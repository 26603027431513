import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import {
	Paper,
	Link as MuiLink,
	Button,
	makeStyles,
	ThemeProvider,
	TextField,
	Typography,
	Tooltip,
	Box,
	InputAdornment,
} from '@material-ui/core';
import { InsightsIcon, SearchIcon } from '../../assets/SvgIcons';
import others from '../../assets/icons/asset-others.svg';
import {
	autoCompleteFilterOption,
	getCurrencyFormat,
} from '../../Utils/string.util';
import {
	gridCardGlobalStyle,
	globalStyles,
	AutocompleteSearchStyles,
	dropDownEllipsisStyle,
} from '../../Layout/styles';
import PipelineInsights from './PipelineInsights.component';
import { Link } from 'react-router-dom';
import head from 'lodash/head';
import {
	useGetCrmKanbanBoardOptionsQuery,
	useGetCrmKanbanBoardQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { autoCompleteTheme } from '../../Layout/Theme.component';
import { Autocomplete } from '@material-ui/lab';

export interface Props {
	name: string;
	id: string;
	type?: string | null;
}

interface pipelineProps {
	count: number;
	name: string;
	id: string;
	value: number;
}

const useStyles = makeStyles({
	value: {
		color: '#212121',
		opacity: 1,
		fontSize: '12px !important',
		fontFamily: 'Manrope-semiBold',
		marginLeft: '4px',
		marginTop: '1px',
	},

	searchStyle: {
		marginTop: '20px',
		width: '210px',
	},
});

const PipelineCard = () => {
	const { permissions: permissionList } = useContext<any>(UserProfileContext);
	const [crmKanbanTemplate, setCrmKanbanTemplate] = useState<any>();
	const [pipelineData, setPipelineData] = useState([]);
	const [isPipelineInsightsShown, setIsPipelineInsightsShown] = useState(false);
	const [pipelineTotal, setPipelineTotal] = useState(0);
	const [searchedPipeline, setSearchedPipeline] = useState<{
		id: string;
		name: string;
	} | null>();
	const cardGlobalStyle = gridCardGlobalStyle();
	const commonStyles = globalStyles();
	const inlineStyle = useStyles();
	const ellipsis = dropDownEllipsisStyle();
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const { data: crmKanbanBoard } = useGetCrmKanbanBoardQuery({
		variables: {
			filter: {
				master_sub_module: { master_module: { value: { _eq: 'crm' } } },
				is_active: { _eq: true },
				deleted_at: { _is_null: true },
				_or: searchedPipeline?.id
					? [{ id: { _eq: searchedPipeline?.id } }]
					: [{}],
			},
		},
		fetchPolicy: 'network-only',
	});
	//for pipeline options
	const { data: pipelineOptions } = useGetCrmKanbanBoardOptionsQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (isEmpty(crmKanbanBoard?.kanban_board)) {
			setPipelineTotal(0);
			return;
		}
		const crmTemplate = crmKanbanBoard?.kanban_board?.map((value) => {
			if (value?.kanban_columns?.length > 0) {
				return value?.kanban_columns?.map((element) => {
					return {
						count:
							element?.opportunity_kanban_cards_aggregate?.aggregate?.count,
						name: value?.name,
						id: value?.id,
						value: element?.opportunity_kanban_cards?.reduce(
							(sum, total) => sum + total?.opportunity?.value,
							0
						),
					};
				});
			} else {
				return [
					{
						count: 0,
						name: value?.name,
						id: value?.id,
						value: 0,
					},
				];
			}
		});
		setPipelineTotal(crmTemplate?.length || 0);
		setCrmKanbanTemplate(crmTemplate);
	}, [crmKanbanBoard]);

	useEffect(() => {
		const kanbanBoard =
			crmKanbanTemplate &&
			crmKanbanTemplate?.map((value: pipelineProps[]) => {
				if (value && value?.length > 0) {
					return {
						count: value?.reduce(
							(sum: number, total: pipelineProps) => sum + total?.count,
							0
						),
						value: value?.reduce(
							(sum: number, total: pipelineProps) => sum + total?.value,
							0
						),
						name: head(value)?.name,
						id: head(value)?.id,
					};
				}
			});
		setPipelineData(kanbanBoard);
	}, [crmKanbanTemplate]);

	return (
		<Box>
			<Box className={commonStyles.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						!isPipelineInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsPipelineInsightsShown(false);
					}}
				>
					Pipeline({pipelineTotal})
				</Button>
				{permissionList?.appPermissions?.ui?.viewPipelineInsights && (
					<Button
						className={[
							commonStyles.navigationTabButtons,
							isPipelineInsightsShown
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={() => {
							setIsPipelineInsightsShown(true);
						}}
						startIcon={<InsightsIcon />}
					>
						Insights
					</Button>
				)}
			</Box>
			{!isPipelineInsightsShown ? (
				<>
					<Box className={inlineStyle?.searchStyle}>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={autoCompleteTheme}>
								<div className={autocompleteSearchStyles.customFieldContainer}>
									<Autocomplete
										onChange={(event: object, newValue: any | null) => {
											if (isEmpty(newValue)) {
												setSearchedPipeline(null);
												return;
											}
											setSearchedPipeline(newValue);
										}}
										classes={{
											option: autocompleteSearchStyles.options,
											groupLabel: autocompleteSearchStyles.groupLabel,
											inputRoot: autocompleteSearchStyles.inputRootWrap,
											noOptions: autocompleteSearchStyles.noOptions,
											focused: autocompleteSearchStyles.focused,
											input: autocompleteSearchStyles.input,
											endAdornment: autocompleteSearchStyles.endAdornment,
										}}
										options={
											pipelineOptions ? pipelineOptions?.kanban_board : []
										}
										value={searchedPipeline}
										renderOption={(option: any) => (
											<Tooltip title={`${option?.name}`} placement='right'>
												<Typography className={`${ellipsis.ellipsis}`}>
													{`${option?.name}`}
												</Typography>
											</Tooltip>
										)}
										getOptionLabel={(option: any) => option?.name}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
										filterOptions={(options, params) => {
											const filtered = autoCompleteFilterOption(
												options,
												params
											);
											return filtered;
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label=''
												placeholder='Search Pipeline'
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<InputAdornment
															position='start'
															classes={{
																root: autocompleteSearchStyles.inputAdronment,
																positionStart:
																	autocompleteSearchStyles.inputAdronmentPositionStart,
															}}
														>
															<SearchIcon
																className={
																	autocompleteSearchStyles.searchIconPositionStart
																}
															/>
														</InputAdornment>
													),
												}}
											/>
										)}
									/>
								</div>
							</ThemeProvider>
						</form>
					</Box>
					<Box className={cardGlobalStyle.gridList}>
						{pipelineData?.length > 0 &&
						crmKanbanBoard?.kanban_board_aggregate?.aggregate?.count! > 0 ? (
							pipelineData?.map((value: pipelineProps) => {
								return (
									<Paper className={cardGlobalStyle.container} key={value?.id}>
										<MuiLink
											component={Link}
											to={`pipeline/${value?.id}`}
											underline='none'
										>
											<Box className={cardGlobalStyle.identity}>
												<Box className={cardGlobalStyle.avatar}>
													<img src={others} alt='icon' />
												</Box>
											</Box>
											<Box className={cardGlobalStyle.secondaryInfo}>
												<Box
													style={{ width: '150px', textAlign: 'center' }}
													className={cardGlobalStyle.secondaryInfoContent}
												>
													<Tooltip title={`${value?.name}`} placement='right'>
														<Typography
															className={`${cardGlobalStyle.secondaryInfoContent} ${cardGlobalStyle.ellipsis} `}
														>
															{value?.name}
														</Typography>
													</Tooltip>
												</Box>
											</Box>
											<Box
												display='flex'
												justifyContent='center'
												marginTop='4px'
											>
												<Box color='#5C5C5C'>Total Value :</Box>
												<Box className={inlineStyle.value}>
													{getCurrencyFormat(value?.value, 'USD', true)}
												</Box>
											</Box>
											<Box
												display='flex'
												justifyContent='center'
												marginTop='16px'
											>
												<Box color='#5C5C5C'>Count :</Box>
												<Box className={inlineStyle.value}>{value?.count}</Box>
											</Box>
										</MuiLink>
									</Paper>
								);
							})
						) : (
							<Box>No Data found</Box>
						)}
					</Box>
				</>
			) : (
				<PipelineInsights />
			)}
		</Box>
	);
};

export default PipelineCard;
