import React, { useEffect, useState } from 'react';

import {
	TextInput,
	useNotify,
	required,
	SelectInput,
	RadioButtonGroupInput,
	number,
	maxLength,
	useQuery,
	regex,
} from 'react-admin';
import { UPDATE_CONSULTANT } from '../gqlQueries';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import {
	currencyTextBox,
	modalFormTheme,
	prefixSelectBoxTheme,
} from '../../../Layout/Theme.component';
import { trimInput } from '../../../Utils/string.util';

import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import ConsultantInActiveForm from './ConsultantInactiveForm.component';
import { ConsultantActiveForm } from './ConsultantActiveForm.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetActiveUsersByLimitQuery,
	useGetOrgDetailsQuery,
} from '../../../generated/graphql';
import { validatePhoneNumber } from '../../../Utils/string.util';
import { head, isEmpty } from 'lodash';

interface ConsultantForm {
	id?: string;
	user_id?: string;
	first_name?: string;
	middle_name?: string;
	last_name?: string;
	contractor_id?: string;
	job_level_id?: string;
	department_id?: string;
	contractor_status_id?: string;
	reportee_id?: string;
	working_mode?: string;
	is_billable?: boolean;
	years?: string;
	months?: string;
	vendor_type?: string;
	contractorPrefix?: string;
	phone_number?: string;
	contractor_prefix_id?: string;
	designation_id?: string;
}
interface Choice {
	first_name: string;
	middle_name: string;
	last_name: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	record: ConsultantForm;
	refetch: () => void;
}
export const ConsultantBasicDetailsForm = (props: Props) => {
	const { open, onClose, record: consultant, refetch } = props;
	const [
		isConsultantIdUniqueViolationError,
		setIsConsultantIdUniqueViolationError,
	] = useState(false);
	const classes = modalFormStyle();
	const notify = useNotify();

	const [updateConsultant, { error: consultantUpdateError }] =
		useApolloMutation(UPDATE_CONSULTANT);
	const [reporteeId, setReporteeId] = useState(consultant?.reportee_id);
	const [currentStatusId, setCurrentStatus] = useState('');
	const [isInactiveModalShown, setIsInactiveModalShown] = useState(false);
	const [isActiveModalShown, setIsActiveModalShown] = useState(false);
	const [jobLevelChoices, setJobLevelChoices] = useState<
		{ id: string; name: string }[]
	>([]);
	const [jobLevelId, setJobLevelId] = useState(consultant?.job_level_id);
	const [departmentChoices, setDepartmentChoices] = useState<
		{ id: string; name: string }[]
	>([]);
	const [departmentId, setDepartmentId] = useState(consultant?.department_id);

	const [statusOptionList, setStatusOptionList] = useState<
		{ id: string; name: string }[]
	>([]);
	const [formValue, setFormValue] = useState<ConsultantForm>({});
	const [
		isPhoneNumberUniqueViolationError,
		setIsPhoneNumberUniqueViolationError,
	] = useState(false);
	const [consultantInitialFormValues, setConsultantInitialFormValues] =
		useState<ConsultantForm>({});
	const [defaultPrefixId, setDefaultPrefixId] = useState<string>('');

	const [designationId, setdesignationId] = useState<string>('');
	const [designationChoices, setDesignationChoices] = useState<
		{ id: string; name: string }[]
	>([]);

	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: statusOptions } = useQuery({
		type: 'GET_LIST',
		resource: 'contractor_status',
		payload: {
			sort: { field: 'label', order: 'ASC' },
		},
	});

	const { data: prefixList } = useQuery({
		type: 'GET_LIST',
		resource: 'contractor_prefix',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});

	const { data: orgDetails } = useGetOrgDetailsQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgDetails) {
			return;
		}

		const jobLevel: { id: string; name: string }[] = orgDetails?.job_level?.map(
			(value) => {
				return { id: value?.id, name: value?.level };
			}
		);
		setJobLevelChoices(jobLevel);

		const designation = orgDetails?.designation?.map((value) => {
			return { id: value?.id, name: value?.title };
		});
		setDesignationChoices(designation);
		const department: { id: string; name: string }[] =
			orgDetails?.department?.map((value) => {
				return { id: value?.id, name: value?.name };
			});
		setDepartmentChoices(department);
	}, [orgDetails]);

	useEffect(() => {
		if (!statusOptions) {
			return;
		}

		const status: { id: string; name: string }[] = statusOptions?.map(
			(value: any) => {
				return { id: value?.id, name: value?.label };
			}
		);
		setStatusOptionList(status);
	}, [statusOptions]);

	useEffect(() => {
		if (!consultant) {
			return;
		}
		setFormValue({
			...consultant,
		});
		setReporteeId(consultant?.reportee_id);
		setDepartmentId(consultant?.department_id);
		setJobLevelId(consultant?.job_level_id);
		setdesignationId(consultant?.designation_id || '');
		setCurrentStatus(String(consultant?.contractor_status_id));
		if (!consultant?.contractor_prefix_id) {
			const { contractor_prefix_id, ...restValues } = consultant;
			setConsultantInitialFormValues({
				...restValues,
				contractor_prefix_id: defaultPrefixId,
				phone_number: consultant?.phone_number ? consultant?.phone_number : '',
			});
			return;
		}
		setConsultantInitialFormValues({
			...consultant,
			phone_number: consultant?.phone_number ? consultant?.phone_number : '',
		});
	}, [consultant, defaultPrefixId]);

	useEffect(() => {
		if (!consultantUpdateError) {
			return;
		}
		if (
			consultantUpdateError.message.includes(
				'contractor_contractor_id_org_id_contractor_prefix_id_key'
			)
		) {
			setIsConsultantIdUniqueViolationError(true);
			return;
		}
	}, [consultantUpdateError]);

	const validateFullName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
	};

	const onConsultantSave = (value?: ConsultantForm) => {
		const experience = `${value?.years || '00'}:${value?.months || '00'}`;
		updateConsultant({
			variables: {
				id: value?.id,
				user_Id: value?.user_id,
				data: {
					contractor_id: value?.contractor_id || null,
					contractor_status_id: currentStatusId
						? currentStatusId
						: value?.contractor_status_id,
					department_id: departmentId ? departmentId : value?.department_id,
					designation_id: designationId
						? designationId
						: value?.designation_id || null,
					is_billable: value?.is_billable,
					job_level_id: jobLevelId ? jobLevelId : value?.job_level_id || null,
					reportee_id: reporteeId || null,
					working_mode: value?.working_mode,
					total_experience: experience,
					vendor_type: value?.vendor_type,
					contractor_prefix_id: value?.contractor_prefix_id || defaultPrefixId,
				},
				userData: {
					first_name: value?.first_name?.trim(),
					middle_name: value?.middle_name?.trim() || null,
					last_name: value?.last_name?.trim(),
					phone_number: value?.phone_number || null,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					refetch();
					notify('Consultant updated successfully');
					onClose();
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error.message.includes('UQ_eeea54cb587a31720056fed06aa')) {
					setIsPhoneNumberUniqueViolationError(true);
					return;
				}
			});
	};

	const onSubmit = (consultantBasicDetails: ConsultantForm) => {
		if (
			consultant?.contractor_status_id ===
			consultantBasicDetails?.contractor_status_id
		) {
			onConsultantSave(consultantBasicDetails);
			return;
		}
		const contractorStatus = statusOptions?.find(
			({ id }: any) => currentStatusId === id
		);
		const isInactiveState = contractorStatus?.value === 'inactive';
		if (isInactiveState) {
			setIsInactiveModalShown(isInactiveState);
			return;
		}
		if (!isInactiveState) {
			setIsActiveModalShown(true);
			return;
		}

		onConsultantSave(consultantBasicDetails);
	};

	const validateConsultantFormValues = async () => {
		const consultantFormErrors = {} as ConsultantForm;
		if (isConsultantIdUniqueViolationError) {
			consultantFormErrors.contractor_id = '';
		}
		if (isPhoneNumberUniqueViolationError) {
			consultantFormErrors.phone_number = 'Phone Number already exists!';
		}
		return consultantFormErrors;
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getDesignation = (designationId: string) => {
		const response = orgDetails?.designation?.find(
			(designation) => designation.id === designationId
		);
		return response ? { id: response?.id, name: response?.title } : null;
	};

	const getJobLevel = (jobLevel: string) => {
		const response = orgDetails?.job_level?.find(
			(value) => value.id === jobLevel
		);

		return response ? { id: response?.id, name: response?.level } : null;
	};

	const getDepartment = (department: string) => {
		const response = orgDetails?.department?.find(
			(value) => value.id === department
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getStatus = (status: string) => {
		const response = statusOptions?.find((value: any) => value.id === status);

		return response ? { id: response?.id, name: response?.label } : null;
	};

	const modalClose = () => {
		setReporteeId(consultant?.reportee_id);
		setDepartmentId(consultant?.department_id);
		setJobLevelId(consultant?.job_level_id);
		setCurrentStatus(String(consultant?.contractor_status_id));
		setDepartmentId(consultant?.department_id);
		setdesignationId(consultant?.designation_id || '');
		onClose();
	};

	// Find N/A prefix to set it as default value
	useEffect(() => {
		if (isEmpty(prefixList)) {
			return;
		}
		const emptyPrefix: { value: string; id: string }[] = prefixList?.filter(
			(prefix: { value: string; id: string }) => prefix.value === 'N/A'
		);
		if (emptyPrefix) {
			setDefaultPrefixId(head(emptyPrefix)?.id || '');
		}
	}, [prefixList]);

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={modalClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={modalClose} />
					</Box>
					<Form
						on
						initialValues={consultantInitialFormValues}
						onSubmit={onSubmit}
						validate={validateConsultantFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													First Name*
												</Typography>
												<TextInput
													source='first_name'
													label=''
													validate={[
														required(),
														validateFullName,
														maxLength(
															20,
															'First Name should not be greater than 20'
														),
													]}
													onChange={(e: any) => {
														setFormValue({
															...formValue,
															first_name: e.target.value,
														});
													}}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Middle Name
												</Typography>
												<TextInput
													source='middle_name'
													label=''
													validate={[
														maxLength(
															20,
															'Middle Name should not be greater than 20'
														),
													]}
													onChange={(e: any) => {
														setFormValue({
															...formValue,
															last_name: e.target.value,
														});
													}}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Last Name*
												</Typography>
												<TextInput
													source='last_name'
													label=''
													validate={[
														required(),
														validateFullName,
														maxLength(
															20,
															'Last Name should not be greater than 20'
														),
													]}
													onChange={(e: any) => {
														setFormValue({
															...formValue,
															last_name: e.target.value,
														});
													}}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Department *
												</Typography>

												<AutocompleteSearch
													placeholder={'Search Department'}
													option={departmentChoices || []}
													onChange={setDepartmentId}
													value={
														departmentId ? getDepartment(departmentId) : null
													}
													name={'department_id'}
													validates={true}
												/>
											</Box>
										</Box>

										<Box>
											<Box>
												<Typography className={classes.label}>
													Designation
												</Typography>

												<AutocompleteSearch
													placeholder={'Search Designation'}
													option={designationChoices || []}
													onChange={(value) => {
														setdesignationId(value);
													}}
													value={
														designationId ? getDesignation(designationId) : null
													}
													name={'designation_id'}
												/>
											</Box>
										</Box>

										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Consultant ID
												</Typography>
												<Box display='flex'>
													<Box width='100px'>
														<ThemeProvider theme={prefixSelectBoxTheme}>
															<SelectInput
																choices={prefixList || []}
																source='contractor_prefix_id'
																optionText='name'
																label={''}
																helperText={false}
																resettable
																onChange={() =>
																	setIsConsultantIdUniqueViolationError(false)
																}
															/>
														</ThemeProvider>
													</Box>
													<Box display='block'>
														<ThemeProvider theme={currencyTextBox}>
															<TextInput
																helperText={false}
																validate={[
																	number(),
																	maxLength(6, 'Maximum 6 digits allowed!'),
																]}
																source='contractor_id'
																label=''
																onChange={(e: any) => {
																	setFormValue({
																		...formValue,
																		contractor_id: e.target.value,
																	});
																	setIsConsultantIdUniqueViolationError(false);
																}}
															/>
														</ThemeProvider>
														{isConsultantIdUniqueViolationError && (
															<p className={classes.errorText}>
																{'Id already exists'}
															</p>
														)}
													</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Job Level
												</Typography>
												<Box marginTop='8px'>
													<AutocompleteSearch
														placeholder={'Search Job Level'}
														option={jobLevelChoices || []}
														onChange={setJobLevelId}
														value={jobLevelId ? getJobLevel(jobLevelId) : null}
														name={'job_level_id'}
													/>
												</Box>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Work Experience
												</Typography>
												<TextInput
													source='years'
													label={''}
													InputProps={{
														endAdornment: 'Years',
													}}
													onChange={(e: any) => {
														setFormValue({
															...formValue,
															years: e.target.value,
														});
													}}
													validate={[
														number(),
														maxLength(2, 'must be in the format of 00,01,10'),
													]}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Box style={{ marginTop: '24px' }}>
													<TextInput
														source='months'
														label={''}
														InputProps={{
															endAdornment: 'Months',
														}}
														onChange={(e: any) => {
															setFormValue({
																...formValue,
																months: e.target.value,
															});
														}}
														validate={[
															number(),
															maxLength(2, 'must be in the format of 00,01,10'),
														]}
													/>
												</Box>
											</Box>
										</Box>
										<Typography className={classes.label}>Status *</Typography>

										<AutocompleteSearch
											placeholder={'Search Status'}
											option={statusOptionList || []}
											onChange={setCurrentStatus}
											value={
												currentStatusId ? getStatus(currentStatusId) : null
											}
											name={'contractor_status_id'}
											validates={true}
										/>

										<Typography className={classes.label}>
											Reporting to
										</Typography>
										<AutocompleteSearch
											placeholder={'Search User'}
											option={
												activeUsers && activeUsers?.user
													? activeUsers?.user?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setReporteeId}
											value={getOwnerValue(reporteeId || '')}
											name={'reportee_id'}
											validates={false}
										/>
										<Typography className={classes.label}>
											Phone Number
										</Typography>
										<TextInput
											label=''
											source='phone_number'
											validate={[
												regex(validatePhoneNumber, 'Invalid phone number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											fullWidth
											onChange={(e: any) => {
												setFormValue({
													...formValue,
													phone_number: e.target.value,
												});
												setIsPhoneNumberUniqueViolationError(false);
											}}
											parse={trimInput}
										></TextInput>

										<Typography className={classes.label}>
											Mode of Work
										</Typography>
										<SelectInput
											source='working_mode'
											label=''
											fullWidth
											choices={[
												{ id: 'Hybrid', name: 'Hybrid' },
												{ id: 'WFH', name: 'Remote' },
												{ id: 'Office', name: 'WFO' },
											]}
											onChange={(e) => {
												setFormValue({
													...formValue,
													working_mode: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>Type</Typography>
										<SelectInput
											source='vendor_type'
											label=''
											fullWidth
											choices={[
												{ id: 'Consultancy', name: 'Consultancy' },
												{ id: 'Freelancer', name: 'Freelancer' },
											]}
											onChange={(e) => {
												setFormValue({
													...formValue,
													vendor_type: e.target.value,
												});
											}}
										/>
										<RadioButtonGroupInput
											source='is_billable'
											label=''
											choices={[
												{ id: true, name: 'Billable' },
												{ id: false, name: 'Non Billable' },
											]}
											onChange={(value: any) =>
												setFormValue({ ...formValue, is_billable: value })
											}
										/>
									</DialogContent>
								</Box>
								<DialogActions>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={modalClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid || pristine
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || pristine}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</DialogActions>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
			<ConsultantInActiveForm
				open={isInactiveModalShown}
				onClose={() => {
					setIsInactiveModalShown(false);
				}}
				userId={consultant?.user_id}
				onSuccess={() => {
					onConsultantSave(formValue);
				}}
			/>
			<ConsultantActiveForm
				open={isActiveModalShown}
				onClose={() => {
					setIsActiveModalShown(false);
				}}
				contactor_id={consultant?.id}
				onSuccess={() => {
					onConsultantSave(formValue);
				}}
			/>
		</ThemeProvider>
	);
};

export default ConsultantBasicDetailsForm;
