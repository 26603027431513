import React from 'react';
import { modalFormStyle } from '../../Layout/styles';
import { Button, CircularProgress } from '@material-ui/core';

interface Props {
	isDisabled: boolean;
	isLoading?: boolean;
	title: string;
	loaderColor?: string;
}

const ButtonWithLoader = (props: Props) => {
	const { isDisabled, isLoading, title, loaderColor = '#4285F4' } = props;
	const classes = modalFormStyle();
	return (
		<Button
			className={
				isDisabled || isLoading
					? `${classes.disabledButton}`
					: `${classes.saveButton}`
			}
			disabled={isDisabled || isLoading}
			type={'submit'}
			startIcon={
				isLoading && (
					<CircularProgress
						style={{
							height: '20px',
							width: '20px',
							color: loaderColor,
						}}
					/>
				)
			}
		>
			{title}
		</Button>
	);
};

export default ButtonWithLoader;
