import React, { useState, useEffect, useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	createTheme,
	InputAdornment,
	IconButton,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	TablePagination,
} from '@material-ui/core';
import { SelectInput } from 'react-admin';

import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/dayjs';
import { Form } from 'react-final-form';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { head, isEmpty, orderBy } from 'lodash';
import {
	useGetTimesheetStatusQuery,
	useGetOvertimeHistoryQuery,
	GetOvertimeHistoryQuery,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { modalFormTheme } from '../../../Layout/Theme.component';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayjs from 'dayjs';
import { TimesheetStyles } from '../TimesheetStyles';
import { START_DATE_ERROR } from './constant';
import { TimesheetHistoryPendingApprovalStyle } from '../TimesheetStyles';
import no_data_found from '../../../assets/no_data_found.png';
import {
	getTimesheetEntriesBillablePercentage,
	getTotalBillableHours,
	getTotalHours,
	getTotalNonBillableHours,
} from './Overtime.utils';
import { OvertimeSubmission, User } from './Overtime.model';
import TimesheetOvertimeHistoryDetailsModal from './TimesheetOvertimeHistoryDetails.component';

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const inlineStyle = makeStyles({
	dropdown: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	container: {
		marginTop: '20px',
	},
});

interface Options {
	id?: any;
	name?: any;
}

const TimesheetOvertimeHistory = () => {
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const timesheetPendingApprovalStyles = TimesheetHistoryPendingApprovalStyle();
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<any>();
	const [timesheetStatusId, setTimesheetStatusId] = useState<any>();
	const [startDateError, setStartDateError] = useState(false);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const timesheetStyles = TimesheetStyles();
	const [timesheetData, setTimesheetData] = useState<GetOvertimeHistoryQuery>();
	const style = inlineStyle();
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();
	const { data: timesheetHistory } = useGetOvertimeHistoryQuery({
		variables: {
			userId,
			statusId: timesheetStatusId === 'All' ? {} : { _eq: timesheetStatusId },
			startDate: startDate,
			endDate: endDate,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (isEmpty(timesheetStatus?.timesheet_status)) {
			return;
		}
		const timesheetStatusDropdownOptions = timesheetStatus?.timesheet_status
			?.filter((value) => value.value !== 'draft')
			.map((timesheet) => {
				if (!timesheet) {
					return;
				}
				if (timesheet?.value === 'submitted') {
					setTimesheetStatusId(timesheet.id);
				}
				return {
					id: timesheet?.id,
					name: timesheet?.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			});
		const sortedTimesheetStatusDropdownOptions = orderBy(
			timesheetStatusDropdownOptions,
			['name'],
			['asc']
		);
		setTimesheetStatusOptions(sortedTimesheetStatusDropdownOptions);
	}, [timesheetStatus]);

	useEffect(() => {
		if (isEmpty(timesheetHistory?.user)) {
			return;
		}
		const timesheets = timesheetHistory?.user
			.filter((user) => user?.timesheetOvertimeSubmissions?.length > 0)
			.map((user) => ({
				...user,
				timesheetOvertimeSubmissions:
					user?.timesheetOvertimeSubmissions?.filter(
						(timesheetSubmission) =>
							timesheetSubmission?.timesheet_overtimes?.length > 0
					),
			}));
		if (timesheets) {
			setTimesheetData({ user: timesheets });
		}
	}, [timesheetHistory]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event?.target?.value, 10));
		setPage(0);
	};

	const getOvertimeEnteredTaskName = (overtime: any | undefined) => {
		const taskName = overtime?.task_id
			? overtime?.project_task?.title
			: overtime?.org_task_id
			? overtime?.org_task?.title
			: overtime?.master_project_task_id
			? overtime?.master_project_task?.title
			: '';

		return taskName;
	};
	const getOvertimeEnteredProjectName = (overtime: any | undefined) => {
		const projectName = overtime?.task_id
			? overtime?.project?.name
			: overtime?.org_task_id
			? overtime?.org_task?.task_category
			: '';

		return projectName;
	};
	const handleRowClick = (
		overtimeSubmission: OvertimeSubmission,
		userDetails: User
	) => {
		if (!overtimeSubmission?.timesheet_overtimes[0]?.date) {
			return;
		}
		setSelectedTimesheet({
			user: {
				id: userDetails?.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			billablePercentage: getTimesheetEntriesBillablePercentage(
				overtimeSubmission?.timesheet_overtimes
			),
			billableHours: getTotalBillableHours(
				overtimeSubmission?.timesheet_overtimes
			),
			nonBillableHours: getTotalNonBillableHours(
				overtimeSubmission?.timesheet_overtimes
			),
			totalHours: getTotalHours(overtimeSubmission?.timesheet_overtimes),
			overtimeSubmissionId: overtimeSubmission?.id,
			date: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.date
				: '',
			projectName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredProjectName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			taskName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredTaskName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			notes: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.notes
				: '',
		});
	};

	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<Box className={style.dropdown}>
					<Form
						onSubmit={() => {}}
						initialValues={{ timesheetstatus: timesheetStatusId }}
					>
						{() => (
							<ThemeProvider theme={modalFormTheme}>
								<form>
									<SelectInput
										source={'timesheetstatus'}
										label={''}
										id='timesheet_history_view_status_select'
										choices={
											timesheetStatusOptions &&
											timesheetStatusOptions?.length > 0
												? [
														{
															id: 'All',
															name: 'All',
														},
														...timesheetStatusOptions,
												  ]
												: []
										}
										onChange={(event) =>
											setTimesheetStatusId(event?.target?.value)
										}
									/>
								</form>
							</ThemeProvider>
						)}
					</Form>
				</Box>
				<Box>
					<ThemeProvider theme={dateTimePickerTheme}>
						<Box display='flex'>
							<ErrorTooltip
								title={startDateError ? START_DATE_ERROR : ''}
								placement='top'
								arrow={true}
							>
								<Box width='188px' marginLeft='20px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setStartDateError(false);
												if (dayjs(date).isAfter(dayjs(endDate))) {
													setStartDateError(true);
												}
												setStartDate(dayjs(date).format('YYYY-MM-DD'));
											}}
											value={startDate}
											format={dateFormat}
											error={startDateError}
											label='Start date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton key='timesheet_history_view_start_date_icon'>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</ErrorTooltip>
							<Box width='188px'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant='inline'
										onChange={(date: any) => {
											setStartDateError(false);
											if (dayjs(date).isBefore(dayjs(startDate))) {
												setStartDateError(true);
											}
											setEndDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										value={endDate}
										format={dateFormat}
										label='End date'
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton key='timesheet_history_view_end_date_icon'>
														<EventIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</Box>
					</ThemeProvider>
				</Box>
			</Box>
			{timesheetData && timesheetData.user?.length > 0 ? (
				<div className={style.container}>
					{timesheetData?.user?.map((user, index) =>
						user &&
						user?.timesheetOvertimeSubmissions &&
						user?.timesheetOvertimeSubmissions[0]?.timesheet_overtimes?.length >
							0 ? (
							<div
								className={timesheetPendingApprovalStyles.timesheetsContainer}
							>
								<div className={timesheetPendingApprovalStyles.avatarContainer}>
									<Avatar
										className={timesheetPendingApprovalStyles.avatar}
										src={`${user.profile_pic || ''}`}
									>
										{user?.first_name?.charAt(0).toUpperCase() || ''}
										{user?.last_name?.charAt(0).toUpperCase() || ''}
									</Avatar>
									<Typography
										className={timesheetPendingApprovalStyles.employeeName}
									>
										{`${user.full_name || ''} `}
									</Typography>
								</div>
								<Table>
									<TableHead>
										<TableRow>
											<CustomTableCell>DATE</CustomTableCell>
											<CustomTableCell>STATUS</CustomTableCell>
											<CustomTableCell>TOTAL HOURS</CustomTableCell>
											<CustomTableCell>BILLABLE HOURS</CustomTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{user.timesheetOvertimeSubmissions
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map(
												(timesheetSubmission) =>
													timesheetSubmission?.timesheet_overtimes?.length >
														0 && (
														<TableRow
															hover={true}
															style={{ cursor: 'pointer' }}
															key={timesheetSubmission.id}
															onClick={() => {
																setIsDetailsModalShown(true);
																handleRowClick(timesheetSubmission, {
																	id: user.id,
																	name: `${user?.full_name} `,
																	profilePic: user.profile_pic,
																});
															}}
														>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} $`,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={
																		timesheetPendingApprovalStyles.dateContainer
																	}
																>
																	<DateRangeIcon
																		className={
																			timesheetPendingApprovalStyles.calendarIcon
																		}
																	/>
																	{head(
																		timesheetSubmission?.timesheet_overtimes
																	)?.date
																		? dayjs(
																				head(
																					timesheetSubmission?.timesheet_overtimes
																				)?.date
																		  ).format('DD MMM YYYY')
																		: '--'}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={`${timesheetStyles.statusContainer}
                                ${
																	timesheetSubmission.timesheet_status.value ===
																	'submitted'
																		? timesheetStyles.submittedStatus
																		: timesheetSubmission.timesheet_status
																				.value === 'approved'
																		? timesheetStyles.approvedStatus
																		: timesheetStyles.rejectedStatus
																}
                              `}
																>
																	{timesheetSubmission.timesheet_status
																		.value === 'submitted'
																		? 'Pending'
																		: timesheetSubmission.timesheet_status
																				.label}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={
																		timesheetPendingApprovalStyles.totalHoursContainer
																	}
																>
																	<AccessTimeIcon
																		className={
																			timesheetPendingApprovalStyles.durationIcon
																		}
																	/>
																	{`${getTotalHours(
																		timesheetSubmission.timesheet_overtimes
																	)} hours`}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																{`${getTotalBillableHours(
																	timesheetSubmission.timesheet_overtimes
																)} hours`}
															</CustomTableCell>
														</TableRow>
													)
											)}
									</TableBody>
								</Table>
								<Box display='flex' justifyContent='flex-end'>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={user.timesheetOvertimeSubmissions?.length || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</Box>
							</div>
						) : (
							<Box textAlign='center'>
								<img src={no_data_found} alt='no_data_found' />
							</Box>
						)
					)}
				</div>
			) : (
				<Box textAlign='center'>
					<img src={no_data_found} alt='no_data_found' />
				</Box>
			)}
			<TimesheetOvertimeHistoryDetailsModal
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedTimesheet({});
				}}
				overtime={selectedTimesheet}
			/>
		</Box>
	);
};

export default TimesheetOvertimeHistory;
