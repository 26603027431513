import React from 'react';
import ProjectStatus from './ProjectStatus.component';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import ProjectCategory from './ProjectCategory/ProjectCategory.component';
import ProjectLabel from './ProjectLabel/ProjectLabel.component';
import ProjectResourceTag from './ProjectResourceTag/ProjectResourceTag.component';

export const ProjectSettings = () => {
	return (
		<>
			<SettingsDetailContainer heading='Project Status'>
				<ProjectStatus />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Project Category'>
				<ProjectCategory />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Project Label'>
				<ProjectLabel />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Tag'>
				<ProjectResourceTag />
			</SettingsDetailContainer>
			
		</>
	);
};

export default ProjectSettings;
