import React from 'react';
import { Loading } from 'react-admin';
import { AssetDetailsCard } from './AssetDetailsCard.component';
import { GET_ASSET } from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import AssetDetailContainer from './AssetDetailContainer.component';
import { detailsPageGlobalStyle } from '../../../Layout/styles';
import { useParams } from 'react-router-dom';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadcumData } from '../Constant';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export const AssetDetails = () => {
	const classes = detailsPageGlobalStyle();
	const { id }: { id: string } = useParams();
	const {
		data: asset,
		loading,
		refetch: refetchAsset,
	} = useApolloQuery(GET_ASSET, {
		variables: {
			id: id ? id : null,
		},
	});
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<Breadcrum data={[...breadcumData,{name:asset?.asset_by_pk?.name}]} icon={DoubleArrowIcon}/>
			<div className={classes.container}>
				<div className={classes.leftPanel}>
					<AssetDetailsCard
						record={asset?.asset_by_pk}
						basePath='/asset'
						resource='asset'
						refetch={() => refetchAsset()}
					/>
				</div>

				<div className={classes.rightPanel}>
					<AssetDetailContainer
						record={asset?.asset_by_pk}
						basePath='/asset'
						resource='asset'
						refetch={() => refetchAsset()}
					/>
				</div>
			</div>
		</>
	);
};

export default AssetDetails;
