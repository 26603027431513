import React, { useEffect, useState } from 'react';
import { Layout } from 'react-admin';
import { CustomNavMenu } from './Menu';
import { CustomAppBar } from '../SharedComponents/UserMenu/CustomAppBar';
import HomePageMobile from '../modules/Mobile/HomePageMobile';
import { useHistory } from 'react-router-dom';

export const CustomLayout = (props) => {
	const MIN_WIDTH_MOBILE_VIEW = 768;
	const [width, setWidth] = useState(window?.innerWidth);
	let history = useHistory();

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		if (width <= MIN_WIDTH_MOBILE_VIEW) {
			return history?.push(`/pwa/home_m`);
		} else {
			if (history?.location?.pathname === '/pwa/home_m') {
				return history?.push('/');
			}
		}
	}, [width, history]);

	return width <= MIN_WIDTH_MOBILE_VIEW ? (
		<HomePageMobile />
	) : (
		<Layout {...props} appBar={CustomAppBar} menu={CustomNavMenu} />
	);
};
