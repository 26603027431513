export const editableColumnsPermissions = {
	// Employee Columns
	emp_personal_email: 'emp_personal_email',
	emp_address: 'emp_address',
	emp_blood_group: 'emp_blood_group',
	emp_dob: 'emp_dob',
	emp_gender: 'emp_gender',
	emp_bank_name: 'emp_bank_name',
	emp_branch_name: 'emp_branch_name',
	emp_account_number: 'emp_account_number',
	emp_ifsc_code: 'emp_ifsc_code',
	emp_emergency_contact_number: 'emp_emergency_contact_number',
	emp_marital_status: 'emp_marital_status',
	emp_documents: 'emp_documents',
	emp_current_address: 'emp_current_address',
	emp_highest_academic_degree: 'emp_highest_academic_degree',
	emp_institution_name: 'emp_institution_name',
	emp_skill: 'emp_skill',
};

export const updateDetailsSuccessText = 'Details Updated Successfully';
export const UPDATE_MY_INFO_BANK_DETAILS_SUCCESS_TEXT =
	'Bank Details Updated Successfully';

export const PROFILE_PIC_UPDATE_SUCCESS_TEXT =
	'Profile Pic Updated Successfully';
export const PROFILE_PIC_UPDATE_ERROR_TEXT = 'Profile Pic Update Failed';
