import React, { useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	ReferenceInput,
	regex,
	required,
	useMutation,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { Button, Box, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	validateLinkedIn,
	validateWebsite,
	validatePhoneNumber,
} from '../../../Utils/string.util';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';

interface Record {
	__typename: string;
	address: string;
	category_id: string;
	sector: string;
	phone: string;
	size: string;
	contact_type: string;
	name: string;
	linkedin: string;
	logo?: any;
	id: string;
	website: string;
	country_id: string;
	owner_id: string;
}
interface Props {
	onClose: (value: boolean) => void;
	data: Record;
	open: boolean;
	refetchCompany: () => void;
}
interface FormProps {
	id?: string;
	address?: string;
	name?: string;
	country_id?: string;
	category_id?: string;
	owner_id?: string;
	sector?: string;
	size?: string;
	website?: string;
	linkedin?: string;
	phone?: string;
	contact_type?: string;
}
interface Choice {
	first_name: string;
	last_name: string;
	full_name: string;
}

export const CompanyBasicDetailsForm = (props: Props) => {
	const { onClose, data, open, refetchCompany } = props;
	const classes = modalFormStyle();
	const [isOpen, setIsOpen] = useState(false);
	const [company, setCompany] = useState<FormProps>({});
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [mutate, { loading: isCompanyLoading }] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	const onModalClose = () => {
		setOwnerId(data?.owner_id || null);
		setIsOpen(false);
		onClose(false);
	};

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		setCompany({
			...data,
		});
		setOwnerId(data?.owner_id || null);
	}, [data]);

	const onSave = () => {
		mutate(
			{
				type: 'update',
				resource: 'crm_company',
				payload: {
					id: company?.id,
					data: {
						name: company?.name,
						address: company?.address,
						category_id: company?.category_id,
						owner_id: ownerId || null,
						sector_id: company?.sector,
						size_id: company?.size,
						country_id: company?.country_id,
						website: company?.website,
						linkedin: company?.linkedin,
						phone: company?.phone,
						contact_type_id: company?.contact_type,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					refetchCompany();
					notify('Company details updated successfully');
					refresh();
					onModalClose();
				},
				onFailure: (error) =>
					notify(`Create failed: ${error.message}`, 'warning'),
			}
		);
		return;
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={isOpen} onClose={onModalClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={onModalClose} />
					</Box>
					<Form initialValues={data} onSubmit={onSave}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box>
											<>
												<Box className={classes.label}>Company Name*</Box>
												<Box>
													<TextInput
														source='name'
														onChange={(event) =>
															setCompany({
																...company,
																name: event.target.value,
															})
														}
														validate={required()}
														label={false}
													/>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Address</Box>
												<Box>
													<TextInput
														label={false}
														source='address'
														onChange={(event) =>
															setCompany({
																...company,
																address: event.target.value,
															})
														}
													/>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Country</Box>
												<Box>
													<ReferenceInput
														source='country_id'
														reference='geo_list'
														sort={{ field: 'name', order: 'ASC' }}
														onChange={(event) =>
															setCompany({
																...company,
																country_id: event.target.value,
															})
														}
														label=''
													>
														<SelectInput optionText='name' />
													</ReferenceInput>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Category</Box>
												<Box>
													<ReferenceInput
														source='category_id'
														reference='company_categories'
														label=''
														sort={{ field: 'label', order: 'ASC' }}
														onChange={(event) =>
															setCompany({
																...company,
																category_id: event.target.value,
															})
														}
													>
														<SelectInput optionText='label' />
													</ReferenceInput>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Account Owner</Box>
												<Box>
													<Field name='owner_id'>
														{(props: any) => (
															<AutocompleteSearch
																placeholder={'Search User'}
																option={
																	activeUsers && activeUsers?.user
																		? activeUsers?.user?.map((value) => {
																				return {
																					id: value?.id,
																					name: value?.name,
																				};
																		  })
																		: []
																}
																onChange={(event) => {
																	setOwnerId(event);
																	props.input.onChange(event);
																}}
																value={getOwnerValue(ownerId || '')}
																name={'owner_id'}
																validates={false}
															/>
														)}
													</Field>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Sector</Box>
												<Box>
													<ReferenceInput
														source='sector'
														reference='company_sector'
														label=''
														sort={{ field: 'label', order: 'ASC' }}
														onChange={(event) =>
															setCompany({
																...company,
																sector: event.target.value,
															})
														}
													>
														<SelectInput optionText='label' />
													</ReferenceInput>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Size</Box>
												<Box>
													<ReferenceInput
														source='size'
														reference='company_size'
														label=''
														sort={{ field: 'label', order: 'ASC' }}
														onChange={(event) =>
															setCompany({
																...company,
																size: event.target.value,
															})
														}
													>
														<SelectInput optionText='label' />
													</ReferenceInput>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Website</Box>
												<Box>
													<TextInput
														source='website'
														fullWidth
														label={false}
														validate={[
															regex(validateWebsite, 'Must be a valid Link'),
														]}
														onChange={(event) =>
															setCompany({
																...company,
																website: event.target.value,
															})
														}
													/>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Linkedin</Box>
												<Box>
													<TextInput
														source='linkedin'
														label={false}
														fullWidth
														validate={[
															regex(validateLinkedIn, 'Must be a valid Link'),
														]}
														onChange={(event) =>
															setCompany({
																...company,
																linkedin: event.target.value,
															})
														}
													/>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Phone Number</Box>
												<Box>
													<TextInput
														source='phone'
														validate={[
															regex(validatePhoneNumber, 'Must be a number'),
															maxLength(15, 'Maximum 15 digits allowed'),
														]}
														label={false}
														onChange={(event) =>
															setCompany({
																...company,
																phone: event.target.value,
															})
														}
														fullWidth
													/>
												</Box>
											</>
										</Box>
										<Box>
											<>
												<Box className={classes.label}>Contact type</Box>
												<Box>
													<ReferenceInput
														source='contact_type'
														reference='company_contact_type'
														sort={{ field: 'label', order: 'ASC' }}
														label=''
														onChange={(event) =>
															setCompany({
																...company,
																contact_type: event.target.value,
															})
														}
													>
														<SelectInput optionText='label' />
													</ReferenceInput>
												</Box>
											</>
										</Box>
									</DialogContent>
								</Box>
								<DialogActions>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={onModalClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid || pristine || isCompanyLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || pristine || isCompanyLoading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</DialogActions>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};
export default CompanyBasicDetailsForm;
