import React, { useContext, useState } from 'react';
import { useGetMyTeamViewQuery } from '../generated/graphql';
import {
	Box,
	TableContainer,
	Paper,
	Table,
	TableRow,
	TableHead,
	makeStyles,
	Typography,
	TableBody,
	Tooltip,
	ThemeProvider,
	TextField,
	TablePagination,
} from '@material-ui/core';

import { CustomTableCell } from '../SharedComponents/CustomTableCell.component';
import { ellipsisStyle } from '../Layout/styles';
import { formatEmployeeId, getPrefix } from '../Utils/string.util';
import { head, isEmpty } from 'lodash';

import { searchBarTheme } from '../Layout/Theme.component';

import { SearchIcon } from '../assets/SvgIcons';
import { exportToCsv } from '../Utils/string.util';
import { UserProfileContext } from '../App';
import { ToolTipProfileCard } from '../SharedComponents/ToolTipProfileCard.component';
import Info from '@material-ui/icons/Info';
import dayjs from 'dayjs';
import ExportButtonWithLoader from '../SharedComponents/ExportButton/ExportButtonWithLoader';
const useStyles = makeStyles(() => ({
	label: {
		width: '80px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	tableList: {
		height: '460px',
		overflowY: 'auto',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		letterSpacing: '0px',
	},
	noData: {
		fontFamily: 'normal normal medium 12px/15px Manrope !important',
		fontSize: '12px',
		paddingTop: '30px',
	},
	customRowStyle: {
		background: '#F7F9FA',
		'&:hover': {
			boxShadow: '0px 8px 19px #0000000F',
		},
	},

	avatar: {
		display: 'flex',
		marginTop: '2px',
	},
	nameField: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
	},
	fullNameField: {
		marginLeft: '25px',
		marginRight: '25px',
	},

	phoneIcon: {
		width: '14px',
		paddingRight: '4px',
	},
	tooltip: {
		background: 'transparent',
		borderColor: 'transparent',
	},
	infoStyle: {
		width: '14px',
		padding: '2px',
	},
	resourceName: {
		marginLeft: '10px',
		display: 'flex',
	},
}));

export interface Props {
	userId: string;
}

export const EmployeeMyTeam = (props: Props) => {
	const { userId } = props;
	const inlineStyle = useStyles();
	const styles = ellipsisStyle();

	const { noOfLocations, dateFormat, empIdLength, isFixedEmpIdLengthEnabled } =
		useContext<any>(UserProfileContext);

	const [searchedUser, setSearchedUser] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [page, setPage] = useState(0);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const { data: myTeam } = useGetMyTeamViewQuery({
		variables: {
			userId: userId,
			SearchText: `%${searchedUser}%`,
		},
		fetchPolicy: 'network-only',
	});

	const generateCSV = () => {
		if (!myTeam?.user) {
			return;
		}
		const MY_TEAM_RESOURCE = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',

			'RESOURCE TYPE',

			'DEPARTMENT',
			'DESIGNATION',
			'JOB LEVEL',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'PHONE NUMBER',
			'PERSONAL EMAIL',
			'BLOOD GROUP',
			'DATE OF BIRTH',
			'ADDRESS',
		];
		const exportMyTeam = myTeam?.user?.map((user) => {
			const existingEmployeeId = user?.employee?.emp_id || '';
			const existingContractorId = head(user?.contractors)?.contractor_id || '';
			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			const empId =
				user?.user_type === 'employee'
					? user?.employee?.emp_id
						? `${
								getPrefix(
									user?.employee?.employee_prefix?.name,
									user?.employee?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId || ''}`
						: '--'
					: head(user?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(user?.contractors)?.contractor_prefix?.name,
								head(user?.contractors)?.contractor_prefix?.value
							) || ''
					  }${
							user?.contractors && head(user?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '--';
			const name = user?.full_name;
			const email = user?.email;

			const userType =
				user?.user_type === 'employee' ? 'Employee' : 'Contractor';
			const department =
				user?.user_type === 'employee'
					? user?.employee?.department?.name || '--'
					: head(user?.contractors)?.department?.name || '--';
			const designation =
				user?.user_type === 'employee'
					? user?.employee?.designation?.title || '--'
					: head(user?.contractors)?.designation?.title || '--';
			const joblevel =
				user?.user_type === 'employee'
					? user?.employee?.job_level?.level || '--'
					: head(user?.contractors)?.job_level?.level || '--';
			const location =
				user?.user_type === 'employee'
					? user?.employee?.org_location?.name || '--'
					: head(user?.contractors)?.org_location?.name || '--';
			const phone_number = user?.phone_number;
			const personalEmail =
				user?.user_type === 'employee'
					? user?.employee?.personal_email || '--'
					: head(user?.contractors)?.personal_email || '--';
			const bloodGroup =
				user?.user_type === 'employee' ? user?.employee?.blood_group : '--';
			const dateOfBirth =
				user?.user_type === 'employee'
					? user?.employee?.dob
						? dayjs(user?.employee?.dob).format(dateFormat)
						: '--'
					: head(user?.contractors)?.dob
					? dayjs(head(user?.contractors)?.dob).format(dateFormat)
					: '--' || '--';
			const address =
				user?.user_type === 'employee'
					? user?.employee?.address?.houseName ||
					  user?.employee?.address?.streetName
						? `${user?.employee?.address?.houseName || '--'},${
								user?.employee?.address?.streetName || '--'
						  }`
						: '--'
					: head(user?.contractors)?.address?.houseName ||
					  head(user?.contractors)?.address?.streetName
					? `${head(user?.contractors)?.address?.houseName || '--'}, ${
							head(user?.contractors)?.address?.streetName || '--'
					  }`
					: '--';

			return [
				empId || '--',
				name || '--',
				email || '--',

				userType || '--',
				department || '--',
				designation || '--',
				joblevel || '--',
				...(noOfLocations && noOfLocations > 0 ? [location || '--'] : []),
				phone_number || '--',
				personalEmail || '--',
				bloodGroup || '--',
				dateOfBirth || '--',
				address || '--',
			];
		});
		const myTeamExport = [MY_TEAM_RESOURCE, ...exportMyTeam];
		exportToCsv('MyTeam_Info.csv', myTeamExport);
	};
	return (
		<>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				marginBottom={1}
			>
				<Box>
					<form onSubmit={(event) => event.preventDefault()}>
						<ThemeProvider theme={searchBarTheme}>
							<TextField
								placeholder='Search Resource'
								label={false}
								fullWidth
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									if (!e) {
										return;
									}
									setSearchedUser(e?.target?.value);
									setPage(0);
								}}
								value={searchedUser}
							/>
						</ThemeProvider>
					</form>
				</Box>
				<Box>
					<ExportButtonWithLoader
						generateCSV={generateCSV}
						isDisabled={isEmpty(myTeam?.user)}
					/>
				</Box>
			</Box>
			{myTeam?.user!?.length > 0 ? (
				<Box>
					<TableContainer component={Paper} className={inlineStyle.tableList}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<CustomTableCell>RESOURCE ID</CustomTableCell>
									<CustomTableCell>
										<Box className={inlineStyle.resourceName}>
											RESOURCE NAME
											<Tooltip
												title={
													'Hover above each resource name to see more details'
												}
												placement='top'
											>
												<Info className={inlineStyle.infoStyle} />
											</Tooltip>
										</Box>
									</CustomTableCell>
									<CustomTableCell>RESOURCE TYPE</CustomTableCell>
									<CustomTableCell>DEPARTMENT</CustomTableCell>
									<CustomTableCell>JOB LEVEL</CustomTableCell>
									{noOfLocations && noOfLocations > 0 && (
										<CustomTableCell>LOCATION</CustomTableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{myTeam!.user &&
									myTeam!.user?.length > 0 &&
									myTeam?.user
										?.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
										)
										.map((item) => {
											const existingEmployeeId = item?.employee?.emp_id || '';
											const existingContractorId =
												head(item?.contractors)?.contractor_id || '';
											const formattedContractorId = formatEmployeeId(
												existingContractorId,
												empIdLength,
												isFixedEmpIdLengthEnabled
											);

											const formattedEmployeeId = formatEmployeeId(
												existingEmployeeId,
												empIdLength,
												isFixedEmpIdLengthEnabled
											);
											return (
												<TableRow className={inlineStyle.customRowStyle}>
													<CustomTableCell component='th' scope='row'>
														<Tooltip
															title={
																item.user_type === 'employee'
																	? item?.employee?.emp_id
																		? `${
																				getPrefix(
																					item?.employee?.employee_prefix?.name,
																					item?.employee?.employee_prefix?.value
																				) || ''
																		  }${formattedEmployeeId || ''}`
																		: '- -'
																	: head(item?.contractors)?.contractor_id
																	? `${
																			getPrefix(
																				head(item?.contractors)
																					?.contractor_prefix?.name,
																				head(item?.contractors)
																					?.contractor_prefix?.value
																			) || ''
																	  }${
																			item?.contractors &&
																			head(item?.contractors)?.contractor_id
																				? formattedContractorId
																				: ''
																	  }`
																	: '- -'
															}
															placement='top'
														>
															<Typography
																className={`${inlineStyle.label} ${styles.ellipsis}`}
															>
																{item.user_type === 'employee'
																	? item?.employee?.emp_id
																		? `${
																				getPrefix(
																					item?.employee?.employee_prefix?.name,
																					item?.employee?.employee_prefix?.value
																				) || ''
																		  }${formattedEmployeeId || ''}`
																		: '--'
																	: head(item?.contractors)?.contractor_id
																	? `${
																			getPrefix(
																				head(item?.contractors)
																					?.contractor_prefix?.name,
																				head(item?.contractors)
																					?.contractor_prefix?.value
																			) || ''
																	  }${
																			item?.contractors &&
																			head(item?.contractors)?.contractor_id
																				? formattedContractorId
																				: ''
																	  }`
																	: '- -'}
															</Typography>
														</Tooltip>
													</CustomTableCell>
													<CustomTableCell component='th' scope='row'>
														<ToolTipProfileCard record={item!} />
													</CustomTableCell>

													<CustomTableCell component='th' scope='row'>
														<Tooltip
															title={
																item.user_type === 'employee'
																	? 'Employee'
																	: 'Contractor'
															}
															placement='top'
														>
															<Typography
																className={`${inlineStyle.label} ${styles.ellipsis}`}
															>
																{item.user_type === 'employee'
																	? 'Employee'
																	: 'Contractor'}
															</Typography>
														</Tooltip>
													</CustomTableCell>
													<CustomTableCell component='th' scope='row'>
														<Tooltip
															title={
																item?.user_type === 'employee'
																	? `${item?.employee?.department?.name}`
																	: `${
																			head(item?.contractors)?.department?.name
																	  }`
															}
															placement='top'
														>
															<Typography
																className={`${inlineStyle.label} ${styles.ellipsis}`}
															>
																{item?.user_type === 'employee'
																	? `${item?.employee?.department?.name}`
																	: `${
																			head(item?.contractors)?.department?.name
																	  }`}
															</Typography>
														</Tooltip>
													</CustomTableCell>
													<CustomTableCell component='th' scope='row'>
														<Tooltip
															title={
																item?.user_type === 'employee'
																	? item?.employee?.job_level?.level || '- -'
																	: head(item?.contractors)?.job_level?.level ||
																	  '- -'
															}
															placement='top'
														>
															<Typography
																className={`${inlineStyle.label} ${styles.ellipsis}`}
															>
																{item?.user_type === 'employee'
																	? item?.employee?.job_level?.level || '- -'
																	: head(item?.contractors)?.job_level?.level ||
																	  '- -'}
															</Typography>
														</Tooltip>
													</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell component='th' scope='row'>
															<Tooltip
																title={
																	item?.user_type === 'employee'
																		? item?.employee?.org_location?.name ||
																		  '- -'
																		: head(item?.contractors)?.org_location
																				?.name || '- -'
																}
																placement='top'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.user_type === 'employee'
																		? item?.employee?.org_location?.name ||
																		  '- -'
																		: head(item?.contractors)?.org_location
																				?.name || '- -'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
													)}
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component='div'
							count={myTeam?.user?.length || 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</TableContainer>
				</Box>
			) : (
				<Typography className={inlineStyle.noData}>
					No Data to Display
				</Typography>
			)}
		</>
	);
};
