import React from 'react';
import { Loading } from 'react-admin';
import { GET_PROJECT, GET_PROJECT_CUSTOM_FIELDS } from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { detailsPageGlobalStyle } from '../../../Layout/styles';
import { Box } from '@material-ui/core';
import ProjectDetailsCard from './ProjectDetailsCard.component';
import ProjectDetailsContainer from './ProjectDetailsContainer.component';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadcumData } from '../constant';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export const ProjectDetails = () => {
	const detailsPageGlobalStyles = detailsPageGlobalStyle();
	const { id }: { id: string } = useParams();
	const {
		data: project,
		loading,
		refetch: refetchProject,
	} = useApolloQuery(GET_PROJECT, {
		variables: {
			projectId: id ? id : null,
		},
		fetchPolicy: 'network-only',
	});
	const { data: projectCustomField } = useApolloQuery(
		GET_PROJECT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);
	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Breadcrum
				data={[...breadcumData, { name: project?.project_by_pk?.name }]}
				icon={DoubleArrowIcon}
			/>
			<Box className={detailsPageGlobalStyles.container}>
				<Box className={detailsPageGlobalStyles.leftPanel}>
					<ProjectDetailsCard
						record={project?.project_by_pk}
						refetch={() => refetchProject()}
					/>
				</Box>

				<Box className={detailsPageGlobalStyles.rightPanel}>
					<ProjectDetailsContainer
						refetchProject={() => {
							refetchProject();
						}}
						project={{ ...project?.project_by_pk, projectCustomField }}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ProjectDetails;
