import React, { useState } from 'react';
import { Loading } from 'react-admin';
import {
	GET_PROJECT,
	GET_PROJECT_CUSTOM_FIELDS,
} from '../../ResourcePlanner/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { detailsPageGlobalStyle } from '../../../Layout/styles';
import { Box } from '@material-ui/core';
import ProjectDetailsContainer from './ProjectDetailsContainer.component';
import ProjectDetailsCard from './ProjectDetailsCard.component';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadCumData } from '../constant';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ProjectCloneDailogModal from './ProjectCloneDailogModal.component';

export const ProjectDetails = () => {
	const [showProjectCloneModal, setShowProjectCloneModal] = useState(false);
	const detailsPageGlobalStyles = detailsPageGlobalStyle();
	const { id }: { id: string } = useParams();
	const {
		data: project,
		loading,
		refetch: refetchProject,
	} = useApolloQuery(GET_PROJECT, {
		variables: {
			projectId: id ? id : null,
		},
		fetchPolicy: 'network-only',
	});

	const { data: projectCustomField } = useApolloQuery(
		GET_PROJECT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	const history = useHistory();
	const projectCloneSuccessHandler = () => {
		history.push('/project');
	};
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<Breadcrum
				data={[...breadCumData, { name: project?.project_by_pk?.name }]}
				icon={DoubleArrowIcon}
				navigateUrl={`/project/tasks/${id}`}
				isNavigationEnabled={true}
				redirectUrl={'/project'}
				showProjectCloneButton={true}
				projectCloneHandler={() => {
					setShowProjectCloneModal(true);
				}}
			/>
			<Box className={detailsPageGlobalStyles.container}>
				<Box className={detailsPageGlobalStyles.leftPanel}>
					<ProjectDetailsCard
						record={{ ...project?.project_by_pk, projectCustomField }}
						refetch={() => refetchProject()}
					/>
				</Box>
				<Box className={detailsPageGlobalStyles.rightPanel}>
					<ProjectDetailsContainer
						refetchProject={() => {
							refetchProject();
						}}
						project={project?.project_by_pk}
					/>
				</Box>
			</Box>
			{showProjectCloneModal && (
				<ProjectCloneDailogModal
					open={showProjectCloneModal}
					projectId={id}
					onClose={() => setShowProjectCloneModal(false)}
					onSuccess={projectCloneSuccessHandler}
				/>
			)}
		</>
	);
};

export default ProjectDetails;
