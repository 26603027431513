import React, { useState, useEffect, useContext } from 'react';
import {
	TextInput,
	useNotify,
	required,
	SelectInput,
	BooleanInput,
	maxLength,
} from 'react-admin';

import { Dialog, Typography, Button, Box, Tooltip } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
	getAllDatesInRange,
	getSaturdaysDates,
	getSundaysBetweenDates,
} from '../../Utils/date-time.util';
import { applyingForLeaveOnNonWorkingDaysErrorMsg } from '../../config/constant';
import { Form, Field } from 'react-final-form';

import {
	ApplyLeaveFormValues,
	OrgLeaves,
	SelectedMonthDates,
} from './Attendance.model';
import { DropdownOption } from '../../SharedComponents/model';

import { modalFormTheme } from '../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../Layout/styles';
import {
	useGetLeaveBalanceQuery,
	useGetEmployeeReporteeQuery,
	useUpsertLeaveMutation,
	useEmployeeLeaveStatusQuery,
	useGetEmployeeDetailsByUserIdQuery,
	useGetUserAllHolidaysAndWeekendsQuery,
} from '../../generated/graphql';
import { LEAVE_MESSAGE } from './Constant';
import { GET_EMPLOYEE_LEAVE_APPROVED_SUBMITTED_COUNT } from './Attendance.gql';
import { useQuery as useApolloQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { head, isEmpty, orderBy } from 'lodash';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../App';
import { formatDecimal } from '../../Utils/string.util';
import ButtonWithLoader from '../../SharedComponents/Btn/ButtonWithLoader';
import { regexNameValidation } from '../Settings/constant';

const leaveFormStyle = makeStyles({
	actionContainer: {
		paddingBottom: '10px',
	},
	checkBoxContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	checkBox: {
		marginRight: '8px',
		width: '14px',
		height: '14px',
	},
	container: {
		width: '600px',
		padding: '20px',
		minHeight: '400px',
		borderRadius: '4px',
	},
	error: {
		color: '#f44336',
	},
	dropdown: {
		fontSize: '12px',
		width: '100px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginBottom: '20px',
		marginLeft: '6px',
		height: '5px',
	},
});

export interface leavesProps {
	approver_id: string;
	leave_count: number;
	from_date: string;
	to_date: string;
	is_half_day?: boolean;
	leave_type_id: string;
	reason: string;
	leaveBalance?: number;
	isNegativeBalanceAllowed?: boolean;
	userId?: string;
}

interface ApplyLeaveProps {
	open: boolean;
	userId: string;
	currentFinancialYear: SelectedMonthDates;
	onClose: () => void;
	refetchLeaveBalance: () => void;
	userLocationId?: string | null;
	editFormValues?: ApplyLeaveFormValues | null | undefined;
	isEdit: boolean;
	isManagerView?: boolean;
}

const ApplyLeaveForm = (props: ApplyLeaveProps) => {
	const {
		open,
		onClose,
		userId,
		currentFinancialYear,
		refetchLeaveBalance,
		editFormValues,
		userLocationId: locationId,
		isEdit,
		isManagerView = false,
	} = props;
	const [organizationLeaves, setOrganizationLeaves] = useState<
		OrgLeaves[] | undefined
	>();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [isHalfDay, setIsHalfDay] = useState(false);
	const [startDateGreaterError, setStartDateGreaterError] = useState(false);
	const [leaveApproverOptions, setLeaveApproverOptions] =
		useState<DropdownOption[]>();
	const [leaveApproverId, setLeaveApproverId] = useState();
	const [leaveTypeId, setLeaveTypeId] = useState<string | null>(null);
	const [leaveCount, setLeaveCount] = useState(0);
	const [employeeLeaveSubmittedId, setEmployeeLeaveSubmittedId] = useState();
	const [employeeId, setEmployeeId] = useState<string | null>(null);
	const [selectedDateOverflow, setSelectedDateOverflow] = useState(false);
	const [isNegativeBalanceAllowed, setIsNegativeBalanceAllowed] =
		useState(false);
	const [totalLeaveDays, setTotalLeaveDays] = useState(0);
	const [hasZeroDays, setHasZeroDays] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const classes = modalFormStyle();
	const leaveFormStyles = leaveFormStyle();
	const ellipsis = ellipsisStyle();
	const notify = useNotify();
	const { dateFormat, orgId, noOfLocations } =
		useContext<any>(UserProfileContext);

	const { data: orgHolidays } = useGetUserAllHolidaysAndWeekendsQuery({
		variables: {
			orgId,
			filter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: startDate,
								_lte: endDate,
							},
							location_id: { _eq: locationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: startDate,
								_lte: endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: startDate,
									_lte: endDate,
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: startDate,
									_lte: endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
	});

	const { data: orgLeaves } = useGetLeaveBalanceQuery({
		variables: {
			employeeId,
			startDate: currentFinancialYear?.startDate,
			endDate: currentFinancialYear?.endDate,
		},
	});

	const { data: reportee } = useGetEmployeeReporteeQuery({
		variables: {
			userId: isManagerView ? editFormValues?.userId : userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: employeeLeaveStatus } = useEmployeeLeaveStatusQuery({});

	const { data: employee } = useGetEmployeeDetailsByUserIdQuery({
		variables: { userId: isManagerView ? editFormValues?.userId : userId },
	});
	const [upsertLeaveMutation, { loading: isLeaveMutationLoading }] =
		useUpsertLeaveMutation();

	const { data: employeeLeaveCount } = useApolloQuery(
		GET_EMPLOYEE_LEAVE_APPROVED_SUBMITTED_COUNT,
		{
			variables: {
				userId: employeeId,
				startDate,
				endDate,
			},
			skip: !(startDate && endDate),
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (isEmpty(employeeLeaveStatus?.employee_leave_status)) {
			return;
		}
		const response = employeeLeaveStatus?.employee_leave_status?.find(
			(leaveStatus) => leaveStatus.value === 'submitted'
		);
		setEmployeeLeaveSubmittedId(response?.id);
	}, [employeeLeaveStatus]);

	useEffect(() => {
		if (!employee) {
			return;
		}
		setEmployeeId(head(employee?.employee)?.id || null);
	}, [employee]);

	useEffect(() => {
		if (!leaveCount || !totalLeaveDays) {
			return;
		}
		const dateOverflows =
			isNegativeBalanceAllowed && totalLeaveDays > leaveCount
				? false
				: totalLeaveDays > leaveCount
				? true
				: false;
		setSelectedDateOverflow(dateOverflows);
	}, [totalLeaveDays, leaveCount, isNegativeBalanceAllowed]);

	useEffect(() => {
		if (isEmpty(reportee?.user)) {
			return;
		}
		const response = head(reportee?.user)?.employee;
		if (isEmpty(response?.reportsTo)) {
			return;
		}
		setLeaveApproverId(response?.reportsTo?.id);
		const data = [
			{
				id: response?.reportsTo?.id,
				name: `${response?.reportsTo?.full_name} `,
			},
		];
		setLeaveApproverOptions(data);
	}, [reportee]);

	useEffect(() => {
		if (isEmpty(orgLeaves?.org_leave_group)) {
			return;
		}
		const response = head(orgLeaves?.org_leave_group)?.org_leave_types?.map(
			(value) => {
				const appliedLeaveCount = value?.employee_leaving_mappings?.reduce(
					(sum, number) => {
						return number?.leave_count + sum;
					},
					0
				);
				const accruedLeaveCount =
					value?.accrued_leave_type?.length > 0
						? value?.accrued_leave_type?.reduce((sum, number) => {
								return number?.count + sum;
						  }, 0)
						: 0;
				const openingBalance =
					value?.employee_leave_type_mappings?.length > 0
						? head(value?.employee_leave_type_mappings)?.opening_balance
						: '--';
				const leaveBalance =
					accruedLeaveCount + openingBalance - Number(appliedLeaveCount) || 0;
				return {
					id: value?.id,
					name: `${value?.leave_type}(${
						leaveBalance ? formatDecimal(leaveBalance, 3) : 0
					})`,
					disabled:
						leaveBalance <= 0 && value?.is_negative_bal_allowed
							? false
							: leaveBalance <= 0
							? true
							: false,
					count: leaveBalance || 0,
				};
			}
		);

		const sortedResponse = orderBy(response, ['name'], ['asc']);
		setOrganizationLeaves(sortedResponse);
	}, [orgLeaves]);

	useEffect(() => {
		if (!editFormValues) {
			return;
		}
		setStartDate(editFormValues?.from_date);
		setEndDate(editFormValues?.to_date);
		setIsHalfDay(editFormValues?.is_half_day || false);
		setIsNegativeBalanceAllowed(
			editFormValues?.isNegativeBalanceAllowed || false
		);
		setLeaveTypeId(editFormValues?.leave_type_id || null);
	}, [editFormValues]);

	useEffect(() => {
		if (!(startDate && endDate)) {
			return;
		}
		if (isHalfDay) {
			setIsHalfDay(startDate === endDate);
		}
		const toDate = dayjs(endDate);
		const error = dayjs(startDate).isAfter(endDate);
		setStartDateGreaterError(error);

		const leaveTypeInfo = head(
			orgLeaves?.org_leave_group
		)?.org_leave_types?.filter((leave) => {
			if (leave.id === leaveTypeId) {
				return leave;
			}
		});

		const isHolidaysAndWeekendsExcluded =
			head(leaveTypeInfo)?.is_leave_sandwich_type ?? false;

		const calculateLeaveDayCount = (): number => {
			const leaveDates = getAllDatesInRange(startDate, endDate);
			if (!leaveDates.length) return -1;

			const generalHolidays =
				orgHolidays?.org_holidays?.map((holiday) => holiday?.date) || [];
			const optionalHolidays =
				orgHolidays?.user_optional_holiday_mapping?.map(
					(optionalHoliday) => optionalHoliday?.org_holiday?.date
				) || [];

			// To-Do : We need to handle it like Calender View
			const allHolidayDates =
				!locationId && noOfLocations && noOfLocations > 0
					? []
					: [...generalHolidays, ...optionalHolidays];

			const isSundayAvailable = head(
				orgHolidays?.organization
			)?.weekend?.includes('SUN');
			const sundayAsWeekend = getSundaysBetweenDates(startDate, endDate);

			const availableSaturdays = head(orgHolidays?.organization)
				?.weekend?.filter((weekendDay: string) => weekendDay !== 'SUN')
				?.map((sat: string) => sat?.charAt(sat.length - 1));

			const allSaturdaysBetweenDates = getSaturdaysDates(startDate, endDate);

			const saturdayAsWeekend = allSaturdaysBetweenDates
				?.map((day) =>
					availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
				)
				?.filter((date) => date !== '');

			let numberOfDays = 0;

			leaveDates.forEach((leaveDate) => {
				let isHoliday = allHolidayDates?.includes(leaveDate);
				let isSunday = sundayAsWeekend?.includes(leaveDate);
				let isSaturday = saturdayAsWeekend?.includes(leaveDate);
				if (
					(isSundayAvailable && !isHoliday && !isSunday && !isSaturday) ||
					(!isSundayAvailable && !isHoliday && !isSaturday)
				) {
					numberOfDays++;
				}
			});

			return numberOfDays;
		};

		const halfDayValue = !isHolidaysAndWeekendsExcluded
			? 0.5
			: calculateLeaveDayCount() === 1
			? 0.5
			: 0;

		const leaveDateCount = isHalfDay
			? halfDayValue
			: isHolidaysAndWeekendsExcluded
			? calculateLeaveDayCount()
			: toDate.diff(startDate, 'days') + 1;

		if (isHalfDay && !leaveDateCount) {
			setIsHalfDay(!isHalfDay);
		}
		if (leaveDateCount >= 0) {
			setHasZeroDays(isHolidaysAndWeekendsExcluded && leaveDateCount === 0);
			setTotalLeaveDays(leaveDateCount);
		}
	}, [
		leaveTypeId,
		orgLeaves,
		startDate,
		endDate,
		isHalfDay,
		orgHolidays,
		locationId,
		noOfLocations,
	]);

	const handleSave = (formValues: leavesProps) => {
		if (!isEmpty(employeeLeaveCount?.employee_leaving_mapping)) {
			const duplicateLeaves =
				employeeLeaveCount?.employee_leaving_mapping?.filter(
					(leave: { id: string }) => leave?.id !== editFormValues?.id
				) || [];
			if (!isEmpty(duplicateLeaves)) {
				notify('Already Leave is applied on this date', 'warning');
				return;
			}
		}
		const {
			reason,
			leaveBalance,
			isNegativeBalanceAllowed,
			userId,
			...restFormvalues
		} = formValues;
		upsertLeaveMutation({
			variables: {
				leave: {
					...restFormvalues,
					reason: reason.trim(),
					employee_id: employeeId,
					employee_leave_status_id: employeeLeaveSubmittedId,
					approver_id: leaveApproverId,
					leave_count: totalLeaveDays,
				},
			},
		}).then((data) => {
			if (data) {
				refetchLeaveBalance();
				notify(
					isEdit ? 'Leave Updated Successfully' : 'Leave Applied Successfully'
				);
				handleClose();
			}
		});
	};

	const handleClose = () => {
		onClose();
		setStartDate('');
		setEndDate('');
		setTotalLeaveDays(0);
		setStartDateGreaterError(false);
		setStartDateError(false);
		setEndDateError(false);
		setSelectedDateOverflow(false);
		setLeaveTypeId(null);
		setIsHalfDay(false);
		setHasZeroDays(false);
		setIsNegativeBalanceAllowed(false);
	};

	const getLeaveCount = (typeId: string) => {
		const leave = head(orgLeaves?.org_leave_group)?.org_leave_types?.find(
			(leaveType) => leaveType?.id === typeId
		);
		if (!leave) {
			return 0;
		}
		const accruedCount =
			leave?.accrued_leave_type?.length > 0
				? leave?.accrued_leave_type?.reduce((sum, number) => {
						return number?.count + sum;
				  }, 0)
				: 0;
		const employeeLeaveTypeCount =
			leave?.employee_leave_type_mappings?.length > 0
				? head(leave?.employee_leave_type_mappings)?.opening_balance
				: 0;
		const appliedLeaveCount = leave?.employee_leaving_mappings?.reduce(
			(sum, number) => {
				return number?.leave_count + sum;
			},
			0
		);
		const balance =
			accruedCount + employeeLeaveTypeCount - Number(appliedLeaveCount) || 0;
		setLeaveCount(balance || 0);
		setIsNegativeBalanceAllowed(leave?.is_negative_bal_allowed);
		return balance;
	};
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};
	const getEditFormValuesForInitialization = (
		editFormValues: ApplyLeaveFormValues | null
	) => {
		if (!editFormValues) {
			return;
		}
		const {
			is_half_day,
			from_date,
			to_date,
			leave_type_id,
			userId,
			...restFormValues
		} = editFormValues;
		return {
			...restFormValues,
			is_half_day: isHalfDay,
			approver_id: leaveApproverId,
			from_date: startDate,
			to_date: endDate,
			leave_type_id: leaveTypeId,
		};
	};
	return (
		<Box>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={leaveFormStyles.container}>
						<Box className={classes.headerContainer}>
							<Typography className={classes.heading}>APPLY LEAVE</Typography>
							<CloseIcon className={classes.closeIcon} onClick={handleClose} />
						</Box>
						<Form
							onSubmit={handleSave}
							initialValues={
								isEdit
									? getEditFormValuesForInitialization(editFormValues || null)
									: {
											approver_id: leaveApproverId,
											leave_count: totalLeaveDays,
											from_date: startDate,
											to_date: endDate,
											is_half_day: isHalfDay,
											leave_type_id: leaveTypeId,
									  }
							}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Box className={classes.multipleInputContainer}>
											<Box width='30%'>
												<Typography className={classes.label}>
													From Date *
												</Typography>
												<Field
													name='from_date'
													validate={(value: any) => {
														if (!value) {
															return 'Required';
														}
														if (dayjs(value).isValid() === true) {
															return undefined;
														}
														return 'Invalid Date';
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	if (!value) {
																		setStartDateError(true);
																		return;
																	}
																	setStartDateError(false);
																	setLeaveTypeId(null);
																	setStartDate(value);
																}}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
												{startDateGreaterError && (
													<p className={leaveFormStyles.errorText}>
														Start date must not be greater than end date!
													</p>
												)}
												{startDateError && (
													<p className={leaveFormStyles.errorText}>Required</p>
												)}
											</Box>
											<Box width='30%'>
												<Typography className={classes.label}>
													To Date *
												</Typography>
												<Field
													name='to_date'
													validate={(value: any) => {
														if (!value) {
															return 'Required';
														}
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														}
														return 'Invalid Date';
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	if (!value) {
																		setEndDateError(true);
																		return;
																	}
																	setLeaveTypeId(null);
																	setEndDate(value);
																	setEndDateError(false);
																}}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
												{endDateError && (
													<p className={leaveFormStyles.errorText}>Required</p>
												)}
											</Box>
											<Box width='28%'>
												<Typography className={classes.label}>
													Number Of Days
												</Typography>
												<div>{isHalfDay ? 0.5 : totalLeaveDays}</div>
												{hasZeroDays && (
													<p className={leaveFormStyles.errorText}>
														{applyingForLeaveOnNonWorkingDaysErrorMsg}
													</p>
												)}
											</Box>
										</Box>
										<Box display='flex' justifyContent='space-between'>
											<Box>
												<Typography className={classes.label}>
													Half Day
												</Typography>
												<BooleanInput
													source='is_half_day'
													label=''
													onChange={(event) => {
														setLeaveTypeId(null);
														if (startDate === endDate) {
															setIsHalfDay(event);
															return;
														}
														setIsHalfDay(false);
													}}
													disabled={startDate !== endDate || hasZeroDays}
												/>
											</Box>
											<Box width='11%'></Box>
											<Box width='30%'>
												<Box className={classes.label}>Leave Type *</Box>
												<SelectInput
													source='leave_type_id'
													label={false}
													fullWidth
													choices={organizationLeaves || []}
													validate={required()}
													onChange={(event: any) => {
														if (!event) {
															return;
														}
														getLeaveCount(event?.target?.value);
														setLeaveTypeId(event?.target?.value);
													}}
												/>
												{selectedDateOverflow && leaveTypeId && (
													<p className={leaveFormStyles.errorText}>
														{LEAVE_MESSAGE}
													</p>
												)}
											</Box>
											<Box width='150px'>
												<Typography className={classes.label}>
													Leave Approver *
												</Typography>
												<SelectInput
													source='approver_id'
													label={false}
													fullWidth
													choices={leaveApproverOptions || []}
													validate={required()}
													optionText={(choice: any) => (
														<Tooltip
															title={`${choice?.name || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${ellipsis.ellipsis} ${leaveFormStyles.dropdown}`}
															>
																{`${choice?.name || '- -'}`}
															</Typography>
														</Tooltip>
													)}
												/>
											</Box>
										</Box>
										<Box>
											<Typography className={classes.label}>
												Reason *
											</Typography>
											<TextInput
												source='reason'
												fullWidth={true}
												label={false}
												validate={[validateName, maxLength(200)]}
												multiline
											/>
										</Box>
										<Box
											display='flex'
											justifyContent='flex-end'
											alignItems='center'
										>
											<Button
												onClick={handleClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<ButtonWithLoader
												isDisabled={
													invalid ||
													startDateGreaterError ||
													hasZeroDays ||
													selectedDateOverflow ||
													isLeaveMutationLoading
												}
												title='Save'
												isLoading={isLeaveMutationLoading}
											/>
										</Box>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
		</Box>
	);
};

export default ApplyLeaveForm;
