import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectInput, TextInput, required, ReferenceInput } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { head, uniq } from 'lodash';
import { useMutation as useApolloMutation } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Typography, Button, Box, Switch } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { INACTIVATE_EMPLOYEE } from './gqlQueries';
import {
	useEmployeeLeavingReasonQuery,
	useGetEmployeeAllocationEndDatesQuery,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';
import AllocationWarningModal from '../../Company/DetailsPage/AllocationWarningModal';

interface EmployeeInActiveFormProps {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	userId: string;
	last_date: string;
	noticePeriodStatus: boolean;
	employeeId: string;
}

interface LeavingReason {
	reason_id?: string;
	notes?: string | null;
	is_rehire_eligible?: boolean;
	user_id?: string;
	last_date?: string;
	id?: string;
}

export const EmployeeInActiveForm = ({
	open,
	onClose,
	onSuccess,
	userId,
	last_date,
	noticePeriodStatus,
	employeeId,
}: EmployeeInActiveFormProps) => {
	const classes = modalFormStyle();
	const { id }: { id: string } = useParams();
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [updateEmployee, { loading: isEmployeeLoading }] =
		useApolloMutation(INACTIVATE_EMPLOYEE);
	const [isContractEligible, setIsContractEligible] = useState(true);
	const [allocationWarningModal, setAllocationWarningModal] = useState(false);
	const [lastWorkingDate, setLastWorkingDate] = useState('');

	const [projectName, setProjectName] = useState<string[]>();

	const [employeeLeavingReasonForm, setEmployeeReasonForm] =
		useState<LeavingReason>();

	const { data: employeeLeavingReason, refetch: refetchEmployeeLeavingReason } =
		useEmployeeLeavingReasonQuery({
			variables: {
				user_id: userId ? userId : null,
			},
		});

	const { data: employeeAllocationDates } =
		useGetEmployeeAllocationEndDatesQuery({
			variables: {
				id: employeeId,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (!employeeAllocationDates) {
			return;
		}
		const allocatedProjects: string[] | undefined =
			employeeAllocationDates?.employee_by_pk?.user?.project_resource_mappings
				?.filter((value) => value?.end_date > lastWorkingDate)
				.map((value) => value?.project?.name);
		setProjectName(uniq(allocatedProjects) || []);
	}, [employeeAllocationDates, lastWorkingDate]);

	useEffect(() => {
		const employee = employeeLeavingReason?.employee_leaving_reason;
		if (!employee || !(employee?.length > 0)) {
			setEmployeeReasonForm({
				last_date: last_date,
				is_rehire_eligible: true,
			});
			return;
		}
		const reason = head(employeeLeavingReason?.employee_leaving_reason);
		setEmployeeReasonForm({
			reason_id: reason?.reason_id,
			notes: reason?.notes,
			is_rehire_eligible: reason?.is_rehire_eligible,
			last_date: last_date,
			id: reason?.id,
		});
	}, [employeeLeavingReason, last_date]);

	const saveInactiveFormData = (formValue: any) => {
		setLastWorkingDate(formValue?.last_date);
		const endDates =
			employeeAllocationDates?.employee_by_pk?.user?.project_resource_mappings?.map(
				(value) => {
					return dayjs(value?.end_date)?.isAfter(dayjs(formValue?.last_date));
				}
			);
		if (endDates?.includes(true)) {
			setAllocationWarningModal(true);
			return;
		}

		updateEmployee({
			variables: {
				emp_id: id,
				data: {
					id: formValue?.id,
					reason_id: formValue?.reason_id,
					notes: formValue?.notes,
					is_rehire_eligible: isContractEligible,
					user_id: userId,
				},
				empData: {
					last_date: formValue?.last_date,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				refetchEmployeeLeavingReason();
				onSuccess();
				onClose();
			}
		});
	};

	const validateDate = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (dayjs(value).isValid() === true || value === null) {
			return undefined;
		} else {
			return 'Invalid Date';
		}
	};

	return (
		// TODO After Datamodel creation we need to integrate this Inactive form to store the reason for leaving on database...
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>ADDITIONAL INFO</Typography>
						<CloseIcon onClick={onClose} className={classes.closeIcon} />
					</Box>

					{!noticePeriodStatus ? (
						<Box className={classes.infoContentContainer}>
							<Typography className={classes.warningContent}>
								Please note the user will loose the access to the system. This
								change is not reversible.
							</Typography>
						</Box>
					) : null}
					<Form
						initialValues={employeeLeavingReasonForm}
						onSubmit={saveInactiveFormData}
					>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>
										Reason for Leaving*
									</Typography>
									{/* TODO After table creation for reason we should fetch these options from database */}
									<ReferenceInput
										source='reason_id'
										reference='leaving_reason'
										label=''
										validate={required()}
									>
										<SelectInput optionText='label' fullWidth />
									</ReferenceInput>
									<Typography className={classes.label}>
										Last Working Day*
									</Typography>
									<Field name='last_date' validate={validateDate}>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={props.input.onChange}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>

									<Typography className={classes.label}>Notes</Typography>
									<TextInput
										multiline
										source='notes'
										label=''
										placeholder='Enter the text'
									/>

									<Box className={classes.eligibleContainer}>
										<Typography className={classes.radioButtonLabel}>
											Eligible for Rehire
										</Typography>
										<Switch
											color='primary'
											name='is_rehire_eligible'
											defaultChecked={
												employeeLeavingReasonForm?.is_rehire_eligible
													? true
													: false
											}
											onChange={(event: any) => {
												setIsContractEligible(event?.target?.checked);
											}}
										/>
									</Box>
									<Box className={classes.buttonContainer}>
										<Button onClick={onClose} className={classes.cancelButton}>
											Cancel
										</Button>
										<Button
											className={
												invalid || isEmployeeLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isEmployeeLoading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
			<AllocationWarningModal
				open={allocationWarningModal}
				onClose={() => {
					setAllocationWarningModal(false);
				}}
				projectName={projectName}
				warningMessage={`${'Cannot inactivate user due to project allocations.\nPlease remove the  user from the allocations.'}`}
			/>
		</ThemeProvider>
	);
};

export default EmployeeInActiveForm;
