import React from 'react';
import {
	Box,
	ThemeProvider,
	Card,
	CardHeader,
	CardContent,
	Typography,
} from '@material-ui/core';
import { insightChartTheme } from '../Layout/Theme.component';
import { insightViewStyle } from '../Layout/styles';

interface Props{
	title?: string;
}
const NoDataFound = (props: Props) => {
	const { title} = props
	const classes = insightViewStyle();

	return (
		<ThemeProvider theme={insightChartTheme}>
			{title ? (
				<Card>
					<CardHeader
						title={title}
						titleTypographyProps={{ variant: 'h6' }}
					/>
					<CardContent>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							height='100%'
							className={classes.countValue}
						>
							No data available
						</Box>
					</CardContent>
				</Card>
			) : (
				<Box
					justifyContent='center'
					alignItems='center'
					display='flex'
					height='100px'
					width='100px'
				>
					<Typography
						style={{ fontFamily: 'Manrope-medium', fontSize: '14px' }}
					>
						No data found
					</Typography>
				</Box>
			)}
		</ThemeProvider>
	);
}
;

export default NoDataFound;
