import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
	TextInput,
	ReferenceInput,
	SelectInput,
	useNotify,
	useRefresh,
} from 'react-admin';

import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import {
	ADD_CRM_TASK,
	GET_CRM_TASK_ACTIVITIES,
	GET_USERS,
} from '../Opportunities.gql';
import { UPDATE_CRM_TASK } from '../../CrmTask/CrmTasks.gql';
import dayjs from 'dayjs';
import { UserProfileContext } from '../../../App';
import {
	CrmActivity,
	Task,
	TaskFormValues,
	kanbanBoardInvitedUser,
} from './modal';
import {
	EditorState,
	convertFromHTML,
	ContentState,
	convertToRaw,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import draftToHtml from 'draftjs-to-html';
import '@draft-js-plugins/mention/lib/plugin.css';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';

import Dialog from '@material-ui/core/Dialog';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Typography,
	makeStyles,
	Divider,
	InputAdornment,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { Form } from 'react-final-form';
import {
	DEFAULT_REMINDER_TIME,
	SUGGESTION_DEFAULT_PIC,
} from '../../../config/constant';
import { debounce, head, orderBy } from 'lodash';
import {
	decorator,
	plugins,
	MentionSuggestions,
	customEntityTransform,
} from '../../../Utils/draftjs-util';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';

const taskFormStyle = makeStyles({
	container: {
		width: '49vw',
		padding: '20px',
		minHeight: '500px',
		borderRadius: '4px',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '54px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
});

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
			input: {
				padding: '6px 0px 0px',
			},
		},
		MuiInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});
interface KanbanColumn {
	id: string;
	name: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	opportunity_id?: string;
	kanbanId?: string;
	kanbanColumn?: KanbanColumn[];
	refetch: () => void;
	CrmTask?: Task;
	boardId?: string | undefined;
}

const CrmTaskForm = (props: Props) => {
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const { open, onClose, refetch, opportunity_id, CrmTask, boardId } = props;
	const [searchedUser, setSearchedUser] = useState('');
	const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [assignedTo, setAssignedTo] = useState<any>([]);
	const [taskAssignedTo, setTaskAssignedTo] = useState<string | null>(null);
	const [dueDate, setDueDate] = useState(
		dayjs()
			.add(1, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString()
	);
	const classes = modalFormStyle();
	const taskFormStyles = taskFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [taskFormInitialValues, setTaskFormInitialValues] =
		useState<TaskFormValues>({
			priority: 'Medium',
			remind_at: 1,
		});

	const { data: users } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%${searchedUser}%`,
			boardId,
		},
		skip: searchedUser?.length < 2,
	});

	const { data: assignedUsers } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%%`,
			boardId,
		},
		fetchPolicy: 'network-only',
	});
	const [addCrmTask, { loading: isAddTaskLoading }] =
		useApolloMutation(ADD_CRM_TASK);
	const [updateCrmTask, { loading: isEditTaskLoading }] =
		useApolloMutation(UPDATE_CRM_TASK);
	const { data: crmTaskActivities } = useApolloQuery(GET_CRM_TASK_ACTIVITIES);

	useEffect(() => {
		if (!users) {
			return;
		}
		const userSuggestion = users.kanban_board_invited_users.map(
			(user: kanbanBoardInvitedUser) => ({
				id: user.user.id,
				name: `${user?.user.full_name || ''} `,
				link: user.user.id,
				avatar: user?.user.profile_pic || SUGGESTION_DEFAULT_PIC,
			})
		);
		setMentionSuggestions(userSuggestion);
	}, [users]);

	useEffect(() => {
		if (!assignedUsers) {
			return;
		}
		const userSuggestion = assignedUsers.kanban_board_invited_users.map(
			(user: kanbanBoardInvitedUser) => {
				return {
					id: user.user?.id,
					name: `${user?.user?.full_name} `,
				};
			}
		);
		const sortedUserSuggestion: any = orderBy(
			userSuggestion,
			[(user) => user.name],
			['asc']
		);
		setAssignedTo(sortedUserSuggestion);
	}, [assignedUsers, boardId]);

	useEffect(() => {
		if (!CrmTask?.id || !CrmTask?.name) {
			const crmActivity = crmTaskActivities?.crm_task_activity_type?.find(
				(crmTaskActivity: CrmActivity) => crmTaskActivity.value === 'todo'
			);
			setEditorState(EditorState.createEmpty());
			setDueDate(
				dayjs()
					.add(1, 'day')
					.set('hour', DEFAULT_REMINDER_TIME.hour)
					.set('minute', DEFAULT_REMINDER_TIME.minutes)
					.set('second', DEFAULT_REMINDER_TIME.seconds)
					.toISOString()
			);
			setTaskFormInitialValues({
				priority: 'Medium',
				remind_at: 1,
				activity_type_id: crmActivity?.id,
			});
			return;
		}
		const { name, ...restCrmTask } = CrmTask;
		const blocksFromHTML = convertFromHTML(CrmTask?.name);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		setEditorState(EditorState.createWithContent(state, decorator));
		setTaskAssignedTo(CrmTask?.owner_id || null);
		setTaskFormInitialValues((prev) => ({
			...prev,
			...restCrmTask,
			remind_at: getReminderOptionValue(CrmTask?.due_date, CrmTask?.remind_at),
		}));
		if (CrmTask?.due_date) {
			setDueDate(CrmTask?.due_date);
		}
	}, [CrmTask, crmTaskActivities]);

	const getReminderDate = (dueDate?: string, numberOfDaysToReduce?: number) => {
		if (!dueDate || !numberOfDaysToReduce) {
			return;
		}
		const reminderDate = dayjs(dueDate)
			.subtract(numberOfDaysToReduce, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString();
		return reminderDate;
	};

	const onSaveTask = (task: TaskFormValues) => {
		if (CrmTask?.id) {
			UpdateTask(task);
		} else {
			addTask(task);
		}
	};

	const getMentions = (editorBlock: any) => {
		if (!editorBlock) {
			return;
		}
		let mentions = [];
		for (let key in editorBlock.entityMap) {
			const entity = editorBlock.entityMap[key];
			if (entity.type === 'mention') {
				mentions.push(entity.data.mention);
			}
		}
		return mentions;
	};

	const getHtmlFormat = async () => {
		if (!editorState) {
			return;
		}
		const contentState = editorState.getCurrentContent();
		const raw = convertToRaw(contentState);
		const mentions = await getMentions(raw);
		const html = draftToHtml(raw, {}, false, customEntityTransform);
		return {
			content: html,
			mentionedUser: mentions,
		};
	};

	const UpdateTask = (task: TaskFormValues) => {
		if (!editorState.getCurrentContent().getPlainText().trim()) {
			notify('Please enter task', 'warning');
			return;
		}
		getHtmlFormat().then((response) => {
			if (!response) {
				return;
			}
			updateCrmTask({
				variables: {
					taskId: CrmTask?.id,
					kanbanCardId: CrmTask?.kanbanCardId,
					crmTask: {
						name: response?.content,
						description: task?.description || null,
						opportunity_id: CrmTask?.opportunity_id,
						priority: task?.priority,
						due_date: dueDate,
						activity_type_id: task?.activity_type_id,
						owner_id:
							head(response?.mentionedUser)?.id || taskAssignedTo || null,
						remind_at: getReminderDate(dueDate, task?.remind_at),
					},
					tags: task?.tag_id?.map((tagId: string) => {
						return {
							crm_task_id: CrmTask?.id,
							tag_id: tagId,
						};
					}),
				},
			})
				.then((response: any) => {
					if (!response.error) {
						setEditorState(() => EditorState.createEmpty());
						setTaskAssignedTo(null);
						refetch();
						notify('Tasks updated successfully');
						onClose();
						refresh();
					}
				})
				.catch((error: any) => {
					if (error) {
						return;
					}
				});
		});
	};

	const addTask = (value: TaskFormValues) => {
		if (!editorState.getCurrentContent().getPlainText().trim()) {
			notify('Please enter task', 'warning');
			return;
		}
		getHtmlFormat().then((response) => {
			if (!response) {
				return;
			}
			addCrmTask({
				variables: {
					data: {
						name: response.content,
						description: value?.description,
						opportunity_id: opportunity_id,
						priority: value?.priority,
						due_date: dueDate,
						activity_type_id: value?.activity_type_id,
						owner_id:
							head(response?.mentionedUser)?.id || taskAssignedTo || userId,
						remind_at: getReminderDate(dueDate, value?.remind_at),
						crm_task_tag_mappings: {
							data: value?.tag_id?.map((tag) => ({ tag_id: tag })) || [],
						},
					},
				},
			})
				.then((response) => {
					if (!response.errors) {
						setEditorState(() => EditorState.createEmpty());
						setTaskAssignedTo(null);
						refetch();
						notify('Tasks created successfully');
						onClose();
						refresh();
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	const getReminderOptionValue = (dueDate?: string, reminderAt?: string) => {
		if (!dueDate || !reminderAt) {
			return;
		}
		const endDueDate = dayjs(dueDate);
		const reminderDate = dayjs(reminderAt);
		const reminderValue = endDueDate.diff(reminderDate, 'day');
		return reminderValue;
	};

	const setSearchedUserValue = useCallback(
		debounce((newValue) => setSearchedUser(newValue), 500),
		[]
	);

	const onSearchChange = ({ value }: { value: string }) => {
		if (!value || value.length < 2) {
			return;
		}
		setSearchedUserValue(value);
	};

	const getAssigneValue = (userId: string) => {
		const response = assignedTo?.find((value: any) => value?.id === userId);
		return response ? { id: response?.id, name: `${response?.name}` } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={taskFormStyles.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{CrmTask?.id ? 'Edit Task' : 'Add Task'}
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={onClose} />
					</Box>
					<Form initialValues={taskFormInitialValues} onSubmit={onSaveTask}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Task*</Typography>
									<Box className={taskFormStyles.editor}>
										<Editor
											plugins={plugins}
											editorState={editorState}
											onChange={(editorState) => {
												if (!editorState) {
													return;
												}
												setEditorState(editorState);
											}}
										/>
										<MentionSuggestions
											open={isSuggestionOpen}
											onSearchChange={onSearchChange}
											suggestions={mentionSuggestions}
											onOpenChange={(event: any) => {
												setIsSuggestionOpen(event);
											}}
										/>
									</Box>
									<Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Due Date
												</Typography>
												<ThemeProvider theme={dateTimePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DateTimePicker
															variant='inline'
															name='due_date'
															value={dueDate}
															format={`${dateFormat} h:mm A`}
															onChange={(e) => {
																if (!e) {
																	return;
																}
																setDueDate(e?.toISOString());
															}}
															label=''
															showTodayButton
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<IconButton>
																			<EventIcon />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Reminder
												</Typography>
												<SelectInput
													source='remind_at'
													label=''
													choices={[
														{ id: 1, name: '1 day before' },
														{ id: 3, name: '3 days before' },
														{ id: 7, name: '1 week before' },
													]}
												/>
											</Box>
										</Box>
									</Box>
									<Divider />
									<Box mt={1} display='flex' justifyContent='space-between'>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>Type</Typography>
											<ReferenceInput
												source='activity_type_id'
												reference='crm_task_activity_type'
												label=''
												sort={{ field: 'label', order: 'ASC' }}
											>
												<SelectInput optionText='label' />
											</ReferenceInput>
										</Box>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>
												Priority
											</Typography>
											<SelectInput
												source='priority'
												label=''
												fullWidth={true}
												choices={[
													{ id: 'Low', name: 'Low' },
													{ id: 'Medium', name: 'Medium' },
													{ id: 'High', name: 'High' },
												]}
											/>
										</Box>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>
												Assigned to
											</Typography>
											<AutocompleteSearch
												placeholder={'Search user'}
												option={assignedTo || []}
												onChange={setTaskAssignedTo}
												value={getAssigneValue(taskAssignedTo || '')}
												name={'owner_id'}
												validates={false}
											/>
											{/* <ReferenceInput
												source='owner_id'
												reference='user'
												label=''
												filterToQuery={(searchText) => ({
													first_name: searchText,
													id: assignedTo,
												})}
												sort={{ field: 'first_name', order: 'ASC' }}
												filter={{ first_name: true }}
												fullWidth
											>
												<AutocompleteInput
													resettable={true}
													style={{ paddingTop: '0px' }}
													optionText={mapToFullName}
													shouldRenderSuggestions={(name: string) => {
														return name.trim().length > 0;
													}}
												/>
											</ReferenceInput> */}
										</Box>
									</Box>

									<Typography className={classes.label}>Notes</Typography>
									<TextInput multiline source='description' label='' />
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										className={
											invalid ||
											!editorState.getCurrentContent().getPlainText().trim()
												? [
														classes.disabledButton,
														taskFormStyles.saveButton,
												  ].join(' ')
												: [classes.saveButton, taskFormStyles.saveButton].join(
														' '
												  )
										}
										disabled={
											invalid ||
											isAddTaskLoading ||
											isEditTaskLoading ||
											!editorState.getCurrentContent().getPlainText().trim()
										}
										type='submit'
									>
										{taskFormInitialValues.id ? 'Update' : 'Create'}
									</Button>
									<Button onClick={onClose} className={classes.cancelButton}>
										Cancel
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CrmTaskForm;
