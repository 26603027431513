import React, { useState, useContext, useEffect } from 'react';
import {
	Datagrid,
	List,
	FunctionField,
	Loading,
	ReferenceField,
	SelectInput,
} from 'react-admin';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import * as XLSX from 'xlsx';

import { GET_RESOURCE_MAPPING_LIST } from './gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';

import {
	ThemeProvider,
	TextField as TextComponent,
	Box,
	makeStyles,
	Typography,
	Tooltip,
	IconButton,
	Chip,
	InputAdornment,
	createTheme,
	withStyles,
	Button,
	Checkbox,
	TextField as Field,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
	searchBarTheme,
} from '../../Layout/Theme.component';
import { InsightsIcon, SearchIcon } from '../../assets/SvgIcons';
import { DateTimeUtil } from '../../Utils/date-time.util';
import ResourceAvailableDetailsModal from './ResourceAvailableDetailsModal.component';
import dayjs from 'dayjs';
import { GET_ORGANISATION_FINANICIAL_YEAR } from '../gqlQueries';
import { UserProfileContext } from '../../App';
import {
	globalStyles,
	labelStyle,
	ellipsisStyle,
	autocompleteStyle,
	dropDownEllipsisStyle,
} from '../../Layout/styles';
import ResourceUtilizationFilterContainer from './ResourceUtilizationFilterContainer.component';
import ResourceUtilizationFilterFormBody from './ResourceUtilizationFilterFormBody.component';
import AppliedResourceUitilzationFilterContainer from './AppliedResourceUitilzationFilterContainer.component';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import CreateIcon from '@material-ui/icons/Create';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
	ALL_USERS,
	START_DATE_ERROR,
	USER_ALLOCATED_STATUS_OPTIONS,
	USER_BILLABLE_STATUS_OPTIONS,
	USER_IN_PROJECT,
	USER_TYPE_STATUS_OPTIONS,
	ALLOCATION_DETAILS_COLUMN,
} from './constant';
import { useGetOrganisationManHoursQuery } from '../../generated/graphql';
import Popover from '@material-ui/core/Popover';
import AddNoteEditor from './AddNoteEditor.component';
import { head, isEmpty, orderBy } from 'lodash';
import { formatEmployeeId, getPrefix } from '../../Utils/string.util';
import EmployeeGanttChart from './EmployeeGanttChart.component';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useGetOrgLocationsQuery } from '../../generated/graphql';
import { ResourcePagination } from './ResourcePagination';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const minChipTheme = createTheme({
	overrides: {
		MuiChip: {
			root: {
				color: 'rgba(0, 0, 0, 0.87)',
				cursor: ' default',
				display: 'inline-flex',
				outline: '0',
				padding: '0',
				fontSize: '0.8125rem',
				boxSizing: 'border-box',
				transition:
					'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				alignItems: 'center',
				fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
				whiteSpace: 'nowrap',
				verticalAlign: 'middle',
				justifyContent: 'center',
				textDecoration: 'none',
				marginRight: '3px',
			},
			outlined: {
				border: '1px solid #E0E0E0',
				height: '20px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
				backgroundColor: 'transparent',
				maxWidth: '45px',
			},
			label: {
				color: '#4285F4',
				overflow: 'hidden',
				fontSize: '10px',
				fontFamily: 'Manrope-semibold',
				whiteSpace: 'nowrap',
				paddingLeft: '12px',
				paddingRight: '12px',
				textOverflow: 'ellipsis',
			},
		},
	},
});

const maxChipTheme = createTheme({
	overrides: {
		MuiChip: {
			root: {
				color: 'rgba(0, 0, 0, 0.87)',
				cursor: ' default',
				display: 'inline-flex',
				outline: '0',
				padding: '0',
				fontSize: '0.8125rem',
				boxSizing: 'border-box',
				transition:
					'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				alignItems: 'center',
				fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
				whiteSpace: 'nowrap',
				verticalAlign: 'middle',
				justifyContent: 'center',
				textDecoration: 'none',
				marginRight: '3px',
			},
			outlined: {
				border: '1px solid #E0E0E0',
				height: '20px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
				backgroundColor: 'transparent',
				maxWidth: '88px',
			},
			label: {
				color: '#4285F4',
				overflow: 'hidden',
				fontSize: '10px',
				fontFamily: 'Manrope-semibold',
				whiteSpace: 'nowrap',
				paddingLeft: '12px',
				paddingRight: '12px',
				textOverflow: 'ellipsis',
			},
		},
	},
});
const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const resourceUtilizationReportStyle = makeStyles({
	container: {
		marginTop: '16px',
	},
	searchBarContainer: {
		minWidth: '280px',
	},
	drawerContent: {
		width: 400,
	},
	warningText: {
		color: '#EA4335',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	listText: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	greenText: {
		color: '#34A853',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	toolbarContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	billableFilterContainer: {
		background: '#FFFFFF',
		height: '36px',
		marginLeft: '10px',
		marginTop: '4px',
	},
	userTypeFilterContainer: {
		background: '#FFFFFF',
		width: '150px',
		height: '36px',
		marginLeft: '10px',
		marginTop: '4px',
	},

	userTypeFilter: {
		minWidth: '20px',
	},
	billableFilter: {
		minWidth: '10px',
	},
	topToolbarContainer: {
		height: '80px',
		padding: '18px',
		display: 'flex',
		background: '#FFFFFF',
		boxShadow: '0px 3px 6px #0000000f',
		marginTop: '16px',
		alignItems: 'center',
		borderRadius: '4px',
		justifyContent: 'space-between',
		marginBottom: '16px',
	},
	totalUtilizationContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '17%',
		height: '100%',
		justifyContent: 'center',
	},
	totalUtilization: {
		fontFamily: 'Manrope-bold',
		fontSize: '20px !important',
		color: '#343434',
		display: 'flex',
		alignItems: 'center',
	},
	totalUtilizationLabel: {
		color: '#343434',
		fontSize: '10px',
	},
	userName: {
		color: '#292929',
		width: '92px',
		fontSize: '12px',
		fontFamily: ' Manrope-medium',
	},
	userTeamFilterContainer: {
		background: '#FFFFFF',
		width: '190px',
		height: '36px',
		marginLeft: '10px',
		marginTop: '4px',
	},
});
const useStyles = makeStyles((theme) => ({
	circle: {
		height: '13px',
		width: '20px',
		backgroundColor: '#1976d2',
		borderRadius: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		marginTop: '3px',
		color: '#ffff',
		padding: '1px',
	},
	project: {
		width: '130px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	note: {
		borderRadius: '50%',
		background: '#4285F4',
		display: 'flex',
		width: '15px',
		height: '14px',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#ffffff',
	},
	icon: {
		width: '25px',
		height: '15px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
}));

export const EmployeeUtilizationReport = () => {
	const {
		orgId: organisationId,
		dateFormat,
		featureFlag,
		noOfLocations,
		filterState,
		dispatchFilter,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext(UserProfileContext);
	const initialBillableQuery = {
		is_billable: {
			_eq: true,
		},
	};
	const [totalWorkingHours, setTotalWorkingHours] = useState(0);
	const resourceUtilizationReportStyles = resourceUtilizationReportStyle();
	const [currentFinancialYear, setCurrentFinancialYear] = useState({
		start_date: null,
		end_date: null,
	});
	const [selectedUserId, setSelectedUserId] = useState();
	const globalStyle = globalStyles();
	const dropdownStyle = labelStyle();
	const chipStyle = useStyles();
	const styles = ellipsisStyle();
	const ellipsis = dropDownEllipsisStyle();
	const [resource, setResource] = useState(null);
	const [projectAllocations, setProjectAllocations] = useState([]);
	const [isFilterDrawerShown, setIsFilterDrawerShown] = useState(false);
	const [filterValues, setFilterValues] = useState({});
	const [drawerFilterQuery, setDrawerFilterQuery] = useState({});
	const [startDateError, setStartDateError] = useState(false);
	const [isAllocationsShown, setIsAllocationsShown] = useState(true);
	const [isGanttResourceShown, setIsGanttResourceShown] = useState(false);
	const [calculationStartDate, setCalculationStartDate] = useState(
		dayjs().add(1, 'day').hour(0).minute(0).second(0).toDate()
	);
	const [anchorEl, setAnchorEl] = useState(null);
	const [locationList, setLocationList] = useState([]);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);
	const popOverId = open ? 'addTaskEditor-popover' : undefined;
	const dropDownStyle = autocompleteStyle();
	const handleClose = () => {
		setAnchorEl(null);
	};

	const { data: location } = useGetOrgLocationsQuery({
		variables: {
			orgId: organisationId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!location) {
			return;
		}

		const orgLocation = location.org_location.map((location) => {
			return {
				id: location.id,
				name: location.name,
			};
		});
		setLocationList([...orgLocation, { id: 'Unassigned', name: 'Unassigned' }]);
	}, [location]);

	const selectedLocationValues =
		filterState?.resourceLocationOptionFilter?.map((option) => option.id) ?? [];
	const selectedLocationIds = selectedLocationValues?.filter(
		(option) => option !== 'All' && option !== 'Unassigned'
	);
	const locationAssigned = {
		full_name: { _ilike: `%${filterState?.resourceSearchText || ''}%` },
		id:
			filterState?.resourceResourceIdList &&
			filterState?.resourceResourceIdList?.length > 0
				? { _in: filterState?.resourceResourceIdList }
				: {},
		user_type:
			filterState?.resourceUserTypeFilter === 'All' ||
			!filterState?.resourceUserTypeFilter
				? {}
				: { _eq: filterState?.resourceUserTypeFilter },
		_or: [
			{
				is_active: { _eq: true },

				employees: {
					...(filterState?.resourceBillableQueryFilter || initialBillableQuery),
					employee_status: { value: { _eq: 'active' } },
					location_id:
						selectedLocationValues?.length > 0 &&
						selectedLocationValues?.includes('All')
							? {}
							: selectedLocationValues?.length === 1 &&
							  selectedLocationValues?.includes('Unassigned')
							? {
									_is_null: true,
							  }
							: selectedLocationIds?.length > 0 &&
							  !selectedLocationValues?.includes('Unassigned')
							? { _in: filterState?.resourceLocation ?? [] }
							: {},
				},
			},

			{
				is_active: { _eq: true },
				contractors: {
					contractorStatus: {
						value: {
							_eq: 'active',
						},
					},
					location_id:
						selectedLocationValues?.length > 0 &&
						selectedLocationValues?.includes('All')
							? {}
							: selectedLocationValues?.length === 1 &&
							  selectedLocationValues?.includes('Unassigned')
							? {
									_is_null: true,
							  }
							: selectedLocationIds?.length > 0 &&
							  !selectedLocationValues?.includes('Unassigned')
							? { _in: filterState?.resourceLocation ?? [] }
							: {},
					...(filterState?.resourceBillableQueryFilter || initialBillableQuery),
				},
			},
		],

		...drawerFilterQuery,
	};

	const locationUnAssigned = {
		full_name: { _ilike: `%${filterState?.resourceSearchText || ''}%` },
		id:
			filterState?.resourceResourceIdList &&
			filterState?.resourceResourceIdList?.length > 0
				? { _in: filterState?.resourceResourceIdList }
				: {},
		user_type:
			filterState?.resourceUserTypeFilter === 'All' ||
			!filterState?.resourceUserTypeFilter
				? {}
				: { _eq: filterState?.resourceUserTypeFilter },
		_or: [
			{
				is_active: { _eq: true },

				employees: {
					...(filterState?.resourceBillableQueryFilter || initialBillableQuery),
					employee_status: { value: { _eq: 'active' } },
					_or: [
						{
							location_id: {
								_is_null: true,
							},
						},
						{
							location_id: {
								_in: selectedLocationIds,
							},
						},
					],
				},
			},

			{
				is_active: { _eq: true },
				contractors: {
					contractorStatus: {
						value: {
							_eq: 'active',
						},
					},
					_or: [
						{
							location_id: {
								_is_null: true,
							},
						},
						{
							location_id: {
								_in: selectedLocationIds,
							},
						},
					],
					...(filterState?.resourceBillableQueryFilter || initialBillableQuery),
				},
			},
		],

		...drawerFilterQuery,
	};

	const {
		data: resourceMappings,
		loading,
		refetch: refetchUsers,
	} = useApolloQuery(GET_RESOURCE_MAPPING_LIST, {
		variables: {
			where:
				selectedLocationValues.length > 0 &&
				selectedLocationValues.includes('Unassigned')
					? locationUnAssigned
					: locationAssigned,
		},
		fetchPolicy: 'network-only',
	});

	const { data: resourceMappingsList } = useApolloQuery(
		GET_RESOURCE_MAPPING_LIST,
		{
			variables: {
				where: {
					_or: [
						{
							is_active: { _eq: true },
							employees: {
								employee_status: { value: { _eq: 'active' } },
							},
						},
						{
							is_active: { _eq: true },
							contractors: {
								contractorStatus: {
									value: {
										_eq: 'active',
									},
								},
							},
						},
					],
					...drawerFilterQuery,
				},
			},
			fetchPolicy: 'network-only',
		}
	);
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId:
				!filterState?.resourceUserTeamId || null
					? {}
					: {
							_eq: filterState?.resourceUserTeamId || null,
					  },
		},
		skip: !filterState?.resourceUserTeamId || null,
		fetchPolicy: 'network-only',
	});
	const { data: organisationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId: organisationId,
		},
	});

	const { data: organisationFinanicialYear } = useApolloQuery(
		GET_ORGANISATION_FINANICIAL_YEAR,
		{
			variables: {
				organisationId: organisationId,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!organisationFinanicialYear) {
			return;
		}
		const currentYear = new Date().getFullYear();
		const financialYearStartMonth = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_month
		);
		const financialYearStartDate = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_date
		);
		const today = new Date();
		const calculatedStartDate = new Date(
			currentYear,
			financialYearStartMonth - 1, // Month starts from 0
			financialYearStartDate + 1
		);
		const startDate = dayjs()
			.date(financialYearStartDate)
			.month(financialYearStartMonth)
			.format('YYYY-MM-DD');

		const calculatedEndDate =
			dayjs() < dayjs(startDate)
				? dayjs(startDate)
						.year(dayjs().year() - 1)
						.add(12, 'months')
						.subtract(1, 'day')
						.toDate()
				: dayjs(startDate)
						.year(dayjs().year())
						.add(12, 'months')
						.subtract(1, 'day')
						.toDate();
		setCurrentFinancialYear({
			start_date: new Date(
				today < calculatedStartDate ? currentYear - 1 : currentYear,
				financialYearStartMonth - 1, // Month starts from 0
				financialYearStartDate
			),
			end_date: calculatedEndDate,
		});
	}, [organisationFinanicialYear]);
	useEffect(() => {
		if (!organisationManHours) {
			return;
		}
		const workingHoursPerDay =
			organisationManHours.organization_by_pk?.working_hours || 0;
		const businnessDays = DateTimeUtil.getBusinessDaysBetween(
			calculationStartDate,
			currentFinancialYear.end_date
		);
		const totalHours = businnessDays * workingHoursPerDay;
		setTotalWorkingHours(totalHours); // Total working hours within custom date range
	}, [currentFinancialYear, calculationStartDate, organisationManHours]);

	useEffect(() => {
		if (!resourceMappingsList) {
			return;
		}
		if (filterState?.resourceUserTeamFilter) {
			const userTeamAssignees = usersByTeam?.user_team_mapping?.map((user) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			});
			const sortedAssigneeList = orderBy(
				userTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_LIST,
				payload: sortedAssigneeList,
			});
			return;
		}

		const useTeamAssignees = resourceMappingsList?.user?.map((user) => {
			return {
				id: user?.id,
				name: `${user?.full_name} `,
			};
		});
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_LIST,
			payload: sortedAssigneeList,
		});
	}, [
		usersByTeam,
		filterState.resourceUserTeamFilter,
		resourceMappingsList,
		dispatchFilter,
	]);

	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map((user) => {
			return {
				id: user?.user?.id,
				name: user?.user?.full_name,
			};
		});
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_LIST,
			payload: sortedAssigneeList,
		});
	}, [usersByTeam, dispatchFilter]);

	const getLocationLimits = (value) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions =
			filterState?.resourceLocationOptionFilter?.filter(
				(option) => option?.id !== 'All'
			);
		return (
			<Box className={`${dropDownStyle.dropdownValue} ${styles.ellipsis}`}>
				{filterState?.resourceLocationOptionFilter &&
				filterState?.resourceLocationOptionFilter?.length === 1
					? filterState?.resourceLocationOptionFilter[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</Box>
		);
	};
	const filter = createFilterOptions();

	const generateReport = () => {
		if (resourceMappings?.user?.length === 0) {
			return;
		}

		const RESOURCE_EXPORT_COLUMN = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'CURRENT STATUS',
			'DEPARTMENT',
			'PROJECTS',
			'SKILLS',
			'NEXT AVAILABLE',
			'PLANNED UTILISATION',
			...(featureFlag?.userTeams ? ['USER TEAMS'] : []),
			'COST',
			'BILLABLE',
			'JOB TITLE',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'REPORTING MANAGER',
			'REPORTING MANAGER EMAIL',
			'START_DATE',
			'END_DATE',
			'ALLOCATION(%)',
			'NOTES',
		];

		const filteredUserIds = userStatusFilter();
		const filteredUserList = resourceMappings.user?.filter((user) =>
			filteredUserIds.includes(user?.id)
		);
		if (filteredUserList?.length === 0) {
			return;
		}
		const resourceAvalibityRow = filteredUserList?.map((exportResource) => {
			const existingEmployeeId = exportResource?.employee?.emp_id
				? exportResource?.employee?.emp_id
				: '--';
			const existingContractorId = head(exportResource?.contractors)
				?.contractor_id
				? head(exportResource?.contractors)?.contractor_id
				: '--';

			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const employeeId =
				exportResource?.user_type === 'employee'
					? exportResource.employee.emp_id
						? `${
								getPrefix(
									head(exportResource?.employees)?.employee_prefix?.name,
									head(exportResource?.employees)?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId}`
						: '--'
					: head(exportResource?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(exportResource?.contractors)?.contractor_prefix?.name,
								head(exportResource?.contractors)?.contractor_prefix?.name
							) || ''
					  }${formattedContractorId}`
					: '--';

			const name = `${exportResource?.full_name}`.trim();
			const emailId = `${exportResource?.email}`.trim();
			const employeeStatus = getResourceCurrentStatus(exportResource?.id);
			const resourceProject = getResourceProjectsExports(exportResource?.id);
			const resourceSkills = getResourceSkillsExports(exportResource?.id);
			const nextAvailable = getNextAvailableExports(exportResource);
			const plannedUtilization = getPlannedUtilization(exportResource);
			const teams = exportResource?.user_team_mappings?.map(
				(e) => e?.user_team?.name
			);

			const department =
				exportResource?.user_type === 'employee'
					? exportResource?.employee?.department?.name || '--'
					: head(exportResource?.contractors)?.department?.name || '--';

			const employeeCost =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.emp_cost
					: head(exportResource?.employees)?.emp_cost;

			const isBillable =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.is_billable
					: head(exportResource?.contractors)?.is_billable;

			const designation =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.designation?.title
					: head(exportResource?.contractors)?.designation?.title;

			const location =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.org_location?.name
					: head(exportResource?.contractors)?.org_location?.name;
			const reportingTo =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.reportsTo?.full_name
					: head(exportResource?.contractors)?.reportee?.full_name;
			const reportingToEmailId =
				exportResource?.user_type === 'employee'
					? head(exportResource?.employees)?.reportsTo?.email
					: head(exportResource?.contractors)?.reportee?.email;

			const resourceNotes = getResourceAllocationNoteExport(exportResource?.id);

			return [
				employeeId || '--',
				name || '--',
				emailId || '--',
				exportResource?.user_type === 'employee'
					? 'Employee'
					: 'Consultant' || '--',
				employeeStatus || '--',
				department || '--',
				resourceProject.toString() || '--',
				resourceSkills.toString() || '--',
				nextAvailable || '--',
				plannedUtilization || '--',
				...(featureFlag?.userTeams ? [teams.toString() || '--'] : []),
				employeeCost || '--',
				isBillable === true ? 'Yes' : 'No' || '--',
				designation || '--',
				...(noOfLocations && noOfLocations > 0 ? [location || '--'] : []),
				reportingTo || '--',
				reportingToEmailId || '--',
				dayjs(
					head(exportResource?.project_resource_mappings)?.start_date
				).format('DD-MMM-YYYY') || '--',
				dayjs(head(exportResource?.project_resource_mappings)?.end_date).format(
					'DD-MMM-YYYY'
				) || '--',
				head(exportResource?.project_resource_mappings)
					?.allocation_percentage || '--',
				resourceNotes || '--',
			];
		});

		const resourceExportData = [
			RESOURCE_EXPORT_COLUMN,
			...resourceAvalibityRow,
		];

		const data = filteredUserList?.filter((exportResource) => {
			return exportResource?.project_resource_mappings?.length > 0;
		});

		const filteredResourcesWithMultipleAllocations = data.flatMap((user) => {
			const existingEmployeeId = user?.employee?.emp_id
				? user?.employee?.emp_id
				: '--';
			const existingContractorId = head(user?.contractors)?.contractor_id
				? head(user?.contractors)?.contractor_id
				: '--';

			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const employeeId =
				user?.user_type === 'employee'
					? user.employee.emp_id
						? `${
								getPrefix(
									head(user?.employees)?.employee_prefix?.name,
									head(user?.employees)?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId}`
						: '--'
					: head(user?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(user?.contractors)?.contractor_prefix?.name,
								head(user?.contractors)?.contractor_prefix?.name
							) || ''
					  }${formattedContractorId}`
					: '--';
			const reportingTo =
				user?.user_type === 'employee'
					? head(user?.employees)?.reportsTo?.full_name
					: head(user?.contractors)?.reportee?.full_name;
			const reportingToEmailId =
				user?.user_type === 'employee'
					? head(user?.employees)?.reportsTo?.email
					: head(user?.contractors)?.reportee?.email;

			const name = `${user?.full_name}`.trim();
			const emailId = `${user?.email}`.trim();
			const resourceType =
				user?.user_type === 'employee' ? 'Employee' : 'Consultant' || '--';
			const department =
				user?.user_type === 'employee'
					? user?.employee?.department?.name || '--'
					: head(user?.contractors)?.department?.name || '--';
			const resourceSkills = getResourceSkillsExports(user?.id);
			const resourceNotes = getResourceAllocationNoteExport(user?.id);
			const joiningDate =
				user?.user_type === 'employee'
					? user?.employee?.join_date
						? dayjs(user?.employee?.join_date).format(dateFormat)
						: '--'
					: head(user?.contractors)
					? dayjs(head(user?.contractors)?.join_date).format(dateFormat)
					: '--';

			return (
				user?.project_resource_mappings
					?.filter((projectMapping) => {
						const endDate = new Date(projectMapping?.end_date);
						const startDate = new Date(projectMapping?.start_date);
						return (
							endDate > calculationStartDate &&
							startDate < currentFinancialYear?.end_date
						);
					})
					?.map((projectMapping) => {
						const isBillable = projectMapping?.is_billable;
						const isPartial = projectMapping?.is_partial;
						const project = projectMapping?.project;
						const projectStartDate =
							dayjs(projectMapping?.project?.start_date).format(dateFormat) ||
							'--';
						const projectEndDate =
							dayjs(projectMapping?.project?.end_date).format(dateFormat) ||
							'--';

						const allocation = projectMapping?.allocation_percentage || '--';
						const projectName = project?.name || '--';
						const projectBillable = project?.is_billable;
						const startDate =
							dayjs(projectMapping?.start_date).format(dateFormat) || '--';
						const endDate =
							dayjs(projectMapping?.end_date).format(dateFormat) || '--';

						return [
							employeeId || '--',
							name,
							emailId,
							resourceType,
							joiningDate || '--',
							department,
							reportingTo || '--',
							reportingToEmailId || '--',
							resourceSkills.toString() || '--',
							projectBillable ? `${projectName}` : `${projectName}-(NB)`,
							projectStartDate,
							projectEndDate,
							startDate,
							endDate,
							isBillable ? 'Yes' : 'No' || '--',
							isPartial ? 'Yes' : 'No' || '--',
							allocation,
							resourceNotes,
						];
					}) || []
			);
		});

		const multipleAllocationsCSVData = [
			ALLOCATION_DETAILS_COLUMN,
			...filteredResourcesWithMultipleAllocations,
		];

		const workbook = XLSX.utils.book_new();

		const worksheet1 = XLSX.utils.aoa_to_sheet(resourceExportData);

		XLSX.utils.book_append_sheet(
			workbook,
			worksheet1,
			'Resource Availability '
		);

		const worksheet2 = XLSX.utils.aoa_to_sheet(multipleAllocationsCSVData);
		XLSX.utils.book_append_sheet(workbook, worksheet2, 'Additional Details');

		const fileName = `Resource_Availability_From_${dayjs(
			calculationStartDate
		).format('YYYY-MM-DD')}_To_${dayjs(currentFinancialYear?.end_date).format(
			'YYYY-MM-DD'
		)}.xlsx`;

		XLSX.writeFile(workbook, fileName);
	};

	const getResourceAllocationNoteExport = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		if (!isEmpty(resourceMapping?.resource_allocation_notes)) {
			const blocksFromHTML = convertFromHTML(
				head(resourceMapping?.resource_allocation_notes)?.note || ''
			);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			);

			const notes = EditorState.createWithContent(state)
				.getCurrentContent()
				.getPlainText();
			return notes;
		} else {
			return '--';
		}
	};

	const getResourceCurrentStatus = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		let currentAllocationPercentage = 0;

		if (resourceMapping) {
			resourceMapping.project_resource_mappings.forEach(
				(projectResourceMapping) => {
					const startDate = new Date(projectResourceMapping.start_date);
					const endDate = new Date(projectResourceMapping.end_date);
					const allocationPercentage =
						projectResourceMapping.allocation_percentage;
					// Calculating the current inprogress allocation percentage
					if (
						startDate < calculationStartDate &&
						calculationStartDate < endDate &&
						projectResourceMapping?.project.is_billable
					) {
						currentAllocationPercentage =
							currentAllocationPercentage + allocationPercentage;
					}
				}
			);
		}
		return currentAllocationPercentage === 0 ? 'On Bench' : 'In Project';
	};
	const getNextAvailableDate = (currentAllocations, name) => {
		if (!resourceMappings || !currentAllocations) {
			return;
		}
		const sortedDates = currentAllocations
			.slice()
			.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
		const bufferDays = 10;
		let nextAvailableDate = null;
		let nextAvailableInDays = 0;
		if (sortedDates.length > 1) {
			for (let i = 0; i < sortedDates.length - 1; i++) {
				let dateDifference = DateTimeUtil.getBusinessDaysBetween(
					new Date(sortedDates[i].endDate),
					new Date(sortedDates[i + 1].startDate)
				);

				if (dateDifference > bufferDays) {
					let availableDate = sortedDates[i].endDate;
					nextAvailableDate = dayjs(availableDate)
						.add(1, 'day')
						.format('D-MMM-YYYY');
					nextAvailableInDays = DateTimeUtil.getBusinessDaysBetween(
						calculationStartDate,
						new Date(sortedDates[i].endDate)
					);
					break;
				}
				if (i === sortedDates.length - 2) {
					let availableDate = sortedDates[i + 1].endDate;
					nextAvailableDate = dayjs(availableDate)
						.add(1, 'day')
						.format('D-MMM-YYYY');
					nextAvailableInDays = DateTimeUtil.getBusinessDaysBetween(
						calculationStartDate,
						new Date(sortedDates[i + 1].endDate)
					);
					break;
				}
			}
		} else {
			let availableDate = new Date(sortedDates[0]?.endDate);
			nextAvailableDate = dayjs(availableDate)
				.add(1, 'day')
				.format('D-MMM-YYYY');
			nextAvailableInDays = DateTimeUtil.getBusinessDaysBetween(
				calculationStartDate,
				new Date(sortedDates[0]?.endDate)
			);
		}

		return nextAvailableInDays <= 15 ? (
			<Typography className={resourceUtilizationReportStyles.warningText}>
				In
				{` ${nextAvailableInDays + 1} `}
				days
			</Typography>
		) : (
			<Typography className={resourceUtilizationReportStyles.greenText}>
				{nextAvailableDate}
			</Typography>
		);
	};

	const getResourceDetails = (userId) => {
		if (!userId || !resourceMappings) {
			return;
		}
		const user = resourceMappings.user.find((user) => user.id === userId);

		if (user) {
			setResource({
				id:
					user.user_type === 'employee'
						? user.employees[0].id
						: user.contractors[0].id,
				name: `${user?.first_name} ${user?.middle_name || ''} ${
					user?.last_name || ''
				}`,
				cost:
					user.user_type === 'employee'
						? user.employees[0].emp_cost
						: user.contractors[0].contractor_cost,
				type: user.user_type,
				isBillable:
					user.user_type === 'employee'
						? user.employees[0].is_billable
						: user.contractors[0].is_billable,
				skills: user.employee_skill_mappings.map(
					(employeeSkillMapping) => employeeSkillMapping.skill_master.name
				),
				jobTitle:
					user.user_type === 'employee'
						? user?.employees[0]?.designation?.title
						: user?.contractors[0]?.designation?.title,
			});
		}
	};

	const getProjectAllocations = (userId) => {
		if (!userId || !resourceMappings) {
			return;
		}
		const user = resourceMappings.user.find((user) => user.id === userId);
		let currentAndFutureAllocations = [];

		if (user) {
			user.project_resource_mappings.forEach((allocation) => {
				if (
					calculationStartDate < new Date(allocation.end_date) &&
					new Date(allocation.start_date) < currentFinancialYear?.end_date
				) {
					currentAndFutureAllocations.push({
						allocation_start_date: allocation.start_date,
						allocation_end_date: allocation.end_date,
						projectStartDate: allocation.project.start_date,
						projectEndDate: allocation.project.end_date,
						allocationPercentage: allocation.allocation_percentage,
						projectName: allocation.project.name,
						projectId: allocation.project_id,
						isBillableProject: allocation.project.is_billable,
					});
				}
			});
			const sortedCurrentAndFutureAllocations =
				currentAndFutureAllocations.length > 0
					? currentAndFutureAllocations
							.slice()
							.sort((a, b) => a.allocation_start_date - b.allocation_start_date)
					: [];
			setProjectAllocations(sortedCurrentAndFutureAllocations);
		}
	};

	const getPlannedUtilization = (record) => {
		if (!resourceMappings || !record || !organisationManHours) {
			return;
		}
		const workingHoursPerDay =
			organisationManHours.organization_by_pk?.working_hours || 0;
		let totalHours = 0;
		let plannedUtilization = 0;
		const resourceAllocation = resourceMappings?.user?.find(
			(resource) => record.id === resource.id
		);
		if (resourceAllocation?.project_resource_mappings.length > 0) {
			resourceAllocation.project_resource_mappings.map((resource) => {
				if (
					!(new Date(resource.end_date) < calculationStartDate) &&
					resource.project.is_billable
				) {
					const startDate =
						new Date(resource.start_date) <= calculationStartDate
							? calculationStartDate
							: new Date(resource.start_date);
					const endDate =
						new Date(resource.end_date) <= currentFinancialYear.end_date
							? new Date(resource.end_date)
							: currentFinancialYear.end_date;
					const allocationPercentage = resource.allocation_percentage;
					const businnessDays = DateTimeUtil.getBusinessDaysBetween(
						startDate,
						endDate
					);
					const allocationFactor = allocationPercentage / 100;
					totalHours =
						totalHours +
						businnessDays * (workingHoursPerDay * allocationFactor);
				}
				return true;
			});
			plannedUtilization =
				totalHours !== 0
					? Math.ceil((totalHours / totalWorkingHours) * 100)
					: 0;
			return `${plannedUtilization}%`;
		} else {
			return '0%';
		}
	};

	const getResourceUtilizationAppliedFilters = (filter, filterValues) => {
		switch (filter) {
			case 'skill_id':
				return (
					filterValues?.skill_id &&
					filterValues?.skill_id.length > 0 &&
					filterValues?.skill_id.map((skill_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='id'
								reference='skill_master'
								record={{ id: skill_id }}
								link={false}
							>
								<FunctionField
									render={(skill) => {
										return (
											<Chip
												key={index}
												label={`${skill?.name}`}
												variant='outlined'
											/>
										);
									}}
								/>
							</ReferenceField>
						</Box>
					))
				);
			case 'project_id':
				return (
					filterValues?.project_id &&
					filterValues?.project_id.length > 0 &&
					filterValues?.project_id.map((project_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='id'
								reference='project'
								record={{ id: project_id }}
								link={false}
							>
								<FunctionField
									render={(project) => {
										return (
											<Chip
												key={index}
												label={`${project?.name}`}
												variant='outlined'
											/>
										);
									}}
								/>
							</ReferenceField>
						</Box>
					))
				);
			default:
				return filter;
		}
	};

	const checkIfUserInBench = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		let currentAllocationPercentage = 0;

		if (resourceMapping) {
			resourceMapping.project_resource_mappings.forEach(
				(projectResourceMapping) => {
					if (!projectResourceMapping?.project.is_billable) {
						return;
					}
					const startDate = new Date(projectResourceMapping.start_date);
					const endDate = new Date(projectResourceMapping.end_date);
					const allocationPercentage =
						projectResourceMapping.allocation_percentage;
					// Calculating the current inprogress allocation percentage
					if (
						startDate < calculationStartDate &&
						calculationStartDate < endDate
					) {
						currentAllocationPercentage =
							currentAllocationPercentage + allocationPercentage;
					}
				}
			);
		}
		return currentAllocationPercentage === 0 ? userId : false;
	};

	const checkIfUserInProject = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		let currentAllocationPercentage = 0;

		if (resourceMapping) {
			resourceMapping.project_resource_mappings.forEach(
				(projectResourceMapping) => {
					if (!projectResourceMapping?.project.is_billable) {
						return;
					}
					const startDate = new Date(projectResourceMapping.start_date);
					const endDate = new Date(projectResourceMapping.end_date);
					const allocationPercentage =
						projectResourceMapping.allocation_percentage;
					// Calculating the current inprogress allocation percentage
					if (
						startDate < calculationStartDate &&
						calculationStartDate < endDate
					) {
						currentAllocationPercentage =
							currentAllocationPercentage + allocationPercentage;
					}
				}
			);
		}
		return currentAllocationPercentage === 0 ? false : userId;
	};

	const getOnBenchUserIds = () => {
		if (!resourceMappings) {
			return;
		}
		return resourceMappings.user
			.map((user) => checkIfUserInBench(user.id))
			.filter((userId) => userId !== false);
	};

	const getAllocatedUserIds = () => {
		if (!resourceMappings) {
			return;
		}
		return resourceMappings.user
			.map((user) => checkIfUserInProject(user.id))
			.filter((userId) => userId !== false);
	};

	const getAllUserIds = () => {
		if (!resourceMappings) {
			return;
		}
		return resourceMappings?.user.map(({ id }) => id);
	};

	const userStatusFilter = () => {
		if (
			!filterState?.resourceAllocationFilter ||
			filterState?.resourceAllocationFilter === ALL_USERS
		) {
			return getAllUserIds();
		}

		if (filterState?.resourceAllocationFilter === USER_IN_PROJECT) {
			return getAllocatedUserIds();
		}

		return getOnBenchUserIds();
	};

	const resetFilter = () => {
		setFilterValues({});
		setDrawerFilterQuery({});
		return true;
	};

	const getAllUserUtilizationDetails = () => {
		if (!resourceMappings || !organisationManHours) {
			return;
		}
		const workingHoursPerDay =
			organisationManHours.organization_by_pk?.working_hours || 0;
		const userIds = userStatusFilter();
		if (userIds.length > 0) {
			const resourceMappingBasedOnFilter = userIds.map((userId) => {
				return resourceMappings?.user?.find((user) => user.id === userId);
			});
			return resourceMappingBasedOnFilter?.map((resourceAllocation) => {
				if (resourceAllocation?.project_resource_mappings.length > 0) {
					let totalHours = 0;
					resourceAllocation.project_resource_mappings.map((resource) => {
						if (
							!(new Date(resource.end_date) < calculationStartDate) &&
							resource.project.is_billable
						) {
							const startDate =
								new Date(resource.start_date) <= calculationStartDate
									? calculationStartDate
									: new Date(resource.start_date);
							const endDate =
								new Date(resource.end_date) <= currentFinancialYear.end_date
									? new Date(resource.end_date)
									: currentFinancialYear.end_date;
							const allocationPercentage = resource.allocation_percentage;
							const businnessDays = DateTimeUtil.getBusinessDaysBetween(
								startDate,
								endDate
							);
							const allocationFactor = allocationPercentage / 100;
							totalHours =
								totalHours +
								businnessDays * (workingHoursPerDay * allocationFactor);
						}
						return true;
					});
					return {
						totalHours: totalHours,
					};
				}
				return {
					totalHours: 0,
				};
			});
		}
		return;
	};

	const getResourceForecast = () => {
		if (!resourceMappings) {
			return;
		}
		let totalHours = 0;
		let numberOfEmployees = 0;
		const utilizationDetails = getAllUserUtilizationDetails();
		if (utilizationDetails && utilizationDetails.length > 0) {
			numberOfEmployees = utilizationDetails.length;
			totalHours = utilizationDetails.reduce(
				(previousValue, currentValue) =>
					previousValue + currentValue.totalHours,
				0
			);
		}
		const totalUtilization =
			Math.ceil((totalHours / (totalWorkingHours * numberOfEmployees)) * 100) ||
			0;
		return `${totalUtilization}%`;
	};

	const getResourceSkillsExports = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		if (
			resourceMapping?.employee_skill_mappings &&
			resourceMapping?.employee_skill_mappings?.length > 0
		) {
			return resourceMapping?.employee_skill_mappings &&
				resourceMapping?.employee_skill_mappings?.length > 0
				? resourceMapping?.employee_skill_mappings?.map(
						(skill, index) => skill?.skill_master?.name
				  )
				: '--';
		} else {
			return '--';
		}
	};

	const getResourceSkills = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		if (
			resourceMapping?.employee_skill_mappings &&
			resourceMapping?.employee_skill_mappings?.length > 0
		) {
			return (
				<Box display='flex' maxWidth='95px'>
					{resourceMapping?.employee_skill_mappings &&
					resourceMapping?.employee_skill_mappings?.length > 0
						? resourceMapping?.employee_skill_mappings
								?.slice(0, 2)
								.map((skill, index) => (
									<ThemeProvider
										theme={
											resourceMapping?.employee_skill_mappings?.length > 1
												? minChipTheme
												: maxChipTheme
										}
									>
										<Tooltip
											title={`${skill?.skill_master?.name}`}
											placement='top'
										>
											<Chip
												key={index}
												label={`${skill?.skill_master?.name}`}
												variant='outlined'
											/>
										</Tooltip>
									</ThemeProvider>
								))
						: '--'}
					{resourceMapping?.employee_skill_mappings &&
						resourceMapping?.employee_skill_mappings?.length > 2 && (
							<div className={chipStyle.circle}>{`+${
								resourceMapping?.employee_skill_mappings?.length - 2
							}`}</div>
						)}
				</Box>
			);
		} else {
			return '--';
		}
	};

	const getResourceProjectsExports = (userId) => {
		if (!userId || !resourceMappings) {
			return;
		}
		const user = resourceMappings.user.find((user) => user.id === userId);
		let currentAndFutureAllocations = [];

		if (user) {
			user.project_resource_mappings.forEach((allocation) => {
				if (
					calculationStartDate < new Date(allocation.end_date) &&
					new Date(allocation.start_date) < currentFinancialYear?.end_date
				) {
					currentAndFutureAllocations.push({
						allocation_start_date: allocation.start_date,
						allocation_end_date: allocation.end_date,
						projectStartDate: allocation.project.start_date,
						projectEndDate: allocation.project.end_date,
						allocationPercentage: allocation.allocation_percentage,
						projectName: allocation.project.name,
						projectId: allocation.project_id,
					});
				}
			});
			const sortedCurrentAndFutureAllocations =
				currentAndFutureAllocations.length > 0
					? currentAndFutureAllocations
							.slice()
							.sort((a, b) => a.allocation_start_date - b.allocation_start_date)
					: [];
			if (
				sortedCurrentAndFutureAllocations &&
				sortedCurrentAndFutureAllocations?.length > 0
			) {
				return sortedCurrentAndFutureAllocations?.map((project) => {
					return project?.projectName;
				});
			} else {
				return '--';
			}
		}
	};

	const getResourceProjects = (userId) => {
		if (!userId || !resourceMappings) {
			return;
		}
		const user = resourceMappings.user.find((user) => user.id === userId);
		let currentAndFutureAllocations = [];

		if (user) {
			user.project_resource_mappings.forEach((allocation) => {
				if (
					calculationStartDate < new Date(allocation.end_date) &&
					new Date(allocation.start_date) < currentFinancialYear?.end_date
				) {
					currentAndFutureAllocations.push({
						allocation_start_date: allocation.start_date,
						allocation_end_date: allocation.end_date,
						projectStartDate: allocation.project.start_date,
						projectEndDate: allocation.project.end_date,
						allocationPercentage: allocation.allocation_percentage,
						projectName: allocation.project.name,
						projectId: allocation.project_id,
					});
				}
			});
			const sortedCurrentAndFutureAllocations =
				currentAndFutureAllocations.length > 0
					? currentAndFutureAllocations
							.slice()
							.sort((a, b) => a.allocation_start_date - b.allocation_start_date)
					: [];
			if (
				sortedCurrentAndFutureAllocations &&
				sortedCurrentAndFutureAllocations?.length > 0
			) {
				return (
					<Box display='flex' width='95px'>
						{sortedCurrentAndFutureAllocations
							?.slice(0, 2)
							.map((project, index) => {
								return (
									<ThemeProvider
										theme={
											sortedCurrentAndFutureAllocations?.length > 1
												? minChipTheme
												: maxChipTheme
										}
									>
										<Tooltip title={`${project?.projectName}`} placement='top'>
											<Chip
												key={index}
												label={`${project?.projectName}`}
												variant='outlined'
											/>
										</Tooltip>
									</ThemeProvider>
								);
							})}
						{sortedCurrentAndFutureAllocations &&
							sortedCurrentAndFutureAllocations?.length > 2 && (
								<div className={chipStyle.circle}>{`+${
									sortedCurrentAndFutureAllocations?.length - 2
								}`}</div>
							)}
					</Box>
				);
			} else {
				return '--';
			}
		}
	};

	const getNoteDetails = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		if (resourceMapping?.resource_allocation_notes) {
			return head(resourceMapping?.resource_allocation_notes);
		}
	};

	const getResourceAllocationNote = (userId) => {
		if (!resourceMappings || !userId) {
			return;
		}
		const resourceMapping = resourceMappings.user.find(
			(user) => user.id === userId
		);
		if (!isEmpty(resourceMapping?.resource_allocation_notes)) {
			const blocksFromHTML = convertFromHTML(
				head(resourceMapping?.resource_allocation_notes)?.note || ''
			);
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			);

			const notes = EditorState.createWithContent(state)
				.getCurrentContent()
				.getPlainText();
			return (
				<Box display='flex' width='150px'>
					<Tooltip title={`${notes}`} placement='top'>
						<Box marginTop='3px' className={styles.ellipsis}>
							{notes}
						</Box>
					</Tooltip>
					<Box>
						<CreateIcon
							onClick={(event) => {
								event.preventDefault();
								setSelectedUserId(userId);
								handleClick(event);
								event.stopPropagation();
							}}
							aria-describedby={popOverId}
							style={{ width: '10px' }}
						/>
					</Box>
				</Box>
			);
		} else {
			return (
				<Box display='flex'>
					<div
						onClick={(event) => {
							event.preventDefault();
							setSelectedUserId(userId);
							handleClick(event);
							event.stopPropagation();
						}}
						className={chipStyle.note}
					>
						<AddIcon className={chipStyle.icon} />
					</div>
				</Box>
			);
		}
	};

	const getNextAvailableExports = (user) => {
		let currentAndFutureAllocations = [];
		let totalAllocationPercentage = 0;
		let nextAvailableDate = null;
		const resourceMapping = resourceMappings?.user.find(
			(resource) => resource.id === user.id
		);
		if (resourceMapping) {
			resourceMapping.project_resource_mappings.forEach(
				(projectResourceMapping) => {
					const startDate = new Date(projectResourceMapping.start_date);
					const endDate = new Date(projectResourceMapping.end_date);
					if (
						((endDate > calculationStartDate &&
							startDate < calculationStartDate) ||
							(startDate < currentFinancialYear?.end_date &&
								endDate > calculationStartDate)) &&
						projectResourceMapping.project.is_billable
					) {
						const allocation = projectResourceMapping.allocation_percentage;
						totalAllocationPercentage = totalAllocationPercentage + allocation;
						currentAndFutureAllocations.push({
							startDate: projectResourceMapping.start_date,
							endDate: projectResourceMapping.end_date,
						});
					}
				}
			);
		}

		nextAvailableDate =
			currentAndFutureAllocations.length > 0 &&
			totalAllocationPercentage >= 75 &&
			totalAllocationPercentage > 0
				? getNextAvailableDateExports(
						currentAndFutureAllocations || [],
						resourceMapping.full_name
				  )
				: 'Available';
		return nextAvailableDate !== null ? nextAvailableDate : null;
	};

	const getNextAvailableDateExports = (currentAllocations, name) => {
		if (!resourceMappings || !currentAllocations) {
			return;
		}
		const sortedDates = currentAllocations
			.slice()
			.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
		const bufferDays = 10;
		let nextAvailableDate = null;
		if (sortedDates.length > 1) {
			for (let i = 0; i < sortedDates.length - 1; i++) {
				let dateDifference = DateTimeUtil.getBusinessDaysBetween(
					new Date(sortedDates[i].endDate),
					new Date(sortedDates[i + 1].startDate)
				);

				if (dateDifference > bufferDays) {
					let availableDate = sortedDates[i].endDate;
					nextAvailableDate = dayjs(availableDate)
						.add(1, 'day')
						.format('D-MMM-YYYY');
					break;
				}
				if (i === sortedDates.length - 2) {
					let availableDate = sortedDates[i + 1].endDate;
					nextAvailableDate = dayjs(availableDate)
						.add(1, 'day')
						.format('D-MMM-YYYY');
					break;
				}
			}
		} else {
			let availableDate = new Date(sortedDates[0]?.endDate);
			nextAvailableDate = dayjs(availableDate)
				.add(1, 'day')
				.format('D-MMM-YYYY');
		}

		return nextAvailableDate;
	};
	const getResourceFilterLimits = (value) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions =
			filterState?.resourceResourceOptionFilter?.filter(
				(option) => option?.id !== 'All'
			);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${styles.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const resetTeamResourceFilter = () => {
		if (!resourceMappingsList) {
			return;
		}
		const useTeamAssignees = resourceMappingsList?.user?.map((user) => {
			return {
				id: user?.id,
				name: `${user?.full_name} `,
			};
		});
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_LIST,
			payload: sortedAssigneeList,
		});
	};
	const handleBillableFilter = (value) => {
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_BILLABLE_OPTION_FILTER,
			payload: value,
		});
		if (value === ALL_USERS) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_BILLABLE_QUERY_FILTER,
				payload: {},
			});
			return;
		}
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_BILLABLE_QUERY_FILTER,
			payload: {
				is_billable: {
					_eq: value,
				},
			},
		});
	};
	return (
		<Box className={resourceUtilizationReportStyles.container}>
			<AppliedResourceUitilzationFilterContainer
				clearFilters={async () => {
					const isFilterResetted = await resetFilter();
					if (isFilterResetted) {
						refetchUsers();
					}
				}}
				filterValues={filterValues}
			>
				{getResourceUtilizationAppliedFilters}
			</AppliedResourceUitilzationFilterContainer>
			<Box className={globalStyle.navigationTabContainer}>
				<Button
					className={[
						globalStyle.navigationTabButtons,
						!isGanttResourceShown ? globalStyle.activeNavigationTabButton : '',
					].join(' ')}
					onClick={() => {
						setIsAllocationsShown(true);
						setIsGanttResourceShown(false);
					}}
				>
					Allocations
				</Button>
				<Button
					className={[
						globalStyle.navigationTabButtons,
						!isAllocationsShown ? globalStyle.activeNavigationTabButton : '',
					].join(' ')}
					onClick={() => {
						setIsAllocationsShown(false);
						setIsGanttResourceShown(true);
					}}
					startIcon={<InsightsIcon />}
				>
					Gantt
				</Button>
			</Box>
			{!isGanttResourceShown && (
				<>
					<Box className={resourceUtilizationReportStyles.topToolbarContainer}>
						<Box>
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'
							>
								<ThemeProvider theme={dateTimePickerTheme}>
									<Box display='flex' alignItems='center'>
										<ErrorTooltip
											title={startDateError ? START_DATE_ERROR : ''}
											placement='top'
											arrow={true}
										>
											<Box mr={1}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														variant='inline'
														value={calculationStartDate}
														format={dateFormat}
														disablePast
														label='Start date'
														minDate={dayjs().add(1, 'day').toDate()}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton>
																		<EventIcon />
																	</IconButton>
																</InputAdornment>
															),
														}}
														onChange={(date) => {
															if (
																dayjs(date).isAfter(
																	dayjs(currentFinancialYear.end_date)
																) ||
																dayjs(date).isSame(
																	dayjs(currentFinancialYear.end_date)
																)
															) {
																setStartDateError(true);
															} else {
																setStartDateError(false);
															}
															setCalculationStartDate(
																dayjs(date).hour(0).minute(0).second(0).toDate()
															);
														}}
														error={startDateError}
													/>
												</MuiPickersUtilsProvider>
											</Box>
										</ErrorTooltip>
										<Box mr={1}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													variant='inline'
													value={currentFinancialYear.end_date}
													format={dateFormat}
													disablePast
													onChange={(date) => {
														if (
															dayjs(calculationStartDate).isAfter(
																dayjs(date)
															) ||
															dayjs(calculationStartDate).isSame(dayjs(date))
														) {
															setStartDateError(true);
														} else {
															setStartDateError(false);
														}
														setCurrentFinancialYear({
															...currentFinancialYear,
															end_date: date
																.hour(0)
																.minute(0)
																.second(0)
																.toDate(),
														});
													}}
													minDate={dayjs().add(1).toDate()}
													label='End date'
													InputProps={{
														endAdornment: (
															<InputAdornment position='end'>
																<IconButton>
																	<EventIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</MuiPickersUtilsProvider>
										</Box>
									</Box>
								</ThemeProvider>
							</Box>
						</Box>

						<Box
							className={
								resourceUtilizationReportStyles.totalUtilizationContainer
							}
						>
							<Typography
								className={resourceUtilizationReportStyles.totalUtilization}
							>
								{getResourceForecast()}
							</Typography>
							<Typography
								className={
									resourceUtilizationReportStyles.totalUtilizationLabel
								}
							>
								UTILIZATION FORECAST
							</Typography>
						</Box>
					</Box>
					<Box className={resourceUtilizationReportStyles.toolbarContainer}>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							marginBottom={'25px'}
						>
							<Box
								className={resourceUtilizationReportStyles.searchBarContainer}
								marginTop='10px'
							>
								<ThemeProvider theme={searchBarTheme}>
									<TextComponent
										placeholder='Search employee'
										fullWidth
										label={false}
										InputLabelProps={{ style: { fontSize: 0 } }}
										InputProps={{
											startAdornment: <SearchIcon />,
										}}
										onChange={(e) => {
											dispatchFilter({
												type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_SEARCH_TEXT,
												payload: e?.target?.value,
											});
										}}
										value={filterState?.resourceSearchText || ''}
									/>
								</ThemeProvider>
							</Box>
							<Box display='inline' width='100px'>
								<Box className={dropdownStyle.dropDownFilterLabel}>
									Employee Type
								</Box>
								<Box
									className={
										resourceUtilizationReportStyles.billableFilterContainer
									}
								>
									<Form
										initialValues={{
											billableFilter:
												filterState?.resourceBillableOptionFilter ?? true,
										}}
										onSubmit={() => {}}
									>
										{() => (
											<ThemeProvider theme={modalFormTheme}>
												<form>
													<SelectInput
														className={
															resourceUtilizationReportStyles.billableFilter
														}
														source={'billableFilter'}
														label={''}
														choices={USER_BILLABLE_STATUS_OPTIONS}
														onChange={(e) => {
															handleBillableFilter(e?.target?.value);
														}}
													/>
												</form>
											</ThemeProvider>
										)}
									</Form>
								</Box>
							</Box>
							<Box>
								<Box className={dropdownStyle.dropDownFilterLabel}>
									Allocation
								</Box>
								<Box
									className={
										resourceUtilizationReportStyles.billableFilterContainer
									}
								>
									<Form
										initialValues={{
											availableStatus:
												filterState?.resourceAllocationFilter ?? ALL_USERS,
										}}
										onSubmit={() => {}}
									>
										{() => (
											<ThemeProvider theme={modalFormTheme}>
												<form>
													<SelectInput
														className={
															resourceUtilizationReportStyles.billableFilter
														}
														source={'availableStatus'}
														label={''}
														choices={USER_ALLOCATED_STATUS_OPTIONS}
														onChange={(e) => {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_ALLOCATION_FILTER,
																payload: e?.target?.value,
															});
														}}
													/>
												</form>
											</ThemeProvider>
										)}
									</Form>
								</Box>
							</Box>

							<Box>
								<Box className={dropdownStyle.dropDownFilterLabel}>
									User Type
								</Box>
								<Box
									className={
										resourceUtilizationReportStyles.userTypeFilterContainer
									}
								>
									<Form
										initialValues={{
											userTypeFilterOptions:
												filterState?.resourceUserTypeFilter || 'All',
										}}
										onSubmit={() => {}}
									>
										{() => (
											<ThemeProvider theme={modalFormTheme}>
												<form>
													<SelectInput
														className={
															resourceUtilizationReportStyles.userTypeFilter
														}
														source={'userTypeFilterOptions'}
														label={''}
														choices={USER_TYPE_STATUS_OPTIONS}
														onChange={(e) => {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TYPE_FILTER,
																payload: e?.target?.value,
															});
														}}
													/>
												</form>
											</ThemeProvider>
										)}
									</Form>
								</Box>
							</Box>
							{featureFlag?.userTeams && (
								<>
									<Box width='200px'>
										<Box className={dropdownStyle.dropDownFilterLabel}>
											User Team
										</Box>
										<Box
											className={
												resourceUtilizationReportStyles.userTeamFilterContainer
											}
										>
											<Form onSubmit={() => {}}>
												{() => (
													<AutocompleteSearch
														placeholder={'Search Team'}
														option={
															userTeams && userTeams?.user_team
																? userTeams?.user_team?.map((userTeam) => {
																		return {
																			id: userTeam?.id,
																			name: userTeam?.name,
																		};
																  }) || []
																: []
														}
														onChange={(id) => {
															if (!id) {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
																	payload: [],
																});
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
																	payload: [],
																});
																resetTeamResourceFilter();
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_FILTER,
																	payload: null,
																});
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_ID,
																	payload: null,
																});
																return;
															}
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_ID,
																payload: id,
															});
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
																payload: [],
															});
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
																payload: [],
															});
															const currentTeam = userTeams?.user_team?.find(
																(team) => team?.id === id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_FILTER,
																payload: currentTeam,
															});
														}}
														name={'team_id'}
														value={filterState?.resourceUserTeamFilter}
													/>
												)}
											</Form>
										</Box>
									</Box>
									<Box width='200px'>
										<Box className={dropdownStyle.dropDownFilterLabel}>
											Resource
										</Box>
										<Box
											className={
												resourceUtilizationReportStyles.userTeamFilterContainer
											}
										>
											<Autocomplete
												multiple
												limitTags={0}
												value={
													filterState?.resourceResourceOptionFilter &&
													filterState?.resourceResourceOptionFilter?.length > 0
														? filterState?.resourceResourceOptionFilter
														: []
												}
												selectOnFocus={true}
												disablePortal
												fullWidth={false}
												onChange={(event, value, reason) => {
													if (!value) {
														return;
													}
													const selectedIds = value
														?.filter((resource) => resource?.id !== 'All')
														.map((resource) => resource?.id);
													if (
														value.find((option) => option.id === 'All') &&
														reason === 'select-option'
													) {
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
															payload: [
																{ id: 'All', name: 'All' },
																...(filterState?.resourceResourceOptionList ||
																	[]),
															],
														});
														const allResourceIds =
															filterState?.resourceResourceOptionList?.map(
																(resource) => resource?.id
															) ?? [];
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
															payload: allResourceIds,
														});
														return;
													}
													if (
														value.find((option) => option.id === 'All') &&
														reason === 'remove-option' &&
														filterState?.resourceResourceOptionList?.length !==
															selectedIds?.length
													) {
														const currentOptions = value?.filter(
															(resource) => resource?.id !== 'All'
														);
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
															payload: currentOptions,
														});
														const currentIds = currentOptions?.map(
															(resource) => resource?.id
														);
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
															payload: currentIds,
														});
														return;
													}
													if (
														selectedIds?.length ===
															filterState?.resourceResourceOptionList?.length &&
														reason === 'select-option'
													) {
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
															payload: [
																{ id: 'All', name: 'All' },
																...(filterState?.resourceResourceOptionList ||
																	[]),
															],
														});
														const reporteeIds =
															filterState?.resourceResourceOptionList?.map(
																(resource) => resource?.id
															) ?? [];
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
															payload: reporteeIds,
														});
														return;
													}
													if (
														filterState?.resourceResourceOptionFilter?.find(
															(option) => option?.id === 'All'
														) &&
														reason === 'remove-option'
													) {
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
															payload: [],
														});
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
															payload: [],
														});
														return;
													}
													const selectedId = value?.map(
														(resource) => resource?.id
													);
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST,
														payload: selectedId,
													});
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER,
														payload: value,
													});
												}}
												options={
													filterState?.resourceResourceOptionList &&
													filterState?.resourceResourceOptionList?.length > 0
														? [
																{ id: 'All', name: 'All' },
																...(filterState?.resourceResourceOptionList ||
																	[]),
														  ]
														: []
												}
												disableCloseOnSelect={true}
												getLimitTagsText={getResourceFilterLimits}
												ChipProps={{ style: { display: 'none' } }}
												renderInput={(params) => (
													<ThemeProvider
														theme={customAutoCompleteSearchBarTheme}
													>
														<TextComponent {...params} label='' />
													</ThemeProvider>
												)}
												renderOption={(option, { selected }) => (
													<>
														<Checkbox
															icon={
																<CheckBoxOutlineBlankIcon fontSize='small' />
															}
															checkedIcon={<CheckBoxIcon fontSize='small' />}
															style={{ marginRight: 8 }}
															checked={selected}
															color='primary'
														/>
														<Tooltip
															title={`${option?.name || '- -'}`}
															placement='right'
														>
															<Typography className={`${ellipsis.ellipsis}`}>
																{`${option?.name || '- -'}`}
															</Typography>
														</Tooltip>
													</>
												)}
												getOptionLabel={(option) => `${option?.name}`}
												getOptionSelected={(option, value) =>
													option.id === value.id
												}
											/>
										</Box>
									</Box>
									{noOfLocations && noOfLocations > 0 && (
										<Box width='200px'>
											<Box className={dropdownStyle.dropDownFilterLabel}>
												Location
											</Box>
											<Box
												className={
													resourceUtilizationReportStyles.userTeamFilterContainer
												}
											>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														filterState?.resourceLocationOptionFilter &&
														filterState?.resourceLocationOptionFilter?.length >
															0
															? filterState?.resourceLocationOptionFilter
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={(event, value, reason) => {
														if (!value) {
															return;
														}
														const selectedIds = value
															?.filter(
																(location) =>
																	location?.id !== 'All' &&
																	location?.id !== 'Unassigned'
															)
															.map((location) => location?.id);
														if (
															value.find((option) => option?.id === 'All') &&
															reason === 'select-option'
														) {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
																payload: [
																	{ id: 'All', name: 'All' },
																	...locationList,
																],
															});
															const allLocationIds = locationList?.map(
																(location) => location?.id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
																payload: allLocationIds,
															});

															return;
														}
														if (
															value.find((option) => option?.id === 'All') &&
															reason === 'remove-option' &&
															locationList?.length !== selectedIds?.length
														) {
															const currentOptions = value?.filter(
																(location) => location?.id !== 'All'
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
																payload: currentOptions,
															});
															const currentIds = currentOptions?.map(
																(location) => location?.id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
																payload: currentIds,
															});
															return;
														}
														const selectedIdsWithoutUnassigned = (
															filterState?.resourceLocation ?? []
														).filter((location) => location !== 'Unassigned');

														if (
															selectedIds?.length === 0 &&
															selectedIdsWithoutUnassigned?.length === 0 &&
															reason === 'select-option'
														) {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
																payload: [
																	{ id: 'Unassigned', name: 'Unassigned' },
																],
															});
															const locationIds = locationList?.map(
																(location) => location?.id === 'Unassigned'
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
																payload: locationIds,
															});

															return;
														}

														const selectedAllIds = value?.map(
															(location) => location?.id
														);
														if (
															selectedAllIds?.length === locationList?.length &&
															reason === 'select-option'
														) {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
																payload: [
																	{ id: 'All', name: 'All' },
																	...locationList,
																],
															});
															const locationIds = locationList?.map(
																(location) => location?.id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
																payload: locationIds,
															});
															return;
														}
														if (
															filterState?.resourceLocationOptionFilter?.find(
																(option) => option?.id === 'All'
															) &&
															reason === 'remove-option'
														) {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
																payload: [],
															});
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
																payload: [],
															});
															return;
														}
														const selectedId = value?.map(
															(location) => location?.id
														);
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION,
															payload: selectedId,
														});
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER,
															payload: value,
														});
													}}
													options={
														locationList && locationList?.length > 0
															? locationList
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getLocationLimits}
													ChipProps={{ style: { display: 'none' } }}
													filterOptions={(options, params) => {
														const filtered = filter(options, params);
														return [{ id: 'All', name: 'All' }, ...filtered];
													}}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<Field {...params} label='' placeholder={''} />
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => {
														return (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected || false}
																	color='primary'
																/>
																<Box
																	style={{ fontSize: '12px' }}
																>{`${option?.name}`}</Box>
															</>
														);
													}}
													getOptionLabel={(option) => `${option?.name}`}
													getOptionSelected={(option, value) =>
														option.id === value.id
													}
												/>
											</Box>
										</Box>
									)}
								</>
							)}
						</Box>

						<Box display={'flex'} marginLeft='10px' marginBottom='14px'>
							<Box marginRight={'2px'}>
								<ExportButtonWithLoader
									generateCSV={generateReport}
									isDisabled={!resourceMappings?.user?.length}
								/>
							</Box>
							<Box display='flex' alignItems='center'>
								<Box mr={2}></Box>
								<Tooltip title={'Filter'}>
									<IconButton
										onClick={() => {
											setIsFilterDrawerShown(true);
										}}
										className={globalStyle.filterButton}
									>
										<FilterListIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					</Box>
					{!loading ? (
						<List
							resource='user'
							basePath='/bench-report'
							pagination={
								<ResourcePagination
									userData={resourceMappings?.user?.length ?? -1}
								/>
							}
							perPage={15}
							bulkActionButtons={false}
							title=' '
							actions={false}
							filter={{
								full_name: `%${filterState?.resourceSearchText || ''}%`,
								id: userStatusFilter(),
							}}
							empty={false}
							sort={{ field: 'first_name', order: 'ASC' }}
						>
							<Datagrid
								rowClick={(userId) => {
									getResourceDetails(userId);
									getProjectAllocations(userId);
								}}
							>
								<FunctionField
									label='NAME'
									sortBy='first_name'
									sortByOrder='ASC'
									render={(user) => (
										<Tooltip title={`${user?.full_name} `} placement='right'>
											<Typography
												className={`${resourceUtilizationReportStyles.userName} ${styles.ellipsis}`}
											>
												{`${user?.full_name} `}
											</Typography>
										</Tooltip>
									)}
								/>
								<FunctionField
									label='TYPE'
									render={(user) =>
										user?.user_type === 'employee' ? `Employee` : `Contractor`
									}
								/>
								<FunctionField
									label='STATUS'
									render={(user) => getResourceCurrentStatus(user.id)}
								/>

								<FunctionField
									className={chipStyle.project}
									label='PROJECTS'
									render={(user) => getResourceProjects(user.id)}
								/>

								<FunctionField
									className={chipStyle.project}
									label='SKILLS'
									render={(user) => getResourceSkills(user.id)}
								/>
								<FunctionField
									label='NEXT AVAILABLE'
									render={(user) => {
										let currentAndFutureAllocations = [];
										let totalAllocationPercentage = 0;
										let nextAvailableDate = null;
										const resourceMapping = resourceMappings?.user.find(
											(resource) => resource.id === user.id
										);
										if (resourceMapping) {
											resourceMapping.project_resource_mappings.forEach(
												(projectResourceMapping) => {
													const startDate = new Date(
														projectResourceMapping.start_date
													);
													const endDate = new Date(
														projectResourceMapping.end_date
													);
													if (
														((endDate > calculationStartDate &&
															startDate < calculationStartDate) ||
															(startDate < currentFinancialYear?.end_date &&
																endDate > calculationStartDate)) &&
														projectResourceMapping.project.is_billable
													) {
														const allocation =
															projectResourceMapping.allocation_percentage;
														totalAllocationPercentage =
															totalAllocationPercentage + allocation;
														currentAndFutureAllocations.push({
															startDate: projectResourceMapping.start_date,
															endDate: projectResourceMapping.end_date,
														});
													}
												}
											);
										}

										nextAvailableDate =
											currentAndFutureAllocations.length > 0 &&
											totalAllocationPercentage >= 75 &&
											totalAllocationPercentage > 0 ? (
												getNextAvailableDate(
													currentAndFutureAllocations || [],
													resourceMapping.full_name
												)
											) : (
												<Typography
													className={
														resourceUtilizationReportStyles.warningText
													}
												>
													Available
												</Typography>
											);
										return nextAvailableDate !== null
											? nextAvailableDate
											: null;
									}}
								/>
								<FunctionField
									label='UTILIZATION'
									render={(record) => getPlannedUtilization(record)}
								/>
								<FunctionField
									className={chipStyle.project}
									label='NOTES'
									render={(user) => getResourceAllocationNote(user?.id)}
								/>
							</Datagrid>
						</List>
					) : (
						<Loading />
					)}
				</>
			)}
			{!isAllocationsShown && <EmployeeGanttChart />}
			<ResourceAvailableDetailsModal
				open={!!resource}
				onClose={() => {
					setResource(null);
					setProjectAllocations([]);
				}}
				resource={resource}
				projectAllocations={projectAllocations}
			/>
			<ResourceUtilizationFilterContainer
				open={isFilterDrawerShown}
				onClose={() => {
					setIsFilterDrawerShown(false);
				}}
				filterFormValues={filterValues}
				filterValues={(values) => {
					if (!values) {
						return;
					}
					setFilterValues(values);
				}}
				refetch={() => {
					refetchUsers();
				}}
				filterQuery={(query) => {
					setDrawerFilterQuery(query);
				}}
			>
				<ResourceUtilizationFilterFormBody />
			</ResourceUtilizationFilterContainer>

			<Popover
				id={popOverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<AddNoteEditor
					OnSuccess={() => {
						refetchUsers();
					}}
					note={getNoteDetails(selectedUserId)}
					userId={selectedUserId}
					onClose={() => {
						setAnchorEl(null);
					}}
				/>
			</Popover>
		</Box>
	);
};

export default EmployeeUtilizationReport;
