import React, { useEffect, useState } from 'react';

import {
	Typography,
	makeStyles,
	Box,
	Theme,
	createTheme,
	ThemeProvider,
	Tooltip,
	Button,
} from '@material-ui/core';
import TaskDetailsTab from './TaskDetailsTab.component';
import { useHistory } from 'react-router';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useParams } from 'react-router-dom';
import TaskDetailsTabContainer from './TaskDetailsTabContainer.component';
import {
	useGetProjectTaskStatusQuery,
	useGetTaskByIdQuery,
	useGetTaskListBykanbanBoardQuery,
	useUpdateProjectTaskByIdMutation,
} from '../../../generated/graphql';
import { SelectInput, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { ellipsisStyle } from '../../../Layout/styles';
import { head } from 'lodash';
const useStyles = makeStyles((theme: Theme) => ({
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	container: {
		flexGrow: 1,
		display: 'flex',
	},
	menuContainer: {
		width: '248px',
		height: '579px',
		background: '#ECF1F4',
		borderRadius: '4px',
		padding: '12px',
	},
	contentContainer: {
		borderRadius: '4px',
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		'& > div > div': {
			padding: '0px',
		},
	},
	activeTaskContainer: {
		height: '50px',
		borderLeft: '2px solid #4285F4',
		background: 'white',
		marginBottom: '5px',
		paddingTop: '5px',
		cursor: 'pointer',
	},
	activeTaskName: {
		font: 'normal normal 800 10px/20px Manrope',
		opacity: 1,
		marginLeft: '5px',
		color: '#4285F4',
	},
	taskContainer: {
		height: '50px',
		marginBottom: '5px',
		cursor: 'pointer',
	},
	taskName: {
		font: 'normal normal 800 10px/20px Manrope',
		opacity: 1,
		marginLeft: '5px',
	},
	taskSummary: {
		font: 'normal normal 600 10px/17px Manrope',
		color: '#5C5C5C',
		opacity: 1,
		marginLeft: '5px',
		width: '150px',
	},
	toolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '60px',
		alignItems: 'center',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		cursor: 'pointer',
		marginRight: '5px',
		textTransform: 'capitalize',
	},
	saveButton: {
		width: '89px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF !important',
		cursor: 'pointer',
		marginRight: '5px',
		textTransform: 'capitalize',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		width: '89px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		marginRight: '10px',
		color: 'grey',
		background: 'lightgrey !important',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
		cursor: 'pointer',
	},
	arrowIcon: {
		color: '#5C5C5C',
		padding: '0px 7px',
	},
	noTaskFound: {
		fontFamily: 'Manrope-medium',
		fontSize: '10px',
		opacity: 1,
		marginLeft: '5px',
	},
}));
export const projectTaskAccordionTheme = createTheme({
	overrides: {
		MuiAccordion: {
			root: {
				background: '#ECF1F4',
				'&.Mui-expanded': {
					margin: '8px 0px',
					'&:first-child': {
						borderTopLeftRadius: '2px',
						borderTopRightRadius: '2px',
					},
					'&:last-child': {
						borderBottomLeftRadius: '2px',
						borderBottomRightRadius: '2px',
					},
				},
				'&:before': {
					height: '0px',
				},
			},
			rounded: {
				marginBottom: '8px',
				boxShadow: 'none',
				width: '224px',
				borderRadius: '2px',
				'&:first-child': {
					borderTopLeftRadius: '2px',
					borderTopRightRadius: '2px',
				},
				'&:last-child': {
					borderBottomLeftRadius: '2px',
					borderBottomRightRadius: '2px',
				},
			},
		},
		MuiAccordionSummary: {
			root: {
				minHeight: 0,
				padding: '0px 10px',
				height: '40px',
				color: '#292929',
				'&.Mui-expanded': {
					minHeight: 0,
					height: '40px',
				},
			},
			content: {
				margin: 0,
				'& > p': {
					fontSize: '10px',
					fontFamily: 'Manrope-extrabold',
					textTransform: 'uppercase',
				},
				'&.Mui-expanded': {
					margin: '0px',
				},
			},
		},
		MuiAccordionDetails: {
			root: {
				padding: '0px',
				display: 'block',
				'& .makeStyles-tabs': {
					borderRight: 'none',
				},
			},
		},
		MuiSvgIcon: {
			root: {
				width: '0.8em',
			},
		},

		MuiCollapse: {
			entered: {
				paddingBottom: '8px',
			},
		},
	},
});
interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}
export interface AssignedTo {
	first_name: string;
	last_name: string;
	profile_pic?: any;
	__typename: string;
}
export interface ProjectTask {
	id: string;
	card_name?: string;
	story_point: number;
	project_id: string;
	project_milestone_id: string;
	description: string;
	planned_end_date: Date;
	priority: string;
	title: string;
	assignedTo: AssignedTo;
	status?: any;
	task_number?: number;
	__typename: string;
}
export interface Card {
	id: string;
	ordinal: number;
	column_id: string;
	project_task: ProjectTask;
	__typename: string;
}
interface Column {
	id: string;
	name: string;
	cards: Card[];
}
interface Expansion {
	id: string;
	state: boolean;
}
interface Form {
	title: string;
	planned_start_date?: string;
	planned_end_date?: string;
	assigned_to?: string;
	priority: string;
	description?: string;
	is_billable: boolean;
	completion_percentage?: number;
	reminder?: string;
	status: string;
	planned_hours?: string;
	id?: string;
	cardId?: string;
}
interface Status {
	id: string;
	name: string;
}
interface ProjectKanbanCard {
	column_id: string;
}

interface Project {
	prefix?: string | null;
}

interface Task {
	id: string;
	card_name?: any;
	description?: any;
	planned_end_date?: Date;
	planned_start_date?: Date;
	project_milestone_id?: any;
	project_id?: string;
	priority?: string;
	title: string;
	assigned_to?: string;
	status?: any;
	assigned_by?: any;
	story_point?: any;
	completion_percentage?: number;
	reminder?: any;
	is_billable?: boolean | null;
	planned_hours?: any;
	task_number?: number;
	project_kanban_cards: ProjectKanbanCard[];
	project: Project;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} width='100%'>
					{children}
				</Box>
			)}
		</div>
	);
}

export const TaskDetails = (props: any) => {
	const classes = useStyles();
	const history = useHistory<any>();
	const [taskFormValues, setTaskFormValues] = useState<any>();
	const { id, task_id }: { id: string; task_id: string } = useParams();
	const [activeTaskId, setActiveTaskId] = useState(task_id);
	const [taskStatusList, setTaskStatusList] = useState<Status[]>([]);
	const [selectedStatus, setSelectedStatus] = useState<string>('');
	const [isFormValueChanged, setIsFormValueChanged] = useState(false);
	const [taskList, setTaskList] = useState<Task[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const ellipsis = ellipsisStyle();
	const { data: task } = useGetTaskByIdQuery({
		variables: {
			task_id: task_id,
		},
	});
	const [refetchSubTask, setRefetchSubTask] = useState(false);

	const {
		data: projectTaskList,
		refetch: refetchTaskList,
		loading: taskListLoading,
	} = useGetTaskListBykanbanBoardQuery({
		variables: {
			projectId: id,
			columnId: selectedStatus,
		},
		fetchPolicy: 'network-only',
	});
	const { data: statusList } = useGetProjectTaskStatusQuery({
		variables: {
			id: task?.project_task[0]?.project?.kanban_board?.id,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!statusList) {
			return;
		}
		const statusOptions = statusList?.kanban_column?.map((status: Status) => {
			return {
				id: status?.id,
				name: status?.name,
			};
		});
		setTaskStatusList(statusOptions);
	}, [statusList]);

	const { data: taskDetails } = useGetTaskByIdQuery({
		variables: {
			task_id: task_id,
		},
	});

	const { data: projectTaskStatusList } = useGetProjectTaskStatusQuery({
		variables: {
			id: taskDetails?.project_task[0]?.project?.kanban_board?.id,
		},
	});
	const notify = useNotify();
	const [updateProjectTask] = useUpdateProjectTaskByIdMutation();
	useEffect(() => {
		if (!taskDetails) {
			return;
		}
		setSelectedStatus(
			taskDetails?.project_task[0]?.project_kanban_cards[0]?.column_id
		);
		setActiveTaskId(taskDetails?.project_task[0]?.id);
	}, [taskDetails]);

	const onUpdateTask = () => {
		const {
			id,
			effort_estimate,
			deviation_hr,
			deviation_per,
			cardId,
			working_hours,
			...restFormValues
		} = taskFormValues;
		updateProjectTask({
			variables: {
				task: {
					...restFormValues,
					parent_task_id: null,
					reminder: taskFormValues?.reminder || null,
					category_id: taskFormValues?.category_id || null,
				},
				id: id,
				cardId: cardId,
				kanbanData: {
					column_id: restFormValues?.status,
				},
			},
		})
			.then((response: any) => {
				if (!response.errors) {
					notify('Task updated successfully');
					refetchTaskList();
					setIsFormValueChanged(false);
				}
			})
			.catch((error: string) => {
				if (error) {
					notify('Task updation failed', 'error');
				}
			});
	};
	useEffect(() => {
		if (!projectTaskList) {
			return;
		}
		setTaskList(projectTaskList?.project_task);
	}, [projectTaskList]);

	useEffect(() => {
		if (!selectedStatus) {
			return;
		}
		refetchTaskList();
	}, [refetchTaskList, selectedStatus]);

	return (
		<>
			<Box className={classes.toolBar}>
				<Box display='flex' justifyContent='space-around' alignItems='center'>
					<Box className={classes.title}>Tasks</Box>
					<DoubleArrowIcon className={classes.arrowIcon} />
					<Box className={classes.title}>Details</Box>
				</Box>
				<Box display='flex'>
					<Button
						onClick={() => {
							if (history?.location?.state?.assigned_to) {
								history.push({
									pathname: `/project/tasks/${id}`,
									state: {
										assigned_to: history?.location?.state?.assigned_to,
										projectMilestoneId:
											history?.location?.state?.projectMilestoneId,
										taskCategory: history?.location?.state?.taskCategory,
										projectTaskSearchText:
											history?.location?.state?.projectTaskSearchText,
										projectMilestoneSearchText:
											history?.location?.state?.projectMilestoneSearchText,
										projectMilestoneTaskFilter:
											history?.location?.state?.projectMilestoneTaskFilter,
									},
								});
							} else {
								history.push({
									pathname: `/project/tasks/${id}`,
								});
							}
						}}
						className={classes.backButton}
					>
						Back
					</Button>
					<Button
						onClick={onUpdateTask}
						className={
							!isFormValueChanged ? classes.disabledButton : classes.saveButton
						}
						disabled={!isFormValueChanged}
					>
						Save
					</Button>
				</Box>
			</Box>
			<Box className={classes.container}>
				{
					<Box className={classes.menuContainer}>
						<Box>
							<Form
								initialValues={{
									status:
										task?.project_task[0]?.project_kanban_cards[0]?.column_id,
								}}
								onSubmit={() => {}}
							>
								{() => (
									<form>
										<ThemeProvider theme={modalFormTheme}>
											<SelectInput
												choices={taskStatusList || []}
												source='status'
												label={''}
												onChange={(event: any) => {
													setTaskList([]);
													setSelectedStatus(event?.target?.value);
												}}
											/>
										</ThemeProvider>
									</form>
								)}
							</Form>
							<Box height='500px' overflow='auto'>
								{taskListLoading ? (
									<Typography className={classes.noTaskFound}>
										Loading...
									</Typography>
								) : taskList && taskList?.length > 0 ? (
									taskList?.map((task) => (
										<Box
											key={task?.id}
											onClick={() => {
												setActiveTaskId(task?.id);
												setIsLoading(true);
											}}
											className={
												task?.id === activeTaskId
													? classes.activeTaskContainer
													: classes.taskContainer
											}
										>
											<Typography
												className={
													task?.id === activeTaskId
														? classes.activeTaskName
														: classes.taskName
												}
											>
												{`${head(taskDetails?.project_task)?.project?.prefix}-${
													task?.task_number
												}`}
											</Typography>
											<Tooltip placement='right' title={task?.title || '--'}>
												<Typography
													className={`${classes.taskSummary} ${ellipsis.ellipsis}`}
												>
													{task?.title}
												</Typography>
											</Tooltip>
										</Box>
									))
								) : (
									<Typography className={classes.noTaskFound}>
										No task found
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				}

				<Box className={classes.contentContainer}>
					<TabPanel value={0} index={0}>
						<TaskDetailsTab
							taskId={activeTaskId}
							formValues={(formValue: Form) => {
								setTaskFormValues(formValue);
							}}
							onSuccess={(val: boolean) => setRefetchSubTask(val)}
							isFormValuesChanged={(val) => setIsFormValueChanged(val)}
							setIsLoading={(val: boolean) => setIsLoading(val)}
							isLoading={isLoading}
						/>
						<TaskDetailsTabContainer
							taskId={activeTaskId}
							refetchSubTask={refetchSubTask}
							boardId={
								taskDetails?.project_task[0]?.project?.kanban_board?.id || null
							}
							statusList={projectTaskStatusList?.kanban_column || null}
							mileStoneId={
								taskDetails?.project_task[0]?.project_milestone_id || null
							}
						/>
					</TabPanel>
				</Box>
			</Box>
		</>
	);
};

export default TaskDetails;
