import React,{ useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useRefresh, useNotify, useQuery } from 'react-admin';
import { GET_CONSULTANT_NOTE,UPSERT_CONSULTANT_NOTE,DELETE_CONSULTANT_NOTE } from '../gqlQueries';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import Notes from '../../../SharedComponents/Notes/Notes';
import { UserProfileContext } from '../../../App';


const ConsultantNote = ()=>{
	const { id }: { id: string } = useParams();
	const [noteId, setNoteId] = useState('');
	const [searchConsultantNotes, setSearchConsultantNotes] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();
	const [upsertConsultantNote,{loading:isConsultantNoteLoading}] = useApolloMutation(UPSERT_CONSULTANT_NOTE);
	const [deleteConsultantNote] = useApolloMutation(DELETE_CONSULTANT_NOTE);
	const {permissions: permissionList } = useContext<any>(UserProfileContext);
	const { data: consultantNote, refetch: refetchConsultantNotes } = useApolloQuery(
		GET_CONSULTANT_NOTE,
		{
			variables: {
				consultant_id: id ? id : null,
				SearchText: `%${searchConsultantNotes}%`,
			},
			fetchPolicy:'network-only'
		}
	);

	const { data: consultantList } = useQuery({
		type: 'GET_LIST',
		resource: 'consultant_note',
		payload: {
			filter: {
				id: noteId ? noteId : null,
			},
		},
	});

	const deleteNote = (noteId: string) => {
		deleteConsultantNote({
			variables: {
				noteId: noteId,
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Consultant Notes Deleted successfully');
				refresh();
				refetchConsultantNotes();
			}
		});
	};


	const upsertNote = (note: NoteForm) => {
		upsertConsultantNote({
			variables: {
				consultantNote: {
					consultant_id: id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Employee Notes Created successfully');
				refresh();
				refetchConsultantNotes();
			}
		});
	};


	const saveForm = (note:NoteForm)=>{
		upsertNote(note)
	}

  return (
		<>
			<Notes
				saveForm={saveForm}
				deleteNote={deleteNote}
				searchNotes={setSearchConsultantNotes}
				noteList={consultantNote?.consultant_note}
				refetch={() => refetchConsultantNotes()}
				noteId={setNoteId}
				getNoteList={consultantList}
				permission={
					permissionList?.contractor?.update_permissions ? true : false
				}
				deletePermission={permissionList?.consultant_note?.update_permissions?.deleted_at ? true : false}
				loading={isConsultantNoteLoading}
			/>
		</>
	);
};

export default ConsultantNote;