import React, { useContext } from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { List, Datagrid, FunctionField, Pagination } from 'react-admin';
import { ellipsisStyle } from '../../Layout/styles';
import { Box, Tooltip } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { UserProfileContext } from '../../App';
import { homePageStyle } from './style';
import { useGetOrgHolidaysByUserQuery } from '../../generated/graphql';
import dayjs from 'dayjs';
import UpcomingPendingLeaves from './UpcomingPendingLeaves.component';

export const holidayStyle = makeStyles({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		marginTop: '20px',
		maxWidth: '83vw',
		gap: '15px',
	},
	holidayContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	name: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		opacity: 1,
		padding: '10px 0 0 4px',
		width: '80px',
	},
	chartContainer: {
		width: '40vw',
	},
	rowPadding: {
		padding: '5px',
	},
	fullHeight: {
		height: '100%',
	},
});

const HolidayPagination = () => {
	return (
		<Box marginBottom={'14px'} position={'relative'}>
			<Pagination rowsPerPageOptions={[4, 8, 16]} />
		</Box>
	);
};

const UpComingHolidayList = () => {
	const {
		noOfLocations,
		locationId,
		dateFormat,
		id: userId,
	} = useContext<any>(UserProfileContext);
	const classes = holidayStyle();
	const styles = ellipsisStyle();
	const homeStyle = homePageStyle();

	const { data: orgHolidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: 'today()',
							},
							is_restricted: { _eq: false },
							location_id: { _eq: locationId },
					  }
					: {
							date: {
								_gte: 'today()',
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: 'today()',
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: 'today()',
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip: !locationId && noOfLocations && noOfLocations > 0,
	});
	const getHolidayFilter = () => {
		if (!orgHolidays) {
			return [];
		}
		const regularHolidays =
			orgHolidays?.org_holidays?.map((holiday) => holiday?.id) || [];
		const optionalHolidays =
			orgHolidays?.user_optional_holiday_mapping?.map(
				(holiday) => holiday?.org_holiday?.id
			) || [];
		return [...regularHolidays, ...optionalHolidays];
	};
	return (
		<Box className={classes.container}>
			<Box className={classes.chartContainer}>
				<Card className={classes.fullHeight}>
					<CardHeader
						title='My Upcoming Holidays'
						titleTypographyProps={{ variant: 'h6' }}
					/>
					<CardContent style={{ overflowY: 'auto' }}>
						<ResponsiveContainer height={355}>
							<Box>
								<List
									bulkActionButtons={false}
									resource={'org_holidays'}
									sort={{ field: 'date', order: 'ASC' }}
									basePath='/home'
									filter={{
										id: getHolidayFilter(),
									}}
									perPage={4}
									actions={false}
									pagination={<HolidayPagination />}
									component='div'
									title={' '}
								>
									<Datagrid classes={{ headerCell: homeStyle.headerCell }}>
										<FunctionField
											label='Name'
											render={(record: any) => {
												return (
													<Tooltip
														title={`${record?.name || ''}`}
														placement='right'
													>
														<Typography
															className={`${homeStyle.additionalInfoLabelLeave} ${classes.rowPadding} ${styles.ellipsis}`}
														>
															{`${record?.name}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
										<FunctionField
											label='Date'
											sortable={true}
											render={(record: any) => {
												return (
													<Tooltip
														title={`${
															dayjs(record?.date).format(dateFormat) || ''
														}`}
														placement='right'
													>
														<Typography
															className={`${homeStyle.additionalInfoLabelLeave} ${styles.ellipsis}`}
														>
															{`${dayjs(record?.date).format(dateFormat)}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
										<FunctionField
											label='Day'
											render={(record: any) => {
												return (
													<Tooltip
														title={`${dayjs(record?.date).format('ddd') || ''}`}
														placement='right'
													>
														<Typography
															className={`${homeStyle.additionalInfoLabelLeave} ${styles.ellipsis}`}
														>
															{`${dayjs(record?.date).format('ddd')}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
										<FunctionField
											label='Optional Holiday'
											render={(record: any) => {
												return (
													<Tooltip
														title={record?.is_restricted ? 'Yes' : 'No'}
														placement='right'
													>
														<Typography
															className={`${homeStyle.additionalInfoLabelLeave} ${styles.ellipsis}`}
														>
															{record?.is_restricted ? 'Yes' : 'No'}
														</Typography>
													</Tooltip>
												);
											}}
										/>
										<FunctionField
											label='Description'
											render={(record: any) => {
												return (
													<Tooltip
														title={`${record?.description || ''}`}
														placement='right'
													>
														<Typography
															className={`${homeStyle.additionalInfoLabelLeave} ${styles.ellipsis}`}
														>
															{`${record?.description || '--'}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</Datagrid>
								</List>
							</Box>
						</ResponsiveContainer>
					</CardContent>
				</Card>
			</Box>
			<Box className={classes.chartContainer}>
				<UpcomingPendingLeaves />
			</Box>
		</Box>
	);
};

export default UpComingHolidayList;
