import React, { useEffect } from 'react';

import { AutocompleteInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';

import head from 'lodash/head';
import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import { UPDATE_ORGANISATION } from '../Settings.gql';
import { GET_ORG_TIMEZONE_OPTIONS } from './TimeZone.gql';
import { orderBy } from 'lodash';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

interface TimezoneProps {
	organisationId?: string;
	organisationTimezone: {
		timezone?: string;
	};
	onSuccess: () => void;
}

export interface Value {
	id: string;
	name: string;
}

export interface MasterSetting {
	value: Value[];
}

export interface OrganisationTimezoneOptions {
	master_settings: MasterSetting[];
}

const TimeZoneSettings = ({
	organisationTimezone,
	onSuccess,
	organisationId,
}: TimezoneProps) => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const { data: organisationTimezoneOptions } =
		useApolloQuery<OrganisationTimezoneOptions>(GET_ORG_TIMEZONE_OPTIONS);
	const [
		updateTimezone,
		{ data: updatedTimezone, loading: isUpdateTimezoneLoading },
	] = useApolloMutation(UPDATE_ORGANISATION);

	useEffect(() => {
		if (updatedTimezone) {
			notify('Timezone Updated');
			refresh();
		}
	}, [updatedTimezone, notify, refresh]);

	useEffect(() => {
		if (updatedTimezone) {
			onSuccess();
		}
	}, [updatedTimezone, onSuccess]);

	const onHandleSubmit = (timezone: { timezone: string }) => {
		updateTimezone({
			variables: {
				organisationId: organisationId,
				data: { ...timezone },
			},
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form initialValues={organisationTimezone} onSubmit={onHandleSubmit}>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Time Zone</Typography>
								</Box>
								<Box className={styles.input}>
									<AutocompleteInput
										source='timezone'
										label=''
										choices={orderBy(
											head(organisationTimezoneOptions?.master_settings)?.value,
											['name'],
											['asc']
										)}
									/>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isLoading={isUpdateTimezoneLoading}
									isDisabled={invalid || pristine || isUpdateTimezoneLoading}
									title='Save'
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default TimeZoneSettings;
