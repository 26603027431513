import { makeStyles } from '@material-ui/core/styles';

export const homePageStyle = makeStyles({
	Circle: {
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		color: 'white',
		width: '46px',
		height: '46px',
	},
	countLabel: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#5C5C5C',
	},
	countValue: {
		fontSize: '20px',
		fontFamily: 'Manrope-semibold',
		color: '#292929',
		display: 'flex',
		justifyContent: 'end',
	},
	headCountContainer: {
		height: '96px',
		background: '#FFFFFF',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		marginTop: '16px',
		alignItems: 'center',
		display: 'grid',
		maxWidth: '82vw',
		gridTemplateColumns: 'repeat(5, 18%)',
		gap: '25px',
		padding: '0px 24px 0px 24px',
	},
	countContainer: {
		background: '#F7F9FA',
		minWidth: '161px',
		height: '70px',
		borderRadius: '4px',
		padding: '14px 12px 6px 12px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	headerCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-extrabold',
		color: '#5C5C5C',
		backgroundColor: '#F7F9FA',
		lineHeight: '16px',
		position: 'inherit',
	},

	noData: {
		fontFamily: 'Manrope-medium',
		letterSpacing: '0px',
		fontSize: '12px !important',
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	summaryContainer: {
		width: '655px',
		maxHeight: '300px',
		overflowX: 'hidden',
		overflowY: 'auto',
		background: '#FFFFFF',
	},
	additionalInfoLabelLeave: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
		maxWidth: '60px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	calendarIcon: {
		width: '16px',
		height: '16px',
		color: '#888888',
		marginRight: '12px',
	},
});
