import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
	TextInput,
	ReferenceInput,
	SelectInput,
	useNotify,
	useRefresh,
} from 'react-admin';

import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { Form, Field } from 'react-final-form';
import {
	ADD_CRM_TASK,
	UPDATE_CRM_TASK,
	GET_USERS,
	GET_CRM_TASK_ACTIVITIES,
	GET_OPPORTUNITY_ID,
} from './CrmTasks.gql';
import dayjs from 'dayjs';
import {
	EditorState,
	convertFromHTML,
	ContentState,
	convertToRaw,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import draftToHtml from 'draftjs-to-html';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
	decorator,
	plugins,
	MentionSuggestions,
	customEntityTransform,
} from '../../Utils/draftjs-util';

import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Typography,
	makeStyles,
	Divider,
	InputAdornment,
	IconButton,
	createTheme,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';
import { Task, TaskFormValues, CrmActivity } from './CrmTasksKanban.model';
import { debounce, head, isEmpty, orderBy } from 'lodash';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import { UserProfileContext } from '../../App';
import {
	SUGGESTION_DEFAULT_PIC,
	DEFAULT_REMINDER_TIME,
} from '../../config/constant';
import {
	useGetCrmPipelineBoardQuery,
	useGetAssigneeFilterRecommendationsQuery,
} from '../../generated/graphql';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
			input: {
				padding: '6px 0px 0px',
			},
		},
		MuiInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const taskFormStyle = makeStyles({
	container: {
		width: '49vw',
		padding: '20px',
		minHeight: '500px',
		borderRadius: '4px',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '54px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
});
interface KanbanColumn {
	id: string;
	state: string;
}

interface AddTaskModalProps {
	onClose: () => void;
	open: boolean;
	kanbanColumnId?: string;
	CrmTask?: Task;
	refetch: () => void;
}

const CrmTaskForm = (props: AddTaskModalProps) => {
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const taskFormStyles = taskFormStyle();
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [searchedUser, setSearchedUser] = useState('');
	const [pipelineId, setPipelineId] = useState<string[]>();
	const [opportunityId, setOpportunityId] = useState<string | null>(null);
	const [assigneId, setAssigneId] = useState<string | null>(userId);
	const [dueDate, setDueDate] = useState(
		dayjs()
			.add(1, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString()
	);
	const [taskFormInitialValues, setTaskFormInitialValues] =
		useState<TaskFormValues>({
			priority: 'Medium',
			remind_at: 1,

			owner_id: userId,
		});
	const { open, onClose, CrmTask, kanbanColumnId, refetch } = props;
	const classes = modalFormStyle();
	const { data: assignedUser } = useGetAssigneeFilterRecommendationsQuery();

	const { data: users } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%${searchedUser}%`,
		},
		skip: searchedUser?.length < 2,
	});
	const [addCrmTask, { loading: isAddTaskLoading }] =
		useApolloMutation(ADD_CRM_TASK);
	const [updateCrmTask, { loading: isUpdateTaskLoading }] =
		useApolloMutation(UPDATE_CRM_TASK);
	const notify = useNotify();
	const refresh = useRefresh();
	const { data: crmTaskActivities } = useApolloQuery(GET_CRM_TASK_ACTIVITIES);
	const { data: opportunity } = useApolloQuery(GET_OPPORTUNITY_ID, {
		variables: {
			boardId: pipelineId,
		},
	});
	const { data: kanbanBoard } = useGetCrmPipelineBoardQuery();

	useEffect(() => {
		setTaskFormInitialValues((previousTaskFormInitialValues) => ({
			...previousTaskFormInitialValues,
			status: kanbanColumnId,
		}));
	}, [kanbanColumnId]);

	useEffect(() => {
		if (isEmpty(kanbanBoard?.kanban_board)) {
			return;
		}
		const board = kanbanBoard?.kanban_board?.map((value) => value?.id);
		setPipelineId(board);
	}, [kanbanBoard]);

	useEffect(() => {
		if (!users) {
			return;
		}
		const userSuggestion = users.user.map((user: any) => ({
			id: user.id,
			name: `${user?.full_name || ''} `,
			link: user.id,
			avatar: user?.profile_pic || SUGGESTION_DEFAULT_PIC,
		}));
		setMentionSuggestions(userSuggestion);
	}, [users]);

	useEffect(() => {
		if (!CrmTask?.id || !CrmTask?.name) {
			const crmActivity = crmTaskActivities?.crm_task_activity_type?.find(
				(crmTaskActivity: CrmActivity) => crmTaskActivity.value === 'todo'
			);
			setEditorState(EditorState.createEmpty());
			setDueDate(
				dayjs()
					.add(1, 'day')
					.set('hour', DEFAULT_REMINDER_TIME.hour)
					.set('minute', DEFAULT_REMINDER_TIME.minutes)
					.set('second', DEFAULT_REMINDER_TIME.seconds)
					.toISOString()
			);
			setTaskFormInitialValues({
				priority: 'Medium',
				remind_at: 1,
				activity_type_id: crmActivity?.id,
			});
			return;
		}
		const { name, ...restCrmTask } = CrmTask;
		const blocksFromHTML = convertFromHTML(CrmTask?.name);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		setEditorState(EditorState.createWithContent(state, decorator));
		setTaskFormInitialValues((prev) => ({
			...prev,
			...restCrmTask,
			remind_at: getReminderOptionValue(CrmTask?.due_date, CrmTask?.remind_at),
		}));
		setOpportunityId(CrmTask?.opportunity_id || null);
		setAssigneId(CrmTask?.owner_id || null);
		if (CrmTask?.due_date) {
			setDueDate(CrmTask?.due_date);
		}
	}, [CrmTask, crmTaskActivities]);

	const getReminderOptionValue = (dueDate?: string, reminderAt?: string) => {
		if (!dueDate || !reminderAt) {
			return;
		}
		const endDueDate = dayjs(dueDate);
		const reminderDate = dayjs(reminderAt);
		const reminderValue = endDueDate.diff(reminderDate, 'day');
		return reminderValue;
	};

	const getMentions = (editorBlock: any) => {
		if (!editorBlock) {
			return;
		}
		let mentions = [];
		for (let key in editorBlock.entityMap) {
			const entity = editorBlock.entityMap[key];
			if (entity.type === 'mention') {
				mentions.push(entity.data.mention);
			}
		}
		return mentions;
	};

	const getHtmlFormat = async () => {
		if (!editorState) {
			return;
		}
		const contentState = editorState.getCurrentContent();
		const raw = convertToRaw(contentState);
		const mentions = await getMentions(raw);
		const html = draftToHtml(raw, {}, false, customEntityTransform);
		return {
			content: html,
			mentionedUser: mentions,
		};
	};

	const addTask = (task: TaskFormValues) => {
		getHtmlFormat().then((response) => {
			if (!response) {
				return;
			}
			addCrmTask({
				variables: {
					data: {
						name: response?.content,
						description: task?.description,
						opportunity_id: opportunityId || null,
						priority: task?.priority,
						due_date: dueDate,
						activity_type_id: task?.activity_type_id,
						owner_id: head(response?.mentionedUser)?.id || assigneId || null,
						remind_at: getReminderDate(dueDate, task?.remind_at),
						crm_task_tag_mappings: {
							data: task?.tag_id?.map((tag) => ({ tag_id: tag })) || [],
						},
					},
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify('Task Created Successfully');
						refetch();
						refresh();
						onClose();
						setOpportunityId(null);
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	const getReminderDate = (dueDate?: string, numberOfDaysToReduce?: number) => {
		if (!dueDate || !numberOfDaysToReduce) {
			return;
		}
		const reminderDate = dayjs(dueDate)
			.subtract(numberOfDaysToReduce, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString();
		return reminderDate;
	};

	const updateTask = (task: TaskFormValues) => {
		getHtmlFormat().then((response) => {
			if (!response) {
				return;
			}
			updateCrmTask({
				variables: {
					taskId: CrmTask?.id,
					kanbanCardId: CrmTask?.kanbanCardId,
					crmTask: {
						name: response?.content,
						description: task?.description || null,
						opportunity_id: opportunityId || null,
						priority: task?.priority,
						due_date: dueDate,
						activity_type_id: task?.activity_type_id,
						owner_id: head(response?.mentionedUser)?.id || assigneId || null,
						remind_at: getReminderDate(dueDate, task?.remind_at),
					},
					tags: task?.tag_id?.map((tagId: string) => {
						return {
							crm_task_id: CrmTask?.id,
							tag_id: tagId,
						};
					}),
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify('Task Updated Successfully');
						refetch();
						refresh();
						onClose();
						setOpportunityId(null);
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	const onSaveTask = (task: TaskFormValues) => {
		if (CrmTask?.id) {
			updateTask(task);
		} else {
			addTask(task);
		}
	};

	const setSearchedUserValue = useCallback(
		debounce((newValue) => setSearchedUser(newValue), 500),
		[]
	);

	const onSearchChange = ({ value }: { value: string }) => {
		if (!value || value.length < 2) {
			return;
		}
		setSearchedUserValue(value);
	};

	const getOpportunityValue = (oppId: string) => {
		const response = opportunity?.opportunities?.find(
			(value: any) => value?.id === oppId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getAssigneValue = (userId: string) => {
		const response = assignedUser?.user?.find((value) => value?.id === userId);
		return response
			? {
					id: response?.id,
					name: `${response?.full_name} `,
			  }
			: null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={taskFormStyles.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{CrmTask?.id ? 'Edit Task' : 'Add Task'}
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={onClose} />
					</Box>
					<Form initialValues={taskFormInitialValues} onSubmit={onSaveTask}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Task*</Typography>
									<Box className={taskFormStyles.editor}>
										<Editor
											plugins={plugins}
											editorState={editorState}
											onChange={(editorState) => {
												if (!editorState) {
													return;
												}
												setEditorState(editorState);
											}}
										/>
										<MentionSuggestions
											open={isSuggestionOpen}
											onSearchChange={onSearchChange}
											suggestions={mentionSuggestions}
											onOpenChange={(event) => {
												setIsSuggestionOpen(event);
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Opportunity
										</Typography>
										<AutocompleteSearch
											placeholder={'Search opportunity'}
											option={
												opportunity?.opportunities &&
												opportunity?.opportunities?.length > 0
													? opportunity?.opportunities?.map(
															({ name, id }: { name: string; id: string }) => {
																return {
																	name,
																	id,
																};
															}
													  )
													: []
											}
											onChange={setOpportunityId}
											value={getOpportunityValue(opportunityId || '')}
											name={'opportunity_id'}
											validates={false}
										/>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Due Date
												</Typography>
												<ThemeProvider theme={dateTimePickerTheme}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DateTimePicker
															variant='inline'
															value={dueDate}
															format={`${dateFormat} h:mm A`}
															onChange={(e) => {
																if (!e) {
																	return;
																}
																setDueDate(e?.toISOString());
															}}
															label=''
															showTodayButton
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<IconButton>
																			<EventIcon />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</MuiPickersUtilsProvider>
												</ThemeProvider>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Reminder
												</Typography>
												<SelectInput
													source='remind_at'
													label=''
													choices={[
														{ id: 1, name: '1 day before' },
														{ id: 3, name: '3 days before' },
														{ id: 7, name: '1 week before' },
													]}
												/>
											</Box>
										</Box>
									</Box>
									<Divider />
									<Box mt={1} display='flex' justifyContent='space-between'>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>Type</Typography>
											<ReferenceInput
												source='activity_type_id'
												reference='crm_task_activity_type'
												label=''
												sort={{ field: 'label', order: 'ASC' }}
											>
												<SelectInput optionText='label' />
											</ReferenceInput>
										</Box>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>
												Priority
											</Typography>
											<SelectInput
												source='priority'
												label=''
												fullWidth={true}
												choices={[
													{ id: 'Low', name: 'Low' },
													{ id: 'Medium', name: 'Medium' },
													{ id: 'High', name: 'High' },
												]}
											/>
										</Box>
										<Box className={taskFormStyles.floatInput}>
											<Typography className={classes.label}>
												Assigned to
											</Typography>
											<Field name='owner_id'>
												{(props: any) => (
													<AutocompleteSearch
														placeholder={'Search user'}
														option={
															assignedUser?.user &&
															assignedUser?.user?.length > 0
																? orderBy(
																		assignedUser?.user?.map((value) => {
																			return {
																				id: value?.id,
																				name: `${value?.full_name} `,
																			};
																		}),
																		[(assigne) => assigne?.name.toUpperCase()],
																		['asc']
																  )
																: []
														}
														onChange={(event) => {
															setAssigneId(event);
															props.input.onChange(event);
														}}
														value={getAssigneValue(assigneId || '')}
														name={'owner_id'}
														validates={false}
													/>
												)}
											</Field>
										</Box>
									</Box>

									<Typography className={classes.label}>Notes</Typography>
									<TextInput multiline source='description' label='' />
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										className={
											invalid ||
											!editorState.getCurrentContent().getPlainText().trim()
												? [
														classes.disabledButton ||
															isAddTaskLoading ||
															isUpdateTaskLoading,
														taskFormStyles.saveButton,
												  ].join(' ')
												: [classes.saveButton, taskFormStyles.saveButton].join(
														' '
												  )
										}
										disabled={
											invalid ||
											isAddTaskLoading ||
											isUpdateTaskLoading ||
											!editorState.getCurrentContent().getPlainText().trim()
										}
										type='submit'
									>
										{taskFormInitialValues.id ? 'Update' : 'Create'}
									</Button>
									<Button onClick={onClose} className={classes.cancelButton}>
										Cancel
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CrmTaskForm;
