import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	makeStyles,
	Avatar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Checkbox,
	ThemeProvider,
	Box,
	InputAdornment,
	IconButton,
	createTheme,
	TablePagination,
	Tooltip,
} from '@material-ui/core';

import {
	TIME_SHEET_APPROVAL_SUCCESS_TEXT,
	TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL,
	TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT,
	TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL,
	TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT,
	TIME_SHEET_REJECT_SUCCESS_TEXT,
	TIME_SHEET_REJECT_MODAL_BUTTON_LABEL,
} from './constant';
import { SelectInput } from 'react-admin';
import EventIcon from '@material-ui/icons/Event';

import { modalFormStyle } from '../../Layout/styles';
import {
	useApproveTimesheetMutation,
	useGetTimesheetStatusQuery,
	useGetOrganisationManHoursQuery,
	GetEmployeeAdminStatusQuery,
	useGetEmployeeAdminStatusQuery,
	useGetDraftTimesheetEntriesAdminViewQuery,
	useUpdateTimesheetStatusMutation,
	useRejectTimesheetEntriesMutation,
	useInsertTimesheetSubmissionNotesMutation,
	useGetEmployeeIdQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import {
	SubmittedTimesheets,
	TimesheetSubmission,
	User,
} from './Timesheet.model';
import DateFnsUtils from '@date-io/dayjs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ConfirmationModal from '../../SharedComponents/ConfirmationModal.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReferenceInput, AutocompleteInput, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { TimesheetStyles } from './TimesheetStyles';
import {
	getBillableHours,
	getTotalNonBillableHours,
	getTotalBillableHours,
	getTimesheetEntriesBillablePercentage,
} from './Timesheet.utils';
import TimesheetAdminDetailsModal from './TimesheetAdminDetailsModal.component';
import { START_DATE_ERROR } from '../Projects/constant';
import { modalFormTheme } from '../../Layout/Theme.component';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { chain, isEmpty, orderBy, head } from 'lodash';
import no_data_found from '../../assets/no_data_found.png';
import { ErrorTooltip } from '../Attendance/Style';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import duration from 'dayjs/plugin/duration';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { SpeakerNotes } from '@material-ui/icons';
import TimesheetCommentCapture from './TimesheetCommentCapture.component';
dayjs.extend(duration);
dayjs.extend(weekOfYear);

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	projectName: {
		fontSize: '12px',
		width: '112px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '140px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-64px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	unlockButton: {
		padding: '0px',
		color: '#40a9ff',
	},
}));

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const TimesheetPendingApprovalStyle = makeStyles({
	container: {},
	actionsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	calendarIcon: {
		width: '16px',
		height: '16px',
		color: '#888888',
		marginRight: '12px',
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	avatar: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	employeeName: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
		marginLeft: '12px',
	},
	totalHoursContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	durationIcon: {
		width: '15px',
		height: '15px',
		color: '#4285F4',
		marginRight: '5px',
	},
	timesheetsContainer: {
		marginBottom: '34px',
	},
	approvedIcon: {
		width: '22px',
		height: '22px',
		color: '#3FA543',
		margin: '5px 0px 0px 22px',
	},
	dataActionContainer: {
		width: '10px',
	},
	checkBox: {
		padding: '0px 0px 0px 22px',
	},
	button: {
		width: 'fit-content',
	},
	actionAdminContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});

export const TimesheetAdmin = () => {
	const {
		id: userId,
		orgId,
		dateFormat,
		isDraftTimesheetEnabled,
	} = useContext<any>(UserProfileContext);
	const modalFormStyles = modalFormStyle();
	const timesheetPendingApprovalStyles = TimesheetPendingApprovalStyle();
	const timesheetStyles = TimesheetStyles();
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [submissionIds, setSubmissionIds] = useState<any>([]);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [isMoveToDraft, setIsMoveToDraft] = useState(false);
	const [
		moveToDraftTimesheetSubmissionId,
		setMoveToDraftTimesheetSubmissionId,
	] = useState(null);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [draftStatusId, setDraftStatusId] = useState<any>();
	const [isRejectTimesheet, setIsRejectTimesheet] = useState(false);
	const [isApproveTimesheet, setIsApproveTimesheet] = useState(false);
	const [submittedTimesheets, setSubmittedTimesheets] =
		useState<GetEmployeeAdminStatusQuery>();
	const [isCommentCaptureShown, setIsCommentCaptureShown] = useState(false);

	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const notify = useNotify();
	const styles = modalFormStyle();
	const classes = useStyles();

	const [employeeId, setEmployeeId] = useState<any>();
	const { data: empInfo } = useGetEmployeeIdQuery({
		variables: {
			userId: employeeId,
		},
	});
	
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);

	const { data: organisationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId: orgId,
		},
		fetchPolicy: 'network-only',
	});

	const [timesheetStatusId, setTimesheetStatusId] = useState<any>();
	const [timeSheetStatusOptions, setTimeStatusOptions] = useState([]);

	const {
		data: submittedTimeSheet,
		loading: submittedTimesheetsLoading,
		refetch: refetchSubmittedTimesheets,
	} = useGetEmployeeAdminStatusQuery({
		variables: {
			userId: employeeId,
			startDate: startDate,
			endDate: endDate,
			statusId:
				timesheetStatusId === 'All'
					? isDraftTimesheetEnabled
						? {}
						: { _neq: draftStatusId }
					: { _eq: timesheetStatusId },
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: draftTimesheetEntries,
		loading: draftTimesheetEntriesLoading,
		refetch: refetchDraftTimesheetEntries,
	} = useGetDraftTimesheetEntriesAdminViewQuery({
		variables: {
			userId: employeeId,
			startDate: startDate,
			endDate: endDate,
		},
		fetchPolicy: 'network-only',
	});

	const { data: timesheetStatus } = useGetTimesheetStatusQuery({
		fetchPolicy: 'network-only',
	});
	const [approveTimeSheet, { loading: isApproveTimesheetLoading }] =
		useApproveTimesheetMutation();
	const [rejectTimesheet, { loading: isRejectTimesheetLoading }] =
		useRejectTimesheetEntriesMutation();
	const [updateTimeSheetStatus, { loading: isUpdateTimeSheetStatusLoading }] =
		useUpdateTimesheetStatusMutation();

	const [insertTimesheetSubmissionNotes, { loading: isTimeSheetLoading }] =
		useInsertTimesheetSubmissionNotesMutation();
	useEffect(() => {
		if (isEmpty(timesheetStatus?.timesheet_status)) {
			return;
		}
		const timesheetDraftStatus = timesheetStatus?.timesheet_status.filter(
			(status) => status.value === 'draft'
		);

		// Timesheet Status except draft
		const timesheetStatusOptionsExceptDraft: any =
			timesheetStatus?.timesheet_status
				?.filter((value) => value.value !== 'draft')
				.map((statusVal) => {
					if (statusVal?.value === 'submitted') {
						setTimesheetStatusId(statusVal?.id);
					}
					return {
						id: statusVal?.id,
						name:
							statusVal?.value === 'submitted' ? 'Pending' : statusVal?.label,
					};
				});
		const sortedTimesheetStatusOptionsExceptDraft = orderBy(
			timesheetStatusOptionsExceptDraft,
			['name'],
			['asc']
		);
		// All Timesheet Status
		const timesheetStatusOptions: any = timesheetStatus?.timesheet_status?.map(
			(timesheet) => {
				if (!timesheet) {
					return;
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			}
		);
		const sortedTimesheetStatusOptions: any = orderBy(
			timesheetStatusOptions,
			['name'],
			['asc']
		);
		setTimeStatusOptions(
			isDraftTimesheetEnabled
				? sortedTimesheetStatusOptions
				: sortedTimesheetStatusOptionsExceptDraft
		);
		if (timesheetDraftStatus) {
			setDraftStatusId(timesheetDraftStatus[0]?.id);
		}
	}, [timesheetStatus, isDraftTimesheetEnabled]);

	useEffect(() => {
		if (!submittedTimeSheet || !draftTimesheetEntries) {
			return;
		}
		setSelectedRows([]);
		setSubmissionIds([]);

		const isDraftStatusSelected =
			timesheetStatusId === draftStatusId || timesheetStatusId === 'All';

		// Draft Timesheets
		const draftSubmittedTimesheetEntries = draftTimesheetEntries.user
			.filter((user) => user.timesheets.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: chain(
					user.timesheets.map((draftEntry) => ({
						...draftEntry,
						week: `${dayjs(draftEntry.date).format('YYYY')}-${dayjs(
							draftEntry.date
						).week()}`,
					}))
				)
					.groupBy('week')
					.map((week, index) => ({
						id: index,
						timesheets: week,
						timesheetSubmissionNotes: [],
						timesheet_status: { id: 'draft', label: 'Draft', value: 'draft' },
					}))
					.value(),
			}));

		// Submitted Timesheets
		const timesheets = submittedTimeSheet.user
			.filter((user) => user.timesheetSubmissions.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: user.timesheetSubmissions.filter(
					(timesheetSubmission) => timesheetSubmission.timesheets.length > 0
				),
			}));

		const isSubmittedTimesheetEmpty =
			timesheets.filter((value) => value.timesheetSubmissions.length > 0)
				.length === 0;

		// Combine draft and submitted timesheets
		const allTimesheets = !isSubmittedTimesheetEmpty
			? timesheets.map((user) => {
					const draftEntries = draftSubmittedTimesheetEntries.find(
						(draftEntriesUser) => draftEntriesUser.id === user.id
					)?.timesheetSubmissions;

					if (draftEntries) {
						return {
							...user,
							timesheetSubmissions: [
								...user.timesheetSubmissions,
								...draftEntries,
							],
						};
					}
					return user;
			  })
			: draftSubmittedTimesheetEntries;

		if (timesheets) {
			setSubmittedTimesheets({
				user:
					isDraftTimesheetEnabled && isDraftStatusSelected
						? allTimesheets
						: timesheets,
			});
		}
	}, [
		submittedTimeSheet,
		draftTimesheetEntries,
		timesheetStatusId,
		draftStatusId,
		isDraftTimesheetEnabled,
	]);

	const handleMoveTimesheetToDraft = async () => {
		if (!timesheetStatus || !moveToDraftTimesheetSubmissionId) {
			return;
		}

		const draftTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'draft'
		);

		const timesheetDraft = await updateTimeSheetStatus({
			variables: {
				timesheetSubmissionId: moveToDraftTimesheetSubmissionId,
				timesheetStatusId: draftTimesheetStatus?.id,
			},
		});

		if (!timesheetDraft.errors) {
			setSubmissionIds([]);
			refetchSubmittedTimesheets();
			refetchDraftTimesheetEntries();
			setIsRejectTimesheet(false);
			setIsMoveToDraft(false);
			setIsConfirmationModalShown(false);
			notify(TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT);
		}
	};

	const handleApproveTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		const approvedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveTimeSheet({
			variables: {
				submissionIds: submissionIds,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			setIsMoveToDraft(false);
			setSubmissionIds([]);
			refetchSubmittedTimesheets();
			refetchDraftTimesheetEntries();
			setIsConfirmationModalShown(false);
			setIsRejectTimesheet(false);
			notify(TIME_SHEET_APPROVAL_SUCCESS_TEXT);
		}
	};
	const handleRejectTimesheet = () => {
		if (!timesheetStatus) {
			return;
		}
		setIsCommentCaptureShown(true);
	};
	const rejectTimesheets = async (notes: string) => {
		try {
			if (!timesheetStatus) {
				return;
			}
			const rejectedTimesheetStatus = timesheetStatus.timesheet_status.find(
				(status) => status.value === 'rejected'
			);
			const timesheetRejectNotesMutationRes =
				await insertTimesheetSubmissionNotes({
					variables: {
						data: Array.from(new Set(submissionIds)).map(
							(submissionId: any) => ({
								notes: notes,
								type: 'rejected',
								timesheet_submission_id: submissionId,
							})
						),
					},
				});

			if (timesheetRejectNotesMutationRes.errors) {
				setSubmissionIds([]);
				setIsRejectTimesheet(false);
				setIsApproveTimesheet(false);
				setIsMoveToDraft(false);
				setIsConfirmationModalShown(false);
				notify(
					head(timesheetRejectNotesMutationRes.errors)?.message ||
						'Rejection failed'
				);
				return;
			}
			setIsCommentCaptureShown(false);
			const timesheetReject = await rejectTimesheet({
				variables: {
					submissionIds: submissionIds,
					timesheetStatusId: rejectedTimesheetStatus?.id,
				},
			});
			if (!timesheetReject.errors) {
				setSubmissionIds([]);
				setIsRejectTimesheet(false);
				setIsApproveTimesheet(false);
				setIsConfirmationModalShown(false);
				notify(TIME_SHEET_REJECT_SUCCESS_TEXT);
				refetchSubmittedTimesheets();
				refetchDraftTimesheetEntries();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getTimesheetWeek = (timesheets: SubmittedTimesheets[]) => {
		if (!timesheets || !timesheets[0]?.date) {
			return;
		}
		const startDate = dayjs(timesheets[0].date).day(0).format('DD MMM YYYY');
		const endDate = dayjs(timesheets[0].date).day(6).format('DD MMM YYYY');
		return `${startDate || ''} - ${endDate || ''}`;
	};

	const getTimesheetBillablePercentage = (
		timesheets: SubmittedTimesheets[]
	) => {
		if (!timesheets || !organisationManHours) {
			return;
		}
		const totalHoursPerWeek =
			organisationManHours.organization_by_pk?.working_hours * 7;

		const totalBillableHours = getBillableHours(timesheets);

		return totalBillableHours
			? ((totalBillableHours?.asHours() / totalHoursPerWeek) * 100).toFixed()
			: 0;
	};

	const getTotalHours = (timesheets: SubmittedTimesheets[]) => {
		if (!timesheets) {
			return;
		}
		const timesheetDailyHours = timesheets.map(
			(timesheet) => timesheet.working_hours
		);

		const totalHours = timesheetDailyHours
			.map((timesheetDailyHour) => timesheetDailyHour.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return (
			`${Math.floor(totalHours.asHours())}:${totalHours.format('mm')}` ||
			'00:00'
		);
	};

	const checkIfDraftEntriesSelected = () => {
		if (selectedRows?.length === 0) {
			return false;
		}
		const draftEntries = selectedRows?.filter(
			(timesheet: any) => timesheet?.status?.value === 'draft'
		);
		if (draftEntries?.length > 0) {
			return true;
		}
		return false;
	};

	const handleRowClick = (
		timesheetSubmission: TimesheetSubmission,
		userDetails: User
	) => {
		if (!timesheetSubmission?.timesheets[0]?.date) {
			return;
		}
		setSelectedTimesheet({
			user: {
				id: userDetails.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			dateRange: getTimesheetWeek(timesheetSubmission.timesheets),
			billablePercentage: getTimesheetEntriesBillablePercentage(
				timesheetSubmission.timesheets
			),
			billableHours: getTotalBillableHours(timesheetSubmission.timesheets),
			nonBillableHours: getTotalNonBillableHours(
				timesheetSubmission.timesheets
			),
			totalHours: getTotalHours(timesheetSubmission.timesheets),
			timesheetSubmissionId: timesheetSubmission.id,
			timesheetPeriod: Array(7)
				.fill(1)
				.map((value: number, index: number) =>
					dayjs(timesheetSubmission.timesheets[0].date)
						.day(index)
						.format('YYYY-MM-DD')
				),
			startDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(0)
				.format('YYYY-MM-DD'),
			endDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(6)
				.format('YYYY-MM-DD'),
			timesheetStatus: timesheetSubmission?.timesheet_status?.value,
		});
	};

	const mapToFullName = (status: { full_name: string }) =>
		`${status?.full_name || ''}`;

	const onEmployeeChange = (id: React.ChangeEvent<{}>) => {
		if (id === null) {
			setEmployeeId('');
		}
		setEmployeeId(id);
	};

	const [isApprovedStatus, setIsApprovedStatus] = useState(false);

	const getApprovedStatus = (event: string) => {
		const approvedTimesheetStatus = timesheetStatus?.timesheet_status?.find(
			(status) => status.id === event
		);
		if (
			approvedTimesheetStatus?.value === 'approved' ||
			approvedTimesheetStatus?.value === 'rejected'
		) {
			setIsApprovedStatus(true);
		} else {
			setIsApprovedStatus(false);
		}
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getSubmitButtonLabel = () => {
		if (isRejectTimesheet) {
			return TIME_SHEET_REJECT_MODAL_BUTTON_LABEL;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL;
		}
		return TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL;
	};

	const getConfirmationMessage = () => {
		if (isRejectTimesheet) {
			return `Do you really want to reject ${
				Array.from(new Set(submissionIds))?.length
			} record(s)?`;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT;
		}
		return `Do you really want to approve ${
			Array.from(new Set(submissionIds))?.length
		} record(s)?`;
	};

	return (
		<>
			<div className={timesheetPendingApprovalStyles.container}>
				<div className={timesheetPendingApprovalStyles.actionAdminContainer}>
					<ThemeProvider theme={modalFormTheme}>
						<Box>
							<Box className={styles.label}>Search Employee</Box>
							<Form onSubmit={() => {}}>
								{() => (
									<>
										<ReferenceInput
											source='user'
											label=''
											reference='user'
											sort={{ field: 'first_name', order: 'ASC' }}
											filterToQuery={(searchText) => ({
												first_name: searchText,
											})}
											filter={{
												first_name: true,
											}}
											onChange={(event) => {
												onEmployeeChange(event);
											}}
										>
											<AutocompleteInput
												resettable={true}
												optionText={mapToFullName}
											/>
										</ReferenceInput>
									</>
								)}
							</Form>
						</Box>
						<Box className={classes.projectFilterStatus} marginTop='8px'>
							<Form
								onSubmit={() => {}}
								initialValues={{ assignee: timesheetStatusId }}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'assignee'}
												label={''}
												onChange={(event) => {
													setTimesheetStatusId(event?.target?.value);
													getApprovedStatus(event.target.value);
												}}
												choices={
													timeSheetStatusOptions &&
													timeSheetStatusOptions?.length > 0
														? [
																{
																	id: 'All',
																	name: 'All',
																},
																...timeSheetStatusOptions,
														  ]
														: []
												}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
					</ThemeProvider>
					<ThemeProvider theme={dateTimePickerTheme}>
						<Box display='flex' marginBottom='25px'>
							<ErrorTooltip
								title={startDateError ? START_DATE_ERROR : ''}
								placement='top'
								arrow={true}
							>
								<Box width='188px' marginLeft='20px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											autoOk={true}
											variant='inline'
											onChange={(date: any) => {
												if (dayjs(date).isAfter(dayjs(endDate))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
												setStartDate(dayjs(date).format('YYYY-MM-DD'));
											}}
											value={startDate}
											error={startDateError}
											format={dateFormat}
											label='Start date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</ErrorTooltip>
							<Box width='188px'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										autoOk={true}
										variant='inline'
										onChange={(date: any) => {
											if (dayjs(startDate).isAfter(dayjs(date))) {
												setStartDateError(true);
											} else {
												setStartDateError(false);
											}
											setEndDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										value={endDate}
										format={dateFormat}
										label='End date'
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton>
														<EventIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</Box>
					</ThemeProvider>
					<Box display='flex'>
						<Box marginRight='5px'>
							<Button
								disabled={
									submissionIds.length === 0 ||
									isApprovedStatus ||
									checkIfDraftEntriesSelected()
								}
								className={`${timesheetPendingApprovalStyles.button}
								${
									submissionIds.length === 0 ||
									isApprovedStatus ||
									checkIfDraftEntriesSelected()
										? modalFormStyles.disabledButton
										: modalFormStyles.rejectButton
								}
								`}
								onClick={() => {
									setIsRejectTimesheet(true);
									setIsConfirmationModalShown(true);
									setIsApproveTimesheet(false);
								}}
							>
								Reject
							</Button>
						</Box>
						<Box>
							<Button
								disabled={
									submissionIds.length === 0 ||
									isApprovedStatus ||
									checkIfDraftEntriesSelected()
								}
								className={`${timesheetPendingApprovalStyles.button}
								${
									submissionIds.length === 0 ||
									isApprovedStatus ||
									checkIfDraftEntriesSelected()
										? modalFormStyles.disabledButton
										: modalFormStyles.approveButton
								}
								`}
								onClick={() => {
									setIsConfirmationModalShown(true);
									setIsRejectTimesheet(false);
									setIsApproveTimesheet(true);
								}}
							>
								Approve
							</Button>
						</Box>
					</Box>
				</div>
				{employeeId &&
				submittedTimesheets &&
				submittedTimesheets?.user?.length > 0 ? (
					<div>
						{submittedTimesheets?.user.map((user) =>
							user.timesheetSubmissions.length > 0 ? (
								<div
									className={timesheetPendingApprovalStyles.timesheetsContainer}
									key={user.id}
								>
									<div
										className={timesheetPendingApprovalStyles.avatarContainer}
									>
										<Avatar
											className={timesheetPendingApprovalStyles.avatar}
											src={`${user.profile_pic || ''}`}
										>
											{user?.first_name?.charAt(0).toUpperCase() || ''}
											{user?.last_name?.charAt(0).toUpperCase() || ''}
										</Avatar>
										<Typography
											className={timesheetPendingApprovalStyles.employeeName}
										>
											{`${user.full_name || ''}`}
										</Typography>
									</div>
									{submittedTimesheetsLoading ||
									draftTimesheetEntriesLoading ? (
										<Loading />
									) : (
										<Table>
											<TableHead>
												<TableRow>
													<CustomTableCell></CustomTableCell>
													<CustomTableCell>DATE</CustomTableCell>
													<CustomTableCell>STATUS</CustomTableCell>
													<CustomTableCell>TOTAL HOURS</CustomTableCell>
													<CustomTableCell>BILLABLE HOURS</CustomTableCell>
													<CustomTableCell>BILLABLE (%)</CustomTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{user.timesheetSubmissions
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((timesheetSubmission, index) =>
														timesheetSubmission.timesheets.length > 0 ? (
															<TableRow
																hover={true}
																key={timesheetSubmission.id}
															>
																<CustomTableCell
																	className={
																		timesheetPendingApprovalStyles.dataActionContainer
																	}
																>
																	{timesheetSubmission.timesheet_status
																		.value === 'approved' ? (
																		<CheckCircleIcon
																			className={
																				timesheetPendingApprovalStyles.approvedIcon
																			}
																		/>
																	) : (
																		<Checkbox
																			id={`${timesheetSubmission.timesheet_status?.value}${index}`}
																			className={
																				timesheetPendingApprovalStyles.checkBox
																			}
																			checked={
																				timesheetSubmission.timesheet_status
																					?.value !== 'approved' &&
																				timesheetSubmission.timesheet_status
																					?.value !== 'rejected' &&
																				submissionIds?.includes(
																					timesheetSubmission.id
																				)
																			}
																			disabled={
																				timesheetSubmission.timesheet_status
																					.value !== 'submitted'
																					? true
																					: false
																			}
																			onChange={(e) => {
																				if (e.currentTarget.checked) {
																					const row = {
																						id: timesheetSubmission?.id,
																						status:
																							timesheetSubmission.timesheet_status,
																					};
																					setSelectedRows([
																						...selectedRows,
																						row,
																					]);

																					setSubmissionIds([
																						...submissionIds,
																						timesheetSubmission.id,
																					]);
																				} else {
																					setSelectedRows(
																						selectedRows.filter(
																							(timesheet: any) =>
																								timesheet.id !==
																								timesheetSubmission.id
																						)
																					);

																					setSubmissionIds(
																						submissionIds.filter(
																							(id: any) =>
																								id !== timesheetSubmission.id
																						)
																					);
																				}
																			}}
																			name='checkedB'
																			color='primary'
																		/>
																	)}
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={
																			timesheetPendingApprovalStyles.dateContainer
																		}
																	>
																		<DateRangeIcon
																			className={
																				timesheetPendingApprovalStyles.calendarIcon
																			}
																		/>
																		{getTimesheetWeek(
																			timesheetSubmission.timesheets
																		) || '- -'}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={`${
																			timesheetStyles.statusContainer
																		}
															${
																timesheetSubmission.timesheet_status.value ===
																'submitted'
																	? timesheetStyles.submittedStatus
																	: timesheetSubmission.timesheet_status
																			.value === 'approved'
																	? timesheetStyles.approvedStatus
																	: timesheetStyles.rejectedStatus
															}
														`}
																	>
																		{timesheetSubmission.timesheet_status
																			.value === 'submitted'
																			? 'Pending'
																			: timesheetSubmission.timesheet_status
																					.label}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={
																			timesheetPendingApprovalStyles.totalHoursContainer
																		}
																	>
																		<AccessTimeIcon
																			className={
																				timesheetPendingApprovalStyles.durationIcon
																			}
																		/>
																		{`${getTotalHours(
																			timesheetSubmission.timesheets
																		)} hours`}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	{`${getTotalBillableHours(
																		timesheetSubmission.timesheets
																	)} hours`}
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	{`${getTimesheetBillablePercentage(
																		timesheetSubmission.timesheets
																	)}%`}
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		if (
																			timesheetSubmission.timesheet_status
																				.value !== 'approved'
																		) {
																			setIsDetailsModalShown(true);
																			handleRowClick(timesheetSubmission, {
																				id: user.id,
																				name: `${user?.full_name}`,
																				profilePic: user.profile_pic,
																			});
																		}
																	}}
																>
																	{timesheetSubmission.timesheet_status
																		.value === 'rejected' && (
																		<Tooltip
																			title={
																				head(
																					timesheetSubmission.timesheetSubmissionNotes
																				)?.notes || '- -'
																			}
																			placement='top'
																			arrow
																		>
																			<SpeakerNotes />
																		</Tooltip>
																	)}
																	{timesheetSubmission.timesheet_status
																		.value === 'approved' && (
																		<Tooltip
																			title={'Move to draft'}
																			placement='top'
																			arrow
																		>
																			<IconButton
																				className={classes.unlockButton}
																				onClick={() => {
																					setMoveToDraftTimesheetSubmissionId(
																						timesheetSubmission.id
																					);
																					setIsMoveToDraft(true);
																					setIsConfirmationModalShown(true);
																					setIsRejectTimesheet(false);
																					setIsApproveTimesheet(false);
																				}}
																			>
																				<LockOpenIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																</CustomTableCell>
															</TableRow>
														) : (
															<Box textAlign='center'>
																<img src={no_data_found} alt='no_data_found' />
															</Box>
														)
													)}
											</TableBody>
										</Table>
									)}
									<Box display='flex' justifyContent='flex-end'>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											count={user.timesheetSubmissions?.length || 0}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
								</div>
							) : (
								<Box textAlign='center'>
									<img src={no_data_found} alt='no_data_found' />
								</Box>
							)
						)}
					</div>
				) : (
					<Box textAlign='center'>
						<img src={no_data_found} alt='no_data_found' />
					</Box>
				)}
			</div>

			<ConfirmationModal
				open={isConfirmationModalShown}
				onClose={() => {
					setIsMoveToDraft(false);
					setIsConfirmationModalShown(false);
					setMoveToDraftTimesheetSubmissionId(null);
				}}
				isWarningConfirmation={isRejectTimesheet || isMoveToDraft}
				confirmationMessage={getConfirmationMessage()}
				onSubmit={() => {
					if (isRejectTimesheet) {
						handleRejectTimesheet();
						return;
					}
					if (isApproveTimesheet) {
						handleApproveTimesheet();
						return;
					}
					if (isMoveToDraft) {
						handleMoveTimesheetToDraft();
						return;
					}
				}}
				submitButtonLabel={getSubmitButtonLabel()}
				loading={
					isRejectTimesheetLoading ||
					isApproveTimesheetLoading ||
					isUpdateTimeSheetStatusLoading
				}
			/>
			<TimesheetCommentCapture
				open={isCommentCaptureShown}
				onClose={() => {
					setIsCommentCaptureShown(false);
				}}
				onSubmit={(value) => {
					rejectTimesheets(value?.notes || '');
				}}
				okButtonLabel={'Save'}
				isLoading={isTimeSheetLoading}
			/>
			<TimesheetAdminDetailsModal
				empId={employeeId}
				locationId={head(empInfo?.employee)?.location_id}
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedTimesheet({});
				}}
				timesheet={selectedTimesheet}
				onSuccess={() => {
					refetchSubmittedTimesheets();
					refetchDraftTimesheetEntries();
				}}
			/>
		</>
	);
};

export default TimesheetAdmin;
