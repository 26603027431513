import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import './table-antdStyle.css';
import { insightChartTheme } from '../../Layout/Theme.component';

export const EmployeeByDepartment = (props) => {
	const { departmentDetails, loading } = props;
	const [tableData, setTableData] = useState([]);
	const columns = [
		{
			title: 'DEPARTMENT',
			dataIndex: 'name',
		},
		{
			title: 'EMPLOYEE COUNT',
			dataIndex: 'count',
		},
	];

	useEffect(() => {
		if (!departmentDetails) {
			return;
		}
		const department = departmentDetails?.map((item, index) => {
			return {
				key: index,
				name: item?.name,
				count: item?.employees_aggregate?.aggregate?.count,
			};
		});
		setTableData(department);
	}, [departmentDetails]);
	return (
		<div>
			{loading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Distribution by Department'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer height={355}>
								{tableData && tableData?.length > 0 ? (
									<Table
										size='small'
										columns={columns}
										dataSource={tableData}
										scroll={{
											x: '100w',
											y: 300,
										}}
									/>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
