import React, { useEffect, useState } from 'react';

import { SelectInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { monthOptions } from '../../constant';

import { Typography, Box, ThemeProvider, makeStyles } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPDATE_ORGANISATION } from '../Settings.gql';
import dayjs from 'dayjs';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

const customStyle = makeStyles({
	dateContainer: {
		marginRight: '10px',
		width: '10.5vw',
	},
	finacialYearPeriodContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '12px',
	},
});

interface FinancialYearSettingsProps {
	organisationId?: string;
	organisationFinanicialYear: {
		financial_year_end_date?: string;
		financial_year_end_month?: string;
		financial_year_start_date?: string;
		financial_year_start_month?: string;
	};
	onSuccess: () => void;
}

interface FinancialYear {
	financial_year_end_date?: string;
	financial_year_end_month?: string;
	financial_year_start_date?: string;
	financial_year_start_month?: string;
}

const FinancialYearSettings = ({
	organisationId,
	organisationFinanicialYear,
	onSuccess,
}: FinancialYearSettingsProps) => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyles = customStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [finanacialYearPeriod, setFinanacialYearPeriod] = useState({
		startDate: 0,
		endDate: 0,
		endMonth: 11,
		period: '',
	});
	const [
		updateOrgFinancialYear,
		{
			data: updatedOrgFinancialYear,
			loading: isUpdatedOrgFinancialYearLoading,
		},
	] = useApolloMutation(UPDATE_ORGANISATION);

	useEffect(() => {
		if (updatedOrgFinancialYear) {
			notify('Financial Year Updated');
			refresh();
		}
	}, [updatedOrgFinancialYear, notify, refresh]);

	useEffect(() => {
		if (updatedOrgFinancialYear) {
			onSuccess();
		}
	}, [updatedOrgFinancialYear, onSuccess]);

	useEffect(() => {
		if (!organisationFinanicialYear) {
			return;
		}
		const startDate = dayjs()
			.date(1)
			.month(Number(organisationFinanicialYear.financial_year_start_month))
			.format('YYYY-MM-DD');
		const endDate = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('YYYY-MM-DD');
		const startingPeriod = dayjs()
			.date(1)
			.month(Number(organisationFinanicialYear.financial_year_start_month))
			.format('DD-MMM');
		const endingPeriod = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('DD-MMM');

		setFinanacialYearPeriod({
			startDate: dayjs(startDate).date(),
			endDate: dayjs(endDate).date(),
			endMonth: dayjs(endDate).month(),
			period: `From ${startingPeriod} to ${endingPeriod}`,
		});
	}, [organisationFinanicialYear]);

	const calculateFinancialYear = (monthIndex: string) => {
		const startDate = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('YYYY-MM-DD');
		const endDate = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('YYYY-MM-DD');
		const startingPeriod = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('DD-MMM');
		const endingPeriod = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('DD-MMM');

		setFinanacialYearPeriod({
			startDate: dayjs(startDate).date(),
			endDate: dayjs(endDate).date(),
			endMonth: dayjs(endDate).month(),
			period: `From ${startingPeriod} to ${endingPeriod}`,
		});
	};

	const onHandleSubmit = (financialYear: FinancialYear) => {
		if (!financialYear) {
			return;
		}

		updateOrgFinancialYear({
			variables: {
				organisationId: organisationId,
				data: {
					financial_year_start_month:
						financialYear.financial_year_start_month?.toString(),
					financial_year_start_date: finanacialYearPeriod.startDate?.toString(),
					financial_year_end_month: finanacialYearPeriod.endMonth?.toString(),
					financial_year_end_date: finanacialYearPeriod.endDate?.toString(),
				},
			},
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form
					initialValues={{
						financial_year_start_month:
							Number(organisationFinanicialYear.financial_year_start_month) ||
							0,
					}}
					onSubmit={onHandleSubmit}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Start Month</Typography>
								</Box>
								<Box className={customStyles.dateContainer}>
									<SelectInput
										source='financial_year_start_month'
										label=''
										choices={monthOptions}
										onChange={(e) => {
											calculateFinancialYear(e.target.value);
										}}
									/>
								</Box>
							</Box>
							<Box className={customStyles.finacialYearPeriodContainer}>
								<Typography>{finanacialYearPeriod.period}</Typography>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isLoading={isUpdatedOrgFinancialYearLoading}
									isDisabled={invalid || pristine || isUpdatedOrgFinancialYearLoading}
									title='Save'
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default FinancialYearSettings;
