import { makeStyles, createStyles } from '@material-ui/core/styles';

export const opportunityDetailStyle = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: '22px',
	},
	companyContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	items: {
		fontFamily: 'Manrope-semiBold',
		letterSpacing: '0px',
		color: '#5C5C5C',
		fontSize: '10px',
	},
	label: {
		fontFamily: 'Manrope-semiBold',
		fontSize: '14px',
		letterSpacing: '0px',
		color: '#292929',
	},
	line: {
		borderLeft: '1px solid #0000000F',
		height: '30px',
		marginTop: '4px',
		marginLeft: '15px',
		marginRight: '15px',
	},
	dot: {
		backgroundColor: '#4285F4',
		borderRadius: '50%',
		width: '25px',
		cursor: 'pointer',
	},
}));

export const opportunityDetailsLeftPanelStyle = makeStyles((theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
		outerContainer: {
			position: 'relative',
		},
		container: {
			width: '246px',
			minHeight: '520px',
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			boxShadow: '0px 3px 6px #0000000F',
			borderRadius: '4px',
			opacity: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		headerContainer: {
			width: '246px',
			height: '113px',
			alignItems: 'center',
			borderRadius: '4px 4px 0px 0px',
			justifyCcontent: 'center',
			backgroundColor: '#4285F4',
		},
		actionsContainer: {
			width: '100%',
			display: 'flex',
			height: '67px',
			alignItems: 'center',
			justifyContent: 'space-evenly',
		},
		actionButton: {
			width: '22px',
			height: '22px',
			background: '#FFFFFF',
			border: '1px solid #E0E0E0',
			borderRadius: '100px',
		},
		actionButtonContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		label: {
			fontSize: '10px',
			color: '#5C5C5C',
			fontFamily: 'Manrope-semibold',
			marginTop: '4px',
		},
		largeValue: {
			fontFamily: 'Manrope-semiBold',
			fontSize: '13px',
			letterSpacing: '0px',
			color: '#292929',
		},
		priorityMedium: {
			height: '5px',
			width: '5px',
			backgroundColor: '#4285F4',
			borderRadius: '100%',
			display: 'inline-block',
			marginBottom: '1px',
		},
		priorityHigh: {
			height: '5px',
			width: '5px',
			backgroundColor: '#EA4335',
			borderRadius: '100%',
			display: 'inline-block',
			marginBottom: '1px',
		},
		priorityLow: {
			height: '5px',
			width: '5px',
			backgroundColor: '#34A853',
			borderRadius: '100%',
			display: 'inline-block',
			marginBottom: '1px',
		},
		horizontalDivider: {
			borderLeft: '1px solid #0000000F',
			height: '30px',
			marginTop: '4px',
		},
		ellipsisContent: {
			color: '#292929',
			fontSize: '12px',
			fontFamily: 'Manrope-semiBold',
			width: '100px',
			textAlign: 'end',
		},
		additionalContent: {
			display: 'flex',
			borderBottom: '1px solid #E0E0E0',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		header: {
			fontSize: '16px',
			fontFamily: 'Manrope-extrabold',
			color: '#FFFFFF',
			textAlign: 'center',
			maxWidth: '160px',
			maxHeight: '44px',
			lineHeight: '20px',
			wordBreak: 'break-all',
			display: '-webkit-box',
			webkitBoxOrient: 'vertical',
			mozBoxOrient: 'vertical',
			msBoxOrient: 'vertical',
			boxOrient: 'vertical',
			webkitLineClamp: 2,
			mozLineClamp: 2,
			msLineClamp: 2,
			lineClamp: 2,
			overflow: 'hidden',
		},
		subHeading: {
			fontSize: '10px',
			fontFamily: 'Manrope',
			color: '#FFFFFF',
			marginTop: '8px',
			maxWidth: '200px',
			textAlign: 'center',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		actions: {
			cursor: 'pointer',
		},
		createProjectButton: {
			display: 'flex',
			justifyContent: 'center',
			color: '#4285F4',
			height: '59px',
			alignItems: 'center',
			width: '100%',
			position: 'absolute',
			bottom: '0px',
			cursor: 'pointer',
			'&:hover': {
				background: '#E3EEFF',
				borderRadius: '4px',
			},
		},
		createProjectIcon: {
			width: '12px',
			height: '11px',
		},
		createButtonLabel: {
			fontSize: '12px',
			fontFamily: 'Manrope-bold',
			textTransform: 'uppercase',
			marginLeft: '4px',
		},
		websiteIcon: {
			color: '#FFFFFF',
			width: '17px',
			cursor: 'pointer',
			margin: '0px 6px',
		},
		editButtonContainer: {
			opacity: 1,
			width: '20px',
			height: '20px',
			borderRadius: '100px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			margin: '6px 6px 0px 0px',
		},
		editButton: {
			background: '#FFFFFF',
			border: '1px solid #E0E0E0',
			width: '20px',
			height: '20px',
			borderRadius: '100px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			'&:hover': {
				background: '#4285F4',
			},
		},
		editIcon: {
			width: '11px',
			height: '11px',
			color: '#4285F4',
			'&:hover': {
				color: '#FFFFFF',
			},
		},
		projectContainer: {
			background: '#FFFFFF',
			marginTop: '10px',
			width: '100%',
			minHeight: '59px',
			boxShadow: '0px 3px 6px #0000000f',
			borderRadius: '4px',
			paddingTop: '1px',
		},
		projectHeading: {
			marginLeft: '8px',
		},
		projectHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: '10px 10px 0px 14px',
			height: '18px',
		},
		projectName: {
			color: '#292929',
			font: 'normal normal 600 12px/24px Manrope',
			marginLeft: '10px',
			fontSize: '12px',
			width: '120px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
		projectType: {
			marginLeft: '63px',
			color: '#292929',
			fontFamily: 'Manrope-medium',
			fontSize: '10px',
			marginTop: '5px',
		},
		projectCurrency: {
			color: '#292929',
		},
		projectIcon: {
			width: '12px',
			height: '11px',
		},
		projectInfoContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
		companyContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '10px',
		},
		project: {
			display: 'flex',
			alignItems: 'center',
		},
		bottomAdditonalContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
	})
);
