import React, { useContext, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	useRefresh,
	useMutation,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { useGetSkillLevelValueQuery } from '../../../../generated/graphql';
import CloseIcon from '@material-ui/icons/Close';
import {
	EDIT_SKILL_LEVEL_MESSAGE,
	UPDATE_SUCCESS_MESSAGE,
	INSERT_SUCCESS_MESSAGE,
	ADD_SKILL_LEVEL_MESSAGE,
	SKILL_LEVEL_UNIQUE_ERROR_MESSAGE,
} from './constant';
import { head } from 'lodash';
import { UserProfileContext } from '../../../../App';
import KanbanWarningModal from '../../Kanban/kanbanWarning.component';
import { regexNameValidation } from '../../../Settings/constant';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

export interface skillLevel {
	description?: string;
	id?: string;
	level?: string;
	value?: string;
	created_at?: string;
	orgId?: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	skillLevel: skillLevel;
}
const SkillLevelForm = (props: Props) => {
	const { open, onClose, skillLevel } = props;
	const classes = modalFormStyle();
	const notify = useNotify();
	const [mutate, { loading: isSkillMutationLoading }] = useMutation();
	const userefresh = useRefresh();
	const { orgId } = useContext<any>(UserProfileContext);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [skillLevelUniqueError, setSkillLevelUniqueError] = useState('');
	const [newSkillLevel, setNewSkillLevel] = useState<string | null>(null);
	const [skillValue, setSkillValue] = useState<string | null>(null);
	const [skillDescription, setSkillDescription] = useState<string | null>(null);
	const { data: skillLevelValue } = useGetSkillLevelValueQuery({
		fetchPolicy: 'network-only',
	});

	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		if (value.length > 25) {
			return 'Characters must be below 25';
		}

		return undefined;
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
	};

	const handleClose = () => {
		onClose();
		setSkillLevelUniqueError('');
	};

	const createSkillLevel = () => {
		mutate(
			{
				type: 'create',
				resource: 'skill_level',
				payload: {
					data: {
						org_id: orgId,
						level: newSkillLevel,
						value: skillValue,
						description: skillDescription,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify(INSERT_SUCCESS_MESSAGE);
					userefresh();
					handleClose();
					setSkillLevelUniqueError('');
					setSkillDescription('');
				},
				onFailure: (error) => {
					console.log(error);

					if (error.message.includes('skill_level_level_org_id_key')) {
						setSkillLevelUniqueError(SKILL_LEVEL_UNIQUE_ERROR_MESSAGE);
						return;
					}
					onClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const updateSkillLevel = () => {
		mutate(
			{
				type: 'update',
				resource: 'skill_level',
				payload: {
					id: skillLevel?.id,
					data: {
						level: newSkillLevel ? newSkillLevel : skillLevel?.level,
						value: skillValue ? skillValue : skillLevel?.value,
						description: skillDescription,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify(UPDATE_SUCCESS_MESSAGE);
					onClose();
					userefresh();
					handleClose();
				},
				onFailure: (error) => {
					if (error.message.includes('skill_level_level_org_id_key')) {
						setSkillLevelUniqueError(SKILL_LEVEL_UNIQUE_ERROR_MESSAGE);
						return;
					}
					onClose();
					notify(`Update failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const onSave = () => {
		if (skillLevel?.id) {
			updateSkillLevel();
		} else {
			createSkillLevel();
		}
	};

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{skillLevel?.id
									? EDIT_SKILL_LEVEL_MESSAGE
									: ADD_SKILL_LEVEL_MESSAGE}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={skillLevel}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Name *</Typography>
										<TextInput
											source='level'
											label=''
											validate={validateName}
											onChange={(e) => {
												setNewSkillLevel(e.target.value?.trim());
												form.mutators.setTouched();
												setSkillLevelUniqueError('');
											}}
										/>
										{skillLevelUniqueError && (
											<Box color='red' fontSize={'12px'} marginTop={'-6px'}>
												{skillLevelUniqueError}
											</Box>
										)}
										<Typography className={classes.label}>Value *</Typography>
										<SelectInput
											source='value'
											label={''}
											choices={head(skillLevelValue?.master_settings)?.value}
											validate={required()}
											onChange={(e) => {
												setSkillValue(e.target.value);
												form.mutators.setTouched();
											}}
										/>
										<Typography className={classes.label}>
											Description
										</Typography>
										<TextInput
											source='description'
											label=''
											multiline={true}
											onChange={(e) => {
												setSkillDescription(e.target.value);
												form.mutators.setTouched();
											}}
										/>
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											title='Save'
											isDisabled={
												invalid ||
												pristine ||
												submitSucceeded ||
												isSkillMutationLoading
											}
											isLoading={isSkillMutationLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={''}
				/>
			}
		</>
	);
};
export default SkillLevelForm;
