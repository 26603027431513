import React, { useEffect, useState} from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotesForm from './NotesForm.component';
import NoteDelete from './NoteDelete.component';
import { NoteForm } from './model';

const useStyles = makeStyles({
	toolbar: {
		marginTop: '3px',
	},
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
});

interface Props {
	saveForm: (form: NoteForm) => void;
	deleteNote: (noteId: string) => void;
	NoteId: string;
	refetch: () => void;
	noteId:(id:string)=>void;
	getNoteList:NoteForm [];
	deletePermission?:boolean;
}

const NotesPopOver = (props: Props) => {
	const { NoteId, refetch, saveForm, deleteNote,noteId,getNoteList,deletePermission } = props;
	const classes = useStyles();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [noteList, setNoteList] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);


	useEffect(() => {
		if (!getNoteList || !(getNoteList.length > 0)) {
			return;
		}
		setNoteList({
			id: getNoteList[0]?.id,
			note: getNoteList[0]?.note,
			title: getNoteList[0]?.title,
		});
	}, [getNoteList]);

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setIsEditModalOpen(false);
		setIsDeleteModalOpen(false);
		setAnchorEl(null);
	};

	const openEditModal = ()=>{
		setIsEditModalOpen(true);
		noteId(NoteId)
	}

	return (
		<>
			<Box className={classes.toolbar}>
				<MoreVertIcon onClick={showPopOver} />
				<Menu
					id='simple-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={closePopOver}
				>
					<MenuItem onClick={openEditModal}>
						<EditIcon className={classes.editIcon} />
						<Box className={classes.editText}>Edit</Box>
					</MenuItem>
					{deletePermission && 
					 <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
						<DeleteIcon className={classes.deleteIcon} />
						<Box className={classes.editText}>Delete</Box>
					</MenuItem>}
				</Menu>
			</Box>
			<NotesForm
				open={isEditModalOpen}
				onClose={closePopOver}
				saveForm={saveForm}
				note={noteList}
				refetch={refetch}
			/>
			<NoteDelete
				open={isDeleteModalOpen}
				onClose={closePopOver}
				noteId={NoteId}
				deleteNote={deleteNote}
				refetch={refetch}
			/>
		</>
	);
};

export default NotesPopOver;
