import React, { useState, useContext, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { InsightsIcon } from '../../assets/SvgIcons';
import { globalStyles } from '../../Layout/styles';
import { Style } from './Style';
import MyAttendance from './MyAttendance.component';
import AttendanceManagerView from './AttendanceManagerView.component';
import { UserProfileContext } from '../../App';
import { useQuery } from 'react-admin';
import { isEmpty } from 'lodash';
import { AttendanceInsights } from './Insight/AttendanceInsight.component';
import { AttendanceReportingManagerView } from './AttendanceReportingManagerView.component';
import { useGetMyTeamViewInAttendanceQuery } from '../../generated/graphql';

const AttendanceList = () => {
	const { permissions: permissionList, id: userId } =
		useContext<any>(UserProfileContext);
	const [isAttendanceListShown, setIsAttendanceListShown] = useState(true);
	const [employeeId, setEmployeeId] = useState<string>('');
	const [isAttendanceManagerView, setIsAttendanceManagerView] = useState(false);
	const [
		isAttendanceReportingManagerView,
		setIsAttendanceReportingManagerView,
	] = useState(false);
	const commonStyles = globalStyles();
	const classes = Style();

	const { data: myTeam } = useGetMyTeamViewInAttendanceQuery({
		variables: {
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: employee } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'employee',
			payload: {
				filter: {
					user_id: userId,
				},
			},
		},
		{ enabled: !!userId }
	);

	useEffect(() => {
		if (isEmpty(employee)) {
			setEmployeeId('');
			return;
		}
		const response = employee[0].id;
		setEmployeeId(response);
	}, [employee]);

	return (
		<>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box className={commonStyles.navigationTabContainer}>
					<Button
						className={[
							classes.navigationTabButtons,
							isAttendanceListShown
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={() => {
							setIsAttendanceListShown(true);
							setIsAttendanceManagerView(false);
							setIsAttendanceReportingManagerView(false);
						}}
					>
						My Attendance
					</Button>
					{permissionList?.appPermissions?.ui?.viewOthersAttendance && (
						<Button
							className={[
								classes.navigationTabButtons,
								isAttendanceManagerView
									? commonStyles.activeNavigationTabButton
									: '',
							].join(' ')}
							onClick={() => {
								setIsAttendanceManagerView(true);
								setIsAttendanceListShown(false);
								setIsAttendanceReportingManagerView(false);
							}}
						>
							Attendance
						</Button>
					)}

					{myTeam?.user_aggregate?.aggregate?.count! > 0 && (
						<Button
							className={[
								classes.navigationTabButtons,
								isAttendanceReportingManagerView
									? commonStyles.activeNavigationTabButton
									: '',
							].join(' ')}
							onClick={() => {
								setIsAttendanceReportingManagerView(true);
								setIsAttendanceListShown(false);
								setIsAttendanceManagerView(false);
							}}
						>
							My Team
						</Button>
					)}
					{permissionList?.appPermissions?.ui?.viewOthersAttendance && (
						<Button
							className={[
								commonStyles.navigationTabButtons,
								!isAttendanceListShown &&
								!isAttendanceManagerView &&
								!isAttendanceReportingManagerView
									? commonStyles.activeNavigationTabButton
									: '',
							].join(' ')}
							startIcon={<InsightsIcon />}
							onClick={() => {
								setIsAttendanceListShown(false);
								setIsAttendanceManagerView(false);
								setIsAttendanceReportingManagerView(false);
							}}
						>
							Insights
						</Button>
					)}
				</Box>
				<Box>
					<button className={classes.backButton}>Back</button>
				</Box>
			</Box>
			{isAttendanceListShown && <MyAttendance employeeId={employeeId} />}
			{isAttendanceManagerView && <AttendanceManagerView />}
			{isAttendanceReportingManagerView && <AttendanceReportingManagerView />}
			{!isAttendanceListShown &&
				!isAttendanceManagerView &&
				!isAttendanceReportingManagerView && <AttendanceInsights />}
		</>
	);
};

export default AttendanceList;
