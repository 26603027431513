import React, { useEffect, useState } from 'react';
import {
	TextInput,
	required,
	CheckboxGroupInput,
	useNotify,
	useRefresh,
	Loading,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useQuery } from 'react-admin';
import { useMutation as useApolloMutation } from '@apollo/client';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
	createTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import head from 'lodash/head';
import uniq from 'lodash/uniq';

import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogActions,
} from '../../../Layout/styles';
import { Role } from './Role.model';
import { UPSERT_ROLE, UPSERT_ROLE_PERMISSIONS } from '../Settings.gql';
import {
	CONTRACT_ACCESS_OPTIONS,
	CRM_ACCESS_OPTIONS,
	EMPLOYEE_ACCESS_OPTIONS,
	ASSET_ACCESS_OPTIONS,
	PROJECT_PLANNING_ACCESS_OPTIONS,
	PROJECT_MANAGEMENT_ACCESS_OPTIONS,
	REPORTS_ACCESS_OPTIONS,
} from './constant';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const DialogContent = withStyles(() => ({
	root: {
		maxHeight:'70vh',
		padding : '0px 10px 0px 0px'
	},
}))(MuiDialogContent);

const roleFormStyle = makeStyles({
	container: {
		minWidth: '442px',
		minHeight: '500px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	inputContainer: {
		width: '32vw',
	},
	checkBoxGroupInput: {
		marginLeft: '10px',
	},
	selectAllToggleButton: {
		border: '1px solid #E0E0E0',
		width: '78px',
		height: '18px',
		marginLeft: '10px',
		padding: '0px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#292929',
	},
	labelContainer: {
		display: 'flex',
		alignItems: 'center',
	},
});

const roleAccessCheckBoxTheme = createTheme({
	overrides: {
		MuiFormGroup: {
			row: {
				display: 'grid',
				gridTemplateColumns: 'repeat(5, 19%)',
			},
		},
		// Label text
		MuiTypography: {
			body1: {
				font: 'normal normal bold 12px/37px Manrope',
				fontSize: '12px !important',
				letterSpacing: '0px',
			},
		},
	},
});
interface RoleFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: Role;
}

interface RoleFormModel {
	id?: string;
	name: string;
	description?: string;
	employee_access?: string[];
	crm_access?: string[];
	contract_access?: string[];
	asset_access?: string[];
	project_plan_access?: string[];
	project_management_access?: string[];
	reports_access?: string[];
}

const mapToInitialValues = (initialValues: Role, savedRolePermissions: {permissions: string[]}[]) => {
	const rolePermission = uniq(head(savedRolePermissions)?.permissions);
	return {
		...initialValues,
		employee_access: [...rolePermission] || [],
		crm_access: [...rolePermission] || [],
		contract_access: [...rolePermission] || [],
		asset_access: [...rolePermission] || [],
		project_plan_access: [...rolePermission] || [],
		project_management_access: [...rolePermission] || [],
		reports_access: [...rolePermission] || [],
	}
}

export const RoleForm = (props: RoleFormProps) => {
	const { open, onClose, initialValues } = props;
	const [isRoleNameUniqueViolation, setIsRoleNameUniqueViolation] =
		useState(false);
	const formStyles = modalFormStyle();
	const roleFormStyles = roleFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [upsertRole, { loading: upsertRoleLoading, error: upsertRoleError }] =
		useApolloMutation(UPSERT_ROLE);
	const [upsertRolePermissions, { loading: upsertRolePermissionsLoading }] =
		useApolloMutation(UPSERT_ROLE_PERMISSIONS);

	const { data: rolePermissions, loading: loadingRolePermissions } = useQuery(
		{
			type: 'getList',
			resource: 'role_permissions',
			payload: { 
				filter: { role_id: initialValues?.id}
			}
		},
		{ enabled: !!initialValues?.id }
	);
	const successMessage = initialValues?.id
		? 'Role Updated Successfully'
		: 'Role Created Successfully';
	useEffect(() => {
		if (!upsertRoleError) {
			return;
		}
		if (upsertRoleError.message.includes('role_name_uidx')) {
			setIsRoleNameUniqueViolation(true);
		}
	}, [upsertRoleError]);

	const onSubmit = (roleForm: RoleFormModel) => {
		if (!roleForm) {
			return;
		}
		// Todo fix the multi permissions issue and remove uniq here
		const access = uniq([
			...(roleForm?.employee_access || []),
			...(roleForm?.crm_access || []),
			...(roleForm?.contract_access || []),
			...(roleForm?.asset_access || []),
			...(roleForm?.project_plan_access || []),
			...(roleForm?.project_management_access || []),
			...(roleForm?.reports_access || []),
		]);

		upsertRole({
			variables: {
				data: {
					id: roleForm?.id,
					name: roleForm.name,
					description: roleForm?.description,
				},
			},
		})
			.then((upsertRoleResponse: any) => {
				if (!upsertRoleResponse.error) {
					// TODO After Permission API is ready we will remove this
					// notify(successMessage);
					// refresh();
					// onClose();

					// TODO After Permission API is ready we will use this
					upsertRolePermissions({
						variables: {
							rolePermissions: {
								role_id: upsertRoleResponse?.data?.insert_role_one?.id,
								permissions: access,
							},
						},
					})
						.then((upsertRolePermissionsResponse: any) => {
							if (!upsertRolePermissionsResponse.error) {
								notify(successMessage);
								refresh();
							}
						})
						.catch((error: any) => {
							if (error) {
								return;
							}
						});
				}
			})
			.catch((error: any) => {
				if (error) {
					return;
				}
			});
	};

	const validateRoleFormValues = async () => {
		const roleFormErrors = {} as RoleFormModel;
		if (isRoleNameUniqueViolation) {
			roleFormErrors.name = 'Role already exist!';
		}
		return roleFormErrors;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				fullWidth={true}
				maxWidth={false}
			>
				{!loadingRolePermissions &&
				!upsertRolePermissionsLoading &&
				!upsertRoleLoading ? (
					<Box className={roleFormStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								{initialValues?.id ? `${initialValues.name}` : `Create Role`}
							</Typography>
							<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
						</Box>
						<Form
							initialValues={mapToInitialValues(
								initialValues!,
								rolePermissions
							)}
							validate={validateRoleFormValues}
							mutators={{
								setFullAccesForEmployee: (args, state, utils) => {
									const allEmployeePermissions = EMPLOYEE_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'employee_access', () =>
										allEmployeePermissions.length ===
										state?.lastFormState?.values?.employee_access?.length
											? []
											: allEmployeePermissions
									);
								},
								setFullAccesForCRM: (args, state, utils) => {
									const allCrmPermissions = CRM_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'crm_access', () =>
										allCrmPermissions.length ===
										state?.lastFormState?.values?.crm_access?.length
											? []
											: allCrmPermissions
									);
								},
								setFullAccesForContract: (args, state, utils) => {
									const allContractPermissions = CONTRACT_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'contract_access', () =>
										allContractPermissions.length ===
										state?.lastFormState?.values?.contract_access?.length
											? []
											: allContractPermissions
									);
								},
								setFullAccesForAsset: (args, state, utils) => {
									const allAssetPermissions = ASSET_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'asset_access', () =>
										allAssetPermissions.length ===
										state?.lastFormState?.values?.asset_access?.length
											? []
											: allAssetPermissions
									);
								},
								setFullAccesForProjectPlanning: (args, state, utils) => {
									const allProjectPlanningPermissions = PROJECT_PLANNING_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'project_plan_access', () =>
										allProjectPlanningPermissions.length ===
										state?.lastFormState?.values?.project_plan_access?.length
											? []
											: allProjectPlanningPermissions
									);
								},
								setFullAccesForProjectManagement: (args, state, utils) => {
									const allProjectManagementPermissions = PROJECT_MANAGEMENT_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'project_management_access', () =>
										allProjectManagementPermissions.length ===
										state?.lastFormState?.values?.project_management_access
											?.length
											? []
											: allProjectManagementPermissions
									);
								},
								setFullAccesForReports: (args, state, utils) => {
									const allProjectReportsPermissions = REPORTS_ACCESS_OPTIONS.map(
										(option) => option.id
									);
									utils.changeValue(state, 'reports_access', () =>
										allProjectReportsPermissions.length ===
										state?.lastFormState?.values?.reports_access?.length
											? []
											: allProjectReportsPermissions
									);
								},
							}}
							onSubmit={onSubmit}
						>
							{({ handleSubmit, invalid, pristine, form }) => (
								<form onSubmit={handleSubmit}>
									<Box>
										<DialogContent>
											<Box className={roleFormStyles.inputContainer}>
												<Typography className={formStyles.label}>
													Role*
												</Typography>
												<TextInput
													source='name'
													label=''
													fullWidth
													validate={required()}
												/>
												<Typography className={formStyles.label}>
													Description
												</Typography>
												<TextInput
													multiline
													placeholder='Enter the description'
													source='description'
													label=''
													fullWidth
												/>
											</Box>
											<ThemeProvider theme={roleAccessCheckBoxTheme}>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Employee
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={form.mutators.setFullAccesForEmployee}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='employee_access'
													label=''
													choices={EMPLOYEE_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														CRM
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={form.mutators.setFullAccesForCRM}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='crm_access'
													label=''
													choices={CRM_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Contract
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={form.mutators.setFullAccesForContract}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='contract_access'
													label=''
													choices={CONTRACT_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Asset
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={form.mutators.setFullAccesForAsset}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='asset_access'
													label=''
													choices={ASSET_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Project Planning
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={
															form.mutators.setFullAccesForProjectPlanning
														}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='project_plan_access'
													label=''
													choices={PROJECT_PLANNING_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Project Management
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={
															form.mutators.setFullAccesForProjectManagement
														}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='project_management_access'
													label=''
													choices={PROJECT_MANAGEMENT_ACCESS_OPTIONS}
												/>
											</Box>
											<Box>
												<Box className={roleFormStyles.labelContainer}>
													<Typography className={formStyles.label}>
														Reports
													</Typography>
													<Button
														className={roleFormStyles.selectAllToggleButton}
														onClick={form.mutators.setFullAccesForReports}
													>
														Select All
													</Button>
												</Box>
												<CheckboxGroupInput
													className={roleFormStyles.checkBoxGroupInput}
													source='reports_access'
													label=''
													choices={REPORTS_ACCESS_OPTIONS}
												/>
											</Box>
										</ThemeProvider>
										</DialogContent>
										<DialogActions>
											<Box className={formStyles.floatingButtonContainer}>
												<Button
													onClick={onClose}
													className={formStyles.cancelButton}
												>
													Cancel
												</Button>
												<Button
													className={
														invalid
															? formStyles.disabledButton
															: formStyles.saveButton
													}
													disabled={invalid}
													type='submit'
												>
													Save
												</Button>
											</Box>
										</DialogActions>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				) : (
					<Loading />
				)}
			</Dialog>
		</ThemeProvider>
	);
};

export default RoleForm;
