import React, { useState } from 'react';
import {
	Box,
	IconButton,
	InputAdornment,
	ThemeProvider,
	createTheme,
	Tooltip,
	// Grid,
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';
import { Style } from '../modules/Attendance/Style';

export const datePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
			input: {
				padding: '6px 0px 0px',
			},
		},
		MuiInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});
interface Props {
	name: string;
	initialValue: MaterialUiPickersDate | null;
	onChange: (value: MaterialUiPickersDate | string) => void;
	dateFormat: string;
	helperText?: string | boolean;
	FormHelperTextProps?: any;
	minDate?: any;
	maxDate?: any;
	error?: boolean;
	isDisabled?: boolean;
	isLeaveAndHolidaysEnabled?: boolean;
	leaveDaysList?:
		| { date: string; leaveType: string; name: string }[]
		| null
		| undefined;
	holidays?: { date: string; name: string }[] | null | undefined;
}

export const CustomDateInput = (props: Props) => {
	const {
		name,
		initialValue,
		onChange,
		dateFormat,
		helperText,
		FormHelperTextProps,
		minDate,
		maxDate,
		isLeaveAndHolidaysEnabled = false,
		leaveDaysList,
		holidays,
		error,
		isDisabled = false,
	} = props;
	const [value, setValue] = useState<any>(initialValue);
	const classes = Style();
	const renderDay = (
		day: any,
		selectedDate: any,
		dayInCurrentMonth: any,
		dayComponent: any
	) => {
		const calendarDate = day?.format('YYYY-MM-DD');
		const leaveDateArrayList = leaveDaysList?.map(
			(leave: { date: string; leaveType: string }) => leave?.date
		);
		const leaveTypeName = leaveDaysList?.find(
			(leave: { date: string; leaveType: string; name: string }) =>
				leave.date === calendarDate
		);
		const holidayDate = holidays?.map(
			(holiday: { date: string; name: string }) => holiday?.date
		);
		const holidayName = holidays?.find(
			(holiday: { date: string; name: string }) =>
				holiday?.date === calendarDate
		);
		// if both leave and holiday present in same calender date
		if (
			isLeaveAndHolidaysEnabled &&
			holidayDate?.includes(calendarDate) &&
			leaveDateArrayList?.includes(calendarDate) &&
			dayInCurrentMonth
		) {
			return (
				<Box display='block'>
					<Box display={'flex'} className={classes.leaveHolidayBox}>
						<Tooltip title={holidayName!?.name} placement='right'>
							<Box className={classes.leaveDays}>H</Box>
						</Tooltip>
						<Tooltip title={leaveTypeName!?.name || ''} placement='right'>
							<Box className={classes.leaveDays}>L</Box>
						</Tooltip>
					</Box>
					<Box>{dayComponent}</Box>
				</Box>
			);
		}
		//if holiday present in the calender date
		if (
			isLeaveAndHolidaysEnabled &&
			holidayDate?.includes(calendarDate) &&
			!leaveDateArrayList?.includes(calendarDate) &&
			dayInCurrentMonth
		) {
			return (
				<Box display='flex'>
					<Tooltip title={holidayName!?.name || ''} placement='right'>
						<Box className={`${classes.leaveDays} ${classes.leaveAndHoliday}`}>
							H
						</Box>
					</Tooltip>
					<Box>{dayComponent}</Box>
				</Box>
			);
		}
		// if leave present in the calender date
		if (
			isLeaveAndHolidaysEnabled &&
			!holidayDate?.includes(calendarDate) &&
			leaveDateArrayList?.includes(calendarDate) &&
			dayInCurrentMonth
		) {
			return (
				<Box display='flex'>
					<Tooltip title={leaveTypeName!?.name || ''} placement='right'>
						<Box className={`${classes.leaveDays} ${classes.leaveAndHoliday}`}>
							L
						</Box>
					</Tooltip>
					<Box>{dayComponent}</Box>
				</Box>
			);
		}

		return <Box className={classes.calenderDays}>{dayComponent}</Box>;
	};

	return (
		<ThemeProvider theme={datePickerTheme}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					autoOk={true}
					name={name}
					value={value}
					onChange={(event) => {
						setValue(event ? dayjs(event).format('YYYY-MM-DD') : null);
						onChange(event ? dayjs(event).format('YYYY-MM-DD') : null);
					}}
					variant='inline'
					format={dateFormat}
					renderDay={renderDay}
					label={false}
					emptyLabel={dateFormat}
					InputLabelProps={{ shrink: true }}
					showTodayButton={true}
					helperText={helperText}
					minDate={minDate}
					maxDate={maxDate}
					disabled={isDisabled}
					FormHelperTextProps={FormHelperTextProps}
					error={error || false}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<IconButton>
									<EventIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
};
