import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	withStyles,
	Tabs,
	Tab,
	Theme,
	createStyles,
} from '@material-ui/core';

import TimesheetOvertime from './TimesheetOvertime.component';
import TimesheetOverTimePendingApproval from './TimesheetOverTimePendingApproval.component';
import {
	useGetOvertimePendingCountQuery,
	useGetPendingApprovalUsersForOvertimeQuery,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { isEmpty } from 'lodash';
import TimesheetOvertimeHistory from './TimesheetOvertimeHistory.component';
import TimesheetOvertimeAdmin from './TimesheetOvertimeAdmin.component';

interface StyledTabProps {
	label: string;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			minWidth: 72,
			marginRight: '10px',
			fontFamily: 'Manrope-Medium',
			fontSize: '12px !important',
			color: '#5C5C5C',
			'&:hover': {
				color: '#4285F4',
				opacity: 1,
			},
			'&$selected': {
				color: '#1890ff',
				fontWeight: theme.typography.fontWeightBold,
			},
			'&:focus': {
				color: '#4285F4',
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box pt={3} pr={3}>
					{children}
				</Box>
			)}
		</div>
	);
};

function TimesheetOvertimeContainer() {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);
	const { id: userId, permissions } = useContext<any>(UserProfileContext);
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	const { data: pendingApproverList } =
		useGetPendingApprovalUsersForOvertimeQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: timesheetPendingCount, refetch: refetchTimesheetPendingCount } =
		useGetOvertimePendingCountQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (isEmpty(timesheetPendingCount?.timesheet_overtime)) {
			setPendingApprovalCount(0);
			return;
		}
		const response = timesheetPendingCount?.timesheet_overtime
			?.filter(
				(overtime) =>
					overtime?.userByEmployeeId?.timesheetOvertimeSubmissions?.length > 0
			)
			.map(
				(overtime) => overtime?.userByEmployeeId?.timesheetOvertimeSubmissions
			)
			.flat()
			.filter(
				(timesheetSubmission) =>
					Number(
						timesheetSubmission?.timesheet_overtimes_aggregate?.aggregate?.count
					) > 0
			);
		setPendingApprovalCount(response?.length || 0);
	}, [timesheetPendingCount]);
	return (
		<>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab key={0} label='My Overtime' />
				<CustomTab key={1} label={`History`} />
				{permissions?.appPermissions?.ui?.ViewOthersOvertime && (
					<CustomTab key={2} label='Timesheet' />
				)}
				{pendingApproverList?.timesheet_overtime &&
					pendingApproverList?.timesheet_overtime?.length > 0 && (
						<CustomTab
							key={3}
							label={`Pending Approval(${pendingApprovalCount})`}
						/>
					)}
			</CustomTabs>
			<CustomTabPanel value={selectedTabIndex} index={0}>
				<TimesheetOvertime />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={1}>
				<TimesheetOvertimeHistory />
			</CustomTabPanel>
			{permissions?.appPermissions?.ui?.ViewOthersOvertime && (
				<CustomTabPanel value={selectedTabIndex} index={2}>
					<TimesheetOvertimeAdmin />
				</CustomTabPanel>
			)}
			{pendingApproverList?.timesheet_overtime &&
				pendingApproverList?.timesheet_overtime?.length > 0 && (
					<CustomTabPanel value={selectedTabIndex} index={3}>
						<TimesheetOverTimePendingApproval
							refetch={refetchTimesheetPendingCount}
						/>
					</CustomTabPanel>
				)}
		</>
	);
}

export default TimesheetOvertimeContainer;
