import React, { useState, useEffect, useContext } from 'react';
import {
	List,
	Datagrid,
	ReferenceField,
	useListContext,
	TopToolbar,
	Loading,
	FunctionField,
} from 'react-admin';

import { useQuery as ApolloUseQuery } from '@apollo/client';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import FullNameField from '../../SharedComponents/FullNameField.component';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import {
	Box,
	IconButton,
	Button,
	Chip,
	TextField as Field,
	makeStyles,
	Checkbox,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ThemeProvider } from '@material-ui/core/styles';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import { GridIcon, ListIcon, InsightsIcon } from '../../assets/SvgIcons';
import ConsultantCard from './ConsultantCard.component';
import ConsultantFilterFormBody from './ConsultantFilterFormBody.component';
import {
	globalStyles,
	labelStyle,
	autocompleteStyle,
	ellipsisStyle,
} from '../../Layout/styles';
import ConsultantInsights from './DetailsPage/ConsultantInsight.component';
import { useHistory } from 'react-router';
import {
	searchBarTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import {
	GET_CONSULTANT_CUSTOM_FIELDS,
	GET_CONSULTANT_SEARCH,
	GET_CONSULTANT_STATUS_OPTIONS,
} from './gqlQueries';
import { UserProfileContext } from '../../App';
import { calculateDateDifference } from '../../Utils/date-time.util';
import dayjs from 'dayjs';
import { modalFormTheme } from '../../Layout/Theme.component';
import { SelectInput } from 'react-admin';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { useGetOrgLocationsQuery } from '../../generated/graphql';

import {
	ALL_CONSULTANTS,
	EXPIRED_CONSUTANTS,
	EXPIRED_IN_N_DAYS,
} from './Constant';
import {
	formatEmployeeId,
	exportToCsv,
	getPrefix,
	formatPhoneNumber,
} from '../../Utils/string.util';
import { head } from 'lodash';
import { ConsultantPagination } from './ConsultantPagination';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles(() => ({
	filterStyle: {
		marginTop: '-74px',
		marginBottom: '5px',
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 19%)',
		maxWidth: 1220,
		margin: 'auto',
		gap: '14px',
		marginTop: '-40px',
	},
	toolContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: '8px',
	},
	listStyle: {
		marginTop: '-65px',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	expiredContract: {
		color: '#EA4335',
		fontSize: '12px !important',
		fontFamily: 'Manrope',
	},
	leftContract: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
	expiryFilterStatus: {
		background: '#ffffff',
		height: '36px',
		alignItems: 'left',
		justifyContent: 'flex-start',
	},
	dropDownTitle: {
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		marginTop: '-16px',
		color: '#000',
	},
	exportButton: {
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	locationBox: {
		paddingBotto: '5px',
		marginTop: '-14px',
	},
	designation: {
		width: '100px',
		fontSize: '12px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6',
		opacity: 1,
	},
	name: {
		width: '150px',
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
}));

export const ConsultantList = (props) => {
	const dropdownStyle = labelStyle();
	const dropDownStyles = autocompleteStyle();
	const styles = ellipsisStyle();
	const {
		permissions,
		orgId: organizationId,
		noOfLocations,
		filterState,
		dispatchFilter,
		empIdLength,
		dateFormat,
		isFixedEmpIdLengthEnabled,
	} = useContext(UserProfileContext);
	const [filterContractList, setFilterContractList] = useState(
		filterState?.consultantFilterContract ?? ALL_CONSULTANTS
	);
	const [employeeStatusList, setEmployeeStatusList] = useState([]);
	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [consultantCount, setConsultantCount] = useState(0);
	const [customFields, setCustomFields] = useState([]);
	const [isConsultantInsightsShown, setIsConsultantInsightsShown] =
		useState(false);
	const [indexArray, setIndexArray] = useState([]);
	const commonStyles = globalStyles();
	const classes = useStyles();
	const history = useHistory();
	const [locationList, setLocationList] = useState([]);
	const allSelected =
		employeeStatusList?.length === filterState?.consultantStatusId?.length || 0
			? true
			: false;

	const { data: consultantStatusOptions } = useApolloQuery(
		GET_CONSULTANT_STATUS_OPTIONS
	);

	const { data: location } = useGetOrgLocationsQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!location) {
			return;
		}

		const orgLocation = location.org_location.map((location) => {
			return {
				id: location.id,
				name: location.name,
			};
		});
		setLocationList([...orgLocation, { id: 'Unassigned', name: 'Unassigned' }]);
	}, [location]);

	const selectedLocationValues =
		filterState?.consultantLocation?.map((option) => option.id) ?? [];
	const selectedLocationIds = selectedLocationValues.filter(
		(option) => option !== 'All' && option !== 'Unassigned'
	);

	const locationAssigned = {
		end_date:
			filterContractList === ALL_CONSULTANTS
				? {}
				: filterContractList === EXPIRED_CONSUTANTS
				? { _lt: 'today()' }
				: {
						_gte: 'today()',
						_lte: dayjs().add(11, 'day').format('YYYY-MM-DD'),
				  },
		contractor_status_id: allSelected
			? {}
			: filterState?.consultantStatusId?.length > 0
			? { _in: filterState?.consultantStatusId?.map((value) => value?.value) }
			: { _in: [] },

		location_id:
			selectedLocationValues.length > 0 &&
			selectedLocationValues.includes('All')
				? {}
				: selectedLocationValues.length === 1 &&
				  selectedLocationValues.includes('Unassigned')
				? {
						_is_null: true,
				  }
				: selectedLocationIds?.length > 0 &&
				  !selectedLocationValues.includes('Unassigned')
				? { _in: filterState?.consultantLocationIdList ?? [] }
				: {},
	};

	const locationUnAssigned = {
		end_date:
			filterContractList === ALL_CONSULTANTS
				? {}
				: filterContractList === EXPIRED_CONSUTANTS
				? { _lt: 'today()' }
				: {
						_gte: 'today()',
						_lte: dayjs().add(11, 'day').format('YYYY-MM-DD'),
				  },
		contractor_status_id: allSelected
			? {}
			: filterState?.consultantStatusId?.length > 0
			? { _in: filterState?.consultantStatusId?.map((value) => value?.value) }
			: { _in: [] },

		_or: [
			{
				location_id: {
					_is_null: true,
				},
			},
			{
				location_id: {
					_in: selectedLocationIds,
				},
			},
		],
	};

	const { data: userList, loading: isUserListLoading } = ApolloUseQuery(
		GET_CONSULTANT_SEARCH,
		{
			variables: {
				name: `%${filterState?.consultantSearchText || ''.trim()}%`,
				contractors:
					selectedLocationValues.length > 0 &&
					selectedLocationValues.includes('Unassigned')
						? locationUnAssigned
						: locationAssigned,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: consultantCustomFields } = ApolloUseQuery(
		GET_CONSULTANT_CUSTOM_FIELDS,
		{
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!consultantCustomFields) {
			return;
		}
		const labelArray = consultantCustomFields?.custom_field?.map((value) => {
			return value?.label?.toUpperCase();
		});

		setCustomFields(labelArray);

		const indexValue = consultantCustomFields?.custom_field?.map((data) => {
			return { index: data?.index, type: data?.type };
		});
		setIndexArray(indexValue);
	}, [consultantCustomFields]);

	useEffect(() => {
		if (!userList || !userList?.user) {
			setConsultantCount(0);
			return;
		}
		setConsultantCount(userList.user?.length);
	}, [userList]);

	useEffect(() => {
		if (!consultantStatusOptions) {
			return;
		}
		const activeStatus = consultantStatusOptions?.contractor_status
			?.filter((status) => status.value === 'active')
			.map((value) => {
				return {
					value: value?.id,
					label: value?.label,
				};
			});
		if (activeStatus && !filterState?.consultantStatusId) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS,
				payload: activeStatus,
			});
		}
		const statusOptions = consultantStatusOptions?.contractor_status?.map(
			(status) => {
				return {
					value: status?.id,
					label: status?.label,
				};
			}
		);
		setEmployeeStatusList(statusOptions);
	}, [consultantStatusOptions, dispatchFilter, filterState.consultantStatusId]);

	const getConsultantEndDate = (date) => {
		const diffDays = calculateDateDifference(date);
		const consultantAvailableDays = `${diffDays} Days`;

		if (diffDays === 0) {
			return <span className={classes.leftContract}>Due in 1 day</span>;
		}

		if (diffDays <= 0) {
			return <span className={classes.expiredContract}>Contract Expired</span>;
		}

		if (diffDays >= 30) {
			return (
				<>
					<span className={classes.leftContract}>
						{dayjs(date).format('DD-MMM-YYYY')}
					</span>
				</>
			);
		}

		if (diffDays > 0)
			return (
				<>
					<span className={classes.leftContract}>
						{`${consultantAvailableDays} left`}
					</span>
				</>
			);
	};

	const ConsultantFilter = () => {
		return (
			<>
				<Box display='flex' flexDirection='column' width='100%'>
					<AppliedFiltersContainer
						totalCount={(total) => setConsultantCount(total)}
					>
						{getAppliedFilters}
					</AppliedFiltersContainer>
				</Box>
			</>
		);
	};
	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'designation_id':
				return (
					filterValues?.designation_id.length > 0 &&
					filterValues?.designation_id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='designation'
							label='Full Name'
							resource='designation'
							record={{ id }}
							basePath={'/employee'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.title}`}
											variant='outlined'
											onDelete={() => {
												filterValues.designation_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'job_level_id':
				return (
					filterValues?.job_level_id.length > 0 &&
					filterValues?.job_level_id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='job_level'
							label='Full Name'
							resource='job_level'
							record={{ id }}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.level}`}
											variant='outlined'
											onDelete={() => {
												filterValues.job_level_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'department_id':
				return (
					filterValues?.department_id.length > 0 &&
					filterValues?.department_id.map((department_id, index) => (
						<ReferenceField
							link={false}
							source='department_id'
							reference='department'
							label='Full Name'
							resource='department'
							record={{ department_id }}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.name}`}
											variant='outlined'
											onDelete={() => {
												filterValues.department_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'contractor_status_id':
				return (
					filterValues?.contractor_status_id?.length > 0 &&
					filterValues?.contractor_status_id.map(
						(contractor_status_id, index) => (
							<ReferenceField
								link={false}
								source='contractor_status_id'
								reference='contractor_status'
								label='Full Name'
								resource='contractor_status'
								record={{ contractor_status_id }}
							>
								<FunctionField
									label='Full Name'
									render={(record) => (
										<>
											<Chip
												className={commonStyles.multiSelectFilterChip}
												key={index}
												label={`${record?.label}`}
												variant='outlined'
												onDelete={() => {
													filterValues.contractor_status_id.splice(index, 1);
													setFilters(filterValues, displayedFilters);
												}}
											/>
										</>
									)}
								/>
							</ReferenceField>
						)
					)
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	const consultantFilterOptions = [
		{
			id: ALL_CONSULTANTS,
			name: ALL_CONSULTANTS,
		},
		{
			id: EXPIRED_CONSUTANTS,
			name: EXPIRED_CONSUTANTS,
		},
		{
			id: EXPIRED_IN_N_DAYS,
			name: EXPIRED_IN_N_DAYS,
		},
	];

	const getFilterChangeOptions = (event) => {
		if (event.target.value === ALL_CONSULTANTS) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_FILTER_CONTRACT,
				payload: ALL_CONSULTANTS,
			});
			setFilterContractList(ALL_CONSULTANTS);
		}

		if (event.target.value === EXPIRED_CONSUTANTS) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_FILTER_CONTRACT,
				payload: EXPIRED_CONSUTANTS,
			});
			setFilterContractList(EXPIRED_CONSUTANTS);
		}

		if (event.target.value === EXPIRED_IN_N_DAYS) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_FILTER_CONTRACT,
				payload: EXPIRED_IN_N_DAYS,
			});
			setFilterContractList(EXPIRED_IN_N_DAYS);
		}
	};

	const CONSULTANT_EXPORT_COLUMNS = [
		'RESOURCE ID',
		'RESOURCE NAME',
		'EMAIL',
		'RESOURCE TYPE',
		'REPORTING TO',
		'REPORTING TO EMAIL',
		'DEPARTMENT',
		'DESIGNATION',
		...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
		'CONTRACTOR STATUS',
		'IS BILLABLE',
		'BILLING COST',
		'CONTRACTOR COST',
		'JOB LEVEL',
		'WORKING MODE',
		'JOIN DATE',
		'END DATE',
		'PERSONAL EMAIL',
		'PRIMARY CONTACT',
		'DATE OF BIRTH',
		'GENDER',
		'MARITAL STATUS',
		'WEDDING DATE',
		'EMERGENCY CONTACT NAME ',
		'EMERGENCY CONTACT NUMBER',
		'BANK NAME',
		'BRANCH NAME',
		'IFSC CODE',
		'ACCOUNT NUMBER',
		'VENDOR TYPE',
		'IS CONTRACT ELIGIBLE',
		'ELIGIBILITY REMARK',
		'EXTENSION STATUS',
		'PAYMENT REMARK',
		'CREDIT TERM',
		'ADVANCE PERCENTAGE %',
		'TOTAL EXPERIENCE',
	];

	const generateCSV = () => {
		if (userList?.user?.length === 0) {
			return;
		}
		const consultantRow = userList?.user?.map((exportConsultant) => {
			const getCustomFieldValue = (value) => {
				const key = `custom_field_${value}`;
				return head(exportConsultant?.contractors)?.[key] || '';
			};
			const customFieldData = indexArray?.map((value) =>
				value?.type === 'date'
					? getCustomFieldValue(value?.index)
						? dayjs(getCustomFieldValue(value?.index)).format(dateFormat)
						: '--'
					: getCustomFieldValue(value?.index) || '--'
			);

			const totalExperience = () => {
				if (!head(exportConsultant?.contractors)?.total_experience) {
					return;
				}
				const totalExperienceToArray = head(
					exportConsultant?.contractors
				)?.total_experience?.split(':');
				if (totalExperienceToArray[0] > 0 && totalExperienceToArray[1] > 0) {
					return `${totalExperienceToArray[0]} - yrs & ${totalExperienceToArray[1]} - mnths `;
				} else {
					return '--';
				}
			};

			const existingContractorId =
				head(exportConsultant?.contractors)?.contractor_id || '';
			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const contractorId = existingContractorId
				? `${
						getPrefix(
							head(exportConsultant?.contractors)?.contractor_prefix?.name,
							head(exportConsultant?.contractors)?.contractor_prefix?.value
						) || ''
				  }${formattedContractorId || '--'}`
				: '--';

			const reportingTo = head(exportConsultant?.contractors)?.reportee
				?.full_name;

			const reportingToEmailId = head(exportConsultant?.contractors)?.reportee
				?.email;

			const currencySymbol =
				head(exportConsultant?.contractors)?.organization?.currency?.symbol ||
				'';

			const locationDetails =
				head(exportConsultant?.contractors)?.org_location?.name || '--';

			return [
				contractorId,
				exportConsultant.full_name || '--',
				exportConsultant.email || '--',
				exportConsultant.user_type
					? exportConsultant.user_type === 'contract_employee'
						? 'Consultant'
						: 'Employee'
					: '--',
				reportingTo || '--',
				reportingToEmailId || '--',
				head(exportConsultant?.contractors)?.department?.name || '--',
				head(exportConsultant?.contractors)?.designation?.title || '--',
				...(noOfLocations && noOfLocations > 0 ? [locationDetails] : []),
				head(exportConsultant?.contractors)?.contractorStatus?.label || '--',
				head(exportConsultant?.contractors)?.is_billable === true
					? 'Billable'
					: 'Non Billable' || '--',
				head(exportConsultant?.contractors)?.billing_cost
					? `${currencySymbol}${
							head(exportConsultant?.contractors)?.billing_cost
					  }`
					: '--',
				head(exportConsultant?.contractors)?.contractor_cost
					? `${currencySymbol}${
							head(exportConsultant?.contractors)?.contractor_cost
					  }`
					: '--',
				head(exportConsultant?.contractors)?.job_level?.level || '--',
				head(exportConsultant?.contractors)?.working_mode || '--',
				head(exportConsultant?.contractors)?.join_date
					? dayjs(head(exportConsultant?.contractors)?.join_date).format(
							dateFormat
					  )
					: '--',
				head(exportConsultant?.contractors)?.end_date
					? dayjs(head(exportConsultant?.contractors)?.end_date).format(
							dateFormat
					  )
					: '--',
				head(exportConsultant?.contractors)?.personal_email || '--',
				exportConsultant.phone_number
					? formatPhoneNumber(exportConsultant.phone_number)
					: '--',
				head(exportConsultant?.contractors)?.dob
					? dayjs(head(exportConsultant?.contractors)?.dob).format(dateFormat)
					: '--',
				head(exportConsultant?.contractors)?.gender || '--',
				head(exportConsultant?.contractors)?.marital_status === true
					? 'Married'
					: 'Unmarried' || '--',
				head(exportConsultant?.contractors)?.wedding_date
					? dayjs(head(exportConsultant?.contractors)?.wedding_date).format(
							dateFormat
					  )
					: '--',

				head(exportConsultant?.contractors)?.emergency_contact_name || '--',
				head(exportConsultant?.contractors)?.emergency_contact_number
					? formatPhoneNumber(
							head(exportConsultant?.contractors)?.emergency_contact_number
					  )
					: '--',
				head(exportConsultant?.contractors)?.bank_name || '--',
				head(exportConsultant?.contractors)?.branch_name || '--',
				head(exportConsultant?.contractors)?.ifsc_code || '--',
				head(exportConsultant?.contractors)?.account_number || '--',

				head(exportConsultant?.contractors)?.vendor_type || '--',

				head(exportConsultant?.contractors)?.is_recontract_eligible === true
					? 'Yes'
					: 'No' || '--',

				head(exportConsultant?.contractors)?.eligibility_remark || '--',
				head(exportConsultant?.contractors)?.extension_status === true
					? 'Yes'
					: 'No' || '--',
				head(exportConsultant?.contractors)?.payment_remark || '--',
				head(exportConsultant?.contractors)?.credit_term || '--',
				head(exportConsultant?.contractors)?.advance_percentage || '--',

				totalExperience() || '--',

				...customFieldData,
			];
		});
		const FINAL_EXPORT_COLUMNS = CONSULTANT_EXPORT_COLUMNS.concat(customFields);
		const consultantExportData = [FINAL_EXPORT_COLUMNS, ...consultantRow];
		exportToCsv('Consultants.csv', consultantExportData);
	};

	const ConsultantGrid = (props) => {
		const { data: consultantList, ids, loading } = useListContext();

		if (loading) {
			return <Loading />;
		}

		return ids ? (
			<Box className={classes.gridList}>
				{ids.map((id, index) => (
					<ConsultantCard
						key={index}
						consultant={consultantList[id]}
						basePath={props.basePath}
						resource={props.resource}
					/>
				))}
			</Box>
		) : null;
	};

	const redirectToDetailsPage = (contractorId) => {
		history.push({ pathname: `contractor/${contractorId}` });
	};

	const getLimits = (value) => {
		if (!value) {
			return;
		}
		return (
			<Box className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState?.consultantStatusId?.length === 1
					? filterState?.consultantStatusId[0].label
					: `Selected ${value} items`}
			</Box>
		);
	};

	const handleSelectAll = (isSelected) => {
		if (isSelected) {
			const statusOptions = consultantStatusOptions?.contractor_status?.map(
				(status) => {
					return {
						value: status?.id,
						label: status?.label,
					};
				}
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS,
				payload: statusOptions,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS,
				payload: [],
			});
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll(!allSelected);
	};

	const onEmployeeStatusChange = (event, value, reason) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS,
				payload: [],
			});
			return;
		}
	};

	const getLocationLimits = (value) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = filterState?.consultantLocation?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<Box className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState?.consultantLocation &&
				filterState?.consultantLocation?.length === 1
					? filterState?.consultantLocation[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</Box>
		);
	};

	const filter = createFilterOptions();

	return (
		<>
			<Box className={commonStyles.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						!isConsultantInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsConsultantInsightsShown(false);
					}}
				>
					Consultants({consultantCount})
				</Button>
				{permissions?.appPermissions?.ui?.viewConsultantInsights && (
					<Button
						className={[
							commonStyles.navigationTabButtons,
							isConsultantInsightsShown
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={() => {
							setIsConsultantInsightsShown(true);
						}}
						startIcon={<InsightsIcon />}
					>
						Insights
					</Button>
				)}
			</Box>
			{!isConsultantInsightsShown ? (
				<>
					<Box>
						<Box>
							<TopToolbar>
								<Box className={classes.toolContainer}>
									<Box display={'flex'}>
										<Box>
											<form onSubmit={(event) => event.preventDefault()}>
												<ThemeProvider theme={searchBarTheme}>
													<Box width='300px'>
														<Field
															placeholder='Search consultant'
															label={false}
															fullWidth
															InputLabelProps={{ style: { fontSize: 0 } }}
															InputProps={{
																startAdornment: <SearchIcon />,
															}}
															onChange={(e) => {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_SEARCH_TEXT,
																	payload: e?.target?.value,
																});
															}}
															value={filterState?.consultantSearchText || ''}
														/>
													</Box>
												</ThemeProvider>
											</form>
										</Box>
										<Box marginLeft={'15px'}>
											<Box paddingBottom={'5px'} marginTop={'-19px'}>
												<Box className={dropdownStyle.dropDownFilterLabel}>
													Expiry
												</Box>
											</Box>

											<form>
												<ThemeProvider theme={searchBarTheme}>
													<Box className={classes.expiryFilterStatus}>
														<Form
															onSubmit={() => {}}
															initialValues={{
																filterOptions: filterContractList,
															}}
														>
															{() => (
																<ThemeProvider theme={modalFormTheme}>
																	<form>
																		<SelectInput
																			label={''}
																			source='filterOptions'
																			onChange={(event) => {
																				getFilterChangeOptions(event);
																			}}
																			choices={consultantFilterOptions}
																		/>
																	</form>
																</ThemeProvider>
															)}
														</Form>
													</Box>
												</ThemeProvider>
											</form>
										</Box>
										<Box marginLeft={'15px'}>
											<Box paddingBottom={'5px'} marginTop={'-19px'}>
												<Box className={dropdownStyle.dropDownFilterLabel}>
													Status
												</Box>
											</Box>
											<Box width={'180px'}>
												<form>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															filterState?.consultantStatusId &&
															filterState?.consultantStatusId?.length > 0
																? filterState?.consultantStatusId
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={onEmployeeStatusChange}
														options={
															employeeStatusList?.length > 0
																? employeeStatusList
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getLimits}
														ChipProps={{ style: { display: 'none' } }}
														filterOptions={(options, params) => {
															const filtered = filter(options, params);
															return [
																{ label: 'All', value: 'All' },
																...filtered,
															];
														}}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<Field {...params} label='' placeholder={''} />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => {
															const selectAllProps =
																option.value === 'All' // To control the state of 'select-all' checkbox
																	? { checked: allSelected }
																	: {};
															return (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected || false}
																		color='primary'
																		{...selectAllProps}
																	/>
																	<Box
																		style={{ fontSize: '12px' }}
																	>{`${option?.label}`}</Box>
																</>
															);
														}}
														getOptionLabel={(option) => `${option?.label}`}
														getOptionSelected={(option, value) =>
															option.value === value.value
														}
													/>
												</form>
											</Box>
										</Box>
										<Box marginLeft={'12px'}>
											{noOfLocations && noOfLocations > 0 && (
												<Box>
													<Box className={classes.locationBox}>
														<Box className={dropdownStyle.dropDownFilterLabel}>
															Location
														</Box>
													</Box>
													<Box
														className={classes.projectFilterStatus}
														width='180px'
													>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																filterState?.consultantLocation &&
																filterState?.consultantLocation?.length > 0
																	? filterState?.consultantLocation
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(location) =>
																			location.id !== 'All' &&
																			location.id !== 'Unassigned'
																	)
																	.map((location) => location?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																		payload: [
																			{ id: 'All', name: 'All' },
																			...locationList,
																		],
																	});
																	const allLocationIds = locationList?.map(
																		(location) => location?.id
																	);
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																		payload: allLocationIds,
																	});
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	locationList?.length !== selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(location) => location?.id !== 'All'
																	);
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																		payload: currentOptions,
																	});

																	const currentIds = currentOptions?.map(
																		(location) => location?.id
																	);
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																		payload: currentIds,
																	});
																	return;
																}
																const selectedIdsWithoutUnassigned =
																	filterState?.consultantLocationIdList?.filter(
																		(location) => location !== 'Unassigned'
																	) ?? [];

																if (
																	selectedIds?.length === 0 &&
																	selectedIdsWithoutUnassigned.length === 0 &&
																	reason === 'select-option'
																) {
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																		payload: [
																			{ id: 'Unassigned', name: 'Unassigned' },
																		],
																	});

																	const locationIds = locationList?.map(
																		(location) => location?.id === 'Unassigned'
																	);
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																		payload: locationIds,
																	});
																	return;
																}

																const selectedAllIds = value?.map(
																	(location) => location?.id
																);
																if (
																	selectedAllIds?.length ===
																		locationList.length &&
																	reason === 'select-option'
																) {
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																		payload: [
																			{ id: 'All', name: 'All' },
																			...locationList,
																		],
																	});

																	const locationIds = locationList?.map(
																		(location) => location?.id
																	);
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																		payload: locationIds,
																	});
																	return;
																}
																if (
																	filterState?.consultantLocation?.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																		payload: [],
																	});
																	dispatchFilter({
																		type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																		payload: [],
																	});
																	return;
																}
																const selectedId = value?.map(
																	(location) => location?.id
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST,
																	payload: selectedId,
																});
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION,
																	payload: value,
																});
															}}
															options={
																locationList && locationList?.length > 0
																	? locationList
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getLocationLimits}
															ChipProps={{ style: { display: 'none' } }}
															filterOptions={(options, params) => {
																const filtered = filter(options, params);
																return [
																	{ id: 'All', name: 'All' },
																	...filtered,
																];
															}}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<Field
																		{...params}
																		label=''
																		placeholder={''}
																	/>
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => {
																return (
																	<>
																		<Checkbox
																			icon={
																				<CheckBoxOutlineBlankIcon fontSize='small' />
																			}
																			checkedIcon={
																				<CheckBoxIcon fontSize='small' />
																			}
																			style={{ marginRight: 8 }}
																			checked={selected || false}
																			color='primary'
																		/>
																		<Box
																			style={{ fontSize: '12px' }}
																		>{`${option?.name}`}</Box>
																	</>
																);
															}}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
											)}
										</Box>
									</Box>

									<Box display={'flex'}>
										<Box marginRight={'10px'}>
											<ExportButtonWithLoader
												generateCSV={generateCSV}
												style={classes.exportButton}
												isDisabled={
													!userList?.user?.length || isUserListLoading
												}
											/>
										</Box>
										<Tooltip title={'Grid view'}>
											<IconButton
												onClick={() => {
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_VIEW_FILTER,
														payload: false,
													});
												}}
												className={[
													commonStyles.iconButton,
													!filterState?.isConsultantListView
														? commonStyles.activeIconButton
														: '',
												].join(' ')}
											>
												<GridIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title={'List view'}>
											<IconButton
												onClick={() => {
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_VIEW_FILTER,
														payload: true,
													});
												}}
												className={[
													commonStyles.iconButton,
													filterState?.isConsultantListView
														? commonStyles.activeIconButton
														: '',
												].join(' ')}
											>
												<ListIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title={'Filter'}>
											<IconButton
												onClick={() => setIsDrawerShown(true)}
												className={commonStyles.filterButton}
											>
												<FilterListIcon />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
							</TopToolbar>
						</Box>
					</Box>
					<List
						{...props}
						bulkActionButtons={false}
						pagination={
							<ConsultantPagination
								userData={userList?.user?.length ?? -1}
								{...props}
							/>
						}
						perPage={15}
						filters={
							<Box className={classes.filterStyle}>
								<ConsultantFilter />
							</Box>
						}
						component='div'
						title={' '}
						actions={
							<DrawerFilterContainer
								open={isDrawerShown}
								close={() => {
									setIsDrawerShown(false);
								}}
							>
								<ConsultantFilterFormBody />
							</DrawerFilterContainer>
						}
						filter={{ user_id: userList && userList.user?.map((e) => e?.id) }}
					>
						{!filterState?.isConsultantListView ? (
							<ConsultantGrid />
						) : (
							<Datagrid
								className={classes.listStyle}
								rowClick={(consultant) => {
									redirectToDetailsPage(consultant);
								}}
							>
								<FunctionField
									source='contractor_id'
									label='Contractor Code'
									render={(contractor) => {
										const existingContractorId =
											contractor?.contractor_id || '';
										const formattedContractorId = formatEmployeeId(
											existingContractorId,
											empIdLength,
											isFixedEmpIdLengthEnabled
										);
										return contractor?.contractor_prefix_id ? (
											<ReferenceField
												link={false}
												source='contractor_prefix_id'
												reference='contractor_prefix'
												record={contractor}
											>
												<>
													<FunctionField
														render={(record) => {
															return (
																<Tooltip
																	title={`${getPrefix(
																		record?.name,
																		record?.value
																	)}${formattedContractorId || ''}`}
																	placement='right'
																>
																	<Box
																		className={`${classes.designation} ${styles.ellipsis}`}
																	>
																		{`${getPrefix(
																			record?.name,
																			record?.value
																		)}${formattedContractorId || ''}`}
																	</Box>
																</Tooltip>
															);
														}}
													/>
												</>
											</ReferenceField>
										) : (
											<>
												<Tooltip
													placement='right'
													title={formattedContractorId || ''}
												>
													<Box>{`${formattedContractorId || ''}`}</Box>
												</Tooltip>
											</>
										);
									}}
								/>

								<ReferenceField
									link={false}
									source='user_id'
									reference='user'
									label='Full Name'
								>
									<FullNameField />
								</ReferenceField>

								<FunctionField
									label='Email'
									render={(record) =>
										record?.user_id ? (
											<ReferenceField
												link={false}
												source='user_id'
												reference='user'
												label='Email'
											>
												<FunctionField
													label=''
													render={(record) => {
														return (
															<Tooltip
																title={`${record?.email || '- -'}`}
																placement='right'
															>
																<Typography
																	className={`${classes.name} ${styles.ellipsis}`}
																>
																	{`${record?.email || '- -'}`}
																</Typography>
															</Tooltip>
														);
													}}
												/>
											</ReferenceField>
										) : (
											'- -'
										)
									}
								/>

								<FunctionField
									label='Department'
									render={(record) =>
										record?.department_id ? (
											<ReferenceField
												link={false}
												source='department_id'
												reference='department'
											>
												<>
													<FunctionField
														render={(record) => {
															return (
																<Tooltip
																	title={`${record?.name || ''}`}
																	placement='right'
																>
																	<Typography
																		className={`${classes.designation} ${styles.ellipsis}`}
																	>
																		{record?.name || ''}
																	</Typography>
																</Tooltip>
															);
														}}
													/>
												</>
											</ReferenceField>
										) : (
											'- -'
										)
									}
								/>
								<FunctionField
									label='Designation'
									render={(record) =>
										record?.designation_id ? (
											<ReferenceField
												link={false}
												source='designation_id'
												reference='designation'
											>
												<>
													<FunctionField
														render={(record) => {
															return (
																<Tooltip
																	title={`${record?.title || ''}`}
																	placement='right'
																>
																	<Typography
																		className={`${classes.designation} ${styles.ellipsis}`}
																	>
																		{record?.title || ''}
																	</Typography>
																</Tooltip>
															);
														}}
													/>
												</>
											</ReferenceField>
										) : (
											'- -'
										)
									}
								/>
								<FunctionField
									label='Job level'
									render={(record) =>
										record?.job_level_id ? (
											<ReferenceField
												link={false}
												source='job_level_id'
												reference='job_level'
											>
												<FunctionField
													label=''
													render={(record) => `${record?.level}`}
												/>
											</ReferenceField>
										) : (
											'- -'
										)
									}
								/>

								<FunctionField
									label='End Date'
									render={(record) =>
										record?.end_date
											? getConsultantEndDate(record?.end_date)
											: '- -'
									}
								/>
							</Datagrid>
						)}
					</List>
				</>
			) : (
				<ConsultantInsights />
			)}
		</>
	);
};
