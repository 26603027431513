import React, { useContext, useState } from 'react';
import { List, Datagrid, ReferenceField, FunctionField } from 'react-admin';
import { UserProfileContext } from '../../../../src/App';
import {
	makeStyles,
	Box,
	Tooltip,
	Typography,
	TextField,
	IconButton,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import { listTheme } from '../DetailsPage/listTheme';
import { ellipsisStyle } from '../../../Layout/styles';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { searchBarTheme } from './CompanyContactList.component';
import { useGetCompanyProjectListQuery } from '../../../generated/graphql';
import { COMPANY_PROJECTLIST_EXPORT_COLUMN } from '../Constant';
import { exportToCsv } from '../../../Utils/string.util';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles(() => ({
	label: {
		width: '93px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	tableList: {
		height: '460px',
		overflowY: 'auto',
	},
	menuIcon: {
		height: '18px',
		width: '18px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
}));

const CompanyProjectList = () => {
	const { dateFormat, permissions } = useContext<any>(UserProfileContext);
	const inlineStyle = useStyles();
	const styles = ellipsisStyle();
	const { id }: { id: string } = useParams();
	const [searchedProject, setSearchedProject] = useState('');
	const history = useHistory();

	const { data: companyProjectList } = useGetCompanyProjectListQuery({
		variables: {
			companyId: id,
			name: `%${searchedProject}%`,
		},

		fetchPolicy: 'network-only',
	});

	const generateCSV = () => {
		if (!companyProjectList) {
			return;
		}
		const companyProjects = companyProjectList?.project_list?.map((project) => {
			return [
				project?.name || '- -',
				project?.ProjectOwner?.full_name || '- -',
				project?.start_date || '- -',
				project?.end_date || '- -',
				project?.ProjectStatus?.label || '- -',
			];
		});

		const companyProjectListExportData = [
			COMPANY_PROJECTLIST_EXPORT_COLUMN,
			...companyProjects,
		];
		exportToCsv(
			'Company_Project_List_Export.csv',
			companyProjectListExportData
		);
	};
	return (
		<div>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<form onSubmit={(event) => event.preventDefault()}>
					<ThemeProvider theme={searchBarTheme}>
						<TextField
							placeholder='Search Project'
							label={false}
							fullWidth
							InputProps={{
								startAdornment: <SearchOutlinedIcon />,
							}}
							onChange={(e) => setSearchedProject(e?.target?.value)}
							value={searchedProject}
						/>
					</ThemeProvider>
				</form>

				<Box marginRight={'2px'}>
					<ExportButtonWithLoader generateCSV={generateCSV} isDisabled={!companyProjectList?.project_list?.length}/>
				</Box>
			</Box>
			<Box className={inlineStyle.tableList} mt={2}>
				<ThemeProvider theme={listTheme}>
					<List
						resource='project_list'
						basePath='/crm_company'
						bulkActionButtons={false}
						actions={false}
						filter={{ company_id: id, name: searchedProject }}
						pagination={false}
						title={' '}
						sort={{ field: 'name', order: 'ASC' }}
					>
						<Datagrid>
							<FunctionField
								label='PROJECT NAME'
								render={(record: any) => {
									return (
										<Tooltip title={`${record?.name || ''}`} placement='right'>
											<Typography
												className={[inlineStyle.label, styles.ellipsis].join(
													' '
												)}
											>
												{`${record?.name}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='PROJECT OWNER'
								render={(record: any) =>
									record?.project_owner_id ? (
										<>
											<ReferenceField
												source='project_owner_id'
												label='LEAD OWNER'
												reference='user'
												link={false}
											>
												<FunctionField
													render={(record: any) => {
														return (
															<Tooltip
																title={`${record?.full_name || ''} `}
																placement='right'
															>
																<Typography
																	className={[
																		inlineStyle.label,
																		styles.ellipsis,
																	].join(' ')}
																>
																	{`${record?.full_name || ''} `}
																</Typography>
															</Tooltip>
														);
													}}
												/>
											</ReferenceField>
										</>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								label='PROJECT START DATE'
								render={(record: any) =>
									record?.start_date ? (
										<>
											<Box
												className={[inlineStyle.label, styles.ellipsis].join(
													' '
												)}
											>
												{record?.start_date
													? dayjs(record?.start_date).format(dateFormat)
													: '--'}
											</Box>
										</>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								label='PROJECT END DATE'
								render={(record: any) =>
									record?.end_date ? (
										<>
											<Box
												className={[inlineStyle.label, styles.ellipsis].join(
													' '
												)}
											>
												{record?.end_date
													? dayjs(record?.end_date).format(dateFormat)
													: '--'}
											</Box>
										</>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								label='PROJECT STATUS'
								render={(record: any) =>
									record?.project_status_id ? (
										<>
											<ReferenceField
												source='project_status_id'
												label='LEAD OWNER'
												reference='project_status'
												link={false}
											>
												<FunctionField
													render={(record: any) => {
														return (
															<Tooltip
																title={`${record?.label || ''}`}
																placement='right'
															>
																<Typography
																	className={[
																		inlineStyle.label,
																		styles.ellipsis,
																	].join(' ')}
																>
																	{`${record?.label || ''} `}
																</Typography>
															</Tooltip>
														);
													}}
												/>
											</ReferenceField>
										</>
									) : (
										'- -'
									)
								}
							/>
							{permissions?.project?.update_permissions && (
								<FunctionField
									render={(record: any) => (
										<Box
											onClick={(e) => {
												e.stopPropagation();
												history.push(`/project/detail/${record?.id}`);
											}}
										>
											<Tooltip title='Project details'>
												<IconButton
													onClick={(event) => {
														event.stopPropagation();
														history.push(`/project/detail/${record?.id}`);
													}}
												>
													<SettingsOutlinedIcon
														className={inlineStyle.menuIcon}
													/>
												</IconButton>
											</Tooltip>
										</Box>
									)}
								></FunctionField>
							)}
						</Datagrid>
					</List>
				</ThemeProvider>
			</Box>
		</div>
	);
};
export default CompanyProjectList;
