import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { modalFormStyle } from '../Layout/styles';
import BlockIcon from '@material-ui/icons/Block';

const pageNotFoundStyle = makeStyles({
	container: {
		width: '100%',
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	text: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '20px',
		marginBottom: '16px',
	},
	errorIcon: {
		width: '80px',
		height: '80px',
		color: '#EA4335',
		marginBottom: '16px',
	},
});

const PageNotFound = () => {
	const pageNotFoundStyles = pageNotFoundStyle();
	const history = useHistory();
	const modalFormStyles = modalFormStyle();

	return (
		<div className={pageNotFoundStyles.container}>
			<BlockIcon className={pageNotFoundStyles.errorIcon} />
			<Typography className={pageNotFoundStyles.text}>
				Page Not Found
			</Typography>
			<Button
				className={modalFormStyles.saveButton}
				onClick={() => {
					history.goBack();
				}}
			>
				Back
			</Button>
		</div>
	);
};

export default PageNotFound;
