import React, { useState } from 'react';
import {
	SelectInput,
	ReferenceInput,
	useNotify,
	required,
	NumberInput,
	useRefresh,
	TextInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { useMutation as useApolloMutation } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { UPSERT_PROJECT_SKILL } from '../gqlQueries';
import { SKILL_ALREADY_EXIST_MESSAGE } from '../../Employees/constant';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100%',
		padding: '20px',
		borderRadius: '4px',
	},
	formContainer: {
		marginTop: '24px',
		width: '401px',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '8px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		paddingBottom: '1em',
	},
}));

export interface ProjectSkill {
	skill_id?: string;
	skill_level_id?: string;
	count?: number;
	id?: string;
	request_status_id?: string;
	notes?: string;
}

interface Props {
	projectSkill?: ProjectSkill;
	open: boolean;
	onClose: () => void;
	refetch: () => void;
}

const emptySpaceMessage = 'Required';

const ProjectSkillForm = (props: Props) => {
	const { open, onClose, refetch, projectSkill } = props;
	const { id }: { id: string } = useParams();
	const classes = modalFormStyle();
	const containerClass = useStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const [skillIdError, setSkillIdError] = useState(false);
	const [skillIdErrorMsg, setSkillIdErrorMsg] = useState('');
	const [upsertProjectSkill, { loading: isProjectSkillLoading }] =
		useApolloMutation(UPSERT_PROJECT_SKILL);
	const saveDetails = (skill: ProjectSkill) => {
		upsertProjectSkill({
			variables: {
				projectSkill: {
					project_id: id,
					...skill,
				},
			},
		})
			.then((data) => {
				onClose();
				setSkillIdError(false);
				refetch();
				refresh();
				notify('Project Skill Created SuccessFully');
			})
			.catch((error) => {
				if (
					error.message.includes(
						'project_skill_requirement_project_id_skill_id_skill_level_id_or'
					)
				) {
					setSkillIdError(true);
					setSkillIdErrorMsg(SKILL_ALREADY_EXIST_MESSAGE);
					return;
				}
			});
	};

	const validateNotesFieldDescription = (value: string) => {
		if (!value) {
			return emptySpaceMessage;
		}
		if (value.trim() === '') {
			return emptySpaceMessage;
		}
		return undefined;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{projectSkill?.id ? 'Edit Skill' : 'Add Skill'}
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => {
								setSkillIdError(false);
								onClose();
							}}
						/>
					</Box>
					<Box className={containerClass.formContainer}>
						<Form initialValues={projectSkill} onSubmit={saveDetails}>
							{({ handleSubmit, invalid }) => (
								<form onSubmit={handleSubmit}>
									<Typography className={classes.label}>Skill *</Typography>
									<ReferenceInput
										source='skill_id'
										reference='skill_master'
										label=''
										validate={[required()]}
										filterToQuery={(searchText) => ({ name: searchText })}
										filter={{ name: true }}
										sort={{ field: 'name', order: 'ASC' }}
									>
										<SelectInput optionText='name' />
									</ReferenceInput>
									<Typography className={classes.label}>
										Skill Level *
									</Typography>
									<ReferenceInput
										label=''
										validate={[required()]}
										source='skill_level_id'
										reference='skill_level'
										sort={{ field: 'level', order: 'ASC' }}
									>
										<SelectInput optionText='level' />
									</ReferenceInput>
									<Typography className={classes.label}>Count *</Typography>
									<NumberInput
										min={1}
										source='count'
										label={''}
										validate={[required()]}
									/>
									<Typography className={classes.label}>Status *</Typography>
									<ReferenceInput
										label=''
										validate={[required()]}
										source='request_status_id'
										reference='project_skill_request_status'
										sort={{ field: 'label', order: 'ASC' }}
									>
										<SelectInput optionText='label' />
									</ReferenceInput>
									<Typography className={classes.label}>Notes *</Typography>
									<TextInput
										source='notes'
										fullWidth={true}
										label={false}
										validate={validateNotesFieldDescription}
										multiline
									/>
									{skillIdError && (
										<p className={containerClass.errorText}>
											{skillIdErrorMsg}
										</p>
									)}
									<Box className={containerClass.buttonContainer}>
										<Button
											onClick={() => {
												setSkillIdError(false);
												onClose();
											}}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid || isProjectSkillLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isProjectSkillLoading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectSkillForm;
