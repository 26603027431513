import React, { useState, useEffect, useContext } from 'react';
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	useListContext,
	TopToolbar,
	Loading,
	FunctionField,
} from 'react-admin';

import { useQuery as ApolloUseQuery } from '@apollo/client';

import { EmployeeInsights } from './EmployeeInsights.component';

import FullNameField from '../../SharedComponents/FullNameField.component';
import { EmployeeFilterFormBody } from './EmployeeFilterFormBody.component';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import {
	Box,
	IconButton,
	Button,
	Chip,
	TextField as Field,
	makeStyles,
	Typography,
	Checkbox,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ThemeProvider } from '@material-ui/core/styles';
import { EmployeeCard } from './EmployeeCard.component';
import { useHistory } from 'react-router';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import { GridIcon, ListIcon, InsightsIcon } from '../../assets/SvgIcons';
import {
	ellipsisStyle,
	globalStyles,
	labelStyle,
	autocompleteStyle,
} from '../../Layout/styles';
import {
	searchBarTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import Tooltip from '@material-ui/core/Tooltip';
import {
	GET_EMPLOYEE_CUSTOM_FIELDS,
	GET_EMPLOYEE_SEARCH,
	GET_EMPLOYEE_STATUS_OPTIONS,
} from './gqlQueries';
import { UserProfileContext } from '../../App';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	formatEmployeeId,
	exportToCsv,
	getPrefix,
	formatPhoneNumber,
} from '../../Utils/string.util';
import dayjs from 'dayjs';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useGetOrgLocationsQuery } from '../../generated/graphql';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import { EmployeePagination } from './EmployeePagination';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
	filterStyle: {
		marginTop: '-74px',
		marginBottom: '5px',
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 19%)',
		maxWidth: 1220,
		margin: 'auto',
		gap: '14px',
		marginTop: '-40px',
	},
	toolContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: '8px',
	},
	listStyle: {
		marginTop: '-65px',
	},
	filterButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	designation: {
		width: '100px',
		fontSize: '12px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6',
		opacity: 1,
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	exportButton: {
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	name: {
		width: '150px',
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
}));

export const EmployeeList = (props) => {
	const {
		permissions,
		dateFormat,
		noOfLocations,
		orgId: organizationId,
		filterState,
		dispatchFilter,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext(UserProfileContext);
	const [isEmployeeInsightsShown, setIsEmployeeInsightsShown] = useState(false);
	const [employeeCount, setEmployeeCount] = useState(0);
	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [customFields, setCustomFields] = useState([]);
	const [indexArray, setIndexArray] = useState([]);
	const history = useHistory();
	const commonStyles = globalStyles();
	const classes = useStyles();
	const dropdownStyle = labelStyle();
	const dropDownStyles = autocompleteStyle();
	const styles = ellipsisStyle();

	const [employeeStatusList, setEmployeeStatusList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const filter = createFilterOptions();

	const { data: employeeCustomFields } = useApolloQuery(
		GET_EMPLOYEE_CUSTOM_FIELDS,
		{
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!employeeCustomFields) {
			return;
		}

		const labelArray = employeeCustomFields?.custom_field?.map((value) => {
			return value?.label?.toUpperCase();
		});
		setCustomFields(labelArray);

		const indexValue = employeeCustomFields?.custom_field?.map((data) => {
			return { index: data?.index, type: data?.type };
		});
		setIndexArray(indexValue);
	}, [employeeCustomFields]);

	const EMPLOYEE_EXPORT_COLUMNS = [
		'RESOURCE ID',
		'RESOURCE NAME',
		'EMAIL',
		'RESOURCE TYPE',
		'REPORTING TO',
		'REPORTING TO EMAIL',
		'DEPARTMENT',
		'DESIGNATION',
		...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
		'EMPLOYEE STATUS',
		'PROBATION START DATE',
		'PROBATION END DATE',
		'IS BILLABLE',
		'EMPLOYEE COST',
		'EMPLOYEE BILLING COST',
		'JOB LEVEL',
		'WORKING MODE',
		'JOINING SOURCE',
		'ORG SHIFT',
		'PRIOR EXPERIENCE',
		'JOIN DATE',
		'LAST WORKING DATE',
		'HIGHEST DEGREE',
		'INSTITUTION',
		'PERSONAL EMAIL',
		'PRIMARY CONTACT',
		'ALTERNATE CONTACT',
		'DATE OF BIRTH',
		'GENDER',
		'BLOOD GROUP',
		'FATHERS NAME',
		'MOTHERS NAME',
		'MARITAL STATUS',
		'WEDDING DATE',
		'SPOUSE NAME',
		'EMERGENCY CONTACT NAME',
		'EMERGENCY CONTACT NUMBER',
		'GOVT ISSUED A',
		'GOVT ISSUED B',
		'BANK NAME',
		'BRANCH NAME',
		'ACCOUNT NUMBER',
		'IFSC CODE',
		'SKILLS',
		'NOTES',
		'NOTES DESCRIPTION',
		'LEAVING REASON',
		'LEAVING NOTES',
	];

	const { data: employeeStatusOptions } = useApolloQuery(
		GET_EMPLOYEE_STATUS_OPTIONS
	);

	const { data: location } = useGetOrgLocationsQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	const allSelected =
		employeeStatusOptions?.employeeStatus?.length ===
			filterState?.employeeStatusFilter?.length || 0
			? true
			: false;

	useEffect(() => {
		if (!location) {
			return;
		}

		const orgLocation = location.org_location.map((location) => {
			return {
				id: location.id,
				name: location.name,
			};
		});
		setLocationList([...orgLocation, { id: 'Unassigned', name: 'Unassigned' }]);
	}, [location]);

	const selectedLocationValues =
		filterState?.employeeLocationFilter?.map((option) => option.id) ?? [];
	const selectedLocationIds = selectedLocationValues?.filter(
		(option) => option !== 'All' && option !== 'Unassigned'
	);

	const locationAssigned = {
		employeeStatus: allSelected
			? {}
			: filterState?.employeeStatusFilter &&
			  filterState?.employeeStatusFilter?.length > 0
			? { _in: filterState?.employeeStatusFilter?.map((value) => value?.value) }
			: {
					_in: [],
			  },

		location_id:
			selectedLocationValues.length > 0 &&
			selectedLocationValues.includes('All')
				? {}
				: selectedLocationValues.length === 1 &&
				  selectedLocationValues.includes('Unassigned')
				? {
						_is_null: true,
				  }
				: selectedLocationIds?.length > 0 &&
				  !selectedLocationValues.includes('Unassigned')
				? { _in: filterState?.employeeLocationIdFilter }
				: {},
	};

	const locationUnAssigned = {
		employeeStatus: allSelected
			? {}
			: filterState?.employeeStatusFilter &&
			  filterState?.employeeStatusFilter?.length > 0
			? { _in: filterState?.employeeStatusFilter?.map((value) => value?.value) }
			: {
					_in: [],
			  },

		_or: [
			{
				location_id: {
					_is_null: true,
				},
			},
			{
				location_id: {
					_in: selectedLocationIds,
				},
			},
		],
	};

	const { data: userList, loading: isLoading } = ApolloUseQuery(
		GET_EMPLOYEE_SEARCH,
		{
			variables: {
				name: `%${filterState?.employeeSearchText || ''.trim()}%`,

				employee:
					selectedLocationValues.length > 0 &&
					selectedLocationValues.includes('Unassigned')
						? locationUnAssigned
						: locationAssigned,
			},

			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!employeeStatusOptions) {
			return;
		}
		const activeStatus = employeeStatusOptions?.employeeStatus
			?.filter((status) => status.label === 'Confirmed')
			.map((value) => {
				return {
					value: value?.id,
					label: value?.label,
				};
			});
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER,
			payload: filterState?.employeeStatusFilter ?? activeStatus,
		});
		const statusOptions = employeeStatusOptions?.employeeStatus?.map(
			(status) => {
				return {
					value: status?.id,
					label: status?.label,
				};
			}
		);
		setEmployeeStatusList(statusOptions);
	}, [employeeStatusOptions, dispatchFilter, filterState.employeeStatusFilter]);

	useEffect(() => {
		if (!userList || !userList?.user?.length > 0) {
			setEmployeeCount(0);
			return;
		}
		setEmployeeCount(userList.user?.length);
	}, [userList]);
	const EmployeeFilter = (props) => {
		return (
			<>
				<Box display='flex' flexDirection='column' width='100%'>
					<AppliedFiltersContainer
						totalCount={(total) => setEmployeeCount(total)}
					>
						{getAppliedFilters}
					</AppliedFiltersContainer>
				</Box>
			</>
		);
	};
	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'user_id':
				return (
					<ReferenceField
						link={false}
						source='user_id'
						reference='user'
						label='Full Name'
						resource={props.resource}
						record={filterValues}
						basePath={props.basePath}
					>
						<FunctionField
							label='Full Name'
							render={(record) => (
								<Chip
									label={`${record?.full_name || ''} `}
									variant='outlined'
									onDelete={() => {
										removeFilter(filter);
									}}
								/>
							)}
						/>
					</ReferenceField>
				);
			case 'designation_id':
				return (
					filterValues?.designation_id.length > 0 &&
					filterValues?.designation_id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='designation'
							label='Full Name'
							resource='designation'
							record={{ id }}
							basePath={'/employee'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.title}`}
											variant='outlined'
											onDelete={() => {
												filterValues.designation_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'job_level_id':
				return (
					filterValues?.job_level_id.length > 0 &&
					filterValues?.job_level_id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='job_level'
							label='Full Name'
							resource='job_level'
							record={{ id }}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.level}`}
											variant='outlined'
											onDelete={() => {
												filterValues.job_level_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'department_id':
				return (
					filterValues?.department_id.length > 0 &&
					filterValues?.department_id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='department'
							label='Full Name'
							resource='department'
							record={{ id }}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={commonStyles.multiSelectFilterChip}
											key={index}
											label={`${record?.name}`}
											variant='outlined'
											onDelete={() => {
												filterValues.department_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'employeeStatus':
				return (
					filterValues?.employeeStatus.length > 0 &&
					filterValues?.employeeStatus.map((employeeStatus, index) => (
						<ReferenceField
							link={false}
							source='employeeStatus'
							reference='employee_status'
							label='Full Name'
							resource={props.resource}
							basePath={props.basePath}
							record={{ employeeStatus }}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<Chip
										label={record?.label}
										variant='outlined'
										onDelete={() => {
											filterValues.employeeStatus.splice(index, 1);
											setFilters(filterValues, displayedFilters);
										}}
									/>
								)}
							/>
						</ReferenceField>
					))
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};
	const EmployeeGrid = (props) => {
		const { data: employeeList, ids, loading } = useListContext();

		if (loading) {
			return <Loading />;
		}

		return ids ? (
			<Box className={classes.gridList}>
				{ids.map((id, index) => (
					<EmployeeCard
						key={index}
						data={employeeList[id]}
						basePath={props.basePath}
						resource={props.resource}
					/>
				))}
			</Box>
		) : null;
	};

	const redirectToDetailsPage = (employeeId) => {
		history.push({ pathname: `employee/details/${employeeId}` });
	};

	const generateCSV = () => {
		if (userList?.user?.length === 0) {
			return;
		}
		const employeeRow = userList?.user?.map((exportEmployee) => {
			const getCustomFieldValue = (value) => {
				const key = `custom_field_${value}`;
				return exportEmployee?.employee?.[key] || '';
			};

			const customFieldData = indexArray?.map((value) =>
				value?.type === 'date'
					? getCustomFieldValue(value?.index)
						? dayjs(getCustomFieldValue(value?.index)).format(dateFormat)
						: '--'
					: getCustomFieldValue(value?.index) || '--'
			);

			const isBillable = exportEmployee?.employee?.is_billable;
			const currencySymbol =
				exportEmployee?.employee?.organization?.currency?.symbol || '';
			const reportingTo = `${
				exportEmployee?.employee?.reportsTo?.full_name || '--'
			} `;
			const reportingToEmailId =
				exportEmployee?.employee?.reportsTo?.email || '--';
			const existingEmployeeId = exportEmployee?.employee.emp_id || '';
			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const employeeId = existingEmployeeId
				? `${
						getPrefix(
							exportEmployee?.employee?.employee_prefix?.name,
							exportEmployee?.employee?.employee_prefix?.value
						) || ''
				  }${formattedEmployeeId || '--'}`
				: '--';
			const priorExperience = () => {
				if (!exportEmployee?.employee?.prior_experience) {
					return;
				}
				const priorExperienceToArray =
					exportEmployee?.employee?.prior_experience?.split(':');
				if (priorExperienceToArray[0] > 0 && priorExperienceToArray[1] > 0) {
					return `${priorExperienceToArray[0]} - yrs & ${priorExperienceToArray[1]} - mnths `;
				} else {
					return '--';
				}
			};

			const skills =
				exportEmployee && exportEmployee?.employee_skill_mappings?.length > 0
					? exportEmployee?.employee_skill_mappings.map(
							(skillMapping) => skillMapping?.skill_master?.name || ''
					  )
					: [];

			const notes =
				exportEmployee && exportEmployee?.employee?.employee_notes?.length > 0
					? exportEmployee?.employee?.employee_notes?.map(
							(notes) => notes?.title.trim() || ''
					  )
					: [];
			const notesDescription =
				exportEmployee && exportEmployee?.employee?.employee_notes?.length > 0
					? exportEmployee?.employee?.employee_notes?.map(
							(notes) => notes?.note || ''
					  )
					: '--';

			const locationDetails = exportEmployee?.employee?.org_location
				? exportEmployee?.employee?.org_location?.name
				: '--';

			const leavingReasonNotes = exportEmployee?.employee_leaving_reasons
				? _.head(exportEmployee?.employee_leaving_reasons)?.notes
				: '--';

			const leavingReason =
				exportEmployee?.employee_leaving_reasons &&
				exportEmployee.employee_leaving_reasons.length > 0
					? _.head(exportEmployee.employee_leaving_reasons)?.leaving_reason
							?.label || '--'
					: '--';

			return [
				employeeId,
				exportEmployee.full_name || '--',
				exportEmployee.email || '--',
				exportEmployee?.user_type
					? exportEmployee?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--',
				reportingTo || '--',
				reportingToEmailId || '--',
				exportEmployee?.employee?.department?.name || '--',
				exportEmployee?.employee?.designation?.title || '--',
				...(noOfLocations && noOfLocations > 0 ? [locationDetails] : []),
				exportEmployee?.employee?.employee_status?.label || '--',
				exportEmployee?.employee?.probation_start_date
					? dayjs(exportEmployee?.employee?.probation_start_date).format(
							dateFormat
					  )
					: '--',
				exportEmployee?.employee?.probation_end_date
					? dayjs(exportEmployee?.employee?.probation_end_date).format(
							dateFormat
					  )
					: '--',
				isBillable === true ? 'Billable' : 'Non-Billable' || '--',
				exportEmployee?.employee?.emp_cost
					? `${currencySymbol}${exportEmployee?.employee?.emp_cost}`
					: '--',
				exportEmployee?.employee?.emp_billing_cost
					? `${currencySymbol}${exportEmployee?.employee?.emp_billing_cost}`
					: '--',
				exportEmployee?.employee?.job_level?.level || '--',
				exportEmployee?.employee?.working_mode || '--',
				exportEmployee?.employee?.emp_joining_source?.label || '--',
				exportEmployee?.employee?.org_shift?.name || '--',
				priorExperience() || '--',
				exportEmployee?.employee?.join_date
					? dayjs(exportEmployee?.employee?.join_date).format(dateFormat)
					: '--',
				exportEmployee?.employee?.last_date
					? exportEmployee?.employee?.employee_status?.value === 'inactive' ||
					  exportEmployee?.employee?.employee_status?.label === 'Notice'
						? dayjs(exportEmployee?.employee?.last_date).format(dateFormat)
						: '--'
					: '--',
				exportEmployee?.employee?.highest_academic_degree || '--',
				exportEmployee?.employee?.institution_name || '--',
				exportEmployee?.employee?.personal_email || '--',
				exportEmployee.phone_number
					? formatPhoneNumber(exportEmployee.phone_number)
					: '--',
				exportEmployee?.employee?.alternate_contact_number
					? formatPhoneNumber(
							exportEmployee?.employee?.alternate_contact_number
					  )
					: '--',
				exportEmployee?.employee?.dob
					? dayjs(exportEmployee?.employee?.dob).format(dateFormat)
					: '--',

				exportEmployee?.employee?.gender || '--',
				exportEmployee?.employee?.blood_group || '--',
				exportEmployee?.employee?.father_name || '--',
				exportEmployee?.employee?.mother_name || '--',
				exportEmployee?.employee?.marital_status === true
					? 'Married'
					: 'Not Married' || '--',
				exportEmployee?.employee?.wedding_date
					? dayjs(exportEmployee?.employee?.wedding_date).format(dateFormat)
					: '--',
				exportEmployee?.employee?.spouse_name || '--',
				exportEmployee?.employee?.emergency_contact_name || '--',
				exportEmployee?.employee?.emergency_contact_number
					? formatPhoneNumber(
							exportEmployee?.employee?.emergency_contact_number
					  )
					: '--',
				exportEmployee?.employee?.govt_issue_type_1 || '--',
				exportEmployee?.employee?.govt_issue_type_2 || '--',

				exportEmployee?.employee?.bank_name || '--',
				exportEmployee?.employee?.branch_name || '--',
				exportEmployee?.employee?.account_number || '--',
				exportEmployee?.employee?.ifsc_code || '--',

				skills.join(',') || '--',
				notes.join(', ') || '--',
				notesDescription || '--',
				leavingReason
					? exportEmployee?.employee?.employee_status?.value === 'inactive' ||
					  exportEmployee?.employee?.employee_status?.label === 'Notice'
						? leavingReason
						: '--'
					: '--',
				leavingReasonNotes
					? exportEmployee?.employee?.employee_status?.value === 'inactive' ||
					  exportEmployee?.employee?.employee_status?.label === 'Notice'
						? leavingReasonNotes
						: '--'
					: '--',

				...customFieldData,
			];
		});
		const FINAL_EXPORT_COLUMNS = EMPLOYEE_EXPORT_COLUMNS.concat(customFields);
		const employeeExportData = [FINAL_EXPORT_COLUMNS, ...employeeRow];
		exportToCsv('Employees.csv', employeeExportData);
	};

	const EmployeeContent = (props) => {
		const styles = ellipsisStyle();
		return filterState?.isEmployeeListView ? (
			<Datagrid
				className={classes.listStyle}
				rowClick={(record) => {
					redirectToDetailsPage(record);
				}}
			>
				<FunctionField
					source='emp_id'
					render={(emp) => {
						const existingEmployeeId = emp?.emp_id || '';
						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);
						return emp?.employee_prefix_id ? (
							<ReferenceField
								link={false}
								source='employee_prefix_id'
								reference='employee_prefix'
								record={emp}
							>
								<>
									<FunctionField
										render={(record) => {
											return (
												<Tooltip
													title={`${getPrefix(record?.name, record?.value)}${
														formattedEmployeeId || ''
													}`}
													placement='right'
												>
													<Box
														className={`${classes.designation} ${styles.ellipsis}`}
													>
														{`${getPrefix(record?.name, record?.value)}${
															formattedEmployeeId || ''
														}`}
													</Box>
												</Tooltip>
											);
										}}
									/>
								</>
							</ReferenceField>
						) : (
							<Tooltip placement='right' title={emp?.emp_id || ''}>
								<Box>{`${emp?.emp_id || ''}`}</Box>
							</Tooltip>
						);
					}}
				/>

				<ReferenceField
					link={false}
					source='user_id'
					reference='user'
					label='NAME'
					sortBy={'user.first_name'}
				>
					<FullNameField />
				</ReferenceField>

				<ReferenceField
					link={false}
					source='user_id'
					reference='user'
					label='EMAIL'
					sortBy={'user.email'}
				>
					<FunctionField
						label=''
						render={(record) => {
							return (
								<Tooltip title={`${record?.email || '- -'}`} placement='right'>
									<Typography className={`${classes.name} ${styles.ellipsis}`}>
										{`${record?.email || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</ReferenceField>
				<ReferenceField
					link={false}
					source='department_id'
					reference='department'
					label='DEPARTMENT'
					sortBy={'department.name'}
				>
					<FunctionField
						record={'department'}
						render={(record) => {
							return (
								<Tooltip title={`${record?.name || ''}`} placement='right'>
									<Typography
										className={`${classes.designation} ${styles.ellipsis}`}
									>
										{record?.name || ''}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</ReferenceField>
				<ReferenceField
					link={false}
					source='designation_id'
					reference='designation'
					label='DESIGNATION'
					sortBy={'designation.title'}
				>
					<FunctionField
						record={'designation'}
						render={(record) => {
							return (
								<Tooltip title={`${record?.title || ''}`} placement='right'>
									<Typography
										className={`${classes.designation} ${styles.ellipsis}`}
									>
										{record?.title || ''}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</ReferenceField>
				<ReferenceField
					link={false}
					source='job_level_id'
					reference='job_level'
					label='JOB LEVEL'
					sortBy={'job_level.level'}
				>
					<TextField source='level' />
				</ReferenceField>
			</Datagrid>
		) : (
			<EmployeeGrid />
		);
	};

	const getLimits = (value) => {
		if (!value) {
			return;
		}
		return (
			<Box className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState?.employeeStatusFilter?.length === 1
					? filterState?.employeeStatusFilter[0].label
					: `Selected ${value} items`}
			</Box>
		);
	};
	const handleSelectAll = (isSelected) => {
		if (isSelected) {
			const statusOptions = employeeStatusOptions?.employeeStatus?.map(
				(status) => {
					return {
						value: status?.id,
						label: status.label,
					};
				}
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER,
				payload: statusOptions,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER,
				payload: [],
			});
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll(!allSelected);
	};

	const onEmployeeStatusChange = (event, value, reason) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER,
				payload: [],
			});
			return;
		}
	};

	const getLocationLimits = (value) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = filterState?.employeeLocationFilter?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<Box className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState?.employeeLocationFilter &&
				filterState?.employeeLocationFilter?.length === 1
					? filterState?.employeeLocationFilter[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</Box>
		);
	};

	return (
		<>
			<Box className={commonStyles.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						!isEmployeeInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsEmployeeInsightsShown(false);
					}}
				>
					Employees({employeeCount})
				</Button>
				{permissions?.appPermissions?.ui?.viewEmployeeInsights && (
					<Button
						className={[
							commonStyles.navigationTabButtons,
							isEmployeeInsightsShown
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={() => {
							setIsEmployeeInsightsShown(true);
						}}
						startIcon={<InsightsIcon />}
					>
						Insights
					</Button>
				)}
			</Box>
			{!isEmployeeInsightsShown ? (
				<>
					<Box>
						<Box>
							<TopToolbar>
								<Box className={classes.toolContainer}>
									<Box display={'flex'}>
										<form onSubmit={(event) => event.preventDefault()}>
											<ThemeProvider theme={searchBarTheme}>
												<Box width='344px'>
													<Field
														placeholder='Search employee'
														label={false}
														fullWidth
														InputLabelProps={{ style: { fontSize: 0 } }}
														InputProps={{
															startAdornment: <SearchIcon />,
														}}
														onChange={(e) => {
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_SEARCH_TEXT,
																payload: e?.target?.value,
															});
														}}
														value={filterState?.employeeSearchText || ''}
													/>
												</Box>
											</ThemeProvider>
										</form>
										<Box>
											<Box paddingBottom={'5px'} marginTop={'-19px'}>
												<Box className={dropdownStyle.dropDownFilterLabel}>
													Status
												</Box>
											</Box>
											<Box
												className={classes.projectFilterStatus}
												width='180px'
											>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														filterState?.employeeStatusFilter &&
														filterState?.employeeStatusFilter?.length > 0
															? filterState?.employeeStatusFilter
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={onEmployeeStatusChange}
													options={
														employeeStatusList?.length > 0
															? employeeStatusList
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getLimits}
													ChipProps={{ style: { display: 'none' } }}
													filterOptions={(options, params) => {
														const filtered = filter(options, params);
														return [
															{ label: 'All', value: 'All' },
															...filtered,
														];
													}}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<Field {...params} label='' placeholder={''} />
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => {
														const selectAllProps =
															option.value === 'All' // To control the state of 'select-all' checkbox
																? { checked: allSelected }
																: {};
														return (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected || false}
																	color='primary'
																	{...selectAllProps}
																/>
																<Box
																	style={{ fontSize: '12px' }}
																>{`${option?.label}`}</Box>
															</>
														);
													}}
													getOptionLabel={(option) => `${option?.label}`}
													getOptionSelected={(option, value) =>
														option.value === value.value
													}
												/>
											</Box>
										</Box>
										{noOfLocations && noOfLocations > 0 && (
											<Box>
												<Box paddingBottom={'5px'} marginTop={'-19px'}>
													<Box className={dropdownStyle.dropDownFilterLabel}>
														Location
													</Box>
												</Box>
												<Box
													className={classes.projectFilterStatus}
													width='180px'
												>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															filterState?.employeeLocationFilter &&
															filterState?.employeeLocationFilter?.length > 0
																? filterState?.employeeLocationFilter
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter(
																	(location) =>
																		location.id !== 'All' &&
																		location.id !== 'Unassigned'
																)
																.map((location) => location?.id);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																	payload: [
																		{ id: 'All', name: 'All' },
																		...locationList,
																	],
																});
																const allLocationIds = locationList?.map(
																	(location) => location?.id
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																	payload: allLocationIds,
																});

																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																locationList?.length !== selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(location) => location?.id !== 'All'
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																	payload: currentOptions,
																});

																const currentIds = currentOptions?.map(
																	(location) => location?.id
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																	payload: currentIds,
																});

																return;
															}
															const selectedIdsWithoutUnassigned =
																filterState?.employeeLocationIdFilter?.filter(
																	(location) => location !== 'Unassigned'
																);

															if (
																selectedIds?.length === 0 &&
																selectedIdsWithoutUnassigned.length === 0 &&
																reason === 'select-option'
															) {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																	payload: [
																		{ id: 'Unassigned', name: 'Unassigned' },
																	],
																});
																const locationIds = locationList?.map(
																	(location) => location?.id === 'Unassigned'
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																	payload: locationIds,
																});

																return;
															}

															const selectedAllIds = value?.map(
																(location) => location?.id
															);
															if (
																selectedAllIds?.length ===
																	locationList.length &&
																reason === 'select-option'
															) {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																	payload: [
																		{ id: 'All', name: 'All' },
																		...locationList,
																	],
																});
																const locationIds = locationList?.map(
																	(location) => location?.id
																);
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																	payload: locationIds,
																});

																return;
															}
															if (
																filterState?.employeeLocationFilter?.find(
																	(option) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																	payload: [],
																});

																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																	payload: [],
																});
																return;
															}
															const selectedId = value?.map(
																(location) => location?.id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
																payload: selectedId,
															});

															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
																payload: value,
															});
														}}
														options={
															locationList && locationList?.length > 0
																? locationList
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getLocationLimits}
														ChipProps={{ style: { display: 'none' } }}
														filterOptions={(options, params) => {
															const filtered = filter(options, params);
															return [{ id: 'All', name: 'All' }, ...filtered];
														}}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<Field {...params} label='' placeholder={''} />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => {
															return (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected || false}
																		color='primary'
																	/>
																	<Box
																		style={{ fontSize: '12px' }}
																	>{`${option?.name}`}</Box>
																</>
															);
														}}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) =>
															option.id === value.id
														}
													/>
												</Box>
											</Box>
										)}
									</Box>
									<Box display={'flex'}>
										<Box marginRight={'10px'}>
											<ExportButtonWithLoader
												generateCSV={generateCSV}
												style={classes.exportButton}
												isDisabled={!userList?.user?.length || isLoading}
											/>
										</Box>
										<Tooltip title={'Grid view'}>
											<IconButton
												onClick={() => {
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_VIEW_FILTER,
														payload: false,
													});
												}}
												className={[
													commonStyles.iconButton,
													!filterState?.isEmployeeListView
														? commonStyles.activeIconButton
														: '',
												].join(' ')}
											>
												<GridIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title={'List view'}>
											<IconButton
												onClick={() => {
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_VIEW_FILTER,
														payload: true,
													});
												}}
												className={[
													commonStyles.iconButton,
													filterState?.isEmployeeListView
														? commonStyles.activeIconButton
														: '',
												].join(' ')}
											>
												<ListIcon />
											</IconButton>
										</Tooltip>
										<Tooltip title={'Filter'}>
											<IconButton
												onClick={() => setIsDrawerShown(true)}
												className={classes.filterButton}
											>
												<FilterListIcon />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
							</TopToolbar>
						</Box>
					</Box>
					<List
						resource='employee'
						basePath='/employee'
						filters={
							<Box className={classes.filterStyle}>
								<EmployeeFilter />
							</Box>
						}
						pagination={
							<EmployeePagination
								userData={userList?.user?.length ?? -1}
								{...props}
							/>
						}
						perPage={15}
						bulkActionButtons={false}
						sort={{ field: 'emp_id', order: 'ASC' }}
						component='div'
						actions={
							<DrawerFilterContainer
								open={isDrawerShown}
								close={() => {
									setIsDrawerShown(false);
								}}
							>
								<EmployeeFilterFormBody />
							</DrawerFilterContainer>
						}
						title={' '}
						filter={{ user_id: userList && userList.user?.map((e) => e?.id) }}
					>
						<EmployeeContent />
					</List>
				</>
			) : (
				<EmployeeInsights />
			)}
		</>
	);
};
