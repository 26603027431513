import React, { useState } from 'react';
import { List, ReferenceField, Datagrid, FunctionField } from 'react-admin';
import { Box, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import { Props } from './model';
import { ThemeProvider } from '@material-ui/core/styles';

import { GET_EMPLOYEE_SKILL_LIST } from '../Employees/EmployeeSkillMapping.sevices.gql';
import { ellipsisStyle } from '../../Layout/styles';
import { SearchIcon } from '../../assets/SvgIcons';
import { searchBarTheme } from '../../Layout/Theme.component';

const MyInfoSkillDetail = (props: Props) => {
	const [searchSkill, setSearchSkill] = useState('');
	const useStyles = makeStyles((theme) => ({
		skillName: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
			width: '200px',
		},
		tableList: {
			height: '442px',
			overflowY: 'auto',
			marginTop: '30px',
		},
	}));
	const styles = ellipsisStyle();
	const classes = useStyles();
	const { myInfoViewDetails } = props;

	const { data: employeeSkill } = ApolloUseQuery(GET_EMPLOYEE_SKILL_LIST, {
		variables: {
			filter: {
				employee_id: {
					_eq: myInfoViewDetails?.id,
				},
				skill_master: {
					name: {
						_ilike: `%${searchSkill}%`,
					},
				},
			},
		},
	});
	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px'>
							<TextField
								placeholder='Search Skill'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => setSearchSkill(e?.target?.value)}
							/>
						</Box>
					</form>
				</ThemeProvider>
			</Box>
			<Box className={classes.tableList}>
				<List
					{...myInfoViewDetails}
					component='div'
					title={' '}
					basePath={'/myinfo'}
					bulkActionButtons={false}
					actions={false}
					resource={'employee_skill_mapping'}
					filter={{
						id:
							employeeSkill?.employee_skill_mapping?.length > 0
								? employeeSkill?.employee_skill_mapping?.map(
										(value: any) => value?.id
								  )
								: [],
					}}
				>
					<Datagrid>
						<ReferenceField
							link={false}
							source='skill_id'
							reference='skill_master'
							label='Name'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.name || '- -'}`}
											placement='right'
										>
											<Typography
												className={[classes.skillName, styles.ellipsis].join(
													' '
												)}
											>
												{`${skill?.name || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='skill_level_id'
							reference='skill_level'
							label='Level'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.level || '- -'}`}
											placement='right'
										>
											<Typography
												className={`${classes.skillName} ${styles.ellipsis}`}
											>
												{`${skill?.level || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='id'
							reference='employee_skill_mapping'
							label='IS PRIMARY'
							sortBy='is_primary'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Typography
											className={`${classes.skillName} ${styles.ellipsis}`}
										>
											{`${skill?.is_primary ? 'Yes' : 'No'}`}
										</Typography>
									);
								}}
							/>
						</ReferenceField>
					</Datagrid>
				</List>
			</Box>
		</>
	);
};

export default MyInfoSkillDetail;
