import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';

import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { useDeleteUserEmailMappingMutation } from '../../../../generated/graphql';
import {
	DELETE_CONFIRMATION_MESSAGE,
	DELETE_FAILED_MESSAGE,
	USER_DELETE_MESSAGE,
} from './constant';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	error: {
		fontSize: '14px !important',
	},
}));

interface InvitedUserDeleteModalProps {
	onClose: () => void;
	open: boolean;

	userName: string;
	refetch: () => void;
	refresh: () => void;
	deleteId?: string;
}

const UserDeleteModal = (props: InvitedUserDeleteModalProps) => {
	const {
		open,
		onClose,

		refetch,
		refresh,

		deleteId,
	} = props;
	const classes = modalFormStyle();

	const [error, setError] = useState('');
	const containerClass = useStyles();
	const notify = useNotify();
	const [deleteMapping] = useDeleteUserEmailMappingMutation();

	const deleteUserMapping = () => {
		deleteMapping({
			variables: {
				id: deleteId,
			},
		})
			.then((data) => {
				if (data) {
					refetch();
					refresh();
					notify(USER_DELETE_MESSAGE);
					setError('');
					handleClose();
				}
			})
			.catch((err) => {
				console.log(err);
				notify(DELETE_FAILED_MESSAGE);
			});
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						{DELETE_CONFIRMATION_MESSAGE}
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box display='flex' justifyContent='flex-end'>
							<Button onClick={handleClose} className={classes.cancelButton}>
								Cancel
							</Button>
							<Button
								className={classes.deleteButton}
								type='submit'
								onClick={deleteUserMapping}
							>
								Delete
							</Button>
						</Box>
						{error && (
							<Box color='red' marginTop='5px'>
								{error}
							</Box>
						)}
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default UserDeleteModal;
