import React from 'react';
import { AutocompleteArrayInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	useGetDepartmentOptionsQuery,
	useUpsertOrgTaskMappingsMutation,
} from '../../../generated/graphql';
import { difference } from 'lodash';
import { ORG_TASK_MAPPING_UPDATION_TEXT } from '../constant';

const employeeMappingFormStyle = makeStyles({
	container: {
		width: '49vw',
		padding: '20px',
		borderRadius: '4px',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '54px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
	warningMessage: {
		fontFamily: 'Manrope-medium',
		color: '#EA4335',
		marginBottom: '8px',
	},
});

interface OrgTaskMappings {
	orgTaskMappings?: {
		orgTaskMappingId: any;
		department_id: any;
	}[];
	department_id?: any[];
}

interface OrgTaskDepartmentMappingFormProps {
	open: boolean;
	onClose: () => void;
	orgTaskId: any;
	refetch: () => void;
	orgTaskMappings?: OrgTaskMappings;
}

const OrgTaskDepartmentMappingForm = (
	props: OrgTaskDepartmentMappingFormProps
) => {
	const { open, onClose, orgTaskId, refetch, orgTaskMappings } = props;
	const modalFormStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const employeeMappingFormStyles = employeeMappingFormStyle();
	const { data: departmentOptions } = useGetDepartmentOptionsQuery();

	const [upsertOrgTaskMappings,{loading:isOrgTaskMappingLoading}] = useUpsertOrgTaskMappingsMutation();

	const handleSubmit = (formValues: { department_id: any[] }) => {
		if (!formValues) {
			return;
		}
		const deletedDepartments = difference(
			orgTaskMappings?.department_id,
			formValues.department_id
		);
		upsertOrgTaskMappings({
			variables: {
				orgTaskId: orgTaskId,
				departmentIdsToDelete: deletedDepartments,
				data: formValues.department_id.map((departmentId) => ({
					id: orgTaskMappings?.orgTaskMappings?.find(
						(orgTaskMappings) => orgTaskMappings.department_id === departmentId
					)?.orgTaskMappingId,
					task_id: orgTaskId,
					department_id: departmentId,
				})),
			},
		}).then((response) => {
			if (!response.errors) {
				notify(ORG_TASK_MAPPING_UPDATION_TEXT);
				refresh();
				refetch();
				onClose();
			}
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={employeeMappingFormStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							Department Mapping
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={onClose}
						/>
					</Box>
					<Form
						initialValues={
							orgTaskMappings && orgTaskMappings?.department_id
								? {
										department_id: orgTaskMappings.department_id,
								  }
								: {}
						}
						onSubmit={handleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Select Departments
									</Typography>
									<AutocompleteArrayInput
										source='department_id'
										label=''
										choices={departmentOptions?.department.map(
											(department) => ({
												id: department.id,
												name: department.name,
											})
										)}
									/>
								</Box>
								<Box className={modalFormStyles.buttonContainer}>
									<Button
										className={
											invalid || pristine || isOrgTaskMappingLoading
												? `${modalFormStyles.disabledButton} ${employeeMappingFormStyles.saveButton}`
												: `${modalFormStyles.saveButton} ${employeeMappingFormStyles.saveButton}`
										}
										disabled={invalid || pristine || isOrgTaskMappingLoading}
										type='submit'
									>
										Save
									</Button>
									<Button
										onClick={onClose}
										className={modalFormStyles.cancelButton}
									>
										Cancel
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default OrgTaskDepartmentMappingForm;
