import React, { useEffect, useState } from 'react';
import { TextInput, maxLength, required, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPSERT_SKILL } from '../../Settings.gql';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { validateDescription, validateName } from '../../constant';

import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

interface FormValue {
	id?: string;
	name?: string;
	description?: string;
	created_at?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}
const SkillForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [isSkillUniqueViolation, setIsSkillUniqueViolation] = useState(false);
	const [isEmptyString, setIsEmptyString] = useState(false);
	const [upsertSkill, { loading: isSkillLoading }] =
		useApolloMutation(UPSERT_SKILL);
	const [initialFormValues, setInitialFormValues] = useState<FormValue>({});
	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const closeModal = () => {
		setInitialFormValues({});
		setIsSkillUniqueViolation(false);
		setIsEmptyString(false);
		onClose();
	};

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}

		const { created_at, description, name, ...restFormValues } = formValues;
		const skillName = name?.trim();
		const skillDescription = description?.trim();
		upsertSkill({
			variables: {
				skill: {
					...restFormValues,
					name: skillName,
					description: skillDescription || null,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify('Skill updated Successfully');
						refetch();
						closeModal();
					} else {
						notify('Skill Created Successfully');
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (error) {
					// notify(error?.message, 'warning');
					if (error.message.includes('skill_master_name_org_id_key')) {
						setIsSkillUniqueViolation(true);
						return;
					}
				}
			});
	};

	const validateSkillFormValues = async () => {
		const skillFormErrors = {} as FormValue;
		if (isSkillUniqueViolation) {
			skillFormErrors.name = 'Skill already exist!';
		}
		if (isEmptyString) {
			skillFormErrors.name = 'Required';
		}
		return skillFormErrors;
	};

	const skillValidate = [required(), validateName,maxLength(50, 'Characters must be below 50')];
	const descriptionValidate = [validateDescription,maxLength(150, 'Characters must be below 150')];

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{isEditMutation ? `Edit: ${initialValues?.name}` : `Add SKill`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateSkillFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name *
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={skillValidate}
										onChange={() => {
											setIsSkillUniqueViolation(false);
											setIsEmptyString(false);
										}}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										validate={descriptionValidate}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={isEditMutation ? 'Update' : 'Add'}
										isLoading={isSkillLoading}
										isDisabled={invalid || pristine || isSkillLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default SkillForm;
