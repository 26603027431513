import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
}));

interface KanbanColumn {
	id: string;
	name: string;
}

interface Crm {
	id: string;
}

interface CrmTags {
	crm_tags: Crm;
}

interface Props {
	refetch: () => void;
	opportunity_id?: string;
	crmTask: any;
	selectedCrmTask: (selectedTask: any) => void;
}

export const CrmEditPopover = (props: Props) => {
	const { crmTask, selectedCrmTask } = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem
					onClick={() => {
						selectedCrmTask({
							id: crmTask.id,
							kanbanCardId: crmTask.crm_task_kanban_cards[0]?.id,
							name: crmTask.name,
							description: crmTask.description,
							activity_type_id: crmTask.activity_type_id,
							opportunity_id: crmTask.opportunity_id,
							priority: crmTask.priority,
							status: crmTask.crm_task_kanban_cards[0]?.column_id,
							due_date: crmTask.due_date,
							owner_id: crmTask.owner_id,
							remind_at: crmTask.remind_at,
							tag_id: crmTask.crm_task_tag_mappings.map((val: CrmTags) => {
								return val?.crm_tags?.id;
							}),
						});
						closePopOver();
					}}
				>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
			</Menu>
		</div>
	);
};
