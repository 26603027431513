import React, { useState } from 'react';
import {
	Datagrid,
	FunctionField,
	List,
	useNotify,
	useRefresh,
} from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	Box,
	IconButton,
	makeStyles,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import TimesheetMasterProjectTaskForm from './TimesheetMasterProjectTaskForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import {
	TIMESHEET_PROJECT_TASK_DELETE_CONFIRMATION_TEXT,
	TIMESHEET_PROJECT_TASK_DELETION_TEXT,
	TIMESHEET_MASTER_PROJECT_DESCRIPTION,
} from '../constant';
import { useSoftDeleteMasterProjectTaskMutation } from '../../../generated/graphql';
import { settingsStyle } from '../settingsStyle';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';

const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '16px 0px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	name: {
		color: '#292929',
		fontSize: '12px !important',
		fontFamily: 'Manrope-medium',
		width: '200px',
	},
	search: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '10px',
		marginBottom: '8px',
	},
});

export const TimesheetMasterProjectTask = () => {
	const [isTaskFormShown, setIsTaskFormShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [selectedIdForDelete, setSelectedIdForDelete] = useState(null);
	const [selectedProjectTask, setSelectedProjectTask] = useState({});
	const [searchTask, setSearchTask] = useState('');
	const datagridStyles = datagridStyle();
	const timesheetProjectTaskStyles = useStyles();
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const [deleteProjectTask] = useSoftDeleteMasterProjectTaskMutation();

	const handleDeleteTask = () => {
		if (!selectedIdForDelete) {
			return;
		}
		deleteProjectTask({
			variables: {
				masterProjectTaskId: selectedIdForDelete,
			},
		})
			.then((response) => {
				if (!response.errors) {
					setIsWarningModalShown(false);
					setSelectedIdForDelete(null);
					notify(TIMESHEET_PROJECT_TASK_DELETION_TEXT);
					refresh();
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	return (
		<>
			<Typography className={settingsStyles.description}>
				{TIMESHEET_MASTER_PROJECT_DESCRIPTION}
			</Typography>
			<Box className={timesheetProjectTaskStyles.search}>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Task'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchTask(e?.target?.value);
								}}
								value={searchTask}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsTaskFormShown(true);
					}}
				>
					Add Task
				</Button>
			</Box>

			<List
				resource='master_project_task'
				basePath='/master_project_task'
				actions={false}
				sort={{ field: 'title', order: 'ASC' }}
				bulkActionButtons={false}
				perPage={5}
				filter={{ title: searchTask }}
			>
				<Datagrid rowClick={''}>
					<FunctionField
						source='title'
						label='NAME'
						render={(masterProjectTask: any) => (
							<Tooltip title={`${masterProjectTask.title}`} placement={'right'}>
								<div
									className={`${timesheetProjectTaskStyles.name} ${ellipsisStyles.ellipsis}`}
								>
									{masterProjectTask.title}
								</div>
							</Tooltip>
						)}
					/>
					<FunctionField
						source='is_billable'
						label='BILLABLE'
						render={(masterProjectTask: any) =>
							masterProjectTask.is_billable ? 'Yes' : 'No'
						}
					/>
					<FunctionField
						render={(masterProjectTask: any) => (
							<Box display='flex' alignItems='center'>
								<Box className={datagridStyles.listEditIconContainer} mr={1}>
									<IconButton
										className={datagridStyles.listEditIconButton}
										onClick={() => {
											setSelectedProjectTask(masterProjectTask);
											setIsTaskFormShown(true);
										}}
									>
										<EditIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
								<Box className={datagridStyles.listEditIconContainer}>
									<IconButton
										className={`${datagridStyles.listEditIconButton} ${timesheetProjectTaskStyles.deleteIconButton}`}
										onClick={() => {
											setSelectedIdForDelete(masterProjectTask.id);
											setIsWarningModalShown(true);
										}}
									>
										<DeleteIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
							</Box>
						)}
					/>
				</Datagrid>
			</List>
			<TimesheetMasterProjectTaskForm
				open={isTaskFormShown}
				onClose={() => {
					setIsTaskFormShown(false);
					setSelectedProjectTask({});
				}}
				initialValues={selectedProjectTask}
			/>
			<DeleteModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setSelectedIdForDelete(null);
				}}
				confirmationMessage={TIMESHEET_PROJECT_TASK_DELETE_CONFIRMATION_TEXT}
				onDelete={handleDeleteTask}
			/>
		</>
	);
};

export default TimesheetMasterProjectTask;
