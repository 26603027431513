import React from 'react';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import EnableDraftTimesheetEntries from './EnableDraftTimesheetEntries.component';
import DefaultersSettings from './TimesheetDefaultersSettings.component';
import TimesheetMasterProjectTask from './TimesheetMasterProjectTask.component';
import TimesheetOrgTask from './TimesheetOrgTask.component';
import TimesheetWeeklyEntriesSettings from './TimesheetWeeklyEntriesSettings.component';
import TrackDayTypeSettings from './TrackDayTypeSettings.component';
import AttendanceTimesheetSettings from './AttendanceTimesheetSettings.component';

export const TimsheetSettings = () => {
	return (
		<>
			<SettingsDetailContainer heading=''>
				<EnableDraftTimesheetEntries />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading=''>
				<TimesheetWeeklyEntriesSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Track day type'>
				<TrackDayTypeSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Timesheet Regularization'>
				<AttendanceTimesheetSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Project Tasks'>
				<TimesheetMasterProjectTask />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Organisation Tasks'>
				<TimesheetOrgTask />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Timesheet Defaulter Criteria '>
				<DefaultersSettings />
			</SettingsDetailContainer>
		</>
	);
};

export default TimsheetSettings;
