export const DEFAULT_EMPTY_TIME_SHEET_TEXT =
	'No entries are available! You can add new entry.';

export const DEFAULT_TIME_SHEET_COPY_TEXT =
	'Copy all data from the most recent timesheet';

export const TIME_SHEET_ADD_NOTIFICATION_TEXT =
	'Timesheet Entered Successfully...';

export const TIME_SHEET_UPDATE_NOTIFICATION_TEXT =
	'Timesheet Updated Successfully...';

export const TIME_SHEET_DELETE_NOTIFICATION_TEXT =
	'Timesheet Entry Deleted Successfully...';

export const TIME_SHEET_SUBMISSION_CONFIRMATION_TEXT =
	'Ready to submit this timesheet for approval?';

export const TIME_SHEET_SUBMISSION_SUCCESS_TEXT =
	'Your timesheet has been successfully submitted!';

export const TIME_SHEET_APPROVAL_CONFIRMATION_TEXT =
	'Ready to approve this timesheet?';

export const TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT =
	'Do you really want to revert this approved timesheet?';

export const TIME_SHEET_APPROVAL_SUCCESS_TEXT =
	'Timesheet has been successfully approved!';

export const TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT =
	'Timesheet has been successfully moved to Draft!';

export const TIME_SHEET_REJECT_SUCCESS_TEXT =
	'Your timesheet has been successfully rejected!';

export const TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL = 'Yes! Approve';

export const TIME_SHEET_REJECT_CONFIRMATION_TEXT =
	'Do you really want to reject this timesheet?';

export const TIME_SHEET_REJECT_MODAL_BUTTON_LABEL = 'Reject';

export const TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL = 'Move to Draft';

export const TIME_SHEET_DELETE_CONFIRMATION_TEXT =
	'Are you sure want to delete this time sheet entry?. This action cannot be undone.';

export const WEEKLY_TIME_SHEET_CHANGES_SAVED_TEXT =
	'Changes has been saved successfully';

export const PROJECT_TYPE_CATEGORY = 'project';

export const MASTER_PROJECT_TASK_TYPE = 'master_project_task';

export const ORG_TASK_TYPE = 'org_task';

export const PROJECT_TASK_GROUP_LABEL = 'Tasks';

export const MASTER_PROJECT_GROUP_LABEL = 'Master Project Tasks';

export const ORG_TASK_GROUP_LABEL = 'Organisation Tasks';

export const TIMESHEET_SUBMITTED_STATUS = 'submitted';

export const TIMESHEET_APPROVED_STATUS = 'approved';

// REGEX
export const checkIfOnlyNumbers = /^[0-9]+$/;

export const checkIfOnlyOneColonAfterNumbers = /^([0-9]+):{1}$/; // 11: or 23:

export const checkIfOnlyOneNumberAfterColon = /^([0-9]+):{1}([0-9]{1})$/; // 11:1 or 23:1

export const validHHMMFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; //HH:MM

export const checkIfOnlyZeroesWithOneColon = /^(0+):{1}(0+)$/;

export const checkIfOnlyOneColon = /:{1}/;

export const START_DATE_ERROR =
	'Start date should not be greater than end date';

export const timesheetApprovedConstraintError =
	'insert/update permission has failed';

export const TIME_SHEET_CONTAINER = {
	timesheet: 'timesheet',
	overtime: 'overtime',
	insights: 'insights',
};
