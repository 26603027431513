import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Button,
	Tooltip,
	Card,
	Typography,
	FormControl,
	Checkbox,
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { Form } from 'react-final-form';
import {
	GET_CRM_TASK_STATUS,
	GET_CRM_TASKS,
	UPDATE_TASK_STATUS,
} from '../Opportunities.gql';
import { ProjectStatus } from './modal';
import CrmTaskForm from './CrmTaskForm.component';
import dayjs from 'dayjs';
import { ellipsisStyle } from '../../../Layout/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CrmEditPopover } from './CrmEditPopover.component';
import { DateIcon } from '../../../assets/SvgIcons';
import { UserProfileContext } from '../../../App';
const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		textTransform: 'none',
		minWidth: '111px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	taskName: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		marginTop: '5px',
		width: '400px',
		'& > p': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
	completedTaskName: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		textDecoration: 'line-through',
		marginTop: '5px',
		width: '400px',
		'& > p': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
	container: {
		height: '440px',
		overflowY: 'scroll',
		width: '100%',
		overflowX: 'hidden',
	},
	highPriorityCard: {
		height: '80px',
		marginBottom: '10px',
		border: '1px solid #ECF1F4',
		marginTop: '10px',
		marginRight: '10px',
		borderLeft: '3px solid red',
	},
	card: {
		height: '80px',
		marginBottom: '10px',
		border: '1px solid #ECF1F4',
		marginTop: '10px',
		marginRight: '10px',
	},
	assignBox: {
		fontFamily: 'Manrope',
		fontSize: '12px',
		marginTop: '15px',
		marginLeft: '15px',
		width: '400px',
	},
	dueDate: {
		fontFamily: 'Manrope-semiBold',
		fontSize: '12px',
		marginTop: '15px',
		marginLeft: '15px',
		display: 'flex',
	},
	description: {
		fontFamily: 'Manrope',
		fontSize: '12px',
	},
	label: {
		fontFamily: 'Manrope-semiBold',
		fontSize: '15px',
	},
	activeCheckbox: {
		color: 'green',
	},
	checkbox: {
		color: '#888888',
	},
	dateIcon: {
		marginTop: '2px',
		marginRight: '5px',
	},
	editContainer: {
		width: '30px',
		display: 'flex',
		justifyContent: 'center',
	},
});

interface Value {
	id: string;
	state: string;
}

interface CrmTaskActivityType {
	label: string;
}

interface KanbanColumn {
	state: string;
}

interface CrmTaskKanbanCard {
	kanban_column: KanbanColumn;
}

interface User {
	profile_pic: string;
	first_name: string;
	last_name: string;
	full_name: string;
}

interface CrmTask {
	activity_type_id: string;
	description: string;
	due_date: string;
	id: string;
	name: string;
	owner_id: string;
	is_done: boolean;
	priority: string;
	user: User;
	crm_task_activity_type: CrmTaskActivityType;
	crm_task_kanban_cards: CrmTaskKanbanCard[];
}

interface Props {
	boardId: string;
}

enum TaskType {
	PHONE = 'Phone Call',
	MEETING = 'Meeting',
	VIDEO = 'Video Conference',
	OTHER = 'Task',
	TODO = 'Task',
}
const OpportunityDetailsTaskTab = (props: Props) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const style = useStyles();
	const notify = useNotify();
	const ellipsisStyles = ellipsisStyle();
	const { id: opportunityId }: { id: string } = useParams();
	const [kanbanColumn, setKanbanColumn] = useState([]);
	const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
	const [status, setStatus] = useState('');
	const [kanbanColumnId, setKanbanColumnId] = useState('');
	const [selectedCrmTask, setSelectedCrmTask] = useState({});
	const { data: crmTaskStatusOptions } = useApolloQuery(GET_CRM_TASK_STATUS);
	const [updateCrmTask] = useApolloMutation(UPDATE_TASK_STATUS);
	const { data: completedTaskList, refetch: refetchCompletedTaskList } =
		useApolloQuery(GET_CRM_TASKS, {
			variables: {
				opportunity_id: opportunityId,
				is_done: true,
			},
			fetchPolicy: 'network-only',
		});
	const { data: upComingTaskList, refetch: refetchUpcomingTaskList } =
		useApolloQuery(GET_CRM_TASKS, {
			variables: {
				opportunity_id: opportunityId,
				is_done: false,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		const kanbanData = crmTaskStatusOptions?.crm_task_activity_type.map(
			(value: ProjectStatus) => {
				if (value?.value === 'todo') {
					setStatus(value?.id);
					setKanbanColumnId(value?.id);
				}
				return { id: value?.id, name: value?.label };
			}
		);
		setKanbanColumn(kanbanData);
	}, [crmTaskStatusOptions]);
	const returnTaskType = (task: string) => {
		switch (task) {
			case 'PHONE': {
				return TaskType.PHONE;
			}
			case 'MEETING': {
				return TaskType.MEETING;
			}
			case 'VIDEO CONFERENCE': {
				return TaskType.VIDEO;
			}
			case 'OTHER': {
				return TaskType.OTHER;
			}
			case 'TODO': {
				return TaskType.TODO;
			}
		}
	};
	const refetchCrmTaskList = () => {
		refetchUpcomingTaskList();
		refetchCompletedTaskList();
	};
	const updateTaskStatus = (task_id: string, status: boolean) => {
		updateCrmTask({
			variables: {
				taskId: task_id,
				crmTask: {
					is_done: !status,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					notify('Tasks updated successfully');
					refetchCrmTaskList();
				}
			})
			.catch((error: any) => {
				if (error) {
					return;
				}
			});
	};

	const UpcomingTasks = () => (
		<Box>
			<Typography className={style.label}>Upcoming</Typography>
			{upComingTaskList?.crm_tasks?.length > 0 ? (
				upComingTaskList?.crm_tasks?.map((value: CrmTask, index: number) => {
					return (
						<Box key={value?.id}>
							<Card
								key={value?.id}
								className={
									((dayjs(value?.due_date).isBefore(dayjs()) ||
										dayjs(value?.due_date).isSame(dayjs())) &&
										value?.is_done === false) === true
										? style.highPriorityCard
										: style.card
								}
							>
								<Box display='flex' justifyContent='space-between'>
									<Box>
										{value?.user ? (
											<Tooltip
												title={
													<span>
														<b>{`${returnTaskType(
															value?.crm_task_activity_type?.label
														)} `}</b>
														<span> assigned to</span>
														<span>{` ${value?.user?.full_name || '-'} `}</span>
													</span>
												}
											>
												<Typography
													className={[
														style.assignBox,
														ellipsisStyles.ellipsis,
													].join(' ')}
												>
													<b>{`${returnTaskType(
														value?.crm_task_activity_type?.label
													)} `}</b>
													<span> assigned to</span>
													<span>{` ${value?.user?.full_name || '-'} `}</span>
												</Typography>
											</Tooltip>
										) : (
											<Typography className={style.assignBox}>
												Unassigned
											</Typography>
										)}
									</Box>
									<Box>
										<Typography component='span' className={style.dueDate}>
											{value?.due_date ? (
												<>
													<Box className={style.dateIcon}>
														<DateIcon />
													</Box>
													<span style={{ color: '#79caed' }}>
														Due:
														{dayjs(value?.due_date).format(
															'MMM DD [at]  hh:mm:A'
														)}
													</span>
												</>
											) : (
												'No due date'
											)}
											<Box
												className={style.editContainer}
												id={`#edit_opp_upcoming_task_${index}`}
											>
												{permissions?.crm_tasks?.update_permissions && (
													<CrmEditPopover
														crmTask={value}
														refetch={() => refetchCrmTaskList()}
														opportunity_id={opportunityId}
														selectedCrmTask={(selectedTask: any) => {
															setSelectedCrmTask(selectedTask);
															setIsAddTaskModalOpen(true);
														}}
													/>
												)}
											</Box>
										</Typography>
									</Box>
								</Box>
								<Box marginLeft='17px'>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													checked={value?.is_done}
													onChange={() =>
														permissions?.crm_tasks?.update_permissions &&
														updateTaskStatus(value?.id, value?.is_done)
													}
													icon={
														value?.is_done ? (
															<CheckCircleOutlineIcon
																className={style.activeCheckbox}
															/>
														) : (
															<CheckCircleOutlineIcon
																className={style.checkbox}
															/>
														)
													}
													checkedIcon={
														<CheckCircleOutlineIcon
															className={style.activeCheckbox}
														/>
													}
												/>
											}
											label={
												<Typography
													component='span'
													className={style.description}
												>
													<Tooltip
														title={
															<Box
																dangerouslySetInnerHTML={{
																	__html: value?.name,
																}}
															/>
														}
														placement='right'
													>
														<Box
															className={
																value?.is_done
																	? [
																			style.completedTaskName,
																			ellipsisStyles.ellipsis,
																	  ].join(' ')
																	: [
																			style.taskName,
																			ellipsisStyles.ellipsis,
																	  ].join(' ')
															}
															dangerouslySetInnerHTML={{
																__html: value?.name,
															}}
														/>
													</Tooltip>
												</Typography>
											}
										/>
									</FormControl>
								</Box>
							</Card>
						</Box>
					);
				})
			) : (
				<Typography className={style.description}>
					No Upcoming tasks found
				</Typography>
			)}
		</Box>
	);

	const CompletedTasks = () => (
		<Box>
			<Box marginTop='30px' className={style.label}>
				Completed
			</Box>
			{completedTaskList?.crm_tasks?.length > 0 ? (
				completedTaskList?.crm_tasks?.map((value: CrmTask, index: number) => {
					return (
						<Box key={value?.id}>
							<Card
								key={value?.id}
								className={
									((dayjs(value?.due_date).isBefore(dayjs()) ||
										dayjs(value?.due_date).isSame(dayjs())) &&
										value?.is_done === false) === true
										? style.highPriorityCard
										: style.card
								}
							>
								<Box display='flex' justifyContent='space-between'>
									<Box>
										{value?.user ? (
											<Tooltip
												title={
													<span>
														<b>{`${returnTaskType(
															value?.crm_task_activity_type?.label
														)} `}</b>
														<span> assigned to</span>
														<span>{` ${value?.user?.full_name || '-'} `}</span>
													</span>
												}
											>
												<Typography
													className={[
														style.assignBox,
														ellipsisStyles.ellipsis,
													].join(' ')}
												>
													<b>{`${returnTaskType(
														value?.crm_task_activity_type?.label
													)} `}</b>
													<span> assigned to</span>
													<span>{` ${value?.user?.full_name || '-'} `}</span>
												</Typography>
											</Tooltip>
										) : (
											<Typography className={style.assignBox}>
												Unassigned
											</Typography>
										)}
									</Box>
									<Box>
										<Typography component='span' className={style.dueDate}>
											{value?.due_date ? (
												<>
													<Box className={style.dateIcon}>
														<DateIcon />
													</Box>
													<span style={{ color: '#79caed' }}>
														Due:
														{dayjs(value?.due_date).format(
															`MMM DD [at] hh:mm:A`
														)}
													</span>
												</>
											) : (
												'No due date'
											)}
											<Box
												className={style.editContainer}
												id={`#edit_opp_completed_task_${index}`}
											>
												{permissions?.crm_tasks?.update_permissions && (
													<CrmEditPopover
														crmTask={value}
														refetch={() => refetchCrmTaskList()}
														opportunity_id={opportunityId}
														selectedCrmTask={(selectedTask: any) => {
															setSelectedCrmTask(selectedTask);
															setIsAddTaskModalOpen(true);
														}}
													/>
												)}
											</Box>
										</Typography>
									</Box>
								</Box>
								<Box marginLeft='17px'>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													checked={value?.is_done}
													onChange={() =>
														permissions?.crm_tasks?.update_permissions &&
														updateTaskStatus(value?.id, value?.is_done)
													}
													icon={
														value?.is_done ? (
															<CheckCircleOutlineIcon
																className={style.activeCheckbox}
															/>
														) : (
															<CheckCircleOutlineIcon
																className={style.checkbox}
															/>
														)
													}
													checkedIcon={
														<CheckCircleOutlineIcon
															className={style.activeCheckbox}
														/>
													}
												/>
											}
											label={
												<Typography
													component='span'
													className={style.description}
												>
													<Tooltip
														title={
															<Box
																dangerouslySetInnerHTML={{
																	__html: value?.name,
																}}
															/>
														}
														placement='right'
													>
														<Box
															className={
																value?.is_done
																	? [
																			style.completedTaskName,
																			ellipsisStyles.ellipsis,
																	  ].join(' ')
																	: [
																			style.taskName,
																			ellipsisStyles.ellipsis,
																	  ].join(' ')
															}
															dangerouslySetInnerHTML={{
																__html: value?.name,
															}}
														/>
													</Tooltip>
												</Typography>
											}
										/>
									</FormControl>
								</Box>
							</Card>
						</Box>
					);
				})
			) : (
				<Typography className={style.description}>
					No Completed tasks found
				</Typography>
			)}
		</Box>
	);

	return (
		<Box>
			<Form initialValues={{ status }} onSubmit={() => {}}>
				{({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit}>
						<Box className={style.toolbar}>
							<Box></Box>
							<Box>
								{permissions?.crm_tasks?.insert_permissions && (
									<Button
										className={style.button}
										variant='contained'
										color='primary'
										startIcon={<AddIcon />}
										onClick={() => setIsAddTaskModalOpen(true)}
									>
										Add Task
									</Button>
								)}
							</Box>
						</Box>
					</form>
				)}
			</Form>
			<Box className={style.container}>
				<Box>
					<UpcomingTasks />
					<CompletedTasks />
				</Box>
			</Box>

			<CrmTaskForm
				CrmTask={selectedCrmTask || {}}
				open={isAddTaskModalOpen}
				onClose={() => {
					setIsAddTaskModalOpen(false);
					setSelectedCrmTask({});
				}}
				kanbanColumn={kanbanColumn}
				refetch={refetchCrmTaskList}
				opportunity_id={opportunityId}
				kanbanId={kanbanColumnId}
				boardId={props.boardId}
			/>
		</Box>
	);
};

export default OpportunityDetailsTaskTab;
