import React, { useState, useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	Button,
	Checkbox,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { DropdownOption } from '../../SharedComponents/model';
import { Loading } from 'ra-ui-materialui';

import { Style } from './Style';
import { ellipsisStyle } from '../../Layout/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import { SelectInput } from 'react-admin';
import dayjs from 'dayjs';
import {
	useGetAttendanceStatusQuery,
	useGetAttendanceRequestForApprovalQuery,
	useApproveAttendaceRequestMutation,
	useDeleteAttendanceRequestMutation,
} from '../../generated/graphql';
import { chain, head, isEmpty, orderBy } from 'lodash';
import ApproveRejectModal from './ApproveRejectModal.component';
import { APPROVE_LEAVE_MESSAGE, REJECT_LEAVE_MESSAGE } from './Constant';
import no_data_found from '../../assets/no_data_found.png';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const inlineStyle = makeStyles({
	dropdown: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	container: {
		marginTop: '20px',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'flex',
			},
			'& $deleteIconContainer': {
				display: 'flex',
			},
		},
	},
	listEditIconContainer: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconContainer: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	saveButton: {
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
});
interface Props {
	userId: string;
	refetch: () => void;
}
const AttendanceRequestPendingApproval = (props: Props) => {
	const { userId, refetch } = props;
	const style = inlineStyle();
	const [leaveStatusDropDown, setLeaveStatusDropDown] =
		useState<DropdownOption[]>();
	const [leaveStatusId, setLeaveStatusId] = useState('');
	const [pendingRequestList, setPendingRequestList] = useState<any[]>();
	const [leaveSubmittedId, setLeaveSubmittedId] = useState<string[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<any>([]);
	const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] =
		useState(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const notify = useNotify();
	const styles = Style();
	const ellipsisStyles = ellipsisStyle();

	const { data: attendanceStatus } = useGetAttendanceStatusQuery();

	const {
		data: userAttendanceList,
		refetch: refetchAttendanceList,
		loading: isAttendanceRequestListLoading,
	} = useGetAttendanceRequestForApprovalQuery({
		variables: {
			where:
				leaveStatusId === 'All'
					? {
							approved_by: { _eq: userId },
					  }
					: {
							approved_by: { _eq: userId },
							attendance_status_id: { _eq: leaveStatusId },
					  },
		},
		fetchPolicy: 'network-only',
	});
	const [approveAttendanceRequest] = useApproveAttendaceRequestMutation();
	const [rejectAttendanceRequest] = useDeleteAttendanceRequestMutation();

	useEffect(() => {
		if (isEmpty(attendanceStatus?.attendance_status)) {
			return;
		}
		const statusOption = attendanceStatus?.attendance_status
			.filter((element) => element?.value !== 'draft')
			.map((value) => {
				if (value?.value === 'submitted') {
					setLeaveStatusId(value?.id);
				}
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});
		const sortedStatusOption = orderBy(statusOption, ['name'], ['asc']);
		setLeaveStatusDropDown(sortedStatusOption);
	}, [attendanceStatus]);

	useEffect(() => {
		if (isEmpty(userAttendanceList?.user_attendance_request)) {
			setPendingRequestList([]);
			return;
		}
		const userAttendanceRequestList = chain(
			userAttendanceList?.user_attendance_request
		)
			?.groupBy((user) => user?.submittedBy?.id)
			.map((user) => {
				const sortedAttendance = orderBy(
					user,
					[(attendance) => attendance?.date],
					['desc']
				);
				return {
					user: head(user)?.submittedBy,
					attendanceList: sortedAttendance,
				};
			})
			.value();
		setPendingRequestList(userAttendanceRequestList);
	}, [userAttendanceList]);

	const handleReject = async () => {
		const deleteAttendanceIdList =
			userAttendanceList?.user_attendance_request
				?.filter((attendance) => leaveSubmittedId?.includes(attendance?.id))
				.map((attendance) => attendance?.id) || [];

		const rejectAttendance = await rejectAttendanceRequest({
			variables: {
				attendanceIdList: deleteAttendanceIdList,
			},
		}).catch((error) => {
			if (error) {
				notify('Attendance rejection failed', 'warning');
				setIsApproveModalOpen(false);
				setIsApproveRejectModalOpen(false);
			}
		});
		if (rejectAttendance) {
			setLeaveSubmittedId([]);
			setSelectedUserId([]);
			refetch();
			refetchAttendanceList();
			setIsApproveRejectModalOpen(false);
			notify('Attendance rejected successfully');
			return;
		}
	};

	const handleApprove = async () => {
		const attendanceApprovedStatusIdPayload =
			attendanceStatus?.attendance_status?.find(
				(attendance) => attendance?.value === 'approved'
			)?.id || null;
		const attendanceData =
			userAttendanceList?.user_attendance_request?.filter((attendance) =>
				leaveSubmittedId?.includes(attendance?.id)
			) || [];
		const userIdListPayload =
			attendanceData?.map((attendance) => attendance?.submittedBy?.id) || [];
		const dateListPayload =
			attendanceData?.map((attendance) => attendance?.date) || [];
		const attendanceRequestIdListPayload =
			attendanceData?.map((attendance) => attendance?.id) || [];
		const attendancePayload = attendanceData?.map((attendance) => {
			return {
				date: attendance?.date,
				in_time: attendance?.in_time,
				out_time: attendance?.out_time,
				user_id: attendance?.submittedBy?.id,
			};
		});
		const leaveApproval = await approveAttendanceRequest({
			variables: {
				attendanceData: attendancePayload,
				userIdList: userIdListPayload,
				dateList: dateListPayload,
				attendaceStatusId: attendanceApprovedStatusIdPayload,
				attendanceRequestIdList: attendanceRequestIdListPayload,
			},
		}).catch((error) => {
			if (error) {
				notify('Attendance approval failed', 'warning');
				setIsApproveModalOpen(false);
				setIsApproveRejectModalOpen(false);
			}
		});
		if (leaveApproval) {
			setLeaveSubmittedId([]);
			setSelectedUserId([]);
			refetch();
			refetchAttendanceList();
			setIsApproveModalOpen(false);
			setIsApproveRejectModalOpen(false);
			notify('Attendance approved successfully');
			return;
		}
	};

	const formatTime = (date: string, time: string) => {
		if (!date || !time) {
			return '--';
		}
		return dayjs(`${date} ${time}`).format('HH:mm');
	};

	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Box className={style.dropdown}>
					<Form
						onSubmit={() => {}}
						initialValues={{ leaveStatus: leaveStatusId }}
					>
						{() => (
							<ThemeProvider theme={modalFormTheme}>
								<form>
									<SelectInput
										source={'leaveStatus'}
										label={''}
										choices={
											leaveStatusDropDown && leaveStatusDropDown?.length > 0
												? [
														{
															id: 'All',
															name: 'All',
														},
														...leaveStatusDropDown,
												  ]
												: []
										}
										onChange={(event) => {
											setLeaveStatusId(event?.target?.value);
											setLeaveSubmittedId([]);
											setSelectedUserId([]);
										}}
									/>
								</form>
							</ThemeProvider>
						)}
					</Form>
				</Box>

				<Box display={'flex'} justifyContent='flex-end'>
					<Button
						variant='contained'
						className={styles?.rejectButton}
						disabled={leaveSubmittedId?.length === 0}
						onClick={() => {
							setIsApproveRejectModalOpen(true);
						}}
					>
						Reject
					</Button>
					<Box marginLeft='20px'>
						<Button
							variant='contained'
							className={style.saveButton}
							disabled={leaveSubmittedId?.length === 0}
							onClick={() => {
								setIsApproveModalOpen(true);
								setIsApproveRejectModalOpen(true);
							}}
						>
							Approve
						</Button>
					</Box>
				</Box>
			</Box>
			{isAttendanceRequestListLoading ? (
				<Box>
					<Loading />
				</Box>
			) : (
				<Box marginTop={'15px'}>
					{pendingRequestList && pendingRequestList?.length > 0 ? (
						<div>
							<div>
								{pendingRequestList?.map((user) => {
									return (
										<div
											className={styles.leavesContainer}
											key={user?.user?.id}
										>
											<div className={styles.avatarContainer}>
												<Avatar
													className={styles.avatar}
													src={`${user?.user?.profile_pic || ''}`}
												>
													{user?.user?.full_name?.charAt(0).toUpperCase() || ''}
												</Avatar>
												<Typography className={styles.employeeName}>
													{`${user?.user?.full_name || ''}`}
												</Typography>
											</div>
											<Table>
												<TableHead>
													<TableRow>
														<CustomTableCell
															className={styles.dataActionContainer}
														>
															<Checkbox
																className={styles.checkBox}
																name='checkedB'
																color='primary'
																checked={selectedUserId?.includes(
																	user?.user?.id
																)}
																onChange={(event: any) => {
																	if (event?.currentTarget?.checked) {
																		setLeaveSubmittedId([]);
																		setSelectedUserId([]);
																		const record = user?.attendanceList
																			?.filter(
																				(value: any) =>
																					value?.attendanceStatus?.value ===
																						'submitted' &&
																					value?.submittedBy?.id ===
																						user?.user?.id
																			)
																			.map((id: any) => id?.id);
																		setLeaveSubmittedId(record);
																		setSelectedUserId(user?.user?.id);
																	} else {
																		setLeaveSubmittedId([]);
																		setSelectedUserId([]);
																	}
																}}
															/>
														</CustomTableCell>

														<CustomTableCell>Date</CustomTableCell>
														<CustomTableCell>Status</CustomTableCell>
														<CustomTableCell>Check in</CustomTableCell>
														<CustomTableCell>Check out</CustomTableCell>
														<CustomTableCell>Reason</CustomTableCell>
														<CustomTableCell></CustomTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{user?.attendanceList?.length > 0 &&
														user?.attendanceList?.map((attendance: any) => {
															return (
																<TableRow
																	hover={true}
																	key={attendance?.id}
																	className={style.container}
																>
																	<CustomTableCell
																		className={styles.dataActionContainer}
																	>
																		{attendance?.attendanceStatus?.value ===
																		'approved' ? (
																			<CheckCircleIcon
																				className={styles.approvedIcon}
																			/>
																		) : (
																			<Checkbox
																				className={styles.checkBox}
																				name='checkedB'
																				color='primary'
																				disabled={
																					attendance?.attendanceStatus
																						?.value === 'rejected'
																						? true
																						: false
																				}
																				checked={leaveSubmittedId?.includes(
																					attendance?.id
																				)}
																				onChange={(event: any) => {
																					const record = [
																						...leaveSubmittedId,
																						attendance?.id,
																					];
																					if (event?.currentTarget.checked) {
																						const records = user?.attendanceList
																							?.filter(
																								(value: any) =>
																									value.attendanceStatus
																										?.value === 'submitted'
																							)
																							.map((id: any) => id?.id);
																						setLeaveSubmittedId([
																							...leaveSubmittedId,
																							attendance?.id,
																						]);
																						if (
																							records?.length === record?.length
																						) {
																							setSelectedUserId(user?.user?.id);
																						}
																					} else {
																						setSelectedUserId([]);
																						setLeaveSubmittedId(
																							leaveSubmittedId?.filter(
																								(id: any) =>
																									id !== attendance?.id
																							)
																						);
																					}
																				}}
																			/>
																		)}
																	</CustomTableCell>

																	<CustomTableCell>
																		<div className={styles.dateContainer}>
																			<DateRangeIcon
																				className={styles.calendarIcon}
																			/>
																			{attendance?.date
																				? `${dayjs(attendance?.date).format(
																						'DD MMM YYYY'
																				  )}`
																				: '--'}
																		</div>
																	</CustomTableCell>
																	<CustomTableCell>
																		<div
																			className={`${styles.statusContainer} ${
																				attendance?.attendanceStatus?.value ===
																				'submitted'
																					? styles.submittedStatus
																					: attendance?.attendanceStatus
																							?.value === 'approved'
																					? styles.approvedStatus
																					: styles?.rejectedStatus
																			}`}
																		>
																			{attendance?.attendanceStatus?.label
																				? attendance?.attendanceStatus
																						?.label === 'Submitted'
																					? 'Pending'
																					: attendance?.attendanceStatus?.label
																				: '--'}
																		</div>
																	</CustomTableCell>
																	<CustomTableCell>
																		{formatTime(
																			attendance?.date,
																			attendance?.in_time
																		)}
																	</CustomTableCell>
																	<CustomTableCell>
																		{formatTime(
																			attendance?.date,
																			attendance?.out_time
																		)}
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={`${attendance?.reason || '- -'}`}
																			placement='right'
																		>
																			<Box
																				className={`${styles.reason} ${ellipsisStyles.ellipsis}`}
																			>
																				{attendance?.reason || '--'}
																			</Box>
																		</Tooltip>
																	</CustomTableCell>
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</div>
									);
								})}
							</div>
						</div>
					) : (
						<Box textAlign='center'>
							<img src={no_data_found} alt='no_data_found' />
						</Box>
					)}
				</Box>
			)}
			<ApproveRejectModal
				open={isApproveRejectModalOpen}
				onClose={() => {
					setIsApproveRejectModalOpen(false);
					setIsApproveModalOpen(false);
					setIsApproveModalOpen(false);
				}}
				submit={isApproveModalOpen ? handleApprove : handleReject}
				leaveSubmittedId={leaveSubmittedId}
				message={
					isApproveModalOpen ? APPROVE_LEAVE_MESSAGE : REJECT_LEAVE_MESSAGE
				}
				loader={false}
				isApproveModalOpen={isApproveModalOpen || false}
			/>
		</>
	);
};

export default AttendanceRequestPendingApproval;
