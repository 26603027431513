import React, { useState, useEffect, useContext } from 'react';
import { AvatarField } from '../../../SharedComponents/AvatarField.component';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Logo from '../../../assets/dummyLogo.png';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CompanyBasicDetailsForm from './CompanyBasicDetailsForm.component';
import { FunctionField } from 'react-admin';
import { Tooltip } from '@material-ui/core/';
import { WebSiteIcon } from '../../../assets/SvgIcons';
import { ellipsisStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '246px',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatarContainer: {
		width: '246px',
		height: '91px',
		backgroundColor: '#4285F4',
		borderRadius: '4px 4px 0px 0px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '60px',
	},
	avatarField: {
		width: '104px',
		height: '104px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100px',
		marginTop: '85px',
		position: 'relative',
		left: '16px',
	},
	identityContainer: {
		width: '150px',
		minHeight: '80px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '15px',
	},
	nameField: {
		width: '160px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		display: 'block',
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
		cursor: 'pointer',
	},
	secondaryInfoField: {
		font: 'normal normal 600 12px/20px Manrope !important',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	tertiaryInfoField: {
		fontFamily: 'Manrope-Medium',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	additionalInfoField: {
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	addtionalInfoContainer: {
		width: '85%',
		minHeight: '200px',
	},
	additionalContent: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
	},
	additionalInfoValue: {
		font: 'normal normal 600 12px/40px Manrope !important',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
	},
	additionalInfoLabel: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
	},
	infoBar: {
		width: '80px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		textAlign: 'left',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '20px',
		height: '20px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editButtonContainer: {
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '85px',
		position: 'relative',
		left: '33px',
	},
	avatar: {
		width: ' 55px',
		height: '55px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: '1',
		alignItems: 'center',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
	},
	dot: {
		height: '7px',
		width: ' 7px',
		backgroundColor: '#34A853',
		marginRight: '8px',
		borderRadius: '50%',
		display: ' inline-block',
	},
	avatar1: {},
	iconContainer: {
		display: 'inline',
		marginTop: '10px',
	},
	linkedIcon: {
		color: '#0077B5',
		width: '15px',
		height: '15px',
		cursor: 'pointer',
		marginRight: '5px',
	},
	addressInfo: {
		textAlign: 'right',
		width: '70px',
		display: 'block',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		cursor: 'pointer',
		fontFamily: 'Manrope-semiBold',
		marginTop: '10px',
	},
	accountOwnerValue: {
		font: 'normal normal 600 12px/40px Manrope !important',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		width: '110px',
	},
}));
interface Owner {
	__typename?: string;
	first_name?: string;
	last_name?: string;
	full_name?: string;
	id?: string;
}
interface Country {
	__typename?: string;
	id?: string;
	name?: string;
}
interface Record {
	__typename?: string;
	address?: string;
	company_category?: Category;
	company_sector?: Sector;
	phone?: string;
	company_size?: Size;
	company_contact_type?: Contact;
	name?: string;
	linkedin?: string;
	logo?: any;
	id?: string;
	website?: string;
	country?: Country;
	owner?: Owner;
}
interface Sector {
	id?: string;
	label?: string;
}
interface Size {
	id?: string;
	label?: string;
}
interface Category {
	id?: string;
	label?: string;
}
interface Contact {
	id?: string;
	label?: string;
}
interface Props {
	record?: any;
	refetchCompany: () => void;
}

export const CompanyDetailsCard = (props: Props) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const { record: companyDetails, refetchCompany } = props;
	const [companyFormData, setCompanyFormData] = useState<any>({});
	const classes = useStyles();
	const [isFormShown, setIsFormShown] = useState(false);
	const styles = ellipsisStyle();

	useEffect(() => {
		setCompanyFormData({
			id: companyDetails?.id,
			name: companyDetails?.name,
			address: companyDetails?.address,
			category_id: companyDetails?.company_category?.id,
			owner_id: companyDetails?.owner?.id,
			sector: companyDetails?.company_sector?.id,
			size: companyDetails?.company_size?.id,
			country_id: companyDetails?.country?.id,
			website: companyDetails?.website,
			linkedin: companyDetails?.linkedin,
			phone: companyDetails?.phone,
			contact_type: companyDetails?.company_contact_type?.id,
		});
	}, [companyDetails]);

	const handleCompanyDetailsLink = (url: string) => {
		if (url.startsWith('http')) {
			return `${url}`;
		} else {
			return `//${url}`;
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.avatarContainer}>
				<div className={classes.avatarField}>
					<AvatarField
						className={classes.avatar1}
						imageSrc={Logo}
						firstName={''}
						lastName={''}
						size='80'
					/>
				</div>
				<div className={classes.editButtonContainer}>
					{permissions?.crm_company?.update_permissions && (
						<div
							className={classes.editButton}
							onClick={() => setIsFormShown(true)}
						>
							<EditIcon className={classes.editIcon} />
						</div>
					)}
				</div>
			</div>

			<div className={classes.identityContainer}>
				<div className={classes.nameField}>
					<Tooltip title={`${companyDetails?.name}`} placement='right'>
						<Typography
							className={[classes.nameField, styles.ellipsis].join(' ')}
						>
							{companyDetails?.name}
						</Typography>
					</Tooltip>
				</div>
				<div className={classes.secondaryInfoField}>
					{companyDetails?.company_category?.label}
				</div>
				<div className={classes.iconContainer}>
					{companyDetails?.website ? (
						<a
							href={handleCompanyDetailsLink(companyDetails?.website)}
							target='_blank'
							rel='noopener noreferrer'
						>
							<WebSiteIcon className={classes.linkedIcon} />
						</a>
					) : (
						''
					)}
					{companyDetails?.linkedin ? (
						<a
							href={handleCompanyDetailsLink(companyDetails?.linkedin)}
							target='_blank'
							rel='noopener noreferrer'
						>
							<LinkedInIcon className={classes.linkedIcon} />
						</a>
					) : (
						''
					)}
				</div>
			</div>

			<div className={classes.addtionalInfoContainer}>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Address
					</Typography>
					{companyDetails?.address ? (
						<Tooltip title={`${companyDetails?.address}`} placement='right'>
							<Typography
								className={[classes.addressInfo, styles.ellipsis].join(' ')}
							>
								{companyDetails?.address}
							</Typography>
						</Tooltip>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Sector
					</Typography>
					{companyDetails?.company_sector ? (
						<div className={classes.additionalInfoValue}>{`${
							companyDetails?.company_sector?.label || '- -'
						}`}</div>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>Size</Typography>
					{companyDetails?.company_size ? (
						<div className={classes.additionalInfoValue}>{`${
							companyDetails?.company_size.label || '- -'
						}`}</div>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>

				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Account Owner
					</Typography>

					{companyDetails?.owner ? (
						<FunctionField
							record={companyDetails}
							className={classes.additionalInfoValue}
							render={(companyDetails: any) => {
								return (
									<Tooltip
										title={`${companyDetails?.owner.full_name || '- -'}`}
										placement='right'
									>
										<Typography
											className={`${classes.accountOwnerValue} ${styles.ellipsis}`}
										>
											{`${companyDetails?.owner.full_name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Phone Number
					</Typography>
					{companyDetails?.phone ? (
						<div className={classes.additionalInfoValue}>
							{companyDetails?.phone}
						</div>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Contact Type
					</Typography>
					{companyDetails?.company_contact_type ? (
						<div className={classes.additionalInfoValue}>
							{companyDetails?.company_contact_type?.label}
						</div>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
			</div>
			<CompanyBasicDetailsForm
				open={isFormShown}
				onClose={(value: boolean) => setIsFormShown(value)}
				data={companyFormData}
				{...props}
				refetchCompany={refetchCompany}
			/>
		</div>
	);
};

export default CompanyDetailsCard;
