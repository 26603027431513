import React from 'react';

import SettingsDetailContainer from '../SettingsDetailContainer.component';
import OrganisationShift from './OrganisationShift.component';
import OrganisationTiming from './OrganisationTiming.component';

const OrganisationShiftAndTiming = () => {
	return (
		<>
			<SettingsDetailContainer heading='Work Shifts'>
				<OrganisationShift />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Work Timings'>
				<OrganisationTiming />
			</SettingsDetailContainer>
		</>
	);
};

export default OrganisationShiftAndTiming;
