import React, { useContext, useEffect,useRef } from 'react';
import { UserProfileContext } from '../../App';
import { PAGINATION_STATE_ACTIONS } from '../../globalState';
import { Pagination } from 'react-admin';

export const EmployeePagination = (props: any) => {
	const { userData, page } = props;
	const { paginationState, dispatchPaginationState } =
		useContext<any>(UserProfileContext);
	const initialRenderRef = useRef(true);
	const storedPage: number = paginationState?.employeePagePagination;
	const initialPage: number = storedPage ?? 1;

	let paginationData = {
		...props,
	};
	paginationData.page = initialPage;
	paginationData.setPage(initialPage);
	useEffect(() => {
		if (!initialRenderRef.current) {
			dispatchPaginationState({
				type: PAGINATION_STATE_ACTIONS.UPDATE_EMPLOYEE_PAGE_PAGINATION,
				payload: userData === 0 ? 1 : page,
			});
		}
		initialRenderRef.current =false
	}, [page, userData,dispatchPaginationState]);

	return <Pagination rowsPerPageOptions={[15, 25, 50]} {...paginationData} />;
};
