import React from 'react';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { Box, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { detailsPageTabDetailsStyle } from '../../../Layout/styles';
import { GET_PROJECT } from '../../ResourcePlanner/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getCurrencyFormat } from '../../../Utils/string.util';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '424px',
		width: '500px',
		flexWrap: 'wrap',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
	description: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		marginTop: '5px',
	},
}));

const ProjectDetailList = () => {
	const { id }: { id: string } = useParams();
	const classes = detailsPageTabDetailsStyle();
	const styles = useStyles();

	const { data: project } = useApolloQuery(GET_PROJECT, {
		variables: {
			projectId: id || null,
		},
		fetchPolicy: 'network-only',
	});

	return (
		<Box>
			<Box className={styles.container}>
				<FieldContainer>
					<Typography className={classes.label}>Pre-sales Owner</Typography>
					<Box className={`${classes.value} ${classes.ellipsis}`}>
						{project?.project_by_pk?.pre_sales_owner_id ? (
							<>
								{`${project?.project_by_pk?.userByPreSalesOwnerId?.full_name}
                 `}
							</>
						) : (
							'--'
						)}
					</Box>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Is Strict</Typography>
					<Box className={classes.value}>
						{project?.project_by_pk?.is_strict === true ? 'Yes' : 'No'}
					</Box>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Contract Value</Typography>
					<Box className={classes.value}>
						{getCurrencyFormat(
							project?.project_by_pk?.revenue,
							project?.project_by_pk?.currency?.currency_type,
							true
						)}
					</Box>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>
						Project Execution Type
					</Typography>
					<Box className={classes.value}>
						{project?.project_by_pk?.project_execution_type || '--'}
					</Box>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Country</Typography>
					<Box className={classes.value}>
						{project?.project_by_pk?.geo_list?.name || '--'}
					</Box>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Description</Typography>
					<Tooltip
						title={`${project?.project_by_pk?.description}`}
						placement='right'
					>
						<Box className={styles.description}>
							{project?.project_by_pk?.description || '--'}
						</Box>
					</Tooltip>
				</FieldContainer>
			</Box>
		</Box>
	);
};

export default ProjectDetailList;
