import { createStyles, TableCell, withStyles } from "@material-ui/core";

export const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '10px',
			padding: '12px',
		},
	})
)(TableCell);