import React from 'react';
import { useParams } from 'react-router-dom';
import { TextInput, useMutation, useNotify, useRefresh } from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
interface Record {
	machine_serial_no?: number;
	model?: string;
	processor?: string;
	memory?: string;
	hard_drive?: string;
	ip_address?: string;
	mac_address?: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	record: Record;
	refetch: () => void;
}
export const EditHardwareDetails = (props: Props) => {
	const { open, onClose, record: asset, refetch } = props;
	const classes = modalFormStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const { id }: { id: string } = useParams();
	const saveDetails = (formValue: Record) => {
		mutate(
			{
				type: 'update',
				resource: 'asset',
				payload: {
					id: id,
					data: formValue,
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Hardware details updated successfully');
					onClose();
					refresh();
					refetch();
				},
				onFailure: (error) => {
					notify(`Updating failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => onClose()}
						/>
					</Box>
					<Form initialValues={asset} onSubmit={saveDetails}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box className={classes.label}>Serial Number</Box>
									<TextInput
										source='machine_serial_no'
										fullWidth={true}
										label={false}
									/>
									<Box className={classes.label}>Model Number</Box>
									<TextInput source='model' fullWidth={true} label={false} />
									<Box className={classes.label}>Processor</Box>
									<TextInput
										source='processor'
										label={false}
										fullWidth={true}
									/>
									<Box className={classes.multipleInputContainer}>
										<Box>
											<Box className={classes.label}>Memory</Box>
											<TextInput
												source='memory'
												label={false}
												fullWidth={true}
											/>
										</Box>
										<Box
											style={{
												width: '185px',
												height: '36px',
											}}
										>
											<Box className={classes.label}>Hard Drive</Box>
											<TextInput
												source='hard_drive'
												label={false}
												fullWidth={true}
											/>
										</Box>
									</Box>

									<Box className={classes.label}>IP Address</Box>
									<TextInput
										source='ip_address'
										label={false}
										fullWidth={true}
									/>
									<Box className={classes.label}>MAC Address</Box>
									<TextInput source='mac_address' label={false} />
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => onClose()}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid ? classes.disabledButton : classes.saveButton
										}
										disabled={invalid}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditHardwareDetails;
