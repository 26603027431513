import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Avatar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Checkbox,
	Box,
	ThemeProvider,
	makeStyles,
	TextField as TextComponent,
	Tooltip,
	IconButton,
} from '@material-ui/core';

import {
	TIME_SHEET_APPROVAL_SUCCESS_TEXT,
	TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT,
	TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL,
	TIME_SHEET_REJECT_MODAL_BUTTON_LABEL,
	TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL,
	TIME_SHEET_REJECT_SUCCESS_TEXT,
	TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT,
} from './constant';

import { modalFormStyle } from '../../Layout/styles';

import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
} from '../../Layout/Theme.component';

import {
	useApproveTimesheetMutation,
	useGetTimesheetStatusQuery,
	useGetOrganisationManHoursQuery,
	GetPendingApprovalFilterTimesheetQuery,
	useGetPendingApprovalFilterQuery,
	useGetPendingApprovalFilterTimesheetQuery,
	useRejectTimesheetEntriesMutation,
	useUpdateTimesheetStatusMutation,
	useGetDraftTimesheetEntriesQuery,
	useInsertTimesheetSubmissionNotesMutation,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import {
	SubmittedTimesheets,
	TimesheetSubmission,
	User,
} from './Timesheet.model';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ConfirmationModal from '../../SharedComponents/ConfirmationModal.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useNotify } from 'react-admin';
import {
	TimesheetStyles,
	TimesheetHistoryPendingApprovalStyle,
} from './TimesheetStyles';
import { ellipsisStyle, autocompleteStyle } from '../../Layout/styles';
import TimesheetPendingApprovalDetailsModal from './TimesheetPendingApprovalDetailsModal.component';
import {
	getBillableHours,
	getTotalNonBillableHours,
	getTotalBillableHours,
	getTimesheetEntriesBillablePercentage,
	getTimesheetWeek,
	getTotalHours,
} from './Timesheet.utils';
import { chain, isEmpty, orderBy, head } from 'lodash';
import no_data_found from '../../assets/no_data_found.png';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';

import duration from 'dayjs/plugin/duration';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { SpeakerNotes } from '@material-ui/icons';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TimesheetCommentCapture from './TimesheetCommentCapture.component';
dayjs.extend(duration);
dayjs.extend(weekOfYear);

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
			cursor: 'pointer',
		},
	})
)(TableCell);

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	projectName: {
		fontSize: '12px',
		width: '112px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '140px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-64px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		marginTop: '23px',
	},
	actionAdminContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	rejectButton: {
		background: '#ED3D3D',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	unlockButton: {
		padding: '0px',
		color: '#40a9ff',
	},
}));

interface Props {
	refetch: () => void;
}

export const TimesheetPendingApproval = (props: Props) => {
	const { refetch } = props;
	const {
		id: userId,
		orgId,
		isDraftTimesheetEnabled,
	} = useContext<any>(UserProfileContext);
	const modalFormStyles = modalFormStyle();
	const styles = modalFormStyle();
	const style = ellipsisStyle();
	const dropDownStyle = autocompleteStyle();
	const timesheetPendingApprovalStyles = TimesheetHistoryPendingApprovalStyle();
	const timesheetStyles = TimesheetStyles();
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [submissionIds, setSubmissionIds] = useState<any>([]);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<any>();
	const [submittedTimesheets, setSubmittedTimesheets] =
		useState<GetPendingApprovalFilterTimesheetQuery>();
	const numberOfWeekDays = 5; // TODO We have to fetch this value from DB
	const notify = useNotify();
	const classes = useStyles();
	const [isRejectTimesheet, setIsRejectTimesheet] = useState(false);
	const [isApproveTimesheet, setIsApproveTimesheet] = useState(false);
	const [isMoveToDraft, setIsMoveToDraft] = useState(false);
	const [
		moveToDraftTimesheetSubmissionId,
		setMoveToDraftTimesheetSubmissionId,
	] = useState(null);
	const [reporte, setReportee] = useState<any>();
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedStatusOptionValue, setSelectedStatusOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any>([]);
	const [selectedStatusIdList, setSelectedStatusIdList] = useState<any>([]);
	const [isCommentCaptureShown, setIsCommentCaptureShown] = useState(false);

	const {
		data: pendingApprovalTimesheet,
		refetch: refetchSubmittedTimesheets,
		loading: submittedTimesheetsLoading,
	} = useGetPendingApprovalFilterTimesheetQuery({
		variables: {
			filters: {
				_or: [
					{ employee: { reportsTo: { id: { _eq: userId } } } },
					{ contractors: { reportee: { id: { _eq: userId } } } },
				],
				is_active: { _eq: true },
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? {
								_in: selectedResourceIdList,
						  }
						: { _in: [] },
			},
			timesheetStatusId:
				selectedStatusIdList && selectedStatusIdList?.length > 0
					? {
							_in: selectedStatusIdList,
					  }
					: { _in: [] },
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: draftTimesheetEntries,
		refetch: refetchDraftTimesheetEntries,
		loading: draftTimesheetEntriesLoading,
	} = useGetDraftTimesheetEntriesQuery({
		variables: {
			filters: {
				_or: [
					{ employee: { reportsTo: { id: { _eq: userId } } } },
					{ contractors: { reportee: { id: { _eq: userId } } } },
				],
				is_active: { _eq: true },
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? {
								_in: selectedResourceIdList,
						  }
						: { _in: [] },
			},
		},
		fetchPolicy: 'network-only',
	});

	const { data: reporteUserConsultant } = useGetPendingApprovalFilterQuery({
		variables: {
			userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: organisationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId: orgId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: timesheetStatus } = useGetTimesheetStatusQuery();
	const [approveTimeSheet, { loading: isApproveTimesheetLoading }] =
		useApproveTimesheetMutation();
	const [rejectTimesheet, { loading: isRejectTimesheetLoading }] =
		useRejectTimesheetEntriesMutation();
	const [insertTimesheetSubmissionNotes, { loading: isTimeSheetLoading }] =
		useInsertTimesheetSubmissionNotesMutation();
	const [updateTimeSheetStatus, { loading: isUpdateTimeSheetStatusLoading }] =
		useUpdateTimesheetStatusMutation();

	useEffect(() => {
		if (isEmpty(timesheetStatus?.timesheet_status)) {
			return;
		}
		// Timesheet Status except draft
		const timesheetStatusOptionsExceptDraft = timesheetStatus?.timesheet_status
			?.filter((value) => value.value !== 'draft')
			.map((timesheet) => {
				if (!timesheet) {
					return;
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			});
		const sortedTimesheetStatusOptionsExceptDraft = orderBy(
			timesheetStatusOptionsExceptDraft,
			['name'],
			['asc']
		);
		// All Timesheet Status
		const timesheetStatusOptions = timesheetStatus?.timesheet_status?.map(
			(timesheet) => {
				if (!timesheet) {
					return;
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			}
		);
		const pendingStatus = timesheetStatusOptions?.find(
			(status: any) => status?.name === 'Pending'
		);
		if (pendingStatus) {
			setSelectedStatusOptionValues([pendingStatus]);
			setSelectedStatusIdList([pendingStatus?.id]);
		}
		const sortedTimesheetStatusOptions = orderBy(
			timesheetStatusOptions,
			['name'],
			['asc']
		);
		setTimesheetStatusOptions(
			isDraftTimesheetEnabled
				? sortedTimesheetStatusOptions
				: sortedTimesheetStatusOptionsExceptDraft
		);
	}, [timesheetStatus, isDraftTimesheetEnabled]);

	useEffect(() => {
		if (!reporteUserConsultant) {
			return;
		}
		const filterUserList = reporteUserConsultant.user.map((value) => {
			return {
				id: value?.id,
				name: `${value?.full_name}`,
			};
		});
		const idList = filterUserList?.map((resource) => resource?.id);
		setReportee(filterUserList);
		setSelectedResourceIdList(idList);
		setSelectedResourceOptionValues([
			{ id: 'All', name: 'All' },
			...filterUserList,
		]);
	}, [reporteUserConsultant]);

	useEffect(() => {
		if (!pendingApprovalTimesheet || !draftTimesheetEntries) {
			return;
		}
		setSelectedRows([]);
		setSubmissionIds([]);

		const isDraftStatusSelected = selectedStatusOptionValue?.find(
			(status: any) => status?.name === 'Draft'
		);

		// Draft Timesheets
		const draftSubmittedTimesheetEntries = draftTimesheetEntries.user
			.filter((user) => user.draftEntries.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: chain(
					user.draftEntries.map((draftEntry) => ({
						...draftEntry,
						week: `${dayjs(draftEntry.date).format('YYYY')}-${dayjs(
							draftEntry.date
						).week()}`,
					}))
				)
					.groupBy('week')
					.map((week, index) => ({
						id: index,
						timesheets: week,
						timesheetSubmissionNotes: [],
						timesheet_status: { id: 'draft', label: 'Draft', value: 'draft' },
					}))
					.value(),
			}));

		// Submitted Timesheets
		const timesheets = pendingApprovalTimesheet.user
			.filter((user) => user.timesheetSubmissions.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: user.timesheetSubmissions.filter(
					(timesheetSubmission) => timesheetSubmission.timesheets.length > 0
				),
			}))
			.filter((value) => value?.timesheetSubmissions.length > 0);

		// Combine draft and submitted timesheets
		const allTimesheets = timesheets.map((user) => {
			const draftEntries = draftSubmittedTimesheetEntries.find(
				(draftEntriesUser) => draftEntriesUser.id === user.id
			)?.timesheetSubmissions;

			if (draftEntries) {
				return {
					...user,
					timesheetSubmissions: [...user.timesheetSubmissions, ...draftEntries],
				};
			}
			return user;
		});

		const userHaveOnlyDraftEntries = draftSubmittedTimesheetEntries.filter(
			(timesheetEntry) =>
				allTimesheets.filter((user) => user.id === timesheetEntry.id).length ===
				0
		);

		if (timesheets) {
			setSubmittedTimesheets({
				user: isDraftStatusSelected
					? [...allTimesheets, ...userHaveOnlyDraftEntries]
					: timesheets,
			});
		}
	}, [
		pendingApprovalTimesheet,
		draftTimesheetEntries,
		selectedStatusOptionValue,
	]);

	const handleMoveTimesheetToDraft = async () => {
		if (!timesheetStatus || !moveToDraftTimesheetSubmissionId) {
			return;
		}

		const draftTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'draft'
		);

		const timesheetDraft = await updateTimeSheetStatus({
			variables: {
				timesheetSubmissionId: moveToDraftTimesheetSubmissionId,
				timesheetStatusId: draftTimesheetStatus?.id,
			},
		});

		if (!timesheetDraft.errors) {
			setSubmissionIds([]);
			refetchSubmittedTimesheets();
			refetchDraftTimesheetEntries();
			setIsApproveTimesheet(false);
			setIsRejectTimesheet(false);
			setIsMoveToDraft(false);
			setIsConfirmationModalShown(false);
			refetch();
			notify(TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT);
		}
	};

	const handleApproveTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		const approvedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveTimeSheet({
			variables: {
				submissionIds: submissionIds,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			setSubmissionIds([]);
			refetchSubmittedTimesheets();
			refetchDraftTimesheetEntries();
			setIsApproveTimesheet(false);
			setIsRejectTimesheet(false);
			setIsMoveToDraft(false);
			setIsConfirmationModalShown(false);
			refetch();
			notify(TIME_SHEET_APPROVAL_SUCCESS_TEXT);
		}
	};

	const handleRejectTimesheet = () => {
		if (!timesheetStatus) {
			return;
		}
		setIsCommentCaptureShown(true);
	};

	const rejectTimesheets = async (notes: string) => {
		try {
			if (!timesheetStatus) {
				return;
			}
			const rejectedTimesheetStatus = timesheetStatus.timesheet_status.find(
				(status) => status.value === 'rejected'
			);
			const timesheetRejectNotesMutationRes =
				await insertTimesheetSubmissionNotes({
					variables: {
						data: Array.from(new Set(submissionIds)).map(
							(submissionId: any) => ({
								notes: notes,
								type: 'rejected',
								timesheet_submission_id: submissionId,
							})
						),
					},
				});

			if (timesheetRejectNotesMutationRes.errors) {
				setSubmissionIds([]);
				setIsRejectTimesheet(false);
				setIsApproveTimesheet(false);
				setIsMoveToDraft(false);
				setIsConfirmationModalShown(false);
				refetch();
				notify(
					head(timesheetRejectNotesMutationRes.errors)?.message ||
						'Rejection failed'
				);
				return;
			}
			setIsCommentCaptureShown(false);
			const timesheetReject = await rejectTimesheet({
				variables: {
					submissionIds: submissionIds,
					timesheetStatusId: rejectedTimesheetStatus?.id,
				},
			});
			if (!timesheetReject.errors) {
				setSubmissionIds([]);
				setIsRejectTimesheet(false);
				setIsApproveTimesheet(false);
				setIsConfirmationModalShown(false);
				refetch();
				notify(TIME_SHEET_REJECT_SUCCESS_TEXT);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getTimesheetBillablePercentage = (
		timesheets: SubmittedTimesheets[]
	) => {
		if (!timesheets || !organisationManHours) {
			return;
		}
		const totalHoursPerWeek =
			organisationManHours.organization_by_pk?.working_hours * numberOfWeekDays;

		const totalBillableHours = getBillableHours(timesheets);

		return totalBillableHours
			? ((totalBillableHours?.asHours() / totalHoursPerWeek) * 100).toFixed()
			: 0;
	};

	const handleRowClick = (
		timesheetSubmission: TimesheetSubmission,
		userDetails: User
	) => {
		if (!timesheetSubmission?.timesheets[0]?.date) {
			return;
		}
		setSelectedTimesheet({
			user: {
				id: userDetails.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			dateRange: getTimesheetWeek(timesheetSubmission.timesheets),
			billablePercentage: getTimesheetEntriesBillablePercentage(
				timesheetSubmission.timesheets
			),
			billableHours: getTotalBillableHours(timesheetSubmission.timesheets),
			nonBillableHours: getTotalNonBillableHours(
				timesheetSubmission.timesheets
			),
			totalHours: getTotalHours(timesheetSubmission.timesheets),
			timesheetSubmissionId: timesheetSubmission.id,
			timesheetPeriod: Array(7)
				.fill(1)
				.map((value: number, index: number) =>
					dayjs(timesheetSubmission.timesheets[0].date)
						.day(index)
						.format('YYYY-MM-DD')
				),
			startDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(0)
				.format('YYYY-MM-DD'),
			endDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(6)
				.format('YYYY-MM-DD'),
			timesheetStatus: timesheetSubmission?.timesheet_status?.value,
		});
	};

	const checkIfDraftEntriesSelected = () => {
		if (selectedRows?.length === 0) {
			return false;
		}
		const draftEntries = selectedRows?.filter(
			(timesheet: any) => timesheet?.status?.value === 'draft'
		);
		if (draftEntries?.length > 0) {
			return true;
		}
		return false;
	};

	const onHandleSelectAll = (timesheetSubmissions: any) => {
		if (!timesheetSubmissions && !submissionIds) {
			return;
		}
		if (!isEmpty(submissionIds)) {
			const record = timesheetSubmissions
				?.filter(
					(timesheetSubmission: any) =>
						timesheetSubmission.timesheet_status.value !== 'approved' &&
						timesheetSubmission.timesheet_status.value !== 'rejected' &&
						timesheetSubmission.timesheet_status.value !== 'draft'
				)
				.map((timesheet: any) => timesheet?.id);

			if (record.every((id: string) => submissionIds.includes(id))) {
				return true;
			} else return false;
		}
	};
	const getProjectResourceFilterLimits = (value: any) => {
		if (!value) {
			return;
		}
		return (
			<div className={`${dropDownStyle.dropdownValue} ${style.ellipsis}`}>
				{selectedResourceOptionValue &&
				selectedResourceOptionValue?.length === 1
					? selectedResourceOptionValue[0].name
					: `Selected ${value} items`}
			</div>
		);
	};

	const getProjectStatusFilterLimits = (value: any) => {
		if (!value) {
			return;
		}
		return (
			<div className={`${dropDownStyle.dropdownValue} ${style.ellipsis}`}>
				{selectedStatusOptionValue && selectedStatusOptionValue?.length === 1
					? selectedStatusOptionValue[0].name
					: `Selected ${value} items`}
			</div>
		);
	};

	const getSubmitButtonLabel = () => {
		if (isRejectTimesheet) {
			return TIME_SHEET_REJECT_MODAL_BUTTON_LABEL;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL;
		}
		return TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL;
	};

	const getConfirmationMessage = () => {
		if (isRejectTimesheet) {
			return `Do you really want to reject ${
				Array.from(new Set(submissionIds))?.length
			} record(s)?`;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT;
		}
		return `Do you really want to approve ${
			Array.from(new Set(submissionIds))?.length
		} record(s)?`;
	};

	return (
		<>
			<div className={timesheetPendingApprovalStyles.container}>
				<div className={classes.actionAdminContainer}>
					<ThemeProvider theme={modalFormTheme}>
						<Box display='flex'>
							<Box width='200px' marginRight='15px'>
								<Box className={styles.label}>Search User</Box>
								<Autocomplete
									multiple
									limitTags={0}
									value={
										selectedResourceOptionValue &&
										selectedResourceOptionValue?.length > 0
											? selectedResourceOptionValue
											: []
									}
									selectOnFocus={true}
									disablePortal
									fullWidth={false}
									onChange={(event, value, reason) => {
										if (!value) {
											return;
										}
										const selectedIds = value
											?.filter((resource: any) => resource.id !== 'All')
											.map((resource: any) => resource?.id);
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'select-option'
										) {
											setSelectedResourceOptionValues([
												{ id: 'All', name: 'All' },
												...reporte,
											]);
											const allResourceIds = reporte?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(allResourceIds);
											return;
										}
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'remove-option' &&
											reporte?.length !== selectedIds?.length
										) {
											const currentOptions = value?.filter(
												(resource: any) => resource?.id !== 'All'
											);
											setSelectedResourceOptionValues(currentOptions);
											const currentIds = currentOptions?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(currentIds);
											return;
										}
										if (
											selectedIds?.length === reporte?.length &&
											reason === 'select-option'
										) {
											setSelectedResourceOptionValues([
												{ id: 'All', name: 'All' },
												...reporte,
											]);
											const reporteeIds = reporte?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(reporteeIds);
											return;
										}
										if (
											selectedResourceOptionValue.find(
												(option: any) => option?.id === 'All'
											) &&
											reason === 'remove-option'
										) {
											setSelectedResourceIdList([]);
											setSelectedResourceOptionValues([]);
											return;
										}
										const selectedId = value?.map((resource) => resource?.id);
										setSelectedResourceIdList(selectedId);
										setSelectedResourceOptionValues(value);
									}}
									options={
										reporte && reporte?.length > 0
											? [{ id: 'All', name: 'All' }, ...reporte]
											: []
									}
									disableCloseOnSelect={true}
									getLimitTagsText={getProjectResourceFilterLimits}
									ChipProps={{ style: { display: 'none' } }}
									renderInput={(params) => (
										<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
											<TextComponent {...params} label='' />
										</ThemeProvider>
									)}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected}
												color='primary'
											/>

											<Tooltip
												title={`${option?.name || '- -'}`}
												placement='right'
											>
												<Typography
													className={`${style.ellipsis}`}
													style={{ fontSize: '12px' }}
												>
													{`${option?.name || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									)}
									getOptionLabel={(option) => `${option?.name}`}
									getOptionSelected={(option, value) => option.id === value.id}
								/>
							</Box>
							<Box width='200px'>
								<Box className={styles.label}>Status</Box>
								<Autocomplete
									multiple
									limitTags={0}
									value={
										selectedStatusOptionValue &&
										selectedStatusOptionValue?.length > 0
											? selectedStatusOptionValue
											: []
									}
									selectOnFocus={true}
									disablePortal
									fullWidth={false}
									onChange={(event, value, reason) => {
										if (!value) {
											return;
										}
										const selectedIds = value
											?.filter((status: any) => status.id !== 'All')
											.map((status: any) => status?.id);
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'select-option'
										) {
											setSelectedStatusOptionValues([
												{ id: 'All', name: 'All' },
												...timesheetStatusOptions,
											]);
											const allStatusIds = timesheetStatusOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(allStatusIds);
											return;
										}
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'remove-option' &&
											timesheetStatusOptions?.length !== selectedIds?.length
										) {
											const currentOptions = value?.filter(
												(status: any) => status?.id !== 'All'
											);
											setSelectedStatusOptionValues(currentOptions);
											const currentIds = currentOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(currentIds);
											return;
										}
										if (
											selectedIds?.length === timesheetStatusOptions?.length &&
											reason === 'select-option'
										) {
											setSelectedStatusOptionValues([
												{ id: 'All', name: 'All' },
												...timesheetStatusOptions,
											]);
											const statusIds = timesheetStatusOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(statusIds);
											return;
										}
										if (
											selectedStatusOptionValue.find(
												(option: any) => option.id === 'All'
											) &&
											reason === 'remove-option'
										) {
											setSelectedStatusIdList([]);
											setSelectedStatusOptionValues([]);
											return;
										}
										const selectedId = value?.map((status) => status.id);
										setSelectedStatusIdList(selectedId);
										setSelectedStatusOptionValues(value);
									}}
									options={
										timesheetStatusOptions && timesheetStatusOptions?.length > 0
											? [{ id: 'All', name: 'All' }, ...timesheetStatusOptions]
											: []
									}
									disableCloseOnSelect={true}
									getLimitTagsText={getProjectStatusFilterLimits}
									ChipProps={{ style: { display: 'none' } }}
									renderInput={(params) => (
										<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
											<TextComponent {...params} label='' />
										</ThemeProvider>
									)}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected}
												color='primary'
											/>
											<Box
												style={{ fontSize: '12px' }}
											>{`${option?.name}`}</Box>
										</>
									)}
									getOptionLabel={(option) => `${option?.name}`}
									getOptionSelected={(option, value) => option.id === value.id}
								/>
							</Box>
						</Box>
					</ThemeProvider>
					<Box display='flex' marginTop='3px'>
						<Box marginRight='5px'>
							<Button
								disabled={
									submissionIds.length === 0 || checkIfDraftEntriesSelected()
								}
								className={`${timesheetPendingApprovalStyles.button}  
								${
									submissionIds.length === 0 || checkIfDraftEntriesSelected()
										? modalFormStyles.disabledButton
										: `${modalFormStyles.rejectButton}`
								} 
								`}
								onClick={() => {
									setIsRejectTimesheet(true);
									setIsApproveTimesheet(false);
									setIsConfirmationModalShown(true);
								}}
							>
								Reject
							</Button>
						</Box>
						<Box>
							<Button
								disabled={
									submissionIds.length === 0 || checkIfDraftEntriesSelected()
								}
								className={`${timesheetPendingApprovalStyles.button}
								${
									submissionIds.length === 0 || checkIfDraftEntriesSelected()
										? modalFormStyles.disabledButton
										: modalFormStyles.approveButton
								}
								`}
								onClick={() => {
									setIsRejectTimesheet(false);
									setIsApproveTimesheet(true);
									setIsConfirmationModalShown(true);
								}}
							>
								Approve
							</Button>
						</Box>
					</Box>
				</div>
			</div>
			{submittedTimesheetsLoading || draftTimesheetEntriesLoading ? (
				<Box>
					<Loading />
				</Box>
			) : (
				<Box className={timesheetPendingApprovalStyles.scrollableContainer}>
					{submittedTimesheets && submittedTimesheets.user.length > 0 ? (
						<div className={timesheetPendingApprovalStyles.container}>
							<div>
								{submittedTimesheets.user.map(
									(user) =>
										user.timesheetSubmissions.length > 0 && (
											<div
												className={
													timesheetPendingApprovalStyles.timesheetsContainer
												}
												key={user.id}
											>
												<div
													className={
														timesheetPendingApprovalStyles.avatarContainer
													}
												>
													<Avatar
														className={timesheetPendingApprovalStyles.avatar}
														src={`${user.profile_pic || ''}`}
													>
														{user?.first_name?.charAt(0).toUpperCase() || ''}
														{user?.last_name?.charAt(0).toUpperCase() || ''}
													</Avatar>
													<Typography
														className={
															timesheetPendingApprovalStyles.employeeName
														}
													>
														{`${user.full_name || ''} `}
													</Typography>
												</div>
												<Table>
													<TableHead>
														<TableRow>
															<CustomTableCell>
																<Checkbox
																	name='checkedB'
																	color='primary'
																	checked={onHandleSelectAll(
																		user.timesheetSubmissions
																	)}
																	onChange={(event: any) => {
																		if (event.currentTarget.checked === true) {
																			onHandleSelectAll(
																				user.timesheetSubmissions
																			);
																			const record =
																				user.timesheetSubmissions?.filter(
																					(timesheetSubmission: any) =>
																						timesheetSubmission.timesheet_status
																							.value !== 'approved' &&
																						timesheetSubmission.timesheet_status
																							.value !== 'rejected' &&
																						timesheetSubmission.timesheet_status
																							.value !== 'draft'
																				);

																			setSelectedRows([
																				...selectedRows,
																				...record?.map(
																					(timesheetSubmission) => ({
																						id: timesheetSubmission?.id,
																						status:
																							timesheetSubmission.timesheet_status,
																					})
																				),
																			]);

																			setSubmissionIds([
																				...submissionIds,
																				...record.map(
																					(timesheet) => timesheet?.id
																				),
																			]);
																		} else {
																			const record =
																				user.timesheetSubmissions?.filter(
																					(timesheetSubmission: any) =>
																						timesheetSubmission.timesheet_status
																							.value !== 'approved' &&
																						timesheetSubmission.timesheet_status
																							.value !== 'rejected' &&
																						timesheetSubmission.timesheet_status
																							.value !== 'draft'
																				);

																			const ids = [...submissionIds].filter(
																				(e) =>
																					record
																						?.map((timesheet) => timesheet?.id)
																						?.includes(e) === false
																			);
																			const rows = [...selectedRows].filter(
																				(e) =>
																					record
																						?.map((timesheet) => timesheet?.id)
																						?.includes(e?.id) === false
																			);
																			setSelectedRows(rows);
																			setSubmissionIds(ids);
																		}
																	}}
																/>
															</CustomTableCell>
															<CustomTableCell>DATE</CustomTableCell>
															<CustomTableCell>STATUS</CustomTableCell>
															<CustomTableCell>TOTAL HOURS</CustomTableCell>
															<CustomTableCell>BILLABLE HOURS</CustomTableCell>
															<CustomTableCell>BILLABLE (%)</CustomTableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{user.timesheetSubmissions.map(
															(timesheetSubmission, index) =>
																timesheetSubmission.timesheets.length > 0 && (
																	<TableRow
																		hover={true}
																		key={timesheetSubmission.id}
																	>
																		<CustomTableCell
																			className={
																				timesheetPendingApprovalStyles.dataActionContainer
																			}
																		>
																			{timesheetSubmission.timesheet_status
																				.value === 'approved' ? (
																				<CheckCircleIcon
																					className={
																						timesheetPendingApprovalStyles.approvedIcon
																					}
																				/>
																			) : (
																				<Checkbox
																					id={`${timesheetSubmission.timesheet_status?.value}${index}`}
																					className={
																						timesheetPendingApprovalStyles.checkBox
																					}
																					checked={
																						timesheetSubmission.timesheet_status
																							?.value !== 'approved' &&
																						timesheetSubmission.timesheet_status
																							?.value !== 'rejected' &&
																						submissionIds?.includes(
																							timesheetSubmission.id
																						)
																					}
																					disabled={
																						timesheetSubmission.timesheet_status
																							.value !== 'submitted'
																							? true
																							: false
																					}
																					onChange={(e) => {
																						if (e.currentTarget.checked) {
																							const row = {
																								id: timesheetSubmission?.id,
																								status:
																									timesheetSubmission.timesheet_status,
																							};
																							setSelectedRows([
																								...selectedRows,
																								row,
																							]);

																							setSubmissionIds([
																								...submissionIds,
																								timesheetSubmission.id,
																							]);
																						} else {
																							setSelectedRows(
																								selectedRows.filter(
																									(timesheet: any) =>
																										timesheet.id !==
																										timesheetSubmission.id
																								)
																							);
																							setSubmissionIds(
																								submissionIds.filter(
																									(id: any) =>
																										id !==
																										timesheetSubmission.id
																								)
																							);
																						}
																					}}
																					name='checkedB'
																					color='primary'
																				/>
																			)}
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(timesheetSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={
																					timesheetPendingApprovalStyles.dateContainer
																				}
																			>
																				<DateRangeIcon
																					className={
																						timesheetPendingApprovalStyles.calendarIcon
																					}
																				/>
																				{getTimesheetWeek(
																					timesheetSubmission.timesheets
																				) || '- -'}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(timesheetSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={`${
																					timesheetStyles.statusContainer
																				}
                                ${
																	timesheetSubmission.timesheet_status.value ===
																	'submitted'
																		? timesheetStyles.submittedStatus
																		: timesheetSubmission.timesheet_status
																				.value === 'approved'
																		? timesheetStyles.approvedStatus
																		: timesheetStyles.rejectedStatus
																}
                              `}
																			>
																				{timesheetSubmission.timesheet_status
																					.value === 'submitted'
																					? 'Pending'
																					: timesheetSubmission.timesheet_status
																							.label}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(timesheetSubmission, {
																					id: user.id,
																					name: `${user?.full_name}`,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={
																					timesheetPendingApprovalStyles.totalHoursContainer
																				}
																			>
																				<AccessTimeIcon
																					className={
																						timesheetPendingApprovalStyles.durationIcon
																					}
																				/>
																				{`${getTotalHours(
																					timesheetSubmission.timesheets
																				)} hours`}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(timesheetSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			{`${getTotalBillableHours(
																				timesheetSubmission.timesheets
																			)} hours`}
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(timesheetSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			{`${getTimesheetBillablePercentage(
																				timesheetSubmission.timesheets
																			)}%`}
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				if (
																					timesheetSubmission.timesheet_status
																						.value !== 'approved'
																				) {
																					setIsDetailsModalShown(true);
																					handleRowClick(timesheetSubmission, {
																						id: user.id,
																						name: `${user?.full_name} `,
																						profilePic: user.profile_pic,
																					});
																				}
																			}}
																		>
																			{timesheetSubmission.timesheet_status
																				.value === 'rejected' && (
																				<Tooltip
																					title={
																						head(
																							timesheetSubmission.timesheetSubmissionNotes
																						)?.notes || '- -'
																					}
																					placement='top'
																					arrow
																				>
																					<SpeakerNotes />
																				</Tooltip>
																			)}
																			{timesheetSubmission.timesheet_status
																				.value === 'approved' && (
																				<Tooltip
																					title={'Move to Draft'}
																					placement='top'
																					arrow
																				>
																					<IconButton
																						className={classes.unlockButton}
																						onClick={() => {
																							setMoveToDraftTimesheetSubmissionId(
																								timesheetSubmission.id
																							);
																							setIsRejectTimesheet(false);
																							setIsApproveTimesheet(false);
																							setIsMoveToDraft(true);
																							setIsConfirmationModalShown(true);
																						}}
																					>
																						<LockOpenIcon />
																					</IconButton>
																				</Tooltip>
																			)}
																		</CustomTableCell>
																	</TableRow>
																)
														)}
													</TableBody>
												</Table>
											</div>
										)
								)}
							</div>
						</div>
					) : (
						<Box textAlign='center'>
							<img src={no_data_found} alt='no_data_found' />
						</Box>
					)}
				</Box>
			)}
			<ConfirmationModal
				isWarningConfirmation={isRejectTimesheet || isMoveToDraft}
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
					setMoveToDraftTimesheetSubmissionId(null);
				}}
				confirmationMessage={getConfirmationMessage()}
				onSubmit={() => {
					if (isRejectTimesheet) {
						handleRejectTimesheet();
						return;
					}
					if (isApproveTimesheet) {
						handleApproveTimesheet();
						return;
					}
					if (isMoveToDraft) {
						handleMoveTimesheetToDraft();
						return;
					}
				}}
				submitButtonLabel={getSubmitButtonLabel()}
				loading={
					isRejectTimesheetLoading ||
					isApproveTimesheetLoading ||
					isUpdateTimeSheetStatusLoading
				}
			/>
			<TimesheetCommentCapture
				open={isCommentCaptureShown}
				onClose={() => {
					setIsCommentCaptureShown(false);
				}}
				onSubmit={(value) => {
					rejectTimesheets(value?.notes || '');
				}}
				okButtonLabel={'Save'}
				isLoading={isTimeSheetLoading}
			/>
			<TimesheetPendingApprovalDetailsModal
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedTimesheet({});
				}}
				timesheet={selectedTimesheet}
				onSuccess={() => {
					refetchSubmittedTimesheets();
					refetchDraftTimesheetEntries();
					refetch();
				}}
			/>
		</>
	);
};

export default TimesheetPendingApproval;
