import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	withStyles,
	IconButton,
	InputAdornment,
	ThemeProvider,
	Tooltip,
	makeStyles,
	Checkbox,
	Card,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	Typography,
	TableBody,
	TextField as TextComponent,
	TablePagination,
	CardHeader,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { UserProfileContext } from '../../../App';

import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	InsightReportTableGlobalStyles,
	dropDownEllipsisStyle,
	autocompleteStyle,
} from '../../../Layout/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';

import { isEmpty, orderBy } from 'lodash';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import {
	useEmployeeLeaveStatusQuery,
	useGetActiveUsersByLimitQuery,
	useGetAppliedLeaveReportInAttendanceQuery,
	useGetLocationsByOrgIdQuery,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import { formatEmployeeId, exportToCsv } from '../../../Utils/string.util';
import { START_DATE_ERROR } from '../Constant';
import { dateTimePickerTheme } from '../Style';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
	reducedValueContainer: {
		width: '95px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 0px 6px 6px',
		marginRight: '16px',
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '170px',
		marginRight: '8px',
	},
	exportButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	exportButtonDiv: {
		marginTop: '25px',
		marginLeft: '8px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	startDate: {
		width: '188px',
		marginLeft: '20px',
		marginTop: '14px',
	},
	endDate: {
		width: '188px',
		marginTop: '14px',
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
		minHeight: '200px',
	},
}));

interface option {
	id: any;
	name?: String | null | undefined;
}

export const EmployeeLeaveReport = () => {
	const style = useStyles();
	const commonStyle = InsightReportTableGlobalStyles();
	const styles = modalFormStyle();

	const ellipsis = dropDownEllipsisStyle();
	const dropDownStyle = autocompleteStyle();

	const {
		dateFormat,
		noOfLocations,
		orgId: organizationId,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);

	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [startDateError, setStartDateError] = useState(false);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);
	const [selectedStatusOptionValue, setSelectedStatusOptionValues] = useState<
		option[]
	>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		String[]
	>([]);
	const [selectedLeaveStatusIdList, setSelectedLeaveStatusIdList] = useState<
		String[]
	>([]);
	const [resourceList, setresourceList] = useState<option[]>([]);

	const [leaveStatusDropDown, setLeaveStatusDropDown] = useState<option[]>([]);

	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);
	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const { data: resource } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: leaveStatus } = useEmployeeLeaveStatusQuery({
		fetchPolicy: 'network-only',
	});

	const { data: orgLocations } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});

	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},

					{
						employee: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};

	const { data: appliedLeaveReport } =
		useGetAppliedLeaveReportInAttendanceQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				where: {
					_and: [
						{
							_or: [
								{ from_date: { _gte: startDate, _lte: endDate } },
								{ to_date: { _gte: startDate, _lte: endDate } },
								{
									_and: [
										{ from_date: { _lte: startDate } },
										{ to_date: { _gte: endDate } },
									],
								},
							],
						},
					],
					_or: getLocationFilter(),
					employee: {
						user_id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: { _in: [] },
					},

					employee_leave_status_id:
						selectedLeaveStatusIdList && selectedLeaveStatusIdList?.length > 0
							? { _in: selectedLeaveStatusIdList }
							: { _in: [] },
				},
			},
			fetchPolicy: 'network-only',
		});
	useEffect(() => {
		if (!resource) {
			return;
		}
		const response = resource?.user?.map((user) => {
			return {
				id: user?.id,
				name: user?.name,
			};
		});
		const defaultResourceIdList = response?.map(
			(resource: { id: any; name: string | null | undefined }) => resource?.id
		);
		setresourceList(response);
		setSelectedResourceIdList(defaultResourceIdList);
		setSelectedResourceOptionValues([{ id: 'All', name: 'All' }, ...response]);
	}, [resource]);

	useEffect(() => {
		if (isEmpty(leaveStatus?.employee_leave_status)) {
			return;
		}
		const statusOption = leaveStatus?.employee_leave_status
			.filter((element) => element?.value !== 'draft')
			.map((value) => {
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});

		const sortedStatusOption = orderBy(statusOption, ['name'], ['asc']);
		const defaultResourceIdList = sortedStatusOption?.map(
			(status: { id: any; name: string }) => status?.id
		);
		setLeaveStatusDropDown(sortedStatusOption);
		setSelectedLeaveStatusIdList(defaultResourceIdList);
		setSelectedStatusOptionValues([
			{ id: 'All', name: 'All' },
			...sortedStatusOption,
		]);
	}, [leaveStatus]);

	useEffect(() => {
		if (isEmpty(orgLocations?.org_location)) {
			return;
		}
		const locationOptionList = orgLocations?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);

		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocations]);

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedStatusOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getLocationFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = () => {
		if (appliedLeaveReport?.employee_leaving_mapping?.length === 0) {
			return;
		}
		const APPLIED_LEAVE_REPORT_EXPORT = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'FROM DATE',
			'TO DATE',
			'LEAVE POLICY',
			'LEAVE TYPE',
			'LEAVE COUNT',
			'REASON',
			'STATUS',
			'APPROVER',
			'APPROVED BY',
			'APPROVER EMAIL',
			'APPLIED ON',
			'DEPARTMENT',
			'PROJECTS',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
		];

		const exportLeaveData: any | undefined =
			appliedLeaveReport?.employee_leaving_mapping?.map((leaveExport) => {
				const existingEmployeeId = leaveExport?.employee?.emp_id || ' ';
				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const empId = leaveExport?.employee?.emp_id
					? `${
							leaveExport?.employee?.employee_prefix?.name === 'N/A'
								? ''
								: leaveExport?.employee?.employee_prefix?.name
					  }${formattedEmployeeId}`
					: '--';
				const name = leaveExport?.employee?.user?.full_name
					? leaveExport?.employee?.user?.full_name
					: '--';

				const email = leaveExport?.employee?.user?.email
					? leaveExport?.employee?.user?.email
					: '--';
				const user = leaveExport?.employee?.user?.user_type
					? leaveExport?.employee?.user?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--';
				const fromDate = leaveExport?.from_date ? leaveExport?.from_date : '--';
				const toDate = leaveExport?.to_date ? leaveExport?.to_date : '--';
				const leavePolicy = leaveExport?.org_leave_type?.org_leave_group?.label
					? leaveExport?.org_leave_type?.org_leave_group?.label
					: '--';
				const leaveType = leaveExport?.org_leave_type?.leave_type
					? leaveExport?.org_leave_type?.leave_type
					: '--';
				const leaveCount = leaveExport?.leave_count
					? leaveExport?.leave_count
					: '--';
				const reason = leaveExport?.reason ? leaveExport?.reason : '--';
				const status =
					leaveExport?.employee_leave_status?.label === 'Submitted'
						? 'Pending'
						: leaveExport?.employee_leave_status?.label;
				const approver = leaveExport?.employee?.reportsTo?.full_name
					? leaveExport?.employee?.reportsTo?.full_name
					: '--';
				const approvedBy = leaveExport?.approvedBy?.full_name
					? leaveExport?.approvedBy?.full_name
					: '--';
				const approverEmail = leaveExport?.employee?.reportsTo?.email
					? leaveExport?.employee?.reportsTo?.email
					: '--';
				const appliedOn = leaveExport?.created_at
					? leaveExport?.created_at
					: '--';
				const department = leaveExport?.employee?.department?.name
					? leaveExport?.employee?.department?.name
					: '--';
				const projects =
					leaveExport?.employee?.user?.project_resource_mappings.length > 0
						? leaveExport?.employee?.user?.project_resource_mappings?.map(
								(value) => {
									return `${value?.project?.name}${
										value?.project?.is_billable === true ? '-(B)' : '-(NB)'
									}`;
								}
						  )
						: [];
				const location = leaveExport?.employee?.org_location?.name
					? leaveExport?.employee?.org_location?.name
					: '--';

				return [
					empId || '--',
					name || '--',
					email || '--',
					user || '--',
					dayjs(fromDate).format(dateFormat) || '--',
					dayjs(toDate).format(dateFormat) || '--',
					leavePolicy || '--',
					leaveType || '--',
					leaveCount || '--',
					reason || '--',
					status || '--',
					approver || '--',
					approvedBy || '--',
					approverEmail || '--',
					dayjs(appliedOn).format(dateFormat) || '--',
					department || '--',
					projects?.join(', ') || '--',
					...(noOfLocations && noOfLocations > 0 ? [location || '--'] : []),
				];
			});
		const leaveReportData = [APPLIED_LEAVE_REPORT_EXPORT, ...exportLeaveData];

		exportToCsv(
			`Applied Leave Report from ${dayjs(startDate).format(
				dateFormat
			)} To ${dayjs(endDate).format(dateFormat)}.csv`,
			leaveReportData
		);
	};

	return (
		<>
			<Box>
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							style={{ marginLeft: '20px' }}
							title='Attendance: Employee leave report'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<Box display='flex'>
							<Box marginTop={'4px'}>
								<Form onSubmit={() => {}}>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<form>
												<Box className={style.header}>
													<Box marginTop={'5px'}>
														<ThemeProvider theme={dateTimePickerTheme}>
															<Box display='flex'>
																<ErrorTooltip
																	title={startDateError ? START_DATE_ERROR : ''}
																	placement='top'
																	arrow={true}
																>
																	<Box className={style.startDate}>
																		<MuiPickersUtilsProvider
																			utils={DateFnsUtils}
																		>
																			<KeyboardDatePicker
																				variant='inline'
																				onChange={(date: any) => {
																					setPage(0);
																					setStartDateError(false);
																					if (
																						dayjs(date).isAfter(dayjs(endDate))
																					) {
																						setStartDateError(true);
																					} else {
																						setStartDateError(false);
																					}
																					setStartDate(
																						dayjs(date).format('YYYY-MM-DD')
																					);
																				}}
																				autoOk={true}
																				value={startDate}
																				format={dateFormat}
																				label='Start date'
																				error={startDateError}
																				InputLabelProps={{ shrink: true }}
																				InputProps={{
																					endAdornment: (
																						<InputAdornment position='start'>
																							<IconButton>
																								<EventIcon />
																							</IconButton>
																						</InputAdornment>
																					),
																				}}
																			/>
																		</MuiPickersUtilsProvider>
																	</Box>
																</ErrorTooltip>
																<Box className={style.endDate}>
																	<MuiPickersUtilsProvider utils={DateFnsUtils}>
																		<KeyboardDatePicker
																			variant='inline'
																			onChange={(date: any) => {
																				setPage(0);
																				setStartDateError(false);
																				if (
																					dayjs(date).isBefore(dayjs(startDate))
																				) {
																					setStartDateError(true);
																				} else {
																					setStartDateError(false);
																				}
																				setEndDate(
																					dayjs(date).format('YYYY-MM-DD')
																				);
																			}}
																			value={endDate}
																			autoOk={true}
																			format={dateFormat}
																			label='End date'
																			InputLabelProps={{ shrink: true }}
																			InputProps={{
																				endAdornment: (
																					<InputAdornment position='end'>
																						<IconButton>
																							<EventIcon />
																						</IconButton>
																					</InputAdornment>
																				),
																			}}
																		/>
																	</MuiPickersUtilsProvider>
																</Box>
															</Box>
														</ThemeProvider>
													</Box>

													<Box display='flex'>
														<Box display='block' width='170px'>
															<Box className={styles.label}>Resource</Box>
															<Box className={style.autoCompleteInputContainer}>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedResourceOptionValue &&
																		selectedResourceOptionValue?.length > 0
																			? selectedResourceOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource) => resource.id !== 'All'
																			)
																			.map((resource) => resource?.id);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedResourceOptionValues([
																				{ id: 'All', name: 'All' },
																				...resourceList,
																			]);
																			const allResourceIds = resourceList?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(allResourceIds);

																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			resourceList?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource) => resource?.id !== 'All'
																			);
																			setSelectedResourceOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(currentIds);

																			return;
																		}
																		if (
																			selectedIds?.length ===
																				resourceList?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedResourceOptionValues([
																				{ id: 'All', name: 'All' },
																				...resourceList,
																			]);
																			const responseIds = resourceList?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(responseIds);

																			return;
																		}
																		if (
																			selectedResourceOptionValue.find(
																				(option) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedResourceIdList([]);
																			setSelectedResourceOptionValues([]);

																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedResourceIdList(selectedId);

																		setSelectedResourceOptionValues(value);
																	}}
																	options={
																		resourceList && resourceList?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...resourceList,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getResourceFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Tooltip
																				title={`${option?.name || '- -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${ellipsis.ellipsis}`}
																					style={{ fontSize: '12px' }}
																				>
																					{`${option?.name || '- -'}`}
																				</Typography>
																			</Tooltip>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</Box>
														</Box>
													</Box>

													<Box
														display='block'
														width='170px'
														marginLeft={'10px'}
													>
														<Box className={styles.label}> Leave Status</Box>
														<Box className={style.autoCompleteInputContainer}>
															<Autocomplete
																multiple
																limitTags={0}
																value={
																	selectedStatusOptionValue &&
																	selectedStatusOptionValue?.length > 0
																		? selectedStatusOptionValue
																		: []
																}
																selectOnFocus={true}
																disablePortal
																fullWidth={false}
																onChange={(event, value, reason) => {
																	setPage(0);
																	if (!value) {
																		return;
																	}
																	const selectedIds = value
																		?.filter((status) => status.id !== 'All')
																		.map((status) => status?.id);
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'select-option'
																	) {
																		setSelectedStatusOptionValues([
																			{ id: 'All', name: 'All' },
																			...leaveStatusDropDown,
																		]);
																		const allStatusIds =
																			leaveStatusDropDown?.map(
																				(status) => status?.id
																			);
																		setSelectedLeaveStatusIdList(allStatusIds);

																		return;
																	}
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'remove-option' &&
																		leaveStatusDropDown?.length !==
																			selectedIds?.length
																	) {
																		const currentOptions = value?.filter(
																			(status) => status?.id !== 'All'
																		);
																		setSelectedStatusOptionValues(
																			currentOptions
																		);
																		const currentIds = currentOptions?.map(
																			(resource) => resource?.id
																		);
																		setSelectedLeaveStatusIdList(currentIds);

																		return;
																	}
																	if (
																		selectedIds?.length ===
																			leaveStatusDropDown?.length &&
																		reason === 'select-option'
																	) {
																		setSelectedStatusOptionValues([
																			{ id: 'All', name: 'All' },
																			...leaveStatusDropDown,
																		]);
																		const responseIds =
																			leaveStatusDropDown?.map(
																				(status) => status?.id
																			);
																		setSelectedLeaveStatusIdList(responseIds);

																		return;
																	}
																	if (
																		selectedStatusOptionValue.find(
																			(option) => option?.id === 'All'
																		) &&
																		reason === 'remove-option'
																	) {
																		setSelectedLeaveStatusIdList([]);
																		setSelectedStatusOptionValues([]);

																		return;
																	}
																	const selectedId = value?.map(
																		(status) => status?.id
																	);
																	setSelectedLeaveStatusIdList(selectedId);

																	setSelectedStatusOptionValues(value);
																}}
																options={
																	leaveStatusDropDown &&
																	leaveStatusDropDown?.length > 0
																		? [
																				{ id: 'All', name: 'All' },
																				...leaveStatusDropDown,
																		  ]
																		: []
																}
																disableCloseOnSelect={true}
																getLimitTagsText={getStatusFilterLimits}
																ChipProps={{ style: { display: 'none' } }}
																renderInput={(params) => (
																	<ThemeProvider
																		theme={customAutoCompleteSearchBarTheme}
																	>
																		<TextComponent
																			{...params}
																			label=''
																			placeholder={''}
																		/>
																	</ThemeProvider>
																)}
																renderOption={(option, { selected }) => (
																	<>
																		<Checkbox
																			icon={
																				<CheckBoxOutlineBlankIcon fontSize='small' />
																			}
																			checkedIcon={
																				<CheckBoxIcon fontSize='small' />
																			}
																			style={{ marginRight: 8 }}
																			checked={selected}
																			color='primary'
																		/>
																		<Tooltip
																			title={`${option?.name || '- -'}`}
																			placement='right'
																		>
																			<Typography
																				className={`${ellipsis.ellipsis}`}
																			>
																				{`${option?.name || '- -'}`}
																			</Typography>
																		</Tooltip>
																	</>
																)}
																getOptionLabel={(option) => `${option?.name}`}
																getOptionSelected={(option, value) =>
																	option.id === value.id
																}
															/>
														</Box>
													</Box>

													{noOfLocations && noOfLocations > 0 && (
														<Box
															display='block'
															width='170px'
															marginLeft={'10px'}
														>
															<Box className={styles.label}> Location</Box>
															<Box className={style.autoCompleteInputContainer}>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedLocationOptionValue &&
																		selectedLocationOptionValue?.length > 0
																			? selectedLocationOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(location) =>
																					location?.id !== 'All' &&
																					location?.id !== 'Unassigned'
																			)
																			.map((location) => location?.id);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedLocationOptionValue([
																				{ id: 'All', name: 'All' },
																				...locationDropDown,
																			]);
																			const allLocationIds =
																				locationDropDown?.map(
																					(location) => location?.id
																				);
																			setSelectedLocationIdList(allLocationIds);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			locationDropDown?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(location) => location?.id !== 'All'
																			);
																			setSelectedLocationOptionValue(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource) => resource?.id
																			);
																			setSelectedLocationIdList(currentIds);

																			return;
																		}
																		const selectedIdsWithoutUnassigned =
																			selectedLocationIdList?.filter(
																				(location) => location !== 'Unassigned'
																			);
																		if (
																			selectedIds?.length === 0 &&
																			selectedIdsWithoutUnassigned.length ===
																				0 &&
																			reason === 'select-option'
																		) {
																			setSelectedLocationOptionValue([
																				{
																					id: 'Unassigned',
																					name: 'Unassigned',
																				},
																			]);
																			const locationIds: any =
																				locationDropDown?.map(
																					(location) =>
																						location?.id === 'Unassigned'
																				);
																			setSelectedLocationIdList(locationIds);
																			return;
																		}
																		const selectedAllIds = value?.map(
																			(location) => location?.id
																		);
																		if (
																			selectedAllIds?.length ===
																				locationDropDown?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedLocationOptionValue([
																				{ id: 'All', name: 'All' },
																				...locationDropDown,
																			]);
																			const responseIds = locationDropDown?.map(
																				(location) => location?.id
																			);
																			setSelectedLocationIdList(responseIds);

																			return;
																		}
																		if (
																			selectedLocationOptionValue.find(
																				(option) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedLocationIdList([]);
																			setSelectedLocationOptionValue([]);

																			return;
																		}
																		const selectedId = value?.map(
																			(location) => location?.id
																		);
																		setSelectedLocationIdList(selectedId);
																		setSelectedLocationOptionValue(value);
																	}}
																	options={
																		locationDropDown &&
																		locationDropDown?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...locationDropDown,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getLocationFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent
																				{...params}
																				label=''
																				placeholder={''}
																			/>
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Tooltip
																				title={`${option?.name || '- -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${ellipsis.ellipsis}`}
																				>
																					{`${option?.name || '- -'}`}
																				</Typography>
																			</Tooltip>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</Box>
														</Box>
													)}

													<Box className={style.exportButtonDiv}>
														<ExportButtonWithLoader
															generateCSV={generateCSV}
															isDisabled={isEmpty(
																appliedLeaveReport?.employee_leaving_mapping ||
																	[]
															)}
														/>
													</Box>
												</Box>
											</form>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</Box>

						<CardContent>
							<Box className={style.tableContainer}>
								{!startDateError &&
								appliedLeaveReport &&
								appliedLeaveReport?.employee_leaving_mapping?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE ID
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE NAME
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														EMAIL
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														FROM DATE
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														TO DATE
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														LEAVE POLICY
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														LEAVE TYPE
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														LEAVE COUNT
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														REASON
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														STATUS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														APPROVER
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														APPROVED BY
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														APPLIED ON
													</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell className={commonStyle.headerCell}>
															LOCATION
														</CustomTableCell>
													)}
												</TableRow>
											</TableHead>

											<TableBody>
												{appliedLeaveReport &&
													appliedLeaveReport?.employee_leaving_mapping?.length >
														0 &&
													appliedLeaveReport?.employee_leaving_mapping
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((data) => {
															const employeeId = formatEmployeeId(
																data.employee?.emp_id,
																empIdLength,
																isFixedEmpIdLengthEnabled
															);
															return (
																<TableRow hover={true}>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.employee?.emp_id
																					? `${
																							data?.employee?.employee_prefix
																								?.name === 'N/A'
																								? ''
																								: data?.employee
																										?.employee_prefix?.name
																					  }${employeeId}`
																					: '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.employee?.emp_id
																					? `${
																							data?.employee?.employee_prefix
																								?.name === 'N/A'
																								? ''
																								: data?.employee
																										?.employee_prefix?.name
																					  }${employeeId}`
																					: '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.employee?.user?.full_name || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.employee?.user?.full_name ||
																					'--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.employee?.user?.email || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.employee?.user?.email || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{dayjs(data?.from_date).format(
																			dateFormat
																		) || '--'}
																	</CustomTableCell>
																	<CustomTableCell>
																		{dayjs(data?.to_date).format(dateFormat) ||
																			'--'}
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.org_leave_type?.org_leave_group
																					?.label || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.org_leave_type?.org_leave_group
																					?.label || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.org_leave_type?.leave_type || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.org_leave_type?.leave_type ||
																					'--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{data?.leave_count || '--'}
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip title={data?.reason || '--'}>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.reason || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.employee_leave_status?.label ===
																				'Submitted'
																					? 'Pending'
																					: data?.employee_leave_status
																							?.label || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.employee_leave_status?.label ===
																				'Submitted'
																					? 'Pending'
																					: data?.employee_leave_status
																							?.label || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.employee?.reportsTo?.full_name ||
																				'--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.employee?.reportsTo?.full_name ||
																					'--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.approvedBy?.full_name || '--'
																			}
																		>
																			<Typography
																				className={`${style.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.approvedBy?.full_name || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{dayjs(data?.created_at).format(
																			dateFormat
																		) || '--'}
																	</CustomTableCell>
																	{noOfLocations && noOfLocations > 0 && (
																		<CustomTableCell>
																			<Tooltip
																				title={
																					data?.employee?.org_location?.name ||
																					'--'
																				}
																			>
																				<Typography
																					className={`${style.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.employee?.org_location?.name ||
																						'--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																	)}
																</TableRow>
															);
														})}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												count={
													appliedLeaveReport?.employee_leaving_mapping
														?.length || 0
												}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={style.noData}>
										<Typography className={commonStyle.noDataLabel}>
											No data found
										</Typography>
									</Box>
								)}
							</Box>
						</CardContent>
					</Card>
				</ThemeProvider>
			</Box>
		</>
	);
};
