import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useRefresh, useNotify, useQuery } from 'react-admin';
import {
	GET_OPPORTUNITY_NOTES,
	UPSERT_OPPORTUNITY_NOTE,
	Delete_OPPORTUNITYNOTE,
} from '../Opportunities.gql';
import Notes from '../../../SharedComponents/Notes/Notes';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import { UserProfileContext } from '../../../App';

const OpportunityNotes = () => {
	const { permissions } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const [noteId, setNoteId] = useState('');
	const [searchOpportunityNotes, setSearchOpportunityNotes] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: opportunityNotes, refetch: refetchOpportunityNotes } =
		useApolloQuery(GET_OPPORTUNITY_NOTES, {
			variables: {
				opportunity_id: id ? id : null,
				SearchText: `%${searchOpportunityNotes}%`,
			},
			fetchPolicy: 'network-only',
		});

	const { data: opportunityNotesList } = useQuery({
		type: 'GET_LIST',
		resource: 'opportunity_notes',
		payload: {
			filter: {
				id: noteId ? noteId : null,
			},
		},
	});

	const [upsertOpportunityNote, { loading: isOpportunityNoteLoading }] =
		useApolloMutation(UPSERT_OPPORTUNITY_NOTE);
	const [deleteOpportunityNote] = useApolloMutation(Delete_OPPORTUNITYNOTE);

	const upsertNote = (note: NoteForm) => {
		upsertOpportunityNote({
			variables: {
				opportunityNote: {
					opportunity_id: id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Opportunity Notes Created successfully');
				refresh();
				refetchOpportunityNotes();
			}
		});
	};

	const deleteNote = (noteId: string) => {
		deleteOpportunityNote({
			variables: {
				noteId: noteId,
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Opportunity Notes Deleted successfully');
				refresh();
				refetchOpportunityNotes();
			}
		});
	};

	const saveForm = (note: NoteForm) => {
		upsertNote(note);
	};

	return (
		<>
			<Notes
				saveForm={saveForm}
				deleteNote={deleteNote}
				searchNotes={setSearchOpportunityNotes}
				noteList={opportunityNotes?.opportunity_notes}
				refetch={() => refetchOpportunityNotes()}
				noteId={setNoteId}
				getNoteList={opportunityNotesList}
				smallWidthDescription={true}
				permission={
					permissions?.opportunities?.update_permissions ? true : false
				}
				deletePermission={
					permissions?.opportunity_notes?.update_permissions?.deleted_at
						? true
						: false
				}
				loading={isOpportunityNoteLoading}
			/>
		</>
	);
};

export default OpportunityNotes;
