import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ContactForm from './ContactForm.Component';
import { modalFormTheme } from '../../Layout/Theme.component';


const useStyles = makeStyles({
	button: {
    textTransform: "none",
    minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
  },
});



const AddContactModal = (props) => {
	const { refetch } = props;
  const style=useStyles();
	const [isOpen, setIsOpen] = useState(false);

	const onModalOpen = () => {
		setIsOpen(true);
	};

	const onModalClose = () => {
		refetch()
		setIsOpen(false);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Button
				className={style.button}
				variant='contained'
				color='primary'
        onClick={onModalOpen}
        startIcon={<AddIcon/>}
			>
			  Add contacts
			</Button>
			<ContactForm onClose={onModalClose} open={isOpen} refetch={refetch}/>
		</ThemeProvider>
	); 
};

export default AddContactModal;
