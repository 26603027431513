import React, { useState, useEffect } from 'react';

import { useQuery as ApolloUseQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';

import { GET_PROJECT_OWNER, GET_PROJECT_TYPE } from './Projects.gql';
import { GET_COMPANIES } from '../Contacts/ContactService.gql';
import { Box } from '@material-ui/core';
import { ProjectOwner, ProjectType } from './Projects.modal';
import { orderBy } from 'lodash';

interface Company {
	id: string;
	name: string;
}

export const ProjectFilterFormBody = () => {
	const [searchedCompanyName, setSearchedCompanyName] = useState('');
	const [searchedProjectOwner, setSearchedProjectOwner] = useState('');
	const [companies, setCompanies] = useState(null);
	const [projectOwner, setProjectOwner] = useState<Company[]>([]);
	const [projectOwnerLimit, setProjectOwnerLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [companyFilterLimit, setCompanyFilterLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const [projectType, setProjectType] = useState([]);

	const { data: crmCompanies } = ApolloUseQuery(GET_COMPANIES, {
		variables: {
			limit: companyFilterLimit,
			company: `%${searchedCompanyName}%`,
		},
	});

	const { data: projectOwners } = ApolloUseQuery(GET_PROJECT_OWNER, {
		variables: {
			limit: projectOwnerLimit,
			name: `%${searchedProjectOwner}%`,
		},
		fetchPolicy: 'network-only',
	});

	const { data: type } = ApolloUseQuery(GET_PROJECT_TYPE);

	useEffect(() => {
		if (!projectOwners || !(projectOwners?.project_list?.length > 0)) {
			return;
		}
		const projectOwnerList = projectOwners?.project_list.map(
			(project: ProjectOwner) => {
				return {
					id: project.ProjectOwner.id,
					name: `${project.ProjectOwner?.full_name} `,
				};
			}
		);
		const sortedResponse = orderBy(
			projectOwnerList,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setProjectOwner(sortedResponse);
	}, [projectOwners]);

	useEffect(() => {
		if (!type || !type?.project_list) {
			return;
		}
		const projectsType = type?.project_list.map((value: ProjectType) => {
			return {
				id: value?.project_type,
				name: value?.project_type,
			};
		});
		const sortedProjectsType: any = orderBy(projectsType, ['name'], ['asc']);
		setProjectType(sortedProjectsType);
	}, [type]);

	useEffect(() => {
		if (!crmCompanies || !(crmCompanies?.companies?.length > 0)) {
			return;
		}
		const companyNames = crmCompanies?.companies.map((company: Company) => {
			return {
				id: company.id,
				name: company.name,
			};
		});
		setCompanies(companyNames);
	}, [crmCompanies]);

	const onCompanyExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setCompanyFilterLimit(
			isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null
		);
	};

	const onCompanySearch = (company: string) => {
		setSearchedCompanyName(company);
	};

	const onProjectOwnerSearch = (status: string) => {
		setSearchedProjectOwner(status);
	};

	const onProjectOwnerExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setProjectOwnerLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	return (
		<Box>
			<FilterInput
				label={'Company'}
				sourceName='company_id'
				onExpansionToggle={onCompanyExpansionToggle}
				choices={companies || []}
				onSearch={(name) => onCompanySearch(name)}
				total={crmCompanies?.crm_company_aggregate?.aggregate?.count}
			/>
			<FilterInput
				label={'Project Owner'}
				sourceName='project_owner_id'
				onExpansionToggle={onProjectOwnerExpansionToggle}
				choices={projectOwner || []}
				onSearch={(name) => onProjectOwnerSearch(name)}
				total={projectOwners?.project_list_aggregate?.aggregate?.count}
			/>
			<FilterInput
				label={'Project type'}
				sourceName='project_type'
				choices={projectType || []}
				onSearch={() => {}}
				total={projectType?.length}
				onExpansionToggle={() => {}}
			/>
		</Box>
	);
};
