import React, { useState } from 'react';

import {
	Box,
	Typography,
	Button,
	Drawer,
	Tooltip,
	ThemeProvider,
	CircularProgress,
	IconButton,
} from '@material-ui/core';
import {
	drawerFilterStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { documentUploadStyles, pmsViewDetailsStyle } from '../pms.styles';
import { TextInput, required, useNotify } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	useGetGoalMappingCommentQuery,
	useUpdateManagerGoalCommentMutation,
} from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import { COMMENT_UPDATED_TEXT } from '../constant';
import { head } from 'lodash';
import { preventSubsequentClick } from '../../../Utils/string.util';
import { validateGoalCommentDescription } from '../pms.utils';

interface ReviewGoalCommentProps {
	open: boolean;
	onClose: () => void;
	onCommentSaved: (value: { id: string; comment: string }) => void;
	goalMapping: {
		id: any;
		user_comment: string;
		reviewer_comment: string;
		isDisabled: boolean;
		goal: string;
		index: number;
	};
}

function ReviewGoalComment(props: ReviewGoalCommentProps) {
	const { open, onClose, goalMapping, onCommentSaved } = props;
	const [loadingState, setLoadingState] = useState<boolean>(false);
	const drawerFilterStyles = drawerFilterStyle();
	const formStyles = modalFormStyle();
	const pmsDetailsStyles = pmsViewDetailsStyle();
	const customStyles = documentUploadStyles();
	const ellipsis = ellipsisStyle();
	const notify = useNotify();

	// API
	const {
		data: goalData,
		loading: isGoalMappingDataLoading,
		refetch: refetchGoalMapping,
	} = useGetGoalMappingCommentQuery({
		variables: {
			goalId: goalMapping.id,
		},
		fetchPolicy: 'network-only',
	});
	const [updateManagerGoalComment, { loading: isUpdateCommentLoading }] =
		useUpdateManagerGoalCommentMutation();

	const handleSubmit = (formValue: {
		user_comment: string;
		reviewer_comment: string;
	}) => {
		updateManagerGoalComment({
			variables: {
				goalMappingId: goalMapping.id,
				manager_comment: formValue?.reviewer_comment
					? formValue.reviewer_comment.trim()
					: null,
			},
		}).then((res) => {
			if (!res.errors) {
				notify(COMMENT_UPDATED_TEXT);
				onCommentSaved({
					id: goalMapping.id,
					comment: formValue?.reviewer_comment,
				});
				refetchGoalMapping();
			}
		});
	};
	const getFileName = () => {
		if (!goalData) {
			return;
		}
		const fileName =
			head(goalData?.pms_pms_appraisal_goal_mapping)?.file_name || '';
		return fileName || 'File';
	};

	const disableButton = () => {
		setLoadingState(false);
	};

	const handleDownload = () => {
		setLoadingState(true);
		let link = document.createElement('a');
		link.href = head(goalData?.pms_pms_appraisal_goal_mapping)?.doc_url || '';
		preventClick();
		link.click();
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	return (
		<Drawer open={open} anchor='right' onClose={onClose}>
			<Box
				className={drawerFilterStyles.drawerContainer}
				style={{
					width: '500px',
				}}
			>
				<Box display='flex' flexDirection='column'>
					<Box className={drawerFilterStyles.HeaderSection}>
						<Typography className={drawerFilterStyles.drawerHeading}>
							Comment For Goal
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</Box>
				</Box>
				<ThemeProvider theme={modalFormTheme}>
					{isGoalMappingDataLoading ? (
						<Box className={drawerFilterStyles.noData}>
							<Box className={drawerFilterStyles.loading}>Loading</Box>
							<CircularProgress />
						</Box>
					) : (
						<Form
							onSubmit={handleSubmit}
							initialValues={{
								user_comment:
									head(goalData?.pms_pms_appraisal_goal_mapping)
										?.user_comment || '',
								reviewer_comment:
									head(goalData?.pms_pms_appraisal_goal_mapping)
										?.reviewer_comment || '',
							}}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<div>
										<div
											style={{
												marginTop: '20px',
												marginBottom: '20px',
											}}
										>
											<Tooltip title={goalMapping.goal} placement='top'>
												<div
													className={`${pmsDetailsStyles.goal} ${ellipsis.ellipsis}`}
													style={{
														fontSize: '16px',
														width: '400px',
													}}
												>
													{goalMapping.goal}
												</div>
											</Tooltip>
										</div>

										<div
											style={{
												marginBottom: '20px',
											}}
										>
											<p className={formStyles.label}>Employee Comments</p>
											<TextInput
												source='user_comment'
												fullWidth={true}
												label={false}
												validate={
													goalMapping?.isDisabled ? [required()] : undefined
												}
												multiline
												disabled={true}
											/>
										</div>
										{head(goalData?.pms_pms_appraisal_goal_mapping)
											?.doc_url && (
											<Box marginBottom='30px'>
												<Typography className={customStyles.heading}>
													Uploaded Documents
												</Typography>
												<Box
													display='flex'
													alignItems='center'
													justifyContent={'flex-start'}
													className={customStyles.document}
												>
													<Box>
														<IconButton
															color='primary'
															onClick={() => {}}
															aria-label='download'
														>
															<DescriptionIcon className={customStyles.icon} />
														</IconButton>
													</Box>
													<Box className={customStyles.fileName}>
														{getFileName()}
													</Box>
													<Box
														display='flex'
														className={customStyles.actionContainer}
													>
														<Box className={customStyles.actions}>
															<Tooltip title='Download'>
																<IconButton
																	className={customStyles.downloadIconContainer}
																	color='primary'
																	onClick={() => {}}
																	aria-label='download'
																>
																	{loadingState ? (
																		<CircularProgress
																			className={customStyles.icon}
																			style={{
																				height: '20px',
																				width: '20px',
																				color: '#4285F4',
																			}}
																		/>
																	) : (
																		<GetAppIcon
																			onClick={handleDownload}
																			className={customStyles.downloadIcon}
																		/>
																	)}
																</IconButton>
															</Tooltip>
														</Box>
													</Box>
												</Box>
											</Box>
										)}
										<div
											style={{
												marginBottom: '20px',
											}}
										>
											<p className={formStyles.label}>Manager Comments</p>
											<TextInput
												source='reviewer_comment'
												fullWidth={true}
												label={false}
												validate={validateGoalCommentDescription}
												multiline
												disabled={goalMapping?.isDisabled}
											/>
										</div>
									</div>

									<Box className={drawerFilterStyles.buttonContainer}>
										<Box mb={'10px'}>
											<Button
												className={
													invalid ||
													pristine ||
													goalMapping?.isDisabled ||
													isUpdateCommentLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												variant='outlined'
												color='primary'
												type='submit'
												disabled={
													invalid ||
													pristine ||
													goalMapping?.isDisabled ||
													isUpdateCommentLoading
												}
												startIcon={isUpdateCommentLoading && <Loader />}
											>
												Save
											</Button>
										</Box>
										<Box mb={'10px'}>
											<Button
												onClick={() => onClose()}
												className={drawerFilterStyles.cancelButton}
												variant='outlined'
											>
												Cancel
											</Button>
										</Box>
									</Box>
								</form>
							)}
						</Form>
					)}
				</ThemeProvider>
			</Box>
		</Drawer>
	);
}

export default ReviewGoalComment;
