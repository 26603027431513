import 'antd/dist/antd.css';
import React from 'react';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { insightChartTheme } from '../../Layout/Theme.component';
import { Spin } from 'antd';

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div style={{ background: '#F7F9FA' }}>
				<p>{label}</p>
				<p>{`Count : ${payload[0].value}`}</p>
			</div>
		);
	}

	return null;
};

export const EmployeeResignationReport = (props) => {
	const { chartData, loading } = props;

	return (
		<div>
			{loading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Employee Attrition Report'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer width='100%' height={355}>
								{chartData && chartData?.length > 0 ? (
									<BarChart data={chartData}>
										<XAxis
											dataKey='name'
											dy={20}
											tick={{ fontSize: '11px', fill: '#43425D' }}
											axisLine={false}
											tickLine={false}
										/>
										<YAxis
											dx={-10}
											tick={{ fontSize: '11px', fill: '#43425D' }}
											axisLine={false}
											tickLine={false}
										/>
										<CartesianGrid vertical={false} stroke='#EAF0F4' />
										<Bar
											dataKey='Employee Count'
											fill='#196CF5'
											barSize={10}
											radius={[10, 10, 0, 0]}
										/>
										<Legend
											iconSize={16}
											wrapperStyle={{
												fontSize: '12px',
												fontFamily: 'Manrope-semibold',
												color: '#5C5C5C',
												paddingTop: '50px',
											}}
										/>
										<Tooltip
											cursor={{ fill: 'transparent' }}
											content={<CustomTooltip />}
										/>
									</BarChart>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
