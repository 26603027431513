import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
	Box,
	Card,
	CardContent,
	ThemeProvider,
	TextField as TextComponent,
	Table,
	TableBody,
	TableHead,
	TableRow,
	createStyles,
	TableCell,
	withStyles,
	TableContainer,
	Typography,
	Theme,
	makeStyles,
	Tooltip,
	Checkbox,
	createTheme,
} from '@material-ui/core';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import { Form } from 'react-final-form';
import {
	Loading,
	SelectInput,
	NumberInput,
	TextInput,
	minValue,
} from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import {
	useGetGeneralTimesheetQuery,
	useGetTimesheetProjectAssignedUsersQuery,
	useGetOrgHolidaysQuery,
	useGetResourceLeaveQuery,
	useGetGeneralOvertimeQuery,
	useGetOvertimeByProjectIdQuery,
	useGetProjectResourceMappingListQuery,
	useGetTimesheetDayTypeOptionsQuery,
	useGetDraftAndSavedTimesheetByProjectIdQuery,
	useGetGeneralDraftAndSavedTimesheetForDaywiseQuery,
	useGetTimesheetStatusQuery,
	useGetOrganizationWeekendsQuery,
} from '../../../generated/graphql';
import TablePagination from '@material-ui/core/TablePagination';
import { head, isEmpty, orderBy, uniqBy } from 'lodash';
import {
	formatEmployeeId,
	exportToCsv,
	formatDecimal,
	getPrefix,
	isFloat,
} from '../../../Utils/string.util';
import { uuidValidator } from '../constant';
import {
	autocompleteStyle,
	dropDownEllipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_TIMESHEET_BY_PROJECT } from '../Projects.gql';
import { UserProfileContext } from '../../../App';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../../ResourcePlanner/gqlQueries';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { calculateTotalHoursFromTimesheet } from './insight.utils';
import {
	getSaturdaysDates,
	getSundaysBetweenDates,
} from '../../../Utils/date-time.util';
import ExportButtonWithDropDown from '../../../SharedComponents/ExportButton/ExportButtonWithDropDown';
import { exportTimesheetReportUsersOptions } from '../../../config/constant';
const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '10px',
			padding: '12px',
		},
	})
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	exportButton: {
		marginTop: '22px',
		marginRight: '10px',
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	exportButtonDiv: {
		marginTop: '23px',
	},
	dateCell: {
		display: 'block',
		fontSize: '10px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		fontFamily: 'Manrope-semibold',
	},
	weekEndCell: {
		color: '#e63a2e',
	},
	timeSheetWithDate: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	timeSheetWithDateWithLeave: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#e63a2e',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	timeSheetWithoutDate: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	headerCell: {
		minWidth: '120px',
		padding: '12px',
	},
	circle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		visibility: 'hidden',
	},
	holidayCircle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	leaveCircle: {
		height: '16px',
		width: '16px',
		backgroundColor: '#FFEAC2',
		borderRadius: '50%',
		marginBottom: '1px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	monthCell: {
		width: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '200px',
		marginRight: '8px',
	},
	overflowStyle: {
		overflow: 'auto',
	},
}));

const filterMetricInputTheme = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				border: '1px solid #E0E0E0',
				width: '100px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '13px',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});

interface TotalHrs {
	totalHours: number | null;
	billableHours: number | null;
	nonBillableHours: number | null;
}

interface Props {
	projectId: string[];
	startDate: string;
	endDate: string;
	setUserId?: any;
	choosedResourceId: (value: string[] | null) => void;
	billableType: (value: string | null) => void;
	taskType: (value: string | null) => void;
	dayType: (value: string[] | null) => void;
	choosedTimesheetStatus: (value: string[]) => void;
	setTotalHours: (value: TotalHrs) => void;
}
interface Resource {
	id: string;
	name: string;
}

export interface User {
	first_name?: string | null;
	last_name?: string | null;
	id?: string;
}
export interface TeamMember {
	userByEmployeeId?: User;
}
export interface ProjectTask {
	title?: string;
	id?: string;
}

export interface TimesheetStatus {
	label?: string;
	id?: string;
}

export interface TimesheetSubmission {
	timesheet_status?: TimesheetStatus;
}

export interface Currency {
	symbol?: string | null;
}

export interface Project {
	currency?: Currency | null;
}

export interface ProjectResourceMapping {
	id?: string;
	resource_cost?: number;
	project?: Project;
}

export interface UserByEmployeeId {
	full_name?: string | null;
	project_resource_mappings?: ProjectResourceMapping[];
}
export interface Project {
	name?: string;
}
export interface TimeSheet {
	id?: string;
	task_id?: string;
	is_billable?: boolean;
	working_hours?: string;
	date?: string;
	project_task?: ProjectTask | null;
	timesheet_submission?: TimesheetSubmission | null;
	userByEmployeeId?: UserByEmployeeId | null;
	project?: Project;
}
interface TimesheetTableList {
	reportToEmail?: string;
	department?: string;
	reportTo?: string;
	projectName?: string;
	taskName?: string;
	user?: string | null | undefined;
	workingHours?: string;
	date?: string;
	timesheetStatus?: string;
	resourceCost?: string;
	totalCost?: string | number;
	id?: any;
	userId?: any;
	notes?: string;
	timesheet?: any[];
	totalHours: number;
	currencySymbol?: string;
	org_location: string | null | undefined;
	contractors: any[];
	employee: string;
	user_type: string;
	empId: string | null | undefined;
	employeeType: string | null | undefined;
	email: string | null | undefined;
	isUserActive: boolean;
	leaveDateArray: any[];
}

export const TimesheetDayWiseReport = (props: Props) => {
	const {
		projectId,
		startDate,
		endDate,
		choosedResourceId,
		billableType,
		taskType,
		dayType,
		choosedTimesheetStatus,
		setTotalHours,
	} = props;
	const {
		isOvertimeEnabled,
		dateFormat,
		featureFlag,
		noOfLocations,
		isTrackDayTypeEnabled,
		isDraftTimesheetEnabled,
		empIdLength,
		orgId,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);
	const [billableStatus, setBillableStatus] = useState<string>('All');
	const [timesheetType, setTimesheetType] = useState<string>('All');
	const [totalHourValue, setTotalHourValue] = useState<any>();

	useEffect(() => {
		if (!totalHourValue) {
			return;
		}
		setTotalHours(totalHourValue);
	}, [setTotalHours, totalHourValue]);

	const [totalHoursFilterValue, setTotalHoursFiltervalue] = useState<
		number | null
	>(null);
	const [totalHoursFilterOperator, setTotalHoursFilterOperator] = useState<
		string | null
	>(null);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const ellipsis = dropDownEllipsisStyle();
	const classes = useStyles();
	const [projectIdList, setProjectIdList] = useState<any>();
	const [timeSheetTableList, setTimeSheetTableList] = useState<
		TimesheetTableList[]
	>([]);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<any[]>(
		[]
	);
	const [monthList, setMonthList] = useState<any[]>([]);
	const styles = modalFormStyle();
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		string[]
	>([]);
	const [dayTypeOptions, setDayTypeOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [selectedDayTypeOptionValue, setSelectedDayTypeOptionValues] =
		useState<any>([]);
	const [selectedDayTypeIdList, setSelectedDayTypeIdList] = useState<string[]>(
		[]
	);
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [
		selectedTimesheetStatusOptionValue,
		setSelectedTimesheetStatusOptionValues,
	] = useState<any>([]);
	const [selectedTimesheetStatusIdList, setSelectedTimesheetStatusIdList] =
		useState<string[]>([]);
	const dropDownStyle = autocompleteStyle();

	useEffect(() => {
		if (!startDate && !endDate) {
			return;
		}
		const dateList = [];
		let currentDate = dayjs(startDate);
		const stopDate = dayjs(endDate);
		while (currentDate <= stopDate) {
			dateList.push({
				date: dayjs(currentDate).format('YYYY-MM-DD'),
				day: dayjs(currentDate).date(),
				month: dayjs(currentDate).format('MMM'),
			});
			currentDate = dayjs(currentDate).add(1, 'day');
		}
		setMonthList(dateList);
	}, [startDate, endDate]);

	useEffect(() => {
		if (!projectId) {
			return;
		}
		setPage(0);
		if (projectId && projectId?.length === 0) {
			setBillableStatus('All');
			setUserTeamId(null);
			setSelectedUserTeam(null);
			setSelectedResourceIdList([]);
			setSelectedResourceOptionValues([]);
		}
		setSelectedResourceIdList([]);
		setSelectedResourceOptionValues([]);
		const idList = projectId?.filter((item) => uuidValidator.test(item));
		setProjectIdList(idList);
	}, [projectId]);

	const getDayTypePayload = () => {
		if (selectedDayTypeIdList && selectedDayTypeIdList?.length > 0) {
			const selectedOptionList =
				selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length > 0
					? selectedDayTypeOptionValue?.map(
							(option: { id: string }) => option?.id
					  )
					: [];
			if (selectedOptionList?.includes('All')) {
				return {};
			} else return { _in: selectedDayTypeIdList };
		}
		return {};
	};
	const { data: organizationWeekendData } = useGetOrganizationWeekendsQuery({
		variables: { orgId },
		fetchPolicy: 'network-only',
	});

	const { data: leaveList } = useGetResourceLeaveQuery({
		variables: {
			startDate: dayjs(startDate).format('YYYY-MM-DD'),
			endDate: dayjs(endDate).format('YYYY-MM-DD'),
		},
	});
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const { data: timeSheetList, loading: isTimesheetListLoading } =
		useApolloQuery(GET_TIMESHEET_BY_PROJECT, {
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					timesheet_submission: {
						timesheet_status: {
							value:
								selectedTimesheetStatusIdList &&
								selectedTimesheetStatusIdList.length > 0
									? {
											_in: selectedTimesheetStatusIdList.filter(
												(status) => status !== 'draft'
											),
									  }
									: { _in: ['approved', 'submitted'] },
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				optionalHolidayFilter:
					startDate && endDate
						? {
								org_holiday: {
									date: {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
									},
								},
						  }
						: {},
				projectId: projectIdList,
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
					employee_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
				},
			},
			fetchPolicy: 'network-only',
		});
	const { data: overtimeList } = useGetOvertimeByProjectIdQuery({
		variables: {
			filters: {
				project_id: { _in: projectIdList },
				day_type_id: getDayTypePayload(),
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
			},
			optionalHolidayFilter:
				startDate && endDate
					? {
							org_holiday: {
								date: {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
								},
							},
					  }
					: {},
			projectId: projectIdList,
			projectResourceMappingFilter: {
				project_id: { _in: projectIdList },
				deleted_at: { _is_null: true },
				employee_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
			},
		},
		skip: !isOvertimeEnabled,
		fetchPolicy: 'network-only',
	});

	const { data: generalTimeSheet, loading: isGeneralTimeSheetLoading } =
		useGetGeneralTimesheetQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: {
							value:
								selectedTimesheetStatusIdList &&
								selectedTimesheetStatusIdList.length > 0
									? {
											_in: selectedTimesheetStatusIdList.filter(
												(status) => status !== 'draft'
											),
									  }
									: { _in: ['approved', 'submitted'] },
						},
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				optionalHolidayFilter:
					startDate && endDate
						? {
								org_holiday: {
									date: {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
									},
								},
						  }
						: {},
			},
			fetchPolicy: 'network-only',
		});

	const { data: generalOvertime } = useGetGeneralOvertimeQuery({
		variables: {
			filters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				day_type_id: getDayTypePayload(),
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
			},
			optionalHolidayFilter:
				startDate && endDate
					? {
							org_holiday: {
								date: {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
								},
							},
					  }
					: {},
		},
		fetchPolicy: 'network-only',
	});

	const { data: generalTaskAssigneeList } =
		useGetTimesheetProjectAssignedUsersQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: selectedTimesheetStatusIdList || ['submitted', 'approved'],
							},
						},
					},
				},
			},
		});

	const { data: holidays } = useGetOrgHolidaysQuery({
		variables: {
			startDate: dayjs(startDate).format('YYYY-MM-DD'),
			endDate: dayjs(endDate).format('YYYY-MM-DD'),
		},
		fetchPolicy: 'network-only',
	});

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});
	const { data: projectAssignedResourceList } =
		useGetProjectResourceMappingListQuery({
			variables: {
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
				},
			},
		});
	const { data: dayTypeOptionList } = useGetTimesheetDayTypeOptionsQuery({
		fetchPolicy: 'network-only',
		skip: !isTrackDayTypeEnabled,
	});

	// Get timesheet which are in unsaved and draft status
	const { data: timeSheetDraftAndSavedList } =
		useGetDraftAndSavedTimesheetByProjectIdQuery({
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					timesheet_submission: {
						timesheet_status: { value: { _in: ['draft'] } },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				optionalHolidayFilter:
					startDate && endDate
						? {
								org_holiday: {
									date: {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
									},
								},
						  }
						: {},
				draftFilters: {
					project_id: { _in: projectIdList },
					timesheet_submission_id: { _is_null: true },
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				projectId: projectIdList,
			},
			fetchPolicy: 'network-only',
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});
	// Get general timesheet which are in unsaved and draft status
	const { data: generalDraftAndSavedTimeSheet } =
		useGetGeneralDraftAndSavedTimesheetForDaywiseQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: { value: { _in: ['draft'] } },
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				optionalHolidayFilter:
					startDate && endDate
						? {
								org_holiday: {
									date: {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
									},
								},
						  }
						: {},
				draftFilters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission_id: {
						_is_null: true,
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
			},
			fetchPolicy: 'network-only',
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});
	useEffect(() => {
		if (!dayTypeOptionList) {
			return;
		}
		const dayTypeList = dayTypeOptionList?.timesheet_day_type;
		const sortedAssigneeList = orderBy(
			dayTypeList,
			[(type) => type.name?.toUpperCase()],
			['asc']
		);
		setDayTypeOptions(sortedAssigneeList);
	}, [dayTypeOptionList]);

	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	useEffect(() => {
		if (!projectAssignedResourceList || !generalTaskAssigneeList) {
			return;
		}
		if (selectedUserTeam) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: { user: { id: string; full_name: string } }) => {
					return {
						id: user?.user?.id,
						name: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList = orderBy(
				useTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			setAssigneeRecommendations(sortedAssigneeList);
			return;
		}
		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const uniqueAssignees = uniqBy(
			[...projectAssignedResources, ...orgTaskAssigneeList],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [
		projectAssignedResourceList,
		generalTaskAssigneeList,
		selectedUserTeam,
		usersByTeam,
	]);

	useEffect(() => {
		if (!timesheetStatus) {
			return;
		}
		const statusList = timesheetStatus?.timesheet_status.map((timesheet) => {
			return {
				id: timesheet.value,
				name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
			};
		});
		setSelectedTimesheetStatusOptionValues([
			{
				id: 'submitted',
				name: 'Pending',
			},
			{
				id: 'approved',
				name: 'approved',
			},
		]);
		setSelectedTimesheetStatusIdList(['submitted', 'approved']);
		const sortedStatusList = orderBy(
			statusList,
			[(status) => status.name?.toUpperCase()],
			['asc']
		);
		setTimesheetStatusOptions(sortedStatusList);
	}, [timesheetStatus]);

	const getLeaveDetailsOfEmployee = useCallback(
		(employeeId: string | null) => {
			if (!employeeId) {
				return;
			}
			const employeeLeaveData = !isEmpty(leaveList?.employee_leaving_mapping)
				? leaveList?.employee_leaving_mapping?.filter(
						(leave) => leave?.employee_id === employeeId
				  )
				: [];
			return employeeLeaveData;
		},
		[leaveList]
	);

	const getEmployeeLeaveDates = useCallback(
		(
			locationId: string | null,
			leaveDatesArrayList: {
				date: string;
				label: any;
				isExcluded: boolean;
			}[],
			optionalHolidayArrayList: { date: string }[]
		) => {
			const userLocationId = locationId || null;
			const isSundayAvailable =
				organizationWeekendData?.organization_by_pk?.weekend?.includes('SUN') ||
				false;
			const sundayAsWeekend = getSundaysBetweenDates(startDate, endDate);
			const availableSaturdays =
				organizationWeekendData?.organization_by_pk?.weekend
					?.filter((weekendDay: string) => weekendDay !== 'SUN')
					?.map((sat: string) => sat?.charAt(sat.length - 1));
			const allSaturdaysBetweenDates = getSaturdaysDates(startDate, endDate);
			const saturdayAsWeekend =
				allSaturdaysBetweenDates?.map((day) =>
					availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
				) || [];
			const userRegularHolidayList =
				noOfLocations && noOfLocations > 0
					? userLocationId
						? holidays?.org_holidays
								?.filter(
									(holiday) => holiday?.org_location?.id === userLocationId
								)
								.map((holiday) => {
									return {
										date: holiday?.date,
									};
								})
						: []
					: holidays?.org_holidays?.map((holiday) => {
							return {
								date: holiday?.date,
							};
					  }) || [];
			const userOptionalHolidayList =
				noOfLocations && noOfLocations > 0
					? userLocationId
						? optionalHolidayArrayList
						: []
					: optionalHolidayArrayList;
			const userRegularAndOptionalHolidays =
				[...(userRegularHolidayList || []), ...userOptionalHolidayList] || [];
			const userHolidayDateList =
				userRegularAndOptionalHolidays?.map((holiday) => holiday?.date) || [];
			const userleaveDatesList =
				leaveDatesArrayList?.filter((leaveDate) => {
					const isExcludedType = leaveDate?.isExcluded;
					const date = leaveDate?.date;
					if (!isExcludedType) {
						return leaveDate;
					}
					const isHoliday = userHolidayDateList?.includes(date);
					const isSunday = sundayAsWeekend?.includes(date);
					const isSaturday = saturdayAsWeekend.includes(date);
					if (
						(isSundayAvailable && !isHoliday && !isSunday && !isSaturday) ||
						(!isSundayAvailable && !isHoliday && !isSaturday)
					) {
						return leaveDate;
					}
				}) || [];
			return userleaveDatesList || [];
		},
		[endDate, holidays, noOfLocations, organizationWeekendData, startDate]
	);
	useEffect(() => {
		if (!timeSheetList || !generalTimeSheet) {
			return;
		}
		const timeSheetTableData = timeSheetList?.timesheet?.map(
			(timeSheet: any) => {
				const existingEmployeeId =
					timeSheet?.userByEmployeeId?.employee?.emp_id || '';
				const existingContractorId =
					timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.emp_id
							? `${
									getPrefix(
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.name,
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
						? `${
								getPrefix(
									timeSheet?.userByEmployeeId?.contractors[0]?.contractor_prefix
										?.name,
									timeSheet?.userByEmployeeId?.contractors[0]?.contractor_prefix
										?.value
								) || ''
						  }${
								timeSheet?.userByEmployeeId?.contractors &&
								timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';
				const leaveEmployeeId =
					timeSheet?.userByEmployeeId?.employee?.id || null;
				const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
				const userLocationId =
					timeSheet?.userByEmployeeId.user_type === 'employee'
						? timeSheet?.userByEmployeeId.employee?.org_location?.id
						: timeSheet?.userByEmployeeId.contractors[0]?.org_location?.id;
				return {
					id: timeSheet.id,
					isUserActive: timeSheet?.userByEmployeeId?.is_active,
					billableStatus: timeSheet?.is_billable,
					projectName: timeSheet?.project?.name,
					projectId: timeSheet?.project?.id,
					taskName: getTaskNameByProjectType(timeSheet) || '',
					user: timeSheet.userByEmployeeId?.full_name,
					userId: timeSheet.userByEmployeeId.id,
					notes: timeSheet?.notes || '',
					employeeId: formattedEmployeeId || null,
					workingHours: String(timeSheet?.working_hours).slice(0, 5),
					date: timeSheet?.date,
					email: timeSheet?.userByEmployeeId?.email || '',
					empId: employeeId,
					leaveEmployeeId: leaveEmployeeId,
					leaveDateArray: leaveDateArray,
					userLocationId: userLocationId || null,
					optionalHolidayArray:
						timeSheet?.userByEmployeeId?.user_optional_holiday_mappings || [],
					reportTo:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
							: timeSheet?.userByEmployeeId?.contractors
							? timeSheet.userByEmployeeId.contractors[0]?.reportee?.full_name
							: null,
					reportToEmail:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
							: timeSheet?.userByEmployeeId?.contractors
							? timeSheet.userByEmployeeId.contractors[0]?.reportee?.email
							: null,
					department:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.department?.name
							: timeSheet?.userByEmployeeId?.contractors
							? timeSheet.userByEmployeeId.contractors[0]?.department?.name
							: null,

					org_location:
						timeSheet?.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.org_location?.name
							: timeSheet?.userByEmployeeId.contractors[0]?.org_location?.name,

					timesheetStatus:
						timeSheet?.timesheet_submission?.timesheet_status?.label || '--',
					resourceCost: timeSheet?.userByEmployeeId
						?.project_resource_mappings_aggregate.aggregate?.max?.resource_cost
						? `${
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
									? timeSheet?.project?.currency?.symbol || ''
									: '--'
						  }${
								isFloat(
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
								)
									? formatDecimal(
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost,
											1
									  )
									: timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost || '--'
						  }`
						: '--',
					cost:
						timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
							.aggregate?.max?.resource_cost || '',
					totalCost: timeSheet?.userByEmployeeId
						?.project_resource_mappings_aggregate.aggregate?.max?.resource_cost
						? `${
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
									? timeSheet?.project?.currency?.symbol
									: ''
						  }${
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
									? getTotalCost(
											String(timeSheet?.working_hours).slice(0, 5),
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
									  )
									: '--'
						  }`
						: '--',
					currencySymbol: timeSheet?.project?.currency?.symbol || '',
					employeeType: timeSheet?.userByEmployeeId?.user_type
						? timeSheet?.userByEmployeeId?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
				};
			}
		);

		const overtimeData =
			isOvertimeEnabled && overtimeList
				? overtimeList?.timesheet_overtime?.map((timeSheet) => {
						const existingEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.emp_id || '';
						const existingContractorId =
							timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id || '';
						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.userByEmployeeId?.user_type === 'employee'
								? timeSheet?.userByEmployeeId?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.name,
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.name,
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.value
										) || ''
								  }${
										timeSheet?.userByEmployeeId?.contractors &&
										timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						const leaveEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.id || null;
						const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
						const userLocationId =
							timeSheet?.userByEmployeeId.user_type === 'employee'
								? timeSheet?.userByEmployeeId.employee?.org_location?.id
								: head(timeSheet?.userByEmployeeId.contractors)?.org_location
										?.id;
						return {
							id: timeSheet.id,
							isUserActive: timeSheet?.userByEmployeeId?.is_active,
							billableStatus: timeSheet?.is_billable,
							projectName: timeSheet?.project?.name,
							projectId: timeSheet?.project?.id,
							taskName: getTaskNameByProjectType(timeSheet) || '',
							user: timeSheet.userByEmployeeId?.full_name,
							userId: timeSheet.userByEmployeeId.id,
							notes: timeSheet?.notes || '',
							employeeId: formattedEmployeeId || null,
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							date: timeSheet?.date,
							email: timeSheet?.userByEmployeeId?.email || '',
							empId: employeeId,
							leaveEmployeeId: leaveEmployeeId,
							leaveDateArray: leaveDateArray,
							userLocationId: userLocationId || null,
							optionalHolidayArray:
								timeSheet?.userByEmployeeId?.user_optional_holiday_mappings ||
								[],
							reportTo:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
									: timeSheet?.userByEmployeeId?.contractors
									? timeSheet.userByEmployeeId?.contractors[0]?.reportee
											?.full_name
									: null,
							reportToEmail:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
									: timeSheet?.userByEmployeeId?.contractors
									? timeSheet.userByEmployeeId?.contractors[0]?.reportee?.email
									: null,
							department:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.department?.name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet?.userByEmployeeId?.contractors)?.department
											?.name
									: null,

							org_location:
								timeSheet?.userByEmployeeId.user_type === 'employee'
									? timeSheet?.userByEmployeeId.employee?.org_location?.name
									: head(timeSheet?.userByEmployeeId.contractors)?.org_location
											?.name,
							timesheetStatus:
								timeSheet?.timesheet_overtime_submission?.timesheet_status
									?.label || '--',
							resourceCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol || ''
											: ''
								  }${
										isFloat(
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
										)
											? formatDecimal(
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost,
													1
											  )
											: timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost || '--'
								  }`
								: '--',
							cost:
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost || '',
							totalCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol
											: ''
								  }${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? getTotalCost(
													String(timeSheet?.working_hours).slice(0, 5),
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost
											  )
											: '--'
								  }`
								: '--',
							currencySymbol: timeSheet?.project?.currency?.symbol || '',
							employeeType: timeSheet?.userByEmployeeId?.user_type
								? timeSheet?.userByEmployeeId?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
						};
				  })
				: [];

		const generalTimesheetData = generalTimeSheet?.org_tasks.map((task) => {
			const timeSheetData = task?.timesheets?.map((timeSheet) => {
				const existingEmployeeId =
					timeSheet?.userByEmployeeId?.employee?.emp_id || '';
				const existingContractorId =
					head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.emp_id
							? `${
									getPrefix(
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.name,
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.name,
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.value
								) || ''
						  }${
								timeSheet?.userByEmployeeId?.contractors &&
								head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';
				const leaveEmployeeId =
					timeSheet?.userByEmployeeId?.employee?.id || null;
				const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
				const userLocationId =
					timeSheet?.userByEmployeeId.user_type === 'employee'
						? timeSheet?.userByEmployeeId.employee?.org_location?.id
						: head(timeSheet?.userByEmployeeId.contractors)?.org_location?.id;
				return {
					id: timeSheet.id,
					isUserActive: timeSheet?.userByEmployeeId?.is_active,
					billableStatus: timeSheet?.is_billable,
					projectName: task.task_category || '',
					projectId: task.task_category || '',
					taskName: task.title || '',
					user: timeSheet.userByEmployeeId?.full_name,
					userId: timeSheet.userByEmployeeId.id,
					date: timeSheet?.date,
					employeeId: formattedEmployeeId || null,
					notes: timeSheet?.notes || '',
					email: timeSheet?.userByEmployeeId?.email || '',
					empId: employeeId,
					leaveEmployeeId: leaveEmployeeId,
					leaveDateArray: leaveDateArray || [],
					userLocationId: userLocationId || null,
					optionalHolidayArray:
						timeSheet?.userByEmployeeId?.user_optional_holiday_mappings || [],
					reportTo:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet?.userByEmployeeId?.contractors)?.reportee
									?.full_name
							: null,
					reportToEmail:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet?.userByEmployeeId?.contractors)?.reportee?.email
							: null,
					department:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.department?.name
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet?.userByEmployeeId?.contractors)?.department?.name
							: null,
					org_location:
						timeSheet?.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.org_location?.name
							: head(timeSheet?.userByEmployeeId.contractors)?.org_location
									?.name,

					workingHours: String(timeSheet?.working_hours).slice(0, 5),
					timesheetStatus:
						timeSheet?.timesheet_submission?.timesheet_status?.label || '--',
					resourceCost: `--`,
					cost: 0,
					totalCost: `--`,
					currencySymbol: '',
					employeeType: timeSheet?.userByEmployeeId?.user_type
						? timeSheet?.userByEmployeeId?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
				};
			});
			return timeSheetData;
		});

		const generalOvertimeData =
			isOvertimeEnabled && generalOvertime
				? generalOvertime?.org_tasks.map((task) => {
						const timeSheetData = task?.timesheet_overtime?.map((timeSheet) => {
							const existingEmployeeId =
								timeSheet?.userByEmployeeId?.employee?.emp_id || '';
							const existingContractorId =
								head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id ||
								'';
							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.name,
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.name,
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.value
											) || ''
									  }${
											timeSheet?.userByEmployeeId?.contractors &&
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							const leaveEmployeeId =
								timeSheet?.userByEmployeeId?.employee?.id || null;
							const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
							const userLocationId =
								timeSheet?.userByEmployeeId.user_type === 'employee'
									? timeSheet?.userByEmployeeId.employee?.org_location?.id
									: head(timeSheet?.userByEmployeeId.contractors)?.org_location
											?.id;
							return {
								id: timeSheet.id,
								isUserActive: timeSheet?.userByEmployeeId?.is_active,
								billableStatus: timeSheet.is_billable,
								projectName: task.task_category || '',
								projectId: task.task_category || '',
								taskName: task.title || '',
								user: timeSheet.userByEmployeeId?.full_name,
								userId: timeSheet.userByEmployeeId.id,
								date: timeSheet?.date,
								email: timeSheet?.userByEmployeeId?.email || '',
								employeeType: timeSheet?.userByEmployeeId?.user_type
									? timeSheet?.userByEmployeeId?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								leaveEmployeeId: leaveEmployeeId,
								leaveDateArray: leaveDateArray || [],
								userLocationId: userLocationId,
								optionalHolidayArray:
									timeSheet?.userByEmployeeId?.user_optional_holiday_mappings ||
									[],
								reportTo:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo
												?.full_name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet?.userByEmployeeId?.contractors)?.reportee
												?.full_name
										: null,
								reportToEmail:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet?.userByEmployeeId?.contractors)?.reportee
												?.email
										: null,
								department:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.department?.name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet?.userByEmployeeId?.contractors)?.department
												?.name
										: null,
								org_location:
									timeSheet?.userByEmployeeId.user_type === 'employee'
										? timeSheet?.userByEmployeeId.employee?.org_location?.name
										: head(timeSheet?.userByEmployeeId.contractors)
												?.org_location?.name,
								employeeId: timeSheet.userByEmployeeId?.employee?.id || null,
								notes: timeSheet?.notes || '',
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								timesheetStatus:
									timeSheet?.timesheet_overtime_submission?.timesheet_status
										?.label || '--',
								resourceCost: `--`,
								cost: 0,
								totalCost: `--`,
								currencySymbol: '',
							};
						});
						return timeSheetData;
				  })
				: [];

		// for draft
		//Get timesheet entries which are in draft status
		const draftStatusTimesheetEntries =
			timeSheetDraftAndSavedList?.draftStatusTimesheet
				? timeSheetDraftAndSavedList?.draftStatusTimesheet?.map((timeSheet) => {
						const existingEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.emp_id || '';
						const existingContractorId =
							head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id ||
							'';
						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.userByEmployeeId?.user_type === 'employee'
								? timeSheet?.userByEmployeeId?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.name,
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.name,
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.value
										) || ''
								  }${
										timeSheet?.userByEmployeeId?.contractors &&
										timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						const leaveEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.id || null;
						const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
						const userLocationId =
							timeSheet?.userByEmployeeId.user_type === 'employee'
								? timeSheet?.userByEmployeeId.employee?.org_location?.id
								: timeSheet?.userByEmployeeId.contractors[0]?.org_location?.id;
						return {
							id: timeSheet.id,
							isUserActive: timeSheet?.userByEmployeeId?.is_active,
							billableStatus: timeSheet.is_billable,
							projectName: timeSheet?.project?.name,
							projectId: timeSheet?.project?.id,
							taskName: getTaskNameByProjectType(timeSheet) || '',
							user: timeSheet.userByEmployeeId?.full_name,
							userId: timeSheet.userByEmployeeId.id,
							notes: timeSheet?.notes || '',
							email: timeSheet?.userByEmployeeId?.email || '',
							employeeId: formattedEmployeeId || null,
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							date: timeSheet?.date,
							employeeType: timeSheet?.userByEmployeeId?.user_type
								? timeSheet?.userByEmployeeId?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
							empId: employeeId,
							leaveEmployeeId: leaveEmployeeId,
							leaveDateArray: leaveDateArray || [],
							userLocationId: userLocationId,
							optionalHolidayArray:
								timeSheet?.userByEmployeeId?.user_optional_holiday_mappings ||
								[],
							reportTo:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet?.userByEmployeeId?.contractors)?.reportee
											?.full_name
									: null,
							reportToEmail:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet?.userByEmployeeId?.contractors)?.reportee
											?.email
									: null,
							department:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.department?.name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet?.userByEmployeeId?.contractors)?.department
											?.name
									: null,
							org_location:
								timeSheet?.userByEmployeeId.user_type === 'employee'
									? timeSheet?.userByEmployeeId.employee?.org_location?.name
									: timeSheet?.userByEmployeeId.contractors[0]?.org_location
											?.name,

							timesheetStatus:
								timeSheet?.timesheet_submission?.timesheet_status?.label ||
								'--',
							resourceCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol || ''
											: '--'
								  }${
										isFloat(
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
										)
											? formatDecimal(
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost,
													1
											  )
											: timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost || '--'
								  }`
								: '--',
							cost:
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost || '',
							totalCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol
											: ''
								  }${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? getTotalCost(
													String(timeSheet?.working_hours).slice(0, 5),
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost
											  )
											: '--'
								  }`
								: '--',
							currencySymbol: timeSheet?.project?.currency?.symbol || '',
						};
				  })
				: [];
		//Get timesheet entries which are not yet submitted
		const unSubmittedTimesheetEntries =
			timeSheetDraftAndSavedList?.savedTimesheet
				? timeSheetDraftAndSavedList?.savedTimesheet?.map((timeSheet) => {
						const existingEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.emp_id || '';
						const existingContractorId =
							timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id || '';
						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.userByEmployeeId?.user_type === 'employee'
								? timeSheet?.userByEmployeeId?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.name,
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.name,
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.value
										) || ''
								  }${
										timeSheet?.userByEmployeeId?.contractors &&
										timeSheet?.userByEmployeeId?.contractors[0]?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						const leaveEmployeeId =
							timeSheet?.userByEmployeeId?.employee?.id || null;
						const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
						const userLocationId =
							timeSheet?.userByEmployeeId.user_type === 'employee'
								? timeSheet?.userByEmployeeId.employee?.org_location?.id
								: timeSheet?.userByEmployeeId.contractors[0]?.org_location?.id;
						return {
							id: timeSheet.id,
							isUserActive: timeSheet?.userByEmployeeId?.is_active,

							billableStatus: timeSheet.is_billable,
							projectName: timeSheet?.project?.name,
							projectId: timeSheet?.project?.id,
							taskName: getTaskNameByProjectType(timeSheet) || '',
							user: timeSheet.userByEmployeeId?.full_name,
							userId: timeSheet.userByEmployeeId.id,
							notes: timeSheet?.notes || '',
							email: timeSheet?.userByEmployeeId?.email || '',
							employeeId: formattedEmployeeId || null,
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							date: timeSheet?.date,
							employeeType: timeSheet?.userByEmployeeId?.user_type
								? timeSheet?.userByEmployeeId?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
							empId: employeeId,
							leaveEmployeeId: leaveEmployeeId,
							leaveDateArray: leaveDateArray || [],
							userLocationId: userLocationId,
							optionalHolidayArray:
								timeSheet?.userByEmployeeId?.user_optional_holiday_mappings ||
								[],
							reportTo:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
									: timeSheet?.userByEmployeeId?.contractors
									? timeSheet?.userByEmployeeId?.contractors[0]?.reportee
											?.full_name
									: null,
							reportToEmail:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
									: timeSheet?.userByEmployeeId?.contractors
									? timeSheet?.userByEmployeeId?.contractors[0]?.reportee?.email
									: null,
							department:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.department?.name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet?.userByEmployeeId?.contractors)?.department
											?.name
									: null,
							org_location:
								timeSheet?.userByEmployeeId.user_type === 'employee'
									? timeSheet?.userByEmployeeId.employee?.org_location?.name
									: timeSheet?.userByEmployeeId.contractors[0]?.org_location
											?.name,

							timesheetStatus:
								timeSheet?.timesheet_submission?.timesheet_status?.label ||
								'--',
							resourceCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol || ''
											: '--'
								  }${
										isFloat(
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
										)
											? formatDecimal(
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost,
													1
											  )
											: timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost || '--'
								  }`
								: '--',
							cost:
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost || '',
							totalCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol
											: ''
								  }${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? getTotalCost(
													String(timeSheet?.working_hours).slice(0, 5),
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost
											  )
											: '--'
								  }`
								: '--',
							currencySymbol: timeSheet?.project?.currency?.symbol || '',
						};
				  })
				: [];
		//Get general  Timesheet entries which are in unsaved status
		const generalUnSubmittedTimesheetEntries =
			generalDraftAndSavedTimeSheet?.savedTimesheet
				? generalDraftAndSavedTimeSheet?.savedTimesheet?.map((task) => {
						const timesheetEntries = task?.timesheets
							? task?.timesheets?.map((timeSheet) => {
									const existingEmployeeId =
										timeSheet?.userByEmployeeId?.employee?.emp_id || '';
									const existingContractorId =
										head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id || '';
									const formattedContractorId = formatEmployeeId(
										existingContractorId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const formattedEmployeeId = formatEmployeeId(
										existingEmployeeId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const employeeId =
										timeSheet?.userByEmployeeId?.user_type === 'employee'
											? timeSheet?.userByEmployeeId?.employee?.emp_id
												? `${
														getPrefix(
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.name,
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.value
														) || ''
												  }${formattedEmployeeId || ''}`
												: ''
											: head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											? `${
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_prefix?.name || ''
											  }${
													timeSheet?.userByEmployeeId?.contractors &&
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
														? formattedContractorId
														: ''
											  }`
											: '';
									const leaveEmployeeId =
										timeSheet?.userByEmployeeId?.employee?.id || null;
									const leaveDateArray =
										getLeaveDetailsOfEmployee(leaveEmployeeId);
									const userLocationId =
										timeSheet?.userByEmployeeId.user_type === 'employee'
											? timeSheet?.userByEmployeeId.employee?.org_location?.id
											: head(timeSheet?.userByEmployeeId.contractors)
													?.org_location?.id;
									return {
										id: timeSheet.id,
										isUserActive: timeSheet?.userByEmployeeId?.is_active,

										billableStatus: timeSheet.is_billable,
										projectName: task.task_category || '',
										projectId: task.task_category || '',
										taskName: task.title || '',
										user: timeSheet.userByEmployeeId?.full_name,
										userId: timeSheet.userByEmployeeId.id,
										date: timeSheet?.date,
										email: timeSheet?.userByEmployeeId?.email || '',
										employeeId: formattedEmployeeId || null,
										notes: timeSheet?.notes || '',
										employeeType: timeSheet?.userByEmployeeId?.user_type
											? timeSheet?.userByEmployeeId?.user_type === 'employee'
												? 'Employee'
												: 'Consultant'
											: '--',
										empId: employeeId,
										leaveEmployeeId: leaveEmployeeId,
										leaveDateArray: leaveDateArray || [],
										userLocationId: userLocationId || null,
										optionalHolidayArray:
											timeSheet?.userByEmployeeId
												?.user_optional_holiday_mappings || [],
										reportTo:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.full_name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.reportee?.full_name
												: null,
										reportToEmail:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.email
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.reportee?.email
												: null,
										department:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.department
														?.name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.department?.name
												: null,
										org_location:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location
														?.name
												: head(timeSheet?.userByEmployeeId.contractors)
														?.org_location?.name,

										workingHours: String(timeSheet?.working_hours).slice(0, 5),
										timesheetStatus:
											timeSheet?.timesheet_submission?.timesheet_status
												?.label || '--',
										resourceCost: `--`,
										cost: 0,
										totalCost: `--`,
										currencySymbol: '',
									};
							  })
							: [];
						return timesheetEntries;
				  })
				: [];
		//Get general  Timesheet entries which are in draft status
		const generalDraftTimesheetEntries =
			generalDraftAndSavedTimeSheet?.draftStatusTimesheet
				? generalDraftAndSavedTimeSheet?.draftStatusTimesheet?.map((task) => {
						const timesheetEntries = task?.timesheets
							? task?.timesheets?.map((timeSheet) => {
									const existingEmployeeId =
										timeSheet?.userByEmployeeId?.employee?.emp_id || '';
									const existingContractorId =
										head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id || '';
									const formattedContractorId = formatEmployeeId(
										existingContractorId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const formattedEmployeeId = formatEmployeeId(
										existingEmployeeId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const empId =
										timeSheet?.userByEmployeeId?.user_type === 'employee'
											? timeSheet?.userByEmployeeId?.employee?.emp_id
												? `${
														getPrefix(
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.name,
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.value
														) || ''
												  }${formattedEmployeeId || ''}`
												: ''
											: head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											? `${
													getPrefix(
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.name,
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.value
													) || ''
											  }${
													timeSheet?.userByEmployeeId?.contractors &&
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
														? formattedContractorId
														: ''
											  }`
											: '';
									const leaveEmployeeId =
										timeSheet?.userByEmployeeId?.employee?.id || null;
									const leaveDateArray =
										getLeaveDetailsOfEmployee(leaveEmployeeId);
									const userLocationId =
										timeSheet?.userByEmployeeId.user_type === 'employee'
											? timeSheet?.userByEmployeeId.employee?.org_location?.id
											: head(timeSheet?.userByEmployeeId.contractors)
													?.org_location?.id;
									return {
										id: timeSheet.id,
										isUserActive: timeSheet?.userByEmployeeId?.is_active,

										billableStatus: timeSheet.is_billable,
										projectName: task.task_category || '',
										projectId: task.task_category || '',
										taskName: task.title || '',
										email: timeSheet?.userByEmployeeId?.email || '',
										user: timeSheet.userByEmployeeId?.full_name,
										userId: timeSheet.userByEmployeeId.id,
										date: timeSheet?.date,
										employeeId: formattedEmployeeId || null,
										notes: timeSheet?.notes || '',
										employeeType: timeSheet?.userByEmployeeId?.user_type
											? timeSheet?.userByEmployeeId?.user_type === 'employee'
												? 'Employee'
												: 'Consultant'
											: '--',
										empId: empId,
										leaveEmployeeId: leaveEmployeeId,
										leaveDateArray: leaveDateArray || [],
										userLocationId: userLocationId || null,
										optionalHolidayArray:
											timeSheet?.userByEmployeeId
												?.user_optional_holiday_mappings || [],
										reportTo:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.full_name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.reportee?.full_name
												: null,
										reportToEmail:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.email
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.reportee?.email
												: null,
										department:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.department
														?.name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId?.contractors)
														?.department?.name
												: null,
										org_location:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location
														?.name
												: head(timeSheet?.userByEmployeeId.contractors)
														?.org_location?.name,

										workingHours: String(timeSheet?.working_hours).slice(0, 5),
										timesheetStatus:
											timeSheet?.timesheet_submission?.timesheet_status
												?.label || '--',
										resourceCost: `--`,
										cost: 0,
										totalCost: `--`,
										currencySymbol: '',
									};
							  })
							: [];
						return timesheetEntries;
				  })
				: [];
		// Combine all timesheet with unique check using the timesheet primary key id,
		// to avoid duplicate entries
		const finalTimesheetList = uniqBy(
			isOvertimeEnabled
				? timesheetType === 'overtime'
					? [...overtimeData, ...generalOvertimeData?.flat()]
					: timesheetType === 'timesheet'
					? [
							...timeSheetTableData,
							...generalTimesheetData.flat(),
							...draftStatusTimesheetEntries,
							...unSubmittedTimesheetEntries,
							...generalUnSubmittedTimesheetEntries?.flat(),
							...generalDraftTimesheetEntries?.flat(),
					  ]
					: [
							...timeSheetTableData,
							...generalTimesheetData.flat(),
							...overtimeData,
							...generalOvertimeData?.flat(),
							...draftStatusTimesheetEntries,
							...unSubmittedTimesheetEntries,
							...generalUnSubmittedTimesheetEntries?.flat(),
							...generalDraftTimesheetEntries?.flat(),
					  ]
				: [
						...timeSheetTableData,
						...generalTimesheetData.flat(),
						...draftStatusTimesheetEntries,
						...unSubmittedTimesheetEntries,
						...generalUnSubmittedTimesheetEntries?.flat(),
						...generalDraftTimesheetEntries?.flat(),
				  ],
			'id'
		);

		const tableData: any[] = _.chain(finalTimesheetList)
			.groupBy((item) => `${item.date}-${item.userId}`)
			.map((timesheetEntry) => {
				const billableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === true &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				const nonBillableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === false &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);

				const data: Duration = timesheetEntry
					.map((timeSheet) => timeSheet?.workingHours?.split(':'))
					.map((hoursAndMinutes: string[]) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				return {
					workingHours: String(data?.asHours()),
					billableWorkingHours: String(billableHours?.asHours()),
					nonBillableWorkingHours: String(nonBillableHours?.asHours()),
					projectId: head(timesheetEntry)?.projectName,
					id: head(timesheetEntry)?.id,
					employeeId: head(timesheetEntry)?.employeeId,
					projectName: head(timesheetEntry)?.projectName || '',
					taskName: head(timesheetEntry)?.taskName || '',
					user: head(timesheetEntry)?.user,
					isUserActive: head(timesheetEntry)?.isUserActive,
					userId: head(timesheetEntry)?.userId,
					date: head(timesheetEntry)?.date,
					notes: head(timesheetEntry)?.notes || '',
					timesheetStatus: head(timesheetEntry)?.timesheetStatus || '',
					resourceCost: head(timesheetEntry)?.resourceCost || '--',
					cost: head(timesheetEntry)?.cost || '',
					currencySymbol: head(timesheetEntry)?.currencySymbol || '',
					org_location: head(timesheetEntry)?.org_location || '',
					empId: head(timesheetEntry)?.empId || '',
					employeeType: head(timesheetEntry)?.employeeType || '',
					email: head(timesheetEntry)?.email || '',
					reportTo: head(timesheetEntry)?.reportTo || '',
					reportToEmail: head(timesheetEntry)?.reportToEmail || '',
					department: head(timesheetEntry)?.department || '',
					leaveDateArray: head(timesheetEntry)?.leaveDateArray,
					optionalHolidayArray: head(timesheetEntry)?.optionalHolidayArray,
					userLocationId: head(timesheetEntry)?.userLocationId,
				};
			})
			.groupBy((item) => item.userId)
			.map((item) => {
				const leaveDateList = head(item)?.leaveDateArray
					? head(item)
							?.leaveDateArray?.map((leave: any) => {
								if (dayjs(leave?.to_date).diff(dayjs(leave?.from_date)) > 1) {
									const dateList = [];
									let currentDate = dayjs(leave?.from_date);
									const stopDate = dayjs(leave?.to_date);
									while (currentDate <= stopDate) {
										dateList.push({
											date: dayjs(currentDate).format('YYYY-MM-DD'),
											label: leave?.org_leave_type?.leave_type,
											isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
										});
										currentDate = dayjs(currentDate).add(1, 'day');
									}
									return dateList;
								} else
									return {
										date: leave?.from_date,
										label: leave?.org_leave_type?.leave_type,
										isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
									};
							})
							.flat()
					: [];
				const userLocationId = head(item)?.userLocationId || null;
				const userOptionalHolidayList = head(item)?.optionalHolidayArray || [];
				const userLeaveDateList = getEmployeeLeaveDates(
					userLocationId,
					leaveDateList,
					userOptionalHolidayList
				);
				const totalHours = item.reduce(function (acc, obj) {
					return acc + Number(obj.workingHours);
				}, 0);
				const totalBillableHours = item.reduce(function (acc, obj) {
					return acc + Number(obj.billableWorkingHours);
				}, 0);
				const totalNonBillableHours = item.reduce(function (acc, obj) {
					return acc + Number(obj.nonBillableWorkingHours);
				}, 0);
				return {
					user: head(item)?.user,
					isUserActive: head(item)?.isUserActive,
					empId: head(item)?.empId || '',
					org_location: head(item)?.org_location,
					totalHours: isFloat(totalHours)
						? formatDecimal(totalHours, 1)
						: Number(totalHours),

					billableWorkingHours: isFloat(totalBillableHours)
						? formatDecimal(totalBillableHours, 1)
						: Number(totalBillableHours),
					nonBillableWorkingHours: isFloat(totalNonBillableHours)
						? formatDecimal(totalNonBillableHours, 1)
						: Number(totalNonBillableHours),
					resourceCost: head(item)?.resourceCost
						? head(item)?.resourceCost
						: '--',
					timesheet: item,
					totalCost: head(item)?.cost
						? formatDecimal(totalHours * Number(head(item)?.cost), 1)
						: '',
					currencySymbol: head(item)?.currencySymbol || '',
					userId: head(item)?.userId,
					employeeType: head(item)?.employeeType || '',
					email: head(item)?.email || '',
					reportTo: head(item)?.reportTo || '',
					reportToEmail: head(item)?.reportToEmail || '',
					department: head(item)?.department || '',
					leaveDateArray: userLeaveDateList,
				};
			})
			.value();
		const usersWithTimehsheetEntry = tableData?.map(
			(timesheet) => timesheet?.userId
		);
		const usersWithoutTimesheetEntry = timeSheetList?.projectResources
			?.filter(
				(resource: any) =>
					!usersWithTimehsheetEntry.includes(resource?.employee_id)
			)
			.map((resource: any) => {
				const existingEmployeeId = resource?.user?.employee?.emp_id || '';
				const existingContractorId =
					resource?.user?.contractors[0]?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					resource?.user?.user_type === 'employee'
						? resource?.user?.employee?.emp_id
							? `${
									getPrefix(
										resource?.user?.employee?.employee_prefix?.name,
										resource?.user?.employee?.employee_prefix?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: resource?.user?.contractors[0]?.contractor_id
						? `${
								getPrefix(
									resource?.user?.contractors[0]?.contractor_prefix?.name,
									resource?.user?.contractors[0]?.contractor_prefix?.value
								) || ''
						  }${
								resource?.user?.contractors &&
								resource?.user?.contractors[0]?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';
				const leaveEmployeeId = resource?.user?.employee?.id || null;
				const userLocationId =
					resource?.user.user_type === 'employee'
						? resource?.user.employee?.org_location?.id
						: resource.user?.contractors[0]?.org_location?.id || '';
				const leaveDateArray = getLeaveDetailsOfEmployee(leaveEmployeeId);
				const leaveDateList = leaveDateArray
					? leaveDateArray
							?.map((leave: any) => {
								if (dayjs(leave?.to_date).diff(dayjs(leave?.from_date)) > 1) {
									const dateList = [];
									let currentDate = dayjs(leave?.from_date);
									const stopDate = dayjs(leave?.to_date);
									while (currentDate <= stopDate) {
										dateList.push({
											date: dayjs(currentDate).format('YYYY-MM-DD'),
											label: leave?.org_leave_type?.leave_type,
											isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
										});
										currentDate = dayjs(currentDate).add(1, 'day');
									}
									return dateList;
								} else
									return {
										date: leave?.from_date,
										label: leave?.org_leave_type?.leave_type,
										isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
									};
							})
							.flat()
					: [];
				const userOptionalHolidayList =
					resource?.user?.user_optional_holiday_mappings || [];
				const userLeaveDateList = getEmployeeLeaveDates(
					userLocationId,
					leaveDateList,
					userOptionalHolidayList
				);
				return {
					user: resource?.user?.full_name,
					isUserActive: resource?.user?.is_active,
					totalHours: 0,
					resourceCost: resource?.user?.project_resource_mappings_aggregate
						?.aggregate?.max?.resource_cost
						? `${
								resource?.user?.project_resource_mappings_aggregate?.aggregate
									?.max?.resource_cost
									? resource?.project?.currency?.symbol || ''
									: ''
						  }${
								isFloat(
									resource?.user?.project_resource_mappings_aggregate?.aggregate
										?.max?.resource_cost
								)
									? formatDecimal(
											resource?.user?.project_resource_mappings_aggregate
												?.aggregate?.max?.resource_cost,
											1
									  )
									: resource?.user?.project_resource_mappings_aggregate
											?.aggregate?.max?.resource_cost || '--'
						  }`
						: '--',
					timesheet: [],
					totalCost: '',
					currencySymbol: '',
					userId: resource?.user?.id,
					email: resource?.user?.email || '',
					employeeType: resource?.user?.user_type
						? resource?.user?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
					empId: employeeId,
					leaveEmployeeId: leaveEmployeeId,
					leaveDateArray: userLeaveDateList,
					userLocationId: userLocationId || null,
					optionalHolidayArray:
						resource?.user?.user_optional_holiday_mappings || [],
					reportTo:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.reportsTo?.full_name
							: resource.user?.contractors[0]?.reportee?.full_name || '',
					reportToEmail:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.reportsTo?.email
							: resource.user?.contractors[0]?.reportee?.email || '',
					department:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.department?.name
							: resource.user?.contractors[0]?.department?.name || '',
					org_location:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.org_location?.name
							: resource.user?.contractors[0]?.org_location?.name || '',
				};
			});
		const sortedTableData = orderBy(
			[...tableData, ...usersWithoutTimesheetEntry],
			[(user) => user.user?.toUpperCase()],
			['asc']
		);
		if (totalHoursFilterOperator && totalHoursFilterValue) {
			if (totalHoursFilterValue && totalHoursFilterOperator === '<=') {
				const totalHoursFilterIdList = sortedTableData
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) <= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);

				setTotalHourValue(totalHour);
				return;
			}
			if (totalHoursFilterValue && totalHoursFilterOperator === '>=') {
				const totalHoursFilterIdList = sortedTableData
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) >= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);
				setTotalHourValue(totalHour);
				return;
			}
		}
		setTimeSheetTableList(sortedTableData);
	}, [
		generalDraftAndSavedTimeSheet,
		generalOvertime,
		generalTimeSheet,
		isOvertimeEnabled,
		overtimeList,
		timeSheetDraftAndSavedList,
		timeSheetList,
		timesheetType,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		totalHoursFilterOperator,
		totalHoursFilterValue,
		getLeaveDetailsOfEmployee,
		organizationWeekendData,
		startDate,
		endDate,
		noOfLocations,
		holidays,
		getEmployeeLeaveDates,
	]);

	const getTotalCost = (hours: string, cost: number) => {
		const hour = hours.split(':');
		const totalHours = parseInt(hour[0]) * cost;
		return totalHours;
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = (exportMenuItemIndex: number = 0) => {
		if (timeSheetTableList?.length === 0) {
			return;
		}
		const month = monthList?.map((date) => `${date?.day} ${date?.month}`);
		const timesheetTableRows = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'LOCATION',
			'REPORTING TO',
			'REPORTING TO EMAIL',
			'DEPARTMENT',
			...month,
			'TOTAL HOURS',
			'RESOURCE COST',
			'TOTAL COST',
		];
		const timesheetTableRowsWithoutLocation = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'REPORTING TO',
			'REPORTING TO EMAIL',
			'DEPARTMENT',
			...month,
			'TOTAL HOURS',
			'RESOURCE COST',
			'TOTAL COST',
		];
		const filteredTimeSheetTableListForExport = timeSheetTableList?.filter(
			(timeSheet) => {
				if (exportMenuItemIndex === 0) {
					return true;
				}

				return exportMenuItemIndex === 1
					? timeSheet?.isUserActive
					: !timeSheet?.isUserActive;
			}
		);
		const timesheetRows: any = filteredTimeSheetTableListForExport?.map(
			(timeSheet) => {
				const dateList = monthList.map((date) => {
					const leaveDateList = timeSheet?.leaveDateArray
						? timeSheet?.leaveDateArray?.find(
								(leave) => leave?.date === date?.date
						  )
						: [];
					const timesheetDate = timeSheet?.timesheet?.find(
						(timesheet) => timesheet.date === date.date
					);
					if (leaveDateList) {
						return leaveDateList?.label || '';
					}
					if (timesheetDate) {
						return formatDecimal(timesheetDate?.workingHours, 1);
					} else {
						return 0;
					}
				});

				return noOfLocations && noOfLocations > 0
					? [
							timeSheet.empId || '--',
							timeSheet.user,
							timeSheet.email,
							timeSheet.employeeType || '--',
							timeSheet.org_location || '--',
							timeSheet.reportTo || '--',
							timeSheet.reportToEmail || '--',
							timeSheet.department || '--',
							...dateList,
							timeSheet.totalHours ? formatDecimal(timeSheet.totalHours, 1) : 0,
							timeSheet.resourceCost || '--',
							timeSheet.totalCost
								? `${timeSheet?.currencySymbol}${timeSheet.totalCost}`
								: '--',
					  ]
					: [
							timeSheet.empId || '--',
							timeSheet.user,
							timeSheet.email,
							timeSheet.employeeType || '--',
							timeSheet.reportTo || '--',
							timeSheet.reportToEmail || '--',
							timeSheet.department || '--',
							...dateList,
							timeSheet.totalHours ? formatDecimal(timeSheet.totalHours, 1) : 0,
							timeSheet.resourceCost || '--',
							timeSheet.totalCost
								? `${timeSheet?.currencySymbol}${timeSheet.totalCost}`
								: '--',
					  ];
			}
		);
		const timeSheetExportData = [
			noOfLocations > 0
				? timesheetTableRows
				: timesheetTableRowsWithoutLocation,
			...timesheetRows,
		];
		exportToCsv(
			`Timesheet_Day_Wise_Report_(${
				exportTimesheetReportUsersOptions[exportMenuItemIndex]
			})_From_${dayjs(startDate)?.format(dateFormat)}_To_${dayjs(
				endDate
			)?.format(dateFormat)}.csv`,
			timeSheetExportData
		);
	};

	const getTaskNameByProjectType = (item: any) => {
		if (!item) {
			return;
		}
		if (item?.project_task) {
			return item?.project_task?.title;
		}
		if (item?.master_project_task) {
			return item?.master_project_task?.title;
		}
	};

	const checkHoliday = (date: string) => {
		if (!date && !holidays) {
			return;
		}
		const holiday = holidays?.org_holidays.filter(
			(holiday) => holiday?.date === date
		);
		const month = dayjs(date).format('dddd').slice(0, 1);
		const holidayTitle =
			holiday &&
			holiday?.length > 1 &&
			holiday?.map(
				(item) =>
					`${
						item?.org_location?.name
							? `${item?.org_location?.name}: ${item?.name}`
							: `${item?.name}`
					}`
			);
		return (
			<Box display='flex'>
				<Tooltip title={dayjs(date).format('dddd') || ''} placement='right'>
					<div className={classes.circle}>{month}</div>
				</Tooltip>
				{holiday && head(holiday)?.date && (
					<Tooltip
						title={
							holidayTitle
								? holidayTitle.join(',')
								: head(holiday)?.date
								? head(holiday)?.org_location?.name
									? `${head(holiday)?.org_location?.name}: ${
											head(holiday)?.name
									  }`
									: `Holiday: ${head(holiday)?.name}`
								: ''
						}
						placement='right'
					>
						<div className={classes.holidayCircle}>H</div>
					</Tooltip>
				)}
			</Box>
		);
	};
	const resetAssigneeFilterDropdown = () => {
		if (!timeSheetList || !generalTaskAssigneeList) {
			return;
		}
		const projectAssignedResources = timeSheetList?.projectResources?.map(
			(resource: { user: { id: string; full_name: string } }) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			})
		);

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const uniqueAssignees = uniqBy(
			[...projectAssignedResources, ...orgTaskAssigneeList],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};
	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getDayTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedDayTypeOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length === 1
					? selectedDayTypeOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getTimesheetStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedTimesheetStatusOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedTimesheetStatusOptionValue &&
				selectedTimesheetStatusOptionValue?.length === 1
					? selectedTimesheetStatusOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	return (
		<>
			<ThemeProvider theme={insightChartTheme}>
				<Card className={classes.overflowStyle}>
					<Box>
						<Form
							initialValues={{
								name: 'Total Hours',
								operator: totalHoursFilterOperator,
								billable: billableStatus,
								value: totalHoursFilterValue,
								timesheet_type: timesheetType,
							}}
							onSubmit={() => {}}
							mutators={{
								removeSelectedUser: (args, state, utils) => {
									utils.changeValue(state, 'employee_id', () => null);
								},
							}}
						>
							{({ form }) => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.header}>
											<Box display='flex'>
												{featureFlag?.userTeams && (
													<Box display='block'>
														<Box className={classes.autoCompleteInputContainer}>
															<Typography className={styles.label}>
																User Team
															</Typography>
															<AutocompleteSearch
																placeholder={'Search Team'}
																option={
																	userTeams && userTeams?.user_team
																		? userTeams?.user_team?.map(
																				(userTeam: {
																					id: string;
																					name: string;
																				}) => {
																					return {
																						id: userTeam?.id,
																						name: userTeam?.name,
																					};
																				}
																		  ) || []
																		: []
																}
																onChange={(id) => {
																	if (!id) {
																		resetAssigneeFilterDropdown();
																		setSelectedUserTeam(null);
																		setUserTeamId(null);
																		setSelectedResourceIdList([]);
																		setSelectedResourceOptionValues([]);
																		return;
																	}
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	setUserTeamId(id);
																	const currentTeam =
																		userTeams?.user_team?.find(
																			(team: {
																				id: string;
																				full_name: string;
																			}) => team?.id === id
																		);
																	setSelectedUserTeam(currentTeam);
																}}
																name={'team_id'}
																value={selectedUserTeam}
															/>
														</Box>
													</Box>
												)}

												<Box display='block' width='200px'>
													<Box className={styles.label}>Resource</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedResourceOptionValue &&
																selectedResourceOptionValue?.length > 0
																	? selectedResourceOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(resource: { id: string; name: string }) =>
																			resource.id !== 'All'
																	)
																	.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const allResourceIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(allResourceIds);
																	choosedResourceId(allResourceIds);
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	assigneeRecommendations?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(resource: { id: string; name: string }) =>
																			resource?.id !== 'All'
																	);
																	setSelectedResourceOptionValues(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																	setSelectedResourceIdList(currentIds);
																	choosedResourceId(currentIds);
																	return;
																}
																if (
																	selectedIds?.length ===
																		assigneeRecommendations?.length &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const reporteeIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(reporteeIds);
																	choosedResourceId(reporteeIds);
																	return;
																}
																if (
																	selectedResourceOptionValue.find(
																		(option: { id: string; name: string }) =>
																			option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	choosedResourceId([]);
																	return;
																}
																const selectedId = value?.map(
																	(resource) => resource?.id
																);
																setSelectedResourceIdList(selectedId);
																choosedResourceId(selectedId);
																setSelectedResourceOptionValues(value);
															}}
															options={
																assigneeRecommendations &&
																assigneeRecommendations?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...assigneeRecommendations,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getResourceFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent {...params} label='' />
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>

												<Box display='flex' marginTop='24px' marginLeft='10px'>
													<Box width='90px'>
														<TextInput
															min={0}
															label={''}
															source='name'
															disabled
															onChange={(event) => {}}
														/>
													</Box>
													<Box width='101px' marginLeft='4px' marginRight='4px'>
														<ThemeProvider theme={filterMetricInputTheme}>
															<SelectInput
																source='operator'
																label={false}
																fullWidth
																choices={[
																	{ id: '>=', name: '(gte) >=' },
																	{ id: '<=', name: '(lte) <=' },
																]}
																onChange={(event) => {
																	if (!event) {
																		return;
																	}
																	setTotalHoursFilterOperator(
																		event?.target?.value
																	);
																	setPage(0);
																}}
															/>
														</ThemeProvider>
													</Box>
													<Box width='80px'>
														<NumberInput
															min={0}
															label={''}
															source='value'
															onChange={(event) => {
																if (!event) {
																	setTotalHoursFiltervalue(null);
																	return;
																}
																setTotalHoursFiltervalue(event?.target?.value);
																setPage(0);
															}}
															validate={minValue(0)}
														/>
													</Box>
												</Box>

												<Box display='block' marginLeft='5px'>
													<Box className={styles.label}>Billable</Box>
													<Box>
														<SelectInput
															key={billableStatus}
															source='billable'
															label=''
															choices={[
																{ id: 'All', name: 'All' },
																{ id: 'true', name: 'Billable' },
																{ id: 'false', name: 'Non-billable' },
															]}
															onChange={(e: any) => {
																setBillableStatus(e?.target?.value);
																billableType(e?.target?.value);
																setPage(0);
															}}
														/>
													</Box>
												</Box>
												{isOvertimeEnabled && (
													<Box display='block' marginLeft='5px'>
														<Box className={styles.label}>Task type</Box>
														<Box>
															<SelectInput
																key={timesheetType}
																source='timesheet_type'
																label=''
																choices={[
																	{ id: 'All', name: 'All' },
																	{ id: 'timesheet', name: 'Normal Time' },
																	{ id: 'overtime', name: 'Over Time' },
																]}
																onChange={(e: any) => {
																	setTimesheetType(e?.target?.value);
																	taskType(e?.target?.value);
																	setPage(0);
																}}
															/>
														</Box>
													</Box>
												)}
												{isTrackDayTypeEnabled && (
													<Box display='block' marginLeft='4px' width='190px'>
														<Box className={styles.label}>Day type</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedDayTypeOptionValue &&
																		selectedDayTypeOptionValue?.length > 0
																			? selectedDayTypeOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const allResourceIds =
																				dayTypeOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedDayTypeIdList(allResourceIds);
																			dayType([]);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			dayTypeOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedDayTypeOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedDayTypeIdList(currentIds);
																			dayType(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				dayTypeOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const reporteeIds = dayTypeOptions?.map(
																				(resource: any) => resource?.id
																			);
																			setSelectedDayTypeIdList(reporteeIds);
																			dayType(reporteeIds);
																			return;
																		}
																		if (
																			selectedDayTypeOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedDayTypeIdList([]);
																			dayType([]);
																			setSelectedDayTypeOptionValues([]);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedDayTypeIdList(selectedId);
																		dayType(selectedId);
																		setSelectedDayTypeOptionValues(value);
																	}}
																	options={
																		dayTypeOptions && dayTypeOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...dayTypeOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getDayTypeFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
												{isDraftTimesheetEnabled && (
													<Box display='block' marginLeft='4px' width='170px'>
														<Box className={styles.label}>Timesheet status</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedTimesheetStatusOptionValue &&
																		selectedTimesheetStatusOptionValue?.length >
																			0
																			? selectedTimesheetStatusOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const allResourceIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				allResourceIds
																			);
																			choosedTimesheetStatus(allResourceIds);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			timesheetStatusOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedTimesheetStatusOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedTimesheetStatusIdList(
																				currentIds
																			);
																			choosedTimesheetStatus(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				timesheetStatusOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const reporteeIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				reporteeIds
																			);
																			choosedTimesheetStatus(reporteeIds);
																			return;
																		}
																		if (
																			selectedTimesheetStatusOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedTimesheetStatusIdList([]);
																			setSelectedTimesheetStatusOptionValues(
																				[]
																			);
																			choosedTimesheetStatus([]);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedTimesheetStatusIdList(
																			selectedId
																		);
																		setSelectedTimesheetStatusOptionValues(
																			value
																		);
																		choosedTimesheetStatus(selectedId);
																	}}
																	options={
																		timesheetStatusOptions &&
																		timesheetStatusOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...timesheetStatusOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={
																		getTimesheetStatusFilterLimits
																	}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
											</Box>
											<Box className={classes.exportButtonDiv}>
												<ExportButtonWithDropDown
													onChange={(index: number) => {
														generateCSV(index);
													}}
													options={exportTimesheetReportUsersOptions}
													isDisabled={!timeSheetTableList?.length}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
					{!isTimesheetListLoading && !isGeneralTimeSheetLoading ? (
						<CardContent>
							<Box className={classes.tableContainer}>
								{timeSheetTableList && timeSheetTableList?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell>RESOURCE</CustomTableCell>
													{monthList &&
														monthList?.map((month) => (
															<TableCell className={classes.monthCell}>
																<Tooltip
																	title={
																		dayjs(month?.date).format('dddd') || '--'
																	}
																	placement='top'
																>
																	<Box className={classes.dateCell}>
																		<Box
																			display='flex'
																			className={
																				Number(
																					dayjs(month?.date).format('d')
																				) === 0 ||
																				Number(
																					dayjs(month?.date).format('d')
																				) === 6
																					? classes.weekEndCell
																					: ''
																			}
																		>
																			<span>{month?.month}</span>
																			<span>{`-${month?.day}`}</span>
																		</Box>
																		<span>{checkHoliday(month?.date)}</span>
																	</Box>
																</Tooltip>
															</TableCell>
														))}
													<CustomTableCell className={classes.headerCell}>
														TOTAL HOURS
													</CustomTableCell>
													<CustomTableCell className={classes.headerCell}>
														RESOURCE COST
													</CustomTableCell>
													<CustomTableCell className={classes.headerCell}>
														TOTAL COST
													</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell className={classes.headerCell}>
															LOCATION
														</CustomTableCell>
													)}
												</TableRow>
											</TableHead>

											<TableBody>
												{timeSheetTableList &&
													timeSheetTableList?.length > 0 &&
													timeSheetTableList
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map(
															(timeSheet) =>
																timeSheetTableList?.length > 0 && (
																	<TableRow hover={true} key={timeSheet?.id}>
																		<CustomTableCell>
																			<Tooltip title={`${timeSheet?.user}`}>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.user}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		{monthList &&
																			monthList?.map((month) => {
																				const dateArray = timeSheet?.timesheet;
																				const mappingTimeSheet =
																					dateArray?.find(
																						(item) => item?.date === month?.date
																					);
																				const leaveDateList =
																					timeSheet?.leaveDateArray
																						? timeSheet?.leaveDateArray?.find(
																								(leave) =>
																									leave?.date === month?.date
																						  )
																						: [];
																				if (leaveDateList) {
																					return (
																						<Tooltip
																							title={leaveDateList?.label || ''}
																						>
																							<TableCell>
																								<div
																									className={
																										classes.leaveCircle
																									}
																								>
																									L
																								</div>
																							</TableCell>
																						</Tooltip>
																					);
																				}
																				if (mappingTimeSheet) {
																					return (
																						<TableCell>
																							<div
																								className={
																									classes.timeSheetWithDate
																								}
																							>
																								{mappingTimeSheet?.workingHours
																									? isFloat(
																											Number(
																												mappingTimeSheet?.workingHours
																											)
																									  )
																										? Number(
																												mappingTimeSheet?.workingHours
																										  ).toFixed(1)
																										: mappingTimeSheet?.workingHours
																									: 0}
																							</div>
																						</TableCell>
																					);
																				} else {
																					return (
																						<TableCell>
																							<div
																								className={`${classes.timeSheetWithoutDate}`}
																							>
																								--
																							</div>
																						</TableCell>
																					);
																				}
																			})}
																		<CustomTableCell>
																			{timeSheet?.totalHours
																				? isFloat(timeSheet?.totalHours)
																					? Number(
																							timeSheet?.totalHours
																					  )?.toFixed(1)
																					: timeSheet?.totalHours
																				: 0}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.resourceCost}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.totalCost
																				? `${
																						timeSheet?.totalCost
																							? timeSheet?.currencySymbol
																							: ''
																				  } ${
																						isFloat(timeSheet?.totalCost)
																							? Number(
																									timeSheet?.totalCost
																							  )?.toFixed(1)
																							: timeSheet?.totalCost
																				  }`
																				: '--'}
																		</CustomTableCell>
																		{noOfLocations && noOfLocations > 0 && (
																			<CustomTableCell key={timeSheet?.id}>
																				<Tooltip
																					title={`${
																						timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'
																					}`}
																				>
																					<Typography
																						className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																					>
																						{timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'}
																					</Typography>
																				</Tooltip>
																			</CustomTableCell>
																		)}
																	</TableRow>
																)
														)}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												count={timeSheetTableList?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={classes.noData}>
										<Typography className={classes.noDataLabel}>
											No data found
										</Typography>
									</Box>
								)}
							</Box>
						</CardContent>
					) : (
						<Loading />
					)}
				</Card>
			</ThemeProvider>
		</>
	);
};
