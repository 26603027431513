import React, { useContext, useState } from 'react';
import { List, Datagrid, FunctionField } from 'react-admin';
import {
	Box,
	Typography,
	makeStyles,
	Tooltip,
	Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ellipsisStyle } from '../../../Layout/styles';
import AssetSoftwareForm from './AssetSoftwareForm.component';
import { useParams } from 'react-router-dom';
import { AssetEditPopOver } from './AssetEditPopOver.component';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';

// custom style for component
const useStyles = makeStyles({
	addSoftwareButton: {
		textTransform: 'none',
		display: 'flex',
		justifyContent: 'flex-end',
		minWidth: '111px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box !important',
		boxShadow: '0px 4px 12px #4285F43B !important',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},

	tableList: {
		height: '442px',
		overflowY: 'auto',
		marginTop: '30px',
	},

	title: {
		width: '47px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	softwareTitle: {
		width: '100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	date: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
});

export interface Software {
	id: string;
	name: string;
	product_key: string;
	installation_date: string;
	expiration_date: string;
	asset_id: string;
	type: string;
}
const AssetSoftwareDetails = (props: any) => {
	const [showAddForm, setShowAddForm] = useState(false);
	const { permissions, dateFormat } = useContext<any>(UserProfileContext);
	const styles = ellipsisStyle();
	const custom = useStyles();
	const { id }: { id: string } = useParams();

	return (
		<>
			{permissions?.asset_software?.insert_permissions && (
				<Box display='flex' justifyContent='flex-end'>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						className={custom.addSoftwareButton}
						onClick={() => setShowAddForm(true)}
					>
						Add Software
					</Button>
				</Box>
			)}
			<Box className={custom.tableList}>
				{' '}
				<List
					{...props}
					filters={false}
					component='div'
					title={' '}
					basePath={'/asset'}
					bulkActionButtons={false}
					actions={false}
					resource={'asset_software'}
					filter={{ asset_id: id }}
				>
					<Datagrid>
						<FunctionField
							label='NAME'
							render={(record: any) => {
								return (
									<Tooltip title={`${record?.name || '- -'}`} placement='right'>
										<Typography
											className={[custom.softwareTitle, styles.ellipsis].join(
												' '
											)}
										>
											{`${record?.name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='PRODUCT KEY'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.product_key || '- -'}`}
										placement='right'
									>
										<Typography
											className={[custom.title, styles.ellipsis].join(' ')}
										>
											{`${record?.product_key || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>

						<FunctionField
							label='TYPE'
							render={(record: any) => {
								return (
									<Tooltip title={`${record?.type || '- -'}`} placement='right'>
										<Typography
											className={[custom.softwareTitle, styles.ellipsis].join(
												' '
											)}
										>
											{`${record?.type || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>

						<FunctionField
							label='INSTALATION DATE'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.installation_date? dayjs(record?.installation_date).format(dateFormat) : '- -'}`}
										placement='right'
									>
										<Typography className={custom.date}>
											{`${ record?.installation_date? dayjs(record?.installation_date).format(dateFormat) : '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>

						{permissions?.asset_software?.update_permissions && (
							<FunctionField
								render={(record: any) => (
									<Box key={record.id}>
										<AssetEditPopOver softwareId={record?.id} />
									</Box>
								)}
							/>
						)}
					</Datagrid>
				</List>
			</Box>
			<AssetSoftwareForm
				open={showAddForm}
				onClose={() => setShowAddForm(false)}
			/>
		</>
	);
};

export default AssetSoftwareDetails;
