import React, { useContext, useState } from 'react';

import { TextInput, useNotify, useRefresh, required } from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';
import { useUpdateMyInfoBankDetailsMutation } from '../../generated/graphql';
import {
	editableColumnsPermissions,
	UPDATE_MY_INFO_BANK_DETAILS_SUCCESS_TEXT,
} from './constant';
import { FieldPermissionsContext } from './MyInfoView.component';
interface Employee {
	id?: string;
	bank_name?: string;
	branch_name?: string;
	account_number?: string;
	ifsc_code?: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	record: Employee;
	refetch: () => void;
}
export const EditMyInfoBankForm = (props: Props) => {
	const { editableColumns } = useContext<any>(FieldPermissionsContext);

	const { open, onClose, record: employee, refetch } = props;
	const classes = modalFormStyle();
	const [employeeBankDetails, setEmployeeBankDetails] = useState({
		id: employee?.id,
		bank_name: employee?.bank_name,
		branch_name: employee?.branch_name,
		account_number: employee?.account_number,
		ifsc_code: employee?.ifsc_code,
	});
	const refresh = useRefresh();
	const notify = useNotify();

	const [updateBankInfo,{loading:isBankInfoLoading}] = useUpdateMyInfoBankDetailsMutation();

	const saveDetails = () => {
		updateBankInfo({
			variables: {
				employeeId: employee?.id,
				data: {
					bank_name: employeeBankDetails?.bank_name,
					branch_name: employeeBankDetails?.branch_name,
					account_number: employeeBankDetails?.account_number,
					ifsc_code: employeeBankDetails?.ifsc_code,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					refetch();
					notify(UPDATE_MY_INFO_BANK_DETAILS_SUCCESS_TEXT);
					refresh();
					onClose();
				}
			})
			.catch((err) => {
				console.log(err);
				return;
			});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => onClose()}
						/>
					</Box>
					<Form initialValues={employee} onSubmit={saveDetails}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box>
										<Typography className={classes.label}>
											Bank Name*
										</Typography>
										<TextInput
											source='bank_name'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													bank_name: e.target.value,
												});
											}}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_bank_name
												)
											}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Branch Name*
										</Typography>
										<TextInput
											source='branch_name'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													branch_name: e.target.value,
												});
											}}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_branch_name
												)
											}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Account Number*
										</Typography>
										<TextInput
											source='account_number'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													account_number: e.target.value,
												});
											}}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_account_number
												)
											}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											IFSC Code*
										</Typography>
										<TextInput
											source='ifsc_code'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													ifsc_code: e.target.value,
												});
											}}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_ifsc_code
												)
											}
										/>
									</Box>
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => onClose()}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid || pristine || isBankInfoLoading
												? classes.disabledButton
												: classes.saveButton
										}
										disabled={invalid || pristine || isBankInfoLoading}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditMyInfoBankForm;
