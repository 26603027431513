import { gql } from '@apollo/client';

export const GET_ASSET_SOFTWARES = gql`
	query getSoftwareListById($asset_id: uuid) {
		asset_software(where: { asset_id: { _eq: $asset_id } }) {
			id
			name
			product_key
			installation_date
			note
			expiration_date
			type
		}
	}
`;
// TODO Add where conditon with asset
export const GET_USERS = gql`
	query getAssetUsedBy($limit: Int, $name: String) {
		asset(
			distinct_on: used_by
			where: {
				user: { full_name: { _ilike: $name } }
				used_by: { _is_null: false }
			}
			limit: $limit
		) {
			user {
				id
				full_name
			}
		}
		asset_aggregate(
			distinct_on: used_by
			where: { user: { full_name: { _ilike: $name } } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_ASSETS_MANAGED_BY = gql`
	query getAssetManagedBy($limit: Int, $name: String) {
		asset(
			distinct_on: managed_by
			where: {
				user: { full_name: { _ilike: $name } }
				managed_by: { _is_null: false }
			}
			limit: $limit
		) {
			userByManagedBy {
				id
				full_name
			}
		}
		asset_aggregate(
			distinct_on: managed_by
			where: { user: { full_name: { _ilike: $name } } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_DEPARTMENTS = gql`
	query getDepartments($limit: Int, $department: String) {
		asset(
			where: {
				departmentID: { name: { _ilike: $department } }
				department: { _is_null: false }
			}
			limit: $limit
			distinct_on: department
		) {
			departmentID {
				id
				name
			}
		}
		asset_aggregate(
			where: { departmentID: { name: { _ilike: $department } } }
			distinct_on: department
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_ASSET = gql`
	query getAssetById($id: uuid!) {
		asset_by_pk(id: $id) {
			id
			asset_id
			name
			type
			tag
			cost
			mac_address
			ip_address
			acquisition_date
			warranty_expiration_date
			processor
			memory
			used_by
			managed_by
			department
			hard_drive
			machine_serial_no
			model
			manufacturer
			acquisition_date
			warranty_expiration_date
			in_repair
			location_id
			org_location {
				id
				name
			}
			user {
				first_name
				last_name
				full_name
			}
			userByManagedBy {
				first_name
				last_name
				full_name
			}
			asset_type {
				type
				image
			}
			asset_note {
				id
				note
			}
			departmentID {
				id
				name
			}
			currency {
				id
				currency_type
				symbol
			}
		}
	}
`;

export const GET_SOFTWARE_BY_ID = gql`
	query getSoftwareById($id: uuid!) {
		asset_software_by_pk(id: $id) {
			id
			name
			product_key
			installation_date
			expiration_date
			asset_id
			type
		}
	}
`;
export const UPSERT_SOFTWARE = gql`
	mutation upsertSoftware($software: asset_software_insert_input!) {
		insert_asset_software_one(
			object: $software
			on_conflict: {
				constraint: asset_software_pkey
				update_columns: [
					name
					type
					product_key
					installation_date
					expiration_date
				]
			}
		) {
			id
		}
	}
`;
export const DELETE_SOFTWARE = gql`
	mutation deleteSoftware($id: uuid!) {
		update_asset_software(
			where: { id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;
export const GET_ORGANISATION_BASE_CURRENCY = gql`
	query getOrgBaseCurrency($organizationId: uuid!) {
		organization(where: { id: { _eq: $organizationId } }) {
			id
			name
			currency_id
		}
	}
`;
export const UPSERT_ASSET = gql`
	mutation upsertAsset($asset: asset_insert_input!) {
		insert_asset_one(
			object: $asset
			on_conflict: {
				constraint: asset_pkey
				update_columns: [
					cost
					department
					managed_by
					name
					asset_id
					currency_id
					type
					used_by
					in_repair
					location_id
				]
			}
		) {
			id
		}
	}
`;
export const GET_ASSET_NOTE = gql`
	query getAssetNotes($asset_id: uuid!, $SearchText: String!) {
		asset_notes(
			where: {
				deleted_at: { _is_null: true }
				asset_id: { _eq: $asset_id }
				_or: [
					{ title: { _ilike: $SearchText } }
					{ note: { _ilike: $SearchText } }
				]
			}
		) {
			user {
				first_name
				last_name
				full_name
				profile_pic
			}
			created_by
			note
			asset_id
			title
			id
		}
	}
`;

export const UPSERT_ASSET_NOTE = gql`
	mutation upsertAssetNote($assetNote: asset_notes_insert_input!) {
		insert_asset_notes_one(
			object: $assetNote
			on_conflict: {
				constraint: asset_notes_pkey
				update_columns: [note, title]
			}
		) {
			id
			title
			note
		}
	}
`;

export const DELETE_ASSET_NOTE = gql`
	mutation deleteAssetNote($noteId: uuid!) {
		update_asset_notes(
			where: { id: { _eq: $noteId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_ASSET_ID_TO_FILTER = gql`
	query getAssetIdToFilter($assetFilter: asset_bool_exp = {}) {
		asset(where: $assetFilter) {
			id
		}
	}
`;

export const GET_ASSET_DETAILS_TO_EXPORT = gql`
	query getAssetDetailsToExport($assetFilter: asset_bool_exp = {}) {
		asset(where: $assetFilter) {
			name
			asset_id
			asset_type {
				type
			}
			in_repair
			user {
				full_name
			}
			userByManagedBy {
				full_name
			}
			processor
			memory
			hard_drive
			asset_note {
				note
			}
			org_location {
				name
			}
			product_number
			model
			manufacturer
			mac_address
			cost
			warranty_expiration_date
			asset_softwares {
				name
				product_key
				installation_date
				expiration_date
				type
				note
			}
			organization {
				date_format
				currency {
					symbol
				}
			}
		}
	}
`;

export const GET_ORG_LOCATIONS = gql`
	query getOrgLocations {
		org_location(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_ACTIVE_USER_BY_LIMIT = gql`
	query getActiveUsersByLimit {
		user(where: { is_active: { _eq: true } }, order_by: { full_name: asc }) {
			id
			name: full_name
		}
	}
`;

export const GET_ASSET_TYPE = gql`
	query getAssetType {
		asset_type(order_by: { type: asc }) {
			id
			type
		}
	}
`;
