import React from 'react';

import { Box } from '@material-ui/core';
import EmployeeProjectList from '../../SharedComponents/EmployeeProjectList';

export interface Props {
	userId: string;
}

const MyInfoProjectDetails = (props: Props) => {
	const { userId } = props;

	return (
		<Box>
			<EmployeeProjectList record={userId} />
		</Box>
	);
};

export default MyInfoProjectDetails;
