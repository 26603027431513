import React from 'react';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

export class DateTimeUtil {
	static getBusinessDaysBetween(fromDate, toDate) {
		if (toDate < fromDate) {
			return 0;
		}
		let days = 0;
		for (
			let cursor = new Date(fromDate);
			cursor <= toDate;
			cursor.setDate(cursor.getDate() + 1)
		) {
			if (cursor.getDay() !== 0 && cursor.getDay() !== 6) {
				days += 1;
			}
		}
		return days;
	}
	static overlap(dateRanges) {
		return dateRanges
			.sort((previous, current) => {
				let previousTime = previous.start_date.getTime();
				let currentTime = current.start_date.getTime();
				if (previousTime < currentTime) {
					return -1;
				}
				if (previousTime === currentTime) {
					return 0;
				}
				return 1;
			})
			.reduce(
				(result, current, idx, arr) => {
					if (idx === 0) {
						return result;
					}
					let previous = arr[idx - 1];
					let previousEnd = previous.end_date.getTime();
					let currentStart = current.start_date.getTime();
					let isOverlap = previousEnd >= currentStart;
					if (isOverlap) {
						result.isOverlap = true;
						result.ranges.push({
							previous: previous,
							current: current,
						});
					}
					return result;
				},
				{ isOverlap: false, ranges: [] }
			);
	}
}

export const endDateValidation = (value, allValues) => {
	if (allValues?.start_date) {
		if (allValues?.start_date >= value) {
			return 'End date must be greater than Start date';
		}
	}
	if (allValues?.start_date_temp_3) {
		if (allValues?.start_date_temp_3 >= value) {
			return (
				<span style={{ color: 'red' }}>
					End date must be greater than Start date
				</span>
			);
		}
	}
};
export const formatDate = (date) => {
	let currentDate = new Date(date);
	let month = '' + (currentDate.getMonth() + 1);
	let day = '' + currentDate.getDate();
	let year = currentDate.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

// Function for Change the date format to DD/MM/YYYY
export const dateFormat = (date) => {
	const currentDate = new Date(date);
	var mm = currentDate.getMonth() + 1; // getMonth() is zero-based
	var dd = currentDate.getDate();

	return [
		(dd > 9 ? '' : '0') + dd,
		(mm > 9 ? '' : '0') + mm,
		currentDate.getFullYear(),
	].join('/');
};

export const getExperience = (record) => {
	const currentDate = dayjs();
	const joiningDate = dayjs(record?.join_date);
	dayjs.extend(duration);
	const psplExperienceDuration = dayjs.duration(currentDate.diff(joiningDate));
	const psplExperience =
		record?.join_date === null ? dayjs.duration(0) : psplExperienceDuration;
	const priorExperience = record?.prior_experience || 0;
	const [years, months, days] = priorExperience
		? priorExperience.split(':')
		: '';
	const priorExperienceDuration =
		record?.prior_experience === null
			? dayjs.duration(0)
			: dayjs.duration({ years, months, days });
	const totalExpDuration = psplExperience.add(priorExperienceDuration);
	const psplExperienceYear =
		psplExperienceDuration?.get('year') < 1
			? ''
			: psplExperienceDuration.format('Y [year]');
	const psplExperienceMonth =
		psplExperienceDuration?.get('month') < 1
			? ''
			: psplExperienceDuration.format('M [month]');
	const totalExperienceYear =
		totalExpDuration?.get('year') < 1
			? ''
			: totalExpDuration.format('Y [year]');
	const totalExperienceMonth =
		totalExpDuration?.get('month') < 1
			? ''
			: totalExpDuration.format('M [month]');
	const priorExperienceMonth =
		priorExperienceDuration.get('months') < 1
			? ''
			: priorExperienceDuration.format('M [month]');
	const priorExperienceYear =
		priorExperienceDuration.get('years') < 1
			? ''
			: priorExperienceDuration.format('Y [year]');
	return {
		psplExp: `${psplExperienceYear || ''}  ${psplExperienceMonth || ''}`,
		totalExp: `${totalExperienceYear || ''}  ${totalExperienceMonth || ''}`,
		prevExp: `${priorExperienceYear || ''} ${priorExperienceMonth || ''}`,
	};
};
// function to calculate the difference between a date and current date and return number of days
export const calculateDateDifference = (date) => {
	const fromDate = dayjs(date).startOf('day');
	const toDate = dayjs().startOf('day');
	return fromDate.diff(toDate, 'day');
};
export const getTotalExperience = (date) => {
	const [years, months, days] = date ? date.split(':') : '';
	return { years, months, days };
};

// this function can be used to calculate 10 days from today date
export const getNextTenDaysRange = (date) => {
	const fromDate = dayjs(date);
	const toDate = dayjs(fromDate).add(10, 'day');
	return {
		fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
		toDate: dayjs(toDate).format('YYYY-MM-DD'),
	};
};

//date formatter function formats to DD-MM-YY
export const dateFormatter = (date) => {
	return dayjs(date).format('DD-MMM-YYYY');
};

// date generator using start and end Date
export const getAllDatesInRange = (startDate, endDate) => {
	const start = dayjs(startDate, 'YYYY-MM-DD');
	const end = dayjs(endDate, 'YYYY-MM-DD');

	const daysInRange = Array.from(
		{ length: end.diff(start, 'day') + 1 },
		(_, index) => start.add(index, 'day').format('YYYY-MM-DD')
	);

	return daysInRange;
};

// Generator all the sundays between two dates
export const getSundaysBetweenDates = (startDate, endDate) => {
	const start = dayjs(startDate, 'YYYY-MM-DD');
	const end = dayjs(endDate, 'YYYY-MM-DD');

	const sundays = Array.from(
		{ length: end.diff(start, 'day') + 1 },
		(_, index) => start.add(index, 'day')
	)
		.filter((date) => date.day() === 0)
		.map((sunday) => sunday.format('YYYY-MM-DD'));

	return sundays;
};

// Generate all saturdays between two dates
export const getSaturdaysDates = (startDate, endDate) => {
	dayjs.extend(isSameOrBefore);
	const start = dayjs(startDate, 'YYYY-MM-DD');
	const end = dayjs(endDate, 'YYYY-MM-DD');

	const saturdays = [];

	let currentMonthStart = start.startOf('month');
	let currentMonthEnd = currentMonthStart.endOf('month');
	let saturdayCount = 0;

	while (
		currentMonthStart.isBefore(end) ||
		currentMonthStart.isSame(end, 'month')
	) {
		let currentDay = currentMonthStart;

		while (
			currentDay.isSameOrBefore(currentMonthEnd, 'day') &&
			currentDay.isSameOrBefore(end, 'day')
		) {
			if (currentDay.day() === 6) {
				saturdayCount++;
				saturdays.push({
					date: currentDay.format('YYYY-MM-DD'),
					ordinal: saturdayCount,
				});
			}

			currentDay = currentDay.add(1, 'day');
		}

		currentMonthStart = currentMonthStart.add(1, 'month').startOf('month');
		currentMonthEnd = currentMonthStart.endOf('month');
		saturdayCount = 0;
	}

	return saturdays;
};

// Check a day between range
export const isDateBetweenRange = (startDate, endDate, date) => {
	dayjs.extend(isBetween);
	const fromDate = dayjs(startDate);
	const toDate = dayjs(endDate);
	const checkDate = dayjs(date);

	if (checkDate.isSame(fromDate, 'day') || checkDate.isSame(toDate, 'day')) {
		return true;
	}

	return checkDate.isBetween(fromDate, toDate, null, '[]');
};
