export const TASK_CREATE_MESSAGE = 'Task created successfully';

export const TASK_UPDATE_MESSAGE = 'Task updated successfully';
export const TASK_UPDATE_FAIL_MESSAGE = 'Task updation failed';

export const SUB_TASK_CREATE_MESSAGE = 'Subtask created successfully';

export const SUB_TASK_UPDATE_MESSAGE = 'Subtask updated successfully';

export const SUB_TASK_DELETE_MESSAGE = 'Subtask deleted successfully';

export const SUB_TASK_DELETE_FAILED_MESSAGE = 'Subtask deletion failed';

export const TASK_DELETE_MESSAGE = 'Task deletion failed';

export const TASK_NAME_VALIDATION_MESSAGE = 'minimum three characters required';

export const CREATION_FAILED = 'task creation failed';

export const COMMENT_CREATE_MESSAGE = 'Comment added successfully';

export const COMMENT_UPDATE_MESSAGE = 'Comment updated successfully';

export const COMMENT_DELETED_MESSAGE = 'Comment deleted successfully';

export const COMMENT_CREATION_FAILED_MESSAGE = 'Comment creaion failed';

export const COMMENT_UPDATION_FAILED_MESSAGE = 'Comment updation failed';

export const COMMENT_DELETION_FAILED_MESSAGE = 'Comment deletion failed';

export const FLOAT_BUTTON_MENUS = [
	{
		name: 'Add Subtask',
		value: 'sub_task',
	},
	{
		name: 'Add Comment',
		value: 'comment',
	},
];

export const PROJECT_TASK_CLONE_MESSAGE =
	'You can find the clonned issue by clicking the link below';
export const PROJECT_TASK_CLONNING_COMPLETE = 'Cloning complete';
export const PROJECT_TASK_CLONNING_OPEN = 'Open clonned issue';

export const breadcumData = [{ name: 'Projects', path: '/project' }];

export const ASSIGNED_TO_TOOLTIP_LABEL =
	'User assigned to the task can only enter time again this task.';
export const BILLABLE_TOOLTIP_LABEL =
	'Selecting "False" will exclude the time entered against this task from project costing.';
export const REMINDER_TOOLTIP_LABEL =
	'Set reminder to get an email notification';
export const ESTIMATED_HOUR_TOOLTIP_LABEL =
	'Planned effort for the task in hrs';
export const PROJECT_TASK_DATA_UPLOAD_TOOLTIP_LABEL = 'Upload Tasks in Bulk';
export const PROJECT_TASK_DATA_UPLOAD_MODAL_TEXT =
	'Drag and drop, or browse your files (Excel .xlsx files only) ';
