import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import { GET_COMPANIES, GET_OWNERS } from './Opportunities.gql';
import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';

interface Company {
	id: string;
	name: string;
}

interface CompanyProps {
	crm_company: Company;
}

interface Status {
	id: string;
	label: string;
}

interface User {
	id: string;
	first_name: string;
	last_name?: string;
	full_name?: string;
}

export const OpportunityFilterFormBody = () => {
	const { id: boardId } = useParams<{ id: string }>();
	const [searchedCompanyName, setSearchedCompanyName] = useState('');
	const [searchedSalesOwner, setSearchedSalesOwner] = useState('');
	const [companies, setCompanies] = useState([]);
	const [saleOwner, setSaleOwner] = useState([]);
	const [ownerLimit, setOwnerLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [companyFilterLimit, setCompanyFilterLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const { data: crmCompanies } = ApolloUseQuery(GET_COMPANIES, {
		variables: {
			limit: companyFilterLimit,
			company: `%${searchedCompanyName}%`,
			boardId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: owners } = ApolloUseQuery(GET_OWNERS, {
		variables: {
			limit: ownerLimit,
			name: `%${searchedSalesOwner || ''}%`,
			boardId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!crmCompanies || !(crmCompanies?.opportunities?.length > 0)) {
			setCompanies([]);
			return;
		}
		const companyNames = crmCompanies?.opportunities.map(
			(company: CompanyProps) => {
				if (!company || !company?.crm_company) {
					setCompanies([]);
					return;
				}
				return {
					id: company.crm_company?.id,
					name: company.crm_company?.name,
				};
			}
		);
		const sortedCompanyNames: any = orderBy(
			companyNames,
			[(u) => u.name.toUpperCase()],
			['asc']
		);

		setCompanies(sortedCompanyNames);
	}, [crmCompanies]);

	useEffect(() => {
		if (owners?.opportunities) {
			const owner = owners.opportunities.map((user: any) => {
				return {
					id: user.leadOwner.id,
					name: `${user.leadOwner.full_name} `,
				};
			});
			const sortedOwner: any = orderBy(owner, ['name'], ['asc']);
			setSaleOwner(sortedOwner);
		}
	}, [owners]);

	const onCompanyExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setCompanyFilterLimit(
			isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null
		);
	};

	const onCompanySearch = (company: string) => {
		setSearchedCompanyName(company);
	};

	const onSalesOwnerSearch = (name: string) => {
		setSearchedSalesOwner(name);
	};

	const onSaleOwnerExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setOwnerLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	return (
		<Box>
			<FilterInput
				label={'Company'}
				sourceName='company_id'
				onExpansionToggle={onCompanyExpansionToggle}
				choices={companies || []}
				onSearch={(name) => onCompanySearch(name)}
				total={crmCompanies?.crm_company_aggregate?.aggregate?.count}
			/>
			<FilterInput
				label={'Lead Owner'}
				sourceName='owner_id'
				onExpansionToggle={onSaleOwnerExpansionToggle}
				choices={saleOwner || []}
				onSearch={(name) => onSalesOwnerSearch(name)}
				total={owners?.opportunities_aggregate?.aggregate?.count}
			/>
		</Box>
	);
};
