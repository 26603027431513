import React, { useContext, useState } from 'react';

import {
	List,
	Datagrid,
	ReferenceField,
	FunctionField,
	useNotify,
	useRefresh,
	Loading,
} from 'react-admin';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPDATE_TASK_STATUS } from './CrmTasks.gql';

import {
	Typography,
	Box,
	Tooltip,
	FormControl,
	FormControlLabel,
	Checkbox,
	IconButton,
} from '@material-ui/core';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import { Task } from './CrmTasksKanban.model';
import CrmTaskForm from './CrmTaskForm.component';
import { crmTaskListStyle } from './styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
import { UserProfileContext } from '../../App';
import { CrmTaskListPagination } from './CrmTaskListPagination';

export const CrmTaskList = ({
	searchParam,
	filters,
	refetch,
	loading,
}: {
	searchParam: string;
	filters?: { is_done?: boolean; id?: string[]; owner_id?: string[] };
	refetch?: () => void;
	loading?: boolean;
}) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const tooltipStyle = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const crmTaskListStyles = crmTaskListStyle();
	const [isTaskModalShown, setIsTaskModalShown] = useState(false);
	const [crmTask, setCrmTask] = useState<Task>({
		name: '',
	});
	const [updateCrmTask] = useApolloMutation(UPDATE_TASK_STATUS);
	const notify = useNotify();
	const refresh = useRefresh();


	const onClose = () => {
		setCrmTask({
			name: '',
			priority: 'Medium',
		});
		setIsTaskModalShown(false);
	};

	const handleEdit = (crmTask: Task) => {
		setCrmTask({
			id: crmTask?.id,
			name: crmTask?.name,
			description: crmTask?.description,
			activity_type_id: crmTask?.activity_type_id,
			opportunity_id: crmTask?.opportunity_id,
			priority: crmTask?.priority,
			due_date: crmTask?.due_date,
			owner_id: crmTask?.owner_id,
			remind_at: crmTask?.remind_at,
		});
		setIsTaskModalShown(true);
	};

	const updateTaskStatus = (task_id: string, status: boolean) => {
		updateCrmTask({
			variables: {
				taskId: task_id,
				crmTask: {
					is_done: !status,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (!refetch) {
						notify('Tasks updated successfully');
						refresh();
						return;
					}
					notify('Tasks updated successfully');
					refetch();
					refresh();
				}
			})
			.catch((error: any) => {
				if (error) {
					return;
				}
			});
	};

	if (loading) {
		return <Loading />;
	}

	const getDueDate = (dueDate: string, isDone: boolean) => {
		if (!dueDate) {
			return;
		}
		const today = dayjs();
		const taskDueDate = dayjs(dueDate);
		const dateDifference = taskDueDate.diff(today, 'days');
		if (isDone) {
			return taskDueDate.format('D-MMM-YYYY');
		}
		if (dateDifference === 0) {
			return 'Today';
		}
		if (dateDifference < 0) {
			return 'Overdue';
		}
		if (dateDifference > 0 && dateDifference <= 7) {
			return `In ${dateDifference} days`;
		}
		return taskDueDate.format('D-MMM-YYYY');
	};

	return (
		<>
			<List
				resource='crm_tasks'
				basePath='/crm_tasks'
				bulkActionButtons={false}
				actions={false}
				filter={{ name: `%${searchParam}%`, ...filters }}
				pagination={
					<CrmTaskListPagination
						userData={filters?.id?.length ?? -1}
					/>
				}
				perPage={15}
				title={' '}
				sort={{ field: 'created_at', order: 'DESC' }}
				empty={false}
			>
				<Datagrid rowClick={''}>
					<FunctionField
						label='STATUS'
						source='is_done'
						sortByOrder='ASC'
						sortBy='is_done'
						render={(crmTask: any) => (
							<FormControl style={{ height: '20px' }}>
								<FormControlLabel
									style={{ height: '20px' }}
									control={
										<Checkbox
											checked={crmTask?.is_done}
											onChange={() =>
												permissions?.crm_tasks?.update_permissions &&
												updateTaskStatus(crmTask?.id, crmTask?.is_done)
											}
											icon={
												crmTask?.is_done ? (
													<CheckCircleOutlineIcon
														className={crmTaskListStyles.activeCheckbox}
													/>
												) : (
													<CheckCircleOutlineIcon
														className={crmTaskListStyles.checkbox}
													/>
												)
											}
											checkedIcon={
												<CheckCircleOutlineIcon
													className={crmTaskListStyles.activeCheckbox}
												/>
											}
										/>
									}
									label={''}
								/>
							</FormControl>
						)}
					/>
					<FunctionField
						label='NAME'
						render={(task: any) => {
							const blocksFromHTML = convertFromHTML(task.name);
							const state = ContentState.createFromBlockArray(
								blocksFromHTML.contentBlocks,
								blocksFromHTML.entityMap
							);
							return (
								<Tooltip
									title={`${EditorState.createWithContent(state)
										.getCurrentContent()
										.getPlainText()}`}
									placement='right'
								>
									<Typography
										className={
											task?.is_done
												? [
														crmTaskListStyles.ellipsisContent,
														tooltipStyle.ellipsis,
														crmTaskListStyles.strikeThrough,
												  ].join(' ')
												: [
														crmTaskListStyles.ellipsisContent,
														tooltipStyle.ellipsis,
												  ].join(' ')
										}
									>
										{EditorState.createWithContent(state)
											.getCurrentContent()
											.getPlainText()}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						source='opportunity_id'
						label='OPPORTUNITY'
						sortByOrder='ASC'
						sortBy='opportunity.name'
						className={[
							crmTaskListStyles.ellipsisContent,
							tooltipStyle.ellipsis,
						].join(' ')}
						render={(task: any) =>
							task?.opportunity_id ? (
								<ReferenceField
									source='opportunity_id'
									reference='opportunities'
									link={false}
								>
									<FunctionField
										render={(opportunity: any) => (
											<Tooltip title={`${opportunity.name}`} placement='right'>
												<Typography
													className={[
														crmTaskListStyles.ellipsisContent,
														tooltipStyle.ellipsis,
													].join(' ')}
												>
													{opportunity.name}
												</Typography>
											</Tooltip>
										)}
									/>
								</ReferenceField>
							) : (
								'- -'
							)
						}
					/>
					<FunctionField
						source='opportunity_id'
						label='COMPANY'
						sortByOrder='ASC'
						sortBy='opportunity.crm_company.name'
						className={[
							crmTaskListStyles.ellipsisContent,
							tooltipStyle.ellipsis,
						].join(' ')}
						render={(task: any) =>
							task?.opportunity_id ? (
								<ReferenceField
									link={false}
									source='opportunity_id'
									reference='opportunities'
									label='OPPORTUNITY'
								>
									<FunctionField
										render={(opportunity: any) =>
											opportunity?.company_id ? (
												<ReferenceField
													link={false}
													source='company_id'
													reference='crm_company'
												>
													<FunctionField
														render={(company: any) => (
															<Tooltip
																title={`${company.name}`}
																placement='right'
															>
																<Typography
																	className={[
																		crmTaskListStyles.ellipsisContent,
																		tooltipStyle.ellipsis,
																	].join(' ')}
																>
																	{company.name}
																</Typography>
															</Tooltip>
														)}
													/>
												</ReferenceField>
											) : (
												'--'
											)
										}
									/>
								</ReferenceField>
							) : (
								'- -'
							)
						}
					/>
					<FunctionField
						source='owner_id'
						label='ASSIGNEE'
						sortByOrder='ASC'
						sortBy='user.first_name'
						className={[
							crmTaskListStyles.ellipsisContent,
							tooltipStyle.ellipsis,
						].join(' ')}
						render={(task: any) =>
							task?.owner_id ? (
								<ReferenceField source='owner_id' reference='user' link={false}>
									<FunctionField
										render={(user: any) => (
											<Tooltip
												title={`${user?.full_name || ''}`}
												placement='right'
											>
												<Typography
													className={[
														crmTaskListStyles.ellipsisContent,
														tooltipStyle.ellipsis,
													].join(' ')}
												>
													{`${user?.full_name || ''} `}
												</Typography>
											</Tooltip>
										)}
									/>
								</ReferenceField>
							) : (
								'- -'
							)
						}
					/>
					<FunctionField
						source='due_date'
						sortByOrder='ASC'
						sortBy='due_date'
						render={(crmTask: any) => {
							if (!crmTask?.due_date) {
								return '--';
							}
							return getDueDate(crmTask.due_date, crmTask.is_done);
						}}
					/>
					<FunctionField
						source='priority'
						label='PRIORITY'
						sortByOrder='DESC'
						sortBy='priority'
						render={(record: any) => (
							<Box
								className={`${crmTaskListStyles.priorityContainer}
          ${
						record.priority === 'High'
							? crmTaskListStyles.priorityHigh
							: record.priority === 'Medium'
							? crmTaskListStyles.priorityMedium
							: record.priority === 'Low'
							? crmTaskListStyles.priorityLow
							: undefined
					}
          `}
							>
								{record?.priority}
							</Box>
						)}
					/>
					{permissions?.crm_tasks?.update_permissions && (
						<FunctionField
							render={(crmTask: any) => (
								<Box className={datagridStyles.listEditIconContainer}>
									<IconButton
										className={datagridStyles.listEditIconButton}
										onClick={() => {
											handleEdit(crmTask);
										}}
									>
										<EditIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
							)}
						/>
					)}
				</Datagrid>
			</List>
			<CrmTaskForm
				open={isTaskModalShown}
				onClose={onClose}
				CrmTask={crmTask}
				refetch={() => {
					if (!refetch) {
						return;
					}
					refetch();
				}}
			/>
		</>
	);
};

export default CrmTaskList;
