//TODO remove this hardcoding after fixing issue
export const REPORT_ACCESS_ROLES = [
	'CXO',
	'MANAGER_HR',
	'MANAGER_BD',
	'PM_DL',
	'DM_DL',
	'RESOURCE_MANAGER',
	'ADMIN',
];
export const DELIVERY_REPORT_ACCESS_ROLES = [
	'PM_DL',
	'DM_DL',
	'CXO',
	'ADMIN',
	'RESOURCE_MANAGER',
	'MANAGER_HR',
];

export const BD_REPORT_ACCESS_ROLES = [
	'MANAGER_BD',
	'ADMIN',
	'CXO',
	'MANAGER_HR',
];
export const HR_REPORT_ACCESS_ROLES = [
	'MANAGER_HR',
	'CXO',
	'ADMIN',
	'MANAGER_HR',
];
export const SETTINGS_ACCESS_ROLES = ['CXO', 'ADMIN', 'MANAGER_HR'];
export const DEPARTMENT_ACCESS_ROLES = ['CXO', 'ADMIN', 'MANAGER_HR'];
export const DESIGNATION_ACCESS_ROLES = ['CXO', 'ADMIN', 'MANAGER_HR'];
export const USER_ACCESS_ROLES = ['CXO', 'ADMIN', 'MANAGER_HR'];
export const SKILL_ACCESS_ROLES = ['CXO', 'ADMIN', 'MANAGER_HR'];
export const INTEGRATIONS_ACCESS_ROLES = ['CXO', 'ADMIN'];
export const SYSTEM_ACCESS_ROLES = ['CXO', 'ADMIN'];
// added a dashboard  access role
// Todo-Need to remove this hardcoded permissions
export const DASHBOARD_ACCESS_ROLE = [
	'CXO',
	'ADMIN',
	'MANAGER_HR',
	'6f2c58f0-2356-4bcd-82ff-53fc5ae8f38a',
];
//
export const DEFAULT_PROFILE_PIC =
	'https://media.istockphoto.com/vectors/male-person-silhouette-profile-picture-whith-red-tie-vector-id523615585?k=6&m=523615585&s=170667a&w=0&h=yI9ogg7ksK1kSM8WvZCOCIJXhQl0DLEDtP4-s2sbHQ8=';
export const SUGGESTION_DEFAULT_PIC =
	'https://www.kemhospitalpune.org/wp-content/uploads/2020/12/Profile_avatar_placeholder_large.png';

export const MAX_FILTER_OPTIONS_SHOWN = 8;

// colors for billable pie chart in insight view
export const billablePieChartColor = ['#196CF5', '#ACCBFF', '#292929'];

// colors for user details pie in the dashboard
export const dashboardPieChartColors = ['#FF6492', '#141446', '#7A77FF'];

// DEFAULT TIME FOR REMINDERS
export const DEFAULT_REMINDER_TIME = {
	hour: 9,
	minutes: 0,
	seconds: 0,
};

export const applyingForLeaveOnNonWorkingDaysErrorMsg =
	'You cannot apply for leave on weekends Or Holidays.';

export const exportTimesheetReportUsersOptions = [
	'All',
	'Active Users',
	'Inactive Users',
];
