import React, { useContext, useState } from 'react';

import {
	Typography,
	makeStyles,
	Box,
	Tabs,
	Tab,
	Theme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	ThemeProvider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { accordionMenuTheme } from '../../Layout/Theme.component';
import { OrganisationGeneralSettings } from './Organisation/OrganisationGeneralSettings.component';
import Holidays from './Organisation/Holidays.component';
import UsersList from './UserManagement/UsersList.component';
import OrganisationShiftAndTiming from './Organisation/OrganisationShiftAndTiming.component';
import RoleList from './RoleManagement/RoleList.component';
import HrmsGeneralSettings from './HRMS/HrmsGeneralSettings.component';
import LeaveManagement from './LeaveManagement/LeaveManagement.component';
import CheckListSettings from './Checklist/ChecklistSettings.component';
import KanbanSettings from './Kanban/KanbanSettings.component';
import TimesheetSettings from './Timesheet/TimsheetSettings.component';
import ProjectSettings from './Project/ProjectStatusSettings.component';
import { UserProfileContext } from '../../App';
import UserDataUpload from './Datamanagement/UserDataUpload.component';
import ProjectDataUpload from './Datamanagement/ProjectDataUpload.component';
import AttendanceSettings from './Attendance/AttendanceSettings.component';

const useStyles = makeStyles((theme: Theme) => ({
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	container: {
		flexGrow: 1,
		display: 'flex',
		height: 224,
	},
	menuContainer: {
		width: '248px',
		maxHeight: 'fit-content',
		background: '#ECF1F4',
		borderRadius: '4px',
		padding: '12px',
	},
	contentContainer: {
		borderRadius: '4px',
		width: '100%',
		overflowY: 'auto',
		maxHeight: '82vh',
		overflowX: 'hidden',
		'& > div > div': {
			padding: '0px',
		},
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} width='100%'>
					{children}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface ExpansionDetails {
	isOrganisationExpanded?: boolean;
}

export const Settings = () => {
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const { featureFlag } = useContext<any>(UserProfileContext);

	const [initialExpansion, setInitialExpansion] = useState<ExpansionDetails>({
		isOrganisationExpanded: true,
	});

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	const getSelectedIndexWithinTabRange = (
		indexes: number[],
		selectedIndex: number
	) => {
		return indexes.includes(selectedIndex) ? selectedIndex : false;
	};

	return (
		<>
			<Typography className={classes.pageTitle}>Settings</Typography>
			<Box className={classes.container}>
				<ThemeProvider theme={accordionMenuTheme}>
					<Box className={classes.menuContainer}>
						<Accordion
							expanded={initialExpansion.isOrganisationExpanded}
							onChange={() => {
								setInitialExpansion({
									...initialExpansion,
									isOrganisationExpanded:
										!initialExpansion.isOrganisationExpanded,
								});
							}}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>Organisation</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Tabs
									orientation='vertical'
									value={getSelectedIndexWithinTabRange(
										[0, 1, 2, 3],
										selectedTabIndex
									)}
									onChange={handleChange}
									TabIndicatorProps={{
										style: {
											display: 'none',
										},
									}}
								>
									<Tab value={0} label='General' {...a11yProps(0)} />
									<Tab value={1} label='Leave Management' {...a11yProps(1)} />
									<Tab value={2} label='Shift And Timing' {...a11yProps(2)} />
									<Tab value={3} label='Holidays' {...a11yProps(3)} />
								</Tabs>
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>User Management</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Tabs
									orientation='vertical'
									value={getSelectedIndexWithinTabRange([4], selectedTabIndex)}
									onChange={handleChange}
									TabIndicatorProps={{
										style: {
											display: 'none',
										},
									}}
								>
									<Tab value={4} label='Users' {...a11yProps(4)} />
								</Tabs>
							</AccordionDetails>
						</Accordion>

						{featureFlag?.RoleManagement && (
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Role Management</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Tabs
										orientation='vertical'
										value={getSelectedIndexWithinTabRange(
											[5],
											selectedTabIndex
										)}
										onChange={handleChange}
										TabIndicatorProps={{
											style: {
												display: 'none',
											},
										}}
									>
										<Tab value={5} label='Roles' {...a11yProps(5)} />
									</Tabs>
								</AccordionDetails>
							</Accordion>
						)}

						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>Master Data</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Tabs
									orientation='vertical'
									value={getSelectedIndexWithinTabRange(
										[6, 7, 8, 9, 10, 11],
										selectedTabIndex
									)}
									onChange={handleChange}
									TabIndicatorProps={{
										style: {
											display: 'none',
										},
									}}
								>
									<Tab value={6} label='HRMS' {...a11yProps(6)} />
									<Tab value={7} label='Kanban Settings' {...a11yProps(7)} />
									<Tab value={8} label='Checklist' {...a11yProps(8)} />
									<Tab value={9} label='Timesheet' {...a11yProps(9)} />
									<Tab value={10} label='Project' {...a11yProps(10)} />
									<Tab value={11} label='Attendance' {...a11yProps(11)} />
								</Tabs>
							</AccordionDetails>
						</Accordion>

						{/* DATA MANAGEMENT */}
						{featureFlag?.bulkDataUpload && (
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Data Management</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Tabs
										orientation='vertical'
										value={getSelectedIndexWithinTabRange(
											[12, 13,],
											selectedTabIndex
										)}
										onChange={handleChange}
										TabIndicatorProps={{
											style: {
												display: 'none',
											},
										}}
									>
										<Tab
											value={12}
											label='User Data Upload'
											{...a11yProps(12)}
										/>
										<Tab
											value={13}
											label='Project Data Upload'
											{...a11yProps(13)}
										/>
									</Tabs>
								</AccordionDetails>
							</Accordion>
						)}
					</Box>
				</ThemeProvider>

				<Box className={classes.contentContainer}>
					<TabPanel value={selectedTabIndex} index={0}>
						<OrganisationGeneralSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={1}>
						<LeaveManagement />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={2}>
						<OrganisationShiftAndTiming />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={3}>
						<Holidays />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={4}>
						<UsersList />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={5}>
						<RoleList />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={6}>
						<HrmsGeneralSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={7}>
						<KanbanSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={8}>
						<CheckListSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={9}>
						<TimesheetSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={10}>
						<ProjectSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={11}>
						<AttendanceSettings />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={12}>
						<UserDataUpload />
					</TabPanel>
					<TabPanel value={selectedTabIndex} index={13}>
						<ProjectDataUpload />
					</TabPanel>
				</Box>
			</Box>
		</>
	);
};

export default Settings;
