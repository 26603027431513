import React, { useContext, useEffect, useState } from 'react';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import LeavePolicyManagement from './LeavePolicyManagement.component';
import LeaveCalendarYear from './LeaveCalendarYear';
import { UserProfileContext } from '../../../App';
import { head } from 'lodash';
import { useGetOrganisationLeaveYearDetailsQuery } from '../../../generated/graphql';

export const LeaveManagement = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const [organisationLeaveDetails, setorganisationLeaveDetails] = useState({});
	const {
		data: organisation,
		refetch: refetchOrganisation,
		loading,
	} = useGetOrganisationLeaveYearDetailsQuery({
		variables: {
			organisationId: organisationId,
		},
	});

	useEffect(() => {
		if (!organisation) {
			return;
		}
		setorganisationLeaveDetails({
			leave_calendar_year_start_month: head(organisation?.organization)
				?.leave_calendar_year_start_month,
			leave_calendar_year_start_date: head(organisation?.organization)
				?.leave_calendar_year_start_date,
			leave_calendar_year_end_month: head(organisation?.organization)
				?.leave_calendar_year_end_month,
			leave_calendar_year_end_date: head(organisation?.organization)
				?.leave_calendar_year_end_date,
		});
	}, [organisation]);

	return (
		<>
			<SettingsDetailContainer heading='Leave Calendar Year'>
				<LeaveCalendarYear
				loader={loading}
					organisationId={organisationId}
					organisationLeaveDetails={organisationLeaveDetails}
					onSuccess={() => {
						refetchOrganisation();
					}}
				/>
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Leave Management'>
				
				<LeavePolicyManagement />
			</SettingsDetailContainer>
		</>
	);
};

export default LeaveManagement;
