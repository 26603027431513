import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { modalFormStyle } from '../../Layout/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import { Form } from 'react-final-form';
import { TextInput } from 'react-admin';

const useStyles = makeStyles({
	detailsContainer: {
		width: '442px',
		height: 'fit-content',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	buttonContainer: {
		paddingBottom: '0',
	},
});

interface Props {
	onClose: () => void;
	onSubmit: (value: { notes: string }) => void;
	open: boolean;
	okButtonLabel?: string;
	isLoading?: boolean;
}

function TimesheetCommentCapture(props: Props) {
	const {
		open,
		onClose,
		okButtonLabel = 'Ok',
		onSubmit,
		isLoading = false,
	} = props;
	const formStyles = modalFormStyle();
	const warningModalStyles = useStyles();
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				disableBackdropClick
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={warningModalStyles.detailsContainer}>
					<Form onSubmit={onSubmit}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={formStyles.formContainer}>
									<Typography className={formStyles.label}>Notes*</Typography>
									<TextInput
										source='notes'
										label=''
										multiline={true}
										validate={(value) => {
											if (!value || value?.trim() === '') {
												return 'Required';
											}
										}}
									/>
								</Box>

								<Box className={warningModalStyles.buttonAlignment}>
									<Box
										className={`${formStyles.buttonContainer} ${warningModalStyles.buttonContainer}`}
									>
										<Button
											onClick={onClose}
											className={formStyles.cancelButton}
										>
											Cancel
										</Button>
										<Button
											type='submit'
											// onClick={onSubmit}
											className={
												isLoading || invalid
													? formStyles.disabledButton
													: formStyles.saveButton
											}
											disabled={isLoading || invalid}
											startIcon={
												isLoading && (
													<CircularProgress
														style={{
															height: '20px',
															width: '20px',
															color: '#FFFFFF',
														}}
													/>
												)
											}
										>
											{okButtonLabel}
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
}

export default TimesheetCommentCapture;
