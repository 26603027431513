import React, { useContext } from 'react';
import { UserProfileContext } from '../../App';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';
import MyInfoBasicDetails from './MyInfoBasicDetails.component';
import MyInfoBankDetails from './MyInfoBankDetails.component';
import MyInfoAssetDetails from './MyInfoAssetDetails.component';
import MyInfoSkillDetail from './MyInfoSkillDetail.component';
import MyInfoDocument from './MyInfoDocument.component';
import MyInfoProjectDetails from './MyInfoProjectDetails.component';
import { Props } from './model';
import EmployeeHolidayList from '../../SharedComponents/EmployeeHolidayList.component';
import EmployeeSkillDetail from '../Employees/DetailsPage/EmployeeSkillDetail.component';
import { editableColumnsPermissions } from './constant';
import { FieldPermissionsContext } from './MyInfoView.component';
import { EmployeeMyTeam } from '../../SharedComponents/EmployeeMyTeam';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

const MyInfoViewRightSidePanel = (props: Props) => {
	const { myInfoViewDetails, refetchContractor, refetchEmployee } = props;
	const {
		tabGlobalIndex: selectedTabIndex,
		setTabGlobalIndex: setSelectedTabIndex,
	} = useContext<any>(UserProfileContext);
	const { editableColumns } = useContext<any>(FieldPermissionsContext);
	const classes = useStyles();
	const handleChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	const CustomTab = withStyles((theme: any) => ({
		root: {
			textTransform: 'none',
			minWidth: 72,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: '10px',
			marginLeft: theme.spacing(1.8),
			fontFamily: 'Manrope-Medium',
			fontSize: '12px !important',
			letterSpacing: '0px',
			color: '#898989 ',
			'&$selected': {
				color: '#292929',
				fontFamily: 'Manrope-Bold',
			},
		},
	}))((props: any) => <Tab disableRipple {...props} />);

	const CustomTabs = withStyles({
		root: {
			borderBottom: '1px solid #e8e8e8',
		},
		indicator: {
			backgroundColor: '#1890ff',
		},
	})(Tabs);

	const CustomTabPanel = (props: any) => {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`scrollable-prevent-tabpanel-${index}`}
				aria-labelledby={`scrollable-prevent-tab-${index}`}
				{...other}
			>
				{value === index && <Box p={3}>{children}</Box>}
			</div>
		);
	};

	const getRecord = () => {
		if (myInfoViewDetails) {
			const { employee_id, ...restRecord } = myInfoViewDetails;
			return { ...restRecord };
		}
	};

	return (
		<Box className={classes.root}>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab
					key={0}
					label={
						myInfoViewDetails?.user_type === 'employee'
							? 'Employee Details'
							: 'Consultant Details'
					}
				/>
				<CustomTab key={1} label='Skills' />
				<CustomTab key={2} label='Bank Details' />
				<CustomTab key={3} label='Documents' />
				<CustomTab key={4} label='Assets' />
				<CustomTab key={5} label='Project' />
				<CustomTab key={6} label='My Team' />
				<CustomTab key={7} label='Holidays' />
			</CustomTabs>
			<CustomTabPanel value={selectedTabIndex} index={0}>
				<MyInfoBasicDetails
					myInfoViewDetails={myInfoViewDetails}
					refetchEmployee={() => {
						if (refetchEmployee) {
							refetchEmployee();
							return;
						}
						return;
					}}
					refetchContractor={() => {
						if (refetchContractor) {
							refetchContractor();
							return;
						}
						return;
					}}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={1}>
				{!editableColumns?.includes(editableColumnsPermissions.emp_skill) ? (
					<MyInfoSkillDetail myInfoViewDetails={myInfoViewDetails} />
				) : (
					<EmployeeSkillDetail record={getRecord()} />
				)}
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={2}>
				<MyInfoBankDetails
					myInfoViewDetails={myInfoViewDetails}
					refetchEmployee={() => {
						if (refetchEmployee) {
							refetchEmployee();
							return;
						}
						return;
					}}
					refetchContractor={() => {
						if (refetchContractor) {
							refetchContractor();
							return;
						}
						return;
					}}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={3}>
				<MyInfoDocument
					document_id={myInfoViewDetails?.document_id}
					userType={myInfoViewDetails?.user_type}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={4}>
				<MyInfoAssetDetails userId={myInfoViewDetails?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={5}>
				<MyInfoProjectDetails userId={myInfoViewDetails?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={6}>
				<EmployeeMyTeam userId={myInfoViewDetails?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={7}>
				<EmployeeHolidayList isManagerView={false} />
			</CustomTabPanel>
		</Box>
	);
};

export default MyInfoViewRightSidePanel;
