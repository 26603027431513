import React, { useState, useEffect, useContext } from 'react';
import {
	TextInput,
	SelectInput,
	ReferenceInput,
	required,
	useNotify,
	useRefresh,
	number,
	RadioButtonGroupInput,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
	currencySelectBox,
	modalFormTheme,
	currencyTextBox,
} from '../../Layout/Theme.component';
import { currencyInputStyle, modalFormStyle } from '../../Layout/styles';
import { Form, Field } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import {
	GET_ASSET_TYPE,
	GET_ORGANISATION_BASE_CURRENCY,
	UPSERT_ASSET,
} from './gqlQueries';
import head from 'lodash/head';
import { UserProfileContext } from '../../App';
import { OrganisationBaseCurrency } from './AssetManagement.model';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import {
	useGetActiveUsersByLimitQuery,
	useGetDepartmentOptionsQuery,
	useGetLocationsByOrgIdQuery,
} from '../../generated/graphql';

interface FormValues {
	name?: string;
	type?: string;
	tag?: string;
	cost?: number;
	used_by?: string;
	managed_by?: string;
	department?: string;
	note?: string;
	currency_id?: string;
	asset_id?: string;
	in_repair?: boolean;
	location_id?: string;
}
interface Props {
	closeModal: () => void;
	open: boolean;
	record?: any;
	refetch: () => void;
}

interface AssetType {
	id: string;
	type: string;
}

export const AddAssetModal = (props: Props) => {
	const { closeModal, open, record: asset, refetch } = props;
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const classes = modalFormStyle();
	const currencyStyle = currencyInputStyle();
	const [userId, setUserId] = useState<string | null>(null);
	const [managerId, setManagerId] = useState<string | null>(null);
	const [departmentId, setDepartmentId] = useState<string | null>(null);
	const [assetTypeId, setAssetTypeId] = useState<string | null>(null);
	const [locationId, setLocationId] = useState<string | null>(null);
	const [initialFormValues, setInitialFormValues] = useState<FormValues | null>(
		null
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isAssetIdUniqueViolationError, setIsIdUniqueViolationError] =
		useState(false);
	const notify = useNotify();
	const refresh = useRefresh();
	const history = useHistory();
	const { data: organisation } = useApolloQuery<OrganisationBaseCurrency>(
		GET_ORGANISATION_BASE_CURRENCY,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);
	const { data: assetType } = useApolloQuery(GET_ASSET_TYPE);

	const { data: location } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});
	const [
		upsertAsset,
		{ error: assetCreateError, loading: isUpsertAssetLoading },
	] = useApolloMutation(UPSERT_ASSET);
	const { data: activeUsers } = useGetActiveUsersByLimitQuery();
	const { data: departmentOptions } = useGetDepartmentOptionsQuery();
	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const closeDialogue = () => {
		setIsOpen(false);
		setIsIdUniqueViolationError(false);
		setUserId(null);
		setInitialFormValues(null);
		closeModal();
		if (!id) {
			setAssetTypeId('');
			setUserId('');
			setManagerId('');
			setDepartmentId('');
			setLocationId('');
		}
	};

	useEffect(() => {
		if (!assetCreateError) {
			return;
		}
		if (assetCreateError.message.includes('asset_asset_id_key')) {
			setIsIdUniqueViolationError(true);
		}
	}, [assetCreateError]);
	useEffect(() => {
		setInitialFormValues({
			currency_id: head(organisation?.organization)?.currency_id,
			in_repair: false,
		});
		if (!id) {
			return;
		}
		if (open) {
			setInitialFormValues({
				name: asset?.name,
				type: asset?.type,
				cost: asset?.cost,
				used_by: asset?.used_by,
				managed_by: asset?.managed_by,
				department: asset?.department,
				note: asset?.asset_note?.note,
				asset_id: asset?.asset_id,
				currency_id: head(organisation?.organization)?.currency_id,
				in_repair: asset?.in_repair,
				location_id: asset?.location_id,
			});
		}

		setAssetTypeId(asset?.type);
		setUserId(asset?.used_by);
		setManagerId(asset?.managed_by);
		setDepartmentId(asset?.department);
		setLocationId(asset?.location_id);
	}, [asset, organisation, id, open]);
	const onSave = (formValue: FormValues) => {
		updateAsset(formValue);
	};
	const updateAsset = (formValue: any) => {
		upsertAsset({
			variables: {
				asset: {
					id: id,
					cost: formValue?.cost,
					department: formValue?.department_id,
					managed_by: formValue?.managed_by,
					name: formValue?.name,
					asset_id: formValue?.asset_id,
					currency_id: formValue?.currency_id,
					type: formValue?.type,
					used_by: userId ? userId : formValue.used_by || null,
					in_repair: formValue?.in_repair,
					location_id: formValue?.location_id,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (id) {
						notify('Asset updated Successfully');
						refetch();
					} else {
						notify('Asset Created Successfully');
						history.push({
							pathname: `asset/${response?.data?.insert_asset_one?.id}`,
						});
					}
					closeDialogue();
					refresh();
				}
			})
			.catch((error: any) => {
				if (error) {
					return;
				}
			});
	};

	const validateConsultantFormValues = async () => {
		const assetFormErrors = {} as FormValues;
		if (isAssetIdUniqueViolationError) {
			assetFormErrors.asset_id = 'Id already exist!';
		}
		return assetFormErrors;
	};

	const getActiveUser = (userId: string) => {
		const response = activeUsers?.user.find((user) => user?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getDepartment = (deptId: string) => {
		const response = departmentOptions?.department.find(
			(department) => department?.id === deptId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getLocation = (locatId: string) => {
		const response = location?.org_location?.find(
			(location) => location.id === locatId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getAssetType = (assetTypeId: string) => {
		const response = assetType?.asset_type?.find(
			(type: AssetType) => type.id === assetTypeId
		);
		return response ? { id: response?.id, name: response?.type } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={isOpen} onClose={closeDialogue} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>
							{id ? 'EDIT ASSET' : 'ADD ASSET'}
						</Box>
						<CloseIcon onClick={closeDialogue} className={classes.closeIcon} />
					</Box>
					<Form
						onSubmit={onSave}
						initialValues={initialFormValues}
						validate={validateConsultantFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box className={classes.label}>Name *</Box>
									<TextInput
										source='name'
										fullWidth={true}
										label={false}
										validate={required()}
									/>
									<Box className={classes.label}>Asset ID *</Box>
									<TextInput
										source='asset_id'
										fullWidth={true}
										label={false}
										validate={required()}
										onChange={() => setIsIdUniqueViolationError(false)}
									/>

									<Box className={classes.label}>
										<Typography className={classes.label}>
											Asset Type*
										</Typography>
										<Field name='type'>
											{(props: any) => (
												<AutocompleteSearch
													placeholder={'Search asset type'}
													name={'type'}
													option={
														assetType && assetType?.asset_type
															? assetType?.asset_type?.map(
																	(value: AssetType) => {
																		return {
																			id: value.id,
																			name: value.type,
																		};
																	}
															  )
															: []
													}
													onChange={(event) => {
														props.input.onChange(event);
														setAssetTypeId(event);
													}}
													value={assetTypeId ? getAssetType(assetTypeId) : null}
													validates={true}
												/>
											)}
										</Field>
									</Box>

									<Box className={classes.multipleInputContainer}>
										<Box width='50%'>
											<Box className={classes.label}>Asset Cost(USD)</Box>
											<Box className={currencyStyle.boxContainer}>
												<Box className={currencyStyle.selectCurrency}>
													<ThemeProvider theme={currencySelectBox}>
														<ReferenceInput
															source='currency_id'
															reference='master_currencies'
															label=''
															sort={{ field: 'currency_type', order: 'ASC' }}
														>
															<SelectInput
																optionText='currency_type'
																disabled={true}
															/>
														</ReferenceInput>
													</ThemeProvider>
												</Box>
												<ThemeProvider theme={currencyTextBox}>
													<TextInput
														source='cost'
														label={''}
														validate={number()}
													/>
												</ThemeProvider>
											</Box>
										</Box>
										<Box
											className={classes.multipleInput}
											width={'45% !important'}
										>
											<Typography className={classes.radioButtonLabel}>
												In Repair
											</Typography>
											<RadioButtonGroupInput
												source='in_repair'
												label=''
												choices={[
													{ id: true, name: 'Yes' },
													{ id: false, name: 'No' },
												]}
											/>
										</Box>
									</Box>

									<Box className={classes.multipleInputContainer}>
										<Box width='50%'>
											<Typography className={classes.label}>Used By</Typography>
											<Field name='used_by'>
												{(props: any) => (
													<AutocompleteSearch
														placeholder={'Search used by'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={(event) => {
															props.input.onChange(event);
															setUserId(event);
														}}
														value={getActiveUser(userId || '')}
														name={'used_by'}
														validates={false}
													/>
												)}
											</Field>
										</Box>
										<Box width='45%'>
											<Typography className={classes.label}>
												Managed By
											</Typography>
											<Field name='managed_by'>
												{(props: any) => (
													<AutocompleteSearch
														placeholder={'Search Managed by'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={(event) => {
															props.input.onChange(event);
															setManagerId(event);
														}}
														value={getActiveUser(managerId || '')}
														name={'managed_by'}
														validates={false}
													/>
												)}
											</Field>
										</Box>
									</Box>
									<Box className={classes.label}>Department</Box>
									<Field name='department_id'>
										{(props: any) => (
											<AutocompleteSearch
												placeholder={'Search Department'}
												option={
													departmentOptions?.department.map((department) => ({
														id: department.id,
														name: `${department.name}`,
													})) || []
												}
												onChange={(event) => {
													props.input.onChange(event);
													setDepartmentId(event);
												}}
												value={getDepartment(departmentId || '')}
												name={'department_id'}
												validates={false}
											/>
										)}
									</Field>
									<Box className={classes.label}>Location</Box>
									<Field name='location_id'>
										{(props: any) => (
											<AutocompleteSearch
												placeholder={'Search Location'}
												option={
													location && location?.org_location
														? location?.org_location?.map((value) => {
																return {
																	id: value?.id,
																	name: value?.name,
																};
														  })
														: []
												}
												onChange={(event) => {
													props.input.onChange(event);
													setLocationId(event);
												}}
												value={getLocation(locationId || '')}
												name={'location_id'}
												validates={false}
											/>
										)}
									</Field>
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={closeDialogue}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid || pristine || isUpsertAssetLoading
												? classes.disabledButton
												: classes.saveButton
										}
										disabled={invalid || pristine || isUpsertAssetLoading}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};
export default AddAssetModal;
