import React from 'react';
import { AutocompleteArrayInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	useGetDepartmentOptionsQuery,
	useMapEmployeesWithOrgShiftMutation,
	useUpsertOrgShiftMappingsMutation,
} from '../../../generated/graphql';
import { difference } from 'lodash';

const employeeMappingFormStyle = makeStyles({
	container: {
		width: '49vw',
		padding: '20px',
		// minHeight: '500px',
		borderRadius: '4px',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '54px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
	warningMessage: {
		fontFamily: 'Manrope-medium',
		color: '#EA4335',
		marginBottom: '8px',
	},
});

interface orgShiftMapping {
	orgShiftMappingId: any;
	department_id: any;
}
interface OrgShiftDeptMappings {
	orgShiftMappings?: orgShiftMapping[];
	department_id?: any[];
}
interface OrgShiftEmployeesMappingProps {
	open: boolean;
	onClose: () => void;
	orgShiftId: any;
	refetch: () => void;
	orgShiftMappings?: OrgShiftDeptMappings;
}

export const OrgShiftEmployeeMappingForm = (
	props: OrgShiftEmployeesMappingProps
) => {
	const { open, onClose, orgShiftId, refetch, orgShiftMappings } = props;
	const modalFormStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const employeeMappingFormStyles = employeeMappingFormStyle();
	const { data: departmentOptions } = useGetDepartmentOptionsQuery();
	const [mapEmployeesWithOrgShift] = useMapEmployeesWithOrgShiftMutation();
	const [upsertOrgShiftMappings] = useUpsertOrgShiftMappingsMutation();

	const handleSubmit = (formValues: { departments: string[] }) => {
		if (!formValues.departments) {
			return;
		}
		const shiftId = orgShiftId;
		const removedDepartments = difference(
			orgShiftMappings?.department_id,
			formValues.departments
		);

		const deletedDepartments = [
			...formValues.departments,
			...removedDepartments,
		];
		upsertOrgShiftMappings({
			variables: {
				departmentIdsToDelete: deletedDepartments,
				data: formValues.departments.map((departmentId) => ({
					id: orgShiftMappings?.orgShiftMappings?.find(
						(orgShiftMapping: orgShiftMapping) =>
							orgShiftMapping.department_id === departmentId
					)?.orgShiftMappingId,
					org_shift_id: orgShiftId,
					department_id: departmentId,
				})),
			},
		}).then((response) => {
			mapEmployeesWithOrgShift({
				variables: {
					departmentIds: formValues.departments,
					setEmployeeShift: {
						org_shift_id: shiftId,
					},
					// Set null for removed employees But future we have to move this to default shift
					removedDepartmentIds: removedDepartments || [],
					removeEmployeeShift: {
						org_shift_id: null,
					},
					removeContractorShift: {
						org_shift_id: null,
					},
					setContractorShift: {
						org_shift_id: shiftId,
					},
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify(`Shift mapping has been updated successfully`);
						refetch();
						refresh();
						onClose();
						return;
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={employeeMappingFormStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							Employee Mapping
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={onClose}
						/>
					</Box>
					<Form
						initialValues={
							orgShiftMappings && orgShiftMappings?.department_id
								? {
										departments: orgShiftMappings.department_id,
								  }
								: {}
						}
						onSubmit={handleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography
										className={employeeMappingFormStyles.warningMessage}
									>
										Warning : Employees belonging to this department(s) will be
										moved from any other associated shift and will be re
										assigned this shift.
									</Typography>
									<Typography className={modalFormStyles.label}>
										Select Departments
									</Typography>
									<AutocompleteArrayInput
										source='departments'
										label=''
										choices={departmentOptions?.department.map(
											(department) => ({
												id: department.id,
												name: department.name,
												// name: `${department.name}(${department?.employees_aggregate?.aggregate?.count})`,
											})
										)}
									/>
								</Box>
								<Box className={modalFormStyles.buttonContainer}>
									<Button
										className={
											invalid || pristine
												? `${modalFormStyles.disabledButton} ${employeeMappingFormStyles.saveButton}`
												: `${modalFormStyles.saveButton} ${employeeMappingFormStyles.saveButton}`
										}
										disabled={invalid || pristine}
										type='submit'
									>
										Add
									</Button>
									<Button
										onClick={onClose}
										className={modalFormStyles.cancelButton}
									>
										Cancel
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default OrgShiftEmployeeMappingForm;
