import dayjs from 'dayjs';
import { getRoundedNumberFormat } from '../Utils/string.util';
// we can add constant values for Reports and insights here

//function to calculate current financial year range
// input financial year start date, end date, start month, end month
interface LeaveCalenderObject {
	leave_calendar_year_start_month: string | null | undefined;
	leave_calendar_year_end_month: string | null | undefined;
	leave_calendar_year_start_date: string | null | undefined;
	leave_calendar_year_end_date: string | null | undefined;
}
interface FinancialObject {
	financial_year_start_month: string | null | undefined;
	financial_year_end_month: string | null | undefined;
	financial_year_start_date: string | null | undefined;
	financial_year_end_date: string | null | undefined;
}
export const calculateCurrentFinancialYear = (
	financialYearObject: FinancialObject
) => {
	if (
		!financialYearObject ||
		!financialYearObject?.financial_year_start_month
	) {
		return;
	}
	const startDate = dayjs()
		.date(1)
		.month(parseInt(financialYearObject?.financial_year_start_month))
		.format('YYYY-MM-DD');

	const calculatedStartDate =
		dayjs() < dayjs(startDate)
			? dayjs(startDate)
					.year(dayjs().year() - 1)
					.format('YYYY-MM-DD')
			: dayjs(startDate).format('YYYY-MM-DD');
	const calculatedEndDate =
		dayjs() < dayjs(startDate)
			? dayjs(startDate)
					.year(dayjs().year() - 1)
					.add(12, 'months')
					.subtract(1, 'day')
					.format('YYYY-MM-DD')
			: dayjs(startDate)
					.add(12, 'months')
					.subtract(1, 'day')
					.format('YYYY-MM-DD');
	return { startDate: calculatedStartDate, endDate: calculatedEndDate };
};
export const calculateCurrentLeaveCalenderYear = (
	leaveCalenderYearObject: LeaveCalenderObject
) => {
	if (
		!leaveCalenderYearObject ||
		!leaveCalenderYearObject?.leave_calendar_year_start_month
	) {
		return;
	}
	const startDate = dayjs()
		.date(1)
		.month(parseInt(leaveCalenderYearObject?.leave_calendar_year_start_month))
		.format('YYYY-MM-DD');

	const calculatedStartDate =
		dayjs() < dayjs(startDate)
			? dayjs(startDate)
					.year(dayjs().year() - 1)
					.format('YYYY-MM-DD')
			: dayjs(startDate).format('YYYY-MM-DD');
	const calculatedEndDate =
		dayjs() < dayjs(startDate)
			? dayjs(startDate)
					.year(dayjs().year() - 1)
					.add(12, 'months')
					.subtract(1, 'day')
					.format('YYYY-MM-DD')
			: dayjs(startDate)
					.add(12, 'months')
					.subtract(1, 'day')
					.format('YYYY-MM-DD');
	return { startDate: calculatedStartDate, endDate: calculatedEndDate };
};

export const getCurrencyFormatter = (
	number: number,
	denomination: string,
	isRounded: boolean
) => {
	if (isRounded) {
		switch (denomination) {
			case 'USD':
				return `$ ${getRoundedNumberFormat(number)}`;
			case 'INR':
				return `₹ ${getRoundedNumberFormat(number)}`;
			default:
				return `${getRoundedNumberFormat(number)}`;
		}
	}
};
