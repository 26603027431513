import React, { useState, useContext } from 'react';
import { useNotify, Loading } from 'react-admin';

import {
	OpportunityCardView,
	OpportunityKanbanColumnView,
} from './OpportunitiesKanban.model';
import { Link } from 'react-router-dom';
import { Kanban } from '../Kanban/Kanban.component';
import {
	formatDecimal,
	getCurrencyFormat,
	isFloat,
} from '../../Utils/string.util';

import { Avatar, Link as MuiLink } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AddIcon from '@material-ui/icons/Add';
import AddTaskEditor from './AddTaskEditor.component';
import { UserProfileContext } from '../../App';
import {
	useUpdateOpportunityCloseDateMutation,
	useUpdateOpportunityKanbanCardMutation,
} from '../../generated/graphql';
import dayjs from 'dayjs';
import './kanban.css';
import Popover from '@material-ui/core/Popover';

const addTaskButtonTheme = createTheme({
	overrides: {
		MuiButton: {
			startIcon: {
				marginRight: '4px',
			},
		},
		MuiSvgIcon: {
			root: {
				fontSize: '14px !important',
			},
		},
	},
});
interface Stage {
	name: string;
	sum: number;
}
interface OpportunitiesKanbanProps {
	addOpportunity: (kanbanColumnId: string) => void;
	filterFormValue: any;
	stageValue: Stage[];
	refetchStageValue: () => void;
	kanbanColumnViews: OpportunityKanbanColumnView[];
	isKanbanLoading: boolean;
}

interface CrmTaskStatus {
	id: string;
	state: string;
	name: string;
}

export const OpportunitiesKanban = ({
	addOpportunity,
	stageValue,
	refetchStageValue,
	kanbanColumnViews,
	isKanbanLoading,
}: OpportunitiesKanbanProps) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const [opporunityId, setOpportunityId] = useState('');
	const notify = useNotify();
	const [updateOpportunityCloseDate] = useUpdateOpportunityCloseDateMutation();
	const [updateOpportunityKanbanCard] =
		useUpdateOpportunityKanbanCardMutation();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setOpportunityId('');
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const popOverId = open ? 'addTaskEditor-popover' : undefined;

	const cardBody = (card: OpportunityCardView) => (
		<MuiLink
			component={Link}
			to={`/opportunities/${card?.opportunityId}`}
			underline='none'
			className={'navigationLinkContainer'}
		>
			<div
				className={
					card.numberOfOverDueTasks > 0 ? 'overDueCardContent' : 'cardContainer'
				}
			>
				<div className={'cardTitleContainer'}>
					<p title={card.name} className={'cardTitle'}>
						{card.name}
					</p>
				</div>

				<div className={'opportunityCardContainer'}>
					<div className={'actions'}>
						{permissions?.crm_tasks?.insert_permissions && (
							<ThemeProvider theme={addTaskButtonTheme}>
								<button
									className={'addTaskButton'}
									onClick={(event: any) => {
										event.preventDefault();
										setOpportunityId(card?.opportunityId);
										handleClick(event);
									}}
									aria-describedby={popOverId}
								>
									<AddIcon />
									Add Task
								</button>
							</ThemeProvider>
						)}

						{card.numberOfOverDueTasks > 0 && (
							<div className={'overDue'}>
								<div className={'numberofOverDueTask'}>
									{card.numberOfOverDueTasks > 9
										? '9+'
										: card.numberOfOverDueTasks}
								</div>
								<QueryBuilderIcon
									style={{
										fontSize: '18px',
										color: '#292929',
									}}
								/>
							</div>
						)}
					</div>
					<div className={'opportunityInfoContainer'}>
						<div className={'flexContainer'}>
							<Avatar
								style={{
									width: '20px',
									height: '20px',
									fontSize: '12px',
									marginRight: '12px',
									cursor: 'pointer',
								}}
								src={`${card.bdOwnerPic || ''}`}
							>
								{card?.bdOwnerName && (
									<p title={`${card?.bdOwnerName}`}>
										{card?.bdOwnerName?.charAt(0)}
									</p>
								)}
							</Avatar>

							<div
								className={[
									'winPercentageIcon',
									card?.winPercentage >= 70
										? 'highWinPercentage'
										: 'lowWinPercentage',
								].join(' ')}
							></div>
							<p className={'winPercentage'}>{`${card?.winPercentage}%`}</p>

							{/* TODO based on the value and denomination we should change the format of the value */}
							<p className={'opportunityValue'}>
								{getCurrencyFormat(
									isFloat(card?.value)
										? formatDecimal(card?.value, 2)
										: card?.value,
									card?.currency,
									true
								)}
							</p>
						</div>
						<div>
							<div
								className={`${'priorityContainer'}
                                ${
																	card.priority === 'High'
																		? 'priorityHigh'
																		: card.priority === 'Medium'
																		? 'priorityMedium'
																		: card.priority === 'Low'
																		? 'priorityLow'
																		: undefined
																}
                                `}
							>
								{card?.priority}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MuiLink>
	);

	const updateOrdinal = (id: string, columnId: string, ordinal: number) => {
		updateOpportunityKanbanCard({
			variables: {
				kanbanCardId: id,
				columnId,
				ordinal,
			},
		})
			.then((data) => {
				const response =
					data?.data?.update_opportunity_kanban_card_by_pk?.kanban_column
						?.state;
				if (response === 'won' || response === 'loss') {
					updateOpportunityCloseDate({
						variables: {
							OpportunityId:
								data?.data?.update_opportunity_kanban_card_by_pk
									?.opportunity_id,
							close_date: dayjs().format('YYYY-MM-DD'),
						},
					}).then((data) => {
						refetchStageValue();
					});
				}
				refetchStageValue();
			})
			.catch((error) => {
				notify(`Update failed: ${error.message}`, 'warning');
			});
	};

	if (isKanbanLoading) {
		return <Loading />;
	}

	return (
		<div className={'contentContainer'}>
			<Kanban
				onAddCardToColumn={(columnId: string) => {
					addOpportunity(columnId);
				}}
				columns={kanbanColumnViews}
				updateOrdinal={updateOrdinal}
				stageValue={stageValue}
			>
				{cardBody}
			</Kanban>
			<Popover
				id={popOverId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<AddTaskEditor opportunityId={opporunityId} onClose={handleClose} />
			</Popover>
		</div>
	);
};

export default OpportunitiesKanban;
