import React, { useState } from 'react';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	TextField as TextComponent,
} from '@material-ui/core';
import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';
import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import SkillLevelForm from './SkillLevelForm.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import { settingsStyle } from '../../settingsStyle';

import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import { DELETE_SKILL_LEVEL_MESSAGE } from './constant';

interface Skill {
	id?: string;
	level?: string;
	description?: string;
	org_id?: string;
	value?: string;
}
const SkillLevelList = (props: Skill) => {
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [mutate] = useMutation();

	const [deleteId, setDeleteId] = useState<string | null>();
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isSkillLevelFormShown, setIsSkillLevelFormShown] = useState(false);
	const [skillLevelData, setSkillLevelData] = useState({});
	const [searchSkill, setSearchSkill] = useState('');

	const onEditSkill = (skill: Skill) => {
		if (!skill) {
			return;
		}
		const { org_id, ...restValues } = skill;
		setSkillLevelData({ ...restValues });
		setIsSkillLevelFormShown(true);
	};

	const deleteSkill = () => {
		mutate(
			{
				type: 'delete',
				resource: 'skill_level',
				payload: {
					id: deleteId,
				},
			},
			{
				onSuccess: () => {
					notify(DELETE_SKILL_LEVEL_MESSAGE);
					refresh();
					setIsDeleteModalShown(false);
					setDeleteId(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Skill Level'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchSkill(e?.target?.value);
								}}
								value={searchSkill}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Box>
					<Button
						variant='contained'
						className={modalFormStyles.saveButton}
						onClick={() => {
							setIsSkillLevelFormShown(true);
						}}
					>
						Add Skill Level
					</Button>
				</Box>
			</Box>
			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='skill_level'
					basePath='/skill_level'
					bulkActionButtons={false}
					filter={{ level: searchSkill }}
					perPage={5}
					actions={false}
					title={' '}
					sort={{ field: 'level', order: 'ASC' }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.level}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.level}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='VALUE'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.value}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.value}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(shift: any) => {
								return (
									<Tooltip
										title={`${shift.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(skill: any) => (
								<Box>
									<PopOver
										edit={() => {
											onEditSkill(skill);
										}}
										remove={() => {
											setIsDeleteModalShown(true);
											setDeleteId(skill?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<SkillLevelForm
				open={isSkillLevelFormShown}
				onClose={() => {
					setIsSkillLevelFormShown(false);
					setSkillLevelData({});
				}}
				skillLevel={skillLevelData}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deleteSkill}
			/>
		</>
	);
};
export default SkillLevelList;
