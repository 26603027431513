import { createTheme } from '@material-ui/core';

export const DataGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			root: {
				display: 'none',
			},
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '10px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-extrabold',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				width: '200px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});

export const pmsGoalReviewGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			root: {
				display: 'none',
			},
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-bold',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});
