import React, {
	useState,
	useEffect,
	useContext,
	useReducer,
	Reducer,
} from 'react';
import { useNotify } from 'react-admin';

import {
	Box,
	Button,
	Tooltip,
	IconButton,
	ThemeProvider,
	createTheme,
	InputAdornment,
	Typography,
} from '@material-ui/core';
import { globalStyles, modalFormStyle } from '../../Layout/styles';
import AddIcon from '@material-ui/icons/Add';
import { TimesheetStyles } from './TimesheetStyles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TimeSheetDailyView from './TimeSheetDailyView.component';
import TimeSheetWeekView from './TimeSheetWeekView.component';
import { TimesheetForm } from './TimesheetForm.component';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import {
	useDeleteTimesheetEntryMutation,
	useGetPreviousTimesheetEntriesQuery,
	useGetProjectsOptionsQuery,
	useGetTimesheetsQuery,
	useGetTimesheetTotalHoursQuery,
	useInsertMultipleTimesheetEntriesMutation,
	useSubmitTimesheetMutation,
	useUpdateTimesheetToSubmissionStatusMutation,
	useGetTimesheetStatusQuery,
	useUpdateTimesheetStatusMutation,
	useGetWeeklyTimesheetEntriesQuery,
	useUpsertTimesheetEntriesMutation,
	useGetOrgHolidaysByUserQuery,
	useGetTimesheetSettingsQuery,
	useGetEmployeeJoinDateByUserIdQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import {
	TimesheetFormValues,
	TimesheetValues,
	WeeklyTimesheetEntries,
	UpdateTimesheetEntryHourPayload,
	DeletedTaskTimesheetEntries,
	WeeklyTimesheetHours,
	HolidayProps,
} from './Timesheet.model';
import DeleteModal from '../../SharedComponents/DeleteModal.component';
import {
	TIME_SHEET_ADD_NOTIFICATION_TEXT,
	TIME_SHEET_DELETE_NOTIFICATION_TEXT,
	TIME_SHEET_SUBMISSION_CONFIRMATION_TEXT,
	TIME_SHEET_SUBMISSION_SUCCESS_TEXT,
	TIME_SHEET_DELETE_CONFIRMATION_TEXT,
	WEEKLY_TIME_SHEET_CHANGES_SAVED_TEXT,
	TIMESHEET_SUBMITTED_STATUS,
	TIMESHEET_APPROVED_STATUS,
} from './constant';
import WarningModal from '../../SharedComponents/WarningModal.component';
import ConfirmationModal from '../../SharedComponents/ConfirmationModal.component';
import {
	loadWeeklyTimesheetEntries,
	getDailyTotalHours,
	getTaskTotalHours,
	updateTimesheetEntryHour,
	deleteTaskEntries,
	getWeeklyTimesheetEntriesPayload,
	getDeletedTimesheetEntriesId,
	addEntriesforWeek,
	loadPreviousWeekTimesheetEntries,
} from './reducer.util';
import WarningConfirmationModal from '../../SharedComponents/WarningConfirmationModal.component';
import {
	getProjectOptions,
	getTimesheetDataForDay,
	getTimesheetDisableStatus,
	getTimesheetSubmissionId,
	getTotalHoursByDay,
} from './Timesheet.utils';
import { head } from 'lodash';

import useGetExcludedLeaves from './useGetExcluedLeaves';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				width: '45px',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const weeklyTimesheetEntriesInitialState = {
	isDisabled: false,
	isSubmissionDisabled: false,
	isSaved: true,
	dailyTotalHours: [],
	timesheetEntries: [],
};

const ACTIONS = {
	LOAD_TIMESHEET_ENTRIES: 'load_timesheet',
	UPDATE_TIMESHEET_ENTRY_HOUR: 'update_timesheet_entry_hours',
	DELETE_TASK_TIMESHEET_ENTRIES: 'delete_task_timesheet_entries',
	ADD_TIMESHEET_ENTRIES: 'add_new_timesheet_entry',
	COPY_PREVIOUS_WEEK_TIMESHEET_ENTRIES: 'copy_previous_timesheet_entries',
};

type Action = {
	type: string;
	payload: any;
};

const reducer: Reducer<any, Action> = (
	state: WeeklyTimesheetEntries,
	// TODO need to give correct type
	action: any
) => {
	switch (action.type) {
		case ACTIONS.LOAD_TIMESHEET_ENTRIES: {
			return loadWeeklyTimesheetEntries(
				action.payload.data,
				action.payload.selectedWeek,
				action.payload.disableTimesheetAfterSubmit
			);
		}
		case ACTIONS.UPDATE_TIMESHEET_ENTRY_HOUR: {
			return updateTimesheetEntryHour(
				state,
				action.payload.taskId,
				action.payload.index,
				action.payload.updatedHour,
				action.payload.projectId,
				action.payload.timesheetSubmissionId,
				action.payload.isBillable
			);
		}
		case ACTIONS.DELETE_TASK_TIMESHEET_ENTRIES: {
			return deleteTaskEntries(
				state,
				action.payload.taskId,
				action.payload.projectId
			);
		}
		case ACTIONS.ADD_TIMESHEET_ENTRIES: {
			return addEntriesforWeek(
				state,
				action.payload.selectedWeek,
				action.payload.task,
				action.payload.projects
			);
		}

		case ACTIONS.COPY_PREVIOUS_WEEK_TIMESHEET_ENTRIES: {
			return loadPreviousWeekTimesheetEntries(
				action.payload.data,
				action.payload.selectedWeek
			);
		}
		default:
			return state;
	}
};

export const Timesheet = () => {
	const {
		id: userId,
		noOfLocations,
		locationId,
		isTimesheetWeekViewEnabled,
		orgId: organisationId,
	} = useContext<any>(UserProfileContext);
	const { data: user, loading: joinDateLoader } =
		useGetEmployeeJoinDateByUserIdQuery({
			variables: { user_id: userId },
			fetchPolicy: 'network-only',
		});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;
	const [isWeekViewShown, setIsWeekViewShown] = useState(false);
	const [isTimesheetFormShown, setIsTimesheetFormShown] = useState(false);
	const globalStyle = globalStyles();
	const modalFormStyles = modalFormStyle();
	const timesheetStyles = TimesheetStyles();
	const [timesheetData, setTimesheetData] = useState<
		(TimesheetValues | null | undefined)[] | undefined
	>();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isWarningConfirmationModalShown, setIsWarningConfirmationModalShown] =
		useState(false);
	const [currentWeekTimesheetStatus, setCurrentWeekTimesheetStatus] = useState<{
		label?: string;
		value?: string | null;
	}>({});
	const [selectedTimesheetEntry, setSelectedTimesheetEntry] =
		useState<TimesheetFormValues>({});
	const [selectedDeleteTimesheetEntryId, setSelectedDeleteTimesheetEntryId] =
		useState('');
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [selectedDate, setSelectedDate] = useState(
		dayjs().format('YYYY-MM-DD')
	);
	const [previousDay, setPreviousDay] = useState(
		dayjs().subtract(1, 'day').format('YYYY-MM-DD')
	);
	const [orgHoliday, setOrgHoliday] = useState<HolidayProps[] | undefined>();
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [disableTimesheetAfterSubmit, setDisableTimesheetAfterSubmit] =
		useState(false);
	const [selectedWeek, setSelectedWeek] = useState(
		Array(7)
			.fill(1)
			.map((value: number, index: number) =>
				dayjs(selectedDate).day(index).format('YYYY-MM-DD')
			)
	);
	const [excludedLeaves] = useGetExcludedLeaves(
		selectedWeek[0],
		selectedWeek[6],
		userId,
		locationId
	);
	const [weeklyTimesheetEntries, dispatch] = useReducer(
		reducer,
		weeklyTimesheetEntriesInitialState
	);
	const [weeklyTimsheetHours, setWeeklyTimesheetHours] =
		useState<WeeklyTimesheetHours>({
			totalHours: '00:00',
			dailyHours: Array(7)
				.fill(1)
				.map((value: number, index: number) => ({
					date: dayjs(selectedDate).day(index).format('YYYY-MM-DD'),
					isMaximumLimitExceed: false,
					totalHours: '00:00',
				})),
			taskTotalHours: [],
			timesheetEntriesCount: 0,
		});
	const notify = useNotify();
	const disableCheckIn = selectedWeek[0] < userJoinDate;
	const disableDayCheckIn = selectedDate < userJoinDate;

	const { data: timesheetSettingsDetails } = useGetTimesheetSettingsQuery({
		variables: {
			orgId: organisationId,
		},
		fetchPolicy: 'network-only',
		skip: !organisationId,
	});

	const { data: weekHoursByday, refetch: refetchWeekHoursByDay } =
		useGetTimesheetTotalHoursQuery({
			variables: {
				userId: userId,
				startDate: selectedWeek[0],
				endDate: selectedWeek[6],
			},
			fetchPolicy: 'network-only',
		});

	const [insertTimesheetEntries] = useInsertMultipleTimesheetEntriesMutation();
	const [upsertTimesheetEntries] = useUpsertTimesheetEntriesMutation();

	const { data: previousTimesheetEntries } =
		useGetPreviousTimesheetEntriesQuery({
			variables: {
				userId: userId,
				startDate: previousDay,
				endDate: previousDay,
			},
			fetchPolicy: 'network-only',
		});

	const {
		data: timesheetsEntries,
		loading: timesheetEntriesLoading,
		refetch: refetchTimesheetEntries,
	} = useGetTimesheetsQuery({
		variables: {
			date: selectedDate,
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: timesheetEntriesForWeek,
		refetch: refetchTimesheetEntriesForWeek,
	} = useGetWeeklyTimesheetEntriesQuery({
		variables: {
			startDate: selectedWeek[0],
			endDate: selectedWeek[6],
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: previousWeektimesheetEntries } =
		useGetWeeklyTimesheetEntriesQuery({
			variables: {
				startDate: dayjs(selectedWeek[0])
					.subtract(7, 'days')
					.format('YYYY-MM-DD'),
				endDate: dayjs(selectedWeek[6])
					.subtract(7, 'days')
					.format('YYYY-MM-DD'),
				userId: userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: orgHolidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: selectedWeek[0],
								_lte: selectedWeek[6],
							},
							is_restricted: { _eq: false },
							location_id: { _eq: locationId },
					  }
					: {
							date: {
								_gte: selectedWeek[0],
								_lte: selectedWeek[6],
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: selectedWeek[0],
									_lte: selectedWeek[6],
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: selectedWeek[0],
									_lte: selectedWeek[6],
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip: !locationId && noOfLocations && noOfLocations > 0,
	});

	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const [submitTimesheet] = useSubmitTimesheetMutation();

	const [updateTimesheetToSubmissionStatus] =
		useUpdateTimesheetToSubmissionStatusMutation();

	const [deleteTimesheetEntry] = useDeleteTimesheetEntryMutation();
	const [updateTimeSheetStatus] = useUpdateTimesheetStatusMutation();

	const { data: projectOptions } = useGetProjectsOptionsQuery({
		variables: {
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgHolidays) {
			setOrgHoliday([]);
			return;
		}
		const regularHolidays =
			orgHolidays?.org_holidays?.map((value) => {
				return {
					name: value?.name,
					date: value?.date,
				};
			}) || [];
		const optionalHolidays =
			orgHolidays?.user_optional_holiday_mapping?.map((value) => {
				return {
					name: value?.org_holiday?.name,
					date: value?.org_holiday?.date,
				};
			}) || [];
		if ([...regularHolidays, ...optionalHolidays]?.length > 0) {
			!locationId && noOfLocations && noOfLocations > 0
				? setOrgHoliday([])
				: setOrgHoliday([...regularHolidays, ...optionalHolidays]);
		}
	}, [locationId, noOfLocations, orgHolidays]);

	useEffect(() => {
		if (!timesheetEntriesForWeek) {
			return;
		}
		dispatch({
			type: ACTIONS.LOAD_TIMESHEET_ENTRIES,
			payload: {
				data: timesheetEntriesForWeek,
				selectedWeek: selectedWeek,
				disableTimesheetAfterSubmit: head(
					timesheetSettingsDetails?.timesheet_settings
				)?.disable_after_submit,
			},
		});
	}, [timesheetEntriesForWeek, selectedWeek, timesheetSettingsDetails]);

	useEffect(() => {
		if (!weeklyTimesheetEntries) {
			return;
		}
		setWeeklyTimesheetHours({
			...getDailyTotalHours(weeklyTimesheetEntries, selectedWeek),
			taskTotalHours: getTaskTotalHours(weeklyTimesheetEntries),
		});
	}, [weeklyTimesheetEntries, selectedWeek]);

	useEffect(() => {
		if (!timesheetsEntries) {
			return;
		}
		const timesheet = getTimesheetDataForDay(timesheetsEntries);
		if (!timesheet) {
			return;
		}
		setTimesheetData(timesheet);
	}, [timesheetsEntries]);

	useEffect(() => {
		if (!selectedDate) {
			return;
		}
		const week = Array(7)
			.fill(1)
			.map((value: number, index: number) =>
				dayjs(selectedDate).day(index).format('YYYY-MM-DD')
			);
		setSelectedWeek(week);
	}, [selectedDate]);

	useEffect(() => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission = weekHoursByday.timesheet_daily_hours.find(
			(totalHours) => totalHours?.timesheet_submission_id !== null
		);
		if (!selectedWeekSubmission) {
			setCurrentWeekTimesheetStatus({});
			return;
		}
		setCurrentWeekTimesheetStatus({
			label: selectedWeekSubmission.timesheetSubmission?.timesheet_status.label,
			value: selectedWeekSubmission.timesheetSubmission?.timesheet_status.value,
		});
	}, [weekHoursByday]);

	//Set the daisable status from query
	useEffect(() => {
		if (!timesheetSettingsDetails) {
			return;
		}
		setDisableTimesheetAfterSubmit(
			head(timesheetSettingsDetails.timesheet_settings)?.disable_after_submit ||
				false
		);
	}, [timesheetSettingsDetails]);

	const closeTimeSheetForm = () => {
		setSelectedTimesheetEntry({});
		setIsTimesheetFormShown(false);
	};

	const closeDeleteModal = () => {
		setSelectedDeleteTimesheetEntryId('');
		setIsDeleteModalShown(false);
	};

	const insertPreviousTimesheetEntries = () => {
		if (!previousTimesheetEntries) {
			return;
		}
		if (previousTimesheetEntries.timesheet.length === 0) {
			setIsWarningModalShown(true);
			return;
		}

		insertTimesheetEntries({
			variables: {
				data:
					previousTimesheetEntries.timesheet.length > 0
						? previousTimesheetEntries.timesheet.map(
								(previousTimesheetEntry) => ({
									project_id: previousTimesheetEntry.project_id,
									task_id: previousTimesheetEntry.task_id,
									notes: previousTimesheetEntry?.notes,
									user_id: userId,
									working_hours: previousTimesheetEntry?.working_hours,
									is_billable: previousTimesheetEntry?.is_billable,
									date: selectedDate,
									master_project_task_id:
										previousTimesheetEntry?.master_project_task_id,
									org_task_id: previousTimesheetEntry?.org_task_id,
									timesheet_submission_id: selectedWeek.includes(previousDay)
										? getTimesheetSubmissionId(weekHoursByday)
										: null,
								})
						  )
						: [],
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(TIME_SHEET_ADD_NOTIFICATION_TEXT);
					refetchTimesheetEntries();
					refetchTimesheetEntriesForWeek();
					refetchWeekHoursByDay();
					return;
				}
			})
			.catch((error) => {
				return;
			});
	};

	const handleDeleteTimesheetEntry = (timesheetEntryId: string) => {
		if (!timesheetEntryId) {
			return;
		}
		deleteTimesheetEntry({
			variables: {
				timesheeetEntryId: timesheetEntryId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(TIME_SHEET_DELETE_NOTIFICATION_TEXT);
					setSelectedTimesheetEntry({});
					setIsDeleteModalShown(false);
					refetchTimesheetEntries();
					refetchTimesheetEntriesForWeek();
					refetchWeekHoursByDay();
					return;
				}
			})
			.catch((error) => {
				return;
			});
	};

	const checkIfTimesheetSubmitted = () => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission = weekHoursByday.timesheet_daily_hours.find(
			(totalHours) => totalHours?.timesheet_submission_id !== null
		);
		return selectedWeekSubmission?.timesheetSubmission?.timesheet_status
			.value === TIMESHEET_SUBMITTED_STATUS ||
			selectedWeekSubmission?.timesheetSubmission?.timesheet_status.value ===
				TIMESHEET_APPROVED_STATUS
			? true
			: false;
	};

	const getWeekTotalHours = () => {
		if (!weekHoursByday) {
			return;
		}
		const timesheetDailyHours = weekHoursByday.timesheet_daily_hours.map(
			(timesheet) => timesheet.sum
		);

		const weekTotalHours = timesheetDailyHours
			.map((timesheetDailyHour) => timesheetDailyHour.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return (
			`${Math.floor(weekTotalHours.asHours())}:${weekTotalHours.format(
				'mm'
			)}` || '00:00'
		);
	};

	const handleReSubmitTimesheet = async (timesheetSubmissionId: string) => {
		if (!timesheetStatus || !timesheetSubmissionId) {
			return;
		}
		const submittedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === TIMESHEET_SUBMITTED_STATUS
		);
		const timesheetReject = await updateTimeSheetStatus({
			variables: {
				timesheetSubmissionId: timesheetSubmissionId,
				timesheetStatusId: submittedTimesheetStatus?.id,
			},
		});
		if (!timesheetReject.errors) {
			refetchWeekHoursByDay();
			setIsConfirmationModalShown(false);
			notify(TIME_SHEET_SUBMISSION_SUCCESS_TEXT);
		}
	};

	const handleSubmitTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		const timesheetSubmissionId = getTimesheetSubmissionId(weekHoursByday);
		if (timesheetSubmissionId) {
			handleReSubmitTimesheet(timesheetSubmissionId);
			return;
		}

		const submittedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === TIMESHEET_SUBMITTED_STATUS
		);
		const timesheetSubmission = await submitTimesheet({
			variables: {
				data: {
					submitted_by: userId,
					timesheet_status_id: submittedTimesheetStatus?.id,
				},
			},
		});
		if (timesheetSubmission) {
			const { data: timesheetSubmissionResponse } = timesheetSubmission;
			updateTimesheetToSubmissionStatus({
				variables: {
					startDate: selectedWeek[0],
					endDate: selectedWeek[6],
					userId: userId,
					timesheetSubmissionId:
						timesheetSubmissionResponse?.insert_timesheet_submissions_one?.id,
				},
			})
				.then((response) => {
					if (!response.errors) {
						refetchWeekHoursByDay();
						setIsConfirmationModalShown(false);
						notify(TIME_SHEET_SUBMISSION_SUCCESS_TEXT);
					}
				})
				.catch((error) => {
					return;
				});
		}
	};

	const handleSaveWeeklyTimesheetEntries = async () => {
		const timesheetEntriesToCreate = getWeeklyTimesheetEntriesPayload(
			weeklyTimesheetEntries,
			userId
		);
		const timesheetToDelete = getDeletedTimesheetEntriesId(
			weeklyTimesheetEntries
		);
		if (
			timesheetEntriesToCreate.length === 0 &&
			timesheetToDelete.length === 0
		) {
			dispatch({
				type: ACTIONS.LOAD_TIMESHEET_ENTRIES,
				payload: { data: timesheetEntriesForWeek, selectedWeek: selectedWeek },
			});
			return;
		}
		upsertTimesheetEntries({
			variables: {
				timesheets: getWeeklyTimesheetEntriesPayload(
					weeklyTimesheetEntries,
					userId
				),
				deletedTimesheetEntryIds: getDeletedTimesheetEntriesId(
					weeklyTimesheetEntries
				),
			},
		})
			.then((response) => {
				if (!response.errors) {
					refetchTimesheetEntries();
					refetchTimesheetEntriesForWeek();
					refetchWeekHoursByDay();
					notify(WEEKLY_TIME_SHEET_CHANGES_SAVED_TEXT);
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const onCopyFromPreviousWeek = () => {
		if (!previousWeektimesheetEntries) {
			return;
		}
		if (
			previousWeektimesheetEntries.project_task.length === 0 &&
			previousWeektimesheetEntries.org_tasks.length === 0 &&
			previousWeektimesheetEntries.master_project_task.length === 0
		) {
			setIsWarningModalShown(true);
			return;
		}

		dispatch({
			type: ACTIONS.COPY_PREVIOUS_WEEK_TIMESHEET_ENTRIES,
			payload: {
				data: previousWeektimesheetEntries,
				selectedWeek: selectedWeek,
			},
		});
	};

	const setNextDate = () => {
		setSelectedDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'));
	};
	const setPreviousDate = () => {
		setSelectedDate(
			dayjs(selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
		);
	};
	const setNextWeek = () => {
		setSelectedDate(dayjs(selectedDate).add(7, 'days').format('YYYY-MM-DD'));
	};
	const setPreviousWeek = () => {
		setSelectedDate(
			dayjs(selectedDate).subtract(7, 'days').format('YYYY-MM-DD')
		);
	};

	const discardTimesheetChanges = () => {
		setIsWeekViewShown(false);
		setIsWarningConfirmationModalShown(false);
		dispatch({
			type: ACTIONS.LOAD_TIMESHEET_ENTRIES,
			payload: {
				data: timesheetEntriesForWeek,
				selectedWeek: selectedWeek,
			},
		});
	};

	const disableTimesheetEdit = () => {
		if (!timesheetSettingsDetails) {
			return;
		}
		const timesheetDisableStatus = getTimesheetDisableStatus(
			disableTimesheetAfterSubmit,
			currentWeekTimesheetStatus.value
		);
		return timesheetDisableStatus ? timesheetDisableStatus : false;
	};
	return (
		<>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box className={timesheetStyles.navigationToolbar}>
					{isTimesheetWeekViewEnabled && (
						<>
							<Button
								className={`${timesheetStyles.navigationTabButton} ${
									timesheetStyles.daySwitchButton
								} ${!isWeekViewShown && timesheetStyles.activeNavigationTab}`}
								variant='contained'
								onClick={() => {
									if (!weeklyTimesheetEntries.isSaved) {
										setIsWarningConfirmationModalShown(true);
										return;
									}
									setIsWeekViewShown(false);
								}}
								id='timesheet_day_view_button'
							>
								Day
							</Button>

							<Button
								className={`${timesheetStyles.navigationTabButton} ${
									timesheetStyles.weekSwitchButton
								} ${isWeekViewShown && timesheetStyles.activeNavigationTab}`}
								variant='contained'
								onClick={() => {
									setIsWeekViewShown(true);
								}}
								id='timesheet_week_view_button'
							>
								Week
							</Button>
						</>
					)}

					<Box ml={1}>
						<ThemeProvider theme={dateTimePickerTheme}>
							<form noValidate>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										variant='inline'
										value={selectedDate}
										format='MMM D, YYYY'
										onChange={(date) => {
											setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
											setPreviousDay(
												dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')
											);
										}}
										label=''
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Tooltip title={'Date'}>
														<IconButton className={globalStyle.iconButton}>
															<DateRangeIcon id='timesheet_calendar_icon' />
														</IconButton>
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</form>
						</ThemeProvider>
					</Box>
					<Box>
						{!isWeekViewShown ? (
							<Box className={timesheetStyles.dateContainer}>
								<Box width='160px'>
									<Typography className={timesheetStyles.dateRange}>
										{dayjs(selectedDate).format('ddd, DD MMM YYYY')}
									</Typography>
								</Box>
								<Box display='flex' alignItems='center'>
									<Button
										className={timesheetStyles.dateIncrementButton}
										onClick={setPreviousDate}
										id='timesheet_day_view_backward_arrow'
									>
										{'<'}
									</Button>
									<Button
										className={timesheetStyles.dateIncrementButton}
										onClick={setNextDate}
										id='timesheet_day_view_forward_arrow'
									>
										{'>'}
									</Button>
								</Box>
							</Box>
						) : (
							<Box className={timesheetStyles.dateContainer}>
								<Button
									id='timesheet_week_view_left_arrow'
									className={timesheetStyles.dateIncrementButton}
									onClick={() => {
										if (!weeklyTimesheetEntries.isSaved) {
											setIsWarningConfirmationModalShown(true);
											return;
										}
										setPreviousWeek();
									}}
								>
									{'<'}
								</Button>
								<Typography className={timesheetStyles.dateRange}>
									{`Week - ${dayjs(selectedWeek[0]).format(
										'DD MMM YYYY'
									)} - ${dayjs(selectedWeek[6]).format('DD MMM YYYY')}`}
								</Typography>
								<Button
									id='timesheet_week_view_right_arrow'
									className={timesheetStyles.dateIncrementButton}
									onClick={() => {
										if (!weeklyTimesheetEntries.isSaved) {
											setIsWarningConfirmationModalShown(true);
											return;
										}
										setNextWeek();
									}}
								>
									{'>'}
								</Button>
							</Box>
						)}
					</Box>
					{(currentWeekTimesheetStatus.value === TIMESHEET_SUBMITTED_STATUS ||
						currentWeekTimesheetStatus.value === TIMESHEET_APPROVED_STATUS) && (
						<div
							className={`${timesheetStyles.statusContainer} 
						${timesheetStyles.timesheetStatusContainer}
						${
							currentWeekTimesheetStatus.value === TIMESHEET_SUBMITTED_STATUS
								? timesheetStyles.submittedStatus
								: currentWeekTimesheetStatus.value === TIMESHEET_APPROVED_STATUS
								? timesheetStyles.approvedStatus
								: timesheetStyles.rejectedStatus
						}
						`}
						>
							{currentWeekTimesheetStatus.label}
						</div>
					)}
				</Box>
				<Button
					className={modalFormStyles.saveButton}
					variant='contained'
					startIcon={<AddIcon />}
					onClick={() => {
						setIsTimesheetFormShown(true);
					}}
					id='add_new_timesheet_entry'
					disabled={
						joinDateLoader ||
						(!isWeekViewShown ? disableDayCheckIn : disableCheckIn) ||
						disableTimesheetEdit()
					}
				>
					Add New Entry
				</Button>
			</Box>
			<Box mt={2} className={timesheetStyles.container}>
				{!isWeekViewShown ? (
					<TimeSheetDailyView
						timesheetEntries={timesheetData}
						disabled={
							joinDateLoader ||
							disableDayCheckIn ||
							disableTimesheetEdit() ||
							false
						}
						disableSubmitTimesheet={checkIfTimesheetSubmitted()}
						dailyHours={selectedWeek.map((date) => ({
							date: date,
							totalHours: weekHoursByday
								? getTotalHoursByDay(date, weekHoursByday)
								: '00:00',
							orgHoliday: orgHoliday || [],
							leaves: excludedLeaves || [],
						}))}
						onDateChange={(date) => {
							if (!date) {
								return;
							}
							setSelectedDate(date);
							setPreviousDay(
								dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')
							);
						}}
						selectedDate={selectedDate}
						isTimesheetLoading={timesheetEntriesLoading}
						onAddEntry={() => {
							setIsTimesheetFormShown(true);
						}}
						onEdit={(timesheet) => {
							if (!timesheet) {
								return;
							}
							setSelectedTimesheetEntry(timesheet);
							setIsTimesheetFormShown(true);
						}}
						onDelete={(timesheet) => {
							if (!timesheet) {
								return;
							}
							setSelectedDeleteTimesheetEntryId(timesheet);
							setIsDeleteModalShown(true);
						}}
						onCopyFromPreviousEntry={insertPreviousTimesheetEntries}
						weekTotalHours={getWeekTotalHours() || '00:00'}
						onSubmitTimesheet={() => {
							setIsConfirmationModalShown(true);
						}}
					/>
				) : (
					<TimeSheetWeekView
						disable={joinDateLoader || disableCheckIn}
						timesheet={weeklyTimesheetEntries}
						weeklyTimsheetHours={weeklyTimsheetHours}
						orgHoliday={orgHoliday || []}
						onDelete={(data: DeletedTaskTimesheetEntries) => {
							dispatch({
								type: ACTIONS.DELETE_TASK_TIMESHEET_ENTRIES,
								payload: {
									taskId: data.taskId,
									projectId: data.projectId,
								},
							});
						}}
						onHourUpdate={(data: UpdateTimesheetEntryHourPayload) => {
							dispatch({
								type: ACTIONS.UPDATE_TIMESHEET_ENTRY_HOUR,
								payload: {
									taskId: data.taskId,
									index: data.index,
									updatedHour: data.updatedHour,
									projectId: data.projectId,
									timesheetSubmissionId:
										getTimesheetSubmissionId(weekHoursByday),
									isBillable: data.isBillable,
								},
							});
						}}
						leaves={excludedLeaves || []}
						onSave={() => {
							handleSaveWeeklyTimesheetEntries();
						}}
						onSubmitTimesheet={() => {
							setIsConfirmationModalShown(true);
						}}
						onAddEntry={() => {
							setIsTimesheetFormShown(true);
						}}
						onCopyFromPreviousWeek={() => {
							onCopyFromPreviousWeek();
						}}
					/>
				)}
				<TimesheetForm
					open={isTimesheetFormShown}
					onClose={closeTimeSheetForm}
					timesheetSubmissionId={getTimesheetSubmissionId(weekHoursByday)}
					timesheetEntries={weeklyTimesheetEntries.timesheetEntries}
					projectOptions={
						projectOptions ? getProjectOptions(projectOptions) : []
					}
					totalHours={
						weekHoursByday
							? getTotalHoursByDay(selectedDate, weekHoursByday)
							: '00:00'
					}
					selectedDate={selectedDate}
					initialValues={selectedTimesheetEntry}
					onSuccess={() => {
						setSelectedDeleteTimesheetEntryId('');
						refetchTimesheetEntries();
						refetchTimesheetEntriesForWeek();
						refetchWeekHoursByDay();
					}}
					isWeeklyTimesheetForm={isWeekViewShown}
					onSubmit={(data) => {
						if (!data) {
							return;
						}
						dispatch({
							type: ACTIONS.ADD_TIMESHEET_ENTRIES,
							payload: {
								selectedWeek: selectedWeek,
								task: data,
								projects: projectOptions
									? getProjectOptions(projectOptions)
									: [],
							},
						});
					}}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={closeDeleteModal}
					confirmationMessage={TIME_SHEET_DELETE_CONFIRMATION_TEXT}
					onDelete={() => {
						handleDeleteTimesheetEntry(selectedDeleteTimesheetEntryId);
					}}
				/>
				<WarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
					}}
					warningMessage={
						!weeklyTimesheetEntries.isSaved
							? 'Please save your changes'
							: `No data can be found`
					}
				/>
				<ConfirmationModal
					open={isConfirmationModalShown}
					onClose={() => {
						setIsConfirmationModalShown(false);
					}}
					confirmationMessage={TIME_SHEET_SUBMISSION_CONFIRMATION_TEXT}
					onSubmit={() => {
						handleSubmitTimesheet();
					}}
				/>
				<WarningConfirmationModal
					open={isWarningConfirmationModalShown}
					onClose={() => {
						setIsWarningConfirmationModalShown(false);
					}}
					onSubmit={discardTimesheetChanges}
					warningMessage={'Discard unsaved changes?'}
					okButtonLabel='Discard'
				/>
			</Box>
		</>
	);
};

export default Timesheet;
