import React, { useState } from 'react';
import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useNotify,
} from 'react-admin';

import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';

import { settingsStyle } from '../../settingsStyle';
import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import UserTeamForm from './UserTeamForm.component';
import {
	useDeleteUserTeamMappingsMutation,
	useDeleteUserTeamMutation,
	useGetActiveUsersQuery,
	useGetUserTeamMappingsQuery,
} from '../../../../generated/graphql';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';

const ToolBar = (props: any) => {
	const { searchText, onSearch, setIsUserTeamFormShown } = props;
	const modalFormStyles = modalFormStyle();
	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			marginBottom={'12px'}
		>
			<Box>
				<ThemeProvider theme={searchBarTheme}>
					<Box width='250px' marginTop={'-1px'}>
						<TextComponent
							placeholder='Search User Team'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchIcon />,
							}}
							onChange={(e) => {
								onSearch(e?.target?.value);
							}}
							value={searchText || []}
						/>
					</Box>
				</ThemeProvider>
			</Box>
			<Box>
				<Button
					style={{ width: '130px' }}
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsUserTeamFormShown(true);
					}}
				>
					Add Team
				</Button>
			</Box>
		</Box>
	);
};

const UserTeam = () => {
	const [isUserTeamFormShown, setIsUserTeamFormShown] =
		useState<boolean>(false);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id?: any;
		name?: string;
		users?: any[];
	}>({});
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [teamSearch, setTeamSearch] = useState('');
	const [selectedUserTeamToDelete, setSelectedUserTeamToDelete] =
		useState(null);
	const settingsStyles = settingsStyle();

	const tooltipStyle = ellipsisStyle();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: activeUsers } = useGetActiveUsersQuery({
		variables: {
			filter: {
				is_active: {
					_eq: true,
				},
			},
		},
	});

	const { data: userTeamMappings, refetch: refetchUserTeamMapping } =
		useGetUserTeamMappingsQuery({
			fetchPolicy: 'network-only',
		});

	const [deleteUserTeam, { loading: isDeleteUserTeamLoading }] =
		useDeleteUserTeamMutation();
	const [deleteUserTeamMappings, { loading: isDeleteUserTeamMappingsLoading }] =
		useDeleteUserTeamMappingsMutation();

	const onEditUserTeam = (userTeam: {
		id: any;
		name: string;
		users: any[] | undefined;
	}) => {
		if (!userTeam) {
			return;
		}
		setSelectedUserTeam(userTeam);
		setIsUserTeamFormShown(true);
	};

	const getUserOptions = () => {
		if (!activeUsers) {
			return [];
		}
		return activeUsers.user.map((user) => ({ id: user.id, name: user.name }));
	};

	const getUserTeamMappings = () => {
		if (!userTeamMappings || !selectedUserTeam?.id) {
			return;
		}

		return userTeamMappings.user_team_mapping.filter(
			(userMapping) => userMapping.team_id === selectedUserTeam?.id
		);
	};

	const handleDeletUserTeam = async () => {
		if (!selectedUserTeamToDelete || !userTeamMappings) {
			return;
		}
		const userTeamMappingsToDelete = userTeamMappings?.user_team_mapping
			.filter(
				(userTeamMapping) =>
					userTeamMapping.team_id === selectedUserTeamToDelete
			)
			.map((userTeamMapping) => userTeamMapping.id);

		const deleteUserTeamMappingResponse = await deleteUserTeamMappings({
			variables: {
				userTeamMappings: userTeamMappingsToDelete,
			},
		});
		if (!deleteUserTeamMappingResponse.errors) {
			deleteUserTeam({
				variables: {
					userTeamId: selectedUserTeamToDelete,
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify('User Team Deleted Successfully');
						setIsDeleteModalShown(false);
						setSelectedUserTeamToDelete(null);
						refresh();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			console.log(deleteUserTeamMappingResponse.errors);
		}
	};

	return (
		<>
			<Box className={settingsStyles.content}>
				<ToolBar
					searchText={teamSearch}
					onSearch={(value: string) => setTeamSearch(value)}
					setIsUserTeamFormShown={(value: boolean) =>
						setIsUserTeamFormShown(value)
					}
				/>
				<List
					resource='user_team'
					basePath='/user_team'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: `%${teamSearch}%` }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(team: any) => {
								return (
									<Tooltip title={`${team.name}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{team.name}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='USERS'
							render={(team: any) => {
								return userTeamMappings?.user_team_mapping.filter(
									(userMapping) => userMapping.team_id === team.id
								).length;
							}}
						/>
						<FunctionField
							render={(team: any) => (
								<Box>
									<PopOver
										edit={() => {
											onEditUserTeam({
												id: team?.id,
												name: team?.name,
												users: userTeamMappings?.user_team_mapping
													.filter(
														(userMapping) => userMapping.team_id === team.id
													)
													.map((userMapping) => userMapping.user_id),
											});
										}}
										remove={() => {
											setSelectedUserTeamToDelete(team?.id);
											setIsDeleteModalShown(true);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<UserTeamForm
				open={isUserTeamFormShown}
				onClose={() => {
					setSelectedUserTeam({});
					setIsUserTeamFormShown(false);
				}}
				initialValues={selectedUserTeam}
				refetch={() => {
					refetchUserTeamMapping();
					refresh();
				}}
				userOptions={getUserOptions()}
				userTeamMappings={getUserTeamMappings()}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setSelectedUserTeamToDelete(null);
					setIsDeleteModalShown(false);
				}}
				isLoading={isDeleteUserTeamLoading || isDeleteUserTeamMappingsLoading}
				confirmationMessage={
					'Are you sure want to delete this user team?. This action cannot be undone.'
				}
				onDelete={() => {
					handleDeletUserTeam();
				}}
			/>
		</>
	);
};

export default UserTeam;
