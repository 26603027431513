import React, { useEffect, useState, useContext } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableHead,
	TableRow,
	createStyles,
	TableCell,
	withStyles,
	TableContainer,
	Typography,
	Tooltip,
	Divider,
	IconButton,
	makeStyles,
} from '@material-ui/core';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import { LeaveBalanceProps } from './Attendance.model';
import { Style } from './Style';
import EditIcon from '@material-ui/icons/Edit';
import LeaveBalanceForm from './EditLeaveBalanceForm.component';
import { UserProfileContext } from '../../App';
import { formatDecimal, isFloat } from '../../Utils/string.util';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '0px 16px 0px 24px',
		},
		root: {
			borderBottom: '1px solid #E0E0E0',
		},
	})
)(TableCell);

const useStyles = makeStyles({
	row: {
		'&:hover': {
			'& $listEditIconButton': {
				display: 'inline',
			},
		},
	},
	rowContainer: {
		width: '66px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		display: 'none',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
});
interface Props {
	organizationLeaves: LeaveBalanceProps[];
	isManagerView?: boolean;
	refetch: () => void;
}

const LeaveSummary = (props: Props) => {
	const { permissions: permissionList } = useContext<any>(UserProfileContext);
	const { organizationLeaves, isManagerView, refetch } = props;
	const classes = Style();
	const styles = useStyles();
	const style = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const [leavebalanceFormOpen, SetLeaveBalanceFormOpen] = useState(false);
	const [leaveTypeList, setLeaveTypeList] = useState<any>([]);
	const [leaveTypeId, setLeaveTypeId] = useState<string | null | undefined>();
	useEffect(() => {
		if (!organizationLeaves) {
			return;
		}
		const leaveTypeOptions = organizationLeaves?.map((type: any) => {
			return {
				id: type?.id,
				name: type?.name,
			};
		});
		setLeaveTypeList(leaveTypeOptions);
	}, [organizationLeaves]);
	return (
		<Box>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<CustomTableCell>Leave Type</CustomTableCell>
							<CustomTableCell>OP:Balance</CustomTableCell>
							<CustomTableCell>Accrued</CustomTableCell>
							<CustomTableCell>Applied</CustomTableCell>
							<CustomTableCell>Balance</CustomTableCell>
							{isManagerView && <CustomTableCell></CustomTableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{organizationLeaves?.map((value: LeaveBalanceProps) => {
							return (
								<TableRow key={value.id} className={styles.row}>
									<CustomTableCell>
										<Tooltip
											title={`${value?.name || ' - -'}`}
											placement='right'
										>
											<Typography
												className={`${classes.additionalInfoLabelLeave} ${style.ellipsis}`}
											>
												{value?.name}
											</Typography>
										</Tooltip>
									</CustomTableCell>
									<CustomTableCell>
										{isFloat(Number(value.defaultAmount))
											? formatDecimal(Number(value.defaultAmount), 3)
											: value.defaultAmount}
									</CustomTableCell>
									<CustomTableCell>
										{isFloat(Number(value.accruedLeaveCount))
											? formatDecimal(Number(value.accruedLeaveCount), 3)
											: value.accruedLeaveCount}
									</CustomTableCell>
									<CustomTableCell>
										{isFloat(Number(value.applied))
											? formatDecimal(Number(value.applied), 3)
											: value.applied}
									</CustomTableCell>
									<CustomTableCell>
										{isFloat(Number(value.balance))
											? formatDecimal(Number(value.balance), 3)
											: value.balance}
									</CustomTableCell>
									{isManagerView &&
										permissionList?.appPermissions?.ui
											?.editOthersAttendance && (
											<CustomTableCell className={styles.rowContainer}>
												<Box>
													<IconButton
														className={styles.listEditIconButton}
														onClick={() => {
															setLeaveTypeId(value?.id);
															SetLeaveBalanceFormOpen(true);
														}}
													>
														<EditIcon className={datagridStyles.editIcon} />
													</IconButton>
												</Box>
											</CustomTableCell>
										)}
									<Divider />
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<LeaveBalanceForm
				leaveTypeList={leaveTypeList}
				open={leavebalanceFormOpen}
				onClose={() => {
					SetLeaveBalanceFormOpen(false);
					refetch();
				}}
				leaveTypeId={leaveTypeId}
				organizationLeaves={organizationLeaves}
			/>
		</Box>
	);
};

export default LeaveSummary;
