import React from 'react';
import { CircularProgress } from '@material-ui/core';

interface Props {
	color?: string;
}

function Loader(props: Props) {
	const { color } = props;
	return (
		<CircularProgress
			style={{
				height: '20px',
				width: '20px',
				color: color ? color : '#4285F4',
			}}
		/>
	);
}

export default Loader;
