import React, { useState, useEffect, useContext } from 'react';
import keyBy from 'lodash/keyBy';

import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	FunctionField,
	useQuery,
} from 'react-admin';

import {
	Box,
	makeStyles,
	Tooltip,
	Chip,
	IconButton,
	TextField as TextComponent,
	Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { GET_EMPLOYEE_SKILL_MAPPING } from './EmployeeSkillMapping.gql';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import { EmployeeSkillFilterFormBody } from './EmployeeSkillFilterFormBody.component';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import { searchBarTheme } from '../../Layout/Theme.component';
import { globalStyles } from '../../Layout/styles';
import { SearchIcon } from '../../assets/SvgIcons';
import { UserProfileContext } from '../../App';
import { SKILL_EXPORT_COLUMNS } from './constant';
import {
	formatEmployeeId,
	exportToCsv,
	getPrefix,
} from '../../Utils/string.util';
import EmployeeSkill from '../../SharedComponents/EmployeeSkill.component';
import { head } from 'lodash';
import { EmployeeSkillMappingListPagination } from './EmployeeSkillMappingListPagination';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';

const theme = createTheme({
	overrides: {
		MuiSvgIcon: {
			colorPrimary: {
				color: 'darkgray',
			},
		},
		MuiFilledInput: {
			root: {
				width: '344px',
				height: '36px',
				backgroundColor: 'white !important',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				opacity: 1,
				'&:hover': {
					backgroundColor: 'none',
				},
			},
			underline: {
				'&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
				'&:hover:before': {
					borderBottom: 'none',
				},
			},
			input: {
				fontSize: '12px !important',
				font: 'normal normal medium 12px/37px Manrope !important',
				letterSpacing: '0px',
				color: '#212121',
				opacity: 1,
			},
		},
		MuiInputLabel: {
			filled: {
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#7C7C7C',
				opacity: 1,
				fontSize: '12px',
			},
		},
	},
});

const useStyles = makeStyles({
	buttonStyle: {
		position: 'absolute',
		left: '15.5%',
		top: '19px',
		cursor: 'pointer',
	},
	hideButton: {
		display: 'none',
	},
	resetStyle: {
		marginRight: '5px',
		marginTop: '5px',
	},
	toolbarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '50px',
		marginBottom: '15px',
	},
	filterButton: {
		margin: theme.spacing(1),
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	exportButton: {
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		font: 'normal normal 12px/37px Manrope',
	},
	skillName: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '200px',
	},
	description: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '200px',
		'&:hover': {
			cursor: 'pointer',
		},
	},
});

export const EmployeeSkillMappingList = (props) => {
	const [activeUsersId, setActiveUsersId] = useState([]);
	const [activeEmployeeSkillMappingIds, setActiveEmployeeSkillMappingIds] =
		useState([]);
	const [isDrawerShown, setIsDrawerShown] = useState(false);

	const [isEmployeeSkillSubmitted, setIsEmployeeSkillSubmitted] =
		useState(false);
	const [employeeSkillModal, setEmployeeSkillModal] = useState(false);

	const [employeeSkillMapping, setEmployeeSkillMapping] = useState(null);
	const classes = useStyles();
	const commonStyles = globalStyles();
	const {
		permissions: permissionList,
		filterState,
		dispatchFilter,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext(UserProfileContext);
	const { data: skillMappings, refetch: refetchSkillMappings } = ApolloUseQuery(
		GET_EMPLOYEE_SKILL_MAPPING,
		{
			variables: {
				isActive: true,
				firstName: `%${filterState?.employeeSkillSearchText ?? ''}%`,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (isEmployeeSkillSubmitted) {
			setIsEmployeeSkillSubmitted(false);
			refetchSkillMappings();
		}
	}, [isEmployeeSkillSubmitted, refetchSkillMappings]);
	const { data: skillLevelList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_level',
		payload: {
			sort: { field: 'level', order: 'ASC' },
		},
	});

	const { data: skillMasterList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_master',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});

	useEffect(() => {
		if (skillMappings?.employee_skill_mapping?.length > 0) {
			const usersId = skillMappings.employee_skill_mapping.map(
				(employee_skill_mapping) => employee_skill_mapping?.employee_id
			);
			const skillMappingIds = skillMappings.employee_skill_mapping.map(
				(employee_skill_mapping) => employee_skill_mapping?.id
			);
			if (usersId?.length > 0) {
				setActiveUsersId(usersId);
			}
			if (skillMappingIds.length > 0) {
				setActiveEmployeeSkillMappingIds(skillMappingIds);
			}
		} else {
			setActiveEmployeeSkillMappingIds([]);
		}
	}, [skillMappings]);

	const handleEditSkillMapping = (id) => {
		if (skillMappings?.employee_skill_mapping?.length > 0) {
			const skillMapping = skillMappings.employee_skill_mapping.find(
				(employeeSkillMapping) => employeeSkillMapping?.id === id
			);
			if (skillMapping) {
				setEmployeeSkillMapping({
					id: skillMapping.id,
					employee_id: skillMapping?.employee_id,
					user: skillMapping?.user,
					skill_id: skillMapping?.skill_id,
					skill_level_id: skillMapping?.skill_level_id,
					description: skillMapping?.description || null,
					is_primary: skillMapping.is_primary,
				});
				setEmployeeSkillModal(true);
			}
		}
	};

	const EmployeeSkillMappingFilter = (props) => {
		return (
			<DrawerFilterContainer
				open={isDrawerShown}
				close={() => {
					setIsDrawerShown(false);
				}}
			>
				<EmployeeSkillFilterFormBody />
			</DrawerFilterContainer>
		);
	};

	const generateCSV = () => {
		if (skillMappings?.employee_skill_mapping?.length === 0) {
			return;
		}

		const skillMatrixRow = skillMappings?.employee_skill_mapping?.map(
			(exportSkill) => {
				const existingEmployeeId = exportSkill?.user?.employee?.emp_id || '';
				const existingContractorId =
					head(exportSkill?.user?.contractors)?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const empId =
					exportSkill?.user?.user_type === 'employee'
						? exportSkill?.user?.employee?.emp_id
							? `${
									getPrefix(
										exportSkill?.user?.employee?.employee_prefix?.name,
										exportSkill?.user?.employee?.employee_prefix?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: '--'
						: head(exportSkill?.user?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(exportSkill?.user?.contractors)?.contractor_prefix?.name,
									head(exportSkill?.user?.contractors)?.contractor_prefix?.value
								) || ''
						  }${
								exportSkill?.user?.contractors &&
								head(exportSkill?.user?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '--';
				return [
					empId || '--',
					exportSkill?.user?.full_name || '--',
					exportSkill?.user?.email || '--',
					exportSkill?.user?.user_type
						? exportSkill?.user?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
					exportSkill?.skill_master?.name || '--',
					exportSkill?.skill_level?.level || '--',
					exportSkill?.is_primary ? 'Yes' : 'No',
					exportSkill?.description || '--',
				];
			}
		);

		const skillMatrixExportData = [SKILL_EXPORT_COLUMNS, ...skillMatrixRow];
		exportToCsv('Skill_Matrix.csv', skillMatrixExportData);
	};

	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'employee_id':
				return (
					<ReferenceField
						link={false}
						source='employee_id'
						reference='user'
						label='Full Name'
						resource={props.resource}
						record={filterValues}
						basePath={props.basePath}
					>
						<FunctionField
							label='Full Name'
							render={(record) => (
								<Chip
									label={`${record?.full_name || ''} `}
									variant='outlined'
									onDelete={() => {
										removeFilter(filter);
									}}
								/>
							)}
						/>
					</ReferenceField>
				);
			case 'skill_id':
				return (
					filterValues?.skill_id.length > 0 &&
					filterValues?.skill_id.map((skill, index) => (
						<ReferenceField
							link={false}
							source='skill'
							reference='skill_master'
							label='Full Name'
							resource={props.resource}
							basePath={props.basePath}
							record={{ skill }}
							key={index}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<Chip
										key={index}
										label={record?.name}
										variant='outlined'
										onDelete={() => {
											filterValues.skill_id.splice(index, 1);
											setFilters(filterValues, displayedFilters);
										}}
									/>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'skill_level_id':
				return (
					filterValues?.skill_level_id.length > 0 &&
					filterValues?.skill_level_id.map((skillLevel, index) => (
						<ReferenceField
							link={false}
							source='skillLevel'
							reference='skill_level'
							resource={props?.resource}
							record={{ skillLevel }}
							basePath={props?.basePath}
							key={index}
						>
							<FunctionField
								label=''
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.level}`}
											variant='outlined'
											onDelete={() => {
												filterValues.skill_level_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	const skillMapping = {
		data: keyBy(skillMappings?.employee_skill_mapping, 'id'),
		ids: skillMappings?.employee_skill_mapping?.map(({ id }) => id),
		currentsort: { field: 'name', order: 'ASC' },
		basePath: `/employee_skill_mapping`,
		resource: 'employee_skill_mapping',
		selectedIds: [],
	};

	return (
		<>
			<Box className={commonStyles.pageTitle}>SKILL MATRIX </Box>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				marginTop='10px'
			>
				<form onSubmit={(event) => event.preventDefault()}>
					<ThemeProvider theme={searchBarTheme}>
						<Box width='344px'>
							<TextComponent
								placeholder='Search employee name'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								filter={{ first_name: true, id: activeUsersId }}
								onChange={(e) => {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_SKILL_SEARCH_TEXT,
										payload: e?.target?.value,
									});
								}}
								value={filterState?.employeeSkillSearchText ?? ''}
							/>
						</Box>
					</ThemeProvider>
				</form>
				<Box display={'flex'}>
					<Box marginRight={'10px'}>
						<ExportButtonWithLoader
							generateCSV={generateCSV}
							isDisabled={!skillMappings?.employee_skill_mapping?.length}
						/>
					</Box>
					<Tooltip title={'Filter'}>
						<IconButton
							onClick={() => setIsDrawerShown(true)}
							className={commonStyles.filterButton}
						>
							<FilterListIcon />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<List
				pagination={
					<EmployeeSkillMappingListPagination
						employeeSkillData={
							skillMappings?.employee_skill_mapping?.length ?? -1
						}
					/>
				}
				perPage={15}
				{...skillMapping}
				bulkActionButtons={false}
				filters={
					<Box className={commonStyles.filterStyle}>
						<Box display='flex' flexDirection='column' width='100%'>
							<AppliedFiltersContainer totalCount={(total) => {}}>
								{getAppliedFilters}
							</AppliedFiltersContainer>
						</Box>
					</Box>
				}
				actions={<EmployeeSkillMappingFilter />}
				filter={{ id: activeEmployeeSkillMappingIds }}
				title={' '}
				empty={false}
			>
				<Datagrid className={commonStyles.listStyle}>
					<ReferenceField
						source='employee_id'
						reference='user'
						link={false}
						label='NAME'
						sortBy='user.first_name'
					>
						<FunctionField
							render={(skill) => {
								return (
									<Tooltip
										title={`${skill?.full_name || '- -'}`}
										placement='right'
									>
										<Typography
											className={`${classes.description} ${classes.ellipsis}`}
										>
											{`${skill?.full_name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					</ReferenceField>
					<ReferenceField
						source='skill_id'
						reference='skill_master'
						link={false}
						label='SKILL'
						sortBy='skill_master.name'
					>
						<TextField source='name' />
					</ReferenceField>
					<ReferenceField
						source='skill_level_id'
						reference='skill_level'
						link={false}
						label='EXPERTISE'
						sortBy='skill_level.level'
					>
						<FunctionField
							render={(skill) => {
								return (
									<Tooltip title={`${skill?.level || '- -'}`} placement='right'>
										<Typography
											className={`${classes.description} ${classes.ellipsis}`}
										>
											{`${skill?.level || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						{/* <TextField source='level' /> */}
					</ReferenceField>
					<ReferenceField
						source='employee_id'
						reference='user'
						link={false}
						label='USER TYPE'
						sortBy='user.user_type'
						sortByOrder='DESC'
					>
						<FunctionField
							render={(user) =>
								user.user_type === 'employee' ? 'Employee' : 'Contractor'
							}
						/>
					</ReferenceField>

					<ReferenceField
						link={false}
						source='id'
						reference='employee_skill_mapping'
						label='IS PRIMARY'
						sortBy='is_primary'
					>
						<FunctionField
							render={(skill) => {
								return (
									<Typography className={`${classes.description}`}>
										{`${skill?.is_primary ? 'Yes' : 'No'}`}
									</Typography>
								);
							}}
						/>
					</ReferenceField>
					<ReferenceField
						source='id'
						reference='employee_skill_mapping'
						link={false}
						label='DESCRIPTION'
						sortBy='description'
					>
						<FunctionField
							render={(skill) => {
								return (
									<Tooltip
										title={`${skill?.description || '- -'}`}
										placement='right'
									>
										<Typography
											className={`${classes.description} ${classes.ellipsis}`}
										>
											{`${skill?.description || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					</ReferenceField>
					{permissionList?.employee_skill_mapping?.update_permissions ? (
						<FunctionField
							render={(employeeSkillMapping) => (
								<Box className={classes.listEditIconContainer}>
									<IconButton
										className={classes.listEditIconButton}
										onClick={() => {
											handleEditSkillMapping(employeeSkillMapping.id);
										}}
									>
										<EditIcon className={classes.editIcon} />
									</IconButton>
								</Box>
							)}
						/>
					) : null}
				</Datagrid>
			</List>

			<EmployeeSkill
				open={employeeSkillModal}
				onClose={() => setEmployeeSkillModal(false)}
				record={employeeSkillMapping}
				setIsEmployeeSkillSubmitted={(value) =>
					setIsEmployeeSkillSubmitted(value)
				}
				skillLevelList={skillLevelList}
				skillMasterList={skillMasterList}
			/>
		</>
	);
};
