import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import dayjs from 'dayjs';
import { chain } from 'lodash';
import { formatAmount } from '../../Utils/string.util';
import { useGetPipelineSalesByMonthByIdQuery } from '../../generated/graphql';
import { useParams } from 'react-router';
import NoDataFound from '../NoDataFound.component';
import { insightChartTheme } from '../../Layout/Theme.component';
import { ChartDatePicker } from './CustomDatePicker.component';
import { ChartWithDateRange } from './modal';

const colors = {
	primary: '#196CF5',
};

interface ChartData {
	'Total Value': number;
	month: string;
	monthIndex: number;
	year: number;
}

export const OpportunityTotalSaleReport = (props: ChartWithDateRange) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const { id: boardId }: { id: string } = useParams();
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [currentFinancialYearMonths, setCurrentFinancialYearMonths] = useState(
		Array(12)
			.fill(1)
			.map((value, index) =>
				dayjs()
					.month(index + dayjs(defaultStartDate).month())
					.format('MMM')
			)
	);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineTotalSaleByMonth, loading: isLoading } =
		useGetPipelineSalesByMonthByIdQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				kanbanBoardId: boardId,
			},
		});

	useEffect(() => {
		const numberOfMonths = dayjs(defaultEndDate).diff(
			defaultStartDate,
			'month'
		);
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);

		setCurrentFinancialYearMonths(
			Array(numberOfMonths + 1)
				.fill(1)
				.map((value, index) =>
					dayjs()
						.month(index + dayjs(defaultStartDate).month())
						.format('MMM')
				)
		);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineTotalSaleByMonth) {
			return;
		}
		const formatData = pipelineTotalSaleByMonth.opportunities.map(
			(opportunity) => ({
				...opportunity,
				month: dayjs(opportunity.close_date).format('MMM'),
				monthIndex: dayjs(opportunity.close_date).month(),
				year: dayjs(opportunity.close_date).year(),
			})
		);
		const data = chain(formatData)
			.groupBy('month')
			.map((month) => ({
				month: month[0].month,
				'Total Value': month.reduce(
					(total, currentVal) => total + currentVal.value,
					0
				),
				monthIndex: month[0].monthIndex,
				year: month[0].year,
			}))
			.value()
			.sort((a, b) => a.monthIndex - b.monthIndex)
			.sort((a, b) => a.year - b.year);
		const yearFormatData = currentFinancialYearMonths.map((month) => {
			const monthData = data.find((opportunity) => opportunity.month === month);
			if (monthData) {
				return monthData;
			} else {
				return {
					'Total Value': 0,
					month: month,
					monthIndex: 0,
					year: 0,
				};
			}
		});
		setChartData(yearFormatData);
	}, [
		pipelineTotalSaleByMonth,
		currentFinancialYearMonths,
		startDate,
		endDate,
	]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{payload[0]?.payload['month']}</p>
					<p>{`Total Value : ${formatAmount(
						parseInt(payload[0]?.payload['Total Value']),
						currency
					)}`}</p>
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='month'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey={'Total Value'}
						fill={colors.primary}
						barSize={10}
						stackId='a'
						radius={[10, 10, 0, 0]}
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Total Sales
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => {
								const numberOfMonths = dayjs(date).diff(startDate, 'month');
								setCurrentFinancialYearMonths(
									Array(numberOfMonths + 1)
										.fill(1)
										.map((value, index) =>
											dayjs()
												.month(index + dayjs(startDate).month())
												.format('MMM')
										)
								);
								setEndDate(date);
							}}
							selectedStartDate={(date: string) => {
								const numberOfMonths = dayjs(endDate).diff(
									dayjs(date),
									'month'
								);
								setCurrentFinancialYearMonths(
									Array(numberOfMonths + 1)
										.fill(1)
										.map((value, index) =>
											dayjs()
												.month(index + dayjs(date).month())
												.format('MMM')
										)
								);
								setStartDate(date);
							}}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
