import React, { useContext } from 'react';
import { UserProfileContext } from '../../App';

import { FunctionField, ReferenceField } from 'react-admin';

import { DEFAULT_PROFILE_PIC } from '../../config/constant';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import {
	Paper,
	Avatar,
	Link as MuiLink,
	Typography,
	Box,
	Tooltip,
} from '@material-ui/core';
import { gridCardGlobalStyle, ellipsisStyle } from '../../Layout/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import { calculateDateDifference } from '../../Utils/date-time.util';
import dayjs from 'dayjs';
import { formatEmployeeId, getPrefix } from '../../Utils/string.util';

const useStyles = makeStyles((theme) => ({
	identity: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleContainer: {
		height: '22px',
		width: '130px',
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	subTitleContainer: {
		textAlign: 'center',
	},
	subTitleContainerForDesignation: {
		width: '100px',
		height: '22px',
		textAlign: 'center',
		marginBottom: '6px',
	},
	avatar: {
		width: '67px',
		height: '67px',
		backgroundColor: 'aliceblue',
	},
	titleContent: {
		height: '22px',
		font: 'normal normal bold 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#212121',
		opacity: 1,
		fontSize: '12px !important',
	},
	subTitleContent: {
		height: '22px',
		fontSize: '12px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	subTitleJobLevelContent: {
		height: '22px',
		fontSize: '11px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	secondaryInfo: {
		paddingLeft: '10px',
		paddingRight: '10px',
		height: '22px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		opacity: 1,
		borderRadius: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-2px',
		marginBottom: '0',
		zIndex: 10,
		width: '88px',
	},
	secondaryInfoContent: {
		fontSize: '10px !important',
		font: 'normal normal 800 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#FFFFFF',
		opacity: 1,
	},
	additionalInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '8px',
	},
	additionalInfoIcon: {
		width: '16px',
		height: '16px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 100,
		color: '#4285F4',
	},
	additionalInfoContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '10px !important',
		font: 'normal normal 600 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	contactIcon: {
		fontSize: '10px',
		color: '#4285F4',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	expiredContract: {
		color: '#EA4335',
		fontSize: '10px !important',
		fontFamily: 'Manrope',
	},
	leftContract: {
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#34A853',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
}));
interface Consultant {
	contractor_cost: number;
	contractor_id: string;
	contractor_status_id: string;
	created_by: string;
	department_id: string;
	emergency_contact_number: string;
	employment_status: string;
	extension_status: false;
	gender: string;
	id: string;
	is_billable: boolean;
	is_recontract_eligible: boolean;
	job_level_id: string;
	join_date: string;
	personal_email: string;
	reportee_id: string;
	updated_by: string;
	user_id: string;
	vendor_id: string;
	vendor_type: string;
	end_date: string;
	contractor_prefix_id?: string;
	empIdLength?: string;
	isFixedEmpIdLengthEnabled?: boolean;
}
interface Props {
	resource: string;
	consultant: Consultant;
	basePath: string;
}
export const ConsultantCard = (props: Props) => {
	const { resource, consultant, basePath } = props;
	const { empIdLength, isFixedEmpIdLengthEnabled, setTabGlobalIndex } =
		useContext<any>(UserProfileContext);
	const classes = useStyles();
	const styles = ellipsisStyle();

	const cardGlobalStyle = gridCardGlobalStyle();

	const getConsultantEndDate = (date: string) => {
		const diffDays = calculateDateDifference(date);
		const consultantAvailableDays = `${diffDays} Days`;

		if (diffDays === 0) {
			return <span className={classes.leftContract}>Due in 1 day</span>;
		}

		if (diffDays <= 0) {
			return <span className={classes.expiredContract}>Contract Expired</span>;
		}

		if (diffDays >= 30) {
			return (
				<>
					<span className={classes.days}>End Date: </span>
					<span className={classes.leftContract}>
						{dayjs(date).format('DD-MMM-YYYY')}
					</span>
				</>
			);
		}

		if (diffDays > 0)
			return (
				<>
					<span className={classes.leftContract}>
						{consultantAvailableDays}
					</span>
					<span className={classes.days}>left in contract</span>
				</>
			);
	};

	const formattedContractorId = formatEmployeeId(
		consultant?.contractor_id,
		empIdLength,
		isFixedEmpIdLengthEnabled
	);

	const resetTabGlobalIndexForNewConsultant = () => {
		setTabGlobalIndex(0);
	};

	return (
		<Paper
			className={cardGlobalStyle.container}
			onClick={resetTabGlobalIndexForNewConsultant}
		>
			<MuiLink
				component={Link}
				to={`/contractor/${consultant?.id}`}
				underline='none'
			>
				<div className={classes.identity}>
					<ReferenceField
						link={false}
						source='user_id'
						reference='user'
						label='Full Name'
						resource={resource}
						record={consultant}
						basePath={basePath}
					>
						<FunctionField
							render={(record: any) => (
								<Avatar
									alt='employee_image'
									// TODO We need to findout the high resolution image from the google api
									src={`${record?.profile_pic || DEFAULT_PROFILE_PIC}`}
									className={classes.avatar}
								/>
							)}
						/>
					</ReferenceField>

					<Box>
						<div className={classes.titleContainer}>
							<ReferenceField
								link={false}
								source='user_id'
								reference='user'
								label='Full Name'
								resource={resource}
								record={consultant}
								basePath={basePath}
							>
								<FunctionField
									className={classes.titleContent}
									label='Full Name'
									sortBy='first_name'
									sortByOrder='ASC'
									render={(record: any) => {
										return (
											<Tooltip
												title={`${record?.first_name} ${
													record?.middle_name || ''
												} ${record?.last_name || ''}`}
												placement='right'
											>
												<Box className={`${styles.ellipsis}`}>
													{`${record?.first_name} ${
														record?.middle_name || ''
													} ${record?.last_name || ''}`}
												</Box>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
						</div>
					</Box>

					<Box>
						<div className={`${classes.subTitleContainerForDesignation}`}>
							<ReferenceField
								link={false}
								source='designation_id'
								reference='designation'
								resource={resource}
								record={consultant}
								basePath={basePath}
							>
								<FunctionField
									className={`${classes.subTitleContent}`}
									record={consultant}
									render={(record: any) => {
										return (
											<Tooltip
												title={`${record?.title || '- -'}`}
												placement='right'
											>
												<Box
													className={`${classes.subTitleContent} ${styles.ellipsis}`}
												>
													{record?.title || '- -'}
												</Box>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
						</div>
					</Box>

					<Box marginBottom={'6px'}>
						<div className={classes.secondaryInfo}>
							{consultant?.contractor_prefix_id ? (
								<ReferenceField
									link={false}
									source='contractor_prefix_id'
									reference='contractor_prefix'
									record={consultant}
								>
									<>
										<FunctionField
											render={(record: any) => {
												return (
													<Tooltip
														title={`${getPrefix(record?.name, record?.value)}${
															formattedContractorId || ''
														}`}
														placement='right'
													>
														<Box
															maxWidth={'80px'}
															className={`${classes.secondaryInfoContent} ${styles.ellipsis}`}
														>
															{`${getPrefix(record?.name, record?.value)}${
																formattedContractorId || ''
															}`}
														</Box>
													</Tooltip>
												);
											}}
										/>
									</>
								</ReferenceField>
							) : (
								<Tooltip title={formattedContractorId || ''} placement='right'>
									<Typography
										className={`${classes.secondaryInfoContent} ${styles.ellipsis}`}
									>{`${formattedContractorId || ''}`}</Typography>
								</Tooltip>
							)}
						</div>
					</Box>

					{/* consultant level */}
					<Box>
						<div className={classes.subTitleJobLevelContent}>
							<ReferenceField
								link={false}
								label='Job level'
								source='job_level_id'
								reference='job_level'
								record={consultant}
								basePath={basePath}
								resource={resource}
							>
								<FunctionField
									className={classes.subTitleJobLevelContent}
									label=''
									render={(record: any) => {
										return (
											<Tooltip
												title={`${record?.level || '- -'}`}
												placement='right'
											>
												<Box
													className={`${classes.subTitleJobLevelContent} ${styles.ellipsis}`}
												>
													{record?.level || '- -'}
												</Box>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
						</div>
					</Box>
				</div>

				<div className={classes.additionalInfo} style={{ display: 'flex' }}>
					<div className={classes.additionalInfoIcon}>
						<SmartphoneIcon className={classes.contactIcon} />
					</div>
					<div className={classes.additionalInfoContent}>
						<ReferenceField
							link={false}
							source='user_id'
							reference='user'
							label='Phone Number'
							resource={resource}
							record={consultant}
							basePath={basePath}
						>
							<FunctionField
								className={classes.additionalInfoContent}
								label=''
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.phone_number || '- -'}`}
											placement='right'
										>
											<Box
												className={`${classes.additionalInfoContent} ${styles.ellipsis}`}
											>
												{record?.phone_number || '- -'}
											</Box>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					</div>
				</div>
				<div className={classes.additionalInfoContent}>
					<Typography>
						{consultant?.end_date
							? getConsultantEndDate(consultant.end_date)
							: '- -'}
					</Typography>
				</div>
			</MuiLink>
		</Paper>
	);
};

export default ConsultantCard;
