import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	SelectInput,
	maxLength,
	useMutation,
	useNotify,
	useRefresh,
	email,
	DateInput,
	number,
	RadioButtonGroupInput,
	ReferenceInput,
	AutocompleteInput,
	minLength,
	regex,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { AutocompleteSearchStyles } from '../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Typography,
	createTheme,
	makeStyles,
	CircularProgress,
	TextField,
	Tooltip,
	InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';
import {
	autoCompleteTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	currencyInputStyle,
	DialogActions,
	dropDownEllipsisStyle,
} from '../../../Layout/styles';
import { bloodGroupChoices, EMPLOYEE_GENDER_CHOICES } from '../constant';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetLocationsByOrgIdQuery,
	useGetShiftAndLeaveGroupsQuery,
	useUpdateEmployeeLeaveTypeMappingMutation,
} from '../../../generated/graphql';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_LEAVE_TYPES } from '../gqlQueries';
import CustomField from '../../../SharedComponents/CustomField.component';
import { Autocomplete } from '@material-ui/lab';
import { SearchIcon } from '../../../assets/SvgIcons';
import { validatePhoneNumber, trimInput } from '../../../Utils/string.util';

const useStyles = makeStyles((theme) => ({
	inputMonth: {
		marginTop: '24px',
	},
}));

export const DialogContent = withStyles(() => ({
	root: {
		height: '520px',
		width: '600px',
	},
}))(MuiDialogContent);

export const currencySelectBox = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				width: '80px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#5C5C5C',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});
interface Address {
	city: string;
	country: string;
	houseName: string;
	postalCode: string;
	state: string;
	streetName: string;
}
interface EmployeeForm {
	blood_group: string;
	emp_billing_cost: number;
	emp_cost: number;
	employee_joining_source_id: string;
	highest_academic_degree: string;
	id: string;
	join_date: string;
	marital_status: boolean;
	personal_email: string;
	address: Address;
	years: string;
	months: string;
	currency_id?: string;
	org_shift_id: string;
	last_date: string;
	leave_group_id?: string;
	employeeStatus: string;
	dob?: string;
	institution_name?: string;
	location_id?: string;
	custom_field_1?: string;
	custom_field_2?: string;
	custom_field_3?: string;
	custom_field_4?: string;
	custom_field_5?: string;
	custom_field_6?: string;
	custom_field_7?: string;
	custom_field_8?: string;
	custom_field_9?: string;
	emergency_contact_number?: string;
	emergency_contact_name?: string;
	wedding_date?: string;
}

interface LeavePolicy {
	id: string;
	label: string;
}

interface OrgShift {
	id: string;
	name: string;
}

interface EmployeeDetailsProps {
	onClose: () => void;
	open: boolean;
	record: EmployeeForm;
	validate: () => void;
	refetch: () => void;
	customFields: {
		id: any;
		label: string;
		type: string;
		validation: any;
		options: {
			id: string;
			name: string;
		}[];
		is_active: boolean;
		index: number;
	}[];
}

const EditEmployeeDetails = (props: EmployeeDetailsProps) => {
	const {
		open,
		onClose,
		record: employee,
		refetch: refetchEmployee,
		customFields,
	} = props;
	const [isLeavePolicyWarningShown, setIsLeavePolicyWarningShown] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [leavePolicyId, setLeavePolicyId] = useState<string | null>('');
	const [orgShiftId, setOrgShiftId] = useState<string | null>(null);
	const [isEmailUniqueViolationError, setIsEmailUniqueViolationError] =
		useState(false);
	const [marriedStatus, setMarriedStatus] = useState('');
	const [locationId, setLocationId] = useState<string | null>(null);
	const [optionValue, setOptionValue] = useState();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const currencyInputStyles = currencyInputStyle();
	const classes = modalFormStyle();
	const inputClasses = useStyles();
	const ellipsis = dropDownEllipsisStyle();
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const { dateFormat, orgId: organizationId } =
		useContext<any>(UserProfileContext);

	const handleClose = () => {
		if (
			employee?.location_id !== locationId ||
			employee?.leave_group_id !== leavePolicyId ||
			employee?.org_shift_id !== orgShiftId
		) {
			setLocationId(employee?.location_id || null);
			setLeavePolicyId(employee?.leave_group_id || null);
			setOrgShiftId(employee?.org_shift_id || null);
			setEndDateError(false);
			onClose();
		} else {
			setEndDateError(false);
			onClose();
		}
	};
	const [endDateError, setEndDateError] = useState(false);

	const { data: OrgData } = useGetShiftAndLeaveGroupsQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	const [updateEmployeeLeaveTypeMapping] =
		useUpdateEmployeeLeaveTypeMappingMutation();
	const { data: orgLeaveTypes } = useApolloQuery(GET_LEAVE_TYPES, {
		variables: {
			leavePolicyId: leavePolicyId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: location } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (employee?.id) {
			setLocationId(employee?.location_id || null);
			setLeavePolicyId(employee?.leave_group_id || null);
			setOrgShiftId(employee?.org_shift_id || null);
		}
	}, [employee]);

	const onSave = async (employeeFormValues: EmployeeForm) => {
		if (!employeeFormValues) {
			return;
		}
		if (
			dayjs(employeeFormValues?.join_date).isAfter(
				employeeFormValues?.last_date
			)
		) {
			setEndDateError(true);
			return;
		}
		if (leavePolicyId && leavePolicyId !== employee?.leave_group_id) {
			setIsLoading(true);
			updateLeaveTypeMapping(employeeFormValues);
			return;
		}
		setIsLoading(true);
		await saveEmployeeDetails(employeeFormValues);
	};

	const updateLeaveTypeMapping = async (values: EmployeeForm) => {
		if (!values || !orgLeaveTypes) {
			return;
		}
		const response = await updateEmployeeLeaveTypeMapping({
			variables: {
				employeeIds: [employee.id],
				employeeLeaveTypeMapping: orgLeaveTypes.org_leave_types.map(
					(leaveType: any) => ({
						leave_type_id: leaveType.id,
						employee_id: employee.id,
						renewal_date:
							leaveType?.leave_increments > 0
								? dayjs().add(leaveType.interval, 'days').format('YYYY-MM-DD')
								: null,
						opening_balance: leaveType?.default_amount || 0,
					})
				),
			},
		});
		if (!response.errors) {
			saveEmployeeDetails(values);
		}
	};

	const saveEmployeeDetails = async (values: EmployeeForm) => {
		if (!values) {
			return;
		}
		const previousexperience = `${values?.years || '00'}:${
			values.months || '00'
		}`;
		const marriageStatus = marriedStatus
			? marriedStatus === 'Married'
				? true
				: false
			: employee?.marital_status;
		const { years, months, employeeStatus, ...formValues } = values;

		await mutate(
			{
				type: 'update',
				resource: 'employee',
				payload: {
					id: employee?.id,
					data: {
						...formValues,
						personal_email: formValues?.personal_email || null,
						highest_academic_degree:
							formValues?.highest_academic_degree || null,
						institution_name: formValues?.institution_name || null,
						marital_status: marriageStatus,
						prior_experience: previousexperience,
						location_id: locationId || null,
						custom_field_1: formValues?.custom_field_1 || null,
						custom_field_2: formValues?.custom_field_2 || null,
						custom_field_3: formValues?.custom_field_3 || null,
						custom_field_4: formValues?.custom_field_4 || null,
						custom_field_5: formValues?.custom_field_5 || null,
						custom_field_6: formValues?.custom_field_6 || null,
						custom_field_7: formValues?.custom_field_7 || null,
						custom_field_8: formValues?.custom_field_8 || null,
						custom_field_9: formValues?.custom_field_9 || null,
						leave_group_id: leavePolicyId || null,
						org_shift_id: orgShiftId || null,
						wedding_date: formValues?.wedding_date || null,
						emergency_contact_number:
							formValues?.emergency_contact_number || null,
						emergency_contact_name: formValues?.emergency_contact_name || null,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					setIsLoading(false);
					notify('Employee Details Updated Successfully');
					refresh();
					refetchEmployee();
					handleClose();
				},
				onFailure: (error: any) => {
					//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
					if (error.message.includes('employee_personal_email_key')) {
						setIsLoading(false);
						setIsEmailUniqueViolationError(true);
						return;
					} else {
						setIsLoading(false);
						handleClose();
						notify(`Create failed: ${error.message}`, 'warning');
					}
				},
			}
		);
	};

	const validateEmployeeFormValues = async () => {
		const employeeFormErrors = {} as EmployeeForm;
		if (isEmailUniqueViolationError) {
			employeeFormErrors.personal_email = 'Email already exist!';
		}
		return employeeFormErrors;
	};

	const getLocation = (locatId: string) => {
		const response = location?.org_location?.find(
			(location) => location.id === locatId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getLeavePolicy = (policyId: string | null) => {
		const response = OrgData?.org_leave_group?.find(
			(policy: LeavePolicy) => policy.id === policyId
		);
		return response ? { id: response?.id, label: response?.label } : null;
	};

	const getOrgShift = (shiftId: string) => {
		const response = OrgData?.org_shift?.find(
			(shift: OrgShift) => shift.id === shiftId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const handlePolicyDropdown = (
		event: object,
		newValue: LeavePolicy | null
	) => {
		if (newValue === null) {
			setLeavePolicyId('');
			return;
		}
		setIsLeavePolicyWarningShown(true);
		setLeavePolicyId(newValue.id);
	};
	const handleShiftDropdown = (event: object, newValue: OrgShift | null) => {
		if (newValue === null) {
			setOrgShiftId('');
			return;
		}

		setOrgShiftId(newValue.id);
	};

	const handleChange = (event: any) => {
		setOptionValue(event);
	};

	return (
		<Box>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box>
						<Box className={classes.headerContainer} padding='10px 20px 0 20px'>
							<Typography className={classes.heading}>
								Edit Basic Details
							</Typography>
							<CloseIcon className={classes.closeIcon} onClick={handleClose} />
						</Box>
						<Form
							initialValues={employee}
							onSubmit={onSave}
							validate={validateEmployeeFormValues}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box width={'100%'} display='flex' flexDirection='column'>
										<DialogContent>
											<Box>
												<Typography className={classes.label}>
													Personal Email
												</Typography>
												<TextInput
													source='personal_email'
													fullWidth={true}
													label={''}
													validate={[email(), maxLength(250)]}
													onChange={() => {
														setIsEmailUniqueViolationError(false);
													}}
												/>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Prior Experience
														</Typography>
														<TextInput
															source='years'
															label={''}
															InputProps={{
																endAdornment: 'Years',
															}}
															validate={[
																number(),
																maxLength(
																	2,
																	'must be in the format of 00,01,10'
																),
															]}
														/>
													</Box>
													<Box className={classes.multipleInput}>
														<Box className={inputClasses.inputMonth}>
															<TextInput
																source='months'
																label={''}
																InputProps={{
																	endAdornment: 'Months',
																}}
																validate={[
																	number(),
																	maxLength(
																		2,
																		'must be in the format of 00,01,10'
																	),
																]}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Address(House Name)
														</Typography>
														<TextInput
															source='address.houseName'
															id='houseName'
															label={''}
														/>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Address(Street Name)
														</Typography>
														<TextInput
															source='address.streetName'
															id='streetName'
															label={''}
														/>
													</Box>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															City
														</Typography>
														<TextInput
															source='address.city'
															fullWidth={true}
															label={''}
															id='city'
														/>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															State
														</Typography>
														<TextInput
															source='address.state'
															fullWidth={true}
															label={''}
															id='state'
														/>
													</Box>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Country
														</Typography>
														<ReferenceInput
															reference='geo_list'
															source='address.country'
															id='country'
															label=''
															filterToQuery={(searchText) => ({
																name: searchText,
															})}
														>
															<AutocompleteInput optionText='name' />
														</ReferenceInput>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Postal Code
														</Typography>
														<TextInput
															source='address.postalCode'
															id='postalCode'
															fullWidth={true}
															label={''}
															validate={[number(), maxLength(6)]}
														/>
													</Box>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Joining Source
														</Typography>
														<ReferenceInput
															label=''
															source='employee_joining_source_id'
															reference='emp_joining_source'
															sort={{ field: 'label', order: 'ASC' }}
														>
															<SelectInput optionText='label' />
														</ReferenceInput>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Highest Degree
														</Typography>
														<TextInput
															source='highest_academic_degree'
															fullWidth={true}
															label={''}
														/>
													</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Institution Name
													</Typography>
													<TextInput
														source='institution_name'
														fullWidth={true}
														label={''}
														validate={[
															minLength(3, 'minimum three characters required'),
														]}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Blood Group
													</Typography>
													<SelectInput
														label=''
														source='blood_group'
														choices={bloodGroupChoices}
													/>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Joining Date
														</Typography>
														<Field
															name='join_date'
															validate={(value: any) => {
																if (
																	dayjs(value).isValid() === true ||
																	value === null
																) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props: any) => (
																<div>
																	<CustomDateInput
																		name={props.input.name}
																		initialValue={props.input.value || null}
																		onChange={(value: any) => {
																			props.input.onChange(value);
																			setEndDateError(false);
																		}}
																		dateFormat={dateFormat}
																	/>
																</div>
															)}
														</Field>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Last Date
														</Typography>
														<Field
															name='last_date'
															validate={(value: any) => {
																if (
																	dayjs(value).isValid() === true ||
																	value === null
																) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props: any) => (
																<div>
																	<CustomDateInput
																		name={props.input.name}
																		initialValue={props.input.value || null}
																		onChange={(value: any) => {
																			props.input.onChange(value);
																			setEndDateError(false);
																		}}
																		dateFormat={dateFormat}
																	/>
																</div>
															)}
														</Field>
														{endDateError && (
															<p className={classes.errorText}>
																Last date should be greater than joining date
															</p>
														)}
													</Box>
												</Box>
											</Box>
											<Box margin='10px 0 10px 0'>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Gender
														</Typography>
														<SelectInput
															label=''
															source='gender'
															choices={EMPLOYEE_GENDER_CHOICES}
														/>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															DOB
														</Typography>
														<Field
															name='dob'
															validate={(value: any) => {
																if (
																	dayjs(value).isValid() === true ||
																	value === null
																) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props: any) => (
																<div>
																	<CustomDateInput
																		name={props.input.name}
																		initialValue={props.input.value || null}
																		onChange={props.input.onChange}
																		dateFormat={dateFormat}
																	/>
																</div>
															)}
														</Field>
													</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Emergency Contact Name
													</Typography>
													<TextInput
														source='emergency_contact_name'
														fullWidth={true}
														label={''}
														validate={[
															minLength(3, 'minimum three characters required'),
															maxLength(
																25,
																'maximum 25 characters are allowed'
															),
														]}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Emergency Contact Number
													</Typography>
													<TextInput
														label=''
														source='emergency_contact_number'
														validate={[
															regex(
																validatePhoneNumber,
																'Invalid phone number'
															),
															maxLength(15, 'Maximum 15 digits allowed'),
														]}
														fullWidth
														onChange={() => {}}
														parse={trimInput}
													></TextInput>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Location
														</Typography>
														<Field name='location_id'>
															{(props) => (
																<AutocompleteSearch
																	placeholder={'Search Location'}
																	option={
																		location && location?.org_location
																			? location?.org_location?.map((value) => {
																					return {
																						id: value?.id,
																						name: value?.name,
																					};
																			  })
																			: []
																	}
																	onChange={(event) => {
																		props.input.onChange(event);
																		setLocationId(event);
																	}}
																	value={getLocation(locationId || '')}
																	name={'location_id'}
																	validates={false}
																/>
															)}
														</Field>
													</Box>
													<Box marginRight={'66px'}>
														<Typography className={classes.label}>
															Marital Status
														</Typography>
														<RadioButtonGroupInput
															source={
																employee?.marital_status
																	? 'Married'
																	: 'Unmarried'
															}
															label={''}
															onChange={(event: any) => {
																setMarriedStatus(event);
															}}
															defaultValue={
																employee?.marital_status
																	? 'Married'
																	: 'Unmarried'
															}
															choices={[
																{ id: 'Married', name: 'Married' },
																{ id: 'Unmarried', name: 'Unmarried' },
															]}
														/>
													</Box>
												</Box>
											</Box>
											<Box margin='10px 0 20px 0'>
												<Typography className={classes.label}>
													Wedding Date
												</Typography>
												<Field
													name='wedding_date'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Employee Billing Rate
														</Typography>
														<Box display='flex'>
															<Box
																className={currencyInputStyles.selectCurrency}
															>
																<ThemeProvider theme={currencySelectBox}>
																	<ReferenceInput
																		source='currency_id'
																		reference='master_currencies'
																		label=''
																	>
																		<SelectInput
																			optionText='currency_type'
																			disabled={true}
																		/>
																	</ReferenceInput>
																</ThemeProvider>
															</Box>
															<TextInput
																source='emp_billing_cost'
																fullWidth={true}
																label={false}
																validate={[number()]}
															/>
														</Box>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Employee Cost
														</Typography>
														<Box display='flex'>
															<Box
																className={currencyInputStyles.selectCurrency}
															>
																<ThemeProvider theme={currencySelectBox}>
																	<ReferenceInput
																		source='currency_id'
																		reference='master_currencies'
																		label=''
																	>
																		<SelectInput
																			optionText='currency_type'
																			disabled={true}
																		/>
																	</ReferenceInput>
																</ThemeProvider>
															</Box>
															<TextInput
																source='emp_cost'
																fullWidth={true}
																label={''}
																validate={[number()]}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box>
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Work Shift
														</Typography>
														<Field name='org_shift_id'>
															{() => (
																<ThemeProvider theme={autoCompleteTheme}>
																	<div
																		className={
																			autocompleteSearchStyles.customFieldContainer
																		}
																	>
																		<Autocomplete
																			onChange={handleShiftDropdown}
																			classes={{
																				option:
																					autocompleteSearchStyles.options,
																				groupLabel:
																					autocompleteSearchStyles.groupLabel,
																				inputRoot:
																					autocompleteSearchStyles.inputRootWrap,
																				noOptions:
																					autocompleteSearchStyles.noOptions,
																				focused:
																					autocompleteSearchStyles.focused,
																				input: autocompleteSearchStyles.input,
																				endAdornment:
																					autocompleteSearchStyles.endAdornment,
																			}}
																			options={OrgData?.org_shift || []}
																			value={getOrgShift(orgShiftId || '')}
																			renderOption={(option: OrgShift) => (
																				<Tooltip
																					title={`${option?.name}`}
																					placement='right'
																				>
																					<Typography
																						className={`${ellipsis.ellipsis}`}
																					>
																						{`${option?.name}`}
																					</Typography>
																				</Tooltip>
																			)}
																			getOptionLabel={(option: OrgShift) =>
																				option?.name
																			}
																			getOptionSelected={(option, value) =>
																				option.id === value.id
																			}
																			renderInput={(params) => (
																				<TextField
																					{...params}
																					label=''
																					placeholder='Search Work Shift'
																					InputProps={{
																						...params.InputProps,
																						startAdornment: (
																							<InputAdornment
																								position='start'
																								classes={{
																									root: autocompleteSearchStyles.inputAdronment,
																									positionStart:
																										autocompleteSearchStyles.inputAdronmentPositionStart,
																								}}
																							>
																								<SearchIcon
																									className={
																										autocompleteSearchStyles.searchIconPositionStart
																									}
																								/>
																							</InputAdornment>
																						),
																					}}
																				/>
																			)}
																		/>
																	</div>
																</ThemeProvider>
															)}
														</Field>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Leave Policy
														</Typography>

														<Field name='leave_group_id'>
															{() => (
																<ThemeProvider theme={autoCompleteTheme}>
																	<div
																		className={
																			autocompleteSearchStyles.customFieldContainer
																		}
																	>
																		<Autocomplete
																			onChange={handlePolicyDropdown}
																			classes={{
																				option:
																					autocompleteSearchStyles.options,
																				groupLabel:
																					autocompleteSearchStyles.groupLabel,
																				inputRoot:
																					autocompleteSearchStyles.inputRootWrap,
																				noOptions:
																					autocompleteSearchStyles.noOptions,
																				focused:
																					autocompleteSearchStyles.focused,
																				input: autocompleteSearchStyles.input,
																				endAdornment:
																					autocompleteSearchStyles.endAdornment,
																			}}
																			options={OrgData?.org_leave_group || []}
																			value={getLeavePolicy(leavePolicyId)}
																			renderOption={(option: LeavePolicy) => (
																				<Tooltip
																					title={`${option?.label}`}
																					placement='right'
																				>
																					<Typography
																						className={`${ellipsis.ellipsis}`}
																					>
																						{`${option?.label}`}
																					</Typography>
																				</Tooltip>
																			)}
																			getOptionLabel={(option: LeavePolicy) =>
																				option?.label
																			}
																			getOptionSelected={(option, value) =>
																				option.id === value.id
																			}
																			renderInput={(params) => (
																				<TextField
																					{...params}
																					label=''
																					placeholder='Search Leave Policy'
																					InputProps={{
																						...params.InputProps,
																						startAdornment: (
																							<InputAdornment
																								position='start'
																								classes={{
																									root: autocompleteSearchStyles.inputAdronment,
																									positionStart:
																										autocompleteSearchStyles.inputAdronmentPositionStart,
																								}}
																							>
																								<SearchIcon
																									className={
																										autocompleteSearchStyles.searchIconPositionStart
																									}
																								/>
																							</InputAdornment>
																						),
																					}}
																				/>
																			)}
																		/>
																	</div>
																</ThemeProvider>
															)}
														</Field>
													</Box>
												</Box>
												{customFields?.length > 0 &&
													customFields?.map((customField, index) => (
														<Box key={index}>
															<CustomField
																name={`custom_field_${customField.index}`}
																label={customField.label}
																option={customField.options}
																dateFormat={dateFormat}
																fieldType={customField.type}
																onChange={() => handleChange}
																value={optionValue!}
															/>
														</Box>
													))}
											</Box>
											{employee?.employeeStatus === 'Probation' && (
												<Box className={classes.multipleInputContainer}>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Probation Start Date
														</Typography>
														<DateInput
															source='probation_start_date'
															label={''}
															fullWidth={true}
														/>
													</Box>
													<Box className={classes.multipleInput}>
														<Typography className={classes.label}>
															Probation End Date
														</Typography>
														<DateInput
															source='probation_end_date'
															label={''}
															fullWidth={true}
														/>
													</Box>
												</Box>
											)}
										</DialogContent>
										<DialogActions>
											<Box
												className={classes.floatingButtonContainer}
												margin={'0 12px 12px 0'}
											>
												<Button
													onClick={handleClose}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
												<Button
													className={
														invalid || pristine || isLoading
															? `${classes.disabledButton}`
															: `${classes.saveButton}`
													}
													disabled={invalid || pristine || isLoading}
													type='submit'
													startIcon={
														isLoading && (
															<CircularProgress
																style={{
																	height: '20px',
																	width: '20px',
																	color: '#4285F4',
																}}
															/>
														)
													}
												>
													Save
												</Button>
											</Box>
										</DialogActions>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			<WarningModal
				open={isLeavePolicyWarningShown}
				onClose={() => {
					setIsLeavePolicyWarningShown(false);
				}}
				warningMessage={`Saving this will reset current leave information.`}
			/>
		</Box>
	);
};

export default EditEmployeeDetails;
