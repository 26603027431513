import gql from 'graphql-tag';
export const GET_OWNERS = gql`
	query getCompanyOwners($limit: Int, $name: String) {
		user(
			where: {
				ownedCompanies: { id: { _is_null: false } }
				full_name: { _ilike: $name }
			}
			order_by: { full_name: asc }
			limit: $limit
		) {
			first_name
			full_name
			id
			last_name
		}
		user_aggregate(
			where: {
				ownedCompanies: { id: { _is_null: false } }
				full_name: { _ilike: $name }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_OWNERS_COUNT = gql`
	query getOwnersCount {
		user_aggregate(where: { ownedCompanies: { id: { _is_null: false } } }) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_COMPANY_NOTES = gql`
	query getCompanyNotes($company_id: uuid!, $SearchText: String!) {
		crm_company_note(
			where: {
				company_id: { _eq: $company_id }
				_or: [
					{ title: { _ilike: $SearchText } }
					{ note: { _ilike: $SearchText } }
				]
			}
		) {
			id
			note
			title
			created_at
			company_id
			user {
				first_name
				last_name
				full_name
				profile_pic
			}
		}
	}
`;

export const UPSERT_COMPANY_NOTE = gql`
	mutation upsertCompanyNote($companyNote: crm_company_note_insert_input!) {
		insert_crm_company_note_one(
			object: $companyNote
			on_conflict: {
				constraint: crm_company_note_pkey
				update_columns: [title, note]
			}
		) {
			id
		}
	}
`;

export const DELETE_COMPANY_NOTE = gql`
	mutation deleteCompanyOpportunityNote($noteId: uuid!) {
		update_crm_company_note(
			where: { id: { _eq: $noteId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_KANBAN_BOARD = gql`
	query getCrmPipelineBoard {
		kanban_board(
			where: {
				master_sub_module: { master_module: { value: { _eq: "crm" } } }
				is_active: { _eq: true }
			}
			order_by: { name: asc }
		) {
			name
			id
		}
	}
`;

export const GET_COMPANY_OPPORTUNITY_ID = gql`
	query getCompanyopportunityId($boardId: [uuid!], $companyId: uuid!) {
		opportunities(
			where: {
				kanbanCard: { kanban_column: { board_id: { _in: $boardId } } }
				company_id: { _eq: $companyId }
			}
		) {
			id
		}
	}
`;

export const GET_COMPANY_PROJECTLIST = gql`
	query getCompanyProjectList($companyId: uuid!, $name: String) {
		project_list(
			where: { company_id: { _eq: $companyId }, name: { _ilike: $name } }
			order_by: { name: asc }
		) {
			id
			name
			start_date
			end_date
			ProjectOwner {
				full_name
			}
			ProjectStatus {
				label
			}
		}
	}
`;
