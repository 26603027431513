import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { TimesheetStyles } from './TimesheetStyles';
import AddIcon from '@material-ui/icons/Add';
import { modalFormStyle } from '../../Layout/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {
	DEFAULT_EMPTY_TIME_SHEET_TEXT,
	DEFAULT_TIME_SHEET_COPY_TEXT,
} from './constant';

export const TimesheetEmptyScreen = ({
	text = DEFAULT_EMPTY_TIME_SHEET_TEXT,
	copyText = DEFAULT_TIME_SHEET_COPY_TEXT,
	onAddEntry,
	onCopyFromPreviousEntry,
	disabled = false,
}: {
	text?: string;
	copyText?: string;
	onAddEntry: () => void;
	onCopyFromPreviousEntry: () => void;
	disabled: boolean;
}) => {
	const timesheetStyles = TimesheetStyles();
	const modalFormStyles = modalFormStyle();

	return (
		<Box className={timesheetStyles.emptyScreenContainer}>
			<Typography className={timesheetStyles.emptyScreenText}>
				{text}
			</Typography>
			<Box mt={2} mb={5}>
				<Button
					className={modalFormStyles.saveButton}
					variant='contained'
					startIcon={<AddIcon />}
					onClick={onAddEntry}
					disabled={disabled}
				>
					Add New Entry
				</Button>
			</Box>
			<Box className={timesheetStyles.copyTextContainer}>
				{!disabled && (
					<>
						<Box mr={1}>
							<FileCopyOutlinedIcon />
						</Box>
						<Typography
							className={timesheetStyles.copyText}
							onClick={onCopyFromPreviousEntry}
						>
							{copyText}
						</Typography>
					</>
				)}
			</Box>
		</Box>
	);
};
