import React, { useState, useEffect, useContext } from 'react';
import { List, Datagrid, FunctionField } from 'react-admin';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	makeStyles,
	Box,
	TextField,
	Button,
	IconButton,
	Tooltip,
	Typography,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { GET_CONTACT_BY_ID } from '../gqlQueries';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import CompanyAddContactForm from './CompanyAddContactModal.component';
import CompanyEditContactForm from './CompanyEditContactForm.component';
import { listTheme } from './listTheme';
import { ellipsisStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';

const useStyles = makeStyles(() => ({
	addContactButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			background: '#4285F4',
		},
	},

	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	linkedIcon: {
		color: '#555555',
		width: '15px',
		height: '15px',
		cursor: 'pointer',
		'&:hover': {
			color: '#3B5998',
		},
	},
	row: {
		width: '80px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	email: {
		width: '120px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	name: {
		width: '114px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	social: {
		width: '40px',
	},
	tableList: {
		height: '460px',
		overflowY: 'scroll',
	},
}));

export const searchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '344px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				paddingTop: '6px !important',
				height: '16px',
			},
		},

		// Search Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '8px',
				marginRight: '8px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

export const CompanyContactTab = (props: any) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const [searchedContact, setSearchedContact] = useState('');
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [contact, setContact] = useState<any>(null);
	const { id }: { id: string } = useParams();
	const [contactId, setContactId] = useState('');
	const styles = ellipsisStyle();
	const classes = useStyles();
	const { data: contactDetails } = useApolloQuery(GET_CONTACT_BY_ID, {
		variables: {
			contactId: contactId ? contactId : null,
		},
	});
	useEffect(() => {
		if (contactDetails) {
			setContact({
				id: contactDetails?.crm_contacts_by_pk?.id,
				company_id: contactDetails?.crm_contacts_by_pk?.company_id,
				first_name: contactDetails?.crm_contacts_by_pk?.first_name,
				last_name: contactDetails?.crm_contacts_by_pk?.last_name,
				title: contactDetails?.crm_contacts_by_pk?.title,
				email: contactDetails?.crm_contacts_by_pk?.email,
				primary_phone: contactDetails?.crm_contacts_by_pk?.primary_phone,
				alternate_phone: contactDetails?.crm_contacts_by_pk?.alternate_phone,
				background: contactDetails?.crm_contacts_by_pk?.background,
				linkedin: contactDetails?.crm_contacts_by_pk?.linkedin,
				address: contactDetails?.crm_contacts_by_pk?.address,
				contact_type: contactDetails?.crm_contacts_by_pk?.contact_type,
				owner_id: contactDetails?.crm_contacts_by_pk?.owner_id,
			});
		}
	}, [contactDetails]);
	const handleEditContact = (contactId: string) => {
		setContactId(contactId);
		setEditOpen(true);
	};
	return (
		<div>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<form onSubmit={(event) => event.preventDefault()}>
					<ThemeProvider theme={searchBarTheme}>
						<TextField
							placeholder='Search contact'
							label={false}
							fullWidth
							InputProps={{
								startAdornment: <SearchOutlinedIcon />,
							}}
							onChange={(e) => setSearchedContact(e?.target?.value)}
							value={searchedContact}
						/>
					</ThemeProvider>
				</form>
				<Box>
					{permissions?.crm_contacts?.insert_permissions && (
						<Button
							className={classes.addContactButton}
							startIcon={<AddIcon />}
							onClick={() => setAddOpen(true)}
						>
							Add Contact
						</Button>
					)}
				</Box>
			</Box>
			<Box mt={2} className={classes.tableList}>
				<ThemeProvider theme={listTheme}>
					<List
						{...props}
						bulkActionButtons={false}
						resource={'crm_contacts'}
						basePath='/crm_company'
						filter={{ company_id: id, full_name: searchedContact }}
						actions={false}
						component='div'
						pagination={false}
						title={' '}
					>
						<Datagrid>
							<FunctionField
								label='NAME'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.first_name || ''} ${
												record?.last_name || ''
											}`}
											placement='right'
										>
											<Typography
												className={[classes.name, styles.ellipsis].join(' ')}
											>
												{`${record?.first_name || ''} ${
													record?.last_name || ''
												}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='TITLE'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.title || '- -'}`}
											placement='right'
										>
											<Typography
												className={[classes.row, styles.ellipsis].join(' ')}
											>
												{`${record?.title || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='EMAIL'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.email || '- -'}`}
											placement='right'
										>
											<Typography
												className={[classes.email, styles.ellipsis].join(' ')}
											>
												{`${record?.email || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='PHONE'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.primary_phone || '- -'}`}
											placement='right'
										>
											<Typography
												className={[classes.row, styles.ellipsis].join(' ')}
											>
												{`${record?.primary_phone || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='TYPE'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.contact_type || '- -'}`}
											placement='right'
										>
											<Typography
												className={[classes.row, styles.ellipsis].join(' ')}
											>
												{`${record?.contact_type || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							{permissions?.crm_contacts?.update_permissions && (
								<FunctionField
									render={(data: any) => (
										<Box className={classes.listEditIconContainer}>
											<IconButton
												className={classes.listEditIconButton}
												onClick={() => {
													handleEditContact(data.id);
												}}
											>
												<EditIcon className={classes.editIcon} />
											</IconButton>
										</Box>
									)}
								/>
							)}
						</Datagrid>
					</List>
				</ThemeProvider>
			</Box>
			<CompanyAddContactForm
				onClose={() => setAddOpen(false)}
				isOpen={addOpen}
			/>
			<CompanyEditContactForm
				onClose={() => setEditOpen(false)}
				isOpen={editOpen}
				record={contact}
			/>
		</div>
	);
};

export default CompanyContactTab;
