import laptop from '../../assets/icons/asset-laptop.svg';
import accessPoint from '../../assets/icons/asset-access-point.svg';
import mobile from '../../assets/icons/asset-cellphone.svg';
import desktop from '../../assets/icons/asset-desktop.svg';
import dongle from '../../assets/icons/asset-dongle.svg';
import firewall from '../../assets/icons/asset-firewall.svg';
import gateway from '../../assets/icons/asset-gateway.svg';
import iPhone from '../../assets/icons/asset-iphone.svg';
import monitor from '../../assets/icons/asset-monitor.svg';
import mouse from '../../assets/icons/asset-mouse.svg';
import printer from '../../assets/icons/asset-printer.svg';
import router from '../../assets/icons/asset-router.svg';
import server from '../../assets/icons/asset-server.svg';
import tablet from '../../assets/icons/asset-tablet.svg';
import tv from '../../assets/icons/asset-tv.svg';
import phone from '../../assets/icons/asset-phone.svg';
import others from '../../assets/icons/asset-others.svg';
import iPad from '../../assets/icons/asset-ipad.svg';
import camera from '../../assets/icons/asset-camera.svg';

export const assetFilters = [
	{ id: 'All Assets', name: 'All Assets' },
	{ id: 'In Use', name: 'In Use' },
	{ id: 'In Stock', name: 'In Stock' },
	{ id: 'In Repair', name: 'In Repair' },
];

// function used for getting the icon based on the type
// Todo => Need the icons to be fetched from backend dynamically
export const getImage = (type: string) => {
	if (type !== null) {
		switch (type) {
			case 'Desktop': {
				return desktop;
			}
			case 'Firewall': {
				return firewall;
			}
			case 'Gateway': {
				return gateway;
			}
			case 'iPhone': {
				return iPhone;
			}
			case 'Laptop': {
				return laptop;
			}
			case 'Router': {
				return router;
			}
			case 'Server': {
				return server;
			}
			case 'Tablet': {
				return tablet;
			}
			case 'Tv': {
				return tv;
			}
			case 'Mouse': {
				return mouse;
			}
			case 'Phone': {
				return phone;
			}
			case 'Printer': {
				return printer;
			}
			case 'Mobile': {
				return mobile;
			}
			case 'Dongle': {
				return dongle;
			}
			case 'Access_Point': {
				return accessPoint;
			}
			case 'Monitor': {
				return monitor;
			}
			case 'Other': {
				return others;
			}
			case 'Camera': {
				return camera;
			}
			case 'iPad': {
				return iPad;
			}
			default: {
				return '';
			}
		}
	}
};

export const breadcumData = [{ name: 'Asset', path: '/asset' }];

export const EXPORT_ASSET_COLUMNS: string[] = [
	'ASSET ID',
	'NAME',
	'TYPE',
	'USED BY',
	'MANAGED BY',
	'LOCATION',
	'WARRANTY EXPIRATION DATE',
	'MANUFACTURER',
	'PROCESSOR',
	'MEMORY',
	'HARD DRIVE',
	'MAC ADDRESS',
	'PRODUCT NUMBER',
	'IN REPAIR',
	'COST',
	'SOFTWARES',
	'NOTES'
];
