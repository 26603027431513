import React, {
	createContext,
	Reducer,
	useReducer,
	useState,
	useEffect,
	useContext,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-admin';
import { Box, makeStyles, Button } from '@material-ui/core';
import './projectKanban.css';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { globalStyles } from '../../Layout/styles';
import ProjectKanbanContainer from './ProjectKanbanContainer.component';
import Breadcrum from '../../SharedComponents/Breadcrum.component';
import { breadcumData } from './Constant';
import ProjectMilestoneKanban from './ProjectMilestoneKanban.componet';
import {
	useGetKanbanBoardByProjectIdQuery,
	useGetProjectAssignedUserQuery,
	useGetProjectMileStoneListQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { Team } from './ProjectKanban.model';
import { isEmpty, orderBy } from 'lodash';
export const ProjectTaskContext = createContext<any>(null);

const useStyles = makeStyles((theme) => ({
	navigationTabContainer: {
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		fontSize: '12px',
		width: '300px',
		textTransform: 'none',
		borderRadius: '0px',
		letterSpacing: '0px',
		borderRight: '1px solid #E0E0E0',
		background: '#ECF1F4',
		'&:first-child': {
			borderRight: '1px solid #E0E0E0',
			borderRadius: '4px 0px 0px 4px !important',
		},
		'&:last-child': {
			borderRadius: '0px 4px 4px 0px',
		},
	},
}));

const ACTIONS = {
	UPDATE_PROJECT_TASK_SEARCH_TEXT: 'update_project_task_search_text',
	UPDATE_PROJECT_TASK_ASSIGNEE_FILTER: 'update_project_task_assignee_filter',
	UPDATE_PROJECT_TASK_MILESTONE_FILTER: 'update_project_task_milestone_filter',
	UPDATE_PROJECT_TASK_CATEGORY_FILTER: 'update_project_task_category_filter',
	UPDATE_PROJECT_MILESTONE_SEARCH_TEXT: 'update_project_milestone_search_text',
	UPDATE_PROJECT_MILESTONE_TASK_FILTER: 'update_project_milestone_task_filter',
};

type Action = {
	type: string;
	payload: any;
};

interface OptionProps {
	label: string;
	value: string;
}

const filtersIntialState = {
	projectTaskSearchText: '',
	projectTaskAssigneeFilter: [],
	projectTaskMilestoneFilter: [],
	projectTaskCategoryFilter: [],
	projectMilestoneSearchText: '',
	projectMilestoneTaskFilter: [],
};

const reducer: Reducer<any, Action> = (
	state: {
		projectTaskSearchText: string;
		projectTaskAssigneeFilter: OptionProps[];
		projectTaskMilestoneFilter: OptionProps[];
		projectTaskCategoryFilter: OptionProps[];
		projectMilestoneSearchText: string;
		projectMilestoneTaskFilter: OptionProps[];
	},
	// TODO need to give correct type
	action: any
) => {
	switch (action.type) {
		case ACTIONS.UPDATE_PROJECT_TASK_SEARCH_TEXT: {
			return { ...state, projectTaskSearchText: action.payload };
		}
		case ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER: {
			return { ...state, projectTaskAssigneeFilter: action.payload };
		}

		case ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER: {
			return { ...state, projectTaskMilestoneFilter: action.payload };
		}

		case ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER: {
			return { ...state, projectTaskCategoryFilter: action.payload };
		}

		case ACTIONS.UPDATE_PROJECT_MILESTONE_SEARCH_TEXT: {
			return { ...state, projectMilestoneSearchText: action.payload };
		}

		case ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER: {
			return { ...state, projectMilestoneTaskFilter: action.payload };
		}

		default:
			return state;
	}
};

const ProjectTaskMilestoneContainer = () => {
	const { id }: { id: string } = useParams();
	const { id: userId } = useContext<any>(UserProfileContext);
	const history = useHistory<any>();
	const [isTaskShown, setIsTaskShown] = useState(true);
	const [isMilestoneShown, setIsMilestoneShown] = useState(false);
	const [projectTeamMembers, setProjectTeamMembers] = useState<Team[]>([]);
	const [selectedStatusIdList, setSelectedStatusIdList] = useState<Team[]>([]);
	const [projectMilestoneFilter, setProjectMilestoneFilter] = useState<Team[]>(
		[]
	);
	const [projecTaskFiltersState, dispatchProjectTaskFilters] = useReducer(
		reducer,
		filtersIntialState
	);
	const classes = useStyles();
	const commonStyles = globalStyles();

	const { data: project } = useQuery({
		type: 'getOne',
		resource: 'project',
		payload: { id: id },
	});

	const { data: kanbanBoard } = useGetKanbanBoardByProjectIdQuery({
		variables: {
			id: id,
		},
		fetchPolicy: 'network-only',
	});

	const { data: projectMembers } = useGetProjectAssignedUserQuery({
		variables: { project_id: id },
		fetchPolicy: 'network-only',
	});

	const { data: projectMilestoneList } = useGetProjectMileStoneListQuery({
		variables: {
			projectId: id,
		},
	});

	useEffect(() => {
		if (
			isEmpty(projectMilestoneList?.project_milestone) ||
			!projectMilestoneList
		) {
			return;
		}
		const milestoneInfo = projectMilestoneList?.project_milestone?.map(
			(value) => {
				return {
					value: value?.id,
					label: value?.name,
				};
			}
		);
		if (milestoneInfo) {
			setProjectMilestoneFilter([
				...milestoneInfo,
				{ label: 'UnAssigned', value: 'UnAssigned' },
			]);
			if (isEmpty(history?.location?.state?.projectTaskMilestoneFilter)) {
				dispatchProjectTaskFilters({
					type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
					payload: [
						...milestoneInfo,
						{ label: 'UnAssigned', value: 'UnAssigned' },
					],
				});
				return;
			}
		}
	}, [projectMilestoneList, history]);

	useEffect(() => {
		if (isEmpty(history?.location?.state)) {
			return;
		}
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_TASK_SEARCH_TEXT,
			payload: history?.location?.state?.projectTaskSearchText || '',
		});
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER,
			payload: history?.location?.state?.taskCategory || [],
		});
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
			payload: history?.location?.state?.projectMilestoneId || [],
		});
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
			payload: history?.location?.state?.assigned_to || [],
		});
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_MILESTONE_SEARCH_TEXT,
			payload: history?.location?.state?.projectMilestoneSearchText || '',
		});
		dispatchProjectTaskFilters({
			type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
			payload: history?.location?.state?.projectMilestoneTaskFilter || [],
		});
	}, [history, dispatchProjectTaskFilters]);

	useEffect(() => {
		if (!projectMembers) {
			return;
		}
		const teamMemberList = projectMembers?.project_resource_mapping?.map(
			(resource) => {
				if (resource?.user?.id === userId) {
					!history?.location?.state &&
						dispatchProjectTaskFilters({
							type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
							payload: [{ value: resource?.user?.id, label: 'Me' }],
						});
					return {
						value: userId,
						label: 'Me',
					};
				} else {
					return {
						value: resource?.user?.id,
						label: `${resource?.user?.full_name}`,
					};
				}
			}
		);
		const sortedTeamMemberList = orderBy(teamMemberList, ['label'], ['asc']);
		setProjectTeamMembers(sortedTeamMemberList);
	}, [projectMembers, userId, history]);

	useEffect(() => {
		const initialStatusIdList =
			kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns?.map((value) => {
				return {
					value: value?.id,
					label: value?.name,
				};
			});
		if (!initialStatusIdList) {
			return;
		}
		setSelectedStatusIdList(initialStatusIdList);
		!history?.location?.state &&
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
				payload: initialStatusIdList,
			});
	}, [kanbanBoard, dispatchProjectTaskFilters, history]);

	return (
		<>
			<Breadcrum
				data={[...breadcumData, { name: project?.name }]}
				icon={DoubleArrowIcon}
			/>
			<Box className={classes.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						isTaskShown ? commonStyles.activeNavigationTabButton : '',
					].join(' ')}
					onClick={() => {
						setIsMilestoneShown(false);
						setIsTaskShown(true);
					}}
				>
					Tasks
				</Button>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						isMilestoneShown ? commonStyles.activeNavigationTabButton : '',
					].join(' ')}
					onClick={() => {
						setIsMilestoneShown(true);
						setIsTaskShown(false);
					}}
				>
					Milestone
				</Button>
			</Box>
			<ProjectTaskContext.Provider
				value={{ projecTaskFiltersState, dispatchProjectTaskFilters, ACTIONS }}
			>
				{isTaskShown && (
					<ProjectKanbanContainer
						project={project}
						projectTeamMembers={projectTeamMembers}
						projectMilestoneFilter={projectMilestoneFilter}
					/>
				)}
				{isMilestoneShown && (
					<ProjectMilestoneKanban
						projectId={id}
						option={selectedStatusIdList}
					/>
				)}
			</ProjectTaskContext.Provider>
		</>
	);
};

export default ProjectTaskMilestoneContainer;
