import React, { useEffect, useState } from 'react';
import {
	TextInput,
	required,
	SelectInput,
	ReferenceInput,
	useNotify,
	useRefresh,
	BooleanInput,
} from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle, currencyInputStyle } from '../../../Layout/styles';
import { settingsStyle } from '../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import {
	ADD_ORGANISATION_SHIFT,
	UPDATE_ORGANISATION_SHIFT,
} from '../Settings.gql';

import { ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Dialog,
	Typography,
	createTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningModal from '../../../SharedComponents/WarningModal.component';

export const currencySelectBox = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				width: '80px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#5C5C5C',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});
interface OrganisationShift {
	allowance?: number;
	currency_id?: any;
	end_time?: string;
	id?: string;
	name?: string;
	start_time?: string;
	is_active?: boolean;
	is_default?: boolean;
}

interface OrganisationShiftFormProps {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	isEditMutation: boolean;
	initialValues?: OrganisationShift;
	baseCurrency?: string;
}
const OrganisationShiftForm = (props: OrganisationShiftFormProps) => {
	const {
		open,
		onClose,
		onSuccess,
		isEditMutation,
		initialValues,
		baseCurrency,
	} = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const currencyInputStyles = currencyInputStyle();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isWarningMessageShown, setIsWarningMessageShown] = useState(false);
	const notify = useNotify();
	const refresh = useRefresh();
	const organisationShiftFormInitialValues = {
		is_active: true,
		currency_id: baseCurrency,
	};

	const [addOrganisationShift, { data: addedOrganisationShit,loading:isAddShiftLoading }] =
		useApolloMutation(ADD_ORGANISATION_SHIFT);

	const [updateOrganisationShift, { data: updatedOrganisationShit,loading:isEditShiftLoading }] =
		useApolloMutation(UPDATE_ORGANISATION_SHIFT);

	useEffect(() => {
		if (!addedOrganisationShit) {
			return;
		}
		notify('Shift Created Successfully');
		refresh();
	}, [addedOrganisationShit, notify, refresh]);

	useEffect(() => {
		if (!updatedOrganisationShit) {
			return;
		}
		notify('Shift Updated Successfully');
		refresh();
	}, [updatedOrganisationShit, notify, refresh]);

	useEffect(() => {
		if (addedOrganisationShit || updatedOrganisationShit) {
			onSuccess();
		}
	}, [addedOrganisationShit, updatedOrganisationShit, onSuccess]);

	const onHandleSubmit = (organisationShift: OrganisationShift) => {
		if (!organisationShift) {
			return;
		}
		if (
			!initialValues?.is_default &&
			organisationShift?.is_default &&
			!isWarningMessageShown
		) {
			setIsWarningModalShown(true);
			return;
		}
		const { id, ...organisationShiftPayload } = organisationShift;
		if (!isEditMutation) {
			addOrganisationShift({
				variables: {
					data: {
						...organisationShiftPayload,
					},
				},
			});
			onClose();
		} else {
			updateOrganisationShift({
				variables: {
					organisationShiftId: initialValues?.id,
					data: {
						...organisationShiftPayload,
					},
				},
			});
			setIsWarningMessageShown(false);
			onClose();
		}
	};

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={settingsStyles.container}>
						<Box className={modalFormStyles.headerContainer}>
							<Typography className={modalFormStyles.heading}>
								{isEditMutation ? `${initialValues?.name}` : `Add Shift`}
							</Typography>
							<CloseIcon
								className={modalFormStyles.closeIcon}
								onClick={onClose}
							/>
						</Box>
						<Form
							initialValues={
								!isEditMutation
									? organisationShiftFormInitialValues
									: initialValues
							}
							onSubmit={onHandleSubmit}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={modalFormStyles.formContainer}>
										<Typography className={modalFormStyles.label}>
											Name*
										</Typography>
										<TextInput source='name' label='' validate={required()} />

										<Box className={modalFormStyles.multipleInputContainer}>
											<Box className={modalFormStyles.multipleInput}>
												<Typography className={modalFormStyles.label}>
													StartTime*
												</Typography>
												<TextInput
													source='start_time'
													label=''
													type='time'
													defaultValue='09:30'
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Box>
											<Box className={modalFormStyles.multipleInput}>
												<Typography className={modalFormStyles.label}>
													EndTime*
												</Typography>
												<TextInput
													source='end_time'
													label=''
													type='time'
													defaultValue='06:30'
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Box>
										</Box>
										<Typography className={modalFormStyles.label}>
											Allowance
										</Typography>
										<Box display='flex'>
											<Box className={currencyInputStyles.selectCurrency}>
												<ThemeProvider theme={currencySelectBox}>
													<ReferenceInput
														source='currency_id'
														reference='master_currencies'
														label=''
													>
														<SelectInput
															optionText='currency_type'
															disabled={true}
														/>
													</ReferenceInput>
												</ThemeProvider>
											</Box>
											<TextInput source='allowance' label={''} />
										</Box>

										<Box display='flex' justifyContent='flex-start'>
											<Box width='49%'>
												<Typography className={modalFormStyles.label}>
													Is Active
												</Typography>
												<BooleanInput label='' source='is_active' />
											</Box>
											<Box>
												{isEditMutation && (
													<>
														<Typography className={modalFormStyles.label}>
															Mark this shift as default
														</Typography>
														<BooleanInput
															label=''
															source='is_default'
															disabled={
																initialValues && initialValues?.is_default
															}
														/>
													</>
												)}
											</Box>
										</Box>
									</Box>
									<Box
										className={modalFormStyles.buttonContainer}
										style={{ padding: 0 }}
									>
										<Button
											type='submit'
											variant='contained'
											disabled={invalid || pristine || isAddShiftLoading || isEditShiftLoading}
											className={
												invalid || pristine || isAddShiftLoading || isEditShiftLoading
													? modalFormStyles.disabledButton
													: modalFormStyles.saveButton
											}
										>
											{isEditMutation ? 'Update' : 'Add'}
										</Button>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningMessageShown(true);
					setIsWarningModalShown(false);
				}}
				warningMessage={`This action will make "${
					initialValues?.name || ''
				}" as default shift.`}
			/>
		</>
	);
};

export default OrganisationShiftForm;
