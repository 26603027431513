import React from 'react';
import { Box, TextField, ThemeProvider } from '@material-ui/core';
import { SearchIcon } from '../../../assets/SvgIcons';
import { searchBarTheme } from '../../../Layout/Theme.component';

interface SearchBarProps {
	searchText: string;
	setSearchedText: (value: any) => void;
	placeHolder?: string;
	size?: string;
}

export const PMSSearchBar = (props: SearchBarProps) => {
	const { searchText, setSearchedText, placeHolder, size } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width={size === 'sm' ? '200px' : '344px'}>
				<TextField
					placeholder={placeHolder || 'Search'}
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};
