import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import ProjectSkillForm from './ProjectSkillForm.component';
import ProjectSkillDelete from './ProjectSkillDelete.component';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import { GET_PROJECT_SKILL_BY_PK } from '../gqlQueries';

const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
}));

interface Props {
	projectSkillId: string;
	refetch: () => void;
}

export const ProjectSkillEditPopOver = (props: Props) => {
	const { projectSkillId, refetch } = props;
	const [isProjectSkillModalShown, setIsProjectSkillModalShown] =
		useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [projectSkillRequirement, setProjectSkillRequirement] = useState({});
	const [isProjectSkillDeleteModalShown, setIsProjectSkillDeleteModalShown] =
		useState(false);
	const { data: projectSkill } = ApolloUseQuery(GET_PROJECT_SKILL_BY_PK, {
		variables: {
			projectSkillId: projectSkillId ? projectSkillId : null,
		},
	});

	useEffect(() => {
		if (projectSkill?.project_skill_requirement_by_pk) {
			setProjectSkillRequirement({
				skill_id: projectSkill?.project_skill_requirement_by_pk?.skill_id,
				skill_level_id:
					projectSkill?.project_skill_requirement_by_pk?.skill_level_id,
				count: projectSkill?.project_skill_requirement_by_pk?.count,
				id: projectSkill?.project_skill_requirement_by_pk?.id,
				notes: projectSkill?.project_skill_requirement_by_pk?.notes,
				request_status_id:
					projectSkill?.project_skill_requirement_by_pk?.request_status_id,
			});
		}
	}, [projectSkill]);

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
		setIsProjectSkillModalShown(false);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem onClick={() => setIsProjectSkillModalShown(true)}>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
				<MenuItem onClick={() => setIsProjectSkillDeleteModalShown(true)}>
					<DeleteIcon className={classes.deleteIcon} />
					<Box className={classes.editText}>Delete</Box>
				</MenuItem>
			</Menu>
			<ProjectSkillForm
				open={isProjectSkillModalShown}
				onClose={closePopOver}
				projectSkill={projectSkillRequirement}
				refetch={() => refetch()}
			/>
			<ProjectSkillDelete
				open={isProjectSkillDeleteModalShown}
				onClose={() => setIsProjectSkillDeleteModalShown(false)}
				projectSkillId={projectSkillId}
				refetch={refetch}
			/>
		</div>
	);
};

export default ProjectSkillEditPopOver;
