import React, { useState } from 'react';
import {
	TextInput,
	required,
	useNotify,
	AutocompleteArrayInput,
	maxLength,
} from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
	Box,
	Dialog,
	Typography,
	Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	useUpsertUserTeamMappingMutation,
	useUpsertUserTeamMutation,
} from '../../../../generated/graphql';
import {
	USER_TEAM_CREATION_MESSAGE,
	USER_TEAM_UPDATION_MESSAGE,
} from '../../constant';
import { difference, head } from 'lodash';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

interface FormValue {
	id?: string;
	name?: string;
	users?: any[] | undefined;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	initialValues?: FormValue;
	userOptions: { id: any; name: string | null | undefined }[];
	userTeamMappings?: {
		__typename?: string;
		id: any;
		user_id: any;
		team_id: any;
	}[];
}

const userTeamFormStyle = makeStyles({
	headingContainer: {
		whiteSpace: 'nowrap',
		width: '200px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
});

const UserTeamForm = (props: Props) => {
	const {
		open,
		onClose,
		refetch,
		initialValues,
		userOptions,
		userTeamMappings,
	} = props;
	const [isUserCountIsZero, setIsUserCountIsZero] = useState(false);
	const [formError, setFormError] = useState({
		isError: false,
		error: '',
		field: '',
	});
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const userTeamFormStyles = userTeamFormStyle();
	const [upsertUserTeam, { loading: isUserTeamLoading }] =
		useUpsertUserTeamMutation();

	const [upsertUserTeamMapping, { loading: isUserTeamMappingLoading }] =
		useUpsertUserTeamMappingMutation();

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		if (!formValues?.users || formValues?.users?.length === 0) {
			setIsUserCountIsZero(true);
			return;
		}

		if (formValues.name?.trim() === '') {
			setFormError({
				isError: true,
				error: 'Please Enter Name',
				field: 'name',
			});
			return;
		}

		const usersFromFormValues = formValues.users;
		const removedUsers = difference(initialValues?.users, usersFromFormValues);

		const removedUserTeamMappings = userTeamMappings?.filter(
			(userTeamMapping) => removedUsers.includes(userTeamMapping.user_id)
		);
		const userTeamList = formValues?.users?.filter((id) => !!id)
		upsertUserTeam({
			variables: {
				data: {
					id: formValues?.id,
					name: formValues?.name,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					if (!formValues?.users || formValues?.users.length === 0) {
						if (initialValues?.id) {
							refetch();
							notify(USER_TEAM_UPDATION_MESSAGE);
							closeModal();
						} else {
							refetch();
							notify(USER_TEAM_CREATION_MESSAGE);
							closeModal();
						}
						return;
					}

					// USER TEAM MAPPING
					upsertUserTeamMapping({
						variables: {
							deleteUserMappingIds:
								removedUserTeamMappings?.map(
									(removedUserTeamMapping) => removedUserTeamMapping.id
								) || [],
							userTeamMappings: userTeamList?.map((usr) => ({
								team_id: initialValues?.id
									? initialValues?.id
									: head(response.data?.insert_user_team?.returning)?.id,
								user_id: usr,
							})),
						},
					})
						.then((res) => {
							if (!res.errors) {
								if (initialValues?.id) {
									notify(USER_TEAM_UPDATION_MESSAGE);
									refetch();
									closeModal();
								} else {
									notify(USER_TEAM_CREATION_MESSAGE);
									refetch();
									closeModal();
								}
							}
						})
						.catch((err) => console.log(err));
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
			});
	};
	const closeModal = () => {
		setIsUserCountIsZero(false);
		setFormError({
			isError: false,
			error: '',
			field: '',
		});
		onClose();
	};

	const validateDepartmentFormValues = async () => {
		const departmentFormErrors = {} as any;

		if (formError.isError) {
			departmentFormErrors[formError.field] = formError.error;
		}

		return departmentFormErrors;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Tooltip
							title={initialValues?.id ? `${initialValues?.name}` : ''}
							placement={'top'}
						>
							<Typography
								className={`${modalFormStyles.heading} ${userTeamFormStyles.headingContainer}`}
							>
								{initialValues?.id
									? `Edit: ${initialValues?.name}`
									: `Add Team`}
							</Typography>
						</Tooltip>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialValues?.id ? initialValues : {}}
						onSubmit={onHandleSubmit}
						validate={validateDepartmentFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={[
											required(),
											maxLength(30, 'More than 30 characters are not allowed'),
										]}
										onChange={() => {
											setFormError({
												isError: false,
												error: '',
												field: '',
											});
										}}
									/>
									<Typography className={modalFormStyles.label}>
										Users*
									</Typography>
									<AutocompleteArrayInput
										source='users'
										label=''
										choices={userOptions}
										onInputValueChange={() => {
											setIsUserCountIsZero(false);
										}}
									/>
									{isUserCountIsZero && (
										<span
											style={{
												color: '#f44336',
												fontSize: '10px',
												margin: '-12px 0px 12px 12px',
											}}
										>
											Please add user
										</span>
									)}
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={initialValues?.id ? 'Update' : 'Add'}
										isDisabled={
											invalid ||
											pristine ||
											isUserTeamLoading ||
											isUserTeamMappingLoading
										}
										isLoading={isUserTeamLoading || isUserTeamMappingLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default UserTeamForm;
