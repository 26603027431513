import { Box, Divider } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useContext, useState } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, Responsive } from 'react-admin';
import { useSelector } from 'react-redux';
import { UserProfileContext } from '../App';
import { LogoutButton } from '../Auth/LogoutMenu';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HomeIcon from '@material-ui/icons/Home';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SubMenu from './SubMenu';
import {
	CrmCompanyIcon,
	EmployeesIcon,
	CrmContactIcon,
	AssetIcon,
	PlanningIcon,
	SkillMatrixIcon,
	OpportunitiesIcon,
	CrmTaskIcon,
	AttendanceIcon,
	TimeSheetMenuIcon,
	// OrgChartIcon,
	ProjectIcon,
	ProjectPlanningIcon,
	ResourceAvailabilityIcon,
} from '../assets/MenuIcons';

const theme = createTheme({
	overrides: {
		RaSidebar: {
			drawerPaper: {
				width: '210px !important',
			},
		},
		RaMenuItemLink: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				color: '#292929',
				marginLeft: '14px',
			},
			active: {
				color: '#4285F4',
				backgroundColor: '#E3EEFF',
				borderRadius: '4px',
				fontFamily: 'Manrope-extrabold',
			},
			icon: {
				minWidth: '26px',
			},
		},
		MuiList: {
			root: {
				padding: '0px !important',
			},
		},
		MuiDrawer: {
			docked: {
				'& a': {
					marginBottom: '5px !important',
					marginTop: '5px !important',
					fontSize: '12px !important',
					fontFamily: 'Manrope-medium',
				},
				'& div': {
					marginBottom: '5px !important',
					marginTop: '5px !important',
					fontSize: '12px !important',
					fontFamily: 'Manrope-medium',
				},
			},
		},
		MuiButtonBase: {
			root: {
				marginBottom: '5px !important',
				marginTop: '5px !important',
			},
		},
		MuiTypography: {
			// Submenu Header When menu CLOSED state
			colorTextSecondary: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				color: '#292929',
			},

			// Submenu Header When menu OPENED state
			displayBlock: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				color: '#292929',
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'unset',
			},
		},
		MuiSvgIcon: {
			root: {
				fontSize: '14px',
			},
		},
		MuiListItemText: {
			root: {
				marginTop: '2px',
			},
			multiline: {
				marginTop: '3px',
				marginBottom: '2px',
			},
		},
		MuiDivider: {
			root: {
				marginLeft: '30px',
			},
		},
	},
});
export const CustomNavMenu = ({ onMenuClick, logout }) => {
	const { permissions, featureFlag, setTabGlobalIndex } =
		useContext(UserProfileContext);
	const isMenuOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const [isResourcePlannerMenuOpen, setIsResourcePlannerMenuOpen] =
		useState(true);
	const [isPmsMenuOpen, setIsPmsMenuOpen] = useState(true);
	const handleResourcePlannerToggle = () => {
		setIsResourcePlannerMenuOpen(!isResourcePlannerMenuOpen);
	};
	const handlePmsToggle = () => {
		setIsPmsMenuOpen(!isPmsMenuOpen);
	};
	const resetTabGlobalIndexForMyInfo = () => {
		if (onMenuClick && typeof onMenuClick === 'function') {
			onMenuClick();
		}
		setTabGlobalIndex(0);
	};
	return (
		<ThemeProvider theme={theme}>
			<Menu
				style={{
					marginTop: '0px',
				}}
			>
				{featureFlag?.HomePage && (
					<MenuItemLink
						to={`/home`}
						primaryText={'Home'}
						leftIcon={<HomeIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				)}
				{permissions?.appPermissions?.ui?.viewDashboard && (
					<DashboardMenuItem onClick={onMenuClick} />
				)}
				{/* Org Chart in the menu is hidden for now */}
				{/* <MenuItemLink
					to={`/org-chart`}
					primaryText={'Org Chart'}
					leftIcon={<OrgChartIcon />}
					onClick={onMenuClick}
					sidebarIsOpen={isMenuOpen}
				/> */}
				<MenuItemLink
					to={`/my-info`}
					primaryText={'My Info'}
					leftIcon={<PersonIcon />}
					onClick={resetTabGlobalIndexForMyInfo}
					sidebarIsOpen={isMenuOpen}
				/>
				<Divider />
				{permissions?.appPermissions?.ui?.ViewEmployees ? (
					<MenuItemLink
						to={`/employee`}
						primaryText={'Employees'}
						leftIcon={<EmployeesIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{permissions?.appPermissions?.ui?.viewContractDetails ? (
					<MenuItemLink
						to={`/contractor`}
						primaryText={'Consultants'}
						leftIcon={<UserIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}

				{/* TODO We will move this menu under Home menu (After Home page implementation)  */}
				{/* <MenuItemLink
            to={`/directory`}
            primaryText={"Directory"}
            leftIcon={<ImportContactsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={isMenuOpen}
          /> */}

				{permissions?.attendance?.select_permissions ? (
					<MenuItemLink
						to={`/attendance`}
						primaryText={'Attendance'}
						leftIcon={<AttendanceIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}

				{/* TODO after DB changes uncomment this permission check */}
				{permissions?.timesheet?.select_permissions &&
					featureFlag?.TimesheetEntry && (
						<MenuItemLink
							to='/timesheet'
							primaryText='Timesheet'
							leftIcon={<TimeSheetMenuIcon />}
							onClick={onMenuClick}
							sidebarIsOpen={isMenuOpen}
						/>
					)}

				{permissions?.appPermissions?.ui?.viewEmployeeSkills ? (
					<MenuItemLink
						to='/employee_skill_mapping'
						primaryText='Skill Matrix'
						leftIcon={<SkillMatrixIcon />}
					/>
				) : null}
				<Divider />
				{permissions?.appPermissions?.ui?.viewCompanyDetails ? (
					<MenuItemLink
						to={`/crm_company`}
						primaryText={'Companies'}
						leftIcon={<CrmCompanyIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{permissions?.crm_contacts?.select_permissions ? (
					<MenuItemLink
						to={`/crm_contacts`}
						primaryText={'Contacts'}
						leftIcon={<CrmContactIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{permissions?.opportunities?.select_permissions ? (
					<MenuItemLink
						to={`/pipeline`}
						primaryText={'Pipeline'}
						leftIcon={<OpportunitiesIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{permissions?.crm_tasks?.select_permissions ? (
					<MenuItemLink
						to={`/crm_tasks`}
						primaryText={'Tasks'}
						leftIcon={<CrmTaskIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				<Divider />
				{permissions?.appPermissions?.ui?.viewProjectAllocation ? (
					<SubMenu
						handleToggle={handleResourcePlannerToggle}
						isOpen={isResourcePlannerMenuOpen}
						sidebarIsOpen={isMenuOpen}
						name='Planning'
						icon={<PlanningIcon />}
					>
						<MenuItemLink
							to='/resource-planner'
							primaryText='Project Planning'
							leftIcon={<ProjectPlanningIcon />}
							onClick={onMenuClick}
							sidebarIsOpen={isMenuOpen}
						/>
						{permissions?.appPermissions?.ui?.viewResourceAvailability && (
							<>
								<MenuItemLink
									to='/resource-availability'
									primaryText='Resource availability'
									leftIcon={<ResourceAvailabilityIcon />}
									onClick={onMenuClick}
									sidebarIsOpen={isMenuOpen}
								/>
							</>
						)}
						{permissions?.appPermissions?.ui?.viewProjectResourceRequest && (
							<>
								<MenuItemLink
									to='/resource-request'
									primaryText='Resource request'
									leftIcon={<GroupAddIcon />}
									onClick={onMenuClick}
									sidebarIsOpen={isMenuOpen}
								/>
							</>
						)}
					</SubMenu>
				) : null}
				{permissions?.appPermissions?.ui?.viewProjectDetails ? (
					<MenuItemLink
						to='/project'
						primaryText='Projects'
						leftIcon={<ProjectIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				<Divider />
				{permissions?.appPermissions?.ui?.viewAssetDetails ? (
					<MenuItemLink
						to='/asset'
						primaryText='Assets'
						leftIcon={<AssetIcon />}
						onClick={onMenuClick}
					/>
				) : null}
				<Divider />
				{permissions?.employee_engagement?.select_permissions ? (
					<MenuItemLink
						to='/employee-engagement'
						primaryText='Employee Engagement'
						leftIcon={<AccessibilityIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{permissions?.asset_management?.select_permissions ? (
					<MenuItemLink
						to='/asset-management'
						primaryText='Asset Management'
						leftIcon={<AssetIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}
				{featureFlag?.pms && permissions?.appPermissions?.ui?.viewPMSModule ? (
					<SubMenu
						handleToggle={handlePmsToggle}
						isOpen={isPmsMenuOpen}
						sidebarIsOpen={isMenuOpen}
						name='PMS'
						icon={<EqualizerIcon />}
					>
						<MenuItemLink
							to='/my-pms'
							primaryText='My PMS'
							leftIcon={<PersonIcon />}
							onClick={onMenuClick}
							sidebarIsOpen={isMenuOpen}
						/>
						<MenuItemLink
							to='/team-pms'
							primaryText='Team PMS'
							leftIcon={<UserIcon />}
							onClick={onMenuClick}
							sidebarIsOpen={isMenuOpen}
						/>
						{featureFlag?.pms &&
						permissions?.appPermissions?.ui?.viewPMSAdmin ? (
							<MenuItemLink
								to='/admin-pms'
								primaryText='Admin'
								leftIcon={<SettingsIcon />}
								onClick={onMenuClick}
								sidebarIsOpen={isMenuOpen}
							/>
						) : null}
					</SubMenu>
				) : null}

				{permissions?.master_settings?.select_permissions ? (
					<MenuItemLink
						to={`/settings`}
						primaryText={'Settings'}
						leftIcon={<SettingsIcon />}
						onClick={onMenuClick}
						sidebarIsOpen={isMenuOpen}
					/>
				) : null}

				<Responsive
					small={
						<Box display='flex'>
							<Box display='flex' justifyContent='center' m={2}>
								<LockIcon style={{ color: '#696969' }} />
							</Box>
							<Box display='flex' justifyContent='center'>
								<LogoutButton />
							</Box>
						</Box>
					}
					medium={null}
				/>
			</Menu>
		</ThemeProvider>
	);
};
