import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useRefresh, useNotify, useQuery } from 'react-admin';
import {
	GET_ASSET_NOTE,
	UPSERT_ASSET_NOTE,
	DELETE_ASSET_NOTE,
} from '../gqlQueries';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import Notes from '../../../SharedComponents/Notes/Notes';
import { UserProfileContext } from '../../../App';
const AssetNote = () => {
	const { permissions } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const [noteId, setNoteId] = useState('');
	const [searchedAssetNotes, setSearchAssetNotes] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();
	const [upsertAssetNote] = useApolloMutation(UPSERT_ASSET_NOTE);
	const [deleteAssetNote] = useApolloMutation(DELETE_ASSET_NOTE);
	const { data: assetNotes, refetch: refetchConsultantNotes } = useApolloQuery(
		GET_ASSET_NOTE,
		{
			variables: {
				asset_id: id ? id : null,
				SearchText: `%${searchedAssetNotes}%`,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: noteList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_notes',
		payload: {
			filter: {
				id: noteId ? noteId : null,
			},
		},
	});

	const deleteNote = (noteId: string) => {
		deleteAssetNote({
			variables: {
				noteId: noteId,
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Asset Notes Deleted successfully');
				refresh();
				refetchConsultantNotes();
			}
		});
	};

	const upsertNote = (note: NoteForm) => {
		upsertAssetNote({
			variables: {
				assetNote: {
					asset_id: id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify(
					note?.id
						? 'Asset Notes Updated successfully'
						: 'Asset Notes Created successfully'
				);
				refresh();
				refetchConsultantNotes();
			}
		});
	};

	const saveForm = (note: NoteForm) => {
		upsertNote(note);
	};

	return (
		<>
			<Notes
				saveForm={saveForm}
				deleteNote={deleteNote}
				searchNotes={setSearchAssetNotes}
				noteList={assetNotes?.asset_notes}
				refetch={() => refetchConsultantNotes()}
				noteId={setNoteId}
				getNoteList={noteList}
				permission={permissions?.asset?.update_permissions ? true : false}
				deletePermission={
					permissions?.asset_notes?.update_permissions?.deleted_at
						? true
						: false
				}
			/>
		</>
	);
};

export default AssetNote;
