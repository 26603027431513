import React, { useContext, useState, useEffect } from 'react';
import {
	List,
	TextField,
	ReferenceField,
	FunctionField,
	Datagrid,
	SelectInput,
	useListContext,
	setSidebarVisibility,
} from 'react-admin';
import { SearchIcon } from '../../assets/SvgIcons';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	ellipsisStyle,
	globalStyles,
	datagridStyle,
	autocompleteStyle,
	dropDownEllipsisStyle,
} from '../../Layout/styles';
import {
	searchBarTheme,
	modalFormTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { UserProfileContext } from '../../App';
import { useHistory } from 'react-router-dom';
import {
	Box,
	Typography,
	Tooltip,
	makeStyles,
	TextField as TextComponent,
	Button,
	Chip,
	IconButton,
	Checkbox,
} from '@material-ui/core';
import { useQuery as useApolloQuery } from '@apollo/client';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import {
	GET_PROJECT_EXPORT_FILTER,
	GET_PROJECT_RESOURCES,
} from './Projects.gql';
import {
	GET_PROJECT,
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import ProjectForm from '../ResourcePlanner/ProjectForm.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import DeleteModal from '../../SharedComponents/DeleteModal.component';
import {
	useGetProjectStatusInProjectListQuery,
	useGetProjectTimesheetEntryCountQuery,
} from '../../generated/graphql';

import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { isEmpty, orderBy, sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { ProjectPlanningPagination } from './ProjectPlanningPagination';
import { ProjectFilterFormBody } from './ProjectFilterFormBody.component';

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	navigationTabContainer: {
		width: '610px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
	prefixName: {
		fontSize: '12px',
		width: 'auto',
		fontFamily: 'Manrope-medium',
		fontWeight: 'bold',
		color: 'grey',
		opacity: '0.8',
	},
	projectName: {
		fontSize: '12px',
		maxWidth: '100px',
		fontFamily: 'Manrope-medium',
		opacity: '0.8',
		color: '#292929',
	},
	resourceCount: {
		fontSize: '12px',
		width: '50px',
		textAlign: 'center',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	companyDropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	resourceCountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '125px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
		whiteSpace: 'nowrap',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-72px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	companyDropListCount: {
		marginTop: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
			maxWidth: '150px',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
			maxWidth: '150px',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
			maxWidth: '150px',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	dateEndProject: {
		color: '#EA4335',
		fontSize: '12px !important',
		fontFamily: 'Manrope',
	},
	leftProjectDate: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
	expiryFilterStatus: {
		background: '#ffffff',
		height: '36px',
		alignItems: 'left',
		justifyContent: 'flex-start',
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	menuIcon: {
		height: '18px',
		width: '18px',
	},
	copyContainer: {
		'&:hover': {
			'& > svg': {
				opacity: '1',
			},
		},
	},
	copyIcon: {
		opacity: '0',
		height: '16px',
		width: '16px',
		color: '#1d82f5',
		marginLeft: '10px',
	},
	taskButton: {
		borderRight: '1px solid #E0E0E0',
	},
	projectStatus: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '-2px',
	},
}));

export const ProjectPlanningList = () => {
	const classes = useStyles();
	const styles = ellipsisStyle();
	const dropDownStyles = autocompleteStyle();
	const commonStyles = globalStyles();
	const datagridStyles = datagridStyle();
	const { permissions, dateFormat, filterState, dispatchFilter, featureFlag } =
		useContext(UserProfileContext);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [projectId, setProjectId] = useState(null);
	const [isProjectFormModalShown, setIsProjectFormModalShown] = useState(false);
	const [projectDetails, setProjectDetails] = useState({});
	const [projectPlanningStatusOption, setProjectPlanningStatusOPtion] =
		useState([]);
	const [projectStatusId, setProjectStatusId] = useState('');
	const history = useHistory();
	const ellipsis = dropDownEllipsisStyle();
	const formatUrl = window?.location?.href?.replace(/[?]/g, '/').split('/');
	const path = formatUrl[4];
	const [projectListIdForPlanning, setProjectListIdForPlanning] = useState([]);
	const dispatch = useDispatch();
	const [projectPlanningCount, setProjectPlanningCount] = useState(0);
	const [projectPlanningResource, setProjectPlanningResource] = useState([]);

	const allProjectPlanningResourceSelected =
		projectPlanningResource?.length ===
			filterState?.projectPlanningResourceFilter?.length || 0
			? true
			: false;

	const projectFilterWithResourceMappingForProjectPlanning = {
		project_resource_mappings: {
			employee_id:
				filterState?.projectPlanningResourceFilter &&
				filterState?.projectPlanningResourceFilter?.length > 0
					? {
							_in: filterState?.projectPlanningResourceFilter.map(
								(resource) => resource.value
							),
					  }
					: {},
		},
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
		],
		project_status_id: filterState?.projectPlanningStatusFilter
			? {
					_eq: filterState?.projectPlanningStatusFilter,
			  }
			: {},
	};

	const projectFilterWithoutResourceMappingForProjectPlanning = {
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
		],
		project_status_id: filterState?.projectPlanningStatusFilter
			? {
					_eq: filterState?.projectPlanningStatusFilter,
			  }
			: {},
	};

	const {
		data: filteredProjectsForProjectPlanning,
		refetch: refetchFilteredProjectsForProjectPlanning,
	} = useApolloQuery(GET_PROJECT_EXPORT_FILTER, {
		variables: {
			project:
				filterState?.projectPlanningResourceFilter &&
				filterState?.projectPlanningResourceFilter?.length > 0
					? projectFilterWithResourceMappingForProjectPlanning
					: projectFilterWithoutResourceMappingForProjectPlanning,
		},
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (filteredProjectsForProjectPlanning?.project?.length === 0) {
			setProjectListIdForPlanning([]);
			return;
		}

		const response = filteredProjectsForProjectPlanning?.project?.map(
			(projectList) => {
				return projectList.id;
			}
		);

		setProjectListIdForPlanning(response);
	}, [filteredProjectsForProjectPlanning]);

	const { data: project, refetch: refetchProject } = useApolloQuery(
		GET_PROJECT,
		{
			variables: {
				projectId: projectId || null,
			},
		}
	);
	const { data: projectTimesheetEntries } =
		useGetProjectTimesheetEntryCountQuery({
			variables: {
				projectId: projectId || null,
			},
		});
	const { data: projectStatusList, refetch: refetchProjectStatus } =
		useGetProjectStatusInProjectListQuery({
			fetchPolicy: 'network-only',
		});

	const { data: projectPlanningresourceList } = useApolloQuery(
		GET_PROJECT_RESOURCES,
		{
			skip: path === 'project',
		}
	);

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	const { data: projectPlanningUsersByTeam } = useApolloQuery(
		GET_USER_BY_TEAMS,
		{
			variables: {
				teamId: !filterState?.projectPlanningUserTeamFilter
					? {}
					: {
							_eq: filterState?.projectPlanningUserTeamFilter?.id,
					  },
			},
			skip: !filterState?.projectPlanningUserTeamFilter,
			fetchPolicy: 'network-only',
		}
	);
	useEffect(() => {
		if (!projectPlanningUsersByTeam) {
			return;
		}
		const useTeamAssignees = projectPlanningUsersByTeam?.user_team_mapping?.map(
			(user) => {
				return {
					value: user?.user?.id,
					label: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setProjectPlanningResource(sortedAssigneeList);
		if (filterState?.projectPlanningUserTeamFilter) {
			const availableResourceIdList = sortedAssigneeList.map(
				(option) => option.value
			);
			const selectedResources =
				filterState?.projectPlanningResourceFilter?.filter((option) =>
					availableResourceIdList.includes(option.value)
				);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: selectedResources,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
		}
		// eslint-disable-next-line
	}, [
		dispatchFilter,
		filterState.projectPlanningUserTeamFilter,
		projectPlanningUsersByTeam,
	]);

	// Handle the user team dropdown logic in project planning when filters are retained
	useEffect(() => {
		if (!userTeams) {
			return;
		}
		// check if retained team is available in the option / deleted
		if (filterState?.projectPlanningUserTeamFilter) {
			const availableUserTeamList = userTeams?.user_team?.map(
				(team) => team?.id
			);
			if (
				availableUserTeamList?.includes(
					filterState?.projectPlanningUserTeamFilter?.id
				)
			) {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
					payload: filterState?.projectPlanningUserTeamFilter,
				});
			} else {
				// If not available clear the retained team values and resource values
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
					payload: null,
				});
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: [],
				});
			}
		} else {
			// If not available clear the retained team values and resource values
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
				payload: null,
			});
		}
		// eslint-disable-next-line
	}, [dispatchFilter, userTeams]);

	const ProjectPlanningListCount = () => {
		const { total, isLoading } = useListContext();
		setProjectPlanningCount(total || 0);
		if (isLoading) return null;
		return <></>;
	};
	useEffect(() => {
		if (
			!projectPlanningresourceList ||
			isEmpty(projectPlanningresourceList?.project_resource_mapping)
		) {
			setProjectPlanningResource([]);
			return;
		}
		if (filterState?.projectPlanningUserTeamFilter) {
			const useTeamAssignees =
				projectPlanningUsersByTeam?.user_team_mapping?.map((user) => {
					return {
						value: user?.user?.id,
						label: user?.user?.full_name,
					};
				});
			const sortedAssigneeList = orderBy(useTeamAssignees, ['label'], ['asc']);
			setProjectPlanningResource(sortedAssigneeList);
			return;
		}
		const response = projectPlanningresourceList?.project_resource_mapping?.map(
			(userList) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		const sortedResponse = orderBy(
			response,
			[(user) => user.label?.toUpperCase()],
			['asc']
		);
		setProjectPlanningResource(sortedResponse);
	}, [filterState, projectPlanningUsersByTeam, projectPlanningresourceList]);

	useEffect(() => {
		const statusListInfo = projectStatusList?.project_list?.map((value) => {
			if (value?.ProjectStatus?.value === 'in_progress') {
				setProjectStatusId(value?.ProjectStatus?.id);
			}
			return {
				id: value?.ProjectStatus?.id,
				name: value?.ProjectStatus?.label,
			};
		});
		const sortedStatusListInfo = orderBy(
			statusListInfo,
			[(status) => status.name.toUpperCase()],
			['asc']
		);
		setProjectPlanningStatusOPtion(sortedStatusListInfo);
	}, [projectStatusList]);

	useEffect(() => {
		if (!project || !project?.project_by_pk) {
			return;
		}
		setProjectDetails({
			name: project.project_by_pk?.name,
			revenue: project.project_by_pk?.revenue,
			project_type: project.project_by_pk?.project_type,
			start_date: project.project_by_pk?.start_date,
			end_date: project.project_by_pk?.end_date,
			project_owner_id: project.project_by_pk?.project_owner_id,
			pre_sales_owner_id: project.project_by_pk?.pre_sales_owner_id,
			is_strict: project.project_by_pk?.is_strict,
			kanban_id: project.project_by_pk?.kanban_id,
			is_billable: project.project_by_pk?.is_billable,
			currency_id: project.project_by_pk?.currency_id,
			total_cost: project.project_by_pk?.total_cost,
			effort_estimate: project.project_by_pk?.effort_estimate,
			risk_status: project.project_by_pk?.risk_status,
			project_execution_type: project.project_by_pk?.project_execution_type,
			company_id: project.project_by_pk?.company_id,
			geo_id: project.project_by_pk?.geo_id,
			id: project.project_by_pk?.id,
			prefix: project.project_by_pk?.prefix,
			project_status_id: project.project_by_pk?.project_status_id,
			description: project.project_by_pk?.description,
			isCardsPresent:
				!!project?.project_by_pk?.kanban_board?.kanban_columns.find(
					(elem) => elem.project_kanban_cards_aggregate.aggregate.count > 0
				),
			tag_id: project.project_by_pk?.tag_id,
			category_id: project.project_by_pk?.category_id,
		});
	}, [project]);

	const onEditRowClick = (projectId) => {
		if (path === 'resource-planner') {
			if (permissions?.project?.update_permissions)
				history.push(`resource-planner/${projectId}`);
		} else return 'edit';
	};
	const ProjectsFilters = () => {
		return (
			<Box>
				<DrawerFilterContainer
					open={isDrawerShown}
					close={() => {
						setIsDrawerShown(false);
					}}
				>
					<ProjectFilterFormBody />
				</DrawerFilterContainer>
			</Box>
		);
	};

	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'project_owner_id':
				return (
					filterValues?.project_owner_id?.length > 0 &&
					filterValues?.project_owner_id?.map((project_owner_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='project_owner_id'
								reference='user'
								label='Project Owner'
								resource='user'
								record={{ project_owner_id }}
								link={false}
							>
								<FunctionField
									label='Full Name'
									render={(record) => (
										<>
											<Chip
												className={commonStyles.multiSelectFilterChip}
												key={index}
												label={`${record?.full_name} `}
												variant='outlined'
												onDelete={() => {
													filterValues.project_owner_id.splice(index, 1);
													setFilters(filterValues, displayedFilters);
												}}
											/>
										</>
									)}
								/>
							</ReferenceField>
						</Box>
					))
				);
			case 'project_status_id':
				return (
					filterValues?.project_status_id?.length > 0 &&
					filterValues?.project_status_id?.map((project_status_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='project_status_id'
								reference='project_status'
								label='Project Status'
								resource='project_status'
								sort={{ field: 'label', order: 'ASC' }}
								record={{ project_status_id }}
								link={false}
							>
								<FunctionField
									label='Full Name'
									render={(record) => (
										<>
											<Chip
												className={commonStyles.multiSelectFilterChip}
												key={index}
												label={`${record?.label}`}
												variant='outlined'
												onDelete={() => {
													filterValues.project_status_id.splice(index, 1);
													setFilters(filterValues, displayedFilters);
												}}
											/>
										</>
									)}
								/>
							</ReferenceField>
						</Box>
					))
				);
			case 'project_type':
				return (
					<FunctionField
						record={filterValues}
						source='project_type'
						label=''
						render={() =>
							filterValues?.project_type?.map((projectType, index) => (
								<Chip
									className={classes.multiSelectFilterChip}
									key={index}
									label={`${projectType}`}
									variant='outlined'
									onDelete={() => {
										removeFilter(filter);
									}}
								/>
							))
						}
					/>
				);
			case 'risk_status':
				return (
					<FunctionField
						record={filterValues}
						source='risk_status'
						label=''
						render={() =>
							filterValues?.risk_status?.map((projectRisk, index) => (
								<Chip
									className={classes.multiSelectFilterChip}
									key={index}
									label={`${projectRisk}`}
									variant='outlined'
									onDelete={() => {
										filterValues.risk_status.splice(index, 1);
										setFilters(filterValues, displayedFilters);
									}}
								/>
							))
						}
					/>
				);
			case 'company_id':
				return (
					filterValues?.company_id.length > 0 &&
					filterValues?.company_id.map((company_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='company_id'
								reference='crm_company'
								label='Full Name'
								resource='crm_company'
								record={{ company_id }}
								link={false}
							>
								<FunctionField
									label='Full Name'
									render={(record) => (
										<>
											<Chip
												className={commonStyles.multiSelectFilterChip}
												key={index}
												label={`${record?.name}`}
												variant='outlined'
												onDelete={() => {
													filterValues.company_id.splice(index, 1);
													setFilters(filterValues, displayedFilters);
												}}
											/>
										</>
									)}
								/>
							</ReferenceField>
						</Box>
					))
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	const getProjectId = (projectId) => {
		setProjectId(projectId);
		setIsProjectFormModalShown(true);
	};

	const getProjectPlanningResourceFilterLimits = (value) => (
		<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
			{filterState?.projectPlanningResourceFilter?.length === 1
				? filterState?.projectPlanningResourceFilter[0].label
				: `Selected ${
						filterState?.projectPlanningResourceFilter?.length || 0
				  } items`}
		</div>
	);
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const filter = createFilterOptions();

	const handleSelectProjectPlanningResourceAll = (isSelected) => {
		if (isSelected) {
			if (filterState?.projectPlanningUserTeamFilter) {
				const useTeamAssignees =
					projectPlanningUsersByTeam?.user_team_mapping?.map((user) => {
						return {
							value: user?.user?.id,
							label: user?.user?.full_name,
						};
					});
				const sortedAssigneeList = orderBy(
					useTeamAssignees,
					[(user) => user.name?.toUpperCase()],
					['asc']
				);
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: sortedAssigneeList,
				});
				return;
			}
			const response =
				projectPlanningresourceList?.project_resource_mapping?.map(
					(userList) => {
						return {
							value: userList?.user?.id,
							label: `${userList?.user?.full_name} `,
							projectId: userList?.project_id,
						};
					}
				);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: response,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
		}
	};
	const handleToggleProjectPlanningResource = () => {
		handleSelectProjectPlanningResourceAll(!allProjectPlanningResourceSelected);
	};

	const onProjectPlanningResourceChange = (event, value, reason) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleProjectPlanningResource();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
			return;
		}
	};
	const resetPlanningTeamResourceFilter = () => {
		if (
			!projectPlanningresourceList ||
			isEmpty(projectPlanningresourceList?.project_resource_mapping)
		) {
			setProjectPlanningResource([]);
			return;
		}
		const response = projectPlanningresourceList?.project_resource_mapping?.map(
			(userList) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		let sortedResponse = sortBy(response, ['label']);
		setProjectPlanningResource(sortedResponse);
	};
	return (
		<>
			<>
				<Typography className={classes.pageTitle}>
					Projects({projectPlanningCount || 0})
				</Typography>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					marginTop='10px'
				>
					<Box display='flex' alignItems='center'>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={searchBarTheme}>
								<Box width='344px'>
									<TextComponent
										placeholder='Search Projects'
										label={false}
										fullWidth
										InputLabelProps={{ style: { fontSize: 0 } }}
										InputProps={{
											startAdornment: <SearchIcon />,
										}}
										onChange={(e) => {
											dispatchFilter({
												type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_SEARCH_TEXT,
												payload: e?.target?.value,
											});
										}}
										value={filterState?.projectPlanningSearchText || ''}
									/>
								</Box>
							</ThemeProvider>
						</form>
						<Box className={classes.projectFilterStatus}>
							<Form
								onSubmit={() => {}}
								initialValues={{
									project_status:
										filterState?.projectPlanningStatusFilter || projectStatusId,
								}}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'project_status'}
												label={''}
												onChange={(event) => {
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_STATUS_FILTER,
														payload: event?.target?.value,
													});
												}}
												choices={projectPlanningStatusOption}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
						{featureFlag?.userTeams && (
							<Box className={classes.projectFilterStatus}>
								<Form onSubmit={() => {}}>
									{() => (
										<Box display='flex'>
											<Box width='200px' marginRight='5px'>
												<Box className={classes.dropDownFilterLabel}>
													User Team
												</Box>

												<form>
													<AutocompleteSearch
														placeholder={'Search Team'}
														option={
															userTeams && userTeams?.user_team
																? userTeams?.user_team?.map((userTeam) => {
																		return {
																			id: userTeam?.id,
																			name: userTeam?.name,
																		};
																  }) || []
																: []
														}
														onChange={(id) => {
															if (!id) {
																resetPlanningTeamResourceFilter();
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
																	payload: null,
																});
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
																	payload: [],
																});
																return;
															}
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
																payload: [],
															});
															const currentTeam = userTeams?.user_team?.find(
																(team) => team?.id === id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
																payload: currentTeam,
															});
														}}
														name={'team_id'}
														value={
															filterState?.projectPlanningUserTeamFilter
																? filterState?.projectPlanningUserTeamFilter
																: []
														}
													/>
												</form>
											</Box>
											<Box width='200px'>
												<Box className={classes.dropDownFilterLabel}>
													Resource
												</Box>
												<form>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															filterState?.projectPlanningResourceFilter
																? filterState?.projectPlanningResourceFilter
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={onProjectPlanningResourceChange}
														options={
															projectPlanningResource.length > 0
																? projectPlanningResource
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={
															getProjectPlanningResourceFilterLimits
														}
														ChipProps={{ style: { display: 'none' } }}
														filterOptions={(options, params) => {
															const filtered = filter(options, params);
															return [
																{ label: 'All', value: 'All' },
																...filtered,
															];
														}}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextComponent
																	{...params}
																	label=''
																	placeholder={
																		filterState?.projectPlanningResourceFilter &&
																		filterState?.projectPlanningResourceFilter
																			?.length > 0
																			? ''
																			: 'Search Resource'
																	}
																/>
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => {
															const selectAllProps =
																option.value === 'All' // To control the state of 'select-all' checkbox
																	? {
																			checked:
																				allProjectPlanningResourceSelected,
																	  }
																	: {};
															return (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected || false}
																		color='primary'
																		{...selectAllProps}
																	/>
																	<Tooltip
																		title={`${option?.label || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.label || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															);
														}}
														getOptionLabel={(option) => `${option?.label}`}
														getOptionSelected={(option, value) =>
															option.value === value.value
														}
													/>
												</form>
											</Box>
										</Box>
									)}
								</Form>
							</Box>
						)}
					</Box>
					<Box>
						<Button
							variant='outlined'
							className={classes.filterButton}
							onClick={() => setIsDrawerShown(true)}
							startIcon={<FilterListIcon />}
						>
							Filters
						</Button>
						{permissions?.project?.insert_permissions && (
							<Button
								startIcon={<AddIcon />}
								className={classes.addButton}
								onClick={() => {
									setProjectDetails({});
									setIsProjectFormModalShown(true);
									setProjectId('');
								}}
							>
								Add Project
							</Button>
						)}
					</Box>
				</Box>
				<List
					aside={<ProjectPlanningListCount />}
					pagination={
						<ProjectPlanningPagination
							projectData={
								filteredProjectsForProjectPlanning?.project?.length ?? -1
							}
						/>
					}
					sort={{ field: 'end_date', order: 'DESC' }}
					bulkActionButtons={false}
					resource={'project_list'}
					basePath={'/project'}
					actions={<ProjectsFilters />}
					perPage={15}
					filters={
						<Box className={classes.filterStyle}>
							<Box display='flex' flexDirection='column' width='100%'>
								<AppliedFiltersContainer totalCount={() => {}}>
									{getAppliedFilters}
								</AppliedFiltersContainer>
							</Box>
						</Box>
					}
					filter={{
						name: `%${filterState?.projectPlanningSearchText || ''}%`,
						project_status_id: filterState?.projectPlanningStatusFilter
							? filterState?.projectPlanningStatusFilter
							: projectStatusId
							? projectStatusId
							: [],
						id: projectListIdForPlanning,
					}}
				>
					<Datagrid
						className={classes.listStyle}
						rowClick={(projectId) => onEditRowClick(projectId)}
					>
						<FunctionField
							label='Name'
							render={(projectList) => {
								return (
									<Tooltip
										title={`${projectList?.name || '- -'}`}
										placement='right'
									>
										<Typography
											className={[classes.projectName, styles.ellipsis].join(
												' '
											)}
										>
											{`${projectList?.name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<TextField source='project_type' label='TYPE' />
						<FunctionField
							label='START DATE'
							render={(projectList) => {
								return (
									<Tooltip
										title={`${
											dayjs(projectList?.start_date).format(dateFormat) || '- -'
										}`}
										placement='right'
									>
										<Typography
											className={[classes.startDate, styles.ellipsis].join(' ')}
										>
											{`${
												dayjs(projectList?.start_date).format(dateFormat) ||
												'- -'
											}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='PROJECT OWNER'
							render={(projectList) =>
								projectList?.project_owner_id ? (
									<>
										<ReferenceField
											source='project_owner_id'
											label='PROJECT OWNER'
											reference='user'
											link={false}
										>
											<FunctionField
												render={(user) => {
													return (
														<Tooltip
															title={`${user?.full_name || ''}`}
															placement='right'
														>
															<Typography
																className={[
																	classes.title,
																	styles.ellipsis,
																].join(' ')}
															>
																{`${user?.full_name || '- '}`}
															</Typography>
														</Tooltip>
													);
												}}
											/>
										</ReferenceField>
									</>
								) : (
									'--'
								)
							}
						/>
						<ReferenceField
							source='project_status_id'
							reference='project_status'
							label='STATUS'
							link={false}
						>
							<FunctionField
								label=''
								render={(projectList) => {
									return (
										<Tooltip
											title={`${projectList?.label || '- -'}`}
											placement='right'
										>
											<Typography
												className={[
													classes.statusContainer,
													styles.ellipsis,
												].join(' ')}
											>
												{`${projectList?.label || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<FunctionField
							render={(projectList) => (
								<Box
									onClick={(e) => {
										e.stopPropagation();
										getProjectId(projectList?.id);
									}}
									className={datagridStyles.listEditIconContainer}
								>
									<IconButton className={datagridStyles.listEditIconButton}>
										<EditIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
				<ProjectForm
					open={isProjectFormModalShown}
					onClose={() => setIsProjectFormModalShown(false)}
					project={projectDetails}
					refetch={() => {
						refetchProject();
						refetchProjectStatus();
						refetchFilteredProjectsForProjectPlanning();
					}}
					projectId={projectId}
					isBillableDisabled={
						projectDetails.id &&
						projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
							?.aggregate?.count > 0
					}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => setIsDeleteModalShown(false)}
					confirmationMessage='You are about to permanently delete the Project and all of its associated data including tasks, notes and documents.Please confirm deletion'
				/>
			</>
		</>
	);
};
