import React, { useState, useContext, useEffect } from 'react';
import {
	TextInput,
	useNotify,
	required,
	SelectInput,
	maxLength,
} from 'react-admin';
import {
	Button,
	makeStyles,
	Box,
	Typography,
	Tooltip,
	CircularProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { modalFormTheme } from '../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs';
import { Attendance } from './Attendance.model';
import { UserProfileContext } from '../../App';
import {
	useGetAttendanceStatusQuery,
	useGetReportingManagerByUserIdQuery,
	useUpsertAttendanceRequestMutation,
} from '../../generated/graphql';
import { head, isEmpty } from 'lodash';
import { DropdownOption } from '../../SharedComponents/model';
import { validateName } from '../Settings/constant';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '442px',
		minHeight: '250px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	saveButton: {
		minWidth: '165px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		width: '165px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
	},
	error: {
		font: 'normal normal 12px/37px Manrope',
		color: 'red',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	attendance: Attendance;
	refetch: () => void;
	userId?: string;
}

const AttendanceRequestForm = (props: Props) => {
	const { open, onClose, attendance, refetch, userId } = props;
	const { permissions: permissionList, dateFormat } =
		useContext<any>(UserProfileContext);
	const [formError, setFormError] = useState('');
	const [leaveApproverId, setLeaveApproverId] = useState();
	const [leaveApproverOptions, setLeaveApproverOptions] =
		useState<DropdownOption[]>();
	const classes = modalFormStyle();
	const style = useStyles();
	const notify = useNotify();
	const [upsertAttendance, { loading: isAttendanceMutationLoading }] =
		useUpsertAttendanceRequestMutation();
	const { data: reportee } = useGetReportingManagerByUserIdQuery({
		variables: {
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: attendanceStatusList } = useGetAttendanceStatusQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (isEmpty(reportee?.user)) {
			return;
		}
		const reportingManager =
			head(reportee?.user)?.user_type === 'employee'
				? head(reportee?.user)?.employee?.reportsTo || null
				: head(head(reportee?.user)?.contractors)?.reportee || null;
		setLeaveApproverId(reportingManager?.id || null);
		const data = reportingManager?.id
			? [
					{
						id: reportingManager?.id,
						name: `${reportingManager?.full_name} `,
					},
			  ]
			: [];
		setLeaveApproverOptions(data);
	}, [reportee]);

	const onSave = (formValues: Attendance) => {
		const time = dayjs.duration(
			dayjs(`${formValues?.date} ${formValues?.out_time}`).diff(
				dayjs(`${formValues?.date} ${formValues?.in_time}`)
			)
		);
		const hour = time.format('H');
		const minute = time.format('m');
		if (parseInt(hour) < 0 || parseInt(minute) < 0) {
			setFormError('Out time should be greater than In time');
			return;
		}
		const attendanceDefaultStatus = attendanceStatusList
			? attendanceStatusList?.attendance_status?.find(
					(status) => status?.value === 'submitted'
			  )
			: null;
		if (permissionList?.appPermissions?.ui?.editOthersAttendance) {
			upsertAttendance({
				variables: {
					attendance: {
						...formValues,
						in_time: dayjs(`${formValues.date} ${formValues?.in_time}`).format(
							'HH:mm:ssZ'
						),
						out_time: formValues?.out_time
							? dayjs(`${formValues.date} 
					${formValues?.out_time}`).format('HH:mm:ssZ')
							: null,
						submitted_by: userId,
						attendance_status_id: attendanceDefaultStatus?.id || null,
					},
				},
			})
				.then((data) => {
					setFormError('');
					notify(
						attendance?.id
							? 'Attendance request updated successfully'
							: 'Attendance request submitted successfully'
					);
					refetch();
					onClose();
				})
				.catch((error) => {
					if (error) {
						if (
							error?.message?.includes(
								'user_attendance_request_submitted_by_date_org_id_key'
							)
						) {
							notify(
								'You already applied approval for the selected date',
								'warning'
							);
						} else {
							notify('Attendance request submission failed', 'warning');
						}
						return;
					}
				});
		} else {
			upsertAttendance({
				variables: {
					attendance: {
						...formValues,
						in_time: dayjs(`${formValues.date} ${formValues?.in_time}`).format(
							'HH:mm:ssZ'
						),
						out_time: formValues?.out_time
							? dayjs(`${formValues.date} 
					${formValues?.out_time}`).format('HH:mm:ssZ')
							: null,
						submitted_by: userId,
						attendance_status_id: attendanceDefaultStatus?.id || null,
					},
				},
			})
				.then((data) => {
					setFormError('');
					notify(
						attendance?.id
							? 'Attendance request updated successfully'
							: 'Attendance request submitted successfully'
					);
					refetch();
					onClose();
				})
				.catch((error) => {
					if (error) {
						if (
							error?.message?.includes(
								'user_attendance_request_submitted_by_date_org_id_key'
							)
						) {
							notify(
								'You already applied approval for the selected date',
								'warning'
							);
						} else {
							notify('Attendance request submission failed', 'warning');
						}
						return;
					}
				});
		}
	};
	const handleClose = () => {
		setFormError('');
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={style.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Attendance Regularization
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={{ ...attendance, approved_by: leaveApproverId }}
						onSubmit={onSave}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Typography className={classes.label}>In Time *</Typography>
								<TextInput
									source='in_time'
									label=''
									type='time'
									onChange={() => setFormError('')}
									validate={required()}
								/>
								<Typography className={classes.label}>Out Time *</Typography>
								<TextInput
									source='out_time'
									label=''
									type='time'
									onChange={() => setFormError('')}
									validate={required()}
								/>
								<Typography className={classes.label}>Date </Typography>
								<Field name='date'>
									{(props: any) => (
										<div>
											<CustomDateInput
												name={props.input.name}
												isDisabled={true}
												initialValue={props.input.value || null}
												onChange={() => {}}
												dateFormat={dateFormat}
											/>
										</div>
									)}
								</Field>
								<Typography className={classes.label}>Approver *</Typography>
								<SelectInput
									source='approved_by'
									label={false}
									disabled
									fullWidth
									choices={leaveApproverOptions || []}
									validate={required()}
									optionText={(choice: any) => (
										<Tooltip
											title={`${choice?.name || '- -'}`}
											placement='right'
										>
											<Typography>{`${choice?.name || '- -'}`}</Typography>
										</Tooltip>
									)}
								/>
								<Typography className={classes.label}>Reason *</Typography>
								<TextInput
									source='reason'
									fullWidth={true}
									label={false}
									validate={[required(), validateName, maxLength(200)]}
									multiline
								/>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={handleClose}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										disabled={
											invalid || pristine || isAttendanceMutationLoading
										}
										className={
											invalid || pristine || isAttendanceMutationLoading
												? style.disabledButton
												: style.saveButton
										}
										type='submit'
										startIcon={
											isAttendanceMutationLoading && (
												<CircularProgress
													style={{
														height: '20px',
														width: '20px',
														color: '#FFFFFF',
													}}
												/>
											)
										}
									>
										{attendance?.id ? 'Update' : 'Submit for Approval'}
									</Button>
								</Box>
								<Box className={style.error}>{formError}</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default AttendanceRequestForm;
