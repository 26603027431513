import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, makeStyles, Button, TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import NotesForm from './NotesForm.component';
import NotesList from './NotesList.component';
import { NoteForm, NoteList } from './model';
import { searchBarTheme } from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';

interface Props {
	noteList: NoteList[];
	getNoteList: NoteForm[];
	saveForm: (form: NoteForm) => void;
	refetch: () => void;
	searchNotes: (event: any) => void;
	deleteNote: (noteId: string) => void;
	noteId: (id: string) => void;
	smallWidthDescription?: boolean;
	permission?: boolean;
	deletePermission?: boolean;
	loading?:boolean
}

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	addNotesButton: {
		textTransform: 'none',
		minWidth: '111px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box !important',
		boxShadow: '0px 4px 12px #4285F43B !important',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
});

const Notes = (props: Props) => {
	const {
		saveForm,
		deleteNote,
		searchNotes,
		noteList,
		refetch,
		noteId,
		getNoteList,
		smallWidthDescription,
		permission,
		deletePermission,
		loading
	} = props;
	const [isNotesModalShown, setIsNotesModalShown] = useState(false);
	const classes = useStyles();

	return (
		<>
			<Box className={classes.toolbar}>
				<Box>
					<form onSubmit={(event) => event.preventDefault()}>
						<ThemeProvider theme={searchBarTheme}>
							<Box width='250px'>
								<TextField
									placeholder='Search Notes'
									label={false}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(event: any) => searchNotes(event.target.value)}
								/>
							</Box>
						</ThemeProvider>
					</form>
				</Box>
				<Box>
					{permission ? (
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							className={classes.addNotesButton}
							onClick={() => setIsNotesModalShown(true)}
						>
							Add Notes
						</Button>
					) : null}
				</Box>
			</Box>
			<NotesForm
				open={isNotesModalShown}
				onClose={() => setIsNotesModalShown(false)}
				saveForm={saveForm}
				refetch={refetch}
				loading={loading}
			/>
			<NotesList
				List={noteList}
				refetch={refetch}
				saveForm={saveForm}
				deleteNote={deleteNote}
				noteId={noteId}
				getNoteList={getNoteList}
				smallWidthDescription={smallWidthDescription}
				permission={permission}
				deletePermission={deletePermission}
			/>
		</>
	);
};

export default Notes;
