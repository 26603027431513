import React, { useEffect, useState } from 'react';
import { Box, ThemeProvider, createTheme } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});
export const ChartDatePicker = (props: any) => {
	const {
		selectedStartDate,
		selectedEndDate,
		initialStartDate,
		initialEndDate,
	} = props;
	const [startDate, setStartDate] = useState<any>();
	const [endDate, setEndDate] = useState<any>(initialEndDate);

	useEffect(() => {
		setStartDate(initialStartDate);
	}, [initialStartDate]);

	useEffect(() => {
		setEndDate(initialEndDate);
	}, [initialEndDate]);
	return (
		<ThemeProvider theme={dateTimePickerTheme}>
			<Box
				display='flex'
				justifyContent='flex-end'
				marginBottom='20px'
				marginTop='10px'
				marginRight='10px'
			>
				<Box
					width='90px'
					style={{ cursor: 'pointer', border: '1px solid #d9d3d2' }}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							variant='inline'
							format='MMM D, YYYY'
							value={startDate}
							label={false}
							onChange={(date: any) => {
								setStartDate(dayjs(date).format('YYYY-MM-DD'));
								selectedStartDate(date.format('YYYY-MM-DD'));
							}}
						/>
					</MuiPickersUtilsProvider>
				</Box>
				<Box marginTop='7px'>
					<ArrowRightAltIcon />
				</Box>
				<Box
					width='100px'
					style={{ cursor: 'pointer', border: '1px solid #d9d3d2' }}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							variant='inline'
							format='MMM D, YYYY'
							label={false}
							value={endDate}
							onChange={(date: any) => {
								setEndDate(dayjs(date).format('YYYY-MM-DD'));
								selectedEndDate(date.format('YYYY-MM-DD'));
							}}
						/>
					</MuiPickersUtilsProvider>
				</Box>
			</Box>
		</ThemeProvider>
	);
};
