import React from 'react';
import UpComingHolidayList from './UpComingHolidayList.component';
import HomeInsights from './HomeInsights.component';
import TaskContainer from './TaskContainer.component';
import AllHolidays from './AllHolidays.component';

const Home = () => {
	return (
		<>
			<HomeInsights />
			<TaskContainer />
			<UpComingHolidayList/>
			<AllHolidays/>
		</>
	);
};

export default Home;
