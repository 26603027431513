import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Project, Status, ProjectStatus } from './modal';
import ProjectForm from './ProjectForm.component';

const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
		marginTop: '5px',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	projectData: Project;
	projectChoices: Status[];
	projectStatus: ProjectStatus[];
	currencyList: Status[];
	refetch: () => void;
	projectName: string;
}

export const ProjectEditPopOver = (props: Props) => {
	const {
		projectData,
		projectChoices,
		currencyList,
		refetch,
		projectName,
		projectStatus,
	} = props;
	const [projectModal, setProjectModal] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
		setProjectModal(false);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem onClick={() => setProjectModal(true)}>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
			</Menu>
			<ProjectForm
				open={projectModal}
				onClose={closePopOver}
				projectData={projectData}
				projectChoices={projectChoices}
				projectStatus={projectStatus}
				currencyList={currencyList}
				refetch={refetch}
				projectName={projectName}
			/>
		</div>
	);
};
