import React, { useContext, useEffect, useState } from 'react';
import {
	SelectInput,
	useNotify,
	required,
	TextInput,
	useQuery,
	useRefresh,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from 'react-router-dom';
import { UPSERT_SOFTWARE } from '../gqlQueries';
import { useMutation as useApolloMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';

export interface Software {
	id: string;
	name: string;
	product_key: string;
	installation_date: string;
	expiration_date: string;
	asset_id: string;
	type: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	software?: Software;
}
interface FormValue {
	asset_id: string;
	expiration_date: string;
	id: string;
	installation_date: string;
	name: string;
	product_key: string;
	type: string;
}
interface Type {
	description?: string;
	type: string;
}
const AssetSoftwareForm = (props: Props) => {
	const { open, onClose, software } = props;
	const { id }: { id: string } = useParams();
	const [softwareList, setSoftwareList] = useState([]);
	const notify = useNotify();
	const classes = modalFormStyle();
	const [installationDateGreaterError, setInstallationDateGreaterError] =
		useState(false);
	const [upsertSoftware,{loading : isSoftwareLoading}] = useApolloMutation(UPSERT_SOFTWARE);
	const refresh = useRefresh();
	const { dateFormat } = useContext<any>(UserProfileContext);

	const { data: softwareTypes } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_software_type',
		payload: {
			sort: { field: 'type', order: 'ASC' },
		},
	});

	useEffect(() => {
		if (!softwareTypes) {
			return;
		}
		const softwares = softwareTypes.map((type: Type) => {
			return { id: type.type, name: type.type };
		});
		setSoftwareList(softwares);
	}, [softwareTypes]);
	const onSave = (formValue: FormValue) => {
		if (
			dayjs(formValue?.installation_date).isAfter(formValue?.expiration_date)
		) {
			setInstallationDateGreaterError(true);
			return;
		}
		upsertSoftware({
			variables: {
				software: {
					asset_id: id,
					expiration_date: formValue?.expiration_date
						? formValue?.expiration_date
						: null,
					id: formValue?.id,
					installation_date: formValue?.installation_date
						? formValue?.installation_date
						: null,
					name: formValue?.name,
					product_key: formValue?.product_key ? formValue?.product_key : null,
					type: formValue?.type,
				},
			},
		}).then((response: any) => {
			refresh();
			notify(
				formValue?.id
					? 'Software Updated Successfully'
					: 'Software  Created successfully'
			);
			onClose();
		});
	};
	const validateSoftwareFormValues = async () => {
		const formErrors = {} as FormValue;
		if (installationDateGreaterError) {
			formErrors.installation_date =
				'Installation date must not be greater than expiration date!';
		}
		return formErrors;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>ADD SOFTWARE</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => {
								setInstallationDateGreaterError(false);
								onClose();
							}}
						/>
					</Box>
					<Form
						initialValues={software}
						onSubmit={onSave}
						validate={validateSoftwareFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Name *</Typography>
									<TextInput
										source='name'
										fullWidth={true}
										label={false}
										validate={required()}
									/>
									<Typography className={classes.label}>Type *</Typography>
									<SelectInput
										source='type'
										label={false}
										fullWidth
										choices={softwareList}
										validate={required()}
									/>
									<Typography className={classes.label}>Product Key</Typography>
									<TextInput
										source='product_key'
										sortable={false}
										label={false}
										fullWidth={true}
									/>
									<Box className={classes.multipleInputContainer}>
										<Box>
											<Typography className={classes.label}>
												Installation Date
											</Typography>

											<Field
												name='installation_date'
												validate={(value: any) => {
													if (
														dayjs(value).isValid() === true ||
														value === null
													) {
														return undefined;
													} else {
														return 'Invalid Date';
													}
												}}
											>
												{(props: any) => (
													<div>
														<CustomDateInput
															name={props.input.name}
															initialValue={props.input.value || null}
															onChange={(value) => {
																setInstallationDateGreaterError(false);
																props.input.onChange(value);
															}}
															dateFormat={dateFormat}
														/>
													</div>
												)}
											</Field>
										</Box>
										<Box>
											<Typography className={classes.label}>
												Expiration Date
											</Typography>

											<Field
												name='expiration_date'
												validate={(value: any) => {
													if (
														dayjs(value).isValid() === true ||
														value === null
													) {
														return undefined;
													} else {
														return 'Invalid Date';
													}
												}}
											>
												{(props: any) => (
													<div>
														<CustomDateInput
															name={props.input.name}
															initialValue={props.input.value || null}
															onChange={(value) => {
																setInstallationDateGreaterError(false);
																props.input.onChange(value);
															}}
															dateFormat={dateFormat}
														/>
													</div>
												)}
											</Field>
											{installationDateGreaterError && (
												<p className={classes.errorText}>
													Expiration date should be greater than installation
													date
												</p>
											)}
										</Box>
									</Box>
								</Box>
								<br />
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => {
											setInstallationDateGreaterError(false);
											onClose();
										}}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid || pristine || isSoftwareLoading
												? classes.disabledButton
												: classes.saveButton
										}
										disabled={invalid || pristine || isSoftwareLoading}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default AssetSoftwareForm;
