export const dayOptions = [
	{ id: '01', name: '01' },
	{ id: '02', name: '02' },
	{ id: '03', name: '03' },
	{ id: '04', name: '04' },
	{ id: '05', name: '05' },
	{ id: '06', name: '06' },
	{ id: '07', name: '07' },
	{ id: '08', name: '08' },
	{ id: '09', name: '09' },
	{ id: '10', name: '10' },
	{ id: '11', name: '11' },
	{ id: '12', name: '12' },
	{ id: '13', name: '13' },
	{ id: '14', name: '14' },
	{ id: '15', name: '15' },
	{ id: '16', name: '16' },
	{ id: '17', name: '17' },
	{ id: '18', name: '18' },
	{ id: '19', name: '19' },
	{ id: '20', name: '20' },
	{ id: '21', name: '21' },
	{ id: '22', name: '22' },
	{ id: '23', name: '23' },
	{ id: '24', name: '24' },
	{ id: '25', name: '25' },
	{ id: '26', name: '26' },
	{ id: '27', name: '27' },
	{ id: '28', name: '28' },
	{ id: '29', name: '29' },
	{ id: '30', name: '30' },
	{ id: '31', name: '31' },
];

export const monthOptions = [
	{ id: '01', name: 'Jan' },
	{ id: '02', name: 'Feb' },
	{ id: '03', name: 'Mar' },
	{ id: '04', name: 'Apr' },
	{ id: '05', name: 'May' },
	{ id: '06', name: 'Jun' },
	{ id: '07', name: 'Jul' },
	{ id: '08', name: 'Aug' },
	{ id: '09', name: 'Sep' },
	{ id: '10', name: 'Oct' },
	{ id: '11', name: 'Nov' },
	{ id: '12', name: 'Dec' },
];

export const startOfWeekOptions = [
	{ id: 'SUN', name: 'Sunday' },
	{ id: 'MON', name: 'Monday' },
];

export const hoursOptions = [
	{ id: 1, name: '1' },
	{ id: 2, name: '2' },
	{ id: 3, name: '3' },
	{ id: 4, name: '4' },
	{ id: 5, name: '5' },
	{ id: 6, name: '6' },
	{ id: 7, name: '7' },
	{ id: 8, name: '8' },
	{ id: 9, name: '9' },
	{ id: 10, name: '10' },
	{ id: 11, name: '11' },
	{ id: 12, name: '12' },
];

export const minutesOptions = [
	{ id: 0, name: '00' },
	{ id: 0.25, name: '15' },
	{ id: 0.5, name: '30' },
	{ id: 0.75, name: '45' },
];

export const weekendOptions = [
	{ id: 'SUN', name: 'Sunday' },
	{ id: 'SAT_1', name: '1st Sat' },
	{ id: 'SAT_2', name: '2nd Sat' },
	{ id: 'SAT_3', name: '3rd Sat' },
	{ id: 'SAT_4', name: '4th Sat' },
	{ id: 'SAT_5', name: '5th Sat' },
];

export const HOLIDAY_EXPORT_COLUMN_WITH_LOCATION = [
	'NAME',
	'DATE',
	'LOCATION',
	'RESTRICTED HOLIDAY',
	'YEAR',
	'DESCRIPTION',
];
export const HOLIDAY_EXPORT_COLUMN_WITHOUT_LOCATION = [
	'NAME',
	'DATE',
	'RESTRICTED HOLIDAY',
	'YEAR',
	'DESCRIPTION',
];
export const DELETE_ORG_SHIFT_MESSAGE = 'Shift has been deleted...';
export const ORG_LOCATION_CREATION_TEXT = 'Location Added successfully';
export const ORG_LOCATION_UPDATION_TEXT = 'Location Edited Succesfully';
export const ORG_EDITABLE_COLUMNS_UPDATION_TEXT =
	'Access updated successfully...';
