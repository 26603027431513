import React, { useState, useEffect } from 'react';

import {
	GET_DEPARTMENTS,
	GET_JOB_LEVELS,
	GET_DESIGNATIONS,
} from './gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';

const theme = createTheme({
	overrides: {
		MuiSvgIcon: {
			colorPrimary: {
				color: 'darkgray',
			},
		},
		MuiFilledInput: {
			root: {
				width: '224px',
				height: '36px',
				backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				opacity: 1,
			},
			input: {
				fontSize: '12px',
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#212121',
				opacity: 1,
			},
		},
		MuiInputLabel: {
			filled: {
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#7C7C7C',
				opacity: 1,
				fontSize: '12px',
			},
		},
		MuiTypography: {
			body1: {
				fontSize: '12px',
				font: 'normal normal medium 12px/23px Manrope',
				letterSpacing: '0px',
				color: '#292929',
				opacity: 1,
			},
		},
	},
});

export const EmployeeFilterFormBody = () => {
	const [departments, setDepartments] = useState(null);
	const [searchedDepartment, setSearchedDepartment] = useState(null);
	const [departmentLimit, setDepartmentLimit] = useState(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const [jobLevel, setJobLevel] = useState(null);
	const [searchedJobLevel, setSearchedJobLevel] = useState('');
	const [jobLevelLimit, setJobLevelLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

	const [designation, setDesignation] = useState(null);
	const [searchedDesignation, setSearchedDesignation] = useState('');
	const [designationLimit, setDesignationLimit] = useState(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const { data: designationList } = useApolloQuery(GET_DESIGNATIONS, {
		variables: {
			limit: designationLimit,
			title: `%${searchedDesignation || ''}%`,
		},
	});

	const { data: departmentList } = useApolloQuery(GET_DEPARTMENTS, {
		variables: {
			limit: departmentLimit,
			department: `%${searchedDepartment || ''}%`,
		},
	});

	const { data: jobLevelList } = useApolloQuery(GET_JOB_LEVELS, {
		variables: {
			limit: jobLevelLimit,
			title: `%${searchedJobLevel || ''}%`,
		},
	});

	useEffect(() => {
		if (!departmentList) {
			return;
		}
		const departmentValue = departmentList.departments.map((dept) => {
			return {
				id: dept.id,
				name: dept.name,
			};
		});
		setDepartments(
			orderBy(departmentValue, [(value) => value.name.toUpperCase()], ['asc'])
		);
	}, [departmentList]);

	useEffect(() => {
		if (jobLevelList?.job_level) {
			const departmentValue = jobLevelList.job_level.map((dept) => {
				return {
					id: dept.id,
					name: dept.level,
				};
			});
			setJobLevel(departmentValue);
		}
	}, [jobLevelList]);

	useEffect(() => {
		if (designationList?.designation) {
			const departmentValue = designationList.designation.map((dept) => {
				return {
					id: dept.id,
					name: dept.title,
				};
			});
			setDesignation(departmentValue);
		}
	}, [designationList]);

	const onDepartmentExpantionToggle = (isSeeMoreLabelShown) => {
		setDepartmentLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onJobLevelExpansionToggle = (isSeeMoreLabelShown) => {
		setJobLevelLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onDesignationExpansionToggle = (isSeeMoreLabelShown) => {
		setDesignationLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onDepartmentSearch = (name) => {
		setSearchedDepartment(name);
	};
	const onJobLevelSearch = (name) => {
		setSearchedJobLevel(name);
	};
	const onDesignationSearch = (name) => {
		setSearchedDesignation(name);
	};
	return (
		<ThemeProvider theme={theme}>
			<Box>
				<Box>
					<FilterInput
						label={'Designation'}
						sourceName='designation_id'
						onExpansionToggle={onDesignationExpansionToggle}
						choices={designation || []}
						onSearch={(name) => onDesignationSearch(name)}
						total={designationList?.designation_aggregate?.aggregate?.count}
					/>
				</Box>
				<Box>
					<FilterInput
						label={'Job Level'}
						sourceName='job_level_id'
						onExpansionToggle={onJobLevelExpansionToggle}
						choices={jobLevel || []}
						onSearch={(name) => onJobLevelSearch(name)}
						total={jobLevelList?.job_level_aggregate?.aggregate?.count}
					/>
				</Box>
				<Box>
					<FilterInput
						label={'Departments'}
						sourceName='department_id'
						onExpansionToggle={onDepartmentExpantionToggle}
						choices={departments || []}
						onSearch={(name) => onDepartmentSearch(name)}
						total={departmentList?.department_aggregate?.aggregate?.count}
					/>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default EmployeeFilterFormBody;
