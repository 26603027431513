import React from "react";
import keyBy from "lodash/keyBy";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  Datagrid,
  TextField,
  DateField,
  ListContextProvider,
  useQuery,
  Loading,
  ReferenceField,
  FunctionField
} from "react-admin";
const useStyles = makeStyles({
  resources: {
    textAlign: "center",
    padding: "20px",
  },
});
export const ProjectResources = ({ projectId }) => {
  const classes = useStyles();
  const { data: resources, loading, error } = useQuery({
    type: "GET_LIST",
    resource: "project_resource_mapping",
    payload: {
      sort: { field: "id", order: "ASC" },
      filter: { project_id: projectId },
    },
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Loading />;
  }
  return (
    <ListContextProvider
      value={{
        data: keyBy(resources, "id"),
        ids: resources.map(({ id }) => id),
        currentSort: { field: "id", order: "ASC" },
        basePath: "/project_skill_requirement",
        resource: "project_skill_requirement",
        selectedIds: [],
      }}
    >
      <Box>
        <Box>
          {resources.length > 0 ? (
            <Datagrid>
              <ReferenceField source="user_id" reference="user">
                <FunctionField
                  label="User"
                  render={(record) =>
                    `${record?.first_name} ${record?.middle_name || ""} ${
                      record?.last_name || ""
                    }`
                  }
                />
              </ReferenceField>
              <ReferenceField source="skill_id" reference="skill_master">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="start_date" />
              <DateField source="end_date" />
              <TextField source="total_allocation_hours" label="Hour" />
            </Datagrid>
          ) : (
            <Box className={classes.resources}>
              <Typography>No resources allocated...</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </ListContextProvider>
  );
};
export default ProjectResources;
