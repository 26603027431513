import React, { useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField as TextComponent,
	ReferenceField,
	FunctionField,
} from 'react-admin';

import { KanbanIcon, ListIcon } from '../../assets/SvgIcons';
import { CrmTasksKanban } from './CrmTasksKanban.component';
import {
	GET_CRM_TASKS_STATUS,
	GET_CRM_TASKS_TOTAL,
	GET_CRM_TASK_STATUS,
	GET_CRM_TASK,
} from './CrmTasks.gql';
import { useQuery as useApolloQuery } from '@apollo/client';
import head from 'lodash/head';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';

import {
	Typography,
	makeStyles,
	Box,
	TextField,
	Button,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddIcon from '@material-ui/icons/Add';
import CrmTaskForm from './CrmTaskForm.component';
import { ellipsisStyle } from '../../Layout/styles';
import { TaskStatusList } from '../../SharedComponents/model';
import { Task } from './CrmTasksKanban.model';

const useStyles = makeStyles(() => ({
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	addTaskButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	iconButton: {
		width: '36px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#888888',
	},
	activeIconButton: {
		color: '#4285F4',
		border: '1px solid #4285F4',
	},
	name: {
		width: '200px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	priorityContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '4px 8px 4px 8px',
		width: 'fit-content',
	},
	priorityHigh: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	priorityMedium: {
		backgroundColor: '#EBF3FF',
		color: '#4285F4',
	},
	priorityLow: {
		backgroundColor: '#E9F7EC',
		color: '#34A853',
	},
	statusContainer: {
		width: '116px',
		height: '20px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
	},
}));

export const searchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '344px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				paddingTop: '6px !important',
				height: '16px',
			},
		},

		// Search Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '8px',
				marginRight: '8px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

interface Kanban {
	id: string;
	state: string;
}

interface Crm {
	id: string;
}

interface CrmTags {
	crm_tags: Crm;
}

export const CrmTasksKanbanContainer = () => {
	const [isListShown, setIsListShown] = useState(false);
	const [crmTaskId, setCrmTaskId] = useState('');
	const [crmTask, setCrmTask] = useState<Task>({
		name: '',
	});
	const [searchedTask, setSearchedTask] = useState('');
	const [isAddTaskModalShown, setIsAddTaskModalShown] = useState(false);

	const [kanbanColumnId, setKanbanColumnId] = useState('');
	const [defaultKanbanColumnId, setDefaultKanbanColumnId] = useState('');
	const [crmTasksTotal, setCrmTasksTotal] = useState(0);
	const classes = useStyles();
	const tooltipStyle = ellipsisStyle();
	const { data: crmTaskStatus } = useApolloQuery(GET_CRM_TASKS_STATUS);
	const { data: crmTaskStatusOptions } =
		useApolloQuery<TaskStatusList>(GET_CRM_TASK_STATUS);
	const { data: total } = useApolloQuery(GET_CRM_TASKS_TOTAL, {
		variables: {
			taskName: `%${searchedTask}%`,
		},
	});

	const { data: crmTaskList, refetch: refetchCrmTask } = useApolloQuery(
		GET_CRM_TASK,
		{
			variables: {
				id: crmTaskId ? crmTaskId : null,
			},
		}
	);

	useEffect(() => {
		if (total) {
			setCrmTasksTotal(total?.crm_tasks_aggregate?.aggregate?.count);
		}
	}, [total]);

	useEffect(() => {
		const todoId = head(
			crmTaskStatusOptions?.kanban_board
		)?.kanban_columns.find(
			({ state }: { state: string }) => state === 'TO DO'
		)?.id;
		if (todoId) {
			setDefaultKanbanColumnId(todoId);
		}
	}, [crmTaskStatusOptions]);

	useEffect(() => {
		if (!crmTaskId && !crmTaskList?.crm_tasks_by_pk) {
			return;
		}
		setCrmTask({
			id: crmTaskList?.crm_tasks_by_pk?.id,
			kanbanCardId: crmTaskList?.crm_tasks_by_pk?.crm_task_kanban_cards[0]?.id,
			name: crmTaskList?.crm_tasks_by_pk?.name,
			description: crmTaskList?.crm_tasks_by_pk?.description,
			activity_type_id: crmTaskList?.crm_tasks_by_pk?.activity_type_id,
			opportunity_id: crmTaskList?.crm_tasks_by_pk?.opportunity_id,
			priority: crmTaskList?.crm_tasks_by_pk?.priority,
			status: crmTaskList?.crm_tasks_by_pk?.crm_task_kanban_cards[0]?.column_id,
			due_date: crmTaskList?.crm_tasks_by_pk?.due_date,
			owner_id: crmTaskList?.crm_tasks_by_pk?.user?.id,
			remind_at: crmTaskList?.crm_tasks_by_pk?.remind_at,
			tag_id: crmTaskList?.crm_tasks_by_pk?.crm_task_tag_mappings.map(
				(val: CrmTags) => {
					return val?.crm_tags?.id;
				}
			),
		});
	}, [crmTaskList, crmTaskId]);

	const onClose = () => {
		setCrmTaskId('');
		setCrmTask({
			name: '',
			priority: 'Medium',
		});
		setIsAddTaskModalShown(false);
	};

	return (
		<>
			<Typography className={classes.pageTitle}>
				Tasks({crmTasksTotal})
			</Typography>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<form>
					<ThemeProvider theme={searchBarTheme}>
						<TextField
							placeholder='Search tasks'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchOutlinedIcon />,
							}}
							onChange={(e) => setSearchedTask(e?.target?.value)}
							value={searchedTask}
						/>
					</ThemeProvider>
				</form>
				<Box>
					<IconButton
						className={[
							classes.iconButton,
							!isListShown ? classes.activeIconButton : '',
						].join(' ')}
						onClick={() => {
							setIsListShown(false);
						}}
					>
						<KanbanIcon />
					</IconButton>
					<IconButton
						className={[
							classes.iconButton,
							isListShown ? classes.activeIconButton : '',
						].join(' ')}
						onClick={() => {
							setIsListShown(true);
						}}
					>
						<ListIcon />
					</IconButton>
					<Button
						className={classes.addTaskButton}
						startIcon={<AddIcon />}
						variant='contained'
						onClick={() => {
							setCrmTaskId('');
							setKanbanColumnId(defaultKanbanColumnId);
							setIsAddTaskModalShown(true);
						}}
					>
						Add Task
					</Button>
				</Box>
			</Box>
			{!isListShown ? (
				<CrmTasksKanban
					searchParam={searchedTask}
					addTask={(kanbanColumnId: string) => {
						setKanbanColumnId(kanbanColumnId);
						setIsAddTaskModalShown(true);
					}}
				/>
			) : (
				<Box mt={2}>
					<List
						resource='crm_tasks'
						basePath='/crm_tasks'
						bulkActionButtons={false}
						actions={false}
						filter={{ name: `%${searchedTask}%` }}
					>
						<Datagrid
							rowClick={(id: any) => {
								setIsAddTaskModalShown(true);
								setCrmTaskId(id);
								return '/crm_tasks';
							}}
						>
							<FunctionField
								label='NAME'
								render={(task: any) => {
									const blocksFromHTML = convertFromHTML(task.name);
									const state = ContentState.createFromBlockArray(
										blocksFromHTML.contentBlocks,
										blocksFromHTML.entityMap
									);
									return (
										<Tooltip
											title={`${EditorState.createWithContent(state)
												.getCurrentContent()
												.getPlainText()}`}
											placement='right'
										>
											<Typography
												className={[classes.name, tooltipStyle.ellipsis].join(
													' '
												)}
											>
												{EditorState.createWithContent(state)
													.getCurrentContent()
													.getPlainText()}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								source='opportunity_id'
								label='OPPORTUNITY'
								className={[classes.name, tooltipStyle.ellipsis].join(' ')}
								render={(task: any) =>
									task?.opportunity_id ? (
										<ReferenceField
											source='opportunity_id'
											reference='opportunities'
											link={false}
										>
											<TextComponent source='name' />
										</ReferenceField>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								source='opportunity_id'
								label='CONTACT'
								className={[classes.name, tooltipStyle.ellipsis].join(' ')}
								render={(task: any) =>
									task?.opportunity_id ? (
										<ReferenceField
											link={false}
											source='opportunity_id'
											reference='opportunities'
											label='OPPORTUNITY'
										>
											<ReferenceField
												link={false}
												source='primary_contact_id'
												reference='crm_contacts'
											>
												<>
													<TextComponent source='first_name' />{' '}
													<TextComponent source='last_name' />
												</>
											</ReferenceField>
										</ReferenceField>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								source='owner_id'
								label='BD OWNER'
								className={[classes.name, tooltipStyle.ellipsis].join(' ')}
								render={(task: any) =>
									task?.owner_id ? (
										<ReferenceField
											source='owner_id'
											reference='user'
											link={false}
										>
											<>
												<TextComponent source='first_name' />{' '}
												<TextComponent source='last_name' />
											</>
										</ReferenceField>
									) : (
										'- -'
									)
								}
							/>
							<FunctionField
								source='due_date'
								label='DUE DATE'
								className={[classes.name, tooltipStyle.ellipsis].join(' ')}
								render={(task: any) =>
									task?.due_date ? `${task?.due_date}` : '- -'
								}
							/>
							<FunctionField
								label='STATUS'
								render={(task: any) =>
									crmTaskStatus?.crm_tasks.map(
										(crmTask: any, index: number) =>
											crmTask.id === task.id && (
												<Box key={index} className={classes.statusContainer}>
													{crmTask?.kanbanCard[0]?.kanban_column?.name}
												</Box>
											)
									)
								}
							/>
							<FunctionField
								source='priority'
								label='PRIORITY'
								render={(record: any) => (
									<Box
										className={`${classes.priorityContainer}
                ${
									record.priority === 'High'
										? classes.priorityHigh
										: record.priority === 'Medium'
										? classes.priorityMedium
										: record.priority === 'Low'
										? classes.priorityLow
										: undefined
								}
              `}
									>
										{record?.priority}
									</Box>
								)}
							/>
						</Datagrid>
					</List>
				</Box>
			)}

			<CrmTaskForm
				open={isAddTaskModalShown}
				onClose={onClose}
				kanbanColumnId={kanbanColumnId}
				CrmTask={crmTask}
				refetch={() => refetchCrmTask()}
			/>
		</>
	);
};

export default CrmTasksKanbanContainer;
