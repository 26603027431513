import {
	KanbanCard as KanbanCardModel,
	KanbanCardChildren,
} from './kanban.model';
import React from 'react';
import { Card } from '@material-ui/core';
import './kanbanComponent.css';
interface Props {
	card: KanbanCardModel;
	children: KanbanCardChildren;
	index?: number;
}

export const KanbardCardItem = ({
	snapshot,
	provided,
	children,
	card,
	style,
}: any) => {
	return (
		<div
			className={'kanbanCardRoot'}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			ref={provided.innerRef}
			style={{ ...style, ...provided.draggableProps.style }}
		>
			<Card
				style={{
					opacity: snapshot.isDragging ? 0.9 : 1,
				}}
				elevation={snapshot.isDragging ? 3 : 1}
			>
				<div className={'kanbanCardContent'}>{children(card)}</div>
			</Card>
		</div>
	);
};
// export const KanbanCard = ({ index, card, children}: Props) => {
// 	return (

// 					<KanbardCardItem
// 						provided={provided}
// 						snapshot={snapshot}
// 						card={card}
// 						children={children}
// 					/>
// 				)}

// 	);
// };
