import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
	KanbanCardChildren,
	KanbanColumnView,
	KanbanCard,
	UpdateOrdinalFn,
	GetKanbanColumnIdFn,
} from './kanban.model';
import { getDropOrdinal } from '../../Utils/kanban.util';
import { KanbanColumn } from './KanbanColumn.component';
import './kanbanComponent.css';
interface Stage {
	name: string;
	sum: number;
}
export const Kanban = ({
	columns,
	updateOrdinal,
	children,
	onAddCardToColumn,
	stageValue,
}: {
	columns: KanbanColumnView[];
	updateOrdinal: UpdateOrdinalFn;
	children: KanbanCardChildren;
	onAddCardToColumn: GetKanbanColumnIdFn;
	stageValue?: Stage[];
}) => {
	const [kanbanColumnViews, setKanbanColumnViews] = useState<
		KanbanColumnView[]
	>([]);
	useEffect(() => {
		setKanbanColumnViews(columns);
	}, [columns]);
	const getColumnCards = (
		kanbanView: KanbanColumnView[],
		columnId: string
	): KanbanCard[] => {
		return [...(kanbanView.find((view) => view.id === columnId)?.cards || [])];
	};
	const getIndexesSynced = (cards: KanbanCard[]) =>
		cards.map((card, index) => ({ ...card, index }));
	const onDragEnd = async (result: DropResult) => {
		const { destination, source, draggableId } = result;
		// for checking away from all columns....
		if (!destination) return;
		// When drag and drop in same column and same index
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		// When drag and drop inside the same column but index is not same
		if (source.droppableId === destination.droppableId) {
			const cards = getColumnCards(kanbanColumnViews, destination.droppableId);
			const dropOrdinal = getDropOrdinal(cards, destination.index, true);
			const draggedCard = cards.splice(source.index, 1)[0];
			const updatedCard = { ...draggedCard, ordinal: dropOrdinal };
			cards.splice(destination.index, 0, updatedCard);
			setKanbanColumnViews(
				kanbanColumnViews.map((column) =>
					column.id === destination.droppableId
						? { ...column, cards: getIndexesSynced(cards) }
						: column
				)
			);
			updateOrdinal(updatedCard.id, updatedCard.columnId, updatedCard.ordinal);
		} else {
			// drop to another column
			const srcCards = getColumnCards(kanbanColumnViews, source.droppableId);
			const destCards = getColumnCards(
				kanbanColumnViews,
				destination.droppableId
			);
			const draggedCard: KanbanCard | undefined = srcCards.find(
				(card) => card.id === draggableId
			);
			if (!draggedCard) {
				return;
			}
			const dropOrdinal: number = getDropOrdinal(destCards, destination.index);
			const updatedCard: KanbanCard = {
				...draggedCard,
				columnId: destination.droppableId,
				ordinal: dropOrdinal,
			};
			srcCards.splice(source.index, 1);
			destCards.splice(destination.index, 0, updatedCard);
			// onProjectStatusChange(updatedCard);
			setKanbanColumnViews(
				kanbanColumnViews.map((view) => {
					if (view.id === destination.droppableId) {
						return { ...view, cards: getIndexesSynced(destCards) };
					}
					if (view.id === source.droppableId) {
						return { ...view, cards: getIndexesSynced(srcCards) };
					}
					return view;
				})
			);
			updateOrdinal(updatedCard.id, updatedCard.columnId, updatedCard.ordinal, updatedCard?.taskId);
		}
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className={'kanbanContainer'}>
				{kanbanColumnViews?.length > 0 &&
					kanbanColumnViews.map((column) => {
						const stage = stageValue?.filter(
							(stage: Stage) => stage?.name === column?.name
						);
						// TODO Change this temporary fix code to proper way
						return stage ? (
							<KanbanColumn
								column={column}
								key={column.id}
								onAddCardToColumn={onAddCardToColumn}
								totalValue={stage || []}
							>
								{children}
							</KanbanColumn>
						) : (
							<KanbanColumn
								column={column}
								key={column.id}
								onAddCardToColumn={onAddCardToColumn}
							>
								{children}
							</KanbanColumn>
						);
					})}
			</div>
		</DragDropContext>
	);
};
