import React, { useContext, useState } from 'react';
import {
	TextInput,
	SelectInput,
	maxLength,
	useNotify,
	useRefresh,
	email,
	DateInput,
	number,
	RadioButtonGroupInput,
	ReferenceInput,
	AutocompleteInput,
	minLength,
	regex,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Typography,
	createTheme,
	makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../Layout/styles';
import { UserProfileContext } from '../../App';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { bloodGroupChoices, EMPLOYEE_GENDER_CHOICES } from '../constant';
import { MyInfoBasicForm } from './model';
import { useUpdateMyInfoPersonalDetailsMutation } from '../../generated/graphql';
import {
	editableColumnsPermissions,
	updateDetailsSuccessText,
} from '../MyInfo/constant';
import { FieldPermissionsContext } from './MyInfoView.component';
import { validatePhoneNumber, trimInput } from '../../Utils/string.util';
import CustomField from '../../SharedComponents/CustomField.component';

const useStyles = makeStyles((theme) => ({
	inputMonth: {
		marginTop: '24px',
	},
}));

export const currencySelectBox = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				width: '80px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '12px',
				color: '#5C5C5C',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});
interface Address {
	city: string;
	country: string;
	houseName: string;
	postalCode: string;
	state: string;
	streetName: string;
}
interface EmployeeForm {
	blood_group: string;
	emp_billing_cost: number;
	emp_cost: number;
	employee_joining_source_id: string;
	highest_academic_degree: string;
	id: string;
	join_date: string;
	marital_status: boolean;
	wedding_date?: string;
	personal_email: string;
	address: Address;
	years: string;
	months: string;
	currency_id?: string;
	org_shift_id: string;
	last_date: string;
	leave_group_id?: string;
	employeeStatus: string;
	dob?: string;
	gender?: string;
	institution_name?: string;
	emergency_contact_number?: string;
	emergency_contact_name?: string;
	custom_field_1?: string | undefined;
	custom_field_2?: string | undefined;
	custom_field_3?: string | undefined;
	custom_field_4?: string | undefined;
	custom_field_5?: string | undefined;
	custom_field_6?: string | undefined;
	custom_field_7?: string | undefined;
	custom_field_8?: string | undefined;
	custom_field_9?: string | undefined;
}
export interface CustomField {
	id: string;
	label: string;
	index: number;
	validation: any;
	options: { id: string; name: string }[];
	type: string;
	is_active: boolean;
}

interface EmployeeDetailsProps {
	onClose: () => void;
	open: boolean;
	record?: MyInfoBasicForm;
	refetch: () => void;
	customFields: CustomField[];
}

const EditMyInfoDetails = (props: EmployeeDetailsProps) => {
	const {
		open,
		onClose,
		record: employee,
		refetch: refetchEmployee,
		customFields,
	} = props;
	const [isEmailUniqueViolationError, setIsEmailUniqueViolationError] =
		useState(false);
	const [marriedStatus, setMarriedStatus] = useState('');
	const [optionValue, setOptionValue] = useState();
	const refresh = useRefresh();
	const notify = useNotify();
	const classes = modalFormStyle();
	const inputClasses = useStyles();
	const { dateFormat } = useContext<any>(UserProfileContext);
	const { editableColumns } = useContext<any>(FieldPermissionsContext);

	const handleClose = () => {
		setEndDateError(false);
		onClose();
	};
	const [endDateError, setEndDateError] = useState(false);

	const [updateMyInfo, { loading: isMyInfoLoading }] =
		useUpdateMyInfoPersonalDetailsMutation();
	const onSave = (employeeFormValues: EmployeeForm) => {
		if (!employeeFormValues) {
			return;
		}
		if (
			dayjs(employeeFormValues?.join_date).isAfter(
				employeeFormValues?.last_date
			)
		) {
			setEndDateError(true);
			return;
		}
		let marriageStatus = marriedStatus
			? marriedStatus === 'Married'
				? true
				: false
			: employee?.marital_status;
		updateMyInfo({
			variables: {
				employeeId: employee?.id,
				data: {
					personal_email: employeeFormValues?.personal_email || null,
					blood_group: employeeFormValues.blood_group,
					gender: employeeFormValues.gender,
					dob: employeeFormValues.dob,
					marital_status: marriageStatus,
					address: employeeFormValues.address,
					highest_academic_degree:
						employeeFormValues?.highest_academic_degree || null,
					institution_name: employeeFormValues?.institution_name || null,
					wedding_date: employeeFormValues?.wedding_date || null,
					emergency_contact_number:
						employeeFormValues?.emergency_contact_number || null,
					emergency_contact_name:
						employeeFormValues?.emergency_contact_name || null,
					custom_field_1: employeeFormValues?.custom_field_1 || null,
					custom_field_2: employeeFormValues?.custom_field_2 || null,
					custom_field_3: employeeFormValues?.custom_field_3 || null,
					custom_field_4: employeeFormValues?.custom_field_4 || null,
					custom_field_5: employeeFormValues?.custom_field_5 || null,
					custom_field_6: employeeFormValues?.custom_field_6 || null,
					custom_field_7: employeeFormValues?.custom_field_7 || null,
					custom_field_8: employeeFormValues?.custom_field_8 || null,
					custom_field_9: employeeFormValues?.custom_field_9 || null,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					refetchEmployee();
					notify(updateDetailsSuccessText);
					refresh();
					onClose();
				}
			})
			.catch((err) => {
				notify(`Create failed: ${err.message}`, 'warning');
				return;
			});
	};

	const validateEmployeeFormValues = async () => {
		const employeeFormErrors = {} as EmployeeForm;
		if (isEmailUniqueViolationError) {
			employeeFormErrors.personal_email = 'Email already exist!';
		}
		return employeeFormErrors;
	};

	const handleChange = (event: any) => {
		setOptionValue(event);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={{
							...employee,
							marital_status: employee?.marital_status
								? 'Married'
								: 'Unmarried',
						}}
						onSubmit={onSave}
						validate={validateEmployeeFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Typography className={classes.label}>
											Personal Email
										</Typography>
										<TextInput
											source='personal_email'
											fullWidth={true}
											label={''}
											validate={[email(), maxLength(250)]}
											onChange={() => {
												setIsEmailUniqueViolationError(false);
											}}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_personal_email
												)
											}
										/>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Prior Experience
												</Typography>
												<TextInput
													source='years'
													label={''}
													InputProps={{
														endAdornment: 'Years',
													}}
													validate={[
														number(),
														maxLength(2, 'must be in the format of 00,01,10'),
													]}
													disabled={true}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Box className={inputClasses.inputMonth}>
													<TextInput
														source='months'
														label={''}
														InputProps={{
															endAdornment: 'Months',
														}}
														validate={[
															number(),
															maxLength(2, 'must be in the format of 00,01,10'),
														]}
														disabled={true}
													/>
												</Box>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Address(House Name)
												</Typography>
												<TextInput
													source='address.houseName'
													label={''}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Address(Street Name)
												</Typography>
												<TextInput
													source='address.streetName'
													label={''}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>City</Typography>
												<TextInput
													source='address.city'
													fullWidth={true}
													label={''}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>State</Typography>
												<TextInput
													source='address.state'
													fullWidth={true}
													label={''}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Country
												</Typography>
												<ReferenceInput
													reference='geo_list'
													source='address.country'
													label=''
													filterToQuery={(searchText) => ({
														name: searchText,
													})}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												>
													<AutocompleteInput optionText='name' />
												</ReferenceInput>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Postal Code
												</Typography>
												<TextInput
													source='address.postalCode'
													fullWidth={true}
													label={''}
													validate={[number(), maxLength(6)]}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_address
														)
													}
												/>
											</Box>
										</Box>
										<Typography className={classes.label}>
											Joining Source
										</Typography>
										<ReferenceInput
											label=''
											source='employee_joining_source_id'
											reference='emp_joining_source'
										>
											<SelectInput optionText='label' disabled={true} />
										</ReferenceInput>
										<Typography className={classes.label}>
											Highest Degree
										</Typography>
										<TextInput
											source='highest_academic_degree'
											fullWidth={true}
											label={''}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_highest_academic_degree
												)
											}
										/>
										<Typography className={classes.label}>
											Institution Name
										</Typography>
										<TextInput
											source='institution_name'
											fullWidth={true}
											label={''}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_institution_name
												)
											}
											validate={[
												minLength(3, 'minimum three characters required'),
											]}
										/>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Joining Date
												</Typography>
												<Field
													name='join_date'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	setEndDateError(false);
																}}
																dateFormat={dateFormat}
																isDisabled={true}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Last Date
												</Typography>
												<Field
													name='last_date'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	setEndDateError(false);
																}}
																dateFormat={dateFormat}
																isDisabled={true}
															/>
														</div>
													)}
												</Field>
												{endDateError && (
													<p className={classes.errorText}>
														Last date should be greater than joining date
													</p>
												)}
											</Box>
										</Box>
										<Typography className={classes.label}>
											Blood Group
										</Typography>
										<SelectInput
											label=''
											source='blood_group'
											choices={bloodGroupChoices}
											disabled={
												!editableColumns?.includes(
													editableColumnsPermissions.emp_blood_group
												)
											}
										/>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Gender
												</Typography>
												<SelectInput
													label=''
													source='gender'
													choices={EMPLOYEE_GENDER_CHOICES}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_gender
														)
													}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>DOB</Typography>
												<Field
													name='dob'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
																isDisabled={
																	!editableColumns?.includes(
																		editableColumnsPermissions.emp_dob
																	)
																}
															/>
														</div>
													)}
												</Field>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Emergency Contact Name
												</Typography>
												<TextInput
													source='emergency_contact_name'
													fullWidth={true}
													label={''}
													validate={[
														minLength(3, 'minimum three characters required'),
														maxLength(25, 'Maximum 25 characters are allowed'),
													]}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Emergency Contact Number
												</Typography>
												<TextInput
													label=''
													source='emergency_contact_number'
													validate={[
														regex(validatePhoneNumber, 'Invalid phone number'),
														maxLength(15, 'Maximum 15 digits allowed'),
													]}
													fullWidth
													onChange={() => {}}
													parse={trimInput}
												></TextInput>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Location
												</Typography>
												<ReferenceInput
													reference='org_location'
													source='location_id'
													label=''
													filterToQuery={(searchText) => ({
														name: searchText,
													})}
													disabled={true}
												>
													<AutocompleteInput
														optionText='name'
														shouldRenderSuggestions={(name: string) =>
															name.trim().length > 0
														}
													/>
												</ReferenceInput>
											</Box>
											<Box>
												<Typography className={classes.label}>
													Marital Status
												</Typography>
												<RadioButtonGroupInput
													source={'marital_status'}
													label={''}
													onChange={(event: any) => {
														setMarriedStatus(event);
													}}
													choices={[
														{ id: 'Married', name: 'Married' },
														{ id: 'Unmarried', name: 'Unmarried' },
													]}
													disabled={
														!editableColumns?.includes(
															editableColumnsPermissions.emp_marital_status
														)
													}
												/>
											</Box>
										</Box>
										<Box margin='10px 0 10px 0'>
											<Typography className={classes.label}>
												Wedding Date
											</Typography>
											<Field
												name='wedding_date'
												validate={(value: any) => {
													if (
														dayjs(value).isValid() === true ||
														value === null
													) {
														return undefined;
													} else {
														return 'Invalid Date';
													}
												}}
											>
												{(props: any) => (
													<div>
														<CustomDateInput
															name={props.input.name}
															initialValue={props.input.value || null}
															onChange={props.input.onChange}
															dateFormat={dateFormat}
														/>
													</div>
												)}
											</Field>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>Shift</Typography>
												<ReferenceInput
													label=''
													source='org_shift_id'
													reference='org_shift'
													sort={{ field: 'name', order: 'ASC' }}
													filter={{
														is_active: true,
													}}
												>
													<SelectInput optionText='name' disabled={true} />
												</ReferenceInput>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Leave Policy
												</Typography>
												<ReferenceInput
													label=''
													source='leave_group_id'
													reference='org_leave_group'
												>
													<SelectInput optionText='label' disabled={true} />
												</ReferenceInput>
											</Box>
										</Box>
										{employee?.employeeStatus === 'Probation' && (
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Probation Start Date
													</Typography>
													<DateInput
														source='probation_start_date'
														label={''}
														fullWidth={true}
														disabled={true}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Probation End Date
													</Typography>
													<DateInput
														source='probation_end_date'
														label={''}
														fullWidth={true}
														disabled={true}
													/>
												</Box>
											</Box>
										)}
										{customFields &&
											customFields?.map(
												(
													customField: {
														index: any;
														label: any;
														options: any;
														type: any;
													},
													index: React.Key | null | undefined
												) => (
													<Box key={index}>
														<CustomField
															name={`custom_field_${customField.index}`}
															label={customField.label}
															option={customField.options}
															dateFormat={dateFormat}
															fieldType={customField.type}
															onChange={() => handleChange}
															value={optionValue!}
														/>
													</Box>
												)
											)}
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={handleClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												variant='contained'
												disabled={invalid || pristine || isMyInfoLoading}
												className={
													invalid || pristine || isMyInfoLoading
														? classes.disabledButton
														: classes.saveButton
												}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditMyInfoDetails;
