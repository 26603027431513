import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useGetPipelineByOwnerByKanbanBoardIdQuery } from '../../generated/graphql';
import { useParams } from 'react-router';
import { ChartWithDateRange } from './modal';
import { formatAmount } from '../../Utils/string.util';
import NoDataFound from '../NoDataFound.component';
import { insightChartTheme } from '../../Layout/Theme.component';
import { ChartDatePicker } from './CustomDatePicker.component';

export const OpportunityByOwnerReport = (props: ChartWithDateRange) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const { id: boardId }: { id: string } = useParams();
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartData, setChartData] = useState<any[]>([]);
	const [kanbanColumnNames, setKanbanColumnNames] = useState<string[]>([]);
	const colors = ['#3f9908', '#ed9907', '#196CF5', '#f05e1f'];

	const { data: pipelineByOwner, loading: isLoading } =
		useGetPipelineByOwnerByKanbanBoardIdQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				kanbanBoardId: boardId,
			},
		});

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineByOwner) {
			return;
		}
		const kanbanColumns = Array.from(
			new Set(
				pipelineByOwner.user
					.map((user) =>
						user.opportunities.map(
							(opportunity) =>
								opportunity?.opportunity_kanban_cards[0]?.kanban_column?.name ||
								''
						)
					)
					.flat()
			)
		);
		const data = pipelineByOwner.user
			.map((user) => ({
				name: user.full_name,
				opportunities: user.opportunities.map((opportunity) => ({
					...opportunity,
					kanbanColumn:
						opportunity?.opportunity_kanban_cards[0]?.kanban_column?.name || '',
				})),
			}))
			.map((user) => ({
				...user,
				kanbanColumnValues: kanbanColumns
					.map((kanbanColumn) => ({
						column: kanbanColumn,
						value: user.opportunities
							.filter(
								(opportunity) => opportunity.kanbanColumn === kanbanColumn
							)
							.reduce((total, currentVal) => total + currentVal.value, 0),
					}))
					.filter((column) => column.value > 0),
			}))
			.map((userOpportunity) => ({
				name: userOpportunity.name,
				totalValue: userOpportunity.opportunities.reduce(
					(total, currentVal) => total + currentVal.value,
					0
				),
				...userOpportunity.kanbanColumnValues.reduce(
					(a, v) => ({ ...a, [v.column]: v.value }),
					{}
				),
			}))
			.sort((a, b) => b.totalValue - a.totalValue)
			.map((opportunity) => {
				const { totalValue, ...restOpportunityValues } = opportunity;
				return {
					...restOpportunityValues,
				};
			});

		setKanbanColumnNames(kanbanColumns);
		setChartData(data);
	}, [pipelineByOwner]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			const labelList = payload[0].payload;
			const { name, ...rest } = labelList;
			const mappingLabelList = Object.entries(rest);
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{label}</p>
					{mappingLabelList?.map((label) => {
						return (
							<p>
								{label[0]}:{`${formatAmount(label[1], currency)}`}
							</p>
						);
					})}
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='name'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					{kanbanColumnNames.map((kanbanColumn, index) => (
						<Bar
							dataKey={kanbanColumn}
							fill={colors[index]}
							barSize={10}
							stackId='a'
						/>
					))}
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Owner
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => setEndDate(date)}
							selectedStartDate={(date: string) => setStartDate(date)}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
