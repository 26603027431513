import React, { useEffect, useState } from 'react';
import { TextInput, maxLength, required, useNotify } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPSERT_DESIGNATION } from '../../Settings.gql';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
import { validateDescription } from '../../constant';

interface FormValues {
	id?: string;
	title?: string;
	description?: string;
	created_at?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValues;
}
const DesignationForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const ellipsisStyles = ellipsisStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [initialFormValues, setInitialFormValues] = useState<FormValues>({});
	const [isNameUniqueViolationError, setIsNameUniqueViolationError] =
		useState(false);
	const [upsertDesignation, { loading: isDesignationLoading }] =
		useApolloMutation(UPSERT_DESIGNATION);

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const closeModal = () => {
		setInitialFormValues({});
		onClose();
	};
	const onHandleSubmit = (formValues: FormValues) => {
		if (!formValues) {
			return;
		}
		const { created_at, ...restFormValues } = formValues;

		upsertDesignation({
			variables: {
				designation: { ...restFormValues },
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify('Designation Updated Successfully');
						refetch();
						closeModal();
						setInitialFormValues({});
					} else {
						notify('Designation Created Successfully');
						refetch();
						closeModal();
						setInitialFormValues({});
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error?.message.includes('UQ_b5abb4e201bc0267e2b65aab15d')) {
					setIsNameUniqueViolationError(true);
				}
				if (
					error?.message.includes(
						'value too long for type character varying(100)'
					)
				) {
					notify('Designation length limit exceeded', 'warning');
				}
			});
	};
	const descriptionValidate = [validateDescription,maxLength(50, 'Characters must be below 50')];
	const titleValidate = [required(),maxLength(100, 'Characters must be below 100')]

	const validateDesignationFormValues = async () => {
		const formErrors = {} as FormValues;
		if (isNameUniqueViolationError) {
			formErrors.title = 'designation already exist!';
		}
		return formErrors;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Tooltip
							title={initialValues?.id ? `${initialValues?.title}` : ''}
							placement={'top'}
						>
							<Typography
								className={`${modalFormStyles.heading} ${ellipsisStyles.ellipsis}`}
							>
								{isEditMutation
									? `Edit: ${initialFormValues?.title}`
									: `Add Designation`}
							</Typography>
						</Tooltip>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateDesignationFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Title *
									</Typography>
									<TextInput
										source='title'
										label=''
										validate={titleValidate}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										validate={descriptionValidate}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={isEditMutation ? 'Update' : 'Add'}
										isDisabled={invalid || pristine || isDesignationLoading}
										isLoading={isDesignationLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default DesignationForm;
