import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Tooltip } from '@material-ui/core';
import {
	DialogContent,
	ellipsisStyle,
	modalFormStyle,
} from '../../Layout/styles';
import { modalFormTheme, Theme } from '../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';

const employeeListModalStyle = makeStyles({
	content: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
	},
	employeeContainer: {
		borderBottom: '1px solid #E0E0E0',
		padding: '8px',
		display: 'flex',
		alignItems: 'center',
	},
	employeeName: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginRight: '10px',
		maxWidth: '44%',
	},
	email: {
		color: '#5C5C5C',
		fontSize: '10px !important',
		fontFamily: 'Manrope-medium',
		marginRight: '10px',
		maxWidth: '44%',
	},
});

interface Employees {
	name?: string;
	email?: string;
}

interface EmployeeListModalProps {
	onClose: () => void;
	open: boolean;
	employees: Employees[];
	onSearchChange?: (searchText: string) => void;
}

const EmployeeListModal = (props: EmployeeListModalProps) => {
	const { open, onClose, employees } = props;
	const formStyles = modalFormStyle();
	const employeeListModalStyles = employeeListModalStyle();
	const ellipsisStyles = ellipsisStyle();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={formStyles.container}>
					<Box className={formStyles.headerContainer} marginBottom={'20px'}>
						<Typography className={formStyles.heading}>
							Allocated Resource List
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</Box>

					<DialogContent>
						{employees && employees.length > 0 ? (
							<Box className={employeeListModalStyles.content}>
								{employees.map((employee, index) => (
									<Box
										key={index}
										className={employeeListModalStyles.employeeContainer}
									>
										<ThemeProvider theme={Theme}>
											<Tooltip placement='right' title={`${employee.name}`}>
												<Typography
													className={`${employeeListModalStyles.employeeName} ${ellipsisStyles.ellipsis}`}
												>
													{employee.name}
												</Typography>
											</Tooltip>
											<Tooltip placement='right' title={`${employee.email}`}>
												<Typography
													className={`${employeeListModalStyles.email} ${ellipsisStyles.ellipsis}`}
												>
													{employee.email}
												</Typography>
											</Tooltip>
										</ThemeProvider>
									</Box>
								))}
							</Box>
						) : (
							<Typography className={employeeListModalStyles.employeeName}>
								No Results Found...
							</Typography>
						)}
					</DialogContent>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeeListModal;
