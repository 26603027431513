import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useQuery } from 'react-admin';
import AssetCard from '../AssetManagement/AssestCard.component';
import { detailPageAssestTabStyle } from '../../Layout/styles';



const MyInfoAssetDetails = (props:any)=>{
  const { userId } = props;
  const classes = detailPageAssestTabStyle();
  const { data: assetList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset',
		payload: {
			filter: {
				used_by: userId,
			},
		},
  });

  
	const { data: assetTypeList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_type',
		payload: {},
	});
  
  return (
		<>
			{assetList?.length > 0 ? (
				<Box className={classes.gridContainer}>
					<Box className={classes.gridList}>
						{assetList?.map((item: any, index: number) => (
							<AssetCard
								key={index}
								data={item}
								typeList={assetTypeList}
								noRedirect={true}
							/>
						))}
					</Box>
				</Box>
			) : (
				<Typography className={classes.noData}>
					No data to display...
				</Typography>
			)}
		</>
	);
};

export default MyInfoAssetDetails;