import React, { useContext, useState } from 'react';
import { useNotify } from 'react-admin';

import {
	Box,
	Dialog,
	ThemeProvider,
	Typography,
	Button,
	Avatar,
	CircularProgress,
	CircularProgressProps,
	makeStyles,
	Card,
	CardContent,
	Tooltip,
} from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
	TimesheetHistoryPendingApprovalStyle,
	TimesheetStyles,
} from '../TimesheetStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
	useGetTimesheetStatusQuery,
	useUpdateOvertimeStatusMutation,
	useApproveOvertimeMutation,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import ConfirmationModal from '../../../SharedComponents/ConfirmationModal.component';
import {
	OVERTIME_APPROVAL_CONFIRMATION_TEXT,
	OVERTIME_APPROVAL_MODAL_BUTTON_LABEL,
	OVERTIME_APPROVAL_SUCCESS_TEXT,
	OVERTIME_REJECT_CONFIRMATION_TEXT,
	OVERTIME_REJECT_MODAL_BUTTON_LABEL,
	OVERTIME_REJECT_SUCCESS_TEXT,
} from './constant';
import { TimesheetDetailModalStyle } from '../TimesheetStyles';
import { UserProfileContext } from '../../../App';

import { OvertimeDetails } from './Overtime.model';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetOvertimePendingApprovalDetailsModalProps {
	onClose: () => void;
	open: boolean;
	overtime?: OvertimeDetails;
	onApprove: () => void;
}
const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: '550px',
		minHeight: '400px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	kpi: {
		width: '98%',
		height: '100px',
		background: '#FFFFFF',
		display: 'flex',
		justifyContent: 'space-around',
		boxShadow: '0px 3px 6px #0000000f',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	billableHoursContainer: {
		marginTop: '30px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	circularProgress: {
		color: '#4285F4',
		width: '80px !important',
		height: '80px !important',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 1px 12.5px #ACCBFF',
		marginTop: '9px',
	},
	cardRoot: {
		minWidth: 200,
		marginRight: 12,
		marginTop: 10,
		boxShadow: '0px 3px 6px #0000000F',
		marginBottom: 25,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	contentContainer: {
		marginTop: '25px',
		marginLeft: '10px',
	},
	contentDetails: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '120px',
		marginBottom: '8px',
	},
	fieldValue: {
		color: '#7C7C7C',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		width: '480px',
	},
	label: {
		color: '#292929',
		fontSize: '10px !important',
		letterSpacing: '0px',
		fontFamily: 'Manrope-bold',
		marginBottom: '5px',
	},
	link: {
		color: '#4285F4',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	taskContainer: {
		width: '100%',
	},
	divider: {
		margin: '12px 0px',
	},
	modalOuterContainer: {
		width: '100%',
		padding: '20px',
		background: '#FFFFFF',
		minHeight: '350px',
		borderRadius: '4px',
	},
	actionContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '16px',
		marginBottom: '16px',
	},
	approveButton: {
		width: 'fit-content',
	},
	note: {
		color: '#7C7C7C',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		width: '480px',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
	},
}));
export const TimesheetOvertimePendingApprovalDetailsModal = (
	props: TimesheetOvertimePendingApprovalDetailsModalProps
) => {
	const { id: userId } = useContext<any>(UserProfileContext);
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [isRejectOvertime, setIsRejectOvertime] = useState(false);
	const { open, onClose, overtime, onApprove } = props;
	const formStyles = modalFormStyle();
	const pendingApprovaDetailsStyles = TimesheetDetailModalStyle();
	const timesheetStyle = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const customStyles = useStyles();
	const timesheetPendingApprovalStyles = TimesheetHistoryPendingApprovalStyle();
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const [approveTimeSheet, { loading: isApproveTimesheetLoading }] =
		useApproveOvertimeMutation();

	const [updateOvertimeStatus, { loading: isRejectTimesheetLoading }] =
		useUpdateOvertimeStatusMutation();

	const CircularProgressWithLabel = (
		props: CircularProgressProps & { value: number }
	) => {
		return (
			<Box position='relative' display='inline-flex'>
				<CircularProgress variant='determinate' {...props} />
				<Box
					top={4}
					left={0}
					bottom={0}
					right={0}
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					<Typography
						className={pendingApprovaDetailsStyles.billablePercentageLabel}
					>{`${props.value}%`}</Typography>
				</Box>
			</Box>
		);
	};

	const Toolbar = () => (
		<Box className={customStyles.actionContainer}>
			{overtime?.date ? (
				<Box
					className={`${timesheetStyle.avatarContainer} ${pendingApprovaDetailsStyles.userDetailsContainer}`}
				>
					<Avatar
						className={timesheetStyle.avatar}
						src={`${overtime?.user?.profilePic || ''}`}
					>
						{overtime?.user?.name?.charAt(0).toUpperCase() || ''}
					</Avatar>
					<Typography className={timesheetStyle.employeeName}>
						{`${overtime?.user?.name || ''}`}
					</Typography>
					<Box ml={1} mr={1}>
						<Typography className={pendingApprovaDetailsStyles.breadCrum}>
							{'>>'}
						</Typography>
					</Box>
					<Typography className={pendingApprovaDetailsStyles.dateRange}>
						{`${dayjs(overtime?.date).format('DD MMM YYYY')}`}
					</Typography>
				</Box>
			) : (
				<Typography className={pendingApprovaDetailsStyles.dateRange}>
					- -
				</Typography>
			)}
			<Box className={pendingApprovaDetailsStyles.buttonActionContainer}>
				{overtime && overtime?.enableActionButtons && (
					<Button
						variant='contained'
						className={`${timesheetPendingApprovalStyles.button}  
								${`${formStyles.rejectButton}`} 
								`}
						onClick={() => {
							setIsRejectOvertime(true);
							setIsConfirmationModalShown(true);
						}}
						disabled={!overtime?.date || isRejectTimesheetLoading}
					>
						Reject
					</Button>
				)}
				{overtime && overtime?.enableActionButtons && (
					<Button
						variant='contained'
						className={`${timesheetPendingApprovalStyles.button}  
								${`${formStyles.approveButton}`} 
								`}
						onClick={() => {
							setIsConfirmationModalShown(true);
						}}
						disabled={!overtime?.date || isApproveTimesheetLoading}
					>
						Approve
					</Button>
				)}
			</Box>
		</Box>
	);

	const handleApproveOvertime = async () => {
		if (!timesheetStatus) {
			return;
		}
		const approvedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveTimeSheet({
			variables: {
				submissionIds: overtime?.timesheetSubmissionId,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			onApprove();
			setIsRejectOvertime(false);
			setIsConfirmationModalShown(false);
			notify(OVERTIME_APPROVAL_SUCCESS_TEXT);
			onClose();
		}
	};

	const handleRejectOvertime = async () => {
		if (!timesheetStatus) {
			return;
		}
		const rejectedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'rejected'
		);
		const timesheetReject = await updateOvertimeStatus({
			variables: {
				timesheetSubmissionId: overtime?.timesheetSubmissionId,
				timesheetStatusId: rejectedTimesheetStatus?.id,
			},
		});
		if (!timesheetReject.errors) {
			onApprove();
			setIsRejectOvertime(false);
			setIsConfirmationModalShown(false);
			notify(OVERTIME_REJECT_SUCCESS_TEXT);
			onClose();
		}
	};
	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
					fullWidth={false}
					maxWidth={false}
				>
					<Box className={customStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								OVERTIME DETAILS
							</Typography>
							<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
						</Box>
						<Toolbar />
						<Box>
							<Card className={customStyles.cardRoot} variant='outlined'>
								<CardContent>
									<Box className={customStyles.contentContainer}>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Project
											</Typography>
											<Tooltip
												title={overtime?.projectName || '--'}
												placement='left-start'
											>
												<Typography
													className={`${customStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{overtime?.projectName || '--'}
												</Typography>
											</Tooltip>
										</Box>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Task
											</Typography>
											<Tooltip
												title={overtime?.taskName || '--'}
												placement='left-start'
											>
												<Typography
													className={`${customStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{overtime?.taskName || '--'}
												</Typography>
											</Tooltip>
										</Box>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Notes
											</Typography>
											<Tooltip
												title={overtime?.notes || '--'}
												placement='left-start'
											>
												<Typography className={customStyles.note}>
													{overtime?.notes || '--'}
												</Typography>
											</Tooltip>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Box>
						<Box className={customStyles.kpi}>
							<CircularProgressWithLabel
								value={Number(overtime?.billablePercentage || 0)}
								variant='determinate'
								thickness={5.6}
								className={customStyles.circularProgress}
							/>
							<Box className={customStyles.billableHoursContainer}>
								<Typography
									className={pendingApprovaDetailsStyles.billableHours}
								>
									{`${overtime?.billableHours || '00:00'} hrs`}
								</Typography>
								<Box display='flex' alignItems='center'>
									<Box
										className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.billableHoursIcon}`}
									></Box>
									<Typography
										className={pendingApprovaDetailsStyles.billableHoursLabel}
									>
										Billable
									</Typography>
								</Box>
							</Box>
							<Box className={customStyles.billableHoursContainer}>
								<Typography
									className={pendingApprovaDetailsStyles.billableHours}
								>
									{`${overtime?.nonBillableHours || '00:00'} hrs`}
								</Typography>
								<Box display='flex' alignItems='center'>
									<Box
										className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.nonBillableHoursIcon}`}
									></Box>
									<Typography
										className={pendingApprovaDetailsStyles.billableHoursLabel}
									>
										Non-Billable
									</Typography>
								</Box>
							</Box>
							<Box display='flex' marginTop='25px'>
								<Box className={pendingApprovaDetailsStyles.iconContainer}>
									<AccessTimeIcon
										className={pendingApprovaDetailsStyles.accessTimeIcon}
									/>
								</Box>
								<Box
									display='flex'
									alignItems='flex-end'
									flexDirection='column'
								>
									<Typography
										className={pendingApprovaDetailsStyles.totalHoursValue}
									>
										{`${overtime?.totalHours || '00:00'}`}
									</Typography>
									<Typography className={timesheetStyle.totalHoursLabel}>
										TOTAL HOURS
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Dialog>
			</ThemeProvider>
			<ConfirmationModal
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
					setIsRejectOvertime(false);
				}}
				confirmationMessage={
					isRejectOvertime
						? OVERTIME_REJECT_CONFIRMATION_TEXT
						: OVERTIME_APPROVAL_CONFIRMATION_TEXT
				}
				onSubmit={() => {
					if (isRejectOvertime) {
						handleRejectOvertime();
						return;
					}
					handleApproveOvertime();
				}}
				submitButtonLabel={
					isRejectOvertime
						? OVERTIME_REJECT_MODAL_BUTTON_LABEL
						: OVERTIME_APPROVAL_MODAL_BUTTON_LABEL
				}
				isWarningConfirmation={isRejectOvertime}
				loading = {
					isRejectTimesheetLoading ||
					isApproveTimesheetLoading 
				}
			/>
		</>
	);
};

export default TimesheetOvertimePendingApprovalDetailsModal;
