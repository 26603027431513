import React from 'react';
import keyBy from 'lodash/keyBy';
import { useQuery } from 'react-admin';

import { ProjectPlanningList } from '../Projects/ProjectPlanningList.component';

export const ProjectListing = () => {
	const { data: projects, total } = useQuery({
		type: 'GET_LIST',
		resource: 'project',
		payload: {},
	});

	const resourcePlanning = {
		data: keyBy(projects, 'id'),
		ids: projects ? projects.map(({ id }) => id) : '',
		total,
		currentsort: { field: 'name', order: 'ASC' },
		basePath: `/project`,
		resource: 'project',
		selectedIds: [],
		title: ' ',
	};

	return <ProjectPlanningList {...resourcePlanning} />;
};

export default ProjectListing;
