import { gql } from '@apollo/client';

export const GET_CHECKLIST_TYPES = gql`
	query getCheckListTypeTabs($employeeId: uuid!) {
		checklist_types(where: { is_active: { _eq: true } }) {
			id
			label
			checklist_items_aggregate {
				aggregate {
					count
				}
			}
			checklist_items(
				where: {
					employee_checklists: {
						employee_id: { _eq: $employeeId }
						isCompleted: { _eq: true }
					}
				}
			) {
				employee_checklists {
					isCompleted
				}
			}
		}
	}
`;

export const GET_CHECKLISTS = gql`
	query getCheckListByTab($checkListTypeId: uuid!, $employeeId: uuid!) {
		checklist_items(
			where: { checklist_type_id: { _eq: $checkListTypeId } }
			order_by: { ordinal: asc }
		) {
			id
			name
		}
		employee_checklist(
			where: {
				employee_id: { _eq: $employeeId }
				checklist_item: { checklist_type: { id: { _eq: $checkListTypeId } } }
			}
		) {
			isCompleted
			checklist_item {
				id
			}
		}
	}
`;

/**
 * {"checkListItem":  {
        "checklist_item_id": "d117360b-fdf1-4acc-9aa4-4369a9cd3aac",
        "employee_id": "3f4d5fc3-1650-4be5-b446-24729352a9e1",
        "isCompleted": true
      }}
 */

export const UPSERT_EMP_CHECKLIST = gql`
	mutation upsertEmployeeChecklist(
		$checkListItem: employee_checklist_insert_input!
	) {
		insert_employee_checklist_one(
			on_conflict: {
				constraint: employee_checklist_employee_id_checklist_item_id_key
				update_columns: isCompleted
			}
			object: $checkListItem
		) {
			id
			isCompleted
			checklist_item_id
		}
	}
`;

export const GET_ORGANISATION_BASE_CURRENCY = gql`
	query getOrganisationBaseCurrency($organisationId: uuid!) {
		organization(where: { id: { _eq: $organisationId } }) {
			id
			name
			currency_id
		}
	}
`;

export const GET_EMPLOYEE_NOTE = gql`
	query getEmployeeNotes($employee_id: uuid!, $SearchText: String!) {
		employee_note(
			where: {
				employee_id: { _eq: $employee_id }
				_or: [
					{ title: { _ilike: $SearchText } }
					{ note: { _ilike: $SearchText } }
				]
			}
		) {
			user {
				first_name
				last_name
				full_name
				profile_pic
			}
			created_at
			created_by
			note
			employee_id
			title
			id
		}
	}
`;

export const UPSERT_EMPLOYEE_NOTE = gql`
	mutation upsertEmployeeNote($employeeNote: employee_note_insert_input!) {
		insert_employee_note_one(
			object: $employeeNote
			on_conflict: {
				constraint: employee_note_pkey
				update_columns: [note, title]
			}
		) {
			id
			title
			note
		}
	}
`;

export const DELETE_EMPLOYEE_NOTE = gql`
	mutation deleteEmployeeNote($noteId: uuid!) {
		update_employee_note(
			where: { id: { _eq: $noteId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;
export const INACTIVATE_EMPLOYEE = gql`
	mutation inactivateEmployee(
		$data: employee_leaving_reason_insert_input!
		$emp_id: uuid!
		$empData: employee_set_input!
	) {
		insert_employee_leaving_reason_one(
			object: $data
			on_conflict: {
				constraint: leaving_reason_pkey
				update_columns: [reason_id, notes, user_id, is_rehire_eligible, id]
			}
		) {
			id
		}
		update_employee_by_pk(pk_columns: { id: $emp_id }, _set: $empData) {
			id
			last_date
		}
	}
`;

export const GET_EMPLOYEE_DOCS = gql`
	query getEmployeeDocs($employee_id: uuid!) {
		employee_docs(
			order_by: { file_name: asc }
			where: { employee_id: { _eq: $employee_id } }
		) {
			id
			url
			file_name
		}
	}
`;

export const ADD_EMPLOYEE_DOC = gql`
	mutation addEmployeeDoc($data: employee_docs_insert_input!) {
		insert_employee_docs_one(object: $data) {
			file_name
		}
	}
`;

export const DELETE_EMPLOYEE_DOC = gql`
	mutation deleteEmployeeDoc($id: uuid!) {
		delete_employee_docs(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_FILE_NAME = gql`
	mutation updateEmployeeFileName($file_name: String!, $id: uuid!) {
		update_employee_docs(
			_set: { file_name: $file_name }
			where: { id: { _eq: $id } }
		) {
			returning {
				id
			}
		}
	}
`;

export const EMPLOYEE_LEAVING_REASON = gql`
	query employeeLeavingReason($user_id: uuid!) {
		employee_leaving_reason(where: { user_id: { _eq: $user_id } }) {
			is_rehire_eligible
			notes
			reason_id
			user_id
			id
		}
	}
`;

export const UPDATE_EMPLOYEE_PROBATION_DATE = gql`
	mutation updateEmployeeProbation(
		$employeeId: uuid!
		$probation_end_date: date
		$probation_start_date: date
		$employeeNote: employee_note_insert_input!
	) {
		update_employee_by_pk(
			pk_columns: { id: $employeeId }
			_set: {
				probation_end_date: $probation_end_date
				probation_start_date: $probation_start_date
			}
		) {
			probation_end_date
			probation_start_date
		}
		insert_employee_note_one(
			object: $employeeNote
			on_conflict: {
				constraint: employee_note_pkey
				update_columns: [note, title]
			}
		) {
			id
			title
			note
		}
	}
`;

export const GET_PROJECT_LIST_IDS = gql`
	query getProjectListIds($userId: uuid!, $SearchText: String!) {
		project_list(
			where: {
				name: { _ilike: $SearchText }
				project_resource_mapping: { employee_id: { _eq: $userId } }
			}
		) {
			id
			name

			end_date

			ProjectOwner {
				full_name
				email
			}
			ProjectStatus {
				label
			}
			Company {
				name
			}
			project_resource_mapping(where: { employee_id: { _eq: $userId } }) {
				start_date
				end_date
			}
		}
	}
`;
export const GET_EMPLOYEE_ALLOCATION_END_DATES = gql`
	query getEmployeeAllocationEndDates($id: uuid!) {
		employee_by_pk(id: $id) {
			user {
				full_name

				project_resource_mappings {
					end_date
					project {
						name
					}
				}
			}
		}
	}
`;

export const GET_ORG_DETAILS = gql`
	query getOrgDetails {
		department(order_by: { name: asc }) {
			id
			name
		}
		designation(order_by: { title: asc }) {
			id
			title
		}
		job_level(order_by: { level: asc }) {
			id
			level
		}
		employee_status(order_by: { label: asc }) {
			id
			label
		}
	}
`;

export const GET_ORG_MASTER_CURRENCIES = gql`
	query getOrgMasterCurrencies {
		master_currencies(order_by: { currency_type: asc }) {
			currency_type
			id
			symbol
		}
	}
`;

export const GET_EMP_CTC_DETAILS = gql`
	query getEmpCtcDetails($id: uuid!) {
		employee_by_pk(id: $id) {
			ctc_fixed
			ctc_notes
			ctc_variable
			ctc_currency_id
			master_currency {
				symbol
			}
			updated_at
			updatedByUser {
				full_name
			}
		}
	}
`;

export const GET_CTC_AUDIT_DETAILS = gql`
	query getCtcAuditDetails($userId: uuid!) {
		audit_audit_ctc(
			where: { action_on: { _eq: $userId }, updated_data: { _is_null: false } }
			order_by: { action_tstamp: desc }
			offset: 1
		) {
			action_on
			action_tstamp

			updated_data
			user {
				full_name
			}
		}
	}
`;

export const GET_CTC_AUDIT_CURRENT_DATA = gql`
	query getCtcAuditCurrentData($userId: uuid!) {
		audit_audit_employee(
			where: {
				action_on: { _eq: $userId }
				updated_data: { _is_null: false }
				action_type: { _eq: "compensation_change" }
			}
			order_by: { action_tstamp: desc }
			limit: 1
		) {
			action_on
			action_tstamp
			action_type
			action_data
			updated_data
			user {
				full_name
			}
		}
	}
`;
export const UPSERT_CTC_DATA = gql`
	mutation upsertCtcData($ctcData: ctc_insert_input!) {
		insert_ctc_one(
			object: $ctcData
			on_conflict: {
				constraint: ctc_pkey
				update_columns: [
					fixed
					variable
					notes
					currency_id
					relocation_amount
					joining_bonus
					net
					gross_1
					gross_2
					user_id
				]
			}
		) {
			id
		}
	}
`;

export const GET_USER_CTC = gql`
	query getUserCtc($userId: uuid!) {
		ctc(where: { user_id: { _eq: $userId } }) {
			id
			fixed
			variable
			joining_bonus
			relocation_amount
			net
			gross_1
			gross_2
			notes
			updatedBy {
				full_name
			}
			master_currency {
				currency_type
				id
				symbol
			}
		}
	}
`;

export const UPSERT_USER_EMAIL_GROUP = gql`
	mutation upsertUserEmailGroup($data: user_email_group_insert_input!) {
		insert_user_email_group_one(
			object: $data
			on_conflict: {
				constraint: user_email_group_pkey
				update_columns: [name, description]
			}
		) {
			id
		}
	}
`;
