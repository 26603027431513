import React, { useState, useEffect } from 'react';
import { TextInput, useNotify } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle, ellipsisStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	Box,
	Dialog,
	Typography,
	Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useUpsertEmployeePrefixMutation } from '../../../../generated/graphql';
import {
	PREFIX_UPDATION_MESSAGE,
	PREFIX_CREATION_MESSAGE,
	regexNameValidation,
} from '../../constant';

import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

interface FormValue {
	id?: string;
	name?: string;
	description?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}
const EmployeePrefixForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const ellipsis = ellipsisStyle();
	const [upsertPrefix, { loading: isPrefixLoading }] =
		useUpsertEmployeePrefixMutation();
	const [isNameUniqueViolationError, setIsNameUniqueViolationError] =
		useState(false);
	const [initialFormValues, setInitialFormValues] = useState<FormValue>({});

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		const { name, description, ...restFormValues } = formValues;
		const trimmedName = name?.trim();
		const trimmedDescription = description?.trim();
		upsertPrefix({
			variables: {
				prefixData: {
					...restFormValues,
					name: trimmedName,
					value: trimmedName,
					description: trimmedDescription,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify(PREFIX_UPDATION_MESSAGE);
						refetch();
						closeModal();
					} else {
						notify(PREFIX_CREATION_MESSAGE);
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error?.message.includes('employee_prefix_org_id_name_key')) {
					setIsNameUniqueViolationError(true);
				}
			});
	};
	const validateDepartmentFormValues = async () => {
		const departmentFormErrors = {} as FormValue;
		if (isNameUniqueViolationError) {
			departmentFormErrors.name = 'Prefix already exist!';
		}
		return departmentFormErrors;
	};
	const closeModal = () => {
		setInitialFormValues({});
		onClose();
	};
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		if (value?.length > 10) {
			return 'Maximum 10 characters allowed!';
		}
		return undefined;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Tooltip title={initialValues?.name || ''}>
							<Typography
								className={`${modalFormStyles.heading} ${ellipsis.ellipsis}`}
							>
								{isEditMutation ? `Edit: ${initialValues?.name}` : `Add Prefix`}
							</Typography>
						</Tooltip>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateDepartmentFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={validateName}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput source='description' label='' multiline={true} />
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										isDisabled={invalid || pristine || isPrefixLoading}
										isLoading={isPrefixLoading}
										title={isEditMutation ? 'Update' : 'Add'}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeePrefixForm;
