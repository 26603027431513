import { makeStyles } from '@material-ui/core';

export const TimesheetStyles = makeStyles({
	navigationTabButton: {
		width: '113px',
		height: '36px',
		font: '14px',
		color: '#626262',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		boxShadow: 'none',
		textTransform: 'none',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: 'none',
		},
	},
	activeNavigationTab: {
		color: '#FFFFFF',
		border: 'none',
		background: '#4285F4',
		'&:hover': {
			background: '#4285F4',
			boxShadow: 'none',
		},
	},
	navigationToolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	daySwitchButton: {
		borderRadius: '4px 0px 0px 4px',
	},
	weekSwitchButton: {
		borderRadius: '0px 4px 4px 0px',
	},
	emptyScreenContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	emptyScreenText: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#343434',
	},
	copyText: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		'&:hover': {
			fontWeight: 'bold',
		},
	},
	copyTextContainer: {
		display: 'flex',
		alignItems: 'center',
		color: '#4285F4',
		cursor: 'pointer',
	},
	container: {
		width: '100%',
		opacity: 1,
		flexGrow: 1,
		borderRadius: '4px',
	},
	dayNavigationContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '57px',
		width: '100%',
		background: '#FFFFFF',
		borderRadius: '4px',
		marginBottom: '5px',
	},
	content: {
		width: '100%',
		height: '584px',
	},
	dayNavigation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '80%',
	},
	dayNavigationButtonLabel: {
		fontFamily: 'Manrope-medium',
		color: '#292929',
		fontSize: '12px !important',
		width: '64px',
	},
	totalHours: {
		fontFamily: 'Manrope-bold',
		fontSize: '20px !important',
		color: '#343434',
		display: 'flex',
		alignItems: 'center',
	},
	totalHoursLabel: {
		color: '#343434',
		fontSize: '10px',
	},
	navigationButtonLabelContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50px',
	},
	formSubheading: {
		fontFamily: 'Manrope-medium',
		fontSize: '12 !important',
		color: '#343434',
	},
	selectedDateLabel: {
		color: '#4285F4',
	},
	dayNavigationButton: {
		borderRadius: '0px',
	},
	selectedDayNavigationButton: {
		borderBottom: '3px solid #4285F4',
	},
	timesheetContentContainer: {
		width: '100%',
		height: '114px',
		background: '#FFFFFF',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '5px',
		'&:hover': {
			boxShadow: '0px 4px 10px #0000001A',
			'& .MuiBox-root': {
				'& > button': {
					display: 'block',
				},
			},
		},
	},
	timesheetTotalHoursContainer: {
		width: '100%',
		height: '60px',
		background: '#FFFFFF',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '5px',
		'&:hover': {
			boxShadow: '0px 4px 10px #0000001A',
		},
	},
	timesheetContent: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
		padding: '0px 0px 0px 30px',
	},
	timesheetTaskTotalHoursContainer: {
		width: '20%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	projectName: {
		fontFamily: 'Manrope-semibold',
		fontSize: '12px',
		color: '#343434',
		width: '50%',
	},
	companyName: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#4E4E4E',
		width: '50%',
		marginBottom: '10px',
	},
	task: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#4E4E4E',
		width: '50%',
	},
	notes: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px',
		color: '#5A5A5A',
		width: '300px',
	},
	timesheetTotalContainer: {
		width: '100%',
		height: '36px',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0px 0px 0px 30px',
		background: '#FFFFFF',
	},
	timesheetTotalHoursLabel: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
	},
	accumulatedTimesheetTotalHours: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '20px',
		color: '#343434',
		display: 'flex',
		alignItems: 'center',
	},
	submitTimesheetActionContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	submitTimesheetButton: {
		width: 'fit-content',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		color: '#4285F4',
		textTransform: 'none',
		marginLeft: '20px',
		'&:hover': {
			background: '#FFFFFF',
		},
	},
	workingHoursInput: {
		width: '113px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px 0px 0px 0px',
	},
	timesheetFormContainer: {
		width: '600px',
	},
	workinHoursInputContainer: {
		width: '114px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	timesheetsContainer: {
		maxHeight: '70vh',
		overflowY: 'auto',
	},
	timesheetTotalHoursLabelContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '5px',
	},
	statusContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '4px 8px 4px 8px',
		width: 'fit-content',
	},
	rejectedStatus: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	submittedStatus: {
		backgroundColor: '#FFF9E2',
		color: '#EF9F20',
	},
	approvedStatus: {
		backgroundColor: '#C9F4CB',
		color: '#118115',
	},
	timesheetStatusContainer: {
		marginLeft: '30px',
	},
	rejectButton: {
		background: '#ED3D3D',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	avatar: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	employeeName: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
		marginLeft: '12px',
	},
	totalHoursContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '20%',
		height: '100%',
		justifyContent: 'center',
	},
	totalHoursRowData: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '14px !important',
		color: '#343434',
		width: '64px',
	},
	totalHoursTitle: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '14px !important',
		color: '#343434',
	},
	noEntryTotalHoursRowData: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '14px !important',
		color: '#888888',
		width: '70px',
	},
	timesheetHoursContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '60%',
	},
	hoursContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '80%',
		height: '100%',
	},
	dateContainer: {
		marginLeft: '8px',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		background: '#FFFFFF',
		height: '36px',
		borderRadius: '4px',
		padding: '0px 12px',
	},
	dateRange: {
		color: '#0B0B0B',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	dateIncrementButton: {
		minWidth: '20px',
		fontSize: '21px',
		fontFamily: 'Manrope-medium',
		color: '#707070',
		height: '36px',
	},
	leaveCircle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	iconContainer: {
		width: '20px',
		height: '20px',
		marginLeft: '-36px',
		marginTop: '-1px',
	},
	icon: {
		width: '10px',
		marginTop: '-4px',
		height: '10px',
	},
	addTaskShortcut: {
		color: '#4285F4',
		justifyContent: 'flex-start',
		paddingLeft: '10px',
		font: 'normal normal bold 10px/13px Manrope',
	},
	addTaskIcon: {
		color: '#4285F4',
		marginTop: '3px',
		width: '15px',
		height: '15px',
		marginRight: '5px',
	},
	addTaskLabel: {
		marginTop: '4px',
		font: 'normal normal bold 10px/13px Manrope',
		color: 'black',
	},
});

export const TimesheetHistoryPendingApprovalStyle = makeStyles({
	container: {},
	scrollableContainer: {
		marginTop: '20px',
		height: '575px',
		overflowY: 'auto',
	},
	actionsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	calendarIcon: {
		width: '16px',
		height: '16px',
		color: '#888888',
		marginRight: '12px',
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	avatar: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	employeeName: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
		marginLeft: '12px',
	},
	totalHoursContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	durationIcon: {
		width: '15px',
		height: '15px',
		color: '#4285F4',
		marginRight: '5px',
	},
	timesheetsContainer: {
		marginBottom: '34px',
	},
	approvedIcon: {
		width: '22px',
		height: '22px',
		color: '#3FA543',
		margin: '5px 0px 0px 10px',
	},
	dataActionContainer: {
		width: '10px',
	},
	checkBox: {
		padding: '0px 0px 0px 10px',
	},
	button: {
		width: 'fit-content',
	},
});

export const TimesheetDetailModalStyle = makeStyles(() => ({
	container: {
		minWidth: '442px',
		minHeight: '500px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	actionContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '16px',
		marginBottom: '16px',
	},
	buttonActionContainer: {
		width: '35%',
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	button: {
		width: 'fit-content',
	},
	userDetailsContainer: {
		width: '50%',
		marginBottom: '0px',
	},
	dateRange: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px !important',
		color: '#343434',
	},
	kpiContainer: {
		height: '130px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
		justifyContent: 'space-between',
	},
	kpi: {
		width: '49%',
		height: '100%',
		background: '#FFFFFF',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		boxShadow: '0px 3px 6px #0000000f',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	circularProgress: {
		color: '#4285F4',
		width: '100px !important',
		height: '100px !important',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 1px 12.5px #ACCBFF',
	},
	billablePercentageLabel: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '20px !important',
		color: '#4285F4',
	},
	billableHoursContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	billableHours: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px !important',
		color: '#292929',
		marginBottom: '6px',
	},
	hoursIcon: {
		width: '16px',
		height: '16px',
		borderRadius: '2px',
		marginRight: '5px',
	},
	billableHoursIcon: {
		background: '#4285F4',
	},
	nonBillableHoursIcon: {
		background: '#ACCBFF',
	},
	billableHoursLabel: {
		fontFamily: 'Manrope-semibold',
		fontSize: '12px !important',
		color: '#5C5C5C',
	},
	accessTimeIcon: {
		width: '32px',
		height: '32px',
		color: '#4285F4',
	},
	totalHoursValue: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '20px !important',
		color: '#343434',
	},
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '42px',
		height: '42px',
		background: '#EBF3FF',
		marginRight: '16px',
		borderRadius: '100px',
	},
	timesheetsContainer: {
		maxHeight: '40vh',
		overflowY: 'auto',
	},
	noEntryHours: {
		fontFamily: 'Manrope-medium',
		color: '#888888',
		fontSize: '12px !important',
		width: '64px',
	},
	date: {
		fontFamily: 'Manrope-medium',
		color: '#888888',
		fontSize: '10px !important',
		width: '70px',
	},
	breadCrum: {
		color: '#5C5C5C',
		fontSize: '10px',
		fontFamily: 'Manrope-extrabold',
	},
	timesheetContentContainer: {
		width: '100%',
		height: '70px',
		background: '#FFFFFF',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '5px',
		'&:hover': {
			boxShadow: '0px 4px 10px #0000001A',
		},
	},
	navigationButtonLabelContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	dayNavigationButtonLabel: {
		fontFamily: 'Manrope-medium',
		color: '#292929',
		fontSize: '12px !important',
		width: '64px',
	},
}));
