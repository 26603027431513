import React, { useState, useEffect } from 'react';

import {
	GET_USERS,
	GET_DEPARTMENTS,
	GET_ASSETS_MANAGED_BY,
} from './gqlQueries';
import { useQuery } from 'react-admin';
import { useQuery as useApolloQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import { Box } from '@material-ui/core';
import { DropdownOption } from '../../SharedComponents/model';
import { orderBy } from 'lodash';

interface Job {
	id: string;
	level: string;
}
interface Department {
	id: string;
	name: string;
}
interface Status {
	id: string;
	label: string;
}

export interface User {
	id: string;
	full_name: string;
}

export interface AssetUsedBy {
	user: User;
}

export interface AssetManagedBy {
	userByManagedBy: User;
}

export interface Departments {
	departmentID: DropdownOption;
}

export const AssetFilterFormBody = () => {
	const { data: assetTypeList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_type',
		payload: {
			sort: { field: 'type', order: 'ASC' },
		},
	});
	const [assetTypeChoices, setAssetTypeChoices] = useState(null);
	const [typeLimit, setTypeLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

	const [userChoices, setUserChoices] = useState(null);
	const [searchedUserName, setSearchedUserName] = useState('');
	const [userLimit, setUserLimit] = useState<Number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [managerChoices, setManagerChoices] = useState<Department[]>();
	const [searchedManagerName, setSearchedManagerName] = useState('');
	const [managerLimit, setManagerLimit] = useState<Number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const [departmentChoices, setDepartmentChoices] = useState(null);
	const [searchedDepartment, setSearchedDepartment] = useState('');
	const [departmentLimit, setDepartmentLimit] = useState<Number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const { data: departmentList, refetch: refetchDepartmentList } =
		useApolloQuery(GET_DEPARTMENTS, {
			variables: {
				limit: departmentLimit,
				department: `%${searchedDepartment || ''}%`,
			},
		});

	const { data: assetUsedBy } = useApolloQuery(GET_USERS, {
		variables: {
			limit: userLimit,
			name: `%${searchedUserName || ''}%`,
		},
		fetchPolicy: 'network-only',
	});

	const { data: managers } = useApolloQuery(GET_ASSETS_MANAGED_BY, {
		variables: {
			limit: managerLimit,
			name: `%${searchedManagerName || ''}%`,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		refetchDepartmentList();
	}, [refetchDepartmentList]);

	useEffect(() => {
		if (!assetTypeList || !(assetTypeList?.length > 0)) {
			return;
		}
		const assetTypeChoices = assetTypeList?.map((type: any) => {
			return {
				id: type?.id,
				name: `${type?.type}`,
			};
		});
		setAssetTypeChoices(assetTypeChoices);
	}, [assetTypeList]);

	useEffect(() => {
		if (!assetUsedBy?.asset || !(assetUsedBy?.asset?.length > 0)) {
			return;
		}
		const user = assetUsedBy?.asset.map((user: AssetUsedBy) => {
			return {
				id: user.user?.id,
				name: `${user.user?.full_name}`,
			};
		});
		const sortedUser: any = orderBy(
			user,
			[(asset) => asset?.name.toUpperCase()],
			['asc']
		);
		setUserChoices(sortedUser);
	}, [assetUsedBy]);

	useEffect(() => {
		if (!managers?.asset || !(managers?.asset?.length > 0)) {
			return;
		}
		const manager = managers.asset.map((manager: AssetManagedBy) => {
			if (manager?.userByManagedBy?.id) {
				return {
					id: manager.userByManagedBy?.id,
					name: `${manager.userByManagedBy?.full_name}`,
				};
			}
			return manager;
		});
		const sortedManagers = orderBy(
			manager,
			[(manager) => manager?.name.toUpperCase()],
			['asc']
		);
		setManagerChoices(sortedManagers);
	}, [managers]);

	useEffect(() => {
		if (!departmentList?.asset || !(departmentList?.asset?.length > 0)) {
			return;
		}
		const departmentValue = departmentList.asset.map((dept: Departments) => {
			return {
				id: dept.departmentID?.id,
				name: dept.departmentID?.name,
			};
		});
		const sortedDepartmentValue: any = orderBy(
			departmentValue,
			[(department) => department?.name.toUpperCase()],
			['asc']
		);
		setDepartmentChoices(sortedDepartmentValue);
	}, [departmentList]);

	const onTypeExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setTypeLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : typeLimit);
	};
	const onUserExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setUserLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onManagerExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setManagerLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onDepartmentExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setDepartmentLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onUserSearch = (name: string) => {
		setSearchedUserName(name);
	};
	const onManagerSearch = (name: string) => {
		setSearchedManagerName(name);
	};
	const onDepartmentSearch = (name: string) => {
		setSearchedDepartment(name);
	};

	return (
		<Box>
			<Box>
				<FilterInput
					label={'Type'}
					sourceName='type'
					onExpansionToggle={onTypeExpansionToggle}
					choices={assetTypeChoices || []}
					onSearch={() => {}}
					total={assetTypeList?.total?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Used By'}
					sourceName='used_by'
					onExpansionToggle={onUserExpansionToggle}
					choices={userChoices || []}
					onSearch={(name) => onUserSearch(name)}
					total={assetUsedBy?.asset_aggregate?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Managed By'}
					sourceName='managed_by'
					onExpansionToggle={onManagerExpansionToggle}
					choices={managerChoices || []}
					onSearch={(name) => onManagerSearch(name)}
					total={managers?.asset_aggregate?.aggregate?.count || 0}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Departments'}
					sourceName='department'
					onExpansionToggle={onDepartmentExpansionToggle}
					choices={departmentChoices || []}
					onSearch={(name) => onDepartmentSearch(name)}
					total={departmentList?.asset_aggregate?.aggregate?.count}
				/>
			</Box>
		</Box>
	);
};

export default AssetFilterFormBody;
