import React, { useEffect, useState } from 'react';
import { Paper, Link as MuiLink, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from 'antd';
import { getImage } from './Constant';
import { gridCardGlobalStyle } from '../../Layout/styles';
import { GetWarrantyState } from './AssetDetailsPage/AssetWarranty.component';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
	paper: {
		width: '195px',
		height: ' 230px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		padding: '1em',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		'&:hover': {
			boxShadow: '0px 36px 60px #0000000F',
		},
	},
	identity: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleContainer: {
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	subTitleContainer: {
		textAlign: 'center',
	},
	avatar: {
		width: ' 55px',
		height: '55px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: '1',
		alignItems: 'center',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '25px',
	},
	titleContent: {
		font: 'normal normal bold 12px/23px Manrope',
		letterSpacing: '0px',
		opacity: 1,
		fontSize: '12px',
	},
	subTitleContent: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
		color: '#6F6F6F',
		opacity: 1,
	},
	secondaryInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '20px',
	},
	secondaryInfoContent: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		maxWidth: '140px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	additionalInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '8px',
	},
	additionalInfoContent: {
		fontSize: '12px',
		opacity: 1,
		marginTop: '20px',
		marginBottom: '15px',
	},
	expiredWarranty: {
		color: '#EA4335',
		fontFamily: 'Manrope-Bold',
	},
	leftWarranty: {
		fontFamily: 'Manrope-Bold',
		color: '#34A853',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		color: '#888888',
	},
	days: {
		fontFamily: 'Manrope-Medium',
		color: '#292929',
		paddingLeft: '2px',
	},
}));
interface TypeList {
	id: string;
	image: string;
	type: string;
}
interface Props {
	typeList: TypeList[];
	data: any;
	noRedirect?: boolean;
	key: number;
}
export const AssetCard = (props: Props) => {
	const { typeList, data: asset, noRedirect } = props;
	const classes = useStyles();
	const cardGlobalStyle = gridCardGlobalStyle();
	const [typeDetails, setTypeDetails] = useState([
		{
			id: '',
			type: '',
			image: '',
		},
	]);

	useEffect(() => {
		if (typeList?.length > 0) {
			let type = typeList.filter((e: any) => e.id === asset.type);
			setTypeDetails(type);
		}
	}, [typeList, asset]);

	return (
		<Paper className={cardGlobalStyle.container}>
			<MuiLink
				component={Link}
				to={!noRedirect ? `/asset/${asset?.id}` : '#'}
				underline='none'
			>
				<div className={classes.identity}>
					<div className={classes.avatar}>
						<img src={getImage(typeDetails[0]?.type)} alt='icon' />
					</div>
				</div>
				<div className={classes.secondaryInfo}>
					<Tooltip placement='right' title={`${asset?.name || ''}`}>
						<Typography className={`${classes.secondaryInfoContent}`}>
							{asset?.name}
						</Typography>
					</Tooltip>
				</div>
				<div className={classes.titleContainer}>
					<Typography className={classes.subTitleContent}>
						{typeDetails[0]?.type}
					</Typography>
				</div>
				<div className={classes.additionalInfo}>
					<div className={classes.additionalInfoContent}>
						<span>{GetWarrantyState(asset?.warranty_expiration_date)}</span>
					</div>
				</div>
			</MuiLink>
		</Paper>
	);
};

export default AssetCard;
