import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	SelectInput,
	useNotify,
	ReferenceInput,
	AutocompleteInput,
	useQuery,
	BooleanInput,
	required,
	number,
	maxLength,
	minLength,
} from 'react-admin';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import {
	Button,
	Box,
	Typography,
	makeStyles,
	InputAdornment,
	CircularProgress,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
	modalFormTheme,
	currencySelectBox,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	currencyInputStyle,
	DialogActions,
} from '../../../Layout/styles';
import {
	GET_PROJECT_KANBANS,
	GET_PROJECT_TASK_CARDS_AGGREGATE,
	CREATE_PROJECT_LABEL,
	CREATE_PROJECT_CATEGORY,
	GET_PROJECT_CUSTOM_FIELDS,
} from '../../ResourcePlanner/gqlQueries';
import AddCompany from '../../Opportunities/AddCompany.component';
import { UserProfileContext } from '../../../App';
import {
	Project,
	ProjectStatus,
} from '../../ResourcePlanner/ResourcePlanner.model';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../../SharedComponents/gqlQueries';
import { OrganizationSupportedCurrencies } from '../../../SharedComponents/model';
import { useQuery as useApolloQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { head, omit } from 'lodash';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';

import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetActiveUsersByLimitQuery,
	useGetTasksAndMilestonesForProjectCloningQuery,
} from '../../../generated/graphql';
import {
	PROJECT_CATEGORY_CREATED_NOTIFICATION,
	PROJECT_LABEL_CREATED_NOTIFICATION,
} from '../../ResourcePlanner/constant';
import { PROJECT_TYPE_OPTIONS } from '../../constant';
import ProjectCloneModal from './projectCloneModal.component';
import {
	CLONE_PROJECT,
	CLONE_PROJECT_TASK_AND_MILESTONE,
} from '../Projects.gql';
import { useHistory } from 'react-router-dom';
import {
	PROJECT_CLONE_FAILED_MESSAGE,
	PROJECT_CLONE_SUCCESS_MESSAGE,
	PROJECT_NAME_CONSTRAINT,
	PROJECT_PREFIX_CONSTRAINT,
	PROJECT_TAG_CONSTRAINT,
} from '../constant';
import { regexNameValidation } from '../../Settings/constant';
import CustomField from '../../../SharedComponents/CustomField.component';

export const DialogContent = withStyles(() => ({
	root: {
		height: '400px',
		padding: '0px 14px 0px 0px',
	},
}))(MuiDialogContent);

const useStyles = makeStyles({
	detailContainer: {
		borderRadius: '4px',
		padding: '20px 0px 0px 20px',
	},
	error: {
		color: 'red',
		fontSize: '10px',
	},
	detailsContainer: {
		width: '442px',
		minHeight: '150px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '5px',
		marginLeft: '6px',
		height: '5px',
	},
	prefixText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-10px',
		marginLeft: '6px',
		height: '5px',
	},
	projectLabelErrorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-15px',
		marginLeft: '6px',
		height: '5px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
	},
	icon: {
		width: '12px',
		marginTop: '-2px',
		height: '12px',
	},
	ownerErrorText: {
		marginBottom: '5px',
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		height: '5px',
	},
});

interface Choice {
	first_name: string;
	last_name: string;
	full_name: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
	projectId?: string;
	project: Project;
}
interface Template {
	id: string;
	name: string;
	is_default: boolean;
}
const mapToName = (status: { name: string }) => `${status?.name || ''}`;
const projectLabelRenderer = (projectLabel: { name: string }) =>
	`${projectLabel?.name || ''}`;
const projectCategoryRenderer = (projectCategory: { name: string }) =>
	`${projectCategory?.name || ''}`;

const ProjectClone = (props: Props) => {
	const { open, onClose, project, projectId } = props;
	const { orgId: organizationId, dateFormat } =
		useContext<any>(UserProfileContext);
	const history = useHistory();

	const [projectChoices, setProjectChoices] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [kanbanTemplate, setKanbanTemplate] = useState(null);
	const [companyName, setCompanyName] = useState('');
	const [projectNameUniqueError, setProjectNameUniqueError] = useState(false);
	const [isCloneModalShown, setIsCloneModalShown] = useState(false);
	const [isAddCompanyFormShown, setIsAddCompanyFormShown] = useState(false);
	const [projectName, setProjectName] = useState('');
	const [prefixUniqueError, setPrefixUniqueError] = useState(false);
	const [prefixes, setPrefixes] = useState('');
	const [projectFormInitialValues, setProjectFormInitialValues] =
		useState<Project>({});
	const notify = useNotify();
	const classes = modalFormStyle();
	const currencyStyle = currencyInputStyle();
	const inlineStyle = useStyles();
	const [currentProjectFormValues, setCurrentProjectFormValues] =
		useState<Project>({});

	const [startDateError, setStartDateError] = useState(false);
	const [isProjectLabelsLoading, setIsProjectLabelsLoading] = useState(false);
	const [isProjectCategoriesLoading, setIsProjectCategoriesLoading] =
		useState(false);
	const [
		isProjectLabelUniqueViolationError,
		setIsProjectLabelUniqueViolationError,
	] = useState(false);
	const [
		isProjectCategoryUniqueViolationError,
		setIsProjectCategoryUniqueViolationError,
	] = useState(false);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);
	const [startDateFieldError, setStartDateFieldError] = useState(false);
	const [startDateFieldErrorText, setStartDateFieldErrorText] = useState('');
	const [endDateFieldError, setEndDateFieldError] = useState(false);
	const [endDateFieldErrorText, setEndDateFieldErrorText] = useState('');
	const [currentFormValues, setCurrentFormValues] = useState<Project>({});
	const [optionValue, setOptionValue] = useState();
	const { data: organisationSupportedCurrencies } = useApolloQuery<any>(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);

	const [createProjectLabel] = useApolloMutation(CREATE_PROJECT_LABEL);
	const [createProjectCategory] = useApolloMutation(CREATE_PROJECT_CATEGORY);
	const [cloneProject] = useApolloMutation(CLONE_PROJECT);
	const [cloneTaskAndMilestone] = useApolloMutation(
		CLONE_PROJECT_TASK_AND_MILESTONE
	);

	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: projectKanbanTemplates } = useApolloQuery<any>(
		GET_PROJECT_KANBANS,
		{
			fetchPolicy: 'network-only',
		}
	);
	const { data: projectCustomField } = useApolloQuery(
		GET_PROJECT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	const { data: taskCardsAggregrate } = useApolloQuery<any>(
		GET_PROJECT_TASK_CARDS_AGGREGATE,
		{
			variables: {
				project_id: project?.id || null,
			},
			fetchPolicy: 'network-only',
		}
	);
	const { data: taskAndMilestoneList } =
		useGetTasksAndMilestonesForProjectCloningQuery({
			variables: {
				projectId: projectId,
			},
			fetchPolicy: 'network-only',
		});
	useEffect(() => {
		if (
			!organisationSupportedCurrencies ||
			!organisationSupportedCurrencies?.org_supported_currencies
		) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);
		setCurrencyList(organizationCurrencyChoices);
	}, [organisationSupportedCurrencies]);

	const { data: projectStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'project_status',
		payload: { sort: { field: 'label', order: 'ASC' } },
	});

	useEffect(() => {
		if (project?.id) {
			setOwnerId(project?.project_owner_id || null);
			setPresalesOwnerId(project?.pre_sales_owner_id || null);
			const { name, prefix, id, ...restFormValues } = project;
			setCurrentFormValues(restFormValues);
			return;
		}
	}, [project]);

	useEffect(() => {
		if (!projectStatus || !(projectStatus?.length > 0)) {
			return;
		}
		const projectStatusList = projectStatus.map((value: ProjectStatus) => {
			return { id: value?.id, name: value?.label };
		});
		setProjectChoices(projectStatusList);
	}, [projectStatus]);

	useEffect(() => {
		const kanbanTemplates = projectKanbanTemplates?.kanban_board.map(
			(value: any) => {
				return {
					id: value?.id,
					name: value?.name,
					is_default: value?.is_default,
				};
			}
		);
		setKanbanTemplate(kanbanTemplates);
	}, [projectKanbanTemplates]);

	const handleCompany = (company: string) => {
		setCompanyName(company);
		setIsAddCompanyFormShown(true);
	};

	const handleProjectLabelCreate = (projectLabel: string) => {
		if (!projectLabel) {
			return;
		}
		setIsProjectLabelsLoading(true);
		const projectTagId = createProjectLabel({
			variables: {
				projectLabel: projectLabel,
			},
		})
			.then((res) => {
				setIsProjectLabelsLoading(false);
				notify(PROJECT_LABEL_CREATED_NOTIFICATION);
				return res?.data?.insert_project_tags_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectLabelsLoading(false);
				if (err.message.includes(PROJECT_TAG_CONSTRAINT)) {
					setIsProjectLabelUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectTagId;
	};

	const handleProjectCategoryCreate = (projectCategory: string) => {
		if (!projectCategory) {
			return;
		}
		setIsProjectCategoriesLoading(true);
		const projectCategoryId = createProjectCategory({
			variables: {
				projectCategory: projectCategory,
				type: 'project',
			},
		})
			.then((res) => {
				setIsProjectCategoriesLoading(false);
				notify(PROJECT_CATEGORY_CREATED_NOTIFICATION);
				return res?.data?.insert_project_category_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectCategoriesLoading(false);
				if (err.message.includes('project_category_name_org_id_key')) {
					setIsProjectCategoryUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectCategoryId;
	};

	const HandleCompanyClose = () => {
		setIsAddCompanyFormShown(false);
	};

	const onSave = (formValues: Project, checkEndDate: boolean) => {
		setCurrentProjectFormValues(formValues);
		if (dayjs(formValues?.start_date).isAfter(formValues?.end_date)) {
			setStartDateError(true);
			return;
		}
		setIsCloneModalShown(true);
		return;
	};

	const handleClose = () => {
		closeProjectForm();
	};

	const validateProjectFormValues = async () => {
		const projectFormErrors = {} as Project;
		if (projectNameUniqueError) {
			projectFormErrors.name = 'Project name already exists!';
		}
		return projectFormErrors;
	};

	const closeProjectForm = () => {
		setProjectFormInitialValues({});
		setCurrentFormValues({});
		setPrefixes('');
		setPrefixUniqueError(false);
		setProjectNameUniqueError(false);
		setStartDateError(false);
		setProjectName('');
		onClose();
		setCurrentProjectFormValues({});
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};
	const onConfirmClone = (cloneOptions: {
		cloneTask: boolean;
		cloneMilestone: boolean;
	}) => {
		const { id, name, prefix, ...restValues } = currentProjectFormValues;
		const cloneProjectResponse = cloneProject({
			variables: {
				objects: {
					name: String(currentProjectFormValues?.name).trim(),
					prefix: String(currentProjectFormValues.prefix).toUpperCase().trim(),
					...restValues,
				},
			},
		})
			.then((res) => {
				const newProjectId = res?.data?.insert_project_one?.id;
				const milestoneList = taskAndMilestoneList?.project_milestone?.map(
					(milestone) => {
						const taskWithMilestones = taskAndMilestoneList?.project_task
							?.filter((task) => task?.project_milestone_id === milestone?.id)
							.map((task) => {
								const {
									__typename,
									project_kanban_cards,
									project_milestone_id,
									project_task_comments,
									...taskRestValues
								} = task;
								const kanbanData = {
									data: omit({ ...head(project_kanban_cards) }, '__typename'),
								};
								const commentList = task?.project_task_comments?.map(
									(comment) => omit(comment, '__typename')
								);
								const taskCommentList = {
									data: commentList,
								};
								const taskObject = {
									...taskRestValues,
									assigned_to: null,
									project_id: newProjectId,
									project_kanban_cards: kanbanData,
									project_task_comments: taskCommentList,
								};
								return taskObject;
							});
						const { __typename, id, ...milestoneRestValues } = milestone;
						return cloneOptions?.cloneTask
							? {
									...milestoneRestValues,
									project_id: newProjectId,
									...{
										project_tasks: {
											data: taskWithMilestones,
										},
									},
							  }
							: {
									...milestoneRestValues,
									project_id: newProjectId,
							  };
					}
				);
				const taskList = taskAndMilestoneList?.project_task
					?.filter((task) => !task?.project_milestone_id)
					.map((task) => {
						const { __typename, project_kanban_cards, ...taskRestValues } =
							task;
						const kanbanData = {
							data: omit({ ...head(project_kanban_cards) }, '__typename'),
						};
						const commentList = task?.project_task_comments?.map((comment) =>
							omit(comment, '__typename')
						);
						const taskCommentList = {
							data: commentList,
						};
						const taskObject = {
							...taskRestValues,
							assigned_to: null,
							project_id: newProjectId,
							project_kanban_cards: kanbanData,
							project_task_comments: taskCommentList,
						};
						return taskObject;
					});
				const allTaskList = taskAndMilestoneList?.project_task?.map((task) => {
					const { __typename, project_kanban_cards, ...taskRestValues } = task;
					const kanbanData = {
						data: omit({ ...head(project_kanban_cards) }, '__typename'),
					};
					const commentList = task?.project_task_comments?.map((comment) =>
						omit(comment, '__typename')
					);
					const taskCommentList = {
						data: commentList,
					};
					const taskObject = {
						...taskRestValues,
						assigned_to: null,
						project_id: newProjectId,
						project_kanban_cards: kanbanData,
						project_task_comments: taskCommentList,
					};
					return taskObject;
				});
				const cloneResponse = cloneTaskAndMilestone({
					variables: {
						taskObjects: cloneOptions?.cloneTask
							? !cloneOptions?.cloneMilestone
								? allTaskList
								: taskList
							: [],
						milestoneObjects: cloneOptions?.cloneMilestone ? milestoneList : [],
					},
				})
					.then((res) => {
						notify(PROJECT_CLONE_SUCCESS_MESSAGE);
						setIsCloneModalShown(false);
						history.push({
							pathname: `/project/detail/${newProjectId}`,
						});
					})
					.catch((err) => {
						console.log(err);
						if (err?.message) {
							notify(PROJECT_CLONE_FAILED_MESSAGE, 'warning');
							return;
						}
						return;
					});
				return cloneResponse;
			})
			.catch((err) => {
				console.log(err);
				setIsCloneModalShown(false);
				if (err.message.includes(PROJECT_NAME_CONSTRAINT)) {
					setProjectNameUniqueError(true);
					notify(PROJECT_CLONE_FAILED_MESSAGE, 'warning');
					return;
				}
				if (err?.message.includes(PROJECT_PREFIX_CONSTRAINT)) {
					notify(PROJECT_CLONE_FAILED_MESSAGE, 'warning');
					setPrefixUniqueError(true);
					return;
				}
				return;
			});
		return cloneProjectResponse;
	};
	const getProjectFormIntitalValues = () => {
		if (!project) {
			return;
		}
		const { name, prefix, ...restFormValues } = project;
		return restFormValues;
	};
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};
	const handleChange = (event: any) => {
		setOptionValue(event);
	};
	useEffect(() => {
		if (
			!isAddCompanyFormShown &&
			!currentFormValues?.company_id &&
			Object?.keys(currentFormValues)?.length > 0
		) {
			setProjectFormInitialValues({
				...currentFormValues,
				project_owner_id: ownerId,
				prefix: prefixes,
			});
		}
	}, [
		currentFormValues,
		isAddCompanyFormShown,
		ownerId,
		prefixes,
		projectName,
	]);

	const validatePrefix = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		return undefined;
	};
	const formContent = () => (
		<Form
			onSubmit={(values: Project) => onSave(values, true)}
			initialValues={getProjectFormIntitalValues()}
			validate={validateProjectFormValues}
			mutators={{
				setPrefix: (args, state, utils) => {
					utils.changeValue(state, 'prefix', () => {
						if (!state?.lastFormState?.values?.name && prefixes?.length === 0) {
							setPrefixes('');
							return '';
						}
						if (prefixes?.length > 0) {
							const prefix = prefixes;
							return prefix;
						}
						if (prefixes?.length === 0 && state?.lastFormState?.values?.name) {
							const prefix = String(state?.lastFormState?.values?.name)
								.trim()
								.slice(0, 3)
								.toUpperCase();
							return prefix;
						}
					});
				},
				setProjectLabel: (args, state, utils) => {
					utils.changeValue(state, 'tag_id', () => {
						return args[0];
					});
				},
				setProjectCategory: (args, state, utils) => {
					utils.changeValue(state, 'category_id', () => {
						return args[0];
					});
				},
			}}
		>
			{({ handleSubmit, invalid, pristine, form }) => (
				<form onSubmit={handleSubmit}>
					<Box>
						<DialogContent>
							<Box className={classes.multipleInputContainer}>
								<Box width='70%'>
									<Typography className={classes.label}>
										Project Name *
									</Typography>
									<TextInput
										autoComplete='off'
										source='name'
										fullWidth={true}
										label={''}
										validate={validateName}
										onChange={(event: any) => {
											if (!event?.target?.value) {
												setProjectName('');
												form.mutators.setPrefix();
												return;
											}
											setCurrentFormValues({
												...currentFormValues,
												name: String(event.target.value).trim(),
											});
											setPrefixUniqueError(false);
											setProjectName(String(event.target.value).trim());
											setProjectNameUniqueError(false);
											form.mutators.setPrefix();
										}}
									/>
								</Box>
								<Box width='25%'>
									<Typography className={classes.label}>Prefix *</Typography>
									<TextInput
										inputProps={{ style: { textTransform: 'uppercase' } }}
										autoComplete='off'
										source='prefix'
										fullWidth={true}
										label={''}
										onChange={(event) => {
											if (!event) {
												setPrefixes('');
											}
											setCurrentFormValues({
												...currentFormValues,
												prefix: event?.target?.value,
											});
											setPrefixUniqueError(false);
											setPrefixes(String(event?.target?.value).trim());
										}}
										validate={[
											validatePrefix,
											minLength(3, 'Must be 3 characters'),
											maxLength(6, 'Must be 6 characters'),
										]}
									/>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{prefixUniqueError && (
										<p className={inlineStyle.prefixText}>
											prefix already exist!
										</p>
									)}
								</Box>
							</Box>
							<Typography className={classes.label}>Customer Name *</Typography>
							<ReferenceInput
								source='company_id'
								label={''}
								sort={{ field: 'name', order: 'ASC' }}
								reference='crm_company'
								filterToQuery={(searchText) => ({
									name: searchText,
								})}
								filter={{
									name: true,
								}}
								validate={required()}
								onChange={(event) => {
									if (!event) {
										const { company_id, ...restValues } = currentFormValues;
										setCurrentFormValues({ ...restValues });
										return;
									}
								}}
							>
								<AutocompleteInput
									resettable={true}
									optionText={mapToName}
									shouldRenderSuggestions={(name: string) =>
										name.trim().length > 0
									}
									onCreate={(event: any) => handleCompany(event)}
								/>
							</ReferenceInput>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Type *
									</Typography>
									<SelectInput
										fullWidth
										source='project_type'
										label={''}
										choices={PROJECT_TYPE_OPTIONS}
										validate={required()}
										onChange={(event) => {
											if (!event) {
												return;
											}
											setCurrentFormValues({
												...currentFormValues,
												project_type: event?.target?.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Execution Type *
									</Typography>
									<SelectInput
										fullWidth
										source='project_execution_type'
										label={''}
										choices={[
											{ id: 'External', name: 'External' },
											{ id: 'Internal', name: 'Internal' },
											{ id: 'Other', name: 'Other' },
										]}
										validate={required()}
										onChange={(event) => {
											if (!event) {
												return;
											}
											setCurrentFormValues({
												...currentFormValues,
												project_execution_type: event?.target?.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box
								display='flex'
								flexWrap='wrap'
								justifyContent='space-between'
							>
								<Box
									display='flex'
									width='38%'
									justifyContent='space-between'
									padding='0px 0px 0px 8px'
								>
									<Box>
										<Typography className={classes.label}>
											Is Billable
										</Typography>
										<BooleanInput
											source='is_billable'
											label=''
											choices={[
												{ id: true, name: 'True' },
												{ id: false, name: 'False' },
											]}
											onChange={(event) => {
												if (!event) {
													return;
												}
												setCurrentFormValues({
													...currentFormValues,
													is_billable: event?.target?.value,
												});
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>Is Strict</Typography>
										<BooleanInput
											source='is_strict'
											label=''
											choices={[
												{ id: true, name: 'True' },
												{ id: false, name: 'False' },
											]}
											onChange={(event) => {
												if (!event) {
													return;
												}
												setCurrentFormValues({
													...currentFormValues,
													is_strict: event?.target?.value,
												});
											}}
										/>
									</Box>
								</Box>
								<Box width='49%'>
									<Typography className={classes.label}>Country</Typography>
									<ReferenceInput
										source='geo_id'
										reference='geo_list'
										label={''}
										sort={{ field: 'name', order: 'ASC' }}
										onChange={(event) => {
											if (!event) {
												return;
											}
											setCurrentFormValues({
												...currentFormValues,
												geo_id: event?.target?.value,
											});
										}}
									>
										<SelectInput resettable={true} optionText='name' />
									</ReferenceInput>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Owner *
									</Typography>
									<AutocompleteSearch
										placeholder={'Search Owner'}
										option={
											activeUsers && activeUsers?.user
												? activeUsers?.user?.map((value) => {
														return {
															id: value?.id,
															name: value?.name,
														};
												  })
												: []
										}
										onChange={(value) => {
											if (!value) {
												setOwnerId(null);
												return;
											}
											setOwnerId(value);
										}}
										name={'project_owner_id'}
										validates={true}
										value={getOwnerValue(ownerId || '')}
									/>
									{!ownerId && (
										<p className={inlineStyle.ownerErrorText}>Required!</p>
									)}
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Pre-sales Owner
									</Typography>
									<AutocompleteSearch
										placeholder={'Search Owner'}
										option={
											activeUsers && activeUsers?.user
												? activeUsers?.user?.map((value) => {
														return {
															id: value?.id,
															name: value?.name,
														};
												  })
												: []
										}
										onChange={(event) => {
											setPresalesOwnerId(event);
											setCurrentFormValues({
												...currentFormValues,
												pre_sales_owner_id: event,
											});
										}}
										name={'pre_sales_owner_id'}
										validates={false}
										value={getOwnerValue(presalesOwnerId || '')}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Status
									</Typography>
									<SelectInput
										source='project_status_id'
										fullWidth
										label=''
										choices={projectChoices || []}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												project_status_id: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>Risk Status</Typography>
									<SelectInput
										source='risk_status'
										fullWidth
										label=''
										choices={[
											{ id: 'Amber', name: 'Amber' },
											{ id: 'Green', name: 'Green' },
											{ id: 'Red', name: 'Red' },
										]}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												risk_status: event.target.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Kanban template
									</Typography>
									<SelectInput
										disabled={
											taskCardsAggregrate?.project_kanban_card_aggregate
												?.aggregate?.count > 0
												? true
												: false
										}
										source='kanban_id'
										fullWidth
										label=''
										choices={kanbanTemplate || []}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												kanban_id: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Label
									</Typography>
									<ReferenceInput
										source='tag_id'
										label={''}
										reference='project_tags'
										sort={{ field: 'name', order: 'ASC' }}
										filterToQuery={(searchText) => ({
											name: searchText,
										})}
										filter={{
											name: true,
										}}
										onFocus={() => {
											setIsProjectLabelUniqueViolationError(false);
										}}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												tag_id: event,
											});
										}}
									>
										{/* TODO Need to refactor like passing loader to autocomplete directly */}
										{isProjectLabelsLoading ? (
											<TextInput
												source=''
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CircularProgress
																style={{
																	height: '20px',
																	width: '20px',
																	color: '#4285F4',
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										) : (
											<AutocompleteInput
												resettable={true}
												optionText={projectLabelRenderer}
												shouldRenderSuggestions={(name: string) =>
													name.trim().length > 0
												}
												onCreate={async (event: any) => {
													const projectTagId = await handleProjectLabelCreate(
														event
													);
													if (!isProjectLabelUniqueViolationError) {
														setTimeout(
															() => form.mutators.setProjectLabel(projectTagId),
															100
														);
													}
												}}
											/>
										)}
									</ReferenceInput>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{isProjectLabelUniqueViolationError && (
										<p className={inlineStyle.projectLabelErrorText}>
											Project label already exist!
										</p>
									)}
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Category
									</Typography>
									<ReferenceInput
										source='category_id'
										label={''}
										reference='project_category'
										sort={{ field: 'name', order: 'ASC' }}
										filterToQuery={(searchText) => ({
											name: searchText,
											type: 'project',
										})}
										filter={{
											name: true,
										}}
										onFocus={() => {
											setIsProjectCategoryUniqueViolationError(false);
										}}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												category_id: event,
											});
										}}
									>
										{isProjectCategoriesLoading ? (
											<TextInput
												source=''
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CircularProgress
																style={{
																	height: '20px',
																	width: '20px',
																	color: '#4285F4',
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										) : (
											<AutocompleteInput
												resettable={true}
												optionText={projectCategoryRenderer}
												shouldRenderSuggestions={(name: string) =>
													name.trim().length > 0
												}
												onCreate={async (event: any) => {
													const projectCategoryId =
														await handleProjectCategoryCreate(event);
													if (!isProjectCategoryUniqueViolationError) {
														setTimeout(
															() =>
																form.mutators.setProjectCategory(
																	projectCategoryId
																),
															100
														);
													}
												}}
											/>
										)}
									</ReferenceInput>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{isProjectLabelUniqueViolationError && (
										<p className={inlineStyle.projectLabelErrorText}>
											Project Category already exist!
										</p>
									)}
								</Box>
								<Box className={classes.multipleInput}></Box>
							</Box>

							<Box
								className={classes.multipleInputContainer}
								marginBottom='5px'
							>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Start Date *
									</Typography>
									<Field
										name='start_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true) {
												return undefined;
											}
											if (value === 'Invalid Date') {
												setStartDateFieldError(true);
												setStartDateFieldErrorText('Invalid Date');
												return 'Invalid Date';
											}
											return 'Invalid Date';
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={(value: any) => {
														if (!value) {
															setStartDateFieldError(true);
															setStartDateFieldErrorText('Required');
															return;
														}
														setCurrentFormValues({
															...currentFormValues,
															start_date: value,
														});
														setStartDateError(false);
														setStartDateFieldError(false);
														setStartDateFieldErrorText('');
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{startDateFieldError && (
										<p className={inlineStyle.errorText}>
											{startDateFieldErrorText}
										</p>
									)}
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>End Date *</Typography>
									<Field
										name='end_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true) {
												return undefined;
											}
											if (value === 'Invalid Date') {
												setEndDateFieldError(true);
												setEndDateFieldErrorText('Invalid Date');
												return 'Invalid Date';
											}
											return 'Invalid Date';
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={(value: any) => {
														if (!value) {
															setEndDateFieldError(true);
															setEndDateFieldErrorText('Required');
															return;
														}
														setCurrentFormValues({
															...currentFormValues,
															end_date: value,
														});
														setStartDateError(false);
														setEndDateFieldError(false);
														setEndDateFieldErrorText('');
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{startDateError && (
										<p className={inlineStyle.errorText}>
											Start date must not be greater than end date!
										</p>
									)}
									{endDateFieldError && (
										<p className={inlineStyle.errorText}>
											{endDateFieldErrorText}
										</p>
									)}
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Estimated hours
									</Typography>
									<TextInput
										validate={number()}
										source='effort_estimate'
										label={''}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												effort_estimate: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>Currency</Typography>
									<SelectInput
										choices={currencyList}
										source='currency_id'
										optionText='name'
										label={''}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												currency_id: event.target.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Box className={classes.label}>
										Contract Value
										<Tooltip title='Actual contract value' placement='right'>
											<IconButton className={inlineStyle.iconContainer}>
												<InfoIcon className={inlineStyle.icon} />
											</IconButton>
										</Tooltip>
									</Box>
									<Box display='flex'>
										<Box className={currencyStyle.selectCurrency}>
											<ThemeProvider theme={currencySelectBox}>
												<SelectInput
													choices={currencyList}
													source='currency_id'
													optionText='name'
													label={''}
													disabled={true}
												/>
											</ThemeProvider>
										</Box>
										<Box marginTop='7px'>
											<TextInput
												validate={number()}
												source='revenue'
												label={''}
												onChange={(event) => {
													setCurrentFormValues({
														...currentFormValues,
														revenue: event.target.value,
													});
												}}
											/>
										</Box>
									</Box>
								</Box>
								<Box className={classes.multipleInput}>
									<Box className={classes.label}>
										Budget
										<Tooltip
											title='Budget allocated to the project'
											placement='right'
										>
											<IconButton className={inlineStyle.iconContainer}>
												<InfoIcon className={inlineStyle.icon} />
											</IconButton>
										</Tooltip>
									</Box>
									<Box display='flex'>
										<Box className={currencyStyle.selectCurrency}>
											<ThemeProvider theme={currencySelectBox}>
												<SelectInput
													choices={currencyList}
													source='currency_id'
													optionText='name'
													label={''}
													disabled={true}
												/>
											</ThemeProvider>
										</Box>
										<Box marginTop='7px'>
											<TextInput
												validate={number()}
												source='total_cost'
												label={''}
												onChange={(event) => {
													setCurrentFormValues({
														...currentFormValues,
														total_cost: event.target.value,
													});
												}}
											/>
										</Box>
									</Box>
								</Box>
							</Box>

							{projectCustomField?.custom_field?.length > 0 &&
								projectCustomField?.custom_field?.map(
									(
										customField: {
											index: any;
											label: any;
											options: any;
											type: any;
										},
										index: React.Key | null | undefined
									) => (
										<Box key={index}>
											<CustomField
												name={`custom_field_${customField.index}`}
												label={customField.label}
												option={customField.options}
												dateFormat={dateFormat}
												fieldType={customField.type}
												onChange={() => handleChange}
												value={optionValue!}
											/>
										</Box>
									)
								)}

							<Typography className={classes.label}>
								Project Overview
							</Typography>
							<TextInput
								source='description'
								label=''
								multiline={true}
								onChange={(event) => {
									setCurrentFormValues({
										...currentFormValues,
										description: event.target.value,
									});
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Box
								className={classes.buttonContainer}
								marginRight='22px'
								marginTop='20px'
							>
								<Button onClick={handleClose} className={classes.cancelButton}>
									Cancel
								</Button>
								<Button
									className={
										invalid || startDateFieldError || endDateFieldError
											? classes.disabledButton
											: classes.saveButton
									}
									disabled={invalid || startDateFieldError || endDateFieldError}
									type='submit'
								>
									Next
								</Button>
							</Box>
						</DialogActions>
					</Box>
				</form>
			)}
		</Form>
	);

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeProjectForm}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box
					className={
						!isAddCompanyFormShown
							? inlineStyle.detailContainer
							: inlineStyle.detailsContainer
					}
				>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{!isAddCompanyFormShown ? 'Clone Project' : 'Add Company'}
						</Typography>
						<Box marginRight={isAddCompanyFormShown ? '0' : '22px'}>
							<CloseIcon
								className={classes.closeIcon}
								onClick={
									isAddCompanyFormShown ? HandleCompanyClose : handleClose
								}
							/>
						</Box>
					</Box>
					{!isAddCompanyFormShown ? (
						formContent()
					) : (
						<AddCompany
							onClose={HandleCompanyClose}
							companyName={companyName}
							setCompanyId={(id) => {
								setProjectFormInitialValues({
									...projectFormInitialValues,
									name: project?.name || projectName,
									company_id: id,
									prefix: prefixes,
								});
								setCurrentFormValues({
									...currentFormValues,
									name: project?.name || projectName,
									company_id: id,
								});
							}}
						/>
					)}
				</Box>
			</Dialog>
			<ProjectCloneModal
				open={isCloneModalShown}
				onClose={() => setIsCloneModalShown(false)}
				handleSubmit={(val) => onConfirmClone(val)}
			/>
		</ThemeProvider>
	);
};

export default ProjectClone;
