import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import { onError } from 'apollo-link-error';

export const logInUrl = `${process.env.REACT_APP_REST_API_URL}/auth`;
const wsUrl = process.env.REACT_APP_GRAPHQL_URL.replace('https', 'wss');
const httpUrl = process.env.REACT_APP_GRAPHQL_URL;
const tokenExpirationHandlerLink = onError(
	({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, extensions }) => {
				if (
					extensions.code === 'invalid-jwt' ||
					extensions.code === 'access-denied'
				) {
					localStorage.clear();
					window.location = '/';
				}
			});
		if (networkError) console.log(`[Network error]: ${networkError}`);
	}
);

const wsLink = new WebSocketLink({
	uri: wsUrl,
	options: {
		reconnect: true,
		connectionParams: {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		},
	},
});
const httpLink = createHttpLink({
	uri: ({ operationName }) => {
		// following name url param is just to identify specific request in debugger network tab, server won't use it
		return `${httpUrl}?name=${operationName}`;
	},
	headers: {
		Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
	},
});
// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink
);
export const CreateApolloClient = () =>
	new ApolloClient({
		link: ApolloLink.from([tokenExpirationHandlerLink, splitLink]),
		cache: new InMemoryCache(),
		credentials: 'include',
	});
