import React, { useState } from 'react';

import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';

import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../../settingsStyle';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import DesignationForm from './DesignationForm.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import { searchBarTheme } from '../../../../Layout/Theme.component';

interface Designation {
	id?: string;
	title?: string;
	description?: string;
	org_id?: string;
}

const DesignationList = () => {
	const [isDesignationFormShown, setIsDesignationFormShown] = useState(false);
	const [editDesignationDetails, setEditDesignationDetails] =
		useState<Designation>({});
	const [isDesignationEdit, setIsDesignationEdit] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [choosedDepartment, setChoosedDepartment] = useState(null);
	const [searchDesignation, setSearchDesignation] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const onEditDesignation = (designation: Designation) => {
		if (!designation) {
			return;
		}
		const { org_id, ...restDepartment } = designation;
		setEditDesignationDetails({ ...restDepartment });
		setIsDesignationEdit(true);
		setIsDesignationFormShown(true);
	};
	const deleteDepartment = () => {
		mutate(
			{
				type: 'delete',
				resource: 'designation',
				payload: {
					id: choosedDepartment,
				},
			},
			{
				onSuccess: () => {
					notify('Designation deleted Successfully');
					refresh();
					setIsDeleteModalShown(false);
					setChoosedDepartment(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};
	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Designation'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchDesignation(e?.target?.value);
								}}
								value={searchDesignation}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					style={{ width: '130px' }}
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsDesignationFormShown(true);
						setIsDesignationEdit(false);
					}}
				>
					Add Designation
				</Button>
			</Box>
			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='designation'
					basePath='/designation'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'title', order: 'ASC' }}
					filter={{ title: searchDesignation }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='TITLE'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.title}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.title}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(shift: any) => {
								return (
									<Tooltip
										title={`${shift.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(department: any) => (
								<Box>
									<PopOver
										edit={() => onEditDesignation(department)}
										remove={() => {
											setIsDeleteModalShown(true);
											setChoosedDepartment(department?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<DesignationForm
				open={isDesignationFormShown}
				onClose={() => setIsDesignationFormShown(false)}
				refetch={() => refresh()}
				initialValues={editDesignationDetails}
				isEditMutation={isDesignationEdit}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deleteDepartment}
			/>
		</>
	);
};

export default DesignationList;
