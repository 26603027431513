import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import GetAppIcon from '@material-ui/icons/GetApp';

import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { uploadData } from '../../../Api/restApi.config';
import { preventSubsequentClick } from '../../../Utils/string.util';
import { dataUploadStyles } from '../../Settings/Datamanagement/dataUpload.style';
import SettingsDetailContainer from '../../Settings/SettingsDetailContainer.component';
import {
	EXCEL_TEMPLATE_DOWNLOAD_CAPTION,
	PROJECT_CLONE_DATA_UPLOAD_TEMPLATE_URL,
} from '../../Settings/constant';
import {
	PROJECT_CLONE_DATA_UPLOAD_CAPTION,
	PROJECT_CLONE_DATA_UPLOAD_MODAL_TEXT,
} from '../../../SharedComponents/constant';

const styles = makeStyles({
	customRoot: {
		minHeight: '220px',
	},
	header: {
		width: '100%',
		display: 'flex',
		padding: '0.5rem 1rem 0 0.5rem',
		alignItems: 'center',
		justifyContent: 'end',
	},
	closeIcon: {
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	wrapper: {
		margin: '0.3rem 1rem 1rem 0',
		padding: '0 1rem 0 0',
	},
});

interface Props {
	open: boolean;
	projectId: string;
	onClose: () => void;
	onSuccess: () => void;
}

interface ProjectCloneDataUploadApiResponse {
	isCompleted: boolean;
	isError: boolean;
	response: string[];
}

const ProjectCloneDailogModal = (props: Props) => {
	const { onClose, onSuccess, open, projectId } = props;

	const classes = styles();
	const taskDataUploadStyle = dataUploadStyles();

	const [uploadResponse, setUploadResponse] =
		useState<ProjectCloneDataUploadApiResponse>({
			isCompleted: false,
			isError: false,
			response: [],
		});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingState, setLoadingState] = useState<boolean>(false);
	const notify = useNotify();

	const handleFileUploadAlert = (message: string, variant: string) => {
		if (!message || !variant) {
			return;
		}
		if (variant === 'error') {
			setUploadResponse({
				isCompleted: true,
				isError: true,
				response: [message],
			});
		}
	};

	const disableButton = () => {
		setLoadingState(false);
	};

	const preventClick = preventSubsequentClick(disableButton, 1000);

	const handleTemplateDownload = () => {
		setLoadingState(true);
		let link = document.createElement('a');
		link.download = 'project_clone_data_upload_template';
		link.href = PROJECT_CLONE_DATA_UPLOAD_TEMPLATE_URL;
		preventClick();
		link.click();
	};

	const handleDataUpload = async (file: any) => {
		if (!file) {
			return;
		}
		setIsLoading(true);
		let data = new FormData();
		data.append('file', file);
		data.append('type', 'projectClone');
		data.append('projectId', projectId);

		try {
			const res = await uploadData(data);
			if (res.data.error) {
				setUploadResponse({
					isError: true,
					isCompleted: true,
					response: res.data.message || [''],
				});
				setIsLoading(false);
				return;
			}
			if (res.data.error === false && res.data.message.length > 0) {
				setUploadResponse({
					isError: false,
					isCompleted: true,
					response: res.data.message,
				});
				onClose();
				onSuccess();
				setIsLoading(false);
				notify(res.data.message[0]);
				return;
			}
			setUploadResponse({
				isError: false,
				isCompleted: true,
				response: res.data.message || [''],
			});
			onClose();
			setIsLoading(false);
			notify('Data Upload Success');
		} catch (err) {
			console.error(err);
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			disableBackdropClick
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth='sm'
		>
			<Box className={classes.header}>
				<CloseIcon className={classes.closeIcon} onClick={() => onClose()} />
			</Box>
			<Box className={classes.wrapper}>
				<Backdrop className={taskDataUploadStyle.backdrop} open={isLoading}>
					<CircularProgress color='inherit' />
				</Backdrop>

				<SettingsDetailContainer heading={PROJECT_CLONE_DATA_UPLOAD_CAPTION}>
					<div className={taskDataUploadStyle.fileTemplateDownloadContainer}>
						<div className={taskDataUploadStyle.description}>
							{EXCEL_TEMPLATE_DOWNLOAD_CAPTION}
						</div>
						<div className={taskDataUploadStyle.fileDownloadButtonContainer}>
							<Button
								className={taskDataUploadStyle.fileDownloadButton}
								label='Download Project Clone Template'
								endIcon={<GetAppIcon />}
								variant='contained'
								disabled={loadingState}
								onClick={handleTemplateDownload}
								startIcon={
									loadingState && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#FFFFFF',
											}}
										/>
									)
								}
							/>
						</div>

						<p className={taskDataUploadStyle.heading}>Data Upload</p>

						<div className={taskDataUploadStyle.fileUploadContainer}>
							<DropzoneArea
								acceptedFiles={[
									'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
								]}
								initialFiles={[]}
								maxFileSize={5242880}
								showPreviewsInDropzone={false}
								filesLimit={1}
								dropzoneText={PROJECT_CLONE_DATA_UPLOAD_MODAL_TEXT}
								onChange={(files) => {
									setUploadResponse({
										isCompleted: false,
										isError: false,
										response: [''],
									});
									handleDataUpload(files[0]);
								}}
								classes={{
									root: classes.customRoot,
									text: taskDataUploadStyle.dropZoneAreaText,
									icon: taskDataUploadStyle.uploadIcon,
								}}
								showAlerts={false}
								onAlert={handleFileUploadAlert}
							/>
						</div>

						{uploadResponse.isCompleted && (
							<div className={taskDataUploadStyle.logContainerSmall}>
								{uploadResponse?.response?.length > 0 &&
									uploadResponse?.response?.map((dataUploadResponse, index) => (
										<p
											className={
												uploadResponse.isError
													? taskDataUploadStyle.errorText
													: taskDataUploadStyle.successText
											}
											key={index}
										>
											{dataUploadResponse}
										</p>
									))}
							</div>
						)}
					</div>
				</SettingsDetailContainer>
			</Box>
		</Dialog>
	);
};

export default ProjectCloneDailogModal;
