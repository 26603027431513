import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import ProjectMilestoneForm from './ProjectMilestoneForm.component';
import ProjectMilestoneDelete from './ProjectMilestoneDelete.component';
import { PROJECT_MILESTONE_BY_PK } from '../gqlQueries';
import { DropdownOption } from '../../../SharedComponents/model';

const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
}));

interface Validation {
	projectStartdate: string;
	projectEnddate: string;
	revenue: string;
}

interface Props {
	milestoneId : string;
	paymentStatusList : DropdownOption[];
	milestoneStatusList : DropdownOption[];
	currencyId : string;
	validation : Validation
	milestoneAmount : number,
	refetch : ()=>void;
}



export const ProjectMilestonePopOver = (props: Props) => {
	const { milestoneId,milestoneAmount,refetch,paymentStatusList,milestoneStatusList,currencyId,validation } = props;
	const [isProjectMilestoneModal, setIsProjectMilestoneModal] = useState(
		false
	);
	const [anchorEl, setAnchorEl] = React.useState(null);
	 const [projectMilestoneForm, setProjectMilestoneForm] = useState({});
	const [
		isProjectMilestoneDeleteModalShown,
		setIsProjectMilestoneDeleteModalShown,
	] = useState(false);

	const { data: projectMilestone, refetch:refetchMilestone } = ApolloUseQuery(PROJECT_MILESTONE_BY_PK, {
		variables: {
			projectMilestoneId: milestoneId || null,
		},
	});


	useEffect(()=>{
		if(!projectMilestone || !(projectMilestone?.project_milestone_by_pk)) {
			return;
		}
		setProjectMilestoneForm({
			name : projectMilestone.project_milestone_by_pk?.name,
			end_date : projectMilestone.project_milestone_by_pk?.end_date,
			id : projectMilestone.project_milestone_by_pk?.id,
			link_to_invoice : projectMilestone.project_milestone_by_pk?.link_to_invoice,
			payment_status_id : projectMilestone.project_milestone_by_pk?.payment_status_id,
			start_date : projectMilestone.project_milestone_by_pk?.start_date,
			status :  projectMilestone.project_milestone_by_pk?.status,
			amount : projectMilestone.project_milestone_by_pk?.amount,
			currency_id : currencyId
		})
	},[projectMilestone,currencyId])

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
		setIsProjectMilestoneModal(false);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem onClick={() => setIsProjectMilestoneModal(true)}>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
				<MenuItem onClick={() => setIsProjectMilestoneDeleteModalShown(true)}>
					<DeleteIcon className={classes.deleteIcon} />
					<Box className={classes.editText}>Delete</Box>
				</MenuItem>
			</Menu>
			<ProjectMilestoneForm
				open={isProjectMilestoneModal}
				milestoneAmount={milestoneAmount}
				onClose={() => setIsProjectMilestoneModal(false)}
				milestone={projectMilestoneForm}
				paymentStatusList={paymentStatusList}
				milestoneStatusList={milestoneStatusList}
				validation={validation}
				refetch={() => {
					refetchMilestone()
					refetch()
				}}
			/>
			<ProjectMilestoneDelete 
				open={isProjectMilestoneDeleteModalShown}
				onClose={()=>setIsProjectMilestoneDeleteModalShown(false)}
				milestoneId={milestoneId}
				onDelete={refetch}
			/>
		</div>
	);
};

export default ProjectMilestonePopOver;
