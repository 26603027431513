import gql from 'graphql-tag';
import { MASTER_DATA_TYPE } from '../../constant';

export const GET_ORG_TIMEZONE_OPTIONS = gql`
	query getOrganisationTimezoneOptions {
		master_settings(where: { name: { _eq: ${MASTER_DATA_TYPE.TIMEZONE_OPTIONS} } }) {
			value
		}
	}
`;
