import React, { useState, useEffect } from "react";
import { Loading, useQuery } from "react-admin";
import Link from "@material-ui/core/Link";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { endDateValidation } from "../../Utils/date-time.util";

import {
  SimpleForm,
  required,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  useMutation,
  DateInput,
  email,
  regex,
  useNotify,
} from "react-admin";
const useStyles = makeStyles({
  title: {
    fontSize: "1.25rem",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "15%",
  },
  buttonCursor: {
    cursor: "pointer",
  },
  container: {
    width: "100%",
  },
  RaFormInput: {
    width: "100%",
  },
  radioButton: {
    textAlign: "end",
    marginTop: "1rem"
  },
  docLink: {
    wordWrap: "break-word",
    padding: "1rem",
    width: "100%",
    background: "lightgrey",
    fontSize: "1rem",
    margin: "auto"
  },
});
export const ProjectSoW = ({ projectSoW, customerId, projectId }) => {
  const [selectedSoW, setSelectedSoW] = useState(null);
  const [selectExistingSoW, setSelectExistingSoW] = useState(null);
  const [sow, setSoW] = useState(null);
  const [toggleStatus, setToggleStatus] = useState("new");
  const validateEndDate =[required(), endDateValidation];

  const classes = useStyles();
  const [mutate] = useMutation();
  const notify = useNotify();
  const { data: SoW, loading } = useQuery({
    type: "GET_LIST",
    resource: "sow_master",
    payload: {
      sort: { field: "id", order: "ASC" },
      filter: { id: projectSoW ? projectSoW : "" },
    },
  });

  const { data: duplicatedSOW } = useQuery({
    type: "GET_LIST",
    resource: "sow_master",
    payload: {
      filter: {
        sow_id: sow?.sowId,
        sow_doc_link: sow?.sowLink,
        bd_owner_email: sow?.bdOwnerEmail,
        customer_contact_email: sow?.customerContactEmail,
        start_date: sow?.startDate,
        end_date: sow?.endDate,
        customer_id: customerId,
      },
    },
  });

  const validateReminder = [
    regex(/\b(0?[1-9]|[12][0-9]|3[01])\b/, "Must be in the limit 1 to 31 days"),
  ];
  const createFormatInput = (value) => {
    return value;
  };
  const onStatusChange = (event) => {
    setToggleStatus(event.target.value);
  };

  const SaveExistingSoW = () => (
    <Box className={classes.buttonStyle}>
      <Box>
        <Button
          variant="contained"
          className={classes.buttonCursor}
          onClick={updateProjectSoW}
          color="primary"
          disabled ={ !(selectExistingSoW)}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );

  const SaveNewSoW = () => (
    <Box className={classes.buttonStyle}>
      <Box>
        <Button
          variant="contained"
          className={classes.buttonCursor}
          onClick={createNewSOW}
          color="primary"
          disabled ={ !(sow?.sowId && sow?.customerContactEmail && sow?.preSalesOwnerEmail && sow?.bdOwnerEmail && sow?.sowLink)}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );

  const updateProjectSoW = (event) => {
    mutate(
      {
        type: "update",
        resource: "project",
        payload: {
          id: projectId,
          data: {
            sow_id: selectExistingSoW,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Sow Updated");
        },
      }
    );
    return;
  };

  const createNewSOW = (event) => {
    if (duplicatedSOW.length > 0) {
      setSoW(null)
      notify('Failed to create a duplicate record', 'warning')
      return
    }
    mutate(
      {
        type: "create",
        resource: "sow_master",
        payload: {
          data: {
            sow_id: sow?.sowId,
            msa_id: sow?.msaId,
            sow_doc_link: sow?.sowLink,
            bd_owner_email: sow?.bdOwnerEmail,
            customer_contact_email: sow?.customerContactEmail,
            pre_sales_owner_email: sow?.preSalesOwnerEmail,
            remind_before: sow?.remindMe,
            customer_contact_name: sow?.customerContactName,
            start_date: sow?.startDate,
            end_date: sow?.endDate,
            customer_id: customerId,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Sow created");
          updateProject(data.id);
        },
      }
    );
    return;
  };

  const updateProject = (id) => {
    mutate(
      {
        type: "update",
        resource: "project",
        payload: {
          id: projectId,
          data: {
            sow_id: id,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Project sow updated");
        },
      }
    );
    return;
  };

  useEffect(() => {
    if (SoW && projectSoW) {
      const formattedSoW = SoW[0].sow_doc_link;
      setSelectedSoW(formattedSoW);
    }
  }, [SoW, projectSoW]);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box className={classes.docLink}>
        <Typography>
          {selectedSoW ? (
            <>
              <Typography>
                Doc Link:
              </Typography>
              <Link target='_blank' href={`${selectedSoW}`}>{selectedSoW}</Link>
            </>
          ) : null}
        </Typography>
      </Box>
      <Box className={classes.radioButton}>
        <FormControl component="fieldset">
          <RadioGroup value={toggleStatus} onChange={onStatusChange}>
            <Box display="flex">
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="Add new SOW"
              />
              <FormControlLabel
                value="existing"
                control={<Radio />}
                label="Choose existing SOW"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      {toggleStatus === "new" ? (
        <>
          <Box>
            <SimpleForm className={classes.container} toolbar={<SaveNewSoW />}>
              <Box display="flex" className={classes.RaFormInput}>
                <Box mr="0.5em" width="50%">
                  <TextInput
                    source="sow_id"
                    fullWidth
                    label="SoW ID"
                    validate={required()}
                    onChange={(event) =>
                      setSoW({ ...sow, sowId: event.target.value })
                    }
                  />
                </Box>
                <Box width="50%">
                  <DateInput
                    fullWidth
                    source="start_date"
                    onChange={(event) =>
                      setSoW({ ...sow, startDate: event.target.value })
                    }
                  />
                </Box>
                <Box width="50%" ml="0.5em">
                  <DateInput
                    fullWidth
                    source="end_date"
                    onChange={(event) =>
                      setSoW({ ...sow, endDate: event.target.value })
                    }
                    validate={validateEndDate}
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.RaFormInput}>
                <Box mr="0.5em" width="50%">
                  <TextInput
                    fullWidth
                    source="customer_contact_name"
                    onChange={(event) =>
                      setSoW({
                        ...sow,
                        customerContactName: event.target.value,
                      })
                    }
                  />
                </Box>
                <Box width="50%">
                  <TextInput
                    fullWidth
                    source="customer_contact_email"
                    label="Contact Email"
                    onChange={(event) =>
                      setSoW({
                        ...sow,
                        customerContactEmail: event.target.value,
                      })
                    }
                    validate={[required(), email()]}
                  />
                </Box>
                <Box width="50%" ml="0.5em">
                  <ReferenceInput
                    source="msa_id"
                    reference="msa_master"
                    filterToQuery={(searchText) => ({ msa_id: searchText })}
                    filter={{ msa_id: true }}
                    onChange={(event) => setSoW({ ...sow, msaId: event })}
                  >
                    <AutocompleteInput
                      optionText="msa_id"
                      fullWidth
                      shouldRenderSuggestions={(name) => {
                        return name.trim().length > 0;
                      }}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
              <Box display="flex" className={classes.RaFormInput}>
                <Box mr="0.5em" width="50%">
                  <ReferenceInput
                    source="pre_sales_owner_email"
                    label="Pre sales owner"
                    reference="user"
                    filterToQuery={(searchText) => ({ email: searchText })}
                    filter={{ email: true }}
                    onChange={(event) =>
                      setSoW({ ...sow, preSalesOwnerEmail: event })
                    }
                    validate={[required()]}
                  >
                    <AutocompleteInput
                      optionText="email"
                      shouldRenderSuggestions={(name) => {
                        return name.trim().length > 0;
                      }}
                      fullWidth
                    />
                  </ReferenceInput>
                </Box>
                <Box width="50%">
                  <ReferenceInput
                    source="bd_owner_email"
                    label="BD Owner Email"
                    reference="user"
                    filterToQuery={(searchText) => ({ email: searchText })}
                    filter={{ email: true }}
                    onChange={(event) =>
                      setSoW({ ...sow, bdOwnerEmail: event })
                    }
                    validate={[required()]}
                  >
                    <AutocompleteInput
                      optionText="email"
                      shouldRenderSuggestions={(name) => {
                        return name.trim().length > 0;
                      }}
                      fullWidth
                    />
                  </ReferenceInput>
                </Box>
                <Box ml="0.5em" width="50%">
                  <TextInput
                    source="remind_before"
                    label="Reminder (In Days)"
                    format={createFormatInput}
                    validate={validateReminder}
                    onChange={(event) =>
                      setSoW({ ...sow, remindMe: event.target.value })
                    }
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.RaFormInput}>
                <Box width="150%">
                  <TextInput
                    fullWidth
                    source="sow_doc_link"
                    label="Doc Link"
                    validate={[
                      required(),
                      regex(
                        /^(ftp|http|https):\/\/[^ "]+$/,
                        "Must be a valid Link"
                      ),
                    ]}
                    onChange={(event) =>
                      setSoW({ ...sow, sowLink: event.target.value })
                    }
                  />
                </Box>
                {/* <Box width="50%" ml="0.5em"></Box> */}
                {/* <Box width="50%" ml="0.5em"></Box> */}
              </Box>
            </SimpleForm>
          </Box>
        </>
      ) : (
        <>
          <SimpleForm
            className={classes.container}
            toolbar={<SaveExistingSoW />}
          >
            <Box display="flex" className={classes.RaFormInput}>
              <Box mr="0.5em" width="50%">
                <ReferenceInput
                  source="sow_id"
                  label="Sow Id"
                  reference="sow_master"
                  filterToQuery={(searchText) => ({ sow_id: searchText })}
                  filter={{ sow_id: true, customer_id: customerId }}
                  onChange={(event) => setSelectExistingSoW(event)}
                  validate={[required()]}
                >
                  <AutocompleteInput
                    optionText="sow_id"
                    shouldRenderSuggestions={(name) => {
                      return name.trim().length > 0;
                    }}
                    fullWidth
                  />
                </ReferenceInput>
              </Box>
            </Box>
          </SimpleForm>
        </>
      )}
    </>
  );
};
export default ProjectSoW;
