import React, { useEffect, useState, useContext } from 'react';
import { FunctionField, setSidebarVisibility } from 'react-admin';
import MUIDataTable from 'mui-datatables';

import DetailContainer from '../../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Box,
	ThemeProvider,
	TableBody,
	TableCell,
	TableRow,
	TableContainer,
	Paper,
	Table,
	TableHead,
	Card,
	Tooltip,
} from '@material-ui/core';
import EmployeeBankDetailsEditForm from './EmployeeBankDetailsEditForm.component';
import editIcon from '../../../assets/icons/edit-icon.svg';
import { UserProfileContext } from '../../../App';
import { head } from 'lodash';

import {
	subTaskTableTheme,
	CTCTableTheme,
} from '../../ProjectTask/dataGridThemes';
import CtcForm from './CtcForm.component';
import {
	useGetCtcAuditDetailsQuery,
	useGetOrgMasterCurrenciesQuery,
	useGetUserCtcQuery,
} from '../../../generated/graphql';

import dayjs from 'dayjs';
import { formatDecimal, isFloat } from '../../../Utils/string.util';
import { useDispatch } from 'react-redux';

const taskListStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		marginLeft: '10px',
		maxHeight: '200px',
	},
	table: {
		minWidth: '350px',
	},
	tr: {
		background: '#FFFFFF',
	},
}));

const useStyles = makeStyles((theme) => ({
	label: {
		textAlign: 'left',
		font: 'normal normal bold 10px/25px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '10px !important',
		textTransform: 'capitalize',
	},
	value: {
		textAlign: 'left',
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: ' #5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	editIcon: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
		paddingTop: '3px',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	editIconCTC: {
		width: '12px',
		height: '12px',
	},
	editContainer: {
		display: 'flex',
		cursor: 'pointer',
		width: '100px',
		height: '17px',
		fontFamily: 'Manrope-normal',
		color: '#4285F4',
		opacity: '1',
		marginBottom: '20px',
	},
	editButton: {
		width: '10px',
		height: '10px',
		marginTop: '6px',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		marginRight: '-70px',
		width: '70px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
	},
	heading: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		alignContent: 'center',
	},
	listDeleteIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	notes: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	noData: {
		marginTop: '8px',
		fontSize: '12px',
	},
	ctcHeading: {
		marginLeft: '25px',
		marginRight: '725px',
		marginBottom: '5px',
		marginTop: '5px',
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		alignContent: 'center',
	},
	bankDetails: {
		alignContent: 'center',
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		marginLeft: '25px',
		marginTop: '10px',
	},
}));
export const EmployeeBankDetails = (props) => {
	const { record: employee, refetch: refetchEmployee } = props;
	const { dateFormat } = useContext(UserProfileContext);
	const [openEditModal, setOpenEditModal] = useState(false);
	const classes = useStyles();
	const { permissions: permissionList } = useContext(UserProfileContext);
	const taskListStyles = taskListStyle();
	const [form, setForm] = useState(false);
	const dispatch = useDispatch();

	const { data: ctcAudit, refetch: refetchAuditCtc } =
		useGetCtcAuditDetailsQuery({
			variables: {
				userId: employee?.user?.id,
			},
			fetchPolicy: 'network-only',
		});

	const { data: userCtc, refetch: refetchCtc } = useGetUserCtcQuery({
		variables: {
			userId: employee?.user?.id,
		},
		fetchPolicy: 'network-only',
	});

	const { data: currencySymbols } = useGetOrgMasterCurrenciesQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const ctcData = [
		[
			head(userCtc?.ctc) && head(userCtc?.ctc).fixed !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.fixed)
							? formatDecimal(head(userCtc?.ctc)?.fixed, 2)
							: head(userCtc?.ctc)?.fixed
				  }`
				: '--',
			head(userCtc?.ctc) && head(userCtc?.ctc)?.variable !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.variable)
							? formatDecimal(head(userCtc?.ctc)?.variable, 2)
							: head(userCtc?.ctc)?.variable
				  }`
				: '--',
			head(userCtc?.ctc)
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.variable + head(userCtc?.ctc)?.fixed)
							? formatDecimal(
									head(userCtc?.ctc)?.variable + head(userCtc?.ctc)?.fixed,
									2
							  )
							: head(userCtc?.ctc)?.variable + head(userCtc?.ctc)?.fixed
				  }`
				: '--',

			head(userCtc?.ctc) && head(userCtc?.ctc)?.joining_bonus !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.joining_bonus)
							? formatDecimal(head(userCtc?.ctc)?.joining_bonus, 2)
							: head(userCtc?.ctc)?.joining_bonus
				  }`
				: '--',

			head(userCtc?.ctc) && head(userCtc?.ctc)?.relocation_amount !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.relocation_amount)
							? formatDecimal(head(userCtc?.ctc)?.relocation_amount, 2)
							: head(userCtc?.ctc)?.relocation_amount
				  }`
				: '--',

			head(userCtc?.ctc) && head(userCtc?.ctc)?.net !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.net)
							? formatDecimal(head(userCtc?.ctc)?.net, 2)
							: head(userCtc?.ctc)?.net
				  }`
				: '--',

			head(userCtc?.ctc) && head(userCtc?.ctc)?.gross_1 !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.gross_1)
							? formatDecimal(head(userCtc?.ctc)?.gross_1, 2)
							: head(userCtc?.ctc)?.gross_1
				  }`
				: '--',

			head(userCtc?.ctc) && head(userCtc?.ctc)?.gross_2 !== null
				? ` ${head(userCtc?.ctc)?.master_currency?.symbol} ${
						isFloat(head(userCtc?.ctc)?.gross_2)
							? formatDecimal(head(userCtc?.ctc)?.gross_2, 2)
							: head(userCtc?.ctc)?.gross_2
				  }`
				: '--',

			head(userCtc?.ctc)
				? dayjs(head(userCtc?.ctc)?.updated_at).format(dateFormat)
				: '--',

			head(userCtc?.ctc)?.updatedBy?.full_name || '--',

			head(userCtc?.ctc)?.notes ? head(userCtc?.ctc)?.notes : '--',
		],
	];

	const columns = [
		{
			name: 'Fixed',
			label: 'FIXED',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Variable',
			label: 'VARIABLE',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'Ctc',
			label: 'CTC',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'Joining Bonus',
			label: 'JOINING BONUS',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Relocation Amount',
			label: 'RELOCATION AMOUNT',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Net',
			label: 'NET',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Gross 1',
			label: 'GROSS 1',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Gross 2',
			label: 'GROSS 2',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Updated Date',
			label: 'UPDATED DATE',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'Updated By',
			label: 'UPDATED BY',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'Notes',
			label: 'NOTES',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${classes.notes} ${classes.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
	];

	const options = {
		filter: false,
		filterType: false,
		responsive: 'standard',
		downloadOptions: false,
		print: false,
		searchAlwaysOpen: false,
		search: false,
		serverSide: true,
		viewColumns: false,
		download: false,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		columnHeaderHeight: 0,
		pagination: false,
		filterTable: false,

		scrollY: 100,
		// scrollX: 10,
		scrollCollapse: true,
		onFilterChange: false,
		// fixedHeader: true,
		tableBodyHeight: '100%',
		setCellProps: () => ({
			style: {
				minWidth: '100px',
				maxWidth: '500px',
				alignitems: 'end',
				justifyContent: 'end',
				display: 'flex',
			},
		}),
		textLabels: {
			body: {
				noMatch: 'No data found',
				pagination: {
					displayRows: 'off',
				},
			},
		},

		renderExpandableRow: (rowData, rowMeta) => {
			const colSpan = rowData.length;
			return (
				<>
					<tr className={taskListStyles.tr}>
						<td colSpan={12}>
							<ThemeProvider theme={subTaskTableTheme}>
								<Box
									width='90rem'
									display='flex'
									alignItems='center'
									justifyContent='center'
									marginBottom='20px'
									marginTop='20px'
									sx={{ width: '100%', overflow: 'hidden' }}
								>
									<TableContainer
										sx={{ maxHeight: 440 }}
										component={Paper}
										className={taskListStyles.tableContainer}
									>
										<Table className={taskListStyles.table} stickyHeader>
											<TableHead className={classes.heading}>
												History
												<TableRow colSpan={colSpan} style={{ height: '35px' }}>
													<TableCell align='left'>Fixed</TableCell>
													<TableCell align='left'>Variable</TableCell>
													<TableCell align='left'>Ctc</TableCell>
													<TableCell align='left'>Joining Bonus</TableCell>
													<TableCell align='left'>Relocation Amount</TableCell>
													<TableCell align='left'>Net</TableCell>
													<TableCell align='left'>Gross_1</TableCell>
													<TableCell align='left'>Gross_2</TableCell>
													<TableCell align='left'>Updated Date</TableCell>
													<TableCell align='left'>Updated By</TableCell>
													<TableCell align='left'>Notes</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{ctcAudit && ctcAudit?.audit_audit_ctc?.length > 0 ? (
													ctcAudit?.audit_audit_ctc?.map((value) => {
														const dataSymbol =
															currencySymbols?.master_currencies
																?.filter(
																	(id) =>
																		id?.id === value?.updated_data?.currency_id
																)
																.map((value) => {
																	return value?.symbol;
																});

														return (
															<TableRow colSpan={colSpan}>
																<TableCell component='th' scope='row'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.fixed !== null
																				? `${dataSymbol} ${
																						isFloat(value?.updated_data?.fixed)
																							? formatDecimal(
																									value?.updated_data?.fixed,
																									2
																							  )
																							: value?.updated_data?.fixed
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.fixed !== null
																				? `${dataSymbol} ${
																						isFloat(value?.updated_data?.fixed)
																							? formatDecimal(
																									value?.updated_data?.fixed,
																									2
																							  )
																							: value?.updated_data?.fixed
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.variable !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.variable
																						)
																							? formatDecimal(
																									value?.updated_data?.variable,
																									2
																							  )
																							: value?.updated_data?.variable
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.variable !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.variable
																						)
																							? formatDecimal(
																									value?.updated_data?.variable,
																									2
																							  )
																							: value?.updated_data?.variable
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.variable +
																								value?.updated_data?.fixed
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.variable +
																										value?.updated_data?.fixed,
																									2
																							  )
																							: value?.updated_data?.variable +
																							  value?.updated_data?.fixed
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.variable !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.variable +
																								value?.updated_data?.fixed
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.variable +
																										value?.updated_data?.fixed,
																									2
																							  )
																							: value?.updated_data?.variable +
																							  value?.updated_data?.fixed
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.joining_bonus !==
																				null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.joining_bonus
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.joining_bonus,
																									2
																							  )
																							: value?.updated_data
																									?.joining_bonus
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.joining_bonus !==
																				null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.joining_bonus
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.joining_bonus,
																									2
																							  )
																							: value?.updated_data
																									?.joining_bonus
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.relocation_amount !==
																				null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data
																								?.relocation_amount
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.relocation_amount,
																									2
																							  )
																							: value?.updated_data
																									?.relocation_amount
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.relocation_amount !==
																				null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data
																								?.relocation_amount
																						)
																							? formatDecimal(
																									value?.updated_data
																										?.relocation_amount,
																									2
																							  )
																							: value?.updated_data
																									?.relocation_amount
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.net !== null
																				? `${dataSymbol} ${
																						isFloat(value?.updated_data?.net)
																							? formatDecimal(
																									value?.updated_data?.net,
																									2
																							  )
																							: value?.updated_data?.net
																				  }
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.net !== null
																				? `${dataSymbol} ${
																						isFloat(value?.updated_data?.net)
																							? formatDecimal(
																									value?.updated_data?.net,
																									2
																							  )
																							: value?.updated_data?.net
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.gross_1 !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.gross_1
																						)
																							? formatDecimal(
																									value?.updated_data?.gross_1,
																									2
																							  )
																							: value?.updated_data?.gross_1
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.gross_1 !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.gross_1
																						)
																							? formatDecimal(
																									value?.updated_data?.gross_1,
																									2
																							  )
																							: value?.updated_data?.gross_1
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.updated_data &&
																			value?.updated_data?.gross_2 !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.gross_2
																						)
																							? formatDecimal(
																									value?.updated_data?.gross_2,
																									2
																							  )
																							: value?.updated_data?.gross_2
																				  }`
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data &&
																			value?.updated_data?.gross_2 !== null
																				? `${dataSymbol} ${
																						isFloat(
																							value?.updated_data?.gross_2
																						)
																							? formatDecimal(
																									value?.updated_data?.gross_2,
																									2
																							  )
																							: value?.updated_data?.gross_2
																				  }`
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>

																<TableCell align='right'>
																	<Tooltip
																		title={
																			value?.action_tstamp
																				? dayjs(value?.action_tstamp).format(
																						dateFormat
																				  )
																				: '--'
																		}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.action_tstamp
																				? dayjs(value?.action_tstamp).format(
																						dateFormat
																				  )
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>
																<TableCell align='right'>
																	<Tooltip
																		title={`${value?.user?.full_name || '- -'}`}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.user?.full_name
																				? value?.user?.full_name
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>
																<TableCell align='right'>
																	<Tooltip
																		title={`${
																			value?.updated_data?.notes || '- -'
																		}`}
																		placement='right'
																	>
																		<Box
																			className={`${classes.notes} ${classes.ellipsis}`}
																		>
																			{value?.updated_data?.notes
																				? value?.updated_data?.notes
																				: '--'}
																		</Box>
																	</Tooltip>
																</TableCell>
															</TableRow>
														);
													})
												) : (
													<Box className={classes.noData}>No data found</Box>
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
							</ThemeProvider>
						</td>
					</tr>
				</>
			);
		},
	};

	return (
		<>
			<Box
				style={{
					display: 'grid',
					gridGap: '25px',
					flexDirection: 'row',
				}}
			>
				<Card style={{ height: '120px' }}>
					<Typography className={classes.bankDetails}>Bank Details</Typography>
					{permissionList?.employee?.update_permissions ? (
						<Box
							style={{ marginLeft: '25px' }}
							className={classes.editContainer}
							onClick={() => setOpenEditModal(true)}
						>
							<img
								className={classes.editButton}
								src={editIcon}
								alt='editicon'
							/>
							<Box style={{ marginLeft: '5px' }} className={classes.editIcon}>
								Edit Details
							</Box>
						</Box>
					) : null}

					<DetailContainer>
						<Box>
							<Box
								style={{
									display: 'flex',
								}}
							>
								<Box style={{ marginRight: '100px', marginLeft: '25px' }}>
									<FieldContainer>
										<Typography className={classes.label}>Bank Name</Typography>
										<FunctionField
											className={classes.value}
											record={employee}
											render={(employee) => {
												return (
													<Tooltip
														title={`${employee?.bank_name || '- -'}`}
														placement='right'
													>
														<Typography
															className={`${classes.notes} ${classes.ellipsis}`}
														>
															{`${employee?.bank_name || '- -'}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</FieldContainer>
								</Box>
								<Box style={{ marginRight: '100px' }}>
									<FieldContainer>
										<Typography className={classes.label}>
											Branch Name
										</Typography>
										<FunctionField
											className={classes.value}
											record={employee}
											render={(employee) => {
												return (
													<Tooltip
														title={`${employee?.branch_name || '- -'}`}
														placement='right'
													>
														<Typography
															className={`${classes.notes} ${classes.ellipsis}`}
														>
															{`${employee?.branch_name || '- -'}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</FieldContainer>
								</Box>

								<Box style={{ marginRight: '100px' }}>
									<FieldContainer>
										<Typography className={classes.label}>
											Account Number
										</Typography>
										<FunctionField
											className={classes.value}
											record={employee}
											render={(employee) => {
												return (
													<Tooltip
														title={`${employee?.account_number || '- -'}`}
														placement='right'
													>
														<Typography
															className={`${classes.notes} ${classes.ellipsis}`}
														>
															{`${employee?.account_number || '- -'}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</FieldContainer>
								</Box>
								<Box>
									<FieldContainer>
										<Typography className={classes.label}>IFSC Code</Typography>
										<FunctionField
											className={classes.value}
											record={employee}
											render={(employee) => {
												return (
													<Tooltip
														title={`${employee?.ifsc_code || '- -'}`}
														placement='right'
													>
														<Typography
															className={`${classes.notes} ${classes.ellipsis}`}
														>
															{`${employee?.ifsc_code || '- -'}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</FieldContainer>
								</Box>
							</Box>
						</Box>
					</DetailContainer>
				</Card>

				<Card>
					<Box style={{ display: 'inline-flex' }}>
						<Box className={classes.ctcHeading}>Compensation</Box>
					</Box>
					{permissionList?.employee?.update_permissions ? (
						<Box
							style={{ marginLeft: '25px' }}
							className={classes.editContainer}
							onClick={() => setForm(true)}
						>
							<img
								className={classes.editButton}
								src={editIcon}
								alt='editicon'
							/>
							<Box style={{ marginLeft: '5px' }} className={classes.editIcon}>
								Edit Details
							</Box>
						</Box>
					) : null}

					<Box>
						<ThemeProvider theme={CTCTableTheme}>
							<>
								<MUIDataTable
									title={'CTC'}
									data={ctcData}
									columns={columns}
									options={options}
									components={{
										Header: () => null,
									}}
								/>
							</>
						</ThemeProvider>
					</Box>
				</Card>

				<CtcForm
					open={form}
					record={employee}
					refetch={() => {
						refetchCtc();
					}}
					onSuccess={() => {
						refetchAuditCtc();
					}}
					onClose={() => setForm(false)}
				/>
				<EmployeeBankDetailsEditForm
					open={openEditModal}
					onClose={(value) => setOpenEditModal(value)}
					record={employee}
					refetch={() => {
						refetchEmployee();
					}}
				/>
			</Box>
		</>
	);
};

export default EmployeeBankDetails;
