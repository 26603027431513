import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { insightChartTheme } from '../../../Layout/Theme.component';
import { formatAmount } from '../../../Utils/string.util';
import NoDataFound from '../../NoDataFound.component';
import { ChartDatePicker } from '../CustomDatePicker.component';
import { ChartWithDateRange } from '../modal';
import { useGetPipelineSummaryQuery } from '../../../generated/graphql';
interface ChartData {
	name: string;
	'Total Value': number;
	'Weighted Value': number;
}

export const PipelineSummaryReport = (props: ChartWithDateRange) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineSummary, loading: isLoading } =
		useGetPipelineSummaryQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
			},
		});

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineSummary) {
			return;
		}
		const opportunities = pipelineSummary.kanban_column
			.filter(
				(kanbanColumn) => kanbanColumn.opportunity_kanban_cards.length > 0
			)
			.map((kanbanColumn) => ({
				state: kanbanColumn.state || '',
				totalValue: kanbanColumn.opportunity_kanban_cards.reduce(
					(total, currentVal) => total + currentVal?.opportunity?.value || 0,
					0
				),
				totalWeightedValue: kanbanColumn.opportunity_kanban_cards.reduce(
					(total, currentVal) =>
						total + currentVal?.opportunity?.weighted_value || 0,
					0
				),
			}));
		const pipelineStates = Array.from(
			new Set(opportunities.map((opportunity) => opportunity.state))
		);
		const data = pipelineStates.map((state) => ({
			name: state,
			'Total Value': opportunities
				.filter((opportunity) => opportunity.state === state)
				.reduce((total, currentVal) => total + currentVal.totalValue, 0),
			'Weighted Value': opportunities
				.filter((opportunity) => opportunity.state === state)
				.reduce(
					(total, currentVal) => total + currentVal.totalWeightedValue,
					0
				),
		}));

		setChartData(data);
	}, [pipelineSummary]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{label}</p>
					<p>
						Total Value:
						{` ${formatAmount(
							parseInt(payload[0]?.payload['Total Value']),
							currency
						)}`}
					</p>
					<p>
						Weighted Value:
						{` ${formatAmount(
							parseInt(payload[0]?.payload['Weighted Value']),
							currency
						)}`}
					</p>
					<p></p>
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='name'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey='Total Value'
						fill='#196CF5'
						barSize={10}
						radius={[10, 10, 0, 0]}
					/>
					<Bar
						dataKey='Weighted Value'
						fill='#9ac8f5'
						barSize={10}
						radius={[10, 10, 0, 0]}
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Summary
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => setEndDate(date)}
							selectedStartDate={(date: string) => setStartDate(date)}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
