import React, { useState } from 'react';

import {
	Box,
	withStyles,
	Tabs,
	Tab,
	Theme,
	createStyles,
} from '@material-ui/core';
import TimesheetTaskWiseReportContainer from './TimesheetTaskWiseReportContainer.component';
import { TimesheetDayWiseReportContainer } from './TimesheetDayWiseReportContainer.component';
import TimesheetSummaryReportContainer from './TimesheetSummaryReportContainer.component';
import { TimesheetProjectWiseReport } from './TimesheetProjectWiseReport.component';

interface StyledTabProps {
	label: string;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			minWidth: 72,
			marginRight: '10px',
			fontFamily: 'Manrope-Medium',
			fontSize: '12px !important',
			color: '#5C5C5C',
			'&:hover': {
				color: '#4285F4',
				opacity: 1,
			},
			'&$selected': {
				color: '#1890ff',
				fontWeight: theme.typography.fontWeightBold,
			},
			'&:focus': {
				color: '#4285F4',
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box pr={3}>
					{children}
				</Box>
			)}
		</div>
	);
};

export const ProjectInsightContainer = () => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	return (
		<>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab key={0} label='Summary' />
				<CustomTab key={1} label='Day Wise' />
				<CustomTab key={2} label='Task Wise' />
				<CustomTab key={3} label='Project Wise' />
			</CustomTabs>
			<CustomTabPanel value={selectedTabIndex} index={0}>
				<TimesheetSummaryReportContainer />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={1}>
				<TimesheetDayWiseReportContainer />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={2}>
				<TimesheetTaskWiseReportContainer />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={3}>
				<TimesheetProjectWiseReport />
			</CustomTabPanel>
		</>
	);
};

export default ProjectInsightContainer;
