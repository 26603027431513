import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextInput, useMutation, useNotify } from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import dayjs from 'dayjs';
interface Record {
	manufacturer?: string;
	acquisition_date?: string;
	warranty_expiration_date?: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	record: Record;
	refetch: () => void;
}
export const EditWarrantyDetails = (props: Props) => {
	const { open, onClose, record: asset, refetch } = props;
	const classes = modalFormStyle();
	const [mutate] = useMutation();
	const notify = useNotify();
	const { id }: { id: string } = useParams();
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [ endDateError, setEndDateError] = useState(false)

	const saveDetails = (formValue: Record) => {
		if(!formValue){
			return
		}
		if (
			dayjs(formValue?.acquisition_date).isAfter(
				dayjs(formValue?.warranty_expiration_date)
			)
		) {
			setEndDateError(true)
			return
		}
		mutate(
			{
				type: 'update',
				resource: 'asset',
				payload: {
					id: id,
					data: formValue,
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Warranty details updated successfully');
					onClose();
					refetch();
				},
				onFailure: (error) => {
					notify(`Updating failed: ${error.message}`, 'warning');
				},
			}
		);
	};
	const handleClose = () => {
		setEndDateError(false)
		onClose()
	}
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={handleClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Warranty Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => handleClose()}
						/>
					</Box>
					<Form initialValues={asset} onSubmit={saveDetails}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box className={classes.label}>Manufacturer</Box>
									<TextInput
										source='manufacturer'
										fullWidth={true}
										label={false}
									/>
									<Box className={classes.label}>Acquisition Date</Box>

									<Field
										name='acquisition_date'
										validate={(value: any) => {
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={(value) => {
														setEndDateError(false);
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									<Box className={classes.label}>Warranty Expiration Date</Box>

									<Field
										name='warranty_expiration_date'
										validate={(value: any) => {
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={(value) => {
														setEndDateError(false);
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{endDateError && (
										<p className={classes.errorText}>
											End date should be greater than start date
										</p>
									)}
								</Box>

								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => handleClose()}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid ? classes.disabledButton : classes.saveButton
										}
										disabled={invalid}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditWarrantyDetails;
