import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ProjectMilestone from "./ProjectMilestone.component";
import ProjectResources from "./ProjectResources.component";
import ProjectSoW from "./ProjectSoW.component";
 
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ProjectTabs = ({ projectId, refresh, projectType,projectSoW,customerId }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const onTabChange = (event, newIndex) => {
    setIndex(newIndex);
  };

  const onClear = () => {
    refresh();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={index}
          onChange={onTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="MILESTONES"
            {...tabProps(0)}
            disabled ={projectType === "FP" || projectType === "TM" ? false : true}
          />
          <Tab label="RESOURCES" {...tabProps(1)} />
          <Tab label="SoW" {...tabProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={index} index={0}>
        {projectType === "FP" || projectType === "TM"? (
          <ProjectMilestone projectId={projectId} refresh={onClear} />
          ) : (
          <Typography>No milestones for this project</Typography>
        )}
      </TabPanel>
      <TabPanel value={index} index={1}>
        <ProjectResources projectId={projectId} />
      </TabPanel>
      <TabPanel value={index} index={2}>
        <ProjectSoW customerId={customerId} projectId={projectId}  projectSoW={projectSoW}/>
      </TabPanel>
    </div>
  );
};

export default ProjectTabs;
