import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useNotify } from 'react-admin';
import { isEmpty } from 'lodash';
import { formStyle, pmsDatagridStyle } from '../pms.styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { DataGridTheme } from '../pmsTheme';
import {
  PMS_USER_APPRAISAL_STATUS,
  GOAL_REMOVE_SUCCESS_MSG,
  GOAL_ERROR_MSG,
} from '../constant';
import { useRemoveGoalMappingMutation } from '../../../generated/graphql';

const viewGoalsStyle = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '20px',
    boxShadow: '0px 4px 12px #4285F43B',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  label: {
    fontFamily: 'Manrope-medium',
    fontSize: '10px',
    color: '#5C5C5C',
  },
  value: {
    fontFamily: 'Manrope-semibold',
    fontSize: '12px',
    color: '#292929',
  },
  name: {
    fontFamily: 'Manrope-extrabold',
    fontSize: '12px',
    color: '#000000',
  },
  profileContent: {
    fontFamily: 'Manrope-regular',
    fontSize: '12px',
    color: '#888888',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePic: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
  },
});

interface ViewGoalsProps {
  onClose: () => void;
  open: boolean;
  userDetails: {
    userName: string;
    experience: string;
    profilePic?: string;
    reviewer: string;
    jobLevel: string;
    department: string;
    status: string;
    userAppraisalStatus: string;
  };
  goals: {
    id: any;
    name: string;
    description: string;
    weightage: number;
    startDate: string;
    endDate: string;
  }[];
  refetch: () => void;
  disabled: boolean;
}

function ViewGoals(props: ViewGoalsProps) {
  const {
    open,
    onClose,
    userDetails,
    goals: initialGoals,
    disabled = true,
    refetch,
  } = props;
  const [goals, setGoals] = useState(initialGoals);
  const formStyles = formStyle();
  const viewGoalsStyles = viewGoalsStyle();
  const dataGridStyles = pmsDatagridStyle();
  const notify = useNotify();

  useEffect(() => {
    if (isEmpty(initialGoals)) return;

    setGoals(initialGoals);
  }, [initialGoals]);

  // API
  const [removeGoalMapping, { loading: isRemoveGoalMappingLoading }] =
    useRemoveGoalMappingMutation();

  const columns = [
    { name: 'name', label: 'NAME' },
    { name: 'description', label: 'DESCRIPTION' },
    {
      name: 'weightage',
      label: 'WEIGHTAGE',
    },
    {
      name: 'startDate',
      label: 'START DATE',
    },
    {
      name: 'endDate',
      label: 'END DATE',
    },
    {
      name: 'id',
      label: 'ACTION',
      options: {
        customBodyRender: (value: any) => (
          <Button
            disabled={
              disabled ||
              userDetails.userAppraisalStatus !==
                PMS_USER_APPRAISAL_STATUS.APPRAISAL_INITIATED ||
              isRemoveGoalMappingLoading
            }
            className={
              disabled ||
              userDetails.userAppraisalStatus !==
                PMS_USER_APPRAISAL_STATUS.APPRAISAL_INITIATED ||
              isRemoveGoalMappingLoading
                ? dataGridStyles.disabledExcludeButton
                : dataGridStyles.excludeButton
            }
            onClick={() => {
              handleRemoveGoalMapping(value);
            }}
          >
            Remove
          </Button>
        ),
      },
    },
  ];

  const handleRemoveGoalMapping = (goalMappingId: any) => {
    if (!goalMappingId) return;
    removeGoalMapping({
      variables: {
        goalMappingId: goalMappingId,
      },
    }).then((res) => {
      if (!res.errors) {
        notify(GOAL_REMOVE_SUCCESS_MSG);
        setGoals((prevGoals) =>
          prevGoals.filter((goal) => goal.id !== goalMappingId)
        );
        refetch();
      } else {
        notify(GOAL_ERROR_MSG);
      }
    });
  };

  const options: MUIDataTableOptions = {
    filter: false,
    search: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    rowsPerPageOptions: [5, 10, 20, 30],
    download: false,
    serverSide: true,
    viewColumns: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'No data found',
      },
    },
  };

  return (
    <ThemeProvider theme={modalFormTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'
        maxWidth={false}
        fullWidth
      >
        <div
          className={formStyles.container}
          style={{
            width: '100%',
          }}
        >
          <div className={formStyles.headerContainer}>
            <p className={formStyles.heading}>Goals</p>
            <CloseIcon className={formStyles.closeIcon} onClick={onClose} />
          </div>

          <div>
            <DialogContent>
              <div className={viewGoalsStyles.headerContainer}>
                <div className={viewGoalsStyles.flex}>
                  <div>
                    <Avatar
                      alt='employee_image'
                      src={userDetails.profilePic}
                      className={viewGoalsStyles.profilePic}
                    />
                  </div>
                  <div>
                    <p className={viewGoalsStyles.name}>
                      {userDetails.userName}
                    </p>
                    <p className={viewGoalsStyles.profileContent}>
                      {userDetails.department}
                    </p>
                    <p className={viewGoalsStyles.profileContent}>
                      {userDetails.jobLevel}
                    </p>
                  </div>
                </div>

                <div>
                  <p className={viewGoalsStyles.label}>Year Of Experience</p>
                  <p className={viewGoalsStyles.value}>
                    {userDetails.experience}
                  </p>
                </div>
                <div>
                  <p className={viewGoalsStyles.label}>Employee Status</p>
                  <p className={viewGoalsStyles.value}>{userDetails.status}</p>
                </div>
                <div>
                  <p className={viewGoalsStyles.label}>Appraisal Eligibility</p>
                  <p className={viewGoalsStyles.value}>{'Eligible'}</p>
                </div>
                <div>
                  <p className={viewGoalsStyles.label}>Reviewer</p>
                  <p className={viewGoalsStyles.value}>
                    {userDetails.reviewer}
                  </p>
                </div>
              </div>
              <div>
                <ThemeProvider theme={DataGridTheme}>
                  <MUIDataTable
                    title='Goals'
                    data={goals}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              </div>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}

export default ViewGoals;
