import React, { useState, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	Box,
	makeStyles,
	Button,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { ellipsisStyle } from '../../../../Layout/styles';
import AvatarField from '../../../../SharedComponents/AvatarField.component';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import CommentsForm from './CommentsForm.component';
import {
	useDeleteCommentByIdMutation,
	useGetTaskCommentsByTaskIdQuery,
} from '../../../../generated/graphql';
import dayjs from 'dayjs';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import {
	COMMENT_DELETED_MESSAGE,
	COMMENT_DELETION_FAILED_MESSAGE,
} from '../../Constant';
import { useNotify } from 'react-admin';
import { UserProfileContext } from '../../../../App';

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '20px',
	},
	addNotesButton: {
		textTransform: 'none',
		minWidth: '111px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box !important',
		boxShadow: '0px 4px 12px #4285F43B !important',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	container: {
		overflowY: 'auto',
		width: '100%',
		height: '400px',
	},
	listContainer: {
		border: '1px solid #E0E0E0',
		height: '80px',
		marginBottom: '10px',
		borderRadius: '7px',
	},
	avatar: {
		display: 'flex',
		marginTop: '2px',
	},
	description: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-semiBold',
		fontSize: '12px',
		overflow: 'hidden',
		marginLeft: '45px',
		width: '700px',
		marginTop: '3px',
	},
	noteTitle: {
		marginLeft: '7px',
		marginTop: '3px',
		color: '#292929',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	date: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '8px',
		color: '#5C5C5C',
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
	},
	noData: {
		marginTop: '5px',
	},
	label: {
		font: 'normal normal medium 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '12px !important',
		marginTop: '15px',
	},
	timeStamp: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-semiBold',
		fontSize: '10px',
		overflow: 'hidden',
		marginLeft: '45px',
		width: '700px',
	},
});
export interface CreatedBy {
	first_name?: string;
	last_name?: string;
	full_name?: string;
	__typename: string;
}
interface Comment {
	id?: string;
	task_id?: string;
	comment?: string;
	created_at?: Date;
	createdBy?: CreatedBy;
}

interface Props {
	taskId: string;
}

const CommentsTab = (props: Props) => {
	const { taskId } = props;
	const [isCommentModalShown, setIsCommentModalShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedCommentDetails, setSelectedCommentDetails] =
		useState<Comment>();
	const classes = useStyles();
	const styles = ellipsisStyle();
	const notify = useNotify();
	const { id } = useContext<any>(UserProfileContext);
	const { data: commentsList, refetch: refetchComments } =
		useGetTaskCommentsByTaskIdQuery({
			variables: {
				task_id: taskId,
			},
		});
	const [deleteComment] = useDeleteCommentByIdMutation();

	const onDeleteComment = () => {
		deleteComment({
			variables: {
				id: selectedCommentDetails?.id,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(COMMENT_DELETED_MESSAGE);
					setIsDeleteModalShown(false);
					refetchComments();
					setSelectedCommentDetails({});
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify(COMMENT_DELETION_FAILED_MESSAGE, 'warning');
					return;
				}
			});
	};
	return (
		<>
			<Box className={classes.toolbar}>
				<Box>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						className={classes.addNotesButton}
						onClick={() => setIsCommentModalShown(true)}
					>
						Add Comment
					</Button>
				</Box>
			</Box>
			<Box className={classes.container}>
				{commentsList && commentsList?.project_task_comments?.length > 0 ? (
					commentsList?.project_task_comments?.map((comment: any) => (
						<Box className={classes.listContainer} key={comment?.id}>
							<Box
								display='flex'
								justifyContent='space-between'
								marginTop='5px'
								marginLeft='5px'
							>
								<Box display='flex' marginLeft='5px'>
									<Tooltip
										title={
											comment?.createdBy?.full_name
												? `${comment?.createdBy?.full_name} `
												: '- -'
										}
										placement='right'
									>
										<Box>
											<AvatarField
												className={classes.avatar}
												firstName={
													comment?.createdBy?.full_name
														? `${comment?.createdBy?.full_name}`
														: '- -'
												}
												lastName={''}
												imageSrc={``}
												size='30'
											/>
										</Box>
									</Tooltip>
									<Box className={classes.noteTitle}>
										{comment?.createdBy?.full_name
											? `${comment?.createdBy?.full_name} `
											: '- -'}
									</Box>
								</Box>
								{comment?.created_by === id && (
									<Box marginRight='5px'>
										<PopOver
											edit={() => {
												setIsEdit(true);
												setIsCommentModalShown(true);
												const {
													createdBy,
													created_at,
													__typename,
													...restValues
												} = comment;
												setSelectedCommentDetails(restValues);
											}}
											remove={() => {
												const {
													createdBy,
													created_at,
													task_id,
													...restValues
												} = comment;

												setSelectedCommentDetails(restValues);
												setIsDeleteModalShown(true);
											}}
										/>
									</Box>
								)}
							</Box>
							<Typography className={classes.timeStamp}>
								{comment?.created_at
									? dayjs(comment?.created_at).format(
											'DD-MMM-YYYY [at] hh:mm A'
									  )
									: '- -'}
							</Typography>
							<Tooltip title={comment?.comment || '- -'} placement='right'>
								<Box className={`${classes.description} ${styles.ellipsis}`}>
									{comment?.comment || '- -'}
								</Box>
							</Tooltip>
						</Box>
					))
				) : (
					<Typography className={classes.label}>No comments found</Typography>
				)}
			</Box>
			<CommentsForm
				open={isCommentModalShown}
				onClose={() => {
					setIsCommentModalShown(false);
					setIsEdit(false);
					setSelectedCommentDetails({});
				}}
				onSuccess={() => {
					refetchComments();
					setIsEdit(false);
				}}
				commentDetails={selectedCommentDetails}
				isEdit={isEdit}
				taskId={taskId}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setIsDeleteModalShown(false);
					setSelectedCommentDetails({});
				}}
				onDelete={onDeleteComment}
				confirmationMessage='Do you really want to delete this comment ?'
			/>
		</>
	);
};

export default CommentsTab;
