import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import { Loading } from 'ra-ui-materialui';
import OrganizationChart from '@dabeng/react-orgchart';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import "./orgChartNode.css";

const GET_PEOPLE_HEIRARCHY = gql`
	query getPeopleHierarchy {
		employee(
			where: {
				reportee_id: { _is_null: false }
				user: { is_active: { _eq: true } }
				reportsTo: { is_active: { _eq: true } }
			}
		) {
			user {
				id
				first_name
				last_name
				email
				profile_pic
			}
			designation {
				title
			}
			reportsTo {
				id
				first_name
				last_name
				email
				profile_pic
				employee {
					designation {
						title
					}
				}
			}
		}
	}
`;
interface User {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	profile_pic: string;
}
interface ReportsTo extends User {
	employee: {
		designation: {
			title: string;
		};
	};
}
export interface Employee {
	user: User;
	reportsTo: ReportsTo;
	designation: {
		title: string;
	};
}
export interface Hierarchy {
	employee: Employee[];
}
export interface Node {
	id: string;
	title: string;
	name: string;
	image?: string;
	reportsTo?: string;
	children?: Node[];
	className?: string;
	collapsed?: boolean;
}
const mapArrayToTree = (items: any, id = undefined, link = 'reportsTo') => {
	return items
		.filter((item: any) => item[link] === id)
		.map((item: any) => ({ ...item, children: mapArrayToTree(items, item.id) }));
};
/**  Todo uncomment once this issue fixed: https://github.com/dabeng/react-orgchart/issues/43
let i = 0;
const nodeDepth = 3;
const collapseChildrens = (tree: Node): Node[] => {
	 i++;
	 const isCollapseReady = i > nodeDepth;
	 return _.map(tree.children, (node: Node) => {
		 const isParent = !!node.children?.length;
		return {
			 ...node,
			 collapsed: isParent ? true : undefined,
			 className: !isParent ? 'slide-up' : undefined,
			 children: isParent ? collapseChildrens(node) : []
		 }
	 });
}
*/
const useStyles = makeStyles((theme) => ({
	nodeCard:{
		width: '160px',
		padding: '5px 5px 0px 5px',
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid',
	},
	nameEllipsis: {
		width: '90px',
		whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
	},
	contentWrap:{
			display: 'flex',
	},
	nodeDetails: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		alignItems: 'center'
	},
	count: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '7px',
	},
	countNum:{
		background: '#b3bfd9',
		width: '15px',
		position: 'relative',
		top: '3px',
		borderRadius: '50%',
		fontSize: 'x-small',
		fontWeight: 'bold'
	}
}));
/* position: relative;
top: 6px;
font-size: smaller;
background: gold;
width: 20px;
border-radius: 50%; */
const ChartNode = ({nodeData}: {nodeData: Node}) => {
	const classes = useStyles();
	return (
	<div className={classes.nodeCard}>
		<div className={classes.contentWrap}>
				<div>
					<Avatar src={nodeData.image || ''}></Avatar>
				</div>
				<div className={classes.nodeDetails}>
						<div title={nodeData.name} className={classes.nameEllipsis}>{nodeData.name}</div>
						{ !!nodeData.title && 
							<div title={nodeData.title} className={classes.nameEllipsis}> {nodeData.title} </div>
						}
				</div>
		</div>
		<div className={classes.count}>
			<div className={classes.countNum}>{!!nodeData.children?.length ? nodeData.children?.length : ''}</div>
		</div>
	</div>
	);
}

export const OrgChart = () => {
	const { data: peopleHierarchy, loading } = ApolloUseQuery<Hierarchy>(
		GET_PEOPLE_HEIRARCHY,
		{ fetchPolicy: 'network-only' }
	);
	const [graphData, setGraphData] = useState<any>({});
	useEffect(() => {
		if (!peopleHierarchy) {
			return;
		}
		const items = uniqBy(
			peopleHierarchy.employee
				.map((ob) => {
					return [
						{
							id: ob.user.id,
							image: ob.user.profile_pic,
							name: `${ob.user.first_name} ${ob.user.last_name}`,
							title: ob?.designation?.title,
							reportsTo: ob.reportsTo.id,
						},
						{
							id: ob.reportsTo.id,
							image: ob.reportsTo.profile_pic,
							name: `${ob.reportsTo.first_name} ${ob.reportsTo.last_name}`,
							title: ob?.reportsTo?.employee?.designation?.title,
						},
					];
				})
				.flat()
				.sort((a: Node, b: Node) => {
					if (!a?.reportsTo && b?.reportsTo) {
						return 1;
					}
					if (a?.reportsTo && !b?.reportsTo) {
						return -1;
					}
					if (a?.reportsTo && b?.reportsTo) {
						return 0;
					}
					return 0;
				}),
			'id'
		);
		const graph = {id: 'board', title: '', name: 'Board', children: mapArrayToTree(items)};
		setGraphData(() => graph);
	}, [peopleHierarchy]);
	if (loading) {
		return <Loading></Loading>;
	}
	return <OrganizationChart datasource={graphData} pan={true} chartClass="myChart" NodeTemplate={ChartNode}  zoom={true} />;
};
