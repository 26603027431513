import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import KanbanWarningModal from '../Kanban/kanbanWarning.component';
import { validateName, validateDescription } from '../constant';

import {
	useUpsertProjectStatusMutation,
	useGetOrganisationProjectStatusOptionsQuery,
} from '../../../generated/graphql';

import {
	EDIT_PROJECT_STATUS_MESSAGE,
	ADD_PROJECT_STTAUS_MESSAGE,
	PROJECT_STATUS_NAME_UNIQUE_ERROR,
	UPDATE_SUCCESS_MESSAGE,
	INSERT_SUCCESS_MESSAGE,
} from './constant';
import { orderBy } from 'lodash';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

export interface projectStatus {
	description?: string;
	id?: string;
	label?: string;
	value?: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	projectStatus: projectStatus;
}

const ProjectStatusForm = (props: Props) => {
	const { open, onClose, projectStatus } = props;
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectStatusUniqueError, setProjectStatusUniqueError] = useState('');
	const classes = modalFormStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const { data: organisationTimezoneOptions } =
		useGetOrganisationProjectStatusOptionsQuery();

	const [upsertProjectStatus, { loading: isProjectStatusLoading }] =
		useUpsertProjectStatusMutation();

	const onSave = (formValue: projectStatus) => {
		if (!formValue) {
			return;
		}
		setProjectStatusUniqueError('');
		const { label, description } = formValue;
		upsertProjectStatus({
			variables: {
				projectStatus: {
					...formValue,
					label: label?.trim(),
					description: description?.trim(),
				},
			},
		})
			.then((data) => {
				onClose();
				notify(formValue?.id ? UPDATE_SUCCESS_MESSAGE : INSERT_SUCCESS_MESSAGE);
				setProjectStatusUniqueError('');
				userefresh();
				handleClose();
			})
			.catch((error) => {
				if (error.message.includes('project_status_label_org_id_key')) {
					setProjectStatusUniqueError(PROJECT_STATUS_NAME_UNIQUE_ERROR);
					return;
				}
			});
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
	};

	const handleClose = () => {
		setProjectStatusUniqueError('');
		onClose();
	};

	const validate = [
		required(),
		validateName,
		maxLength(100, 'Maximum 100 character allowed'),
	];

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{projectStatus?.id
									? EDIT_PROJECT_STATUS_MESSAGE
									: ADD_PROJECT_STTAUS_MESSAGE}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={projectStatus}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Name *</Typography>
										<TextInput
											source='label'
											label=''
											validate={validate}
											onChange={(event: any) => {
												form.mutators.setTouched();
												setProjectStatusUniqueError('');
											}}
										/>
										{projectStatusUniqueError && (
											<Box color='red' fontSize={'12px'} marginTop={'-6px'}>
												{projectStatusUniqueError}
											</Box>
										)}
										<Typography className={classes.label}>Stage *</Typography>
										<SelectInput
											source='value'
											label={''}
											choices={
												organisationTimezoneOptions &&
												organisationTimezoneOptions?.master_settings?.length > 0
													? orderBy(
															organisationTimezoneOptions?.master_settings[0].value.map(
																(value: String[]) => {
																	return {
																		id: value,
																		name: value,
																	};
																}
															),
															[(project) => project?.name.toUpperCase()],
															['asc']
													  )
													: []
											}
											validate={required()}
										/>
										<Typography className={classes.label}>
											Description
										</Typography>
										<TextInput
											validate={validateDescription}
											source='description'
											label=''
											multiline={true}
										/>
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										
										<ButtonWithLoader
											isDisabled={
												invalid ||
												pristine ||
												submitSucceeded ||
												isProjectStatusLoading
											}
											isLoading={isProjectStatusLoading}
											title='Save'
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={`This action will make "${
						projectStatus?.label || ''
					}" as default Location.`}
				/>
			}
		</>
	);
};

export default ProjectStatusForm;
