import React, { useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import { preventSubsequentClick } from '../../Utils/string.util';
import ArrowDropUpSharp from '@material-ui/icons/ArrowDropUpSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownSharp';

const useStyles = makeStyles(() => ({
	buttonContainer: {
		margin: '0px 20px 0px 40px',
	},
	exportButton: {
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		height: '34px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
	},
	displayFlex: {
		display: 'flex',
	},
	centerAlignment: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '7px',
	},
	buttonWidth: {
		minWidth: '100px',
	},
	loaderStyles: {
		height: '20px !important',
		width: '20px !important',
		color: '#FFFFFF',
	},
}));

interface Props {
	options: string[];
	isDisabled?: boolean;
	downloadInProgress?: boolean;
	onChange: (index: number) => void;
}

const ExportButtonWithDropDown = (props: Props) => {
	const {
		onChange,
		isDisabled = false,
		downloadInProgress = false,
		options,
	} = props;

	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const classes = useStyles();
	const closeMenuHandler = () => {
		setOpen(false);
	};
	const disableButton = () => {
		setIsLoading(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const handleMenuItemClick = (index: number) => {
		if (!isDisabled && !isLoading) {
			setIsLoading(true);
			preventClick();
			onChange(index);
		}
		closeMenuHandler();
	};

	const handleToggle = (event: any) => {
		if (!isDisabled && !downloadInProgress) {
			event.stopPropagation();
			setOpen((prevOpen) => !prevOpen);
		}
	};

	return (
		<Box className={classes.buttonContainer}>
			<Box>
				<ButtonGroup
					variant='contained'
					ref={anchorRef}
					onClick={(event) => handleToggle(event)}
					disableElevation
				>
					<Button
						className={
							isDisabled || isLoading || downloadInProgress
								? `${classes.disabledButton} ${classes.buttonWidth}`
								: `${classes.exportButton} ${classes.buttonWidth}`
						}
						disabled={isDisabled || isLoading || downloadInProgress}
						startIcon={
							(isLoading || downloadInProgress) && (
								<CircularProgress className={classes.loaderStyles} />
							)
						}
					>
						<Box className={classes.centerAlignment}>
							<Box>
								<span> Export </span>
							</Box>
							<Box className={classes.displayFlex}>
								{!open ? (
									<ArrowDropDownIcon fontSize='small' />
								) : (
									<ArrowDropUpSharp fontSize='small' />
								)}
							</Box>
						</Box>
					</Button>
				</ButtonGroup>
				<Menu
					open={open}
					anchorEl={anchorRef.current}
					onClose={closeMenuHandler}
					PaperProps={{
						style: {
							marginTop: '45px',
							width: '100px',
						},
					}}
				>
					{options.map((option, index) => (
						<MenuItem
							style={{ fontSize: '12px' }}
							key={option}
							onClick={() => handleMenuItemClick(index)}
						>
							{option}
						</MenuItem>
					))}
				</Menu>
			</Box>
		</Box>
	);
};

export default ExportButtonWithDropDown;
