import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import {
	GET_CHECKLISTS,
	UPSERT_EMP_CHECKLIST,
} from './gqlQueries';
import LinearProgress from '@material-ui/core/LinearProgress';
import { keyBy } from 'lodash';
import { CheckListProgressSectionIcon } from "../../../assets/SvgIcons";
import head from 'lodash/head';
import { useGetCheckListTypeTabsQuery } from '../../../generated/graphql';

const useStyles = makeStyles({
	rowEven: {
		backgroundColor: '#F7F9FA',
	},
	formControl: {
		minWidth: 120,
	},
	selectedCheckList: {
		background: '#4285F427',
	},
	checklistTab: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '6px 14px',
    paddingTop: '8px',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: '4px',
    marginBottom: '15px',
		maxWidth: '314px',
	},
	progressSection:{
		width: '140px',
		justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
	},
	progressWrap: {
		width: '100%',
	},
	checklistTable: {
		'& td': {
			width: '80%',
			fontFamily: 'Manrope-medium',
			color: '#5C5C5C',
			fontSize: '12px',
			padding: '0px 20px',
		},
		'& tr:nth-child(even)': {
			backgroundColor: '#F7F9FA',
		}
	},
	progressContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > p': {
			margin: '0px',
		},
	},
	progressLabel: {
		fontFamily: 'Manrope-bold',
		fontSize: '10px',
		color: '#5C5C5C'
	},
	container:{
		height:'500px', 
		overflowY:'auto'
	}
});

interface CheckListTypeTabProps {
	checkListTypeId: string;
	label: string;
	total: number;
	pending: number;
	completed: number;
	percentage: string;
}

export const EmployeeChecklist = ({ employeeId }: { employeeId: string }) => {
	const classes = useStyles();
	const [selectedCheckListTypeId, setSelectedCheckListTypeId] = useState('');
	const [checkLists, setCheckLists] = useState([]);
	const [checkListTypesTabs, setCheckListTypesTabs] = useState<CheckListTypeTabProps[]>([]);

	const {
		data: checkListTypes,
		refetch: refetchCheckListTypes,
	} = useGetCheckListTypeTabsQuery({
		variables: {
			employeeId,
		},
	});
	const {
		data: apiCheckList,
		refetch: refetchChecklists,
	} = useApolloQuery(GET_CHECKLISTS, {
		variables: {
			employeeId,
			checkListTypeId: selectedCheckListTypeId ? selectedCheckListTypeId : null,
		},
	});
	useEffect(() => {
		if (!checkListTypes) {
			return;
		}
		const updatedCheckLists = checkListTypes?.checklist_types?.map(
			(checklistType: any) => {
				const total =
					checklistType?.checklist_items_aggregate?.aggregate?.count || 0;
				const completed = checklistType.checklist_items.length;
				const pending = total - completed;
				const percentage = ((completed / total) * 100).toFixed(1);
				return {
					checkListTypeId: checklistType.id,
					label: checklistType.label,
					total,
					completed,
					pending,
					percentage,
				};
			}
		);
		setCheckListTypesTabs(updatedCheckLists);
		if (!selectedCheckListTypeId) {
			setSelectedCheckListTypeId(head(updatedCheckLists)?.checkListTypeId);
		}
		// Todo fix this, disabled for demo build purpose
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkListTypes]);
	useEffect(() => {
		if (!apiCheckList) {
			return;
		}
		const lookup = keyBy(apiCheckList.employee_checklist, 'checklist_item.id');
		const uiCheckList = apiCheckList.checklist_items.map((item: any) => {
			return {
				id: item.id,
				name: item.name,
				isCompleted: lookup[item.id]?.isCompleted || false,
				employee_id: '',
			};
		});
		setCheckLists(uiCheckList);
	}, [apiCheckList]);

	const [upsertCheckList, { data: upsertedData }] =
		useApolloMutation(UPSERT_EMP_CHECKLIST);
	useEffect(() => {
		if (!upsertedData) {
			return;
		}
		refetchCheckListTypes();
		refetchChecklists();
		// Todo fix this, disabled for demo build purpose
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upsertedData]);
	// const checklist = [
	// 	{
	// 		id: '031704d4-493b-412d-ad75-8e2e404a5a32',
	// 		name: 'Document Verification',
	// 		isCompleted: false,
	// 	},
	// 	{
	// 		id: '8f9153c6-53f9-400e-872b-4b4d1c8e36a0',
	// 		name: 'Welcome kit',
	// 		isCompleted: true,
	// 	},
	// ];
	useEffect(() =>{
		if(!selectedCheckListTypeId){
			return;
		}
		refetchChecklists()
	},[refetchChecklists, selectedCheckListTypeId])

	useEffect(() =>{
		refetchCheckListTypes()
		refetchChecklists()
	},[refetchCheckListTypes, refetchChecklists])
	return (
		<Grid container spacing={2} >
			<Grid item xs={4} className={classes.container}>
				{checkListTypesTabs.map((check : any) => (
					<div
						className={
							`${classes.checklistTab} ${selectedCheckListTypeId === check.checkListTypeId ? classes.selectedCheckList: ''}`
						}
						onClick={() => {
							setSelectedCheckListTypeId(check.checkListTypeId);
						}}
					>
						<div className={classes.progressSection}>
							<div 
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									alignItems: 'center',
								}}
							>
								<CheckListProgressSectionIcon />
								<div style={{
									fontFamily: 'Manrope-bold',
									fontSize: '12px',
									color: '#888888',
								}}>{check.label}</div>
							</div>
							<div className={classes.progressWrap}>
								<LinearProgress
									variant='determinate'
									value={check.percentage}
								/>
							</div>
						</div>
						<div style={{ width: "100px" }}>
							<div className={classes.progressContainer}>
								<p className={classes.progressLabel}>Total:</p>
								<p style={{
									color: '#888888',
									fontFamily: 'Manrope-semibold',
									fontSize: '12px',
								}}>{check.total}</p>
							</div>
							<div className={classes.progressContainer}>
							<p className={classes.progressLabel}>Completed:</p>
							<p style={{
									color: '#34A853',
									fontFamily: 'Manrope-semibold',
									fontSize: '12px',
								}}>{check.completed}</p>
								 </div>
							<div className={classes.progressContainer}>
								<p className={classes.progressLabel}>Pending:</p>	
								<p style={{
									color: '#EA4335',
									fontFamily: 'Manrope-semibold',
									fontSize: '12px',
								}}>{check.pending}</p>
								 </div>
						</div>
					</div>
				))}
			</Grid>
			<Grid item xs={8} className={classes.container}>
				<table className={classes.checklistTable}>
					<tbody>
							{/* <th>
								<tr style={{
									display: 'flex',
									justifyContent: 'space-between'
								}}>
									<td>ONBOARDING ITEMS</td>
									<td>STATUS</td>
								</tr>
							</th> */}
						{checkLists.map((checklistItem: any) => (
							<tr>
								<td>{checklistItem.name}</td>
								<td>
									<FormControl
										variant='outlined'
										className={classes.formControl}
										size="small" 
									>
										<Select
											labelId='demo-simple-select-outlined-label'
											id='demo-simple-select-outlined'
											onChange={(event) => {
												upsertCheckList({
													variables: {
														checkListItem: {
															checklist_item_id: event.target.name,
															employee_id: employeeId,
															isCompleted: event.target.value,
														},
													},
												});
											}}
											name={checklistItem.id}
											value={checklistItem.isCompleted}
											inputProps={{ 'aria-label': 'Without label' }}
											style={{
												color: checklistItem.isCompleted ? '#34A853' : '#5C5C5C',
												margin: '7px 0px',
												fontSize: '12px',
												fontFamily: 'Manrope-semibold',
												width: '126px',
												height: '32px',
											}}
										>
											<MenuItem value='true'>Completed</MenuItem>
											<MenuItem value='false'>Pending</MenuItem>
										</Select>
									</FormControl>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Grid>
		</Grid>
	);
};

export default EmployeeChecklist;