import React, { useState, useContext, useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { SearchIcon, KanbanIcon } from '../../assets/SvgIcons';
import { ThemeProvider } from '@material-ui/core/styles';
import { ProjectKanban } from './ProjectKanban.component';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SettingsOutlined } from '@material-ui/icons';
import {
	customAutoCompleteSearchBarTheme,
	searchBarTheme,
} from '../../Layout/Theme.component';
import {
	TextField,
	Chip,
	Checkbox,
	Tooltip,
	Typography,
} from '@material-ui/core';
import CreateTaskForm from './CreateTaskForm';
import { UserProfileContext } from '../../App';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
	useGetProjectAssignedUserQuery,
	useGetProjectTaskCategoriesOptionsQuery,
	useGetProjectMileStoneListQuery,
} from '../../generated/graphql';
import ProjectKanbanFilterContainer from './ProjectkanbanFilterContainer.component';
import ProjectTaskFilterFormBody from './ProjectTaskFilterFormBody.component';
import AppliedProjectTaskFiltersContainer from './projectTaskFilterContainer.component';
import CachedIcon from '@material-ui/icons/Cached';
import './projectKanban.css';
import { isEmpty } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ProjectTaskContext } from './ProjectTaskMilestoneContainer';
import { dropDownEllipsisStyle } from '../../Layout/styles';
import TaskDataUpload from '../Settings/Datamanagement/TaskDataUpload.component';
import { PROJECT_TASK_DATA_UPLOAD_TOOLTIP_LABEL } from './Constant';

export const ProjectKanbanContainer = (props: any) => {
	const { project, projectTeamMembers, projectMilestoneFilter } = props;
	const { id: userId, permissions } = useContext<any>(UserProfileContext);
	const { projecTaskFiltersState, dispatchProjectTaskFilters, ACTIONS } =
		useContext<any>(ProjectTaskContext);
	const [isListShown, setIsListShown] = useState(false);
	const [isCreateTaskModalShown, setIsCreateTaskModalShown] = useState(false);
	const [isUploadTaskModalShown, setIsUploadTaskModalShown] = useState(false);
	const [filterValues, setFilterValues] = useState<any>({
		assigned_to: userId,
	});
	const [isFilterCleared, setIsFilterCleared] = useState(false);
	const { id }: { id: string } = useParams();
	const history = useHistory();
	const ellipsisStyles = dropDownEllipsisStyle();
	const [isCustomDrawerShown, setCustomDrawerShown] = useState(false);
	const projectKanbanRef = useRef<any>();
	const [projectCategoryOptions, setProjectCategoryOptions] = useState<any>([]);

	const allAssigneSelected =
		projecTaskFiltersState?.projectTaskAssigneeFilter?.length ===
			projectTeamMembers?.length || 0
			? true
			: false;

	const allMilestoneSelected =
		projecTaskFiltersState?.projectTaskMilestoneFilter?.length ===
			projectMilestoneFilter?.length || 0
			? true
			: false;

	const { data: projectMembers } = useGetProjectAssignedUserQuery({
		variables: { project_id: id },
		fetchPolicy: 'network-only',
	});
	const { data: projectMilestoneList } = useGetProjectMileStoneListQuery({
		variables: {
			projectId: id,
		},
	});
	const { data: projectCategories } = useGetProjectTaskCategoriesOptionsQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!projectCategories || isEmpty(projectCategories?.project_category)) {
			setProjectCategoryOptions([]);
			return;
		}
		const response = projectCategories?.project_category?.map(
			(projectCategory) => {
				return {
					value: projectCategory.id,
					label: projectCategory.name,
				};
			}
		);
		setProjectCategoryOptions(response);
	}, [projectCategories]);

	const getCrmTaskAppliedFilters = (filter: string, filterValues: any) => {
		switch (filter) {
			case 'priority':
				return (
					filterValues?.priority &&
					filterValues?.priority.length > 0 &&
					filterValues?.priority.map((priority: string, index: number) => (
						<div key={index}>
							<Chip key={index} label={`${priority}`} variant='outlined' />
						</div>
					))
				);
			default:
				return filter;
		}
	};
	const getProjectCategoryFilterLimits = () => (
		<div
			style={{
				marginTop: '7px',
				color: '#4e4e4e',
				marginLeft: '10px',
			}}
		>
			{projecTaskFiltersState?.projectTaskCategoryFilter?.length === 1
				? projecTaskFiltersState?.projectTaskCategoryFilter[0].label
				: `Selected ${
						projecTaskFiltersState?.projectTaskCategoryFilter?.length || 0
				  } items`}
		</div>
	);

	const getAssigneFilterLimit = () => {
		return (
			<div
				style={{
					marginTop: '7px',
					color: '#4e4e4e',
					marginLeft: '10px',
				}}
				className={`${ellipsisStyles.ellipsis}`}
			>
				{projecTaskFiltersState?.projectTaskAssigneeFilter?.length === 1
					? projecTaskFiltersState?.projectTaskAssigneeFilter[0].label
					: `Selected ${
							projecTaskFiltersState?.projectTaskAssigneeFilter?.length || 0
					  } items`}
			</div>
		);
	};

	const getMilestoneFilterLimit = () => {
		return (
			<div
				style={{
					marginTop: '7px',
					color: '#4e4e4e',
					marginLeft: '10px',
				}}
			>
				{projecTaskFiltersState?.projectTaskMilestoneFilter?.length === 1
					? projecTaskFiltersState?.projectTaskMilestoneFilter[0].label
					: `Selected ${
							projecTaskFiltersState?.projectTaskMilestoneFilter?.length || 0
					  } items`}
			</div>
		);
	};

	const handleSelectAssigneAll = (isSelected: boolean) => {
		if (isSelected) {
			const response = projectMembers?.project_resource_mapping?.map(
				(value) => {
					return {
						value: value?.user?.id,
						label:
							value?.user?.id === userId ? 'Me' : `${value?.user?.full_name}`,
					};
				}
			);
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
				payload: response,
			});
		} else {
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
				payload: [],
			});
		}
	};

	const handleAssigneToggle = () => {
		handleSelectAssigneAll(!allAssigneSelected);
	};

	const handleMilestoneToggle = () => {
		handleSelectMilestoneAll(!allMilestoneSelected);
	};

	const handleCategoryToggleLabel = () => {
		const allProjectCategorySelected =
			projecTaskFiltersState?.projectTaskCategoryFilter?.length ===
				projectCategories?.project_category.length || 0
				? true
				: false;
		handleSelectCategoryAll(!allProjectCategorySelected);
	};

	const handleSelectMilestoneAll = (isSelected: boolean) => {
		if (isSelected) {
			const milestoneInfo = projectMilestoneList?.project_milestone?.map(
				(value) => {
					return {
						value: value?.id,
						label: value?.name,
					};
				}
			);
			if (milestoneInfo) {
				dispatchProjectTaskFilters({
					type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
					payload: [
						...milestoneInfo,
						{ label: 'UnAssigned', value: 'UnAssigned' },
					],
				});
			}
		} else {
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectCategoryAll = (isSelected: boolean) => {
		if (isSelected) {
			const response = projectCategories?.project_category?.map(
				(projectCategory) => {
					return {
						value: projectCategory.id || '',
						label: projectCategory.name || '',
					};
				}
			);
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER,
				payload: response,
			});
		} else {
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER,
				payload: [],
			});
		}
	};

	return (
		<>
			<div
				style={{ marginTop: '30px', paddingLeft: '0px' }}
				className={'projectTaskKanbanToolbarContainer'}
			>
				<AppliedProjectTaskFiltersContainer
					clearFilters={() => {
						setFilterValues([]);
						setIsFilterCleared(true);
					}}
					filterValues={filterValues}
				>
					{getCrmTaskAppliedFilters}
				</AppliedProjectTaskFiltersContainer>
			</div>
			<div className={'projectTaskKanbanToolbarContainer'}>
				<div className={'projectTaskKanbanFilterContainer'}>
					<form onSubmit={(event) => event.preventDefault()}>
						<ThemeProvider theme={searchBarTheme}>
							<div
								style={{
									width: '300px',
								}}
							>
								<TextField
									placeholder='Search Tasks'
									label={false}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(e) => {
										dispatchProjectTaskFilters({
											type: ACTIONS.UPDATE_PROJECT_TASK_SEARCH_TEXT,
											payload: e?.target?.value,
										});
									}}
									value={projecTaskFiltersState.projectTaskSearchText || ''}
								/>
							</div>
						</ThemeProvider>
					</form>
					<div
						className={'projectTaskKanbanAssigneeFilterContainer'}
						style={{ width: '182px' }}
					>
						<form>
							<Autocomplete
								multiple
								value={
									projecTaskFiltersState?.projectTaskAssigneeFilter
										? projecTaskFiltersState?.projectTaskAssigneeFilter
										: []
								}
								limitTags={0}
								selectOnFocus={true}
								disablePortal
								fullWidth={false}
								onChange={(event, value, reason) => {
									if (!value) {
										return;
									}
									if (
										reason === 'select-option' ||
										reason === 'remove-option'
									) {
										if (value.find((option) => option.value === 'All')) {
											handleAssigneToggle();
											return;
										} else {
											dispatchProjectTaskFilters({
												type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
												payload: value,
											});
											return;
										}
									} else if (reason === 'clear') {
										dispatchProjectTaskFilters({
											type: ACTIONS.UPDATE_PROJECT_TASK_ASSIGNEE_FILTER,
											payload: [],
										});
										return;
									}
								}}
								options={
									projectTeamMembers.length > 0
										? [{ label: 'All', value: 'All' }, ...projectTeamMembers]
										: []
								}
								disableCloseOnSelect
								getLimitTagsText={getAssigneFilterLimit}
								ChipProps={{ style: { display: 'none' } }}
								renderInput={(params) => (
									<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
										<TextField
											{...params}
											label=''
											placeholder={
												projecTaskFiltersState?.projectTaskAssigneeFilter &&
												projecTaskFiltersState?.projectTaskAssigneeFilter
													.length > 0
													? ''
													: 'Search Assignee'
											}
										/>
									</ThemeProvider>
								)}
								renderOption={(option, { selected }) => {
									const selectAllProps =
										option.value === 'All' // To control the state of 'select-all' checkbox
											? { checked: allAssigneSelected }
											: {};
									return (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected || false}
												color='primary'
												{...selectAllProps}
											/>

											<Tooltip
												title={`${option?.label || '- -'}`}
												placement='right'
											>
												<Typography className={`${ellipsisStyles.ellipsis}`}>
													{`${option?.label || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									);
								}}
								getOptionLabel={(option) => `${option?.label}`}
								getOptionSelected={(option, value) =>
									option.value === value.value
								}
							/>
						</form>
					</div>
					<div
						className={'projectTaskKanbanAssigneeFilterContainer'}
						style={{ width: '182px' }}
					>
						<Autocomplete
							multiple
							value={
								projecTaskFiltersState?.projectTaskMilestoneFilter
									? projecTaskFiltersState?.projectTaskMilestoneFilter
									: []
							}
							limitTags={0}
							selectOnFocus={true}
							disablePortal
							fullWidth={false}
							onChange={(event, value, reason) => {
								if (!value) {
									return;
								}
								if (reason === 'select-option' || reason === 'remove-option') {
									if (value.find((option) => option.value === 'All')) {
										handleMilestoneToggle();
										return;
									} else {
										dispatchProjectTaskFilters({
											type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
											payload: value,
										});
										return;
									}
								} else if (reason === 'clear') {
									dispatchProjectTaskFilters({
										type: ACTIONS.UPDATE_PROJECT_TASK_MILESTONE_FILTER,
										payload: [],
									});
									return;
								}
							}}
							options={
								projectMilestoneFilter?.length > 0
									? [{ label: 'All', value: 'All' }, ...projectMilestoneFilter]
									: []
							}
							disableCloseOnSelect
							getLimitTagsText={getMilestoneFilterLimit}
							ChipProps={{ style: { display: 'none' } }}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextField
										{...params}
										label=''
										placeholder={
											projecTaskFiltersState?.projectTaskMilestoneFilter &&
											projecTaskFiltersState?.projectTaskMilestoneFilter
												.length > 0
												? ''
												: 'Search Milestone'
										}
									/>
								</ThemeProvider>
							)}
							renderOption={(option, { selected }) => {
								const selectAllProps =
									option.value === 'All' // To control the state of 'select-all' checkbox
										? { checked: allMilestoneSelected }
										: {};
								return (
									<>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>
										<div style={{ fontSize: '12px' }}>{`${option?.label}`}</div>
									</>
								);
							}}
							getOptionLabel={(option) => `${option?.label}`}
							getOptionSelected={(option, value) =>
								option.value === value.value
							}
						/>
					</div>
					<div
						className={'projectTaskKanbanAssigneeFilterContainer'}
						style={{ width: '182px' }}
					>
						<form onSubmit={(event) => event.preventDefault()}>
							<Autocomplete
								multiple
								value={
									projecTaskFiltersState
										? projecTaskFiltersState?.projectTaskCategoryFilter
										: []
								}
								limitTags={0}
								selectOnFocus={true}
								disablePortal
								fullWidth={false}
								onChange={(event, value, reason) => {
									if (!value) {
										return;
									}
									if (
										reason === 'select-option' ||
										reason === 'remove-option'
									) {
										if (value.find((option) => option.value === 'All')) {
											handleCategoryToggleLabel();
											return;
										} else {
											dispatchProjectTaskFilters({
												type: ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER,
												payload: value,
											});
											return;
										}
									} else if (reason === 'clear') {
										dispatchProjectTaskFilters({
											type: ACTIONS.UPDATE_PROJECT_TASK_CATEGORY_FILTER,
											payload: [],
										});
										return;
									}
								}}
								options={
									projectCategoryOptions.length > 0
										? [
												{ label: 'All', value: 'All' },
												...projectCategoryOptions,
										  ]
										: []
								}
								disableCloseOnSelect
								getLimitTagsText={getProjectCategoryFilterLimits}
								ChipProps={{ style: { display: 'none' } }}
								renderInput={(params) => (
									<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
										<TextField
											{...params}
											label=''
											placeholder={
												projecTaskFiltersState?.projectTaskCategoryFilter &&
												projecTaskFiltersState?.projectTaskCategoryFilter
													.length > 0
													? ''
													: 'Search Category'
											}
										/>
									</ThemeProvider>
								)}
								renderOption={(option, { selected }) => {
									const allProjectCategorySelected =
										projecTaskFiltersState?.projectTaskCategoryFilter
											?.length === projectCategories?.project_category.length ||
										0
											? true
											: false;
									const selectAllProps =
										option.value === 'All' // To control the state of 'select-all' checkbox
											? { checked: allProjectCategorySelected }
											: {};
									return (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected || false}
												color='primary'
												{...selectAllProps}
											/>
											<div
												style={{ fontSize: '12px' }}
											>{`${option?.label}`}</div>
										</>
									);
								}}
								getOptionLabel={(option) => `${option?.label}`}
								getOptionSelected={(option, value) =>
									option.value === value.value
								}
							/>
						</form>
					</div>
				</div>
				<ProjectKanbanFilterContainer
					isFilterCleared={isFilterCleared}
					choosedFilters={filterValues}
					formValue={(value: any) => {
						setFilterValues((previousFilterValues: any) => ({
							...previousFilterValues,
							...value,
						}));
					}}
					clearFilterValues={() => {
						setFilterValues([]);
						setIsFilterCleared(true);
					}}
					open={isCustomDrawerShown}
					close={() => {
						setCustomDrawerShown(false);
					}}
				>
					<ProjectTaskFilterFormBody />
				</ProjectKanbanFilterContainer>
				<div
					style={{
						marginRight: isListShown ? 0 : '20px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<button
						hidden={isListShown}
						className={'projectTaskKanbanFilterButton'}
						onClick={() => {
							projectKanbanRef.current.loadKanbanData();
						}}
					>
						<CachedIcon />
					</button>
					<button
						className={[
							'projectTaskKanbanIconButton',
							!isListShown ? 'projectTaskKanbanActiveIconButton' : '',
						].join(' ')}
						onClick={() => {
							setIsListShown(false);
						}}
					>
						<KanbanIcon />
					</button>
					<button
						className={'projectTaskKanbanFilterButton'}
						onClick={() => setCustomDrawerShown(true)}
					>
						<FilterListIcon />
					</button>
					{permissions?.project?.update_permissions && (
						<Tooltip title='Navigate to Project details'>
							<button
								className={'projectTaskKanbanFilterButton'}
								onClick={() => {
									history.push(`/project/detail/${id}`);
								}}
							>
								<SettingsOutlined />
							</button>
						</Tooltip>
					)}
					<button
						className={'projectTaskKanbanAddTaskButton'}
						onClick={() => {
							setIsCreateTaskModalShown(true);
						}}
					>
						<AddIcon />
						Add Task
					</button>
					<Tooltip title={PROJECT_TASK_DATA_UPLOAD_TOOLTIP_LABEL}>
						<button
							className={'projectTaskKanbanUploadTaskButton'}
							onClick={() => {
								setIsUploadTaskModalShown(true);
							}}
						>
							<UploadIcon />
							Upload
						</button>
					</Tooltip>
				</div>
			</div>
			{isCreateTaskModalShown && (
				<CreateTaskForm
					onCreateTask={() => {}}
					onClose={() => setIsCreateTaskModalShown(false)}
					open={isCreateTaskModalShown}
					boardId={project?.kanban_id}
					onSuccess={() => {
						projectKanbanRef.current.loadKanbanData();
					}}
				/>
			)}
			{isUploadTaskModalShown && (
				<TaskDataUpload
					open={isUploadTaskModalShown}
					projectId={project?.id || ''}
					onClose={() => setIsUploadTaskModalShown(false)}
					onSuccess={() => {
						projectKanbanRef.current.loadKanbanData();
					}}
				/>
			)}
			<ProjectKanban
				searchedTask={projecTaskFiltersState.projectTaskSearchText || ''}
				boardId={project?.kanban_id}
				filterValues={filterValues}
				setFilterValues={setFilterValues}
				projectTaskCategory={projecTaskFiltersState?.projectTaskCategoryFilter}
				projectTaskCategoriesCount={
					projectCategories?.project_category.length || 0
				}
				ref={projectKanbanRef}
				assigne={projecTaskFiltersState?.projectTaskAssigneeFilter}
				allMilestoneSelected={allMilestoneSelected}
				milestoneList={projecTaskFiltersState?.projectTaskMilestoneFilter}
				allAssigneSelected={allAssigneSelected}
			/>
		</>
	);
};

export default ProjectKanbanContainer;
