import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	chipContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	multiSelectFilterChip: {
		margin: theme.spacing(0.5),
	},
	resetButton: {
		textDecoration: 'underline',
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		cursor: 'pointer',
		marginLeft: '5px',
		'&:hover': {
			color: '#4285F4',
		},
	},
	label: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		marginRight: '5px',
	},
}));

const AppliedProjectTaskFiltersContainer = (props: any) => {
	const classes = useStyles();
	const [filters, setFilters] = useState<any>({});
	const { children, filterValues, clearFilters } = props;

	useEffect(() => {
		if (!filterValues) {
			return;
		}
		const { assigned_to, ...rest } = filterValues;
		setFilters(rest);
	}, [filterValues]);
	const appliedFilters = Object.keys(filters);
	return (
		<Box>
			{appliedFilters?.length > 0 && (
				<Box display='flex' alignItems='center' height={'30px'}>
					<Typography className={classes.label}>Showing :</Typography>
					{appliedFilters.map((filter, index) => (
						<div key={index} className={classes.chipContainer}>
							{children(filter, filterValues)}
						</div>
					))}
					<Typography
						onClick={() => clearFilters()}
						className={classes.resetButton}
					>
						Clear All
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default AppliedProjectTaskFiltersContainer;
