import React, { useContext, useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	AutocompleteInput,
	useNotify,
	useRefresh,
	minLength,
	number,
	useQuery,
} from 'react-admin';
import { Button, Box } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle, currencyInputStyle } from '../../../Layout/styles';
import { ADD_OPPORTUNITY } from '../gqlQueries';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import AddContact from './AddContactForm.component';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import { UserProfileContext } from '../../../App';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../../SharedComponents/gqlQueries';
import { OrganizationSupportedCurrencies } from '../../../SharedComponents/model';
import dayjs from 'dayjs';
import { head, isEmpty, orderBy } from 'lodash';
import {
	useGetCrmPipelineBoardQuery,
	useGetAssigneeFilterRecommendationsQuery,
} from '../../../generated/graphql';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';

interface OpportunityForm {
	currency_id?: string;
	status?: string;
	name?: string;
	win_percentage?: number;
	value?: number;
	priority?: string;
	close_date?: string;
	company_id?: string;
	primary_contact_id?: string;
	owner_id?: string;
	lead_owner_id?: string;
}

interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
}
interface Value {
	id: string;
	name: string;
}
interface Contact {
	id: string;
	first_name: string;
	last_name: string;
	full_name: string;
}
interface kanbanColumnProps {
	board_id: string;
	created_at: Date;
	id: string;
	name: string;
	ordinal: number;
	state: string;
	updated_at: Date;
}

const OpportunitiesForm = (props: Props) => {
	const { onClose, open, refetch } = props;
	const { orgId: organizationId, dateFormat } =
		useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const notify = useNotify();
	const refresh = useRefresh();
	const [contactDetails, setContactDetails] = useState({});
	const [isAddContactFormShown, setIsAddContactFormShown] = useState(false);
	const [crmContact, setCrmContact] = useState([]);
	const [kanbanColumn, setKanbanColumn] = useState<Value[] | undefined>();
	const [currencyList, setCurrencyList] = useState([]);
	const [pipelineBoard, setPipelineBoard] = useState<Value[]>();
	const [assigneId, setAssigneId] = useState<any>([]);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);
	const [boardId, setBoardId] = useState('');
	const [opportunityForm, setOpportunityForm] = useState<OpportunityForm>({
		priority: 'Medium',
		win_percentage: 50,
		close_date: dayjs().add(7, 'days').format('YYYY-MM-DD'),
	});
	const classes = modalFormStyle();
	const customStyle = currencyInputStyle();
	const [addOpportunity, { loading: isOpportunityLoading }] =
		useApolloMutation(ADD_OPPORTUNITY);

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	const { data: kanbanBoard } = useGetCrmPipelineBoardQuery();
	const { data: assignedUser } = useGetAssigneeFilterRecommendationsQuery();

	const { data: organisationSupportedCurrencies } = useApolloQuery(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);

	const { data: crmContacts } = useQuery({
		type: 'GET_LIST',
		resource: 'crm_contacts',
		payload: {
			filter: {
				company_id: id || null,
			},
		},
	});

	const { data: kanbanColumns, refetch: refetchKanbanColumns } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'kanban_column',
			payload: {
				sort: { field: 'ordinal', order: 'ASC' },
				filter: {
					board_id: boardId,
				},
			},
		},
		{
			enabled: !!boardId,
		}
	);

	useEffect(() => {
		if (!assignedUser?.user) {
			return;
		}
		const invitedUser = assignedUser.user?.map((user) => {
			return {
				id: user?.id,
				name: `${user?.full_name}`,
			};
		});
		const sortedAssigneId = orderBy(invitedUser, ['name'], ['asc']);
		setAssigneId(sortedAssigneId);
	}, [assignedUser]);

	useEffect(() => {
		if (
			!organisationSupportedCurrencies ||
			!(organisationSupportedCurrencies?.org_supported_currencies.length > 0)
		) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					if (currency?.currency?.id === currency?.organization?.currency_id) {
						setOpportunityForm((previousOpportunityForm) => ({
							...previousOpportunityForm,
							currency_id: currency?.currency?.id,
						}));
					}
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);
		setCurrencyList(organizationCurrencyChoices);
	}, [organisationSupportedCurrencies]);

	useEffect(() => {
		if (isEmpty(kanbanBoard?.kanban_board)) {
			return;
		}
		const board = kanbanBoard?.kanban_board?.map((value) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		if (!isEmpty(board) && board?.length === 1) {
			setOpportunityForm((previousOpportunityForm) => ({
				...previousOpportunityForm,
				board_id: head(board)?.id,
			}));
			setBoardId(head(board)?.id);
		}
		setPipelineBoard(board);
	}, [kanbanBoard]);

	useEffect(() => {
		if (crmContacts?.length > 0) {
			const companyContacts = crmContacts.map((contact: Contact) => {
				return {
					id: contact?.id,
					name: `${contact?.full_name} `,
				};
			});
			const sortedContacts: any = orderBy(
				companyContacts,
				[(contact) => contact.name.toUpperCase()],
				['asc']
			);
			setCrmContact(sortedContacts);
		} else {
			setCrmContact([]);
		}
	}, [opportunityForm, crmContacts, id]);

	useEffect(() => {
		if (isEmpty(kanbanColumns)) {
			setKanbanColumn([]);
			return;
		}
		const kanbanData = kanbanColumns?.map((value: Value) => {
			return { id: value?.id, name: value?.name };
		});
		const sortedKanban = orderBy(
			kanbanData,
			[(column) => column.name.toUpperCase()],
			['asc']
		);
		setKanbanColumn(sortedKanban);
	}, [kanbanColumns, opportunityForm.status, boardId]);

	const onDialogueClose = () => {
		setOpportunityForm({
			priority: 'Medium',
			currency_id: opportunityForm?.currency_id,
			status: '',
		});
		setOwnerId(null);
		setPresalesOwnerId(null);
		onClose();
	};

	const onSave = () => {
		addOpportunity({
			variables: {
				data: {
					name: opportunityForm?.name,
					currency_id: opportunityForm.currency_id,
					win_percentage: opportunityForm.win_percentage || 0,
					priority: opportunityForm.priority,
					close_date: opportunityForm.close_date,
					value: opportunityForm.value || 0,
					company_id: id,
					primary_contact_id: opportunityForm.primary_contact_id,
					owner_id: presalesOwnerId || null,
					lead_owner_id: ownerId || null,
					opportunity_kanban_cards: {
						data: {
							column_id: opportunityForm?.status,
						},
					},
				},
			},
		}).then((data) => {
			notify('Opportunity Created Successfully');
			refresh();
			refetch();
			refetchKanbanColumns();
			onDialogueClose();
		});
	};

	const onContactDialogueClose = () => {
		setIsAddContactFormShown(false);
	};

	const onCreateContact = (contact: string) => {
		setContactDetails({
			first_name: contact,
			company_id: id,
		});
		setIsAddContactFormShown(true);
	};

	useEffect(() => {
		if (isEmpty(kanbanColumn)) {
			return;
		}
		const pipelineStatus = head(kanbanColumn)?.id;
		setOpportunityForm((previousOpportunityForm) => ({
			...previousOpportunityForm,
			board_id: boardId,
			win_percentage: 50,
			status: pipelineStatus,
		}));
	}, [kanbanColumn, boardId]);

	useEffect(() => {
		if (pipelineBoard?.length === 1 && kanbanColumns?.length > 0) {
			const opportunityStatusId = kanbanColumns?.find(
				(item: kanbanColumnProps) => item?.ordinal === 1
			);
			if (!opportunityStatusId) {
				return;
			}
			setOpportunityForm((previousOpportunityForm) => ({
				...previousOpportunityForm,
				status: opportunityStatusId.id,
			}));
		}
	}, [pipelineBoard, kanbanColumns]);

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box width='464px' height='550px' borderRadius='4px'>
					<Box className={customStyle.headerContainer}>
						<Box className={customStyle.heading}>
							Add Opportunities{' '}
							{isAddContactFormShown ? (
								<>
									<DoubleArrowIcon className={customStyle.arrowIcon} />
									<Box className={customStyle.subHeading}>Add Contact</Box>
								</>
							) : null}
						</Box>
						{isAddContactFormShown ? (
							<CloseIcon
								className={classes.closeIcon}
								onClick={onContactDialogueClose}
							/>
						) : (
							<CloseIcon
								className={classes.closeIcon}
								onClick={onDialogueClose}
							/>
						)}
					</Box>
					<DialogContent>
						<Box className={customStyle.formContainer}>
							{!isAddContactFormShown ? (
								<Form initialValues={opportunityForm} onSubmit={onSave}>
									{({ handleSubmit, invalid }) => (
										<form onSubmit={handleSubmit}>
											<Box className={classes.label}>Opportunity Name *</Box>
											<TextInput
												source='name'
												fullWidth={true}
												label={''}
												validate={[
													required(),
													minLength(3, 'minimum three characters required'),
												]}
												onChange={(e: any) => {
													setOpportunityForm({
														...opportunityForm,
														name: e?.target?.value,
													});
												}}
											/>
											<Box className={classes.label}>Pipeline*</Box>
											<SelectInput
												source={'board_id'}
												label={''}
												choices={pipelineBoard}
												onChange={(event: any) =>
													setBoardId(event?.target?.value)
												}
												validate={required()}
											/>
											<Box className={classes.multipleInputContainer}>
												<Box width='40%' marginRight='5px'>
													<Box className={classes.label}>Stage *</Box>
													<SelectInput
														source='status'
														label={''}
														validate={required()}
														choices={kanbanColumn || []}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																status: e?.target?.value,
															});
														}}
													/>
												</Box>
												<Box width='30%' marginRight='5px'>
													<Box className={classes.label}>Win %</Box>
													<TextInput
														source='win_percentage'
														label={''}
														name='win_percentage'
														validate={number()}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																win_percentage: e?.target?.value,
															});
														}}
													/>
												</Box>
												<Box width='40%'>
													<Box className={classes.label}>Priority</Box>
													<SelectInput
														source='priority'
														label=''
														fullWidth={true}
														choices={[
															{ id: 'Low', name: 'Low' },
															{ id: 'Medium', name: 'Medium' },
															{ id: 'High', name: 'High' },
														]}
														name='priority'
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																priority: e?.target?.value,
															});
														}}
													/>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Value</Box>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Close Date</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={customStyle.boxContainer}>
													<Box className={customStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<SelectInput
																source='currency_id'
																label=''
																choices={currencyList || []}
																onChange={(e: any) => {
																	setOpportunityForm({
																		...opportunityForm,
																		currency_id: e,
																	});
																}}
															/>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='value'
															label={''}
															validate={number()}
															onChange={(e: any) => {
																setOpportunityForm({
																	...opportunityForm,
																	value: e?.target?.value,
																});
															}}
														/>
													</ThemeProvider>
												</Box>
												<Box>
													<Field
														name='close_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div className={customStyle.dateInput}>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value: any) => {
																		props.input.onChange(value);
																		setOpportunityForm({
																			...opportunityForm,
																			close_date: value,
																		});
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Lead Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={setOwnerId}
														name={'lead_owner_id'}
														validates={false}
														value={getOwnerValue(ownerId || '')}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Pre-Sales Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={assigneId || []}
														onChange={setPresalesOwnerId}
														name={'owner_id'}
														validates={false}
														value={getOwnerValue(presalesOwnerId || '')}
													/>
												</Box>
											</Box>

											<Box className={classes.label}>Contact</Box>
											<AutocompleteInput
												optionText={'name'}
												choices={crmContact}
												source='primary_contact_id'
												label={''}
												resettable={true}
												shouldRenderSuggestions={(name: any) =>
													name.trim().length > 0
												}
												onChange={(event: any) => {
													setOpportunityForm({
														...opportunityForm,
														primary_contact_id: event,
													});
												}}
												onCreate={(event: any) => onCreateContact(event)}
											/>
											<Box className={classes.buttonContainer}>
												<Button
													className={
														invalid || isOpportunityLoading
															? classes.disabledStartButton
															: classes.updateButton
													}
													disabled={invalid || isOpportunityLoading}
													type='submit'
												>
													Create
												</Button>
												<Button
													onClick={onDialogueClose}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
											</Box>
										</form>
									)}
								</Form>
							) : (
								<>
									<AddContact
										onClose={() => setIsAddContactFormShown(false)}
										contact={contactDetails}
										setContactId={(name: string) =>
											setOpportunityForm({
												...opportunityForm,
												primary_contact_id: name,
											})
										}
									/>
								</>
							)}
						</Box>
					</DialogContent>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default OpportunitiesForm;
