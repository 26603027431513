import React from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	ThemeProvider,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { TextInput, required, useNotify, maxLength } from 'react-admin';

import { formStyle } from '../pms.styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { useInsertVisionMutation } from '../../../generated/graphql';
import {
	REQUIRED_ERROR_TEXT,
	VISION_CREATED,
	VISION_UPDATED,
} from '../constant';
import {
	CREATE_BUTTON_LABEL,
	regexNameValidation,
	UPDATE_BUTTON_LABEL,
	REGEX_FAILED_ERROR_TEXT,
} from './constant';
import { VisionFormInitialValue } from '../pms.module';
import Loader from '../SharedComponents/Loader.component';

interface VisionFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: VisionFormInitialValue;
	isEditMutation: boolean;
	onSuccess: () => void;
}

interface FormValues {
	name: string;
	description?: string;
}

function VisionForm(props: VisionFormProps) {
	const { open, onClose, isEditMutation, onSuccess, initialValues } = props;
	const formStyles = formStyle();
	const notify = useNotify();

	const [insertVision, { loading: isVisionQueryLoading }] =
		useInsertVisionMutation();

	const handleSubmit = (formValue: FormValues) => {
		if (!formValue) return;

		const { name, description } = formValue;
		const visionName = name?.trim();
		const visionDescription = description?.trim();

		insertVision({
			variables: {
				data: initialValues?.id
					? {
							id: initialValues?.id,
							name: visionName,
							description: visionDescription,
					  }
					: {
							name: visionName,
							description: visionDescription,
					  },
			},
		}).then((response: any) => {
			if (!response.error) {
				notify(initialValues?.id ? VISION_UPDATED : VISION_CREATED);
				onSuccess();
				onClose();
			}
		});
	};

	const validateVisionFormValue = (formValue: {
		name: string;
		description?: string;
	}) => {
		const visionFormError = {} as { name: string; description?: string };

		const { name } = formValue;

		if (!name || name.trim() === '') {
			visionFormError.name = REQUIRED_ERROR_TEXT;
		}
		if (!regexNameValidation.test(name)) {
			visionFormError.name = REGEX_FAILED_ERROR_TEXT;
		}

		return visionFormError;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				disableBackdropClick
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={'sm'}
			>
				<div className={formStyles.container}>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>
							{isEditMutation ? `Edit Vision` : `Create Vision`}
						</p>
						<CloseIcon
							className={formStyles.closeIcon}
							onClick={onClose}
						/>
					</div>
					<Form
						onSubmit={handleSubmit}
						initialValues={initialValues?.id ? initialValues : {}}
						validate={validateVisionFormValue}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<div>
									<DialogContent>
										<p className={formStyles.label}>
											Name*
										</p>
										<TextInput
											label=''
											source='name'
											validate={[
												maxLength(100),
												required(),
											]}
										/>

										<p className={formStyles.label}>
											Description
										</p>
										<TextInput
											source='description'
											fullWidth={true}
											label={''}
											multiline
											validate={maxLength(500)}
										/>
										<div
											className={
												formStyles.buttonContainer
											}
										>
											<Button
												className={
													formStyles.cancelButton
												}
												onClick={onClose}
											>
												Cancel
											</Button>

											<Button
												className={
													invalid ||
													pristine ||
													isVisionQueryLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid ||
													pristine ||
													isVisionQueryLoading
												}
												type='submit'
												startIcon={
													isVisionQueryLoading && (
														<Loader />
													)
												}
											>
												{isEditMutation
													? UPDATE_BUTTON_LABEL
													: CREATE_BUTTON_LABEL}
											</Button>
										</div>
									</DialogContent>
								</div>
							</form>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default VisionForm;
