import React, { useContext, useEffect, useState } from 'react';
import { FunctionField } from 'react-admin';
import DetailContainer from '../../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { detailsPageTabDetailsStyle } from '../../../Layout/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import editIcon from '../../../assets/icons/edit-icon.svg';
import EditWarrantyDetails from './EditWarrantyDetails.component';
import { Asset } from '../AssetManagement.model';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
interface Props {
	data: Asset;
	refetch: () => void;
}
const AssetWarrantyDetails = (props: Props) => {
	const { data: asset, refetch } = props;
	const { permissions, dateFormat } = useContext<any>(UserProfileContext);
	const classes = detailsPageTabDetailsStyle();
	const [openEditModal, setOpenEditModal] = useState(false);
	const [assetDetails, setAssetDetails] = useState({});
	useEffect(() => {
		if (!asset) {
			return;
		}
		setAssetDetails({
			manufacturer: asset?.manufacturer,
			acquisition_date: asset?.acquisition_date,
			warranty_expiration_date: asset?.warranty_expiration_date,
		});
	}, [asset]);
	return (
		<>
			{permissions?.asset?.update_permissions && (
				<Box
					className={classes.editContainer}
					onClick={() => setOpenEditModal(true)}
				>
					<img className={classes.editButton} src={editIcon} alt='edit icon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			)}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Manufacturer</Typography>
					<FunctionField
						record={asset}
						render={(record: any) => {
							return (
								<Tooltip
									title={`${record?.manufacturer || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${classes.ellipsis}`}
									>
										{`${record?.manufacturer || '- -'} `}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Acquisition date</Typography>
					<FunctionField
						className={classes.value}
						record={asset}
						render={(asset: any) =>
							`${
								asset?.acquisition_date
									? dayjs(asset?.acquisition_date).format(dateFormat)
									: '- -'
							}`
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Warranty Expiration Date
					</Typography>
					<FunctionField
						className={classes.value}
						record={asset}
						render={(asset: any) =>
							`${
								asset?.warranty_expiration_date
									? dayjs(asset?.warranty_expiration_date).format(dateFormat)
									: '- -'
							}`
						}
					/>
				</FieldContainer>
			</DetailContainer>
			<EditWarrantyDetails
				open={openEditModal}
				onClose={() => setOpenEditModal(false)}
				record={assetDetails}
				refetch={refetch}
			/>
		</>
	);
};

export default AssetWarrantyDetails;
