import React, { useContext, useEffect, useState } from 'react';

import { head,isEmpty} from 'lodash';
import {
	SelectInput,
	CheckboxGroupInput,
	useNotify,
	useRefresh,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import {
	hoursOptions,
	minutesOptions,
	startOfWeekOptions,
	weekendOptions,
} from './constant';

import {
	Typography,
	Button,
	Box,
	ThemeProvider,
	makeStyles,
	createTheme,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { OrganisationResponse } from './Organisation.model';
import { UserProfileContext } from '../../../App';
import { GET_ORGANISATION, UPDATE_ORGANISATION } from '../Settings.gql';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';

const organisationTimingStyle = makeStyles({
	formContainer: {
		width: '34vw',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '20px',
		marginBottom: '20px',
		marginLeft: '20px',
	},
	groupCheckBoxContainer: {
		marginBottom: '30px',
	},
	checkboxGroupInput: {
		border: '1px solid lightgrey',
		borderRadius: '4px',
		minWidth: '502px',
	},
	dateContainer: {
		marginRight: '10px',
		width: '10.5vw',
	},
});

interface OrganisationTiming {
	start_time?: string;
	end_time?: string;
	weekend?: string[];
	start_week?: string;
	hours?: number;
	minutes?: number;
	__typename?: string;
}

const checkBoxGroupInputTheme = createTheme({
	overrides: {
		MuiFormControlLabel: {
			root: {
				flexDirection: 'column-reverse',
				marginRight: '20px',
				marginLeft: '20px',
			},
		},
		MuiTypography: {
			body1: {
				font: 'normal normal bold 12px/37px Manrope',
				fontSize: '12px !important',
				letterSpacing: '0px',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '14px',
				marginBottom: '0px',
			},
		},
		MuiFormGroup: {
			row: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	},
});

const OrganisationTiming = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const modalFormsettingsStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [
		organisationTimingsFormInitialValue,
		setorganisationTimingsFormInitialValue,
	] = useState({});
	const organisationTimingStyles = organisationTimingStyle();
	const { data: organisation, refetch: refetchOrganisation } =
		useApolloQuery<OrganisationResponse>(GET_ORGANISATION, {
			variables: {
				organisationId: organisationId,
			},
		});
	const [updateOrganisationTiming, { data: updatedOrganisationTiming }] =
		useApolloMutation(UPDATE_ORGANISATION);

	useEffect(() => {
		if (isEmpty(organisation?.organization)) {
			return;
		}
		setorganisationTimingsFormInitialValue({
			hours: Number(
				head(organisation?.organization)?.working_hours?.toString().split('.')[0]
			), // Before the decimal
			minutes: getMinutes(head(organisation?.organization)?.working_hours),
			start_week: head(organisation?.organization)?.start_week,
			weekend: head(organisation?.organization)?.weekend,
		});
	}, [organisation]);

	useEffect(() => {
		if (!updatedOrganisationTiming) {
			return;
		}
		notify('Organisation Timing Updated');
		refresh();
	}, [updatedOrganisationTiming, notify, refresh]);

	useEffect(() => {
		if (!updatedOrganisationTiming) {
			return;
		}
		refetchOrganisation();
	}, [updatedOrganisationTiming, refetchOrganisation]);

	const onHandleSubmit = (organisationTiming: OrganisationTiming) => {
		const { __typename, hours, minutes, ...organisationTiminingPayload } =
			organisationTiming;
		const working_hours = (hours || 0) + (minutes || 0);

		updateOrganisationTiming({
			variables: {
				organisationId: organisationId,
				data: !working_hours
					? {
							...organisationTiminingPayload,
					  }
					: {
							...organisationTiminingPayload,
							working_hours,
					  },
			},
		});
	};

	const getMinutes = (workingHours?: number) => {
		if (!workingHours) {
			return;
		}
		const fraction = workingHours?.toString().split('.')[1];
		return !fraction ? 0 : Number(`0.${fraction}`);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={organisationTimingStyles.formContainer}>
				<Form
					initialValues={organisationTimingsFormInitialValue}
					onSubmit={onHandleSubmit}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={settingsStyles.inputContainer}>
								<Box className={settingsStyles.inputLabel}>
									<Typography className={modalFormsettingsStyles.label}>
										Start of the Week
									</Typography>
								</Box>
								<Box className={settingsStyles.input}>
									<SelectInput
										source='start_week'
										label=''
										choices={startOfWeekOptions}
									/>
								</Box>
							</Box>
							<Box className={organisationTimingStyles.groupCheckBoxContainer}>
								<Box className={settingsStyles.inputLabel}>
									<Typography className={modalFormsettingsStyles.label}>
										Weekend
									</Typography>
								</Box>
								<ThemeProvider theme={checkBoxGroupInputTheme}>
									<Box className={organisationTimingStyles.checkboxGroupInput}>
										<CheckboxGroupInput
											source='weekend'
											label=''
											choices={weekendOptions}
										/>
									</Box>
								</ThemeProvider>
							</Box>
							<Box className={settingsStyles.inputContainer}>
								<Box className={settingsStyles.inputLabel}>
									<Typography className={modalFormsettingsStyles.label}>
										Working Hours
									</Typography>
								</Box>
								<Box className={organisationTimingStyles.dateContainer}>
									<SelectInput source='hours' label='' choices={hoursOptions} />
								</Box>
								<Box className={organisationTimingStyles.dateContainer}>
									<SelectInput
										source='minutes'
										label=''
										choices={minutesOptions}
									/>
								</Box>
							</Box>
							<Box
								className={modalFormsettingsStyles.buttonContainer}
								style={{ padding: 0 }}
							>
								<Button
									variant='contained'
									disabled={invalid || pristine}
									className={
										invalid || pristine
											? modalFormsettingsStyles.disabledButton
											: modalFormsettingsStyles.saveButton
									}
									type='submit'
								>
									Save
								</Button>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default OrganisationTiming;
