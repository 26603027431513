import {
	AddTaskPayload,
	WeeklyTimesheetEntries,
	WorkingHours,
} from './Timesheet.model';
import dayjs from 'dayjs';
import { GetWeeklyTimesheetEntriesQuery } from '../../generated/graphql';
import {
	checkIfTimeInputValueHasError,
	getPreviousTimesheetData,
	getTimesheetDataForWeek,
	getTimesheetDisableStatus,
	getTimesheetSubmissions,
} from './Timesheet.utils';
import {
	checkIfOnlyNumbers,
	MASTER_PROJECT_TASK_TYPE,
	ORG_TASK_TYPE,
	PROJECT_TYPE_CATEGORY,
	TIMESHEET_APPROVED_STATUS,
	TIMESHEET_SUBMITTED_STATUS,
} from './constant';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
export const loadWeeklyTimesheetEntries = (
	data: GetWeeklyTimesheetEntriesQuery,
	selectedWeek: string[],
	disableTimesheetEditAfterSubmit: boolean
): WeeklyTimesheetEntries => {
	const timesheetSubmissions = getTimesheetSubmissions(data);
	return {
		isDisabled: getTimesheetDisableStatus(
			disableTimesheetEditAfterSubmit,
			timesheetSubmissions
				? timesheetSubmissions[0]?.timesheets[0].timesheet_submission
						?.timesheet_status.value
				: null
		),
		isSubmissionDisabled:
			(timesheetSubmissions &&
				timesheetSubmissions[0]?.timesheets[0]?.timesheet_submission
					?.timesheet_status.value === TIMESHEET_APPROVED_STATUS) ||
			(timesheetSubmissions &&
				timesheetSubmissions[0]?.timesheets[0]?.timesheet_submission
					?.timesheet_status.value === TIMESHEET_SUBMITTED_STATUS)
				? true
				: false,
		isSaved: true,
		dailyTotalHours: data?.timesheet_daily_hours.map((timesheetDailyHours) => ({
			date: timesheetDailyHours.date,
			totalHours: timesheetDailyHours.sum,
		})),
		timesheetEntries: getTimesheetDataForWeek(data, selectedWeek),
	};
};

export const loadPreviousWeekTimesheetEntries = (
	data: GetWeeklyTimesheetEntriesQuery,
	selectedWeek: string[]
) => {
	const previousWeek = selectedWeek.map((date) =>
		dayjs(date).subtract(7, 'days').format('YYYY-MM-DD')
	);
	return {
		isDisabled: false,
		isSubmissionDisabled: false,
		isSaved: false,
		dailyTotalHours: data?.timesheet_daily_hours.map((timesheetDailyHours) => ({
			date: timesheetDailyHours.date,
			totalHours: timesheetDailyHours.sum,
		})),
		timesheetEntries: getPreviousTimesheetData(data, previousWeek),
	};
};

export const getDailyTotalHours = (
	data: WeeklyTimesheetEntries,
	dates: string[]
) => {
	let workingHours = [] as { id: any; date: any; hoursAndMinutes: any }[] | any;
	workingHours = data.timesheetEntries
		.map((timesheetEntry) =>
			!timesheetEntry.isDeleted
				? timesheetEntry
				: { workingHours: { hoursAndMinutes: '00:00' } }
		)
		.map((hoursByDay) => hoursByDay.workingHours);
	const workingHoursByDate: WorkingHours[] = workingHours.flat(1);

	const dailyTotalHours = dates.map((date) => {
		const totalHoursForDate = workingHoursByDate
			.filter((workingHour) => workingHour?.date === date)
			.map((timesheetDailyHours) =>
				timesheetDailyHours.hoursAndMinutes.split(':')
			)
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, djs) => total.add(djs),
				dayjs.duration({ hours: 0, minutes: 0, seconds: 0 })
			);
		return {
			date: date,
			isMaximumLimitExceed:
				Math.floor(totalHoursForDate.asHours()) >= 24 ? true : false,
			totalHours: `${Math.floor(
				totalHoursForDate.asHours()
			)}:${totalHoursForDate.format('mm')}`,
		};
	});

	const totalHours = dailyTotalHours
		.map((day) => day.totalHours.split(':'))
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, djs) => total.add(djs),
			dayjs.duration({ hours: 0, minutes: 0, seconds: 0 })
		);

	return {
		totalHours: `${Math.floor(totalHours.asHours())}:${totalHours.format(
			'mm'
		)}`,
		dailyHours: dailyTotalHours,
		timesheetEntriesCount: data.timesheetEntries.filter(
			(timesheetEntry) => !timesheetEntry.isDeleted
		).length,
	};
};

export const getTaskTotalHours = (data: WeeklyTimesheetEntries) => {
	const tasks = data?.timesheetEntries.map((timesheetEntry) =>
		!timesheetEntry.isDeleted
			? {
					projectId: timesheetEntry.projectId,
					taskId: timesheetEntry.taskId,
					workingHours: timesheetEntry.workingHours,
			  }
			: {
					workingHours: [{ hoursAndMinutes: '00:00' }],
			  }
	);

	const taskTotalHours = tasks.map((task) => {
		const taskHoursAndMinutes = task.workingHours
			.map((workingHour) => workingHour.hoursAndMinutes)
			.flat(1)
			.map((timesheetHours) => timesheetHours.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, djs) => total.add(djs),
				dayjs.duration({ hours: 0, minutes: 0, seconds: 0 })
			);

		return {
			projectId: task.projectId,
			taskId: task.taskId,
			totalWorkingHours: `${Math.floor(
				taskHoursAndMinutes.asHours()
			)}:${taskHoursAndMinutes.format('mm')}`,
		};
	});
	return taskTotalHours;
};

export const updateTimesheetEntryHour = (
	weeklyTimesheetEntries: WeeklyTimesheetEntries,
	taskId: any,
	timesheetEntryIndex: any,
	updatedHour: any,
	projectId: any,
	timesheetSubmissionId: any,
	isBillable: boolean | null | undefined
) => {
	const getEntriesPayload = (data: {
		id?: any;
		date: any;
		hoursAndMinutes: any;
		isBillable: boolean | null | undefined;
		submissionId?: any;
		isError?: boolean;
		entryNotes?: string | null | undefined;
	}) => {
		return isBillable === undefined || isBillable === true
			? {
					...data,
					hoursAndMinutes: updatedHour,
					isError: checkIfTimeInputValueHasError(updatedHour),
					submissionId: timesheetSubmissionId,
			  }
			: {
					...data,
					hoursAndMinutes: updatedHour,
					isError: checkIfTimeInputValueHasError(updatedHour),
					submissionId: timesheetSubmissionId,
					isBillable: isBillable,
			  };
	};

	return {
		...weeklyTimesheetEntries,
		isSaved: false,
		timesheetEntries: weeklyTimesheetEntries.timesheetEntries.map(
			(timesheetEntry) => {
				return timesheetEntry.taskId === taskId &&
					timesheetEntry.projectId === projectId
					? {
							...timesheetEntry,
							isError: checkIfTimeInputValueHasError(updatedHour),
							workingHours: timesheetEntry.workingHours.map(
								(workingHour, index) => {
									return index === timesheetEntryIndex
										? getEntriesPayload(workingHour)
										: { ...workingHour };
								}
							),
					  }
					: { ...timesheetEntry };
			}
		),
	};
};

export const deleteTaskEntries = (
	weeklyTimesheetEntries: WeeklyTimesheetEntries,
	taskId: any,
	projectId: any
) => {
	return {
		...weeklyTimesheetEntries,
		isSaved: false,
		timesheetEntries: weeklyTimesheetEntries.timesheetEntries.map(
			(timesheetEntry) => {
				return timesheetEntry.taskId === taskId &&
					timesheetEntry.projectId === projectId
					? {
							...timesheetEntry,
							isDeleted: true,
					  }
					: { ...timesheetEntry };
			}
		),
	};
};

export const getWeeklyTimesheetEntriesPayload = (
	weeklyTimesheetEntries: WeeklyTimesheetEntries,
	userId: any
) => {
	return weeklyTimesheetEntries.timesheetEntries
		.map((timesheetEntry) => {
			const projectTasks =
				timesheetEntry.taskType === PROJECT_TYPE_CATEGORY &&
				!timesheetEntry.isDeleted
					? timesheetEntry.workingHours.map((entry) => {
							if (
								entry.hoursAndMinutes === '00:00' ||
								entry.hoursAndMinutes.trim() === ''
							) {
								return {};
							}
							return {
								id: entry?.id,
								project_id: timesheetEntry.projectId,
								task_id: timesheetEntry.taskId,
								master_project_task_id: null,
								org_task_id: null,
								working_hours: checkIfOnlyNumbers.test(entry.hoursAndMinutes)
									? `0${Number(entry.hoursAndMinutes)}:00`
									: entry.hoursAndMinutes,
								user_id: userId,
								date: entry.date,
								is_billable: entry.isBillable,
								timesheet_submission_id: entry?.submissionId,
								notes: entry?.entryNotes
									? entry.entryNotes
									: timesheetEntry?.notes,
							};
					  })
					: [];

			const masterProjectTasks =
				timesheetEntry.taskType === MASTER_PROJECT_TASK_TYPE &&
				!timesheetEntry.isDeleted
					? timesheetEntry.workingHours.map((entry) => {
							if (
								entry.hoursAndMinutes === '00:00' ||
								entry.hoursAndMinutes.trim() === ''
							) {
								return {};
							}
							return {
								id: entry?.id,
								project_id: timesheetEntry.projectId,
								task_id: null,
								master_project_task_id: timesheetEntry.taskId,
								org_task_id: null,
								working_hours: checkIfOnlyNumbers.test(entry.hoursAndMinutes)
									? `0${Number(entry.hoursAndMinutes)}:00`
									: entry.hoursAndMinutes,
								user_id: userId,
								date: entry.date,
								is_billable: entry.isBillable,
								timesheet_submission_id: entry?.submissionId,
								notes: entry?.entryNotes
									? entry.entryNotes
									: timesheetEntry?.notes,
							};
					  })
					: [];

			const orgTasks =
				timesheetEntry.taskType === ORG_TASK_TYPE && !timesheetEntry.isDeleted
					? timesheetEntry.workingHours.map((entry) => {
							if (
								entry.hoursAndMinutes === '00:00' ||
								entry.hoursAndMinutes.trim() === ''
							) {
								return {};
							}
							return {
								id: entry?.id,
								project_id: null,
								task_id: null,
								master_project_task_id: null,
								org_task_id: timesheetEntry.taskId,
								working_hours: checkIfOnlyNumbers.test(entry.hoursAndMinutes)
									? `0${Number(entry.hoursAndMinutes)}:00`
									: entry.hoursAndMinutes,
								user_id: userId,
								date: entry.date,
								is_billable: entry.isBillable,
								timesheet_submission_id: entry?.submissionId,
								notes: entry?.entryNotes
									? entry.entryNotes
									: timesheetEntry?.notes,
							};
					  })
					: [];
			return [...projectTasks, ...masterProjectTasks, ...orgTasks];
		})
		.flat(1)
		.filter((value) => Object.keys(value).length !== 0);
};

export const getDeletedTimesheetEntriesId = (
	weeklyTimesheetEntries: WeeklyTimesheetEntries
) =>
	weeklyTimesheetEntries.timesheetEntries
		.map((timesheetEntry) => {
			return timesheetEntry.isDeleted
				? timesheetEntry.workingHours.map((entry) => {
						if (
							entry.hoursAndMinutes === '00:00' ||
							entry.hoursAndMinutes.trim() === '' ||
							!entry?.id
						) {
							return {};
						}
						return entry?.id;
				  })
				: timesheetEntry.workingHours.map((entry) => {
						if (
							(entry.hoursAndMinutes === '00:00' ||
								entry.hoursAndMinutes.trim() === '') &&
							entry?.id
						) {
							return entry?.id;
						}
						return {};
				  });
		})
		.flat(1)
		.filter((value) => Object.keys(value).length !== 0);

export const addEntriesforWeek = (
	weeklyTimesheetEntries: WeeklyTimesheetEntries,
	selectedWeek: string[],
	task: AddTaskPayload,
	projects: {
		id: string;
		name: string;
		isBillable?: boolean | null;
		companyName?: string | undefined;
		type: string;
	}[]
) => {
	const selectedProject = projects.find(
		(project: any) => project.id === task.projectId
	);
	return {
		...weeklyTimesheetEntries,
		timesheetEntries: [
			...weeklyTimesheetEntries.timesheetEntries,
			{
				companyName: task.companyName,
				isDeleted: false,
				projectId: task.projectId,
				projectName: task.projectName,
				taskType: task.taskType,
				taskId: task.taskId,
				taskName: task.taskName,
				notes: task?.notes,
				workingHours: selectedWeek.map((date, index) => {
					return {
						date: date,
						hoursAndMinutes: '',
						isBillable: selectedProject?.isBillable ? task.isBillable : false,
						submissionId: task?.timesheetSubmissionId,
					};
				}),
			},
		],
	};
};
