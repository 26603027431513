import React, { useEffect } from 'react';

import {
	ReferenceInput,
	TextInput,
	AutocompleteInput,
	useNotify,
	useRefresh,
	required,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPDATE_ORGANISATION } from '../Settings.gql';

import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

interface CompanyDetails {
	companyDetails: {
		organisationId?: string;
		name?: string;
		country?: string;
		postal_code?: string;
		address_line1?: string;
		address_line2?: string;
	};
	onSuccess: () => void;
}

interface OrgCompany {
	name?: string;
	country?: string;
	postal_code?: string;
	address_line1?: string;
	address_line2?: string;
}

const CompanyDetailsSettings = ({
	companyDetails,
	onSuccess,
}: CompanyDetails) => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [
		updateCompanyDetails,
		{ data: updatedCompanyDetails, loading: isUpdateCompanyDetailsLoading },
	] = useApolloMutation(UPDATE_ORGANISATION);

	useEffect(() => {
		if (updatedCompanyDetails) {
			notify('Company Details Updated');
			refresh();
		}
	}, [updatedCompanyDetails, notify, refresh]);

	useEffect(() => {
		if (updatedCompanyDetails) {
			onSuccess();
		}
	}, [updatedCompanyDetails, onSuccess]);

	const onHandleSubmit = (companyValues: OrgCompany) => {
		updateCompanyDetails({
			variables: {
				organisationId: companyDetails?.organisationId,
				data: {
					name: companyValues?.name,
					address: {
						country: companyValues?.country,
						postal_code: companyValues?.postal_code,
						address_line1: companyValues?.address_line1,
						address_line2: companyValues?.address_line2,
					},
				},
			},
		})
			.then((respone: any) => {})
			.catch((error: any) => {
				if (!error) {
					return;
				}

				if (
					error?.message.includes(
						'value too long for type character varying(150)'
					)
				) {
					notify('Company Name character limit exceeded', 'warning');
				}
			});
	};

	const validateOrganisationFormValues = async () => {
		const formErrors = {} as OrgCompany;
		return formErrors;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form
					initialValues={companyDetails || {}}
					onSubmit={onHandleSubmit}
					validate={validateOrganisationFormValues}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Company Name
									</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput source='name' label='' validate={required()} />
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Address line1
									</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput source='address_line1' label='' />
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Address line2
									</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput source='address_line2' label='' />
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Country</Typography>
								</Box>
								<Box className={styles.input}>
									<ReferenceInput
										reference='geo_list'
										source='country'
										sort={{ field: 'name', order: 'ASC' }}
										label=''
										filterToQuery={(searchText) => ({
											name: searchText,
										})}
									>
										<AutocompleteInput optionText='name' />
									</ReferenceInput>
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Zipcode</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput source='postal_code' label='' />
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isDisabled={
										invalid || pristine || isUpdateCompanyDetailsLoading
									}
									isLoading={isUpdateCompanyDetailsLoading}
									title='Save'
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default CompanyDetailsSettings;
