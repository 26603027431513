import React from 'react';
import { NoteForm } from './model';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { TextInput, required } from 'react-admin';
import { modalFormStyle } from '../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import { validateRequiredField } from '../../modules/Settings/constant';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles({
	container: {
		width: '400px',
		height: '350px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
});

interface Props {
	open: boolean;
	onClose: () => void;
	saveForm: (note: NoteForm) => void;
	note?: NoteForm;
	refetch: () => void;
	loading?: boolean;
}

const NotesForm = (props: Props) => {
	const { open, onClose, saveForm, refetch, note, loading } = props;
	const classes = modalFormStyle();
	const customStyle = useStyles();

	const saveDetails = (notes: NoteForm) => {
		saveForm(notes);
		onClose();
		refetch();
	};

	return (
		<Box>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={customStyle.container}>
						<Box className={classes.headerContainer}>
							<Typography className={classes.heading}>
								{note?.id ? 'Edit Note' : 'Add Note'}
							</Typography>
							<CloseIcon className={classes.closeIcon} onClick={onClose} />
						</Box>
						<Form initialValues={note} onSubmit={saveDetails}>
							{({ handleSubmit, invalid }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Title *</Typography>
										<TextInput
											source='title'
											fullWidth={true}
											label={''}
											validate={[required(), validateRequiredField]}
										/>
										<Typography className={classes.label}>
											Description *
										</Typography>
										<TextInput
											source='note'
											fullWidth={true}
											label={''}
											validate={[required(), validateRequiredField]}
											multiline
										/>
									</Box>
									<Box className={classes.buttonContainer}>
										<Button onClick={onClose} className={classes.cancelButton}>
											Cancel
										</Button>
										<Button
											className={
												invalid || loading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || loading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
		</Box>
	);
};

export default NotesForm;
