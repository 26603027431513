import React, { useContext, useEffect, useState } from 'react';
import DetailContainer from '../../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { detailsPageTabDetailsStyle } from '../../../Layout/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import editIcon from '../../../assets/icons/edit-icon.svg';
import EditHardwareDetails from './EditHardwareDetails.component';
import { Asset } from '../AssetManagement.model';
import { UserProfileContext } from '../../../App';

interface Props {
	data: Asset;
	refetch: () => void;
}
const AssetHardwareDetails = (props: Props) => {
	const { data: asset, refetch } = props;
	const { permissions } = useContext<any>(UserProfileContext);
	const classes = detailsPageTabDetailsStyle();
	const [openEditModal, setOpenEditModal] = useState(false);
	const [assetDetails, setAssetDetails] = useState({});
	useEffect(() => {
		if (!asset) {
			return;
		}
		setAssetDetails({
			machine_serial_no: asset?.machine_serial_no,
			model: asset?.model,
			processor: asset?.processor,
			memory: asset?.memory,
			hard_drive: asset?.hard_drive,
			ip_address: asset?.ip_address,
			mac_address: asset?.mac_address,
		});
	}, [asset]);
	return (
		<>
			{permissions?.asset?.update_permissions && (
				<Box
					className={classes.editContainer}
					onClick={() => setOpenEditModal(true)}
				>
					<img className={classes.editButton} src={editIcon} alt='edit icon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			)}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Processor</Typography>

					<Tooltip title={`${asset?.processor || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.processor || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Memory</Typography>

					<Tooltip title={`${asset?.memory || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.memory || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Hard Drive</Typography>

					<Tooltip title={`${asset?.hard_drive || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.hard_drive || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Serial Number</Typography>

					<Tooltip
						title={`${asset?.machine_serial_no || '- -'}`}
						placement='right'
					>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.machine_serial_no || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Model Number</Typography>

					<Tooltip title={`${asset?.model || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.model || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>IP Address</Typography>
					<Tooltip title={`${asset?.ip_address || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.ip_address || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>MAC Address</Typography>

					<Tooltip title={`${asset?.mac_address || '- -'}`} placement='right'>
						<Typography className={`${classes.value} ${classes.ellipsis}`}>
							{asset?.mac_address || '- -'}
						</Typography>
					</Tooltip>
				</FieldContainer>
			</DetailContainer>
			<EditHardwareDetails
				open={openEditModal}
				onClose={() => setOpenEditModal(false)}
				record={assetDetails}
				refetch={() => refetch()}
			/>
		</>
	);
};

export default AssetHardwareDetails;
