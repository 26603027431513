import React from 'react';
import { useMutation, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
}));

interface Props {
	onClose: () => void;
	handleClose: () => void;
	open: boolean;
	skillMappingId: string;
	setIsEmployeeSkillSubmitted: (data: boolean) => void;
}

const DeleteEmployeeSkill = (props: Props) => {
	const {
		open,
		onClose,
		skillMappingId,
		handleClose,
		setIsEmployeeSkillSubmitted,
	} = props;
	const [mutate] = useMutation();
	const notify = useNotify();
	const classes = modalFormStyle();
	const containerClass = useStyles();

	const deleteEmployeeSkill = () => {
		mutate(
			{
				type: 'delete',
				resource: 'employee_skill_mapping',
				payload: {
					id: skillMappingId,
				},
			},
			{
				onSuccess: ({ data }) => {
					setIsEmployeeSkillSubmitted(true);
					onClose();
					handleClose();
					notify('Skill deleted Successfully');
				},
				onFailure: (error) => {
					onClose();
					handleClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						Do you really want to delete this record?
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button onClick={onClose} className={classes.cancelButton}>
								Cancel
							</Button>
							<Button
								className={classes.deleteButton}
								onClick={deleteEmployeeSkill}
								type='submit'
							>
								Delete
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default DeleteEmployeeSkill;
