import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRefresh, setSidebarVisibility } from 'react-admin';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import dayjs from 'dayjs';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import {
	DELETE_RESOURCE_ALLOCATION,
	EXPORT_PROJECT_RESOURCE_MAPPINGS,
	GET_PLANNED_COST_AND_HOURS,
	GET_PROJECT_RESOURCE_MAPPINGS,
	GET_RESOURCE_TAGS,
} from '../gqlQueries';

import {
	Box,
	makeStyles,
	Dialog,
	Button,
	TextField as TextComponent,
	Typography,
	Menu,
	MenuItem,
	ThemeProvider,
	createTheme,
	CircularProgress,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import AddAllocationForm from './AddAllocationForm.component';
import { dropDownEllipsisStyle, modalFormStyle } from '../../../Layout/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { UserProfileContext } from '../../../App';
import MUIDataTable from 'mui-datatables';
import {
	customAutoCompleteSearchBarTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import {
	exportToCsv,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import {
	ProjectPlannedCostAndHoursInterface,
	ProjectResourceMappingType,
	ProjectResourceMappingItem,
	RESOURCE_EXPORT_COLUMNS,
} from './constant';
import { isEmpty, orderBy } from 'lodash';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { autocompleteMultiSelectLabelStyle } from '../../Projects/style';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const dataGridStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		maxHeight: '430px',
		overflowY: 'auto',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		color: 'grey',
		backgroundColor: 'lightgrey',
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
	loadingContainer: {
		marginBottom: '100px !important',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
	},
}));
const TaskTableTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			root: {
				display: 'none',
			},
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-bold',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				width: '113px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});
const useStyles = makeStyles({
	ellipsisWidth: {
		width: '65px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	allocationPercentageEllipsisWidth: {
		width: '70px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	deleteConfirmationModalContainer: {
		width: '442px',
		minHeight: 'fit-content',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
		marginTop: '7px',
		cursor: 'pointer',
	},
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	tableList: {
		height: '500px',
		overflowY: 'auto',
	},
	filterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	limitLabel: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	tagFilterContainer: {
		marginBottom: '5px',
		width: '180px',
	},
});

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Resources'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};
interface Tag {
	label: string;
	value: string;
}
interface Props {
	projectId: string | undefined;
	start_date: string | undefined;
	end_date: string | undefined;
	budget: number | undefined;
	currencySymbol: string | undefined;
	refetchProject: () => void;
	effort_estimate: number | undefined;
}

export const AddAllocation = ({
	projectId,
	start_date: projectStartDate,
	end_date: projectEndDate,
	budget,
	currencySymbol,
	refetchProject,
	effort_estimate,
}: Props) => {
	const dataGridStyles = dataGridStyle();
	const ellipsis = dropDownEllipsisStyle();
	const filter = createFilterOptions();
	const autocompleteStyle = autocompleteMultiSelectLabelStyle();
	const dispatch = useDispatch();
	const { permissions, dateFormat, orgId } =
		useContext<any>(UserProfileContext);
	const addAllocationStyles = useStyles();
	const [isAddAllocationFormShown, setIsAddAllocationFormShown] =
		useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
	const [allocationViewIdToDelete, setAllocationViewIdToDelete] =
		useState(null);
	const notify = useNotify();
	const refresh = useRefresh();
	const [projectPlannedCostAndHours, setProjectPlannedCostAndHours] =
		useState<ProjectPlannedCostAndHoursInterface | null>(null);
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(0);
	const [deleteResourceMapping] = useApolloMutation(DELETE_RESOURCE_ALLOCATION);
	const [selectedResourceAllocation, setSelectedResourceAllocation] = useState(
		{}
	);
	const [resourceAllocationTableList, setResourceAllocationTableList] =
		useState([]);
	const [tagIdFilter, setTagFilter] = useState<
		{ label: string; value: string }[]
	>([]);

	const [searchedText, setSearchedText] = useState<string>('');
	const [sortFilter, setSortFilter] = useState<any[]>([{ created_at: 'desc' }]);
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState({});
	const [rowActionMenuAnchorEl, setRowActionMenuAnchorEl] = useState<any>(null);
	const formStyles = modalFormStyle();
	const { data: plannedCostAndHours, refetch: refetchPlannedAndHours } =
		useApolloQuery(GET_PLANNED_COST_AND_HOURS, {
			variables: {
				projectId: projectId,
			},
			fetchPolicy: 'network-only',
		});
	const { data: resourceTagList } = useApolloQuery(GET_RESOURCE_TAGS, {
		variables: { orgId: orgId },
		skip: !orgId,
		fetchPolicy: 'network-only',
	});
	const allSelected =
		resourceTagList?.project_resource_tag?.length === tagIdFilter?.length || 0
			? true
			: false;

	const columns = [
		{
			name: 'name',
			label: 'Resource Name',
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'start_date',
			label: 'Start Date',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'end_date',
			label: 'End Date',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'total_cost',
			label: 'Cost',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip
							title={`${currencySymbol ? currencySymbol : ''} ${value}`}
							arrow
						>
							<Box
								className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
							>{`${currencySymbol ? currencySymbol : ''} ${value}`}</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'total_hours',
			label: 'Hours',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'allocation_percentage',
			label: 'Allocation %',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={`${value}%`} arrow>
							<Box
								className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
							>{`${value}%`}</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'is_billable',
			label: 'Billable',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value ? 'Yes' : 'No'} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value ? 'Yes' : 'No'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'is_partial',
			label: 'Partial',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value ? 'Yes' : 'No'} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value ? 'Yes' : 'No'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'tag_name',
			label: 'Tag',
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} arrow>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value || '--'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'ACTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Box>
							<MoreVertIcon
								onClick={(event) => {
									setRowActionMenuAnchorEl(event.currentTarget);
									setSelectedRow(value);
									setAllocationViewIdToDelete(value?.id);
								}}
								className={addAllocationStyles.verticalIcon}
							/>
						</Box>
					);
				},
			},
		},
	];

	const {
		data: projectResourceMappings,
		refetch: refetchProjectResourceMappings,
		loading: isLoadingResource,
	} = useApolloQuery(GET_PROJECT_RESOURCE_MAPPINGS, {
		variables: {
			resourceMappingFilter: {
				project_id: { _eq: projectId },
				user: { full_name: { _ilike: `%${searchedText}%` } },
				_or: !isEmpty(tagIdFilter)
					? allSelected
						? {}
						: {
								tag_id: {
									_in: tagIdFilter.map((tag) => tag?.value),
								},
						  }
					: {},
			},
			holidayFilter: {
				date: {
					_gte: projectStartDate,
					_lte: projectEndDate,
				},
			},
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			sortFilter: sortFilter,
		},
		fetchPolicy: 'network-only',
	});

	const { data: exportProjectResourceMappings } = useApolloQuery(
		EXPORT_PROJECT_RESOURCE_MAPPINGS,
		{
			variables: {
				resourceMappingFilter: {
					project_id: { _eq: projectId },
					user: { full_name: { _ilike: `%${searchedText}%` } },
					_or: !isEmpty(tagIdFilter)
						? allSelected
							? {}
							: {
									tag_id: {
										_in: tagIdFilter.map((tag) => tag?.value),
									},
							  }
						: {},
				},
				holidayFilter: {
					date: {
						_gte: projectStartDate,
						_lte: projectEndDate,
					},
				},
				sortFilter: sortFilter,
			},
			fetchPolicy: 'network-only',
		}
	);

	const options = {
		tableBodyMaxHeight: '400px',
		filter: false,
		print: false,
		rowsPerPage: rowsPerPage,
		download: false,
		page: page,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		count:
			projectResourceMappings?.project_resource_mapping_aggregate?.aggregate
				?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
		downloadOptions: {
			fileame: 'Project_Resource_Request',
			filterOptions: {
				useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true,
			},
		},
		textLabels: {
			body: {
				noMatch: 'No data found',
				pagination: {
					displayRows: 'on',
				},
			},
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'name': {
					setSortFilter([{ user: { full_name: direction } }]);
					break;
				}
				case 'start_date': {
					setSortFilter([{ start_date: direction }]);
					break;
				}
				case 'end_date': {
					setSortFilter([{ end_date: direction }]);
					break;
				}
				case 'total_cost': {
					setSortFilter([{ total_cost: direction }]);
					break;
				}
				case 'total_hours': {
					setSortFilter([{ total_hours: direction }]);
					break;
				}
				case 'allocation_percentage': {
					setSortFilter([{ allocation_percentage: direction }]);
					break;
				}
				case 'is_billable': {
					setSortFilter([{ is_billable: direction }]);
					break;
				}
				case 'is_partial': {
					setSortFilter([{ is_partial: direction }]);
					break;
				}
				case 'tag_name': {
					setSortFilter([{ project_resource_tag: { name: direction } }]);
					break;
				}
				default: {
					setSortFilter([]);
				}
			}
		},
	};

	const onRemoveAllocation = (allocationId: any) => {
		if (!allocationId) {
			return;
		}
		deleteResourceMapping({
			variables: {
				resourceAllocationId: allocationId,
			},
		}).then((deleteResourceMappingResponse) => {
			if (!deleteResourceMappingResponse.errors) {
				notify('Allocation Removed SuccessFully');
				refresh();
				refetchProject();
				refetchProjectResourceMappings();
				setAllocationViewIdToDelete(null);
				setSelectedResourceAllocation({});
				setRowActionMenuAnchorEl(null);
				setIsConfirmationModalOpen(false);
			}
		});
	};

	const onModalClose = () => {
		setIsConfirmationModalOpen(false);
	};

	const onRemoveConfirmation = () => {
		onRemoveAllocation(allocationViewIdToDelete);
	};
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	useEffect(() => {
		if (!plannedCostAndHours) {
			return;
		}
		setProjectPlannedCostAndHours({
			total_hours:
				plannedCostAndHours?.project_resource_mapping_aggregate?.aggregate?.sum
					?.total_hours,
			total_cost:
				plannedCostAndHours?.project_resource_mapping_aggregate?.aggregate?.sum
					?.total_cost,
		});
	}, [plannedCostAndHours]);

	useEffect(() => {
		if (!projectResourceMappings) {
			setResourceAllocationTableList([]);
			return;
		}
		const resourceAllocationList =
			projectResourceMappings?.project_resource_mapping?.map(
				(item: ProjectResourceMappingItem) => {
					return {
						id: item.id,
						created_at: item.created_at,
						employee_id: item?.employee_id,
						start_date: dayjs(item?.start_date).format(dateFormat),
						end_date: dayjs(item?.end_date).format(dateFormat),
						name: item?.user?.full_name,
						total_hours: item?.total_hours
							? Number(item?.total_hours).toFixed(2)
							: '--',
						total_cost: item?.total_cost,
						is_partial: item?.is_partial,
						is_billable: item?.is_billable,
						project_id: item?.project_id,
						resource_cost: item?.resource_cost,
						allocation_percentage: item?.allocation_percentage,
						client_rate: item?.client_rate,
						tag_name: item?.project_resource_tag?.name || null,
						action: {
							id: item.id,
							created_at: item.created_at,
							employee_id: item?.employee_id,
							start_date: item?.start_date,
							end_date: item?.end_date,
							name: item?.user?.full_name,
							total_hours: item?.total_hours,
							total_cost: item?.total_cost,
							is_partial: item?.is_partial,
							is_billable: item?.is_billable,
							project_id: item?.project_id,
							resource_cost: item?.resource_cost,
							allocation_percentage: item?.allocation_percentage,
							client_rate: item?.client_rate,
							tag_id: item?.tag_id || null,
						},
					};
				}
			);
		setResourceAllocationTableList(resourceAllocationList);
	}, [projectResourceMappings, dateFormat]);

	const handleExport = () => {
		if (resourceAllocationTableList?.length === 0) {
			return;
		}
		const resourceData =
			exportProjectResourceMappings?.project_resource_mapping?.map(
				(data: ProjectResourceMappingType) => {
					return [
						data?.user?.full_name,
						data?.start_date ? dayjs(data?.start_date).format(dateFormat) : '',
						data?.end_date ? dayjs(data?.end_date).format(dateFormat) : '',
						` ${currencySymbol ? currencySymbol : ''} ${data?.total_cost}`,
						data?.total_hours ? Number(data?.total_hours).toFixed(2) : '--',
						data?.allocation_percentage,
						data?.is_billable ? 'Yes' : 'No',
						data?.is_partial ? 'Yes' : 'No',
						data?.project_resource_tag?.name || '--',
					];
				}
			) || [];
		const exportData = [RESOURCE_EXPORT_COLUMNS, ...[...(resourceData || [])]];

		exportToCsv('Resource.csv', exportData);
	};

	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);
	const exportWithDelay = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};
	const handleSelectAll = (isSelected: boolean) => {
		if (isSelected) {
			const tagInfo = resourceTagList?.project_resource_tag?.map(
				(tag: { id: string; name: string }) => {
					return {
						value: tag?.id,
						label: tag?.name,
					};
				}
			);
			setTagFilter(tagInfo);
		} else {
			setTagFilter([]);
		}
	};
	const handleToggleSelectAll = () => {
		handleSelectAll(!allSelected);
	};

	const onTagFilterChange = (event: any, value: any, reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option: any) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				setTagFilter(value);
				return;
			}
		} else if (reason === 'clear') {
			setTagFilter([]);
			return;
		}
	};
	const getTagLimits = () => {
		return (
			<div className={`${addAllocationStyles.limitLabel} ${ellipsis.ellipsis}`}>
				{tagIdFilter?.length === 1
					? tagIdFilter[0].label
					: `Selected ${tagIdFilter?.length || 0} items`}
			</div>
		);
	};
	const getTagAutoCompleteOptions = () => {
		const optionList =
			resourceTagList && resourceTagList?.project_resource_tag
				? resourceTagList?.project_resource_tag?.map(
						(tag: { id: string; name: string }) => {
							return {
								value: tag?.id,
								label: tag?.name,
							};
						}
				  ) || []
				: [];
		const sortedOptionList = orderBy(
			optionList,
			[(option) => option?.label],
			['asc']
		);
		return sortedOptionList;
	};
	return (
		<Box>
			<Box display='flex' justifyContent='space-between' marginBottom='20px'>
				<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
					<Box>
						<CustomSearchRender
							searchText={searchedText}
							setSearchedText={setSearchedText}
						/>
					</Box>

					<Box className={addAllocationStyles.tagFilterContainer}>
						<Box className={addAllocationStyles.filterLabel}>Tag</Box>
						<Autocomplete
							multiple
							value={tagIdFilter || []}
							limitTags={0}
							selectOnFocus={true}
							disablePortal
							fullWidth={false}
							onChange={onTagFilterChange}
							classes={{
								tag: autocompleteStyle.label,
							}}
							options={getTagAutoCompleteOptions()}
							disableCloseOnSelect
							getLimitTagsText={getTagLimits}
							ChipProps={{ style: { display: 'none' } }}
							filterOptions={(options: any, params: any) => {
								const filtered = filter(options, params);
								return [{ label: 'All', value: 'All' }, ...filtered];
							}}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextComponent
										{...params}
										label=''
										placeholder={
											tagIdFilter && tagIdFilter?.length > 0 ? '' : 'Search Tag'
										}
									/>
								</ThemeProvider>
							)}
							renderOption={(option: any, { selected }) => {
								const selectAllProps =
									option.value === 'All' // To control the state of 'select-all' checkbox
										? { checked: allSelected }
										: {};
								return (
									<>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>
										<Tooltip
											title={`${option?.label || '- -'}`}
											placement='right'
										>
											<Typography className={`${ellipsis.ellipsis}`}>
												{`${option?.label || '- -'}`}
											</Typography>
										</Tooltip>
									</>
								);
							}}
							getOptionLabel={(option) => `${option?.label}`}
							getOptionSelected={(option, value) =>
								option.value === value.value
							}
						/>
					</Box>
					{isLoadingResource && (
						<Box>
							<CircularProgress size={25} />
						</Box>
					)}
				</Box>
				<Box display='flex' sx={{ gridGap: '8px' }}>
					<Box>
						<Button
							endIcon={<ArrowUpwardIcon />}
							className={
								isEmpty(resourceAllocationTableList) || isButtonDisabled
									? dataGridStyles.disabledButton
									: dataGridStyles.exportButton
							}
							onClick={exportWithDelay}
							disabled={
								isEmpty(resourceAllocationTableList) || isButtonDisabled
							}
							startIcon={
								isButtonDisabled && (
									<CircularProgress
										style={{
											height: '20px',
											width: '20px',
											color: '#FFFFFF',
										}}
									/>
								)
							}
						>
							Export
						</Button>
					</Box>
					<Box>
						{permissions?.project_resource_mapping?.insert_permissions && (
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									setIsAddAllocationFormShown(true);
								}}
								className={formStyles.saveButton}
							>
								Add Resource
							</Button>
						)}
					</Box>
				</Box>
			</Box>
			<Box>
				<Box width='100%' display='flex' justifyContent='flex-end'></Box>
				{isConfirmationModalOpen ? (
					<div>
						<Dialog
							open={isConfirmationModalOpen}
							onClose={onModalClose}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
						>
							<Box
								className={addAllocationStyles.deleteConfirmationModalContainer}
							>
								<Box className={formStyles.headerContainer}>
									<Typography className={formStyles.heading}>
										Delete Confirmation
									</Typography>
									<CloseIcon
										className={formStyles.closeIcon}
										onClick={onModalClose}
									/>
								</Box>
								<Box m={2}>
									<Typography>
										Are you sure you want to remove this allocation?
									</Typography>
								</Box>
								<Box className={formStyles.buttonContainer}>
									<Button
										className={formStyles.cancelButton}
										onClick={onModalClose}
									>
										CANCEL
									</Button>
									<Button
										onClick={onRemoveConfirmation}
										autoFocus
										className={formStyles.deleteButton}
										variant='contained'
									>
										REMOVE
									</Button>
								</Box>
							</Box>
						</Dialog>
					</div>
				) : (
					''
				)}
			</Box>

			<ThemeProvider theme={TaskTableTheme}>
				{projectResourceMappings?.project_resource_mapping && (
					<MUIDataTable
						title={'Resource Allocations'}
						data={resourceAllocationTableList}
						columns={columns}
						options={options}
					/>
				)}
			</ThemeProvider>
			<Menu
				anchorEl={rowActionMenuAnchorEl}
				open={Boolean(rowActionMenuAnchorEl)}
				onClose={() => {
					setRowActionMenuAnchorEl(null);
					setSelectedRow({});
					setAllocationViewIdToDelete(null);
				}}
			>
				<MenuItem
					onClick={() => {
						setSelectedResourceAllocation(selectedRow);
						setIsAddAllocationFormShown(true);
					}}
				>
					<EditIcon className={addAllocationStyles.editIcon} />
					<Box className={addAllocationStyles.editText}>Edit</Box>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setIsConfirmationModalOpen(true);
					}}
				>
					<DeleteIcon className={addAllocationStyles.deleteIcon} />
					<Box className={addAllocationStyles.editText}>Delete</Box>
				</MenuItem>
			</Menu>
			<AddAllocationForm
				open={isAddAllocationFormShown}
				onClose={() => {
					setRowActionMenuAnchorEl(null);
					setIsAddAllocationFormShown(false);
					setSelectedRow({});
					setSelectedResourceAllocation({});
				}}
				projectStartDate={projectStartDate || null}
				projectEndDate={projectEndDate || null}
				projectId={projectId}
				initialValues={selectedResourceAllocation}
				budget={budget}
				currencySymbol={currencySymbol}
				plannedCostAndHours={projectPlannedCostAndHours}
				projectResourceMappings={
					projectResourceMappings?.project_resource_mapping || []
				}
				effort_estimate={effort_estimate}
				onSuccess={() => {
					refetchPlannedAndHours();
					refetchProjectResourceMappings();
					refetchProject();
					refresh();
				}}
			/>
		</Box>
	);
};

export default AddAllocation;
