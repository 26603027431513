import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import OptionsMenu from './OptionsMenu';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const UseStyle = makeStyles((theme) => ({
	paperBox: {
		border: '1px solid #E0E0E0',
		height: 172,
		width: 172,
		position: 'relative',
	},
	avatar: {
		top: '58px',
		backgroundColor: '#ECF1F4 !important',
		margin: 'auto',
	},
	avtharIcon: {
		color: '#000000',
		displa: 'flex',
		margin: 'auto',
	},
	paperText: {
		position: 'absolute',
		bottom: '25px',
		textAlign: 'center',
		color: '#292929',
		width: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '0px 44px',
	},
	addDocBox: {
		cursor: 'pointer',
		height: 172,
		width: 172,
		display: 'flex',
		margin: 'auto',
		border: '2px dashed #E0E0E0',
		'&:hover': {
			border: '2px dashed #4285F4',
			backgroundColor: 'rgba(66, 133, 244, 0.1)',
		},
	},
	uploadButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		width: '100%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	uploadTile: {
		color: '#4285F4',
		fontSize: 'inherit',
		marginTop: '10px',
	},
	docsContainer: {
		overflowY: 'auto',
		alignContent: 'flex-start',
	},
}));

interface document {
	docUrl: string;
	id: string;
	file_name: string;
}

interface tiledocs {
	index: number;
	doc: string;
}

interface Doc {
	id: string;
	docUrl: string;
	file_name: string;
}

interface documentProps {
	index: number;
	doc: Doc;
}

interface docsType {
	id: string;
	docUrl: string;
	file_name: string;
}

interface propsTypes {
	docs: any;
	loading: boolean;
	errorMessage: string;
	FileUploadHandler: (array: any) => void;
	refetchDocs: () => void;
	onDocDelete: any;
	onFileRename: any;
	onRenameError: any;
	pageHeight: string;
	permission?: boolean;
}

const DocumentUpload = (props: propsTypes) => {
	const {
		docs,
		FileUploadHandler,
		loading,
		refetchDocs,
		errorMessage,
		onDocDelete,
		onFileRename,
		onRenameError,
		pageHeight,
		permission,
	} = props;
	const classes = UseStyle();
	const inputRef = useRef<HTMLInputElement>(null);
	const [popper, setPopper] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const uploadFileHandler = (event: any) => {
		FileUploadHandler([event.target.files[0], event.target.files[0]?.name]);
	};

	useEffect(() => {
		if (errorMessage) {
			setPopper(true);
			setAlertMessage(errorMessage);
		}
	}, [errorMessage]);

	const DocTile = (props: documentProps) => {
		return (
			<Grid item key={`tile${props?.index}`}>
				<Paper className={classes.paperBox}>
					{permission ? (
						<OptionsMenu
							docDetails={props?.doc}
							refetchDocs={refetchDocs}
							setPopper={(message: string) => {
								setPopper(true);
								setAlertMessage(message);
							}}
							onDocDelete={onDocDelete}
							onFileRename={onFileRename}
							onRenameError={onRenameError}
						/>
					) : null}
					<Box>
						<Avatar
							className={classes.avatar}
							style={{ width: 39, height: 39 }}
						>
							<InsertDriveFileIcon
								className={classes.avtharIcon}
								fontSize='small'
							/>
						</Avatar>
					</Box>
					<Tooltip title={props?.doc?.file_name}>
						<Typography className={classes.paperText} variant='body2'>
							{props?.doc?.file_name}
						</Typography>
					</Tooltip>
				</Paper>
			</Grid>
		);
	};

	const ProgressLoader = () => (
		<Backdrop className={classes.backdrop} open={loading}>
			<CircularProgress color='inherit' />
		</Backdrop>
	);

	return (
		<Grid item md={12}>
			<ProgressLoader />
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={popper}
				autoHideDuration={4000}
				onClose={() => {
					setPopper(false);
				}}
				message={alertMessage}
				action={
					<React.Fragment>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={() => {
								setPopper(false);
							}}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</React.Fragment>
				}
			/>
			<Grid
				container
				spacing={3}
				className={classes.docsContainer}
				style={{ height: pageHeight }}
			>
				<Grid item>
					{permission ? (
						<Paper
							className={classes.addDocBox}
							onClick={() => {
								inputRef.current?.click();
								if (!inputRef?.current?.value) {
									return;
								}
								inputRef.current.value = '';
							}}
						>
							<Box className={classes.uploadButton}>
								<AddCircleOutlineIcon
									fontSize='large'
									style={{
										color: '#4285F4',
										height: 39,
										width: 39,
									}}
								/>
								<input
									type='file'
									style={{ display: 'none' }}
									ref={inputRef}
									onChange={uploadFileHandler}
									accept='.pdf,.doc,.docx,.xls,.csv,.xlsx,.odt,.ods,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.svg,.eml'
								/>
								<Typography variant='body1' className={classes.uploadTile}>
									Upload <br />
									Document
								</Typography>
							</Box>
						</Paper>
					) : null}
				</Grid>
				{docs?.length > 0 &&
					docs?.map((doc: document, index: number) => (
						<DocTile index={index} key={index} doc={doc} />
					))}
			</Grid>
		</Grid>
	);
};

export default DocumentUpload;
