import React from 'react';

import { Box, Typography, Button, Drawer } from '@material-ui/core';
import { Form } from 'react-final-form';
import { drawerFilterStyle } from '../../Layout/styles';

interface FilterValues {
	skill_id?: string[];
	project_id?: string[];
}

interface ResourceUtilizationFilterContainerProps {
	open: boolean;
	onClose: () => void;
	filterValues: (filterValue: FilterValues) => void;
	refetch?: () => void;
	filterFormValues: FilterValues;
	children: React.ReactNode;
	filterQuery: (query: any) => void;
}

export const ResourceUtilizationFilterContainer = (
	props: ResourceUtilizationFilterContainerProps
) => {
	const {
		open,
		onClose,
		filterValues,
		refetch,
		filterFormValues,
		children,
		filterQuery,
	} = props;
	const drawerFilterStyles = drawerFilterStyle();

	const getFilterQuery = (filterValues: FilterValues) => {
		const skillFilter =
			filterValues?.skill_id && filterValues?.skill_id?.length > 0
				? {
						employee_skill_mappings: {
							skill_id: {
								_in: filterValues.skill_id,
							},
						},
				  }
				: {};

		const projectFilter =
			filterValues?.project_id && filterValues?.project_id?.length > 0
				? {
						project_resource_mappings: {
							project_id: {
								_in: filterValues.project_id,
							},
						},
				  }
				: {};

		filterQuery({
			...skillFilter,
			...projectFilter,
		});
		return true;
	};

	const getFilterValues = (formValues: FilterValues) => {
		const skillFilterValue =
			formValues?.skill_id && formValues?.skill_id?.length > 0
				? { skill_id: formValues?.skill_id }
				: {};
		const projectFilterValue =
			formValues?.project_id && formValues?.project_id?.length > 0
				? { project_id: formValues?.project_id }
				: {};

		return {
			...skillFilterValue,
			...projectFilterValue,
		};
	};

	const setFilterValue = async (formValues: FilterValues) => {
		const query = await getFilterQuery(formValues);
		const filterFormValues = await getFilterValues(formValues);
		if (query && filterFormValues) {
			filterValues({ ...filterFormValues });
			return true;
		}
	};

	const onSubmit = async (formValues: FilterValues) => {
		if (!formValues) {
			return;
		}
		if (!refetch) {
			const isFormValueSet = await setFilterValue(formValues);
			if (isFormValueSet) {
				onClose();
			}
			return;
		}
		const isFormValueSet = await setFilterValue(formValues);
		if (isFormValueSet) {
			refetch();
			onClose();
		}
	};

	return (
		<Drawer open={open} anchor='right' onClose={onClose}>
			<Box className={drawerFilterStyles.drawerContainer}>
				<Box display='flex' flexDirection='column'>
					<Box className={drawerFilterStyles.HeaderSection}>
						<Typography className={drawerFilterStyles.drawerHeading}>
							Filters
						</Typography>
						<Typography
							className={drawerFilterStyles.resetButton}
							onClick={async () => {
								if (!refetch) {
									const isFormValueSet = await setFilterValue({});
									if (isFormValueSet) {
										onClose();
									}
									return;
								}
								const isFormValueSet = await setFilterValue({});
								if (isFormValueSet) {
									refetch();
									onClose();
								}
							}}
						>
							Clear all
						</Typography>
					</Box>
				</Box>

				<Form onSubmit={onSubmit} initialValues={filterFormValues}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							{children}
							<Box className={drawerFilterStyles.buttonContainer}>
								<Box mb={'10px'}>
									<Button
										className={drawerFilterStyles.applyFilterButton}
										variant='outlined'
										color='primary'
										type='submit'
									>
										Apply Filters
									</Button>
								</Box>
								<Box mb={'10px'}>
									<Button
										onClick={() => onClose()}
										className={drawerFilterStyles.cancelButton}
										variant='outlined'
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</Drawer>
	);
};

export default ResourceUtilizationFilterContainer;
