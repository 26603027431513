import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	ThemeProvider,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import {
	AutocompleteArrayInput,
	required,
	useNotify,
	AutocompleteArrayInputProps,
} from 'react-admin';
import { ATTENDANCE_TIMESHEET_STATUS_MESSAGE } from '../constant';
import { settingsStyle } from '../settingsStyle';
import { UserProfileContext } from '../../../App';
import {
	useGetTimesheetCalculationStatusQuery,
	useUpdateTimesheetCalculationStatusMutation,
} from '../../../generated/graphql';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-6px',
		marginLeft: '6px',
		height: '5px',
	},
});
const AttendanceTimesheetSettings = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const notify = useNotify();
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyle = useStyles();
	const { data: organization, refetch } = useGetTimesheetCalculationStatusQuery(
		{
			variables: {
				orgId: orgId,
			},
		}
	);
	const [updateTimesheetStatus, { loading: isUpdateSettingsLoading }] =
		useUpdateTimesheetCalculationStatusMutation();

	const [timesheetStatusValue, setTimesheetStatusValue] = useState<
		string[] | null
	>();
	const onHandleSubmit = (formValues: {
		timesheet_calculation_status: string[];
	}) => {
		if (!formValues) {
			return;
		}
		updateTimesheetStatus({
			variables: {
				orgId: orgId,
				data: formValues || [],
			},
		})
			.then((res) => {
				if (!res.errors) {
					refetch();
					notify('Updated successfully');
				}
			})
			.catch((err) => {
				notify('Updation Failed', 'warning');
			});
	};

	useEffect(() => {
		if (!organization?.organization_by_pk) {
			setTimesheetStatusValue([]);
			return;
		}
		setTimesheetStatusValue(
			organization?.organization_by_pk?.timesheet_calculation_status || []
		);
	}, [organization]);

	const handleChange: AutocompleteArrayInputProps['onChange'] = (
		value: string[],
		records: any[]
	) => {
		if (isEmpty(value)) {
			setTimesheetStatusValue(null);
			return;
		}
		setTimesheetStatusValue(value);
	};

	return (
		<>
			<Typography className={styles.description}>
				{ATTENDANCE_TIMESHEET_STATUS_MESSAGE}
			</Typography>
			<ThemeProvider theme={modalFormTheme}>
				<Box className={styles.formContainer}>
					<Form
						initialValues={{
							timesheet_calculation_status:
								organization?.organization_by_pk
									?.timesheet_calculation_status || [],
						}}
						onSubmit={onHandleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={styles.inputContainer}>
									<Box className={styles.inputLabel}>
										<Typography className={classes.label}>
											Choose status
										</Typography>
									</Box>
									<Box className={styles.input}>
										<AutocompleteArrayInput
											source='timesheet_calculation_status'
											onChange={handleChange}
											label=''
											choices={
												organization?.timesheet_status
													? organization?.timesheet_status?.map((status) => ({
															id: status?.value,
															name: status?.label,
													  }))
													: []
											}
											validate={required('')}
										/>
										{!timesheetStatusValue && (
											<p className={customStyle.errorText}>
												Please choose atleast one status
											</p>
										)}
									</Box>
								</Box>

								<Box className={classes.buttonContainer} style={{ padding: 0 }}>
									<ButtonWithLoader
										title='Save'
										isDisabled={
											invalid ||
											pristine ||
											isUpdateSettingsLoading ||
											isEmpty(timesheetStatusValue)
										}
										isLoading={isUpdateSettingsLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</ThemeProvider>
		</>
	);
};

export default AttendanceTimesheetSettings;
