import dayjs from 'dayjs';

export enum MASTER_DATA_TYPE {
	TIMEZONE_OPTIONS = 'app_timezone_options',
}

export const PRIORITY_OPTIONS = [
	{ id: 'Low', name: 'Low' },
	{ id: 'Medium', name: 'Medium' },
	{ id: 'High', name: 'High' },
];

export const monthOptions = [
	{ id: 0, name: 'Jan' },
	{ id: 1, name: 'Feb' },
	{ id: 2, name: 'Mar' },
	{ id: 3, name: 'Apr' },
	{ id: 4, name: 'May' },
	{ id: 5, name: 'Jun' },
	{ id: 6, name: 'Jul' },
	{ id: 7, name: 'Aug' },
	{ id: 8, name: 'Sep' },
	{ id: 9, name: 'Oct' },
	{ id: 10, name: 'Nov' },
	{ id: 11, name: 'Dec' },
];

export const ERR_LEAVE_TYPE_NOT_UNIQUE =
	'org_leave_types_leave_type_leave_group_id_key';
export const ERR_LEAVE_GROUP_NOT_UNIQUE = 'org_leave_group_name_key';

export const dateOptions = [
	{ id: 'DD-MM-YYYY', name: 'DD-MM-YYYY' },
	{ id: 'MM-DD-YYYY', name: 'MM-DD-YYYY' },
];

export const currentYear = {
	startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
	endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
};

export const EMPLOYEE_GENDER_CHOICES = [
	{ id: 'Female', name: 'Female' },
	{ id: 'Male', name: 'Male' },
	{ id: 'Transgender', name: 'Transgender' },
];

export const bloodGroupChoices = [
	{ id: 'A+', name: 'A+' },
	{ id: 'A−', name: 'A−' },
	{ id: 'AB+', name: 'AB+' },
	{ id: 'AB−', name: 'AB−' },
	{ id: 'B+', name: 'B+' },
	{ id: 'B−', name: 'B−' },
	{ id: 'O+', name: 'O+' },
	{ id: 'O−', name: 'O−' },
];

export const workplazeDocumentURL = 'https://docs.workplaze.perfomatix.online/';

export const taskPriorityOptions = [
	{ id: 'High', name: 'High' },
	{ id: 'Medium', name: 'Medium' },
	{ id: 'Low', name: 'Low' },
];

export const PROJECT_TYPE_OPTIONS = [
	{ id: 'FP', name: 'FP' },
	{ id: 'FMB', name: 'FMB' },
	{ id: 'MS', name: 'MS' },
	{ id: 'TM', name: 'TM' },
	{ id: 'Other', name: 'Other' },
];

export const userStatusOptions = [
	{
		id: 'All',
		name: 'All',
	},
	{
		id: true,
		name: 'Active',
	},
	{
		id: false,
		name: 'Inactive',
	},
];

export const RESOURCE_PLANNER_CONTAINER = {
	project: 'project',
	ganttChart: 'ganttChart',
	resourceAllocations: 'resourceAllocations',
};

export const ExportOptions = [
	{ id: 'csv', label: 'CSV' },
	{ id: 'excel', label: 'Excel' },
];
export const ACCEPTED_FILE_FORMATS_FOR_CERTIFICATES = [
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.oasis.opendocument.text',
	'application/vnd.oasis.opendocument.spreadsheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'text/plain',
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	'message/rfc822',
];