import React, { useEffect, useState } from 'react';
import {
	useMutation,
	useNotify,
	required,
	TextInput,
	BooleanInput,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../Layout/Theme.component';
import { modalFormStyle } from '../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { AutocompleteSearch } from './Autocompletesearch.component';
import { isEmpty } from 'lodash';
import {
	SKILL_ALREADY_EXIST_MESSAGE,
	SKILL_LEVEL_SELECT_MESSAGE,
	SKILL_SELECT_MESSAGE,
} from '../modules/Employees/constant';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '500px',
		padding: '20px',
		borderRadius: '4px',
	},
	formContainer: {
		marginTop: '24px',
		width: '401px',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '16px',
	},
}));

interface SkillLevelList {
	id: string;
	level: string;
}

interface SkillMasterList {
	id: string;
	name: string;
}

interface User {
	first_name: string;
	last_name: string;
	full_name: string;
	id: string;
}
interface Record {
	first_name: string;
	last_name: string;
	full_name: string;
	id: string;
	user: User;
	skill_id: string;
	skill_level_id: string;
	employee_id: string;
	description: string;
	is_primary: boolean;
}

interface Props {
	onClose: () => void;
	handleClose: () => void;
	open: boolean;
	record: Record;
	setIsEmployeeSkillSubmitted: (data: boolean) => void;
	skillLevelList: SkillLevelList[];
	skillMasterList: SkillMasterList[];
}

const EmployeeSkill = (props: Props) => {
	const {
		open,
		onClose,
		record,
		setIsEmployeeSkillSubmitted,
		handleClose,
		skillLevelList,
		skillMasterList,
	} = props;
	const [skillId, setSkillId] = useState<string | null>(null);
	const [skillLevelId, setSkillLevelId] = useState<string | null>(null);
	const [skillDescriptionList, setSkillDescriptionList] = useState<
		string | null
	>(null);
	const [mutate, { loading: isEmployeeSkillLoading }] = useMutation();
	const notify = useNotify();
	const classes = modalFormStyle();
	const containerClass = useStyles();
	const [skillIdError, setSkillIdError] = useState(false);
	const [skillIdErrorMsg, setSkillIdErrorMsg] = useState('');
	const [skillLevelError, setSkillLevelError] = useState(false);
	const [skillLevelErrorMsg, setSkillLevelErrorMsg] = useState('');
	const [isPrimarySkill, setIsPrimarySkill] = useState(false);

	useEffect(() => {
		if (!record?.id) {
			return;
		}
		setSkillId(record?.skill_id);
		setSkillLevelId(record?.skill_level_id);
		setSkillDescriptionList(record?.description);
		setIsPrimarySkill(record?.is_primary);
	}, [record]);

	const getSkillMasterOption = () => {
		if (isEmpty(skillMasterList)) {
			return [];
		}
		const response = skillMasterList?.map((value: any) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		return response;
	};

	const getSkillMLevelOption = () => {
		if (isEmpty(skillLevelList)) {
			return [];
		}
		const response = skillLevelList?.map((value: any) => {
			return {
				id: value?.id,
				name: value?.level,
			};
		});
		return response;
	};

	const getSkillMasterValue = (id: any) => {
		const response = skillMasterList?.find((value: any) => value?.id === id);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getSkillLevelValue = (id: any) => {
		const response = skillLevelList?.find((value: any) => value?.id === id);
		return response ? { id: response?.id, name: response?.level } : null;
	};

	const updateEmployeeSkill = () => {
		mutate(
			{
				type: 'update',
				resource: 'employee_skill_mapping',
				payload: {
					id: record?.id,
					data: {
						skill_id: skillId ? skillId : record?.skill_id,
						skill_level_id: skillLevelId
							? skillLevelId
							: record?.skill_level_id,
						description: skillDescriptionList,
						is_primary: isPrimarySkill,
					},
				},
			},
			{
				onSuccess: () => {
					onClose();
					handleClose();
					setIsEmployeeSkillSubmitted(true);
					setSkillIdError(false);
					setSkillLevelError(false);
					notify('Skill Updated Successfully');
				},
				onFailure: (error) => {
					if (
						error.message.includes(
							'employee_skill_mapping_employee_id_skill_id_org_id_key'
						)
					) {
						setIsEmployeeSkillSubmitted(false);
						setSkillIdError(true);
						setSkillIdErrorMsg(SKILL_ALREADY_EXIST_MESSAGE);
						return;
					} else if (error.message.includes('skill_id')) {
						setIsEmployeeSkillSubmitted(false);
						setSkillIdError(true);
						setSkillIdErrorMsg(SKILL_SELECT_MESSAGE);
						return;
					} else if (error.message.includes('skill_level_id')) {
						setIsEmployeeSkillSubmitted(false);
						setSkillLevelError(true);
						setSkillLevelErrorMsg(SKILL_LEVEL_SELECT_MESSAGE);
						return;
					}
					onClose();
					handleClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const createEmployeeSkill = () => {
		mutate(
			{
				type: 'create',
				resource: 'employee_skill_mapping',
				payload: {
					data: {
						employee_id: record?.user?.id,
						skill_id: skillId,
						skill_level_id: skillLevelId,
						description: skillDescriptionList,
						is_primary: isPrimarySkill,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					modalClose();
					setIsEmployeeSkillSubmitted(true);
					setSkillIdError(false);
					setSkillLevelError(false);
					notify('Skill Created Successfully');
				},
				onFailure: (error) => {
					if (
						error.message.includes(
							'employee_skill_mapping_employee_id_skill_id_org_id_key'
						)
					) {
						setIsEmployeeSkillSubmitted(false);
						setSkillIdError(true);
						setSkillIdErrorMsg(SKILL_ALREADY_EXIST_MESSAGE);
						return;
					} else if (error.message.includes('skill_id')) {
						setIsEmployeeSkillSubmitted(false);
						setSkillIdError(true);
						setSkillIdErrorMsg(SKILL_SELECT_MESSAGE);
						return;
					} else if (error.message.includes('skill_level_id')) {
						setIsEmployeeSkillSubmitted(false);
						setSkillLevelError(true);
						setSkillLevelErrorMsg(SKILL_LEVEL_SELECT_MESSAGE);
						return;
					}
					onClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const onSave = () => {
		setSkillIdError(false);
		setSkillLevelError(false);
		if (!skillId) {
			setSkillIdError(true);
			setSkillIdErrorMsg(SKILL_SELECT_MESSAGE);
		} else if (!skillLevelId) {
			setSkillLevelError(true);
			setSkillLevelErrorMsg(SKILL_LEVEL_SELECT_MESSAGE);
		} else {
			if (record?.employee_id) {
				updateEmployeeSkill();
			} else {
				createEmployeeSkill();
			}
		}
	};

	const modalClose = () => {
		setSkillId(record?.skill_id);
		setSkillLevelId(record?.skill_level_id);
		setSkillDescriptionList(null);
		setSkillIdError(false);
		setSkillLevelError(false);
		setIsPrimarySkill(false);
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={modalClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{`${record?.user?.full_name}
             `}
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={modalClose} />
					</Box>
					<Box className={containerClass.formContainer}>
						<Form initialValues={record} onSubmit={onSave}>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Typography className={classes.label}>Skill*</Typography>
									<Field name='skill_id' validate={required()}>
										{(props) => (
											<AutocompleteSearch
												placeholder={'Search Skill'}
												option={getSkillMasterOption()}
												onChange={setSkillId}
												value={getSkillMasterValue(skillId)}
												name={'skill_id'}
											/>
										)}
									</Field>
									{skillIdError && (
										<p className={classes.errorText}>{skillIdErrorMsg}</p>
									)}
									<Typography className={classes.label}>
										Skill Level*
									</Typography>
									<Field name='skill_level_id' validate={required()}>
										{(props) => (
											<AutocompleteSearch
												placeholder={'Search Skill level'}
												option={getSkillMLevelOption()}
												onChange={setSkillLevelId}
												value={getSkillLevelValue(skillLevelId)}
												name={'skill_level_id'}
											/>
										)}
									</Field>
									{skillLevelError && (
										<p className={classes.errorText}>{skillLevelErrorMsg}</p>
									)}
									<BooleanInput
										source='is_primary'
										label='Is Primary'
										onChange={(e: any) => {
											setIsPrimarySkill(e);
										}}
									/>
									<Typography className={classes.label}>Description</Typography>
									<TextInput
										multiline={true}
										label=''
										source='description'
										fullWidth
										onChange={(e) => setSkillDescriptionList(e.target.value)}
									/>
									<Box className={containerClass.buttonContainer}>
										<Button
											onClick={modalClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid || pristine || isEmployeeSkillLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || pristine || isEmployeeSkillLoading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeeSkill;
