import React, { useState, useRef, useEffect, useCallback } from 'react';

import {
	EditorState,
	convertToRaw,
	convertFromHTML,
	ContentState,
} from 'draft-js';
import { decorator } from '../../Utils/draftjs-util';
import Editor from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import './style.css';
import { NOTES_PLACEHOLDER } from './constant';
import { debounce } from 'lodash';
import draftToHtml from 'draftjs-to-html';
import '../Opportunities/addTaskEditor.css';
import { useNotify } from 'ra-core';
import { Button, makeStyles, Box } from '@material-ui/core';
import {
	DELETE_ALLOCATION_NOTE,
	GET_PROJECTS,
	UPSERT_ALLOCATION_NOTE,
} from './gqlQueries';
const addTaskEditorStyle = makeStyles({
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '78px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
	container: {
		width: '100%',
		padding: '0px 10px 10px 10px',
	},
	saveButton: {
		width: '70px',
		height: '19px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4285F4',
		textTransform: 'none',
		marginRight: '10px',
	},
	cancelButton: {
		width: '70px',
		height: '19px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		textTransform: 'none',
		marginRight: '10px',
	},
});

const mentionPlugin = createMentionPlugin({
	entityMutability: 'IMMUTABLE',
	supportWhitespace: true,
});
const { MentionSuggestions } = mentionPlugin;
const plugins = [mentionPlugin];

export const AddNoteEditor = ({ onClose, userId, note, OnSuccess }) => {
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [searchedProject, setSearchedProject] = useState('');
	const [open, setOpen] = useState(false);
	const addTaskEditorStyles = addTaskEditorStyle();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty()
	);
	const editor = useRef(null);
	const notify = useNotify();

	const { data: projects } = useApolloQuery(GET_PROJECTS, {
		variables: {
			name: `%${searchedProject}%`,
		},
	});

	const [upsertAllocationNote] = useApolloMutation(UPSERT_ALLOCATION_NOTE);
	const [deleteAllocationNote] = useApolloMutation(DELETE_ALLOCATION_NOTE);

	useEffect(() => {
		if (!projects) {
			return;
		}
		const userSuggestion = projects?.project.map((project) => ({
			id: project?.id,
			name: project?.name,
		}));
		setMentionSuggestions(userSuggestion);
	}, [projects]);

	useEffect(() => {
		if (!note) {
			return;
		}
		setEditorState(EditorState.createEmpty());
		const blocksFromHTML = convertFromHTML(note?.note);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		setEditorState(EditorState.createWithContent(state, decorator));
	}, [note]);

	const setSearchedProjectValue = useCallback(
		debounce((newValue) => setSearchedProject(newValue), 500),
		[]
	);

	const onSearchChange = ({ value }) => {
		if (!value || value.length < 2) {
			return;
		}
		setSearchedProjectValue(value);
	};

	const focusEditor = () => {
		editor.current.focus();
	};

	const getMentions = (editorBlock) => {
		if (!editorBlock) {
			return;
		}
		let mentions = [];
		for (let key in editorBlock.entityMap) {
			const entity = editorBlock.entityMap[key];
			if (entity.type === 'mention') {
				mentions.push(entity.data.mention);
			}
		}
		return mentions;
	};

	const customEntityTransform = (entity, text) => {
		if (entity.type !== 'mention') return;
		return `<a href="">${text}</a>`;
	};

	const getContent = async () => {
		if (
			(!editorState ||
				editorState.getCurrentContent().getPlainText().length === 0) &&
			!note?.id
		) {
			notify('Please enter note', 'warning');
			return;
		}
		const contentState = editorState.getCurrentContent();
		const raw = convertToRaw(contentState);
		const mentions = await getMentions(raw);
		const htmlFormattedContent = draftToHtml(
			raw,
			{},
			false,
			customEntityTransform
		);

		return {
			content: htmlFormattedContent,
			mentionedUser: mentions,
		};
	};

	const onSave = () => {
		if (!editorState) {
			return;
		}
		if (
			editorState.getCurrentContent().getPlainText().length === 0 &&
			note?.id
		) {
			deleteAllocationNote({
				variables: {
					resourceMappingNoteId: note?.id,
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify('Note updated Successfully');
						OnSuccess();
						onClose();
					}
				})
				.catch((error) => {
					if (error) {
						console.log(error);
						return;
					}
				});
			return;
		}

		getContent().then((response) => {
			if (!response) {
				return;
			}
			const noteDetails = {
				user_id: userId,
				note: response.content,
				id: note?.id || null,
			};
			upsertAllocationNote({
				variables: {
					note: note?.id
						? noteDetails
						: {
								user_id: userId,
								note: response.content,
						  },
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify(
							note?.id ? 'Note updated Successfully' : 'Note added Successfully'
						);
						OnSuccess();
						onClose();
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	return (
		<>
			<Box className={addTaskEditorStyles.container}>
				<Box className={addTaskEditorStyles.editor} onFocus={focusEditor}>
					<Editor
						placeholder={NOTES_PLACEHOLDER}
						ref={editor}
						editorState={editorState}
						plugins={plugins}
						onChange={(editorState) => {
							if (!editorState) {
								return;
							}
							setEditorState(editorState);
						}}
						overflow-y
					/>
					<MentionSuggestions
						className='mentionSuggestions'
						open={open}
						onSearchChange={onSearchChange}
						suggestions={mentionSuggestions}
						onOpenChange={(event) => {
							setOpen(event);
						}}
					/>
				</Box>
				<Button className={addTaskEditorStyles.saveButton} onClick={onSave}>
					Save
				</Button>
				<Button className={addTaskEditorStyles.cancelButton} onClick={onClose}>
					Cancel
				</Button>
			</Box>
		</>
	);
};

export default AddNoteEditor;
