import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import './table-antdStyle.css';
import { insightChartTheme } from '../../Layout/Theme.component';

const columns = [
	{
		title: 'JOB LEVEL',
		dataIndex: 'level',
	},
	{
		title: 'EMPLOYEE COUNT',
		dataIndex: 'count',
	},
];
export const EmployeeExperienceWiseSplitUp = (props) => {
	const { jobLevelReport, loading } = props;
	return (
		<div>
			{loading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Job Level Distribution'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer height={355}>
								{jobLevelReport && jobLevelReport?.length > 0 ? (
									<Table
										pagination={false}
										columns={columns}
										dataSource={jobLevelReport}
										size='small'
										scroll={{
											x: '100w',
											y: 300,
										}}
									/>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
