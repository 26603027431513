import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';
import {
	useDeleteInvitedUserMutation,
	useGetOpportunityCountByPresalesOwnerQuery,
} from '../../generated/graphql';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	error: {
		fontSize: '14px !important',
	},
}));

interface InvitedUserDeleteModalProps {
	onClose: () => void;
	open: boolean;
	invitedUserId: string;
	userName: string;
	refetchInvitedUser: () => void;
	boardId: string;
	deleteUserId?: string;
}

const InvitedUserDeleteModal = (props: InvitedUserDeleteModalProps) => {
	const {
		open,
		onClose,
		userName,
		invitedUserId,
		refetchInvitedUser,
		boardId,
		deleteUserId,
	} = props;
	const classes = modalFormStyle();
	const [count, setCount] = useState<any>();
	const [error, setError] = useState('');
	const containerClass = useStyles();
	const notify = useNotify();
	const [deleteInvitedUser] = useDeleteInvitedUserMutation();

	const { data: opportunityCount, refetch: refetchOpportunityCount } =
		useGetOpportunityCountByPresalesOwnerQuery({
			variables: {
				boardId,
				userId: deleteUserId,
			},
		});

	useEffect(() => {
		if (isEmpty(opportunityCount?.opportunity_kanban_card_aggregate)) {
			return;
		}
		const opportunityCardCount =
			opportunityCount?.opportunity_kanban_card_aggregate?.aggregate?.count;
		setCount(opportunityCardCount);
	}, [opportunityCount]);

	useEffect(() => {
		refetchOpportunityCount();
	}, [open, refetchOpportunityCount]);

	const deleteUser = () => {
		if (count > 0) {
			setError('Cards are assigned to the user please reassign and delete');
			return;
		}
		deleteInvitedUser({
			variables: {
				invitedUserId,
			},
		}).then((data) => {
			if (data) {
				refetchInvitedUser();
				refetchOpportunityCount();
				notify('Invited user Delete Successfully');
				setError('');
				setCount(0);
			}
		});
		handleClose();
	};

	const handleClose = () => {
		setCount(0);
		setError('');
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						Do you really want to delete the user {` ${userName}`}
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box display='flex' justifyContent='flex-end'>
							<Button onClick={handleClose} className={classes.cancelButton}>
								Cancel
							</Button>
							<Button
								className={classes.saveButton}
								type='submit'
								onClick={deleteUser}
							>
								Delete
							</Button>
						</Box>
						{error && (
							<Box color='red' marginTop='5px'>
								{error}
							</Box>
						)}
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default InvitedUserDeleteModal;
