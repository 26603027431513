import React from 'react'

import {
	Typography,
	Box,
} from '@material-ui/core';
import { settingsStyle } from './settingsStyle';

const SettingsDetailContainer = ({ heading, children }: { heading: string, children: React.ReactNode }) => {
  const classes = settingsStyle();

  return (
    <Box className={classes.contentContainer}>
      <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>{heading}</Typography>
      </Box>
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  )
}

export default SettingsDetailContainer
