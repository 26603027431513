import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	createTheme,
	IconButton,
	InputAdornment,
	ThemeProvider,
	withStyles,
	Tooltip,
	Theme,
	makeStyles,
	Card,
	CardContent,
} from '@material-ui/core';
import { setSidebarVisibility } from 'react-admin';
import { insightViewStyle } from '../../../Layout/styles';
import { insightChartTheme } from '../../../Layout/Theme.component';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import dayjs from 'dayjs';
import { DATE_RANGE_ERROR, START_DATE_ERROR } from '../../Projects/constant';
import { useDispatch } from 'react-redux';
import { TimesheetDefaultersList } from './TimesheetDefaultersListComponent.component';
import { UserProfileContext } from '../../../App';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});
const useStyles = makeStyles((theme: Theme) => ({
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	reducedCountContainer: {
		height: '65px',
		background: '#FFFFFF',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		marginTop: '16px',
	},
	countContainerWithoutGrey: {
		minWidth: '161px',
		height: '50px',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
		marginLeft: '5px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	exportIcon: {
		width: '20px',
		height: '16px',
		color: 'white !important',
	},
}));
interface Hours {
	totalHours: any;
	billableHours: any;
	nonBillableHours: any;
}
interface Option {
	id: string;
	name: string;
}
interface TimeSheet {
	id: string;
	working_hours: string;
	is_billable: boolean;
}
interface FormValues {
	start_date: string;
	end_date: string;
}

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const CountCard = ({
	label,
	value,
}: {
	label: string;
	value: number | string;
}) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.reducedValueContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
export const TimesheetDefaultersListContainer = () => {
	const [startDate, setStartDate] = useState<string | null>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState<string | null>(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const classes = insightViewStyle();
	const dispatch = useDispatch();
	const customStyles = useStyles();
	const [startDateError, setStartDateError] = useState(false);
	const [dateRangeError, setDateRangeError] = useState(false);
	const [timesheetTotalHours, setTimesheetTotalHours] = useState<
		number | undefined
	>();
	const [totalUtilization, setTotalUtilization] = useState<number>();
	const { dateFormat } = useContext<any>(UserProfileContext);

	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);



	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card> 
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Timesheet: All Resources
					</Box>
				</Box>
				<CardContent>
					<div>
						<Box className={customStyles.reducedCountContainer}>
							<Box className={customStyles.countContainerWithoutGrey}>
								<Box display='flex' justifyContent='space-between'>
									<Box>
										<ThemeProvider theme={dateTimePickerTheme}>
											<Box display='flex'>
												<ErrorTooltip
													title={
														startDateError
															? START_DATE_ERROR
															: dateRangeError
															? DATE_RANGE_ERROR
															: ''
													}
													placement='top'
													arrow={true}
												>
													<Box width='188px' marginLeft='20px'>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																variant='inline'
																onChange={(date: any) => {
																	setStartDate(date);
																	if (dayjs(date).isAfter(dayjs(endDate))) {
																		setStartDateError(true);
																	} else {
																		setStartDateError(false);
																	}
																	if (
																		dayjs(endDate).diff(dayjs(date), 'day') > 30
																	) {
																		setDateRangeError(true);
																	} else {
																		setDateRangeError(false);
																	}
																}}
																value={startDate}
																format={dateFormat}
																label='Start date'
																InputLabelProps={{ shrink: true }}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position='start'>
																			<IconButton>
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	),
																}}
																error={startDateError || dateRangeError}
															/>
														</MuiPickersUtilsProvider>
													</Box>
												</ErrorTooltip>
												<Box width='188px'>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<KeyboardDatePicker
															variant='inline'
															onChange={(date: any) => {
																setEndDate(date);
																if (dayjs(startDate).isAfter(dayjs(date))) {
																	setStartDateError(true);
																} else {
																	setStartDateError(false);
																}
																if (
																	dayjs(date).diff(dayjs(startDate), 'day') > 30
																) {
																	setDateRangeError(true);
																} else {
																	setDateRangeError(false);
																}
															}}
															value={endDate}
															format={dateFormat}
															label='End date'
															InputLabelProps={{ shrink: true }}
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<IconButton>
																			<EventIcon />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</MuiPickersUtilsProvider>
												</Box>
											</Box>
										</ThemeProvider>
									</Box>
								</Box>
								{startDate &&
									endDate &&
									dayjs(endDate).diff(dayjs(startDate), 'day') <= 30 && (
										<Box display='flex'>
											<CountCard
												label='Total hours'
												value={timesheetTotalHours || '--'}
											/>
											<CountCard
												label='Total Utilization'
												value={totalUtilization ?`${totalUtilization}%` : '--'}
											/>
										</Box>
									)}
							</Box>
						</Box>
						{startDate &&
							endDate &&
							dayjs(endDate).diff(dayjs(startDate), 'day') <= 30 && (
								<Box className={classes.largeContainer}>
									<TimesheetDefaultersList
										startDate={startDate}
										endDate={endDate}
										setTotalHours={(value: number) => setTimesheetTotalHours(value)}
										setTotalUtilization={(value: number) => setTotalUtilization(value)}
									/>
								</Box>
							)}
					</div>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};

export default TimesheetDefaultersListContainer;
