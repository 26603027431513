import React, { useContext, useEffect, useState } from 'react';

import { List, ReferenceField, Datagrid, FunctionField } from 'react-admin';

import { useQuery as ApolloUseQuery } from '@apollo/client';

import {
	Box,
	makeStyles,
	Chip,
	IconButton,
	Tooltip,
	Typography,
	TextField,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';

import AddContactModal from './AddContactModal.Component';
import ContactBasicDetailsForm from './ContactBasicDetailsForm.Component';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import { GET_CONTACTS, GET_CONTACT_SEARCH } from './ContactService.gql';
import { ContactFilterFormBody } from './ContactFilterFormBody.component';
import { ellipsisStyle } from '../../Layout/styles';
import { datagridStyle, globalStyles } from '../../Layout/styles';
import { searchBarTheme } from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import { UserProfileContext } from '../../App';

import { CONTACT_EXPORT_COLUMN } from './constant';
import { exportToCsv, formatPhoneNumber } from '../../Utils/string.util';
import { ContactListPagination } from './ContactListPagination';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	headerContainer: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: 0,
		color: '#292929',
		opacity: 1,
	},
	toolbarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	input: {
		backgroundColor: 'white',
	},
	companiesContainer: {
		height: '100vh',
		marginTop: '20px',
	},
	searchInput: {
		paddingRight: '10px',
		color: '#7C7C7C',
	},
	filterContaner: {
		position: 'absolute',
		left: '2px',
	},
	userName: {
		width: '93px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
}));

export const ContactList = (props) => {
	const { permissions,filterState, dispatchFilter } = useContext(UserProfileContext);
	const classes = useStyles();
	const styles = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const commonStyles = globalStyles();
	const [contactCount, setContactCount] = useState(0);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [contactId, setContactId] = useState(null);
	const [record, setRecord] = useState({});
	const [isDrawerShown, setIsDrawerShown] = useState(false);

	const { data, loading, error } = ApolloUseQuery(GET_CONTACTS, {
		variables: {
			id: contactId ? contactId : null,
		},
	});

	const { data: contactList, refetch: refetchContactList } = ApolloUseQuery(
		GET_CONTACT_SEARCH,
		{
			variables: {
				name: `%${filterState?.contactSearchText || "".trim()}%`,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!contactList || !contactList?.crm_contacts) {
			setContactCount(0);
			return;
		}
		setContactCount(contactList.crm_contacts?.length);
	}, [contactList]);

	useEffect(() => {
		const record = data?.crm_contacts_by_pk;
		setRecord(record);
	}, [data]);

	const ContactFilter = () => {
		return (
			<DrawerFilterContainer
				open={isDrawerShown}
				close={() => {
					setIsDrawerShown(false);
				}}
			>
				<ContactFilterFormBody />
			</DrawerFilterContainer>
		);
	};

	const generateCSV = () => {
		if (contactList?.crm_contacts?.length === 0) {
			return;
		}
		const contactRow = contactList?.crm_contacts?.map((exportContact) => {
			return [
				exportContact?.full_name || '--',
				exportContact?.title || '--',
				exportContact?.crm_company?.name || '--',
				exportContact?.email || '--',
				exportContact?.primary_phone ? formatPhoneNumber(exportContact?.primary_phone) : '--',
				exportContact?.contact_type || '--',
				exportContact?.user?.full_name || '--',
				exportContact?.linkedin || '--',
			];
		});
		const contactExportData = [CONTACT_EXPORT_COLUMN, ...contactRow];
		exportToCsv('Contacts.csv', contactExportData);
	};

	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'first_name':
				return (
					<FunctionField
						record={filterValues}
						source='first_name'
						label=''
						render={() => (
							<Chip
								className={classes.multiSelectFilterChip}
								label={`${filterValues?.first_name}`}
								variant='outlined'
								onDelete={() => {
									removeFilter(filter);
								}}
							/>
						)}
					/>
				);
			case 'id':
				return (
					filterValues?.id.length > 0 &&
					filterValues?.id.map((id, index) => (
						<ReferenceField
							link={false}
							source='id'
							reference='crm_contacts'
							label='Full Name'
							resource='crm_contacts'
							record={{ id }}
							basePath={'/crm_contacts'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.title || ''} 
                      `}
											variant='outlined'
											onDelete={() => {
												filterValues.id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'owner_id':
				return (
					filterValues?.owner_id.length > 0 &&
					filterValues?.owner_id.map((owner_id, index) => (
						<ReferenceField
							link={false}
							source='owner_id'
							reference='user'
							label='Full Name'
							resource='user'
							record={{ owner_id }}
							basePath={'/crm_company'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.full_name} `}
											variant='outlined'
											onDelete={() => {
												filterValues.owner_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'company_id':
				return (
					filterValues?.company_id.length > 0 &&
					filterValues?.company_id.map((company_id, index) => (
						<ReferenceField
							link={false}
							source='company_id'
							reference='crm_company'
							label='Full Name'
							resource='crm_company'
							record={{ company_id }}
							basePath={'/crm_company'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.name || ''} 
                      `}
											variant='outlined'
											onDelete={() => {
												filterValues.company_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'contact_type':
				return (
					<FunctionField
						record={filterValues}
						source='contact_type'
						label=''
						render={() =>
							filterValues?.contact_type?.map((contactTypeFilter, index) => (
								<Chip
									className={classes.multiSelectFilterChip}
									key={index}
									label={`${contactTypeFilter}`}
									variant='outlined'
									onDelete={() => {
										filterValues.contact_type.splice(index, 1);
										setFilters(filterValues, displayedFilters);
									}}
								/>
							))
						}
					/>
				);
			case 'title':
				return (
					<FunctionField
						record={filterValues}
						source='title'
						label=''
						render={() =>
							filterValues?.title?.map((contactTitleFilter, index) => (
								<Chip
									className={classes.multiSelectFilterChip}
									key={index}
									label={`${contactTitleFilter}`}
									variant='outlined'
									onDelete={() => {
										filterValues.title.splice(index, 1);
										setFilters(filterValues, displayedFilters);
									}}
								/>
							))
						}
					/>
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	const getContactId = (contactId) => {
		setContactId(contactId);
		setIsEditModalOpen(true);
	};

	return (
		<>
			<Box className={commonStyles.pageTitle}>CONTACTS({contactCount})</Box>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				marginTop='10px'
			>
				<form onSubmit={(event) => event.preventDefault()}>
					<ThemeProvider theme={searchBarTheme}>
						<Box width='344px'>
							<TextField
								placeholder='Search contacts'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0, color: '#7C7C7C' } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_CONTACT_SEARCH_TEXT,
										payload: e?.target?.value,
									});
								}}
								value={filterState?.contactSearchText || ""}
							/>
						</Box>
					</ThemeProvider>
				</form>

				<Box display={'flex'}>
					<Box marginRight={'10px'}>
						<ExportButtonWithLoader generateCSV={generateCSV} isDisabled={!contactList?.crm_contacts?.length}/>
					</Box>
					<Box>
						<Tooltip title={'Filter'}>
							<IconButton
								onClick={() => setIsDrawerShown(true)}
								className={commonStyles.filterButton}
							>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						{permissions?.crm_contacts?.insert_permissions && (
							<AddContactModal refetch={() => refetchContactList()} />
						)}
					</Box>
				</Box>
			</Box>
			<List
				{...props}
				empty={false}
				bulkActionButtons={false}
				pagination={<ContactListPagination projectData={contactCount ?? -1} />}
				perPage={15}
				filters={
					<Box className={commonStyles.filterStyle}>
						<Box display='flex' flexDirection='column' width='100%'>
							<AppliedFiltersContainer
								totalCount={(total) => setContactCount(total)}
							>
								{getAppliedFilters}
							</AppliedFiltersContainer>
						</Box>
					</Box>
				}
				actions={<ContactFilter />}
				component='div'
				title={' '}
				sort={{ field: 'created_at', order: 'DESC' }}
				filter={{
					id:
						contactList &&
						contactList?.crm_contacts.map((contact) => contact?.id),
				}}
			>
				<Datagrid className={commonStyles.listStyle}>
					<ReferenceField
						link={false}
						source='id'
						reference='crm_contacts'
						label='NAME'
						sortable={false}
					>
						<FunctionField
							label='Full Name'
							sortBy='first_name'
							sortByOrder='ASC'
							render={(record) => {
								return (
									<Tooltip
										title={`${record?.first_name || ''} ${
											record?.last_name || ''
										}`}
										placement='right'
									>
										<Typography
											className={[classes.userName, styles.ellipsis].join(' ')}
										>
											{`${record?.first_name || ''} ${record?.last_name || ''}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					</ReferenceField>
					<FunctionField
						label='TITLE'
						render={(contactList) => {
							return (
								<Tooltip
									title={`${contactList?.title || '- -'}`}
									placement='right'
								>
									<Typography
										className={[classes.userName, styles.ellipsis].join(' ')}
									>
										{`${contactList?.title || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<ReferenceField
						link={false}
						source='company_id'
						reference='crm_company'
						label='COMPANY'
						sortBy='crm_company.name'
					>
						<FunctionField
							label={'COMPANY'}
							render={(contactList) => {
								return (
									<Tooltip
										title={`${contactList?.name || '- -'}`}
										placement='right'
									>
										<Typography
											className={[classes.userName, styles.ellipsis].join(' ')}
										>
											{`${contactList?.name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
					</ReferenceField>
					<FunctionField
						label='EMAIL'
						render={(contactList) => {
							return (
								<Tooltip
									title={`${contactList?.email || '- -'}`}
									placement='right'
								>
									<Typography
										className={[classes.userName, styles.ellipsis].join(' ')}
									>
										{`${contactList?.email || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						label='PHONE'
						render={(contactList) => {
							return (
								<Tooltip
									title={`${contactList?.primary_phone || '- -'}`}
									placement='right'
								>
									<Typography
										className={[classes.userName, styles.ellipsis].join(' ')}
									>
										{`${contactList?.primary_phone || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						label='TYPE'
						render={(contactList) => {
							return (
								<Tooltip
									title={`${contactList?.contact_type || '- -'}`}
									placement='right'
								>
									<Typography
										className={[classes.userName, styles.ellipsis].join(' ')}
									>
										{`${contactList?.contact_type || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						label='BD OWNER'
						render={(contactList) =>
							contactList?.owner_id ? (
								<>
									<ReferenceField
										link={false}
										source='owner_id'
										reference='user'
										label='BD OWNER'
									>
										<FunctionField
											render={(owner) => {
												return (
													<Tooltip
														title={`${owner?.full_name || ''} `}
														placement='right'
													>
														<Typography
															className={[
																classes.userName,
																styles.ellipsis,
															].join(' ')}
														>
															{`${owner?.full_name}`}
														</Typography>
													</Tooltip>
												);
											}}
										/>
									</ReferenceField>
								</>
							) : (
								'--'
							)
						}
					/>
					{permissions?.crm_contacts?.update_permissions && (
						<FunctionField
							render={(contactList) => (
								<Box
									onClick={() => getContactId(contactList.id)}
									className={datagridStyles.listEditIconContainer}
								>
									<IconButton className={datagridStyles.listEditIconButton}>
										<EditIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
							)}
						/>
					)}
				</Datagrid>
			</List>
			<ContactBasicDetailsForm
				open={isEditModalOpen}
				onClose={() => {
					setIsEditModalOpen(false);
				}}
				record={record}
				loading={loading}
				error={error}
				{...props}
			/>
		</>
	);
};
