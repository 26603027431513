import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	useNotify,
	useMutation,
	SelectInput,
	TextInput,
	useQuery,
	useRefresh,
} from 'react-admin';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';

import { detailsLeftCardStyle, ellipsisStyle } from '../../../Layout/styles';
import {
	Box,
	Tooltip,
	Typography,
	ThemeProvider,
	createTheme,
} from '@material-ui/core';
import {
	OpportunityDetailsLeftPanelProps,
	OpportunityStatus,
	Project,
	ProjectStatus,
} from './modal';
import {
	formatDecimal,
	getCurrencyFormat,
	isFloat,
} from '../../../Utils/string.util';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ADD_OPPORTUNITY_DOC } from '../Opportunities.gql';
import {
	GET_Project,
	GET_CRM_TASKS,
	UPSERT_OPPORTUNITY_NOTE,
	GET_OPPORTUNITY_NOTES,
	GET_OPPORTUNITY_STATUS,
} from '../Opportunities.gql';
import { makeStyles } from '@material-ui/core/styles';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../../SharedComponents/gqlQueries';

import { Form } from 'react-final-form';
import {
	TaskIcon,
	NotesIcon,
	BriefCaseIcon,
	AttachmentButtonIcon,
} from '../../../assets/SvgIcons';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EditDetails from './EditDetails.component';
import { opportunityDetailsLeftPanelStyle } from './style';
import { UserProfileContext } from '../../../App';
import { OrganizationSupportedCurrencies } from '../../../SharedComponents/model';
import ProjectForm from '../DetailsPage/ProjectForm.component';
import { ProjectEditPopOver } from './EditProject.component';
import CrmTaskForm from './CrmTaskForm.component';
import NotesForm from '../../../SharedComponents/Notes/NotesForm.component';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import { uploadFile } from '../../../Api/restApi.config';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { supportedFileFormats } from '../../../Utils';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import { useHistory } from 'react-router';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import { useDeleteOpportunityMutation } from '../../../generated/graphql';
import { orderBy, uniqBy } from 'lodash';

const opportunityStatusTheme = createTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				fontSize: '12px',
			},
		},
		MuiFormHelperText: {
			root: {
				display: 'none',
			},
		},
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				background: '#ECF1F4 0% 0% no-repeat padding-box !important',
				width: '144px',
				height: '33px',
				color: '#292929',
				fontSize: '14px',
				fontFamily: 'Manrope-semiBold',
				borderRadius: '4px',
				'& > input': {
					paddingTop: '0px',
				},
			},
			inputMarginDense: {
				paddingTop: '0px',
				paddingBottom: '0px',
				fontSize: '14px',
			},
			input: {
				padding: '0px 0px 0px 8px',
			},
		},
		MuiInputLabel: {
			marginDense: {
				fontSize: '12px',
				color: '#5C5C5C',
				fontFamily: 'Manrope-semiBold',
			},
		},
		MuiFormControl: {
			root: {
				display: 'flex',
				justifyContent: 'end',
				alignItems: 'flex-end',
			},
		},
		MuiSelect: {
			select: {
				fontSize: '14px',
				fontFamily: 'Manrope-semibold',
			},
		},
	},
});

const useStyles = makeStyles({
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editButtonContainer: {
		opacity: 1,
		borderRadius: '100px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		width: '20px',
		height: '20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: '#4285F4',
		},
	},
	popOver: {
		marginRight: '10px',
	},
	editContainer: {
		width: '20px',
		height: '20px',
	},
});

const OpportunityDetailsLeftPanel = (
	props: OpportunityDetailsLeftPanelProps
) => {
	const { id: opportunityId }: { id: string } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const refresh = useRefresh();
	const { opportunity, refetch, setUploaded } = props;
	const {
		orgId: organizationId,
		permissions,
		dateFormat,
		orgBaseCurrency,
	} = useContext<any>(UserProfileContext);
	const detailsLeftCardStyles = detailsLeftCardStyle();
	const [opportunityProbablityFormValues, setOpportunityProbablityFormValues] =
		useState({});
	const [opportunityStatusOptions, setOpportunityStatusOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [uploadMessage, setUploadMessage] = useState('');
	const opportunityDetailsLeftPanelStyles = opportunityDetailsLeftPanelStyle();
	const [probability, setProbability] = useState(opportunity?.win_percentage);
	const [kanbanColumnId, setKanbaColumnId] = useState(null);
	const [isEditModalShown, setIsEditModalShown] = useState(false);
	const [opportunityForm, setOpportunityForm] = useState({});
	const [project, setProject] = useState<Project>({});
	const [projectFormInitialValues, setProjectFormInitialValues] =
		useState<Project>({});
	const [projectStatusId, setProjectStatusId] = useState('');
	const [projectChoices, setProjectChoices] = useState([]);
	const [popper, setPopper] = useState(false);
	const [currencyList, setCurrencyList] = useState<
		{ id: string; name: string }[]
	>([]);
	const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
	const [isTaskFormShown, setIsTaskFormShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isOpportunityNotesFormShown, setIsOpportunityNotesFormShown] =
		useState(false);
	const createdDate = dayjs(opportunity?.created_at).startOf('day');
	const sinceDays = dayjs().startOf('day').diff(createdDate, 'days');
	const closeDate = opportunity?.close_date
		? dayjs(opportunity?.close_date).format(dateFormat)
		: '--';
	const ellipsisStyles = ellipsisStyle();
	const inputRef = useRef<HTMLInputElement>(null);
	const { data: opportunityStatus } = useApolloQuery(GET_OPPORTUNITY_STATUS, {
		variables: {
			boardId: opportunity?.kanbanCard?.kanban_column?.kanban_board?.id,
		},
	});
	const [addOpportunityDoc, { error: uploadError }] =
		useApolloMutation(ADD_OPPORTUNITY_DOC);
	const { refetch: refetchCompletedTaskList } = useApolloQuery(GET_CRM_TASKS, {
		variables: {
			opportunity_id: opportunityId,
			is_done: true,
		},
	});
	const { refetch: refetchUpcomingTaskList } = useApolloQuery(GET_CRM_TASKS, {
		variables: {
			opportunity_id: opportunityId,
			is_done: false,
		},
	});

	const refetchCrmTaskList = () => {
		refetchUpcomingTaskList();
		refetchCompletedTaskList();
	};

	const { data: opportunityProject, refetch: refetchProjectList } =
		useApolloQuery(GET_Project, {
			variables: {
				opportunity_id: opportunity?.id || null,
			},
		});

	const [mutate] = useMutation();
	const notify = useNotify();

	const { refetch: refetchOpportunityNotes } = useApolloQuery(
		GET_OPPORTUNITY_NOTES,
		{
			variables: {
				opportunity_id: opportunity?.id,
				SearchText: `%%`,
			},
		}
	);
	const [upsertOpportunityNote] = useApolloMutation(UPSERT_OPPORTUNITY_NOTE);

	const upsertNote = (note: NoteForm) => {
		upsertOpportunityNote({
			variables: {
				opportunityNote: {
					opportunity_id: opportunity?.id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Opportunity Notes Created successfully');
			}
		});
	};

	const { data: organisationSupportedCurrencies } = useApolloQuery<any>(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);

	useEffect(() => {
		if (
			!organisationSupportedCurrencies ||
			!organisationSupportedCurrencies?.org_supported_currencies
		) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);
		setCurrencyList(
			uniqBy([...organizationCurrencyChoices, orgBaseCurrency], 'id')
		);
	}, [orgBaseCurrency, organisationSupportedCurrencies]);

	const { data: projectStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'project_status',
		payload: {
			sort: { field: 'label', order: 'ASC' },
		},
	});

	useEffect(() => {
		if (!projectStatus || !(projectStatus?.length > 0)) {
			return;
		}
		const projectStatusList = projectStatus.map((value: ProjectStatus) => {
			return { id: value?.id, name: value?.label };
		});
		setProjectChoices(projectStatusList);
	}, [projectStatus]);

	useEffect(() => {
		if (!opportunity) {
			return;
		}
		setOpportunityForm({
			...opportunity,
			kanban_id: opportunity?.kanbanCard?.id,
			status: opportunity?.kanbanCard?.kanban_column?.id,
			kanban_board_id: opportunity?.kanbanCard?.kanban_column?.kanban_board?.id,
		});
	}, [opportunity]);

	useEffect(() => {
		setOpportunityProbablityFormValues({
			win_percentage: opportunity?.win_percentage + '%',
			status: opportunity?.kanbanCard?.kanban_column?.id,
		});
	}, [opportunity]);

	useEffect(() => {
		if (!opportunityStatus) {
			return;
		}
		const kanbanData = opportunityStatus?.kanban_board[0].kanban_columns.map(
			(value: OpportunityStatus) => {
				return { id: value?.id, name: value?.name };
			}
		);
		const sortedKanbanData: any = orderBy(
			kanbanData,
			[(data) => data.name.toUpperCase()],
			['asc']
		);
		setOpportunityStatusOptions(sortedKanbanData);
	}, [opportunityStatus]);

	useEffect(() => {
		if (!opportunityProject || !(opportunityProject.project.length > 0)) {
			return;
		}
		setProject({
			projectId: opportunityProject.project[0]?.id,
			project_status_id: opportunityProject.project[0]?.project_status_id,
			opportunity_id: opportunity?.id,
			name: opportunityProject.project[0]?.name,
			project_type: opportunityProject.project[0]?.project_type,
			revenue: opportunityProject.project[0]?.revenue,
			pre_sales_owner_id: opportunityProject.project[0]?.pre_sales_owner_id,
			geo_id: opportunityProject.project[0]?.geo_id,
			description: opportunityProject.project[0]?.description,
			start_date: opportunityProject.project[0]?.start_date,
			CompanyName: opportunity?.crm_company?.name,
			company_id: opportunity?.crm_company?.id,
			userId: opportunity?.owner_id,
			currency_id: opportunityProject.project[0]?.currency_id,
			end_date: opportunityProject.project[0]?.end_date,
		});
	}, [opportunity, opportunityProject]);

	useEffect(() => {
		if (!project) {
			return;
		}
		setProjectStatusId(project?.project_status_id || '');
		setProjectFormInitialValues({
			project_status_id: projectStatusId,
			opportunity_id: opportunity?.id,
			project_type: 'TM',
			revenue: 0,
			pre_sales_owner_id: opportunity?.owner_id,
			geo_id: opportunity?.crm_company?.country_id,
			CompanyName: opportunity?.crm_company?.name,
			company_id: opportunity?.crm_company?.id,
			userId: opportunity?.owner_id,
			currency_id: orgBaseCurrency?.id,
		});
	}, [project, orgBaseCurrency, projectStatusId, opportunity]);

	const changeHandler = (event: any) => {
		const filename = event.target.files[0]?.name;
		setLoading(true);
		if (supportedFileFormats.includes(filename.split('.').pop())) {
			setUploaded('');
			let data = new FormData();
			data.append('uploadType', 'OpportunityDocs');
			data.append('file', event.target.files[0]);
			data.append('id', organizationId);
			uploadFile(data)
				.then((res) => {
					addOpportunityDoc({
						variables: {
							data: {
								type: 'OpportunityDocs',
								url: res.data.url,
								opportunity_id: opportunityId,
								file_name: filename,
							},
						},
					})
						.then(() => {
							setLoading(false);
							setPopper(true);
							setUploaded('success');
							setUploadMessage('File uploaded to documents successfully');
						})
						.catch(() => {
							setLoading(false);
							setPopper(true);
							setUploadMessage('File already exist in the documents');
							if (uploadError) return `Submission error! ${uploadError}`;
						});
				})
				.catch((err) => {
					setPopper(true);
					setUploadMessage(err.response.data.message);
					setLoading(false);
				});
		} else {
			setUploadMessage(
				` '${filename
					.split('.')
					.pop()}'  file format is not supported in documents. `
			);
			setLoading(false);
		}
	};

	const handleBlur = () => {
		if (!probability || opportunity.win_percentage === probability) {
			return;
		}
		mutate(
			{
				type: 'update',
				resource: 'opportunities',
				payload: {
					id: opportunity?.id,
					data: {
						win_percentage: probability || 0,
					},
				},
			},
			{
				onSuccess: ({ data }: { data: any }) => {
					setOpportunityProbablityFormValues({
						...opportunityProbablityFormValues,
						win_percentage: data?.win_percentage + '%',
					});
					notify('Details Updated Successfully');
				},
				onFailure: (error: any) => {
					notify(`Update failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const handleChangeKanbanColumn = () => {
		if (!kanbanColumnId || opportunity.kanbanCard?.id === kanbanColumnId) {
			return;
		}
		mutate(
			{
				type: 'update',
				resource: 'opportunity_kanban_card',
				payload: {
					id: opportunity?.kanbanCard?.id,
					data: {
						column_id: kanbanColumnId,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					refetch();
				},
				onFailure: (error) => {
					notify(`Update failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const saveNotesForm = (note: NoteForm) => {
		upsertNote(note);
	};
	const [deleteOpportunity] = useDeleteOpportunityMutation();

	const onDeleteOpportunity = () => {
		deleteOpportunity({
			variables: {
				id: opportunityId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Opportunity deleted successfully');
					history.goBack();
				}
			})
			.catch((error: string) => {
				if (error) {
					notify(error, 'warning');
				}
			});
	};

	const handleCompanyDetailsLink = (url: string) => {
		if (url.startsWith('http')) {
			return `${url}`;
		} else {
			return `//${url}`;
		}
	};
	return (
		<>
			<Box className={opportunityDetailsLeftPanelStyles.outerContainer}>
				<Box className={opportunityDetailsLeftPanelStyles.container}>
					<Box className={opportunityDetailsLeftPanelStyles.headerContainer}>
						<Box
							display='flex'
							justifyContent='flex-end'
							position='relative'
							top='4px'
							right='6px'
						>
							<Box className={classes.editContainer}>
								{permissions?.opportunities?.update_permissions && (
									<Box className={classes.editButtonContainer}>
										<div className={classes.popOver} id='#edit_opp'>
											<PopOver
												edit={() => setIsEditModalShown(true)}
												remove={() => {
													setIsDeleteModalShown(true);
												}}
												isModalClosed={isEditModalShown}
												isDeleteShown={
													permissions?.opportunities?.update_permissions
														?.deleted_at
														? true
														: false
												}
											/>
										</div>
									</Box>
								)}
							</Box>
						</Box>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							position='relative'
							height='82%'
						>
							<Box>
								<Tooltip title={`${opportunity.name}`} placement='right'>
									<Typography
										className={opportunityDetailsLeftPanelStyles.header}
									>
										{opportunity.name}
									</Typography>
								</Tooltip>
							</Box>
							<Box>
								<Tooltip
									title={`${opportunity?.crm_company?.name}`}
									placement='right'
								>
									<Typography
										className={opportunityDetailsLeftPanelStyles.subHeading}
									>
										Company: {opportunity?.crm_company?.name || '--'}
									</Typography>
								</Tooltip>
							</Box>
							<Box
								display='flex'
								alignItems='center'
								justifyContent='center'
								position='absolute'
								bottom='0px'
							>
								{opportunity?.crm_company?.linkedin ? (
									<a
										href={handleCompanyDetailsLink(
											opportunity?.crm_company?.linkedin
										)}
										target=' _blank'
									>
										<LinkedInIcon
											className={opportunityDetailsLeftPanelStyles.websiteIcon}
										/>
									</a>
								) : (
									''
								)}
								{opportunity?.crm_company?.website ? (
									<a
										href={handleCompanyDetailsLink(
											opportunity?.crm_company?.website
										)}
										target=' _blank'
									>
										<LanguageIcon
											className={opportunityDetailsLeftPanelStyles.websiteIcon}
										/>
									</a>
								) : (
									''
								)}
							</Box>
						</Box>
					</Box>
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={popper}
						autoHideDuration={4000}
						onClose={() => {
							setPopper(false);
						}}
						message={uploadMessage}
						action={
							<React.Fragment>
								<IconButton
									size='small'
									aria-label='close'
									color='inherit'
									onClick={() => {
										setPopper(false);
									}}
								>
									<CloseIcon fontSize='small' />
								</IconButton>
							</React.Fragment>
						}
					/>
					{permissions?.opportunities?.update_permissions && (
						<Box className={opportunityDetailsLeftPanelStyles.actionsContainer}>
							<Box
								className={
									opportunityDetailsLeftPanelStyles.actionButtonContainer
								}
								onClick={() => {
									setIsTaskFormShown(true);
								}}
							>
								<TaskIcon
									className={opportunityDetailsLeftPanelStyles.actions}
								/>
								<Typography className={opportunityDetailsLeftPanelStyles.label}>
									Tasks
								</Typography>
							</Box>
							<input
								type='file'
								ref={inputRef}
								style={{ display: 'none' }}
								onChange={changeHandler}
							/>
							<Box
								onClick={() => {
									inputRef.current?.click();
								}}
								className={
									opportunityDetailsLeftPanelStyles.actionButtonContainer
								}
							>
								<AttachmentButtonIcon
									className={opportunityDetailsLeftPanelStyles.actions}
								/>
								<Typography className={opportunityDetailsLeftPanelStyles.label}>
									Attachments
								</Typography>
							</Box>
							<Backdrop
								className={opportunityDetailsLeftPanelStyles.backdrop}
								open={loading}
							>
								<CircularProgress color='inherit' />
							</Backdrop>
							<Box
								className={
									opportunityDetailsLeftPanelStyles.actionButtonContainer
								}
								onClick={() => {
									setIsOpportunityNotesFormShown(true);
								}}
							>
								<NotesIcon
									className={opportunityDetailsLeftPanelStyles.actions}
								/>
								<Typography className={opportunityDetailsLeftPanelStyles.label}>
									Notes
								</Typography>
							</Box>
						</Box>
					)}
					<Box className={opportunityDetailsLeftPanelStyles.actionsContainer}>
						<Box>
							<Typography className={opportunityDetailsLeftPanelStyles.label}>
								Value
							</Typography>
							<Typography
								className={opportunityDetailsLeftPanelStyles.largeValue}
							>
								{getCurrencyFormat(
									isFloat(opportunity?.value)
										? formatDecimal(opportunity?.value, 2)
										: opportunity?.value,
									opportunity?.master_currency?.currency_type,
									true
								)}
							</Typography>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.horizontalDivider}
						></Box>
						<Box>
							<Typography className={opportunityDetailsLeftPanelStyles.label}>
								Since
							</Typography>
							<Typography
								className={opportunityDetailsLeftPanelStyles.largeValue}
							>
								{sinceDays + ' Days'}
							</Typography>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.horizontalDivider}
						></Box>
						<Box>
							<Typography className={opportunityDetailsLeftPanelStyles.label}>
								Priority
							</Typography>
							<Typography
								className={opportunityDetailsLeftPanelStyles.largeValue}
							>
								<span
									className={
										opportunity?.priority === 'High'
											? opportunityDetailsLeftPanelStyles.priorityHigh
											: opportunity?.priority === 'Medium'
											? opportunityDetailsLeftPanelStyles.priorityMedium
											: opportunityDetailsLeftPanelStyles.priorityLow
									}
								></span>
								<span style={{ paddingLeft: '5px' }}>
									{opportunity?.priority}
								</span>
							</Typography>
						</Box>
					</Box>
					<Box className={detailsLeftCardStyles.addtionalInfoContainer}>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Status
							</Typography>
							<Box>
								<Form
									initialValues={opportunityProbablityFormValues}
									onSubmit={() => {}}
								>
									{() => (
										<form>
											<ThemeProvider theme={opportunityStatusTheme}>
												<SelectInput
													choices={opportunityStatusOptions}
													source='status'
													label={''}
													onChange={(e: any) =>
														setKanbaColumnId(e.target.value)
													}
													onBlur={handleChangeKanbanColumn}
													disabled={
														!permissions?.opportunities?.update_permissions
													}
												/>
											</ThemeProvider>
										</form>
									)}
								</Form>
							</Box>
						</Box>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							mb={2}
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Probability
							</Typography>
							<Box>
								<Form
									initialValues={opportunityProbablityFormValues}
									onSubmit={() => {}}
								>
									{() => (
										<form>
											<ThemeProvider theme={opportunityStatusTheme}>
												<TextInput
													source='win_percentage'
													label={''}
													onFocus={() => {
														setOpportunityProbablityFormValues({
															...opportunityProbablityFormValues,
															win_percentage: opportunity?.win_percentage,
														});
													}}
													onBlur={handleBlur}
													onChange={(e: any) => {
														setProbability(e.target.value);
													}}
													disabled={
														!permissions?.opportunities?.update_permissions
													}
												/>
											</ThemeProvider>
										</form>
									)}
								</Form>
							</Box>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.additionalContent}
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Contact
							</Typography>
							<Tooltip
								title={`${opportunity?.crm_contact?.first_name || '--'} ${
									opportunity?.crm_contact?.last_name || ''
								}`}
								placement='right'
							>
								<Typography
									className={[
										opportunityDetailsLeftPanelStyles.ellipsisContent,
										ellipsisStyles.ellipsis,
									].join(' ')}
								>
									{`${opportunity?.crm_contact?.first_name || '--'} ${
										opportunity?.crm_contact?.last_name || ''
									}`}
								</Typography>
							</Tooltip>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.additionalContent}
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Pre-sales Owner
							</Typography>
							<Tooltip
								title={`${opportunity?.user?.full_name || '--'} `}
								placement='right'
							>
								<Typography
									className={[
										opportunityDetailsLeftPanelStyles.ellipsisContent,
										ellipsisStyles.ellipsis,
									].join(' ')}
								>
									{`${opportunity?.user?.full_name || '--'}`}
								</Typography>
							</Tooltip>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.additionalContent}
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Close Date
							</Typography>
							<Tooltip title={closeDate} placement='right'>
								<Typography
									className={[
										opportunityDetailsLeftPanelStyles.ellipsisContent,
										ellipsisStyles.ellipsis,
									].join(' ')}
								>
									{closeDate}
								</Typography>
							</Tooltip>
						</Box>
						<Box
							className={
								opportunityDetailsLeftPanelStyles.bottomAdditonalContent
							}
						>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Lead Owner
							</Typography>
							<Tooltip
								title={`${opportunity?.leadOwner?.full_name || '--'}`}
								placement='right'
							>
								<Typography
									className={[
										opportunityDetailsLeftPanelStyles.ellipsisContent,
										ellipsisStyles.ellipsis,
									].join(' ')}
								>
									{`${opportunity?.leadOwner?.full_name || '--'} `}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
				</Box>
				{opportunityProject?.project[0]?.id ? (
					<Box className={opportunityDetailsLeftPanelStyles.projectContainer}>
						<Box className={opportunityDetailsLeftPanelStyles.projectHeader}>
							<Box className={opportunityDetailsLeftPanelStyles.project}>
								<BriefCaseIcon
									className={opportunityDetailsLeftPanelStyles.projectIcon}
								/>
								<Tooltip
									title={`${opportunityProject?.project[0].name}`}
									placement='right'
								>
									<Box
										className={opportunityDetailsLeftPanelStyles.projectName}
									>
										{opportunityProject?.project[0].name}
									</Box>
								</Tooltip>
							</Box>
							<Box
								className={opportunityDetailsLeftPanelStyles.projectCurrency}
							>
								{permissions?.project?.update_permissions && (
									<ProjectEditPopOver
										open={isProjectModalOpen}
										onClose={() => setIsProjectModalOpen(false)}
										projectData={project}
										projectChoices={projectChoices}
										projectStatus={projectStatus}
										currencyList={currencyList}
										refetch={() => refetchProjectList()}
										projectName={opportunityProject?.project[0].name}
									/>
								)}
							</Box>
						</Box>
						<Box
							className={opportunityDetailsLeftPanelStyles.projectInfoContainer}
						>
							<Box
								className={opportunityDetailsLeftPanelStyles.actionsContainer}
							>
								<Box>
									<Box className={opportunityDetailsLeftPanelStyles.label}>
										Project Type
									</Box>
									<Box className={opportunityDetailsLeftPanelStyles.largeValue}>
										{opportunityProject?.project[0]?.project_type}
									</Box>
								</Box>
								<Box
									className={
										opportunityDetailsLeftPanelStyles.horizontalDivider
									}
								></Box>
								<Box>
									<Box className={opportunityDetailsLeftPanelStyles.label}>
										Project Status
									</Box>
									<Box className={opportunityDetailsLeftPanelStyles.largeValue}>
										{opportunityProject?.project[0]?.ProjectStatus?.label}
									</Box>
								</Box>
								<Box
									className={
										opportunityDetailsLeftPanelStyles.horizontalDivider
									}
								></Box>
								<Box>
									<Box className={opportunityDetailsLeftPanelStyles.label}>
										Revenue
									</Box>
									<Box className={opportunityDetailsLeftPanelStyles.largeValue}>
										{getCurrencyFormat(
											opportunityProject?.project[0]?.revenue,
											opportunityProject?.project[0]?.currency?.currency_type,
											true
										)}
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<>
						{permissions?.project?.insert_permissions && (
							<Box
								className={opportunityDetailsLeftPanelStyles.projectContainer}
							>
								<Box
									className={
										opportunityDetailsLeftPanelStyles.createProjectButton
									}
									onClick={() => setIsProjectModalOpen(true)}
								>
									<BriefCaseIcon
										className={
											opportunityDetailsLeftPanelStyles.createProjectIcon
										}
									/>
									<Typography
										className={
											opportunityDetailsLeftPanelStyles.createButtonLabel
										}
									>
										Create Project
									</Typography>
								</Box>
							</Box>
						)}
					</>
				)}
			</Box>
			<EditDetails
				open={isEditModalShown}
				onClose={() => setIsEditModalShown(false)}
				record={opportunityForm}
				refetch={refetch}
				boardId={opportunity?.kanbanCard?.kanban_column?.kanban_board?.id}
			/>
			<ProjectForm
				open={isProjectModalOpen}
				onClose={() => setIsProjectModalOpen(false)}
				projectData={projectFormInitialValues}
				projectChoices={projectChoices}
				projectStatus={projectStatus}
				currencyList={currencyList}
				refetch={() => refetchProjectList()}
			/>
			<CrmTaskForm
				CrmTask={{}}
				open={isTaskFormShown}
				onClose={() => {
					setIsTaskFormShown(false);
				}}
				refetch={refetchCrmTaskList}
				opportunity_id={opportunity?.id}
				boardId={opportunity?.kanbanCard?.kanban_column?.kanban_board?.id}
			/>
			<NotesForm
				open={isOpportunityNotesFormShown}
				onClose={() => {
					setIsOpportunityNotesFormShown(false);
				}}
				saveForm={saveNotesForm}
				refetch={() => {
					refetchOpportunityNotes();
					refresh();
				}}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onDelete={onDeleteOpportunity}
				onClose={() => setIsDeleteModalShown(false)}
				heading='Delete Opportunity?'
				confirmationMessage='You are about to permanently delete this opportunity and all of its associated data including tasks, notes and documents.'
			/>
		</>
	);
};

export default OpportunityDetailsLeftPanel;
