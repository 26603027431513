import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	MenuItem,
} from '@material-ui/core';
import { List, Datagrid, FunctionField, Pagination } from 'react-admin';
import 'antd/dist/antd.css';
import React, { useContext, ReactNode } from 'react';
import { useNotify } from 'react-admin';
import { Box, Tooltip, Select } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { UserProfileContext } from '../../App';
import { ellipsisStyle } from '../../Layout/styles';
import { statusSelectBoxTheme } from '../ProjectTask/dataGridThemes';
import dayjs from 'dayjs';
import {
	useUpdateSubtaskStatusByIdMutation,
	useOpenTaskQuery,
} from '../../generated/graphql';
import { head } from 'lodash';
import { homePageStyle } from './style';

export const Style = makeStyles({
	name: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		opacity: 1,
		padding: '10px 0 0 4px',
		width: '80px',
	},
	title: {
		width: '47px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	milestonePeriodEnded: {
		color: '#EA4335',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		width: '100px',
		marginTop: '10px',
		marginRight: '16px',
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		display: 'flex',
		justifyContent: 'center',
	},
	pagination: {
		marginBottom: '14px',
		position: 'relative',
	},
	rowPadding: {
		padding: '5px',
	},
	fullHeight: {
		height: '100%',
	},
});

const TaskList = () => {
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const classes = Style();
	const styles = ellipsisStyle();
	const homeStyle = homePageStyle();
	const notify = useNotify();

	const { data: openTask, refetch } = useOpenTaskQuery({
		variables: {
			userId,
		},
		skip: !userId,
		fetchPolicy: 'network-only',
	});

	const [updateTaskStatus] = useUpdateSubtaskStatusByIdMutation();

	const getEndDateTooltipValue = (date: string): string => {
		if (!date) {
			return '- -';
		}
		const diffDays = dayjs(date)
			.startOf('day')
			.diff(dayjs().startOf('day'), 'days');
		if (diffDays <= 0) {
			return `Overdue by ${dayjs().diff(dayjs(date), 'days')}`;
		}
		return dayjs(date).format(dateFormat);
	};

	const getEndDate = (date: string): ReactNode => {
		if (!date) {
			return <Typography className={classes.title}>- -</Typography>;
		}
		const diffDays = dayjs(date).diff(dayjs(), 'days');
		const milestoneAvailableDays = `${diffDays} Days`;

		if (diffDays === 0) {
			return (
				<Typography className={`${classes.title} ${styles.ellipsis}`}>
					Due in 1 day
				</Typography>
			);
		}

		if (diffDays <= 0) {
			return (
				<Typography
					className={`${classes.title} ${styles.ellipsis} ${classes.milestonePeriodEnded}`}
				>
					{dayjs(date).format(dateFormat)}
				</Typography>
			);
		}

		if (diffDays >= 30) {
			return (
				<Typography className={`${classes.title} ${styles.ellipsis}`}>
					{dayjs(date).format(dateFormat)}
				</Typography>
			);
		}

		return (
			<Typography className={`${classes.title} ${styles.ellipsis}`}>
				{`${milestoneAvailableDays} left`}
			</Typography>
		);
	};

	const updateStatus = (taskId: String, statusId: String) => {
		updateTaskStatus({
			variables: {
				id: taskId,
				column_id: statusId,
			},
		})
			.then((response: any) => {
				if (!response.errors) {
					notify('Task updated successfully');
					refetch();
					return;
				}
			})
			.catch((error: any) => {
				if (error) {
					return;
				}
			});
	};

	const TaskPagination = () => {
		return (
			<Box className={classes.pagination}>
				<Pagination rowsPerPageOptions={[4, 8, 16]} />
			</Box>
		);
	};

	return (
		<Box className={classes.fullHeight}>
			<Card className={classes.fullHeight}>
				<CardHeader title='My Tasks' titleTypographyProps={{ variant: 'h6' }} />
				<CardContent style={{ overflowY: 'auto' }}>
					<ResponsiveContainer height={355}>
						<Box>
							<List
								bulkActionButtons={false}
								resource={'project_task'}
								sort={{ field: 'planned_end_date', order: 'DESC' }}
								basePath='/home'
								filter={{
									id: openTask?.projectTaskList?.map((value: any) => value?.id),
								}}
								perPage={4}
								actions={false}
								pagination={<TaskPagination />}
								component='div'
								title={' '}
							>
								<Datagrid classes={{ headerCell: homeStyle.headerCell }}>
									<FunctionField
										label='NAME'
										render={(record: any) => {
											return (
												<Tooltip
													title={`${record?.title || ''}`}
													placement='right'
												>
													<Typography
														className={`${homeStyle.additionalInfoLabelLeave} ${classes.rowPadding} ${styles.ellipsis}`}
													>
														{`${record?.title}`}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Project Name'
										render={(record: any) => {
											const response = openTask?.projectTaskList?.find(
												(value) => value?.id === record?.id
											);
											return (
												<Tooltip
													title={response?.project?.name || ''}
													placement='right'
												>
													<Typography
														className={[
															homeStyle.additionalInfoLabelLeave,
															styles.ellipsis,
														].join(' ')}
													>
														{response?.project?.name}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Due Date'
										render={(record: any) => {
											return (
												<Tooltip
													title={getEndDateTooltipValue(
														record?.planned_end_date
													)}
													placement='right'
												>
													<Typography
														className={[
															homeStyle.additionalInfoLabelLeave,
															styles.ellipsis,
														].join(' ')}
													>
														{getEndDate(record?.planned_end_date)}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Task Status'
										render={(record: any) => {
											const response = openTask?.projectTaskList?.find(
												(value) => value?.id === record?.id
											);
											return (
												<ThemeProvider theme={statusSelectBoxTheme}>
													<Select
														value={
															head(response?.project_kanban_cards)
																?.kanban_column?.id || ''
														}
														onChange={(event: any) =>
															updateStatus(
																response?.project_kanban_cards[0]?.id,
																event?.target?.value
															)
														}
														disableUnderline={true}
														variant='outlined'
													>
														{response?.project?.kanban_board?.kanban_columns &&
															response?.project?.kanban_board?.kanban_columns?.map(
																(option) => (
																	<MenuItem value={option?.id}>
																		{option?.name}
																	</MenuItem>
																)
															)}
													</Select>
												</ThemeProvider>
											);
										}}
									/>
								</Datagrid>
							</List>
						</Box>
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</Box>
	);
};

export default TaskList;
