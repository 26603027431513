import React, { useContext } from 'react';

import { FunctionField, ReferenceField } from 'react-admin';

import { DEFAULT_PROFILE_PIC } from '../../config/constant';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

import {
	Paper,
	Avatar,
	Link as MuiLink,
	Tooltip,
	Typography,
	Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ellipsisStyle, gridCardGlobalStyle } from '../../Layout/styles';
import { formatEmployeeId, getPrefix } from '../../Utils/string.util';
import { UserProfileContext } from '../../App';

const useStyles = makeStyles((theme) => ({
	identity: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: '5px',
	},
	titleContainer: {
		width: '130px',
		height: '22px',
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	subTitleContainer: {
		width: '100px',
		height: '22px',
		textAlign: 'center',
		marginBottom: '4px',
	},
	avatar: {
		width: '67px',
		height: '67px',
		backgroundColor: 'aliceblue',
	},
	titleContent: {
		width: '130px',
		height: '22px',
		font: 'normal normal bold 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#212121',
		opacity: 1,
		fontSize: '12px !important',
	},
	subTitleContent: {
		height: '22px',
		fontSize: '11px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	subTitleJobLevelContent: {
		fontSize: '10px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	secondaryInfo: {
		width: '88px',
		paddingLeft: '10px',
		paddingRight: '10px',
		height: '22px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		opacity: 1,
		borderRadius: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-2px',
		marginBottom: '0',
		zIndex: 10,
	},
	secondaryInfoContent: {
		fontSize: '10px !important',
		font: 'normal normal 600 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#FFFFFF',
		opacity: 1,
	},
	secondaryInfoEmpIdContent: {
		fontSize: '10px !important',
		font: 'normal normal 800 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#FFFFFF',
		opacity: 1,
	},

	additionalInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '8px',
	},
	additionalInfoIcon: {
		width: '16px',
		height: '16px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 100,
		color: '#4285F4',
	},
	additionalInfoContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '10px !important',
		font: 'normal normal 600 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
	contactIcon: {
		fontSize: '10px',
		color: '#4285F4',
	},
	contactInfo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '-4px',
	},
	contactDisplay: {
		fontSize: '11px !important',
		font: 'normal normal 600 10px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
}));

export const EmployeeCard = ({ data, basePath, resource }) => {
	const classes = useStyles();
	const cardGlobalStyle = gridCardGlobalStyle();
	const styles = ellipsisStyle();
	const { empIdLength, isFixedEmpIdLengthEnabled, setTabGlobalIndex } =
		useContext(UserProfileContext);

	const resetTabGlobalIndexForNewEmp = () => {
		setTabGlobalIndex(0);
	};

	const formattedEmployeeId = formatEmployeeId(
		data?.emp_id,
		empIdLength,
		isFixedEmpIdLengthEnabled
	);
	return (
		<Paper
			className={cardGlobalStyle.container}
			onClick={resetTabGlobalIndexForNewEmp}
		>
			<MuiLink
				component={Link}
				to={`/employee/details/${data?.id}`}
				underline='none'
			>
				<div className={classes.identity}>
					<ReferenceField
						link={false}
						source='user_id'
						reference='user'
						label='Full Name'
						resource={resource}
						record={data}
						basePath={basePath}
					>
						<FunctionField
							render={(record) => (
								<Avatar
									alt='employee_image'
									// TODO We need to findout the high resolution image from the google api
									src={`${record?.profile_pic || DEFAULT_PROFILE_PIC}`}
									className={classes.avatar}
								/>
							)}
						/>
					</ReferenceField>

					<div className={classes.titleContainer}>
						<ReferenceField
							link={false}
							source='user_id'
							reference='user'
							label='Full Name'
							resource={resource}
							record={data}
							basePath={basePath}
						>
							<FunctionField
								className={classes.titleContent}
								label='Full Name'
								sortBy='first_name'
								sortByOrder='ASC'
								render={(record) => {
									return (
										<Tooltip
											title={`${record?.first_name} ${
												record?.middle_name || ''
											} ${record?.last_name || ''}`}
											placement='right'
										>
											<Box className={`${styles.ellipsis}`}>
												{`${record?.first_name} ${record?.middle_name || ''} ${
													record?.last_name || ''
												}`}
											</Box>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					</div>

					<div className={`${classes.subTitleContainer}`}>
						<ReferenceField
							link={false}
							source='designation_id'
							reference='designation'
							resource={resource}
							record={data}
							basePath={basePath}
						>
							<FunctionField
								className={`${classes.subTitleContent}`}
								record={data}
								render={(record) => {
									return (
										<Tooltip
											title={`${record?.title || ' - - '}`}
											placement='right'
										>
											<Box
												className={`${classes.subTitleContent} ${styles.ellipsis}`}
											>
												{record?.title || '- -'}
											</Box>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					</div>
					<div className={`${classes.secondaryInfo}`}>
						{data?.employee_prefix_id ? (
							<ReferenceField
								link={false}
								source='employee_prefix_id'
								reference='employee_prefix'
								record={data}
							>
								<>
									<FunctionField
										render={(record) => {
											return (
												<Tooltip
													title={`${getPrefix(record?.name, record?.value)}${
														formattedEmployeeId || ''
													}`}
													placement='right'
												>
													<Box
														maxWidth={'80px'}
														className={`${classes.secondaryInfoEmpIdContent} ${styles.ellipsis}`}
													>
														{`${getPrefix(record?.name, record?.value)}${
															formattedEmployeeId || ''
														}`}
													</Box>
												</Tooltip>
											);
										}}
									/>
								</>
							</ReferenceField>
						) : (
							<Tooltip title={formattedEmployeeId || ''} placement='right'>
								<Typography
									className={`${classes.secondaryInfoEmpIdContent} ${styles.ellipsis}`}
								>{`${formattedEmployeeId || ''}`}</Typography>
							</Tooltip>
						)}
					</div>

					<div className={`${classes.subTitleContainer}`}>
						<ReferenceField
							link={false}
							label='Job level'
							source='job_level_id'
							reference='job_level'
							record={data}
							basePath={basePath}
							resource={resource}
						>
							<FunctionField
								className={`${classes.subTitleJobLevelContent}`}
								record={data}
								render={(record) => {
									return (
										<Tooltip
											title={`${record?.level || ' - - '}`}
											placement='right'
										>
											<Box marginTop={'3px'}>
												<Box
													className={`${classes.subTitleJobLevelContent} ${styles.ellipsis}`}
												>
													{record?.level || '- -'}
												</Box>
											</Box>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					</div>
				</div>

				<Box display={'flex'} className={classes.additionalInfo}>
					<div className={classes.additionalInfoIcon}>
						<SmartphoneIcon className={classes.contactIcon} />
					</div>

					<div className={`${classes.additionalInfoContent}`}>
						<ReferenceField
							link={false}
							label='Phone Number'
							source='user_id'
							reference='user'
							record={data}
							basePath={basePath}
							resource={resource}
						>
							<FunctionField
								className={`${classes.subTitleContent}`}
								record={data}
								render={(record) => {
									return (
										<Tooltip
											title={`${record?.phone_number || ' - - '}`}
											placement='right'
										>
											<Typography
												className={`${classes.contactDisplay} ${styles.ellipsis}`}
											>
												{`${record?.phone_number || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					</div>
				</Box>
			</MuiLink>
		</Paper>
	);
};

export default EmployeeCard;
