import React, { useContext, useState, useEffect, createContext } from 'react';
import { Box } from '@material-ui/core';
import head from 'lodash/head';
import { UserProfileContext } from '../../App';
import { detailsPageGlobalStyle } from '../../Layout/styles';

import {
	useGetEmployeeByUserIdQuery,
	useGetContractorByUserIdQuery,
	useGetOrganizationDetailsQuery,
} from '../../generated/graphql';
import MyInfoViewLeftSidePanel from './MyInfoViewLeftSidePanel.component';
import MyInfoViewRightSidePanel from './MyInfoViewRightSidePanel.component';
import { getPrefix } from '../../Utils/string.util';

export const FieldPermissionsContext = createContext({});

const MyInfoView = () => {
	const classes = detailsPageGlobalStyle();
	const { id: userId, orgId } = useContext<any>(UserProfileContext);
	const [myInfoDetails, setMyInfoDetails] = useState<any>();
	const { data: employee, refetch: refetchEmployee } =
		useGetEmployeeByUserIdQuery({
			variables: {
				user_id: userId,
			},
		});

	const { data: consultant, refetch: refetchContractor } =
		useGetContractorByUserIdQuery({
			variables: {
				user_id: userId,
			},
		});

	const { data: organisationFeildPermissions } = useGetOrganizationDetailsQuery(
		{
			variables: {
				orgId: orgId,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!employee || !(employee?.employee?.length > 0)) {
			return;
		}
		const user = head(employee?.employee);
		setMyInfoDetails({
			first_name: user?.user?.first_name,
			last_name: user?.user?.last_name,
			middle_name: user?.user?.middle_name ? user?.user?.middle_name : '',
			full_name: user?.user?.full_name,
			profile_pic: user?.user?.profile_pic,
			id: user?.user?.id,
			email: user?.user?.email,
			phone_number: user?.user?.phone_number,
			is_active: user?.user?.is_active,
			user_type: user?.user?.user_type,
			is_billable: user?.is_billable,
			emp_id: user?.emp_id,
			job_level_id: user?.job_level_id,
			department_id: user?.department_id,
			employee_status: user?.employee_status,
			designation: user?.designation,
			prior_experience: user?.prior_experience,
			personal_email: user?.personal_email,
			wedding_date: user?.wedding_date,
			emergency_contact_number: user?.emergency_contact_number,
			emergency_contact_name: user?.emergency_contact_name,
			address: user?.address,
			highest_academic_degree: user?.highest_academic_degree,
			join_date: user?.join_date,
			prefix: user?.organization?.emp_id_prefix,
			employee_joining_source_id: user?.employee_joining_source_id,
			blood_group: user?.blood_group,
			marital_status: user?.marital_status,
			org_shift_id: user?.org_shift_id,
			last_date: user?.last_date,
			institution_name: user?.institution_name,
			org_leave_group: user?.org_leave_group,
			reportee_id: user?.reportee_id,
			bank_name: user?.bank_name,
			branch_name: user?.branch_name,
			account_number: user?.account_number,
			ifsc_code: user?.ifsc_code,
			employee_skill_mappings: user?.user?.employee_skill_mappings,
			working_mode: user?.working_mode,
			document_id: user?.id,
			joining_source: user?.emp_joining_source?.label,
			employee_id: user?.id,
			probation_start_date: user?.probation_start_date,
			probation_end_date: user?.probation_end_date,
			gender: user?.gender,
			dob: user?.dob,
			custom_field_1: user?.custom_field_1,
			custom_field_2: user?.custom_field_2,
			custom_field_3: user?.custom_field_3,
			custom_field_4: user?.custom_field_4,
			custom_field_5: user?.custom_field_5,
			custom_field_6: user?.custom_field_6,
			custom_field_7: user?.custom_field_7,
			custom_field_8: user?.custom_field_8,
			custom_field_9: user?.custom_field_9,
			prefix_id: getPrefix(
				user?.employee_prefix?.name,
				user?.employee_prefix?.value
			),
			user: {
				id: user?.user?.id,
				full_name: user?.user?.full_name,
			},
		});
	}, [employee]);

	useEffect(() => {
		if (!consultant || !(consultant?.contractor?.length > 0)) {
			return;
		}
		const user = head(consultant?.contractor);
		setMyInfoDetails({
			first_name: user?.user?.first_name,
			last_name: user?.user?.last_name,
			middle_name: user?.user?.middle_name ? user?.user?.middle_name : '',
			full_name: user?.user?.full_name,
			profile_pic: user?.user?.profile_pic,
			id: user?.user?.id,
			email: user?.user?.email,
			phone_number: user?.user?.phone_number,
			is_active: user?.user?.is_active,
			user_type: user?.user?.user_type,
			is_billable: user?.is_billable,
			emp_id: user?.contractor_id,
			job_level_id: user?.job_level_id,
			department_id: user?.department_id,
			designation: user?.designation,
			employee_status: user?.contractorStatus,
			prior_experience: user?.total_experience,
			reportee_id: user?.reportee_id,
			working_mode: user?.working_mode,
			vendor_type: user?.vendor_type,
			join_date: user?.join_date,
			prefix: user?.organization?.contractor_id_prefix,
			personal_email: user?.personal_email,
			marital_status: user?.marital_status,
			wedding_date: user?.wedding_date,
			emergency_contact_number: user?.emergency_contact_number,
			emergency_contact_name: user?.emergency_contact_name,
			contractor_joining_source: user?.contractor_joining_source,
			bank_name: user?.bank_name,
			branch_name: user?.branch_name,
			account_number: user?.account_number,
			ifsc_code: user?.ifsc_code,
			address: user?.address,
			end_date: user?.end_date,
			employee_skill_mappings: user?.user?.employee_skill_mappings,
			document_id: user?.id,
			joining_source: user?.contractor_joining_source?.label,
			org_shift_id: user?.org_shift_id,
			custom_field_1: user?.custom_field_1,
			custom_field_2: user?.custom_field_2,
			custom_field_3: user?.custom_field_3,
			custom_field_4: user?.custom_field_4,
			custom_field_5: user?.custom_field_5,
			custom_field_6: user?.custom_field_6,
			custom_field_7: user?.custom_field_7,
			custom_field_8: user?.custom_field_8,
			custom_field_9: user?.custom_field_9,
			gender: user?.gender,
			prefix_id: getPrefix(
				user?.contractor_prefix?.name,
				user?.contractor_prefix?.value
			),
			user: {
				id: user?.user?.id,
				full_name: user?.user?.full_name,
			},
			employee_id: user?.id,
		});
	}, [consultant]);

	const getFieldPermissions = () => {
		if (!organisationFeildPermissions) {
			return {};
		}
		return {
			isEmpSelfServiceEnabled:
				organisationFeildPermissions.organization_by_pk?.enable_emp_ss,
			editableColumns:
				organisationFeildPermissions.organization_by_pk?.editable_columns || [],
		};
	};

	return (
		<FieldPermissionsContext.Provider value={getFieldPermissions()}>
			<Box display='flex' marginTop='20px'>
				<Box marginRight='2%'>
					<MyInfoViewLeftSidePanel myInfoViewDetails={myInfoDetails} />
				</Box>
				<Box className={classes.rightPanel}>
					<MyInfoViewRightSidePanel
						myInfoViewDetails={myInfoDetails}
						refetchEmployee={() => {
							refetchEmployee();
						}}
						refetchContractor={() => {
							refetchContractor();
						}}
					/>
				</Box>
			</Box>
		</FieldPermissionsContext.Provider>
	);
};

export default MyInfoView;
