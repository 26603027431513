import React, { useContext, useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	useNotify,
	useRefresh,
	minLength,
	number,
	useQuery,
} from 'react-admin';
import { Button, Box } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle, currencyInputStyle } from '../../../Layout/styles';
import { UPDATE_OPPORTUNITY } from '../gqlQueries';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import AddContact from './AddContactForm.component';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import { UserProfileContext } from '../../../App';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../../SharedComponents/gqlQueries';
import { OrganizationSupportedCurrencies } from '../../../SharedComponents/model';
import { isEmpty, orderBy } from 'lodash';
import { useGetAssigneeFilterRecommendationsQuery } from '../../../generated/graphql';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';

interface OpportunityForm {
	denomination?: string;
	status?: string;
	name?: string;
	win_percentage?: number;
	value?: number;
	priority?: string;
	close_date?: string;
	company_id?: string;
	primary_contact_id?: string;
	owner_id?: string;
	id?: string;
	kanban_id?: string;
}
interface Record {
	id?: string;
	currency_id?: string;
	status?: string;
	name?: string;
	win_percentage?: number;
	value?: number;
	priority?: string;
	close_date?: string;
	company_id?: string;
	primary_contact_id?: string;
	owner_id?: string;
	lead_owner_id?: string;
	loss_reason_id?: string;
}
interface Props {
	onClose: (value: boolean) => void;
	open: boolean;
	opportunity: OpportunityForm;
	refetch: () => void;
}
interface Choice {
	first_name: string;
	last_name: string;
	full_name: string;
}
interface Value {
	name: string;
	id: string;
}
interface Contact {
	first_name: string;
	last_name: string;
	full_name: string;
	id: string;
}
interface OpportunityStatus {
	board_id: string;
	created_at: Date;
	created_by?: any;
	description?: any;
	id: string;
	limit: number;
	name: string;
	ordinal: number;
	state?: any;
	updated_at: Date;
	updated_by?: any;
}

const CompanyEditOpportunityForm = (props: any) => {
	const { onClose, open, opportunity, refetch } = props;
	const { orgId: organizationId, dateFormat } =
		useContext<any>(UserProfileContext);
	const notify = useNotify();
	const refresh = useRefresh();
	const [isAddContactFormShown, setIsAddContactFormShown] = useState(false);
	const [kanbanColumn, setKanbanColumn] = useState([]);
	const { id }: { id: string } = useParams();
	const [opportunityForm, setOpportunityForm] = useState<Record>({});
	const classes = modalFormStyle();
	const [crmContact, setCrmContact] = useState([]);
	const [contactId, setContactId] = useState('');
	const customStyle = currencyInputStyle();
	const [contactDetails, setContactDetails] = useState({});
	const [currencyList, setCurrencyList] = useState([]);
	const [disableLoss, setDisableLoss] = useState(false);
	const [assigneId, setAssigneId] = useState<any>([]);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);

	const { data: assignedUser } = useGetAssigneeFilterRecommendationsQuery();
	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	const { data: organisationSupportedCurrencies } = useApolloQuery(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);

	const [updateOpportunity, { loading: isOpportunityLoading }] =
		useApolloMutation(UPDATE_OPPORTUNITY);
	const { data: crmContacts } = useQuery({
		type: 'GET_LIST',
		resource: 'crm_contacts',
		payload: {
			filter: {
				company_id: id || null,
			},
		},
	});

	const { data: kanbanColumns, refetch: refetchKanbanColumn } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'kanban_column',
			payload: {
				filter: {
					board_id: opportunity?.board_id,
				},
			},
		},
		{
			enabled: !!opportunity?.board_id,
		}
	);

	useEffect(() => {
		if (!assignedUser?.user) {
			return;
		}
		const invitedUser = assignedUser.user?.map((user) => {
			return {
				id: user?.id,
				name: `${user?.full_name} `,
			};
		});
		const sortedInvitedUser = orderBy(
			invitedUser,
			[(user) => user.name.toUpperCase()],
			['asc']
		);
		setAssigneId(sortedInvitedUser);
	}, [assignedUser]);

	useEffect(() => {
		if (
			!organisationSupportedCurrencies ||
			!(organisationSupportedCurrencies?.org_supported_currencies.length > 0)
		) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					if (currency?.currency?.id === currency?.organization?.currency_id) {
						setOpportunityForm((previousOpportunityForm) => ({
							...previousOpportunityForm,
							currency_id: currency?.currency?.id,
						}));
					}
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);
		setCurrencyList(organizationCurrencyChoices);
	}, [organisationSupportedCurrencies]);

	useEffect(() => {
		setOpportunityForm({
			...opportunity,
		});
		setPresalesOwnerId(opportunity?.owner_id);
		setOwnerId(opportunity?.lead_owner_id);
	}, [opportunity]);

	useEffect(() => {
		if (contactId) {
			opportunity.primary_contact_id = contactId;
			setOpportunityForm({
				...opportunityForm,
				primary_contact_id: contactId,
			});
			setContactId('');
		}
	}, [opportunity, opportunityForm, contactId]);

	useEffect(() => {
		if (isEmpty(kanbanColumns)) {
			return;
		}
		const lossState = kanbanColumns?.filter(
			(opportunity: OpportunityStatus) => opportunity?.state === 'loss'
		);
		if (opportunityForm?.status === lossState[0]?.id) {
			setDisableLoss(false);
		} else {
			setDisableLoss(true);
		}
	}, [opportunityForm, kanbanColumns]);

	useEffect(() => {
		if (isEmpty(kanbanColumns)) {
			setKanbanColumn([]);
			return;
		}
		const kanbanData = kanbanColumns?.map((value: Value) => {
			return { id: value?.id, name: value?.name };
		});
		const sortedKanban: any = orderBy(
			kanbanData,
			[(data) => data.name.toUpperCase()],
			['asc']
		);
		setKanbanColumn(sortedKanban);
	}, [kanbanColumns, opportunityForm]);

	const onDialogueClose = () => {
		setOpportunityForm({
			...opportunity,
		});
		onClose(false);
	};

	useEffect(() => {
		if (crmContacts?.length > 0) {
			const companyContacts = crmContacts.map((contact: Contact) => {
				return {
					id: contact?.id,
					name: `${contact?.full_name} `,
				};
			});
			setCrmContact(companyContacts);
		} else {
			setCrmContact([]);
		}
	}, [opportunityForm, crmContacts, id]);

	const onSave = () => {
		updateOpportunity({
			variables: {
				id: opportunity?.id,
				kanbanId: opportunity?.kanban_id,
				data: {
					name: opportunityForm?.name,
					currency_id: opportunityForm?.currency_id,
					win_percentage: opportunityForm?.win_percentage || 0,
					priority: opportunityForm?.priority,
					close_date: opportunityForm?.close_date,
					value: opportunityForm?.value || 0,
					company_id: id,
					primary_contact_id: opportunityForm?.primary_contact_id,
					owner_id: presalesOwnerId || null,
					lead_owner_id: ownerId || null,
					loss_reason_id: opportunityForm?.loss_reason_id,
				},
				kanData: {
					column_id: opportunityForm?.status,
				},
			},
		}).then((data) => {
			refetch();
			refetchKanbanColumn();
			onDialogueClose();
			notify('Opportunity details updated successfully');
			refresh();
		});
	};

	const onContactDialogueClose = () => {
		setIsAddContactFormShown(false);
		setOpportunityForm((prev) => ({
			...prev,
			primary_contact_id: opportunity?.primary_contact_id,
		}));
	};
	const onCreateContact = (contact: string) => {
		setContactDetails({
			first_name: contact,
			company_id: id,
		});
		setIsAddContactFormShown(true);
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getPresaleValue = (userId: string) => {
		const response = assigneId?.find((value: any) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box width='464px' height='700px' borderRadius='4px'>
					<Box className={customStyle.headerContainer}>
						<Box className={customStyle.heading}>
							Edit Opportunities{' '}
							{isAddContactFormShown ? (
								<>
									<DoubleArrowIcon className={customStyle.arrowIcon} />
									<Box className={customStyle.subHeading}>Add Contact</Box>
								</>
							) : null}
						</Box>
						{isAddContactFormShown ? (
							<CloseIcon
								className={classes.closeIcon}
								onClick={onContactDialogueClose}
							/>
						) : (
							<CloseIcon
								className={classes.closeIcon}
								onClick={onDialogueClose}
							/>
						)}
					</Box>
					<DialogContent className={classes.dialogueContent}>
						<Box className={customStyle.formContainer}>
							{!isAddContactFormShown ? (
								<Form initialValues={opportunityForm} onSubmit={onSave}>
									{({ handleSubmit, invalid }) => (
										<form onSubmit={handleSubmit}>
											<Box className={classes.label}>Opportunity Name *</Box>
											<TextInput
												source='name'
												fullWidth={true}
												label={''}
												validate={[
													required(),
													minLength(3, 'minimum three characters required'),
												]}
												onChange={(e: any) => {
													setOpportunityForm({
														...opportunityForm,
														name: e?.target?.value,
													});
												}}
											/>
											<Box className={classes.label}>Pipeline *</Box>
											<TextInput
												source='board_name'
												fullWidth={true}
												label={''}
												disabled={true}
											/>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Stage</Box>
													<SelectInput
														source='status'
														label={''}
														choices={kanbanColumn || []}
														validate={required()}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																status: e?.target?.value,
															});
														}}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Loss Reason</Box>
													<ReferenceInput
														source='loss_reason_id'
														reference='opportunity_loss_reason'
														label={''}
														onChange={(event) => {
															setOpportunityForm({
																...opportunityForm,
																loss_reason_id: event?.target?.value || null,
															});
														}}
													>
														<SelectInput
															optionText='label'
															disabled={disableLoss}
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Win %</Box>
													<TextInput
														source='win_percentage'
														label={''}
														name='win_percentage'
														validate={number()}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																win_percentage: e?.target?.value,
															});
														}}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Priority</Box>
													<SelectInput
														source='priority'
														label=''
														fullWidth={true}
														choices={[
															{ id: 'Low', name: 'Low' },
															{ id: 'Medium', name: 'Medium' },
															{ id: 'High', name: 'High' },
														]}
														name='priority'
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																priority: e?.target?.value,
															});
														}}
													/>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Value</Box>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Close Date</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={customStyle.boxContainer}>
													<Box className={customStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<SelectInput
																source='currency_id'
																label=''
																choices={currencyList || []}
																onChange={(e: any) => {
																	setOpportunityForm({
																		...opportunityForm,
																		currency_id: e,
																	});
																}}
															/>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='value'
															label={''}
															validate={number()}
															onChange={(e: any) => {
																setOpportunityForm({
																	...opportunityForm,
																	value: e?.target?.value,
																});
															}}
														/>
													</ThemeProvider>
												</Box>
												<Box>
													<Field
														name='close_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div className={customStyle.dateInput}>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value: any) => {
																		props.input.onChange(value);
																		setOpportunityForm({
																			...opportunityForm,
																			close_date: value,
																		});
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Lead Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={setOwnerId}
														name={'lead_owner_id'}
														validates={false}
														value={getOwnerValue(ownerId || '')}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Pre-Sales Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={assigneId || []}
														onChange={setPresalesOwnerId}
														name={'owner_id'}
														validates={false}
														value={getPresaleValue(presalesOwnerId || '')}
													/>
												</Box>
											</Box>
											<Box className={classes.label}>Contact</Box>
											<AutocompleteInput
												optionText={'name'}
												choices={crmContact}
												source='primary_contact_id'
												label={''}
												resettable={true}
												shouldRenderSuggestions={(name: any) =>
													name?.trim().length > 0
												}
												onChange={(event: any) => {
													setOpportunityForm({
														...opportunityForm,
														primary_contact_id: event,
													});
												}}
												onCreate={(event: any) => onCreateContact(event)}
											/>
											<Box className={classes.customButtonContainer}>
												<Button
													className={
														invalid || isOpportunityLoading
															? classes.disabledStartButton
															: classes.updateButton
													}
													disabled={invalid || isOpportunityLoading}
													type='submit'
												>
													Update
												</Button>
												<Button
													onClick={onDialogueClose}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
											</Box>
										</form>
									)}
								</Form>
							) : (
								<>
									<AddContact
										onClose={onContactDialogueClose}
										contact={contactDetails}
										setContactId={(id: string) => {
											setContactId(id);
										}}
									/>
								</>
							)}
						</Box>
					</DialogContent>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CompanyEditOpportunityForm;
