import React, { useEffect, useState } from 'react';
import { TextInput, useNotify, useRefresh } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';

import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetOrgMasterCurrenciesQuery,
	useGetUserCtcQuery,
	useUpsertCtcDataMutation,
} from '../../../generated/graphql';

import { CTC_UPDATE, validateNum } from '../constant';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

import { head } from 'lodash';
import { formatDecimal, isFloat } from '../../../Utils/string.util';

interface User {
	id: string;
	full_name: string;
}
interface Symbol {
	id: string;
	symbol: string;
	currency_type: string;
}
interface EmployeeCTC {
	id: string;
	master_currency: Symbol;
	updated_at: string;
	updatedByUser: User;
	user: User;
}
interface UserCTC {
	id?: string;
	fixed?: number;
	variable?: number;
	notes?: string | null;
	currency_id?: string;
	master_currency?: any;
	updated_at?: string;
	updated_by?: User;
	net?: string;
	gross_1?: number;
	gross_2?: number;
	relocation_amount?: number;
	joining_bonus?: number;
}
interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	record: EmployeeCTC;
	refetch: () => void;
}

const CtcForm = (props: Props) => {
	const {
		open,
		onClose,
		record: employee,
		onSuccess: refetchAuditCtc,
		refetch: refetchEmployee,
	} = props;

	const validateNumber = /^(0|[1-9]\d*)(\.\d+)?$/;

	const [currency, setCurrency] = useState<
		{
			id: any;
			name: string | null | undefined;
		}[]
	>([]);
	const [currencyId, setCurrencyId] = useState<
		(string | null | undefined) | undefined
	>();
	const [initialFormValues, SetInitialFormValues] = useState<UserCTC>({});
	const { data: masterCurrencies } = useGetOrgMasterCurrenciesQuery({
		fetchPolicy: 'network-only',
	});

	const [upsertCtc, { loading: isMutationLoading }] =
		useUpsertCtcDataMutation();

	const { data: userCtc, refetch: refetchCurrentCtc } = useGetUserCtcQuery({
		variables: {
			userId: employee?.user?.id,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!masterCurrencies) {
			return;
		}

		const response = masterCurrencies?.master_currencies?.map((value) => {
			return {
				id: value?.id,
				name: value?.currency_type,
			};
		});
		setCurrency(response);
	}, [masterCurrencies]);

	useEffect(() => {
		if (!userCtc) {
			return;
		}
		setCurrencyId(head(userCtc?.ctc)?.master_currency?.id);
	}, [userCtc]);

	const formStyles = modalFormStyle();

	const refresh = useRefresh();
	const notify = useNotify();

	const validateCtc = (value: string) => {
		const str = value?.toString();
		if (str?.trim() === '') {
			return 'Required';
		}
		if (!str) {
			return 'Required';
		}

		if (!validateNumber.test(value)) {
			return 'Only numbers allowed';
		}
		return undefined;
	};

	const validateNotes = (value: string) => {
		if (!value) {
			return 'Required';
		}

		if (value?.trim() === '') {
			return 'Required';
		}
		if (value?.length > 150) {
			return 'Maximun 150 characters allowed';
		}
	};

	useEffect(() => {
		if (!userCtc) {
			return;
		}

		SetInitialFormValues({
			id: head(userCtc?.ctc)?.id,
			fixed: isFloat(head(userCtc?.ctc)?.fixed)
				? formatDecimal(head(userCtc?.ctc)?.fixed, 2)
				: head(userCtc?.ctc)?.fixed,
			variable: isFloat(head(userCtc?.ctc)?.variable)
				? formatDecimal(head(userCtc?.ctc)?.variable, 2)
				: head(userCtc?.ctc)?.variable,
			notes: head(userCtc?.ctc)?.notes,
			currency_id: head(userCtc?.ctc)?.master_currency?.id,
			net: isFloat(head(userCtc?.ctc)?.net)
				? formatDecimal(head(userCtc?.ctc)?.net, 2)
				: head(userCtc?.ctc)?.net,
			gross_1: isFloat(head(userCtc?.ctc)?.gross_1)
				? formatDecimal(head(userCtc?.ctc)?.gross_1, 2)
				: head(userCtc?.ctc)?.gross_1,
			gross_2: isFloat(head(userCtc?.ctc)?.gross_2)
				? formatDecimal(head(userCtc?.ctc)?.gross_2, 2)
				: head(userCtc?.ctc)?.gross_2,
			relocation_amount: isFloat(head(userCtc?.ctc)?.relocation_amount)
				? formatDecimal(head(userCtc?.ctc)?.relocation_amount, 2)
				: head(userCtc?.ctc)?.relocation_amount,
			joining_bonus: isFloat(head(userCtc?.ctc)?.joining_bonus)
				? formatDecimal(head(userCtc?.ctc)?.joining_bonus, 2)
				: head(userCtc?.ctc)?.joining_bonus,
		});
	}, [userCtc]);

	const saveCtcDetails = (formValues: UserCTC) => {
		upsertCtc({
			variables: {
				ctcData: {
					...formValues,
					net: formValues?.net || 0,
					gross_1: formValues?.gross_1 || 0,
					gross_2: formValues?.gross_2 || 0,
					joining_bonus: formValues?.joining_bonus || 0,
					relocation_amount: formValues?.relocation_amount || 0,
					notes: formValues?.notes?.trim(),
					master_currency: formValues?.master_currency?.currency_type,
					user_id: employee?.user?.id,
					id: initialFormValues?.id,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					refetchEmployee();
					refetchAuditCtc();

					notify(CTC_UPDATE);
					refresh();
					onClose();
					refetchCurrentCtc();
				}
			})
			.catch((error: any) => {
				if (error) {
					notify('Ctc updation failed', 'warning');
					return;
				}
			});
	};

	const modalClose = () => {
		setCurrencyId(head(userCtc?.ctc)?.master_currency?.id);
		onClose();
	};
	const getCurrency = (id: string) => {
		if (!masterCurrencies) {
			return null;
		}
		const response = masterCurrencies?.master_currencies.find(
			(value) => value?.id === id
		);
		return response
			? { id: response?.id, name: response?.currency_type }
			: null;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={formStyles.container}>
					<Box className={formStyles.headerContainer}>
						<Typography
							className={formStyles.heading}
						>{`Edit Compensation`}</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={modalClose} />
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={saveCtcDetails}
						mutators={{
							setTouched: (args, state, utils) => {
								if (
									state &&
									state?.formState &&
									state?.formState?.submitSucceeded
								) {
									state.formState.submitSucceeded = false;
									return;
								}
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, submitSucceeded }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Fixed*
												</Typography>
												<TextInput
													source='fixed'
													label=''
													validate={validateCtc}
													fullWidth
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Variable*
												</Typography>
												<TextInput
													source='variable'
													label=''
													validate={validateCtc}
													fullWidth
												/>
											</Box>
										</Box>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Currency*
												</Typography>

												<AutocompleteSearch
													placeholder={'Search Curreny'}
													option={currency || []}
													onChange={(value: any) => {
														if (!value) {
															setCurrencyId('');
														}
														setCurrencyId(value);
													}}
													value={currencyId ? getCurrency(currencyId) : null}
													name={'currency_id'}
													validates={true}
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Joining Bonus
												</Typography>
												<TextInput
													source='joining_bonus'
													label=''
													validate={validateNum}
													fullWidth
												/>
											</Box>
										</Box>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Relocation Amount
												</Typography>
												<TextInput
													source='relocation_amount'
													label=''
													validate={validateNum}
													fullWidth
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Net
												</Typography>
												<TextInput
													source='net'
													label=''
													validate={validateNum}
													fullWidth
												/>
											</Box>
										</Box>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Gross 1
												</Typography>
												<TextInput
													source='gross_1'
													label=''
													validate={validateNum}
													fullWidth
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Gross 2
												</Typography>
												<TextInput
													source='gross_2'
													label=''
													validate={validateNum}
													fullWidth
												/>
											</Box>
										</Box>
										<Typography className={formStyles.label}>Notes*</Typography>
										<TextInput
											source='notes'
											label=''
											multiline
											validate={validateNotes}
											fullWidth
										/>
									</DialogContent>
									<DialogActions>
										<Box className={formStyles.floatingButtonContainer}>
											<Button
												onClick={modalClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>

											<ButtonWithLoader
												title='Update'
												isDisabled={
													invalid ||
													pristine ||
													submitSucceeded ||
													isMutationLoading
												}
												isLoading={isMutationLoading}
											/>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CtcForm;
