import React, { useState } from 'react';
import { AutocompleteArrayInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
	CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	useGetDepartmentOptionsQuery,
	useMapEmployeesWithLeavePoliciesMutation,
	useUpdateEmployeeLeaveTypeMappingMutation,
	useUpsertLeaveGroupDeptMappingsMutation,
} from '../../../generated/graphql';
import { difference } from 'lodash';
import dayjs from 'dayjs';

const employeeMappingFormStyle = makeStyles({
	container: {
		width: '49vw',
		padding: '20px',
		// minHeight: '500px',
		borderRadius: '4px',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '54px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
	warningMessage: {
		fontFamily: 'Manrope-medium',
		color: '#EA4335',
		marginBottom: '8px',
	},
});

interface LeaveDeptMapping {
	leaveDeptMappingId: any;
	department_id: any;
}
interface LeaveDeptMappings {
	leaveDeptMapping?: LeaveDeptMapping[];
	department_id?: any[];
	leaveTypes: {
		__typename?: 'org_leave_types';
		id: any;
		leave_type: string;
		leave_increments?: any | null | undefined;
		interval?: any | null | undefined;
		default_amount: any;
	}[];
}
interface EmployeesMappingProps {
	open: boolean;
	onClose: () => void;
	leavePolicyId: string;
	leaveDeptMappings?: LeaveDeptMappings;
	refetch: () => void;
}

export const EmployeesMappingForm = (props: EmployeesMappingProps) => {
	const { open, onClose, leavePolicyId, leaveDeptMappings, refetch } = props;
	const modalFormStyles = modalFormStyle();
	const [isLoading, setIsLoading] = useState(false);
	const notify = useNotify();
	const refresh = useRefresh();
	const employeeMappingFormStyles = employeeMappingFormStyle();
	const { data: departmentOptions } = useGetDepartmentOptionsQuery({
		fetchPolicy: 'network-only',
	});
	const [updateEmployeesLeavePolicies] =
		useMapEmployeesWithLeavePoliciesMutation();
	const [upsertLeaveGroupDeptMappings] =
		useUpsertLeaveGroupDeptMappingsMutation();
	const [updateEmployeeLeaveTypeMapping] =
		useUpdateEmployeeLeaveTypeMappingMutation();

	const handleClose = () => {
		setIsLoading(false);
		onClose();
	};

	const handleSubmit = (formValues: { departments: string[] }) => {
		if (!formValues.departments) {
			return;
		}
		setIsLoading(true);
		const removedDepartments = difference(
			leaveDeptMappings?.department_id,
			formValues.departments
		);

		const deletedDepartments = [
			...formValues.departments,
			...removedDepartments,
		];

		upsertLeaveGroupDeptMappings({
			variables: {
				departmentIdsToDelete: deletedDepartments,
				data: formValues.departments.map((departmentId) => ({
					id: leaveDeptMappings?.leaveDeptMapping?.find(
						(leaveDepartmentMapping: LeaveDeptMapping) =>
							leaveDepartmentMapping.department_id === departmentId
					)?.leaveDeptMappingId,
					org_leave_group_id: leavePolicyId,
					department_id: departmentId,
				})),
			},
		})
			.then((leaveGroupDeptResponse) => {
				if (!leaveGroupDeptResponse.errors) {
					updateEmployeesLeavePolicies({
						variables: {
							removedDepartmentIds: removedDepartments || [],
							removeEmployeeLeaveGroup: {
								leave_group_id: null,
							},
							departmentIds: formValues.departments,
							data: {
								leave_group_id: leavePolicyId,
							},
						},
					})
						.then((updateEmployeesLeavePoliciesResponse) => {
							if (!updateEmployeesLeavePoliciesResponse.errors) {
								updateEmployeeLeaveTypeMapping({
									variables: {
										employeeIds:
											updateEmployeesLeavePoliciesResponse.data?.update_employee?.returning.map(
												({ id }) => id
											),
										employeeLeaveTypeMapping:
											leaveDeptMappings?.leaveTypes &&
											leaveDeptMappings?.leaveTypes?.length > 0
												? updateEmployeesLeavePoliciesResponse.data?.update_employee?.returning
														.map((employee) =>
															leaveDeptMappings?.leaveTypes?.map(
																(leaveType) => ({
																	leave_type_id: leaveType.id,
																	employee_id: employee.id,
																	renewal_date:
																		leaveType?.leave_increments > 0
																			? dayjs()
																					.add(leaveType.interval, 'days')
																					.format('YYYY-MM-DD')
																			: null,
																	opening_balance:
																		leaveType?.default_amount || 0,
																})
															)
														)
														.flat()
												: [],
									},
								})
									.then(() => {
										notify('Employees has been added successfully');
										refetch();
										refresh();
										setIsLoading(false);
										handleClose();
										return;
									})
									.catch((error) => {
										if (error) {
											console.log(error);
											return;
										}
									});
							}
						})
						.catch((error) => {
							if (error) {
								console.log(error);
								return;
							}
						});
				}
			})
			.catch((error) => {
				if (error) {
					console.log(error);
					return;
				}
			});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={employeeMappingFormStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							Employee Mapping
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={handleClose}
						/>
					</Box>
					<Form
						initialValues={
							leaveDeptMappings && leaveDeptMappings?.department_id
								? {
										departments: leaveDeptMappings.department_id,
								  }
								: {}
						}
						onSubmit={handleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography
										className={employeeMappingFormStyles.warningMessage}
									>
										Warning : Employees belonging to this department(s) will be
										moved from any other associated leave policy and will be re
										assigned this leave policy.
									</Typography>
									<Typography className={modalFormStyles.label}>
										Select Departments
									</Typography>
									<AutocompleteArrayInput
										source='departments'
										label=''
										choices={departmentOptions?.department.map(
											(department) => ({
												id: department.id,
												name: `${department.name}(${department?.employees_aggregate?.aggregate?.count})`,
											})
										)}
									/>
								</Box>
								<Box className={modalFormStyles.buttonContainer}>
									<Button
										className={
											invalid || pristine || isLoading
												? `${modalFormStyles.disabledButton} ${employeeMappingFormStyles.saveButton}`
												: `${modalFormStyles.saveButton} ${employeeMappingFormStyles.saveButton}`
										}
										disabled={invalid || pristine || isLoading}
										type='submit'
										startIcon={
											isLoading && (
												<CircularProgress
													style={{
														height: '20px',
														width: '20px',
														color: '#4285F4',
													}}
												/>
											)
										}
									>
										Add
									</Button>
									<Button
										onClick={handleClose}
										className={modalFormStyles.cancelButton}
									>
										Cancel
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeesMappingForm;
