import React, { useState, useEffect } from 'react';
import { TextInput, maxLength, required, useNotify } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPSERT_DEPARTMENT } from '../../Settings.gql';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
import { validateDescription } from '../../constant';
interface FormValue {
	id?: string;
	name?: string;
	description?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}
const DepartmentForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const [upsertDepartment, { loading: isDepartmentLoading }] =
		useApolloMutation(UPSERT_DEPARTMENT);
	const [isNameUniqueViolationError, setIsNameUniqueViolationError] =
		useState(false);
	const [initialFormValues, setInitialFormValues] = useState<FormValue>({});

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		upsertDepartment({
			variables: {
				department: { ...formValues },
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify('Department updated Successfully');
						refetch();
						closeModal();
					} else {
						notify('Department Created Successfully');
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error?.message.includes('UQ_845e6a89c6752be4350157b1e55')) {
					setIsNameUniqueViolationError(true);
				}
			});
	};
	const validateDepartmentFormValues = async () => {
		const departmentFormErrors = {} as FormValue;
		if (isNameUniqueViolationError) {
			departmentFormErrors.name = 'name already exist!';
		}
		return departmentFormErrors;
	};
	const departmentDescriptionValidate = [validateDescription,maxLength(50, 'Characters must be below 50')];
	const closeModal = () => {
		setInitialFormValues({});
		onClose();
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Tooltip
							title={initialValues?.id ? `${initialValues?.name}` : ''}
							placement={'top'}
						>
							<Typography
								className={`${modalFormStyles.heading} ${ellipsisStyles.ellipsis}`}
							>
								{isEditMutation
									? `Edit: ${initialValues?.name}`
									: `Add Department`}
							</Typography>
						</Tooltip>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateDepartmentFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={required()}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										validate={departmentDescriptionValidate}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={isEditMutation ? 'Update' : 'Add'}
										isLoading={isDepartmentLoading}
										isDisabled={invalid || pristine || isDepartmentLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default DepartmentForm;
