import React from 'react';
import { makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { checkIfOnlyOneColon } from '../modules/Timesheet/constant';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export const TimeInputStyle = makeStyles({
	workinHoursInputContainerWithIcon: {
		width: '114px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		display: 'flex',
		padding: '0px 0px 0px 8px',
	},
	workinHoursInputContainerWithIconAndError: {
		width: '114px',
		height: '36px',
		background: '#FFFFFF',
		border: '2px solid #EA4335',
		borderRadius: '4px',
		display: 'flex',
		padding: '0px 0px 0px 8px',
	},
	workinHoursInputContainerWithoutIcon: {
		width: '84px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	workinHoursInputContainerWithoutIconAndError: {
		width: '84px',
		height: '36px',
		background: '#FFFFFF',
		border: '2px solid #EA4335',
		borderRadius: '4px',
	},
	workinHoursInputSmContainerWithoutIcon: {
		width: '58px',
		height: '36px',
		background: '#FFFFFF',
		// border: '1px solid #E0E0E0',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputWithIcon: {
		border: 'none',
		background: 'none',
		padding: '3px 0px !important',
		width: '74px',
		fontSize: '20px',
		fontFamily: 'Manrope-medium',
		color: '#343434',
		margin: '0px 0px 0px 6px',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	inputWithOutIcon: {
		border: 'none',
		background: 'none',
		margin: '3px',
		padding: '3px 0px !important',
		width: '42px',
		fontSize: '13px',
		fontFamily: 'Manrope-medium',
		color: '#343434',
		'&:focus-visible': {
			outline: 'none',
		},
	},
});

interface TimeInputProps {
	value: string;
	onChange: (time: string) => void;
	iconVisiblity?: Boolean;
	name?: string;
	size?: string;
	isDisabled?: boolean;
	isError?: boolean;
}

const TimeInput = (props: TimeInputProps) => {
	const {
		value,
		onChange,
		iconVisiblity = true,
		name,
		size = 'large',
		isDisabled = false,
		isError,
	} = props;
	const timeInputStyles = TimeInputStyle();

	function insertTimingColon(element: string) {
		if (
			element.trim().length === 2 &&
			!checkIfOnlyOneColon.test(element.trim())
		) {
			element = element + ':';
		}
		onChange(element);
	}

	return (
		<div
			className={`${
				iconVisiblity
					? isError
						? timeInputStyles.workinHoursInputContainerWithIconAndError
						: timeInputStyles.workinHoursInputContainerWithIcon
					: !iconVisiblity && isError
					? timeInputStyles.workinHoursInputContainerWithoutIconAndError
					: timeInputStyles.workinHoursInputContainerWithoutIcon
			}
					${size === 'small' && timeInputStyles.workinHoursInputSmContainerWithoutIcon}
					`}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{iconVisiblity && (
					<AccessTimeIcon
						style={{
							color: '#555555',
						}}
					/>
				)}
				<input
					type='text'
					className={
						iconVisiblity
							? timeInputStyles.inputWithIcon
							: timeInputStyles.inputWithOutIcon
					}
					name={name}
					placeholder={'hh:mm'}
					value={value.substring(0, 5)}
					onChange={(e: any) => {
						if (e.target.value.trim().length === 2) {
							insertTimingColon(e.target.value);
							return;
						}
						onChange(e.target.value.trim().substring(0, 5));
					}}
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};

export default TimeInput;
