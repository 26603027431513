import React, { useState, useEffect } from 'react';

import { useQuery as ApolloUseQuery } from '@apollo/client';
import { GET_CRM_COMPANIES, GET_CRM_TASK_ACTIVITY_TYPE } from './CrmTasks.gql';

import { Box } from '@material-ui/core';
import { drawerFilterStyle } from '../../Layout/styles';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { PRIORITY_OPTIONS } from '../constant';

export const CrmTaskFilterFormBody = () => {
	const drawerFilterStyles = drawerFilterStyle();
	const [searchedActivity, setSearchedActivity] = useState('');
	const [activityFilterLimit, setActivityFilterLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [taskActivityTypes, setTaskActivityTypes] = useState(null);
	const [searchedCompany, setSearchedCompany] = useState('');
	const [companyFilterLimit, setCompanyFilterLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [companies, setCompanies] = useState(null);

	const { data: crmTaskActivityTypes } = ApolloUseQuery(
		GET_CRM_TASK_ACTIVITY_TYPE,
		{
			variables: {
				limit: activityFilterLimit,
				activity: `%${searchedActivity}%`,
			},
		}
	);

	const { data: crmCompanies } = ApolloUseQuery(GET_CRM_COMPANIES, {
		variables: {
			limit: companyFilterLimit,
			company: `%${searchedCompany}%`,
		},
	});

	useEffect(() => {
		if (
			!crmTaskActivityTypes ||
			!(crmTaskActivityTypes?.crm_task_activity_type?.length > 0)
		) {
			return;
		}
		const activityTypes = crmTaskActivityTypes?.crm_task_activity_type.map(
			({ id, label }: { id: string; label: string }) => {
				return {
					id: id,
					name: label,
				};
			}
		);
		setTaskActivityTypes(activityTypes);
	}, [crmTaskActivityTypes]);

	useEffect(() => {
		if (!crmCompanies || !(crmCompanies?.crm_company?.length > 0)) {
			return;
		}
		const companyFilterOptions = crmCompanies?.crm_company.map(
			({ id, name }: { id: string; name: string }) => {
				return {
					id: id,
					name: name,
				};
			}
		);
		setCompanies(companyFilterOptions);
	}, [crmCompanies]);

	const onActivityTypeExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setActivityFilterLimit(
			isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null
		);
	};

	const onActvitySearch = (activity: string) => {
		setSearchedActivity(activity);
	};

	const onCompanyExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setCompanyFilterLimit(
			isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null
		);
	};

	const onCompanySearch = (activity: string) => {
		setSearchedCompany(activity);
	};
	return (
		<Box className={drawerFilterStyles.container}>
			<FilterInput
				label={'Activity Type'}
				sourceName='activity_type_id'
				onExpansionToggle={onActivityTypeExpansionToggle}
				choices={taskActivityTypes || []}
				onSearch={(name) => onActvitySearch(name)}
				total={
					crmTaskActivityTypes?.crm_task_activity_type_aggregate?.aggregate
						?.count
				}
			/>
			<FilterInput
				label={'Company'}
				sourceName='company_id'
				onExpansionToggle={onCompanyExpansionToggle}
				choices={companies || []}
				onSearch={(name) => onCompanySearch(name)}
				total={crmCompanies?.crm_company_aggregate?.aggregate?.count}
			/>
			<FilterInput
				label={'Priority'}
				sourceName='priority'
				onExpansionToggle={() => {}}
				choices={PRIORITY_OPTIONS}
				onSearch={() => {}}
				total={MAX_FILTER_OPTIONS_SHOWN}
			/>
		</Box>
	);
};

export default CrmTaskFilterFormBody;
