import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';

const useStyles = makeStyles({
	detailsContainer: {
		width: '442px',
		height: 'fit-content',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	buttonContainer: {
		paddingBottom: '0',
	},
	errorContainer: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope',
		color: 'red',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '200px',
		height: '100px',
		overflowY: 'auto',
	},
	updateStatus: {
		fontSize: '14px !important',
		marginBottom: '10px',
		color: 'black',
		font: 'normal normal 800 14px/23px Manrope',
	},
});

interface Props {
	onClose: () => void;
	open: boolean;
	warningMessage?: string;
	projectName?: string[];
}

const AllocationWarningModal = (props: Props) => {
	const { open, onClose, warningMessage, projectName } = props;
	const formStyles = modalFormStyle();
	const warningModalStyles = useStyles();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={warningModalStyles.detailsContainer}>
					<Typography className={warningModalStyles.heading}>
						{warningMessage
							? warningMessage
							: 'This action cannot be performed'}
					</Typography>
					<Box>
						<div className={warningModalStyles.updateStatus}>
							Current Allocations
						</div>
						<div className={warningModalStyles.errorContainer}>
							{projectName?.map((value, i) => (
								<div key={i}>{value}</div>
							))}
						</div>
					</Box>
					<Box className={warningModalStyles.buttonAlignment}>
						<Box
							className={`${formStyles.buttonContainer} ${warningModalStyles.buttonContainer}`}
						>
							<Button onClick={onClose} className={formStyles.saveButton}>
								Ok
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default AllocationWarningModal;
