import React from 'react';

import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { TimesheetStyles } from '../TimesheetStyles';
import dayjs from 'dayjs';
import { Loading } from 'ra-ui-materialui';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import AddIcon from '@material-ui/icons/Add';
import holiday from '../../../assets/holiday.png';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import { ExcludedLeaveProps } from '../../../SharedComponents/model';
import { head } from 'lodash';
import { OvetimeEmptyScreen } from './OvertimeEmptyScreen.component';
import { HolidayProps, TimesheetOvertimeValues } from './Overtime.model';

interface TimeSheetOvertimeDailyViewProps {
	timesheetOvertimeEntries:
		| (TimesheetOvertimeValues | null | undefined)[]
		| undefined;
	onDateChange: (date?: string) => void;
	selectedDate: string;
	isTimesheetOvertimeLoading: boolean;
	onAddEntry: () => void;
	onEdit: (timesheet: TimesheetOvertimeValues | null | undefined) => void;
	onDelete: (timesheetEntryId: string) => void;
	dailyHours: {
		date?: string;
		totalHours?: string;
		orgHoliday: HolidayProps[];
		leaves: any;
	}[];
	weekTotalHours: string;
	onSubmitTimesheetOvertime: () => void;
	disableSubmitTimesheetOvertime?: boolean;
	disabled: boolean;
	isAddButtonDisabled?: boolean;
	isModalClosed?: boolean;
}
interface Holiday {
	name: string;
	date: string;
}
const OvertimeDailyView = (props: TimeSheetOvertimeDailyViewProps) => {
	const {
		dailyHours,
		timesheetOvertimeEntries,
		onDateChange,
		selectedDate,
		isTimesheetOvertimeLoading,
		onAddEntry,
		onEdit,
		onDelete,
		weekTotalHours,
		onSubmitTimesheetOvertime,
		disableSubmitTimesheetOvertime,
		disabled,
		isAddButtonDisabled,
		isModalClosed,
	} = props;
	const timesheetStyles = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const modalFormStyles = modalFormStyle();
	const TabNavigation = ({
		label,
		onClick,
		isSelectedDate = false,
		hours,
	}: {
		label: string;
		onClick: () => void;
		isSelectedDate?: boolean;
		hours: string;
	}) => (
		<Button
			className={`${timesheetStyles.dayNavigationButton} ${
				isSelectedDate && timesheetStyles.selectedDayNavigationButton
			}`}
			onClick={onClick}
		>
			<Box className={timesheetStyles.navigationButtonLabelContainer}>
				<Typography
					className={`${timesheetStyles.dayNavigationButtonLabel} ${
						isSelectedDate && timesheetStyles.selectedDateLabel
					}`}
				>
					{label}
				</Typography>
				<Typography
					className={`${timesheetStyles.dayNavigationButtonLabel} ${
						isSelectedDate && timesheetStyles.selectedDateLabel
					}`}
				>
					{hours}
				</Typography>
			</Box>
		</Button>
	);

	const getTimesheetOvertimeTaskList = () => {
		if (!timesheetOvertimeEntries) {
			return;
		}
		return isTimesheetOvertimeLoading ? (
			<Loading />
		) : (
			<>
				<Box className={timesheetStyles.timesheetsContainer}>
					{timesheetOvertimeEntries?.map((timesheetEntry, index) => (
						<Box
							key={index}
							className={timesheetStyles.timesheetContentContainer}
						>
							<Box className={timesheetStyles.timesheetContent}>
								<Tooltip
									title={`${timesheetEntry?.project || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${timesheetStyles.projectName} ${ellipsisStyles.ellipsis}`}
									>
										{timesheetEntry?.project || '- -'}
									</Typography>
								</Tooltip>
								<Tooltip
									title={`${timesheetEntry?.companyName || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${timesheetStyles.companyName} ${ellipsisStyles.ellipsis}`}
									>
										{timesheetEntry?.companyName || '- -'}
									</Typography>
								</Tooltip>
								<Tooltip
									title={`${timesheetEntry?.task || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${timesheetStyles.task || '- -'} ${
											ellipsisStyles.ellipsis
										}`}
									>
										{timesheetEntry?.task || '- -'}
									</Typography>
								</Tooltip>
								<Tooltip
									title={`${timesheetEntry?.notes || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${timesheetStyles.notes} ${ellipsisStyles.ellipsis}`}
									>
										{timesheetEntry?.notes || '- -'}
									</Typography>
								</Tooltip>
							</Box>
							<Box className={timesheetStyles.timesheetTaskTotalHoursContainer}>
								<Typography className={timesheetStyles.totalHours}>
									{timesheetEntry?.working_hours}
								</Typography>
								<Box
									display='flex'
									justifyContent='flex-end'
									alignItems='center'
								>
									{!disabled && (
										<PopOver
											edit={() => {
												onEdit(timesheetEntry);
											}}
											remove={() => {
												onDelete(timesheetEntry?.id);
											}}
											isModalClosed={isModalClosed}
										/>
									)}
								</Box>
							</Box>
						</Box>
					))}
				</Box>
				<Box width='100%' mt={2}>
					<Box className={timesheetStyles.submitTimesheetActionContainer}>
						<Button
							className={modalFormStyles.saveButton}
							variant='contained'
							startIcon={<AddIcon />}
							onClick={onAddEntry}
							disabled={isAddButtonDisabled}
							id='timesheet_add_daily_entry'
						>
							Add New Entry
						</Button>
						<Button
							className={timesheetStyles.submitTimesheetButton}
							variant='contained'
							onClick={onSubmitTimesheetOvertime}
							disabled={disableSubmitTimesheetOvertime}
							id='timesheet_submit_week'
						>
							Submit overtime for approval
						</Button>
					</Box>
				</Box>
			</>
		);
	};
	const checkHoliday = (date: string, orgHolidayList: Holiday[]) => {
		if (!date && !orgHolidayList) {
			return;
		}
		const holidayList = orgHolidayList?.filter(
			(holiday) => holiday?.date === date
		);
		const holidayTitle =
			holidayList &&
			holidayList?.length > 1 &&
			holidayList?.map((item) => `${item?.name ? item?.name : ''}`);
		return holidayList && holidayList?.length > 0 ? (
			<Box position='relative' left='-20px'>
				<Tooltip
					title={
						holidayTitle
							? holidayTitle.join(',')
							: head(holidayList)?.date
							? `${head(holidayList)?.name}`
							: ''
					}
					placement='right'
				>
					<img src={holiday} alt='Holiday' width='24px' height='20px' />
				</Tooltip>
			</Box>
		) : (
			<></>
		);
	};
	return (
		<>
			<Box className={timesheetStyles.dayNavigationContainer}>
				<Box className={timesheetStyles.dayNavigation}>
					{dailyHours.length > 0 &&
						dailyHours.map((dailyHour: any, index) => (
							<Box key={index} display={'flex'}>
								<Box>
									<TabNavigation
										label={`${dayjs(dailyHour.date).format('ddd')}`}
										onClick={() => {
											onDateChange(dailyHour?.date);
										}}
										hours={dailyHour?.totalHours || '00:00'}
										isSelectedDate={dailyHour.date === selectedDate}
									/>
								</Box>
								<Box position='relative' left='-24px'>
									{dailyHour?.leaves?.length > 0 &&
										dailyHour?.leaves?.map((leave: ExcludedLeaveProps) => {
											if (dailyHour?.date === leave?.leaveDate) {
												return (
													<Box>
														<Tooltip title={leave?.name} placement='right'>
															<div className={timesheetStyles.leaveCircle}>
																L
															</div>
														</Tooltip>
													</Box>
												);
											}
										})}
								</Box>
								<Box>
									{checkHoliday(dailyHour?.date, dailyHour?.orgHoliday)}
								</Box>
							</Box>
						))}
				</Box>
				<Box className={timesheetStyles.totalHoursContainer}>
					<Typography className={timesheetStyles.totalHours}>
						{weekTotalHours}
					</Typography>
					<Typography className={timesheetStyles.totalHoursLabel}>
						TOTAL HOURS
					</Typography>
				</Box>
			</Box>
			<Box className={timesheetStyles.content}>
				{timesheetOvertimeEntries && timesheetOvertimeEntries?.length > 0 ? (
					getTimesheetOvertimeTaskList()
				) : (
					<OvetimeEmptyScreen onAddEntry={onAddEntry} disabled={disabled} />
				)}
			</Box>
		</>
	);
};

export default OvertimeDailyView;
