import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useContext,
} from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { ellipsisStyle } from '../../../Layout/styles';
import { Divider, Avatar, Tooltip } from '@material-ui/core';
import { Loading, setSidebarVisibility } from 'react-admin';
import { SubTaskIcon } from '../../../assets/SvgIcons';
import './milestoneKanban.css';
import { LinerProgressTask } from '../../Projects/Progressbar';
import { GET_MILESTONES_BY_PROJECT_ID } from '../Projects.gql';
import { Kanban } from './kanban.component';
import { UserProfileContext } from '../../../App';

interface propTypes {
	projectId: string;
	searchedText: string;
	selectedStatus: any;
	resourceList: string[];
}

export const MileStoneKanban = forwardRef((props: propTypes, ref: any) => {
	const { projectId, searchedText, selectedStatus, resourceList } = props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const dispatch = useDispatch();
	const ellipsis = ellipsisStyle();
	const [kanbanColumnViews, setKanbanColumnViews] = useState<any[]>([]);
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const [loadKanban, { data: kanbanBoard, loading }] = useLazyQuery<any>(
		GET_MILESTONES_BY_PROJECT_ID,
		{
			variables: {
				id: projectId,
				filter: {
					deleted_at: { _is_null: true },
					parent_task_id: { _is_null: true },
					title: {
						_ilike: `%${searchedText || ''}%`,
					},
					assigned_to:
						resourceList && resourceList?.length > 0
							? { _in: resourceList }
							: {},
					project_kanban_cards: {
						kanban_column: {
							id:
								selectedStatus && selectedStatus?.length > 0
									? { _in: selectedStatus }
									: { _in: [] },
						},
					},
				},
			},
			fetchPolicy: 'network-only',
		}
	);
	useEffect(() => {
		loadKanban();
	}, [loadKanban]);

	useImperativeHandle(ref, () => ({
		loadKanbanData() {
			loadKanban();
		},
	}));

	useEffect(() => {
		if (!kanbanBoard) {
			return;
		}
		const columns = kanbanBoard?.project_milestone || [];
		const kanbanColumns =
			columns &&
			columns.length > 0 &&
			columns.map((column: any) => {
				return {
					...column,
					cards: column?.project_tasks?.map((card: any, index: number) => ({
						name: card?.title,
						index,
						ordinal: '',
						id: card?.id,
						unique_id: card?.task_number,
						categoryName: card?.project_task_category?.name || '',
						projectId: card?.project_id,
						columnName:
							card?.project_kanban_cards[0]?.kanban_column?.name || '',
						fullName: card?.assignedTo?.full_name
							? `${card?.assignedTo?.full_name} `
							: 'Unassigned',
						profilePic: card?.assignedTo?.profile_pic,
						planned_hours: card?.planned_hours,
						dueDate: card?.planned_end_date,
						userId: card?.assignedTo?.id,
						priority: card?.priority,
						prefix: card?.project?.prefix,
						is_billable: card?.is_billable,
						planned_start_date: card?.planned_start_date,
						planned_end_date: card?.planned_end_date,
						reminder: card?.reminder,
						assigned_to: card?.assigned_to,
						completion_percentage: card?.completion_percentage,
						subTaskCount: card?.project_tasks_aggregate?.aggregate?.count || 0,
						totalTask: column?.project_tasks?.totalTaskCount,
						completedTask: column?.project_tasks?.completedTaskCount,
					})),
				};
			});

		if (kanbanColumns && kanbanColumns.length > 0) {
			setKanbanColumnViews(kanbanColumns);
		}
	}, [kanbanBoard]);
	const getDueDate = (dueDate: string) => {
		if (!dueDate) {
			return;
		}
		const today = dayjs().startOf('day');
		const taskDueDate = dayjs(dueDate).startOf('day');
		const dateDifference = taskDueDate.diff(today, 'days');
		if (dateDifference === 0) {
			return <p className={'projectTaskDueToday'}>Due Today</p>;
		}
		if (dateDifference < 0) {
			return <p className={'projectTaskOverDue'}>Overdue</p>;
		}
		if (dateDifference > 0 && dateDifference <= 7) {
			return (
				<p
					title={`Due In ${dateDifference} days`}
					className={`projectTaskNormalDue ${ellipsis.ellipsis}`}
				>
					Due In {dateDifference} days
				</p>
			);
		}
		return taskDueDate.format('D-MMM-YYYY');
	};

	const getTaskOverDueStatus = (dueDate: string) => {
		if (!dueDate) {
			return;
		}
		const today = dayjs();
		const taskDueDate = dayjs(dueDate);
		const dateDifference = taskDueDate.diff(today, 'days');
		return dateDifference < 0 ? true : false;
	};

	const cardBody = (card: any) => (
		<div
			className={
				getTaskOverDueStatus(card?.dueDate) && card?.state !== 'DONE'
					? 'projectTaskOverdueCardContainer'
					: 'projectTaskCardContainer'
			}
		>
			<div className={'projectTaskIdStyle'}>
				<div className={'taskIdContainer'}>
					{`${card?.prefix}-${card.unique_id}`}
				</div>
				{card?.subTaskCount && card?.subTaskCount > 0 ? (
					<div
						style={{
							display: 'flex',
							boxShadow: 'none',
							cursor: 'pointer',
							marginTop: '6px',
						}}
					>
						<div className={'subTask'} title='Subtask'>
							<div
								className={'numberofSubTask'}
								style={{
									display: 'flex !important',
									justifyContent: 'flex-end !important',
								}}
							>{`${card?.subTaskCount}`}</div>
							<SubTaskIcon className='taskIcon' />
						</div>
					</div>
				) : (
					''
				)}
			</div>
			<Tooltip title={`${card.name}`} placement='right'>
				<div
					style={{
						paddingLeft: '8px',
					}}
					className={`${'projectTaskHeaderStyle'} ${ellipsis.ellipsis}`}
				>
					{card.name}
				</div>
			</Tooltip>
			<div className={'projectCategoryContainer'}>
				<Tooltip title={`${card.columnName || ''}`} placement='right'>
					<div className={'projectTaskCategoryName'}>
						{card?.columnName || ''}
					</div>
				</Tooltip>
				<Tooltip title={`${card.categoryName || ''}`} placement='right'>
					<div
						style={{ marginRight: '10px' }}
						className={'projectTaskCategoryName'}
					>
						{card?.categoryName || ''}
					</div>
				</Tooltip>
			</div>
			<Divider />
			<div className='projectTaskCardFooter'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p className={'projectTaskContainerStyle'}>
						<Tooltip title={card?.fullName || 'Unassigned'} placement='bottom'>
							<Avatar
								style={{
									width: '20px',
									height: '20px',
									fontSize: '12px',
									marginRight: '12px',
								}}
								src={`${card?.profilePic || ''}`}
							>
								{card?.fullName?.charAt(0) || 'Unassigned'}
							</Avatar>
						</Tooltip>
					</p>

					{card?.state !== 'DONE' && (
						<p
							className={'projectTaskCreatedDate'}
							title={`${dayjs(card?.dueDate).format(dateFormat)}`}
						>
							{card?.dueDate ? getDueDate(card?.dueDate) : '--'}
						</p>
					)}
				</div>
				<div className='progressBarContainer'>
					<LinerProgressTask
						variant='determinate'
						value={Number(card?.completion_percentage?.toFixed()) || 0}
					/>
					<div style={{ marginLeft: '4px', fontSize: '10px' }}>
						{`${card?.completion_percentage?.toFixed() || 0}%`}
					</div>
				</div>
				<div
					style={{
						width: '52px',
					}}
				>
					<div
						title={'Priority'}
						style={{
							padding:
								card?.priority === 'Medium'
									? '4px 8px 4px 8px'
									: '4px 8px 4px 14px',
						}}
						className={`${'projectTaskChipStyle'} ${
							card.priority === 'High'
								? 'projectTaskPriorityHigh'
								: card.priority === 'Medium'
								? 'projectTaskPriorityMedium'
								: card.priority === 'Low'
								? 'projectTaskPriorityLow'
								: undefined
						}`}
					>
						{card.priority}
					</div>
				</div>
			</div>
		</div>
	);
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<div
				style={{
					paddingTop: '33px',
				}}
			>
				<Kanban
					onAddCardToColumn={() => {}}
					columns={kanbanColumnViews}
					updateOrdinal={() => {}}
				>
					{cardBody}
				</Kanban>
			</div>
		</>
	);
});
