import React, { useState, useEffect } from 'react';
import {
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	required,
	NumberInput,
	useQuery,
	useNotify,
	minValue,
} from 'react-admin';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import {
	ProjectEditForm,
	ProjectCreateActions,
} from './ProjectEditForm.component';
import { ProjectSkillList } from './ProjectSkillLIst.component';
const useStyles = makeStyles({
	buttonStyle: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginLeft: '15%',
	},
	buttonCursor: {
		cursor: 'pointer',
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});
export const EditProject = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [projectId, setProjectId] = useState(null);
	const EditProjectSkills = () => {
		const [levelId, setLevelId] = useState(null);
		const [skillId, setSkillId] = useState(null);
		const [count, setCount] = useState(null);
		const [updatedSkillId, setUpdatedSkillId] = useState(null);
		const [selectedSkill, setSelectedSkill] = useState(null);
		const isDisabled = true;
		const [mutate] = useMutation();
		const notify = useNotify();
		const { data: skill } = useQuery({
			type: 'GET_LIST',
			resource: 'project_skill_requirement',
			payload: {
				filter: { id: updatedSkillId ? updatedSkillId : '' },
			},
		});
		const { data: duplicatedSkills } = useQuery({
			type: 'GET_LIST',
			resource: 'project_skill_requirement',
			payload: {
				filter: {
					project_id: projectId,
					skill_id: skillId,
					skill_level_id: levelId,
				},
			},
		});
		useEffect(() => {
			if (skill) {
				const updatedSkill = {
					skill_id: skill[0].skill_id,
					skill_level_id: skill[0].skill_level_id,
					count: skill[0].count,
				};
				setSelectedSkill(updatedSkill);
			}
		}, [skill, updatedSkillId]);
		const onSkillLevelChange = (employeeLevel) => {
			employeeLevel?.target?.value
				? setLevelId(employeeLevel.target.value)
				: setLevelId('');
		};
		const onSkillChange = (employeeSkill) => {
			employeeSkill?.target?.value
				? setSkillId(employeeSkill.target.value)
				: setSkillId('');
		};
		const onCountChange = (employeeCount) => {
			employeeCount?.target?.value
				? setCount(parseInt(employeeCount.target.value))
				: setCount('');
		};
		const onEdit = (skillId) => {
			setUpdatedSkillId(skillId);
		};
		const onClear = () => {
			history.push(props.location.pathName);
		};
		const saveProjectSkills = (event) => {
			if (count < 1) {
				notify('Not a valid count (Count must be at least 1)', 'warning');
				onClear();
				return;
			}
			if (duplicatedSkills[0]) {
				notify('Failed to create a duplicate record', 'warning');
				onClear();
				return;
			}
			if (!updatedSkillId) {
				mutate({
					type: 'create',
					resource: 'project_skill_requirement',
					payload: {
						data: {
							project_id: projectId,
							skill_id: skillId,
							skill_level_id: levelId,
							count: count,
						},
					},
				});
				onClear();
				return;
			}
			mutate({
				type: 'update',
				resource: 'project_skill_requirement',
				payload: {
					id: updatedSkillId,
					data: {
						project_id: projectId,
						skill_id: skillId || selectedSkill.skill_id,
						skill_level_id: levelId || selectedSkill.skill_level_id,
						count: count || selectedSkill.count,
					},
				},
			});
			onClear();
		};
		const CustomToolbar = () => (
			<Box>
				<Box style={{ margin: '1em' }}>
					<Box className={classes.buttonContainer}>
						<Box>
							<NumberInput
								source='count'
								min={1}
								validate={[required(), minValue(1)]}
								onChange={(employeeCount) => {
									onCountChange(employeeCount);
								}}
							/>
						</Box>
						<Box className={classes.buttonStyle}>
							<Box>
								<SaveIcon
									color='primary'
									onClick={saveProjectSkills}
									className={classes.buttonCursor}
								/>
							</Box>
							<Box ml='10px'>
								<ClearIcon
									onClick={onClear}
									color='secondary'
									className={classes.buttonCursor}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		);

		return (
			<Box>
				<Box style={{ margin: '1em' }}>
					<Typography variant='h6'>Skill Requirements</Typography>
					<>
						<SimpleForm
							toolbar={<CustomToolbar />}
							initialValues={
								selectedSkill
									? selectedSkill
									: { skill_id: '', skill_level_id: '', count: '' }
							}
						>
							<Box display='flex'>
								<Box mr={1}>
									<ReferenceInput
										onChange={(employeeSkill) => {
											onSkillChange(employeeSkill);
										}}
										source='skill_id'
										reference='skill_master'
										label='Skill'
									>
										<SelectInput
											resettable={true}
											optionText='name'
											validate={required()}
										/>
									</ReferenceInput>
								</Box>
								<Box pl='20px'>
									<ReferenceInput
										onChange={(employeeLevel) =>
											onSkillLevelChange(employeeLevel)
										}
										source='skill_level_id'
										reference='skill_level'
										label='Skill Level'
										sort={{ field: 'level', order: 'ASC' }}
									>
										<SelectInput
											resettable={true}
											optionText='level'
											validate={required()}
										/>
									</ReferenceInput>
								</Box>
								<Box></Box>
							</Box>
						</SimpleForm>
					</>
				</Box>
				<Box pl='20px'>
					<ProjectSkillList
						projectId={projectId}
						onEdit={onEdit}
						isDisabled={isDisabled}
					/>
				</Box>
			</Box>
		);
	};
	const ProjectTitle = ({ record }) => {
		setProjectId(record.id);
		return <span>Edit Project: {record ? `"${record.name}"` : ''}</span>;
	};
	return (
		<Edit
			aside={<EditProjectSkills />}
			{...props}
			title={<ProjectTitle />}
			actions={<ProjectCreateActions {...props} />}
		>
			<ProjectEditForm {...props} />
		</Edit>
	);
};
