import React, { useEffect, useState, useContext } from 'react';
import {
	Box,
	Typography,
	Button,
	Drawer,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { GET_OPPORTUNITY_MIN_MAX } from '../modules/Opportunities/Opportunities.gql';
import { UserProfileContext } from '../App';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_ORGANISATION } from './gqlQueries';
import { FilterSliderInput } from './FilterSliderComponent';
import { drawerFilterStyle } from '../Layout/styles';

const OpportunityKanbanFilterContainer = (props) => {
	const { open, close, children, formValue, choosedFilters, isFilterCleared } =
		props;
	const [opportunitySliderValue, setOpportunitySliderValue] = useState([]);
	const [opportunityMin, setOpportunityMin] = useState(null);
	const [opportunityMax, setOpportunityMax] = useState(null);
	const [probabilityMin, setProbabilityMin] = useState(0);
	const [probabilityMax, setProbabilityMax] = useState(100);
	const [probabilitySliderValue, setProbabilitySliderValue] = useState([]);
	const { orgId: organizationId } = useContext(UserProfileContext);
	const { data: organisation } = useApolloQuery(GET_ORGANISATION, {
		variables: { organizationId },
	});
	const classes = drawerFilterStyle();
	const onSubmit = (values) => {
		const formData = {
			...values,
			win_percentage: probabilitySliderValue,
			value: opportunitySliderValue,
		};
		formValue(formData);
		close();
	};

	const onProbabilityChange = (value) => {
		setProbabilitySliderValue(value);
	};
	const onOpportunityChange = (value) => {
		setOpportunitySliderValue(value);
	};
	const { data: opportunity } = useApolloQuery(GET_OPPORTUNITY_MIN_MAX, {
		variables: {},
	});

	useEffect(() => {
		if (!opportunity) {
			return;
		}
		const sliderValue = opportunity?.opportunities_aggregate?.aggregate;
		setOpportunitySliderValue([
			sliderValue?.min?.value,
			sliderValue?.max?.value,
		]);
		setOpportunityMin(
			opportunity?.opportunities_aggregate?.aggregate?.min?.value
		);
		setOpportunityMax(
			opportunity?.opportunities_aggregate?.aggregate?.max?.value
		);
		setProbabilityMin(0)
		setProbabilityMax(100)
		setProbabilitySliderValue([0, 100])
	}, [opportunity]);

	const clearFilter = () => {
		const sliderValue = opportunity?.opportunities_aggregate?.aggregate;
		setOpportunitySliderValue([
			sliderValue?.min?.value,
			sliderValue?.max?.value,
		]);
		setProbabilitySliderValue([0, 100])
		formValue([])
		close()
	};

	useEffect(() => {
		if (!isFilterCleared) {
			return;
		}
		const sliderValue = opportunity?.opportunities_aggregate?.aggregate;
		setOpportunitySliderValue([
			sliderValue?.min?.value,
			sliderValue?.max?.value,
		]);
		setOpportunityMin(
			opportunity?.opportunities_aggregate?.aggregate?.min?.value
		);
		setOpportunityMax(
			opportunity?.opportunities_aggregate?.aggregate?.max?.value
		);
		setProbabilityMax(100)
		setProbabilityMin(0)
	}, [isFilterCleared, opportunity]);

	useEffect(() => {
		if (!choosedFilters && !choosedFilters?.length > 0) {
			return;
		}
		if(choosedFilters?.value){
		setOpportunitySliderValue(choosedFilters?.value)
		}
		if(choosedFilters?.win_percentage){
			setProbabilitySliderValue(choosedFilters?.win_percentage)
		}
	}, [choosedFilters, opportunity]);

	return (
		<Drawer open={open} anchor='right' onClose={close}>
			<Box className={classes.drawerContainer}>
				<Box display='flex' flexDirection='column'>
					<Box className={classes.HeaderSection}>
						<Typography className={classes.drawerHeading}>Filters</Typography>
						<Typography
							className={classes.resetButton}
							onClick={() => clearFilter()}
						>
							Clear all
						</Typography>
					</Box>
				</Box>

				<Form onSubmit={onSubmit} initialValues={choosedFilters}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							{children}
								<Box className={classes.container}>
									<Box>
										<FilterSliderInput
											initialMin={probabilityMin}
											initialMax={probabilityMax}
											selectedValue={probabilitySliderValue}
											label='Win Percentage(%)'
											onChange={onProbabilityChange}
										/>
									</Box>
									<Box>
										<FilterSliderInput
											initialMin={opportunityMin}
											initialMax={opportunityMax}
											selectedValue={opportunitySliderValue}
											label={`Opportunity Value(${organisation?.organization[0]?.currency?.currency_type})`}
											onChange={onOpportunityChange}
										/>
									</Box>
								</Box>
							<Box className={classes.buttonContainer}>
								<Box mb={'10px'}>
									<Button
										className={classes.applyFilterButton}
										variant='outlined'
										color='primary'
										type='submit'
									>
										Apply Filters
									</Button>
								</Box>
								<Box mb={'10px'}>
									<Button
										onClick={() => close()}
										className={classes.cancelButton}
										variant='outlined'
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</Drawer>
	);
};

export default OpportunityKanbanFilterContainer;
