import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import dayjs from 'dayjs';

import { Attendance } from '../../Attendance/Attendance.model';
import { useUpsertAttendanceMutation } from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { Style } from '../styles/HomePageMobile.styles';

interface Props {
	refetchAttendance: () => void;
	refetchWorkHours: () => void;
	todaysAttendance: Attendance;
}

const CheckInMobile = (props: Props) => {
	const { refetchAttendance, refetchWorkHours, todaysAttendance } = props;
	const { permissions: permissionList, id: userId } =
		useContext<any>(UserProfileContext);
	const classes = Style();
	const [todaysWorkedHour, setTodaysWorkedHour] = useState('0');
	const [time, setTime] = useState(dayjs().format('HH:mm'));
	const [location, setLocation] = useState('');
	const [upsertAttendance] = useUpsertAttendanceMutation();

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(getPosition);
		const intervel = setInterval(() => {
			setTime(dayjs().format('HH:mm'));
		}, 60 * 100);
		return () => clearInterval(intervel);
	}, []);

	useEffect(() => {
		if (todaysAttendance?.id) {
			const inTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.in_time}`
			);
			const outTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.out_time}`
			);
			const diff = dayjs.duration(outTime.diff(inTime)).format('HH:mm');
			setTodaysWorkedHour(diff);
		}
	}, [todaysAttendance]);

	const getPosition = (position: GeolocationPosition) => {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		const location = `${latitude},${longitude}`;
		setLocation(location);
	};

	const onSave = (attendance: Attendance) => {
		if (permissionList?.appPermissions?.ui?.editOthersAttendance) {
			upsertAttendance({
				variables: {
					attendance: {
						user_id: userId,
						...attendance,
						location: location || null,
					},
				},
			}).then((data) => {
				refetchAttendance();
				refetchWorkHours();
			});
		} else {
			upsertAttendance({
				variables: {
					attendance: {
						...attendance,
						location: location || null,
					},
				},
			}).then((data) => {
				refetchAttendance();
				refetchWorkHours();
			});
		}
	};

	const handleCheckout = () => {
		const checkOut = {
			...todaysAttendance,
			out_time: dayjs().format('HH:mm:ssZ'),
		};
		onSave(checkOut);
	};

	const handleCheckIn = () => {
		const checkIn = {
			in_time: dayjs().format('HH:mm:ssZ'),
			date: dayjs().format('YYYY-MM-DD'),
		};
		onSave(checkIn);
	};

	return (
		<Box className={classes.checkinContainerMobileView}>
			<Box className={classes.additionalInfoLabelMobileView} marginLeft={'0px'}>
				Mark today’s attendance
			</Box>
			<Box className={classes.attendanceDetailsMobileView}>
				<Box
					display='flex'
					justifyContent={'space-between'}
					className={classes.currentDateInfoContainerMobileView}
				>
					<Box className={classes.todaysDate}>
						{dayjs().format('DD MMM, YYYY')}
					</Box>
					<Box className={classes.todaysDay} marginLeft={'0px'}>
						{dayjs().format('dddd')}
					</Box>
				</Box>
				<Box
					className={classes.addCheckButtonContainerMobileView}
					width='129px'
				>
					<Button
						className={
							!todaysAttendance?.in_time
								? classes.addCheckButtonMobileView
								: classes?.addCheckedInContainerMobileView
						}
						onClick={handleCheckIn}
						disabled={!!todaysAttendance?.in_time}
					>
						{!todaysAttendance?.in_time ? (
							`Check In ${time}`
						) : (
							<Box className={classes.checkedin}>
								<Box color='#34A853'>Checked In</Box>
								<Box color='#292929' marginLeft='5px'>
									{todaysAttendance?.in_time
										? dayjs(
												`${todaysAttendance?.date} ${todaysAttendance?.in_time}`
										  ).format('HH:mm')
										: time}
								</Box>
							</Box>
						)}
					</Button>
					<Button
						className={
							todaysAttendance?.out_time
								? classes.checkoutOutMobileView
								: todaysAttendance?.in_time
								? classes.addCheckButtonMobileView
								: classes.addCheckoutButtonMobileView
						}
						onClick={handleCheckout}
						disabled={
							!todaysAttendance?.in_time || !!todaysAttendance?.out_time
						}
					>
						{todaysAttendance?.out_time ? (
							<Box className={classes.checkedin}>
								<Box color='#888888'>Checked Out</Box>
								<Box color='#292929' marginLeft='5px'>
									{todaysAttendance?.out_time
										? dayjs(
												`${todaysAttendance?.date} ${todaysAttendance?.out_time}`
										  ).format('HH:mm')
										: time}
								</Box>
							</Box>
						) : todaysAttendance?.in_time ? (
							<Box>{`Check Out ${time}`}</Box>
						) : (
							<Box>Checkout</Box>
						)}
					</Button>
				</Box>
			</Box>
			{todaysAttendance?.out_time ? (
				<Box
					display='flex'
					justifyContent={'center'}
					marginRight={'0px'}
					marginBottom={'0px'}
				>
					<Box className={classes.additionalInfoLabel}>
						Hours worked : {todaysWorkedHour} Hrs
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default CheckInMobile;
