import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { TimesheetStyles } from '../TimesheetStyles';
import AddIcon from '@material-ui/icons/Add';
import { modalFormStyle } from '../../../Layout/styles';
import { DEFAULT_EMPTY_TIME_SHEET_TEXT } from '../constant';

export const OvetimeEmptyScreen = ({
	text = DEFAULT_EMPTY_TIME_SHEET_TEXT,
	onAddEntry,
	disabled = false,
}: {
	text?: string;
	copyText?: string;
	onAddEntry: () => void;
	disabled: boolean;
}) => {
	const timesheetStyles = TimesheetStyles();
	const modalFormStyles = modalFormStyle();

	return (
		<Box className={timesheetStyles.emptyScreenContainer}>
			<Typography className={timesheetStyles.emptyScreenText}>
				{text}
			</Typography>
			<Box mt={2} mb={5}>
				<Button
					className={modalFormStyles.saveButton}
					variant='contained'
					startIcon={<AddIcon />}
					onClick={onAddEntry}
					disabled={disabled}
				>
					Add Overtime
				</Button>
			</Box>
		</Box>
	);
};
