import React, { Fragment } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	listItem: {
		paddingLeft: '1rem',
		marginLeft: '14px',
	},
	listItemText: {
		paddingLeft: 2,
		fontSize: '2rem',
		position: 'absolute',
		left: '40px',
		top: '0px',
	},
	MuiListItemIcon: {
		backgroundColor: 'red',
	},
	sidebarIsOpen: {
		paddingLeft: 25,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
		marginLeft: '14px',
	},
	sidebarIsClosed: {
		paddingLeft: 0,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
	},
	subMenuIndicator: {
		marginLeft: 'auto',
	},
}));

const SubMenu = ({
	handleToggle,
	sidebarIsOpen,
	isOpen,
	name,
	icon,
	classes,
	children,
	translate,
}) => {
	classes = useStyles();
	return (
		<Fragment>
			<ListItem
				dense
				button
				title={name}
				onClick={handleToggle}
				className={classes.listItem}
			>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					inset
					primary={isOpen ? name : ''}
					secondary={isOpen ? '' : name}
					className={classes.listItemText}
				/>
				{isOpen ? (
					<ExpandLess className={classes.subMenuIndicator} />
				) : (
					<ExpandMore className={classes.subMenuIndicator} />
				)}
			</ListItem>
			<Collapse in={isOpen} timeout='auto' unmountOnExit>
				<List
					dense
					component='div'
					disablePadding
					className={
						sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
					}
				>
					{children}
				</List>
				<Divider />
			</Collapse>
		</Fragment>
	);
};

export default SubMenu;
