import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	Dialog,
	ThemeProvider,
	Typography,
	Avatar,
	CircularProgress,
	CircularProgressProps,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { modalFormTheme } from '../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
	SubmittedTimesheet,
	TimesheetDetails,
	HolidayProps,
} from './Timesheet.model';
import { TimesheetStyles } from './TimesheetStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
	useGetTimesheetEntriesForWeekQuery,
	useGetTimesheetTotalHoursQuery,
	useGetOrgHolidaysByUserQuery,
} from '../../generated/graphql';
import { TimesheetDetailModalStyle } from './TimesheetStyles';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import { head } from 'lodash';
import { ExcludedLeaveProps } from '../../SharedComponents/model';
import holiday from '../../assets/holiday.png';
import { getSubmittedTimesheet } from './Timesheet.utils';
import { UserProfileContext } from '../../App';
import { SpeakerNotes } from '@material-ui/icons';
import useGetExcludedLeaves from './useGetExcluedLeaves';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetHistoryDetailsModalProps {
	onClose: () => void;
	open: boolean;
	timesheet?: TimesheetDetails;
}

export const TimesheetHistoryDetailModal = (
	props: TimesheetHistoryDetailsModalProps
) => {
	const { open, onClose, timesheet } = props;
	const formStyles = modalFormStyle();
	const pendingApprovaDetailsStyles = TimesheetDetailModalStyle();
	const [submittedTimesheet, setSubmittedTimesheet] =
		useState<SubmittedTimesheet[]>();
	const [organizationHoliday, setOrganizationHoliday] = useState<
		HolidayProps[] | undefined
	>();

	const timesheetStyle = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const {
		noOfLocations,
		locationId,
		dateFormat,
		id: userId,
	} = useContext<any>(UserProfileContext);

	const [excludedLeaves] = useGetExcludedLeaves(
		timesheet?.startDate,
		timesheet?.endDate,
		userId,
		locationId
	);

	const { data: orgHoliday } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: timesheet?.startDate,
								_lte: timesheet?.endDate,
							},
							location_id: { _eq: locationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: timesheet?.startDate,
								_lte: timesheet?.endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: timesheet?.startDate,
									_lte: timesheet?.endDate,
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: timesheet?.startDate,
									_lte: timesheet?.endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip: !locationId && noOfLocations && noOfLocations > 0,
	});

	const { data: timesheetEntries, loading: isTimesheetEntriesLoading } =
		useGetTimesheetEntriesForWeekQuery({
			variables: {
				userId: timesheet?.user?.id,
				startDate: timesheet?.startDate,
				endDate: timesheet?.endDate,
			},
			fetchPolicy: 'network-only',
		});

	const { data: weekHoursByday } = useGetTimesheetTotalHoursQuery({
		variables: {
			userId: timesheet?.user?.id,
			startDate: timesheet?.startDate,
			endDate: timesheet?.endDate,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgHoliday) {
			setOrganizationHoliday([]);
			return;
		}
		const regularHolidays =
			orgHoliday?.org_holidays?.map((value) => {
				return {
					name: value?.name,
					date: value?.date,
					locationName: value?.org_location?.name || '',
				};
			}) || [];
		const optionalHolidays =
			orgHoliday?.user_optional_holiday_mapping?.map((value) => {
				return {
					name: value?.org_holiday?.name,
					date: value?.org_holiday?.date,
					locationName: value?.org_holiday?.org_location?.name || '',
				};
			}) || [];
		setOrganizationHoliday([...regularHolidays, ...optionalHolidays]);
	}, [locationId, noOfLocations, orgHoliday]);

	useEffect(() => {
		if (!timesheetEntries) {
			return;
		}
		const reporteeSubmittedTimesheet = getSubmittedTimesheet(timesheetEntries);
		if (reporteeSubmittedTimesheet) {
			setSubmittedTimesheet(reporteeSubmittedTimesheet);
		}
	}, [timesheetEntries]);

	const CircularProgressWithLabel = (
		props: CircularProgressProps & { value: number }
	) => {
		return (
			<Box position='relative' display='inline-flex'>
				<CircularProgress variant='determinate' {...props} />
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					<Typography
						className={pendingApprovaDetailsStyles.billablePercentageLabel}
					>{`${props.value}%`}</Typography>
				</Box>
			</Box>
		);
	};

	const Toolbar = () => (
		<Box className={pendingApprovaDetailsStyles.actionContainer}>
			{timesheet?.dateRange ? (
				<Box
					className={`${timesheetStyle.avatarContainer} ${pendingApprovaDetailsStyles.userDetailsContainer}`}
				>
					<Avatar
						className={timesheetStyle.avatar}
						src={`${timesheet?.user?.profilePic || ''}`}
					>
						{timesheet?.user?.name?.charAt(0).toUpperCase() || ''}
					</Avatar>
					<Typography className={timesheetStyle.employeeName}>
						{`${timesheet?.user?.name || ''}`}
					</Typography>
					<Box ml={1} mr={1}>
						<Typography className={pendingApprovaDetailsStyles.breadCrum}>
							{'>>'}
						</Typography>
					</Box>
					<Typography className={pendingApprovaDetailsStyles.dateRange}>
						{`Timesheet for ${timesheet?.dateRange}`}
					</Typography>
				</Box>
			) : (
				<Typography className={pendingApprovaDetailsStyles.dateRange}>
					- -
				</Typography>
			)}
		</Box>
	);

	const getTotalHoursByDay = (date: string) => {
		if (!date || !weekHoursByday) {
			return;
		}
		const timesheetEntryDate = weekHoursByday.timesheet_daily_hours.find(
			(hoursByday) => hoursByday?.date === date
		);
		const workingHoursByDay = timesheetEntryDate?.sum.split(':');
		return workingHoursByDay
			? `${workingHoursByDay[0]}:${workingHoursByDay[1]}`
			: '00:00';
	};

	const getTaskTotalHours = (taskWorkingHours: string[]) => {
		if (!taskWorkingHours) {
			return;
		}
		const taskHoursAndMinutes = taskWorkingHours
			.map((hours) => hours.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return `${Math.floor(
			taskHoursAndMinutes.asHours()
		)}: ${taskHoursAndMinutes.format('mm')}`;
	};

	const checkHoliday = (date: string) => {
		if (!date && !organizationHoliday) {
			return;
		}
		const holidayList = organizationHoliday?.filter(
			(holiday) => holiday?.date === date
		);
		const holidayTitle =
			holidayList &&
			holidayList?.length > 1 &&
			holidayList?.map((item) => `${item?.name ? item?.name : ''}`);
		return holidayList && holidayList?.length > 0 ? (
			<Box width='1px' position='relative' top='-4px'>
				<Tooltip
					title={
						holidayTitle
							? holidayTitle.join(',')
							: head(holidayList)?.date
							? `${head(holidayList)?.name}`
							: ''
					}
					placement='right'
				>
					<img src={holiday} alt='Holiday' width='24px' height='16px' />
				</Tooltip>
			</Box>
		) : (
			<></>
		);
	};
	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
					fullWidth={true}
					maxWidth={false}
				>
					<Box className={pendingApprovaDetailsStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								TIMESHEET DETAILS
							</Typography>
							<CloseIcon
								className={formStyles.closeIcon}
								onClick={onClose}
								id='timesheet_history_view_details_close_icon'
							/>
						</Box>
						<Toolbar />
						<Box className={pendingApprovaDetailsStyles.kpiContainer}>
							<Box className={pendingApprovaDetailsStyles.kpi}>
								<CircularProgressWithLabel
									value={Number(timesheet?.billablePercentage || 0)}
									variant='determinate'
									thickness={5.6}
									className={pendingApprovaDetailsStyles.circularProgress}
								/>
								<Box
									className={pendingApprovaDetailsStyles.billableHoursContainer}
								>
									<Typography
										className={pendingApprovaDetailsStyles.billableHours}
									>
										{`${timesheet?.billableHours || '00:00'} hrs`}
									</Typography>
									<Box display='flex' alignItems='center'>
										<Box
											className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.billableHoursIcon}`}
										></Box>
										<Typography
											className={pendingApprovaDetailsStyles.billableHoursLabel}
										>
											Billable
										</Typography>
									</Box>
								</Box>
								<Box
									className={pendingApprovaDetailsStyles.billableHoursContainer}
								>
									<Typography
										className={pendingApprovaDetailsStyles.billableHours}
									>
										{`${timesheet?.nonBillableHours || '00:00'} hrs`}
									</Typography>
									<Box display='flex' alignItems='center'>
										<Box
											className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.nonBillableHoursIcon}`}
										></Box>
										<Typography
											className={pendingApprovaDetailsStyles.billableHoursLabel}
										>
											Non-Billable
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box
								className={pendingApprovaDetailsStyles.kpi}
								justifyContent='center'
							>
								<Box
									width='100%'
									display='flex'
									alignItems='center'
									justifyContent='center'
								>
									<Box className={pendingApprovaDetailsStyles.iconContainer}>
										<AccessTimeIcon
											className={pendingApprovaDetailsStyles.accessTimeIcon}
										/>
									</Box>
									<Box
										display='flex'
										alignItems='flex-end'
										flexDirection='column'
									>
										<Typography
											className={pendingApprovaDetailsStyles.totalHoursValue}
										>
											{`${timesheet?.totalHours || '00:00'}`}
										</Typography>
										<Typography className={timesheetStyle.totalHoursLabel}>
											TOTAL HOURS
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						{isTimesheetEntriesLoading ? (
							<Loading />
						) : (
							<Box mt={3}>
								<Box
									display='flex'
									alignItems='center'
									justifyContent='flex-end'
								>
									<Box className={timesheetStyle.timesheetHoursContainer}>
										<Box className={timesheetStyle.hoursContainer}>
											{timesheet?.timesheetPeriod &&
												timesheet?.timesheetPeriod.map((date, index) => (
													<Box
														key={index}
														className={
															pendingApprovaDetailsStyles.navigationButtonLabelContainer
														}
													>
														<Typography
															className={`${
																timesheetStyle.dayNavigationButtonLabel
															} ${
																getTotalHoursByDay(date) === '00:00' &&
																pendingApprovaDetailsStyles.noEntryHours
															}`}
														>
															<Box display='flex'>
																<Box>
																	{dayjs(date).format('ddd').toUpperCase()}
																</Box>
																<Box>
																	{excludedLeaves?.length > 0 &&
																		excludedLeaves?.map(
																			(leave: ExcludedLeaveProps) => {
																				if (date === leave?.leaveDate) {
																					return (
																						<Box
																							className={
																								timesheetStyle.leaveCircle
																							}
																						>
																							<Tooltip
																								title={leave?.name}
																								placement='right'
																							>
																								<div>L</div>
																							</Tooltip>
																						</Box>
																					);
																				}
																			}
																		)}
																</Box>
																<Box>{checkHoliday(date)}</Box>
															</Box>
														</Typography>
														<Tooltip
															title={dayjs(date).format(dateFormat)}
															placement='right'
														>
															<Box
																className={`${timesheetStyle.dayNavigationButtonLabel} 
																		${pendingApprovaDetailsStyles.date}
																		${ellipsisStyles.ellipsis}
																		`}
																position={'relative'}
																right={'15px'}
															>
																{dayjs(date).format(dateFormat)}
															</Box>
														</Tooltip>
													</Box>
												))}
										</Box>
										<Box className={timesheetStyle.totalHoursContainer}>
											<Typography className={timesheetStyle.totalHours}>
												{timesheet?.totalHours}
											</Typography>
											<Typography className={timesheetStyle.totalHoursLabel}>
												TOTAL HOURS
											</Typography>
										</Box>
									</Box>
								</Box>

								<Box>
									<Box
										className={pendingApprovaDetailsStyles.timesheetsContainer}
									>
										{submittedTimesheet &&
											submittedTimesheet.map((timesheetEntry, index) => (
												<Box
													key={index}
													className={
														pendingApprovaDetailsStyles.timesheetContentContainer
													}
												>
													<Box className={timesheetStyle.timesheetContent}>
														<Tooltip
															title={`${timesheetEntry?.projectName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.projectName} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.projectName || '- -'}
															</Typography>
														</Tooltip>
														<Tooltip
															title={`${timesheetEntry?.companyName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.companyName} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.companyName || '- -'}
															</Typography>
														</Tooltip>
														<Tooltip
															title={`${timesheetEntry?.taskName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.task} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.taskName || '- -'}
															</Typography>
														</Tooltip>
													</Box>
													<Box
														className={timesheetStyle.timesheetHoursContainer}
													>
														<Box className={timesheetStyle.hoursContainer}>
															{timesheet?.timesheetPeriod?.map(
																(date, index) => {
																	const taskWorkingHours =
																		timesheetEntry.timesheets.filter(
																			(timesheet) => timesheet.date === date
																		);
																	const workingHours =
																		taskWorkingHours?.length > 0
																			? taskWorkingHours
																					.map((taskHoursAndMinutes) =>
																						taskHoursAndMinutes.working_hours.split(
																							':'
																						)
																					)
																					.map((hoursAndMinutes) =>
																						dayjs.duration({
																							hours: Number(hoursAndMinutes[0]),
																							minutes: Number(
																								hoursAndMinutes[1]
																							),
																							seconds: Number(
																								hoursAndMinutes[2]
																							),
																						})
																					)
																					.reduce(
																						(total, durations) =>
																							total.add(durations),
																						dayjs.duration({
																							hours: 0,
																							minutes: 0,
																							seconds: 0,
																						})
																					)
																					.format('HH:mm')
																			: 0;
																	const notes = taskWorkingHours?.map(
																		(note) => note?.notes
																	);
																	return (
																		<Box display={'flex'} key={index}>
																			{workingHours ? (
																				<Box display='flex'>
																					<Typography
																						className={
																							timesheetStyle.dayNavigationButtonLabel
																						}
																					>
																						{workingHours}
																					</Typography>
																					<Box>
																						<Tooltip
																							title={notes || '- -'}
																							placement='top'
																							arrow
																						>
																							<IconButton
																								className={
																									timesheetStyle.iconContainer
																								}
																							>
																								<SpeakerNotes
																									className={
																										timesheetStyle.icon
																									}
																								/>
																							</IconButton>
																						</Tooltip>
																					</Box>
																				</Box>
																			) : (
																				<Typography
																					className={
																						pendingApprovaDetailsStyles.noEntryHours
																					}
																				>
																					00:00
																				</Typography>
																			)}
																		</Box>
																	);
																}
															)}
														</Box>
														<Box className={timesheetStyle.totalHoursContainer}>
															<Typography className={timesheetStyle.totalHours}>
																{getTaskTotalHours(
																	timesheetEntry.timesheets.map(
																		(taskHours) => taskHours.working_hours
																	)
																)}
															</Typography>
														</Box>
													</Box>
												</Box>
											))}
									</Box>
								</Box>
							</Box>
						)}
						<Box className={pendingApprovaDetailsStyles.timesheetsContainer}>
							<Box className={timesheetStyle.timesheetTotalHoursContainer}>
								<Box className={timesheetStyle.timesheetContent}>
									<Typography className={timesheetStyle.totalHoursRowData}>
										TOTAL HOURS
									</Typography>
								</Box>
								<Box className={timesheetStyle.timesheetHoursContainer}>
									<Box className={timesheetStyle.hoursContainer}>
										{timesheet?.timesheetPeriod &&
											timesheet?.timesheetPeriod.map((date, index) => (
												<Box
													key={index}
													className={
														pendingApprovaDetailsStyles.navigationButtonLabelContainer
													}
												>
													<Typography
														className={`${timesheetStyle.totalHoursRowData} ${
															getTotalHoursByDay(date) === '00:00' &&
															timesheetStyle.noEntryTotalHoursRowData
														}`}
													>
														{getTotalHoursByDay(date)}
													</Typography>
												</Box>
											))}
									</Box>
									<Box className={timesheetStyle.totalHoursContainer}>
										<Typography className={timesheetStyle.totalHours}>
											{timesheet?.totalHours || '00:00'}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Dialog>
			</ThemeProvider>
		</>
	);
};

export default TimesheetHistoryDetailModal;
