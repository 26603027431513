import React, { useContext, useEffect, useState } from 'react';
import {
	createTheme,
	ThemeProvider,
	Box,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { BooleanInput, useNotify, useRefresh } from 'react-admin';
import { settingsStyle } from '../settingsStyle';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import { Form } from 'react-final-form';
import { ENABLE_OVERTIME_CONFIRMATION } from '../constant';
import { UserProfileContext } from '../../../App';
import {
	useGetTimesheetConfigQuery,
	useUpdateTimesheetSettingsMutation,
} from '../../../generated/graphql';
import { head } from 'lodash';

const timesheetWeekSettingsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});

const timesheetWeekSeetingsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

function TimesheetWeeklyEntriesSettings() {
	const { orgId } = useContext<any>(UserProfileContext);

	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isTimesheetWeekViewEnabled, setIsTimesheetWeekViewEnabled] =
		useState<boolean>(false);
	const timesheetWeekSettingsStyles = timesheetWeekSettingsStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: timesheetSettings, refetch: refetchTimesheetSettings } =
		useGetTimesheetConfigQuery();

	const [updateTimesheetSettings] = useUpdateTimesheetSettingsMutation();

	useEffect(() => {
		if (!timesheetSettings) {
			return;
		}
		setIsTimesheetWeekViewEnabled(
			head(timesheetSettings?.timesheet_settings)?.enable_weekly_entry || false
		);
	}, [timesheetSettings]);

	const updateTimesheetWeekViewSettings = () => {
		updateTimesheetSettings({
			variables: {
				data: {
					enable_weekly_entry: isTimesheetWeekViewEnabled,
				},
				orgId: orgId,
			},
		})
			.then((res) => {
				if (!res.errors) {
					refetchTimesheetSettings();
					notify('Timesheet settings updated successfully');
					refresh();
					onWarningModalClose();
				}
			})
			.catch((err) => {
				notify('Failed', 'warning');
			});
	};

	const handleChange = (value: boolean) => {
		setIsTimesheetWeekViewEnabled(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsTimesheetWeekViewEnabled(!isTimesheetWeekViewEnabled);
		setIsWarningModalShown(false);
	};
	return (
		<>
			<Box marginBottom='10px'>
				<Form
					onSubmit={updateTimesheetWeekViewSettings}
					initialValues={{
						enable_weekly_entry: isTimesheetWeekViewEnabled,
					}}
				>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<ThemeProvider theme={timesheetWeekSeetingsTheme}>
								<div className={timesheetWeekSettingsStyles.container}>
									<Box
										className={settingsStyles.inputContainer}
										display='flex'
										flexDirection='column'
									>
										<>
											<div className={settingsStyles.inputContainer}>
												<BooleanInput
													label='Enable timesheet week view'
													source='enable_weekly_entry'
													onChange={handleChange}
												/>
											</div>
											<Typography
												className={timesheetWeekSettingsStyles.description}
											>
												Enabling this will allow users to enter timesheet in
												week view.
											</Typography>
										</>
									</Box>
								</div>
							</ThemeProvider>
						</form>
					)}
				</Form>
			</Box>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => {
					updateTimesheetWeekViewSettings();
				}}
			/>
		</>
	);
}

export default TimesheetWeeklyEntriesSettings;
