import React, { useContext } from 'react';

import { Card } from '@material-ui/core';
import {
	Draggable,
	DraggableProvided,
	DraggableRubric,
	DraggableStateSnapshot,
	Droppable,
} from 'react-beautiful-dnd';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	KanbanCardChildren,
	KanbanColumnView,
	GetKanbanColumnIdFn,
} from './kanban.model';
import { KanbardCardItem } from './KanbanCard.component';
import { formatDecimal, getCurrencyFormat } from '../../Utils/string.util';
import { UserProfileContext } from '../../App';
import { GET_ORGANISATION } from '../Opportunities/Opportunities.gql';
import head from 'lodash/head';
import { OrganisationBaseCurrency } from '../../Reports/talent/Reports.model';

import { FixedSizeList as VList, areEqual } from 'react-window';
import './kanbanComponent.css';
interface TotalValue {
	name: string;
	sum: number;
}
const Row = React.memo(({ index, style, data }: any) => {
	const { cards, children } = data;
	if (!cards[index]) {
		// console.log('undefined index', cards, index);
		return null;
	}
	return (
		<Draggable
			draggableId={cards[index]?.id}
			key={cards[index]?.id}
			index={index}
		>
			{(provided, snapshot) => (
				<KanbardCardItem
					key={cards[index].id}
					index={index}
					provided={provided}
					snapshot={snapshot}
					card={cards[index]}
					style={style}
				>
					{children}
				</KanbardCardItem>
			)}
		</Draggable>
	);
}, areEqual);

export const KanbanColumn = ({
	column,
	children,
	onAddCardToColumn,
	totalValue,
}: {
	column: KanbanColumnView;
	children: KanbanCardChildren;
	onAddCardToColumn: GetKanbanColumnIdFn;
	totalValue?: TotalValue[];
}) => {
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const { data: organisation } = useApolloQuery<OrganisationBaseCurrency>(
		GET_ORGANISATION,
		{
			variables: { organizationId },
		}
	);
	return (
		<div className={'kanbanColumnRoot'}>
			<div className='kanbanColumContainer'>
				<p className={'columnHeader'}>{column?.name?.toUpperCase()}</p>
				<AddBoxOutlinedIcon
					style={{
						color: '#5c5c5c',
						marginRight: '8px',
						fontSize: '18px',
						cursor: 'pointer',
					}}
					onClick={() => {
						onAddCardToColumn(column.id);
					}}
				/>
			</div>
			<Droppable
				droppableId={column.id}
				mode='virtual'
				renderClone={(
					provided: DraggableProvided,
					snapshot: DraggableStateSnapshot,
					rubric: DraggableRubric
				) => {
					return (
						<KanbardCardItem
							index={rubric.source.index}
							provided={provided}
							snapshot={snapshot}
							card={column?.cards[rubric.source.index]}
						>
							{children}
						</KanbardCardItem>
					);
				}}
			>
				{(droppableProvided, snapshot) => {
					const itemCount: number = snapshot.isUsingPlaceholder
						? column?.cards.length + 1
						: column?.cards.length;
					return (
						<VList
							height={688}
							itemCount={itemCount}
							itemSize={116}
							width={'100%'}
							outerRef={droppableProvided.innerRef}
							itemData={{ cards: column?.cards, children }}
						>
							{Row}
						</VList>
					);
				}}
			</Droppable>
			{totalValue && (
				<Card
					style={{
						marginLeft: '-4px',
						height: '45px',
						position: 'relative',
						marginRight: '-5px',
						display: 'flex',
						alignItems: 'center',
						minWidth: '260px',
						borderRadius: '8px',
						justifyContent: 'flex-end',
						padding: '0px 14px 0px 14px',
						background: '#d3dcea80',
						boxShadow: '0px 0px 0px 0px !important',
					}}
				>
					<div className={'kanbanColumnFooterContainer'}>
						<p className={'totalLabel'}>TOTAL REVENUE</p>
						<p className={'totalValue'}>
							{getCurrencyFormat(
								totalValue?.length > 0
									? formatDecimal(totalValue[0]?.sum, 2)
									: 0,
								head(organisation?.organization)?.currency?.currency_type,
								true
							)}
						</p>
					</div>
				</Card>
			)}
		</div>
	);
};
