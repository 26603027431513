import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { modalFormTheme } from '../Layout/Theme.component';
import { modalFormStyle } from '../Layout/styles';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '125px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '126px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
	},
}));

interface Props {
	onClose: () => void;
	open: boolean;
	onDelete: () => void;
	confirmationMessage?: string;
	heading?: string;
	isLoading?: boolean;
	loaderColor?: string;
}

const DeleteModal = (props: Props) => {
	const {
		open,
		onClose,
		onDelete,
		confirmationMessage,
		heading,
		isLoading = false,
		loaderColor,
	} = props;
	const classes = modalFormStyle();
	const containerClass = useStyles();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				{heading ? <DialogTitle>{heading}</DialogTitle> : null}
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						{confirmationMessage
							? confirmationMessage
							: 'Do you really want to delete this record?'}
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button onClick={onClose} className={containerClass.cancelButton}>
								Cancel
							</Button>
							<Button
								className={
									isLoading
										? containerClass.disabledButton
										: containerClass.saveButton
								}
								onClick={onDelete}
								type='submit'
								disabled={isLoading}
								startIcon={
									isLoading && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: loaderColor ? loaderColor : '#4285F4',
											}}
										/>
									)
								}
							>
								Delete
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};
export default DeleteModal;
