import React, { useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	useRefresh,
	useMutation,
	regex,
	email,
	maxLength,
} from 'react-admin';
import { Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import {
	validatePhoneNumber,
	validateWebsite,
} from '../../../Utils/string.util';
import CloseIcon from '@material-ui/icons/Close';

import { useParams } from 'react-router-dom';
import { contactChoices } from '../Constant';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';

interface Form {
	first_name?: string;
	last_name?: string;
	title?: string;
	email?: string;
	company_id?: string;
	primary_phone?: string;
	alternate_phone?: string;
	contact_type?: string;
	owner_id?: string;
	linkedin?: string;
	address?: string;
	background?: string;
}

interface Props {
	onClose: () => void;
	isOpen: boolean;
	record: any;
}

const CompanyEditContactForm = (props: Props) => {
	const { onClose, isOpen, record: contact } = props;
	const { id }: { id: string } = useParams();
	const notify = useNotify();
	const refresh = useRefresh();
	const [contactForm, setContactForm] = useState<Form>({});
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const classes = modalFormStyle();
	const [mutate] = useMutation();

	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		setContactForm({
			...contact,
		});
		setOwnerId(contact?.owner_id || null);
	}, [contact, id]);

	const onSave = () => {
		const alternateNum = contactForm?.alternate_phone
			? contactForm?.alternate_phone
			: null;
		mutate(
			{
				type: 'update',
				resource: 'crm_contacts',
				payload: {
					id: contact?.id,
					data: {
						...contactForm,
						alternate_phone: alternateNum,
						company_id: id,
						owner_id: ownerId || null,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					onClose();
					notify('Contact details updated successfully');
					refresh();
				},
				onFailure: (error) => {
					onClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
		return;
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={isOpen}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>Edit Contact </Box>
						<CloseIcon className={classes.closeIcon} onClick={onClose} />
					</Box>
					<Form initialValues={contact} onSubmit={onSave}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.label}>First Name *</Box>
										<TextInput
											source='first_name'
											fullWidth={true}
											label={false}
											validate={required()}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													first_name: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Last Name </Box>
										<TextInput
											source='last_name'
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													last_name: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Title</Box>
										<TextInput
											source='title'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													title: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Email </Box>
										<TextInput
											source='email'
											sortable={false}
											label={false}
											validate={[email()]}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													email: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Address</Box>
										<TextInput
											source='address'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													address: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}> Phone 1 </Box>
										<TextInput
											source='primary_phone'
											sortable={false}
											label={false}
											fullWidth={true}
											validate={[
												regex(validatePhoneNumber, 'Must be a number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													primary_phone: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}> Phone 2</Box>
										<TextInput
											source='alternate_phone'
											name='alternate_phone'
											label={false}
											validate={[
												regex(validatePhoneNumber, 'Must be a number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											fullWidth
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													alternate_phone: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Background</Box>
										<TextInput
											source='background'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													background: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Contact Type</Box>
										<Box flex={1}>
											<SelectInput
												source='contact_type'
												resettable={true}
												label={false}
												fullWidth
												choices={contactChoices}
												onChange={(e: any) => {
													setContactForm({
														...contactForm,
														contact_type: e?.target?.value,
													});
												}}
											/>
										</Box>
										<Box className={classes.label}>BD OWNER</Box>
										<AutocompleteSearch
											placeholder={'Search Owner'}
											option={
												activeUsers && activeUsers?.user
													? activeUsers?.user?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setOwnerId}
											value={getOwnerValue(ownerId || '')}
											name={'owner_id'}
											validates={false}
										/>
										<Box className={classes.label}>Linkedin</Box>
										<TextInput
											source='linkedin'
											fullWidth={true}
											label={false}
											validate={[regex(validateWebsite, 'Must be a valid URL')]}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													linkedin: e?.target?.value,
												});
											}}
										/>
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={onClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid ? classes.disabledButton : classes.saveButton
												}
												disabled={invalid}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CompanyEditContactForm;
