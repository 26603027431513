import {
	TextField as TextComponent,
	ThemeProvider,
	Checkbox,
	Box,
	Typography,
	Tooltip,
} from '@material-ui/core';
import './projectKanban.css';

import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchIcon } from '../../assets/SvgIcons';
import { autocompleteStyle, dropDownEllipsisStyle } from '../../Layout/styles';
import { customAutoCompleteSearchBarTheme } from '../../Layout/Theme.component';
import { searchBarTheme } from '../CrmTask/CrmTasksKanbanContainer';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { MileStoneKanban } from '../Projects/Milestones/MilestonesKanban.component';
import { ProjectTaskContext } from './ProjectTaskMilestoneContainer';
import { Form } from 'react-final-form';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import { orderBy, uniqBy } from 'lodash';
import { useGetProjectResourceMappingListQuery } from '../../generated/graphql';
import { UserProfileContext } from '../../App';

interface projectMilestoneKanbanProps {
	projectId: string;
	option: any;
}

const ProjectMilestoneKanban = (props: projectMilestoneKanbanProps) => {
	const { projectId, option } = props;
	const { projecTaskFiltersState, dispatchProjectTaskFilters, ACTIONS } =
		useContext<any>(ProjectTaskContext);
	const { featureFlag } = useContext<any>(UserProfileContext);
	const projectKanbanRef = useRef<any>();
	const dropDownStyles = autocompleteStyle();
	const styles = dropDownEllipsisStyle();
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any[]>(
		[]
	);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const dropDownStyle = autocompleteStyle();
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});
	const { data: projectAssignedResourceList } =
		useGetProjectResourceMappingListQuery({
			variables: {
				projectResourceMappingFilter: {
					project_id: { _eq: projectId },
					deleted_at: { _is_null: true },
				},
			},
			fetchPolicy: 'network-only',
			skip: !projectId,
		});
	const getLimits = () => {
		return (
			<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{projecTaskFiltersState?.projectMilestoneTaskFilter?.length === 1
					? projecTaskFiltersState?.projectMilestoneTaskFilter[0].label
					: `Selected ${
							projecTaskFiltersState?.projectMilestoneTaskFilter?.length || 0
					  } items`}
			</div>
		);
	};

	const handleSelectAll = (isSelected: boolean) => {
		if (isSelected) {
			const initialStatusIdList = option.map((value: any) => {
				return {
					value: value?.value,
					label: value?.label,
				};
			});
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
				payload: initialStatusIdList,
			});
		} else {
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
				payload: [],
			});
		}
	};

	const allSelected =
		option.length ===
			projecTaskFiltersState?.projectMilestoneTaskFilter?.length || 0
			? true
			: false;

	const handleToggle = () => {
		handleSelectAll(!allSelected);
	};

	const onProjectStatusChange = (event: any, value: any, reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((value: any) => value.value === 'All')) {
				handleToggle();
				return;
			} else {
				dispatchProjectTaskFilters({
					type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchProjectTaskFilters({
				type: ACTIONS.UPDATE_PROJECT_MILESTONE_TASK_FILTER,
				payload: [],
			});
			return;
		}
	};
	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	useEffect(() => {
		if (!projectAssignedResourceList) {
			return;
		}
		if (selectedUserTeam) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: { user: { id: string; full_name: string } }) => {
					return {
						id: user?.user?.id,
						name: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList = orderBy(
				useTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			setAssigneeRecommendations(sortedAssigneeList);
			return;
		}

		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));
		const uniqueAssignees = uniqBy(
			[...(projectAssignedResources || [])],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [projectAssignedResourceList, selectedUserTeam, usersByTeam]);
	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${styles.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const resetTeamResourceFilter = () => {
		if (!projectAssignedResourceList) {
			return;
		}
		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));
		const uniqueAssignees = uniqBy(projectAssignedResources, function (e) {
			return e.id;
		});
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};
	return (
		<>
			<div style={{ marginTop: '30px' }}>
				<div className={'projectTaskKanbanToolbarContainer'}>
					<div className={'projectTaskKanbanFilterContainer'}>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={searchBarTheme}>
								<div
									style={{
										width: '344px',
									}}
								>
									<TextComponent
										placeholder='Search Tasks'
										label={false}
										fullWidth
										InputLabelProps={{ style: { fontSize: 0 } }}
										InputProps={{
											startAdornment: (
												<SearchIcon
													style={{
														width: '22px',
														height: '28px',
														fontSize: '17px',
														marginTop: '9px',
														display: 'inline-block',
														marginLeft: '14px',
													}}
												/>
											),
										}}
										onChange={(e) => {
											dispatchProjectTaskFilters({
												type: ACTIONS.UPDATE_PROJECT_MILESTONE_SEARCH_TEXT,
												payload: e?.target?.value,
											});
										}}
										value={projecTaskFiltersState?.projectMilestoneSearchText}
									/>
								</div>
							</ThemeProvider>
						</form>
						<div style={{ display: 'inline' }}>
							<div
								style={{
									position: 'relative',
									bottom: '18px',
									left: '14px',
									fontSize: '9px',
								}}
							>
								Status
							</div>
							<div
								className={'projectTaskKanbanAssigneeFilterContainer'}
								style={{
									width: '182px',
									position: 'relative',
									bottom: '14px',
								}}
							>
								<form onSubmit={(event) => event.preventDefault()}>
									<Autocomplete
										multiple
										value={
											projecTaskFiltersState?.projectMilestoneTaskFilter
												? projecTaskFiltersState?.projectMilestoneTaskFilter
												: []
										}
										limitTags={0}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={onProjectStatusChange}
										options={
											option?.length > 0
												? [{ label: 'All', value: 'All' }, ...option]
												: []
										}
										disableCloseOnSelect
										getLimitTagsText={getLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
												<TextComponent
													{...params}
													label=''
													placeholder={
														projecTaskFiltersState?.projectMilestoneTaskFilter &&
														projecTaskFiltersState?.projectMilestoneTaskFilter
															?.length > 0
															? ''
															: 'Search Status'
													}
												/>
											</ThemeProvider>
										)}
										renderOption={(option: any, { selected }) => {
											// To control the state of 'select-all' checkbox
											const selectAllProps =
												option.value === 'All' ? { checked: allSelected } : {};
											return (
												<>
													<Checkbox
														icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
														checkedIcon={<CheckBoxIcon fontSize='small' />}
														style={{ marginRight: 8 }}
														checked={selected || false}
														color='primary'
														{...selectAllProps}
													/>
													<Box
														style={{ fontSize: '12px' }}
													>{`${option?.label}`}</Box>
												</>
											);
										}}
										getOptionLabel={(option: any) => `${option?.label}`}
										getOptionSelected={(option: any, value) =>
											option.value === value.value
										}
									/>
								</form>
							</div>
						</div>
						{featureFlag?.userTeams && (
							<Form onSubmit={() => {}}>
								{() => (
									<>
										<div style={{ display: 'inline' }}>
											<div
												style={{
													position: 'relative',
													bottom: '18px',
													left: '14px',
													fontSize: '9px',
												}}
											>
												User Team
											</div>
											<div
												className={'projectTaskKanbanAssigneeFilterContainer'}
												style={{
													width: '182px',
													position: 'relative',
													bottom: '14px',
												}}
											>
												<form onSubmit={(event) => event.preventDefault()}>
													<AutocompleteSearch
														placeholder={'Search Team'}
														option={
															userTeams && userTeams?.user_team
																? userTeams?.user_team?.map(
																		(userTeam: {
																			id: string;
																			name: string;
																		}) => {
																			return {
																				id: userTeam?.id,
																				name: userTeam?.name,
																			};
																		}
																  ) || []
																: []
														}
														onChange={(id) => {
															if (!id) {
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																resetTeamResourceFilter();
																setSelectedUserTeam(null);
																setUserTeamId(null);
																return;
															}
															setUserTeamId(id);
															setSelectedResourceIdList([]);
															setSelectedResourceOptionValues([]);
															const currentTeam = userTeams?.user_team?.find(
																(team: { id: string; full_name: string }) =>
																	team?.id === id
															);
															setSelectedUserTeam(currentTeam);
														}}
														name={'team_id'}
														value={selectedUserTeam}
													/>
												</form>
											</div>
										</div>
										<div style={{ display: 'inline' }}>
											<div
												style={{
													position: 'relative',
													bottom: '18px',
													left: '14px',
													fontSize: '9px',
												}}
											>
												Resource
											</div>
											<div
												className={'projectTaskKanbanAssigneeFilterContainer'}
												style={{
													width: '182px',
													position: 'relative',
													bottom: '14px',
												}}
											>
												<form onSubmit={(event) => event.preventDefault()}>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															selectedResourceOptionValue &&
															selectedResourceOptionValue?.length > 0
																? selectedResourceOptionValue
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id !== 'All'
																)
																.map(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id
																);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...assigneeRecommendations,
																]);
																const allResourceIds =
																	assigneeRecommendations?.map(
																		(resource: any) => resource?.id
																	);
																setSelectedResourceIdList(allResourceIds);
																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																assigneeRecommendations?.length !==
																	selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id !== 'All'
																);
																setSelectedResourceOptionValues(currentOptions);
																const currentIds = currentOptions?.map(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id
																);
																setSelectedResourceIdList(currentIds);
																return;
															}
															if (
																selectedIds?.length ===
																	assigneeRecommendations?.length &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...assigneeRecommendations,
																]);
																const reporteeIds =
																	assigneeRecommendations?.map(
																		(resource: any) => resource?.id
																	);
																setSelectedResourceIdList(reporteeIds);
																return;
															}
															if (
																selectedResourceOptionValue.find(
																	(option: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																return;
															}
															const selectedId = value?.map(
																(resource) => resource?.id
															);
															setSelectedResourceIdList(selectedId);
															setSelectedResourceOptionValues(value);
														}}
														options={
															assigneeRecommendations &&
															assigneeRecommendations?.length > 0
																? [
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																  ]
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getResourceFilterLimits}
														ChipProps={{ style: { display: 'none' } }}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextComponent {...params} label='' />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected}
																	color='primary'
																/>

																<Tooltip
																	title={`${option?.name || '- -'}`}
																	placement='right'
																>
																	<Typography className={`${styles.ellipsis}`}>
																		{`${option?.name || '- -'}`}
																	</Typography>
																</Tooltip>
															</>
														)}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) =>
															option.id === value.id
														}
													/>
												</form>
											</div>
										</div>
									</>
								)}
							</Form>
						)}
					</div>
				</div>
			</div>

			<MileStoneKanban
				ref={projectKanbanRef}
				projectId={projectId}
				searchedText={projecTaskFiltersState?.projectMilestoneSearchText}
				selectedStatus={projecTaskFiltersState?.projectMilestoneTaskFilter?.map(
					(value: any) => value?.value
				)}
				resourceList={selectedResourceIdList}
			/>
		</>
	);
};

export default ProjectMilestoneKanban;
