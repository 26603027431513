export const MILESTONE_UNABLE_TO_CHANGE_STATUS =
	'In progress task in milestone';

export const ALL_OPTION = 'All';

export const RESOURCE_ALLOCATION_START_DATE_VALIDATION_MESSAGE = `Allocated start date must be greater than Resource joining date`;
export interface ProjectPlannedCostAndHoursInterface {
	total_hours: number | null;
	total_cost: number | null;
}

export const RESOURCE_EXPORT_COLUMNS = [
	'RESOURCE NAME',
	'START DATE',
	'END DATE',
	'COST',
	'HOURS',
	'ALLOCATION%',
	'BILLABLE',
	'PARTIAL',
	'TAG'
];

export type ProjectResourceMappingType = {
	user: {
		full_name: string;
	};
	start_date?: string;
	end_date?: string;
	total_cost: number;
	total_hours: number;
	allocation_percentage: number;
	is_billable: boolean;
	is_partial: boolean;
	project_resource_tag: {
		name: string
	}
};

export type ProjectResourceMappingItem = {
	id: number;
	created_at: string;
	employee_id?: number;
	start_date: string;
	end_date: string;
	user?: {
		full_name: string;
		// Add other properties of the 'user' object if needed
	};
	total_hours: number;
	total_cost: number;
	is_partial: boolean;
	is_billable: boolean;
	project_id?: number;
	resource_cost?: number;
	allocation_percentage?: number;
	client_rate?: number;
	tag_id: string | null;
	project_resource_tag: {
		name: string;
	};
	action: {
		id: number;
		created_at: string;
		employee_id?: number;
		start_date: string;
		end_date: string;
		user?: {
			full_name: string;
			// Add other properties of the 'user' object if needed
		};
		total_hours: number;
		total_cost: number;
		is_partial: boolean;
		is_billable: boolean;
		project_id?: number;
		resource_cost?: number;
		allocation_percentage?: number;
		allocation_start_date?: string;
		allocation_end_date?: string;
	};
};
