import React, { useEffect } from 'react';

import { Loading } from 'react-admin';
import { EmployeeDetailsCard } from './EmployeeDetailsCard.component';
import { EmployeeDetailContainer } from './EmployeeDetailContainer.component';

import { GET_EMPLOYEE, GET_EMPLOYEE_CUSTOM_FIELDS } from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadcumData } from '../constant';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles((theme) => ({
	detailContainer: {
		display: 'flex',
	},
	leftPanel: {
		marginRight: '16px',
	},
	rightPanel: {
		width: '100%',
	},
	toolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '60px',
		alignItems: 'center',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		cursor: 'pointer',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
		cursor: 'pointer',
	},
	arrowIcon: {
		color: '#5C5C5C',
		padding: '0px 7px',
	},
}));
export const EmployeeDetails = (props) => {
	const classes = useStyles();
	const { id } = props?.match?.params;
	const {
		data,
		loading,
		refetch: refetchEmployee,
	} = useApolloQuery(GET_EMPLOYEE, {
		variables: {
			employeeId: id ? id : null,
		},
		fetchPolicy: 'network-only',
	});

	const { data: employeeCustomFields } = useApolloQuery(
		GET_EMPLOYEE_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	useEffect(() => {
		refetchEmployee();
	}, [refetchEmployee]);

	if (loading) {
		return <Loading />;
	}

	const { user } = data?.employee;
	return (
		<>
			<Breadcrum
				data={[...breadcumData, { name: `${user?.full_name} ` }]}
				icon={DoubleArrowIcon}
			/>
			<div className={classes.detailContainer}>
				<div className={classes.leftPanel}>
					<EmployeeDetailsCard
						record={data?.employee}
						basePath='/employee'
						resource='employee'
						{...props}
						refetch={() => {
							refetchEmployee();
						}}
					/>
				</div>

				<div className={classes.rightPanel}>
					<EmployeeDetailContainer
						record={{ ...data?.employee, employeeCustomFields }}
						basePath='/employee'
						resource='employee'
						{...props}
						refetch={() => {
							refetchEmployee();
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default EmployeeDetails;
