import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Form, Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import {
	Button,
	Box,
	InputAdornment,
	makeStyles,
	Typography,
	withStyles,
	TextField,
	CircularProgress,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	modalFormTheme,
	autoCompleteTheme,
	projectTaskAutoCompleteArrayInputTheme,
} from '../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogActions,
	AutocompleteSearchStyles,
} from '../../Layout/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { TiptapEditor } from '../../SharedComponents/Tiptaptexteditor.component';
import { useMutation as useApolloMutation } from '@apollo/client';

import {
	TextInput,
	required,
	RadioButtonGroupInput,
	minLength,
	SelectInput,
	useNotify,
	NumberInput,
	minValue,
	maxValue,
	useQuery,
	number,
	AutocompleteInput,
	ReferenceInput,
	AutocompleteArrayInput,
	AutocompleteArrayInputProps,
} from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	useCreateProjectTaskMutation,
	useGetProjectAssignedUsersQuery,
	useGetProjectTaskStatusQuery,
	useUpdateProjectTaskByIdMutation,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { ProjectTask } from './task.modal';
import {
	SUB_TASK_CREATE_MESSAGE,
	SUB_TASK_UPDATE_MESSAGE,
	TASK_CREATE_MESSAGE,
	TASK_NAME_VALIDATION_MESSAGE,
	TASK_UPDATE_MESSAGE,
	CREATION_FAILED,
	BILLABLE_TOOLTIP_LABEL,
	ASSIGNED_TO_TOOLTIP_LABEL,
	REMINDER_TOOLTIP_LABEL,
	ESTIMATED_HOUR_TOOLTIP_LABEL,
} from './Constant';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { SearchIcon } from '../../assets/SvgIcons';
import { CREATE_PROJECT_CATEGORY } from '../ResourcePlanner/gqlQueries';
import { head, isEmpty, orderBy } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

const taskFormStyle = makeStyles({
	container: {
		padding: '15px',
		borderRadius: '4px',
		overflow: 'hidden',
	},
	saveButton: {
		marginRight: '10px',
	},
	floatInput: {
		width: '30.33%',
	},
	formContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	floatingButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '26px',
		marginBottom: '15px',
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		color: '#292929',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
	},
	icon: {
		width: '16px',
		height: '16px',
		marginTop: '-2px',
	},
});

const DialogContent = withStyles(() => ({
	root: {
		maxHeight: '70vh',
		padding: '0px 10px 0px 0px',
		width: '850px',
	},
}))(MuiDialogContent);

interface Status {
	id?: string;
	name?: string;
	state?: string;
}
interface User {
	id: string;
	first_name: string;
	full_name: string;
	last_name: string;
}
interface Team {
	user: User;
}
interface Props {
	onClose: () => void;
	open: boolean;
	kanbanColumnId?: string;
	boardId: string | null;
	parentTaskId?: string;
	onSuccess: (value: boolean) => void;
	taskDetails?: ProjectTask;
	isEdit?: boolean;
	mileStoneId?: string | null;
	onCreateTask: (value: any) => void;
	TimesheetProjectId?: string | null;
	timesheetTaskName?: string | null;
}
interface TaskFormValue {
	title?: string | null;
	planned_start_date?: string;
	planned_end_date?: string;
	is_billable?: boolean;
	status?: string;
	project_milestone_id?: string | null;
	completion_percentage?: number;
	reminder?: string;
	planned_hours?: number | undefined | string;
	priority?: string;
	assigned_to?: string[];
	id?: string;
	cardId?: string;
	category_id?: string;
}
const CreateTaskForm = (props: Props) => {
	const {
		onClose,
		open,
		kanbanColumnId,
		boardId,
		parentTaskId,
		onSuccess,
		taskDetails,
		isEdit,
		mileStoneId,
		onCreateTask,
		TimesheetProjectId,
		timesheetTaskName,
	} = props;
	const notify = useNotify();
	const { id: projectId } = useParams<{ id: string }>();
	const [projectTeamMembers, setProjectTeamMembers] = useState<any>();
	const [initialFormValues, setInitialFormValues] = useState<TaskFormValue>();
	const [projectStatusList, setProjectStatusList] = useState<
		Status[] | undefined
	>([]);
	const [description, setDescription] = useState<string>('');
	const classes = modalFormStyle();
	const taskStyles = taskFormStyle();
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const [isSaveClicked, setIsSaveClicked] = useState(false);
	const [startDateGreaterError, setStartDateGreaterError] = useState(false);
	const [durationGreaterError, setDurationGreaterError] = useState(false);
	const [taskEditDetails, setTaskDetails] = useState<any>();
	const [assignedTo, setAssigneTo] = useState<string[] | null>(userId);
	const [projectMilestoneId, setProjectMilestoneId] = useState<string | null>(
		null
	);
	const [kanbanBoardId, setKanbanBoardId] = useState<string | null>(null);
	const { data: projectMembers } = useGetProjectAssignedUsersQuery({
		variables: { project_id: projectId || TimesheetProjectId },
	});
	const [
		isProjectCategoryUniqueViolationError,
		setIsProjectCategoryUniqueViolationError,
	] = useState(false);
	const [isProjectCategoriesLoading, setIsProjectCategoriesLoading] =
		useState(false);
	const [createProjectCategory] = useApolloMutation(CREATE_PROJECT_CATEGORY);

	const { data: projectTaskStatusList } = useGetProjectTaskStatusQuery({
		variables: {
			id: kanbanBoardId,
		},
		skip: !kanbanBoardId,
		onError: () => setProjectStatusList([]),
		fetchPolicy: 'network-only',
	});
	const { data: project } = useQuery({
		type: 'getOne',
		resource: 'project',
		payload: { id: projectId || TimesheetProjectId },
	});

	const { data: milestones } = useQuery({
		type: 'GET_LIST',
		resource: 'project_milestone',
		payload: {
			sort: { field: 'name', order: 'ASC' },
			filter: { project_id: projectId || TimesheetProjectId || null },
		},
	});

	const [createProjectTask, { loading: isAddProjectTaskLoading }] =
		useCreateProjectTaskMutation();
	const [updateProjectTask, { loading: isUpdateProjectTaskLoading }] =
		useUpdateProjectTaskByIdMutation();

	const getMilestoneOptions = () => {
		const response = milestones?.map((value: any) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		return response;
	};

	useEffect(() => {
		if (!boardId) {
			return;
		}
		setKanbanBoardId(boardId);
	}, [boardId]);
	useEffect(() => {
		setProjectTeamMembers(projectMembers);
	}, [open, projectMembers]);
	const teamMembers = projectTeamMembers?.project_resource_mapping?.map(
		(user: Team) => ({
			id: user?.user?.id,
			name: `${user?.user?.full_name} `,
		})
	);
	const sortedTeamMembers = orderBy(
		teamMembers,
		[(user) => user?.name?.toUpperCase()],
		['asc']
	);
	const isLoggedInUserIsResource = teamMembers?.find(
		(member: { id: string; name: string }) => member?.id === userId
	)
		? true
		: false;
	useEffect(() => {
		const defaultStatus = projectTaskStatusList?.kanban_column?.find(
			(status: any) => status?.ordinal === 1
		);
		setInitialFormValues({
			planned_start_date: String(dayjs().format('YYYY-MM-DD')),
			planned_end_date: String(dayjs().add(5, 'day').format('YYYY-MM-DD')),
			completion_percentage: 0,
			priority: 'Medium',
			status: defaultStatus?.id,
			project_milestone_id: mileStoneId,
			title: timesheetTaskName,
			...(isLoggedInUserIsResource && { assigned_to: [userId] }),
		});
		if (isLoggedInUserIsResource) setAssigneTo([userId]);
		setProjectMilestoneId(mileStoneId || null);
	}, [
		projectTaskStatusList,
		taskDetails,
		userId,
		mileStoneId,
		timesheetTaskName,
		isLoggedInUserIsResource,
	]);

	useEffect(() => {
		setTaskDetails(taskDetails);
		if (taskDetails?.assigned_to) {
			setAssigneTo([taskDetails?.assigned_to] || []);
		}
		setProjectMilestoneId(taskDetails?.project_milestone_id || null);
	}, [taskDetails]);

	useEffect(() => {
		if (!boardId && !projectTaskStatusList) {
			return;
		}
		const statusList = projectTaskStatusList?.kanban_column?.map(
			(status: any) => {
				return {
					id: status?.id,
					name: status?.name,
					state: status?.state,
				};
			}
		);

		setProjectStatusList(statusList);
		if (kanbanColumnId) {
			setInitialFormValues((previousValues: any) => ({
				...previousValues,
				status: kanbanColumnId,
			}));
			return;
		}
	}, [kanbanColumnId, boardId, projectTaskStatusList, taskDetails]);

	const closeModal = () => {
		setIsSaveClicked(false);
		setInitialFormValues({});
		setTaskDetails({});
		setProjectStatusList([]);
		setKanbanBoardId(null)
		const defaultStatus = projectTaskStatusList?.kanban_column?.find(
			(status: any) => status?.ordinal === 1
		);
		setInitialFormValues({
			planned_start_date: String(dayjs().format('YYYY-MM-DD')),
			planned_end_date: String(dayjs().add(5, 'day').format('YYYY-MM-DD')),
			completion_percentage: 0,
			priority: 'Medium',
			assigned_to: [userId],
			status: defaultStatus?.id,
			project_milestone_id: mileStoneId || null,
		});
		if (isLoggedInUserIsResource) setAssigneTo([userId]);
		setProjectMilestoneId(mileStoneId || null);
		setStartDateGreaterError(false);
		setDurationGreaterError(false);
		setDescription('');
		onClose();
	};
	const onTaskCreation = (value: TaskFormValue) => {
		if (dayjs(value?.planned_start_date).isAfter(value?.planned_end_date)) {
			setStartDateGreaterError(true);
			return;
		}
		if (
			project?.effort_estimate &&
			value?.planned_hours &&
			value?.planned_hours > project?.effort_estimate
		) {
			setDurationGreaterError(true);
			return;
		}
		setIsSaveClicked(true);
		const {
			reminder,
			status,
			id,
			project_milestone_id,
			cardId,
			...resFormValues
		} = value;
		if (isEdit) {
			updateProjectTask({
				variables: {
					task: {
						...resFormValues,
						parent_task_id: parentTaskId || null,
						reminder: value?.reminder || null,
						project_milestone_id: projectMilestoneId || null,
						description: description || null,
						assigned_to: assignedTo,
						category_id: value?.category_id || null,
						status: value?.status,
					},
					id: taskDetails?.id,
					cardId: taskDetails?.cardId,
					kanbanData: {
						column_id: value?.status,
					},
				},
			}).then((response: any) => {
				if (!response.errors) {
					notify(parentTaskId ? SUB_TASK_UPDATE_MESSAGE : TASK_UPDATE_MESSAGE);
					setIsSaveClicked(false);
					closeModal();
				}
			});
			return;
		}
		const queryPayload =
			assignedTo?.map((userId) => {
				return {
					...resFormValues,
					project_id: projectId || TimesheetProjectId,
					parent_task_id: parentTaskId || null,
					reminder: value?.reminder || null,
					project_milestone_id: projectMilestoneId || null,
					description: description || null,
					assigned_to: userId,
					category_id: value?.category_id || null,
					status: value?.status,
					project_kanban_cards: {
						data: [
							{
								column_id: value?.status,
								ordinal: 1,
							},
						],
					},
				};
			}) || [];
		createProjectTask({
			variables: {
				data: queryPayload,
			},
		})
			.then((response) => {
				if (!response.errors) {
					const taskId =
						head(response?.data?.insert_project_task?.returning)?.id || null;
					if (taskId) {
						onCreateTask(
							head(response?.data?.insert_project_task?.returning)?.id || null
						);
					}
					onSuccess(true);
					notify(parentTaskId ? SUB_TASK_CREATE_MESSAGE : TASK_CREATE_MESSAGE);
					closeModal();
					setIsSaveClicked(false);
					onSuccess(false);
				}
			})
			.catch((error: string) => {
				if (error) {
					notify(CREATION_FAILED, 'warning');
					setIsSaveClicked(false);
				}
			});
	};

	const validateTaskFormValues = async () => {
		const projectTaskErrors = {} as TaskFormValue;
		if (startDateGreaterError) {
			projectTaskErrors.planned_start_date =
				'start date must not be greater than end date!';
		}
		if (durationGreaterError) {
			projectTaskErrors.planned_hours =
				'duration must not be greater than project duration!';
		}
		return projectTaskErrors;
	};

	const getMilestoneIntialValue = (milestoneId: string | null) => {
		const project = milestones?.find((value: any) => value.id === milestoneId);
		return project
			? {
					id: project.id,
					name: project?.name,
			  }
			: null;
	};
	const projectCategoryRenderer = (projectCategory: any) =>
		`${projectCategory?.name || ''}`;

	const handleProjectCategoryCreate = (projectCategory: string) => {
		if (!projectCategory) {
			return;
		}
		setIsProjectCategoriesLoading(true);
		const projectCategoryId = createProjectCategory({
			variables: {
				projectCategory: projectCategory,
				type: 'project_task',
			},
		})
			.then((res) => {
				setIsProjectCategoriesLoading(false);
				notify('Project Category created...');
				return res?.data?.insert_project_category_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectCategoriesLoading(false);
				if (err.message.includes('project_category_name_org_id_key')) {
					setIsProjectCategoryUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectCategoryId;
	};
	const handleChange: AutocompleteArrayInputProps['onChange'] = (
		value: string[]
	) => {
		if (isEmpty(value)) {
			setAssigneTo([]);
			return;
		}
		setAssigneTo(value);
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
			>
				<Box className={taskStyles.container}>
					<Box className={classes.headerContainer} marginBottom='10px'>
						<Box className={classes.heading}>
							{isEdit && parentTaskId
								? 'Edit subtask'
								: !isEdit && !parentTaskId
								? 'Add task'
								: isEdit && !parentTaskId
								? 'Edit Task'
								: 'Add subtask'}
						</Box>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => closeModal()}
						/>
					</Box>
					<Form
						initialValues={
							taskEditDetails?.id ? taskEditDetails : initialFormValues
						}
						onSubmit={onTaskCreation}
						validate={validateTaskFormValues}
						mutators={{
							setProjectCategory: (args, state, utils) => {
								utils.changeValue(state, 'category_id', () => {
									return args[0];
								});
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, values, form }) => (
							<form onSubmit={handleSubmit}>
								<Box className={taskStyles.formContainer}>
									<DialogContent>
										<Box
											height='70px'
											display='flex'
											justifyContent='space-between'
										>
											<Box width={'65%'}>
												<Typography className={classes.label}>
													Task Name *
												</Typography>
												<TextInput
													source='title'
													fullWidth={true}
													label={''}
													validate={[
														required(),
														minLength(3, TASK_NAME_VALIDATION_MESSAGE),
													]}
												/>
											</Box>
											<Box width={'25%'}>
												<Box className={taskStyles.label}>
													Is Billable *
													<Tooltip
														title={BILLABLE_TOOLTIP_LABEL}
														placement='right'
													>
														<IconButton className={taskStyles.iconContainer}>
															<InfoIcon className={taskStyles.icon} />
														</IconButton>
													</Tooltip>
												</Box>
												<RadioButtonGroupInput
													validate={required()}
													source='is_billable'
													label=''
													choices={[
														{ id: true, name: 'True' },
														{ id: false, name: 'False' },
													]}
												/>
											</Box>
										</Box>
										<Box display='flex' justifyContent='space-between'>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Start Date *
												</Typography>
												<Field
													name='planned_start_date'
													validate={(value: any) => {
														if (dayjs(value).isValid() === true) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	setStartDateGreaterError(false);
																}}
																dateFormat={dateFormat}
																error={startDateGreaterError}
																helperText={
																	startDateGreaterError &&
																	'start date must not be greater than end date!'
																}
																FormHelperTextProps={{
																	style: { color: 'red' },
																}}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Due Date *
												</Typography>
												<Field
													name='planned_end_date'
													validate={(value: any) => {
														if (dayjs(value).isValid() === true) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value: any) => {
																	props.input.onChange(value);
																	setStartDateGreaterError(false);
																}}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Category
												</Typography>
												<ReferenceInput
													source='category_id'
													label={''}
													reference='project_category'
													sort={{ field: 'name', order: 'ASC' }}
													filterToQuery={(searchText) => ({
														name: searchText,
														type: 'project_task',
													})}
													filter={{
														name: true,
													}}
													onFocus={() => {
														setIsProjectCategoryUniqueViolationError(false);
													}}
												>
													{isProjectCategoriesLoading ? (
														<TextInput
															source=''
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<CircularProgress
																			style={{
																				height: '20px',
																				width: '20px',
																				color: '#4285F4',
																			}}
																		/>
																	</InputAdornment>
																),
															}}
														/>
													) : (
														<AutocompleteInput
															resettable={true}
															optionText={projectCategoryRenderer}
															shouldRenderSuggestions={(name: string) =>
																name.trim().length > 0
															}
															onCreate={async (event: any) => {
																const projectCategoryId =
																	await handleProjectCategoryCreate(event);
																if (!isProjectCategoryUniqueViolationError) {
																	setTimeout(
																		() =>
																			form.mutators.setProjectCategory(
																				projectCategoryId
																			),
																		100
																	);
																}
															}}
														/>
													)}
												</ReferenceInput>
											</Box>
										</Box>
										<Box display='flex' justifyContent='space-between'>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Task Status *
												</Typography>
												<SelectInput
													source='status'
													label=''
													choices={projectStatusList}
													validate={[required()]}
												/>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Milestone
												</Typography>
												<Field name='project_milestone_id'>
													{(props: any) => (
														<ThemeProvider theme={autoCompleteTheme}>
															<div
																className={
																	autocompleteSearchStyles.customFieldContainer
																}
															>
																<Autocomplete
																	disabled={
																		isEdit && mileStoneId ? true : false
																	}
																	id='project_milestone_id'
																	classes={{
																		option: autocompleteSearchStyles.options,
																		groupLabel:
																			autocompleteSearchStyles.groupLabel,
																		inputRoot:
																			autocompleteSearchStyles.inputRoot,
																		noOptions:
																			autocompleteSearchStyles.noOptions,
																		focused: autocompleteSearchStyles.focused,
																		input: autocompleteSearchStyles.input,
																		endAdornment:
																			autocompleteSearchStyles.endAdornment,
																	}}
																	getOptionSelected={(
																		option: any,
																		value: any
																	) => option?.id === props.input.value}
																	value={getMilestoneIntialValue(
																		projectMilestoneId
																	)}
																	onChange={(event, newValue) => {
																		setProjectMilestoneId(newValue?.id);
																		props.input.onChange(newValue?.id);
																	}}
																	options={
																		milestones && milestones?.length > 0
																			? getMilestoneOptions()
																			: []
																	}
																	getOptionLabel={(option: any) =>
																		option?.name || ''
																	}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label=''
																			placeholder='Search milestone'
																			InputProps={{
																				...params.InputProps,
																				startAdornment: (
																					<InputAdornment
																						position='start'
																						classes={{
																							root: autocompleteSearchStyles.inputAdronment,
																							positionStart:
																								autocompleteSearchStyles.inputAdronmentPositionStart,
																						}}
																					>
																						<SearchIcon
																							className={
																								autocompleteSearchStyles.searchIconPositionStart
																							}
																						/>
																					</InputAdornment>
																				),
																			}}
																		/>
																	)}
																/>
															</div>
														</ThemeProvider>
													)}
												</Field>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Completion(%)
												</Typography>
												<NumberInput
													source='completion_percentage'
													fullWidth={true}
													label={''}
													InputProps={{
														endAdornment: (
															<InputAdornment position='end'>%</InputAdornment>
														),
													}}
													validate={[minValue(0), maxValue(100)]}
												/>
											</Box>
										</Box>
										<Box display='flex' justifyContent='space-between'>
											<Box className={taskStyles.floatInput}>
												<Box className={taskStyles.label}>
													Reminder
													<Tooltip
														title={REMINDER_TOOLTIP_LABEL}
														placement='right'
													>
														<IconButton className={taskStyles.iconContainer}>
															<InfoIcon className={taskStyles.icon} />
														</IconButton>
													</Tooltip>
												</Box>
												<Field
													name='reminder'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Box className={taskStyles.label}>
													Estimated Hour
													<Tooltip
														title={ESTIMATED_HOUR_TOOLTIP_LABEL}
														placement='right'
													>
														<IconButton className={taskStyles.iconContainer}>
															<InfoIcon className={taskStyles.icon} />
														</IconButton>
													</Tooltip>
												</Box>
												<TextInput
													source='planned_hours'
													fullWidth={true}
													label={''}
													validate={number()}
													onChange={() => setDurationGreaterError(false)}
													error={durationGreaterError}
													helperText={
														durationGreaterError &&
														'duration must not be greater than project duration!'
													}
													FormHelperTextProps={{ style: { color: 'red' } }}
												/>
											</Box>
											<Box className={taskStyles.floatInput}>
												<Typography className={classes.label}>
													Priority
												</Typography>
												<SelectInput
													source='priority'
													label=''
													choices={[
														{ id: 'Low', name: 'Low' },
														{ id: 'Medium', name: 'Medium' },
														{ id: 'High', name: 'High' },
													]}
												/>
											</Box>
										</Box>
										<Box display='flex' justifyContent='space-between'>
											<Box>
												<Box className={taskStyles.label}>
													Assigned To *
													<Tooltip
														title={ASSIGNED_TO_TOOLTIP_LABEL}
														placement='right'
													>
														<IconButton className={taskStyles.iconContainer}>
															<InfoIcon className={taskStyles.icon} />
														</IconButton>
													</Tooltip>
												</Box>
												<ThemeProvider
													theme={projectTaskAutoCompleteArrayInputTheme}
												>
													<AutocompleteArrayInput
														source='assigned_to'
														onChange={handleChange}
														label=''
														choices={
															teamMembers && teamMembers?.length > 0
																? sortedTeamMembers
																: []
														}
														validate={required()}
													/>
												</ThemeProvider>
											</Box>
										</Box>
										<Box className={classes.label}>Task Description </Box>
										{!parentTaskId ? (
											<Box
												marginBottom={'26px'}
												onClick={(e: any) => e.preventDefault()}
											>
												<TiptapEditor
													setDescription={(val: any) => setDescription(val)}
													description={description || ''}
												/>
											</Box>
										) : (
											<TextInput
												source='description'
												fullWidth={true}
												label={''}
												multiline={true}
												style={{ height: '100px' }}
												onChange={(event: any) =>
													setDescription(event?.target?.value)
												}
											/>
										)}
									</DialogContent>
								</Box>
								<DialogActions>
									<Box className={taskStyles.floatingButtonContainer}>
										<Button
											startIcon={
												(isAddProjectTaskLoading ||
													isUpdateProjectTaskLoading) && (
													<CircularProgress
														style={{
															height: '20px',
															width: '20px',
															color: '#FFFFFF',
														}}
													/>
												)
											}
											className={
												invalid ||
												pristine ||
												isSaveClicked ||
												isAddProjectTaskLoading ||
												isUpdateProjectTaskLoading
													? classes.disabledStartButton
													: classes.updateButton
											}
											disabled={
												invalid ||
												pristine ||
												isSaveClicked ||
												isAddProjectTaskLoading ||
												isUpdateProjectTaskLoading
											}
											type='submit'
										>
											{taskDetails?.id ? 'Update' : 'Create'}
										</Button>
										<Button
											onClick={() => closeModal()}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
									</Box>
								</DialogActions>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CreateTaskForm;
