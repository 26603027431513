import React, { useState } from 'react';

import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';

import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../../settingsStyle';
import DepartmentForm from './DepartmentForm.component';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';

interface Department {
	id?: string;
	name?: string;
	description?: string;
	org_id?: string;
}
const DepartmentList = () => {
	const [isDepartmentFormSHown, setIsDepartmentFormSHown] = useState(false);
	const [editDepartmentDetails, setEditDepartmentDetails] =
		useState<Department>({});
	const [isDepartmentEdit, setIsDepartmentEdit] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [choosedDepartment, setChoosedDepartment] = useState(null);
	const [searchDepartment, setSearchDepartment] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const onEditDepartment = (department: Department) => {
		if (!department) {
			return;
		}
		const { org_id, ...restDepartment } = department;
		setEditDepartmentDetails({ ...restDepartment });
		setIsDepartmentEdit(true);
		setIsDepartmentFormSHown(true);
	};
	const deleteDepartment = () => {
		mutate(
			{
				type: 'delete',
				resource: 'department',
				payload: {
					id: choosedDepartment,
				},
			},
			{
				onSuccess: () => {
					notify('Department deleted Successfully');
					refresh();
					setIsDeleteModalShown(false);
					setChoosedDepartment(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};
	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Department'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchDepartment(e?.target?.value);
								}}
								value={searchDepartment}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					style={{ width: '130px' }}
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsDepartmentFormSHown(true);
						setIsDepartmentEdit(false);
					}}
				>
					Add Department
				</Button>
			</Box>

			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='department'
					basePath='/department'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: searchDepartment }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.name}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.name}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(shift: any) => {
								return (
									<Tooltip
										title={`${shift.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(department: any) => (
								<Box>
									<PopOver
										edit={() => onEditDepartment(department)}
										remove={() => {
											setIsDeleteModalShown(true);
											setChoosedDepartment(department?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<DepartmentForm
				open={isDepartmentFormSHown}
				onClose={() => setIsDepartmentFormSHown(false)}
				refetch={() => refresh()}
				initialValues={editDepartmentDetails}
				isEditMutation={isDepartmentEdit}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deleteDepartment}
			/>
		</>
	);
};

export default DepartmentList;
