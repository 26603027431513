export const bloodGroupChoices = [
	{ id: 'A+', name: 'A+' },
	{ id: 'A−', name: 'A−' },
	{ id: 'AB+', name: 'AB+' },
	{ id: 'AB−', name: 'AB−' },
	{ id: 'B+', name: 'B+' },
	{ id: 'B−', name: 'B−' },
	{ id: 'O+', name: 'O+' },
	{ id: 'O−', name: 'O−' },
];

export const EMPLOYEE_GENDER_CHOICES = [
	{ id: 'Male', name: 'Male' },
	{ id: 'Female', name: 'Female' },
	{ id: 'Transgender', name: 'Transgender' },
];

export const breadcumData = [{ name: 'Employee', path: '/employee' }];

export const SKILL_ALREADY_EXIST_MESSAGE = 'Selected skill already exist';
export const SKILL_SELECT_MESSAGE = 'Please select a skill';
export const SKILL_LEVEL_SELECT_MESSAGE = 'Please select a skill level';

export const EMPLOYEE_PROJECTLIST_EXPORT_COLUMN = [
	'PROJECT NAME',
	'COMPANY NAME',
	'PROJECT OWNER',
	'PROJECT OWNER EMAIL',
	'PROJECT END DATE',
	'PROJECT STATUS',
	'ALLOCATION START DATE',
	'ALLOCATION END DATE',
];

export const tableHeadingData = [
	{ id: 'project_name', label: 'PROJECT NAME' },
	{ id: 'company_name', label: 'COMPANY NAME' },
	{ id: 'project_owner', label: 'PROJECT OWNER' },
	{ id: 'project_end_date', label: 'PROJECT END DATE' },
	{ id: 'project_status', label: 'PROJECT STATUS' },
	{ id: 'allocation_start_date', label: 'ALLOCATION START DATE' },
	{ id: 'allocation_end_date', label: 'ALLOCATION END DATE' },
];

export const noDataMessage = 'No Project list yet.';
export const projectStatusText = 'Project status';
export const CTC_UPDATE = 'Employee compensation updated successfully';

export const validateNumber = /^(0|[1-9]\d*)(\.\d+)?$/;
export const validateNum = (value: string | null) => {
	if (value && !validateNumber.test(value)) {
		return 'Only numbers allowed ';
	}
	return undefined;
};
