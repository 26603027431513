import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Holidays from './Holidays.component';

const Style = makeStyles({
	chartContainer: {
		width:'82vw'
	},
	container: {
		marginTop: '25px',
		display: 'grid',
		gridTemplateColumns: 'repeat(1, 1fr)',
		gap: '15px',
		maxWidth: '83vw',
	},
});

const AllHolidays = () => {
	const classes = Style();
	return (
		<Box className={classes.container}>
			<Box className={classes.chartContainer}>
				<Holidays />
			</Box>
		</Box>
	);
};

export default AllHolidays;
