export const CUSTOM_FIELD_DELETE_MESSAGE = 'Custom field deleted successfully';
export const CUSTOM_FIELD_DELETE_FAILED = 'Custom field delete failed';
export const CUSTOM_FIELD_DELETE_WARNING =
	'Are you sure you want to delete this custom field?. This action cannot be undone.';
export const CUSTOM_FIELD_UPDATE_MESSAGE = 'Updated custom field successfully';
export const CUSTOM_FIELD_CREATE_MESSAGE = 'Created custom field successfully';
export const UNIQUENESS_VIOLATION_ERROR = 'This name is already present';
export const OPTION_MESSAGE = 'Add options separated by comma';
export const CUSTOM_FIELD_WARNING = 'Cannot create more than 9 custom fields';
export const NON_NULL_EDIT_FIELD_ERROR =
	'Error fetching non-null editable data';
