import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { GET_CONSULTANT_DOC } from '../Consultant/gqlQueries';
import {
	GET_EMPLOYEE_DOCS,
	UPDATE_FILE_NAME,
} from '../Employees/DetailsPage/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { detailPageDocumentStyle } from '../../Layout/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useMutation as useApolloMutation } from '@apollo/client';
import { supportedFileFormats } from '../../Utils';
import { uploadFile } from '../../Api/restApi.config';
import { useAddMyInfoEmployeeDocMutation } from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNotify } from 'ra-core';
import OptionsMenu from './OptionMenu';
import { editableColumnsPermissions } from './constant';
import { FieldPermissionsContext } from './MyInfoView.component';
export interface Props {
	document_id: string;
	userType: string;
}

interface document {
	url: string;
	id: string;
	file_name: string;
}

const MyInfoDocument = (props: Props) => {
	const { document_id, userType } = props;
	const { orgId } = useContext<any>(UserProfileContext);
	const { isEmpSelfServiceEnabled, editableColumns } = useContext<any>(
		FieldPermissionsContext
	);
	const classes = detailPageDocumentStyle();
	const inputRef = useRef<HTMLInputElement>(null);
	const [document, setDocument] = useState([]);
	const [loading, setLoading] = useState(false);
	const notify = useNotify();

	const [updateFileName] = useApolloMutation(UPDATE_FILE_NAME);

	const [addEmployeeDoc, { error: uploadError }] =
		useAddMyInfoEmployeeDocMutation();

	const { data: consultantDocs } = useApolloQuery(GET_CONSULTANT_DOC, {
		variables: { consultant_id: document_id },
	});

	const { data: employeeDocs, refetch: refetchEmpDocs } = useApolloQuery(
		GET_EMPLOYEE_DOCS,
		{
			variables: { employee_id: document_id },
		}
	);

	const FileUploadHandler = (uploadedFile: any) => {
		let filename: any = uploadedFile[1];
		if (supportedFileFormats.includes(filename.split('.').pop())) {
			setLoading(true);
			let data = new FormData();
			data.append('uploadType', 'OpportunityDocs');
			data.append('file', uploadedFile[0]);
			data.append('id', orgId);
			uploadFile(data)
				.then((res) => {
					addEmployeeDoc({
						variables: {
							data: {
								type: 'EmployeeDocs',
								url: res.data.url,
								employee_id: document_id,
								file_name: uploadedFile[1],
							},
						},
					})
						.then(() => {
							setLoading(false);
							notify('File uploaded successfully');
							refetchEmpDocs();
						})
						.catch(() => {
							setLoading(false);
							notify('File already exist in the documents', 'warning');
							if (uploadError) return `Submission error! ${uploadError}`;
						});
				})
				.catch((err) => {
					notify(
						err.response?.data?.message || 'Document Upload failed',
						'warning'
					);
					setLoading(false);
					refetchEmpDocs();
				});
		} else {
			notify(
				` '${filename
					.split('.')
					.pop()}'  file format is not supported in documents. `,
				'warning'
			);
			setLoading(false);
		}
	};

	const ProgressLoader = () => (
		<Backdrop className={classes.backdrop} open={loading}>
			<CircularProgress color='inherit' />
		</Backdrop>
	);

	useEffect(() => {
		if (!employeeDocs || !(employeeDocs?.employee_docs?.length > 0)) {
			return;
		}
		const doc = employeeDocs?.employee_docs?.map((doc: document) => {
			const docInfo = {
				id: doc.id,
				docUrl: doc.url,
				file_name: doc.file_name,
			};
			return docInfo;
		});
		setDocument(doc);
	}, [employeeDocs]);

	useEffect(() => {
		if (!consultantDocs || !(consultantDocs?.consultant_doc?.length > 0)) {
			return;
		}
		const doc = consultantDocs?.consultant_doc?.map((doc: document) => {
			const docInfo = {
				id: doc.id,
				docUrl: doc.url,
				file_name: doc.file_name,
			};
			return docInfo;
		});
		setDocument(doc);
	}, [consultantDocs]);

	const onFileRename = (newFieldName: string, field: string) => {
		setLoading(true);
		updateFileName({
			variables: {
				id: field,
				file_name: newFieldName,
			},
		})
			.then(() => {
				refetchEmpDocs();
				setLoading(false);
				notify(`File name updated successfully`);
			})
			.catch(() => {
				setLoading(false);
				notify(
					`The file name ${newFieldName} already exist in document`,
					'warning'
				);
				return;
			});
	};

	return (
		<Grid item md={12}>
			<ProgressLoader />
			<Grid
				container
				spacing={3}
				className={classes.docsContainer}
				style={{ height: '500px' }}
			>
				<Grid item>
					{/* TODO Need to handle contractor also */}
					{isEmpSelfServiceEnabled &&
						userType === 'employee' &&
						editableColumns?.includes(
							editableColumnsPermissions.emp_documents
						) && (
							<Paper
								className={classes.addDocBox}
								onClick={() => {
									inputRef.current?.click();
									if (!inputRef?.current?.value) {
										return;
									}
									inputRef.current.value = '';
								}}
							>
								<Box className={classes.uploadButton}>
									<AddCircleOutlineIcon
										fontSize='large'
										style={{
											color: '#4285F4',
											height: 39,
											width: 39,
										}}
									/>
									<input
										type='file'
										style={{ display: 'none' }}
										ref={inputRef}
										onChange={(event: any) => {
											FileUploadHandler([
												event.target.files[0],
												event.target.files[0]?.name,
											]);
										}}
										accept='.pdf,.doc,.docx,.xls,.csv,.xlsx,.odt,.ods,.ppt,.pptx,.txt,.eml'
									/>
									<Typography variant='body1' className={classes.uploadTile}>
										Upload <br />
										Document
									</Typography>
								</Box>
							</Paper>
						)}
				</Grid>
				{document?.length > 0 ? (
					document?.map((docs: document, index: any) => {
						return (
							<Grid item key={`tile${index}`}>
								<Paper className={classes.paperBox}>
									{/* TODO Need to handle contractor also */}
									{isEmpSelfServiceEnabled &&
										userType === 'employee' &&
										editableColumns?.includes(
											editableColumnsPermissions.emp_documents
										) && (
											<OptionsMenu
												docDetails={docs}
												refetchDocs={refetchEmpDocs}
												setPopper={(message: string) => {
													notify(message);
												}}
												onFileRename={onFileRename}
												userType={''}
											/>
										)}
									<Box>
										<Avatar
											className={classes.avatar}
											style={{ width: 39, height: 39 }}
										>
											<InsertDriveFileIcon
												className={classes.avtharIcon}
												fontSize='small'
											/>
										</Avatar>
									</Box>
									<Tooltip title={docs?.file_name}>
										<Typography className={classes.paperText} variant='body2'>
											{docs?.file_name}
										</Typography>
									</Tooltip>
								</Paper>
							</Grid>
						);
					})
				) : (
					<Typography className={classes.noData}>
						No data to display...
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default MyInfoDocument;
