import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	ThemeProvider,
	TextField as TextComponent,
	Tooltip,
} from '@material-ui/core';
import { SearchIcon } from '../../../assets/SvgIcons';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { DataGridTheme } from '../pmsTheme';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { useGetMyAppraisalCyclesQuery } from '../../../generated/graphql';
import dayjs from 'dayjs';
import { pmsDatagridStyle } from '../pms.styles';
import { useHistory } from 'react-router-dom';
import { UserProfileContext } from '../../../App';
import { PMS_DATE_FORMAT } from '../constant';
import { getEndDate } from '../pms.utils';
import { modalFormStyle } from '../../../Layout/styles';
import Loader from '../SharedComponents/Loader.component';

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Cycles'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function MyPMS() {
	const { id: userId } = useContext<any>(UserProfileContext);
	const [searchedText, setSearchedText] = useState<string>('');
	const [appraisalRows, setAppraisalRows] = useState<
		{ id: any; name: string; period: string; status: string }[]
	>([]);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);

	const history = useHistory();
	const formStyles = modalFormStyle();
	const listStyles = pmsDatagridStyle();

	// API CALLS
	const { data: appraisalCycles, loading: isAppraisalLoading } =
		useGetMyAppraisalCyclesQuery({
			variables: {
				limit: rowsPerPage,
				offset: rowsPerPage * page,
				name: `%${searchedText}%`,
				userId: userId,
			},
			fetchPolicy: 'network-only',
		});

	const columns = [
		{
			name: 'name',
			label: 'CYCLE',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${listStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'period',
			label: 'PERIOD',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${listStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'status',
			label: 'STATUS',
			options: {
				customBodyRender: (value: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${listStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'selfReviewEndDate',
			label: 'SELF REVIEW END DATE',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${listStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
	];

	useEffect(() => {
		if (!appraisalCycles) return;
		const data = appraisalCycles.pms_pms_appraisal_cycle.map(
			(appraisalCycle) => {
				return {
					id: appraisalCycle.id,
					name: appraisalCycle.name,
					period: `${dayjs(appraisalCycle.start_date).format(
						PMS_DATE_FORMAT
					)} - ${dayjs(appraisalCycle.end_date).format(PMS_DATE_FORMAT)}`,
					status: appraisalCycle.pms_appraisal_status.label,
					selfReviewEndDate: getEndDate(appraisalCycle.self_review_end_date),
				};
			}
		);

		setAppraisalRows(data);
	}, [appraisalCycles]);

	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		rowsPerPage: rowsPerPage,
		download: false,
		page: page,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		count:
			appraisalCycles?.pms_pms_appraisal_cycle_aggregate.aggregate?.count || 0,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		onRowClick: (row: any, rowMeta) => {
			history.push(`/my-pms/${appraisalRows[rowMeta.dataIndex].id}`);
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
	};

	return (
		<>
			<p className={listStyles.heading}>My PMS</p>
			<Box
				display='flex'
				justifyContent='space-between'
				marginBottom='20px'
				marginTop='20px'
			>
				<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
					<Box>
						<CustomSearchRender
							searchText={searchedText}
							setSearchedText={setSearchedText}
						/>
					</Box>
					{isAppraisalLoading && (
						<Box>
							<Loader />
						</Box>
					)}
				</Box>
			</Box>
			<ThemeProvider theme={DataGridTheme}>
				<MUIDataTable
					title='Appraisal Cycles'
					data={appraisalRows}
					columns={columns}
					options={options}
				/>
			</ThemeProvider>
		</>
	);
}

export default MyPMS;
