import React, { useContext } from 'react';
import EmployeeAttendanceReport from './employeeAttendanceReport.component';
import { EmployeeLeaveReport } from './EmployeeLeaveReport.component';
import { Card } from '@material-ui/core';
import { AttendanceDetailedReport } from './AttendanceDetailedReport.component';
import { UserProfileContext } from '../../../App';

export const AttendanceInsights = () => {
	const { featureFlag } = useContext<any>(UserProfileContext);
	return (
		<>
			<Card style={{ minHeight: 200 }}>
				<EmployeeAttendanceReport />
			</Card>
			<Card style={{ marginTop: '10px' }}>
				<EmployeeLeaveReport />
			</Card>
			{featureFlag?.attendanceDetailedReport && (
				<Card style={{ marginTop: '10px' }}>
					<AttendanceDetailedReport />
				</Card>
			)}
		</>
	);
};
