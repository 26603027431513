import React, { useState, useEffect } from 'react';
import { TextInput, useNotify } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { settingsStyle } from '../settingsStyle';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useUpsertDayTypeMutation } from '../../../generated/graphql';
import {
	DAY_TYPE_CREATION_TEXT,
	DAY_TYPE_UNIQUE_VIOLATION_KEY,
	DAY_TYPE_UNIQUE_VIOLATION_TEXT,
	DAY_TYPE_UPDATION_TEXT,
	regexNameValidation,
	validateDescription,
} from '../constant';

interface FormValue {
	id?: string;
	name?: string;
	description?: string | null | undefined;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue | null | undefined;
}
const DayTypeForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [upsertDayType, { loading: isDepartmentLoading }] =
		useUpsertDayTypeMutation();
	const [initialFormValues, setInitialFormValues] = useState<FormValue | null>(
		{}
	);
	const [isDayTypeUniqueViolationError, setIsDayTypeUniqueViolationError] =
		useState(false);
	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		upsertDayType({
			variables: {
				object: { ...formValues },
			},
		})
			.then((response: any) => {
				if (!response.error) {
					setIsDayTypeUniqueViolationError(false);
					if (formValues?.id) {
						notify(DAY_TYPE_UPDATION_TEXT);
						refetch();
						closeModal();
					} else {
						notify(DAY_TYPE_CREATION_TEXT);
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error.message.includes(DAY_TYPE_UNIQUE_VIOLATION_KEY)) {
					setIsDayTypeUniqueViolationError(true);
					return;
				}
				notify('Failed', 'warning');
			});
	};
	const closeModal = () => {
		setInitialFormValues({});
		onClose();
	};
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};
	const validateDayTypeFormValues = async () => {
		const orgTaskFormErrors = {} as FormValue;
		if (isDayTypeUniqueViolationError) {
			orgTaskFormErrors.name = DAY_TYPE_UNIQUE_VIOLATION_TEXT;
		}
		return orgTaskFormErrors;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{isEditMutation ? `Edit: ${initialValues?.name}` : `Add Day Type`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateDayTypeFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={validateName}
										onChange={() => {
											setIsDayTypeUniqueViolationError(false);
										}}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										validate={validateDescription}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine || isDepartmentLoading}
										className={
											invalid || pristine || isDepartmentLoading
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{isEditMutation ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default DayTypeForm;
