import { gql } from '@apollo/client';

export const GET_EMPLOYEE = gql`
	query getEmployee($employeeId: uuid!) {
		employee: employee_by_pk(id: $employeeId) {
			id
			address
			join_date
			prior_experience
			blood_group
			marital_status
			wedding_date
			org_shift_id
			account_number
			ifsc_code
			bank_name
			branch_name
			ctc_fixed
			ctc_notes
			ctc_variable
			ctc_currency_id
			master_currency {
				symbol
			}
			highest_academic_degree
			institution_name
			emp_billing_cost
			emp_cost
			personal_email
			emp_id
			job_level_id
			designation_id
			department_id
			reportee_id
			working_mode
			is_billable
			employeeStatus
			emergency_contact_number
			emergency_contact_name
			employee_joining_source_id
			last_date
			probation_end_date
			probation_start_date
			gender
			location_id
			employee_prefix_id
			dob
			created_at
			custom_field_1
			custom_field_2
			custom_field_3
			custom_field_4
			custom_field_5
			custom_field_6
			custom_field_7
			custom_field_8
			custom_field_9
			org_location {
				id
				name
			}
			employee_prefix_id
			employee_prefix {
				id
				name
				value
			}
			department {
				name
			}
			org_leave_group {
				id
				label
			}
			employee_status {
				id
				label
				value
			}
			designation {
				title
			}
			user {
				id
				first_name
				middle_name
				last_name
				full_name
				email
				profile_pic
				is_active
				phone_number
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee_skill_mappings {
					id
					is_primary
					skill_master {
						name
					}
					skill_level {
						level
					}
				}
			}
			reportsTo {
				is_active
			}
			createdByUser {
				id
				full_name
			}
		}
	}
`;

export const GET_EMPLOYEE_CUSTOM_FIELDS = gql`
	query getEmployeeCustomFields {
		custom_field(
			where: { master_sub_module: { value: { _eq: "employee" } } }
			order_by: { index: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
		}
		lastIndexCustomField: custom_field_aggregate {
			aggregate {
				max {
					index
				}
			}
		}
	}
`;

export const GET_LEAVE_TYPES = gql`
	query getLeaveTypes($leavePolicyId: uuid!) {
		org_leave_types(where: { leave_group_id: { _eq: $leavePolicyId } }) {
			id
			leave_increments
			default_amount
			interval
		}
	}
`;

export const GET_EMPLOYEE_STATUS = gql`
	query getEmployeeStatus($limit: Int, $status: String) {
		employeeStatus: employee_status(
			limit: $limit
			where: { label: { _ilike: $status } }
		) {
			id
			label
			value
		}
		employee_status_aggregate(where: { label: { _ilike: $status } }) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_DEPARTMENTS = gql`
	query getDepartments($limit: Int, $department: String) {
		departments: department(
			distinct_on: name
			limit: $limit
			where: { name: { _ilike: $department } }
		) {
			id
			name
		}
		department_aggregate(
			distinct_on: name
			where: { name: { _ilike: $department } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_JOB_LEVELS = gql`
	query getJobLevels($limit: Int, $title: String) {
		job_level(
			distinct_on: level
			limit: $limit
			where: { level: { _ilike: $title } }
		) {
			id
			level
		}
		job_level_aggregate(
			distinct_on: level
			where: { level: { _ilike: $title } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_DESIGNATIONS = gql`
	query getDesignations($limit: Int, $title: String) {
		designation(
			distinct_on: title
			limit: $limit
			where: { title: { _ilike: $title } }
		) {
			id
			title
		}
		designation_aggregate(
			distinct_on: title
			where: { title: { _ilike: $title } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_EMPLOYEE_STATUS_OPTIONS = gql`
	query getEmployeeStatusOptions {
		employeeStatus: employee_status(order_by: { label: asc }) {
			id
			label
			value
		}
	}
`;

export const GET_EMPLOYEE_SEARCH = gql`
	query getEmployeeSearch($name: String!, $employee: employee_bool_exp = {}) {
		user(
			where: {
				user_type: { _eq: "employee" }
				full_name: { _ilike: $name }
				employee: $employee
			}
			order_by: { full_name: asc }
		) {
			first_name
			last_name
			full_name
			id
			phone_number
			user_type
			email
			organization {
				emp_id_prefix
			}
			employee {
				is_billable
				personal_email
				blood_group
				prior_experience
				gender
				dob
				join_date
				highest_academic_degree
				institution_name
				bank_name
				branch_name
				account_number
				ifsc_code
				last_date
				probation_start_date
				probation_end_date
				marital_status
				alternate_contact_number
				father_name
				wedding_date
				spouse_name
				govt_issue_type_1
				govt_issue_type_2
				emp_cost
				emp_billing_cost
				working_mode
				middle_name
				mother_name
				location_id
				custom_field_1
				custom_field_2
				custom_field_3
				custom_field_4
				custom_field_5
				custom_field_6
				custom_field_7
				custom_field_8
				custom_field_9
				employee_notes {
					title
					note
				}
				org_location {
					id
					name
				}
				emergency_contact_name
				emergency_contact_number
				employee_status {
					label
					value
				}
				employee_prefix {
					id
					name
					value
				}
				emp_joining_source {
					label
				}
				org_shift {
					name
				}
				reportsTo {
					id
					first_name
					last_name
					full_name
					email
				}
				emp_id
				department {
					name
				}
				designation {
					id
					title
				}
				job_level {
					id
					level
				}
				organization {
					currency {
						symbol
					}
				}
			}
			employee_skill_mappings {
				skill_master {
					name
				}
			}
			employee_leaving_reasons {
				notes
				leaving_reason {
					label
				}
			}
		}
	}
`;

export const UPDATE_EMPLOYEE = gql`
	mutation updateEmployee(
		$id: uuid!
		$user_Id: uuid!
		$data: employee_set_input!
		$userData: user_set_input
	) {
		update_employee_by_pk(pk_columns: { id: $id }, _set: $data) {
			emp_id
		}
		update_user_by_pk(pk_columns: { id: $user_Id }, _set: $userData) {
			id
		}
	}
`;
