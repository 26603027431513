import { SubmittedTimesheets, TimesheetEntry } from './Timesheet.model';
import dayjs from 'dayjs';
import {
	GetProjectsOptionsQuery,
	GetTimesheetEntriesForWeekQuery,
	GetTimesheetsQuery,
	GetTimesheetTotalHoursQuery,
	GetWeeklyTimesheetEntriesQuery,
} from '../../generated/graphql';
import {
	PROJECT_TYPE_CATEGORY,
	MASTER_PROJECT_TASK_TYPE,
	ORG_TASK_TYPE,
	checkIfOnlyNumbers,
	checkIfOnlyOneColonAfterNumbers,
	checkIfOnlyOneNumberAfterColon,
	validHHMMFormat,
	TIMESHEET_APPROVED_STATUS,
	TIMESHEET_SUBMITTED_STATUS,
} from './constant';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export const getBillableHours = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets) {
		return;
	}
	return timesheets
		.map((timesheetEntry) => {
			if (timesheetEntry.is_billable === false) {
				return '00:00:00';
			}
			return timesheetEntry.working_hours.split(':');
		})
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
};

export const getNonBillableHours = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets) {
		return;
	}
	return timesheets
		.map((timesheetEntry) => {
			if (timesheetEntry.is_billable === true) {
				return '00:00:00';
			}
			return timesheetEntry.working_hours.split(':');
		})
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
};

export const getTotalBillableHours = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets) {
		return;
	}
	const totalBillableHours = getBillableHours(timesheets);

	return totalBillableHours
		? `${Math.floor(totalBillableHours.asHours())}:${totalBillableHours.format(
				'mm'
		  )}`
		: '00:00';
};

export const getTotalNonBillableHours = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets) {
		return;
	}
	const totalBillableHours = getNonBillableHours(timesheets);

	return totalBillableHours
		? `${Math.floor(totalBillableHours.asHours())}:${totalBillableHours.format(
				'mm'
		  )}`
		: '00:00';
};

export const getTimesheetEntriesBillablePercentage = (
	timesheets: SubmittedTimesheets[]
) => {
	if (!timesheets) {
		return;
	}
	const totalHours = timesheets
		.map((timesheet) => timesheet.working_hours.split(':'))
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		)
		.asHours();

	const totalBillableHours = getBillableHours(timesheets);

	return totalBillableHours
		? ((totalBillableHours?.asHours() / totalHours) * 100).toFixed()
		: 0;
};

export const getTimesheetDataForDay = (data: GetTimesheetsQuery) => {
	if (!data) {
		return;
	}
	const projectTaskEntries = data.timesheet
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.project_task && {
					id: timesheet.id,
					taskType: PROJECT_TYPE_CATEGORY,
					project: timesheet?.project?.is_billable
						? timesheet?.project?.name
						: `${timesheet?.project?.name} (NB)`,
					project_id: timesheet?.project?.id,
					task: timesheet?.project_task.is_billable
						? timesheet?.project_task?.title
						: `${timesheet?.project_task?.title} (NB)`,
					task_id: timesheet?.project_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.project?.company?.name,
					day_type_id: timesheet?.day_type_id || null,
				}
			);
		})
		.filter((task) => task !== null);

	const orgTaskEntries = data.timesheet
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.org_task && {
					id: timesheet.id,
					taskType: ORG_TASK_TYPE,
					project: timesheet?.org_task.task_category,
					project_id: timesheet?.org_task.task_category,
					task: timesheet?.org_task?.is_billable
						? timesheet?.org_task?.title
						: `${timesheet?.org_task?.title} (NB)`,
					task_id: timesheet?.org_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.org_task.organization.name,
					day_type_id: timesheet?.day_type_id || null,
				}
			);
		})
		.filter((task) => task !== null);

	const masterProjectTaskEntries = data.timesheet
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.master_project_task && {
					id: timesheet.id,
					taskType: MASTER_PROJECT_TASK_TYPE,
					project: timesheet?.project?.is_billable
						? timesheet?.project?.name
						: `${timesheet?.project?.name} (NB)`,
					project_id: timesheet?.project?.id,
					task: timesheet?.master_project_task.is_billable
						? timesheet?.master_project_task?.title
						: `${timesheet?.master_project_task?.title} (NB)`,
					task_id: timesheet?.master_project_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.project?.company?.name,
					day_type_id: timesheet?.day_type_id || null,
				}
			);
		})
		.filter((task) => task !== null);

	return [
		...projectTaskEntries,
		...orgTaskEntries,
		...masterProjectTaskEntries,
	];
};

export const getTimesheetDataForWeek = (
	data: GetWeeklyTimesheetEntriesQuery,
	selectedWeek: string[]
): TimesheetEntry[] => {
	const submissionId =
		data?.project_task[0]?.timesheets[0]?.timesheet_submission?.id;

	const projectTaskEntries = data?.project_task.map((task) => ({
		taskId: task.id,
		taskName: task.is_billable ? task.title : `${task.title} (NB)`,
		taskType: PROJECT_TYPE_CATEGORY,
		projectName: task.project?.is_billable
			? task?.project?.name
			: `${task?.project?.name} (NB)`,
		projectId: task.project.id,
		companyName: task.project?.company?.name,
		notes: task.timesheets[0]?.notes,
		isDeleted: false,
		workingHours: selectedWeek.map((date, index) => {
			const workingHourDetails = task?.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					id: workingHourDetails.id,
					date: workingHourDetails.date,
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					submissionId:
						workingHourDetails?.timesheet_submission?.id || submissionId,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: date,
					hoursAndMinutes: '',
					isBillable: task.is_billable,
					submissionId: submissionId,
				};
			}
		}),
	}));

	// Handle Master Project Tasks
	const masterTasks = data?.master_project_task
		.map((masterTask) =>
			masterTask.timesheets.map((timesheetEntry) => ({
				taskId: masterTask.id,
				taskName: masterTask.is_billable
					? masterTask.title
					: `${masterTask.title} (NB)`,
				projectId: timesheetEntry?.project?.id,
				projectName: timesheetEntry?.project?.name,
				companyName: timesheetEntry?.project?.company?.name,
				projectIsBillable: timesheetEntry?.project?.is_billable,
			}))
		)
		.flat();

	// Get Distinct list by projects
	const uniqueMasterTasksByProjects = masterTasks.filter(
		(thing, index, self) =>
			index ===
			self.findIndex(
				(t) => t.projectId === thing.projectId && t.taskId === thing.taskId
			)
	);

	const formattedMasterTasks = uniqueMasterTasksByProjects.map(
		(masterTask) => ({
			...masterTask,
			timesheets: data?.master_project_task
				.map((response) =>
					response?.timesheets?.filter(
						(timesheetEntry) =>
							timesheetEntry?.master_project_task_id === masterTask.taskId &&
							timesheetEntry?.project?.id === masterTask.projectId
					)
				)
				.flat(),
		})
	);

	const masterTaskEntries = formattedMasterTasks.map((task) => ({
		taskId: task.taskId,
		taskName: task.taskName,
		taskType: MASTER_PROJECT_TASK_TYPE,
		projectName: task.projectIsBillable
			? task.projectName || ''
			: `${task.projectName} (NB)`,
		projectId: task.projectId,
		companyName: task.companyName,
		isDeleted: false,
		notes: task.timesheets[0]?.notes,
		workingHours: selectedWeek.map((date, index) => {
			const workingHourDetails = task?.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					id: workingHourDetails.id,
					date: workingHourDetails.date,
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					submissionId:
						workingHourDetails?.timesheet_submission?.id || submissionId,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: date,
					hoursAndMinutes: '',
					isBillable: true,
					submissionId: submissionId,
				};
			}
		}),
	}));

	const orgTaskEntries = data?.org_tasks.map((task) => ({
		taskId: task.id,
		taskName: task.is_billable ? task.title : `${task.title} (NB)`,
		taskType: ORG_TASK_TYPE,
		projectName: task.task_category,
		projectId: null,
		companyName: task.organization.name,
		isDeleted: false,
		notes: task.timesheets[0]?.notes,
		workingHours: selectedWeek.map((date, index) => {
			const workingHourDetails = task.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					id: workingHourDetails.id,
					date: workingHourDetails.date,
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					submissionId:
						workingHourDetails?.timesheet_submission?.id || submissionId,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: date,
					hoursAndMinutes: '',
					isBillable: task.is_billable,
					submissionId: submissionId,
				};
			}
		}),
	}));

	return [...projectTaskEntries, ...masterTaskEntries, ...orgTaskEntries];
};

export const getPreviousTimesheetData = (
	data: GetWeeklyTimesheetEntriesQuery,
	previousWeek: string[]
): TimesheetEntry[] => {
	const projectTaskEntries = data?.project_task.map((task) => ({
		taskId: task.id,
		taskName: task.is_billable ? task.title : `${task.title} (NB)`,
		taskType: PROJECT_TYPE_CATEGORY,
		projectName: task.project.name,
		projectId: task.project.id,
		companyName: task.project?.company?.name,
		isDeleted: false,
		notes: task.timesheets[0]?.notes,
		workingHours: previousWeek.map((date, index) => {
			const workingHourDetails = task?.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: '00:00',
					isBillable: task.is_billable,
				};
			}
		}),
	}));

	const masterTasks = data?.master_project_task
		.map((masterTask) =>
			masterTask.timesheets.map((timesheetEntry) => ({
				taskId: masterTask.id,
				taskName: masterTask.is_billable
					? masterTask.title
					: `${masterTask.title} (NB)`,
				projectId: timesheetEntry?.project?.id,
				projectName: timesheetEntry?.project?.name,
				companyName: timesheetEntry?.project?.company?.name,
				projectIsBillable: timesheetEntry?.project?.is_billable,
			}))
		)
		.flat();

	// Get Distinct list by projects
	const uniqueMasterTasksByProjects = masterTasks.filter(
		(thing, index, self) =>
			index ===
			self.findIndex(
				(t) => t.projectId === thing.projectId && t.taskId === thing.taskId
			)
	);

	const formattedMasterTasks = uniqueMasterTasksByProjects.map(
		(masterTask) => ({
			...masterTask,
			timesheets: data?.master_project_task
				.map((response) =>
					response?.timesheets?.filter(
						(timesheetEntry) =>
							timesheetEntry?.master_project_task_id === masterTask.taskId &&
							timesheetEntry?.project?.id === masterTask.projectId
					)
				)
				.flat(),
		})
	);

	const masterTaskEntries = formattedMasterTasks.map((task) => ({
		taskId: task.taskId,
		taskName: task.taskName,
		taskType: MASTER_PROJECT_TASK_TYPE,
		projectName: task.projectIsBillable
			? task.projectName || ''
			: `${task.projectName} (NB)`,
		projectId: task.projectId,
		companyName: task.companyName,
		isDeleted: false,
		notes: task.timesheets[0]?.notes,
		workingHours: previousWeek.map((date, index) => {
			const workingHourDetails = task?.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: '00:00',
					isBillable: task.projectIsBillable,
				};
			}
		}),
	}));

	const orgTaskEntries = data?.org_tasks.map((task) => ({
		taskId: task.id,
		taskName: task.is_billable ? task.title : `${task.title} (NB)`,
		taskType: ORG_TASK_TYPE,
		projectName: task.task_category,
		projectId: null,
		companyName: task.organization.name,
		isDeleted: false,
		notes: task.timesheets[0]?.notes,
		workingHours: previousWeek.map((date, index) => {
			const workingHourDetails = task.timesheets.find(
				(workingHour) => workingHour.date === date
			);
			if (workingHourDetails) {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: workingHourDetails.working_hours,
					isBillable: workingHourDetails.is_billable,
					entryNotes: workingHourDetails?.notes,
				};
			} else {
				return {
					date: dayjs(date).add(7, 'days').format('YYYY-MM-DD'),
					hoursAndMinutes: '00:00',
					isBillable: task.is_billable,
				};
			}
		}),
	}));

	return [...projectTaskEntries, ...masterTaskEntries, ...orgTaskEntries];
};

export const getSubmittedTimesheet = (
	data: GetTimesheetEntriesForWeekQuery
) => {
	const projectTask = data.project_task.map((projectTask) => ({
		projectName: projectTask.timesheets[0].project?.is_billable
			? projectTask.timesheets[0].project?.name
			: `${projectTask.timesheets[0].project?.name} (NB)`,
		companyName: projectTask.timesheets[0].project?.company?.name,
		taskName: projectTask.is_billable
			? projectTask.title
			: `${projectTask.title} (NB)`,
		timesheets: projectTask.timesheets,
	}));

	const orgTask = data.org_tasks.map((orgTask) => ({
		projectName: orgTask.task_category,
		companyName: orgTask.organization.name,
		taskName: orgTask.is_billable ? orgTask.title : `${orgTask.title} (NB)`,
		timesheets: orgTask.timesheets,
	}));

	const masterTasks = data?.master_project_task
		.map((masterTask) =>
			masterTask.timesheets.map((timesheetEntry) => ({
				taskId: timesheetEntry.master_project_task_id,
				taskName: masterTask.is_billable
					? masterTask.title
					: `${masterTask.title} (NB)`,
				projectId: timesheetEntry?.project?.id,
				projectName: timesheetEntry.project?.is_billable
					? timesheetEntry?.project?.name
					: `${timesheetEntry?.project?.name} (NB)`,
				companyName: timesheetEntry?.project?.company?.name,
			}))
		)
		.flat();

	const uniqueMasterTasksByProjects = masterTasks.filter(
		(thing, index, self) =>
			index ===
			self.findIndex(
				(t) => t.projectId === thing.projectId && t.taskId === thing.taskId
			)
	);

	const formattedMasterTasks = uniqueMasterTasksByProjects.map(
		(masterTask) => ({
			...masterTask,
			timesheets: data?.master_project_task
				.map((response) =>
					response?.timesheets?.filter(
						(timesheetEntry) =>
							timesheetEntry?.master_project_task_id === masterTask.taskId &&
							timesheetEntry?.project?.id === masterTask.projectId
					)
				)
				.flat(),
		})
	);

	const masterProjectTask = formattedMasterTasks.map((masterTask) => ({
		projectName: masterTask.projectName,
		companyName: masterTask.companyName,
		taskName: masterTask.taskName,
		timesheets: masterTask.timesheets,
	}));

	return [...projectTask, ...orgTask, ...masterProjectTask];
};

export const getTimesheetSubmissions = (
	data: GetWeeklyTimesheetEntriesQuery
) => {
	if (!data) {
		return;
	}
	const projectSubmissions = data?.project_task || [];
	const masterProjectSubmissions = data?.master_project_task || [];
	const orgTaskSubmissions = data?.org_tasks || [];

	return [
		...projectSubmissions,
		...masterProjectSubmissions,
		...orgTaskSubmissions,
	];
};

export const checkIfTimeInputValueHasError = (timeInputValue: string) => {
	if (timeInputValue.trim() === '00:00' || timeInputValue.trim() === '') {
		return false;
	}

	if (checkIfOnlyNumbers.test(timeInputValue)) {
		return false;
	}

	// Check if hrs or minutes reached max limit
	if (timeInputValue.includes(':')) {
		if (Number(timeInputValue.split(':')[0]) > 23) {
			return true;
		}
		if (Number(timeInputValue.split(':')[1]) > 59) {
			return true;
		}
	}

	// Check if only numbers are there in input and check if it is greater than 23
	if (checkIfOnlyOneColonAfterNumbers.test(timeInputValue)) {
		if (Number(timeInputValue.split(':')[0]) > 23) {
			return true;
		}
		return false;
	}

	// Check for format HH:m (11:1)
	if (checkIfOnlyOneNumberAfterColon.test(timeInputValue)) {
		return false;
	}

	// Check valid format HH:MM
	if (validHHMMFormat.test(timeInputValue)) {
		return false;
	}
	return true;
};

export const getTimesheetWeek = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets || !timesheets[0]?.date) {
		return;
	}
	const startDate = dayjs(timesheets[0].date).day(0).format('DD MMM YYYY');
	const endDate = dayjs(timesheets[0].date).day(6).format('DD MMM YYYY');

	return `${startDate || ''} - ${endDate || ''}`;
};

export const getTotalHours = (timesheets: SubmittedTimesheets[]) => {
	if (!timesheets) {
		return;
	}
	const timesheetDailyHours = timesheets.map(
		(timesheet: any) => timesheet.working_hours
	);

	const totalHours = timesheetDailyHours
		.map((timesheetDailyHour: any) => timesheetDailyHour.split(':'))
		.map((hoursAndMinutes: any) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total: any, durations: any) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);

	return (
		`${Math.floor(totalHours.asHours())}:${totalHours.format('mm')}` || '00:00'
	);
};

export const getProjectOptions = (projectOptions: GetProjectsOptionsQuery) => {
	if (!projectOptions) {
		return;
	}
	const projects = projectOptions?.project_resource_mapping.map(
		(projectResourceMapping) => ({
			id: projectResourceMapping.project.id,
			name: projectResourceMapping.project.name,
			companyName: projectResourceMapping.project.company?.name,
			isBillable: projectResourceMapping.project.is_billable,
			type: PROJECT_TYPE_CATEGORY,
		})
	);
	const orgTaskCategories = projectOptions.org_tasks.map((task) => ({
		id: task.task_category,
		name: task.task_category,
		companyName: task.organization.name,
		isBillable: true, // org tasks is only based on if task is billable or not
		type: task.task_category,
	}));

	return [...orgTaskCategories, ...projects];
};

export const getTotalHoursByDay = (
	date: string,
	totalHours: GetTimesheetTotalHoursQuery
) => {
	if (!totalHours || !date) {
		return;
	}
	const selectedHoursAndMinutes = totalHours.timesheet_daily_hours.find(
		(totalHours) => totalHours?.date === date
	);
	const hoursAndMinutes = selectedHoursAndMinutes
		? selectedHoursAndMinutes.sum.split(':')
		: [];

	return `${hoursAndMinutes[0] || '00'}:${hoursAndMinutes[1] || '00'}`;
};

export const getTimesheetSubmissionId = (
	data: GetTimesheetTotalHoursQuery | undefined
) => {
	if (!data) {
		return;
	}
	const selectedWeekSubmission = data.timesheet_daily_hours.find(
		(totalHours) => totalHours?.timesheet_submission_id !== null
	);
	return selectedWeekSubmission?.timesheet_submission_id;
};

export const getTimesheetDisableStatus = (
	disableTimesheetAfterSubmission: boolean,
	timesheetStatus: string | null | undefined
) => {
	if (timesheetStatus === TIMESHEET_APPROVED_STATUS) {
		return true;
	}
	if (
		!disableTimesheetAfterSubmission &&
		timesheetStatus === TIMESHEET_SUBMITTED_STATUS
	) {
		return false;
	}
	if (
		disableTimesheetAfterSubmission &&
		timesheetStatus === TIMESHEET_SUBMITTED_STATUS
	) {
		return true;
	}
	return false;
};
