import { Box, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router';
import React from 'react';
import { KanbanIcon } from '../../../assets/SvgIcons';
import { BreadcrumStyles, ellipsisStyle } from '../../../Layout/styles';
export interface Props {
	name: string;
	path?: any;
}
interface BreadcumProps {
	data: Props[];
	icon: any;
	redirectUrl?: string;
	isNavigationEnabled?: boolean;
	navigateUrl?: string;
}

const PMSBreadcrumb = (props: BreadcumProps) => {
	const history = useHistory();
	const {
		data,
		icon,
		redirectUrl,
		isNavigationEnabled = false,
		navigateUrl,
	} = props;
	const classes = BreadcrumStyles();
	const style = ellipsisStyle();
	const navigate = () => {
		if (!redirectUrl) {
			history.goBack();
		} else {
			history.push(redirectUrl);
		}
	};

	const navigateToKanban = () => {
		if (!navigateUrl) {
			history.goBack();
		} else {
			history.push(navigateUrl);
		}
	};

	return (
		<Box
			display='flex'
			justifyContent='space-between'
			height='60px'
			alignItems={'center'}
		>
			<Box className={classes.toolBar}>
				{data &&
					data?.length > 0 &&
					data?.map((value: Props) => {
						if (!value?.name) {
							return;
						}
						return (
							<>
								<Box
									display='flex'
									justifyContent='space-around'
									alignItems='center'
								>
									<Tooltip title={`${value?.name || '- -'}`} placement='right'>
										<Box
											className={
												value?.path
													? classes.title
													: `${classes.details} ${style.ellipsis}`
											}
											onClick={() => history.push(value?.path)}
										>
											  {value?.name}
										</Box>
									</Tooltip>
									{data[data?.length - 1]?.name !== value?.name &&
										icon &&
										React.createElement(icon, { className: classes.arrowIcon })}
								</Box>
							</>
						);
					})}
			</Box>
			<Box>
				{isNavigationEnabled && (
					<Tooltip title='Navigate to Project kanban'>
						<button onClick={navigateToKanban} className={classes.navigate}>
							<KanbanIcon />
						</button>
					</Tooltip>
				)}
				<button onClick={navigate} className={classes.backButton}>
					Back
				</button>
			</Box>
		</Box>
	);
};

export default PMSBreadcrumb;
