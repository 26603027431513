import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	IconButton,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	maxLength,
	minLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';

import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

import {
	useCustomFieldTypeQuery,
	useGetSubModulesQuery,
	useUpsertCustomFieldsMutation,
} from '../../../../generated/graphql';
import {
	CUSTOM_FIELD_CREATE_MESSAGE,
	CUSTOM_FIELD_UPDATE_MESSAGE,
	CUSTOM_FIELD_WARNING,
	OPTION_MESSAGE,
	UNIQUENESS_VIOLATION_ERROR,
} from './constant';
import { head } from 'lodash';
import { validateName } from '../../constant';

export interface FormValue {
	id: string;
	label: string;
	index: number;
	options?: any;
	type: string;
	sub_module_id: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	refetch: () => {};
	maxValue: number;
	isEdit: boolean;
	initialValues: any;
	setModule: (value: string) => void;
	setEmployeeModuleId: (value: string) => void;
	setContractorModuleId: (value: string) => void;
	setProjectModuleId: (value: string) => void;
	fieldEditable: number;
}

const CustomFieldForm = (props: Props) => {
	const {
		open,
		onClose,
		refetch,
		initialValues,
		maxValue,
		isEdit,
		setModule,
		setEmployeeModuleId,
		setContractorModuleId,
		setProjectModuleId,
		fieldEditable,
	} = props;
	const classes = modalFormStyle();
	const notify = useNotify();
	const [moduleId, setModuleId] = useState('');
	const [selectedType, setSelectedType] = useState('');
	const [employeeModule, setEmployeeModule] = useState('');
	const [contractorModule, setContractorModule] = useState('');
	const [projectModule, setProjectModule] = useState('');
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [error, setError] = useState('');

	const { data: subModules } = useGetSubModulesQuery({
		fetchPolicy: 'network-only',
	});

	const { data: customFieldType } = useCustomFieldTypeQuery({
		fetchPolicy: 'network-only',
	});

	const [upsertCustomField, { loading: upsertLoading }] =
		useUpsertCustomFieldsMutation();

	useEffect(() => {
		if (!subModules) {
			return;
		}
		const employeeModule = subModules?.master_sub_modules?.find(
			(value) => value?.value === 'employee'
		);
		setEmployeeModule(employeeModule?.id);
		setEmployeeModuleId(employeeModule?.id);
		const contractorModule = subModules?.master_sub_modules?.find(
			(value) => value?.value === 'contractor'
		);
		setContractorModule(contractorModule?.id);
		setContractorModuleId(contractorModule?.id);
		const projectModule = subModules?.master_sub_modules?.find(
			(value) => value?.value === 'project'
		);
		setProjectModule(projectModule?.id);
		setProjectModuleId(projectModule?.id);
	}, [
		subModules,
		setEmployeeModuleId,
		setContractorModuleId,
		setProjectModuleId,
	]);

	const validateOption = (value: string) => {
		if (selectedType !== 'select') {
			return undefined;
		}
		if (!value) return 'Required';
	};

	const onSave = (formValues: FormValue) => {
		if (!isEdit && maxValue === 10) {
			setError(CUSTOM_FIELD_WARNING);
			return;
		}
		const { index, label, ...restFormValues } = formValues;
		const options =
			formValues?.options && formValues?.type === 'select'
				? formValues?.options
						?.split(',')
						.filter((entry: string) => entry.trim() !== '')
						.map((option: string) => ({
							id: option.trim(),
							name: option.trim(),
						}))
				: null;
		const fieldName = label?.trim();

		upsertCustomField({
			variables: {
				customField: {
					...restFormValues,
					label: fieldName,
					sub_module_id: isEdit ? initialValues?.sub_module_id : moduleId,
					id: formValues?.id,

					index: isEdit ? initialValues?.index : maxValue,
					options: options || null,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					if (formValues?.id) {
						notify(CUSTOM_FIELD_UPDATE_MESSAGE);
						refetch();
						onClose();
						setError('');
						setSelectedType('');
					} else {
						notify(CUSTOM_FIELD_CREATE_MESSAGE);
						refetch();
						onClose();
						setError('');
						setSelectedType('');
					}
					setError('');
				}
				setButtonDisabled(false);
			})
			.catch((error) => {
				if (
					error.message.includes('custom_field_label_sub_module_id_org_id_key')
				) {
					notify(UNIQUENESS_VIOLATION_ERROR, 'warning');

					setButtonDisabled(true);
					setSelectedType('');
				}
				setButtonDisabled(false);
			});
	};
	const handleClose = () => {
		setError('');
		onClose();
		setButtonDisabled(false);
		setSelectedType('');
	};

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{isEdit ? 'EDIT CUSTOM FIELD' : 'ADD CUSTOM FIELD'}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={isEdit ? initialValues : {}}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Module*</Typography>
										<SelectInput
											source='sub_module_id'
											label=''
											choices={[
												{ id: employeeModule, name: 'Employee' },
												{ id: contractorModule, name: 'Contractor' },
												{ id: projectModule, name: 'Project' },
											]}
											validate={required()}
											onChange={(event) => {
												if (!event) {
													return;
												}

												setModuleId(event.target?.value);
												setModule(event.target.value);
												setError('');
											}}
											disabled={isEdit}
										/>
										{error && (
											<Box color='red' marginTop={'-7px'} fontSize={'11px'}>
												{error}
											</Box>
										)}
										<Typography className={classes.label}>Name*</Typography>
										<TextInput
											source='label'
											label=''
											validate={[
												required(),
												validateName,
												maxLength(40),
												minLength(3),
											]}
										/>

										<Typography className={classes.label}>Type*</Typography>
										<SelectInput
											source='type'
											label={''}
											choices={head(customFieldType?.master_settings)?.value}
											validate={required()}
											disabled={isEdit}
											onChange={(event) => {
												if (!event) {
													return;
												}

												setSelectedType(event.target?.value);

												setError('');
											}}
										/>
										{(selectedType === 'select' ||
											initialValues?.type === 'select') && (
											<>
												<Typography className={classes.label}>
													Options*
												</Typography>
												<Tooltip title={OPTION_MESSAGE} placement='top'>
													<IconButton
														className={classes.iconContainer}
														style={{ marginTop: '-23px', marginLeft: '35px' }}
													>
														<InfoIcon className={classes.infoIcon} />
													</IconButton>
												</Tooltip>

												<TextInput
													source='options'
													label=''
													disabled={
														isEdit
															? initialValues?.type !== 'select' ||
															  fieldEditable > 0
															: selectedType !== 'select'
													}
													validate={[validateOption]}
												/>
											</>
										)}
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											title='Save'
											isDisabled={invalid || pristine || buttonDisabled}
											isLoading={upsertLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
		</>
	);
};
export default CustomFieldForm;
