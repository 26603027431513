import React, { useState } from 'react';
import {
	Datagrid,
	FunctionField,
	List,
	useNotify,
	useRefresh,
} from 'react-admin';
import { datagridStyle, ellipsisStyle } from '../../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { settingsStyle } from '../../settingsStyle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	Box,
	Tooltip,
	Typography,
	TextField as TextComponent,
	IconButton,
	Button,
} from '@material-ui/core';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../../SharedComponents/WarningModal.component';
import {
	DELETE_TAG_WARNING_MESSAGE,
	PROJECT_TAG_DELETE_SUCCESS_MESSAGE,
	WARNING_TAG_MODAL_MESSAGE,
} from './../constant';
import {
	useDeleteProjectResourceTagMutation,
	useGetProjectResourceTagCountQuery,
} from '../../../../generated/graphql';
import { inlineStyle } from '../style';
import ProjectResourceTagForm from './ProjectResourceTagForm.component';

interface Toolbar {
	searchText: string;
	onSearch: (value: string) => void;
	setIsResourceTagFormShown: (value: boolean) => void;
}
const ToolBar = (props: Toolbar) => {
	const { searchText, onSearch, setIsResourceTagFormShown } = props;
	const inlineStyles = inlineStyle();
	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			marginBottom={'12px'}
		>
			<Box>
				<ThemeProvider theme={searchBarTheme}>
					<Box width='250px' marginTop={'-1px'}>
						<TextComponent
							placeholder='Search Tag'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchIcon />,
							}}
							onChange={(e) => {
								onSearch(e?.target?.value);
							}}
							value={searchText || []}
						/>
					</Box>
				</ThemeProvider>
			</Box>
			<Box>
				<Button
					variant='contained'
					className={inlineStyles.saveButton}
					onClick={() => {
						setIsResourceTagFormShown(true);
					}}
				>
					Add Tag
				</Button>
			</Box>
		</Box>
	);
};
const ProjectResourceTag = () => {
	const notify = useNotify();
	const userefresh = useRefresh();
	const classes = inlineStyle();
	const tooltipStyle = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const settingsStyles = settingsStyle();
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectReourceTagData, setProjectResourceTagData] = useState({});
	const [isResourceTagFormShown, setIsResourceTagFormShown] = useState(false);
	const [searchedLabel, setSearchedLabel] = useState('');
	const [deleteId, setDeleteId] = useState<string | null>();

	const [deleteTag] = useDeleteProjectResourceTagMutation();
	useGetProjectResourceTagCountQuery({
		variables: {
			id: deleteId,
		},
		fetchPolicy: 'network-only',
		skip: !deleteId,
		onCompleted: (data) =>
			handleWarningModal(
				data.project_resource_tag_by_pk?.project_resource_mappings_aggregate
					?.aggregate?.count || 0
			),
	});
	const handleWarningModal = (projectCount: number) => {
		if (projectCount > 0) {
			setIsWarningModalShown(true);
			setIsDeleteModalShown(false);
		} else {
			setIsWarningModalShown(false);
			setIsDeleteModalShown(true);
		}
	};
	const handleDelete = () => {
		deleteTag({
			variables: {
				tagId: deleteId,
			},
		}).then((data) => {
			notify(PROJECT_TAG_DELETE_SUCCESS_MESSAGE);
			userefresh();
			setIsResourceTagFormShown(false);
			setIsDeleteModalShown(false);
		});
	};

	return (
		<>
			<Box className={settingsStyles.content}>
				<ToolBar
					searchText={searchedLabel}
					onSearch={(value: string) => setSearchedLabel(value)}
					setIsResourceTagFormShown={(value: boolean) =>
						setIsResourceTagFormShown(value)
					}
				/>

				<List
					empty={false}
					resource='project_resource_tag'
					basePath='/settings'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: `%${searchedLabel}%` }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='TAG'
							render={(project: any) => {
								return (
									<Tooltip title={`${project.name || '--'}`} placement='right'>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.name || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(project: any) => {
								return (
									<Tooltip
										title={`${project.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label=''
							render={(record: any) => {
								return (
									<Box display='flex' alignItems='center'>
										<Box
											className={datagridStyles.listEditIconContainer}
											mr={1}
										>
											<IconButton
												className={datagridStyles.listEditIconButton}
												onClick={() => {
													const { org_id, created_at, ...restValues } = record;
													setProjectResourceTagData(restValues);
													setIsResourceTagFormShown(true);
												}}
											>
												<EditIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
										<Box className={datagridStyles.listEditIconContainer}>
											<IconButton
												className={`${datagridStyles.listEditIconButton} ${datagridStyles.deleteIconButton}`}
												onClick={(e) => {
													setDeleteId(record.id);
												}}
											>
												<DeleteIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
									</Box>
								);
							}}
						/>
					</Datagrid>
				</List>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
						setDeleteId(null);
					}}
					confirmationMessage={DELETE_TAG_WARNING_MESSAGE}
					onDelete={() => {
						handleDelete();
					}}
				/>
				<ProjectResourceTagForm
					open={isResourceTagFormShown}
					onClose={() => {
						setIsResourceTagFormShown(false);
						setProjectResourceTagData({});
					}}
					resourceTag={projectReourceTagData}
				/>
				<WarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
						setDeleteId(null);
					}}
					warningMessage={WARNING_TAG_MODAL_MESSAGE}
				/>
			</Box>
		</>
	);
};

export default ProjectResourceTag;
