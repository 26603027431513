import React, { useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	ReferenceInput,
	regex,
	required,
	useMutation,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import {
	validateWebsite,
	validatePhoneNumber,
	validateLinkedIn,
} from '../../Utils/string.util';
import { Button, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../generated/graphql';

export const AddCompanyModal = (props) => {
	const { onClose, open } = props;
	const classes = modalFormStyle();
	const [isOpen, setIsOpen] = useState(false);
	const [ownerId, setOwnerId] = useState(null);
	const [mutate, { loading: isCompanyLoading }] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	const onModalClose = () => {
		setIsOpen(false);
		onClose();
	};

	useEffect(() => {
		if (open) {
			setIsOpen(open);
		}
	}, [open]);

	const onSave = (company) => {
		mutate(
			{
				type: 'create',
				resource: 'crm_company',
				payload: {
					data: {
						name: company?.name,
						address: company?.address,
						category_id: company?.category_id,
						description: company?.description,
						owner_id: ownerId || null,
						sector_id: company?.sector,
						size_id: company?.size,
						website: company?.website,
						linkedin: company?.linkedin,
						phone: company?.phone,
						contact_type_id: company?.contact_type,
						country_id: company?.country_id,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					onClose();
					onModalClose();
					notify('Company created');
					refresh();
				},
				onFailure: (error) =>
					notify(`Create failed: ${error.message}`, 'warning'),
			}
		);
		return;
	};

	const getOwnerValue = (userId) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={isOpen}
				onClose={onModalClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>ADD COMPANY </Box>
						<CloseIcon onClick={onModalClose} className={classes.closeIcon} />
					</Box>
					<Form onSubmit={onSave}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.label}>Company Name*</Box>
										<TextInput
											placeholder='Enter Company Name Here'
											source='name'
											fullWidth
											validate={required()}
											label={false}
										/>
										<Box className={classes.label}>Address</Box>
										<TextInput
											source='address'
											placeholder='Enter Address Name Here'
											fullWidth
											label={false}
										/>
										<Box className={classes.label}>Country</Box>
										<ReferenceInput
											source='country_id'
											reference='geo_list'
											sort={{ field: 'name', order: 'ASC' }}
											label=''
										>
											<SelectInput optionText='name' />
										</ReferenceInput>
										<Box className={classes.label}>Category</Box>

										<ReferenceInput
											source='category_id'
											reference='company_categories'
											sort={{ field: 'label', order: 'ASC' }}
											label=''
										>
											<SelectInput optionText='label' />
										</ReferenceInput>
										<Box className={classes.label}>Account Owner</Box>
										<AutocompleteSearch
											placeholder={'Search Owner'}
											option={
												activeUsers && activeUsers?.user
													? activeUsers?.user?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setOwnerId}
											value={getOwnerValue(ownerId || '')}
											name={'reportee_id'}
											validates={false}
										/>
										<Box className={classes.label}>Sector</Box>

										<ReferenceInput
											source='sector'
											reference='company_sector'
											label=''
											sort={{ field: 'label', order: 'ASC' }}
										>
											<SelectInput optionText='label' />
										</ReferenceInput>
										<Box className={classes.label}>Size</Box>

										<ReferenceInput
											source='size'
											reference='company_size'
											label=''
											sort={{ field: 'label', order: 'ASC' }}
										>
											<SelectInput optionText='label' />
										</ReferenceInput>
										<Box className={classes.label}>Website</Box>

										<TextInput
											source='website'
											fullWidth
											label={false}
											validate={[
												regex(validateWebsite, 'Must be a valid Link'),
											]}
										/>
										<Box className={classes.label}>Linkedin Url</Box>
										<TextInput
											source='linkedin'
											label={false}
											fullWidth
											validate={[
												regex(validateLinkedIn, 'Must be a valid Link'),
											]}
										/>
										<Box className={classes.label}>Phone Number</Box>

										<TextInput
											source='phone'
											validate={[
												regex(validatePhoneNumber, 'Must be a number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											label={false}
											fullWidth
										/>
										<Box className={classes.label}>Contact type</Box>

										<ReferenceInput
											source='contact_type'
											reference='company_contact_type'
											label=''
											sort={{ field: 'label', order: 'ASC' }}
										>
											<SelectInput optionText='label' />
										</ReferenceInput>
										<Box className={classes.label}>Description</Box>
										<TextInput source='description' label={false} fullWidth />
									</DialogContent>
								</Box>
								<DialogActions>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={onModalClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid || isCompanyLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isCompanyLoading}
											type='submit'
										>
											Add Company
										</Button>
									</Box>
								</DialogActions>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};
export default AddCompanyModal;
