import React, { useState, useEffect, useContext } from 'react';
import { useNotify } from 'react-admin';

import { Form, Field } from 'react-final-form';
import {
	autoCompleteTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	AutocompleteSearchStyles,
	dropDownEllipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	DateTimeUtil,
	isDateBetweenRange,
} from '../../../Utils/date-time.util';
import {
	UPSERT_PROJECT_RESOURCE_MAPPING,
	GET_USER_OPTION,
	GET_ORGANISATION_BASE_CURRENCY,
	GET_USER_TEAMS,
	GET_USER_BY_TEAMS,
	GET_LEAVE_DAYS,
	GET_RESOURCE_TAGS,
	GET_SKILL_LEVEL_OPTION,
	GET_SKILL_OPTION,
} from '../gqlQueries';

import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';

import {
	Button,
	Box,
	Dialog,
	Typography,
	createTheme,
	makeStyles,
	ThemeProvider,
	Tooltip,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { opportunityDetailStyle } from '../../Opportunities/DetailsPage/style';
import {
	formatDecimal,
	getThousandSeparatorFormat,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import { UserProfileContext } from '../../../App';
import head from 'lodash/head';

import { orderBy, uniqBy } from 'lodash';
import { GET_ORGANISATION_MAN_HOURS } from '../../gqlQueries';
import { Autocomplete } from '@material-ui/lab';

import { SliderInput } from './SliderComponent.component';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { RESOURCE_ALLOCATION_START_DATE_VALIDATION_MESSAGE } from './constant';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetHolidaysForSettingsQuery,
	useGetLocationIdByUserIdQuery,
} from '../../../generated/graphql';
import useGetExcludedLeaves from '../../Timesheet/useGetExcluedLeaves';
import { SearchIcon } from '../../../assets/SvgIcons';

const addResourceModalTheme = createTheme({
	overrides: {
		MuiDialog: {
			paperWidthSm: {
				maxWidth: 'fit-content',
			},
		},
	},
});

const addResourceModalStyle = makeStyles({
	container: {
		minHeight: 'fit-content',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	label: {
		fontSize: '0.85rem',
		height: '19px',
	},
	inputContainer: {
		minWidth: '160px',
		marginRight: '8px',
	},
	autoCompleteInputContainer: {
		minWidth: '200px',
		marginRight: '8px',
	},
	allocationPercentageContainer: {
		minWidth: '160px',
		marginLeft: '16px',
	},
	whitSpaceNowrap: {
		width: 'auto',
		whiteSpace: 'nowrap',
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
		marginLeft: '10px',
		height: '100%',
	},
	billableContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
		height: '100%',
		marginRight: '32px',
		justifyContent: 'center',
	},
	checkBoxInput: {
		height: '18px',
		width: '18px',
	},
	checkBoxLabel: {
		marginLeft: '8px',
	},
	resourceCostInput: {
		padding: '0px 8px !important',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '0px 4px 4px 0px',
		width: '70px',
		height: '28px !important',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	baseCurrency: {
		padding: '0px 8px !important',
		border: '1px solid #E0E0E0',
		borderRadius: '4px 0px 0px 4px',
		width: '45px',
		height: '28px !important',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		color: '#5C5C5C',
		background: 'rgba(0, 0, 0, 0.12)',
	},
	plannedHours: {
		fontSize: '14px',
		fontFamily: 'Manrope-semiBold',
		letterSpacing: '0',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},
});

const AddAllocationForm = (props) => {
	const {
		open,
		onClose,
		initialValues,
		projectId,
		projectStartDate,
		projectEndDate,
		budget,
		currencySymbol,
		plannedCostAndHours,
		projectResourceMappings,
		onSuccess,
		effort_estimate,
	} = props;
	const { orgId, featureFlag, noOfLocations } = useContext(UserProfileContext);
	const [holidayStartDate, setHolidayStartDate] = useState();
	const [holidayEndDate, setHolidayEndDate] = useState(
		initialValues?.end_date || projectEndDate
	);
	const notify = useNotify();
	const formStyles = modalFormStyle();
	const ellipsis = dropDownEllipsisStyle();
	const addResourceModalStyles = addResourceModalStyle();
	const [skilledUserCost, setSkilledUserCost] = useState(0);
	const [selectedSkillId, setSelectedSkillId] = useState(null);
	const [selectedSkillLevelId, setSelectedSkillLevelId] = useState(null);
	const [userOptions, setUserOptions] = useState(null);
	const [resourceEndDate, setResourceEndDate] = useState(null);
	const [resourceStartDate, setResourceStartDate] = useState(null);
	const opportunityStyles = opportunityDetailStyle();
	const [userOptionsQueryFilter, setUserOptionsQueryFilter] = useState({});
	const [resourceAllocationValue, setResourceAllocationValue] = useState(100);
	const [isSliderDisabled, setIsSliderDisabled] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [userId, setUserId] = useState(null);
	const [userTeam, setUserTeam] = useState(null);
	const [holidays, setHolidays] = useState();
	const [userLocationId, setUserLocationId] = useState();
	const [tagId, setTagId] = useState(null);
	const [userClientRate, setUserClientRate] = useState(0);
	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(false);
	const [excludedLeaves] = useGetExcludedLeaves(
		projectStartDate,
		projectEndDate,
		userId,
		userLocationId
	);
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const disableButton = () => {
		setSaveButtonDisabled(false);
	};
	const preventMultipleClick = preventSubsequentClick(disableButton, 1000);
	const { data: locationDetails } = useGetLocationIdByUserIdQuery({
		variables: { userId: userId },
		skip: !userId,
		fetchPolicy: 'network-only',
	});
	const { data: resourceTagList } = useApolloQuery(GET_RESOURCE_TAGS, {
		variables: { orgId: orgId },
		skip: !orgId,
		fetchPolicy: 'network-only',
	});
	const { data: leaveData } = useApolloQuery(GET_LEAVE_DAYS, {
		variables: {
			userId: userId,
			startDate: projectStartDate,
			endDate: projectEndDate,
			holidayFilter:
				userLocationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: projectStartDate,
								_lte: projectEndDate,
							},
							location_id: { _eq: userLocationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: projectStartDate,
								_lte: projectEndDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				userLocationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: projectStartDate,
									_lte: projectEndDate,
								},
								location_id: { _eq: userLocationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: projectStartDate,
									_lte: projectEndDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip: !userId,
	});

	const { data: leavesWithinPeriod, loading: holidaysLoading } = useApolloQuery(
		GET_LEAVE_DAYS,
		{
			variables: {
				userId: userId,
				startDate: holidayStartDate,
				endDate: holidayEndDate,
				holidayFilter:
					userLocationId && noOfLocations && noOfLocations > 0
						? {
								is_restricted: { _eq: false },
								date: {
									_gte: holidayStartDate,
									_lte: holidayEndDate,
								},
								location_id: { _eq: userLocationId },
						  }
						: {
								is_restricted: { _eq: false },
								date: {
									_gte: holidayStartDate,
									_lte: holidayEndDate,
								},
						  },
				optionalHolidayFilter:
					userLocationId && noOfLocations && noOfLocations > 0
						? {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: holidayStartDate,
										_lte: holidayEndDate,
									},
									location_id: { _eq: userLocationId },
								},
						  }
						: {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: holidayStartDate,
										_lte: holidayEndDate,
									},
								},
						  },
			},
			fetchPolicy: 'network-only',
			skip: !userId,
		}
	);
	const { data: orgHolidays } = useGetHolidaysForSettingsQuery({
		variables: {
			filter: !userId
				? {
						is_restricted: { _eq: false },
						date: {
							_gte: holidayStartDate,
							_lte: holidayEndDate,
						},
				  }
				: userLocationId && noOfLocations && noOfLocations > 0
				? {
						is_restricted: { _eq: false },
						date: {
							_gte: holidayStartDate,
							_lte: holidayEndDate,
						},
						location_id: { _eq: userLocationId },
				  }
				: {
						is_restricted: { _eq: false },
						date: {
							_gte: holidayStartDate,
							_lte: holidayEndDate,
						},
				  },
		},
		fetchPolicy: 'network-only',
	});
	const { data: user } = useApolloQuery(GET_USER_OPTION, {
		variables: {
			where: {
				is_active: {
					_eq: true,
				},
				...userOptionsQueryFilter,
			},
		},
		fetchPolicy: 'network-only',
	});
	const { data: allUser } = useApolloQuery(GET_USER_OPTION, {
		fetchPolicy: 'network-only',
	});
	const { data: skillLevel } = useApolloQuery(GET_SKILL_LEVEL_OPTION, {
		fetchPolicy: 'network-only',
	});

	const skillLevelOptions = skillLevel?.skill_level || [];

	const { data: skill } = useApolloQuery(GET_SKILL_OPTION, {
		fetchPolicy: 'network-only',
	});

	const skillOptions = skill?.skill_master || [];

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeam
				? {}
				: {
						_eq: userTeam,
				  },
		},
		fetchPolicy: 'network-only',
	});

	const [upsertResourceMapping, { loading: isAllocationLoading }] =
		useApolloMutation(UPSERT_PROJECT_RESOURCE_MAPPING);
	const { data: organisation } = useApolloQuery(
		GET_ORGANISATION_BASE_CURRENCY,
		{
			variables: {
				organizationId: orgId,
			},
			fetchPolicy: 'network-only',
		}
	);

	const successMessage = initialValues?.id
		? 'Project Allocation Updated Successfully'
		: 'Project Allocation Created Successfully';

	const { orgId: organisation_Id, dateFormat } = useContext(UserProfileContext);
	// Getting Manhours from settings
	const { data: orgManHours } = useApolloQuery(GET_ORGANISATION_MAN_HOURS, {
		variables: {
			orgId: organisation_Id,
		},
	});

	useEffect(() => {
		if (!locationDetails) {
			return;
		}
		const userLocationId =
			locationDetails && locationDetails?.user_by_pk
				? locationDetails?.user_by_pk?.user_type === 'employee'
					? locationDetails?.user_by_pk?.employee?.location_id
					: head(locationDetails?.user_by_pk?.contractors)?.location_id
				: null;
		setUserLocationId(userLocationId);
	}, [locationDetails]);

	useEffect(() => {
		if (!leaveData) {
			return;
		}

		const regularHolidays =
			leaveData?.org_holidays?.map((value) => {
				return {
					name: value?.name,
					date: value?.date,
				};
			}) || [];
		const optionalHolidays =
			leaveData?.user_optional_holiday_mapping?.map((value) => {
				return {
					name: value?.org_holiday?.name,
					date: value?.org_holiday?.date,
				};
			}) || [];
		setHolidays(
			(noOfLocations && noOfLocations > 0 && userLocationId) ||
				!noOfLocations ||
				noOfLocations === 0
				? [...regularHolidays, ...optionalHolidays]
				: []
		);
	}, [leaveData, noOfLocations, userLocationId]);

	useEffect(() => {
		if (!user) {
			return;
		}
		if (!selectedSkillId && !selectedSkillLevelId) {
			const userInfo = !userTeam
				? user.user.map((choice) => {
						if (choice?.user_type === 'employee') {
							return {
								id: choice?.id,
								name: `${choice?.full_name} - $${
									choice?.employees[0]?.emp_cost || 0
								}`,
							};
						} else {
							return {
								id: choice?.id,
								name: `${choice?.full_name}  (C) - $${
									choice?.contractors[0]?.emp_cost || 0
								}`,
							};
						}
				  })
				: usersByTeam?.user_team_mapping?.map((choice) => {
						if (choice?.user.user_type === 'employee') {
							return {
								id: choice?.user.id,
								name: `${choice?.user.full_name} - $${
									choice?.user.employees[0]?.emp_cost || 0
								}`,
							};
						} else {
							return {
								id: choice?.user.id,
								name: `${choice?.user.full_name} (C) - $${
									choice?.user.contractors[0]?.emp_cost || 0
								}`,
							};
						}
				  }) || [];
			const sortedUserInfo = orderBy(
				userInfo,
				[(user) => user.name.toUpperCase()],
				['asc']
			);
			setUserOptions(sortedUserInfo);
		} else {
			const userTeamUserIds = userTeam
				? usersByTeam?.user_team_mapping?.map((userTeam) => userTeam?.user.id)
				: [];
			const userInfo = !userTeam
				? user.user.map((choice) => {
						if (choice?.user_type === 'employee') {
							return {
								id: choice?.id,
								name: `${choice?.full_name}  - $${
									choice?.employees[0]?.emp_cost || 0
								}`,
							};
						} else {
							return {
								id: choice?.id,
								name: `${choice?.full_name}  (C) - $${
									choice?.contractors[0]?.emp_cost || 0
								}`,
							};
						}
				  })
				: user?.user
						.filter((usr) => userTeamUserIds?.includes(usr.id))
						.map((choice) => {
							if (choice?.user_type === 'employee') {
								return {
									id: choice?.id,
									name: `${choice?.full_name}  - $${
										choice?.employees[0]?.emp_cost || 0
									}`,
								};
							} else {
								return {
									id: choice?.id,
									name: `${choice?.full_name}  (C) - $${
										choice?.contractors[0]?.emp_cost || 0
									}`,
								};
							}
						});
			const sortedUserInfo = orderBy(
				userInfo,
				[(user) => user.name.toUpperCase()],
				['asc']
			);
			setUserOptions(sortedUserInfo);
		}
	}, [user, selectedSkillId, selectedSkillLevelId, userTeam, usersByTeam]);

	useEffect(() => {
		if (!selectedSkillId && !selectedSkillLevelId) {
			setUserOptionsQueryFilter({});
			return;
		}
		setUserOptionsQueryFilter({
			employee_skill_mappings: {
				skill_id: selectedSkillId !== null ? { _eq: selectedSkillId } : {},
				skill_level_id:
					selectedSkillLevelId !== null ? { _eq: selectedSkillLevelId } : {},
			},
		});
	}, [selectedSkillLevelId, selectedSkillId]);

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		if (initialValues?.id) {
			setHolidayStartDate(initialValues.start_date);
			setHolidayEndDate(initialValues.end_date);
			setSkilledUserCost(initialValues.resource_cost);
			setResourceAllocationValue(initialValues.allocation_percentage);
			setUserClientRate(initialValues?.client_rate);
			setTagId(initialValues?.tag_id || null);
			const selectedUser = allUser?.user.find(
				(user) => user.id === initialValues.employee_id
			);
			if (selectedUser?.user_type === 'employee') {
				setResourceEndDate(selectedUser?.employees[0]?.last_date || 0);
				setResourceStartDate(selectedUser?.employees[0]?.join_date || 0);
			} else {
				setResourceStartDate(selectedUser?.contractors[0]?.join_date || 0);
				setResourceEndDate(selectedUser?.contractors[0]?.end_date || 0);
			}
			setUserId(initialValues?.employee_id);
		}
		setHolidayStartDate(initialValues.start_date || projectStartDate);
		setHolidayEndDate(initialValues.end_date || projectEndDate);
	}, [initialValues, allUser, projectStartDate, projectEndDate]);

	const getResourceEndDate = (userId) => {
		if (!userId) {
			return;
		}
		const selectedUser = allUser?.user.find((user) => user.id === userId);

		if (selectedUser?.user_type === 'employee') {
			setResourceEndDate(selectedUser?.employees[0]?.last_date || 0);
			setSkilledUserCost(selectedUser?.employees[0]?.emp_cost || 0);
			setResourceStartDate(selectedUser?.employees[0]?.join_date || 0);
		} else {
			setResourceStartDate(selectedUser?.contractors[0]?.join_date || 0);
			setResourceEndDate(selectedUser?.contractors[0]?.end_date || 0);
			setSkilledUserCost(selectedUser?.contractors[0]?.contractor_cost || 0);
		}
	};
	const _getHoursAndCost = (allocationMappingRow, userCost) => {
		if (
			!allocationMappingRow?.allocation_percentage ||
			!orgManHours?.organization_by_pk?.working_hours
		) {
			return { cost: 0, hours: 0 };
		}
		const startDate = new Date(allocationMappingRow.start_date);
		const endDate = new Date(allocationMappingRow.end_date);
		const days = DateTimeUtil.getBusinessDaysBetween(startDate, endDate);
		const allocationFactor = allocationMappingRow?.allocation_percentage / 100;
		const settingHours = orgManHours?.organization_by_pk?.working_hours;
		const regularHolidays =
			orgHolidays?.org_holidays
				?.filter((value) => isDateBetweenRange(startDate, endDate, value?.date))
				?.map((value) => {
					return {
						name: value?.name,
						date: value?.date,
					};
				}) || [];
		const optionalHolidays =
			leavesWithinPeriod?.user_optional_holiday_mapping
				?.filter((value) =>
					isDateBetweenRange(startDate, endDate, value?.org_holiday?.date)
				)
				?.map((value) => {
					return {
						name: value?.org_holiday?.name,
						date: value?.org_holiday?.date,
					};
				}) || [];
		if (!userId) {
			const numberOfHolidays =
				uniqBy(
					[...regularHolidays].filter(
						(value) =>
							dayjs(value.date).day() !== 0 && dayjs(value.date).day() !== 6
					),
					(holidayObj) => holidayObj.date
				).length || 0;
			const hours =
				(days - numberOfHolidays) * (settingHours * allocationFactor);
			const cost = Math.round(hours * userCost * 100) / 100;
			return { cost, hours };
		}
		const numberOfHolidays =
			(userLocationId && noOfLocations && noOfLocations > 0) ||
			(!noOfLocations && !userLocationId)
				? uniqBy(
						[...regularHolidays, ...optionalHolidays].filter(
							(value) =>
								dayjs(value.date).day() !== 0 && dayjs(value.date).day() !== 6
						),
						(holidayObj) => holidayObj.date
				  ).length
				: 0;
		const hours = (days - numberOfHolidays) * (settingHours * allocationFactor);
		const cost = Math.round(hours * userCost * 100) / 100;
		return { cost, hours };
	};
	const CostDetails = (
		allocation,
		userCost,
		baseCurrency,
		name,
		onResourceCostChange
	) => {
		const initial = {
			hourlyRate: 0,
			totalHours: 0,
			totalCost: 0,
			availableBudget: 0,
			budgetUtilization: 0,
		};
		const [totals, setTotals] = useState(initial);
		useEffect(() => {
			const totalHoursAndCost = _getHoursAndCost(allocation, userCost);
			const savedTotalCost = plannedCostAndHours?.total_cost || 0;
			const initialBudgetUtilizationValue =
				savedTotalCost === 0 && budget === 0
					? 0
					: savedTotalCost === 0 || budget === 0
					? 0
					: ((savedTotalCost / budget) * 100).toFixed(1);
			const totalPlannedBudget = !!allocation.id
				? savedTotalCost - allocation.total_cost + totalHoursAndCost.cost
				: savedTotalCost + totalHoursAndCost.cost;
			const availableBudgetValue = budget - totalPlannedBudget;
			const nonBillableAvailableBudgetValue = budget - savedTotalCost;
			const budgetUtilizationValue =
				savedTotalCost === 0 && budget === 0
					? 0
					: savedTotalCost === 0 && budget !== 0
					? ((totalPlannedBudget / budget) * 100).toFixed(1)
					: savedTotalCost === 0 || budget === 0
					? 0
					: ((totalPlannedBudget / budget) * 100).toFixed(1);
			const nonBillableAvailableBudgetOnEditValue =
				budget - savedTotalCost + allocation.total_cost;
			const nonBillableBudgetUtilizationOnEditValue = (
				((savedTotalCost - allocation.total_cost) / budget) *
				100
			).toFixed(1);

			setTotals({
				hourlyRate: skilledUserCost,
				totalHours: totalHoursAndCost.hours,
				totalCost: getThousandSeparatorFormat(totalHoursAndCost.cost),
				availableBudget: getThousandSeparatorFormat(availableBudgetValue),
				budgetUtilization: budgetUtilizationValue,
				nonBillableAvailableBudget: getThousandSeparatorFormat(
					nonBillableAvailableBudgetValue
				),
				initialBudgetUtilization: initialBudgetUtilizationValue,
				nonBillableAvailableBudgetOnEdit: getThousandSeparatorFormat(
					nonBillableAvailableBudgetOnEditValue
				),
				nonBillableBudgetUtilizationOnEdit: isNaN(
					nonBillableBudgetUtilizationOnEditValue
				)
					? nonBillableBudgetUtilizationOnEditValue
					: 0,
			});
		}, [allocation, userCost]);

		const getPlannedHours = () => {
			if (initialValues?.id) {
				return (
					plannedCostAndHours?.total_hours -
					initialValues.total_hours +
					totals?.totalHours
				).toFixed(1);
			}
			return isNaN(plannedCostAndHours?.total_hours + totals?.totalHours)
				? 0
				: (plannedCostAndHours?.total_hours + totals?.totalHours).toFixed(1);
		};
		return (
			<Box
				display='flex'
				justifyContent='space-between'
				className={addResourceModalStyles.RaFormInput}
			>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='flex-start'
					minWidth='75%'
				>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Hourly Rate</Box>
						<Box display='flex' alignItems='center'>
							<input
								className={addResourceModalStyles.baseCurrency}
								type='text'
								value={baseCurrency}
								disabled={true}
							/>
							<input
								type='text'
								name={name}
								value={userCost}
								onChange={(e) => {
									if (
										/^\d*\.?\d*$/.test(e.target.value) ||
										e.target.value === ''
									) {
										onResourceCostChange(e);
									}
								}}
								className={addResourceModalStyles.resourceCostInput}
							/>
						</Box>
					</Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}> Bill Rate</Box>
						<Box display='flex' alignItems='center'>
							<Field name='client_rate'>
								{(props) => (
									<>
										<input
											className={addResourceModalStyles.baseCurrency}
											type='text'
											value={
												head(organisation?.organization)?.currency
													.currency_type || null
											}
											disabled={true}
										/>
										<input
											type='text'
											name={'client_rate'}
											value={userClientRate}
											onChange={(e) => {
												if (
													/^\d*\.?\d*$/.test(e.target.value) ||
													e.target.value === ''
												) {
													if (e.target.value) {
														props.input.onChange(e.target.value);
														setUserClientRate(e.target.value);
													} else {
														setUserClientRate(null);
													}
												}
											}}
											className={addResourceModalStyles.resourceCostInput}
										/>
									</>
								)}
							</Field>
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Effort Estimate</Box>
						<Box className={opportunityStyles.label}>
							{effort_estimate || '-'}
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Planned Hours</Box>
						<Box
							className={addResourceModalStyles.plannedHours}
							color={
								!effort_estimate
									? 'black'
									: getPlannedHours() <= effort_estimate
									? '#34A853'
									: '#EA4335'
							}
						>
							{getPlannedHours()}
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Cost</Box>
						<Box className={opportunityStyles.label}>
							{`${currencySymbol ? currencySymbol : '' || ''} ${
								totals.totalCost || 0
							}`}
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Project Budget</Box>
						<Box className={opportunityStyles.label}>{`${
							currencySymbol || ''
						} ${getThousandSeparatorFormat(budget)}`}</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Available Budget</Box>
						<Box className={opportunityStyles.label}>
							{`${currencySymbol || ''} ${
								allocation.id && !allocation.is_billable
									? Number(totals.nonBillableAvailableBudgetOnEdit).toFixed(1)
									: allocation.is_billable
									? Number(totals.availableBudget).toFixed(1)
									: Number(totals.nonBillableAvailableBudget).toFixed(1)
							}`}
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
					<Box className={addResourceModalStyles.whitSpaceNowrap} mr={3}>
						<Box className={opportunityStyles.items}>Budget Utilization</Box>
						<Box className={opportunityStyles.label}>
							{`${
								allocation.id && !allocation.is_billable
									? totals.nonBillableBudgetUtilizationOnEdit
									: allocation.is_billable
									? totals.budgetUtilization
									: totals.initialBudgetUtilization
							}%`}
						</Box>
					</Box>
					<Box className={opportunityStyles.line}></Box>
				</Box>
			</Box>
		);
	};

	const _getDateRangesWithIndex = (projResMapping) => {
		return projResMapping.map(({ id, start_date, end_date }, index) => ({
			index,
			id: id,
			start_date: new Date(start_date),
			end_date: new Date(end_date),
		}));
	};

	const checkIsAllocationOverlap = (allocations, allocationForm) => {
		if (!allocations || !allocationForm) {
			return;
		}
		const selectedUserStartDate = new Date(allocationForm.start_date);
		const selectedUserEndDate = new Date(allocationForm.end_date);

		return allocations.map((allocation) => {
			if (
				allocationForm?.id === allocation.id ||
				(selectedUserStartDate < allocation.start_date &&
					selectedUserEndDate < allocation.start_date) ||
				(allocation.end_date < selectedUserStartDate &&
					allocation.end_date < selectedUserEndDate)
			) {
				return false;
			}
			return true;
		});
	};

	const onSubmit = (projectResourceAllocationForm) => {
		if (!projectResourceAllocationForm) {
			return;
		}
		preventMultipleClick();
		const totalHoursAndCost = _getHoursAndCost(
			projectResourceAllocationForm,
			skilledUserCost
		);
		if (resourceStartDate > projectResourceAllocationForm?.start_date) {
			notify(RESOURCE_ALLOCATION_START_DATE_VALIDATION_MESSAGE, 'warning');
			return;
		}

		if (
			dayjs(projectResourceAllocationForm?.start_date).isBefore(
				projectStartDate
			)
		) {
			notify(
				`Start date ${dayjs(projectResourceAllocationForm?.start_date).format(
					dateFormat
				)} should greater than project start date ${dayjs(
					projectStartDate
				).format(dateFormat)}`,
				'warning'
			);
			return;
		}

		if (
			dayjs(projectResourceAllocationForm?.end_date).isAfter(projectEndDate)
		) {
			notify(
				`End date ${dayjs(projectResourceAllocationForm?.end_date).format(
					dateFormat
				)} should less than project end date ${dayjs(projectEndDate).format(
					dateFormat
				)}`,
				'warning'
			);
			return;
		}

		if (
			resourceEndDate !== null &&
			resourceEndDate < projectResourceAllocationForm.end_date
		) {
			notify(
				`Resource End date must be greater than allocated end date`,
				'warning'
			);
			return;
		}
		if (
			dayjs(projectResourceAllocationForm?.start_date).isAfter(
				projectResourceAllocationForm?.end_date
			)
		) {
			setEndDateError(true);
			return;
		}
		if (projectResourceMappings.length > 0) {
			const selectedUser = projectResourceMappings.filter(
				(projectResourceMapping) =>
					projectResourceMapping.employee_id ===
					projectResourceAllocationForm?.employee_id
			);

			if (selectedUser?.length > 0) {
				const overlapValidationResp = checkIsAllocationOverlap(
					_getDateRangesWithIndex(selectedUser),
					projectResourceAllocationForm
				);
				if (overlapValidationResp.includes(true)) {
					notify('This Resource already exist within this period', 'warning');
					return;
				}
			}
		}

		upsertResourceMapping({
			variables: {
				projectResourceMapping: {
					id: initialValues?.id,
					project_id: projectId,
					employee_id: userId,
					start_date: projectResourceAllocationForm?.start_date,
					end_date: projectResourceAllocationForm?.end_date,
					is_billable: projectResourceAllocationForm?.is_billable,
					is_partial:
						projectResourceAllocationForm?.allocation_percentage < 100
							? true
							: false,
					allocation_percentage:
						projectResourceAllocationForm?.allocation_percentage,
					total_hours: totalHoursAndCost.hours || 0,
					total_cost: projectResourceAllocationForm?.is_billable
						? totalHoursAndCost.cost
						: 0,
					resource_cost: skilledUserCost || null,
					client_rate: formatDecimal(userClientRate, 2) || null,
					tag_id: projectResourceAllocationForm?.tag_id || null,
				},
			},
		})
			.then((upsertResourceMappingResponse) => {
				if (!upsertResourceMappingResponse.error) {
					notify(successMessage);
					handleClose();
				}
			})
			.catch((upsertResourceMappingError) => {
				return;
			});
	};
	const handleClose = () => {
		setUserClientRate(0);
		setSkilledUserCost(0);
		setUserId(null);
		setEndDateError(false);
		setSelectedSkillLevelId(null);
		setSelectedSkillId(null);
		onSuccess();
		setResourceAllocationValue(100);
		setUserTeam(null);
		setHolidayEndDate(null);
		setHolidayEndDate(null);
		setUserLocationId(null);
		setTagId(null);
		onClose();
	};
	const getOwnerValue = (userId) => {
		const response = userOptions?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};
	const getInitialTag = (tagId) => {
		const response = resourceTagList?.project_resource_tag?.find(
			(policy) => policy.id === tagId
		);
		return response ? { value: response?.id, label: response?.name } : null;
	};

	const getTagAutoCompleteOptions = () => {
		const optionList =
			resourceTagList && resourceTagList?.project_resource_tag
				? resourceTagList?.project_resource_tag?.map((tag) => {
						return {
							value: tag?.id,
							label: tag?.name,
						};
				  }) || []
				: [];
		const sortedOptionList = orderBy(
			optionList,
			[(option) => option?.label],
			['asc']
		);
		return sortedOptionList;
	};
	
	const getSkillLevelValue = (id) => {
		const mappedSkillLevelOptions = skillLevelOptions.map((skill) => ({
			id: skill.id,
			name: skill.level,
		}));
		return mappedSkillLevelOptions.find((option) => option.id === id) || null;
	};

	const handleSkillLevelChange = (selectedOption) => {
		const id = selectedOption ? selectedOption : null;
		setSelectedSkillLevelId(id);
	};

	const getSkillValue = (id) => {
		const mappedSkillOptions = skillOptions.map((skill) => ({
			id: skill.id,
			name: skill.name,
		}));
		return mappedSkillOptions.find((option) => option.id === id) || null;
	};
	const handleSkillChange = (selectedOption) => {
		const id = selectedOption ? selectedOption : null;
		setSelectedSkillId(id);
	};
	return (
		<ThemeProvider theme={addResourceModalTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				disableBackdropClick
			>
				<ThemeProvider theme={modalFormTheme}>
					<Box className={addResourceModalStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								{initialValues?.id ? `Edit allocation` : `Add allocation`}
							</Typography>
							<CloseIcon
								className={formStyles.closeIcon}
								onClick={handleClose}
							/>
						</Box>
						<Form
							initialValues={
								initialValues?.id
									? initialValues
									: {
											start_date: projectStartDate,
											end_date: projectEndDate,
											allocation_percentage: 100,
											is_billable: true,
											is_partial: false,
									  }
							}
							onSubmit={onSubmit}
							mutators={{
								setPartialAllocationPercentage: (args, state, utils) => {
									if (!state?.lastFormState?.values?.is_partial) {
										utils.changeValue(state, 'allocation_percentage', () => 50);
										return;
									}
									utils.changeValue(state, 'allocation_percentage', () => 100);
								},
								checkIsPartial: (args, state, utils) => {
									if (
										state?.lastFormState?.values?.allocation_percentage === 100
									) {
										utils.changeValue(state, 'is_partial', () => false);
										return;
									}
									utils.changeValue(state, 'is_partial', () => true);
								},
								removeSelectedUser: (args, state, utils) => {
									utils.changeValue(state, 'employee_id', () => null);
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, values, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={formStyles.formContainer}>
										<Box>
											<Box display='flex' width='100%'>
												<Box
													className={
														addResourceModalStyles.autoCompleteInputContainer
													}
												>
													<Typography className={formStyles.label}>
														Skills
													</Typography>
													<AutocompleteSearch
														placeholder={'Search Skill'}
														option={
															skill && skill?.skill_master
																? skill?.skill_master?.map((skill) => {
																		return {
																			id: skill?.id,
																			name: skill?.name,
																		};
																  }) || []
																: []
														}
														onChange={(selectedOption) => {
															form.mutators.removeSelectedUser();
															handleSkillChange(selectedOption);
														}}
														name={'skill_id'}
														validates={false}
														value={getSkillValue(selectedSkillId)}
													/>
												</Box>
												<Box
													className={
														addResourceModalStyles.autoCompleteInputContainer
													}
												>
													<Typography className={formStyles.label}>
														Skill Level
													</Typography>
													<AutocompleteSearch
														placeholder={'Search Skill Level'}
														option={
															skillLevel && skillLevel?.skill_level
																? skillLevel?.skill_level?.map((skill) => {
																		return {
																			id: skill?.id,
																			name: skill?.level,
																		};
																  }) || []
																: []
														}
														onChange={(selectedOption) => {
															form.mutators.removeSelectedUser();
															handleSkillLevelChange(selectedOption);
														}}
														name={'skill_level_id'}
														validates={false}
														value={getSkillLevelValue(selectedSkillLevelId)}
													/>
												</Box>
												{featureFlag?.userTeams && (
													<Box
														className={
															addResourceModalStyles.autoCompleteInputContainer
														}
													>
														<Typography className={formStyles.label}>
															User Team
														</Typography>
														<AutocompleteSearch
															placeholder={'Search Team'}
															option={
																userTeams && userTeams?.user_team
																	? userTeams?.user_team?.map((userTeam) => {
																			return {
																				id: userTeam?.id,
																				name: userTeam?.name,
																			};
																	  }) || []
																	: []
															}
															onChange={(id) => {
																form.mutators.removeSelectedUser();
																setUserTeam(id);
															}}
															name={'team_id'}
														/>
													</Box>
												)}
												<Box
													className={
														addResourceModalStyles.autoCompleteInputContainer
													}
												>
													<Typography className={formStyles.label}>
														User
													</Typography>
													<AutocompleteSearch
														placeholder={'Search User'}
														option={
															userOptions && userOptions
																? userOptions?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={(id) => {
															getResourceEndDate(id);
															setUserId(id);
														}}
														name={'employee_id'}
														validates={true}
														value={getOwnerValue(userId || '')}
													/>
												</Box>
												<Box className={addResourceModalStyles.inputContainer}>
													<Box
														className={addResourceModalStyles.checkboxContainer}
													>
														<Field
															type='checkbox'
															component='input'
															name='is_billable'
															className={addResourceModalStyles.checkBoxInput}
														/>
														<Typography
															className={addResourceModalStyles.checkBoxLabel}
														>
															Billable
														</Typography>
													</Box>
												</Box>
											</Box>
											<Box display='flex' width='100%'>
												<Box className={addResourceModalStyles.inputContainer}>
													<Typography className={formStyles.label}>
														Start Date
													</Typography>
													<Field
														name='start_date'
														validate={(value) => {
															if (dayjs(value).isValid() === true) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props) => (
															<Box width='160px'>
																<CustomDateInput
																	minDate={new Date(projectStartDate)}
																	maxDate={new Date(projectEndDate)}
																	name={props.input.name}
																	initialValue={
																		initialValues?.start_date ||
																		projectStartDate
																	}
																	onChange={(value) => {
																		setHolidayStartDate(value);
																		props.input.onChange(value);
																		setEndDateError(false);
																	}}
																	dateFormat={dateFormat}
																	leaveDaysList={
																		excludedLeaves && excludedLeaves.length > 0
																			? excludedLeaves.map((excludedLeave) => {
																					return {
																						...excludedLeave,
																						date: excludedLeave.leaveDate,
																					};
																			  })
																			: []
																	}
																	holidays={holidays}
																	isLeaveAndHolidaysEnabled={true}
																/>
															</Box>
														)}
													</Field>
												</Box>
												<Box className={addResourceModalStyles.inputContainer}>
													<Typography className={formStyles.label}>
														End Date
													</Typography>
													<Field
														name='end_date'
														validate={(value) => {
															if (dayjs(value).isValid() === true) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props) => (
															<Box width='160px'>
																<CustomDateInput
																	minDate={new Date(projectStartDate)}
																	maxDate={new Date(projectEndDate)}
																	name={props.input.name}
																	initialValue={
																		initialValues?.end_date || projectEndDate
																	}
																	onChange={(value) => {
																		setHolidayEndDate(value);
																		props.input.onChange(value);
																		setEndDateError(false);
																	}}
																	dateFormat={dateFormat}
																	isLeaveAndHolidaysEnabled={true}
																	leaveDaysList={
																		excludedLeaves && excludedLeaves.length > 0
																			? excludedLeaves.map((excludedLeave) => {
																					return {
																						...excludedLeave,
																						date: excludedLeave.leaveDate,
																					};
																			  })
																			: []
																	}
																	holidays={holidays}
																/>
															</Box>
														)}
													</Field>
													{endDateError && (
														<p className={addResourceModalStyles.errorText}>
															End date should be greater than start date
														</p>
													)}
												</Box>
												<Box
													className={
														addResourceModalStyles.autoCompleteInputContainer
													}
												>
													<Typography className={formStyles.label}>
														Tag
													</Typography>
													<Field name='tag_id'>
														{(props) => (
															<ThemeProvider theme={autoCompleteTheme}>
																<div
																	className={
																		autocompleteSearchStyles.customFieldContainer
																	}
																>
																	<Autocomplete
																		onChange={(event, newValue) => {
																			if (newValue === null) {
																				setTagId('');
																				props.input.onChange(null);
																				return;
																			}
																			props.input.onChange(newValue?.value);
																			setTagId(newValue.value);
																		}}
																		key={tagId}
																		classes={{
																			option: autocompleteSearchStyles.options,
																			groupLabel:
																				autocompleteSearchStyles.groupLabel,
																			inputRoot:
																				autocompleteSearchStyles.inputRootWrap,
																			noOptions:
																				autocompleteSearchStyles.noOptions,
																			focused: autocompleteSearchStyles.focused,
																			input: autocompleteSearchStyles.input,
																			endAdornment:
																				autocompleteSearchStyles.endAdornment,
																		}}
																		options={getTagAutoCompleteOptions() || []}
																		value={getInitialTag(tagId)}
																		renderOption={(option) => (
																			<Tooltip
																				title={`${option?.label}`}
																				placement='right'
																			>
																				<Typography
																					className={`${ellipsis.ellipsis}`}
																				>
																					{`${option?.label}`}
																				</Typography>
																			</Tooltip>
																		)}
																		getOptionLabel={(option) => option?.label}
																		getOptionSelected={(option, value) =>
																			option.id === value.id
																		}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label=''
																				placeholder='Search Tag'
																				InputProps={{
																					...params.InputProps,
																					startAdornment: (
																						<InputAdornment
																							position='start'
																							classes={{
																								root: autocompleteSearchStyles.inputAdronment,
																								positionStart:
																									autocompleteSearchStyles.inputAdronmentPositionStart,
																							}}
																						>
																							<SearchIcon
																								className={
																									autocompleteSearchStyles.searchIconPositionStart
																								}
																							/>
																						</InputAdornment>
																					),
																				}}
																			/>
																		)}
																	/>
																</div>
															</ThemeProvider>
														)}
													</Field>
												</Box>
												<Box
													className={
														addResourceModalStyles.allocationPercentageContainer
													}
												>
													<Typography className={formStyles.label}>
														Allocation Percentage
													</Typography>
													<Field name='allocation_percentage'>
														{(props) => (
															<SliderInput
																onChange={(value) => {
																	props.input.onChange(value);
																	setResourceAllocationValue(value);
																	form.mutators.checkIsPartial();
																}}
																min={5}
																max={100}
																label={''}
																initialValue={resourceAllocationValue}
																disabled={isSliderDisabled}
															/>
														)}
													</Field>
												</Box>
												<Box className={addResourceModalStyles.inputContainer}>
													<Box
														className={addResourceModalStyles.billableContainer}
													>
														<Field
															type='checkbox'
															component='input'
															name='is_partial'
															className={addResourceModalStyles.checkBoxInput}
															onClick={(event) => {
																form.mutators.setPartialAllocationPercentage();
																if (event) {
																	const checkStatus = event?.target?.checked;
																	setResourceAllocationValue(
																		checkStatus ? 50 : 100
																	);
																	setIsSliderDisabled(
																		checkStatus ? false : true
																	);
																}
															}}
														/>
														<Typography
															className={addResourceModalStyles.checkBoxLabel}
														>
															Partial
														</Typography>
													</Box>
												</Box>
												<Box>
													<Typography className={formStyles.label}>
														Allocated Hours
													</Typography>
													<Box className={opportunityStyles.label}>
														{holidaysLoading
															? 'Loading'
															: _getHoursAndCost(
																	values,
																	skilledUserCost
															  )?.hours?.toFixed(1)}
													</Box>
												</Box>
											</Box>
											<Box display='flex'>
												<Field name='resource_cost' key={userLocationId}>
													{(props) => (
														<div>
															<>
																{CostDetails(
																	values,
																	skilledUserCost,
																	head(organisation?.organization)?.currency
																		.currency_type,
																	props.input.name,
																	(e) => {
																		if (e?.target?.value) {
																			props.input.onChange(e);
																			setSkilledUserCost(e.target.value);
																		} else {
																			setSkilledUserCost(null);
																		}
																	}
																)}
															</>
														</div>
													)}
												</Field>
											</Box>

											<Box className={formStyles.buttonContainer} mt={2}>
												<Button
													type='submit'
													variant='contained'
													color='primary'
													className={
														invalid ||
														pristine ||
														isAllocationLoading ||
														holidaysLoading ||
														isSaveButtonDisabled
															? formStyles.disabledButton
															: formStyles.saveButton
													}
													disabled={
														invalid ||
														pristine ||
														isAllocationLoading ||
														holidaysLoading ||
														isSaveButtonDisabled
													}
												>
													{initialValues?.id ? `Update` : `Add Resource`}
												</Button>
											</Box>
										</Box>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</ThemeProvider>
			</Dialog>
		</ThemeProvider>
	);
};

export default AddAllocationForm;
