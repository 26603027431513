import React, { useState, useEffect } from "react";
import { CheckboxGroupInput } from "react-admin";

import { Box, Typography, makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Divider from '@material-ui/core/Divider';
import InputAdornment from "@material-ui/core/InputAdornment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { MAX_FILTER_OPTIONS_SHOWN } from "../config/constant";

const useStyles = makeStyles(() => ({
  container: {
    margin: '10px 20px 10px 0px',
  },
  seeMoreContainer: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px"
  },
  filterContainer: {
    height: "auto",
  },
  filterScrollContainer: {
    height: "276px",
    overflowY: "scroll"
  },
  filterLabel: {
    fontFamily: 'Manrope-bold',
    fontSize: '12px',
    letterSpacing: "0px",
    color: "#292929",
    opacity: 1,
    height: "14px",
  },
  seeMore: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontFamily: 'Manrope-bold',
    textDecoration: "underline",
    textTransform: "none",
    cursor: "pointer",
    color: "#5C5C5C",
    marginBottom: "10px"
  },
}));

const searchBarTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: '25px',
        width: '100%',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
      }
    },
    MuiInputBase: {
      root: {
        fontSize: "15px",
        fontFamily: 'Manrope-medium',
      },
      input: {
        padding: '0px 0px 0px 7px !important',
      }
    },
    MuiFilledInput: {
      input: {
        fontSize: "12px",
      }
    },
    MuiSvgIcon: {
      root: {
        color: "#7C7C7C",
        width: "15px",
        height: "15px",
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      }
    },
  }
})

const theme = createTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        display: "none",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "12px",
        fontFamily: "Manrope-medium",
        color: "#292929",
      },
    },
  },
});

interface InputChoices {
  id: string;
  name: string;
}

interface Props {
  label: string;
  sourceName: string;
  choices: InputChoices[];
  total: number;
  onSearch: (searchText: string) => void;
  onExpansionToggle: (isSeemore: boolean) => void;
}

export const FilterInput = ({
  label,
  sourceName,
  choices,
  onSearch,
  onExpansionToggle,
  total
}: Props) => {
  const classes = useStyles();
  const [isSeeMoreLabel, setIsSeeMoreLabel] = useState(true);
  const [isExpansionShown, setIsExpansionShown] = useState(true);
  const [isSearchShown, setIsSearchShown] = useState(true);
  const [searchText, setSearchText] = useState('');
  const onExpansionToggleClick = () => {
    onExpansionToggle(!isSeeMoreLabel);
    setIsSeeMoreLabel(!isSeeMoreLabel);
  };
  useEffect(() => {
    setIsSearchShown(!!searchText || total > MAX_FILTER_OPTIONS_SHOWN)
  }, [total, searchText])

  useEffect(() => {
    setIsExpansionShown(total > MAX_FILTER_OPTIONS_SHOWN)
  }, [total])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Typography className={classes.filterLabel}>{label}</Typography>
          {isSearchShown && (
            <ThemeProvider theme={searchBarTheme}>
              <Box marginTop="10px">
                <FormControl variant="outlined">
                  <OutlinedInput
                    value={searchText}
                    placeholder={label}
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(event) => {
                      setSearchText(event.target.value)
                      onSearch(event.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    labelWidth={0}
                  />
                </FormControl>
              </Box>
            </ThemeProvider>
          )}
          <Box className={choices.length > MAX_FILTER_OPTIONS_SHOWN ? classes.filterScrollContainer : classes.filterContainer}>
            <CheckboxGroupInput
              source={sourceName}
              label={""}
              row={false}
              choices={choices}
            />
          </Box>
          {isExpansionShown && (
            <Box className={classes.seeMoreContainer}>
              <Box className={classes.seeMore} onClick={onExpansionToggleClick}>
                {isSeeMoreLabel ? 'See more' : 'See Less'}
              </Box>
            </Box>
          )}
          <Divider />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default FilterInput;
