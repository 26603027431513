import React, { Fragment } from 'react';

import { GET_EMPLOYEE_STATUS_AUDIT } from '../EmployeeSkillMapping.sevices.gql';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { globalStyles } from '../../../Layout/styles';

const useStyles = makeStyles((theme) => ({
	dateClass: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope, Medium'
	},
	timeClass: {
		color: '#898989',
		fontSize: '11px',
		marginLeft: '21px',
		fontFamily: 'Manrope, SemiBold'
	},
	statusClass: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope'
	},
	empClass: {
		color: '#898989',
		fontSize: '12px',
		fontFamily: 'Manrope'
	},
	dotClass: {
		marginBottom: '1px',
		backgroundColor: '#E3EEFF',
		borderColor: '#C4DAFF',
		borderWidth: '1px'
	},
	connectorClass: {
		marginLeft: '-1px',
		width: '1px'
	},
	timelineClass: {
		marginTop: '-20px',
		marginBottom: '0px'
	},
	oppsiteconClass: {
		maxWidth: '16%',
	}
}));
interface Record {
	created_at?: string;
	user?: {
		id?: string;
	}
	createdByUser?: {
		full_name?: string;
	}
}
interface Props {
	record: Record;
}

const EmployeeHistory = (props: Props) => {
	const classes = useStyles();
	const { record } = props;
	const globalClasses = globalStyles();

	const { data: employeeHistory } =
		ApolloUseQuery(GET_EMPLOYEE_STATUS_AUDIT, {
			variables: {
				userId: record?.user?.id
			},
		});

	return (
		<div className={globalClasses.scrollableAuditContainer}>
		{employeeHistory && (
				<Fragment>
					<Timeline align="left" className={classes.timelineClass}>
						<TimelineItem>
							<TimelineOppositeContent className={classes.oppsiteconClass}>
								<Typography color="textSecondary">
									<span className={classes.dateClass}>{record.created_at && dayjs(record.created_at).format('DD MMM YYYY')}</span><br />
									<span className={classes.timeClass}>{record.created_at && dayjs(record.created_at).format('h:mm a')}</span>
								</Typography>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot className={classes.dotClass}/>
								<TimelineConnector className={classes.connectorClass}/>
							</TimelineSeparator >
							<TimelineContent>
								<Typography>
									<span className={classes.statusClass}>Employee created</span><br />
									<span className={classes.empClass}>{record.createdByUser?.full_name && "By 	"+record.createdByUser?.full_name}</span>
								</Typography>
							</TimelineContent>
						</TimelineItem>
					</Timeline>
				</Fragment>)}
			{employeeHistory && Object.keys(employeeHistory.audit_audit_employee).map((key) => {
			return(
					<Fragment>
						<Timeline align="left" className={classes.timelineClass}>
							<TimelineItem>
								<TimelineOppositeContent className={classes.oppsiteconClass}>
									<Typography color="textSecondary">
										<span className={classes.dateClass}>{dayjs(employeeHistory.audit_audit_employee[key].action_tstamp).format('DD MMM YYYY')}</span><br />
										<span className={classes.timeClass}>{dayjs(employeeHistory.audit_audit_employee[key].action_tstamp).format('h:mm a')}</span>
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={classes.dotClass}/>
									<TimelineConnector className={classes.connectorClass}/>
								</TimelineSeparator>
								<TimelineContent>
									<Typography>
										<span className={classes.statusClass}>Status changed to <b>{employeeHistory.audit_audit_employee[key].action_data}</b></span><br />
										<span className={classes.empClass}>{employeeHistory.audit_audit_employee[key].user && "By "+employeeHistory.audit_audit_employee[key].user.full_name}</span>
									</Typography>
								</TimelineContent>
							</TimelineItem>
						</Timeline>
					</Fragment>
				)
			})}
		</div>
	);
};

export default EmployeeHistory;
