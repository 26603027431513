import React, { useState,useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
	container: {
		display: 'flex',
	},
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
});

interface Props {
	edit: () => void;
	remove: () => void;
	hideEdit?: boolean;
	isDeleteShown?: boolean;
	isModalClosed?:boolean;
}

export const PopOver = (props: Props) => {
	const { edit, remove, hideEdit, isDeleteShown = true,isModalClosed } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
	};


	useEffect(()=>{
		closePopOver()
	},[isModalClosed])

	const popOverStyles = useStyles();

	return (
		<div className={popOverStyles.container}>
			<MoreVertIcon
				id='ellipsis_icon'
				onClick={showPopOver}
				className={popOverStyles.verticalIcon}
			/>
			<Menu
				id='pop_over'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				{!hideEdit ? (
					<MenuItem onClick={() => edit()} id='edit_option'>
						<EditIcon className={popOverStyles.editIcon} />
						<Box className={popOverStyles.editText}>Edit</Box>
					</MenuItem>
				) : (
					''
				)}
				{isDeleteShown && (
					<MenuItem onClick={() => remove()} id='delete_option'>
						<DeleteIcon className={popOverStyles.deleteIcon} />
						<Box className={popOverStyles.editText}>Delete</Box>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};
