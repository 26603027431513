import { head, isEmpty } from 'lodash';
import { UserProfileContext } from '../../App';
import { useState, useContext, useEffect } from 'react';
import { useGetOrgHolidaysByUserQuery, useGetApprovedLeaveQuery, useGetEmployeeIdQuery, useGetUserWorkingDaysQuery } from '../../generated/graphql';

import {
  getAllDatesInRange,
  getSaturdaysDates,
  getSundaysBetweenDates,
} from '../../Utils/date-time.util';


const useGetExcludedLeaves = (startDate, endDate, userId, locationId) => {

  const [leaves, setLeaves] = useState([]);
  const [orgHoliday, setOrgHoliday] = useState([]);
  const [excludedLeaves, setExcludedLeaves] = useState([]);

  const {
    noOfLocations,
    orgId,
  } = useContext(UserProfileContext);


  const { data: orgHolidays } = useGetOrgHolidaysByUserQuery({
    variables: {
      filter:
        locationId && noOfLocations && noOfLocations > 0
          ? {
            date: {
              _gte: startDate,
              _lte: endDate,
            },
            location_id: { _eq: locationId },
            is_restricted: { _eq: false },
          }
          : {
            date: {
              _gte: startDate,
              _lte: endDate,
            },
            is_restricted: { _eq: false },
          },
      optionalHolidayFilter:
        locationId && noOfLocations && noOfLocations > 0
          ? {
            user_id: { _eq: userId },
            org_holiday: {
              date: {
                _gte: startDate,
                _lte: endDate,
              },
              location_id: { _eq: locationId },
            },
          }
          : {
            user_id: { _eq: userId },
            org_holiday: {
              date: {
                _gte: startDate,
                _lte: endDate,
              },
            },
          },
    },
    fetchPolicy: 'network-only',
    skip: !locationId && noOfLocations && noOfLocations > 0,
  });

  const { data: allHolidaysAndWeekends } = useGetUserWorkingDaysQuery({
    variables: {
      orgId
    }
  })




  const { data: employeeId } = useGetEmployeeIdQuery({
    variables: {
      userId,
    },
  });



  const { data: appliedLeave } = useGetApprovedLeaveQuery({
    variables: {
      employeeId: head(employeeId?.employee)?.id,
      startDate: startDate,
      endDate: endDate,
    },
    skip: !head(employeeId?.employee)?.id,
  });




  useEffect(() => {
    if (isEmpty(appliedLeave?.employee_leaving_mapping)) {
      setLeaves([]);
      return;
    }
    const response = appliedLeave?.employee_leaving_mapping?.map((element) => {
      return {
        from_date: element?.from_date,
        to_date: element?.to_date,
        name: element?.org_leave_type?.leave_type,
        isExcluded: element?.org_leave_type?.is_leave_sandwich_type
      };
    });
    setLeaves(response);
  }, [appliedLeave]);

  useEffect(() => {
    if (!orgHolidays) {
      setOrgHoliday([]);
      return;
    }
    const regularHolidays =
      orgHolidays?.org_holidays?.map((value) => {
        return {
          name: value?.name,
          date: value?.date,
          locationName: value?.org_location?.name || '',
        };
      }) || [];
    const optionalHolidays =
      orgHolidays?.user_optional_holiday_mapping?.map((value) => {
        return {
          name: value?.org_holiday?.name,
          date: value?.org_holiday?.date,
          locationName: value?.org_holiday?.org_location?.name || '',
        };
      }) || [];
    setOrgHoliday([...regularHolidays, ...optionalHolidays]);
  }, [locationId, noOfLocations, orgHolidays]);

  useEffect(() => {
    if (!leaves || !orgHoliday || !allHolidaysAndWeekends) {
      return;
    }

    const allLeaveDates = leaves
      ?.map((leave) => {
        const dates = getAllDatesInRange(leave?.from_date, leave?.to_date);
        return dates?.map((date) => {
          return {
            leaveDate: date,
            name: leave?.name,
            isExcluded: leave?.isExcluded,
          };
        });
      })
      .flat();

    const leaveDatesInRange = allLeaveDates?.filter(
      (leaveDate) =>
        leaveDate?.leaveDate >= startDate &&
        leaveDate?.leaveDate <= endDate
    );

    const isSundayAvailable = head(
      allHolidaysAndWeekends?.organization
    )?.weekend?.includes('SUN');

    const sundayAsWeekend = getSundaysBetweenDates(
      startDate,
      endDate
    );
    const availableSaturdays = head(allHolidaysAndWeekends?.organization)
      ?.weekend?.filter((weekendDay) => weekendDay !== 'SUN')
      ?.map((sat) => sat?.charAt(sat.length - 1));
    const allSaturdaysBetweenDates = getSaturdaysDates(
      startDate,
      endDate
    );

    const saturdayAsWeekend = allSaturdaysBetweenDates
      ?.map((day) =>
        availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
      )
      ?.filter((date) => date !== '');

    const allHolidaysDate = orgHoliday?.map((holiday) => holiday?.date);

    const leavesWithoutHolidays = leaveDatesInRange?.filter((leaveDate) => {
      const isExcludedType = leaveDate?.isExcluded;
      const date = leaveDate?.leaveDate;
      if (!isExcludedType) {
        return leaveDate;
      }

      const isHoliday = allHolidaysDate?.includes(date);
      const isSunday = sundayAsWeekend?.includes(date);
      const isSaturday = saturdayAsWeekend.includes(date);

      return (
        ((isSundayAvailable && !isHoliday && !isSunday && !isSaturday) ||
          (!isSundayAvailable && !isHoliday && !isSaturday)) &&
        leaveDate
      );
    });
    setExcludedLeaves(leavesWithoutHolidays);
  }, [leaves, orgHoliday, allHolidaysAndWeekends, startDate, endDate]);


  return [excludedLeaves];
}

export default useGetExcludedLeaves;