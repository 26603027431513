import React, { useState, useEffect, useContext } from 'react';
import { TextInput, required, number } from 'react-admin';
import { useNotify } from 'react-admin';
import { isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { UserProfileContext } from '../../../../App';
import { settingsStyle } from '../../settingsStyle';
import { GET_ORGANIZATION_PROBABTION_DAY } from '../../../../SharedComponents/gqlQueries';
import { useUpdateOrganizationProbationDaysMutation } from '../../../../generated/graphql';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
interface ProbationDays {
	probationDay: string;
}

const ProbationPeriodSettings = () => {
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const [probationDay, setProbationDay] = useState('');
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const notify = useNotify();

	const {
		data: organisationProbationDays,
		refetch: reftechOrganizationProbationDay,
	} = useApolloQuery(GET_ORGANIZATION_PROBABTION_DAY, {
		variables: {
			organizationId,
		},
	});

	useEffect(() => {
		if (isEmpty(organisationProbationDays?.organization_by_pk)) {
			return;
		}
		setProbationDay(
			organisationProbationDays?.organization_by_pk?.probation_day
		);
	}, [organisationProbationDays]);

	const [updateProbationDay, { loading: isUpdateProbationDayLoading }] =
		useUpdateOrganizationProbationDaysMutation();

	const onHandleSubmit = (value: ProbationDays) => {
		updateProbationDay({
			variables: {
				id: organizationId,
				probationPeriod: {
					probation_day: parseInt(value?.probationDay),
				},
			},
		}).then((data) => {
			notify('Probation Days updated successfully');
			reftechOrganizationProbationDay();
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form initialValues={{ probationDay }} onSubmit={onHandleSubmit}>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Probation Period(day) *
									</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput
										source='probationDay'
										fullWidth={true}
										label={''}
										validate={[required(), number()]}
									/>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isLoading={isUpdateProbationDayLoading}
									title='Save'
									isDisabled={invalid || pristine || isUpdateProbationDayLoading}
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default ProbationPeriodSettings;
