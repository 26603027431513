import { Col, Row, Spin, Typography } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
const { Text } = Typography;

export const PipelineTotalCount = ({
	value,
	isLoading,
}: {
	value: number;
	isLoading: boolean;
}) => {
	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text>{value}</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};
