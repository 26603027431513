import React from 'react';
import {
	TextInput,
	required,
	useNotify,
	ReferenceInput,
	SelectInput,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../Layout/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import { CREATE_PROJECT_DELAY } from './gqlQueries';

interface FormValue {
	comment?: string;
	id?: string;
	created_by?: string;
	project_id?: string;
	updated_at?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	projectId?: string | null;
	previousProjectEndDate: string | null;
	newProjectEndDate: string | null;
}
const ProjectDelayForm = (props: Props) => {
	const {
		open,
		onClose,
		onSuccess,
		projectId,
		previousProjectEndDate,
		newProjectEndDate,
	} = props;
	const classes = modalFormStyle();
	const notify = useNotify();
	const [createProjectDelay, { loading: isEmployeeLoading }] =
		useApolloMutation(CREATE_PROJECT_DELAY);

	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}

		createProjectDelay({
			variables: {
				projectData: {
					project_id: projectId,
					changed_from: previousProjectEndDate || null,
					changed_to: newProjectEndDate || null,
					...formValues,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Delay record saved successfully');
					onSuccess();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify('Creation failed', 'warning');
					return;
				}
			});
		return;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} disableBackdropClick onClose={onClose}>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>PROJECT DELAY</Typography>
						<CloseIcon onClick={onClose} className={classes.closeIcon} />
					</Box>
					<Form onSubmit={onSubmit}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Reason*</Typography>
									{/* TODO After table creation for reason we should fetch these options from database */}
									<ReferenceInput
										source='reason_id'
										reference='project_delay_reason'
										label=''
										validate={required()}
									>
										<SelectInput optionText='label' fullWidth />
									</ReferenceInput>

									<Typography className={classes.label}>Notes</Typography>
									<TextInput
										multiline
										source='notes'
										label=''
										placeholder='Enter the text'
									/>

									<Box className={classes.buttonContainer}>
										<Button onClick={onClose} className={classes.cancelButton}>
											Cancel
										</Button>
										<Button
											className={
												invalid || isEmployeeLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isEmployeeLoading}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectDelayForm;
