import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { FunctionField, ReferenceField } from 'react-admin';
import { getExperience } from '../../Utils/date-time.util';
import FieldContainer from '../../SharedComponents/Employee/FieldContainer.componet';
import { detailsPageTabDetailsStyle } from '../../Layout/styles';
import { MyInfoBasicForm, Props } from './model';
import { UserProfileContext } from '../../App';
import dayjs from 'dayjs';
import DetailContainer from '../../SharedComponents/Employee/DetailContainer.component';
import editicon from '../../assets/icons/edit-icon.svg';
import EditMyInfoDetails from './EditMyInfoDetails.component';
import { GET_ORGANISATION_BASE_CURRENCY } from '../Employees/DetailsPage/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { FieldPermissionsContext } from './MyInfoView.component';
import { GET_CONSULTANT_CUSTOM_FIELDS } from '../Consultant/gqlQueries';
import { GET_EMPLOYEE_CUSTOM_FIELDS } from '../Employees/gqlQueries';
import FieldRenderer from '../../SharedComponents/FieldRenderer.component';

const MyInfoBasicDetails = (props: Props) => {
	const { myInfoViewDetails, refetchContractor, refetchEmployee } = props;
	const { dateFormat, orgId } = useContext<any>(UserProfileContext);
	const { isEmpSelfServiceEnabled } = useContext<any>(FieldPermissionsContext);

	const classes = detailsPageTabDetailsStyle();
	const [employeeDetails, setEmployeeDetails] = useState<MyInfoBasicForm>();
	const [isFormShown, setIsFormShown] = useState(false);
	const { data: organisation } = useApolloQuery(
		GET_ORGANISATION_BASE_CURRENCY,
		{
			variables: {
				organisationId: orgId,
			},
		}
	);

	const { data: consultantCustomFields } = useApolloQuery(
		GET_CONSULTANT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	const { data: employeeCustomFields } = useApolloQuery(
		GET_EMPLOYEE_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	useEffect(() => {
		setEmployeeDetails({
			personal_email: myInfoViewDetails?.personal_email,
			highest_academic_degree: myInfoViewDetails?.highest_academic_degree,
			join_date: myInfoViewDetails?.join_date,
			employee_joining_source_id: myInfoViewDetails?.employee_joining_source_id,
			blood_group: myInfoViewDetails?.blood_group,
			gender: myInfoViewDetails?.gender,
			marital_status: myInfoViewDetails?.marital_status,
			wedding_date: myInfoViewDetails?.wedding_date,
			emp_billing_cost: myInfoViewDetails?.emp_billing_cost,
			emp_cost: myInfoViewDetails?.emp_cost,
			address: myInfoViewDetails?.address,
			id: myInfoViewDetails?.id,
			years: myInfoViewDetails?.prior_experience
				? myInfoViewDetails?.prior_experience.slice(0, 2)
				: '00',
			months: myInfoViewDetails?.prior_experience
				? myInfoViewDetails?.prior_experience.slice(3, 5)
				: '00',
			currency_id: organisation?.organization[0]?.currency_id,
			org_shift_id: myInfoViewDetails?.org_shift_id,
			last_date: myInfoViewDetails?.last_date,
			leave_group_id: myInfoViewDetails?.org_leave_group?.id,
			probation_start_date: myInfoViewDetails?.probation_start_date,
			probation_end_date: myInfoViewDetails?.probation_end_date,
			employeeStatus: myInfoViewDetails?.employee_status?.label,
			dob: myInfoViewDetails?.dob,
			user_type: myInfoViewDetails?.user_type,
			institution_name: myInfoViewDetails?.institution_name,
			emergency_contact_number: myInfoViewDetails?.emergency_contact_number,
			emergency_contact_name: myInfoViewDetails?.emergency_contact_name,
			custom_field_1: myInfoViewDetails?.custom_field_1,
			custom_field_2: myInfoViewDetails?.custom_field_2,
			custom_field_3: myInfoViewDetails?.custom_field_3,
			custom_field_4: myInfoViewDetails?.custom_field_4,
			custom_field_5: myInfoViewDetails?.custom_field_5,
			custom_field_6: myInfoViewDetails?.custom_field_6,
			custom_field_7: myInfoViewDetails?.custom_field_7,
			custom_field_8: myInfoViewDetails?.custom_field_8,
			custom_field_9: myInfoViewDetails?.custom_field_9,
		});
	}, [myInfoViewDetails, organisation]);

	return (
		<>
			{/* TODO Once implement for contractor we need to handle contractor also */}
			{isEmpSelfServiceEnabled &&
				myInfoViewDetails?.user_type === 'employee' && (
					<Box
						className={classes.editContainer}
						onClick={() => setIsFormShown(true)}
					>
						<img className={classes.editButton} src={editicon} alt='editicon' />
						<Box className={classes.editIcon}>Edit Details</Box>
					</Box>
				)}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Personal Email</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(user: any) => `${user?.personal_email || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Address</Typography>
					<FunctionField
						record={myInfoViewDetails}
						className={classes.value}
						render={(address: any) => {
							return address?.address?.houseName && address?.address?.streetName
								? `${address?.address?.houseName || ''}, ${
										address?.address?.streetName || ''
								  }`
								: `${address?.address?.houseName || ''}  ${
										address?.address?.streetName || ''
								  }`;
						}}
					/>
					<FunctionField
						record={myInfoViewDetails}
						className={classes.value}
						render={(address: any) => {
							return address?.address?.houseName && address?.address?.streetName
								? `${address?.address?.city || ''}, ${
										address?.address?.postalCode || ''
								  }`
								: `${address?.address?.city || ''}  ${
										address?.address?.postalCode || ''
								  }`;
						}}
					/>
					<FunctionField
						record={myInfoViewDetails}
						className={classes.value}
						render={(address: any) => {
							return `${address?.address?.state || ''}`;
						}}
					/>
					{myInfoViewDetails?.address?.country && (
						<ReferenceField
							link={false}
							label=''
							source='address.country'
							reference='geo_list'
							record={myInfoViewDetails}
							basePath={'/my-info'}
							className={classes.value}
						>
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(consultant: any) => {
									return `${consultant?.name}`;
								}}
							/>
						</ReferenceField>
					)}
				</FieldContainer>
				{myInfoViewDetails?.user_type === 'contract_employee' && (
					<FieldContainer>
						<Typography className={classes.label}>
							Contract Start Date
						</Typography>
						{myInfoViewDetails?.join_date ? (
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(consultant: any) => {
									return `${consultant?.join_date}`;
								}}
							/>
						) : (
							'- -'
						)}
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'contract_employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Contract End Date</Typography>
						{myInfoViewDetails?.end_date ? (
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(consultant: any) => {
									return `${consultant?.end_date}`;
								}}
							/>
						) : (
							'- -'
						)}
					</FieldContainer>
				)}
				<FieldContainer>
					<Typography className={classes.label}>Joining Source</Typography>
					{myInfoViewDetails?.joining_source ? (
						<FunctionField
							className={classes.value}
							record={myInfoViewDetails}
							render={(consultant: any) => {
								return `${consultant?.joining_source}`;
							}}
						/>
					) : (
						'- -'
					)}
				</FieldContainer>
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Prior Experience</Typography>
						{myInfoViewDetails?.prior_experience ? (
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(experience: any) => {
									const employeeExperience = getExperience(experience);
									return employeeExperience?.prevExp;
								}}
							/>
						) : (
							'- -'
						)}
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>
							Current Experience
						</Typography>
						{myInfoViewDetails?.join_date ? (
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(employee: any) => {
									const employeeExperience = getExperience(employee);
									return employeeExperience?.psplExp;
								}}
							/>
						) : (
							'- -'
						)}
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Highest Degree</Typography>
						<FunctionField
							className={classes.value}
							record={myInfoViewDetails}
							render={(academics: any) =>
								`${academics?.highest_academic_degree || '- -'}`
							}
						/>
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Institution</Typography>
						<FunctionField
							className={classes.value}
							record={myInfoViewDetails}
							render={(user: any) => `${user?.institution_name || '- -'}`}
						/>
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Joining Date</Typography>
						<FunctionField
							className={classes.value}
							record={myInfoViewDetails}
							render={(user: any) =>
								`${
									user?.join_date
										? dayjs(user?.join_date).format(dateFormat)
										: '- -'
								}`
							}
						/>
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Blood Group</Typography>
						<FunctionField
							className={classes.value}
							record={myInfoViewDetails}
							render={(employee: any) => `${employee?.blood_group || '- -'}`}
						/>
					</FieldContainer>
				)}
				<FieldContainer>
					<Typography className={classes.label}>Gender</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(employee: any) => `${employee?.gender || '- -'}`}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Marital Status</Typography>
					<Box className={classes.value}>
						{myInfoViewDetails?.marital_status ? 'Married' : 'Unmarried'}
					</Box>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Wedding Date</Typography>
					<Box className={classes.value}>
						{myInfoViewDetails?.marital_status
							? myInfoViewDetails?.wedding_date
								? dayjs(myInfoViewDetails?.wedding_date).format(dateFormat)
								: '- -'
							: '- -'}
					</Box>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Name
					</Typography>
					<Box className={classes.value}>
						{myInfoViewDetails?.emergency_contact_name
							? myInfoViewDetails?.emergency_contact_name
							: '- -'}
					</Box>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Number
					</Typography>
					<Box className={classes.value}>
						{myInfoViewDetails?.emergency_contact_number
							? myInfoViewDetails?.emergency_contact_number
							: '- -'}
					</Box>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Shift</Typography>
					{myInfoViewDetails?.org_shift_id ? (
						<ReferenceField
							link={false}
							label=''
							source='org_shift_id'
							reference='org_shift'
							record={myInfoViewDetails}
							basePath={'/my-info'}
							className={classes.value}
						>
							<FunctionField
								className={classes.value}
								record={myInfoViewDetails}
								render={(consultant: any) => {
									return `${consultant?.name}`;
								}}
							/>
						</ReferenceField>
					) : (
						'--'
					)}
				</FieldContainer>
				{(myInfoViewDetails?.user_type === 'employee' &&
					myInfoViewDetails?.employee_status?.value === 'inactive') ||
					(myInfoViewDetails?.employee_status?.label === 'Notice' && (
						<FieldContainer>
							<Typography className={classes.label}>
								Last Working Date
							</Typography>
							<Typography className={classes.value}>
								{myInfoViewDetails?.last_date
									? dayjs(myInfoViewDetails?.last_date).format(dateFormat)
									: '- -'}
							</Typography>
						</FieldContainer>
					))}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>Leave policy</Typography>
						<Typography className={classes.value}>
							{myInfoViewDetails?.org_leave_group?.label
								? myInfoViewDetails?.org_leave_group?.label
								: '- -'}
						</Typography>
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>
							Probation Start Date
						</Typography>
						<Typography className={classes.value}>
							{myInfoViewDetails?.probation_start_date
								? dayjs(myInfoViewDetails?.probation_start_date).format(
										dateFormat
								  )
								: '- -'}
						</Typography>
					</FieldContainer>
				)}
				{myInfoViewDetails?.user_type === 'employee' && (
					<FieldContainer>
						<Typography className={classes.label}>
							Probation End Date
						</Typography>
						<Typography className={classes.value}>
							{myInfoViewDetails?.probation_end_date
								? dayjs(myInfoViewDetails?.probation_end_date).format(
										dateFormat
								  )
								: '- -'}
						</Typography>
					</FieldContainer>
				)}
				{/* CUSTOM FIELDS */}
				{myInfoViewDetails?.user_type === 'employee' &&
					employeeCustomFields?.custom_field?.map(
						(customField: any, index: any) => {
							return (
								<FieldContainer key={index}>
									<FieldRenderer
										label={customField.label}
										tooltip={
											customField?.type === 'date' &&
											myInfoViewDetails[`custom_field_${customField?.index}`]
												? dayjs(
														myInfoViewDetails[
															`custom_field_${customField?.index}`
														]
												  ).format(dateFormat) || '- -'
												: myInfoViewDetails[
														`custom_field_${customField?.index}`
												  ] || '- -'
										}
										value={
											customField?.type === 'date' &&
											myInfoViewDetails[`custom_field_${customField?.index}`]
												? dayjs(
														myInfoViewDetails[
															`custom_field_${customField?.index}`
														]
												  ).format(dateFormat) || '- -'
												: myInfoViewDetails[
														`custom_field_${customField?.index}`
												  ] || '- -'
										}
									/>
								</FieldContainer>
							);
						}
					)}

				{myInfoViewDetails?.user_type === 'contract_employee' &&
					consultantCustomFields?.custom_field?.map(
						(customField: any, index: any) => {
							return (
								<FieldContainer key={index}>
									<FieldRenderer
										label={customField.label}
										tooltip={
											customField?.type === 'date' &&
											myInfoViewDetails[`custom_field_${customField?.index}`]
												? dayjs(
														myInfoViewDetails[
															`custom_field_${customField?.index}`
														]
												  ).format(dateFormat) || '--'
												: myInfoViewDetails[
														`custom_field_${customField?.index}`
												  ] || '--'
										}
										value={
											customField?.type === 'date' &&
											myInfoViewDetails[`custom_field_${customField?.index}`]
												? dayjs(
														myInfoViewDetails[
															`custom_field_${customField?.index}`
														]
												  ).format(dateFormat) || '--'
												: myInfoViewDetails[
														`custom_field_${customField?.index}`
												  ] || '--'
										}
									/>
								</FieldContainer>
							);
						}
					)}
			</DetailContainer>
			<EditMyInfoDetails
				open={isFormShown}
				onClose={() => setIsFormShown(false)}
				record={employeeDetails}
				customFields={
					employeeDetails?.user_type === 'employee' &&
					refetchEmployee &&
					employeeCustomFields?.custom_field?.length > 0
						? employeeCustomFields?.custom_field?.map((customField: any) => ({
								id: customField?.id,
								label: customField?.label,
								type: customField.type,
								validation: customField?.validation,
								options: customField?.options || [],
								is_active: customField?.is_active,
								index: customField?.index,
						  })) || []
						: employeeDetails?.user_type === 'contract_employee' &&
						  consultantCustomFields?.custom_field?.length > 0 &&
						  refetchContractor
						? consultantCustomFields?.custom_field?.map((customField: any) => ({
								id: customField?.id,
								label: customField?.label,
								type: customField?.type,
								validation: customField?.validation,
								options: customField?.options || [],
								is_active: customField?.is_active,
								index: customField?.index,
						  })) || []
						: []
				}
				refetch={() => {
					if (employeeDetails?.user_type === 'employee' && refetchEmployee) {
						refetchEmployee();
						return;
					}
					if (refetchContractor) {
						refetchContractor();
					}
				}}
			/>
		</>
	);
};

export default MyInfoBasicDetails;
