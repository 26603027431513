import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import AttendanceMyTeamSearch from './AttendanceMyTeamSearch.component';
import dayjs from 'dayjs';
import { Style } from './Style';
import {
	useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery,
	useCurrentMonthAttendanceLeaveInfoQuery,
	useGetLeaveBalanceQuery,
	useGetLocationIdByUserIdQuery,
	useGetOrganisationLeaveCalenderYearQuery,
	useGetUserDetailsQuery,
} from '../../generated/graphql';
import no_data_found from '../../assets/no_data_found.png';

import { UserProfileContext } from '../../App';
import { setSidebarVisibility } from 'react-admin';
import { useDispatch } from 'react-redux';
import { compact, flatten, head, isEmpty, orderBy, range, sum } from 'lodash';
import { exportToCsv, formatDecimal, isFloat } from '../../Utils/string.util';
import {
	LEAVE_BALACE_EXPORT_CLOUMNS,
	calculateWorkedHours,
	calculatedLeaveCountInSummaryInLeaveData,
	calculatedTimesheetHours,
} from './Constant';
import { calculateCurrentLeaveCalenderYear } from '../../Reports/Constant';
import { LeaveBalanceProps, LeaveCountProps } from './Attendance.model';
import { ExportReportIcon } from '../../assets/SvgIcons';
import LeaveSummary from './LeaveSummary.component';
import Calender from './Calender.component';
import AttendanceContainer from './AttendanceContainer.component';
import useExcludeLeaveDates from './useExcludeLeaveDates';

const customStyle = makeStyles(() => ({
	summaryContainer: {
		minWidth: '580px',
		maxHeight: '586px',
		overflow: 'auto',
		background: '#FFFFFF',
		marginTop: '10px',
	},
	attendanceContainer: {
		background: '#FFFFFF',
	},
	calenderContainer: {
		marginTop: '10px',
		background: '#FFFFFF',
		minWidth: '719px',
		height: '456px',
		marginLeft: '20px',
		width: '-webkit-fill-available',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '28px',
		height: '28px',
		marginRight: '2px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	container: {
		flexGrow: 1,
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		marginTop: '10px',
	},
}));
interface SelectedMonthDates {
	startDate: string;
	endDate: string;
}
interface LeaveDate {
	date: string;
	halfDay: number;
	reason: string;
	leave_type: string;
	isExcluded: boolean;
}
export const AttendanceReportingManagerView = () => {
	const customClasses = customStyle();
	const classes = Style();
	const [selectedDate, setSelectedDate] = useState({
		startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
		endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
	});
	const { orgId: organisationId, attendanceConsiderableTimesheetStatus } =
		useContext<any>(UserProfileContext);
	const [selectedAttendanceMonth, setSelectedAttendanceMonth] = useState('');
	const [employeeId, setEmployeeId] = useState('');
	const [userId, setUserId] = useState('');

	const [timesheetHours, setTimesheetHours] = useState<number>(0);
	const [leaveApprover, setLeaveApprover] = useState('');
	const [workHoursLogged, setWorkedHourslogged] = useState(0);
	const [leaveDatesWithReason, setLeaveDatesWithReason] = useState<LeaveDate[]>(
		[]
	);

	const [organizationLeaves, setOrganizationLeaves] =
		useState<LeaveBalanceProps[]>();
	const [currentLeaveYear, setCurrentLeaveYear] = useState<SelectedMonthDates>({
		startDate: '',
		endDate: '',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);
	const date = range(dayjs(selectedDate?.startDate).endOf('month').date()).map(
		(value) => dayjs(selectedDate?.startDate).date(value + 1)
	);

	const { data: attendanceAndLeaveInSummary, refetch: refetchWorkHours } =
		useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery({
			variables: {
				userId,
				orgId: organisationId,
				startDate: selectedDate?.startDate,
				endDate: selectedDate?.endDate,
				statusList: attendanceConsiderableTimesheetStatus,
			},
			fetchPolicy: 'network-only',
		});
	const { data: leaveSummaryData, refetch: refetchLeaveSummary } =
		useCurrentMonthAttendanceLeaveInfoQuery({
			variables: {
				userId,
				startDate: selectedDate?.startDate,
				endDate: selectedDate?.endDate,
			},
			fetchPolicy: 'network-only',
		});
	const { data: orgLeaves, refetch: refetchLeaveBalance } =
		useGetLeaveBalanceQuery({
			variables: {
				employeeId,
				startDate: currentLeaveYear?.startDate,
				endDate: currentLeaveYear?.endDate,
			},
			fetchPolicy: 'network-only',
			skip: !employeeId || isEmpty(currentLeaveYear),
		});

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId },
			fetchPolicy: 'network-only',
		});

	const { data: locationDetails } = useGetLocationIdByUserIdQuery({
		variables: { userId: userId },
		skip: !userId,
		fetchPolicy: 'network-only',
	});

	const userLocationId =
		locationDetails && locationDetails?.user_by_pk
			? locationDetails?.user_by_pk?.user_type === 'employee'
				? locationDetails?.user_by_pk?.employee?.location_id
				: head(locationDetails?.user_by_pk?.contractors)?.location_id
			: null;

	const leaveDates = useExcludeLeaveDates(
		leaveDatesWithReason,
		selectedDate.startDate,
		selectedDate.endDate,
		userLocationId,
		userId
	);

	useEffect(() => {
		if (!attendanceAndLeaveInSummary) {
			return;
		}
		const timesheetHours = calculatedTimesheetHours(
			attendanceAndLeaveInSummary
		);
		setTimesheetHours(timesheetHours);

		if (attendanceAndLeaveInSummary?.leaveApprove) {
			const approver =
				attendanceAndLeaveInSummary?.leaveApprove?.user_type === 'employee'
					? `${
							head(attendanceAndLeaveInSummary?.leaveApprove?.employees)
								?.reportsTo?.full_name || '--'
					  }`
					: `${
							head(attendanceAndLeaveInSummary?.leaveApprove?.contractors)
								?.reportee?.full_name || '--'
					  }`;
			setLeaveApprover(approver);
		}

		if (
			!attendanceAndLeaveInSummary?.attendance ||
			!(attendanceAndLeaveInSummary?.attendance?.length > 0)
		) {
			setWorkedHourslogged(0);
			return;
		}

		const workedHours = calculateWorkedHours(attendanceAndLeaveInSummary);

		setWorkedHourslogged(workedHours);
	}, [attendanceAndLeaveInSummary]);

	useEffect(() => {
		if (!attendanceAndLeaveInSummary) {
			return;
		}
		const leaveCountData = leaveSummaryData
			? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				: []
			: [];
		const userLeaveDays = calculatedLeaveCountInSummaryInLeaveData(
			selectedDate,
			leaveCountData
		);

		const leaveDaysArray = flatten(userLeaveDays);
		const leaveDays = compact(leaveDaysArray);
		const leaveDataArray: LeaveDate[] = leaveDays?.map((leaveDay) => ({
			date: leaveDay?.date,
			halfDay: leaveDay?.halfDay,
			reason: leaveDay?.reason || '',
			leave_type: leaveDay?.leave_type || '',
			isExcluded: leaveDay?.isExcluded || false,
		}));
		setLeaveDatesWithReason(leaveDataArray);
	}, [attendanceAndLeaveInSummary, leaveSummaryData, selectedDate]);

	const leaveCount = sum(leaveDates?.map((e: any) => e?.halfDay));

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const leaveCalenderData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!leaveCalenderData) {
			return;
		}
		const calculatedDate = calculateCurrentLeaveCalenderYear({
			leave_calendar_year_start_month:
				leaveCalenderData.leave_calendar_year_start_month,
			leave_calendar_year_start_date:
				leaveCalenderData?.leave_calendar_year_start_date,
			leave_calendar_year_end_date:
				leaveCalenderData?.leave_calendar_year_end_date,
			leave_calendar_year_end_month:
				leaveCalenderData?.leave_calendar_year_end_month,
		});
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	useEffect(() => {
		if (isEmpty(orgLeaves?.org_leave_group)) {
			return;
		}
		const response = head(orgLeaves?.org_leave_group)?.org_leave_types?.map(
			(value) => {
				const appliedLeaveCount = value?.employee_leaving_mappings?.reduce(
					(sum: number, number: LeaveCountProps) => {
						return number?.leave_count + sum;
					},
					0
				);
				const accruedLeaveCount =
					value?.accrued_leave_type?.length > 0
						? value?.accrued_leave_type?.reduce((sum, number) => {
								return number?.count + sum;
						  }, 0)
						: 0;
				const openingBalance =
					value?.employee_leave_type_mappings?.length > 0
						? head(value?.employee_leave_type_mappings)?.opening_balance
						: '--';
				return {
					id: value?.id,
					name: value?.leave_type,
					defaultAmount: isFloat(Number(openingBalance))
						? formatDecimal(Number(openingBalance), 3)
						: openingBalance,
					accruedLeaveCount: isFloat(Number(accruedLeaveCount))
						? formatDecimal(Number(accruedLeaveCount), 3)
						: accruedLeaveCount,
					applied: Number(appliedLeaveCount) || 0,
					balance:
						accruedLeaveCount + openingBalance - Number(appliedLeaveCount) || 0,
					renewalDate:
						head(value?.employee_leave_type_mappings)?.renewal_date || null,
					leaveTypeId: head(value?.employee_leave_type_mappings)?.id || null,
					accruedId: head(value?.accrued_leave_type)?.id || null,
				};
			}
		);
		const sortedResponse = orderBy(response, ['name'], ['asc']);
		setOrganizationLeaves(sortedResponse);
	}, [orgLeaves]);

	useEffect(() => {
		if (!userId) {
			setLeaveApprover('');
		}
	}, [userId]);

	const { data: userDetails } = useGetUserDetailsQuery({
		variables: {
			userId,
		},
		skip: !userId,
	});

	const onExportLeaveBalance = () => {
		if (isEmpty(organizationLeaves)) {
			return;
		}
		const leaveBalanceList =
			organizationLeaves?.map((leave) => {
				return [
					leave?.name || '',
					leave?.defaultAmount
						? formatDecimal(Number(leave?.defaultAmount), 3)
						: 0,
					leave?.accruedLeaveCount
						? formatDecimal(Number(leave?.accruedLeaveCount), 3)
						: 0,
					leave?.applied ? formatDecimal(Number(leave?.applied), 3) : 0,
					leave?.balance ? formatDecimal(Number(leave?.balance), 3) : 0,
				];
			}) || [];

		exportToCsv(
			`${
				head(userDetails?.user)?.full_name
					? `${head(userDetails?.user)?.full_name} `
					: ''
			}Leave Balance Summary.csv`,
			[LEAVE_BALACE_EXPORT_CLOUMNS, ...leaveBalanceList]
		);
	};

	return (
		<Box>
			<Box marginTop='14px'>
				<Box>
					<Box className={customClasses.attendanceContainer}>
						<AttendanceMyTeamSearch
							setUserId={(id: string) => setUserId(id)}
							setSelectedDate={(startDate: string, endDate: string) =>
								setSelectedDate({ startDate, endDate })
							}
							userId={userId}
							selectedAttendanceMonth={selectedAttendanceMonth}
							setSelectedAttendanceMonth={(month: string) =>
								setSelectedAttendanceMonth(month)
							}
							setEmployeeId={(id: string) => setEmployeeId(id)}
						/>
					</Box>
					<Box display='flex'>
						<Box className={customClasses.summaryContainer}>
							{organizationLeaves &&
							organizationLeaves?.length > 0 &&
							employeeId ? (
								<Box>
									<Box display='flex' justifyContent='flex-end'>
										<Box>
											<Tooltip title='Export' placement='top'>
												<IconButton
													className={customClasses.exportIconContainer}
													onClick={() => onExportLeaveBalance()}
												>
													<ExportReportIcon
														className={customClasses.exportIcon}
													/>
												</IconButton>
											</Tooltip>
										</Box>
									</Box>
									<LeaveSummary
										refetch={() => refetchLeaveBalance()}
										organizationLeaves={organizationLeaves}
									/>
								</Box>
							) : (
								<Box>
									<Box className={classes.noData}>
										Please choose an employee with leave balance to view the
										leave summary.
									</Box>
								</Box>
							)}
						</Box>
						<Box className={customClasses.calenderContainer}>
							<Calender
								currentMonth={selectedDate}
								setCurrentMonth={(startDate: string, endDate: string) => {
									setSelectedDate({
										startDate,
										endDate,
									});
								}}
								userId={userId}
								isReportingManagerView={true}
								isManagerView={false}
								leaveApprover={leaveApprover || '-'}
								workHoursLogged={workHoursLogged}
								selectedAttendanceMonth={selectedAttendanceMonth}
								currentFinancialYear={currentLeaveYear}
								orgId={organisationId}
								timesheetHours={timesheetHours}
								employeeId={employeeId}
								currentLeaveCount={leaveCount}
								refetchLeaveBalance={() => refetchLeaveBalance()}
								userLocationId={userLocationId}
								leaveData={leaveDates || []}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className={customClasses.container}>
				{!(selectedAttendanceMonth && userId) ? (
					<Box textAlign='center'>
						<img src={no_data_found} alt='no_data_found' />
					</Box>
				) : (
					<AttendanceContainer
						refetch={() => {
							refetchWorkHours();
							refetchLeaveSummary();
						}}
						leaveData={leaveDates || []}
						userId={userId}
						date={date}
						currentMonth={selectedDate}
						isManagerView={false}
						isReportingManagerView={true}
						userLocationId={userLocationId}
					/>
				)}
			</Box>
		</Box>
	);
};
