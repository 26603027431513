import React, { useEffect } from 'react';

import {
	SelectInput,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	ReferenceInput,
	useNotify,
	useRefresh,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import {
	UPDATE_ORGANISATION,
	UPDATE_ORG_SUPPORTED_CURRENCIES,
} from '../Settings.gql';

import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

interface CurrencyProps {
	organisationCurrency: {
		currency_id?: string;
		supported_currency_id?: string[];
		organisationId?: string;
	};
	onSuccess: () => void;
}

interface CurrencyValues {
	currency_id?: string;
	supported_currency_id?: string[];
}

const CurrencySettings = ({
	organisationCurrency,
	onSuccess,
}: CurrencyProps) => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [
		updateOrgCurrency,
		{ data: updatedOrgCurrency, loading: isUpdatedOrgCurrencyLoading },
	] = useApolloMutation(UPDATE_ORGANISATION);
	const [updateOrgSupportedCurrencies] = useApolloMutation(
		UPDATE_ORG_SUPPORTED_CURRENCIES
	);

	useEffect(() => {
		if (updatedOrgCurrency) {
			notify('Currency Details Updated');
			refresh();
		}
	}, [updatedOrgCurrency, notify, refresh]);

	useEffect(() => {
		if (updatedOrgCurrency) {
			onSuccess();
		}
	}, [updatedOrgCurrency, onSuccess]);

	const onHandleSubmit = (currencyValues: CurrencyValues) => {
		if (currencyValues.supported_currency_id) {
			updateOrgSupportedCurrencies({
				variables: {
					organisationId: organisationCurrency?.organisationId,
					data: currencyValues.supported_currency_id.map(
						(supportedCurrency: string) => ({ currency_id: supportedCurrency })
					),
				},
			});
		}
		updateOrgCurrency({
			variables: {
				organisationId: organisationCurrency?.organisationId,
				data: {
					currency_id: currencyValues?.currency_id,
				},
			},
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form initialValues={organisationCurrency} onSubmit={onHandleSubmit}>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Base Currency
									</Typography>
								</Box>
								<Box className={styles.input}>
									<ReferenceInput
										source='currency_id'
										reference='master_currencies'
										label=''
										sort={{ field: 'currency_type', order: 'ASC' }}
									>
										<SelectInput optionText='currency_type' />
									</ReferenceInput>
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Supported Currency
									</Typography>
								</Box>
								<Box className={styles.input}>
									<ReferenceArrayInput
										label=''
										reference='master_currencies'
										source='supported_currency_id'
										sort={{ field: 'currency_type', order: 'ASC' }}
										filterToQuery={(searchText: string) => ({
											currency_type: searchText,
										})}
									>
										<AutocompleteArrayInput optionText='currency_type' />
									</ReferenceArrayInput>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									title='Save'
									isDisabled={invalid || pristine || isUpdatedOrgCurrencyLoading}
									isLoading={isUpdatedOrgCurrencyLoading}
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default CurrencySettings;
