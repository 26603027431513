import React from 'react';
import {
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Field } from 'react-final-form';
import { autoCompleteTheme } from '../Layout/Theme.component';
import {
	AutocompleteSearchStyles,
	dropDownEllipsisStyle,
} from '../Layout/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchIcon } from '../assets/SvgIcons';

export interface DropdownOption {
	id: string | null | undefined;
	name: string | null | undefined;
	user_id?: string;
}

interface Props {
	name: string;
	onChange: (id: string) => void;
	placeholder: string;
	value: DropdownOption | null;
	option: DropdownOption[];
	validates?: boolean;	
}

export const AutocompleteSearch = (props: Props) => {
	const { placeholder, option, onChange, value, name, validates } = props;
	const ellipsis = dropDownEllipsisStyle();
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const validate = (value: string) => {
		if (validates && !value) {
			return true;
		}
		return false;
	};
	return (
		<Box>
			<Field name={name} validate={validate}>
				{(props: any) => (
					<ThemeProvider theme={autoCompleteTheme}>
						<div className={autocompleteSearchStyles.customFieldContainer}>
							<Autocomplete
								id={name}
								classes={{
									option: autocompleteSearchStyles.options,
									groupLabel: autocompleteSearchStyles.groupLabel,
									inputRoot: autocompleteSearchStyles.inputRoot,
									noOptions: autocompleteSearchStyles.noOptions,
									focused: autocompleteSearchStyles.focused,
									input: autocompleteSearchStyles.input,
									endAdornment: autocompleteSearchStyles.endAdornment,
								}}
								getOptionSelected={(option: any, value: any) =>
									option?.id === props.input.value
								}
								onChange={(event, newValue: any) => {
									if (!newValue) {
										onChange('');
										props.input.onChange('');
										return;
									}
									onChange(newValue?.id);
									props.input.onChange(newValue?.id);
								}}
								value={value}
								options={option || []}
								getOptionLabel={(option: any) => option?.name || ''}
								renderInput={(params) => (
									<TextField
										{...params}
										label=''
										placeholder={placeholder}
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment
													position='start'
													classes={{
														root: autocompleteSearchStyles.inputAdronment,
														positionStart:
															autocompleteSearchStyles.inputAdronmentPositionStart,
													}}
												>
													<SearchIcon
														className={
															autocompleteSearchStyles.searchIconPositionStart
														}
													/>
												</InputAdornment>
											),
										}}
									/>
								)}
								renderOption={(option) => {
									return (
										<>
											<Tooltip
												title={`${option?.name || '- -'}`}
												placement='right'
											>
												<Typography className={`${ellipsis.ellipsis}`}>
													{`${option?.name || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									);
								}}
							/>
						</div>
					</ThemeProvider>
				)}
			</Field>
		</Box>
	);
};
