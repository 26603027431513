import React, { useState, useEffect } from 'react';
import {
	FormWithRedirect,
	DateInput,
	TextInput,
	SaveButton,
	DeleteButton,
	NumberInput,
	ReferenceInput,
	SelectInput,
	required,
	TopToolbar,
	ListButton,
	useGetList,
	AutocompleteInput,
	useRefresh,
	minValue,
} from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ProjectTabs from './ProjectTabs.component';
import { InputAdornment } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { endDateValidation } from '../../Utils/date-time.util';

const theme = createTheme({
	overrides: {
		MuiTypography: {
			colorTextSecondary: {
				fontSize: '17px !important',
			},
		},
	},
});

export const ProjectEditForm = (props) => {
	const customerId = props.location?.state;
	const customerPath = `/customer/${customerId?.customer_id}`;
	const { data: organizations } = useGetList('organization');
	const orgIds = Object.keys(organizations);
	const selectedOrgId = orgIds.length === 0 ? '' : orgIds[0];
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [project, setProject] = useState(props ? props : null);
	const projectType = props?.record.project_type;
	const status = props?.record.project_status;
	const endDate = props?.record.end_date;
	const refresh = useRefresh();

	const mapToFullName = (choice) => `${choice?.full_name || ''} `;

	const onClear = () => {
		refresh();
	};

	useEffect(() => {
		const date = new Date();
		const month = date.getMonth() + 1;
		const day = date.getDay();
		const year = date.getFullYear();
		const currentDate = new Date(year, month, day);
		const projectEndDate = new Date(endDate);

		if (status === 'InProgress' || selectedStatus === 'InProgress') {
			const formattedProject = {
				...project.record,
				conversion_probability: 100,
				org_id: selectedOrgId,
			};
			setProject({ ...project, record: formattedProject });
			if (currentDate > projectEndDate && status === 'InProgress') {
				const formattedProject = { ...project.record, risk_status: 'Red' };
				setProject({ ...project, record: formattedProject });
			}
			if (currentDate < projectEndDate && status === 'InProgress') {
				const formattedProject = { ...project.record, risk_status: 'Green' };
				setProject({ ...project, record: formattedProject });
			}
		}
		// eslint-disable-next-line
	}, [status, selectedStatus]);

	const validateEndDate = [required(), endDateValidation];

	return (
		<ThemeProvider theme={theme}>
			<FormWithRedirect
				{...project}
				render={(formProps) => {
					return (
						<>
							<form>
								{customerId ? (
									<Box display='none'>
										<ReferenceInput source='customer_id' reference='customer'>
											<SelectInput
												resettable={true}
												optionText='company_name'
												initialValue={customerId ? customerId.customer_id : ''}
											/>
										</ReferenceInput>
									</Box>
								) : (
									''
								)}
								<Box p='1em'>
									<Box display={{ md: 'block', lg: 'flex' }}>
										<Box flex={2} mr='1em'>
											<Box display='flex'>
												<Box flex={1} mr='0.5em'>
													<TextInput
														source='name'
														validate={required()}
														fullWidth
													/>
												</Box>
												<Box flex={1} ml='0.5em'>
													<ReferenceInput
														source='customer_id'
														reference='customer'
														filterToQuery={(searchText) => ({
															company_name: searchText,
														})}
														filter={{ company_name: true }}
														validate={[required()]}
														sort={{ field: 'company_name', order: 'ASC' }}
													>
														<AutocompleteInput
															optionText='company_name'
															initialValue={
																customerId ? customerId.customer_id : ''
															}
															shouldRenderSuggestions={(name) => {
																return name.trim().length > 0;
															}}
															fullWidth
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box display='flex'>
												<Box flex={1} mr='0.5em'>
													<SelectInput
														resettable={true}
														fullWidth
														source='project_type'
														validate={required()}
														label='Project Type'
														choices={[
															{ id: 'FP', name: 'FP' },
															{ id: 'Other', name: 'Other' },
															{ id: 'TM', name: 'TM' },
														]}
													/>
												</Box>
												<Box flex={1} ml='0.5em'>
													<ReferenceInput
														source='geo_id'
														reference='geo_list'
														label='Geography'
														sort={{ field: 'name', order: 'ASC' }}
													>
														<SelectInput
															resettable={true}
															optionText='name'
															validate={required()}
															fullWidth
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box display='flex'>
												<Box flex={1} mr='0.5em'>
													<SelectInput
														resettable={true}
														source='project_execution_type'
														fullWidth
														label='Execution Type'
														choices={[
															{ id: 'External', name: 'External' },
															{ id: 'Internal', name: 'Internal' },
															{ id: 'Other', name: 'Other' },
														]}
													/>
												</Box>

												<Box flex={1} ml='0.5em'>
													<SelectInput
														resettable={true}
														source='project_status'
														validate={required()}
														fullWidth
														label='Status'
														choices={[
															{ id: 'Completed', name: 'Completed' },
															{ id: 'Future', name: 'Future' },
															{ id: 'InProgress', name: 'In Progress' },
															{ id: 'Lost', name: 'Lost' },
															{ id: 'OnHold', name: 'On Hold' },
														]}
														onChange={(event) =>
															event?.target?.value
																? setSelectedStatus(event.target.value)
																: setSelectedStatus('')
														}
													/>
												</Box>
											</Box>
											<Box display='flex'>
												<Box flex={1} mr='0.5em'>
													<DateInput
														source='start_date'
														label='Start Date'
														fullWidth
														validate={required()}
													></DateInput>
												</Box>
												<Box flex={1} ml='0.5em'>
													<DateInput
														source='end_date'
														label='End Date'
														fullWidth
														validate={validateEndDate}
													/>
												</Box>
											</Box>
											<Box display='flex'>
												<Box flex={1} mr='0.5em'>
													<NumberInput
														source='effort_estimate'
														min={1}
														label='Estimated Hours'
														fullWidth
														validate={[required(), minValue(1)]}
													/>
												</Box>
												<Box flex={1} ml='0.5em'>
													<NumberInput
														source='revenue'
														min={1}
														validate={minValue(1)}
														label='Budget'
														InputProps={{
															startAdornment: (
																<InputAdornment position='start'>
																	USD
																</InputAdornment>
															),
														}}
														fullWidth
													/>
												</Box>
											</Box>
										</Box>
										<Box
											flex={1}
											ml={{ xs: 0, lg: 0 }}
											mt={{ xs: '1em', lg: 0 }}
										>
											<Box display='none'>
												<ReferenceInput
													source='org_id'
													reference='organization'
													label='Organization'
												>
													<SelectInput
														resettable={true}
														initialValue={
															selectedOrgId.length ? selectedOrgId : ''
														}
														fullWidth
													/>
												</ReferenceInput>
											</Box>
											<ReferenceInput
												source='pre_sales_owner_id'
												reference='user'
												label='Pre Sales Owner'
												filterToQuery={(searchText) => ({
													first_name: searchText,
												})}
												filter={{ first_name: true }}
												validate={[required()]}
												sort={{ field: 'first_name', order: 'ASC' }}
											>
												<AutocompleteInput
													optionText={mapToFullName}
													shouldRenderSuggestions={(name) => {
														return name.trim().length > 0;
													}}
													fullWidth
												/>
											</ReferenceInput>
											<ReferenceInput
												source='project_owner_id'
												reference='user'
												label='Project Owner'
												filterToQuery={(searchText) => ({
													first_name: searchText,
												})}
												filter={{ first_name: true }}
												validate={[required()]}
												sort={{ field: 'first_name', order: 'ASC' }}
											>
												<AutocompleteInput
													optionText={mapToFullName}
													shouldRenderSuggestions={(name) => {
														return name.trim().length > 0;
													}}
													fullWidth
												/>
											</ReferenceInput>
											<Box flex={1}>
												<ReferenceInput
													source='lg_owner_id'
													reference='user'
													label='Lead Generated By'
													filterToQuery={(searchText) => ({
														first_name: searchText,
													})}
													filter={{
														first_name: true,
													}}
													validate={[required()]}
													sort={{ field: 'first_name', order: 'ASC' }}
												>
													<AutocompleteInput
														optionText={mapToFullName}
														shouldRenderSuggestions={(name) => {
															return name.trim().length > 0;
														}}
														fullWidth
													/>
												</ReferenceInput>
											</Box>
											<Box flex={1}>
												<SelectInput
													resettable={true}
													source='risk_status'
													fullWidth
													label='Risk Status'
													choices={[
														{ id: 'Amber', name: 'Amber' },
														{ id: 'Green', name: 'Green' },
														{ id: 'Red', name: 'Red' },
													]}
													initialValue={'Green'}
												/>
											</Box>
											{status === 'Future' || selectedStatus === 'Future' ? (
												<Box flex={1}>
													<SelectInput
														fullWidth
														resettable={true}
														source='conversion_probability'
														label='Probability'
														choices={[
															{ id: 100, name: '100%' },
															{ id: 75, name: '75%' },
															{ id: 50, name: '50%' },
															{ id: 25, name: '25%' },
														]}
														initialValue={
															selectedStatus === 'Future' ? '100' : ''
														}
													/>
												</Box>
											) : (
												''
											)}
										</Box>
									</Box>
								</Box>
								<Toolbar>
									<Box
										display='flex'
										justifyContent='space-between'
										width='100%'
									>
										<SaveButton
											saving={formProps.saving}
											redirect={customerId ? customerPath : 'edit'}
											handleSubmitWithRedirect={
												formProps.handleSubmitWithRedirect
											}
										/>
										<DeleteButton
											record={formProps.record}
											basePath={formProps.basePath}
											resource={formProps.resource}
											undoable={false}
											confirmTitle={'Delete Project'}
											confirmContent={
												'Are you sure you want to delete this item?'
											}
											label={'Delete'}
										/>
									</Box>
								</Toolbar>
							</form>
							<Box p={2}>
								{props.id ? (
									<ProjectTabs
										projectId={props.id}
										refresh={onClear}
										projectType={projectType}
										projectSoW={props?.record?.sow_id || null}
										customerId={props?.record.customer_id}
									/>
								) : (
									''
								)}
							</Box>
						</>
					);
				}}
			/>
		</ThemeProvider>
	);
};
export const ProjectCreateActions = (props) => {
	const customerLocation = new URLSearchParams(props?.location?.search);
	const customerListButton = customerLocation.get('name');
	const path = customerLocation.get('path');

	return (
		<TopToolbar>
			<ListButton
				basePath={path ? path : props.basePath}
				label={customerListButton ? customerListButton : 'Project List'}
				icon={<ChevronLeft />}
			/>
		</TopToolbar>
	);
};
