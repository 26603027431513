import React from 'react';
import {
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	AreaChart,
} from 'recharts';

function PMSAppraisalBellCurveChart(props: {
	data: {
		name: string;
		employees: number;
	}[];
	totalEmployees: number;
}) {
	const { data, totalEmployees } = props;

	const getPercentage = (value: number) =>
		((value / totalEmployees) * 100).toFixed(1);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active) {
			return (
				<div
					style={{
						background: '#FFFFFF',
						padding: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<p
							style={{
								fontFamily: 'Manrope-bold',
							}}
						>
							{`${getPercentage(payload?.[0].value)}%`}
						</p>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<p className='desc'>{`Employees: `}</p>
						<p
							style={{
								fontFamily: 'Manrope-bold',
							}}
						>
							{payload?.[0].value}
						</p>
					</div>
				</div>
			);
		}

		return null;
	};

	return (
		<ResponsiveContainer width='50%'>
			<AreaChart
				width={500}
				height={400}
				data={data}
				margin={{
					top: 10,
					right: 30,
					left: 0,
					bottom: 0,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey='name' />
				<YAxis />
				<Tooltip content={<CustomTooltip />} />
				<Area
					type='monotone'
					dataKey='employees'
					stroke='#8884d8'
					fill='#8884d8'
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
}

export default PMSAppraisalBellCurveChart;
