import { Box,makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import TaskList from './TaskList.component';
import LeaveSummary from './LeaveSummary.component';
import { useQuery } from 'react-admin';
import { UserProfileContext } from '../../App';

export const Style = makeStyles({
  chartContainer: {
    width:'40vw'
  },
  container : {
    marginTop: '25px',
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: '15px',
    maxWidth:'83vw'
  }
})


const TaskContainer = ()=>{
  const { id: userId } = useContext<any>(UserProfileContext);
  const classes = Style();
  const { data: employee } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'employee',
			payload: {
				filter: {
					user_id: userId,
				},
			},
		},
		{ enabled: !!userId }
	);

  
  return (
    <Box className={classes.container}>
      <Box className={classes.chartContainer}>
        <TaskList/>
      </Box>
      <Box className={classes.chartContainer}>
        <LeaveSummary employeeId={employee?.length > 0 ? employee[0].id : ''}/>
      </Box>
    </Box>
  )
};

export default TaskContainer;