export const getDropOrdinal = (cards, destinationIndex, isSameColumn = false) => {
    const nextCardOrdinal = cards[destinationIndex]?.ordinal || 0;
    const prevCardOrdinal = cards[destinationIndex - 1]?.ordinal || 0;
    
    const isFirstCardInColumn = nextCardOrdinal === 0 && prevCardOrdinal === 0;
    if (isFirstCardInColumn) {
      return 1;
    }
    
    const isLastCardInColumn = nextCardOrdinal === 0;
    if(isLastCardInColumn) {
      return Math.ceil(prevCardOrdinal + 1);
    }

    const isSameColumnLastPosition = isSameColumn && (cards.length - 1) === destinationIndex
    if(isSameColumnLastPosition) {
      return Math.ceil(nextCardOrdinal + 1);
    }
  
    return (nextCardOrdinal + prevCardOrdinal) / 2;
  }