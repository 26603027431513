import React, { useContext, useState } from 'react';

import {
	Box,
	createTheme,
	makeStyles,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { BooleanInput, useNotify, useRefresh } from 'react-admin';
import { settingsStyle } from '../../settingsStyle';
import { UserProfileContext } from '../../../../App';
import { useUpdateOrgUsrCustomPicEnableMutation } from '../../../../generated/graphql';
import WarningConfirmationModal from '../../../../SharedComponents/WarningConfirmationModal.component';
import {
	ENABLE_USR_CUSTOM_PIC_CONFIRMATION,
	ENABLE_USR_CUSTOM_PIC_SUCCESS_TEXT,
} from '../../constant';

const employeeProfilePicSettingsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

function EmployeeProfilePicSettings() {
	const { isUserCustomPictureEnabled, orgId } =
		useContext<any>(UserProfileContext);
	const employeeProfilePicSettingsStyles = employeeProfilePicSettingsStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isUserCustomProfilePicEnabled, setIsUserCustomProfilePicEnabled] =
		useState<boolean>(false);

	const [updateOrgUsrCustomProfilePic] =
		useUpdateOrgUsrCustomPicEnableMutation();

	function handleSave() {
		updateOrgUsrCustomProfilePic({
			variables: {
				orgId: orgId,
				enableUsrCustomPic: isUserCustomProfilePicEnabled,
			},
		})
			.then((res) => {
				if (!res.errors) {
					refresh();
					notify(ENABLE_USR_CUSTOM_PIC_SUCCESS_TEXT);
					onWarningModalClose();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function handleChange(value: boolean) {
		setIsUserCustomProfilePicEnabled(value);
		setIsWarningModalShown(true);
	}

	const onWarningModalClose = () => {
		setIsUserCustomProfilePicEnabled(false);
		setIsWarningModalShown(false);
	};

	return (
		<>
			<Form
				onSubmit={handleSave}
				initialValues={{
					enable_usr_custom_pic: isUserCustomPictureEnabled,
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div className={employeeProfilePicSettingsStyles.container}>
								<Box
									className={settingsStyles.inputContainer}
									display='flex'
									flexDirection='column'
								>
									<>
										<div className={settingsStyles.inputContainer}>
											<BooleanInput
												label='Profile Picture Upload'
												source='enable_usr_custom_pic'
												onChange={handleChange}
											/>
										</div>
										<Typography
											className={employeeProfilePicSettingsStyles.description}
										>
											Enabling this will allow users to modify profile picture.
										</Typography>
									</>
								</Box>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				warningMessage={ENABLE_USR_CUSTOM_PIC_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
			/>
		</>
	);
}

export default EmployeeProfilePicSettings;
