import React, { useState } from 'react';
import { TextInput, required, useNotify, useMutation } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../settingsStyle';

interface FormValue {
	label?: string;
	name?: string;
	description?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	subCategoryId?: string;
	createdType :(id?: string) => void
}
const CheckListTypeForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, subCategoryId, createdType } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const [isActive, setIsActive] = useState(true);
	const [mutate] = useMutation();
	const notify = useNotify();

	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		mutate(
			{
				type: 'create',
				resource: 'checklist_types',
				payload: {
					data: { ...formValues, is_active: isActive,sub_module_id: subCategoryId },
				},
			},
			{
				onSuccess: ({ data }) => {
					if(data?.id){
						createdType(data.id)
					}
					notify('Checklist type created');
					refetch()
					onClose();
				},
				onFailure: (error) =>
					notify(`Create failed: ${error.message}`, 'warning'),
			}
		);
		return;
	};
	const closeModal = () => {
		onClose();
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							Add CheckList Type
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form onSubmit={onSubmit}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name *
									</Typography>
									<TextInput source='name' label='' validate={required()} />
									<Typography className={modalFormStyles.label}>
										Label *
									</Typography>
									<TextInput source='label' label='' validate={required()} />
									<Typography className={modalFormStyles.label}>
										Description *
									</Typography>
									<TextInput source='description' label='' multiline={true}  validate={required()}/>
									<Typography className={modalFormStyles.radioButtonLabel}>
										{isActive? 'Active' : 'Inactive'}
									</Typography>
									<Switch
										defaultChecked={true}
										color='primary'
										name='is_active'
										onChange={(event: any) => {
											setIsActive(event?.target?.checked);
										}}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine}
										className={
											invalid || pristine
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{isEditMutation ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CheckListTypeForm;
