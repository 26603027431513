import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import './table-antdStyle.css';
import { insightChartTheme } from '../../Layout/Theme.component';

	const columns = [
		{
			title: 'OPPORTUNITY NAME',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
		},
		{
			title: 'DATE',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'VALUE',
			dataIndex: 'value',
			key: 'value',
		},
	];

export const OpportunityRecentWins = (props) => {
	const { recentWinsList } = props

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<CardHeader
					title='Recent Wins'
					titleTypographyProps={{ variant: 'h6' }}
				/>
				<CardContent style={{ overflowY: 'auto' }}>
					<ResponsiveContainer height={355}>
						{recentWinsList && recentWinsList?.length > 0 ? (
							<Table
							size='small'
							pagination={false}
							columns={columns}
							dataSource={recentWinsList}
							scroll={{
								x: '100w',
								y: 300,
							}}
						/>
						):
						(
							<Typography>No data found</Typography>
						)
						}
						
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
