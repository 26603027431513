import React from 'react';
import { calculateDateDifference } from '../../../Utils/date-time.util';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
	warranty: {
		fontFamily: 'Manrope-Medium',
		color: '#888888',
	},
	expiredWarranty: {
		color: '#EA4335',
		fontFamily: 'Manrope',
	},
	leftWarranty: {
		fontFamily: 'Manrope',
		color: '#34A853',
	},
  days: {
		fontFamily: 'Manrope',
		color: '#292929',
		paddingLeft: '2px',
	},
}))
export const GetWarrantyState = (date: string) =>{
  const classes = useStyles();
	const diffDays = calculateDateDifference(date);
  const warrantyDays = String(diffDays).concat(' ', 'Days');

    if (diffDays <= 0) {
      return <span className={classes.expiredWarranty}>Warranty Expired</span>;
    }
    if (diffDays > 0)
      return (
        <>
          <span className={classes.leftWarranty}>{warrantyDays}</span>
          <span className={classes.days}>left in warranty</span>
        </>
      );
  
}