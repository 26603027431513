import { createTheme } from '@material-ui/core/styles';


export const listTheme = createTheme({
  overrides: {
	MuiTableCell: {
		root: {
			borderBottom: 'none',
		}
	},
	RaList: {
		content: {
			boxShadow: 'none',
			backgroundColor: '#FFFFFF',
		}
	},
	MuiTable: {
		root: {
			borderCollapse: "separate",
			borderSpacing: "0 5px",
			marginTop: "-5px",
		}
	},
	MuiTableRow: { 
		root:{
			backgroundColor: '#F7F9FA !important',
			'&.MuiTableRow-hover:hover': {
				backgroundColor: '#F7F9FA',
			}
		},
		hover: {
			backgroundColor: '#FFFFFF',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				boxShadow: '0px 8px 19px #0000000F',
				'& > td': {
					'& > span': {
						'& div': {
							'& > button': {
								display: 'block !important',
							}
						}
					}
				},
			}
		}
	},
	RaDatagrid: {
		headerCell: {
			fontSize: '10px',
			fontFamily: 'Manrope-extrabold',
			color: '#5C5C5C',
			backgroundColor: '#FFFFFF !important',
		},
		row: {
			height: '40px',
			borderRadius: '4px',
		},
		rowCell: {
			fontFamily: 'Manrope-medium',
			fontSize: '12px',
			color: '#292929',

			// URL field link style
			'& div > a': {
				color: '#4285F4'
			},
			'& .MuiTypography-body2': {
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
				color: '#292929',

				// Functional field reference link color
				'& > a': {
					color: '#4285F4'
				}
			},
			'& .RaLink-link': {
				'& .MuiTypography-body2': {
					fontFamily: 'Manrope-medium',
					fontSize: '12px',
					color: '#292929',
				}
			},
			'& > span': {
				fontFamily: 'Manrope-medium',
				fontSize: '12px',
				color: '#292929',
			},
			'& .RaReferenceField': {
				link: {
					color: '#4285F4'
				}
			},
			'& > a': {
				color: '#4285F4'
			}
		}
	},
  }
})