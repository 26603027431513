import React, { useState, cloneElement, useEffect, ChangeEvent } from 'react';
import {
	List,
	Datagrid,
	FunctionField,
	Filter,
	TextInput,
	SelectInput,
	ReferenceManyField,
	ReferenceField,
	TextField,
	SingleFieldList,
	Loading,
	TopToolbar,
	Button,
	useListContext,
} from 'react-admin';

import SettingsDetailContainer from '../SettingsDetailContainer.component';
import {
	Box,
	IconButton,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { datagridStyle } from '../../../Layout/styles';
import { GET_USERS } from '../Settings.gql';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import UserForm from './UserForm.component';
import AddIcon from '@material-ui/icons/Add';
import { USER_EXPORT_COLUMNS } from './constant';
import { exportToCsv, formatPhoneNumber } from '../../../Utils/string.util';
import { head } from 'lodash';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '16px 0px',
	},
	financialYear: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px',
		color: '#292929',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	exportButton: {
		marginLeft: '8px',
		marginRight: '4px',
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	fullname: {
		width: '150px',
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
});

const UserFilter = (props: any) => (
	<Filter {...props}>
		<TextInput
			label='Search by user'
			source='email,first_name,middle_name,last_name,full_name'
			alwaysOn
			onChange={(e) => props.onSearchChange(e)}
		/>
		<SelectInput
			resettable={true}
			source='is_active'
			label='Status'
			fullWidth
			choices={[
				{ id: true, name: 'Active' },
				{ id: false, name: 'Inactive' },
			]}
			onChange={(e) => {
				props.onStatusChange(e);
			}}
		/>
		<SelectInput
			resettable={true}
			source='user_type'
			label='User Type'
			choices={[
				{ id: 'contract_employee', name: 'Contract' },
				{ id: 'employee', name: 'Employee' },
			]}
			onChange={(e) => {
				props.onTypeChange(e);
			}}
		/>
	</Filter>
);

interface User {
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	full_name?: string;
	is_active?: boolean;
	user_type?: string;
	email?: string;
	id?: string;
	phone_number?: string;
}

const UsersList = (props: any) => {
	const datagridStyles = datagridStyle();

	const customStyles = useStyles();

	const [isUserFormshown, setIsUserFormshown] = useState(false);
	const [isEditMutation, setIsEditMutation] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [searchText, setSearchText] = useState('');
	const [userStatus, setUserStatus] = useState<string | null>(null);
	const [userType, setUserType] = useState('');

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event?.target?.value);
	};

	const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event?.target as HTMLInputElement;
		setUserStatus(target?.value);
	};

	const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event?.target as HTMLInputElement;
		setUserType(target?.value);
	};

	const {
		data: users,
		loading,
		refetch: refetchUsers,
	} = ApolloUseQuery(GET_USERS, {
		variables: {
			userFilter: {
				_and: [
					...(searchText
						? [
								{
									_or: [
										{ first_name: { _ilike: `%${searchText}%` } },
										{ last_name: { _ilike: `%${searchText}%` } },
										{ email: { _ilike: `%${searchText}%` } },
										{ full_name: { _ilike: `%${searchText}%` } },
										{ middle_name: { _ilike: `%${searchText}%` } },
									],
								},
						  ]
						: []),
					...(userStatus !== null ? [{ is_active: { _eq: userStatus } }] : []),
					...(userType ? [{ user_type: { _eq: userType } }] : []),
				],
			},
		},
	});

	const onHandleEditUser = (userId: string) => {
		if (!userId) {
			return;
		}
		if (users?.user.length > 0) {
			const user = users.user.find((user: User) => user.id === userId);
			if (user) {
				setSelectedUser({
					...user,
					role_id: user?.role_mappings[0]?.role_id,
				});
				setIsEditMutation(true);
				setIsUserFormshown(true);
			}
		}
	};

	const generateCSV = () => {
		if (users?.user?.length === 0) {
			return;
		}

		interface Role {
			name?: string;
		}
		interface RoleMappings {
			role_id?: string;
			role?: Role;
		}
		interface UserExports {
			full_name?: string;
			email?: string;
			user_type?: string;
			role_mappings?: RoleMappings[];
			is_active?: boolean;
			phone_number?: string;
		}

		const usersRow: UserExports[] = users?.user.map(
			(exportUser: UserExports) => {
				const userPhoneNumber = formatPhoneNumber(exportUser?.phone_number);
				return [
					exportUser?.full_name || '--',
					exportUser?.email || '--',
					exportUser?.user_type || '--',
					head(exportUser?.role_mappings)?.role?.name || '--',
					exportUser?.is_active ? 'Active' : 'Inactive' || '--',
					exportUser?.phone_number ? userPhoneNumber : '--',
				];
			}
		);

		const userExportData = [USER_EXPORT_COLUMNS, ...usersRow];
		exportToCsv('UsersList.csv', userExportData);
	};

	const UserActions = (props: any) => {
		const { displayedFilters } = useListContext();

		useEffect(() => {
			if (!Object.keys(displayedFilters).length) {
				if (!Object.keys(displayedFilters).includes('is_active')) {
					setUserStatus(null);
				}
				if (!Object.keys(displayedFilters).includes('user_type')) {
					setUserType('');
				}
			}
		}, [displayedFilters]);

		return (
			<Box display={'flex'}>
				<Box>
					<TopToolbar>
						{cloneElement(props.filters, { context: 'button' })}
						<Button
							onClick={() => {
								setIsUserFormshown(true);
							}}
							label='Create User'
						>
							<AddIcon />
						</Button>

						<ExportButtonWithLoader
							generateCSV={generateCSV}
							style={customStyles.exportButton}
							isDisabled={!users?.user?.length}
						/>
					</TopToolbar>
				</Box>
			</Box>
		);
	};

	return (
		<SettingsDetailContainer heading='User Management'>
			<List
				resource='user'
				basePath='/user'
				title=' '
				sort={{ field: 'first_name', order: 'ASC' }}
				bulkActionButtons={false}
				filters={
					<UserFilter
						onSearchChange={handleSearchChange}
						onStatusChange={handleStatusChange}
						onTypeChange={handleTypeChange}
					/>
				}
				actions={<UserActions />}
			>
				{loading ? (
					<Loading />
				) : (
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.full_name || '- -'}`}
										placement='right'
									>
										<Typography
											className={`${customStyles.fullname} ${customStyles.ellipsis}`}
										>
											{`${record?.full_name || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='EMAIL'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.email || '- -'}`}
										placement='right'
									>
										<Typography
											className={`${customStyles.fullname} ${customStyles.ellipsis}`}
										>
											{`${record?.email || '- -'}`}
										</Typography>
									</Tooltip>
								);
							}}
						/>

						<FunctionField
							label='USER TYPE'
							render={(user: any) =>
								`${
									user.user_type === 'contract_employee'
										? 'Contract'
										: 'Employee'
								}`
							}
						/>
						<ReferenceManyField
							label='ROLE'
							reference='role_mapping'
							target='user_id'
						>
							<SingleFieldList linkType=''>
								<ReferenceField source='role_id' reference='role' link={false}>
									<TextField source='name' />
								</ReferenceField>
							</SingleFieldList>
						</ReferenceManyField>
						<FunctionField
							label='STATUS'
							render={(user: any) => (user?.is_active ? 'Active' : 'Inactive')}
						/>
						<FunctionField
							render={(user: any) => (
								<Box
									className={datagridStyles.listEditIconContainer}
									id={`edit-user-${user?.id?.substring(0, 3)}`}
								>
									<IconButton
										className={datagridStyles.listEditIconButton}
										onClick={() => {
											onHandleEditUser(user?.id);
										}}
									>
										<EditIcon className={datagridStyles.editIcon} />
									</IconButton>
								</Box>
							)}
						/>
					</Datagrid>
				)}
			</List>
			<UserForm
				open={isUserFormshown}
				onClose={() => {
					setIsUserFormshown(false);
					setSelectedUser({});
					setIsEditMutation(false);
				}}
				initialValues={selectedUser}
				isEditMutation={isEditMutation}
				onSuccess={() => {
					refetchUsers();
				}}
			/>
		</SettingsDetailContainer>
	);
};

export default UsersList;
