import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { debounce } from 'lodash';
import { createFilterOptions } from '@material-ui/lab';
import dayjs from 'dayjs';

export const getThousandSeparatorFormat = (number) => {
	//Todo replace nothing with  breaking in safari>>\B(?<!\.\d*)(?=(\d{3})+(?!\d))
	//https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
	return number?.toString().replace(/nothing/g, ',');
};

export const getRoundedNumberFormat = (number) => {
	if (number >= 1000000) {
		return (number / 1000000).toFixed(1) + 'M';
	}
	if (number >= 1000) {
		return (number / 1000).toFixed(1) + 'K';
	}
	return number;
};

export const getCurrencyFormat = (number, denomination, isRounded) => {
	if (isRounded) {
		switch (denomination) {
			case 'INR':
				return `₹ ${getRoundedNumberFormat(number)}`;
			case 'USD':
				return `$ ${getRoundedNumberFormat(number)}`;
			case 'SGD':
				return `S$ ${getRoundedNumberFormat(number)}`;
			case 'EUR':
				return `€ ${getRoundedNumberFormat(number)}`;
			case 'AUD':
				return `A$ ${getRoundedNumberFormat(number)}`;
			case 'CAD':
				return `C$ ${getRoundedNumberFormat(number)}`;
			case 'RSD':
				return `RSD ${getRoundedNumberFormat(number)}`;
			default:
				return `${getRoundedNumberFormat(number)}`;
		}
	} else {
		switch (denomination) {
			case 'INR':
				return `₹ ${getThousandSeparatorFormat(number)}`;
			case 'USD':
				return `$ ${getThousandSeparatorFormat(number)}`;
			case 'SGD':
				return `S$ ${getThousandSeparatorFormat(number)}`;
			case 'EUR':
				return `€ ${getThousandSeparatorFormat(number)}`;
			case 'AUD':
				return `A$ ${getRoundedNumberFormat(number)}`;
			case 'CAD':
				return `C$ ${getRoundedNumberFormat(number)}`;
			case 'RSD':
				return `RSD ${getRoundedNumberFormat(number)}`;
			default:
				return `${getThousandSeparatorFormat(number)}`;
		}
	}
};

export const validateLinkedIn =
	/^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?[a-zA-Z]+(\/[a-zA-Z]{2,})/;

export const validateWebsite =
	/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const validatePhoneNumber = /^[0-9*+]+$/;

// function to format an amount using commas
export const formatWithCommas = (value) => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// function to format to currency with currency symbol
export const getCurrencyFormatter = (number, denomination, isRounded) => {
	if (isRounded) {
		switch (denomination) {
			case 'USD':
				return `$ ${getRoundedNumberFormat(number)}`;
			case 'INR':
				return `₹ ${getRoundedNumberFormat(number)}`;
			default:
				return `${getRoundedNumberFormat(number)}`;
		}
	}
};
// function to format currency amount based on character length

export const formatAmount = (value, currency) => {
	let amount = formatWithCommas(String(value));
	if (amount?.length > 5) {
		return [getCurrencyFormatter(value, currency?.currency_type, true)].join(
			' '
		);
	}
	return [currency?.symbol, amount].join(' ');
};

export const trimInput = (value) => {
	return value?.trim();
};

export const exportData = (filename, data, format) => {
	let workSheet;
	let fileType;

	switch (format) {
		case 'excel':
			fileType =
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			const fileExtension = '.xlsx';
			workSheet = XLSX.utils.aoa_to_sheet(data);
			const workBook = {
				Sheets: { data: workSheet, cols: [] },
				SheetNames: ['data'],
			};
			const excelBuffer = XLSX.write(workBook, {
				bookType: 'xlsx',
				type: 'array',
			});
			const fileData = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(fileData, filename + fileExtension);
			break;

		case 'csv':
			fileType = '.csv';
			workSheet = XLSX.utils.aoa_to_sheet(data);
			const csvContent = XLSX.utils.sheet_to_csv(workSheet);
			const csvData = new Blob([csvContent], {
				type: 'text/csv;charset=utf-8;',
			});
			FileSaver.saveAs(csvData, filename + fileType);
			break;

		default:
			console.error('Unsupported format: ' + format);
	}
};

// Function to create the CSV file
// Input params : filename: name for the file, rows : data for the csv file
export function exportToCsv(filename, rows) {
	var processRow = function (row) {
		var finalVal = '';
		for (var j = 0; j < row.length; j++) {
			var innerValue = row[j] === null ? '' : row[j].toString();
			if (row[j] instanceof Date) {
				innerValue = row[j].toLocaleString();
			}
			var result = innerValue.replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
			if (j > 0) finalVal += ',';
			finalVal += result;
		}
		return finalVal + '\n';
	};

	var csvFile = '';
	for (var i = 0; i < rows.length; i++) {
		csvFile += processRow(rows[i]);
	}

	var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	} else {
		var link = document.createElement('a');
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}

// Reusable function to download binary file to excel

export const excelDownload = (data, filename) => {
	var blob = new Blob([data], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	if (navigator.msSaveBlob) {
		navigator.msSaveBlob(blob, filename);
	} else {
		var link = document.createElement('a');
		if (link.download !== undefined) {
			var url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

//check whether a number is float
export const isFloat = (number) => {
	return Number(number) === number && number % 1 !== 0;
};

export const formatDecimal = (value, trailingIndex) => {
	if (!value || !trailingIndex) {
		return '';
	}
	return parseFloat(Number(value).toFixed(trailingIndex));
};
// Function to get prefix label
export const getPrefix = (label, value) => {
	if (!label) {
		return '';
	}
	if (value && value === 'N/A') {
		return '';
	}
	return label;
};

export const preventSubsequentClick = (callingFn, time) =>
	debounce(callingFn, time);

export const autoCompleteFilterOption = createFilterOptions({
	matchFrom: 'any',
	limit: 7,
});

export const formatEmployeeId = (id, idLength, isFixedEmpIdLengthEnabled) => {
	const result =
		isFixedEmpIdLengthEnabled && id
			? `${id}`.padStart(idLength, '0')
			: id || '--';
	return result;
};

// return max bill rate for project wise report
export const getMaxBillRate = (projectAllocations) => {
	let maxBillRate = 0;
	projectAllocations.forEach((projectAllocation) => {
		const billRate = Number(projectAllocation?.client_rate);
		if (billRate > maxBillRate) {
			maxBillRate = billRate;
		}
	});
	return maxBillRate;
};

export const formatExperience = (totalExperience) => {
	const duration = dayjs.duration(totalExperience.days, 'days');
	let years = duration.years();
	let months = duration.months();
	const remainingDays = duration.days();
	if (months >= 12) {
		years += Math.floor(months / 12);
		months = months % 12;
	}
	if (years === 0 && months === 0) {
		return `${remainingDays} ${remainingDays === 1 ? 'day' : 'days'}`;
	}

	return `${years} ${years <= 1 ? 'year' : 'years'}, ${months} ${
		months <= 1 ? 'month' : 'months'
	} `;
};

export const calculateExperienceInDays = (startDate, endDate) => {
	const start = dayjs(startDate);
	const end = dayjs(endDate);
	const diffInDays = end.diff(start, 'day');

	// If start date and end date are the same, set duration to 1 day

	const duration =
		diffInDays === 0 || diffInDays <= 30 ? diffInDays + 1 : diffInDays;

	return { duration };
};

export const formatPhoneNumber = (mobileNumber) => {
	if(!mobileNumber){
		return ''
	}
	const phoneNumber = mobileNumber
					? String(mobileNumber)?.split('')
					: [];
				const formattedMobileNumber =
					phoneNumber[0] === '+'
						? `(+)${phoneNumber.filter((item) => item !== '+').join('')}`
						: `${phoneNumber.join('')}`;
				return formattedMobileNumber
}


export const validateNonEmptyTrimmedString = (value) => {
	if (!value) {
		return 'Required';
	}
	if (value.trim() === '') {
		return 'Required';
	}
	return undefined;
};