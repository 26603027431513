import React, { useEffect, useState } from 'react';
import {
	SelectInput,
	useQuery,
	useMutation,
	useNotify,
	TextInput,
} from 'react-admin';
import { useMutation as useApolloMutation } from '@apollo/client';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	ThemeProvider,
	Box,
	makeStyles,
	Button,
	Switch,
	Typography,
} from '@material-ui/core';
import { modalFormStyle } from '../../../Layout/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import TaskForm from './TaskForm.component';
import CheckListTypeForm from './CheckListTypeForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { UPDATE_CHECKLIST_ITEMS } from '../Settings.gql';

const customStyle = makeStyles({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	kanbanColumnCard: {
		width: '100%',
		padding: '10px',
		border: '1px solid #E0E0E0',
		marginBottom: '10px',
		borderRadius: '4px',
		boxShadow: '0px 3px 6px #0000000F',
		display: 'flex',
		alignItems: 'center',
		background: '#FFFFFF',
		justifyContent: 'space-between',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
	},
	dataContaner: {
		marginLeft: '12px',
	},
	dragIndicator: {
		width: '18px',
		height: '18px',
	},
	noData: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	editIcon: {
		marginTop: '4px',
		width: '15px',
		height: '15px',
		cursor: 'pointer',
		marginLeft: '5px',
	},
	label: {
		fontFamily: 'Manrope-medium',
	},
	iconContainer: {
		width: '33px',
		height: '36px',
		marginLeft: '5px',
		background: '#e0e0e0',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '4px',
		alignItems: 'center',
		cursor: 'pointer',
	},
	icon: {
		width: '22px',
		height: '18px',
	},
});

interface FormValue {
	master_module?: string;
	master_sub_module?: string;
	checklist_type?: string;
}
interface Task {
	checklist_type_id?: string;
	description?: string;
	id?: string;
	name?: string;
	updated_at?: string;
	created_at?: string;
	ordinal?: number;
}
interface Type {
	description?: string;
	id?: string;
	is_active?: boolean;
	is_strict_order?: boolean;
	label?: string;
	name?: string;
	sub_module_id?: string;
}
export const CheckListSettings = () => {
	const customCheckListStyle = customStyle();
	const modalFormStyles = modalFormStyle();
	const [moduleSelectOptions, setModuleSelectOptions] = useState([]);
	const [selectedModule, setSelectedModule] = useState<string>('');
	const [formValues, setFormValues] = useState<FormValue>();
	const [subModuleSelectOptions, setSubModuleSelectOptions] = useState([]);
	const [selectedSubModule, setSelectedSubModule] = useState<string>('');
	const [checkListTypeOptions, setCheckListTypeOptions] = useState([]);
	const [selectedCheckListType, setSelectedCheckListType] =
		useState<string>('');
	const [isTaskFormShown, setIsTaskFormShown] = useState(false);
	const [isTypeFormShown, setIsTypeFormShown] = useState(false);
	const [isActive, setIsActive] = useState(true);
	const [isTaskEdit, setIsTaskEdit] = useState(false);
	const [mutate] = useMutation();
	const notify = useNotify();
	const [selectedTask, setSelectedTask] = useState<Task>({});
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [selectedType, setSelectedType] = useState<Type>();
	const [isTypeNameEdit, setIsTypeNameEdit] = useState(false);
	const [checkListTypeName, setCheckListTypeName] = useState<string>('');
	const [columns, setColumns] = useState<Task[]>();
	const [isColumnReOrdered, setIsColumnReOrdered] = useState(false);
	const [updateChecklistItems] = useApolloMutation(UPDATE_CHECKLIST_ITEMS);

	const classes = modalFormStyle();
	const { data: masterModuleList } = useQuery({
		type: 'GET_LIST',
		resource: 'master_modules',
		payload: {
			sort: { field: 'label', order: 'ASC' },
		},
	});

	const { data: masterSubModuleList } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'master_sub_modules',
			payload: {
				filter:{master_module_id : selectedModule},
				sort: { field: 'label', order: 'ASC' },
			},
		},
		{ enabled: !!selectedModule }
	);

	const { data: checkListTypeList, refetch: refetchCheckListType } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'checklist_types',
			payload: {
				sort: { field: 'label', order: 'ASC' },
				filter:{sub_module_id : selectedSubModule}
			},
		},
		{ enabled: !!selectedSubModule }
	);

	const { data: checkListTaskList, refetch: refetchCheckTaskList } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'checklist_items',
			payload: {
				sort: { field: 'ordinal', order: 'ASC' },
				filter: {
					checklist_type_id: selectedCheckListType,
				},
			},
		},
		{ enabled: !!selectedCheckListType }
	);
	useEffect(() => {
		if (!masterModuleList) {
			return;
		}
		const moduleOptions = masterModuleList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.label,
			};
		});
		setModuleSelectOptions(moduleOptions);
	}, [masterModuleList]);

	useEffect(() => {
		if (!masterSubModuleList) {
			return;
		}
		const moduleOptions = masterSubModuleList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.label,
			};
		});
		setSubModuleSelectOptions(moduleOptions);
	}, [masterSubModuleList]);

	useEffect(() => {
		if (!checkListTypeList) {
			return;
		}
		const listOptions = checkListTypeList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.label,
			};
		});
		setCheckListTypeOptions(listOptions);
	}, [checkListTypeList]);

	useEffect(() => {
		if (!checkListTaskList) {
			return;
		}
		const columnsList = checkListTaskList?.map((task: Task) => ({
			id: task.id,
			name: task.name,
			ordinal: task.ordinal,
		}));
		if (columnsList) {
			setColumns(columnsList);
		} else {
			setColumns([]);
		}
	}, [checkListTaskList]);

	const reorder = (list: Task[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const reOrderedColumns =
			columns &&
			reorder(columns, result.source.index, result.destination.index);
		if (reOrderedColumns) {
			setColumns(reOrderedColumns);
			setIsColumnReOrdered(true);
		}
	};

	const onStatusChange = (status: boolean) => {
		mutate(
			{
				type: 'update',
				resource: 'checklist_types',
				payload: {
					id: selectedCheckListType,
					data: {
						is_active: status,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Status changed successfully');
				},
				onFailure: (error) => {
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};
	const onEditTask = (task: Task) => {
		setIsTaskFormShown(true);
		setIsTaskEdit(true);
		const { created_at, updated_at, ...rest } = task;
		setSelectedTask({ ...rest });
	};

	const onDeleteTask = () => {
		mutate(
			{
				type: 'delete',
				resource: 'checklist_items',
				payload: {
					id: selectedTask?.id,
				},
			},
			{
				onSuccess: () => {
					notify('Checklist task deleted successfully');
					refetchCheckTaskList();
					setIsDeleteModalShown(false);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
						return;
					}
					notify(error?.message, 'warning');
				},
			}
		);
	};
	const onCheckListTypeChange = (id: string) => {
		const selectedType = checkListTypeList?.find(
			(type: Type) => type?.id === id
		);
		setSelectedType(selectedType);
		setCheckListTypeName(selectedType?.label);
	};
	const onUpdateTypeName = () => {
		mutate(
			{
				type: 'update',
				resource: 'checklist_types',
				payload: {
					id: selectedType?.id,
					data: {
						label: checkListTypeName,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Checklist name updated successfully');
					refetchCheckTaskList();
					setIsTypeNameEdit(false);
					refetchCheckListType();
					setSelectedType(data);
				},
				onFailure: (error: any) => {
					notify(error?.message, 'warning');
				},
			}
		);
	};

	const onUpdateTask = () => {
		if (!columns) {
			return;
		}
		Promise.all(
			columns.map((column, index) =>
				updateChecklistItems({
					variables: {
						taskId: column?.id,
						data: {
							name: column?.name,
							ordinal: index + 1,
						},
					},
				})
			)
		)
			.then((response) => {
				if (response) {
					notify('Checklist tasks has been updated');
					setIsColumnReOrdered(false);
					refetchCheckTaskList();
				}
			})
			.catch((error) => {
				return;
			});
	};
	const getCheckListTypeStatus = (id: string) => {
		if (!id) {
			return;
		}
		if (checkListTypeList) {
			const selectedType = checkListTypeList?.find(
				(type: Type) => type?.id === id
			);
			if (selectedType) {
				setIsActive(selectedType?.is_active);
				refetchCheckListType();
			}
		}
	};
	return (
		<>
			<SettingsDetailContainer heading='Checklist'>
				<Box className={customCheckListStyle.actionContainer}>
					<Box width='fit-content'>
						<Form initialValues={formValues} onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Category</Box>
												<SelectInput
													source='master_module'
													label=''
													choices={moduleSelectOptions}
													onChange={(e) => {
														setSelectedModule(e.target.value);
														setFormValues({
															...formValues,
															master_module: e.target.value,
														});
													}}
												/>
											</Box>
											<Box className={classes.multipleInput} marginLeft='10px'>
												<Box className={classes.label}>Sub Category</Box>
												<SelectInput
													source='master_sub_module'
													label=''
													choices={subModuleSelectOptions}
													onChange={(e) => {
														setSelectedSubModule(e.target.value);
														setFormValues({
															...formValues,
															master_sub_module: e.target.value,
														});
													}}
												/>
											</Box>
											<Box className={classes.multipleInput} marginLeft='10px'>
												<Box className={classes.label}>Checklist Type</Box>
												<SelectInput
													source='checklist_type'
													disabled={!selectedSubModule}
													label=''
													choices={checkListTypeOptions}
													onChange={(event: any) => {
														if(!event){
															setColumns([])
															setSelectedCheckListType('')
															setSelectedType({})
															refetchCheckListType()
															return
														}
														if (event?.target?.value !== '@@ra-create') {
															setSelectedCheckListType(event?.target?.value);
															getCheckListTypeStatus(event?.target?.value)
															onCheckListTypeChange(event?.target?.value);
															setFormValues({
																...formValues,
																checklist_type: event?.target?.value,
															});
														}
													}}
													resettable={true}
													createLabel='-- Add new --'
													onCreate={() => setIsTypeFormShown(true)}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<Box>
					<Box
						display='flex'
						justifyContent={'space-between'}
						mt={2}
						marginBottom='10px'
					>
						<Box
							display={'flex'}
							marginBottom='10px'
							marginTop={'3px'}
							alignContent={'center'}
							justifyContent={'center'}
						>
							{selectedType?.label ? (
								<>
									{!isTypeNameEdit ? (
										<>
											<Typography className={customCheckListStyle.label}>
												{selectedType?.label}
											</Typography>
											<EditIcon
												onClick={() => setIsTypeNameEdit(true)}
												className={customCheckListStyle.editIcon}
											/>
										</>
									) : (
										<Form onSubmit={() => {}} initialValues={selectedType}>
											{() => (
												<ThemeProvider theme={modalFormTheme}>
													<form>
														<Box display={'flex'}>
															<Box>
																<TextInput
																	source='label'
																	label=''
																	onChange={(event) =>
																		setCheckListTypeName(event?.target?.value)
																	}
																/>
															</Box>
															<div
																className={customCheckListStyle.iconContainer}
																onClick={onUpdateTypeName}
															>
																<CheckIcon
																	className={customCheckListStyle.icon}
																/>
															</div>
															<div
																className={customCheckListStyle.iconContainer}
																onClick={() => setIsTypeNameEdit(false)}
															>
																<CloseIcon
																	className={customCheckListStyle.icon}
																/>
															</div>
														</Box>
													</form>
												</ThemeProvider>
											)}
										</Form>
									)}
								</>
							) : (
								''
							)}
						</Box>
						<Box display='flex'>
							{selectedCheckListType ? (
								<>
									<Box
										fontFamily={'ManRope-semiBold'}
										fontSize={'11px'}
										marginTop={'10px'}
									>
										{isActive ? 'Active' : 'Inactive'}
									</Box>
									<Switch
										color='primary'
										name='is_rehire_eligible'
										checked={isActive}
										onChange={(event: any) => {
											setIsActive(event?.target?.checked);
											onStatusChange(event?.target?.checked);
										}}
									/>
									<Button
										variant='contained'
										className={modalFormStyles.saveButton}
										onClick={() => {
											if (isColumnReOrdered) {
												notify('Please save the tasks order', 'warning');
												return;
											}
											setIsTaskFormShown(true);
										}}
									>
										Add Task
									</Button>
								</>
							) : (
								''
							)}
						</Box>
					</Box>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId='list'>
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{columns &&
										columns.length > 0 &&
										columns.map((task: any, index: number) => (
											<Draggable
												draggableId={task.id}
												index={index}
												key={task.id}
											>
												{(provided) => (
													<div
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														ref={provided.innerRef}
													>
														<div
															className={customCheckListStyle.kanbanColumnCard}
															key={task.id}
														>
															<div className={customCheckListStyle.content}>
																<DragIndicatorIcon
																	className={customCheckListStyle.dragIndicator}
																/>
																<div
																	className={customCheckListStyle.dataContaner}
																>
																	{task.name}
																</div>
															</div>
															<PopOver
																edit={() => {
																	if (isColumnReOrdered) {
																		notify(
																			'Please save the tasks order',
																			'warning'
																		);
																		return;
																	}
																	onEditTask(task);
																}}
																remove={() => {
																	if (isColumnReOrdered) {
																		notify(
																			'Please save the tasks order',
																			'warning'
																		);
																		return;
																	}
																	setSelectedTask(task);
																	setIsDeleteModalShown(true);
																}}
															/>
														</div>
													</div>
												)}
											</Draggable>
										))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					{checkListTaskList &&
					!checkListTaskList.length &&
					selectedCheckListType ? (
						<Typography className={customCheckListStyle.noData}>
							No task Found
						</Typography>
					) : (
						''
					)}
				</Box>
				{selectedCheckListType ? (
					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button
							disabled={!isColumnReOrdered}
							className={
								isColumnReOrdered
									? modalFormStyles.saveButton
									: modalFormStyles.disabledButton
							}
							variant='contained'
							onClick={onUpdateTask}
						>
							Save
						</Button>
					</Box>
				) : (
					''
				)}

				<TaskForm
					open={isTaskFormShown}
					onClose={() => {
						setIsTaskFormShown(false);
						setIsTaskEdit(false);
					}}
					refetch={() => refetchCheckTaskList()}
					isEditMutation={isTaskEdit}
					checkListTypeId={selectedCheckListType}
					formValue={selectedTask}
				/>
				<CheckListTypeForm
					onClose={() => setIsTypeFormShown(false)}
					open={isTypeFormShown}
					refetch={() => refetchCheckListType()}
					isEditMutation={false}
					subCategoryId={selectedSubModule}
					createdType={(id) =>
						setFormValues({
							...formValues,
							checklist_type: id,
						})
					}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
					}}
					onDelete={onDeleteTask}
				/>
			</SettingsDetailContainer>
		</>
	);
};

export default CheckListSettings;
