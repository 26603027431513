import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import KanbanWarningModal from '../../Kanban/kanbanWarning.component';
import {
	EDIT_PROJECT_CATEGORY_MESSAGE,
	ADD_PROJECT_CATEGORY_MESSAGE,
	PROJECT_CATEGORY_NAME_UNIQUE_ERROR,
	PROJECT_TYPE,
	UPDATE_CATEGORY_SUCCESS_MESSAGE,
	INSERT_CATEGORY_SUCCESS_MESSAGE,
	PROJECT_CATEGORY_UNIQUE_KEY,
} from './../constant';
import { useUpsertProjectCategoryMutation } from '../../../../generated/graphql';
import { validateName } from '../../constant';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
export interface projectCategory {
	id?: string;
	name?: string;
	type?: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	projectCategory: projectCategory;
}

const ProjectCategoryForm = (props: Props) => {
	const { open, onClose, projectCategory } = props;
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectCategoryUniqueError, setProjectCategoryUniqueError] =
		useState('');
	const classes = modalFormStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const [upsertProjectCategory, { loading: isProjectCategoryLoading }] =
		useUpsertProjectCategoryMutation();

	const onSave = (formValue: projectCategory) => {
		if (!formValue) {
			return;
		}
		setProjectCategoryUniqueError('');
		const { name } = formValue;
		upsertProjectCategory({
			variables: {
				projectCategory: {
					...formValue,
					name: name?.trim(),
				},
			},
		})
			.then((data) => {
				onClose();
				notify(
					formValue?.id
						? UPDATE_CATEGORY_SUCCESS_MESSAGE
						: INSERT_CATEGORY_SUCCESS_MESSAGE
				);
				setProjectCategoryUniqueError('');
				userefresh();
				handleClose();
			})
			.catch((error) => {
				if (error.message.includes(PROJECT_CATEGORY_UNIQUE_KEY)) {
					setProjectCategoryUniqueError(PROJECT_CATEGORY_NAME_UNIQUE_ERROR);
					return;
				}
			});
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
	};

	const handleClose = () => {
		setProjectCategoryUniqueError('');
		onClose();
	};

	const validate = [
		required(),
		validateName,
		maxLength(100, 'Maximum 100 character allowed'),
	];

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{projectCategory?.id
									? EDIT_PROJECT_CATEGORY_MESSAGE
									: ADD_PROJECT_CATEGORY_MESSAGE}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={projectCategory}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Name *</Typography>
										<TextInput
											source='name'
											label=''
											validate={validate}
											onChange={(event: any) => {
												form.mutators.setTouched();
												setProjectCategoryUniqueError('');
											}}
										/>
										{projectCategoryUniqueError && (
											<Box color='red' fontSize={'12px'} marginTop={'-6px'}>
												{projectCategoryUniqueError}
											</Box>
										)}
										<Typography className={classes.label}>Type*</Typography>
										<SelectInput
											source='type'
											label={''}
											choices={PROJECT_TYPE}
											validate={required()}
											onChange={() => {
												setProjectCategoryUniqueError('');
												form.mutators.setTouched();
											}}
										/>
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											title='Save'
											isDisabled={
												invalid ||
												pristine ||
												submitSucceeded ||
												isProjectCategoryLoading
											}
											isLoading={isProjectCategoryLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={''}
				/>
			}
		</>
	);
};

export default ProjectCategoryForm;
