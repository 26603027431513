import React, { useState, useEffect } from 'react';

import {
	GET_JOB_LEVELS,
	GET_DEPARTMENTS,
	GET_DESIGNATIONS,
} from './gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import { Box } from '@material-ui/core';

interface Job {
	id: string;
	level: string;
}
interface Department {
	id: string;
	name: string;
}
interface Status {
	id: string;
	label: string;
}
interface Designation {
	id: string;
	title: string;
}
export const ConsultantFilterFormBody = () => {
	const [departmentChoices, setDepartmentChoices] = useState(null);
	const [searchedDepartment, setSearchedDepartment] = useState('');
	const [departmentLimit, setDepartmentLimit] = useState(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const [designation, setDesignation] = useState(null);
	const [searchedDesignation, setSearchedDesignation] = useState('');
	const [designationLimit, setDesignationLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const { data: designationList } = useApolloQuery(GET_DESIGNATIONS, {
		variables: {
			limit: designationLimit,
			title: `%${searchedDesignation || ''}%`,
		},
	});

	const [jobLevelChoices, setJobLevelChoices] = useState(null);
	const [searchedJobLevel, setSearchedJobLevel] = useState('');
	const [jobLevelLimit, setJobLevelLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

	const { data: jobLevelList } = useApolloQuery(GET_JOB_LEVELS, {
		variables: {
			limit: jobLevelLimit,
			title: searchedJobLevel,
		},
	});

	const { data: departmentList } = useApolloQuery(GET_DEPARTMENTS, {
		variables: {
			limit: departmentLimit,
			department: `%${searchedDepartment || ''}%`,
		},
	});

	useEffect(() => {
		if (departmentList?.departments) {
			const departmentValue = departmentList.departments.map(
				(dept: Department) => {
					return {
						id: dept.id,
						name: dept.name,
					};
				}
			);
			setDepartmentChoices(departmentValue);
		}
	}, [departmentList]);

	useEffect(() => {
		if (designationList?.designation) {
			const designationValueList = designationList?.designation?.map(
				(designationValue: Designation) => {
					return {
						id: designationValue.id,
						name: designationValue.title,
					};
				}
			);
			setDesignation(designationValueList);
		}
	}, [designationList]);

	useEffect(() => {
		if (jobLevelList?.job_level) {
			const jobValue = jobLevelList.job_level.map((job: Job) => {
				return {
					id: job.id,
					name: job.level,
				};
			});
			setJobLevelChoices(jobValue);
		}
	}, [jobLevelList]);

	const onDepartmentExpantionToggle = (isSeeMoreLabelShown: boolean) => {
		setDepartmentLimit(
			isSeeMoreLabelShown
				? MAX_FILTER_OPTIONS_SHOWN
				: departmentList?.department_aggregate?.aggregate?.count
		);
	};
	const onJobLevelExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setJobLevelLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : 0);
	};
	const onDesignationExpansionToggle = (isSeeMoreLabelShown: boolean) => {
		setDesignationLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onDepartmentSearch = (name: string) => {
		setSearchedDepartment(name);
	};

	const onDesignationSearch = (name: string) => {
		setSearchedDesignation(name);
	};
	const onJobLevelSearch = (name: string) => {
		setSearchedJobLevel(name);
	};

	return (
		<Box>
			<Box>
				<FilterInput
					label={'Designation'}
					sourceName='designation_id'
					onExpansionToggle={onDesignationExpansionToggle}
					choices={designation || []}
					onSearch={(name) => onDesignationSearch(name)}
					total={designationList?.designation_aggregate?.aggregate?.count || 0}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Job Level'}
					sourceName='job_level_id'
					onExpansionToggle={onJobLevelExpansionToggle}
					choices={jobLevelChoices || []}
					onSearch={(name) => onJobLevelSearch(name)}
					total={jobLevelList?.job_level_aggregate?.aggregate?.count || 0}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Departments'}
					sourceName='department_id'
					onExpansionToggle={onDepartmentExpantionToggle}
					choices={departmentChoices || []}
					onSearch={(name) => onDepartmentSearch(name)}
					total={departmentList?.department_aggregate?.aggregate?.count || 0}
				/>
			</Box>
		</Box>
	);
};

export default ConsultantFilterFormBody;
