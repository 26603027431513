import React, { useState } from 'react';
import { Button, useNotify } from 'react-admin';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DropzoneArea } from 'material-ui-dropzone';
import { uploadData } from '../../../Api/restApi.config';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dataUploadStyles } from './dataUpload.style';
import { USER_DATA_UPLOAD_TEMPLATE_URL } from '../constant';
import { preventSubsequentClick } from '../../../Utils/string.util';

interface DataUploadApiResponse {
	isCompleted: boolean;
	isError: boolean;
	response: string[];
}

function UserDataUpload() {
	const userDataUploadStyle = dataUploadStyles();
	const [uploadResponse, setUploadResponse] = useState<DataUploadApiResponse>({
		isCompleted: false,
		isError: false,
		response: [],
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingState, setLoadingState] = useState<boolean>(false);
	const notify = useNotify();

	const handleFileUploadAlert = (message: string, variant: string) => {
		if (!message || !variant) {
			return;
		}
		if (variant === 'error') {
			setUploadResponse({
				isCompleted: true,
				isError: true,
				response: [message],
			});
		}
	};
	const disableButton = () => {
		setLoadingState(false);
	};

	const preventClick = preventSubsequentClick(disableButton, 1000);

	const handleTemplateDownload = () => {
		setLoadingState(true);
		let link = document.createElement('a');
		link.download = 'user_data_upload_template';
		link.href = USER_DATA_UPLOAD_TEMPLATE_URL;
		preventClick();
		link.click();
	};

	const handleDataUpload = async (file: any) => {
		if (!file) {
			return;
		}
		setIsLoading(true);
		let data = new FormData();
		data.append('type', 'user');
		data.append('file', file);

		uploadData(data)
			.then((res) => {
				if (res.data.error) {
					setUploadResponse({
						isError: true,
						isCompleted: true,
						response: res.data.message || [''],
					});
					setIsLoading(false);
					return;
				}
				setUploadResponse({
					isError: false,
					isCompleted: true,
					response: res.data.message || [''],
				});
				setIsLoading(false);
				notify('Data Upload Success');
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	};

	return (
		<>
			{/* PROGRESS LOADER */}
			<Backdrop className={userDataUploadStyle.backdrop} open={isLoading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			{/* User Data Upload Component */}
			<SettingsDetailContainer heading='User Data Upload'>
				<div className={userDataUploadStyle.fileTemplateDownloadContainer}>
					<div className={userDataUploadStyle.description}>
						Use this file template to upload bulk data
					</div>
					<div className={userDataUploadStyle.fileDownloadButtonContainer}>
						<Button
							className={userDataUploadStyle.fileDownloadButton}
							label='Download User Template'
							endIcon={<GetAppIcon />}
							variant='contained'
							onClick={handleTemplateDownload}
							disabled={loadingState}
							startIcon={
								loadingState && (
									<CircularProgress
										style={{
											height: '20px',
											width: '20px',
											color: '#FFFFFF',
										}}
									/>
								)
							}
						/>
					</div>

					<p className={userDataUploadStyle.heading}>Data Upload</p>

					<div className={userDataUploadStyle.fileUploadContainer}>
						<DropzoneArea
							acceptedFiles={[
								'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							]}
							initialFiles={[]}
							maxFileSize={5000000}
							showPreviewsInDropzone={false}
							filesLimit={1}
							dropzoneText={'Drag and drop, or browse your files'}
							onChange={(files) => {
								setUploadResponse({
									isCompleted: false,
									isError: false,
									response: [''],
								});
								handleDataUpload(files[0]);
							}}
							classes={{
								text: userDataUploadStyle.dropZoneAreaText,
								icon: userDataUploadStyle.uploadIcon,
							}}
							showAlerts={false}
							onAlert={handleFileUploadAlert}
						/>
					</div>

					{uploadResponse.isCompleted && (
						<div className={userDataUploadStyle.logContainer}>
							{uploadResponse?.response?.length > 0 &&
								uploadResponse?.response?.map((dataUploadResponse, index) => (
									<p
										className={
											uploadResponse.isError
												? userDataUploadStyle.errorText
												: userDataUploadStyle.successText
										}
										key={index}
									>
										{dataUploadResponse}
									</p>
								))}
						</div>
					)}
				</div>
			</SettingsDetailContainer>
		</>
	);
}

export default UserDataUpload;
