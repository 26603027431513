import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { insightViewStyle } from '../../Layout/styles';
import { ContractorCount } from '../../Reports/talent/ContractorCount.component';
import { EmployeeCount } from '../../Reports/talent/EmployeeCount.component';

import {
	EmployeeBillableRatio,
	EmployeeByDepartment,
	EmployeeServingNoticePeriod,
} from '../../Reports';
import { PipelineSalesRevenue } from '../../Reports/talent/Pipeline/PipelineSalesRevenue.component';
import { EmployeeAddtionReport } from '../../Reports/talent/EmployeeAdditionReport.component';
import { EmployeeResignationReport } from '../../Reports/talent/EmployeeResignationReport.component';
import { OpportunityRecentWins } from '../../Reports/talent/OpportunityRecentWins.component';
import { ActiveProjectCount } from '../../Reports/talent/ActiveProjectCount.component';
import { PipelineSaleForecastReport } from '../../Reports/talent/Pipeline/PipelineSaleForecast.component';
import { UserProfileContext } from '../../App';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';
import dayjs from 'dayjs';
import _, { head } from 'lodash';
import { getCurrencyFormat } from '../../Utils/string.util';
import {
	useGetDashboardChartDataQuery,
	useGetDashboardCountQuery,
	useGetOrgFinancialQuery,
} from '../../generated/graphql';

const CountCard = ({ label, value }: { label: string; value: ReactNode }) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.countContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
export const DashboardInsightReport = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const [startDate, setStartDate] = useState<any>('');
	const [endDate, setEndDate] = useState<any>('');
	const [employeeAdditionData, setEmployeeAdditionData] = useState<any>([]);
	const [employeeAttitionData, setEmployeeAttitionData] = useState<any>([]);
	const [recentWinsList, setRecentWinsList] = useState<any>([]);
	const last30Day = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
	const { data: organisation } = useGetOrgFinancialQuery({
		variables: { organisationId },
	});

	const { data: insightCount, loading: isCountLoading } =
		useGetDashboardCountQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
			},
			skip: !startDate && !endDate,
		});
	const { data: insightChartData, loading: isChartLoading } =
		useGetDashboardChartDataQuery({
			variables: {
				startDate: dayjs().format('YYYY-MM-DD'),
				endDate: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
				today: dayjs().format('YYYY-MM-DD'),
				last30Day: last30Day,
			},
		});
	useEffect(() => {
		if (!organisation) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				organisation?.organization[0]?.financial_year_start_month || '',
			financial_year_start_date:
				organisation?.organization[0]?.financial_year_start_date || '',
			financial_year_end_date:
				organisation?.organization[0]?.financial_year_end_date || '',
			financial_year_end_month:
				organisation?.organization[0]?.financial_year_end_month || '',
		});
		setStartDate(calculatedDate?.startDate);
		setEndDate(calculatedDate?.endDate);
	}, [organisation]);

	useEffect(() => {
		if (!insightChartData) {
			return;
		}
		const employeeAdditionList = _.chain(
			insightChartData?.employeeAddition
				?.filter((employee: any) => employee?.join_date !== null)
				.map((item: any) => {
					return {
						joinDate: item?.join_date,
						month: dayjs(item?.join_date).format('MMM'),
						id: item?.id,
						monthId: dayjs(item?.join_date).get('month'),
						year: dayjs(item?.join_date).get('year'),
					};
				})
		)
			.groupBy('month')
			.map((groupedObj, name) => {
				return _.reduce(
					groupedObj,
					(mappedObj, value) => {
						return {
							...mappedObj,
							name,
							'Employee Count': groupedObj?.length,
							monthId: value?.monthId,
							year: value?.year,
						};
					},
					{}
				);
			})
			.sort((a: any, b: any) => {
				if (a?.monthId < b?.monthId && a?.year < b?.year) {
					return 1;
				} else if (a?.monthId < b?.monthId && a?.year > b?.year) {
					return 1;
				} else if (a?.monthId > b?.monthId && a?.year < b?.year) {
					return -1;
				} else if (a?.monthId > b?.monthId && a?.year > b?.year) {
					return -1;
				}
				return a.monthId - b.monthId;
			})
			.value();
		setEmployeeAdditionData(employeeAdditionList);
		const employeeAttitionList = _.chain(
			insightChartData?.employeeAttrition
				?.filter((employee: any) => employee?.last_date !== null)
				.map((item: any) => {
					return {
						lastDate: item?.last_date,
						month: dayjs(item?.last_date).format('MMM'),
						id: item?.id,
						monthId: dayjs(item?.last_date).get('month'),
						year: dayjs(item?.last_date).get('year'),
					};
				})
		)
			.groupBy('month')
			.map((groupedObj, name) => {
				return _.reduce(
					groupedObj,
					(mappedObj, value) => {
						return {
							...mappedObj,
							name,
							'Employee Count': groupedObj?.length,
							monthId: value?.monthId,
							year: value?.year,
						};
					},
					{}
				);
			})
			.sort((a: any, b: any) => {
				if (a?.monthId < b?.monthId && a?.year < b?.year) {
					return 1;
				} else if (a?.monthId < b?.monthId && a?.year > b?.year) {
					return 1;
				} else if (a?.monthId > b?.monthId && a?.year < b?.year) {
					return -1;
				} else if (a?.monthId > b?.monthId && a?.year > b?.year) {
					return -1;
				}
				return a.monthId - b.monthId;
			})
			.value();
		setEmployeeAttitionData(employeeAttitionList);
		const recentWinsData = insightChartData?.recentWins?.map(
			(opportunity: any, index: number) => {
				return {
					key: index,
					name: opportunity.name,
					date: dayjs(opportunity.closeDate).format('YYYY-MMM-DD'),
					value: getCurrencyFormat(
						opportunity.value,
						head(organisation?.organization)?.currency,
						true
					),
				};
			}
		);
		setRecentWinsList(recentWinsData);
	}, [insightChartData, organisation]);
	const classes = insightViewStyle();
	return (
		<>
			<Box className={classes.headCountContainer}>
				<CountCard
					label='Total Employees'
					value={
						<EmployeeCount
							count={insightCount?.totalEmployee?.aggregate?.count || '--'}
							loading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Total Contractors'
					value={
						<ContractorCount
							count={insightCount?.totalContractor?.aggregate?.count || '--'}
							isLoading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Employees serving notice'
					value={
						<EmployeeServingNoticePeriod
							count={
								insightCount?.employeeServingNotice?.aggregate?.count || '--'
							}
							loading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Active Projects'
					value={
						<ActiveProjectCount
							count={insightCount?.activeProjects?.aggregate?.count || '--'}
							loading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Total Sales'
					value={
						<PipelineSalesRevenue
							count={insightCount?.totalSales?.aggregate?.sum?.value || '--'}
							currencyType={
								head(organisation?.organization)?.currency?.currency_type || '-'
							}
						/>
					}
				/>
			</Box>
			<Box className={classes.container}>
				<Box className={classes.chartContainer}>
					<EmployeeBillableRatio
						loading={isChartLoading}
						billableCount={
							insightChartData?.billableEmployees?.aggregate?.count
						}
						nonBillableCount={
							insightChartData?.nonBillableEmployees?.aggregate?.count
						}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeByDepartment
						departmentDetails={insightChartData?.employeeByDepartment}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeAddtionReport
						chartData={employeeAdditionData}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeResignationReport
						chartData={employeeAttitionData}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityRecentWins recentWinsList={recentWinsList} />
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineSaleForecastReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
			</Box>
		</>
	);
};

export default DashboardInsightReport;
