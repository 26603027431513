import React, { useContext, useEffect, useState } from 'react';
import { ellipsisStyle } from '../../../Layout/styles';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import {
	Box,
	createTheme,
	makeStyles,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@material-ui/core';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { head, isEmpty } from 'lodash';
import { UserProfileContext } from '../../../App';
import { useNotify, useRefresh } from 'ra-core';
import {
	useGetEmailNotificationListQuery,
	useUpdateOrganisationEmailEnabledMutation,
	useUpdateEmailNotiificationMutation,
} from '../../../generated/graphql';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import EmailNotificationWarningModal from './EmailNotificationWarningModal.component';
import { tableListStyle } from '../settingsStyle';
import RecipientListModal from './RecieversListModal.component';

const editableColumnsStyle = makeStyles({
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
	},
	disableDiv: {
		opacity: 0.7,
		pointerEvents: 'none',
	},
	contentContainer: {
		background: '#FFFFFF',
		marginLeft: '16px',
		borderRadius: '4px',
		width: '100%',
		marginBottom: '10px',
		padding: '14px 24px 14px 14px',
		boxShadow: '0px 3px 6px #0000000F',
	},
	inputContainer: {
		display: 'flex',
		overflow: 'auto',
	},
	emailName: {
		color: '#292929',
		width: '200px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	emailType: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},

	emailPolicyContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginBottom: '10px',
		gap: '150px',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
			'& > div': {
				'& > div': {
					'& > button': {
						display: 'block',
					},
				},
			},
		},
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});
export interface RecipientRole {
	id?: string | undefined;
	name?: string[] | undefined;
}

export interface RecipientData {
	role: RecipientRole[];
}

const EmailNotification = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const [isOrgEnable, setIsOrgEnable] = useState(false);
	const [isEmailEnable, setIsEmailEnable] = useState(false);
	const [isAddNewRoleModalShown, setIsAddNewRoleModalShown] = useState(false);
	const [isOrganizationEnable, setIsOrganizationEnable] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [emailNotification, setEmailNotiification] = useState({
		id: null,
		is_enabled: false,
	});

	const [currentNotificationId, setCurrentNotificationId] = useState<
		string | null | undefined
	>(null);
	const [currentNotificationReceivers, setCurrentNotificationReceivers] =
		useState<string[]>();
	const editableColumnStyles = editableColumnsStyle();
	const classes = tableListStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: emailList, refetch } = useGetEmailNotificationListQuery({
		variables: {
			orgId: orgId,
		},
	});

	const [updateOrganizationEmailEnabled] =
		useUpdateOrganisationEmailEnabledMutation();

	const [updateEmailNotiification] = useUpdateEmailNotiificationMutation();

	useEffect(() => {
		if (isEmpty(emailList?.organization)) {
			setIsOrgEnable(false);
			return;
		}
		const isOrgEnable: boolean =
			head(emailList?.organization)?.is_email_enabled || false;
		setIsOrgEnable(isOrgEnable);
	}, [emailList]);

	const handleEmailEnabled = async () => {
		try {
			const response = await updateOrganizationEmailEnabled({
				variables: {
					organisationId: orgId,
					data: {
						is_email_enabled: isOrganizationEnable,
					},
				},
			});
			if (response?.data?.update_organization_by_pk?.id) {
				refresh();
				notify(
					`Organization Email Notification ${
						response?.data?.update_organization_by_pk?.is_email_enabled
							? 'enabled'
							: 'disabled'
					} successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};

	const handleEmailNotiification = async () => {
		try {
			const response = await updateEmailNotiification({
				variables: {
					id: emailNotification?.id,
					data: {
						is_enabled: emailNotification?.is_enabled,
					},
				},
			});
			if (response?.data?.update_email_notification_by_pk?.id) {
				refresh();
				notify(
					`${response?.data?.update_email_notification_by_pk?.name} 
					Email Notification ${
						response?.data?.update_email_notification_by_pk?.is_enabled
							? 'Enabled'
							: 'Disabled'
					} Successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};

	return (
		<Box className={editableColumnStyles.contentContainer}>
			<Typography className={editableColumnStyles.description}>
				Turn off to deactivate email for entire organization
			</Typography>
			<ThemeProvider theme={editableColumnsTheme}>
				<div>
					<Box
						className={editableColumnStyles.inputContainer}
						display='flex'
						flexDirection='column'
					>
						<>
							<div style={{ display: 'flex' }}>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												name='is_email_enabled'
												color='primary'
												checked={isOrgEnable}
												onChange={(event: any) => {
													setIsWarningModalShown(true);
													setIsOrganizationEnable(event?.target.checked);
												}}
											/>
										}
										label='Enable Organisation Email Notification'
									/>
								</FormGroup>
							</div>
							<Box
								marginTop={'35px'}
								className={
									!head(emailList?.organization)?.is_email_enabled
										? editableColumnStyles.disableDiv
										: ''
								}
							>
								{emailList && emailList?.email_notification?.length > 0
									? emailList.email_notification.map((value) => (
											<Box
												className={editableColumnStyles.emailPolicyContainer}
												key={value.id}
											>
												<Box display='flex' alignItems='center'>
													<Box display='flex' mr={1}>
														<FormGroup>
															<FormControlLabel
																control={
																	<Switch
																		name='is_email_enabled'
																		color='primary'
																		disabled={
																			!head(emailList?.organization)
																				?.is_email_enabled
																		}
																		checked={
																			head(emailList?.organization)
																				?.is_email_enabled && value?.is_enabled
																				? true
																				: false
																		}
																		onChange={(event: any) => {
																			setIsWarningModalShown(true);
																			setIsEmailEnable(true);
																			setEmailNotiification({
																				id: value?.id,
																				is_enabled: event.target.checked,
																			});
																		}}
																	/>
																}
																label={''}
															/>
														</FormGroup>
													</Box>
													<Box>
														<Typography className={classes.label}>
															Name
														</Typography>
														<Tooltip title={`${value.name}`} placement='right'>
															<Typography
																className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
															>
																{value?.name}
															</Typography>
														</Tooltip>
													</Box>
												</Box>
												<Box>
													<Typography className={classes.label}>
														Description
													</Typography>
													<Tooltip
														title={`${value.description || '--'}`}
														placement='right'
													>
														<Typography
															className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
														>
															{value?.description || '--'}
														</Typography>
													</Tooltip>
												</Box>
												<Box>
													<div
														className={classes.employeesContainer}
														onClick={() => {
															if (!value?.is_recipient_editable) {
																notify(
																	'Email recipients cannot be edited !',
																	'warning'
																);
																return;
															}
															setIsAddNewRoleModalShown(true);
															setCurrentNotificationId(value?.id);
															setCurrentNotificationReceivers(value?.receivers);
														}}
													>
														<PeopleOutlineIcon
															className={classes.peoplesIcon}
														/>
														<Typography
															className={`${classes.rowContent} ${classes.employeesCount}`}
														>
															{value?.receivers?.length}
														</Typography>
													</div>
												</Box>
												<Box>
													<Typography className={classes.label}></Typography>
													<Tooltip
														title={`${value.email_type?.label}`}
														placement='left-start'
													>
														<Typography
															className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
														>
															{value?.email_type?.value ===
															'scheduledTrigger' ? (
																<AccessAlarmIcon
																	fontSize='small'
																	className={editableColumnStyles.emailType}
																/>
															) : (
																<OfflineBoltOutlinedIcon
																	fontSize='small'
																	className={editableColumnStyles.emailType}
																/>
															)}
														</Typography>
													</Tooltip>
												</Box>
											</Box>
									  ))
									: 'No Email Notification'}
							</Box>
						</>
					</Box>
				</div>
			</ThemeProvider>

			<RecipientListModal
				open={isAddNewRoleModalShown}
				onClose={() => {
					setIsAddNewRoleModalShown(false);
				}}
				onSuccess={refetch}
				emailNotificationId={currentNotificationId}
				emailNotificationReceiver={currentNotificationReceivers}
				name={'Recipient List'}
			/>
			<EmailNotificationWarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setIsEmailEnable(false);
				}}
				confirmationMessage={'Are you sure you want to proceed?'}
				onSave={isEmailEnable ? handleEmailNotiification : handleEmailEnabled}
			/>
		</Box>
	);
};

export default EmailNotification;
