import React from 'react'
import { Avatar } from '@material-ui/core';

export const AvatarField = ({
    imageSrc,
    firstName,
    lastName, 
    size='25',
    className
}) => {
    return (
        <div>
            <Avatar
            src={`${imageSrc || ""}`}
            style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
            className={className}
            >
            {firstName?.charAt(0) || ""}
            {lastName?.charAt(0) || ""}
            </Avatar>            
        </div>
    )
}

export default AvatarField
