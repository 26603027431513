import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '450px',
        width:'80%',
        flexWrap: 'wrap',
    },
    noData: {
        font: 'normal normal medium 12px/15px Manrope !important',
        letterSpacing: '0px',
        fontSize: '12px !important',
    }
}))

const DetailContainer = ({
    children
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            {children}
        </Box>
    )
}

export default DetailContainer
