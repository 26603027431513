import React, { useState } from 'react';
import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useNotify,
} from 'react-admin';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	IconButton,
	TextField as TextComponent,
} from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { settingsStyle } from '../settingsStyle';
import {
	ellipsisStyle,
	modalFormStyle,
	datagridStyle,
} from '../../../Layout/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ProjectStatusForm from './ProjectStatusForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import {
	useDeleteProjectStatusMutation,
	useProjectStatusCountQuery,
} from '../../../generated/graphql';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import {
	PROJECT_STATUS_DELETE_SUCCESS_MESSAGE,
	DELETE_WARNING_MESSAGE,
	WARNING_MODAL_MESSAGE,
} from './constant';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';

export const inlineStyle = makeStyles({
	name: {
		color: '#292929',
		width: '250px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
});

const ProjectStatus = () => {
	const [isProjectStatusFormShown, setIsProjectStatusFormShown] =
		useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectStatusData, setProjectStatusData] = useState({});
	const [projectStatusDeleteId, setProjectStatusDeleteId] = useState('');
	const [searchStatus, setSearchStatus] = useState('');
	const settingsStyles = settingsStyle();
	const modalFormStyles = modalFormStyle();
	const tooltipStyle = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const classes = inlineStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const [deleteProjectStatus] = useDeleteProjectStatusMutation();
	const { data: projectStatusCount } = useProjectStatusCountQuery({});

	const handleDelete = () => {
		deleteProjectStatus({
			variables: {
				projectStatusId: projectStatusDeleteId,
			},
		}).then((data) => {
			notify(PROJECT_STATUS_DELETE_SUCCESS_MESSAGE);
			userefresh();
			setIsProjectStatusFormShown(false);
			setIsDeleteModalShown(false);
		});
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Project Status'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchStatus(e?.target?.value);
								}}
								value={searchStatus}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsProjectStatusFormShown(true);
					}}
				>
					Add Project Status
				</Button>
			</Box>

			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='project_status'
					basePath='/settings'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'label', order: 'ASC' }}
					filter={{ label: searchStatus }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(project: any) => {
								return (
									<Tooltip title={`${project.label}`} placement='right'>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.label}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='STAGE'
							render={(project: any) => {
								return (
									<Tooltip title={`${project.label}`} placement='right'>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.value}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(project: any) => {
								return (
									<Tooltip
										title={`${project.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label=''
							render={(record: any) => {
								return (
									<Box display='flex' alignItems='center'>
										<Box
											className={datagridStyles.listEditIconContainer}
											mr={1}
										>
											<IconButton
												className={datagridStyles.listEditIconButton}
												onClick={() => {
													setProjectStatusData(record);
													setIsProjectStatusFormShown(true);
												}}
											>
												<EditIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
										<Box className={datagridStyles.listEditIconContainer}>
											<IconButton
												className={`${datagridStyles.listEditIconButton} ${datagridStyles.deleteIconButton}`}
												onClick={() => {
													const response =
														projectStatusCount?.project_status?.find(
															(value) => value?.id === record?.id
														);
													if (
														(response?.projects_aggregate?.aggregate?.count ||
															0) > 0
													) {
														setIsWarningModalShown(true);
														return;
													}
													setProjectStatusDeleteId(record?.id);
													setIsDeleteModalShown(true);
												}}
											>
												<DeleteIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
									</Box>
								);
							}}
						/>
					</Datagrid>
				</List>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
					}}
					confirmationMessage={DELETE_WARNING_MESSAGE}
					onDelete={() => {
						handleDelete();
					}}
				/>
				<ProjectStatusForm
					open={isProjectStatusFormShown}
					onClose={() => {
						setIsProjectStatusFormShown(false);
						setProjectStatusData({});
					}}
					projectStatus={projectStatusData}
				/>
				<WarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
					}}
					warningMessage={WARNING_MODAL_MESSAGE}
				/>
			</Box>
		</>
	);
};

export default ProjectStatus;
