import {
	Box,
	ThemeProvider,
	Typography,
	TextField as TextComponent,
	CircularProgress,
	Checkbox,
	Tooltip,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	searchBarTheme,
} from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import React, { useContext, useEffect, useState } from 'react';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';
import {
	InsightReportTableGlobalStyles,
	ellipsisStyle,
	modalFormStyle,
} from '../../Layout/styles';
import { Autocomplete } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import {
	GetCustomerWiseProjectResourceMappingQuery,
	useGetCrmCompanyListQuery,
	useGetCustomerWiseProjectResourceMappingQuery,
} from '../../generated/graphql';
import { exportToCsv, formatExperience } from '../../Utils/string.util';
import { EMPLOYEE_LIST_WITH_EXPERIENCE_EXPORT_COLUMNS } from '../ResourcePlanner/constant';
import _, { head, isEmpty, uniq } from 'lodash';
import { customizedDataGridTheme, dataGridStyle } from './Style';
import { UserProfileContext } from '../../App';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import dayjs from 'dayjs';

interface SearchProps {
	searchText: string;
	onSearch: (searchValue: string) => void;
}

interface TableData {
	companyName: string;
	resourceName: string;
	experience: string;
	projectNames: string;
}

interface companyOption {
	__typename: string;
	id: string;
	name: string;
}

const CustomSearch = (props: SearchProps) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Resource'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

const CompanyWiseEmployeeExperienceList = () => {
	const dataGridStyles = dataGridStyle();
	const [searchedText, setSearchedText] = useState<string>('');
	const [companyOptions, setCompanyOptions] = useState<companyOption[] | any>(
		[]
	);
	const [selectedCompanyOptionValue, setSelectedCompanyOptionValue] = useState<
		any | null
	>();
	const [selectedCompanyIdList, setSelectedCompanyIdList] = useState<
		string[] | any[]
	>([]);
	const styles = modalFormStyle();
	const ellipsisstyle = ellipsisStyle();
	const ellipsis = ellipsisStyle();
	const commonStyle = InsightReportTableGlobalStyles();
	const { filterState, dispatchFilter } = useContext<any>(UserProfileContext);
	const [sortFilter, setSortFilter] = useState<
		{ field: string; direction: 'asc' | 'desc' } | any
	>({ field: 'resourceName', direction: 'asc' });

	const { data: CompanyList } = useGetCrmCompanyListQuery();

	const searchPayload = {
		_companyId:
			selectedCompanyIdList && selectedCompanyIdList?.length > 0
				? selectedCompanyIdList
				: [],
		...(searchedText && {
			resourceFilter: {
				_or: [
					{ user: { first_name: { _ilike: `%${searchedText}%` } } },
					{ user: { last_name: { _ilike: `%${searchedText}%` } } },
					{ user: { full_name: { _ilike: `%${searchedText}%` } } },
				],
				deleted_at: { _is_null: true },
			},
		}),
		sortingResource: filterState.companyResourceSort ?? [],
	};

	const { data: projectResourceMappingList, loading: loadingList } =
		useGetCustomerWiseProjectResourceMappingQuery({
			variables: searchPayload,
			fetchPolicy: 'network-only',
		});

	const { data: exportResourceData } =
		useGetCustomerWiseProjectResourceMappingQuery({
			variables: searchPayload,
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (!CompanyList) {
			return;
		}
		const response = CompanyList?.crm_company?.map((user) => {
			return {
				id: user?.id,
				name: user?.name,
			};
		});
		const defaultResourceIdList = response?.map(
			(CompanyList: { id: string }) => CompanyList?.id
		);
		setCompanyOptions(response);
		setSelectedCompanyIdList(defaultResourceIdList);
		setSelectedCompanyOptionValue([{ id: 'All', name: 'All' }, ...response]);
	}, [CompanyList]);

	const columns = [
		{
			name: 'data',
			label: 'data',
			options: {
				filter: true,
				viewColumns: false,
				display: false,
			},
		},
		{
			name: 'companyName',
			label: 'Company',
			options: {
				filter: true,
				viewColumns: false,
				sort: true,
				customBodyRender: (value: any | null | undefined) => (
					<Tooltip title={value || ''} placement='right'>
						<span>{value || ''}</span>
					</Tooltip>
				),
			},
		},
		{
			name: 'resourceName',
			label: 'Employee Name',
			options: {
				filter: true,
				viewColumns: false,
				sort: true,
				customBodyRender: (value: any | null | undefined) => (
					<Tooltip title={value || ''} placement='right'>
						<span>{value || ''}</span>
					</Tooltip>
				),
			},
		},
		{
			name: 'experience',
			label: 'Experience',
			options: {
				filter: true,
				viewColumns: false,
				sort: false,
				setCellHeaderProps: () => ({
					className: dataGridStyles.tableHeader,
				}),
				customBodyRender: (value: any | null | undefined) => (
					<Tooltip title={value || ''} placement='right'>
						<span>{value || ''}</span>
					</Tooltip>
				),
			},
		},
	];

	const options = {
		filter: false,
		search: false,
		viewColumns: false,
		download: false,
		print: false,
		toolbar: false,
		selectableRowsHideCheckboxes: true,
		rowsPerPageOptions: [10, 20, 30],
		onColumnSortChange: (changedColumn: string, direction: 'asc' | 'desc') => {
			let sortField = '';
			switch (changedColumn) {
				case 'companyName':
					sortField = 'companyName';
					dispatchFilter({
						type: FILTER_STATE_ACTIONS.UPDATE_COMPANY_RESOURCE_SORT,
						payload: [{ project: { company: { name: direction } } }],
					});
					break;
				case 'resourceName':
					sortField = 'resourceName';
					dispatchFilter({
						type: FILTER_STATE_ACTIONS.UPDATE_COMPANY_RESOURCE_SORT,
						payload: [{ user: { full_name: direction } }],
					});
					break;
				default:
					sortField = '';
			}
			setSortFilter({ field: sortField, direction });
		},
	};

	const getResourceWiseExperience = (
		resourceMappingData: GetCustomerWiseProjectResourceMappingQuery,
		sortFilter?: { field: keyof TableData; direction: 'asc' | 'desc' }
	) => {
		const data = _.chain(resourceMappingData?.crm_company)
			.map((company) => {
				const resourceMappingList = isEmpty(company?.projects)
					? []
					: company?.projects?.map((project) => {
							const resourceList = project?.project_resource_mappings || [];
							return resourceList;
					  });
				return resourceMappingList?.flat();
			})
			.value()
			.flat();

		const groupedResourceMapping = _.chain(data)
			.groupBy(
				(resourceMapping) =>
					`${resourceMapping?.employee_id}-${resourceMapping?.project?.company?.id}`
			)
			.value();

		const finalTableData = Object.values(groupedResourceMapping).map((data) => {
			const user = head(data)?.user?.full_name || '--';
			const company = head(data)?.project?.company?.name;
			const projectNames = data?.map(
				(resourceMapping) => resourceMapping?.project?.name
			);
			const uniqueProjectNames = uniq(projectNames);

			const today = dayjs().format('YYYY-MM-DD');

			const datesArray = data.filter((value) =>
				dayjs(value.start_date).isBefore(today)
			);

			if (datesArray.length > 0) {
				const nonEmptyDatesArray = datesArray.map((value) => {
					return {
						startDate: value?.start_date,
						endDate: dayjs(value.end_date).isAfter(today)
							? today
							: value.end_date,
					};
				});

				nonEmptyDatesArray.sort((a, b) =>
					dayjs(a.startDate).diff(dayjs(b.startDate))
				);

				let totalDays = 0;
				let currentStart = dayjs(nonEmptyDatesArray[0]?.startDate);
				let currentEnd = dayjs(nonEmptyDatesArray[0]?.endDate);
				for (let i = 1; i < nonEmptyDatesArray.length; i++) {
					const start = dayjs(nonEmptyDatesArray[i]?.startDate);
					const end = dayjs(nonEmptyDatesArray[i]?.endDate);
					if (start.isAfter(currentEnd)) {
						totalDays += currentEnd.diff(currentStart, 'day') + 1;
						currentStart = start;
						currentEnd = end;
					} else {
						if (end.isAfter(currentEnd)) {
							currentEnd = end;
						}
					}
				}

				totalDays += currentEnd.diff(currentStart, 'day') + 1;
				const totalExperience = { days: totalDays };
				const formatedExperience = formatExperience(totalExperience);

				return {
					companyName: company,
					resourceName: user,
					experience: formatedExperience,
					projectNames: uniqueProjectNames.join(','),
				};
			} else {
				return {
					companyName: company,
					resourceName: user,
					experience: formatExperience({ days: 0 }),
					projectNames: uniqueProjectNames.join(','),
				};
			}
		});
		if (sortFilter) {
			const { field, direction } = sortFilter;

			const sortedData = _.orderBy(
				finalTableData,
				[
					(data) => {
						const value: string | undefined = data[field];
						return value?.toUpperCase();
					},
				],
				[direction]
			);

			return sortedData;
		}

		return finalTableData || [];
	};

	const getResourceDetail = () => {
		if (
			!projectResourceMappingList ||
			!projectResourceMappingList.crm_company
		) {
			return [];
		}
		const resourceDetail = getResourceWiseExperience(
			projectResourceMappingList,
			sortFilter
		);
		return resourceDetail || [];
	};

	const exportResourceDetail = () => {
		if (!exportResourceData) {
			return;
		}

		const resourceData = getResourceWiseExperience(
			exportResourceData,
			sortFilter
		).map((experience: object) => {
			return [...Object.values(experience)];
		});
		const exportData = [
			EMPLOYEE_LIST_WITH_EXPERIENCE_EXPORT_COLUMNS,
			...resourceData,
		];

		exportToCsv('Customer Wise Employee Experience.csv', exportData);
	};

	const getLimits = (value: Number) => {
		return (
			<div
				className={`${dataGridStyles.dropDownValue} ${ellipsisstyle.ellipsis}`}
			>
				{selectedCompanyOptionValue && selectedCompanyOptionValue?.length === 1
					? selectedCompanyOptionValue[0]?.name
					: `Selected ${value} items`}
			</div>
		);
	};

	const companyAutoCompleteInput = () => (
		<Autocomplete
			multiple
			limitTags={0}
			value={
				selectedCompanyOptionValue && selectedCompanyOptionValue?.length > 0
					? selectedCompanyOptionValue
					: []
			}
			selectOnFocus={true}
			style={{ width: 210, marginTop: '4px' }}
			disablePortal
			fullWidth={false}
			onChange={(event, value, reason) => {
				if (!value) {
					return;
				}
				const selectedIds = value
					?.filter((company) => company.id !== 'All')
					.map((company) => company?.id);
				if (
					value.find((option) => option.id === 'All') &&
					reason === 'select-option'
				) {
					setSelectedCompanyOptionValue([
						{ id: 'All', name: 'All' },
						...companyOptions,
					]);
					const allCompanyIds = companyOptions?.map(
						(company: { id: any }) => company?.id
					);
					setSelectedCompanyIdList(allCompanyIds);

					return;
				}
				if (
					value.find((option) => option.id === 'All') &&
					reason === 'remove-option' &&
					companyOptions?.length !== selectedIds?.length
				) {
					const currentOptions = value?.filter(
						(company) => company?.id !== 'All'
					);
					setSelectedCompanyOptionValue(currentOptions);
					const currentIds = currentOptions?.map((company) => company?.id);
					setSelectedCompanyIdList(currentIds);

					return;
				}
				if (
					selectedIds?.length === companyOptions?.length &&
					reason === 'select-option'
				) {
					setSelectedCompanyOptionValue([
						{ id: 'All', name: 'All' },
						...companyOptions,
					]);
					const companyIds = companyOptions?.map((company: any) => company?.id);
					setSelectedCompanyIdList(companyIds);

					return;
				}
				if (
					selectedCompanyOptionValue.find(
						(option: any) => option?.id === 'All'
					) &&
					reason === 'remove-option'
				) {
					setSelectedCompanyIdList([]);
					setSelectedCompanyOptionValue([]);

					return;
				}
				const selectedId = value?.map((company) => company?.id);
				setSelectedCompanyIdList(selectedId);

				setSelectedCompanyOptionValue(value);
			}}
			options={
				companyOptions && companyOptions?.length > 0
					? [{ id: 'All', name: 'All' }, ...companyOptions]
					: []
			}
			disableCloseOnSelect={true}
			getLimitTagsText={getLimits}
			ChipProps={{ style: { display: 'none' } }}
			renderInput={(params) => (
				<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
					<TextComponent {...params} label='' />
				</ThemeProvider>
			)}
			renderOption={(option, { selected }) => (
				<>
					<Checkbox
						icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
						checkedIcon={<CheckBoxIcon fontSize='small' />}
						style={{ marginRight: 8 }}
						checked={selected}
						color='primary'
					/>
					<Tooltip title={`${option?.name || '- -'}`} placement='right'>
						<Typography
							className={`${ellipsis.ellipsis}`}
							style={{ fontSize: '12px' }}
						>
							{`${option?.name || '- -'}`}
						</Typography>
					</Tooltip>
				</>
			)}
			getOptionLabel={(option) => `${option?.name}`}
			getOptionSelected={(option, value) => option.id === value.id}
		/>
	);
	return (
		<ThemeProvider theme={insightChartTheme}>
			<>
				<Box display='flex' marginBottom='10px'>
					<Typography className={dataGridStyles.heading}>
						Customer Wise Employee Experience
					</Typography>
				</Box>
				<Box className={dataGridStyles.headerBox}>
					<Box className={dataGridStyles.displayFlex}>
						<Box>
							<CustomSearch
								searchText={searchedText}
								onSearch={(value: string) => setSearchedText(value)}
							/>
						</Box>
						<Box className={dataGridStyles.searchBox}>
							<Box className={styles.label}>Search Company</Box>
							{companyAutoCompleteInput()}
						</Box>
						<Box>
							{loadingList ?? (
								<Box className={dataGridStyles.loader}>
									<CircularProgress
										className={dataGridStyles.circularProgress}
									/>
								</Box>
							)}
						</Box>
					</Box>
					<Box>
						<ExportButtonWithLoader
							generateCSV={() => {
								exportResourceDetail();
							}}
							isDisabled={
								!projectResourceMappingList?.crm_company?.length ||
								!companyOptions.length ||
								!exportResourceData
							}
						/>
					</Box>
				</Box>

				<Box>
					{!projectResourceMappingList?.crm_company?.length ||
					!companyOptions.length ? (
						<Box className={dataGridStyles.noData}>
							<Typography className={commonStyle.noDataLabel}>
								No data found
							</Typography>
						</Box>
					) : (
						selectedCompanyOptionValue && (
							<ThemeProvider theme={customizedDataGridTheme}>
								<>
									<MUIDataTable
										title={'Customer Wise Employee Experience List'}
										data={getResourceDetail()}
										columns={columns}
										options={options}
									/>
								</>
							</ThemeProvider>
						)
					)}
				</Box>
				{/* )} */}
			</>
		</ThemeProvider>
	);
};

export default CompanyWiseEmployeeExperienceList;
