import { Col, Row, Spin, Typography } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { getCurrencyFormat } from '../../../Utils/string.util';

const { Text } = Typography;
interface Props {
	count: number | string;
	currencyType: string | undefined;
	isLoading?: boolean;
}

export const PipelineSalesRevenue = (props: Props) => {
	const { count, currencyType, isLoading } = props;

	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text key={''}>
							{count ? getCurrencyFormat(count || 0, currencyType, true) : '--'}
						</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};
