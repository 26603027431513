export const PROJECT_LABEL_CREATED_NOTIFICATION = 'Project label created...';
export const PROJECT_CATEGORY_CREATED_NOTIFICATION =
	'Project category created...';

export const breadcumData = [{ name: 'Projects', path: '/resource-planner' }];
export const MILESTONE_EXPORT_COLUMNS = [
	'NAME',
	'AMOUNT',
	'START DATE',
	'END DATE',
	'TOTAL TASK',
	'COMPLETED TASK',
	'STATUS',
	'PAYMENT STATUS',
	'LINK TO INVOICE',
];
export const RESOURCE_ALLOCATION_EXPORT_COLUMNS = [
	'RESOURCE',
	'PROJECT',
	'START DATE',
	'END DATE',
	'ALLOCATION(%)',
	'TOTAL COST',
	'BILLABLE',
	'IS PARTIAL ALLOCATION',
	'TAG',
];

export const EMPLOYEE_LIST_WITH_EXPERIENCE_EXPORT_COLUMNS = [
	'COMPANY',
	'RESOURCE',
	'EXPERIENCE',
	'PROJECTS',
];
