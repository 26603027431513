import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableHead,
	TableRow,
	Divider,
	TablePagination,
} from '@material-ui/core';
import 'antd/dist/antd.css';
import React, { useState, useContext, useEffect } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { UserProfileContext } from '../../App';
import { head, isEmpty } from 'lodash';
import { calculateCurrentLeaveCalenderYear } from '../../Reports/Constant';
import {
	useGetLeaveBalanceQuery,
	useGetOrganisationLeaveCalenderYearQuery,
} from '../../generated/graphql';
import { ellipsisStyle } from '../../Layout/styles';
import { isFloat } from '../../Utils/string.util';
import { homePageStyle } from './style';
import { CustomTableCell } from '../../SharedComponents/CustomTableCell.component';

export const Style = makeStyles({
	summaryContainer: {
		width: '655px',
		maxHeight: '300px',
		overflowX: 'hidden',
		overflowY: 'auto',
		background: '#FFFFFF',
	},
	additionalInfoLabelLeave: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
		maxWidth: '60px',
	},
	noData: {
		fontFamily: 'Manrope-medium',
		letterSpacing: '0px',
		fontSize: '12px !important',
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

interface Props {
	employeeId: String;
}

interface Leaves {
	id: string;
	name: string;
	applied: number;
	balance: number;
}

const LeaveSummary = (props: Props) => {
	const { employeeId } = props;
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const [currentLeaveCalenderYear, setCurrentLeaveCalenderYear] = useState({
		startDate: '',
		endDate: '',
	});
	const [rowsPerPage, setRowsPerPage] = useState(4);
	const [page, setPage] = useState(0);
	const classes = Style();
	const style = ellipsisStyle();
	const homeStyles = homePageStyle();
	const [organizationLeaves, setOrganizationLeaves] = useState<Leaves[]>([]);

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId },
			fetchPolicy: 'network-only',
		});

	const { data: orgLeaves } = useGetLeaveBalanceQuery({
		variables: {
			employeeId,
			startDate: currentLeaveCalenderYear?.startDate,
			endDate: currentLeaveCalenderYear?.endDate,
		},
		skip: !(employeeId && currentLeaveCalenderYear),
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const leaveCalenderData = organisationLeaveCalenderYear.organization_by_pk;
		if (!leaveCalenderData) {
			return;
		}
		const calculatedDate = calculateCurrentLeaveCalenderYear({
			leave_calendar_year_start_month:
				leaveCalenderData.leave_calendar_year_start_month,
			leave_calendar_year_start_date:
				leaveCalenderData?.leave_calendar_year_start_date,
			leave_calendar_year_end_date:
				leaveCalenderData?.leave_calendar_year_end_date,
			leave_calendar_year_end_month:
				leaveCalenderData?.leave_calendar_year_end_month,
		});
		setCurrentLeaveCalenderYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	useEffect(() => {
		if (isEmpty(orgLeaves?.org_leave_group)) {
			return;
		}
		const response = head(orgLeaves?.org_leave_group)?.org_leave_types?.map(
			(value) => {
				const appliedLeaveCount = value?.employee_leaving_mappings?.reduce(
					(sum, number) => {
						return number?.leave_count + sum;
					},
					0
				);
				const accruedLeaveCount =
					value?.accrued_leave_type?.length > 0
						? value?.accrued_leave_type?.reduce((sum, number) => {
								return number?.count + sum;
						  }, 0)
						: 0;
				const openingBalance =
					value?.employee_leave_type_mappings?.length > 0
						? head(value?.employee_leave_type_mappings)?.opening_balance
						: '--';
				return {
					id: value?.id,
					name: value?.leave_type,
					applied: Number(appliedLeaveCount) || 0,
					balance:
						accruedLeaveCount + openingBalance - Number(appliedLeaveCount) || 0,
				};
			}
		);
		if (!response) {
			return;
		}
		setOrganizationLeaves(response);
	}, [orgLeaves]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event?.target?.value, 10));
		setPage(0);
	};

	return (
		<Box>
			<Card>
				<CardHeader
					title='My Leave Balance Summary'
					titleTypographyProps={{ variant: 'h6' }}
				/>
				<CardContent style={{ overflowY: 'auto' }}>
					<ResponsiveContainer height={355}>
						<Box>
							<Box className={homeStyles.headerCell}>
								{organizationLeaves && organizationLeaves?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell>Leave Type</CustomTableCell>
													<CustomTableCell>Applied</CustomTableCell>
													<CustomTableCell>Balance</CustomTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{organizationLeaves &&
													organizationLeaves?.length > 0 &&
													organizationLeaves
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((value: any) => {
															return (
																<TableRow key={value.id}>
																	<CustomTableCell>
																		<Tooltip
																			title={`${value?.name || ' - -'}`}
																			placement='right'
																		>
																			<Typography
																				className={`${classes.additionalInfoLabelLeave} ${style.ellipsis}`}
																			>
																				{value?.name}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{isFloat(Number(value.applied))
																			? parseFloat(
																					Number(value.applied).toFixed(3)
																			  )
																			: value.applied}
																	</CustomTableCell>
																	<CustomTableCell>
																		{isFloat(Number(value.balance))
																			? parseFloat(
																					Number(value.balance).toFixed(3)
																			  )
																			: value.balance}
																	</CustomTableCell>
																	<Divider />
																</TableRow>
															);
														})}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[4, 8, 16]}
												count={organizationLeaves?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={classes.noData}>
										No leave types assigned.
										<br />
										Please contact admin
									</Box>
								)}
							</Box>
						</Box>
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</Box>
	);
};

export default LeaveSummary;
