import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { insightChartTheme } from '../../../Layout/Theme.component';
import { formatAmount } from '../../../Utils/string.util';
import NoDataFound from '../../NoDataFound.component';
import { ChartDatePicker } from '../CustomDatePicker.component';
import { ChartWithDateRange } from '../modal';
import { useGetPipelineSalesByGeographyQuery } from '../../../generated/graphql';
import { chain } from 'lodash';

interface ChartData {
	Country: string;
	'Total Value': number;
}

export const PipelineTotalSaleByGeographyReport = (
	props: ChartWithDateRange
) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineTotalSaleByGeography, loading: isLoading } =
		useGetPipelineSalesByGeographyQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
			},
		});

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineTotalSaleByGeography) {
			return;
		}
		const data = chain(pipelineTotalSaleByGeography.crm_company)
			.map((company) => {
				const { country, ...rest } = company;
				return { ...rest, country: country?.name };
			})
			.groupBy('country')
			.map((company) => ({
				Country: company[0]?.country || 'Other',
				'Total Value': company.reduce(
					(previousValue, currentValue) =>
						previousValue +
							currentValue?.opportunities_aggregate?.aggregate?.sum?.value || 0,
					0
				),
			}))
			.sort((a, b) => b['Total Value'] - a['Total Value'])
			.value();
		setChartData(data);
	}, [pipelineTotalSaleByGeography]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (!(active && payload && payload.length)) {
			return null;
		}
		return (
			<div style={{ background: '#F7F9FA' }}>
				<p>{label}</p>
				<p>{`${formatAmount(
					parseInt(payload[0].payload['Total Value']),
					currency
				)}`}</p>
			</div>
		);
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='Country'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey='Total Value'
						fill='#196CF5'
						barSize={10}
						radius={[10, 10, 0, 0]}
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Sale By Geography
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => setEndDate(date)}
							selectedStartDate={(date: string) => setStartDate(date)}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
