import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import {
	GET_CRM_CONTACT_TITLES,
	GET_COMPANIES,
	GET_BD_OWNERS,
} from './ContactService.gql';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import { contactChoices } from '../Company/Constant';
import { orderBy } from 'lodash';

export const ContactFilterFormBody = () => {
	const [titleFilterLimit, setTitleFilterLimit] = useState(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [titles, setTitles] = useState(null);
	const [searchedTitle, setSearchedTitle] = useState('');

	const [companyFilterLimit, setCompanyFilterLimit] = useState(
		MAX_FILTER_OPTIONS_SHOWN
	);
	const [companies, setCompanies] = useState(null);
	const [searchedCompany, setSearchedCompany] = useState('');

	const [bdOwnersLimit, setBdOwnersLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);
	const [bdOwners, setBdOwners] = useState(null);
	const [searchedOwnerName, setSearchedOwnerName] = useState('');

	const { data: crmContactTitles } = ApolloUseQuery(GET_CRM_CONTACT_TITLES, {
		variables: {
			limit: titleFilterLimit,
			title: `%${searchedTitle}%`,
		},
	});

	const { data: crmCompanies } = ApolloUseQuery(GET_COMPANIES, {
		variables: {
			limit: companyFilterLimit,
			company: `%${searchedCompany}%`,
		},
	});

	const { data: bdOwnerContacts } = ApolloUseQuery(GET_BD_OWNERS, {
		variables: {
			limit: bdOwnersLimit,
			name: `%${searchedOwnerName}%`,
		},
	});

	useEffect(() => {
		if (crmContactTitles?.crmContacts) {
			const contactTitles = crmContactTitles?.crmContacts.map((contact) => {
				return {
					id: contact.title,
					name: contact.title,
				};
			});
			setTitles(contactTitles);
		}
	}, [crmContactTitles]);

	useEffect(() => {
		if (crmCompanies?.companies) {
			const companyNames = crmCompanies?.companies.map((company) => {
				return {
					id: company.id,
					name: company.name,
				};
			});
			setCompanies(companyNames);
		}
	}, [crmCompanies]);

	useEffect(() => {
		if (bdOwnerContacts?.contacts) {
			const bdUsers = bdOwnerContacts.contacts.map((bdOwner) => {
				return {
					id: bdOwner.user.id,
					name: `${bdOwner.user.full_name} `,
				};
			});
			const sortedBdUsers = orderBy(
				bdUsers,
				[(owners) => owners?.name.toUpperCase()],
				['asc']
			);
			setBdOwners(sortedBdUsers);
		}
	}, [bdOwnerContacts]);

	const onTitleExpansionToggle = (isSeeMoreLabelShown) => {
		setTitleFilterLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onTitleSearch = (title) => {
		setSearchedTitle(title);
	};

	const onCompanyExpansionToggle = (isSeeMoreLabelShown) => {
		setCompanyFilterLimit(
			isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null
		);
	};

	const onCompanySearch = (company) => {
		setSearchedCompany(company);
	};

	const onOwnerExpansionToggle = (isSeeMoreLabelShown) => {
		setBdOwnersLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onOwnerSearch = (name) => {
		setSearchedOwnerName(name);
	};

	return (
		<Box>
			<Box>
				<FilterInput
					label={'Company'}
					sourceName='company_id'
					onExpansionToggle={onCompanyExpansionToggle}
					choices={companies || []}
					onSearch={(name) => onCompanySearch(name)}
					total={crmCompanies?.crm_company_aggregate?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'BD Owner'}
					sourceName='owner_id'
					onExpansionToggle={onOwnerExpansionToggle}
					choices={bdOwners || []}
					onSearch={(name) => onOwnerSearch(name)}
					total={bdOwnerContacts?.crm_contacts_aggregate?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Title'}
					sourceName='title'
					onExpansionToggle={onTitleExpansionToggle}
					choices={titles || []}
					onSearch={(name) => onTitleSearch(name)}
					total={crmContactTitles?.crm_contacts_aggregate?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Contact type'}
					sourceName='contact_type'
					choices={contactChoices}
				/>
			</Box>
		</Box>
	);
};
