import React, { useState } from 'react'

import {
    SelectInput,
    TextInput,
    required,
    useMutation,
    useNotify,
    ReferenceInput,
} from "react-admin";
import { Form } from 'react-final-form';

import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Typography, Button, Box, Switch } from '@material-ui/core';
import { modalFormTheme } from "../../../Layout/Theme.component";
import { modalFormStyle } from "../../../Layout/styles";

interface EmployeeInActiveFormProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    userId?: string;
}

interface Record {
    reason_id: string;
    notes?: string;
    is_rehire_eligible: boolean;
    user_id?: string;
}

export const ConsultantInActiveForm = ({
    open,
    onClose,
    onSuccess,
    userId
}: EmployeeInActiveFormProps) => {
    const classes = modalFormStyle();
    const [mutate] = useMutation();
    const notify = useNotify();
    const[isContractEligible, setIsContractEligible] = useState(true)
    const saveInactiveFormData = (formValue: Record ) => {
        mutate({
            type: "create",
            resource: "contractor_leaving_reason",
            payload: {
                data: {...formValue, user_id: userId, is_rehire_eligible: isContractEligible}
            },
        },
            {
                onSuccess: ({ data }) => {
                    onSuccess()
                    onClose()
                },
                onFailure: (error) =>
                    notify(`Create failed: ${error.message}`, "warning"),
            });
    }

    return (
        <ThemeProvider theme={modalFormTheme}>
            <Dialog open={open} disableBackdropClick>
                <Box className={classes.container}>
                    <Box className={classes.headerContainer}>
                        <Typography className={classes.heading}>ADDITIONAL INFO</Typography>
                        <CloseIcon
                            onClick={onClose}
                            className={classes.closeIcon}
                        />
                    </Box>

                    <Box className={classes.infoContentContainer}>
                        <Typography className={classes.warningContent}>
                            Please note the user will loose the access to the system. This change is not reversible.
                        </Typography>
                    </Box>

                    <Form
                        onSubmit={saveInactiveFormData}
                    >
                        {({ handleSubmit, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                <Box className={classes.formContainer}>
                                    <Typography className={classes.label}>Reason for Leaving *</Typography>
                                    <ReferenceInput
                                        validate={required()}
                                        source="reason_id"
                                        reference="contractor_reason"
                                        label=""
                                    >
                                        <SelectInput
                                            optionText="label"
                                            fullWidth
                                       />
                                    </ReferenceInput>
                                    
                                    

                                    <Typography className={classes.label}>Notes</Typography>
                                    <TextInput
                                        multiline
                                        source="notes"
                                        label=""
                                        placeholder="Enter the text"
                                    />

                                    <Box className={classes.eligibleContainer} >
                                        <Typography className={classes.radioButtonLabel}>Eligible for Rehire</Typography>
                                        <Switch
                                            defaultChecked={true}
                                            color="primary"
                                            name="is_rehire_eligible"
                                            onChange={(event: any) => {
                                                setIsContractEligible(event?.target?.checked)
                                            }}
                                        />
                                    </Box>
                                    <Box className={classes.buttonContainer}>
                                        <Button
                                            onClick={onClose}
                                            className={classes.cancelButton}
                                        >
                                            Cancel
                                            </Button>
                                        <Button
                                            className={invalid ? classes.disabledButton : classes.saveButton}
                                            disabled={invalid}
                                            type="submit"
                                        >
                                            Save
                                            </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Form>
                </Box>
            </Dialog>
        </ThemeProvider>
    )
}

export default ConsultantInActiveForm
