import React, { useState } from 'react';

import {
	Box,
	ThemeProvider,
	Dialog,
	Typography,
	Button,
} from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../settingsStyle';
import { modalFormStyle } from '../../../Layout/styles';
import { Form } from 'react-final-form';
import {
	TextInput,
	RadioButtonGroupInput,
	required,
	useRefresh,
	useNotify,
} from 'react-admin';
import {
	useUpsertOrgTaskMutation,
	useGetDepartmentOptionsQuery,
	useUpsertOrgTaskMappingsMutation,
	useAddOrgTaskMutation,
} from '../../../generated/graphql';
import {
	TIMESHEET_ORG_TASK_CREATION_TEXT,
	TIMESHEET_ORG_TASK_UPDATION_TEXT,
	ORG_TASK_UNIQUE_VIOLATION_TEXT,
	ORG_TASK_UNIQUE_VIOLATION_KEY,
} from '../constant';

interface OrgTask {
	id?: string;
	is_billable?: boolean;
	title?: string;
	task_category?: string;
	org_id?: string;
}

interface TimesheetOrgTaskFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: OrgTask;
	onSuccess: () => void;
}

export const TimesheetOrgTaskForm = (props: TimesheetOrgTaskFormProps) => {
	const timesheetOrgTaskFormInitialValues = {
		is_billable: true,
	};
	const { open, onClose, initialValues, onSuccess } = props;
	const [isTaskUniqueViolationError, setIsTaskUniqueViolationError] =
		useState(false);
	const settingsStyles = settingsStyle();
	const modalFormStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const notificationText = initialValues?.id
		? TIMESHEET_ORG_TASK_UPDATION_TEXT
		: TIMESHEET_ORG_TASK_CREATION_TEXT;

	const [upsertOrgTask,{loading:isTaskUpdateLoading}] = useUpsertOrgTaskMutation();
	const { data: departments } = useGetDepartmentOptionsQuery();
	const [upsertOrgTaskMappings,{loading:isOrgTaskLoading}] = useUpsertOrgTaskMappingsMutation();
	const [insertOrgTask] = useAddOrgTaskMutation();

	const addOrgTask = (formValues: OrgTask) => {
		insertOrgTask({
			variables: {
				data: {
					is_billable: formValues.is_billable,
					title: formValues.title,
					task_category: formValues.task_category,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					upsertOrgTaskMappings({
						variables: {
							orgTaskId: response.data?.insert_org_tasks_one?.id,
							departmentIdsToDelete: [],
							data: departments?.department.map((dept) => ({
								task_id: response.data?.insert_org_tasks_one?.id,
								department_id: dept.id,
							})),
						},
					})
						.then((response) => {
							if (!response.errors) {
								setIsTaskUniqueViolationError(false);
								notify(notificationText);
								refresh();
								handleClose();
								onSuccess();
							}
						})
						.catch((error) => {
							if (!error) {
								return;
							}
						});
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}
				if (error.message.includes(ORG_TASK_UNIQUE_VIOLATION_KEY)) {
					setIsTaskUniqueViolationError(true);
					return;
				}
			});
	};

	const updateOrgTask = (formValues: OrgTask) => {
		upsertOrgTask({
			variables: {
				data: {
					id: initialValues?.id,
					is_billable: formValues.is_billable,
					title: formValues.title,
					task_category: formValues.task_category,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					setIsTaskUniqueViolationError(false);
					notify(notificationText);
					refresh();
					handleClose();
					onSuccess();
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}
				if (error.message.includes(ORG_TASK_UNIQUE_VIOLATION_KEY)) {
					setIsTaskUniqueViolationError(true);
					return;
				}
			});
	};

	const handleTaskFormSubmit = (formValues: OrgTask) => {
		if (!formValues) {
			return;
		}
		if (initialValues?.id) {
			updateOrgTask(formValues);
			return;
		}
		addOrgTask(formValues);
	};

	const handleClose = () => {
		setIsTaskUniqueViolationError(false);
		onClose();
	};

	const validateOrgTaskFormValues = async () => {
		const orgTaskFormErrors = {} as OrgTask;
		if (isTaskUniqueViolationError) {
			orgTaskFormErrors.title = ORG_TASK_UNIQUE_VIOLATION_TEXT;
		}
		return orgTaskFormErrors;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{initialValues?.id ? `${initialValues?.title}` : `Add Task`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={handleClose}
						/>
					</Box>
					<Form
						initialValues={
							initialValues?.id
								? initialValues
								: timesheetOrgTaskFormInitialValues
						}
						onSubmit={handleTaskFormSubmit}
						validate={validateOrgTaskFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='title'
										label=''
										validate={required()}
										onChange={() => {
											setIsTaskUniqueViolationError(false);
										}}
									/>

									<Typography className={modalFormStyles.label}>
										Category*
									</Typography>
									<TextInput
										source='task_category'
										label=''
										validate={required()}
									/>

									<Typography className={modalFormStyles.label}>
										Is Billable
									</Typography>
									<RadioButtonGroupInput
										source='is_billable'
										label=''
										choices={[
											{ id: true, name: 'Billable' },
											{ id: false, name: 'Non Billable' },
										]}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine || isOrgTaskLoading || isTaskUpdateLoading}
										className={
											invalid || pristine || isOrgTaskLoading || isTaskUpdateLoading
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{initialValues?.id ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default TimesheetOrgTaskForm;
