export const ALL_CONSULTANTS = 'All';
export const EXPIRED_CONSUTANTS = 'Expired';
export const EXPIRED_IN_N_DAYS = 'Expiring in 10 days';
export const SKILL_ALREADY_EXIST_MESSAGE = 'Selected skill already exist';
export const SKILL_SELECT_MESSAGE = 'Please select a skill';
export const SKILL_LEVEL_SELECT_MESSAGE = 'Please select a skill level';

export const CONSULTANT_GENDER_CHOICES = [
	{ id: 'Male', name: 'Male' },
	{ id: 'Female', name: 'Female' },
	{ id: 'Transgender', name: 'Transgender' },
];

export const breadcumData = [{ name: 'Consultant', path: '/contractor' }];
