import React, { useContext, useState, useEffect, useCallback } from 'react';
import { TextInput, required, useNotify, useRefresh } from 'react-admin';
import { Form, Field } from 'react-final-form';

import CloseIcon from '@material-ui/icons/Close';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	TextField,
	InputAdornment,
	Paper,
} from '@material-ui/core';
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { SearchIcon } from '../../assets/SvgIcons';
import { AutocompleteSearchStyles, modalFormStyle } from '../../Layout/styles';
import {
	modalFormTheme,
	autoCompleteTheme,
} from '../../Layout/Theme.component';
import { TimesheetStyles } from './TimesheetStyles';
import {
	useGetOrgTasksQuery,
	useGetProjectTaskOptionQuery,
	useUpsertTimesheetMutation,
	useGetDepartmentIdQuery,
	useGetTimesheetTotalHoursQuery,
	useGetTimesheetSettingsQuery,
	useGetTimesheetDayTypeOptionsQuery,
	useGetProjectDetailsForCreateTaskInTimesheetQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import dayjs from 'dayjs';
import TimeInput from '../../SharedComponents/TimeInput.component';
import {
	AddTaskPayload,
	TimesheetEntry,
	TimesheetFormValues,
} from './Timesheet.model';
import {
	TIME_SHEET_ADD_NOTIFICATION_TEXT,
	TIME_SHEET_UPDATE_NOTIFICATION_TEXT,
	PROJECT_TYPE_CATEGORY,
	PROJECT_TASK_GROUP_LABEL,
	MASTER_PROJECT_GROUP_LABEL,
	ORG_TASK_GROUP_LABEL,
	MASTER_PROJECT_TASK_TYPE,
	ORG_TASK_TYPE,
	checkIfOnlyZeroesWithOneColon,
	validHHMMFormat,
	checkIfOnlyOneNumberAfterColon,
	checkIfOnlyOneColonAfterNumbers,
	checkIfOnlyNumbers,
	timesheetApprovedConstraintError,
} from './constant';
import { head, isEmpty } from 'lodash';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import {
	getTimesheetSubmissionId,
	getTotalHoursByDay,
} from './Timesheet.utils';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateTaskForm from '../ProjectTask/CreateTaskForm';
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetFormProps {
	onClose: () => void;
	open: boolean;
	projectOptions:
		| {
				id: string;
				name: string;
				isBillable?: boolean | null;
				companyName?: string | undefined;
				type: string;
		  }[]
		| undefined;
	onSuccess?: () => void;
	initialValues: TimesheetFormValues;
	isWeeklyTimesheetForm?: boolean;
	onSubmit: (data: AddTaskPayload) => void;
	timesheetEntries: TimesheetEntry[];
	userJoinDate?: string;
	projectTaskDetail?: {
		projectId: string | null;
		taskId: string | null;
	};
}

export const TimesheetGlobalForm = (props: TimesheetFormProps) => {
	const {
		id: userId,
		orgId: organisationId,
		isTrackDayTypeEnabled,
		dateFormat,
	} = useContext<any>(UserProfileContext);
	const timesheetStyles = TimesheetStyles();
	const timesheetFormStyles = AutocompleteSearchStyles();
	const {
		open,
		onClose,
		projectOptions,
		onSuccess,
		initialValues,
		isWeeklyTimesheetForm = false,
		onSubmit,
		timesheetEntries,
		userJoinDate,
		projectTaskDetail,
	} = props;

	const [selectedProject, setSelectedProject] = useState<string>('');
	const [isDayHoursLimitExceed, setIsDayHoursLimitExceed] = useState(false);
	const [isTaskUniqueViolationError, setIsTaskUniqueViolationError] =
		useState(false);
	const [
		isMasterProjectTaskUniqueViolationError,
		setIsMasterProjectTaskUniqueViolationError,
	] = useState(false);
	const [isOrgTaskUniqueViolationError, setIsOrgTaskUniqueViolationError] =
		useState(false);
	const [isZeroWorkingHoursError, setIsZeroWorkingHoursError] = useState(false);
	const [isWorkingHoursError, setIsWorkingHoursError] = useState(false);
	const [isCreateTaskFormShown, setIsCreateTaskFormShown] = useState(false);
	const [showCreateOption, setShowCreateOption] = useState(false);
	const [createTaskName, setCreateTaskName] = useState<string | null>('');
	const [kanbanId, setKanbanId] = useState<string | null>();
	const [workingHourInputError, setWorkingHourInputError] = useState('');
	const [choosedTaskDetails, setChoosedTaskDetails] = useState<any>();
	const [currentWeekTimesheetStatus, setCurrentWeekTimesheetStatus] = useState<
		string | null | undefined
	>('');
	const [
		isTimesheetApprovedConstraintError,
		setIsTimesheetApprovedConstraintError,
	] = useState(false);
	const [orgTaskCategory, setOrgTaskCategory] = useState('');
	const [orgDepartmentId, setOrgDepartmentId] = useState('');
	const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
	const [timesheetSubmissionError, setTimesheetSubmissionError] =
		useState(false);
	const filter = createFilterOptions();
	const formStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const currentWeek = Array(7)
		.fill(1)
		.map((value, index) => dayjs(date).day(index).format('YYYY-MM-DD'));

	const { data: timesheetSettingsDetails } = useGetTimesheetSettingsQuery({
		variables: {
			orgId: organisationId,
		},
		skip: !organisationId,
	});

	const { data: weekHoursByday, refetch: refetchTime } =
		useGetTimesheetTotalHoursQuery({
			variables: {
				userId: userId,
				startDate: currentWeek[0],
				endDate: currentWeek[6],
			},
			fetchPolicy: 'network-only',
		});

	const {
		data: projectTaskOptions,
		loading: isProjectTaskLoading,

		refetch: refetchProjectTask,
	} = useGetProjectTaskOptionQuery({
		variables: {
			projectId: selectedProject,
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: departmentId } = useGetDepartmentIdQuery({
		variables: {
			userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: dayTypeOptions, loading: isDayTypeLoading } =
		useGetTimesheetDayTypeOptionsQuery({
			fetchPolicy: 'network-only',
			skip: !isTrackDayTypeEnabled,
		});
	const [upsertTimesheet] = useUpsertTimesheetMutation();

	const { data: orgTasks } = useGetOrgTasksQuery({
		variables: {
			taskCategory: orgTaskCategory,
			departmentId: orgDepartmentId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: projectDetails } =
		useGetProjectDetailsForCreateTaskInTimesheetQuery({
			variables: {
				projectId: selectedProject,
			},
			fetchPolicy: 'network-only',
			skip: !selectedProject,
			onError: () => {
				setKanbanId(null);
			},
		});

	useEffect(() => {
		if (isEmpty(departmentId?.user)) {
			return;
		}
		const response = head(departmentId?.user);
		if (
			isEmpty(response?.employee?.department?.id) &&
			isEmpty(head(response?.contractors)?.department?.id)
		) {
			return;
		}
		const record = response?.employee
			? response?.employee?.department?.id
			: head(response?.contractors)?.department?.id;
		setOrgDepartmentId(record);
	}, [departmentId]);

	useEffect(() => {
		if (!projectDetails?.project) {
			setKanbanId(null);
			return;
		}
		setKanbanId(head(projectDetails?.project)?.kanban_id || null);
	}, [projectDetails]);

	useEffect(() => {
		if (!initialValues?.project_id) {
			return;
		}
		setSelectedProject(initialValues?.project_id);
		if (initialValues.taskType !== ORG_TASK_TYPE) {
			setOrgTaskCategory(PROJECT_TYPE_CATEGORY);
			return;
		}
		setOrgTaskCategory(initialValues?.project_id || '');
	}, [initialValues]);

	const checkTimesheetStatus = () => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission = weekHoursByday.timesheet_daily_hours.find(
			(totalHours) => totalHours?.timesheet_submission_id !== null
		);
		if (!selectedWeekSubmission) {
			setCurrentWeekTimesheetStatus('');
			return;
		}
		setCurrentWeekTimesheetStatus(
			selectedWeekSubmission.timesheetSubmission?.timesheet_status.value
		);
	};

	const isDailyHoursLimitExceed = (formTotalHoursValue: string) => {
		if (!weekHoursByday || !formTotalHoursValue) {
			return;
		}
		const totalHours = getTotalHoursByDay(date, weekHoursByday) || '00:00:00';

		if (initialValues?.id && totalHours) {
			const currentHoursAndMinutes = totalHours.split(':');
			const weekHoursAndMinutes = [initialValues?.working_hours];
			const totalHoursAndMinutes = weekHoursAndMinutes
				.map((hours) => hours.split(':'))
				.map((hoursAndMinutes) =>
					dayjs.duration({
						hours: Number(hoursAndMinutes[0]),
						minutes: Number(hoursAndMinutes[1]),
						seconds: Number(hoursAndMinutes[2]),
					})
				)
				.reduce(
					(total, djs) => total.subtract(djs),
					dayjs.duration({
						hours: Number(currentHoursAndMinutes[0]),
						minutes: Number(currentHoursAndMinutes[1]),
						seconds: Number(currentHoursAndMinutes[2]),
					})
				)
				.format('HH:mm');
			return calculateTotalHours([totalHoursAndMinutes, formTotalHoursValue]);
		}
		const weekHoursAndMinutes = [totalHours, formTotalHoursValue];
		return calculateTotalHours(weekHoursAndMinutes);
	};

	const calculateTotalHours = (hoursAndMinutes: string[]) => {
		const totalHoursAndMinutes = hoursAndMinutes
			.map((hours) => hours.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, djs) => total.add(djs),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			)
			.asHours();

		if (totalHoursAndMinutes > 24) {
			return true;
		}
		return false;
	};

	const checkIfTimeInputValueHasError = (timeInputValue: string) => {
		if (!timeInputValue || timeInputValue.trim() === '') {
			setWorkingHourInputError('Invalid Input');
			return true;
		}

		if (checkIfOnlyNumbers.test(timeInputValue)) {
			return false;
		}

		// Check if hrs or minutes reached max limit
		if (timeInputValue.includes(':')) {
			if (Number(timeInputValue.split(':')[0]) > 23) {
				setWorkingHourInputError('Max hrs reached');
				return true;
			}
			if (Number(timeInputValue.split(':')[1]) > 59) {
				setWorkingHourInputError('Max mins reached');
				return true;
			}
		}

		// Check if only numbers are there in input and check if it is greater than 23
		if (checkIfOnlyOneColonAfterNumbers.test(timeInputValue)) {
			if (Number(timeInputValue.split(':')[0]) > 23) {
				setWorkingHourInputError('Max hrs reached');
				return true;
			}
			return false;
		}

		// Check for format HH:m (11:1)
		if (checkIfOnlyOneNumberAfterColon.test(timeInputValue)) {
			return false;
		}

		// Check valid format HH:MM
		if (validHHMMFormat.test(timeInputValue)) {
			return false;
		}
		setWorkingHourInputError('Invalid Input');
		return true;
	};

	const getProjectTaskOptions = () => {
		if (!projectTaskOptions) {
			return;
		}
		const projectTasks = projectTaskOptions?.project_task.map(
			(projectTask) => ({
				id: projectTask.id,
				name: projectTask.is_billable
					? `${projectTask.title}`
					: `${projectTask.title} (NB)`,
				taskType: PROJECT_TYPE_CATEGORY,
				groupByLabel: PROJECT_TASK_GROUP_LABEL,
				is_billable: projectTask.is_billable,
			})
		);
		const generalProjectTasks = projectTaskOptions?.master_project_task.map(
			(task) => ({
				id: task.id,
				name: task.is_billable ? `${task.title}` : `${task.title} (NB)`,
				taskType: MASTER_PROJECT_TASK_TYPE,
				groupByLabel: MASTER_PROJECT_GROUP_LABEL,
				is_billable: task.is_billable,
			})
		);

		return [...projectTasks, ...generalProjectTasks];
	};

	const getDayTypeOptionRenderer = () => {
		if (!dayTypeOptions) {
			return;
		}
		const dayTypeOptionList = dayTypeOptions?.timesheet_day_type?.map(
			(dayType) => {
				return {
					id: dayType?.id,
					name: dayType?.name,
				};
			}
		);
		return dayTypeOptionList;
	};
	const setSelectedTaskCategory = useCallback(
		(categoryId: string) => {
			if (!categoryId || !projectOptions) {
				return;
			}
			const category = projectOptions.find(
				(project) => project.id === categoryId
			);
			if (category && category.type !== PROJECT_TYPE_CATEGORY) {
				setOrgTaskCategory(category.type);
				return;
			}
			setOrgTaskCategory(PROJECT_TYPE_CATEGORY);
		},
		[projectOptions]
	);

	const getTaskOptionRenderer = () => {
		if (!selectedProject) {
			return [];
		}
		if (orgTaskCategory !== PROJECT_TYPE_CATEGORY) {
			if (!orgTasks) {
				return;
			}
			return orgTasks?.org_task_mappings.map((task) => ({
				id: task.org_task?.id,
				name: task.org_task?.is_billable
					? task.org_task?.title
					: `${task.org_task?.title} (NB)`,
				taskType: ORG_TASK_TYPE,
				groupByLabel: ORG_TASK_GROUP_LABEL,
				is_billable: task.org_task?.is_billable,
			}));
		}

		return getProjectTaskOptions();
	};

	const getTimesheetPayload = (timesheetFormValues: TimesheetFormValues) => {
		let workingHours = timesheetFormValues.working_hours;
		if (/^[0-9]+$/.test(timesheetFormValues.working_hours)) {
			if (Number(timesheetFormValues.working_hours) < 23) {
				workingHours = `0${timesheetFormValues.working_hours}:00`;
			}
		}
		const selectedProject = projectOptions?.find(
			(project) => project.id === timesheetFormValues.project_id
		);
		const taskOptions = getTaskOptionRenderer();
		const selectedTask =
			taskOptions &&
			taskOptions.find((task) => task.id === timesheetFormValues.task_id);

		if (selectedTask?.taskType === PROJECT_TYPE_CATEGORY) {
			return {
				id: timesheetFormValues?.id,
				project_id: timesheetFormValues.project_id,
				task_id: timesheetFormValues.task_id,
				master_project_task_id: null,
				org_task_id: null,
				notes: timesheetFormValues?.notes,
				user_id: userId,
				working_hours: workingHours,
				date: timesheetFormValues.date,
				is_billable: selectedProject?.isBillable
					? selectedTask.is_billable
					: false,
				timesheet_submission_id: getTimesheetSubmissionId(weekHoursByday),
				day_type_id: timesheetFormValues?.day_type_id || null,
			};
		}

		if (selectedTask?.taskType === MASTER_PROJECT_TASK_TYPE) {
			return {
				id: timesheetFormValues?.id,
				project_id: timesheetFormValues.project_id,
				master_project_task_id: timesheetFormValues.task_id,
				task_id: null,
				org_task_id: null,
				notes: timesheetFormValues?.notes,
				user_id: userId,
				working_hours: workingHours,
				date: timesheetFormValues.date,
				is_billable: selectedProject?.isBillable
					? selectedTask.is_billable
					: false,
				timesheet_submission_id: getTimesheetSubmissionId(weekHoursByday),
				day_type_id: timesheetFormValues?.day_type_id || null,
			};
		}

		return {
			id: timesheetFormValues?.id,
			org_task_id: timesheetFormValues.task_id,
			master_project_task_id: null,
			project_id: null,
			task_id: null,
			notes: timesheetFormValues?.notes,
			user_id: userId,
			working_hours: workingHours,
			date: timesheetFormValues.date,
			is_billable: selectedTask?.is_billable,
			timesheet_submission_id: getTimesheetSubmissionId(weekHoursByday),
			day_type_id: timesheetFormValues?.day_type_id || null,
		};
	};

	const getTimesheetStatus = () => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission = weekHoursByday.timesheet_daily_hours.find(
			(totalHours) => totalHours?.timesheet_submission_id !== null
		);
		if (!selectedWeekSubmission) {
			return null;
		}

		return selectedWeekSubmission?.timesheetSubmission?.timesheet_status?.value;
	};

	const handleSubmit = (timesheetFormValues: TimesheetFormValues) => {
		if (!timesheetFormValues) {
			return;
		}
		checkTimesheetStatus();
		const currentStatus = getTimesheetStatus();
		if (
			head(timesheetSettingsDetails?.timesheet_settings)
				?.disable_after_submit &&
			currentStatus === 'submitted'
		) {
			setTimesheetSubmissionError(true);
			return;
		}
		if (isDailyHoursLimitExceed(timesheetFormValues.working_hours)) {
			setIsDayHoursLimitExceed(true);
			return;
		}
		if (
			timesheetFormValues.working_hours === '00:00' ||
			Number(timesheetFormValues.working_hours) === 0 ||
			checkIfOnlyZeroesWithOneColon.test(timesheetFormValues.working_hours)
		) {
			setIsZeroWorkingHoursError(true);
			return;
		}

		const isTimeInputError = checkIfTimeInputValueHasError(
			timesheetFormValues.working_hours
		);

		if (isTimeInputError) {
			setIsWorkingHoursError(true);
			return;
		}

		const timesheetPayload = getTimesheetPayload(timesheetFormValues);
		upsertTimesheet({
			variables: {
				timesheet: {
					...timesheetPayload,
					notes: timesheetPayload?.notes ? timesheetPayload?.notes?.trim() : '',
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					if (onSuccess) {
						onSuccess();
						notify(
							initialValues?.id
								? TIME_SHEET_UPDATE_NOTIFICATION_TEXT
								: TIME_SHEET_ADD_NOTIFICATION_TEXT
						);
						refresh();
						handleClose();
						return;
					}
					notify(
						initialValues?.id
							? TIME_SHEET_UPDATE_NOTIFICATION_TEXT
							: TIME_SHEET_ADD_NOTIFICATION_TEXT
					);
					refresh();
					refetchTime();
					handleClose();
					return;
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}
				if (
					error.message.includes('timesheet_task_id_user_id_org_id_date_key')
				) {
					setIsTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_date_master_project_task_id_user_id_org_id_key'
					)
				) {
					setIsMasterProjectTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_org_id_user_id_date_master_project_task_id_project_id'
					)
				) {
					setIsMasterProjectTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_date_org_task_id_user_id_org_id_key'
					)
				) {
					setIsOrgTaskUniqueViolationError(true);
					return;
				}
				// Check constraint of an insert/update permission has failed
				if (error.message.includes(timesheetApprovedConstraintError)) {
					setIsTimesheetApprovedConstraintError(true);
					return;
				}
			});
	};

	const handleClose = () => {
		setSelectedProject('');
		setOrgTaskCategory('');
		setChoosedTaskDetails(null);
		setIsDayHoursLimitExceed(false);
		setIsTaskUniqueViolationError(false);
		setIsMasterProjectTaskUniqueViolationError(false);
		setIsOrgTaskUniqueViolationError(false);
		setIsZeroWorkingHoursError(false);
		setWorkingHourInputError('');
		setIsWorkingHoursError(false);
		setDate(dayjs().format('YYYY-MM-DD'));
		setIsTimesheetApprovedConstraintError(false);
		setCurrentWeekTimesheetStatus('');
		setTimesheetSubmissionError(false);
		setCreateTaskName(null);
		setShowCreateOption(false);
		setKanbanId(null);
		onClose();
	};

	const validateTimesheetEntryFormValues = async () => {
		const timesheetEntryForm = {} as TimesheetFormValues;
		if (isDayHoursLimitExceed) {
			timesheetEntryForm.working_hours = 'Daily hours limit exceed!';
		}
		if (isTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isMasterProjectTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isOrgTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isZeroWorkingHoursError) {
			timesheetEntryForm.working_hours = 'Zero Hour will not be taken!';
		}
		if (isWorkingHoursError) {
			timesheetEntryForm.working_hours = workingHourInputError;
		}
		if (
			isTimesheetApprovedConstraintError ||
			currentWeekTimesheetStatus === 'approved'
		) {
			timesheetEntryForm.date = 'Timesheet has been approved already!';
		}
		return timesheetEntryForm;
	};

	const handleWeeklyFormSubmit = (formValues: TimesheetFormValues) => {
		if (!formValues) {
			return;
		}

		if (
			timesheetEntries.filter(
				(task) =>
					task.taskId === formValues.task_id &&
					(task.projectId === formValues.project_id ||
						task.projectName === formValues.project_id)
			).length > 0
		) {
			setIsTaskUniqueViolationError(true);
			return;
		}
		const project = projectOptions?.find(
			(project) => project.id === formValues.project_id
		);
		const taskOptions = getTaskOptionRenderer();

		const selectedTask =
			taskOptions && taskOptions.find((task) => task.id === formValues.task_id);

		onSubmit({
			projectId: formValues.project_id,
			projectName: project?.name,
			taskId: selectedTask?.id,
			taskName: selectedTask?.name,
			companyName: project?.companyName,
			taskType: selectedTask?.taskType,
			isBillable: selectedTask?.is_billable,
			timesheetSubmissionId: getTimesheetSubmissionId(weekHoursByday),
			notes: formValues?.notes?.trim(),
		});
		refetchTime();
		handleClose();
		return;
	};

	useEffect(() => {
		if (projectTaskDetail?.projectId) {
			setSelectedProject(projectTaskDetail?.projectId);
			setSelectedTaskCategory(projectTaskDetail?.projectId);
			setChoosedTaskDetails(null);
		}
	}, [projectTaskDetail, setSelectedTaskCategory]);

	const getOptionTaskAutoCompleteIntialValue = (taskId: string) => {
		const taskoptions = getTaskOptionRenderer();
		const task = taskoptions?.find((value) => value.id === taskId) || null;
		setChoosedTaskDetails(task);
	};

	const getOptionDayTypeAutoCompleteIntialValue = (id: string) => {
		const daytypeOptions = getDayTypeOptionRenderer();
		return daytypeOptions?.find((value) => value.id === id) || null;
	};

	const getProjectIntialValue = (projectId: string) => {
		const project = projectOptions?.find((value) => value.id === projectId);
		return project
			? {
					id: project.id,
					name: project.isBillable ? project.name : `${project.name} (NB)`,
			  }
			: null;
	};

	const customValidation = (note: string) => {
		const trimmedNote = note?.trim();
		const hasNote = trimmedNote && trimmedNote?.length >= 10;
		const isNoteRequired = head(
			timesheetSettingsDetails?.timesheet_settings
		)?.is_note_mandatory;

		if (isNoteRequired && !trimmedNote) {
			return 'Required';
		}

		if (isNoteRequired && trimmedNote && !hasNote) {
			return 'Minimum 10 characters are required!';
		}

		return undefined;
	};
	const checkIfProjectTaskSelected = () => {
		if (!selectedProject) {
			return false;
		}
		const project = projectOptions
			?.filter((project) => project.type === PROJECT_TYPE_CATEGORY)
			.find((project) => project?.id === selectedProject);

		return !isEmpty(project) ? true : false;
	};

	const getNewTaskDetails = async (taskId: string) => {
		const refetchData = await refetchProjectTask();
		const task = refetchData?.data?.project_task?.find(
			(task) => task?.id === taskId
		);
		if (task) {
			const projectTasks = {
				id: task?.id,
				name: task.is_billable ? `${task.title}` : `${task.title} (NB)`,
				taskType: PROJECT_TYPE_CATEGORY,
				groupByLabel: PROJECT_TASK_GROUP_LABEL,
				is_billable: task.is_billable,
			};
			setChoosedTaskDetails(projectTasks);
		}
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box
					className={`${formStyles.container} ${timesheetStyles.timesheetFormContainer}`}
				>
					<Box className={formStyles.headerContainer} mb={1}>
						<Typography className={formStyles.heading}>
							{initialValues?.id ? 'EDIT ENTRY' : 'ADD ENTRY'}
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={
							initialValues?.id
								? initialValues
								: {
										task_id:
											choosedTaskDetails?.id || projectTaskDetail?.taskId,
										project_id: selectedProject,
										working_hours: '',
										date: date,
								  }
						}
						onSubmit={
							!isWeeklyTimesheetForm ? handleSubmit : handleWeeklyFormSubmit
						}
						validate={validateTimesheetEntryFormValues}
						mutators={{
							clearTaskId: (args, state, utils) => {
								utils.changeValue(state, 'task_id', () => null);
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, values, form }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<Typography className={formStyles.label}>Date*</Typography>
									<Field
										name='date'
										validate={(value: any) => {
											if (dayjs(value).isValid() === true) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div className={timesheetFormStyles.customFieldContainer}>
												<CustomDateInput
													minDate={userJoinDate ?? false}
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={(value: any) => {
														props.input.onChange(value);
														setIsTimesheetApprovedConstraintError(false);
														setTimesheetSubmissionError(false);
														setCurrentWeekTimesheetStatus('');
														setDate(value);
														setIsTaskUniqueViolationError(false);
														setIsMasterProjectTaskUniqueViolationError(false);
														setIsOrgTaskUniqueViolationError(false);
														if(!projectTaskDetail?.projectId){
															setSelectedProject('')
															setChoosedTaskDetails({})
														}
													}}
													dateFormat={dateFormat}
												/>
												{(isTimesheetApprovedConstraintError ||
													currentWeekTimesheetStatus === 'approved') && (
													<Typography className={timesheetStyles.errorText}>
														{props.meta.error}
													</Typography>
												)}
												{timesheetSubmissionError && (
													<Typography className={timesheetStyles.errorText}>
														{'Timesheet has been submitted already!'}
													</Typography>
												)}
											</div>
										)}
									</Field>

									<Typography className={formStyles.label}>
										Choose Project*
									</Typography>
									<Field name='project_id' validate={required()}>
										{(props: any) => (
											<ThemeProvider theme={autoCompleteTheme}>
												<div
													className={timesheetFormStyles.customFieldContainer}
												>
													<Autocomplete
														id='project_id'
														classes={{
															option: timesheetFormStyles.options,
															groupLabel: timesheetFormStyles.groupLabel,
															inputRoot: timesheetFormStyles.inputRoot,
															noOptions: timesheetFormStyles.noOptions,
															focused: timesheetFormStyles.focused,
															input: timesheetFormStyles.input,
															endAdornment: timesheetFormStyles.endAdornment,
														}}
														disabled={!!projectTaskDetail?.projectId}
														getOptionSelected={(option, value) =>
															option.id === props.input.value
														}
														onChange={(event, newValue) => {
															form.mutators.clearTaskId();
															setKanbanId(null);
															setChoosedTaskDetails({});
															props.input.onChange(newValue?.id);
															setIsTaskUniqueViolationError(false);
															setIsMasterProjectTaskUniqueViolationError(false);
															setIsOrgTaskUniqueViolationError(false);
															setSelectedProject(newValue?.id || '');
															setSelectedTaskCategory(newValue?.id || '');
														}}
														value={getProjectIntialValue(props.input.value)}
														options={
															projectOptions
																? projectOptions.map((project) => ({
																		id: project.id,
																		name: project.isBillable
																			? project.name
																			: `${project.name} (NB)`,
																  }))
																: []
														}
														getOptionLabel={(option) => option?.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																label=''
																placeholder='Search project'
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			classes={{
																				root: timesheetFormStyles.inputAdronment,
																				positionStart:
																					timesheetFormStyles.inputAdronmentPositionStart,
																			}}
																		>
																			<SearchIcon
																				className={
																					timesheetFormStyles.searchIconPositionStart
																				}
																			/>
																		</InputAdornment>
																	),
																}}
															/>
														)}
													/>
												</div>
											</ThemeProvider>
										)}
									</Field>

									<Typography className={formStyles.label}>
										Choose Task*
									</Typography>
									<Field
										initialValue={
											choosedTaskDetails?.id || projectTaskDetail?.taskId
										}
										name='task_id'
										validate={required()}
									>
										{(props: any) => (
											<ThemeProvider theme={autoCompleteTheme}>
												<div
													className={timesheetFormStyles.customFieldContainer}
												>
													<Autocomplete
														key={
															choosedTaskDetails || projectTaskDetail?.taskId
														}
														filterOptions={(options, params) => {
															const filtered = filter(options, params);
															setCreateTaskName(params?.inputValue);
															// condition to check if the entered value is not match with any options
															setShowCreateOption(
																isEmpty(filtered) ? true : false
															);
															return filtered;
														}}
														freeSolo
														selectOnFocus
														clearOnBlur
														handleHomeEndKeys
														PaperComponent={({ children }) => {
															return (
																<Paper>
																	{children}
																	{checkIfProjectTaskSelected() &&
																		showCreateOption && (
																			<Button
																				className={
																					timesheetStyles.addTaskShortcut
																				}
																				fullWidth
																				onMouseDown={() => {
																					setIsCreateTaskFormShown(true);
																				}}
																			>
																				<Box display='flex'>
																					<AddCircleOutlineIcon
																						className={
																							timesheetStyles.addTaskIcon
																						}
																					/>
																					<Box
																						className={
																							timesheetStyles.addTaskLabel
																						}
																						marginTop='1px'
																					>{`Add: ${createTaskName}`}</Box>
																				</Box>
																			</Button>
																		)}
																</Paper>
															);
														}}
														id='task_id'
														classes={{
															option: timesheetFormStyles.options,
															groupLabel: timesheetFormStyles.groupLabel,
															inputRoot: timesheetFormStyles.inputRoot,
															noOptions: timesheetFormStyles.noOptions,
															focused: timesheetFormStyles.focused,
															input: timesheetFormStyles.input,
															endAdornment: timesheetFormStyles.endAdornment,
														}}
														loading={isProjectTaskLoading}
														getOptionSelected={(option: any, value) =>
															option.id === props.input.value
														}
														onChange={(event, newValue) => {
															props.input.onChange(newValue?.id);
															getOptionTaskAutoCompleteIntialValue(
																newValue?.id
															);
															setIsTaskUniqueViolationError(false);
															setIsMasterProjectTaskUniqueViolationError(false);
															setIsOrgTaskUniqueViolationError(false);
														}}
														disabled={!!projectTaskDetail?.taskId}
														value={
															choosedTaskDetails ||
															getOptionTaskAutoCompleteIntialValue(
																props.input.value
															)
														}
														options={getTaskOptionRenderer() || []}
														groupBy={(option) => option.groupByLabel}
														getOptionLabel={(option) => option?.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																label=''
																placeholder='Search your task'
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			classes={{
																				root: timesheetFormStyles.inputAdronment,
																				positionStart:
																					timesheetFormStyles.inputAdronmentPositionStart,
																			}}
																		>
																			<SearchIcon
																				className={
																					timesheetFormStyles.searchIconPositionStart
																				}
																			/>
																		</InputAdornment>
																	),
																}}
															/>
														)}
													/>
													{(isTaskUniqueViolationError ||
														isMasterProjectTaskUniqueViolationError ||
														isOrgTaskUniqueViolationError) && (
														<Typography className={timesheetStyles.errorText}>
															{props.meta.error}
														</Typography>
													)}
												</div>
											</ThemeProvider>
										)}
									</Field>

									{isTrackDayTypeEnabled && (
										<>
											<Typography className={formStyles.label}>
												{`Choose Day Type ${isTrackDayTypeEnabled && '*'}`}
											</Typography>
											<Field name='day_type_id' validate={required()}>
												{(props: any) => (
													<ThemeProvider theme={autoCompleteTheme}>
														<div
															className={
																timesheetFormStyles.customFieldContainer
															}
														>
															<Autocomplete
																id='task_id'
																classes={{
																	option: timesheetFormStyles.options,
																	groupLabel: timesheetFormStyles.groupLabel,
																	inputRoot: timesheetFormStyles.inputRoot,
																	noOptions: timesheetFormStyles.noOptions,
																	focused: timesheetFormStyles.focused,
																	input: timesheetFormStyles.input,
																	endAdornment:
																		timesheetFormStyles.endAdornment,
																}}
																loading={isDayTypeLoading}
																getOptionSelected={(option, value) =>
																	option.id === props.input.value
																}
																onChange={(event, newValue) => {
																	props.input.onChange(newValue?.id);
																}}
																value={getOptionDayTypeAutoCompleteIntialValue(
																	props.input.value
																)}
																options={getDayTypeOptionRenderer() || []}
																getOptionLabel={(option) => option?.name || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search day type'
																		InputProps={{
																			...params.InputProps,
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					classes={{
																						root: timesheetFormStyles.inputAdronment,
																						positionStart:
																							timesheetFormStyles.inputAdronmentPositionStart,
																					}}
																				>
																					<SearchIcon
																						className={
																							timesheetFormStyles.searchIconPositionStart
																						}
																					/>
																				</InputAdornment>
																			),
																		}}
																	/>
																)}
															/>
														</div>
													</ThemeProvider>
												)}
											</Field>
										</>
									)}
									<Typography className={formStyles.label}>
										Notes
										{head(timesheetSettingsDetails?.timesheet_settings)
											?.is_note_mandatory
											? '*'
											: ''}
									</Typography>
									<TextInput
										multiline
										label=''
										source='notes'
										validate={customValidation}
										fullWidth
									/>

									<Box
										className={timesheetFormStyles.timesheetFormActionContainer}
										mt={4}
									>
										<Box>
											{!isWeeklyTimesheetForm && (
												<Field name='working_hours'>
													{(props) => (
														<div>
															<TimeInput
																name={props.input.name}
																value={props.input.value}
																onChange={(e) => {
																	props.input.onChange(e);
																	setIsDayHoursLimitExceed(false);
																	setIsZeroWorkingHoursError(false);
																	setWorkingHourInputError('');
																	setIsWorkingHoursError(false);
																}}
																isError={isWorkingHoursError}
															/>
															<Typography className={timesheetStyles.errorText}>
																{props.meta.error}
															</Typography>
														</div>
													)}
												</Field>
											)}
										</Box>
										<Box display='flex' alignItems='center'>
											<Button
												onClick={handleClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine || timesheetSubmissionError
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid || pristine || timesheetSubmissionError
												}
												type='submit'
											>
												{initialValues?.id ? `Update` : `Save`}
											</Button>
										</Box>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
			{isCreateTaskFormShown && (
				<CreateTaskForm
					boardId={kanbanId || null}
					TimesheetProjectId={selectedProject || null}
					open={isCreateTaskFormShown}
					mileStoneId={''}
					onClose={() => {
						setIsCreateTaskFormShown(false);
					}}
					onSuccess={() => {}}
					isEdit={false}
					timesheetTaskName={createTaskName || null}
					onCreateTask={(value: any) => {
						if (value) {
							getNewTaskDetails(value);
						}
					}}
				/>
			)}
		</ThemeProvider>
	);
};

export default TimesheetGlobalForm;
