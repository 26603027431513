import React, { useState } from 'react';

import {
	Box,
	Typography,
	Button,
	Tooltip,
	IconButton,
	ThemeProvider,
	TextField,
	makeStyles,
} from '@material-ui/core';

import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../../Layout/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import { tableListStyle } from '../../settingsStyle';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import { UserEmailGroupForm } from './UserEmailGroupForm.component';
import { useNotify, useRefresh } from 'react-admin';
import {
	useDeleteUserEmailGroupMutation,
	useGetUserEmailGroupQuery,
} from '../../../../generated/graphql';
import { UserEmailMappingForm } from '../../../../modules/Settings/HRMS/UserEmailGroup/UserEmailMappingForm.component';
import WarningModal from '../../../../SharedComponents/WarningModal.component';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import {
	DELETE_GROUP_FAILURE_MESSAGE,
	DELETE_SUCCESS_MESSAGE,
	DELETE_WARNING_MESSAGE,
} from './constant';
import { WARNING_MODAL_MESSAGE } from '../../Project/constant';

const useStyles = makeStyles(() => ({
	name: {
		marginTop: '15px',
		marginLeft: '-80px',
		marginRight: '40px',
	},
}));

interface Group {
	id?: string;
	name: string;
	description?: string | null | undefined;
}

export const EmailUserGroupManagement = () => {
	const emailGroupStyles = tableListStyle();

	const modalFormStyles = modalFormStyle();
	const ellipsisStyles = ellipsisStyle();
	const datagridStyles = datagridStyle();

	const refresh = useRefresh();
	const notify = useNotify();
	const style = useStyles();

	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isUserEmailMappingFormShown, setIsUserEmailMappingFormShown] =
		useState(false);
	const [isUserEmailFormShown, setIsUserEmailFormShown] = useState(false);
	const [deleteId, setDeleteId] = useState<string>();

	const [isGroupEdit, setIsGroupEdit] = useState(false);
	const [selectedData, setSelectedData] = useState<Group>();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [searchName, setSearchName] = useState('');

	const [deleteEmailGroup] = useDeleteUserEmailGroupMutation();

	const { data: userEmailGroup, refetch: refetchEmailGroup } =
		useGetUserEmailGroupQuery({
			variables: {
				name: `%${searchName || ''}%`,
			},
			fetchPolicy: 'network-only',
		});

	const deleteGroup = () => {
		deleteEmailGroup({
			variables: {
				id: deleteId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(DELETE_SUCCESS_MESSAGE);
					refetchEmailGroup();
					refresh();

					setIsDeleteModalShown(false);
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
				notify(DELETE_GROUP_FAILURE_MESSAGE, 'warning');
			});
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<Box>
					<ThemeProvider theme={searchBarTheme}>
						<form onSubmit={(event) => event.preventDefault()}>
							<Box width='250px'>
								<TextField
									placeholder='Search Group'
									label={''}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(e: any) => setSearchName(e?.target?.value)}
								/>
							</Box>
						</form>
					</ThemeProvider>
				</Box>
				<Box mb={2} display='flex' justifyContent='flex-end'>
					<Button
						variant='contained'
						className={modalFormStyles.saveButton}
						onClick={() => {
							setIsUserEmailFormShown(true);
							setIsGroupEdit(false);
						}}
					>
						Add Group
					</Button>
				</Box>
			</Box>
			<Box>
				{userEmailGroup && userEmailGroup?.user_email_group?.length > 0
					? userEmailGroup?.user_email_group?.map((group) => (
							<Box className={emailGroupStyles.Container}>
								<Box display='flex' alignItems='center'>
									<Box display='flex' mr={1}>
										<Typography className={emailGroupStyles.label}>
											User Email Group
										</Typography>
										<Tooltip placement='right' title={`${group?.name}`}>
											<Typography
												className={`${emailGroupStyles.rowContent} ${ellipsisStyles?.ellipsis} ${style.name}`}
											>
												{group?.name}
											</Typography>
										</Tooltip>
									</Box>
								</Box>

								<Box style={{ marginLeft: '-225px' }}>
									<Typography className={emailGroupStyles.label}>
										Description
									</Typography>
									<Tooltip
										placement='right'
										title={`${group?.description ? group?.description : '--'}`}
									>
										<Typography
											className={`${emailGroupStyles.rowContent} ${ellipsisStyles?.ellipsis}`}
										>
											{group?.description ? group?.description : '--'}
										</Typography>
									</Tooltip>
								</Box>

								<Box style={{ marginLeft: '-85px' }}>
									<div className={emailGroupStyles.employeesContainer}>
										<PeopleOutlineIcon
											className={emailGroupStyles.peoplesIcon}
										/>
										<Typography
											className={`${emailGroupStyles.rowContent} ${emailGroupStyles.employeesCount}`}
										>
											{
												group?.user_email_group_mappings_aggregate?.aggregate
													?.count
											}
										</Typography>
									</div>
								</Box>
								<Box className={emailGroupStyles.actionContainer}>
									<Box className={emailGroupStyles.actions}>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${emailGroupStyles.IconButton}`}
										>
											<PersonAddIcon
												className={datagridStyles.editIcon}
												onClick={() => {
													setIsUserEmailMappingFormShown(true);
													setSelectedData(group);
												}}
											/>
										</IconButton>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${emailGroupStyles.IconButton}`}
										>
											<EditIcon
												className={datagridStyles.editIcon}
												onClick={() => {
													setIsUserEmailFormShown(true);
													setSelectedData(group);
													setIsGroupEdit(true);
												}}
											/>
										</IconButton>

										<IconButton
											className={`${datagridStyles.listEditIconButton} ${emailGroupStyles.IconButton} ${emailGroupStyles.deleteIconButton}`}
										>
											<DeleteIcon
												className={datagridStyles.editIcon}
												onClick={() => {
													if (
														group?.user_email_group_mappings_aggregate
															?.aggregate?.count! > 0
													) {
														setIsWarningModalShown(true);
														return;
													}
													setIsDeleteModalShown(true);
													setDeleteId(group.id);
												}}
											/>
										</IconButton>
									</Box>
								</Box>
							</Box>
					  ))
					: 'No Results Found...'}
			</Box>
			<UserEmailGroupForm
				open={isUserEmailFormShown}
				onClose={() => setIsUserEmailFormShown(false)}
				refetch={() => {
					refetchEmailGroup();
				}}
				initialValues={selectedData}
				isEditMutation={isGroupEdit}
			/>
			<UserEmailMappingForm
				open={isUserEmailMappingFormShown}
				onClose={() => {
					setIsUserEmailMappingFormShown(false);
				}}
				groupId={selectedData?.id}
				refetch={() => {
					refetchEmailGroup();
				}}
			/>

			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setIsDeleteModalShown(false);
				}}
				confirmationMessage={DELETE_WARNING_MESSAGE}
				onDelete={() => {
					deleteGroup();
				}}
			/>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
				}}
				warningMessage={WARNING_MODAL_MESSAGE}
			/>
		</>
	);
};

export default EmailUserGroupManagement;
