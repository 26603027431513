import React, { useContext, useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	FunctionField,
	Loading,
	ReferenceField,
	useListContext,
	useQuery,
} from 'react-admin';
import {
	makeStyles,
	Box,
	Button,
	Tooltip,
	IconButton,
	Chip,
	TextField as TextComponent,
	Checkbox,
	Typography,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ThemeProvider } from '@material-ui/core/styles';
import AssetCard from './AssestCard.component';
import AddIcon from '@material-ui/icons/Add';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import AssetFilterFormBody from './AssetFilterFormBody.component';
import AddAssetModal from './AddAssetModal.component';
import { GridIcon, ListIcon, SearchIcon } from '../../assets/SvgIcons';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import {
	globalStyles,
	autocompleteStyle,
	ellipsisStyle,
	dropDownEllipsisStyle,
} from '../../Layout/styles';
import { GetWarrantyState } from './AssetDetailsPage/AssetWarranty.component';
import { UserProfileContext } from '../../App';
import { Autocomplete } from '@material-ui/lab';
import {
	customAutoCompleteSearchBarTheme,
	searchBarTheme,
} from '../../Layout/Theme.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import {
	GetAssetDetailsToExportQuery,
	useGetActiveUsersByLimitQuery,
	useGetAssetDetailsToExportQuery,
	useGetAssetIdToFilterQuery,
	useGetOrgLocationsQuery,
} from '../../generated/graphql';
import { EXPORT_ASSET_COLUMNS } from './Constant';
import { exportToCsv } from '../../Utils/string.util';
import { AssetListPagination } from './AssetListPagination';
import ExportButtonWithLoader from '../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles({
	toolbarContainer: {
		position: 'absolute',
		right: '0',
	},
	listStyle: {
		marginTop: '-50px',
	},
	filterContainer: {
		marginTop: '-72px',
		marginBottom: '5px',
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 18%)',
		maxWidth: 1220,
		margin: 'auto',
		gap: '18px',
		marginTop: '-40px',
		marginBottom: '20px',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		marginLeft: '10px',
		width: '140px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
	},
	searchInput: {
		paddingRight: '10px',
		paddingTop: '2px',
		color: '#7C7C7C',
	},
	input: {
		width: '250px !important',
		height: '36px',
		fontSize: '12px',
		fontFamily: 'Manrope-Medium',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: ' 4px',
		opacity: 1,
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
});

interface ListActionProps {
	total?: number;
}

interface FilterProps {
	isDrawerOpen: boolean;
	drawerClose: () => void;
}

interface Type {
	id: any;
	type: any;
}
interface Props {}

const assetStatusOption = [
	{
		label: 'In Stock',
		value: 'In Stock',
	},
	{
		label: 'In Use',
		value: 'In Use',
	},
];

export const AssetList = (props: Props) => {
	const classes = useStyles();
	const commonStyles = globalStyles();
	const styles = ellipsisStyle();
	const ellipsis = dropDownEllipsisStyle();
	const dropDownStyles = autocompleteStyle();
	const { permissions } = useContext<any>(UserProfileContext);
	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [openAddDialogue, setOpenAddDialogue] = useState(false);
	const [assetsCount, setAssetsCount] = useState(0);
	const { filterState, dispatchFilter } = useContext<any>(UserProfileContext);
	const [isExportTriggered, setIsExportTriggered] = useState(false);

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();
	const { data: orgLocations } = useGetOrgLocationsQuery();

	const isAllAssetStatusSelected =
		assetStatusOption?.length === filterState?.assetStatusFilter?.length || 0
			? true
			: false;

	const isAllAssetLocationsSelected =
		orgLocations?.org_location?.length ===
			filterState?.assetLocationFilter?.length || 0
			? true
			: false;

	const isAllUsersSelected =
		activeUsers?.user?.length === filterState?.assetUsedByFilter?.length || 0
			? true
			: false;

	const statusFilterPayload = isAllAssetStatusSelected
		? {}
		: filterState?.assetStatusFilter &&
		  filterState?.assetStatusFilter?.length > 0
		? {
				_is_null:
					filterState?.assetStatusFilter?.filter(
						(assetStatus: any) => assetStatus?.value === 'In Use'
					).length > 0
						? false
						: true,
		  }
		: {};
	const usedByFilterPayload = isAllUsersSelected
		? {}
		: filterState?.assetUsedByFilter &&
		  filterState?.assetUsedByFilter?.length > 0
		? { _in: filterState?.assetUsedByFilter?.map((user: any) => user.value) }
		: {};

	const { data: filteredAssetIds } = useGetAssetIdToFilterQuery({
		variables: {
			assetFilter: {
				used_by: { ...statusFilterPayload, ...usedByFilterPayload },
				location_id: isAllAssetLocationsSelected
					? {}
					: filterState?.assetLocationFilter &&
					  filterState?.assetLocationFilter?.length > 0
					? {
							_in: filterState?.assetLocationFilter.map(
								(assetLocation: any) => assetLocation.value
							),
					  }
					: {},
			},
		},
		fetchPolicy: 'network-only',
	});

	const { data: assetTypeList, refetch: refetchList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_type',
		payload: {},
	});

	 useGetAssetDetailsToExportQuery({
		variables: {
			assetFilter: {
				name: {
					_ilike: `%${filterState?.assetSearchText}%` 
			},
				used_by: { ...statusFilterPayload, ...usedByFilterPayload },
				location_id: isAllAssetLocationsSelected
					? {}
					: filterState?.assetLocationFilter &&
					  filterState?.assetLocationFilter?.length > 0
					? {
							_in: filterState?.assetLocationFilter.map(
								(assetLocation: any) => assetLocation.value
							),
					  }
					: {},
			},
		},
		onCompleted: (data) => {
			setIsExportTriggered(false);
			exportAssets(data);
		},
		fetchPolicy: 'network-only',
		skip: !isExportTriggered,
	});

	useEffect(() => {
		if (!filterState?.assetStatusFilter) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER,
				payload: [
					{
						label: 'In Use',
						value: 'In Use',
					},
				],
			});
		}
	}, [dispatchFilter, filterState]);

	const AssetGrid = () => {
		const { data, ids, loading } = useListContext();
		if (loading) {
			return <Loading />;
		}
		return ids ? (
			<Box className={classes.gridList}>
				{ids.map((id, index) => (
					<AssetCard typeList={assetTypeList} key={index} data={data[id]} />
				))}
			</Box>
		) : null;
	};

	const getAppliedFilters = (
		filter: any,
		removeFilter: any,
		filterValues: any,
		setFilters: any,
		displayedFilters: any
	) => {
		switch (filter) {
			case 'type':
				return (
					filterValues?.type?.length > 0 &&
					filterValues?.type?.map((type: string, index: number) => (
						<ReferenceField
							link={false}
							source='id'
							reference='asset_type'
							label='Full Name'
							resource='asset_type'
							basePath={''}
							record={{ id: type }}
						>
							<FunctionField
								label='Full Name'
								render={(record: any) => (
									<>
										<Chip
											key={index}
											label={`${record?.type || ''}`}
											variant='outlined'
											onDelete={() => {
												filterValues.type.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'used_by':
				return (
					filterValues?.used_by.length > 0 &&
					filterValues?.used_by.map((used_by: string, index: number) => (
						<ReferenceField
							link={false}
							source='id'
							reference='user'
							label='Full Name'
							resource='user'
							record={{ id: used_by }}
							basePath={'/user'}
						>
							<FunctionField
								label='Full Name'
								render={(record: any) => (
									<>
										<Chip
											key={index}
											label={`${record?.first_name || ''} ${
												record?.last_name || ''
											}`}
											variant='outlined'
											onDelete={() => {
												filterValues.used_by.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'managed_by':
				return (
					filterValues?.managed_by.length > 0 &&
					filterValues?.managed_by.map((managed_by: string, index: number) => (
						<ReferenceField
							link={false}
							source='id'
							reference='user'
							label='Full Name'
							resource='user'
							record={{ id: managed_by }}
							basePath={'/user'}
						>
							<FunctionField
								label='Full Name'
								render={(record: any) => (
									<>
										<Chip
											key={index}
											label={`${record?.first_name || ''} ${
												record?.last_name || ''
											}`}
											variant='outlined'
											onDelete={() => {
												filterValues.managed_by.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'department':
				return (
					filterValues?.department.length > 0 &&
					filterValues?.department.map((department: string, index: number) => (
						<ReferenceField
							link={false}
							source='id'
							reference='department'
							resource='department'
							record={{ id: department }}
						>
							<FunctionField
								render={(record: any) => (
									<>
										<Chip
											key={index}
											label={`${record?.name}`}
											variant='outlined'
											onDelete={() => {
												filterValues.department.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	const getAssetFilterStatusLimits = (value: Number) => {
		if (!value) {
			return;
		}
		return (
			<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState && filterState?.assetStatusFilter?.length === 1
					? filterState?.assetStatusFilter[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	const getAssetLocationFilterLimits = (value: Number) => {
		if (!value) {
			return;
		}
		return (
			<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState && filterState?.assetLocationFilter?.length === 1
					? filterState?.assetLocationFilter[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	const getAssetUsedByFilterLimits = (value: Number) => {
		if (!value) {
			return;
		}
		return (
			<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
				{filterState && filterState?.assetUsedByFilter?.length === 1
					? filterState?.assetUsedByFilter[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	const handleSelectAllStatus = (isSelected: boolean) => {
		if (isSelected) {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER,
				payload: assetStatusOption,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectAllLocations = (isSelected: boolean) => {
		if (isSelected) {
			const allLocations = orgLocations
				? orgLocations.org_location.map((orgLocation) => ({
						value: orgLocation.id,
						label: orgLocation.name,
				  }))
				: [];
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_LOCATION_FILTER,
				payload: allLocations,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_LOCATION_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectAllUsers = (isSelected: boolean) => {
		if (isSelected) {
			const allUsers = activeUsers
				? activeUsers.user.map((user) => ({
						value: user.id,
						label: user.name,
				  }))
				: [];
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_USED_BY_FILTER,
				payload: allUsers,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_ASSET_USED_BY_FILTER,
				payload: [],
			});
		}
	};

	const AssetListFilter = (props: FilterProps) => {
		const { isDrawerOpen, drawerClose } = props;

		return (
			<>
				<DrawerFilterContainer open={isDrawerOpen} close={drawerClose}>
					<AssetFilterFormBody />
				</DrawerFilterContainer>
			</>
		);
	};

	const exportAssets = (data: GetAssetDetailsToExportQuery) => {
		if (!data) {
			return;
		}
		const assetRows = data.asset.map((asset) => {
			const softwares = asset?.asset_softwares.map((software) => software.name);
			return [
				asset.asset_id,
				asset.name,
				asset?.asset_type?.type || '',
				asset.user?.full_name || '',
				asset.userByManagedBy?.full_name || '',
				asset.org_location?.name || '',
				asset?.warranty_expiration_date || '',
				asset?.manufacturer || '',
				asset?.processor || '',
				asset?.memory || '',
				asset?.hard_drive || '',
				asset?.mac_address || '',
				asset?.product_number || '',
				asset?.in_repair ? 'Yes' : 'No',
				`${asset?.cost || ''}${asset.organization?.currency.symbol || ''}`,
				softwares,
				asset?.asset_note?.note || '',
			];
		});
		const assetExportData = [EXPORT_ASSET_COLUMNS, ...assetRows];
		exportToCsv('Asset_Export.csv', assetExportData);
	};

	const AssetListActions = () => {
		return (
			<>
				<Box className={classes.toolbarContainer}>
					<Box display='flex' alignItems='center'>
						<Tooltip title='Export' placement='top'>
							<ExportButtonWithLoader
								generateCSV={() => {
									setIsExportTriggered(true);
								}}
								loading={isExportTriggered}
								isDisabled={!assetsCount}
							/>
						</Tooltip>
						<Box marginLeft='10px'>
							<IconButton
								onClick={() => {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_ASSET_VIEW_FILTER,
										payload: false,
									});
								}}
								className={[
									commonStyles.iconButton,
									!filterState?.isAssetListView
										? commonStyles.activeIconButton
										: '',
								].join(' ')}
							>
								<GridIcon />
							</IconButton>
						</Box>
						<IconButton
							onClick={() => {
								dispatchFilter({
									type: FILTER_STATE_ACTIONS.UPDATE_ASSET_VIEW_FILTER,
									payload: true,
								});
							}}
							className={[
								commonStyles.iconButton,
								filterState?.isAssetListView
									? commonStyles.activeIconButton
									: '',
							].join(' ')}
						>
							<ListIcon />
						</IconButton>
						<Tooltip title={'Filter'}>
							<IconButton
								onClick={() => setIsDrawerShown(true)}
								className={commonStyles.filterButton}
							>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						{permissions?.asset?.insert_permissions && (
							<Button
								startIcon={<AddIcon />}
								className={classes.addButton}
								onClick={() => setOpenAddDialogue(true)}
							>
								Add Asset
							</Button>
						)}
					</Box>
				</Box>
			</>
		);
	};

	const assetDropDownFilters = () => (
		<Box display='flex' alignItems='center'>
			<form onSubmit={(event) => event.preventDefault()}>
				<ThemeProvider theme={searchBarTheme}>
					<Box width='250px'>
						<TextComponent
							placeholder='Search Assets'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchIcon />,
							}}
							onChange={(e) => {
								dispatchFilter({
									type: FILTER_STATE_ACTIONS.UPDATE_ASSET_SEARCH_TEXT,
									payload: e?.target?.value,
								});
							}}
							value={filterState?.assetSearchText || ''}
						/>
					</Box>
				</ThemeProvider>
			</form>
			<Box width={'182px'}>
				<Box marginBottom='6px' marginLeft='10px'>
					<Box className={classes.dropDownFilterLabel}>Status</Box>
					<Autocomplete
						multiple
						limitTags={0}
						value={
							filterState?.assetStatusFilter
								? filterState?.assetStatusFilter
								: []
						}
						selectOnFocus={true}
						disablePortal
						fullWidth={false}
						onChange={(event, value, reason) => {
							if (!value) {
								return;
							}
							if (reason === 'select-option' || reason === 'remove-option') {
								if (value.find((option) => option.value === 'All')) {
									handleSelectAllStatus(!isAllAssetStatusSelected);
									return;
								} else {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER,
										payload: value,
									});
									return;
								}
							} else if (reason === 'clear') {
								dispatchFilter({
									type: FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER,
									payload: [],
								});
								return;
							}
						}}
						options={[
							{
								label: 'All',
								value: 'All',
							},
							...assetStatusOption,
						]}
						disableCloseOnSelect={true}
						getLimitTagsText={getAssetFilterStatusLimits}
						ChipProps={{ style: { display: 'none' } }}
						renderInput={(params) => (
							<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
								<TextComponent
									{...params}
									label=''
									placeholder={
										filterState?.assetStatusFilter &&
										filterState?.assetStatusFilter?.length > 0
											? ''
											: 'Search Status'
									}
								/>
							</ThemeProvider>
						)}
						renderOption={(option: any | null, { selected }) => {
							const selectAllProps =
								option.value === 'All' // To control the state of 'select-all' checkbox
									? { checked: isAllAssetStatusSelected }
									: {};
							return (
								<>
									<Checkbox
										icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
										checkedIcon={<CheckBoxIcon fontSize='small' />}
										style={{ marginRight: 8 }}
										checked={selected || false}
										color='primary'
										{...selectAllProps}
									/>
									<Box style={{ fontSize: '12px' }}>{`${option?.label}`}</Box>
								</>
							);
						}}
						getOptionLabel={(option: any) => `${option?.label}`}
						getOptionSelected={(option: any, value: any) =>
							option.value === value.value
						}
					/>
				</Box>
			</Box>
			<Box width={'182px'} marginLeft={'8px'}>
				<Box marginBottom='6px'>
					<Box className={classes.dropDownFilterLabel}>Location</Box>
					<form onSubmit={(event) => event.preventDefault()}>
						<Autocomplete
							multiple
							limitTags={0}
							value={filterState?.assetLocationFilter || []}
							selectOnFocus={true}
							disablePortal
							fullWidth={false}
							onChange={(event, value, reason) => {
								if (!value) {
									return;
								}
								if (reason === 'select-option' || reason === 'remove-option') {
									if (value.find((option) => option.value === 'All')) {
										handleSelectAllLocations(!isAllAssetLocationsSelected);
										return;
									} else {
										dispatchFilter({
											type: FILTER_STATE_ACTIONS.UPDATE_ASSET_LOCATION_FILTER,
											payload: value,
										});
										return;
									}
								} else if (reason === 'clear') {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_ASSET_LOCATION_FILTER,
										payload: [],
									});
									return;
								}
							}}
							options={
								orgLocations
									? [
											{ label: 'All', value: 'All' },
											...orgLocations.org_location.map((orgLocation) => ({
												value: orgLocation.id,
												label: orgLocation.name,
											})),
									  ]
									: []
							}
							disableCloseOnSelect={true}
							getLimitTagsText={getAssetLocationFilterLimits}
							ChipProps={{ style: { display: 'none' } }}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextComponent
										{...params}
										label=''
										placeholder={
											filterState?.assetLocationFilter &&
											filterState?.assetLocationFilter?.length > 0
												? ''
												: 'Search Location'
										}
									/>
								</ThemeProvider>
							)}
							renderOption={(option: any | null, { selected }) => {
								const selectAllProps =
									option.value === 'All' // To control the state of 'select-all' checkbox
										? { checked: isAllAssetLocationsSelected }
										: {};
								return (
									<>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>
										<Box style={{ fontSize: '12px' }}>{`${option?.label}`}</Box>
									</>
								);
							}}
							getOptionLabel={(option: any) => `${option?.label}`}
							getOptionSelected={(option: any, value: any) =>
								option.value === value.value
							}
						/>
					</form>
				</Box>
			</Box>
			<Box width={'182px'} marginLeft={'8px'}>
				<Box marginBottom='6px'>
					<Box className={classes.dropDownFilterLabel}>Used By</Box>
					<form onSubmit={(event) => event.preventDefault()}>
						<Autocomplete
							multiple
							limitTags={0}
							value={filterState?.assetUsedByFilter || []}
							selectOnFocus={true}
							disablePortal
							fullWidth={false}
							onChange={(event, value, reason) => {
								if (!value) {
									return;
								}
								if (reason === 'select-option' || reason === 'remove-option') {
									if (value.find((option) => option.value === 'All')) {
										handleSelectAllUsers(!isAllUsersSelected);
										return;
									} else {
										dispatchFilter({
											type: FILTER_STATE_ACTIONS.UPDATE_ASSET_USED_BY_FILTER,
											payload: value,
										});
										return;
									}
								} else if (reason === 'clear') {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_ASSET_USED_BY_FILTER,
										payload: [],
									});
									return;
								}
							}}
							options={
								activeUsers
									? [
											{ label: 'All', value: 'All' },
											...activeUsers.user.map((user) => ({
												value: user.id,
												label: user.name,
											})),
									  ]
									: []
							}
							disableCloseOnSelect={true}
							getLimitTagsText={getAssetUsedByFilterLimits}
							ChipProps={{ style: { display: 'none' } }}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextComponent
										{...params}
										label=''
										placeholder={
											filterState?.assetUsedByFilter &&
											filterState?.assetUsedByFilter?.length > 0
												? ''
												: 'Search User'
										}
									/>
								</ThemeProvider>
							)}
							renderOption={(option: any | null, { selected }) => {
								const selectAllProps =
									option.value === 'All' // To control the state of 'select-all' checkbox
										? { checked: isAllUsersSelected }
										: {};
								return (
									<>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>

										<Tooltip
											title={`${option?.label || '- -'}`}
											placement='right'
										>
											<Typography className={`${ellipsis.ellipsis}`}>
												{`${option?.label || '- -'}`}
											</Typography>
										</Tooltip>
									</>
								);
							}}
							getOptionLabel={(option: any) => `${option?.label}`}
							getOptionSelected={(option: any, value: any) =>
								option.value === value.value
							}
						/>
					</form>
				</Box>
			</Box>
		</Box>
	);

	return (
		<>
			<div className='headingName'>ASSETS({assetsCount})</div>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				marginTop='27px'
			>
				{assetDropDownFilters()}
				<AssetListActions />
			</Box>
			<List
				empty={false}
				filters={
					<Box className={classes.filterContainer}>
						<Box display='flex' flexDirection='column' width='100%'>
							<AppliedFiltersContainer
								{...props}
								totalCount={(total: number) => setAssetsCount(total)}
							>
								{getAppliedFilters}
							</AppliedFiltersContainer>
						</Box>
					</Box>
				}
				filter={{
					id:
						filteredAssetIds && filteredAssetIds?.asset?.length > 0
							? filteredAssetIds?.asset.map((asset) => asset?.id)
							: [],
					name: `%${filterState?.assetSearchText || ''}%`,
				}}
				pagination={
					<AssetListPagination assetData={assetTypeList?.length ?? -1} />
				}
				perPage={15}
				actions={
					<AssetListFilter
						isDrawerOpen={isDrawerShown}
						drawerClose={() => {
							setIsDrawerShown(false);
						}}
					/>
				}
				exporter={false}
				sort={{ field: 'created_at', order: 'DESC' }}
				bulkActionButtons={false}
				basePath={'/asset'}
				resource={'asset'}
				component='div'
				title={' '}
			>
				{!filterState?.isAssetListView ? (
					<AssetGrid />
				) : (
					<Datagrid rowClick='edit' className={classes.listStyle}>
						<TextField source='name' label='ASSET NAME' />
						<TextField source='asset_id' label='ASSET ID' />
						<ReferenceField
							link={false}
							source='type'
							reference='asset_type'
							label='Asset Type'
							sortable={false}
						>
							<FunctionField
								label='Full Name'
								sortBy='first_name'
								sortByOrder='ASC'
								render={(record: any) => `${record?.type}`}
							/>
						</ReferenceField>
						<FunctionField
							label='WARRANTY'
							render={(record: any) =>
								record?.warranty_expiration_date
									? GetWarrantyState(record?.warranty_expiration_date)
									: 'Warranty -NA-'
							}
						/>
					</Datagrid>
				)}
			</List>
			<AddAssetModal
				open={openAddDialogue}
				closeModal={() => setOpenAddDialogue(false)}
				refetch={refetchList}
			/>
		</>
	);
};
