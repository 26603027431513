import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    useGetList,
    maxLength,
    TopToolbar,
    ListButton
} from 'react-admin';
import { Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const DepartmentCreateActions = ({ basePath }) => {
    return (
      <TopToolbar>
        <ListButton
          basePath={basePath}
          label="Department List"
          icon={<ChevronLeft />}
        />
      </TopToolbar>
    );
  };

export const CreateDepartment = props => {
    const { data: organizations } = useGetList("organization");
    const orgIds = Object.keys(organizations);
    const selectedOrgId = orgIds.length === 0 ? "" : orgIds[0];
 return(
    <Create title="Add Department"{...props} actions={<DepartmentCreateActions />}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), maxLength(10)]} />
            <TextInput source="description" validate={required()} />
            <Box display="none">
            <ReferenceInput source="org_id" reference="organization" label="Organization"><SelectInput resettable={true} initialValue={selectedOrgId.length ? selectedOrgId : ""} /></ReferenceInput>
            </Box>
        </SimpleForm>
    </Create>
)};