import React, { useState, useEffect } from 'react';
import { useMutation, useNotify, Loading } from 'react-admin';

import {
	CrmTaskKanbanColumnView,
	CrmTaskKanbanModel,
	CrmTaskCardView,
} from './CrmTasksKanban.model';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_CRM_TASK_KANBAN, GET_CRM_TASK } from './CrmTasks.gql';
import { Kanban } from '../Kanban/Kanban.component';
import dayjs from 'dayjs';
import head from 'lodash/head';
import {
	Typography,
	makeStyles,
	Box,
	Avatar,
	Tooltip,
} from '@material-ui/core';
import { ellipsisStyle } from '../../Layout/styles';
import CrmTaskForm from './CrmTaskForm.component';
import { Task } from './CrmTasksKanban.model';

interface Props {
	searchParam: string;
	addTask: (kanbanColumnId: string) => void;
}

const useStyles = makeStyles(() => ({
	cardContainer: {
		width: '100%',
		minHeight: '104px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	cardTitleContainer: {
		width: '100%',
		padding: '2px 14px 0px 14px',
	},
	cardTitle: {
		fontFamily: 'Manrope-semibold',
		fontSize: '12px',
		color: '#292929',
		width: '100px',
	},
	contentContainer: {
		width: '100%',
		marginTop: '5px',
		height: '38px',
		padding: '0px 14px 0px 14px',
	},
	priorityContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '6px 10px 6px 10px',
		height: '20px',
		display: 'flex',
		alignItems: 'center',
	},
	priorityHigh: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	priorityMedium: {
		backgroundColor: '#EBF3FF',
		color: '#4285F4',
	},
	priorityLow: {
		backgroundColor: '#E9F7EC',
		color: '#34A853',
	},
	crmTaskInfoContainer: {
		height: '38px',
		position: 'relative',
		bottom: '-12px',
		borderTop: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
		padding: '0px 14px 0px 14px',
	},
	opportunityName: {
		fontFamily: 'Manrope-semibold',
		fontSize: '12px',
		color: '#6F6F6F',
	},
	bdOwnerPic: {
		width: '22px',
		height: '22px',
		fontSize: '12px',
		marginRight: '12px',
		cursor: 'pointer',
	},
	dueDate: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		color: '#4285F4',
	},
	contactName: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#6F6F6F',
		marginTop: '14px',
	},
}));

interface Kanban {
	id: string;
	state: string;
}

interface Crm {
	id: string;
}

interface CrmTags {
	crm_tags: Crm;
}

export const CrmTasksKanban = ({ searchParam, addTask }: Props) => {
	const [kanbanColumnViews, setKanbanColumnViews] = useState<
		CrmTaskKanbanColumnView[]
	>([]);
	const [crmTaskId, setCrmTaskId] = useState<string>('');
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [crmTask, setCrmTask] = useState<Task>({
		name: '',
	});
	const classes = useStyles();
	const [mutate] = useMutation();
	const notify = useNotify();
	const toolTipStyles = ellipsisStyle();

	const { data: kanbanBoard, loading } = useSubscription<CrmTaskKanbanModel>(
		GET_CRM_TASK_KANBAN,
		{
			variables: {
				taskName: `%${searchParam}%`,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: crmTaskList, refetch: refetchCrmTask } = useQuery(
		GET_CRM_TASK,
		{
			variables: {
				id: crmTaskId ? crmTaskId : null,
			},
		}
	);

	useEffect(() => {
		if (!crmTaskList?.crm_tasks_by_pk) {
			return;
		}
		setCrmTask({
			id: crmTaskList.crm_tasks_by_pk?.id,
			kanbanCardId: crmTaskList.crm_tasks_by_pk?.crm_task_kanban_cards[0]?.id,
			name: crmTaskList.crm_tasks_by_pk?.name,
			description: crmTaskList.crm_tasks_by_pk?.description,
			activity_type_id: crmTaskList.crm_tasks_by_pk?.activity_type_id,
			opportunity_id: crmTaskList.crm_tasks_by_pk?.opportunity_id,
			priority: crmTaskList.crm_tasks_by_pk?.priority,
			status: crmTaskList.crm_tasks_by_pk?.crm_task_kanban_cards[0]?.column_id,
			due_date: crmTaskList.crm_tasks_by_pk?.due_date,
			owner_id: crmTaskList.crm_tasks_by_pk?.user?.id,
			tag_id: crmTaskList.crm_tasks_by_pk?.crm_task_tag_mappings.map(
				(val: CrmTags) => {
					return val?.crm_tags?.id;
				}
			),
			remind_at: crmTaskList.crm_tasks_by_pk?.remind_at,
		});
	}, [crmTaskList]);

	useEffect(() => {
		if (!kanbanBoard) {
			return;
		}
		const columns = head(kanbanBoard?.kanban_board)?.kanban_columns;
		const kanbanColumns =
			columns &&
			columns.length > 0 &&
			columns.map((column) => ({
				...column,
				cards: column?.cards.map((card, index) => ({
					name: card?.crm_task?.name,
					priority: card?.crm_task?.priority,
					id: card.id,
					ordinal: card.ordinal,
					columnId: card.column_id,
					index,
					bdOwnerName: card?.crm_task?.user?.full_name,
					bdOwnerPic: card?.crm_task?.user?.profile_pic,
					opportunityName: card?.crm_task?.opportunity?.name || '- -',
					contact: `${
						card?.crm_task?.opportunity?.crm_contact?.first_name || '-'
					} ${card?.crm_task?.opportunity?.crm_contact?.last_name || '-'}`,
					due_date: card?.crm_task?.due_date,
					taskId: card?.crm_task?.id,
				})),
			}));
		if (kanbanColumns && kanbanColumns.length > 0) {
			setKanbanColumnViews(kanbanColumns);
		}
	}, [kanbanBoard]);

	const getCrmId = (id?: string) => {
		if (!id) {
			return;
		}
		setCrmTaskId(id);
		setIsEditModalOpen(true);
	};

	const cardBody = (card: CrmTaskCardView) => (
		<Box
			className={classes.cardContainer}
			onClick={() => getCrmId(card?.taskId)}
		>
			<Box className={classes.cardTitleContainer}>
				<Tooltip title={`${card.name}`} placement='top'>
					<Box>
						<Typography
							title={card.name}
							className={[classes.cardTitle, toolTipStyles.ellipsis].join(' ')}
						>
							{card.name}
						</Typography>
					</Box>
				</Tooltip>
			</Box>
			<Box className={classes.contentContainer}>
				<Typography className={classes.opportunityName}>
					{card.opportunityName}
				</Typography>
				<Typography className={classes.contactName}>{card.contact}</Typography>
			</Box>
			<Box className={classes.crmTaskInfoContainer}>
				<Box display='flex' alignItems='center'>
					<Avatar
						className={classes.bdOwnerPic}
						src={`${card.bdOwnerPic || ''}`}
					>
						{card?.bdOwnerName?.charAt(0)}
					</Avatar>
					{card?.due_date ? (
						<Typography className={classes.dueDate}>
							{dayjs(card?.due_date).format('D MMM YYYY')}
						</Typography>
					) : (
						<Typography className={classes.dueDate}>- -</Typography>
					)}
				</Box>
				<Box>
					<Box
						className={`${classes.priorityContainer}
            ${
							card.priority === 'High'
								? classes.priorityHigh
								: card.priority === 'Medium'
								? classes.priorityMedium
								: card.priority === 'Low'
								? classes.priorityLow
								: undefined
						}
            `}
					>
						{card?.priority}
					</Box>
				</Box>
			</Box>
		</Box>
	);

	const updateOrdinal = (id: string, columnId: string, ordinal: number) => {
		mutate(
			{
				type: 'update',
				resource: 'crm_task_kanban_card',
				payload: {
					id: id,
					data: {
						column_id: columnId,
						ordinal,
					},
				},
			},
			{
				onFailure: (error) => {
					notify(`Update failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<Box mt={2}>
			<Kanban
				onAddCardToColumn={(columnId: string) => {
					addTask(columnId);
				}}
				columns={kanbanColumnViews}
				updateOrdinal={updateOrdinal}
			>
				{cardBody}
			</Kanban>
			<CrmTaskForm
				open={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
				CrmTask={crmTask}
				refetch={() => refetchCrmTask()}
			/>
		</Box>
	);
};

export default CrmTasksKanban;
