import { Col, Row, Typography, Spin } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
const { Text } = Typography;
interface Props {
	count: number | string;
	loading: boolean;
}

export const ActiveProjectCount = (props: Props) => {
	const { count, loading } = props;

	return (
		<div>
			{loading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text key={''}>{count ? count : '--'}</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};
