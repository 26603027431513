import React, { useState, useContext } from "react";
import keyBy from "lodash/keyBy";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { UserProfileContext } from "../../App";
import {
  Datagrid,
  TextField,
  ListContextProvider,
  useQuery,
  Loading,
  ReferenceField,
  DeleteButton,
} from "react-admin";

const useStyles = makeStyles({
  skillContainer: {
    pointerEvents: "none",
  },
  headerRow: {
    position: "static",
  },
  skills: {
    fontSize: "0.75rem"
  },
  title: {
    textAlign: "center",
    padding: "20px",
  },
});

export const ProjectSkillList = ({ projectId, onEdit, isDisabled }) => {
  const { permissions } = useContext(UserProfileContext);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const perPage = 10;
  const { data: skills, total, loading, error } = useQuery({
    type: "GET_LIST",
    resource: "project_skill_requirement",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { project_id: projectId },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Loading />;
  }
  const onEditRowClick = (skillId) => {
    onEdit(skillId);
  };

  return (
    <ListContextProvider
      value={{
        data: keyBy(skills, "id"),
        ids: skills?.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: "id", order: "ASC" },
        basePath: `/project/${projectId}`,
        resource: "project_skill_requirement",
        selectedIds: [],
      }}
    >
      <Box paddingBottom="10px">
        <Box className={!isDisabled ? classes.skillContainer : ""} textAlign="center">
          {skills?.length > 0 ? (
            <Datagrid rowClick={(skillId) => onEditRowClick(skillId)} classes={{ headerCell: classes.headerRow }}>
              <ReferenceField source="skill_id" reference="skill_master">
                <TextField className={classes.skills} source="name" />
              </ReferenceField>
              <ReferenceField source="skill_level_id" link={false} reference="skill_level">
                <TextField className={classes.skills} source="level" />
              </ReferenceField>
              <TextField className={classes.skills} source="count" />
              {permissions?.project_skill_requirement?.insert_permissions ? (
              <DeleteButton
                confirmTitle={"Delete Skill Requirment"}
                confirmContent={"Are you sure you want to delete this item?"}
                undoable={false}
                label={""}
              />) 
              : null }
            </Datagrid>
          ) :
            (
              <Box className={classes.title}>
                <Typography>No skill requirements found...</Typography>
              </Box>
            )}
        </Box>
      </Box>
    </ListContextProvider>
  );
};

export default ProjectSkillList;
