import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { head, isEmpty } from 'lodash';

import CheckInMobile from './components/CheckInMobile.component';
import {
	useGetAttendanceQuery,
	useGetLeaveApproverQuery,
	useGetLeaveBalanceQuery,
	useGetTimesheetListQuery,
	useGetWorkingHoursQuery,
} from '../../generated/graphql';
import { Attendance, SelectedMonthDates } from '../Attendance/Attendance.model';
import { UserProfileContext } from '../../App';
import { Style } from './styles/HomePageMobile.styles';
import { useQuery as useApolloQuery } from '@apollo/client';
import HomeIcon from '../../assets/icons/asset-home-icon-pwa.svg';
import TimeIcon from '../../assets/icons/asset-time-icon-pwa.svg';
import UserIcon from '../../assets/icons/asset-user-icon-pwa.svg';
import CalenderIcon from '../../assets/icons/asset-calender-icon-pwa.svg';
import CalenderMobile from './components/CalenderMobile.component';
import {
	calculatedTimesheetHours,
	calculateWorkedHours,
} from '../Attendance/Constant';
import { GET_ORGANISATION_FINANICIAL_YEAR } from '../gqlQueries';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';

interface Props {
	employeeId: string;
}

const HomePageMobile = (props: Props) => {
	const { employeeId } = props;
	const customStyles = Style();
	const {
		id: userId,
		orgId: organisationId,
		attendanceConsiderableTimesheetStatus,
	} = useContext<any>(UserProfileContext);

	const [todaysAttendance, setTodaysAttendance] = useState<Attendance>({});
	const [currentMonth, setCurrentMonth] = useState({
		startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
		endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
	});
	const [leaveApprover, setLeaveApprover] = useState('');
	const [workHoursLogged, setWorkedHourslogged] = useState(0);
	const [timesheetHours, setTimesheetHours] = useState<number>(0);
	const [currentFinancialYear, setCurrentFinancialYear] =
		useState<SelectedMonthDates>({
			startDate: '',
			endDate: '',
		});

	const { data: workingHoursLogged, refetch: refetchWorkHours } =
		useGetWorkingHoursQuery({
			variables: {
				userId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
			},
		});

	const { data: organizationLeaveApprover } = useGetLeaveApproverQuery({
		variables: {
			userId,
		},
	});

	const { data: organisationFinanicialYear } = useApolloQuery(
		GET_ORGANISATION_FINANICIAL_YEAR,
		{
			variables: { organisationId },
		}
	);

	const { data: timesheetList } = useGetTimesheetListQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
			statusList: attendanceConsiderableTimesheetStatus,
		},
	});

	const { refetch: refetchLeaveBalance } = useGetLeaveBalanceQuery({
		variables: {
			employeeId,
			startDate: currentFinancialYear?.startDate,
			endDate: currentFinancialYear?.endDate,
		},
		fetchPolicy: 'network-only',
		skip: !employeeId || isEmpty(currentFinancialYear),
	});

	const { data: attendance, refetch: refetchAttendance } =
		useGetAttendanceQuery({
			variables: {
				userId,
				date: dayjs().format('YYYY-MM-DD'),
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (!timesheetList?.timesheet) {
			return;
		}
		const timesheetHours = calculatedTimesheetHours(timesheetList);
		setTimesheetHours(timesheetHours);
	}, [timesheetList]);

	useEffect(() => {
		if (!workingHoursLogged || !(workingHoursLogged?.attendance?.length > 0)) {
			setWorkedHourslogged(0);
			return;
		}
		const workedHours = calculateWorkedHours(workingHoursLogged);
		setWorkedHourslogged(workedHours);
	}, [workingHoursLogged]);

	useEffect(() => {
		if (
			!organizationLeaveApprover ||
			!(
				organizationLeaveApprover?.user_by_pk ||
				organizationLeaveApprover?.user_by_pk?.employees?.length
			)
		) {
			return;
		}
		const approver = organizationLeaveApprover?.user_by_pk?.employees[0];
		setLeaveApprover(
			`${approver?.reportsTo?.first_name || ''} ${
				approver?.reportsTo?.last_name || ''
			}`
		);
	}, [organizationLeaveApprover]);

	useEffect(() => {
		if (
			!(
				organisationFinanicialYear &&
				organisationFinanicialYear?.organization_by_pk
			)
		) {
			return;
		}
		const financialData = organisationFinanicialYear?.organization_by_pk;
		if (!financialData) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month: financialData?.financial_year_start_month,
			financial_year_start_date: financialData?.financial_year_start_date,
			financial_year_end_date: financialData?.financial_year_end_date,
			financial_year_end_month: financialData?.financial_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentFinancialYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationFinanicialYear]);

	useEffect(() => {
		if (!attendance || !(attendance?.attendance?.length > 0)) {
			return;
		}
		const todaysAttendance = head(attendance.attendance);
		setTodaysAttendance({
			in_time: todaysAttendance?.in_time,
			out_time: todaysAttendance?.out_time || '',
			date: todaysAttendance?.date,
			id: todaysAttendance?.id,
		});
	}, [attendance]);

	return (
		<Grid container className={customStyles.homePageContainer}>
			<Grid
				item
				container
				direction='column'
				alignItems='center'
				className={customStyles.homePageContentContainer}
			>
				<Typography component='span' className={customStyles.homePageTitle}>
					Home
				</Typography>
				<Grid item container>
					<CheckInMobile
						refetchAttendance={refetchAttendance}
						refetchWorkHours={refetchWorkHours}
						todaysAttendance={todaysAttendance}
					/>
				</Grid>
				<Grid
					item
					container
					direction='column'
					className={customStyles.calenderContainerMobileView}
				>
					<CalenderMobile
						currentMonth={currentMonth}
						setCurrentMonth={(startDate: string, endDate: string) => {
							setCurrentMonth({
								startDate,
								endDate,
							});
						}}
						userId={userId}
						leaveApprover={leaveApprover || '-'}
						workHoursLogged={workHoursLogged}
						currentFinancialYear={currentFinancialYear}
						orgId={organisationId}
						timesheetHours={timesheetHours}
						employeeId={employeeId}
						refetchLeaveBalance={() => refetchLeaveBalance()}
						todaysAttendance={todaysAttendance}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems='center' className={customStyles.bottomNavBar}>
				<Grid item container direction='column' alignItems='center' xs={3}>
					<img
						src={HomeIcon}
						alt='home_icon_mobile'
						className={customStyles.bottomNavBarIcon}
					/>
					<Typography
						component='span'
						className={`${customStyles.bottomNavBarIconLabel} ${customStyles.bottomNavBarIconLabelActive}`}
					>
						Home
					</Typography>
				</Grid>
				<Grid item container direction='column' alignItems='center' xs={3}>
					<img
						src={CalenderIcon}
						alt='calender_icon_mobile'
						className={customStyles.bottomNavBarIcon}
					/>
					<Typography
						component='span'
						className={`${customStyles.bottomNavBarIconLabel} ${customStyles.bottomNavBarIconLabelDisabled}`}
					>
						Attendance
					</Typography>
				</Grid>
				<Grid item container direction='column' alignItems='center' xs={3}>
					<img
						src={TimeIcon}
						alt='timesheet_icon_mobile'
						className={customStyles.bottomNavBarIcon}
					/>
					<Typography
						component='span'
						className={`${customStyles.bottomNavBarIconLabel} ${customStyles.bottomNavBarIconLabelDisabled}`}
					>
						Timesheet
					</Typography>
				</Grid>
				<Grid item container direction='column' alignItems='center' xs={3}>
					<img
						src={UserIcon}
						alt='user_icon_mobile'
						className={customStyles.bottomNavBarIcon}
					/>
					<Typography
						component='span'
						className={`${customStyles.bottomNavBarIconLabel} ${customStyles.bottomNavBarIconLabelDisabled}`}
					>
						My Info
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default HomePageMobile;
