import React from 'react';
import { TextInput, required, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../Layout/styles';
import { useUpdateProjectRiskMutation } from '../../generated/graphql';

interface FormValue {
	reason: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	projectId?: string | null;
  risk: string |null
}
const ProjectRiskChangeForm = (props: Props) => {
	const {
		open,
		onClose,
		onSuccess,
		projectId,
    risk
	} = props;
	const classes = modalFormStyle();
	const notify = useNotify();
	const [createProjectRiskMapping, { loading: isMutationLoading }] =
		useUpdateProjectRiskMutation();

	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}

		createProjectRiskMapping({
			variables: {
        projectId: projectId,
        projectData:{
          risk_status: risk
        },
				riskMappingData: {
					project_id: projectId,
					...formValues,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Risk status updated successfully');
					onSuccess();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify('Updation failed', 'warning');
          onClose()
					return;
				}
			});
		return;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} disableBackdropClick onClose={onClose}>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>RISK CHANGE</Typography>
						<CloseIcon onClick={onClose} className={classes.closeIcon} />
					</Box>
					<Form onSubmit={onSubmit}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Reason *</Typography>
									<TextInput
										multiline
										source='reason'
										label=''
										placeholder='Enter the text'
										validate={required()}
									/>
									<Box className={classes.buttonContainer}>
										<Button onClick={onClose} className={classes.cancelButton}>
											Cancel
										</Button>
										<Button
											className={
												invalid || isMutationLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isMutationLoading}
											type='submit'
                      startIcon={
                        isMutationLoading && (
                          <CircularProgress
                            style={{
                              height: '20px',
                              width: '20px',
                              color: '#4285F4',
                            }}
                          />
                        )
                      }
										>
											Save
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectRiskChangeForm;
