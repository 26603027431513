import React, { useState } from 'react';

import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';

import {
	ellipsisStyle,
	modalFormStyle,
	exportButtonStyles,
} from '../../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../../settingsStyle';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import SkillForm from './SkillForm.component';
import { exportToCsv } from '../../../../Utils/string.util';
import { useGetSkillQuery } from '../../../../generated/graphql';
import { SKILL_EXPORT_NAME } from './constant';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import ExportButtonWithLoader from '../../../../SharedComponents/ExportButton/ExportButtonWithLoader';

interface Skill {
	id?: string;
	name?: string;
	description?: string;
	org_id?: string;
}

const SkillList = (props: any) => {
	const [isSkillFormShown, setIsSkillFormShown] = useState(false);
	const [editSkillDetails, setEditSkillDetails] = useState<Skill>({});
	const [isSkillEdit, setIsSkillEdit] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [choosedSkill, setChoosedSkill] = useState(null);
	const [searchSkill, setSearchSkill] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const style = exportButtonStyles();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: skill } = useGetSkillQuery({
		variables: { name: `%${searchSkill}%` },
	});

	const generateCSV = () => {
		if (skill?.skill_master?.length === 0) {
			return;
		}
		const skillRow = skill?.skill_master?.map((exportSkill) => {
			return [exportSkill?.name || '--', exportSkill?.description || '--'];
		});
		if (!skillRow) {
			return;
		}
		const employeeExportData = [SKILL_EXPORT_NAME, ...skillRow];
		exportToCsv('SkillList.csv', employeeExportData);
	};

	const onEditSkill = (skill: Skill) => {
		if (!skill) {
			return;
		}
		const { org_id, ...restValues } = skill;
		setEditSkillDetails({ ...restValues });
		setIsSkillEdit(true);
		setIsSkillFormShown(true);
	};
	const deleteSkill = () => {
		mutate(
			{
				type: 'delete',
				resource: 'skill_master',
				payload: {
					id: choosedSkill,
				},
			},
			{
				onSuccess: () => {
					notify('Skill deleted Successfully');
					refresh();
					setIsDeleteModalShown(false);
					setChoosedSkill(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Skill'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchSkill(e?.target?.value);
								}}
								value={searchSkill}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Box display={'flex'}>
					<Box marginRight={'10px'}>
						<ExportButtonWithLoader generateCSV={generateCSV} style={style.exportButton} isDisabled={!skill?.skill_master?.length}/>
					</Box>
					<Button
						style={{ width: '130px' }}
						variant='contained'
						className={modalFormStyles.saveButton}
						onClick={() => {
							setIsSkillFormShown(true);
							setIsSkillEdit(false);
						}}
					>
						Add Skill
					</Button>
				</Box>
			</Box>

			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='skill_master'
					basePath='/skill_master'
					bulkActionButtons={false}
					perPage={5}
					actions={false}
					title={' '}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: searchSkill }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.name}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.name}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(shift: any) => {
								return (
									<Tooltip
										title={`${shift.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(skill: any) => (
								<Box>
									<PopOver
										edit={() => onEditSkill(skill)}
										remove={() => {
											setIsDeleteModalShown(true);
											setChoosedSkill(skill?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<SkillForm
				open={isSkillFormShown}
				onClose={() => setIsSkillFormShown(false)}
				refetch={() => refresh()}
				initialValues={editSkillDetails}
				isEditMutation={isSkillEdit}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deleteSkill}
			/>
		</>
	);
};

export default SkillList;
