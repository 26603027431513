import { makeStyles } from '@material-ui/core/styles';

export const inlineStyle = makeStyles({
	name: {
		color: '#292929',
		width: '250px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	saveButton: {
		minWidth: '126px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
});
