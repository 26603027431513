import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	required,
	SelectInput,
	useNotify,
	useRefresh,
	RadioButtonGroupInput,
	ReferenceInput,
	number,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useMutation as useApolloMutation, useQuery } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import { modalFormStyle, currencyInputStyle } from '../../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { UPSERT_PROJECT_MILESTONE } from '../gqlQueries';
import { DropdownOption } from '../../../SharedComponents/model';
import dayjs from 'dayjs';
import { GET_PROJECT_TASK_BY_MILESTONEID } from '../../Projects/Projects.gql';
import { MILESTONE_UNABLE_TO_CHANGE_STATUS } from './constant';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';
import { orderBy } from 'lodash';

const useStyles = makeStyles({
	boxContainer: {
		display: 'flex',
		width: '200px',
	},
	error: {
		color: 'red',
		fontSize: '10px',
	},
	detailsContainer: {
		width: '464px',
		borderRadius: '4px',
	},
});

interface Validation {
	projectStartdate: string;
	projectEnddate: string;
	revenue: string;
}

interface MilestoneForm {
	currency_id?: string;
	link_to_invoice?: boolean;
	payment_status_id?: string;
	status?: string;
	id?: string;
	start_date?: string;
	end_date?: string;
	amount?: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	milestone?: MilestoneForm;
	paymentStatusList: DropdownOption[];
	milestoneStatusList: DropdownOption[];
	validation: Validation;
}

const ProjectMilestoneForm = (props: any) => {
	const {
		open,
		onClose,
		milestone,
		paymentStatusList,
		validation,
		milestoneStatusList,
		milestoneAmount,
		refetch,
	} = props;
	const { id }: { id: string } = useParams();
	const [startDateError, setStartDateError] = useState('');
	const [endDateError, setEndDateError] = useState('');
	const [revenueError, setRevenueError] = useState(false);
	const [isSaveClicked, setIsSaveClicked] = useState(false);
	const classes = modalFormStyle();
	const currencyStyle = currencyInputStyle();
	const style = useStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isCompletedStatusSelected, setIsCompletedStatusSelected] =
		useState(false);
	const [upsertProjectMilestone, { loading: isMilestoneLoading }] =
		useApolloMutation(UPSERT_PROJECT_MILESTONE);
	const [startDateGreaterError, setStartDateGreaterError] = useState(false);
	const [mileStoneFormValues, SetMileStoneFormValues] = useState<MilestoneForm>(
		{}
	);
	const { dateFormat } = useContext<any>(UserProfileContext);

	const { data: projectTaskByMilestoneId } = useQuery(
		GET_PROJECT_TASK_BY_MILESTONEID,
		{
			variables: {
				milesStoneId: milestone?.id,
			},
			fetchPolicy: 'network-only',
		}
	);
	const saveDetails = (formValues: MilestoneForm) => {
		if (formValues?.start_date && validation?.projectStartdate) {
			if (validation?.projectStartdate > formValues?.start_date) {
				setStartDateError('Start date  should be greater project start date');
				return;
			} else {
				setStartDateError('');
			}
		}
		if (formValues?.end_date && validation?.projectEnddate) {
			if (validation?.projectEnddate < formValues?.end_date) {
				setEndDateError('End date should be less than project end date');
				return;
			} else {
				setEndDateError('');
			}
		}
		if (formValues?.amount && validation?.revenue) {
			const totalAmount =
				milestoneAmount -
				parseInt(milestone?.amount || 0) +
				parseInt(formValues?.amount);
			if (parseInt(validation?.revenue) < totalAmount) {
				setRevenueError(true);
				return;
			}
		}
		if (dayjs(formValues?.start_date).isAfter(dayjs(formValues?.end_date))) {
			setStartDateGreaterError(true);
			return;
		}
		setIsSaveClicked(true);
		const { currency_id, ...restFormValues } = formValues;
		upsertProjectMilestone({
			variables: {
				projectmilestone: {
					project_id: id,
					...restFormValues,
					start_date:
						formValues?.start_date !== '' ? formValues?.start_date : null,
					end_date: formValues?.end_date !== '' ? formValues?.end_date : null,
				},
			},
		})
			.then((data: any) => {
				setStartDateError('');
				setEndDateError('');
				setRevenueError(false);
				notify(
					formValues?.id
						? 'Milestone updated successfully'
						: 'Mile stone Created SuccessFully'
				);
				refresh();
				refetch();
				closeModal();
			})
			.catch((error) => {
				if (error?.message.includes('insert/update permission has failed')) {
					console.log(error?.message);
				}
				return;
			});
	};
	const validateMileStoneFormValues = async () => {
		const projectMileStoneErrors = {} as MilestoneForm;
		if (startDateGreaterError) {
			projectMileStoneErrors.start_date =
				'start date must not be greater than end date!';
		}
		if (revenueError) {
			projectMileStoneErrors.amount =
				'Amount should not greater than project revenue';
		}
		return projectMileStoneErrors;
	};

	useEffect(() => {
		if (!milestone) {
			return;
		}
		SetMileStoneFormValues({
			...milestone,
		});
	}, [milestone]);
	const closeModal = () => {
		setIsSaveClicked(false);
		setIsCompletedStatusSelected(false);
		setStartDateGreaterError(false);
		setEndDateError('');
		setStartDateError('');
		onClose();
	};
	const sortedMilestoneStatusList = orderBy(
		milestoneStatusList,
		[(milestone) => milestone.name.toUpperCase()],
		['asc']
	);
	const checkIfMilestoneCompleted = (event: any) => {
		const sortedMilestoneStatusList = orderBy(
			milestoneStatusList,
			[(milestone) => milestone.name.toUpperCase()],
			['asc']
		);
		const completeStatus = sortedMilestoneStatusList?.find(
			(mileStoneFilterStat: any) => mileStoneFilterStat?.name === 'Complete'
		);
		setIsCompletedStatusSelected(
			event.target?.value === completeStatus?.id ? true : false
		);
	};
	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={closeModal}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={style.detailsContainer}>
						<Box className={currencyStyle.headerContainer}>
							<Typography className={currencyStyle.heading}>
								{milestone?.id ? 'Edit Milestone' : 'Add Milestone'}
							</Typography>
							<CloseIcon className={classes.closeIcon} onClick={closeModal} />
						</Box>
						<DialogContent>
							<Box className={currencyStyle.formContainer}>
								<Form
									initialValues={mileStoneFormValues}
									onSubmit={saveDetails}
									validate={validateMileStoneFormValues}
								>
									{({ handleSubmit, invalid, pristine }) => (
										<form onSubmit={handleSubmit}>
											<Typography className={classes.label}>
												Milestone Name *
											</Typography>
											<TextInput
												source='name'
												fullWidth={true}
												label={''}
												validate={required()}
											/>
											<Box className={currencyStyle.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Amount</Box>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Link to Invoice</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={style.boxContainer}>
													<Box className={currencyStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<ReferenceInput
																link={false}
																source='currency_id'
																reference='master_currencies'
																label=''
															>
																<SelectInput
																	optionText='currency_type'
																	disabled={true}
																/>
															</ReferenceInput>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='amount'
															label={''}
															onChange={() => setRevenueError(false)}
															validate={number()}
														/>
													</ThemeProvider>
												</Box>
												<Box marginRight='70px'>
													<RadioButtonGroupInput
														source='link_to_invoice'
														label=''
														choices={[
															{ id: true, name: 'Yes' },
															{ id: false, name: 'No' },
														]}
													/>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Start Date
													</Typography>

													<Field
														name='start_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value) => {
																		setStartDateError('');
																		setStartDateGreaterError(false);
																		props.input.onChange(value);
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														End Date
													</Typography>
													<Field
														name='end_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value) => {
																		setEndDateError('');
																		setStartDateGreaterError(false);
																		props.input.onChange(value);
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
													{startDateGreaterError && (
														<p
															className={classes.errorText}
															style={{ marginBottom: '17px' }}
														>
															End date must be greater than start date
														</p>
													)}
												</Box>
											</Box>
											<Box
												className={classes.multipleInputContainer}
												marginTop='20px'
											>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Milestone Status
													</Typography>
													<SelectInput
														source='status'
														label=''
														choices={sortedMilestoneStatusList || []}
														onChange={(e) => checkIfMilestoneCompleted(e)}
													/>
												</Box>

												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Payment Status
													</Typography>
													<SelectInput
														source='payment_status_id'
														label=''
														choices={paymentStatusList || []}
													/>
												</Box>
											</Box>
											{isCompletedStatusSelected &&
											projectTaskByMilestoneId?.project_task_aggregate
												?.aggregate?.count > 0 ? (
												<Box className={style.error} marginBottom={'5px'}>
													{MILESTONE_UNABLE_TO_CHANGE_STATUS}
												</Box>
											) : (
												''
											)}
											{startDateError ? (
												<Box className={style.error}>{startDateError}</Box>
											) : null}
											{endDateError ? (
												<Box className={style.error}>{endDateError}</Box>
											) : null}
											{revenueError ? (
												<Box className={style.error}>{revenueError}</Box>
											) : null}
											<Box className={classes.buttonContainer} marginTop='20px'>
												<Button
													onClick={closeModal}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
												<Button
													className={
														invalid ||
														isSaveClicked ||
														pristine ||
														isMilestoneLoading ||
														(isCompletedStatusSelected &&
															projectTaskByMilestoneId?.project_task_aggregate
																?.aggregate?.count > 0)
															? classes.disabledButton
															: classes.saveButton
													}
													disabled={
														isMilestoneLoading ||
														invalid ||
														pristine ||
														isSaveClicked ||
														pristine ||
														(isCompletedStatusSelected &&
															projectTaskByMilestoneId?.project_task_aggregate
																?.aggregate?.count > 0)
													}
													type='submit'
												>
													Save
												</Button>
											</Box>
										</form>
									)}
								</Form>
							</Box>
						</DialogContent>
					</Box>
				</Dialog>
			</ThemeProvider>
		</>
	);
};

export default ProjectMilestoneForm;
