import React, { useContext, useState, useEffect } from 'react';
import { FunctionField, ReferenceField, SelectInput } from 'react-admin';
import CrmTaskList from './CrmTaskList.component';

import {
	Typography,
	Box,
	Tabs,
	Tab,
	ThemeProvider,
	Button,
	TextField,
	Chip,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { GET_ASSIGNEE_FILTER_RECOMMENDATIONS } from './CrmTasks.gql';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Form } from 'react-final-form';

import AddIcon from '@material-ui/icons/Add';
import CrmTaskForm from './CrmTaskForm.component';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { UserProfileContext } from '../../App';
import { modalFormTheme } from '../../Layout/Theme.component';
import FilterListIcon from '@material-ui/icons/FilterList';
import { globalStyles } from '../../Layout/styles';
import CrmTaskFilterContainer from './CrmTaskFilterContainer.component';
import AppliedCrmtaskFiltersContainer from './CrmTaskAppliedFilterContainer.component';
import { FilterValues } from './CrmTasks.model';
import CrmTaskFilterFormBody from './CrmTaskFilterFormBody.component';
import {
	useGetAllTaskQuery,
	useGetCompletedTasksQuery,
	useGetDueTodayTasksQuery,
	useGetOverDueTasksQuery,
	useGetUpcomingTasksQuery,
} from '../../generated/graphql';
import { searchBarTheme } from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import { orderBy } from 'lodash';
import { FILTER_STATE_ACTIONS } from '../../globalState';

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: any) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: '10px',
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Medium',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#898989 ',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		width: '100%',
		height: 'fit-content',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		marginTop: '10px',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	addTaskButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	assigneeFilterContainer: {
		background: '#FFFFFF',
		height: '36px',
		marginLeft: '10px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		width: '200px',
	},
	filterContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	assigneeFilter: {
		minWidth: '10px',
	},
	filterButton: {
		marginRight: '10px',
	},
});

interface User {
	id: string;
	first_name: string;
	last_name?: string;
	full_name?: string;
}

export const CrmTaskListContainer = () => {
	const { id: userId, permissions,filterState, dispatchFilter } = useContext<any>(UserProfileContext);
	const crmTaskListContainerStyles = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [isAddTaskModalShown, setIsAddTaskModalShown] = useState(false);
	const [isFilterDrawerShown, setIsFilterDrawerShown] = useState(false);
	const globalStyle = globalStyles();
	const [filterValues, setFilterValues] = useState<FilterValues>({});
	const [assigneesRecommendation, setAssigneesRecommendation] = useState([]);
	const [drawerFilterQuery, setDrawerFilterQuery] = useState({});

	const { data: users } = useApolloQuery(GET_ASSIGNEE_FILTER_RECOMMENDATIONS);

	const {
		data: crmTasks,
		loading: crmTasksLoading,
		refetch: refetchCrmTasks,
	} = useGetAllTaskQuery({
		variables: {
			crmTaskFilter: {
				owner_id:
				filterState?.crmTaskAssignedFilter === 'All'
						? {}
						: {
								_eq: filterState?.crmTaskAssignedFilter ?? userId,
						  },
				is_done: {
					_eq: false,
				},
				...drawerFilterQuery,
			},
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: dueTodayTasks,
		refetch: refetchDueTodayTasks,
		loading: dueTodayTasksLoading,
	} = useGetDueTodayTasksQuery({
		variables: {
			dueTodayTaskFilter: {
				owner_id:
				filterState?.crmTaskAssignedFilter === 'All'
						? {}
						: {
								_eq: filterState?.crmTaskAssignedFilter ?? userId,
						  },
				due_date: { _gte: 'today()', _lt: 'tomorrow()' },
				is_done: { _eq: false },
				...drawerFilterQuery,
			},
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: overDueTasks,
		refetch: refetchOverDueTasks,
		loading: overDueTaskLoading,
	} = useGetOverDueTasksQuery({
		variables: {
			overDueTaskFilter: {
				owner_id:
				filterState?.crmTaskAssignedFilter === 'All'
						? {}
						: {
								_eq: filterState?.crmTaskAssignedFilter ?? userId,
						  },
				due_date: {
					_lt: 'today()',
				},
				is_done: {
					_eq: false,
				},
				...drawerFilterQuery,
			},
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: upComingTasks,
		refetch: refetchUpComingTasks,
		loading: upComingTasksLoading,
	} = useGetUpcomingTasksQuery({
		variables: {
			upcomingTaskFilter: {
				owner_id:
				filterState?.crmTaskAssignedFilter === 'All'
						? {}
						: {
								_eq: filterState?.crmTaskAssignedFilter ?? userId,
						  },
				due_date: {
					_gte: 'tomorrow()',
				},
				is_done: {
					_eq: false,
				},
				...drawerFilterQuery,
			},
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: completedTasks,
		refetch: refetchCompletedTasks,
		loading: completedTasksLoading,
	} = useGetCompletedTasksQuery({
		variables: {
			completedTaskFilter: {
				owner_id:
				filterState?.crmTaskAssignedFilter === 'All'
						? {}
						: {
								_eq: filterState?.crmTaskAssignedFilter ?? userId,
						  },
				is_done: {
					_eq: true,
				},
				...drawerFilterQuery,
			},
		},
		fetchPolicy: 'network-only',
	});

	const handleChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	useEffect(() => {
		if (!users) {
			return;
		}
		const recommendations = users?.user.map((assignedUser: User) => {
			if (!assignedUser) {
				return;
			}
			if (assignedUser.id === userId) {
				return { id: assignedUser.id, name: 'Me' };
			} else {
				return {
					id: assignedUser.id,
					name: `${assignedUser.full_name || ''} `,
				};
			}
		});
		const sortedRecommendations: any = orderBy(
			recommendations,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneesRecommendation(sortedRecommendations);
	}, [users, userId]);

	const refetchTasks = () => {
		refetchCrmTasks();
		refetchDueTodayTasks();
		refetchOverDueTasks();
		refetchUpComingTasks();
		refetchCompletedTasks();
	};

	const getCrmTaskAppliedFilters = (
		filter: string,
		filterValues: FilterValues
	) => {
		switch (filter) {
			case 'activity_type_id':
				return (
					filterValues?.activity_type_id &&
					filterValues?.activity_type_id.length > 0 &&
					filterValues?.activity_type_id.map((activity_id, index) => (
						<Box key={index}>
							<ReferenceField
								source='id'
								reference='crm_task_activity_type'
								resource='crm_tasks'
								record={{ id: activity_id }}
								link={false}
							>
								<FunctionField
									render={(activity: any) => {
										return (
											<Chip
												key={index}
												label={`${activity?.label}`}
												variant='outlined'
											/>
										);
									}}
								/>
							</ReferenceField>
						</Box>
					))
				);
			case 'company_id':
				return (
					filterValues?.company_id &&
					filterValues?.company_id.length > 0 &&
					filterValues?.company_id.map((companyId, index) => (
						<Box key={index}>
							<ReferenceField
								source='id'
								reference='crm_company'
								resource='crm_company'
								record={{ id: companyId }}
								link={false}
							>
								<FunctionField
									render={(company: any) => {
										return (
											<Chip
												key={index}
												label={`${company?.name}`}
												variant='outlined'
											/>
										);
									}}
								/>
							</ReferenceField>
						</Box>
					))
				);
			case 'priority':
				return (
					filterValues?.priority &&
					filterValues?.priority.length > 0 &&
					filterValues?.priority.map((priority, index) => (
						<Box key={index}>
							<Chip key={index} label={`${priority}`} variant='outlined' />
						</Box>
					))
				);
			default:
				return filter;
		}
	};

	const resetFilter = () => {
		setFilterValues({});
		setDrawerFilterQuery({});
		return true;
	};

	return (
		<>
			<Typography className={crmTaskListContainerStyles.pageTitle}>
				Tasks
			</Typography>
			<AppliedCrmtaskFiltersContainer
				clearFilters={async () => {
					const isFilterResetted = await resetFilter();
					if (isFilterResetted) {
						refetchTasks();
					}
				}}
				filterValues={filterValues}
			>
				{getCrmTaskAppliedFilters}
			</AppliedCrmtaskFiltersContainer>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box className={crmTaskListContainerStyles.filterContainer}>
					<form onSubmit={(event) => event.preventDefault()}>
						<ThemeProvider theme={searchBarTheme}>
							<Box width='344px'>
								<TextField
									placeholder='Search tasks'
									label={false}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(e) => {
										dispatchFilter({
											type: FILTER_STATE_ACTIONS.UPDATE_CRM_TASK_SEARCH_TEXT,
											payload: e?.target?.value,
										});
									}}
									value={filterState?.crmTaskSearchText || ''}
								/>
							</Box>
						</ThemeProvider>
					</form>
					<Box className={crmTaskListContainerStyles.assigneeFilterContainer}>
						<Form initialValues={{ assignee: filterState?.crmTaskAssignedFilter ?? userId }} onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<SelectInput
											className={crmTaskListContainerStyles.assigneeFilter}
											source={'assignee'}
											label={''}
											choices={[
												{ id: 'All', name: 'All' },
												...assigneesRecommendation,
											]}
											onChange={(e) => {
												dispatchFilter({
													type: FILTER_STATE_ACTIONS.UPDATE_CRM_TASK_ASSIGNED_FILTER,
													payload: e?.target?.value,
												});
											}}
										/>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<Box>
					<Tooltip title={'Filter'}>
						<IconButton
							onClick={() => setIsFilterDrawerShown(true)}
							className={globalStyle.filterButton}
						>
							<FilterListIcon />
						</IconButton>
					</Tooltip>
					{permissions?.crm_tasks?.insert_permissions && (
						<Button
							className={crmTaskListContainerStyles.addTaskButton}
							startIcon={<AddIcon />}
							variant='contained'
							onClick={() => {
								setIsAddTaskModalShown(true);
							}}
						>
							Add Task
						</Button>
					)}
				</Box>
			</Box>
			<div className={crmTaskListContainerStyles.root}>
				<CustomTabs value={selectedTabIndex} onChange={handleChange}>
					<CustomTab key={0} label='All' />
					<CustomTab key={1} label={`Due Today`} />
					<CustomTab key={2} label={`Overdue`} />
					<CustomTab key={3} label={`Upcoming`} />
					<CustomTab key={4} label='Completed' />
				</CustomTabs>

				<CustomTabPanel value={selectedTabIndex} index={0}>
					<CrmTaskList
						searchParam={filterState?.crmTaskSearchText || ''}
						filters={{
							id: crmTasks?.crm_tasks.map(({ id }: { id: string }) => id),
						}}
						loading={crmTasksLoading}
						refetch={() => {
							refetchTasks();
						}}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={selectedTabIndex} index={1}>
					<CrmTaskList
						searchParam={filterState?.crmTaskSearchText || ''}
						filters={{
							id: dueTodayTasks?.crm_tasks.map(({ id }: { id: string }) => id), // TODO instead of passing id to filter again need to pass the data and render it
						}}
						refetch={() => {
							refetchTasks();
						}}
						loading={dueTodayTasksLoading}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={selectedTabIndex} index={2}>
					<CrmTaskList
						searchParam={filterState?.crmTaskSearchText || ''}
						filters={{
							id: overDueTasks?.crm_tasks.map(({ id }: { id: string }) => id),
						}}
						refetch={() => {
							refetchTasks();
						}}
						loading={overDueTaskLoading}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={selectedTabIndex} index={3}>
					<CrmTaskList
						searchParam={filterState?.crmTaskSearchText || ''}
						filters={{
							id: upComingTasks?.crm_tasks.map(({ id }: { id: string }) => id),
						}}
						refetch={() => {
							refetchTasks();
						}}
						loading={upComingTasksLoading}
					/>
				</CustomTabPanel>

				<CustomTabPanel value={selectedTabIndex} index={4}>
					<CrmTaskList
						searchParam={filterState?.crmTaskSearchText || ''}
						filters={{
							id: completedTasks?.crm_tasks.map(({ id }: { id: string }) => id),
						}}
						refetch={() => {
							refetchTasks();
						}}
						loading={completedTasksLoading}
					/>
				</CustomTabPanel>
			</div>
			<CrmTaskForm
				open={isAddTaskModalShown}
				onClose={() => {
					setIsAddTaskModalShown(false);
				}}
				CrmTask={{ name: '' }}
				refetch={() => {
					refetchTasks();
				}}
			/>
			<CrmTaskFilterContainer
				open={isFilterDrawerShown}
				onClose={() => {
					setIsFilterDrawerShown(false);
				}}
				filterFormValues={filterValues}
				filterValues={(values) => {
					if (!values) {
						return;
					}
					setFilterValues(values);
				}}
				refetch={() => {
					refetchTasks();
				}}
				filterQuery={(query) => {
					setDrawerFilterQuery(query);
				}}
			>
				<CrmTaskFilterFormBody />
			</CrmTaskFilterContainer>
		</>
	);
};

export default CrmTaskListContainer;
