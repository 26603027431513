import React, { useState, useEffect, useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	createTheme,
	InputAdornment,
	IconButton,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	TablePagination,
} from '@material-ui/core';
import { SelectInput } from 'react-admin';

import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/dayjs';
import { Form } from 'react-final-form';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { chain, isEmpty, orderBy, head } from 'lodash';
import {
	useGetTimesheetStatusQuery,
	useGetTimesheetHistoryQuery,
	useGetOrganisationManHoursQuery,
	GetTimesheetHistoryQuery,
	useGetDraftTimesheetEntriesAdminViewQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import { modalFormTheme } from '../../Layout/Theme.component';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayjs from 'dayjs';
import { TimesheetStyles } from './TimesheetStyles';
import { SubmittedTimesheets } from './Timesheet.model';
import {
	getBillableHours,
	getTotalBillableHours,
	getTotalNonBillableHours,
	getTimesheetWeek,
	getTotalHours,
	getTimesheetEntriesBillablePercentage,
} from './Timesheet.utils';
import { START_DATE_ERROR } from './constant';
import { TimesheetHistoryPendingApprovalStyle } from './TimesheetStyles';
import no_data_found from '../../assets/no_data_found.png';
import TimesheetHistoryDetailModal from './TimesheetHistoryDetailModal.component';

import duration from 'dayjs/plugin/duration';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { SpeakerNotes } from '@material-ui/icons';
dayjs.extend(duration);
dayjs.extend(weekOfYear);

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const inlineStyle = makeStyles({
	dropdown: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	container: {
		marginTop: '20px',
	},
});

interface Options {
	id?: any;
	name?: any;
}

const TimesheetHistory = () => {
	const {
		id: userId,
		orgId,
		dateFormat,
		isDraftTimesheetEnabled,
	} = useContext<any>(UserProfileContext);
	const numberOfWeekDays = 5;
	const timesheetPendingApprovalStyles = TimesheetHistoryPendingApprovalStyle();
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<any>();
	const [timesheetStatusId, setTimesheetStatusId] = useState<any>();
	const [startDateError, setStartDateError] = useState(false);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const timesheetStyles = TimesheetStyles();
	const [draftStatusId, setDraftStatusId] = useState<any>();
	const [timesheetData, setTimesheetData] =
		useState<GetTimesheetHistoryQuery>();
	const style = inlineStyle();
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();
	const { data: timesheetHistory } = useGetTimesheetHistoryQuery({
		variables: {
			userId,
			statusId:
				timesheetStatusId === 'All'
					? isDraftTimesheetEnabled
						? {}
						: { _neq: draftStatusId }
					: { _eq: timesheetStatusId },
			startDate: startDate,
			endDate: endDate,
		},
		fetchPolicy: 'network-only',
	});

	const { data: draftTimesheetEntries } =
		useGetDraftTimesheetEntriesAdminViewQuery({
			variables: {
				userId: userId,
				startDate: startDate,
				endDate: endDate,
			},
			fetchPolicy: 'network-only',
		});

	const { data: organisationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId: orgId,
		},
	});

	const getTimesheetBillablePercentage = (
		timesheets: SubmittedTimesheets[]
	) => {
		if (!timesheets || !organisationManHours) {
			return;
		}
		const totalHoursPerWeek =
			organisationManHours.organization_by_pk?.working_hours * numberOfWeekDays;

		const totalBillableHours = getBillableHours(timesheets);

		return totalBillableHours
			? ((totalBillableHours?.asHours() / totalHoursPerWeek) * 100).toFixed()
			: 0;
	};

	useEffect(() => {
		if (isEmpty(timesheetStatus?.timesheet_status)) {
			return;
		}
		const timesheetDraftStatus = timesheetStatus?.timesheet_status.filter(
			(status) => status.value === 'draft'
		);

		// Timesheet Status except draft
		const timesheetStatusOptionsExceptDraft = timesheetStatus?.timesheet_status
			?.filter((value) => value.value !== 'draft')
			.map((timesheet) => {
				if (!timesheet) {
					return;
				}
				if (timesheet?.value === 'submitted') {
					setTimesheetStatusId(timesheet.id);
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			});
		const sortedTimesheetStatusOptionsExceptDraft = orderBy(
			timesheetStatusOptionsExceptDraft,
			['name'],
			['asc']
		);
		// All Timesheet Status
		const timesheetStatusOptions = timesheetStatus?.timesheet_status?.map(
			(timesheet) => {
				if (!timesheet) {
					return;
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			}
		);
		const sortedTimesheetStatusOptions = orderBy(
			timesheetStatusOptions,
			['name'],
			['asc']
		);
		setTimesheetStatusOptions(
			isDraftTimesheetEnabled
				? sortedTimesheetStatusOptions
				: sortedTimesheetStatusOptionsExceptDraft
		);
		if (timesheetDraftStatus) {
			setDraftStatusId(timesheetDraftStatus[0]?.id);
		}
	}, [timesheetStatus, isDraftTimesheetEnabled]);

	useEffect(() => {
		if (isEmpty(timesheetHistory?.user) || !draftTimesheetEntries) {
			return;
		}

		const isDraftStatusSelected =
			timesheetStatusId === draftStatusId || timesheetStatusId === 'All';

		// Draft Timesheets
		const draftSubmittedTimesheetEntries = draftTimesheetEntries.user
			.filter((user) => user.timesheets.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: chain(
					user.timesheets.map((draftEntry) => ({
						...draftEntry,
						week: `${dayjs(draftEntry.date).format('YYYY')}-${dayjs(
							draftEntry.date
						).week()}`,
					}))
				)
					.groupBy('week')
					.map((week, index) => ({
						id: index,
						timesheets: week,
						timesheetSubmissionNotes: [],
						timesheet_status: { id: 'draft', label: 'Draft', value: 'draft' },
					}))
					.value(),
			}));

		// Submitted Timesheets
		const timesheets = timesheetHistory?.user
			.filter((user) => user.timesheetSubmissions.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: user.timesheetSubmissions.filter(
					(timesheetSubmission) => timesheetSubmission.timesheets.length > 0
				),
			}));

		const isSubmittedTimesheetEmpty =
			timesheets?.filter((value) => value.timesheetSubmissions.length > 0)
				.length === 0;

		// Combine draft and submitted timesheets
		const allTimesheets = !isSubmittedTimesheetEmpty
			? timesheets?.map((user) => {
					const draftEntries = draftSubmittedTimesheetEntries.find(
						(draftEntriesUser) => draftEntriesUser.id === user.id
					)?.timesheetSubmissions;

					if (draftEntries) {
						return {
							...user,
							timesheetSubmissions: [
								...user.timesheetSubmissions,
								...draftEntries,
							],
						};
					}
					return user;
			  })
			: draftSubmittedTimesheetEntries;

		if (timesheets) {
			setTimesheetData({
				user:
					isDraftTimesheetEnabled && isDraftStatusSelected && allTimesheets
						? allTimesheets
						: timesheets,
			});
		}
	}, [
		timesheetHistory,
		draftTimesheetEntries,
		draftStatusId,
		timesheetStatusId,
		isDraftTimesheetEnabled,
	]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRowClick = (timesheetSubmission: any, userDetails: any) => {
		if (!timesheetSubmission?.timesheets[0]?.date) {
			return;
		}
		setSelectedTimesheet({
			user: {
				id: userDetails.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			dateRange: getTimesheetWeek(timesheetSubmission.timesheets),
			billablePercentage: getTimesheetEntriesBillablePercentage(
				timesheetSubmission.timesheets
			),
			billableHours: getTotalBillableHours(timesheetSubmission.timesheets),
			nonBillableHours: getTotalNonBillableHours(
				timesheetSubmission.timesheets
			),
			totalHours: getTotalHours(timesheetSubmission.timesheets),
			timesheetSubmissionId: timesheetSubmission.id,
			timesheetPeriod: Array(7)
				.fill(1)
				.map((value: number, index: number) =>
					dayjs(timesheetSubmission.timesheets[0].date)
						.day(index)
						.format('YYYY-MM-DD')
				),
			startDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(0)
				.format('YYYY-MM-DD'),
			endDate: dayjs(timesheetSubmission.timesheets[0].date)
				.day(6)
				.format('YYYY-MM-DD'),
		});
	};

	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<Box className={style.dropdown}>
					<Form
						onSubmit={() => {}}
						initialValues={{ timesheetstatus: timesheetStatusId }}
					>
						{() => (
							<ThemeProvider theme={modalFormTheme}>
								<form>
									<SelectInput
										source={'timesheetstatus'}
										label={''}
										id='timesheet_history_view_status_select'
										choices={
											timesheetStatusOptions &&
											timesheetStatusOptions?.length > 0
												? [
														{
															id: 'All',
															name: 'All',
														},
														...timesheetStatusOptions,
												  ]
												: []
										}
										onChange={(event) =>
											setTimesheetStatusId(event?.target?.value)
										}
									/>
								</form>
							</ThemeProvider>
						)}
					</Form>
				</Box>
				<Box>
					<ThemeProvider theme={dateTimePickerTheme}>
						<Box display='flex'>
							<ErrorTooltip
								title={startDateError ? START_DATE_ERROR : ''}
								placement='top'
								arrow={true}
							>
								<Box width='188px' marginLeft='20px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setStartDateError(false);
												if (dayjs(date).isAfter(dayjs(endDate))) {
													setStartDateError(true);
												}
												setStartDate(dayjs(date).format('YYYY-MM-DD'));
											}}
											value={startDate}
											format={dateFormat}
											error={startDateError}
											label='Start date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton key='timesheet_history_view_start_date_icon'>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</ErrorTooltip>
							<Box width='188px'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant='inline'
										onChange={(date: any) => {
											setStartDateError(false);
											if (dayjs(date).isBefore(dayjs(startDate))) {
												setStartDateError(true);
											}
											setEndDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										value={endDate}
										format={dateFormat}
										label='End date'
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton key='timesheet_history_view_end_date_icon'>
														<EventIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</Box>
					</ThemeProvider>
				</Box>
			</Box>
			{timesheetData && timesheetData.user?.length > 0 ? (
				<div className={style.container}>
					{timesheetData?.user?.map((user, index) =>
						user && user?.timesheetSubmissions.length > 0 ? (
							<div
								className={timesheetPendingApprovalStyles.timesheetsContainer}
								key={index}
							>
								<div className={timesheetPendingApprovalStyles.avatarContainer}>
									<Avatar
										className={timesheetPendingApprovalStyles.avatar}
										src={`${user.profile_pic || ''}`}
									>
										{user?.first_name?.charAt(0).toUpperCase() || ''}
										{user?.last_name?.charAt(0).toUpperCase() || ''}
									</Avatar>
									<Typography
										className={timesheetPendingApprovalStyles.employeeName}
									>
										{`${user.full_name || ''} `}
									</Typography>
								</div>
								<Table>
									<TableHead>
										<TableRow>
											<CustomTableCell>DATE</CustomTableCell>
											<CustomTableCell>STATUS</CustomTableCell>
											<CustomTableCell>TOTAL HOURS</CustomTableCell>
											<CustomTableCell>BILLABLE HOURS</CustomTableCell>
											<CustomTableCell>BILLABLE (%)</CustomTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{user.timesheetSubmissions
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map(
												(timesheetSubmission) =>
													timesheetSubmission.timesheets.length > 0 && (
														<TableRow
															hover={true}
															style={{ cursor: 'pointer' }}
															key={timesheetSubmission.id}
															onClick={() => {
																setIsDetailsModalShown(true);
																handleRowClick(timesheetSubmission, {
																	id: user.id,
																	name: `${user?.full_name}`,
																	profilePic: user.profile_pic,
																});
															}}
														>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={
																		timesheetPendingApprovalStyles.dateContainer
																	}
																>
																	<DateRangeIcon
																		className={
																			timesheetPendingApprovalStyles.calendarIcon
																		}
																	/>
																	{getTimesheetWeek(
																		timesheetSubmission.timesheets
																	) || '- -'}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={`${timesheetStyles.statusContainer}
                                ${
																	timesheetSubmission.timesheet_status.value ===
																	'submitted'
																		? timesheetStyles.submittedStatus
																		: timesheetSubmission.timesheet_status
																				.value === 'approved'
																		? timesheetStyles.approvedStatus
																		: timesheetStyles.rejectedStatus
																}
                              `}
																>
																	{timesheetSubmission.timesheet_status
																		.value === 'submitted'
																		? 'Pending'
																		: timesheetSubmission.timesheet_status
																				.label}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																<div
																	className={
																		timesheetPendingApprovalStyles.totalHoursContainer
																	}
																>
																	<AccessTimeIcon
																		className={
																			timesheetPendingApprovalStyles.durationIcon
																		}
																	/>
																	{`${getTotalHours(
																		timesheetSubmission.timesheets
																	)} hours`}
																</div>
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																{`${getTotalBillableHours(
																	timesheetSubmission.timesheets
																)} hours`}
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.first_name} ${
																			user?.last_name || ''
																		}`,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																{`${getTimesheetBillablePercentage(
																	timesheetSubmission.timesheets
																)}%`}
															</CustomTableCell>
															<CustomTableCell
																onClick={() => {
																	setIsDetailsModalShown(true);
																	handleRowClick(timesheetSubmission, {
																		id: user.id,
																		name: `${user?.full_name} `,
																		profilePic: user.profile_pic,
																	});
																}}
															>
																{timesheetSubmission.timesheet_status.value ===
																	'rejected' && (
																	<Tooltip
																		title={
																			head(
																				timesheetSubmission.timesheetSubmissionNotes
																			)?.notes || '- -'
																		}
																		placement='top'
																		arrow
																	>
																		<SpeakerNotes />
																	</Tooltip>
																)}
															</CustomTableCell>
														</TableRow>
													)
											)}
									</TableBody>
								</Table>
								<Box display='flex' justifyContent='flex-end'>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={user.timesheetSubmissions?.length || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</Box>
							</div>
						) : (
							<Box textAlign='center'>
								<img src={no_data_found} alt='no_data_found' />
							</Box>
						)
					)}
				</div>
			) : (
				<Box textAlign='center'>
					<img src={no_data_found} alt='no_data_found' />
				</Box>
			)}
			<TimesheetHistoryDetailModal
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedTimesheet({});
				}}
				timesheet={selectedTimesheet}
			/>
		</Box>
	);
};

export default TimesheetHistory;
