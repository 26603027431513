import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CrmCompanyIcon = (props) => (
	<SvgIcon {...props} width='12' height='13.714' viewBox='0 0 12 13.714'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='13.714'
			viewBox='0 0 12 13.714'
		>
			<path d='M11.679,12.857h-.536V.643A.643.643,0,0,0,10.5,0h-9A.643.643,0,0,0,.857.643V12.857H.321A.321.321,0,0,0,0,13.179v.536H12v-.536A.321.321,0,0,0,11.679,12.857ZM3.429,2.036a.321.321,0,0,1,.321-.321H4.821a.321.321,0,0,1,.321.321V3.107a.321.321,0,0,1-.321.321H3.75a.321.321,0,0,1-.321-.321Zm0,2.571a.321.321,0,0,1,.321-.321H4.821a.321.321,0,0,1,.321.321V5.679A.321.321,0,0,1,4.821,6H3.75a.321.321,0,0,1-.321-.321ZM4.821,8.571H3.75a.321.321,0,0,1-.321-.321V7.179a.321.321,0,0,1,.321-.321H4.821a.321.321,0,0,1,.321.321V8.25A.321.321,0,0,1,4.821,8.571Zm2.036,4.286H5.143v-2.25a.321.321,0,0,1,.321-.321H6.536a.321.321,0,0,1,.321.321ZM8.571,8.25a.321.321,0,0,1-.321.321H7.179a.321.321,0,0,1-.321-.321V7.179a.321.321,0,0,1,.321-.321H8.25a.321.321,0,0,1,.321.321Zm0-2.571A.321.321,0,0,1,8.25,6H7.179a.321.321,0,0,1-.321-.321V4.607a.321.321,0,0,1,.321-.321H8.25a.321.321,0,0,1,.321.321Zm0-2.571a.321.321,0,0,1-.321.321H7.179a.321.321,0,0,1-.321-.321V2.036a.321.321,0,0,1,.321-.321H8.25a.321.321,0,0,1,.321.321Z' />
		</svg>
	</SvgIcon>
);

export const EmployeesIcon = (props) => (
	<SvgIcon {...props} width='14.277' height='9.278' viewBox='0 0 14.277 9.278'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14.277'
			height='9.278'
			viewBox='0 0 14.277 9.278'
		>
			<g transform='translate(0 0)'>
				<path
					d='M15.587,14.887c-.356-.127-.938-.134-1.2-.232a1.689,1.689,0,0,1-.553-.258,3.419,3.419,0,0,1-.092-.906,1.464,1.464,0,0,0,.315-.445,4.947,4.947,0,0,0,.153-.874s.21.089.293-.331c.07-.362.2-.553.169-.82s-.184-.2-.184-.2a2.381,2.381,0,0,0,.184-1.189,1.9,1.9,0,0,0-2.054-1.879,1.909,1.909,0,0,0-2.057,1.879,2.416,2.416,0,0,0,.181,1.189s-.149-.064-.184.2.1.458.169.82c.083.423.293.331.293.331a5,5,0,0,0,.153.874,1.464,1.464,0,0,0,.315.445,3.419,3.419,0,0,1-.092.906,1.626,1.626,0,0,1-.553.254c-.254.1-.836.111-1.2.238a2.244,2.244,0,0,0-1.456,2.137H17.04A2.24,2.24,0,0,0,15.587,14.887Z'
					transform='translate(-5.488 -7.748)'
				/>
				<path
					d='M5.665,15.233a2.159,2.159,0,0,0,1.062-.3c-.493-.747-.226-1.618-.327-2.432a1.341,1.341,0,0,0-1.485-1.3H4.9a1.346,1.346,0,0,0-1.472,1.3c-.1.811.181,1.781-.324,2.432A2,2,0,0,0,4.17,15.2h0a1.61,1.61,0,0,1-.032.534.8.8,0,0,1-.382.175,7.378,7.378,0,0,0-.824.229,1.339,1.339,0,0,0-.719,1.183H4.768a2.284,2.284,0,0,1,1.017-.948,2.135,2.135,0,0,1,.785-.165s.121-.191-.277-.264a2.71,2.71,0,0,1-.61-.213C5.623,15.662,5.665,15.233,5.665,15.233Z'
					transform='translate(-2.215 -9.643)'
				/>
				<path
					d='M24.87,15.233a2.159,2.159,0,0,1-1.062-.3c.493-.747.226-1.618.327-2.432a1.341,1.341,0,0,1,1.485-1.3h.013a1.346,1.346,0,0,1,1.472,1.3c.1.811-.181,1.781.324,2.432a2,2,0,0,1-1.065.264h0a1.61,1.61,0,0,0,.032.534.8.8,0,0,0,.382.175,7.378,7.378,0,0,1,.824.229,1.339,1.339,0,0,1,.719,1.183H25.766a2.284,2.284,0,0,0-1.017-.948,2.135,2.135,0,0,0-.785-.165s-.121-.191.277-.264a2.71,2.71,0,0,0,.61-.213C24.911,15.662,24.87,15.233,24.87,15.233Z'
					transform='translate(-14.043 -9.643)'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const CrmContactIcon = (props) => (
	<SvgIcon {...props} width='12' height='13.333' viewBox='0 0 12 13.333'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='13.333'
			viewBox='0 0 12 13.333'
		>
			<path
				d='M15.167,2.833H14.5V1.5H13.167V2.833H7.833V1.5H6.5V2.833H5.833A1.333,1.333,0,0,0,4.5,4.167V13.5a1.333,1.333,0,0,0,1.333,1.333h9.333A1.337,1.337,0,0,0,16.5,13.5V4.167A1.337,1.337,0,0,0,15.167,2.833Zm-4.667,2a2,2,0,1,1-2,2A2,2,0,0,1,10.5,4.833Zm4,8h-8v-.667c0-1.333,2.667-2.067,4-2.067s4,.733,4,2.067Z'
				transform='translate(-4.5 -1.5)'
			/>
		</svg>
	</SvgIcon>
);

export const FolderIcon = (props) => (
	<SvgIcon {...props} width='14' height='9.333' viewBox='0 0 14 9.333'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='9.333'
			viewBox='0 0 14 9.333'
		>
			<path
				d='M13.92,10.044l-1.76,3.018a1.555,1.555,0,0,1-1.344.772H1.094a.583.583,0,0,1-.5-.877l1.76-3.018a1.556,1.556,0,0,1,1.344-.772h9.721a.583.583,0,0,1,.5.877ZM3.694,8.389h7.972V7.222A1.167,1.167,0,0,0,10.5,6.056H6.611L5.056,4.5H1.167A1.167,1.167,0,0,0,0,5.667v6.758L1.679,9.547A2.341,2.341,0,0,1,3.694,8.389Z'
				transform='translate(0 -4.5)'
			/>
		</svg>
	</SvgIcon>
);

export const ContractsIcon = (props) => (
	<SvgIcon {...props} width='12' height='14.002' viewBox='0 0 12 14.002'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='14.002'
			viewBox='0 0 12 14.002'
		>
			<path
				d='M5.334,2A1.329,1.329,0,0,0,4,3.334V14a1.334,1.334,0,0,0,1.334,1.334H8V14.062l1.394-1.394H5.334V11.335h5.394L12.061,10H5.334V8.668h8.061l1.274-1.274V6l-4-4H5.334M10,3l3.667,3.667H10V3m4.767,6.334a.367.367,0,0,0-.267.107l-.68.68,1.394,1.387.68-.673a.383.383,0,0,0,0-.527l-.867-.867a.363.363,0,0,0-.26-.107m-1.34,1.18-4.094,4.1V16h1.387l4.1-4.1Z'
				transform='translate(-4 -2)'
			/>
		</svg>
	</SvgIcon>
);

export const ReportIcon = (props) => (
	<SvgIcon {...props} width='9.865' height='13.153' viewBox='0 0 9.865 13.153'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='9.865'
			height='13.153'
			viewBox='0 0 9.865 13.153'
		>
			<path d='M5.754,3.494V0H.617A.615.615,0,0,0,0,.617v11.92a.615.615,0,0,0,.617.617H9.248a.615.615,0,0,0,.617-.617V4.11H6.371A.618.618,0,0,1,5.754,3.494ZM7.4,9.556a.309.309,0,0,1-.308.308H2.774a.309.309,0,0,1-.308-.308V9.351a.309.309,0,0,1,.308-.308H7.09a.309.309,0,0,1,.308.308Zm0-1.644a.309.309,0,0,1-.308.308H2.774a.309.309,0,0,1-.308-.308V7.707A.309.309,0,0,1,2.774,7.4H7.09a.309.309,0,0,1,.308.308Zm0-1.85v.206a.309.309,0,0,1-.308.308H2.774a.309.309,0,0,1-.308-.308V6.063a.309.309,0,0,1,.308-.308H7.09A.309.309,0,0,1,7.4,6.063ZM9.865,3.132v.157H6.576V0h.157A.616.616,0,0,1,7.17.18L9.685,2.7A.615.615,0,0,1,9.865,3.132Z' />
		</svg>
	</SvgIcon>
);

export const SkillMatrixIcon = (props) => (
	<SvgIcon {...props} width='14.649' height='8.789' viewBox='0 0 14.649 8.789'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14.649'
			height='8.789'
			viewBox='0 0 14.649 8.789'
		>
			<g transform='translate(0)'>
				<path
					d='M14.245,6.542,7.86,4.58a1.828,1.828,0,0,0-1.071,0L.4,6.542a.539.539,0,0,0,0,1.043l1.113.342A1.8,1.8,0,0,0,1.108,9a.706.706,0,0,0-.058,1.214L.466,12.843a.366.366,0,0,0,.357.446H2.107a.366.366,0,0,0,.358-.446L1.88,10.214a.7.7,0,0,0-.041-1.2,1.08,1.08,0,0,1,.474-.84L6.789,9.547a1.831,1.831,0,0,0,1.071,0l6.385-1.962a.539.539,0,0,0,0-1.044Zm-6.17,3.705a2.562,2.562,0,0,1-1.5,0L3.255,9.227l-.325,2.6c0,.809,1.968,1.465,4.395,1.465s4.395-.656,4.395-1.465l-.325-2.6Z'
					transform='translate(0 -4.5)'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const OpportunitiesIcon = (props) => (
	<SvgIcon {...props} width='12' height='12' viewBox='0 0 12 12'>
		<svg xmlns='http://www.w3.org/2000/svg'>
			<rect width='12' height='12' rx='2' fill='currentColor' />
			<g transform='translate(2 2)'>
				<path
					color='#FFFFFF'
					d='M6,2a4,4,0,1,0,4,4,3.909,3.909,0,0,0-.244-1.356l-.64.64A3.1,3.1,0,0,1,9.2,6,3.2,3.2,0,1,1,6,2.8a3.1,3.1,0,0,1,.716.084L7.36,2.24A4.041,4.041,0,0,0,6,2M8.8,2,7.2,3.6v.6L6.18,5.22A.554.554,0,0,0,6,5.2a.8.8,0,1,0,.8.8.554.554,0,0,0-.02-.18L7.8,4.8h.6L10,3.2H8.8V2M6,3.6A2.4,2.4,0,1,0,8.4,6H7.6A1.6,1.6,0,1,1,6,4.4Z'
					transform='translate(-2 -2)'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const PlanningIcon = (props) => (
	<SvgIcon {...props} width='10' height='14.054' viewBox='0 0 10 14.054'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='10'
			height='14.054'
			viewBox='0 0 10 14.054'
		>
			<path
				d='M17.594,7.945a4.882,4.882,0,0,0-5-4.57,4.884,4.884,0,0,0-5,4.57,4.321,4.321,0,0,0,1.02,2.6H8.6c.368.487.723.574,1.064,1.135a3.534,3.534,0,0,1,.632,1.807.533.533,0,0,0,.541.5h.3a.133.133,0,0,0,.135-.13V10.787a1.015,1.015,0,0,0-.115-.464l-.709-1.362a.341.341,0,0,1,.318-.493h0a.353.353,0,0,1,.318.191l.855,1.667a.986.986,0,0,1,.111.457v3.069a.133.133,0,0,0,.135.13H13a.133.133,0,0,0,.135-.13V10.784a.986.986,0,0,1,.111-.457L14.1,8.653a.344.344,0,0,1,.3-.182h0a.327.327,0,0,1,.3.474l-.72,1.382a1.025,1.025,0,0,0-.115.464v3.062a.133.133,0,0,0,.135.13h.338a.532.532,0,0,0,.541-.487,3.789,3.789,0,0,1,.622-1.816c.341-.561.7-.649,1.064-1.135h0A4.321,4.321,0,0,0,17.594,7.945Z'
				transform='translate(-7.594 -3.375)'
			/>
			<path
				d='M16.734,32.625h2.531a.987.987,0,0,0,.984-.984h0a.987.987,0,0,0-.984-.984H16.734a.987.987,0,0,0-.984.984h0A.987.987,0,0,0,16.734,32.625Z'
				transform='translate(-13 -18.571)'
			/>
			<path
				d='M15.059,29.531h3.882c.393,0,.715-.443.715-.984h0c0-.541-.322-.984-.715-.984H15.059c-.393,0-.715.443-.715.984h0C14.344,29.088,14.666,29.531,15.059,29.531Z'
				transform='translate(-12 -17.987)'
			/>
		</svg>
	</SvgIcon>
);

export const AssetIcon = (props) => (
	<SvgIcon {...props} width='12.5' height='10.5' viewBox='0 0 12.5 10.5'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12.5'
			height='10.5'
			viewBox='0 0 12.5 10.5'
		>
			<g transform='translate(0.25 0.25)'>
				<path
					fill='currenColor'
					stroke='currentColor'
					strokeWidth='0.5px'
					d='M11.5,7.505,9,7.5a.5.5,0,0,0-.5.5v4.5A.5.5,0,0,0,9,13h2.5a.5.5,0,0,0,.5-.5V8A.5.5,0,0,0,11.5,7.505ZM9,8.835V8.5h2.5ZM10,3H1A1,1,0,0,0,0,4v6a1,1,0,0,0,1,1H4.5v1h-1v1h4V12h-1V11h1V10H1V4h9V6.5h1V4A1,1,0,0,0,10,3ZM2.3,6.5V5H8.812V6.5H2.3V8.835H7.5V6.811Z'
					transform='translate(0 -3)'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const CrmTaskIcon = (props) => (
	<SvgIcon {...props} width='12' height='13.714' viewBox='0 0 12 13.714'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='13.714'
			viewBox='0 0 12 13.714'
		>
			<path
				fill='currenColor'
				d='M11.679,4.286H.321A.321.321,0,0,1,0,3.964V3A1.286,1.286,0,0,1,1.286,1.714H2.571V.321A.321.321,0,0,1,2.893,0H3.964a.321.321,0,0,1,.321.321V1.714H7.714V.321A.321.321,0,0,1,8.036,0H9.107a.321.321,0,0,1,.321.321V1.714h1.286A1.286,1.286,0,0,1,12,3v.964A.321.321,0,0,1,11.679,4.286ZM.321,5.143H11.679A.321.321,0,0,1,12,5.464v6.964a1.286,1.286,0,0,1-1.286,1.286H1.286A1.286,1.286,0,0,1,0,12.429V5.464A.321.321,0,0,1,.321,5.143Zm8.928,2.57-.755-.761a.321.321,0,0,0-.455,0L5.2,9.768,3.968,8.526a.321.321,0,0,0-.455,0l-.761.755a.321.321,0,0,0,0,.455l2.213,2.23a.321.321,0,0,0,.455,0l3.829-3.8a.321.321,0,0,0,0-.455Z'
			/>
		</svg>
	</SvgIcon>
);

export const AttendanceIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11.414'
		height='13.045'
		viewBox='0 0 11.414 13.045'
	>
		<path
			id='Icon_awesome-calendar-alt'
			data-name='Icon awesome-calendar-alt'
			d='M0,11.822a1.223,1.223,0,0,0,1.223,1.223h8.969a1.223,1.223,0,0,0,1.223-1.223V4.892H0ZM8.153,6.828a.307.307,0,0,1,.306-.306H9.478a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H8.459a.307.307,0,0,1-.306-.306Zm0,3.261a.307.307,0,0,1,.306-.306H9.478a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H8.459a.307.307,0,0,1-.306-.306ZM4.892,6.828A.307.307,0,0,1,5.2,6.523H6.217a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H5.2a.307.307,0,0,1-.306-.306Zm0,3.261A.307.307,0,0,1,5.2,9.784H6.217a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H5.2a.307.307,0,0,1-.306-.306ZM1.631,6.828a.307.307,0,0,1,.306-.306H2.956a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H1.936a.307.307,0,0,1-.306-.306Zm0,3.261a.307.307,0,0,1,.306-.306H2.956a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H1.936a.307.307,0,0,1-.306-.306Zm8.561-8.459H8.969V.408A.409.409,0,0,0,8.561,0H7.746a.409.409,0,0,0-.408.408V1.631H4.077V.408A.409.409,0,0,0,3.669,0H2.854a.409.409,0,0,0-.408.408V1.631H1.223A1.223,1.223,0,0,0,0,2.854V4.077H11.415V2.854A1.223,1.223,0,0,0,10.192,1.631Z'
			fill='currentColor'
		/>
	</svg>
);

export const TimeSheetMenuIcon = (props) => (
	<svg
		id='Icon_awesome-clock'
		data-name='Icon awesome-clock'
		xmlns='http://www.w3.org/2000/svg'
		width='12'
		height='12'
		viewBox='0 0 12 12'
	>
		<path
			id='Icon_awesome-clock-2'
			data-name='Icon awesome-clock'
			d='M6.563.563a6,6,0,1,0,6,6A6,6,0,0,0,6.563.563Zm1.381,8.47L5.81,7.482a.292.292,0,0,1-.119-.235V3.175a.291.291,0,0,1,.29-.29H7.143a.291.291,0,0,1,.29.29V6.507L8.97,7.625a.29.29,0,0,1,.063.406L8.35,8.97A.292.292,0,0,1,7.944,9.033Z'
			transform='translate(-0.563 -0.563)'
            fill='currentColor'
		/>
	</svg>
);

export const OrgChartIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='15.002'
		height='15.002'
		viewBox='0 0 15.002 15.002'
	>
		<g id='org_chart' transform='translate(3951 137)'>
			<path
				id='Icon_awesome-user-alt'
				data-name='Icon awesome-user-alt'
				d='M2.935,3.338A1.661,1.661,0,0,0,4.586,1.669a1.651,1.651,0,1,0-3.3,0A1.661,1.661,0,0,0,2.935,3.338ZM4.4,3.709H3.771a1.976,1.976,0,0,1-1.672,0H1.468A1.476,1.476,0,0,0,0,5.193v.185a.554.554,0,0,0,.55.556H5.32a.554.554,0,0,0,.55-.556V5.193A1.476,1.476,0,0,0,4.4,3.709Z'
				transform='translate(-3946.434 -137)'
				fill='currentColor'
			/>
			<g
				id='Group_1009'
				data-name='Group 1009'
				transform='translate(-3951 -127.933)'
			>
				<path
					id='Icon_awesome-user-alt-2'
					data-name='Icon awesome-user-alt'
					d='M2.935,3.338A1.661,1.661,0,0,0,4.586,1.669a1.651,1.651,0,1,0-3.3,0A1.661,1.661,0,0,0,2.935,3.338ZM4.4,3.709H3.771a1.976,1.976,0,0,1-1.672,0H1.468A1.476,1.476,0,0,0,0,5.193v.185a.554.554,0,0,0,.55.556H5.32a.554.554,0,0,0,.55-.556V5.193A1.476,1.476,0,0,0,4.4,3.709Z'
					fill='currentColor'
				/>
				<path
					id='Icon_awesome-user-alt-3'
					data-name='Icon awesome-user-alt'
					d='M2.935,3.338A1.661,1.661,0,0,0,4.586,1.669a1.651,1.651,0,1,0-3.3,0A1.661,1.661,0,0,0,2.935,3.338ZM4.4,3.709H3.771a1.976,1.976,0,0,1-1.672,0H1.468A1.476,1.476,0,0,0,0,5.193v.185a.554.554,0,0,0,.55.556H5.32a.554.554,0,0,0,.55-.556V5.193A1.476,1.476,0,0,0,4.4,3.709Z'
					transform='translate(9.132)'
					fill='currentColor'
				/>
			</g>
			<rect
				id='Rectangle_1022'
				data-name='Rectangle 1022'
				width='0.395'
				height='2.473'
				transform='translate(-3943.662 -131.56)'
				fill='currentColor'
			/>
			<rect
				id='Rectangle_1023'
				data-name='Rectangle 1023'
				width='0.326'
				height='0.989'
				transform='translate(-3948.228 -129.252)'
				fill='currentColor'
			/>
			<rect
				id='Rectangle_1024'
				data-name='Rectangle 1024'
				width='0.326'
				height='0.989'
				transform='translate(-3939.096 -129.252)'
				fill='currentColor'
			/>
			<rect
				id='Rectangle_1025'
				data-name='Rectangle 1025'
				width='0.33'
				height='9.31'
				transform='translate(-3938.844 -129.252) rotate(90)'
				fill='currentColor'
			/>
		</g>
	</svg>
);

export const ProjectIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='10.5'
		height='14'
		viewBox='0 0 10.5 14'
	>
		<path
			id='project'
			d='M9.188,1.75H7a1.75,1.75,0,0,0-3.5,0H1.313A1.313,1.313,0,0,0,0,3.063v9.625A1.313,1.313,0,0,0,1.313,14H9.188A1.313,1.313,0,0,0,10.5,12.688V3.063A1.313,1.313,0,0,0,9.188,1.75ZM2.625,11.594a.656.656,0,1,1,.656-.656A.655.655,0,0,1,2.625,11.594Zm0-2.625a.656.656,0,1,1,.656-.656A.655.655,0,0,1,2.625,8.969Zm0-2.625a.656.656,0,1,1,.656-.656A.655.655,0,0,1,2.625,6.344ZM5.25,1.094a.656.656,0,1,1-.656.656A.655.655,0,0,1,5.25,1.094Zm3.5,10.063a.219.219,0,0,1-.219.219H4.594a.219.219,0,0,1-.219-.219v-.437a.219.219,0,0,1,.219-.219H8.531a.219.219,0,0,1,.219.219Zm0-2.625a.219.219,0,0,1-.219.219H4.594a.219.219,0,0,1-.219-.219V8.094a.219.219,0,0,1,.219-.219H8.531a.219.219,0,0,1,.219.219Zm0-2.625a.219.219,0,0,1-.219.219H4.594a.219.219,0,0,1-.219-.219V5.469a.219.219,0,0,1,.219-.219H8.531a.219.219,0,0,1,.219.219Z'
			fill='currentColor'
		/>
	</svg>
);

export const ProjectPlanningIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='13'
		height='10.4'
		viewBox='0 0 13 10.4'
	>
		<path
			id='project_planning'
			d='M7.8,6.5H5.2a.65.65,0,0,0-.65.65v2.6a.65.65,0,0,0,.65.65H7.8a.65.65,0,0,0,.65-.65V7.15A.65.65,0,0,0,7.8,6.5ZM3.9.65A.65.65,0,0,0,3.25,0H.65A.65.65,0,0,0,0,.65v2.6a.65.65,0,0,0,.65.65H2.594L4.08,6.5A1.3,1.3,0,0,1,5.2,5.85h.006L3.9,3.565V2.6H8.45V1.3H3.9ZM12.35,0H9.75A.65.65,0,0,0,9.1.65v2.6a.65.65,0,0,0,.65.65h2.6A.65.65,0,0,0,13,3.25V.65A.65.65,0,0,0,12.35,0Z'
			fill='currentColor'
		/>
	</svg>
);

export const ResourceAvailabilityIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='13.99'
		viewBox='0 0 14 13.99'
	>
		<path
			id='resource_availability'
			d='M13.307,13.99a.7.7,0,0,1-.5-.207l-2.574-2.575a6.3,6.3,0,1,1,.972-.972l2.6,2.574a.693.693,0,0,1-.5,1.18ZM5.495,6.632A1.509,1.509,0,0,0,3.987,8.14v.466a.54.54,0,0,0,.539.539H8.474a.539.539,0,0,0,.538-.539V8.14A1.509,1.509,0,0,0,7.505,6.632H7.318a1.956,1.956,0,0,1-1.635,0ZM6.5,3.4A1.436,1.436,0,1,0,7.935,4.838,1.438,1.438,0,0,0,6.5,3.4Z'
			transform='translate(-0.001 0)'
			fill='currentColor'
		/>
	</svg>
);

export const ResourceGanttChartIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='13'
		height='11.7'
		viewBox='0 0 13 11.7'
	>
		<path
			id='resources'
			d='M13.05,4.95H11.1V4.3A1.3,1.3,0,0,0,9.8,3H7.2A1.3,1.3,0,0,0,5.9,4.3v.65H3.95A1.915,1.915,0,0,0,2,6.9v5.85A1.915,1.915,0,0,0,3.95,14.7h9.1A1.915,1.915,0,0,0,15,12.75V6.9A1.915,1.915,0,0,0,13.05,4.95ZM7.2,4.3H9.8v.65H7.2Zm6.5,8.45a.614.614,0,0,1-.65.65H3.95a.614.614,0,0,1-.65-.65V9.11l3.055,1.04h4.1a.239.239,0,0,0,.195-.065L13.7,9.045Z'
			transform='translate(-2 -3)'
			fill='currentColor'
		/>
	</svg>
);