export const getUrlParam = (key) => {
	const url_string = window.location.href;
	const url = new URL(url_string);
	const value = url.searchParams.get(key);
	console.log(value);
	return value;
};

export const hasRole = (currentRole, permittedRoles) =>
	permittedRoles.includes(currentRole);

export const supportedFileFormats = [
	'pdf',
	'doc',
	'docx',
	'xls',
	'csv',
	'xlsx',
	'odt',
	'ods',
	'ppt',
	'pptx',
	'txt',
	'png',
	'jpeg',
	'jpg',
	'svg',
	'eml'
];
