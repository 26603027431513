import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useContext,
} from 'react';
import CloseIcon from '@material-ui/icons/Close';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GET_KANBAN_BOARD } from './TaskList.gql';
import { useLazyQuery } from '@apollo/client';
import { Kanban } from '../Kanban/Kanban.component';
import { useHistory } from 'react-router';
import { ellipsisStyle } from '../../Layout/styles';
import {
	ProjectKanbanColumnView,
	ProjectKanbanModel,
	ProjectTaskCardView,
} from './ProjectKanban.model';
import { Divider, Avatar, Tooltip, IconButton } from '@material-ui/core';
import {
	Loading,
	useMutation,
	useNotify,
	setSidebarVisibility,
	Button,
} from 'react-admin';
import CreateTaskForm from './CreateTaskForm';
import { SubTaskIcon, TimesheetButtonIcon } from '../../assets/SvgIcons';
import './projectKanban.css';
import { head, isEmpty } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteModal from '../../SharedComponents/DeleteModal.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TaskCloneModal from './TaskCloneModal.component';
import { LinerProgressTask } from '../Projects/Progressbar';
import {
	PROJECT_TASK_CLONE_MESSAGE,
	PROJECT_TASK_CLONNING_COMPLETE,
	PROJECT_TASK_CLONNING_OPEN,
} from './Constant';
import {
	useDeleteProjectKanbanTaskMutation,
	useCreateProjectTaskMutation,
	useGetSubTaskListBytaskIdQuery,
	useCloneSubtaskMutation,
	useUpdateProjectTaskStatusByIdMutation,
	useGetProjectsOptionsQuery,
} from '../../generated/graphql';
import { Team } from './ProjectKanban.model';
import { uuidValidator } from '../Projects/constant';
import { UserProfileContext } from '../../App';
import { ProjectTaskContext } from './ProjectTaskMilestoneContainer';
import TimesheetGlobalForm from '../Timesheet/TimesheetGlobalForm.component';
import { getProjectOptions } from '../Timesheet/Timesheet.utils';
interface propTypes {
	searchedTask: string;
	boardId: string;
	filterValues?: Filter;
	setFilterValues: any;
	projectTaskCategory: Team[];
	projectTaskCategoriesCount: number;
	assigne: Team[];
	allMilestoneSelected: boolean;
	milestoneList: Team[];
	allAssigneSelected: boolean;
}
interface Filter {
	assigned_to?: string;
	priority?: string[];
}

const theme = createTheme({
	overrides: {
		MuiSnackbarContent: {
			root: {
				backgroundColor: '#fff',
				color: 'black',
				minHeight: '125px',
				minWidth: '400px !important',
				padding: 0,
				display: 'flex',
			},
			action: {
				marginLeft: 0,
				paddingLeft: 0,
			},
		},
	},
});

export interface SubtaskProps {
	assigned_to: string;
	completion_percentage: number;
	description?: any;
	is_billable: boolean | null | undefined;
	planned_end_date: Date;
	planned_start_date: Date;
	priority: string;
	project_id: string;
	project_milestone_id: string;
	title: string;
	project_kanban_cards: string;
	planned_hours: number;
	reminder: Date;
}

export const ProjectKanban = forwardRef((props: propTypes, ref: any) => {
	const {
		searchedTask,
		boardId,
		filterValues,
		projectTaskCategory,
		projectTaskCategoriesCount,
		assigne,
		allMilestoneSelected,
		allAssigneSelected,
		milestoneList,
	} = props;
	const { id: projectId } = useParams<{ id: string }>();
	const { projecTaskFiltersState } = useContext<any>(ProjectTaskContext);
	const [isCreateTaskModalShown, setIsCreateTaskModalShown] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [taskId, setTaskId] = useState('');
	const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
	const [cloneTaskName, setCloneTaskName] = useState('');
	const [cloneTaskDetail, setCloneTaskDetail] = useState<any>();
	const [open, setOpen] = useState(false);
	const [clonnedTaskId, setClonnedTaskId] = useState();
	const [subTaskList, setSubTaskList] = useState<SubtaskProps[] | undefined>();
	const [isSubtaskClone, setIsSubTaskClone] = useState(false);
	const [mutate] = useMutation();
	const notify = useNotify();
	const history = useHistory<any>();
	const dispatch = useDispatch();
	const ellipsis = ellipsisStyle();
	const [filterFormValues, setFilterFormValues] = useState<Filter>();
	const [showTimesheetForm, setShowTimesheetForm] = useState<boolean>(false);
	const { dateFormat, id: userId } = useContext<any>(UserProfileContext);
	const [cardDetail, setCardDetail] = useState<ProjectTaskCardView>();

	const { data: taskList } = useGetSubTaskListBytaskIdQuery({
		variables: {
			task_id: taskId,
		},
	});

	const [addSubTask] = useCloneSubtaskMutation();

	const [deleteTask] = useDeleteProjectKanbanTaskMutation();
	const [cloneTask] = useCreateProjectTaskMutation();

	const { data: projectOptions } = useGetProjectsOptionsQuery({
		variables: {
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const projectTaskDetail = {
		projectId: cardDetail?.projectId || null,
		taskId: cardDetail?.taskId || null,
	};

	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	useEffect(() => {
		if (isEmpty(taskList?.project_task)) {
			setSubTaskList([]);
			return;
		}
		const response = taskList?.project_task?.map((value) => {
			return {
				assigned_to: value?.assigned_to,
				completion_percentage: value?.completion_percentage || 0,
				description: value?.description,
				is_billable: value?.is_billable,
				planned_end_date: value?.planned_end_date,
				planned_start_date: value?.planned_start_date,
				priority: value?.priority,
				project_id: value?.project_id,
				project_milestone_id: value?.project_milestone_id,
				title: value?.title,
				project_kanban_cards: value?.project_kanban_cards[0]?.column_id,
				planned_hours: value?.planned_hours,
				reminder: value?.reminder,
			};
		});
		setSubTaskList(response);
	}, [taskList]);

	const unAssigned = milestoneList?.find(
		(value: Team) => value?.value === 'UnAssigned'
	);
	const mildestoneId = milestoneList?.filter((value: Team) =>
		uuidValidator.test(value?.value)
	);

	const milestoneWithUnassigned = {
		title: searchedTask ? { _ilike: `%${searchedTask}%` } : {},
		_or: [
			{
				project_milestone_id: {
					_is_null: true,
				},
			},
			{
				project_milestone_id: {
					_in: mildestoneId?.map((value) => value?.value),
				},
			},
		],
		parent_task_id: { _is_null: true },
		project_id: projectId ? { _in: projectId } : {},
		assigned_to: allAssigneSelected
			? {}
			: assigne && assigne?.length > 0
			? {
					_in: assigne.map((value) => value.value),
			  }
			: {
					_in: [],
			  },
		priority: filterFormValues?.priority
			? { _in: filterFormValues?.priority }
			: {},
		category_id:
			projectTaskCategory &&
			projectTaskCategory.length > 0 &&
			projectTaskCategory.length !== projectTaskCategoriesCount
				? { _in: projectTaskCategory.map((category) => category.value) }
				: {},
	};

	const milestoneWithassigned = {
		title: searchedTask ? { _ilike: `%${searchedTask}%` } : {},
		parent_task_id: { _is_null: true },
		project_id: projectId ? { _in: projectId } : {},
		project_milestone_id: allMilestoneSelected
			? {}
			: unAssigned?.value === 'UnAssigned'
			? {
					_is_null: true,
			  }
			: milestoneList?.length > 0
			? {
					_in: milestoneList?.map((value) => value?.value),
			  }
			: { _in: [] },
		assigned_to: allAssigneSelected
			? {}
			: assigne && assigne?.length > 0
			? {
					_in: assigne.map((value) => value.value),
			  }
			: {
					_in: [],
			  },
		priority: filterFormValues?.priority
			? { _in: filterFormValues?.priority }
			: {},
		category_id:
			projectTaskCategory &&
			projectTaskCategory.length > 0 &&
			projectTaskCategory.length !== projectTaskCategoriesCount
				? { _in: projectTaskCategory.map((category) => category.value) }
				: {},
	};

	const [loadKanban, { data: kanbanBoard, loading }] =
		useLazyQuery<ProjectKanbanModel>(GET_KANBAN_BOARD, {
			variables: {
				projectId,
				task:
					unAssigned?.value === 'UnAssigned' && milestoneList?.length > 0
						? milestoneWithUnassigned
						: milestoneWithassigned,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		loadKanban();
	}, [loadKanban]);

	useImperativeHandle(ref, () => ({
		loadKanbanData() {
			loadKanban();
		},
	}));

	useEffect(() => {
		if (!filterValues) {
			return;
		}
		if (filterValues?.assigned_to === 'All') {
			const { assigned_to, ...restFilterValues } = filterValues;
			setFilterFormValues(restFilterValues);
		} else {
			setFilterFormValues(filterValues);
		}
	}, [filterValues]);

	const [kanbanColumnViews, setKanbanColumnViews] = useState<
		ProjectKanbanColumnView[]
	>([]);
	const [kanbanColumnId, setKanbanColumnId] = useState('');
	const [updateTaskStatusById] = useUpdateProjectTaskStatusByIdMutation();
	const updateOrdinal = (
		id: string,
		columnId: string,
		ordinal: number,
		taskId?: string
	) => {
		updateTaskStatusById({
			variables: {
				id: taskId,
				status: columnId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					mutate(
						{
							type: 'update',
							resource: 'project_kanban_card',
							payload: {
								id: id,
								data: {
									column_id: columnId,
									ordinal,
								},
							},
						},
						{
							onSuccess: ({ data }) => {},
							onFailure: (error) => {
								notify(`Update failed: ${error.message}`, 'warning');
							},
						}
					);
				}
			})
			.catch((err) => {
				console.log(err);
				return;
			});
	};

	useEffect(() => {
		if (!kanbanBoard) {
			return;
		}
		const columns = kanbanBoard.project[0]?.kanban_board?.kanban_columns;
		const kanbanColumns =
			columns &&
			columns.length > 0 &&
			columns.map((column) => ({
				...column,
				cards: column?.cards.map((card, index) => ({
					name: card.project_task?.title,
					index,
					ordinal: card.ordinal,
					id: card.id,
					unique_id: card?.project_task?.task_number,
					categoryName: card?.project_task.project_task_category?.name || '',
					categoryId: card?.project_task.project_task_category?.id || null,
					projectId: card.project_task?.project_id,
					columnId: card.column_id,
					description: card.project_task?.description,
					projectMilestoneId: card.project_task?.project_milestone_id,
					taskId: card.project_task?.id,
					fullName: card.project_task?.assignedTo?.full_name
						? `${card.project_task?.assignedTo?.full_name}`
						: 'Unassigned',
					profilePic: card.project_task?.assignedTo?.profile_pic,
					planned_hours: card.project_task?.planned_hours,
					dueDate: card.project_task?.planned_end_date,
					userId: card.project_task?.assigned_to,
					priority: card.project_task?.priority,
					prefix: kanbanBoard.project[0]?.prefix,
					state: column?.state,
					is_billable: card?.project_task?.is_billable,
					planned_start_date: card?.project_task?.planned_start_date,
					planned_end_date: card?.project_task?.planned_end_date,
					reminder: card?.project_task?.reminder,
					assigned_to: card?.project_task?.assigned_to,
					completion_percentage: card?.project_task?.completion_percentage,
					subTaskCount:
						card?.project_task?.project_tasks_aggregate?.aggregate?.count || 0,
				})),
			}));

		if (kanbanColumns && kanbanColumns.length > 0) {
			setKanbanColumnViews(kanbanColumns);
		}
	}, [kanbanBoard]);

	const redirectToDetails = (projectId: any, taskId: any) => {
		if (anchorEl) {
			return;
		}
		history.push({
			pathname: `/project/${projectId}/tasks/${taskId}`,
			state: {
				assigned_to: projecTaskFiltersState?.projectTaskAssigneeFilter,
				projectMilestoneId: projecTaskFiltersState?.projectTaskMilestoneFilter,
				taskCategory: projecTaskFiltersState?.projectTaskCategoryFilter,
				projectTaskSearchText: projecTaskFiltersState?.projectTaskSearchText,
				projectMilestoneSearchText:
					projecTaskFiltersState?.projectMilestoneSearchText,
				projectMilestoneTaskFilter:
					projecTaskFiltersState?.projectMilestoneTaskFilter,
			},
		});
	};
	const getDueDate = (dueDate: string) => {
		if (!dueDate) {
			return;
		}
		const today = dayjs().startOf('day');
		const taskDueDate = dayjs(dueDate).startOf('day');
		const dateDifference = taskDueDate.diff(today, 'days');
		if (dateDifference === 0) {
			return <p className={'projectTaskDueToday'}>Due Today</p>;
		}
		if (dateDifference < 0) {
			return <p className={'projectTaskOverDue'}>Overdue</p>;
		}
		if (dateDifference > 0 && dateDifference <= 7) {
			return (
				<p
					title={`Due In ${dateDifference} days`}
					className={`projectTaskNormalDue ${ellipsis.ellipsis}`}
				>
					Due In {dateDifference} days
				</p>
			);
		}
		return taskDueDate.format('D-MMM-YYYY');
	};

	const getTaskOverDueStatus = (dueDate: string) => {
		if (!dueDate) {
			return;
		}
		const today = dayjs();
		const taskDueDate = dayjs(dueDate);
		const dateDifference = taskDueDate.diff(today, 'days');
		return dateDifference < 0 ? true : false;
	};

	const closePopOver = () => {
		setTaskId('');
		setAnchorEl(null);
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		deleteTask({
			variables: {
				taskId,
			},
		}).then((data) => {
			if (data?.data) {
				loadKanban();
				setAnchorEl(null);
				setTaskId('');
				setIsDeleteModalOpen(false);
				notify('Project Task Deleted Successfully');
			}
		});
	};

	const handleCloneTask = () => {
		if (!cloneTaskDetail) {
			return;
		}
		cloneTask({
			variables: {
				data: {
					title: cloneTaskName,
					project_id: cloneTaskDetail?.projectId,
					project_milestone_id: cloneTaskDetail?.projectMilestoneId,
					parent_task_id: null,
					category_id: cloneTaskDetail?.categoryId,
					reminder: cloneTaskDetail?.reminder || null,
					is_billable: cloneTaskDetail?.is_billable,
					planned_start_date: cloneTaskDetail?.planned_start_date,
					planned_end_date: cloneTaskDetail?.planned_end_date,
					planned_hours: cloneTaskDetail?.planned_hours,
					assigned_to: cloneTaskDetail?.assigned_to || null,
					priority: cloneTaskDetail?.priority,
					completion_percentage: cloneTaskDetail?.completion_percentage || 0,
					description: cloneTaskDetail?.description,
					project_kanban_cards: {
						data: [
							{
								column_id: cloneTaskDetail?.columnId,
								ordinal: 1,
							},
						],
					},
				},
			},
		}).then((data) => {
			if (data?.data) {
				const record =
					subTaskList &&
					subTaskList?.map((value) => {
						return {
							assigned_to: value?.assigned_to,
							parent_task_id: head(data?.data?.insert_project_task?.returning)
								?.id,
							completion_percentage: value?.completion_percentage || 0,
							description: value?.description,
							is_billable: value?.is_billable,
							planned_end_date: value?.planned_end_date,
							planned_start_date: value?.planned_start_date,
							priority: value?.priority,
							project_id: value?.project_id,
							project_milestone_id: value?.project_milestone_id,
							planned_hours: value?.planned_hours,
							reminder: value?.reminder,
							title: `Clone-${value?.title}`,
							project_kanban_cards: {
								data: {
									column_id: value?.project_kanban_cards,
								},
							},
						};
					});
				if (isSubtaskClone && taskList && taskList?.project_task?.length > 0) {
					insertSubTask(record);
				}
				setClonnedTaskId(head(data?.data?.insert_project_task?.returning)?.id);
				loadKanban();
				setOpen(true);
				setIsCloneModalOpen(false);
				setAnchorEl(null);
			}
		});
	};

	const insertSubTask = (projectTask: any) => {
		addSubTask({
			variables: {
				projectTask,
			},
		}).then((data: any) => {});
	};

	const handleClose = (event: any, reason: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const cardBody = (card: ProjectTaskCardView) => (
		<div
			className={
				getTaskOverDueStatus(card?.dueDate) && card?.state !== 'DONE'
					? 'projectTaskOverdueCardContainer'
					: 'projectTaskCardContainer'
			}
			onClick={() => redirectToDetails(card?.projectId, card?.taskId)}
		>
			{card?.userId === userId && (
				<Tooltip title={'Add Timesheet'} placement='right'>
					<div className={'timesheetButton'}>
						<Button
							style={{ minWidth: 'auto' }}
							onClick={(event) => {
								event.stopPropagation();
								setCardDetail(card);
								setShowTimesheetForm(true);
							}}
							endIcon={<TimesheetButtonIcon className={'timesheetIcon'} />}
							className={'timesheetButtonStyle'}
						/>
					</div>
				</Tooltip>
			)}
			<div className={'projectTaskIdStyle'}>
				<div className={'taskIdContainer'}>
					{`${card?.prefix}-${card.unique_id}`}
				</div>
				<div style={{ display: 'flex', boxShadow: 'none', cursor: 'pointer' }}>
					<MoreVertIcon
						style={{
							height: '24px',
							paddingTop: '9px',
						}}
						fontSize='small'
						aria-label='more'
						aria-controls='long-menu'
						aria-haspopup='true'
						onClick={(event: any) => {
							event.stopPropagation();
							setAnchorEl(event.currentTarget);
							setTaskId(card?.taskId);
							setCloneTaskDetail(card);
							setCloneTaskName(`Clone-${card?.name}`);
						}}
					/>
					<Menu
						elevation={0}
						id='long-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={closePopOver}
						style={{ boxShadow: 'none' }}
					>
						<MenuItem
							onClick={(event: any) => {
								event.stopPropagation();
								setIsCloneModalOpen(true);
							}}
						>
							<FileCopyIcon
								style={{
									width: '10px',
									height: '10px',
								}}
							/>
							<div
								style={{
									fontFamily: 'Manrope-SemiBold',
									opacity: 1,
									letterSpacing: '0px',
									fontSize: '10px',
									paddingLeft: '5px',
								}}
							>
								Clone
							</div>
						</MenuItem>
						<MenuItem
							onClick={(event: any) => {
								event.stopPropagation();
								setIsDeleteModalOpen(true);
							}}
						>
							<DeleteIcon
								style={{
									color: 'rgb(220, 0, 78)',
									width: '10px',
									height: '10px',
								}}
							/>
							<div
								style={{
									fontFamily: 'Manrope-SemiBold',
									opacity: 1,
									letterSpacing: '0px',
									fontSize: '10px',
									paddingLeft: '5px',
								}}
							>
								Delete
							</div>
						</MenuItem>
					</Menu>
				</div>
			</div>
			<Tooltip title={`${card.name}`} placement='right'>
				<div
					style={{
						paddingLeft: '8px',
					}}
					className={`${'projectTaskHeaderStyle'} ${ellipsis.ellipsis}`}
				>
					{card.name}
				</div>
			</Tooltip>
			<div className={'projectCategoryContainer'}>
				<Tooltip title={`${card.categoryName || ''}`} placement='right'>
					<div className={'projectTaskCategoryName'}>
						{card?.categoryName || ''}
					</div>
				</Tooltip>

				{card?.subTaskCount && card?.subTaskCount > 0 ? (
					<div className={'subTask'} title='Subtask'>
						<div
							className={'numberofSubTask'}
							style={{
								display: 'flex !important',
								justifyContent: 'flex-end !important',
							}}
						>{`${card?.subTaskCount}`}</div>
						<SubTaskIcon className='taskIcon' />
					</div>
				) : (
					''
				)}
			</div>
			<Divider />
			<div className='projectTaskCardFooter'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p className={'projectTaskContainerStyle'}>
						<Tooltip title={card?.fullName || 'Unassigned'} placement='bottom'>
							<Avatar
								style={{
									width: '20px',
									height: '20px',
									fontSize: '12px',
									marginRight: '12px',
								}}
								src={`${card?.profilePic || ''}`}
							>
								{card?.fullName?.charAt(0) || 'Unassigned'}
							</Avatar>
						</Tooltip>
					</p>

					{card?.state !== 'DONE' && (
						<p
							className={'projectTaskCreatedDate'}
							title={`${dayjs(card?.dueDate).format(dateFormat)}`}
						>
							{card?.dueDate ? getDueDate(card?.dueDate) : '--'}
						</p>
					)}
				</div>
				<div className='progressBarContainer'>
					<LinerProgressTask
						variant='determinate'
						value={Number(card?.completion_percentage?.toFixed()) || 0}
					/>
					<div style={{ marginLeft: '4px', fontSize: '10px' }}>
						{`${card?.completion_percentage?.toFixed() || 0}%`}
					</div>
				</div>
				<div
					style={{
						width: '52px',
					}}
				>
					<div
						title={'Priority'}
						style={{
							padding:
								card?.priority === 'Medium'
									? '4px 8px 4px 8px'
									: '4px 8px 4px 14px',
						}}
						className={`${'projectTaskChipStyle'} ${
							card.priority === 'High'
								? 'projectTaskPriorityHigh'
								: card.priority === 'Medium'
								? 'projectTaskPriorityMedium'
								: card.priority === 'Low'
								? 'projectTaskPriorityLow'
								: undefined
						}`}
					>
						{card.priority}
					</div>
				</div>
			</div>
		</div>
	);
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<div className={'projectTaskContainer'}>
				{isCreateTaskModalShown &&(
					<CreateTaskForm
					onCreateTask={() => {}}
					kanbanColumnId={kanbanColumnId}
					open={isCreateTaskModalShown}
					onClose={() => setIsCreateTaskModalShown(false)}
					boardId={boardId}
					onSuccess={() => {
						loadKanban();
					}}
				/>
				)}
				
			</div>
			<div
				style={{
					paddingTop: '33px',
				}}
			>
				<Kanban
					onAddCardToColumn={(columnId: string) => {
						setKanbanColumnId(columnId);
						setIsCreateTaskModalShown(true);
						// TODO To implement add card to column directly
					}}
					columns={kanbanColumnViews}
					updateOrdinal={updateOrdinal}
				>
					{cardBody}
				</Kanban>
			</div>
			<DeleteModal
				open={isDeleteModalOpen}
				onClose={closePopOver}
				confirmationMessage={'Do you really want to delete this Task?'}
				onDelete={() => {
					handleDelete();
				}}
			/>
			<TaskCloneModal
				open={isCloneModalOpen}
				onClose={() => {
					setIsCloneModalOpen(false);
					setAnchorEl(null);
				}}
				handleSubmit={handleCloneTask}
				setCloneTaskName={setCloneTaskName}
				cloneTaskName={cloneTaskName}
				setIsCloneSubtask={setIsSubTaskClone}
				isProjectTaskSubTask={
					(taskList && taskList?.project_task?.length > 0) || false
				}
			/>
			<ThemeProvider theme={theme}>
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={open}
					onClose={handleClose}
					message=''
					action={
						<div style={{ width: '380px' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<div style={{ display: 'flex' }}>
									<CheckCircleIcon
										style={{ color: 'green', marginLeft: '10px' }}
									/>{' '}
									<div
										style={{
											marginLeft: '10px',
											font: 'normal normal 800 14px/23px Manrope',
										}}
									>
										{PROJECT_TASK_CLONNING_COMPLETE}
									</div>
								</div>
								<div>
									<IconButton
										size='small'
										style={{
											color: 'black',
										}}
										onClick={() => setOpen(false)}
									>
										<CloseIcon fontSize='small' />
									</IconButton>{' '}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									marginLeft: '48px',
									marginTop: '10px',
									marginBottom: '5px',
								}}
							>
								{PROJECT_TASK_CLONE_MESSAGE}
							</div>
							<div
								className={'hoverText'}
								onClick={() => redirectToDetails(projectId, clonnedTaskId)}
							>
								{PROJECT_TASK_CLONNING_OPEN}
							</div>
						</div>
					}
				/>
				<TimesheetGlobalForm
					open={showTimesheetForm}
					onClose={() => {
						setShowTimesheetForm(false);
					}}
					timesheetEntries={[]} // No need to pass (only for Weekly entry)
					projectOptions={
						projectOptions ? getProjectOptions(projectOptions) : []
					}
					initialValues={{}}
					isWeeklyTimesheetForm={false}
					onSubmit={() => {
						// Only for Weekly entry
					}}
					userJoinDate={''}
					projectTaskDetail={projectTaskDetail}
				/>
			</ThemeProvider>
		</>
	);
});
export default ProjectKanban;
