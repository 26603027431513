import React, { useState } from 'react';

import AppraisalCycleList from './AppraisalCycleList.component';
import { Box, Button } from '@material-ui/core';

import {
	APPRAISAL_CONTAINER,
	GOAL_CONTAINER,
	VISION_CONTAINER,
} from './constant';
import { globalStyles } from '../../../Layout/styles';
import VisionList from './VisionList.component';
import OrgGoalList from './OrgGoalList.component';

function PMSAdmin() {
	const commonStyles = globalStyles();
	const [container, setContainer] = useState(APPRAISAL_CONTAINER);

	return (
		<div>
			<Box display={'flex'} width={500}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						container === APPRAISAL_CONTAINER
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => setContainer(APPRAISAL_CONTAINER)}
				>
					Appraisal
				</Button>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						container === VISION_CONTAINER
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => setContainer(VISION_CONTAINER)}
				>
					Vision
				</Button>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						container === GOAL_CONTAINER
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => setContainer(GOAL_CONTAINER)}
				>
					Goal
				</Button>
			</Box>

			{container === APPRAISAL_CONTAINER && (
				<div>
					<AppraisalCycleList />
				</div>
			)}
			{container === VISION_CONTAINER && (
				<div>
					<VisionList />
				</div>
			)}
			{container === GOAL_CONTAINER && (
				<div>
					<OrgGoalList />
				</div>
			)}
		</div>
	);
}

export default PMSAdmin;
