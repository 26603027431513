import React, { useContext } from 'react';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import LeaveSetting from './LeaveSetting.component';
import AttendanceRegularizationSettings from './AttendanceRegularizationSettings.component';
import { UserProfileContext } from '../../../App';

export const AttendanceSettings = () => {
	const { featureFlag } = useContext<any>(UserProfileContext);

	return (
		<>
			<SettingsDetailContainer heading=''>
				<LeaveSetting />
			</SettingsDetailContainer>
			{featureFlag?.attendanceRegularization && (
				<SettingsDetailContainer heading='Attendance Regularization'>
					<AttendanceRegularizationSettings />
				</SettingsDetailContainer>
			)}
		</>
	);
};

export default AttendanceSettings;
