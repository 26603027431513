import React, { ReactNode, useEffect, useState, useContext } from 'react';
import {
	Box,
	Typography,
	makeStyles,
	ThemeProvider,
	CardContent,
	Tooltip as MuiTooltip,
} from '@material-ui/core';
import { insightViewStyle } from '../../../Layout/styles';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import { GET_PROJECT } from '../../ResourcePlanner/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getCurrencyFormat } from '../../../Utils/string.util';
import { Col, Row, Typography as Typo, Card } from 'antd';
import { insightChartTheme } from '../../../Layout/Theme.component';
import { billablePieChartColor } from '../../../config/constant';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { useGetActualProjectCostQuery } from '../../../generated/graphql';
import { GET_PROJECT_ACTUAL_COST } from '../Projects.gql';
import { UserProfileContext } from '../../../App';
const { Text } = Typo;
interface TimesheetStatus {
	label?: string;
	id?: string;
}

interface TimesheetSubmission {
	timesheet_status?: TimesheetStatus;
}

interface ProjectResourceMapping {
	id?: string;
	resource_cost?: number[] | null;
	project?: Project;
}

interface ProjectAggregateMapping {
	resource_cost?: number[] | null;
}

interface UserByEmployeeId {
	full_name?: string | null;
	project_resource_mappings?: ProjectResourceMapping[];
	project_resource_mappings_aggregate?: ProjectAggregateMapping[];
}
interface Project {
	name?: string;
}
interface ITimeSheet {
	working_hours: string;
}
interface IActualCost {
	id?: string;
	task_id?: string;
	is_billable?: boolean;
	working_hours?: string;
	date?: string;
	timesheet_submission?: TimesheetSubmission | null;
	userByEmployeeId?: UserByEmployeeId | null;
	project?: Project;
}

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '424px',
		width: '500px',
		flexWrap: 'wrap',
	},
	countContainer: {
		minWidth: '110px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
	},
	chartHeading: {
		fontSize: '13px',
		fontFamily: 'Manrope-bold',
	},
	count: {
		fontSize: '12px',
	},
	noDataFound: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
	},
}));

const CountCard = ({
	label,
	value,
	toolTipLabel,
}: {
	label: string;
	value: ReactNode;
	toolTipLabel: string;
}) => {
	const classes = insightViewStyle();
	const styles = useStyles();

	return (
		<MuiTooltip title={toolTipLabel} placement={'top'}>
			<Box className={styles.countContainer}>
				<Box className={classes.countLabel}>{label}</Box>
				<Box className={classes.countValue}>{value}</Box>
			</Box>
		</MuiTooltip>
	);
};

const ChartCard = ({ title, dataSet }: { title: string; dataSet: any }) => {
	const styles = useStyles();

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Typography className={styles.chartHeading}>{title}</Typography>

				<CardContent
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ResponsiveContainer width={150} height={150}>
						{dataSet && dataSet?.length > 0 ? (
							<PieChart width={70} height={70}>
								<Pie
									isAnimationActive={false}
									data={dataSet}
									dataKey={'value'}
									innerRadius={20}
								>
									{dataSet.map((e: any, index: number) => (
										<Cell
											key={index}
											fill={
												billablePieChartColor[
													index % billablePieChartColor.length
												]
											}
										/>
									))}
								</Pie>
								<Legend
									iconSize={10}
									wrapperStyle={{
										width: '155px',
										position: 'none',
										fontSize: '10px',
										fontFamily: 'Manrope-semibold',
										color: '#5C5C5C',
									}}
								/>
								<Tooltip />
							</PieChart>
						) : (
							<Box display='flex' alignItems='center' justifyContent='center'>
								<Typography className={styles.noDataFound}>
									No data found
								</Typography>
							</Box>
						)}
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
const ProjectDetailList = () => {
	const { id }: { id: string } = useParams();
	const [actualCost, setActualCost] = useState<number>(0);
	const [projectMargin, setProjectMargin] = useState<number | undefined>();
	const [computedActualTime, setComputedActualTime] = useState(0);
	const styles = useStyles();
	const { isOvertimeEnabled } = useContext<any>(UserProfileContext);
	const { data: projectDetails } = useApolloQuery(GET_PROJECT, {
		variables: {
			projectId: id || null,
		},
		fetchPolicy: 'network-only',
	});

	const { data: projectActualCost } = useGetActualProjectCostQuery({
		variables: {
			projectId: id,
		},
		fetchPolicy: 'network-only',
	});
	const { data: projectActualTime } = useApolloQuery(GET_PROJECT_ACTUAL_COST, {
		variables: {
			projectId: id,
		},
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!projectActualCost && projectDetails) {
			return;
		}
		const projectBudget = projectDetails?.project_by_pk?.total_cost;
		const aggregateTimesheetData = isOvertimeEnabled
			? [
					...(projectActualCost?.timesheet || []),
					...(projectActualCost?.timesheet_overtime || []),
			  ]
			: projectActualCost?.timesheet || [];
		const actualCost = aggregateTimesheetData
			.map((timesheetEntry) => ({
				hours: dayjs
					.duration({
						hours: Number(timesheetEntry?.working_hours?.split(':')[0]),
						minutes: Number(timesheetEntry?.working_hours?.split(':')[1]),
						seconds: Number(timesheetEntry?.working_hours?.split(':')[2]),
					})
					.asHours(),
				resourceCost:
					timesheetEntry?.userByEmployeeId?.project_resource_mappings_aggregate
						?.aggregate?.max?.resource_cost,
			}))
			.map((user) => user.hours * user.resourceCost)
			.reduce((total, currentVal) => total + currentVal, 0);

		setActualCost(actualCost || 0);
		const margin =
			projectBudget && actualCost
				? Number(((projectBudget - actualCost) / projectBudget) * 100).toFixed(
						1
				  )
				: '--';
		setProjectMargin(parseInt(margin));
	}, [isOvertimeEnabled, projectActualCost, projectDetails]);

	useEffect(() => {
		if (!projectActualTime) {
			return;
		}
		const aggregateTimesheetData: ITimeSheet[] = isOvertimeEnabled
			? [
					...projectActualTime?.timesheet,
					...projectActualTime?.timesheet_overtime,
			  ]
			: projectActualTime?.timesheet;
		const totalHours = aggregateTimesheetData
			.map((timeSheet: ITimeSheet) => timeSheet?.working_hours?.split(':'))
			.map((hoursAndMinutes: string[]) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total: any, durations: Duration) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);
		setComputedActualTime(totalHours?.asHours().toFixed(1) || 0);
	}, [isOvertimeEnabled, projectActualTime]);
	return (
		<>
			<Box>
				<Box display={'flex'} marginLeft='5px'>
					<CountCard
						label='Contract Value'
						toolTipLabel={'Actual contract value'}
						value={
							<Row
								justify='center'
								align='middle'
								style={{
									height: '100%',
								}}
							>
								<Col>
									<Text className={styles.count}>
										{projectDetails?.project_by_pk?.revenue
											? getCurrencyFormat(
													projectDetails?.project_by_pk?.revenue,
													projectDetails?.project_by_pk?.currency
														?.currency_type,
													true
											  )
											: '--'}
									</Text>
								</Col>
							</Row>
						}
					/>
					<CountCard
						label='Budget'
						toolTipLabel={'Budget allocated to the project'}
						value={
							<Row
								justify='center'
								align='middle'
								style={{
									height: '100%',
								}}
							>
								<Col>
									<Text className={styles.count}>
										{projectDetails?.project_by_pk?.total_cost
											? getCurrencyFormat(
													projectDetails?.project_by_pk?.total_cost,
													projectDetails?.project_by_pk?.currency
														?.currency_type,
													true
											  )
											: '--'}
									</Text>
								</Col>
							</Row>
						}
					/>
					<CountCard
						label='Budget(Planning)'
						toolTipLabel={'Budget based on resource allocation'}
						value={
							<Row
								justify='center'
								align='middle'
								style={{
									height: '100%',
								}}
							>
								<Col>
									<Text className={styles.count}>
										{projectDetails?.project_by_pk
											?.project_resource_mappings_aggregate?.aggregate?.sum
											?.total_cost
											? getCurrencyFormat(
													projectDetails?.project_by_pk
														?.project_resource_mappings_aggregate?.aggregate
														?.sum?.total_cost,
													projectDetails?.project_by_pk?.currency
														?.currency_type,
													true
											  )
											: '--'}
									</Text>
								</Col>
							</Row>
						}
					/>
					<CountCard
						label='Actual Cost'
						toolTipLabel={'Billable timesheet effort x employee cost'}
						value={
							<Row
								justify='center'
								align='middle'
								style={{
									height: '100%',
								}}
							>
								<Col>
									<Box
										marginTop='2px'
										className={styles.count}
										color={
											!projectDetails?.project_by_pk?.total_cost
												? 'black'
												: projectDetails?.project_by_pk?.total_cost >=
												  actualCost
												? '#34A853'
												: '#EA4335'
										}
									>
										{projectDetails?.project_by_pk?.total_cost && actualCost
											? getCurrencyFormat(
													actualCost?.toFixed(2) || 0,
													projectDetails?.project_by_pk?.currency
														?.currency_type,
													true
											  )
											: '--'}
									</Box>
								</Col>
							</Row>
						}
					/>
					<CountCard
						label='Project Margin %'
						toolTipLabel={''}
						value={
							<Row
								justify='center'
								align='middle'
								style={{
									height: '100%',
								}}
							>
								<Col>
									<Text className={styles.count}>{projectMargin || '--'}</Text>
								</Col>
							</Row>
						}
					/>
				</Box>
			</Box>
			<Box display={'flex'} marginLeft='5px' marginTop='50px'>
				<CountCard
					label='Estimated Hours'
					toolTipLabel={'Actual estimate as per contract'}
					value={
						<Row
							justify='center'
							align='middle'
							style={{
								height: '100%',
							}}
						>
							<Col>
								<Text className={styles.count}>
									{projectDetails?.project_by_pk?.effort_estimate
										? projectDetails?.project_by_pk?.effort_estimate
										: '--'}
								</Text>
							</Col>
						</Row>
					}
				/>
				<CountCard
					label='Planned Hours'
					toolTipLabel={'Effort based on billable resource allocation'}
					value={
						<Row
							justify='center'
							align='middle'
							style={{
								height: '100%',
							}}
						>
							<Col>
								<Text className={styles.count}>
									{projectDetails?.project_by_pk
										?.project_resource_mappings_aggregate?.aggregate?.sum
										?.total_hours
										? projectDetails?.project_by_pk?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
												1
										  )
										: '--'}
								</Text>
							</Col>
						</Row>
					}
				/>
				<CountCard
					label='Actual Hours'
					toolTipLabel={'Billable timesheet hours'}
					value={
						<Row
							justify='center'
							align='middle'
							style={{
								height: '100%',
							}}
						>
							<Col>
								<Box
									marginTop='2px'
									className={styles.count}
									color={
										!projectDetails?.project_by_pk?.effort_estimate
											? 'black'
											: projectDetails?.project_by_pk?.effort_estimate >=
											  computedActualTime
											? '#34A853'
											: '#EA4335'
									}
								>
									{projectDetails?.project_by_pk?.effort_estimate &&
									computedActualTime
										? computedActualTime
										: '--'}
								</Box>
							</Col>
						</Row>
					}
				/>
				<CountCard
					label='Effort Deviation(%)'
					toolTipLabel={''}
					value={
						<Row
							justify='center'
							align='middle'
							style={{
								height: '100%',
							}}
						>
							<Col>
								<Text className={styles.count}>
									{projectDetails?.project_by_pk?.effort_estimate
										? (
												((computedActualTime -
													projectDetails?.project_by_pk?.effort_estimate) /
													projectDetails?.project_by_pk?.effort_estimate) *
												100
										  ).toFixed()
										: '--'}
								</Text>
							</Col>
						</Row>
					}
				/>
			</Box>
			<Box display={'flex'} justifyContent='space-evenly' marginTop='50px'>
				<Box>
					<ChartCard
						title={`Milestone Progress: ${
							projectDetails?.project_by_pk?.completedMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalMilestones?.aggregate?.count >
								0
								? (
										(projectDetails?.project_by_pk?.completedMilestones
											?.aggregate?.count /
											projectDetails?.project_by_pk?.totalMilestones?.aggregate
												?.count) *
										100
								  ).toFixed(1)
								: '--'
						}%`}
						dataSet={
							projectDetails?.project_by_pk?.completedMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalMilestones?.aggregate?.count >
								0
								? [
										{
											name: 'Completed',
											value:
												projectDetails?.project_by_pk?.completedMilestones
													?.aggregate?.count,
										},
										{
											name: 'Pending',
											value:
												projectDetails?.project_by_pk?.totalMilestones
													?.aggregate?.count -
												projectDetails?.project_by_pk?.completedMilestones
													?.aggregate?.count,
										},
								  ]
								: []
						}
					/>
				</Box>
				<Box>
					<ChartCard
						title={`Task Progress: ${
							projectDetails?.project_by_pk?.completedTask?.aggregate?.count !==
								undefined &&
							projectDetails?.project_by_pk?.totalTask?.aggregate?.count !==
								undefined &&
							projectDetails?.project_by_pk?.totalTask?.aggregate?.count > 0
								? (
										(projectDetails?.project_by_pk?.completedTask?.aggregate
											?.count /
											projectDetails?.project_by_pk?.totalTask?.aggregate
												?.count) *
										100
								  ).toFixed(1)
								: '--'
						}%`}
						dataSet={
							projectDetails?.project_by_pk?.completedTask?.aggregate?.count !==
								undefined &&
							projectDetails?.project_by_pk?.totalTask?.aggregate?.count !==
								undefined &&
							projectDetails?.project_by_pk?.totalTask?.aggregate?.count > 0
								? [
										{
											name: 'Completed',
											value:
												projectDetails?.project_by_pk?.completedTask?.aggregate
													?.count,
										},
										{
											name: 'Pending',
											value:
												projectDetails?.project_by_pk?.totalTask?.aggregate
													?.count -
												projectDetails?.project_by_pk?.completedTask?.aggregate
													?.count,
										},
								  ]
								: []
						}
					/>
				</Box>
				<Box>
					<ChartCard
						title={`Payment Progress: ${
							projectDetails?.project_by_pk?.paymentcompletedMilestones
								?.aggregate?.count !== undefined &&
							projectDetails?.project_by_pk?.totalPaymentMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalPaymentMilestones?.aggregate
								?.count > 0
								? (
										(projectDetails?.project_by_pk?.paymentcompletedMilestones
											?.aggregate?.count /
											projectDetails?.project_by_pk?.totalPaymentMilestones
												?.aggregate?.count) *
										100
								  ).toFixed(1)
								: '--'
						}%`}
						dataSet={
							projectDetails?.project_by_pk?.paymentcompletedMilestones
								?.aggregate?.count !== undefined &&
							projectDetails?.project_by_pk?.totalPaymentMilestones?.aggregate
								?.count !== undefined &&
							projectDetails?.project_by_pk?.totalPaymentMilestones?.aggregate
								?.count > 0
								? [
										{
											name: 'Completed',
											value:
												projectDetails?.project_by_pk
													?.paymentcompletedMilestones?.aggregate?.count,
										},
										{
											name: 'Pending',
											value:
												projectDetails?.project_by_pk?.totalPaymentMilestones
													?.aggregate?.count -
												projectDetails?.project_by_pk
													?.paymentcompletedMilestones?.aggregate?.count,
										},
								  ]
								: []
						}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ProjectDetailList;
