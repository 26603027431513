import {LinearProgress,withStyles} from '@material-ui/core';

export const LinerProgressTask = withStyles((theme) => ({
	root: {
		height: 7,
		borderRadius: 5,
		width: '30px',
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#3ABC9E',
	},
}))(LinearProgress);

