import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	useMutation,
	useRefresh,
	useNotify,
	ReferenceInput,
	AutocompleteInput,
} from 'react-admin';
import { Button, Typography, Box, Tooltip } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	currencyInputStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { ellipsisStyle } from '../../../Layout/styles';
import { Project, Status, ProjectStatus } from './modal';
import { FullName } from '../OpportunitiesKanban.model';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { PROJECT_TYPE_OPTIONS } from '../../constant';
import { regexNameValidation } from '../../Settings/constant';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
const useStyles = makeStyles((theme) => ({
	error: {
		color: 'red',
		fontSize: '10px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '20px',
		marginLeft: '6px',
		height: '5px',
	},
	dateInput: {
		marginTop: '8px',
		width: '190px',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	projectData: Project;
	projectChoices: Status[];
	projectStatus: ProjectStatus[];
	currencyList: Status[];
	refetch: () => void;
	projectName?: string;
}

const mapToFullName = (choice: FullName) => `${choice?.full_name || ''} `;

const ProjectForm = (props: Props) => {
	const {
		open,
		onClose,
		projectData,
		projectChoices,
		projectName,
		projectStatus,
		refetch,
		currencyList,
	} = props;
	const [projectError, setProjectError] = useState('');
	const [ownerId, setOwnerId] = useState('');
	const { dateFormat, orgBaseCurrency } = useContext<any>(UserProfileContext);
	const classes = modalFormStyle();
	const customStyle = currencyInputStyle();
	const styles = ellipsisStyle();
	const inlineStyle = useStyles();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const [opportunityCurrency, setOpportunityCurrency] = useState<any>({
		currency_id: orgBaseCurrency?.id,
	});
	const [endDateError, setEndDateError] = useState(false);
	const [startDateFieldError, setStartDateFieldError] = useState(false);
	const [projectStatusId, setProjectStatusId] = useState('');
	const [startDateFieldErrorText, setStartDateFieldErrorText] = useState('');
	const [endDateFieldError, setEndDateFieldError] = useState(false);
	const [endDateFieldErrorText, setEndDateFieldErrorText] = useState('');
	const [statusFieldError, setStatusFieldError] = useState(false);

	useEffect(() => {
		if (!projectData?.projectId) {
			return;
		}

		setProjectStatusId(projectData?.project_status_id || '');
		return;
	}, [projectData]);

	const onSave = (value: Project) => {
		if (!value) {
			return;
		}
		if (dayjs(value?.start_date).isAfter(dayjs(value?.end_date))) {
			setEndDateError(true);
			return;
		}
		if (!projectData?.projectId) {
			createProject(value);
		} else {
			updateProject(value);
		}
	};

	const updateProject = (ProjectFormValue: Project) => {
		const { name, ...restFormValues } = ProjectFormValue;

		mutate(
			{
				type: 'update',
				resource: 'project',
				payload: {
					id: projectData?.projectId,
					data: ProjectFormValue?.pre_sales_owner_id
						? {
								opportunity_id: projectData?.opportunity_id,
								total_cost: ProjectFormValue?.revenue,
								name: String(ProjectFormValue?.name).trim(),

								...restFormValues,
						  }
						: {
								pre_sales_owner_id: ownerId,
								opportunity_id: projectData?.opportunity_id,
								total_cost: ProjectFormValue?.revenue,
								name: String(ProjectFormValue?.name).trim(),

								...restFormValues,
						  },
				},
			},
			{
				onSuccess: ({ data }) => {
					refetch();
					handleClose();
					notify('Projects updated Successfully');
					refresh();
				},
				onFailure: (error) => {
					if (error.message.includes('Uniqueness violation')) {
						setProjectError(
							'Project Name already exists please give another name'
						);
					} else {
						handleClose();
						notify(`Create failed: ${error.message}`, 'warning');
					}
				},
			}
		);
	};
	const createProject = (value: Project) => {
		mutate(
			{
				type: 'create',
				resource: 'project',
				payload: {
					data: {
						...value,
						total_cost: value?.revenue,
						opportunity_id: projectData?.opportunity_id,
						company_id: projectData?.company_id,
						currency_id: projectData?.currency_id,
						name: String(value?.name).trim(),
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					refetch();
					handleClose();
					notify('Projects Created Successfully');
					refresh();
				},
				onFailure: (error) => {
					if (error.message.includes('Uniqueness violation')) {
						setProjectError(
							'Project Name already exists please give another name'
						);
					} else {
						handleClose();
						notify(`Create failed: ${error.message}`, 'warning');
					}
				},
			}
		);
	};

	const getProjectStatus = (status: any) => {
		if (!status) {
			return null;
		}
		const response = projectStatus?.find(
			(value: { id: string }) => value?.id === status
		);
		return response ? { id: response?.id, name: response?.label } : null;
	};

	const handleClose = () => {
		setEndDateError(false);
		onClose();
	};
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Tooltip title={`${projectData?.CompanyName}`} placement='right'>
							<Box
								className={[classes.heading, styles.ellipsis].join(' ')}
								width='300px'
							>
								{projectData?.projectId ? 'Edit Project' : 'Add Project'}:
								{projectName ? projectName : projectData?.CompanyName}
							</Box>
						</Tooltip>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form initialValues={projectData} onSubmit={onSave}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Project Title*
												</Typography>
												<TextInput
													source='name'
													fullWidth={true}
													label={''}
													validate={validateName}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Project Type*</Box>
												<SelectInput
													choices={PROJECT_TYPE_OPTIONS}
													fullWidth
													source='project_type'
													validate={required()}
													label={''}
												/>
											</Box>
										</Box>
										<Typography className={classes.label}>
											Company Name
										</Typography>
										<ReferenceInput
											source='company_id'
											reference='crm_company'
											label={''}
											fullWidth
											disabled={true}
										>
											<AutocompleteInput
												optionText={(choice: any) => choice?.name}
												shouldRenderSuggestions={(name: any) =>
													name.trim().length > 0
												}
											/>
										</ReferenceInput>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Country Name
												</Typography>
												<ReferenceInput
													source='geo_id'
													reference='geo_list'
													sort={{ field: 'name', order: 'ASC' }}
													label=''
												>
													<SelectInput optionText='name' />
												</ReferenceInput>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Pre Sales Owner
												</Typography>
												<ReferenceInput
													source='pre_sales_owner_id'
													label=''
													reference='user'
													fullWidth
													filterToQuery={(searchText) => ({
														first_name: searchText,
													})}
													filter={{ first_name: true, is_active: true }}
													sort={{ field: 'first_name', order: 'ASC' }}
													onChange={(event: any) => setOwnerId(event || null)}
												>
													<AutocompleteInput
														optionText={mapToFullName}
														shouldRenderSuggestions={(name: any) => {
															return name.trim().length > 0;
														}}
														resettable={true}
													/>
												</ReferenceInput>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Contract Value
												</Typography>
												<Box display='flex'>
													<Box className={customStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<SelectInput
																choices={currencyList}
																source='currency_id'
																optionText='name'
																label={''}
																onChange={(e: any) => {
																	setOpportunityCurrency({
																		...opportunityCurrency,
																		currency_id: e.target.value,
																	});
																}}
															/>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput source='revenue' label={''} />
													</ThemeProvider>
												</Box>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Status *
												</Typography>
												<Box marginTop='8px'>
													<AutocompleteSearch
														placeholder={'Search Status'}
														option={projectChoices || []}
														onChange={(value) => {
															if (!value) {
																setProjectStatusId('');
																setStatusFieldError(true);
																return;
															}
															setStatusFieldError(false);
															setProjectStatusId(value);
														}}
														name={'project_status_id'}
														validates={true}
														value={
															projectStatusId
																? getProjectStatus(projectStatusId)
																: null
														}
													/>
													{statusFieldError && (
														<p className={inlineStyle.errorText}>Required!</p>
													)}
												</Box>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Start Date *
												</Typography>
												<Field
													name='start_date'
													validate={(value: any) => {
														if (!value) {
															return 'Required';
														}
														if (dayjs(value).isValid() === true) {
															return undefined;
														}
														if (value === 'Invalid Date') {
															setStartDateFieldError(true);
															setStartDateFieldErrorText('Invalid Date');
															return 'Invalid Date';
														}
														return 'Invalid Date';
													}}
												>
													{(props: any) => (
														<div className={inlineStyle.dateInput}>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value) => {
																	if (!value) {
																		setStartDateFieldError(true);
																		setStartDateFieldErrorText('Required');
																		return;
																	}
																	setEndDateError(false);
																	setStartDateFieldError(false);
																	setStartDateFieldErrorText('');
																	props.input.onChange(value);
																}}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
												{startDateFieldError && (
													<p className={inlineStyle.errorText}>
														{startDateFieldErrorText}
													</p>
												)}
											</Box>

											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													End Date *
												</Typography>
												<Field
													name='end_date'
													validate={(value: any) => {
														if (!value) {
															return 'Required';
														}
														if (dayjs(value).isValid() === true) {
															return undefined;
														}
														if (value === 'Invalid Date') {
															setEndDateFieldError(true);
															setEndDateFieldErrorText('Invalid Date');
															return 'Invalid Date';
														}
														return 'Invalid Date';
													}}
												>
													{(props: any) => (
														<div className={inlineStyle.dateInput}>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value) => {
																	if (!value) {
																		setEndDateFieldError(true);
																		setEndDateFieldErrorText('Required');
																		return;
																	}
																	setEndDateError(false);
																	setEndDateFieldError(false);
																	setEndDateFieldErrorText('');
																	props.input.onChange(value);
																}}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
												{endDateError && (
													<Box
														className={inlineStyle.errorText}
														marginBottom='20px'
													>
														End date should be greater than start date
													</Box>
												)}
												{endDateFieldError && (
													<p className={inlineStyle.errorText}>
														{endDateFieldErrorText}
													</p>
												)}
											</Box>
										</Box>
										<Typography className={classes.label}>
											Project Overview
										</Typography>
										<TextInput source='description' label='' multiline={true} />
										{projectError ? (
											<Box className={inlineStyle.error}>{projectError}</Box>
										) : null}
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={handleClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || startDateFieldError || endDateFieldError
														? classes.disabledButton
														: classes.saveButton
												}
												disabled={
													invalid || startDateFieldError || endDateFieldError
												}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectForm;
