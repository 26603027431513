export const USER_TYPE_OPTIONS = [
	{ id: 'contract_employee', name: 'Contract' },
	{ id: 'employee', name: 'Employee' },
	{ id: 'support', name: 'Support' },
];

export const USER_EXPORT_COLUMNS = [
	'NAME',
	'EMAIL',
	'USER TYPE',
	'ROLE',
	'STATUS',
	'PHONE NUMBER',
];
