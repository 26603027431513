import React, { useEffect, useState, useContext, useCallback } from 'react';
import MUIDataTable, { ExpandButton, FilterType } from 'mui-datatables';
import {
	Box,
	TableBody,
	TableCell,
	TableRow,
	makeStyles,
	ThemeProvider,
	TableContainer,
	Paper,
	Table,
	TableHead,
	MenuItem,
	TextField as TextComponent,
	Typography,
	CircularProgress,
	FormControl,
	InputLabel,
	Checkbox,
	Tooltip,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setSidebarVisibility, useNotify } from 'react-admin';
import { Select } from '@material-ui/core';
import { searchBarTheme } from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import {
	useGetDataGridFilterListQuery,
	useGetDataGridProjectTasksQuery,
	useGetExportDataGridTasksQuery,
	useGetProjectlistForDataGridFilterQuery,
	useGetProjectSubTaskListForDataGridQuery,
	useUpdatePriorityByIdMutation,
	useUpdateSubtaskStatusByIdMutation,
} from '../../generated/graphql';
import {
	statusSelectBoxTheme,
	TaskTableTheme,
	subTaskTableTheme,
	prioritySelectBoxTheme,
} from './dataGridThemes';
import { autocompleteStyle, dropDownEllipsisStyle } from '../../Layout/styles';
import { taskPriorityOptions } from '../constant';
import { TASK_UPDATE_FAIL_MESSAGE, TASK_UPDATE_MESSAGE } from './Constant';
import { head, orderBy, uniqBy } from 'lodash';
import { UserProfileContext } from '../../App';
import { useQuery as useApolloQuery } from '@apollo/client';
import objectSupport from 'dayjs/plugin/objectSupport';
import _ from 'lodash';
import { exportToCsv } from '../../Utils/string.util';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import { Form } from 'react-final-form';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
dayjs.extend(objectSupport);
const taskListStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		marginLeft: '20px',
		maxHeight: '200px',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Tasks'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};
export const TaskList = () => {
	const [taskTableList, setTaskTableList] = useState<any[]>([]);
	const [parentTaskId, setParentTaskId] = useState<string | null>(null);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [searchedText, setSearchedText] = useState<string>('');
	const [projectFilter, setProjectFilter] = useState<string | null>('');
	const [selectedProject, setSelectedProject] = useState<string | null>('');
	const [milestoneFilter, setMilestoneFilter] = useState<string | null>('');
	const [selectedMilestone, setSelectedMilestone] = useState<string | null>('');
	const [statusFilter, setStatusFilter] = useState<string | null>('');
	const [selectedStatus, setSelectedStatus] = useState<string | null>('');
	const [priorityFilter, setPriorityFilter] = useState<string | null>('');
	const [selectedPriority, setSelectedPriority] = useState<string | null>('');
	const [sortFilter, setSortFilter] = useState<any[]>([]);
	const filterType: FilterType = 'custom';
	const dispatch = useDispatch();
	const taskListStyles = taskListStyle();
	const ellipsis = dropDownEllipsisStyle();
	const { dateFormat, featureFlag } = useContext<any>(UserProfileContext);
	const [rowsExpanded, setRowsExpanded] = useState<any[]>();
	const [projectArray, setProjectArray] = useState<any[]>();
	const [mileStoneList, setMileStoneList] = useState<any[]>();
	const [statusList, setStatusList] = useState<any[]>();
	const [selectedUserTeamId, setSelectedUserTeamId] = useState<any>();
	const [userTeamList, setUserTeamList] = useState<any[]>();
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [updateTaskStatusById] = useUpdateSubtaskStatusByIdMutation();
	const [updatePriorityById] = useUpdatePriorityByIdMutation();
	const [isExportTriggered, setisExportTriggered] = useState(false);
	const [exportColumns, setExportColumns] = useState<any>([]);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any[]>(
		[]
	);
	const dropDownStyle = autocompleteStyle();
	const { data: latestFilterList } = useGetDataGridFilterListQuery({
		variables: {
			filters: {
				deleted_at: { _is_null: true },
				id: projectFilter ? { _eq: projectFilter } : {},
			},
		},
	});
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});
	const { data: projectFilterOptions } =
		useGetProjectlistForDataGridFilterQuery();

	const { data: exportTaskList } = useGetExportDataGridTasksQuery({
		variables: {
			sortFilter: sortFilter,
			filter: milestoneFilter
				? {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
						project_milestone: milestoneFilter
							? { id: { _eq: milestoneFilter } }
							: {},
				  }
				: {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
				  },
		},
		skip: !isExportTriggered,
	});
	const getExportData = useCallback(
		(data: any) => {
			const exportHeadings =
				exportColumns?.map((column: any) => column?.label) || [];
			const task =
				exportTaskList?.project_task?.map((task) => {
					return [
						task?.title,
						...(exportHeadings?.includes('Project Name')
							? task?.project?.name
								? [task?.project?.name]
								: ['--']
							: []),
						...(exportHeadings?.includes('Milestone')
							? task?.project_milestone?.name
								? [task?.project_milestone?.name]
								: ['--']
							: []),
						...(exportHeadings?.includes('Status')
							? head(task?.project_kanban_cards)?.kanban_column?.name
								? [head(task?.project_kanban_cards)?.kanban_column?.name]
								: ['--']
							: []),
						...(exportHeadings?.includes('Priority')
							? task?.priority
								? [task?.priority]
								: ['--']
							: []),
						...(exportHeadings?.includes('Assigned to')
							? task?.assignedTo?.full_name
								? [task?.assignedTo?.full_name]
								: ['--']
							: []),
						...(exportHeadings?.includes('Start Date')
							? task?.planned_start_date
								? [dayjs(task?.planned_start_date).format(dateFormat)]
								: ['--']
							: []),
						...(exportHeadings?.includes('End Date')
							? task?.planned_end_date
								? [dayjs(task?.planned_end_date).format(dateFormat)]
								: ['--']
							: []),
						...(exportHeadings?.includes('Billable')
							? task?.is_billable
								? task?.is_billable === true
									? ['Yes']
									: ['No']
								: ['--']
							: []),
						...(exportHeadings?.includes('Duration(hrs)')
							? task?.planned_hours
								? [Number(task?.planned_hours).toFixed()]
								: ['--']
							: []),
						...(exportHeadings?.includes('Completion(%)')
							? task?.completion_percentage !== null
								? [Number(task?.completion_percentage).toFixed()]
								: ['--']
							: []),
					];
				}) || [];
			exportToCsv('Project_Task_Export.csv', [...[exportHeadings], ...task]);

			setisExportTriggered(false);
		},
		[dateFormat, exportColumns, exportTaskList]
	);

	useEffect(() => {
		if (!exportTaskList) {
			return;
		}
		getExportData(exportTaskList);
	}, [exportTaskList, getExportData]);

	const {
		data: taskList,
		loading: isDataGridLoading,
		refetch: refetchTaskList,
	} = useGetDataGridProjectTasksQuery({
		variables: {
			sortFilter: sortFilter,
			filter: milestoneFilter
				? {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
						project_milestone: milestoneFilter
							? { id: { _eq: milestoneFilter } }
							: {},
				  }
				: {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
				  },
			totatTaskCountFilter: milestoneFilter
				? {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
						project_milestone: milestoneFilter
							? { id: { _eq: milestoneFilter } }
							: {},
				  }
				: {
						parent_task_id: { _is_null: true },
						deleted_at: { _is_null: true },
						title: { _ilike: `%${searchedText}%` },
						project_kanban_cards: statusFilter
							? { kanban_column: { id: { _eq: statusFilter } } }
							: {},
						assigned_to:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						priority: priorityFilter ? { _eq: priorityFilter } : {},
						project: projectFilter ? { id: { _eq: projectFilter } } : {},
				  },
			limit: rowsPerPage,
			offset: page * rowsPerPage,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: parentSubTaskList,
		loading: isSubTaskListLodaing,
		refetch: refetchSubTaskList,
	} = useGetProjectSubTaskListForDataGridQuery({
		variables: {
			parentTaskId: parentTaskId,
		},
		skip: !parentTaskId,
		fetchPolicy: 'network-only',
	});

	const notify = useNotify();
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	useEffect(() => {
		if (!projectFilterOptions) {
			return;
		}
		setProjectArray(projectFilterOptions?.project_list);
	}, [projectFilterOptions]);

	useEffect(() => {
		if (!latestFilterList) {
			return;
		}
		const mileStoneArrayList = _.chain(latestFilterList?.project_list)
			?.map((project) => {
				const milestoneList = project?.projects?.project_milestones || [];
				return milestoneList;
			})
			.filter((item) => item?.length > 0)
			.value()
			.flat();
		const milestone = uniqBy(mileStoneArrayList, (status) => status.name);
		setMileStoneList(
			orderBy(
				milestone,
				[(milestonelist) => milestonelist.name.toUpperCase()],
				['asc']
			)
		);
		const statusArrayList = _.chain(latestFilterList?.project_list)
			?.map((project) => {
				const milestoneList =
					project?.projects?.kanban_board?.kanban_columns || [];
				return milestoneList;
			})
			.filter((item) => item?.length > 0)
			.value()
			.flat();
		const taskStatus = uniqBy(statusArrayList, (list) => list.name);
		const status = orderBy(
			taskStatus,
			[(status) => status?.name.toUpperCase()],
			['asc']
		);
		setStatusList(status);

		const assigneArrayList = _.chain(latestFilterList?.project_list)
			?.map((project) => {
				const userList =
					project?.projects?.project_tasks?.map((user) => {
						return {
							id: user?.assignedTo?.id,
							name: user?.assignedTo?.full_name,
						};
					}) || [];
				return userList;
			})
			.filter((item) => item?.length > 0)
			.value()
			.flat();
		const assigneelist = uniqBy(assigneArrayList, (list) => list?.name);
		setAssigneeRecommendations(
			orderBy(assigneelist, [(user) => user?.name?.toUpperCase()], ['asc'])
		);
	}, [latestFilterList]);
	useEffect(() => {
		if (!userTeams) {
			return;
		}
		const teamList =
			userTeams && userTeams?.user_team
				? userTeams?.user_team?.map((team: { id: string; name: string }) => {
						return {
							id: team?.id,
							name: team?.name,
						};
				  })
				: [];
		setUserTeamList(teamList);
	}, [userTeams]);

	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);
	useEffect(() => {
		if (!userTeams) {
			return;
		}
		const teamList =
			userTeams && userTeams?.user_team
				? userTeams?.user_team?.map((team: { id: string; name: string }) => {
						return {
							id: team?.id,
							name: team?.name,
						};
				  })
				: [];
		setUserTeamList(teamList);
	}, [userTeams]);
	useEffect(() => {
		if (!taskList) {
			setTaskTableList([]);
			return;
		}
		const task = taskList?.project_task?.map((task) => {
			const colums = task?.project?.kanban_board?.kanban_columns?.map(
				(column) => {
					return {
						id: column?.id,
						name: column?.name,
						cardId: task?.project_kanban_cards
							? head(task?.project_kanban_cards)?.id
							: '',
					};
				}
			);
			return {
				taskId: task?.id,
				taskName: task?.title,
				projectName: task?.project?.name || '--',
				mileStone: task?.project_milestone?.name || '--',
				status: {
					name: task?.project_kanban_cards
						? head(task?.project_kanban_cards)?.kanban_column?.name
						: '--',
					id: task?.project_kanban_cards
						? head(task?.project_kanban_cards)?.kanban_column?.id
						: '--',
					cardId: task?.project_kanban_cards
						? head(task?.project_kanban_cards)?.id
						: '--',
				},
				statusId: head(task?.project_kanban_cards)?.kanban_column?.id || null,
				statusName: task?.project_kanban_cards
					? head(task?.project_kanban_cards)?.kanban_column?.name
					: '--',
				assignedTo: task?.assignedTo?.full_name || '--',
				startDate: task?.planned_start_date
					? dayjs(task?.planned_start_date).format(dateFormat)
					: '--',
				endDate: task?.planned_end_date
					? dayjs(task?.planned_end_date).format(dateFormat)
					: '--',
				isBillable: task?.is_billable === true ? 'Yes' : 'No',
				priority: task?.priority || '--',
				completionPercentage:
					task?.completion_percentage !== null
						? Number(task?.completion_percentage).toFixed()
						: '--',
				duration: task?.planned_hours
					? Number(task?.planned_hours).toFixed()
					: '--',
				statusList: colums,
			};
		});
		setTaskTableList(task);
	}, [dateFormat, taskList]);

	const onTaskStatusChange = (status: string, task_id: string) => {
		updateTaskStatusById({
			variables: {
				id: task_id,
				column_id: status,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(TASK_UPDATE_MESSAGE);
					refetchTaskList();
					return;
				}
				if (response?.errors) {
					notify(TASK_UPDATE_FAIL_MESSAGE);
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const onTaskPriorityChange = (priority: string, task_id: string) => {
		updatePriorityById({
			variables: {
				id: task_id,
				priority,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Task updated successfully');
					refetchTaskList();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const onSubTaskStatusChange = (status: string, task_id: string) => {
		updateTaskStatusById({
			variables: {
				id: task_id,
				column_id: status,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(TASK_UPDATE_MESSAGE);
					refetchSubTaskList();
					return;
				}
				if (response?.errors) {
					notify(TASK_UPDATE_FAIL_MESSAGE);
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const onSubTaskPriorityChange = (priority: string, task_id: string) => {
		updatePriorityById({
			variables: {
				id: task_id,
				priority,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Task updated successfully');
					refetchSubTaskList();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedResourceOptionValue &&
				selectedResourceOptionValue?.length === 1
					? selectedResourceOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const durationSort = (
		firstValue: string | number,
		secondValue: string | number,
		order: string
	) => {
		const nullPosition = order === 'asc' ? 1 : -1;
		if (firstValue === '--') {
			return nullPosition;
		}
		if (secondValue === '--') {
			return -nullPosition;
		}
		if (Number(firstValue) < Number(secondValue)) {
			return -nullPosition;
		}
		if (Number(firstValue) > Number(secondValue)) {
			return nullPosition;
		}
		return 0;
	};

	const columns = [
		{
			name: 'taskName',
			label: 'Name',
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'projectName',
			label: 'Project Name',
			options: {
				filter: true,
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Project</InputLabel>
								<Select
									value={selectedProject}
									onChange={(event: any) => {
										setSelectedProject(event?.target?.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{projectArray?.map((item) => (
										<MenuItem
											onClick={() => setProjectFilter(item?.id)}
											key={item.id}
											value={item.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'mileStone',
			label: 'Milestone',
			options: {
				filter: true,
				filterType: filterType,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <Box className={ellipsis.ellipsis}>{value}</Box>;
				},
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>
									Milestone
								</InputLabel>
								<Select
									value={selectedMilestone}
									onChange={(event: any) => {
										setSelectedMilestone(event.target.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{mileStoneList?.map((item) => (
										<MenuItem
											onClick={() => setMilestoneFilter(item?.id)}
											key={item?.id}
											value={item?.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'statusName',
			label: 'Status',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const statusOptions = orderBy(
						tableMeta?.rowData[13],
						(option) => option?.name.toUpperCase(),
						['asc']
					);
					const currentStatus = statusOptions?.find(
						(status: { id: string; name: string }) =>
							status.id === tableMeta?.rowData[14]
					);
					return (
						<>
							<ThemeProvider theme={statusSelectBoxTheme}>
								<Select
									className={taskListStyles.statusSelect}
									value={currentStatus?.id}
									onChange={(event: any) =>
										onTaskStatusChange(
											event?.target?.value,
											currentStatus?.cardId
										)
									}
									disableUnderline={true}
									variant='outlined'
								>
									{statusOptions &&
										statusOptions?.map(
											(option: { id: string; name: string }) => (
												<MenuItem key={option?.id} value={option?.id}>
													{option?.name}
												</MenuItem>
											)
										)}
								</Select>
							</ThemeProvider>
						</>
					);
				},
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Status</InputLabel>
								<Select
									value={selectedStatus}
									onChange={(event: any) => {
										filterList[index][0] = event.target.value;
										setSelectedStatus(event.target.value);
										onChange(filterList[index], index, column);
									}}
								>
									{statusList?.map((item) => (
										<MenuItem
											onClick={() => setStatusFilter(item?.id)}
											key={item?.id}
											value={item?.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'priority',
			label: 'Priority',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<ThemeProvider theme={prioritySelectBoxTheme}>
							<Select
								style={{
									background:
										value === 'High'
											? '#FFF3F2'
											: value === 'Medium'
											? '#EBF3FF'
											: value === 'Low'
											? '#E9F7EC'
											: '',
									color:
										value === 'High'
											? '#EA4335'
											: value === 'Medium'
											? '#4285F4'
											: value === 'Low'
											? '#34A853'
											: '',
									border:
										value === 'High'
											? '1 px solid #FFF3F2'
											: value === 'Medium'
											? '1 px solid #EBF3FF'
											: value === 'Low'
											? '1 px solid #E9F7EC'
											: '',
								}}
								labelId='demo-simple-select-filled-label'
								id='demo-simple-select-filled'
								value={value}
								onChange={(event: any) =>
									onTaskPriorityChange(
										event?.target?.value,
										tableMeta?.rowData[12]
									)
								}
								disableUnderline={true}
								variant='outlined'
							>
								{[
									{ id: 'Low', name: 'Low' },
									{ id: 'Medium', name: 'Medium' },
									{ id: 'High', name: 'High' },
								].map((option) => (
									<MenuItem key={option?.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</ThemeProvider>
					);
				},
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Priority</InputLabel>
								<Select
									value={selectedPriority}
									onChange={(event: any) => {
										setSelectedPriority(event.target.value);
										filterList[index][0] = event.target.value;
										setPriorityFilter(filterList[index][0]);
										onChange(filterList[index], index, column);
									}}
								>
									{[
										{ id: 'Low', name: 'Low' },
										{ id: 'Medium', name: 'Medium' },
										{ id: 'High', name: 'High' },
									]?.map((item) => (
										<MenuItem key={item?.id} value={item?.name}>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'userTeam',
			label: 'User Team',
			options: {
				display: false,
				filter: featureFlag?.userTeams,
				viewColumns: false,
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>
									User Team
								</InputLabel>
								<Select
									value={selectedUserTeamId}
									onChange={(event) => {
										setSelectedUserTeamId(event?.target?.value);
									}}
								>
									{userTeamList?.map((item) => (
										<MenuItem
											key={item?.id}
											value={item?.name}
											onClick={() => {
												setSelectedResourceOptionValues([]);
												setSelectedResourceIdList([]);
												setUserTeamId(item?.id);
												onChange(filterList[index], index, column);
												onChange([], 6, column);
											}}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'assignedTo',
			label: 'Assigned to',
			options: {
				filter: true,
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<Autocomplete
										placeholder='Assigned To'
										multiple
										limitTags={0}
										value={
											selectedResourceOptionValue &&
											selectedResourceOptionValue?.length > 0
												? selectedResourceOptionValue
												: []
										}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={(event, value, reason) => {
											if (!value) {
												return;
											}
											if (value && value?.length === 0) {
												filterList[index][0] = [];
												onChange([], index, column);
												return;
											}
											const selectedIds = value
												?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												)
												.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'select-option'
											) {
												setSelectedResourceOptionValues([
													{ id: 'All', name: 'All' },
													...assigneeRecommendations,
												]);
												const allResourceIds = assigneeRecommendations?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = assigneeRecommendations?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedResourceIdList(allResourceIds);
												return;
											}
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'remove-option' &&
												assigneeRecommendations?.length !== selectedIds?.length
											) {
												const currentOptions = value?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												);
												setSelectedResourceOptionValues(currentOptions);
												const currentIds = currentOptions?.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
												const allResourceNames = currentOptions?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedResourceIdList(currentIds);
												return;
											}
											if (
												selectedIds?.length ===
													assigneeRecommendations?.length &&
												reason === 'select-option'
											) {
												setSelectedResourceOptionValues([
													{ id: 'All', name: 'All' },
													...assigneeRecommendations,
												]);
												const reporteeIds = assigneeRecommendations?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = assigneeRecommendations?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedResourceIdList(reporteeIds);
												return;
											}
											if (
												selectedResourceOptionValue.find(
													(option: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => option?.id === 'All'
												) &&
												reason === 'remove-option'
											) {
												filterList[index][0] = [];
												onChange([], index, column);
												setSelectedResourceIdList([]);
												setSelectedResourceOptionValues([]);
												return;
											}
											const selectedId = value?.map((resource) => resource?.id);
											const allResourceNames = value?.map(
												(resource: any) => resource?.name
											);
											const nameArray = allResourceNames.join(`, `);
											filterList[index][0] = nameArray;
											onChange(filterList[index], index, column);
											setSelectedResourceIdList(selectedId);
											setSelectedResourceOptionValues(value);
										}}
										options={
											assigneeRecommendations &&
											assigneeRecommendations?.length > 0
												? [
														{ id: 'All', name: 'All' },
														...assigneeRecommendations,
												  ]
												: []
										}
										disableCloseOnSelect={true}
										getLimitTagsText={getResourceFilterLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<TextComponent {...params} label='Assigned To' />
										)}
										renderOption={(option, { selected }) => (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected}
													color='primary'
												/>

												<Tooltip
													title={`${option?.name || '- -'}`}
													placement='right'
												>
													<Typography className={`${ellipsis.ellipsis}`}>
														{`${option?.name || '- -'}`}
													</Typography>
												</Tooltip>
											</>
										)}
										getOptionLabel={(option) => `${option?.name}`}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
									/>
								)}
							</Form>
						);
					},
				},
			},
		},
		{
			name: 'startDate',
			label: 'Start Date',
			options: {
				filter: false,
			},
		},
		{
			name: 'endDate',
			label: 'End Date',
			options: {
				filter: false,
			},
		},
		{
			name: 'isBillable',
			label: 'Billable',
			options: {
				filter: false,
				sort: true,
			},
		},

		{
			name: 'duration',
			label: 'Duration(hrs)',
			options: {
				filter: false,
			},
		},
		{
			name: 'completionPercentage',
			label: 'Completion(%)',
			options: {
				filter: false,
				sortCompare:
					(order: string) =>
					(
						{ data: percentage1 }: { data: string },
						{ data: percentage2 }: { data: string }
					) =>
						durationSort(percentage1, percentage2, order),
			},
		},
		{
			name: 'taskId',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'statusList',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'statusId',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
	];

	const components = {
		ExpandButton: function (props: any) {
			const subTaskList = taskList?.parentTaskList?.filter(
				(taskItem: any) =>
					taskItem?.parent_task_id === taskTableList[props.dataIndex]?.taskId
			);
			if (subTaskList && subTaskList?.length > 0) {
				return <ExpandButton {...props} />;
			} else {
				return <></>;
			}
		},
	};

	const changePage = (pageNumber: number) => {
		setPage(pageNumber);
	};

	const options = {
		filter: true,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		searchAlwaysOpen: true,
		rowsPerPage: rowsPerPage,
		serverSide: true,
		rowsPerPageOptions: [10, 20, 30],
		page: page,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsExpanded([]);
			setRowsPerPage(numberOfRows);
		},
		onDownload: (
			buildHead: (columns: any) => string,
			buildBody: (data: any) => string,
			columns: any,
			data: any
		) => {
			setisExportTriggered(true);
			setExportColumns(columns);
			return false;
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			setRowsExpanded([]);
			switch (changedColumn) {
				case 'taskName': {
					setSortFilter([{ title: direction }]);
					break;
				}
				case 'projectName': {
					setSortFilter([{ project: { name: direction } }]);
					break;
				}
				case 'mileStone': {
					setSortFilter([{ project_milestone: { name: direction } }]);
					break;
				}
				case 'statusName': {
					setSortFilter([
						{ project_kanban_card: { kanban_column: { name: direction } } },
					]);
					break;
				}
				case 'priority': {
					setSortFilter([{ priority: direction }]);
					break;
				}
				case 'assignedTo': {
					setSortFilter([{ assignedTo: { full_name: direction } }]);
					break;
				}
				case 'startDate': {
					setSortFilter([{ planned_start_date: direction }]);
					break;
				}
				case 'endDate': {
					setSortFilter([{ planned_end_date: direction }]);
					break;
				}
				case 'isBillable': {
					setSortFilter([{ is_billable: direction }]);
					break;
				}
				case 'duration': {
					setSortFilter([{ planned_hours: direction }]);
					break;
				}
				case 'completionPercentage': {
					setSortFilter([{ completion_percentage: direction }]);
					break;
				}
				default: {
					setSortFilter([]);
				}
			}
		},
		onSearchChange: (searchText: string | null) => {
			setRowsExpanded([]);
			if (!searchText) {
				setSearchedText('');
				return;
			}
			setSearchedText(searchText);
		},
		onFilterChange: (
			changedColumn: any,
			filterList: any,
			type: any,
			changedColumnIndex: any,
			displayData: any
		) => {
			// Index here is the index of the column in column configuration array
			// if the length of the array of filterlist with index as given is 0 means
			//  no optiion is selected thus clearing the filter
			setRowsExpanded([]);
			setPage(0);
			if (type === 'reset') {
				setProjectFilter(null);
				setSelectedProject(null);
				setMilestoneFilter(null);
				setSelectedMilestone(null);
				setStatusFilter(null);
				setSelectedStatus(null);
				setPriorityFilter(null);
				setSelectedPriority(null);
				setSelectedUserTeamId(null);
				setSelectedResourceIdList([]);
				setSelectedResourceOptionValues([]);
				const assigneArrayList = _.chain(latestFilterList?.project_list)
					?.map((project) => {
						const userList =
							project?.projects?.project_tasks?.map((user) => {
								return {
									id: user?.assignedTo?.id,
									name: user?.assignedTo?.full_name,
								};
							}) || [];
						return userList;
					})
					.filter((item) => item?.length > 0)
					.value()
					.flat();
				setAssigneeRecommendations(
					orderBy(
						uniqBy(assigneArrayList, function (e) {
							return e.id;
						}),
						[(user) => user?.name?.toUpperCase()],
						['asc']
					)
				);
				return;
			}
			if (filterList[1]?.length === 0) {
				setProjectFilter(null);
				setSelectedProject(null);
			}
			if (filterList[2]?.length === 0) {
				setMilestoneFilter(null);
				setSelectedMilestone(null);
			}
			if (filterList[3]?.length === 0) {
				setStatusFilter(null);
				setSelectedStatus(null);
			}
			if (filterList[4]?.length === 0) {
				setPriorityFilter(null);
				setSelectedPriority(null);
			}
			if (filterList[6]?.length === 0) {
				setSelectedResourceIdList([]);
				setSelectedResourceOptionValues([]);
				if (userTeamId) {
					const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
						(user: { user: { id: string; full_name: string } }) => {
							return {
								id: user?.user?.id,
								name: user?.user?.full_name,
							};
						}
					);
					const sortedAssigneeList = orderBy(
						useTeamAssignees,
						[(user) => user.name?.toUpperCase()],
						['asc']
					);
					setAssigneeRecommendations(sortedAssigneeList);
					return;
				}
				const assigneArrayList = _.chain(latestFilterList?.project_list)
					?.map((project) => {
						const userList =
							project?.projects?.project_tasks?.map((user) => {
								return {
									id: user?.assignedTo?.id,
									name: user?.assignedTo?.full_name,
								};
							}) || [];
						return userList;
					})
					.filter((item) => item?.length > 0)
					.value()
					.flat();
				setAssigneeRecommendations(
					orderBy(
						uniqBy(assigneArrayList, function (e) {
							return e.id;
						}),
						[(user) => user?.name?.toUpperCase()],
						['asc']
					)
				);
			}
		},
		count: taskList?.TotalTaskCount?.aggregate?.count || 0,
		onTableChange: (action: any, tableState: any) => {
			switch (action) {
				case 'rowExpansionChange':
					const rows = tableState.expandedRows.data.map(
						(item: any) => item.index
					);
					setRowsExpanded(rows.length > 1 ? rows.slice(-1) : rows);
					break;
				case 'changePage': {
					changePage(tableState.page);
					setRowsExpanded([]);
					break;
				}
				default:
					return;
			}
		},
		downloadOptions: {
			fileame: 'Project_Task',
			filterOptions: {
				useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true,
			},
		},

		print: false,
		textLabels: {
			body: {
				noMatch: 'No data found',
				pagination: {
					displayRows: 'on',
				},
			},
		},
		isRowExpandable: (dataIndex: any, expandedRows: any) => {
			const subTaskList = taskList?.parentTaskList?.filter(
				(taskItem: any) =>
					taskItem?.parent_task_id === taskTableList[dataIndex]?.taskId
			);
			if (subTaskList && subTaskList?.length > 0) {
				return true;
			} else {
				return false;
			}
		},
		rowsExpanded: rowsExpanded,
		customSearchRender: (
			searchText: string,
			handleSearch: any,
			hideSearch: any,
			options: any
		) => {
			return (
				<>
					<CustomSearchRender searchText={searchText} onSearch={handleSearch} />
				</>
			);
		},
		renderExpandableRow: (rowData: any, rowMeta: any) => {
			setParentTaskId(rowData[12]);
			return (
				<>
					<tr className={taskListStyles.tr}>
						<td colSpan={12}>
							<ThemeProvider theme={subTaskTableTheme}>
								<Box
									width='90rem'
									display='flex'
									alignItems='center'
									justifyContent='center'
									marginBottom='20px'
									marginTop='20px'
								>
									{isSubTaskListLodaing ? (
										'Loading...'
									) : (
										<TableContainer
											component={Paper}
											className={taskListStyles.tableContainer}
										>
											<Table className={taskListStyles.table}>
												<TableHead>
													<TableRow>
														<TableCell align='left'>Name</TableCell>
														<TableCell align='left'>Milestone</TableCell>
														<TableCell align='left'>Status</TableCell>
														<TableCell align='left'>Priority</TableCell>
														<TableCell align='left'>Assigned To</TableCell>
														<TableCell align='left'>Start Date</TableCell>
														<TableCell align='left'>End Date</TableCell>
														<TableCell align='left'>Billable</TableCell>
														<TableCell align='left'>Duration(HRS)</TableCell>
														<TableCell align='left'>Completion(%)</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{parentSubTaskList &&
														parentSubTaskList?.project_task &&
														parentSubTaskList?.project_task?.length > 0 &&
														parentSubTaskList?.project_task?.map(
															(subTask: any, index: number) => {
																const statusOptions =
																	subTask?.project?.kanban_board?.kanban_columns?.map(
																		(column: any) => {
																			return {
																				id: column?.id,
																				name: column?.name,
																			};
																		}
																	);
																return (
																	<TableRow key={index}>
																		<TableCell component='th' scope='row'>
																			{subTask?.title}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.project_milestone?.name || '--'}
																		</TableCell>
																		<TableCell align='right'>
																			<ThemeProvider
																				theme={statusSelectBoxTheme}
																			>
																				<Select
																					value={
																						subTask?.project_kanban_cards
																							? subTask?.project_kanban_cards[0]
																									?.kanban_column?.id
																							: ''
																					}
																					onChange={(event: any) =>
																						onSubTaskStatusChange(
																							event?.target?.value,
																							subTask?.project_kanban_cards[0]
																								?.id
																						)
																					}
																					disableUnderline={true}
																					variant='outlined'
																				>
																					{statusOptions &&
																						statusOptions?.map(
																							(option: any, index: number) => (
																								<MenuItem
																									value={option?.id}
																									key={index}
																								>
																									{option?.name}
																								</MenuItem>
																							)
																						)}
																				</Select>
																			</ThemeProvider>
																		</TableCell>
																		<TableCell align='right'>
																			<ThemeProvider
																				theme={prioritySelectBoxTheme}
																			>
																				<Select
																					style={{
																						background:
																							subTask?.priority === 'High'
																								? '#FFF3F2'
																								: subTask?.priority === 'Medium'
																								? '#EBF3FF'
																								: subTask?.priority === 'Low'
																								? '#E9F7EC'
																								: '',
																						color:
																							subTask?.priority === 'High'
																								? '#EA4335'
																								: subTask?.priority === 'Medium'
																								? '#4285F4'
																								: subTask?.priority === 'Low'
																								? '#34A853'
																								: '',
																					}}
																					value={subTask?.priority}
																					onChange={(event: any) =>
																						onSubTaskPriorityChange(
																							event?.target?.value,
																							subTask?.id
																						)
																					}
																					disableUnderline={true}
																					variant='outlined'
																				>
																					{taskPriorityOptions.map(
																						(option: {
																							id: string;
																							name: string;
																						}) => (
																							<MenuItem
																								value={option.id}
																								key={index}
																							>
																								{option.name}
																							</MenuItem>
																						)
																					)}
																				</Select>
																			</ThemeProvider>
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.assignedTo?.full_name || '--'}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.planned_start_date
																				? dayjs(
																						subTask?.planned_start_date
																				  ).format(dateFormat)
																				: '--'}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.planned_end_date
																				? dayjs(
																						subTask?.planned_end_date
																				  ).format(dateFormat)
																				: '--'}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.is_billable === true
																				? 'Yes'
																				: 'No'}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.planned_hours
																				? Number(
																						subTask?.planned_hours
																				  ).toFixed()
																				: '--'}
																		</TableCell>
																		<TableCell align='right'>
																			{subTask?.completion_percentage
																				? Number(
																						subTask?.completion_percentage
																				  ).toFixed()
																				: '--'}
																		</TableCell>
																	</TableRow>
																);
															}
														)}
												</TableBody>
											</Table>
										</TableContainer>
									)}
								</Box>
							</ThemeProvider>
						</td>
					</tr>
				</>
			);
		},
	};
	return (
		<Box marginLeft='10px'>
			<Box display='flex'>
				<Typography className={taskListStyles.heading}>Tasks</Typography>
				{isDataGridLoading && (
					<CircularProgress
						size={20}
						style={{ marginLeft: 15, position: 'relative', top: 19 }}
					/>
				)}
			</Box>

			<ThemeProvider theme={TaskTableTheme}>
				<>
					<MUIDataTable
						title={'Task List'}
						data={taskTableList}
						columns={columns}
						options={options}
						components={components}
					/>
				</>
			</ThemeProvider>
		</Box>
	);
};
