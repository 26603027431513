import gql from 'graphql-tag';
export const GET_USERS_LIST = gql`
	query MyQuery($userStatus: user_status) {
		user(where: { status: { _eq: $userStatus } }) {
			id
		}
	}
`;

export const GET_EMPLOYEE_SKILL_MAPPING = gql`
	query RetrieveEmployeeSkillMappings($isActive: Boolean, $firstName: String!) {
		employee_skill_mapping(
			where: {
				user: {
					is_active: { _eq: $isActive }
					full_name: { _ilike: $firstName }
				}
			}
		) {
			employee_id
			id
			is_primary
			skill_id
			skill_level_id
			skill_master {
				name
			}
			skill_level {
				level
			}
			user {
				id
				full_name
				first_name
				last_name
				email
				user_type
				employee {
					emp_id
					employee_prefix {
						id
						name
						value
					}
				}
				organization {
					employee_id_length
					enable_fixed_employee_id_length
				}
				contractors {
					contractor_id
					contractor_prefix {
						id
						name
						value
					}
				}
			}
			description
		}
	}
`;

export const GET_SKILLS = gql`
	query getSkills($limit: Int, $skill: String) {
		skills: skill_master(
			distinct_on: name
			limit: $limit
			where: { name: { _ilike: $skill } }
		) {
			id
			name
		}
		skill_master_aggregate(
			distinct_on: name
			where: { name: { _ilike: $skill } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_SKILL_LEVELS = gql`
	query getSkillLevels($limit: Int, $level: String) {
		skillLevels: skill_level(
			distinct_on: level
			limit: $limit
			where: { level: { _ilike: $level } }
		) {
			id
			level
		}
		skill_level_aggregate(
			distinct_on: level
			where: { level: { _ilike: $level } }
		) {
			aggregate {
				count
			}
		}
	}
`;
