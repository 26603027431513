import React from 'react'

import { useListContext } from 'react-admin'


import { Box, Typography, makeStyles, Button, Drawer } from "@material-ui/core";
import { Form } from 'react-final-form';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(theme => ({
	drawerContainer: {
		width: '328px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		padding: '50px'
	},
	drawerHeading: {
		fontSize: "14px",
		textAlign: 'left',
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		textTransform: 'uppercase',
	},
	HeaderSection: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	resetButton: {
		textDecoration: 'underline',
		font: 'normal normal bold 12px/23px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		opacity: 1,
		cursor: 'pointer',
	},
	applyFilterButton: {
		width: '120px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		fontSize: '12px',
		"&:hover": {
			color: '#FFFF',
			background: '#366CC6'
		}
	},
	cancelButton: {
		width: '120px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: ' #4285F4',
		opacity: 1,
		textTransform: 'none',
		fontSize: '12px',
		marginLeft: '10px'
	},
	buttonContainer: {
		width: '250px',
		display: 'flex',
		alignItems: 'center',
		marginTop: '50px',
		marginLeft: '10px'
	}
}))

export const DialogContent = withStyles(() => ({
	root: {
		maxHeight: '70vh',
		padding: '0px 10px 0px 0px',
		width:'270px'
	},
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		display : 'flex',
		justifyContent : 'center'
	},
}))(MuiDialogActions);

const DrawerFilterContainer = (props) => {
	const { open, close, children } = props
	const classes = useStyles();

	const {
		filterValues,
		setFilters,
		hideFilter,
	} = useListContext();

	const onSubmit = values => {
		if (Object.keys(values).length > 0) {
			setFilters(values);
			close()
		} else {
			hideFilter("main");
		}
	};

	const resetFilter = () => {
		setFilters({}, []);
		close()
	};

	return (
		<Drawer
			open={open}
			anchor="right"
			onClose={close}
		>
			<Box className={classes.drawerContainer}>
				<Box display="flex" flexDirection="column">
					<Box className={classes.HeaderSection}>
						<Typography className={classes.drawerHeading}>Filters</Typography>
						<Typography
							className={classes.resetButton}
							onClick={resetFilter}
						>
							Clear all
						</Typography>
					</Box>
				</Box>

				<Form onSubmit={onSubmit} initialValues={filterValues}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Box>
								<DialogContent>
									{children}
								</DialogContent>
							</Box>
							<DialogActions>
								<Box className={classes.buttonContainer}>
									<Box mb={'10px'}>
										<Button className={classes.applyFilterButton} variant="outlined" color="primary" type="submit">
											Apply Filters
										</Button>
									</Box>
									<Box mb={'10px'}>
										<Button className={classes.cancelButton} variant="outlined" onClick={resetFilter}>
											Cancel
										</Button>
									</Box>
								</Box>
							</DialogActions>
						</form>
					)}
				</Form>
			</Box>
		</Drawer>
	)
}

export default DrawerFilterContainer