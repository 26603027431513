import React, { useState } from 'react';
import { GET_EMPLOYEE_SKILL } from '../EmployeeSkillMapping.sevices.gql';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmployeeSkill from '../../../SharedComponents/EmployeeSkill.component';
import DeleteEmployeeSkill from './DeleteEmployeeSkill.component';
import { useQuery } from 'react-admin';

const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
		marginTop: '7px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
}));

interface Props {
	employeeId: string;
	setIsEmployeeSkillSubmitted: (data: boolean) => void;
}

export const EmployeeSkillEditPopOver = (props: Props) => {
	const { employeeId, setIsEmployeeSkillSubmitted } = props;
	const [employeeSkillModal, setEmployeeSkillModal] = useState(false);
	const [isSkillDeleteModalShown, setIsSkillDeleteModalShown] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const { data: employeeSkillData } = ApolloUseQuery(GET_EMPLOYEE_SKILL, {
		variables: {
			id: employeeId ? employeeId : null,
		},
	});

	const { data: skillLevelList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_level',
		payload: {
			sort: { field: 'level', order: 'ASC' },
		},
	});

	const { data: skillMasterList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_master',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});

	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem onClick={() => setEmployeeSkillModal(true)}>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
				<MenuItem onClick={() => setIsSkillDeleteModalShown(true)}>
					<DeleteIcon className={classes.deleteIcon} />
					<Box className={classes.editText}>Delete</Box>
				</MenuItem>
			</Menu>
			<EmployeeSkill
				open={employeeSkillModal}
				onClose={() => setEmployeeSkillModal(false)}
				record={employeeSkillData?.employee_skill_mapping_by_pk}
				handleClose={closePopOver}
				setIsEmployeeSkillSubmitted={(value) =>
					setIsEmployeeSkillSubmitted(value)
				}
				skillLevelList={skillLevelList}
				skillMasterList={skillMasterList}
			/>
			<DeleteEmployeeSkill
				open={isSkillDeleteModalShown}
				onClose={() => setIsSkillDeleteModalShown(false)}
				skillMappingId={employeeSkillData?.employee_skill_mapping_by_pk?.id}
				handleClose={closePopOver}
				setIsEmployeeSkillSubmitted={(value) =>
					setIsEmployeeSkillSubmitted(value)
				}
			/>
		</div>
	);
};
