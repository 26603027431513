import React, { useState, useEffect } from 'react';

import { useQuery as useApolloQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { GET_PROJECT_FILTER_OPTIONS, GET_SKILLS } from './gqlQueries';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';

const theme = createTheme({
	overrides: {
		MuiSvgIcon: {
			colorPrimary: {
				color: 'darkgray',
			},
		},
		MuiFilledInput: {
			root: {
				width: '224px',
				height: '36px',
				backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				opacity: 1,
			},
			input: {
				fontSize: '12px',
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#212121',
				opacity: 1,
			},
		},
		MuiInputLabel: {
			filled: {
				font: 'normal normal medium 12px/37px Manrope',
				letterSpacing: '0px',
				color: '#7C7C7C',
				opacity: 1,
				fontSize: '12px',
			},
		},
		MuiTypography: {
			body1: {
				fontSize: '12px',
				font: 'normal normal medium 12px/23px Manrope',
				letterSpacing: '0px',
				color: '#292929',
				opacity: 1,
			},
		},
	},
});

const ResourceUtilizationFilterFormBody = () => {
	const [skills, setSkills] = useState(null);
	const [searchedSkill, setSearchedSkill] = useState('');
	const [skillLimit, setSkillLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const [projects, setProjects] = useState(null);
	const [searchedProjects, setSearchedProjects] = useState('');
	const [projectsLimit, setProjectslLimit] = useState<number | null>(
		MAX_FILTER_OPTIONS_SHOWN
	);

	const { data: employeeSkills } = useApolloQuery(GET_SKILLS, {
		variables: {
			limit: skillLimit,
			skill: `%${searchedSkill || ''}%`,
		},
	});

	const { data: projectsOptions } = useApolloQuery(GET_PROJECT_FILTER_OPTIONS, {
		variables: {
			limit: projectsLimit,
			project: `%${searchedProjects || ''}%`,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (employeeSkills?.skills) {
			const skill = employeeSkills.skills.map(
				({ id, name }: { id: string; name: string }) => {
					return {
						id: id,
						name: name,
					};
				}
			);
			setSkills(skill);
		}
	}, [employeeSkills]);

	useEffect(() => {
		if (projectsOptions?.project) {
			const projectList = projectsOptions.project.map(
				({ id, name }: { id: string; name: string }) => {
					return {
						id: id,
						name: name,
					};
				}
			);
			setProjects(projectList);
		}
	}, [projectsOptions]);

	const onSkillExpansion = (isSeeMoreLabelShown: boolean) => {
		setSkillLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onSkillSearch = (name: string) => {
		setSearchedSkill(name);
	};

	const onProjectExpansion = (isSeeMoreLabelShown: boolean) => {
		setProjectslLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onProjectSearch = (name: string) => {
		setSearchedProjects(name);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box>
				<Box>
					<FilterInput
						label={'Skill'}
						sourceName='skill_id'
						onExpansionToggle={onSkillExpansion}
						choices={skills || []}
						onSearch={(name) => onSkillSearch(name)}
						total={employeeSkills?.skill_master_aggregate?.aggregate?.count}
					/>
				</Box>
				<Box>
					<FilterInput
						label={'Project'}
						sourceName='project_id'
						onExpansionToggle={onProjectExpansion}
						choices={projects || []}
						onSearch={(name) => onProjectSearch(name)}
						total={projectsOptions?.project_aggregate?.aggregate?.count}
					/>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default ResourceUtilizationFilterFormBody;
