import React from 'react';
import {
	useNotify,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { DELETE_PROJECT_SKILL } from '../gqlQueries';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment : {
		marginTop:"30px"
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
},
}));

interface Props {
	onClose: () => void;
	refetch: () => void;
	open: boolean;
	projectSkillId : string;
}


const ProjectSkillDelete = (props:Props) => {
  const { open, onClose,projectSkillId,refetch } = props;
  const notify = useNotify();
  const classes = modalFormStyle();
  const containerClass = useStyles();
  const [upsertProjectSkill] = useApolloMutation(DELETE_PROJECT_SKILL);
  
 
  const deleteProjectSkill = ()=>{
    upsertProjectSkill({
      variables : {
        projectSkillId : projectSkillId
      }
    }).then((data)=>{
        refetch();
				onClose();
				notify('Project Skill Deleted SuccessFully')
    })
  }
  
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
        <Typography className={containerClass.heading}>
						Do you really want to delete this record ? 
				</Typography>
				<Box className={containerClass.buttonAlignment}>
				<Box className={classes.buttonContainer}>
						<Button onClick={onClose} className={classes.cancelButton}>
							Cancel
						</Button>
						<Button
              className={classes.saveButton}
              onClick={deleteProjectSkill}
							type='submit'
						>
							Delete
						</Button>
					</Box>
				</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectSkillDelete;
