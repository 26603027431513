export const DELETE_WARNING_MESSAGE =
	'Are you sure you want to delete this user email group?. This action cannot be undone.';
export const UPDATE_MESSAGE = 'User email group updated successfully';
export const CREATE_MESSAGE = 'User email group created successfully';
export const UPDATE_FAILED_MESSAGE = 'User Group updation failed';
export const CREATE_FAILED_MESSAGE = 'User Group creation failed';
export const INVITE_USER_WARNING = 'This user is already present !';
export const INVITE_SUCCESS_MESSAGE = 'User added successfully';
export const INVITE_FAILED_MESSAGE = 'Adding user failed';
export const USER_DELETE_MESSAGE = 'User deleted Successfully';
export const DELETE_FAILED_MESSAGE = 'User delete failed';
export const DELETE_CONFIRMATION_MESSAGE =
	'Do you really want to delete this user ?';
export const DELETE_GROUP_FAILURE_MESSAGE = 'User email group removal failed';
export const DELETE_SUCCESS_MESSAGE = 'User Email Group has been removed';
export const UNIQUENESS_WARNING_MESSAGE = 'User email group already exists';
