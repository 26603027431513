import React, { useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserProfileContext } from '../../App';
const fileNames = [];
const OptionsMenu = ({
	docDetails,
	refetchDocs,
	setPopper,
	onDocDelete,
	onFileRename,
	onRenameError,
}) => {
	fileNames.push(docDetails.file_name);
	const { permissions } = useContext(UserProfileContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [renameFile, setRenameFile] = React.useState({
		fileId: '',
		newName: '',
		extension: '',
		renameError: '',
	});
	const { fileId, newName, extension, renameError } = renameFile;
	const open = Boolean(anchorEl);

	const UseStyle = makeStyles(() => ({
		menuIcon: {
			position: 'absolute',
			right: 0,
		},
		popupHeading: {
			color: '#4285F4',
			width: '90%',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			marginLeft: '10px',
			display: 'block',
		},
		saveButton: {
			width: '93px',
			height: '36px',
			background: '#4285F4 0% 0% no-repeat padding-box',
			boxShadow: '0px 4px 12px #4285F43B',
			borderRadius: '4px',
			opacity: 1,
			font: 'normal normal bold 12px/37px Manrope',
			letterSpacing: '0px',
			color: '#FFFFFF',
			textTransform: 'none',
			fontSize: '12px',
			'&:hover': {
				color: '#FFFF',
				background: '#366CC6',
			},
		},
		cancelButton: {
			width: '93px',
			height: '36px',
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			border: '1px solid #E0E0E0',
			borderRadius: '4px',
			font: 'normal normal bold 12px/37px Manrope',
			letterSpacing: '0px',
			color: ' #4285F4',
			opacity: 1,
			textTransform: 'none',
			fontSize: '12px',
		},
		editor: {
			background: '#FFFFFF 0% 0% no-repeat padding',
			border: '1px solid #E0E0E0',
			borderRadius: '4px',
			paddingLeft: '8px',
			cursor: 'text',
			height: '54px',
			margin: '8px',
			width: '100%',
			textAlign: 'left',
			overflowY: 'auto',
		},
		popperBox: {
			width: '500px',
		},
		actionContainer: {
			padding: '15px',
		},
		warningText: {
			color: 'red',
			marginLeft: '10px',
			fontSize: '14px',
		},
		align: {
			marginLeft: '10px',
		},
		deleteIcon: {
			color: 'rgb(220, 0, 78)',
			width: '10px',
			height: '10px',
		},
		downloadIcon: {
			color: '#4caf50',
			width: '10px',
			height: '10px',
			marginTop: '3px',
		},
		downloadBtn: {
			display: 'flex',
			marginTop: '3px',
		},
		editIcon: {
			width: '10px',
			height: '10px',
		},
		editText: {
			fontFamily: 'Manrope-SemiBold',
			opacity: 1,
			color: 'black',
			letterSpacing: '0px',
			fontSize: '10px',
			paddingLeft: '5px',
		},
	}));
	const classes = UseStyle();

	const docDeleteHandler = (id) => {
		onDocDelete(id);
	};

	const handleFilenameSave = (prevFilename) => {
		if (prevFilename === `${newName}.${extension}`) {
			return handleDialogClose();
		}
		if (newName?.length < 3) {
			return setRenameFile({
				...renameFile,
				renameError: 'The file name should contain at least 3 characters.',
			});
		}
		onFileRename(`${newName}.${extension}`, fileId);
	};

	const handleDialogOpen = (id, extension, fileName) => {
		setDialogOpen(true);
		setRenameFile({
			...renameFile,
			fileId: id,
			extension: extension,
			newName: fileName,
		});
	};

	const handleDialogClose = () => {
		setRenameFile({ renameFile, fileId: '', newName: '' });
		setDialogOpen(false);
	};

	return (
		<React.Fragment>
			<IconButton
				className={classes.menuIcon}
				onClick={(event) => setAnchorEl(event.currentTarget)}
			>
				<MoreVertIcon
					aria-label='more'
					aria-controls='long-menu'
					aria-haspopup='true'
				>
					<PriorityHighIcon />
				</MoreVertIcon>
			</IconButton>
			<Menu
				elevation={0}
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem
					onClick={() => {
						setAnchorEl(null);
					}}
				>
					<a href={docDetails.docUrl} download className={classes.downloadBtn}>
						<GetAppIcon className={classes.downloadIcon} />
						<Box className={classes.editText}>Download</Box>
					</a>
				</MenuItem>

				<MenuItem
					onClick={() => {
						setAnchorEl(null);
						handleDialogOpen(
							docDetails.id,
							docDetails.file_name.split('.')[1],
							docDetails.file_name.split('.')[0]
						);
					}}
				>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Rename</Box>
				</MenuItem>
				{permissions.opportunities?.update_permissions?.deleted_at && (
					<MenuItem
						onClick={() => {
							docDeleteHandler(docDetails.id);
							setAnchorEl(null);
						}}
					>
						<DeleteIcon className={classes.deleteIcon} />
						<Box className={classes.editText}>Delete</Box>
					</MenuItem>
				)}
			</Menu>
			<Dialog
				disableBackdropClick
				open={dialogOpen}
				onClose={handleDialogClose}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>
					<Tooltip title={docDetails?.file_name}>
						<span className={classes.popupHeading}>
							{docDetails?.file_name}
						</span>
					</Tooltip>
				</DialogTitle>
				<DialogContent className={classes.popperBox}>
					<DialogContentText className={classes.align}>
						Please enter the new file name
					</DialogContentText>
					<TextField
						className={classes.editor}
						id='name'
						type='text'
						fullWidth
						placeholder='File name'
						required={true}
						value={newName}
						InputProps={{ disableUnderline: true }}
						onChange={(e) => {
							if (
								e.target.value.length > 30 &&
								docDetails.file_name.length < 30
							) {
								return false;
							}
							setRenameFile({
								...renameFile,
								newName: e.target.value,
								renameError: '',
							});
						}}
					/>
					{renameError && (
						<Typography className={classes.warningText}>
							{renameError}
						</Typography>
					)}
				</DialogContent>
				<DialogActions className={classes.actionContainer}>
					<Button
						onClick={handleDialogClose}
						className={classes.cancelButton}
						color='primary'
					>
						Cancel
					</Button>
					<Button
						className={classes.saveButton}
						onClick={() => {
							handleFilenameSave(docDetails.file_name);
						}}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default OptionsMenu;
