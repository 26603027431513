import React, { useState, useEffect, useContext } from 'react';
import {
	TextInput,
	required,
	BooleanInput,
	useNotify,
	useRefresh,
} from 'react-admin';

import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';

import {
	Button,
	Box,
	Typography,
	ThemeProvider,
	makeStyles,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { LeaveGroupFormValues } from './LeaveManagement.model';
import {
	useUpdateLeavePolicyMutation,
	useUpdateLeavePolicyWithMarkDefaultMutation,
} from '../../../generated/graphql';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import dayjs from 'dayjs';
import {
	LEAVE_POLICY_APPLICABLE_FROM_VALIDATION_MESSAGE,
	LEAVE_POLICY_END_DATE_VALIDATION_MESSAGE,
	LEAVE_POLICY_START_DATE_VALIDATION_MESSAGE,
} from '../constant';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';

const toggleButtonsContainer = makeStyles({
	container: {
		display: 'flex',
	},
});

interface EditLeavePolicyProps {
	initialValues?: LeaveGroupFormValues;
	refetch: () => void;
	defaultLeavePolicyId: string;
	leavePolicyLabel: string[];
}

export const EditLeavePolicy = (props: EditLeavePolicyProps) => {
	const { initialValues, refetch, defaultLeavePolicyId, leavePolicyLabel } =
		props;
	const [leavePolicyName, setLeavePolicyName] = useState('');
	const [warningMsg, setWarningMsg] = useState('');
	const [leavePolicyError, setLeavePolicyError] = useState(false);
	const settingsStyles = settingsStyle();
	const formStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);
	const [applicableFromError, setApplicableFromError] = useState(false);
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [applicableFrom, setApplicableFrom] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [endDateFieldRequired, setEndDateFieldRequired] = useState(false);
	const [startDateFieldRequired, setStartDateFieldRequired] = useState(false);

	const toggleButtonContainerStyle = toggleButtonsContainer();

	const [updateLeavePolicy] = useUpdateLeavePolicyMutation();

	const [updateLeavePolicyWithMarkDefault] =
		useUpdateLeavePolicyWithMarkDefaultMutation();

	useEffect(() => {
		if (!initialValues?.label) {
			return;
		}
		setLeavePolicyName(initialValues.label);
		setApplicableFrom(initialValues.applicable_from);
		setStartDate(initialValues?.start_date);
		setEndDate(initialValues?.end_date);
	}, [initialValues]);

	const handleSubmit = async (formValues: LeaveGroupFormValues) => {
		if (!formValues) {
			return;
		}

		if (dayjs(formValues?.start_date).isAfter(dayjs(formValues?.end_date))) {
			setStartDateError(true);
			setApplicableFromError(false);
			setEndDateError(false);
			return;
		}

		if (
			dayjs(formValues?.applicable_from).isAfter(dayjs(formValues?.end_date)) ||
			dayjs(formValues?.applicable_from).isBefore(dayjs(formValues?.start_date))
		) {
			setApplicableFromError(true);
			setStartDateError(false);
			setStartDateError(false);
			return;
		}

		if (dayjs(formValues?.end_date).isBefore(dayjs(formValues?.start_date))) {
			setEndDateError(true);
			setStartDateError(false);
			setApplicableFromError(false);
			return;
		}
		if (initialValues?.label === formValues?.label.trim()) {
			setLeavePolicyError(false);
		} else if (
			leavePolicyLabel &&
			leavePolicyLabel?.length > 0 &&
			leavePolicyLabel?.includes(formValues?.label.trim())
		) {
			setLeavePolicyError(true);
			return;
		}

		if (!initialValues?.is_default && formValues?.is_default) {
			if (!defaultLeavePolicyId) {
				return;
			}
			updateLeavePolicyWithMarkDefault({
				variables: {
					leaveGroupId: formValues?.id,
					defaultLeaveGroupId: defaultLeavePolicyId,
					data: {
						label: formValues?.label.trim(),
						is_default: formValues?.is_default,
						start_date: formValues?.start_date,
						end_date: formValues?.end_date,
						applicable_from:
							formValues?.applicable_from === ''
								? null
								: formValues?.applicable_from,
					},
				},
			})
				.then((response) => {
					if (!response.errors) {
						notify('Leave policy details updated');
						refetch();
						refresh();
						setEndDateError(false);
						setStartDateError(false);
						setApplicableFromError(false);
						return;
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
			setEndDateError(false);
			setStartDateError(false);
			setApplicableFromError(false);
			return;
		}
		updateLeavePolicy({
			variables: {
				leaveGroupId: formValues?.id,
				data: {
					label: formValues?.label.trim(),
					is_default: formValues?.is_default,
					start_date: formValues?.start_date,
					end_date: formValues?.end_date,
					applicable_from: formValues?.applicable_from || null,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Leave policy details updated');
					refetch();
					refresh();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const validateleavePolicyFormValues = async () => {
		const leavePolicyFormErrors = {} as LeaveGroupFormValues;
		if (leavePolicyError) {
			leavePolicyFormErrors.label = 'Leave policy name already exist!';
		}

		return leavePolicyFormErrors;
	};

	return (
		<Box className={settingsStyles.contentCard}>
			<Box className={settingsStyles.headingContainer}>
				<Typography className={settingsStyles.heading}>
					Leave Policy Details
				</Typography>
			</Box>
			<ThemeProvider theme={modalFormTheme}>
				<Form
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validate={validateleavePolicyFormValues}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box paddingLeft={2} paddingTop={2}>
								<Box display='flex' alignItems='flex-end'>
									<Box mr={3} width='49%'>
										<Typography className={formStyles.label}>
											Leave Policy*
										</Typography>
										<TextInput
											source='label'
											label=''
											validate={required()}
											onChange={(e) => {
												setLeavePolicyError(false);
												setLeavePolicyName(e?.target?.value.trim());
												setDisableButton(false);
											}}
										/>
									</Box>
									<Box className={toggleButtonContainerStyle.container}>
										<BooleanInput
											label='Mark this leave policy as default'
											source='is_default'
											disabled={initialValues?.is_default}
											onChange={(event) => {
												if (!initialValues?.is_default && event) {
													setIsWarningModalShown(event);
													setWarningMsg(
														`This action will make "${leavePolicyName}" as default policy.`
													);
													return;
												}
												setIsWarningModalShown(false);
											}}
										/>
									</Box>
								</Box>
								<Box className={formStyles.multipleInputContainer}>
									<Box className={formStyles.multipleInput}>
										<Typography className={formStyles.label}>
											Start Date*
										</Typography>

										<Field
											name='start_date'
											validate={(value: any) => {
												if (!value) {
													return 'Required';
												}
												if (dayjs(value).isValid() === true || value === null) {
													return undefined;
												} else {
													return 'Invalid Date';
												}
											}}
										>
											{(props: any) => (
												<div>
													<CustomDateInput
														name={props.input.name}
														initialValue={props.input.value || null}
														key={startDate}
														onChange={(value) => {
															if (!value) {
																setStartDateFieldRequired(true);

																return;
															}
															setEndDateError(false);
															setDisableButton(false);
															setApplicableFromError(false);
															setStartDateError(false);
															setStartDateFieldRequired(false);

															props.input.onChange(value);
														}}
														dateFormat={dateFormat}
													/>
												</div>
											)}
										</Field>

										{startDateError && (
											<p className={formStyles.errorText}>
												{LEAVE_POLICY_START_DATE_VALIDATION_MESSAGE}
											</p>
										)}
										{startDateFieldRequired && (
											<p className={formStyles.errorText}>{'Required'}</p>
										)}
									</Box>
									<Box className={formStyles.multipleInput}>
										<Typography className={formStyles.label}>
											Policy Period*
										</Typography>

										<Field
											name='end_date'
											validate={(value: any) => {
												if (!value) {
													return 'Required';
												}
												if (dayjs(value).isValid() === true || value === null) {
													return undefined;
												} else {
													return 'Invalid Date';
												}
											}}
										>
											{(props: any) => (
												<div>
													<CustomDateInput
														name={props.input.name}
														initialValue={props.input.value || null}
														key={endDate}
														onChange={(value) => {
															if (!value) {
																setEndDateFieldRequired(true);
																return;
															}
															setEndDateError(false);
															setDisableButton(false);
															setApplicableFromError(false);
															setStartDateError(false);
															setEndDateFieldRequired(false);
															props.input.onChange(value);
														}}
														dateFormat={dateFormat}
													/>
												</div>
											)}
										</Field>

										{endDateError && (
											<p className={formStyles.errorText}>
												{LEAVE_POLICY_END_DATE_VALIDATION_MESSAGE}
											</p>
										)}
										{endDateFieldRequired && (
											<p className={formStyles.errorText}>{'Required'}</p>
										)}
									</Box>
								</Box>
								<Box className={formStyles.multipleInput}>
									<Typography className={formStyles.label}>
										Applicable From
									</Typography>
									<Field
										name='applicable_from'
										validate={(value: any) => {
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													key={applicableFrom}
													onChange={(value) => {
														setEndDateError(false);
														setDisableButton(false);
														setApplicableFromError(false);
														setStartDateError(false);
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>

									{applicableFromError && (
										<p className={formStyles.errorText}>
											{LEAVE_POLICY_APPLICABLE_FROM_VALIDATION_MESSAGE}
										</p>
									)}
								</Box>
								<Box className={`${formStyles.buttonContainer}`}>
									<Button
										className={
											invalid || pristine || disableButton
												? formStyles.disabledButton
												: formStyles.saveButton
										}
										disabled={invalid || pristine || disableButton}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Form>
			</ThemeProvider>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setWarningMsg('');
				}}
				warningMessage={warningMsg}
				isBackdropClickEnable={true}
			/>
		</Box>
	);
};

export default EditLeavePolicy;
