import { gql } from '@apollo/client';
export const GET_JOB_LEVELS = gql`
	query getJobLevels($limit: Int, $title: String) {
		job_level(
			distinct_on: level
			limit: $limit
			where: { level: { _iregex: $title } }
		) {
			id
			level
		}
		job_level_aggregate(
			distinct_on: level
			where: { level: { _ilike: $title } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_DEPARTMENTS = gql`
	query getDepartments($limit: Int, $department: String) {
		departments: department(
			distinct_on: name
			limit: $limit
			where: { name: { _ilike: $department } }
		) {
			id
			name
		}
		department_aggregate(
			distinct_on: name
			where: { name: { _ilike: $department } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_DESIGNATIONS = gql`
	query getDesignations($limit: Int, $title: String) {
		designation(
			distinct_on: title
			limit: $limit
			where: { title: { _ilike: $title } }
		) {
			id
			title
		}
		designation_aggregate(
			distinct_on: title
			where: { title: { _ilike: $title } }
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_CONSULTANT_STATUS = gql`
	query getConsultantStatus($limit: Int, $status: String) {
		contractor_status(limit: $limit, where: { label: { _ilike: $status } }) {
			id
			label
		}
		contractor_status_aggregate(where: { label: { _ilike: $status } }) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_CONSULTANT_BY_ID = gql`
	query getConsultantById($consultantId: uuid!) {
		contractor_by_pk(id: $consultantId) {
			user {
				id
				first_name
				middle_name
				last_name
				full_name
				profile_pic
				email
				is_active
				phone_number
				organization {
					contractor_id_prefix
				}
			}
			contractorStatus {
				id
				label
			}
			contractor_prefix {
				id
				name
				value
			}
			contractor_joining_source {
				id
				label
			}
			currency {
				id
				currency_type
				symbol
			}
			custom_field_1
			custom_field_2
			custom_field_3
			custom_field_4
			custom_field_5
			custom_field_6
			custom_field_7
			custom_field_8
			custom_field_9

			is_billable
			vendor_type
			contractor_id
			working_mode
			job_level_id
			department_id
			contractor_status_id
			contractor_prefix_id
			reportee_id
			total_experience
			id
			vendor_type
			contractor_id
			join_date
			personal_email
			contractor_cost
			billing_cost
			extension_status
			is_recontract_eligible
			address
			bank_name
			ifsc_code
			account_number
			branch_name
			end_date
			org_shift_id
			gender
			location_id
			dob
			created_at
			marital_status
			wedding_date
			emergency_contact_number
			emergency_contact_name
			org_location {
				id
				name
			}
			department {
				name
			}
			designation {
				id
				title
			}
			reportee {
				is_active
			}
			createdBy {
				id
				full_name
			}
		}
	}
`;

export const GET_CONSULTANT_CUSTOM_FIELDS = gql`
	query getConsultantCustomFields {
		custom_field(
			where: { master_sub_module: { value: { _eq: "contractor" } } }
			order_by: { index: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
		}
		lastIndexCustomField: custom_field_aggregate {
			aggregate {
				max {
					index
				}
			}
		}
	}
`;
export const UPDATE_CONSULTANT = gql`
	mutation updateConsultant(
		$id: uuid!
		$user_Id: uuid!
		$data: contractor_set_input!
		$userData: user_set_input
	) {
		update_contractor_by_pk(pk_columns: { id: $id }, _set: $data) {
			contractor_id
		}
		update_user_by_pk(pk_columns: { id: $user_Id }, _set: $userData) {
			id
		}
	}
`;
export const GET_CONSULTANT_SKILL_LIST = gql`
	query getConsultantSkillSearch(
		$filter: employee_skill_mapping_bool_exp = {}
	) {
		employee_skill_mapping(where: $filter) {
			id
			employee_id
			skill_master {
				id
				name
			}
		}
	}
`;
export const GET_SKILL_BY_ID = gql`
	query getSkillById($id: uuid!) {
		employee_skill_mapping_by_pk(id: $id) {
			id
			skill_level_id
			skill_id
			employee_id
			user {
				first_name
				last_name
				full_name
			}
		}
	}
`;
export const UPDATE_CONTRACTOR_BY_ID = gql`
	mutation updateConsultantBasicDetails(
		$contractorId: uuid!
		$data: contractor_set_input!
	) {
		update_contractor_by_pk(pk_columns: { id: $contractorId }, _set: $data) {
			contractor_id
		}
	}
`;

export const GET_CONSULTANT_STATUS_OPTIONS = gql`
	query getConsultantStatusOptions {
		contractor_status {
			id
			label
			value
		}
	}
`;

export const GET_ORGANISATION_BASE_CURRENCY = gql`
	query getOrganisationBaseCurrency($organizationId: uuid!) {
		organization(where: { id: { _eq: $organizationId } }) {
			id
			name
			currency_id
		}
	}
`;

export const GET_CONSULTANT_NOTE = gql`
	query getConsultantNotes($consultant_id: uuid!, $SearchText: String!) {
		consultant_note(
			where: {
				consultant_id: { _eq: $consultant_id }
				_or: [
					{ title: { _ilike: $SearchText } }
					{ note: { _ilike: $SearchText } }
				]
			}
		) {
			user {
				first_name
				last_name
				full_name
				profile_pic
			}
			created_by
			note
			consultant_id
			title
			id
		}
	}
`;

export const UPSERT_CONSULTANT_NOTE = gql`
	mutation upsertConsultantNote(
		$consultantNote: consultant_note_insert_input!
	) {
		insert_consultant_note_one(
			object: $consultantNote
			on_conflict: {
				constraint: consyltant_note_pkey
				update_columns: [note, title]
			}
		) {
			id
			title
			note
		}
	}
`;

export const DELETE_CONSULTANT_NOTE = gql`
	mutation deleteConsultantNote($noteId: uuid!) {
		update_consultant_note(
			where: { id: { _eq: $noteId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_CONSULTANT_DOC = gql`
	query getConsultantDoc($consultant_id: uuid!) {
		consultant_doc(
			order_by: { file_name: asc }
			where: { consultant_id: { _eq: $consultant_id } }
		) {
			id
			url
			file_name
		}
	}
`;

export const ADD_CONSULTANT_DOC = gql`
	mutation addConsultantDoc($data: consultant_doc_insert_input!) {
		insert_consultant_doc_one(object: $data) {
			file_name
		}
	}
`;

export const DELETE_CONSULTANT_DOC = gql`
	mutation deleteConsultantDoc($id: uuid!) {
		delete_consultant_doc(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_FILE_NAME = gql`
	mutation updateFileName($file_name: String!, $id: uuid!) {
		update_consultant_doc(
			_set: { file_name: $file_name }
			where: { id: { _eq: $id } }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_CONSULTANT_SEARCH = gql`
	query getConsultantSearch(
		$name: String!
		$contractors: contractor_bool_exp = {}
	) {
		user(
			where: {
				user_type: { _eq: "contract_employee" }
				full_name: { _ilike: $name }
				contractors: $contractors
			}
		) {
			first_name
			last_name
			id
			full_name
			phone_number
			user_type
			email
			organization {
				contractor_id_prefix
			}
			contractors {
				join_date
				personal_email
				emergency_contact_number
				emergency_contact_name
				contractor_cost
				billing_cost
				is_billable
				vendor_type
				working_mode
				is_recontract_eligible
				employment_status
				eligibility_remark
				extension_status
				payment_remark
				credit_term
				advance_percentage
				bank_name
				branch_name
				ifsc_code
				account_number
				gender
				total_experience
				dob
				address
				end_date
				marital_status
				wedding_date
				emergency_contact_name
				emergency_contact_number
				contractor_id
				location_id
				custom_field_1
				custom_field_2
				custom_field_3
				custom_field_4
				custom_field_5
				custom_field_6
				custom_field_7
				custom_field_8
				custom_field_9
				org_location {
					id
					name
				}
				department {
					name
				}
				designation {
					id
					title
				}
				job_level {
					level
				}
				reportee {
					full_name
					email
				}
				contractorStatus {
					id
					label
				}
				contractor_prefix {
					id
					name
					value
				}
				organization {
					currency {
						symbol
					}
				}
			}
		}
	}
`;
export const GET_CONSULTANT_STATUS_AUDIT = gql`
	query getConsultantAuditHistory($userId: uuid!) {
		audit_audit_contractor(
			order_by: { action_tstamp: asc }
			where: { action_on: { _eq: $userId } }
		) {
			user {
				id
				full_name
			}
			userByActionOn {
				id
				full_name
			}
			action_tstamp
			action
			action_type
			action_data
		}
	}
`;
