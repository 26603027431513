import React, { useEffect, useState } from 'react';

import { useQuery as ApolloUseQuery } from '@apollo/client';

import { Box } from '@material-ui/core';

import { GET_OWNERS } from './CompanyServices.gql';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import { useQuery } from 'react-admin';

export const CompanyFilterFormBody = () => {
	const [accountOwners, setAccountOwners] = useState(null);
	const [companyCategories, setCompanyCategories] = useState([]);
	const [companySizes, setCompanySizes] = useState([]);
	const [companySector, setCompanySector] = useState([]);
	const [companyContactType, setCompanyContactType] = useState([]);
	const [searchedOwnerName, setSearchedOwnerName] = useState(null);
	const [ownerLimit, setOwnerLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

	const { data: owners, refetch: refetchOwners } = ApolloUseQuery(GET_OWNERS, {
		variables: {
			limit: ownerLimit,
			name: `%${searchedOwnerName || ''}%`,
		},
	});
	const { data: categories } = useQuery({
		type: 'GET_LIST',
		resource: 'company_categories',
		payload: {},
	});
	const { data: sizes } = useQuery({
		type: 'GET_LIST',
		resource: 'company_size',
		payload: {},
	});
	const { data: sectors } = useQuery({
		type: 'GET_LIST',
		resource: 'company_sector',
		payload: { sort: { field: 'label', order: 'ASC' } },
	});
	const { data: contactTypes } = useQuery({
		type: 'GET_LIST',
		resource: 'company_contact_type',
		payload: { sort: { field: 'label', order: 'ASC' } },
	});
	useEffect(() => {
		if (owners?.user) {
			const accountOwners = owners.user.map((user) => {
				return {
					id: user.id,
					name: `${user.full_name} `,
				};
			});
			setAccountOwners(accountOwners);
		}
	}, [owners]);
	useEffect(() => {
		if (categories) {
			const categoryList = categories?.map((category) => {
				return {
					id: category?.id,
					name: `${category?.label}`,
				};
			});
			setCompanyCategories(categoryList);
		}
	}, [categories]);

	useEffect(() => {
		if (sizes) {
			const sizeList = sizes?.map((size) => {
				return {
					id: size?.id,
					name: `${size?.label}`,
				};
			});
			setCompanySizes(sizeList);
		}
	}, [sizes]);
	useEffect(() => {
		if (sectors) {
			const sectorList = sectors?.map((sector) => {
				return {
					id: sector?.id,
					name: `${sector?.label}`,
				};
			});

			setCompanySector(sectorList);
		}
	}, [sectors]);

	useEffect(() => {
		if (contactTypes) {
			const typeList = contactTypes?.map((type) => {
				return {
					id: type?.id,
					name: `${type?.label}`,
				};
			});
			setCompanyContactType(typeList);
		}
	}, [contactTypes]);

	useEffect(() => {
		refetchOwners();
	}, [refetchOwners]);

	const isChoiceSearchable = (choice) => {
		const length = choice && choice.length > 0 && Object.keys(choice).length;
		const isSearchable = length > MAX_FILTER_OPTIONS_SHOWN ? true : false;
		return isSearchable;
	};

	const onExpansionToggle = (isSeeMoreLabelShown) => {
		setOwnerLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};
	const onSearch = (name) => {
		setSearchedOwnerName(name);
	};

	return (
		<Box>
			<Box>
				<FilterInput
					label={'Account Owner'}
					sourceName='owner_id'
					onExpansionToggle={onExpansionToggle}
					choices={accountOwners || []}
					onSearch={(name) => onSearch(name)}
					total={owners?.user_aggregate?.aggregate?.count}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Sector'}
					sourceName='sector_id'
					choices={companySector}
					isSearchShown={isChoiceSearchable(companySector)}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Category'}
					sourceName='category_id'
					choices={companyCategories}
					isSearchShown={isChoiceSearchable(companyCategories)}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Size'}
					sourceName='size_id'
					choices={companySizes}
					isSearchShown={isChoiceSearchable(companySizes)}
				/>
			</Box>
			<Box>
				<FilterInput
					label={'Contact type'}
					sourceName='contact_type_id'
					choices={companyContactType}
					isSearchShown={isChoiceSearchable(companyContactType)}
				/>
			</Box>
		</Box>
	);
};

export default CompanyFilterFormBody;
