interface Timesheet {
	totalHours: number;
	billableWorkingHours: number;
	nonBillableWorkingHours: number;
}
export const calculateTotalHoursFromTimesheet = (
	timesheetList: Timesheet[]
) => {
	const totalHrs = timesheetList?.reduce(function (
		acc: number,
		obj: { totalHours: number }
	) {
		return acc + parseFloat(Number(obj.totalHours).toFixed(1));
	},
	0);
	const billableHrs = timesheetList?.reduce(function (
		acc: number,
		obj: { billableWorkingHours: number }
	) {
		return acc + parseFloat(Number(obj.billableWorkingHours).toFixed(1));
	},
	0);
	const nonBillableHrs = timesheetList?.reduce(function (
		acc: number,
		obj: { nonBillableWorkingHours: number }
	) {
		return acc + parseFloat(Number(obj.nonBillableWorkingHours).toFixed(1));
	},
	0);
	return {
		totalHours: totalHrs,
		billableHours: billableHrs,
		nonBillableHours: nonBillableHrs,
	};
};

export const getCustomFieldFilter = (index: number, searchData: string) => {
	if (index >= 1 && index <= 9 && searchData) {
		const fieldName = `custom_field_${index}`;
		return {
			[fieldName]: searchData
				? { _ilike: `%${searchData}%` }
				: { _is_null: true },
		};
	}

	return {};
};
