import React, { useState } from 'react';
import {
	Box,
	Typography,
	Drawer,
	IconButton,
	makeStyles,
	Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	ATTENDANCE_EXPORT_DROPDOWN_OPTIONS,
	BASIC_LEAVE_BALANCE_REPORT_INFO,
	DETAILED_LEAVE_BALANCE_REPORT_INFO,
} from './Constant';
import ExportButtonWithDropDown from '../../SharedComponents/ExportButton/ExportButtonWithDropDown';
import { Divider } from 'antd';
import InfoIcon from '@material-ui/icons/Info';
import {
	exportBasicLeavePolicyBalance,
	exportDetailedLeavePolicyBalance,
} from '../../Api/restApi.config';
import { excelDownload } from '../../Utils/string.util';
import { useNotify } from 'react-admin';

interface GoalCommentProps {
	open: boolean;
	onClose: () => void;
}
const useStyles = makeStyles((theme) => ({
	drawerContainer: {
		width: '400px',
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	heading: {
		fontWeight: 'bold',
		fontFamily: 'Manrope-bold',
		fontSize: '17px',
	},
	section: {
		marginBottom: '25px',
	},
	divider: {
		margin: `${theme.spacing(2)}px 0`,
	},
	label: {
		font: 'normal normal bold 12px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '13px !important',
		marginTop: '8px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '5px',
	},

	editIcon: {
		width: '12px',
		height: '12px',
	},
}));
const LeaveBalanceExportDrawer = (props: GoalCommentProps) => {
	const { open, onClose } = props;
	const [basicExportLoading, setBasicExportLoading] = useState(false);
	const [detailedExportLoading, setDetailedExportLoading] = useState(false);
	const classes = useStyles();
	const notify = useNotify();

	const handleDetailedLeaveExport = async (index: number) => {
		setDetailedExportLoading(true);
		const data = new FormData();
		const selectedUsers = index === 0 ? 'All' : index === 1 ? 'true' : 'false';
		data.append('is_active', selectedUsers);

		exportDetailedLeavePolicyBalance(data)
			.then((res) => {
				if (res?.data?.error) {
					notify('Export failed', 'warning');
					setDetailedExportLoading(false);
					return;
				}
				excelDownload(res.data, 'Employee Detailed Leave Balance');
				setDetailedExportLoading(false);
				return res.data;
			})
			.catch((err) => {
				notify('Export failed', 'warning');
				setDetailedExportLoading(false);
			});
		return;
	};
	const handleBasicLeaveExport = async (index: number) => {
		setBasicExportLoading(true);
		const data = new FormData();
		const selectedUsers = index === 0 ? 'All' : index === 1 ? 'true' : 'false';
		data.append('is_active', selectedUsers);

		exportBasicLeavePolicyBalance(data)
			.then((res) => {
				if (res?.data?.error) {
					notify('Export failed', 'warning');
					setBasicExportLoading(false);
					return;
				}
				excelDownload(res.data, 'Employee Basic Leave Balance');
				setBasicExportLoading(false);
				return res.data;
			})
			.catch((err) => {
				notify('Export failed', 'warning');
				setBasicExportLoading(false);
			});
	};
	return (
		<>
			<Drawer open={open} anchor='right' onClose={onClose} disableBackdropClick>
				<Box className={classes.drawerContainer}>
					{/* Header Section */}
					<Box className={classes.header}>
						<Typography variant='h6' className={classes.heading}>
							Leave Balance Export
						</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Box>
						<Box display='flex' className={classes.section}>
							<Box display='flex'>
								<Box display='flex'>
									<Typography className={classes.label}>
										Basic Report
									</Typography>
									<Tooltip
										title={BASIC_LEAVE_BALANCE_REPORT_INFO}
										placement='right'
									>
										<IconButton className={classes.iconContainer}>
											<InfoIcon className={classes.editIcon} />
										</IconButton>
									</Tooltip>
								</Box>

								<Box marginLeft='19px'>
									<ExportButtonWithDropDown
										downloadInProgress={basicExportLoading}
										onChange={handleBasicLeaveExport}
										options={ATTENDANCE_EXPORT_DROPDOWN_OPTIONS}
									/>
								</Box>
							</Box>
						</Box>
						<Box display='flex' className={classes.section}>
							<Box display='flex'>
								<Typography className={classes.label}>
									Detailed Report
								</Typography>
								<Tooltip
									title={DETAILED_LEAVE_BALANCE_REPORT_INFO}
									placement='right'
								>
									<IconButton className={classes.iconContainer}>
										<InfoIcon className={classes.editIcon} />
									</IconButton>
								</Tooltip>
							</Box>
							<ExportButtonWithDropDown
								downloadInProgress={detailedExportLoading}
								onChange={handleDetailedLeaveExport}
								options={ATTENDANCE_EXPORT_DROPDOWN_OPTIONS}
							/>
						</Box>
					</Box>
					<Divider />
				</Box>
			</Drawer>
		</>
	);
};

export default LeaveBalanceExportDrawer;
