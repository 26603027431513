import * as React from "react";
import { 
  Edit, 
  TextInput,
  SimpleForm, 
  required, 
  ReferenceInput, 
  SelectInput, 
  useGetList,
  TopToolbar,
  ListButton,
  SaveButton,
  Toolbar 
} from "react-admin";
import { Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const DesignationTitle = ({ record }) => {
  return <span>Edit Designation: {record ? `"${record.title}"` : ""}</span>;
};

export const DesignationCreateActions = ({ basePath }) => {
  return (
    <TopToolbar>
      <ListButton
        basePath={basePath}
        label="Designation List"
        icon={<ChevronLeft />}
      />
    </TopToolbar>
  );
};

const CustomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const EditDesignation = (props) => {
  const { data: organizations } = useGetList("organization");
    const orgIds = Object.keys(organizations);
    const selectedOrgId = orgIds.length === 0 ? "" : orgIds[0];
  return(
  <Edit {...props} title={<DesignationTitle />} actions={<DesignationCreateActions />} >
    <SimpleForm toolbar={<CustomToolbar />}>  
      <TextInput source="title" validate={required()} />
      <TextInput source="description" validate={required()} />
      <Box display="none">
      <ReferenceInput
        source="org_id"
        reference="organization"
        label="Organization"
      >
      <SelectInput resettable={true} initialValue={selectedOrgId.length ? selectedOrgId : ""} />
      </ReferenceInput>
      </Box>
    </SimpleForm>
  </Edit>
)};
