import React, { useState } from 'react';
import { GET_SOFTWARE_BY_ID } from '../gqlQueries';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssetSoftwareForm from './AssetSoftwareForm.component';
import DeleteSoftware from './DeleteSoftware.component';
const useStyles = makeStyles((theme) => ({
	editIcon: {
		width: '10px',
		height: '10px',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
		marginTop: '7px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
}));

interface Props {
	softwareId: string;
}

export const AssetEditPopOver = (props: Props) => {
	const { softwareId } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [showEditForm, setShowEditForm] = useState(false);
	const [showDeleteForm, setShowDeleteForm] = useState(false);
	const { data: softwareData } = ApolloUseQuery(GET_SOFTWARE_BY_ID, {
		variables: {
			id: softwareId,
		},
	});
	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
	};

	const classes = useStyles();

	return (
		<div>
			<MoreVertIcon onClick={showPopOver} className={classes.verticalIcon} />
			<Menu
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
			>
				<MenuItem onClick={() => setShowEditForm(true)}>
					<EditIcon className={classes.editIcon} />
					<Box className={classes.editText}>Edit</Box>
				</MenuItem>
				<MenuItem onClick={() => setShowDeleteForm(true)}>
					<DeleteIcon className={classes.deleteIcon} />
					<Box className={classes.editText}>Delete</Box>
				</MenuItem>
			</Menu>
			<AssetSoftwareForm
				open={showEditForm}
				onClose={() => setShowEditForm(false)}
				software={softwareData?.asset_software_by_pk}
			/>
			<DeleteSoftware
				open={showDeleteForm}
				onClose={() => setShowDeleteForm(false)}
				softwareId={softwareData?.asset_software_by_pk?.id}
			/>
		</div>
	);
};
