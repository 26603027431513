import React, { useState, useEffect, useContext } from 'react';
import { required, SelectInput, NumberInput, useNotify } from 'react-admin';
import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';
import { useUpdateLeaveBalancesMutation } from '../../generated/graphql';
import { LeaveBalanceProps } from './Attendance.model';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../App';

const leaveFormStyle = makeStyles({
	container: {
		width: '600px',
		padding: '20px',
		minHeight: '300px',
		borderRadius: '4px',
	},
});
interface LeaveProps {
	open: boolean;
	onClose: () => void;
	leaveTypeList: any;
	leaveTypeId?: string | null;
	organizationLeaves: LeaveBalanceProps[];
}
interface FormValues {
	accruedLeaveCount: number;
	defaultAmount: number;
	renewalDate: string;
	leaveTypeId: string;
}
const LeaveBalanceForm = (props: LeaveProps) => {
	const { open, onClose, leaveTypeList, leaveTypeId, organizationLeaves } =
		props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [initialFormValues, SetInitialFormValues] = useState<any>({});
	const [selectedLeaveTypeDetails, SetSelectedLeaveTypeDetails] = useState<any>(
		{}
	);
	const classes = modalFormStyle();
	const formStyles = leaveFormStyle();
	const notify = useNotify();
	const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
	const handleSave = (formValues: FormValues) => {
		if (selectedLeaveTypeDetails?.id) {
			updateLeave({
				variables: {
					//will enable this when accrued is confirmed
					// accruedId: selectedLeaveTypeDetails?.accruedId,
					// accruedData: {
					// 	count: formValues?.accruedLeaveCount,
					// },
					leaveTypeId: selectedLeaveTypeDetails?.leaveTypeId,
					leaveTypeData: {
						renewal_date: formValues?.renewalDate || null,
						opening_balance: formValues?.defaultAmount,
					},
				},
			})
				.then((response: any) => {
					if (!response?.error) {
						notify('Leave balance updated successfully');
						handleClose();
					}
				})
				.catch((error: any) => {
					if (error) {
						notify('Leave balance updation failed', 'warning');
						return;
					}
				});
		}
	};
	const handleClose = () => {
		onClose();
	};
	const [updateLeave] = useUpdateLeaveBalancesMutation();
	useEffect(() => {
		if (!organizationLeaves || !leaveTypeId) {
			return;
		}
		const selectedLeaveType = organizationLeaves.find(
			(type: any) => type.id === leaveTypeId
		);
		SetSelectedLeaveTypeDetails(selectedLeaveType);
		SetInitialFormValues({
			leaveTypeId: leaveTypeId,
			accruedLeaveCount: selectedLeaveType?.accruedLeaveCount,
			defaultAmount: selectedLeaveType?.defaultAmount,
			renewalDate: selectedLeaveType?.renewalDate,
		});
	}, [leaveTypeId, organizationLeaves]);
	return (
		<Box>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={formStyles.container}>
						<Box className={classes.headerContainer}>
							<Typography className={classes.heading}>
								EDIT LEAVE BALANCE
							</Typography>
							<CloseIcon className={classes.closeIcon} onClick={handleClose} />
						</Box>
						<Form onSubmit={handleSave} initialValues={initialFormValues}>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Box className={classes.multipleInputContainer}>
											<Box width='45%'>
												<Typography className={classes.label}>
													Leave Type *
												</Typography>
												<SelectInput
													source='leaveTypeId'
													label={false}
													fullWidth
													choices={leaveTypeList || []}
													validate={required()}
													disabled={leaveTypeList && leaveTypeId ? true : false}
												/>
											</Box>
											<Box width='45%'>
												<Typography className={classes.label}>
													Renewal Date
												</Typography>
												<Field
													name='renewalDate'
													validate={(value) => {
														if (dayjs(value).isValid() === true) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props) => (
														<Box>
															<CustomDateInput
																minDate={tomorrow}
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value) => {
																	props.input.onChange(value);
																}}
																dateFormat={dateFormat}
															/>
														</Box>
													)}
												</Field>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box width='45%'>
												<Typography className={classes.label}>
													Opening Balance *
												</Typography>
												<NumberInput
													source='defaultAmount'
													fullWidth={true}
													label={false}
													validate={required()}
												/>
											</Box>
											<Box width='45%'>
												<Typography className={classes.label}>
													Accrued Leave *
												</Typography>
												<NumberInput
													source='accruedLeaveCount'
													fullWidth={true}
													label={false}
													validate={required()}
													disabled={true}
												/>
											</Box>
										</Box>
										<Box
											marginTop='20px'
											display='flex'
											justifyContent='flex-end'
											alignItems='center'
										>
											<Button
												onClick={handleClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine
														? classes.disabledButton
														: classes.saveButton
												}
												disabled={invalid}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
		</Box>
	);
};

export default LeaveBalanceForm;
