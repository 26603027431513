export const CONTACT_EXPORT_COLUMN = [
	'NAME',
	'TITLE',
	'COMPANY',
	'EMAIL',
	'PHONE',
	'TYPE',
	'BD OWNER',
	'LINKEDIN',
];
