import polyglotI18nProvider from 'ra-i18n-polyglot';
import { customEnglishMessages } from './english/en'

const messages = {
    en: customEnglishMessages
}

// If we need localization in future we can add using this i18nProvider
export const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    'en',
    { allowMissing: true, onMissingKey: (key, _, __) => key } // To avoid the "translation key is missing" warning from the browser console 
);