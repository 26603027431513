import React, { useContext, useEffect, useState } from 'react';
import { FunctionField, ReferenceField, TextField } from 'react-admin';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { Typography, Box, makeStyles, Tooltip } from '@material-ui/core';
import editicon from '../../../assets/icons/edit-icon.svg';
import EditConsultantBasicDetails from './EditConsultantBasicDetails.component';
import { detailsPageTabDetailsStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_ORGANISATION_BASE_CURRENCY } from '../gqlQueries';
import head from 'lodash/head';
import { OrganisationBaseCurrency } from '../Consultant.model';
import dayjs from 'dayjs';
import FieldRenderer from '../../../SharedComponents/FieldRenderer.component';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '424px',
		width: '80%',
		flexWrap: 'wrap',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
	margin: {
		marginRight: '10px',
	},
}));
interface Source {
	id: string;
}
interface Address {
	houseName: string;
	streetName: string;
	city: string;
	postalCode: string;
	state: string;
	country: string;
}
interface Currency {
	id: string;
}
export interface CustomField {
	id: string;
	label: string;
	index: number;
	validation: any;
	options: { id: string; name: string }[];
	type: string;
	is_active: boolean;
}
export interface customFieldData {
	custom_field: CustomField[];
}

interface Record {
	id: string;
	personal_email: string;
	join_date: string;
	end_date: string;
	billing_cost: string;
	contractor_cost: string;
	contractor_joining_source_id: string;
	address: Address;
	contractor_joining_source: Source;
	currency: Currency;
	org_shift_id?: string;
	gender?: string;
	dob?: string;
	consultantCustomFields: customFieldData;
	[key: string]: any;
	custom_field_1: string;
	custom_field_2: string;
	custom_field_3: string;
	custom_field_4: string;
	custom_field_5: string;
	custom_field_6: string;
	custom_field_7: string;
	custom_field_8: string;
	custom_field_9: string;
	location_id?: string;
	marital_status: boolean;
	wedding_date: string;
	emergency_contact_number: string;
	emergency_contact_name: string;
}
interface Props {
	record: Record;
	refetch: () => void;
}

const ConsultantBasicDetails = (props: Props) => {
	const {
		orgId: organizationId,
		permissions: permissionList,
		dateFormat,
	} = useContext<any>(UserProfileContext);
	const { record: consultant, refetch } = props;
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [consultantDetails, setConsultantDetails] = useState({});
	const classes = detailsPageTabDetailsStyle();
	const styles = useStyles();
	const { data: organisation } = useApolloQuery<OrganisationBaseCurrency>(
		GET_ORGANISATION_BASE_CURRENCY,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);
	useEffect(() => {
		if (!consultant) {
			return;
		}
		setConsultantDetails({
			id: consultant?.id,
			personal_email: consultant?.personal_email,
			join_date: consultant?.join_date,
			end_date: consultant?.end_date,
			billing_cost: consultant?.billing_cost,
			contractor_cost: consultant?.contractor_cost,
			contractor_joining_source_id: consultant?.contractor_joining_source?.id,
			address: consultant?.address,
			currency_id: head(organisation?.organization)?.currency_id,
			org_shift_id: consultant?.org_shift_id,
			gender: consultant?.gender,
			dob: consultant?.dob,
			location_id: consultant?.location_id,
			custom_field_1: consultant?.custom_field_1,
			custom_field_2: consultant?.custom_field_2,
			custom_field_3: consultant?.custom_field_3,
			custom_field_4: consultant?.custom_field_4,
			custom_field_5: consultant?.custom_field_5,
			custom_field_6: consultant?.custom_field_6,
			custom_field_7: consultant?.custom_field_7,
			custom_field_8: consultant?.custom_field_8,
			custom_field_9: consultant?.custom_field_9,
			marital_status: consultant?.marital_status,
			wedding_date: consultant?.wedding_date,
			emergency_contact_name: consultant?.emergency_contact_name,
			emergency_contact_number: consultant?.emergency_contact_number,
		});
	}, [consultant, organisation]);

	return (
		<>
			{permissionList?.contractor?.update_permissions ? (
				<Box
					className={classes.editContainer}
					onClick={() => setEditModalOpen(true)}
				>
					<img className={classes.editButton} src={editicon} alt='edit icon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			) : null}
			<Box className={styles.container}>
				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Personal Email
					</Typography>

					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => {
							return (
								<Tooltip
									title={`${consultant?.personal_email || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${styles.margin} ${classes.ellipsis}`}
									>
										{`${consultant?.personal_email || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Address
					</Typography>
					<FunctionField
						record={consultant}
						className={classes.value}
						render={(consultant: any) => {
							return (
								<Tooltip
									title={
										consultant?.address?.houseName &&
										consultant?.address?.streetName
											? `${consultant?.address?.houseName || '--'}, ${
													consultant?.address?.streetName || '--'
											  }`
											: `${consultant?.address?.houseName || '--'}  ${
													consultant?.address?.streetName || '--'
											  }`
									}
									placement='right'
								>
									<Typography
										className={`${classes.value}  ${styles.margin} ${classes.ellipsis}`}
									>
										{consultant?.address?.houseName &&
										consultant?.address?.streetName
											? `${consultant?.address?.houseName || '--'}, ${
													consultant?.address?.streetName || '--'
											  }`
											: `${consultant?.address?.houseName || '--'}  ${
													consultant?.address?.streetName || '--'
											  }`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						record={consultant}
						className={classes.value}
						render={(consultant: any) => {
							return (
								<Tooltip
									title={
										consultant?.address?.houseName &&
										consultant?.address?.streetName
											? `${consultant?.address?.city || ''}, ${
													consultant?.address?.postalCode || ''
											  }`
											: `${consultant?.address?.city || ''}  ${
													consultant?.address?.postalCode || ''
											  }`
									}
									placement='right'
								>
									<Typography
										className={`${classes.value}  ${styles.margin} ${classes.ellipsis}`}
									>
										{consultant?.address?.houseName &&
										consultant?.address?.streetName
											? `${consultant?.address?.city || ''}, ${
													consultant?.address?.postalCode || ''
											  }`
											: `${consultant?.address?.city || ''}  ${
													consultant?.address?.postalCode || ''
											  }`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						record={consultant}
						className={classes.value}
						render={(consultant: any) => {
							return (
								<Tooltip
									title={`${consultant?.address?.state || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${styles.margin} ${classes.ellipsis}`}
									>
										{`${consultant?.address?.state || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<ReferenceField
						record={consultant}
						link={false}
						reference='geo_list'
						source='address.country'
						label=''
					>
						<TextField className={classes.value} source='name' />
					</ReferenceField>
				</FieldContainer>

				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Contract Start Date
					</Typography>
					{consultant?.join_date ? (
						<FunctionField
							className={classes.value}
							record={consultant}
							render={(consultant: any) => {
								return `${
									consultant?.join_date
										? dayjs(consultant?.join_date).format(dateFormat)
										: '--'
								}`;
							}}
						/>
					) : (
						'- -'
					)}
				</FieldContainer>

				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Contract End Date
					</Typography>
					{consultant?.end_date ? (
						<FunctionField
							className={classes.value}
							record={consultant}
							render={(consultant: any) => {
								return `${
									consultant?.end_date
										? dayjs(consultant?.end_date).format(dateFormat)
										: '--'
								}`;
							}}
						/>
					) : (
						'- -'
					)}
				</FieldContainer>

				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Joining Source
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => {
							return (
								<Tooltip
									title={`${
										consultant?.contractor_joining_source?.label || '- -'
									}`}
									placement='right'
								>
									<Typography
										className={`${classes.value} ${styles.margin} ${classes.ellipsis}`}
									>
										{`${consultant?.contractor_joining_source?.label || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Consultant Cost
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							consultant?.contractor_cost ? (
								<>
									<ReferenceField
										link={false}
										source='currency_id'
										reference='master_currencies'
										record={head(organisation?.organization)}
									>
										<TextField className={classes.value} source='symbol' />
									</ReferenceField>
									{consultant?.contractor_cost}
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Consultant Billing Rate
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							consultant?.billing_cost ? (
								<>
									<ReferenceField
										link={false}
										source='currency_id'
										reference='master_currencies'
										record={head(organisation?.organization)}
									>
										<TextField className={classes.value} source='symbol' />
									</ReferenceField>
									{consultant?.billing_cost}
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Shift
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							consultant?.org_shift_id ? (
								<>
									<ReferenceField
										link={false}
										source='org_shift_id'
										reference='org_shift'
										record={consultant}
									>
										<TextField className={classes.value} source='name' />
									</ReferenceField>
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Gender
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => {
							return (
								<Tooltip title={consultant?.gender || '- -'} placement='right'>
									<Typography
										className={`${classes.value} ${styles.margin} ${classes.ellipsis}`}
									>
										{consultant?.gender || '- -'}
									</Typography>
								</Tooltip>
							);
						}}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Marital Status</Typography>
					<Box className={classes.value}>
						{consultant?.marital_status ? 'Married' : 'Unmarried'}
					</Box>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Wedding Date</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							`${
								consultant?.marital_status
									? consultant?.wedding_date
										? dayjs(consultant?.wedding_date).format(dateFormat)
										: '- -'
									: '--'
							}`
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Name
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							`${consultant?.emergency_contact_name || '- -'}`
						}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>
						Emergency Contact Number
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							`${consultant?.emergency_contact_number || '- -'}`
						}
					/>
				</FieldContainer>

				<FieldContainer>
					<Typography className={classes.label}>Date of birth</Typography>
					<Typography className={classes.value}>
						{consultant?.dob
							? dayjs(consultant?.dob).format(dateFormat)
							: '- -'}
					</Typography>
				</FieldContainer>
				<FieldContainer>
					<Typography className={`${classes.label} ${styles.margin}`}>
						Location
					</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							consultant?.location_id ? (
								<>
									<ReferenceField
										link={false}
										source='location_id'
										reference='org_location'
										record={consultant}
									>
										<TextField className={classes.value} source='name' />
									</ReferenceField>
								</>
							) : (
								'- -'
							)
						}
					/>
				</FieldContainer>
				{/* CUSTOM FIELDS */}
				{consultant?.consultantCustomFields?.custom_field?.length > 0 &&
					consultant?.consultantCustomFields?.custom_field?.map(
						(customField: any, index: any) => {
							return (
								<FieldContainer key={index}>
									<FieldRenderer
										label={customField.label}
										tooltip={
											customField?.type === 'date' &&
											consultant[`custom_field_${customField?.index}`]
												? dayjs(
														consultant[`custom_field_${customField?.index}`]
												  ).format(dateFormat) || '- -'
												: consultant[`custom_field_${customField?.index}`] ||
												  '- -'
										}
										value={
											customField?.type === 'date' &&
											consultant[`custom_field_${customField?.index}`]
												? dayjs(
														consultant[`custom_field_${customField?.index}`]
												  ).format(dateFormat) || '- -'
												: consultant[`custom_field_${customField?.index}`] ||
												  '- -'
										}
									/>
								</FieldContainer>
							);
						}
					)}
			</Box>
			<EditConsultantBasicDetails
				open={editModalOpen}
				onClose={() => setEditModalOpen(false)}
				record={consultantDetails}
				refetch={() => refetch()}
				customFields={
					consultant?.consultantCustomFields?.custom_field?.length
						? consultant?.consultantCustomFields?.custom_field?.map(
								(customField: any) => ({
									id: customField.id,
									label: customField.label,
									type: customField.type,
									validation: customField.validation,
									options: customField?.options || [],
									is_active: customField.is_active,
									index: customField.index,
								})
						  )
						: []
				}
			/>
		</>
	);
};

export default ConsultantBasicDetails;
