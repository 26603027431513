import React from 'react';
import { TextInput, maxLength, required, useNotify } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { validateName } from '../../constant';
import { useUpsertUserEmailGroupMutation } from '../../../../generated/graphql';
import {
	CREATE_FAILED_MESSAGE,
	CREATE_MESSAGE,
	UNIQUENESS_WARNING_MESSAGE,
	UPDATE_FAILED_MESSAGE,
	UPDATE_MESSAGE,
} from './constant';

interface FormValue {
	id?: string;
	name?: string;
	description?: string | null | undefined;
	created_at?: string;
}

interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}

export const UserEmailGroupForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const settingsStyles = settingsStyle();
	const modalFormStyles = modalFormStyle();
	const notify = useNotify();

	const [upsertUserEmailGroup, { loading: isMutationLoading }] =
		useUpsertUserEmailGroupMutation();

	const saveGroup = (formValues: FormValue) => {
		const { name, description } = formValues;
		const groupName = name?.trim();
		const groupDescription = description?.trim();
		upsertUserEmailGroup({
			variables: {
				data: {
					name: groupName,
					description: groupDescription,
					id: formValues?.id,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify(UPDATE_MESSAGE);
						refetch();
						closeModal();
					} else {
						notify(CREATE_MESSAGE);
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (error) {
					notify(
						isEditMutation
							? UPDATE_FAILED_MESSAGE
							: error?.message?.includes('Uniqueness violation')
							? UNIQUENESS_WARNING_MESSAGE
							: CREATE_FAILED_MESSAGE,
						'warning'
					);
				}
			});
	};

	const closeModal = () => {
		onClose();
	};
	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={closeModal}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
				>
					<Box className={settingsStyles.container}>
						<Box className={modalFormStyles.headerContainer}>
							<Typography className={modalFormStyles.heading}>
								{isEditMutation
									? `Edit ${initialValues?.name}`
									: `Add User Email Group`}
							</Typography>
							<CloseIcon
								className={modalFormStyles.closeIcon}
								onClick={closeModal}
							/>
						</Box>
						<Form
							initialValues={isEditMutation ? initialValues : {}}
							onSubmit={saveGroup}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={modalFormStyles.formContainer}>
										<Typography className={modalFormStyles.label}>
											Name*
										</Typography>
										<TextInput
											source='name'
											label=''
											validate={[
												required(),
												validateName,
												maxLength(50, 'Maximum 50 characters allowed'),
											]}
											onChange={() => {}}
										/>
										<Typography className={modalFormStyles.label}>
											Description
										</Typography>
										<TextInput
											source='description'
											label=''
											multiline={true}
											onChange={() => {}}
										/>
									</Box>
									<Box
										className={modalFormStyles.buttonContainer}
										style={{ padding: 0 }}
									>
										<ButtonWithLoader
											title={isEditMutation ? 'Update' : 'Add'}
											isDisabled={invalid || pristine || isMutationLoading}
											isLoading={isMutationLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
		</>
	);
};
