import React, { useState, useEffect } from 'react'

import { useQuery as useApolloQuery } from '@apollo/client';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { GET_SKILLS, GET_SKILL_LEVELS } from "./EmployeeSkillMapping.gql";
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const theme = createTheme({
    overrides: {
        MuiSvgIcon: {
            colorPrimary: {
                color: "darkgray",
            },
        },
        MuiFilledInput: {
            root: {
                width: '224px',
                height: '36px',
                backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #E0E0E0',
                borderRadius: '4px',
                opacity: 1,
            },
            input: {
                fontSize: '12px',
                font: 'normal normal medium 12px/37px Manrope',
                letterSpacing: '0px',
                color: '#212121',
                opacity: 1,
            }
        },
        MuiInputLabel: {
            filled: {
                font: 'normal normal medium 12px/37px Manrope',
                letterSpacing: '0px',
                color: '#7C7C7C',
                opacity: 1,
                fontSize: '12px',
            }
        },
        MuiTypography: {
            body1: {
                fontSize: '12px',
                font: 'normal normal medium 12px/23px Manrope',
                letterSpacing: '0px',
                color: '#292929',
                opacity: 1,
            }
        }
    },
});

export const EmployeeSkillFilterFormBody = () => {
    const [skills, setSkills] = useState(null);
	const [searchedSkill, setSearchedSkill] = useState('');
	const [skillLimit, setSkillLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

    const [skillLevels, setSkillLevels] = useState(null);
	const [searchedSkillLevel, setSearchedSkillLevel] = useState('');
	const [skillLevelLimit, setSkillLevelLimit] = useState(MAX_FILTER_OPTIONS_SHOWN);

    const { data: employeeSkills } = useApolloQuery(GET_SKILLS, {
		variables: {
			limit: skillLimit,
			skill: `%${searchedSkill || ''}%`,
		},
	});

    const { data: employeeSkillLevel } = useApolloQuery(GET_SKILL_LEVELS, {
		variables: {
			limit: skillLevelLimit,
			level: `%${searchedSkillLevel || ''}%`,
		},
	});

    useEffect(() => {
		if (employeeSkills?.skills) {
			const skill = employeeSkills.skills.map((skill) => {
				return {
					id: skill.id,
					name: skill.name,
				};
			});
			setSkills(skill);
		}
	}, [employeeSkills]);

    useEffect(() => {
		if (employeeSkillLevel?.skillLevels) {
			const skillLevel = employeeSkillLevel.skillLevels.map((skillLevel) => {
				return {
					id: skillLevel.id,
					name: skillLevel.level,
				};
			});
			setSkillLevels(skillLevel);
		}
	}, [employeeSkillLevel]);

    const onSkillExpansion = (isSeeMoreLabelShown) => {
		setSkillLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onSkillSearch = (name) => {
		setSearchedSkill(name);
    }

    const onSkillLevelExpansion = (isSeeMoreLabelShown) => {
		setSkillLevelLimit(isSeeMoreLabelShown ? MAX_FILTER_OPTIONS_SHOWN : null);
	};

	const onSkillLevelSearch = (name) => {
		setSearchedSkillLevel(name);
	};
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Box>
					<FilterInput
						label={'Skill'}
						sourceName='skill_id'
						onExpansionToggle={onSkillExpansion}
						choices={skills || []}
						onSearch={(name) => onSkillSearch(name)}
						total={employeeSkills?.skill_master_aggregate?.aggregate?.count}
					/>
				</Box>

                <Box>
					<FilterInput
						label={'Skill Level'}
						sourceName='skill_level_id'
						onExpansionToggle={onSkillLevelExpansion}
						choices={skillLevels || []}
						onSearch={(name) => onSkillLevelSearch(name)}
						total={employeeSkillLevel?.skill_level_aggregate?.aggregate?.count}
					/>
				</Box>
            </Box>
        </ThemeProvider>
    )
}
export default EmployeeSkillFilterFormBody
