import React, { useState, useContext } from 'react';

import { FunctionField, ReferenceField, TextField } from 'react-admin';
import { getExperience } from '../../../Utils/date-time.util';

import { AvatarField } from '../../../SharedComponents/AvatarField.component';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EmployeeBasicDetailsForm from './EmployeeBasicDetailsForm.component';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import Box from '@material-ui/core/Box';
import { UserProfileContext } from '../../../App';
import { ellipsisStyle } from '../../../Layout/styles';
import { formatEmployeeId, getPrefix } from '../../../Utils/string.util';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '246px',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatarContainer: {
		width: '246px',
		height: '91px',
		backgroundColor: '#4285F4',
		borderRadius: '4px 4px 0px 0px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '60px',
	},
	avatarField: {
		width: '104px',
		height: '104px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100px',
		marginTop: '85px',
		position: 'relative',
		left: '16px',
	},
	identityContainer: {
		width: '246px',
		minHeight: '80px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '15px',
	},
	nameField: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
	},
	secondaryInfoField: {
		width: '100px',
		margin: '0 auto',
		font: 'normal normal 600 12px/20px Manrope !important',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	additionalInfoField: {
		font: 'normal normal medium Manrope',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	addtionalInfoContainer: {
		width: '85%',
		minHeight: '200px',
	},
	additionalContent: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
	},
	additionalInfoValue: {
		font: 'normal normal 600 12px/40px Manrope',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		padding: '0 5px 0 10px',
		maxWidth: '120px',
	},
	additionalInfoLabel: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
	},
	infoBar: {
		width: '84px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
		position: 'relative',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '20px',
		height: '20px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editButtonContainer: {
		opacity: 1,
		width: '36px',
		height: '36px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '85px',
		position: 'relative',
		left: '33px',
	},
	activeIcon: {
		width: '7px',
		height: '7px',
		borderRadius: '100px',
		position: 'absolute',
		left: '6px',
	},
	activeColor: {
		backgroundColor: '#34A853',
	},
	inactiveColor: {
		backgroundColor: '#5C5C5C',
	},
	billableIcon: {
		fontSize: 'small',
		color: '#5C5C5C',
		position: 'absolute',
		left: '6px',
	},
	infoBarValueContainer: {
		width: '67px',
		justifyContent: 'center',
		position: 'absolute',
		right: '0px',
	},
	phoneIcon: {
		width: '14px',
		paddingRight: '4px',
	},
	fullNameField: {
		marginLeft: '25px',
		marginRight: '25px',
	},
}));

export const EmployeeDetailsCard = (props) => {
	const { record: employee, basePath, resource, refetch } = props;
	const [isFormShown, setIsFormShown] = useState(false);
	const classes = useStyles();
	const styles = ellipsisStyle();

	const {
		permissions: permissionList,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext(UserProfileContext);

	const formattedEmployeeId = formatEmployeeId(
		employee?.emp_id,
		empIdLength,
		isFixedEmpIdLengthEnabled
	);
	return (
		<div className={classes.container}>
			<div className={classes.avatarContainer}>
				<div className={classes.avatarField}>
					<AvatarField
						imageSrc={employee?.user?.profile_pic}
						firstName={employee?.user?.first_name}
						lastName={employee?.user?.last_name}
						size='90'
					/>
				</div>
				<div className={classes.editButtonContainer}>
					{permissionList?.employee?.update_permissions && (
						<div
							onClick={() => setIsFormShown(true)}
							className={classes.editButton}
						>
							<EditIcon className={classes.editIcon} />
						</div>
					)}
				</div>
			</div>

			<div className={classes.identityContainer}>
				<FunctionField
					record={employee?.user}
					render={(record) => {
						return (
							<Tooltip
								title={`${record?.full_name || '- -'}`}
								placement='right'
							>
								<Typography
									className={`${classes.nameField} ${styles.ellipsis} ${classes.fullNameField}`}
								>
									{`${record?.full_name || '- -'}`}
								</Typography>
							</Tooltip>
						);
					}}
				/>
				<FunctionField
					record={employee}
					render={(record) => {
						return (
							<Tooltip
								title={`${record?.designation?.title || ''}`}
								placement='right'
							>
								<Typography
									className={`${classes.secondaryInfoField} ${styles.ellipsis}`}
								>
									{record?.designation?.title || ''}
								</Typography>
							</Tooltip>
						);
					}}
				/>

				<FunctionField
					record={employee}
					render={(record) => {
						return (
							<Tooltip
								title={`${record?.user?.email || '- -'}`}
								placement='right'
							>
								<Typography
									className={`${classes.additionalInfoField} ${styles.ellipsis} ${classes.fullNameField}`}
								>
									{`${record?.user?.email || '- -'}`}
								</Typography>
							</Tooltip>
						);
					}}
				/>
				{employee?.user?.phone_number && (
					<Box display='flex' justifyContent='center' alignItems='center'>
						<SmartphoneIcon className={classes.phoneIcon} />
						<Box className={classes.additionalInfoField}>
							{employee?.user?.phone_number}
						</Box>
					</Box>
				)}
				<div className={classes.infoBarContainer}>
					<div className={classes.infoBar}>
						<div
							className={[
								classes.activeIcon,
								employee?.user?.is_active
									? classes.activeColor
									: classes.inactiveColor,
							].join(' ')}
						/>
						<div className={classes.infoBarValueContainer}>
							<FunctionField
								record={employee}
								render={(employee) => (
									<Typography className={classes.infoField}>
										{employee?.user?.is_active ? 'Active' : 'Inactive'}
									</Typography>
								)}
							/>
						</div>
					</div>
					<div className={classes.infoBar}>
						<AttachMoneyIcon className={classes.billableIcon} />
						<div className={classes.infoBarValueContainer}>
							<FunctionField
								className={classes.infoField}
								record={employee}
								render={(employee) =>
									`${employee?.is_billable ? 'Billable' : 'Non-Billable'}`
								}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className={classes.addtionalInfoContainer}>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Employee ID
					</Typography>
					{formattedEmployeeId ? (
						<Tooltip
							title={`${
								getPrefix(
									employee?.employee_prefix?.name,
									employee?.employee_prefix?.value
								) || ''
							}${formattedEmployeeId}`}
						>
							<Typography
								className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
							>
								{`${
									getPrefix(
										employee?.employee_prefix?.name,
										employee?.employee_prefix?.value
									) || ''
								}${formattedEmployeeId}`}
							</Typography>
						</Tooltip>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Job Level
					</Typography>
					{employee?.job_level_id ? (
						<ReferenceField
							link={false}
							label='Job level'
							source='job_level_id'
							reference='job_level'
							record={employee}
							basePath={basePath}
							resource={resource}
						>
							<TextField
								source='level'
								className={classes.additionalInfoValue}
							/>
						</ReferenceField>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Department
					</Typography>

					{employee?.department_id ? (
						<Tooltip
							title={`${employee?.department?.name || ' - -'}`}
							placement='right'
						>
							<div
								className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
							>
								{`${employee?.department?.name || ' - -'}`}
							</div>
						</Tooltip>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Status
					</Typography>
					{employee?.employee_status?.label ? (
						<ReferenceField
							link={false}
							label=''
							source='employeeStatus'
							reference='employee_status'
							resource={resource}
							record={employee}
							basePath={basePath}
						>
							<TextField
								source='label'
								className={classes.additionalInfoValue}
							/>
						</ReferenceField>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Total Experience
					</Typography>
					{employee ? (
						<FunctionField
							record={employee}
							className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
							render={(employee) => {
								const employeeExperience = getExperience(employee);
								return employeeExperience?.totalExp.length > 2 ? (
									<Tooltip
										title={`${employeeExperience?.totalExp || '--'}`}
										placement='right'
									>
										<Typography
											className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
										>
											{employeeExperience?.totalExp}
										</Typography>
									</Tooltip>
								) : (
									'- -'
								);
							}}
						/>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Reporting to
					</Typography>
					{employee?.reportee_id && employee?.reportsTo?.is_active ? (
						<ReferenceField
							link={false}
							source='reportee_id'
							reference='user'
							record={employee}
							basePath={basePath}
							resource={resource}
						>
							<FunctionField
								render={(employee) => {
									return (
										<Tooltip
											title={`${`${employee?.full_name || ''} ` || '--'}`}
											placement='right'
										>
											<Typography
												className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
											>
												{`${`${employee?.full_name || ''} ` || '--'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
				<div className={classes.additionalContent}>
					<Typography className={classes.additionalInfoLabel}>
						Mode of Work
					</Typography>
					{employee?.working_mode ? (
						<TextField
							source='working_mode'
							record={employee}
							className={classes.additionalInfoValue}
						/>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</div>
			</div>
			<EmployeeBasicDetailsForm
				open={isFormShown}
				onClose={() => setIsFormShown(false)}
				onSuccess={() => refetch()}
				record={employee}
				{...props}
			/>
		</div>
	);
};

export default EmployeeDetailsCard;
