import React, { useState } from 'react';

import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';

import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../../settingsStyle';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import JobLevelForm from './JoblevelForm.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import { searchBarTheme } from '../../../../Layout/Theme.component';

interface JobLevel {
	id?: string;
	level: string;
	description?: string;
	org_id?: string;
}

const JobLevelList = () => {
	const [isJobLevelFormShown, setIsJobLevelFormShown] = useState(false);
	const [editJobLevelDetails, setEditJobLevelDetails] = useState<JobLevel>();
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isJobLevelEdit, setIsJobLevelEdit] = useState(false);
	const [choosedJobLevel, setChoosedJobLevel] = useState(null);
	const [searchJobLevel, setSearchJobLevel] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const onEditJobLevel = (jobLevel: JobLevel) => {
		if (!jobLevel) {
			return;
		}
		const { org_id, ...restValues } = jobLevel;
		setEditJobLevelDetails({ ...restValues });
		setIsJobLevelEdit(true);
		setIsJobLevelFormShown(true);
	};
	const deleteJobLevel = () => {
		mutate(
			{
				type: 'delete',
				resource: 'job_level',
				payload: {
					id: choosedJobLevel,
				},
			},
			{
				onSuccess: () => {
					notify('Job Level deleted Successfully');
					refresh();
					setIsDeleteModalShown(false);
					setChoosedJobLevel(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};
	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Job Level'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchJobLevel(e?.target?.value);
								}}
								value={searchJobLevel}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					style={{ width: '130px' }}
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsJobLevelFormShown(true);
						setIsJobLevelEdit(false);
					}}
				>
					Add Job Level
				</Button>
			</Box>
			<Box className={settingsStyles.content}>
				<List
					empty={false}
					resource='job_level'
					basePath='/job_level'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'level', order: 'ASC' }}
					filter={{ level: searchJobLevel }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='LEVEL'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.level}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.level}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(shift: any) => {
								return (
									<Tooltip
										title={`${shift.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{shift.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(level: any) => (
								<Box>
									<PopOver
										edit={() => onEditJobLevel(level)}
										remove={() => {
											setIsDeleteModalShown(true);
											setChoosedJobLevel(level?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<JobLevelForm
				open={isJobLevelFormShown}
				onClose={() => setIsJobLevelFormShown(false)}
				refetch={() => refresh()}
				initialValues={editJobLevelDetails}
				isEditMutation={isJobLevelEdit}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deleteJobLevel}
			/>
		</>
	);
};

export default JobLevelList;
