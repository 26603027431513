import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import './table-antdStyle.css';
import { insightChartTheme } from '../../Layout/Theme.component';

export const ContractorByDepartment = (props) => {
	const { departmentDetails, isLoading } = props;
	const columns = [
		{
			title: 'DEPARTMENT',
			dataIndex: 'name',
		},
		{
			title: 'EMPLOYEE COUNT',
			dataIndex: 'count',
		},
	];

	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Distribution by Department'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer height={355}>
								{departmentDetails && departmentDetails?.length > 0 ? (
									<Table
										size='small'
										columns={columns}
										dataSource={departmentDetails}
										scroll={{
											x: '100w',
											y: 300,
										}}
									/>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
