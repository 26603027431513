import * as React from "react";
import {
    Edit,
    SimpleForm,
    required,
    TextInput,
    ReferenceInput,
    SelectInput,
    useGetList,
    maxLength,
    TopToolbar,
    ListButton,
    SaveButton,
    Toolbar
} from 'react-admin';
import { Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const DepartmentTitle = ({ record }) => {
    return <span>Edit Department: {record ? `"${record.name}"` : ""}</span>;
};

export const DepartmentCreateActions = ({ basePath }) => {
    return (
      <TopToolbar>
        <ListButton
          basePath={basePath}
          label="Department List"
          icon={<ChevronLeft />}
        />
      </TopToolbar>
    );
  };
  
const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  );

export const EditDepartment = props => {
    const { data: organizations } = useGetList("organization");
    const orgIds = Object.keys(organizations);
    const selectedOrgId = orgIds.length === 0 ? "" : orgIds[0];
    return(
    <Edit {...props} title={<DepartmentTitle />} actions={<DepartmentCreateActions />}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput source="name" validate={[required(), maxLength(10)]} />
            <TextInput source="description" validate={required()}/>
            <Box display="none">
            <ReferenceInput source="org_id" reference="organization" label="Organization"><SelectInput resettable={true} initialValue={selectedOrgId.length ? selectedOrgId : ""} /></ReferenceInput>
            </Box>
        </SimpleForm>
    </Edit>
)};