import gql from 'graphql-tag';

export const GET_CRM_CONTACT_TITLES = gql`
	query getCrmContactTitles($limit: Int, $title: String) {
		crmContacts: crm_contacts(
			limit: $limit
			where: { title: { _ilike: $title } }
			distinct_on: title
		) {
			title
		}
		crm_contacts_aggregate(
			where: { title: { _ilike: $title } }
			distinct_on: title
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_COMPANIES = gql`
	query getCrmCompanies($limit: Int, $company: String) {
		companies: crm_company(
			limit: $limit
			where: { name: { _ilike: $company } }
			order_by: { name: asc }
		) {
			id
			name
		}
		crm_company_aggregate(where: { name: { _ilike: $company } }) {
			aggregate {
				count
			}
		}
	}
`;


export const GET_BD_OWNERS = gql`
	query MyQuery($limit: Int, $name: String) {
		contacts: crm_contacts(
			where: { user: { first_name: { _ilike: $name } } }
			limit: $limit
			distinct_on: owner_id
		) {
			owner_id
			user {
				id
				first_name
				last_name
				full_name
			}
		}
		crm_contacts_aggregate(
			where: { user: { first_name: { _ilike: $name } } }
			distinct_on: owner_id
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_CONTACTS = gql`
	query getContact($id: uuid!) {
		crm_contacts_by_pk(id: $id) {
			id
			first_name
			last_name
			company_id
			email
			contact_type
			primary_phone
			owner_id
			title
			alternate_phone
			background
			linkedin
			address
		}
	}
`;

export const GET_CONTACT_SEARCH = gql`
	query getContactSearch($name: String!) {
		crm_contacts(
			where: {
				_or: [
					{ first_name: { _ilike: $name } }
					{ last_name: { _ilike: $name } }
				]
			}
		) {
			id
			first_name
			full_name
			title
			email
			primary_phone
			linkedin
			contact_type
			user {
				first_name
				full_name
			}
			crm_company {
				name
			}
		}
	}
`;
