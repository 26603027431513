import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box, CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { useParams } from 'react-router';
import { useGetPipelineSalesByOwnerByIdQuery } from '../../generated/graphql';
import { formatAmount } from '../../Utils/string.util';
import NoDataFound from '../NoDataFound.component';
import { ChartDatePicker } from './CustomDatePicker.component';
import { ChartWithDateRange } from './modal';
import { insightChartTheme } from '../../Layout/Theme.component';

interface ChartData {
	'Total Value': number;
	name: string | null | undefined;
	kanbanColumnLabel: string;
}

export const OpportunityTotalSaleByOwnerReport = (
	props: ChartWithDateRange
) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const { id: boardId }: { id: string } = useParams();
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineTotalSaleByOwner, loading: isLoading } =
		useGetPipelineSalesByOwnerByIdQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				kanbanBoardId: boardId,
			},
		});

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineTotalSaleByOwner) {
			return;
		}
		const data = pipelineTotalSaleByOwner.user
			.map((user) => ({
				name: user.full_name,
				'Total Value': user.opportunities.reduce(
					(total, current) => total + current.value,
					0
				),
				kanbanColumnLabel:
					user?.opportunities[0]?.opportunity_kanban_cards[0]?.kanban_column
						?.name || '',
			}))
			.filter((opportunityDetails) => opportunityDetails['Total Value'] > 0)
			.sort((a, b) => b['Total Value'] - a['Total Value']);

		setChartData(data);
	}, [pipelineTotalSaleByOwner]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{label}</p>
					{payload[0]?.payload['Total Value'] ? (
						<p>
							{payload[0]?.payload['kanbanColumnLabel']}:
							{` ${formatAmount(
								parseInt(payload[0]?.payload['Total Value']),
								currency
							)}`}
						</p>
					) : (
						''
					)}
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='name'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey={'Total Value'}
						fill='#3f9908'
						barSize={10}
						stackId='a'
						radius={[10, 10, 0, 0]}
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						By Owner
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => setEndDate(date)}
							selectedStartDate={(date: string) => setStartDate(date)}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
