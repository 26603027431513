import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { detailsLeftCardStyle, ellipsisStyle } from '../../../Layout/styles';
import { Project } from '../../ResourcePlanner/ResourcePlanner.model';
import EditIcon from '@material-ui/icons/Edit';
import { useNotify } from 'react-admin';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProjectForm from '../../ResourcePlanner/ProjectForm.component';
import IconButton from '@material-ui/core/IconButton';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import { UserProfileContext } from '../../../App';

import {
	useDeleteProjectMutation,
	useGetProjectTimesheetEntryCountQuery,
} from '../../../generated/graphql';
import ProjectDeleteModal from './projectDeleteModal';
import {
	DELETE_PROJECT_FAIL_MESSAGE,
	DELETE_PROJECT_SUCCESS_MESSAGE,
	PROJECT_DELETION_WARNING_MESSAGE,
} from '../constant';
import ProjectClone from './projectCloneForm.component';

const scrollStyles = makeStyles({
	scrollableBox: {
		maxHeight: '370px',
		overflowY: 'auto',
		marginRight: '-20px',

		display: 'block',
	},
});

const useStyles = makeStyles((theme) => ({
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
		marginBottom: '16px',
	},
	infoBar: {
		width: '84px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
		position: 'relative',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
	},
	additionalInfoValue: {
		width: '90px',
		color: '#292929',
		fontSize: '12px !important',
		font: 'normal normal 600 12px/40px Manrope !important',
	},
	nameField: {
		width: '160px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		display: 'block',
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: 'white',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
		cursor: 'pointer',
		margin: '29px auto 5px auto',
	},
	header: {
		fontSize: '16px',
		fontFamily: 'Manrope-extrabold',
		color: '#FFFFFF',
		textAlign: 'center',
		maxWidth: '160px',
		maxHeight: '44px',
		lineHeight: '20px',
		wordBreak: 'break-all',
		display: '-webkit-box',
		webkitBoxOrient: 'vertical',
		mozBoxOrient: 'vertical',
		msBoxOrient: 'vertical',
		boxOrient: 'vertical',
		webkitLineClamp: 2,
		mozLineClamp: 2,
		msLineClamp: 2,
		lineClamp: 2,
		overflow: 'hidden',
	},
	avatarContainer: {
		position: 'relative',
		width: '246px',
		height: '110px',
		backgroundColor: '#4285F4',
		borderRadius: '4px 4px 0px 0px',
		opacity: 1,
		display: 'flex',
		marginBottom: '10px',
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	menuIcon: {
		position: 'absolute',
		right: 0,
		color: 'white',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	subHeading: {
		fontSize: '10px',
		fontFamily: 'Manrope',
		color: '#FFFFFF',
		margin: 'auto',
		maxWidth: '135px',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	riskIndicator: {
		marginRight: '5px',
		display: 'inline-block',
		height: '6px',
		width: '6px',
		borderRadius: '30px',
	},
	riskRed: {
		backgroundColor: '#EA4335',
	},
	riskGreen: {
		backgroundColor: '#34A853',
	},
	riskAmber: {
		backgroundColor: '#ff8f00',
	},
	actualHours: {
		width: '90px',
		font: 'normal normal 600 12px/40px Manrope !important',
	},
}));

interface ProjectDetailsCardProps {
	record: Project;
	refetch: () => void;
}

export const ProjectDetailsCard = (props: ProjectDetailsCardProps) => {
	const { permissions, dateFormat } = useContext<any>(UserProfileContext);
	const { record: project, refetch } = props;
	const detailsLeftCardStyles = detailsLeftCardStyle();
	const classes = useStyles();
	const scrollClass = scrollStyles();
	const history = useHistory();
	const styles = ellipsisStyle();
	const { id: projectId }: { id: string } = useParams();
	const [isProjectFormModalShown, setIsProjectFormModalShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isProjectCloneModalShown, setIsProjectCloneModalShown] =
		useState(false);
	const [anchorEl, setAnchorEl] = useState<any | null>(null);
	const [projectDetails, setProjectDetails] = useState({});

	const [deleteProject, { loading: isDeleteProjectMutationLoading }] =
		useDeleteProjectMutation();

	interface TimesheetStatus {
		label?: string;
		id?: string;
	}

	interface TimesheetSubmission {
		timesheet_status?: TimesheetStatus;
	}

	interface ProjectResourceMapping {
		id?: string;
		resource_cost?: number[] | null;
		project?: Project;
	}

	interface ProjectAggregateMapping {
		resource_cost?: number[] | null;
	}

	interface UserByEmployeeId {
		full_name?: string | null;
		project_resource_mappings?: ProjectResourceMapping[];
		project_resource_mappings_aggregate?: ProjectAggregateMapping[];
	}
	interface Project {
		name?: string;
	}

	const { data: projectTimesheetEntries } =
		useGetProjectTimesheetEntryCountQuery({
			variables: {
				projectId: projectId,
			},
		});

	interface ITimeSheet {
		working_hours: string;
	}

	const open = Boolean(anchorEl);
	const notify = useNotify();

	const onDeleteProject = () => {
		deleteProject({
			variables: {
				projectId: projectId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(DELETE_PROJECT_SUCCESS_MESSAGE);
					setIsDeleteModalShown(isDeleteProjectMutationLoading);
					history.push('/project');
				} else {
					notify(DELETE_PROJECT_FAIL_MESSAGE, 'warning');
				}
			})
			.catch((error: string) => {
				if (error) {
					notify(DELETE_PROJECT_FAIL_MESSAGE, 'warning');
				}
			});
	};

	useEffect(() => {
		if (!project) {
			return;
		}
		setProjectDetails({
			name: project?.name,
			revenue: project?.revenue,
			project_type: project?.project_type,
			start_date: project?.start_date,
			end_date: project?.end_date,
			project_owner_id: project?.project_owner_id,
			pre_sales_owner_id: project?.pre_sales_owner_id,
			is_strict: project?.is_strict,
			is_billable: project?.is_billable,
			currency_id: project?.currency_id,
			total_cost: project?.total_cost,
			effort_estimate: project?.effort_estimate,
			risk_status: project?.risk_status,
			project_execution_type: project?.project_execution_type,
			company_id: project?.company_id,
			geo_id: project?.geo_id,
			id: project?.id,
			project_status_id: project?.project_status_id,
			description: project?.description,
			prefix: project?.prefix,
			kanban_id: project?.kanban_id,
			tag_id: project?.tag_id,
			category_id: project?.category_id,
			custom_field_1: project?.custom_field_1,
			custom_field_2: project?.custom_field_2,
			custom_field_3: project?.custom_field_3,
			custom_field_4: project?.custom_field_4,
			custom_field_5: project?.custom_field_5,
			custom_field_6: project?.custom_field_6,
			custom_field_7: project?.custom_field_7,
			custom_field_8: project?.custom_field_8,
			custom_field_9: project?.custom_field_9,
		});
	}, [project]);

	return (
		<Box className={detailsLeftCardStyles.container}>
			<Box className={classes.avatarContainer}>
				{permissions?.project?.update_permissions && (
					<IconButton
						className={classes.menuIcon}
						onClick={(event) => setAnchorEl(event.currentTarget)}
					>
						<MoreVertIcon
							fontSize='small'
							aria-label='more'
							aria-controls='long-menu'
							aria-haspopup='true'
						></MoreVertIcon>
					</IconButton>
				)}
				<Menu
					elevation={0}
					id='long-menu'
					anchorEl={anchorEl}
					keepMounted
					open={open}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					onClose={() => setAnchorEl(null)}
				>
					<MenuItem
						onClick={() => {
							setIsProjectFormModalShown(true);
							setAnchorEl(null);
						}}
					>
						<EditIcon className={classes.editIcon} />
						<Box className={classes.editText}>Edit</Box>
					</MenuItem>
					{permissions?.appPermissions?.ui?.deleteProject && (
						<MenuItem
							onClick={() => {
								setIsDeleteModalShown(true);
								setAnchorEl(null);
							}}
						>
							<DeleteIcon className={classes.deleteIcon} />
							<Box className={classes.editText}>Delete</Box>
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							setIsProjectCloneModalShown(true);
							setAnchorEl(null);
						}}
					>
						<FileCopyIcon className={classes.editIcon} />
						<Box className={classes.editText}>Clone</Box>
					</MenuItem>
				</Menu>
				<Box className={detailsLeftCardStyles.identityContainer}>
					<Tooltip title={`${project?.name}`} placement='right'>
						<Box className={[classes.nameField, styles.ellipsis].join(' ')}>
							{project?.name}
						</Box>
					</Tooltip>
					<Box>
						<Tooltip title={`${project?.company?.name}`} placement='right'>
							<Typography className={classes.subHeading}>
								Client: {project?.company?.name || '--'}
							</Typography>
						</Tooltip>
					</Box>
					<Box>
						<Tooltip title={`${project?.project_type}`} placement='right'>
							<Typography className={classes.subHeading}>
								Project type: {project?.project_type || '--'}
							</Typography>
						</Tooltip>
					</Box>
				</Box>
			</Box>

			<Box className={detailsLeftCardStyles.addtionalInfoContainer}>
				<Box className={classes.infoBarContainer}>
					<Box className={classes.infoBar}>
						<Box className={classes.infoField}>
							{project?.is_billable
								? `${project?.currency?.symbol || ''} Billable`
								: 'Non Billable'}
						</Box>
					</Box>
					<Tooltip title='Project risk' placement='right-start'>
						<Box className={classes.infoBar}>
							<Box className={classes.infoField}>
								{project?.risk_status ? (
									<span
										className={`${classes.riskIndicator} ${
											project?.risk_status === 'Red'
												? classes.riskRed
												: project?.risk_status === 'Green'
												? classes.riskGreen
												: classes.riskAmber
										}`}
									></span>
								) : (
									''
								)}
								{project?.risk_status || '--'}
							</Box>
						</Box>
					</Tooltip>
				</Box>
				<Box className={scrollClass.scrollableBox}>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Project Owner'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Project Owner
							</Typography>
						</Tooltip>
						{project?.user?.full_name ? (
							<Tooltip title={`${project?.user?.full_name} `} placement='right'>
								<Box
									className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
								>
									{`${project?.user?.full_name} `}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography className={classes.additionalInfoValue}>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Presales Owner'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Presales Owner
							</Typography>
						</Tooltip>
						{project?.userByPreSalesOwnerId?.full_name ? (
							<Tooltip
								title={`${project?.userByPreSalesOwnerId?.full_name} `}
								placement='right'
							>
								<Box
									className={`${classes.additionalInfoValue} ${styles.ellipsis}`}
								>
									{`${project?.userByPreSalesOwnerId?.full_name} `}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography className={classes.additionalInfoValue}>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Project Status'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Project Status
							</Typography>
						</Tooltip>
						{project?.ProjectStatus?.label ? (
							<Tooltip
								title={`${project?.ProjectStatus?.label}`}
								placement='right'
							>
								<Box
									className={[
										classes.additionalInfoValue,
										styles.ellipsis,
									].join(' ')}
								>
									{project?.ProjectStatus?.label}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography
									className={detailsLeftCardStyles.additionalInfoValue}
								>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Is Strict'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Is Strict
							</Typography>
						</Tooltip>
						{project?.ProjectStatus?.label ? (
							<Tooltip
								title={project?.is_strict ? 'Yes' : 'No'}
								placement='right'
							>
								<Box className={classes.additionalInfoValue}>
									{project?.is_strict ? 'Yes' : 'No'}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'	- -'} placement='right'>
								<Typography
									className={detailsLeftCardStyles.additionalInfoValue}
								>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Start Date'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Start Date
							</Typography>
						</Tooltip>
						{project?.start_date ? (
							<Tooltip
								title={dayjs(project?.start_date).format(dateFormat)}
								placement='right'
							>
								<Box className={classes.additionalInfoValue}>
									{dayjs(project?.start_date).format(dateFormat)}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography className={classes.additionalInfoValue}>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Due Date'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Due Date
							</Typography>
						</Tooltip>
						{project?.end_date ? (
							<Tooltip
								title={dayjs(project?.end_date).format(dateFormat)}
								placement='right'
							>
								<Box className={classes.additionalInfoValue}>
									{dayjs(project?.end_date).format(dateFormat)}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography className={classes.additionalInfoValue}>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					<Box className={detailsLeftCardStyles.additionalContent}>
						<Tooltip title={'Country'} placement='right'>
							<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
								Country
							</Typography>
						</Tooltip>
						{project?.geo_list?.id ? (
							<Tooltip title={project?.geo_list?.name} placement='right'>
								<Box className={classes.additionalInfoValue}>
									{project?.geo_list?.name}
								</Box>
							</Tooltip>
						) : (
							<Tooltip title={'- -'} placement='right'>
								<Typography className={classes.additionalInfoValue}>
									- -
								</Typography>
							</Tooltip>
						)}
					</Box>
					{/* CUSTOM FIELDS */}
					<Box>
						{project?.projectCustomField?.custom_field?.length > 0 &&
							project?.projectCustomField?.custom_field?.map(
								(
									customField: { index?: number; label: string; type?: string },
									index: number
								) => {
									const customFieldKey =
										`custom_field_${customField?.index}` as string;
									const fieldValue =
										project[customFieldKey as keyof typeof project];

									const formattedValue =
										customField?.type === 'date' &&
										typeof fieldValue === 'string'
											? dayjs(fieldValue).format(dateFormat) || '- -'
											: typeof fieldValue === 'string'
											? fieldValue
											: '- -';

									return (
										<Box
											key={index}
											className={detailsLeftCardStyles.additionalContent}
										>
											<Tooltip title={customField.label} placement='right'>
												<Typography
													className={`${detailsLeftCardStyles.additionalInfoLabel} ${styles.customFieldEllipsis}`}
												>
													{customField.label}
												</Typography>
											</Tooltip>
											{fieldValue ? (
												<Tooltip title={formattedValue} placement='right'>
													<Box
														className={`${classes.additionalInfoValue}  ${styles.customFieldEllipsis}`}
													>
														{formattedValue}
													</Box>
												</Tooltip>
											) : (
												<Tooltip title={'- -'} placement='right'>
													<Typography className={classes.additionalInfoValue}>
														- -
													</Typography>
												</Tooltip>
											)}
										</Box>
									);
								}
							)}
					</Box>
				</Box>
			</Box>
			<ProjectForm
				open={isProjectFormModalShown}
				onClose={() => setIsProjectFormModalShown(false)}
				project={projectDetails}
				projectId={project?.id}
				refetch={refetch}
				isBillableDisabled={
					project?.id &&
					projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
						?.aggregate?.count &&
					projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
						?.aggregate?.count > 0
						? true
						: false
				}
			/>
			<ProjectDeleteModal
				mutationLoading={isDeleteProjectMutationLoading || false}
				open={isDeleteModalShown}
				onDelete={() => onDeleteProject()}
				onClose={() => setIsDeleteModalShown(false)}
				heading={` Delete Project "${project?.name || ''}" !`}
				confirmationMessage={PROJECT_DELETION_WARNING_MESSAGE}
			/>
			<ProjectClone
				open={isProjectCloneModalShown}
				onClose={() => setIsProjectCloneModalShown(false)}
				project={projectDetails}
				projectId={project?.id}
				refetch={refetch}
			/>
		</Box>
	);
};

export default ProjectDetailsCard;
