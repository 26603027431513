import React, { useState, useContext } from 'react';
import ProjectListing from './ProjectListing.component';
import { useGetList, Loading } from 'react-admin';
import { globalStyles } from '../../Layout/styles';
import { Box, Button, makeStyles } from '@material-ui/core';
import { InsightsIcon } from '../../assets/SvgIcons';
import ProjectGanttChart from './ProjectGanttChart.component';
import { UserProfileContext } from '../../App';
import { RESOURCE_PLANNER_CONTAINER } from '../constant';
import ResourceAllocationList from './ResourceAllocationList/ResourceAllocationList.component';

const navigationContainerStyles = makeStyles(() => ({
	navigationTabContainer: {
		width: '415px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
}));

export const ResourcePlanner = () => {
	const { permissions, featureFlag } = useContext(UserProfileContext);
	const { loading } = useGetList('project');
	const [container, setContainer] = useState(
		RESOURCE_PLANNER_CONTAINER.project
	);
	const styles = globalStyles();
	const navigationStyle = navigationContainerStyles();

	if (loading) {
		return <Loading />;
	}

	function handleProjectClick() {
		setContainer(RESOURCE_PLANNER_CONTAINER.project);
	}

	function handleGanttChartClick() {
		setContainer(RESOURCE_PLANNER_CONTAINER.ganttChart);
	}

	function handleResourceAllocationClick() {
		setContainer(RESOURCE_PLANNER_CONTAINER.resourceAllocations);
	}
	return (
		<>
			<Box className={navigationStyle.navigationTabContainer}>
				<Button
					className={[
						styles.navigationTabButtons,
						container === RESOURCE_PLANNER_CONTAINER.project
							? styles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						handleProjectClick();
					}}
				>
					Allocations
				</Button>
				{permissions?.appPermissions?.ui?.viewEmployeeInsights &&
					featureFlag?.resourceAllocationList && (
						<Button
							className={[
								styles.navigationTabButtons,
								container === RESOURCE_PLANNER_CONTAINER.resourceAllocations
									? styles.activeNavigationTabButton
									: '',
							].join(' ')}
							onClick={() => {
								handleResourceAllocationClick();
							}}
						>
							Resource Allocations
						</Button>
					)}
				{permissions?.appPermissions?.ui?.viewResourceAvailability && (
					<>
						<Button
							className={[
								styles.navigationTabButtons,
								container === RESOURCE_PLANNER_CONTAINER.ganttChart
									? styles.activeNavigationTabButton
									: '',
							].join(' ')}
							onClick={() => {
								handleGanttChartClick();
							}}
							startIcon={<InsightsIcon />}
						>
							Gantt
						</Button>
					</>
				)}
			</Box>
			{container === RESOURCE_PLANNER_CONTAINER.project && <ProjectListing />}
			{container === RESOURCE_PLANNER_CONTAINER.ganttChart && (
				<ProjectGanttChart />
			)}
			{container === RESOURCE_PLANNER_CONTAINER.resourceAllocations && (
				<ResourceAllocationList />
			)}
		</>
	);
};
export default ResourcePlanner;
