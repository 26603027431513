import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	Button,
	createTheme,
	makeStyles,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import {
	AutocompleteArrayInput,
	BooleanInput,
	ReferenceArrayInput,
	useNotify,
} from 'react-admin';
import {
	ENABLE_OVERTIME_CONFIRMATION,
	ENABLE_USR_CUSTOM_PIC_SUCCESS_TEXT,
} from '../constant';
import { settingsStyle } from '../settingsStyle';
import { UserProfileContext } from '../../../App';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import {
	useGetOvertimeApproversQuery,
	useUpdateOvertimeEnableMutation,
	useUpdateTimesheetApproverListMutation,
} from '../../../generated/graphql';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';

const employeeProfilePicSettingsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

const OvertimeSettings = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const employeeProfilePicSettingsStyles = employeeProfilePicSettingsStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();

	const classes = modalFormStyle();
	const styles = settingsStyle();
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isOvertimeEnabled, setIsOvertimeEnabled] = useState<boolean>(false);

	const { data: organisation, refetch } = useGetOvertimeApproversQuery({
		variables: {
			orgId: orgId,
		},
	});
	const [updateOverTime] = useUpdateOvertimeEnableMutation();
	const [updateOvertimeApproverList] = useUpdateTimesheetApproverListMutation();

	useEffect(() => {
		if (!organisation) {
			return;
		}
		setIsOvertimeEnabled(
			organisation?.organization_by_pk?.enable_overtime || false
		);
	}, [organisation]);

	const handleSave = () => {
		updateOverTime({
			variables: {
				orgId: orgId,
				enableOvertime: isOvertimeEnabled,
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify(ENABLE_USR_CUSTOM_PIC_SUCCESS_TEXT);
					refetch();
					onWarningModalClose();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleChange = (value: boolean) => {
		setIsOvertimeEnabled(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsOvertimeEnabled(!isOvertimeEnabled);
		setIsWarningModalShown(false);
	};
	const onHandleSubmit = (formValues: { user_id: string[] }) => {
		if (!formValues) {
			return;
		}
		if (formValues?.user_id) {
			updateOvertimeApproverList({
				variables: {
					organisationId: orgId,
					data: formValues?.user_id.map((id: string) => ({ user_id: id })),
				},
			})
				.then((res) => {
					if (!res.errors) {
						refetch();
						notify('Updated successfully');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	return (
		<>
			<Form
				onSubmit={handleSave}
				initialValues={{
					enable_overtime: isOvertimeEnabled,
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div className={employeeProfilePicSettingsStyles.container}>
								<Box
									className={settingsStyles.inputContainer}
									display='flex'
									flexDirection='column'
								>
									<>
										<div className={settingsStyles.inputContainer}>
											<BooleanInput
												label='Overtime'
												source='enable_overtime'
												onChange={handleChange}
											/>
										</div>
										<Typography
											className={employeeProfilePicSettingsStyles.description}
										>
											Enabling this will allow users to enter overtime.
										</Typography>
									</>
								</Box>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>
			{isOvertimeEnabled && (
				<ThemeProvider theme={modalFormTheme}>
					<Box className={styles.formContainer}>
						<Form
							initialValues={
								organisation &&
								organisation?.timesheet_overtime_approvers &&
								organisation?.timesheet_overtime_approvers?.length > 0
									? {
											user_id: organisation?.timesheet_overtime_approvers?.map(
												(user: any) => user?.user_id
											),
									  }
									: []
							}
							onSubmit={onHandleSubmit}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={styles.inputContainer}>
										<Box className={styles.inputLabel}>
											<Typography className={classes.label}>
												Choose Approvers
											</Typography>
										</Box>
										<Box className={styles.input}>
											<ReferenceArrayInput
												label=''
												reference='user'
												source='user_id'
												sort={{ field: 'first_name', order: 'ASC' }}
												filterToQuery={(searchText: string) => ({
													full_name: searchText,
												})}
											>
												<AutocompleteArrayInput optionText='full_name' />
											</ReferenceArrayInput>
										</Box>
									</Box>
									<Box
										className={classes.buttonContainer}
										style={{ padding: 0 }}
									>
										<Button
											variant='contained'
											disabled={invalid || pristine}
											className={
												invalid || pristine
													? classes.disabledButton
													: classes.saveButton
											}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</ThemeProvider>
			)}

			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
			/>
		</>
	);
};

export default OvertimeSettings;
