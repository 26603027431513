import React, { useState } from 'react';
import { Datagrid, FunctionField, List } from 'ra-ui-materialui';

import { Box, Button, IconButton } from '@material-ui/core';
import { datagridStyle, modalFormStyle } from '../../../Layout/styles';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import EditIcon from '@material-ui/icons/Edit';
import { Role } from './Role.model';
import RoleForm from './RoleForm.component';

export const RoleList = () => {
	const [isRoleFormShown, setIsRoleFormShown] = useState(false);
	const [selectedRole, setSelectedRole] = useState({});
	const datagridStyles = datagridStyle();
	const modalFormStyles = modalFormStyle();

	const ToolBar = () => (
		<Button
			variant='contained'
			className={modalFormStyles.saveButton}
			onClick={() => {
				setIsRoleFormShown(true);
			}}
		>
			Create Role
		</Button>
	);

	const onEditRole = (role: Role) => {
		setSelectedRole(role);
		setIsRoleFormShown(true);
	};

	return (
		<SettingsDetailContainer heading='ROLES'>
			<List
				resource='role'
				basePath='/role'
				bulkActionButtons={false}
				actions={<ToolBar />}
				title={' '}
				sort={{ field: 'name', order: 'ASC' }}
			>
				<Datagrid rowClick={''}>
					<FunctionField
						label='ROLE'
						render={(role: any) => `${role.name || '- -'}`}
					/>
					<FunctionField
						label='DESCRIPTION'
						render={(role: any) => `${role?.description || '- -'}`}
					/>
					<FunctionField
						render={(role: any) => (
							<Box className={datagridStyles.listEditIconContainer}>
								<IconButton
									className={datagridStyles.listEditIconButton}
									onClick={() => {
										onEditRole(role);
									}}
								>
									<EditIcon className={datagridStyles.editIcon} />
								</IconButton>
							</Box>
						)}
					/>
				</Datagrid>
			</List>
			<RoleForm
				initialValues={selectedRole}
				open={isRoleFormShown}
				onClose={() => {
					setIsRoleFormShown(false);
					setSelectedRole({});
				}}
			/>
		</SettingsDetailContainer>
	);
};

export default RoleList;
