import React, { useEffect, useState } from 'react';
import {
	Box,
	TextField as TextComponent,
	makeStyles,
	MenuItem,
	Select,
	ThemeProvider,
	Typography,
	FormControl,
	InputLabel,
	Tooltip,
} from '@material-ui/core';
import {
	TaskTableTheme,
	statusSelectBoxTheme,
} from '../ProjectTask/dataGridThemes';
import MUIDataTable, { FilterType } from 'mui-datatables';
import { SearchIcon } from '../../assets/SvgIcons';
import { searchBarTheme } from '../../Layout/Theme.component';
import {
	useGetProjectListForResourceRequestTableQuery,
	useGetProjectSkillRequirmentListQuery,
	useGetProjectSkillRequirmentListForExportQuery,
	useGetRestFiltersBasedOnProjectQuery,
	useUpdateProjectSkillRequirementMutation,
	GetProjectSkillRequirmentListForExportQuery,
} from '../../generated/graphql';
import { useNotify } from 'react-admin';
import { orderBy } from 'lodash';
import { exportToCsv } from '../../Utils/string.util';
import { ellipsisStyle } from '../../Layout/styles';
import { RESOURCE_REQUEST_DUPLICATE_SKILL_MESSAGE } from './constant';

const requestResourceListStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		marginLeft: '20px',
		maxHeight: '200px',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	notes: {
		width: '100px',
		cursor: 'pointer',
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Project'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

const RequestResourceContainer = () => {
	const requestResourceCustomStyle = requestResourceListStyle();
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [searchProject, setSearchProject] = useState('');
	const [resourceTableList, setResourceTableList] = useState<any[]>([]);
	const [statusOptions, setStatusOptions] = useState<any[]>([]);
	const [skillLevelOptions, setSkillLevelOptions] = useState<any[]>([]);
	const [projectFilter, setProjectFilter] = useState<string | null>('');
	const [selectedProject, setSelectedProject] = useState<string | null>('');
	const [skillFilter, setSkillFilter] = useState<string | null>('');
	const [selectedSkill, setSelectedSkill] = useState<string | null>('');
	const [selectedSkillLevel, setSelectedSkillLevel] = useState<string | null>(
		''
	);
	const [statusFilter, setStatusFilter] = useState<string | null>('');
	const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
	const [skillLevelFilter, setSkillLevelFilter] = useState<string | null>('');
	const [sortFilter, setSortFilter] = useState<any[]>([]);
	const [projectList, setProjectList] = useState<any[]>();
	const [skillList, setSkillList] = useState<any[]>();
	const [statusList, setStatusList] = useState<any[]>();
	const [skillLevelList, setSkillLevelList] = useState<any[]>();
	const [exportColumns, setExportColumns] = useState<any>([]);
	const [updateRequestDataId] = useUpdateProjectSkillRequirementMutation();
	const notify = useNotify();
	const ellipsis = ellipsisStyle();
	const [isExportTriggered, setisExportTriggered] = useState(false);
	const filterType: FilterType = 'custom';
	const { data: projectFilterOptions } =
		useGetProjectListForResourceRequestTableQuery({
			fetchPolicy: 'network-only',
		});
	const { data: restFilterOptions } = useGetRestFiltersBasedOnProjectQuery({
		variables: {
			filters: {
				project_id: projectFilter ? { _eq: projectFilter } : {},
				skill_id: skillFilter ? { _eq: skillFilter } : {},
				skill_level_id: skillLevelFilter ? { _eq: skillLevelFilter } : {},
				request_status_id: statusFilter ? { _eq: statusFilter } : {},
				deleted_at: { _is_null: true },
			},
		},
		fetchPolicy: 'network-only',
	});
	const { data: projectSkill, refetch: refetchProjectSkillList } =
		useGetProjectSkillRequirmentListQuery({
			variables: {
				sortFilter: sortFilter,
				limit: rowsPerPage,
				offset: page * rowsPerPage,
				filter: {
					deleted_at: { _is_null: true },
					project_id: projectFilter ? { _eq: projectFilter } : {},
					skill_id: skillFilter ? { _eq: skillFilter } : {},
					skill_level_id: skillLevelFilter ? { _eq: skillLevelFilter } : {},
					request_status_id: statusFilter ? { _eq: statusFilter } : {},
					project: {
						name: {
							_ilike: `%${searchProject}%`,
						},
					},
				},
			},
			fetchPolicy: 'network-only',
		});

	useGetProjectSkillRequirmentListForExportQuery({
		variables: {
			sortFilter: sortFilter,
			filter: {
				deleted_at: { _is_null: true },
				project_id: projectFilter ? { _eq: projectFilter } : {},
				skill_id: skillFilter ? { _eq: skillFilter } : {},
				skill_level_id: skillLevelFilter ? { _eq: skillLevelFilter } : {},
				request_status_id: statusFilter ? { _eq: statusFilter } : {},
				project: {
					name: {
						_ilike: `%${searchProject}%`,
					},
				},
			},
		},
		fetchPolicy: 'network-only',
		skip: !isExportTriggered,
		onCompleted: (data) => getExportData(data),
	});

	useEffect(() => {
		if (!projectFilterOptions) {
			return;
		}
		const projectOptions = projectFilterOptions?.project_skill_requirement?.map(
			(project) => {
				return {
					id: project?.project?.id,
					name: project?.project?.name,
				};
			}
		);
		setProjectList(
			orderBy(
				projectOptions,
				[(project) => project?.name?.toUpperCase()],
				['asc']
			)
		);
	}, [projectFilterOptions]);

	useEffect(() => {
		if (!restFilterOptions) {
			return;
		}
		const skillOptions = restFilterOptions?.skillList?.map((skill) => {
			return {
				id: skill?.skill_master?.id,
				name: skill?.skill_master?.name,
			};
		});
		setSkillList(
			orderBy(skillOptions, [(skill) => skill?.name?.toUpperCase()], ['asc'])
		);
		const statusArrayList = restFilterOptions?.statusList?.map((status) => {
			return {
				id: status?.project_skill_request_status?.id,
				name: status?.project_skill_request_status?.label,
			};
		});
		setStatusList(
			orderBy(
				statusArrayList,
				[(status) => status?.name?.toUpperCase()],
				['asc']
			)
		);
		const skillLevelOptions = restFilterOptions?.skillLevelList?.map(
			(skill) => {
				return {
					id: skill?.skill_level?.id,
					name: skill?.skill_level?.level,
				};
			}
		);
		setSkillLevelList(
			orderBy(
				skillLevelOptions,
				[(skill) => skill?.name?.toUpperCase()],
				['asc']
			)
		);
	}, [restFilterOptions]);
	useEffect(() => {
		if (!projectSkill) {
			setResourceTableList([]);
			return;
		}
		const statusOptionList = projectSkill?.project_skill_request_status;
		setStatusOptions(
			orderBy(
				statusOptionList,
				[(options) => options.label?.toUpperCase()],
				['asc']
			)
		);
		const skillLevelOptionList = projectSkill?.skill_level;
		setSkillLevelOptions(
			orderBy(
				skillLevelOptionList,
				[(optionList) => optionList.level.toUpperCase()],
				['asc']
			)
		);
		const resourceList = projectSkill?.project_skill_requirement?.map(
			(item) => {
				return {
					requestId: item?.id,
					notes: item?.notes,
					count: item?.count,
					projectName: item?.project?.name,
					skillName: item?.skill_master?.name,
					skillLevelName: item?.skill_level?.level,
					skillLevelId: item?.skill_level?.id,
					statusId: item?.project_skill_request_status?.id,
					status: item?.project_skill_request_status?.label,
					statusList: projectSkill?.project_skill_request_status,
				};
			}
		);
		setResourceTableList(resourceList);
	}, [projectSkill]);

	const onRequestSkillLevelChange = (levelId: string, rowId: string) => {
		updateRequestDataId({
			variables: {
				id: rowId,
				rowData: { skill_level_id: levelId },
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Skill level updated successfully');
					refetchProjectSkillList();
					return;
				}
				if (response?.errors) {
					notify('Updation failed', 'warning');
					return;
				}
			})
			.catch((error) => {
				if (error) {
					if (
						error.message.includes(
							'project_skill_requirement_project_id_skill_id_skill_level_id_or'
						)
					) {
						notify(RESOURCE_REQUEST_DUPLICATE_SKILL_MESSAGE, 'warning');
						return;
					}
				}
			});
	};

	const onRequestStatusChange = (statusId: string, rowId: string) => {
		updateRequestDataId({
			variables: {
				id: rowId,
				rowData: { request_status_id: statusId },
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Status updated successfully');
					refetchProjectSkillList();
					return;
				}
				if (response?.errors) {
					notify('Updation failed', 'warning');
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};
	const getExportData = (data: GetProjectSkillRequirmentListForExportQuery) => {
		const exportHeadings =
			exportColumns?.map((column: any) => column?.label) || [];
		const exportData =
			data?.project_skill_requirement?.map((request) => {
				return [
					...(exportHeadings?.includes('Project')
						? request?.project?.name
							? [request?.project?.name]
							: ['--']
						: []),
					...(exportHeadings?.includes('Skill')
						? request?.skill_master?.name
							? [request?.skill_master?.name]
							: ['--']
						: []),
					...(exportHeadings?.includes('Status')
						? request?.project_skill_request_status?.label
							? [request?.project_skill_request_status?.label]
							: ['--']
						: []),
					...(exportHeadings?.includes('Skill Level')
						? request?.skill_level?.level
							? [request?.skill_level?.level]
							: ['--']
						: []),
					...(exportHeadings?.includes('Count')
						? request?.count
							? [request?.count]
							: ['--']
						: []),
					...(exportHeadings?.includes('Notes')
						? request?.notes
							? [request?.notes]
							: ['--']
						: []),
				];
			}) || [];
		exportToCsv('Project_Resource_Request_Export.csv', [
			...[exportHeadings],
			...exportData,
		]);

		setisExportTriggered(false);
	};

	const resetProjectFilter = () => {
		if (!projectFilterOptions) {
			return;
		}
		const projectOptions = projectFilterOptions?.project_skill_requirement?.map(
			(project) => {
				return {
					id: project?.project?.id,
					name: project?.project?.name,
				};
			}
		);
		setProjectList(
			orderBy(
				projectOptions,
				[(project) => project?.name?.toUpperCase()],
				['asc']
			)
		);
	};
	const columns = [
		{
			name: 'projectName',
			label: 'Project',
			options: {
				filter: true,
				filterType: filterType,
				viewColumns: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box
								className={`${requestResourceCustomStyle.notes} ${ellipsis.ellipsis}`}
							>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Project</InputLabel>
								<Select
									value={selectedProject}
									onChange={(event: any) => {
										setSelectedProject(event?.target?.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{projectList?.map((item) => (
										<MenuItem
											onClick={() => setProjectFilter(item?.id)}
											key={item.id}
											value={item.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'skillName',
			label: 'Skill',
			options: {
				filter: true,
				filterType: filterType,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box
								className={`${requestResourceCustomStyle.notes} ${ellipsis.ellipsis}`}
							>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Skill</InputLabel>
								<Select
									value={selectedSkill}
									onChange={(event: any) => {
										setSelectedSkill(event?.target?.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{skillList?.map((item) => (
										<MenuItem
											onClick={() => setSkillFilter(item?.id)}
											key={item.id}
											value={item.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				filter: true,
				filterType: filterType,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const currentStatus = statusOptions?.find(
						(status: any) => status?.id === tableMeta?.rowData[6]
					);
					return (
						<>
							<ThemeProvider theme={statusSelectBoxTheme}>
								<Select
									value={currentStatus?.label}
									onChange={() => {}}
									disableUnderline={true}
									variant='outlined'
								>
									{statusOptions?.length > 0 &&
										statusOptions?.map(
											(option: { label: string; id: string }) => (
												<MenuItem
													key={option?.label}
													value={option?.label}
													onClick={(event: any) =>
														onRequestStatusChange(
															option?.id,
															tableMeta?.rowData[8]
														)
													}
												>
													{option?.label}
												</MenuItem>
											)
										)}
								</Select>
							</ThemeProvider>
						</>
					);
				},
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>Status</InputLabel>
								<Select
									value={selectedStatus}
									onChange={(event: any) => {
										setSelectedStatus(event?.target?.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{statusList?.map((item) => (
										<MenuItem
											onClick={() => {
												setStatusFilter(item?.id);
											}}
											key={item.id}
											value={item.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'skillLevelName',
			label: 'Skill Level',
			options: {
				filter: true,
				filterType: filterType,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const currentSkillLevel = skillLevelOptions?.find(
						(level: any) => level?.id === tableMeta?.rowData[7]
					);
					return (
						<>
							<ThemeProvider theme={statusSelectBoxTheme}>
								<Select
									value={currentSkillLevel?.level}
									onChange={() => {}}
									disableUnderline={true}
									variant='outlined'
								>
									{skillLevelOptions?.length > 0 &&
										skillLevelOptions?.map(
											(option: { level: string; id: string }) => (
												<MenuItem
													key={option?.level}
													onClick={(event: any) =>
														onRequestSkillLevelChange(
															option?.id,
															tableMeta?.rowData[8]
														)
													}
													value={option?.level}
												>
													{option?.level}
												</MenuItem>
											)
										)}
								</Select>
							</ThemeProvider>
						</>
					);
				},
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<FormControl>
								<InputLabel htmlFor='select-multiple-chip'>
									Skill Level
								</InputLabel>
								<Select
									value={selectedSkillLevel}
									onChange={(event: any) => {
										setSelectedSkillLevel(event?.target?.value);
										filterList[index][0] = event.target.value;
										onChange(filterList[index], index, column);
									}}
								>
									{skillLevelList?.map((item) => (
										<MenuItem
											onClick={() => setSkillLevelFilter(item?.id)}
											key={item.id}
											value={item.name}
										>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
			},
		},
		{
			name: 'count',
			label: 'Count',
			options: {
				filter: false,
			},
		},
		{
			name: 'notes',
			label: 'Notes',
			options: {
				filter: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box
								className={`${requestResourceCustomStyle.notes} ${ellipsis.ellipsis}`}
							>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'statusId',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'skillLevelId',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'requestId',
			label: '',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
	];

	const options = {
		filter: true,
		print: false,
		searchAlwaysOpen: true,
		rowsPerPage: rowsPerPage,
		serverSide: true,
		rowsPerPageOptions: [10, 20, 30],
		page: page,
		count: projectSkill?.totalRequestCount?.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setPage(0)
			setRowsPerPage(numberOfRows);
		},
		downloadOptions: {
			fileame: 'Project_Resource_Request',
			filterOptions: {
				useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true,
			},
		},
		textLabels: {
			body: {
				noMatch: 'No data found',
				pagination: {
					displayRows: 'on',
				},
			},
		},
		selectableRowsHideCheckboxes: true,
		onTableChange: (action: any, tableState: any) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'projectName': {
					setSortFilter([{ project: { name: direction } }]);
					break;
				}
				case 'skillName': {
					setSortFilter([{ skill_master: { name: direction } }]);
					break;
				}
				case 'status': {
					setSortFilter([
						{ project_skill_request_status: { label: direction } },
					]);
					break;
				}
				case 'skillLevelName': {
					setSortFilter([{ skill_level: { level: direction } }]);
					break;
				}
				case 'notes': {
					setSortFilter([{ notes: direction }]);
					break;
				}
				case 'count': {
					setSortFilter([{ count: direction }]);
					break;
				}
				default: {
					setSortFilter([]);
				}
			}
		},
		customSearchRender: (
			searchText: string,
			handleSearch: any,
			hideSearch: any,
			options: any
		) => {
			return (
				<>
					<CustomSearchRender searchText={searchText} onSearch={handleSearch} />
				</>
			);
		},
		onSearchChange: (searchText: string | null) => {
			if (!searchText) {
				setSearchProject('');
				return;
			}
			setSearchProject(searchText);
		},
		onDownload: (
			buildHead: (columns: any) => string,
			buildBody: (data: any) => string,
			columns: any,
			data: any
		) => {
			setisExportTriggered(true);
			setExportColumns(columns);
			return false;
		},
		onFilterChange: (
			changedColumn: any,
			filterList: any,
			type: any,
			changedColumnIndex: any,
			displayData: any
		) => {
			// Index here is the index of the column in column configuration array
			// if the length of the array of filterlist with index as given is 0 means
			//  no optiion is selected thus clearing the filter
			setPage(0);
			if (type === 'reset') {
				setProjectFilter(null);
				setSelectedProject(null);
				setSkillFilter(null);
				setSelectedSkill(null);
				setSkillLevelFilter(null);
				setSelectedSkillLevel(null);
				setStatusFilter(null);
				setSelectedStatus(null);
				resetProjectFilter();
				return;
			}
			if (filterList[0]?.length === 0) {
				setProjectFilter(null);
				setSelectedProject(null);
			}
			if (filterList[1]?.length === 0) {
				setSkillFilter(null);
				setSelectedSkill(null);
			}
			if (filterList[2]?.length === 0) {
				setStatusFilter(null);
				setSelectedStatus(null);
			}
			if (filterList[3]?.length === 0) {
				setSkillLevelFilter(null);
				setSelectedSkillLevel(null);
			}
		},
	};

	return (
		<Box marginLeft='10px'>
			<Box display='flex'>
				<Typography className={requestResourceCustomStyle?.heading}>
					Resource Request
				</Typography>
			</Box>

			<ThemeProvider theme={TaskTableTheme}>
				<>
					<MUIDataTable
						title={'Request Resource List'}
						data={resourceTableList}
						columns={columns}
						options={options}
					/>
				</>
			</ThemeProvider>
		</Box>
	);
};

export default RequestResourceContainer;
