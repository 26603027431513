import gql from 'graphql-tag';
export const GET_EMPLOYEE_SKILL_LIST = gql`
	query getEmployeeSkillSearch($filter: employee_skill_mapping_bool_exp = {}) {
		employee_skill_mapping(
			where: $filter
			order_by: { skill_master: { name: asc } }
		) {
			id
			employee_id
			skill_master {
				id
				name
			}
		}
	}
`;

export const GET_EMPLOYEE_SKILL = gql`
	query MyQuery($id: uuid!) {
		employee_skill_mapping_by_pk(id: $id) {
			id
			skill_level_id
			skill_id
			employee_id
			description
			is_primary
			user {
				first_name
				last_name
				full_name
			}
		}
	}
`;
export const GET_EMPLOYEE_STATUS_AUDIT = gql`
	query getEmployeeAuditHistory($userId: uuid!) {
		audit_audit_employee(
			order_by: { action_tstamp: asc }
			where: { action_on: { _eq: $userId } }
		) {
			user {
				id
				full_name
			}
			userByActionOn {
				id
				full_name
			}
			action_tstamp
			action
			action_type
			action_data
		}
	}
`;
