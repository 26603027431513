import React, { useContext } from 'react';

import { SelectInput, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { dateOptions } from '../../constant';

import { Typography, Box, ThemeProvider, makeStyles } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPDATE_DATE_FORMAT } from '../Settings.gql';
import { UserProfileContext } from '../../../App';
import { useGetDateFormatSettingsByIdQuery } from '../../../generated/graphql';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

const customStyle = makeStyles({
	dateContainer: {
		marginRight: '10px',
		width: '10.5vw',
	},
	finacialYearPeriodContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '12px',
	},
});

const DateFormatSettings = () => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyles = customStyle();
	const notify = useNotify();
	const [updateDateFormat, { loading: isUpdateDateFormatLoading }] =
		useApolloMutation(UPDATE_DATE_FORMAT);
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const { data: dateFormatDetails, refetch } =
		useGetDateFormatSettingsByIdQuery({
			variables: {
				id: organizationId,
			},
			fetchPolicy: 'network-only',
		});
	const onHandleSubmit = (formValues: { date_format: string }) => {
		updateDateFormat({
			variables: {
				orgId: organizationId,
				data: formValues,
			},
		})
			.then((response: any) => {
				if (!response.error) {
					notify('Date format updated successfully');
					refetch();
					return;
				}
			})
			.catch((error: any) => {
				if (error) {
					notify('Date format updation failed', 'warning');
					return;
				}
			});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form
					initialValues={{
						date_format:
							dateFormatDetails?.organization_by_pk?.date_format || null,
					}}
					onSubmit={onHandleSubmit}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Date format</Typography>
								</Box>
								<Box className={customStyles.dateContainer}>
									<SelectInput
										source='date_format'
										label=''
										choices={dateOptions}
										onChange={(e) => {}}
									/>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isDisabled={invalid || pristine || isUpdateDateFormatLoading}
									isLoading={isUpdateDateFormatLoading}
									title='Save'
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default DateFormatSettings;
