import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import {
	GetOvertimeTimesheetsQuery,
	GetOvertimeTotalHoursQuery,
	GetProjectsOptionsQuery,
} from '../../../generated/graphql';
import {
	MASTER_PROJECT_TASK_TYPE,
	ORG_TASK_TYPE,
	PROJECT_TYPE_CATEGORY,
} from '../constant';
import { SubmittedOvertimes } from './Overtime.model';

export const getTimesheetOvertimeDataForDay = (
	data: GetOvertimeTimesheetsQuery
) => {
	if (!data) {
		return;
	}
	const projectTaskEntries = data.timesheet_overtime
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.project_task && {
					id: timesheet.id,
					taskType: PROJECT_TYPE_CATEGORY,
					project: timesheet?.project?.is_billable
						? timesheet?.project?.name
						: `${timesheet?.project?.name} (NB)`,
					project_id: timesheet?.project?.id,
					task: timesheet?.project_task.is_billable
						? timesheet?.project_task?.title
						: `${timesheet?.project_task?.title} (NB)`,
					task_id: timesheet?.project_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.project?.company?.name,
					approver_id: timesheet?.approver_id,
					day_type_id: timesheet?.day_type_id,
				}
			);
		})
		.filter((task) => task !== null);

	const orgTaskEntries = data.timesheet_overtime
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.org_task && {
					id: timesheet.id,
					taskType: ORG_TASK_TYPE,
					project: timesheet?.org_task.task_category,
					project_id: timesheet?.org_task.task_category,
					task: timesheet?.org_task?.is_billable
						? timesheet?.org_task?.title
						: `${timesheet?.org_task?.title} (NB)`,
					task_id: timesheet?.org_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.org_task.organization.name,
					approver_id: timesheet?.approver_id,
					day_type_id: timesheet?.day_type_id,
				}
			);
		})
		.filter((task) => task !== null);

	const masterProjectTaskEntries = data.timesheet_overtime
		.map((timesheet) => {
			const timesheetWorkingHours = timesheet.working_hours.split(':');

			return (
				timesheet?.master_project_task && {
					id: timesheet.id,
					taskType: MASTER_PROJECT_TASK_TYPE,
					project: timesheet?.project?.is_billable
						? timesheet?.project?.name
						: `${timesheet?.project?.name} (NB)`,
					project_id: timesheet?.project?.id,
					task: timesheet?.master_project_task.is_billable
						? timesheet?.master_project_task?.title
						: `${timesheet?.master_project_task?.title} (NB)`,
					task_id: timesheet?.master_project_task?.id,
					working_hours: `${timesheetWorkingHours[0]}:${timesheetWorkingHours[1]}`,
					notes: timesheet?.notes,
					companyName: timesheet?.project?.company?.name,
					approver_id: timesheet?.approver_id,
					day_type_id: timesheet?.day_type_id,
				}
			);
		})
		.filter((task) => task !== null);

	return [
		...projectTaskEntries,
		...orgTaskEntries,
		...masterProjectTaskEntries,
	];
};

export const getTimesheetOvertimeSubmissionId = (
	data: GetOvertimeTotalHoursQuery | undefined,
	date: string
) => {
	if (!data) {
		return;
	}
	const selectedWeekSubmission = data.timesheet_overtime_daily_hours.find(
		(totalHours) => totalHours?.date === date
	);
	return selectedWeekSubmission?.timesheet_submission_id;
};

export const getOvertimeTotalHoursByDay = (
	date: string,
	totalHours: GetOvertimeTotalHoursQuery
) => {
	if (!totalHours || !date) {
		return;
	}
	const selectedHoursAndMinutes =
		totalHours.timesheet_overtime_daily_hours.find(
			(totalHours) => totalHours?.date === date
		);
	const hoursAndMinutes = selectedHoursAndMinutes
		? selectedHoursAndMinutes.sum.split(':')
		: [];

	return `${hoursAndMinutes[0] || '00'}:${hoursAndMinutes[1] || '00'}`;
};

export const getProjectOptions = (projectOptions: GetProjectsOptionsQuery) => {
	if (!projectOptions) {
		return;
	}
	const projects = orderBy(
		projectOptions?.project_resource_mapping.map((projectResourceMapping) => ({
			id: projectResourceMapping.project.id,
			name: projectResourceMapping.project.name,
			companyName: projectResourceMapping.project.company?.name,
			isBillable: projectResourceMapping.project.is_billable,
			type: PROJECT_TYPE_CATEGORY,
		})),
		[(option) => option?.name?.toUpperCase()],
		['asc']
	);
	const orgTaskCategories = orderBy(
		projectOptions.org_tasks.map((task) => ({
			id: task.task_category,
			name: task.task_category,
			companyName: task.organization.name,
			isBillable: true, // org tasks is only based on if task is billable or not
			type: task.task_category,
		})),
		[(option) => option?.name?.toUpperCase()],
		['asc']
	);

	return [...orgTaskCategories, ...projects];
};
export const getBillableOvertimeHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	return timesheets
		.map((timesheetEntry) => {
			if (timesheetEntry.is_billable === false) {
				return '00:00:00';
			}
			return timesheetEntry.working_hours.split(':');
		})
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
};
export const getTimesheetWeek = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets || !timesheets[0]?.date) {
		return;
	}
	const startDate = dayjs(timesheets[0].date).day(0).format('DD MMM YYYY');
	const endDate = dayjs(timesheets[0].date).day(6).format('DD MMM YYYY');

	return `${startDate || ''} - ${endDate || ''}`;
};

export const getBillableHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	return timesheets
		.map((timesheetEntry) => {
			if (timesheetEntry.is_billable === false) {
				return '00:00:00';
			}
			return timesheetEntry.working_hours.split(':');
		})
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
};
export const getNonBillableHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	return timesheets
		.map((timesheetEntry) => {
			if (timesheetEntry.is_billable === true) {
				return '00:00:00';
			}
			return timesheetEntry.working_hours.split(':');
		})
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
};

export const getTotalNonBillableHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	const totalBillableHours = getNonBillableHours(timesheets);

	return totalBillableHours
		? `${Math.floor(totalBillableHours.asHours())}:${totalBillableHours.format(
				'mm'
		  )}`
		: '00:00';
};
export const getTimesheetEntriesBillablePercentage = (
	timesheets: SubmittedOvertimes[]
) => {
	if (!timesheets) {
		return;
	}
	const totalHours = timesheets
		.map((timesheet) => timesheet.working_hours.split(':'))
		.map((hoursAndMinutes) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total, durations) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		)
		.asHours();

	const totalBillableHours = getBillableHours(timesheets);

	return totalBillableHours
		? ((totalBillableHours?.asHours() / totalHours) * 100).toFixed()
		: 0;
};
export const getTotalBillableHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	const totalBillableHours = getBillableHours(timesheets);

	return totalBillableHours
		? `${Math.floor(totalBillableHours.asHours())}:${totalBillableHours.format(
				'mm'
		  )}`
		: '00:00';
};
export const getTotalHours = (timesheets: SubmittedOvertimes[]) => {
	if (!timesheets) {
		return;
	}
	const timesheetDailyHours = timesheets.map(
		(timesheet: any) => timesheet.working_hours
	);

	const totalHours = timesheetDailyHours
		.map((timesheetDailyHour: any) => timesheetDailyHour.split(':'))
		.map((hoursAndMinutes: any) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total: any, durations: any) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);

	return (
		`${Math.floor(totalHours.asHours())}:${totalHours.format('mm')}` || '00:00'
	);
};

export const getOvertimeEnteredTaskName = (overtime: any) => {
	const taskName = overtime?.task_id
		? overtime?.project_task?.title
		: overtime?.org_task_id
		? overtime?.org_task?.title
		: overtime?.master_project_task_id
		? overtime?.master_project_task?.title
		: '';

	return taskName;
};

export const getOvertimeEnteredProjectName = (overtime: any | undefined) => {
	const projectName = overtime?.task_id
		? overtime?.project?.name
		: overtime?.org_task_id
		? overtime?.org_task?.task_category
		: '';

	return projectName;
};
