import React, { useEffect, useState } from 'react';
import {
	SelectInput,
	useQuery,
	useMutation,
	useNotify,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	useRefresh,
} from 'react-admin';
import { useMutation as useApolloMutation } from '@apollo/client';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	ThemeProvider,
	Box,
	makeStyles,
	Button,
	Typography,
	Switch,
	Tooltip,
} from '@material-ui/core';
import { modalFormStyle } from '../../../Layout/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import KanbanColumnForm from './KanbanColumnForm.component';
import KanbanTemplateForm from './KanbanTemplate.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { UPDATE_KANBAN_ITEMS } from '../Settings.gql';
import {
	useGetKanbanBoardStatesQuery,
	useUpdateDefaultKanbanMutation,
	useUpdateKanbanBoardPrimaryUserMutation,
	useGetKanbanBoardPrimaryUserQuery,
	useGetOpportunityCountByPresalesOwnerQuery,
} from '../../../generated/graphql';
import { head, orderBy } from 'lodash';
import StarIcon from '@material-ui/icons/Star';
import KanbanWarningModal from './kanbanWarning.component';
import Delete from '@material-ui/icons/Delete';
import { useDeletePipelineMutation } from '../../../generated/graphql';
import { regexNameValidation } from '../constant';
import {
	DELETE_FAILED_WARNING,
	DELETE_SUCCESS_MESSAGE,
	DELETE_WARNING_MESSAGE,
} from './constant';

const customStyle = makeStyles({
	actionContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	kanbanColumnCard: {
		width: '100%',
		padding: '10px',
		border: '1px solid #E0E0E0',
		marginBottom: '10px',
		borderRadius: '4px',
		boxShadow: '0px 3px 6px #0000000F',
		display: 'flex',
		alignItems: 'center',
		background: '#FFFFFF',
		justifyContent: 'space-between',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
	},
	dataContaner: {
		marginLeft: '12px',
	},
	dragIndicator: {
		width: '18px',
		height: '18px',
	},
	noData: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	editIcon: {
		marginTop: '4px',
		width: '15px',
		height: '15px',
		cursor: 'pointer',
		marginLeft: '5px',
	},
	label: {
		fontFamily: 'Manrope-medium',
	},
	iconContainer: {
		width: '33px',
		height: '36px',
		marginLeft: '5px',
		background: '#e0e0e0',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '4px',
		alignItems: 'center',
		cursor: 'pointer',
	},
	icon: {
		width: '22px',
		height: '18px',
	},
	nonDefaultColor: {
		color: '#C7C7C7',
		width: '20px',
		height: '20px',
	},
	defaultColor: {
		color: '#EA9926',
		width: '20px',
		height: '20px',
	},
	primaryUserEditIcon: {
		marginTop: '1px',
		width: '15px',
		height: '15px',
		cursor: 'pointer',
		marginLeft: '5px',
	},
	error: {
		color: 'red',
		fontSize: '12px',
	},

	deleteIcon: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '8px',
		marginTop: '5px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '100px',
		textOverflow: 'ellipsis',
	},
	width: {
		width: '150px',
	},
	input: {
		font: 'ManRope-medium',
		fontColor: '#292929',
		fontSize: '12px',
	},
});

interface FormValue {
	master_module?: string;
	master_sub_module?: string;
	kanban_template?: string;
}
interface Task {
	description?: string;
	id?: string;
	name?: string;
	updated_at?: string;
	created_at?: string;
	ordinal?: number;
	state?: string;
}
interface Type {
	description?: string;
	id?: string;
	is_active?: boolean;
	is_strict_order?: boolean;
	label?: string;
	name?: string;
	sub_module_id?: string;
	is_default?: boolean;
}
export const KanbanSettings = () => {
	const [mutate] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();
	const modalFormStyles = modalFormStyle();
	const customKanbanSettingsStyle = customStyle();
	const [isTaskEdit, setIsTaskEdit] = useState(false);
	const [formValues, setFormValues] = useState<FormValue>();
	const [selectedModule, setSelectedModule] = useState<string>('');
	const [moduleSelectOptions, setModuleSelectOptions] = useState([]);
	const [subModuleSelectOptions, setSubModuleSelectOptions] = useState([]);
	const [selectedSubModule, setSelectedSubModule] = useState<string>('');
	const [selectedSubModuleDetails, setSelectedSubModuleDetails] = useState<any>(
		{}
	);
	const [kanbanBoardOptions, setKanbanBoardOptions] = useState([]);
	const [selectedKanbanBoard, setSelectedKanbanBoard] = useState<
		string | undefined
	>('');
	const [isTaskFormShown, setIsTaskFormShown] = useState(false);
	const [isTypeFormShown, setIsTypeFormShown] = useState(false);
	const [selectedTask, setSelectedTask] = useState<Task>({});
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isDeletePipelineModalShown, setIsDeletePipelineModalShown] =
		useState(false);
	const [selectedType, setSelectedType] = useState<Type>();
	const [isTypeNameEdit, setIsTypeNameEdit] = useState(false);
	const [kanbanTemplateName, setKanbanTemplateName] = useState<string>('');
	const [columns, setColumns] = useState<Task[]>();
	const [isColumnReOrdered, setIsColumnReOrdered] = useState(false);
	const [updateKanbanItems] = useApolloMutation(UPDATE_KANBAN_ITEMS);
	const [kanbanStages, setKanbanStages] = useState([]);
	const [isActive, setIsActive] = useState(true);
	const [isDefault, setIsDefault] = useState(true);
	const [defaultKanbanDetails, setDefaultkanbanDetails] = useState<Type>();
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [kanbanBoardId, setKanbanBoardId] = useState<string>('');
	const [isPrimaryUserEdit, setIsPrimaryUserEdit] = useState(false);
	const [primaryUserId, setPrimaryUserId] = useState<string>('');
	const [primaryUserError, setPrimaryUserError] = useState('');
	const [primaryUserName, setPrimaryUserName] = useState('');
	const [kanbanTemplateNameError, setKanbanTemplateNameError] = useState('');
	const classes = modalFormStyle();
	const [updateInviteUser] = useUpdateKanbanBoardPrimaryUserMutation();

	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};

	const { data: primaryUser, refetch } = useGetKanbanBoardPrimaryUserQuery({
		variables: { boardId: kanbanBoardId },
	});

	const { data: kanbanBoardStateList } = useGetKanbanBoardStatesQuery({
		variables: {
			kanbanBoardType: selectedSubModuleDetails?.value,
		},
	});

	const { data: opportunityCount } = useGetOpportunityCountByPresalesOwnerQuery(
		{
			variables: {
				boardId: kanbanBoardId,
				userId: primaryUser?.kanban_board_invited_users[0]?.user?.id,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: masterModuleList } = useQuery({
		type: 'GET_LIST',
		resource: 'master_modules',
		payload: {
			sort: { field: 'label', order: 'ASC' },
			filter: { have_kanban: true },
		},
	});

	const { data: masterSubModuleList } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'master_sub_modules',
			payload: {
				filter: { master_module_id: selectedModule },
				sort: { field: 'label', order: 'ASC' },
			},
		},
		{ enabled: !!selectedModule }
	);

	const { data: kanbanBoardList, refetch: refetchKanbanBoardList } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'kanban_board',
			payload: {
				sort: { field: 'name', order: 'ASC' },
				filter: { sub_module_id: selectedSubModule },
			},
		},
		{ enabled: !!selectedSubModule }
	);
	const { data: kanbanColumnList, refetch: refetchKanbanColumnList } = useQuery(
		{
			type: 'GET_LIST',
			resource: 'kanban_column',
			payload: {
				sort: { field: 'ordinal', order: 'ASC' },
				filter: {
					board_id: selectedKanbanBoard,
				},
			},
		},
		{ enabled: !!selectedKanbanBoard }
	);

	const [updateDefaultKanbanTemplate] = useUpdateDefaultKanbanMutation();

	useEffect(() => {
		setPrimaryUserError('');
		setKanbanTemplateNameError('');
	}, []);

	useEffect(() => {
		if (!masterModuleList) {
			return;
		}
		const moduleOptions = masterModuleList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.label,
			};
		});
		setModuleSelectOptions(moduleOptions);
	}, [masterModuleList]);

	useEffect(() => {
		if (!primaryUser?.kanban_board_invited_users[0]?.user) {
			setPrimaryUserName('');
			return;
		}
		const kanbanBoardDetails = primaryUser?.kanban_board_invited_users[0]?.user;
		const currentType = kanbanBoardList?.find(
			(type: Type) => type?.id === kanbanBoardId
		);
		setPrimaryUserName(`${kanbanBoardDetails?.full_name} `);
		setPrimaryUserId(kanbanBoardDetails?.id);
		setKanbanTemplateName(
			primaryUser?.kanban_board_invited_users[0]?.kanban_board?.name.trim()
		);
		setIsActive(currentType?.is_active);
		setIsDefault(currentType?.is_default);
	}, [primaryUser, kanbanBoardId, kanbanBoardList]);

	useEffect(() => {
		if (!masterSubModuleList) {
			return;
		}
		const moduleOptions = masterSubModuleList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.label,
			};
		});
		setSubModuleSelectOptions(moduleOptions);
	}, [masterSubModuleList]);

	useEffect(() => {
		if (!kanbanBoardList) {
			return;
		}
		const listOptions = kanbanBoardList?.map((option: any) => {
			return {
				id: option?.id,
				name: option?.name,
				is_default: option?.is_default,
			};
		});
		setKanbanBoardOptions(listOptions);
		const defaultKanban = kanbanBoardList?.find(
			(board: any) => board?.is_default === true
		);
		setDefaultkanbanDetails(defaultKanban);
	}, [kanbanBoardList]);

	useEffect(() => {
		if (!kanbanColumnList) {
			return;
		}
		const columnsList = kanbanColumnList?.map((task: Task) => ({
			id: task.id,
			name: task.name,
			description: task.description,
			ordinal: task.ordinal,
			state: task?.state,
		}));
		if (columnsList) {
			setColumns(columnsList);
		} else {
			setColumns([]);
		}
	}, [kanbanColumnList]);

	useEffect(() => {
		if (!kanbanBoardStateList) {
			return;
		}
		const kanbanStateOptions = head(
			kanbanBoardStateList?.master_settings
		)?.states?.map((state: string) => {
			return { id: state, name: state };
		});
		const sortedKanbanStateOptions: any = orderBy(
			kanbanStateOptions,
			[(state) => state.name.toUpperCase()],
			['asc']
		);
		setKanbanStages(sortedKanbanStateOptions);
	}, [kanbanBoardStateList]);
	const reorder = (list: Task[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const reOrderedColumns =
			columns &&
			reorder(columns, result.source.index, result.destination.index);
		if (reOrderedColumns) {
			setColumns(reOrderedColumns);
			setIsColumnReOrdered(true);
		}
	};

	const onEditTask = (task: Task) => {
		setIsTaskFormShown(true);
		setIsTaskEdit(true);
		const { created_at, updated_at, ...rest } = task;
		setSelectedTask({ ...rest });
	};

	const onDeleteTask = () => {
		mutate(
			{
				type: 'delete',
				resource: 'kanban_column',
				payload: {
					id: selectedTask?.id,
				},
			},
			{
				onSuccess: () => {
					notify('Kanban column deleted successfully');
					setSelectedTask({});
					refetchKanbanColumnList();
					setIsDeleteModalShown(false);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify(
							'Unable to delete, Please remove all associated data from board before deletion',
							'warning'
						);
						setIsDeleteModalShown(false);
						return;
					}
					notify(error?.message, 'warning');
				},
			}
		);
	};
	const onKanbanBoardTypeChange = (id: string | undefined) => {
		const currentType = kanbanBoardList?.find((type: Type) => type?.id === id);
		setKanbanBoardId(currentType?.id);
		setSelectedType(currentType);
		setIsActive(currentType?.is_active);
		setIsDefault(currentType?.is_default);
		setKanbanTemplateName(currentType?.name.trim());
	};
	const onUpdateTemplateName = () => {
		if (kanbanTemplateName.trim() === '') {
			return 'Required';
		}
		mutate(
			{
				type: 'update',
				resource: 'kanban_board',
				payload: {
					id: selectedType?.id,
					data: {
						name: kanbanTemplateName.trim(),
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Template name updated successfully');
					refetchKanbanColumnList();
					setIsTypeNameEdit(false);
					refetchKanbanBoardList();
					setSelectedType(data);
				},
				onFailure: (error: any) => {
					notify(`Updating failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const onUpdateTask = () => {
		if (!columns) {
			return;
		}
		Promise.all(
			columns.map((column, index) =>
				updateKanbanItems({
					variables: {
						board_id: column?.id,
						data: {
							name: column?.name,
							ordinal: index + 1,
						},
					},
				})
			)
		)
			.then((response) => {
				if (response) {
					notify('Kanban order updated successfully');
					setIsColumnReOrdered(false);
					refetchKanbanColumnList();
				}
			})
			.catch((error) => {
				return;
			});
	};
	const onSubModuleChange = (subModuleId: string) => {
		const currentSubModule = masterSubModuleList?.filter(
			(subModule: any) => subModule?.id === subModuleId
		);
		setSelectedSubModuleDetails(head(currentSubModule));
	};
	const onStatusChange = (status: boolean) => {
		mutate(
			{
				type: 'update',
				resource: 'kanban_board',
				payload: {
					id: selectedType?.id,
					data: {
						is_active: status,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Status changed successfully');
				},
				onFailure: (error) => {
					notify(`failed: ${error.message}`, 'warning');
				},
			}
		);
	};
	const onDefaultChange = () => {
		updateDefaultKanbanTemplate({
			variables: {
				object: [
					{ id: selectedType?.id, name: selectedType?.name, is_default: true },
					{
						id: defaultKanbanDetails?.id,
						name: defaultKanbanDetails?.name,
						is_default: false,
					},
				],
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Default kanban template changed successfully');
					refetchKanbanBoardList();
					setWarningModalOpen(false);
					setIsDefault(true);
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};
	const optionRenderer = (choice: any) => {
		return (
			<Box display='flex' justifyContent='center' alignItems='center'>
				{choice?.id !== '@@ra-create' && (
					<StarIcon
						className={
							choice?.is_default === true
								? customKanbanSettingsStyle.defaultColor
								: customKanbanSettingsStyle.nonDefaultColor
						}
					/>
				)}

				<Tooltip title={`${choice.name}`} placement='right'>
					<Typography
						className={`${customKanbanSettingsStyle?.input} ${customKanbanSettingsStyle?.ellipsis} `}
					>
						{choice.name}
					</Typography>
				</Tooltip>
			</Box>
		);
	};
	const onUpdatePrimaryUser = () => {
		if (!primaryUserId) {
			setPrimaryUserError('Primary User should not be empty');
			return;
		}
		if (
			opportunityCount?.opportunity_kanban_card_aggregate?.aggregate?.count &&
			primaryUserId !== primaryUser?.kanban_board_invited_users[0]?.user?.id
		) {
			setKanbanTemplateNameError(
				'Opportunity Cards are assigned to the user please reassign the cards and change user'
			);
			return;
		}
		updateInviteUser({
			variables: {
				id: primaryUser?.kanban_board_invited_users[0]?.id,
				invitedUser: {
					user_id: primaryUserId,
					is_primary: true,
				},
			},
		}).then((data: any) => {
			notify('Primary User updated successfully');
			setKanbanTemplateNameError('');
			setPrimaryUserError('');
			refetchKanbanColumnList();
			setIsPrimaryUserEdit(false);
			refetchKanbanBoardList();
			refetch();
		});
	};

	//Pipeline Delete
	const [deletePipeline] = useDeletePipelineMutation();

	const onDeletePipeline = () => {
		deletePipeline({
			variables: {
				id: selectedKanbanBoard,
			},
		})
			.then((response) => {
				if (response.data) {
					setIsDeletePipelineModalShown(false);
					refresh();
					refetch();
					notify(DELETE_SUCCESS_MESSAGE);
					return;
				}
			})
			.catch((error: string) => {
				if (error) {
					notify(DELETE_FAILED_WARNING, 'warning');
				}
			});
	};

	useEffect(() => {
		if (!kanbanBoardList || !selectedKanbanBoard) {
			return;
		}
		const currentType = kanbanBoardList?.find(
			(type: Type) => type?.id === selectedKanbanBoard
		);
		setSelectedType(currentType);
	}, [kanbanBoardList, selectedKanbanBoard]);

	return (
		<>
			<SettingsDetailContainer heading='KANBAN SETTINGS'>
				<Box className={customKanbanSettingsStyle.actionContainer}>
					<Box width='fit-content'>
						<Form initialValues={formValues} onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Master Module</Box>
												<SelectInput
													source='master_module'
													label=''
													choices={moduleSelectOptions}
													onChange={(e) => {
														setSelectedModule(e.target.value);
														setFormValues({
															...formValues,
															master_module: e.target.value,
															kanban_template: '',
														});
														setSelectedKanbanBoard('');
													}}
												/>
											</Box>
											<Box className={classes.multipleInput} marginLeft='10px'>
												<Box className={classes.label}>Sub Module</Box>
												<SelectInput
													source='master_sub_module'
													label=''
													choices={subModuleSelectOptions}
													onChange={(e) => {
														setSelectedSubModule(e.target.value);
														setFormValues({
															...formValues,
															master_sub_module: e.target.value,
														});
														onSubModuleChange(e.target.value);
													}}
												/>
											</Box>
											<Box className={classes.multipleInput} marginLeft='10px'>
												<Box className={classes.label}>Kanban Template</Box>
												<SelectInput
													source='kanban_template'
													disabled={!selectedSubModule}
													label=''
													choices={[
														...kanbanBoardOptions,
														{
															id: '-- Add Template --',
															name: '-- Add Template --',
														},
													]}
													onChange={(event: any) => {
														if (!event) {
															setColumns([]);
															setSelectedKanbanBoard('');
															setSelectedType({});
															refetchKanbanBoardList();
															return;
														}
														if (event.target.value === '-- Add Template --') {
															setIsTypeFormShown(true);
															setFormValues({
																...formValues,
																kanban_template: event?.target?.value,
															});
															setSelectedKanbanBoard('');
															return;
														}
														if (event?.target?.value !== '@@ra-create') {
															setSelectedKanbanBoard(event?.target?.value);
															onKanbanBoardTypeChange(event?.target?.value);
															setFormValues({
																...formValues,
																kanban_template: event?.target?.value,
															});
														}
													}}
													resettable={true}
													optionText={optionRenderer}
												/>
											</Box>
											{kanbanTemplateName &&
												primaryUserName &&
												selectedKanbanBoard && (
													<Box
														className={classes.multipleInput}
														marginLeft='10px'
													>
														{!isPrimaryUserEdit ? (
															<>
																<Box className={classes.label}>
																	Primary User
																</Box>
																<Box display='flex' marginTop={'5px'}>
																	<Box
																		className={customKanbanSettingsStyle.label}
																	>
																		{primaryUserName}
																	</Box>
																	<EditIcon
																		className={
																			customKanbanSettingsStyle.primaryUserEditIcon
																		}
																		onClick={() => {
																			setIsPrimaryUserEdit(true);
																		}}
																	/>
																</Box>
															</>
														) : (
															<>
																<Box className={classes.label}>
																	Edit Primary User
																</Box>
																<Box display='flex'>
																	<Form
																		onSubmit={onUpdatePrimaryUser}
																		initialValues={{ primaryUserId }}
																	>
																		{() => (
																			<ThemeProvider theme={modalFormTheme}>
																				<form>
																					<Box display={'flex'}>
																						<Box display='block'>
																							<ReferenceInput
																								source='primaryUserId'
																								reference='user'
																								sort={{
																									field: 'first_name',
																									order: 'ASC',
																								}}
																								label={''}
																								filterToQuery={(
																									searchText
																								) => ({
																									first_name: searchText,
																									is_active: true,
																								})}
																								onChange={(event: any) => {
																									if (!event) {
																										setPrimaryUserId('');
																										return;
																									}
																									setPrimaryUserId(event);
																								}}
																							>
																								<AutocompleteInput
																									optionText={(user: any) =>
																										`${user?.full_name} `
																									}
																									resettable={true}
																									shouldRenderSuggestions={(
																										name: any
																									) => name?.trim().length > 0}
																								/>
																							</ReferenceInput>
																							{primaryUserError && (
																								<Box
																									className={
																										customKanbanSettingsStyle.error
																									}
																								>
																									{primaryUserError}
																								</Box>
																							)}
																							{kanbanTemplateNameError && (
																								<Box
																									className={
																										customKanbanSettingsStyle.error
																									}
																								>
																									{kanbanTemplateNameError}
																								</Box>
																							)}
																						</Box>
																						<div
																							className={
																								customKanbanSettingsStyle.iconContainer
																							}
																							onClick={onUpdatePrimaryUser}
																						>
																							<CheckIcon
																								className={
																									customKanbanSettingsStyle.icon
																								}
																							/>
																						</div>
																						<Box
																							className={
																								customKanbanSettingsStyle.iconContainer
																							}
																							onClick={() => {
																								if (!primaryUserId) {
																									setPrimaryUserError(
																										'Primary User should not be empty'
																									);
																									return;
																								}
																								setIsPrimaryUserEdit(false);
																								setPrimaryUserError('');
																								setKanbanTemplateNameError('');
																								setPrimaryUserId(
																									primaryUser
																										?.kanban_board_invited_users[0]
																										?.user?.id
																								);
																							}}
																						>
																							<CloseIcon
																								className={
																									customKanbanSettingsStyle.icon
																								}
																							/>
																						</Box>
																					</Box>
																				</form>
																			</ThemeProvider>
																		)}
																	</Form>
																</Box>
															</>
														)}
													</Box>
												)}
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				{selectedKanbanBoard && (
					<Box>
						<Box
							display='flex'
							justifyContent={'space-between'}
							mt={2}
							marginBottom='10px'
						>
							<Box
								display={'flex'}
								marginBottom='10px'
								marginTop={'3px'}
								alignContent={'center'}
								justifyContent={'center'}
							>
								{selectedType?.name && (
									<>
										{!isTypeNameEdit ? (
											<>
												<Tooltip
													title={`${selectedType?.name}`}
													placement='right'
												>
													<Typography
														className={`${customKanbanSettingsStyle.label} ${customKanbanSettingsStyle.ellipsis}`}
													>
														{selectedType?.name}
													</Typography>
												</Tooltip>
												<EditIcon
													onClick={() => setIsTypeNameEdit(true)}
													className={customKanbanSettingsStyle.editIcon}
												/>
											</>
										) : (
											<Form onSubmit={() => {}} initialValues={selectedType}>
												{() => (
													<ThemeProvider theme={modalFormTheme}>
														<form>
															<Box display={'flex'}>
																<Box>
																	<TextInput
																		source='name'
																		label=''
																		onChange={(event: any) =>
																			setKanbanTemplateName(
																				event?.target?.value.trim()
																			)
																		}
																		validate={validateName}
																	/>
																</Box>
																<div
																	className={
																		customKanbanSettingsStyle.iconContainer
																	}
																	onClick={onUpdateTemplateName}
																>
																	<CheckIcon
																		className={customKanbanSettingsStyle.icon}
																	/>
																</div>
																<div
																	className={
																		customKanbanSettingsStyle.iconContainer
																	}
																	onClick={() => setIsTypeNameEdit(false)}
																>
																	<CloseIcon
																		className={customKanbanSettingsStyle.icon}
																	/>
																</div>
															</Box>
														</form>
													</ThemeProvider>
												)}
											</Form>
										)}
									</>
								)}
							</Box>
							<Box display='flex'>
								{selectedKanbanBoard ? (
									<>
										<Box
											fontFamily={'ManRope-semiBold'}
											fontSize={'11px'}
											marginTop={'10px'}
										>
											Mark this template as default
										</Box>
										<Switch
											color='primary'
											name='is_default'
											checked={isDefault}
											disabled={
												selectedType?.id === defaultKanbanDetails?.id ||
												selectedType?.is_active === false
											}
											onChange={(event: any) => {
												if (
													defaultKanbanDetails?.id &&
													defaultKanbanDetails?.id !== selectedType?.id
												) {
													setWarningModalOpen(true);
													return;
												}
												setIsDefault(event?.target?.checked);
											}}
										/>
										<Box
											fontFamily={'ManRope-semiBold'}
											fontSize={'11px'}
											marginTop={'10px'}
										>
											{isActive ? 'Active' : 'Inactive'}
										</Box>
										<Switch
											color='primary'
											name='is_active'
											checked={isActive}
											disabled={selectedType?.id === defaultKanbanDetails?.id}
											onChange={(event: any) => {
												setIsActive(event?.target?.checked);
												onStatusChange(event?.target?.checked);
												refetchKanbanBoardList();
												setSelectedType({ ...selectedType, is_active: true });
											}}
										/>

										<Button
											variant='contained'
											className={modalFormStyles.delButton}
											onClick={() => {
												if (isColumnReOrdered) {
													notify('Please save the column order', 'warning');
													return;
												}
												setIsTaskFormShown(true);
											}}
										>
											Add column
										</Button>
										<Button
											variant='contained'
											className={modalFormStyles.delButton}
											onClick={() => {
												setIsDeletePipelineModalShown(true);
											}}
											endIcon={<Delete />}
										>
											Delete
										</Button>
									</>
								) : (
									''
								)}
							</Box>
						</Box>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId='list'>
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{columns &&
											columns.length > 0 &&
											columns.map((task: any, index: number) => (
												<Draggable
													draggableId={task.id}
													index={index}
													key={task.id}
												>
													{(provided) => (
														<div
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															ref={provided.innerRef}
														>
															<div
																className={
																	customKanbanSettingsStyle.kanbanColumnCard
																}
																key={task.id}
															>
																<div
																	className={customKanbanSettingsStyle.content}
																>
																	<DragIndicatorIcon
																		className={
																			customKanbanSettingsStyle.dragIndicator
																		}
																	/>
																	<div
																		className={
																			customKanbanSettingsStyle.dataContaner
																		}
																	>
																		{task.name}
																	</div>
																</div>
																<PopOver
																	edit={() => {
																		if (isColumnReOrdered) {
																			notify(
																				'Please save the tasks order',
																				'warning'
																			);
																			return;
																		}
																		onEditTask(task);
																	}}
																	remove={() => {
																		if (isColumnReOrdered) {
																			notify(
																				'Please save the tasks order',
																				'warning'
																			);
																			return;
																		}
																		setSelectedTask(task);
																		setIsDeleteModalShown(true);
																	}}
																/>
															</div>
														</div>
													)}
												</Draggable>
											))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						{kanbanColumnList &&
						!kanbanColumnList.length &&
						selectedKanbanBoard ? (
							<Typography className={customKanbanSettingsStyle.noData}>
								No columns added for kanban
							</Typography>
						) : (
							''
						)}
					</Box>
				)}
				{selectedKanbanBoard ? (
					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button
							disabled={!isColumnReOrdered}
							className={
								isColumnReOrdered
									? modalFormStyles.saveButton
									: modalFormStyles.disabledButton
							}
							variant='contained'
							onClick={onUpdateTask}
						>
							Save
						</Button>
					</Box>
				) : (
					''
				)}

				<KanbanColumnForm
					open={isTaskFormShown}
					onClose={() => {
						setIsTaskFormShown(false);
						setIsTaskEdit(false);
					}}
					refetch={() => refetchKanbanColumnList()}
					isEditMutation={isTaskEdit}
					kanbanBoardId={selectedKanbanBoard}
					formValue={selectedTask}
					columnCount={kanbanColumnList?.length || 0}
					kanbanStages={kanbanStages}
				/>
				<KanbanTemplateForm
					onClose={() => setIsTypeFormShown(false)}
					open={isTypeFormShown}
					refetch={() => refetchKanbanBoardList()}
					isEditMutation={false}
					subCategoryId={selectedSubModule}
					createdType={(id) => {
						refetchKanbanBoardList();
						setFormValues({
							...formValues,
							kanban_template: id,
						});
						setSelectedKanbanBoard(id);
					}}
					setKanbanBoardId={setKanbanBoardId}
					close={() => {
						setIsTypeFormShown(false);
						setFormValues({
							...formValues,
							kanban_template: '',
						});
					}}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
						setSelectedTask({});
					}}
					onDelete={onDeleteTask}
				/>
				<KanbanWarningModal
					onConfirm={() => onDefaultChange()}
					open={warningModalOpen}
					onClose={() => setWarningModalOpen(false)}
					warningMessage={`This action will make "${selectedType?.name}" as default template`}
				/>
				<DeleteModal
					open={isDeletePipelineModalShown}
					onDelete={onDeletePipeline}
					onClose={() => setIsDeletePipelineModalShown(false)}
					heading='Delete Pipeline?'
					confirmationMessage={DELETE_WARNING_MESSAGE}
				/>
			</SettingsDetailContainer>
		</>
	);
};

export default KanbanSettings;
