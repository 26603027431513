import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	ThemeProvider,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	Typography,
	Tooltip,
	createTheme,
	TextField as TextComponent,
	Checkbox,
} from '@material-ui/core';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import { Form } from 'react-final-form';
import {
	useGetDraftTimesheetByProjectIdForSummaryReportQuery,
	useGetGeneralDraftTimesheetForSummaryReportQuery,
	useGetGeneralOvertimeForSummaryReportQuery,
	useGetGeneralTimesheetForSummaryReportQuery,
	useGetHolidaysForSummaryQuery,
	useGetOrganisationManHoursQuery,
	useGetOvertimeByProjectIdForSummaryReportQuery,
	useGetProjectResourceMappingListQuery,
	useGetTimesheetByProjectIdForSummaryReportQuery,
	useGetTimesheetDayTypeOptionsQuery,
	useGetTimesheetProjectAssignedUsersQuery,
	useGetTimesheetStatusQuery,
} from '../../../generated/graphql';
import TablePagination from '@material-ui/core/TablePagination';
import { head, orderBy, uniqBy } from 'lodash';
import {
	formatEmployeeId,
	exportToCsv,
	formatDecimal,
	getPrefix,
	isFloat,
} from '../../../Utils/string.util';
import { timesheetTypes, uuidValidator } from '../constant';
import {
	autocompleteStyle,
	dropDownEllipsisStyle,
	InsightReportTableGlobalStyles,
	modalFormStyle,
} from '../../../Layout/styles';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';
import { UserProfileContext } from '../../../App';
import { DateTimeUtil } from '../../../Utils/date-time.util';
import {
	NumberInput,
	minValue,
	TextInput,
	SelectInput,
	Loading,
} from 'react-admin';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../../ResourcePlanner/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { calculateTotalHoursFromTimesheet } from './insight.utils';
import ExportButtonWithDropDown from '../../../SharedComponents/ExportButton/ExportButtonWithDropDown';
import { exportTimesheetReportUsersOptions } from '../../../config/constant';

const filterMetricInputTheme = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				border: '1px solid #E0E0E0',
				width: '100px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '13px',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});
interface TotalHrs {
	totalHours: number | null;
	billableHours: number | null;
	nonBillableHours: number | null;
}
interface Props {
	projectId: string[];
	startDate: string;
	endDate: string;
	setTotalHours: (value: TotalHrs) => void;
}
interface Resource {
	id?: string | null | undefined;
	name?: string | null | undefined;
}

interface TimesheetListProps {
	reportTo?: string | null;
	department?: string | null;
	reportToEmail?: string | null;
	user?: string | null | undefined;
	user_type: string;
	empId: string | null | undefined;
	employeeType: string | null | undefined;
	org_location?: string | null | undefined;
	userLocationId?: string | null | undefined;
	contractors: any[];
	employee: string;
	workingHours?: string;
	billableWorkingHours?: string;
	nonBillableWorkingHours?: string;
	date?: string;
	resourceCost?: string;
	totalCost?: string | number;
	id?: string;
	userId?: string;
	timesheet?: any[];
	totalHours: number;
	currencySymbol?: string;
	cost?: string;
	timeSheetEnteredDates: string[];
	attendanceList: any[];
	workedDays?: Number;
	utilization?: Number;
	email: string | null | undefined;
	businessDays?: Number;
	billRate?: number;
	totalBillRate?: number;
	isUserActive?: boolean;
}
export const TimesheetSummaryReport = (props: Props) => {
	const { projectId, startDate, endDate, setTotalHours } = props;
	const styles = modalFormStyle();
	const commonStyle = InsightReportTableGlobalStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const ellipsis = dropDownEllipsisStyle();
	const [projectIdList, setProjectIdList] = useState<string[]>();
	const [timesheetType, setTimesheetType] = useState<string>('All');
	const [totalHourValue, setTotalHourValue] = useState<any>();
	const {
		dateFormat,
		orgId,
		isOvertimeEnabled,
		featureFlag,
		noOfLocations,
		isTrackDayTypeEnabled,
		isDraftTimesheetEnabled,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);

	const [timeSheetTableList, setTimeSheetTableList] = useState<
		TimesheetListProps[]
	>([]);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [totalHoursFilterValue, setTotalHoursFiltervalue] = useState<
		number | null
	>(null);
	const [totalHoursFilterOperator, setTotalHoursFilterOperator] = useState<
		string | null
	>(null);
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		string[]
	>([]);
	const [dayTypeOptions, setDayTypeOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);

	const [selectedDayTypeOptionValue, setSelectedDayTypeOptionValues] =
		useState<any>([]);
	const [selectedDayTypeIdList, setSelectedDayTypeIdList] = useState<string[]>(
		[]
	);
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [
		selectedTimesheetStatusOptionValue,
		setSelectedTimesheetStatusOptionValues,
	] = useState<any>([]);
	const [selectedTimesheetStatusIdList, setSelectedTimesheetStatusIdList] =
		useState<string[]>([]);


	const dropDownStyle = autocompleteStyle();

	useEffect(() => {
		if (!projectId) {
			return;
		}
		setPage(0);
		if (projectId && projectId?.length === 0) {
			setUserTeamId(null);
			setSelectedUserTeam(null);
			setSelectedResourceIdList([]);
			setSelectedResourceOptionValues([]);
		}
		setSelectedResourceIdList([]);
		setSelectedResourceOptionValues([]);
		const idList = projectId?.filter((item) => uuidValidator.test(item));
		setProjectIdList(idList);
	}, [projectId]);

	const getDayTypePayload = () => {
		if (selectedDayTypeIdList && selectedDayTypeIdList?.length > 0) {
			const selectedOptionList =
				selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length > 0
					? selectedDayTypeOptionValue?.map(
							(option: { id: string }) => option?.id
					  )
					: [];
			if (selectedOptionList?.includes('All')) {
				return {};
			} else return { _in: selectedDayTypeIdList };
		}
		return {};
	};

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const { data: holdayCount } = useGetHolidaysForSummaryQuery({
		variables: {
			filter: {
				date: {
					_gte: startDate,
					_lte: endDate,
				},
			},
		},
		fetchPolicy: 'network-only',
	});
	const { data: organizationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId,
		},
	});
	const { data: timeSheetList, loading: isTimesheetListLoading } =
		useGetTimesheetByProjectIdForSummaryReportQuery({
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					timesheet_submission: {
						timesheet_status: {
							value:
								selectedTimesheetStatusIdList &&
								selectedTimesheetStatusIdList.length > 0
									? {
											_in: selectedTimesheetStatusIdList.filter(
												(status) => status !== 'draft'
											),
									  }
									: { _in: ['approved', 'submitted'] },
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
				},
				attendanceFilters: {
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				projectId: projectIdList,
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
					employee_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
				},
			},
			fetchPolicy: 'network-only',
		});

	const { data: overtimeList } = useGetOvertimeByProjectIdForSummaryReportQuery(
		{
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					timesheet_overtime_submission: {
						timesheet_status: {
							value:
								selectedTimesheetStatusIdList &&
								selectedTimesheetStatusIdList.length > 0
									? {
											_in: selectedTimesheetStatusIdList.filter(
												(status) => status !== 'draft'
											),
									  }
									: { _in: ['approved', 'submitted'] },
						},
					},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
				},
				projectId: projectIdList,
				attendanceFilters: {
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
					employee_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
				},
			},
			skip: !isOvertimeEnabled,
			fetchPolicy: 'network-only',
		}
	);
	const { data: generalTimeSheet, loading: isGeneralTimeSheetLoading } =
		useGetGeneralTimesheetForSummaryReportQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: {
							value:
								selectedTimesheetStatusIdList &&
								selectedTimesheetStatusIdList.length > 0
									? {
											_in: selectedTimesheetStatusIdList.filter(
												(status) => status !== 'draft'
											),
									  }
									: { _in: ['approved', 'submitted'] },
						},
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				attendanceFilters: {
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
			},
			fetchPolicy: 'network-only',
		});

	const { data: generalOvertime } = useGetGeneralOvertimeForSummaryReportQuery({
		variables: {
			filters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				day_type_id: getDayTypePayload(),
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
			},
			attendanceFilters: {
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
			},
		},
		skip: !isOvertimeEnabled,
		fetchPolicy: 'network-only',
	});

	const { data: generalTaskAssigneeList } =
		useGetTimesheetProjectAssignedUsersQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: selectedTimesheetStatusIdList || ['submitted', 'approved'],
							},
						},
					},
				},
			},
			fetchPolicy: 'network-only',
		});
	const { data: projectAssignedResourceList } =
		useGetProjectResourceMappingListQuery({
			variables: {
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
				},
			},
		});

	const { data: dayTypeOptionList } = useGetTimesheetDayTypeOptionsQuery({
		fetchPolicy: 'network-only',
		skip: !isTrackDayTypeEnabled,
	});

	// For draft status
	// Query to get timesheet in draft status and timesheet which are not yet submitted
	const { data: timeSheetDraftList } =
		useGetDraftTimesheetByProjectIdForSummaryReportQuery({
			variables: {
				draftFilters: {
					project_id: { _in: projectIdList },
					timesheet_submission_id: { _is_null: true },
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
				},
				filters: {
					project_id: { _in: projectIdList },
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: selectedTimesheetStatusIdList.filter(
									(status) => status === 'draft'
								),
							},
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
				},
				attendanceFilters: {
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				projectId: projectIdList,
				projectResourceMappingFilter: {
					project_id: { _in: projectIdList },
					deleted_at: { _is_null: true },
					employee_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
				},
			},
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
			fetchPolicy: 'network-only',
		});
	// Query to get general timesheet in draft and not yet submitted status
	const { data: generalDraftTimeSheet } =
		useGetGeneralDraftTimesheetForSummaryReportQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: selectedTimesheetStatusIdList.filter(
									(status) => status !== 'draft'
								),
							},
						},
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				draftFilters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission_id: { _is_null: true },
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
				attendanceFilters: {
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
				},
			},
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
			fetchPolicy: 'network-only',
		});

	const getTotalBillRate = (
		isBillable: boolean,
		workingHours: string,
		billRate: number
	) => {
		if (!isBillable) {
			return 0;
		}

		const billableHoursAndMinutes = workingHours?.split(':');
		const billableHours = parseInt(billableHoursAndMinutes[0]);
		const billableMinutes = parseInt(billableHoursAndMinutes[1]);

		const billRateForMinute = billRate / 60;
		const totalBillRate =
			billableHours * billRate + billableMinutes * billRateForMinute;
		return totalBillRate;
	};
	useEffect(() => {
		if (!dayTypeOptionList) {
			return;
		}
		const dayTypeList = dayTypeOptionList?.timesheet_day_type;
		const sortedAssigneeList = orderBy(
			dayTypeList,
			[(type) => type.name?.toUpperCase()],
			['asc']
		);
		setDayTypeOptions(sortedAssigneeList);
	}, [dayTypeOptionList]);

	useEffect(() => {
		if (!timesheetStatus) {
			return;
		}
		const statusList = timesheetStatus?.timesheet_status.map((timesheet) => {
			return {
				id: timesheet.value,
				name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
			};
		});
		setSelectedTimesheetStatusOptionValues([
			{
				id: 'submitted',
				name: 'Pending',
			},
			{
				id: 'approved',
				name: 'approved',
			},
		]);
		setSelectedTimesheetStatusIdList(['submitted', 'approved']);
		const sortedStatusList = orderBy(
			statusList,
			[(status) => status.name?.toUpperCase()],
			['asc']
		);
		setTimesheetStatusOptions(sortedStatusList);
	}, [timesheetStatus]);

	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	useEffect(() => {
		if (!projectAssignedResourceList || !generalTaskAssigneeList) {
			return;
		}

		if (selectedUserTeam) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: { user: { id: string; full_name: string } }) => {
					return {
						id: user?.user?.id,
						name: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList = orderBy(
				useTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			setAssigneeRecommendations(sortedAssigneeList);
			return;
		}

		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const uniqueAssignees = uniqBy(
			[...(projectAssignedResources || []), ...orgTaskAssigneeList],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [
		projectAssignedResourceList,
		generalTaskAssigneeList,
		selectedUserTeam,
		usersByTeam,
	]);

	useEffect(() => {
		if (!totalHourValue) {
			return;
		}
		setTotalHours(totalHourValue);
	}, [setTotalHours, totalHourValue]);

	// Main Logic for Bill Rate Calculation
	useEffect(() => {
		if (
			!timeSheetList ||
			!generalTimeSheet ||
			!startDate ||
			!endDate ||
			!holdayCount
		) {
			return;
		}
		const allUsersWithOvertimeAndTimesheetEntry =
			isOvertimeEnabled && overtimeList
				? [...timeSheetList?.timesheet, ...overtimeList?.timesheet_overtime]
				: [...timeSheetList?.timesheet];
		const usersWithTimehsheetEntry = allUsersWithOvertimeAndTimesheetEntry?.map(
			(timesheet) => timesheet?.user?.id
		);
		const usersWithoutTimesheetEntry = timeSheetList?.projectResources
			?.filter(
				(resource) => !usersWithTimehsheetEntry.includes(resource?.employee_id)
			)
			.map((resource) => {
				const existingEmployeeId = resource?.user?.employee?.emp_id
					? resource?.user?.employee?.emp_id
					: '--';
				const existingContractorId = head(resource?.user?.contractors)
					?.contractor_id
					? head(resource?.user?.contractors)?.contractor_id
					: '--';

				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					resource?.user?.user_type === 'employee'
						? resource?.user?.employee?.emp_id
							? `${
									getPrefix(
										resource?.user?.employee?.employee_prefix?.name,
										resource?.user?.employee?.employee_prefix?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: head(resource?.user?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(resource?.user?.contractors)?.contractor_prefix?.name,
									head(resource?.user?.contractors)?.contractor_prefix?.value
								) || ''
						  }${
								resource?.user?.contractors &&
								head(resource?.user?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';

				const billRate =
					resource?.user?.project_resource_mappings_aggregate?.aggregate?.max
						?.client_rate;

				return {
					user: resource?.user?.full_name,
					isUserActive: resource?.user?.is_active,
					totalHours: '',
					resourceCost: resource?.user?.project_resource_mappings_aggregate
						?.aggregate?.max?.resource_cost
						? `${
								resource?.user?.project_resource_mappings_aggregate?.aggregate
									?.max?.resource_cost
									? resource?.project?.currency?.symbol || ''
									: ''
						  }${
								resource?.user?.project_resource_mappings_aggregate?.aggregate
									?.max?.resource_cost
									? formatDecimal(
											resource?.user?.project_resource_mappings_aggregate
												?.aggregate?.max?.resource_cost,
											1
									  )
									: '--'
						  }`
						: '',
					totalCost: '--',
					billRate: billRate
						? `${billRate ? resource?.project?.currency?.symbol || '' : ''}${
								billRate ? formatDecimal(billRate, 2) : 0
						  }`
						: 0,

					totalBillRate: 0,
					currencySymbol: '',
					userId: resource?.user?.id,
					employeeType: resource?.user?.user_type
						? resource?.user?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
					email: resource?.user?.email,
					empId: employeeId,
					reportTo:
						resource?.user?.user_type === 'employee'
							? resource?.user?.employee?.reportsTo?.full_name
							: resource?.user?.contractors
							? head(resource.user.contractors)?.reportee?.full_name
							: null,
					reportToEmail:
						resource?.user?.user_type === 'employee'
							? resource?.user?.employee?.reportsTo?.email
							: resource?.user?.contractors
							? head(resource.user.contractors)?.reportee?.email
							: null,
					department:
						resource?.user?.user_type === 'employee'
							? resource?.user?.employee?.department?.name
							: resource?.user?.contractors
							? head(resource.user.contractors)?.department?.name
							: null,
					org_location:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.org_location?.name
							: head(resource.user.contractors)?.org_location?.name || '',
					userLocationId:
						resource?.user.user_type === 'employee'
							? resource?.user.employee?.org_location?.id
							: head(resource.user.contractors)?.org_location?.id || '',
				};
			});
		const timeSheetTableData = timeSheetList?.timesheet?.map((timeSheet) => {
			const existingEmployeeId = timeSheet?.user?.employee?.emp_id
				? timeSheet?.user?.employee?.emp_id
				: '--';
			const existingContractorId = head(timeSheet?.user?.contractors)
				?.contractor_id
				? head(timeSheet?.user?.contractors)?.contractor_id
				: '--';

			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			const employeeId =
				timeSheet?.user?.user_type === 'employee'
					? timeSheet?.user?.employee?.emp_id
						? `${
								getPrefix(
									timeSheet?.user?.employee?.employee_prefix?.name,
									timeSheet?.user?.employee?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId || ''}`
						: ''
					: head(timeSheet?.user?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(timeSheet?.user?.contractors)?.contractor_prefix?.name,
								head(timeSheet?.user?.contractors)?.contractor_prefix?.value
							) || ''
					  }${
							timeSheet?.user?.contractors &&
							head(timeSheet?.user?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '';
			const billRate =
				timeSheet?.user?.project_resource_mappings_aggregate?.aggregate?.max
					?.client_rate || 0;
			return {
				id: timeSheet?.id,
				user: timeSheet?.user?.full_name,
				isUserActive: timeSheet?.user?.is_active,
				userId: timeSheet?.user.id,
				billableStatus: timeSheet?.is_billable,
				employeeId: timeSheet?.user?.employee?.id || null,
				workingHours: String(timeSheet?.working_hours).slice(0, 5),
				date: timeSheet?.date,
				email: timeSheet?.user?.email,
				employeeType: timeSheet?.user?.user_type
					? timeSheet?.user?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--',
				empId: employeeId,
				reportTo:
					timeSheet?.user?.user_type === 'employee'
						? timeSheet?.user?.employee?.reportsTo?.full_name
						: timeSheet?.user?.contractors
						? head(timeSheet.user.contractors)?.reportee?.full_name
						: null,
				reportToEmail:
					timeSheet?.user?.user_type === 'employee'
						? timeSheet?.user?.employee?.reportsTo?.email
						: timeSheet?.user?.contractors
						? head(timeSheet.user.contractors)?.reportee?.email
						: null,
				department:
					timeSheet?.user?.user_type === 'employee'
						? timeSheet?.user?.employee?.department?.name
						: timeSheet?.user?.contractors
						? head(timeSheet.user.contractors)?.department?.name
						: null,
				org_location:
					timeSheet?.user.user_type === 'employee'
						? timeSheet?.user.employee?.org_location?.name
						: head(timeSheet?.user.contractors)?.org_location?.name,
				userLocationId:
					timeSheet?.user.user_type === 'employee'
						? timeSheet?.user.employee?.org_location?.id
						: head(timeSheet.user.contractors)?.org_location?.id || '',
				resourceCost: timeSheet?.user?.project_resource_mappings_aggregate
					.aggregate?.max?.resource_cost
					? `${
							timeSheet?.user?.project_resource_mappings_aggregate.aggregate
								?.max?.resource_cost
								? timeSheet?.project?.currency?.symbol || ''
								: ''
					  }${
							isFloat(
								Number(
									timeSheet?.user?.project_resource_mappings_aggregate.aggregate
										?.max?.resource_cost
								)
							)
								? Number(
										timeSheet?.user?.project_resource_mappings_aggregate
											.aggregate?.max?.resource_cost
								  )?.toFixed(1)
								: timeSheet?.user?.project_resource_mappings_aggregate.aggregate
										?.max?.resource_cost
					  }`
					: '--',
				billRate: billRate,
				totalBillRate: billRate
					? getTotalBillRate(
							timeSheet?.is_billable,
							String(timeSheet?.working_hours).slice(0, 5),
							billRate
					  )
					: 0,
				cost:
					timeSheet?.user?.project_resource_mappings_aggregate.aggregate?.max
						?.resource_cost || '',
				totalCost: `${timeSheet?.project?.currency?.symbol || ''}${getTotalCost(
					String(timeSheet?.working_hours).slice(0, 5),
					timeSheet?.user?.project_resource_mappings_aggregate.aggregate?.max
						?.resource_cost || ''
				)}`,
				currencySymbol: timeSheet?.project?.currency?.symbol || '',
				attendanceList: timeSheet?.user?.attendance || [],
			};
		});
		//Get timesheet entries which are in draft status
		const draftStatusTimesheetEntries =
			timeSheetDraftList?.draftStatusTimesheetList
				? timeSheetDraftList?.draftStatusTimesheetList?.map((timeSheet) => {
						const existingEmployeeId = timeSheet?.user?.employee?.emp_id
							? timeSheet?.user?.employee?.emp_id
							: '--';
						const existingContractorId = head(timeSheet?.user?.contractors)
							?.contractor_id
							? head(timeSheet?.user?.contractors)?.contractor_id
							: '--';

						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.user?.user_type === 'employee'
								? timeSheet?.user?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.user?.employee?.employee_prefix?.name,
												timeSheet?.user?.employee?.employee_prefix?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: head(timeSheet?.user?.contractors)?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.user?.contractors)?.contractor_prefix
												?.name,
											head(timeSheet?.user?.contractors)?.contractor_prefix
												?.value
										) || ''
								  }${
										timeSheet?.user?.contractors &&
										head(timeSheet?.user?.contractors)?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						const billRate =
							timeSheet?.user?.project_resource_mappings_aggregate.aggregate
								?.max?.client_rate || 0;
						return {
							id: timeSheet?.id,
							user: timeSheet?.user?.full_name,
							isUserActive: timeSheet?.user?.is_active,
							userId: timeSheet?.user.id,
							billableStatus: timeSheet?.is_billable,
							employeeId: timeSheet?.user?.employee?.id || null,
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							date: timeSheet?.date,
							email: timeSheet?.user?.email,
							employeeType: timeSheet?.user?.user_type
								? timeSheet?.user?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
							empId: employeeId,
							reportTo:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.reportsTo?.full_name
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.reportee?.full_name
									: null,
							reportToEmail:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.reportsTo?.email
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.reportee?.email
									: null,
							department:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.department?.name
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.department?.name
									: null,
							org_location:
								timeSheet?.user.user_type === 'employee'
									? timeSheet?.user.employee?.org_location?.name
									: head(timeSheet?.user.contractors)?.org_location?.name,
							userLocationId:
								timeSheet?.user.user_type === 'employee'
									? timeSheet?.user.employee?.org_location?.id
									: head(timeSheet.user.contractors)?.org_location?.id || '',
							resourceCost: timeSheet?.user?.project_resource_mappings_aggregate
								.aggregate?.max?.resource_cost
								? `${timeSheet?.project?.currency?.symbol || ''} ${
										isFloat(
											Number(
												timeSheet?.user?.project_resource_mappings_aggregate
													.aggregate?.max?.resource_cost
											)
										)
											? Number(
													timeSheet?.user?.project_resource_mappings_aggregate
														.aggregate?.max?.resource_cost
											  )?.toFixed(1)
											: timeSheet?.user?.project_resource_mappings_aggregate
													.aggregate?.max?.resource_cost
								  }`
								: '--',
							cost:
								timeSheet?.user?.project_resource_mappings_aggregate.aggregate
									?.max?.resource_cost || '',
							totalCost: `${
								timeSheet?.project?.currency?.symbol || ''
							} ${getTotalCost(
								String(timeSheet?.working_hours).slice(0, 5),
								timeSheet?.user?.project_resource_mappings_aggregate.aggregate
									?.max?.resource_cost || ''
							)}`,
							billRate: billRate,
							totalBillRate: billRate
								? getTotalBillRate(
										timeSheet?.is_billable,
										String(timeSheet?.working_hours).slice(0, 5),
										billRate
								  )
								: 0,
							currencySymbol: timeSheet?.project?.currency?.symbol || '',
							attendanceList: timeSheet?.user?.attendance || [],
						};
				  })
				: [];
		//Get timesheet entries which are not yet submitted
		const unSubmittedTimesheetEntries = timeSheetDraftList?.savedTimesheetList
			? timeSheetDraftList?.savedTimesheetList?.map((timeSheet) => {
					const existingEmployeeId = timeSheet?.user?.employee?.emp_id
						? timeSheet?.user?.employee?.emp_id
						: '--';
					const existingContractorId = head(timeSheet?.user?.contractors)
						?.contractor_id
						? head(timeSheet?.user?.contractors)?.contractor_id
						: '--';

					const formattedContractorId = formatEmployeeId(
						existingContractorId,
						empIdLength,
						isFixedEmpIdLengthEnabled
					);

					const formattedEmployeeId = formatEmployeeId(
						existingEmployeeId,
						empIdLength,
						isFixedEmpIdLengthEnabled
					);

					const employeeId =
						timeSheet?.user?.user_type === 'employee'
							? timeSheet?.user?.employee?.emp_id
								? `${
										getPrefix(
											timeSheet?.user?.employee?.employee_prefix?.name,
											timeSheet?.user?.employee?.employee_prefix?.value
										) || ''
								  }${formattedEmployeeId || ''}`
								: ''
							: head(timeSheet?.user?.contractors)?.contractor_id
							? `${
									getPrefix(
										head(timeSheet?.user?.contractors)?.contractor_prefix?.name,
										head(timeSheet?.user?.contractors)?.contractor_prefix?.value
									) || ''
							  }${
									timeSheet?.user?.contractors &&
									head(timeSheet?.user?.contractors)?.contractor_id
										? formattedContractorId
										: ''
							  }`
							: '';
					const billRate =
						timeSheet?.user?.project_resource_mappings_aggregate.aggregate?.max
							?.client_rate || 0;
					return {
						id: timeSheet?.id,
						user: timeSheet?.user?.full_name,
						isUserActive: timeSheet?.user?.is_active,
						userId: timeSheet?.user.id,
						billableStatus: timeSheet?.is_billable,
						employeeId: timeSheet?.user?.employee?.id || null,
						workingHours: String(timeSheet?.working_hours).slice(0, 5),
						date: timeSheet?.date,
						email: timeSheet?.user?.email,
						employeeType: timeSheet?.user?.user_type
							? timeSheet?.user?.user_type === 'employee'
								? 'Employee'
								: 'Consultant'
							: '--',
						empId: employeeId,
						reportTo:
							timeSheet?.user?.user_type === 'employee'
								? timeSheet?.user?.employee?.reportsTo?.full_name
								: timeSheet?.user?.contractors
								? head(timeSheet.user.contractors)?.reportee?.full_name
								: null,
						reportToEmail:
							timeSheet?.user?.user_type === 'employee'
								? timeSheet?.user?.employee?.reportsTo?.email
								: timeSheet?.user?.contractors
								? head(timeSheet.user.contractors)?.reportee?.email
								: null,
						department:
							timeSheet?.user?.user_type === 'employee'
								? timeSheet?.user?.employee?.department?.name
								: timeSheet?.user?.contractors
								? head(timeSheet.user.contractors)?.department?.name
								: null,

						org_location:
							timeSheet?.user.user_type === 'employee'
								? timeSheet?.user.employee?.org_location?.name
								: head(timeSheet?.user.contractors)?.org_location?.name,
						userLocationId:
							timeSheet?.user.user_type === 'employee'
								? timeSheet?.user.employee?.org_location?.id
								: head(timeSheet.user.contractors)?.org_location?.id || '',
						resourceCost: timeSheet?.user?.project_resource_mappings_aggregate
							.aggregate?.max?.resource_cost
							? `${timeSheet?.project?.currency?.symbol || ''} ${
									isFloat(
										Number(
											timeSheet?.user?.project_resource_mappings_aggregate
												.aggregate?.max?.resource_cost
										)
									)
										? Number(
												timeSheet?.user?.project_resource_mappings_aggregate
													.aggregate?.max?.resource_cost
										  )?.toFixed(1)
										: timeSheet?.user?.project_resource_mappings_aggregate
												.aggregate?.max?.resource_cost
							  }`
							: '--',
						cost:
							timeSheet?.user?.project_resource_mappings_aggregate.aggregate
								?.max?.resource_cost || '',
						totalCost: `${
							timeSheet?.project?.currency?.symbol || ''
						} ${getTotalCost(
							String(timeSheet?.working_hours).slice(0, 5),
							timeSheet?.user?.project_resource_mappings_aggregate.aggregate
								?.max?.resource_cost || ''
						)}`,
						billRate: billRate,
						totalBillRate: billRate
							? getTotalBillRate(
									timeSheet?.is_billable,
									String(timeSheet?.working_hours).slice(0, 5),
									billRate
							  )
							: 0,
						currencySymbol: timeSheet?.project?.currency?.symbol || '',
						attendanceList: timeSheet?.user?.attendance || [],
					};
			  })
			: [];
		const overtimeData =
			isOvertimeEnabled && overtimeList
				? overtimeList?.timesheet_overtime?.map((timeSheet) => {
						const existingEmployeeId = timeSheet?.user?.employee?.emp_id
							? timeSheet?.user?.employee?.emp_id
							: '--';
						const existingContractorId = head(timeSheet?.user?.contractors)
							?.contractor_id
							? head(timeSheet?.user?.contractors)?.contractor_id
							: '--';

						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.user?.user_type === 'employee'
								? timeSheet?.user?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.user?.employee?.employee_prefix?.name,
												timeSheet?.user?.employee?.employee_prefix?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: head(timeSheet?.user?.contractors)?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.user?.contractors)?.contractor_prefix
												?.name,
											head(timeSheet?.user?.contractors)?.contractor_prefix
												?.value
										) || ''
								  }${
										timeSheet?.user?.contractors &&
										head(timeSheet?.user?.contractors)?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						const billRate =
							timeSheet?.user?.project_resource_mappings_aggregate.aggregate
								?.max?.client_rate || 0;
						return {
							id: timeSheet.id,
							user: timeSheet.user?.full_name,
							isUserActive: timeSheet?.user?.is_active,
							userId: timeSheet.user.id,
							employeeId: timeSheet.user?.employee?.id || null,
							billableStatus: timeSheet?.is_billable,
							email: timeSheet?.user?.email,
							employeeType: timeSheet?.user?.user_type
								? timeSheet?.user?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
							empId: employeeId,
							reportTo:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.reportsTo?.full_name
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.reportee?.full_name
									: null,
							reportToEmail:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.reportsTo?.email
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.reportee?.email
									: null,
							department:
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.department?.name
									: timeSheet?.user?.contractors
									? head(timeSheet.user.contractors)?.department?.name
									: null,
							org_location:
								timeSheet?.user.user_type === 'employee'
									? timeSheet?.user.employee?.org_location?.name
									: head(timeSheet?.user.contractors)?.org_location?.name,
							userLocationId:
								timeSheet?.user.user_type === 'employee'
									? timeSheet?.user.employee?.org_location?.id
									: head(timeSheet.user.contractors)?.org_location?.id || '',
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							date: timeSheet?.date,
							resourceCost: timeSheet?.user?.project_resource_mappings_aggregate
								.aggregate?.max?.resource_cost
								? `${
										timeSheet?.user?.project_resource_mappings_aggregate
											.aggregate?.max?.resource_cost
											? timeSheet?.project?.currency?.symbol || ''
											: ''
								  }${
										isFloat(
											timeSheet?.user?.project_resource_mappings_aggregate
												.aggregate?.max?.resource_cost
										)
											? formatDecimal(
													timeSheet?.user?.project_resource_mappings_aggregate
														.aggregate?.max?.resource_cost,
													1
											  )
											: timeSheet?.user?.project_resource_mappings_aggregate
													.aggregate?.max?.resource_cost || ''
								  }`
								: '',
							cost:
								timeSheet?.user?.project_resource_mappings_aggregate.aggregate
									?.max?.resource_cost || '',
							totalCost: `${
								timeSheet?.project?.currency?.symbol || ''
							}${getTotalCost(
								String(timeSheet?.working_hours).slice(0, 5),
								timeSheet?.user?.project_resource_mappings_aggregate.aggregate
									?.max?.resource_cost || ''
							)}`,
							billRate: billRate,
							totalBillRate: billRate
								? getTotalBillRate(
										timeSheet?.is_billable || false,
										String(timeSheet?.working_hours).slice(0, 5),
										billRate
								  )
								: 0,
							currencySymbol: timeSheet?.project?.currency?.symbol || '',
							attendanceList: timeSheet?.user?.attendance || [],
						};
				  })
				: [];
		const generalTimesheetData = generalTimeSheet?.org_tasks.map((task) => {
			const timeSheetData = task?.timesheets?.map((timeSheet) => {
				const existingEmployeeId = timeSheet?.userByEmployeeId?.employee?.emp_id
					? timeSheet?.userByEmployeeId?.employee?.emp_id
					: '--';
				const existingContractorId = head(
					timeSheet?.userByEmployeeId?.contractors
				)?.contractor_id
					? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
					: '--';

				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.emp_id
							? `${
									getPrefix(
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.name,
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.name,
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.value
								) || ''
						  }${
								timeSheet?.userByEmployeeId?.contractors &&
								head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';
				return {
					id: timeSheet?.id,
					user: timeSheet?.userByEmployeeId?.full_name,
					isUserActive: timeSheet?.userByEmployeeId?.is_active,
					userId: timeSheet?.userByEmployeeId.id,
					date: timeSheet?.date,
					email: timeSheet?.userByEmployeeId?.email,
					employeeType: timeSheet?.userByEmployeeId?.user_type
						? timeSheet?.userByEmployeeId?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
					empId: employeeId,
					reportTo:
						timeSheet?.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.reportsTo?.full_name
							: timeSheet?.userByEmployeeId.contractors
							? head(timeSheet.userByEmployeeId.contractors)?.reportee
									?.full_name
							: null,
					reportToEmail:
						timeSheet?.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.reportsTo?.email
							: timeSheet?.userByEmployeeId.contractors
							? head(timeSheet.userByEmployeeId.contractors)?.reportee?.email
							: null,
					department:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.department?.name
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet?.userByEmployeeId.contractors)?.department?.name
							: null,

					org_location:
						timeSheet.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.org_location?.name
							: head(timeSheet?.userByEmployeeId.contractors)?.org_location
									?.name,
					userLocationId:
						timeSheet?.userByEmployeeId.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.org_location?.id
							: head(timeSheet.userByEmployeeId.contractors)?.org_location
									?.id || '',
					billableStatus: timeSheet?.is_billable,
					employeeId: timeSheet.userByEmployeeId?.employee?.id || null,
					workingHours: String(timeSheet?.working_hours).slice(0, 5),
					resourceCost: `--`,
					cost: 0,
					totalCost: ``,
					billRate: 0,
					totalBillRate: 0,
					currencySymbol:
						head(timeSheet?.userByEmployeeId?.project_resource_mappings)
							?.project?.currency?.symbol || '',
					attendanceList: timeSheet?.userByEmployeeId?.attendance || [],
				};
			});
			return timeSheetData;
		});
		//Get general timesheet entries which are noy yet submitted
		const generalUnSubmittedTimesheetEntries =
			generalDraftTimeSheet?.savedTimesheets
				? generalDraftTimeSheet?.savedTimesheets.map((task) => {
						const generalUnSubmittedEntries = task?.timesheets
							? task?.timesheets?.map((timeSheet) => {
									const existingEmployeeId = timeSheet?.userByEmployeeId
										?.employee?.emp_id
										? timeSheet?.userByEmployeeId?.employee?.emp_id
										: '--';
									const existingContractorId = head(
										timeSheet?.userByEmployeeId?.contractors
									)?.contractor_id
										? head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
										: '--';

									const formattedContractorId = formatEmployeeId(
										existingContractorId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const formattedEmployeeId = formatEmployeeId(
										existingEmployeeId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const employeeId =
										timeSheet?.userByEmployeeId?.user_type === 'employee'
											? timeSheet?.userByEmployeeId?.employee?.emp_id
												? `${
														getPrefix(
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.name,
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.value
														) || ''
												  }${formattedEmployeeId || ''}`
												: ''
											: head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											? `${
													getPrefix(
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.name,
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.value
													) || ''
											  }${
													timeSheet?.userByEmployeeId?.contractors &&
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
														? formattedContractorId
														: ''
											  }`
											: '';
									return {
										id: timeSheet?.id,
										user: timeSheet?.userByEmployeeId?.full_name,
										isUserActive: timeSheet?.userByEmployeeId?.is_active,
										userId: timeSheet?.userByEmployeeId.id,
										date: timeSheet?.date,
										email: timeSheet?.userByEmployeeId?.email,
										employeeType: timeSheet?.userByEmployeeId?.user_type
											? timeSheet?.userByEmployeeId?.user_type === 'employee'
												? 'Employee'
												: 'Consultant'
											: '--',
										empId: employeeId,
										reportTo:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.reportsTo
														?.full_name
												: timeSheet?.userByEmployeeId.contractors
												? head(timeSheet.userByEmployeeId.contractors)?.reportee
														?.full_name
												: null,
										reportToEmail:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.reportsTo?.email
												: timeSheet?.userByEmployeeId.contractors
												? head(timeSheet.userByEmployeeId.contractors)?.reportee
														?.email
												: null,
										department:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.department
														?.name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId.contractors)
														?.department?.name
												: null,
										org_location:
											timeSheet.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location
														?.name
												: head(timeSheet?.userByEmployeeId.contractors)
														?.org_location?.name,
										userLocationId:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location?.id
												: head(timeSheet.userByEmployeeId.contractors)
														?.org_location?.id || '',
										billableStatus: timeSheet?.is_billable,
										employeeId:
											timeSheet.userByEmployeeId?.employee?.id || null,
										workingHours: String(timeSheet?.working_hours).slice(0, 5),
										resourceCost: `--`,
										cost: 0,
										totalCost: ``,
										billRate: 0,
										totalBillRate: 0,
										currencySymbol:
											head(
												timeSheet?.userByEmployeeId?.project_resource_mappings
											)?.project?.currency?.symbol || '',
										attendanceList:
											timeSheet?.userByEmployeeId?.attendance || [],
									};
							  })
							: [];
						return generalUnSubmittedEntries;
				  })
				: [];
		//Get general timesheet entries which are in draft status
		const generalDraftTimesheetEntries =
			generalDraftTimeSheet?.draftStatusTimesheets
				? generalDraftTimeSheet?.draftStatusTimesheets.map((task) => {
						const generalDraftEntries = task?.timesheets
							? task?.timesheets?.map((timeSheet) => {
									const existingEmployeeId = timeSheet?.userByEmployeeId
										?.employee?.emp_id
										? timeSheet?.userByEmployeeId?.employee?.emp_id
										: '--';
									const existingContractorId = head(
										timeSheet?.userByEmployeeId?.contractors
									)?.contractor_id
										? head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
										: '--';

									const formattedContractorId = formatEmployeeId(
										existingContractorId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const formattedEmployeeId = formatEmployeeId(
										existingEmployeeId,
										empIdLength,
										isFixedEmpIdLengthEnabled
									);

									const employeeId =
										timeSheet?.userByEmployeeId?.user_type === 'employee'
											? timeSheet?.userByEmployeeId?.employee?.emp_id
												? `${
														getPrefix(
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.name,
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.value
														) || ''
												  }${formattedEmployeeId || ''}`
												: ''
											: head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											? `${
													getPrefix(
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.name,
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.value
													) || ''
											  }${
													timeSheet?.userByEmployeeId?.contractors &&
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
														? formattedContractorId
														: ''
											  }`
											: '';
									return {
										id: timeSheet?.id,
										user: timeSheet?.userByEmployeeId?.full_name,
										isUserActive: timeSheet?.userByEmployeeId?.is_active,
										userId: timeSheet?.userByEmployeeId.id,
										date: timeSheet?.date,
										email: timeSheet?.userByEmployeeId?.email,
										employeeType: timeSheet?.userByEmployeeId?.user_type
											? timeSheet?.userByEmployeeId?.user_type === 'employee'
												? 'Employee'
												: 'Consultant'
											: '--',
										empId: employeeId,
										reportTo:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.reportsTo
														?.full_name
												: timeSheet?.userByEmployeeId.contractors
												? head(timeSheet.userByEmployeeId.contractors)?.reportee
														?.full_name
												: null,
										reportToEmail:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.reportsTo?.email
												: timeSheet?.userByEmployeeId.contractors
												? head(timeSheet.userByEmployeeId.contractors)?.reportee
														?.email
												: null,
										department:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.department
														?.name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId.contractors)
														?.department?.name
												: null,
										org_location:
											timeSheet.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location
														?.name
												: head(timeSheet?.userByEmployeeId.contractors)
														?.org_location?.name,
										userLocationId:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location?.id
												: head(timeSheet.userByEmployeeId.contractors)
														?.org_location?.id || '',
										billableStatus: timeSheet?.is_billable,
										employeeId:
											timeSheet.userByEmployeeId?.employee?.id || null,
										workingHours: String(timeSheet?.working_hours).slice(0, 5),
										resourceCost: `--`,
										cost: 0,
										totalCost: ``,
										billRate: 0,
										totalBillRate: 0,
										currencySymbol:
											head(
												timeSheet?.userByEmployeeId?.project_resource_mappings
											)?.project?.currency?.symbol || '',
										attendanceList:
											timeSheet?.userByEmployeeId?.attendance || [],
									};
							  })
							: [];
						return generalDraftEntries;
				  })
				: [];
		const generalOvertimeData =
			isOvertimeEnabled && generalOvertime
				? generalOvertime?.org_tasks.map((task) => {
						const timeSheetData = task?.timesheet_overtime?.map((timeSheet) => {
							const existingEmployeeId = timeSheet?.userByEmployeeId?.employee
								?.emp_id
								? timeSheet?.userByEmployeeId?.employee?.emp_id
								: '--';
							const existingContractorId = head(
								timeSheet?.userByEmployeeId?.contractors
							)?.contractor_id
								? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.name,
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.name,
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.value
											) || ''
									  }${
											timeSheet?.userByEmployeeId?.contractors &&
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet.id,
								user: timeSheet.userByEmployeeId?.full_name,
								isUserActive: timeSheet?.userByEmployeeId?.is_active,
								userId: timeSheet.userByEmployeeId.id,
								date: timeSheet?.date,
								billableStatus: timeSheet?.is_billable,
								email: timeSheet?.userByEmployeeId?.email,
								employeeType: timeSheet?.userByEmployeeId?.user_type
									? timeSheet?.userByEmployeeId?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.userByEmployeeId.user_type === 'employee'
										? timeSheet?.userByEmployeeId.employee?.reportsTo?.full_name
										: timeSheet?.userByEmployeeId.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.full_name
										: null,
								reportToEmail:
									timeSheet?.userByEmployeeId.user_type === 'employee'
										? timeSheet?.userByEmployeeId.employee?.reportsTo?.email
										: timeSheet?.userByEmployeeId.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.email
										: null,
								department:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.department?.name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet?.userByEmployeeId.contractors)?.department
												?.name
										: null,

								org_location:
									timeSheet?.userByEmployeeId.user_type === 'employee'
										? timeSheet?.userByEmployeeId.employee?.org_location?.name
										: head(timeSheet?.userByEmployeeId.contractors)
												?.org_location?.name,
								userLocationId:
									timeSheet?.userByEmployeeId.user_type === 'employee'
										? timeSheet?.userByEmployeeId.employee?.org_location?.id
										: head(timeSheet.userByEmployeeId.contractors)?.org_location
												?.id || '',
								employeeId: timeSheet.userByEmployeeId?.employee?.id || null,
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								resourceCost: `--`,
								cost: 0,
								totalCost: `--`,
								billRate: 0,
								totalBillRate: 0,
								currencySymbol:
									head(timeSheet?.userByEmployeeId?.project_resource_mappings)
										?.project?.currency?.symbol || '',
								attendanceList: timeSheet?.userByEmployeeId?.attendance || [],
							};
						});
						return timeSheetData;
				  })
				: [];
		// Combine all timesheet with unique check using the timesheet primary key id,
		// to avoid duplicate entries
		const finalTimesheetEntryList = uniqBy(
			isOvertimeEnabled
				? timesheetType === 'overtime'
					? [...overtimeData, ...generalOvertimeData?.flat()]
					: timesheetType === 'timesheet'
					? [
							...timeSheetTableData,
							...generalTimesheetData.flat(),
							...draftStatusTimesheetEntries,
							...unSubmittedTimesheetEntries,
							...generalDraftTimesheetEntries.flat(),
							...generalUnSubmittedTimesheetEntries.flat(),
					  ]
					: [
							...timeSheetTableData,
							...generalTimesheetData.flat(),
							...overtimeData,
							...generalOvertimeData?.flat(),
							...draftStatusTimesheetEntries,
							...unSubmittedTimesheetEntries,
							...generalDraftTimesheetEntries.flat(),
							...generalUnSubmittedTimesheetEntries.flat(),
					  ]
				: [
						...timeSheetTableData,
						...generalTimesheetData.flat(),
						...draftStatusTimesheetEntries,
						...unSubmittedTimesheetEntries,
						...generalDraftTimesheetEntries.flat(),
						...generalUnSubmittedTimesheetEntries.flat(),
				  ],
			'id'
		);

		const tableData: any[] = _.chain(finalTimesheetEntryList)
			.groupBy((item) => `${item.userId}`)
			.map((timesheetEntry) => {
				const timeSheetEnteredDates = timesheetEntry?.map(
					(entry) => entry?.date
				);
				const billableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === true &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				const nonBillableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === false &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				const totalWorkingHours: Duration = timesheetEntry
					.map((timeSheet) => timeSheet?.workingHours?.split(':'))
					.map((hoursAndMinutes: string[]) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);

				// Sum of all bill rates
				const sumAllBillRate = timesheetEntry?.reduce(
					(initialValue, timesheet) =>
						initialValue + Number(timesheet?.totalBillRate),
					0
				);
				let maxBillRate = 0;
				timesheetEntry?.forEach((timesheet) => {
					const billRate = Number(timesheet?.billRate);
					if (billRate > maxBillRate) {
						maxBillRate = billRate;
					}
				});
				return {
					workingHours: String(totalWorkingHours?.asHours()),
					billableWorkingHours: String(billableHours?.asHours()),
					nonBillableWorkingHours: String(nonBillableHours?.asHours()),
					id: head(timesheetEntry)?.id,
					employeeId: head(timesheetEntry)?.employeeId,
					isUserActive: head(timesheetEntry)?.isUserActive,
					user: head(timesheetEntry)?.user,
					userId: head(timesheetEntry)?.userId,
					date: head(timesheetEntry)?.date,
					resourceCost: head(timesheetEntry)?.resourceCost || '',
					cost: head(timesheetEntry)?.cost || '',
					billRate: maxBillRate || 0,
					totalBillRate: sumAllBillRate || 0,
					currencySymbol: head(timesheetEntry)?.currencySymbol || '',
					attendanceList: head(timesheetEntry)?.attendanceList || [],
					timeSheetEnteredDates: timeSheetEnteredDates || [],
					org_location: head(timesheetEntry)?.org_location || '',
					userLocationId: head(timesheetEntry)?.userLocationId || '',
					empId: head(timesheetEntry)?.empId || '',
					employeeType: head(timesheetEntry)?.employeeType || '',
					reportTo: head(timesheetEntry)?.reportTo || '',
					reportToEmail: head(timesheetEntry)?.reportToEmail || '',
					department: head(timesheetEntry)?.department || '',
					email: head(timesheetEntry)?.email || '',
				};
			})
			.groupBy((item) => item.userId)
			.map((item: TimesheetListProps[]) => {
				const totalHours = item.reduce(function (acc, obj) {
					return acc + Number(obj.workingHours);
				}, 0);
				const attendnaceEnteredDates =
					head(item)?.attendanceList?.map((attendance) => attendance?.date) ||
					[];
				const workedDays = _.uniq([
					...attendnaceEnteredDates,
					...(head(item)?.timeSheetEnteredDates || []),
				]).length;
				const billableHours = Number(head(item)?.billableWorkingHours);

				// FIND Number of working days and utilization
				const holidayListWithoutWeekends = holdayCount?.org_holidays?.filter(
					(holiday) =>
						dayjs(holiday?.date).get('day') !== 0 &&
						dayjs(holiday?.date).get('day') !== 6 &&
						holiday.is_restricted !== true
				);
				// Consider all holidays as holidays If there is no location created in the system
				const holidaysWithoutWeekendsAndDuplicates = uniqBy(
					holidayListWithoutWeekends,
					function (e) {
						return e.date;
					}
				);

				const buisnessDays = DateTimeUtil.getBusinessDaysBetween(
					dayjs(startDate),
					dayjs(endDate)
				);

				const userLocationHolidays = holidayListWithoutWeekends.filter(
					(hol) => hol.org_location?.id === head(item)?.userLocationId
				);

				// Taken user location based holidays and make it unique by date field
				const userLocationHolidaysWithoutDuplicate = uniqBy(
					userLocationHolidays,
					function (e) {
						return e.date;
					}
				);

				const noOfBusinessDays =
					noOfLocations > 0
						? buisnessDays - userLocationHolidaysWithoutDuplicate.length
						: buisnessDays - holidaysWithoutWeekendsAndDuplicates.length || 0;

				const utilization =
					billableHours && noOfBusinessDays
						? (billableHours /
								(noOfBusinessDays *
									organizationManHours?.organization_by_pk?.working_hours)) *
						  100
						: '';
				return {
					id: head(item)?.id,
					user: head(item)?.user,
					isUserActive: head(item)?.isUserActive,
					totalHours: isFloat(totalHours)
						? formatDecimal(totalHours, 1)
						: Number(totalHours),
					billableWorkingHours: formatDecimal(
						head(item)?.billableWorkingHours,
						1
					),
					nonBillableWorkingHours: formatDecimal(
						head(item)?.nonBillableWorkingHours,
						1
					),
					resourceCost: head(item)?.resourceCost
						? head(item)?.resourceCost
						: '',
					totalCost: head(item)?.cost
						? formatDecimal(totalHours * Number(head(item)?.cost), 1)
						: '',
					billRate: head(item)?.billRate || 0,
					totalBillRate: head(item)?.totalBillRate || 0,
					currencySymbol: head(item)?.currencySymbol || '',
					workedDays: workedDays,
					businessDays: noOfBusinessDays,
					userId: head(item)?.userId,
					utilization: utilization,
					org_location: head(item)?.org_location
						? head(item)?.org_location
						: '',
					empId: head(item)?.empId ? head(item)?.empId : '',
					employeeType: head(item)?.employeeType || '',
					email: head(item)?.email || '',
					reportTo: head(item)?.reportTo || '',
					reportToEmail: head(item)?.reportToEmail || '',
					department: head(item)?.department || '',
				};
			})
			.value();

		const usersWithTimeSheetIdList = tableData?.map((user) => user?.userId);
		const userListWIthoutTimeSheetEntries = usersWithoutTimesheetEntry?.filter(
			(user) => !usersWithTimeSheetIdList?.includes(user?.userId)
		);
		if (totalHoursFilterOperator && totalHoursFilterValue) {
			if (totalHoursFilterValue && totalHoursFilterOperator === '<=') {
				const totalHoursFilterIdList = tableData
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) <= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);
				setTotalHourValue(totalHour);
				return;
			}
			if (totalHoursFilterValue && totalHoursFilterOperator === '>=') {
				const totalHoursFilterIdList = tableData
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) >= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);
				setTotalHourValue(totalHour);
				return;
			}
		}
		totalHoursFilterOperator && totalHoursFilterValue
			? setTimeSheetTableList(
					orderBy([...tableData], [(user) => user.user?.toUpperCase()], ['asc'])
			  )
			: setTimeSheetTableList(
					orderBy(
						[...tableData, ...userListWIthoutTimeSheetEntries],
						[(user) => user.user?.toUpperCase()],
						['asc']
					)
			  );
		const totalHour = calculateTotalHoursFromTimesheet(tableData);
		setTotalHourValue(totalHour);
	}, [
		generalDraftTimeSheet,
		generalOvertime,
		generalTimeSheet,
		isDraftTimesheetEnabled,
		isOvertimeEnabled,
		organizationManHours,
		overtimeList,
		timeSheetDraftList,
		timeSheetList,
		timesheetType,
		totalHoursFilterOperator,
		totalHoursFilterValue,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		endDate,
		holdayCount,
		startDate,
		noOfLocations,
	]);
	const getTotalCost = (hours: string, cost: number) => {
		const hour = hours.split(':');
		const totalHours = parseInt(hour[0]) * cost;
		return totalHours;
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = (exportMenuItemIndex: number = 0) => {
		if (timeSheetTableList?.length === 0) {
			return;
		}
		const timesheetTableRows = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'LOCATION',
			'REPORTING TO',
			'REPORTING TO EMAIL',
			'DEPARTMENT',
			'START DATE',
			'END DATE',
			'BUSINESS DAYS',
			'DAYS WORKED',
			'TOTAL HOURS',
			'BILLABLE HOURS',
			'NON BILLABLE HOURS',
			'UTILIZATION',
			'RESOURCE COST',
			'TOTAL COST',
			'BILL RATE',
			'TOTAL BILL RATE',
		];
		const timesheetTableRowsWithoutLocation = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'REPORTING TO',
			'REPORTING TO EMAIL',
			'DEPARTMENT',
			'START DATE',
			'END DATE',
			'BUSINESS DAYS',
			'DAYS WORKED',
			'TOTAL HOURS',
			'BILLABLE HOURS',
			'NON BILLABLE HOURS',
			'UTILIZATION',
			'RESOURCE COST',
			'TOTAL COST',
			'BILL RATE',
			'TOTAL BILL RATE',
		];
		const filteredTimeSheetTableListForExport = timeSheetTableList?.filter(
			(timeSheet) => {
				// Export All Users Data
				if (exportMenuItemIndex === 0) {
					return true;
				}

				// If Index is 1, then Export Active Users only otherwise Inactive Users
				return exportMenuItemIndex === 1
					? timeSheet?.isUserActive
					: !timeSheet?.isUserActive;
			}
		);
		const timesheetRows = filteredTimeSheetTableListForExport?.map(
			(timeSheet) => {
				return noOfLocations && noOfLocations > 0
					? [
							timeSheet?.empId || '--',
							timeSheet?.user,
							timeSheet?.email || '',
							timeSheet?.employeeType,
							timeSheet?.org_location || '--',
							timeSheet?.reportTo || '--',
							timeSheet?.reportToEmail || '--',
							timeSheet?.department || '--',
							dayjs(startDate).format(dateFormat),
							dayjs(endDate).format(dateFormat),
							timeSheet?.businessDays || 0,
							timeSheet?.workedDays || 0,
							timeSheet.totalHours
								? isFloat(Number(timeSheet?.totalHours))
									? formatDecimal(timeSheet?.totalHours, 1)
									: timeSheet?.totalHours
								: 0,
							timeSheet?.billableWorkingHours
								? isFloat(Number(timeSheet?.billableWorkingHours))
									? formatDecimal(timeSheet?.billableWorkingHours, 1)
									: timeSheet?.billableWorkingHours
								: 0,
							timeSheet?.nonBillableWorkingHours
								? isFloat(Number(timeSheet?.nonBillableWorkingHours))
									? formatDecimal(timeSheet?.nonBillableWorkingHours, 1)
									: timeSheet?.nonBillableWorkingHours
								: 0,
							timeSheet?.utilization
								? isFloat(Number(timeSheet?.utilization))
									? `${formatDecimal(timeSheet?.utilization, 1)}%`
									: `${timeSheet?.utilization}%`
								: 0,
							timeSheet?.resourceCost || '--',
							timeSheet?.totalCost
								? `${timeSheet?.currencySymbol}${
										isFloat(Number(timeSheet?.totalCost))
											? formatDecimal(timeSheet?.totalCost, 1)
											: timeSheet?.totalCost
								  }`
								: '--',
							isNaN(Number(timeSheet?.billRate))
								? timeSheet?.billRate
								: `${
										timeSheet?.billRate ? timeSheet?.currencySymbol || '' : ''
								  } ${
										timeSheet?.billRate
											? formatDecimal(timeSheet?.billRate, 2)
											: 0
								  }`,
							`${
								timeSheet?.totalBillRate ? timeSheet?.currencySymbol || '' : ''
							}${
								timeSheet?.totalBillRate
									? formatDecimal(timeSheet?.totalBillRate, 2)
									: 0
							}`,
					  ]
					: [
							timeSheet?.empId || '--',
							timeSheet?.user,
							timeSheet?.email || '',
							timeSheet?.employeeType,
							timeSheet?.reportTo || '--',
							timeSheet?.reportToEmail || '--',
							timeSheet?.department || '--',
							dayjs(startDate).format(dateFormat),
							dayjs(endDate).format(dateFormat),
							timeSheet?.businessDays || 0,
							timeSheet?.workedDays || 0,
							timeSheet.totalHours
								? isFloat(Number(timeSheet?.totalHours))
									? formatDecimal(timeSheet?.totalHours, 1)
									: timeSheet?.totalHours
								: 0,
							timeSheet?.billableWorkingHours
								? isFloat(Number(timeSheet?.billableWorkingHours))
									? formatDecimal(timeSheet?.billableWorkingHours, 1)
									: timeSheet?.billableWorkingHours
								: 0,
							timeSheet?.nonBillableWorkingHours
								? isFloat(Number(timeSheet?.nonBillableWorkingHours))
									? formatDecimal(timeSheet?.nonBillableWorkingHours, 1)
									: timeSheet?.nonBillableWorkingHours
								: 0,
							timeSheet?.utilization
								? isFloat(Number(timeSheet?.utilization))
									? `${formatDecimal(timeSheet?.utilization, 1)}%`
									: `${timeSheet?.utilization}%`
								: 0,
							timeSheet?.resourceCost || '--',
							timeSheet?.totalCost
								? `${timeSheet?.currencySymbol}${
										isFloat(Number(timeSheet?.totalCost))
											? formatDecimal(timeSheet?.totalCost, 1)
											: timeSheet?.totalCost
								  }`
								: '--',
							isNaN(Number(timeSheet?.billRate))
								? timeSheet?.billRate
								: `${
										timeSheet?.billRate ? timeSheet?.currencySymbol || '' : ''
								  } ${
										timeSheet?.billRate
											? formatDecimal(timeSheet?.billRate, 2)
											: 0
								  }`,

							`${
								timeSheet?.totalBillRate ? timeSheet?.currencySymbol || '' : ''
							}${
								timeSheet?.totalBillRate
									? formatDecimal(timeSheet?.totalBillRate, 2)
									: 0
							}`,
					  ];
			}
		);
		const timeSheetExportData = [
			noOfLocations > 0
				? timesheetTableRows
				: timesheetTableRowsWithoutLocation,
			...timesheetRows,
		];
		exportToCsv(
			`Timesheet_Summary_Report_(${
				exportTimesheetReportUsersOptions[exportMenuItemIndex]
			})_From__${dayjs(startDate).format(dateFormat)}_TO_${dayjs(
				endDate
			).format(dateFormat)}.csv`,
			timeSheetExportData
		);
	};

	const resetTeamResourceFilter = () => {
		if (!timeSheetList || !generalTaskAssigneeList) {
			return;
		}
		const projectAssignedResources = timeSheetList?.projectResources?.map(
			(resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			})
		);

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const uniqueAssignees = uniqBy(
			[...projectAssignedResources, ...orgTaskAssigneeList],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};
	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getDayTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedDayTypeOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length === 1
					? selectedDayTypeOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getTimesheetStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedTimesheetStatusOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedTimesheetStatusOptionValue &&
				selectedTimesheetStatusOptionValue?.length === 1
					? selectedTimesheetStatusOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	return (
		<>
			<ThemeProvider theme={insightChartTheme}>
				<Card style={{ overflow: 'auto' }}>
					<Box>
						<Form
							onSubmit={() => {}}
							initialValues={{
								name: 'Total Hours',
								operator: totalHoursFilterOperator,
								value: totalHoursFilterValue,
								timesheet_type: timesheetType,
							}}
							mutators={{
								removeSelectedUser: (args, state, utils) => {
									utils.changeValue(state, 'employee_id', () => null);
								},
							}}
						>
							{({ form }) => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={commonStyle.header} alignItems={'center'}>
											<Box display='flex'>
												{featureFlag?.userTeams && (
													<Box
														className={commonStyle.autoCompleteInputContainer}
													>
														<Typography className={commonStyle.label}>
															User Team
														</Typography>
														<AutocompleteSearch
															placeholder={'Search Team'}
															option={
																userTeams && userTeams?.user_team
																	? userTeams?.user_team?.map(
																			(userTeam: {
																				id: string;
																				name: string;
																			}) => {
																				return {
																					id: userTeam?.id,
																					name: userTeam?.name,
																				};
																			}
																	  ) || []
																	: []
															}
															onChange={(id) => {
																if (!id) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	resetTeamResourceFilter();
																	setSelectedUserTeam(null);
																	setUserTeamId(null);
																	return;
																}
																setUserTeamId(id);
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																const currentTeam = userTeams?.user_team?.find(
																	(team: { id: string; full_name: string }) =>
																		team?.id === id
																);
																setSelectedUserTeam(currentTeam);
															}}
															name={'team_id'}
															value={selectedUserTeam}
														/>
													</Box>
												)}

												<Box display='block' width='180px' marginRight={'13px'}>
													<Box className={styles.label}>Choose resource</Box>
													<Box
														className={commonStyle.autoCompleteInputContainer}
													>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedResourceOptionValue &&
																selectedResourceOptionValue?.length > 0
																	? selectedResourceOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(resource: { id: string; name: string }) =>
																			resource.id !== 'All'
																	)
																	.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const allResourceIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(allResourceIds);
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	assigneeRecommendations?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(resource: { id: string; name: string }) =>
																			resource?.id !== 'All'
																	);
																	setSelectedResourceOptionValues(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																	setSelectedResourceIdList(currentIds);
																	return;
																}
																if (
																	selectedIds?.length ===
																		assigneeRecommendations?.length &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const reporteeIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(reporteeIds);
																	return;
																}
																if (
																	selectedResourceOptionValue.find(
																		(option: { id: string; name: string }) =>
																			option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	return;
																}
																const selectedId = value?.map(
																	(resource) => resource?.id
																);
																setSelectedResourceIdList(selectedId);
																setSelectedResourceOptionValues(value);
															}}
															options={
																assigneeRecommendations &&
																assigneeRecommendations?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...assigneeRecommendations,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getResourceFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent {...params} label='' />
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>

																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
												<Box display='flex' marginTop='24px' marginLeft='10px'>
													<Box width='90px'>
														<TextInput
															min={0}
															label={''}
															source='name'
															disabled
															onChange={(event) => {}}
														/>
													</Box>
													<Box width='101px' marginLeft='4px' marginRight='4px'>
														<ThemeProvider theme={filterMetricInputTheme}>
															<SelectInput
																source='operator'
																label={false}
																fullWidth
																choices={[
																	{ id: '>=', name: '(gte) >=' },
																	{ id: '<=', name: '(lte) <=' },
																]}
																onChange={(event) => {
																	if (!event) {
																		return;
																	}
																	setTotalHoursFilterOperator(
																		event?.target?.value
																	);
																	setPage(0);
																}}
															/>
														</ThemeProvider>
													</Box>
													<Box width='80px'>
														<NumberInput
															min={0}
															label={''}
															source='value'
															onChange={(event) => {
																if (!event) {
																	setTotalHoursFiltervalue(null);
																	return;
																}
																setTotalHoursFiltervalue(event?.target?.value);
																setPage(0);
															}}
															validate={minValue(0)}
														/>
													</Box>
												</Box>
												{isOvertimeEnabled && (
													<Box display='block' marginLeft='4px'>
														<Box className={styles.label}>Task type</Box>
														<Box>
															<SelectInput
																key={timesheetType}
																source='timesheet_type'
																label=''
																choices={timesheetTypes}
																onChange={(e: any) => {
																	setTimesheetType(e?.target?.value);
																	setPage(0);
																}}
															/>
														</Box>
													</Box>
												)}
												{isTrackDayTypeEnabled && (
													<Box display='block' marginLeft='4px' width='160px'>
														<Box className={styles.label}>Day type</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedDayTypeOptionValue &&
																		selectedDayTypeOptionValue?.length > 0
																			? selectedDayTypeOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const allResourceIds =
																				dayTypeOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedDayTypeIdList(allResourceIds);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			dayTypeOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedDayTypeOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedDayTypeIdList(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				dayTypeOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const reporteeIds = dayTypeOptions?.map(
																				(resource: any) => resource?.id
																			);
																			setSelectedDayTypeIdList(reporteeIds);
																			return;
																		}
																		if (
																			selectedDayTypeOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedDayTypeIdList([]);
																			setSelectedDayTypeOptionValues([]);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedDayTypeIdList(selectedId);
																		setSelectedDayTypeOptionValues(value);
																	}}
																	options={
																		dayTypeOptions && dayTypeOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...dayTypeOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getDayTypeFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
												{isDraftTimesheetEnabled && (
													<Box display='block' marginLeft='4px' width='170px'>
														<Box className={styles.label}>Timesheet status</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedTimesheetStatusOptionValue &&
																		selectedTimesheetStatusOptionValue?.length >
																			0
																			? selectedTimesheetStatusOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const allResourceIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				allResourceIds
																			);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			timesheetStatusOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedTimesheetStatusOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedTimesheetStatusIdList(
																				currentIds
																			);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				timesheetStatusOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const reporteeIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				reporteeIds
																			);
																			return;
																		}
																		if (
																			selectedTimesheetStatusOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedTimesheetStatusIdList([]);
																			setSelectedTimesheetStatusOptionValues(
																				[]
																			);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedTimesheetStatusIdList(
																			selectedId
																		);
																		setSelectedTimesheetStatusOptionValues(
																			value
																		);
																	}}
																	options={
																		timesheetStatusOptions &&
																		timesheetStatusOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...timesheetStatusOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={
																		getTimesheetStatusFilterLimits
																	}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
											</Box>
											<Box>
												<ExportButtonWithDropDown
													onChange={(index: number) => {
														generateCSV(index);
													}}
													options={exportTimesheetReportUsersOptions}
													isDisabled={!timeSheetTableList.length}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
					{!isTimesheetListLoading && !isGeneralTimeSheetLoading ? (
						<CardContent>
							<Box className={commonStyle.tableContainer}>
								{timeSheetTableList && timeSheetTableList?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE
													</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell className={commonStyle.headerCell}>
															LOCATION
														</CustomTableCell>
													)}

													<CustomTableCell className={commonStyle.headerCell}>
														BUSINESS DAYS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														DAYS WORKED
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														TOTAL HOURS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														BILLABLE HOURS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														NON-BILLABLE HOURS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														UTILIZATION
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE COST
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														TOTAL COST
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														BILL RATE
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														TOTAL BILL RATE
													</CustomTableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{timeSheetTableList &&
													timeSheetTableList?.length > 0 &&
													timeSheetTableList
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map(
															(timeSheet) =>
																timeSheetTableList?.length > 0 && (
																	<TableRow hover={true} key={timeSheet?.id}>
																		<CustomTableCell>
																			<Tooltip title={`${timeSheet?.user}`}>
																				<Typography
																					className={`${commonStyle.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.user}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		{noOfLocations && noOfLocations > 0 && (
																			<CustomTableCell key={timeSheet?.id}>
																				<Tooltip
																					title={`${
																						timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'
																					}`}
																				>
																					<Typography
																						className={`${commonStyle.dataCell} ${ellipsis.ellipsis}`}
																					>
																						{timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'}
																					</Typography>
																				</Tooltip>
																			</CustomTableCell>
																		)}

																		<CustomTableCell>
																			{timeSheet?.businessDays || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.workedDays || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{isFloat(timeSheet?.totalHours)
																				? Number(
																						timeSheet?.totalHours
																				  )?.toFixed(1)
																				: timeSheet?.totalHours || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{isFloat(
																				Number(timeSheet?.billableWorkingHours)
																			)
																				? Number(
																						timeSheet?.billableWorkingHours
																				  )?.toFixed(1)
																				: timeSheet?.billableWorkingHours ||
																				  '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{isFloat(
																				Number(
																					timeSheet?.nonBillableWorkingHours
																				)
																			)
																				? Number(
																						timeSheet?.nonBillableWorkingHours
																				  )?.toFixed(1)
																				: timeSheet?.nonBillableWorkingHours ||
																				  '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.utilization
																				? isFloat(
																						Number(timeSheet?.utilization)
																				  )
																					? `${Number(
																							timeSheet?.utilization
																					  )?.toFixed(1)}%`
																					: `${timeSheet?.utilization}%`
																				: '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.resourceCost
																				? isFloat(
																						Number(timeSheet?.resourceCost)
																				  )
																					? Number(
																							timeSheet?.resourceCost
																					  )?.toFixed(1)
																					: timeSheet?.resourceCost
																				: '--'}
																		</CustomTableCell>
																		<CustomTableCell>{`${
																			timeSheet?.currencySymbol &&
																			timeSheet?.totalCost
																				? timeSheet?.currencySymbol
																				: ''
																		} ${
																			timeSheet?.totalCost
																				? isFloat(timeSheet?.totalCost)
																					? Number(
																							timeSheet?.totalCost
																					  ).toFixed(1)
																					: timeSheet?.totalCost
																				: '--'
																		}`}</CustomTableCell>
																		<CustomTableCell>
																			{isNaN(Number(timeSheet?.billRate))
																				? timeSheet?.billRate
																				: `${
																						timeSheet?.billRate
																							? timeSheet?.currencySymbol || ''
																							: ''
																				  } ${
																						timeSheet?.billRate
																							? formatDecimal(
																									timeSheet?.billRate,
																									2
																							  )
																							: '--'
																				  }`}
																		</CustomTableCell>
																		<CustomTableCell>
																			{`${
																				timeSheet?.totalBillRate
																					? timeSheet?.currencySymbol || ''
																					: ''
																			} ${
																				timeSheet?.totalBillRate
																					? formatDecimal(
																							timeSheet?.totalBillRate,
																							2
																					  )
																					: '--'
																			}`}
																		</CustomTableCell>
																	</TableRow>
																)
														)}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												count={timeSheetTableList?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={commonStyle.noData}>
										<Typography className={commonStyle.noDataLabel}>
											No data found
										</Typography>
									</Box>
								)}
							</Box>
						</CardContent>
					) : (
						<Loading />
					)}
				</Card>
			</ThemeProvider>
		</>
	);
};
