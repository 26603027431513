import { makeStyles } from '@material-ui/core/styles';

export const settingsStyle = makeStyles({
	container: {
		width: '442px',
		height: 'fit-content',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	contentContainer: {
		background: '#FFFFFF',
		marginLeft: '16px',
		borderRadius: '4px',
		width: '100%',
		marginBottom: '10px',
		padding: '14px 24px 14px 14px',
		boxShadow: '0px 3px 6px #0000000F',
	},
	content: {
		marginTop: '10px',
	},
	headingContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	heading: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		color: '#292929',
		textTransform: 'uppercase',
	},
	inputContainer: {
		display: 'flex',
		width: '40vw',
	},
	inputLabel: {
		width: '13vw',
		height: '36px',
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		width: '22vw',
		height: 'fit-content',
	},
	formContainer: {
		width: '34vw',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '20px',
		marginBottom: '20px',
		marginLeft: '20px',
	},
	name:{
		width: '100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	contentCard: {
		background: '#FFFFFF',
		borderRadius: '4px',
		width: '100%',
		marginBottom: '16px',
		padding: '14px 24px 14px 14px',
		boxShadow: '0px 3px 6px #0000000F',
	},
	normalHeading: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px !important',
		color: '#292929',
	},
	description: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#5C5C5C',
	}
});

export const tableListStyle = makeStyles({
	Container: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginBottom: '10px',
		justifyContent: 'space-between',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
			'& > div': {
				'& > div': {
					'& > button': {
						display: 'block',
					},
				},
			},
		},
	},
	name: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: '100px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: '180px',
	},
	label: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		color: '#5C5C5C',
	},
	nonDefaultColor: {
		color: '#C7C7C7',
		width: '20px',
		height: '20px',
	},
	defaultColor: {
		color: '#EA9926',
		width: '20px',
		height: '20px',
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
	},
	IconButton: {
		marginRight: '10px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	actionContainer: {
		width: '130px',
	},
	employeesCount: {
		width: 'fit-content',
	},
});
