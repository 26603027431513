import React, { useContext, useEffect } from 'react';
import {
	TextInput,
	required,
	useNotify,
	useRefresh,
	SelectInput,
	BooleanInput,
} from 'react-admin';
import dayjs from 'dayjs';

import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { settingsStyle } from '../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { ADD_ORG_HOLIDAY, UPDATE_ORG_HOLIDAY } from '../Settings.gql';

import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserProfileContext } from '../../../App';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { useGetLocationsByOrgIdQuery } from '../../../generated/graphql';

interface Holiday {
	date?: string;
	id?: string;
	is_restricted?: boolean;
	name?: string;
	org_id?: string;
	description?: string;
	year?: string;
	location_id?: string;
}

interface HolidayFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: Holiday;
	isEditMutation: boolean;
	onSuccess: () => void;
}

const HolidayForm = (props: HolidayFormProps) => {
	const { open, onClose, initialValues, isEditMutation, onSuccess } = props;
	const holidayFormInitialValues = {
		is_restricted: false,
	};
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		dateFormat,
		orgId: organizationId,
		noOfLocations,
	} = useContext<any>(UserProfileContext);
	const [addOrgHoliday, { data: holiday, loading: isAddHolidayLoading }] =
		useApolloMutation(ADD_ORG_HOLIDAY);
	const [
		updateOrgHoliday,
		{ data: updatedHoliday, loading: IsEditHolidayLoading },
	] = useApolloMutation(UPDATE_ORG_HOLIDAY);

	const { data: orgLocationList } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});

	useEffect(() => {
		if (holiday) {
			notify('Holiday Created Successfully');
			refresh();
		}
	}, [holiday, notify, refresh]);

	useEffect(() => {
		if (updatedHoliday) {
			notify('Holiday Updated Successfully');
			refresh();
		}
	}, [updatedHoliday, notify, refresh]);

	useEffect(() => {
		if (holiday || updatedHoliday) {
			onSuccess();
		}
	}, [updatedHoliday, holiday, onSuccess]);

	const onHandleSubmit = (holiday: Holiday) => {
		const year = dayjs(holiday?.date).year();
		const formattedYear = dayjs()
			.year(year + 1)
			.format('YY');
		const financialYear = `${year}-${formattedYear}`;
		if (!isEditMutation) {
			addOrgHoliday({
				variables: {
					data: {
						name: holiday?.name,
						description: holiday?.description,
						date: holiday?.date,
						is_restricted: holiday?.is_restricted,
						year: financialYear,
						location_id: holiday?.location_id,
					},
				},
			});
			onClose();
		} else {
			updateOrgHoliday({
				variables: {
					holidayId: initialValues?.id,
					data: {
						name: holiday?.name,
						description: holiday?.description,
						date: holiday?.date,
						is_restricted: holiday?.is_restricted,
						year: financialYear,
						location_id: holiday?.location_id,
					},
				},
			});
			onClose();
		}
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={styles.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{isEditMutation ? `${initialValues?.name}` : `Add Holiday`}
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={onClose} />
					</Box>
					<Form
						initialValues={
							isEditMutation ? initialValues : holidayFormInitialValues
						}
						onSubmit={onHandleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>Name*</Typography>
									<TextInput source='name' label='' validate={required()} />
									{noOfLocations && noOfLocations > 0 && (
										<>
											<Typography className={classes.label}>
												Location*
											</Typography>
											<SelectInput
												fullWidth
												source='location_id'
												label={''}
												choices={orgLocationList?.org_location || []}
												validate={required()}
											/>
										</>
									)}

									<Typography className={classes.label}>Date*</Typography>
									<Field
										name='date'
										validate={(value: any) => {
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													onChange={props.input.onChange}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>

									<Typography className={classes.label}>Description</Typography>
									<TextInput source='description' label='' />

									<BooleanInput label='Is Optional' source='is_restricted' />
								</Box>
								<Box className={classes.buttonContainer} style={{ padding: 0 }}>
									<Button
										type='submit'
										variant='contained'
										disabled={
											invalid ||
											pristine ||
											isAddHolidayLoading ||
											IsEditHolidayLoading
										}
										className={
											invalid ||
											pristine ||
											isAddHolidayLoading ||
											IsEditHolidayLoading
												? classes.disabledButton
												: classes.saveButton
										}
									>
										{isEditMutation ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default HolidayForm;
