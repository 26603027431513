import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import './milestoneKanban.css';
import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { Form } from 'react-final-form';
import { isEmpty, uniqBy, orderBy } from 'lodash';
import { MileStoneKanban } from './MilestonesKanban.component';
import {
	Box,
	Checkbox,
	TextField as TextComponent,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { SearchIcon } from '../../../assets/SvgIcons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
	useGetKanbanBoardByProjectIdQuery,
	useGetProjectListOptionsQuery,
	useGetProjectResourceMappingListQuery,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useQuery as useApolloQuery } from '@apollo/client';

import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../../ResourcePlanner/gqlQueries';
import {
	autocompleteStyle,
	dropDownEllipsisStyle,
	ellipsisStyle,
} from '../../../Layout/styles';

interface Team {
	id: string;
	name: string;
}
const useStyles = makeStyles((theme) => ({
	companyDropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	companyDropListCount: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
}));
export const MilestoneKanbanContainer = () => {
	const [projectFilter, setProjectFilter] = useState<any>(null);
	const [projectMilestoneFilter, setProjectMilestoneFilter] = useState<any>([]);
	const [selectedStatusOptionValue, setSelectedStatusOptionValues] =
		useState<any>([]);
	const inlinestyle = useStyles();
	const ellipsisStyles = dropDownEllipsisStyle();
	const [searchedText, setSearchedText] = useState('');
	const [selectedStatusIdList, setSelectedStatusIdList] = useState<any>([]);
	const projectKanbanRef = useRef<any>();
	const { featureFlag } = useContext<any>(UserProfileContext);
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any[]>(
		[]
	);
	const dropDownStyle = autocompleteStyle();
	const ellipsis = ellipsisStyle();
	const { data: projectList } = useGetProjectListOptionsQuery();
	const { data: kanbanBoard, refetch } = useGetKanbanBoardByProjectIdQuery({
		variables: {
			id: projectFilter,
		},
		fetchPolicy: 'network-only',
		skip: !projectFilter,
	});

	const { data: projectAssignedResourceList } =
		useGetProjectResourceMappingListQuery({
			variables: {
				projectResourceMappingFilter: {
					project_id: { _eq: projectFilter },
					deleted_at: { _is_null: true },
				},
			},
			fetchPolicy: 'network-only',
			skip: !projectFilter,
		});
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		refetch();
	}, [refetch]);
	useEffect(() => {
		if (isEmpty(projectList?.project_list)) {
			return;
		}
		const milestoneInfo = projectList?.project_list?.map((value: any) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		setProjectMilestoneFilter(milestoneInfo);
	}, [projectList]);

	useEffect(() => {
		if (!kanbanBoard) {
			return;
		}
		const initialStatusIdList =
			kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns?.map(
				(column: { id: string; name: string }) => column?.id
			);
		setSelectedStatusIdList(initialStatusIdList);
		setSelectedStatusOptionValues(
			kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns
				? [
						{ id: 'All', name: 'All' },
						...kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns,
				  ]
				: []
		);
	}, [kanbanBoard]);
	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	useEffect(() => {
		if (!projectAssignedResourceList) {
			return;
		}
		if (selectedUserTeam) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: { user: { id: string; full_name: string } }) => {
					return {
						id: user?.user?.id,
						name: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList = orderBy(
				useTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			setAssigneeRecommendations(sortedAssigneeList);
			return;
		}

		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));
		const uniqueAssignees = uniqBy(
			[...(projectAssignedResources || [])],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [projectAssignedResourceList, selectedUserTeam, usersByTeam]);
	const getProjectStatusFilterLimits = (value: any) => {
		if (!value) {
			return;
		}
		return (
			<div className={inlinestyle.dropdownLabel}>
				Selected {value || 0} items
			</div>
		);
	};
	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const resetTeamResourceFilter = () => {
		if (!projectAssignedResourceList) {
			return;
		}
		const projectAssignedResources =
			projectAssignedResourceList?.projectResources?.map((resource) => ({
				id: resource.user.id,
				name: resource.user.full_name,
			}));
		const uniqueAssignees = uniqBy(projectAssignedResources, function (e) {
			return e.id;
		});
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};
	return (
		<>
			<div
				style={{ marginTop: '30px', paddingLeft: '0px' }}
				className={'projectTaskKanbanToolbarContainer'}
			>
				<div className={'projectTaskKanbanFilterContainer'}>
					<div className={'projectTaskKanbanAssigneeFilterContainer'}>
						<Form
							initialValues={{
								assignee: projectFilter,
							}}
							onSubmit={() => {}}
						>
							{() => (
								<Box display='flex' style={{ background: '#f7f9fa' }}>
									<ThemeProvider theme={modalFormTheme}>
										<Box style={{ background: '#f7f9fa' }}>
											<Box
												style={{
													fontSize: '9px',
													marginLeft: '12px',
													marginTop: '-12px',
													marginBottom: '4px',
												}}
											>
												Choose project
											</Box>
											<Autocomplete
												placeholder='Select Project'
												options={projectMilestoneFilter || []}
												style={{ width: 210, marginTop: '4px' }}
												renderInput={(params) => (
													<ThemeProvider
														theme={customAutoCompleteSearchBarTheme}
													>
														<TextComponent {...params} label='' />
													</ThemeProvider>
												)}
												getOptionLabel={(option: any | null) =>
													`${option?.name}`
												}
												renderOption={(option: any | null) => (
													<Box
														style={{ fontSize: '12px' }}
													>{`${option?.name}`}</Box>
												)}
												onChange={(event, newValue) => {
													if (!newValue) {
														setProjectFilter(null);
														return;
													}
													setProjectFilter(newValue?.id);
												}}
											/>
										</Box>
									</ThemeProvider>
									{projectFilter && projectFilter !== null && (
										<Box
											marginTop='6px'
											marginLeft='5px'
											marginRight='5px'
											style={{ background: '#f7f9fa' }}
										>
											<form onSubmit={(event) => event.preventDefault()}>
												<ThemeProvider theme={searchBarTheme}>
													<Box width='344px'>
														<TextComponent
															placeholder='Search Task'
															label={false}
															fullWidth
															InputLabelProps={{ style: { fontSize: 0 } }}
															InputProps={{
																startAdornment: <SearchIcon />,
															}}
															onChange={(e) =>
																setSearchedText(e?.target?.value)
															}
															value={searchedText || ''}
														/>
													</Box>
												</ThemeProvider>
											</form>
										</Box>
									)}
									{projectFilter && projectFilter !== null && (
										<Box>
											<Box className={inlinestyle.companyDropDownFilterLabel}>
												Status
											</Box>
											<Autocomplete
												multiple
												limitTags={0}
												style={{ width: 210, marginTop: '4px' }}
												value={
													selectedStatusOptionValue &&
													selectedStatusOptionValue?.length > 0
														? selectedStatusOptionValue
														: []
												}
												selectOnFocus={true}
												disablePortal
												fullWidth={false}
												onChange={(event, value, reason) => {
													if (!value) {
														return;
													}
													const selectedIds = value
														?.filter((status: any) => status.id !== 'All')
														.map((status: any) => status?.id);
													if (
														value.find((option) => option.id === 'All') &&
														reason === 'select-option'
													) {
														setSelectedStatusOptionValues(
															kanbanBoard?.project_by_pk?.kanban_board
																?.kanban_columns
																? [
																		{ id: 'All', name: 'All' },
																		...kanbanBoard?.project_by_pk?.kanban_board
																			?.kanban_columns,
																  ]
																: []
														);
														const allStatusIds =
															kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns?.map(
																(status: any) => status?.id
															);
														setSelectedStatusIdList(allStatusIds);
														return;
													}
													if (
														value.find((option) => option.id === 'All') &&
														reason === 'remove-option' &&
														kanbanBoard?.project_by_pk?.kanban_board
															?.kanban_columns?.length !== selectedIds?.length
													) {
														const currentOptions = value?.filter(
															(status: any) => status?.id !== 'All'
														);
														setSelectedStatusOptionValues(currentOptions);
														const currentIds = currentOptions?.map(
															(status: any) => status?.id
														);
														setSelectedStatusIdList(currentIds);
														return;
													}
													if (
														selectedIds?.length ===
															kanbanBoard?.project_by_pk?.kanban_board
																?.kanban_columns?.length &&
														reason === 'select-option'
													) {
														setSelectedStatusOptionValues([
															{ id: 'All', name: 'All' },
															...kanbanBoard?.project_by_pk?.kanban_board
																?.kanban_columns,
														]);
														const statusIds =
															kanbanBoard?.project_by_pk?.kanban_board?.kanban_columns?.map(
																(status: any) => status?.id
															);
														setSelectedStatusIdList(statusIds);
														return;
													}
													if (
														selectedStatusOptionValue.find(
															(option: any) => option.id === 'All'
														) &&
														reason === 'remove-option'
													) {
														setSelectedStatusIdList([]);
														setSelectedStatusOptionValues([]);
														return;
													}
													const selectedId = value?.map((status) => status.id);
													setSelectedStatusIdList(selectedId);
													setSelectedStatusOptionValues(value);
												}}
												options={
													kanbanBoard?.project_by_pk?.kanban_board
														?.kanban_columns &&
													kanbanBoard?.project_by_pk?.kanban_board
														?.kanban_columns?.length > 0
														? [
																{ id: 'All', name: 'All' },
																...kanbanBoard?.project_by_pk?.kanban_board
																	?.kanban_columns,
														  ]
														: []
												}
												disableCloseOnSelect={true}
												getLimitTagsText={getProjectStatusFilterLimits}
												ChipProps={{ style: { display: 'none' } }}
												renderInput={(params) => (
													<ThemeProvider
														theme={customAutoCompleteSearchBarTheme}
													>
														<TextComponent {...params} label='' />
													</ThemeProvider>
												)}
												renderOption={(option, { selected }) => (
													<>
														<Checkbox
															icon={
																<CheckBoxOutlineBlankIcon fontSize='small' />
															}
															checkedIcon={<CheckBoxIcon fontSize='small' />}
															style={{ marginRight: 8 }}
															checked={selected}
															color='primary'
														/>
														<Box
															style={{ fontSize: '12px' }}
														>{`${option?.name}`}</Box>
													</>
												)}
												getOptionLabel={(option) => `${option?.name}`}
												getOptionSelected={(option, value) =>
													option.id === value.id
												}
											/>
										</Box>
									)}
									{projectFilter &&
										projectFilter !== null &&
										featureFlag?.userTeams && (
											<>
												<Box width='200px' marginLeft='5px'>
													<Box
														className={inlinestyle.companyDropDownFilterLabel}
													>
														User Team
													</Box>
													<AutocompleteSearch
														placeholder={'Search Team'}
														option={
															userTeams && userTeams?.user_team
																? userTeams?.user_team?.map(
																		(userTeam: {
																			id: string;
																			name: string;
																		}) => {
																			return {
																				id: userTeam?.id,
																				name: userTeam?.name,
																			};
																		}
																  ) || []
																: []
														}
														onChange={(id) => {
															if (!id) {
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																resetTeamResourceFilter();
																setSelectedUserTeam(null);
																setUserTeamId(null);
																return;
															}
															setUserTeamId(id);
															setSelectedResourceIdList([]);
															setSelectedResourceOptionValues([]);
															const currentTeam = userTeams?.user_team?.find(
																(team: { id: string; full_name: string }) =>
																	team?.id === id
															);
															setSelectedUserTeam(currentTeam);
														}}
														name={'team_id'}
														value={selectedUserTeam}
													/>
												</Box>
												<Box width='200px' marginLeft='5px'>
													<Box
														className={inlinestyle.companyDropDownFilterLabel}
													>
														Resource
													</Box>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															selectedResourceOptionValue &&
															selectedResourceOptionValue?.length > 0
																? selectedResourceOptionValue
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id !== 'All'
																)
																.map(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id
																);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...assigneeRecommendations,
																]);
																const allResourceIds =
																	assigneeRecommendations?.map(
																		(resource: any) => resource?.id
																	);
																setSelectedResourceIdList(allResourceIds);
																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																assigneeRecommendations?.length !==
																	selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id !== 'All'
																);
																setSelectedResourceOptionValues(currentOptions);
																const currentIds = currentOptions?.map(
																	(resource: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => resource?.id
																);
																setSelectedResourceIdList(currentIds);
																return;
															}
															if (
																selectedIds?.length ===
																	assigneeRecommendations?.length &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...assigneeRecommendations,
																]);
																const reporteeIds =
																	assigneeRecommendations?.map(
																		(resource: any) => resource?.id
																	);
																setSelectedResourceIdList(reporteeIds);
																return;
															}
															if (
																selectedResourceOptionValue.find(
																	(option: {
																		id?: string | null | undefined;
																		name?: string | null | undefined;
																	}) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																return;
															}
															const selectedId = value?.map(
																(resource) => resource?.id
															);
															setSelectedResourceIdList(selectedId);
															setSelectedResourceOptionValues(value);
														}}
														options={
															assigneeRecommendations &&
															assigneeRecommendations?.length > 0
																? [
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																  ]
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getResourceFilterLimits}
														ChipProps={{ style: { display: 'none' } }}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextComponent {...params} label='' />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected}
																	color='primary'
																/>

																<Tooltip
																	title={`${option?.name || '- -'}`}
																	placement='right'
																>
																	<Typography
																		className={`${ellipsisStyles.ellipsis}`}
																	>
																		{`${option?.name || '- -'}`}
																	</Typography>
																</Tooltip>
															</>
														)}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) =>
															option.id === value.id
														}
													/>
												</Box>
											</>
										)}
								</Box>
							)}
						</Form>
					</div>
				</div>
			</div>
			<div style={{ marginLeft: '10px' }}>
				{projectFilter && projectFilter !== null && (
					<MileStoneKanban
						ref={projectKanbanRef}
						projectId={projectFilter}
						searchedText={searchedText}
						selectedStatus={selectedStatusIdList}
						resourceList={selectedResourceIdList}
					/>
				)}
			</div>
		</>
	);
};
