export const TIMESHEET_PROJECT_TASK_CREATION_TEXT =
	'Task created successfully...';

export const TIMESHEET_PROJECT_TASK_UPDATION_TEXT =
	'Task updated successfully...';

export const TIMESHEET_PROJECT_TASK_DELETE_CONFIRMATION_TEXT =
	'Do you really want to delete this Task?. Associated data also will remove, It cannot be undone';

export const TIMESHEET_PROJECT_TASK_DELETION_TEXT =
	'Task deleted successfully...';

export const TIMESHEET_MASTER_PROJECT_DESCRIPTION =
	'These are general tasks and will be applicable to all projects. All allocated resources to the project will be able to enter time sheet against these tasks.';

export const TIMESHEET_ORG_TASK_DESCRIPTION =
	'These are all Org level tasks and  are applicable to all active users.';

export const TIMESHEET_ORG_TASK_CREATION_TEXT = 'Task created successfully...';

export const TIMESHEET_ORG_TASK_UPDATION_TEXT = 'Task updated successfully...';

export const TIMESHEET_ORG_TASK_DELETE_CONFIRMATION_TEXT =
	'Do you really want to delete this Task?. Associated data also will remove, It cannot be undone';

export const TIMESHEET_ORG_TASK_DELETION_TEXT = 'Task deleted successfully...';

export const ORG_TASK_UNIQUE_VIOLATION_TEXT = 'Task already entered!';

export const MASTER_PROJECT_TASK_UNIQUE_VIOLATION_TEXT =
	'Task already entered!';

export const MASTER_PROJECT_TASK_UNIQUE_VIOLATION_KEY =
	'org_project_tasks_title_key';

export const ORG_TASK_UNIQUE_VIOLATION_KEY =
	'org_tasks_org_id_title_task_category_key';

export const ORG_TASK_MAPPING_UPDATION_TEXT =
	'Org Task mappings updated successfully...';

export const TIMESHEET_SETTINGS_UPDATION_TEXT =
	'Timesheet settings updated successfully';

export const TIMESHEET_SETTINGS_CREATION_TEXT =
	'Timesheet settings created successfully';

export const TIMESHEET_SETTINGS_UPDATION_FAILED_TEXT =
	'Timesheet settings updation failed';

export const TIMESHEET_SETTINGS_CREATION_FAILED_TEXT =
	'Timesheet settings creation failed';

export const DAY_TYPE_UNIQUE_VIOLATION_KEY =
	'timesheet_day_type_org_id_name_key';

export const DAY_TYPE_UNIQUE_VIOLATION_TEXT = 'Day type already exists!';

export const DAY_TYPE_CREATION_TEXT = 'Day type created successfully';

export const DAY_TYPE_UPDATION_TEXT = 'Day type updated successfully';

export const DAY_TYPE_DELETION_TEXT = 'Day type deleted successfully';

export const DAY_TYPE_DELETION_FAILED_TEXT = 'Deletion failed';
export const DAY_TYPE_DELETION_VIOLATION_KEY =
	'timesheet_overtime_day_type_id_fkey';

export const DAY_TYPE_DELETION_VIOLATION_TEXT =
	'Cannot delete due to associated data';

export const DAY_TYPE_SETTINGS_UPDATION_TEXT =
	'Day type settings saved successfully';

export const EMP_ID_PREFIX_UPDATION_TEXT = 'Prefix updated successfully';

export const regexNameValidation = /[a-zA-Z]/;

export const emptySpaceMessage = 'Required';

export const USER_TEAM_CREATION_MESSAGE = 'Team Created Successfully';

export const USER_TEAM_UPDATION_MESSAGE = 'Team Updated Successfully';

export const ENABLE_USR_CUSTOM_PIC_CONFIRMATION =
	'Are you sure want to proceed?';

export const ENABLE_EMPID_LENGTH_SETTING_CONFIRMATION =
	'Are you sure want to proceed?';
export const ENABLE_EMPID_SUCCESS_TEXT = 'Updated Successfully';

export const USER_DATA_UPLOAD_TEMPLATE_URL =
	'dataUploadTemplates/user_data_upload_template.xlsx';
export const PROJECT_DATA_UPLOAD_TEMPLATE_URL =
	'dataUploadTemplates/project_data_upload_template.xlsx';
export const TASK_DATA_UPLOAD_TEMPLATE_URL =
	'dataUploadTemplates/project_task_data_upload_template.xlsx';
export const PROJECT_CLONE_DATA_UPLOAD_TEMPLATE_URL =
	'dataUploadTemplates/project_clone_data_upload_template.xlsx';

export const ENABLE_USR_CUSTOM_PIC_SUCCESS_TEXT = 'Updated Successfully';
export const ENABLE_OVERTIME_CONFIRMATION = 'Are you sure you want to proceed?';
export const ENABLE_APPLY_LEAVE_CONFIRMATION =
	'Are you sure you want to proceed?';
export const ENABLE_APPLY_LEAVE_NOTIFICATION_MESSAGE =
	'Leave Settings Updated Successfully!';
export const DELETE_DAY_TYPE_CONFIRMATION =
	'Are you sure want to delete this type?';

export const PREFIX_CREATION_MESSAGE = 'Prefix Created Successfully';
export const PREFIX_UPDATION_MESSAGE = 'Prefix Updated Successfully';
export const PREFIX_DELETION_MESSAGE = 'Prefix Deleted Successfully';

export const validateDescription = (value: string) => {
	if (!value) {
		return undefined;
	}
	if (value.trim() === '') {
		return emptySpaceMessage;
	}
	return undefined;
};

export const validateName = (value: string) => {
	if (!value) {
		return undefined;
	}
	if (value.trim() === '') {
		return emptySpaceMessage;
	}
	if (!regexNameValidation.test(value)) {
		return 'Minimum one alphabet character is required';
	}
	return undefined;
};

export const validateRequiredField = (value: string) => {
	if (!value) {
		return 'Required';
	}
	if (value.trim() === '') {
		return emptySpaceMessage;
	}
	if (!regexNameValidation.test(value)) {
		return 'Minimum one alphabet character is required';
	}
	return undefined;
};

export const LEAVE_POLICY_DELETE_CONFIRMATION_MESSAGE =
	'Deleting this Leave Policy will permanently delete all the data associated with it (all Leave types, applied leaves and all other leave history). Once deleted, data cannot be retrieved. Are you sure, you want to permanently delete this leave policy?';

export const INTERVAL_WARNING_MESSAGE =
	'When the interval is modified, a recalculated renewal date will be applied to all users in the policy.';
export const DEFAULT_AMOUNT_WARNING_MESSAGE =
	'Modifying the default amount will be applied only to newly assigned users in the policy.';
export const LEAVE_POLICY_START_DATE_VALIDATION_MESSAGE =
	'	Start date must be less than Policy Period';

export const LEAVE_POLICY_END_DATE_VALIDATION_MESSAGE =
	'Policy Period must be greater than start date';

export const LEAVE_POLICY_APPLICABLE_FROM_VALIDATION_MESSAGE =
	'	Applicable From should lies between Start date and Policy Period';

export const INTERVAL_ZERO_ERROR_MESSAGE =
	'For increments greater than zero, the interval should be greater than zero';
export const INCREMENT_ZERO_ERROR_MESSAGE =
	'For interval greater than zero, the increment should be greater than zero';
export const ATTENDANCE_TIMESHEET_STATUS_MESSAGE =
	'The selected timesheet status will be taken into account for attendance.';
export const ATTENDANCE_REGULARIZATION_INFO_MESSAGE =
	'This count will be used to calculate the no of days for the regularization.';
export const ATTENDANCE_REGULARIZATION_LABEL_MESSAGE =
	'Enable user to edit attendance for past calendar month.';
export const EXCEL_TEMPLATE_DOWNLOAD_CAPTION =
	'Use this file template to upload bulk data';
export const USER_TYPE_EDIT_MESSAGE = 'User type cannot be modified after creation. Please contact the admin for assistance.'