import React, { useState, useEffect, useContext } from 'react';

import {
	TextInput,
	useNotify,
	useRefresh,
	required,
	SelectInput,
	RadioButtonGroupInput,
	maxLength,
	number,
	regex,
} from 'react-admin';
import { head, isEmpty } from 'lodash';
import { GET_EMPLOYEE_STATUS_OPTIONS } from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import EmployeeInActiveForm from './EmployeeInactiveForm.component';
import EmployeeProbabtionForm from './EmployeeProbationForm.component';
import { useMutation as useApolloMutation } from '@apollo/client';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { UserProfileContext } from '../../../App';
import { Form, Field } from 'react-final-form';
import {
	currencyTextBox,
	modalFormTheme,
	prefixSelectBoxTheme,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { trimInput } from '../../../Utils/string.util';
import dayjs from 'dayjs';
import { UPDATE_EMPLOYEE } from '../gqlQueries';
import {
	GET_ORGANIZATION_NOTICE_PERIOD_DAYS,
	GET_ORGANIZATION_PROBABTION_DAY,
} from '../../../SharedComponents/gqlQueries';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useGetActiveUsersByLimitQuery,
	useGetOrgDetailsQuery,
} from '../../../generated/graphql';
import { validatePhoneNumber } from '../../../Utils/string.util';
import { useQuery } from 'react-admin';

export const EmployeeBasicDetailsForm = (props) => {
	const { orgId: organizationId } = useContext(UserProfileContext);
	const { open, onClose, record: employee, refetch } = props;
	const classes = modalFormStyle();
	const [employeeDetails, setEmployeeDetails] = useState(null);
	const [isInactiveFormShown, setIsInactiveFormShown] = useState(false);
	const [isEmployeeProbationFormShown, setIsEmployeeProbationFormShown] =
		useState(false);
	const [employeeDetail, setEmployeeDetail] = useState({});
	const [isEmployeeInProbation, setIsEmployeeInProbation] = useState(false);
	const [noticePeriodDate, setNoticePeriodDate] = useState('');
	const [noticePeriodDay, setNoticePeriodDay] = useState();
	const [probationDay, setProbationDay] = useState();
	const [noticePeriodStatus, setNoticePeriodStatus] = useState(false);
	const [employeeStatusId, setEmployeeStatusId] = useState('');
	const [ownerId, setOwnerId] = useState(null);
	const [designationId, setdesignationId] = useState(null);
	const [designationChoices, setDesignationChoices] = useState();
	const [jobLevelChoices, setJobLevelChoices] = useState();
	const [jobLevelId, setJobLevelId] = useState(null);
	const [departmentChoices, setDepartmentChoices] = useState();
	const [departmentId, setDepartmentId] = useState(null);
	const [statusId, setStatusId] = useState(null);
	const [formValues, setFormValues] = useState({});
	const [isEmpIdUniqueViolationError, setIsEmpIdUniqueViolationError] =
		useState(false);
	const [employeeId, setEmployeeId] = useState();
	const [defaultPrefixId, setDefaultPrefixId] = useState();
	const [isActive, setIsActive] = useState(false);
	const refresh = useRefresh();
	const notify = useNotify();
	const [employeeInitialFormValues, setEmployeeInitialFormValues] = useState(
		{}
	);

	const { data: prefixList } = useQuery({
		type: 'GET_LIST',
		resource: 'employee_prefix',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});
	const [updateEmployee, { error: employeeUpdateError }] =
		useApolloMutation(UPDATE_EMPLOYEE);

	const [
		isPhoneNumberUniqueViolationError,
		setIsPhoneNumberUniqueViolationError,
	] = useState(false);

	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: employeeStatusOptions } = useApolloQuery(
		GET_EMPLOYEE_STATUS_OPTIONS
	);

	const { data: orgEmployeeDetails } = useGetOrgDetailsQuery({
		fetchPolicy: 'network-only',
	});

	const { data: organizationNoticePeriodDays } = useApolloQuery(
		GET_ORGANIZATION_NOTICE_PERIOD_DAYS,
		{
			variables: {
				organizationId,
			},
		}
	);

	const { data: organizationProbabtionDay } = useApolloQuery(
		GET_ORGANIZATION_PROBABTION_DAY,
		{
			variables: {
				organizationId,
			},
		}
	);

	useEffect(() => {
		if (!orgEmployeeDetails) {
			return;
		}

		const designation = orgEmployeeDetails?.designation?.map((value) => {
			return { id: value?.id, name: value?.title };
		});
		setDesignationChoices(designation);

		const jobLevel = orgEmployeeDetails?.job_level?.map((value) => {
			return { id: value?.id, name: value?.level };
		});
		setJobLevelChoices(jobLevel);

		const department = orgEmployeeDetails?.department?.map((value) => {
			return { id: value?.id, name: value?.name };
		});
		setDepartmentChoices(department);
	}, [orgEmployeeDetails]);

	useEffect(() => {
		if (isEmpty(organizationProbabtionDay?.organization_by_pk)) {
			return;
		}
		setProbationDay(
			organizationProbabtionDay?.organization_by_pk?.probation_day
		);
	}, [organizationProbabtionDay]);

	useEffect(() => {
		if (
			!organizationNoticePeriodDays ||
			!organizationNoticePeriodDays?.organization_by_pk ||
			!organizationNoticePeriodDays?.organization_by_pk?.notice_period_days
		) {
			return;
		}
		setNoticePeriodDay(
			organizationNoticePeriodDays?.organization_by_pk?.notice_period_days
		);
	}, [organizationNoticePeriodDays]);

	useEffect(() => {
		if (employee) {
			const userPhoneNumber = {
				...employee?.user,
				phone_number: employee?.user?.phone_number
					? employee?.user?.phone_number
					: '',
			};
			if (!employee?.employee_prefix_id) {
				const { employee_prefix_id, ...restValues } = employee;
				setEmployeeInitialFormValues({
					...restValues,
					employee_prefix_id: defaultPrefixId,
					user: userPhoneNumber,
				});
			} else {
				setEmployeeInitialFormValues({
					...employee,
					user: userPhoneNumber,
				});
			}
			setEmployeeDetails({
				id: employee?.id,
				first_name: employee?.user?.first_name,
				middle_name: employee?.user?.middle_name,
				last_name: employee?.user?.last_name,

				last_date: employee?.last_date,
				phone_number: employee?.user?.phone_number,
			});
			setOwnerId(employee?.reportee_id);
			setEmployeeId(employee?.id);
			setDepartmentId(employee?.department_id);
			setdesignationId(employee?.designation_id);
			setJobLevelId(employee?.job_level_id);
			setStatusId(employee?.employeeStatus);
		}
	}, [defaultPrefixId, employee, open]);

	useEffect(() => {
		if (employeeStatusId) {
			const activeStatus = employeeStatusOptions?.employeeStatus?.find(
				(status) => employeeStatusId === status.id
			);
			if (activeStatus.label === 'Terminated') {
				setIsActive(true);
				setNoticePeriodStatus(false);
				setNoticePeriodDate(employeeDetails?.last_date);
				setIsEmployeeInProbation(false);
			} else {
				setIsActive(false);
				setIsEmployeeInProbation(false);
			}
		}
	}, [employeeStatusId, employeeStatusOptions, employeeDetails]);

	useEffect(() => {
		if (employeeStatusId) {
			const noticePeriodStatus = employeeStatusOptions?.employeeStatus?.find(
				(status) => employeeStatusId === status.id
			);
			const options = noticePeriodStatus?.label;
			if (options === 'Notice') {
				setNoticePeriodStatus(options === 'Notice' ? true : false);
				setNoticePeriodDate(
					employeeDetail?.last_date ||
						dayjs().add(noticePeriodDay, 'day').format('YYYY-MM-DD')
				);
				setIsActive(true);
			}
		}
	}, [
		employeeStatusId,
		employeeDetail,
		employeeStatusOptions,
		noticePeriodDay,
	]);

	useEffect(() => {
		if (isEmpty(employeeStatusId)) {
			return;
		}
		const probabtionPeriodStatus = employeeStatusOptions?.employeeStatus?.find(
			(status) => employeeStatusId === status.id
		);
		if (probabtionPeriodStatus?.label === 'Probation') {
			setIsEmployeeInProbation(true);
			setIsInactiveFormShown(false);
			setNoticePeriodStatus(false);
			setEmployeeDetail({
				probation_start_date:
					employee?.probation_start_date || employee?.join_date,
				probation_end_date:
					employee?.probation_end_date ||
					dayjs(employee?.join_date)
						.add(probationDay, 'day')
						.format('YYYY-MM-DD'),
			});
		}
	}, [employeeStatusId, probationDay, employee, employeeStatusOptions]);

	useEffect(() => {
		if (employeeStatusId) {
			const noticePeriodStatus = employeeStatusOptions?.employeeStatus?.find(
				(status) => employeeStatusId === status.id
			);
			const options = noticePeriodStatus?.label;
			if (options === 'Resigned') {
				setNoticePeriodStatus(false);
				setNoticePeriodDate(employeeDetails?.last_date);
				setIsActive(true);
			}
		}
	}, [employeeStatusId, employeeDetails, employeeStatusOptions]);

	useEffect(() => {
		if (!employeeUpdateError) {
			return;
		}
		if (employeeUpdateError?.message.includes('employee_emp_id_org_id_key')) {
			setIsEmpIdUniqueViolationError(true);
		}
		if (
			employeeUpdateError?.message.includes(
				'employee_emp_id_org_id_employee_prefix_id_key'
			)
		) {
			setIsEmpIdUniqueViolationError(true);
			return;
		}
	}, [employeeUpdateError]);

	const validateFullName = (value) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
	};

	const saveEmployeeDetails = (formValues) => {
		updateEmployee({
			variables: {
				id: formValues?.id,
				user_Id: formValues?.user?.id,
				data: {
					emp_id: formValues?.emp_id || null,
					designation_id: designationId
						? designationId
						: formValues?.designation_id || null,
					job_level_id: jobLevelId
						? jobLevelId
						: formValues?.job_level_id || null,
					department_id: departmentId
						? departmentId
						: formValues?.department_id,

					working_mode: formValues?.working_mode,
					reportee_id: ownerId || null,
					is_billable: formValues?.is_billable,
					employeeStatus: statusId ? statusId : formValues?.employeeStatus,
					employee_prefix_id: formValues?.employee_prefix_id || defaultPrefixId,
				},
				userData: {
					first_name: formValues?.user?.first_name?.trim(),
					middle_name: formValues?.user?.middle_name?.trim() || null,
					last_name: formValues?.user?.last_name?.trim(),
					phone_number: formValues?.user?.phone_number || null,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					refetch();
					refresh();
					notify('Employee updated successfully');
					onClose();
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}

				if (error.message.includes('UQ_eeea54cb587a31720056fed06aa')) {
					setIsPhoneNumberUniqueViolationError(true);
					return;
				}
			});
	};

	const handleSave = (formValues) => {
		setFormValues(formValues);
		if (isActive) {
			setIsInactiveFormShown(true);
			return;
		}
		if (isEmployeeInProbation) {
			setIsEmployeeProbationFormShown(true);
			return;
		}
		saveEmployeeDetails(formValues);
	};

	const validateEmployeeFormValues = async (value) => {
		const errors = {};
		if (isEmpIdUniqueViolationError) {
			errors.emp_id = '';
			return errors;
		}
		return errors;
	};

	const closeModal = () => {
		setOwnerId(employee?.reportee_id || null);
		setDepartmentId('');
		setdesignationId('');
		setJobLevelId('');
		setStatusId('');
		onClose();
	};

	const getOwnerValue = (userId) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getDesignation = (designtnId) => {
		const response = orgEmployeeDetails?.designation?.find(
			(designation) => designation.id === designtnId
		);
		return response ? { id: response?.id, name: response?.title } : null;
	};

	const getJobLevel = (jobLevel) => {
		const response = orgEmployeeDetails?.job_level?.find(
			(value) => value.id === jobLevel
		);

		return response ? { id: response?.id, name: response?.level } : null;
	};

	const getDepartment = (department) => {
		const response = orgEmployeeDetails?.department?.find(
			(value) => value.id === department
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getStatus = (status) => {
		const response = orgEmployeeDetails?.employee_status?.find(
			(value) => value.id === status
		);
		return response ? { id: response?.id, name: response?.label } : null;
	};

	// Find N/A prefix to set it as default value
	useEffect(() => {
		if (isEmpty(prefixList)) {
			return;
		}
		const emptyPrefix = prefixList?.filter((prefix) => prefix.value === 'N/A');
		if (emptyPrefix) {
			setDefaultPrefixId(head(emptyPrefix)?.id);
		}
	}, [prefixList]);

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog disableBackdropClick open={open} onClose={closeModal}>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => {
								setIsPhoneNumberUniqueViolationError(false);
								closeModal();
							}}
						/>
					</Box>
					<Form
						initialValues={employeeInitialFormValues}
						onSubmit={handleSave}
						validate={validateEmployeeFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													First Name*
												</Typography>
												<TextInput
													source='user.first_name'
													id='first_name'
													label=''
													validate={[
														required(),
														validateFullName,
														maxLength(
															20,
															'First Name should not be greater than 20'
														),
													]}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Middle Name
												</Typography>
												<TextInput
													source='user.middle_name'
													id='middle_name'
													label=''
													validate={[
														maxLength(
															20,
															'Middle Name should not be greater than 20'
														),
													]}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Last Name*
												</Typography>
												<TextInput
													source='user.last_name'
													id='last_name'
													label=''
													validate={[
														required(),
														validateFullName,
														maxLength(
															20,
															'Last Name should not be greater than 20'
														),
													]}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Designation
												</Typography>

												<AutocompleteSearch
													placeholder={'Search Designation'}
													option={designationChoices || []}
													onChange={(value) => {
														setdesignationId(value);
													}}
													value={
														designationId ? getDesignation(designationId) : null
													}
													name={'designation_id'}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Employee ID
												</Typography>
												<Box display='flex'>
													<Box width='100px'>
														<ThemeProvider theme={prefixSelectBoxTheme}>
															<SelectInput
																style={{ background: 'white' }}
																choices={prefixList || []}
																source='employee_prefix_id'
																helperText={false}
																optionText='name'
																label={''}
																resettable
																onChange={(e) => {
																	setIsEmpIdUniqueViolationError(false);
																}}
															/>
														</ThemeProvider>
													</Box>
													<Box display='block'>
														<ThemeProvider theme={currencyTextBox}>
															<TextInput
																source='emp_id'
																helperText={false}
																label=''
																onChange={(e) => {
																	setIsEmpIdUniqueViolationError(false);
																}}
																validate={[
																	number(),
																	maxLength(6, 'Maximum 6 digits allowed!'),
																]}
															/>
														</ThemeProvider>
														{isEmpIdUniqueViolationError && (
															<p className={classes.errorText}>
																{'Id already exists'}
															</p>
														)}
													</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Job Level
												</Typography>
												<Box marginTop='8px'>
													<AutocompleteSearch
														placeholder={'Search Job Level'}
														option={jobLevelChoices || []}
														onChange={(value) => {
															setJobLevelId(value);
														}}
														value={jobLevelId ? getJobLevel(jobLevelId) : null}
														name={'job_level_id'}
													/>
												</Box>
											</Box>
										</Box>
										<Typography className={classes.label}>
											Department *
										</Typography>

										<AutocompleteSearch
											placeholder={'Search Department'}
											option={departmentChoices || []}
											onChange={(event) => {
												setDepartmentId(event);
											}}
											value={departmentId ? getDepartment(departmentId) : null}
											name={'department_id'}
											validates={true}
										/>

										<Typography className={classes.label}>Status *</Typography>

										<AutocompleteSearch
											placeholder={'Search Status'}
											option={employeeStatusOptions?.employeeStatus?.map(
												(status) => ({ id: status.id, name: status.label })
											)}
											onChange={(value) => {
												setStatusId(value);
												setEmployeeStatusId(value);
											}}
											value={getStatus(statusId)}
											name={'employeeStatus'}
											validates={true}
										/>

										<Typography className={classes.label}>
											Reporting to
										</Typography>
										<Field name='reportee_id'>
											{(props) => (
												<AutocompleteSearch
													placeholder={'Search User'}
													option={
														activeUsers && activeUsers?.user
															? activeUsers?.user?.map((value) => {
																	return {
																		id: value?.id,
																		name: value?.name,
																	};
															  })
															: []
													}
													onChange={(event) => {
														props.input.onChange(event);
														setOwnerId(event);
													}}
													value={getOwnerValue(ownerId || '')}
													name={'reportee_id'}
												/>
											)}
										</Field>
										<Typography className={classes.label}>
											Phone Number
										</Typography>
										<TextInput
											label=''
											source='user.phone_number'
											id='phone_number'
											reference='user'
											validate={[
												regex(validatePhoneNumber, 'Invalid phone number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											onChange={(e) => {
												setIsPhoneNumberUniqueViolationError(false);
											}}
											fullWidth
											parse={trimInput}
										></TextInput>
										{isPhoneNumberUniqueViolationError && (
											<p className={classes.phoneNumberError}>
												{'Phone Number already exists'}
											</p>
										)}
										<Typography className={classes.label}>
											Mode of Work
										</Typography>
										<SelectInput
											source='working_mode'
											label=''
											fullWidth
											choices={[
												{ id: 'Hybrid', name: 'Hybrid' },
												{ id: 'WFH', name: 'Remote' },
												{ id: 'Office', name: 'WFO' },
											]}
										/>
										<RadioButtonGroupInput
											source='is_billable'
											label=''
											choices={[
												{ id: true, name: 'Billable' },
												{ id: false, name: 'Non Billable' },
											]}
										/>
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={() => {
													setIsPhoneNumberUniqueViolationError(false);
													closeModal();
												}}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine
														? classes.disabledButton
														: classes.saveButton
												}
												disabled={invalid || pristine}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
			<EmployeeInActiveForm
				open={isInactiveFormShown}
				onClose={() => {
					setIsInactiveFormShown(false);
				}}
				onSuccess={() => {
					saveEmployeeDetails(formValues);
				}}
				userId={employee?.user?.id}
				last_date={noticePeriodDate}
				noticePeriodStatus={noticePeriodStatus}
				employeeId={employeeId}
			/>
			<EmployeeProbabtionForm
				open={isEmployeeProbationFormShown}
				onClose={() => setIsEmployeeProbationFormShown(false)}
				onSuccess={() => {
					saveEmployeeDetails(formValues);
				}}
				userId={employee?.id}
				employeeDetails={employeeDetail}
				probationDay={probationDay}
			/>
		</ThemeProvider>
	);
};

export default EmployeeBasicDetailsForm;
