import React, { useContext, useState } from 'react';

import { useMutation, useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';

import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Typography, Button, Box, makeStyles } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import dayjs from 'dayjs';
import { UserProfileContext } from '../../../App';
const useStyles = makeStyles({
	container: {
		width: '442px',
		minHeight: '300px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
});
interface ActiveFormProps {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	contactor_id?: string;
}

interface Record {
	join_date: string;
	end_date: string;
}

export const ConsultantActiveForm = ({
	open,
	onClose,
	onSuccess,
	contactor_id,
}: ActiveFormProps) => {
	const classes = modalFormStyle();
	const [mutate] = useMutation();
	const notify = useNotify();
	const customStyle = useStyles();
	const [endDateError, setEndDateError] = useState(false);
	const { dateFormat } = useContext<any>(UserProfileContext);

	const saveInactiveFormData = (formValue: Record) => {
		if (!formValue) {
			return;
		}
		if (dayjs(formValue?.join_date).isAfter(formValue?.end_date)) {
			setEndDateError(true);
			return;
		}

		mutate(
			{
				type: 'update',
				resource: 'contractor',
				payload: {
					id: contactor_id,
					data: {
						...formValue,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					onSuccess();
					onClose();
				},
				onFailure: (error) =>
					notify(`Create failed: ${error.message}`, 'warning'),
			}
		);
	};

	const handleClose = () => {
		setEndDateError(false);
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} disableBackdropClick>
				<Box className={customStyle.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>ADDITIONAL INFO</Typography>
						<CloseIcon onClick={handleClose} className={classes.closeIcon} />
					</Box>

					<Form onSubmit={saveInactiveFormData}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>
										Start Date *
									</Typography>
									<Field
										name='join_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={null}
													onChange={(value) => {
														setEndDateError(false);
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>

									<Typography className={classes.label}>End Date *</Typography>
									<Field
										name='end_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true || value === null) {
												return undefined;
											} else {
												return 'Invalid Date';
											}
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={null}
													onChange={(value) => {
														setEndDateError(false);
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{endDateError && (
										<p className={classes.errorText}>
											End date should be greater than start date
										</p>
									)}

									<Box
										className={classes.buttonContainer}
										style={{ marginTop: '15px' }}
									>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid ? classes.disabledButton : classes.saveButton
											}
											disabled={invalid}
											type='submit'
										>
											Save
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};
