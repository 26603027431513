import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AssetHardwareDetails from './AssetHardwareDetails.componenet';
import AssetWarrantyDetails from './AssetWarrantyDetails.component';
import AssetSoftwareDetails from './AssetSoftwareDetails.component';
import { Asset } from '../AssetManagement.model';
import AssetNote from './AssetNotes.component';
const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme:any) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: '10px',
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Medium',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#898989 ',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
	note:{
		height:'535px'
	}
}));

interface Props {
	record: Asset;
	basePath: string;
	resource: string;
	refetch: () => void;
}
export const AssetDetailContainer = (props: Props) => {
	const { record: asset, refetch } = props;
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const onTabChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};
	return (
		<Paper className={classes.root}>
			<CustomTabs value={selectedTabIndex} onChange={onTabChange}>
				<CustomTab key={0} label='Hardware' />
				<CustomTab key={1} label='Software' />
				<CustomTab key={2} label='Warranty' />
				<CustomTab key={3} label='Notes' />
				<CustomTab key={4} label='History' />
			</CustomTabs>
			<CustomTabPanel value={selectedTabIndex} index={0}>
				<AssetHardwareDetails data={asset} refetch={() => refetch()} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={1}>
				<AssetSoftwareDetails />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={2}>
				<AssetWarrantyDetails data={asset} refetch={() => refetch()} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={3} >
				<AssetNote />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={4}>
				<Typography className={classes.noData}>
					No data to display...
				</Typography>
			</CustomTabPanel>
		</Paper>
	);
};

export default AssetDetailContainer;
