import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import { modalFormTheme } from '../../../Layout/Theme.component';
const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '150px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		fontFamily: 'Manrope-medium',
		letterSpacing: '0px',
		color: '#c50707',
		fontSize: '14px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	header: {
		width: '440px',
	},
}));

interface Props {
	onClose: () => void;
	open: boolean;
	onDelete: () => void;
	confirmationMessage?: string;
	heading?: string;
	mutationLoading: boolean;
}

const ProjectDeleteModal = (props: Props) => {
	const {
		open,
		onClose,
		onDelete,
		confirmationMessage,
		heading,
		mutationLoading,
	} = props;
	const classes = modalFormStyle();
	const containerClass = useStyles();
	const ellipsisStyles = ellipsisStyle();
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				{heading ? (
					<DialogTitle>
						<Box
							className={`${containerClass.header} ${ellipsisStyles.ellipsis}`}
						>
							{heading}
						</Box>
					</DialogTitle>
				) : null}
				<Box className={containerClass.detailsContainer}>
					<p className={containerClass.heading}>
						{confirmationMessage
							? confirmationMessage
							: 'Do you really want to delete this record ?'}
					</p>
					<Box className={containerClass.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button
								disabled={mutationLoading}
								onClick={onClose}
								className={containerClass.cancelButton}
							>
								Cancel
							</Button>
							<Button
								disabled={mutationLoading}
								className={containerClass.saveButton}
								onClick={onDelete}
								type='submit'
								startIcon={
									mutationLoading && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#4285F4',
											}}
										/>
									)
								}
							>
								Delete
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectDeleteModal;
