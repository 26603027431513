import React from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	ThemeProvider,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { TextInput, required } from 'react-admin';

import { formStyle } from '../pms.styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import Loader from '../SharedComponents/Loader.component';
import {  ellipsisStyle } from '../../../Layout/styles';

interface PMSManagerFeedbackFormProps {
	onClose: () => void;
	open: boolean;
	details: {
		userName: string;
		appraisalName: string;
	};
	employee_self_feedback: string;
	updateFeedback: (feedback: string) => void;
	isLoading: boolean;
}

function PMSManagerFeedbackForm(props: PMSManagerFeedbackFormProps) {
	const {
		open,
		onClose,
		updateFeedback,
		details,
		isLoading,
		employee_self_feedback,
	} = props;
	const formStyles = formStyle();
	const style = ellipsisStyle();
	

	const handleSubmit = (formValues: { feedback: string }) => {
		updateFeedback(formValues.feedback);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<div className={formStyles.container} style={{ width: '600px' }}>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>Appraisal Feedback</p>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</div>
					<Form
						onSubmit={handleSubmit}
						initialValues={{
							employee_self_feedback: employee_self_feedback,
						}}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<div>
									<DialogContent>
										<div
											className={formStyles.multipleInputContainer}
											style={{
												marginBottom: '20px',
											}}
										>
											<div className={formStyles.multipleInput}>
												<p className={formStyles.label}>Appraisal</p>
												<p
												className={style.ellipsis}
													style={{
														fontSize: '12px',
														fontFamily: 'Manrope',
													}}
												>
													{details.appraisalName}
												</p>
											</div>
											<div className={formStyles.multipleInput}>
												<p className={formStyles.label}>Employee</p>
												<p
													style={{
														fontSize: '12px',
														fontFamily: 'Manrope',
													}}
												>
													{details.userName}
												</p>
											</div>
										</div>

										<p className={formStyles.label}>Employee Feedback</p>
										<TextInput
											source='employee_self_feedback'
											fullWidth={true}
											label={''}
											multiline
											disabled
										/>

										<p className={formStyles.label}>Overall Feedback*</p>
										<TextInput
											source='feedback'
											fullWidth={true}
											label={''}
											multiline
											validate={required()}
										/>
										<div className={formStyles.buttonContainer}>
											<Button
												className={formStyles.cancelButton}
												onClick={onClose}
											>
												Cancel
											</Button>

											<Button
												className={
													invalid || pristine || isLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={invalid || pristine || isLoading}
												type='submit'
												startIcon={isLoading && <Loader />}
											>
												Submit Manager Review
											</Button>
										</div>
									</DialogContent>
								</div>
							</form>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default PMSManagerFeedbackForm;
