import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';

const useStyles = makeStyles({
	detailsContainer: {
		width: '442px',
		height: 'fit-content',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
});

interface Props {
	onClose: () => void;
	open: boolean;
	onConfirm: () => void;
	warningMessage?: string;
}

const KanbanWarningModal = (props: Props) => {
	const { open, onClose, onConfirm, warningMessage } = props;
	const formStyles = modalFormStyle();
	const warningModalStyles = useStyles();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={warningModalStyles.detailsContainer}>
					<Typography className={warningModalStyles.heading}>
						{warningMessage ? warningMessage : 'This action cannot be perform'}
					</Typography>
					<Box className={warningModalStyles.buttonAlignment}>
						<Box className={formStyles.floatingButtonContainer}>
							<Button onClick={onClose} className={formStyles.cancelButton}>
								Cancel
							</Button>
							<Button className={formStyles.saveButton} onClick={onConfirm}>
								Ok
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default KanbanWarningModal;
