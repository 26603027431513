import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'ra-core';
import {
	AutocompleteInput,
	ReferenceInput,
	useNotify,
	useRefresh,
} from 'react-admin';
import { Form } from 'react-final-form';
import {
	Button,
	Box,
	Dialog,
	ThemeProvider,
	makeStyles,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';

import {
	useAddInvitedEmailGroupUserMutation,
	useGetUserEmailMappingQuery,
} from '../../../../generated/graphql';

import { isEmpty } from 'lodash';
import { DropdownOption } from '../../../../SharedComponents/Autocompletesearch.component';
import UserDeleteModal from './UserDeleteModal.component';
import {
	INVITE_FAILED_MESSAGE,
	INVITE_SUCCESS_MESSAGE,
	INVITE_USER_WARNING,
} from './constant';

const useStyles = makeStyles(() => ({
	table: {
		overflowY: 'auto',
		height: '200px',
	},
	name: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	deleteIcon: {
		color: '#787575',
	},
	error: {
		color: '#f44336',
		fontSize: '12px',
	},
	dropDownEllipsis: {
		fontSize: '12px',
		width: '170px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '125px',
	},
}));

interface UserMappingProps {
	open: boolean;
	onClose: () => void;
	groupId?: string;

	refetch: () => void;
}

interface User {
	first_name: string;
	last_name?: string | null;
	full_name?: string;
}

interface UserProps {
	user: User;
	id: string;
}

interface DeleteUser {
	id: string | undefined;
	name: string | undefined;
	user_id: string;
}

export const UserEmailMappingForm = (props: UserMappingProps) => {
	const { open, onClose, refetch, groupId } = props;

	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

	const [userName, setUserName] = useState<string>('');
	const [addedUsers, setAddedUsers] = useState<DropdownOption[]>();
	const [userId, setUserId] = useState('');
	const [id, setId] = useState<any | null>();

	const [userError, setUserError] = useState('');
	const classes = modalFormStyle();
	const inlineStyle = useStyles();

	const notify = useNotify();
	const refresh = useRefresh();

	const [deleteId, setDeleteId] = useState<string | undefined>('');

	const [updateUserEmailMapping, { loading: isMutationLoading }] =
		useAddInvitedEmailGroupUserMutation();

	const { data: getAddedUsers, refetch: refetchAddedUsers } =
		useGetUserEmailMappingQuery({
			variables: {
				groupId: groupId,
			},
			fetchPolicy: 'network-only',
		});

	const onHandleSubmit = (formValues: DeleteUser) => {
		if (!userId) {
			return;
		}

		if (id! && id.includes(userId!)) {
			setUserError(INVITE_USER_WARNING);
		} else {
			updateUserEmailMapping({
				variables: {
					addedUser: {
						user_id: userId,
						group_id: groupId,
					},
				},
			})
				.then((res) => {
					setUserError('');
					setUserId('');
					notify(INVITE_SUCCESS_MESSAGE);
					handleClose();
					setUserError('');
					refetchAddedUsers();
					refetch();
				})
				.catch((err) => {
					setUserError('');
					setUserId('');
					notify(INVITE_FAILED_MESSAGE, 'warning');
				});
		}
	};

	const handleClose = () => {
		onClose();
		setUserError('');
		setUserId('');
	};

	useEffect(() => {
		if (isEmpty(getAddedUsers?.user_email_group_mapping)) {
			setAddedUsers([]);
			setId([]);
			return;
		}
		const addedUser = getAddedUsers?.user_email_group_mapping?.map((user) => {
			return {
				name: `${user?.emailGroupUser?.full_name} `,
				id: user?.id,
			};
		});

		setAddedUsers(addedUser);

		const ids = getAddedUsers?.user_email_group_mapping?.map(
			(user) => user?.user_id
		);
		setId(ids!);
	}, [getAddedUsers]);

	const handleDelete = (user: any) => {
		if (!user) {
			return;
		}
		setIsDeleteModalShown(true);
		setUserName(user?.name);
	};

	const MaptoFullName = (props: any) => {
		const record = useRecordContext(props);

		return (
			<span>
				<Tooltip title={`${record?.full_name}`} placement='right'>
					<Typography className={inlineStyle.dropDownEllipsis}>
						{`${record?.full_name}`}
					</Typography>
				</Tooltip>
			</span>
		);
	};

	const optionText = <MaptoFullName />;
	const inputText = (choice: any) => `${choice?.full_name}`;
	const matchSuggestion = (filter: any, choice: any) => {
		return choice?.full_name?.toLowerCase().includes(filter.toLowerCase());
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box width='400px' padding='20px' borderRadius='4px'>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>Add User</Box>
						<CloseIcon
							id='opp_invite_user_close_icon'
							className={classes.closeIcon}
							onClick={handleClose}
						/>
					</Box>
					<Form onSubmit={onHandleSubmit} initialValues={{ userId }}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box
									className={classes.multipleInputContainer}
									marginTop='10px'
								>
									<Box>
										<ReferenceInput
											source='userId'
											reference='user'
											sort={{ field: 'first_name', order: 'ASC' }}
											label={''}
											filterToQuery={(searchText) => ({
												full_name: searchText,
												is_active: true,
											})}
											onChange={(event) => {
												setUserId(event);
												setUserError('');
											}}
										>
											<AutocompleteInput
												optionText={optionText}
												inputText={inputText}
												matchSuggestion={matchSuggestion}
												resettable={true}
												shouldRenderSuggestions={(name: any) =>
													name?.trim().length > 0
												}
											/>
										</ReferenceInput>
									</Box>

									<Box>
										<Button
											className={
												!userId || isMutationLoading
													? classes.disabledButton
													: classes.saveButton
											}
											disabled={invalid || isMutationLoading}
											type='submit'
											startIcon={
												isMutationLoading && (
													<CircularProgress
														style={{
															height: '20px',
															width: '20px',
															color: '#4285F4',
														}}
													/>
												)
											}
										>
											Add
										</Button>
									</Box>
								</Box>
								{userError && (
									<Box className={inlineStyle.error}>{userError}</Box>
								)}
							</form>
						)}
					</Form>
					{addedUsers && addedUsers.length > 0 && (
						<Box className={inlineStyle.table}>
							<TableContainer component={Paper}>
								<Table aria-label='simple table'>
									<TableBody>
										{addedUsers?.map((user, index) => {
											return (
												<TableRow key={index}>
													<TableCell
														component='th'
														scope='row'
														className={inlineStyle.name}
													>
														<Tooltip title={user?.name!}>
															<Typography
																className={`${inlineStyle.dataCell} ${inlineStyle.dropDownEllipsis}`}
															>
																{user.name}
															</Typography>
														</Tooltip>
													</TableCell>
													<TableCell
														component='th'
														scope='row'
														onClick={() => {
															setDeleteId(user?.id!);
															handleDelete(user);
														}}
													>
														<DeleteIcon className={inlineStyle.deleteIcon} />
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					)}
				</Box>
			</Dialog>
			<UserDeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				userName={userName}
				refetch={() => {
					refetch();
					refetchAddedUsers();
				}}
				deleteId={deleteId}
				refresh={refresh}
			/>
		</ThemeProvider>
	);
};
