import { Col, Row, Spin, Typography } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { getCurrencyFormat } from '../../../Utils/string.util';
import { InsightCount } from '../modal';
const { Text } = Typography;

export const PipelineLossRevenue = ({
	value,
	currencyType,
	isLoading,
}: InsightCount) => {
	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text>{getCurrencyFormat(value, currencyType, true)}</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};
