import React, { useState, useEffect } from 'react';
import {
	TextInput,
	useMutation,
	useNotify,
	useRefresh,
	required,
	SelectInput,
	email,
	regex,
	maxLength,
} from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { Form } from 'react-final-form';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../Layout/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import { validatePhoneNumber, validateWebsite } from '../../Utils/string.util';
import { contactChoices } from '../Company/Constant';
import {
	useGetActiveUsersByLimitQuery,
	useGetCrmCompanyQuery,
} from '../../generated/graphql';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import ButtonWithLoader from '../../SharedComponents/Btn/ButtonWithLoader';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '442px',
		minHeight: '500px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '32px',
	},
}));

const ContactBasicDetailsForm = (props) => {
	const { open, onClose, record: contact } = props;
	const classes = modalFormStyle();
	const style = useStyles();
	const [contactDetails, setContactDetails] = useState(null);
	const [ownerId, setOwnerId] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [mutate, { loading: isBasicDetailsUpdating }] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const { data: activeUsers } = useGetActiveUsersByLimitQuery();
	const { data: company } = useGetCrmCompanyQuery();

	useEffect(() => {
		if (contact) {
			setContactDetails({
				first_name: contact?.first_name,
				last_name: contact?.last_name,
				full_name: contact?.full_name,
				title: contact?.title,
				email: contact?.email,
				primary_phone: contact?.primary_phone,
				contact_type: contact?.contact_type,
				alternate_phone: contact?.alternate_phone,
				background: contact?.background,
				tags: contact?.tags,
				company_id: contact?.company_id,
				linkedin: contact?.linkedin,
				address: contact?.address,
				owner_id: contact?.owner_id,
			});
			setCompanyId(contact?.company_id || null);
			setOwnerId(contact?.owner_id || null);
		}
	}, [contact]);

	const saveDetails = (event) => {
		mutate(
			{
				type: 'update',
				resource: 'crm_contacts',
				payload: {
					id: contact?.id,
					data: {
						...contactDetails,
						company_id: companyId || null,
						owner_id: ownerId || null,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Contacts Updated Successfully');
					refresh();
					onClose();
				},
				onFailure: (error) => {
					props.onClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	const getCompanyValue = (compId) => {
		const response = company?.crm_company?.find(
			(value) => value?.id === compId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const getOwnerValue = (userId) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const modalClose = () => {
		setCompanyId(null);
		setOwnerId(null);
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={modalClose} />
					</Box>
					<Form initialValues={contact} onSubmit={saveDetails}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													First Name*
												</Typography>
												<TextInput
													source='first_name'
													label=''
													validate={required()}
													onChange={(e) => {
														setContactDetails({
															...contactDetails,
															first_name: e.target.value,
														});
													}}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Last Name
												</Typography>
												<TextInput
													source='last_name'
													label=''
													onChange={(e) => {
														setContactDetails({
															...contactDetails,
															last_name: e.target.value,
														});
													}}
												/>
											</Box>
										</Box>
										<Typography className={classes.label}>Title</Typography>
										<TextInput
											source='title'
											label=''
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													title: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>Email</Typography>
										<TextInput
											source='email'
											label={''}
											validate={[email()]}
											fullWidth
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													email: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>
											Phone Number 1
										</Typography>
										<TextInput
											source='primary_phone' 
											label={''}
											validate={[
												regex(validatePhoneNumber, 'Must be a number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}											fullWidth
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													primary_phone: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>
											Phone Number 2
										</Typography>
										<TextInput
											source='alternate_phone'
											label={''}
											validate={[
												regex(validatePhoneNumber, 'Must be a number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											fullWidth
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													alternate_phone: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>
											Background
										</Typography>
										<TextInput
											source='background'
											label={''}
											fullWidth
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													background: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>
											Contact Type
										</Typography>
										<SelectInput
											source='contact_type'
											label={''}
											resettable={true}
											fullWidth
											choices={contactChoices}
											onChange={(e) => {
												setContactDetails({
													...contactDetails,
													contact_type: e?.target?.value || null,
												});
											}}
										/>
										<Typography className={classes.label}>
											Company Name
										</Typography>
										<AutocompleteSearch
											placeholder={'Search Company'}
											option={
												company && company?.crm_company
													? company?.crm_company?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setCompanyId}
											value={getCompanyValue(companyId || '')}
											name={'company_id'}
											validates={false}
										/>
										<Typography className={classes.label}>BD OWNER</Typography>
										<AutocompleteSearch
											placeholder={'Search Owner'}
											option={
												activeUsers && activeUsers?.user
													? activeUsers?.user?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setOwnerId}
											value={getOwnerValue(ownerId || '')}
											name={'owner_id'}
											validates={false}
										/>
										<Typography className={classes.label}>Linkedin</Typography>
										<TextInput
											source='linkedin'
											label={''}
											fullWidth
											validate={[
												regex(validateWebsite, 'Must be a valid Link'),
											]}
											onChange={(e) => {
												setContactDetails({
													linkedin: e.target.value,
												});
											}}
										/>
										<Typography className={classes.label}>Address</Typography>
										<TextInput
											source='address'
											label={''}
											fullWidth
											onChange={(e) => {
												setContactDetails({
													address: e.target.value,
												});
											}}
										/>
									</DialogContent>
								</Box>
								<DialogActions>
									<Box className={style.floatingButtonContainer}>
										<Button
											onClick={modalClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											title='Update'
											isDisabled={invalid || pristine || isBasicDetailsUpdating}
											isLoading={isBasicDetailsUpdating}
										/>
									</Box>
								</DialogActions>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ContactBasicDetailsForm;
