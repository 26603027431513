import React from 'react';

import { Loading } from 'react-admin';
import { CompanyDetailsCard } from './CompanyDetailsCard.component';
import { GET_COMPANY } from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { makeStyles } from '@material-ui/core/styles';
import CompanyDetailContainer from './CompanyDetailContainer.component';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadcumData } from '../Constant';

const useStyles = makeStyles((theme) => ({
	detailContainer: {
		display: 'flex',
	},
	leftPanel: {
		marginRight: '16px',
	},
	rightPanel: {
		width: '100%',
	},
	toolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '60px',
		alignItems: 'center',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		font: 'normal normal bold 12px/32px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		cursor: 'pointer',
	},
}));
export const CompanyDetails = (props) => {
	const classes = useStyles();
	const { id } = props?.match?.params;
	const { data, loading, refetch: refetchCompany } = useApolloQuery(GET_COMPANY, {
		variables: {
			companyId: id ? id : null,
		},
	});
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<Breadcrum data={[...breadcumData,{name:data?.crm_company?.name}]} icon={DoubleArrowIcon}/>
			<div className={classes.detailContainer}>
				<div className={classes.leftPanel}>
					<CompanyDetailsCard
						record={data?.crm_company}
						basePath='/crm_company'
						resource='crm_company'
						{...props}
						refetchCompany ={() => refetchCompany()}
					/>
				</div>

				<div className={classes.rightPanel}>
					<CompanyDetailContainer
						record={data?.employee}
						basePath='/employee'
						resource='employee'
						{...props}
					/>
				</div>
			</div>
		</>
	);
};

export default CompanyDetails;
