import React, { useEffect, useState } from 'react';
import { TextInput, required, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../../../Settings/settingsStyle';
import { useCreateProjectCommentMutation } from '../../../../generated/graphql';
import {
	COMMENT_CREATE_MESSAGE,
	COMMENT_CREATION_FAILED_MESSAGE,
	COMMENT_UPDATE_MESSAGE,
	COMMENT_UPDATION_FAILED_MESSAGE,
} from '../../constant';

interface FormValue {
	comment?: string;
	id?: string;
	created_by?: string;
	project_id?: string;
	updated_at?: string;
	createdUserId?: string;
	type?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	isEdit?: boolean;
	commentDetails?: any;
	projectId?: string | null;
}
const ProjectCommentsForm = (props: Props) => {
	const { open, onClose, onSuccess, isEdit, commentDetails, projectId } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [createComment] = useCreateProjectCommentMutation();
	const [formValues, setFormValues] = useState<FormValue>();
	const closeModal = () => {
		setFormValues({});
		onClose();
	};
	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		const {
			type,
			createdUserId,
			created_by,
			project_id,
			updated_at,
			...restFormValues
		} = formValues;
		createComment({
			variables: {
				comment: {
					project_id: projectId,
					...restFormValues,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(isEdit ? COMMENT_UPDATE_MESSAGE : COMMENT_CREATE_MESSAGE);
					onSuccess();
					closeModal();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify(
						isEdit
							? COMMENT_UPDATION_FAILED_MESSAGE
							: COMMENT_CREATION_FAILED_MESSAGE,
						'warning'
					);
					return;
				}
			});
		return;
	};
	useEffect(() => {
		if (!commentDetails) {
			return;
		}
		setFormValues(commentDetails);
	}, [commentDetails]);
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{isEdit ? 'Update Note' : 'Add Note'}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form onSubmit={onSubmit} initialValues={formValues}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<TextInput
										multiline={true}
										source='comment'
										label=''
										validate={required()}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine}
										className={
											invalid || pristine
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{isEdit ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectCommentsForm;
