import * as React from "react";
import {
    Create,
    TextInput,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    required,
    useGetList,
    TopToolbar,
    ListButton  
  } from 'react-admin';
import { Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const DesignationCreateActions = ({ basePath }) => {
    return (
      <TopToolbar>
        <ListButton
          basePath={basePath}
          label="Designation List"
          icon={<ChevronLeft />}
        />
      </TopToolbar>
    );
  };

export const CreateDesignation = props => {
    const { data: organizations } = useGetList("organization");
    const orgIds = Object.keys(organizations);
    const selectedOrgId = orgIds.length === 0 ? "" : orgIds[0];
    return(
    <Create title="Add Designation" {...props} actions={<DesignationCreateActions />}>
        <SimpleForm>
            <TextInput source="title" validate={required()} />
            <TextInput source="description" validate={required()}/>
            <Box display="none">
            <ReferenceInput source="org_id" reference="organization" label="Organization"><SelectInput resettable={true} initialValue={selectedOrgId.length ? selectedOrgId : ""}/></ReferenceInput>
            </Box>
        </SimpleForm>
    </Create>
    )};