import React, { useState } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	email,
	regex,
	maxLength,
} from 'react-admin';
import { Button, makeStyles, Box, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import {
	modalFormStyle,
	DialogActions,
	DialogContent,
} from '../../Layout/styles';
import { validatePhoneNumber, validateWebsite } from '../../Utils/string.util';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { contactChoices } from '../Company/Constant';
import {
	useGetActiveUsersByLimitQuery,
	useGetCrmCompanyQuery,
} from '../../generated/graphql';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import ButtonWithLoader from '../../SharedComponents/Btn/ButtonWithLoader';

const useStyles = makeStyles({
	optionalData: {
		display: 'flex',
		cursor: 'pointer',
		color: '#5C5C5C',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	optionalText: {
		fontSize: '12px',
		fontFamily: 'Manrope-Medium',
	},
	expandIcon: {
		color: '#5C5C5C',
	},
	detailsContainer: {
		width: '442px',
		minHeight: '150px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
});

const ContactForm = (props) => {
	const { open, onClose, refetch } = props;
	const classes = modalFormStyle();
	const style = useStyles();
	const [isIconExpanded, setIsIconExpanded] = useState(false);
	const [ownerId, setOwnerId] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [mutate, { loading: isContactFormLoading }] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirectTo = useRedirect();
	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});
	const { data: company } = useGetCrmCompanyQuery();

	const onSave = (value) => {
		mutate(
			{
				type: 'create',
				resource: 'crm_contacts',
				payload: {
					data: {
						...value,
						owner_id: ownerId || null,
						company_id: companyId || null,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					refresh();
					refetch();
					handleClose();
					redirectTo(props.basePath);
					notify('Contacts Created Successfully');
				},
				onFailure: (error) => {
					handleClose();
					notify(`Create failed: ${error.message}`, 'warning');
					redirectTo(props.basePath);
				},
			}
		);
		return;
	};

	const handleClose = () => {
		setIsIconExpanded(false);
		onClose();
	};

	return (
		<Dialog
			disableBackdropClick
			open={open}
			onClose={props.onClose}
			aria-labelledby='dialog-title'
			aria-describedby='dialog-description'
		>
			<Box className={style.detailsContainer}>
				<Box className={classes.headerContainer}>
					<Box className={classes.heading}>Add Contact </Box>
					<CloseIcon className={classes.closeIcon} onClick={handleClose} />
				</Box>
				<Form onSubmit={onSave}>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<Box>
									<Box className={classes.multipleInputContainer}>
										<Box className={classes.multipleInput}>
											<Typography className={classes.label}>
												First Name*
											</Typography>
											<TextInput
												source='first_name'
												fullWidth={true}
												label={''}
												validate={required()}
											/>
										</Box>
										<Box className={classes.multipleInput}>
											<Typography className={classes.label}>
												Last Name
											</Typography>
											<TextInput
												source='last_name'
												label={''}
												fullWidth={true}
											/>
										</Box>
									</Box>
								</Box>
								<Box className={classes.label}>Email</Box>
								<TextInput
									source='email'
									sortable={false}
									label={''}
									fullWidth={true}
									validate={[email()]}
								/>
								<Box className={classes.label}>Company</Box>
								<AutocompleteSearch
									placeholder={'Search Company'}
									option={
										company && company?.crm_company
											? company?.crm_company?.map((value) => {
													return {
														id: value?.id,
														name: value?.name,
													};
											  })
											: []
									}
									onChange={setCompanyId}
									name={'company_id'} 
									validates={false}
								/>
								<Box
									className={style.optionalData}
									onClick={() => setIsIconExpanded(!isIconExpanded)}
								>
									<Box className={style.optionalText}>
										Fill Additional Information(optional)
									</Box>
									<Box className={style.expandIcon}>
										{isIconExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
								</Box>
								{isIconExpanded ? (
									<>
										<Box className={classes.label}>Title</Box>
										<TextInput
											source='title'
											sortable={false}
											label={''}
											fullWidth={true}
										/>
										<Box className={classes.label}> Phone 1</Box>
										<TextInput
											source='primary_phone'
											label={''}
											validate={[
												regex(validatePhoneNumber, 'Must be number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											fullWidth
										/>
										<Box className={classes.label}> Phone 2</Box>
										<TextInput
											source='alternate_phone'
											validate={[
												regex(validatePhoneNumber, 'Must be number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											label={''}
											fullWidth
										/>
										<Box className={classes.label}>Background</Box>
										<TextInput source='background' label={''} fullWidth />
										<Box className={classes.label}>Tags</Box>
										<TextInput source='tags' label={''} fullWidth />
										<Box className={classes.label}>Contact Type</Box>
										<Box flex={1}>
											<SelectInput
												source='contact_type'
												resettable={true}
												label={''}
												fullWidth
												choices={contactChoices}
											/>
											<Box className={classes.label}>BD OWNER</Box>
											<AutocompleteSearch
												placeholder={'Search owner'}
												option={
													activeUsers && activeUsers?.user
														? activeUsers?.user?.map((value) => {
																return {
																	id: value?.id,
																	name: value?.name,
																};
														  })
														: []
												}
												onChange={setOwnerId}
												name={'owner_id'}
												validates={false}
											/>
										</Box>
										<Box className={classes.label}>Linkedin</Box>
										<TextInput
											source='linkedin'
											label={''}
											fullWidth
											validate={[
												regex(validateWebsite, 'Must be a valid Link'),
											]}
										/>
										<Typography className={classes.label}>Address</Typography>
										<TextInput source='address' label={''} fullWidth />
									</>
								) : null}
							</DialogContent>
							<DialogActions>
								<Box className={classes.floatingButtonContainer}>
									<Button
										onClick={handleClose}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<ButtonWithLoader
										isDisabled={invalid || pristine || isContactFormLoading}
										isLoading={isContactFormLoading}
										title='Save'
									/>
								</Box>
							</DialogActions>
						</form>
					)}
				</Form>
			</Box>
		</Dialog>
	);
};

export default ContactForm;
