import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { modalFormStyle } from '../Layout/styles';
import { modalFormTheme } from '../Layout/Theme.component';

const useStyles = makeStyles({
	detailsContainer: {
		width: '500px',
		height: 'fit-content',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 1px/23px Manrope',
		letterSpacing: '0px',
		color: '#dd6e6e',
		fontSize: '17px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	buttonContainer: {
		paddingBottom: '0',
	},
	listContainer: {
		height: '120px',
		overflowY: 'auto',
		marginTop: '20px',
	},
	text: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginBottom: '5px',
	},
});

interface Props {
	onClose: () => void;
	open: boolean;
	heading?: string;
	warningList: string[];
	onConfirm: () => void;
	isLoading?: boolean;
}

const ListWarningModal = (props: Props) => {
	const { open, onClose, heading, warningList, onConfirm, isLoading } = props;
	const formStyles = modalFormStyle();
	const warningModalStyles = useStyles();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={warningModalStyles.detailsContainer}>
					<Typography className={warningModalStyles.heading}>
						{heading ? heading : 'Info!'}
					</Typography>
					<Box className={warningModalStyles.listContainer}>
						<ol>
							{warningList &&
								warningList.map((item) => (
									<li className={warningModalStyles.text}>{item}</li>
								))}
						</ol>
					</Box>
					<Box className={warningModalStyles.buttonAlignment}>
						<Box
							className={`${formStyles.buttonContainer} ${warningModalStyles.buttonContainer}`}
						>
							<Button onClick={onClose} className={formStyles.cancelButton}>
								Cancel
							</Button>
							<Button
								onClick={() =>onConfirm()}
								disabled={isLoading}
								className={
									isLoading
										? `${formStyles.disabledButton}`
										: `${formStyles.saveButton}`
								}
								startIcon={
									isLoading && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#ffffff',
											}}
										/>
									)
								}
							>
								Proceed
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ListWarningModal;
