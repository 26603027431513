import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import './table-antdStyle.css';
import { insightChartTheme } from '../../Layout/Theme.component';

const columns = [
	{
		title: 'SKILL',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'CONSULTANT COUNT',
		dataIndex: 'count',
		key: 'count',
	},
];
interface Props {
	skillReport: any;
	isLoading: boolean;
}
export const ContractorSkillWiseSplitUp = (props: Props) => {
	const { skillReport, isLoading } = props;
	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Skill Report'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer height={355}>
								{skillReport && skillReport?.length > 0 ? (
									<Table
										size='small'
										pagination={false}
										columns={columns}
										dataSource={skillReport}
										scroll={{
											x: '100w',
											y: 300,
										}}
									/>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
