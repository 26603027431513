import React from 'react';
import { Box, Typography, Button, Drawer } from '@material-ui/core';
import { Form } from 'react-final-form';
import { drawerFilterStyle } from '../../Layout/styles';

const ProjectKanbanFilterContainer = (props: any) => {
	const { open, close, children, formValue, choosedFilters, clearFilterValues } = props;

	const classes = drawerFilterStyle();
	const onSubmit = (values: any) => {
		const formData = {
			...values,
		};
		formValue(formData);
		close();
	};

	const clearFilter = () => {
		clearFilterValues()
		formValue([]);
		close();
	};

	return (
		<Drawer open={open} anchor='right' onClose={close}>
			<Box className={classes.drawerContainer}>
				<Box display='flex' flexDirection='column'>
					<Box className={classes.HeaderSection}>
						<Typography className={classes.drawerHeading}>Filters</Typography>
						<Typography
							className={classes.resetButton}
							onClick={() => clearFilter()}
						>
							Clear all
						</Typography>
					</Box>
				</Box>

				<Form onSubmit={onSubmit} initialValues={choosedFilters}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							{children}
							<Box className={classes.buttonContainer}>
								<Box mb={'10px'}>
									<Button
										className={classes.applyFilterButton}
										variant='outlined'
										color='primary'
										type='submit'
									>
										Apply Filters
									</Button>
								</Box>
								<Box mb={'10px'}>
									<Button
										onClick={() => close()}
										className={classes.cancelButton}
										variant='outlined'
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</Drawer>
	);
};

export default ProjectKanbanFilterContainer;
