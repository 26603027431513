import React, { useState } from 'react';

import {
	TextInput,
	useMutation,
	useNotify,
	useRefresh,
	required,
} from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
interface Employee {
	id: string;
	bank_name: string;
	branch_name: string;
	account_number: string;
	ifsc_code: string;
}
interface Props {
	open: boolean;
	onClose: () => void;
	record: Employee;
	refetch: () => void;
}
export const EmployeeBankDetailsEditForm = (props: Props) => {
	const { open, onClose, record: employee, refetch: refetchEmployee } = props;
	const classes = modalFormStyle();
	const [employeeBankDetails, setEmployeeBankDetails] = useState({
		id: employee?.id,
		bank_name: employee?.bank_name,
		branch_name: employee?.branch_name,
		account_number: employee?.account_number,
		ifsc_code: employee?.ifsc_code,
	});
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const saveDetails = () => {
		mutate(
			{
				type: 'update',
				resource: 'employee',
				payload: {
					id: employee?.id,
					data: {
						bank_name: employeeBankDetails?.bank_name,
						branch_name: employeeBankDetails?.branch_name,
						account_number: employeeBankDetails?.account_number,
						ifsc_code: employeeBankDetails?.ifsc_code,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Employee bank details updated successfully');
					onClose();
					refresh();
					refetchEmployee();
				},
				onFailure: (error) => {
					notify(`Updation failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => onClose()}
						/>
					</Box>
					<Form initialValues={employee} onSubmit={saveDetails}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box>
										<Typography className={classes.label}>
											Bank Name*
										</Typography>
										<TextInput
											source='bank_name'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													bank_name: e.target.value,
												});
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Branch Name*
										</Typography>
										<TextInput
											source='branch_name'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													branch_name: e.target.value,
												});
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Account Number*
										</Typography>
										<TextInput
											source='account_number'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													account_number: e.target.value,
												});
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											IFSC Code*
										</Typography>
										<TextInput
											source='ifsc_code'
											label=''
											validate={required()}
											onChange={(e) => {
												setEmployeeBankDetails({
													...employeeBankDetails,
													ifsc_code: e.target.value,
												});
											}}
										/>
									</Box>
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => onClose()}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid ? classes.disabledButton : classes.saveButton
										}
										disabled={invalid}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeeBankDetailsEditForm;
