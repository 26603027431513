import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { maxLength, SelectInput, TextInput } from 'react-admin';
import { ellipsisStyle, modalFormStyle } from '../Layout/styles';
import { Field } from 'react-final-form';
import { DropdownOption } from './Autocompletesearch.component';
import dayjs from 'dayjs';
import { CustomDateInput } from './CustomDateInput.component';

const useStyle = makeStyles({
	dropdown: {
		fontSize: '12px',
		width: '350px',
	},
});
interface CustomFieldProps {
	fieldType: string;
	name: string;
	label?: string;
	fullWidth?: boolean;
	onChange: (event: any) => void;
	option?: DropdownOption[];
	value: DropdownOption | null;
	dateFormat?: string;
}

function CustomField(props: CustomFieldProps) {
	const {
		name,
		label,
		fullWidth = true,
		fieldType = 'text',
		option,
		dateFormat,
	} = props;
	const classes = modalFormStyle();
	const ellipsis = ellipsisStyle();
	const style = useStyle();

	const renderField = (type: string) => {
		switch (type) {
			case 'text':
				return (
					<div>
						{label && (
							<Typography className={classes.label}>{label}</Typography>
						)}
						<TextInput
							source={name}
							fullWidth={fullWidth}
							label={''}
							validate={[maxLength(50)]}
							onChange={() => {}}
						/>
					</div>
				);

			case 'select':
				return (
					<div>
						{label && (
							<Typography className={classes.label}>{label}</Typography>
						)}
						<Field name={name}>
							{(props) => (
								<SelectInput
									source={name}
									label={''}
									choices={option || []}
									validate={false}
									resettable
									onChange={(event) => {
										props.input.onChange(event);
									}}
									optionText={(choice: any) => (
										<Tooltip title={`${choice?.name}`} placement='right'>
											<Typography
												className={`${ellipsis.ellipsis} ${style.dropdown}`}
											>
												{`${choice?.name}`}
											</Typography>
										</Tooltip>
									)}
								/>
							)}
						</Field>
					</div>
				);

			case 'date':
				return (
					<div>
						{label && (
							<Typography className={classes.label}>{label}</Typography>
						)}
						<Field
							name={name}
							validate={(value: any) => {
								if (dayjs(value).isValid() === true || value === null) {
									return undefined;
								} else {
									return 'Invalid Date';
								}
							}}
						>
							{(props: any) => (
								<div>
									<CustomDateInput
										name={props.input.name}
										initialValue={props.input.value || null}
										onChange={props.input.onChange}
										dateFormat={dateFormat || 'YYYY-MM-DD'}
									/>
								</div>
							)}
						</Field>
					</div>
				);

			default:
				return <div></div>;
		}
	};

	return <>{renderField(fieldType)}</>;
}

export default CustomField;
