export const SKILL_EXPORT_COLUMNS = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'SKILL',
	'EXPERTISE',
	'IS_PRIMARY',
	'DESCRIPTION',
];
