import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { SelectInput } from 'react-admin';
import {
	ThemeProvider,
	TextField as TextComponent,
	Box,
	makeStyles,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	modalFormTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { useGetProjectCustomFieldsQuery } from '../../../generated/graphql';

import ClearIcon from '@material-ui/icons/Clear';
import { SearchIcon } from '../../../assets/SvgIcons';
import { DATE_INFO, DEFAULT_LABEL_FOR_CUSTOM_FIELD_MEMU } from '../constant';

const useStyles = makeStyles((theme) => ({
	selectInput: {
		fontFamily: 'Manrope-medium',
		fontSize: '15px',
		width: '210px',
		marginBottom: '5px',
		color: 'rgba(0, 0, 0, 0.54)',
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
		marginTop: '-24px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	dateWarning: {
		marginTop: '-15px',
		fontSize: '10px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	dropdown: {
		fontSize: '12px',
		width: '150px',
	},
}));

interface Props {
	selectedData: string;
	setSelectedData: (value: string) => void;
	selectedCustomValue: number;
	setSelectedCustomValue: (value: number) => void;
}

interface Search {
	onSearch: (value: string) => void;
	searchText: string;
	classes: any;
	placeholder: string;
}

interface CustomField {
	id: number;
	name: string;
}

const CustomSearch = (props: Search) => {
	const { searchText, onSearch, classes, placeholder } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box>
				<TextComponent
					placeholder={`${placeholder}`}
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: searchText?.length > 0 && (
							<ClearIcon
								className={classes.clearIcon}
								onClick={() => {
									onSearch('');
									return;
								}}
							/>
						),
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

const CustomFieldFilter = (props: Props) => {
	const {
		selectedData,
		selectedCustomValue,
		setSelectedData,
		setSelectedCustomValue,
	} = props;
	const classes = useStyles();
	const styles = modalFormStyle();

	// STATE
	const [customField, setCustomField] = useState<CustomField[]>([]);
	const [customFieldType, setCustomFieldType] = useState<string>('');

	// QUERIES
	const { data: customFields } = useGetProjectCustomFieldsQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!customFields) {
			return;
		}
		const fields = customFields?.custom_field?.map((value: any) => {
			return { id: value?.index, name: value?.label };
		});
		setCustomField(fields);
	}, [customFields]);

	return (
		<Box>
			<Form onSubmit={() => {}}>
				{() => (
					<Box className={classes.wrapper}>
						<Box>
							<Box className={styles.label}>Custom Field</Box>
							<ThemeProvider theme={modalFormTheme}>
								<SelectInput
									source={' Select Custom Field'}
									label={''}
									className={classes.selectInput}
									choices={
										customField && customField?.length > 0
											? [
													{ id: 0, name: DEFAULT_LABEL_FOR_CUSTOM_FIELD_MEMU },
													...customField,
											  ]
											: []
									}
									onChange={(event) => {
										if (!event || !event.target) {
											return;
										}
										setSelectedCustomValue(Number(event?.target?.value));
										setSelectedData('');
										const selectedField = customFields?.custom_field?.find(
											(field: { index: string }) =>
												field.index === event.target.value
										);
										if (!selectedField) {
											return;
										}
										const selectedFieldType = selectedField?.type;
										setCustomFieldType(selectedFieldType);
									}}
									defaultValue={selectedCustomValue}
									optionText={(choice: any) =>
										choice.name === DEFAULT_LABEL_FOR_CUSTOM_FIELD_MEMU ? (
											<Typography
												className={`${classes.ellipsis} ${classes.dropdown}`}
											>
												{`${choice?.name || '- -'}`}
											</Typography>
										) : (
											<Tooltip
												title={`${choice?.name || '- -'}`}
												placement='right'
											>
												<Typography
													className={`${classes.ellipsis} ${classes.dropdown}`}
												>
													{`${choice?.name || '- -'}`}
												</Typography>
											</Tooltip>
										)
									}
								/>
							</ThemeProvider>
						</Box>
						{selectedCustomValue ? (
							<Box>
								<Box>
									{customFieldType && customFieldType === 'date' && (
										<p className={classes?.dateWarning}>{DATE_INFO}</p>
									)}
								</Box>
								<Box>
									<CustomSearch
										classes={classes}
										searchText={selectedData}
										placeholder={`Search  value`}
										onSearch={(value: string) => setSelectedData(value)}
									/>
								</Box>
							</Box>
						) : null}
					</Box>
				)}
			</Form>
		</Box>
	);
};

export default CustomFieldFilter;
