import React, { useContext, useState, useEffect } from 'react';
import {
	TextInput,
	useNotify,
	required,
	ReferenceInput,
	SelectInput,
	AutocompleteInput,
	useQuery,
} from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { UPDATE_OPPORTUNITY, GET_USERS } from '../Opportunities.gql';
import { kanbanBoardInvitedUser } from './modal';

import { Form, Field } from 'react-final-form';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import { modalFormStyle, currencyInputStyle } from '../../../Layout/styles';
import AddCompany from '../AddCompany.component';
import AddContact from '../AddContact.component';
import { UserProfileContext } from '../../../App';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../../SharedComponents/gqlQueries';
import { OrganizationSupportedCurrencies } from '../../../SharedComponents/model';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';
import _, { orderBy } from 'lodash';

interface Record {
	currency_id?: string;
	status?: string;
	name?: string;
	win_percentage?: number;
	value?: number;
	priority?: string;
	close_date?: string;
	company_id?: string;
	primary_contact_id?: string;
	owner_id?: string;
	lead_owner_id?: string;
	description?: string;
	kanban_id?: string;
	id?: string;
	source?: string;
	commission?: string;
	kanban_board_id?: string;
	kamban_id?: string;
	loss_reason_id?: string;
}

interface Name {
	first_name: string;
	last_name: string;
	full_name: string;
	name: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	record: Record;
	refetch: () => void;
	boardId: string | undefined;
}
interface OpportunityStatus {
	board_id: string;
	created_at: Date;
	created_by?: any;
	description?: any;
	id: string;
	limit: number;
	name: string;
	ordinal: number;
	state?: any;
	updated_at: Date;
	updated_by?: any;
}
const mapToFullName = (choice: Name) => `${choice?.name || ''}`;

const mapToFullUserName = (choice: Name) =>
	choice?.name || `${choice?.first_name} ${choice?.last_name || ''}`;

const EditDetails = (props: Props) => {
	const { open, onClose, record, refetch, boardId } = props;
	const {
		orgId: organizationId,
		dateFormat,
		orgBaseCurrency,
	} = useContext<any>(UserProfileContext);
	const classes = modalFormStyle();
	const style = currencyInputStyle();
	const [opportunityDetails, setOpportunityDetails] = useState<Record>({});
	const [companyId, setCompanyId] = useState('');
	const [contactId, setContactId] = useState('');
	const [isAddCompanyFormShown, setIsAddCompanyFormShown] = useState(false);
	const [isAddContactFormShown, setIsAddContactFormShown] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [contactName, setContactName] = useState('');
	const [currencyList, setCurrencyList] = useState<
		{ id: string; name: string }[]
	>([]);
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);
	const notify = useNotify();
	const [disableLoss, setDisableLoss] = useState(false);
	const { data: opportunitiesStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'kanban_column',
		payload: {
			filter: {
				board_id: record?.kanban_board_id,
			},
		},
	});
	const [
		updateOpportunity,
		{ data: opportunityData, loading: isUpdateOpportunityLoading },
	] = useApolloMutation(UPDATE_OPPORTUNITY);

	const { data: organisationSupportedCurrencies } = useApolloQuery<any>(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: users } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%%`,
			boardId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	useEffect(() => {
		if (!users) {
			return;
		}
		const userSuggestion = users.kanban_board_invited_users.map(
			(user: kanbanBoardInvitedUser) => {
				return {
					id: user?.user?.id,
					name: `${user?.user?.full_name} `,
				};
			}
		);
		const sortedUserSuggestion: any = orderBy(
			userSuggestion,
			[(user) => user.name.toUpperCase()],
			['asc']
		);
		setMentionSuggestions(sortedUserSuggestion);
	}, [users]);

	useEffect(() => {
		if (!organisationSupportedCurrencies || !orgBaseCurrency) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);
		setCurrencyList(
			_.uniqBy([...organizationCurrencyChoices, orgBaseCurrency], 'id')
		);
	}, [organisationSupportedCurrencies, orgBaseCurrency]);

	useEffect(() => {
		if (!record) {
			return;
		}
		setOpportunityDetails({
			...record,
		});
		setOwnerId(record?.lead_owner_id || null);
		setPresalesOwnerId(record?.owner_id || null);
	}, [record]);

	useEffect(() => {
		if (opportunityData) {
			refetch();
			notify('Opportunities Updated Successfully');
		}
	}, [opportunityData, notify, refetch]);

	useEffect(() => {
		if (!opportunitiesStatus) {
			return;
		}
		const lossState = opportunitiesStatus?.filter(
			(opportunity: OpportunityStatus) => opportunity?.state === 'loss'
		);
		if (opportunityDetails?.status === lossState[0]?.id) {
			setDisableLoss(false);
		} else {
			setDisableLoss(true);
		}
	}, [opportunitiesStatus, opportunityDetails]);

	useEffect(() => {
		if (companyId) {
			record.company_id = companyId;
			setOpportunityDetails({
				...opportunityDetails,
				company_id: companyId,
			});
			setCompanyId('');
		}
		if (contactId) {
			record.primary_contact_id = contactId;
			setOpportunityDetails({
				...opportunityDetails,
				primary_contact_id: contactId,
			});
			setContactId('');
		}
	}, [opportunityDetails, companyId, record, contactId]);

	const saveDetails = () => {
		updateOpportunity({
			variables: {
				id: opportunityDetails?.id,
				kanbanId: record?.kanban_id,
				data: {
					name: opportunityDetails?.name,
					currency_id: opportunityDetails?.currency_id,
					win_percentage: opportunityDetails?.win_percentage || 0,
					priority: opportunityDetails?.priority,
					close_date: opportunityDetails?.close_date,
					value: opportunityDetails?.value || 0,
					company_id: opportunityDetails?.company_id,
					primary_contact_id: opportunityDetails?.primary_contact_id || null,
					owner_id: presalesOwnerId || null,
					lead_owner_id: ownerId || null,
					loss_reason_id:
						opportunityDetails?.loss_reason_id === ''
							? null
							: opportunityDetails?.loss_reason_id,
				},
				kanData: {
					column_id: opportunityDetails?.status,
				},
			},
		});
		onClose();
	};

	const handleCompany = (companyName: string) => {
		setIsAddCompanyFormShown(true);
		setCompanyName(companyName);
	};

	const handleContact = (contactName: string) => {
		setIsAddCompanyFormShown(false);
		setIsAddContactFormShown(true);
		setContactName(contactName);
	};

	const handleClose = () => {
		onClose();
		setOpportunityDetails({
			...record,
		});
	};

	const handleCompanyContactClose = () => {
		if (isAddCompanyFormShown) {
			setIsAddCompanyFormShown(false);
		}
		if (isAddContactFormShown) {
			setIsAddContactFormShown(false);
		}
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box width='464px' height='700px' borderRadius='4px'>
					<Box className={style.headerContainer}>
						<Typography className={style.heading}>
							Edit Basic Details
							{isAddCompanyFormShown ? (
								<>
									<DoubleArrowIcon className={style.arrowIcon} />
									<Box className={style.subHeading}>Add Company</Box>
								</>
							) : null}
							{isAddContactFormShown ? (
								<>
									<DoubleArrowIcon className={style.arrowIcon} />
									<Box className={style.subHeading}>Add Contact</Box>
								</>
							) : null}
						</Typography>
						{isAddCompanyFormShown || isAddContactFormShown ? (
							<CloseIcon
								className={classes.closeIcon}
								onClick={handleCompanyContactClose}
							/>
						) : (
							<CloseIcon className={classes.closeIcon} onClick={handleClose} />
						)}
					</Box>
					<DialogContent>
						<Box className={style.formContainer}>
							{!isAddCompanyFormShown && !isAddContactFormShown ? (
								<Form initialValues={opportunityDetails} onSubmit={saveDetails}>
									{({ handleSubmit, invalid }) => (
										<form onSubmit={handleSubmit}>
											<Typography className={classes.label}>
												Opportunity Name
											</Typography>
											<TextInput
												source='name'
												label=''
												validate={required()}
												onChange={(e: any) => {
													setOpportunityDetails({
														...opportunityDetails,
														name: e.target.value,
													});
												}}
											/>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Stage</Box>
													<ReferenceInput
														source='status'
														reference='kanban_column'
														sort={{ field: 'name', order: 'ASC' }}
														label={''}
														filter={{
															board_id: record?.kanban_board_id,
														}}
														onChange={(event) => {
															setOpportunityDetails({
																...opportunityDetails,
																status: event?.target?.value,
																loss_reason_id: '',
															});
														}}
													>
														<SelectInput label='Status' />
													</ReferenceInput>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Loss Reason</Box>
													<ReferenceInput
														source='loss_reason_id'
														reference='opportunity_loss_reason'
														label={''}
														onChange={(event) => {
															setOpportunityDetails({
																...opportunityDetails,
																loss_reason_id: event?.target?.value,
															});
														}}
													>
														<SelectInput
															optionText='label'
															disabled={disableLoss}
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Win %</Box>
													<TextInput
														source='win_percentage'
														label={''}
														name='win_percentage'
														onChange={(event: any) => {
															setOpportunityDetails({
																...opportunityDetails,
																win_percentage: event.target.value,
															});
														}}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Priority
													</Typography>
													<SelectInput
														source='priority'
														label={false}
														validate={required()}
														fullWidth
														choices={[
															{ id: 'Low', name: 'Low' },
															{ id: 'Medium', name: 'Medium' },
															{ id: 'High', name: 'High' },
														]}
														onChange={(e: any) => {
															setOpportunityDetails({
																...opportunityDetails,
																priority: e.target.value,
															});
														}}
													/>
												</Box>
											</Box>
											<Box className={style.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Value</Box>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Close Date</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={style.boxContainer}>
													<Box className={style.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<SelectInput
																source='currency_id'
																label=''
																choices={currencyList || []}
																onChange={(e: any) => {
																	setOpportunityDetails({
																		...opportunityDetails,
																		currency_id: e.target.value,
																	});
																}}
															/>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='value'
															label={''}
															onChange={(e: any) => {
																setOpportunityDetails({
																	...opportunityDetails,
																	value: e?.target?.value,
																});
															}}
														/>
													</ThemeProvider>
												</Box>
												<Box>
													<Field
														name='close_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div className={style.dateInput}>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value: any) => {
																		props.input.onChange(value);
																		setOpportunityDetails({
																			...opportunityDetails,
																			close_date: value,
																		});
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Lead Owner
													</Typography>
													<AutocompleteSearch
														placeholder={'Search Lead Owner'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={setOwnerId}
														name={'lead_owner_id'}
														validates={false}
														value={getOwnerValue(ownerId || '')}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Pre-Sales Owner
													</Typography>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={mentionSuggestions || []}
														onChange={setPresalesOwnerId}
														name={'owner_id'}
														validates={false}
														value={getOwnerValue(presalesOwnerId || '')}
													/>
												</Box>
											</Box>

											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Company Name
													</Typography>
													<ReferenceInput
														source='company_id'
														reference='crm_company'
														sort={{ field: 'name', order: 'ASC' }}
														label={''}
														fullWidth
														filterToQuery={(searchText) => ({
															name: searchText,
														})}
														onChange={(event) => {
															setOpportunityDetails({
																...opportunityDetails,
																company_id: event || null,
															});
														}}
														onCreate={(event: any) => handleCompany(event)}
													>
														<AutocompleteInput
															optionText={mapToFullName}
															resettable={true}
															shouldRenderSuggestions={(name: any) => {
																return name.trim().length > 0;
															}}
															onInputValueChange={(e: any) => {
																if (!e) {
																	record.primary_contact_id = '';
																	setOpportunityDetails({
																		...opportunityDetails,
																		primary_contact_id: '',
																	});
																}
															}}
														/>
													</ReferenceInput>
												</Box>
												<Box className={classes.multipleInput}>
													<Typography className={classes.label}>
														Contact Name
													</Typography>
													<ReferenceInput
														source='primary_contact_id'
														reference='crm_contacts'
														label={''}
														sort={{ field: 'first_name', order: 'ASC' }}
														fullWidth
														filterToQuery={(searchText) => ({
															full_name: searchText,
														})}
														onChange={(event) => {
															setOpportunityDetails({
																...opportunityDetails,
																primary_contact_id: event || null,
															});
														}}
														onCreate={(event: any) => handleContact(event)}
													>
														<AutocompleteInput
															optionText={mapToFullUserName}
															resettable={true}
															shouldRenderSuggestions={(name: any) =>
																name?.trim().length > 0
															}
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box className={classes.customButtonContainer} mt={4}>
												<Button
													className={
														invalid || isUpdateOpportunityLoading
															? classes.disabledButton
															: classes.updateButton
													}
													disabled={invalid || isUpdateOpportunityLoading}
													type='submit'
												>
													Update
												</Button>
												<Button
													onClick={handleClose}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
											</Box>
										</form>
									)}
								</Form>
							) : isAddCompanyFormShown && !isAddContactFormShown ? (
								<AddCompany
									onClose={() => setIsAddCompanyFormShown(false)}
									companyName={companyName}
									setCompanyId={(id) => setCompanyId(id)}
								/>
							) : (
								<AddContact
									onClose={() => setIsAddContactFormShown(false)}
									contactName={contactName}
									setContactId={(id) => setContactId(id)}
									companyId={opportunityDetails?.company_id}
								/>
							)}
						</Box>
					</DialogContent>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditDetails;
