import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	makeStyles,
	ThemeProvider,
	TextField as TextComponent,
	Button,
	createTheme,
	Tooltip,
	withStyles,
	LinearProgress,
	IconButton,
	Chip,
	Typography,
	Checkbox,
	CircularProgress,
	Paper,
	Select,
	MenuItem,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MUIDataTable, { FilterType, MUIDataTableOptions } from 'mui-datatables';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';

import { SelectInput, setSidebarVisibility, useNotify } from 'react-admin';
import { isEmpty, orderBy, sortBy } from 'lodash';
import { SearchIcon } from '../../assets/SvgIcons';
import ClearIcon from '@material-ui/icons/Clear';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { useQuery as useApolloQuery } from '@apollo/client';

import {
	GET_PROJECT_CUSTOM_FIELDS,
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import { UserProfileContext } from '../../App';
import {
	autocompleteStyle,
	dropDownEllipsisStyle,
	ellipsisStyle,
	labelStyle,
} from '../../Layout/styles';
import AddIcon from '@material-ui/icons/Add';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EmployeeListModal from './EmployeeListModal.component';
import { useHistory } from 'react-router-dom';
import { calculateDateDifference } from '../../Utils/date-time.util';
import {
	FILTER_STATE_ACTIONS,
	PAGINATION_STATE_ACTIONS,
} from '../../globalState';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import {
	customAutoCompleteSearchBarTheme,
	dataGridFilterDropdownTheme,
	modalFormTheme,
	searchBarTheme,
} from '../../Layout/Theme.component';
import { excelDownload } from '../../Utils/string.util';
import { autocompleteMultiSelectLabelStyle } from './style';
import {
	ALL_PROJECTS,
	DATE_INFO,
	EXPIRED_PROJECTS,
	EXPIRED_PROJECTS_IN_N_DAYS,
	PROJECT_EXPORT_MESSAGE,
	projectRisk,
	riskStatusOptions,
} from './constant';
import {
	useGetAllProjectOwnersQuery,
	useGetCrmCompaniesQuery,
	useGetProjectCategoriesQuery,
	useGetProjectDataGridListsQuery,
	useGetProjectIdsFromProjectListQuery,
	useGetProjectLabelsQuery,
	useGetProjectResourcesQuery,
	useGetProjectStatusInProjectListQuery,
	useGetProjectTypeQuery,
} from '../../generated/graphql';
import ProjectForm from '../ResourcePlanner/ProjectForm.component';
import { statusSelectBoxTheme } from '../ProjectTask/dataGridThemes';
import ProjectRiskChangeForm from './ProjectRiskChangeModal.component';
import { exportProjectListData } from '../../Api/restApi.config';

const customizedDataGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			regular: {
				minHeight: '0px !important',
			},
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				maxWidth: '100vw',
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			titleText: {
				display: 'none',
			},
			actions: {},
			icon: {
				position: 'absolute',
				top: '-69px',
				right: '-28px',
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
			iconActive: {
				position: 'absolute',
				top: '-63px',
				right: '-28px',
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
				width: '200px',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-bold',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '520px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});
const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);
const LinerProgressTask = withStyles((theme) => ({
	root: {
		height: 7,
		borderRadius: 5,
		width: '50px',
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#3ABC9E',
	},
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},

	selectInput: {
		fontFamily: 'Manrope-medium',
		fontSize: '15px',
		width: '100px',
		marginBottom: '5px',
		color: 'rgba(0, 0, 0, 0.54)',
	},

	dateWarning: {
		marginTop: '-55px',
		fontSize: '11px',
	},
	searchBox: {
		marginBottom: '-10px',
	},
	dropdown: {
		fontSize: '12px',
		width: '350px',
	},
	navigationTabContainer: {
		width: '610px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
	prefixName: {
		fontSize: '12px',
		width: 'auto',
		fontFamily: 'Manrope-medium',
		fontWeight: 'bold',
		color: 'grey',
		opacity: '0.8',
	},
	projectName: {
		fontSize: '12px',
		maxWidth: '100px',
		fontFamily: 'Manrope-medium',
		opacity: '0.8',
		color: '#292929',
	},
	resourceCount: {
		fontSize: '12px',
		width: '50px',
		textAlign: 'center',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	companyDropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	resourceCountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '125px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
		whiteSpace: 'nowrap',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-72px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	companyDropListCount: {
		marginTop: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
			maxWidth: '150px',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
			maxWidth: '150px',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
			maxWidth: '150px',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	dateEndProject: {
		color: '#EA4335',
		fontSize: '12px !important',
		fontFamily: 'Manrope',
	},
	leftProjectDate: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
	expiryFilterStatus: {
		background: '#ffffff',
		height: '36px',
		alignItems: 'left',
		justifyContent: 'flex-start',
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	menuIcon: {
		height: '18px',
		width: '18px',
	},
	copyContainer: {
		'&:hover': {
			'& > svg': {
				opacity: '1',
			},
		},
	},
	copyIcon: {
		opacity: '0',
		height: '16px',
		width: '16px',
		color: '#1d82f5',
		marginLeft: '10px',
	},
	taskButton: {
		borderRight: '1px solid #E0E0E0',
	},
	projectStatus: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '-2px',
	},
	cursor: {
		cursor: 'pointer',
	},
}));
const dataGridStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		maxHeight: '430px',
		overflowY: 'auto',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		color: 'grey',
		backgroundColor: 'lightgrey',
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
	loadingContainer: {
		marginBottom: '100px !important',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
	},
	dropdownContainer: {
		marginTop: '50px',
	},
}));
interface Search {
	onSearch: (value: string) => void;
	searchText: string;
	classes: any;
	placeholder: string;
}
interface Project {
	[key: string]: string | undefined;
}
const CustomSearch = (props: Search) => {
	const { searchText, onSearch, classes, placeholder } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box>
				<TextComponent
					placeholder={`${placeholder}`}
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
						endAdornment: searchText?.length > 0 && (
							<ClearIcon
								className={classes.clearIcon}
								onClick={() => {
									onSearch('');
									return;
								}}
							/>
						),
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};
interface ProjectDataGridListProps {
	setProjectListCount: (arg: number) => void;
}
interface OptionType {
	id: string;
	name: string;
}

const ProjectDataGridList = (props: ProjectDataGridListProps) => {
	const { setProjectListCount } = props;
	const classes = useStyles();
	const styles = ellipsisStyle();
	const dropDownStyles = autocompleteStyle();
	const dropdownStyle = labelStyle();
	const inlinestyle = autocompleteMultiSelectLabelStyle();
	const notify = useNotify();
	const filter = createFilterOptions();

	const {
		permissions,
		dateFormat,
		filterState,
		dispatchFilter,
		featureFlag,
		paginationState,
		dispatchPaginationState,
	} = useContext<any>(UserProfileContext);
	const dispatch = useDispatch();
	const filterType: FilterType = 'custom';
	const [projectId, setProjectId] = useState<string>('');
	const [projectIdForRiskStatus, setProjectIdForRiskStatus] = useState<
		string | null
	>(null);
	const [selectedRiskStatus, setSelectedRiskStatus] = useState<string | null>(
		null
	);
	const [isProjectFormModalShown, setIsProjectFormModalShown] = useState(false);
	const [isProjectRiskChangeModalShown, setIsProjectRiskChangeModalShown] =
		useState(false);
	const [projectStatusOptions, setProjectStatusOptions] = useState([]);
	const history = useHistory();
	const ellipsis = dropDownEllipsisStyle();
	const formatUrl = window?.location?.href?.replace(/[?]/g, '/').split('/');
	const path = formatUrl[4];
	const [isEmployeesListModalShown, setIsEmployeesListModalShown] =
		useState(false);
	const [projectLabelsOptions, setProjectLabelsOptions] = useState<any[]>([]);
	const [projectCategoryOptions, setProjectCategoryOptions] = useState<any[]>(
		[]
	);
	const [companies, setCompanies] = useState<any>([]);
	const [selectedCompaniesOptionValue, setSelectedCompaniesOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedCompaniesIdList, setSelectedCompaniesIdList] = useState<any[]>(
		[]
	);
	const [projectOwner, setProjectOwner] = useState<any[]>([]);
	const [selectedProjectOwnerOptionValue, setSelectedProjectOwnerOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedProjectOwnerIdList, setSelectedProjectOwnerIdList] = useState<
		any[]
	>([]);
	const [customField, setCustomField] = useState<any[]>([]);

	const [selectedCustomValue, setSelectedCustomValue] = useState<String>('');
	const [customFieldType, setCustomFieldType] = useState('');

	const [selectedData, setSelectedData] = useState<string>('');

	const [projectType, setProjectType] = useState<any>([]);
	const [selectedProjectTypeOptionValue, setSelectedProjectTypeOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedProjectTypeIdList, setSelectedProjectTypeIdList] = useState<
		any[]
	>([]);
	const [selectedProjectRiskOptionValue, setSelectedProjectRiskOptionValues] =
		useState<
			{ id?: string | null | undefined; name?: string | null | undefined }[]
		>([]);
	const [selectedProjectRiskIdList, setSelectedProjectRiskIdList] =
		useState<any>([]);
	const [resourceData, setResourceData] = useState(null);
	const [projectResource, setProjectResource] = useState<any[]>([]);

	const [isLoading, setLoading] = useState(false);
	const dropDownStyle = autocompleteStyle();
	const allSelected =
		projectStatusOptions.length === filterState?.projectStatusFilter?.length ||
		0
			? true
			: false;
	const allProjectLabelSelected =
		projectLabelsOptions?.length === filterState?.projectLabelFilter?.length ||
		0
			? true
			: false;
	const allProjectCategorySelected =
		projectCategoryOptions?.length ===
			filterState?.projectCategoryFilter?.length || 0
			? true
			: false;
	const allResourceSelected =
		projectResource?.length === filterState?.projectResourceFilter?.length || 0
			? true
			: false;
	const getProjectExpiryFilter = () => {
		if (filterState?.projectExpiryFilter) {
			if (filterState?.projectExpiryFilter === EXPIRED_PROJECTS) {
				return { _lt: 'today()' };
			}
			if (filterState?.projectExpiryFilter === ALL_PROJECTS) {
				return {};
			}
			return {
				_gte: 'today()',
				_lte: dayjs().add(10, 'day').format('YYYY-MM-DD'),
			};
		}
		return {};
	};

	const getCompanyFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedCompaniesOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedCompaniesOptionValue &&
				selectedCompaniesOptionValue?.length === 1
					? selectedCompaniesOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getProjectOwnerFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedProjectOwnerOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedProjectOwnerOptionValue &&
				selectedProjectOwnerOptionValue?.length === 1
					? selectedProjectOwnerOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getCustomFieldFilter = (index: number | String, searchData: string) => {
		if (typeof index === 'number' && index >= 1 && index <= 9 && searchData) {
			const fieldName = `custom_field_${index}`;
			return {
				[fieldName]: searchData
					? { _ilike: `%${searchData}%` }
					: { _is_null: true },
			};
		}

		return {};
	};

	const getProjectRiskFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedProjectRiskOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedProjectRiskOptionValue &&
				selectedProjectRiskOptionValue?.length === 1
					? selectedProjectRiskOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getProjectTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedProjectTypeOptionValue?.filter(
			(option: {
				id?: string | null | undefined;
				name?: string | null | undefined;
			}) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedProjectTypeOptionValue &&
				selectedProjectTypeOptionValue?.length === 1
					? selectedProjectTypeOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const projectFilterWithResourceMappingForProjectList = {
		project_resource_mapping: {
			employee_id:
				filterState?.projectResourceFilter &&
				filterState?.projectResourceFilter?.length > 0
					? {
							_in: filterState?.projectResourceFilter.map(
								(resource: any) => resource?.value
							),
					  }
					: {},
		},
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectSearchText || ''}%`,
				},
			},
		],
		end_date: getProjectExpiryFilter(),

		...getCustomFieldFilter(selectedCustomValue, selectedData),
		project_status_id:
			filterState?.projectStatusFilter &&
			filterState?.projectStatusFilter?.length > 0
				? {
						_in: filterState?.projectStatusFilter.map(
							(status: any) => status?.value
						),
				  }
				: {
						_in: [],
				  },
		company_id:
			selectedCompaniesIdList.length > 0
				? { _in: selectedCompaniesIdList ?? [] }
				: {},
		project_owner_id:
			selectedProjectOwnerIdList.length > 0
				? { _in: selectedProjectOwnerIdList ?? [] }
				: {},
		risk_status:
			!isEmpty(selectedProjectRiskOptionValue) &&
			selectedProjectRiskOptionValue?.find((option) => option?.id === 'All')
				? {}
				: selectedProjectRiskIdList.length > 0
				? { _in: selectedProjectRiskIdList ?? [] }
				: {},
		project_type:
			selectedProjectTypeIdList.length > 0
				? { _in: selectedProjectTypeIdList ?? [] }
				: {},
		tag_id: allProjectLabelSelected
			? {}
			: filterState?.projectLabelFilter &&
			  filterState?.projectLabelFilter?.length > 0
			? {
					_in: filterState?.projectLabelFilter?.map((item: any) => item?.value),
			  }
			: {},
		category_id: allProjectCategorySelected
			? {}
			: filterState?.projectCategoryFilter &&
			  filterState?.projectCategoryFilter?.length > 0
			? {
					_in: filterState?.projectCategoryFilter.map(
						(category: any) => category.value
					),
			  }
			: {},
	};
	const projectFilterWithoutResourceMappingForProjectList = {
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectSearchText || ''}%`,
				},
			},
		],
		end_date: getProjectExpiryFilter(),

		...getCustomFieldFilter(selectedCustomValue, selectedData),

		project_status_id:
			filterState?.projectStatusFilter &&
			filterState?.projectStatusFilter?.length > 0
				? {
						_in: filterState?.projectStatusFilter.map(
							(status: any) => status?.value
						),
				  }
				: {
						_in: [],
				  },

		company_id:
			selectedCompaniesIdList.length > 0
				? { _in: selectedCompaniesIdList ?? [] }
				: {},
		project_owner_id:
			selectedProjectOwnerIdList.length > 0
				? { _in: selectedProjectOwnerIdList ?? [] }
				: {},
		risk_status:
			!isEmpty(selectedProjectRiskOptionValue) &&
			selectedProjectRiskOptionValue?.find((option) => option?.id === 'All')
				? {}
				: selectedProjectRiskIdList.length > 0
				? { _in: selectedProjectRiskIdList ?? [] }
				: {},
		project_type:
			selectedProjectTypeIdList.length > 0
				? { _in: selectedProjectTypeIdList ?? [] }
				: {},
		tag_id: allProjectLabelSelected
			? {}
			: filterState?.projectLabelFilter &&
			  filterState?.projectLabelFilter?.length > 0
			? {
					_in: filterState?.projectLabelFilter?.map((item: any) => item?.value),
			  }
			: {},
		category_id: allProjectCategorySelected
			? {}
			: filterState?.projectCategoryFilter &&
			  filterState?.projectCategoryFilter?.length > 0
			? {
					_in: filterState?.projectCategoryFilter.map(
						(category: any) => category.value
					),
			  }
			: {},
	};

	const exportProjectData = async (file: any) => {
		if (!file) {
			return;
		}
		setLoading(true);
		const data =
			filterState?.projectResourceFilter &&
			filterState?.projectResourceFilter?.length > 0
				? projectFilterWithResourceMappingForProjectList
				: projectFilterWithoutResourceMappingForProjectList;

		exportProjectListData(data)
			.then((res: { data: { error: any } }) => {
				if (res?.data?.error) {
					notify(PROJECT_EXPORT_MESSAGE, 'warning');
					setLoading(false);
					return;
				}

				excelDownload(res.data, 'Project Export');
				setLoading(false);
				return res.data;
			})
			.catch(
				(err: {
					response: {
						message: any;
						data: { message: React.SetStateAction<string> };
					};
				}) => {
					notify(PROJECT_EXPORT_MESSAGE, 'warning');
					setLoading(false);
				}
			);
	};
	const { data: crmCompanies } = useGetCrmCompaniesQuery();
	const { data: projectOwners } = useGetAllProjectOwnersQuery();
	const { data: type } = useGetProjectTypeQuery();
	const { data: customFields } = useApolloQuery(GET_PROJECT_CUSTOM_FIELDS);

	const { data: filteredProjectIdList } = useGetProjectIdsFromProjectListQuery({
		fetchPolicy: 'network-only',
		variables: {
			project:
				filterState?.projectResourceFilter &&
				filterState?.projectResourceFilter?.length > 0
					? projectFilterWithResourceMappingForProjectList
					: projectFilterWithoutResourceMappingForProjectList,
		},
	});

	useEffect(() => {
		if (!type || !type?.project_list) {
			return;
		}
		const projectsType = type?.project_list.map((value: any) => {
			return {
				id: value?.project_type,
				name: value?.project_type,
			};
		});
		const sortedProjectsType: any = orderBy(projectsType, ['name'], ['asc']);
		setProjectType(sortedProjectsType);
	}, [type]);

	useEffect(() => {
		const fields = customFields?.custom_field?.map((value: any) => {
			return { id: value?.index, name: value?.label };
		});
		setCustomField(fields);
	}, [customFields]);

	useEffect(() => {
		if (!projectOwners || !(projectOwners?.project_list?.length > 0)) {
			return;
		}
		const projectOwnerList = projectOwners?.project_list.map((project: any) => {
			return {
				id: project.ProjectOwner?.id,
				name: `${project.ProjectOwner?.full_name} `,
			};
		});
		const sortedResponse = orderBy(
			projectOwnerList,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setProjectOwner(sortedResponse);
	}, [projectOwners]);
	useEffect(() => {
		if (!crmCompanies || !(crmCompanies?.companies?.length > 0)) {
			return;
		}
		const companyNames = crmCompanies?.companies.map((company: any) => {
			return {
				id: company.id,
				name: company.name,
			};
		});
		setCompanies(companyNames);
	}, [crmCompanies]);
	const {
		data: filteredProjects,
		loading: isFilteredProjectLoading,
		refetch: refetchFilteredProjects,
	} = useGetProjectDataGridListsQuery({
		variables: {
			project:
				filteredProjectIdList && filteredProjectIdList?.project_list?.length > 0
					? {
							id: {
								_in: filteredProjectIdList?.project_list?.map(
									(project) => project?.id
								),
							},
					  }
					: { id: { _in: [] } },
			limit: paginationState?.projectPerPagePagination ?? 15,
			offset:
				(paginationState?.projectPagePagination ?? 0) *
				(paginationState?.projectPerPagePagination ?? 15),
			sortFilter: filterState?.projectSortFilter ?? [],
		},
		onCompleted: (data) => {
			setProjectListCount(data?.project_aggregate?.aggregate?.count ?? 0);
		},
		fetchPolicy: 'network-only',
		skip: !filteredProjectIdList,
	});

	const { data: projectLabels } = useGetProjectLabelsQuery({
		fetchPolicy: 'network-only',
	});
	const { data: projectCategories } = useGetProjectCategoriesQuery({
		fetchPolicy: 'network-only',
	});

	const { data: projectStatusList, refetch: refetchProjectStatus } =
		useGetProjectStatusInProjectListQuery({
			fetchPolicy: 'network-only',
		});
	const { data: resourceList } = useGetProjectResourcesQuery({
		skip: path !== 'project',
		fetchPolicy: 'network-only',
	});

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !filterState?.projectUserTeamFilter
				? {}
				: {
						_eq: filterState?.projectUserTeamFilter?.id,
				  },
		},
		skip: !filterState?.projectUserTeamFilter,
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: any) => {
				return {
					value: user?.user?.id,
					label: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList: any[] = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setProjectResource(sortedAssigneeList);
		if (filterState?.projectUserTeamFilter) {
			const availableResourceIdList = sortedAssigneeList.map(
				(option: any) => option.value
			);
			const selectedResources = filterState.projectResourceFilter?.filter(
				(option: any) => availableResourceIdList.includes(option.value)
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
				payload: selectedResources,
			});
		}
		// eslint-disable-next-line
	}, [dispatchFilter, filterState.projectUserTeamFilter, usersByTeam]);

	useEffect(() => {
		if (!resourceList || isEmpty(resourceList?.project_resource_mapping)) {
			setProjectResource([]);
			return;
		}
		if (filterState?.projectUserTeamFilter) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: any) => {
					return {
						value: user?.user?.id,
						label: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList: any = orderBy(
				useTeamAssignees,
				['label'],
				['asc']
			);
			setProjectResource(sortedAssigneeList);
			return;
		}
		const response = resourceList?.project_resource_mapping?.map(
			(userList: any) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		const sortedResponse: any = orderBy(
			response,
			[(user) => user.label?.toUpperCase()],
			['asc']
		);
		setProjectResource(sortedResponse);
	}, [filterState, resourceList, usersByTeam]);
	useEffect(() => {
		if (!projectLabels || isEmpty(projectLabels?.project_tags)) {
			setProjectLabelsOptions([]);
			return;
		}
		const response = projectLabels?.project_tags?.map((projectLabel: any) => {
			return {
				value: projectLabel.id,
				label: projectLabel.name,
			};
		});
		setProjectLabelsOptions(response);
	}, [projectLabels]);

	useEffect(() => {
		if (!projectCategories || isEmpty(projectCategories?.project_category)) {
			setProjectCategoryOptions([]);
			return;
		}
		const response = projectCategories?.project_category?.map(
			(projectCategory: any) => {
				return {
					value: projectCategory.id,
					label: projectCategory.name,
				};
			}
		);
		setProjectCategoryOptions(response);
	}, [projectCategories]);

	useEffect(() => {
		if (isEmpty(projectStatusList?.project_list)) {
			return;
		}
		const statusList = projectStatusList?.project_list
			?.filter((value) => value?.ProjectStatus?.value === 'in_progress')
			.map((element) => {
				return {
					value: element?.ProjectStatus?.id,
					label: element?.ProjectStatus?.label,
				};
			});
		dispatchFilter({
			type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER,
			payload:
				filterState?.projectStatusFilter?.length > 0
					? filterState?.projectStatusFilter
					: statusList,
		});
		const statusListInfo = projectStatusList?.project_list?.map((value) => {
			return {
				value: value?.ProjectStatus?.id,
				label: value?.ProjectStatus?.label,
			};
		});
		const sortedStatusListInfo: any = orderBy(
			statusListInfo,
			['label'],
			['asc']
		);
		setProjectStatusOptions(sortedStatusListInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectStatusList]);

	const getProjectEndDate = (date: string) => {
		if (!date) {
			return <span className={classes.leftProjectDate}>- -</span>;
		}

		const diffDays = calculateDateDifference(date);
		const projectAvailableDays = `${diffDays} Days`;

		if (diffDays === 0) {
			return <span className={classes.leftProjectDate}>Due in 0 day</span>;
		}

		if (diffDays <= 0) {
			return (
				<span className={classes.dateEndProject}>
					{dayjs(date).format(dateFormat)}
				</span>
			);
		}

		if (diffDays >= 30) {
			return (
				<>
					<span className={classes.leftProjectDate}>
						{dayjs(date).format(dateFormat)}
					</span>
				</>
			);
		}

		if (diffDays > 0)
			return (
				<>
					<span className={classes.leftProjectDate}>
						{`${projectAvailableDays} left`}
					</span>
				</>
			);
	};

	const projectFilterOptions = [
		{
			id: ALL_PROJECTS,
			name: ALL_PROJECTS,
		},
		{
			id: EXPIRED_PROJECTS,
			name: EXPIRED_PROJECTS,
		},
		{
			id: EXPIRED_PROJECTS_IN_N_DAYS,
			name: EXPIRED_PROJECTS_IN_N_DAYS,
		},
	];

	const getMileStonePercentage = (completed: number, total: number) => {
		if (!completed || !total) {
			return;
		}
		const percentage = (completed / total) * 100;
		return Math.trunc(percentage);
	};

	const getProjectLabelFilterLimits = () => (
		<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
			{filterState?.projectLabelFilter?.length === 1
				? filterState?.projectLabelFilter[0].label
				: `Selected ${filterState?.projectLabelFilter?.length || 0} items`}
		</div>
	);

	const getProjectCategoryFilterLimits = () => (
		<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
			{filterState?.projectCategoryFilter?.length === 1
				? filterState?.projectCategoryFilter[0].label
				: `Selected ${filterState?.projectCategoryFilter?.length || 0} items`}
		</div>
	);

	const getProjectResourceFilterLimits = () => (
		<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
			{filterState?.projectResourceFilter?.length === 1
				? filterState?.projectResourceFilter[0].label
				: `Selected ${filterState?.projectResourceFilter?.length || 0} items`}
		</div>
	);

	const handleSelectAll = (isSelected: boolean) => {
		if (isSelected) {
			const statusListInfo = projectStatusList?.project_list?.map((value) => {
				return {
					value: value?.ProjectStatus?.id,
					label: value?.ProjectStatus?.label,
				};
			});
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER,
				payload: statusListInfo,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectLabelAll = (isSelected: boolean) => {
		if (isSelected) {
			const response = projectLabels?.project_tags?.map((projectLabel: any) => {
				return {
					value: projectLabel.id,
					label: projectLabel.name,
				};
			});
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_LABEL_FILTER,
				payload: response,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_LABEL_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectCategoryAll = (isSelected: boolean) => {
		if (isSelected) {
			const response = projectCategories?.project_category?.map(
				(projectCategory) => {
					return {
						value: projectCategory.id,
						label: projectCategory.name,
					};
				}
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_CATEGORY_FILTER,
				payload: response,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_CATEGORY_FILTER,
				payload: [],
			});
		}
	};

	const handleSelectResourceAll = (isSelected: boolean) => {
		if (isSelected) {
			if (filterState?.projectUserTeamFilter) {
				const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
					(user: any) => {
						return {
							value: user?.user?.id,
							label: user?.user?.full_name,
						};
					}
				);
				const sortedAssigneeList = orderBy(
					useTeamAssignees,
					[(user) => user.name?.toUpperCase()],
					['asc']
				);
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
					payload: sortedAssigneeList,
				});
				return;
			}
			const response = resourceList?.project_resource_mapping?.map(
				(userList: any) => {
					return {
						value: userList?.user?.id,
						label: `${userList?.user?.full_name} `,
						projectId: userList?.project_id,
					};
				}
			);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
				payload: response,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
				payload: [],
			});
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll(!allSelected);
	};

	const handleToggleResource = () => {
		handleSelectResourceAll(!allResourceSelected);
	};
	const onResourceChange = (event: any, value: any, reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option: any) => option.value === 'All')) {
				handleToggleResource();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
				payload: [],
			});
			return;
		}
	};
	const onProjectStatusChange = (event: any, value: any, reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option: any) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER,
				payload: [],
			});
			return;
		}
	};

	const handleToggleLabel = () => {
		handleSelectLabelAll(!allProjectLabelSelected);
	};

	const handleCategoryToggleLabel = () => {
		handleSelectCategoryAll(!allProjectCategorySelected);
	};

	const onProjectLabelChange = (event: any, value: any, reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option: any) => option.value === 'All')) {
				handleToggleLabel();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_LABEL_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_LABEL_FILTER,
				payload: [],
			});
			return;
		}
	};

	const onProjectCategoryChange = (event: any, value: any, reason: string) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option: any) => option.value === 'All')) {
				handleCategoryToggleLabel();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_CATEGORY_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_CATEGORY_FILTER,
				payload: [],
			});
			return;
		}
	};

	const getLimits = () => {
		return (
			<div className={`${classes.projectStatus} ${styles.ellipsis}`}>
				{filterState?.projectStatusFilter?.length === 1
					? filterState?.projectStatusFilter[0].label
					: `Selected ${filterState?.projectStatusFilter?.length || 0} items`}
			</div>
		);
	};
	const resetTeamResourceFilter = () => {
		if (!resourceList || isEmpty(resourceList?.project_resource_mapping)) {
			setProjectResource([]);
			return;
		}
		const response = resourceList?.project_resource_mapping?.map(
			(userList: any) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		let sortedResponse: any = sortBy(response, ['label']);
		setProjectResource(sortedResponse);
	};
	const columns = [
		{
			name: 'name',
			label: 'NAME',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<Box
							width='200px'
							display='flex'
							justifyContent='flex-start' // Ensure that items are spaced out
							alignItems='center' // Center items vertically
							className={`${classes.copyContainer} ${classes.cursor}`}
							onClick={(e) => {
								e.stopPropagation();
								history.push(`project/tasks/${value?.id}`);
							}}
							style={{ minWidth: 0 }} // Prevent the box from overflowing its parent
						>
							<Tooltip
								title={
									value?.prefix
										? `${value?.prefix} - ${value?.name || '- -'}`
										: value?.name || '- -'
								}
								placement='top'
							>
								<Box
									display='flex'
									alignItems='center'
									style={{ minWidth: 0 }} // Allow this box to grow and shrink as needed, with a minimum width
								>
									{value?.prefix && (
										<Box
											className={classes.prefixName}
											style={{ whiteSpace: 'nowrap' }}
										>
											{`${value?.prefix}`}&nbsp; - &nbsp;
										</Box>
									)}
									<Typography
										className={[classes.projectName, ellipsis.ellipsis].join(
											' '
										)}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis', // Use ellipsis for overflowed text
											maxWidth: '100%', // Ensure the text doesn't overflow its container
										}}
									>
										{`${value?.name || '--'}`}
									</Typography>
								</Box>
							</Tooltip>
							<Tooltip title='Copy'>
								<FileCopyOutlinedIcon
									onClick={(event) => {
										event.stopPropagation();
										notify('Copied to clipboard', {
											autoHideDuration: 1000,
											type: 'info',
										});
										navigator.clipboard.writeText(value?.name || '');
									}}
									className={classes.copyIcon}
								/>
							</Tooltip>
						</Box>
					);
				},
			},
		},
		{
			name: 'risk',
			label: 'RISK',
			options: {
				filter: true,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return permissions?.project?.update_permissions ? (
						<>
							<ThemeProvider theme={statusSelectBoxTheme}>
								<Select
									style={{
										background:
											value?.risk === 'Red'
												? '#FFF3F2'
												: value?.risk === 'Amber'
												? '#ffbf006e'
												: value?.risk === 'Green'
												? '#E9F7EC'
												: '',
										color:
											value?.risk === 'Red'
												? '#EA4335'
												: value?.risk === 'Amber'
												? '#ff8f00'
												: value?.risk === 'Green'
												? '#34A853'
												: '',
										border:
											value?.risk === 'Red'
												? '1 px solid #FFF3F2'
												: value?.risk === 'Amber'
												? '1 px solid #ffbf006e'
												: value?.risk === 'Green'
												? '1 px solid #E9F7EC'
												: '',
									}}
									value={value?.risk}
									onChange={(event: any) => {
										event.stopPropagation();
										setProjectIdForRiskStatus(value?.id || null);
										setSelectedRiskStatus(event?.target?.value || null);
										setIsProjectRiskChangeModalShown(true);
									}}
									disableUnderline={true}
									variant='outlined'
								>
									{riskStatusOptions?.map(
										(option: { id: string; name: string }) => (
											<MenuItem key={option?.id} value={option?.id}>
												{option?.name}
											</MenuItem>
										)
									)}
								</Select>
							</ThemeProvider>
						</>
					) : (
						<Box width='90px'>
							<Tooltip title={value?.risk || '--'}>
								<Chip
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
									className={`${
										value?.risk === 'Green'
											? classes.completed
											: value?.risk === 'Red'
											? classes.pending
											: value?.risk === 'Amber'
											? classes.riskAmber
											: classes.inProgress
									} ${classes.cursor}`}
									label={value?.risk || '--'}
								/>
							</Tooltip>
						</Box>
					);
				},
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<Autocomplete
										placeholder='Companies'
										multiple
										limitTags={0}
										value={
											selectedCompaniesOptionValue &&
											selectedCompaniesOptionValue?.length > 0
												? selectedCompaniesOptionValue
												: []
										}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={(event, value, reason) => {
											if (!value) {
												return;
											}
											if (value && value?.length === 0) {
												filterList[index][0] = [];
												onChange([], index, column);
												return;
											}
											const selectedIds = value
												?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												)
												.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'select-option'
											) {
												setSelectedCompaniesOptionValues([
													{ id: 'All', name: 'All' },
													...companies,
												]);
												const allResourceIds = companies?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = companies?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedCompaniesIdList(allResourceIds);
												return;
											}
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'remove-option' &&
												companies?.length !== selectedIds?.length
											) {
												const currentOptions = value?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												);
												setSelectedCompaniesOptionValues(currentOptions);
												const currentIds = currentOptions?.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
												const allResourceNames = currentOptions?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedCompaniesIdList(currentIds);
												return;
											}
											if (
												selectedIds?.length === companies?.length &&
												reason === 'select-option'
											) {
												setSelectedCompaniesOptionValues([
													{ id: 'All', name: 'All' },
													...companies,
												]);
												const reporteeIds = companies?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = companies?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedCompaniesIdList(reporteeIds);
												return;
											}
											if (
												selectedCompaniesOptionValue.find(
													(option: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => option?.id === 'All'
												) &&
												reason === 'remove-option'
											) {
												filterList[index][0] = [];
												onChange([], index, column);
												setSelectedCompaniesIdList([]);
												setSelectedCompaniesOptionValues([]);
												return;
											}
											const selectedId = value?.map((resource) => resource?.id);
											const allResourceNames = value?.map(
												(resource: any) => resource?.name
											);
											const nameArray = allResourceNames.join(`, `);
											filterList[index][0] = nameArray;
											onChange(filterList[index], index, column);
											setSelectedCompaniesIdList(selectedId);
											setSelectedCompaniesOptionValues(value);
										}}
										options={
											companies && companies?.length > 0
												? [{ id: 'All', name: 'All' }, ...companies]
												: []
										}
										disableCloseOnSelect={true}
										getLimitTagsText={getCompanyFilterLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<ThemeProvider theme={dataGridFilterDropdownTheme}>
												<TextComponent {...params} label='Companies' />
											</ThemeProvider>
										)}
										renderOption={(option, { selected }) => (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected}
													color='primary'
												/>

												<Tooltip
													title={`${option?.name || '- -'}`}
													placement='right'
												>
													<Typography className={`${ellipsis.ellipsis}`}>
														{`${option?.name || '- -'}`}
													</Typography>
												</Tooltip>
											</>
										)}
										PaperComponent={({ children }) => {
											return (
												<Paper className={dataGridStyles.dropdownContainer}>
													{children}
												</Paper>
											);
										}}
										getOptionLabel={(option) => `${option?.name}`}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
									/>
								)}
							</Form>
						);
					},
				},
			},
		},

		{
			name: 'projectOwner',
			label: 'OWNER',
			options: {
				filter: true,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<Box width='130px'>
							<Tooltip title={value?.projectOwner || '--'}>
								<Box
									className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
								>
									{value?.projectOwner || '--'}
								</Box>
							</Tooltip>
						</Box>
					);
				},

				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<Autocomplete
										placeholder='Project Owner'
										multiple
										limitTags={0}
										value={
											selectedProjectOwnerOptionValue &&
											selectedProjectOwnerOptionValue?.length > 0
												? selectedProjectOwnerOptionValue
												: []
										}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={(event, value, reason) => {
											if (!value) {
												return;
											}
											if (value && value?.length === 0) {
												filterList[index][0] = [];
												onChange([], index, column);
												return;
											}
											const selectedIds = value
												?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												)
												.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'select-option'
											) {
												setSelectedProjectOwnerOptionValues([
													{ id: 'All', name: 'All' },
													...projectOwner,
												]);
												const allResourceIds = projectOwner?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectOwner?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectOwnerIdList(allResourceIds);
												return;
											}
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'remove-option' &&
												projectOwner?.length !== selectedIds?.length
											) {
												const currentOptions = value?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												);
												setSelectedProjectOwnerOptionValues(currentOptions);
												const currentIds = currentOptions?.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
												const allResourceNames = currentOptions?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectOwnerIdList(currentIds);
												return;
											}
											if (
												selectedIds?.length === projectOwner?.length &&
												reason === 'select-option'
											) {
												setSelectedProjectOwnerOptionValues([
													{ id: 'All', name: 'All' },
													...projectOwner,
												]);
												const reporteeIds = projectOwner?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectOwner?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectOwnerIdList(reporteeIds);
												return;
											}
											if (
												selectedProjectOwnerOptionValue.find(
													(option: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => option?.id === 'All'
												) &&
												reason === 'remove-option'
											) {
												filterList[index][0] = [];
												onChange([], index, column);
												setSelectedProjectOwnerIdList([]);
												setSelectedProjectOwnerOptionValues([]);
												return;
											}
											const selectedId = value?.map((resource) => resource?.id);
											const allResourceNames = value?.map(
												(resource: any) => resource?.name
											);
											const nameArray = allResourceNames.join(`, `);
											filterList[index][0] = nameArray;
											onChange(filterList[index], index, column);
											setSelectedProjectOwnerIdList(selectedId);
											setSelectedProjectOwnerOptionValues(value);
										}}
										options={
											projectOwner && projectOwner?.length > 0
												? [{ id: 'All', name: 'All' }, ...projectOwner]
												: []
										}
										disableCloseOnSelect={true}
										getLimitTagsText={getProjectOwnerFilterLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<ThemeProvider theme={dataGridFilterDropdownTheme}>
												<TextComponent {...params} label='Project Owner' />
											</ThemeProvider>
										)}
										renderOption={(option, { selected }) => (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected}
													color='primary'
												/>

												<Tooltip
													title={`${option?.name || '- -'}`}
													placement='right'
												>
													<Typography className={`${ellipsis.ellipsis}`}>
														{`${option?.name || '- -'}`}
													</Typography>
												</Tooltip>
											</>
										)}
										PaperComponent={({ children }) => {
											return (
												<Paper className={dataGridStyles.dropdownContainer}>
													{children}
												</Paper>
											);
										}}
										getOptionLabel={(option) => `${option?.name}`}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
									/>
								)}
							</Form>
						);
					},
				},
			},
		},
		{
			name: 'status',
			label: 'STATUS',
			options: {
				filter: true,
				viewColumns: false,
				sort: true,
				customBodyRender: (value: any) => {
					return (
						<Box width='120px'>
							<Tooltip title={value?.status?.label || '--'} placement='right'>
								{
									<Chip
										onClick={(e) => {
											e.stopPropagation();
											history.push(`project/tasks/${value?.id}`);
										}}
										className={`${
											value?.status.value === 'completed'
												? classes.completed
												: value?.status.value === 'in_progress'
												? classes.inProgress
												: value?.status.value === 'pending'
												? classes.pending
												: classes.lost
										} ${ellipsis.ellipsis}`}
										style={{ width: '100%' }}
										label={value?.status?.label || '-'}
									/>
								}
							</Tooltip>
						</Box>
					);
				},

				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<Autocomplete
										placeholder='Project Risk'
										multiple
										limitTags={0}
										value={
											selectedProjectRiskOptionValue &&
											selectedProjectRiskOptionValue?.length > 0
												? selectedProjectRiskOptionValue
												: []
										}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={(event, value, reason) => {
											if (!value) {
												return;
											}
											if (value && value?.length === 0) {
												filterList[index][0] = [];
												onChange([], index, column);
												return;
											}
											const selectedIds = value
												?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												)
												.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'select-option'
											) {
												setSelectedProjectRiskOptionValues([
													{ id: 'All', name: 'All' },
													...projectRisk,
												]);
												const allResourceIds = projectRisk?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectRisk?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectRiskIdList(allResourceIds);
												return;
											}
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'remove-option' &&
												projectRisk?.length !== selectedIds?.length
											) {
												const currentOptions = value?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												);
												setSelectedProjectRiskOptionValues(currentOptions);
												const currentIds = currentOptions?.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
												const allResourceNames = currentOptions?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectRiskIdList(currentIds);
												return;
											}
											if (
												selectedIds?.length === projectRisk?.length &&
												reason === 'select-option'
											) {
												setSelectedProjectRiskOptionValues([
													{ id: 'All', name: 'All' },
													...projectRisk,
												]);
												const reporteeIds = projectRisk?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectRisk?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectRiskIdList(reporteeIds);
												return;
											}
											if (
												selectedProjectRiskOptionValue.find(
													(option: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => option?.id === 'All'
												) &&
												reason === 'remove-option'
											) {
												filterList[index][0] = [];
												onChange([], index, column);
												setSelectedProjectRiskIdList([]);
												setSelectedProjectRiskOptionValues([]);
												return;
											}
											const selectedId = value?.map((resource) => resource?.id);
											const allResourceNames = value?.map(
												(resource: any) => resource?.name
											);
											const nameArray = allResourceNames.join(`, `);
											filterList[index][0] = nameArray;
											onChange(filterList[index], index, column);
											setSelectedProjectRiskIdList(selectedId);
											setSelectedProjectRiskOptionValues(value);
										}}
										options={
											projectRisk && projectRisk?.length > 0
												? [{ id: 'All', name: 'All' }, ...projectRisk]
												: []
										}
										disableCloseOnSelect={true}
										getLimitTagsText={getProjectRiskFilterLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<ThemeProvider theme={dataGridFilterDropdownTheme}>
												<TextComponent {...params} label='Project Risk' />
											</ThemeProvider>
										)}
										renderOption={(option, { selected }) => (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected}
													color='primary'
												/>

												<Tooltip
													title={`${option?.name || '- -'}`}
													placement='right'
												>
													<Typography className={`${ellipsis.ellipsis}`}>
														{`${option?.name || '- -'}`}
													</Typography>
												</Tooltip>
											</>
										)}
										PaperComponent={({ children }) => {
											return (
												<Paper className={dataGridStyles.dropdownContainer}>
													{children}
												</Paper>
											);
										}}
										getOptionLabel={(option) => `${option?.name}`}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
									/>
								)}
							</Form>
						);
					},
				},
			},
		},

		{
			name: 'task',
			label: 'TASK',
			options: {
				filter: true,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<Box width='100px'>
							<HtmlTooltip
								title={
									<span className={classes.progressBar}>
										<LinerProgressTask
											variant='determinate'
											value={
												getMileStonePercentage(
													value?.completedTask,
													value?.totalTask
												) || 0
											}
										/>
										<Typography
											className={classes.percentage}
											variant='caption'
										>
											{getMileStonePercentage(
												value?.completedTask,
												value?.totalTask
											) || 0}
											%
										</Typography>
									</span>
								}
							>
								<Box
									className={classes.cursor}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
								>{`${value?.completedTask} of ${value?.totalTask}`}</Box>
							</HtmlTooltip>
						</Box>
					);
				},
				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<Autocomplete
										placeholder='Project Type'
										multiple
										limitTags={0}
										value={
											selectedProjectTypeOptionValue &&
											selectedProjectTypeOptionValue?.length > 0
												? selectedProjectTypeOptionValue
												: []
										}
										selectOnFocus={true}
										disablePortal
										fullWidth={false}
										onChange={(event, value, reason) => {
											if (!value) {
												return;
											}
											if (value && value?.length === 0) {
												filterList[index][0] = [];
												onChange([], index, column);
												return;
											}
											const selectedIds = value
												?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												)
												.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'select-option'
											) {
												setSelectedProjectTypeOptionValues([
													{ id: 'All', name: 'All' },
													...projectType,
												]);
												const allResourceIds = projectType?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectType?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectTypeIdList(allResourceIds);
												return;
											}
											if (
												value.find((option) => option.id === 'All') &&
												reason === 'remove-option' &&
												projectType?.length !== selectedIds?.length
											) {
												const currentOptions = value?.filter(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id !== 'All'
												);
												setSelectedProjectTypeOptionValues(currentOptions);
												const currentIds = currentOptions?.map(
													(resource: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => resource?.id
												);
												const allResourceNames = currentOptions?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectTypeIdList(currentIds);
												return;
											}
											if (
												selectedIds?.length === projectType?.length &&
												reason === 'select-option'
											) {
												setSelectedProjectTypeOptionValues([
													{ id: 'All', name: 'All' },
													...projectType,
												]);
												const reporteeIds = projectType?.map(
													(resource: any) => resource?.id
												);
												const allResourceNames = projectType?.map(
													(resource: any) => resource?.name
												);
												const nameArray = allResourceNames.join(`, `);
												filterList[index][0] = nameArray;
												onChange(filterList[index], index, column);
												setSelectedProjectTypeIdList(reporteeIds);
												return;
											}
											if (
												selectedProjectTypeOptionValue.find(
													(option: {
														id?: string | null | undefined;
														name?: string | null | undefined;
													}) => option?.id === 'All'
												) &&
												reason === 'remove-option'
											) {
												filterList[index][0] = [];
												onChange([], index, column);
												setSelectedProjectTypeIdList([]);
												setSelectedProjectTypeOptionValues([]);
												return;
											}
											const selectedId = value?.map((resource) => resource?.id);
											const allResourceNames = value?.map(
												(resource: any) => resource?.name
											);
											const nameArray = allResourceNames.join(`, `);
											filterList[index][0] = nameArray;
											onChange(filterList[index], index, column);
											setSelectedProjectTypeIdList(selectedId);
											setSelectedProjectTypeOptionValues(value);
										}}
										options={
											projectType && projectType?.length > 0
												? [{ id: 'All', name: 'All' }, ...projectType]
												: []
										}
										disableCloseOnSelect={true}
										getLimitTagsText={getProjectTypeFilterLimits}
										ChipProps={{ style: { display: 'none' } }}
										renderInput={(params) => (
											<ThemeProvider theme={dataGridFilterDropdownTheme}>
												<TextComponent {...params} label='Project Type' />
											</ThemeProvider>
										)}
										renderOption={(option, { selected }) => (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected}
													color='primary'
												/>

												<Tooltip
													title={`${option?.name || '- -'}`}
													placement='right'
												>
													<Typography className={`${ellipsis.ellipsis}`}>
														{`${option?.name || '- -'}`}
													</Typography>
												</Tooltip>
											</>
										)}
										PaperComponent={({ children }) => {
											return (
												<Paper className={dataGridStyles.dropdownContainer}>
													{children}
												</Paper>
											);
										}}
										getOptionLabel={(option) => `${option?.name}`}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
									/>
								)}
							</Form>
						);
					},
				},
			},
		},

		{
			name: 'customField',
			label: 'Fields',
			options: {
				filter: true,
				viewColumns: false,
				sort: false,
				display: false,

				customBodyRender: (value: any) => {
					const displayValue = value?.customField || '--';

					return (
						<Box width='130px'>
							<Tooltip title={value?.customField || '--'}>
								<Box
									className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
								>
									{displayValue}
								</Box>
							</Tooltip>
						</Box>
					);
				},

				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<>
								<Box>
									<Box className={classes.selectInput}>Custom Fields</Box>
									<Form onSubmit={() => {}}>
										{() => (
											<>
												<ThemeProvider theme={modalFormTheme}>
													<SelectInput
														source={' Select Custom Field'}
														label={''}
														resettable
														choices={
															customField && customField?.length > 0
																? [...customField]
																: []
														}
														onChange={(event) => {
															if (!event || !event.target) {
																return;
															}
															setSelectedCustomValue(event?.target?.value);
															setSelectedData('');
															const selectedField =
																customFields?.custom_field?.find(
																	(field: { index: string }) =>
																		field.index === event.target.value
																);
															if (!selectedField) {
																return;
															}

															const selectedFieldType = selectedField?.type;

															setCustomFieldType(selectedFieldType);
														}}
														defaultValue={selectedCustomValue}
														key={selectedCustomValue as React.Key}
													/>
												</ThemeProvider>
											</>
										)}
									</Form>
								</Box>
							</>
						);
					},
				},
			},
		},

		{
			name: 'customFieldValues',
			label: 'Fields',
			options: {
				filter: true,
				viewColumns: false,
				display: false,
				sort: false,
				customBodyRender: (value: any) => {
					const displayValue = value?.customFieldValues || '--';

					return (
						<Box width='130px'>
							<Tooltip title={value?.customFieldValues || '--'}>
								<Box
									className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
								>
									{displayValue}
								</Box>
							</Tooltip>
						</Box>
					);
				},

				filterType: filterType,
				filterOptions: {
					display: (
						filterList: any,
						onChange: any,
						index: number,
						column: any
					) => {
						return (
							<Form onSubmit={() => {}}>
								{() => (
									<>
										{selectedCustomValue && (
											<>
												<Box className={classes.searchBox}>
													<CustomSearch
														searchText={selectedData}
														placeholder={`Search  value`}
														classes={classes}
														onSearch={(value: string) => setSelectedData(value)}
													/>
												</Box>
												<Box>
													{customFieldType && customFieldType === 'date' && (
														<p className={classes?.dateWarning}>{DATE_INFO}</p>
													)}
												</Box>
											</>
										)}
									</>
								)}
							</Form>
						);
					},
				},
			},
		},

		{
			name: 'milestone',
			label: 'MILESTONE',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<Box width='100px'>
							<HtmlTooltip
								title={
									<span className={classes.progressBar}>
										<LinerProgressTask
											variant='determinate'
											value={
												getMileStonePercentage(
													value?.completedMilestones,
													value?.totalMilestones
												) || 0
											}
										/>
										<Typography
											onClick={(e) => {
												e.stopPropagation();
												history.push(`project/tasks/${value?.id}`);
											}}
											className={classes.percentage}
											variant='caption'
										>
											{getMileStonePercentage(
												value?.completedMilestones,
												value?.totalMilestones
											) || 0}
											%
										</Typography>
									</span>
								}
							>
								<Box
									className={classes.cursor}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`project/tasks/${value?.id}`);
									}}
								>{`${value?.completedMilestones} of ${value?.totalMilestones}`}</Box>
							</HtmlTooltip>
						</Box>
					);
				},
			},
		},
		{
			name: 'resourceCount',
			label: 'RESOURCE COUNT',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<div
							className={classes.employeesContainer}
							onClick={(e) => {
								e.stopPropagation();
								const resEmployee = value?.resourceCount?.map(
									(employeeResource: any) => {
										return {
											name: `${employeeResource.user?.full_name || ''} `,
											email: `${employeeResource.user?.email || ''}`,
										};
									}
								);
								setResourceData(resEmployee ? resEmployee : []);
								setIsEmployeesListModalShown(true);
							}}
						>
							<PeopleOutlineIcon className={classes.peoplesIcon} />
							<Tooltip
								title={`${value?.resourceCount?.length || '- -'}`}
								placement='right'
							>
								<Typography className={[classes.resourceCount].join(' ')}>
									{`${value?.resourceCount?.length || '- -'}`}
								</Typography>
							</Tooltip>
						</div>
					);
				},
			},
		},
		{
			name: 'startDate',
			label: 'START DATE',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any) => {
					const startDate = value?.startDate
						? dayjs(value?.startDate).format(dateFormat)
						: '--';
					return (
						<Tooltip title={startDate}>
							<Box
								className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
								onClick={(e) => {
									e.stopPropagation();
									history.push(`project/tasks/${value?.id}`);
								}}
							>
								{startDate}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'endDate',
			label: 'END DATE',
			options: {
				filter: false,
				viewColumns: false,
				sort: true,
				customBodyRender: (value: any) => {
					const diffDays = dayjs(value?.endDate)
						.startOf('day')
						.diff(dayjs().startOf('day'), 'days');
					const endDate = value?.endDate
						? dayjs(value?.endDate).format(dateFormat)
						: '';
					const isProjectCompleted = value?.projectStatusValue === 'completed';

					return (
						<Tooltip
							title={
								value?.endDate
									? isProjectCompleted
										? endDate
										: diffDays <= 0
										? `Overdue by ${dayjs()
												.startOf('day')
												.diff(dayjs(value?.endDate).startOf('day'), 'days')}`
										: diffDays > 0
										? endDate || '- -'
										: getProjectEndDate(value?.endDate) || '- -'
									: '- -'
							}
							placement='right'
						>
							<Typography
								onClick={(e) => {
									e.stopPropagation();
									history.push(`project/tasks/${value?.id}`);
								}}
								className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
							>
								{value?.endDate && isProjectCompleted ? (
									<span className={classes.leftProjectDate}>{endDate}</span>
								) : (
									getProjectEndDate(value?.endDate)
								)}
							</Typography>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'actions',
			label: 'ACTIONS',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (id: any) => {
					return permissions?.project?.update_permissions ? (
						<Box
							onClick={(e) => {
								e.stopPropagation();
								history.push(`project/detail/${id}`);
							}}
						>
							<Tooltip title='Project details'>
								<IconButton
									onClick={(event) => {
										event.stopPropagation();
										history.push(`project/detail/${id}`);
									}}
								>
									<SettingsOutlinedIcon className={classes.menuIcon} />
								</IconButton>
							</Tooltip>
						</Box>
					) : (
						<></>
					);
				},
			},
		},
	];

	//Styles
	const dataGridStyles = dataGridStyle();
	function getProjectListData() {
		if (!filteredProjects) {
			return [];
		}
		const ProjectListData = filteredProjects?.project?.map((project: any) => ({
			name: {
				name: project?.name,
				prefix: project?.prefix,
				id: project?.id,
			},
			risk: {
				risk: project?.risk_status,
				id: project?.id,
			},
			projectOwner: {
				projectOwner: project?.user?.full_name,
				id: project?.id,
			},
			status: {
				status: project?.ProjectStatus,
				id: project?.id,
			},
			task: {
				completedTask: project?.completedTask?.aggregate?.count,
				totalTask: project?.totalTask?.aggregate?.count,
				id: project?.id,
			},
			milestone: {
				completedMilestones: project?.completedMilestones?.aggregate?.count,
				totalMilestones: project?.totalMilestones?.aggregate?.count,
				id: project?.id,
			},
			resourceCount: {
				resourceCount: project?.resourceList,
				id: project?.id,
			},
			startDate: {
				startDate: project?.start_date,
				id: project?.id,
			},
			endDate: {
				endDate: project?.end_date,
				projectStatusValue: project?.ProjectStatus?.value,
				projectStatusId: project?.ProjectStatus?.id,
				id: project?.id,
			},

			actions: project?.id,
		}));
		return ProjectListData;
	}

	const options: MUIDataTableOptions = {
		filter: true,
		search: false,
		print: false,
		rowsPerPage: paginationState?.projectPerPagePagination ?? 15,
		rowsPerPageOptions: [15, 25, 50],
		page: paginationState?.projectPagePagination ?? 0,
		serverSide: true,
		viewColumns: false,
		download: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		count: filteredProjects?.project_aggregate?.aggregate?.count || 0,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		onRowClick: (row: any, rowMeta) => {
			if (isFilteredProjectLoading) {
				return;
			}
			const selectedRowData = getProjectListData()[rowMeta.dataIndex];
			history.push(`project/tasks/${selectedRowData?.actions}`);
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			dispatchPaginationState({
				type: PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PER_PAGE_PAGINATION,
				payload: numberOfRows,
			});
			dispatchPaginationState({
				type: PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PAGE_PAGINATION,
				payload: 0,
			});
		},
		onFilterChange: (
			changedColumn: any,
			filterList: any,
			type: any,
			changedColumnIndex: any,
			displayData: any
		) => {
			if (type === 'reset') {
				setSelectedCompaniesIdList([]);
				setSelectedCompaniesOptionValues([]);
				setSelectedProjectOwnerIdList([]);
				setSelectedProjectOwnerOptionValues([]);
				setSelectedProjectRiskOptionValues([]);
				setSelectedProjectRiskIdList([]);
				setSelectedProjectTypeOptionValues([]);
				setSelectedProjectTypeIdList([]);
				setSelectedData('');
				setSelectedCustomValue('');
				return;
			}
			if (filterList[1]?.length === 0) {
				setSelectedCompaniesIdList([]);
				setSelectedCompaniesOptionValues([]);
			}
			if (filterList[2]?.length === 0) {
				setSelectedProjectOwnerIdList([]);
				setSelectedProjectOwnerOptionValues([]);
			}
			if (filterList[3]?.length === 0) {
				setSelectedProjectRiskOptionValues([]);
				setSelectedProjectRiskIdList([]);
			}
			if (filterList[4]?.length === 0) {
				setSelectedProjectTypeOptionValues([]);
				setSelectedProjectTypeIdList([]);
			}
		},
		onTableChange: (action: string, tableState: any) => {
			switch (action) {
				case 'changePage': {
					dispatchPaginationState({
						type: PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PAGE_PAGINATION,
						payload: tableState.page,
					});
					break;
				}
				default:
					return;
			}
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'status': {
					dispatchFilter({
						type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_SORT_FILTER,
						payload: [{ ProjectStatus: { label: direction } }],
					});
					break;
				}
				case 'endDate': {
					dispatchFilter({
						type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_SORT_FILTER,
						payload: [{ end_date: direction }],
					});
					break;
				}
				default: {
					dispatchFilter({
						type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_SORT_FILTER,
						payload: [],
					});
				}
			}
		},
	};

	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);
	return (
		<Box>
			<Box
				display='flex'
				justifyContent='space-between'
				marginBottom='20px'
				marginTop='20px'
				gridRowGap={'10px'}
				gridColumnGap={'10px'}
			>
				<Box display='flex' marginTop={'6px'}>
					<Box>
						<CustomSearch
							placeholder='Search Project'
							searchText={filterState?.projectSearchText}
							onSearch={(value: string) => {
								dispatchFilter({
									type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_LIST_SEARCH_TEXT,
									payload: value ?? '',
								});
								dispatchPaginationState({
									type: PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PAGE_PAGINATION,
									payload: 0,
								});
							}}
							classes={classes}
						/>
					</Box>
				</Box>
				{featureFlag?.userTeams && (
					<Box width={'210px'}>
						<Box>
							<Box className={classes.companyDropDownFilterLabel}>
								User Team
							</Box>
							<Form onSubmit={() => {}}>
								{({ form }) => (
									<form>
										<AutocompleteSearch
											placeholder={'Search Team'}
											option={
												userTeams && userTeams?.user_team
													? userTeams?.user_team?.map((userTeam: any) => {
															return {
																id: userTeam?.id,
																name: userTeam?.name,
															};
													  }) || []
													: []
											}
											onChange={(id) => {
												if (!id) {
													resetTeamResourceFilter();
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_USER_TEAM_FILTER,
														payload: null,
													});
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
														payload: [],
													});
													return;
												}
												dispatchFilter({
													type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER,
													payload: [],
												});
												const currentTeam = userTeams?.user_team?.find(
													(team: any) => team?.id === id
												);
												dispatchFilter({
													type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_USER_TEAM_FILTER,
													payload: currentTeam,
												});
											}}
											name={'team_id'}
											value={
												filterState?.projectUserTeamFilter
													? filterState?.projectUserTeamFilter
													: null
											}
										/>
									</form>
								)}
							</Form>
						</Box>
					</Box>
				)}
				<Box width={'182px'}>
					<Box>
						<Box className={classes.companyDropDownFilterLabel}>Resource</Box>
						<Autocomplete
							multiple
							limitTags={0}
							value={
								filterState?.projectResourceFilter
									? filterState?.projectResourceFilter
									: []
							}
							selectOnFocus={true}
							disablePortal
							fullWidth={false}
							onChange={onResourceChange}
							options={projectResource.length > 0 ? projectResource : []}
							disableCloseOnSelect={true}
							getLimitTagsText={getProjectResourceFilterLimits}
							ChipProps={{ style: { display: 'none' } }}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								return [{ label: 'All', value: 'All' }, ...filtered];
							}}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextComponent
										{...params}
										label=''
										placeholder={
											filterState?.projectResourceFilter &&
											filterState?.projectResourceFilter?.length > 0
												? ''
												: 'Search Resource'
										}
									/>
								</ThemeProvider>
							)}
							renderOption={(option, { selected }) => {
								const selectAllProps =
									option.value === 'All' // To control the state of 'select-all' checkbox
										? { checked: allResourceSelected }
										: {};
								return (
									<>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>
										<Tooltip
											title={`${option?.label || '- -'}`}
											placement='right'
										>
											<Typography className={`${ellipsis.ellipsis}`}>
												{`${option?.label || '- -'}`}
											</Typography>
										</Tooltip>
									</>
								);
							}}
							getOptionLabel={(option) => `${option?.label}`}
							getOptionSelected={(option, value) =>
								option.value === value.value
							}
						/>
					</Box>
				</Box>
				{!featureFlag?.userTeams && (
					<Box width={'182px'} marginLeft={'10px'} marginBottom={'7px'}>
						<Box>
							<Box className={classes.companyDropDownFilterLabel}>Category</Box>
							<form onSubmit={(event) => event.preventDefault()}>
								<Autocomplete
									multiple
									value={
										filterState?.projectCategoryFilter
											? filterState?.projectCategoryFilter
											: []
									}
									limitTags={0}
									selectOnFocus={true}
									disablePortal
									fullWidth={false}
									onChange={onProjectCategoryChange}
									options={
										projectCategoryOptions.length > 0
											? projectCategoryOptions
											: []
									}
									filterOptions={(options, params) => {
										const filtered = filter(options, params);
										return [{ label: 'All', value: 'All' }, ...filtered];
									}}
									disableCloseOnSelect
									getLimitTagsText={getProjectCategoryFilterLimits}
									ChipProps={{ style: { display: 'none' } }}
									renderInput={(params) => (
										<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
											<TextComponent
												{...params}
												label=''
												placeholder={
													filterState?.projectCategoryFilter &&
													filterState?.projectCategoryFilter.length > 0
														? ''
														: 'Search Category'
												}
											/>
										</ThemeProvider>
									)}
									renderOption={(option, { selected }) => {
										const selectAllProps =
											option.value === 'All' // To control the state of 'select-all' checkbox
												? { checked: allProjectCategorySelected }
												: {};
										return (
											<>
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													style={{ marginRight: 8 }}
													checked={selected || false}
													color='primary'
													{...selectAllProps}
												/>
												<Box
													style={{ fontSize: '12px' }}
												>{`${option?.label}`}</Box>
											</>
										);
									}}
									getOptionLabel={(option) => `${option?.label}`}
									getOptionSelected={(option, value) =>
										option.value === value.value
									}
								/>
							</form>
						</Box>
					</Box>
				)}
				<Box width={'182px'}>
					<Box>
						<Box className={classes.companyDropDownFilterLabel}>Label</Box>
						<form onSubmit={(event) => event.preventDefault()}>
							<Autocomplete
								multiple
								value={
									filterState?.projectLabelFilter
										? filterState?.projectLabelFilter
										: []
								}
								limitTags={0}
								selectOnFocus={true}
								disablePortal
								fullWidth={false}
								onChange={onProjectLabelChange}
								options={
									projectLabelsOptions.length > 0 ? projectLabelsOptions : []
								}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
									return [{ label: 'All', value: 'All' }, ...filtered];
								}}
								disableCloseOnSelect
								getLimitTagsText={getProjectLabelFilterLimits}
								ChipProps={{ style: { display: 'none' } }}
								renderInput={(params) => (
									<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
										<TextComponent
											{...params}
											label=''
											placeholder={
												filterState?.projectLabelFilter &&
												filterState?.projectLabelFilter.length > 0
													? ''
													: 'Search Label'
											}
										/>
									</ThemeProvider>
								)}
								renderOption={(option, { selected }) => {
									const selectAllProps =
										option.value === 'All' // To control the state of 'select-all' checkbox
											? { checked: allProjectLabelSelected }
											: {};
									return (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected || false}
												color='primary'
												{...selectAllProps}
											/>
											<Tooltip
												title={`${option?.label || '- -'}`}
												placement='right'
											>
												<Typography className={`${ellipsis.ellipsis}`}>
													{`${option?.label || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									);
								}}
								getOptionLabel={(option) => `${option?.label}`}
								getOptionSelected={(option, value) =>
									option.value === value.value
								}
							/>
						</form>
					</Box>
				</Box>
				<Box width={'182px'}>
					<Box>
						<Box className={classes.companyDropDownFilterLabel}>
							Project Status
						</Box>
						<form onSubmit={(event) => event.preventDefault()}>
							<Autocomplete
								multiple
								value={
									filterState?.projectStatusFilter
										? filterState?.projectStatusFilter
										: []
								}
								limitTags={0}
								selectOnFocus={true}
								disablePortal
								fullWidth={false}
								onChange={onProjectStatusChange}
								classes={{
									tag: inlinestyle.label,
								}}
								options={
									projectStatusOptions.length > 0 ? projectStatusOptions : []
								}
								disableCloseOnSelect
								getLimitTagsText={getLimits}
								ChipProps={{ style: { display: 'none' } }}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
									return [{ label: 'All', value: 'All' }, ...filtered];
								}}
								renderInput={(params) => (
									<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
										<TextComponent
											{...params}
											label=''
											placeholder={
												filterState?.projectStatusFilter &&
												filterState?.projectStatusFilter.length > 0
													? ''
													: 'Search Status'
											}
										/>
									</ThemeProvider>
								)}
								renderOption={(option, { selected }) => {
									const selectAllProps =
										option.value === 'All' // To control the state of 'select-all' checkbox
											? { checked: allSelected }
											: {};
									return (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected || false}
												color='primary'
												{...selectAllProps}
											/>
											<Tooltip
												title={`${option?.label || '- -'}`}
												placement='right'
											>
												<Typography className={`${ellipsis.ellipsis}`}>
													{`${option?.label || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									);
								}}
								getOptionLabel={(option) => `${option?.label}`}
								getOptionSelected={(option, value) =>
									option.value === value.value
								}
							/>
						</form>
					</Box>
				</Box>

				<Box>
					<Box paddingBottom={'5px'} marginTop={'-12px'}>
						<Box className={dropdownStyle.dropDownFilterLabel}>Expiry</Box>
					</Box>
					<form>
						<ThemeProvider theme={searchBarTheme}>
							<Box className={classes.expiryFilterStatus}>
								<Form
									onSubmit={() => {}}
									initialValues={{
										expiry: filterState?.projectExpiryFilter
											? filterState?.projectExpiryFilter
											: ALL_PROJECTS,
									}}
								>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<form>
												<SelectInput
													label={''}
													source='expiry'
													onChange={(event) => {
														dispatchFilter({
															type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_EXPIRY_FILTER,
															payload: event?.target?.value,
														});
													}}
													choices={projectFilterOptions}
												/>
											</form>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</ThemeProvider>
					</form>
				</Box>
				<Box marginTop={'6px'}>
					{permissions?.appPermissions?.ui?.exportProject && (
						<Button
							endIcon={<ArrowUpwardIcon />}
							className={
								!!isEmpty(filteredProjects?.project) ||
								isLoading ||
								isFilteredProjectLoading
									? dataGridStyles.disabledButton
									: dataGridStyles.exportButton
							}
							onClick={exportProjectData}
							disabled={
								!!isEmpty(filteredProjects?.project) ||
								isLoading ||
								isFilteredProjectLoading
							}
							startIcon={
								isLoading && (
									<CircularProgress
										style={{
											height: '20px',
											width: '20px',
											color: '#FFFFFF',
										}}
									/>
								)
							}
						>
							Export
						</Button>
					)}
				</Box>
				<Box marginTop={'6px'} marginRight={'10px'}>
					{permissions?.project?.insert_permissions && (
						<Button
							startIcon={<AddIcon />}
							className={classes.addButton}
							onClick={() => {
								setIsProjectFormModalShown(true);
								setProjectId('');
							}}
						>
							Add Project
						</Button>
					)}
				</Box>
			</Box>
			<Box>{isFilteredProjectLoading && <CircularProgress size={20} />}</Box>
			<Box>
				<ThemeProvider theme={customizedDataGridTheme}>
					<MUIDataTable
						title={''}
						data={getProjectListData()}
						columns={columns}
						options={options}
					/>
				</ThemeProvider>
			</Box>
			<ProjectForm
				project={{}}
				open={isProjectFormModalShown}
				onClose={() => setIsProjectFormModalShown(false)}
				refetch={() => {
					refetchFilteredProjects();
					refetchProjectStatus();
				}}
				projectId={projectId}
			/>
			<EmployeeListModal
				open={isEmployeesListModalShown}
				onClose={() => {
					setIsEmployeesListModalShown(false);
				}}
				employees={resourceData ?? []}
			/>
			<ProjectRiskChangeForm
				open={isProjectRiskChangeModalShown}
				projectId={projectIdForRiskStatus}
				risk={selectedRiskStatus}
				onClose={() => {
					setIsProjectRiskChangeModalShown(false);
					setProjectIdForRiskStatus(null);
					setSelectedRiskStatus(null);
				}}
				onSuccess={() => {
					setIsProjectRiskChangeModalShown(false);
					setProjectIdForRiskStatus(null);
					setSelectedRiskStatus(null);
					refetchFilteredProjects();
				}}
			/>
		</Box>
	);
};

export default ProjectDataGridList;
