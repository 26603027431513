import React from 'react';
import {
	Box,
	Dialog,
	ThemeProvider,
	Typography,
	Avatar,
	CircularProgress,
	CircularProgressProps,
	makeStyles,
	Card,
	CardContent,
	Tooltip,
} from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TimesheetStyles } from '../TimesheetStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayjs from 'dayjs';
import { TimesheetDetailModalStyle } from '../TimesheetStyles';
import { OvertimeDetails } from './Overtime.model';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetHistoryDetailsModalProps {
	onClose: () => void;
	open: boolean;
	overtime?: OvertimeDetails;
}
const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: '550px',
		minHeight: '400px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	kpi: {
		width: '98%',
		height: '100px',
		background: '#FFFFFF',
		display: 'flex',
		justifyContent: 'space-around',
		boxShadow: '0px 3px 6px #0000000f',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	billableHoursContainer: {
		marginTop: '30px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	circularProgress: {
		color: '#4285F4',
		width: '80px !important',
		height: '80px !important',
		backgroundColor: 'transparent',
		borderRadius: '50%',
		boxShadow: 'inset 0 0 1px 12.5px #ACCBFF',
		marginTop: '9px',
	},
	cardRoot: {
		minWidth: 200,
		marginRight: 12,
		marginTop: 10,
		boxShadow: '0px 3px 6px #0000000F',
		marginBottom: 25,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	contentContainer: {
		marginTop: '25px',
		marginLeft: '10px',
	},
	contentDetails: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '120px',
		marginBottom: '8px',
	},
	fieldValue: {
		color: '#7C7C7C',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		width: '480px',
	},
	label: {
		color: '#292929',
		fontSize: '10px !important',
		letterSpacing: '0px',
		fontFamily: 'Manrope-bold',
		marginBottom: '5px',
	},
	link: {
		color: '#4285F4',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	taskContainer: {
		width: '100%',
	},
	divider: {
		margin: '12px 0px',
	},
	modalOuterContainer: {
		width: '100%',
		padding: '20px',
		background: '#FFFFFF',
		minHeight: '350px',
		borderRadius: '4px',
	},
	actionContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '16px',
		marginBottom: '16px',
	},
	approveButton: {
		width: 'fit-content',
	},
	note: {
		color: '#7C7C7C',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		width: '480px',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
	},
}));
export const TimesheetOvertimeHistoryDetailsModal = (
	props: TimesheetHistoryDetailsModalProps
) => {
	const { open, onClose, overtime } = props;
	const formStyles = modalFormStyle();
	const pendingApprovaDetailsStyles = TimesheetDetailModalStyle();
	const timesheetStyle = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const customStyles = useStyles();

	const CircularProgressWithLabel = (
		props: CircularProgressProps & { value: number }
	) => {
		return (
			<Box position='relative' display='inline-flex'>
				<CircularProgress variant='determinate' {...props} />
				<Box
					top={4}
					left={0}
					bottom={0}
					right={0}
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					<Typography
						className={pendingApprovaDetailsStyles.billablePercentageLabel}
					>{`${props.value}%`}</Typography>
				</Box>
			</Box>
		);
	};

	const Toolbar = () => (
		<Box className={customStyles.actionContainer}>
			{overtime?.date ? (
				<Box
					className={`${timesheetStyle.avatarContainer} ${pendingApprovaDetailsStyles.userDetailsContainer}`}
				>
					<Avatar
						className={timesheetStyle.avatar}
						src={`${overtime?.user?.profilePic || ''}`}
					>
						{overtime?.user?.name?.charAt(0).toUpperCase() || ''}
					</Avatar>
					<Typography className={timesheetStyle.employeeName}>
						{`${overtime?.user?.name || ''}`}
					</Typography>
					<Box ml={1} mr={1}>
						<Typography className={pendingApprovaDetailsStyles.breadCrum}>
							{'>>'}
						</Typography>
					</Box>
					<Typography className={pendingApprovaDetailsStyles.dateRange}>
						{`${dayjs(overtime?.date).format('DD MMM YYYY')}`}
					</Typography>
				</Box>
			) : (
				<Typography className={pendingApprovaDetailsStyles.dateRange}>
					- -
				</Typography>
			)}
		</Box>
	);

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
					fullWidth={false}
					maxWidth={false}
				>
					<Box className={customStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								OVERTIME DETAILS
							</Typography>
							<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
						</Box>
						<Toolbar />
						<Box>
							<Card className={customStyles.cardRoot} variant='outlined'>
								<CardContent>
									<Box className={customStyles.contentContainer}>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Project
											</Typography>
											<Tooltip
												title={overtime?.projectName || '--'}
												placement='left-start'
											>
												<Typography
													className={`${customStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{overtime?.projectName || '--'}
												</Typography>
											</Tooltip>
										</Box>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Task
											</Typography>
											<Tooltip
												title={overtime?.taskName || '--'}
												placement='left-start'
											>
												<Typography
													className={`${customStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{overtime?.taskName || '--'}
												</Typography>
											</Tooltip>
										</Box>
										<Box className={customStyles.contentDetails}>
											<Typography className={customStyles.label}>
												Notes
											</Typography>
											<Tooltip
												title={overtime?.notes || '--'}
												placement='left-start'
											>
												<Typography className={customStyles.note}>
													{overtime?.notes || '--'}
												</Typography>
											</Tooltip>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Box>
						<Box className={customStyles.kpi}>
							<CircularProgressWithLabel
								value={Number(overtime?.billablePercentage || 0)}
								variant='determinate'
								thickness={5.6}
								className={customStyles.circularProgress}
							/>
							<Box className={customStyles.billableHoursContainer}>
								<Typography
									className={pendingApprovaDetailsStyles.billableHours}
								>
									{`${overtime?.billableHours || '00:00'} hrs`}
								</Typography>
								<Box display='flex' alignItems='center'>
									<Box
										className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.billableHoursIcon}`}
									></Box>
									<Typography
										className={pendingApprovaDetailsStyles.billableHoursLabel}
									>
										Billable
									</Typography>
								</Box>
							</Box>
							<Box className={customStyles.billableHoursContainer}>
								<Typography
									className={pendingApprovaDetailsStyles.billableHours}
								>
									{`${overtime?.nonBillableHours || '00:00'} hrs`}
								</Typography>
								<Box display='flex' alignItems='center'>
									<Box
										className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.nonBillableHoursIcon}`}
									></Box>
									<Typography
										className={pendingApprovaDetailsStyles.billableHoursLabel}
									>
										Non-Billable
									</Typography>
								</Box>
							</Box>
							<Box display='flex' marginTop='25px'>
								<Box className={pendingApprovaDetailsStyles.iconContainer}>
									<AccessTimeIcon
										className={pendingApprovaDetailsStyles.accessTimeIcon}
									/>
								</Box>
								<Box
									display='flex'
									alignItems='flex-end'
									flexDirection='column'
								>
									<Typography
										className={pendingApprovaDetailsStyles.totalHoursValue}
									>
										{`${overtime?.totalHours || '00:00'}`}
									</Typography>
									<Typography className={timesheetStyle.totalHoursLabel}>
										TOTAL HOURS
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Dialog>
			</ThemeProvider>
		</>
	);
};

export default TimesheetOvertimeHistoryDetailsModal;
