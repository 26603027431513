import React, { useContext, useState, useEffect } from 'react';
import {
	Box,
	ThemeProvider,
	Typography,
	createTheme,
	makeStyles,
} from '@material-ui/core';

import { useUpdateLeaveMutation } from '../../../generated/graphql';
import {
	ENABLE_APPLY_LEAVE_CONFIRMATION,
	ENABLE_APPLY_LEAVE_NOTIFICATION_MESSAGE,
} from '../constant';

import { preventSubsequentClick } from '../../../Utils/string.util';
import { useGetOrganizationLeaveSettingsQuery } from '../../../generated/graphql';

import { Form } from 'react-final-form';
import { BooleanInput, useNotify } from 'react-admin';
import { settingsStyle } from '../settingsStyle';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import { UserProfileContext } from '../../../App';

const leaveSettingStyles = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

const LeaveSetting = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isApplyLeave, setIsApplyLeave] = useState<boolean>(false);
	const notify = useNotify();
	const leaveSettingStyle = leaveSettingStyles();
	const settingsStyles = settingsStyle();

	const { data: enableLeaveDetails } = useGetOrganizationLeaveSettingsQuery({
		variables: {
			orgId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (enableLeaveDetails) {
			setIsApplyLeave(
				enableLeaveDetails?.organization_by_pk?.enable_leave || false
			);
		}
	}, [enableLeaveDetails]);

	const handleChange = (value: boolean) => {
		setIsApplyLeave(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsApplyLeave(!isApplyLeave);
		setIsWarningModalShown(false);
	};

	const [updateLeaveFunction, { loading: updateLeaveInfoLoading }] =
		useUpdateLeaveMutation({
			variables: {
				orgId,
				value: isApplyLeave,
			},
		});

	const handleSave = () => {
		updateLeaveFunction({
			variables: {
				orgId,
				value: isApplyLeave,
			},
		}).then(() => {
			notify(ENABLE_APPLY_LEAVE_NOTIFICATION_MESSAGE);
			setIsWarningModalShown(false);
		});
	};

	return (
		<>
			<Form onSubmit={handleSave} initialValues={{ leaveValue: isApplyLeave }}>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div className={leaveSettingStyle.container}>
								<Box
									className={settingsStyles.inputContainer}
									display='flex'
									flexDirection='column'
								>
									<>
										<div className={settingsStyles.inputContainer}>
											<BooleanInput
												label='Enable Apply Leave'
												source='leaveValue'
												onChange={handleChange}
											/>
										</div>
										<Typography className={leaveSettingStyle.description}>
											​Enabling this option will allow users to apply for leave.
										</Typography>
									</>
								</Box>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={preventSubsequentClick(onWarningModalClose, 1000)}
				warningMessage={ENABLE_APPLY_LEAVE_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
				isLoading={updateLeaveInfoLoading}
			/>
		</>
	);
};

export default LeaveSetting;
