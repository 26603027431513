import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import { useParams } from 'react-router-dom';

import { EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
	GET_USERS,
	ADD_CRM_TASK,
	GET_CRM_TASK_STATUS,
	GET_CRM_TASK_ACTIVITIES,
} from './Opportunities.gql';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import head from 'lodash/head';
import {
	SUGGESTION_DEFAULT_PIC,
	DEFAULT_REMINDER_TIME,
} from '../../config/constant';
import { debounce } from 'lodash';
import draftToHtml from 'draftjs-to-html';

import './addTaskEditor.css';
import dayjs from 'dayjs';
import { useNotify, useRefresh } from 'ra-core';
import {
	ThemeProvider,
	createTheme,
	Button,
	makeStyles,
	Box,
	InputAdornment,
	IconButton,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import { UserProfileContext } from '../../App';

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '10px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const addTaskEditorStyle = makeStyles({
	editor: {
		background: '#FFFFFF 0% 0% no-repeat padding',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'text',
		height: '78px',
		margin: '10px 0px',
		width: '100%',
		textAlign: 'left',
		overflowY: 'auto',
	},
	container: {
		width: '100%',
		padding: '0px 10px 10px 10px',
	},
	saveButton: {
		width: '70px',
		height: '19px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4285F4',
		textTransform: 'none',
		marginRight: '10px',
	},
	cancelButton: {
		width: '70px',
		height: '19px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		textTransform: 'none',
		marginRight: '10px',
	},
});

const mentionPlugin = createMentionPlugin({
	entityMutability: 'IMMUTABLE',
	supportWhitespace: true,
});
const { MentionSuggestions } = mentionPlugin;
const plugins = [mentionPlugin];

export const AddTaskEditor = ({ onClose, opportunityId }) => {
	const { id: userId } = useContext(UserProfileContext);
	const { id: boardId } = useParams();
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [taskInitialValues, setTaskInitialValues] = useState({});
	const [dueDate, setDueDate] = useState(
		dayjs()
			.add(1, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.format('YYYY-MM-DDTHH:mm')
	);
	const [searchedUser, setSearchedUser] = useState('');
	const [open, setOpen] = useState(false);
	const addTaskEditorStyles = addTaskEditorStyle();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty()
	);
	const editor = useRef(null);
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: users } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%${searchedUser}%`,
			boardId,
		},
		skip: searchedUser?.length < 2,
	});

	const { data: crmTaskActivities } = useApolloQuery(GET_CRM_TASK_ACTIVITIES);

	const [addCrmTask] = useApolloMutation(ADD_CRM_TASK);

	const { data: crmTaskStatus } = useApolloQuery(GET_CRM_TASK_STATUS);

	useEffect(() => {
		if (!users) {
			return;
		}
		const userSuggestion = users.kanban_board_invited_users.map((user) => ({
			id: user.user?.id,
			name: `${user?.user?.first_name || ''} ${user?.user?.last_name || ''}`,
			link: user.user?.id,
			avatar: user?.user?.profile_pic || SUGGESTION_DEFAULT_PIC,
		}));
		setMentionSuggestions(userSuggestion);
	}, [users]);

	useEffect(() => {
		if (!crmTaskStatus) {
			return;
		}
		const todoStatus = crmTaskStatus?.crm_task_activity_type.find(
			(value) => value?.value === 'todo'
		);
		setTaskInitialValues((previousTaskInitialValues) => ({
			...previousTaskInitialValues,
			taskStatus: todoStatus.id,
		}));
	}, [crmTaskStatus]);

	useEffect(() => {
		if (!crmTaskActivities) {
			return;
		}
		const crmActivity = crmTaskActivities.crm_task_activity_type.find(
			(crmTaskActivity) => crmTaskActivity.value === 'todo'
		);
		setTaskInitialValues((previousTaskInitialValues) => ({
			...previousTaskInitialValues,
			taskActivityType: crmActivity.id,
		}));
	}, [crmTaskActivities]);

	const setSearchedUserValue = useCallback(
		debounce((newValue) => setSearchedUser(newValue), 500),
		[]
	);

	const onSearchChange = ({ value }) => {
		if (!value || value.length < 2) {
			return;
		}
		setSearchedUserValue(value);
	};

	const focusEditor = () => {
		editor.current.focus();
	};

	const getMentions = (editorBlock) => {
		if (!editorBlock) {
			return;
		}
		let mentions = [];
		for (let key in editorBlock.entityMap) {
			const entity = editorBlock.entityMap[key];
			if (entity.type === 'mention') {
				mentions.push(entity.data.mention);
			}
		}
		return mentions;
	};

	const customEntityTransform = (entity, text) => {
		if (entity.type !== 'mention') return;
		return `<a href="">${text}</a>`;
	};

	const getContent = async () => {
		if (
			!editorState ||
			!editorState.getCurrentContent().getPlainText().trim()
		) {
			notify('Please enter task', 'warning');
			return;
		}
		const contentState = editorState.getCurrentContent();
		const raw = convertToRaw(contentState);
		const mentions = await getMentions(raw);
		const htmlFormattedContent = draftToHtml(
			raw,
			{},
			false,
			customEntityTransform
		);

		return {
			content: htmlFormattedContent,
			mentionedUser: mentions,
		};
	};

	const getReminderDate = (dueDate, numberOfDaysToReduce) => {
		if (!dueDate || !numberOfDaysToReduce) {
			return;
		}
		const reminderDate = dayjs(dueDate)
			.subtract(numberOfDaysToReduce, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString();
		return reminderDate;
	};

	const getDefaultDueDate = () =>
		dayjs()
			.add(1, 'day')
			.set('hour', DEFAULT_REMINDER_TIME.hour)
			.set('minute', DEFAULT_REMINDER_TIME.minutes)
			.set('second', DEFAULT_REMINDER_TIME.seconds)
			.toISOString();

	const onSave = () => {
		if (!editorState) {
			return;
		}
		getContent().then((response) => {
			if (!response) {
				return;
			}
			const defaultDueDate = getDefaultDueDate();

			addCrmTask({
				variables: {
					data: {
						name: response.content,
						opportunity_id: opportunityId,
						due_date: dueDate !== null ? dueDate : defaultDueDate,
						activity_type_id: taskInitialValues?.taskActivityType,
						owner_id: head(response.mentionedUser)?.id || userId,
						remind_at: dueDate
							? getReminderDate(dueDate, 1)
							: getReminderDate(defaultDueDate, 1),
					},
				},
			})
				.then((addCrmTaskResponse) => {
					if (!addCrmTaskResponse.errors) {
						notify('Task created Successfully');
						refresh();
						onClose();
					}
				})
				.catch((error) => {
					if (error) {
						return;
					}
				});
		});
	};

	return (
		<>
			<Box className={addTaskEditorStyles.container}>
				<Box className={addTaskEditorStyles.editor} onFocus={focusEditor}>
					<Editor
						ref={editor}
						editorState={editorState}
						plugins={plugins}
						onChange={(editorState) => {
							if (!editorState) {
								return;
							}
							setEditorState(editorState);
						}}
						overflow-y
					/>
					<MentionSuggestions
						className='mentionSuggestions'
						open={open}
						onSearchChange={onSearchChange}
						suggestions={mentionSuggestions}
						onOpenChange={(event) => {
							setOpen(event);
						}}
					/>
				</Box>
				<ThemeProvider theme={dateTimePickerTheme}>
					<form
						style={{
							marginBottom: '8px',
						}}
						noValidate
					>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DateTimePicker
								variant='inline'
								value={dueDate}
								format='MMM D, YYYY h:mm A'
								onChange={(date) => {
									setDueDate(date);
								}}
								label='Due Date'
								showTodayButton
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton>
												<EventIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</MuiPickersUtilsProvider>
					</form>
				</ThemeProvider>
				<Button className={addTaskEditorStyles.saveButton} onClick={onSave}>
					Save
				</Button>
				<Button className={addTaskEditorStyles.cancelButton} onClick={onClose}>
					Cancel
				</Button>
			</Box>
		</>
	);
};

export default AddTaskEditor;
