import React, { useState, useEffect, useContext } from 'react';
import {
	List,
	Datagrid,
	TextField as TextComponent,
	ReferenceField,
	FunctionField,
	useQuery,
	setSidebarVisibility,
	SelectInput,
} from 'react-admin';

import { useHistory } from 'react-router';
import { OpportunitiesKanban } from './OpportunitiesKanban.component';
import OpportunitiesForm from './OpportunitiesForm.component';
import {
	GET_OPPORTUNITIES_STATUS,
	GET_OPPORTUNITY_STAGE_SUM,
	GET_OPPORTUNITY_KANBAN,
} from './Opportunities.gql';
import {
	OpportunityKanbanColumnView,
	OpportunityKanbanModel,
} from './OpportunitiesKanban.model';
import { useQuery as useApolloQuery, useLazyQuery } from '@apollo/client';
import { KanbanIcon, ListIcon, InsightsIcon } from '../../assets/SvgIcons';
import { OpportunityStatus } from './DetailsPage/modal';
import { isEmpty, head, orderBy } from 'lodash';
import { TextField, Chip, Tooltip, Typography } from '@material-ui/core';
import { SearchIcon } from '../../assets/SvgIcons';
import AddIcon from '@material-ui/icons/Add';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { getCurrencyFormat } from '../../Utils/string.util';
import { searchBarTheme, modalFormTheme } from '../../Layout/Theme.component';
import { globalStyles } from '../../Layout/styles';
import { UNASSIGNED_USER } from './constant';
import OpportunityInsights from './OpportunityInsight.component';
import { OpportunityFilterFormBody } from './OpportunitiesFilterFormBody.component';
import FilterListIcon from '@material-ui/icons/FilterList';
import CachedIcon from '@material-ui/icons/Cached';
import AppliedKanbanFiltersContainer from '../../SharedComponents/AppliedKanbanFilter.component';
import OpportunityKanbanFilterContainer from '../../SharedComponents/OpportunityKanbanFilterContainer.component';
import { useDispatch } from 'react-redux';
import { UserProfileContext } from '../../App';
import {
	useGetCrmBoardNameQuery,
	useGetOpportunityStatusQuery,
	useGetkanbanBoardInvitedUsersQuery,
} from '../../generated/graphql';
import InviteUserForm from './InviteUserForm.component';
import { Form } from 'react-final-form';
import flatten from 'lodash/flatten';

import './kanban.css';

interface TaskStatusList {
	kanban_board: {
		kanban_columns: {
			id: string;
			name: string;
		}[];
	}[];
}

interface User {
	id?: string;
	name?: string;
}

interface FilterUser {
	presales_owner_id: string[];
}

export const OpportunitiesKanbanContainer = (props: any) => {
	const { permissions, id: userId } = useContext<any>(UserProfileContext);
	const { id: boardId } = useParams<{ id: string }>();
	const history = useHistory();
	const [isListShown, setIsListShown] = useState(false);
	const [searchedOpportunity, setSearchedOpportunity] = useState('');
	const [isCreateOpportunityModalShown, setIsCreateOpportunityModalShown] =
		useState(false);
	const [kanbanColumnId, setKanbanColumnId] = useState<string>('');
	const [opportunityOptions, setOpportunityOptions] =
		useState<OpportunityStatus[]>();
	const [pipelineTitle, setPipelineTitle] = useState('');
	const commonStyles = globalStyles();
	// const classes = useStyles();
	const [isOpportunityInsightsShown, setIsOpportunityInsightsShown] =
		useState(false);
	const { data: opportunitiesStatus } = useApolloQuery(
		GET_OPPORTUNITIES_STATUS
	);
	const [isCustomDrawerShown, setCustomDrawerShown] = useState(false);
	const [filterFormValue, setFilterFormValue] = useState<any>([]);
	const [isFilterCleared, setIsFilterCleared] = useState(false);
	const [isInviteUserModalShown, setIsInviteUserModalShown] = useState(false);
	const [filterUser, setFilterUser] = useState(userId);
	const [userFilterChoices, setUserFilterChoices] = useState<any>();
	const [opportunityIds, setOpportunityIds] = useState<string[]>([]);
	const [opportunityCount, setOpportunityCount] = useState(0);
	const [filterId, setFilterId] = useState<any>();
	const [kanbanColumnViews, setKanbanColumnViews] = useState<
		OpportunityKanbanColumnView[]
	>([]);
	const [isPrimaryUser, setIsPrimaryUser] = useState(true);
	const [loadKanban, { data: kanbanBoard, loading }] =
		useLazyQuery<OpportunityKanbanModel>(GET_OPPORTUNITY_KANBAN, {
			variables: {
				boardId,
				opportunity: {
					name: searchedOpportunity
						? { _ilike: `%${searchedOpportunity}%` }
						: {},
					company_id: filterFormValue?.company_id
						? { _in: filterFormValue?.company_id }
						: {},
					lead_owner_id: filterFormValue?.owner_id
						? { _in: filterFormValue?.owner_id }
						: {},
					win_percentage: filterFormValue?.win_percentage
						? {
								_gte: filterFormValue?.win_percentage[0],
								_lte: filterFormValue?.win_percentage[1],
						  }
						: {},
					owner_id: filterId?.presales_owner_id
						? { _in: filterId?.presales_owner_id }
						: {},
					value: filterFormValue?.value
						? {
								_gte: filterFormValue?.value[0],
								_lte: filterFormValue?.value[1],
						  }
						: {},
				},
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		loadKanban();
	}, [loadKanban]);

	useEffect(() => {
		if (!kanbanBoard) {
			return;
		}
		const columns = kanbanBoard?.kanban_board[0]?.kanban_columns;
		const kanbanColumns =
			columns &&
			columns.length > 0 &&
			columns.map((column) => ({
				...column,
				cards: column?.cards.map((card, index) => ({
					name: card?.opportunity?.name,
					id: card.id,
					opportunity_id: card?.opportunity?.id,
					ordinal: card.ordinal,
					columnId: card.column_id,
					index,
					bdOwnerName: card?.opportunity?.user?.full_name
						? `${card?.opportunity?.user?.full_name}`
						: UNASSIGNED_USER,
					bdOwnerPic: card?.opportunity?.user?.profile_pic,
					priority: card?.opportunity?.priority,
					winPercentage: card?.opportunity?.win_percentage,
					value: card?.opportunity?.value,
					opportunityId: card?.opportunity?.id,
					currency: card?.opportunity?.master_currency?.currency_type,
					numberOfOverDueTasks:
						card?.opportunity?.crm_tasks_aggregate?.aggregate?.count,
				})),
			}));
		const opportunityIds =
			kanbanColumns &&
			kanbanColumns.length > 0 &&
			kanbanColumns.map((value: any) => {
				return value?.cards?.map((data: any) => data.opportunity_id);
			});
		if (kanbanColumns && kanbanColumns.length > 0) {
			setKanbanColumnViews(kanbanColumns);
			if (opportunityIds && opportunityIds.length > 0) {
				setOpportunityIds(flatten(opportunityIds));
			}
		}
		setOpportunityCount(kanbanBoard?.opportunityCount?.aggregate.count || 0);
	}, [kanbanBoard]);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const [opportunityStageTotal, setOpportunityStageTotal] = useState([]);
	const { data: opportunityStatusOptions } = useGetOpportunityStatusQuery({
		variables: {
			boardId,
		},
	});

	const { data: OpportunityStageSum, refetch: refetchStageValue } =
		useApolloQuery(GET_OPPORTUNITY_STAGE_SUM, {
			variables: {
				boardId,
			},
		});

	const { data: crmBoard } = useGetCrmBoardNameQuery({
		variables: {
			boardId,
		},
	});

	const { data: invitedUser } = useGetkanbanBoardInvitedUsersQuery({
		variables: {
			board_id: boardId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (isEmpty(invitedUser?.kanban_board_invited_users)) {
			setUserFilterChoices([]);
			return;
		}
		const inviteUserChoices = invitedUser?.kanban_board_invited_users?.map(
			(value) => {
				if (!value) {
					return;
				}
				if (value?.user?.id === userId) {
					setIsPrimaryUser(value?.is_primary);
					return {
						id: value?.user?.id,
						name: 'Me',
					};
				}
				return {
					name: `${value?.user?.full_name} `,
					id: value?.user?.id,
				};
			}
		);
		const sortedInviteUserChoices = orderBy(
			inviteUserChoices,
			['name'],
			['asc']
		);
		setUserFilterChoices(sortedInviteUserChoices);
	}, [invitedUser, userId]);

	useEffect(() => {
		setFilterId((previousFilterValues: any) => ({
			...previousFilterValues,
			presales_owner_id: [userId],
		}));
	}, [userId]);

	useEffect(() => {
		if (isEmpty(crmBoard?.kanban_board)) {
			return;
		}
		const pipelineTitle = crmBoard?.kanban_board[0];
		if (pipelineTitle) {
			setPipelineTitle(pipelineTitle?.name);
		}
	}, [crmBoard]);

	useEffect(() => {
		if (isEmpty(opportunityStatusOptions)) {
			return;
		}
		const opportunityStatusId = head(
			head(opportunityStatusOptions?.kanban_board)?.kanban_columns
		)?.id;
		setKanbanColumnId(opportunityStatusId);
		const statusOption = head(
			opportunityStatusOptions?.kanban_board
		)?.kanban_columns?.map((value: OpportunityStatus) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		const sortedStatusOption = orderBy(
			statusOption,
			[(status) => status.name?.toUpperCase()],
			['asc']
		);
		setOpportunityOptions(sortedStatusOption);
	}, [opportunityStatusOptions]);

	useEffect(() => {
		if (!OpportunityStageSum) {
			return;
		}
		setOpportunityStageTotal(OpportunityStageSum?.opportunites_sum_by_stage);
	}, [OpportunityStageSum]);

	const { data: currencyList } = useQuery({
		type: 'GET_LIST',
		resource: 'master_currencies',
		payload: {},
	});

	const opportunityKanbanAppliedFilters = (filter: any, filterValues: any) => {
		switch (filter) {
			case 'company_id':
				return (
					filterValues?.company_id.length > 0 &&
					filterValues?.company_id.map((company_id: any, index: any) => (
						<div key={index}>
							<ReferenceField
								source='id'
								reference='crm_company'
								resource='crm_company'
								record={{ id: company_id }}
								link={false}
							>
								<FunctionField
									render={(record: any) => {
										return (
											<>
												<Chip
													key={index}
													label={`${record?.name}`}
													variant='outlined'
												/>
											</>
										);
									}}
								/>
							</ReferenceField>
						</div>
					))
				);
			case 'owner_id':
				return (
					filterFormValue?.owner_id.length > 0 &&
					filterFormValue?.owner_id.map((owner_id: any, index: number) => (
						<ReferenceField
							link={false}
							source='id'
							reference='user'
							label='Full Name'
							resource='user'
							record={{ id: owner_id }}
						>
							<FunctionField
								label='Full Name'
								render={(record: any) => (
									<>
										<Chip
											key={index}
											label={`${record?.first_name || ''} ${
												record?.last_name || ''
											}`}
											variant='outlined'
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'value':
				return (
					<Chip
						label={`Value: ${filterValues?.value[0]}-${filterValues?.value[1]}`}
						variant='outlined'
					/>
				);
			case 'win_percentage':
				return (
					<Chip
						label={`Win Percentage: ${filterValues?.win_percentage[0]}-${filterValues?.win_percentage[1]}`}
						variant='outlined'
					/>
				);
			default:
				return <></>;
		}
	};

	useEffect(() => {
		refetchStageValue();
	}, [refetchStageValue]);

	return (
		<>
			<div className={'navigationTabContainer'}>
				<button
					className={[
						'navigationTabButtons',
						!isOpportunityInsightsShown ? 'activeNavigationTabButton' : '',
					].join(' ')}
					onClick={() => {
						setIsOpportunityInsightsShown(false);
					}}
				>
					<Tooltip
						title={`${pipelineTitle}(${opportunityCount})`}
						placement='right'
					>
						<Typography className={` ${commonStyles?.ellipsis} `}>
							{pipelineTitle}({opportunityCount})
						</Typography>
					</Tooltip>
				</button>
				{permissions?.appPermissions?.ui?.viewOpportunitiesInsights && (
					<button
						className={[
							'navigationTabButtons',
							isOpportunityInsightsShown ? 'activeNavigationTabButton' : '',
						].join(' ')}
						onClick={() => {
							setIsOpportunityInsightsShown(true);
						}}
					>
						<InsightsIcon />
						Insights
					</button>
				)}
			</div>
			<button
				onClick={() => history.goBack()}
				className={commonStyles.backButton}
			>
				Back
			</button>

			<div>
				<AppliedKanbanFiltersContainer
					clearFilters={() => {
						setFilterFormValue([]);
						setIsFilterCleared(true);
					}}
					filterValues={filterFormValue}
				>
					{opportunityKanbanAppliedFilters}
				</AppliedKanbanFiltersContainer>
			</div>
			{!isOpportunityInsightsShown ? (
				<>
					<div>
						<div>
							{/* <TopToolbar style={{paddingTop:'2px'}}> */}
							<div className={'toolContainer'}>
								<div className={'toolbar'}>
									<form onSubmit={(event) => event.preventDefault()}>
										<ThemeProvider theme={searchBarTheme}>
											<div className={'searchBar'}>
												<TextField
													placeholder='Search opportunities'
													label={false}
													fullWidth
													InputLabelProps={{ style: { fontSize: 0 } }}
													InputProps={{
														startAdornment: <SearchIcon />,
													}}
													onChange={(e) =>
														setSearchedOpportunity(e?.target?.value)
													}
													value={searchedOpportunity}
												/>
											</div>
										</ThemeProvider>
									</form>
									<div className={'assigneFilter'}>
										<Form
											onSubmit={() => {}}
											initialValues={{ assignee: filterUser }}
										>
											{() => (
												<ThemeProvider theme={modalFormTheme}>
													<form>
														<SelectInput
															source={'assignee'}
															label={''}
															onChange={(event: any) => {
																if (event?.target?.value === 'All') {
																	setFilterUser(event?.target?.value);
																	setFilterId({});
																} else {
																	setFilterUser(event?.target?.value);
																	setFilterId((previousFilterValues: any) => ({
																		...previousFilterValues,
																		presales_owner_id: [event?.target?.value],
																	}));
																}
															}}
															choices={
																userFilterChoices?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...userFilterChoices,
																	  ]
																	: []
															}
														/>
													</form>
												</ThemeProvider>
											)}
										</Form>
									</div>
									<div>
										<button
											onClick={() => setIsInviteUserModalShown(true)}
											className={'inviteUserButton'}
											disabled={
												!permissions?.appPermissions?.ui?.inviteKanbanUsers &&
												!isPrimaryUser
											}
										>
											Invite User
										</button>
									</div>
								</div>
								<OpportunityKanbanFilterContainer
									isFilterCleared={isFilterCleared}
									choosedFilters={filterFormValue}
									formValue={(value: any) => setFilterFormValue(value)}
									open={isCustomDrawerShown}
									close={() => {
										setCustomDrawerShown(false);
									}}
									clearFilters={() => setFilterFormValue([])}
								>
									<OpportunityFilterFormBody />
								</OpportunityKanbanFilterContainer>

								<div
									style={{
										marginRight: isListShown ? 0 : '160px',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<button
										hidden={isListShown}
										className={'filterButton'}
										onClick={() => loadKanban()}
									>
										<CachedIcon />
									</button>
									<button
										className={[
											'iconButton',
											!isListShown ? 'activeIconButton' : '',
										].join(' ')}
										onClick={() => {
											setIsListShown(false);
										}}
									>
										<KanbanIcon />
									</button>
									<button
										className={[
											'iconButton',
											isListShown ? 'activeIconButton' : '',
										].join(' ')}
										onClick={() => {
											setIsListShown(true);
										}}
									>
										<ListIcon />
									</button>
									<button
										className={'filterButton'}
										onClick={() => setCustomDrawerShown(true)}
									>
										<FilterListIcon />
									</button>
									{permissions?.opportunities?.insert_permissions && (
										<button
											className={'addOpportunityButton'}
											onClick={() => {
												setIsCreateOpportunityModalShown(true);
											}}
										>
											<AddIcon />
											Add Opportunity
										</button>
									)}
								</div>
							</div>
							{/* </TopToolbar> */}
						</div>
					</div>

					{!isListShown ? (
						<OpportunitiesKanban
							filterFormValue={filterFormValue}
							addOpportunity={(kanbanColumnId: string) => {
								setKanbanColumnId(kanbanColumnId);
								setIsCreateOpportunityModalShown(true);
							}}
							stageValue={opportunityStageTotal}
							refetchStageValue={() => refetchStageValue()}
							kanbanColumnViews={kanbanColumnViews}
							isKanbanLoading={loading}
						/>
					) : (
						<div className={'contentContainer'}>
							<List
								{...props}
								filters={<div className={'filterStyle'}></div>}
								actions={false}
								sort={{ field: 'name', order: 'ASC' }}
								resource='opportunities'
								basePath='/opportunities'
								bulkActionButtons={false}
								filter={{ name: searchedOpportunity, id: opportunityIds }}
							>
								<Datagrid
									className={commonStyles.listStyle}
									rowClick={(id) => `/opportunities/${id}`}
								>
									<TextComponent source='name' label='NAME' />
									<ReferenceField
										source='company_id'
										label='CUSTOMER NAME'
										reference='crm_company'
										link={false}
										sortBy='crm_company.name'
										sortByOrder='ASC'
									>
										<FunctionField
											render={(record: any) => `${record?.name}`}
										/>
									</ReferenceField>
									<ReferenceField
										source='primary_contact_id'
										label='CONTACT NAME'
										reference='crm_contacts'
										link={false}
										sortBy='crm_contact.first_name'
										sortByOrder='ASC'
									>
										<FunctionField
											render={(record: any) =>
												`${record?.first_name || ''} ${record?.last_name || ''}`
											}
										/>
									</ReferenceField>
									<FunctionField
										label='VALUE'
										source='value'
										render={(record: any) => {
											const currency =
												currencyList?.length > 0 &&
												currencyList.find(
													(item: any) => item?.id === record?.currency_id
												);
											return getCurrencyFormat(
												record?.value,
												currency?.currency_type,
												true
											);
										}}
									/>
									<FunctionField
										source='win_percentage'
										label='PROBABILITY'
										sortBy='win_percentage'
										sortByOrder='ASC'
										render={(record: any) => `${record?.win_percentage || 0}%`}
									/>
									<FunctionField
										label='STATUS'
										render={(record: any) =>
											opportunitiesStatus?.opportunities.map(
												(opportunity: any, index: number) =>
													opportunity.id === record.id && (
														<div key={index} className={'statusContainer'}>
															{opportunity?.kanbanCard?.kanban_column?.name}
														</div>
													)
											)
										}
									/>
									<FunctionField
										source='priority'
										label='PRIORITY'
										sortBy='priority'
										sortByOrder='ASC'
										render={(record: any) => (
											<div
												className={`${'priorityContainer'}
                                ${
																	record.priority === 'High'
																		? 'priorityHigh'
																		: record.priority === 'Medium'
																		? 'priorityMedium'
																		: record.priority === 'Low'
																		? 'priorityLow'
																		: undefined
																}
                                `}
											>
												{record?.priority}
											</div>
										)}
									/>
								</Datagrid>
							</List>
						</div>
					)}
				</>
			) : (
				<OpportunityInsights />
			)}
			<OpportunitiesForm
				onClose={() => setIsCreateOpportunityModalShown(false)}
				open={isCreateOpportunityModalShown}
				kanbanColumnId={kanbanColumnId}
				onSuccess={() => {
					refetchStageValue();
					loadKanban();
				}}
				opportunityOptions={opportunityOptions}
			/>
			<InviteUserForm
				open={isInviteUserModalShown}
				onClose={() => setIsInviteUserModalShown(false)}
				boardId={boardId}
			/>
		</>
	);
};

export default OpportunitiesKanbanContainer;
