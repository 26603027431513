import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { detailsLeftCardStyle } from '../../../Layout/styles';
import { Asset } from '../AssetManagement.model';
import { getImage } from '../Constant';
import AddAssetModal from '../AddAssetModal.component';
import { UserProfileContext } from '../../../App';
import { calculateDateDifference } from '../../../Utils/date-time.util';
import { ellipsisStyle } from '../../../Layout/styles';

const useStyles = makeStyles((theme) => ({
	secondaryInfoField: {
		font: 'normal normal 600 12px/20px Manrope !important',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
	},
	infoBar: {
		width: '80px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		textAlign: 'left',
	},
	avatar: {
		width: ' 55px',
		height: '55px',
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		opacity: '1',
		alignItems: 'center',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
	},
	warranty: {
		marginTop: '20px',
		marginBottom: '15px',
		width: '56px',
		height: '14px',
		textAlign: 'left',
		font: 'normal normal 800 10px/31px Manrope',
		color: '#5C5C5C',
		textTransform: 'uppercase',
		opacity: '1',
	},
	greenDot: {
		height: '7px',
		width: ' 7px',
		backgroundColor: '#34A853',
		marginRight: '8px',
		borderRadius: '50%',
		display: ' inline-block',
	},
	yellowDot: {
		height: '7px',
		width: ' 7px',
		backgroundColor: '#F4CF06',
		marginRight: '8px',
		borderRadius: '50%',
		display: ' inline-block',
	},
	redDot: {
		height: '7px',
		width: ' 7px',
		backgroundColor: 'red',
		marginRight: '8px',
		borderRadius: '50%',
		display: ' inline-block',
	},
	expiredWarranty: {
		color: '#EA4335',
		fontFamily: 'Manrope',
	},
	leftWarranty: {
		fontFamily: 'Manrope',
		color: '#34A853',
	},
	days: {
		fontFamily: 'Manrope',
		color: '#292929',
		paddingLeft: '2px',
	},
	warrantyContainer: {
		font: 'normal normal 600 12px/40px Manrope !important',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '12px !important',
		padding: '0 5px 0 10px',
	},
}));
interface Props {
	record: Asset;
	refetch: () => void;
	basePath: string;
	resource: string;
}
export const AssetDetailsCard = (props: Props) => {
	const { record: asset, refetch } = props;
	const { permissions } = useContext<any>(UserProfileContext);
	const [isEditModalShown, setIsEditModalShown] = useState(false);
	const classes = useStyles();
	const cardClass = detailsLeftCardStyle();
	const styles = ellipsisStyle();

	const AssetState = () => {
		if (asset?.in_repair) {
			return (
				<>
					<span className={classes.redDot}></span> In Repair
				</>
			);
		}
		if (asset?.user) {
			return (
				<>
					<span className={classes.yellowDot}></span> In Use
				</>
			);
		}
		return (
			<>
				<span className={classes.greenDot}></span> In Stock
			</>
		);
	};

	const getWarrantyState = (date: string) => {
		const diffDays = calculateDateDifference(date);
		const warrantyDays = `${diffDays} Days`;
		if (diffDays <= 0) {
			return <span className={classes.expiredWarranty}>Warranty Expired</span>;
		}
		if (diffDays > 0)
			return (
				<>
					<span className={classes.leftWarranty}>{warrantyDays}</span>
					<span className={classes.days}>left in warranty</span>
				</>
			);
	};
	return (
		<div className={cardClass.container}>
			<div className={cardClass.avatarContainer}>
				<div className={cardClass.avatarField}>
					<div className={classes.avatar}>
						<img src={getImage(asset?.asset_type?.type)} alt='logo' />
					</div>
				</div>
				<div className={cardClass.editButtonContainer}>
					{permissions?.asset?.update_permissions && (
						<div
							onClick={() => setIsEditModalShown(true)}
							className={cardClass.editButton}
						>
							<EditIcon className={cardClass.editIcon} />
						</div>
					)}
				</div>
			</div>

			<div className={cardClass.identityContainer}>
				<div className={cardClass.nameField}>
					<Tooltip title={`${asset?.name}`} placement='right'>
						<Typography className={`${cardClass.assetName} ${styles.ellipsis}`}>
							{asset?.name}
						</Typography>
					</Tooltip>
				</div>
				<div className={classes.secondaryInfoField}>
					{asset?.asset_type?.type}
				</div>
				<div className={classes.infoBarContainer}>
					<div className={classes.infoBar}>
						<div className={classes.infoField}>
							<AssetState />
						</div>
					</div>
				</div>
				<div className={classes.secondaryInfoField}>
					<div className={classes.warrantyContainer}>
						{asset?.warranty_expiration_date
							? getWarrantyState(asset?.warranty_expiration_date)
							: 'Warranty -NA-'}
					</div>
				</div>
			</div>

			<div className={cardClass.addtionalInfoContainer}>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Asset ID
					</Typography>

					<Tooltip title={`${asset?.asset_id || '- -'}`} placement='right'>
						<Typography
							className={`${cardClass.additionalInfoValue} ${styles.ellipsis}`}
						>
							{asset?.asset_id || '- -'}
						</Typography>
					</Tooltip>
				</div>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Assigned to
					</Typography>
					<Tooltip
						title={`${asset?.user ? `${asset?.user.full_name}` : '- -'}`}
						placement='right'
					>
						<div
							className={`${cardClass.additionalInfoValue} ${styles.ellipsis}`}
						>
							{asset?.user ? `${asset?.user.full_name}` : '- -'}
						</div>
					</Tooltip>
				</div>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Managed By
					</Typography>
					<Tooltip
						title={`${
							asset?.userByManagedBy
								? `${asset?.userByManagedBy.full_name} `
								: '- -'
						}`}
						placement='right'
					>
						<div
							className={`${cardClass.additionalInfoValue} ${styles.ellipsis}`}
						>
							{asset?.userByManagedBy
								? `${asset?.userByManagedBy.full_name} `
								: '- -'}
						</div>
					</Tooltip>
				</div>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Department
					</Typography>

					<Tooltip
						title={`${asset?.departmentID?.name || ' - -'}`}
						placement='right'
					>
						<div
							className={`${cardClass.additionalInfoValue} ${styles.ellipsis}`}
						>
							{`${asset?.departmentID?.name || ' - -'}`}
						</div>
					</Tooltip>
				</div>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Cost
					</Typography>
					<div className={cardClass.additionalInfoValue}>{`${
						asset?.currency?.symbol
					}${asset?.cost || '- -'}`}</div>
				</div>
				<div className={cardClass.additionalContent}>
					<Typography className={cardClass.additionalInfoLabel}>
						Location
					</Typography>
					<Tooltip
						title={`${
							asset?.org_location ? `${asset?.org_location?.name}` : '- -'
						}`}
						placement='right'
					>
						<div
							className={`${cardClass.additionalInfoValue} ${styles.ellipsis}`}
						>
							{asset?.org_location ? `${asset?.org_location?.name}` : '- -'}
						</div>
					</Tooltip>
				</div>
			</div>
			<AddAssetModal
				open={isEditModalShown}
				closeModal={() => setIsEditModalShown(false)}
				record={asset}
				refetch={() => refetch()}
			/>
		</div>
	);
};

export default AssetDetailsCard;
