import React, { useContext, useState } from 'react';
import {
	GET_COMPANY_NOTES,
	UPSERT_COMPANY_NOTE,
	DELETE_COMPANY_NOTE,
} from '../CompanyServices.gql';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useRefresh, useNotify, useQuery } from 'react-admin';
import { useParams } from 'react-router-dom';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import Notes from '../../../SharedComponents/Notes/Notes';
import { UserProfileContext } from '../../../App';

const CompanyNote = () => {
	const { permissions } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const [searchCompanyNotes, setSearchCompanyNotes] = useState('');
	const [noteId, setNoteId] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();

	const [upsertCrmCompanyNote,{loading:isCompanyNoteLoading}] = useApolloMutation(UPSERT_COMPANY_NOTE);
	const [deleteCompanyNote] = useApolloMutation(DELETE_COMPANY_NOTE);
	const { data: companyNotes, refetch: refetchCompanyNotes } = useApolloQuery(
		GET_COMPANY_NOTES,
		{
			variables: {
				company_id: id ? id : null,
				SearchText: `%${searchCompanyNotes}%`,
			},
			fetchPolicy:'network-only'
		},
	);

	const { data: companyNotesList } = useQuery({
		type: 'GET_LIST',
		resource: 'crm_company_note',
		payload: {
			filter: {
				id: noteId ? noteId : null,
			},
		},
	});

	const upsertNote = (note: NoteForm) => {
		upsertCrmCompanyNote({
			variables: {
				companyNote: {
					company_id: id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Opportunity Notes Created successfully');
				refresh();
				refetchCompanyNotes();
			}
		});
	};

	const deleteNote = (noteId: string) => {
		deleteCompanyNote({
			variables: {
				noteId: noteId,
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Company Notes Deleted successfully');
				refresh();
				refetchCompanyNotes();
			}
		});
	};

	const saveForm = (note: NoteForm) => {
		upsertNote(note);
	};

	return (
		<>
			<Notes
				saveForm={saveForm}
				deleteNote={deleteNote}
				searchNotes={setSearchCompanyNotes}
				noteList={companyNotes?.crm_company_note}
				refetch={() => refetchCompanyNotes()}
				noteId={setNoteId}
				getNoteList={companyNotesList}
				permission={permissions?.crm_company?.update_permissions ? true : false}
				deletePermission={permissions?.crm_company_note?.update_permissions?.deleted_at ? true : false}
				loading={isCompanyNoteLoading}
			/>
		</>
	);
};

export default CompanyNote;
