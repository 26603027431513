import React from 'react';
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	BarChart,
	Bar,
	Cell,
} from 'recharts';
import { chartColorCodes } from '../constant';

function PMSBarChart(props: {
	data: {
		name: string;
		employees: number;
	}[];
	totalEmployees: number;
}) {
	const { data, totalEmployees } = props;

	const getPercentage = (value: number) =>
		((value / totalEmployees) * 100).toFixed(1);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active) {
			return (
				<div
					style={{
						background: '#FFFFFF',
						padding: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<p className='label'>{`${label} `}</p>
						<p
							style={{
								fontFamily: 'Manrope-bold',
							}}
						>
							{`: ${getPercentage(payload?.[0].value)}%`}
						</p>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<p className='desc'>{`Employees: `}</p>
						<p
							style={{
								fontFamily: 'Manrope-bold',
							}}
						>
							{payload?.[0].value}
						</p>
					</div>
				</div>
			);
		}

		return null;
	};

	return (
		<ResponsiveContainer width='50%'>
			<BarChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey='name' />
				<YAxis />
				<Tooltip content={<CustomTooltip />} />
				<Bar dataKey='employees' fill='#8884d8' label={{ position: 'top' }}>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={chartColorCodes[index % 20]} />
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}

export default PMSBarChart;
