import React from 'react'

import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        marginBottom: '20px',
    },
    detailContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '182px',
        wordWrap: 'break-word',
    },
}))

export const FieldContainer = ({
    children,
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box className={classes.detailContainer}>
                {children}
            </Box>         
        </Box>
    )
}

export default FieldContainer
