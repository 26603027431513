import React, { useState, useEffect, useContext } from 'react';
import { keyBy } from 'lodash';
import { Box, Button, Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import dayjs, { Dayjs } from 'dayjs';
import {
	Attendance,
	Date,
	SelectedMonthDates,
	Holiday,
} from '../../Attendance/Attendance.model';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { calenderTheme } from '../../../Layout/Theme.component';
import {
	useGetAttendanceTotalQuery,
	useGetTimesheetListQuery,
	useGetOrgHolidaysByUserQuery,
} from '../../../generated/graphql';
import { Style } from '../styles/HomePageMobile.styles';
import { UserProfileContext } from '../../../App';
import TimerIcon from '../../../assets/icons/asset-timer-icon-pwa.svg';

interface Props {
	setCurrentMonth: (startDate: string, endDate: string) => void;
	currentMonth: SelectedMonthDates;
	userId: string;
	selectedAttendanceMonth?: string;
	isManagerView?: boolean;
	workHoursLogged: number;
	leaveApprover: string;
	currentFinancialYear: SelectedMonthDates;
	orgId: string;
	timesheetHours?: number;
	employeeId: string;
	refetchLeaveBalance: () => void;
	userLocationId?: string | null;
	todaysAttendance: Attendance;
}

const CalenderMobile = (props: Props) => {
	const {
		setCurrentMonth,
		currentMonth,
		userId,
		isManagerView,
		selectedAttendanceMonth,
		employeeId,
		userLocationId,
		todaysAttendance,
	} = props;

	const [calenderDate, setCalenderDate] = useState(dayjs());
	const [organizationHoliday, setOrganizationHoliday] = useState<any>({});
	const [organizationAttendance, setOrganizationAttendance] = useState<Date>(
		{}
	);
	const [timesheetAttendance, setTimesheetAttendance] = useState<Date>({});
	const [todaysWorkedHour, setTodaysWorkedHour] = useState('0');
	const classes = Style();
	const { locationId, noOfLocations, attendanceConsiderableTimesheetStatus } =
		useContext<any>(UserProfileContext);

	const { data: timesheetList } = useGetTimesheetListQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
			statusList: attendanceConsiderableTimesheetStatus,
		},
	});

	useEffect(() => {
		if (todaysAttendance?.id) {
			const inTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.in_time}`
			);
			const outTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.out_time}`
			);
			const diff = dayjs.duration(outTime.diff(inTime)).format('HH:mm');
			setTodaysWorkedHour(diff);
		}
	}, [todaysAttendance]);

	const { data: selectedMonthAttendance } = useGetAttendanceTotalQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
		},
		fetchPolicy: 'network-only',
		skip: !(userId && currentMonth),
	});

	const { data: holidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter: isManagerView
				? userLocationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							location_id: { _eq: userLocationId },
					  }
					: {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
					  }
				: locationId && noOfLocations && noOfLocations > 0
				? {
						date: {
							_gte: currentMonth?.startDate,
							_lte: currentMonth?.endDate,
						},
						location_id: { _eq: locationId },
				  }
				: {
						date: {
							_gte: currentMonth?.startDate,
							_lte: currentMonth?.endDate,
						},
				  },
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!timesheetList || !(timesheetList?.timesheet?.length > 0)) {
			setTimesheetAttendance({});
			return;
		}
		const attendance = timesheetList?.timesheet.map(
			(value: Attendance) => value.date
		);
		const timesheetLookup = keyBy(attendance);
		setTimesheetAttendance(timesheetLookup);
	}, [timesheetList]);

	useEffect(() => {
		if (currentMonth && isManagerView) {
			if (currentMonth?.startDate) {
				setCalenderDate(dayjs(currentMonth?.startDate));
			}
		}
	}, [isManagerView, currentMonth]);

	useEffect(() => {
		if (!holidays || !(holidays?.org_holidays?.length > 0)) {
			return;
		}
		const holiday = holidays?.org_holidays.map(
			(holiday: Holiday) => holiday?.date
		);
		const holidayLookup = keyBy(holiday);
		isManagerView
			? !userLocationId && noOfLocations && noOfLocations > 0
				? setOrganizationHoliday([])
				: setOrganizationHoliday(holidayLookup)
			: !locationId && noOfLocations && noOfLocations > 0
			? setOrganizationHoliday([])
			: setOrganizationHoliday(holidayLookup);
	}, [holidays, isManagerView, locationId, noOfLocations, userLocationId]);

	useEffect(() => {
		if (
			!selectedMonthAttendance ||
			!(selectedMonthAttendance?.attendance?.length > 0)
		) {
			setOrganizationAttendance({});
			return;
		}
		const attendance = selectedMonthAttendance?.attendance.map(
			(value: Attendance) => value.date
		);
		const attendanceLookup = keyBy(attendance);
		setOrganizationAttendance(attendanceLookup);
	}, [selectedMonthAttendance]);

	const onDateChange = (date: Dayjs | null) => {
		if (!date) {
			return;
		}
		setCalenderDate(date);
	};

	const renderDay = (
		day: any,
		selectedDate: any,
		dayInCurrentMonth: any,
		dayComponent: any
	) => {
		var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
		dayjs.extend(isSameOrBefore);
		const calenderDate = day.format('YYYY-MM-DD');

		if (!(selectedAttendanceMonth && userId) && isManagerView) {
			return <Box className={classes.weekendMobileView}>{dayComponent}</Box>;
		}
		if (
			!!organizationAttendance[calenderDate] ||
			(timesheetAttendance[calenderDate] &&
				!dayjs().isSameOrBefore(calenderDate, 'day'))
		) {
			return <Box className={classes.presentMobileView}>{dayComponent}</Box>;
		}
		if (organizationHoliday && !!organizationHoliday[calenderDate]) {
			return <Box className={classes.holidayMobileView}>{dayComponent}</Box>;
		}
		//will change this after getting api for week days
		if (day.get('day') === 0 || day.get('day') === 6) {
			return <Box className={classes.weekendMobileView}>{dayComponent}</Box>;
		}
		if (
			!dayjs().isBefore(calenderDate, 'day') &&
			!dayjs().isSame(calenderDate, 'day') &&
			!organizationAttendance[calenderDate] &&
			organizationHoliday &&
			!organizationHoliday[calenderDate]
		) {
			return <Box className={classes.absentMobileView}>{dayComponent}</Box>;
		}
		return <Box className={classes.calenderMobileView}>{dayComponent}</Box>;
	};

	return (
		<>
			<Box className={classes.calenderTitleMobileView}>Calendar</Box>
			<Box display='flex' justifyContent='center'>
				<Box className={classes.datePickerContainerMobileView}>
					<ThemeProvider theme={calenderTheme}>
						<MuiPickersUtilsProvider utils={DayjsUtils}>
							<KeyboardDatePicker
								variant='static'
								value={calenderDate}
								disableToolbar={true}
								onChange={onDateChange}
								disableFuture={true}
								// disablePast={true}
								renderDay={renderDay}
								onMonthChange={(date: Dayjs | null) => {
									if (!date) {
										return;
									}
									setCalenderDate(date);
									const startDate = date.startOf('month').format('YYYY-MM-DD');
									const endDate = date.endOf('month').format('YYYY-MM-DD');
									setCurrentMonth(startDate, endDate);
								}}
							/>
						</MuiPickersUtilsProvider>
					</ThemeProvider>
					<Grid
						item
						container
						justifyContent={'space-between'}
						alignContent='center'
					>
						<Grid
							item
							container
							alignItems='center'
							className={classes.calenderLegendsContainer}
						>
							<Box className={classes.calenderListWeekendMobileView}></Box>
							<Box className={classes.calenderListTitleMobileView}>Weekend</Box>
						</Grid>
						<Grid
							item
							container
							alignItems='center'
							className={classes.calenderLegendsContainer}
						>
							<Box className={classes.calenderListAbsentMobileView}></Box>
							<Box className={classes.calenderListTitleMobileView}>Absent</Box>
						</Grid>
						<Grid
							item
							container
							alignItems='center'
							className={classes.calenderLegendsContainer}
						>
							<Box className={classes.calenderListHolidayMobileView}></Box>
							<Box className={classes.calenderListTitleMobileView}>Holiday</Box>
						</Grid>
						<Grid
							item
							container
							alignItems='center'
							className={classes.calenderLegendsContainer}
						>
							<Box className={classes.calenderListPresentMobileView}></Box>
							<Box className={classes.calenderListTitleMobileView}>Present</Box>
						</Grid>
					</Grid>
					<Grid
						item
						container
						className={classes.dayTypeLabelContainerMobileView}
					>
						<span className={classes.dayTypeLabel}>Working day</span>
					</Grid>
					<Grid
						item
						container
						className={classes.currDateInfoContainerMobileView}
					>
						<Grid
							item
							container
							justifyContent='space-between'
							alignItems='center'
							className={classes.currDayLabelContainer}
						>
							<span className={classes.currDayLabelBold}>
								{dayjs().format('DD MMM, YYYY')}
							</span>
							<span className={classes.currDayLabelLight}>
								{dayjs().format('dddd')}
							</span>
						</Grid>
						<Grid
							item
							container
							justifyContent='space-between'
							alignItems='center'
							className={classes.currDayLabelContainer}
						>
							<span className={classes.currDayLabelLight}>Hours worked</span>
							<Grid
								item
								container
								justifyContent='space-between'
								alignItems='center'
								className={classes.currDayTimeInfoContainerMobileView}
							>
								<img src={TimerIcon} alt='timer_icon' />
								<span className={classes.currDayLabelBold}>
									{todaysAttendance?.out_time && todaysWorkedHour !== '0'
										? `${todaysWorkedHour} Hrs`
										: '00:00 Hrs'}
								</span>
							</Grid>
						</Grid>
						<Grid
							item
							container
							justifyContent='center'
							className={classes.leaveButtonContainerMobileView}
						>
							<Button
								className={classes.addLeaveButtonMobileView}
								disabled={!(userId && employeeId)}
							>
								Apply for Leave
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default CalenderMobile;
