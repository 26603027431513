import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	makeStyles,
	ThemeProvider,
	TextField as TextComponent,
	createTheme,
	Tooltip,
} from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { UserProfileContext } from '../App';
import { dropDownEllipsisStyle, modalFormStyle } from '../Layout/styles';
import { modalFormTheme, searchBarTheme } from '../Layout/Theme.component';
import { SearchIcon } from '../assets/SvgIcons';
import { exportToCsv } from '../Utils/string.util';
import { useGetHolidaysByUserIdQuery } from '../generated/graphql';
import { SelectInput, Loading } from 'react-admin';
import { isEmpty } from 'lodash';
import ExportButtonWithLoader from './ExportButton/ExportButtonWithLoader';

const dataGridStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		maxHeight: '430px',
		overflowY: 'auto',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		color: 'grey',
		backgroundColor: 'lightgrey',
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
	loadingContainer: {
		marginBottom: '100px !important',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
	},
}));

const customizedDataGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-bold',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				width: '113px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});
interface Props {
	isManagerView: boolean;
	userLocationId?: string | null;
	selectedUserId?: string | null;
}
interface Holiday {
	name: string;
	id: any;
	date: any;
	location?: any;
	day?: any;
	optionalHoliday?: any;
	is_restricted?: boolean | null | undefined;
	org_location?:
		| {
				id: any;
				name: string;
		  }
		| null
		| undefined;
}
interface Search {
	onSearch: (value: string) => void;
	searchText: string;
}
const CustomSearch = (props: Search) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Holiday'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

const EmployeeHolidayList = (props: Props) => {
	const { isManagerView, userLocationId, selectedUserId } = props;
	const {
		dateFormat,
		noOfLocations,
		locationId,
		id: userId,
	} = useContext<any>(UserProfileContext);
	const dataGridStyles = dataGridStyle();
	const ellipsis = dropDownEllipsisStyle();

	const [searchedText, setSearchedText] = useState<string>('');
	const [holidayTypeFilter, setHolidayTypeFilter] = useState<string>('All');
	const [yearFilter, setYearFilter] = useState<number>(dayjs().get('year'));
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(0);
	const [isExportTriggered, setIsExportTriggered] = useState(false);
	const styles = modalFormStyle();

	// Queries
	const { data: orgHolidayList, loading: isDataGridLoading } =
		useGetHolidaysByUserIdQuery({
			variables: {
				holidayFilter: {
					name: { _ilike: `%${searchedText}%` },
					date: {
						_gte: dayjs().year(yearFilter).startOf('year').format('YYYY-MM-DD'),
						_lte: dayjs().year(yearFilter).endOf('year').format('YYYY-MM-DD'),
					},
					is_restricted: { _eq: false },
					_or: isManagerView
						? userLocationId && noOfLocations && noOfLocations > 0
							? [{ location_id: { _eq: userLocationId } }]
							: [{}]
						: locationId && noOfLocations && noOfLocations > 0
						? [{ location_id: { _eq: locationId } }]
						: [{}],
				},
				optionalHolidayFilter: {
					user_id: isManagerView ? { _eq: selectedUserId } : { _eq: userId },
					org_holiday: {
						name: { _ilike: `%${searchedText}%` },
						date: {
							_gte: dayjs()
								.year(yearFilter)
								.startOf('year')
								.format('YYYY-MM-DD'),
							_lte: dayjs().year(yearFilter).endOf('year').format('YYYY-MM-DD'),
						},
						_or: isManagerView
							? userLocationId && noOfLocations && noOfLocations > 0
								? [{ location_id: { _eq: userLocationId } }]
								: [{}]
							: locationId && noOfLocations && noOfLocations > 0
							? [{ location_id: { _eq: locationId } }]
							: [{}],
					},
				},
			},
			//if the organization has locations and user is not mapped to any location query should skip
			skip: isManagerView
				? !userLocationId && noOfLocations && noOfLocations > 0
				: !locationId && noOfLocations && noOfLocations > 0,
			fetchPolicy: 'network-only',
		});

	// EXPORT
	const { data: holidayExportList, loading: isExportLoading } =
		useGetHolidaysByUserIdQuery({
			variables: {
				holidayFilter: {
					name: { _ilike: `%${searchedText}%` },
					date: {
						_gte: dayjs().year(yearFilter).startOf('year').format('YYYY-MM-DD'),
						_lte: dayjs().year(yearFilter).endOf('year').format('YYYY-MM-DD'),
					},
					is_restricted: { _eq: false },
					_or: isManagerView
						? userLocationId && noOfLocations && noOfLocations > 0
							? [{ location_id: { _eq: userLocationId } }]
							: [{}]
						: locationId && noOfLocations && noOfLocations > 0
						? [{ location_id: { _eq: locationId } }]
						: [{}],
				},
				optionalHolidayFilter: {
					user_id: isManagerView ? { _eq: selectedUserId } : { _eq: userId },
					org_holiday: {
						name: { _ilike: `%${searchedText}%` },
						date: {
							_gte: dayjs()
								.year(yearFilter)
								.startOf('year')
								.format('YYYY-MM-DD'),
							_lte: dayjs().year(yearFilter).endOf('year').format('YYYY-MM-DD'),
						},
						is_restricted: { _eq: true },
						_or: isManagerView
							? userLocationId && noOfLocations && noOfLocations > 0
								? [{ location_id: { _eq: userLocationId } }]
								: [{}]
							: locationId && noOfLocations && noOfLocations > 0
							? [{ location_id: { _eq: locationId } }]
							: [{}],
					},
				},
			},
			skip: !isExportTriggered,
			fetchPolicy: 'network-only',
		});
	const columns = [
		{
			name: 'name',
			label: 'NAME',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: string) => {
					return (
						<Tooltip title={value || '--'}>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'date',
			label: 'DATE',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: string) => {
					return (
						<Tooltip title={value ? dayjs(value).format(dateFormat) : '--'}>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value ? dayjs(value).format(dateFormat) : '--'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'day',
			label: 'DAY',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: string) => {
					return (
						<Tooltip title={value || '--'}>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		...(noOfLocations && noOfLocations > 0
			? [
					{
						name: 'location',
						label: 'LOCATION',
						options: {
							filter: false,
							viewColumns: false,
							sort: false,
							customBodyRender: (value: string) => {
								return (
									<Tooltip title={value || '--'}>
										<Box
											className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}
										>
											{value}
										</Box>
									</Tooltip>
								);
							},
						},
					},
			  ]
			: []),
		{
			name: 'optionalHoliday',
			label: 'OPTIONAL HOLIDAY',
			options: {
				filter: false,
				viewColumns: false,
				sort: false,
				customBodyRender: (value: string) => {
					return (
						<Tooltip title={value || '--'}>
							<Box className={`${dataGridStyles.text} ${ellipsis.ellipsis}`}>
								{value}
							</Box>
						</Tooltip>
					);
				},
			},
		},
	];

	const options: MUIDataTableOptions = {
		tableBodyMaxHeight: '380px',
		filter: false,
		search: false,
		print: false,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [5, 10, 20, 30],
		page: page,
		serverSide: false,
		viewColumns: false,
		download: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
	};
	const holidayTypeInput = () => (
		<Box display='block' marginLeft='5px'>
			<Box className={styles.label}>Holiday type</Box>
			<Box>
				<Form
					initialValues={{
						holiday_type: holidayTypeFilter,
					}}
					onSubmit={() => {}}
				>
					{() => (
						<form>
							<ThemeProvider theme={modalFormTheme}>
								<SelectInput
									source='holiday_type'
									label=''
									choices={[
										{ id: 'All', name: 'All' },
										{ id: 'true', name: 'Optional Holiday' },
										{ id: 'false', name: 'Regular Holiday' },
									]}
									onChange={(e: any) => {
										setHolidayTypeFilter(e?.target?.value);
										setPage(0);
									}}
								/>
							</ThemeProvider>
						</form>
					)}
				</Form>
			</Box>
		</Box>
	);

	const yearInputFilter = () => (
		<Box display='block' marginLeft='5px'>
			<Box className={styles.label}>Year</Box>
			<Box>
				<Form
					initialValues={{
						year: yearFilter,
					}}
					onSubmit={() => {}}
				>
					{() => (
						<form>
							<ThemeProvider theme={modalFormTheme}>
								<SelectInput
									source='year'
									label=''
									choices={[
										{
											id: `${dayjs().get('year')}`,
											name: `${dayjs().get('year')}`,
										},
										{
											id: `${dayjs().subtract(1, 'year').get('year')}`,
											name: `${dayjs().subtract(1, 'year').get('year')}`,
										},
									]}
									onChange={(e: any) => {
										setYearFilter(e?.target?.value);
										setPage(0);
									}}
								/>
							</ThemeProvider>
						</form>
					)}
				</Form>
			</Box>
		</Box>
	);
	const sortHolidayBasedOnDate = (holidayList: Holiday[]) => {
		if (!holidayList) {
			return [];
		}
		const sortedList = holidayList.sort((a, b) =>
			dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
		);
		return sortedList;
	};
	const getHolidays = () => {
		if (!orgHolidayList) {
			return [];
		}
		const holidayList =
			holidayTypeFilter !== 'true'
				? orgHolidayList?.org_holidays?.map((holiday) => {
						return {
							name: holiday?.name,
							date: holiday?.date ? holiday?.date : '',
							day: holiday?.date ? dayjs(holiday?.date).format('ddd') : '',

							optionalHoliday: holiday?.is_restricted ? 'Yes' : 'No',
							...(noOfLocations &&
								noOfLocations > 0 && {
									location: holiday?.org_location?.name || '--',
								}),
						};
				  }) || []
				: [];
		const optionalHolidayList =
			holidayTypeFilter !== 'false'
				? orgHolidayList?.user_optional_holiday_mapping?.map((holiday) => {
						return {
							name: holiday?.org_holiday?.name,
							date: holiday?.org_holiday?.date
								? holiday?.org_holiday?.date
								: '',
							day: holiday?.org_holiday?.date
								? dayjs(holiday?.org_holiday?.date).format('ddd')
								: '',
							...(noOfLocations &&
								noOfLocations > 0 && {
									location: holiday?.org_holiday?.org_location?.name || '--',
								}),
							optionalHoliday: holiday?.org_holiday?.is_restricted
								? 'Yes'
								: 'No',
						};
				  }) || []
				: [];
		const sortedHolidayList = sortHolidayBasedOnDate([
			...(holidayList || []),
			...(optionalHolidayList || []),
		]);
		return sortedHolidayList;
	};

	useEffect(() => {
		if (!holidayExportList) {
			setIsExportTriggered(false);
			return;
		}
		const orgHolidays =
			holidayTypeFilter !== 'true'
				? holidayExportList?.org_holidays?.map((holiday) => {
						return {
							name: holiday?.name,
							date: holiday?.date ? holiday?.date : '',
							day: holiday?.date ? dayjs(holiday?.date).format('ddd') : '',
							...(noOfLocations &&
								noOfLocations > 0 && {
									location: holiday?.org_location?.name || '--',
								}),
							optionalHoliday: holiday?.is_restricted ? 'Yes' : 'No',
						};
				  }) || []
				: [];
		const optionalHolidayList =
			holidayTypeFilter !== 'false'
				? holidayExportList?.user_optional_holiday_mapping?.map((holiday) => {
						return {
							name: holiday?.org_holiday?.name,
							date: holiday?.org_holiday?.date
								? holiday?.org_holiday?.date
								: '',
							day: holiday?.org_holiday?.date
								? dayjs(holiday?.org_holiday?.date).format('ddd')
								: '',
							...(noOfLocations &&
								noOfLocations > 0 && {
									location: holiday?.org_holiday?.org_location?.name || '--',
								}),
							optionalHoliday: holiday?.org_holiday?.is_restricted
								? 'Yes'
								: 'No',
						};
				  }) || []
				: [];
		const sortedHolidayList = sortHolidayBasedOnDate([
			...(orgHolidays || []),
			...(optionalHolidayList || []),
		]);
		const holidayListForExport =
			sortedHolidayList?.map((holiday) => {
				return [
					holiday?.name || '--',
					holiday?.date ? dayjs(holiday?.date).format(dateFormat) : '--',
					holiday?.day || '--',
					...(noOfLocations && noOfLocations > 0
						? [holiday?.location || '--']
						: []),
					holiday?.optionalHoliday || '--',
				];
			}) || [];

		const HOLIDAY_EXPORT_COLUMNS = [
			'NAME',
			'DATE',
			'DAY',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'OPTIONAL HOLIDAY',
		];
		const exportData = [HOLIDAY_EXPORT_COLUMNS, ...holidayListForExport];
		exportToCsv('Holidays.csv', exportData);
		setIsExportTriggered(false);
	}, [dateFormat, holidayExportList, holidayTypeFilter, noOfLocations]);

	const handleExport = () => {
		setIsExportTriggered(true);
	};
	const checkEnableExport = () => {
		const holidayList = getHolidays();
		if (
			isExportLoading ||
			isEmpty(holidayList) ||
			(isEmpty(orgHolidayList?.org_holidays) &&
				isEmpty(orgHolidayList?.user_optional_holiday_mapping))
		) {
			return true;
		}

		return false;
	};
	return (
		<Box>
			<Box display='flex' justifyContent='space-between' marginBottom='20px'>
				<Box display='flex'>
					<Box>
						<CustomSearch
							searchText={searchedText}
							onSearch={(value: string) => setSearchedText(value)}
						/>
					</Box>
					<Box display='block' marginTop='-25px' marginLeft='5px'>
						{holidayTypeInput()}
					</Box>
					<Box display='block' marginTop='-25px' marginLeft='5px'>
						{yearInputFilter()}
					</Box>
				</Box>
				{isManagerView && (
					<Box>
						<ExportButtonWithLoader
						generateCSV={handleExport}
						isDisabled={checkEnableExport()}
					/>
					</Box> 
				)}
			</Box>
			<Box>
				{isDataGridLoading ? (
					<Box className={dataGridStyles.loadingContainer}>
						<Loading />
					</Box>
				) : (
					<ThemeProvider theme={customizedDataGridTheme}>
						<MUIDataTable
							title={''}
							data={getHolidays()}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				)}
			</Box>
		</Box>
	);
};

export default EmployeeHolidayList;
