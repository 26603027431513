import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { getCurrencyFormat } from '../../../Utils/string.util';
import { ProjectDetail } from '../ResourcePlanner.model';
import ProjectForm from '../../ResourcePlanner/ProjectForm.component';
import { detailsLeftCardStyle, ellipsisStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';
import { useGetProjectTimesheetEntryCountQuery } from '../../../generated/graphql';

const useStyles = makeStyles((theme) => ({
	infoBarContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
		marginBottom: '16px',
	},
	infoBar: {
		width: '84px',
		height: '20px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '8px',
		position: 'relative',
	},
	infoField: {
		fontSize: '10px !important',
		font: 'normal normal bold 10px/13px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
	},
	additionalInfoValue: {
		width: '90px',
		color: '#292929',
		fontSize: '12px !important',
		font: 'normal normal 600 12px/40px Manrope !important',
	},
	nameField: {
		width: '160px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		display: 'block',
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: 'white',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
		cursor: 'pointer',
		margin: '29px auto 5px auto',
	},
	header: {
		fontSize: '16px',
		fontFamily: 'Manrope-extrabold',
		color: '#FFFFFF',
		textAlign: 'center',
		maxWidth: '160px',
		maxHeight: '44px',
		lineHeight: '20px',
		wordBreak: 'break-all',
		display: '-webkit-box',
		webkitBoxOrient: 'vertical',
		mozBoxOrient: 'vertical',
		msBoxOrient: 'vertical',
		boxOrient: 'vertical',
		webkitLineClamp: 2,
		mozLineClamp: 2,
		msLineClamp: 2,
		lineClamp: 2,
		overflow: 'hidden',
	},
	avatarContainer: {
		position: 'relative',
		width: '246px',
		height: '110px',
		backgroundColor: '#4285F4',
		borderRadius: '4px 4px 0px 0px',
		opacity: 1,
		display: 'flex',
		marginBottom: '10px',
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	menuIcon: {
		position: 'absolute',
		right: 0,
		color: 'white',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	subHeading: {
		fontSize: '10px',
		fontFamily: 'Manrope',
		color: '#FFFFFF',
		margin: 'auto',
		maxWidth: '135px',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	riskIndicator: {
		marginRight: '5px',
		display: 'inline-block',
		height: '6px',
		width: '6px',
		borderRadius: '30px',
	},
	riskRed: {
		backgroundColor: '#EA4335',
	},
	riskGreen: {
		backgroundColor: '#34A853',
	},
	riskAmber: {
		backgroundColor: '#ff8f00',
	},
}));

interface Aggregate {
	__typename: string;
	count: number;
}

interface ProjectKanbanCardsAggregate {
	__typename: string;
	aggregate: Aggregate;
}

interface kanbanMapPropsTypes {
	__typename: string;
	project_kanban_cards_aggregate: ProjectKanbanCardsAggregate;
}

interface ProjectDetailsCardProps {
	record: ProjectDetail;
	refetch: () => void;
}

export const ProjectDetailsCard = (props: ProjectDetailsCardProps) => {
	const { permissions, dateFormat } = useContext<any>(UserProfileContext);
	const { record: project, refetch } = props;
	const detailsLeftCardStyles = detailsLeftCardStyle();
	const classes = useStyles();
	const styles = ellipsisStyle();
	const [anchorEl, setAnchorEl] = useState<any | null>(null);
	const [isProjectFormModalShown, setIsProjectFormModalShown] = useState(false);
	const [projectDetails, setProjectDetails] = useState({});
	const open = Boolean(anchorEl);
	useEffect(() => {
		if (!project) {
			return;
		}
		setProjectDetails({
			name: project?.name,
			revenue: project?.revenue,
			project_type: project?.project_type,
			start_date: project?.start_date,
			end_date: project?.end_date,
			project_owner_id: project?.project_owner_id,
			pre_sales_owner_id: project?.pre_sales_owner_id,
			is_strict: project?.is_strict,
			is_billable: project?.is_billable,
			kanban_id: project?.kanban_id,
			currency_id: project?.currency_id,
			total_cost: project?.total_cost,
			effort_estimate: project?.effort_estimate,
			risk_status: project?.risk_status,
			project_execution_type: project?.project_execution_type,
			company_id: project?.company_id,
			geo_id: project?.geo_id,
			id: project?.id,
			project_status_id: project?.project_status_id,
			Budget: project?.total_cost,
			description: project?.description,
			prefix: project?.prefix,
			isCardsPresent: !!project?.kanban_board?.kanban_columns.find(
				(elem: kanbanMapPropsTypes) =>
					elem.project_kanban_cards_aggregate.aggregate.count > 0
			),
			tag_id: project?.tag_id,
		});
	}, [project]);

	const { data: projectTimesheetEntries } =
		useGetProjectTimesheetEntryCountQuery({
			variables: {
				projectId: project.id,
			},
		});

	return (
		<Box className={detailsLeftCardStyles.container}>
			<Box className={classes.avatarContainer}>
				<IconButton
					className={classes.menuIcon}
					onClick={(event) => setAnchorEl(event.currentTarget)}
				>
					<MoreVertIcon
						fontSize='small'
						aria-label='more'
						aria-controls='long-menu'
						aria-haspopup='true'
					></MoreVertIcon>
				</IconButton>
				<Menu
					elevation={0}
					id='long-menu'
					anchorEl={anchorEl}
					keepMounted
					open={open}
					transformOrigin={{
						vertical: 'center',
						horizontal: 'left',
					}}
					onClose={() => setAnchorEl(null)}
				>
					<MenuItem
						onClick={() => {
							setIsProjectFormModalShown(true);
							setAnchorEl(null);
						}}
					>
						<EditIcon className={classes.editIcon} />
						<Box className={classes.editText}>Edit</Box>
					</MenuItem>
					{permissions?.project?.update_permissions?.deleted_at && (
						<MenuItem
							onClick={() => {
								setAnchorEl(null);
							}}
						>
							<DeleteIcon className={classes.deleteIcon} />
							<Box className={classes.editText}>Delete</Box>
						</MenuItem>
					)}
				</Menu>
				<Box className={detailsLeftCardStyles.identityContainer}>
					<Tooltip title={`${project?.name}`} placement='right'>
						<Box className={[classes.nameField, styles.ellipsis].join(' ')}>
							{project?.name}
						</Box>
					</Tooltip>
					<Box>
						<Tooltip title={`${project?.company?.name}`} placement='right'>
							<Typography className={classes.subHeading}>
								Client: {project?.company?.name || '--'}
							</Typography>
						</Tooltip>
					</Box>
					<Box>
						<Tooltip title={`${project?.project_type}`} placement='right'>
							<Typography className={classes.subHeading}>
								Project type: {project?.project_type || '--'}
							</Typography>
						</Tooltip>
					</Box>
				</Box>
			</Box>
			<Box></Box>
			<Box className={detailsLeftCardStyles.addtionalInfoContainer}>
				<Box className={classes.infoBarContainer}>
					<Box className={classes.infoBar}>
						<Box className={classes.infoField}>
							{project?.is_billable
								? `${project?.currency?.symbol || ''} Billable`
								: 'Non Billable'}
						</Box>
					</Box>
					<Tooltip title='Project risk' placement='right-start'>
						<Box className={classes.infoBar}>
							<Box className={classes.infoField}>
								{project?.risk_status ? (
									<span
										className={`${classes.riskIndicator} ${
											project?.risk_status === 'Red'
												? classes.riskRed
												: project?.risk_status === 'Green'
												? classes.riskGreen
												: classes.riskAmber
										}`}
									></span>
								) : (
									''
								)}
								{project?.risk_status || '--'}
							</Box>
						</Box>
					</Tooltip>
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Project Owner
					</Typography>
					{project?.user?.first_name ? (
						<Tooltip title={`${project?.user?.full_name}`} placement='right'>
							<Box
								className={[classes.additionalInfoValue, styles.ellipsis].join(
									' '
								)}
							>
								{`${project?.user?.full_name} `}
							</Box>
						</Tooltip>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Project Status
					</Typography>
					{project?.ProjectStatus?.label ? (
						<Box className={classes.additionalInfoValue}>
							{project?.ProjectStatus?.label}
						</Box>
					) : (
						<Typography className={detailsLeftCardStyles.additionalInfoValue}>
							- -
						</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Budget (SoW)
					</Typography>
					{project?.total_cost ? (
						<Box className={classes.additionalInfoValue}>
							{getCurrencyFormat(
								project?.total_cost,
								project?.currency?.currency_type,
								true
							)}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>--</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Budget (Planning)
					</Typography>
					{project?.project_resource_mappings_aggregate?.aggregate?.sum
						?.total_cost ? (
						<Box className={classes.additionalInfoValue}>
							{getCurrencyFormat(
								project?.project_resource_mappings_aggregate?.aggregate?.sum
									?.total_cost,
								project?.currency?.currency_type,
								true
							)}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>0</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Estimated Hours
					</Typography>
					{project?.effort_estimate ? (
						<Box className={classes.additionalInfoValue}>
							{project?.effort_estimate}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>--</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Planned Hours
					</Typography>
					{project?.project_resource_mappings_aggregate?.aggregate?.sum
						?.total_hours ? (
						<Box className={classes.additionalInfoValue}>
							{project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
								1
							)}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>--</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						Start Date
					</Typography>
					{project?.start_date ? (
						<Box className={classes.additionalInfoValue}>
							{dayjs(project?.start_date).format(dateFormat)}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</Box>
				<Box className={detailsLeftCardStyles.additionalContent}>
					<Typography className={detailsLeftCardStyles.additionalInfoLabel}>
						End Date
					</Typography>
					{project?.end_date ? (
						<Box className={classes.additionalInfoValue}>
							{dayjs(project?.end_date).format(dateFormat)}
						</Box>
					) : (
						<Typography className={classes.additionalInfoValue}>- -</Typography>
					)}
				</Box>
			</Box>
			<ProjectForm
				open={isProjectFormModalShown}
				onClose={() => setIsProjectFormModalShown(false)}
				project={projectDetails}
				projectId={project?.id}
				refetch={refetch}
				isBillableDisabled={
					project?.id &&
					projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
						?.aggregate?.count &&
					projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
						?.aggregate?.count > 0
						? true
						: false
				}
			/>
		</Box>
	);
};

export default ProjectDetailsCard;
