import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	required,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import KanbanWarningModal from '../../Kanban/kanbanWarning.component';
import {
	EDIT_PROJECT_LABEL_MESSAGE,
	ADD_PROJECT_LABEL_MESSAGE,
	PROJECT_LABEL_NAME_UNIQUE_ERROR,
	INSERT_LABEL_SUCCESS_MESSAGE,
	UPDATE_LABEL_SUCCESS_MESSAGE,
	PROJECT_LABEL_UNIQUE_KEY,
} from './../constant';
import { useUpsertProjectLabelMutation } from '../../../../generated/graphql';
import { validateDescription, validateName } from '../../constant';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

export interface projectLabel {
	id?: string;
	name?: string;
	description?: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	projectLabel: projectLabel;
}

const ProjectLabelForm = (props: Props) => {
	const { open, onClose, projectLabel } = props;
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectLabelUniqueError, setProjectLabelUniqueError] = useState('');
	const classes = modalFormStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const [upsertProjectLabel, { loading: isProjectLabelLoading }] =
		useUpsertProjectLabelMutation();

	const onSave = (formValue: projectLabel) => {
		if (!formValue) {
			return;
		}
		setProjectLabelUniqueError('');
		const { name, description } = formValue;
		upsertProjectLabel({
			variables: {
				projectTags: {
					...formValue,
					name: name?.trim(),
					description: description?.trim(),
				},
			},
		})
			.then((data) => {
				onClose();
				notify(
					formValue?.id
						? UPDATE_LABEL_SUCCESS_MESSAGE
						: INSERT_LABEL_SUCCESS_MESSAGE
				);
				setProjectLabelUniqueError('');
				userefresh();
				handleClose();
			})
			.catch((error) => {
				if (error.message.includes(PROJECT_LABEL_UNIQUE_KEY)) {
					setProjectLabelUniqueError(PROJECT_LABEL_NAME_UNIQUE_ERROR);
					return;
				}
			});
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
	};

	const handleClose = () => {
		setProjectLabelUniqueError('');
		onClose();
	};

	const validate = [
		required(),
		validateName,
		maxLength(100, 'Maximum 100 character allowed'),
	];

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{projectLabel?.id
									? EDIT_PROJECT_LABEL_MESSAGE
									: ADD_PROJECT_LABEL_MESSAGE}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={projectLabel}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Name *</Typography>
										<TextInput
											source='name'
											label=''
											validate={validate}
											onChange={(event: any) => {
												form.mutators.setTouched();
												setProjectLabelUniqueError('');
											}}
										/>
										{projectLabelUniqueError && (
											<Box color='red' fontSize={'12px'} marginTop={'-6px'}>
												{projectLabelUniqueError}
											</Box>
										)}
										<Typography className={classes.label}>
											Description
										</Typography>
										<TextInput
											validate={validateDescription}
											source='description'
											label=''
											multiline={true}
										/>
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											isDisabled={
												invalid ||
												pristine ||
												submitSucceeded ||
												isProjectLabelLoading
											}
											title='Save'
											isLoading={isProjectLabelLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={''}
				/>
			}
		</>
	);
};

export default ProjectLabelForm;
