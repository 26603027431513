import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
}));

interface Props {
	onClose: () => void;
	open: boolean;
	noteId: string;
	deleteNote: (noteId: string) => void;
	refetch: () => void;
}

const NoteDelete = (props: Props) => {
	const { open, onClose, noteId, deleteNote, refetch } = props;
	const classes = modalFormStyle();
	const containerClass = useStyles();

	const deleteNotes = () => {
		deleteNote(noteId);
		refetch();
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						Do you really want to delete this record ?
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button onClick={onClose} className={classes.cancelButton}>
								Cancel
							</Button>
							<Button
								className={classes.saveButton}
								type='submit'
								onClick={deleteNotes}
							>
								Delete
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default NoteDelete;
