import React from 'react';

import {
	Paper,
	Avatar,
	Link as MuiLink,
	Tooltip,
	Typography,
	Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Logo from '../assets/dummyLogo.png';
import { ellipsisStyle } from '../Layout/styles';
import { ReferenceField, FunctionField } from 'react-admin';
const useStyles = makeStyles((theme) => ({
	container: {
		width: '200px',
		minHeight: '230px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '1em',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		'&:hover': {
			boxShadow: '0px 36px 60px #0000000F',
		},
	},
	identity: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		paddingTop: '20px',
		position: 'relative',
	},
	titleContainer: {
		textAlign: 'center',
		color: '#6F6F6F',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		marginBottom: '70px',
	},
	subTitleContainer: {
		minWidth: '88px',
		height: '28px',
		backgroundColor: '#ECF1F4',
		marginTop: '38px',
		borderRadius: '4px',
		color: '#212121',
		fontSize: '12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
		fontFamily: 'Manrope-medium',
		position: 'absolute',
		bottom: '5px',
	},
	titleContent: {
		fontFamily: 'Manrope-semiBold',
		letterSpacing: '0px',
		color: '#212121',
		opacity: 1,
		fontSize: '12px !important',
	},
	avatar: {
		width: '56px',
		height: '57px',
		backgroundColor: 'aliceblue',
	},
	title: {
		width: '160px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	titleStyle: {
		display: 'block',
		marginTop: '30px',
		color: '#212121',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		marginBottom: '5px',
	},
	subTitleContent: {
		fontSize: '12px !important',
		font: 'normal normal 600 12px/23px Manrope !important',
		letterSpacing: '0px',
		color: '#6F6F6F',
		opacity: 1,
	},
}));

// Todo - Need to configure this component as a reusable component
export const CompanyCard = ({ data: company, basePath, resource }) => {
	const styles = ellipsisStyle();
	const classes = useStyles();
	return (
		<Paper className={classes.container}>
			<MuiLink
				component={Link}
				to={`/crm_company/${company.id}`}
				underline='none'
			>
				<Box className={classes.identity}>
					<Avatar>
						<img src={Logo} alt='zoho' />
					</Avatar>
					<Box className={classes.title}>
						<Tooltip title={`${company?.name}`} placement='right'>
							<Typography
								className={[classes.titleStyle, styles.ellipsis].join(' ')}
							>
								{company?.name}
							</Typography>
						</Tooltip>
					</Box>
					<Box className={classes.titleContainer}>
						{company?.category_id ? (
							<ReferenceField
								source='category_id'
								reference='company_categories'
								label='Category'
								resource={resource}
								record={company}
								basePath={basePath}
								linkType={false}
							>
								<FunctionField
									className={classes.titleContent}
									label=''
									render={(record) => `${record?.label || '--'}`}
								/>
							</ReferenceField>
						) : (
							'- -'
						)}
					</Box>
					<Box className={classes.subTitleContainer}>
						{company?.sector_id ? (
							<ReferenceField
								source='sector_id'
								reference='company_sector'
								label='Sector'
								resource={resource}
								record={company}
								basePath={basePath}
								linkType={false}
							>
								<FunctionField
									className={classes.subTitleContent}
									label=''
									render={(record) => `${record?.label || '--'}`}
								/>
							</ReferenceField>
						) : (
							'- -'
						)}
					</Box>
				</Box>
			</MuiLink>
		</Paper>
	);
};

export default CompanyCard;
