import { makeStyles } from '@material-ui/core';

export const dataUploadStyles = makeStyles((theme) => ({
	fileTemplateDownloadContainer: {},
	description: {
		color: '#6C6C6C',
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
	},
	fileDownloadButtonContainer: {
		marginTop: '10px',
	},
	fileDownloadButton: {
		font: 'normal normal 700 12px/37px Manrope',
		color: '#fff',
		height: '36px',
		padding: '10px 14px',
		minWidth: '108px',
		background: '#4285f4 0 0 no-repeat padding-box',
		boxShadow: '0 4px 12px rgb(66 133 244 / 23%)',
		borderRadius: '4px',
		textTransform: 'none',
		cursor: 'pointer',
		border: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&:hover': {
			background: '#4285f4 0 0 no-repeat padding-box',
		},
	},
	fileUploadContainer: {
		marginTop: '12px',
	},
	dropZoneAreaText: {
		marginTop: '24px',
		marginBottom: '24px',
		fontSize: '18px',
		color: '#4285F4',
	},
	uploadIcon: {
		color: '#4285F4',
		width: '51px',
		height: '51px',
	},
	heading: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		textTransform: 'uppercase',
		marginTop: '18px',
	},
	logContainer: {
		margin: '16px 0px 0px 0px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '8px 4px 8px 11px',
		minHeight: '200px',
		maxHeight: '200px',
		overflowY: 'scroll',
	},
	logContainerSmall: {
		margin: '8px 0px 0px 0px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '8px 4px 8px 11px',
		minHeight: '120px',
		maxHeight: '180px',
		overflowY: 'scroll',
	},
	successText: {
		color: '#34A853',
	},
	errorText: {
		color: '#EA4335',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));
