import gql from 'graphql-tag';
export const GET_TASK_LIST = gql`
	query getTaskList($projectId: uuid) {
		project_task(where: { project_id: { _eq: $projectId } }) {
			id
			index
			status
			title
			description
			planned_end_date
			assigned_to
			priority
		}
	}
`;

export const GET_USERS_LIST = gql`
	query getUserList($userId: uuid) {
		user(where: { id: { _eq: $userId } }) {
			first_name
			last_name
			id
			profile_pic
		}
	}
`;

export const GET_PROJECT_ASIGNEES = gql`
	query getProjectAssignedUser($project_id: uuid) {
		project_resource_mapping(
			where: { project_id: { _eq: $project_id } }
			distinct_on: employee_id
		) {
			user {
				first_name
				last_name
				full_name
				id
			}
		}
	}
`;

export const GET_INITIAL_KANBAN_ID = gql`
	query getInitialKanbanColumnId($id: uuid = "") {
		project(where: { id: { _eq: $id } }) {
			kanban_board {
				kanban_columns(limit: 1) {
					id
				}
			}
		}
	}
`;

export const ADD_NEW_TASK_TO_PROJECT = gql`
	mutation getNewTaskToProject($objects: [project_task_insert_input!] = {}) {
		insert_project_task(objects: $objects) {
			returning {
				card_id
			}
		}
	}
`;

export const GET_TASK = gql`
	query getTask {
		kanban_column {
			name
			project_kanban_cards {
				id
				ordinal
				column_id
				project_task {
					id
					card_name
					description
					planned_end_date
					project_milestone_id
					project_id
					priority
					title
					assigned_to
					status
				}
			}
		}
	}
`;

export const GET_KANBAN_BOARD = gql`
	query GetKanbanBoard($projectId: uuid, $task: project_task_bool_exp = {}) {
		project(where: { id: { _eq: $projectId } }) {
			prefix
			kanban_board {
				kanban_columns(order_by: { ordinal: asc }) {
					id
					name
					state
					cards: project_kanban_cards(
						order_by: { ordinal: asc }
						where: { project_task: $task }
					) {
						id
						ordinal
						column_id
						project_task {
							id
							card_name
							story_point
							project_id
							project_milestone_id
							story_point
							description
							planned_end_date
							planned_start_date
							reminder
							priority
							title
							planned_hours
							task_number
							is_billable
							assigned_to
							completion_percentage
							project_task_category {
								name
								id
							}
							assignedTo {
								first_name
								last_name
								full_name
								profile_pic
							}
							status
							project_tasks_aggregate {
								aggregate {
									count
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_TASKS_BY_PROJECT_ID = gql`
	query getTaskListbyProjectId($projectId: uuid) {
		project(where: { id: { _eq: $projectId } }) {
			kanban_board {
				kanban_columns {
					id
					name
					cards: project_kanban_cards(order_by: { ordinal: asc }) {
						id
						ordinal
						column_id
						project_task {
							id
							card_name
							story_point
							project_id
							project_milestone_id
							story_point
							description
							planned_end_date
							priority
							title
							assignedTo {
								first_name
								last_name
								profile_pic
							}
							status
						}
					}
				}
			}
		}
	}
`;
export const CREATE_PROJECT_TASK = gql`
	mutation createProjectTask($data: [project_task_insert_input!]!) {
		insert_project_task(objects: $data) {
			returning {
				id
			}
		}
	}
`;

export const GET_PROJECT_TASK_STATUS = gql`
	query getProjectTaskStatus($id: uuid!) {
		kanban_column(where: { board_id: { _eq: $id } }, order_by: { name: asc }) {
			id
			name
			state
			ordinal
		}
	}
`;

export const GET_TASK_BY_ID = gql`
	query getTaskById($task_id: uuid!) {
		project_task(where: { id: { _eq: $task_id } }) {
			id
			card_name
			description
			planned_end_date
			planned_start_date
			project_milestone_id
			project_id
			priority
			title
			assigned_to
			status
			assigned_by
			story_point
			completion_percentage
			reminder
			is_billable
			planned_hours
			task_number
			timesheets {
				working_hours
			}
			timesheet_overtimes {
				working_hours
			}
			project_task_category {
				id
				name
			}
			project {
				effort_estimate
				prefix
				kanban_board {
					id
				}
			}
			project_kanban_cards {
				id
				column_id
			}
		}
	}
`;
export const UPDATE_PRJECT_TASK_BY_ID = gql`
	mutation updateProjectTaskById(
		$id: uuid!
		$cardId: uuid!
		$task: project_task_set_input!
		$kanbanData: project_kanban_card_set_input
	) {
		update_project_task_by_pk(pk_columns: { id: $id }, _set: $task) {
			id
		}
		update_project_kanban_card_by_pk(
			pk_columns: { id: $cardId }
			_set: $kanbanData
		) {
			id
		}
	}
`;

export const GET_PROJECT_TASK_BY_KANBAN_COLUMN_ID = gql`
	query getTaskListBykanbanBoard($projectId: uuid!, $columnId: uuid!) {
		project_task(
			where: {
				project_kanban_cards: { column_id: { _eq: $columnId } }
				parent_task_id: { _is_null: true }
				project_id: { _eq: $projectId }
			}
		) {
			id
			card_name
			description
			planned_end_date
			planned_start_date
			project_milestone_id
			project_id
			priority
			title
			assigned_to
			status
			assigned_by
			story_point
			completion_percentage
			reminder
			is_billable
			planned_hours
			task_number
			project_kanban_cards {
				column_id
			}
			project {
				prefix
			}
		}
	}
`;

export const GET_SUB_TASK_LIST_BY_TASK_ID = gql`
	query getSubTaskListBytaskId($task_id: uuid!) {
		project_task(
			where: {
				parent_task_id: { _eq: $task_id }
				deleted_at: { _is_null: true }
			}
		) {
			id
			card_name
			description
			planned_end_date
			planned_start_date
			project_milestone_id
			project_id
			priority
			title
			assigned_to
			status
			assigned_by
			story_point
			completion_percentage
			reminder
			is_billable
			planned_hours
			assignedTo {
				first_name
				last_name
				full_name
			}
			project {
				prefix
				kanban_board {
					id
				}
			}
			project_kanban_cards {
				id
				column_id
			}
		}
	}
`;
export const UPDATE_SUB_TASK_STATUS_BY_ID = gql`
	mutation updateSubtaskStatusById($id: uuid!, $column_id: uuid!) {
		update_project_kanban_card_by_pk(
			pk_columns: { id: $id }
			_set: { column_id: $column_id }
		) {
			id
		}
	}
`;
export const DELETE_SUB_TASK_BY_ID = gql`
	mutation deleteSubTaskById($card_id: uuid!, $task_id: uuid!) {
		update_project_task_by_pk(
			pk_columns: { id: $task_id }
			_set: { deleted_at: "now()" }
		) {
			id
		}
		update_project_kanban_card_by_pk(
			pk_columns: { id: $card_id }
			_set: { deleted_at: "now()" }
		) {
			id
		}
	}
`;

export const CREATE_PROJECT_TASK_COMMENT = gql`
	mutation createProjectTaskComment(
		$comment: project_task_comments_insert_input!
	) {
		insert_project_task_comments_one(
			object: $comment
			on_conflict: {
				constraint: project_task_comments_pkey
				update_columns: comment
			}
		) {
			id
			comment
		}
	}
`;

export const GET_TASK_COMMENTS_BY_TASK_ID = gql`
	query getTaskCommentsByTaskId($task_id: uuid!) {
		project_task_comments(
			where: { task_id: { _eq: $task_id }, deleted_at: { _is_null: true } }
			order_by: { created_at: desc }
		) {
			id
			task_id
			comment
			created_at
			created_by
			createdBy {
				first_name
				last_name
				full_name
			}
		}
	}
`;

export const DELETE_TASK_COMMENT = gql`
	mutation deleteCommentById($id: uuid!) {
		update_project_task_comments_by_pk(
			pk_columns: { id: $id }
			_set: { deleted_at: "now()" }
		) {
			id
		}
	}
`;
export const GET_TASK_DOCS = gql`
	query getProjectTaskDocs($task_id: uuid!) {
		project_task_docs(
			order_by: { file_name: asc }
			where: { task_id: { _eq: $task_id } }
		) {
			id
			url
			file_name
		}
	}
`;

export const ADD_TASK_DOC = gql`
	mutation addTaskDoc($data: project_task_docs_insert_input!) {
		insert_project_task_docs_one(object: $data) {
			url
		}
	}
`;

export const DELETE_TASK_DOC = gql`
	mutation deleteTaskDoc($id: uuid!) {
		delete_project_task_docs(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_FILE_NAME = gql`
	mutation updateTaskFileName($file_name: String!, $id: uuid!) {
		update_project_task_docs(
			_set: { file_name: $file_name }
			where: { id: { _eq: $id } }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_PROJECT_MILESTONE_LIST = gql`
	query getProjectMileStoneList($projectId: uuid!) {
		project_milestone(where: { project_id: { _eq: $projectId } }) {
			name
			id
			project_id
		}
	}
`;

export const DELETE_PROJECT_TASK = gql`
	mutation deleteProjectKanbanTask($taskId: uuid!) {
		update_project_task(
			where: {
				_or: [{ id: { _eq: $taskId } }, { parent_task_id: { _eq: $taskId } }]
			}
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_project_kanban_card(
			where: { task_id: { _eq: $taskId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const CLONE_ADD_TASK_DOC = gql`
	mutation cloneAddTaskDoc($data: [project_task_docs_insert_input!]! = {}) {
		insert_project_task_docs(objects: $data) {
			returning {
				id
			}
		}
	}
`;

export const CLONE_SUB_TASK = gql`
	mutation cloneSubtask($projectTask: [project_task_insert_input!] = {}) {
		insert_project_task(objects: $projectTask) {
			returning {
				id
			}
		}
	}
`;

export const GET_DATAGRID_TASK_LIST = gql`
	query getDataGridProjectTasks(
		$filter: project_task_bool_exp = {}
		$limit: Int!
		$offset: Int!
		$totatTaskCountFilter: project_task_bool_exp = {}
		$sortFilter: [project_task_order_by!]
	) {
		project_task(
			where: $filter
			order_by: $sortFilter
			limit: $limit
			offset: $offset
		) {
			project {
				id
				name
				kanban_board {
					kanban_columns {
						id
						name
						state
					}
				}
			}
			parent_task {
				id
				title
			}
			parent_task_id
			title
			id
			project_milestone {
				name
				id
			}
			planned_hours
			planned_start_date
			planned_end_date
			is_billable
			priority
			completion_percentage
			project_kanban_cards {
				id
				kanban_column {
					name
					id
					state
				}
			}
			assignedTo {
				id
				full_name
			}
		}
		TotalTaskCount: project_task_aggregate(where: $totatTaskCountFilter) {
			aggregate {
				count
			}
		}
		parentTaskList: project_task(
			where: {
				deleted_at: { _is_null: true }
				parent_task_id: { _is_null: false }
			}
			distinct_on: parent_task_id
		) {
			parent_task_id
		}
	}
`;
export const UPDATE_PRIORITY = gql`
	mutation updatePriorityById($id: uuid!, $priority: project_task_priority!) {
		update_project_task_by_pk(
			pk_columns: { id: $id }
			_set: { priority: $priority }
		) {
			id
		}
	}
`;
export const UPDATE_TASK_STATUS = gql`
	mutation updateProjectTaskStatusById($id: uuid!, $status: uuid!) {
		update_project_task_by_pk(
			pk_columns: { id: $id }
			_set: { status: $status }
		) {
			id
		}
	}
`;

export const GET_SUBTASK_LIST_FOR_DATA_GRID = gql`
	query getProjectSubTaskListForDataGrid($parentTaskId: uuid!) {
		project_task(
			where: {
				parent_task_id: { _eq: $parentTaskId }
				deleted_at: { _is_null: true }
			}
			order_by: { title: asc }
		) {
			project {
				id
				name
				kanban_board {
					kanban_columns {
						id
						name
						state
					}
				}
			}
			parent_task_id
			title
			id
			project_milestone {
				name
				id
			}
			planned_hours
			planned_start_date
			planned_end_date
			is_billable
			priority
			completion_percentage
			project_kanban_cards {
				id
				kanban_column {
					name
					id
					state
				}
			}
			assignedTo {
				id
				full_name
			}
		}
	}
`;
export const GET_DATAGRID_PROJECT_FILTER_LIST = gql`
	query getProjectlistForDataGridFilter {
		project_list(
			where: { deleted_at: { _is_null: true } }
			order_by: { name: asc }
		) {
			id
			name
		}
	}
`;
export const GET_DATAGRID_FILTER_LIST = gql`
	query getDataGridFilterList($filters: project_list_bool_exp = {}) {
		project_list(where: $filters) {
			id
			projects {
				id
				name
				project_tasks(
					where: {
						deleted_at: { _is_null: true }
						assigned_to: { _is_null: false }
					}
					distinct_on: assigned_to
				) {
					assignedTo {
						id
						full_name
					}
				}
				project_milestones(where: { deleted_at: { _is_null: true } }) {
					id
					name
				}

				kanban_board {
					kanban_columns {
						id
						name
						state
					}
				}
			}
		}
	}
`;
export const GET_EXPORT_DATA_GRID_DATA = gql`
	query getExportDataGridTasks(
		$filter: project_task_bool_exp = {}
		$sortFilter: [project_task_order_by!]
	) {
		project_task(where: $filter, order_by: $sortFilter) {
			project {
				id
				name
				kanban_board {
					kanban_columns {
						id
						name
						state
					}
				}
			}
			parent_task {
				id
				title
			}
			parent_task_id
			title
			id
			project_milestone {
				name
				id
			}
			planned_hours
			planned_start_date
			planned_end_date
			is_billable
			priority
			completion_percentage
			project_kanban_cards {
				id
				kanban_column {
					name
					id
					state
				}
			}
			assignedTo {
				id
				full_name
			}
		}
	}
`;
