import React, { useContext, useState } from 'react';
import {
	FunctionField,
	ReferenceField,
	TextField,
	useNotify,
	useRefresh,
} from 'react-admin';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { detailsLeftCardStyle } from '../../Layout/styles';
import { ellipsisStyle } from '../../Layout/styles';
import { AvatarField } from '../../SharedComponents/AvatarField.component';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { getExperience, getTotalExperience } from '../../Utils/date-time.util';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Props } from './model';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UserProfileContext } from '../../App';
import { uploadProfilePic } from '../../Api/restApi.config';
import { useUpdateProfilePicMutation } from '../../generated/graphql';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import {
	PROFILE_PIC_UPDATE_ERROR_TEXT,
	PROFILE_PIC_UPDATE_SUCCESS_TEXT,
} from './constant';
import { formatEmployeeId } from '../../Utils/string.util';

const myInfoViewLeftSidePanelStyle = makeStyles((theme) => ({
	photoUploadButton: {
		position: 'absolute',
		bottom: '16px',
		right: '2px',
		background: '#FFFFFF',
		padding: '2px',
		border: '1px solid #E0E0E0',
		'&:hover': {
			background: '#FFFFFF',
			border: '1px solid #E0E0E0',
		},
	},
	photoUploadIcon: {
		width: '20px',
		height: '20px',
		color: '#4285F4',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	fullNameField: {
		marginLeft: '25px',
		marginRight: '25px',
	},
}));

const MyInfoViewLeftSidePanel = (props: Props) => {
	const { id: userId, isUserCustomPictureEnabled } =
		useContext<any>(UserProfileContext);
	const { myInfoViewDetails } = props;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const globalClass = detailsLeftCardStyle();
	const styles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const myInfoViewLeftSidePanelStyles = myInfoViewLeftSidePanelStyle();

	const [updateProfilePicUrl] = useUpdateProfilePicMutation();
	const { empIdLength, isFixedEmpIdLengthEnabled } =
		useContext(UserProfileContext)!;

	const formattedEmployeeId = formatEmployeeId(
		myInfoViewDetails?.emp_id,
		empIdLength,
		isFixedEmpIdLengthEnabled
	);

	const ProgressLoader = () => (
		<Backdrop
			className={myInfoViewLeftSidePanelStyles.backdrop}
			open={isLoading}
		>
			<CircularProgress color='inherit' />
		</Backdrop>
	);

	const handleProfilePicUpload = async (options: RcCustomRequestOptions) => {
		if (!options) {
			return;
		}
		const { file } = options;
		setIsLoading(true);
		let data = new FormData();
		data.append('uploadType', 'ProfilePic');
		data.append('file', file);
		data.append('id', userId);

		uploadProfilePic(data)
			.then((res) => {
				updateProfilePicUrl({
					variables: {
						userId: userId,
						profilePic: res.data.url,
					},
				})
					.then((res) => {
						if (!res.errors) {
							refresh();
							notify(PROFILE_PIC_UPDATE_SUCCESS_TEXT);
							setIsLoading(false);
						}
					})
					.catch((err) => {
						notify(PROFILE_PIC_UPDATE_ERROR_TEXT, 'warning');
						setIsLoading(false);
						console.log(err);
					});
			})
			.catch((err) => {
				notify(PROFILE_PIC_UPDATE_ERROR_TEXT, 'warning');
				setIsLoading(false);
				console.log(err);
			});
	};

	const onBeforeUpload = (file: UploadFile) => {
		const isPNG = file.type === 'image/png' || file.type === 'image/jpeg';
		if (!isPNG) {
			notify(`${file.name} is not a image file`, 'warning');
		}
		return isPNG || Upload.LIST_IGNORE;
	};

	return (
		<>
			<ProgressLoader />
			<Box className={globalClass.container}>
				<Box className={globalClass.avatarContainer}>
					<Box className={globalClass.avatarField} marginRight='20px'>
						<AvatarField
							imageSrc={myInfoViewDetails?.profile_pic}
							firstName={myInfoViewDetails?.first_name}
							lastName={myInfoViewDetails?.last_name}
							size='90'
							className={''}
						/>
						{isUserCustomPictureEnabled && (
							<ImgCrop modalTitle='Profile Picture' rotate>
								<Upload
									accept='image/png, image/jpeg'
									customRequest={(values: RcCustomRequestOptions) => {
										handleProfilePicUpload(values);
									}}
									beforeUpload={onBeforeUpload}
									fileList={[]}
								>
									<>
										<IconButton
											color='primary'
											aria-label='upload picture'
											component='span'
											className={
												myInfoViewLeftSidePanelStyles.photoUploadButton
											}
										>
											<PhotoCamera
												className={
													myInfoViewLeftSidePanelStyles.photoUploadIcon
												}
											/>
										</IconButton>
									</>
								</Upload>
							</ImgCrop>
						)}
					</Box>
				</Box>
				<Box className={globalClass.identityContainer}>
					<FunctionField
						record={myInfoViewDetails}
						render={(employee: any) => {
							return (
								<Tooltip
									title={`${employee?.full_name || '- -'}`}
									placement='right'
								>
									<Typography
										className={`${globalClass.nameField} ${styles.ellipsis} ${myInfoViewLeftSidePanelStyles.fullNameField}`}
									>
										{`${employee?.full_name || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					<FunctionField
						record={myInfoViewDetails}
						render={(record: any) => {
							return (
								<Tooltip
									title={`${record?.designation?.title || ''}`}
									placement='right'
								>
									<Typography
										className={`${globalClass.designationInfoField} ${styles.ellipsis}`}
									>
										{record?.designation?.title || ''}
									</Typography>
								</Tooltip>
							);
						}}
					/>

					<FunctionField
						record={myInfoViewDetails}
						render={(record: any) => {
							return (
								<Tooltip title={`${record?.email || '- -'}`} placement='right'>
									<Typography
										className={`${globalClass.additionalInfoField} ${styles.ellipsis} ${myInfoViewLeftSidePanelStyles.fullNameField}`}
									>
										{`${record?.email || '- -'}`}
									</Typography>
								</Tooltip>
							);
						}}
					/>
					{myInfoViewDetails?.phone_number && (
						<Box display='flex' justifyContent='center' alignItems='center'>
							<SmartphoneIcon className={globalClass.phoneIcon} />
							<Box className={globalClass.additionalInfoField}>
								{myInfoViewDetails?.phone_number}
							</Box>
						</Box>
					)}
					<Box className={globalClass.infoBarContainer}>
						<Box className={globalClass.infoBar}>
							<Box
								className={[
									globalClass.activeIcon,
									myInfoViewDetails?.is_active
										? globalClass.activeColor
										: globalClass.inactiveColor,
								].join(' ')}
							/>
							<Box className={globalClass.infoBarValueContainer}>
								<FunctionField
									record={myInfoViewDetails}
									render={(employee: any) => (
										<Typography className={globalClass.infoField}>
											{employee?.is_active ? 'Active' : 'Inactive'}
										</Typography>
									)}
								/>
							</Box>
						</Box>
						<Box className={globalClass.infoBar}>
							<AttachMoneyIcon className={globalClass.billableIcon} />
							<Box className={globalClass.infoBarValueContainer}>
								<FunctionField
									className={globalClass.infoField}
									record={myInfoViewDetails}
									render={(employee: any) =>
										`${employee?.is_billable ? 'Billable' : 'Non-Billable'}`
									}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={globalClass.addtionalInfoContainer}>
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							{myInfoViewDetails?.user_type === 'employee'
								? 'Employee ID'
								: 'Consultant ID'}
						</Typography>
						{myInfoViewDetails?.emp_id ? (
							<FunctionField
								record={myInfoViewDetails}
								className={globalClass.additionalInfoValue}
								render={(user: any) => (
									<Tooltip
										title={
											user?.prefix_id
												? `${user?.prefix_id || ''}${formattedEmployeeId || ''}`
												: formattedEmployeeId
										}
									>
										<Box
											maxWidth='100px'
											className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
										>
											{user?.prefix_id
												? `${user?.prefix_id || ''}${formattedEmployeeId || ''}`
												: formattedEmployeeId}
										</Box>
									</Tooltip>
								)}
							/>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							Job Level
						</Typography>
						{myInfoViewDetails?.job_level_id ? (
							<ReferenceField
								link={false}
								label='Job level'
								source='job_level_id'
								reference='job_level'
								record={myInfoViewDetails}
								basePath={'/my-info'}
							>
								<TextField
									source='level'
									className={globalClass.additionalInfoValue}
								/>
							</ReferenceField>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							Department
						</Typography>
						{myInfoViewDetails?.department_id ? (
							<ReferenceField
								link={false}
								source='department_id'
								reference='department'
								record={myInfoViewDetails}
								basePath={'/my-info'}
								resource={'employee'}
							>
								<FunctionField
									label='NAME'
									render={(record: any) => {
										return (
											<Tooltip
												title={`${record?.name || '--'}`}
												placement='right'
											>
												<Box
													className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
													maxWidth='100px'
												>
													{record?.name}
												</Box>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
					{myInfoViewDetails?.vendor_type && (
						<Box className={globalClass.additionalContent}>
							<Typography className={globalClass.additionalInfoLabel}>
								Joining Source
							</Typography>
							<Box
								className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
							>
								{myInfoViewDetails?.vendor_type}
							</Box>
						</Box>
					)}
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							Status
						</Typography>
						{myInfoViewDetails?.employee_status?.label ? (
							<Box className={globalClass.additionalInfoValue}>
								{myInfoViewDetails?.employee_status?.label}
							</Box>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
					{myInfoViewDetails?.user_type === 'employee' && (
						<Box className={globalClass.additionalContent}>
							<Typography className={globalClass.additionalInfoLabel}>
								Total Experience
							</Typography>
							{myInfoViewDetails ? (
								<FunctionField
									record={myInfoViewDetails}
									className={globalClass.additionalInfoValue}
									render={(employee: any) => {
										const employeeExperience = getExperience(employee);
										return employeeExperience?.totalExp.length > 2
											? employeeExperience?.totalExp
											: '- -';
									}}
								/>
							) : (
								<Typography className={globalClass.additionalInfoValue}>
									- -
								</Typography>
							)}
						</Box>
					)}
					{myInfoViewDetails?.user_type === 'contract_employee' && (
						<Box className={globalClass.additionalContent}>
							<Typography className={globalClass.additionalInfoLabel}>
								Work Experience
							</Typography>
							{myInfoViewDetails ? (
								<FunctionField
									record={myInfoViewDetails}
									className={globalClass.additionalInfoValue}
									render={(consultant: any) => {
										const experience = getTotalExperience(
											consultant?.prior_experience
										);
										return experience
											? `${experience?.years} Year ${experience?.months} Month`
											: '- -';
									}}
								/>
							) : (
								<Typography className={globalClass.additionalInfoValue}>
									- -
								</Typography>
							)}
						</Box>
					)}
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							Reporting to
						</Typography>
						{myInfoViewDetails?.reportee_id ? (
							<ReferenceField
								link={false}
								source='reportee_id'
								reference='user'
								record={myInfoViewDetails}
								basePath={'/my-info'}
							>
								<FunctionField
									render={(employee: any) => {
										return (
											<Tooltip
												title={`${`${employee?.full_name || ''}` || '--'}`}
												placement='right'
											>
												<Typography
													className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
												>
													{`${employee?.full_name || ''}  `}
												</Typography>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
					<Box className={globalClass.additionalContent}>
						<Typography className={globalClass.additionalInfoLabel}>
							Mode of Work
						</Typography>
						{myInfoViewDetails?.working_mode ? (
							<TextField
								source='working_mode'
								record={myInfoViewDetails}
								className={globalClass.additionalInfoValue}
							/>
						) : (
							<Typography className={globalClass.additionalInfoValue}>
								- -
							</Typography>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default MyInfoViewLeftSidePanel;
