import React, { useEffect, useState } from 'react';
import {
	TextInput,
	required,
	useNotify,
	useRefresh,
	SelectInput,
	email,
	regex,
	ReferenceInput,
	maxLength,
} from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import { ADD_USER, UPDATE_USER } from '../Settings.gql';
import InfoIcon from '@material-ui/icons/Info';

import { ThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Box,
	Dialog,
	Typography,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { trimInput } from '../../../Utils/string.util';
import { USER_TYPE_OPTIONS } from './constant';
import { validatePhoneNumber } from '../../../Utils/string.util';
import { USER_TYPE_EDIT_MESSAGE } from '../constant';

interface RoleMappings {
	__type_name?: string;
	role_id?: string;
}

interface UserForm {
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	is_active?: boolean;
	user_type?: string;
	email?: string;
	id?: string;
	phone_number?: string;
	role_id?: string;
	__typename?: string;
	role_mappings?: RoleMappings[];
	created_at?: string;
	full_name?: string;
}

interface UserFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: UserForm;
	isEditMutation: boolean;
	onSuccess: () => void;
}

const UserForm = (props: UserFormProps) => {
	const { open, onClose, initialValues, isEditMutation, onSuccess } = props;
	const [isEmailUniqueViolationError, setIsEmailUniqueViolationError] =
		useState(false);
	const [
		isPhoneNumberUniqueViolationError,
		setIsPhoneNumberUniqueViolationError,
	] = useState(false);
	const [isPhoneNumberLengthError, setIsPhoneNumberLengthError] =
		useState(false);

	const notify = useNotify();
	const refresh = useRefresh();
	const userFormInitialValues = {
		is_active: true,
		user_type: 'employee',
	};
	const formStyles = modalFormStyle();
	const [addUser, { error: createUserError, loading: isCreateUserLoading }] =
		useApolloMutation(ADD_USER);
	const [updateUser, { error: updateUserError, loading: isUpdateUserLoading }] =
		useApolloMutation(UPDATE_USER);

	useEffect(() => {
		if (!updateUserError) {
			return;
		}
		//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
		if (updateUserError?.message.includes('UQ_b7a5e4a3b174e954b2dabf2ef9e')) {
			setIsEmailUniqueViolationError(true);
		}
	}, [updateUserError]);
	useEffect(() => {
		if (!updateUserError) {
			return;
		}
		//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
		if (updateUserError?.message.includes('UQ_eeea54cb587a31720056fed06aa')) {
			setIsPhoneNumberUniqueViolationError(true);
		}
	}, [updateUserError]);

	useEffect(() => {
		if (!createUserError) {
			return;
		}
		//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
		if (createUserError?.message.includes('UQ_b7a5e4a3b174e954b2dabf2ef9e')) {
			setIsEmailUniqueViolationError(true);
		}
	}, [createUserError]);
	useEffect(() => {
		if (!createUserError) {
			return;
		}
		//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
		if (createUserError?.message.includes('UQ_eeea54cb587a31720056fed06aa')) {
			setIsPhoneNumberUniqueViolationError(true);
		}
	}, [createUserError]);

	const onHandleSubmit = (user: UserForm) => {
		if (!user) {
			return;
		}

		const {
			role_id,
			__typename,
			role_mappings,
			id,
			created_at,
			full_name,
			first_name,
			middle_name,
			last_name,
			email,
			...userFormValues
		} = user;

		if (
			user?.phone_number &&
			user.phone_number.replace(/ +/g, '').length > 15
		) {
			setIsPhoneNumberLengthError(true);
			return;
		}

		if (!isEditMutation) {
			addUser({
				variables: {
					data: {
						email: String(email).toLowerCase(),
						first_name: first_name?.trim(),
						middle_name: middle_name?.trim() || null,
						last_name: last_name?.trim(),
						...userFormValues,
						role_mappings: {
							data: {
								role_id: role_id,
							},
						},
					},
				},
			})
				.then((response: any) => {
					if (!response.error) {
						notify('User Created Successfully');
						refresh();
						onSuccess();
						onClose();
					}
				})
				.catch((error: any) => {
					if (error) {
						return;
					}
				});
		} else {
			updateUser({
				variables: {
					userId: initialValues?.id,
					data: {
						...userFormValues,
						email: String(email).toLowerCase(),
						first_name: user?.first_name?.trim(),
						middle_name: user?.middle_name?.trim() || null,
						last_name: user?.last_name?.trim(),
						phone_number: user?.phone_number || null,
					},
					role_mapping: {
						role_id: role_id,
						user_id: initialValues?.id,
					},
				},
			})
				.then((response: any) => {
					if (!response.error) {
						notify('User Updated Successfully');
						refresh();
						onSuccess();
						onClose();
					}
				})
				.catch((error: any) => {
					if (error) {
						return;
					}
				});
		}
	};

	const validateUserFormValues = async () => {
		const userFormErrors = {} as UserForm;
		if (isPhoneNumberUniqueViolationError) {
			userFormErrors.phone_number = 'Phone Number already exists!';
		}
		if (isPhoneNumberLengthError) {
			userFormErrors.phone_number = 'Maximum 15 digits allowed';
		}
		if (isEmailUniqueViolationError) {
			userFormErrors.email = 'Email already exist!';
		}

		return userFormErrors;
	};

	const validateFullName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={formStyles.container}>
					<Box className={formStyles.headerContainer}>
						<Typography className={formStyles.heading}>
							{isEditMutation
								? `${initialValues?.first_name} ${
										initialValues?.middle_name || ''
								  } ${initialValues?.last_name || ''}`
								: `Create User`}
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</Box>
					<Form
						initialValues={
							isEditMutation ? initialValues : userFormInitialValues
						}
						onSubmit={onHandleSubmit}
						validate={validateUserFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Typography className={formStyles.label}>
											First Name*
										</Typography>
										<TextInput
											source='first_name'
											label=''
											fullWidth
											validate={[
												required(),
												validateFullName,
												maxLength(
													20,
													'First Name should not be greater than 20'
												),
											]}
										/>
										<Typography className={formStyles.label}>
											Middle Name
										</Typography>
										<TextInput
											label=''
											source='middle_name'
											fullWidth
											validate={[
												maxLength(
													20,
													'Middle Name should not be greater than 20'
												),
											]}
										/>
										<Typography className={formStyles.label}>
											Last Name*
										</Typography>
										<TextInput
											label=''
											source='last_name'
											fullWidth
											validate={[
												required(),
												validateFullName,
												maxLength(
													20,
													'Last Name should not be greater than 20'
												),
											]}
										/>
										<Typography className={formStyles.label}>
											Phone Number
										</Typography>
										<TextInput
											label=''
											source='phone_number'
											validate={[
												regex(validatePhoneNumber, 'Invalid Phone Number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											fullWidth
											onChange={() => {
												setIsPhoneNumberLengthError(false);
												setIsPhoneNumberUniqueViolationError(false);
											}}
											parse={trimInput}
										/>
										<Typography className={formStyles.label}>
											User Type*
										</Typography>
										<Box display='flex'>
											<SelectInput
												disabled={!!initialValues?.id}
												label=''
												source='user_type'
												validate={required()}
												fullWidth
												choices={USER_TYPE_OPTIONS}
											/>
											{initialValues?.id && (
												<Tooltip
													title={USER_TYPE_EDIT_MESSAGE}
													placement='top'
												>
													<IconButton className={formStyles.iconContainer}>
														<InfoIcon className={formStyles.editIcon} />
													</IconButton>
												</Tooltip>
											)}
										</Box>

										<Typography className={formStyles.label}>Email*</Typography>
										<TextInput
											label=''
											source='email'
											fullWidth
											validate={[
												required(),
												email(),
												maxLength(
													100,
													'Maximum email length is 100 characters.'
												),
											]}
											onChange={() => {
												setIsEmailUniqueViolationError(false);
											}}
											parse={trimInput}
										/>
										<Typography className={formStyles.label}>
											IsActive
										</Typography>
										<SelectInput
											label=''
											source='is_active'
											fullWidth
											choices={[
												{ id: true, name: 'Active' },
												{ id: false, name: 'Inactive' },
											]}
										/>
										<Typography className={formStyles.label}>Role*</Typography>
										<ReferenceInput
											source='role_id'
											reference='role'
											label=''
											sort={{ field: 'name', order: 'ASC' }}
											validate={required()}
										>
											<SelectInput label='' source='name' />
										</ReferenceInput>
									</DialogContent>
									<DialogActions>
										<Box className={formStyles.floatingButtonContainer}>
											<Button
												onClick={onClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid ||
													pristine ||
													isCreateUserLoading ||
													isUpdateUserLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid ||
													pristine ||
													isCreateUserLoading ||
													isUpdateUserLoading
												}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default UserForm;
