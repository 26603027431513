import {
	Avatar,
	makeStyles,
	Menu,
	Box,
	IconButton,
	Button,
} from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AppBar, useQuery } from 'react-admin';
import { UserProfileContext } from '../../App';
import { LogoutButton } from '../../Auth/LogoutMenu';
import workplazeLogo from '../../assets/workplazeLogo.svg';
import { DEFAULT_PROFILE_PIC } from '../../config/constant';
import {
	useGetEmployeeJoinDateByUserIdQuery,
	useGetOrganisationLogoQuery,
	useGetProjectsOptionsQuery,
} from '../../generated/graphql';
import { getProjectOptions } from '../../modules/Timesheet/Timesheet.utils';
import TimesheetGlobalForm from '../../modules/Timesheet/TimesheetGlobalForm.component';
import { TimesheetIcon } from '../../assets/SvgIcons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { workplazeDocumentURL } from '../../modules/constant';
import { head } from 'lodash';
import dayjs from 'dayjs';

const useStyles = makeStyles({
	avatar: {
		cursor: 'pointer',
	},
	organizationLogo: {
		marginLeft: '15px',
		maxWidth: '100px',
		maxHeight: '60px',
	},
	workplazeLogo: {
		marginLeft: '50px',
		position: 'fixed',
	},
	timesheetIcon: {
		color: '#FFFFFF',
		marginTop: '1px',
	},
	timesheetButton: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '6px 8px',
		gap: '8px',
		width: '135px',
		height: '36px',
		background: '#0E63E0',
		border: '1px solid rgba(0, 0, 0, 0.24)',
		boxShadow: '0px 4px 8px rgb(0 0 0 / 16%)',
		borderRadius: '8px',
		color: '#FFFFFF',
		fontFamily: 'Manrope',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '12px',
		textTransform: 'none',
		marginRight: '16px',
		'&:hover': {
			background: '#0E63E0',
			color: '#FFFFFF',
		},
	},
	disableTimesheetButton: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '6px 8px',
		gap: '8px',
		width: '135px',
		height: '36px',
		background: '#dcdcdc',
		borderRadius: '8px',
		color: '#FFFFFF',
		fontFamily: 'Manrope',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '12px',
		textTransform: 'none',
		marginRight: '16px',
		'&:hover': {
			background: '#dcdcdc',
			color: '#FFFFFF',
		},
	},
	helperIcon: {
		color: '#555555',
		cursor: 'pointer',
		width: '20px',
		height: '20px',
	},
	helperIconButton: {
		color: '#555555',
		marginRight: '16px',
		cursor: 'pointer',
		width: '20px',
		height: '20px',
	},
	accountContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '0px 24px 0px 14px',
		height: '100%',
		background: '#F0F0F0',
	},
});

const CustomUserMenu = (props) => {
	const classes = useStyles();
	const { orgId, id: userId } = useContext(UserProfileContext);
	const [clickedElement, setClickedElemt] = useState(null);
	const [isTimesheetFormShown, setIsTimesheetFormShown] = useState(false);
	const { email } = useContext(UserProfileContext);
	const [profilePic, setProfilePic] = useState(null);

	const { data: users } = useQuery({
		type: 'GET_LIST',
		resource: 'user',
		payload: {
			sort: { field: 'id', order: 'ASC' },
			filter: { email: email },
		},
	});

	const { data: projectOptions } = useGetProjectsOptionsQuery({
		variables: {
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: organisation } = useGetOrganisationLogoQuery({
		variables: {
			orgId: orgId,
		},
	});
	const { data: user, loading: joinDateLoader } =
		useGetEmployeeJoinDateByUserIdQuery({
			variables: { user_id: userId },
			fetchPolicy: 'network-only',
		});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;
	const currentDate = dayjs().format('YYYY-MM-DD');
	const disableCheckIn = currentDate < userJoinDate;

	useEffect(() => {
		if (users) {
			setProfilePic(users[0]?.profile_pic || DEFAULT_PROFILE_PIC);
		}
	}, [users]);

	const onHandleAvatarClick = (event) => {
		setClickedElemt(event.currentTarget);
	};

	const onHandleMenuClose = () => {
		setClickedElemt(null);
	};
	return (
		<Fragment>
			<Box className={classes.workplazeLogo}>
				<img src={workplazeLogo} alt='workplaze' />
			</Box>
			<Button
				className={
					joinDateLoader || disableCheckIn
						? classes.disableTimesheetButton
						: classes.timesheetButton
				}
				onClick={() => {
					setIsTimesheetFormShown(true);
				}}
				disabled={joinDateLoader || disableCheckIn}
				endIcon={<TimesheetIcon className={classes.timesheetIcon} />}
			>
				Add Timesheet
			</Button>
			<a href={workplazeDocumentURL} target='_blank' rel='noopener noreferrer'>
				<IconButton className={classes.helperIconButton}>
					<HelpOutlineIcon className={classes.helperIcon} />
				</IconButton>
			</a>
			<div className={classes.accountContainer}>
				<Avatar
					src={profilePic}
					alt='profilePic'
					onClick={onHandleAvatarClick}
					className={classes.avatar}
				/>
				{organisation?.organization_by_pk?.logo_url && (
					<img
						src={organisation?.organization_by_pk?.logo_url}
						alt='logo'
						className={classes.organizationLogo}
					/>
				)}
			</div>
			<Menu
				id='user-menu'
				anchorEl={clickedElement}
				keepMounted
				open={Boolean(clickedElement)}
				onClose={onHandleMenuClose}
			>
				<LogoutButton />
			</Menu>
			<TimesheetGlobalForm
				open={isTimesheetFormShown}
				onClose={() => {
					setIsTimesheetFormShown(false);
				}}
				timesheetEntries={[]} // No need to pass (only for Weekly entry)
				projectOptions={projectOptions ? getProjectOptions(projectOptions) : []}
				initialValues={{}}
				isWeeklyTimesheetForm={false}
				onSubmit={() => {
					// Only for Weekly entry
				}}
				userJoinDate = {userJoinDate}
			/>
		</Fragment>
	);
};

export const CustomAppBar = (props) => (
	<AppBar {...props} userMenu={<CustomUserMenu />} />
);
