import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	makeStyles,
	Avatar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Checkbox,
	ThemeProvider,
	Box,
	InputAdornment,
	IconButton,
	createTheme,
	TablePagination,
} from '@material-ui/core';

import {
	OVERTIME_APPROVAL_CONFIRMATION_TEXT,
	OVERTIME_APPROVAL_SUCCESS_TEXT,
	OVERTIME_APPROVAL_MODAL_BUTTON_LABEL,
} from './constant';
import { SelectInput } from 'react-admin';
import EventIcon from '@material-ui/icons/Event';

import { modalFormStyle } from '../../../Layout/styles';
import {
	useGetTimesheetStatusQuery,
	useGetOvertimeHistoryQuery,
	GetOvertimeHistoryQuery,
	useApproveOvertimeMutation,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/dayjs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ConfirmationModal from '../../../SharedComponents/ConfirmationModal.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReferenceInput, AutocompleteInput, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { TimesheetStyles } from '../TimesheetStyles';
import {
	getTotalNonBillableHours,
	getTotalBillableHours,
	getTimesheetEntriesBillablePercentage,
	getOvertimeEnteredTaskName,
	getOvertimeEnteredProjectName,
} from './Overtime.utils';
import { START_DATE_ERROR } from '../../Projects/constant';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { head, isEmpty, orderBy } from 'lodash';
import no_data_found from '../../../assets/no_data_found.png';
import { ErrorTooltip } from '../../Attendance/Style';
import { OvertimeSubmission, SubmittedOvertimes, User } from './Overtime.model';
import TimesheetOvertimePendingApprovalDetailsModal from './TimesheetOvertimePendingApprovalDetailsModal.component';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	projectName: {
		fontSize: '12px',
		width: '112px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '140px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-64px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
}));

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const TimesheetPendingApprovalStyle = makeStyles({
	container: {},
	actionsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	dateContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	calendarIcon: {
		width: '16px',
		height: '16px',
		color: '#888888',
		marginRight: '12px',
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	avatar: {
		width: '32px',
		height: '32px',
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
	},
	employeeName: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		color: '#343434',
		marginLeft: '12px',
	},
	totalHoursContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	durationIcon: {
		width: '15px',
		height: '15px',
		color: '#4285F4',
		marginRight: '5px',
	},
	timesheetsContainer: {
		marginBottom: '34px',
	},
	approvedIcon: {
		width: '22px',
		height: '22px',
		color: '#3FA543',
		margin: '5px 0px 0px 22px',
	},
	dataActionContainer: {
		width: '10px',
	},
	checkBox: {
		padding: '0px 0px 0px 22px',
	},
	button: {
		width: 'fit-content',
	},
	actionAdminContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	approveButton: {
		minWidth: '126px',
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
});

export const TimesheetOvertimeAdmin = () => {
	const { id: userId, dateFormat } = useContext<any>(UserProfileContext);
	const modalFormStyles = modalFormStyle();
	const timesheetPendingApprovalStyles = TimesheetPendingApprovalStyle();
	const timesheetStyles = TimesheetStyles();
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [submissionIds, setSubmissionIds] = useState<any>([]);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedOvertime, setSelectedOvertime] = useState({});
	const [submittedOvertimeList, setSubmittedOvertimeList] =
		useState<GetOvertimeHistoryQuery>();
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const notify = useNotify();
	const styles = modalFormStyle();
	const classes = useStyles();

	const [employeeId, setEmployeeId] = useState<any>();
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);

	const [overtimeStatusId, setOvertimeStatusId] = useState<any>();
	const [overtimeStatusOptions, setOvertimeStatusOptions] = useState([]);

	const {
		data: getSubmittedOvertime,
		loading: submittedOvertimeLoading,
		refetch: refetchSubmittedOvertimeList,
	} = useGetOvertimeHistoryQuery({
		variables: {
			userId: employeeId,
			startDate: startDate,
			endDate: endDate,
			statusId: overtimeStatusId === 'All' ? {} : { _eq: overtimeStatusId },
		},
		fetchPolicy: 'network-only',
	});

	const { data: overtimeStatus } = useGetTimesheetStatusQuery();
	const [approveOvertime,{loading:approveOvertimeLoading}] = useApproveOvertimeMutation();

	useEffect(() => {
		if (isEmpty(overtimeStatus?.timesheet_status)) {
			return;
		}
		const statusListInfo: any = overtimeStatus?.timesheet_status
			?.filter((value) => value.value !== 'draft')
			.map((statusVal) => {
				if (statusVal?.value === 'submitted') {
					setOvertimeStatusId(statusVal?.id);
				}
				return {
					id: statusVal?.id,
					name: statusVal?.value === 'submitted' ? 'Pending' : statusVal?.label,
				};
			});
		const sortedStatusListInfo: any = orderBy(
			statusListInfo,
			['name'],
			['asc']
		);
		setOvertimeStatusOptions(sortedStatusListInfo);
	}, [overtimeStatus]);

	useEffect(() => {
		if (!getSubmittedOvertime) {
			return;
		}
		const overtime = getSubmittedOvertime?.user
			.filter((user) => user.timesheetOvertimeSubmissions?.length > 0)
			.map((user) => ({
				...user,
				timesheetOvertimeSubmissions:
					user?.timesheetOvertimeSubmissions?.filter(
						(timesheetSubmission) =>
							timesheetSubmission?.timesheet_overtimes.length > 0
					),
			}));
		if (overtime) {
			setSubmittedOvertimeList({ user: overtime });
		}
	}, [getSubmittedOvertime]);

	const handleApproveTimesheet = async () => {
		if (!overtimeStatus) {
			return;
		}
		const approvedTimesheetStatus = overtimeStatus?.timesheet_status?.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveOvertime({
			variables: {
				submissionIds: submissionIds,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			setSubmissionIds([]);
			refetchSubmittedOvertimeList();
			setIsConfirmationModalShown(false);
			notify(OVERTIME_APPROVAL_SUCCESS_TEXT);
		}
	};

	const getTotalHours = (overtime: SubmittedOvertimes[]) => {
		if (!overtime) {
			return;
		}
		const timesheetDailyHours = overtime?.map(
			(overtime) => overtime?.working_hours
		);

		const totalHours = timesheetDailyHours
			.map((timesheetDailyHour) => timesheetDailyHour?.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return (
			`${Math.floor(totalHours.asHours())}:${totalHours.format('mm')}` ||
			'00:00'
		);
	};

	const handleRowClick = (
		overtimeSubmission: OvertimeSubmission,
		userDetails: User
	) => {
		if (!overtimeSubmission?.timesheet_overtimes[0]?.date) {
			return;
		}
		setSelectedOvertime({
			user: {
				id: userDetails?.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			billablePercentage: getTimesheetEntriesBillablePercentage(
				overtimeSubmission?.timesheet_overtimes
			),
			billableHours: getTotalBillableHours(
				overtimeSubmission?.timesheet_overtimes
			),
			nonBillableHours: getTotalNonBillableHours(
				overtimeSubmission?.timesheet_overtimes
			),
			totalHours: getTotalHours(overtimeSubmission?.timesheet_overtimes),
			timesheetSubmissionId: overtimeSubmission?.id,
			timesheetStatus: overtimeSubmission?.timesheet_status?.value,
			date: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.date
				: '',
			projectName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredProjectName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			taskName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredTaskName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			notes: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.notes
				: '',
			enableActionButtons: overtimeSubmission?.timesheet_status?.value
				? overtimeSubmission?.timesheet_status?.value === 'submitted'
					? true
					: false
				: false,
		});
	};

	const mapToFullName = (status: { full_name: string }) =>
		`${status?.full_name || ''} `;

	const onEmployeeChange = (id: React.ChangeEvent<{}>) => {
		if (id === null) {
			setEmployeeId('');
		}
		setEmployeeId(id);
	};

	const [isApprovedStatus, setIsApprovedStatus] = useState(false);

	const getApprovedStatus = (event: string) => {
		const approvedTimesheetStatus = overtimeStatus?.timesheet_status?.find(
			(status) => status.id === event
		);
		if (
			approvedTimesheetStatus?.value === 'approved' ||
			approvedTimesheetStatus?.value === 'rejected'
		) {
			setIsApprovedStatus(true);
		} else {
			setIsApprovedStatus(false);
		}
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
		setSubmissionIds([]);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<div className={timesheetPendingApprovalStyles.container}>
				<div className={timesheetPendingApprovalStyles.actionAdminContainer}>
					<ThemeProvider theme={modalFormTheme}>
						<Box>
							<Box className={styles.label}>Search Employee</Box>
							<Form onSubmit={() => {}}>
								{() => (
									<>
										<ReferenceInput
											source='user'
											label=''
											reference='user'
											sort={{ field: 'full_name', order: 'ASC' }}
											filterToQuery={(searchText) => ({
												first_name: searchText,
											})}
											filter={{
												first_name: true,
											}}
											onChange={(event) => {
												onEmployeeChange(event);
											}}
										>
											<AutocompleteInput
												resettable={true}
												optionText={mapToFullName}
											/>
										</ReferenceInput>
									</>
								)}
							</Form>
						</Box>
						<Box className={classes.projectFilterStatus} marginTop='8px'>
							<Form
								onSubmit={() => {}}
								initialValues={{ assignee: overtimeStatusId }}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'assignee'}
												label={''}
												onChange={(event) => {
													setOvertimeStatusId(event?.target?.value);
													getApprovedStatus(event.target.value);
													setSubmissionIds([]);
												}}
												choices={
													overtimeStatusOptions &&
													overtimeStatusOptions?.length > 0
														? [
																{
																	id: 'All',
																	name: 'All',
																},
																...overtimeStatusOptions,
														  ]
														: []
												}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
					</ThemeProvider>
					<ThemeProvider theme={dateTimePickerTheme}>
						<Box display='flex' marginBottom='25px'>
							<ErrorTooltip
								title={startDateError ? START_DATE_ERROR : ''}
								placement='top'
								arrow={true}
							>
								<Box width='188px' marginLeft='20px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												if (dayjs(date).isAfter(dayjs(endDate))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
												setStartDate(dayjs(date).format('YYYY-MM-DD'));
											}}
											value={startDate}
											error={startDateError}
											format={dateFormat}
											label='Start date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</ErrorTooltip>
							<Box width='188px'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant='inline'
										onChange={(date: any) => {
											if (dayjs(startDate).isAfter(dayjs(date))) {
												setStartDateError(true);
											} else {
												setStartDateError(false);
											}
											setEndDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										value={endDate}
										format={dateFormat}
										label='End date'
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton>
														<EventIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</Box>
					</ThemeProvider>
					<Button
						disabled={submissionIds.length === 0 || isApprovedStatus}
						className={`${timesheetPendingApprovalStyles.button}
								${
									submissionIds.length === 0 || isApprovedStatus
										? modalFormStyles.disabledButton
										: timesheetPendingApprovalStyles.approveButton
								}
								`}
						onClick={() => {
							setIsConfirmationModalShown(true);
						}}
					>
						Approve Overtime(s)
					</Button>
				</div>
				{employeeId &&
				submittedOvertimeList &&
				submittedOvertimeList?.user?.length > 0 ? (
					<div>
						{submittedOvertimeList?.user.map((user, index) =>
							user.timesheetOvertimeSubmissions.length > 0 ? (
								<div
									className={timesheetPendingApprovalStyles.timesheetsContainer}
									key={index}
								>
									<div
										className={timesheetPendingApprovalStyles.avatarContainer}
									>
										<Avatar
											className={timesheetPendingApprovalStyles.avatar}
											src={`${user.profile_pic || ''}`}
										>
											{user?.first_name?.charAt(0).toUpperCase() || ''}
											{user?.last_name?.charAt(0).toUpperCase() || ''}
										</Avatar>
										<Typography
											className={timesheetPendingApprovalStyles.employeeName}
										>
											{`${user.full_name || ''}`}
										</Typography>
									</div>
									{submittedOvertimeLoading ? (
										<Loading />
									) : (
										<Table>
											<TableHead>
												<TableRow>
													<CustomTableCell></CustomTableCell>
													<CustomTableCell>DATE</CustomTableCell>
													<CustomTableCell>STATUS</CustomTableCell>
													<CustomTableCell>TOTAL HOURS</CustomTableCell>
													<CustomTableCell>BILLABLE HOURS</CustomTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{user.timesheetOvertimeSubmissions
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((timesheetSubmission) =>
														timesheetSubmission.timesheet_overtimes.length >
														0 ? (
															<TableRow
																hover={true}
																key={timesheetSubmission.id}
															>
																<CustomTableCell
																	className={
																		timesheetPendingApprovalStyles.dataActionContainer
																	}
																>
																	{timesheetSubmission.timesheet_status
																		.value === 'approved' ? (
																		<CheckCircleIcon
																			className={
																				timesheetPendingApprovalStyles.approvedIcon
																			}
																		/>
																	) : (
																		<Checkbox
																			className={
																				timesheetPendingApprovalStyles.checkBox
																			}
																			disabled={
																				timesheetSubmission.timesheet_status
																					.value === 'rejected'
																					? true
																					: false
																			}
																			onChange={(e) => {
																				if (e.currentTarget.checked) {
																					setSubmissionIds([
																						...submissionIds,
																						timesheetSubmission.id,
																					]);
																				} else {
																					setSubmissionIds(
																						submissionIds.filter(
																							(id: any) =>
																								id !== timesheetSubmission.id
																						)
																					);
																				}
																			}}
																			name='checkedB'
																			color='primary'
																		/>
																	)}
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={
																			timesheetPendingApprovalStyles.dateContainer
																		}
																	>
																		<DateRangeIcon
																			className={
																				timesheetPendingApprovalStyles.calendarIcon
																			}
																		/>
																		{head(
																			timesheetSubmission?.timesheet_overtimes
																		)?.date
																			? dayjs(
																					head(
																						timesheetSubmission?.timesheet_overtimes
																					)?.date
																			  ).format('DD MMM YYYY')
																			: '--'}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name}`,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={`${
																			timesheetStyles.statusContainer
																		}
															${
																timesheetSubmission.timesheet_status.value ===
																'submitted'
																	? timesheetStyles.submittedStatus
																	: timesheetSubmission.timesheet_status
																			.value === 'approved'
																	? timesheetStyles.approvedStatus
																	: timesheetStyles.rejectedStatus
															}
														`}
																	>
																		{timesheetSubmission.timesheet_status
																			.value === 'submitted'
																			? 'Pending'
																			: timesheetSubmission.timesheet_status
																					.label}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	<div
																		className={
																			timesheetPendingApprovalStyles.totalHoursContainer
																		}
																	>
																		<AccessTimeIcon
																			className={
																				timesheetPendingApprovalStyles.durationIcon
																			}
																		/>
																		{`${getTotalHours(
																			timesheetSubmission.timesheet_overtimes
																		)} hours`}
																	</div>
																</CustomTableCell>
																<CustomTableCell
																	onClick={() => {
																		setIsDetailsModalShown(true);
																		handleRowClick(timesheetSubmission, {
																			id: user.id,
																			name: `${user?.full_name} `,
																			profilePic: user.profile_pic,
																		});
																	}}
																>
																	{`${getTotalBillableHours(
																		timesheetSubmission.timesheet_overtimes
																	)} hours`}
																</CustomTableCell>
															</TableRow>
														) : (
															<Box textAlign='center'>
																<img src={no_data_found} alt='no_data_found' />
															</Box>
														)
													)}
											</TableBody>
										</Table>
									)}
									<Box display='flex' justifyContent='flex-end'>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											count={user.timesheetOvertimeSubmissions?.length || 0}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
								</div>
							) : (
								<Box textAlign='center'>
									<img src={no_data_found} alt='no_data_found' />
								</Box>
							)
						)}
					</div>
				) : (
					<Box textAlign='center'>
						<img src={no_data_found} alt='no_data_found' />
					</Box>
				)}
			</div>

			<ConfirmationModal
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
				}}
				confirmationMessage={OVERTIME_APPROVAL_CONFIRMATION_TEXT}
				onSubmit={() => {
					handleApproveTimesheet();
				}}
				submitButtonLabel={OVERTIME_APPROVAL_MODAL_BUTTON_LABEL}
				loading={approveOvertimeLoading}
			/>
			<TimesheetOvertimePendingApprovalDetailsModal
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedOvertime({});
				}}
				overtime={selectedOvertime}
				onApprove={() => refetchSubmittedOvertimeList()}
			/>
		</>
	);
};

export default TimesheetOvertimeAdmin;
