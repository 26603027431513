import React from 'react';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { makeStyles } from '@material-ui/core';

const NoOfUsersIndicatorStyle = makeStyles({
	container: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: '180px',
	},
	count: {
		width: 'fit-content',
	},
});

function NoOfUsersIndicator({ count = 0 }: { count?: number }) {
	const NoOfUsersIndicatorStyles = NoOfUsersIndicatorStyle();
	return (
		<div className={NoOfUsersIndicatorStyles.container}>
			<PeopleOutlineIcon className={NoOfUsersIndicatorStyles.peoplesIcon} />
			<p
				className={`${NoOfUsersIndicatorStyles.rowContent} ${NoOfUsersIndicatorStyles.count}`}
			>
				{count || 0}
			</p>
		</div>
	);
}

export default NoOfUsersIndicator;
