import gql from 'graphql-tag';

export const GET_OPPORTUNITY_KANBAN = gql`
	query getOpportunityKanban(
		$opportunity: opportunities_bool_exp = {}
		$boardId: uuid!
	) {
		kanban_board(where: { id: { _eq: $boardId } }) {
			id
			name
			kanban_columns(order_by: { ordinal: asc }) {
				id
				name
				cards: opportunity_kanban_cards(
					where: { opportunity: $opportunity }
					order_by: { ordinal: asc }
				) {
					id
					ordinal
					updated_at
					updated_by
					column_id
					created_at
					created_by
					opportunity {
						id
						name
						priority
						win_percentage
						value
						master_currency {
							currency_type
							id
							symbol
						}
						user {
							first_name
							last_name
							full_name
							profile_pic
						}
						crm_tasks_aggregate(
							where: { due_date: { _lte: "now()" }, is_done: { _eq: false } }
						) {
							aggregate {
								count
							}
						}
					}
				}
			}
		}
		opportunityCount: opportunity_kanban_card_aggregate(
			where: {
				opportunity: $opportunity
				kanban_column: {
					board_id: { _eq: $boardId }
					state: { _eq: "opportunity" }
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_OPPORTUNITIES_STATUS = gql`
	query getOppotunityStatus {
		opportunities {
			id
			kanbanCard {
				column_id
				kanban_column {
					name
				}
			}
		}
	}
`;

export const GET_OPPORTUNITY = gql`
	query getOpportunity($id: uuid!) {
		opportunities_by_pk(id: $id) {
			company_id
			name
			id
			priority
			close_date
			created_at
			win_percentage
			description
			value
			primary_contact_id
			loss_reason_id
			lead_owner_id
			owner_id
			currency_id
			master_currency {
				currency_type
				id
				symbol
			}
			leadOwner {
				first_name
				last_name
				full_name
			}
			crm_company {
				id
				name
				logo
				website
				linkedin
				owner_id
				description
				country_id
				owner {
					first_name
					last_name
					full_name
					profile_pic
				}
			}
			crm_contact {
				first_name
				last_name
				title
				crm_company {
					name
				}
			}
			user {
				first_name
				profile_pic
				last_name
				full_name
			}
			kanbanCard {
				id
				kanban_column {
					name
					id
					kanban_board {
						id
					}
				}
			}
			leadOwner {
				first_name
				last_name
				full_name
			}
		}
	}
`;

export const GET_OPPORTUNITY_STATUS = gql`
	query getOpportunityStatus($boardId: uuid!) {
		kanban_board(where: { id: { _eq: $boardId } }) {
			kanban_columns(order_by: { ordinal: asc }) {
				id
				name
			}
		}
	}
`;

export const ADD_OPPORTUNITY = gql`
	mutation insertOpportunity($data: opportunities_insert_input!) {
		insert_opportunities_one(object: $data) {
			id
			name
			close_date
			opportunity_kanban_cards {
				column_id
				id
			}
		}
	}
`;

export const GET_OPPORTUNITIES_TOTAL = gql`
	query getOpportunitiesTotal($opportunityName: String!) {
		opportunities_aggregate(where: { name: { _ilike: $opportunityName } }) {
			aggregate {
				count
			}
		}
	}
`;

export const UPDATE_OPPORTUNITY = gql`
	mutation updateOpportunity(
		$id: uuid!
		$kanbanId: uuid!
		$data: opportunities_set_input!
		$kanData: opportunity_kanban_card_set_input
	) {
		update_opportunities_by_pk(pk_columns: { id: $id }, _set: $data) {
			close_date
		}
		update_opportunity_kanban_card_by_pk(
			pk_columns: { id: $kanbanId }
			_set: $kanData
		) {
			column_id
		}
	}
`;

export const GET_CRM_TASK_STATUS = gql`
	query getOpportunityCrmTaskStatus {
		crm_task_activity_type {
			id
			value
			label
		}
	}
`;

export const ADD_CRM_TASK = gql`
	mutation insertOpportunityCrmTask($data: crm_tasks_insert_input!) {
		insert_crm_tasks_one(object: $data) {
			id
			name
			opportunity_id
			owner_id
			priority
			activity_type_id
			description
			due_date
			crm_task_kanban_cards {
				id
				column_id
				ordinal
			}
			crm_task_tag_mappings {
				tag_id
			}
		}
	}
`;

export const CREATE_CRM_TAG = gql`
	mutation creatTag($data: crm_tags_insert_input!) {
		insert_crm_tags_one(object: $data) {
			id
			name
		}
	}
`;

export const GET_CRM_TASKS = gql`
	query getOpportunityCrmTasks($opportunity_id: uuid!, $is_done: Boolean) {
		crm_tasks(
			where: {
				opportunity_id: { _eq: $opportunity_id }
				is_done: { _eq: $is_done }
			}
			order_by: { due_date: asc }
		) {
			activity_type_id
			description
			due_date
			id
			name
			owner_id
			is_done
			priority
			crm_task_tag_mappings {
				crm_tags {
					id
				}
			}
			crm_task_activity_type {
				label
			}
			crm_task_kanban_cards {
				kanban_column {
					state
				}
			}
			user {
				profile_pic
				first_name
				last_name
				full_name
			}
		}
	}
`;
export const GET_Project = gql`
	query getProject($opportunity_id: uuid!) {
		project(where: { opportunity_id: { _eq: $opportunity_id } }) {
			name
			opportunity_id
			revenue
			project_type
			currency_id
			geo_id
			pre_sales_owner_id
			description
			start_date
			project_status_id
			id
			end_date
			currency {
				symbol
				currency_type
			}
			ProjectStatus {
				label
				id
			}
		}
	}
`;

export const GET_OPPORTUNITY_NOTES = gql`
	query getOpportunityNotes($opportunity_id: uuid!, $SearchText: String!) {
		opportunity_notes(
			where: {
				opportunity_id: { _eq: $opportunity_id }
				deleted_at: { _is_null: true }
				_or: [
					{ title: { _ilike: $SearchText } }
					{ note: { _ilike: $SearchText } }
				]
			}
			order_by: { created_at: desc }
		) {
			user {
				first_name
				last_name
				full_name
				profile_pic
			}
			created_at
			created_by
			note
			opportunity_id
			title
			updated_at
			updated_by
			id
		}
	}
`;

export const UPSERT_OPPORTUNITY_NOTE = gql`
	mutation upsertOpportunityNote(
		$opportunityNote: opportunity_notes_insert_input!
	) {
		insert_opportunity_notes_one(
			object: $opportunityNote
			on_conflict: {
				constraint: opportunity_notes_pkey
				update_columns: [note, title]
			}
		) {
			id
			title
			note
		}
	}
`;

export const Delete_OPPORTUNITYNOTE = gql`
	mutation deleteOpportunityNote($noteId: uuid!) {
		update_opportunity_notes(
			where: { id: { _eq: $noteId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;
export const GET_ORGANISATION = gql`
	query getOrganisation($organizationId: uuid!) {
		organization(where: { id: { _eq: $organizationId } }) {
			id
			name
			financial_year_start_month
			financial_year_start_date
			financial_year_end_month
			financial_year_end_date
			currency {
				id
				currency_type
				symbol
			}
		}
	}
`;
export const GET_COMPANIES = gql`
	query getCompanyFilter($limit: Int, $company: String, $boardId: uuid!) {
		opportunities(
			where: {
				_and: { kanbanCard: { kanban_column: { board_id: { _eq: $boardId } } } }
				crm_company: { name: { _ilike: $company } }
			}
			limit: $limit
			distinct_on: company_id
		) {
			crm_company {
				id
				name
			}
		}
		crm_company_aggregate(
			where: {
				opportunities: {
					kanbanCard: { kanban_column: { board_id: { _eq: $boardId } } }
				}
			}
			distinct_on: id
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_OWNERS = gql`
	query getOwners($limit: Int, $name: String, $boardId: uuid!) {
		opportunities(
			distinct_on: lead_owner_id
			where: {
				lead_owner_id: { _is_null: false }
				deleted_at: { _is_null: true }
				leadOwner: { first_name: { _ilike: $name } }
				kanbanCard: { kanban_column: { board_id: { _eq: $boardId } } }
			}
			limit: $limit
		) {
			leadOwner {
				first_name
				id
				last_name
				full_name
			}
		}
		opportunities_aggregate(
			distinct_on: lead_owner_id
			where: {
				lead_owner_id: { _is_null: false }
				deleted_at: { _is_null: true }
				leadOwner: { first_name: { _ilike: $name } }
				kanbanCard: { kanban_column: { board_id: { _eq: $boardId } } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_OPPORTUNITY_MIN_MAX = gql`
	query getMinMaxOfOpportunity {
		opportunities_aggregate {
			aggregate {
				max {
					value
				}
				min {
					value
				}
			}
		}
	}
`;

export const GET_OPPORTUNITY_STAGE_SUM = gql`
	query getOpportunitySumByLabel($boardId: uuid!) {
		opportunites_sum_by_stage(where: { board_id: { _eq: $boardId } }) {
			name
			sum
		}
	}
`;

export const GET_USERS = gql`
	query invitedUser($boardId: uuid!, $name: String = "") {
		kanban_board_invited_users(
			where: {
				board_id: { _eq: $boardId }
				user: { is_active: { _eq: true }, first_name: { _ilike: $name } }
			}
			distinct_on: user_id
		) {
			user {
				first_name
				id
				last_name
				full_name
				profile_pic
			}
		}
	}
`;

export const GET_CRM_TASK_ACTIVITIES = gql`
	query getCrmTaskActivities {
		crm_task_activity_type {
			id
			label
			value
		}
	}
`;
export const UPDATE_TASK_STATUS = gql`
	mutation updateCrmTask($taskId: uuid!, $crmTask: crm_tasks_set_input!) {
		update_crm_tasks_by_pk(pk_columns: { id: $taskId }, _set: $crmTask) {
			activity_type_id
			name
		}
	}
`;

export const GET_OPPORTUNITY_DOCS = gql`
	query getOpportunityDocs($opportunity_id: uuid!) {
		opportunity_docs(
			order_by: { file_name: asc }
			where: { opportunity_id: { _eq: $opportunity_id } }
		) {
			id
			url
			file_name
		}
	}
`;

export const ADD_OPPORTUNITY_DOC = gql`
	mutation addOpportunityDoc($data: opportunity_docs_insert_input!) {
		insert_opportunity_docs_one(object: $data) {
			url
		}
	}
`;

export const DELETE_OPPORTUNITY_DOC = gql`
	mutation deleteOpportunityDoc($id: uuid!) {
		delete_opportunity_docs(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_FILE_NAME = gql`
	mutation updateFileName($file_name: String!, $id: uuid!) {
		update_opportunity_docs(
			_set: { file_name: $file_name }
			where: { id: { _eq: $id } }
		) {
			returning {
				id
			}
		}
	}
`;

export const DELETE_OPPORTUNITY = gql`
	mutation deleteOpportunity($id: uuid!) {
		update_opportunities(
			where: { id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_crm_tasks(
			where: { opportunity_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_opportunity_docs(
			where: { opportunity_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_opportunity_notes(
			where: { opportunity_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_opportunity_kanban_card(
			where: { opportunity_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_KANBAN_BOARD = gql`
	query getCrmKanbanBoard($filter: kanban_board_bool_exp = {}) {
		kanban_board(where: $filter, order_by: { name: asc }) {
			name
			type
			id
			kanban_columns(where: { state: { _eq: "opportunity" } }) {
				opportunity_kanban_cards(
					where: { kanban_column: { state: { _eq: "opportunity" } } }
				) {
					opportunity {
						value
					}
				}
				opportunity_kanban_cards_aggregate(
					where: { kanban_column: { state: { _eq: "opportunity" } } }
				) {
					aggregate {
						count
					}
				}
			}
		}
		kanban_board_aggregate(where: $filter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_CRM_BOARD_NAME = gql`
	query getCrmBoardName($boardId: uuid) {
		kanban_board(where: { id: { _eq: $boardId } }) {
			name
		}
	}
`;

export const ADD_INVITED_USER = gql`
	mutation addInvitedUser(
		$invitedUser: kanban_board_invited_users_insert_input!
	) {
		insert_kanban_board_invited_users_one(
			object: $invitedUser
			on_conflict: { constraint: kanban_board_invited_users_pkey }
		) {
			id
		}
	}
`;

export const GET_INVITED_USER = gql`
	query getInvitedUser($board_id: uuid!) {
		kanban_board_invited_users(
			where: {
				board_id: { _eq: $board_id }
				is_primary: { _eq: false }
				user: { is_active: { _eq: true } }
			}
			order_by: { user: { full_name: asc } }
		) {
			user {
				first_name
				last_name
				full_name
				id
			}
			id
		}
	}
`;

export const DELETE_INVITED_USER = gql`
	mutation deleteInvitedUser($invitedUserId: uuid!) {
		delete_kanban_board_invited_users_by_pk(id: $invitedUserId) {
			id
		}
	}
`;

export const GET_OPPORTUNITY_COUNT_BY_PRE_SALES_OWNER = gql`
	query getOpportunityCountByPresalesOwner($boardId: uuid!, $userId: uuid!) {
		opportunity_kanban_card_aggregate(
			where: {
				_and: {
					kanban_column: {
						state: { _eq: "opportunity" }
						board_id: { _eq: $boardId }
					}
				}
				deleted_at: { _is_null: true }
				opportunity: { owner_id: { _eq: $userId } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_KANBAN_BOARD_INVITED_USERS = gql`
	query getkanbanBoardInvitedUsers($board_id: uuid!) {
		kanban_board_invited_users(
			where: {
				board_id: { _eq: $board_id }
				user: { is_active: { _eq: true } }
			}
			distinct_on: user_id
		) {
			user {
				first_name
				last_name
				full_name
				id
			}
			id
			is_primary
		}
	}
`;

export const UPDATE_OPPORTUNITY_CLOSE_DATE = gql`
	mutation updateOpportunityCloseDate(
		$close_date: timestamptz = ""
		$OpportunityId: uuid!
	) {
		update_opportunities_by_pk(
			pk_columns: { id: $OpportunityId }
			_set: { close_date: $close_date }
		) {
			id
		}
	}
`;

export const UPDATE_OPPORTUNITY_KANBAN_CARD = gql`
	mutation updateOpportunityKanbanCard(
		$kanbanCardId: uuid!
		$columnId: uuid!
		$ordinal: numeric!
	) {
		update_opportunity_kanban_card_by_pk(
			pk_columns: { id: $kanbanCardId }
			_set: { column_id: $columnId, ordinal: $ordinal }
		) {
			kanban_column {
				state
			}
			opportunity_id
			column_id
		}
	}
`;

export const CHECK_INVITED_USER = gql`
	query checkInvitedUser($boardId: uuid, $userId: uuid!) {
		kanban_board_invited_users(
			where: {
				_and: [{ board_id: { _eq: $boardId } }, { user_id: { _eq: $userId } }]
			}
		) {
			id
			user {
				first_name
				last_name
				full_name
			}
		}
	}
`;

export const DELETE_PIPELINE = gql`
	mutation deletePipeline($id: uuid!) {
		update_opportunity_docs(
			where: {
				opportunity: {
					kanbanCard: { kanban_column: { board_id: { _eq: $id } } }
				}
			}
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_opportunity_notes(
			where: {
				opportunity: {
					kanbanCard: { kanban_column: { board_id: { _eq: $id } } }
				}
			}
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_crm_task_kanban_card(
			where: { kanban_column: { board_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
		update_crm_tasks(
			where: {
				opportunity: {
					kanbanCard: { kanban_column: { board_id: { _eq: $id } } }
				}
			}

			_set: { deleted_at: "now()" }
		) {
			returning {
				id

				name
			}
		}
		update_opportunities(
			where: { kanbanCard: { kanban_column: { board_id: { _eq: $id } } } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}

		update_opportunity_kanban_card(
			where: { kanban_column: { board_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}

		update_kanban_board_invited_users(
			where: { board_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}

		update_kanban_column(
			where: { board_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}

		update_kanban_board(
			where: { id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;
export const GET_PIPELINE_OPTIONS = gql`
	query getCrmKanbanBoardOptions {
		kanban_board(
			where: {
				master_sub_module: { master_module: { value: { _eq: "crm" } } }
				is_active: { _eq: true }
			}
			order_by: { name: asc }
		) {
			name
			id
		}
	}
`;
