import React, { useState } from 'react';
import { Loading, useNotify, useRefresh } from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	Box,
	IconButton,
	makeStyles,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Tooltip,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import {
	TIMESHEET_ORG_TASK_DESCRIPTION,
	TIMESHEET_ORG_TASK_DELETE_CONFIRMATION_TEXT,
	TIMESHEET_ORG_TASK_DELETION_TEXT,
} from '../constant';
import {
	useGetOrgTaskMappingsQuery,
	useSoftDeleteOrgTaskMutation,
} from '../../../generated/graphql';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TimesheetOrgTaskForm from './TimesheetOrgTaskForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import OrgTaskDepartmentMappingForm from './OrgTaskDepartmentMappingForm.component';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '16px 0px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	departmentsContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: 'fit-content',
	},
	name: {
		color: '#292929',
		fontSize: '12px !important',
		fontFamily: 'Manrope-medium',
		width: '200px',
	},
});

interface OrgTask {
	id?: string;
	is_billable?: boolean;
	title?: string;
	task_category?: string;
	org_id?: string;
}

interface OrgTaskMappings {
	orgTaskMappings?: {
		orgTaskMappingId: any;
		department_id: any;
	}[];
	department_id?: any[];
}

export const TimesheetOrgTask = () => {
	const [isTaskFormShown, setIsTaskFormShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isDepartmentMappingFormShown, setIsDepartmentMappingFormShown] =
		useState(false);
	const [selectedIdForDelete, setSelectedIdForDelete] = useState(null);
	const [selectedOrgTask, setSelectedOrgTask] = useState<OrgTask>({});
	const [selectedOrgTaskMappings, setSelectedOrgTaskMappings] =
		useState<OrgTaskMappings>();
	const datagridStyles = datagridStyle();
	const timesheetOrgTaskStyles = useStyles();
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		data: orgTaskMappings,
		loading: isOrgTaskMappingsLoading,
		refetch: refetchOrgTaskMappings,
	} = useGetOrgTaskMappingsQuery();
	const [deleteOrgTask] = useSoftDeleteOrgTaskMutation();

	const handleDeleteTask = () => {
		if (!selectedIdForDelete) {
			return;
		}
		deleteOrgTask({
			variables: {
				orgTaskId: selectedIdForDelete,
			},
		})
			.then((response) => {
				if (!response.errors) {
					setIsWarningModalShown(false);
					setSelectedIdForDelete(null);
					refetchOrgTaskMappings();
					notify(TIMESHEET_ORG_TASK_DELETION_TEXT);
					refresh();
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	if (isOrgTaskMappingsLoading) {
		return <Loading />;
	}

	return (
		<>
			<Typography className={settingsStyles.description}>
				{TIMESHEET_ORG_TASK_DESCRIPTION}
			</Typography>
			<Box className={timesheetOrgTaskStyles.toolBarContainer}>
				<Button
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsTaskFormShown(true);
					}}
				>
					Add Task
				</Button>
			</Box>
			<div>
				{orgTaskMappings && orgTaskMappings.org_tasks.length > 0 ? (
					<Table>
						<TableHead>
							<TableRow>
								<CustomTableCell>NAME</CustomTableCell>
								<CustomTableCell>CATEGORY</CustomTableCell>
								<CustomTableCell>BILLABLE</CustomTableCell>
								<CustomTableCell>DEPARTMENTS</CustomTableCell>
								<CustomTableCell></CustomTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orgTaskMappings.org_tasks.map((orgTask, index) => (
								<TableRow key={index} hover={true}>
									<CustomTableCell>
										<Tooltip title={`${orgTask.title}`} placement={'right'}>
											<div
												className={`${timesheetOrgTaskStyles.name} ${ellipsisStyles.ellipsis}`}
											>
												{orgTask.title}
											</div>
										</Tooltip>
									</CustomTableCell>
									<CustomTableCell>
										<Tooltip
											title={`${orgTask.task_category}`}
											placement={'right'}
										>
											<div
												className={`${timesheetOrgTaskStyles.name} ${ellipsisStyles.ellipsis}`}
											>
												{orgTask.task_category}
											</div>
										</Tooltip>
									</CustomTableCell>
									<CustomTableCell>
										{orgTask.is_billable ? 'Yes' : 'No'}
									</CustomTableCell>
									<CustomTableCell>
										<div
											className={timesheetOrgTaskStyles.departmentsContainer}
											onClick={() => {}}
										>
											<PeopleOutlineIcon
												className={timesheetOrgTaskStyles.peoplesIcon}
											/>
											<Typography className={timesheetOrgTaskStyles.rowContent}>
												{orgTask.departments.length}
											</Typography>
										</div>
									</CustomTableCell>
									<CustomTableCell>
										<Box display='flex' alignItems='center' component='span'>
											<Box
												className={datagridStyles.listEditIconContainer}
												mr={1}
											>
												<IconButton
													className={datagridStyles.listEditIconButton}
													onClick={() => {
														if (orgTask.departments.length > 0) {
															setSelectedOrgTaskMappings({
																orgTaskMappings: orgTask.departments.map(
																	(department) => ({
																		orgTaskMappingId: department.id,
																		department_id: department.department.id,
																	})
																),
																department_id: orgTask.departments.map(
																	(department) => department.department.id
																),
															});
															setSelectedOrgTask(orgTask);
															setIsDepartmentMappingFormShown(true);
															return;
														}
														setSelectedOrgTask(orgTask);
														setIsDepartmentMappingFormShown(true);
													}}
												>
													<PersonAddIcon className={datagridStyles.editIcon} />
												</IconButton>
											</Box>
											<Box
												className={datagridStyles.listEditIconContainer}
												mr={1}
											>
												<IconButton
													className={datagridStyles.listEditIconButton}
													onClick={() => {
														setSelectedOrgTask(orgTask);
														setIsTaskFormShown(true);
													}}
												>
													<EditIcon className={datagridStyles.editIcon} />
												</IconButton>
											</Box>
											<Box className={datagridStyles.listEditIconContainer}>
												<IconButton
													className={`${datagridStyles.listEditIconButton} ${timesheetOrgTaskStyles.deleteIconButton}`}
													onClick={() => {
														setSelectedIdForDelete(orgTask.id);
														setIsWarningModalShown(true);
													}}
												>
													<DeleteIcon className={datagridStyles.editIcon} />
												</IconButton>
											</Box>
										</Box>
									</CustomTableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				) : (
					'No data found'
				)}
			</div>
			<TimesheetOrgTaskForm
				open={isTaskFormShown}
				onClose={() => {
					setSelectedOrgTask({});
					setIsTaskFormShown(false);
				}}
				initialValues={selectedOrgTask}
				onSuccess={() => {
					refetchOrgTaskMappings();
				}}
			/>
			<DeleteModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setSelectedIdForDelete(null);
				}}
				confirmationMessage={TIMESHEET_ORG_TASK_DELETE_CONFIRMATION_TEXT}
				onDelete={handleDeleteTask}
			/>
			<OrgTaskDepartmentMappingForm
				open={isDepartmentMappingFormShown}
				onClose={() => {
					setSelectedOrgTask({});
					setSelectedOrgTaskMappings({});
					setIsDepartmentMappingFormShown(false);
				}}
				orgTaskId={selectedOrgTask.id}
				refetch={() => {
					refetchOrgTaskMappings();
				}}
				orgTaskMappings={selectedOrgTaskMappings}
			/>
		</>
	);
};

export default TimesheetOrgTask;
