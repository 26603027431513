import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	SelectInput,
	maxLength,
	useNotify,
	email,
	number,
	regex,
	minLength,
	ReferenceInput,
	AutocompleteInput,
	RadioButtonGroupInput,
} from 'react-admin';
import { useMutation as useApolloMutation } from '@apollo/client';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';

import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	currencyInputStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { UPDATE_CONTRACTOR_BY_ID } from '../gqlQueries';
import { CONSULTANT_GENDER_CHOICES } from '../Constant';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import dayjs from 'dayjs';

import { validatePhoneNumber, trimInput } from '../../../Utils/string.util';

import { UserProfileContext } from '../../../App';
import { useGetLocationsByOrgIdQuery } from '../../../generated/graphql';
import CustomField from '../../../SharedComponents/CustomField.component';

interface Address {
	city: string;
	country: string;
	houseName: string;
	postalCode: string;
	state: string;
	streetName: string;
}
interface Record {
	location_id?: string | null;
	id?: string;
	personal_email?: string;
	address?: Address;
	join_date?: string;
	end_date?: string;
	contractor_joining_source_id?: string;
	billing_cost?: string;
	contractor_cost?: string;
	gender?: string;
	[key: string]: any;
	custom_field_1?: string;
	custom_field_2?: string;
	custom_field_3?: string;
	custom_field_4?: string;
	custom_field_5?: string;
	custom_field_6?: string;
	custom_field_7?: string;
	custom_field_8?: string;
	custom_field_9?: string;
	marital_status?: boolean;
	wedding_date?: string;
	emergency_contact_number?: string;
	emergency_contact_name?: string;
}

interface Props {
	onClose: () => void;
	open: boolean;
	record: Record;
	refetch: () => void;
	customFields: {
		id: any;
		label: string;
		type: string;
		validation: any;
		options: {
			id: string;
			name: string;
		}[];
		is_active: boolean;
		index: number;
	}[];
}

interface Types {
	id: string;
	label: string;
}
interface FormValue {
	id: string;
	personal_email: string;
	address: Address;
	join_date: string;
	end_date: string;
	contractor_joining_source_id: string;
	billing_cost: string;
	contractor_cost: string;
	currency_id: string;
	org_shift_id?: string;
	gender?: string;
	location_id: string;
	dob?: string;
	custom_field_1?: string | undefined;
	custom_field_2?: string | undefined;
	custom_field_3?: string | undefined;
	custom_field_4?: string | undefined;
	custom_field_5?: string | undefined;
	custom_field_6?: string | undefined;
	custom_field_7?: string | undefined;
	custom_field_8?: string | undefined;
	custom_field_9?: string | undefined;
	wedding_date?: string;
	emergency_contact_number?: string;
	emergency_contact_name?: string;
}
const EditConsultantBasicDetails = (props: Props) => {
	const { open, onClose, record: consultant, refetch, customFields } = props;
	const [isEmailUniqueViolationError, setIsEmailUniqueViolationError] =
		useState(false);
	const notify = useNotify();
	const classes = modalFormStyle();
	const currencyStyle = currencyInputStyle();
	const [endDateError, setEndDateError] = useState(false);
	const [locationId, setLocationId] = useState<string | null>(null);
	const [marriedStatus, setMarriedStatus] = useState('');
	const [optionValue, setOptionValue] = useState<string | null>();
	
	
	const [
		updateConsultant,
		{ data: updatedConsultant, error: updateConsultantError },
	] = useApolloMutation(UPDATE_CONTRACTOR_BY_ID);

	const handleClose = () => {
		setEndDateError(false);
		onClose();
	};
	const { dateFormat, orgId: organizationId } =
		useContext<any>(UserProfileContext);

	const { data: location } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});

	useEffect(() => {
		if (consultant?.id) {
			setLocationId(consultant?.location_id || null);
		}
	}, [consultant]);
	

	

	useEffect(() => {
		if (updatedConsultant) {
			onClose();
		}
	}, [onClose, updatedConsultant]);

	useEffect(() => {
		if (updatedConsultant) {
			refetch();
		}
	}, [refetch, updatedConsultant]);

	useEffect(() => {
		if (updatedConsultant) {
			notify('Consultant updated successfully');
		}
	}, [notify, updatedConsultant]);

	useEffect(() => {
		if (!updateConsultantError) {
			return;
		}
		//TODO this is a hack for time being will be fixed in https://perfomatix.atlassian.net/browse/WP-127
		if (
			updateConsultantError?.message.includes(
				'contractor_personal_email_uindex'
			)
		) {
			setIsEmailUniqueViolationError(true);
		}
	}, [updateConsultantError]);

	const onSave = (consultantFormValue: FormValue) => {
	
		if (!consultantFormValue) {
			return;
		}
		if (
			dayjs(consultantFormValue?.join_date).isAfter(
				consultantFormValue?.end_date
			)
		) {
			setEndDateError(true);
			return;
		}

		// Marital Status
		const marriageStatus = marriedStatus
			? marriedStatus === 'Married'
				? true
				: false
			: consultant?.marital_status;

		updateConsultant({
			variables: {
				contractorId: consultant.id,
				data: {
					personal_email: consultantFormValue?.personal_email || null,
					join_date: consultantFormValue?.join_date || null,
					billing_cost: consultantFormValue?.billing_cost || null,
					contractor_cost: consultantFormValue?.contractor_cost || null,
					contractor_joining_source_id:
						consultantFormValue?.contractor_joining_source_id || null,
					address: consultantFormValue?.address,
					end_date: consultantFormValue?.end_date || null,
					org_shift_id: consultantFormValue?.org_shift_id || null,
					gender: consultantFormValue?.gender || null,
					location_id: consultantFormValue?.location_id || null,
					dob: consultantFormValue?.dob || null,
					custom_field_1: consultantFormValue?.custom_field_1 || null,
					custom_field_2: consultantFormValue?.custom_field_2 || null,
					custom_field_3: consultantFormValue?.custom_field_3 || null,
					custom_field_4: consultantFormValue?.custom_field_4 || null,
					custom_field_5: consultantFormValue?.custom_field_5 || null,
					custom_field_6: consultantFormValue?.custom_field_6 || null,
					custom_field_7: consultantFormValue?.custom_field_7 || null,
					custom_field_8: consultantFormValue?.custom_field_8 || null,
					custom_field_9: consultantFormValue?.custom_field_9 || null,
					emergency_contact_number:
						consultantFormValue?.emergency_contact_number || null,
					emergency_contact_name:
						consultantFormValue?.emergency_contact_name || null,
					wedding_date: consultantFormValue?.wedding_date,
					marital_status: marriageStatus,
				},
			},
		}).catch((error: any) => {
			if (error) {
				return;
			}
		});
	};

	const validateConsultantFormValues = async () => {
		const consultantFormErrors = {} as FormValue;
		if (isEmailUniqueViolationError) {
			consultantFormErrors.personal_email = 'Email already exist!';
		}
		return consultantFormErrors;
	};

	const getLocation = (locatId: string) => {
		const response = location?.org_location?.find(
			(location) => location.id === locatId
		);
		return response ? { id: response?.id, name: response?.name } : null;
	};
	
	const getOptionSelected = (option: string) => {
		const response = customFields
			?.map((e) => e?.options)
			?.flat()
			.find((value) => value?.id === option);
		return response ? { id: response?.id, name: response?.name } : null;
	};
	
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={consultant}
					
						onSubmit={onSave}
						validate={validateConsultantFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Typography className={classes.label}>
											Personal Email
										</Typography>
										<TextInput
											source='personal_email'
											fullWidth={true}
											label={''}
											validate={[email(), maxLength(250)]}
											onChange={() => {
												setIsEmailUniqueViolationError(false);
											}}
										/>

										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Address(House Name)
												</Typography>
												<TextInput
													source='address.houseName'
													id='houseName'
													label={''}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Address(Street Name)
												</Typography>
												<TextInput
													source='address.streetName'
													id='streetName'
													label={''}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>City</Typography>
												<TextInput
													source='address.city'
													fullWidth={true}
													label={''}
													id='city'
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>State</Typography>
												<TextInput
													source='address.state'
													fullWidth={true}
													label={''}
													id='state'
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Country
												</Typography>
												<ReferenceInput
													source='address.country'
													id='country'
													reference='geo_list'
													sort={{ field: 'name', order: 'ASC' }}
													label=''
													filterToQuery={(searchText) => ({
														name: searchText,
													})}
												>
													<AutocompleteInput optionText='name' />
												</ReferenceInput>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Postal Code
												</Typography>
												<TextInput
													source='address.postalCode'
													id='postalCode'
													fullWidth={true}
													label={''}
													validate={[number(), maxLength(6)]}
												/>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Emergency Contact Name
												</Typography>
												<TextInput
													source='emergency_contact_name'
													fullWidth={true}
													label={''}
													validate={[
														minLength(3, 'minimum three characters required'),
														maxLength(25, 'maximum 25 characters are allowed '),
													]}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Emergency Contact Number
												</Typography>
												<TextInput
													label=''
													source='emergency_contact_number'
													validate={[
														regex(validatePhoneNumber, 'Invalid phone number'),
														maxLength(15, 'Maximum 15 digits allowed'),
													]}
													fullWidth
													onChange={() => {}}
													parse={trimInput}
												></TextInput>
											</Box>
										</Box>
										<Typography className={classes.label}>
											Contract Start Date*
										</Typography>
										<Field
											name='join_date'
											validate={(value: any) => {
												if (!value) {
													return 'Required';
												}
												if (dayjs(value).isValid() === true || value === null) {
													return undefined;
												} else {
													return 'Invalid Date';
												}
											}}
										>
											{(props: any) => (
												<div>
													<CustomDateInput
														name={props.input.name}
														initialValue={props.input.value || null}
														onChange={(value) => {
															setEndDateError(false);
															props.input.onChange(value);
														}}
														dateFormat={dateFormat}
													/>
												</div>
											)}
										</Field>

										<Typography className={classes.label}>
											Contract End Date*
										</Typography>
										<Field
											name='end_date'
											validate={(value: any) => {
												if (!value) {
													return 'Required';
												}
												if (dayjs(value).isValid() === true || value === null) {
													return undefined;
												} else {
													return 'Invalid Date';
												}
											}}
										>
											{(props: any) => (
												<div>
													<CustomDateInput
														name={props.input.name}
														initialValue={props.input.value || null}
														onChange={(value) => {
															setEndDateError(false);
															props.input.onChange(value);
														}}
														dateFormat={dateFormat}
													/>
												</div>
											)}
										</Field>

										{endDateError && (
											<p className={classes.errorText}>
												End date should be greater than start date
											</p>
										)}
										<Typography className={classes.label}>
											Joining Source
										</Typography>
										<ReferenceInput
											label=''
											source='contractor_joining_source_id'
											reference='emp_joining_source'
											sort={{ field: 'label', order: 'ASC' }}
										>
											<SelectInput optionText='label' />
										</ReferenceInput>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Gender
												</Typography>
												<SelectInput
													label=''
													source='gender'
													choices={CONSULTANT_GENDER_CHOICES}
												/>
											</Box>

											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>DOB</Typography>
												<Field
													name='dob'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
										</Box>

										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Wedding Date
												</Typography>
												<Field
													name='wedding_date'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Marital Status
												</Typography>
												<RadioButtonGroupInput
													source={
														consultant?.marital_status ? 'Married' : 'Unmarried'
													}
													label={''}
													onChange={(event: any) => {
														setMarriedStatus(event);
													}}
													defaultValue={
														consultant?.marital_status ? 'Married' : 'Unmarried'
													}
													choices={[
														{ id: 'Married', name: 'Married' },
														{ id: 'Unmarried', name: 'Unmarried' },
													]}
												/>
											</Box>
										</Box>

										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>
													Consultant Billing Rate
												</Box>
												<Box display='flex'>
													<Box className={currencyStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<ReferenceInput
																source='currency_id'
																reference='master_currencies'
																label=''
															>
																<SelectInput
																	optionText='currency_type'
																	disabled={true}
																/>
															</ReferenceInput>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='billing_cost'
															label={''}
															validate={number()}
														/>
													</ThemeProvider>
												</Box>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Consultant Cost
												</Typography>
												<Box display='flex'>
													<Box className={currencyStyle.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<ReferenceInput
																source='currency_id'
																reference='master_currencies'
																label=''
															>
																<SelectInput
																	optionText='currency_type'
																	disabled={true}
																/>
															</ReferenceInput>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='contractor_cost'
															label={''}
															validate={number()}
														/>
													</ThemeProvider>
												</Box>
											</Box>
										</Box>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>Shift</Typography>
												<ReferenceInput
													label=''
													source='org_shift_id'
													reference='org_shift'
													sort={{ field: 'name', order: 'ASC' }}
												>
													<SelectInput optionText='name' />
												</ReferenceInput>
											</Box>
											<Box className={classes.multipleInput}>
												<Typography className={classes.label}>
													Location
												</Typography>
												<Field name='location_id'>
													{(props) => (
														<AutocompleteSearch
															placeholder={'Search Location'}
															option={
																location && location?.org_location
																	? location?.org_location?.map((value) => {
																			return {
																				id: value?.id,
																				name: value?.name,
																			};
																	  })
																	: []
															}
															onChange={(event) => {
																props.input.onChange(event);
																
																setLocationId(event);
															}}
															value={getLocation(locationId || '')}
															name={'location_id'}
															validates={false}
														/>
													)}
												</Field>
											</Box>
										</Box>
										{customFields?.length > 0 &&
											customFields?.map((customField, index) => (
												<Box key={index}>
													<CustomField
														name={`custom_field_${customField.index}`}
														label={customField.label}
														option={customField.options}
														dateFormat={dateFormat}
														fieldType={customField.type}
														onChange={(event) => {
															setOptionValue(event);
														}}
														value={getOptionSelected(optionValue!)}
														
														
													/>
												</Box>
											))}
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={handleClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine
														? classes.disabledButton
														: classes.saveButton
												}
												disabled={invalid || pristine}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EditConsultantBasicDetails;
