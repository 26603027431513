import React, { useState } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	regex,
	minLength,
	useMutation,
	useNotify,
	maxLength,
} from 'react-admin';
import { Button, makeStyles, Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
	validateWebsite,
	validatePhoneNumber,
	validateLinkedIn,
} from '../../Utils/string.util';
import { FullName } from './OpportunitiesKanban.model';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../Layout/styles';

const useStyles = makeStyles({
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingBottom: '30px',
		marginTop: '18px',
	},
	optionalData: {
		display: 'flex',
		cursor: 'pointer',
		color: '#5C5C5C',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	optionalText: {
		fontSize: '12px',
		fontFamily: 'Manrope-Medium',
	},
	expandIcon: {
		color: '#5C5C5C',
	},
});

interface CompanyForm {
	name?: string;
	category_id?: string;
	owner_id?: string;
	sector_id?: string;
	address?: null;
	size_id?: string;
	linkedin?: string;
	website?: string;
	phone?: string;
	contact_type_id?: string;
	description?: string;
	country_id?: string;
}
interface Props {
	onClose: () => void;
	companyName: string;
	setCompanyId: (id: string) => void;
}

const mapToContactName = (choice: FullName) =>
	`${choice?.first_name} ${choice?.last_name}`;

const AddCompany = (props: Props) => {
	const { onClose, companyName, setCompanyId } = props;
	const classes = modalFormStyle();
	const style = useStyles();
	const [companyForm, setCompanyForm] = useState<CompanyForm>({
		name: companyName,
	});
	const [mutate] = useMutation();
	const notify = useNotify();
	const [isIconExpanded, setIsIconExpanded] = useState(false);

	const onSave = () => {
		mutate(
			{
				type: 'create',
				resource: 'crm_company',
				payload: {
					data: {
						...companyForm,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					setCompanyId(data?.id);
					onClose();
					notify('Company created');
				},
				onFailure: (error) =>
					notify(`Create failed: ${error.message}`, 'warning'),
			}
		);
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Form initialValues={companyForm} onSubmit={onSave}>
			{({ handleSubmit, invalid }) => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.label}>Company Name *</Box>
					<TextInput
						source='name'
						fullWidth={true}
						label={''}
						validate={[
							required(),
							minLength(3, 'minimum three characters required'),
						]}
						onChange={(event: any) =>
							setCompanyForm({ ...companyForm, name: event.target.value })
						}
					/>
					<Box className={classes.label}>Category</Box>
					<ReferenceInput
						source='category_id'
						reference='company_categories'
						label=''
						onChange={(event: any) =>
							setCompanyForm({
								...companyForm,
								category_id: event.target.value,
							})
						}
					>
						<SelectInput optionText='label' />
					</ReferenceInput>
					<Box className={classes.label}>Account Owner</Box>
					<ReferenceInput
						source='owner_id'
						reference='user'
						label={''}
						name='owner_id'
						fullWidth
						filterToQuery={(searchText) => ({ first_name: searchText })}
						filter={{ is_active: true }}
						onChange={(event: any) =>
							setCompanyForm({ ...companyForm, owner_id: event })
						}
					>
						<AutocompleteInput
							optionText={mapToContactName}
							resettable={true}
							shouldRenderSuggestions={(name: string) => name.trim().length > 0}
						/>
					</ReferenceInput>
					<Box className={classes.label}>Country</Box>
					<ReferenceInput
						source='country_id'
						reference='geo_list'
						sort={{ field: 'name', order: 'ASC' }}
						onChange={(event) =>
							setCompanyForm({
								...companyForm,
								country_id: event.target.value,
							})
						}
						label=''
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
					<Box className={classes.label}>Sector</Box>
					<ReferenceInput
						source='sector_id'
						reference='company_sector'
						label=''
						onChange={(event: any) =>
							setCompanyForm({ ...companyForm, sector_id: event.target.value })
						}
					>
						<SelectInput optionText='label' />
					</ReferenceInput>
					<Box
						className={style.optionalData}
						onClick={() => setIsIconExpanded(!isIconExpanded)}
					>
						<Box className={style.optionalText}>
							Fill Additional Information(optional)
						</Box>
						<Box className={style.expandIcon}>
							{' '}
							{isIconExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</Box>
					</Box>
					{isIconExpanded ? (
						<>
							<Box className={classes.label}>Address</Box>
							<TextInput
								source='address'
								fullWidth={true}
								label={''}
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										address: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>Size</Box>
							<ReferenceInput
								source='size_id'
								reference='company_size'
								label=''
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										size_id: event.target.value,
									})
								}
							>
								<SelectInput optionText='label' />
							</ReferenceInput>
							<Box className={classes.label}> Linkedin URL</Box>
							<TextInput
								placeholder='Enter Company Linkedin Url Here'
								source='linkedin'
								label={''}
								fullWidth
								validate={[regex(validateLinkedIn, 'Must be a valid Link')]}
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										linkedin: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>Website</Box>
							<TextInput
								placeholder='Enter Company Website Url Here'
								source='website'
								fullWidth
								label={''}
								validate={[regex(validateWebsite, 'Must be a valid Link')]}
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										website: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>Phone Number</Box>
							<TextInput
								placeholder='Enter Phone number Here'
								source='phone'
								label={''}
								fullWidth
								validate={[
									regex(validatePhoneNumber, 'Please enter valid phone number'),
									maxLength(15, 'Maximum 15 digits allowed'),
								]}
								onChange={(event: any) =>
									setCompanyForm({ ...companyForm, phone: event.target.value })
								}
							/>
							<Box className={classes.label}>Contact Type</Box>
							<ReferenceInput
								source='contact_type_id'
								reference='company_contact_type'
								label=''
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										contact_type_id: event.target.value,
									})
								}
							>
								<SelectInput optionText='label' />
							</ReferenceInput>
							<Box className={classes.label}>Description</Box>
							<TextInput
								source='description'
								label={''}
								fullWidth
								onChange={(event: any) =>
									setCompanyForm({
										...companyForm,
										description: event.target.value,
									})
								}
							/>
						</>
					) : null}
					<Box className={style.buttonContainer}>
						<Button onClick={handleClose} className={classes.cancelButton}>
							Cancel
						</Button>
						<Button
							className={invalid ? classes.disabledButton : classes.saveButton}
							disabled={invalid}
							type='submit'
						>
							Add Company
						</Button>
					</Box>
				</form>
			)}
		</Form>
	);
};

export default AddCompany;
