import { makeStyles } from '@material-ui/core';

export const Style = makeStyles(() => ({
	additionalInfoLabelMobileView: {
		padding: '0em 0.7em',
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	attendanceDetailsMobileView: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},
	checkinContainerMobileView: {
		background: 'rgba(0, 0, 0, 0.02)',
		width: '100%',
		margin: '1em 0em',
		borderRadius: '10px',
		padding: '1em',
	},
	currentDateInfoContainerMobileView: {
		padding: '0em 0.5em',
	},
	addCheckButtonContainerMobileView: {
		width: '100%',
		fontSize: '12px',
		fontFamily: 'Manrope-normal',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em 0em',
	},
	addCheckButtonMobileView: {
		background: 'rgba(14, 99, 224, 1)',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		borderRadius: '8px',
		width: '50%',
	},
	addCheckoutButtonMobileView: {
		color: 'rgba(106, 106, 106, 1) !important',
		background: 'rgba(239, 236, 236, 1)',
		width: '50%',
		textTransform: 'capitalize',
		borderRadius: '0px 8px 8px 0px',
	},
	addCheckedInContainerMobileView: {
		color: 'rgba(106, 106, 106, 1) !important',
		border: 'none !important',
		background: 'rgba(239, 236, 236, 1)',
		backgroundColor: 'rgba(239, 236, 236, 1) !important',
		width: '50%',
		textTransform: 'capitalize',
		borderRadius: '8px 0px 0px 8px',
	},
	checkoutOutMobileView: {
		background: '#FFDC7F',
		backgroundColor: '#FFDC7F !important',
		color: '#888888',
		textTransform: 'capitalize',
		width: '50%',
		borderRadius: '0px 8px 8px 0px',
	},
	todaysDate: {
		color: '#292929',
		fontSize: '16px',
		fontFamily: 'Manrope-bold',
	},
	todaysDay: {
		color: '#5C5C5C',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginTop: '3px',
	},
	checkedin: {
		display: 'flex',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		textTransform: 'capitalize',
	},
	additionalInfoLabel: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	homePageContainer: {
		diplay: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	homePageContentContainer: {
		padding: '4em 1em 1em 1em',
		marginBottom: '5.5em',
	},
	homePageTitle: {
		fontFamily: 'Manrope',
		fontWeight: 800,
		fontSize: '16px',
		color: '#242637',
	},
	bottomNavBar: {
		padding: '1em 0em',
		justifyContent: 'space-between',
		background: '#FFFFFF',
		boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
		position: 'fixed',
		bottom: 0,
	},
	bottomNavBarIconLabel: {
		fontFamily: 'Manrope',
		fontWeight: 600,
		fontSize: '12px',
	},
	bottomNavBarIconLabelActive: {
		color: '#0E63E0',
	},
	bottomNavBarIconLabelDisabled: {
		color: '#B8B8B8',
	},
	bottomNavBarIcon: {
		marginBottom: '0.4em',
	},

	calenderTitleMobileView: {
		color: '#292929',
		font: 'normal normal bold 12px/15px Manrope',
		padding: '20px',
	},
	calenderContainerMobileView: {
		backgroundColor: 'rgba(0, 0, 0, 0.02)',
		borderRadius: '10px',
	},
	presentMobileView: {
		background: '#34A85380',
		borderRadius: '8px',
	},
	holidayMobileView: {
		background: '#FFDC7F',
		borderRadius: '8px',
	},
	weekendMobileView: {
		color: '#888888',
		borderRadius: '8px',
		backgroundColor: '#ECF1F4',
		opacity: 0.5,
	},
	absentMobileView: {
		background: '#EA4335',
		borderRadius: '8px',
	},
	calenderMobileView: {
		color: '#888888',
		background: '#ECF1F4',
		borderRadius: '8px',
	},
	calenderListWeekendMobileView: {
		height: '17px',
		width: '17px',
		background: '#888888',
	},
	calenderListAbsentMobileView: {
		height: '17px',
		width: '17px',
		background: '#EA4335',
	},
	calenderListHolidayMobileView: {
		height: '17px',
		width: '17px',
		background: '#FFDC7F',
	},
	calenderListPresentMobileView: {
		height: '17px',
		width: '17px',
		background: '#99D3A8',
	},
	calenderListTitleMobileView: {
		color: '#5C5C5C',
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		marginLeft: '7px',
	},
	datePickerContainerMobileView: {
		'& .MuiPickersStaticWrapper-staticWrapperRoot': {
			backgroundColor: 'transparent',
		},
		'& .MuiPickersCalendarHeader-iconButton': {
			padding: '2px',
			backgroundColor: 'rgba(239, 236, 236, 1)',
		},
		'& .MuiPickersCalendarHeader-daysHeader': {
			marginTop: '1em',
			justifyContent: 'space-between',
		},
		'& .MuiPickersSlideTransition-transitionContainer': {
			marginTop: '0px',
			marginBottom: '10px',
		},
	},
	calenderLegendsContainer: {
		width: '22%',
	},
	addLeaveButtonMobileView: {
		textTransform: 'none',
		minWidth: '140px',
		background: 'rgba(14, 99, 224, 1) 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '8px',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		color: '#FFFFFF !important',
	},
	leaveButtonContainerMobileView: {
		display: 'none',
		padding: '1em 0em',
	},
	dayTypeLabelContainerMobileView: {
		display: 'none',
	},
	dayTypeLabel: {
		background: 'rgba(14, 99, 224, 0.1)',
		color: 'rgba(14, 99, 224, 1)',
		borderRadius: '20px',
		width: '100%',
		textAlign: 'center',
		margin: '1.5em 0em',
		padding: '0.45em 0em',
		fontSize: '12px',
		fontWeight: 700,
		fontFamily: 'Manrope-bold',
	},
	currDayLabelBold: {
		fontSize: '12px',
		fontWeight: 700,
		fontFamily: 'Manrope-bold',
		color: 'rgba(36, 38, 55, 1)',
	},
	currDayLabelLight: {
		fontSize: '12px',
		fontWeight: 700,
		fontFamily: 'Manrope-bold',
		color: 'rgba(36, 38, 55, 0.5)',
	},
	currDayLabelContainer: {
		padding: '0.35em 0em',
	},
	currDateInfoContainerMobileView: {
		padding: '1em 0em',
	},
	currDayTimeInfoContainerMobileView: {
		width: '25%',
		'& img': {
			paddingBottom: '0.2em',
		},
	},
}));
