import React, { useContext, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { FunctionField } from 'react-admin';
import DetailContainer from '../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../SharedComponents/Employee/FieldContainer.componet';
import { detailsPageTabDetailsStyle } from '../../Layout/styles';
import { Props } from './model';
import editIcon from '../../assets/icons/edit-icon.svg';
import EditMyInfoBankForm from './EditMyInfoBankForm.component';
import { FieldPermissionsContext } from './MyInfoView.component';

const MyInfoBankDetails = (props: Props) => {
	const { myInfoViewDetails, refetchContractor, refetchEmployee } = props;
	const [isFormShown, setIsFormShown] = useState(false);

	const { isEmpSelfServiceEnabled } = useContext<any>(FieldPermissionsContext);
	const classes = detailsPageTabDetailsStyle();
	return (
		<Box>
			{isEmpSelfServiceEnabled && myInfoViewDetails?.user_type === 'employee' && (
				<Box
					className={classes.editContainer}
					onClick={() => setIsFormShown(true)}
				>
					<img className={classes.editButton} src={editIcon} alt='editicon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			)}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Bank Name</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(employee: any) => `${employee?.bank_name || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Branch Name</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(employee: any) => `${employee?.branch_name || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Account Number</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(employee: any) => `${employee?.account_number || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>IFSC Code</Typography>
					<FunctionField
						className={classes.value}
						record={myInfoViewDetails}
						render={(employee: any) => `${employee?.ifsc_code || '- -'}`}
					/>
				</FieldContainer>
			</DetailContainer>
			<EditMyInfoBankForm
				open={isFormShown}
				onClose={() => setIsFormShown(false)}
				record={{
					id: myInfoViewDetails.id,
					bank_name: myInfoViewDetails.bank_name,
					branch_name: myInfoViewDetails.branch_name,
					account_number: myInfoViewDetails.account_number,
					ifsc_code: myInfoViewDetails.ifsc_code,
				}}
				refetch={() => {
					if (myInfoViewDetails?.user_type === 'employee' && refetchEmployee) {
						refetchEmployee();
						return;
					}
					if (refetchContractor) {
						refetchContractor();
					}
				}}
			/>
		</Box>
	);
};

export default MyInfoBankDetails;
