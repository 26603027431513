import { BooleanInput, CheckboxGroupInput, Loading } from 'ra-ui-materialui';
import React, { useContext, useState } from 'react';
import {
	useGetEditableColumnsQuery,
	useGetOrgEditableColumnQuery,
	useUpdateEditableColumnsMutation,
} from '../../../generated/graphql';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../../Layout/styles';
import {
	Box,
	createTheme,
	makeStyles,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { head } from 'lodash';
import { UserProfileContext } from '../../../App';
import { useNotify, useRefresh } from 'ra-core';
import { ORG_EDITABLE_COLUMNS_UPDATION_TEXT } from './constant';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

const editableColumnsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

const EditableColumns = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const [isLoading, setIsLoading] = useState(false);
	const formStyles = modalFormStyle();
	const styles = settingsStyle();
	const editableColumnStyles = editableColumnsStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: editableColumns } = useGetEditableColumnsQuery();
	const { data: orgEditableColumns, refetch: refetchEditableColumns } =
		useGetOrgEditableColumnQuery({
			variables: {
				orgId: orgId,
			},
		});

	const [
		updateOrgEditableColumns,
		{ loading: isUpdateOrgEditableColumnsLoading },
	] = useUpdateEditableColumnsMutation();

	const getEmployeeEditableColumnOptions = () => {
		if (!editableColumns) {
			return [];
		}
		return head(editableColumns.master_settings)?.options.employeeColumns;
	};

	const getEditableColumnInitialValues = () => {
		if (!orgEditableColumns) {
			return;
		}
		return {
			enable_emp_ss: head(orgEditableColumns.organization)?.enable_emp_ss,
			employee_columns: head(orgEditableColumns.organization)?.editable_columns,
		};
	};

	const handleSave = (formValues: {
		enable_emp_ss?: boolean;
		employee_columns?: string[];
	}) => {
		if (!formValues) {
			return;
		}
		setIsLoading(true);
		updateOrgEditableColumns({
			variables: {
				orgId: orgId,
				data: {
					enable_emp_ss: formValues.enable_emp_ss,
					editable_columns: formValues.employee_columns,
				},
			},
		}).then((response) => {
			if (!response.errors) {
				notify(ORG_EDITABLE_COLUMNS_UPDATION_TEXT);
				refresh();
				refetchEditableColumns();
				setIsLoading(false);
			}
		});
	};

	return (
		<>
			<Typography className={editableColumnStyles.description}>
				Enable this section will allow users to modify HRMS information.
			</Typography>
			<Form
				onSubmit={handleSave}
				initialValues={getEditableColumnInitialValues}
				mutators={{
					unCheckAllEditableColumns: (args, state, utils) => {
						utils.changeValue(state, 'employee_columns', () => []);
					},
					checkAllEditableColumns: (args, state, utils) => {
						utils.changeValue(state, 'employee_columns', () => {
							if (!editableColumns) {
								return;
							}
							return head(
								editableColumns.master_settings
							)?.options.employeeColumns.map(
								(employeeColumn: { id: string; name: string }) =>
									employeeColumn.id
							);
						});
					},
					isEmployeeNameEnabled: (args, state, utils) => {
						const value: { [key: string]: any } = state.formState.values;
						if (value?.enable_emp_ss) {
							return false;
						}
						return true;
					},
				}}
			>
				{({ handleSubmit, invalid, pristine, form, values }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div>
								<Box
									className={styles.inputContainer}
									display='flex'
									flexDirection='column'
									height='200px'
								>
									{isLoading ? (
										<Loading />
									) : (
										<>
											<div className={styles.inputContainer}>
												<BooleanInput
													label='Enable Employee Self Service'
													source='enable_emp_ss'
													onChange={(value) => {
														form.mutators.isEmployeeNameEnabled();
														if (value) {
															form.mutators.checkAllEditableColumns();
															return;
														}
														form.mutators.unCheckAllEditableColumns();
													}}
												/>
											</div>
											<div className={styles.inputContainer}>
												<CheckboxGroupInput
													classes={{
														root: editableColumnStyles.label,
													}}
													source='employee_columns'
													label=''
													choices={getEmployeeEditableColumnOptions()}
													disabled={form.mutators.isEmployeeNameEnabled()}
													color={'primary'}
												/>
											</div>
										</>
									)}
								</Box>
								<div className={styles.inputContainer}>
									<div className={formStyles.floatingButtonContainer}>
										<ButtonWithLoader
											isDisabled={
												invalid || pristine || isUpdateOrgEditableColumnsLoading
											}
											title='Save'
											isLoading={isUpdateOrgEditableColumnsLoading}
										/>
									</div>
								</div>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>
		</>
	);
};

export default EditableColumns;
