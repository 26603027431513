import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	useGetProjectListIdsQuery,
	useGetProjectStatusInProjectListQuery,
} from '../generated/graphql';
import {
	ThemeProvider,
	createStyles,
	withStyles,
} from '@material-ui/core/styles';
import {
	Box,
	makeStyles,
	Tooltip,
	Typography,
	TextField,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	TableContainer,
	Paper,
	TablePagination,
	Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import dayjs from 'dayjs';
import { UserProfileContext } from '../App';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { searchBarTheme } from '../modules/Company/DetailsPage/CompanyContactList.component';
import { exportToCsv } from '../Utils/string.util';
import { orderBy, isEmpty, compact, head } from 'lodash';
import Item from 'antd/lib/list/Item';
import {
	EMPLOYEE_PROJECTLIST_EXPORT_COLUMN,
	noDataMessage,
	projectStatusText,
	tableHeadingData,
} from '../modules/Employees/constant';
import { customAutoCompleteSearchBarTheme } from '../Layout/Theme.component';
import ExportButtonWithLoader from './ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles(() => ({
	filterTextFieldStyle: {
		width: '200px',
	},
	labelAutocompleate: {
		margin: '10px 0 0 14px !important',
	},
	optionContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	checkbox: {
		marginRight: '8px',
	},
	optionText: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
	},
	label: {
		width: '80px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	specialLabel: {
		width: '100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	tableList: {
		height: '450px',
		overflowY: 'auto',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		letterSpacing: '0px',
	},
	noData: {
		fontFamily: 'normal normal medium 12px/15px Manrope !important',
		fontSize: '12px',
		paddingTop: '30px',
	},
	customRowStyle: {
		background: '#F7F9FA',
		'&:hover': {
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	DropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	DropDownFilterWrapper: {
		marginLeft: '20px',
	},
	projectStatus: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
}));
const ellipsisStyle = makeStyles({
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

export interface Props {
	userId: string;
}

type filteredProjectListType = {
	id: string;
	name: string;
	company: string;
	owner: string;
	email: string;
	endDate: string;
	status: string;
	resourcesAllocatedStartDate: string;
	resourcesAllocatedEndDate: string;
	resourceMappings: { resourceStartDate: string; resourceEndDate: string }[];
};

interface StatusInfo {
	value: string;
	label: string | null | undefined;
}

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			backgroundColor: '#FFFFFF',
		},
		body: {
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			cursor: 'pointer',
		},
	})
)(TableCell);

const EmployeeProjectList = ({ record }: { record: string }) => {
	const inlineStyle = useStyles();
	const styles = ellipsisStyle();
	const [searchedProject, setSearchedProject] = useState('');
	const { dateFormat } = useContext<any>(UserProfileContext);
	let history = useHistory();
	const [sortedList, setSortedList] = useState<filteredProjectListType[]>([]);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState<string[]>([]);
	const [statusOption, setStatusOption] = useState<string[]>([]);
	const [filteredProjectList, setFilteredProjectList] = useState<
		filteredProjectListType[]
	>([]);
	const [projectStatusOptions, setProjectStatusOptions] = useState<
		StatusInfo[]
	>([]);
	const [allSelected, setAllSelected] = useState(false);
	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const { data: projectList } = useGetProjectListIdsQuery({
		variables: {
			userId: record,
			SearchText: `%${searchedProject}%`,
		},
		fetchPolicy: 'network-only',
	});

	const { data: projectStatusList } = useGetProjectStatusInProjectListQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!projectList) {
			return;
		}

		const projects: filteredProjectListType[] =
			projectList?.project_list?.map((project) => {
				const resourceMappings =
					project?.project_resource_mapping?.map((mapping) => ({
						resourceStartDate: mapping?.start_date || '',
						resourceEndDate: mapping?.end_date || '',
					})) || [];

				return {
					name: project?.name || '',
					company: project?.Company?.name || '',
					owner: project?.ProjectOwner?.full_name || '',
					endDate: project?.end_date || '',
					status: project?.ProjectStatus?.label || '',
					resourcesAllocatedStartDate:
						head(resourceMappings)?.resourceStartDate || '',
					resourcesAllocatedEndDate:
						head(resourceMappings)?.resourceEndDate || '',
					resourceMappings,
					id: project.id || '',
					email: project?.ProjectOwner?.email || '',
				};
			}) || [];

		const projectsList = projects.flatMap((project) =>
			project.resourceMappings.map(
				(mapping: { resourceStartDate: string; resourceEndDate: string }) => ({
					...project,
					resourcesAllocatedStartDate: mapping.resourceStartDate,
					resourcesAllocatedEndDate: mapping.resourceEndDate,
				})
			)
		);

		const sortedProjects = orderBy(
			projectsList,
			['resourcesAllocatedEndDate'],
			['desc']
		);
		setSortedList(sortedProjects);
	}, [projectList]);

	const generateCSV = () => {
		if (!projectList) {
			return;
		}
		const projects = filteredProjectList?.map((project) => {
			return {
				name: project?.name,
				company: project?.company,
				owner: project?.owner,
				email: project?.email,
				endDate: project?.endDate,
				status: project?.status,
				resourcesAllocatedStartDate: project?.resourcesAllocatedStartDate,
				resourcesAllocatedEndDate: project?.resourcesAllocatedEndDate,
			};
		});

		const sortedProjects = orderBy(
			projects,
			[(project) => project.resourcesAllocatedEndDate],
			['desc']
		);
		const employeeProjects = sortedProjects?.map((value) => [
			value.name || '--',
			value.company || '--',
			value?.owner || '--',
			value?.email || '--',
			value?.endDate ? dayjs(value.endDate).format(dateFormat) : '--',
			value?.status || '--',
			value.resourcesAllocatedStartDate
				? dayjs(value.resourcesAllocatedStartDate).format(dateFormat)
				: '--',
			value.resourcesAllocatedEndDate
				? dayjs(value.resourcesAllocatedEndDate).format(dateFormat)
				: '--',
		]);
		const employeeProjectListExportData = [
			EMPLOYEE_PROJECTLIST_EXPORT_COLUMN,
			...employeeProjects,
		];
		exportToCsv('Projects List.csv', employeeProjectListExportData);
	};
	useEffect(() => {
		const StatusOptionList = (projectStatusOptions?.flatMap(
			({ label }) => label ?? []
		) ?? []) as string[];
		setStatusOption(StatusOptionList);
	}, [projectStatusOptions, filters]);

	useEffect(() => {
		const allSelected =
			statusOption.length === filters?.length || 0 ? true : false;
		setAllSelected(allSelected);
	}, [statusOption, filters]);
	const handleFilterChange = (
		event: React.ChangeEvent<{}>,
		newValue: string[]
	) => {
		setPage(0);
		if (newValue.includes('All') && filters.length === statusOption.length) {
			setFilters([]);
		} else if (newValue.includes('All')) {
			setFilters(statusOption);
		} else {
			setFilters(newValue);
		}
	};
	useEffect(() => {
		const filteredItems = sortedList?.filter((item) =>
			filters?.includes(item?.status as string)
		) as filteredProjectListType[] | undefined;
		setFilteredProjectList(filteredItems || []);
	}, [filters, sortedList]);

	useEffect(() => {
		if (isEmpty(projectStatusList?.project_list)) {
			return;
		}
		const statusList = projectStatusList?.project_list
			?.filter((value) => value?.ProjectStatus?.value === 'in_progress')
			.map((element) => element?.ProjectStatus?.label);

		const filteredStatusList = compact(statusList);
		setFilters(filteredStatusList);
		const statusListInfo = projectStatusList?.project_list?.map((value) => {
			return {
				value: value?.ProjectStatus?.id,
				label: value?.ProjectStatus?.label,
			};
		});
		const sortedStatusListInfo: {
			value: string;
			label: string | null | undefined;
		}[] = orderBy(statusListInfo, ['label'], ['asc']);
		setProjectStatusOptions(sortedStatusListInfo);
	}, [projectStatusList]);

	const getLimits = () => {
		return (
			<div className={`${inlineStyle.projectStatus} ${styles.ellipsis}`}>
				{filters.length === 1
					? filters[0]
					: `Selected ${filters?.length || 0} items`}
			</div>
		);
	};

	return (
		<>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				marginBottom={1}
			>
				<Box display='flex' alignItems='flex-end'>
					<Box>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={searchBarTheme}>
								<TextField
									placeholder='Search Project'
									label={false}
									fullWidth
									InputProps={{
										startAdornment: <SearchOutlinedIcon />,
									}}
									onChange={(e) => {
										if (!e) {
											return;
										}
										setSearchedProject(e?.target?.value);
										setPage(0);
									}}
									value={searchedProject}
								/>
							</ThemeProvider>
						</form>
					</Box>
					<Box className={inlineStyle?.DropDownFilterWrapper}>
						<Box className={inlineStyle?.DropDownFilterLabel}>
							{projectStatusText}
						</Box>
						<Autocomplete
							multiple
							options={['All', ...statusOption]}
							value={filters}
							onChange={handleFilterChange}
							selectOnFocus={true}
							disablePortal
							disableCloseOnSelect
							fullWidth={false}
							limitTags={0}
							classes={{
								tag: inlineStyle?.labelAutocompleate,
							}}
							getLimitTagsText={getLimits}
							ChipProps={{ style: { display: 'none' } }}
							renderOption={(option: string, { selected }) => {
								const selectAllProps =
									option === 'All' ? { checked: allSelected } : {};
								return (
									<div className={inlineStyle.optionContainer}>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											className={inlineStyle.checkbox}
											checked={selected || false}
											color='primary'
											{...selectAllProps}
										/>
										<Tooltip title={`${option || ''}`} placement='right'>
											<Typography
												className={`${inlineStyle.specialLabel} ${styles.ellipsis}`}
											>
												{`${option || '- -'} `}
											</Typography>
										</Tooltip>
									</div>
								);
							}}
							renderInput={(params) => (
								<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
									<TextField
										className={inlineStyle.filterTextFieldStyle}
										{...params}
										placeholder={filters?.length > 0 ? '' : 'Search Status'}
									/>
								</ThemeProvider>
							)}
						/>
					</Box>
				</Box>
				<Box>
					<ExportButtonWithLoader
						generateCSV={generateCSV}
						isDisabled={isEmpty(filteredProjectList)}
					/>
				</Box>
			</Box>
			{filteredProjectList?.length ? (
				<Box>
					<TableContainer component={Paper} className={inlineStyle.tableList}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{tableHeadingData?.map((item) => {
										return (
											<CustomTableCell key={item.id}>
												{item.label}
											</CustomTableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredProjectList?.length
									? filteredProjectList
											?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
											?.map((item) => {
												return (
													<TableRow
														key={Item.name}
														onClick={() => {
															history.push(`/project/tasks/${item?.id}`);
														}}
														className={inlineStyle.customRowStyle}
													>
														<CustomTableCell component='th' scope='row'>
															<Tooltip
																title={`${item.name || ''}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{`${item.name || ''} `}
																</Typography>
															</Tooltip>
														</CustomTableCell>

														<CustomTableCell>
															<Tooltip
																title={`${item?.company || ''}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.company || '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${item?.owner || ''}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.owner || '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${
																	item?.endDate
																		? dayjs(item?.endDate).format(dateFormat)
																		: ''
																}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.endDate
																		? dayjs(item?.endDate).format(dateFormat)
																		: '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${item?.status || ''}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.status || '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${
																	item?.resourcesAllocatedStartDate
																		? dayjs(
																				item?.resourcesAllocatedStartDate
																		  ).format(dateFormat)
																		: ''
																}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.resourcesAllocatedStartDate
																		? dayjs(
																				item?.resourcesAllocatedStartDate
																		  ).format(dateFormat)
																		: '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${
																	item?.resourcesAllocatedEndDate
																		? dayjs(
																				item?.resourcesAllocatedEndDate
																		  ).format(dateFormat)
																		: ''
																}`}
																placement='right'
															>
																<Typography
																	className={`${inlineStyle.label} ${styles.ellipsis}`}
																>
																	{item?.resourcesAllocatedEndDate
																		? dayjs(
																				item?.resourcesAllocatedEndDate
																		  ).format(dateFormat)
																		: '--'}
																</Typography>
															</Tooltip>
														</CustomTableCell>
													</TableRow>
												);
											})
									: null}
							</TableBody>
						</Table>

						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component='div'
							count={filteredProjectList?.length || 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</TableContainer>
				</Box>
			) : (
				<Typography className={inlineStyle.noData}>{noDataMessage}</Typography>
			)}
		</>
	);
};
export default EmployeeProjectList;
