import React from 'react';
import { useNotify, useRefresh } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { DELETE_PROJECT_MILESTONE } from '../gqlQueries';

const useStyles = makeStyles((theme) => ({
	detailsContainer: {
		width: '442px',
		height: '100px',
		margin: '21px',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
}));

interface Props {
	onClose: () => void;
	open: boolean;
	milestoneId: string;
	onDelete: () => void;
}

const ProjectMilestoneDelete = (props: Props) => {
	const { open, onClose, milestoneId, onDelete } = props;
	const notify = useNotify();
	const refresh = useRefresh();
	const classes = modalFormStyle();
	const containerClass = useStyles();
	const [deleteMilestone] = useApolloMutation(DELETE_PROJECT_MILESTONE);

	const deleteProjectMilestone = () => {
		deleteMilestone({
			variables: {
				milestoneId: milestoneId,
			},
		}).then((data) => {
			refresh();
			onDelete()
			onClose();
			notify('Milestone Deleted SuccessFully');
		}).catch((error: any) => {
			if (error.message.includes('project_task_mapping_project_milestone_id_fkey')) {
				notify('Deletion failed. Milestone contains tasks, Please unassign them from the milestone', 'warning')
				return;
			}
		})
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={containerClass.detailsContainer}>
					<Typography className={containerClass.heading}>
						Do you really want to delete this record ?
					</Typography>
					<Box className={containerClass.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button onClick={onClose} className={classes.cancelButton}>
								Cancel
							</Button>
							<Button
								className={classes.saveButton}
								onClick={deleteProjectMilestone}
								type='submit'
							>
								Delete
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectMilestoneDelete;