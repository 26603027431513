import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';

const useStyles = makeStyles({
	detailsContainer: {
		width: '442px',
		height: 'fit-content',
		margin: '21px',
	},
	buttonAlignment: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '15px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#f05d5d 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#f05d5d',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	buttonContainer: {
		paddingBottom: '0',
	},
	name: {
		marginTop: '10px',
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		fontSize: '10px',
		color: '#292929',
	},
	include: {
		font: 'normal normal bold 12px/24px Manrope',
		fontSize: '12px',
	},
});

interface TaskModalProps {
	open: boolean;
	handleSubmit: (data: { cloneTask: boolean; cloneMilestone: boolean }) => void;
	onClose: () => void;
}

const ProjectCloneModal = (props: TaskModalProps) => {
	const { open, onClose, handleSubmit } = props;
	const formStyles = modalFormStyle();
	const warningModalStyles = useStyles();
	const [cloneTask, setCloneTask] = useState(true);
	const [cloneMilestone, setCloneMilestone] = useState(true);

	const handleClose = () => {
		setCloneMilestone(true);
		setCloneTask(true);
		onClose();
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={warningModalStyles.detailsContainer}>
					<Typography className={warningModalStyles.heading}>
						Include
					</Typography>
					<Box>
						<Box display={'flex'}>
							<Box>
								<Checkbox
									color='primary'
									checked={cloneTask}
									onChange={(event: any) =>
										setCloneTask(event?.target?.checked)
									}
								/>
							</Box>
							<Box marginTop={'9px'} className={warningModalStyles.label}>
								Tasks
							</Box>
						</Box>
						<Box display={'flex'}>
							<Box>
								<Checkbox
									color='primary'
									checked={cloneMilestone}
									onChange={(event: any) =>
										setCloneMilestone(event?.target?.checked)
									}
								/>
							</Box>
							<Box marginTop={'9px'} className={warningModalStyles.label}>
								Milestones
							</Box>
						</Box>
					</Box>
					<Box className={warningModalStyles.buttonAlignment}>
						<Box
							className={`${formStyles.buttonContainer} ${warningModalStyles.buttonContainer}`}
						>
							<Box>
								<Button
									onClick={handleClose}
									className={formStyles.cancelButton}
								>
									Cancel
								</Button>
							</Box>
							<Button
								onClick={() => {
									const cloneOptions = {
										cloneMilestone: cloneMilestone,
										cloneTask: cloneTask,
									};
									handleSubmit(cloneOptions);
									handleClose()
								}}
								className={formStyles.saveButton}
							>
								Clone
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ProjectCloneModal;
