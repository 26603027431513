import React, { ReactNode, useEffect, useState } from 'react';
import { ContractorCount } from '../../../Reports/talent/index';
import { ContractorJobLevel } from '../../../Reports/talent/ContractorJobLevel.component';
import { Box } from '@material-ui/core';
import { ContractorBillableRatio } from '../../../Reports/talent/ContractorBillableRatio.component';
import { ContractorByDepartment } from '../../../Reports/talent/ContractorByDepartment.component';
import { ContractorSkillWiseSplitUp } from '../../../Reports/talent/ContractorSkillWiseSplitUp.component';
import { ContractorExpiring } from '../../../Reports/talent/ContractorExpiring.component';
import { insightViewStyle } from '../../../Layout/styles';
import { getNextTenDaysRange } from '../../../Utils/date-time.util';
import {
	useGetConsultantChartDataQuery,
	useGetConsultantInsightCountQuery,
} from '../../../generated/graphql';

interface Skill {
	name: string;
	count: number | undefined;
}
interface jobLevel {
	level: string;
	count: number | undefined;
}
interface Department {
	name: string;
	count: number | undefined;
}
const CountCard = ({ label, value }: { label: string; value: ReactNode }) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.countContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
export const ConsultantInsights = () => {
	const classes = insightViewStyle();
	const dateRange = getNextTenDaysRange(new Date());
	const [skillReportData, setSkillReportData] = useState<Skill[]>([]);
	const [jobLevelReportData, setJobLevelReportData] = useState<jobLevel[]>([]);
	const [departmentWiseReport, setDepartmentWiseReport] = useState<
		Department[]
	>([]);

	const { data: insightCount, loading: isCountLoading } =
		useGetConsultantInsightCountQuery({
			variables: {
				startDate: dateRange?.fromDate,
				endDate: dateRange?.toDate,
			},
		});

	const { data: insightChartData, loading: isChartLoading } =
		useGetConsultantChartDataQuery();

	useEffect(() => {
		if (!insightChartData) {
			return;
		}
		const skillReportList = insightChartData?.employeeSkillMapping?.map(
			(skill) => {
				return {
					name: skill?.name,
					count: skill?.employee_skill_mappings_aggregate?.aggregate?.count,
				};
			}
		);
		setSkillReportData(skillReportList);

		const jobLevelList = insightChartData?.jobLevelDistribution?.map(
			(jobLevel) => {
				return {
					level: jobLevel?.level,
					count: jobLevel?.contractors_aggregate?.aggregate?.count,
				};
			}
		);
		setJobLevelReportData(jobLevelList);
		const departmentWiseReport = insightChartData?.contractorByDepartment?.map(
			(department) => {
				return {
					name: department?.name,
					count: department?.contractors_aggregate?.aggregate?.count,
				};
			}
		);
		setDepartmentWiseReport(departmentWiseReport);
	}, [insightChartData]);
	return (
		<>
			<Box className={classes.headCountContainer}>
				<CountCard
					label='Total Consultant'
					value={
						<ContractorCount
							count={insightCount?.totalContractor?.aggregate?.count || '--'}
							isLoading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Consultant expiring within 10 days'
					value={
						<ContractorExpiring
							count={insightCount?.expiringContractor?.aggregate?.count || '--'}
							isLoading={isCountLoading}
						/>
					}
				/>
			</Box>
			<Box className={classes.container}>
				<Box className={classes.chartContainer}>
					<ContractorSkillWiseSplitUp
						skillReport={skillReportData}
						isLoading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<ContractorJobLevel
						jobLevelReport={jobLevelReportData}
						isLoading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<ContractorBillableRatio
						billableCount={
							insightChartData?.billableContractor?.aggregate?.count || '--'
						}
						nonBillableCount={
							insightChartData?.nonBillableContractor?.aggregate?.count || '--'
						}
						isLoading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<ContractorByDepartment
						departmentDetails={departmentWiseReport}
						isLoading={isChartLoading}
					/>
				</Box>
			</Box>
		</>
	);
};

export default ConsultantInsights;
