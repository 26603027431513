import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import { useRefresh, useNotify, useQuery } from 'react-admin';
import {
	GET_EMPLOYEE_NOTE,
	UPSERT_EMPLOYEE_NOTE,
	DELETE_EMPLOYEE_NOTE,
} from '../DetailsPage/gqlQueries';
import { NoteForm } from '../../../SharedComponents/Notes/model';
import Notes from '../../../SharedComponents/Notes/Notes';
import { UserProfileContext } from '../../../App';

const EmployeeNote = () => {
	const { id }: { id: string } = useParams();
	const [noteId, setNoteId] = useState('');
	const [searchEmployeeNotes, setSearchEmployeeNotes] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();
	const { permissions: permissionList } = useContext<any>(UserProfileContext);
	const [upsertEmployeeNote,{loading:isEmployeeNoteLoading}] = useApolloMutation(UPSERT_EMPLOYEE_NOTE);
	const [deleteEmployeeNote] = useApolloMutation(DELETE_EMPLOYEE_NOTE);

	const { data: EmployeeNote, refetch: refetchEmployeeNotes } = useApolloQuery(
		GET_EMPLOYEE_NOTE,
		{
			variables: {
				employee_id: id ? id : null,
				SearchText: `%${searchEmployeeNotes}%`,
			},
			fetchPolicy:'network-only'
		}
	);

	const { data: employeeNoteList } = useQuery({
		type: 'GET_LIST',
		resource: 'employee_note',
		payload: {
			filter: {
				id: noteId ? noteId : null,
			},
		},
	});

	const upsertNote = (note: NoteForm) => {
		upsertEmployeeNote({
			variables: {
				employeeNote: {
					employee_id: id,
					...note,
				},
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Employee Notes Created successfully');
				refresh();
				refetchEmployeeNotes();
			}
		});
	};

	const deleteNote = (noteId: string) => {
		deleteEmployeeNote({
			variables: {
				noteId: noteId,
			},
		}).then((response: any) => {
			if (!response.error) {
				notify('Employee Notes Deleted successfully');
				refresh();
				refetchEmployeeNotes();
			}
		});
	};

	const saveForm = (note: NoteForm) => {
		upsertNote(note);
	};

	return (
		<>
			<Notes
				saveForm={saveForm}
				deleteNote={deleteNote}
				searchNotes={setSearchEmployeeNotes}
				noteList={EmployeeNote?.employee_note}
				refetch={() => refetchEmployeeNotes()}
				noteId={setNoteId}
				getNoteList={employeeNoteList}
				permission={permissionList?.employee?.update_permissions ? true: false}
				deletePermission={permissionList?.employee_note?.update_permissions?.deleted_at ? true : false}
				loading={isEmployeeNoteLoading}
			/>
		</>
	);
};

export default EmployeeNote;
