import React, { useState } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	useNotify,
	useRefresh,
	useMutation,
	regex,
	email,
	maxLength,
} from 'react-admin';
import { Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	DialogContent,
	DialogActions,
} from '../../../Layout/styles';
import { validateWebsite } from '../../../Utils/string.util';
import { useParams } from 'react-router-dom';
import { validatePhoneNumber } from '../../../Utils/string.util';
import { contactChoices } from '../Constant';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../../generated/graphql';

interface Form {
	first_name?: string;
	last_name?: string;
	title?: string;
	email?: string;
	company_id?: string;
	primary_phone?: string;
	alternate_phone?: string;
	contact_type?: string;
	owner_id?: string;
	linkedin?: string;
	address?: string;
	background?: string;
}

interface Props {
	onClose: () => void;
	isOpen: boolean;
}

const CompanyAddContactForm = (props: Props) => {
	const { onClose, isOpen } = props;
	const { id }: { id: string } = useParams();
	const notify = useNotify();
	const refresh = useRefresh();
	const [contactForm, setContactForm] = useState<Form>({});
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const classes = modalFormStyle();
	const [mutate] = useMutation();

	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const handleClose = () => {
		onClose();
		setContactForm({});
	};

	const onSave = () => {
		mutate(
			{
				type: 'create',
				resource: 'crm_contacts',
				payload: {
					data: {
						first_name: contactForm?.first_name,
						last_name: contactForm?.last_name,
						title: contactForm?.title,
						email: contactForm?.email,
						company_id: id,
						primary_phone: contactForm?.primary_phone,
						alternate_phone: contactForm?.alternate_phone
							? contactForm?.alternate_phone
							: null,
						contact_type: contactForm?.contact_type,
						owner_id: ownerId || null,
						linkedin: contactForm?.linkedin,
						address: contactForm?.address,
						background: contactForm?.background,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					props.onClose();
					notify('Contact Created Successfully');
					refresh();
					setContactForm({});
				},
				onFailure: (error) => {
					props.onClose();
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
		return;
	};

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={isOpen} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>Add Contact </Box>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form initialValues={contactForm} onSubmit={onSave}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Box className={classes.label}>First Name *</Box>
										<TextInput
											source='first_name'
											fullWidth={true}
											label={false}
											validate={required()}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													first_name: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}>Last Name</Box>
										<TextInput
											source='last_name'
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													last_name: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}>Title </Box>
										<TextInput
											source='title'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													title: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}>Email</Box>
										<TextInput
											source='email'
											sortable={false}
											validate={[email()]}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													email: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}>Address</Box>
										<TextInput
											source='address'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													address: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}> Phone 1</Box>
										<TextInput
											source='primary_phone'
											sortable={false}
											label={false}
											fullWidth={true}
											validate={[
												regex(validatePhoneNumber, 'Must be number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													primary_phone: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}> Phone 2</Box>
										<TextInput
											source='alternate_phone'
											name='alternate_phone'
											validate={[
												regex(validatePhoneNumber, 'Must be number'),
												maxLength(15, 'Maximum 15 digits allowed'),
											]}
											label={false}
											fullWidth
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													alternate_phone: e.target.value,
												});
											}}
										/>
										<Box className={classes.label}>Background</Box>
										<TextInput
											source='background'
											sortable={false}
											label={false}
											fullWidth={true}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													background: e?.target?.value,
												});
											}}
										/>
										<Box className={classes.label}>Contact Type</Box>
										<Box flex={1}>
											<SelectInput
												source='contact_type'
												resettable={true}
												label={false}
												fullWidth
												choices={contactChoices}
												onChange={(e: any) => {
													setContactForm({
														...contactForm,
														contact_type: e.target.value,
													});
												}}
											/>
										</Box>
										<Box className={classes.label}>BD OWNER </Box>
										<AutocompleteSearch
											placeholder={'Search Owner'}
											option={
												activeUsers && activeUsers?.user
													? activeUsers?.user?.map((value) => {
															return {
																id: value?.id,
																name: value?.name,
															};
													  })
													: []
											}
											onChange={setOwnerId}
											value={getOwnerValue(ownerId || '')}
											name={'reportee_id'}
											validates={false}
										/>
										<Box className={classes.label}>Linkedin</Box>
										<TextInput
											source='linkedin'
											fullWidth={true}
											label={false}
											validate={[regex(validateWebsite, 'Must be a valid URL')]}
											onChange={(e: any) => {
												setContactForm({
													...contactForm,
													linkedin: e?.target?.value,
												});
											}}
										/>
									</DialogContent>
									<DialogActions>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={onClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid ? classes.disabledButton : classes.saveButton
												}
												disabled={invalid}
												type='submit'
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default CompanyAddContactForm;
