import React from "react";
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';
import { Filter, TextInput } from 'react-admin';

const DepartmentFilter = props => (
    <Filter {...props}>
        <TextInput
            label="Search by department"
            source="name"
            alwaysOn
        />
    </Filter>
);

export const DepartmentList = props => {  
    return(
    <List sort={{ field: "name", order: "ASC" }} bulkActionButtons={false} {...props} filters={<DepartmentFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="description" />
        </Datagrid>
    </List>
    )
};