import React from 'react';

import {
	Box,
	Dialog,
	Typography,
	Card,
	CardContent,
	makeStyles,
	Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ellipsisStyle, modalFormStyle } from '../../Layout/styles';
import dayjs from 'dayjs';

const useStyles = makeStyles({
	cardRoot: {
		minWidth: 200,
		marginRight: 10,
		marginTop: 10,
		boxShadow: '0px 3px 6px #0000000F',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1em',
	},
	contentContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	contentDetails: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '120px',
	},
	fieldValue: {
		color: '#7C7C7C',
		fontSize: '12px',
		fontFamily: 'Manrope-bold',
		width: '100px',
	},
	label: {
		color: '#292929',
		fontSize: '10px !important',
		letterSpacing: '0px',
		fontFamily: 'Manrope-bold',
		marginBottom: '5px',
	},
	link: {
		color: '#4285F4',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	container: {
		width: '100%',
	},
	divider: {
		margin: '12px 0px',
	},
	modalOuterContainer: {
		width: '100%',
		padding: '20px',
		background: '#FFFFFF',
		minHeight: '350px',
		borderRadius: '4px',
	},
});

interface Resource {
	id?: string;
	name?: string;
	cost?: number;
	isBillable?: boolean;
	skills?: string[];
	type?: string;
	jobTitle?: string;
}

interface ProjectAllocations {
	allocation_start_date?: string;
	allocation_end_date?: string;
	projectStartDate?: string;
	projectEndDate?: string;
	allocationPercentage?: number;
	projectName?: string;
	projectId?: string;
	isBillableProject?: boolean;
}

interface ResourceAvailableDetailsModalProps {
	resource: Resource;
	projectAllocations: ProjectAllocations[];
	open: boolean;
	onClose: () => void;
}

const ResourceAvailableDetailsModal = (
	props: ResourceAvailableDetailsModalProps
) => {
	const { resource, projectAllocations, open, onClose } = props;
	const formStyles = modalFormStyle();
	const resourceAvailableDetailsModalStyles = useStyles();
	const ellipsisStyles = ellipsisStyle();

	return (
		<Dialog open={open} onClose={onClose} maxWidth={false}>
			<Box className={resourceAvailableDetailsModalStyles.modalOuterContainer}>
				<Box className={formStyles.headerContainer}>
					<Typography className={formStyles.heading}>
						Resource Details
					</Typography>
					<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
				</Box>
				<Card
					className={resourceAvailableDetailsModalStyles.cardRoot}
					variant='outlined'
				>
					<CardContent>
						<Box className={resourceAvailableDetailsModalStyles.container}>
							<Box
								className={resourceAvailableDetailsModalStyles.contentContainer}
							>
								<Box
									className={resourceAvailableDetailsModalStyles.contentDetails}
								>
									<Typography
										className={resourceAvailableDetailsModalStyles.label}
									>
										{resource?.type === 'employee' ? 'Employee' : 'Contractor'}
									</Typography>
									<Tooltip title={`${resource?.name}`}>
										<Typography
											className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
										>
											{resource?.name}
										</Typography>
									</Tooltip>
								</Box>

								<Box
									className={resourceAvailableDetailsModalStyles.contentDetails}
								>
									<Typography
										className={resourceAvailableDetailsModalStyles.label}
									>
										Cost
									</Typography>
									<Typography
										className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
									>
										{`$${resource?.cost || '--'}`}
									</Typography>
								</Box>
								<Box
									className={resourceAvailableDetailsModalStyles.contentDetails}
								>
									<Typography
										className={resourceAvailableDetailsModalStyles.label}
									>
										Billable
									</Typography>
									{resource?.isBillable !== null && (
										<Typography
											className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
										>
											{resource?.isBillable === true ? 'Yes' : 'No'}
										</Typography>
									)}
								</Box>
								<Box
									className={resourceAvailableDetailsModalStyles.contentDetails}
								>
									<Typography
										className={resourceAvailableDetailsModalStyles.label}
									>
										Job Title
									</Typography>
									<Tooltip title={`${resource?.jobTitle || '--'}`}>
										<Typography
											className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
										>
											{resource?.jobTitle || '--'}
										</Typography>
									</Tooltip>
								</Box>
								<Box
									className={resourceAvailableDetailsModalStyles.contentDetails}
								>
									<Typography
										className={resourceAvailableDetailsModalStyles.label}
									>
										Skills
									</Typography>
									{resource?.skills && resource?.skills.length > 0 ? (
										resource?.skills.map((skill, index) => (
											<Tooltip title={`${skill}`}>
												<Box
													className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
													key={index}
													mb={1}
												>
													{skill}
												</Box>
											</Tooltip>
										))
									) : (
										<Typography
											className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
										>
											No skills found...
										</Typography>
									)}
								</Box>
							</Box>
						</Box>
					</CardContent>
				</Card>
				<Box mt={2}>
					<Typography className={formStyles.heading}>
						Allocation Details
					</Typography>
				</Box>

				<Box className={resourceAvailableDetailsModalStyles.container}>
					{projectAllocations?.length > 0 ? (
						projectAllocations?.map((project, index) => (
							<>
								<Card
									className={resourceAvailableDetailsModalStyles.cardRoot}
									variant='outlined'
									key={index}
								>
									<CardContent>
										<Box
											className={
												resourceAvailableDetailsModalStyles.contentContainer
											}
										>
											<Box
												className={
													resourceAvailableDetailsModalStyles.contentDetails
												}
											>
												<Typography
													className={resourceAvailableDetailsModalStyles.label}
												>
													Project
												</Typography>
												<Tooltip
													title={
														project?.isBillableProject
															? `${project?.projectName}`
															: `${project?.projectName}(NB)`
													}
												>
													<Typography
														className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
													>
														{project?.isBillableProject
															? `${project?.projectName}`
															: `${project?.projectName}(NB)`}
													</Typography>
												</Tooltip>
											</Box>
											<Box
												className={
													resourceAvailableDetailsModalStyles.contentDetails
												}
											>
												<Typography
													className={resourceAvailableDetailsModalStyles.label}
												>
													Start Date
												</Typography>
												<Typography
													className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{project?.allocation_start_date
														? dayjs(project.allocation_start_date).format(
																'DD-MMM-YYYY'
														  )
														: '- -'}
												</Typography>
											</Box>

											<Box
												className={
													resourceAvailableDetailsModalStyles.contentDetails
												}
											>
												<Typography
													className={resourceAvailableDetailsModalStyles.label}
												>
													End Date
												</Typography>
												<Typography
													className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{project?.allocation_end_date
														? dayjs(project.allocation_end_date).format(
																'DD-MMM-YYYY'
														  )
														: '--'}
												</Typography>
											</Box>
											<Box
												className={
													resourceAvailableDetailsModalStyles.contentDetails
												}
											>
												<Typography
													className={resourceAvailableDetailsModalStyles.label}
												>
													Allocation(%)
												</Typography>
												<Typography
													className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
												>
													{`${project?.allocationPercentage}%` || '--'}
												</Typography>
											</Box>
										</Box>
									</CardContent>
								</Card>
							</>
						))
					) : (
						<Card
							className={resourceAvailableDetailsModalStyles.cardRoot}
							variant='outlined'
						>
							<CardContent>
								<Box
									className={
										resourceAvailableDetailsModalStyles.contentContainer
									}
								>
									<Box
										className={
											resourceAvailableDetailsModalStyles.contentDetails
										}
									>
										<Typography
											className={`${resourceAvailableDetailsModalStyles.fieldValue} ${ellipsisStyles.ellipsis}`}
										>
											No Projects..
										</Typography>
									</Box>
								</Box>
							</CardContent>
						</Card>
					)}
				</Box>
			</Box>
		</Dialog>
	);
};

export default ResourceAvailableDetailsModal;
