import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
	label: {
		textAlign: 'left',
		font: 'normal normal bold 10px/25px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '10px !important',
		textTransform: 'capitalize',
		marginRight: '5px',
		marginLeft: '5px',
	},
	value: {
		textAlign: 'left',
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: ' #5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
		marginRight: '5px',
		marginLeft: '5px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		maxWidth: '150px',
	},
}));

interface FieldRendererProps {
	label: string;
	value: string;
	tooltip: string;
}

function FieldRenderer(props: FieldRendererProps) {
	const { label, value, tooltip } = props;
	const classes = useStyles();

	return (
		<>
			<Tooltip title={label || '- -'} placement='right'>
				<Typography className={`${classes.label} ${classes.ellipsis}`}>
					{label}
				</Typography>
			</Tooltip>
			<Tooltip title={tooltip || '- -'} placement='right'>
				<Typography className={`${classes.value} ${classes.ellipsis}`}>
					{value || '- -'}
				</Typography>
			</Tooltip>
		</>
	);
}

export default FieldRenderer;
