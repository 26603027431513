import { Reducer } from 'react';
import { FilterState } from './modal';

type Action = {
	type: string;
	payload: any;
};

// ACTIONS
export const FILTER_STATE_ACTIONS = {
	// Project list page filters
	UPDATE_PROJECT_LIST_SEARCH_TEXT: 'update_project_list_search_text',
	UPDATE_PROJECT_RESOURCE_FILTER: 'update_project_resource_filter',
	UPDATE_PROJECT_STATUS_FILTER: 'update_project_status_filter',
	UPDATE_PROJECT_EXPIRY_FILTER: 'update_project_expiry_filter',
	UPDATE_PROJECT_SORT_FILTER: 'update_project_sort_filter',
	UPDATE_PROJECT_LABEL_FILTER: 'update_project_label_filter',
	UPDATE_PROJECT_CATEGORY_FILTER: 'update_project_category_filter',
	UPDATE_PROJECT_USER_TEAM_FILTER: 'update_project_user_team_filter',
	// Project planning page filters
	UPDATE_PROJECT_PLANNING_SEARCH_TEXT: 'update_project_planning_search_text',
	UPDATE_PROJECT_PLANNING_STATUS_FILTER:
		'update_project_planning_status_filter',
	UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER:
		'update_project_planning_user_team_filter',
	UPDATE_PROJECT_PLANNING_RESOURCE_FILTER:
		'update_project_planning_resource_filter',
	// Asset filters
	UPDATE_ASSET_SEARCH_TEXT: 'update_asset_search_text',
	UPDATE_ASSET_STATUS_FILTER: 'update_asset_status_filter',
	UPDATE_ASSET_LOCATION_FILTER: 'update_asset_location_filter',
	UPDATE_ASSET_USED_BY_FILTER: 'update_asset_used_by_filter',
	UPDATE_ASSET_VIEW_FILTER: 'update_asset_view_filter',
	// Employee filters
	UPDATE_EMPLOYEE_SEARCH_TEXT: 'update_employee_search_text',
	UPDATE_EMPLOYEE_STATUS_FILTER: 'update_employee_status_filter',
	UPDATE_EMPLOYEE_LOCATION_FILTER: 'update_employee_location_filter',
	UPDATE_EMPLOYEE_LOCATION_ID_FILTER: 'update_employee_location_id_filter',
	UPDATE_EMPLOYEE_VIEW_FILTER: 'update_employee_view_filter',
	//Resource filters
	UPDATE_RESOURCE_SEARCH_TEXT: 'update_resource_search_text',
	UPDATE_RESOURCE_START_DATE: 'update_resource_start_date',
	UPDATE_RESOURCE_END_DATE: 'update_resource_end_date',
	UPDATE_RESOURCE_BILLABLE_OPTION_FILTER:
		'update_resource_billable_option_filter',
	UPDATE_RESOURCE_BILLABLE_QUERY_FILTER:
		'update_resource_billable_query_filter',
	UPDATE_RESOURCE_ALLOCATION_FILTER: 'update_resource_allocation_filter',
	UPDATE_RESOURCE_USER_TYPE_FILTER: 'update_resource_user_type_filter',
	UPDATE_RESOURCE_USER_TEAM_FILTER: 'update_resource_user_team_filter',
	UPDATE_RESOURCE_USER_TEAM_ID: 'update_resource_user_team_id',
	UPDATE_RESOURCE_RESOURCE_OPTION_FILTER:
		'update_resource_resource_option_filter',
	UPDATE_RESOURCE_RESOURCE_ID_LIST: 'update_resource_resource_id_list',
	UPDATE_RESOURCE_RESOURCE_OPTION_LIST: 'update_resource_resource_option_list',
	UPDATE_RESOURCE_LOCATION_OPTION_FILTER:
		'update_resource_location_option_filter',
	UPDATE_RESOURCE_LOCATION: 'update_resource_location',

	// Consultant filters
	UPDATE_CONSULTANT_SEARCH_TEXT: 'update_consultant_search_text',
	UPDATE_CONSULTANT_FILTER_CONTRACT: 'update_consultant_filter_contract',
	UPDATE_CONSULTANT_STATUS: 'update_consultant_status',
	UPDATE_CONSULTANT_LOCATION: 'update_consultant_location',
	UPDATE_CONSULTANT_LOCATION_ID_LIST: 'update_consultant_location_id_list',
	UPDATE_CONSULTANT_VIEW_FILTER: 'update_consultant_view_filter',

	//Contact filters
	UPDATE_CONTACT_SEARCH_TEXT: 'update_contact_search_text',
	//Company filters
	UPDATE_COMPANY_SEARCH_TEXT: 'update_company_search_text',
	UPDATE_COMPANY_VIEW_FILTER: 'update_company_view_filter',
	UPDATE_COMPANY_RESOURCE_SORT: 'update_company_resource_sort',
	//Employee skill filters
	UPDATE_EMPLOYEE_SKILL_SEARCH_TEXT: 'update_employee_skill_search_text',
	//Crm task filters
	UPDATE_CRM_TASK_SEARCH_TEXT: 'update_crm_task_search_text',
	UPDATE_CRM_TASK_ASSIGNED_FILTER: 'update_crm_task_assigned_filter',
};

// REDUCER FUNCTION
export const filterReducer: Reducer<any, Action> = (
	state: FilterState,
	action: any
) => {
	switch (action.type) {
		// Project list page filters
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_LIST_SEARCH_TEXT: {
			return {
				...state,
				projectSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_RESOURCE_FILTER: {
			return {
				...state,
				projectResourceFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_STATUS_FILTER: {
			return {
				...state,
				projectStatusFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_EXPIRY_FILTER: {
			return {
				...state,
				projectExpiryFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_SORT_FILTER: {
			return {
				...state,
				projectSortFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_LABEL_FILTER: {
			return {
				...state,
				projectLabelFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_CATEGORY_FILTER: {
			return {
				...state,
				projectCategoryFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_USER_TEAM_FILTER: {
			return {
				...state,
				projectUserTeamFilter: action.payload,
			};
		}

		// Project planning page filters
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_SEARCH_TEXT: {
			return {
				...state,
				projectPlanningSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_STATUS_FILTER: {
			return {
				...state,
				projectPlanningStatusFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER: {
			return {
				...state,
				projectPlanningUserTeamFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER: {
			return {
				...state,
				projectPlanningResourceFilter: action.payload,
			};
		}

		// Asset page filters
		case FILTER_STATE_ACTIONS.UPDATE_ASSET_SEARCH_TEXT: {
			return {
				...state,
				assetSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_ASSET_STATUS_FILTER: {
			return {
				...state,
				assetStatusFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_ASSET_LOCATION_FILTER: {
			return {
				...state,
				assetLocationFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_ASSET_USED_BY_FILTER: {
			return {
				...state,
				assetUsedByFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_ASSET_VIEW_FILTER: {
			return {
				...state,
				isAssetListView: action.payload,
			};
		}
		// Employee
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_SEARCH_TEXT: {
			return {
				...state,
				employeeSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_STATUS_FILTER: {
			return {
				...state,
				employeeStatusFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER: {
			return {
				...state,
				employeeLocationFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER: {
			return {
				...state,
				employeeLocationIdFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_VIEW_FILTER: {
			return {
				...state,
				isEmployeeListView: action.payload,
			};
		}

		// resource
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_SEARCH_TEXT: {
			return {
				...state,
				resourceSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_START_DATE: {
			return {
				...state,
				resourceStartDate: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_END_DATE: {
			return {
				...state,
				resourceEndDate: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_BILLABLE_OPTION_FILTER: {
			return {
				...state,
				resourceBillableOptionFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_BILLABLE_QUERY_FILTER: {
			return {
				...state,
				resourceBillableQueryFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_ALLOCATION_FILTER: {
			return {
				...state,
				resourceAllocationFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TYPE_FILTER: {
			return {
				...state,
				resourceUserTypeFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_FILTER: {
			return {
				...state,
				resourceUserTeamFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_USER_TEAM_ID: {
			return {
				...state,
				resourceUserTeamId: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_FILTER: {
			return {
				...state,
				resourceResourceOptionFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_ID_LIST: {
			return {
				...state,
				resourceResourceIdList: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_RESOURCE_OPTION_LIST: {
			return {
				...state,
				resourceResourceOptionList: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION_OPTION_FILTER: {
			return {
				...state,
				resourceLocationOptionFilter: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_RESOURCE_LOCATION: {
			return {
				...state,
				resourceLocation: action.payload,
			};
		}
		//Consultant
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_SEARCH_TEXT: {
			return {
				...state,
				consultantSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_FILTER_CONTRACT: {
			return {
				...state,
				consultantFilterContract: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_STATUS: {
			return {
				...state,
				consultantStatusId: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION: {
			return {
				...state,
				consultantLocation: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_LOCATION_ID_LIST: {
			return {
				...state,
				consultantLocationIdList: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CONSULTANT_VIEW_FILTER: {
			return {
				...state,
				isConsultantListView: action.payload,
			};
		}
		//Company
		case FILTER_STATE_ACTIONS.UPDATE_COMPANY_SEARCH_TEXT: {
			return {
				...state,
				companySearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_COMPANY_VIEW_FILTER: {
			return {
				...state,
				isCompanyListView: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_COMPANY_RESOURCE_SORT: {
			return {
				...state,
				companyResourceSort: action.payload,
			};
		}

		//Contact
		case FILTER_STATE_ACTIONS.UPDATE_CONTACT_SEARCH_TEXT: {
			return {
				...state,
				contactSearchText: action.payload,
			};
		}
		//Employee Skill
		case FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_SKILL_SEARCH_TEXT: {
			return {
				...state,
				employeeSkillSearchText: action.payload,
			};
		}

		//Crm task
		case FILTER_STATE_ACTIONS.UPDATE_CRM_TASK_SEARCH_TEXT: {
			return {
				...state,
				crmTaskSearchText: action.payload,
			};
		}
		case FILTER_STATE_ACTIONS.UPDATE_CRM_TASK_ASSIGNED_FILTER: {
			return {
				...state,
				crmTaskAssignedFilter: action.payload,
			};
		}

		default:
			return state;
	}
};

// PAGINATION ACTIONS
export const PAGINATION_STATE_ACTIONS = {
	// Employee Pagination
	UPDATE_EMPLOYEE_PAGE_PAGINATION: 'update_employee_page_pagination',
	UPDATE_EMPLOYEE_PER_PAGE_PAGINATION: 'update_employee_per_page_pagination',

	// Resource Pagination
	UPDATE_RESOURCE_PAGE_PAGINATION: 'update_resource_page_pagination',
	UPDATE_RESOURCE_PER_PAGE_PAGINATION: 'update_resource_per_page_pagination',

	// Company Pagination
	UPDATE_COMPANY_PAGE_PAGINATION: 'update_company_page_pagination',
	UPDATE_COMPANY_PER_PAGE_PAGINATION: 'update_company_per_page_pagination',

	// Project Pagination
	UPDATE_PROJECT_PAGE_PAGINATION: 'update_project_page_pagination',
	UPDATE_PROJECT_PER_PAGE_PAGINATION: 'update_project_per_page_pagination',
	// Project Planning Pagination
	UPDATE_PROJECT_PLANNING_PAGE_PAGINATION:
		'update_project_planning_page_pagination',
	UPDATE_PROJECT_PLANNING_PER_PAGE_PAGINATION:
		'update_project_planning_per_page_pagination',
	// Consultant Pagination
	UPDATE_CONSULTANT_PAGE_PAGINATION: 'update_consultant_page_pagination',
	UPDATE_CONSULTANT_PER_PAGE_PAGINATION:
		'update_consultant_per_page_pagination',
	// Contact Pagination
	UPDATE_CONTACT_PAGE_PAGINATION: 'update_contact_page_pagination',
	UPDATE_CONTACT_PER_PAGE_PAGINATION: 'update_contact_per_page_pagination',
	// Employee Skill Pagination
	UPDATE_EMPLOYEE_SKILL_PAGE_PAGINATION:
		'update_employee_skill_page_pagination',
	UPDATE_EMPLOYEE_SKILL_PER_PAGE_PAGINATION:
		'update_employee_skill_per_page_pagination',
	// Asset Pagination
	UPDATE_ASSET_PAGE_PAGINATION: 'update_asset_page_pagination',
	UPDATE_ASSET_PER_PAGE_PAGINATION: 'update_asset_per_page_pagination',
	// Crm Task Pagination
	UPDATE_CRM_PAGE_PAGINATION: 'update_crm_page_pagination',
	UPDATE_CRM_PER_PAGE_PAGINATION: 'update_crm_per_page_pagination',
};

// REDUCER FUNCTION
export const paginationReducer: Reducer<any, Action> = (
	state: FilterState,
	action: any
) => {
	switch (action.type) {
		// Employeet list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_EMPLOYEE_PAGE_PAGINATION: {
			return {
				...state,
				employeePagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_EMPLOYEE_PER_PAGE_PAGINATION: {
			return {
				...state,
				employeePerPagePagination: action.payload,
			};
		}
		// Project list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PAGE_PAGINATION: {
			return {
				...state,
				projectPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PER_PAGE_PAGINATION: {
			return {
				...state,
				projectPerPagePagination: action.payload,
			};
		}

		// Project planning page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_PAGE_PAGINATION: {
			return {
				...state,
				projectPlanningPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_PER_PAGE_PAGINATION: {
			return {
				...state,
				projectPlanningPerPagePagination: action.payload,
			};
		}

		// Consultant list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_CONSULTANT_PAGE_PAGINATION: {
			return {
				...state,
				consultantPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_CONSULTANT_PER_PAGE_PAGINATION: {
			return {
				...state,
				consultantPerPagePagination: action.payload,
			};
		}

		// Resource list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_RESOURCE_PAGE_PAGINATION: {
			return {
				...state,
				resourcePagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_RESOURCE_PER_PAGE_PAGINATION: {
			return {
				...state,
				resourcePerPagePagination: action.payload,
			};
		}
		// Company list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_COMPANY_PAGE_PAGINATION: {
			return {
				...state,
				companyPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_COMPANY_PER_PAGE_PAGINATION: {
			return {
				...state,
				companyPerPagePagination: action.payload,
			};
		}

		// Contact list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_CONTACT_PAGE_PAGINATION: {
			return {
				...state,
				contactPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_CONTACT_PER_PAGE_PAGINATION: {
			return {
				...state,
				contactPerPagePagination: action.payload,
			};
		}
		// Employee skill list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_EMPLOYEE_SKILL_PAGE_PAGINATION: {
			return {
				...state,
				employeeSkillPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_EMPLOYEE_SKILL_PER_PAGE_PAGINATION: {
			return {
				...state,
				employeeSkillPerPagePagination: action.payload,
			};
		}
		// Asset list page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_ASSET_PAGE_PAGINATION: {
			return {
				...state,
				assetPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_ASSET_PER_PAGE_PAGINATION: {
			return {
				...state,
				assetPerPagePagination: action.payload,
			};
		}
		// Crm page pagination
		case PAGINATION_STATE_ACTIONS.UPDATE_CRM_PAGE_PAGINATION: {
			return {
				...state,
				crmPagePagination: action.payload,
			};
		}

		case PAGINATION_STATE_ACTIONS.UPDATE_CRM_PER_PAGE_PAGINATION: {
			return {
				...state,
				crmPerPagePagination: action.payload,
			};
		}

		default:
			return state;
	}
};
