import React, { useContext, useState } from 'react';
import {
	Box,
	Button,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	List,
	ReferenceField,
	Datagrid,
	FunctionField,
	useNotify,
	useRefresh,
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import StarIcon from '@material-ui/icons/Star';
import AddLocationForm from './AddLocationForm.component';
import {
	modalFormStyle,
	ellipsisStyle,
	datagridStyle,
} from '../../../Layout/styles';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import {
	useDeleteOrgLocationMutation,
	useGetOrgLocationUsersQuery,
	useGetOrgLocationUserCountQuery,
} from '../../../generated/graphql';
import { isEmpty } from 'lodash';
import EmployeeListModal from '../../Settings/LeaveManagement/EmployeeListModal.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import { UserProfileContext } from '../../../App';
import { FILTER_STATE_ACTIONS } from '../../../globalState';

const useStyles = makeStyles({
	ellipsisContent: {
		width: '70px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	container: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginBottom: '10px',
		justifyContent: 'space-between',
		marginTop: '20px',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
			'& > div': {
				'& > div': {
					'& > button': {
						display: 'block',
					},
				},
			},
		},
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	peopleIconContainer: {
		width: '70px',
		border: '1px solid #E0E0E0',
		cursor: 'pointer',
		height: '32px',
		padding: '0px 10px',
		alignItems: 'center',
		borderRadius: '4px',
		justifyContent: 'space-between',
		display: 'flex',
	},
	nonDefaultColor: {
		color: '#C7C7C7',
		width: '20px',
		height: '20px',
	},
	defaultColor: {
		color: '#EA9926',
		width: '20px',
		height: '20px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: '100px',
	},
});

const Location = () => {
	const {
		filterState,
		dispatchFilter,
	} = useContext<any>(UserProfileContext);
	const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [locationDeletedId, setLocationDeletedId] = useState(null);
	const [locationData, setLocationData] = useState({});
	const [searchedEmployee, setSearchedEmployee] = useState('');
	const [isEmployeesListModalShown, setIsEmployeesListModalShown] =
		useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [orgLocationId, setOrgLocationId] = useState(null);
	const classes = modalFormStyle();
	const inlineStyle = useStyles();
	const ellipsisStyles = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const [deleteOrgLocation] = useDeleteOrgLocationMutation();
	const { data: locationUser } = useGetOrgLocationUsersQuery({
		variables: {
			locationId: orgLocationId,
			name: `%${searchedEmployee}%`,
		},
		fetchPolicy: 'network-only',
		skip: isEmpty(orgLocationId),
	});

	const { data: userCount } = useGetOrgLocationUserCountQuery({
		fetchPolicy: 'network-only',
	});

	const handleDelete = () => {
		deleteOrgLocation({
			variables: {
				orgLocationId: locationDeletedId,
			},
		}).then((data) => {
			notify('Location Deleted successfully');
			userefresh();
			setIsLocationModalOpen(false);
			setIsDeleteModalShown(false);
			if(locationDeletedId===filterState?.employeeLocationFilter?.[0]?.id){
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_ID_FILTER,
					payload: [],
				});

				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_EMPLOYEE_LOCATION_FILTER,
					payload: [],
				});
			}
		});
	};

	const ToolBar = () => (
		<Button
			variant='contained'
			className={classes.saveButton}
			onClick={() => setIsLocationModalOpen(true)}
		>
			Add Location
		</Button>
	);

	const getEmployeeCount = (locationId: string) => {
		if (!locationId || isEmpty(userCount?.org_location)) {
			return;
		}
		const response = userCount?.org_location?.find(
			(location: any) => location.id === locationId
		);
		const employeesCount = response?.employees_aggregate.aggregate?.count || 0;
		const contractorsCount =
			response?.contractors_aggregate.aggregate?.count || 0;

		return {
			userCount: employeesCount + contractorsCount,
			assetCount: response?.assets_aggregate?.aggregate?.count || 0,
			holidayCount: response?.org_holidays_aggregate?.aggregate?.count || 0,
		};
	};

	return (
		<>
			<Box>
				<List
					empty={false}
					resource='org_location'
					basePath='/org_location'
					bulkActionButtons={false}
					actions={<ToolBar />}
					title={' '}
					sort={{ field: 'name', order: 'ASC' }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							source='is_default'
							label=''
							render={(record: any) => (
								<StarIcon
									className={
										record?.is_default
											? inlineStyle?.defaultColor
											: inlineStyle?.nonDefaultColor
									}
								/>
							)}
						/>
						<FunctionField
							label='NAME'
							render={(record: any) => {
								return (
									<Tooltip title={`${record?.name || '--'}`} placement='right'>
										<Typography
											className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
										>
											{record.name}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='Address1'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.address?.address_line_1 || '--'}`}
										placement='right'
									>
										<Typography
											className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
										>
											{record?.address?.address_line_1 || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='Address2'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.address?.address_line_2 || '--'}`}
										placement='right'
									>
										<Typography
											className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
										>
											{record?.address?.address_line_2 || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='Zipcode'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.address?.postal_code || '--'}`}
										placement='right'
									>
										<Typography
											className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
										>
											{record?.address?.postal_code || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='Timezone'
							render={(record: any) => {
								return (
									<Tooltip
										title={`${record?.timezone || '--'}`}
										placement='right'
									>
										<Typography
											className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
										>
											{record?.timezone || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='Country'
							sortBy='name'
							sortByOrder='ASC'
							render={(record: any) =>
								record?.country_id ? (
									<>
										<ReferenceField
											source='country_id'
											label='LEAD OWNER'
											reference='geo_list'
											link={false}
										>
											<FunctionField
												render={(record: any) => {
													return (
														<Tooltip
															title={`${record?.name || '--'} `}
															placement='right'
														>
															<Typography
																className={`${inlineStyle.rowContent} ${ellipsisStyles.ellipsis}`}
															>
																{`${record?.name || ''} `}
															</Typography>
														</Tooltip>
													);
												}}
											/>
										</ReferenceField>
									</>
								) : (
									'--'
								)
							}
						/>
						<FunctionField
							label='User'
							render={(record: any) => {
								return (
									<Box
										className={inlineStyle.peopleIconContainer}
										onClick={(e) => {
											e.stopPropagation();
											setOrgLocationId(record?.id);
											setIsEmployeesListModalShown(true);
										}}
									>
										<PeopleOutlineIcon />
										<Box>{getEmployeeCount(record?.id)?.userCount || 0}</Box>
									</Box>
								);
							}}
						/>
						<FunctionField
							label=''
							render={(record: any) => {
								return (
									<Box display='flex' alignItems='center'>
										<Box
											className={datagridStyles.listEditIconContainer}
											mr={1}
										>
											<IconButton
												className={datagridStyles.listEditIconButton}
												onClick={() => {
													setLocationData(record);
													setIsLocationModalOpen(true);
												}}
											>
												<EditIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
										<Box className={datagridStyles.listEditIconContainer}>
											<IconButton
												className={`${datagridStyles.listEditIconButton} ${inlineStyle.deleteIconButton}`}
												onClick={() => {
													const employeesAssetCount = getEmployeeCount(
														record.id
													);
													if (
														employeesAssetCount &&
														(employeesAssetCount?.userCount > 0 ||
															employeesAssetCount?.assetCount > 0 ||
															employeesAssetCount?.holidayCount > 0)
													) {
														setIsWarningModalShown(true);
														return;
													}
													setLocationDeletedId(record?.id);
													setIsDeleteModalShown(true);
												}}
											>
												<DeleteIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
									</Box>
								);
							}}
						/>
					</Datagrid>
				</List>
			</Box>
			<AddLocationForm
				open={isLocationModalOpen}
				onClose={() => {
					setIsLocationModalOpen(false);
					setLocationData({});
				}}
				locationData={locationData}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setIsDeleteModalShown(false);
				}}
				confirmationMessage={'Do you really want to delete this Location?'}
				onDelete={() => {
					handleDelete();
				}}
			/>
			<EmployeeListModal
				open={isEmployeesListModalShown}
				onClose={() => {
					setIsEmployeesListModalShown(false);
					setOrgLocationId(null);
				}}
				employees={
					locationUser && locationUser?.user?.length > 0
						? locationUser.user.map((user: any) => ({
								name: `${user?.full_name} `,
								email: `${user?.email || ''}`,
						  }))
						: []
				}
				onSearchChange={(searchText: string) => {
					setSearchedEmployee(searchText);
				}}
			/>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
				}}
				warningMessage={'Unable to delete as there as associated data'}
			/>
		</>
	);
};

export default Location;
