import React, { useEffect, useState } from 'react';

import {
	createTheme,
	ThemeProvider,
	Box,
	IconButton,
	makeStyles,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Tooltip,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { BooleanInput, useNotify } from 'react-admin';
import {
	DAY_TYPE_DELETION_FAILED_TEXT,
	DAY_TYPE_DELETION_TEXT,
	DAY_TYPE_DELETION_VIOLATION_KEY,
	DAY_TYPE_DELETION_VIOLATION_TEXT,
	DAY_TYPE_SETTINGS_UPDATION_TEXT,
	DELETE_DAY_TYPE_CONFIRMATION,
	ENABLE_OVERTIME_CONFIRMATION,
} from '../constant';
import { settingsStyle } from '../settingsStyle';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import {
	useDeleteDayTypeMutation,
	useGetTimesheetAggregateByDayTypeIdQuery,
	useGetTrackDayTypeDataQuery,
	useUpdateTrackDayTypeMutation,
} from '../../../generated/graphql';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { head } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DayTypeForm from './TrackDayTypeForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
const employeeProfilePicSettingsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});
const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);
const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});
const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '16px 0px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	departmentsContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: 'fit-content',
	},
	name: {
		color: '#292929',
		fontSize: '12px !important',
		fontFamily: 'Manrope-medium',
		width: '200px',
	},
});
interface DayType {
	id?: string;
	name?: string;
	description?: string | null | undefined;
}
const TrackDayTypeSettings = () => {
	const employeeProfilePicSettingsStyles = employeeProfilePicSettingsStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const classes = useStyles();
	const ellipsisStyles = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const modalFormStyles = modalFormStyle();
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isDayTypeEnabled, setIsDayTypeEnabled] = useState<boolean>(false);
	const [isDayTypeFormShown, setIsDayTypeFormShown] = useState<boolean>(false);
	const [selectedDayType, setSelectedDayType] = useState<
		DayType | null | undefined
	>(null);
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState<boolean>(false);
	const [selectedIdForDelete, setSelectedIdForDelete] = useState<string | null>(
		null
	);

	const { data: dayTypeDetails, refetch } = useGetTrackDayTypeDataQuery({
		fetchPolicy: 'network-only',
	});

	const { data: timesheetDetails } = useGetTimesheetAggregateByDayTypeIdQuery({
		variables: {
			dayTypeId: selectedIdForDelete,
		},
		skip: !selectedIdForDelete,
		fetchPolicy: 'network-only',
	});

	const [upsertTimesheetSettings, { loading: isLoading }] =
		useUpdateTrackDayTypeMutation();
	const [deleteDayType] = useDeleteDayTypeMutation();

	useEffect(() => {
		if (!dayTypeDetails) {
			return;
		}
		setIsDayTypeEnabled(
			head(dayTypeDetails?.timesheet_settings)?.track_day_type || false
		);
	}, [dayTypeDetails]);

	const handleSave = () => {
		upsertTimesheetSettings({
			variables: {
				enableDayType: isDayTypeEnabled,
				id: head(dayTypeDetails?.timesheet_settings)?.id,
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify(DAY_TYPE_SETTINGS_UPDATION_TEXT);
					refetch();
					onWarningModalClose();
				}
			})
			.catch((err) => {
				notify('Failed', 'warning');
			});
	};

	const handleChange = (value: boolean) => {
		setIsDayTypeEnabled(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsDayTypeEnabled(!isDayTypeEnabled);
		setIsWarningModalShown(false);
	};
	const handleDeleteType = () => {
		if (
			timesheetDetails?.timesheet_aggregate?.aggregate?.count &&
			timesheetDetails?.timesheet_aggregate?.aggregate?.count > 0
		) {
			notify(DAY_TYPE_DELETION_VIOLATION_TEXT, 'warning');
			setIsConfirmationModalShown(false);
			return;
		}
		deleteDayType({
			variables: {
				id: selectedIdForDelete,
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify(DAY_TYPE_DELETION_TEXT);
					refetch();
					setIsConfirmationModalShown(false);
					setSelectedIdForDelete(null);
				}
			})
			.catch((err) => {
				if (err?.message?.includes(DAY_TYPE_DELETION_VIOLATION_KEY)) {
					notify(DAY_TYPE_DELETION_VIOLATION_TEXT, 'warning');
					setIsConfirmationModalShown(false);
					return;
				}
				notify(DAY_TYPE_DELETION_FAILED_TEXT, 'warning');
			});
	};
	return (
		<>
			<Box marginBottom='10px'>
				<Form
					onSubmit={handleSave}
					initialValues={{
						track_day_type: isDayTypeEnabled,
					}}
				>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<ThemeProvider theme={editableColumnsTheme}>
								<div className={employeeProfilePicSettingsStyles.container}>
									<Box
										className={settingsStyles.inputContainer}
										display='flex'
										flexDirection='column'
									>
										<>
											<div className={settingsStyles.inputContainer}>
												<BooleanInput
													label='Track day type'
													source='track_day_type'
													onChange={handleChange}
												/>
											</div>
											<Typography
												className={employeeProfilePicSettingsStyles.description}
											>
												Enabling this will allow to track day type in timesheet.
											</Typography>
										</>
									</Box>
								</div>
							</ThemeProvider>
						</form>
					)}
				</Form>
			</Box>

			{isDayTypeEnabled ? (
				<>
					<Box className={classes.toolBarContainer}>
						<Button
							variant='contained'
							className={modalFormStyles.saveButton}
							onClick={() => {
								setIsDayTypeFormShown(true);
							}}
						>
							Add Day Type
						</Button>
					</Box>
					{dayTypeDetails && dayTypeDetails.timesheet_day_type?.length > 0 ? (
						<Table>
							<TableHead>
								<TableRow>
									<CustomTableCell>DAY</CustomTableCell>
									<CustomTableCell>DESCRIPTION</CustomTableCell>
									<CustomTableCell></CustomTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dayTypeDetails?.timesheet_day_type?.map((dayType, index) => (
									<TableRow key={index} hover={true}>
										<CustomTableCell>
											<Tooltip title={`${dayType.name}`} placement={'right'}>
												<div
													className={`${classes.name} ${ellipsisStyles.ellipsis}`}
												>
													{dayType.name}
												</div>
											</Tooltip>
										</CustomTableCell>
										<CustomTableCell>
											<Tooltip
												title={`${dayType.description}`}
												placement={'right'}
											>
												<div
													className={`${classes.name} ${ellipsisStyles.ellipsis}`}
												>
													{dayType.description || '--'}
												</div>
											</Tooltip>
										</CustomTableCell>

										<CustomTableCell>
											<Box display='flex' alignItems='center' component='span'>
												<Box
													className={datagridStyles.listEditIconContainer}
													mr={1}
												>
													<IconButton
														className={datagridStyles.listEditIconButton}
														onClick={() => {
															const { __typename, ...restOptions } = dayType;
															setSelectedDayType(restOptions);
															setIsDayTypeFormShown(true);
														}}
													>
														<EditIcon className={datagridStyles.editIcon} />
													</IconButton>
												</Box>
												<Box className={datagridStyles.listEditIconContainer}>
													<IconButton
														className={`${datagridStyles.listEditIconButton} ${classes.deleteIconButton}`}
														onClick={() => {
															setSelectedIdForDelete(dayType?.id);
															setIsConfirmationModalShown(true);
														}}
													>
														<DeleteIcon className={datagridStyles.editIcon} />
													</IconButton>
												</Box>
											</Box>
										</CustomTableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						'No data found'
					)}
				</>
			) : (
				''
			)}

			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				isLoading={isLoading}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
			/>
			<DayTypeForm
				isEditMutation={selectedDayType ? true : false}
				open={isDayTypeFormShown}
				onClose={() => {
					setSelectedDayType(null);
					setIsDayTypeFormShown(false);
				}}
				initialValues={selectedDayType}
				refetch={refetch}
			/>
			<DeleteModal
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
					setSelectedIdForDelete(null);
				}}
				confirmationMessage={DELETE_DAY_TYPE_CONFIRMATION}
				onDelete={handleDeleteType}
			/>
		</>
	);
};

export default TrackDayTypeSettings;
