import React, { useEffect } from 'react';
import { useListContext } from 'react-admin';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	chipContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	multiSelectFilterChip: {
		margin: theme.spacing(0.5),
	},
	resetButton: {
		textDecoration: 'underline',
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		cursor: 'pointer',
		marginLeft: '5px',
		'&:hover': {
			color: '#4285F4',
		},
	},
	label: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		marginRight: '5px',
	},
}));

const AppliedFiltersContainer = (props) => {
	const classes = useStyles();
	const { children, totalCount } = props;
	const {
		filterValues,
		setFilters,
		hideFilter,
		displayedFilters,
		total,
	} = useListContext();
	const appliedFilters = Object.keys(filterValues);

	useEffect(() => {
		totalCount(total);
	}, [total, totalCount]);

	const removeFilter = (removedFilter) => {
		hideFilter(removedFilter);
	};

	return (
		<Box>
			{appliedFilters?.length > 0 && (
				<Box display='flex' alignItems='center' height='30px'>
					<Typography className={classes.label}>Showing :</Typography>
					{appliedFilters.map((filter, index) => (
						<div key={index} className={classes.chipContainer}>
							{children(
								filter,
								removeFilter,
								filterValues,
								setFilters,
								displayedFilters
							)}
						</div>
					))}
					<Typography
						className={classes.resetButton}
						onClick={() => {
							setFilters({});
						}}
					>
						Clear All
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default AppliedFiltersContainer;
