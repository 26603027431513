import React, { useContext } from 'react';
import {
	makeStyles,
	Typography,
	Tooltip,
	Card,
	CardHeader,
	CardContent,
	withStyles,
	Box,
} from '@material-ui/core';
import AvatarField from './AvatarField.component';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { EmailOutlined } from '@material-ui/icons';
import { ellipsisStyle } from '../Layout/styles';
import { UserProfileContext } from '../App';

const useStyles = makeStyles(() => ({
	label: {
		width: '120px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		marginLeft: '10px',
	},
	nameField: {
		font: 'normal normal 800 16px/47px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		fontSize: '16px',
		lineHeight: 'inherit',
		width: '150px',
	},
	designation: {
		width: '120px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	contactLabel: {
		width: '190px',
		marginBottom: '2px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		marginLeft: '10px',
	},
	subHeading: {
		fontWeight: 'bold',
		width: '150px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		marginLeft: '10px',
	},

	avatar: {
		display: 'flex',
		marginTop: '2px',
		background: '#1890ff',
	},
	phoneIcon: {
		width: '15px',
		paddingRight: '4px',
		marginLeft: '5px',
		color: '#1890ff',
		paddingBottom: '5px',
	},
	tooltip: {
		background: 'transparent',
		borderColor: 'transparent',
	},
	boxStyle: {
		display: 'flex',
	},
	contactStyle: {
		font: 'normal normal 800 14px/47px Manrope',
		marginLeft: '10px',
	},

	contactText: {
		fontSize: '12px',
		marginLeft: '10px',
	},
}));
const TextOnlyTooltip = withStyles({
	tooltip: {
		width: 100,
		color: 'black',
		backgroundColor: 'transparent',
		pointerEvents: 'auto',
	},
})(Tooltip);

interface User {
	full_name?: string | null | undefined;
	first_name: string;
	last_name?: string | null | undefined;
	email: string;
	phone_number?: string | null | undefined;
	profile_pic?: string | null | undefined;
	employee?: any;
	contractors?: any;
	user_type?: string;
}
interface Props {
	record: User;
}
export const ToolTipProfileCard = (props: Props) => {
	const { record } = props;

	const inlineStyle = useStyles();
	const styles = ellipsisStyle();
	const { noOfLocations } = useContext<any>(UserProfileContext);
	return (
		<>
			<TextOnlyTooltip
				interactive={true}
				style={{ backgroundColor: 'transparent' }}
				className={inlineStyle.tooltip}
				title={
					<Card
						style={{
							minHeight: 200,
							width: 285,
							overflow: 'auto',
						}}
					>
						<CardHeader
							avatar={
								<AvatarField
									imageSrc={record?.profile_pic}
									firstName={record?.first_name}
									lastName={record?.last_name}
									size='90'
									className={inlineStyle.avatar}
								/>
							}
							title={
								<Tooltip title={`${record?.full_name || '- -'}`}>
									<Typography
										className={`${inlineStyle.nameField} ${styles.ellipsis} `}
									>{`${record?.full_name || '- -'}`}</Typography>
								</Tooltip>
							}
							subheader={
								<Tooltip
									title={
										record?.user_type === 'contract_employee'
											? record?.contractors[0]?.designation?.title || '- -'
											: record?.employee?.designation?.title || '- -'
									}
								>
									<Typography
										className={`${inlineStyle.designation} ${styles.ellipsis}`}
									>
										{record?.user_type === 'contract_employee'
											? record?.contractors[0]?.designation?.title || '- -'
											: record?.employee?.designation?.title || '- -'}
									</Typography>
								</Tooltip>
							}
						/>
						<hr></hr>
						<CardContent style={{ overflow: 'auto' }}>
							<Typography className={inlineStyle.contactStyle}>
								Contact
							</Typography>

							<Box className={inlineStyle.boxStyle}>
								<EmailOutlined className={inlineStyle.phoneIcon} />
								<Tooltip title={`${record?.email || '- -'}`}>
									<Typography
										className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
									>
										{`${record?.email || '- -'}`}
									</Typography>
								</Tooltip>
							</Box>
							<Box className={inlineStyle.boxStyle}>
								<SmartphoneIcon className={inlineStyle.phoneIcon} />
								<Tooltip title={`${record?.phone_number || '- -'}`}>
									<Typography
										className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
									>
										{`${record?.phone_number || '- -'}`}
									</Typography>
								</Tooltip>
							</Box>
							<br></br>
							<Box className={inlineStyle.boxStyle}>
								<Typography className={inlineStyle.subHeading}>
									Reporting to
								</Typography>

								<Tooltip
									title={
										record?.user_type === 'contract_employee'
											? record?.contractors[0]?.reportee?.full_name || '- -'
											: record?.employee?.reportsTo?.full_name || '- -'
									}
								>
									<Typography
										className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
									>
										{record?.user_type === 'contract_employee'
											? record?.contractors[0]?.reportee?.full_name || '- -'
											: record?.employee?.reportsTo?.full_name || '- -'}
									</Typography>
								</Tooltip>
							</Box>
							<br></br>
							<Box className={inlineStyle.boxStyle}>
								<Typography className={inlineStyle.subHeading}>
									Department
								</Typography>
								<Tooltip
									title={
										record?.user_type === 'contract_employee'
											? record?.contractors[0]?.department?.name || '- -'
											: record?.employee?.department?.name || '- -'
									}
								>
									<Typography
										className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
									>
										{record?.user_type === 'contract_employee'
											? record?.contractors[0]?.department?.name || '- -'
											: record?.employee?.department?.name || '- -'}
									</Typography>
								</Tooltip>
							</Box>
							<br></br>
							<Box className={inlineStyle.boxStyle}>
								<Typography className={inlineStyle.subHeading}>
									Job Level
								</Typography>

								<Tooltip
									title={
										record?.user_type === 'contract_employee'
											? record?.contractors[0]?.job_level?.level || '- -'
											: record?.employee?.job_level?.level || '- -'
									}
								>
									<Typography
										className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
									>
										{record?.user_type === 'contract_employee'
											? record?.contractors[0]?.job_level?.level || '- -'
											: record?.employee?.job_level?.level || '- -'}
									</Typography>
								</Tooltip>
							</Box>
							<br></br>
							{noOfLocations && noOfLocations > 0 && (
								<Box className={inlineStyle.boxStyle}>
									<Typography className={inlineStyle.subHeading}>
										Location
									</Typography>
									<Tooltip
										title={
											record?.user_type === 'employee'
												? record?.employee?.org_location?.name || '- -'
												: record?.contractors[0]?.org_location?.name || '- - '
										}
									>
										<Typography
											className={`${inlineStyle.contactLabel} ${styles.ellipsis}`}
										>
											{record?.user_type === 'employee'
												? record?.employee?.org_location?.name || '- -'
												: record?.contractors[0]?.org_location?.name || '- -'}
										</Typography>
									</Tooltip>
								</Box>
							)}
						</CardContent>
					</Card>
				}
				placement='bottom-end'
			>
				<Typography className={`${inlineStyle.label} ${styles.ellipsis}`}>
					{`${record?.full_name || ''} `}
				</Typography>
			</TextOnlyTooltip>
		</>
	);
};
