import React, { useContext, useEffect, useState, useRef } from 'react';

import {
	Box,
	CircularProgress,
	IconButton,
	makeStyles,
	ThemeProvider,
	Typography,
	TextField as TextComponent,
	Button,
	createTheme,
} from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { UserProfileContext } from '../../../App';
import {
	GetResourceAllocationsForExportQuery,
	useGetProjectListQuery,
	useGetResourceAllocationsForExportQuery,
	useGetResourceAllocationsQuery,
} from '../../../generated/graphql';
import AddAllocationForm from '../Details/AddAllocationForm.component';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	customAutoCompleteSearchBarTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import { autocompleteMultiSelectLabelStyle } from '../../Projects/style';
import { RESOURCE_ALLOCATION_EXPORT_COLUMNS } from '../constant';
import { exportToCsv, formatDecimal } from '../../../Utils/string.util';
import ResourceAllocationForm from './ResourceAllocationForm.component';
import { ResourceAllocationFormValues } from '../modal';
import { head, isEmpty } from 'lodash';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const dataGridStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		marginLeft: '20px',
		maxHeight: '200px',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
}));

const customizedDataGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-medium',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				width: '113px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});

const CustomSearch = (props: any) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Resource'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function ResourceAllocationList() {
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [selectedRowData, setSelectedRowData] = useState<
		ResourceAllocationFormValues[]
	>([]);
	const [selectedRowIds, setSelectedRowIds] = useState<number[][]>([[]]);

	const dataGridStyles = dataGridStyle();
	const gridStyles = datagridStyle();
	const [allocationFormValue, setAllocationFormValue] = useState<any>({});
	const [isAddAllocationFormShown, setIsAddAllocationFormShown] =
		useState(false);
	const [isBulkAllocationFormShown, setIsBulkAllocationFormShown] =
		useState(false);
	const [searchedText, setSearchedText] = useState<string>('');
	const [projectOptions, setProjectOptions] = useState<any>([]);
	const [selectedProjectOptionValue, setSelectedProjectOptionValue] =
		useState<(any | null)[]>();
	const [selectedProjectIdList, setSelectedProjectIdList] = useState<string[]>(
		[]
	);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [isExportTriggered, setIsExportTriggered] = useState(false);
	const [sortFilter, setSortFilter] = useState<any[]>([
		{ user: { full_name: 'asc' } },
	]);
	const prevPageRef = useRef<null | number>(null);
	const inlineStyle = autocompleteMultiSelectLabelStyle();
	const ellipsisstyle = ellipsisStyle();
	const styles = modalFormStyle();

	const filter = createFilterOptions();

	const { data: projectList } = useGetProjectListQuery({
		variables: {
			filters: {
				deleted_at: { _is_null: true },
			},
		},
	});

	// Queries
	const {
		data: resourceAllocations,
		loading: isDataGridLoading,
		refetch: refetchTaskList,
	} = useGetResourceAllocationsQuery({
		variables: {
			resourceMappingFilter: {
				user: {
					full_name: { _ilike: `%${searchedText}%` },
				},
				project_id:
					selectedProjectIdList && selectedProjectIdList?.length > 0
						? { _in: selectedProjectIdList }
						: {
								_in: projectOptions.map(
									(project: { id: string }) => project?.id
								),
						  },
			},
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			sortFilter: sortFilter,
		},
		fetchPolicy: 'network-only',
		onCompleted: (data) => updateSelectedRowsData(data),
	});

	// EXPORT
	const { loading: isExportLoading } = useGetResourceAllocationsForExportQuery({
		variables: {
			resourceMappingFilter: {
				user: {
					full_name: { _ilike: `%${searchedText}%` },
				},
				project_id:
					selectedProjectIdList && selectedProjectIdList?.length > 0
						? { _in: selectedProjectIdList }
						: {
								_in: projectOptions.map(
									(project: { id: string }) => project?.id
								),
						  },
			},
			sortFilter: sortFilter,
		},
		skip: !isExportTriggered,
		fetchPolicy: 'network-only',
		onCompleted: (data) => exportResourceAllocations(data),
	});

	const columns = [
		{
			name: 'data',
			label: 'data',
			options: {
				filter: true,
				viewColumns: false,
				display: false,
			},
		},
		{
			name: 'resourceName',
			label: 'Resource',
			options: {
				filter: true,
				viewColumns: false,
			},
		},
		{
			name: 'projectName',
			label: 'Project',
			options: {
				filter: true,
				viewColumns: false,
			},
		},
		{
			name: 'startDate',
			label: 'Start Date',
			sort: false,
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'endDate',
			label: 'End Date',
			sort: false,
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'actions',
			label: 'Actions',
			sort: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value: any) => editButton(value),
			},
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		search: false,
		selectableRowsHeader: true,
		selectableRowsHideCheckboxes: false,
		rowsSelected:
			selectedRowIds[page] && selectedRowIds[page].length > 0
				? selectedRowIds[page]
				: [],
		print: false,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [10, 20, 30],
		page: page,
		serverSide: true,
		viewColumns: false,
		download: false,
		count:
			resourceAllocations?.totalResourceMappingCount?.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setPage(0);
			setSelectedRowData([]);
			setSelectedRowIds([[]]);
			setRowsPerPage(numberOfRows);
		},
		onTableChange: (action: string, tableState: any) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				case 'sort': {
					setSelectedRowData([]);
					setSelectedRowIds([[]]);
					break;
				}
				default:
					return;
			}
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'resourceName': {
					setSortFilter([{ user: { full_name: direction } }]);
					break;
				}
				case 'projectName': {
					setSortFilter([{ project: { name: direction } }]);
					break;
				}

				default: {
					setSortFilter([]);
				}
			}
		},
		onRowSelectionChange: (
			currentRowsSelected: any,
			allRowsSelected: any,
			rowsSelected: any
		) => {
			handleRowSelect(currentRowsSelected, allRowsSelected, rowsSelected);
		},
	};

	const updateSelectedRowIds = (
		currentlySelectedRowIds: number[][],
		pageNumber: number,
		selectedRowIdsForCurrentPage: number[]
	) => {
		const newSelectedRowIds = currentlySelectedRowIds.slice();
		while (newSelectedRowIds.length <= pageNumber) {
			newSelectedRowIds.push([]);
		}
		newSelectedRowIds[pageNumber] = selectedRowIdsForCurrentPage;

		return newSelectedRowIds;
	};

	const handleRowSelect = (
		currentRowsSelected: any,
		allRowsSelected: any,
		rowsSelectedData: any
	) => {
		const selected = allRowsSelected.map((item: any) => item.index);
		setSelectedRowIds((prev) => {
			const result = updateSelectedRowIds(prev, page, selected);
			return result;
		});
		const displayData = getResourceAllocations();
		let selectedRows = rowsSelectedData.map((dataIndex: any) => {
			return displayData[dataIndex]?.data;
		});

		setSelectedRowData(selectedRows);
	};

	function customToolbar() {
		return (
			<div className={dataGridStyles.toolbarContainer}>
				<>
					<p>{`${selectedRowIds[page].length} Row(s) selected`}</p>
				</>
				<Button
					className={dataGridStyles.exportButton}
					onClick={() => {
						setIsBulkAllocationFormShown(true);
					}}
				>
					Edit
				</Button>
			</div>
		);
	}

	function editButton(value: any) {
		return (
			<Box
				onClick={() => {
					setAllocationFormValue(value);
					setIsAddAllocationFormShown(true);
				}}
				className={gridStyles.listEditIconContainer}
			>
				<IconButton className={dataGridStyles.listEditIconButton}>
					<EditIcon className={gridStyles.editIcon} />
				</IconButton>
			</Box>
		);
	}
	const handleClearOptions = () => {
		setSelectedProjectOptionValue([]);
		setSelectedProjectIdList([]);
	};

	const handleToggleOption = (selectedOptions: any) => {
		const selectedProjectIds = selectedOptions?.value || '';
		setSelectedProjectIdList([selectedProjectIds]);
		setSelectedProjectOptionValue(selectedOptions);
	};

	const onProjectChange = (event: any, value: (any | null)[], reason: any) => {
		setSelectedRowIds([[]]);
		if (reason === 'clear') {
			handleClearOptions();
			return;
		}
		setPage(0);
		if (reason === 'select-option' || reason === 'remove-option') {
			if (!value) {
				return;
			}
			handleToggleOption(value);
			return;
		}
	};

	const getLimits = (value: Number) => {
		return (
			<div
				className={`${dataGridStyles.dropDownValue} ${ellipsisstyle.ellipsis}`}
			>
				{selectedProjectOptionValue && selectedProjectOptionValue?.length === 1
					? selectedProjectOptionValue[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	function getResourceAllocations() {
		if (!resourceAllocations) {
			return [];
		}
		const resourceAllocationData =
			resourceAllocations.project_resource_mapping.map(
				(resourceAllocation) => ({
					data: {
						id: resourceAllocation?.id,
						employee_id: resourceAllocation.employee_id,
						start_date: resourceAllocation?.start_date || '--',
						end_date: resourceAllocation?.end_date || '--',
						allocation_percentage: resourceAllocation.allocation_percentage,
						total_cost: resourceAllocation.total_cost,
						is_partial: resourceAllocation.is_partial,
						is_billable: resourceAllocation.is_billable,
						total_hours: resourceAllocation?.total_hours || 0,
						client_rate: resourceAllocation?.client_rate || 0,
						resource_cost: resourceAllocation?.resource_cost || 0,
						project_id: resourceAllocation?.project_id,
						projectStartDate: resourceAllocation.project?.start_date,
						projectEndDate: resourceAllocation.project?.end_date,
						userName: resourceAllocation.user.full_name,
						resourceJoinDate:
							resourceAllocation.user.user_type === 'employee'
								? resourceAllocation.user.employee?.join_date ?? 0
								: resourceAllocation.user.contractors[0]?.join_date ?? 0,
						resourceEndDate:
							resourceAllocation.user.user_type === 'employee'
								? resourceAllocation.user.employee?.last_date ?? 0
								: resourceAllocation.user.contractors[0]?.end_date ?? 0,
						locationHolidays:
							resourceAllocation.user.user_type === 'employee'
								? resourceAllocation.user.employee?.org_location
										?.org_holidays ?? []
								: resourceAllocation.user.contractors[0]?.org_location
										?.org_holidays ?? [],
					},
					resourceName: resourceAllocation.user?.full_name ?? '',
					projectName: resourceAllocation.project?.name || '',
					startDate: dayjs(resourceAllocation.start_date).format(dateFormat),
					endDate: dayjs(resourceAllocation.end_date).format(dateFormat),
					tag_id: resourceAllocation?.tag_id || null,
					actions: {
						projectId: resourceAllocation.project?.id,
						initialValues: {
							id: resourceAllocation?.id,
							employee_id: resourceAllocation.employee_id,
							start_date: resourceAllocation?.start_date || '--',
							end_date: resourceAllocation?.end_date || '--',
							allocation_percentage: resourceAllocation.allocation_percentage,
							total_cost: resourceAllocation.total_cost,
							is_partial: resourceAllocation.is_partial,
							is_billable: resourceAllocation.is_billable,
							total_hours: resourceAllocation?.total_hours || 0,
							resource_cost: resourceAllocation?.resource_cost || 0,
							client_rate: resourceAllocation?.client_rate || 0,
							project_id: resourceAllocation?.project_id,
							tag_id: resourceAllocation?.tag_id || null,
						},
						projectStartDate: resourceAllocation.project?.start_date || '--',
						projectEndDate: resourceAllocation.project?.end_date || '--',
						budget: resourceAllocation.project?.budget || 0,
						currencySymbol: resourceAllocation.project?.currency?.symbol || '',
						plannedCostAndHours: {
							total_hours:
								resourceAllocation.project?.plannedCostAndHours?.aggregate?.sum
									?.total_hours || 0,
							total_cost:
								resourceAllocation.project?.plannedCostAndHours?.aggregate?.sum
									?.total_cost || 0,
						},
						projectResourceMappings:
							resourceAllocation.project?.projectResourceMappings || [],

						effort_estimate: resourceAllocation.project?.effort_estimate || 0,
					},
				})
			);
		return resourceAllocationData;
	}

	const projectAutoCompleteInput = () => (
		<Autocomplete
			placeholder='Select project'
			onChange={onProjectChange}
			options={projectOptions?.length > 0 ? projectOptions : []}
			value={selectedProjectOptionValue}
			style={{ width: 210, marginTop: '4px' }}
			ChipProps={{ style: { display: 'none' } }}
			getLimitTagsText={getLimits}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				return [...filtered];
			}}
			classes={{
				tag: inlineStyle.label,
			}}
			renderOption={(option: any) => (
				<Box style={{ fontSize: '12px' }}>{option?.name || ''}</Box>
			)}
			getOptionLabel={(option: any | null) => `${option?.label}`}
			renderInput={(params) => (
				<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
					<TextComponent {...params} label='' />
				</ThemeProvider>
			)}
		/>
	);
	useEffect(() => {
		if (!projectList) {
			return;
		}
		const record = projectList?.project_list;
		setProjectOptions(record);
		return;
	}, [projectList]);

	// update selected rows data on page change
	const updateSelectedRowsData = (data: any) => {
		const resourceAllocationData = data?.project_resource_mapping.map(
			(resourceAllocation: any) => ({
				data: {
					id: resourceAllocation?.id,
					employee_id: resourceAllocation.employee_id,
					start_date: resourceAllocation?.start_date || '--',
					end_date: resourceAllocation?.end_date || '--',
					allocation_percentage: resourceAllocation.allocation_percentage,
					total_cost: resourceAllocation.total_cost,
					is_partial: resourceAllocation.is_partial,
					is_billable: resourceAllocation.is_billable,
					total_hours: resourceAllocation?.total_hours || 0,
					client_rate: resourceAllocation?.client_rate || 0,
					resource_cost: resourceAllocation?.resource_cost || 0,
					project_id: resourceAllocation?.project_id,
					projectStartDate: resourceAllocation.project?.start_date,
					projectEndDate: resourceAllocation.project?.end_date,
					userName: resourceAllocation.user.full_name,
					resourceJoinDate:
						resourceAllocation.user.user_type === 'employee'
							? resourceAllocation.user.employee?.join_date ?? 0
							: resourceAllocation.user.contractors[0]?.join_date ?? 0,
					resourceEndDate:
						resourceAllocation.user.user_type === 'employee'
							? resourceAllocation.user.employee?.last_date ?? 0
							: resourceAllocation.user.contractors[0]?.end_date ?? 0,
					locationHolidays:
						resourceAllocation.user.user_type === 'employee'
							? resourceAllocation.user.employee?.org_location?.org_holidays ??
							  []
							: resourceAllocation.user.contractors[0]?.org_location
									?.org_holidays ?? [],
				},
				resourceName: resourceAllocation.user?.full_name ?? '',
				projectName: resourceAllocation.project?.name || '',
				startDate: dayjs(resourceAllocation.start_date).format(dateFormat),
				endDate: dayjs(resourceAllocation.end_date).format(dateFormat),
				tag_id: resourceAllocation?.tag_id || null,
				actions: {
					projectId: resourceAllocation.project?.id,
					initialValues: {
						id: resourceAllocation?.id,
						employee_id: resourceAllocation.employee_id,
						start_date: resourceAllocation?.start_date || '--',
						end_date: resourceAllocation?.end_date || '--',
						allocation_percentage: resourceAllocation.allocation_percentage,
						total_cost: resourceAllocation.total_cost,
						is_partial: resourceAllocation.is_partial,
						is_billable: resourceAllocation.is_billable,
						total_hours: resourceAllocation?.total_hours || 0,
						resource_cost: resourceAllocation?.resource_cost || 0,
						client_rate: resourceAllocation?.client_rate || 0,
						project_id: resourceAllocation?.project_id,
						tag_id: resourceAllocation?.tag_id || null,
					},
					projectStartDate: resourceAllocation.project?.start_date || '--',
					projectEndDate: resourceAllocation.project?.end_date || '--',
					budget: resourceAllocation.project?.budget || 0,
					currencySymbol: resourceAllocation.project?.currency?.symbol || '',
					plannedCostAndHours: {
						total_hours:
							resourceAllocation.project?.plannedCostAndHours?.aggregate?.sum
								?.total_hours || 0,
						total_cost:
							resourceAllocation.project?.plannedCostAndHours?.aggregate?.sum
								?.total_cost || 0,
					},
					projectResourceMappings:
						resourceAllocation.project?.projectResourceMappings || [],

					effort_estimate: resourceAllocation.project?.effort_estimate || 0,
				},
			})
		);

		if (page !== prevPageRef.current) {
			const displayData = resourceAllocationData;
			let selectedRows =
				selectedRowIds[page] && selectedRowIds[page].length > 0
					? selectedRowIds[page].map((rowIndex: number) => {
							return displayData[rowIndex]?.data;
					  })
					: [];

			setSelectedRowData(selectedRows);
			prevPageRef.current = page;
		}
	};

	// Export Logic
	const exportResourceAllocations = (
		data: GetResourceAllocationsForExportQuery
	) => {
		if (isEmpty(data?.project_resource_mapping)) {
			return;
		}
		const resourceAllocationData = data?.project_resource_mapping.map(
			(resourceAllocation) => [
				resourceAllocation.user?.full_name || '',
				resourceAllocation.project?.name || '',
				dayjs(resourceAllocation.start_date).format(dateFormat),
				dayjs(resourceAllocation.end_date).format(dateFormat),
				`${resourceAllocation.allocation_percentage}%`,
				formatDecimal(resourceAllocation.total_cost, 1),
				resourceAllocation.is_billable === false ? 'No' : 'Yes',
				resourceAllocation.is_partial === false ? 'No' : 'Yes',
				resourceAllocation?.project_resource_tag?.name || '--',
			]
		);
		const exportData = [
			RESOURCE_ALLOCATION_EXPORT_COLUMNS,
			...resourceAllocationData,
		];
		exportToCsv('Resource_Allocations_Export.csv', exportData);
		setIsExportTriggered(false);
	};
	return (
		<div>
			<Box display='flex' marginBottom='10px'>
				<Typography className={dataGridStyles.heading}>
					Resource Allocations
				</Typography>
				{isDataGridLoading && (
					<CircularProgress
						size={20}
						style={{ marginLeft: 15, position: 'relative', top: 19 }}
					/>
				)}
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<Box display='flex'>
					<Box>
						<CustomSearch
							searchText={searchedText}
							onSearch={(value: string) => {
								setSearchedText(value);
								setSelectedRowData([]);
								setSelectedRowIds([[]]);
							}}
						/>
					</Box>
					<Box display='block' marginTop='-28px' marginLeft='5px'>
						<Box className={styles.label}>Search Project</Box>
						{projectAutoCompleteInput()}
					</Box>
				</Box>
				<Box
					onClick={() => {
						setIsExportTriggered(true);
					}}
				>
					<ExportButtonWithLoader
						generateCSV={() => setIsExportTriggered(true)}
						isDisabled={
							!resourceAllocations?.project_resource_mapping.length ||
							!projectOptions.length ||
							!selectedProjectIdList?.length ||
							isExportLoading ||
							isExportTriggered
						}
					/>
				</Box>
			</Box>
			<>{selectedRowIds[page]?.length > 0 && customToolbar()}</>
			{selectedProjectIdList?.length > 0 && (
				<ThemeProvider theme={customizedDataGridTheme}>
					<>
						<MUIDataTable
							title={'Resource Allocations'}
							data={getResourceAllocations()}
							columns={columns}
							options={options}
						/>
					</>
				</ThemeProvider>
			)}
			<AddAllocationForm
				open={isAddAllocationFormShown}
				onClose={() => {
					setAllocationFormValue({});
					setIsAddAllocationFormShown(false);
				}}
				projectStartDate={allocationFormValue?.projectStartDate}
				projectEndDate={allocationFormValue?.projectEndDate}
				projectId={allocationFormValue?.projectId}
				initialValues={allocationFormValue?.initialValues}
				budget={allocationFormValue?.budget}
				currencySymbol={allocationFormValue?.currencySymbol}
				plannedCostAndHours={allocationFormValue?.plannedCostAndHours}
				projectResourceMappings={
					allocationFormValue?.projectResourceMappings || []
				}
				effort_estimate={allocationFormValue?.effort_estimate}
				onSuccess={async () => {
					await refetchTaskList();
				}}
			/>
			<ResourceAllocationForm
				open={isBulkAllocationFormShown}
				onClose={() => {
					setAllocationFormValue({});
					setIsBulkAllocationFormShown(false);
				}}
				initialValues={selectedRowData}
				projectResourceMappings={
					!resourceAllocations
						? []
						: head(resourceAllocations?.project_resource_mapping)?.project
								.projectResourceMappings
				}
				onSuccess={async () => {
					await refetchTaskList();
				}}
			/>
		</div>
	);
}

export default ResourceAllocationList;
