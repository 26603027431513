export const USER_IN_PROJECT = 'in_project';
export const USER_ON_BENCH = 'on_bench';
export const ALL_USERS = 'all';

export const USER_BILLABLE_STATUS_OPTIONS = [
	{ id: ALL_USERS, name: 'All' },
	{ id: true, name: 'Billable' },
	{ id: false, name: 'Non Billable' },
];

export const USER_TYPE_STATUS_OPTIONS = [
	{ id: 'All', name: 'All' },
	{ id: 'contract_employee', name: 'Contractors' },
	{ id: 'employee', name: 'Employees' },
];

export const USER_ALLOCATED_STATUS_OPTIONS = [
	{ id: ALL_USERS, name: 'All' },
	{ id: USER_IN_PROJECT, name: 'In Project' },
	{ id: USER_ON_BENCH, name: 'On Bench' },
];

export const START_DATE_ERROR = 'Start date should be less than the End date';

export const NOTES_PLACEHOLDER = 'Type @ to select the project';

export const ALLOCATION_DETAILS_COLUMN = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'JOINING DATE',
	'DEPARTMENT',
	'REPORTING MANAGER',
	'REPORTING MANAGER EMAIL',
	'SKILLS',
	'PROJECT',
	'SOW START DATE',
	'SOW END DATE',
	'ALLOCATION START DATE',
	'ALLOCATION END DATE',
	'BILLABLE',
	'PARTIAL ALLOCATION',
	'ALLOCATION(%)',
	'NOTES',
];
