import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import {
	KanbanCardChildren,
	KanbanColumnView,
	UpdateOrdinalFn,
	GetKanbanColumnIdFn,
} from '../../Kanban/kanban.model';
import '../../Kanban/kanbanComponent.css';
import { MilestoneKanbanColumn } from './MilestoneKanbanColumn.component';
interface Stage {
	name: string;
	sum: number;
}
export const Kanban = ({
	columns,
	children,
	onAddCardToColumn,
	stageValue,
}: {
	columns: KanbanColumnView[];
	updateOrdinal: UpdateOrdinalFn;
	children: KanbanCardChildren;
	onAddCardToColumn: GetKanbanColumnIdFn;
	stageValue?: Stage[];
}) => {
	const [kanbanColumnViews, setKanbanColumnViews] = useState<
		KanbanColumnView[]
	>([]);
	useEffect(() => {
		setKanbanColumnViews(columns);
	}, [columns]);

	return (
		<DragDropContext onDragEnd={() => {}}>
			<div className={'kanbanContainer'}>
				{kanbanColumnViews?.length > 0 &&
					kanbanColumnViews.map((column) => {
						const stage = stageValue?.filter(
							(stage: Stage) => stage?.name === column?.name
						);
						// TODO Change this temporary fix code to proper way
						return stage ? (
							<MilestoneKanbanColumn
								column={column}
								key={column.id}
								onAddCardToColumn={onAddCardToColumn}
								totalValue={stage || []}
							>
								{children}
							</MilestoneKanbanColumn>
						) : (
							<MilestoneKanbanColumn
								column={column}
								key={column.id}
								onAddCardToColumn={onAddCardToColumn}
							>
								{children}
							</MilestoneKanbanColumn>
						);
					})}
			</div>
		</DragDropContext>
	);
};
