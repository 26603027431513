import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
	query getCompany($companyId: uuid!) {
		crm_company: crm_company_by_pk(id: $companyId) {
			address
			phone
			name
			linkedin
			logo
			id
			website
			country_id
			owner_id
			company_size {
				id
				label
			}
			owner {
				first_name
				last_name
				full_name
				id
			}
			country {
				id
				name
			}
			company_category {
				id
				label
			}
			company_contact_type {
				id
				label
			}
			company_size {
				id
				label
			}
			company_sector {
				id
				label
			}
		}
	}
`;

export const GET_CONTACT_BY_ID = gql`
	query getContactById($contactId: uuid!) {
		crm_contacts_by_pk(id: $contactId) {
			id
			company_id
			first_name
			last_name
			title
			email
			primary_phone
			alternate_phone
			background
			linkedin
			address
			contact_type
			owner_id
		}
	}
`;
export const GET_OPPORTUNITIES = gql`
	query getOpportunities($companyId: uuid!) {
		opportunities(where: { company_id: { _eq: $companyId } }) {
			id
			name
			win_percentage
			description
			commission
			company_id
			primary_contact_id
			close_date
			owner_id
			source
			priority
			value
			denomination
			crm_contact {
				first_name
			}
			kanbanCard {
				id
				column_id
				opportunity_id
				kanban_column {
					name
					kanban_board {
						id
						name
					}
				}
			}
		}
	}
`;
export const GET_OPPORTUNITY_STATUS = gql`
	query getOpportunityStatus {
		kanban_board(where: { type: { _eq: crm_oppurtunity_kanban } }) {
			kanban_columns(order_by: { ordinal: asc }) {
				id
				name
			}
		}
	}
`;
export const ADD_OPPORTUNITY = gql`
	mutation insertOpportunity($data: opportunities_insert_input!) {
		insert_opportunities_one(object: $data) {
			id
			name
			close_date
			opportunity_kanban_cards {
				column_id
				id
			}
		}
	}
`;

export const UPDATE_OPPORTUNITY = gql`
	mutation updateOpportunity(
		$id: uuid!
		$kanbanId: uuid!
		$data: opportunities_set_input!
		$kanData: opportunity_kanban_card_set_input
	) {
		update_opportunities_by_pk(pk_columns: { id: $id }, _set: $data) {
			close_date
		}
		update_opportunity_kanban_card_by_pk(
			pk_columns: { id: $kanbanId }
			_set: $kanData
		) {
			column_id
		}
	}
`;
export const GET_OPPORTUNITY_BY_ID = gql`
	query getOpportunityById($opportunityId: uuid!) {
		opportunities_by_pk(id: $opportunityId) {
			id
			name
			win_percentage
			description
			commission
			company_id
			primary_contact_id
			close_date
			owner_id
			lead_owner_id
			source
			priority
			value
			currency_id
			loss_reason_id
			kanbanCard {
				id
				kanban_column {
					name
					id
					kanban_board {
						id
						name
					}
				}
			}
		}
	}
`;
