import React, { useState, useContext } from 'react';
import {
	TextInput,
	required,
	useNotify,
	useRefresh,
	SelectInput,
} from 'react-admin';

import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	DialogActions,
	DialogContent,
	modalFormStyle,
} from '../../../Layout/styles';
import { monthOptions } from '../../constant';

import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
	CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAddLeavePolicyMutation } from '../../../generated/graphql';
import { LeaveGroupFormValues } from './LeaveManagement.model';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';

const leavePolicyFormStyle = makeStyles({
	actionContainer: {
		paddingBottom: '10px',
	},
	container: {
		minHeight: 'fit-content',
		width: '49vw',
	},
	dialogContent: {
		width: '100%',
		maxHeight: '70vh',
		padding: '0px 10px 0px 0px',
	},
});

const leavePolicyToggleButtonStyle = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'flex-end',
		gap: '1rem',
	},
	innerContainer: {
		marginBottom: '-1rem',
	},
});

interface LeavePolicyFormProps {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	leavePolicyLabel: string[];
}

export const LeavePolicyForm = (props: LeavePolicyFormProps) => {
	const { open, onClose, refetch, leavePolicyLabel } = props;
	const [policyPeriod, setPolicyPeriod] = useState({
		startDate: '',
		endDate: '',
		period: '',
	});
	const [errors, setErrors] = useState({});
	const [leavePolicyError, setLeavePolicyError] = useState(false);
	const leavePolicyFormStyles = leavePolicyFormStyle();
	const leavePolicyToggleButton = leavePolicyToggleButtonStyle();
	const formStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const { dateFormat } = useContext<any>(UserProfileContext);

	const [addLeavePolicy, { loading: isLeavePolicyCreateLoading }] =
		useAddLeavePolicyMutation();

	const handleSubmit = (formValues: LeaveGroupFormValues) => {
		if (!formValues) {
			return;
		}
		if (
			leavePolicyLabel &&
			leavePolicyLabel?.length > 0 &&
			leavePolicyLabel?.includes(formValues?.label)
		) {
			setLeavePolicyError(true);
			return;
		}

		if (
			formValues?.applicable_from &&
			(new Date(formValues?.start_date) >
				new Date(formValues?.applicable_from) ||
				new Date(policyPeriod?.endDate) < new Date(formValues?.applicable_from))
		) {
			setErrors({
				applicable_from: 'Applicable Should be within period',
			});
			return;
		}
		addLeavePolicy({
			variables: {
				data: {
					label: formValues?.label?.trim(),
					value: formValues?.label,
					is_default: formValues?.is_default,
					start_date: policyPeriod?.startDate,
					end_date: policyPeriod?.endDate,
					applicable_from: formValues?.applicable_from || null,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Leave policy created');
					refetch();
					refresh();
					handleClose();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const handleClose = () => {
		setPolicyPeriod({
			startDate: '',
			endDate: '',
			period: '',
		});
		setLeavePolicyError(false);
		onClose();
	};

	const validateleavePolicyFormValues = async () => {
		const leavePolicyFormErrors = {} as LeaveGroupFormValues;
		if (leavePolicyError) {
			leavePolicyFormErrors.label = 'Leave policy name already exists!';
		}
		return { ...leavePolicyFormErrors, ...errors };
	};

	const calculatePolicyPeriod = (monthIndex: string) => {
		const startDate = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('YYYY-MM-DD');
		const endDate = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('YYYY-MM-DD');
		const startingPeriod = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('D-MMM-YYYY');
		const endingPeriod = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('D-MMM-YYYY');

		setPolicyPeriod({
			startDate: startDate,
			endDate: endDate,
			period: `Policy runs from ${startingPeriod} to ${endingPeriod}`,
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={false}
				disableBackdropClick
			>
				<Box
					className={`${formStyles.container} ${leavePolicyFormStyles.container}`}
				>
					<Box className={formStyles.headerContainer}>
						<Typography className={formStyles.heading}>
							{`Add Leave Policy`}
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={{}}
						onSubmit={handleSubmit}
						validate={validateleavePolicyFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent
										className={leavePolicyFormStyles.dialogContent}
									>
										<Box display='flex' alignItems='flex-end'>
											<Box mr={2} width='60%'>
												<Typography className={formStyles.label}>
													Leave Policy*
												</Typography>
												<TextInput
													source='label'
													label=''
													validate={required()}
													onChange={() => {
														setLeavePolicyError(false);
													}}
												/>
											</Box>
											{/* TODO Remove to handle this default case */}
											{/* <Box>
												<BooleanInput
													label='Mark this leave policy as default'
													source='is_default'
												/>
											</Box> */}
										</Box>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Start Month*
												</Typography>
												<SelectInput
													source='start_date'
													label=''
													choices={monthOptions}
													validate={required()}
													onChange={(e) => {
														calculatePolicyPeriod(e.target.value);
													}}
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Policy Period
												</Typography>
												<Typography>{policyPeriod.period || '- -'}</Typography>
											</Box>
										</Box>
										<Box className={leavePolicyToggleButton.container}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Applicable From
												</Typography>
												<Field
													name='applicable_from'
													validate={(value: any) => {
														if (
															dayjs(value).isValid() === true ||
															value === null
														) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props: any) => (
														<div>
															<CustomDateInput
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={props.input.onChange}
																dateFormat={dateFormat}
															/>
														</div>
													)}
												</Field>
											</Box>
										</Box>
									</DialogContent>
									<DialogActions>
										<Box
											className={`${formStyles.buttonContainer}${leavePolicyFormStyles.actionContainer}`}
										>
											<Button
												onClick={handleClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine || isLeavePolicyCreateLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid || pristine || isLeavePolicyCreateLoading
												}
												type='submit'
												startIcon={
													isLeavePolicyCreateLoading && (
														<CircularProgress
															style={{
																height: '20px',
																width: '20px',
																color: '#ffffff',
															}}
														/>
													)
												}
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default LeavePolicyForm;
