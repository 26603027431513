import { gql } from '@apollo/client';

export const DELETE_PROJECT_MILESTONE = gql`
	mutation deleteProjectMilestone($milestoneId: uuid!) {
		delete_project_milestone_by_pk(id: $milestoneId) {
			id
		}
	}
`;

const resourceMappingResponse = `{
  end_date
  id
  is_partial
  project_id
  total_cost
  employee_skill_id
  total_hours
  start_date
  employee_id
  user {
    id
    first_name
    last_name,
    user_type
  }
  employee_skill_mapping {
    skill_level {
      level
      id
    }
    skill_master {
      id
      name
    }
  }
  project_resource_mapping_details {
    allocation_percentage
    cost
    end_date
    hours
    id
    is_billable
    project_resource_id
    start_date
  }
}
}`;
//take skill against user
export const GET_ALLOCATED_RESOURCES = gql`query getAllocatedResources($projectId: uuid) {
  project_resource_mapping(where: {project_id: {_eq: $projectId}})
  ${resourceMappingResponse}
  `;

export const UPSERT_RESOURCE_MAPPING_DETAILS = gql`
	mutation UpsertResourceMappingDetails(
		$allocations: [project_resource_mapping_detail_insert_input!]!
	) {
		insert_project_resource_mapping_detail(
			objects: $allocations
			on_conflict: {
				constraint: project_resource_mapping_detail_pkey
				update_columns: [
					end_date
					cost
					allocation_percentage
					hours
					is_billable
					start_date
					project_resource_id
				]
			}
		) {
			returning {
				id
				end_date
				cost
				allocation_percentage
				hours
				is_billable
				start_date
				project_resource_id
			}
		}
	}
`;

export const GET_ALLOCATED_RESOURCE = gql`query getAllocatedResources($resourceMappingId: uuid!) {
  project_resource_mapping(where: {id: {_eq: $resourceMappingId}})
  ${resourceMappingResponse}
  `;

export const GET_EMPLOYEES = gql`
	query MyQuery {
		employee {
			emp_cost
			user_id
		}
	}
`;

export const GET_CONTRACTORS = gql`
	query MyQuery {
		contractor {
			contractor_cost
			user_id
		}
	}
`;

export const GET_PROJECTS_RESOURCE_LIST = gql`
	query MyQuery {
		project {
			project_resource_mappings_aggregate {
				aggregate {
					count
				}
			}
			id
		}
	}
`;

export const GET_PROJECT_LIST = gql`
	query getProjectGanttChart {
		project(
			where: {
				_and: [
					{ ProjectStatus: { value: { _in: ["in_progress", "pending"] } } }
				]
			}
			order_by: { name: asc }
		) {
			id
			name
			start_date
			end_date
			project_type
			project_resource_mappings_aggregate(distinct_on: employee_id) {
				aggregate {
					count
				}
			}
			project_resource_mappings {
				project_id
				employee_id
				user {
					full_name
				}
				allocation_percentage
				start_date
				end_date
			}
		}
	}
`;

export const GET_RESOURCE_MAPPING_LIST = gql`
	query MyQuery {
		user {
			id
			first_name
			org_id
			last_name
			user_type
			project_resource_mappings {
				employee_id
				project_id
				project_resource_mapping_details {
					start_date
					end_date
					allocation_percentage
				}
			}
			employees {
				id
			}
			contractors {
				id
			}
		}
	}
`;

export const GET_PROJECT = gql`
	query getProject($projectId: uuid!) {
		project_by_pk(id: $projectId) {
			id
			name
			start_date
			end_date
			project_type
			kanban_id
			revenue
			description
			project_owner_id
			tag_id
			category_id
			custom_field_1
			custom_field_2
			custom_field_3
			custom_field_4
			custom_field_5
			custom_field_6
			custom_field_7
			custom_field_8
			custom_field_9
			user {
				first_name
				last_name
				full_name
			}
			ProjectStatus {
				label
				value
			}
			project_status_id
			pre_sales_owner_id
			is_strict
			is_billable
			company_id
			currency_id
			total_cost
			effort_estimate
			risk_status
			prefix
			project_execution_type
			company_id
			geo_id
			revenue
			created_at
			userByCreatedByUser {
				id
				full_name
			}
			userByPreSalesOwnerId {
				first_name
				last_name
				full_name
			}
			geo_list {
				id
				name
			}
			ProjectStatus {
				label
				value
				id
			}
			company {
				name
				logo
			}
			currency {
				currency_type
				symbol
			}
			project_resource_mappings_aggregate(
				where: { is_billable: { _eq: true } }
			) {
				aggregate {
					sum {
						total_cost
						total_hours
					}
				}
			}
			kanban_board {
				kanban_columns {
					project_kanban_cards_aggregate(
						where: { project_task: { project_id: { _eq: $projectId } } }
					) {
						aggregate {
							count(columns: column_id)
						}
					}
				}
			}
			totalTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			completedTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
					project_kanban_cards: { kanban_column: { state: { _eq: "DONE" } } }
				}
			) {
				aggregate {
					count
				}
			}
			totalPaymentMilestones: project_milestones_aggregate(
				where: {
					link_to_invoice: { _eq: true }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			paymentcompletedMilestones: project_milestones_aggregate(
				where: {
					link_to_invoice: { _eq: true }
					deleted_at: { _is_null: true }
					milestone_payment_status: { value: { _eq: "received" } }
				}
			) {
				aggregate {
					count
				}
			}
			totalMilestones: project_milestones_aggregate(
				where: { deleted_at: { _is_null: true } }
			) {
				aggregate {
					count
				}
			}
			completedMilestones: project_milestones_aggregate(
				where: {
					deleted_at: { _is_null: true }
					project_milestone_status: { value: { _eq: "complete" } }
				}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const UPSERT_PROJECT_RESOURCE_MAPPING = gql`
	mutation upsertProjectResourceMapping(
		$projectResourceMapping: project_resource_mapping_insert_input!
	) {
		insert_project_resource_mapping_one(
			object: $projectResourceMapping
			on_conflict: {
				constraint: PK_5c20558ac7059dae61aad653bbb
				update_columns: [
					allocation_percentage
					start_date
					end_date
					employee_skill_id
					employee_id
					is_partial
					is_billable
					total_cost
					total_hours
					resource_cost
					client_rate
					tag_id
				]
			}
		) {
			id
			start_date
			end_date
			project_id
		}
	}
`;

export const GET_PLANNED_COST_AND_HOURS = gql`
	query getPlannedCostandHours($projectId: uuid!) {
		project_resource_mapping_aggregate(
			where: { project_id: { _eq: $projectId } }
		) {
			aggregate {
				sum {
					total_cost
					total_hours
				}
			}
		}
	}
`;
export const UPSERT_PROJECT = gql`
	mutation upsertProject($project: project_insert_input!) {
		insert_project_one(
			object: $project
			on_conflict: {
				constraint: PK_f648594d8d54cf65d99b2c10fa5
				update_columns: [
					geo_id
					company_id
					name
					geo_id
					currency_id
					project_status_id
					project_owner_id
					project_execution_type
					project_type
					kanban_id
					start_date
					end_date
					effort_estimate
					revenue
					pre_sales_owner_id
					risk_status
					description
					total_cost
					is_strict
					is_billable
					risk_status
					prefix
					tag_id
					category_id
					custom_field_1
					custom_field_2
					custom_field_3
					custom_field_4
					custom_field_5
					custom_field_6
					custom_field_7
					custom_field_8
					custom_field_9
				]
			}
		) {
			id
		}
	}
`;

export const GET_USER_OPTION = gql`
	query getUserOption($where: user_bool_exp = {}) {
		user(where: $where, order_by: { first_name: asc }) {
			id
			first_name
			last_name
			full_name
			user_type
			employees {
				emp_cost
				emp_billing_cost
				last_date
				join_date
			}
			contractors {
				contractor_cost
				billing_cost
				end_date
				join_date
			}
		}
	}
`;
export const GET_SKILL_LEVEL_OPTION = gql`
	query getSkillLevelOption {
		skill_level(order_by: { level: asc }) {
			id
			level
		}
	}
`;
export const GET_SKILL_OPTION = gql`
	query getSkillOption {
		 skill_master(order_by: { name: asc }) {
			skill_id
			name
			id
		}
	}
`;
export const GET_PROJECT_SKILL = gql`
	query getProjectSkillSearch(
		$filter: project_skill_requirement_bool_exp = {}
	) {
		project_skill_requirement(where: $filter) {
			project_id
			id
		}
	}
`;

export const UPSERT_PROJECT_SKILL = gql`
	mutation upsertProjectSkill(
		$projectSkill: project_skill_requirement_insert_input!
	) {
		insert_project_skill_requirement_one(
			object: $projectSkill
			on_conflict: {
				constraint: project_skill_requirement_pkey
				update_columns: [
					skill_id
					skill_level_id
					count
					project_id
					notes
					request_status_id
				]
			}
		) {
			id
		}
	}
`;

export const DELETE_RESOURCE_ALLOCATION = gql`
	mutation deleteResourceAllocation($resourceAllocationId: uuid!) {
		update_project_resource_mapping(
			where: { id: { _eq: $resourceAllocationId } }
			_set: { deleted_at: "now()" }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_PROJECT_SKILL_BY_PK = gql`
	query getProjectByPk($projectSkillId: uuid!) {
		project_skill_requirement_by_pk(id: $projectSkillId) {
			skill_id
			skill_level_id
			count
			id
			notes
			request_status_id
		}
	}
`;

export const DELETE_PROJECT_SKILL = gql`
	mutation deleteProjectSkill($projectSkillId: uuid!) {
		delete_project_skill_requirement_by_pk(id: $projectSkillId) {
			id
		}
	}
`;

export const UPSERT_PROJECT_MILESTONE = gql`
	mutation upsertProjectMilestone(
		$projectmilestone: project_milestone_insert_input!
	) {
		insert_project_milestone_one(
			object: $projectmilestone
			on_conflict: {
				constraint: PK_d48bbba0b8d2b2f7d9daabd0342
				update_columns: [
					project_id
					name
					status
					start_date
					end_date
					payment_status_id
					id
					amount
					link_to_invoice
				]
			}
		) {
			id
		}
	}
`;

export const GET_PROJECT_RESOURCE_MAPPINGS = gql`
	query getProjectResourceMapping(
		$resourceMappingFilter: project_resource_mapping_bool_exp = {}
		$holidayFilter: org_holidays_bool_exp = {}
		$limit: Int!
		$offset: Int!
		$sortFilter: [project_resource_mapping_order_by!]
	) {
		project_resource_mapping_aggregate(where: $resourceMappingFilter) {
			aggregate {
				count
			}
		}
		project_resource_mapping(
			where: $resourceMappingFilter
			limit: $limit
			offset: $offset
			order_by: $sortFilter
		) {
			id
			employee_id
			start_date
			end_date
			user {
				full_name
				user_type
				employee {
					org_location {
						org_holidays(where: $holidayFilter) {
							date
							name
						}
					}
				}
				contractors {
					org_location {
						org_holidays(where: $holidayFilter) {
							date
							name
						}
					}
				}
			}
			is_billable
			is_partial
			allocation_percentage
			total_cost
			total_hours
			created_at
			project_id
			resource_cost
			client_rate
			tag_id
			project_resource_tag {
				name
			}
		}
		org_holidays(where: $holidayFilter) {
			name
			date
		}
	}
`;
export const EXPORT_PROJECT_RESOURCE_MAPPINGS = gql`
	query getProjectResourceMapping(
		$resourceMappingFilter: project_resource_mapping_bool_exp = {}
		$holidayFilter: org_holidays_bool_exp = {}
		$sortFilter: [project_resource_mapping_order_by!]
	) {
		project_resource_mapping_aggregate(where: $resourceMappingFilter) {
			aggregate {
				count
			}
		}
		project_resource_mapping(
			where: $resourceMappingFilter
			order_by: $sortFilter
		) {
			id
			employee_id
			start_date
			end_date
			user {
				full_name
				user_type
				employee {
					org_location {
						org_holidays(where: $holidayFilter) {
							date
							name
						}
					}
				}
				contractors {
					org_location {
						org_holidays(where: $holidayFilter) {
							date
							name
						}
					}
				}
			}
			is_billable
			is_partial
			allocation_percentage
			total_cost
			total_hours
			created_at
			project_id
			resource_cost
			project_resource_tag {
				name
			}
		}
		org_holidays(where: $holidayFilter) {
			name
			date
		}
	}
`;

export const PROJECT_MILESTONE_BY_PK = gql`
	query getProjectMilestoneBYpK($projectMilestoneId: uuid!) {
		project_milestone_by_pk(id: $projectMilestoneId) {
			id
			link_to_invoice
			name
			payment_status_id
			start_date
			status
			end_date
			amount
		}
	}
`;

export const GET_ORGANISATION_SUPPORTED_CURRENCY = gql`
	query getOrganisationBaseCurrency($organizationId: uuid!) {
		org_supported_currencies(where: { org_id: { _eq: $organizationId } }) {
			id
			currency_id
			currency {
				id
				currency_type
				symbol
			}
		}
	}
`;

export const GET_MILESTONE_LIST = gql`
	query getProjectMilestoneList($project_id: uuid!) {
		project_milestone(
			where: { project_id: { _eq: $project_id } }
			order_by: { name: asc }
		) {
			amount
			id
		}
	}
`;

export const GET_PROJECT_DOC = gql`
	query getProjectDocs($project_id: uuid!) {
		project_docs(
			order_by: { file_name: asc }
			where: { project_id: { _eq: $project_id } }
		) {
			id
			url
			file_name
		}
	}
`;

export const ADD_PROJECT_DOC = gql`
	mutation addProjectDoc($data: project_docs_insert_input!) {
		insert_project_docs_one(object: $data) {
			file_name
		}
	}
`;

export const DELETE_PROJECT_DOC = gql`
	mutation deleteProjectDoc($id: uuid!) {
		delete_project_docs(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_FILE_NAME = gql`
	mutation updateProjectFileName($file_name: String!, $id: uuid!) {
		update_project_docs(
			_set: { file_name: $file_name }
			where: { id: { _eq: $id } }
		) {
			returning {
				id
			}
		}
	}
`;
export const GET_PROJECT_KANBANS = gql`
	query getProjectKanbanTemplates {
		kanban_board(
			where: {
				master_sub_module: { value: { _eq: "projectTask" } }
				is_active: { _eq: true }
			}
			order_by: { name: asc }
		) {
			description
			id
			name
			is_default
		}
	}
`;

export const GET_ORGANISATION_BASE_CURRENCY = gql`
	query getOrgBaseCurrency($organizationId: uuid!) {
		organization(where: { id: { _eq: $organizationId } }) {
			id
			currency_id
			currency {
				id
				currency_type
				symbol
			}
		}
	}
`;
export const GET_PROJECT_TASK_CARDS_AGGREGATE = gql`
	query getTaskCardsAggregate($project_id: uuid!) {
		project_kanban_card_aggregate(
			where: { project_task: { project_id: { _eq: $project_id } } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_MILESTONE_PROGRESS = gql`
	query getProjectMilestonesProgress(
		$projectMilestoneFilter: project_milestone_bool_exp = {}
	) {
		project_milestone(
			where: $projectMilestoneFilter
			order_by: { ordinal: asc }
		) {
			id
			name
			amount
			start_date
			end_date
			ordinal
			link_to_invoice
			milestone_payment_status {
				label
				id
			}
			project_milestone_status {
				label
				id
				value
			}
			totalTasks: project_tasks_aggregate(
				where: { parent_task_id: { _is_null: true } }
			) {
				aggregate {
					count
				}
			}
			compeletedTasks: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					project_kanban_cards: { kanban_column: { state: { _eq: "DONE" } } }
				}
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const CREATE_PROJECT_LABEL = gql`
	mutation createProjectLabel($projectLabel: String = "") {
		insert_project_tags_one(object: { name: $projectLabel }) {
			id
		}
	}
`;

export const CREATE_PROJECT_CATEGORY = gql`
	mutation createProjectCategory(
		$projectCategory: String = ""
		$type: project_category_type = ""
	) {
		insert_project_category_one(
			object: { name: $projectCategory, type: $type }
		) {
			id
		}
	}
`;

export const GET_USER_TEAMS = gql`
	query getUserTeams {
		user_team(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_USER_BY_TEAMS = gql`
	query getUsersByTeam($teamId: uuid_comparison_exp = {}) {
		user_team_mapping(
			where: { user: { is_active: { _eq: true } }, team_id: $teamId }
		) {
			user_id
			user: userByUserId {
				id
				full_name
				user_type
				employees {
					emp_cost
					emp_billing_cost
					last_date
					join_date
				}
				contractors {
					contractor_cost
					billing_cost
					end_date
					join_date
				}
			}
		}
	}
`;
export const CREATE_PROJECT_DELAY = gql`
	mutation insertProjectDelayMapping(
		$projectData: project_delay_mapping_insert_input!
	) {
		insert_project_delay_mapping_one(
			object: $projectData
			on_conflict: {
				constraint: project_delay_mapping_pkey
				update_columns: [notes, reason_id]
			}
		) {
			project_id
		}
	}
`;
export const UPDATE_MILESTONE_ORDER = gql`
	mutation updateMilestoneOrder($mileStoneData: [project_milestone_updates!]!) {
		update_project_milestone_many(updates: $mileStoneData) {
			returning {
				id
			}
		}
	}
`;

export const GET_LEAVE_DAYS = gql`
	query getEmployeeLeaveAndHolidays(
		$userId: uuid!
		$startDate: date
		$endDate: date
		$holidayFilter: org_holidays_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		employee(where: { user_id: { _eq: $userId } }) {
			id
			user {
				id
			}
			employee_leaving_mappings(
				where: {
					from_date: { _gte: $startDate }
					to_date: { _lte: $endDate }
					employee_leave_status: { value: { _eq: "approved" } }
				}
			) {
				id
				from_date
				to_date
				org_leave_type {
					leave_type
				}
			}
		}
		user_optional_holiday_mapping(where: $optionalHolidayFilter) {
			id
			org_holiday {
				name
				id
				date
				is_restricted
				org_location {
					id
					name
				}
			}
		}
	}
`;

export const UPDATE_RESOURCE_ALLOCATION = gql`
	mutation updateResourceAllocation(
		$data: [project_resource_mapping_insert_input!]!
	) {
		insert_project_resource_mapping(
			on_conflict: {
				constraint: PK_5c20558ac7059dae61aad653bbb
				update_columns: [
					allocation_percentage
					start_date
					end_date
					is_billable
					is_partial
					resource_cost
					total_hours
					total_cost
				]
			}
			objects: $data
		) {
			affected_rows
		}
	}
`;
export const GET_RESOURCE_TAGS = gql`
	query getResourceTags($orgId: uuid!) {
		project_resource_tag(where: { org_id: { _eq: $orgId } }) {
			name
			id
		}
	}
`;

export const GET_PROJECT_CUSTOM_FIELDS = gql`
	query getProjectCustomFields {
		custom_field(
			where: { master_sub_module: { value: { _eq: "project" } } }
			order_by: { index: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
		}
		lastIndexCustomField: custom_field_aggregate {
			aggregate {
				max {
					index
				}
			}
		}
	}
`;
