import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { insightChartTheme } from '../../../Layout/Theme.component';
import dayjs from 'dayjs';
import { formatAmount } from '../../../Utils/string.util';
import NoDataFound from '../../NoDataFound.component';
import { ChartWithDateRange } from '../modal';
import { useGetPipelineForeCastQuery } from '../../../generated/graphql';

interface ChartData {
	month: string;
	'Total Value': number;
	'Weighted Value': number;
}

export const PipelineSaleForecastReport = (props: ChartWithDateRange) => {
	const { currency } = props;
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineTotalSaleByGeography, loading: isLoading } =
		useGetPipelineForeCastQuery({
			variables: {
				// next three months
				startDate: dayjs()
					.add(1, 'months')
					.startOf('month')
					.format('YYYY-MM-DD'),
				endDate: dayjs().add(3, 'months').endOf('month').format('YYYY-MM-DD'),
			},
		});

	useEffect(() => {
		if (!pipelineTotalSaleByGeography) {
			return;
		}
		const nextThreeMonths = Array(3)
			.fill(1)
			.map((value, index) =>
				dayjs()
					.add(index + 1, 'months')
					.format('MMM')
			);
		const opportunities = pipelineTotalSaleByGeography.opportunities
			.filter(
				(opportunity) =>
					opportunity.value !== 0 || opportunity.weighted_value !== 0
			)
			.map((opportunity) => ({
				...opportunity,
				month: dayjs(opportunity.close_date).format('MMM'),
			}));
		const data = nextThreeMonths.map((month) => ({
			month: month,
			'Total Value': opportunities
				.filter((opportunity) => opportunity.month === month)
				.reduce((total, currentVal) => total + currentVal.value, 0),
			'Weighted Value': opportunities
				.filter((opportunity) => opportunity.month === month)
				.reduce((total, currentVal) => total + currentVal.weighted_value, 0),
		}));

		setChartData(data);
	}, [pipelineTotalSaleByGeography]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{label}</p>
					<p>
						Total Value:
						{` ${formatAmount(payload[0]?.payload['Total Value'], currency)}`}
					</p>
					<p>
						Weighted Value:
						{` ${formatAmount(
							payload[0]?.payload['Weighted Value'],
							currency
						)}`}
					</p>
					<p></p>
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={355}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='month'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey='Total Value'
						fill='#196CF5'
						barSize={10}
						radius={[10, 10, 0, 0]}
						name={'Total Value'}
					/>
					<Bar
						dataKey='Weighted Value'
						fill='#9ac8f5'
						barSize={10}
						radius={[10, 10, 0, 0]}
						name={'Weighted Value'}
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<CardHeader
					title='Sales Forecast'
					titleTypographyProps={{ variant: 'h6' }}
				/>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
