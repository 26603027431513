import React, { useState, useContext, useEffect } from 'react';
import { UserProfileContext } from '../../../App';
import {
	ComposedChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import 'antd/dist/antd.css';
import { insightChartTheme } from '../../../Layout/Theme.component';
import dayjs from 'dayjs';
import _, { head } from 'lodash';
import { DateTimeUtil } from '../../../Utils/date-time.util';
import { Duration } from 'dayjs/plugin/duration';
import { useGetEmployeesQuery, useGetHolidayListQuery, useGetUtilizationByMonthQuery } from '../../../generated/graphql';

export const UtilizationByMonth = () => {
	const [chartData, setChartData] = useState<any>();
	const { orgId: organisationId,isOvertimeEnabled } = useContext<any>(UserProfileContext);
	const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
	const endDate = dayjs().format('YYYY-MM-DD');
	const [monthStart, setMonthStart] = useState<string | null >(null)
	const [monthEnd, setMonthEnd] = useState<string | null >(null)

	const { data: utilizationData } = useGetUtilizationByMonthQuery({
		variables: {
			orgId: organisationId,
			startDate: startDate,
			endDate: endDate,
		},
	});

	const { data: holidayList } = useGetHolidayListQuery({
		variables: {
			startDate: startDate,
			endDate: endDate,
		},
		fetchPolicy: 'network-only',
	});

	const { data: usersList, refetch : refetchUserList } = useGetEmployeesQuery({
		variables: {
			startDate: monthStart,
			endDate: monthEnd
		},
		skip: monthStart=== null || monthEnd === null, 
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!utilizationData) {
			return;
		}

		const timesheet = _.chain(
			isOvertimeEnabled
				? [
						...utilizationData?.timesheet,
						...utilizationData?.timesheet_overtime,
				  ]
				: utilizationData?.timesheet
		)
			.map((timesheetEntry) => {
				return {
					working_hours: timesheetEntry?.working_hours,
					month: dayjs(timesheetEntry?.date).format('MMM'),
					id: dayjs(timesheetEntry?.date).format('MMM-YYYY'),
					noOfDays: dayjs(timesheetEntry?.date).daysInMonth(),
					monthId: dayjs(timesheetEntry?.date).get('month'),
					date: timesheetEntry?.date,
				};
			})
			.groupBy('id')
			.map((timesheetEntry) => {
				// Finding start and end of month and setting it for the users API
				//Todo => We need to optimize the API calls beacuse currentlyfor each month API will trigger
				const startOfMonth = dayjs(head(timesheetEntry)?.date).startOf('month');
				const endOfMonth = dayjs(head(timesheetEntry)?.date).endOf('month');
				setMonthStart(startOfMonth.format('YYYY-MM-DD'))
				setMonthEnd(endOfMonth.format('YYYY-MM-DD'))
				refetchUserList()
				const holidayArray = holidayList?.org_holidays?.filter(
					(holiday: { date: string }) =>
						((dayjs(holiday?.date).isSame(startOfMonth)  ||dayjs(holiday?.date).isAfter(startOfMonth) )&&
							(dayjs(holiday?.date).isSame(endOfMonth) ||dayjs(holiday?.date).isBefore(endOfMonth))) 
							=== true
				);
				const buisnessDays = DateTimeUtil.getBusinessDaysBetween(
					startOfMonth,
					endOfMonth
				);
				const actualTotalDays = buisnessDays - (holidayArray?.length || 0);
				const month = head(timesheetEntry)?.month;
				const avgUsersCount = (
					(usersList?.usersJoinedBeforeMonth?.aggregate?.count || 0 )
					+ 
					(usersList?.usersJoinedBeforeEndOfMonth?.aggregate?.count || 0))
					/2
				const totalHours =
				actualTotalDays *
				head(utilizationData?.organization)?.working_hours *
				avgUsersCount;
				const monthId = head(timesheetEntry)?.monthId;
				const data = timesheetEntry
					.map((timeSheet: any) => timeSheet?.working_hours?.split(':'))
					.map((hoursAndMinutes: string[]) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				return {
					data,
					month,
					totalHours,
					monthId,
					date: head(timesheetEntry)?.date,
				};
			})
			.map((finalTimesheet: any) => {
				return {
					'Billed Hours': Math.floor(finalTimesheet?.data?.asHours()),
					month: finalTimesheet?.month,
					'Available Hours': Math.floor(finalTimesheet?.totalHours),
					monthId: finalTimesheet?.monthId,
					year: dayjs(finalTimesheet?.date).get('year')
				};
			})
			// Todo-Need to change this code using loadash
			.sort((a, b) =>  {
				if(a?.monthId < b?.monthId && a?.year < b?.year) {
					return 1
				}else if(a?.monthId < b?.monthId && a?.year > b?.year ){
					return 1
				}else if(a?.monthId > b?.monthId && a?.year < b?.year ){
					return -1
				}else if(a?.monthId > b?.monthId && a?.year > b?.year ){
					return -1
				}
				return a.monthId-b.monthId
			})
			.value();
		setChartData(timesheet);
	}, [holidayList, isOvertimeEnabled, refetchUserList, usersList, utilizationData]);
	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Utilization By Month
					</Box>
				</Box>
				<CardContent>
					<ResponsiveContainer width='100%' height={350}>
						<ComposedChart
							width={500}
							height={400}
							data={chartData}
							margin={{
								top: 20,
								right: 80,
								bottom: 20,
								left: 20,
							}}
						>
							<CartesianGrid stroke='#f5f5f5' />
							<XAxis
								dataKey='month'
								dy={20}
								tick={{ fontSize: '11px', fill: '#43425D' }}
								axisLine={false}
								tickLine={false}
							/>
							<YAxis
								dx={-10}
								tick={{ fontSize: '11px', fill: '#43425D' }}
								axisLine={false}
								tickLine={false}
							/>
							<Tooltip />
							<Legend
								iconSize={16}
								wrapperStyle={{
									fontSize: '12px',
									fontFamily: 'Manrope-semibold',
									color: '#5C5C5C',
									paddingTop: '50px',
								}}
							/>
							<Bar
								dataKey='Billed Hours'
								fill='#196CF5'
								barSize={10}
								radius={[10, 10, 0, 0]}
							/>
							<Line
								type='monotone'
								dataKey='Available Hours'
								stroke='#ff7300'
							/>
						</ComposedChart>
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
