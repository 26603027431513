import React from 'react';
import {
	TextInput,
	SelectInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	email,
	useMutation,
	useNotify,
	useRefresh,
	regex,
	maxLength,
} from 'react-admin';
import { validatePhoneNumber } from '../../../Utils/string.util';
import { Button, Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../../Layout/styles';
import { contactChoices } from '../Constant';

interface Props {
	onClose: () => void;
	contact: ContactForm;
	setContactId: (id: string) => void;
}
interface ContactForm {
	first_name?: string;
	last_name?: string;
	title?: string;
	email?: string;
	company_id?: string;
	primary_phone?: string;
	alternate_phone?: string;
	background?: string;
	contact_type?: string;
	owner_id?: string;
}
interface Choice {
	first_name: string;
	last_name: string;
	full_name: string;
}
const mapToUserName = (choice: Choice) => `${choice?.full_name}`;

const AddContact = (props: Props) => {
	const { onClose, contact, setContactId } = props;
	const classes = modalFormStyle();
	const [mutate] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();
	const onSave = (value: ContactForm) => {
		mutate(
			{
				type: 'create',
				resource: 'crm_contacts',
				payload: {
					data: value,
				},
			},
			{
				onSuccess: ({ data }) => {
					setContactId(data?.id);
					onClose();
					notify('Contact Created Successfully');
					refresh();
				},
				onFailure: (error) => {
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};
	const onDialogueClose = () => {
		onClose();
	};

	return (
		<Form initialValues={contact} onSubmit={onSave}>
			{({ handleSubmit, invalid }) => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.label}>First Name *</Box>
					<TextInput
						source='first_name'
						fullWidth={true}
						label={''}
						validate={required()}
					/>
					<Box className={classes.label}>Last Name</Box>
					<TextInput source='last_name' fullWidth={true} label={''} />
					<Box className={classes.label}>Title</Box>
					<TextInput source='title' label={''} fullWidth={true} />
					<Box className={classes.label}>Email</Box>
					<TextInput
						source='email'
						label={''}
						fullWidth={true}
						validate={[email()]}
					/>
					<Box className={classes.label}> Phone 1</Box>
					<TextInput
						source='primary_phone'
						label={''}
						validate={[
							regex(validatePhoneNumber, 'Must be number'),
							maxLength(15, 'Maximum 15 digits allowed'),
						]}
						fullWidth
					/>
					<Box className={classes.label}> Phone 2</Box>
					<TextInput
						source='alternate_phone'
						label={''}
						fullWidth
						validate={[
							regex(validatePhoneNumber, 'Must be number'),
							maxLength(15, 'Maximum 15 digits allowed'),
						]}
					/>
					<Box className={classes.label}>Background</Box>
					<TextInput source='background' label={''} fullWidth />
					<Box className={classes.label}>Contact Type</Box>
					<SelectInput
						source='contact_type'
						resettable={true}
						label={''}
						fullWidth
						choices={contactChoices}
					/>
					<Box className={classes.label}>BD OWNER</Box>
					<ReferenceInput
						source='owner_id'
						reference='user'
						label={''}
						fullWidth
						filterToQuery={(searchText) => ({
							full_name: searchText,
						})}
						filter={{ is_active: true }}
					>
						<AutocompleteInput
							optionText={mapToUserName}
							resettable={true}
							shouldRenderSuggestions={(name: any) => name.trim().length > 0}
						/>
					</ReferenceInput>
					<Box className={classes.buttonContainer}>
						<Button onClick={onDialogueClose} className={classes.cancelButton}>
							Cancel
						</Button>
						<Button
							className={invalid ? classes.disabledButton : classes.saveButton}
							disabled={invalid}
							type='submit'
						>
							Add Contact
						</Button>
					</Box>
				</form>
			)}
		</Form>
	);
};

export default AddContact;
