import React, { ReactNode, useEffect, useState } from 'react';

import {
	EmployeeCount,
	ContractorCount,
	EmployeeGenderPercentage,
	EmployeeServingNoticePeriod,
	EmployeeExperienceWiseSplitUp,
	EmployeeBillableRatio,
	EmployeeByDepartment,
	EmployeeJuniorSeniorPercentage,
} from '../../Reports/talent/index';
import { UsersCount } from '../../Reports/UsersCount.component';
import { EmployeeSkillWiseSplitUp } from '../../Reports/talent/EmployeeSkillWiseSplitUp.component';
// import { EmployeeBenchReport } from "../../Reports/talent/EmployeeBenchReport.component";  TODO We will need this report in future
import { Box } from '@material-ui/core';
import { insightViewStyle } from '../../Layout/styles';
import { EmployeeAddtionReport } from '../../Reports/talent/EmployeeAdditionReport.component';
import { EmployeeResignationReport } from '../../Reports/talent/EmployeeResignationReport.component';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
	useGetEmployeeChartDataQuery,
	useGetEmployeeCountQuery,
} from '../../generated/graphql';

const CountCard = ({ label, value }: { label: string; value: ReactNode }) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.countContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
 interface GenderPercentage {
		malePercentage: string | number;
		feMalePercentage: string | number;
		otherPercentage: string | number;
 }

 interface juniorSeniorPercentage {
	juniorPercentage: string | number;
	seniorPercentage: string | number;
}
interface JobLevel {
	level: string;
	count: number
}
interface Skill {
	name: string;
	count: number
}
export const EmployeeInsights = () => {
	const classes = insightViewStyle();
	const [employeeAdditionData, setEmployeeAdditionData] = useState<any>([]);
	const [employeeAttitionData, setEmployeeAttitionData] = useState<any>([]);
	const [skillReportData, setSkillReportData] = useState<Skill[]>([]);
	const [jobLevelReportData, setJobLevelReportData] = useState<JobLevel[]>([]);
	const [juniorSeniorData, setJuniorSeniorData] = useState<juniorSeniorPercentage>({
		juniorPercentage: '',
		seniorPercentage:''
	});
	const [employeeGenderPercentage, setEmployeeGenderPercentage] = useState<GenderPercentage>({
		malePercentage: '',
		feMalePercentage:'',
		otherPercentage: ''
	});
	const { data: insightCount, loading: isCountLoading } =
		useGetEmployeeCountQuery();

	const { data: insightChartData, loading: isChartLoading } =
		useGetEmployeeChartDataQuery({
			variables: {
				startDate: dayjs().format('YYYY-MM-DD'),
				endDate: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
			},
		});
	useEffect(() => {
		if (!insightChartData) {
			return;
		}
		const employeeAdditionList = _.chain(
			insightChartData?.employeeAddition
				?.filter((employee: any) => employee?.join_date !== null)
				.map((item: any) => {
					return {
						joinDate: item?.join_date,
						month: dayjs(item?.join_date).format('MMM'),
						id: item?.id,
						monthId: dayjs(item?.join_date).get('month'),
						year: dayjs(item?.join_date).get('year'),
					};
				})
		)
			.groupBy('month')
			.map((groupedObj, name) => {
				return _.reduce(
					groupedObj,
					(mappedObj, value) => {
						return {
							...mappedObj,
							name,
							'Employee Count': groupedObj?.length,
							monthId: value?.monthId,
							year: value?.year,
						};
					},
					{}
				);
			})
			.sort((a: any, b: any) => {
				if (a?.monthId < b?.monthId && a?.year < b?.year) {
					return 1;
				} else if (a?.monthId < b?.monthId && a?.year > b?.year) {
					return 1;
				} else if (a?.monthId > b?.monthId && a?.year < b?.year) {
					return -1;
				} else if (a?.monthId > b?.monthId && a?.year > b?.year) {
					return -1;
				}
				return a.monthId - b.monthId;
			})
			.value();
		setEmployeeAdditionData(employeeAdditionList);
		const employeeAttitionList = _.chain(
			insightChartData?.employeeAttrition
				?.filter((employee: any) => employee?.last_date !== null)
				.map((item: any) => {
					return {
						lastDate: item?.last_date,
						month: dayjs(item?.last_date).format('MMM'),
						id: item?.id,
						monthId: dayjs(item?.last_date).get('month'),
						year: dayjs(item?.last_date).get('year'),
					};
				})
		)
			.groupBy('month')
			.map((groupedObj, name) => {
				return _.reduce(
					groupedObj,
					(mappedObj, value) => {
						return {
							...mappedObj,
							name,
							'Employee Count': groupedObj?.length,
							monthId: value?.monthId,
							year: value?.year,
						};
					},
					{}
				);
			})
			.sort((a: any, b: any) => {
				if (a?.monthId < b?.monthId && a?.year < b?.year) {
					return 1;
				} else if (a?.monthId < b?.monthId && a?.year > b?.year) {
					return 1;
				} else if (a?.monthId > b?.monthId && a?.year < b?.year) {
					return -1;
				} else if (a?.monthId > b?.monthId && a?.year > b?.year) {
					return -1;
				}
				return a.monthId - b.monthId;
			})
			.value();
		setEmployeeAttitionData(employeeAttitionList);
		const skillReportList = insightChartData?.employeeSkillMapping?.map(
			(skill: any) => {
				return {
					name: skill?.name,
					count: skill?.employee_skill_mappings_aggregate?.aggregate?.count,
				};
			}
		);
		setSkillReportData(skillReportList);

		const jobLevelList = insightChartData?.jobLevelDistribution?.map(
			(jobLevel: any) => {
				return {
					level: jobLevel?.level,
					count: jobLevel?.employees_aggregate?.aggregate?.count,
				};
			}
		);
		setJobLevelReportData(jobLevelList);

		const juniorSeniorData = insightChartData?.JuniorSeniorData?.map((item) => {
			const previousExpInMonths = String(item?.prior_experience).split(':')[1];
			const currentExpInMonths = dayjs().diff(dayjs(item?.join_date), 'month');
			const totalExpInMonths =
				currentExpInMonths + parseInt(previousExpInMonths);
			return {
				totalExpInMonths,
				id: item?.id,
			};
		});
		const juniorPercentage =
			(juniorSeniorData?.filter((item) => item.totalExpInMonths <= 60).length /
				insightChartData?.JuniorSeniorData?.length) *
			100;
		const seniorPercentage =
			(juniorSeniorData?.filter((item) => item.totalExpInMonths >= 60).length /
				insightChartData?.JuniorSeniorData?.length) *
			100;
		setJuniorSeniorData({ juniorPercentage, seniorPercentage });
		const totalEmployeeCount =
		insightChartData?.employeeCountForGender?.aggregate?.count || 0;

			const malePercentage = insightChartData?.genderMale?.aggregate?.count
				? (insightChartData?.genderMale?.aggregate?.count /
						totalEmployeeCount) *
				  100
				: 0;
			const feMalePercentage = insightChartData?.genderFemale?.aggregate?.count
				? (insightChartData?.genderFemale?.aggregate?.count /
						totalEmployeeCount) *
				  100
				: 0;
			const otherPercentage = insightChartData?.genderTransGender?.aggregate
				?.count
				? (insightChartData?.genderTransGender?.aggregate?.count /
						totalEmployeeCount) *
				  100
				: 0;
			setEmployeeGenderPercentage({
				malePercentage,
				feMalePercentage,
				otherPercentage,
			});
	}, [insightChartData]);
	return (
		<>
			<Box className={classes.headCountContainer}>
				<CountCard
					label='Total Count'
					value={
						<UsersCount
							count={insightCount?.userCount?.aggregate?.count || '--'}
							loading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Employee Count'
					value={
						<EmployeeCount
							count={insightCount?.totalEmployee?.aggregate?.count || '--'}
							loading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Contractor Count'
					value={
						<ContractorCount
							count={insightCount?.totalContractor?.aggregate?.count || '--'}
							isLoading={isCountLoading}
						/>
					}
				/>
				<CountCard
					label='Employees serving notice'
					value={
						<EmployeeServingNoticePeriod
							count={
								insightCount?.employeeServingNotice?.aggregate?.count || '--'
							}
							loading={isCountLoading}
						/>
					}
				/>
			</Box>
			<Box className={classes.container}>
				<Box className={classes.chartContainer}>
					<EmployeeSkillWiseSplitUp
						skillReport={skillReportData}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeGenderPercentage chartData={employeeGenderPercentage} loading={isChartLoading}/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeExperienceWiseSplitUp
						jobLevelReport={jobLevelReportData}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeBillableRatio
						loading={isChartLoading}
						billableCount={
							insightChartData?.billableEmployees?.aggregate?.count
						}
						nonBillableCount={
							insightChartData?.nonBillableEmployees?.aggregate?.count
						}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeByDepartment
						departmentDetails={insightChartData?.employeeByDepartment}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeJuniorSeniorPercentage chartData={juniorSeniorData} loading={isChartLoading}/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeAddtionReport
						chartData={employeeAdditionData}
						loading={isChartLoading}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<EmployeeResignationReport
						chartData={employeeAttitionData}
						loading={isChartLoading}
					/>
				</Box>
				{/* TODO We will need this report in future */}
				{/* <Box className={classes.chartContainer}>
        <EmployeeBenchReport />
      </Box> */}
			</Box>
		</>
	);
};

export default EmployeeInsights;
