import React, { useState, useEffect, useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	InputAdornment,
	IconButton,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	Button,
	Checkbox,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateFnsUtils from '@date-io/dayjs';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
	LeaveProps,
	EmployeeLeavingMapping,
	SelectedMonthDates,
	ApplyLeaveFormValues,
} from './Attendance.model';
import { DropdownOption } from '../../SharedComponents/model';

import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Loading } from 'ra-ui-materialui';

import { Style, ErrorTooltip, dateTimePickerTheme } from './Style';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import { modalFormTheme } from '../../Layout/Theme.component';
import { SelectInput } from 'react-admin';
import dayjs from 'dayjs';
import {
	useEmployeeLeaveStatusQuery,
	useGetPendingApprovalLeaveQuery,
	useApproveLeaveMutation,
	useDeleteAppliedLeaveMutation,
	useGetEmployeeDetailsByUserIdQuery,
	useGetLeaveSettingsSubscription,
	useGetOrganisationLeaveCalenderYearQuery,
} from '../../generated/graphql';
import { head, isEmpty, orderBy } from 'lodash';
import ApproveRejectModal from './ApproveRejectModal.component';
import {
	APPROVE_LEAVE_MESSAGE,
	REJECT_LEAVE_MESSAGE,
	START_DATE_ERROR,
} from './Constant';
import no_data_found from '../../assets/no_data_found.png';
import { UserProfileContext } from '../../App';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';
import ApplyLeaveForm from './ApplyLeaveForm.component';
import WarningConfirmationModal from '../../SharedComponents/WarningConfirmationModal.component';
import { ENABLE_OVERTIME_CONFIRMATION } from '../Settings/constant';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const inlineStyle = makeStyles({
	dropdown: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	container: {
		marginTop: '20px',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'flex',
			},
			'& $deleteIconContainer': {
				display: 'flex',
			},
		},
	},
	listEditIconContainer: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconContainer: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	saveButton: {
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
});
interface Props {
	userId: string;
	userLocationId: string | null | undefined;
	refetch: () => void;
}
const PendingApproval = (props: Props) => {
	const { userId, refetch, userLocationId } = props;
	const style = inlineStyle();
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [leaveStatusDropDown, setLeaveStatusDropDown] =
		useState<DropdownOption[]>();
	const [leaveStatusId, setLeaveStatusId] = useState('');
	const [pendingApprovalLeaves, setPendingApprovalLeaves] =
		useState<LeaveProps[]>();
	const [leaveSubmittedId, setLeaveSubmittedId] = useState<string[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<any>([]);
	const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] =
		useState(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const datagridStyles = datagridStyle();
	const { data: leaveStatus } = useEmployeeLeaveStatusQuery({
		fetchPolicy: 'network-only',
	});

	const [approveLeaveMutation, { loading: approveLeaveLoader }] =
		useApproveLeaveMutation();
	const { dateFormat, orgId, id:loggedInUserId } = useContext<any>(UserProfileContext);
	const notify = useNotify();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedLeaveIdForDelete, setSelectedLeaveIdForDelete] = useState<
		string | null
	>();
	const [selectedLeaveDetails, setSelectedLeaveDetails] =
		useState<ApplyLeaveFormValues | null>();
	const [currentLeaveYear, setCurrentLeaveYear] = useState<SelectedMonthDates>({
		startDate: '',
		endDate: '',
	});

	const { data: leaveSettings } = useGetLeaveSettingsSubscription({
		variables: {
			orgId,
		},
		fetchPolicy: 'network-only',
	});

	const enableLeave = leaveSettings?.organization_by_pk?.enable_leave ?? false;

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId: orgId },
			fetchPolicy: 'network-only',
		});

	const { data: employee } = useGetEmployeeDetailsByUserIdQuery({
		variables: { userId: userId },
		skip: !userId,
	});
	const [deleteAppliedLeaveMutation, { loading: handleDeleteLeaveLoader }] =
		useDeleteAppliedLeaveMutation();

	const {
		data: pendingLeaves,
		loading: pendingApprovalLoading,
		refetch: refetchPendingApproval,
	} = useGetPendingApprovalLeaveQuery({
		variables: {
			userId,
			startDate,
			endDate,
			statusId: leaveStatusId === 'All' ? {} : { _eq: leaveStatusId },
			finacialEndDate: currentLeaveYear?.endDate,
			finacialStartDate: currentLeaveYear?.startDate,
			employeeId: head(employee?.employee)?.id,
		},
		fetchPolicy: 'network-only',
	});

	const styles = Style();
	const ellipsisStyles = ellipsisStyle();

	useEffect(() => {
		if (isEmpty(leaveStatus?.employee_leave_status)) {
			return;
		}
		const statusOption = leaveStatus?.employee_leave_status
			.filter((element) => element?.value !== 'draft')
			.map((value) => {
				if (value?.value === 'submitted') {
					setLeaveStatusId(value?.id);
				}
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});
		const sortedStatusOption = orderBy(statusOption, ['name'], ['asc']);
		setLeaveStatusDropDown(sortedStatusOption);
	}, [leaveStatus]);

	useEffect(() => {
		if (isEmpty(pendingLeaves?.employee)) {
			return;
		}
		const response = pendingLeaves?.employee
			?.filter((value) => value?.employee_leaving_mappings?.length > 0)
			.map((data) => ({
				...data,
				employee_leaving_mappings: data?.employee_leaving_mappings.map(
					(leave) => {
						return {
							leaveStatus:
								leave?.employee_leave_status?.label === 'Submitted'
									? 'Pending'
									: leave?.employee_leave_status?.label,
							fromDate: leave?.from_date,
							toDate: leave?.to_date,
							leaveCount: leave?.leave_count,
							reason: leave?.reason,
							leaveType: leave?.org_leave_type?.leave_type,
							id: leave?.id,
							userId: leave?.employee_id,
							leaveTypeId: leave?.org_leave_type?.id,
							isHalfDay: leave?.is_half_day,
							leaveStatusValue: leave?.employee_leave_status?.value,
						};
					}
				),
			}));
		setPendingApprovalLeaves(response);
		refetch();
	}, [pendingLeaves, refetch]);

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const financialData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!financialData) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				financialData?.leave_calendar_year_start_month,
			financial_year_start_date: financialData?.leave_calendar_year_start_date,
			financial_year_end_date: financialData?.leave_calendar_year_end_date,
			financial_year_end_month: financialData?.leave_calendar_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);
	const handleReject = async () => {
		const data = leaveStatus?.employee_leave_status?.find(
			(value) => value?.value === 'rejected'
		);
		const rejectLeave = await approveLeaveMutation({
			variables: {
				leaveData: {
					employee_leave_status_id: data?.id, 
				},
				leaveId: leaveSubmittedId,
			},
		});
		if (rejectLeave) {
			setLeaveSubmittedId([]);
			refetch();
			refetchPendingApproval();
			setIsApproveRejectModalOpen(false);
			notify('Leave Rejected Successfully');
			return;
		}
	};

	const handleApprove = async () => {
		const data = leaveStatus?.employee_leave_status?.find(
			(value) => value?.value === 'approved'
		);
		const leaveApproval = await approveLeaveMutation({
			variables: {
				leaveData: {
					approved_by:loggedInUserId,        
					employee_leave_status_id: data?.id,  
				},
				leaveId: leaveSubmittedId,
			},
		});
		if (leaveApproval) {
			setLeaveSubmittedId([]);
			refetch();
			refetchPendingApproval();
			setIsApproveModalOpen(false);
			setIsApproveRejectModalOpen(false);
			notify('Leave Approved Successfully');
			return;
		}
	};

	const getLeaveCount = (typeId: string | null) => {
		if (!typeId) {
			return {
				balance: 0,
				isNegativeBalanceAllowed: false,
			};
		}
		const leave = head(pendingLeaves?.leaveBalanceData)?.org_leave_types?.find(
			(leaveType) => leaveType?.id === typeId
		);
		if (!leave) {
			return {
				balance: 0,
				isNegativeBalanceAllowed: false,
			};
		}
		const accruedCount =
			leave?.accrued_leave_type?.length > 0
				? leave?.accrued_leave_type?.reduce((sum, number) => {
						return number?.count + sum;
				  }, 0)
				: 0;
		const employeeLeaveTypeCount =
			leave?.employee_leave_type_mappings?.length > 0
				? head(leave?.employee_leave_type_mappings)?.opening_balance
				: 0;
		const appliedLeaveCount = leave?.employee_leaving_mappings?.reduce(
			(sum, number) => {
				return number?.leave_count + sum;
			},
			0
		);
		const balance =
			accruedCount + employeeLeaveTypeCount - Number(appliedLeaveCount) || 0;

		const leaveBalance = {
			balance: balance || 0,
			isNegativeBalanceAllowed: leave?.is_negative_bal_allowed || false,
		};
		return leaveBalance;
	};
	const handleEditLeave = (leaveDetails: EmployeeLeavingMapping) => {
		if (!leaveDetails) {
			return;
		}
		const leaveBalanceData:
			| {
					balance: number;
					isNegativeBalanceAllowed: boolean;
			  }
			| undefined = getLeaveCount(leaveDetails?.leaveTypeId || null);
		const formValues = {
			from_date: leaveDetails?.fromDate,
			to_date: leaveDetails?.toDate,
			is_half_day: leaveDetails?.isHalfDay,
			leave_type_id: leaveDetails?.leaveTypeId,
			approver_id: leaveDetails?.leaveApproverId,
			reason: leaveDetails?.reason,
			id: leaveDetails?.id,
			leave_count: leaveDetails?.leaveCount,
			leaveBalance: leaveBalanceData?.balance || 0,
			isNegativeBalanceAllowed:
				leaveBalanceData?.isNegativeBalanceAllowed || false,
		};
		setSelectedLeaveDetails(formValues);
	};
	const handleDeleteLeave = async () => {
		if (!selectedLeaveIdForDelete) {
			return;
		}
		const deletedLeaveStatus = await deleteAppliedLeaveMutation({
			variables: {
				id: selectedLeaveIdForDelete,
			},
		});
		if (deletedLeaveStatus) {
			if (deletedLeaveStatus?.errors) {
				notify('Leave deletion failed', 'warning');
				return;
			}
			setSelectedLeaveIdForDelete(null);
			refetchPendingApproval();
			setIsWarningModalShown(false);
			refetch();
			notify('Leave deleted successfully');
			return;
		}
	};
	return (
		<>
			<Box display='flex' justifyContent='space-between'>
				<Box className={style.dropdown}>
					<Form
						onSubmit={() => {}}
						initialValues={{ leaveStatus: leaveStatusId }}
					>
						{() => (
							<ThemeProvider theme={modalFormTheme}>
								<form>
									<SelectInput
										source={'leaveStatus'}
										label={''}
										choices={
											leaveStatusDropDown && leaveStatusDropDown?.length > 0
												? [
														{
															id: 'All',
															name: 'All',
														},
														...leaveStatusDropDown,
												  ]
												: []
										}
										onChange={(event) => {
											setLeaveStatusId(event?.target?.value);
											setLeaveSubmittedId([]);
											setSelectedUserId([]);
										}}
									/>
								</form>
							</ThemeProvider>
						)}
					</Form>
				</Box>
				<Box>
					<ThemeProvider theme={dateTimePickerTheme}>
						<Box display='flex'>
							<ErrorTooltip
								title={startDateError ? START_DATE_ERROR : ''}
								placement='top'
								arrow={true}
							>
								<Box width='188px' marginLeft='20px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setLeaveSubmittedId([]);
												setSelectedUserId([]);
												setStartDateError(false);
												if (dayjs(date).isAfter(dayjs(endDate))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
												setStartDate(dayjs(date).format('YYYY-MM-DD'));
											}}
											value={startDate}
											format={dateFormat}
											label='Start date'
											error={startDateError}
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</ErrorTooltip>
							<Box width='188px'>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										variant='inline'
										onChange={(date: any) => {
											setLeaveSubmittedId([]);
											setSelectedUserId([]);
											setStartDateError(false);
											if (dayjs(date).isBefore(dayjs(startDate))) {
												setStartDateError(true);
											} else {
												setStartDateError(false);
											}
											setEndDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										value={endDate}
										format={dateFormat}
										label='End date'
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton>
														<EventIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</Box>
					</ThemeProvider>
				</Box>
				<Box display={'flex'} justifyContent='flex-end'>
					<Button
						variant='contained'
						className={styles?.rejectButton}
						disabled={!enableLeave || leaveSubmittedId?.length === 0}
						onClick={() => {
							setIsApproveRejectModalOpen(true);
						}}
					>
						Reject
					</Button>
					<Box marginLeft='20px'>
						<Button
							variant='contained'
							className={style.saveButton}
							disabled={!enableLeave || leaveSubmittedId?.length === 0}
							onClick={() => {
								setIsApproveModalOpen(true);
								setIsApproveRejectModalOpen(true);
							}}
						>
							Approve
						</Button>
					</Box>
				</Box>
			</Box>
			{pendingApprovalLoading ? (
				<Box>
					<Loading />
				</Box>
			) : (
				<Box marginTop={'15px'}>
					{pendingApprovalLeaves && pendingApprovalLeaves?.length > 0 ? (
						<div>
							<div>
								{pendingApprovalLeaves?.map((user: LeaveProps) => {
									return (
										<div
											className={styles.leavesContainer}
											key={user?.user?.id}
										>
											<div className={styles.avatarContainer}>
												<Avatar
													className={styles.avatar}
													src={`${user?.user?.profile_pic || ''}`}
												>
													{user?.user?.first_name?.charAt(0).toUpperCase() ||
														''}
													{user?.user?.last_name?.charAt(0).toUpperCase() || ''}
												</Avatar>
												<Typography className={styles.employeeName}>
													{`${user.user?.full_name || ''}`}
												</Typography>
											</div>
											<Table>
												<TableHead>
													<TableRow>
														<CustomTableCell
															className={styles.dataActionContainer}
														>
															<Checkbox
																className={styles.checkBox}
																name='checkedB'
																color='primary'
																checked={selectedUserId?.includes(
																	user?.user?.id
																)}
																onChange={(event: any) => {
																	if (event?.currentTarget?.checked) {
																		const record =
																			user?.employee_leaving_mappings
																				?.filter(
																					(value: any) =>
																						value.leaveStatus === 'Pending'
																				)
																				.map((id: any) => id?.id);
																		setLeaveSubmittedId([
																			...leaveSubmittedId,
																			...record,
																		]);
																		setSelectedUserId(user?.user?.id);
																	} else {
																		setLeaveSubmittedId([]);
																		setSelectedUserId([]);
																	}
																}}
															/>
														</CustomTableCell>
														<CustomTableCell>Reason</CustomTableCell>
														<CustomTableCell>Date</CustomTableCell>
														<CustomTableCell>Status</CustomTableCell>
														<CustomTableCell>Number Of Days</CustomTableCell>
														<CustomTableCell>Leave Type</CustomTableCell>
														<CustomTableCell></CustomTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{user?.employee_leaving_mappings?.length > 0 &&
														user?.employee_leaving_mappings?.map(
															(leave: EmployeeLeavingMapping) => {
																return (
																	<TableRow
																		hover={true}
																		key={leave?.id}
																		className={style.container}
																	>
																		<CustomTableCell
																			className={styles.dataActionContainer}
																		>
																			{leave?.leaveStatus === 'Approved' ? (
																				<CheckCircleIcon
																					className={styles.approvedIcon}
																				/>
																			) : (
																				<Checkbox
																					className={styles.checkBox}
																					name='checkedB'
																					color='primary'
																					disabled={
																						leave.leaveStatus === 'Rejected'
																							? true
																							: false
																					}
																					checked={leaveSubmittedId?.includes(
																						leave?.id
																					)}
																					onChange={(event: any) => {
																						const record = [
																							...leaveSubmittedId,
																							leave?.id,
																						];
																						if (event?.currentTarget.checked) {
																							const records =
																								user?.employee_leaving_mappings
																									?.filter(
																										(value: any) =>
																											value.leaveStatus ===
																											'Pending'
																									)
																									.map((id: any) => id?.id);
																							setLeaveSubmittedId([
																								...leaveSubmittedId,
																								leave?.id,
																							]);
																							if (
																								records?.length ===
																								record?.length
																							) {
																								setSelectedUserId(
																									user?.user?.id
																								);
																							}
																						} else {
																							setSelectedUserId([]);
																							setLeaveSubmittedId(
																								leaveSubmittedId?.filter(
																									(id: any) => id !== leave?.id
																								)
																							);
																						}
																					}}
																				/>
																			)}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${leave?.reason || '- -'}`}
																				placement='right'
																			>
																				<Box
																					className={`${styles.reason} ${ellipsisStyles.ellipsis}`}
																				>
																					{leave?.reason}
																				</Box>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<div className={styles.dateContainer}>
																				<DateRangeIcon
																					className={styles.calendarIcon}
																				/>
																				{`${dayjs(leave?.fromDate).format(
																					'DD MMM YYYY'
																				)}-${dayjs(leave?.toDate).format(
																					'DD MMM YYYY'
																				)}`}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell>
																			<div
																				className={`${styles.statusContainer} ${
																					leave?.leaveStatus === 'Pending'
																						? styles.submittedStatus
																						: leave?.leaveStatus === 'Approved'
																						? styles.approvedStatus
																						: styles?.rejectedStatus
																				}`}
																			>
																				{leave?.leaveStatus}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell>
																			{leave?.leaveCount}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${leave?.leaveType || '- -'}`}
																				placement='right'
																			>
																				<Box
																					className={`${styles.leaveTYpe} ${ellipsisStyles.ellipsis}`}
																				>
																					{leave?.leaveType}
																				</Box>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			{leave?.leaveStatusValue !== 'approved' &&
																			leave?.leaveStatusValue !== 'rejected' ? (
																				<Box
																					className={
																						style.listEditIconContainer
																					}
																				>
																					<Tooltip
																						//title={'Edit' || ''}
																						title={'Edit'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								style.listEditIconButton
																							}
																							disabled={!enableLeave}
																						>
																							<EditIcon
																								className={
																									datagridStyles.editIcon
																								}
																								onClick={() => {
																									setIsEditModalOpen(true);
																									handleEditLeave(leave);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>

																					<Tooltip
																						title={'Delete'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								style.deleteIconContainer
																							}
																							disabled={!enableLeave}
																						>
																							<DeleteIcon
																								className={
																									datagridStyles.deleteIcon
																								}
																								onClick={() => {
																									setIsWarningModalShown(true);
																									setSelectedLeaveIdForDelete(
																										leave?.id
																									);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>
																				</Box>
																			) : leave?.leaveStatusValue ===
																			  'approved' ? (
																				<Box
																					className={
																						style.listEditIconContainer
																					}
																				>
																					<Tooltip
																						title={'Delete'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								style.deleteIconContainer
																							}
																							onClick={() => {
																								setIsWarningModalShown(true);
																								setSelectedLeaveIdForDelete(
																									leave?.id
																								);
																							}}
																							disabled={!enableLeave}
																						>
																							<DeleteIcon
																								className={
																									datagridStyles.deleteIcon
																								}
																								onClick={() => {
																									setIsWarningModalShown(true);
																									setSelectedLeaveIdForDelete(
																										leave?.id
																									);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>
																				</Box>
																			) : (
																				<Box
																					className={
																						style.listEditIconContainer
																					}
																				></Box>
																			)}
																		</CustomTableCell>
																	</TableRow>
																);
															}
														)}
												</TableBody>
											</Table>
										</div>
									);
								})}
							</div>
						</div>
					) : (
						<Box textAlign='center'>
							<img src={no_data_found} alt='no_data_found' />
						</Box>
					)}
				</Box>
			)}
			<ApproveRejectModal
				open={isApproveRejectModalOpen}
				onClose={() => {
					setIsApproveRejectModalOpen(false);
					setIsApproveModalOpen(false);
					setIsApproveModalOpen(false);
				}}
				submit={isApproveModalOpen ? handleApprove : handleReject}
				leaveSubmittedId={leaveSubmittedId}
				message={
					isApproveModalOpen ? APPROVE_LEAVE_MESSAGE : REJECT_LEAVE_MESSAGE
				}
				loader={approveLeaveLoader}
				isApproveModalOpen={isApproveModalOpen || false}
			/>
			<ApplyLeaveForm
				open={isEditModalOpen}
				onClose={() => {
					setIsEditModalOpen(false);
					setSelectedLeaveDetails(null);
				}}
				userId={userId}
				userLocationId={userLocationId}
				currentFinancialYear={currentLeaveYear}
				refetchLeaveBalance={() => refetchPendingApproval()}
				editFormValues={selectedLeaveDetails}
				isEdit={true}
			/>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={() => setIsWarningModalShown(false)}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => handleDeleteLeave()}
				isLoading={handleDeleteLeaveLoader}
			/>
		</>
	);
};

export default PendingApproval;
