import React, { useEffect, useState, useContext } from 'react';

import {
	Box,
	Card,
	CardContent,
	ThemeProvider,
	TextField as TextComponent,
	Table,
	TableBody,
	TableHead,
	TableRow,
	createStyles,
	TableCell,
	withStyles,
	TableContainer,
	Typography,
	Theme,
	makeStyles,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { Form } from 'react-final-form';
import { Loading, SelectInput } from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';

import {
	useGetDraftAndUnSubmittedTimesheetEnteredResourcesQuery,
	useGetDraftAndUnSubmittedTimesheetForTaskWiseQuery,
	useGetGeneralUnSubmittedTimesheetEnteredResourcesQuery,
	useGetGeneralDraftAndUnSubmittedTimesheetForTaskWiseQuery,
	useGetGeneralOvertimeQuery,
	useGetGeneralTimesheetQuery,
	useGetOvertimeByProjectIdQuery,
	useGetOvertimeProjectAssignedUsersQuery,
	useGetTimesheetByProjectIdQuery,
	useGetTimesheetDayTypeOptionsQuery,
	useGetTimesheetProjectAssignedUsersQuery,
	useGetTimesheetStatusQuery,
} from '../../../generated/graphql';
import TablePagination from '@material-ui/core/TablePagination';
import { head, isEmpty, orderBy, uniqBy } from 'lodash';
import {
	formatEmployeeId,
	exportToCsv,
	formatDecimal,
	getPrefix,
	isFloat,
} from '../../../Utils/string.util';
import {
	timeSheetExportColumns,
	timeSheetExportColumnsWithoutLocation,
	uuidValidator,
} from '../constant';
import {
	autocompleteStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import dayjs from 'dayjs';
import { UserProfileContext } from '../../../App';
import {
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../../ResourcePlanner/gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SearchIcon } from '../../../assets/SvgIcons';
import ExportButtonWithDropDown from '../../../SharedComponents/ExportButton/ExportButtonWithDropDown';
import { exportTimesheetReportUsersOptions } from '../../../config/constant';
const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	exportButton: {
		marginTop: '22px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
	},
	exportButtonDiv: {
		marginTop: '23px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	dataCell: {
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	autoCompleteInputContainer: {
		minWidth: '170px',
		marginRight: '8px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	overflowStyle: {
		overflow: 'auto',
	},
}));
interface Props {
	projectId: string[];
	startDate: string;
	endDate: string;
	choosedResourceId: (value: string[] | null) => void;
	billableType: (value: string | null) => void;
	taskType: (value: string | null) => void;
	dayType: (value: string[] | null) => void;
	choosedTimesheetStatus: (value: string[]) => void;
}
interface Resource {
	id: string;
	name: string;
}

export interface User {
	first_name?: string | null;
	last_name?: string | null;
	id?: string;
}
export interface TeamMember {
	userByEmployeeId?: User;
}
export interface ProjectTask {
	title?: string;
	id?: string;
}

export interface TimesheetStatus {
	label?: string;
	id?: string;
}

export interface TimesheetSubmission {
	timesheet_status?: TimesheetStatus;
}

export interface Currency {
	symbol?: string | null;
}

export interface Project {
	currency?: Currency | null;
}

export interface ProjectResourceMapping {
	id?: string;
	resource_cost?: number;
	project?: Project;
}

export interface UserByEmployeeId {
	full_name?: string | null;
	project_resource_mappings?: ProjectResourceMapping[];
}
export interface Project {
	name?: string;
}
export interface TimeSheet {
	id?: string;
	task_id?: string;
	is_billable?: boolean;
	working_hours?: string;
	date?: string;
	project_task?: ProjectTask | null;
	timesheet_submission?: TimesheetSubmission | null;
	userByEmployeeId?: UserByEmployeeId | null;
	project?: Project;
}
interface TimesheetTableList {
	reportToEmail?: string | null | undefined;
	reportTo?: string | null | undefined;
	department?: string | null | undefined;
	workingDuration?: any;
	projectName?: string;
	projectStartDate?: string;
	projectEndDate?: string;
	projectOwner?: string;
	projectOwnerEmail?: string;
	projectStatus?: string;
	taskName?: string;
	user?: string | null | undefined;
	workingHours?: string;
	date?: string;
	timesheetStatus?: string;
	resourceCost?: string;
	totalCost?: string;
	id?: any;
	userId?: any;
	notes?: string;
	taskCompletion?: string | number;
	org_location: any;
	empId: string | null | undefined;
	employeeType: string | null | undefined;
	email: string | null | undefined;
	isUserActive?: boolean;
}

export const TimesheetTaskWiseReport = (props: Props) => {
	const {
		projectId,
		startDate,
		endDate,
		choosedResourceId,
		billableType,
		taskType,
		dayType,
		choosedTimesheetStatus,
	} = props;
	const {
		isOvertimeEnabled,
		featureFlag,
		dateFormat,
		noOfLocations,
		isTrackDayTypeEnabled,
		isDraftTimesheetEnabled,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);
	const [billableStatus, setBillableStatus] = useState<boolean | string>('All');
	const [timesheetType, setTimesheetType] = useState<string>('All');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const ellipsis = ellipsisStyle();
	const classes = useStyles();
	const styles = modalFormStyle();
	const [projectIdList, setProjectIdList] = useState<any>();
	const [timeSheetTableList, setTimeSheetTableList] = useState<
		TimesheetTableList[]
	>([]);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<any[]>(
		[]
	);
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		string[]
	>([]);
	const [dayTypeOptions, setDayTypeOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [selectedDayTypeOptionValue, setSelectedDayTypeOptionValues] =
		useState<any>([]);
	const [selectedDayTypeIdList, setSelectedDayTypeIdList] = useState<string[]>(
		[]
	);
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [
		selectedTimesheetStatusOptionValue,
		setSelectedTimesheetStatusOptionValues,
	] = useState<any>([]);
	const [selectedTimesheetStatusIdList, setSelectedTimesheetStatusIdList] =
		useState<string[]>([]);
	const [taskSearchText, setTaskSearchText] = useState<string | null>('');

	const dropDownStyle = autocompleteStyle();

	useEffect(() => {
		if (!projectId) {
			return;
		}
		if (projectId && projectId?.length === 0) {
			setUserTeamId(null);
			setSelectedUserTeam(null);
			setSelectedResourceIdList([]);
			setSelectedResourceOptionValues([]);
		}
		setSelectedResourceIdList([]);
		setSelectedResourceOptionValues([]);
		const idList = projectId?.filter((item) => uuidValidator.test(item));
		setProjectIdList(idList);
	}, [projectId]);

	const getDayTypePayload: any = () => {
		if (selectedDayTypeIdList && selectedDayTypeIdList?.length > 0) {
			const selectedOptionList =
				selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length > 0
					? selectedDayTypeOptionValue?.map(
							(option: { id: string }) => option?.id
					  )
					: [];
			if (selectedOptionList?.includes('All')) {
				return {};
			} else return { _in: selectedDayTypeIdList };
		}
		return {};
	};

	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const getTimesheetStatusFilter = () => {
		if (!isDraftTimesheetEnabled) {
			return { value: { _in: ['submitted', 'approved'] } };
		}
		if (isEmpty(selectedTimesheetStatusOptionValue)) {
			return {};
		}
		const currentStatusOptions = selectedTimesheetStatusOptionValue?.map(
			(option: { id: string }) => option.id
		);
		if (currentStatusOptions.includes('All')) {
			return {};
		}
		return { value: { _in: currentStatusOptions } };
	};

	const { data: timeSheetList, loading: isTimesheetListLoading } =
		useGetTimesheetByProjectIdQuery({
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					timesheet_submission: {
						timesheet_status: getTimesheetStatusFilter(),
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					day_type_id: getDayTypePayload(),
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				projectId: projectIdList,
			},
		});

	const { data: generalTimeSheet, loading: isGeneralTimeSheetLoading } =
		useGetGeneralTimesheetQuery({
			variables: {
				filters: {
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: getTimesheetStatusFilter(),
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
			},
		});

	const { data: assigneeList } = useGetTimesheetProjectAssignedUsersQuery({
		variables: {
			filters: {
				project_id: { _in: projectIdList },
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				timesheet_submission: {
					timesheet_status: getTimesheetStatusFilter(),
				},
			},
		},
	});

	const { data: generalTaskAssigneeList } =
		useGetTimesheetProjectAssignedUsersQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission: {
						timesheet_status: getTimesheetStatusFilter(),
					},
				},
			},
		});

	const { data: overtimeAssigneeList } =
		useGetOvertimeProjectAssignedUsersQuery({
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_overtime_submission: {
						timesheet_status: { value: { _in: ['submitted', 'approved'] } },
					},
				},
			},
		});

	const { data: generalOvertimeTaskAssigneeList } =
		useGetOvertimeProjectAssignedUsersQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_overtime_submission: {
						timesheet_status: { value: { _in: ['submitted', 'approved'] } },
					},
				},
			},
		});

	const { data: overtimeList } = useGetOvertimeByProjectIdQuery({
		variables: {
			filters: {
				_or: [
					{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
					{ master_project_task: { title: { _ilike: `%${taskSearchText}%` } } },
					{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
				],
				project_id: { _in: projectIdList },
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				day_type_id: getDayTypePayload(),
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
			},
			projectId: projectIdList,
			projectResourceMappingFilter: {
				project_id: { _in: projectIdList },
				deleted_at: { _is_null: true },
				employee_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
			},
		},
		skip: !isOvertimeEnabled,
	});

	const { data: generalOvertime } = useGetGeneralOvertimeQuery({
		variables: {
			filters: {
				_or: [
					{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
					{ master_project_task: { title: { _ilike: `%${taskSearchText}%` } } },
					{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
				],
				org_task: {
					task_category: { _in: projectId || [] },
				},
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
				day_type_id: getDayTypePayload(),
			},
		},
	});
	//For draft status
	//Query to get unsaved and daft status timesheet entries
	const { data: draftAndUnSubmittedTimeSheetList } =
		useGetDraftAndUnSubmittedTimesheetForTaskWiseQuery({
			variables: {
				filters: {
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					project_id: { _in: projectIdList },
					timesheet_submission: {
						timesheet_status: {
							value: { _in: ['draft'] },
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					day_type_id: getDayTypePayload(),
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				draftFilters: {
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					project_id: { _in: projectIdList },
					timesheet_submission_id: {
						_is_null: true,
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					day_type_id: getDayTypePayload(),
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				projectId: projectIdList,
			},
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});
	//Query to get general unsaved and daft status timesheet entries
	const { data: generalDraftAndUnSubmittedTimeSheetList } =
		useGetGeneralDraftAndUnSubmittedTimesheetForTaskWiseQuery({
			variables: {
				filters: {
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission_id: {
						_is_null: true,
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
				draftFilters: {
					_or: [
						{ project_task: { title: { _ilike: `%${taskSearchText}%` } } },
						{
							master_project_task: { title: { _ilike: `%${taskSearchText}%` } },
						},
						{ org_task: { title: { _ilike: `%${taskSearchText}%` } } },
					],
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: {
							value: { _in: ['draft'] },
						},
					},
					user_id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: {},
					day_type_id: getDayTypePayload(),
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					is_billable:
						billableStatus === 'All'
							? {}
							: billableStatus === 'true'
							? { _eq: true }
							: billableStatus === 'false'
							? { _eq: false }
							: {},
				},
			},
			skip:
				selectedTimesheetStatusIdList &&
				selectedTimesheetStatusIdList.length > 0 &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});

	const { data: dayTypeOptionList } = useGetTimesheetDayTypeOptionsQuery({
		fetchPolicy: 'network-only',
		skip: !isTrackDayTypeEnabled,
	});

	const { data: draftAndUnSubmittedTimesheetStatusResourceList } =
		useGetDraftAndUnSubmittedTimesheetEnteredResourcesQuery({
			variables: {
				filters: {
					project_id: { _in: projectIdList },
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission_id: {
						_is_null: true,
					},
				},
				draftFilters: {
					project_id: { _in: projectIdList },
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission: {
						timesheet_status: { value: { _in: ['draft'] } },
					},
				},
			},
			skip:
				selectedTimesheetStatusIdList &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});
	const { data: generalUnSubmittedTimesheetStatusResourceList } =
		useGetGeneralUnSubmittedTimesheetEnteredResourcesQuery({
			variables: {
				generalFilters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission_id: {
						_is_null: true,
					},
				},
			},
			skip:
				selectedTimesheetStatusIdList &&
				!selectedTimesheetStatusIdList.includes('draft'),
		});

	useEffect(() => {
		if (!timesheetStatus) {
			return;
		}
		const statusList = timesheetStatus?.timesheet_status.map((timesheet) => {
			return {
				id: timesheet.value,
				name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
			};
		});
		setSelectedTimesheetStatusOptionValues([
			{
				id: 'submitted',
				name: 'Pending',
			},
			{
				id: 'approved',
				name: 'approved',
			},
		]);
		setSelectedTimesheetStatusIdList(['submitted', 'approved']);
		const sortedStatusList = orderBy(
			statusList,
			[(status) => status.name?.toUpperCase()],
			['asc']
		);
		setTimesheetStatusOptions(sortedStatusList);
	}, [timesheetStatus]);

	useEffect(() => {
		if (!dayTypeOptionList) {
			return;
		}
		const dayTypeList = dayTypeOptionList?.timesheet_day_type;
		const sortedAssigneeList = orderBy(
			dayTypeList,
			[(type) => type.name?.toUpperCase()],
			['asc']
		);
		setDayTypeOptions(sortedAssigneeList);
	}, [dayTypeOptionList]);
	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	useEffect(() => {
		if (!assigneeList || !generalTaskAssigneeList) {
			return;
		}
		if (selectedUserTeam) {
			const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
				(user: { user: { id: string; full_name: string } }) => {
					return {
						id: user?.user?.id,
						name: user?.user?.full_name,
					};
				}
			);
			const sortedAssigneeList = orderBy(
				useTeamAssignees,
				[(user) => user.name?.toUpperCase()],
				['asc']
			);
			setAssigneeRecommendations(sortedAssigneeList);
			return;
		}
		const draftStatusResourceList =
			draftAndUnSubmittedTimesheetStatusResourceList?.draftStatusResourceList
				? draftAndUnSubmittedTimesheetStatusResourceList?.draftStatusResourceList.map(
						(timesheet) => ({
							id: timesheet.userByEmployeeId.id,
							name: timesheet.userByEmployeeId.full_name,
						})
				  )
				: [];
		const unSubmittedStatusResourceList =
			draftAndUnSubmittedTimesheetStatusResourceList?.unSubmittedResourceList
				? draftAndUnSubmittedTimesheetStatusResourceList?.unSubmittedResourceList.map(
						(timesheet) => ({
							id: timesheet.userByEmployeeId.id,
							name: timesheet.userByEmployeeId.full_name,
						})
				  )
				: [];
		const generalUnSubmittedStatusResourceList =
			generalUnSubmittedTimesheetStatusResourceList?.generalUnSubmittedResourceList
				? generalUnSubmittedTimesheetStatusResourceList?.generalUnSubmittedResourceList.map(
						(timesheet) => ({
							id: timesheet.userByEmployeeId.id,
							name: timesheet.userByEmployeeId.full_name,
						})
				  )
				: [];
		const projectTaskAssigneeList = assigneeList.timesheet.map((timesheet) => ({
			id: timesheet.userByEmployeeId.id,
			name: timesheet.userByEmployeeId.full_name,
		}));
		const overtimeProjectTaskAssigneeList =
			isOvertimeEnabled && overtimeAssigneeList
				? overtimeAssigneeList?.timesheet_overtime?.map((timesheet) => ({
						id: timesheet.userByEmployeeId.id,
						name: timesheet.userByEmployeeId.full_name,
				  }))
				: [];

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const orgTaskOvertimeAssigneeList =
			isOvertimeEnabled && generalOvertimeTaskAssigneeList
				? generalOvertimeTaskAssigneeList?.timesheet_overtime?.map(
						(timesheet) => ({
							id: timesheet.userByEmployeeId.id,
							name: timesheet.userByEmployeeId.full_name || '',
						})
				  )
				: [];
		const uniqueAssignees = uniqBy(
			isOvertimeEnabled
				? [
						...projectTaskAssigneeList,
						...orgTaskAssigneeList,
						...orgTaskOvertimeAssigneeList,
						...overtimeProjectTaskAssigneeList,
						...draftStatusResourceList,
						...unSubmittedStatusResourceList,
						...generalUnSubmittedStatusResourceList,
				  ]
				: [
						...projectTaskAssigneeList,
						...orgTaskAssigneeList,
						...draftStatusResourceList,
						...unSubmittedStatusResourceList,
						...generalUnSubmittedStatusResourceList,
				  ],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [
		assigneeList,
		draftAndUnSubmittedTimesheetStatusResourceList,
		generalOvertimeTaskAssigneeList,
		generalTaskAssigneeList,
		generalUnSubmittedTimesheetStatusResourceList,
		isOvertimeEnabled,
		overtimeAssigneeList,
		selectedUserTeam,
		usersByTeam,
	]);

	useEffect(() => {
		if (!timeSheetList || !generalTimeSheet) {
			return;
		}
		const timeSheetTableData = timeSheetList?.timesheet?.map((timeSheet) => {
			const existingEmployeeId = timeSheet?.userByEmployeeId?.employee?.emp_id
				? timeSheet?.userByEmployeeId?.employee?.emp_id
				: '--';
			const existingContractorId = head(
				timeSheet?.userByEmployeeId?.contractors
			)?.contractor_id
				? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
				: '--';

			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const employeeId =
				timeSheet?.userByEmployeeId?.user_type === 'employee'
					? timeSheet?.userByEmployeeId?.employee?.emp_id
						? `${
								getPrefix(
									timeSheet?.userByEmployeeId?.employee?.employee_prefix?.name,
									timeSheet?.userByEmployeeId?.employee?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId || ''}`
						: ''
					: head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(timeSheet?.userByEmployeeId?.contractors)
									?.contractor_prefix?.name,
								head(timeSheet?.userByEmployeeId?.contractors)
									?.contractor_prefix?.value
							) || ''
					  }${
							timeSheet?.userByEmployeeId?.contractors &&
							head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '';
			return {
				id: timeSheet.id,
				isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
				projectName: timeSheet?.project?.name,
				projectStartDate: timeSheet?.project?.start_date
					? dayjs(timeSheet?.project?.start_date).format(dateFormat)
					: '--',
				projectEndDate: timeSheet?.project?.end_date
					? dayjs(timeSheet?.project?.end_date).format(dateFormat)
					: '--',
				projectStatus: timeSheet?.project?.ProjectStatus?.label,
				projectOwner: timeSheet?.project?.user?.full_name,
				projectOwnerEmail: timeSheet?.project?.user?.email,
				taskName: getTaskNameByProjectType(timeSheet) || '',
				user: timeSheet.userByEmployeeId?.full_name,
				taskCompletion: timeSheet?.project_task
					? formatDecimal(timeSheet?.project_task?.completion_percentage, 1)
					: '0',
				userId: timeSheet.userByEmployeeId.id,
				notes: timeSheet?.notes || '',
				email: timeSheet?.userByEmployeeId?.email,
				employeeType: timeSheet?.userByEmployeeId?.user_type
					? timeSheet?.userByEmployeeId?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--',
				empId: employeeId,
				reportTo:
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
						: timeSheet?.userByEmployeeId?.contractors
						? head(timeSheet.userByEmployeeId.contractors)?.reportee?.full_name
						: null,
				reportToEmail:
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
						: timeSheet?.userByEmployeeId?.contractors
						? head(timeSheet.userByEmployeeId.contractors)?.reportee?.email
						: null,
				department:
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.department?.name
						: timeSheet?.userByEmployeeId?.contractors
						? head(timeSheet.userByEmployeeId.contractors)?.department?.name
						: null,

				org_location:
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.org_location?.name
						: head(timeSheet?.userByEmployeeId?.contractors)?.org_location
								?.name,

				workingHours: String(timeSheet?.working_hours).slice(0, 5),
				workingDuration:
					dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') || null,
				date: timeSheet?.date,
				timesheetStatus:
					timeSheet?.timesheet_submission?.timesheet_status?.label || '--',
				resourceCost: `${
					timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
						.aggregate?.max?.resource_cost
						? timeSheet?.project?.currency?.symbol
							? timeSheet?.project?.currency?.symbol
							: ''
						: ''
				}${
					isFloat(
						timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
							.aggregate?.max?.resource_cost
					)
						? Number(
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
						  ).toFixed(1)
						: timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
								.aggregate?.max?.resource_cost || '--'
				}`,
				totalCost: `${
					timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
						.aggregate?.max?.resource_cost
						? timeSheet?.project?.currency?.symbol
							? timeSheet?.project?.currency?.symbol
							: ''
						: ''
				}${
					timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
						.aggregate?.max?.resource_cost
						? getTotalCost(
								String(timeSheet?.working_hours).slice(0, 5),
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
						  )
						: '--'
				}`,
			};
		});
		const generalTimesheetData = generalTimeSheet?.org_tasks.map((task) => {
			const timeSheetData = task?.timesheets?.map((timeSheet) => {
				const existingEmployeeId = timeSheet?.userByEmployeeId?.employee?.emp_id
					? timeSheet?.userByEmployeeId?.employee?.emp_id
					: '--';
				const existingContractorId = head(
					timeSheet?.userByEmployeeId?.contractors
				)?.contractor_id
					? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
					: '--';

				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const employeeId =
					timeSheet?.userByEmployeeId?.user_type === 'employee'
						? timeSheet?.userByEmployeeId?.employee?.emp_id
							? `${
									getPrefix(
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.name,
										timeSheet?.userByEmployeeId?.employee?.employee_prefix
											?.value
									) || ''
							  }${formattedEmployeeId || ''}`
							: ''
						: head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.name,
									head(timeSheet?.userByEmployeeId?.contractors)
										?.contractor_prefix?.value
								) || ''
						  }${
								timeSheet?.userByEmployeeId?.contractors &&
								head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '';
				return {
					id: timeSheet.id,
					isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
					projectName: task.task_category || '',
					taskName: task.title || '',
					user: timeSheet.userByEmployeeId?.full_name,
					userId: timeSheet.userByEmployeeId.id,
					date: timeSheet?.date,
					notes: timeSheet?.notes || '',
					email: timeSheet?.userByEmployeeId?.email,
					employeeType: timeSheet?.userByEmployeeId?.user_type
						? timeSheet?.userByEmployeeId?.user_type === 'employee'
							? 'Employee'
							: 'Consultant'
						: '--',
					empId: employeeId,
					reportTo:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet.userByEmployeeId.contractors)?.reportee
									?.full_name
							: null,
					reportToEmail:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet.userByEmployeeId.contractors)?.reportee?.email
							: null,
					department:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId?.employee?.department?.name
							: timeSheet?.userByEmployeeId?.contractors
							? head(timeSheet.userByEmployeeId.contractors)?.department?.name
							: null,

					org_location:
						timeSheet?.userByEmployeeId?.user_type === 'employee'
							? timeSheet?.userByEmployeeId.employee?.org_location?.name
							: head(timeSheet?.userByEmployeeId.contractors)?.org_location
									?.name || '--',

					taskCompletion: '0',
					workingHours: String(timeSheet?.working_hours).slice(0, 5),
					workingDuration:
						dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') || null,
					timesheetStatus:
						timeSheet?.timesheet_submission?.timesheet_status?.label || '--',
					resourceCost: `--`,
					totalCost: `--`,
				};
			});
			return timeSheetData;
		});
		const overtimeData =
			isOvertimeEnabled && overtimeList
				? overtimeList?.timesheet_overtime?.map((timeSheet) => {
						const existingEmployeeId = timeSheet?.userByEmployeeId?.employee
							?.emp_id
							? timeSheet?.userByEmployeeId?.employee?.emp_id
							: '--';
						const existingContractorId = head(
							timeSheet?.userByEmployeeId?.contractors
						)?.contractor_id
							? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
							: '--';

						const formattedContractorId = formatEmployeeId(
							existingContractorId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const formattedEmployeeId = formatEmployeeId(
							existingEmployeeId,
							empIdLength,
							isFixedEmpIdLengthEnabled
						);

						const employeeId =
							timeSheet?.userByEmployeeId?.user_type === 'employee'
								? timeSheet?.userByEmployeeId?.employee?.emp_id
									? `${
											getPrefix(
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.name,
												timeSheet?.userByEmployeeId?.employee?.employee_prefix
													?.value
											) || ''
									  }${formattedEmployeeId || ''}`
									: ''
								: head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								? `${
										getPrefix(
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.name,
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_prefix?.value
										) || ''
								  }${
										timeSheet?.userByEmployeeId?.contractors &&
										head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
											? formattedContractorId
											: ''
								  }`
								: '';
						return {
							id: timeSheet.id,
							isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
							projectName: timeSheet?.project?.name,
							projectId: timeSheet?.project?.id,
							projectStartDate: timeSheet?.project?.start_date
								? dayjs(timeSheet?.project?.start_date).format(dateFormat)
								: '--',
							projectEndDate: timeSheet?.project?.end_date
								? dayjs(timeSheet?.project?.end_date).format(dateFormat)
								: '--',
							projectStatus: timeSheet?.project?.ProjectStatus?.label,
							projectOwner: timeSheet?.project?.user?.full_name,
							projectOwnerEmail: timeSheet?.project?.user?.email,
							taskName: getTaskNameByProjectType(timeSheet) || '',
							user: timeSheet.userByEmployeeId?.full_name,
							userId: timeSheet.userByEmployeeId.id,
							notes: timeSheet?.notes || '',
							email: timeSheet?.userByEmployeeId?.email,
							employeeType: timeSheet?.userByEmployeeId?.user_type
								? timeSheet?.userByEmployeeId?.user_type === 'employee'
									? 'Employee'
									: 'Consultant'
								: '--',
							empId: employeeId,
							reportTo:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.full_name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet.userByEmployeeId.contractors)?.reportee
											?.full_name
									: null,
							reportToEmail:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet.userByEmployeeId.contractors)?.reportee
											?.email
									: null,
							department:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.department?.name
									: timeSheet?.userByEmployeeId?.contractors
									? head(timeSheet.userByEmployeeId.contractors)?.department
											?.name
									: null,
							org_location:
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.org_location?.name
									: head(timeSheet?.userByEmployeeId?.contractors)?.org_location
											?.name || '--',

							taskCompletion: timeSheet?.project_task
								? formatDecimal(
										timeSheet?.project_task?.completion_percentage,
										1
								  )
								: '0',
							employeeId: timeSheet.userByEmployeeId?.employee?.id || null,
							workingHours: String(timeSheet?.working_hours).slice(0, 5),
							workingDuration:
								dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') ||
								null,
							date: timeSheet?.date,
							timesheetStatus:
								timeSheet?.timesheet_overtime_submission?.timesheet_status
									?.label || '--',
							resourceCost: timeSheet?.userByEmployeeId
								?.project_resource_mappings_aggregate.aggregate?.max
								?.resource_cost
								? `${
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
											? timeSheet?.project?.currency?.symbol
												? timeSheet?.project?.currency?.symbol
												: ''
											: ''
								  }${
										isFloat(
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
										)
											? Number(
													timeSheet?.userByEmployeeId
														?.project_resource_mappings_aggregate.aggregate?.max
														?.resource_cost
											  )?.toFixed(1)
											: timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost || ''
								  }`
								: '--',
							cost:
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost || '',
							totalCost: `${
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
									? timeSheet?.project?.currency?.symbol
										? timeSheet?.project?.currency?.symbol
										: ''
									: ''
							}${
								timeSheet?.userByEmployeeId?.project_resource_mappings_aggregate
									.aggregate?.max?.resource_cost
									? getTotalCost(
											String(timeSheet?.working_hours).slice(0, 5),
											timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost
									  )
									: '--'
							}`,
							currencySymbol: timeSheet?.project?.currency?.symbol
								? timeSheet?.project?.currency?.symbol
								: '' || '',
						};
				  })
				: [];
		const generalOvertimeData =
			isOvertimeEnabled && generalOvertime
				? generalOvertime?.org_tasks.map((task) => {
						const timeSheetData = task?.timesheet_overtime?.map((timeSheet) => {
							const existingEmployeeId = timeSheet?.userByEmployeeId?.employee
								?.emp_id
								? timeSheet?.userByEmployeeId?.employee?.emp_id
								: '--';
							const existingContractorId = head(
								timeSheet?.userByEmployeeId?.contractors
							)?.contractor_id
								? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.name,
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.name,
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.value
											) || ''
									  }${
											timeSheet?.userByEmployeeId?.contractors &&
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet.id,
								isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
								projectName: task.task_category || '',
								projectId: task.task_category || '',
								taskName: task.title || '',
								user: timeSheet.userByEmployeeId?.full_name,
								userId: timeSheet.userByEmployeeId.id,
								date: timeSheet?.date,
								email: timeSheet?.userByEmployeeId?.email,
								employeeType: timeSheet?.userByEmployeeId?.user_type
									? timeSheet?.userByEmployeeId?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo
												?.full_name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.full_name
										: null,
								reportToEmail:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.email
										: null,
								department:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.department?.name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.department
												?.name
										: null,
								org_location:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.org_location?.name
										: head(timeSheet?.userByEmployeeId?.contractors)
												?.org_location?.name || '--',

								employeeId: timeSheet.userByEmployeeId?.employee?.id || null,
								notes: timeSheet?.notes || '',
								taskCompletion: '0',
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								workingDuration:
									dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') ||
									null,
								timesheetStatus:
									timeSheet?.timesheet_overtime_submission?.timesheet_status
										?.label || '--',
								resourceCost: `--`,
								cost: 0,
								totalCost: `--`,
								currencySymbol: head(
									timeSheet?.userByEmployeeId?.project_resource_mappings
								)?.project?.currency?.symbol
									? head(timeSheet?.userByEmployeeId?.project_resource_mappings)
											?.project?.currency?.symbol
									: '',
							};
						});
						return timeSheetData;
				  })
				: [];
		//Get unsaved timesheet entries
		const unSavedTimeSheetEntries =
			draftAndUnSubmittedTimeSheetList?.savedTimesheetList
				? draftAndUnSubmittedTimeSheetList?.savedTimesheetList?.map(
						(timeSheet) => {
							const existingEmployeeId = timeSheet?.userByEmployeeId?.employee
								?.emp_id
								? timeSheet?.userByEmployeeId?.employee?.emp_id
								: '--';
							const existingContractorId = head(
								timeSheet?.userByEmployeeId?.contractors
							)?.contractor_id
								? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.name,
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.name,
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.value
											) || ''
									  }${
											timeSheet?.userByEmployeeId?.contractors &&
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet.id,
								isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
								projectName: timeSheet?.project?.name,
								taskName: getTaskNameByProjectType(timeSheet) || '',
								user: timeSheet.userByEmployeeId?.full_name,
								taskCompletion: timeSheet?.project_task
									? formatDecimal(
											timeSheet?.project_task?.completion_percentage,
											1
									  )
									: '0',
								userId: timeSheet.userByEmployeeId.id,
								notes: timeSheet?.notes || '',
								email: timeSheet?.userByEmployeeId?.email,
								employeeType: timeSheet?.userByEmployeeId?.user_type
									? timeSheet?.userByEmployeeId?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo
												?.full_name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.full_name
										: null,
								reportToEmail:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.email
										: null,
								department:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.department?.name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.department
												?.name
										: null,
								org_location:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.org_location?.name
										: head(timeSheet?.userByEmployeeId?.contractors)
												?.org_location?.name,

								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								workingDuration:
									dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') ||
									null,
								date: timeSheet?.date,
								timesheetStatus: 'Draft',
								resourceCost: `${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? timeSheet?.project?.currency?.symbol
											? timeSheet?.project?.currency?.symbol
											: ''
										: ''
								}${
									isFloat(
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
									)
										? Number(
												timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost
										  ).toFixed(1)
										: timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost || '--'
								}`,
								totalCost: `${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? timeSheet?.project?.currency?.symbol
											? timeSheet?.project?.currency?.symbol
											: ''
										: ''
								}${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? getTotalCost(
												String(timeSheet?.working_hours).slice(0, 5),
												timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost
										  )
										: '--'
								}`,
							};
						}
				  )
				: [];
		//Get draft status timesheet entries
		const draftStatusTimeSheetEntries =
			draftAndUnSubmittedTimeSheetList?.draftStatusTimesheetList
				? draftAndUnSubmittedTimeSheetList?.draftStatusTimesheetList?.map(
						(timeSheet) => {
							const existingEmployeeId = timeSheet?.userByEmployeeId?.employee
								?.emp_id
								? timeSheet?.userByEmployeeId?.employee?.emp_id
								: '--';
							const existingContractorId = head(
								timeSheet?.userByEmployeeId?.contractors
							)?.contractor_id
								? head(timeSheet?.userByEmployeeId?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.userByEmployeeId?.user_type === 'employee'
									? timeSheet?.userByEmployeeId?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.name,
													timeSheet?.userByEmployeeId?.employee?.employee_prefix
														?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.name,
												head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_prefix?.value
											) || ''
									  }${
											timeSheet?.userByEmployeeId?.contractors &&
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet.id,
								isUserActive: timeSheet?.userByEmployeeId?.is_active || false,
								projectName: timeSheet?.project?.name,
								projectStartDate: timeSheet?.project?.start_date
									? dayjs(timeSheet?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: timeSheet?.project?.end_date
									? dayjs(timeSheet?.project?.end_date).format(dateFormat)
									: '--',
								projectStatus: timeSheet?.project?.ProjectStatus?.label,
								projectOwner: timeSheet?.project?.user?.full_name,
								projectOwnerEmail: timeSheet?.project?.user?.email,
								taskName: getTaskNameByProjectType(timeSheet) || '',
								user: timeSheet.userByEmployeeId?.full_name,
								taskCompletion: timeSheet?.project_task
									? formatDecimal(
											timeSheet?.project_task?.completion_percentage,
											1
									  )
									: '0',
								userId: timeSheet.userByEmployeeId.id,
								notes: timeSheet?.notes || '',
								email: timeSheet?.userByEmployeeId?.email,
								employeeType: timeSheet?.userByEmployeeId?.user_type
									? timeSheet?.userByEmployeeId?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo
												?.full_name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.full_name
										: null,
								reportToEmail:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.reportsTo?.email
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.reportee
												?.email
										: null,
								department:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.department?.name
										: timeSheet?.userByEmployeeId?.contractors
										? head(timeSheet.userByEmployeeId.contractors)?.department
												?.name
										: null,
								org_location:
									timeSheet?.userByEmployeeId?.user_type === 'employee'
										? timeSheet?.userByEmployeeId?.employee?.org_location?.name
										: head(timeSheet?.userByEmployeeId?.contractors)
												?.org_location?.name,

								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								workingDuration:
									dayjs(timeSheet?.working_hours, 'HH:mm').format('HH:mm') ||
									null,
								date: timeSheet?.date,
								timesheetStatus:
									timeSheet?.timesheet_submission?.timesheet_status?.label ||
									'--',
								resourceCost: `${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? timeSheet?.project?.currency?.symbol
											? timeSheet?.project?.currency?.symbol
											: ''
										: ''
								}${
									isFloat(
										timeSheet?.userByEmployeeId
											?.project_resource_mappings_aggregate.aggregate?.max
											?.resource_cost
									)
										? Number(
												timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost
										  ).toFixed(1)
										: timeSheet?.userByEmployeeId
												?.project_resource_mappings_aggregate.aggregate?.max
												?.resource_cost || '--'
								}`,
								totalCost: `${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? timeSheet?.project?.currency?.symbol
											? timeSheet?.project?.currency?.symbol
											: ''
										: ''
								}${
									timeSheet?.userByEmployeeId
										?.project_resource_mappings_aggregate.aggregate?.max
										?.resource_cost
										? getTotalCost(
												String(timeSheet?.working_hours).slice(0, 5),
												timeSheet?.userByEmployeeId
													?.project_resource_mappings_aggregate.aggregate?.max
													?.resource_cost
										  )
										: '--'
								}`,
							};
						}
				  )
				: [];
		//Get general unsaved timesheet entries
		const generalUnSubmittedTimesheetEntries =
			generalDraftAndUnSubmittedTimeSheetList?.savedTimesheets
				? generalDraftAndUnSubmittedTimeSheetList?.savedTimesheets?.map(
						(task) => {
							const timeSheetEntries = task?.timesheets
								? task?.timesheets?.map((timeSheet) => {
										const existingEmployeeId = timeSheet?.userByEmployeeId
											?.employee?.emp_id
											? timeSheet?.userByEmployeeId?.employee?.emp_id
											: '--';
										const existingContractorId = head(
											timeSheet?.userByEmployeeId?.contractors
										)?.contractor_id
											? head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											: '--';

										const formattedContractorId = formatEmployeeId(
											existingContractorId,
											empIdLength,
											isFixedEmpIdLengthEnabled
										);

										const formattedEmployeeId = formatEmployeeId(
											existingEmployeeId,
											empIdLength,
											isFixedEmpIdLengthEnabled
										);

										const employeeId =
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.emp_id
													? `${
															getPrefix(
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.name,
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.value
															) || ''
													  }${formattedEmployeeId || ''}`
													: ''
												: head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
												? `${
														getPrefix(
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.name,
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.value
														) || ''
												  }${
														timeSheet?.userByEmployeeId?.contractors &&
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_id
															? formattedContractorId
															: ''
												  }`
												: '';
										return {
											id: timeSheet.id,
											isUserActive:
												timeSheet?.userByEmployeeId?.is_active || false,
											projectName: task.task_category || '',
											taskName: task.title || '',
											user: timeSheet.userByEmployeeId?.full_name,
											userId: timeSheet.userByEmployeeId.id,
											date: timeSheet?.date,
											notes: timeSheet?.notes || '',
											email: timeSheet?.userByEmployeeId?.email,
											employeeType: timeSheet?.userByEmployeeId?.user_type
												? timeSheet?.userByEmployeeId?.user_type === 'employee'
													? 'Employee'
													: 'Consultant'
												: '--',
											empId: employeeId,
											reportTo:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.full_name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.full_name
													: null,
											reportToEmail:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.email
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.email
													: null,
											department:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.department
															?.name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.department?.name
													: null,
											org_location:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId.employee?.org_location
															?.name
													: head(timeSheet?.userByEmployeeId.contractors)
															?.org_location?.name || '--',

											taskCompletion: '0',
											workingHours: String(timeSheet?.working_hours).slice(
												0,
												5
											),
											workingDuration:
												dayjs(timeSheet?.working_hours, 'HH:mm').format(
													'HH:mm'
												) || null,
											timesheetStatus: 'Draft',
											resourceCost: `--`,
											totalCost: `--`,
										};
								  })
								: [];
							return timeSheetEntries;
						}
				  )
				: [];
		//Get general draft status timesheet entries
		const generalDraftStatusTimesheetEntries =
			generalDraftAndUnSubmittedTimeSheetList?.draftStatusTimesheet
				? generalDraftAndUnSubmittedTimeSheetList?.draftStatusTimesheet?.map(
						(task) => {
							const timeSheetEntries = task?.timesheets
								? task?.timesheets?.map((timeSheet) => {
										const existingEmployeeId = timeSheet?.userByEmployeeId
											?.employee?.emp_id
											? timeSheet?.userByEmployeeId?.employee?.emp_id
											: '--';
										const existingContractorId = head(
											timeSheet?.userByEmployeeId?.contractors
										)?.contractor_id
											? head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											: '--';

										const formattedContractorId = formatEmployeeId(
											existingContractorId,
											empIdLength,
											isFixedEmpIdLengthEnabled
										);

										const formattedEmployeeId = formatEmployeeId(
											existingEmployeeId,
											empIdLength,
											isFixedEmpIdLengthEnabled
										);

										const employeeId =
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.emp_id
													? `${
															getPrefix(
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.name,
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.value
															) || ''
													  }${formattedEmployeeId || ''}`
													: ''
												: head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
												? `${
														getPrefix(
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.name,
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.value
														) || ''
												  }${
														timeSheet?.userByEmployeeId?.contractors &&
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_id
															? formattedContractorId
															: ''
												  }`
												: '';
										return {
											id: timeSheet.id,
											isUserActive:
												timeSheet?.userByEmployeeId?.is_active || false,
											projectName: task.task_category || '',
											taskName: task.title || '',
											user: timeSheet.userByEmployeeId?.full_name,
											userId: timeSheet.userByEmployeeId.id,
											date: timeSheet?.date,
											notes: timeSheet?.notes || '',
											email: timeSheet?.userByEmployeeId?.email,
											employeeType: timeSheet?.userByEmployeeId?.user_type
												? timeSheet?.userByEmployeeId?.user_type === 'employee'
													? 'Employee'
													: 'Consultant'
												: '--',
											empId: employeeId,
											reportTo:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.full_name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.full_name
													: null,
											reportToEmail:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.email
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.email
													: null,
											department:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.department
															?.name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.department?.name
													: null,
											org_location:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId.employee?.org_location
															?.name
													: head(timeSheet?.userByEmployeeId.contractors)
															?.org_location?.name || '--',

											taskCompletion: '0',
											workingHours: String(timeSheet?.working_hours).slice(
												0,
												5
											),
											workingDuration:
												dayjs(timeSheet?.working_hours, 'HH:mm').format(
													'HH:mm'
												) || null,
											timesheetStatus:
												timeSheet?.timesheet_submission?.timesheet_status
													?.label || '--',
											resourceCost: `--`,
											totalCost: `--`,
										};
								  })
								: [];
							return timeSheetEntries;
						}
				  )
				: [];
		const timesheetAndOvertimeList = isOvertimeEnabled
			? timesheetType === 'overtime'
				? [...overtimeData, ...generalOvertimeData?.flat()]
				: timesheetType === 'timesheet'
				? [
						...timeSheetTableData,
						...generalTimesheetData.flat(),
						...unSavedTimeSheetEntries,
						...draftStatusTimeSheetEntries,
						...generalDraftStatusTimesheetEntries.flat(),
						...generalUnSubmittedTimesheetEntries.flat(),
				  ]
				: [
						...timeSheetTableData,
						...generalTimesheetData.flat(),
						...overtimeData,
						...generalOvertimeData?.flat(),
						...unSavedTimeSheetEntries,
						...draftStatusTimeSheetEntries,
						...generalDraftStatusTimesheetEntries.flat(),
						...generalUnSubmittedTimesheetEntries.flat(),
				  ]
			: [
					...timeSheetTableData,
					...generalTimesheetData.flat(),
					...unSavedTimeSheetEntries,
					...draftStatusTimeSheetEntries,
					...generalDraftStatusTimesheetEntries.flat(),
					...generalUnSubmittedTimesheetEntries.flat(),
			  ];
		setTimeSheetTableList(
			orderBy(
				uniqBy(timesheetAndOvertimeList, 'id'),
				[(user) => user.user?.toUpperCase()],
				['asc']
			)
		);
	}, [
		draftAndUnSubmittedTimeSheetList,
		generalDraftAndUnSubmittedTimeSheetList,
		generalOvertime,
		generalTimeSheet,
		isOvertimeEnabled,
		overtimeList,
		timeSheetList,
		timesheetType,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		dateFormat,
	]);

	const getTotalCost = (hours: string, cost: number) => {
		const timesheetHour = hours.split(':');
		const totalWorkinghours = dayjs
			.duration({
				hours: Number(timesheetHour[0]),
				minutes: Number(timesheetHour[1]),
			})
			.asHours();
		const totalHours = Number(totalWorkinghours) * cost;
		return formatDecimal(totalHours, 1);
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = (exportMenuItemIndex: number = 0) => {
		if (timeSheetTableList?.length === 0) {
			return;
		}

		const filteredTimeSheetTableListForExport = timeSheetTableList?.filter(
			(timeSheet) => {
				if (exportMenuItemIndex === 0) {
					return true;
				}

				return exportMenuItemIndex === 1
					? timeSheet?.isUserActive
					: !timeSheet?.isUserActive;
			}
		);
		const timesheetRows: any = filteredTimeSheetTableListForExport?.map(
			(timeSheet: TimesheetTableList) => {
				const workingHoursToHours = String(timeSheet.workingHours)
					.slice(0, 5)
					.split(':');

				const exportWorkingHours = dayjs
					.duration({
						hours: Number(workingHoursToHours[0]),
						minutes: Number(workingHoursToHours[1]),
					})
					.asHours()
					.toFixed(2);

				return noOfLocations && noOfLocations > 0
					? [
							timeSheet?.empId || '--',
							timeSheet?.user,
							timeSheet?.email || '',
							timeSheet?.employeeType || '--',
							timeSheet?.org_location || '--',
							timeSheet?.reportTo || '--',
							timeSheet?.reportToEmail || '--',
							timeSheet?.department || '--',
							timeSheet?.projectName,
							timeSheet?.projectOwner || '--',
							timeSheet?.projectOwnerEmail || '--',
							timeSheet?.projectStatus || '--',
							timeSheet?.projectStartDate || '--',
							timeSheet?.projectEndDate || '--',
							timeSheet?.taskName,
							timeSheet?.date ? dayjs(timeSheet?.date).format(dateFormat) : '',
							exportWorkingHours,
							timeSheet?.workingDuration || '--',
							timeSheet?.timesheetStatus || '--',
							timeSheet?.resourceCost || '--',
							timeSheet?.totalCost || '--',
							timeSheet?.notes || '--',
					  ]
					: [
							timeSheet?.empId || '--',
							timeSheet?.user,
							timeSheet?.email || '',
							timeSheet?.employeeType || '--',
							timeSheet?.reportTo || '--',
							timeSheet?.reportToEmail || '--',
							timeSheet?.department || '--',
							timeSheet?.projectName,
							timeSheet?.projectOwner || '--',
							timeSheet?.projectOwnerEmail || '--',
							timeSheet?.projectStatus || '--',
							timeSheet?.projectStartDate || '--',
							timeSheet?.projectEndDate || '--',
							timeSheet?.taskName || '--',
							timeSheet?.date ? dayjs(timeSheet?.date).format(dateFormat) : '',
							exportWorkingHours,
							timeSheet?.workingDuration || '--',
							timeSheet?.timesheetStatus || '--',
							timeSheet?.resourceCost || '--',
							timeSheet?.totalCost || '--',
							timeSheet?.notes || '--',
					  ];
			}
		);
		const timeSheetExportData = [
			noOfLocations && noOfLocations > 0
				? timeSheetExportColumns
				: timeSheetExportColumnsWithoutLocation,
			...timesheetRows,
		];
		exportToCsv(
			`Timesheet_Task_Wise_Report_(${
				exportTimesheetReportUsersOptions[exportMenuItemIndex]
			})_From_${dayjs(startDate).format(dateFormat)}_To_${dayjs(endDate).format(
				dateFormat
			)}.csv`,
			timeSheetExportData
		);
	};

	const getTaskNameByProjectType = (item: any) => {
		if (!item) {
			return;
		}
		if (item?.project_task?.title) {
			return item?.project_task?.title;
		}
		if (item?.master_project_task?.title) {
			return item?.master_project_task?.title;
		}
	};

	const resetAssigneeFilterDropdown = () => {
		if (!assigneeList || !generalTaskAssigneeList) {
			return;
		}
		const projectTaskAssigneeList = assigneeList.timesheet.map((timesheet) => ({
			id: timesheet.userByEmployeeId.id,
			name: timesheet.userByEmployeeId.full_name,
		}));
		const overtimeProjectTaskAssigneeList =
			isOvertimeEnabled && overtimeAssigneeList
				? overtimeAssigneeList?.timesheet_overtime?.map((timesheet) => ({
						id: timesheet.userByEmployeeId.id,
						name: timesheet.userByEmployeeId.full_name,
				  }))
				: [];

		const orgTaskAssigneeList = generalTaskAssigneeList.timesheet.map(
			(timesheet) => ({
				id: timesheet.userByEmployeeId.id,
				name: timesheet.userByEmployeeId.full_name || '',
			})
		);
		const orgTaskOvertimeAssigneeList =
			isOvertimeEnabled && generalOvertimeTaskAssigneeList
				? generalOvertimeTaskAssigneeList?.timesheet_overtime?.map(
						(timesheet) => ({
							id: timesheet.userByEmployeeId.id,
							name: timesheet.userByEmployeeId.full_name || '',
						})
				  )
				: [];
		const uniqueAssignees = uniqBy(
			isOvertimeEnabled
				? [
						...projectTaskAssigneeList,
						...orgTaskAssigneeList,
						...orgTaskOvertimeAssigneeList,
						...overtimeProjectTaskAssigneeList,
				  ]
				: [...projectTaskAssigneeList, ...orgTaskAssigneeList],
			function (e) {
				return e.id;
			}
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getDayTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedDayTypeOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length === 1
					? selectedDayTypeOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getTimesheetStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedTimesheetStatusOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedTimesheetStatusOptionValue &&
				selectedTimesheetStatusOptionValue?.length === 1
					? selectedTimesheetStatusOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	return (
		<>
			<ThemeProvider theme={insightChartTheme}>
				<Card className={classes.overflowStyle}>
					<Box>
						<Form
							initialValues={{
								billable: billableStatus,
								timesheet_type: timesheetType,
							}}
							onSubmit={() => {}}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.header}>
											<Box display='flex'>
												<Box display='block' marginRight='8px'>
													<Box className={styles.label}>Search Task</Box>
													<Box>
														<ThemeProvider theme={searchBarTheme}>
															<Box width='150px'>
																<TextComponent
																	placeholder='Search Task'
																	label={false}
																	fullWidth
																	InputLabelProps={{ style: { fontSize: 0 } }}
																	InputProps={{
																		startAdornment: <SearchIcon />,
																		endAdornment: taskSearchText && (
																			<ClearIcon
																				className={classes.clearIcon}
																				onClick={() => {
																					setTaskSearchText('');
																					return;
																				}}
																			/>
																		),
																	}}
																	onChange={(event) => {
																		if (!event) {
																			setTaskSearchText(null);
																			return;
																		}
																		setTaskSearchText(event.target.value);
																	}}
																	value={taskSearchText}
																/>
															</Box>
														</ThemeProvider>
													</Box>
												</Box>
												{featureFlag?.userTeams && (
													<Box display='block'>
														<Box className={classes.autoCompleteInputContainer}>
															<Typography className={styles.label}>
																User Team
															</Typography>
															<AutocompleteSearch
																placeholder={'Search Team'}
																option={
																	userTeams && userTeams?.user_team
																		? userTeams?.user_team?.map(
																				(userTeam: {
																					id: string;
																					name: string;
																				}) => {
																					return {
																						id: userTeam?.id,
																						name: userTeam?.name,
																					};
																				}
																		  ) || []
																		: []
																}
																onChange={(id) => {
																	if (!id) {
																		resetAssigneeFilterDropdown();
																		setSelectedUserTeam(null);
																		setUserTeamId(null);
																		setSelectedResourceIdList([]);
																		setSelectedResourceOptionValues([]);
																		return;
																	}
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	setUserTeamId(id);
																	const currentTeam =
																		userTeams?.user_team?.find(
																			(team: {
																				id: string;
																				full_name: string;
																			}) => team?.id === id
																		);
																	setSelectedUserTeam(currentTeam);
																}}
																name={'team_id'}
																value={selectedUserTeam}
															/>
														</Box>
													</Box>
												)}

												<Box display='block' width='170px'>
													<Box className={styles.label}>Resource</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedResourceOptionValue &&
																selectedResourceOptionValue?.length > 0
																	? selectedResourceOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(resource: { id: string; name: string }) =>
																			resource.id !== 'All'
																	)
																	.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const allResourceIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(allResourceIds);
																	choosedResourceId(allResourceIds);
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	assigneeRecommendations?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(resource: { id: string; name: string }) =>
																			resource?.id !== 'All'
																	);
																	setSelectedResourceOptionValues(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource: { id: string; name: string }) =>
																			resource?.id
																	);
																	setSelectedResourceIdList(currentIds);
																	choosedResourceId(currentIds);
																	return;
																}
																if (
																	selectedIds?.length ===
																		assigneeRecommendations?.length &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...assigneeRecommendations,
																	]);
																	const reporteeIds =
																		assigneeRecommendations?.map(
																			(resource: any) => resource?.id
																		);
																	setSelectedResourceIdList(reporteeIds);
																	choosedResourceId(reporteeIds);
																	return;
																}
																if (
																	selectedResourceOptionValue.find(
																		(option: { id: string; name: string }) =>
																			option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	choosedResourceId([]);
																	return;
																}
																const selectedId = value?.map(
																	(resource) => resource?.id
																);
																setSelectedResourceIdList(selectedId);
																choosedResourceId(selectedId);
																setSelectedResourceOptionValues(value);
															}}
															options={
																assigneeRecommendations &&
																assigneeRecommendations?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...assigneeRecommendations,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getResourceFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent {...params} label='' />
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																			style={{ fontSize: '12px' }}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
												<Box display='block' marginLeft='5px'>
													<Box className={styles.label}>Billable</Box>
													<Box>
														<SelectInput
															source='billable'
															label=''
															choices={[
																{ id: 'All', name: 'All' },
																{ id: 'true', name: 'Billable' },
																{ id: 'false', name: 'Non-billable' },
															]}
															onChange={(e: any) => {
																setBillableStatus(e?.target?.value);
																billableType(e?.target?.value);
																setPage(0);
															}}
														/>
													</Box>
												</Box>
												{isOvertimeEnabled && (
													<Box display='block' marginLeft='5px'>
														<Box className={styles.label}>Task type</Box>
														<Box>
															<SelectInput
																key={timesheetType}
																source='timesheet_type'
																label=''
																choices={[
																	{ id: 'All', name: 'All' },
																	{ id: 'timesheet', name: 'Normal Time' },
																	{ id: 'overtime', name: 'Over Time' },
																]}
																onChange={(e: any) => {
																	setTimesheetType(e?.target?.value);
																	taskType(e?.target?.value);
																	setPage(0);
																}}
															/>
														</Box>
													</Box>
												)}
												{isTrackDayTypeEnabled && (
													<Box display='block' marginLeft='4px' width='180px'>
														<Box className={styles.label}>Day type</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedDayTypeOptionValue &&
																		selectedDayTypeOptionValue?.length > 0
																			? selectedDayTypeOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const allResourceIds =
																				dayTypeOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedDayTypeIdList(allResourceIds);
																			dayType([]);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			dayTypeOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedDayTypeOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedDayTypeIdList(currentIds);
																			dayType(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				dayTypeOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedDayTypeOptionValues([
																				{ id: 'All', name: 'All' },
																				...dayTypeOptions,
																			]);
																			const reporteeIds = dayTypeOptions?.map(
																				(resource: any) => resource?.id
																			);
																			setSelectedDayTypeIdList(reporteeIds);
																			dayType(reporteeIds);
																			return;
																		}
																		if (
																			selectedDayTypeOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedDayTypeIdList([]);
																			setSelectedDayTypeOptionValues([]);
																			dayType([]);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedDayTypeIdList(selectedId);
																		dayType(selectedId);
																		setSelectedDayTypeOptionValues(value);
																	}}
																	options={
																		dayTypeOptions && dayTypeOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...dayTypeOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getDayTypeFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
												{isDraftTimesheetEnabled && (
													<Box display='block' marginLeft='4px' width='170px'>
														<Box className={styles.label}>Timesheet status</Box>
														<Box>
															<form
																onSubmit={(event) => event.preventDefault()}
															>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedTimesheetStatusOptionValue &&
																		selectedTimesheetStatusOptionValue?.length >
																			0
																			? selectedTimesheetStatusOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource.id !== 'All'
																			)
																			.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const allResourceIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				allResourceIds
																			);
																			choosedTimesheetStatus(allResourceIds);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			timesheetStatusOptions?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id !== 'All'
																			);
																			setSelectedTimesheetStatusOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource: {
																					id: string;
																					name: string;
																				}) => resource?.id
																			);
																			setSelectedTimesheetStatusIdList(
																				currentIds
																			);
																			choosedTimesheetStatus(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				timesheetStatusOptions?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedTimesheetStatusOptionValues([
																				{ id: 'All', name: 'All' },
																				...timesheetStatusOptions,
																			]);
																			const reporteeIds =
																				timesheetStatusOptions?.map(
																					(resource: any) => resource?.id
																				);
																			setSelectedTimesheetStatusIdList(
																				reporteeIds
																			);
																			choosedTimesheetStatus(reporteeIds);
																			return;
																		}
																		if (
																			selectedTimesheetStatusOptionValue.find(
																				(option: {
																					id: string;
																					name: string;
																				}) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedTimesheetStatusIdList([]);
																			setSelectedTimesheetStatusOptionValues(
																				[]
																			);
																			choosedTimesheetStatus([]);
																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedTimesheetStatusIdList(
																			selectedId
																		);
																		setSelectedTimesheetStatusOptionValues(
																			value
																		);
																		choosedTimesheetStatus(selectedId);
																	}}
																	options={
																		timesheetStatusOptions &&
																		timesheetStatusOptions?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...timesheetStatusOptions,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={
																		getTimesheetStatusFilterLimits
																	}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Box
																				style={{ fontSize: '12px' }}
																			>{`${option?.name}`}</Box>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</form>
														</Box>
													</Box>
												)}
											</Box>
											<Box className={classes.exportButtonDiv}>
												<ExportButtonWithDropDown
													onChange={(index: number) => {
														generateCSV(index);
													}}
													isDisabled={!timeSheetTableList?.length}
													options={exportTimesheetReportUsersOptions}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
					{!isTimesheetListLoading && !isGeneralTimeSheetLoading ? (
						<CardContent>
							<Box className={classes.tableContainer}>
								{timeSheetTableList && timeSheetTableList?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell>PROJECT NAME</CustomTableCell>
													<CustomTableCell>TASK NAME</CustomTableCell>
													<CustomTableCell>RESOURCE</CustomTableCell>
													<CustomTableCell>TASK COMPLETION</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell>LOCATION</CustomTableCell>
													)}
													<CustomTableCell>DATE</CustomTableCell>
													<CustomTableCell>HOURS ENTERED</CustomTableCell>
													<CustomTableCell>APPROVAL STATUS</CustomTableCell>
													<CustomTableCell>RESOURCE COST</CustomTableCell>
													<CustomTableCell>TOTAL COST</CustomTableCell>
													<CustomTableCell>NOTES</CustomTableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{timeSheetTableList &&
													timeSheetTableList?.length > 0 &&
													timeSheetTableList
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map(
															(timeSheet) =>
																timeSheetTableList?.length > 0 && (
																	<TableRow hover={true} key={timeSheet?.id}>
																		<CustomTableCell>
																			<Tooltip
																				title={`${timeSheet?.projectName}`}
																			>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.projectName}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip title={`${timeSheet?.taskName}`}>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.taskName}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip title={`${timeSheet?.user}`}>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.user}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${timeSheet?.taskCompletion}`}
																			>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.taskCompletion || 0}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		{noOfLocations && noOfLocations > 0 && (
																			<CustomTableCell key={timeSheet?.id}>
																				<Tooltip
																					title={`${
																						timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'
																					}`}
																				>
																					<Typography
																						className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																					>
																						{timeSheet?.org_location
																							? timeSheet?.org_location
																							: '--'}
																					</Typography>
																				</Tooltip>
																			</CustomTableCell>
																		)}

																		<CustomTableCell>
																			{timeSheet?.date
																				? dayjs(timeSheet?.date).format(
																						dateFormat
																				  )
																				: ''}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.workingHours}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.timesheetStatus || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.resourceCost || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{timeSheet?.totalCost || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${timeSheet?.notes || ''}`}
																			>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.notes || ''}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																	</TableRow>
																)
														)}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												count={timeSheetTableList?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={classes.noData}>
										<Typography className={classes.noDataLabel}>
											No data found
										</Typography>
									</Box>
								)}
							</Box>
						</CardContent>
					) : (
						<Loading />
					)}
				</Card>
			</ThemeProvider>
		</>
	);
};
