import React, { useState, useEffect, createContext, useReducer } from 'react';
import buildHasuraProvider from 'ra-data-hasura';
import { Admin, Resource } from 'react-admin';
import { CompanyList } from './modules/Company/CompanyList.component';
import { ContactList } from './modules/Contacts/ContactList.Component';
import { EmployeeList } from './modules/Employees/EmployeeList.component';
import WithAuthentication from './Auth/WithAuthentication';
import authProvider from './Auth/authProvider';
import { DesignationList } from './modules/Designation/DesignationList.component';
import { CreateDesignation } from './modules/Designation/CreateDesignation.component';
import { EditDesignation } from './modules/Designation/EditDesignation.component';
import { DepartmentList } from './modules/Departments/DepartmentList.component';
import { EditDepartment } from './modules/Departments/EditDepartment.component';
import { CreateDepartment } from './modules/Departments/CreateDepartment';
import { ProjectList } from './modules/Projects/ProjectList.component';
import { EditProject } from './modules/Projects/EditProject.component';
import { CreateProject } from './modules/Projects/CreateProject.component';
import { EmployeeSkillMappingList } from './modules/EmployeeSkillMapping/EmployeeSkillMappingList.component';
import { Theme } from './Layout/Theme.component';
import { CustomLayout } from './Layout/CustomLayout';
import Routes from './CustomRoutes/Routes';
import { retrievePermissions } from './Api/restApi.config';
import _, { head } from 'lodash';
import { i18nProvider } from './config/i18n/index';
import { AssetList } from './modules/AssetManagement/AssetsList.component';
import { ConsultantList } from './modules/Consultant/ConsultantList.component';
import ProjectMilestoneList from './modules/ResourcePlanner/Details/ProjectMilestoneList.component';
import AttendanceList from './modules/Attendance/AttendanceList.component';
import DashboardInsightReport from './modules/Dashboard/DashboardInsightComponent';
import {
	useGetOrganizationDetailsQuery,
	useGetOrganisationFeaturesQuery,
	useGetLocationIdByUserIdQuery,
} from './generated/graphql';
import PageNotFound from './SharedComponents/PageNotFound.component';
import { filterReducer, paginationReducer } from './globalState';
export const UserProfileContext = createContext(null);

const App = (props) => {
	const initialFilterState = {
		projectSortFilter: [{ end_date: 'asc' }],
	};
	const { client, userProfile } = props;
	const [tabGlobalIndex, setTabGlobalIndex] = useState(0);
	const [graphQlProvider, setGraphQlProvider] = useState(null);
	const [permissions, setPermissions] = useState(null);
	const [userProfileContext, setUserProfileContext] = useState({});
	const [filterState, dispatchFilter] = useReducer(
		filterReducer,
		initialFilterState
	);
	const [paginationState, dispatchPaginationState] = useReducer(
		paginationReducer,
		{}
	);

	const { data: orgFeatureFlags } = useGetOrganisationFeaturesQuery({
		variables: { orgId: userProfile?.hasuraAuth['x-hasura-org-id'] },
		fetchPolicy: 'network-only',
	});

	const { data: organizationDetails } = useGetOrganizationDetailsQuery({
		variables: { orgId: userProfile?.hasuraAuth['x-hasura-org-id'] },
		fetchPolicy: 'network-only',
	});
	const { data: locationDetails } = useGetLocationIdByUserIdQuery({
		variables: { userId: userProfile?.hasuraAuth['x-hasura-user-id'] },
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		buildHasuraProvider({ client }).then(
			(p) => setGraphQlProvider(() => p),
			(err) => console.log(err)
		);
	}, [client]);
	useEffect(() => {
		if (!userProfile) {
			return;
		}

		const featureFlag =
			orgFeatureFlags?.organization_by_pk?.feature_flags || {};
		const dateFormat =
			organizationDetails?.organization_by_pk?.date_format || '';
		const noOfLocations =
			organizationDetails?.org_location_aggregate?.aggregate?.count || '';
		const locationId =
			locationDetails && locationDetails?.user_by_pk
				? locationDetails?.user_by_pk?.user_type === 'employee'
					? locationDetails?.user_by_pk?.employee?.location_id
					: head(locationDetails?.user_by_pk?.contractors)?.location_id
				: null;
		const isUserCustomPictureEnabled =
			organizationDetails?.organization_by_pk?.enable_usr_custom_pic || false;
		const isOvertimeEnabled =
			organizationDetails?.organization_by_pk?.enable_overtime || false;
		const orgBaseCurrency = {
			id: organizationDetails?.organization_by_pk?.currency?.id,
			name: organizationDetails?.organization_by_pk?.currency?.currency_type,
		};
		const isDraftTimesheetEnabled = head(
			organizationDetails?.organization_by_pk?.timesheet_settings
		)?.enable_draft;
		const isTimesheetWeekViewEnabled = head(
			organizationDetails?.organization_by_pk?.timesheet_settings
		)?.enable_weekly_entry;
		const isTrackDayTypeEnabled =
			head(organizationDetails?.organization_by_pk?.timesheet_settings)
				?.track_day_type || false;
		const empIdLength =
			organizationDetails?.organization_by_pk?.employee_id_length || null;
		const isFixedEmpIdLengthEnabled =
			organizationDetails?.organization_by_pk
				?.enable_fixed_employee_id_length || false;
		const attendanceConsiderableTimesheetStatus =
			organizationDetails?.organization_by_pk?.timesheet_calculation_status ||
			null;
		const isAttendanceRegularizationEnabled =
			organizationDetails?.organization_by_pk
				?.enable_attendance_regularization || false;
		const attendanceRegularizationDayCount =
			organizationDetails?.organization_by_pk
				?.attendance_regularization_day_count || null;
		setUserProfileContext({
			email: userProfile?.hasuraAuth['x-hasura-user-email'],
			role: userProfile?.hasuraAuth['x-hasura-default-role'],
			id: userProfile?.hasuraAuth['x-hasura-user-id'],
			orgId: userProfile?.hasuraAuth['x-hasura-org-id'],
			permissions,
			featureFlag,
			dateFormat,
			noOfLocations,
			locationId,
			isUserCustomPictureEnabled,
			isOvertimeEnabled,
			orgBaseCurrency,
			isTrackDayTypeEnabled,
			isDraftTimesheetEnabled,
			isTimesheetWeekViewEnabled,
			empIdLength,
			isFixedEmpIdLengthEnabled,
			attendanceConsiderableTimesheetStatus,
			isAttendanceRegularizationEnabled,
			attendanceRegularizationDayCount,
		});
	}, [
		userProfile,
		permissions,
		orgFeatureFlags,
		organizationDetails,
		locationDetails,
	]);

	useEffect(() => {
		retrievePermissions().then((response) =>
			setPermissions(mapToPermissionMap(response?.data))
		);
	}, []);

	const mapToPermissionMap = (permission) => {
		return _.chain(permission)
			.values()
			.head()
			.mapValues((value, key) => {
				return _.mapValues(value, (val, key) => {
					return _.keyBy(val);
				});
			})
			.value();
	};
	if (!graphQlProvider) {
		return <div>Loading....</div>;
	}
	return (
		<UserProfileContext.Provider
			value={{
				...userProfileContext,
				filterState,
				dispatchFilter,
				paginationState,
				dispatchPaginationState,
				tabGlobalIndex,
				setTabGlobalIndex,
			}}
		>
			<Admin
				{...props}
				dataProvider={graphQlProvider}
				dashboard={DashboardInsightReport}
				authProvider={authProvider}
				theme={Theme}
				layout={CustomLayout}
				customRoutes={Routes}
				i18nProvider={i18nProvider}
			>
				<Resource name='role_mapping' />
				<Resource name='role' />
				<Resource name='organization' />
				<Resource name='job_level' />
				<Resource name='skill_level' />
				<Resource name='geo_list' />
				<Resource name='project_skill_requirement' />
				<Resource name='project_resource_mapping_detail' />
				<Resource name='project_resource_mapping' />
				<Resource name='contract_agreement' />
				<Resource name='employee_status' />
				<Resource name='kanban_column' />
				<Resource name='opportunity_kanban_card' />
				<Resource name='asset_type' />
				<Resource name='emp_joining_source' />
				<Resource name='project_task' />
				{/* Todo remove this after confirming with @aleena check WP-62 <Resource name="project_task" /> */}
				<Resource name='project_milestone' />
				<Resource name='leaving_reason' />
				<Resource name='opportunities' />
				<Resource name='crm_task_kanban_card' />
				<Resource name='company_categories' />
				<Resource name='company_size' />
				<Resource name='company_sector' />
				<Resource name='company_contact_type' />
				<Resource name='crm_task_activity_type' />
				<Resource name='crm_tasks' />
				<Resource name='crm_tags' />
				<Resource name='org_holidays' />
				<Resource name='master_currencies' />
				<Resource name='contractor_status' />
				<Resource name='contractor_reason' />
				<Resource name='contractor_leaving_reason' />
				<Resource name='org_shift' />
				<Resource name='asset_notes' />
				<Resource name='asset_software' />
				<Resource name='asset_software_type' />
				<Resource name='opportunity_notes' />
				<Resource name='crm_company_note' />
				<Resource name='employee_note' />
				<Resource name='consultant_note' />
				<Resource name='project_status' />
				<Resource name='opportunity_loss_reason' />
				<Resource name='project_skill_requirement' />
				<Resource name='milestone_payment_status' />
				<Resource name='project_milestone_status' />
				<Resource name='org_leave_types' />
				<Resource name='org_leave_group' />
				<Resource name='attendance' list={AttendanceList} />
				<Resource name='master_modules' />
				<Resource name='master_sub_modules' />
				<Resource name='checklist_types' />
				<Resource name='kanban_board_types' />
				<Resource name='project_task_comments' />
				<Resource name='project_list' />
				<Resource name='master_project_task' />
				<Resource name='org_task_mappings' />
				<Resource name='org_tasks' />
				<Resource name='org_location' />
				<Resource name='project_tags' />
				<Resource name='skill_master' />
				<Resource name='project_category' />
				<Resource name='user_team' />
				<Resource name='project_delay_reason' />
				<Resource name='timesheet_day_type' />
				<Resource name='employee_prefix' />
				<Resource name='contractor_prefix' />
				<Resource name='timesheet_status' />
				<Resource name='project_resource_tag' />
				<Resource name='kanban_board' />
				<Resource
					name='ProjectMilestoneList'
					list={
						permissions?.project?.select_permissions &&
						permissions?.appPermissions?.ui?.viewProjectDetails
							? ProjectMilestoneList
							: PageNotFound
					}
				/>
				<Resource
					name='crm_company'
					list={
						permissions?.appPermissions?.ui?.viewCompanyDetails
							? CompanyList
							: PageNotFound
					}
				/>
				<Resource
					name='crm_contacts'
					list={
						permissions?.crm_contacts?.select_permissions
							? ContactList
							: PageNotFound
					}
				/>
				<Resource name='user' />
				<Resource
					name='employee'
					list={
						permissions?.employee?.select_permissions &&
						permissions?.appPermissions?.ui?.ViewEmployees
							? EmployeeList
							: PageNotFound
					}
				/>
				<Resource
					name='contractor'
					list={
						permissions?.contractor?.select_permissions &&
						permissions?.appPermissions?.ui?.viewContractDetails
							? ConsultantList
							: PageNotFound
					}
				/>
				<Resource
					name='designation'
					list={
						permissions?.designation?.select_permissions
							? DesignationList
							: null
					}
					edit={
						permissions?.designation?.update_permissions
							? EditDesignation
							: null
					}
					create={
						permissions?.designation?.insert_permissions
							? CreateDesignation
							: null
					}
				/>
				<Resource
					name='department'
					list={
						permissions?.department?.select_permissions ? DepartmentList : null
					}
					edit={
						permissions?.department?.update_permissions ? EditDepartment : null
					}
					create={
						permissions?.department?.insert_permissions
							? CreateDepartment
							: null
					}
				/>
				<Resource
					name='employee_skill_mapping'
					list={
						permissions?.employee_skill_mapping?.select_permissions &&
						permissions?.appPermissions?.ui?.viewEmployeeSkills
							? EmployeeSkillMappingList
							: PageNotFound
					}
				/>
				<Resource
					name='project'
					list={
						permissions?.project?.select_permissions &&
						permissions?.appPermissions?.ui?.viewProjectDetails
							? ProjectList
							: PageNotFound
					}
				/>
				<Resource
					name='project_milestone'
					list={permissions?.project?.select_permissions ? ProjectList : null}
					edit={permissions?.project?.update_permissions ? EditProject : null}
					create={
						permissions?.project?.insert_permissions ? CreateProject : null
					}
				/>
				<Resource
					name='asset'
					list={
						permissions?.appPermissions?.ui?.viewAssetDetails
							? AssetList
							: PageNotFound
					}
				/>
				<Resource name='project_skill_request_status' />
				<Resource name='pms_pms_vision' />
				<Resource name='pms_pms_master_goal' />
			</Admin>
		</UserProfileContext.Provider>
	);
};
const AppWithAuth = WithAuthentication(App);
export default AppWithAuth;
