import React, { useState } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	email,
	useMutation,
	useNotify,
	useRefresh,
	regex,
	maxLength,
} from 'react-admin';
import { validatePhoneNumber, validateWebsite } from '../../Utils/string.util';
import { FullName } from './OpportunitiesKanban.model';
import { Button, makeStyles, Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../Layout/styles';
import { contactChoices } from '../Company/Constant';

const useStyles = makeStyles({
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingBottom: '30px',
		marginTop: '18px',
	},
	optionalData: {
		display: 'flex',
		cursor: 'pointer',
		color: '#5C5C5C',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	optionalText: {
		fontSize: '12px',
		fontFamily: 'Manrope-Medium',
	},
	expandIcon: {
		color: '#5C5C5C',
	},
});

interface Props {
	onClose: () => void;
	contactName: string;
	companyId?: string;
	setContactId: (id: string) => void;
}

interface AddContactForm {
	first_name?: string;
	middle_name?: string;
	last_name?: string;
	title?: string;
	email?: string;
	company_id?: string;
	primary_phone?: string;
	alternate_phone?: string;
	background?: string;
	tags?: string;
	contact_type?: string;
	owner_id?: string;
	linkedin?: string;
	address?: string;
}

const mapToUserName = (choice: FullName) => `${choice?.full_name || ''}`;

const AddContact = (props: Props) => {
	const { onClose, contactName, companyId, setContactId } = props;
	const classes = modalFormStyle();
	const style = useStyles();
	const [mutate] = useMutation();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isIconExpanded, setIsIconExpanded] = useState(false);
	const [contactForm, setContactForm] = useState<AddContactForm>({
		first_name: contactName,
		company_id: companyId,
	});

	const onSave = () => {
		mutate(
			{
				type: 'create',
				resource: 'crm_contacts',
				payload: {
					data: {
						...contactForm,
					},
				},
			},
			{
				onSuccess: ({ data }) => {
					setContactId(data?.id);
					onClose();
					notify('Contacts Created Successfully');
					refresh();
				},
				onFailure: (error) => {
					notify(`Create failed: ${error.message}`, 'warning');
				},
			}
		);
	};
	const handleClose = () => {
		onClose();
	};

	return (
		<Form initialValues={contactForm} onSubmit={onSave}>
			{({ handleSubmit, invalid }) => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.label}>First Name *</Box>
					<TextInput
						source='first_name'
						fullWidth={true}
						label={''}
						validate={required()}
						onChange={(event: any) =>
							setContactForm({ ...contactForm, first_name: event.target.value })
						}
					/>
					<Box className={classes.label}>Last Name</Box>
					<TextInput
						source='last_name'
						fullWidth={true}
						label={''}
						onChange={(event: any) =>
							setContactForm({ ...contactForm, last_name: event.target.value })
						}
					/>
					<Box className={classes.label}>Email</Box>
					<TextInput
						source='email'
						label={''}
						fullWidth={true}
						validate={[email()]}
						onChange={(event: any) =>
							setContactForm({ ...contactForm, email: event.target.value })
						}
					/>
					<Box className={classes.label}>Company Name</Box>
					<ReferenceInput
						source='company_id'
						reference='crm_company'
						label={''}
						fullWidth
						filterToQuery={(searchText) => ({ name: searchText })}
						onChange={(event: any) =>
							setContactForm({ ...contactForm, company_id: event })
						}
					>
						<AutocompleteInput
							optionText={(choice: any) => choice?.name}
							resettable={true}
							shouldRenderSuggestions={(name: any) => name.trim().length > 0}
						/>
					</ReferenceInput>
					<Box
						className={style.optionalData}
						onClick={() => setIsIconExpanded(!isIconExpanded)}
					>
						<Box className={style.optionalText}>
							Fill Additional Information(optional)
						</Box>
						<Box className={style.expandIcon}>
							{isIconExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</Box>
					</Box>
					{isIconExpanded ? (
						<>
							<Box className={classes.label}>Title</Box>
							<TextInput
								source='title'
								label={''}
								fullWidth={true}
								onChange={(event: any) =>
									setContactForm({ ...contactForm, title: event.target.value })
								}
							/>
							<Box className={classes.label}> Phone 1</Box>
							<TextInput
								source='primary_phone'
								label={''}
								validate={[
									regex(validatePhoneNumber, 'Please enter valid phone number'),
									maxLength(15, 'Maximum 15 digits allowed'),
								]}
								fullWidth
								onChange={(event: any) =>
									setContactForm({
										...contactForm,
										primary_phone: event.target.value,
									})
								}
							/>
							<Box className={classes.label}> Phone 2</Box>
							<TextInput
								source='alternate_phone'
								label={''}
								fullWidth
								validate={[
									regex(validatePhoneNumber, 'Please enter valid phone number'),
									maxLength(15, 'Maximum 15 digits allowed'),
								]}
								onChange={(event: any) =>
									setContactForm({
										...contactForm,
										alternate_phone: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>Background</Box>
							<TextInput
								source='background'
								label={''}
								fullWidth
								onChange={(event: any) =>
									setContactForm({
										...contactForm,
										background: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>Tags</Box>
							<TextInput
								source='tags'
								label={''}
								fullWidth
								onChange={(event: any) =>
									setContactForm({ ...contactForm, tags: event.target.value })
								}
							/>
							<Box className={classes.label}>Contact Type</Box>
							<SelectInput
								source='contact_type'
								resettable={true}
								label={''}
								fullWidth
								choices={contactChoices}
								onChange={(event: any) =>
									setContactForm({
										...contactForm,
										contact_type: event.target.value,
									})
								}
							/>
							<Box className={classes.label}>BD OWNER</Box>
							<ReferenceInput
								source='owner_id'
								reference='user'
								label={''}
								fullWidth
								filterToQuery={(searchText) => ({
									first_name: searchText,
								})}
								filter={{ first_name: true, is_active: true }}
								onChange={(event: any) =>
									setContactForm({ ...contactForm, owner_id: event })
								}
							>
								<AutocompleteInput
									optionText={mapToUserName}
									resettable={true}
									shouldRenderSuggestions={(name: any) =>
										name.trim().length > 0
									}
								/>
							</ReferenceInput>
							<Box className={classes.label}>Linkedin</Box>
							<TextInput
								source='linkedin'
								label={''}
								fullWidth
								validate={[regex(validateWebsite, 'Must be a valid Link')]}
								onChange={(e: any) => {
									setContactForm({
										linkedin: e.target.value,
									});
								}}
							/>
							<Box className={classes.label}>Address</Box>
							<TextInput
								source='address'
								label={''}
								fullWidth
								onChange={(e: any) => {
									setContactForm({
										address: e.target.value,
									});
								}}
							/>
						</>
					) : null}
					<Box className={style.buttonContainer}>
						<Button onClick={handleClose} className={classes.cancelButton}>
							Cancel
						</Button>
						<Button
							className={invalid ? classes.disabledButton : classes.saveButton}
							disabled={invalid}
							type='submit'
						>
							Add Contact
						</Button>
					</Box>
				</form>
			)}
		</Form>
	);
};

export default AddContact;
