import React, { useState, useEffect, useContext } from 'react';
import {
	List,
	Datagrid,
	ReferenceField,
	FunctionField,
	useQuery,
	Loading,
} from 'react-admin';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	makeStyles,
	Box,
	TextField,
	Button,
	IconButton,
	Tooltip,
	Typography,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { GET_OPPORTUNITIES, GET_OPPORTUNITY_BY_ID } from '../gqlQueries';
import { useParams } from 'react-router-dom';
import { getCurrencyFormat } from '../../../Utils/string.util';
import EditIcon from '@material-ui/icons/Edit';
import AddOpportunityForm from './CompanyAddOpportunityForm.component';
import CompanyEditOpportunityForm from './CompanyEditOpportunityForm.component';
import { ellipsisStyle } from '../../../Layout/styles';
import { listTheme } from './listTheme';
import { UserProfileContext } from '../../../App';
import {
	useGetCrmPipelineBoardQuery,
	useGetCompanyopportunityIdQuery,
} from '../../../generated/graphql';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	addOpportunityButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			background: '#196CF5',
		},
	},
	iconButton: {
		width: '36px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#888888',
	},
	activeIconButton: {
		color: '#4285F4',
		border: '1px solid #4285F4',
	},
	priorityContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '4px 8px 4px 8px',
		width: 'fit-content',
	},
	priorityHigh: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	priorityMedium: {
		backgroundColor: '#EBF3FF',
		color: '#4285F4',
	},
	priorityLow: {
		backgroundColor: '#E9F7EC',
		color: '#34A853',
	},
	statusContainer: {
		width: '106px',
		fontSize: '12px',
		paddingLeft: '10px',
	},
	dot: {
		height: ' 5px',
		width: '5px',
		backgroundColor: '#F4CF06',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: '3px',
		marginTop: '5px',
	},
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		display: 'none',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	name: {
		width: '110px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	contact: {
		width: '80px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	tableList: {
		height: '460px',
		overflowY: 'scroll',
	},
}));
interface Form {
	close_date?: string;
	commission?: string;
	company_id?: string;
	id?: string;
	currency_id?: string;
	description?: string;
	name?: string;
	owner_id?: string;
	primary_contact_id?: string;
	priority?: string;
	value?: number;
	win_percentage?: number;
	source?: string;
	status?: string;
	kanban_id?: string;
	lead_owner_id?: string;
	loss_reason_id?: string;
	board_id?: string;
	board_name?: string;
}
interface Value {
	value?: number;
	denomination?: string;
}
interface TaskStatusList {
	kanban_board: {
		kanban_columns: {
			id: string;
			name: string;
		}[];
	}[];
}
export const searchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '344px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				paddingTop: '6px !important',
				height: '16px',
			},
		},

		// Search Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '8px',
				marginRight: '8px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

export const CompanyOpportunityTab = () => {
	const { permissions } = useContext<any>(UserProfileContext);
	const [searchedOpportunity, setSearchedOpportunity] = useState('');
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [opportunityId, setOpportunityId] = useState('');
	const [opportunity, setOpportunity] = useState<Form>({});
	const [boardId, setBoardId] = useState<string[]>();
	const [opportunitiesId, setOpportunitiesId] = useState<string[]>();
	const { id }: { id: string } = useParams();
	const classes = useStyles();
	const styles = ellipsisStyle();

	const { data: kanbanBoard } = useGetCrmPipelineBoardQuery({
		fetchPolicy: 'network-only',
	});

	const { data: currencyList } = useQuery({
		type: 'GET_LIST',
		resource: 'master_currencies',
		payload: {},
	});

	const {
		data: opportunityIds,
		refetch: refetchOpportunityId,
		loading,
	} = useGetCompanyopportunityIdQuery({
		variables: {
			boardId,
			companyId: id,
		},
	});

	const { data: opportunityList, refetch: refetchOpportunityList } =
		useApolloQuery(GET_OPPORTUNITIES, {
			variables: {
				companyId: id ? id : null,
			},
		});

	const { data: opportunityData } = useApolloQuery(GET_OPPORTUNITY_BY_ID, {
		variables: {
			opportunityId: opportunityId ? opportunityId : null,
		},
	});
	useEffect(() => {
		refetchOpportunityId();
	}, [refetchOpportunityId]);

	useEffect(() => {
		if (isEmpty(kanbanBoard?.kanban_board)) {
			setBoardId([]);
			return;
		}
		const board = kanbanBoard?.kanban_board?.map((value) => value?.id);
		setBoardId(board);
	}, [kanbanBoard]);

	useEffect(() => {
		if (isEmpty(opportunityIds?.opportunities)) {
			setOpportunitiesId([]);
			return;
		}
		const pipelineId = opportunityIds?.opportunities?.map((value) => value?.id);
		setOpportunitiesId(pipelineId);
	}, [opportunityIds]);

	useEffect(() => {
		if (opportunityData) {
			setOpportunity({
				close_date: opportunityData?.opportunities_by_pk?.close_date,
				commission: opportunityData?.opportunities_by_pk?.commission,
				company_id: opportunityData?.opportunities_by_pk?.company_id,
				id: opportunityData?.opportunities_by_pk?.id,
				currency_id: opportunityData?.opportunities_by_pk?.currency_id,
				description: opportunityData?.opportunities_by_pk?.description,
				name: opportunityData?.opportunities_by_pk?.name,
				owner_id: opportunityData?.opportunities_by_pk?.owner_id,
				lead_owner_id: opportunityData?.opportunities_by_pk?.lead_owner_id,
				primary_contact_id:
					opportunityData?.opportunities_by_pk?.primary_contact_id,
				priority: opportunityData?.opportunities_by_pk?.priority,
				loss_reason_id: opportunityData?.opportunities_by_pk?.loss_reason_id,
				value: opportunityData?.opportunities_by_pk?.value,
				win_percentage: opportunityData?.opportunities_by_pk?.win_percentage,
				source: opportunityData?.opportunities_by_pk?.source,
				status:
					opportunityData?.opportunities_by_pk?.kanbanCard?.kanban_column?.id,
				kanban_id: opportunityData?.opportunities_by_pk?.kanbanCard?.id,
				board_id:
					opportunityData?.opportunities_by_pk?.kanbanCard?.kanban_column
						?.kanban_board?.id,
				board_name:
					opportunityData?.opportunities_by_pk?.kanbanCard?.kanban_column
						?.kanban_board?.name,
			});
		}
	}, [opportunityData]);
	const onEditOpportunity = (opportunityId: string) => {
		setOpportunityId(opportunityId);
		setEditOpen(true);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<form onSubmit={(event) => event.preventDefault()}>
					<ThemeProvider theme={searchBarTheme}>
						<TextField
							placeholder='Search opportunities'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchOutlinedIcon />,
							}}
							onChange={(e) => setSearchedOpportunity(e?.target?.value)}
							value={searchedOpportunity}
						/>
					</ThemeProvider>
				</form>
				<Box>
					{permissions?.opportunities?.insert_permissions && (
						<Button
							className={classes.addOpportunityButton}
							startIcon={<AddIcon />}
							onClick={() => setAddOpen(true)}
						>
							Add Opportunity
						</Button>
					)}
				</Box>
			</Box>
			<Box mt={2} className={classes.tableList}>
				<ThemeProvider theme={listTheme}>
					<List
						resource='opportunities'
						basePath=''
						bulkActionButtons={false}
						actions={false}
						filter={{ id: opportunitiesId, name: searchedOpportunity }}
						pagination={false}
						title={' '}
						sort={{ field: 'created_at', order: 'DESC' }}
					>
						<Datagrid>
							<FunctionField
								label='NAME'
								render={(record: any) => {
									return (
										<Tooltip
											title={`${record?.name || '--'}`}
											placement='right'
										>
											<Typography
												className={[classes.name, styles.ellipsis].join(' ')}
											>
												{record?.name}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								label='PIPELINE'
								render={(record: any) =>
									opportunityList?.opportunities.map(
										(opportunity: any, index: number) =>
											opportunity.id === record.id && (
												<Tooltip
													title={
														opportunity?.kanbanCard?.kanban_column?.kanban_board
															?.name
													}
													placement='right'
												>
													<Box
														key={index}
														className={[
															classes.statusContainer,
															styles.ellipsis,
														].join(' ')}
													>
														{
															opportunity?.kanbanCard?.kanban_column
																?.kanban_board?.name
														}
													</Box>
												</Tooltip>
											)
									)
								}
							/>
							<ReferenceField
								source='primary_contact_id'
								label='CONTACT'
								reference='crm_contacts'
								sortBy='crm_contact.first_name'
								link={false}
							>
								<FunctionField
									render={(record: any) => {
										return (
											<Tooltip
												title={`${record?.full_name || ''}`}
												placement='right'
											>
												<Typography
													className={[classes.contact, styles.ellipsis].join(
														' '
													)}
												>
													{`${record?.full_name || ''} `}
												</Typography>
											</Tooltip>
										);
									}}
								/>
							</ReferenceField>
							<FunctionField
								label='STAGE'
								render={(record: any) =>
									opportunityList?.opportunities.map(
										(opportunity: any, index: number) =>
											opportunity.id === record.id && (
												<Tooltip
													title={opportunity?.kanbanCard?.kanban_column?.name}
													placement='right'
												>
													<Box
														key={index}
														className={[
															classes.statusContainer,
															styles.ellipsis,
														].join(' ')}
													>
														{opportunity?.kanbanCard?.kanban_column?.name}
													</Box>
												</Tooltip>
											)
									)
								}
							/>
							<FunctionField
								label='BD OWNER'
								render={(contact: any) =>
									contact?.owner_id ? (
										<>
											<ReferenceField
												source='owner_id'
												label='LEAD OWNER'
												reference='user'
												link={false}
											>
												<FunctionField
													render={(record: any) => {
														return (
															<Tooltip
																title={`${record?.full_name || ''} `}
																placement='right'
															>
																<Typography
																	className={[
																		classes.contact,
																		styles.ellipsis,
																	].join(' ')}
																>
																	{`${record?.full_name || '- '}`}
																</Typography>
															</Tooltip>
														);
													}}
												/>
											</ReferenceField>
										</>
									) : (
										'--'
									)
								}
							/>
							<FunctionField
								label='VALUE'
								source='value'
								className={[classes.contact, styles.ellipsis].join(' ')}
								render={(record: any) => {
									const currency =
										currencyList?.length > 0 &&
										currencyList.find(
											(item: any) => item?.id === record?.currency_id
										);
									return getCurrencyFormat(
										record?.value,
										currency?.currency_type,
										true
									);
								}}
							/>

							<FunctionField
								source='priority'
								label='PRIORITY'
								render={(record: any) => (
									<Box
										className={`${classes.priorityContainer}
                      ${
												record.priority === 'High'
													? classes.priorityHigh
													: record.priority === 'Medium'
													? classes.priorityMedium
													: record.priority === 'Low'
													? classes.priorityLow
													: undefined
											}
                      `}
									>
										{record?.priority}
									</Box>
								)}
							/>
							<FunctionField
								source='win_percentage'
								label='WIN'
								render={(record: any) => (
									<span style={{ display: 'flex', fontSize: '10px' }}>
										<span className={classes.dot}></span>
										{record?.win_percentage || 0}%
									</span>
								)}
							/>

							{permissions?.opportunities?.update_permissions && (
								<FunctionField
									render={(data: any) => (
										<Box className={classes.listEditIconContainer}>
											<IconButton
												className={classes.listEditIconButton}
												onClick={() => {
													onEditOpportunity(data.id);
												}}
											>
												<EditIcon className={classes.editIcon} />
											</IconButton>
										</Box>
									)}
								/>
							)}
						</Datagrid>
					</List>
				</ThemeProvider>
			</Box>
			<AddOpportunityForm
				onClose={() => setAddOpen(false)}
				open={addOpen}
				refetch={() => {
					refetchOpportunityList();
					refetchOpportunityId();
				}}
			/>
			<CompanyEditOpportunityForm
				onClose={(value: boolean) => setEditOpen(value)}
				open={editOpen}
				refetch={() => {
					refetchOpportunityList();
				}}
				opportunity={opportunity}
			/>
		</div>
	);
};

export default CompanyOpportunityTab;
