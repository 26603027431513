import React, { useState, useEffect, useContext } from 'react';

import { GET_PROJECT_LIST } from './gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import { ViewMode, Gantt } from 'gantt-task-react';

import {
	makeStyles,
	ThemeProvider,
	createTheme,
} from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GET_ORGANISATION_FINANICIAL_YEAR } from '../gqlQueries';
import { UserProfileContext } from '../../App';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import 'gantt-task-react/dist/index.css';

const customSearchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '100%',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				'& .MuiAutocomplete-endAdornment': {
					marginTop: '2px',
				},
				'& > input': {
					height: '23px',
				},
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				height: '16px',
				marginLeft: '8px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},

		// Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '8px',
				marginRight: '8px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

const initialChartData = [
	{
		type: 'project',
		id: 'ProjectSample',
		name: '1.Project',
		start: new Date(2021, 6, 1),
		end: new Date(2021, 9, 30),
		progress: 25,
		hideChildren: false,
	},
];

const useStyles = makeStyles({
	container: {
		paddingTop: '16px',
	},
	chartContainer: {
		width: '100%',
		margin: '1em auto',
	},
	dateRangeButton: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		background: '#ECF1F4',
		borderRadius: '4px',
		border: '1px solid lightblue',
		cursor: 'pointer',
	},
});

export const ProjectGanttChart = () => {
	const { orgId: organisationId } = useContext(UserProfileContext);
	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedProjectGanttChartData, setSelectedProjectGanttChartData] =
		useState(initialChartData);
	const styles = useStyles();
	// TODO after data are ready we have to fetch current financial year from database
	const [currentFinancialYear, setCurrentFinancialYear] = useState({
		start_date: null,
		end_date: null,
	});
	const [mode, setMode] = useState(ViewMode.Month);
	const [tasks, setTasks] = useState(initialChartData);
	let columnWidth = 60;
	if (mode === ViewMode.Month) {
		columnWidth = 80;
	} else if (mode === ViewMode.Week) {
		columnWidth = 40;
	}

	const { data: projectList, refetch: refetchProjectList } =
		useApolloQuery(GET_PROJECT_LIST);
	const { data: organisationFinanicialYear, loading } = useApolloQuery(
		GET_ORGANISATION_FINANICIAL_YEAR,
		{
			variables: {
				organisationId: organisationId,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!organisationFinanicialYear) {
			return;
		}
		const financialYearStartMonth = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_month
		);
		const financialYearStartDate = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_date
		);
		const startDate = dayjs()
			.date(financialYearStartDate + 1)
			.month(financialYearStartMonth)
			.format('YYYY-MM-DD');
		const calculatedStartDate =
			dayjs() < dayjs(startDate)
				? dayjs(startDate)
						.year(dayjs().year() - 1)
						.toDate()
				: dayjs(startDate).toDate();
		const calculatedEndDate =
			dayjs() < dayjs(startDate)
				? dayjs(startDate)
						.year(dayjs().year() - 1)
						.add(12, 'months')
						.subtract(1, 'day')
						.toDate()
				: dayjs(startDate).add(12, 'months').subtract(1, 'day').toDate();
		setCurrentFinancialYear({
			start_date: calculatedStartDate,
			end_date: calculatedEndDate,
		});
	}, [organisationFinanicialYear]);

	useEffect(() => {
		if (projectList?.project.length > 0) {
			let data;
			if (selectedProject) {
				data = projectList?.project.filter(
					(project) => project.id === selectedProject?.id
				);
			} else {
				data = projectList.project;
			}

			const ReactGanttData = data.map((projectObj, projectIndex) => {
				const resourceDetails =
					projectObj.project_resource_mappings.length > 0
						? projectObj.project_resource_mappings.map((resource, index) => {
								return (
									currentFinancialYear.start_date <
										new Date(resource?.end_date) &&
									new Date(resource?.start_date) <
										currentFinancialYear.end_date && {
										type: 'task',
										id: `${resource?.employee_id}-${projectObj.id}-${index}`,
										start: resource?.start_date
											? new Date(resource?.start_date)
											: new Date(),
										end: resource?.end_date
											? new Date(resource?.end_date)
											: new Date(),
										progress: resource?.allocation_percentage || 100,
										dependencies: [],
										name: resource?.user?.full_name || '',
										project: projectObj?.project_id || '',
										isDisabled: true,
									}
								);
						  })
						: [];

				const projectDetails =
					currentFinancialYear.start_date < new Date(projectObj?.end_date) &&
					new Date(projectObj?.start_date) < currentFinancialYear.end_date
						? [
								{
									type: 'project',
									name: projectObj?.name || '',
									id: projectObj.id,
									start: projectObj?.start_date
										? new Date(projectObj?.start_date)
										: new Date(),
									end: projectObj?.end_date
										? new Date(projectObj?.end_date)
										: new Date(),
									progress: 0,
									dependencies: [],
									isDisabled: true,
									hideChildren: false,
								},
						  ]
						: [];

				return [...projectDetails, ...resourceDetails];
			});
			const finalData = ReactGanttData.flat().filter(
				(value) => value !== false
			);
			if (finalData?.length > 0) {
				if (selectedProject) {
					setSelectedProjectGanttChartData(finalData);
				} else {
					setTasks(finalData);
				}
			}
		}
	}, [projectList, selectedProject, currentFinancialYear]);

	useEffect(() => {
		refetchProjectList();
	}, [refetchProjectList]);

	const onSearchChange = (event, newValue) => {
		setSelectedProject(newValue);
	};

	const handleExpanderClick = (task) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Box className={styles.container} mt={1}>
				<Autocomplete
					value={selectedProject}
					onChange={onSearchChange}
					options={projectList?.project ? projectList?.project : []}
					style={{ width: 300 }}
					getOptionLabel={(option) => option?.name}
					getOptionSelected={(option, value) => option.id === value.id}
					renderInput={(params) => (
						<ThemeProvider theme={customSearchBarTheme}>
							<TextField {...params} label='' placeholder='Search Project' />
						</ThemeProvider>
					)}
				/>
			</Box>
			<Box className={styles.chartContainer}>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.QuarterDay)}
				>
					Quarter of Day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.HalfDay)}
				>
					Half of day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Day)}
				>
					Day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Week)}
				>
					Week
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Month)}
				>
					Month
				</button>
				{projectList?.project.length > 0 && (
					<Gantt
						tasks={selectedProject ? selectedProjectGanttChartData : tasks}
						viewMode={mode}
						todayColor={'#ECF1F4'}
						ganttHeight={500}
						fontSize={'12px'}
						fontFamily={'Manrope-medium'}
						columnWidth={columnWidth}
						onExpanderClick={handleExpanderClick}
						barProgressColor={'#4285F4'}
					/>
				)}
			</Box>
		</>
	);
};

export default ProjectGanttChart;
