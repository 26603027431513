import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { getUrlParam } from './Utils/index';
import { CreateApolloClient } from './Api/graphQLClient.config';

const token = getUrlParam('accessToken');
if (!!token) {
	localStorage.setItem('accessToken', token);
	window.location = '/';
}
const apolloClient = CreateApolloClient();
const rootNode = document.getElementById('root');
ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={apolloClient}>
			<App client={apolloClient} />
		</ApolloProvider>
	</React.StrictMode>,
	rootNode
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
