import React, { ReactNode, useContext } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { UserProfileContext } from '../../App';
import { isEmpty } from 'lodash';
import { Col, Row, Spin, Typography } from 'antd';
import 'antd/dist/antd.css';
import ListIcon from '@material-ui/icons/List';
import { useGetProjectCountQuery } from '../../generated/graphql';
import { homePageStyle } from './style';

interface CountProps {
	isLoading: boolean;
	count: Number | String;
}

const CountCard = ({
	label,
	value,
	backgroundcolor,
}: {
	label: String;
	value: ReactNode;
	backgroundcolor: any;
}) => {
	const inlineStyle = homePageStyle();
	return (
		<Box className={inlineStyle.countContainer}>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Box
					className={inlineStyle.Circle}
					style={{ backgroundColor: backgroundcolor }}
				>
					<ListIcon />
				</Box>
				<Box>
					<Box className={inlineStyle.countLabel}>{label}</Box>
					<Box className={inlineStyle.countValue}>{value}</Box>
				</Box>
			</Box>
		</Box>
	);
};

export const Count = (props: CountProps) => {
	const { count, isLoading } = props;
	const { Text } = Typography;
	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text>{count}</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};

const HomeInsights = () => {
	const { id: userId } = useContext<any>(UserProfileContext);
	const history = useHistory();
	const inlineStyle = homePageStyle();

	const { data: projectCounts, loading: isProjectLoading } =
		useGetProjectCountQuery({
			variables: {
				userId,
			},
			skip: !userId,
			fetchPolicy: 'network-only',
		});

	const getOpenTaskCount = () => {
		if (!(projectCounts && projectCounts?.projectTaskCount)) {
			return '--';
		}
		const response = projectCounts?.projectTaskCount?.aggregate?.count;
		return response || 0;
	};

	const getLeaveCount = () => {
		let initialValue = 0;

		const response = projectCounts?.leavePendingCount?.reduce(
			(previousValue: any, currentValue: any) => {
				let value =
					currentValue?.employee_leaving_mappings_aggregate?.aggregate?.count ||
					0;
				return (initialValue += value);
			},
			0
		);
		return response;
	};

	const getTimesheetCount = () => {
		if (isEmpty(projectCounts?.timesheetPendingCount)) {
			return 0;
		}
		const response = projectCounts?.timesheetPendingCount
			?.filter((user) => user.timesheetSubmissions.length > 0)
			.map((user) => user.timesheetSubmissions)
			.flat()
			.filter(
				(timesheetSubmission) =>
					Number(timesheetSubmission?.timesheets_aggregate?.aggregate?.count) >
					0
			);
		return response?.length || 0;
	};

	const getProjectCount = () => {
		if (isEmpty(projectCounts) && isEmpty(projectCounts?.projectListCount)) {
			return 0;
		}

		const response = projectCounts?.projectListCount?.aggregate?.count || 0;
		return response;
	};


	return (
		<Box className={inlineStyle.headCountContainer}>
			<Box onClick={() => history.push('/project')}>
				<CountCard
					label={'Project Count'}
					value={
						<Count count={getProjectCount()} isLoading={isProjectLoading} />
					}
					backgroundcolor={'#4285F4'}
				/>
			</Box>
			<Box>
				<CountCard
					label={'Open Task'}
					value={
						<Count count={getOpenTaskCount()} isLoading={isProjectLoading} />
					}
					backgroundcolor={'#34a853'}
				/>
			</Box>
			<Box onClick={() => history.push('/timesheet')}>
				<CountCard
					label={'My Approval-Timesheet'}
					value={
						<Count count={getTimesheetCount()} isLoading={isProjectLoading} />
					}
					backgroundcolor={'#4285F4'}
				/>
			</Box>
			<Box onClick={() => history.push('/attendance')}>
				<CountCard
					label={'My Approval-Leave'}
					value={<Count count={getLeaveCount()} isLoading={isProjectLoading} />}
					backgroundcolor={'#34a853'}
				/>
			</Box>
		</Box>
	);
};

export default HomeInsights;
