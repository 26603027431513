import React from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	InputAdornment,
	TextField,
	ThemeProvider,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { required, useNotify } from 'react-admin';

import { formStyle } from '../pms.styles';
import {
	autoCompleteTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import Loader from '../SharedComponents/Loader.component';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteSearchStyles } from '../../../Layout/styles';
import { SearchIcon } from '../../../assets/SvgIcons';
import {
	useGetUserAppraisalStatusListQuery,
  useUpdateAppraisalStatusByUserIdMutation,
} from '../../../generated/graphql';
import {
  USER_APPRAISAL_STATUS_UPDATED,
} from '../constant';

interface FormProps {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	selectedUsersId: any[];
  appraisalCycleId: string
}

function StatusChangeForm(props: FormProps) {
	const { open, onClose, onSuccess, selectedUsersId, appraisalCycleId } = props;
	const formStyles = formStyle();
	const notify = useNotify();
	const autoCompleteSearchStyles = AutocompleteSearchStyles();

	// API
	const { data: users } = useGetUserAppraisalStatusListQuery({});

	const [updateStatus, { loading: isUpdateReviewerLoading }] =
		useUpdateAppraisalStatusByUserIdMutation();

	const handleSubmit = (formValue: { status: string }) => {
		if (!formValue) return;
		updateStatus({
			variables: {
				userIdList: selectedUsersId,
				appraisalCycleId: appraisalCycleId,
        userAppraisalStatusId: formValue?.status
			},
		}).then((res) => {
			if (!res.errors) {
				notify(USER_APPRAISAL_STATUS_UPDATED);
				onSuccess();
			}
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={'sm'}
			>
				<div
					className={formStyles.container}
					style={{
						minHeight: '200px',
					}}
				>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>Change Status</p>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</div>

					<Form onSubmit={handleSubmit}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<div>
									<DialogContent>
										<p className={formStyles.label}>Choose Status*</p>
										<Field name='status' validate={required()}>
											{(props: any) => (
												<ThemeProvider theme={autoCompleteTheme}>
													<div>
														<Autocomplete
															id='status_id'
															classes={{
																option: autoCompleteSearchStyles.options,
																groupLabel: autoCompleteSearchStyles.groupLabel,
																inputRoot: autoCompleteSearchStyles.inputRoot,
																noOptions: autoCompleteSearchStyles.noOptions,
																focused: autoCompleteSearchStyles.focused,
																input: autoCompleteSearchStyles.input,
																endAdornment:
																	autoCompleteSearchStyles.endAdornment,
															}}
															getOptionSelected={(
																option: { id: string },
																value
															) => option.id === props.input.value}
															onChange={(event, newValue) => {
																props.input.onChange(newValue?.id);
															}}
															options={
																users?.pms_pms_user_appraisal_status?.map(
																	(usr) => ({
																		id: usr.id,
																		name: usr.name,
																	})
																) || []
															}
															getOptionLabel={(option) => option?.name || ''}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label=''
																	placeholder='Search Status'
																	InputProps={{
																		...params.InputProps,
																		startAdornment: (
																			<InputAdornment
																				position='start'
																				classes={{
																					root: autoCompleteSearchStyles.inputAdronment,
																					positionStart:
																						autoCompleteSearchStyles.inputAdronmentPositionStart,
																				}}
																			>
																				<SearchIcon
																					className={
																						autoCompleteSearchStyles.searchIconPositionStart
																					}
																				/>
																			</InputAdornment>
																		),
																	}}
																/>
															)}
														/>
													</div>
												</ThemeProvider>
											)}
										</Field>
										<div
											className={formStyles.buttonContainer}
											style={{
												padding: '30px 0px 30px 0px',
											}}
										>
											<Button
												className={formStyles.cancelButton}
												onClick={onClose}
											>
												Cancel
											</Button>

											<Button
												className={
													invalid || pristine || isUpdateReviewerLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid || pristine || isUpdateReviewerLoading
												}
												type='submit'
												startIcon={isUpdateReviewerLoading && <Loader />}
											>
												Update
											</Button>
										</div>
									</DialogContent>
								</div>
							</form>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default StatusChangeForm;
