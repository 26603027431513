import React, { useState, useEffect, useContext } from 'react';
import { ViewMode, Gantt } from 'gantt-task-react';

import { GET_ACTIVE_EMPLOYEE_RESOURCE_MAPPING_LIST } from './gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';

import {
	Box,
	TextField,
	createTheme,
	ThemeProvider,
	Tooltip,
	Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { UserProfileContext } from '../../App';
import { GET_ORGANISATION_FINANICIAL_YEAR } from '../gqlQueries';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import 'gantt-task-react/dist/index.css';
import { dropDownEllipsisStyle } from '../../Layout/styles';

const customSearchBarTheme = createTheme({
	overrides: {
		// Search Input
		MuiInput: {
			root: {
				height: '36px',
				width: '100%',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #E0E0E0',
				borderRadius: '4px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				'& .MuiAutocomplete-endAdornment': {
					marginTop: '2px',
				},
				'& > input': {
					height: '23px',
				},
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&:after': {
					borderBottom: 'none',
				},
			},
			input: {
				height: '16px',
				marginLeft: '8px',
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},

		// Icon
		MuiSvgIcon: {
			root: {
				color: '#7C7C7C',
				marginLeft: '8px',
				marginRight: '8px',
				width: '16px',
				height: '16px',
				fontSize: '17px',
			},
		},
	},
});

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100vh',
		backgroundColor: '#fff',
		borderRadius: '5px',
	},
	chartContainer: {
		width: '60%',
		marginTop: '20px',
		maxWidth: '60%',
	},
	dateRangeButton: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		background: '#ECF1F4',
		borderRadius: '4px',
		border: '1px solid lightblue',
		cursor: 'pointer',
	},
}));

const initialChartData = [
	{
		type: 'project',
		id: 'ProjectSample',
		name: '1.Project',
		start: new Date(2021, 6, 1),
		end: new Date(2021, 9, 30),
		progress: 25,
		hideChildren: false,
	},
];

// TODO Search implementation need to refactor, Have to find the proper way
export const EmployeeGanttChart = () => {
	const { orgId: organisationId } = useContext(UserProfileContext);
	const [today, setToday] = useState(null);
	const [dateRange, setDateRange] = useState(null); // TODO After implement the date filter we have to change this state variable.
	const styles = useStyles();
	const ellipsis = dropDownEllipsisStyle();
	const [searchedEmployee, setSearchedEmployee] = useState(null);
	const [searchedEmployeeGanttChartData, setSearchedEmployeeGanttChartData] =
		useState(initialChartData);
	const [mode, setMode] = useState(ViewMode.Month);
	const [tasks, setTasks] = useState(initialChartData);
	let columnWidth = 60;
	if (mode === ViewMode.Month) {
		columnWidth = 60;
	} else if (mode === ViewMode.Week) {
		columnWidth = 40;
	}

	const {
		data: activeEmployeeResourceMappingList,
		refetch: refetchActiveEmployeeResourceMappingList,
	} = useApolloQuery(GET_ACTIVE_EMPLOYEE_RESOURCE_MAPPING_LIST);

	const { data: organisationFinanicialYear, loading } = useApolloQuery(
		GET_ORGANISATION_FINANICIAL_YEAR,
		{
			variables: {
				organisationId: organisationId,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (!organisationFinanicialYear) {
			return;
		}
		setToday(new Date());
		const financialYearStartMonth = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_month
		);
		const financialYearStartDate = Number(
			organisationFinanicialYear?.organization_by_pk?.financial_year_start_date
		);
		const startDate = dayjs()
			.date(financialYearStartDate + 1)
			.month(financialYearStartMonth)
			.format('YYYY-MM-DD');
		const calculatedEndDate =
			dayjs() < dayjs(startDate)
				? dayjs(startDate)
						.year(dayjs().year() - 1)
						.add(12, 'months')
						.subtract(1, 'day')
						.toDate()
				: dayjs(startDate)
						.year(dayjs().year())
						.add(12, 'months')
						.subtract(1, 'day')
						.toDate();
		setDateRange(calculatedEndDate);
	}, [organisationFinanicialYear]);

	useEffect(() => {
		if (activeEmployeeResourceMappingList?.user.length > 0) {
			let data;
			if (searchedEmployee) {
				data = activeEmployeeResourceMappingList.user.filter(
					(usr) => usr.id === searchedEmployee.id
				);
			} else {
				data = activeEmployeeResourceMappingList.user;
			}
			const ReactGanttData = data.map((usr) => {
				const projectDetails =
					usr.project_resource_mappings.length > 0
						? usr.project_resource_mappings.map(
								(resource, resourceAllocationIndex) => {
									return (
										today < new Date(resource?.end_date) &&
										new Date(resource?.start_date) < dateRange && {
											type: 'task',
											id: `${resource?.employee_id}-${resourceAllocationIndex}`,
											start: resource?.start_date
												? new Date(resource?.start_date)
												: new Date(),
											end: resource?.end_date
												? new Date(resource?.end_date)
												: new Date(),
											progress: resource?.allocation_percentage || 100,
											dependencies: [],
											name: resource?.project?.name || '',
											isDisabled: true,
											project: resource?.employee_id || '',
										}
									);
								}
						  )
						: [];

				const resourceStartDate =
					projectDetails && projectDetails.length > 0
						? projectDetails
								.map((resourceAllocation) => ({
									startDate: resourceAllocation.start,
									diff: dayjs().diff(dayjs(resourceAllocation.start), 'day'),
								}))
								.sort((a, b) => b.diff - a.diff)[0]
						: dayjs().toDate();

				const resourceEndDate =
					projectDetails && projectDetails.length > 0
						? projectDetails
								.map((resourceAllocation) => ({
									endDate: resourceAllocation.end,
									diff: dayjs().diff(dayjs(resourceAllocation.end), 'day'),
								}))
								.sort((a, b) => a.diff - b.diff)[0]
						: dayjs().toDate();

				const userDetails = [
					{
						type: 'project',
						name: usr?.full_name,
						id: usr.id,
						start:
							projectDetails && projectDetails.length > 0
								? dayjs(resourceStartDate.startDate).toDate()
								: dayjs().toDate(),
						end: dayjs(resourceEndDate.endDate).toDate(),
						progress: 0,
						dependencies: [],
						isDisabled: true,
						hideChildren: false,
					},
				];

				return [...userDetails, ...projectDetails];
			});

			const finalData = ReactGanttData.flat().filter(
				(v) => v !== false && v.name !== ''
			);

			if (finalData?.length > 0) {
				if (searchedEmployee) {
					setSearchedEmployeeGanttChartData(finalData);
				} else {
					setTasks(finalData);
				}
			}
		}
	}, [activeEmployeeResourceMappingList, searchedEmployee, dateRange, today]);

	useEffect(() => {
		refetchActiveEmployeeResourceMappingList();
	}, [refetchActiveEmployeeResourceMappingList]);

	const onSearchChange = (event, newValue) => {
		setSearchedEmployee(newValue);
	};

	if (loading) {
		return <Loading />;
	}
	const handleExpanderClick = (task) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
	};

	return (
		<>
			<Box mt={2}>
				<Autocomplete
					value={searchedEmployee}
					onChange={onSearchChange}
					options={
						activeEmployeeResourceMappingList?.user
							? activeEmployeeResourceMappingList?.user
							: []
					}
					style={{ width: 300 }}
					renderOption={(option) => (
						<Tooltip title={`${option?.full_name || '- -'}`} placement='right'>
							<Typography className={`${ellipsis.ellipsis}`}>
								{`${option?.full_name || '- -'}`}
							</Typography>
						</Tooltip>
					)}
					getOptionLabel={(option) => option?.full_name}
					renderInput={(params) => (
						<ThemeProvider theme={customSearchBarTheme}>
							<TextField {...params} label='' placeholder='Search Employee' />
						</ThemeProvider>
					)}
				/>
			</Box>
			<Box className={styles.chartContainer}>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.QuarterDay)}
				>
					Quarter of Day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.HalfDay)}
				>
					Half of day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Day)}
				>
					Day
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Week)}
				>
					Week
				</button>
				<button
					className={styles.dateRangeButton}
					onClick={() => setMode(ViewMode.Month)}
				>
					Month
				</button>
				{activeEmployeeResourceMappingList?.user.length > 0 && (
					<Gantt
						tasks={searchedEmployee ? searchedEmployeeGanttChartData : tasks}
						viewMode={mode}
						todayColor={'#ECF1F4'}
						ganttHeight={500}
						fontSize={'12px'}
						fontFamily={'Manrope-medium'}
						columnWidth={columnWidth}
						onExpanderClick={handleExpanderClick}
						barProgressColor={'#4285F4'}
					/>
				)}
			</Box>
		</>
	);
};

export default EmployeeGanttChart;
