import React, { useContext, useState, useEffect } from 'react';

import { globalStyles } from '../../Layout/styles';
import {
	Box,
	makeStyles,
	withStyles,
	Tabs,
	Tab,
	Theme,
	createStyles,
	Button,
} from '@material-ui/core';
import Timesheet from './Timesheet.component';
import TimesheetPendingApproval from './TimesheetPendingApproval.component';
import {
	useGetNumberOfReporteesQuery,
	useGetTimeheetPendingCountQuery,
} from '../../generated/graphql';
import { UserProfileContext } from '../../App';
import TimesheetAdmin from './TimesheetAdmin.component';
import TimesheetHistory from './TimesheetHistory.component';
import TimesheetInsights from './Insight/TimehseetInsightContainer.component';
import { InsightsIcon } from '../../assets/SvgIcons';
import { isEmpty } from 'lodash';
import TimesheetOvertimeContainer from './Overtime/TimesheetOvertimeContainer.component';
import { TIME_SHEET_CONTAINER } from './constant';

interface StyledTabProps {
	label: string;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			minWidth: 72,
			marginRight: '10px',
			fontFamily: 'Manrope-Medium',
			fontSize: '12px !important',
			color: '#5C5C5C',
			'&:hover': {
				color: '#4285F4',
				opacity: 1,
			},
			'&$selected': {
				color: '#1890ff',
				fontWeight: theme.typography.fontWeightBold,
			},
			'&:focus': {
				color: '#4285F4',
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box pt={3} pr={3}>
					{children}
				</Box>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '584px',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

export const TimesheetContainer = () => {
	const {
		id: userId,
		permissions: permissionList,
		isOvertimeEnabled,
	} = useContext<any>(UserProfileContext);
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const commonStyles = globalStyles();
	const [container, setContainer] = useState(TIME_SHEET_CONTAINER.timesheet);
	const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	const { data: reportees } = useGetNumberOfReporteesQuery({
		variables: {
			userId: userId,
		},
	});

	const { data: timesheetPendingCount, refetch: refetchTimesheetPendingCount } =
		useGetTimeheetPendingCountQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (isEmpty(timesheetPendingCount?.user)) {
			setPendingApprovalCount(0);
			return;
		}
		const response = timesheetPendingCount?.user
			?.filter((user) => user.timesheetSubmissions.length > 0)
			.map((user) => user.timesheetSubmissions)
			.flat()
			.filter(
				(timesheetSubmission) =>
					Number(timesheetSubmission?.timesheets_aggregate?.aggregate?.count) >
					0
			);
		setPendingApprovalCount(response?.length || 0);
	}, [timesheetPendingCount]);

	function handleTimesheetClick() {
		setContainer(TIME_SHEET_CONTAINER.timesheet);
	}

	function handleOvertimeClick() {
		setContainer(TIME_SHEET_CONTAINER.overtime);
	}

	function handleInsightsClick() {
		setContainer(TIME_SHEET_CONTAINER.insights);
	}

	return (
		<>
			<Box marginBottom='25px' className={commonStyles.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						container === TIME_SHEET_CONTAINER.timesheet
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={handleTimesheetClick}
				>
					Timesheet
				</Button>
				{isOvertimeEnabled && (
					<Button
						className={[
							commonStyles.navigationTabButtons,
							container === TIME_SHEET_CONTAINER.overtime
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={handleOvertimeClick}
					>
						Overtime
					</Button>
				)}

				{permissionList?.appPermissions?.ui?.viewEmployeeInsights && (
					<Button
						className={[
							commonStyles.navigationTabButtons,
							container === TIME_SHEET_CONTAINER.insights
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={handleInsightsClick}
						startIcon={<InsightsIcon />}
					>
						Insights
					</Button>
				)}
			</Box>
			<div className={classes.root}>
				{container === TIME_SHEET_CONTAINER.insights && <TimesheetInsights />}
				{container === TIME_SHEET_CONTAINER.overtime && (
					<TimesheetOvertimeContainer />
				)}

				{container === TIME_SHEET_CONTAINER.timesheet && (
					<>
						<CustomTabs value={selectedTabIndex} onChange={handleChange}>
							<CustomTab key={0} label='My Timesheet' />
							<CustomTab key={1} label='History' />
							{permissionList?.appPermissions?.ui?.ViewOthersTimesheet && (
								<CustomTab key={2} label='Timesheet' />
							)}
							{reportees?.user_aggregate?.aggregate?.count &&
								reportees?.user_aggregate?.aggregate?.count > 0 && (
									<CustomTab
										key={
											permissionList?.appPermissions?.ui?.ViewOthersTimesheet
												? 3
												: 2
										}
										label={` Pending Approval(${pendingApprovalCount})`}
									/>
								)}
						</CustomTabs>
						<CustomTabPanel value={selectedTabIndex} index={0}>
							<Timesheet />
						</CustomTabPanel>
						<CustomTabPanel value={selectedTabIndex} index={1}>
							<TimesheetHistory />
						</CustomTabPanel>
						{permissionList?.appPermissions?.ui?.ViewOthersTimesheet && (
							<CustomTabPanel value={selectedTabIndex} index={2}>
								<TimesheetAdmin />
							</CustomTabPanel>
						)}
						{reportees?.user_aggregate?.aggregate?.count &&
						reportees?.user_aggregate?.aggregate?.count > 0 ? (
							<CustomTabPanel
								value={selectedTabIndex}
								index={
									permissionList?.appPermissions?.ui?.ViewOthersTimesheet
										? 3
										: 2
								}
							>
								<TimesheetPendingApproval
									refetch={() => refetchTimesheetPendingCount()}
								/>
							</CustomTabPanel>
						) : null}
					</>
				)}
			</div>
		</>
	);
};

export default TimesheetContainer;
