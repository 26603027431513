import React, { useEffect, useState, useContext } from 'react';

import { Loading } from 'react-admin';

import head from 'lodash/head';
import CompanyDetailsSettings from './CompanyDetailsSettings.component';
import CurrencySettings from './CurrencySettings.component';
import FinancialYearSettings from './FinancialYearSettings.component';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import TimeZoneSettings from './TimeZoneSettings.component';
import Location from './Location.component';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_ORGANISATION } from '../Settings.gql';
import { OrganisationResponse } from './Organisation.model';
import { UserProfileContext } from '../../../App';
import DateFormatSettings from './DateFormatSettings.component';
import EditableColumns from './EditableColumns.component';
import EmailNotification from './EmailNotification.component';
import OvertimeSettings from '../OvertimeSettings/OvertimeSettings.component';

export const OrganisationGeneralSettings = () => {
	const { orgId: organisationId, featureFlag } =
		useContext<any>(UserProfileContext); // TODO Define types for userprofile context
	const [companyDetails, setCompanyDetails] = useState({});
	const [organisationTimezone, setOrganisationTimezone] = useState({});
	const [organisationCurrency, setOrganisationCurrency] = useState({});
	const [organisationFinanicialYear, setorganisationFinanicialYear] = useState(
		{}
	);

	const {
		data: organisation,
		refetch: refetchOrganisation,
		loading,
	} = useApolloQuery<OrganisationResponse>(GET_ORGANISATION, {
		variables: {
			organisationId: organisationId,
		},
	});
	useEffect(() => {
		if (!organisation) {
			return;
		}
		setCompanyDetails({
			organisationId: head(organisation?.organization)?.id,
			name: head(organisation?.organization)?.name,
			address_line1: head(organisation?.organization)?.address?.address_line1,
			address_line2: head(organisation?.organization)?.address?.address_line2,
			country: head(organisation?.organization)?.address?.country,
			postal_code: head(organisation?.organization)?.address?.postal_code,
		});
		setOrganisationTimezone({
			timezone: head(organisation?.organization)?.timezone,
		});
		setOrganisationCurrency({
			organisationId: head(organisation?.organization)?.id,
			currency_id: head(organisation?.organization)?.currency_id,
			supported_currency_id: head(
				organisation?.organization
			)?.org_supported_currencies.map(
				(supportedCurrency) => supportedCurrency?.currency_id
			),
		});
		setorganisationFinanicialYear({
			financial_year_start_month: head(organisation?.organization)
				?.financial_year_start_month,
			financial_year_start_date: head(organisation?.organization)
				?.financial_year_start_date,
			financial_year_end_month: head(organisation?.organization)
				?.financial_year_end_month,
			financial_year_end_date: head(organisation?.organization)
				?.financial_year_end_date,
		});
	}, [organisation]);

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<SettingsDetailContainer heading='Company details'>
				<CompanyDetailsSettings
					companyDetails={companyDetails}
					onSuccess={() => {
						refetchOrganisation();
					}}
				/>
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Location'>
				<Location />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Time Zone'>
				<TimeZoneSettings
					organisationId={organisationId}
					organisationTimezone={organisationTimezone}
					onSuccess={() => {
						refetchOrganisation();
					}}
				/>
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Currency'>
				<CurrencySettings
					organisationCurrency={organisationCurrency}
					onSuccess={() => {
						refetchOrganisation();
					}}
				/>
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Financial year'>
				<FinancialYearSettings
					organisationId={organisationId}
					organisationFinanicialYear={organisationFinanicialYear}
					onSuccess={() => {
						refetchOrganisation();
					}}
				/>
			</SettingsDetailContainer>

			<SettingsDetailContainer heading='Date format'>
				<DateFormatSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='HRMS Self Service'>
				<EditableColumns />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Timesheet Overtime'>
				<OvertimeSettings />
			</SettingsDetailContainer>
			{featureFlag?.emailNotification && <SettingsDetailContainer heading='Email Notification'>
				<EmailNotification />
			</SettingsDetailContainer>}
		</>
	);
};

export default OrganisationGeneralSettings;
