import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const KanbanIcon = (props) => (
	<SvgIcon {...props} viewBox='-6 -6 24 24'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
		>
			<defs>
				<style></style>
			</defs>
			<path
				className='a'
				d='M0,0V3H3V0ZM4.5,0V3h3V0ZM9,0V3h3V0ZM0,3V6.027H3V3Zm4.5-.45V5.106h3V3ZM9,3V7.5h3V3ZM0,7.5V12H3V7.5Zm4.5-.881V12h3V6.619ZM9,9v3h3V9Z'
			/>
		</svg>
	</SvgIcon>
);

export const ListIcon = (props) => (
	<SvgIcon {...props} viewBox='-5 0 23.392 10.068'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12.392'
			height='10.068'
			viewBox='0 0 12.392 10.068'
		>
			<path
				className='a'
				d='M1.162,3.375A1.162,1.162,0,1,0,2.323,4.537,1.162,1.162,0,0,0,1.162,3.375Zm0,3.872A1.162,1.162,0,1,0,2.323,8.409,1.162,1.162,0,0,0,1.162,7.247Zm0,3.872a1.162,1.162,0,1,0,1.162,1.162A1.162,1.162,0,0,0,1.162,11.12ZM12,11.507H4.26a.387.387,0,0,0-.387.387v.774a.387.387,0,0,0,.387.387H12a.387.387,0,0,0,.387-.387v-.774A.387.387,0,0,0,12,11.507Zm0-7.745H4.26a.387.387,0,0,0-.387.387v.774a.387.387,0,0,0,.387.387H12a.387.387,0,0,0,.387-.387V4.149A.387.387,0,0,0,12,3.762Zm0,3.872H4.26a.387.387,0,0,0-.387.387V8.8a.387.387,0,0,0,.387.387H12a.387.387,0,0,0,.387-.387V8.022A.387.387,0,0,0,12,7.635Z'
				transform='translate(0 -3.375)'
			/>
		</svg>
	</SvgIcon>
);
export const GridIcon = (props) => (
	<SvgIcon {...props} viewBox='-5 0 23.392 12.068'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12.392'
			height='12.392'
			viewBox='0 0 12.392 12.392'
		>
			<path
				className='a'
				d='M0,0V3H3V0ZM4.5,0V3h3V0ZM9,0V3h3V0ZM0,4.5v3H3v-3Zm4.5,0v3h3v-3ZM9,4.5v3h3v-3ZM0,9v3H3V9ZM4.5,9v3h3V9ZM9,9v3h3V9Z'
			/>
		</svg>
	</SvgIcon>
);

export const InsightsIcon = (props) => (
	<SvgIcon {...props} viewBox='-5 1 20.392 12.068'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14.477'
			height='14.477'
			viewBox='0 0 14.477 14.477'
		>
			<path
				d='M4.81,11.143,3,12.916V7.826H4.81m3.016,2.208-.947-.808-.863.8V5.413h1.81m3.016,3.619-1.81,1.81V3h1.81m1.695,5.917L11.445,7.826h3.016v3.016l-1.08-1.08L9.032,14.075,6.939,12.253l-2.28,2.208H3l3.9-3.824,2.129,1.8'
				transform='translate(-1.19 -1.19)'
			/>
		</svg>
	</SvgIcon>
);

export const CheckListProgressSectionIcon = (props) => (
	<SvgIcon {...props} viewBox='0 0 16.061 12.849'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='11.061'
			height='12.849'
			viewBox='0 0 16.061 12.849'
		>
			<path
				fill='#888888'
				className='a'
				d='M15.659,11.243H13.652v-8.4a1.225,1.225,0,0,0-1.2-1.241H9.637V3.212h2.409v9.637h3.614a.4.4,0,0,0,.4-.4v-.8A.4.4,0,0,0,15.659,11.243ZM7.836.025,3.017,1.274a.827.827,0,0,0-.608.807v9.162H.4a.4.4,0,0,0-.4.4v.8a.4.4,0,0,0,.4.4H8.833V.833a.81.81,0,0,0-1-.807Zm-1.211,7.2c-.333,0-.6-.36-.6-.8s.27-.8.6-.8.6.36.6.8S6.958,7.227,6.625,7.227Z'
				transform='translate(0 0)'
			/>
		</svg>
	</SvgIcon>
);
export const WebSiteIcon = (props) => (
	<SvgIcon {...props} viewBox='0 0 34.868 34.875'>
		<svg xmlns='http://www.w3.org/2000/svg' width='34.868' height='34.875'>
			<path
				id='Icon_awesome-globe'
				data-name='Icon awesome-globe'
				d='M23.66,11.25C22.641,4.971,20.236.563,17.438.563s-5.2,4.409-6.223,10.688ZM10.688,18a44.071,44.071,0,0,0,.232,4.5H23.948A44.071,44.071,0,0,0,24.18,18a44.071,44.071,0,0,0-.232-4.5H10.92A44.071,44.071,0,0,0,10.688,18Zm22.83-6.75A17.474,17.474,0,0,0,22.409,1.294c1.716,2.377,2.9,5.955,3.516,9.956ZM12.459,1.294a17.463,17.463,0,0,0-11.1,9.956H8.951c.612-4,1.793-7.58,3.509-9.956ZM34.27,13.5H26.205c.148,1.477.232,2.988.232,4.5s-.084,3.023-.232,4.5h8.058a17.287,17.287,0,0,0,.6-4.5A17.589,17.589,0,0,0,34.27,13.5ZM8.438,18c0-1.512.084-3.023.232-4.5H.6a17.047,17.047,0,0,0,0,9H8.663C8.522,21.023,8.438,19.512,8.438,18Zm2.777,6.75c1.02,6.279,3.424,10.688,6.223,10.688s5.2-4.409,6.223-10.687Zm11.2,9.956A17.491,17.491,0,0,0,33.525,24.75H25.931c-.619,4-1.8,7.58-3.516,9.956ZM1.357,24.75a17.474,17.474,0,0,0,11.109,9.956c-1.716-2.377-2.9-5.955-3.516-9.956H1.357Z'
				transform='translate(0 -0.563)'
			/>
		</svg>
	</SvgIcon>
);

export const BriefCaseIcon = (props) => (
	<SvgIcon {...props} viewBox='0 0 12.268 11.324'>
		<svg xmlns='http://www.w3.org/2000/svg' width='12.268' height='11.324'>
			<path
				id='Icon_ionic-md-briefcase'
				data-name='Icon ionic-md-briefcase'
				d='M12.34,6.859V5.691A1.188,1.188,0,0,0,11.149,4.5H7.869A1.188,1.188,0,0,0,6.678,5.691V6.859h-3.3v7.774a1.188,1.188,0,0,0,1.191,1.191h9.885a1.188,1.188,0,0,0,1.191-1.191V6.859Zm-1.18,0h-3.3V5.68h3.3Z'
				transform='translate(-3.375 -4.5)'
				fill='#4285f4'
			/>
		</svg>
	</SvgIcon>
);

export const AttachmentIcon = (props) => (
	<SvgIcon {...props} viewBox='0 0 12.045 13.045'>
		<svg xmlns='http://www.w3.org/2000/svg' width='12.045' height='13.045'>
			<path
				id='Icon_metro-attachment'
				data-name='Icon metro-attachment'
				d='M11.281,5.7h-1l-4,4a1.232,1.232,0,0,0,0,2,2.453,2.453,0,0,0,3,0l5-5a2.768,2.768,0,0,0,0-4,2.769,2.769,0,0,0-4,0l-6,5h0a4.373,4.373,0,0,0,0,6,4.375,4.375,0,0,0,6,0h0l4-4h-1l-4,3h0a2.828,2.828,0,0,1-4-4h0l5-5a2.455,2.455,0,0,1,3,0,1.233,1.233,0,0,1,0,2l-5,5a.818.818,0,0,1-1,0c-.227-.227-.227.227,0,0Z'
				transform='translate(-3.09 -1.842)'
				fill='#888'
			/>
		</svg>
	</SvgIcon>
);

export const ClockIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			id='Icon_awesome-clock'
			data-name='Icon awesome-clock'
			xmlns='http://www.w3.org/2000/svg'
			width='10'
			height='10'
			viewBox='0 0 10 10'
		>
			<path
				id='Icon_awesome-clock-2'
				data-name='Icon awesome-clock'
				d='M5.563.563a5,5,0,1,0,5,5A5,5,0,0,0,5.563.563ZM6.714,7.621,4.935,6.329a.244.244,0,0,1-.1-.2V2.74A.243.243,0,0,1,5.079,2.5h.968a.243.243,0,0,1,.242.242V5.516l1.28.931a.242.242,0,0,1,.052.339l-.569.782A.244.244,0,0,1,6.714,7.621Z'
				transform='translate(-0.563 -0.563)'
				fill='#888'
			/>
		</svg>
	</SvgIcon>
);
export const DateIcon = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='11.414'
		height='13.045'
		viewBox='0 0 11.414 13.045'
	>
		<path
			id='Icon_awesome-calendar-alt'
			data-name='Icon awesome-calendar-alt'
			d='M0,11.822a1.223,1.223,0,0,0,1.223,1.223h8.969a1.223,1.223,0,0,0,1.223-1.223V4.892H0ZM8.153,6.828a.307.307,0,0,1,.306-.306H9.478a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H8.459a.307.307,0,0,1-.306-.306Zm0,3.261a.307.307,0,0,1,.306-.306H9.478a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H8.459a.307.307,0,0,1-.306-.306ZM4.892,6.828A.307.307,0,0,1,5.2,6.523H6.217a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H5.2a.307.307,0,0,1-.306-.306Zm0,3.261A.307.307,0,0,1,5.2,9.784H6.217a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H5.2a.307.307,0,0,1-.306-.306ZM1.631,6.828a.307.307,0,0,1,.306-.306H2.956a.307.307,0,0,1,.306.306V7.848a.307.307,0,0,1-.306.306H1.936a.307.307,0,0,1-.306-.306Zm0,3.261a.307.307,0,0,1,.306-.306H2.956a.307.307,0,0,1,.306.306v1.019a.307.307,0,0,1-.306.306H1.936a.307.307,0,0,1-.306-.306Zm8.561-8.459H8.969V.408A.409.409,0,0,0,8.561,0H7.746a.409.409,0,0,0-.408.408V1.631H4.077V.408A.409.409,0,0,0,3.669,0H2.854a.409.409,0,0,0-.408.408V1.631H1.223A1.223,1.223,0,0,0,0,2.854V4.077H11.415V2.854A1.223,1.223,0,0,0,10.192,1.631Z'
			fill='#5C5C5C'
		/>
	</svg>
);
export const AttachmentButtonIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			id='Component_153_9'
			data-name='Component 153 – 9'
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 22 22'
		>
			<g
				id='Ellipse_72'
				data-name='Ellipse 72'
				fill='#fff'
				stroke='#e0e0e0'
				stroke-width='1'
			>
				<circle cx='11' cy='11' r='11' stroke='none' />
				<circle cx='11' cy='11' r='10.5' fill='none' />
			</g>
			<path
				id='Icon_metro-attachment'
				data-name='Icon metro-attachment'
				d='M9.755,5.138,9.118,4.5,5.93,7.688A1.352,1.352,0,0,0,7.843,9.6l3.825-3.825A2.254,2.254,0,1,0,8.48,2.588L4.464,6.6l-.009.008A3.143,3.143,0,1,0,8.9,11.057l.008-.009h0l2.741-2.741-.638-.637L8.272,10.411l-.008.008A2.242,2.242,0,1,1,5.093,7.25L5.1,7.242h0L9.118,3.226A1.352,1.352,0,0,1,11.03,5.138L7.205,8.962a.451.451,0,0,1-.638-.637L9.755,5.138Z'
				transform='translate(3.068 4.047)'
				fill='#4285f4'
			/>
		</svg>
	</SvgIcon>
);

export const TaskIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			id='Component_154_7'
			data-name='Component 154 – 7'
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 22 22'
		>
			<g
				id='Ellipse_18'
				data-name='Ellipse 18'
				fill='#fff'
				stroke='#e0e0e0'
				stroke-width='1'
			>
				<circle cx='11' cy='11' r='11' stroke='none' />
				<circle cx='11' cy='11' r='10.5' fill='none' />
			</g>
			<g
				id='Icon_awesome-calendar-check'
				data-name='Icon awesome-calendar-check'
				transform='translate(6.479 5.913)'
			>
				<path
					id='Icon_awesome-calendar-check-2'
					data-name='Icon awesome-calendar-check'
					d='M8.272,3.036H.228A.228.228,0,0,1,0,2.808V2.125a.911.911,0,0,1,.911-.911h.911V.228A.228.228,0,0,1,2.049,0h.759a.228.228,0,0,1,.228.228v.987H5.464V.228A.228.228,0,0,1,5.692,0h.759a.228.228,0,0,1,.228.228v.987h.911a.911.911,0,0,1,.911.911v.683A.228.228,0,0,1,8.272,3.036ZM.228,3.643H8.272a.228.228,0,0,1,.228.228V8.8a.911.911,0,0,1-.911.911H.911A.911.911,0,0,1,0,8.8V3.871A.228.228,0,0,1,.228,3.643Zm6.324,1.82-.534-.539a.228.228,0,0,0-.322,0l-2.012,2-.872-.879a.228.228,0,0,0-.322,0l-.539.534a.228.228,0,0,0,0,.322l1.567,1.58a.228.228,0,0,0,.322,0L6.55,5.785a.228.228,0,0,0,0-.322Z'
					fill='#4285f4'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const NotesIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			id='Component_152_7'
			data-name='Component 152 – 7'
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='22'
			viewBox='0 0 22 22'
		>
			<g
				id='Ellipse_72'
				data-name='Ellipse 72'
				fill='#fff'
				stroke='#e0e0e0'
				stroke-width='1'
			>
				<circle cx='11' cy='11' r='11' stroke='none' />
				<circle cx='11' cy='11' r='10.5' fill='none' />
			</g>
			<g id='MDI_text' data-name='MDI / text' transform='translate(5 5)'>
				<g
					id='Boundary'
					fill='#4285f4'
					stroke='rgba(0,0,0,0)'
					stroke-width='1'
					opacity='0'
				>
					<rect width='12.234' height='12.234' stroke='none' />
					<rect x='0.5' y='0.5' width='11.234' height='11.234' fill='none' />
				</g>
				<path
					id='Path_text'
					data-name='Path / text'
					d='M12.176,6V7.02H3V6h9.176M3,12.117H7.588V11.1H3v1.02M3,9.568h9.176V8.549H3Z'
					transform='translate(-1.471 -2.941)'
					fill='#4285f4'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const SearchIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='11.589'
			height='11.589'
			viewBox='0 0 11.589 11.589'
		>
			<g
				id='Icon_feather-search'
				data-name='Icon feather-search'
				transform='translate(0.75 0.75)'
			>
				<path
					id='Path_19'
					data-name='Path 19'
					d='M13.192,8.846A4.346,4.346,0,1,1,8.846,4.5a4.346,4.346,0,0,1,4.346,4.346Z'
					transform='translate(-4.5 -4.5)'
					fill='none'
					stroke='#7c7c7c'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='1.5'
				/>
				<path
					id='Path_20'
					data-name='Path 20'
					d='M27.338,27.338l-2.363-2.363'
					transform='translate(-17.56 -17.56)'
					fill='none'
					stroke='#7c7c7c'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='1.5'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const ExportIcon = (props) => (
	<SvgIcon {...props}>
		<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
			<g id='Share'>
				<path
					clipRule='evenodd'
					d='M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z'
					fill='#121313'
					fillRule='evenodd'
				/>
				<path
					clipRule='evenodd'
					d='M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z'
					fill='#121313'
					fillRule='evenodd'
				/>
			</g>
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
		</svg>
	</SvgIcon>
);

export const SubTaskIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 65 67'
		>
			<g
				id='Group_1143'
				data-name='Group 1143'
				transform='translate(-4656 -4995.425)'
			>
				<rect
					id='Rectangle_1032'
					data-name='Rectangle 1032'
					width='65'
					height='15'
					rx='5'
					transform='translate(4656 4995.425)'
					fill='#858282'
				/>
				<path
					id='Path_349'
					data-name='Path 349'
					d='M4672.021,5011.555V5056.2h23.916'
					transform='translate(-2.229 -2.304)'
					fill='none'
					stroke='#858282'
					stroke-linejoin='round'
					stroke-width='8'
				/>
				<path
					id='Path_350'
					data-name='Path 350'
					d='M4672.021,5033.9h23.916'
					transform='translate(-2.229 -5.413)'
					fill='none'
					stroke='#858282'
					stroke-width='8'
				/>
				<rect
					id='Rectangle_1033'
					data-name='Rectangle 1033'
					width='13'
					height='13'
					rx='4'
					transform='translate(4695 5021.425)'
					fill='none'
					stroke='#858282'
					stroke-linejoin='round'
					stroke-width='4'
				/>
				<rect
					id='Rectangle_1034'
					data-name='Rectangle 1034'
					width='13'
					height='13'
					rx='4'
					transform='translate(4695 5047.425)'
					fill='none'
					stroke='#858282'
					stroke-linejoin='round'
					stroke-width='4'
				/>
			</g>
		</svg>
	</SvgIcon>
);

export const ExportReportIcon = (props) => (
	<SvgIcon {...props}>
		<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
			<g id='Share'>
				<path
					clipRule='evenodd'
					d='M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z'
					fill='currentColor'
					fillRule='evenodd'
				/>
				<path
					clipRule='evenodd'
					d='M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z'
					fill='currentColor'
					fillRule='evenodd'
				/>
			</g>
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
		</svg>
	</SvgIcon>
);

export const HeadingIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			width='24px'
			height='24px'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M18 20V4h-3v6H9V4H6v16h3v-7h6v7z' />
		</svg>
	</SvgIcon>
);

export const TimesheetIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			width='21'
			height='20'
			viewBox='0 0 21 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2.81543 0H16.8154C17.3459 0 17.8546 0.210714 18.2296 0.585786C18.6047 0.960859 18.8154 1.46957 18.8154 2V8.1C20.0554 9.36 20.8154 11.09 20.8154 13C20.8154 14.8565 20.0779 16.637 18.7652 17.9497C17.4524 19.2625 15.6719 20 13.8154 20C11.9054 20 10.1754 19.24 8.91543 18H2.81543C2.285 18 1.77629 17.7893 1.40122 17.4142C1.02614 17.0391 0.81543 16.5304 0.81543 16V2C0.81543 1.46957 1.02614 0.960859 1.40122 0.585786C1.77629 0.210714 2.285 0 2.81543 0ZM2.81543 13V16H7.48543C7.05543 15.09 6.81543 14.07 6.81543 13H2.81543ZM2.81543 6H8.81543V3H2.81543V6ZM16.8154 6V3H10.8154V6H16.8154ZM2.81543 11H7.10543C7.44543 9.85 8.07543 8.82 8.91543 8H2.81543V11ZM13.8154 8.15C12.5291 8.15 11.2955 8.66098 10.386 9.57053C9.47641 10.4801 8.96543 11.7137 8.96543 13C8.96543 15.68 11.1354 17.85 13.8154 17.85C14.4523 17.85 15.083 17.7246 15.6714 17.4808C16.2599 17.2371 16.7945 16.8798 17.2449 16.4295C17.6953 15.9791 18.0525 15.4444 18.2962 14.856C18.54 14.2676 18.6654 13.6369 18.6654 13C18.6654 10.32 16.4954 8.15 13.8154 8.15Z'
				fill='white'
			/>
			<path
				d='M10.909 12.0081C10.909 11.87 11.021 11.7581 11.159 11.7581H16.659C16.7971 11.7581 16.909 11.87 16.909 12.0081V13.5081C16.909 13.6462 16.7971 13.7581 16.659 13.7581H11.159C11.021 13.7581 10.909 13.6462 10.909 13.5081V12.0081Z'
				fill='white'
			/>
			<path
				d='M13.159 15.7581C13.021 15.7581 12.909 15.6462 12.909 15.5081V10.0081C12.909 9.87005 13.021 9.75812 13.159 9.75812H14.659C14.7971 9.75812 14.909 9.87005 14.909 10.0081V15.5081C14.909 15.6462 14.7971 15.7581 14.659 15.7581H13.159Z'
				fill='white'
			/>
		</svg>
	</SvgIcon>
);

export const TimesheetButtonIcon = (props) => (
	<SvgIcon {...props}>
		<svg
			width='21'
			height='20'
			viewBox='0 0 21 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2.81543 0H16.8154C17.3459 0 17.8546 0.210714 18.2296 0.585786C18.6047 0.960859 18.8154 1.46957 18.8154 2V8.1C20.0554 9.36 20.8154 11.09 20.8154 13C20.8154 14.8565 20.0779 16.637 18.7652 17.9497C17.4524 19.2625 15.6719 20 13.8154 20C11.9054 20 10.1754 19.24 8.91543 18H2.81543C2.285 18 1.77629 17.7893 1.40122 17.4142C1.02614 17.0391 0.81543 16.5304 0.81543 16V2C0.81543 1.46957 1.02614 0.960859 1.40122 0.585786C1.77629 0.210714 2.285 0 2.81543 0ZM2.81543 13V16H7.48543C7.05543 15.09 6.81543 14.07 6.81543 13H2.81543ZM2.81543 6H8.81543V3H2.81543V6ZM16.8154 6V3H10.8154V6H16.8154ZM2.81543 11H7.10543C7.44543 9.85 8.07543 8.82 8.91543 8H2.81543V11ZM13.8154 8.15C12.5291 8.15 11.2955 8.66098 10.386 9.57053C9.47641 10.4801 8.96543 11.7137 8.96543 13C8.96543 15.68 11.1354 17.85 13.8154 17.85C14.4523 17.85 15.083 17.7246 15.6714 17.4808C16.2599 17.2371 16.7945 16.8798 17.2449 16.4295C17.6953 15.9791 18.0525 15.4444 18.2962 14.856C18.54 14.2676 18.6654 13.6369 18.6654 13C18.6654 10.32 16.4954 8.15 13.8154 8.15Z'
				fill='#4285f4'
			/>
			<path
				d='M10.909 12.0081C10.909 11.87 11.021 11.7581 11.159 11.7581H16.659C16.7971 11.7581 16.909 11.87 16.909 12.0081V13.5081C16.909 13.6462 16.7971 13.7581 16.659 13.7581H11.159C11.021 13.7581 10.909 13.6462 10.909 13.5081V12.0081Z'
				fill='#4285f4'
			/>
			<path
				d='M13.159 15.7581C13.021 15.7581 12.909 15.6462 12.909 15.5081V10.0081C12.909 9.87005 13.021 9.75812 13.159 9.75812H14.659C14.7971 9.75812 14.909 9.87005 14.909 10.0081V15.5081C14.909 15.6462 14.7971 15.7581 14.659 15.7581H13.159Z'
				fill='#4285f4'
			/>
		</svg>
	</SvgIcon>
);
