import React, { useState, useEffect } from 'react';
import { useNotify, AutocompleteInput, ReferenceInput } from 'react-admin';

import { ThemeProvider } from '@material-ui/core/styles';
import { head, isEmpty } from 'lodash';
import { modalFormTheme } from '../../Layout/Theme.component';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { modalFormStyle } from '../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropdownOption } from '../../SharedComponents/model';
import {
	useAddInvitedUserMutation,
	useGetInvitedUserQuery,
	useCheckInvitedUserQuery,
} from '../../generated/graphql';
import InvitedUserDeleteModal from './InvitedUserDeleteModal.component';

const useStyles = makeStyles(() => ({
	table: {
		overflowY: 'auto',
		height: '200px',
	},
	name: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	deleteIcon: {
		color: '#787575',
	},
	error: {
		color: '#f44336',
	},
}));

interface InviteUserFormProps {
	open: boolean;
	onClose: () => void;
	boardId: string;
}

interface User {
	first_name: string;
	last_name?: string | null;
	full_name?: string;
}

interface inviteUserProps {
	user: User;
	id: string;
}

interface DeleteUser {
	name: string;
	id: string;
	user_id?: string;
}

const InviteUserForm = (props: InviteUserFormProps) => {
	const { open, onClose, boardId } = props;
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [invitedUserId, setInvitedUserId] = useState('');
	const [userName, setUserName] = useState('');
	const [invitesUsers, setInvitesUsers] = useState<DropdownOption[]>();
	const [userId, setUserId] = useState('');
	const [deleteUserId, setDeleteUserId] = useState<string | undefined>('');
	const [userError, setUserError] = useState('');
	const classes = modalFormStyle();
	const inlineStyle = useStyles();
	const notify = useNotify();
	const [addInvitedUser] = useAddInvitedUserMutation();
	const { data: invitedUsers, refetch: refetchInvitedUsers } =
		useGetInvitedUserQuery({
			variables: {
				board_id: boardId,
			},
		});

	const { data: checkUser } = useCheckInvitedUserQuery({
		variables: {
			boardId,
			userId,
		},
	});

	useEffect(() => {
		if (isEmpty(invitedUsers?.kanban_board_invited_users)) {
			setInvitesUsers([]);
			return;
		}
		const inviteUser = invitedUsers?.kanban_board_invited_users?.map((user) => {
			return {
				name: `${user?.user?.full_name} `,
				id: user?.id,
				user_id: user?.user?.id,
			};
		});
		setInvitesUsers(inviteUser);
	}, [invitedUsers]);

	const onSubmit = () => {
		const user = head(checkUser?.kanban_board_invited_users)?.user;
		if (!userId) {
			return;
		}
		if (user) {
			setUserError(`${user?.full_name} is already present`);
			return;
		}
		addInvitedUser({
			variables: {
				invitedUser: {
					board_id: boardId,
					user_id: userId,
					is_primary: false,
				},
			},
		}).then((data) => {
			if (data) {
				refetchInvitedUsers();
				setUserId('');
				setUserError('');
				notify('User Invited Successfully');
			}
		});
	};

	const handleClose = () => {
		setUserId('');
		setUserError('');
		onClose();
	};

	const handleDelete = (user: DeleteUser) => {
		if (!user) {
			return;
		}
		setIsDeleteModalShown(true);
		setUserName(user?.name);
		setInvitedUserId(user?.id);
		setDeleteUserId(user?.user_id);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box width='400px' padding='20px' borderRadius='4px'>
					<Box className={classes.headerContainer}>
						<Box className={classes.heading}>Invite User</Box>
						<CloseIcon
							id='opp_invite_user_close_icon'
							className={classes.closeIcon}
							onClick={handleClose}
						/>
					</Box>
					<Form onSubmit={onSubmit} initialValues={{ userId }}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box
									className={classes.multipleInputContainer}
									marginTop='10px'
								>
									<Box>
										<ReferenceInput
											source='userId'
											reference='user'
											sort={{ field: 'full_name', order: 'ASC' }}
											label={''}
											filterToQuery={(searchText) => ({
												first_name: searchText,
												is_active: true,
											})}
											onChange={(event) => setUserId(event)}
										>
											<AutocompleteInput
												optionText={(user: any) => `${user?.full_name} `}
												resettable={true}
												shouldRenderSuggestions={(name: any) =>
													name?.trim().length > 0
												}
											/>
										</ReferenceInput>
									</Box>
									<Box>
										<Button
											className={
												!userId ? classes.disabledButton : classes.saveButton
											}
											disabled={invalid}
											type='submit'
										>
											Invite
										</Button>
									</Box>
								</Box>
								{userError && (
									<Box className={inlineStyle.error}>{userError}</Box>
								)}
							</form>
						)}
					</Form>
					{invitesUsers && invitesUsers.length > 0 && (
						<Box className={inlineStyle.table}>
							<TableContainer component={Paper}>
								<Table aria-label='simple table'>
									<TableBody>
										{invitesUsers?.map((user, index) => {
											return (
												<TableRow key={index}>
													<TableCell
														component='th'
														scope='row'
														className={inlineStyle.name}
													>
														{user.name}
													</TableCell>
													<TableCell
														component='th'
														scope='row'
														onClick={() => handleDelete(user)}
													>
														<DeleteIcon className={inlineStyle.deleteIcon} />
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					)}
				</Box>
			</Dialog>
			<InvitedUserDeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				userName={userName}
				invitedUserId={invitedUserId}
				refetchInvitedUser={refetchInvitedUsers}
				boardId={boardId}
				deleteUserId={deleteUserId}
			/>
		</ThemeProvider>
	);
};

export default InviteUserForm;
