export const DEFAULT_EMPTY_OVERTIME_TEXT =
	'No entries are available! You can add new entry.';

export const DEFAULT_OVERTIME_COPY_TEXT =
	'Copy all data from the most recent timesheet';

export const OVERTIME_ADD_NOTIFICATION_TEXT =
	'Overtime Entered Successfully...';

export const OVERTIME_UPDATE_NOTIFICATION_TEXT =
	'Overtime Updated Successfully...';

export const OVERTIME_DELETE_NOTIFICATION_TEXT =
	'Overtime Entry Deleted Successfully...';
 
export const OVERTIME_SUBMISSION_CONFIRMATION_TEXT =
	'Ready to submit this overtime for approval?';

export const OVERTIME_SUBMISSION_SUCCESS_TEXT =
	'Your overtime has been successfully submitted!';

export const OVERTIME_APPROVAL_CONFIRMATION_TEXT =
	'Do you really want to approve this record(s)?';

export const OVERTIME_APPROVAL_SUCCESS_TEXT =
	'Your overtime has been successfully approved!';

export const OVERTIME_REJECT_SUCCESS_TEXT =
	'Your overtime has been successfully rejected!';

export const OVERTIME_APPROVAL_MODAL_BUTTON_LABEL = 'Yes! Approve';

export const OVERTIME_REJECT_CONFIRMATION_TEXT =
	'Do you really want to reject this record?';

export const OVERTIME_REJECT_MODAL_BUTTON_LABEL = 'Reject';

export const OVERTIME_DELETE_CONFIRMATION_TEXT =
	'Are you sure want to delete this overtime entry?. This action cannot be undone.';

export const WEEKLY_OVERTIME_CHANGES_SAVED_TEXT =
	'Changes has been saved successfully';

export const PROJECT_TYPE_CATEGORY = 'project';

export const MASTER_PROJECT_TASK_TYPE = 'master_project_task';

export const ORG_TASK_TYPE = 'org_task';

export const PROJECT_TASK_GROUP_LABEL = 'Tasks';

export const MASTER_PROJECT_GROUP_LABEL = 'Master Project Tasks';

export const ORG_TASK_GROUP_LABEL = 'Organisation Tasks';

export const TIMESHEET_OVERTIME_SUBMITTED_STATUS = 'submitted';

export const TIMESHEET_OVERTIME_APPROVED_STATUS = 'approved';

// REGEX
export const checkIfOnlyNumbers = /^[0-9]+$/;

export const checkIfOnlyOneColonAfterNumbers = /^([0-9]+):{1}$/; // 11: or 23:

export const checkIfOnlyOneNumberAfterColon = /^([0-9]+):{1}([0-9]{1})$/; // 11:1 or 23:1

export const validHHMMFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; //HH:MM

export const checkIfOnlyZeroesWithOneColon = /^(0+):{1}(0+)$/;

export const checkIfOnlyOneColon = /:{1}/;

export const START_DATE_ERROR =
	'Start date should not be greater than end date';

export const timesheetApprovedConstraintError =
	'insert/update permission has failed';

export const OVERTIME_SECTIONS = {
	timesheet: 'timesheet',
	overtime: 'overtime',
	insights: 'insights',
};
