import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  SelectInput,
  TextInput,
  DateInput,
  useMutation,
  useQuery,
  useNotify,
  required
} from "react-admin";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import MilestoneList from "./MilestoneList.component";
import { endDateValidation } from "../../Utils/date-time.util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightRegular,
  },
  RaFormInput: {
    width: "100%",
  },
  container: {
    width: "100%",
  },
  buttonCursor: {
    cursor: "pointer",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "15%",
  },
}));

export const ProjectMilestone = ({ projectId, refresh }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [name, setName] = useState(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updatedMilestoneId, setUpdatedMilestoneId] = useState(null);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [mutate] = useMutation();
  const validateEndDate =[required(), endDateValidation];

  const { data: milestones } = useQuery({
    type: "GET_LIST",
    resource: "project_milestone",
    payload: {
      filter: { id: updatedMilestoneId ? updatedMilestoneId : "" },
    },
  });

  const { data: duplicatedMilestones } = useQuery({
    type: "GET_LIST",
    resource: "project_milestone",
    payload: {
      filter: {
        name: name,
        start_date: startDate,
        end_date: endDate,
        status: status,
        project_id: projectId,
      },
    },
  });

  useEffect(() => {
    if (milestones) {
      const updatedMilestone = {
        name: milestones[0].name,
        status: milestones[0].status,
        start_date: milestones[0].start_date,
        end_date: milestones[0].end_date,
      };
      setSelectedMilestone(updatedMilestone);
    }
  }, [milestones, updatedMilestoneId]);

  const onStatusChange = (status) => {
    status?.target?.value ? setStatus(status.target.value) : setStatus("");
  };

  const onClear = () => {
    refresh();
  };

  const saveProjectMilestone = (event) => {
    if (!updatedMilestoneId) {
      if (duplicatedMilestones.length > 0) {
        setName("")
        setStartDate("")
        setEndDate("")
        notify('Failed to create a duplicate record', 'warning')
        onClear();
        return
      }
      mutate({
        type: "create",
        resource: "project_milestone",
        payload: {
          data: {
            name: name,
            start_date: startDate,
            end_date: endDate,
            status: status,
            project_id: projectId,
          },
        },
      });
      onClear();
      return;
    }
    mutate({
      type: "update",
      resource: "project_milestone",
      payload: {
        id: updatedMilestoneId,
        data: {
          project_id: projectId,
          name: name || selectedMilestone.name,
          status: status || selectedMilestone.status,
          start_date: startDate || selectedMilestone.start_date,
          end_date: endDate || selectedMilestone.end_date,
        },
      },
    });
    onClear();
  };

  const onEditRowClick = (milestoneId) => {
    setUpdatedMilestoneId(milestoneId);
  };

  const CustomToolbar = () => (
        <Box className={classes.buttonStyle}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={saveProjectMilestone}
              disabled ={ !(name && status && startDate && endDate )}
              className={classes.buttonCursor}>SAVE</Button>
          </Box>
        </Box>
  );

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" className={classes.heading}>ADD MILESTONE</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SimpleForm
            className={classes.container}
            toolbar={<CustomToolbar />}
            initialValues={
              selectedMilestone
                ? selectedMilestone
                : { name: "", status: "", start_date: "", end_date: "" }
            }
          >
            <Box display="flex" className={classes.RaFormInput}>
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="name"
                  fullWidth
                  label="Name"
                  onChange={(event) => setName(event.target.value)}
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <SelectInput
                  resettable={true}
                  source="status"
                  fullWidth
                  onChange={(status) => onStatusChange(status)}
                  choices={[
                    { id: "InProgress", name: "In Progress" },
                    { id: "Complete", name: "Complete" },
                    { id: "New", name: "New" },
                    { id: "PaymentReceived", name: "Payment Received" },
                    { id: "PaymentPending", name: "Payment Pending" },
                  ]}
                />
              </Box>
            </Box>
            <Box display="flex" className={classes.RaFormInput}>
              <Box flex={1} mr="0.5em">
                <DateInput
                  source="start_date"
                  label="Start Date"
                  onChange={(event) => setStartDate(event.target.value)}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <DateInput
                  source="end_date"
                  label="End Date"
                  onChange={(event) => setEndDate(event.target.value)}
                  fullWidth
                  validate={validateEndDate}
                />
              </Box>
            </Box>
          </SimpleForm>
        </AccordionDetails>
        <Box>
          <MilestoneList projectId={projectId} onEdit={onEditRowClick} />
        </Box>
      </Accordion>
    </div>
  );
};
export default ProjectMilestone;
