import React, { useEffect, useState, useContext } from 'react';
import { FunctionField, ReferenceField, TextField } from 'react-admin';
import { getTotalExperience } from '../../../Utils/date-time.util';

import { AvatarField } from '../../../SharedComponents/AvatarField.component';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ConsultantBasicDetailsForm from './ConsultantBasicDetailsForm.component';
import { detailsLeftCardStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';
import { ellipsisStyle } from '../../../Layout/styles';
import { formatEmployeeId, getPrefix } from '../../../Utils/string.util';

const useStyles = makeStyles((theme) => ({
	finalContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	container: {
		width: '246px',
		height: '600px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	secondaryInfoField: {
		width: '100px',
		margin: '0 auto',
		font: 'normal normal 600 12px/20px Manrope !important',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	fullNameField: {
		marginLeft: '25px',
		marginRight: '25px',
	},
}));
interface User {
	__typename?: string;
	id: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	full_name: string;
	profile_pic?: any;
	email: string;
	is_active: boolean;
	phone_number: string;
	organization?: {
		contractor_id_prefix?: string;
	};
}
interface Status {
	__typename?: string;
	id: string;
	label: string;
}
interface Prefix {
	id: string;
	name: string;
	value: string;
}
interface Dept {
	name: string;
}
interface Designation {
	title: string;
	id: string;
}
interface Record {
	__typename?: string;
	user: User;
	contractorStatus: Status;
	is_billable: boolean;
	vendor_type: string;
	contractor_id: string;
	working_mode: string;
	job_level_id: string;
	department_id: string;
	designation: Designation;
	contractor_status_id: string;
	reportee_id: string;
	total_experience: string;
	contractor_prefix_id?: string;
	id: string;
	department: Dept;
	reportee: {
		is_active: boolean;
	};
	location_id?: string;
	contractor_prefix?: Prefix;
}

interface Props {
	record: Record;
	basePath: string;
	resource: string;
	refetch: () => void;
}

export const ConsultantDetailsCard = (props: Props) => {
	const { record: consultant, basePath, resource, refetch } = props;
	const classes = useStyles();
	const globalClass = detailsLeftCardStyle();
	const [isFormShown, setIsFormShown] = useState(false);
	const [consultantDetails, setConsultantDetails] = useState({});
	const {
		permissions: permissionList,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);
	const styles = ellipsisStyle();

	useEffect(() => {
		const experience = getTotalExperience(consultant?.total_experience);
		setConsultantDetails({
			id: consultant?.id,
			user_id: consultant?.user?.id,
			first_name: consultant?.user?.first_name,
			middle_name: consultant?.user?.middle_name
				? consultant?.user?.middle_name
				: '',
			last_name: consultant?.user?.last_name,
			full_name: consultant?.user?.full_name,
			contractor_id: consultant?.contractor_id,
			job_level_id: consultant?.job_level_id,
			department_id: consultant?.department_id,
			designation_id: consultant?.designation?.id || null,
			contractor_status_id: consultant?.contractor_status_id,
			reportee_id: consultant?.reportee_id,
			working_mode: consultant?.working_mode,
			is_billable: consultant?.is_billable,
			years: experience?.years,
			months: experience?.months,
			vendor_type: consultant?.vendor_type,
			phone_number: consultant?.user?.phone_number,
			contractorPrefix:
				consultant?.user?.organization?.contractor_id_prefix || '',
			location_id: consultant?.location_id || null,
			contractor_prefix_id: consultant?.contractor_prefix_id || null,
		});
	}, [consultant]);

	const formattedConsultantId = formatEmployeeId(
		consultant?.contractor_id,
		empIdLength,
		isFixedEmpIdLengthEnabled
	);

	return (
		<div className={classes.container}>
			<div className={globalClass.avatarContainer}>
				<div className={globalClass.avatarField}>
					<AvatarField
						imageSrc={consultant?.user?.profile_pic}
						firstName={consultant?.user?.first_name}
						lastName={consultant?.user?.last_name}
						size='90'
						className=''
					/>
				</div>
				<div className={globalClass.editButtonContainer}>
					{permissionList?.contractor?.update_permissions && (
						<div
							onClick={() => setIsFormShown(true)}
							className={globalClass.editButton}
						>
							<EditIcon className={globalClass.editIcon} />
						</div>
					)}
				</div>
			</div>

			<div className={globalClass.identityContainer}>
				<FunctionField
					record={consultant?.user}
					render={(record: any) => {
						return (
							<Tooltip
								title={`${record?.full_name || '- -'}`}
								placement='right'
							>
								<Typography
									className={`${globalClass.nameField} ${styles.ellipsis} ${classes.fullNameField}`}
								>
									{`${record?.full_name || '- -'}`}
								</Typography>
							</Tooltip>
						);
					}}
				/>

				<FunctionField
					record={consultant}
					render={(record: any) => {
						return (
							<Tooltip
								title={`${record?.designation?.title || ''}`}
								placement='right'
							>
								<Typography
									className={`${classes.secondaryInfoField} ${styles.ellipsis}`}
								>
									{record?.designation?.title || ''}
								</Typography>
							</Tooltip>
						);
					}}
				/>

				<FunctionField
					record={consultant?.user}
					render={(record: any) => {
						return (
							<Tooltip title={`${record?.email || '- -'}`} placement='right'>
								<Typography
									className={`${globalClass.additionalInfoField} ${styles.ellipsis} ${classes.fullNameField}`}
								>
									{`${record?.email || '- -'}`}
								</Typography>
							</Tooltip>
						);
					}}
				/>

				{consultant?.user?.phone_number && (
					<Box display='flex' justifyContent='center' alignItems='center'>
						<SmartphoneIcon className={globalClass.phoneIcon} />
						<Box className={globalClass.additionalInfoField}>
							{consultant?.user?.phone_number}
						</Box>
					</Box>
				)}

				<div className={globalClass.infoBarContainer}>
					<div className={globalClass.infoBar}>
						<div
							className={[
								globalClass.activeIcon,
								consultant?.user?.is_active
									? globalClass.activeColor
									: globalClass.inactiveColor,
							].join(' ')}
						/>
						<div className={globalClass.infoBarValueContainer}>
							<FunctionField
								record={consultant}
								render={(employee: any) => (
									<Typography className={globalClass.infoField}>
										{employee?.user?.is_active ? 'Active' : 'Inactive'}
									</Typography>
								)}
							/>
						</div>
					</div>
					<div className={globalClass.infoBar}>
						<AttachMoneyIcon className={globalClass.billableIcon} />
						<div className={globalClass.infoBarValueContainer}>
							<FunctionField
								className={globalClass.infoField}
								record={consultant}
								render={(employee: any) =>
									`${employee?.is_billable ? 'Billable' : 'Non-Billable'}`
								}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className={globalClass.addtionalInfoContainer}>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Consultant ID
					</Typography>
					{consultant?.contractor_id ? (
						<Tooltip
							title={`${
								getPrefix(
									consultant?.contractor_prefix?.name,
									consultant?.contractor_prefix?.value
								) || ''
							}${formattedConsultantId}`}
						>
							<Typography
								className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
							>
								{`${
									getPrefix(
										consultant?.contractor_prefix?.name,
										consultant?.contractor_prefix?.value
									) || ''
								}${formattedConsultantId}`}
							</Typography>
						</Tooltip>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Job Level
					</Typography>
					{consultant?.job_level_id ? (
						<ReferenceField
							link={false}
							label='Job level'
							source='job_level_id'
							reference='job_level'
							record={consultant}
							basePath={basePath}
							resource={resource}
						>
							<TextField
								source='level'
								className={globalClass.additionalInfoValue}
							/>
						</ReferenceField>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Department
					</Typography>
					{consultant?.department_id ? (
						<Tooltip
							title={`${consultant?.department?.name || ' - -'}`}
							placement='right'
						>
							<div
								className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
							>
								{`${consultant?.department?.name || ' - -'}`}
							</div>
						</Tooltip>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Type
					</Typography>
					{consultant?.vendor_type ? (
						<TextField
							source='vendor_type'
							record={consultant}
							className={globalClass.additionalInfoValue}
						/>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Status
					</Typography>
					{consultant?.contractorStatus?.label ? (
						<ReferenceField
							link={false}
							label=''
							source='contractor_status_id'
							reference='contractor_status'
							resource={resource}
							record={consultant}
							basePath={basePath}
						>
							<TextField
								source='label'
								className={globalClass.additionalInfoValue}
							/>
						</ReferenceField>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Work Experience
					</Typography>
					{consultant ? (
						<FunctionField
							record={consultant}
							className={globalClass.additionalInfoValue}
							render={(consultant: any) => {
								const experience = getTotalExperience(
									consultant?.total_experience
								);
								return experience
									? `${experience?.years} Year ${experience?.months} Month`
									: '- -';
							}}
						/>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={globalClass.additionalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Reporting to
					</Typography>
					{consultant?.reportee_id && consultant?.reportee?.is_active ? (
						<ReferenceField
							link={false}
							source='reportee_id'
							reference='user'
							record={consultant}
							basePath={basePath}
							resource={resource}
						>
							<FunctionField
								render={(consultant: any) => {
									return (
										<Tooltip
											title={`${`${consultant?.full_name || ''}` || '--'}`}
											placement='right'
										>
											<Typography
												className={`${globalClass.additionalInfoValue} ${styles.ellipsis}`}
											>
												{`${`${consultant?.full_name || ''}` || '--'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
				<div className={classes.finalContent}>
					<Typography className={globalClass.additionalInfoLabel}>
						Mode of Work
					</Typography>
					{consultant?.working_mode ? (
						<TextField
							source='working_mode'
							record={consultant}
							className={globalClass.additionalInfoValue}
						/>
					) : (
						<Typography className={globalClass.additionalInfoValue}>
							- -
						</Typography>
					)}
				</div>
			</div>
			<ConsultantBasicDetailsForm
				open={isFormShown}
				onClose={() => setIsFormShown(false)}
				record={consultantDetails}
				refetch={() => refetch()}
			/>
		</div>
	);
};

export default ConsultantDetailsCard;
