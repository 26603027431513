import { gql } from '@apollo/client';

export const GET_ORGANISATION_FINANICIAL_YEAR = gql`
	query getOrganisationFinanicialYear($organisationId: uuid!) {
		organization_by_pk(id: $organisationId) {
			financial_year_start_month
			financial_year_end_month
			financial_year_start_date
			financial_year_end_date
		}
	}
`;
export const GET_ORGANISATION_LEAVE_CALENDER_YEAR = gql`
	query getOrganisationLeaveCalenderYear($organisationId: uuid!) {
		organization_by_pk(id: $organisationId) {
			leave_calendar_year_end_date
			leave_calendar_year_end_month
			leave_calendar_year_start_date
			leave_calendar_year_start_month
		}
	}
`;

export const GET_ORGANISATION_LOGO = gql`
	query getOrganisationLogo($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			logo_url
		}
	}
`;

export const GET_ORGANISATION_FEATURE_FLAGS = gql`
	query getOrganisationFeatures($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			feature_flags
		}
	}
`;

export const GET_ORGANISATION_MAN_HOURS = gql`
	query getOrganisationManHours($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			working_hours
		}
	}
`;

export const GET_HOLIDAYS_COUNT = gql`
	query getOrgHolidaysCount($startDate: date, $endDate: date) {
		org_holidays_aggregate(
			where: { date: { _gte: $startDate, _lt: $endDate } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_TIMESHEET_ENTRIES_COUNT_FOR_PROJECT = gql`
	query getProjectTimesheetEntryCount($projectId: uuid!) {
		project_by_pk(id: $projectId) {
			timesheets_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_ORGANIZATION_HOLIDAY = gql`
	query getOrgHolidays($startDate: date, $endDate: date) {
		org_holidays(where: { date: { _gte: $startDate, _lte: $endDate } }) {
			name
			date
			org_location {
				id
				name
			}
		}
	}
`;

export const GET_ORGANISATION_DATE_FORMAT = gql`
	query getOrganizationDetails($orgId: uuid!) {
		org_location_aggregate(where: { org_id: { _eq: $orgId } }) {
			aggregate {
				count
			}
		}
		organization_by_pk(id: $orgId) {
			date_format
			editable_columns
			enable_emp_ss
			enable_usr_custom_pic
			enable_overtime
			employee_id_length
			enable_fixed_employee_id_length
			timesheet_calculation_status
			enable_leave
			enable_attendance_regularization
			attendance_regularization_day_count
			currency {
				id
				currency_type
			}
			timesheet_settings {
				track_day_type
				enable_draft
				enable_weekly_entry
			}
		}
	}
`;

export const GET_ORGANISATION_LEAVE_DETAILS = gql`
	query getOrganizationLeaveSettings($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			enable_leave
		}
	}
`;

export const GET_PROJECT_CATEGORIES_OPTIONS = gql`
	query getProjectCategoriesOptions {
		project_category(
			order_by: { name: asc }
			where: { type: { _eq: "project" } }
		) {
			id
			name
		}
	}
`;

export const GET_PROJECT_TASK_CATEGORIES_OPTIONS = gql`
	query getProjectTaskCategoriesOptions {
		project_category(
			order_by: { name: asc }
			where: { type: { _eq: "project_task" } }
		) {
			id
			name
		}
	}
`;

export const GET_ACTIVE_USERS = gql`
	query getActiveUsers($filter: user_bool_exp = {}) {
		user(where: $filter, order_by: { full_name: asc }) {
			id
			name: full_name
		}
	}
`;
export const GET_USER_LOCATION = gql`
	query getLocationIdByUserId($userId: uuid!) {
		user_by_pk(id: $userId) {
			user_type
			id
			employee {
				location_id
			}
			contractors {
				location_id
			}
		}
	}
`;
export const GET_USER_HOLIDAY = gql`
	query getOrgHolidaysByUser(
		$filter: org_holidays_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		org_holidays(where: $filter) {
			name
			date
			id
			org_location {
				id
				name
			}
		}
		user_optional_holiday_mapping(where: $optionalHolidayFilter) {
			id
			org_holiday {
				name
				id
				date
				is_restricted
				org_location {
					id
					name
				}
			}
		}
	}
`;

export const GET_COMPANY = gql`
	query getCrmCompany {
		crm_company(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_DESIGNATION = gql`
	query getDesignation {
		designation {
			id
			title
		}
	}
`;

export const SUBSCRIPTION_ORGANISATION_LEAVE_SETTINGS = gql`
	subscription getLeaveSettings($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			enable_leave
		}
	}
`;

export const GET_SHIFT_AND_LEAVE_POLICY = gql`
	query getShiftAndLeaveGroups($orgId: uuid!) {
		org_leave_group(distinct_on: label, where: { org_id: { _eq: $orgId } }) {
			id
			label
		}
		org_shift(
			distinct_on: name
			order_by: { name: asc }
			where: { is_active: { _eq: true } }
		) {
			name
			id
		}
	}
`;

export const GET_CUSTOMER_WISE_PROJECT_RESOURCE_MAPPING = gql`
	query getCustomerWiseProjectResourceMapping(
		$_companyId: [uuid!]
		$resourceFilter: project_resource_mapping_bool_exp = {}
		$sortingResource: [project_resource_mapping_order_by!]
	) {
		crm_company(where: { id: { _in: $_companyId } }) {
			id
			name
			projects {
				project_resource_mappings(
					where: $resourceFilter
					order_by: $sortingResource
				) {
					employee_id
					project_id
					project {
						name
						company {
							name
							id
						}
					}
					user {
						first_name
						last_name
						id
						full_name
					}
					start_date
					end_date
				}
			}
		}
	}
`;

export const GET_COMPANYLIST = gql`
	query getCrmCompanyList {
		crm_company(order_by: { name: asc }) {
			id
			name
		}
	}
`;
