import React, { useEffect, useState } from 'react';
import { TextInput, required, useNotify } from 'react-admin';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../settingsStyle';
import { UPSERT_TASK } from '../Settings.gql';

interface InsertChecklistItemsOne {
	id: string;
	__typename: string;
}
interface Data {
	insert_checklist_items_one: InsertChecklistItemsOne;
}
interface Error {
	message: string;
}
interface Response {
	data?: Data;
	error?: Error;
}
interface FormValue {
	checklist_type_id?: string;
	description?: string;
	id?: string;
	name?: string;
	updated_at?: string;
	created_at?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	checkListTypeId?: string;
	formValue: FormValue;
}
const TaskForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, checkListTypeId, formValue } =
		props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [upsertTask] = useApolloMutation(UPSERT_TASK);
	const [taskForm, setTaskForm] = useState<FormValue>({});

	useEffect(() => {
		if (!formValue) {
			return;
		}
		setTaskForm(formValue);
	}, [formValue]);
	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		upsertTask({
			variables: {
				task: {
					id: formValues?.id,
					name: formValues?.name,
					description: formValues?.description,
					checklist_type_id: checkListTypeId
						? checkListTypeId
						: formValues?.checklist_type_id,
				},
			},
		})
			.then((response: Response) => {
				if (!response.error) {
					if (formValues?.id) {
						notify('Checklist Task updated Successfully');
						refetch();
						closeModal();
					} else {
						notify('Checklist Task Created Successfully');
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: string) => {
				if (!error) {
					return;
				}
			});
	};
	const closeModal = () => {
		setTaskForm({});
		onClose();
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{isEditMutation
								? `Edit: ${formValue?.name}`
								: `Add Checklist Task`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form onSubmit={onSubmit} initialValues={taskForm}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name *
									</Typography>
									<TextInput source='name' label='' validate={required()} />
									<Typography className={modalFormStyles.label}>
										Description *
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										validate={required()}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine}
										className={
											invalid || pristine
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{isEditMutation ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default TaskForm;
