import * as React from "react";
import { MenuItem, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { onSignOut } from "../Api/restApi.config";
import { useHistory } from 'react-router';

export const onLogout = () => {
  onSignOut().then((response) => {
    const location = response?.data;
    localStorage.clear();
    window.location = location;
  });
};

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
  },
}))

export const LogoutButton = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
    <MenuItem className={classes.button}>
      <Button style={{
        color: "#696969",
      }}
      onClick={()=>history.push('/my-info')}>My Info</Button>
    </MenuItem>
    <MenuItem className={classes.button}>
      <Button style={{
        color: "#696969",
      }}
        onClick={() => onLogout()}>Logout</Button>
    </MenuItem>
    </>
  );
};

