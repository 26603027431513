import {
	Box,
	Typography,
	makeStyles,
	Button,
	Tooltip,
	Avatar,
	Chip,
	ThemeProvider,
} from '@material-ui/core';
import { SelectInput, useNotify } from 'react-admin';
import React, { useContext, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {
	useGetSubTaskListBytaskIdQuery,
	useUpdateSubtaskStatusByIdMutation,
	useDeleteSubTaskByIdMutation,
} from '../../../generated/graphql';
import { Form } from 'react-final-form';
import CreateTaskForm from '../CreateTaskForm';
import dayjs from 'dayjs';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import { customDropDownTheme } from '../../../Layout/Theme.component';
import { ProjectTask } from '../task.modal';
import {
	SUB_TASK_DELETE_FAILED_MESSAGE,
	SUB_TASK_DELETE_MESSAGE,
} from '../Constant';
import { head } from 'lodash';
import { UserProfileContext } from '../../../App';

const useStyles = makeStyles((theme: any) => ({
	bdOwnerPic: {
		width: '30px',
		height: '30px',
		fontSize: '12px',
	},
	boxStyle: {
		border: '1px solid #ECF1F4',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '50px',
		marginBottom: '10px',
		borderRadius: '7px',
		cursor: 'pointer',
	},
	typography: {
		fontFamily: 'Manrope-semiBold',
		fontSize: '13px',
		marginLeft: '15px',
		width: '370px',
		marginTop: '2px',
		marginRight: '10px',
	},
	chipStyle: {
		fontSize: '10px',
		padding: '3px',
	},
	priorityHigh: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	priorityMedium: {
		backgroundColor: '#EBF3FF',
		color: '#4285F4',
	},
	priorityLow: {
		backgroundColor: '#E9F7EC',
		color: '#34A853',
	},
	label: {
		font: 'normal normal medium 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '12px !important',
		marginTop: '15px',
	},
	deleteIcon: {
		color: '#999e9b',
		width: '18px',
		height: '21px',
		cursor: 'pointer',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'right',
	},
	toolbar: {
		height: '400px !important',
		overflowY: 'auto',
		marginTop: '20px',
	},
}));
interface Status {
	__typename: string;
	id: string;
	name: string;
	state: string;
}
interface Props {
	refetchSubTask: () => void;
	boardId: string;
	mileStoneId: string;
	statusList: Status[];
	taskId: string;
}
const SubTask = (props: Props) => {
	const { refetchSubTask, statusList, boardId, mileStoneId, taskId } = props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const notify = useNotify();
	const styles = useStyles();
	const modalStyle = modalFormStyle();
	const ellipsis = ellipsisStyle();
	const [subTaskList, setSubTaskList] = useState<any>([]);
	const [isTaskModalShown, setIsTaskModalShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [subTaskDetails, setSubTaskDetails] = useState<any>();
	const [selectedSubTaskDetails, setSelectedSubTaskDetails] =
		useState<ProjectTask>();
	const { data: taskList, refetch: refetchTask } =
		useGetSubTaskListBytaskIdQuery({
			variables: {
				task_id: taskId,
			},
		});
	const [updateSubTaskStatusById] = useUpdateSubtaskStatusByIdMutation();
	const [deleteSubTaskById] = useDeleteSubTaskByIdMutation();

	useEffect(() => {
		if (!taskList) {
			return;
		}
		setSubTaskList(taskList?.project_task);
	}, [taskList]);

	useEffect(() => {
		if (!refetchSubTask) {
			return;
		}
		refetchTask();
	}, [refetchSubTask, refetchTask]);

	const getFullName = (full_name: string) => {
		if (!full_name) {
			return 'Uassigned';
		}
		return `${full_name} `;
	};
	//update for status dropdown
	const onSubtaskStatusChange = (status: string, task_id: string) => {
		updateSubTaskStatusById({
			variables: {
				id: task_id,
				column_id: status,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Task updated successfully');
					refetchTask();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};
	const onEditSubTask = (task: ProjectTask) => {
		setIsTaskModalShown(true);
		const taskDetails = {
			title: task?.title,
			is_billable: task?.is_billable,
			planned_start_date: task?.planned_start_date,
			planned_end_date: task?.planned_end_date,
			assigned_to: task?.assigned_to,
			status: head(task?.project_kanban_cards)?.column_id,
			project_milestone_id: task?.project_milestone_id,
			completion_percentage: task?.completion_percentage,
			reminder: task?.reminder,
			planned_hours: task?.planned_hours,
			priority: task?.priority,
			description: task?.description,
			id: task?.id,
			cardId: head(task?.project_kanban_cards)?.id,
		};
		setSubTaskDetails(taskDetails);
	};

	const openDeleteModal = (task: ProjectTask) => {
		setIsDeleteModalShown(true);
		setSelectedSubTaskDetails(task);
	};

	const onDeleteSubTask = () => {
		if (!selectedSubTaskDetails) {
			return;
		}
		deleteSubTaskById({
			variables: {
				card_id: selectedSubTaskDetails?.project_kanban_cards[0]?.id,
				task_id: selectedSubTaskDetails?.id,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(SUB_TASK_DELETE_MESSAGE);
					setIsDeleteModalShown(false);
					refetchTask();
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify(SUB_TASK_DELETE_FAILED_MESSAGE, 'warning');
					return;
				}
			});
	};
	return (
		<Box>
			<Box className={styles.buttonContainer}>
				<Button
					startIcon={<AddIcon />}
					onClick={() => {
						setIsTaskModalShown(true);
					}}
					className={modalStyle.saveButton}
				>
					Add SubTask
				</Button>
			</Box>
			<Box className={styles.toolbar}>
				{subTaskList && subTaskList?.length > 0 ? (
					subTaskList?.map((task: ProjectTask) => (
						<Box
							className={styles.boxStyle}
							key={task?.id}
							onClick={() => onEditSubTask(task)}
						>
							<Tooltip title={task?.title || ''} placement='bottom'>
								<Typography
									className={`${styles.typography} ${ellipsis.ellipsis}`}
								>
									{task?.title}
								</Typography>
							</Tooltip>
							<Box display='flex' marginRight='15px'>
								<Box>
									<Tooltip placement='bottom' title={'Due date'}>
										<Typography className={styles.label}>
											{task?.planned_end_date
												? dayjs(task?.planned_end_date).format(dateFormat)
												: '- -'}
										</Typography>
									</Tooltip>
								</Box>
								<Box>
									<Form
										initialValues={{
											status: task?.project_kanban_cards[0]?.column_id,
										}}
										onSubmit={() => {}}
									>
										{() => (
											<form>
												<ThemeProvider theme={customDropDownTheme}>
													<SelectInput
														choices={statusList || []}
														source='status'
														label={''}
														onChange={(event) =>
															onSubtaskStatusChange(
																event?.target?.value,
																task?.project_kanban_cards[0]?.id
															)
														}
													/>
												</ThemeProvider>
											</form>
										)}
									</Form>
								</Box>
								<Box marginTop='10px' marginRight='25px' marginLeft='30px'>
									<Tooltip title='Priority' placement='bottom'>
										<Chip
											label={task?.priority || '--'}
											size='small'
											className={`${styles.chipStyle} ${
												task.priority === 'High'
													? styles.priorityHigh
													: task.priority === 'Medium'
													? styles.priorityMedium
													: task.priority === 'Low'
													? styles.priorityLow
													: undefined
											}`}
										/>
									</Tooltip>
								</Box>
								<Box marginTop='7px' marginRight='15px'>
									<Tooltip
										title={
											task?.assignedTo
												? ` Assigned to: ${getFullName(
														task?.assignedTo?.full_name
												  )}`
												: 'Unassigned'
										}
										placement='bottom'
									>
										<Avatar
											className={styles.bdOwnerPic}
											src={`${task?.profilePic || ''}`}
										>
											{task?.assignedTo
												? getFullName(task?.assignedTo?.full_name)?.charAt(0)
												: '-'}
										</Avatar>
									</Tooltip>
								</Box>
								<Box marginTop='12px'>
									<DeleteIcon
										className={styles.deleteIcon}
										onClick={(event: any) => {
											event.stopPropagation();
											openDeleteModal(task);
										}}
									/>
								</Box>
							</Box>
						</Box>
					))
				) : (
					<Typography className={styles.label}>No subtask found</Typography>
				)}
			</Box>
			{isTaskModalShown && (
				<CreateTaskForm
					onSuccess={() => {}}
					open={isTaskModalShown}
					boardId={boardId}
					parentTaskId={taskId}
					onClose={() => {
						setIsTaskModalShown(false);
						setSubTaskDetails({});
						refetchTask();
					}}
					taskDetails={subTaskDetails}
					isEdit={subTaskDetails?.id ? true : false}
					mileStoneId={mileStoneId}
					onCreateTask={() => {}}
				/>
			)}

			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={onDeleteSubTask}
				confirmationMessage='Do you really want to delete this subtask ?'
			/>
		</Box>
	);
};

export default SubTask;
