import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserProfileContext } from '../../../App';
import { uploadFile } from '../../../Api/restApi.config';
import DocumentUpload from '../../../SharedComponents/DocumentUpload/DocumentUpload.component';
import {
	GET_OPPORTUNITY_DOCS,
	ADD_OPPORTUNITY_DOC,
	DELETE_OPPORTUNITY_DOC,
	UPDATE_FILE_NAME,
} from '../Opportunities.gql';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import { supportedFileFormats } from '../../../Utils';

interface document {
	url: string;
	id: string;
	file_name: string;
}
interface propsType {
	uploaded: string;
}

const DocumentUploader = (props: propsType) => {
	const { permissions } = useContext<any>(UserProfileContext);
	const { uploaded } = props;
	const [docs, setDocs] = useState({});
	const [loading, setLoading] = useState(false);
	const { id: opportunityId }: { id: string } = useParams();
	const [fileUploadError, setFileUploadError] = useState('');
	const [duplicationError, setDuplicationError] = useState(false);
	const { orgId } = useContext<any>(UserProfileContext);
	const [addOpportunityDoc, { error: uploadError }] =
		useApolloMutation(ADD_OPPORTUNITY_DOC);
	const [deleteOpportunityDoc] = useApolloMutation(DELETE_OPPORTUNITY_DOC);
	const [updateOpportunityDoc] = useApolloMutation(UPDATE_FILE_NAME);
	const { refetch: refetchDocs } = useApolloQuery(GET_OPPORTUNITY_DOCS, {
		variables: { opportunity_id: opportunityId },
	});
	const { data: opportunityDocs } = useApolloQuery(GET_OPPORTUNITY_DOCS, {
		variables: { opportunity_id: opportunityId },
	});
	useEffect(() => {
		const doc = opportunityDocs?.opportunity_docs?.map((doc: document) => {
			const docInfo = {
				id: doc.id,
				docUrl: doc.url,
				file_name: doc.file_name,
			};
			return docInfo;
		});
		setDocs(doc);
	}, [opportunityDocs]);

	useEffect(() => {
		if (uploaded === 'success') {
			refetchDocs();
		}
	}, [uploaded, refetchDocs]);

	const FileUploadHandler = (uploadedFile: string) => {
		let filename: any = uploadedFile[1];
		if (supportedFileFormats.includes(filename.split('.').pop())) {
			setFileUploadError('');
			setLoading(true);
			let data = new FormData();
			data.append('uploadType', 'OpportunityDocs');
			data.append('file', uploadedFile[0]);
			data.append('id', orgId);
			uploadFile(data)
				.then((res) => {
					addOpportunityDoc({
						variables: {
							data: {
								type: 'OpportunityDocs',
								url: res.data.url,
								opportunity_id: opportunityId,
								file_name: uploadedFile[1],
							},
						},
					})
						.then(() => {
							setLoading(false);
							setFileUploadError('File uploaded successfully');
							refetchDocs();
						})
						.catch(() => {
							setLoading(false);
							setFileUploadError('File already exist in the documents');
							if (uploadError) return `Submission error! ${uploadError}`;
						});
				})
				.catch((err) => {
					setFileUploadError(err.response?.data?.message || "Document Upload failed");
					setLoading(false);
					refetchDocs();
				});
		} else {
			setFileUploadError(
				` '${filename
					.split('.')
					.pop()}'  file format is not supported in documents. `
			);
			setLoading(false);
		}
	};

	const onDocDelete = (id: string) => {
		setFileUploadError('');
		setLoading(true);
		deleteOpportunityDoc({
			variables: {
				id,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError('File deleted successfully');
			})
			.catch(() => {
				setLoading(false);
				setFileUploadError('File deletion failed');
			});
	};

	const onFileRename = (newFieldName: string, fileId: string) => {
		setLoading(true);
		setFileUploadError('');
		setDuplicationError(false);
		updateOpportunityDoc({
			variables: {
				id: fileId,
				file_name: newFieldName,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError(`File name updated successfully`);
			})
			.catch(() => {
				setLoading(false);
				setDuplicationError(true);
				setFileUploadError(
					`The file name ${newFieldName} already exist in document`
				);
			});
	};

	return (
		<DocumentUpload
			docs={docs}
			FileUploadHandler={FileUploadHandler}
			onDocDelete={onDocDelete}
			loading={loading}
			refetchDocs={refetchDocs}
			errorMessage={fileUploadError}
			onFileRename={onFileRename}
			onRenameError={duplicationError}
			pageHeight='516px'
			permission={permissions?.opportunities?.update_permissions ? true : false}
		/>
	);
};

export default DocumentUploader;
