import React, { useState } from 'react';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../settingsStyle';
import { modalFormStyle } from '../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { Button, Box, Dialog, Typography } from '@material-ui/core';
import {
	TextInput,
	required,
	useNotify,
	AutocompleteInput,
	ReferenceInput,
} from 'react-admin';
import { useInsertKanbanBoardMutation } from '../../../generated/graphql';

interface FormValue {
	label?: string;
	name?: string;
	description?: string;
	user_id?: string;
}
interface KanbanTemplateFormProps {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	subCategoryId?: string;
	createdType: (id?: string) => void;
	setKanbanBoardId: (id: string) => void;
	close : ()=> void;
}

const KanbanTemplateForm = (props: KanbanTemplateFormProps) => {
	const {
		open,
		onClose,
		refetch,
		isEditMutation,
		subCategoryId,
		createdType,
		setKanbanBoardId,
		close
	} = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const [kanbanTemplateUniqueError, setKanbanTemplateUniqueError] = useState(
		''
	);
	const notify = useNotify();
	const [addKanbanboard,{loading:isKanbanboardLoading}] = useInsertKanbanBoardMutation();

	const onSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		addKanbanboard({
			variables: {
				data: {
					sub_module_id: subCategoryId,
					name: formValues?.name,
					description: formValues?.description,
					kanban_board_invited_users: {
						data: [
							{
								is_primary: true,
								user_id: formValues?.user_id,
							},
						],
					},
				},
			},
		})
			.then((data) => {
				if (data?.data?.insert_kanban_board_one?.id) {
					createdType(data?.data?.insert_kanban_board_one?.id);
					setKanbanBoardId(data?.data?.insert_kanban_board_one?.id);
				}
				notify('Kanban board created successfully');
				refetch();
				onClose();
			})
			.catch((error) => {
				if (error.message.includes('kanban_board_name_org_id_key')) {
					setKanbanTemplateUniqueError('Template Name already exists');
				}
			});
	};
	const closeModal = () => {
		setKanbanTemplateUniqueError('');
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							Add New Kanban Template
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={close}
						/>
					</Box>

					<Form onSubmit={onSubmit}>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Template Name *
									</Typography>
									<TextInput source='name' label='' validate={required()} 
										onChange={()=>setKanbanTemplateUniqueError('')}/>
									{kanbanTemplateUniqueError && (
										<Box color='red'>{kanbanTemplateUniqueError}</Box>
									)}
									<Typography className={modalFormStyles.label}>
										Primary User *
									</Typography>
									<ReferenceInput
										source='user_id'
										reference='user'
										label={''}
										filterToQuery={(searchText) => ({
											first_name: searchText,
											is_active: true,
										})}
										validate={required()}
									>
										<AutocompleteInput
											optionText={(user: any) =>
												`${user?.first_name} ${user?.last_name || ''}`
											}
											resettable={true}
											shouldRenderSuggestions={(name: any) =>
												name?.trim().length > 0
											}
										/>
									</ReferenceInput>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput source='description' label='' multiline={true} />
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine || isKanbanboardLoading}
										className={
											invalid || pristine || isKanbanboardLoading
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{isEditMutation ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default KanbanTemplateForm;
