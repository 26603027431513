import React, { useState, useEffect, useContext } from 'react';
import { TextInput, required, number } from 'react-admin';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { UserProfileContext } from '../../../../App';
import { settingsStyle } from '../../settingsStyle';
import { GET_ORGANIZATION_NOTICE_PERIOD_DAYS } from '../../../../SharedComponents/gqlQueries';
import { useUpdateOrganizationNoticePeriodDaysMutation } from '../../../../generated/graphql';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

export interface Props {
	noticePeriodDays: string;
}

const NoticePeriodSetting = () => {
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const [noticePeriodDays, setNoticePeriodDays] = useState('');
	const notify = useNotify();

	const {
		data: organisationNoticePeriodDays,
		refetch: reftechOrganizationNoticePeriod,
	} = useApolloQuery(GET_ORGANIZATION_NOTICE_PERIOD_DAYS, {
		variables: {
			organizationId,
		},
	});

	const [updateNoticePeriodDays, { loading: isUpdateNoticePeriodDaysLoading }] =
		useUpdateOrganizationNoticePeriodDaysMutation();

	useEffect(() => {
		if (
			!organisationNoticePeriodDays ||
			!organisationNoticePeriodDays?.organization_by_pk ||
			!organisationNoticePeriodDays?.organization_by_pk?.notice_period_days
		) {
			return;
		}
		setNoticePeriodDays(
			organisationNoticePeriodDays?.organization_by_pk?.notice_period_days
		);
	}, [organisationNoticePeriodDays]);

	const onHandleSubmit = (value: Props) => {
		updateNoticePeriodDays({
			variables: {
				id: organizationId,
				noticePeriod: {
					notice_period_days: parseInt(value?.noticePeriodDays),
				},
			},
		}).then((data) => {
			notify('Notice period updated successfully');
			reftechOrganizationNoticePeriod();
		});
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form initialValues={{ noticePeriodDays }} onSubmit={onHandleSubmit}>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Notice Period(day) *
									</Typography>
								</Box>
								<Box className={styles.input}>
									<TextInput
										source='noticePeriodDays'
										fullWidth={true}
										label={''}
										validate={[required(), number()]}
									/>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									title='Save'
									isLoading={isUpdateNoticePeriodDaysLoading}
									isDisabled={invalid || pristine || isUpdateNoticePeriodDaysLoading}
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default NoticePeriodSetting;
