import gql from 'graphql-tag';

export const GET_CRM_TASK_KANBAN = gql`
	subscription CrmTaskKanban($taskName: String) {
		kanban_board(where: { type: { _eq: crm_task_kanban } }) {
			id
			name
			kanban_columns(order_by: { ordinal: asc }) {
				id
				name
				cards: crm_task_kanban_cards(
					order_by: { ordinal: asc, updated_at: desc }
					where: { crm_task: { name: { _ilike: $taskName } } }
				) {
					id
					ordinal
					updated_at
					updated_by
					column_id
					created_at
					created_by
					crm_task {
						id
						name
						priority
						due_date
						user {
							first_name
							last_name
							full_name
						}
						opportunity {
							name
							crm_contact {
								first_name
								last_name
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_CRM_TASK_STATUS = gql`
	query getCrmTaskStatus {
		kanban_board(where: { type: { _eq: crm_task_kanban } }) {
			kanban_columns {
				id
				state
			}
		}
	}
`;

export const ADD_CRM_TASK = gql`
	mutation insertCrmTask($data: crm_tasks_insert_input!) {
		insert_crm_tasks_one(object: $data) {
			id
			name
			opportunity_id
			owner_id
			priority
			activity_type_id
			description
			due_date
			remind_at
			crm_task_kanban_cards {
				id
				column_id
				ordinal
			}
			crm_task_tag_mappings {
				tag_id
			}
		}
	}
`;

export const CREATE_CRM_TAG = gql`
	mutation creatTag($data: crm_tags_insert_input!) {
		insert_crm_tags_one(object: $data) {
			id
			name
		}
	}
`;

export const GET_CRM_TASKS_STATUS = gql`
	query getCrmTasksStatus {
		crm_tasks {
			id
			kanbanCard: crm_task_kanban_cards {
				column_id
				kanban_column {
					name
				}
			}
		}
	}
`;
export const GET_CRM_TASKS_TOTAL = gql`
	query getCrmTasksTotal($taskName: String!) {
		crm_tasks_aggregate(where: { name: { _ilike: $taskName } }) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_CRM_TASK = gql`
	query getCrmTaskByPk($id: uuid!) {
		crm_tasks_by_pk(id: $id) {
			description
			due_date
			name
			id
			priority
			opportunity_id
			activity_type_id
			remind_at
			crm_task_kanban_cards {
				id
				column_id
			}
			crm_task_tag_mappings {
				crm_task_id
				id
				crm_tags {
					id
					name
					color
				}
			}
			crm_task_kanban_cards {
				kanban_column {
					state
				}
			}
			user {
				id
				first_name
				last_name
			}
			opportunity {
				name
				id
				crm_company {
					name
				}
			}
		}
	}
`;

export const UPDATE_CRM_TASK = gql`
	mutation updateCrmTask($taskId: uuid!, $crmTask: crm_tasks_set_input!) {
		update_crm_tasks_by_pk(pk_columns: { id: $taskId }, _set: $crmTask) {
			activity_type_id
			name
		}
	}
`;

export const GET_USERS = gql`
	query getUsers($name: String = "") {
		user(where: { is_active: { _eq: true }, first_name: { _ilike: $name } }) {
			id
			first_name
			last_name
			profile_pic
		}
	}
`;

export const GET_CRM_TASK_ACTIVITIES = gql`
	query getCrmTaskActivities {
		crm_task_activity_type {
			id
			label
			value
		}
	}
`;

export const UPDATE_TASK_STATUS = gql`
	mutation updateCrmTask($taskId: uuid!, $crmTask: crm_tasks_set_input!) {
		update_crm_tasks_by_pk(pk_columns: { id: $taskId }, _set: $crmTask) {
			activity_type_id
			name
		}
	}
`;

export const GET_DUE_TODAY_TASKS = gql`
	query getDueTodayTasks($dueTodayTaskFilter: crm_tasks_bool_exp = {}) {
		crm_tasks(where: $dueTodayTaskFilter) {
			id
		}
		crm_tasks_aggregate(where: $dueTodayTaskFilter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_UPCOMING_TASKS = gql`
	query getUpcomingTasks($upcomingTaskFilter: crm_tasks_bool_exp = {}) {
		crm_tasks(where: $upcomingTaskFilter, order_by: { due_date: asc }) {
			id
		}
		crm_tasks_aggregate(where: $upcomingTaskFilter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_OVERDUE_TASKS = gql`
	query getOverDueTasks($overDueTaskFilter: crm_tasks_bool_exp = {}) {
		crm_tasks(where: $overDueTaskFilter) {
			id
		}
		crm_tasks_aggregate(where: $overDueTaskFilter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_ASSIGNEE_FILTER_RECOMMENDATIONS = gql`
	query getAssigneeFilterRecommendations {
		user(
			distinct_on: id
			where: {
				kanban_board_invited_users: {
					kanban_board: {
						master_sub_module: { master_module: { value: { _eq: "crm" } } }
						is_active: { _eq: true }
					}
				}
			}
		) {
			first_name
			id
			last_name
			full_name
		}
	}
`;

export const GET_CRM_TASKS = gql`
	query getAllTask($crmTaskFilter: crm_tasks_bool_exp = {}) {
		crm_tasks(where: $crmTaskFilter) {
			id
		}
		crm_tasks_aggregate(where: $crmTaskFilter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_COMPLETED_TASKS = gql`
	query getCompletedTasks($completedTaskFilter: crm_tasks_bool_exp = {}) {
		crm_tasks(where: $completedTaskFilter, order_by: { due_date: asc }) {
			id
		}
		crm_tasks_aggregate(where: $completedTaskFilter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_CRM_TASK_ACTIVITY_TYPE = gql`
	query getTaskActivityType($limit: Int, $activity: String) {
		crm_task_activity_type(
			distinct_on: value
			limit: $limit
			where: { label: { _ilike: $activity } }
		) {
			id
			label
		}
		crm_task_activity_type_aggregate(
			distinct_on: value
			where: { label: { _ilike: $activity } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_CRM_COMPANIES = gql`
	query getCompanies($limit: Int, $company: String = "") {
		crm_company(
			distinct_on: name
			limit: $limit
			where: {
				opportunities: { crm_tasks: { id: { _is_null: false } } }
				name: { _ilike: $company }
			}
		) {
			id
			name
		}
		crm_company_aggregate(
			distinct_on: name
			where: {
				opportunities: { crm_tasks: { id: { _is_null: false } } }
				name: { _ilike: $company }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_OPPORTUNITY_ID = gql`
	query opportunityId($boardId: [uuid!]) {
		opportunities(
			where: { kanbanCard: { kanban_column: { board_id: { _in: $boardId } } } }
			order_by: { name: asc }
		) {
			id
			name
		}
	}
`;
