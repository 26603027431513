import React, { useState } from 'react';

import {
	Box,
	ThemeProvider,
	Dialog,
	Typography,
	Button,
} from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';
import { settingsStyle } from '../settingsStyle';
import { modalFormStyle } from '../../../Layout/styles';
import { Form } from 'react-final-form';
import {
	TextInput,
	RadioButtonGroupInput,
	required,
	useRefresh,
	useNotify,
} from 'react-admin';
import { useUpsertMasterProjectTaskMutation } from '../../../generated/graphql';
import {
	MASTER_PROJECT_TASK_UNIQUE_VIOLATION_KEY,
	MASTER_PROJECT_TASK_UNIQUE_VIOLATION_TEXT,
	TIMESHEET_PROJECT_TASK_CREATION_TEXT,
	TIMESHEET_PROJECT_TASK_UPDATION_TEXT,
} from '../constant';

interface MasterProjectTask {
	id?: string;
	is_billable?: boolean;
	title?: string;
	org_id?: string;
}

interface TimesheetMasterProjectTaskFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: MasterProjectTask;
	onSuccess?: () => void;
}

export const TimesheetMasterProjectTaskForm = (
	props: TimesheetMasterProjectTaskFormProps
) => {
	const masterTaskFormInitialValues = {
		is_billable: true,
	};
	const { open, onClose, initialValues } = props;
	const [isTaskUniqueViolationError, setIsTaskUniqueViolationError] =
		useState(false);
	const settingsStyles = settingsStyle();
	const modalFormStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const notificationText = initialValues?.id
		? TIMESHEET_PROJECT_TASK_CREATION_TEXT
		: TIMESHEET_PROJECT_TASK_UPDATION_TEXT;

	const [upsertMasterProjectTask,{loading:isMasterProjectTaskLoading}] = useUpsertMasterProjectTaskMutation();

	const handleTaskFormSubmit = (formValues: MasterProjectTask) => {
		if (!formValues) {
			return;
		}
		upsertMasterProjectTask({
			variables: {
				data: {
					id: initialValues?.id,
					title: formValues.title,
					is_billable: formValues.is_billable,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(notificationText);
					refresh();
					handleClose();
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}
				if (error.message.includes(MASTER_PROJECT_TASK_UNIQUE_VIOLATION_KEY)) {
					setIsTaskUniqueViolationError(true);
					return;
				}
			});
	};

	const handleClose = () => {
		setIsTaskUniqueViolationError(false);
		onClose();
	};

	const validateMasterTaskFormValues = async () => {
		const masterTaskFormErrors = {} as MasterProjectTask;
		if (isTaskUniqueViolationError) {
			masterTaskFormErrors.title = MASTER_PROJECT_TASK_UNIQUE_VIOLATION_TEXT;
		}
		return masterTaskFormErrors;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{initialValues?.id ? `${initialValues?.title}` : `Add Task`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={handleClose}
						/>
					</Box>
					<Form
						initialValues={
							initialValues?.id ? initialValues : masterTaskFormInitialValues
						}
						onSubmit={handleTaskFormSubmit}
						validate={validateMasterTaskFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput source='title' label='' validate={required()} />

									<Typography className={modalFormStyles.label}>
										Is Billable
									</Typography>
									<RadioButtonGroupInput
										source='is_billable'
										label=''
										choices={[
											{ id: true, name: 'Billable' },
											{ id: false, name: 'Non Billable' },
										]}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<Button
										type='submit'
										variant='contained'
										disabled={invalid || pristine || isMasterProjectTaskLoading}
										className={
											invalid || pristine || isMasterProjectTaskLoading
												? modalFormStyles.disabledButton
												: modalFormStyles.saveButton
										}
									>
										{initialValues?.id ? 'Update' : 'Add'}
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default TimesheetMasterProjectTaskForm;
