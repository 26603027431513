import React, { useContext, useState, useEffect } from 'react';
import {
	TextInput,
	SelectInput,
	required,
	ReferenceInput,
	AutocompleteInput,
	useNotify,
	useRefresh,
	minLength,
	number,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Button, makeStyles, Box } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import {
	modalFormTheme,
	currencySelectBox,
	currencyTextBox,
} from '../../Layout/Theme.component';
import { modalFormStyle, currencyInputStyle } from '../../Layout/styles';
import { OpportunityStatus, kanbanBoardInvitedUser } from './DetailsPage/modal';
import { ADD_OPPORTUNITY, GET_USERS } from './Opportunities.gql';
import {
	useQuery as useApolloQuery,
	useMutation as useApolloMutation,
} from '@apollo/client';
import AddCompany from './AddCompany.component';
import AddContact from './AddContact.component';
import { OrganizationSupportedCurrencies } from '../../SharedComponents/model';
import { UserProfileContext } from '../../App';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../SharedComponents/gqlQueries';
import dayjs from 'dayjs';
import _, { orderBy } from 'lodash';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../generated/graphql';

const useStyles = makeStyles({
	hideInput: {
		visibility: 'hidden',
		position: 'absolute',
	},
});
interface Name {
	first_name?: string;
	last_name?: string;
	full_name?: string;
	name?: string;
}

interface OpportunityForm {
	currency_id?: string;
	status?: string;
	name?: string;
	win_percentage?: number;
	value?: number;
	priority?: string;
	close_date?: string;
	company_id?: string;
	primary_contact_id?: string;
	owner_id?: string;
	lead_owner_id?: string;
	description?: string;
}

interface Contact {
	id?: string;
	first_name: string;
	last_name: string;
}

interface Props {
	onClose: () => void;
	open: boolean;
	kanbanColumnId: string;
	onSuccess: () => void;
	opportunityOptions?: OpportunityStatus[];
}

const OpportunitiesForm = (props: Props) => {
	const { onClose, open, kanbanColumnId, onSuccess, opportunityOptions } =
		props;
	const {
		orgId: organizationId,
		dateFormat,
		orgBaseCurrency,
	} = useContext<any>(UserProfileContext);
	const { id: boardId }: { id: string } = useParams();
	const notify = useNotify();
	const refresh = useRefresh();
	const [companyName, setCompanyName] = useState('');
	const [contactName, setContactName] = useState('');
	const [isAddCompanyFormShown, setIsAddCompanyFormShown] = useState(false);
	const [isAddContactFormShown, setIsAddContactFormShown] = useState(false);
	const [currencyList, setCurrencyList] = useState<
		{ id: string; name: string }[]
	>([]);
	const [isPercentageLabelHidden, setIsPercentageLabelHidden] = useState(true);
	const [mentionSuggestions, setMentionSuggestions] = useState([]);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);
	const [opportunityForm, setOpportunityForm] = useState<OpportunityForm>({
		priority: 'Medium',
		win_percentage: 50,
		close_date: dayjs().add(7, 'days').format('YYYY-MM-DD'),
		currency_id: orgBaseCurrency?.id,
	});

	const classes = modalFormStyle();
	const inlineStyle = useStyles();
	const style = currencyInputStyle();

	const { data: activeUsers } = useGetActiveUsersByLimitQuery();

	const [addOpportunity, { loading: isOpportunityLoading }] =
		useApolloMutation(ADD_OPPORTUNITY);

	const { data: organisationSupportedCurrencies } = useApolloQuery(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: users } = useApolloQuery(GET_USERS, {
		variables: {
			name: `%%`,
			boardId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!users) {
			return;
		}
		const userSuggestion = users.kanban_board_invited_users.map(
			(user: kanbanBoardInvitedUser) => {
				return {
					id: user?.user?.id,
					name: `${user?.user?.full_name} `,
				};
			}
		);
		const sortedUserSuggestion: any = orderBy(
			userSuggestion,
			[(user) => user.name.toUpperCase()],
			['asc']
		);
		setMentionSuggestions(sortedUserSuggestion);
	}, [users]);

	useEffect(() => {
		setOpportunityForm((previousOpportunityForm) => ({
			...previousOpportunityForm,
			status: kanbanColumnId,
		}));
	}, [kanbanColumnId]);

	useEffect(() => {
		if (!organisationSupportedCurrencies || !orgBaseCurrency) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					if (currency?.currency?.id === orgBaseCurrency?.id) {
						setOpportunityForm((previousOpportunityForm) => ({
							...previousOpportunityForm,
							currency_id: currency?.currency?.id,
						}));
					}
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);

		setCurrencyList(
			_.uniqBy([...organizationCurrencyChoices, orgBaseCurrency], 'id')
		);
	}, [organisationSupportedCurrencies, orgBaseCurrency]);

	useEffect(() => {
		if (contactName) {
			setOpportunityForm({
				...OpportunitiesForm,
				primary_contact_id: contactName,
			});
		}
	}, [contactName]);

	const handleClose = () => {
		setOpportunityForm({
			priority: 'Medium',
			currency_id: opportunityForm?.currency_id,
			win_percentage: 50,
			close_date: dayjs().add(7, 'days').format('YYYY-MM-DD'),
			status: kanbanColumnId,
		});
		onClose();
	};

	const onSave = () => {
		addOpportunity({
			variables: {
				data: {
					name: opportunityForm?.name,
					currency_id: opportunityForm.currency_id,
					win_percentage: opportunityForm.win_percentage || 0,
					priority: opportunityForm.priority,
					close_date: opportunityForm.close_date,
					value: opportunityForm.value || 0,
					company_id: opportunityForm.company_id,
					primary_contact_id: opportunityForm.primary_contact_id || null,
					owner_id: presalesOwnerId || null,
					lead_owner_id: ownerId || null,
					opportunity_kanban_cards: {
						data: {
							column_id: opportunityForm?.status,
						},
					},
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Opportunity Created Successfully');
					refresh();
					onSuccess();
					handleClose();
				}
			})
			.catch((error: string) => {
				if (error) {
					notify(error, 'warning');
				}
			});
	};

	const handleCompanyContactClose = () => {
		setIsAddCompanyFormShown(false);
		setIsAddContactFormShown(false);
	};

	const handleCompany = (company: any) => {
		setCompanyName(company);
		setIsAddCompanyFormShown(true);
	};

	const handleContact = (contact: any) => {
		setContactName(contact);
		setIsAddContactFormShown(true);
	};

	const handleBlur = () => {
		setIsPercentageLabelHidden(!!opportunityForm.win_percentage);
	};

	const mapToFullUserName = (choice: Name) =>
		choice?.name || `${choice?.first_name} ${choice?.last_name || ''}`;

	const getOwnerValue = (userId: string) => {
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box width='464px' height='550px' borderRadius='4px'>
					<Box className={style.headerContainer}>
						<Box className={style.heading}>
							Add Opportunities{' '}
							{isAddCompanyFormShown ? (
								<>
									<DoubleArrowIcon className={style.arrowIcon} />
									<Box className={style.subHeading}>Add Company</Box>
								</>
							) : null}
							{isAddContactFormShown ? (
								<>
									<DoubleArrowIcon className={style.arrowIcon} />
									<Box className={style.subHeading}>Add Contact</Box>
								</>
							) : null}
						</Box>
						{isAddCompanyFormShown || isAddContactFormShown ? (
							<CloseIcon
								className={classes.closeIcon}
								onClick={handleCompanyContactClose}
							/>
						) : (
							<CloseIcon className={classes.closeIcon} onClick={handleClose} />
						)}
					</Box>
					<DialogContent>
						<Box className={style.formContainer}>
							{!isAddCompanyFormShown && !isAddContactFormShown ? (
								<Form initialValues={opportunityForm} onSubmit={onSave}>
									{({ handleSubmit, invalid }) => (
										<form onSubmit={handleSubmit}>
											<Box className={classes.label}>Opportunity Name *</Box>
											<TextInput
												source='name'
												fullWidth={true}
												label={''}
												validate={[
													required(),
													minLength(3, 'minimum three characters required'),
												]}
												onChange={(e: any) => {
													setOpportunityForm({
														...opportunityForm,
														name: e.target.value,
													});
												}}
											/>
											<Box className={classes.multipleInputContainer}>
												<Box width='40%' marginRight='5px'>
													<Box className={classes.label}>Stage</Box>
													<SelectInput
														source='status'
														label={''}
														choices={opportunityOptions || []}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																status: e.target.value,
															});
														}}
													/>
												</Box>
												<Box width='30%' marginRight='5px'>
													<Box className={classes.label}>Win %</Box>
													<TextInput
														source='win_percentage'
														label={''}
														name='win_percentage'
														onFocus={() => setIsPercentageLabelHidden(true)}
														onBlur={handleBlur}
														InputProps={{
															startAdornment: (
																<div
																	className={
																		isPercentageLabelHidden
																			? inlineStyle.hideInput
																			: ''
																	}
																>
																	%
																</div>
															),
														}}
														validate={number()}
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																win_percentage: e.target.value,
															});
														}}
													/>
												</Box>
												<Box width='40%'>
													<Box className={classes.label}>Priority</Box>
													<SelectInput
														source='priority'
														label=''
														fullWidth={true}
														choices={[
															{ id: 'Low', name: 'Low' },
															{ id: 'Medium', name: 'Medium' },
															{ id: 'High', name: 'High' },
														]}
														name='priority'
														onChange={(e: any) => {
															setOpportunityForm({
																...opportunityForm,
																priority: e.target.value,
															});
														}}
													/>
												</Box>
											</Box>

											<Box className={style.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Value</Box>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Close Date</Box>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={style.boxContainer}>
													<Box className={style.selectCurrency}>
														<ThemeProvider theme={currencySelectBox}>
															<SelectInput
																source='currency_id'
																label=''
																choices={currencyList || []}
																onChange={(e: any) => {
																	setOpportunityForm({
																		...opportunityForm,
																		currency_id: e.target.value,
																	});
																}}
															/>
														</ThemeProvider>
													</Box>
													<ThemeProvider theme={currencyTextBox}>
														<TextInput
															source='value'
															label={''}
															validate={number()}
															onChange={(e: any) => {
																setOpportunityForm({
																	...opportunityForm,
																	value: e.target.value,
																});
															}}
														/>
													</ThemeProvider>
												</Box>
												<Box>
													<Field
														name='close_date'
														validate={(value: any) => {
															if (
																dayjs(value).isValid() === true ||
																value === null
															) {
																return undefined;
															} else {
																return 'Invalid Date';
															}
														}}
													>
														{(props: any) => (
															<div className={style.dateInput}>
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value: any) => {
																		props.input.onChange(value);
																		setOpportunityForm({
																			...opportunityForm,
																			close_date: value,
																		});
																	}}
																	dateFormat={dateFormat}
																/>
															</div>
														)}
													</Field>
												</Box>
											</Box>
											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Lead Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Lead Owner'}
														option={
															activeUsers && activeUsers?.user
																? activeUsers?.user?.map((value) => {
																		return {
																			id: value?.id,
																			name: value?.name,
																		};
																  })
																: []
														}
														onChange={setOwnerId}
														name={'lead_owner_id'}
														validates={false}
														value={getOwnerValue(ownerId || '')}
													/>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Pre-Sales Owner</Box>
													<AutocompleteSearch
														placeholder={'Search Owner'}
														option={mentionSuggestions || []}
														onChange={setPresalesOwnerId}
														name={'owner_id'}
														validates={false}
														value={getOwnerValue(presalesOwnerId || '')}
													/>
												</Box>
											</Box>

											<Box className={classes.multipleInputContainer}>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Company</Box>
													<ReferenceInput
														source='company_id'
														reference='crm_company'
														sort={{ field: 'name', order: 'ASC' }}
														label={''}
														fullWidth
														filterToQuery={(searchText) => ({
															name: searchText,
														})}
														onChange={(event) => {
															setOpportunityForm({
																...opportunityForm,
																company_id: event,
															});
														}}
													>
														<AutocompleteInput
															optionText={(choice: any) => choice?.name}
															resettable={true}
															shouldRenderSuggestions={(name: any) =>
																name.trim().length > 0
															}
															onCreate={(event: any) => handleCompany(event)}
														/>
													</ReferenceInput>
												</Box>
												<Box className={classes.multipleInput}>
													<Box className={classes.label}>Contact</Box>
													<ReferenceInput
														source='primary_contact_id'
														reference='crm_contacts'
														label={''}
														sort={{ field: 'first_name', order: 'ASC' }}
														fullWidth
														filterToQuery={(searchText) => ({
															full_name: searchText,
														})}
														onChange={(event) => {
															setOpportunityForm({
																...opportunityForm,
																primary_contact_id: event || null,
															});
														}}
														onCreate={(event: any) => handleContact(event)}
													>
														<AutocompleteInput
															optionText={mapToFullUserName}
															resettable={true}
															shouldRenderSuggestions={(name: any) =>
																name?.trim().length > 0
															}
														/>
													</ReferenceInput>
												</Box>
											</Box>
											<Box className={classes.buttonContainer}>
												<Button
													className={
														invalid || isOpportunityLoading
															? classes.disabledStartButton
															: classes.updateButton
													}
													disabled={invalid || isOpportunityLoading}
													type='submit'
												>
													Create
												</Button>
												<Button
													onClick={handleClose}
													className={classes.cancelButton}
												>
													Cancel
												</Button>
											</Box>
										</form>
									)}
								</Form>
							) : isAddCompanyFormShown && !isAddContactFormShown ? (
								<AddCompany
									onClose={() => setIsAddCompanyFormShown(false)}
									companyName={companyName}
									setCompanyId={(id) =>
										setOpportunityForm({
											...opportunityForm,
											company_id: id,
										})
									}
								/>
							) : (
								<AddContact
									onClose={() => setIsAddContactFormShown(false)}
									contactName={contactName}
									setContactId={(id) =>
										setOpportunityForm({
											...opportunityForm,
											primary_contact_id: id,
										})
									}
									companyId={opportunityForm?.company_id}
								/>
							)}
						</Box>
					</DialogContent>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default OpportunitiesForm;
