import React, { useContext, useState, useEffect } from 'react';
import {
	Datagrid,
	List,
	FunctionField,
	TextField,
	useNotify,
	useRefresh,
} from 'react-admin';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import {
	makeStyles,
	Box,
	Button,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	detailsPageGlobalStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import LeaveTypeForm from './LeaveTypeForm.component';
import { LeaveTypeFormValues } from './LeaveManagement.model';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import EditLeavePolicy from './EditLeavePolicy.component';
import { settingsStyle } from '../settingsStyle';
import {
	useDeleteLeaveTypeMutation,
	useGetLeaveGroupsQuery,
} from '../../../generated/graphql';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';

const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '16px 0px',
	},
	ellipsisContent: {
		width: '70px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	doneIcon: {
		fill: '#3ABC9E',
		width: '18px',
		height: '18px',
	},
	crossIcon: {
		fill: '#EA4335',
		width: '18px',
		height: '18px',
	},
	breadCrumb: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
	},
	arrowIcon: {
		color: '#5C5C5C',
		width: '21px',
		paddingLeft: '7px',
		marginRight: '7px',
	},
	opportunityName: {
		color: '#4285F4',
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		width: '230px',
	},
});

interface LeavePolicy {
	__typename?: string | undefined;
	id: any;
	label: string;
	value: string;
	is_default: boolean;
	is_holidays_and_weekends_excluded: boolean;
	applicable_from?: any;
	start_date?: any;
	end_date?: any;
}

export const LeaveTypesList = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const { id: leavePolicyId }: { id: string } = useParams();
	const leaveTypesListStyles = useStyles();
	const modalFormStyles = modalFormStyle();
	const ellipsisStyles = ellipsisStyle();
	const [isLeaveFormShown, setIsLeaveFormShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [leavePolicyLabel, setLeavePolicyLabel] = useState([]);
	const [defaultLeaveGroupId, setDefaultLeaveGroupId] = useState('');
	const [leavePolicy, setLeavePolicy] = useState<LeavePolicy>();
	const [leaveType, setLeaveType] = useState<LeaveTypeFormValues>({});
	const detailsPageStyles = detailsPageGlobalStyle();
	const history = useHistory();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const [deleteLeaveType] = useDeleteLeaveTypeMutation();
	const { data: leavePolicies, refetch: refetchLeaveGroup } =
		useGetLeaveGroupsQuery({
			variables: {
				orgId: organisationId,
				employeeName: '%%',
			},
		});

	useEffect(() => {
		if (!leavePolicies) {
			return;
		}
		const policyLabel: any = leavePolicies?.org_leave_group?.map((value) => {
			return value?.label;
		});
		setLeavePolicyLabel(policyLabel);
	}, [leavePolicies]);

	const getLeavePolicyName = () => {
		if (!leavePolicies) {
			return;
		}
		const leavePolicy = leavePolicies?.org_leave_group?.find(
			({ id }: { id: string }) => id === leavePolicyId
		);
		if (leavePolicy?.label) {
			return leavePolicy.label;
		}
	};

	useEffect(() => {
		if (!leavePolicies) {
			return;
		}
		const leaveGroup = leavePolicies?.org_leave_group?.find(
			({ id }: { id: string }) => id === leavePolicyId
		);
		const defaultLeaveGroup = leavePolicies?.org_leave_group?.find(
			({ is_default }) => is_default === true
		);
		if (leaveGroup) {
			setLeavePolicy(leaveGroup);
		}
		if (defaultLeaveGroup) {
			setDefaultLeaveGroupId(defaultLeaveGroup?.id);
		}
	}, [leavePolicies, leavePolicyId]);

	const TopToolBar = () => (
		<div className={detailsPageStyles.toolBar}>
			<Box className={leaveTypesListStyles.breadCrumb}>
				<Typography className={leaveTypesListStyles.title}>Settings</Typography>
				<DoubleArrowIcon className={leaveTypesListStyles.arrowIcon} />
				<Typography className={leaveTypesListStyles.title}>
					Leave Management
				</Typography>
				<DoubleArrowIcon className={leaveTypesListStyles.arrowIcon} />
				<Tooltip title={`${getLeavePolicyName()}`} placement='right'>
					<Typography
						className={`${leaveTypesListStyles.opportunityName} ${ellipsisStyles.ellipsis}`}
					>
						{getLeavePolicyName()}
					</Typography>
				</Tooltip>
			</Box>
			<Button
				onClick={() => history.goBack()}
				className={detailsPageStyles.backButton}
			>
				Back
			</Button>
		</div>
	);

	const ToolBar = () => (
		<Box className={leaveTypesListStyles.toolBarContainer}>
			<Box></Box>
			<Button
				variant='contained'
				className={modalFormStyles.saveButton}
				onClick={() => {
					setIsLeaveFormShown(true);
				}}
			>
				Add Leave Type
			</Button>
		</Box>
	);

	const deleteMutation = (leaveTypeId: string) => {
		if (!leaveTypeId) {
			return;
		}
		deleteLeaveType({
			variables: {
				leaveTypeId: leaveTypeId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Leave type has been removed');
					refresh();
					setLeaveType({});
					setIsDeleteModalShown(false);
				}
			})
			.catch((error) => {
				notify(`Delete  failed due to dependency`, 'warning');
				setIsDeleteModalShown(false);
				setLeaveType({});
			});
	};

	return (
		<>
			<TopToolBar />
			<EditLeavePolicy
				initialValues={leavePolicy}
				refetch={() => {
					refetchLeaveGroup();
				}}
				defaultLeavePolicyId={defaultLeaveGroupId}
				leavePolicyLabel={leavePolicyLabel}
			/>
			<Box className={settingsStyles.contentCard}>
				<Box className={settingsStyles.headingContainer}>
					<Typography className={settingsStyles.heading}>
						Leave Types
					</Typography>
				</Box>
				<ToolBar />
				<List
					resource='org_leave_types'
					basePath='/org-leave-types'
					actions={false}
					empty={false}
					bulkActionButtons={false}
					filter={{ leave_group_id: leavePolicyId }}
					sort={{ field: 'leave_type', order: 'ASC' }}
				>
					<Datagrid>
						<FunctionField
							source='leave_type'
							label='TYPE'
							render={(leave: any) => {
								return (
									<Tooltip title={`${leave.leave_type}`} placement='right'>
										<Typography
											className={`${leaveTypesListStyles.ellipsisContent} ${ellipsisStyles.ellipsis}`}
										>
											{leave.leave_type}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<TextField source='default_amount' label='DEFAULT AMOUNT' />
						<TextField source='leave_increments' label='LEAVE INCREMENTS' />
						<TextField source='interval' label='INTERVAL' />
						<FunctionField
							source='is_carry_forward'
							label='CARRY FORWARD'
							render={(leave: any) =>
								leave?.is_carry_forward ? (
									<Box display='flex'>
										<DoneIcon className={leaveTypesListStyles.doneIcon} />
									</Box>
								) : (
									<Box display='flex'>
										<CloseIcon className={leaveTypesListStyles.crossIcon} />
									</Box>
								)
							}
						/>
						<TextField
							source='carry_forward_max_limit'
							label='CARRY FORWARD MAX LIMIT'
						/>
						<FunctionField
							source='is_negative_bal_allowed'
							label='ALLOW NEGATIVE BALANCE'
							render={(leave: any) =>
								leave?.is_negative_bal_allowed ? (
									<Box display='flex'>
										<DoneIcon className={leaveTypesListStyles.doneIcon} />
									</Box>
								) : (
									<Box display='flex'>
										<CloseIcon className={leaveTypesListStyles.crossIcon} />
									</Box>
								)
							}
						/>
						<FunctionField
							source='is_leave_sandwich_type'
							label='EXCLUDE HOLIDAYS & WEEKENDS'
							render={(leave: any) =>
								leave?.is_leave_sandwich_type ? (
									<Box display='flex'>
										<DoneIcon className={leaveTypesListStyles.doneIcon} />
									</Box>
								) : (
									<Box display='flex'>
										<CloseIcon className={leaveTypesListStyles.crossIcon} />
									</Box>
								)
							}
						/>
						<FunctionField
							source='is_LOP'
							label='IS LEAVE WITHOUT PAY'
							render={(leave: any) =>
								leave?.is_LOP ? (
									<Box display='flex'>
										<DoneIcon className={leaveTypesListStyles.doneIcon} />
									</Box>
								) : (
									<Box display='flex'>
										<CloseIcon className={leaveTypesListStyles.crossIcon} />
									</Box>
								)
							}
						/>
						<FunctionField
							render={(leave: any) => (
								<PopOver
									edit={() => {
										setLeaveType(leave);
										setIsLeaveFormShown(true);
									}}
									remove={() => {
										setLeaveType(leave);
										setIsDeleteModalShown(true);
									}}
								/>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<LeaveTypeForm
				open={isLeaveFormShown}
				onClose={() => {
					setLeaveType({});
					setIsLeaveFormShown(false);
				}}
				initialValues={leaveType}
				leavePolicyId={leavePolicyId}
				leavePolicyName={getLeavePolicyName()}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setLeaveType({});
					setIsDeleteModalShown(false);
				}}
				confirmationMessage={
					'Are you sure want to delete this leave type?. This action cannot be undone.'
				}
				onDelete={() => {
					if (!leaveType?.id) {
						return;
					}
					deleteMutation(leaveType?.id);
				}}
			/>
		</>
	);
};

export default LeaveTypesList;
