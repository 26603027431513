import React, { useContext } from 'react';
import {
	NumberInput,
	RadioButtonGroupInput,
	SelectInput,
	minValue,
	maxValue,
	useNotify,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import InfoIcon from '@material-ui/icons/Info';
import {
	Typography,
	Button,
	Box,
	ThemeProvider,
	IconButton,
	makeStyles,
	Tooltip,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import { UserProfileContext } from '../../../App';
import {
	useGetTimesheetSettingsQuery,
	useUpsertTimesheetSettingsMutation,
} from '../../../generated/graphql';
import { head } from 'lodash';
import {
	TIMESHEET_SETTINGS_CREATION_FAILED_TEXT,
	TIMESHEET_SETTINGS_CREATION_TEXT,
	TIMESHEET_SETTINGS_UPDATION_FAILED_TEXT,
	TIMESHEET_SETTINGS_UPDATION_TEXT,
} from '../constant';
import dayjs from 'dayjs';

const Style = makeStyles(() => ({
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '10px',
	},

	editIcon: {
		width: '12px',
		height: '12px',
	},
	timesheetDeadline: {
		margin: '7px 12px 0 10px',
		fontFamily: 'Manrope-semibold',
	},
}));
interface FormValues {
	freeze_date: number;
	id: string;
	is_note_mandatory: boolean;
	threshold_value: number;
	__typename: string;
}
const DefaultersSettings = () => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyles = Style();
	const notify = useNotify();
	const freezeDateOptions = Array.from({ length: 31 }, (_, i) => {
		return {
			id: i + 1,
			name: i + 1,
		};
	});

	const weekDays = Array.from({ length: 7 }, (_, i) => {
		const day = dayjs().day(i).format('dddd');
		return {
			id: i,
			name: day,
		};
	});

	const time = Array.from({ length: 24 }, (_, i) => {
		const data = dayjs().hour(i).format('h:00:A');
		return {
			id: dayjs().hour(i).format('HH:00:00Z'),
			name: data,
		};
	});

	const [upsertTimesheetSettings, { loading: isTimesheetSettingsLoading }] =
		useUpsertTimesheetSettingsMutation();

	const { orgId: organisationId } = useContext<any>(UserProfileContext);

	const {
		data: timesheetSettingsDetails,
		refetch: refetchTimesheetSettingsDetails,
	} = useGetTimesheetSettingsQuery({
		variables: {
			orgId: organisationId,
		},
		skip: !organisationId,
	});

	const handleFormSubmit = (formValues: FormValues) => {
		if (!formValues) {
			return;
		}
		const { __typename, ...restFormValues } = formValues;

		upsertTimesheetSettings({
			variables: {
				settingsData: restFormValues,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(
						formValues?.id
							? TIMESHEET_SETTINGS_UPDATION_TEXT
							: TIMESHEET_SETTINGS_CREATION_TEXT
					);
					refetchTimesheetSettingsDetails();
				}
			})
			.catch((error) => {
				if (error) {
					notify(
						formValues?.id
							? TIMESHEET_SETTINGS_UPDATION_FAILED_TEXT
							: TIMESHEET_SETTINGS_CREATION_FAILED_TEXT,
						'warning'
					);
					return;
				}
			});
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form
					initialValues={
						head(timesheetSettingsDetails?.timesheet_settings) || {
							is_note_mandatory: true,
						}
					}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Threshold value(%)
									</Typography>
								</Box>
								<Box className={styles.input}>
									<NumberInput
										source='threshold_value'
										label=''
										validate={[minValue(0), maxValue(100)]}
									/>
								</Box>
								<Tooltip
									title='Threshold value for timesheet defaulters report'
									placement='right'
								>
									<IconButton className={customStyles.iconContainer}>
										<InfoIcon className={customStyles.editIcon} />
									</IconButton>
								</Tooltip>
							</Box>

							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Freeze Date</Typography>
								</Box>
								<Box className={styles.input}>
									<SelectInput
										source={'freeze_date'}
										label={''}
										choices={freezeDateOptions || []}
									/>
								</Box>
								<Tooltip
									title='Freeze date for timesheet entries for every month'
									placement='right'
								>
									<IconButton className={customStyles.iconContainer}>
										<InfoIcon className={customStyles.editIcon} />
									</IconButton>
								</Tooltip>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Timesheet Deadline
									</Typography>
								</Box>
								<Box>
									<SelectInput
										source={'weekly_reminder_day'}
										label={''}
										choices={weekDays || []}
									/>
								</Box>
								<Box className={customStyles.timesheetDeadline}>at</Box>
								<Box>
									<SelectInput
										source={'weekly_reminder_time'}
										label={''}
										choices={time || []}
									/>
								</Box>
								<Box>
									<Tooltip
										title='Deadline time for timesheet entries'
										placement='right'
									>
										<IconButton className={customStyles.iconContainer}>
											<InfoIcon className={customStyles.editIcon} />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Make Note mandatory?
									</Typography>
								</Box>
								<Box className={styles.input}>
									<RadioButtonGroupInput
										source='is_note_mandatory'
										label=''
										choices={[
											{ id: true, name: 'Yes' },
											{ id: false, name: 'No' },
										]}
									/>
								</Box>
							</Box>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>
										Disable timesheet after submission?
									</Typography>
								</Box>
								<Box className={styles.input}>
									<RadioButtonGroupInput
										source='disable_after_submit'
										label=''
										choices={[
											{ id: true, name: 'Yes' },
											{ id: false, name: 'No' },
										]}
									/>
								</Box>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<Button
									variant='contained'
									disabled={invalid || pristine || isTimesheetSettingsLoading}
									className={
										invalid || pristine || isTimesheetSettingsLoading
											? classes.disabledButton
											: classes.saveButton
									}
									type='submit'
								>
									Save
								</Button>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default DefaultersSettings;
