import React, { useState } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { preventSubsequentClick } from '../../Utils/string.util';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  exportButton: {
    minWidth: '60px',
    height: '34px',
    background: '#4285F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 12px #4285F43B',
    borderRadius: '4px',
    font: 'normal normal bold 12px/37px Manrope',
    letterSpacing: '0px',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      background: '#4285F4',
    },
  },
  disabledButton: {
    color: 'grey',
    backgroundColor: 'lightgrey',
    minWidth: '70px',
    height: '34px',
    boxShadow: '0px 4px 12px #4285F43B',
    borderRadius: '4px',
    font: 'normal normal bold 12px/37px Manrope',
    textTransform: 'none',
  },
}))

interface Props {
  generateCSV: () => void;
  isDisabled?: boolean;
  style?: string;
  loading?: boolean
}

const ExportButtonWithLoader: React.FC<Props> = (props) => {
  const { generateCSV, isDisabled, style, loading } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false || loading);
	
	const disableButton = () => {
				setIsLoading(false);
			};
	
	const buttonDisabled = isDisabled ? isDisabled : false;

  const buttonStyle = style ? style : classes.exportButton;

	const preventClick = preventSubsequentClick(disableButton, 1000);
  const handleClick = () => {
    if (!isDisabled && !isLoading) {
			setIsLoading(true);
			preventClick();
      generateCSV();
    }
  };

  return (
    <Button
      endIcon={<ArrowUpwardIcon />}
      className={isDisabled || isLoading ? classes.disabledButton : buttonStyle}
      onClick={handleClick}
      disabled={ isLoading || buttonDisabled}
			startIcon={
									 isLoading && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#FFFFFF',
											}}
										/>
									)
								}
							
    >
      <span>Export</span>
    </Button>
  );
};

export default ExportButtonWithLoader;
