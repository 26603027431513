import React, { useEffect, useState, useContext } from 'react';
import {
	List,
	ReferenceField,
	Datagrid,
	FunctionField,
	useQuery,
} from 'react-admin';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Box, TextField, Button, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { GET_CONSULTANT_SKILL_LIST } from '../gqlQueries';
import { useQuery as ApolloUseQuery } from '@apollo/client';

import { EmployeeSkillEditPopOver } from '../../Employees/DetailsPage/EmployeeSkillEditPopOver.component';
import { UserProfileContext } from '../../../App';
import { SearchIcon } from '../../../assets/SvgIcons';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { ellipsisStyle } from '../../../Layout/styles';
import EmployeeSkill from '../../../SharedComponents/EmployeeSkill.component';

const ConsultantSkillDetail = (props: any) => {
	const { record } = props;
	const styles = ellipsisStyle();
	const [employeeSkillModal, setEmployeeSkillModal] = useState(false);

	const [isEmployeeSkillSubmitted, setIsEmployeeSkillSubmitted] =
		useState(false);
	const [searchSkill, setSearchSkill] = useState('');
	const { permissions: permissionList } = useContext<any>(UserProfileContext);

	const { data: skillLevelList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_level',
		payload: {
			sort: { field: 'level', order: 'ASC' },
		},
	});

	const { data: skillMasterList } = useQuery({
		type: 'GET_LIST',
		resource: 'skill_master',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});

	const { data: consultantSkill, refetch: refetchEmployeeSkills } =
		ApolloUseQuery(GET_CONSULTANT_SKILL_LIST, {
			variables: {
				filter: {
					employee_id: {
						_eq: record?.user?.id,
					},
					skill_master: {
						name: {
							_ilike: `%${searchSkill}%`,
						},
					},
				},
			},
		});
	useEffect(() => {
		if (isEmployeeSkillSubmitted) {
			setIsEmployeeSkillSubmitted(false);
			refetchEmployeeSkills();
		}
	}, [isEmployeeSkillSubmitted, refetchEmployeeSkills]);
	const useStyles = makeStyles((theme) => ({
		skillName: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
			width: '200px',
		},
		description: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
			width: '200px',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		addButton: {
			textTransform: 'none',
			minWidth: '140px',
			height: '36px',
			background: '#4285F4 0% 0% no-repeat padding-box',
			boxShadow: '0px 4px 12px #4285F43B',
			borderRadius: '4px',
			font: 'normal normal bold 12px/37px Manrope',
			color: '#FFFFFF',
			padding: '10px 16px',
			'&:hover': {
				backgroundColor: '#4285F4',
			},
		},
		tableList: {
			height: '442px',
			overflowY: 'auto',
			marginTop: '30px',
		},
	}));
	const classes = useStyles();

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px'>
							<TextField
								placeholder='Search Skill'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => setSearchSkill(e?.target?.value)}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Box>
					{permissionList?.employee_skill_mapping?.insert_permissions && (
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							className={classes.addButton}
							onClick={() => setEmployeeSkillModal(true)}
						>
							Add Skill
						</Button>
					)}
				</Box>
			</Box>
			<Box className={classes.tableList}>
				<List
					{...record}
					component='div'
					title={' '}
					basePath={'/contractor'}
					bulkActionButtons={false}
					actions={false}
					resource={'employee_skill_mapping'}
					filter={{
						id:
							consultantSkill?.employee_skill_mapping?.length > 0
								? consultantSkill?.employee_skill_mapping?.map(
										(value: any) => value?.id
								  )
								: [],
					}}
				>
					<Datagrid>
						<ReferenceField
							link={false}
							source='skill_id'
							reference='skill_master'
							label='Name'
							sortBy='skill_master.name'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.name || '- -'}`}
											placement='right'
										>
											<Typography
												className={`${classes.skillName} ${styles.ellipsis}`}
											>
												{`${skill?.name || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='skill_level_id'
							reference='skill_level'
							label='Level'
							sortBy='skill_level.level'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.level || '- -'}`}
											placement='right'
										>
											<Typography
												className={`${classes.skillName} ${styles.ellipsis}`}
											>
												{`${skill?.level || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='id'
							reference='employee_skill_mapping'
							label='Is Primary'
							sortBy='is_primary'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Typography
											className={`${classes.description} ${styles.ellipsis}`}
										>
											{`${skill?.is_primary ? 'Yes' : 'No'}`}
										</Typography>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='id'
							reference='employee_skill_mapping'
							label='Description'
							sortBy='description'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.description || '- -'}`}
											placement='right'
										>
											<Typography
												className={`${classes.description} ${styles.ellipsis}`}
											>
												{`${skill?.description || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						{permissionList?.employee_skill_mapping?.update_permissions && (
							<FunctionField
								render={(skill: any) => (
									<EmployeeSkillEditPopOver
										employeeId={skill?.id}
										setIsEmployeeSkillSubmitted={(value) =>
											setIsEmployeeSkillSubmitted(value)
										}
									/>
								)}
							/>
						)}
					</Datagrid>
				</List>
			</Box>

			<EmployeeSkill
				open={employeeSkillModal}
				onClose={() => setEmployeeSkillModal(false)}
				record={record}
				setIsEmployeeSkillSubmitted={(value: any) =>
					setIsEmployeeSkillSubmitted(value)
				}
				handleClose={() => setEmployeeSkillModal(false)}
				skillLevelList={skillLevelList}
				skillMasterList={skillMasterList}
			/>
		</>
	);
};

export default ConsultantSkillDetail;
