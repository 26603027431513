import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableHead,
	TableRow,
	Divider,
	TablePagination,
} from '@material-ui/core';
import 'antd/dist/antd.css';
import React, { useState, useContext, useEffect } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { ResponsiveContainer } from 'recharts';

import { UserProfileContext } from '../../App';
import { head, isEmpty } from 'lodash';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { calculateCurrentLeaveCalenderYear } from '../../Reports/Constant';
import {
	useGetOrganisationLeaveCalenderYearQuery,
	useGetUpcomingPendingLeaveQuery,
} from '../../generated/graphql';
import { homePageStyle } from './style';
import { CustomTableCell } from '../../SharedComponents/CustomTableCell.component';
import dayjs from 'dayjs';

const UpcomingPendingLeaves = () => {
	const { orgId: organisationId, id: userId } =
		useContext<any>(UserProfileContext);
	const styles = homePageStyle();
	const [rowsPerPage, setRowsPerPage] = useState(4);
	const [page, setPage] = useState(0);
	const [upcomingLeaves, setUpcomingLeaves] = useState<
		| {
				leave_type: string;
				reason: string;
				fromDate: string;
				toDate: string;
				days: string;
		  }[]
		| undefined
	>([]);
	const [currentLeaveCalenderYear, setCurrentLeaveCalenderYear] = useState({
		startDate: '',
		endDate: '',
	});
	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId },
			fetchPolicy: 'network-only',
		});

	const { data: upcomingPendingLeaves } = useGetUpcomingPendingLeaveQuery({
		variables: {
			finacialStartDate: currentLeaveCalenderYear?.startDate,
			finacialEndDate: currentLeaveCalenderYear?.endDate,
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const leaveCalenderData = organisationLeaveCalenderYear.organization_by_pk;
		if (!leaveCalenderData) {
			return;
		}
		const calculatedDate = calculateCurrentLeaveCalenderYear({
			leave_calendar_year_start_month:
				leaveCalenderData.leave_calendar_year_start_month,
			leave_calendar_year_start_date:
				leaveCalenderData?.leave_calendar_year_start_date,
			leave_calendar_year_end_date:
				leaveCalenderData?.leave_calendar_year_end_date,
			leave_calendar_year_end_month:
				leaveCalenderData?.leave_calendar_year_end_month,
		});
		setCurrentLeaveCalenderYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	useEffect(() => {
		if (
			!upcomingPendingLeaves ||
			isEmpty(head(upcomingPendingLeaves?.employee))
		) {
			return;
		}
		const response = head(
			upcomingPendingLeaves?.employee
		)?.employee_leaving_mappings?.map((e) => {
			return {
				leave_type: e?.org_leave_type?.leave_type,
				reason: e?.reason,
				fromDate: e?.from_date,
				toDate: e?.to_date,
				days: e?.is_half_day ? 0.5 : e?.leave_count,
			};
		});

		setUpcomingLeaves(response);
	}, [upcomingPendingLeaves]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event?.target?.value, 10));
		setPage(0);
	};

	return (
		<Box>
			<Card>
				<CardHeader
					title='My Leaves (Pending for Approval)'
					titleTypographyProps={{ variant: 'h6' }}
				/>
				<CardContent style={{ overflowY: 'auto' }}>
					<ResponsiveContainer height={355}>
						<Box>
							<Box className={styles.headerCell}>
								{upcomingPendingLeaves &&
								!isEmpty(head(upcomingPendingLeaves?.employee)) &&
								head(upcomingPendingLeaves?.employee)!.employee_leaving_mappings
									?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell>Date</CustomTableCell>

													<CustomTableCell>Number Of Days</CustomTableCell>
													<CustomTableCell>Reason</CustomTableCell>
													<CustomTableCell>Leave Type</CustomTableCell>
													<CustomTableCell></CustomTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{upcomingLeaves &&
													upcomingLeaves
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map(
															(value: {
																leave_type: string;
																reason: string;
																fromDate: string;
																toDate: string;
																days: string;
															}) => {
																return (
																	<TableRow>
																		<CustomTableCell>
																			<div className={styles.dateContainer}>
																				<DateRangeIcon
																					className={styles.calendarIcon}
																				/>
																				{`${dayjs(value?.fromDate).format(
																					'DD MMM YYYY'
																				)}-${dayjs(value?.toDate).format(
																					'DD MMM YYYY'
																				)}`}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${value?.days || ' - -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${styles.additionalInfoLabelLeave} ${styles.ellipsis}`}
																				>
																					{value?.days || ' - -'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>

																		<CustomTableCell>
																			<Tooltip
																				title={`${value?.reason || ' - -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${styles.additionalInfoLabelLeave} ${styles.ellipsis}`}
																				>
																					{value?.reason || ' - -'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${value?.leave_type || ' - -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${styles.additionalInfoLabelLeave} ${styles.ellipsis}`}
																				>
																					{value?.leave_type || ' - -'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>

																		<Divider />
																	</TableRow>
																);
															}
														)}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[4, 8, 16]}
												count={upcomingLeaves?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={styles.noData}>
										<Typography className={styles.noDataLabel}>
											No leaves pending for Approval.
										</Typography>
									</Box>
								)}
							</Box>
						</Box>
					</ResponsiveContainer>
				</CardContent>
			</Card>
		</Box>
	);
};
export default UpcomingPendingLeaves;
