import React, { useState, useContext, useEffect } from 'react';
import { useNotify } from 'react-admin';

import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';

import {
	Button,
	Box,
	Dialog,
	Typography,
	createTheme,
	makeStyles,
	ThemeProvider,
	CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserProfileContext } from '../../../App';

import dayjs from 'dayjs';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';

import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import { SliderInput } from '../Details/SliderComponent.component';
import { ResourceAllocationFormValues } from '../modal';
import { head, uniqBy } from 'lodash';
import { UPDATE_RESOURCE_ALLOCATION } from '../gqlQueries';
import { DateTimeUtil } from '../../../Utils/date-time.util';
import { GET_ORGANISATION_MAN_HOURS } from '../../gqlQueries';
import { useGetOrgHolidaysQuery } from '../../../generated/graphql';

const addResourceModalTheme = createTheme({
	overrides: {
		MuiDialog: {
			paperWidthSm: {
				maxWidth: 'fit-content',
			},
		},
	},
});

const addResourceModalStyle = makeStyles({
	container: {
		minHeight: 'fit-content',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	label: {
		fontSize: '0.85rem',
		height: '19px',
	},
	inputContainer: {
		minWidth: '160px',
		marginRight: '8px',
	},
	autoCompleteInputContainer: {
		minWidth: '200px',
		marginRight: '8px',
	},
	allocationPercentageContainer: {
		minWidth: '160px',
		marginLeft: '16px',
	},
	whitSpaceNowrap: {
		width: 'auto',
		whiteSpace: 'nowrap',
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
		marginLeft: '10px',
		height: '100%',
	},
	billableContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
		height: '100%',
		marginRight: '32px',
		justifyContent: 'center',
	},
	checkBoxInput: {
		height: '18px',
		width: '18px',
	},
	checkBoxLabel: {
		marginLeft: '8px',
	},
	resourceCostInput: {
		padding: '0px 8px !important',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '0px 4px 4px 0px',
		width: '70px',
		height: '28px !important',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	baseCurrency: {
		padding: '0px 8px !important',
		border: '1px solid #E0E0E0',
		borderRadius: '4px 0px 0px 4px',
		width: '45px',
		height: '28px !important',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		color: '#5C5C5C',
		background: 'rgba(0, 0, 0, 0.12)',
	},
	plannedHours: {
		fontSize: '14px',
		fontFamily: 'Manrope-semiBold',
		letterSpacing: '0',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},
	errorContainer: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope',
		color: 'red',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '200px',
		overflowY: 'scroll',
	},
	updateStatus: {
		fontSize: '16px',
		fontFamily: 'Manrope-semibold',
		marginBottom: '10px',
		color: 'red',
	},
});

interface ProjectAllocations {
	__typename?: 'project_resource_mapping' | undefined;
	id: any;
	employee_id: any;
	start_date: any;
	end_date: any;
}

interface ResourceAllocationFormProps {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialValues: ResourceAllocationFormValues[];
	projectResourceMappings?: ProjectAllocations[];
}

interface FormValues {
	start_date: string;
	end_date: string;
	is_partial: boolean;
	allocation_percentage: number;
	is_billable: boolean;
}

const ResourceAllocationForm = (props: ResourceAllocationFormProps) => {
	const { open, onClose, onSuccess, initialValues, projectResourceMappings } =
		props;
	const { orgId, dateFormat, noOfLocations } =
		useContext<any>(UserProfileContext);
	const notify = useNotify();
	const formStyles = modalFormStyle();
	const addResourceModalStyles = addResourceModalStyle();
	const [isSliderDisabled, setIsSliderDisabled] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({
		start_date:
			head(initialValues)?.projectStartDate ?? dayjs().format('YYYY-MM-DD'),
		end_date:
			head(initialValues)?.projectEndDate ?? dayjs().format('YYYY-MM-DD'),
		allocation_percentage: 100,
		is_billable: true,
		is_partial: false,
	});

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setFormValues({
			start_date:
				head(initialValues)?.projectStartDate ?? dayjs().format('YYYY-MM-DD'),
			end_date:
				head(initialValues)?.projectEndDate ?? dayjs().format('YYYY-MM-DD'),
			allocation_percentage: 100,
			is_billable: true,
			is_partial: false,
		});
	}, [initialValues]);

	const [updateResourceAllocation] = useApolloMutation(
		UPDATE_RESOURCE_ALLOCATION
	);

	// Getting Manhours from settings
	const { data: orgManHours } = useApolloQuery(GET_ORGANISATION_MAN_HOURS, {
		variables: {
			orgId: orgId,
		},
	});

	const { data: orgHolidays } = useGetOrgHolidaysQuery({
		variables: {
			startDate: formValues.start_date,
			endDate: formValues.end_date,
		},
	});

	const getNumberOfHolidays = (
		startDate: Date | string,
		endDate: Date | string,
		holidays?: {
			id?: string;
			date?: string;
		}[]
	) => {
		if (!holidays || holidays.length === 0) {
			return 0;
		}
		const holidaysCount = uniqBy(
			holidays.filter(
				(holiday) =>
					dayjs(holiday.date).isAfter(dayjs(startDate)) &&
					dayjs(holiday.date).isBefore(dayjs(endDate)) &&
					dayjs(holiday.date).day() !== 0 &&
					dayjs(holiday.date).day() !== 6
			),
			(data) => data.date
		).length;

		return holidaysCount;
	};

	const _getHoursAndCost = (resourceAllocation: {
		start_date: Date | string;
		end_date: Date | string;
		allocation_percentage: number;
		resource_cost: number;
		holidays?: {
			id?: string;
			date?: string;
		}[];
	}) => {
		if (
			!resourceAllocation?.allocation_percentage ||
			!orgManHours?.organization_by_pk?.working_hours
		) {
			return { cost: 0, hours: 0 };
		}
		const startDate = new Date(resourceAllocation.start_date);
		const endDate = new Date(resourceAllocation.end_date);
		const days = DateTimeUtil.getBusinessDaysBetween(startDate, endDate);
		const allocationFactor = resourceAllocation?.allocation_percentage / 100;
		const settingHours = orgManHours?.organization_by_pk?.working_hours;
		const organizationHolidays =
			orgHolidays && orgHolidays?.org_holidays?.length > 0
				? orgHolidays?.org_holidays.map((holiday) => ({
						id: holiday.name,
						date: holiday.date,
				  }))
				: [];

		const numberOfHolidays =
			noOfLocations && noOfLocations > 0
				? getNumberOfHolidays(startDate, endDate, resourceAllocation?.holidays)
				: getNumberOfHolidays(startDate, endDate, organizationHolidays);

		const hours = (days - numberOfHolidays) * (settingHours * allocationFactor);
		const cost =
			Math.round(hours * resourceAllocation.resource_cost * 100) / 100;
		return { cost, hours };
	};

	const _getDateRangesWithIndex = (projResMapping: ProjectAllocations[]) => {
		return projResMapping.map((allocation, index) => ({
			index,
			id: allocation.id,
			start_date: new Date(allocation.start_date),
			end_date: new Date(allocation.end_date),
		}));
	};

	const checkIsAllocationOverlap = (
		allocations: {
			index: number;
			id: string;
			start_date: Date;
			end_date: Date;
		}[],
		allocationForm: FormValues,
		resourceAllocationId: string
	) => {
		if (!allocations || !allocationForm || !resourceAllocationId) {
			return;
		}
		const selectedUserStartDate = new Date(allocationForm.start_date);
		const selectedUserEndDate = new Date(allocationForm.end_date);

		return allocations.map((allocation) => {
			if (
				resourceAllocationId === allocation.id ||
				(selectedUserStartDate < allocation.start_date &&
					selectedUserEndDate < allocation.start_date) ||
				(allocation.end_date < selectedUserStartDate &&
					allocation.end_date < selectedUserEndDate)
			) {
				return false;
			}
			return true;
		});
	};

	const handleSubmit = async (values: FormValues) => {
		if (!initialValues) {
			return;
		}

		const projectStartDate =
			head(initialValues)?.projectStartDate ?? dayjs().format('YYYY-MM-DD');
		const projectEndDate =
			head(initialValues)?.projectEndDate ?? dayjs().format('YYYY-MM-DD');

		if (dayjs(values?.start_date).isBefore(dayjs(projectStartDate))) {
			setErrors((prev) => [
				...prev,
				`Start date ${dayjs(values?.start_date).format(
					dateFormat
				)} should be greater than project start date ${dayjs(
					projectStartDate
				).format(dateFormat)}`,
			]);

			return;
		}

		if (dayjs(values?.end_date).isAfter(dayjs(projectEndDate))) {
			setErrors((prev) => [
				...prev,
				`End date ${dayjs(values?.end_date).format(
					dateFormat
				)} should be less than project end date ${dayjs(projectEndDate).format(
					dateFormat
				)}`,
			]);

			return;
		}

		if (dayjs(values?.start_date).isAfter(values?.end_date)) {
			setErrors((prev) => [
				...prev,
				`End date "${dayjs(values?.end_date).format(
					dateFormat
				)}" should be greater than start date "${dayjs(
					values?.start_date
				).format(dateFormat)}"`,
			]);

			return;
		}
		let clientValidationErrors = [] as string[];

		const payload = initialValues.map((resourceAllocation) => {
			let err = [] as string[];
			// Handling error before create payload
			if (resourceAllocation.resourceJoinDate > values?.start_date) {
				err = [
					...err,
					`Allocated start date "${dayjs(values.start_date).format(
						dateFormat
					)}" must be greater than Resource "${
						resourceAllocation.userName
					}" joining date "${dayjs(resourceAllocation.resourceJoinDate).format(
						dateFormat
					)}"`,
				];
			}

			if (
				resourceAllocation.resourceEndDate !== null &&
				resourceAllocation.resourceEndDate < values.end_date
			) {
				err = [
					...err,
					`Resource "${resourceAllocation.userName}" End date "${dayjs(
						resourceAllocation.resourceEndDate
					).format(
						dateFormat
					)}" must be greater than allocated end date "${dayjs(
						values.end_date
					).format(dateFormat)}"`,
				];
			}

			// Validate overlap Allocation
			if (projectResourceMappings && projectResourceMappings?.length > 0) {
				const selectedUser = projectResourceMappings.filter(
					(projectResourceMapping) =>
						projectResourceMapping.employee_id ===
						resourceAllocation?.employee_id
				);
				if (selectedUser?.length > 0) {
					const overlapValidationResp = checkIsAllocationOverlap(
						_getDateRangesWithIndex(selectedUser),
						values,
						resourceAllocation.id
					);
					if (overlapValidationResp?.includes(true)) {
						err = [
							...err,
							`Resource "${resourceAllocation.userName} is already exist within this period`,
						];
					}
				}
			}

			if (err.length !== 0) {
				clientValidationErrors = [...clientValidationErrors, ...err];
				return {};
			}

			return {
				id: resourceAllocation.id,
				employee_id: resourceAllocation.employee_id,
				start_date: values?.start_date,
				end_date: values?.end_date,
				allocation_percentage: values?.allocation_percentage,
				is_partial: values.is_partial,
				is_billable: values.is_billable,
				resource_cost: resourceAllocation.resource_cost,
				project_id: resourceAllocation.project_id,
				total_cost: _getHoursAndCost({
					start_date: values?.start_date,
					end_date: values?.end_date,
					allocation_percentage: values?.allocation_percentage,
					resource_cost: resourceAllocation?.resource_cost || 0,
					holidays: resourceAllocation.locationHolidays,
				}).cost,
				total_hours: _getHoursAndCost({
					start_date: values?.start_date,
					end_date: values?.end_date,
					allocation_percentage: values?.allocation_percentage,
					resource_cost: resourceAllocation?.resource_cost || 0,
					holidays: resourceAllocation.locationHolidays,
				}).hours,
			};
		});

		if (clientValidationErrors.length === 0) {
			setIsLoading(true);
			updateResourceAllocation({
				variables: {
					data: payload,
				},
			})
				.then((res) => {
					if (!res.errors) {
						notify('Updated Successfully...');
						setIsLoading(false);
						handleClose();
						onSuccess();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setErrors(clientValidationErrors);
	};

	const handleClose = () => {
		setErrors([]);
		setIsLoading(false);
		onClose();
	};

	return (
		<ThemeProvider theme={addResourceModalTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				disableBackdropClick
			>
				<ThemeProvider theme={modalFormTheme}>
					<Box className={addResourceModalStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								{`Edit allocation`}
							</Typography>
							<CloseIcon
								className={formStyles.closeIcon}
								onClick={handleClose}
							/>
						</Box>
						<Form
							initialValues={
								initialValues.length > 0
									? {
											start_date:
												head(initialValues)?.projectStartDate ??
												dayjs().format('YYYY-MM-DD'),
											end_date:
												head(initialValues)?.projectEndDate ??
												dayjs().format('YYYY-MM-DD'),
											allocation_percentage: 100,
											is_billable: true,
											is_partial: false,
									  }
									: {}
							}
							onSubmit={handleSubmit}
							mutators={{
								setPartialAllocationPercentage: (args, state, utils) => {
									if (!state?.lastFormState?.values?.is_partial) {
										utils.changeValue(state, 'allocation_percentage', () => 50);
										return;
									}
									utils.changeValue(state, 'allocation_percentage', () => 100);
								},
								checkIsPartial: (args, state, utils) => {
									if (
										state?.lastFormState?.values?.allocation_percentage === 100
									) {
										utils.changeValue(state, 'is_partial', () => false);
										return;
									}
									utils.changeValue(state, 'is_partial', () => true);
								},
							}}
						>
							{({ handleSubmit, invalid, values, form }) => (
								<form
									onSubmit={handleSubmit}
									onChange={() => {
										setErrors([]);
									}}
								>
									<Box className={formStyles.formContainer}>
										<Box>
											<Box display='flex' width='100%'>
												<Box className={addResourceModalStyles.inputContainer}>
													<Box display='flex' width='100%'>
														<Box
															className={addResourceModalStyles.inputContainer}
														>
															<Typography className={formStyles.label}>
																Start Date
															</Typography>
															<Field
																name='start_date'
																validate={(value) => {
																	if (dayjs(value).isValid() === true) {
																		return undefined;
																	} else {
																		return 'Invalid Date';
																	}
																}}
															>
																{(props) => (
																	<Box width='160px'>
																		<CustomDateInput
																			minDate={
																				head(initialValues)?.projectStartDate
																			}
																			maxDate={
																				head(initialValues)?.projectEndDate
																			}
																			name={props.input.name}
																			initialValue={props.input.value || null}
																			onChange={(value) => {
																				if (!value) {
																					return;
																				}
																				props.input.onChange(value);
																				setFormValues({
																					...formValues,
																					start_date:
																						dayjs(value).format('YYYY-MM-DD'),
																				});
																				setErrors([]);
																			}}
																			dateFormat={dateFormat}
																			isLeaveAndHolidaysEnabled={true}
																		/>
																	</Box>
																)}
															</Field>
														</Box>
														<Box
															className={addResourceModalStyles.inputContainer}
														>
															<Typography className={formStyles.label}>
																End Date
															</Typography>
															<Field
																name='end_date'
																validate={(value) => {
																	if (dayjs(value).isValid() === true) {
																		return undefined;
																	} else {
																		return 'Invalid Date';
																	}
																}}
															>
																{(props) => (
																	<Box width='160px'>
																		<CustomDateInput
																			minDate={
																				head(initialValues)?.projectStartDate
																			}
																			maxDate={
																				head(initialValues)?.projectEndDate
																			}
																			name={props.input.name}
																			initialValue={props.input.value || null}
																			onChange={(value) => {
																				if (!value) {
																					return;
																				}
																				props.input.onChange(value);
																				setFormValues({
																					...formValues,
																					end_date:
																						dayjs(value).format('YYYY-MM-DD'),
																				});
																				setErrors([]);
																			}}
																			dateFormat={dateFormat}
																			isLeaveAndHolidaysEnabled={true}
																		/>
																	</Box>
																)}
															</Field>
														</Box>
														<Box
															className={
																addResourceModalStyles.allocationPercentageContainer
															}
														>
															<Typography className={formStyles.label}>
																Allocation Percentage
															</Typography>
															<Field name='allocation_percentage'>
																{(props) => (
																	<SliderInput
																		onChange={(value: any) => {
																			props.input.onChange(value);
																			setFormValues({
																				...formValues,
																				allocation_percentage: value,
																			});
																			form.mutators.checkIsPartial();
																		}}
																		min={5}
																		max={100}
																		label={''}
																		initialValue={
																			formValues.allocation_percentage
																		}
																		disabled={isSliderDisabled}
																	/>
																)}
															</Field>
														</Box>
														<Box
															className={addResourceModalStyles.inputContainer}
														>
															<Box
																className={
																	addResourceModalStyles.billableContainer
																}
															>
																<Field
																	type='checkbox'
																	component='input'
																	name='is_partial'
																	className={
																		addResourceModalStyles.checkBoxInput
																	}
																	onClick={(event: any) => {
																		form.mutators.setPartialAllocationPercentage();
																		if (event) {
																			const checkStatus =
																				event?.target?.checked;
																			setFormValues({
																				...formValues,
																				allocation_percentage: checkStatus
																					? 50
																					: 100,
																				is_partial: checkStatus ? true : false,
																			});
																			setIsSliderDisabled(
																				checkStatus ? false : true
																			);
																		}
																	}}
																/>
																<Typography
																	className={
																		addResourceModalStyles.checkBoxLabel
																	}
																>
																	Partial
																</Typography>
															</Box>
														</Box>
														<Box
															className={addResourceModalStyles.inputContainer}
														>
															<Box
																className={
																	addResourceModalStyles.checkboxContainer
																}
															>
																<Field
																	type='checkbox'
																	component='input'
																	name='is_billable'
																	className={
																		addResourceModalStyles.checkBoxInput
																	}
																	onClick={(event: any) => {
																		const checkStatus = event?.target?.checked;
																		setFormValues({
																			...formValues,
																			is_billable: checkStatus ? true : false,
																		});
																	}}
																/>
																<Typography
																	className={
																		addResourceModalStyles.checkBoxLabel
																	}
																>
																	Billable
																</Typography>
															</Box>
														</Box>
													</Box>
													<Box className={formStyles.buttonContainer} mt={2}>
														<Button
															className={
																invalid || isLoading || errors.length > 0
																	? `${formStyles.disabledButton}`
																	: `${formStyles.saveButton}`
															}
															disabled={
																invalid || isLoading || errors.length > 0
															}
															type='submit'
															startIcon={
																isLoading && (
																	<CircularProgress
																		style={{
																			height: '20px',
																			width: '20px',
																			color: '#4285F4',
																		}}
																	/>
																)
															}
														>
															Update
														</Button>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</form>
							)}
						</Form>

						{errors.length > 0 && (
							<>
								<div className={addResourceModalStyles.updateStatus}>
									Update Failed!
								</div>
								<div className={addResourceModalStyles.errorContainer}>
									{errors.length > 0 &&
										errors.map((value, i) => <div key={i}>{value}</div>)}
								</div>
							</>
						)}
					</Box>
				</ThemeProvider>
			</Dialog>
		</ThemeProvider>
	);
};

export default ResourceAllocationForm;
