import React, { useState, useContext, useEffect } from 'react';
import { useNotify } from 'react-admin';

import {
	Box,
	Button,
	Tooltip,
	IconButton,
	ThemeProvider,
	createTheme,
	InputAdornment,
	Typography,
} from '@material-ui/core';
import { globalStyles, modalFormStyle } from '../../../Layout/styles';
import AddIcon from '@material-ui/icons/Add';
import { TimesheetStyles } from '.././TimesheetStyles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import {
	useDeleteOvertimeEntryMutation,
	useGetEmployeeJoinDateByUserIdQuery,
	useGetOrgHolidaysByUserQuery,
	useGetOvertimeApproversForTimesheetQuery,
	useGetOvertimeTimesheetsQuery,
	useGetOvertimeTotalHoursQuery,
	useGetProjectsOptionsQuery,
	useGetTimesheetStatusQuery,
	useSubmitOvertimeMutation,
	useUpdateOvertimeStatusMutation,
	useUpdateOvertimeToSubmissionStatusMutation,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import ConfirmationModal from '../../../SharedComponents/ConfirmationModal.component';
import TimesheetOvertimeForm from './TimesheetOvertimeForm.component';
import { head, orderBy, uniqBy } from 'lodash';
import {
	getProjectOptions,
	getTimesheetOvertimeDataForDay,
	getTimesheetOvertimeSubmissionId,
	getOvertimeTotalHoursByDay,
} from './Overtime.utils';
import OvertimeDailyView from './OvertimeDailyView.component';
import {
	OVERTIME_DELETE_CONFIRMATION_TEXT,
	OVERTIME_DELETE_NOTIFICATION_TEXT,
	OVERTIME_SUBMISSION_CONFIRMATION_TEXT,
	OVERTIME_SUBMISSION_SUCCESS_TEXT,
	TIMESHEET_OVERTIME_APPROVED_STATUS,
	TIMESHEET_OVERTIME_SUBMITTED_STATUS,
} from './constant';
import {
	HolidayProps,
	TimesheetOvertimeFormValues,
	TimesheetOvertimeValues,
} from './Overtime.model';
import useGetExcludedLeaves from '../useGetExcluedLeaves';
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
				width: '45px',
			},
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

function TimesheetOvertime() {
	const {
		id: userId,
		orgId,
		noOfLocations,
		locationId,
	} = useContext<any>(UserProfileContext);
	const { data: user, loading: joinDateLoader } =
		useGetEmployeeJoinDateByUserIdQuery({
			variables: { user_id: userId },
			fetchPolicy: 'network-only',
		});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;
	const [isTimesheetOvertimeFormShown, setIsTimesheetOvertimeFormShown] =
		useState(false);
	const globalStyle = globalStyles();
	const modalFormStyles = modalFormStyle();
	const timesheetStyles = TimesheetStyles();
	const notify = useNotify();
	const [timesheetOvertimeData, setTimesheetOvertimeData] = useState<
		(TimesheetOvertimeValues | null | undefined)[] | undefined
	>();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [
		currentWeekTimesheetOvertimeStatus,
		setCurrentWeekTimesheetOvertimeStatus,
	] = useState<{
		label?: string;
		value?: string | null;
	}>({});
	const [selectedTimesheetOvertimeEntry, setSelectedTimesheetOvertimeEntry] =
		useState<TimesheetOvertimeFormValues>({});
	const [
		selectedDeleteTimesheetOvertimeEntryId,
		setSelectedDeleteTimesheetOvertimeEntryId,
	] = useState('');
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [selectedDate, setSelectedDate] = useState(
		dayjs().format('YYYY-MM-DD')
	);
	const disableBeforeJoinDate = joinDateLoader || selectedDate < userJoinDate;
	const [orgHoliday, setOrgHoliday] = useState<HolidayProps[] | undefined>();
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [selectedWeek, setSelectedWeek] = useState(
		Array(7)
			.fill(1)
			.map((value: number, index: number) =>
				dayjs(selectedDate).day(index).format('YYYY-MM-DD')
			)
	);

	const [excludedLeaves] = useGetExcludedLeaves(
		selectedWeek[0],
		selectedWeek[6],
		userId,
		locationId
	);

	const [timesheetOvertimeApproverList, setTimesheetOvertimeApproverLIst] =
		useState<
			{
				id: any;
				name: string | null | undefined;
				type?: string | null | undefined;
			}[]
		>([]);

	const { data: projectOptions } = useGetProjectsOptionsQuery({
		variables: {
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: weekHoursByday, refetch: refetchWeekHoursByDay } =
		useGetOvertimeTotalHoursQuery({
			variables: {
				userId: userId,
				startDate: selectedWeek[0],
				endDate: selectedWeek[6],
			},
			fetchPolicy: 'network-only',
		});

	const {
		data: timesheetsOvertimeEntries,
		loading: timesheetOvertimeEntriesLoading,
		refetch: refetchTimesheetOvertimeEntries,
	} = useGetOvertimeTimesheetsQuery({
		variables: {
			date: selectedDate,
			employeeId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: approverList } = useGetOvertimeApproversForTimesheetQuery({
		variables: {
			userId: userId,
			orgId: orgId,
		},
	});

	const { data: timesheetStatus } = useGetTimesheetStatusQuery();

	const { data: orgHolidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							is_restricted: { _eq: false },
							date: {
								_gte: selectedWeek[0],
								_lte: selectedWeek[6],
							},
							location_id: { _eq: locationId },
					  }
					: {
							is_restricted: { _eq: false },
							date: {
								_gte: selectedWeek[0],
								_lte: selectedWeek[6],
							},
					  },
			optionalHolidayFilter:
				locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: selectedWeek[0],
									_lte: selectedWeek[6],
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: selectedWeek[0],
									_lte: selectedWeek[6],
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
	});
	const [deleteTimesheetOvertimeEntry] = useDeleteOvertimeEntryMutation();
	const [submitTimesheetOvertime] = useSubmitOvertimeMutation();
	const [updateTimeSheetOvertimeStatus] = useUpdateOvertimeStatusMutation();

	const [updateTimesheetOvertimeToSubmissionStatus] =
		useUpdateOvertimeToSubmissionStatusMutation();
	const setPreviousDate = () => {
		setSelectedDate(
			dayjs(selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
		);
	};
	const setNextDate = () => {
		setSelectedDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'));
	};

	const checkIfTimesheetOvertimeSubmitted = () => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission =
			weekHoursByday.timesheet_overtime_daily_hours.find(
				(totalHours) => totalHours?.date === selectedDate
			);
		return selectedWeekSubmission?.timesheetSubmission?.timesheet_status
			.value === TIMESHEET_OVERTIME_SUBMITTED_STATUS ||
			selectedWeekSubmission?.timesheetSubmission?.timesheet_status.value ===
				TIMESHEET_OVERTIME_APPROVED_STATUS
			? true
			: false;
	};

	const getOvertimeWeekTotalHours = () => {
		if (!weekHoursByday) {
			return;
		}
		const timesheetOvertimeDailyHours =
			weekHoursByday.timesheet_overtime_daily_hours.map(
				(timesheet) => timesheet.sum
			);

		const weekTotalHours = timesheetOvertimeDailyHours
			.map((timesheetOvertimeDailyHour) =>
				timesheetOvertimeDailyHour.split(':')
			)
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return (
			`${Math.floor(weekTotalHours.asHours())}:${weekTotalHours.format(
				'mm'
			)}` || '00:00'
		);
	};

	const closeTimeSheetOvertimeForm = () => {
		setSelectedTimesheetOvertimeEntry({});
		setIsTimesheetOvertimeFormShown(false);
	};

	const closeDeleteModal = () => {
		setSelectedDeleteTimesheetOvertimeEntryId('');
		setIsDeleteModalShown(false);
	};

	useEffect(() => {
		if (!approverList) {
			return;
		}
		const approversTableList = orderBy(
			approverList?.timesheet_overtime_approvers?.map((usr) => ({
				id: usr?.user?.id,
				name: usr?.user?.full_name,
			})),
			[(option) => option?.name?.toUpperCase()],
			['asc']
		);
		const reportingManager =
			head(approverList?.user) &&
			head(approverList?.user)?.user_type === 'employee'
				? [
						{
							id: head(approverList?.user)?.employee?.reportsTo?.id,
							name: `${
								head(approverList?.user)?.employee?.reportsTo?.full_name
							} (RM)`,
							type: 'reporting_manager',
						},
				  ]
				: [
						{
							id: head(head(approverList?.user)?.contractors)?.reportee?.id,
							name: `${
								head(head(approverList?.user)?.contractors)?.reportee?.full_name
							} (RM)`,
							type: 'reporting_manager',
						},
				  ];

		const reportingAndOvertimeApproverList = reportingManager
			? uniqBy([...reportingManager, ...approversTableList], function (e) {
					return e.id;
			  })
			: [...approversTableList];
		setTimesheetOvertimeApproverLIst(reportingAndOvertimeApproverList);
	}, [approverList]);

	useEffect(() => {
		if (!timesheetsOvertimeEntries) {
			return;
		}
		const timesheetOvertime = getTimesheetOvertimeDataForDay(
			timesheetsOvertimeEntries
		);
		if (!timesheetOvertime) {
			return;
		}
		setTimesheetOvertimeData(timesheetOvertime);
	}, [timesheetsOvertimeEntries]);

	const handleDeleteTimesheetEntry = (timesheetEntryId: string) => {
		if (!timesheetEntryId) {
			return;
		}
		deleteTimesheetOvertimeEntry({
			variables: {
				timesheetEntryId: timesheetEntryId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(OVERTIME_DELETE_NOTIFICATION_TEXT);
					setSelectedTimesheetOvertimeEntry({});
					setIsDeleteModalShown(false);
					refetchTimesheetOvertimeEntries();
					refetchWeekHoursByDay();
					return;
				}
			})
			.catch((error) => {
				return;
			});
	};

	useEffect(() => {
		if (!orgHolidays) {
			setOrgHoliday([]);
			return;
		}
		const regularHolidays =
			orgHolidays?.org_holidays?.map((value) => {
				return {
					name: value?.name,
					date: value?.date,
				};
			}) || [];
		const optionalHolidays =
			orgHolidays?.user_optional_holiday_mapping?.map((value) => {
				return {
					name: value?.org_holiday?.name,
					date: value?.org_holiday?.date,
				};
			}) || [];
		if ([...regularHolidays, ...optionalHolidays]?.length > 0) {
			!locationId && noOfLocations && noOfLocations > 0
				? setOrgHoliday([])
				: setOrgHoliday([...regularHolidays, ...optionalHolidays]);
		}
	}, [locationId, noOfLocations, orgHolidays]);

	const handleReSubmitTimesheetOvertime = async (
		timesheetSubmissionId: string
	) => {
		if (!timesheetStatus || !timesheetSubmissionId) {
			return;
		}
		const submittedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === TIMESHEET_OVERTIME_SUBMITTED_STATUS
		);
		const timesheetReject = await updateTimeSheetOvertimeStatus({
			variables: {
				timesheetSubmissionId: timesheetSubmissionId,
				timesheetStatusId: submittedTimesheetStatus?.id,
			},
		});
		if (!timesheetReject.errors) {
			refetchWeekHoursByDay();
			setIsConfirmationModalShown(false);
			notify(OVERTIME_SUBMISSION_SUCCESS_TEXT);
		}
	};
	const handleSubmitTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		const timesheetOvertimeSubmissionId = getTimesheetOvertimeSubmissionId(
			weekHoursByday,
			selectedDate
		);
		if (timesheetOvertimeSubmissionId) {
			handleReSubmitTimesheetOvertime(timesheetOvertimeSubmissionId);
			return;
		}
		const submittedTimesheetOvertimeStatus =
			timesheetStatus?.timesheet_status?.find(
				(status) => status.value === TIMESHEET_OVERTIME_SUBMITTED_STATUS
			);
		const timesheetSubmission = await submitTimesheetOvertime({
			variables: {
				data: {
					submitted_by: userId,
					timesheet_status_id: submittedTimesheetOvertimeStatus?.id,
				},
			},
		});
		if (timesheetSubmission) {
			const { data: timesheetSubmissionResponse } = timesheetSubmission;
			updateTimesheetOvertimeToSubmissionStatus({
				variables: {
					startDate: selectedDate,
					endDate: selectedDate,
					userId: userId,
					timesheetSubmissionId:
						timesheetSubmissionResponse
							?.insert_timesheet_overtime_submissions_one?.id,
				},
			})
				.then((response) => {
					if (!response.errors) {
						refetchWeekHoursByDay();
						setIsConfirmationModalShown(false);
						notify(OVERTIME_SUBMISSION_SUCCESS_TEXT);
					}
				})
				.catch((error) => {
					return;
				});
		}
	};

	useEffect(() => {
		if (!selectedDate) {
			return;
		}
		const week = Array(7)
			.fill(1)
			.map((value: number, index: number) =>
				dayjs(selectedDate).day(index).format('YYYY-MM-DD')
			);
		setSelectedWeek(week);
	}, [selectedDate]);

	useEffect(() => {
		if (!weekHoursByday) {
			return;
		}
		const selectedWeekSubmission =
			weekHoursByday.timesheet_overtime_daily_hours?.find(
				(totalHours) => totalHours?.date === selectedDate
			);
		if (!selectedWeekSubmission) {
			setCurrentWeekTimesheetOvertimeStatus({});
			return;
		}
		setCurrentWeekTimesheetOvertimeStatus({
			label:
				selectedWeekSubmission.timesheetSubmission?.timesheet_status?.label,
			value:
				selectedWeekSubmission.timesheetSubmission?.timesheet_status?.value,
		});
	}, [selectedDate, weekHoursByday]);
	return (
		<>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box className={timesheetStyles.navigationToolbar}>
					<Box ml={1}>
						<ThemeProvider theme={dateTimePickerTheme}>
							<form noValidate>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										variant='inline'
										value={selectedDate}
										format='MMM D, YYYY'
										onChange={(date) => {
											setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
										}}
										label=''
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<Tooltip title={'Date'}>
														<IconButton className={globalStyle.iconButton}>
															<DateRangeIcon id='timesheet_calendar_icon' />
														</IconButton>
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								</MuiPickersUtilsProvider>
							</form>
						</ThemeProvider>
					</Box>
					<Box>
						<Box className={timesheetStyles.dateContainer}>
							<Box width='160px'>
								<Typography className={timesheetStyles.dateRange}>
									{dayjs(selectedDate).format('ddd, DD MMM YYYY')}
								</Typography>
							</Box>
							<Box display='flex' alignItems='center'>
								<Button
									className={timesheetStyles.dateIncrementButton}
									onClick={setPreviousDate}
									id='timesheet_day_view_backward_arrow'
								>
									{'<'}
								</Button>
								<Button
									className={timesheetStyles.dateIncrementButton}
									onClick={setNextDate}
									id='timesheet_day_view_forward_arrow'
								>
									{'>'}
								</Button>
							</Box>
						</Box>
					</Box>
					{(currentWeekTimesheetOvertimeStatus.value ===
						TIMESHEET_OVERTIME_SUBMITTED_STATUS ||
						currentWeekTimesheetOvertimeStatus.value ===
							TIMESHEET_OVERTIME_APPROVED_STATUS) && (
						<div
							className={`${timesheetStyles.statusContainer} 
      ${timesheetStyles.timesheetStatusContainer}
      ${
				currentWeekTimesheetOvertimeStatus.value ===
				TIMESHEET_OVERTIME_SUBMITTED_STATUS
					? timesheetStyles.submittedStatus
					: currentWeekTimesheetOvertimeStatus.value ===
					  TIMESHEET_OVERTIME_APPROVED_STATUS
					? timesheetStyles.approvedStatus
					: timesheetStyles.rejectedStatus
			}
      `}
						>
							{currentWeekTimesheetOvertimeStatus.label}
						</div>
					)}
				</Box>
				<Button
					className={modalFormStyles.saveButton}
					variant='contained'
					startIcon={<AddIcon />}
					onClick={() => {
						setIsTimesheetOvertimeFormShown(true);
					}}
					id='add_new_timesheet_entry'
					disabled={
						disableBeforeJoinDate ||
						currentWeekTimesheetOvertimeStatus.value ===
							TIMESHEET_OVERTIME_APPROVED_STATUS ||
						(timesheetsOvertimeEntries &&
							timesheetsOvertimeEntries?.timesheet_overtime?.length > 0)
					}
				>
					Add New Entry
				</Button>
			</Box>
			<Box mt={2} className={timesheetStyles.container}>
				<OvertimeDailyView
					timesheetOvertimeEntries={timesheetOvertimeData}
					isAddButtonDisabled={
						disableBeforeJoinDate ||
						currentWeekTimesheetOvertimeStatus.value ===
							TIMESHEET_OVERTIME_APPROVED_STATUS ||
						(timesheetsOvertimeEntries &&
							timesheetsOvertimeEntries?.timesheet_overtime?.length > 0)
							? true
							: false
					}
					disabled={
						disableBeforeJoinDate ||
						currentWeekTimesheetOvertimeStatus.value ===
							TIMESHEET_OVERTIME_APPROVED_STATUS
							? true
							: false
					}
					disableSubmitTimesheetOvertime={checkIfTimesheetOvertimeSubmitted()}
					dailyHours={selectedWeek.map((date) => ({
						date: date,
						totalHours: weekHoursByday
							? getOvertimeTotalHoursByDay(date, weekHoursByday)
							: '00:00',
						orgHoliday: orgHoliday || [],
						leaves: excludedLeaves || [],
					}))}
					onDateChange={(date) => {
						if (!date) {
							return;
						}
						setSelectedDate(date);
					}}
					selectedDate={selectedDate}
					isTimesheetOvertimeLoading={timesheetOvertimeEntriesLoading}
					onAddEntry={() => {
						setIsTimesheetOvertimeFormShown(true);
					}}
					onEdit={(timesheet) => {
						if (!timesheet) {
							return;
						}
						setSelectedTimesheetOvertimeEntry(timesheet);
						setIsTimesheetOvertimeFormShown(true);
					}}
					onDelete={(timesheet) => {
						if (!timesheet) {
							return;
						}
						setSelectedDeleteTimesheetOvertimeEntryId(timesheet);
						setIsDeleteModalShown(true);
					}}
					weekTotalHours={getOvertimeWeekTotalHours() || '00:00'}
					onSubmitTimesheetOvertime={() => {
						setIsConfirmationModalShown(true);
					}}
					isModalClosed={isTimesheetOvertimeFormShown}
				/>
				<TimesheetOvertimeForm
					open={isTimesheetOvertimeFormShown}
					onClose={closeTimeSheetOvertimeForm}
					timesheetOvertimeSubmissionId={getTimesheetOvertimeSubmissionId(
						weekHoursByday,
						selectedDate
					)}
					timesheetOvertimeEntries={[]}
					projectOptions={
						projectOptions ? getProjectOptions(projectOptions) : []
					}
					totalHours={
						weekHoursByday
							? getOvertimeTotalHoursByDay(selectedDate, weekHoursByday)
							: '00:00'
					}
					selectedDate={selectedDate}
					initialValues={selectedTimesheetOvertimeEntry}
					onSuccess={() => {
						setSelectedTimesheetOvertimeEntry({});
						setSelectedDeleteTimesheetOvertimeEntryId('');
						refetchTimesheetOvertimeEntries();
						refetchWeekHoursByDay();
					}}
					onSubmit={() => {}}
					timesheetOvertimeApproverOptions={timesheetOvertimeApproverList}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={closeDeleteModal}
					confirmationMessage={OVERTIME_DELETE_CONFIRMATION_TEXT}
					onDelete={() => {
						handleDeleteTimesheetEntry(selectedDeleteTimesheetOvertimeEntryId);
					}}
				/>
				<WarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
					}}
					warningMessage={`No data can be found`}
				/>
				<ConfirmationModal
					open={isConfirmationModalShown}
					onClose={() => {
						setIsConfirmationModalShown(false);
					}}
					confirmationMessage={OVERTIME_SUBMISSION_CONFIRMATION_TEXT}
					onSubmit={() => {
						handleSubmitTimesheet();
					}}
				/>
			</Box>
		</>
	);
}

export default TimesheetOvertime;
