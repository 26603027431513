import React from 'react';

import { Box } from '@material-ui/core';
import { drawerFilterStyle } from '../../Layout/styles';
import { MAX_FILTER_OPTIONS_SHOWN } from '../../config/constant';
import FilterInput from '../../SharedComponents/FilterInput.component';
import { PRIORITY_OPTIONS } from '../constant';

export const ProjectTaskFilterFormBody = () => {
	const drawerFilterStyles = drawerFilterStyle();
	return (
		<Box className={drawerFilterStyles.container}>
			<FilterInput
				label={'Priority'}
				sourceName='priority'
				onExpansionToggle={() => {}}
				choices={PRIORITY_OPTIONS}
				onSearch={() => {}}
				total={MAX_FILTER_OPTIONS_SHOWN}
			/>
		</Box>
	);
};

export default ProjectTaskFilterFormBody;
