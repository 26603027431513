import React, { useContext, useEffect, useState } from 'react';
import {
	TextInput,
	SelectInput,
	useNotify,
	useRefresh,
	ReferenceInput,
	AutocompleteInput,
	useQuery,
	BooleanInput,
	required,
	number,
	maxLength,
	minLength,
} from 'react-admin';
import { Form, Field } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import { useMutation as useApolloMutation } from '@apollo/client';
import {
	Button,
	Box,
	Typography,
	makeStyles,
	InputAdornment,
	CircularProgress,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
	modalFormTheme,
	currencySelectBox,
} from '../../Layout/Theme.component';
import {
	modalFormStyle,
	currencyInputStyle,
	DialogActions,
} from '../../Layout/styles';
import {
	UPSERT_PROJECT,
	GET_PROJECT_KANBANS,
	GET_PROJECT_TASK_CARDS_AGGREGATE,
	CREATE_PROJECT_LABEL,
	CREATE_PROJECT_CATEGORY,
	GET_PROJECT_CUSTOM_FIELDS,
} from './gqlQueries';
import AddCompany from '../Opportunities/AddCompany.component';
import { UserProfileContext } from '../../App';
import { Project, ProjectStatus } from './ResourcePlanner.model';
import { GET_ORGANISATION_SUPPORTED_CURRENCIES } from '../../SharedComponents/gqlQueries';
import { OrganizationSupportedCurrencies } from '../../SharedComponents/model';
import { useQuery as useApolloQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { head, orderBy, uniqBy } from 'lodash';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import {
	PROJECT_LABEL_CREATED_NOTIFICATION,
	PROJECT_CATEGORY_CREATED_NOTIFICATION,
} from './constant';
import {
	AutocompleteSearch,
	DropdownOption,
} from '../../SharedComponents/Autocompletesearch.component';
import { useGetActiveUsersByLimitQuery } from '../../generated/graphql';
import { PROJECT_TYPE_OPTIONS } from '../constant';
import { useHistory } from 'react-router-dom';
import ProjectDelayForm from './ProjectDelayForm.component';
import { regexNameValidation } from '../Settings/constant';
import ProjectRiskChangeForm from '../Projects/ProjectRiskChangeModal.component';
import { riskStatusOptions } from '../Projects/constant';
import CustomField from '../../SharedComponents/CustomField.component';

export const DialogContent = withStyles(() => ({
	root: {
		height: '400px',
		padding: '0px 14px 0px 0px',
	},
}))(MuiDialogContent);

const useStyles = makeStyles({
	detailContainer: {
		borderRadius: '4px',
		padding: '20px 0px 0px 20px',
	},
	error: {
		color: 'red',
		fontSize: '10px',
	},
	detailsContainer: {
		width: '442px',
		minHeight: '150px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '3px',
		marginLeft: '6px',
		height: '5px',
	},
	projectLabelErrorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-15px',
		marginLeft: '6px',
		height: '5px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
	},
	icon: {
		width: '12px',
		marginTop: '-2px',
		height: '12px',
	},
	ownerErrorText: {
		marginBottom: '5px',
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		height: '5px',
	},
	statusErrorText: {
		marginBottom: '5px',
		fontFamily: 'Manrope-regular',
		fontSize: '10px !important',
		color: '#EA4335',
		height: '5px',
		marginLeft: '4px',
		marginTop: '-8px',
	},
});

interface Choice {
	first_name: string;
	last_name: string;
	full_name: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
	projectId?: string;
	project: Project;
	isBillableDisabled?: boolean;
}
interface Template {
	id: string;
	name: string;
	is_default: boolean;
}
const mapToName = (status: { name: string }) => `${status?.name || ''}`;
const projectLabelRenderer = (projectLabel: any) =>
	`${projectLabel?.name || ''}`;
const projectCategoryRenderer = (projectCategory: any) =>
	`${projectCategory?.name || ''}`;

const ProjectForm = (props: Props) => {
	const {
		open,
		onClose,
		project,
		refetch,
		projectId,
		isBillableDisabled = false,
	} = props;
	const {
		orgId: organizationId,
		orgBaseCurrency,
		dateFormat,
		id: userId,
	} = useContext<any>(UserProfileContext);
	const [currencyId, setCurrencyId] = useState(orgBaseCurrency?.id);
	const [projectStatusId, setProjectStatusId] = useState<string | null>('');
	const [projectStatusChoices, setProjectStatusChoices] = useState([]);
	const [currencyList, setCurrencyList] = useState<any>([]);
	const [kanbanTemplate, setKanbanTemplate] = useState(null);
	const [companyName, setCompanyName] = useState('');
	const [projectNameUniqueError, setProjectNameUniqueError] = useState(false);
	const [isAddCompanyFormShown, setIsAddCompanyFormShown] = useState(false);
	const [projectName, setProjectName] = useState('');
	const [prefixUniqueError, setPrefixUniqueError] = useState(false);
	const [prefixes, setPrefixes] = useState('');
	const [projectFormInitialValues, setProjectFormInitialValues] =
		useState<Project>({});
	const notify = useNotify();
	const refresh = useRefresh();
	const classes = modalFormStyle();
	const currencyStyle = currencyInputStyle();
	const inlineStyle = useStyles();
	const history = useHistory();
	const [currentProjectFormValues, setCurrentProjectFormValues] = useState<any>(
		{}
	);
	const [upsertProject, { loading: isProjectLoading }] =
		useApolloMutation(UPSERT_PROJECT);
	const [startDateError, setStartDateError] = useState(false);
	const [isProjectLabelsLoading, setIsProjectLabelsLoading] = useState(false);
	const [isProjectDelayFormShown, setIsProjectDelayFormShown] = useState(false);
	const [isProjectCategoriesLoading, setIsProjectCategoriesLoading] =
		useState(false);
	const [
		isProjectLabelUniqueViolationError,
		setIsProjectLabelUniqueViolationError,
	] = useState(false);
	const [
		isProjectCategoryUniqueViolationError,
		setIsProjectCategoryUniqueViolationError,
	] = useState(false);
	const [ownerId, setOwnerId] = useState<string | null>(null);
	const [presalesOwnerId, setPresalesOwnerId] = useState<string | null>(null);
	const [projectEndDate, setProjectEndDate] = useState<string | null>(null);
	const [newProjectEndDate, setNewProjectEndDate] = useState<string | null>(
		null
	);
	const [riskStatus, setRiskStatus] = useState<string | null>(null);
	const [startDateFieldError, setStartDateFieldError] = useState(false);
	const [startDateFieldErrorText, setStartDateFieldErrorText] = useState('');
	const [endDateFieldError, setEndDateFieldError] = useState(false);
	const [endDateFieldErrorText, setEndDateFieldErrorText] = useState('');
	const [currentFormValues, setCurrentFormValues] = useState<Project>({});
	const [ownerFieldError, setOwnerFieldError] = useState(false);
	const [statusFieldError, setStatusFieldError] = useState(false);
	const [startDate, setStartDate] = useState<string | null>(null);
	const [optionValue, setOptionValue] = useState();
	const [isProjectRiskChangeModalShown, setIsProjectRiskChangeModalShown] =
		useState(false);
	const [projectIdForRiskStatus, setProjectIdForRiskStatus] = useState<
		string | null
	>(null);
	const [selectedRiskStatus, setSelectedRiskStatus] = useState<string | null>(
		null
	);
	const { data: organisationSupportedCurrencies } = useApolloQuery<any>(
		GET_ORGANISATION_SUPPORTED_CURRENCIES,
		{
			variables: {
				organizationId: organizationId,
			},
		}
	);

	const { data: projectCustomField } = useApolloQuery(
		GET_PROJECT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	const [createProjectLabel] = useApolloMutation(CREATE_PROJECT_LABEL);
	const [createProjectCategory] = useApolloMutation(CREATE_PROJECT_CATEGORY);
	const { data: activeUsers } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: projectKanbanTemplates } = useApolloQuery<any>(
		GET_PROJECT_KANBANS,
		{
			fetchPolicy: 'network-only',
		}
	);

	const { data: taskCardsAggregrate } = useApolloQuery<any>(
		GET_PROJECT_TASK_CARDS_AGGREGATE,
		{
			variables: {
				project_id: project?.id || null,
			},
			fetchPolicy: 'network-only',
		}
	);

	useEffect(() => {
		if (
			!organisationSupportedCurrencies ||
			!organisationSupportedCurrencies?.org_supported_currencies
		) {
			return;
		}
		const organizationCurrencyChoices =
			organisationSupportedCurrencies.org_supported_currencies.map(
				(currency: OrganizationSupportedCurrencies) => {
					if (currency?.currency?.id === currency?.organization?.currency_id) {
						setCurrencyId(currency?.currency.id);
					}
					return {
						id: currency?.currency.id,
						name: currency?.currency?.currency_type,
					};
				}
			);

		setCurrencyList(
			uniqBy([...organizationCurrencyChoices, orgBaseCurrency], 'id')
		);
	}, [orgBaseCurrency, organisationSupportedCurrencies]);

	const { data: projectStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'project_status',
		payload: {},
	});

	useEffect(() => {
		if (project?.id) {
			setPrefixes(project?.prefix || '');
			setOwnerId(project?.project_owner_id || null);
			setPresalesOwnerId(project?.pre_sales_owner_id || null);
			setProjectEndDate(project?.end_date || null);
			setProjectStatusId(project?.project_status_id || '');
			setStartDate(project?.start_date || null);
			setRiskStatus(project?.risk_status || null);
			setSelectedRiskStatus(project?.risk_status || null);
			return;
		}
	}, [project]);

	useEffect(() => {
		const defaultTemplate: Template[] =
			projectKanbanTemplates?.kanban_board?.filter(
				(template: Template) => template?.is_default === true
			);
		setProjectFormInitialValues({
			currency_id: currencyId,
			is_billable: true,
			is_strict: true,
			risk_status: 'Green',
			project_execution_type: 'Internal',
			kanban_id: head(defaultTemplate)?.id,
			project_owner_id: userId,
		});
		setCurrentFormValues({
			currency_id: currencyId,
			is_billable: true,
			is_strict: true,
			risk_status: 'Green',
			project_execution_type: 'Internal',
			kanban_id: head(defaultTemplate)?.id,
			project_owner_id: userId,
		});
	}, [currencyId, projectKanbanTemplates, userId]);

	useEffect(() => {
		if (!projectStatus || !(projectStatus?.length > 0)) {
			return;
		}
		const projectStatusList = projectStatus.map((value: ProjectStatus) => {
			return { id: value?.id, name: value?.label };
		});
		const sortedProjectStatusList: any = orderBy(
			projectStatusList,
			[(project) => project.name?.toUpperCase()],
			['asc']
		);
		setProjectStatusChoices(sortedProjectStatusList);
	}, [projectStatus]);

	useEffect(() => {
		const kanbanTemplates = projectKanbanTemplates?.kanban_board.map(
			(value: any) => {
				return {
					id: value?.id,
					name: value?.name,
					is_default: value?.is_default,
				};
			}
		);
		setKanbanTemplate(kanbanTemplates);
	}, [projectKanbanTemplates]);

	const handleCompany = (company: string) => {
		setCompanyName(company);
		setIsAddCompanyFormShown(true);
	};

	const handleProjectLabelCreate = (projectLabel: string) => {
		if (!projectLabel) {
			return;
		}
		setIsProjectLabelsLoading(true);
		const projectTagId = createProjectLabel({
			variables: {
				projectLabel: projectLabel,
			},
		})
			.then((res) => {
				setIsProjectLabelsLoading(false);
				notify(PROJECT_LABEL_CREATED_NOTIFICATION);
				return res?.data?.insert_project_tags_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectLabelsLoading(false);
				if (err.message.includes('project_tags_name_org_id_key')) {
					setIsProjectLabelUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectTagId;
	};

	const handleProjectCategoryCreate = (projectCategory: string) => {
		if (!projectCategory) {
			return;
		}
		setIsProjectCategoriesLoading(true);
		const projectCategoryId = createProjectCategory({
			variables: {
				projectCategory: projectCategory,
				type: 'project',
			},
		})
			.then((res) => {
				setIsProjectCategoriesLoading(false);
				notify(PROJECT_CATEGORY_CREATED_NOTIFICATION);
				return res?.data?.insert_project_category_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectCategoriesLoading(false);
				if (err.message.includes('project_category_name_org_id_type_key')) {
					setIsProjectCategoryUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectCategoryId;
	};

	const HandleCompanyClose = () => {
		setIsAddCompanyFormShown(false);
	};

	const onSave = (
		formValues: Project,
		checkEndDate: boolean,
		isRiskStatusChanged: boolean = false
	) => {
		setCurrentProjectFormValues(formValues);
		if (dayjs(formValues?.start_date).isAfter(formValues?.end_date)) {
			setStartDateError(true);
			return;
		}
		if (
			projectEndDate !== null &&
			checkEndDate &&
			projectEndDate !== formValues?.end_date
		) {
			setIsProjectDelayFormShown(true);
			return;
		}

		if (isRiskStatusChanged) {
			setIsProjectRiskChangeModalShown(true);
			return;
		}
		const {
			isCardsPresent,
			prefix,
			Budget,
			project_owner_id,
			...ProjectValues
		} = formValues;
		upsertProject({
			variables: {
				project: {
					...ProjectValues,
					end_date: formValues?.end_date ? formValues.end_date : null,
					start_date: formValues?.start_date ? formValues.start_date : null,
					prefix: String(formValues.prefix).toUpperCase().trim(),
					category_id: formValues?.category_id || null,
					pre_sales_owner_id: presalesOwnerId || null,
					project_owner_id: ownerId || null,
					name: String(formValues?.name).trim(),
					project_status_id: formValues?.project_status_id
						? formValues?.project_status_id
						: null,
					custom_field_1: formValues?.custom_field_1?.trim() || null,
					custom_field_2: formValues?.custom_field_2?.trim() || null,
					custom_field_3: formValues?.custom_field_3?.trim() || null,
					custom_field_4: formValues?.custom_field_4?.trim() || null,
					custom_field_5: formValues?.custom_field_5?.trim() || null,
					custom_field_6: formValues?.custom_field_6?.trim() || null,
					custom_field_7: formValues?.custom_field_7?.trim() || null,
					custom_field_8: formValues?.custom_field_8?.trim() || null,
					custom_field_9: formValues?.custom_field_9?.trim() || null,
				},
			},
		})
			.then((data) => {
				if (project?.id) {
					refetch();
					notify('Projects Updated Successfully');
					refresh();
					setPrefixes('');
					closeProjectForm();
					setPrefixUniqueError(false);
				} else {
					notify('Projects Created Successfully');
					refetch();
					refresh();
					setPrefixes('');
					closeProjectForm();
					setPrefixUniqueError(false);
					history.push({
						pathname: `project/detail/${data?.data?.insert_project_one?.id}`,
					});
				}
			})
			.catch((error) => {
				if (error.message.includes('UQ_fdf317ed422e8e036a1295d6447')) {
					setProjectNameUniqueError(true);

					return;
				}
				if (error?.message.includes('project_prefix_key')) {
					setPrefixUniqueError(true);
					return;
				}
				closeProjectForm();
				notify(`Create failed: ${error.message}`, 'warning');
			});
	};

	const handleClose = () => {
		closeProjectForm();
	};

	const validateProjectFormValues = async () => {
		const projectFormErrors = {} as Project;
		// if (prefixUniqueError) {
		// 	projectFormErrors.prefix = 'prefix already exist!';
		// }
		if (projectNameUniqueError) {
			projectFormErrors.name = 'Project name already exists!';
		}
		// if (isProjectLabelUniqueViolationError) {
		// 	projectFormErrors.tag_id = 'Project label already exists';
		// }
		return projectFormErrors;
	};

	const closeProjectForm = () => {
		setProjectFormInitialValues({});
		setCurrentFormValues({});
		setPrefixes(project?.prefix || '');
		setPrefixUniqueError(false);
		setProjectNameUniqueError(false);
		setStartDateError(false);
		setProjectName('');
		onClose();
		setCurrentProjectFormValues({});
		setIsProjectDelayFormShown(false);
		setProjectEndDate(null);
		setStartDateFieldError(false);
		setEndDateFieldError(false);
		setOwnerFieldError(false);
		setStatusFieldError(false);
		setStartDate(null);
		setPresalesOwnerId(project?.pre_sales_owner_id || null);
		setOwnerId(project?.project_owner_id || null);
		setProjectStatusId(project?.project_status_id || null);
	};
	const getOwnerValue = (userId: string) => {
		if (!userId) {
			return null;
		}
		const response = activeUsers?.user?.find((value) => value?.id === userId);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return 'Minimum one alphabet character is required';
		}
		return undefined;
	};

	const getProjectStatus = (status: any) => {
		if (!status) {
			return null;
		}
		const response = projectStatus?.find((value: any) => value?.id === status);
		return response ? { id: response?.id, name: response?.label } : null;
	};

	useEffect(() => {
		if (
			!isAddCompanyFormShown &&
			!currentFormValues?.company_id &&
			Object?.keys(currentFormValues)?.length > 0
		) {
			setProjectFormInitialValues({
				...currentFormValues,
				name: projectName,
				project_owner_id: ownerId,
			});
		}
	}, [currentFormValues, isAddCompanyFormShown, ownerId, projectName]);

	const validatePrefix = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		return undefined;
	};

	const handleChange = (event: any) => {
		setOptionValue(event);
	};

	const formContent = () => (
		<Form
			onSubmit={(values: Project) =>
				onSave(
					values,
					true,
					!!project?.risk_status &&
						!!selectedRiskStatus &&
						project?.risk_status !== selectedRiskStatus
				)
			}
			initialValues={
				project?.id
					? { ...project, risk_status: riskStatus }
					: projectFormInitialValues
			}
			validate={validateProjectFormValues}
			mutators={{
				setPrefix: (args, state, utils) => {
					utils.changeValue(state, 'prefix', () => {
						if (!projectName) {
							setPrefixes('');
							return '';
						}
						if (projectName && prefixes?.length > 0) {
							const prefix = prefixes;
							setPrefixes(prefix);
							return prefix;
						}
						if (prefixes?.length === 0 && projectName) {
							const prefix = String(projectName)
								.trim()
								.trim()
								.slice(0, 3)
								.toUpperCase();
							setCurrentFormValues({
								...currentFormValues,
								prefix: prefix,
							});
							return prefix;
						}
					});
				},
				setProjectLabel: (args, state, utils) => {
					utils.changeValue(state, 'tag_id', () => {
						setCurrentFormValues({
							...currentFormValues,
							tag_id: args[0],
						});
						return args[0];
					});
				},
				setProjectCategory: (args, state, utils) => {
					utils.changeValue(state, 'category_id', () => {
						setCurrentFormValues({
							...currentFormValues,
							category_id: args[0],
						});
						return args[0];
					});
				},
			}}
		>
			{({ handleSubmit, invalid, pristine, form }) => (
				<form onSubmit={handleSubmit}>
					<Box>
						<DialogContent>
							<Box className={classes.multipleInputContainer}>
								<Box width='70%'>
									<Typography className={classes.label}>
										Project Name *
									</Typography>
									<TextInput
										autoComplete='off'
										source='name'
										fullWidth={true}
										label={''}
										validate={validateName}
										onChange={(event: any) => {
											if (!event?.target?.value) {
												setProjectName('');
												form.mutators.setPrefix();
												return;
											}
											setPrefixUniqueError(false);
											setProjectName(String(event.target.value).trim());
											setProjectNameUniqueError(false);
											form.mutators.setPrefix();
										}}
									/>
								</Box>
								<Box width='25%'>
									<Typography className={classes.label}>Prefix *</Typography>
									<TextInput
										inputProps={{ style: { textTransform: 'uppercase' } }}
										autoComplete='off'
										source='prefix'
										fullWidth={true}
										label={''}
										onChange={(event) => {
											if (!event) {
												setPrefixes('');
											}
											setCurrentFormValues({
												...currentFormValues,
												prefix: event.target.value,
											});

											setPrefixUniqueError(false);
											setPrefixes(String(event?.target?.value).trim());
										}}
										validate={[
											validatePrefix,
											minLength(3, 'Must be 3 characters'),
											maxLength(6, 'Must be 6 characters'),
										]}
									/>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{prefixUniqueError && (
										<p className={inlineStyle.errorText}>
											prefix already exist!
										</p>
									)}
								</Box>
							</Box>
							<Typography className={classes.label}>Customer Name *</Typography>
							<ReferenceInput
								source='company_id'
								label={''}
								reference='crm_company'
								onChange={(event) => {
									setCurrentFormValues({
										...currentFormValues,
										company_id: event,
									});
								}}
								sort={{ field: 'name', order: 'ASC' }}
								filterToQuery={(searchText) => ({
									name: searchText,
								})}
								filter={{
									name: true,
								}}
								validate={required()}
							>
								<AutocompleteInput
									resettable={true}
									optionText={mapToName}
									shouldRenderSuggestions={(name: string) =>
										name.trim().length > 0
									}
									onCreate={(event: any) => handleCompany(event)}
								/>
							</ReferenceInput>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Type *
									</Typography>
									<SelectInput
										fullWidth
										source='project_type'
										label={''}
										choices={PROJECT_TYPE_OPTIONS}
										validate={required()}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												project_type: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Execution Type *
									</Typography>
									<SelectInput
										fullWidth
										source='project_execution_type'
										label={''}
										choices={[
											{ id: 'External', name: 'External' },
											{ id: 'Internal', name: 'Internal' },
											{ id: 'Other', name: 'Other' },
										]}
										validate={required()}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												project_execution_type: event.target.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box
								display='flex'
								flexWrap='wrap'
								justifyContent='space-between'
							>
								<Box
									display='flex'
									width='38%'
									justifyContent='space-between'
									padding='0px 0px 0px 8px'
								>
									<Box>
										<Typography className={classes.label}>
											Is Billable
										</Typography>
										<BooleanInput
											source='is_billable'
											label=''
											disabled={isBillableDisabled}
											choices={[
												{ id: true, name: 'True' },
												{ id: false, name: 'False' },
											]}
											onChange={(event) => {
												setCurrentFormValues({
													...currentFormValues,
													is_billable: event?.target?.value,
												});
											}}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>Is Strict</Typography>
										<BooleanInput
											source='is_strict'
											label=''
											choices={[
												{ id: true, name: 'True' },
												{ id: false, name: 'False' },
											]}
											onChange={(event) => {
												setCurrentFormValues({
													...currentFormValues,
													is_strict: event?.target?.value,
												});
											}}
										/>
									</Box>
								</Box>
								<Box width='49%'>
									<Typography className={classes.label}>Country</Typography>
									<ReferenceInput
										source='geo_id'
										reference='geo_list'
										label={''}
										sort={{ field: 'name', order: 'ASC' }}
										onChange={(event) => {
											if (!event) {
												return;
											}
											setCurrentFormValues({
												...currentFormValues,
												geo_id: event?.target?.value,
											});
										}}
									>
										<SelectInput resettable={true} optionText='name' />
									</ReferenceInput>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Owner *
									</Typography>
									<AutocompleteSearch
										placeholder={'Search Owner'}
										option={
											activeUsers && activeUsers?.user
												? activeUsers?.user?.map((value) => {
														return {
															id: value?.id,
															name: value?.name,
														};
												  })
												: []
										}
										onChange={(value) => {
											if (!value) {
												setOwnerId(null);
												setOwnerFieldError(true);
												return;
											}
											setOwnerId(value);
											setOwnerFieldError(false);
										}}
										name={'project_owner_id'}
										validates={true}
										value={ownerId ? getOwnerValue(ownerId) : null}
									/>
									{ownerFieldError && (
										<p className={inlineStyle.ownerErrorText}>Required!</p>
									)}
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Pre-sales Owner
									</Typography>
									<AutocompleteSearch
										placeholder={'Search Owner'}
										option={
											activeUsers && activeUsers?.user
												? activeUsers?.user?.map((value) => {
														return {
															id: value?.id,
															name: value?.name,
														};
												  })
												: []
										}
										onChange={(event) => {
											setPresalesOwnerId(event);
											setCurrentFormValues({
												...currentFormValues,
												pre_sales_owner_id: event,
											});
										}}
										name={'pre_sales_owner_id'}
										validates={false}
										value={getOwnerValue(presalesOwnerId || '')}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Status*
									</Typography>
									<AutocompleteSearch
										placeholder={'Search Project Status'}
										option={projectStatusChoices || []}
										onChange={(value) => {
											if (!value) {
												setProjectStatusId('');
												setStatusFieldError(true);
												return;
											}
											setStatusFieldError(false);
											setProjectStatusId(value);
										}}
										name={'project_status_id'}
										validates={true}
										value={
											projectStatusId ? getProjectStatus(projectStatusId) : null
										}
									/>
									{statusFieldError && (
										<p className={inlineStyle.statusErrorText}>Required!</p>
									)}
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>Risk Status</Typography>
									<SelectInput
										source='risk_status'
										fullWidth
										label=''
										choices={riskStatusOptions}
										onChange={(event) => {
											if (project?.id && event) {
												setProjectIdForRiskStatus(project?.id);
												setSelectedRiskStatus(event?.target?.value || null);
											}
											setCurrentFormValues({
												...currentFormValues,
												risk_status: event.target.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Kanban template
									</Typography>
									<SelectInput
										disabled={
											project?.id &&
											taskCardsAggregrate?.project_kanban_card_aggregate
												?.aggregate?.count > 0
												? true
												: false
										}
										source='kanban_id'
										fullWidth
										label=''
										choices={kanbanTemplate || []}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												kanban_id: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Label
									</Typography>
									<ReferenceInput
										source='tag_id'
										label={''}
										reference='project_tags'
										sort={{ field: 'name', order: 'ASC' }}
										filterToQuery={(searchText) => ({
											name: searchText,
										})}
										filter={{
											name: true,
										}}
										onFocus={() => {
											setIsProjectLabelUniqueViolationError(false);
										}}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												tag_id: event,
											});
										}}
									>
										{/* TODO Need to refactor like passing loader to autocomplete directly */}
										{isProjectLabelsLoading ? (
											<TextInput
												source=''
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CircularProgress
																style={{
																	height: '20px',
																	width: '20px',
																	color: '#4285F4',
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										) : (
											<AutocompleteInput
												resettable={true}
												optionText={projectLabelRenderer}
												shouldRenderSuggestions={(name: string) =>
													name.trim().length > 0
												}
												onCreate={async (event: any) => {
													const projectTagId = await handleProjectLabelCreate(
														event
													);
													if (!isProjectLabelUniqueViolationError) {
														setTimeout(
															() => form.mutators.setProjectLabel(projectTagId),
															100
														);
													}
												}}
											/>
										)}
									</ReferenceInput>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{isProjectLabelUniqueViolationError && (
										<p className={inlineStyle.projectLabelErrorText}>
											Project label already exist!
										</p>
									)}
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Project Category
									</Typography>
									<ReferenceInput
										source='category_id'
										label={''}
										reference='project_category'
										sort={{ field: 'name', order: 'ASC' }}
										filterToQuery={(searchText) => ({
											name: searchText,
											type: 'project',
										})}
										filter={{
											name: true,
										}}
										onFocus={() => {
											setIsProjectCategoryUniqueViolationError(false);
										}}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												category_id: event,
											});
										}}
									>
										{isProjectCategoriesLoading ? (
											<TextInput
												source=''
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<CircularProgress
																style={{
																	height: '20px',
																	width: '20px',
																	color: '#4285F4',
																}}
															/>
														</InputAdornment>
													),
												}}
											/>
										) : (
											<AutocompleteInput
												resettable={true}
												optionText={projectCategoryRenderer}
												shouldRenderSuggestions={(name: string) =>
													name.trim().length > 0
												}
												onCreate={async (event: any) => {
													const projectCategoryId =
														await handleProjectCategoryCreate(event);
													if (!isProjectCategoryUniqueViolationError) {
														setTimeout(
															() =>
																form.mutators.setProjectCategory(
																	projectCategoryId
																),
															100
														);
													}
												}}
											/>
										)}
									</ReferenceInput>
									{/* TODO Remove this Temporary fix and fix it proper way */}
									{isProjectCategoryUniqueViolationError && (
										<p className={inlineStyle.projectLabelErrorText}>
											Project Category already exist!
										</p>
									)}
								</Box>
								<Box className={classes.multipleInput}></Box>
							</Box>

							<Box
								className={classes.multipleInputContainer}
								marginBottom='5px'
							>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Start Date *
									</Typography>
									<Field
										name='start_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true) {
												return undefined;
											}
											if (value === 'Invalid Date') {
												setStartDateFieldError(true);
												setStartDateFieldErrorText('Invalid Date');
												return 'Invalid Date';
											}
											return 'Invalid Date';
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													initialValue={props.input.value || null}
													key={startDate}
													onChange={(value: any) => {
														if (!value) {
															setStartDateFieldError(true);
															setStartDateFieldErrorText('Required');
															return;
														}
														setStartDateError(false);
														setStartDateFieldError(false);
														setStartDateFieldErrorText('');
														setCurrentFormValues({
															...currentFormValues,
															start_date: value,
														});
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{startDateFieldError && (
										<p className={inlineStyle.errorText}>
											{startDateFieldErrorText}
										</p>
									)}
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>End Date *</Typography>
									<Field
										name='end_date'
										validate={(value: any) => {
											if (!value) {
												return 'Required';
											}
											if (dayjs(value).isValid() === true) {
												return undefined;
											}
											if (value === 'Invalid Date') {
												setEndDateFieldError(true);
												setEndDateFieldErrorText('Invalid Date');
												return 'Invalid Date';
											}
											return 'Invalid Date';
										}}
									>
										{(props: any) => (
											<div>
												<CustomDateInput
													name={props.input.name}
													key={projectEndDate}
													initialValue={props.input.value || null}
													onChange={(value: any) => {
														if (!value) {
															setEndDateFieldError(true);
															setEndDateFieldErrorText('Required');
															return;
														}
														setStartDateError(false);
														setEndDateFieldError(false);
														setEndDateFieldErrorText('');
														setNewProjectEndDate(value);
														setCurrentFormValues({
															...currentFormValues,
															end_date: value,
														});
														props.input.onChange(value);
													}}
													dateFormat={dateFormat}
												/>
											</div>
										)}
									</Field>
									{startDateError && (
										<p className={inlineStyle.errorText}>
											Start date must not be greater than end date!
										</p>
									)}
									{endDateFieldError && (
										<p className={inlineStyle.errorText}>
											{endDateFieldErrorText}
										</p>
									)}
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>
										Estimated hours
									</Typography>
									<TextInput
										validate={number()}
										source='effort_estimate'
										label={''}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												effort_estimate: event.target.value,
											});
										}}
									/>
								</Box>
								<Box className={classes.multipleInput}>
									<Typography className={classes.label}>Currency</Typography>
									<SelectInput
										choices={currencyList}
										source='currency_id'
										optionText='name'
										label={''}
										onChange={(event) => {
											setCurrentFormValues({
												...currentFormValues,
												currency_id: event.target.value,
											});
										}}
									/>
								</Box>
							</Box>
							<Box className={classes.multipleInputContainer}>
								<Box className={classes.multipleInput}>
									<Box className={classes.label}>
										Contract Value
										<Tooltip title='Actual contract value' placement='right'>
											<IconButton className={inlineStyle.iconContainer}>
												<InfoIcon className={inlineStyle.icon} />
											</IconButton>
										</Tooltip>
									</Box>
									<Box display='flex'>
										<Box className={currencyStyle.selectCurrency}>
											<ThemeProvider theme={currencySelectBox}>
												<SelectInput
													choices={currencyList}
													source='currency_id'
													optionText='name'
													label={''}
													disabled={true}
												/>
											</ThemeProvider>
										</Box>
										<Box marginTop='7px'>
											<TextInput
												validate={number()}
												source='revenue'
												label={''}
												onChange={(event) => {
													setCurrentFormValues({
														...currentFormValues,
														revenue: event.target.value,
													});
												}}
											/>
										</Box>
									</Box>
								</Box>
								<Box className={classes.multipleInput}>
									<Box className={classes.label}>
										Budget
										<Tooltip
											title='Budget allocated to the project'
											placement='right'
										>
											<IconButton className={inlineStyle.iconContainer}>
												<InfoIcon className={inlineStyle.icon} />
											</IconButton>
										</Tooltip>
									</Box>
									<Box display='flex'>
										<Box className={currencyStyle.selectCurrency}>
											<ThemeProvider theme={currencySelectBox}>
												<SelectInput
													choices={currencyList}
													source='currency_id'
													optionText='name'
													label={''}
													disabled={true}
												/>
											</ThemeProvider>
										</Box>
										<Box marginTop='7px'>
											<TextInput
												validate={number()}
												source='total_cost'
												label={''}
												onChange={(event) => {
													setCurrentFormValues({
														...currentFormValues,
														total_cost: event.target.value,
													});
												}}
											/>
										</Box>
									</Box>
								</Box>
							</Box>

							{projectCustomField?.custom_field?.length > 0 &&
								projectCustomField?.custom_field?.map(
									(
										customField: {
											index: number;
											label: string | undefined;
											options: DropdownOption[] | undefined;
											type: string;
										},
										index: React.Key | null | undefined
									) => (
										<Box key={index}>
											<CustomField
												name={`custom_field_${customField.index}`}
												label={customField.label}
												option={customField.options}
												dateFormat={dateFormat}
												fieldType={customField.type}
												onChange={() => handleChange}
												value={optionValue!}
											/>
										</Box>
									)
								)}

							<Typography className={classes.label}>
								Project Overview
							</Typography>
							<TextInput
								source='description'
								label=''
								multiline={true}
								onChange={(event) => {
									setCurrentFormValues({
										...currentFormValues,
										description: event.target.value,
									});
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Box
								className={classes.buttonContainer}
								marginRight='22px'
								marginTop='20px'
							>
								<Button onClick={handleClose} className={classes.cancelButton}>
									Cancel
								</Button>
								<Button
									className={
										invalid ||
										pristine ||
										isProjectLoading ||
										startDateFieldError ||
										endDateFieldError
											? classes.disabledButton
											: classes.saveButton
									}
									disabled={
										invalid ||
										pristine ||
										isProjectLoading ||
										startDateFieldError ||
										endDateFieldError
									}
									type='submit'
								>
									Save
								</Button>
							</Box>
						</DialogActions>
					</Box>
				</form>
			)}
		</Form>
	);

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeProjectForm}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box
					className={
						!isAddCompanyFormShown
							? inlineStyle.detailContainer
							: inlineStyle.detailsContainer
					}
				>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							{!isAddCompanyFormShown
								? projectId
									? 'Edit Project'
									: 'Add Project'
								: 'Add Company'}
						</Typography>
						<Box marginRight={isAddCompanyFormShown ? '0' : '22px'}>
							<CloseIcon
								className={classes.closeIcon}
								onClick={
									isAddCompanyFormShown ? HandleCompanyClose : handleClose
								}
							/>
						</Box>
					</Box>
					{!isAddCompanyFormShown ? (
						formContent()
					) : (
						<AddCompany
							onClose={HandleCompanyClose}
							companyName={companyName}
							setCompanyId={(id) => {
								setProjectFormInitialValues({
									...projectFormInitialValues,
									name: project?.name || projectName,
									company_id: id,
								});
								setCurrentFormValues({
									...currentFormValues,
									name: project?.name || projectName,
									company_id: id,
								});
							}}
						/>
					)}
				</Box>
			</Dialog>
			<ProjectDelayForm
				open={isProjectDelayFormShown}
				onClose={() => {
					setIsProjectDelayFormShown(false);
				}}
				projectId={project?.id}
				onSuccess={() => {
					setProjectEndDate(null);
					setIsProjectDelayFormShown(false);
					onSave(
						currentProjectFormValues,
						false,
						!!project?.risk_status &&
							!!selectedRiskStatus &&
							project?.risk_status !== selectedRiskStatus
					);
				}}
				previousProjectEndDate={projectEndDate}
				newProjectEndDate={newProjectEndDate}
			/>
			<ProjectRiskChangeForm
				open={isProjectRiskChangeModalShown}
				projectId={projectIdForRiskStatus}
				risk={selectedRiskStatus}
				onClose={() => {
					setIsProjectRiskChangeModalShown(false);
				}}
				onSuccess={() => {
					setSelectedRiskStatus(null);
					setIsProjectRiskChangeModalShown(false);
					setProjectIdForRiskStatus(null);
					onSave(currentProjectFormValues, false);
				}}
			/>
		</ThemeProvider>
	);
};

export default ProjectForm;
