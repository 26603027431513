import { makeStyles } from '@material-ui/core';

export const crmTaskListStyle = makeStyles(() => ({
	ellipsisContent: {
		width: '100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	priorityContainer: {
		fontFamily: 'Manrope-semibold',
		fontSize: '10px',
		borderRadius: '10px',
		padding: '4px 8px 4px 8px',
		width: 'fit-content',
	},
	priorityHigh: {
		backgroundColor: '#FFF3F2',
		color: '#EA4335',
	},
	priorityMedium: {
		backgroundColor: '#EBF3FF',
		color: '#4285F4',
	},
	priorityLow: {
		backgroundColor: '#E9F7EC',
		color: '#34A853',
	},
	activeCheckbox: {
		color: 'green',
	},
	checkbox: {
		color: '#888888',
	},
	strikeThrough: {
		textDecoration: 'line-through',
	},
}));
