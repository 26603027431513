import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserProfileContext } from '../../../App';
import { uploadFile } from '../../../Api/restApi.config';
import DocumentUpload from '../../../SharedComponents/DocumentUpload/DocumentUpload.component';
import {
	GET_CONSULTANT_DOC,
	ADD_CONSULTANT_DOC,
	DELETE_CONSULTANT_DOC,
	UPDATE_FILE_NAME,
} from '../gqlQueries';
import {
	useMutation as useApolloMutation,
	useQuery as useApolloQuery,
} from '@apollo/client';
import { supportedFileFormats } from '../../../Utils';

interface document {
	url: string;
	id: string;
	file_name: string;
}

const DocumentUploader = () => {
	const [docs, setDocs] = useState({});
	const [loading, setLoading] = useState(false);
	const { id: consultant_id }: { id: string } = useParams();
	const [fileUploadError, setFileUploadError] = useState('');
	const { orgId, permissions: permissionList } =
		useContext<any>(UserProfileContext);
	const [addConsultantDoc, { error: uploadError }] =
		useApolloMutation(ADD_CONSULTANT_DOC);
	const [deleteConsultantDoc] = useApolloMutation(DELETE_CONSULTANT_DOC);
	const [updateConsultantDoc] = useApolloMutation(UPDATE_FILE_NAME);
	const { refetch: refetchDocs } = useApolloQuery(GET_CONSULTANT_DOC, {
		variables: { consultant_id: consultant_id },
	});
	const { data: consultantDocs } = useApolloQuery(GET_CONSULTANT_DOC, {
		variables: { consultant_id: consultant_id },
	});
	useEffect(() => {
		const doc = consultantDocs?.consultant_doc?.map((doc: document) => {
			const docInfo = {
				id: doc.id,
				docUrl: doc.url,
				file_name: doc.file_name,
			};
			return docInfo;
		});
		setDocs(doc);
	}, [consultantDocs]);

	const FileUploadHandler = (uploadedFile: string) => {
		let filename: any = uploadedFile[1];
		if (supportedFileFormats.includes(filename.split('.').pop())) {
			setLoading(true);
			setFileUploadError('');
			let data = new FormData();
			data.append('uploadType', 'ConsultantDocs');
			data.append('file', uploadedFile[0]);
			data.append('id', orgId);
			uploadFile(data)
				.then((res) => {
					addConsultantDoc({
						variables: {
							data: {
								type: 'ConsultantDocs',
								url: res.data.url,
								consultant_id: consultant_id,
								file_name: uploadedFile[1],
							},
						},
					})
						.then(() => {
							setLoading(false);
							setFileUploadError('File uploaded successfully');
							refetchDocs();
						})
						.catch(() => {
							if (uploadError) return `Submission error! ${uploadError}`;
							setLoading(false);
							setFileUploadError('File already exist in the documents');
						});
				})
				.catch((err) => {
					setFileUploadError(
						err.response?.data?.message || 'Document Upload failed'
					);
					setLoading(false);
					refetchDocs();
				});
		} else {
			setFileUploadError(
				` '${filename
					.split('.')
					.pop()}'  file format is not supported in documents. `
			);
			setLoading(false);
		}
	};

	const onDocDelete = (id: string) => {
		setFileUploadError('');
		setLoading(true);
		deleteConsultantDoc({
			variables: {
				id,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError('File deleted successfully');
			})
			.catch(() => {
				setLoading(false);
				setFileUploadError('File deletion failed');
			});
	};

	const onFileRename = (newFieldName: string, fileId: string) => {
		setLoading(true);
		setFileUploadError('');
		updateConsultantDoc({
			variables: {
				id: fileId,
				file_name: newFieldName,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError(`File name updated successfully`);
			})
			.catch(() => {
				setLoading(false);
				setFileUploadError(
					`The file name ${newFieldName} already exist in document`
				);
			});
	};

	return (
		<DocumentUpload
			docs={docs}
			FileUploadHandler={FileUploadHandler}
			onDocDelete={onDocDelete}
			loading={loading}
			refetchDocs={refetchDocs}
			errorMessage={fileUploadError}
			onFileRename={onFileRename}
			onRenameError={false}
			pageHeight='500px'
			permission={permissionList?.contractor?.update_permissions ? true : false}
		/>
	);
};

export default DocumentUploader;
