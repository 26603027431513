import React, { useState, useEffect, useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	IconButton,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	TablePagination,
} from '@material-ui/core';
import { SelectInput } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import { Form } from 'react-final-form';
import { head, isEmpty, orderBy } from 'lodash';
import {
	useGetAttendanceRegularizationHistoryByUserIdQuery,
	useGetAttendanceStatusQuery,
} from '../../generated/graphql';
import { modalFormTheme } from '../../Layout/Theme.component';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import { Style } from './Style';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import no_data_found from '../../assets/no_data_found.png';
import { UserProfileContext } from '../../App';
import { DropdownOption } from '../../SharedComponents/Autocompletesearch.component';
import AttendanceRequestForm from './AttendanceRequestForm.component';

export const inlineStyle = makeStyles(() => ({
	dropDown: {
		background: '#ffffff',
		marginLeft: '36px',
	},
	container: {
		marginTop: '20px',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'flex',
			},
			'& $deleteIconContainer': {
				display: 'flex',
			},
		},
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	listEditIconContainer: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconContainer: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
}));

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

interface Props {
	userId: string;
}

interface Attendance {
	id: any;
	date: any;
	in_time?: any | null | undefined;
	out_time?: any | null | undefined;
	reason: string;
	__typename?: string;
	submittedBy: {
		__typename?: 'user';
		full_name?: string | null | undefined;
		profile_pic?: string | null | undefined;
		id: any;
	};
	attendanceStatus: {
		__typename?: string;
		label: string;
		value: string;
	};
}
const AttendanceHistory = (props: Props) => {
	const { userId } = props;

	const [leaveStatusOption, setLeaveStatusOption] =
		useState<DropdownOption[]>();
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [leaveStatusId, setLeaveStatusId] = useState<string>();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [selectedAttendanceDetails, setSelectedAttendanceDetails] =
		useState<any>({});
	const classes = Style();
	const style = inlineStyle();
	const styles = ellipsisStyle();
	const datagridStyles = datagridStyle();

	const { data: attendanceLeaveStatus } = useGetAttendanceStatusQuery();

	const { data: userAttendanceList, refetch: refetchAttendanceList } =
		useGetAttendanceRegularizationHistoryByUserIdQuery({
			variables: {
				filter:
					leaveStatusId === 'All'
						? {
								submitted_by: { _eq: userId },
						  }
						: {
								submitted_by: { _eq: userId },
								attendance_status_id: { _eq: leaveStatusId },
						  },
			},
			fetchPolicy: 'network-only',
		});
	useEffect(() => {
		if (isEmpty(attendanceLeaveStatus?.attendance_status)) {
			return;
		}
		const response = attendanceLeaveStatus?.attendance_status?.map((value) => {
			if (value?.value === 'submitted') {
				setLeaveStatusId(value?.id);
			}
			return {
				id: value?.id,
				name: value?.value === 'submitted' ? 'Pending' : value?.label,
			};
		});
		const sortedResponse = orderBy(response, ['name'], ['asc']);
		setLeaveStatusOption(sortedResponse);
	}, [attendanceLeaveStatus]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const formatTime = (date: string, time: string) => {
		if (!date || !time) {
			return '--';
		}
		return dayjs(`${date} ${time}`).format('HH:mm');
	};
	const getInitialValueForEdit = (attendance: Attendance) => {
		const {
			attendanceStatus,
			in_time,
			out_time,
			date,
			submittedBy,
			__typename,
			...restFormValues
		} = attendance;

		setSelectedAttendanceDetails({
			...restFormValues,
			in_time: in_time ? dayjs(`${date} ${in_time}`).format('HH:mm') : null,
			out_time: out_time ? dayjs(`${date} ${out_time}`).format('HH:mm') : null,
			date: date,
		});
	};
	return (
		<>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
				mb={3}
			>
				<Box className={style?.dropDown}>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'flex-start'}
					>
						<Box className={style?.label}> Attendance Status </Box>
						<Form
							onSubmit={() => {}}
							initialValues={{ leavestatus: leaveStatusId }}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<SelectInput
											source={'leavestatus'}
											label={''}
											choices={
												leaveStatusOption && leaveStatusOption?.length > 0
													? [
															{
																id: 'All',
																name: 'All',
															},
															...leaveStatusOption,
													  ]
													: []
											}
											onChange={(event) => {
												setLeaveStatusId(event?.target?.value);
												setPage(0);
											}}
										/>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
			</Box>

			{userAttendanceList &&
			userAttendanceList?.user_attendance_request?.length > 0 ? (
				<Box marginTop={'10px'} marginLeft={'15px'}>
					<div>
						<div
							className={classes.leavesContainer}
							key={
								head(userAttendanceList?.user_attendance_request)?.submittedBy
									?.id
							}
							style={{ marginLeft: '13px', marginTop: '5px' }}
						>
							<div className={classes.avatarContainer}>
								<Avatar
									className={classes.avatar}
									src={`${
										head(userAttendanceList?.user_attendance_request)
											?.submittedBy?.profile_pic || ''
									}`}
								>
									{head(userAttendanceList?.user_attendance_request)
										?.submittedBy?.full_name?.charAt(0)
										.toUpperCase() || ''}
								</Avatar>
								<Typography className={classes.employeeName}>
									{`${
										head(userAttendanceList?.user_attendance_request)
											?.submittedBy?.full_name || ''
									}`}
								</Typography>
							</div>
							<Table>
								<TableHead>
									<TableRow>
										<CustomTableCell>Date</CustomTableCell>
										<CustomTableCell>Status</CustomTableCell>
										<CustomTableCell>Check in</CustomTableCell>
										<CustomTableCell>Check out</CustomTableCell>
										<CustomTableCell>Reason</CustomTableCell>
										<CustomTableCell></CustomTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{userAttendanceList?.user_attendance_request?.length > 0 &&
										userAttendanceList?.user_attendance_request
											?.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((attendance) => {
												return (
													<TableRow
														hover={true}
														key={attendance?.id}
														className={style.container}
													>
														<CustomTableCell>
															<div className={classes.dateContainer}>
																<DateRangeIcon
																	className={classes.calendarIcon}
																/>
																{`${dayjs(attendance?.date).format(
																	dateFormat
																)}`}
															</div>
														</CustomTableCell>
														<CustomTableCell>
															<div
																className={`${classes.statusContainer} ${
																	attendance?.attendanceStatus?.value ===
																	'submitted'
																		? classes.submittedStatus
																		: attendance?.attendanceStatus?.value ===
																		  'approved'
																		? classes.approvedStatus
																		: classes?.rejectedStatus
																}`}
															>
																{attendance?.attendanceStatus?.label
																	? attendance?.attendanceStatus?.label ===
																	  'Submitted'
																		? 'Pending'
																		: attendance?.attendanceStatus?.label
																	: ''}
															</div>
														</CustomTableCell>
														<CustomTableCell>
															{formatTime(
																attendance?.date,
																attendance?.in_time
															)}
														</CustomTableCell>
														<CustomTableCell>
															{formatTime(
																attendance?.date,
																attendance?.out_time
															)}
														</CustomTableCell>
														<CustomTableCell>
															<Tooltip
																title={`${attendance?.reason || '- -'}`}
																placement='right'
															>
																<Box
																	className={`${classes.reason} ${styles.ellipsis}`}
																>
																	{attendance?.reason}
																</Box>
															</Tooltip>
														</CustomTableCell>
														<CustomTableCell>
															{attendance?.attendanceStatus?.value !==
																'approved' &&
															attendance?.attendanceStatus?.value !==
																'rejected' ? (
																<Box className={style.listEditIconContainer}>
																	<IconButton
																		className={style.listEditIconButton}
																		onClick={() => {
																			setIsEditModalOpen(true);
																			getInitialValueForEdit(attendance);
																		}}
																		//disabled={!enableLeave}
																	>
																		<EditIcon
																			className={datagridStyles.editIcon}
																			onClick={() => {
																				setIsEditModalOpen(true);
																				//	handleEditLeave(leave);
																			}}
																		/>
																	</IconButton>
																</Box>
															) : (
																<Box
																	className={style.listEditIconContainer}
																></Box>
															)}
														</CustomTableCell>
													</TableRow>
												);
											})}
								</TableBody>
							</Table>
							<Box display='flex' justifyContent='flex-end'>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									count={
										userAttendanceList?.user_attendance_request_aggregate
											?.aggregate?.count || 0
									}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</Box>
						</div>
					</div>
				</Box>
			) : (
				<Box textAlign='center'>
					<img src={no_data_found} alt='no_data_found' />
				</Box>
			)}
			<AttendanceRequestForm
				open={isEditModalOpen}
				onClose={() => {
					setSelectedAttendanceDetails({});
					setIsEditModalOpen(false);
				}}
				attendance={selectedAttendanceDetails}
				refetch={() => refetchAttendanceList()}
				userId={userId}
			/>
		</>
	);
};

export default AttendanceHistory;
