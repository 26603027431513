import React from 'react';
import { useParams } from 'react-router-dom';
import {
	TextInput,
	useMutation,
	useNotify,
	useRefresh,
	required,
	number,
} from 'react-admin';

import { Dialog, Typography, Button, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
interface Record {
	bank_name?: string;
	branch_name?: string;
	account_number?: string;
	ifsc_code?: string;
}
interface Props {
	open: boolean;
	onClose: (value: boolean) => void;
	record: Record;
}
export const ConsultantBankDetailsEditForm = (props: Props) => {
	const { open, onClose, record: consultant } = props;
	const classes = modalFormStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const { id }: { id: string } = useParams();
	const saveDetails = (formValue: Record) => {
		mutate(
			{
				type: 'update',
				resource: 'contractor',
				payload: {
					id: id,
					data: formValue,
				},
			},
			{
				onSuccess: ({ data }) => {
					notify('Employee bank details updated successfully');
					onClose(false);
					refresh();
				},
				onFailure: (error) => {
					notify(`Updating failed: ${error.message}`, 'warning');
				},
			}
		);
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={onClose} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>
							Edit Basic Details
						</Typography>
						<CloseIcon
							className={classes.closeIcon}
							onClick={() => onClose(false)}
						/>
					</Box>
					<Form initialValues={consultant} onSubmit={saveDetails}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Box>
										<Typography className={classes.label}>
											Bank Name*
										</Typography>
										<TextInput
											source='bank_name'
											label=''
											validate={required()}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											Branch Name*
										</Typography>
										<TextInput source='branch_name' label='' />
									</Box>
									<Box>
										<Typography className={classes.label}>
											Account Number*
										</Typography>
										<TextInput
											source='account_number'
											label=''
											validate={[required(), number()]}
										/>
									</Box>
									<Box>
										<Typography className={classes.label}>
											IFSC Code*
										</Typography>
										<TextInput
											source='ifsc_code'
											label=''
											validate={required()}
										/>
									</Box>
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => onClose(false)}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											invalid ? classes.disabledButton : classes.saveButton
										}
										disabled={invalid}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ConsultantBankDetailsEditForm;
