import React from 'react';
import {
	Box,
	Typography,
	makeStyles,
	Slider,
	TextField,
} from '@material-ui/core';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { head, last } from 'lodash';
const textBoxTheme = createTheme({
	overrides: {
		MuiOutlinedInput: {
			root: {
				height: '35px',
			},
		},
	},
});
const useStyles = makeStyles(() => ({
	filterLabel: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		letterSpacing: '0px',
		color: '#292929',
		opacity: 1,
		height: '14px',
		marginBottom:'15px'
	},
	multipleInputContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '50px',
	},
}));

export const FilterSliderInput = (props) => {
	const { initialMin, initialMax, label, onChange , selectedValue } = props;
	const classes = useStyles();
	const onSliderValueChange = (event, newValue) =>{
		onChange(newValue)
	}
	const onMinTextValueChange = (event) =>{ 
		if(!event){
			return
		}
		onChange([parseInt(event?.target?.value), last(selectedValue) || initialMax])
	}
	const onMaxTextValueChange = (event) =>{
		if(!event){
			return
		}
		onChange([head(selectedValue) || initialMin, parseInt(event?.target?.value)])
	}
	return (
		<Box>
			<Typography className={classes.filterLabel}>{label}</Typography>
			<Slider
				value={!selectedValue.length ? [initialMin, initialMax] : selectedValue}
				onChange={onSliderValueChange}
				min={initialMin}
				max={initialMax}
			/>

			<Box className={classes.multipleInputContainer}>
				<Box width='185px' height='36px' paddingRight='10px'>
					<Typography >Min</Typography>
					<ThemeProvider theme={textBoxTheme}>
						<TextField variant='outlined' value={head(selectedValue) || initialMin} label='' onChange={onMinTextValueChange} />
					</ThemeProvider>
				</Box>
				<Box width='185px' height='36px'>
					<Typography >Max</Typography>
					<ThemeProvider theme={textBoxTheme}>
						<TextField variant='outlined' value={last(selectedValue) || initialMax} label='' onChange={onMaxTextValueChange} />
					</ThemeProvider>
				</Box>
			</Box>
		</Box>
	);
};
