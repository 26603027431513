import React, { useContext } from 'react';
import SettingsDetailContainer from '../SettingsDetailContainer.component';
import DepartmentList from './Department/DepartmentList.component';
import DesignationList from './Designation/DesignationList.component';
import JobLevelList from './JobLevel/JobLevelList.component';
import SkillList from './Skill/SkillList.component';
import NoticePeriodSetting from './NoticePeriod/NoticePeriodSetting.component';
import ProbationPeriodSettings from './ProbationPeriod/ProbationPeriodSettings.component';
import { UserProfileContext } from '../../../App';
import UserTeam from './UserTeams/UserTeam.component';
import EmployeeProfilePicSettings from './EmpCustomProfileSettings/EmployeeProfilePicSettings.component';
import SkillLevelList from './SkillLevel/SkillLevelList.component';
import EmployeePrefixList from './Prefix/EmployeePrefixList.component';
import ContractorPrefixList from './Prefix/ContractorPrefixList.component';
import EmployeeIdLengthSetting from './EmployeeIdLength/EmployeeIdLengthSetting';
import EmailUserGroupManagement from './UserEmailGroup/UserEmailGroupList.component';
import CustomFieldList from './CustomFields/CustomFieldList.component';

export const HrmsGeneralSettings = () => {
	const { featureFlag } = useContext<any>(UserProfileContext);

	return (
		<>
			<SettingsDetailContainer heading='Probation Period'>
				<ProbationPeriodSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Notice Period'>
				<NoticePeriodSetting />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading=''>
				<EmployeeProfilePicSettings />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Employee Id Length'>
				<EmployeeIdLengthSetting />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Employee Id Prefix'>
				<EmployeePrefixList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Consultant Id Prefix'>
				<ContractorPrefixList />
			</SettingsDetailContainer>
			{featureFlag?.userTeams && (
				<SettingsDetailContainer heading='Teams'>
					<UserTeam />
				</SettingsDetailContainer>
			)}
			<SettingsDetailContainer heading='Department'>
				<DepartmentList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Designation'>
				<DesignationList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Job Level'>
				<JobLevelList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Skill'>
				<SkillList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Skill Level'>
				<SkillLevelList />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Email User Groups'>
				<EmailUserGroupManagement />
			</SettingsDetailContainer>
			<SettingsDetailContainer heading='Custom Fields'>
				<CustomFieldList />
			</SettingsDetailContainer>
		</>
	);
};

export default HrmsGeneralSettings;
