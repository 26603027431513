import React, { useState } from 'react';

import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
	useQuery,
} from 'react-admin';

import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../../settingsStyle';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import EmployeePrefixForm from './EmployeePrefixForm.component';
import { PREFIX_DELETION_MESSAGE } from '../../constant';

interface Prefix {
	id?: string;
	name?: string;
	description?: string;
	org_id?: string;
}
const EmployeePrefixList = () => {
	const [isPrefixFormShown, setIsPrefixFormShown] = useState(false);
	const [editPrefixDetails, setEditPrefixDetails] = useState<Prefix>({});
	const [isPrefixEdit, setIsPrefixEdit] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [choosedPrefix, setChoosedPrefix] = useState(null);
	const [searchPrefix, setSearchPrefix] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: prefixList } = useQuery({
		type: 'GET_LIST',
		resource: 'employee_prefix',
		payload: {
			sort: { field: 'name', order: 'ASC' },
		},
	});
	const onEditPrefix = (department: Prefix) => {
		if (!department) {
			return;
		}
		const { org_id, ...restPrefix } = department;
		setEditPrefixDetails({ ...restPrefix });
		setIsPrefixEdit(true);
		setIsPrefixFormShown(true);
	};
	const deletePrefix = () => {
		mutate(
			{
				type: 'delete',
				resource: 'employee_prefix',
				payload: {
					id: choosedPrefix,
				},
			},
			{
				onSuccess: () => {
					notify(PREFIX_DELETION_MESSAGE);
					refresh();
					setIsDeleteModalShown(false);
					setChoosedPrefix(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('employee_employee_prefix_id_fkey')) {
						notify('Delete failed due to dependency', 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};
	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Prefix'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchPrefix(e?.target?.value);
								}}
								value={searchPrefix}
							/>
						</Box>
					</form>
				</ThemeProvider>

				<Button
					style={{ width: '130px' }}
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsPrefixFormShown(true);
						setIsPrefixEdit(false);
					}}
				>
					Add Prefix
				</Button>
			</Box>

			<Box className={settingsStyles.content}>
				<List
					resource='employee_prefix'
					basePath='/employee_prefix'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{
						name: searchPrefix,
						id:
							prefixList && prefixList?.length > 0
								? prefixList
										.filter(
											(prefix: { value: string }) => prefix.value !== 'N/A'
										)
										.map((prefix: { id: string }) => prefix.id)
								: [],
					}}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(prefix: any) => {
								return (
									<Tooltip title={`${prefix.name || '--'}`} placement='right'>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{prefix.name || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='DESCRIPTION'
							render={(prefix: any) => {
								return (
									<Tooltip
										title={`${prefix.description || '--'}`}
										placement='right'
									>
										<Typography
											className={`${settingsStyles.name} ${tooltipStyle.ellipsis}`}
										>
											{prefix.description || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							render={(department: any) => (
								<Box>
									<PopOver
										edit={() => onEditPrefix(department)}
										remove={() => {
											setIsDeleteModalShown(true);
											setChoosedPrefix(department?.id);
										}}
									/>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<EmployeePrefixForm
				open={isPrefixFormShown}
				onClose={() => setIsPrefixFormShown(false)}
				refetch={() => refresh()}
				initialValues={editPrefixDetails}
				isEditMutation={isPrefixEdit}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => setIsDeleteModalShown(false)}
				onDelete={deletePrefix}
			/>
		</>
	);
};

export default EmployeePrefixList;
