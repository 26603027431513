import React, { useState, useContext, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	Box,
	makeStyles,
	Button,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { ellipsisStyle } from '../../../../Layout/styles';
import AvatarField from '../../../../SharedComponents/AvatarField.component';
import { PopOver } from '../../../../SharedComponents/PopOver.component';
import {
	useDeleteProjectCommentByIdMutation,
	useGetProjectCommentsByProjectIdQuery,
} from '../../../../generated/graphql';
import dayjs from 'dayjs';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import {
	COMMENT_DELETED_MESSAGE,
	COMMENT_DELETION_FAILED_MESSAGE,
} from '../../constant';
import { useNotify } from 'react-admin';
import { UserProfileContext } from '../../../../App';
import ProjectCommentsForm from './AddCommentsForm.component';
import { sortBy } from 'lodash';

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '20px',
	},
	addNotesButton: {
		textTransform: 'none',
		minWidth: '111px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box !important',
		boxShadow: '0px 4px 12px #4285F43B !important',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	container: {
		overflowY: 'auto',
		width: '100%',
		height: '400px',
	},
	listContainer: {
		border: '1px solid #E0E0E0',
		height: '70px',
		marginBottom: '10px',
		borderRadius: '7px',
	},
	delayListContainer: {
		border: '1px solid #E0E0E0',
		height: '105px',
		marginBottom: '10px',
		borderRadius: '7px',
	},
	avatar: {
		display: 'flex',
		marginTop: '2px',
	},
	description: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-semiBold',
		fontSize: '13px',
		overflow: 'hidden',
		marginLeft: '45px',
		width: '700px',
	},
	noteTitle: {
		marginLeft: '7px',
		marginTop: '3px',
		color: '#292929',
		fontSize: '14px',
		fontFamily: 'Manrope-bold',
	},
	date: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '8px',
		color: '#5C5C5C',
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
	},
	noData: {
		marginTop: '5px',
	},
	label: {
		font: 'normal normal medium 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '12px !important',
		marginTop: '15px',
	},
	timeStamp: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-semiBold',
		fontSize: '10px',
		overflow: 'hidden',
		marginLeft: '45px',
		width: '700px',
	},
	projectDate: {
		fontFamily: 'Manrope-bold',
		fontSize: '13px',
		marginTop: '3px',
		marginLeft: '5px',
	},
	delayStartNote: {
		fontFamily: 'Manrope-medium',
		fontSize: '14px',
		marginTop: '2px',
	},
	delayEndNote: {
		fontFamily: 'Manrope-medium',
		marginLeft: '5px',
		fontSize: '14px',
		marginTop: '2px',
	},
	reason: {
		fontSize: '13px',
		fontFamily: 'Manrope-medium',
	},
	note: {
		fontSize: '13px',
		fontFamily: 'Manrope-medium',
		fontStyle: 'italic',
	},
	comment: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-medium',
		fontSize: '13px',
		overflow: 'hidden',
		marginLeft: '45px',
		width: '700px',
		fontStyle: 'italic',
	},
});
export interface CreatedBy {
	first_name?: string;
	full_name?: string;
	last_name?: string;
	__typename: string;
}
interface Comment {
	id?: string;
	task_id?: string;
	comment?: string;
	created_at?: Date;
	createdBy?: CreatedBy;
}

interface Props {
	projectId: string | null;
}

export const ProjectCommentsTab = (props: Props) => {
	const { projectId } = props;
	const [isCommentModalShown, setIsCommentModalShown] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedCommentDetails, setSelectedCommentDetails] =
		useState<Comment>();
	const [noteList, setNoteList] = useState<any>([]);
	const classes = useStyles();
	const styles = ellipsisStyle();
	const notify = useNotify();
	const { id, dateFormat } = useContext<any>(UserProfileContext);
	const { data: commentsList, refetch: refetchComments } =
		useGetProjectCommentsByProjectIdQuery({
			variables: {
				project_id: projectId,
			},
			skip: !projectId,
		});
	const [deleteComment] = useDeleteProjectCommentByIdMutation();

	useEffect(() => {
		if (!commentsList) {
			return;
		}
		const projectCommentList =
			commentsList?.project_comments &&
			commentsList?.project_comments?.length > 0
				? commentsList?.project_comments?.map((comment) => {
						return {
							createdBy: comment?.createdBy?.full_name
								? `${comment?.createdBy?.full_name} `
								: '- -',
							createdAt: comment?.updated_at || '',
							id: comment?.id,
							comment: comment?.comment,
							type: 'note',
							createdUserId: comment?.created_by,
						};
				  })
				: [];
		const projectDelayList =
			commentsList?.project_delay_mapping &&
			commentsList?.project_delay_mapping?.length > 0
				? commentsList?.project_delay_mapping?.map((delayList) => {
						return {
							createdBy: delayList?.createdBy?.full_name || '--',
							createdAt: delayList?.created_at,
							id: delayList?.id,
							reason: delayList?.project_delay_reason?.label,
							type: 'delay_note',
							changedFrom: delayList?.changed_from
								? dayjs(delayList?.changed_from).format(dateFormat)
								: '- -',
							changedTo: delayList?.changed_to
								? dayjs(delayList?.changed_to).format(dateFormat)
								: '- -',
							note: delayList?.notes || '- -',
						};
				  })
				: [];
		const sortedNoteList = sortBy(
			[...projectCommentList, ...projectDelayList],
			['createdAt']
		);
		setNoteList(sortedNoteList);
	}, [commentsList, dateFormat]);
	const onDeleteComment = () => {
		deleteComment({
			variables: {
				id: selectedCommentDetails?.id,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(COMMENT_DELETED_MESSAGE);
					setIsDeleteModalShown(false);
					refetchComments();
					setSelectedCommentDetails({});
					return;
				}
			})
			.catch((error) => {
				if (error) {
					notify(COMMENT_DELETION_FAILED_MESSAGE, 'warning');
					return;
				}
			});
	};
	return (
		<>
			<Box className={classes.toolbar}>
				<Box>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						className={classes.addNotesButton}
						onClick={() => setIsCommentModalShown(true)}
					>
						Add Note
					</Button>
				</Box>
			</Box>
			<Box className={classes.container}>
				{noteList && noteList?.length > 0 ? (
					noteList?.map((comment: any) => (
						<Box
							className={
								comment?.type === 'note'
									? classes.listContainer
									: classes.delayListContainer
							}
							key={comment?.id}
						>
							<>
								<Box
									display='flex'
									justifyContent='space-between'
									marginTop='5px'
									marginLeft='5px'
								>
									<Box display='flex' marginLeft='5px'>
										<Tooltip
											title={
												comment?.createdBy ? `${comment?.createdBy}` : '- -'
											}
											placement='left'
										>
											<Box>
												<AvatarField
													className={classes.avatar}
													firstName={
														comment?.createdBy ? `${comment?.createdBy}` : '- -'
													}
													lastName={''}
													imageSrc={``}
													size='30'
												/>
											</Box>
										</Tooltip>
										<Box display='flex' className={classes.noteTitle}>
											<Box>
												{comment?.createdBy ? `${comment?.createdBy}` : '- -'}
											</Box>
											<Box
												marginLeft='20px'
												marginTop='2px'
												className={classes.date}
											>
												{comment?.createdAt
													? dayjs(comment?.createdAt).format(
															'DD-MMM-YYYY [at] hh:mm A'
													  )
													: ''}
											</Box>
										</Box>
									</Box>
									{comment?.createdUserId === id &&
										comment?.type === 'note' && (
											<Box marginRight='5px'>
												<PopOver
													edit={() => {
														setIsEdit(true);
														setIsCommentModalShown(true);
														const {
															createdBy,
															createdAt,
															__typename,
															...restValues
														} = comment;
														setSelectedCommentDetails(restValues);
													}}
													remove={() => {
														const {
															createdBy,
															created_at,
															task_id,
															...restValues
														} = comment;

														setSelectedCommentDetails(restValues);
														setIsDeleteModalShown(true);
													}}
												/>
											</Box>
										)}
								</Box>
								{comment?.type === 'note' ? (
									<Tooltip title={comment?.comment || '- -'} placement='left'>
										<Box className={`${classes.comment} ${styles.ellipsis}`}>
											{comment?.comment || '- -'}
										</Box>
									</Tooltip>
								) : (
									<Box className={`${classes.description} ${styles.ellipsis}`}>
										<Box display='flex'>
											<Typography className={classes.delayStartNote}>
												Project end date changed from
											</Typography>
											<Typography className={classes.projectDate}>
												{comment?.changedFrom}
											</Typography>
											<Typography className={classes.delayEndNote}>
												to
											</Typography>
											<Typography className={classes.projectDate}>
												{comment?.changedTo}
											</Typography>
										</Box>
										<Tooltip
											title={`Reason: ${comment?.reason}`}
											placement='left'
										>
											<Box className={`${classes.reason} ${styles.ellipsis}`}>
												{`Reason: ${comment?.reason}`}
											</Box>
										</Tooltip>

										<Tooltip title={comment?.note} placement='left'>
											<Box className={`${classes.note} ${styles.ellipsis}`}>
												{comment?.note}
											</Box>
										</Tooltip>
									</Box>
								)}
							</>
						</Box>
					))
				) : (
					<Typography className={classes.label}>No notes found</Typography>
				)}
			</Box>
			<ProjectCommentsForm
				open={isCommentModalShown}
				onClose={() => {
					setIsCommentModalShown(false);
					setIsEdit(false);
					setSelectedCommentDetails({});
				}}
				onSuccess={() => {
					refetchComments();
					setIsEdit(false);
				}}
				commentDetails={selectedCommentDetails}
				isEdit={isEdit}
				projectId={projectId}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setIsDeleteModalShown(false);
					setSelectedCommentDetails({});
				}}
				onDelete={onDeleteComment}
				confirmationMessage='Do you really want to delete this comment ?'
			/>
		</>
	);
};
