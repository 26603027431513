import React, { useState, useContext } from 'react';
import {
	List,
	Datagrid,
	useListContext,
	ReferenceField,
	FunctionField,
	Loading,
} from 'react-admin';

import {
	Box,
	makeStyles,
	IconButton,
	Button,
	Chip,
	Tooltip,
	Typography,
	TextField,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { CompanyCard } from '../../SharedComponents/CompanyCard.component';
import AddCompanyModal from './AddCompanyModal.component';
import DrawerFilterContainer from '../../SharedComponents/DrawerFilterContainer.component';
import CompanyFilterFormBody from './CompanyFilterFormBody.component';
import AppliedFiltersContainer from '../../SharedComponents/AppliedFiltersContainer.component';
import { GridIcon, InsightsIcon, ListIcon } from '../../assets/SvgIcons';
import { ellipsisStyle, globalStyles } from '../../Layout/styles';
import { searchBarTheme } from '../../Layout/Theme.component';
import { SearchIcon } from '../../assets/SvgIcons';
import { UserProfileContext } from '../../App';
import { CompanyListPagination } from './companyListPagination';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import CompanyWiseEmployeeExperienceList from './CompanyWiseEmployeeExperienceList.component';

const navigationContainerStyles = makeStyles(() => ({
	navigationTabContainer: {
		width: '415px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
}));

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		fontSize: '14px',
		color: '#292929',
		fontWeight: '700',
		marginBottom: '28px',
	},
	gridList: {
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 19%)',
		margin: 'auto',
		gap: '16px',
		position: 'relative',
		bottom: '26px',
	},
	companiesContainer: {
		height: '100vh',
	},
	filterContainer: {
		position: 'absolute',
	},
	toolbarContainer: {
		position: 'absolute',
		right: '0',
		marginBottom: '10px',
	},
	button: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	iconButton: {
		width: '36px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#888888',
	},
	activeIconButton: {
		color: '#4285F4',
		border: '1px solid #4285F4',
	},
	name: {
		width: '130px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '140px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
	},
}));

export const CompanyList = (props) => {
	const { permissions, filterState, dispatchFilter } =
		useContext(UserProfileContext);
	const [isDrawerShown, setIsDrawerShown] = useState(false);
	const [openAddDialogue, setOpenAddDialogue] = useState(false);
	const [companyTotal, setCompanyTotal] = useState(0);
	const [isCompanyInsightsShown, setIsCompanyInsightsShown] = useState(false);

	const classes = useStyles();
	const styles = ellipsisStyle();
	const commonStyles = globalStyles();
	const navigationStyle = navigationContainerStyles();

	const CompanyGrid = () => {
		const classes = useStyles();
		const { data: companies, ids, loading } = useListContext();

		if (loading) return <Loading />;

		return ids ? (
			<Box className={classes.gridList}>
				{ids.map((id, index) => (
					<CompanyCard
						key={index}
						data={companies[id]}
						basePath={props.basePath}
						resource={props.resource}
					/>
				))}
			</Box>
		) : null;
	};

	const CompanyFilter = () => {
		return (
			<>
				<DrawerFilterContainer
					open={isDrawerShown}
					close={() => {
						setIsDrawerShown(false);
					}}
				>
					<CompanyFilterFormBody />
				</DrawerFilterContainer>
			</>
		);
	};
	const getAppliedFilters = (
		filter,
		removeFilter,
		filterValues,
		setFilters,
		displayedFilters
	) => {
		switch (filter) {
			case 'name':
				return (
					<FunctionField
						record={filterValues}
						source='name'
						label=''
						render={() => (
							<Chip
								className={classes.multiSelectFilterChip}
								label={`${filterValues?.name}`}
								variant='outlined'
								onDelete={() => {
									removeFilter(filter);
								}}
							/>
						)}
					/>
				);
			case 'owner_id':
				return (
					filterValues?.owner_id.length > 0 &&
					filterValues?.owner_id.map((owner_id, index) => (
						<ReferenceField
							link={false}
							source='owner_id'
							reference='user'
							label='Full Name'
							resource='crm_company'
							record={{ owner_id }}
							basePath={'/crm_company'}
						>
							<FunctionField
								label='Full Name'
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.first_name || ''} ${
												record?.last_name || ''
											}`}
											variant='outlined'
											onDelete={() => {
												filterValues.owner_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);

			case 'category_id':
				return (
					filterValues?.category_id.length > 0 &&
					filterValues?.category_id.map((category_id, index) => (
						<ReferenceField
							link={false}
							source='category_id'
							reference='company_categories'
							label='Full Name'
							resource={props.resource}
							record={{ category_id }}
							basePath={props.basePath}
						>
							<FunctionField
								label='Full Name'
								record={filterValues}
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.label || ''}`}
											variant='outlined'
											onDelete={() => {
												filterValues.category_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'sector_id':
				return (
					filterValues?.sector_id.length > 0 &&
					filterValues?.sector_id.map((sector_id, index) => (
						<ReferenceField
							link={false}
							source='sector_id'
							reference='company_sector'
							label=''
							resource={props.resource}
							record={{ sector_id }}
							basePath={props.basePath}
						>
							<FunctionField
								record={filterValues}
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.label || ''}`}
											variant='outlined'
											onDelete={() => {
												filterValues.sector_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'size_id':
				return (
					filterValues?.size_id.length > 0 &&
					filterValues?.size_id.map((size_id, index) => (
						<ReferenceField
							link={false}
							source='size_id'
							reference='company_size'
							label=''
							resource={props.resource}
							record={{ size_id }}
							basePath={props.basePath}
						>
							<FunctionField
								record={filterValues}
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.label || ''}`}
											variant='outlined'
											onDelete={() => {
												filterValues.size_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			case 'contact_type_id':
				return (
					filterValues?.contact_type_id.length > 0 &&
					filterValues?.contact_type_id.map((contact_type_id, index) => (
						<ReferenceField
							link={false}
							source='contact_type_id'
							reference='company_contact_type'
							label=''
							resource={props.resource}
							record={{ contact_type_id }}
							basePath={props.basePath}
						>
							<FunctionField
								record={filterValues}
								render={(record) => (
									<>
										<Chip
											className={classes.multiSelectFilterChip}
											key={index}
											label={`${record?.label || ''}`}
											variant='outlined'
											onDelete={() => {
												filterValues.contact_type_id.splice(index, 1);
												setFilters(filterValues, displayedFilters);
											}}
										/>
									</>
								)}
							/>
						</ReferenceField>
					))
				);
			default:
				return (
					<Chip
						label={filter}
						variant='outlined'
						onDelete={() => {
							removeFilter(filter);
						}}
					/>
				);
		}
	};

	return (
		<Box className={classes.companiesContainer}>
			<Box className={navigationStyle.navigationTabContainer}>
				<Button
					className={[
						commonStyles.navigationTabButtons,
						!isCompanyInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsCompanyInsightsShown(false);
					}}
				>
					Companies({companyTotal || 0})
				</Button>

				<Button
					className={[
						commonStyles.navigationTabButtons,
						isCompanyInsightsShown
							? commonStyles.activeNavigationTabButton
							: '',
					].join(' ')}
					onClick={() => {
						setIsCompanyInsightsShown(true);
					}}
					startIcon={<InsightsIcon />}
				>
					Insights
				</Button>
			</Box>
			{!isCompanyInsightsShown ? (
				<>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'
						marginTop='24px'
					>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={searchBarTheme}>
								<Box width='344px'>
									<TextField
										placeholder='Search companies'
										label={false}
										fullWidth
										InputLabelProps={{
											style: { fontSize: 0, color: '#7C7C7C' },
										}}
										InputProps={{
											startAdornment: <SearchIcon />,
										}}
										onChange={(e) => {
											dispatchFilter({
												type: FILTER_STATE_ACTIONS.UPDATE_COMPANY_SEARCH_TEXT,
												payload: e?.target?.value,
											});
										}}
										value={filterState?.companySearchText ?? ''}
									/>
								</Box>
							</ThemeProvider>
						</form>
						<Box>
							<IconButton
								className={[
									classes.iconButton,
									!filterState?.isCompanyListView
										? classes.activeIconButton
										: '',
								].join(' ')}
								onClick={() => {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_COMPANY_VIEW_FILTER,
										payload: false,
									});
								}}
							>
								<GridIcon />
							</IconButton>
							<IconButton
								className={[
									classes.iconButton,
									filterState?.isCompanyListView
										? classes.activeIconButton
										: '',
								].join(' ')}
								color='primary'
								onClick={() => {
									dispatchFilter({
										type: FILTER_STATE_ACTIONS.UPDATE_COMPANY_VIEW_FILTER,
										payload: true,
									});
								}}
							>
								<ListIcon />
							</IconButton>
							<Tooltip title={'Filter'}>
								<IconButton
									onClick={() => setIsDrawerShown(true)}
									className={commonStyles.filterButton}
								>
									<FilterListIcon />
								</IconButton>
							</Tooltip>
							{permissions?.crm_company?.insert_permissions && (
								<Button
									startIcon={<AddIcon />}
									className={classes.addButton}
									onClick={() => setOpenAddDialogue(true)}
								>
									Add company
								</Button>
							)}
						</Box>
					</Box>
					<Box>
						<List
							{...props}
							empty={false}
							pagination={
								<CompanyListPagination companyData={companyTotal ?? -1} />
							}
							component='div'
							perPage={15}
							sort={{ field: 'created_at', order: 'DESC' }}
							bulkActionButtons={false}
							filters={
								<Box className={commonStyles.filterStyle}>
									<Box display='flex' flexDirection='column' width='100%'>
										<AppliedFiltersContainer
											totalCount={(total) => setCompanyTotal(total)}
										>
											{getAppliedFilters}
										</AppliedFiltersContainer>
									</Box>
								</Box>
							}
							title={' '}
							actions={<CompanyFilter />}
							filter={{ name: `%${filterState?.companySearchText ?? ''}%` }}
						>
							{!filterState?.isCompanyListView ? (
								<CompanyGrid />
							) : (
								<Datagrid rowClick='edit' className={commonStyles.listStyle}>
									<FunctionField
										label='NAME'
										render={(record) => {
											return (
												<Tooltip
													title={`${record?.name || '--'}`}
													placement='right'
												>
													<Typography
														className={[classes.name, styles.ellipsis].join(
															' '
														)}
													>
														{record?.name}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='CATEGORY'
										render={(record) =>
											record?.category_id ? (
												<ReferenceField
													source='category_id'
													reference='company_categories'
													label='Category'
												>
													<FunctionField
														label=''
														render={(record) => `${record?.label || '--'}`}
													/>
												</ReferenceField>
											) : (
												'- -'
											)
										}
									/>
									<FunctionField
										label='SECTOR'
										render={(record) =>
											record?.sector_id ? (
												<ReferenceField
													source='sector_id'
													reference='company_sector'
													label=''
												>
													<FunctionField
														label=''
														render={(record) => `${record?.label || '--'}`}
													/>
												</ReferenceField>
											) : (
												'- -'
											)
										}
									/>
									<FunctionField
										label='SIZE'
										render={(record) =>
											record?.category_id ? (
												<ReferenceField
													source='size_id'
													reference='company_size'
													label=''
												>
													<FunctionField
														label=''
														render={(record) => `${record?.label || '--'}`}
													/>
												</ReferenceField>
											) : (
												'- -'
											)
										}
									/>
									<FunctionField
										label='WEBSITE'
										render={(record) => {
											return record?.website ? (
												<Tooltip title={`${record?.website}`} placement='right'>
													<Typography
														className={[classes.name, styles.ellipsis].join(
															' '
														)}
													>
														{record?.website}
													</Typography>
												</Tooltip>
											) : (
												'- -'
											);
										}}
									/>
									<FunctionField
										label='PHONE'
										render={(record) => `${record?.phone || '--'}`}
									/>
									<ReferenceField
										source='owner_id'
										reference='user'
										label='OWNER'
									>
										<FunctionField
											label='Full Name'
											render={(record) => (
												<Tooltip
													title={`${record?.full_name || '- -'}`}
													placement='right'
												>
													<Typography
														className={`${classes.name} ${styles.ellipsis}`}
													>
														{`${record?.full_name || '- -'}`}
													</Typography>
												</Tooltip>
											)}
										/>
									</ReferenceField>
								</Datagrid>
							)}
						</List>
						<AddCompanyModal
							open={openAddDialogue}
							onClose={() => setOpenAddDialogue(false)}
						/>
					</Box>
				</>
			) : (
				<CompanyWiseEmployeeExperienceList />
			)}
		</Box>
	);
};

export default CompanyList;
