import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { insightViewStyle } from '../../Layout/styles';
import { OpportunitySummaryReport } from '../../Reports/talent/OpportunitySummary.component';
import { OpportunityByOwnerReport } from '../../Reports/talent/OpportunityByOwner.component';
import { OpportunityLossReport } from '../../Reports/talent/OpportunityLossReason.component';
import { OpportunityTotalSaleReport } from '../../Reports/talent/OpportunityTotalSale.component';
import { OpportunityTotalSaleByOwnerReport } from '../../Reports/talent/OpportunityTotalSaleByOwner.component';
import { OpportunityTotalSaleByGeographyReport } from '../../Reports/talent/OpportunitySaleByGeography.component';
import { OpportunitySaleForecastReport } from '../../Reports/talent/OpportunitySalesForecast.component';
import { UserProfileContext } from '../../App';
import { currentYear } from '../constant';
import {
	useGetOrgFinancialQuery,
	useGetPipelineInsightsValuesByPipelineIdQuery,
} from '../../generated/graphql';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';
import { useParams } from 'react-router';
import { PipelineTotalCount } from '../../Reports/talent/Pipeline/PipelineTotalCount.component';
import { head } from 'lodash';
import { PipelineTotalValue } from '../../Reports/talent/Pipeline/PipelineTotalValue.component';
import { PipelineWeightedValue } from '../../Reports/talent/Pipeline/PipelineWeightedValue.component';
import { PipelineSalesRevenue } from '../../Reports/talent/Pipeline/PipelineSalesRevenue.component';
import { PipelineLossRevenue } from '../../Reports/talent/Pipeline/PipelineLossRevenue.component';

const CountCard = ({ label, value }: { label: string; value: ReactNode }) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.countContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
export const OpportunityInsights = () => {
	const classes = insightViewStyle();
	const { id: boardId } = useParams<{ id: string }>();
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const [startDate, setStartDate] = useState<string | null | undefined>(
		currentYear.startDate
	);
	const [endDate, setEndDate] = useState<string | null | undefined>(
		currentYear.endDate
	);

	const { data: organisation } = useGetOrgFinancialQuery({
		variables: { organisationId },
	});

	const { data: pipelineValues, loading: isPipelineValuesLoading } =
		useGetPipelineInsightsValuesByPipelineIdQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				kanbanBoardId: boardId,
			},
		});

	useEffect(() => {
		if (!organisation) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				organisation?.organization[0]?.financial_year_start_month || '',
			financial_year_start_date:
				organisation?.organization[0]?.financial_year_start_date || '',
			financial_year_end_date:
				organisation?.organization[0]?.financial_year_end_date || '',
			financial_year_end_month:
				organisation?.organization[0]?.financial_year_end_month || '',
		});
		setStartDate(calculatedDate?.startDate);
		setEndDate(calculatedDate?.endDate);
	}, [organisation]);

	return (
		<>
			<Box className={classes.headCountContainer}>
				<CountCard
					label='Opportunity Total Value'
					value={
						<PipelineTotalValue
							value={
								pipelineValues?.pipelineTotalValue.aggregate?.sum?.value || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Opportunity Weighted Value'
					value={
						<PipelineWeightedValue
							value={
								pipelineValues?.pipelineTotalWeightedValue.reduce(
									(previousValue, currentValue) =>
										previousValue + currentValue.weighted_value,
									0
								) || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Opportunity Total Count'
					value={
						<PipelineTotalCount
							value={pipelineValues?.pipelineTotalCount.aggregate?.count || 0}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Total Sales'
					value={
						<PipelineSalesRevenue
							count={pipelineValues?.totalSales.aggregate?.sum?.value || 0}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Lost Revenue'
					value={
						<PipelineLossRevenue
							value={
								pipelineValues?.pipelineLossValue.aggregate?.sum?.value || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
			</Box>
			<Box className={classes.container}>
				<Box className={classes.chartContainer}>
					<OpportunitySummaryReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityByOwnerReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityLossReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityTotalSaleReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityTotalSaleByOwnerReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunityTotalSaleByGeographyReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<OpportunitySaleForecastReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
			</Box>
		</>
	);
};

export default OpportunityInsights;
