import React, { useContext, useState, useEffect } from 'react';
import { TextInput, required, useNotify, useRefresh } from 'react-admin';
import { Form, Field } from 'react-final-form';

import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	TextField,
	InputAdornment,
	makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchIcon } from '../../../assets/SvgIcons';
import {
	AutocompleteSearchStyles,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	modalFormTheme,
	autoCompleteTheme,
} from '../../../Layout/Theme.component';
import { TimesheetStyles } from '.././TimesheetStyles';
import {
	useGetOrgTasksQuery,
	useGetProjectTaskOptionQuery,
	useGetDepartmentIdQuery,
	useUpsertOvertimeMutation,
	useGetTimesheetDayTypeOptionsQuery,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import TimeInput from '../../../SharedComponents/TimeInput.component';
import {
	PROJECT_TYPE_CATEGORY,
	PROJECT_TASK_GROUP_LABEL,
	MASTER_PROJECT_GROUP_LABEL,
	ORG_TASK_GROUP_LABEL,
	MASTER_PROJECT_TASK_TYPE,
	ORG_TASK_TYPE,
	checkIfOnlyZeroesWithOneColon,
	validHHMMFormat,
	checkIfOnlyOneNumberAfterColon,
	checkIfOnlyOneColonAfterNumbers,
	checkIfOnlyNumbers,
	OVERTIME_ADD_NOTIFICATION_TEXT,
	OVERTIME_UPDATE_NOTIFICATION_TEXT,
} from './constant';
import { head, isEmpty, orderBy } from 'lodash';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	AddTaskPayload,
	TimesheetOvertimeEntry,
	TimesheetOvertimeFormValues,
} from './Overtime.model';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetOvertimeFormProps {
	onClose: () => void;
	open: boolean;
	projectOptions:
		| {
				id: string;
				name: string;
				isBillable?: boolean | null;
				companyName?: string | undefined;
				type: string;
		  }[]
		| undefined;
	selectedDate: string;
	onSuccess?: () => void;
	initialValues: TimesheetOvertimeFormValues;
	totalHours?: string;
	timesheetOvertimeSubmissionId?: any;
	isWeeklyTimesheetForm?: boolean;
	onSubmit: (data: AddTaskPayload) => void;
	timesheetOvertimeEntries: TimesheetOvertimeEntry[];
	timesheetOvertimeApproverOptions: {
		type?: string | null | undefined;
		id: any;
		name: string | null | undefined;
	}[];
}

export const customStyles = makeStyles({
	errorText: {
		fontFamily: 'Manrope-medium',
		fontSize: '10px !important',
		color: '#EA4335',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},
});
export const TimesheetOvertimeForm = (props: TimesheetOvertimeFormProps) => {
	const { id: userId, isTrackDayTypeEnabled } =
		useContext<any>(UserProfileContext);
	const timesheetStyles = TimesheetStyles();
	const timesheetFormStyles = AutocompleteSearchStyles();
	const {
		open,
		onClose,
		projectOptions,
		selectedDate,
		onSuccess,
		initialValues,
		totalHours,
		timesheetOvertimeSubmissionId,
		isWeeklyTimesheetForm = false,
		onSubmit,
		timesheetOvertimeEntries,
		timesheetOvertimeApproverOptions,
	} = props;
	const [selectedProject, setSelectedProject] = useState<string>('');
	const [isDayHoursLimitExceed, setIsDayHoursLimitExceed] = useState(false);
	const [isTaskUniqueViolationError, setIsTaskUniqueViolationError] =
		useState(false);
	const [
		isMasterProjectTaskUniqueViolationError,
		setIsMasterProjectTaskUniqueViolationError,
	] = useState(false);
	const [isOrgTaskUniqueViolationError, setIsOrgTaskUniqueViolationError] =
		useState(false);
	const [isZeroWorkingHoursError, setIsZeroWorkingHoursError] = useState(false);
	const [isWorkingHoursError, setIsWorkingHoursError] = useState(false);
	const [workingHourInputError, setWorkingHourInputError] = useState('');
	const [orgTaskCategory, setOrgTaskCategory] = useState('');
	const [orgDepartmentId, setOrgDepartmentId] = useState('');
	const [currentApprover, setCurrentApprover] = useState<
		| {
				type?: string | null | undefined;
				id: any;
				name: string | null | undefined;
		  }
		| undefined
		| null
	>();

	const formStyles = modalFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const customStyle = customStyles();
	const { data: projectTaskOptions, loading: isProjectTaskLoading } =
		useGetProjectTaskOptionQuery({
			variables: {
				projectId: selectedProject,
				userId: userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: departmentId } = useGetDepartmentIdQuery({
		variables: {
			userId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: dayTypeOptions, loading: isDayTypeLoading } =
		useGetTimesheetDayTypeOptionsQuery({
			fetchPolicy: 'network-only',
			skip: !isTrackDayTypeEnabled,
		});

	const [upsertTimesheetOvertime, { loading: isLoading }] =
		useUpsertOvertimeMutation();

	const { data: orgTasks } = useGetOrgTasksQuery({
		variables: {
			taskCategory: orgTaskCategory,
			departmentId: orgDepartmentId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (isEmpty(departmentId?.user)) {
			return;
		}
		const response = head(departmentId?.user);
		if (
			isEmpty(response?.employee?.department?.id) &&
			isEmpty(head(response?.contractors)?.department?.id)
		) {
			return;
		}
		const record = response?.employee
			? response?.employee?.department?.id
			: head(response?.contractors)?.department?.id;
		setOrgDepartmentId(record);
	}, [departmentId]);

	useEffect(() => {
		if (!initialValues?.project_id) {
			return;
		}
		setSelectedProject(initialValues?.project_id);
		if (initialValues.taskType !== ORG_TASK_TYPE) {
			setOrgTaskCategory(PROJECT_TYPE_CATEGORY);
			return;
		}
		setOrgTaskCategory(initialValues?.project_id || '');
	}, [initialValues]);

	const isDailyHoursLimitExceed = (formTotalHoursValue: string) => {
		if (!totalHours || !formTotalHoursValue) {
			return;
		}
		if (initialValues?.id) {
			const currentHoursAndMinutes = totalHours.split(':');
			const weekHoursAndMinutes = [initialValues?.working_hours];
			const totalHoursAndMinutes = weekHoursAndMinutes
				.map((hours) => hours.split(':'))
				.map((hoursAndMinutes) =>
					dayjs.duration({
						hours: Number(hoursAndMinutes[0]),
						minutes: Number(hoursAndMinutes[1]),
						seconds: Number(hoursAndMinutes[2]),
					})
				)
				.reduce(
					(total, djs) => total.subtract(djs),
					dayjs.duration({
						hours: Number(currentHoursAndMinutes[0]),
						minutes: Number(currentHoursAndMinutes[1]),
						seconds: Number(currentHoursAndMinutes[2]),
					})
				)
				.format('HH:mm');
			return calculateTotalHours([totalHoursAndMinutes, formTotalHoursValue]);
		}
		const weekHoursAndMinutes = [totalHours, formTotalHoursValue];
		return calculateTotalHours(weekHoursAndMinutes);
	};

	const calculateTotalHours = (hoursAndMinutes: string[]) => {
		const totalHoursAndMinutes = hoursAndMinutes
			.map((hours) => hours.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, djs) => total.add(djs),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			)
			.asHours();

		if (totalHoursAndMinutes > 24) {
			return true;
		}
		return false;
	};

	const checkIfTimeInputValueHasError = (timeInputValue: string) => {
		if (!timeInputValue || timeInputValue.trim() === '') {
			setWorkingHourInputError('Invalid Input');
			return true;
		}

		if (checkIfOnlyNumbers.test(timeInputValue)) {
			return false;
		}

		// Check if hrs or minutes reached max limit
		if (timeInputValue.includes(':')) {
			if (Number(timeInputValue.split(':')[0]) > 23) {
				setWorkingHourInputError('Max hrs reached');
				return true;
			}
			if (Number(timeInputValue.split(':')[1]) > 59) {
				setWorkingHourInputError('Max mins reached');
				return true;
			}
		}

		// Check if only numbers are there in input and check if it is greater than 23
		if (checkIfOnlyOneColonAfterNumbers.test(timeInputValue)) {
			if (Number(timeInputValue.split(':')[0]) > 23) {
				setWorkingHourInputError('Max hrs reached');
				return true;
			}
			return false;
		}

		// Check for format HH:m (11:1)
		if (checkIfOnlyOneNumberAfterColon.test(timeInputValue)) {
			return false;
		}

		// Check valid format HH:MM
		if (validHHMMFormat.test(timeInputValue)) {
			return false;
		}
		setWorkingHourInputError('Invalid Input');
		return true;
	};

	const getProjectTaskOptions = () => {
		if (!projectTaskOptions) {
			return;
		}
		const projectTasks = orderBy(
			projectTaskOptions?.project_task.map((projectTask) => ({
				id: projectTask.id,
				name: projectTask.is_billable
					? `${projectTask.title}`
					: `${projectTask.title} (NB)`,
				taskType: PROJECT_TYPE_CATEGORY,
				groupByLabel: PROJECT_TASK_GROUP_LABEL,
				is_billable: projectTask.is_billable,
			})),
			[(option) => option?.name?.toUpperCase()],
			['asc']
		);
		const generalProjectTasks = orderBy(
			projectTaskOptions?.master_project_task.map((task) => ({
				id: task.id,
				name: task.is_billable ? `${task.title}` : `${task.title} (NB)`,
				taskType: MASTER_PROJECT_TASK_TYPE,
				groupByLabel: MASTER_PROJECT_GROUP_LABEL,
				is_billable: task.is_billable,
			})),
			[(option) => option?.name?.toUpperCase()],
			['asc']
		);

		return [...projectTasks, ...generalProjectTasks];
	};

	const setSelectedTaskCategory = (categoryId: string) => {
		if (!categoryId || !projectOptions) {
			return;
		}
		const category = projectOptions.find(
			(project) => project.id === categoryId
		);
		if (category && category.type !== PROJECT_TYPE_CATEGORY) {
			setOrgTaskCategory(category.type);
			return;
		}
		setOrgTaskCategory(PROJECT_TYPE_CATEGORY);
	};

	const getTaskOptionRenderer = () => {
		if (!selectedProject) {
			return [];
		}
		if (orgTaskCategory !== PROJECT_TYPE_CATEGORY) {
			if (!orgTasks) {
				return;
			}
			return orderBy(
				orgTasks?.org_task_mappings.map((task) => ({
					id: task.org_task?.id,
					name: task.org_task?.is_billable
						? task.org_task?.title
						: `${task.org_task?.title} (NB)`,
					taskType: ORG_TASK_TYPE,
					groupByLabel: ORG_TASK_GROUP_LABEL,
					is_billable: task.org_task?.is_billable,
				})),
				[(option) => option?.name?.toUpperCase()],
				['asc']
			);
		}

		return getProjectTaskOptions();
	};

	const getTimesheetPayload = (
		timesheetOvertimeFormValues: TimesheetOvertimeFormValues
	) => {
		let workingHours = timesheetOvertimeFormValues.working_hours;
		if (/^[0-9]+$/.test(timesheetOvertimeFormValues.working_hours)) {
			if (Number(timesheetOvertimeFormValues.working_hours) < 23) {
				workingHours = `0${timesheetOvertimeFormValues.working_hours}:00`;
			}
		}
		const selectedProject = projectOptions?.find(
			(project) => project.id === timesheetOvertimeFormValues.project_id
		);
		const taskOptions = getTaskOptionRenderer();
		const selectedTask =
			taskOptions &&
			taskOptions.find(
				(task) => task.id === timesheetOvertimeFormValues.task_id
			);

		if (selectedTask?.taskType === PROJECT_TYPE_CATEGORY) {
			return {
				id: timesheetOvertimeFormValues?.id,
				project_id: timesheetOvertimeFormValues.project_id,
				task_id: timesheetOvertimeFormValues.task_id,
				master_project_task_id: null,
				org_task_id: null,
				notes: timesheetOvertimeFormValues?.notes,
				user_id: userId,
				working_hours: workingHours,
				date: selectedDate,
				is_billable: selectedProject?.isBillable
					? selectedTask.is_billable
					: false,
				timesheet_submission_id: timesheetOvertimeSubmissionId,
				approver_id: currentApprover?.id,
				day_type_id: timesheetOvertimeFormValues?.day_type_id,
			};
		}

		if (selectedTask?.taskType === MASTER_PROJECT_TASK_TYPE) {
			return {
				id: timesheetOvertimeFormValues?.id,
				project_id: timesheetOvertimeFormValues?.project_id,
				master_project_task_id: timesheetOvertimeFormValues?.task_id,
				task_id: null,
				org_task_id: null,
				notes: timesheetOvertimeFormValues?.notes,
				user_id: userId,
				working_hours: workingHours,
				date: selectedDate,
				is_billable: selectedProject?.isBillable
					? selectedTask.is_billable
					: false,
				timesheet_submission_id: timesheetOvertimeSubmissionId,
				approver_id: currentApprover?.id,
				day_type_id: timesheetOvertimeFormValues?.day_type_id,
			};
		}

		return {
			id: timesheetOvertimeFormValues?.id,
			org_task_id: timesheetOvertimeFormValues?.task_id,
			master_project_task_id: null,
			project_id: null,
			task_id: null,
			notes: timesheetOvertimeFormValues?.notes,
			user_id: userId,
			working_hours: workingHours,
			date: selectedDate,
			is_billable: selectedTask?.is_billable,
			timesheet_submission_id: timesheetOvertimeSubmissionId,
			approver_id: currentApprover?.id,
			day_type_id: timesheetOvertimeFormValues?.day_type_id,
		};
	};

	const handleSubmit = (
		timesheetOvertimeFormValues: TimesheetOvertimeFormValues
	) => {
		if (!timesheetOvertimeFormValues || !totalHours) {
			return;
		}

		if (isDailyHoursLimitExceed(timesheetOvertimeFormValues.working_hours)) {
			setIsDayHoursLimitExceed(true);
			return;
		}
		if (
			timesheetOvertimeFormValues.working_hours === '00:00' ||
			Number(timesheetOvertimeFormValues.working_hours) === 0 ||
			checkIfOnlyZeroesWithOneColon.test(
				timesheetOvertimeFormValues.working_hours
			)
		) {
			setIsZeroWorkingHoursError(true);
			return;
		}

		const isTimeInputError = checkIfTimeInputValueHasError(
			timesheetOvertimeFormValues.working_hours
		);

		if (isTimeInputError) {
			setIsWorkingHoursError(true);
			return;
		}
		const timesheetPayload = getTimesheetPayload(timesheetOvertimeFormValues);

		upsertTimesheetOvertime({
			variables: {
				timesheet: {
					...timesheetPayload,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					if (onSuccess) {
						onSuccess();
						notify(
							initialValues?.id
								? OVERTIME_UPDATE_NOTIFICATION_TEXT
								: OVERTIME_ADD_NOTIFICATION_TEXT
						);
						refresh();
						handleClose();
						return;
					}
					notify(
						initialValues?.id
							? OVERTIME_UPDATE_NOTIFICATION_TEXT
							: OVERTIME_ADD_NOTIFICATION_TEXT
					);
					refresh();
					handleClose();
					return;
				}
			})
			.catch((error) => {
				if (!error) {
					return;
				}
				if (
					error.message.includes('timesheet_task_id_user_id_org_id_date_key')
				) {
					setIsTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_date_master_project_task_id_user_id_org_id_key'
					)
				) {
					setIsMasterProjectTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_org_id_user_id_date_master_project_task_id_project_id'
					)
				) {
					setIsMasterProjectTaskUniqueViolationError(true);
					return;
				}
				if (
					error.message.includes(
						'timesheet_date_org_task_id_user_id_org_id_key'
					)
				) {
					setIsOrgTaskUniqueViolationError(true);
					return;
				}
			});
	};

	const handleClose = () => {
		setSelectedProject('');
		setOrgTaskCategory('');
		setIsDayHoursLimitExceed(false);
		setIsTaskUniqueViolationError(false);
		setIsMasterProjectTaskUniqueViolationError(false);
		setIsOrgTaskUniqueViolationError(false);
		setIsZeroWorkingHoursError(false);
		setWorkingHourInputError('');
		setIsWorkingHoursError(false);
		setCurrentApprover(null);
		onClose();
	};

	const validateTimesheetOvertimeEntryFormValues = async () => {
		const timesheetEntryForm = {} as TimesheetOvertimeFormValues;
		if (isDayHoursLimitExceed) {
			timesheetEntryForm.working_hours = 'Daily hours limit exceed!';
		}
		if (isTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isMasterProjectTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isOrgTaskUniqueViolationError) {
			timesheetEntryForm.task_id = 'Task already entered!';
		}
		if (isZeroWorkingHoursError) {
			timesheetEntryForm.working_hours = 'Zero Hour will not be taken!';
		}
		if (isWorkingHoursError) {
			timesheetEntryForm.working_hours = workingHourInputError;
		}
		return timesheetEntryForm;
	};

	const handleWeeklyFormSubmit = (formValues: TimesheetOvertimeFormValues) => {
		if (!formValues) {
			return;
		}

		if (
			timesheetOvertimeEntries.filter(
				(task) =>
					task.taskId === formValues.task_id &&
					(task.projectId === formValues.project_id ||
						task.projectName === formValues.project_id)
			).length > 0
		) {
			setIsTaskUniqueViolationError(true);
			return;
		}
		const project = projectOptions?.find(
			(project) => project.id === formValues.project_id
		);
		const taskOptions = getTaskOptionRenderer();

		const selectedTask =
			taskOptions && taskOptions.find((task) => task.id === formValues.task_id);

		onSubmit({
			projectId: formValues.project_id,
			projectName: project?.name,
			taskId: selectedTask?.id,
			taskName: selectedTask?.name,
			companyName: project?.companyName,
			taskType: selectedTask?.taskType,
			isBillable: selectedTask?.is_billable,
			timesheetSubmissionId: timesheetOvertimeSubmissionId,
			notes: formValues.notes,
		});
		handleClose();
		return;
	};

	const getOptionTaskAutoCompleteIntialValue = (taskId: string) => {
		const taskoptions = getTaskOptionRenderer();
		return taskoptions?.find((value) => value.id === taskId) || null;
	};

	const getProjectIntialValue = (projectId: string) => {
		const project = projectOptions?.find((value) => value.id === projectId);
		return project
			? {
					id: project.id,
					name: project.isBillable ? project.name : `${project.name} (NB)`,
			  }
			: null;
	};

	const customValidation = (note: string) => {
		if (!note || note?.trim() === '') {
			return 'Required';
		}
		return undefined;
	};

	useEffect(() => {
		if (!timesheetOvertimeApproverOptions) {
			return;
		}
		if (initialValues?.id) {
			const defaultApproverId = timesheetOvertimeApproverOptions?.find(
				(usr) => usr?.id === initialValues?.approver_id
			);
			setCurrentApprover(defaultApproverId);
			return;
		}
		const defaultApproverId = timesheetOvertimeApproverOptions?.find(
			(usr) => usr?.type === 'reporting_manager'
		);
		setCurrentApprover(defaultApproverId);
	}, [timesheetOvertimeApproverOptions, open, initialValues]);

	const getOptionDayTypeAutoCompleteIntialValue = (id: string) => {
		const daytypeOptions = getDayTypeOptionRenderer();
		return daytypeOptions?.find((value) => value.id === id) || null;
	};

	const getDayTypeOptionRenderer = () => {
		if (!dayTypeOptions) {
			return;
		}
		const dayTypeOptionList = dayTypeOptions?.timesheet_day_type?.map(
			(dayType) => {
				return {
					id: dayType?.id,
					name: dayType?.name,
				};
			}
		);
		return dayTypeOptionList;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box
					className={`${formStyles.container} ${timesheetStyles.timesheetFormContainer}`}
				>
					<Box className={formStyles.headerContainer} marginBottom='20px'>
						<Typography className={formStyles.heading}>
							{initialValues?.id ? 'EDIT ENTRY' : 'ADD ENTRY'}
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={
							initialValues?.id ? initialValues : { working_hours: '' }
						}
						onSubmit={
							!isWeeklyTimesheetForm ? handleSubmit : handleWeeklyFormSubmit
						}
						validate={validateTimesheetOvertimeEntryFormValues}
						mutators={{
							clearTaskId: (args, state, utils) => {
								utils.changeValue(state, 'task_id', () => null);
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, values, form }) => (
							<form onSubmit={handleSubmit}>
								<Box display='flex' alignItems='center' mb={1} mt={1}>
									{!isWeeklyTimesheetForm && (
										<Box
											display='flex'
											alignItems='center'
											justifyContent='space-between'
											width='100%'
											height='24px'
										>
											<Box display='flex' alignItems='center'>
												<CalendarTodayIcon
													className={timesheetFormStyles.calenderIcon}
												/>
												<Typography className={timesheetStyles.formSubheading}>
													{dayjs(selectedDate).format('dddd, D MMM')}
												</Typography>
											</Box>
											<Box width={'38%'}>
												<Typography className={formStyles.label}>
													Approver*
												</Typography>
												<Field name='approver'>
													{(props: any) => (
														<AutocompleteSearch
															placeholder={'Search approver'}
															option={timesheetOvertimeApproverOptions || []}
															onChange={(event) => {
																const selectedApprover =
																	timesheetOvertimeApproverOptions?.find(
																		(usr) => usr?.id === event
																	);
																setCurrentApprover(selectedApprover);
															}}
															value={{
																id: currentApprover?.id,
																name: currentApprover?.name,
															}}
															name={'approver'}
															validates={false}
														/>
													)}
												</Field>
												{!currentApprover && (
													<p className={customStyle.errorText}>
														{'Choose an approver'}
													</p>
												)}
											</Box>
										</Box>
									)}
								</Box>

								<Box>
									<Typography className={formStyles.label}>
										Choose Project*
									</Typography>
									<Field name='project_id' validate={required()}>
										{(props: any) => (
											<ThemeProvider theme={autoCompleteTheme}>
												<div
													className={timesheetFormStyles.customFieldContainer}
												>
													<Autocomplete
														id='project_id'
														classes={{
															option: timesheetFormStyles.options,
															groupLabel: timesheetFormStyles.groupLabel,
															inputRoot: timesheetFormStyles.inputRoot,
															noOptions: timesheetFormStyles.noOptions,
															focused: timesheetFormStyles.focused,
															input: timesheetFormStyles.input,
															endAdornment: timesheetFormStyles.endAdornment,
														}}
														getOptionSelected={(option, value) =>
															option.id === props.input.value
														}
														onChange={(event, newValue) => {
															form.mutators.clearTaskId();
															props.input.onChange(newValue?.id);
															setIsTaskUniqueViolationError(false);
															setIsMasterProjectTaskUniqueViolationError(false);
															setIsOrgTaskUniqueViolationError(false);
															setSelectedProject(newValue?.id || '');
															setSelectedTaskCategory(newValue?.id || '');
														}}
														value={getProjectIntialValue(props.input.value)}
														options={
															projectOptions
																? projectOptions.map((project) => ({
																		id: project.id,
																		name: project.isBillable
																			? project.name
																			: `${project.name} (NB)`,
																  }))
																: []
														}
														getOptionLabel={(option) => option?.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																label=''
																placeholder='Search project'
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			classes={{
																				root: timesheetFormStyles.inputAdronment,
																				positionStart:
																					timesheetFormStyles.inputAdronmentPositionStart,
																			}}
																		>
																			<SearchIcon
																				className={
																					timesheetFormStyles.searchIconPositionStart
																				}
																			/>
																		</InputAdornment>
																	),
																}}
															/>
														)}
													/>
												</div>
											</ThemeProvider>
										)}
									</Field>
									<Typography className={formStyles.label}>
										Choose Task*
									</Typography>
									<Field name='task_id' validate={required()}>
										{(props: any) => (
											<ThemeProvider theme={autoCompleteTheme}>
												<div
													className={timesheetFormStyles.customFieldContainer}
												>
													<Autocomplete
														id='task_id'
														classes={{
															option: timesheetFormStyles.options,
															groupLabel: timesheetFormStyles.groupLabel,
															inputRoot: timesheetFormStyles.inputRoot,
															noOptions: timesheetFormStyles.noOptions,
															focused: timesheetFormStyles.focused,
															input: timesheetFormStyles.input,
															endAdornment: timesheetFormStyles.endAdornment,
														}}
														loading={isProjectTaskLoading}
														getOptionSelected={(option, value) =>
															option.id === props.input.value
														}
														onChange={(event, newValue) => {
															props.input.onChange(newValue?.id);
															setIsTaskUniqueViolationError(false);
															setIsMasterProjectTaskUniqueViolationError(false);
															setIsOrgTaskUniqueViolationError(false);
														}}
														value={getOptionTaskAutoCompleteIntialValue(
															props.input.value
														)}
														options={getTaskOptionRenderer() || []}
														groupBy={(option) => option.groupByLabel}
														getOptionLabel={(option) => option?.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																label=''
																placeholder='Search your task'
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			classes={{
																				root: timesheetFormStyles.inputAdronment,
																				positionStart:
																					timesheetFormStyles.inputAdronmentPositionStart,
																			}}
																		>
																			<SearchIcon
																				className={
																					timesheetFormStyles.searchIconPositionStart
																				}
																			/>
																		</InputAdornment>
																	),
																}}
															/>
														)}
													/>
													{(isTaskUniqueViolationError ||
														isMasterProjectTaskUniqueViolationError ||
														isOrgTaskUniqueViolationError) && (
														<Typography className={timesheetStyles.errorText}>
															{props.meta.error}
														</Typography>
													)}
												</div>
											</ThemeProvider>
										)}
									</Field>
									{isTrackDayTypeEnabled && (
										<>
											<Typography className={formStyles.label}>
												{`Choose Day Type ${isTrackDayTypeEnabled && '*'}`}
											</Typography>
											<Field name='day_type_id' validate={required()}>
												{(props: any) => (
													<ThemeProvider theme={autoCompleteTheme}>
														<div
															className={
																timesheetFormStyles.customFieldContainer
															}
														>
															<Autocomplete
																id='task_id'
																classes={{
																	option: timesheetFormStyles.options,
																	groupLabel: timesheetFormStyles.groupLabel,
																	inputRoot: timesheetFormStyles.inputRoot,
																	noOptions: timesheetFormStyles.noOptions,
																	focused: timesheetFormStyles.focused,
																	input: timesheetFormStyles.input,
																	endAdornment:
																		timesheetFormStyles.endAdornment,
																}}
																loading={isDayTypeLoading}
																getOptionSelected={(option, value) =>
																	option.id === props.input.value
																}
																onChange={(event, newValue) => {
																	props.input.onChange(newValue?.id);
																}}
																value={getOptionDayTypeAutoCompleteIntialValue(
																	props.input.value
																)}
																options={getDayTypeOptionRenderer() || []}
																getOptionLabel={(option) => option?.name || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search day type'
																		InputProps={{
																			...params.InputProps,
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					classes={{
																						root: timesheetFormStyles.inputAdronment,
																						positionStart:
																							timesheetFormStyles.inputAdronmentPositionStart,
																					}}
																				>
																					<SearchIcon
																						className={
																							timesheetFormStyles.searchIconPositionStart
																						}
																					/>
																				</InputAdornment>
																			),
																		}}
																	/>
																)}
															/>
														</div>
													</ThemeProvider>
												)}
											</Field>
										</>
									)}
									<Typography className={formStyles.label}>Notes*</Typography>
									<TextInput
										multiline
										label=''
										validate={customValidation}
										source='notes'
										fullWidth
									/>

									<Box
										className={timesheetFormStyles.timesheetFormActionContainer}
										mt={4}
									>
										<Box>
											{!isWeeklyTimesheetForm && (
												<Field name='working_hours'>
													{(props) => (
														<div>
															<TimeInput
																name={props.input.name}
																value={props.input.value}
																onChange={(e) => {
																	props.input.onChange(e);
																	setIsDayHoursLimitExceed(false);
																	setIsZeroWorkingHoursError(false);
																	setWorkingHourInputError('');
																	setIsWorkingHoursError(false);
																}}
																isError={isWorkingHoursError}
															/>
															<Typography className={timesheetStyles.errorText}>
																{props.meta.error}
															</Typography>
														</div>
													)}
												</Field>
											)}
										</Box>
										<Box display='flex' alignItems='center'>
											<Button
												onClick={handleClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine || isLoading || !currentApprover
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid || pristine || !currentApprover || isLoading
												}
												type='submit'
											>
												{initialValues?.id ? `Update` : `Save`}
											</Button>
										</Box>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default TimesheetOvertimeForm;
