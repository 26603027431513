import React from 'react';
import AvatarField from './AvatarField.component';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	avatar: {
		marginRight: theme.spacing(1),
		marginTop: -theme.spacing(0.5),
		marginBottom: -theme.spacing(0.5),
		fontSize: '14px',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	fullname: {
		width: '150px',
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
}));

const FullNameField = ({ record, size }) => {
	const classes = useStyles();
	return (
		record && (
			<div className={classes.root}>
				<AvatarField
					className={classes.avatar}
					imageSrc={record?.profile_pic}
					firstName={record?.first_name}
					lastName={record?.last_name}
					size={size}
				/>
				<Tooltip title={`${record?.full_name || ''}`} placement='right'>
					<Typography className={`${classes.fullname} ${classes.ellipsis}`}>
						{`${record?.full_name || ''}`}
					</Typography>
				</Tooltip>
			</div>
		)
	);
};

FullNameField.defaultProps = { label: 'Full Name' };

export default FullNameField;
