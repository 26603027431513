import React, { useState, useEffect } from 'react';
import { TextInput, required, useNotify, maxLength } from 'react-admin';

import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import { modalFormStyle } from '../../../../Layout/styles';
import { settingsStyle } from '../../settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { UPSERT_JOB_LEVEL } from '../../Settings.gql';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { validateDescription, validateName } from '../../constant';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

interface FormValue {
	id?: string;
	level?: string;
	description?: string;
	created_at?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}
const JobLevelForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const [initialFormValues, setInitialFormValues] = useState<FormValue>({});
	const [upsertJobLevel, { loading: isJobLevelLoading }] =
		useApolloMutation(UPSERT_JOB_LEVEL);
	const [isNameUniqueViolationError, setIsNameUniqueViolationError] =
		useState(false);
	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setInitialFormValues({ ...initialValues });
	}, [initialValues]);

	const closeModal = () => {
		setInitialFormValues({});
		onClose();
	};

	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}

		const { created_at, description, level, ...restFormValues } = formValues;
		const name = level?.trim();
		const jobLevelDescription = description?.trim();
		upsertJobLevel({
			variables: {
				job_level: {
					...restFormValues,
					level: name,
					description: jobLevelDescription || null,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify('Job Level updated Successfully');
						refetch();
						closeModal();
					} else {
						notify('Job Level Created Successfully');
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error?.message.includes('UQ_43dad66a0ff9ad857dd8a3aac18')) {
					setIsNameUniqueViolationError(true);
					return;
				}
				notify(error?.message, 'warning');
			});
	};
	const validateFormValues = async () => {
		const formErrors = {} as FormValue;
		if (isNameUniqueViolationError) {
			formErrors.level = 'Job level already exist!';
		}
		return formErrors;
	};

	const levelValidate = [
		required(),
		validateName,
		maxLength(10, 'Characters must be below 10'),
	];

	const descriptionValidate = [validateDescription ,maxLength(50, 'Characters must be below 50')];
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Typography className={modalFormStyles.heading}>
							{isEditMutation
								? `Edit: ${initialValues?.level}`
								: `Add Job Level`}
						</Typography>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialFormValues}
						onSubmit={onHandleSubmit}
						validate={validateFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Level *
									</Typography>
									<TextInput
										source='level'
										label=''
										validate={levelValidate}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
									<Typography className={modalFormStyles.label}>
										Description
									</Typography>
									<TextInput
										source='description'
										label=''
										multiline={true}
										onChange={() => setIsNameUniqueViolationError(false)}
										validate={descriptionValidate}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={isEditMutation ? 'Update' : 'Add'}
										isDisabled={invalid || pristine || isJobLevelLoading}
										isLoading={isJobLevelLoading}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default JobLevelForm;
