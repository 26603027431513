import React, { useState, useEffect, useContext } from 'react';
import { head, keyBy } from 'lodash';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import dayjs, { Dayjs } from 'dayjs';
import { Attendance, Date, SelectedMonthDates } from './Attendance.model';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Holiday } from './Attendance.model';
import DayjsUtils from '@date-io/dayjs';
import { calenderTheme } from '../../Layout/Theme.component';
import {
	useGetAttendanceTotalQuery,
	useGetOrganisationManHoursQuery,
	useGetHolidaysQuery,
	useGetTimesheetListQuery,
	useGetOrgHolidaysByUserQuery,
	useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery,
	useGetLeaveSettingsSubscription,
	useGetEmployeeJoinDateByUserIdQuery,
	useGetOrganizationWeekendsQuery,
} from '../../generated/graphql';
import ApplyLeaveForm from './ApplyLeaveForm.component';
import { ellipsisStyle } from '../../Layout/styles';
import { modalFormStyle } from '../../Layout/styles';
import {
	getSaturdaysDates,
	getSundaysBetweenDates,
} from '../../Utils/date-time.util';
import { Style } from './Style';
import { UserProfileContext } from '../../App';
import {
	Absent,
	holidayText,
	Leave,
	Present,
	Weekend,
	calendarText,
	halfDay,
} from './Constant';
interface LeaveDate {
	date: string;
	halfDay: number;
	reason: string;
	leave_type: string;
}

interface Props {
	setCurrentMonth: (startDate: string, endDate: string) => void;
	currentMonth: SelectedMonthDates;
	userId: string;
	selectedAttendanceMonth?: string;
	isManagerView?: boolean;
	isReportingManagerView?: boolean;
	workHoursLogged: number;
	leaveApprover: string;
	currentFinancialYear: SelectedMonthDates;
	orgId: string;
	timesheetHours?: number;
	currentLeaveCount?: number;
	employeeId: string;
	refetchLeaveBalance: () => void;
	userLocationId?: string | null;
	leaveData?: LeaveDate[];
}

const Calender = (props: Props) => {
	const {
		setCurrentMonth,
		currentMonth,
		userId,
		isManagerView,
		isReportingManagerView,
		selectedAttendanceMonth,
		leaveApprover,
		workHoursLogged,
		orgId,
		currentFinancialYear,
		refetchLeaveBalance,
		timesheetHours,
		currentLeaveCount,
		employeeId,
		userLocationId,
	} = props;
	const [calenderDate, setCalenderDate] = useState(dayjs());
	const [weekendDates, setWeekendDates] = useState<string[]>([]);
	const [organizationHoliday, setOrganizationHoliday] = useState<any>({});
	const [employeeLeave, setEmployeeLeave] = useState<any>({});
	const [organizationAttendance, setOrganizationAttendance] = useState<Date>(
		{}
	);
	const [timesheetAttendance, setTimesheetAttendance] = useState<Date>({});
	const [open, setOpen] = useState(false);
	const [bussinessDays, setBusinessDays] = useState(0);
	const classes = Style();
	const modalFormStyles = modalFormStyle();
	const style = ellipsisStyle();
	const {
		locationId,
		noOfLocations,
		orgId: organisationId,
		attendanceConsiderableTimesheetStatus
	} = useContext<any>(UserProfileContext);

	const { data: leaveDetails } = useGetLeaveSettingsSubscription({
		variables: {
			orgId,
		},
		fetchPolicy: 'network-only',
	});
	const { data: user, loading: joinDateLoader } =
		useGetEmployeeJoinDateByUserIdQuery({
			variables: { user_id: userId },
			fetchPolicy: 'network-only',
		});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;
	const currentDate = dayjs().format('YYYY-MM-DD');

	const enableLeave = !joinDateLoader
		? !(currentDate < userJoinDate) &&
		  (leaveDetails?.organization_by_pk?.enable_leave || false)
		: false;
	const { data: organizationManHours } = useGetOrganisationManHoursQuery({
		variables: {
			orgId,
		},
	});

	const { data: orgWorkingHours } =
		useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery({
			variables: {
				userId,
				orgId: organisationId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
				statusList: attendanceConsiderableTimesheetStatus
			},
		});

	const { data: holdayCount } = useGetHolidaysQuery({
		variables: {
			filter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								is_restricted: { _eq: false },
								location_id: { _eq: userLocationId },
						  }
						: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								is_restricted: { _eq: false },
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							is_restricted: { _eq: false },
							location_id: { _eq: locationId },
					  }
					: {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
									location_id: { _eq: userLocationId },
								},
						  }
						: {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
								},
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
							},
							user_id: { _eq: userId },
					  },
		},
		fetchPolicy: 'network-only',
		skip:
			isManagerView || isReportingManagerView
				? !userLocationId && noOfLocations && noOfLocations > 0
				: !locationId && noOfLocations && noOfLocations > 0,
	});
	const { data: timesheetList } = useGetTimesheetListQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
			statusList: attendanceConsiderableTimesheetStatus
		},
	});
	const { data: organizationWeekendData } = useGetOrganizationWeekendsQuery(
		{
			variables: { orgId },
			fetchPolicy: 'network-only',
		}
	);
	useEffect(() => {
		if (!orgWorkingHours?.workHours || !organizationWeekendData) {
			setWeekendDates([]);
			return;
		}
		const response = orgWorkingHours?.workHours?.working_hours || 0;
		const isSundayAvailable =
			organizationWeekendData?.organization_by_pk?.weekend?.includes('SUN');
		const sundayAsWeekend = getSundaysBetweenDates(
			currentMonth?.startDate,
			currentMonth?.endDate
		);
		const availableSaturdays =
			organizationWeekendData?.organization_by_pk?.weekend
				?.filter((weekendDay: string) => weekendDay !== 'SUN')
				?.map((sat: string) => sat?.charAt(sat.length - 1));
		const allSaturdaysBetweenDates = getSaturdaysDates(
			currentMonth?.startDate,
			currentMonth?.endDate
		);

		const saturdayAsWeekend = allSaturdaysBetweenDates
			?.map((day) =>
				availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
			)
			?.filter((date) => date !== '');
		const weekends = isSundayAvailable
			? [...saturdayAsWeekend, ...sundayAsWeekend]
			: [...saturdayAsWeekend];
		setWeekendDates(weekends);
		const buisnessDays =
			dayjs(currentMonth?.endDate).diff(dayjs(currentMonth?.startDate), 'day') +
			1 -
			weekends?.length;
		const orgHolidaysArray = holdayCount?.org_holidays ?? [];
		const optionalHolidaysArray =
			holdayCount?.user_optional_holiday_mapping ?? [];
		const filteredOrgHolidaysDates = orgHolidaysArray
			?.filter((holidays) => {
				return Array.isArray(weekends) && !weekends.includes(holidays?.date);
			})
			.map((holidays) => holidays?.date);
		const filteredOptionalHolidaysDates = optionalHolidaysArray
			.filter((optionalHoliday) => {
				const date = optionalHoliday?.org_holiday?.date;
				return Array?.isArray(weekends) && !weekends?.includes(date);
			})
			.map((optionalHoliday) => optionalHoliday?.org_holiday?.date);

		const finalFilteredHolidays = Array.from(
			new Set([...filteredOrgHolidaysDates, ...filteredOptionalHolidaysDates])
		);
		const days =
			isManagerView || isReportingManagerView
				? !userLocationId && noOfLocations && noOfLocations > 0
					? buisnessDays * response
					: (buisnessDays - Number(finalFilteredHolidays?.length || 0)) *
					  response
				: !locationId && noOfLocations && noOfLocations > 0
				? buisnessDays * response
				: (buisnessDays - Number(finalFilteredHolidays?.length || 0)) *
				  response;
		if (!days) {
			setBusinessDays(0);
			return;
		}
		setBusinessDays(days);
	}, [
		organizationManHours,
		holdayCount,
		currentMonth,
		locationId,
		noOfLocations,
		orgWorkingHours,
		isManagerView,
		isReportingManagerView,
		userLocationId,
		organizationWeekendData,
	]);

	const { data: selectedMonthAttendance } = useGetAttendanceTotalQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
		},
		fetchPolicy: 'network-only',
		skip: !(userId && currentMonth),
	});

	const { data: holidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								is_restricted: { _eq: false },
								location_id: { _eq: userLocationId },
						  }
						: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								is_restricted: { _eq: false },
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							location_id: { _eq: locationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
									location_id: { _eq: userLocationId },
								},
						  }
						: {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
								},
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip:
			isManagerView || isReportingManagerView
				? !userLocationId && noOfLocations && noOfLocations > 0
				: !locationId && noOfLocations && noOfLocations > 0,
	});
	useEffect(() => {
		if (!timesheetList || !(timesheetList?.timesheet?.length > 0)) {
			setTimesheetAttendance({});
			return;
		}
		const attendance = timesheetList?.timesheet.map(
			(value: Attendance) => value.date
		);
		const timesheetLookup = keyBy(attendance);
		setTimesheetAttendance(timesheetLookup);
	}, [timesheetList]);

	useEffect(() => {
		if (currentMonth && isManagerView) {
			if (currentMonth?.startDate) {
				setCalenderDate(dayjs(currentMonth?.startDate));
			}
		}
	}, [isManagerView, currentMonth]);

	useEffect(() => {
		if (currentMonth && isReportingManagerView) {
			if (currentMonth?.startDate) {
				setCalenderDate(dayjs(currentMonth?.startDate));
			}
		}
	}, [isReportingManagerView, currentMonth]);
	useEffect(() => {
		if (!holidays || !userId) {
			setOrganizationHoliday([]);
			return;
		}
		const optionalHoliday = holidays?.user_optional_holiday_mapping
			? holidays?.user_optional_holiday_mapping?.map(
					(holiday) => holiday?.org_holiday?.date
			  )
			: [];
		const holiday = holidays?.org_holidays
			? holidays?.org_holidays?.map((holiday: Holiday) => holiday?.date)
			: [];
		const holidayLookup = keyBy([...holiday, ...optionalHoliday]);
		isManagerView || isReportingManagerView
			? !userLocationId && noOfLocations && noOfLocations > 0
				? setOrganizationHoliday([])
				: setOrganizationHoliday(holidayLookup)
			: !locationId && noOfLocations && noOfLocations > 0
			? setOrganizationHoliday([])
			: setOrganizationHoliday(holidayLookup);
	}, [
		holidays,
		isManagerView,
		isReportingManagerView,
		locationId,
		noOfLocations,
		userId,
		userLocationId,
	]);

	useEffect(() => {
		if (!props?.leaveData || !(props?.leaveData?.length > 0)) {
			return;
		}
		const leaveDay = props?.leaveData?.map((item: LeaveDate) => item?.date);
		const leaveDaysLookup = keyBy(leaveDay);
		setEmployeeLeave(leaveDaysLookup);
	}, [props]);

	useEffect(() => {
		if (
			!selectedMonthAttendance ||
			!(selectedMonthAttendance?.attendance?.length > 0)
		) {
			setOrganizationAttendance({});
			return;
		}
		const attendance = selectedMonthAttendance?.attendance.map(
			(value: Attendance) => value.date
		);
		const attendanceLookup = keyBy(attendance);
		setOrganizationAttendance(attendanceLookup);
	}, [selectedMonthAttendance]);

	const onDateChange = (date: Dayjs | null) => {
		if (!date) {
			return;
		}
		setCalenderDate(date);
	};

	const renderDay = (
		day: any,
		selectedDate: any,
		dayInCurrentMonth: any,
		dayComponent: any
	) => {
		const calenderDate = day.format('YYYY-MM-DD');
		if (
			!(selectedAttendanceMonth && userId) &&
			(isManagerView || isReportingManagerView)
		) {
			return (
				<Tooltip
					title={Weekend}
					placement='right'
					classes={{ tooltip: classes?.customTooltip }}
				>
					<Box className={classes?.weekend}>{dayComponent}</Box>
				</Tooltip>
			);
		}
		if (employeeLeave && !!employeeLeave[calenderDate]) {
			const LeaveDate = props?.leaveData?.length
				? props?.leaveData.filter(
						(item: LeaveDate) => item?.date === calenderDate
				  )
				: [];
			if (LeaveDate && LeaveDate?.length > 0) {
				const leaveType = (head(LeaveDate) as LeaveDate)?.leave_type || '';
				const leaveTime =
					(head(LeaveDate) as LeaveDate)?.halfDay === 0.5 ? halfDay : '';

				return (
					<Tooltip
						title={`${leaveType} ${leaveTime}` || '--'}
						placement='right'
						classes={{ tooltip: classes?.customTooltip }}
					>
						<Box className={classes?.employeeLeave}>{dayComponent}</Box>
					</Tooltip>
				);
			}
		}
		if (
			(!!organizationAttendance[calenderDate] ||
				timesheetAttendance[calenderDate]) &&
			dayjs(calenderDate).isSameOrBefore(dayjs(), 'day')
		) {
			const optionalHolidays = holidays?.user_optional_holiday_mapping
				? holidays?.user_optional_holiday_mapping?.map(
						(holiday) => holiday?.org_holiday
				  )
				: [];
			const holidaysForDate =
				[...(holidays?.org_holidays || []), ...optionalHolidays].filter(
					(holiday) => holiday?.date === calenderDate
				) || [];
			if (
				holidaysForDate &&
				holidaysForDate?.length > 0 &&
				organizationHoliday[calenderDate]
			) {
				const holidayNames = holidaysForDate?.map(
					(holiday) => holiday?.name || '--'
				);
				const holidayLocation = holidaysForDate?.map(
					(holiday) => holiday?.org_location?.name || holidayText
				);

				return (
					<Tooltip
						title={`${Present} (${holidayLocation} : ${holidayNames})`}
						placement='right'
						classes={{ tooltip: classes.customTooltip }}
					>
						<Box className={classes.present}>{dayComponent}</Box>
					</Tooltip>
				);
			}
			if (
				employeeLeave &&
				!!employeeLeave[calenderDate] &&
				(!!organizationAttendance[calenderDate] ||
					timesheetAttendance[calenderDate]) &&
				!dayjs().isSameOrBefore(calenderDate, 'day')
			) {
				const LeaveDate = props?.leaveData?.length
					? props?.leaveData.filter(
							(item: LeaveDate) => item?.date === calenderDate
					  )
					: [];
				if (LeaveDate && LeaveDate?.length > 0) {
					const leaveType = (head(LeaveDate) as LeaveDate)?.leave_type || '';
					const leaveTime =
						(head(LeaveDate) as LeaveDate)?.halfDay === 0.5 ? halfDay : '';

					return (
						<Tooltip
							title={`${leaveType}${leaveTime}` || '--'}
							placement='right'
							classes={{ tooltip: classes?.customTooltip }}
						>
							<Box className={classes?.employeeLeave}>{dayComponent}</Box>
						</Tooltip>
					);
				}
			}
			if (weekendDates?.includes(calenderDate)) {
				if (
					(!!organizationAttendance[calenderDate] ||
						timesheetAttendance[calenderDate]) &&
					!dayjs().isSameOrBefore(calenderDate, 'day')
				) {
					return (
						<Tooltip
							title={`${Present} (${Weekend})`}
							placement='right'
							classes={{ tooltip: classes?.customTooltip }}
						>
							<Box className={classes?.present}>{dayComponent}</Box>
						</Tooltip>
					);
				}
			} else {
				return (
					<Tooltip
						title={`${Present}`}
						placement='right'
						classes={{ tooltip: classes?.customTooltip }}
					>
						<Box className={classes?.present}>{dayComponent}</Box>
					</Tooltip>
				);
			}
		}
		if (organizationHoliday && organizationHoliday[calenderDate]) {
			const optionalHolidays = holidays?.user_optional_holiday_mapping
				? holidays?.user_optional_holiday_mapping?.map(
						(holiday) => holiday?.org_holiday
				  )
				: [];
			const holidaysForDate =
				[...(holidays?.org_holidays || []), ...optionalHolidays]?.filter(
					(holiday) => holiday?.date === calenderDate
				) || [];
			if (holidaysForDate && holidaysForDate?.length > 0) {
				const holidayNames =
					holidaysForDate?.map((holiday) => holiday?.name) || '';
				const holidayLocation = holidaysForDate?.map(
					(holiday) => holiday?.org_location?.name || holidayText
				);

				return (
					<Tooltip
						title={`${holidayLocation} : ${holidayNames}` || '--'}
						placement='right'
						classes={{ tooltip: classes?.customTooltip }}
					>
						<Box className={classes?.holiday}>{dayComponent}</Box>
					</Tooltip>
				);
			}
		}
		if (employeeLeave && !!employeeLeave[calenderDate]) {
			const LeaveDate = props?.leaveData?.length
				? props?.leaveData.filter(
						(item: LeaveDate) => item?.date === calenderDate
				  )
				: [];
			if (LeaveDate && LeaveDate?.length > 0) {
				const leaveType = (head(LeaveDate) as LeaveDate)?.leave_type || '';
				const leaveTime =
					(head(LeaveDate) as LeaveDate)?.halfDay === 0.5 ? halfDay : '';

				return (
					<Tooltip
						title={`${leaveType} ${leaveTime}` || '--'}
						placement='right'
						classes={{ tooltip: classes?.customTooltip }}
					>
						<Box className={classes?.employeeLeave}>{dayComponent}</Box>
					</Tooltip>
				);
			}
		}
		//will change this after getting api for week days
		if (weekendDates?.includes(calenderDate)) {
			if (
				(!!organizationAttendance[calenderDate] ||
					timesheetAttendance[calenderDate]) &&
				!dayjs().isSameOrBefore(calenderDate, 'day')
			) {
			}
			return (
				<Tooltip
					title={Weekend}
					placement='right'
					classes={{ tooltip: classes?.customTooltip }}
				>
					<Box className={classes?.weekend}>{dayComponent}</Box>
				</Tooltip>
			);
		}

		if (
			!dayjs().isBefore(calenderDate, 'day') &&
			!dayjs().isSame(calenderDate, 'day') &&
			!organizationAttendance[calenderDate] &&
			organizationHoliday &&
			!organizationHoliday[calenderDate]
		) {
			return (
				<Tooltip
					title={Absent}
					placement='right'
					classes={{ tooltip: classes?.customTooltip }}
				>
					<Box className={classes?.absent}>{dayComponent}</Box>
				</Tooltip>
			);
		}
		if (
			(!!organizationAttendance[calenderDate] ||
				timesheetAttendance[calenderDate]) &&
			dayjs().isSame(calenderDate, 'day')
		) {
			return (
				<Tooltip
					title={`${Present}`}
					placement='right'
					classes={{ tooltip: classes?.customTooltip }}
				>
					<Box className={classes?.present}>{dayComponent}</Box>
				</Tooltip>
			);
		}
		return <Box className={classes?.calender}>{dayComponent}</Box>;
	};

	return (
		<>
			<Box className={classes?.calenderTitle}>{calendarText}</Box>
			<Box display='flex' justifyContent='space-evenly'>
				<Box>
					<ThemeProvider theme={calenderTheme}>
						<MuiPickersUtilsProvider utils={DayjsUtils}>
							<KeyboardDatePicker
								variant='static'
								value={calenderDate}
								disableToolbar={true}
								onChange={onDateChange}
								disableFuture={true}
								// disablePast={true}
								renderDay={renderDay}
								onMonthChange={(date: Dayjs | null) => {
									if (!date) {
										return;
									}
									setCalenderDate(date);
									const startDate = date.startOf('month').format('YYYY-MM-DD');
									const endDate = date.endOf('month').format('YYYY-MM-DD');
									setCurrentMonth(startDate, endDate);
								}}
							/>
						</MuiPickersUtilsProvider>
					</ThemeProvider>
					<Box display='flex'>
						<Box className={classes.calenderListWeekend}></Box>
						<Box className={classes.calenderListTitle}>{Weekend}</Box>
						<Box className={classes.calenderListAbsent}></Box>
						<Box className={classes.calenderListTitle}>{Absent}</Box>
						<Box className={classes.calenderListHoliday}></Box>
						<Box className={classes.calenderListTitle}>{holidayText}</Box>
					</Box>
					<Box display='flex' marginTop={1}>
						<Box className={classes.calenderListPresent}></Box>
						<Box className={classes.calenderListTitle}>{Present}</Box>
						<Box className={classes.calenderListLeave} marginLeft={0.9}></Box>
						<Box className={classes.calenderListTitle}>{Leave}</Box>
					</Box>
				</Box>
				<Box className={classes.workingDayContainer}>
					<Box className={classes.workingAttendanceContainer}>
						<Box className={classes.addtionalInfoContainer}>
							<Box className={classes.additionalContent}>
								<Typography className={classes.additionalInfoLabel}>
									Leave Approver
								</Typography>
								<Tooltip title={`${leaveApprover || ' - -'}`} placement='right'>
									<Typography
										className={`${classes.leaveApproverValue} ${style.ellipsis}`}
									>
										{leaveApprover || '-'}
									</Typography>
								</Tooltip>
							</Box>
							<Box className={classes.additionalContent}>
								<Typography className={classes.additionalInfoLabel}>
									Work hours this month
								</Typography>
								<Typography className={classes.additionalInfoValue}>
									{bussinessDays}
								</Typography>
							</Box>
							<Box className={classes.additionalContent}>
								<Typography className={classes.additionalInfoLabel}>
									Attendance hours logged
								</Typography>
								<Typography className={classes.additionalInfoValue}>
									{workHoursLogged || 0}
								</Typography>
							</Box>
							<Box className={classes.additionalContent}>
								<Typography className={classes.additionalInfoLabel}>
									Timesheet hours logged
								</Typography>
								<Typography className={classes.additionalInfoValue}>
									{timesheetHours || 0}
								</Typography>
							</Box>
							{/* Leave Count in Summary will be released with version V.087 */}
							<Box className={classes.additionalContent}>
								<Typography className={classes.additionalInfoLabel}>
									Approved leave(s) this month
								</Typography>
								<Typography className={classes.additionalInfoValue}>
									{currentLeaveCount || 0}
								</Typography>
							</Box>
						</Box>
						{!isReportingManagerView && (
							<Box margin='50px' height='36px'>
								<Button
									className={
										enableLeave
											? userId && employeeId
												? classes.addLeaveButton
												: modalFormStyles?.disabledButton
											: modalFormStyles?.disabledButton
									}
									disabled={
										enableLeave ? !(userId && employeeId) : !enableLeave
									}
									onClick={() => setOpen(true)}
									id='button_apply_leave'
								>
									Apply for Leave
								</Button>
							</Box>
						)}
					</Box>
				</Box>
				<ApplyLeaveForm
					userLocationId={userLocationId}
					open={open}
					onClose={() => setOpen(false)}
					userId={userId}
					refetchLeaveBalance={() => refetchLeaveBalance()}
					currentFinancialYear={currentFinancialYear}
					isEdit={false}
				/>
			</Box>
		</>
	);
};

export default Calender;
