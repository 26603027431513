import React from 'react';
import { useQuery } from 'react-admin';

import { Box,Typography } from '@material-ui/core';
import AssetCard from '../../AssetManagement/AssestCard.component';
import { detailPageAssestTabStyle } from '../../../Layout/styles';


export const EmployeeAssetDetails = (props: any) => {
	const { record: employee } = props;
	const { data: list } = useQuery({
		type: 'GET_LIST',
		resource: 'asset',
		payload: {
			filter: {
				used_by: employee?.user?.id,
			},
		},
	});
	const { data: assetTypeList } = useQuery({
		type: 'GET_LIST',
		resource: 'asset_type',
		payload: {},
	});

	const classes = detailPageAssestTabStyle();
	return (
		<>
			{list?.length > 0 ? (
				<Box className={classes.gridContainer}>
					<Box className={classes.gridList}>
						{list?.map((item: any, index: number) => (
							<AssetCard
								key={index}
								data={item}
								typeList={assetTypeList}
								noRedirect={true}
							/>
						))}
					</Box>
				</Box>
			) : (
				<Typography className={classes.noData}>
					No data to display...
				</Typography>
			)}
		</>
	);
};
