import React from 'react';
import { Loading } from 'react-admin';
import {
	GET_CONSULTANT_BY_ID,
	GET_CONSULTANT_CUSTOM_FIELDS,
} from '../gqlQueries';
import { useQuery as useApolloQuery } from '@apollo/client';
import ConsultantDetailsCard from './ConsultantDetailsCard.component';
import { useParams } from 'react-router-dom';
import { detailsPageGlobalStyle } from '../../../Layout/styles';
import ConsultantDetailContainer from './ConsultantDetailContainer.component';
import Breadcrum from '../../../SharedComponents/Breadcrum.component';
import { breadcumData } from '../Constant';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export const ConsultantDetails = () => {
	const classes = detailsPageGlobalStyle();
	const { id }: { id: string } = useParams();
	const {
		data: consultant,
		loading,
		refetch: refetchConsultant,
	} = useApolloQuery(GET_CONSULTANT_BY_ID, {
		variables: {
			consultantId: id ? id : null,
		},
		fetchPolicy: 'network-only',
	});
	const { data: consultantCustomFields } = useApolloQuery(
		GET_CONSULTANT_CUSTOM_FIELDS,
		{ fetchPolicy: 'network-only' }
	);

	if (loading) {
		return <Loading />;
	}
	const { user } = consultant?.contractor_by_pk;
	return (
		<>
			<Breadcrum
				data={[
					...breadcumData,
					{
						name: `${user?.full_name}`,
					},
				]}
				icon={DoubleArrowIcon}
			/>
			<div className={classes.container}>
				<div className={classes.leftPanel}>
					<ConsultantDetailsCard
						record={consultant?.contractor_by_pk}
						basePath='/consultant'
						refetch={() => refetchConsultant()}
						resource='contractor'
					/>
				</div>

				<div className={classes.rightPanel}>
					<ConsultantDetailContainer
						record={{ ...consultant?.contractor_by_pk, consultantCustomFields }}
						refetch={() => refetchConsultant()}
					/>
				</div>
			</div>
		</>
	);
};

export default ConsultantDetails;
