import React, { useEffect, useState, useContext } from 'react';
import { UserProfileContext } from '../../../App';
import { setSidebarVisibility } from 'react-admin';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';
import ConsultantBasicDetails from './ConsultantBasicDetails.component';
import ConsultantBankDetails from './ConsultantBankDetails.component';
import ConsultantSkillDetail from './ConsultantSkillDetails.component';
import ConsultantHistory from './ConsultantHistory.component';
import { ConsultantAssetDetails } from './ConsultantAssetDetails.component';
import ConsultantNote from './ConsultantNote.component';
import DocumentUploader from './DocumentUploader.component';
import EmployeeProjectList from '../../../SharedComponents/EmployeeProjectList';
import EmployeeHolidayList from '../../../SharedComponents/EmployeeHolidayList.component';
import { EmployeeMyTeam } from '../../../SharedComponents/EmployeeMyTeam';
const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: any) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: '10px',
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Medium',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#898989 ',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '600px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));
interface Agreement {
	end_date: string;
	id: string;
}
interface Source {
	id: string;
}
interface Address {
	houseName: string;
	streetName: string;
	city: string;
	postalCode: string;
	state: string;
	country: string;
}
interface User {
	__typename?: string;
	id: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	full_name: string;
	profile_pic?: string;
	email: string;
	is_active: boolean;
}
interface Status {
	__typename?: string;
	id: string;
	label: string;
}
interface Currency {
	id: string;
	currency_type: string;
	symbol?: string;
}
interface CreatedBy {
	id: string;
	full_name: string;
}
interface CustomField {
	id: string;
	label: string;
	index: number;
	validation: any;
	options: any;
	type: string;
	is_active: boolean;
}
interface customFieldData {
	custom_field: CustomField[];
}
interface Record {
	__typename?: string;
	user: User;
	contractorStatus: Status;
	is_billable: boolean;
	vendor_type: string;
	contractor_id: string;
	working_mode: string;
	job_level_id: string;
	department_id: string;
	contractor_status: string;
	reportee_id: string;
	total_experience: string;
	personal_email: string;
	join_date: string;
	end_date: string;
	agreement_id: string;
	billing_cost: string;
	contractor_cost: string;
	contractor_joining_source_id: string;
	address: Address;
	contract_agreements: Agreement[];
	contractor_joining_source: Source;
	bank_name: string;
	branch_name?: string;
	account_number: string;
	ifsc_code?: string;
	id: string;
	currency: Currency;
	created_at?: string;
	createdBy: CreatedBy;
	location_id?: string;
	consultantCustomFields: customFieldData;
	custom_field_1: string;
	custom_field_2: string;
	custom_field_3: string;
	custom_field_4: string;
	custom_field_5: string;
	custom_field_6: string;
	custom_field_7: string;
	custom_field_8: string;
	custom_field_9: string;
	marital_status: boolean;
	wedding_date: string;
	emergency_contact_number: string;
	emergency_contact_name: string;
}
interface Props {
	record: Record;
	refetch: () => void;
}
export const ConsultantDetailContainer = (props: Props) => {
	const { record: consultant, refetch } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		tabGlobalIndex: selectedTabIndex,
		setTabGlobalIndex: setSelectedTabIndex,
	} = useContext<any>(UserProfileContext);
	const [userDetails, setUserDetails] = useState({});
	const [consultantDetails, setConsultantDetails] = useState({});

	const handleChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};
	useEffect(() => {
		if (!consultant) {
			return;
		}
		setUserDetails({
			first_name: consultant?.user?.first_name,
			midddle_name: consultant?.user?.middle_name,
			last_name: consultant?.user?.last_name,
			id: consultant?.user?.id,
		});
		setConsultantDetails({
			created_at: consultant?.created_at,
			createdBy: consultant?.createdBy,
			user: consultant?.user,
		});
	}, [consultant]);
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	return (
		<div className={classes.root}>
			<CustomTabs
				value={selectedTabIndex}
				onChange={handleChange}
				variant='scrollable'
				scrollButtons='off'
			>
				<CustomTab key={0} label='Consultant Details' />
				<CustomTab key={1} label='Skills' />
				<CustomTab key={2} label='Bank Details' />
				<CustomTab key={3} label='Documents' />
				<CustomTab key={4} label='Assets' />
				<CustomTab key={5} label='Notes' />
				<CustomTab key={6} label='Projects' />
				<CustomTab key={7} label='Holidays' />
				<CustomTab key={8} label='History' />
				<CustomTab key={9} label='MyTeam' />
			</CustomTabs>

			<CustomTabPanel value={selectedTabIndex} index={0}>
				<ConsultantBasicDetails record={consultant} refetch={() => refetch()} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={1}>
				<ConsultantSkillDetail record={consultant} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={2}>
				<ConsultantBankDetails record={consultant} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={3}>
				<DocumentUploader />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={4}>
				<ConsultantAssetDetails record={userDetails} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={5}>
				<ConsultantNote />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={6}>
				<EmployeeProjectList record={consultant?.user?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={7}>
				<EmployeeHolidayList
					selectedUserId={consultant?.user?.id || null}
					isManagerView={true}
					userLocationId={consultant?.location_id || null}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={8}>
				<ConsultantHistory record={consultantDetails} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={9}>
				<EmployeeMyTeam userId={consultant?.user?.id} />
			</CustomTabPanel>
		</div>
	);
};

export default ConsultantDetailContainer;
