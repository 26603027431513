import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Tabs, Tab } from '@material-ui/core';
import OpportunityDetailsTaskTab from '../DetailsPage/OpportunityDetailsTaskTab.component';
import OpportunityNotes from '../DetailsPage/OpportunityNotes.component';
import DocumentUploader from './DocumentUploader.component';

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(1.8),
		marginLeft: theme.spacing(1.8),
		font: 'normal normal bold 12px/15px Manrope !important',
		fontSize: '12px !important',
		letterSpacing: '0px',
		'&$selected': {
			color: '#292929',
			fontWeight: 'bold',
		},
		'&:focus': {
			color: '#292929',
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '100%',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const OpportunityDetailsContainer = (props) => {
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const handleChange = (event, newValue) => {
		setSelectedTabIndex(newValue);
	};
	return (
		<div className={classes.root}>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab key={0} label='Tasks' />
				<CustomTab key={1} label='Documents' />
				<CustomTab key={2} label='Notes' />
				<CustomTab key={3} label='History' />
			</CustomTabs>
			<CustomTabPanel value={selectedTabIndex} index={0}>
				<OpportunityDetailsTaskTab boardId={props?.boardId}/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={1}>
				<DocumentUploader uploaded={props.uploaded} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={2}>
				<OpportunityNotes />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={3}>
				<Typography className={classes.noData}>
					No data to display...
				</Typography>
			</CustomTabPanel>
		</div>
	);
};

export default OpportunityDetailsContainer;
