import { head } from 'lodash';
import { UserProfileContext } from '../../App';
import { useEffect, useState, useContext } from 'react';
import { useGetUserAllHolidaysAndWeekendsQuery } from '../../generated/graphql';
import {
	getSundaysBetweenDates,
	getSaturdaysDates,
} from '../../Utils/date-time.util';

const useExcludeLeaveDates = (
	days,
	startDate,
	endDate,
	userLocationId,
	userId
) => {
	const { orgId, noOfLocations } = useContext(UserProfileContext);

	const [excludedDates, setExcludedDates] = useState(days);
	const { data: orgHolidays } = useGetUserAllHolidaysAndWeekendsQuery({
		variables: {
			orgId,
			filter:
				userLocationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: startDate,
								_lte: endDate,
							},
							location_id: { _eq: userLocationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: startDate,
								_lte: endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				userLocationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: startDate,
									_lte: endDate,
								},
								location_id: { _eq: userLocationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: startDate,
									_lte: endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgHolidays) {
			return;
		}

		const generalHolidays =
			orgHolidays?.org_holidays?.map((holiday) => holiday?.date) || [];
		const optionalHolidays =
			orgHolidays?.user_optional_holiday_mapping?.map(
				(optionalHoliday) => optionalHoliday?.org_holiday?.date
			) || [];

		const allHolidayDates =
			!userLocationId && noOfLocations && noOfLocations > 0
				? []
				: [...generalHolidays, ...optionalHolidays];

		const isSundayAvailable = head(
			orgHolidays?.organization
		)?.weekend?.includes('SUN');

		const sundayAsWeekend = getSundaysBetweenDates(startDate, endDate);

		const availableSaturdays = head(orgHolidays?.organization)
			?.weekend?.filter((weekendDay) => weekendDay !== 'SUN')
			?.map((sat) => sat?.charAt(sat.length - 1));

		const allSaturdaysBetweenDates = getSaturdaysDates(startDate, endDate);

		const saturdayAsWeekend = allSaturdaysBetweenDates
			?.map((day) =>
				availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
			)
			?.filter((date) => date !== '');

		const excludedLeaveDates = days?.filter((day) => {
			const isExcludedType = day?.isExcluded;
			const date = day?.date;
			if (!isExcludedType) {
				return day;
			}
			const isHoliday = allHolidayDates?.includes(date);
			const isSunday = sundayAsWeekend?.includes(date);
			const isSaturday = saturdayAsWeekend.includes(date);

			return (
				((isSundayAvailable && !isHoliday && !isSunday && !isSaturday) ||
					(!isSundayAvailable && !isHoliday && !isSaturday)) &&
				day
			);
		});

		setExcludedDates(excludedLeaveDates);
	}, [orgHolidays, startDate, endDate, days, noOfLocations, userLocationId]);

	return excludedDates && excludedDates.length !== 0 ? excludedDates : [];
};

export default useExcludeLeaveDates;
