import React from 'react';
import { logInUrl } from '../Api/graphQLClient.config';
import jwt from 'jwt-decode';

const WithAuthentication = (WrappedComponent) => {
  const AuthHoc = (props) => {
    const token = localStorage.getItem('accessToken');
    if (!token) window.location = logInUrl;
    return <WrappedComponent {...props} userProfile={jwt(token)} />;
  };
  return AuthHoc;
};
export default WithAuthentication;
