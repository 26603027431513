import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	required,
	useNotify,
	useRefresh,
	maxLength,
} from 'react-admin';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { modalFormStyle } from '../../../../Layout/styles';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import KanbanWarningModal from '../../Kanban/kanbanWarning.component';
import {
	UPDATE_TAG_SUCCESS_MESSAGE,
	INSERT_TAG_SUCCESS_MESSAGE,
	PROJECT_TAG_UNIQUE_KEY,
	PROJECT_TAG_NAME_UNIQUE_ERROR,
	EDIT_PROJECT_TAG_MESSAGE,
	ADD_PROJECT_TAG_MESSAGE,
} from './../constant';
import { useUpsertProjectResourceTagMutation } from '../../../../generated/graphql';
import { validateDescription, validateName } from '../../constant';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';

export interface resourceTag {
	id?: string;
	name?: string;
	description?: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	resourceTag: resourceTag;
}

const ProjectResourceTagForm = (props: Props) => {
	const { open, onClose, resourceTag } = props;
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [resourceTagUniqueError, setResourceTagUniqueError] = useState('');
	const classes = modalFormStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const [upsertResourceTag, { loading: isResourceTagLoading }] =
		useUpsertProjectResourceTagMutation();

	const onSave = (formValue: resourceTag) => {
		if (!formValue) {
			return;
		}
		setResourceTagUniqueError('');
		const { name, description } = formValue;
		upsertResourceTag({
			variables: {
				projectTags: {
					...formValue,
					name: name?.trim(),
					description: description?.trim(),
				},
			},
		})
			.then((data) => {
				onClose();
				notify(
					formValue?.id
						? UPDATE_TAG_SUCCESS_MESSAGE
						: INSERT_TAG_SUCCESS_MESSAGE
				);
				setResourceTagUniqueError('');
				userefresh();
				handleClose();
			})
			.catch((error) => {
				if (error.message.includes(PROJECT_TAG_UNIQUE_KEY)) {
					setResourceTagUniqueError(PROJECT_TAG_NAME_UNIQUE_ERROR);
					return;
				}
			});
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
	};

	const handleClose = () => {
		setResourceTagUniqueError('');
		onClose();
	};

	const validate = [
		required(),
		validateName,
		maxLength(100, 'Maximum 100 character allowed'),
	];

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={handleClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{resourceTag?.id
									? EDIT_PROJECT_TAG_MESSAGE
									: ADD_PROJECT_TAG_MESSAGE}
							</Box>
							<CloseIcon onClick={handleClose} className={classes.closeIcon} />
						</Box>
						<Form
							onSubmit={onSave}
							initialValues={resourceTag}
							mutators={{
								setTouched: (args, state, utils) => {
									if (
										state &&
										state?.formState &&
										state?.formState?.submitSucceeded
									) {
										state.formState.submitSucceeded = false;
										return;
									}
								},
							}}
						>
							{({ handleSubmit, invalid, pristine, submitSucceeded, form }) => (
								<form onSubmit={handleSubmit}>
									<Box className={classes.formContainer}>
										<Typography className={classes.label}>Tag *</Typography>
										<TextInput
											source='name'
											label=''
											validate={validate}
											onChange={(event: any) => {
												form.mutators.setTouched();
												setResourceTagUniqueError('');
											}}
										/>
										{resourceTagUniqueError && (
											<Box color='red' fontSize={'12px'} marginTop={'-6px'}>
												{resourceTagUniqueError}
											</Box>
										)}
										<Typography className={classes.label}>
											Description
										</Typography>
										<TextInput
											validate={validateDescription}
											source='description'
											label=''
											multiline={true}
										/>
									</Box>
									<Box className={classes.floatingButtonContainer}>
										<Button
											onClick={handleClose}
											className={classes.cancelButton}
										>
											Cancel
										</Button>
										<ButtonWithLoader
											isDisabled={
												invalid ||
												pristine ||
												submitSucceeded ||
												isResourceTagLoading
											}
											title='Save'
											isLoading={isResourceTagLoading}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={''}
				/>
			}
		</>
	);
};

export default ProjectResourceTagForm;
