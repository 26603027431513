import {
	Box,
	ThemeProvider,
	InputAdornment,
	Typography,
	IconButton,
	TextField,
	CircularProgress,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState, useContext } from 'react';
import {
	modalFormStyle,
	AutocompleteSearchStyles,
} from '../../../Layout/styles';
import { Form, Field } from 'react-final-form';
import {
	modalFormTheme,
	autoCompleteTheme,
} from '../../../Layout/Theme.component';
import {
	TextInput,
	SelectInput,
	required,
	useRefresh,
	number,
	Loading,
	minValue,
	maxValue,
	RadioButtonGroupInput,
	NumberInput,
	useQuery,
	ReferenceInput,
	AutocompleteInput,
	useNotify,
} from 'react-admin';
import {
	useGetProjectAssignedUsersQuery,
	useGetTaskByIdQuery,
} from '../../../generated/graphql';
import head from 'lodash/head';
import { GET_PROJECT_TASK_STATUS } from '../TaskList.gql';
import { useQuery as useApolloQuery } from '@apollo/client';
import { FloatingComponent } from '../../../SharedComponents/FolatingButton.component';
import CreateTaskForm from '../CreateTaskForm';
import { useParams } from 'react-router-dom';
import { settingsStyle } from '../../Settings/settingsStyle';
import CommentsForm from './Comments/CommentsForm.component';
import {
	ASSIGNED_TO_TOOLTIP_LABEL,
	BILLABLE_TOOLTIP_LABEL,
	ESTIMATED_HOUR_TOOLTIP_LABEL,
	FLOAT_BUTTON_MENUS,
	REMINDER_TOOLTIP_LABEL,
} from '../Constant';
import dayjs from 'dayjs';
import { datePickerTheme } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TiptapEditor } from '../../../SharedComponents/Tiptaptexteditor.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import { CREATE_PROJECT_CATEGORY } from '../../ResourcePlanner/gqlQueries';
import { useMutation as useApolloMutation } from '@apollo/client';
import { Duration } from 'dayjs/plugin/duration';
import { isEmpty, orderBy } from 'lodash';
import { PRIORITY_OPTIONS } from '../../constant';

interface Props {
	taskId: string;
	formValues: (value: any) => void;
	onSuccess: (val: boolean) => void;
	isFormValuesChanged: (val: boolean) => void;
	isEdit?: boolean;
	mileStoneId?: string | null;
	setIsLoading: (val: boolean) => void;
	isLoading: boolean;
}
interface FormValues {
	card_name?: string | null;
	planned_start_date?: string;
	planned_end_date?: string;
	project_milestone_id?: string | null;
	assigned_to?: string;
	priority?: string;
	description?: string | null;
	is_billable?: boolean | null;
	completion_percentage?: number;
	reminder?: string;
	status?: string;
	planned_hours?: string;
	effort_estimate?: number | undefined | null;
	id?: string;
	cardId?: string;
	title?: string | null;
	category_id?: string | null;
	working_hours?: number;
	deviation_hr?: string;
	deviation_per?: string;
}
interface Status {
	id: string;
	name: string;
}
export interface User {
	first_name: string;
	last_name?: string | null;
	full_name?: string | null;
	id: string;
}
interface Member {
	user: User;
}

const useStyles = makeStyles({
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		color: '#292929',
		marginBottom: '4px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
	},
	icon: {
		width: '16px',
		height: '16px',
		marginTop: '-2px',
	},
	root: {
		border: '1px solid #E0E0E0',
		height: '36px',
		borderRadius: '4px',
		background: '#E0E0E0',
		maxWidth: '120px',
		width: '100%',
		paddingLeft: '5px',
	},
});
export const TaskDetailsTab = (props: Props) => {
	const {
		taskId,
		formValues,
		onSuccess,
		isFormValuesChanged,
		setIsLoading,
		isLoading,
	} = props;
	const refresh = useRefresh();
	const { id: projectId } = useParams<{ id: string }>();
	const { dateFormat, isOvertimeEnabled } = useContext<any>(UserProfileContext);
	const [description, setDescription] = useState<any>('');
	const styles = settingsStyle();
	const inlineStyle = useStyles();

	const { data: milestones } = useQuery({
		type: 'GET_LIST',
		resource: 'project_milestone',
		payload: {
			sort: { field: 'name', order: 'ASC' },
			filter: { project_id: projectId || null },
		},
	});

	const { data: taskDetails, loading: taskIdByQueryLoading } =
		useGetTaskByIdQuery({
			variables: {
				task_id: taskId,
			},
		});

	const { data: projectTaskStatusList } = useApolloQuery(
		GET_PROJECT_TASK_STATUS,
		{
			variables: {
				id: taskDetails?.project_task[0]?.project?.kanban_board?.id,
			},
		}
	);
	const { data: projectMembers } = useGetProjectAssignedUsersQuery({
		variables: { project_id: projectId },
	});
	const classes = modalFormStyle();
	const notify = useNotify();
	const autocompleteSearchStyles = AutocompleteSearchStyles();
	const [initialFormValues, setInitialFormValues] = useState<FormValues>({});
	const [projectStatusList, setProjectStatusList] = useState([]);
	const [isBillable, setIsBillable] = useState(false);
	const [taskFormValues, setTaskFormValues] = useState<FormValues>();
	const [mentionSuggestions, setMentionSuggestions] = useState<any>([]);
	const [milestoneOptions, setMilestoneOptions] = useState([]);
	const [
		isProjectCategoryUniqueViolationError,
		setIsProjectCategoryUniqueViolationError,
	] = useState(false);
	const [isProjectCategoriesLoading, setIsProjectCategoriesLoading] =
		useState(false);

	const [isCreateTaskModalShown, setIsCreateTaskModalShown] = useState(false);
	const [isCreateCommentModalShown, setIsCreateCommentModalShown] =
		useState(false);
	const [durationGreaterError, setDurationGreaterError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const { task_id }: { task_id: string } = useParams();

	const [createProjectCategory] = useApolloMutation(CREATE_PROJECT_CATEGORY);

	const calculatedTimesheetHours = (timesheetList: any) => {
		if (isEmpty(timesheetList)) {
			return;
		}
		const aggregateTimesheetAndOvertimeData = isOvertimeEnabled
			? [
					...(timesheetList?.timesheets || []),
					...(timesheetList?.timesheet_overtimes || []),
			  ]
			: timesheetList?.timesheets || [];
		const workedMillis = aggregateTimesheetAndOvertimeData
			?.map((timeSheet: any) => timeSheet?.working_hours?.split(':'))
			.map((hoursAndMinutes: string[]) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total: Duration, durations: Duration) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);
		if (workedMillis) {
			const workedHours = workedMillis.asHours();
			const formattedWorkedHours = Math.floor(workedHours * 100) / 100;
			return formattedWorkedHours;
		}
	};

	useEffect(() => {
		setIsLoading(false);
		const taskData = head(taskDetails?.project_task);
		if (taskData?.is_billable) {
			setIsBillable(taskData.is_billable);
		}
		const task = {
			title: taskData?.title,
			planned_start_date: taskData?.planned_start_date,
			planned_end_date: taskData?.planned_end_date,
			assigned_to: taskData?.assigned_to,
			priority: taskData?.priority,
			description: taskData?.description,
			is_billable: taskData?.is_billable,
			completion_percentage: taskData?.completion_percentage,
			reminder: taskData?.reminder,
			status: head(taskData?.project_kanban_cards)?.column_id,
			planned_hours: taskData?.planned_hours,
			effort_estimate: taskData?.project?.effort_estimate,
			project_milestone_id: taskData?.project_milestone_id,
			working_hours: calculatedTimesheetHours(taskData),
			category_id: taskData?.project_task_category?.id,
			deviation_hr:
				taskData?.planned_hours && calculatedTimesheetHours(taskData)
					? (
							(calculatedTimesheetHours(taskData) || 0) -
							taskData?.planned_hours
					  )?.toFixed(1)
					: '--',
			deviation_per:
				taskData?.planned_hours && (calculatedTimesheetHours(taskData) || 0)
					? (
							(((calculatedTimesheetHours(taskData) || 0) -
								Number(taskData?.planned_hours)) /
								Number(taskData?.planned_hours)) *
							100
					  ).toFixed(1)
					: '--',
		};
		setDescription(task?.description || '');
		setInitialFormValues(task);
		setTaskFormValues({
			...task,
			id: taskData?.id,
			cardId: head(taskData?.project_kanban_cards)?.id,
		});
		// eslint-disable-next-line
	}, [taskDetails]);

	useEffect(() => {
		if (!projectTaskStatusList) {
			return;
		}
		const statusList = projectTaskStatusList?.kanban_column.map(
			(status: Status) => {
				return {
					id: status?.id,
					name: status?.name,
				};
			}
		);

		setProjectStatusList(statusList);
	}, [projectTaskStatusList]);

	useEffect(() => {
		const response = milestones?.map((value: any) => {
			return {
				id: value?.id,
				name: value?.name,
			};
		});
		setMilestoneOptions(response);
	}, [milestones]);

	useEffect(() => {
		formValues(taskFormValues);
	}, [formValues, taskFormValues]);

	useEffect(() => {
		if (!projectMembers) {
			setMentionSuggestions([]);
			return;
		}
		const userSuggestion = projectMembers?.project_resource_mapping?.map(
			(user: Member) => {
				if (!user) {
					return;
				}
				return {
					id: user.user?.id,
					name: `${user.user?.full_name} `,
				};
			}
		);
		const sortedUserSuggestion = orderBy(
			userSuggestion,
			[(user) => user?.name.toUpperCase()],
			['asc']
		);
		setMentionSuggestions(sortedUserSuggestion);
	}, [projectMembers]);

	useEffect(() => {
		if (description) {
			isFormValuesChanged(true);
			setTaskFormValues({
				...taskFormValues,
				description: description,
			});
			return;
		}
		setDescription(head(taskDetails?.project_task)?.description || '');
		// eslint-disable-next-line
	}, [description, taskDetails]);

	if (
		isLoading ||
		(head(taskDetails?.project_task)?.description && !description)
	) {
		return <Loading />;
	}

	const getProjectIntialValue = (milestoneId: any) => {
		const project = milestones?.find((value: any) => value.id === milestoneId);
		return project
			? {
					id: project.id,
					name: project?.name,
			  }
			: null;
	};

	const getReaourceIntialValue = (milestoneId: any) => {
		const project = mentionSuggestions?.find(
			(value: any) => value.id === milestoneId
		);
		return project
			? {
					id: project.id,
					name: project?.name,
			  }
			: null;
	};

	const projectCategoryRenderer = (projectCategory: any) =>
		`${projectCategory?.name || ''}`;

	const handleProjectCategoryCreate = (projectCategory: string) => {
		if (!projectCategory) {
			return;
		}
		setIsProjectCategoriesLoading(true);
		const projectCategoryId = createProjectCategory({
			variables: {
				projectCategory: projectCategory,
				type: 'project_task',
			},
		})
			.then((res) => {
				setIsProjectCategoriesLoading(false);
				notify('Project Category created...');
				return res?.data?.insert_project_category_one?.id;
			})
			.catch((err) => {
				console.log(err);
				setIsProjectCategoriesLoading(false);
				if (err.message.includes('project_category_name_org_id_key')) {
					setIsProjectCategoryUniqueViolationError(true);
					return;
				}
				return;
			});
		return projectCategoryId;
	};

	return (
		<Box>
			{taskIdByQueryLoading ? (
				<Box>
					{' '}
					<Loading />
				</Box>
			) : (
				<ThemeProvider theme={modalFormTheme}>
					<Box className={styles.contentContainer}>
						<Box className={styles.headingContainer}>
							<Typography className={styles.normalHeading}>{`${
								head(taskDetails?.project_task)?.project?.prefix
							}-${head(taskDetails?.project_task)?.task_number}`}</Typography>
						</Box>
						<Box className={styles.content} width='98%'>
							<Box>
								<Form
									initialValues={initialFormValues}
									onSubmit={() => {}}
									mutators={{
										setProjectCategory: (args, state, utils) => {
											utils.changeValue(state, 'category_id', () => {
												return args[0];
											});
										},
									}}
								>
									{({ handleSubmit, invalid, form }) => (
										<form onSubmit={handleSubmit}>
											<Box display={'flex'} justifyContent='space-between'>
												<Box width='49%'>
													<Box className={inlineStyle.label}>Task Name*</Box>
													<TextInput
														source='title'
														fullWidth={true}
														label={''}
														validate={required()}
														onChange={(e: any) => {
															setTaskFormValues({
																...taskFormValues,
																title: e?.target?.value,
															});
															isFormValuesChanged(true);
														}}
													/>
												</Box>
												<Box width='23%'>
													<Box>
														<Box className={inlineStyle.label}>
															Billable *
															<Tooltip
																title={BILLABLE_TOOLTIP_LABEL}
																placement='right'
															>
																<IconButton
																	className={inlineStyle.iconContainer}
																>
																	<InfoIcon className={inlineStyle.icon} />
																</IconButton>
															</Tooltip>
														</Box>
														<RadioButtonGroupInput
															source='is_billable'
															label=''
															choices={[
																{ id: true, name: 'True' },
																{ id: false, name: 'False' },
															]}
															onChange={(e: any) => {
																setIsBillable(e);
																setTaskFormValues({
																	...taskFormValues,
																	is_billable: e,
																});
																isFormValuesChanged(true);
															}}
															defaultChecked={isBillable}
														/>
													</Box>
												</Box>
												<Box width='23%'>
													<Box>
														<Box className={inlineStyle.label}>
															Assigned To
															<Tooltip
																title={ASSIGNED_TO_TOOLTIP_LABEL}
																placement='right'
															>
																<IconButton
																	className={inlineStyle.iconContainer}
																>
																	<InfoIcon className={inlineStyle.icon} />
																</IconButton>
															</Tooltip>
														</Box>
														<Field name='assigned_to' validate={required()}>
															{(props: any) => (
																<ThemeProvider theme={autoCompleteTheme}>
																	<div
																		className={
																			autocompleteSearchStyles.customFieldContainer
																		}
																	>
																		<Autocomplete
																			id='assigned_to'
																			classes={{
																				option:
																					autocompleteSearchStyles.options,
																				groupLabel:
																					autocompleteSearchStyles.groupLabel,
																				inputRoot:
																					autocompleteSearchStyles.inputRoot,
																				noOptions:
																					autocompleteSearchStyles.noOptions,
																				focused:
																					autocompleteSearchStyles.focused,
																				input: autocompleteSearchStyles.input,
																				endAdornment:
																					autocompleteSearchStyles.endAdornment,
																			}}
																			getOptionSelected={(
																				option: any,
																				value: any
																			) => option?.id === props.input.value}
																			onChange={(event, newValue) => {
																				setTaskFormValues({
																					...taskFormValues,
																					assigned_to: newValue?.id || null,
																				});
																				isFormValuesChanged(true);
																			}}
																			value={getReaourceIntialValue(
																				taskFormValues?.assigned_to
																			)}
																			options={
																				mentionSuggestions &&
																				mentionSuggestions?.length > 0
																					? mentionSuggestions
																					: []
																			}
																			getOptionLabel={(option: any) =>
																				option?.name || ''
																			}
																			renderInput={(params) => (
																				<TextField
																					{...params}
																					label=''
																					placeholder='Search user'
																					InputProps={{
																						...params.InputProps,
																						startAdornment: (
																							<InputAdornment
																								position='start'
																								classes={{
																									root: autocompleteSearchStyles.inputAdronment,
																									positionStart:
																										autocompleteSearchStyles.inputAdronmentPositionStart,
																								}}
																							>
																								<SearchIcon
																									className={
																										autocompleteSearchStyles.searchIconPositionStart
																									}
																								/>
																							</InputAdornment>
																						),
																					}}
																				/>
																			)}
																		/>
																	</div>
																</ThemeProvider>
															)}
														</Field>
													</Box>
												</Box>
											</Box>
											<Box display='flex' justifyContent='space-between'>
												<Box width={'23%'}>
													<Box className={inlineStyle.label}>Start Date *</Box>
													<ThemeProvider theme={datePickerTheme}>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																autoOk={true}
																value={taskFormValues?.planned_start_date}
																onChange={(event) => {
																	if (!event) {
																		return;
																	}
																	setStartDateError(false);
																	if (
																		dayjs(event).isAfter(
																			dayjs(taskFormValues?.planned_end_date)
																		)
																	) {
																		setStartDateError(true);
																	}
																	setTaskFormValues({
																		...taskFormValues,
																		planned_start_date:
																			event?.format('YYYY-MM-DD'),
																	});
																	isFormValuesChanged(true);
																}}
																variant='inline'
																format={dateFormat}
																label={false}
																emptyLabel={dateFormat}
																error={startDateError}
																helperText={
																	startDateError
																		? 'Start date cannot be greater than end date'
																		: ''
																}
																InputLabelProps={{ shrink: true }}
																showTodayButton={true}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position='start'>
																			<IconButton>
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	),
																}}
															/>
														</MuiPickersUtilsProvider>
													</ThemeProvider>
												</Box>
												<Box width={'23%'}>
													<Box className={inlineStyle.label}>End Date *</Box>
													<ThemeProvider theme={datePickerTheme}>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																autoOk={true}
																value={taskFormValues?.planned_end_date}
																onChange={(event) => {
																	if (!event) {
																		return;
																	}
																	setStartDateError(false);
																	if (
																		dayjs(event).isBefore(
																			dayjs(taskFormValues?.planned_start_date)
																		)
																	) {
																		setStartDateError(true);
																	}
																	setTaskFormValues({
																		...taskFormValues,
																		planned_end_date:
																			event?.format('YYYY-MM-DD'),
																	});
																	isFormValuesChanged(true);
																}}
																variant='inline'
																format={dateFormat}
																label={false}
																emptyLabel={dateFormat}
																InputLabelProps={{ shrink: true }}
																showTodayButton={true}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position='start'>
																			<IconButton>
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	),
																}}
															/>
														</MuiPickersUtilsProvider>
													</ThemeProvider>
												</Box>
												<Box width={'22%'}>
													<Box className={inlineStyle.label}>Status *</Box>
													<SelectInput
														source='status'
														label={''}
														name='status'
														choices={projectStatusList}
														onChange={(e: any) => {
															setTaskFormValues({
																...taskFormValues,
																status: e?.target?.value,
															});
															isFormValuesChanged(true);
														}}
													/>
												</Box>
												<Box width={'23%'}>
													<Box className={inlineStyle.label}>Milestone</Box>
													<Field
														name='project_milestone_id'
														validate={required()}
													>
														{(props: any) => (
															<ThemeProvider theme={autoCompleteTheme}>
																<div
																	className={
																		autocompleteSearchStyles.customFieldContainer
																	}
																>
																	<Autocomplete
																		id='project_milestone_id'
																		classes={{
																			option: autocompleteSearchStyles.options,
																			groupLabel:
																				autocompleteSearchStyles.groupLabel,
																			inputRoot:
																				autocompleteSearchStyles.inputRoot,
																			noOptions:
																				autocompleteSearchStyles.noOptions,
																			focused: autocompleteSearchStyles.focused,
																			input: autocompleteSearchStyles.input,
																			endAdornment:
																				autocompleteSearchStyles.endAdornment,
																		}}
																		getOptionSelected={(
																			option: any,
																			value: any
																		) => option?.id === props.input.value}
																		onChange={(event, newValue) => {
																			setTaskFormValues({
																				...taskFormValues,
																				project_milestone_id:
																					newValue?.id || null,
																			});
																			isFormValuesChanged(true);
																		}}
																		value={getProjectIntialValue(
																			taskFormValues?.project_milestone_id
																		)}
																		options={
																			milestoneOptions &&
																			milestoneOptions?.length > 0
																				? milestoneOptions
																				: []
																		}
																		getOptionLabel={(option: any) =>
																			option?.name || ''
																		}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label=''
																				placeholder='Search milestone'
																				InputProps={{
																					...params.InputProps,
																					startAdornment: (
																						<InputAdornment
																							position='start'
																							classes={{
																								root: autocompleteSearchStyles.inputAdronment,
																								positionStart:
																									autocompleteSearchStyles.inputAdronmentPositionStart,
																							}}
																						>
																							<SearchIcon
																								className={
																									autocompleteSearchStyles.searchIconPositionStart
																								}
																							/>
																						</InputAdornment>
																					),
																				}}
																			/>
																		)}
																	/>
																</div>
															</ThemeProvider>
														)}
													</Field>
												</Box>
											</Box>
											<Box display='flex' justifyContent='space-between'>
												<Box width={'23%'}>
													<Box className={inlineStyle.label}>Completion(%)</Box>
													<NumberInput
														source='completion_percentage'
														fullWidth={true}
														label={''}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	%
																</InputAdornment>
															),
														}}
														onChange={(e: any) => {
															setTaskFormValues({
																...taskFormValues,
																completion_percentage: e?.target?.value,
															});
															isFormValuesChanged(true);
														}}
														validate={[minValue(0), maxValue(100)]}
													/>
												</Box>
												<Box width={'23%'}>
													<Box>
														<Box className={inlineStyle.label}>
															Reminder
															<Tooltip
																title={REMINDER_TOOLTIP_LABEL}
																placement='right'
															>
																<IconButton
																	className={inlineStyle.iconContainer}
																>
																	<InfoIcon className={inlineStyle.icon} />
																</IconButton>
															</Tooltip>
														</Box>
														<ThemeProvider theme={datePickerTheme}>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	autoOk={true}
																	value={taskFormValues?.reminder}
																	onChange={(event) => {
																		if (!event) {
																			return;
																		}
																		setTaskFormValues({
																			...taskFormValues,
																			reminder: event?.format('YYYY-MM-DD'),
																		});
																		isFormValuesChanged(true);
																	}}
																	variant='inline'
																	format={dateFormat}
																	label={false}
																	emptyLabel={dateFormat}
																	InputLabelProps={{ shrink: true }}
																	showTodayButton={true}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='start'>
																				<IconButton>
																					<EventIcon />
																				</IconButton>
																			</InputAdornment>
																		),
																	}}
																/>
															</MuiPickersUtilsProvider>
														</ThemeProvider>
													</Box>
												</Box>
												<Box display={'flex'} width={'22%'}>
													<Box width={'50%'}>
														<Box className={inlineStyle.label}>
															Estimated Hour
															<Tooltip
																title={ESTIMATED_HOUR_TOOLTIP_LABEL}
																placement='right'
															>
																<IconButton
																	className={inlineStyle.iconContainer}
																>
																	<InfoIcon className={inlineStyle.icon} />
																</IconButton>
															</Tooltip>
														</Box>
														<TextInput
															source='planned_hours'
															fullWidth={true}
															label={''}
															onChange={(e: any) => {
																const hours = e?.target?.value;
																setTaskFormValues({
																	...taskFormValues,
																	planned_hours: hours,
																});
																if (
																	taskFormValues?.effort_estimate &&
																	hours > taskFormValues?.effort_estimate
																) {
																	isFormValuesChanged(false);
																	setDurationGreaterError(true);
																	return;
																}
																isFormValuesChanged(true);
																setDurationGreaterError(false);
															}}
															validate={number()}
															error={durationGreaterError}
															helperText={
																durationGreaterError &&
																'duration must not be greater than project duration!'
															}
															FormHelperTextProps={{
																style: { color: 'red', width: '158px' },
															}}
														/>
													</Box>
													<Box width={'50%'} marginLeft='8px'>
														<Box className={inlineStyle.label}>Actual Hour</Box>
														<TextInput
															source='working_hours'
															fullWidth={true}
															label={''}
															disabled
														/>
													</Box>
												</Box>
												<Box display={'flex'} width={'23%'}>
													<Box width={'50%'}>
														<Box className={inlineStyle.label}>
															Deviation(Hr)
														</Box>
														<Box className={inlineStyle.root}>
															<input
																value={initialFormValues?.deviation_hr}
																className={inlineStyle.root}
																disabled
																style={{
																	color:
																		initialFormValues?.planned_hours &&
																		initialFormValues?.working_hours
																			? initialFormValues?.working_hours -
																					Number(
																						initialFormValues?.planned_hours
																					) >
																			  0
																				? '#EA4335'
																				: '#34A853'
																			: 'black',
																}}
															/>
														</Box>
													</Box>
													<Box width={'50%'} marginLeft='8px'>
														<Box className={inlineStyle.label}>
															Deviation(%)
														</Box>
														<Box>
															<input
																disabled
																value={initialFormValues?.deviation_per}
																className={inlineStyle.root}
																style={{
																	color:
																		initialFormValues?.planned_hours &&
																		initialFormValues?.working_hours
																			? initialFormValues?.working_hours -
																					Number(
																						initialFormValues?.planned_hours
																					) >
																			  0
																				? '#EA4335'
																				: '#34A853'
																			: 'black',
																}}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box display={'flex'} gridGap={'41px'}>
												<Box
													width={'23%'}
													display={'flex'}
													flexDirection={'column'}
												>
													<Box>
														<Box className={classes.label}>Category</Box>
														<ReferenceInput
															source='category_id'
															label={''}
															reference='project_category'
															sort={{ field: 'name', order: 'ASC' }}
															filterToQuery={(searchText) => ({
																name: searchText,
																type: 'project_task',
															})}
															filter={{
																name: true,
															}}
															onFocus={() => {
																setIsProjectCategoryUniqueViolationError(false);
															}}
															onChange={(e) => {
																setTaskFormValues({
																	...taskFormValues,
																	category_id: e,
																});
																isFormValuesChanged(true);
															}}
														>
															{isProjectCategoriesLoading ? (
																<TextInput
																	source=''
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='end'>
																				<CircularProgress
																					style={{
																						height: '20px',
																						width: '20px',
																						color: '#4285F4',
																					}}
																				/>
																			</InputAdornment>
																		),
																	}}
																/>
															) : (
																<AutocompleteInput
																	resettable={true}
																	optionText={projectCategoryRenderer}
																	shouldRenderSuggestions={(name: string) =>
																		name.trim().length > 0
																	}
																	onCreate={async (event: any) => {
																		const projectCategoryId =
																			await handleProjectCategoryCreate(event);
																		if (
																			!isProjectCategoryUniqueViolationError
																		) {
																			setTimeout(
																				() =>
																					form.mutators.setProjectCategory(
																						projectCategoryId
																					),
																				100
																			);
																		}
																	}}
																/>
															)}
														</ReferenceInput>
													</Box>
													<Box>
														<Box className={inlineStyle.label}>Priority *</Box>
														<SelectInput
															source='priority'
															label={''}
															name='priority'
															choices={PRIORITY_OPTIONS}
															onChange={(e: any) => {
																setTaskFormValues({
																	...taskFormValues,
																	priority: e?.target?.value,
																});
																isFormValuesChanged(true);
															}}
														/>
													</Box>
												</Box>
												<Box width={'76%'} className='textDetails'>
													<Box className={classes.label}>Description</Box>
													<Box>
														<TiptapEditor
															setDescription={(val: any) => {
																setDescription(val);
															}}
															description={description}
														/>
													</Box>
												</Box>
											</Box>
										</form>
									)}
								</Form>
							</Box>
							<Box display='flex' justifyContent='right' marginTop={'6px'}>
								<FloatingComponent
									selectedMenu={(menu: string) => {
										if (menu === 'sub_task') {
											setIsCreateTaskModalShown(true);
										}
										if (menu === 'comment') {
											setIsCreateCommentModalShown(true);
										}
									}}
									menuList={FLOAT_BUTTON_MENUS}
								/>
							</Box>
							{isCreateTaskModalShown && (
								<CreateTaskForm
								onCreateTask={() => {}}
								boardId={
									head(taskDetails?.project_task)?.project?.kanban_board?.id
								}
								parentTaskId={task_id}
								open={isCreateTaskModalShown}
								mileStoneId={
									head(taskDetails?.project_task)?.project_milestone_id
								}
								onClose={() => setIsCreateTaskModalShown(false)}
								onSuccess={(val: boolean) => onSuccess(val)}
								isEdit={false}
							/>
							)}
							<CommentsForm
								onClose={() => setIsCreateCommentModalShown(false)}
								open={isCreateCommentModalShown}
								onSuccess={() => refresh()}
							/>
						</Box>
					</Box>
				</ThemeProvider>
			)}
		</Box>
	);
};

export default TaskDetailsTab;
