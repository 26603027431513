import React, { useState, useEffect, useContext } from 'react';
import { TextInput,required } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { Form } from 'react-final-form';
import { ThemeProvider } from '@material-ui/core/styles';
import { Dialog, Typography, Button, Box, IconButton, InputAdornment } from '@material-ui/core';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import dayjs from 'dayjs';
import { useUpdateEmployeeProbationMutation } from '../../../generated/graphql';
import { datePickerTheme } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';


export interface EmployeeProbationProps {
	probation_start_date: string;
	probation_end_date: string;
	note? :string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	userId: string;
	employeeDetails: EmployeeProbationProps;
	probationDay: number;
}

const EmployeeProbabtionForm = ({
	open,
	onClose,
	onSuccess,
	userId,
	employeeDetails,
	probationDay,
}: Props) => {
	const classes = modalFormStyle();
	const [employeeProbationDetails, setEmployeeProbationDetails] = useState({
		probation_start_date: '',
		probation_end_date: '',
	});
	const[ probationStartDateError, setProbationStartDateError] = useState(false)
	const[ probationEndDateError, setProbationEndDateError] = useState(false)
	const[ dateErrorMessage, setdateErrorMessage] = useState('')
	const { dateFormat } = useContext<any>(UserProfileContext);
	const [updateEmployeeProbation,{loading:isEmployeeProbationLoading}] = useUpdateEmployeeProbationMutation();

	useEffect(() => {
		if (employeeDetails) {
			setEmployeeProbationDetails(employeeDetails);
		}
	}, [employeeDetails]);

	const handleChange = (date: any) => {
		if(date === null || dayjs(date).isValid() === false){
			setProbationStartDateError(true)
			setdateErrorMessage('Invalid date')
			return
		}else {
			setProbationStartDateError(false)
			setProbationEndDateError(false)
		}
		setEmployeeProbationDetails({
			probation_start_date: dayjs(date)
			.format('YYYY-MM-DD'),
			probation_end_date: dayjs(date)
				.add(probationDay, 'day')
				.format('YYYY-MM-DD'),
		});
	};

	const handleEndDateChange = (date: any) => {
		if(date === null || dayjs(date).isValid() === false){
			setProbationEndDateError(true)
			setdateErrorMessage('Invalid date')
			return
		}
		setEmployeeProbationDetails({
			probation_start_date: employeeProbationDetails.probation_start_date,
			probation_end_date: dayjs(date)
				.format('YYYY-MM-DD'),
		});
	}
	const save = (value: EmployeeProbationProps) => {
		if(!value){
			return
		}
		if (
			dayjs(employeeProbationDetails?.probation_start_date).isAfter(
				employeeProbationDetails?.probation_end_date
			)
		) {
			setProbationEndDateError(true)
			setdateErrorMessage('End date should be greater than start date')
			return
		}
		updateEmployeeProbation({
			variables: {
				employeeId: userId,
				...value,
				employeeNote: {
					employee_id: userId,
					title: 'Probation details',
					note: value?.note,
				},
			},
		}).then((data: any) => {
			onSuccess();
			handleClose();
		});
	};
	const handleClose = () => {
		setProbationEndDateError(false)
		setProbationStartDateError(false)
		setdateErrorMessage('')
		onClose()
	}
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} disableBackdropClick>
				<Box className={classes.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>ADDITIONAL INFO</Typography>
						<CloseIcon onClick={handleClose} className={classes.closeIcon} />
					</Box>
					<Form initialValues={employeeProbationDetails} onSubmit={save}>
						{({ handleSubmit, invalid, form }) => (
							<form onSubmit={handleSubmit}>
								<Box className={classes.formContainer}>
									<Typography className={classes.label}>
										Probation Start Date *
									</Typography>
									<ThemeProvider theme={datePickerTheme}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												value={employeeProbationDetails?.probation_start_date}
												onChange={(event) => {
													handleChange(event);
												}}
												variant='inline'
												format={dateFormat}
												label={false}
												emptyLabel={dateFormat}
												error={probationStartDateError}
												helperText={probationStartDateError ? dateErrorMessage: ''}
												InputLabelProps={{ shrink: true }}
												showTodayButton={true}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															<IconButton>
																<EventIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</MuiPickersUtilsProvider>
									</ThemeProvider>
									<Typography className={classes.label}>
										Probation End Date
									</Typography>
									<ThemeProvider theme={datePickerTheme}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												autoOk={true}
												value={employeeProbationDetails?.probation_end_date}
												onChange={(event) => {
													setProbationEndDateError(false)
													handleEndDateChange(event);
												}}
												variant='inline'
												format={dateFormat}
												label={false}
												error={probationEndDateError}
												emptyLabel={dateFormat}
												showTodayButton={true}
												helperText={probationEndDateError ? dateErrorMessage: ''}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															<IconButton>
																<EventIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</MuiPickersUtilsProvider>
									</ThemeProvider>
								</Box>
								<Typography className={classes.label}>Notes *</Typography>
								<TextInput
									multiline
									source='note'
									label=''
									placeholder='Enter the text'
									validate={required()}
								/>
								<Box className={classes.buttonContainer} mt={4}>
									<Button onClick={handleClose} className={classes.cancelButton}>
										Cancel
									</Button>
									<Button
										className={
											invalid || isEmployeeProbationLoading ||  probationEndDateError || probationEndDateError ? classes.disabledButton : classes.saveButton
										}
										disabled={
											invalid || probationEndDateError || probationEndDateError || isEmployeeProbationLoading
										}
										type='submit'
									>
										Save
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default EmployeeProbabtionForm;
