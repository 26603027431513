export const EMPLOYEE_ACCESS_OPTIONS = [
	{ id: 'addNewEmployee', name: 'Add new employee' },
	{ id: 'viewEmployeeDetails', name: 'View employee details' },
	{ id: 'changeEmployeeStatus', name: 'Change employee status' },
	{ id: 'changeEmployeeDetails', name: 'Change employee details' },
	{
		id: 'viewEmployeeBillingInformation',
		name: 'View employee billing information',
	},
	{
		id: 'changeEmployeeBillingInformation',
		name: 'Change employee billing information',
	},
	{ id: 'viewEmployeeSkills', name: 'View employee skills' },
	{ id: 'addEmployeeSkills', name: 'Add employee skills' },
	{ id: 'modifyEmployeeSkills', name: 'Modify employee skills' },
	{ id: 'archiveEmployeeSkills', name: 'Archive employee skills' },
	{ id: 'viewEmployeeDocuments', name: 'View employee documents' },
	{ id: 'addEmployeeDocuments', name: 'Add employee documents' },
	{ id: 'modifyEmployeeDocuments', name: 'Modify employee documents' },
	{ id: 'archiveEmployeeDocuments', name: 'Archive employee documents' },
	{ id: 'viewEmployeeAssets', name: 'View employee Assets' },
	{ id: 'viewEmployeeBankDetails', name: 'View employee bank details' },
	{ id: 'addEmployeeBankDetails', name: 'Add employee bank details' },
	{ id: 'modifyEmployeeBankDetails', name: 'Modify employee bank details' },
	{ id: 'archiveEmployeeBankDetails', name: 'Archive employee bank details' },
	{ id: 'editLeavesAndAttendance', name: 'Edit leaves and attendance' },
	{ id: 'assignNewUserRole', name: 'Assign new user role' },
];

export const CRM_ACCESS_OPTIONS = [
	{ id: 'viewCompanyDetails', name: 'View company details' },
	{ id: 'addCompany', name: 'Add company ' },
	{ id: 'modifyCompanyDetails', name: 'Modify company details' },
	{ id: 'archiveCompany', name: 'Archive company ' },
	{ id: 'viewContactDetails', name: 'View contact details' },
	{ id: 'addContact', name: 'Add contact ' },
	{ id: 'modifyContactDetails', name: 'Modify contact details' },
	{ id: 'archiveContact', name: 'Archive contact' },
	{ id: 'viewOpportunityDetails', name: 'View opportunity details' },
	{ id: 'addOpportunity', name: 'Add opportunity ' },
	{ id: 'modifyOpportunityDetails', name: 'Modify opportunity details' },
	{ id: 'archiveOpportunity', name: 'Archive opportunity' },
	{
		id: 'editOpportunityPipelineStages',
		name: 'Edit opportunity pipeline stages',
	},
	{ id: 'viewCrmTaskDetails', name: 'View crm task details' },
	{ id: 'addCrmTask', name: 'Add crm task ' },
	{ id: 'modifyCrmTaskDetails', name: 'Modify crm task details' },
	{ id: 'archiveCrmTask', name: 'Archive crm task' },
	{id: 'viewPipelineInsights', name:'viewPipelineInsights'},
	{id: 'inviteKanbanUsers', name: 'inviteKanbanUsers'}
];

export const CONTRACT_ACCESS_OPTIONS = [
	{ id: 'viewContractDetails', name: 'View contract details' },
	{ id: 'addContract', name: 'Add Contract' },
	{ id: 'modifyContractDetails', name: 'Modify contract details' },
	{ id: 'archiveContract', name: 'Archive contract' },
];

export const ASSET_ACCESS_OPTIONS = [
	{ id: 'viewAssetDetails', name: 'View asset details' },
	{ id: 'addAsset', name: 'Add Asset' },
	{ id: 'modifyAssetDetails', name: 'Modify asset details' },
	{ id: 'archiveAsset', name: 'Archive asset' },
];

export const PROJECT_PLANNING_ACCESS_OPTIONS = [
	// TODO after discussion either remove it or keep it as unique value
	// { id: 'viewProjectDetails', name: 'View project details' },
	// { id: 'addProject', name: 'Add project' },
	// { id: 'modifyProject', name: 'Modify project' },
	// { id: 'archiveProject', name: 'Archive project' },
	{ id: 'viewProjectAllocation', name: 'View project allocation' },
	{ id: 'addProjectAllocation', name: 'Add project allocation' },
	{ id: 'modifyProjectAllocation', name: 'Modify project allocation' },
];

export const PROJECT_MANAGEMENT_ACCESS_OPTIONS = [
	{ id: 'viewProjectDetails', name: 'View project details' },
	{ id: 'addProject', name: 'Add project' },
	{ id: 'modifyProject', name: 'Modify project' },
	{ id: 'archiveProject', name: 'Archive project' },
	{ id: 'viewProjectTaskDetails', name: 'View project task details' },
	{ id: 'addProjectTask', name: 'Add project task' },
	{ id: 'modifyProjectTask', name: 'Modify project task' },
	{ id: 'archiveProjectTask', name: 'Archive project task' },
];

export const REPORTS_ACCESS_OPTIONS = [
	{ id: 'viewReport', name: 'View report' },
	{ id: 'peopleReports', name: 'People reports' },
	{ id: 'pipelineReports', name: 'Pipeline reports' },
	{ id: 'salesReports', name: 'Sales reports' },
	{ id: 'projectReports', name: 'Project reports' },
	{ id: 'projectAccountingReports', name: 'Project accounting reports' },
];

export const ATTENDANCE_ACCESS_OPTIONS = [
	{id: 'viewOthersAttendance', name: 'View others attendance'},
	{id: 'editOthersAttendance', name: 'View others attendance'},
]