import React, { useContext } from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';
import AvatarField from '../AvatarField.component';
import { DEFAULT_PROFILE_PIC } from '../../config/constant';
import NotesPopOver from '../Notes/NotesPopOver.component';
import { ellipsisStyle } from '../../Layout/styles';
import { NoteForm, NoteList } from './model';
import { UserProfileContext } from '../../App';

const useStyles = makeStyles({
	container: {
		overflowY: 'auto',
		width: '100%',
		height: '400px',
	},
	listContainer: {
		border: '1px solid #E0E0E0',
		minHeight: '50px',
		marginTop: '30px',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '25px',
	},
	avatar: {
		display: 'flex',
		marginTop: '2px',
	},
	description: {
		color: '#5C5C5C',
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		marginLeft: '7px',
		marginTop: '5px',
	},
	noteTitle: {
		marginLeft: '7px',
		marginTop: '3px',
		color: '#292929',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
		width: '300px',
	},
	date: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '8px',
		color: '#5C5C5C',
		fontFamily: 'Manrope-Medium',
		fontSize: '12px',
	},
	noData: {
		marginTop: '5px',
	},
});

interface Props {
	getNoteList: NoteForm[];
	refetch: () => void;
	saveForm: (note: NoteForm) => void;
	deleteNote: (noteId: string) => void;
	List: NoteList[];
	noteId: (id: string) => void;
	smallWidthDescription?: boolean;
	permission?: boolean;
	deletePermission?: boolean;
}

const NotesList = (props: Props) => {
	const {
		List,
		refetch,
		saveForm,
		deleteNote,
		noteId,
		getNoteList,
		smallWidthDescription,
		permission,
		deletePermission,
	} = props;
	const classes = useStyles();
	const styles = ellipsisStyle();
	const { dateFormat } = useContext<any>(UserProfileContext);

	return (
		<>
			<Box className={classes.container}>
				{List && List?.length > 0 ? (
					List?.map((list: NoteList) => {
						return (
							<Box className={classes.listContainer}>
								<Box className={classes.toolbar}>
									<Tooltip title={`${list.title}`} placement='right'>
										<Box
											className={[classes.noteTitle, styles.ellipsis].join(' ')}
										>
											{list.title}
										</Box>
									</Tooltip>
									<Box display='flex' className={classes.avatar}>
										<Tooltip
											title={`${list.user?.full_name}`}
											placement='right'
										>
											<Box>
												<AvatarField
													className={classes.avatar}
													firstName={'sss'}
													lastName={''}
													imageSrc={`${
														list.user?.profile_pic || DEFAULT_PROFILE_PIC
													}`}
													size='30'
												/>
											</Box>
										</Tooltip>
										{permission ? (
											<NotesPopOver
												NoteId={list?.id}
												refetch={refetch}
												saveForm={saveForm}
												deleteNote={deleteNote}
												noteId={noteId}
												getNoteList={getNoteList}
												deletePermission={deletePermission}
											/>
										) : null}
									</Box>
								</Box>
								<Tooltip title={`${list.note || '--'}`} placement='right'>
									<Box
										className={classes.description}
										width={smallWidthDescription ? '370px' : '500px'}
									>
										{list.note}
									</Box>
								</Tooltip>
								<Box className={classes.date}>
									{dayjs(list?.created_at).format(dateFormat)}
								</Box>
							</Box>
						);
					})
				) : (
					<Box className={classes.noData}>No Data to show</Box>
				)}
			</Box>
		</>
	);
};

export default NotesList;
