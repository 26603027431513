export const EDIT_PROJECT_STATUS_MESSAGE = 'Edit Project Status';
export const ADD_PROJECT_STTAUS_MESSAGE = 'Add Project Status';
export const UPDATE_SUCCESS_MESSAGE = 'Updated Project Status Successfully';
export const INSERT_SUCCESS_MESSAGE = 'Added Project Status Successfully';
export const PROJECT_STATUS_NAME_UNIQUE_ERROR =
	'Project Status Name already exists';
export const PROJECT_STATUS_DELETE_SUCCESS_MESSAGE =
	'Project Status Deleted successfully';
export const DELETE_WARNING_MESSAGE =
	'Do you really want to delete this Project Status?';
export const WARNING_MODAL_MESSAGE =
	'Unable to delete as there is associated data';

//Constants for project category
export const EDIT_PROJECT_CATEGORY_MESSAGE = 'Edit Project Category';
export const ADD_PROJECT_CATEGORY_MESSAGE = 'Add Project Category';
export const UPDATE_CATEGORY_SUCCESS_MESSAGE =
	'Updated Project Category Successfully';
export const INSERT_CATEGORY_SUCCESS_MESSAGE =
	'Added Project Category Successfully';
export const PROJECT_CATEGORY_NAME_UNIQUE_ERROR =
	'Project Category Name already exists';
export const PROJECT_CATEGORY_DELETE_SUCCESS_MESSAGE =
	'Project Category Deleted successfully';
export const DELETE_CATEGORY_WARNING_MESSAGE =
	'Do you really want to delete this Project Category?';
export const WARNING_CATEGORY_MODAL_MESSAGE =
	'Unable to delete as there is associated data';
export const PROJECT_TYPE = [
	{ id: 'project', name: 'project' },
	{ id: 'project_task', name: 'project_task' },
];
export const PROJECT_CATEGORY_UNIQUE_KEY =
	'project_category_name_org_id_type_key';

//constants for project label
export const EDIT_PROJECT_LABEL_MESSAGE = 'Edit Project Label';
export const ADD_PROJECT_LABEL_MESSAGE = 'Add Project Label';
export const UPDATE_LABEL_SUCCESS_MESSAGE =
	'Updated Project Label Successfully';
export const INSERT_LABEL_SUCCESS_MESSAGE = 'Added Project Label Successfully';
export const PROJECT_LABEL_NAME_UNIQUE_ERROR =
	'Project Label Name already exists';
export const PROJECT_LABEL_DELETE_SUCCESS_MESSAGE =
	'Project Label Deleted successfully';
export const DELETE_LABEL_WARNING_MESSAGE =
	'Do you really want to delete this Project Label?';
export const WARNING_LABEL_MODAL_MESSAGE =
	'Unable to delete as there is associated data';
export const PROJECT_LABEL_UNIQUE_KEY = 'project_tags_name_org_id_key';

//contants for project resource tag
export const EDIT_PROJECT_TAG_MESSAGE = 'Edit Tag';
export const ADD_PROJECT_TAG_MESSAGE = 'Add Tag';
export const UPDATE_TAG_SUCCESS_MESSAGE = 'Updated Tag Successfully';
export const INSERT_TAG_SUCCESS_MESSAGE = 'Added Tag Successfully';
export const PROJECT_TAG_NAME_UNIQUE_ERROR = 'Tag already exists';
export const PROJECT_TAG_DELETE_SUCCESS_MESSAGE = 'Tag Deleted successfully';
export const DELETE_TAG_WARNING_MESSAGE =
	'Do you really want to delete this Tag?';
export const WARNING_TAG_MODAL_MESSAGE =
	'Unable to delete as there is associated data';
export const PROJECT_TAG_UNIQUE_KEY = 'project_resource_tag_name_org_id_key';
