import { Col, Row, Spin, Typography,  } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
const { Text } = Typography;
interface Props {
  count: number | string
  isLoading?: boolean
}

export const ContractorCount = (props:Props ) => {
	const { count, isLoading } = props

	return (
    <div>
    {isLoading ? (
      <Spin />
    ) : (
      <Row
        justify='center'
        align='middle'
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Text>{count}</Text>
        </Col>
      </Row>
    )}
  </div>
  );
};
