export const rowsPerPageOptionsCount = [15, 25, 50];
export const sectorChoices = [
	{ id: 'Oil Gas', name: 'Oil Gas' },
	{ id: 'Telecom', name: 'Telecom' },
	{ id: 'Retail', name: 'Retail' },
	{ id: 'Insurance', name: 'Insurance' },
	{ id: 'Banking ', name: 'Banking ' },
	{ id: 'Helath care', name: 'Helath care' },
	{ id: 'Other', name: 'Other' },
];

export const categoryChoices = [
	{ id: 'Enterprise', name: 'Enterprise' },
	{ id: 'Mid market', name: 'Mid market' },
	{ id: 'SME', name: 'SME' },
	{ id: 'Startup', name: 'Startup' },
];

export const sizeChoices = [
	{ id: '0-25', name: '0-25' },
	{ id: '25-50', name: '25-50' },
	{ id: '50-100', name: '50-100' },
	{ id: '100-200', name: '100-200' },
	{ id: 'above 200', name: 'above 200' },
];

export const contactChoices = [
	{ id: 'Current Customer', name: 'Current Customer' },
	{
		id: 'Potential Customer',
		name: 'Potential Customer',
	},

	{ id: 'Other', name: 'Other' },
];

export const breadcumData = [{ name: 'Company', path: '/crm_company' }];

export const COMPANY_PROJECTLIST_EXPORT_COLUMN = [
	'NAME',
	'PROJECT OWNER',
	'START DATE',
	'END DATE',
	'PROJECT STATUS',
];
