import React from "react";
import keyBy from "lodash/keyBy";
import {
  Datagrid,
  TextField,
  ListContextProvider,
  useQuery,
  Loading,
  DateField,
  DeleteButton,
  FunctionField,
} from "react-admin";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    marginTop: "10px",
  },
});

export const MilestoneList = ({ projectId, onEdit }) => {
  const classes = useStyles();
  const { data: milestones, loading } = useQuery({
    type: "GET_LIST",
    resource: "project_milestone",
    payload: {
      sort: { field: "id", order: "ASC" },
      filter: { project_id: projectId || null },
    },
  });

  if (loading) {
    return <Loading />;
  }

  const onEditRowClick = (milestoneId) => {
    onEdit(milestoneId);
  };
  return (
    <ListContextProvider
      value={{
        data: keyBy(milestones, "id"),
        ids: milestones ? milestones.map(({ id }) => id) : "",
        currentSort: { field: "id", order: "ASC" },
        basePath: `/project/${projectId}`,
        resource: "project_milestone",
        selectedIds: [],
      }}
    >
      <>
        {milestones?.length > 0 ? (
          <Datagrid rowClick={(milestoneId) => onEditRowClick(milestoneId)}>
            <TextField source="name" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <FunctionField
              label="Status"
              render={(record) =>
                `${
                  record.status === "InProgress"
                    ? "In Progress"
                    : record.status === "PaymentReceived"
                    ? "Payment Received"
                    : record.status === "PaymentPending"
                    ? "Payment Pending"
                    : record.status
                }`
              }
            />
            <DeleteButton
              confirmTitle={"Delete Milestone"}
              confirmContent={"Are you sure you want to delete this item?"}
              undoable={false}
              label={null}
            />
          </Datagrid>
        ) : (
          ""
        )}
        {milestones?.length === 0 ? (
          <Typography className={classes.title}>
            No milestones found...
          </Typography>
        ) : (
          ""
        )}
      </>
    </ListContextProvider>
  );
};

export default MilestoneList;
