import React from 'react';
import { Tooltip, makeStyles, withStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoIcon from '@material-ui/icons/Info';

export const GoalRatingInputStyle = makeStyles({
	goalRatingInputContainerWithIcon: {
		width: '114px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		display: 'flex',
		padding: '0px 0px 0px 8px',
	},
	goalRatingInputContainerWithIconAndError: {
		width: '114px',
		height: '36px',
		background: '#FFFFFF',
		border: '2px solid #EA4335',
		borderRadius: '4px',
		display: 'flex',
		padding: '0px 0px 0px 8px',
	},
	goalRatingInputContainerWithoutIcon: {
		width: '84px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
	},
	disabled: {
		width: '84px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		background: '#f0f0f0 !important',
	},
	goalRatingInputContainerWithoutIconAndError: {
		width: '84px',
		height: '36px',
		background: '#FFFFFF',
		border: '2px solid #EA4335',
		borderRadius: '4px',
	},
	goalRatingInputSmContainerWithoutIcon: {
		width: '58px',
		height: '36px',
		background: '#FFFFFF',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputWithIcon: {
		border: 'none',
		background: 'none',
		padding: '3px 0px !important',
		width: '74px',
		fontSize: '20px',
		fontFamily: 'Manrope-medium',
		color: '#343434',
		margin: '0px 0px 0px 6px',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	inputWithOutIcon: {
		border: 'none',
		background: 'none',
		margin: '3px',
		padding: '3px 0px !important',
		width: '42px',
		fontSize: '13px',
		fontFamily: 'Manrope-medium',
		color: '#343434',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	errorIcon: {
		width: '15px',
		height: '15px',
		marginLeft: '4px',
		color: '#EA4335',
		cursor: 'pointer',
	},
});

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

interface GoalRatingInputProps {
	value: string;
	onChange: (time: string) => void;
	iconVisiblity?: Boolean;
	name?: string;
	size?: string;
	isDisabled?: boolean;
	isError?: boolean;
	errorText?: string;
}

function GoalRatingInput(props: GoalRatingInputProps) {
	const {
		value,
		onChange,
		iconVisiblity = true,
		name,
		size = 'large',
		isDisabled = false,
		isError,
		errorText = 'Invalid',
	} = props;
	const timeInputStyles = GoalRatingInputStyle();

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<div
				className={`${
					isDisabled
						? timeInputStyles.disabled
						: iconVisiblity
						? isError
							? timeInputStyles.goalRatingInputContainerWithIconAndError
							: timeInputStyles.goalRatingInputContainerWithIcon
						: !iconVisiblity && isError
						? timeInputStyles.goalRatingInputContainerWithoutIconAndError
						: timeInputStyles.goalRatingInputContainerWithoutIcon
				}
      ${
				size === 'small' &&
				timeInputStyles.goalRatingInputSmContainerWithoutIcon
			}
      `}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{iconVisiblity && (
						<AccessTimeIcon
							style={{
								color: '#555555',
							}}
						/>
					)}
					<input
						type='text'
						className={
							iconVisiblity
								? timeInputStyles.inputWithIcon
								: timeInputStyles.inputWithOutIcon
						}
						name={name}
						value={value}
						onChange={(e: any) => {
							onChange(e);
						}}
						disabled={isDisabled}
					/>
				</div>
			</div>
			{isError && (
				<ErrorTooltip title={errorText} placement='top' arrow={true}>
					<InfoIcon className={timeInputStyles.errorIcon} />
				</ErrorTooltip>
			)}
		</div>
	);
}

export default GoalRatingInput;
