import React, { useEffect, useState } from 'react';
import { Box, Slider, TextField } from '@material-ui/core';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
const textBoxTheme = createTheme({
	overrides: {
		MuiOutlinedInput: {
			root: {
				height: '35px',
				fontSize:'14px',
				fontFamily: 'Manrope-medium',
				paddingTop: '3px'
			},
		},
	},
});

export const SliderInput = (props) => {
	const { initialValue, onChange, min, max, disabled } = props;
	const [value, setValue] = useState(initialValue);
	const onSliderValueChange = (event, newValue) => {
		setValue(newValue);
		onChange(newValue);
	};

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);
	return (
		<Box display='flex'>
			<Box width='150px' marginTop='4px' marginRight='12px' marginleft='4px'>
				<Slider
					onChange={onSliderValueChange}
					min={min}
					max={max}
					value={value}
					step={5}
					disabled={disabled}
				/>
			</Box>

			<Box width='65px' height='36px' paddingRight='10px'>
				<ThemeProvider theme={textBoxTheme}>
					<TextField variant='outlined' value={value} label='' disabled />
				</ThemeProvider>
			</Box>
		</Box>
	);
};
