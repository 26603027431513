import { ContentState, CompositeDecorator, ContentBlock } from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import React from 'react';

export function findLinkEntities(
	contentBlock: ContentBlock,
	callback: (start: number, end: number) => void,
	contentState: ContentState
) {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'LINK'
		);
	}, callback);
}

export const Link = (props: any) => {
	return (
		<a style={{ color: '#4285F4' }} href={'#/'}>
			{props.children}
		</a>
	);
};

export const decorator = new CompositeDecorator([
	{
		strategy: findLinkEntities,
		component: Link,
	},
]);

export const draftDecorator = {
	decorators: [
		{
			strategy: findLinkEntities,
			component: Link,
		},
	],
};

export const mentionPlugin = createMentionPlugin({
	entityMutability: 'IMMUTABLE',
	supportWhitespace: true,
});
export const { MentionSuggestions } = mentionPlugin;
export const plugins = [mentionPlugin, draftDecorator];

export const customEntityTransform = (entity: any, text: string) => {
	if (entity.type !== 'mention') return;
	return `<a href="">${text}</a>`;
};
