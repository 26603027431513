import { gql } from '@apollo/client';

export const GET_ORGANISATION_SUPPORTED_CURRENCIES = gql`
	query getOrganisationSupportedCurrencies($organizationId: uuid!) {
		org_supported_currencies(
			where: { org_id: { _eq: $organizationId } }
			order_by: { currency: { currency_type: asc } }
		) {
			id
			currency_id
			currency {
				id
				currency_type
				symbol
			}
			organization {
				currency_id
				name
			}
		}
	}
`;

export const GET_ORGANISATION = gql`
	query getOrganisation($organizationId: uuid!) {
		organization(where: { id: { _eq: $organizationId } }) {
			id
			name
			financial_year_start_month
			financial_year_start_date
			financial_year_end_month
			financial_year_end_date
			currency {
				id
				currency_type
				symbol
			}
		}
	}
`;

export const GET_ORGANIZATION_NOTICE_PERIOD_DAYS = gql`
	query getOrganizationNoticePeriodDay($organizationId: uuid!) {
		organization_by_pk(id: $organizationId) {
			notice_period_days
		}
	}
`;

export const GET_ORGANIZATION_PROBABTION_DAY = gql`
	query getOrganizationProbationDay($organizationId: uuid!) {
		organization_by_pk(id: $organizationId) {
			probation_day
		}
	}
`;
