import gql from 'graphql-tag';

export const GET_PROJECT_TOTAL = gql`
	query getProjectTotal($projectName: String!) {
		project_aggregate(where: { name: { _ilike: $projectName } }) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_PROJECT_STATUS = gql`
	query getProjectStatus($name: String!, $limit: Int!) {
		project_list(
			distinct_on: project_status_id
			where: {
				ProjectStatus: { label: { _ilike: $name } }
				project_status_id: { _is_null: false }
			}
			limit: $limit
		) {
			ProjectStatus {
				id
				label
			}
		}
		project_list_aggregate(
			where: { project_status_id: { _is_null: false } }
			distinct_on: project_status_id
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_PROJECT_OWNER = gql`
	query getProjectOwners($limit: Int!, $name: String) {
		project_list(
			distinct_on: project_owner_id
			where: {
				project_owner_id: { _is_null: false }
				ProjectOwner: { full_name: { _ilike: $name }, is_active: { _eq: true } }
			}
			limit: $limit
		) {
			ProjectOwner {
				full_name
				id
			}
		}
		project_list_aggregate(
			distinct_on: project_owner_id
			where: {
				project_owner_id: { _is_null: false }
				ProjectOwner: { full_name: { _ilike: $name }, is_active: { _eq: true } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_ALL_PROJECT_OWNER = gql`
	query getAllProjectOwners {
		project_list(
			distinct_on: project_owner_id
			where: { project_owner_id: { _is_null: false } }
		) {
			ProjectOwner {
				full_name
				id
			}
		}
	}
`;
export const GET_ALL_COMPANIES = gql`
	query getCrmCompanies {
		companies: crm_company(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_EXACT_PROJECT_OWNERS = gql`
	query getExactProjectOwner {
		project(
			where: { project_owner_id: { _is_null: false } }
			distinct_on: [project_owner_id]
		) {
			project_owner_id
			user {
				id
				first_name
				last_name
			}
		}
		project_aggregate(
			where: { project_owner_id: { _is_null: false } }
			distinct_on: [project_owner_id]
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_PROJECT_RISKS = gql`
	query getProjectRisks {
		project_list(
			distinct_on: [risk_status]
			order_by: { risk_status: desc }
			where: { risk_status: { _is_null: false } }
		) {
			risk_status
		}
	}
`;

export const DELETE_PROJECT = gql`
	mutation deleteProject($projectId: uuid!) {
		delete_timesheet(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_project_kanban_card(
			where: { project_task: { project_id: { _eq: $projectId } } }
		) {
			returning {
				id
			}
		}
		delete_project_task_comments(
			where: { project_task: { project_id: { _eq: $projectId } } }
		) {
			returning {
				id
			}
		}
		delete_project_task_docs(
			where: { project_task: { project_id: { _eq: $projectId } } }
		) {
			returning {
				id
			}
		}

		delete_project_task(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_project_resource_mapping(
			where: { project_id: { _eq: $projectId } }
		) {
			returning {
				id
			}
		}
		delete_project_milestone(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_project_skill_requirement(
			where: { project_id: { _eq: $projectId } }
		) {
			returning {
				id
			}
		}
		delete_project_docs(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_audit_audit_project(
			where: { project: { id: { _eq: $projectId } } }
		) {
			returning {
				action
			}
		}
		delete_project_comments(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_project_delay_mapping(where: { project_id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
		delete_project(where: { id: { _eq: $projectId } }) {
			returning {
				id
			}
		}
	}
`;

export const GET_PROJECT_LIST = gql`
	query getProjectList($filters: project_list_bool_exp = {}) {
		project_list(where: $filters, order_by: { name: asc }) {
			id: id
			name
			value: id
			label: name
			company_id
		}
		org_tasks(distinct_on: task_category) {
			id: task_category
			name: task_category
			value: task_category
			label: task_category
		}
	}
`;

export const GET_TIMESHEET_BY_PROJECT = gql`
	query getTimesheetByProjectId(
		$filters: timesheet_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		timesheet(order_by: { date: asc }, where: $filters) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			userByEmployeeId {
				full_name
				email
				id
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
		projectResources: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				full_name
				first_name
				last_name
				user_type
				email
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
			project {
				currency {
					symbol
				}
			}
			project_resource_mapping_details_aggregate {
				aggregate {
					max {
						cost
					}
				}
			}
		}
	}
`;
export const GET_PROJECT_ASIGNEES = gql`
	query getProjectAssignedUsers($project_id: uuid) {
		project_resource_mapping(
			where: { project_id: { _eq: $project_id } }
			distinct_on: employee_id
		) {
			user {
				first_name
				last_name
				full_name
				id
			}
		}
	}
`;

export const GET_TIMESHEET_HOURS_BY_PROJECT = gql`
	query getTimesheetHoursByProjectId($filters: timesheet_bool_exp = {}) {
		timesheet(order_by: { date: asc }, where: $filters) {
			id
			working_hours
			is_billable
		}
	}
`;

export const GET_PROJECT_TYPE = gql`
	query getProjectType {
		project_list(distinct_on: project_type) {
			project_type
			id
		}
	}
`;

export const GET_PROJECT_ACTUAL_TIME = gql`
	query GetActaulProjectTime($projectId: uuid!) {
		timesheet(
			where: {
				project_id: { _eq: $projectId }
				timesheet_submission: {
					timesheet_status: { value: { _in: ["submitted", "approved"] } }
				}
			}
		) {
			working_hours
			timesheet_submission {
				timesheet_status {
					label
				}
			}
		}
	}
`;

export const GET_PROJECT_ACTUAL_COST = gql`
	query GetActualProjectCost($projectId: uuid!) {
		timesheet(
			where: {
				project_id: { _eq: $projectId }
				is_billable: { _eq: true }
				timesheet_submission: {
					timesheet_status: { value: { _in: ["submitted", "approved"] } }
				}
			}
		) {
			working_hours
			userByEmployeeId {
				project_resource_mappings_aggregate(
					where: {
						_and: [
							{ project_id: { _eq: $projectId } }
							{ deleted_at: { _is_null: true } }
						]
					}
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
		timesheet_overtime(
			where: {
				project_id: { _eq: $projectId }
				is_billable: { _eq: true }
				timesheet_overtime_submission: {
					timesheet_status: { value: { _in: ["submitted", "approved"] } }
				}
			}
		) {
			working_hours
			userByEmployeeId {
				project_resource_mappings_aggregate(
					where: {
						_and: [
							{ project_id: { _eq: $projectId } }
							{ deleted_at: { _is_null: true } }
						]
					}
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
	}
`;

export const GET_TIMESHEET_PROJECT_ASIGNEES = gql`
	query getTimesheetProjectAssignedUsers($filters: timesheet_bool_exp = {}) {
		timesheet(where: $filters, distinct_on: user_id) {
			userByEmployeeId {
				full_name
				id
			}
		}
	}
`;

export const GET_PROJECT_STATUS_IN_PROJECT_LIST = gql`
	query getProjectStatusInProjectList {
		project_list(
			distinct_on: project_status_id
			where: { project_status_id: { _is_null: false } }
		) {
			ProjectStatus {
				id
				label
				value
			}
		}
	}
`;

export const GET_GENERAL_TASK_TIMESHEET = gql`
	query getGeneralTimesheet(
		$filters: timesheet_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_optional_holiday_mappings(where: $optionalHolidayFilter) {
						org_holiday {
							date
						}
					}
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_TASK_TIMESHEET_HOURS = gql`
	query getGeneralTimesheetHours($filters: timesheet_bool_exp = {}) {
		org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				is_billable
			}
		}
	}
`;

export const GET_PROJECT_FILTER = gql`
	query getProjectDataGridLists(
		$project: project_bool_exp = {}
		$limit: Int!
		$offset: Int!
		$sortFilter: [project_order_by!]
	) {
		project(
			where: $project
			order_by: $sortFilter
			limit: $limit
			offset: $offset
		) {
			id
			end_date
			name
			prefix
			start_date
			project_type
			project_execution_type
			effort_estimate
			revenue
			conversion_probability
			description
			project_tag {
				id
				name
			}
			resourceList: project_resource_mappings(
				where: { deleted_at: { _is_null: true } }
				distinct_on: employee_id
			) {
				id
				user {
					full_name
					email
				}
			}
			userByPreSalesOwnerId {
				first_name
				last_name
				full_name
				email
			}
			currency {
				symbol
			}
			geo_list {
				name
			}
			total_cost
			is_billable
			company {
				name
			}
			user {
				first_name
				last_name
				full_name
				email
			}
			risk_status
			ProjectStatus {
				label
				value
				id
			}
			project_resource_mappings_aggregate {
				aggregate {
					count
				}
			}
			totalTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			completedTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
					project_kanban_cards: { kanban_column: { state: { _eq: "DONE" } } }
				}
			) {
				aggregate {
					count
				}
			}
			totalMilestones: project_milestones_aggregate(
				where: { deleted_at: { _is_null: true } }
			) {
				aggregate {
					count
				}
			}
			completedMilestones: project_milestones_aggregate(
				where: {
					deleted_at: { _is_null: true }
					project_milestone_status: { value: { _eq: "complete" } }
				}
			) {
				aggregate {
					count
				}
			}
			plannedHours: project_resource_mappings_aggregate(
				where: { is_billable: { _eq: true }, deleted_at: { _is_null: true } }
			) {
				aggregate {
					sum {
						total_cost
						total_hours
					}
				}
			}
			timesheets(
				where: {
					is_billable: { _eq: true }
					timesheet_submission: {
						timesheet_status: { value: { _in: ["submitted", "approved"] } }
					}
				}
			) {
				working_hours
			}
		}
		project_aggregate(where: $project) {
			aggregate {
				count
			}
		}
	}
`;
export const GET_PROJECT_EXPORT_FILTER = gql`
	query getProjectDataExportGridList(
		$project: project_bool_exp = {}
		$sortFilter: [project_order_by!]
	) {
		project(where: $project, order_by: $sortFilter) {
			id
			end_date
			name
			prefix
			start_date
			project_type
			project_execution_type
			effort_estimate
			revenue
			conversion_probability
			description
			project_tag {
				id
				name
			}
			resourceList: project_resource_mappings(
				where: { deleted_at: { _is_null: true } }
				distinct_on: employee_id
			) {
				id
				user {
					full_name
					email
				}
			}
			userByPreSalesOwnerId {
				first_name
				last_name
				full_name
				email
			}
			currency {
				symbol
			}
			geo_list {
				name
			}
			total_cost
			is_billable
			company {
				name
			}
			user {
				first_name
				last_name
				full_name
				email
			}
			risk_status
			ProjectStatus {
				label
				value
				id
			}
			project_resource_mappings_aggregate {
				aggregate {
					count
				}
			}
			totalTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			completedTask: project_tasks_aggregate(
				where: {
					parent_task_id: { _is_null: true }
					deleted_at: { _is_null: true }
					project_kanban_cards: { kanban_column: { state: { _eq: "DONE" } } }
				}
			) {
				aggregate {
					count
				}
			}
			totalMilestones: project_milestones_aggregate(
				where: { deleted_at: { _is_null: true } }
			) {
				aggregate {
					count
				}
			}
			completedMilestones: project_milestones_aggregate(
				where: {
					deleted_at: { _is_null: true }
					project_milestone_status: { value: { _eq: "complete" } }
				}
			) {
				aggregate {
					count
				}
			}
			plannedHours: project_resource_mappings_aggregate(
				where: { is_billable: { _eq: true }, deleted_at: { _is_null: true } }
			) {
				aggregate {
					sum {
						total_cost
						total_hours
					}
				}
			}
			timesheets(
				where: {
					is_billable: { _eq: true }
					timesheet_submission: {
						timesheet_status: { value: { _in: ["submitted", "approved"] } }
					}
				}
			) {
				working_hours
			}
		}
		project_aggregate(where: $project) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_RESOURCE_COUNT = gql`
	query getResourceCount {
		project_resource_mapping(order_by: { user: { first_name: asc } }) {
			project_id
			user {
				id
				full_name
				first_name
				last_name
				email
			}
		}
	}
`;

export const GET_PLANNED_HOURS = gql`
	query getPlannedHours($filters: project_resource_mapping_bool_exp = {}) {
		project_resource_mapping(
			where: $filters
			order_by: { start_date: asc, end_date: desc }
		) {
			start_date
			end_date
			allocation_percentage
			project_id
		}
		organization(where: { working_hours: { _is_null: false } }) {
			working_hours
		}
	}
`;

export const GET_PROJECT_TASK_BY_MILESTONEID = gql`
	query getProjectTaskByMilestoneId($milesStoneId: uuid!) {
		project_task_aggregate(
			where: {
				deleted_at: { _is_null: true }
				project_milestone_id: { _eq: $milesStoneId }
				parent_task_id: { _is_null: true }
				project_kanban_cards: { kanban_column: { state: { _neq: "DONE" } } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_COMPANY_LIST = gql`
	query getCompanyList {
		crm_company {
			id
			name
		}
	}
`;

export const GET_PROJECT_RESOURCES = gql`
	query getProjectResources {
		project_resource_mapping(distinct_on: employee_id) {
			project_id
			user {
				id
				full_name
				first_name
				last_name
				email
			}
		}
	}
`;

export const GET_PROJECT_LABELS = gql`
	query getProjectLabels {
		project_tags(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_PROJECT_CATEGORIES = gql`
	query getProjectCategories {
		project_category(
			order_by: { name: asc }
			where: { type: { _eq: "project" } }
		) {
			id
			name
		}
	}
`;

export const GET_PROJECT_ID_FROM_PROJECT_LIST = gql`
	query getProjectIdsFromProjectList($project: project_list_bool_exp = {}) {
		project_list(where: $project) {
			id
		}
	}
`;
export const GET_PROJECTS = gql`
	query getProjectListOptions {
		project_list(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const GET_MILESTONES_BY_PROJECT_ID = gql`
	query getMilestonesByProjectId(
		$id: uuid!
		$filter: project_task_bool_exp = {}
	) {
		project_milestone(
			where: { project_id: { _eq: $id } }
			order_by: { ordinal: asc }
		) {
			completedTaskCount: project_tasks_aggregate(
				where: {
					deleted_at: { _is_null: true }
					parent_task_id: { _is_null: true }
					project_kanban_cards: { kanban_column: { state: { _eq: "DONE" } } }
				}
			) {
				aggregate {
					count
				}
			}
			totalTaskCount: project_tasks_aggregate(
				where: {
					deleted_at: { _is_null: true }
					parent_task_id: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			project_tasks(where: $filter) {
				id
				title
				card_name
				story_point
				project_id
				project_milestone_id
				story_point
				description
				planned_end_date
				planned_start_date
				reminder
				priority
				planned_hours
				task_number
				is_billable
				assigned_to
				completion_percentage
				project_task_category {
					name
				}
				assignedTo {
					first_name
					last_name
					full_name
					profile_pic
				}
				status
				project_tasks_aggregate {
					aggregate {
						count
					}
				}
				project_kanban_cards {
					id
					kanban_column {
						name
					}
				}
				project {
					id
					prefix
				}
			}
			name
			id
		}
	}
`;
export const GET_PROJECT_KANBAN = gql`
	query getKanbanBoardByProjectId($id: uuid!) {
		project_by_pk(id: $id) {
			kanban_board {
				kanban_columns(order_by: { name: asc }) {
					id
					name
				}
			}
		}
	}
`;
export const GET_PROJECT_STATUS_AUDIT = gql`
	query getProjectAuditHistory($projectId: uuid!) {
		audit_audit_project(
			order_by: { action_tstamp: asc }
			where: { action_on: { _eq: $projectId } }
		) {
			user {
				id
				full_name
			}
			project {
				id
				name
			}
			action_tstamp
			action
			action_type
			action_data
		}
	}
`;

export const GET_PROJECT_COMMENTS = gql`
	query getProjectCommentsByProjectId($project_id: uuid!) {
		project_comments(
			where: { project_id: { _eq: $project_id } }
			order_by: { updated_at: desc }
		) {
			id
			project_id
			comment
			updated_at
			created_by
			createdBy {
				first_name
				last_name
				full_name
			}
		}
		project_delay_mapping(where: { project_id: { _eq: $project_id } }) {
			id
			reason_id
			created_at
			changed_from
			changed_to
			notes
			project_delay_reason {
				id
				label
			}
			createdBy {
				id
				full_name
			}
		}
	}
`;

export const CREATE_PROJECT_COMMENT = gql`
	mutation createProjectComment($comment: project_comments_insert_input!) {
		insert_project_comments_one(
			object: $comment
			on_conflict: {
				constraint: project_comments_pkey
				update_columns: comment
			}
		) {
			id
			comment
		}
	}
`;

export const DELETE_TASK_COMMENT = gql`
	mutation deleteProjectCommentById($id: uuid!) {
		delete_project_comments(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const GET_HOLIDAYS_FOR_SUMMARY = gql`
	query getHolidaysForSummary($filter: org_holidays_bool_exp = {}) {
		org_holidays(where: $filter) {
			id
			name
			date
			is_restricted
			org_location {
				id
				name
			}
		}
	}
`;
export const GET_GENERAL_TASK_TIMESHEET_FOR_SUMMARY = gql`
	query getGeneralTimesheetForSummaryReport(
		$filters: timesheet_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
	) {
		org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					email
					id
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					attendance(where: $attendanceFilters) {
						id
						date
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						client_rate
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_TIMESHEET_BY_PROJECT_FOR_SUMMARY = gql`
	query getTimesheetByProjectIdForSummaryReport(
		$filters: timesheet_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		timesheet(order_by: { date: asc }, where: $filters) {
			id
			is_billable
			working_hours
			date
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				id
				currency {
					symbol
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					emp_id
					org_location {
						id
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				attendance(where: $attendanceFilters) {
					id
					date
				}
				project_resource_mappings_aggregate(
					where: {
						project_id: { _in: $projectId }
						deleted_at: { _is_null: true }
					}
				) {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
		}
		projectResources: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				email
				full_name
				first_name
				last_name
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					emp_id
					org_location {
						id
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { deleted_at: { _is_null: true } }
				) {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
			project {
				currency {
					symbol
				}
			}
		}
	}
`;

export const GET_OVERTIME_BY_PROJECT = gql`
	query getOvertimeByProjectId(
		$filters: timesheet_overtime_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		timesheet_overtime(order_by: { date: asc }, where: $filters) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_overtime_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
		projectResources: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				full_name
				first_name
				last_name
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
				}
				project_resource_mappings_aggregate {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
			project {
				currency {
					symbol
				}
			}
			project_resource_mapping_details_aggregate {
				aggregate {
					max {
						cost
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_TASK_OVERTIME = gql`
	query getGeneralOvertime(
		$filters: timesheet_overtime_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		org_tasks(where: { timesheet_overtime: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheet_overtime(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					user_type
					email
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					user_optional_holiday_mappings(where: $optionalHolidayFilter) {
						org_holiday {
							date
						}
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_overtime_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_OVERTIME_BY_PROJECT_FOR_SUMMARY = gql`
	query getOvertimeByProjectIdForSummaryReport(
		$filters: timesheet_overtime_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		timesheet_overtime(order_by: { date: asc }, where: $filters) {
			id
			is_billable
			working_hours
			date
			timesheet_overtime_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				id
				currency {
					symbol
				}
			}
			user: userByEmployeeId {
				full_name
				email
				id
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				attendance(where: $attendanceFilters) {
					id
					date
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
		}
		projectResources: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				full_name
				email
				first_name
				last_name
				is_active
				project_resource_mappings_aggregate {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
			project {
				currency {
					symbol
				}
			}
		}
	}
`;
export const GET_GENERAL_TASK_OVERTIME_FOR_SUMMARY = gql`
	query getGeneralOvertimeForSummaryReport(
		$filters: timesheet_overtime_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
	) {
		org_tasks(where: { timesheet_overtime: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheet_overtime(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					attendance(where: $attendanceFilters) {
						id
						date
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						client_rate
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_overtime_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_OVERTIME_PROJECT_ASIGNEES = gql`
	query getOvertimeProjectAssignedUsers(
		$filters: timesheet_overtime_bool_exp = {}
	) {
		timesheet_overtime(where: $filters, distinct_on: user_id) {
			userByEmployeeId {
				full_name
				id
			}
		}
	}
`;
export const GET_RESOURCE_USER_LIST = gql`
	query getProjectResourceMappingList(
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		projectResources: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				full_name
				first_name
				last_name
				project_resource_mappings_aggregate {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
	}
`;
export const GET_PROJECT_SKILL_REQUIREMENT_LIST = gql`
	query getProjectSkillRequirmentList(
		$filter: project_skill_requirement_bool_exp = {}
		$sortFilter: [project_skill_requirement_order_by!]
		$limit: Int!
		$offset: Int!
	) {
		skill_level {
			id
			level
		}
		project_skill_request_status {
			label
			id
		}
		totalRequestCount: project_skill_requirement_aggregate(where: $filter) {
			aggregate {
				count
			}
		}
		project_skill_requirement(
			where: $filter
			order_by: $sortFilter
			limit: $limit
			offset: $offset
		) {
			project {
				name
			}
			id
			count
			notes
			skill_level {
				level
				id
			}
			skill_master {
				name
				id
			}
			project_skill_request_status {
				id
				label
			}
		}
	}
`;
export const UPDATE_PROJECT_SKILL_REQUIRMENT = gql`
	mutation updateProjectSkillRequirement(
		$id: uuid!
		$rowData: project_skill_requirement_set_input!
	) {
		update_project_skill_requirement(
			where: { id: { _eq: $id } }
			_set: $rowData
		) {
			returning {
				id
			}
		}
	}
`;
export const GET_PROJECT_LIST_FOR_RESOURCE_REQUEST_LIST = gql`
	query getProjectListForResourceRequestTable {
		project_skill_requirement(distinct_on: project_id) {
			project {
				id
				name
			}
		}
	}
`;
export const GET_REST_FILTERS_FOR_RESOURCE_REQUEST_LIST = gql`
	query getRestFiltersBasedOnProject(
		$filters: project_skill_requirement_bool_exp = {}
	) {
		skillList: project_skill_requirement(
			where: $filters
			distinct_on: skill_id
		) {
			skill_master {
				id
				name
			}
		}
		skillLevelList: project_skill_requirement(
			where: $filters
			distinct_on: skill_level_id
		) {
			skill_level {
				id
				level
			}
		}
		statusList: project_skill_requirement(
			where: $filters
			distinct_on: request_status_id
		) {
			project_skill_request_status {
				id
				label
			}
		}
	}
`;
export const EXPORT_PROJECT_SKILL_REQUIREMENT_LIST = gql`
	query getProjectSkillRequirmentListForExport(
		$filter: project_skill_requirement_bool_exp = {}
		$sortFilter: [project_skill_requirement_order_by!]
	) {
		skill_level {
			id
			level
		}
		project_skill_request_status {
			label
			id
		}
		totalRequestCount: project_skill_requirement_aggregate(where: $filter) {
			aggregate {
				count
			}
		}
		project_skill_requirement(where: $filter, order_by: $sortFilter) {
			project {
				name
			}
			id
			count
			notes
			skill_level {
				level
				id
			}
			skill_master {
				name
				id
			}
			project_skill_request_status {
				id
				label
			}
		}
	}
`;

export const GET_TASK_AND_SUBTASK_FOR_PROJECT_CLONE = gql`
	query getTasksAndMilestonesForProjectCloning($projectId: uuid!) {
		project_task(
			where: {
				project_id: { _eq: $projectId }
				deleted_at: { _is_null: true }
				parent_task_id: { _is_null: true }
			}
		) {
			title
			project_milestone_id
			parent_task_id
			category_id
			reminder
			is_billable
			planned_start_date
			planned_end_date
			planned_hours
			priority
			completion_percentage
			description
			project_kanban_cards {
				column_id
				ordinal
			}
			project_task_comments(where: { deleted_at: { _is_null: true } }) {
				comment
			}
		}
		project_milestone(where: { project_id: { _eq: $projectId } }) {
			name
			ordinal
			payment_status_id
			status
			start_date
			end_date
			link_to_invoice
			amount
			id
		}
	}
`;
export const CLONE_PROJECT = gql`
	mutation cloneProject($objects: project_insert_input! = {}) {
		insert_project_one(object: $objects) {
			id
		}
	}
`;
export const CLONE_PROJECT_TASK_AND_MILESTONE = gql`
	mutation cloneProjectTaskAndMilestones(
		$taskObjects: [project_task_insert_input!]! = {}
		$milestoneObjects: [project_milestone_insert_input!]! = {}
	) {
		insert_project_task(objects: $taskObjects) {
			returning {
				id
			}
		}
		insert_project_milestone(objects: $milestoneObjects) {
			returning {
				id
			}
		}
	}
`;

export const GET_DRAFT_TIMESHEET_FOR_SUMMARY = gql`
	query getDraftTimesheetByProjectIdForSummaryReport(
		$draftFilters: timesheet_bool_exp = {}
		$filters: timesheet_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		savedTimesheetList: timesheet(
			order_by: { date: asc }
			where: $draftFilters
		) {
			id
			is_billable
			working_hours
			date
			project {
				id
				currency {
					symbol
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				attendance(where: $attendanceFilters) {
					id
					date
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
		}
		draftStatusTimesheetList: timesheet(
			order_by: { date: asc }
			where: $filters
		) {
			id
			is_billable
			working_hours
			date
			project {
				id
				currency {
					symbol
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						id
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				attendance(where: $attendanceFilters) {
					id
					date
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
							client_rate
						}
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_DRAFT_TIMESHEET_FOR_SUMMARY = gql`
	query getGeneralDraftTimesheetForSummaryReport(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
		$attendanceFilters: attendance_bool_exp = {}
	) {
		savedTimesheets: org_tasks(where: { timesheets: $draftFilters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $draftFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					attendance(where: $attendanceFilters) {
						id
						date
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						client_rate
						project {
							currency {
								symbol
							}
						}
					}
				}
			}
		}
		draftStatusTimesheets: org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							id
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					attendance(where: $attendanceFilters) {
						id
						date
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						client_rate
						project {
							currency {
								symbol
							}
						}
					}
				}
			}
		}
	}
`;
export const GET_DRAFT_AND_SAVED_TIMESHEET_FOR_DAY_WISE = gql`
	query getDraftAndSavedTimesheetByProjectId(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
		$projectId: [uuid!]
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		savedTimesheet: timesheet(order_by: { date: asc }, where: $draftFilters) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
			}
			userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
		draftStatusTimesheet: timesheet(order_by: { date: asc }, where: $filters) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
			}
			userByEmployeeId {
				full_name
				id
				user_type
				email
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				user_optional_holiday_mappings(where: $optionalHolidayFilter) {
					org_holiday {
						date
					}
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
						id
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_DRAFT_AND_SAVED_TIMESHEET_FOR_DAY_WISE = gql`
	query getGeneralDraftAndSavedTimesheetForDaywise(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		draftStatusTimesheet: org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					user_optional_holiday_mappings(where: $optionalHolidayFilter) {
						org_holiday {
							date
						}
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
		savedTimesheet: org_tasks(where: { timesheets: $draftFilters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $draftFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					user_optional_holiday_mappings(where: $optionalHolidayFilter) {
						org_holiday {
							date
						}
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
							id
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_DRAFT_AND_UNSUBMITTED_TIMESHEET_HOURS_BY_PROJECT = gql`
	query getDraftAndUnSubmittedTimesheetHoursByProjectId(
		$filters: timesheet_bool_exp = {}
		$unSubmittedFilters: timesheet_bool_exp = {}
	) {
		draftTimesheetEntries: timesheet(order_by: { date: asc }, where: $filters) {
			id
			working_hours
			is_billable
		}
		unSubmittedTimesheetEntries: timesheet(
			order_by: { date: asc }
			where: $unSubmittedFilters
		) {
			id
			working_hours
			is_billable
		}
	}
`;
export const GET_GENERAL_DRAFT_AND_UNSUBMITTED_TIMESHEET_HOURS_BY_PROJECT = gql`
	query getGeneralDraftAndUnSubmittedTimesheetHoursByProjectId(
		$filters: timesheet_bool_exp = {}
		$unSubmittedFilters: timesheet_bool_exp = {}
	) {
		draftTimesheetEntries: org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				is_billable
			}
		}
		unSubmittedTimesheetEntries: org_tasks(
			where: { timesheets: $unSubmittedFilters }
		) {
			id
			title
			is_billable
			organization {
				name
			}
			timesheets(where: $unSubmittedFilters) {
				id
				working_hours
				is_billable
			}
		}
	}
`;
export const GET_DRAFT_AND_UNSUBMITTED_TIMESHHET_FOR_TASK_WISE = gql`
	query getDraftAndUnSubmittedTimesheetForTaskWise(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		savedTimesheetList: timesheet(
			order_by: { date: asc }
			where: $draftFilters
		) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			userByEmployeeId {
				full_name
				id
				user_type
				email
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
		draftStatusTimesheetList: timesheet(
			order_by: { date: asc }
			where: $filters
		) {
			id
			task_id
			is_billable
			working_hours
			date
			notes
			project_task {
				title
				id
				completion_percentage
			}
			master_project_task {
				title
				id
			}
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				name
				id
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
				project_resource_mappings_aggregate(
					where: { project_id: { _in: $projectId } }
				) {
					aggregate {
						max {
							resource_cost
						}
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_DRAFT_AND_UNSUBMITTED_TIMESHHET_FOR_TASK_WISE = gql`
	query getGeneralDraftAndUnSubmittedTimesheetForTaskWise(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
	) {
		savedTimesheets: org_tasks(where: { timesheets: $filters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $filters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					email
					id
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
		draftStatusTimesheet: org_tasks(where: { timesheets: $draftFilters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $draftFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					user_type
					email
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
					project_resource_mappings(order_by: { resource_cost: desc }) {
						id
						resource_cost
						project {
							currency {
								symbol
							}
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;
export const GET_DRAFT_AND_UNSUBMITTED_TIMESHEET_ENTERED_RESOURCES = gql`
	query getDraftAndUnSubmittedTimesheetEnteredResources(
		$filters: timesheet_bool_exp = {}
		$draftFilters: timesheet_bool_exp = {}
	) {
		unSubmittedResourceList: timesheet(where: $filters, distinct_on: user_id) {
			userByEmployeeId {
				full_name
				id
			}
		}
		draftStatusResourceList: timesheet(
			where: $draftFilters
			distinct_on: user_id
		) {
			userByEmployeeId {
				full_name
				id
			}
		}
	}
`;
export const GET_GENERAL_UNSUBMITTED_TIMESHEET_ENTERED_RESOURCES = gql`
	query getGeneralUnSubmittedTimesheetEnteredResources(
		$generalFilters: timesheet_bool_exp = {}
	) {
		generalUnSubmittedResourceList: timesheet(
			where: $generalFilters
			distinct_on: user_id
		) {
			userByEmployeeId {
				full_name
				id
			}
		}
	}
`;

export const GET_RESOURCE_ALLOCATIONS = gql`
	query getResourceAllocations(
		$resourceMappingFilter: project_resource_mapping_bool_exp = {}
		$limit: Int!
		$offset: Int!
		$sortFilter: [project_resource_mapping_order_by!]
	) {
		project_resource_mapping(
			where: $resourceMappingFilter
			limit: $limit
			offset: $offset
			order_by: $sortFilter
		) {
			id
			employee_id
			start_date
			end_date
			allocation_percentage
			total_cost
			is_partial
			is_billable
			project_id
			resource_cost
			client_rate
			tag_id
			total_hours
			user {
				full_name
				user_type
				employee {
					join_date
					last_date
					org_location {
						org_holidays {
							id
							date
						}
					}
				}
				contractors {
					join_date
					end_date
					org_location {
						org_holidays {
							id
							date
						}
					}
				}
			}
			project {
				id
				name
				start_date
				end_date
				budget: total_cost
				contractValue: revenue
				effort_estimate
				currency {
					symbol
				}
				projectResourceMappings: project_resource_mappings {
					id
					employee_id
					start_date
					end_date
				}
				plannedCostAndHours: project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_cost
							total_hours
						}
					}
				}
			}
		}
		totalResourceMappingCount: project_resource_mapping_aggregate(
			where: $resourceMappingFilter
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_GENERAL_ASSIGNED_PROJECT_ASSIGNED_RESOURCES_FOR_PROJECT_WISE = gql`
	query getGeneralAndProjectAssignedResourceList(
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
		$filters: timesheet_bool_exp = {}
	) {
		projectResourceList: project_resource_mapping(
			distinct_on: employee_id
			where: $projectResourceMappingFilter
		) {
			employee_id
			user {
				id
				full_name
				first_name
				last_name
			}
		}
		generalTaskResourceList: timesheet(where: $filters, distinct_on: user_id) {
			userByEmployeeId {
				full_name
				id
			}
		}
	}
`;
export const GET_GENERAL_AND_PROJECT_TIMESHEET_ENTRIES_FOR_PROJECT_WISE_REPORT = gql`
	query getNormalAndGeneralTimesheetEntriesForProjectWiseReport(
		$filters: timesheet_bool_exp = {}
		$generalFilters: timesheet_bool_exp = {}
		$projectId: [uuid!]
		$projectResourceMappingFilter: project_resource_mapping_bool_exp = {}
	) {
		timesheetEntries: timesheet(order_by: { date: asc }, where: $filters) {
			id
			is_billable
			working_hours
			date
			timesheet_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				id
				name
				effort_estimate
				project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_hours
						}
					}
				}
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			user: userByEmployeeId {
				full_name
				id
				user_type
				email
				is_active
				project_resource_mappings(where: { project_id: { _in: $projectId } }) {
					client_rate
					project_id
				}
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
			}
		}
		generalTimesheetEntries: org_tasks(where: { timesheets: $generalFilters }) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $generalFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					email
					id
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
				}
				timesheet_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
		projectResources: project_resource_mapping(
			where: $projectResourceMappingFilter
		) {
			employee_id
			project {
				name
				id
				currency {
					symbol
				}
				effort_estimate
				project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_hours
						}
					}
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			user {
				id
				full_name
				first_name
				email
				last_name
				user_type
				is_active
				project_resource_mappings(where: { project_id: { _in: $projectId } }) {
					client_rate
					project_id
				}
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
			}
		}
	}
`;
export const GET_GENERAL_AND_NORMAL_OVERTIME_FOR_PROJECT_WISE_REPORT = gql`
	query getOvertimeAndGeneralOvertimeForProjectWiseReport(
		$filters: timesheet_overtime_bool_exp = {}
		$generalFilters: timesheet_overtime_bool_exp = {}
		$projectId: [uuid!]
	) {
		normalOvertimeEntries: timesheet_overtime(where: $filters) {
			id
			is_billable
			working_hours
			date
			timesheet_overtime_submission {
				timesheet_status {
					label
					id
				}
			}
			project {
				id
				name
				effort_estimate
				project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_hours
						}
					}
				}
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				project_resource_mappings(where: { project_id: { _in: $projectId } }) {
					client_rate
					project_id
				}
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					emp_id
					id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					contractor_id
					id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
			}
		}
		generalOvertimeEntries: org_tasks(
			where: { timesheet_overtime: $generalFilters }
		) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheet_overtime(where: $generalFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					email
					id
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
				}
				timesheet_overtime_submission {
					id
					timesheet_status {
						value
						label
					}
				}
			}
		}
	}
`;

export const GET_NORMAL_AND_GENERAL_UNSUBMITTED_AND_DRAFT_STATUS_TIMESHEETS_FOR_PROJECT_WISE_REPORT = gql`
	query getNormalAndGeneralUnSubmittedAndDraftTimesheetEntriesForProjectWiseReport(
		$projectUnSubmittedFilters: timesheet_bool_exp = {}
		$projectDraftStatusFilters: timesheet_bool_exp = {}
		$generalUnSubmittedFilters: timesheet_bool_exp = {}
		$generalDraftStatusFilters: timesheet_bool_exp = {}
		$projectId: [uuid!]
	) {
		unSubmittedNormalTimesheetList: timesheet(
			order_by: { date: asc }
			where: $projectUnSubmittedFilters
		) {
			id
			is_billable
			working_hours
			date
			project {
				id
				name
				effort_estimate
				project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_hours
						}
					}
				}
				currency {
					symbol
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				project_resource_mappings(where: { project_id: { _in: $projectId } }) {
					client_rate
					project_id
				}
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
			}
		}
		normalDraftStatusTimesheetList: timesheet(
			order_by: { date: asc }
			where: $projectDraftStatusFilters
		) {
			id
			is_billable
			working_hours
			date
			project {
				id
				name
				currency {
					symbol
				}
				effort_estimate
				project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_hours
						}
					}
				}
				ProjectStatus {
					label
				}
				start_date
				end_date
				user {
					full_name
					email
				}
			}
			user: userByEmployeeId {
				full_name
				id
				email
				user_type
				is_active
				project_resource_mappings(where: { project_id: { _in: $projectId } }) {
					client_rate
					project_id
				}
				organization {
					emp_id_prefix
					contractor_id_prefix
				}
				employee {
					id
					emp_id
					reportsTo {
						id
						first_name
						last_name
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					employee_prefix {
						id
						name
						value
					}
				}
				contractors {
					id
					contractor_id
					reportee {
						full_name
						email
					}
					department {
						name
					}
					org_location {
						name
					}
					contractor_prefix {
						id
						name
						value
					}
				}
			}
		}
		GeneralUnSubmittedTimesheets: org_tasks(
			where: { timesheets: $generalUnSubmittedFilters }
		) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $generalUnSubmittedFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
				}
			}
		}
		generalDraftStatusTimesheets: org_tasks(
			where: { timesheets: $generalDraftStatusFilters }
		) {
			id
			title
			is_billable
			task_category
			organization {
				name
			}
			timesheets(where: $generalDraftStatusFilters) {
				id
				working_hours
				date
				notes
				is_billable
				userByEmployeeId {
					full_name
					id
					email
					user_type
					is_active
					organization {
						emp_id_prefix
						contractor_id_prefix
					}
					employee {
						emp_id
						id
						reportsTo {
							id
							first_name
							last_name
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						employee_prefix {
							id
							name
							value
						}
					}
					contractors {
						contractor_id
						id
						reportee {
							full_name
							email
						}
						department {
							name
						}
						org_location {
							name
						}
						contractor_prefix {
							id
							name
							value
						}
					}
				}
			}
		}
	}
`;
export const GET_RESOURCE_ALLOCATIONS_FOR__EXPORT = gql`
	query getResourceAllocationsForExport(
		$resourceMappingFilter: project_resource_mapping_bool_exp = {}
		$sortFilter: [project_resource_mapping_order_by!]
	) {
		project_resource_mapping(
			where: $resourceMappingFilter
			order_by: $sortFilter
		) {
			id
			employee_id
			start_date
			end_date
			allocation_percentage
			total_cost
			is_partial
			is_billable
			project_id
			resource_cost
			project_resource_tag {
				name
			}
			total_hours
			user {
				full_name
			}
			project {
				id
				name
				start_date
				end_date
				budget: total_cost
				contractValue: revenue
				effort_estimate
				currency {
					symbol
				}
				projectResourceMappings: project_resource_mappings {
					id
					employee_id
					start_date
					end_date
				}
				plannedCostAndHours: project_resource_mappings_aggregate {
					aggregate {
						sum {
							total_cost
							total_hours
						}
					}
				}
			}
		}
	}
`;
export const UPDATE_PROJECT_RISK = gql`
	mutation UpdateProjectRisk(
		$projectId: uuid!
		$projectData: project_set_input!
		$riskMappingData: project_risk_mapping_insert_input!
	) {
		update_project_by_pk(pk_columns: { id: $projectId }, _set: $projectData) {
			id
		}
		insert_project_risk_mapping_one(object: $riskMappingData) {
			id
		}
	}
`;

export const GET_PROJECT_CUSTOM_FIELDS = gql`
	query getProjectCustomFields {
		custom_field(
			where: { master_sub_module: { value: { _eq: "project" } } }
			order_by: { index: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
		}
	}
`;
