import 'antd/dist/antd.css';
import React from 'react';
import { Row, Col, Typography, Spin } from 'antd';
const { Text } = Typography;
interface Props {
	isLoading: boolean;
	count: string | number;
}

export const ContractorExpiring = (props: Props) => {
	const { isLoading, count } = props;
	return (
		<div>
			{isLoading ? (
				<Spin />
			) : (
				<Row
					justify='center'
					align='middle'
					style={{
						height: '100%',
					}}
				>
					<Col>
						<Text>{count}</Text>
					</Col>
				</Row>
			)}
		</div>
	);
};
