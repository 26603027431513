import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { chain } from 'lodash';
import {
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
} from 'recharts';
import { Card, CardContent, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useGetPipelineByOwnerQuery } from '../../../generated/graphql';
import { formatAmount } from '../../../Utils/string.util';
import { insightChartTheme } from '../../../Layout/Theme.component';
import { ChartDatePicker } from '../CustomDatePicker.component';
import NoDataFound from '../../NoDataFound.component';
import { ChartWithDateRange } from '../modal';

interface ChartData {
	name: string;
	totalOpportunitiesValue: number;
	totalWonOpportunitiesValue: number;
}

export const PipelineByOwnerReport = (props: ChartWithDateRange) => {
	const { defaultStartDate, defaultEndDate, currency } = props;
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data: pipelineByOwner, loading: isLoading } =
		useGetPipelineByOwnerQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
			},
		});

	useEffect(() => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
	}, [defaultStartDate, defaultEndDate]);

	useEffect(() => {
		if (!pipelineByOwner) {
			return;
		}
		const data = chain(pipelineByOwner.user)
			.map((user) => ({
				name: user.full_name || '',
				totalOpportunitiesValue: user?.opportunities
					.filter(
						(opportunity) =>
							opportunity.opportunity_kanban_cards[0].kanban_column?.state ===
							'opportunity'
					)
					.reduce((total, currentVal) => total + currentVal.value, 0),
				totalWonOpportunitiesValue: user?.opportunities
					.filter(
						(opportunity) =>
							opportunity.opportunity_kanban_cards[0].kanban_column?.state ===
							'won'
					)
					.reduce((total, currentVal) => total + currentVal.value, 0),
			}))
			.value()
			.sort((a, b) => b.totalOpportunitiesValue - a.totalOpportunitiesValue);

		setChartData(data);
	}, [pipelineByOwner]);

	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ background: '#F7F9FA' }}>
					<p>{payload[0]?.payload.name || ''}</p>
					<p>
						{'opportunity'}:
						{` ${formatAmount(
							parseInt(payload[0]?.payload.totalOpportunitiesValue),
							currency
						)}`}
					</p>
					<p>
						{'won'}:
						{` ${formatAmount(
							parseInt(payload[0]?.payload.totalWonOpportunitiesValue),
							currency
						)}`}
					</p>
				</div>
			);
		}

		return null;
	};

	const getReportContent = () => (
		<ResponsiveContainer width='100%' height={350}>
			{chartData && chartData?.length > 0 ? (
				<BarChart
					width={500}
					height={300}
					data={chartData}
					margin={{
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false} stroke='#EAF0F4' />
					<XAxis
						dataKey='name'
						dy={20}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						dx={-10}
						tick={{ fontSize: '11px', fill: '#43425D' }}
						axisLine={false}
						tickLine={false}
						tickFormatter={(value) => formatAmount(value, currency)}
					/>
					<Tooltip
						cursor={{ fill: 'transparent' }}
						content={<CustomTooltip />}
					/>
					<Legend
						iconSize={16}
						wrapperStyle={{
							fontSize: '12px',
							fontFamily: 'Manrope-semibold',
							color: '#5C5C5C',
							paddingTop: '50px',
						}}
					/>
					<Bar
						dataKey={'totalOpportunitiesValue'}
						fill={'#3f9908'}
						barSize={10}
						stackId='a'
					/>
					<Bar
						dataKey={'totalWonOpportunitiesValue'}
						fill={'#ed9907'}
						barSize={10}
						stackId='a'
					/>
				</BarChart>
			) : (
				<NoDataFound />
			)}
		</ResponsiveContainer>
	);

	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<Box display='flex' justifyContent='space-between'>
					<Box
						marginTop='20px'
						marginLeft='10px'
						fontFamily='Manrope-extrabold'
					>
						Owner
					</Box>
					<Box display='flex' marginTop='2px'>
						<ChartDatePicker
							initialStartDate={startDate}
							initialEndDate={endDate}
							selectedEndDate={(date: string) => setEndDate(date)}
							selectedStartDate={(date: string) => setStartDate(date)}
						/>
					</Box>
				</Box>
				<CardContent>
					{isLoading ? <CircularProgress size={20} /> : getReportContent()}
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
