import { gql } from '@apollo/client';

export const GET_RESOURCE_MAPPING_LIST = gql`
	query getResourceMappings($where: user_bool_exp) {
		user(where: $where, order_by: { first_name: asc }) {
			id
			first_name
			org_id
			last_name
			full_name
			email
			user_type
			user_type
			resource_allocation_notes {
				id
				note
			}
			employee {
				join_date
				emp_id
				department {
					name
				}
			}
			organization {
				contractor_id_prefix
				emp_id_prefix
			}
			user_team_mappings {
				user_team {
					name
				}
			}
			project_resource_mappings(
				where: { project: { ProjectStatus: { value: { _neq: "lost" } } } }
			) {
				employee_id
				project_id
				start_date
				end_date
				is_partial
				is_billable
				allocation_percentage
				project {
					name
					is_billable
					start_date
					end_date
				}
			}
			employees {
				id
				emp_cost
				is_billable
				org_location {
					name
				}
				employee_prefix {
					id
					name
					value
				}
				reportsTo {
					full_name
					email
				}
				designation {
					title
				}
			}
			contractors {
				id
				join_date
				contractor_id
				contractor_cost
				is_billable
				department {
					name
				}
				designation {
					title
				}
				reportee {
					full_name
					email
				}
				org_location {
					name
				}
				contractor_prefix {
					id
					name
					value
				}
			}

			employee_skill_mappings {
				employee_id
				skill_master {
					name
				}
			}
		}
	}
`;

export const GET_ACTIVE_EMPLOYEE_RESOURCE_MAPPING_LIST = gql`
	query getResourceGanttChart {
		user(
			where: {
				_or: [
					{
						user_type: { _eq: "employee" }
						is_active: { _eq: true }
						employees: { employee_status: { value: { _eq: "active" } } }
					}
					{ user_type: { _eq: "contract_employee" }, is_active: { _eq: true } }
				]
			}
			order_by: { first_name: asc }
		) {
			id
			first_name
			full_name
			org_id
			last_name
			user_type
			project_resource_mappings {
				employee_id
				project_id
				start_date
				end_date
				allocation_percentage
				project {
					name
				}
			}
			employees {
				id
			}
			contractors {
				id
			}
			employee_skill_mappings {
				employee_id
				skill_master {
					name
				}
			}
		}
	}
`;

export const GET_EMPLOYEES = gql`
	query MyQuery {
		employee(
			where: {
				department: { name: { _eq: "Delivery" } }
				employee_status: { value: { _eq: "active" } }
			}
		) {
			emp_cost
			user_id
			is_billable
		}
	}
`;

export const GET_CONTRACTORS = gql`
	query MyQuery {
		contractor {
			user_id
			contractor_cost
		}
	}
`;

export const GET_PROJECT_LIST = gql`
	query MyQuery {
		project {
			id
			name
			start_date
			end_date
			project_status
			project_type
			project_resource_mappings_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_DELIVERY_USERS_LIST = gql`
	query MyQuery {
		user(where: { employees: { department: { name: { _eq: "BD" } } } }) {
			id
			first_name
			last_name
			user_type
		}
	}
`;

export const GET_SKILLS = gql`
	query getEmployeeSkills($limit: Int, $skill: String) {
		skills: skill_master(
			distinct_on: name
			limit: $limit
			where: { name: { _ilike: $skill } }
		) {
			id
			name
		}
		skill_master_aggregate(
			distinct_on: name
			where: { name: { _ilike: $skill } }
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_PROJECT_FILTER_OPTIONS = gql`
	query getBenchReportProjectFilterOptions($project: String, $limit: Int) {
		project(
			where: {
				project_resource_mappings: {
					project: {
						ProjectStatus: { value: { _in: ["pending", "in_progress"] } }
					}
				}
				name: { _like: $project }
			}
			distinct_on: name
			limit: $limit
		) {
			id
			name
		}
		project_aggregate(
			where: {
				project_resource_mappings: {
					project: {
						ProjectStatus: { value: { _in: ["pending", "in_progress"] } }
					}
				}
				name: { _like: $project }
			}
			distinct_on: name
		) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_PROJECTS = gql`
	query getProjectsList($name: String!) {
		project(where: { name: { _ilike: $name } }) {
			id
			name
		}
	}
`;

export const UPSERT_ALLOCATION_NOTE = gql`
	mutation upsertAllocationNote($note: resource_allocation_note_insert_input!) {
		insert_resource_allocation_note_one(
			object: $note
			on_conflict: {
				constraint: employee_allocation_note_pkey
				update_columns: note
			}
		) {
			id
		}
	}
`;

export const DELETE_ALLOCATION_NOTE = gql`
	mutation deleteResourceMappingNote($resourceMappingNoteId: uuid!) {
		delete_resource_allocation_note_by_pk(id: $resourceMappingNoteId) {
			id
		}
	}
`;
