import React, { useEffect, useState } from 'react';
import { Loading, SelectInput, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { monthOptions } from '../../constant';
import { Typography, Box, ThemeProvider, makeStyles } from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import dayjs from 'dayjs';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';
import { useUpdateOrgasationCurrencyMutation } from '../../../generated/graphql';

const customStyle = makeStyles({
	dateContainer: {
		marginRight: '10px',
		width: '10.5vw',
	},
	finacialYearPeriodContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '12px',
	},
});

interface LeaveCalendarYearSettingsProps {
	loader: boolean;
	organisationId: string;
	organisationLeaveDetails: {
		leave_calendar_year_end_date?: string;
		leave_calendar_year_end_month?: string;
		leave_calendar_year_start_date?: string;
		leave_calendar_year_start_month?: string;
	};
	onSuccess: () => void;
}

interface LeaveCalendarYear {
	leave_calendar_year_end_date: string;
	leave_calendar_year_end_month: string;
	leave_calendar_year_start_date: string;
	leave_calendar_year_start_month: string;
}

const LeaveCalendarYear = ({
	loader,
	organisationId,
	organisationLeaveDetails,
	onSuccess,
}: LeaveCalendarYearSettingsProps) => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyles = customStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [leaveCalendarYearPeriod, setLeaveCalendarYearPeriod] = useState({
		startDate: 0,
		endDate: 0,
		endMonth: 11,
		period: '',
	});
	const [updateOrgLeaveYear, { loading: isUpdatedOrgLeaveYearLoading }] =
		useUpdateOrgasationCurrencyMutation();

	useEffect(() => {
		if (!organisationLeaveDetails) {
			return;
		}
		const startDate = dayjs()
			.date(1)
			.month(Number(organisationLeaveDetails.leave_calendar_year_start_month))
			.format('YYYY-MM-DD');
		const endDate = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('YYYY-MM-DD');
		const startingPeriod = dayjs()
			.date(1)
			.month(Number(organisationLeaveDetails.leave_calendar_year_start_month))
			.format('DD-MMM');
		const endingPeriod = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('DD-MMM');

		setLeaveCalendarYearPeriod({
			startDate: dayjs(startDate).date(),
			endDate: dayjs(endDate).date(),
			endMonth: dayjs(endDate).month(),
			period: `From ${startingPeriod} to ${endingPeriod}`,
		});
	}, [organisationLeaveDetails]);

	const calculateLeaveCalendarYear = (monthIndex: string) => {
		const startDate = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('YYYY-MM-DD');
		const endDate = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('YYYY-MM-DD');
		const startingPeriod = dayjs()
			.date(1)
			.month(Number(monthIndex))
			.format('DD-MMM');
		const endingPeriod = dayjs(startDate)
			.add(12, 'months')
			.subtract(1, 'day')
			.format('DD-MMM');

		setLeaveCalendarYearPeriod({
			startDate: dayjs(startDate).date(),
			endDate: dayjs(endDate).date(),
			endMonth: dayjs(endDate).month(),
			period: `From ${startingPeriod} to ${endingPeriod}`,
		});
	};

	const onHandleSubmit = (leaveCalendarYear: LeaveCalendarYear) => {
		if (!leaveCalendarYear) {
			return;
		}

		updateOrgLeaveYear({
			variables: {
				organisationId: organisationId,
				data: {
					leave_calendar_year_start_month:
						leaveCalendarYear.leave_calendar_year_start_month?.toString(),
					leave_calendar_year_start_date:
						leaveCalendarYearPeriod.startDate?.toString(),
					leave_calendar_year_end_month:
						leaveCalendarYearPeriod.endMonth?.toString(),
					leave_calendar_year_end_date:
						leaveCalendarYearPeriod.endDate?.toString(),
				},
			},
		})
			.then((data) => {
				if (data) {
					notify('Leave calendar year settings updated successfully');
					refresh();
					onSuccess();
				}
			})
			.catch((error) => {
				if (error) {
					notify('Leave calendar settings updation failed', 'warning');
				}
			});
	};

	const handleSelectChange = (e: any) => {
		calculateLeaveCalendarYear(e.target.value);
	};

	if (loader) {
		return <Loading />;
	}
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Box className={styles.formContainer}>
				<Form
					initialValues={{
						leave_calendar_year_start_month:
							Number(
								organisationLeaveDetails.leave_calendar_year_start_month
							) || 0,
					}}
					onSubmit={onHandleSubmit}
				>
					{({ handleSubmit, invalid, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Box className={styles.inputContainer}>
								<Box className={styles.inputLabel}>
									<Typography className={classes.label}>Start Month</Typography>
								</Box>
								<Box className={customStyles.dateContainer}>
									<SelectInput
										name='leave_calendar_year_start_month'
										label=''
										choices={monthOptions}
										onChange={(e) => {
											handleSelectChange(e);
										}}
									/>
								</Box>
							</Box>
							<Box className={customStyles.finacialYearPeriodContainer}>
								<Typography>{leaveCalendarYearPeriod.period}</Typography>
							</Box>
							<Box className={classes.buttonContainer} style={{ padding: 0 }}>
								<ButtonWithLoader
									isLoading={isUpdatedOrgLeaveYearLoading}
									isDisabled={
										invalid || pristine || isUpdatedOrgLeaveYearLoading
									}
									title='Save'
								/>
							</Box>
						</form>
					)}
				</Form>
			</Box>
		</ThemeProvider>
	);
};

export default LeaveCalendarYear;
