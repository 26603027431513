import React from 'react';
 
import { Card, Tooltip, withStyles, LinearProgress } from '@material-ui/core';
import {
	Draggable,
	DraggableProvided,
	DraggableRubric,
	DraggableStateSnapshot,
	Droppable,
} from 'react-beautiful-dnd';
import {
	KanbanCardChildren,
	KanbanColumnView,
	GetKanbanColumnIdFn,
} from '../../Kanban/kanban.model';
import { KanbardCardItem } from '../../Kanban/KanbanCard.component';
import { FixedSizeList as VList, areEqual } from 'react-window';
import './milestoneKanban.css';

export const LinerProgressTask = withStyles((theme: any) => ({
	root: {
		height: 7,
		borderRadius: 5,
		width: '30px',
	},
	colorPrimary: {
		backgroundColor: '#FFFFFF',
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#3ABC9E',
	},
}))(LinearProgress);
interface TotalValue {
	name: string;
	sum: number;
}
const Row = React.memo(({ index, style, data }: any) => {
	const { cards, children } = data;
	if (!cards[index]) {
		return null;
	}
	return (
		<Draggable
			isDragDisabled={true}
			draggableId={cards[index]?.id}
			key={cards[index]?.id}
			index={index}
		>
			{(provided, snapshot) => (
				<KanbardCardItem
					key={cards[index].id}
					index={index}
					provided={provided}
					snapshot={snapshot}
					card={cards[index]}
					style={style}
				>
					{children}
				</KanbardCardItem>
			)}
		</Draggable>
	);
}, areEqual);

export const MilestoneKanbanColumn = ({
	column,
	children,
	onAddCardToColumn,
	totalValue,
}: {
	column: KanbanColumnView;
	children: KanbanCardChildren;
	onAddCardToColumn: GetKanbanColumnIdFn;
	totalValue?: TotalValue[];
}) => {
	return (
		<div className={'kanbanColumnRoot'}>
			<div className='kanbanColumContainer'>
				<div style={{ display: 'flex', width: '180px' }}>
					<Tooltip title={column?.name?.toUpperCase() || ''}>
						<p className={'columnHeader'}>{column?.name?.toUpperCase()}</p>
					</Tooltip>
					<span>
						<div
							style={{ marginTop: '9px', marginLeft: '10px' }}
							className='progressBarContainer'
						>
							<Tooltip
								title={
									column?.completedTaskCount && column?.totalTaskCount
										? `Done: ${column?.completedTaskCount?.aggregate?.count} of ${column?.totalTaskCount?.aggregate?.count} tasks`
										: ''
								}
							>
								<LinerProgressTask
									variant='determinate'
									value={
										column?.completedTaskCount &&
										column?.totalTaskCount &&
										column?.totalTaskCount?.aggregate?.count !== 0 &&
										column?.completedTaskCount?.aggregate?.count !== 0
											? parseInt(
													Number(
														(column?.completedTaskCount?.aggregate?.count /
															column?.totalTaskCount?.aggregate?.count) *
															100
													).toFixed()
											  )
											: 0
									}
								/>
							</Tooltip>
							<div style={{ marginLeft: '4px', fontSize: '10px' }}>
								{column?.completedTaskCount &&
								column?.totalTaskCount &&
								column?.totalTaskCount?.aggregate?.count !== 0 &&
								column?.completedTaskCount?.aggregate?.count !== 0
									? parseInt(
											Number(
												(column?.completedTaskCount?.aggregate?.count /
													column?.totalTaskCount?.aggregate?.count) *
													100
											).toFixed()
									  )
									: 0}
								%
							</div>
						</div>
					</span>
				</div>
			</div>
			<Droppable
				isDropDisabled={true}
				droppableId={column.id}
				mode='virtual'
				renderClone={(
					provided: DraggableProvided,
					snapshot: DraggableStateSnapshot,
					rubric: DraggableRubric
				) => {
					return (
						<KanbardCardItem
							index={rubric.source.index}
							provided={provided}
							snapshot={snapshot}
							card={column?.cards[rubric.source.index]}
						>
							{children}
						</KanbardCardItem>
					);
				}}
			>
				{(droppableProvided, snapshot) => {
					const itemCount: number = snapshot.isUsingPlaceholder
						? column?.cards.length + 1
						: column?.cards.length;
					return (
						<VList
							height={688}
							itemCount={itemCount}
							itemSize={116}
							width={'100%'}
							outerRef={droppableProvided.innerRef}
							itemData={{ cards: column?.cards, children }}
						>
							{Row}
						</VList>
					);
				}}
			</Droppable>
			{totalValue && (
				<Card
					style={{
						marginLeft: '-4px',
						height: '45px',
						position: 'relative',
						marginRight: '-5px',
						display: 'flex',
						alignItems: 'center',
						minWidth: '260px',
						borderRadius: '8px',
						justifyContent: 'flex-end',
						padding: '0px 14px 0px 14px',
						background: '#d3dcea80',
						boxShadow: '0px 0px 0px 0px !important',
					}}
				></Card>
			)}
		</div>
	);
};
