import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import 'antd/dist/antd.css';
import React from 'react';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import { ThemeProvider } from '@material-ui/core/styles';
import { insightChartTheme } from '../../Layout/Theme.component';
import { billablePieChartColor } from '../../config/constant';
import { Spin } from 'antd';

interface Props {
	chartData: any
	loading: boolean;
}
export const EmployeeJuniorSeniorPercentage = (props: Props) => {
	const { chartData, loading } = props;
	const dataSet = [
		{ name: 'Junior', value: parseInt(chartData?.juniorPercentage) },
		{ name: 'Senior', value: parseInt(chartData?.seniorPercentage) },
	];
	return (
		<div>
			{loading ? (
				<Spin />
			) : (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							title='Employee Junior V/s Senior Percentage'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<CardContent>
							<ResponsiveContainer height={355}>
								{dataSet && dataSet?.length > 0 ? (
									<PieChart>
										<Pie
											isAnimationActive={false}
											data={dataSet}
											dataKey={'value'}
											innerRadius={50}
										>
											{dataSet.map((e: any, index: number) => (
												<Cell
													key={index}
													fill={
														billablePieChartColor[
															index % billablePieChartColor.length
														]
													}
												/>
											))}
										</Pie>
										<Legend
											iconSize={16}
											wrapperStyle={{
												fontSize: '12px',
												fontFamily: 'Manrope-semibold',
												color: '#5C5C5C',
												paddingTop: '50px',
											}}
										/>
										<Tooltip />
									</PieChart>
								) : (
									<Typography>No data found</Typography>
								)}
							</ResponsiveContainer>
						</CardContent>
					</Card>
				</ThemeProvider>
			)}
		</div>
	);
};
