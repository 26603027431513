import dayjs from 'dayjs';
import { isEmpty, range, orderBy } from 'lodash';
import { UserProfileContext } from '../../App';
import { ellipsisStyle } from '../../Layout/styles';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState, useEffect } from 'react';
import {
	useGetHolidaysForSettingsQuery,
	useGetOrganiztionHolidayYearQuery,
	useGetLocationsByOrgIdQuery,
} from '../../generated/graphql';
import {
	Card,
	CardHeader,
	Box,
	CardContent,
	Tooltip,
	Typography,
	ThemeProvider,
	Checkbox,
	TextField as Field,
} from '@material-ui/core';
import { customAutoCompleteSearchBarTheme } from '../../Layout/Theme.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import { modalFormTheme } from '../../Layout/Theme.component';
import { ResponsiveContainer } from 'recharts';
import {
	Datagrid,
	List,
	FunctionField,
	SelectInput,
	ReferenceField,
	Pagination,
	Loading,
} from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../Layout/styles';

const holidayStyle = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '6px 14px',
	},
	rowPadding: {
		padding: '5px',
	},
	financialYear: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px',
		color: '#292929',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	dropdownLabel: {
		color: '#4e4e4e',
		margin: '5px 10px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	dropdownLabelSpecial: {
		marginTop: '7px',
		color: '#4e4e4e',
		margin: '10px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	additionalInfoLabelLeave: {
		font: 'normal normal 600 10px/40px Manrope',
		letterSpacing: '0px',
		color: '#5C5C5C',
		opacity: 1,
		fontSize: '10px !important',
	},
	shortLabel: {
		maxWidth: '80px',
	},
	longLabel: {
		maxWidth: '200px',
	},
});

interface Holiday {
	created_at?: Date;
	date: string;
	id: string;
	is_restricted?: boolean | null;
	name: string;
	org_id: string;
	updated_at?: Date;
	year?: string | null;
	location_id?: string;
}

interface YearProps {
	id: number;
	name: number;
}

interface OptionProps {
	id: string;
	name: string;
}

const HolidayPagination = () => {
	return (
		<Box marginBottom={'14px'} position={'relative'}>
			<Pagination rowsPerPageOptions={[4, 8, 16]} />
		</Box>
	);
};

const Holidays = () => {
	const {
		noOfLocations,
		dateFormat,
		orgId: organizationId,
	} = useContext<any>(UserProfileContext);

	const classes = holidayStyle();
	const styles = ellipsisStyle();
	const formClasses = modalFormStyle();

	const [year, setYear] = useState<number>(dayjs().year());

	const [yearDropDownOption, setYearDropDownOption] = useState<YearProps[]>([]);

	const [organizationHolidayDates, setOrganizationHolidayDates] = useState({
		startDate: '',
		endDate: '',
	});

	const [locationDropDown, setLocationDropDown] = useState<OptionProps[]>([]);

	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<OptionProps[]>([]);
	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		string[]
	>([]);

	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return {};
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return { location_id: {} };
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return {
					_or: [{ location_id: { _is_null: true } }],
				};
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return { location_id: { _in: selectedLocationIdList } };
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return {
					_or: [
						{
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
						{
							location_id: { _is_null: true },
						},
					],
				};
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return { location_id: { _in: selectedLocationIdList } };
			}
		}
		return {};
	};

	const getLocationFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);

		const hasOneSelectedOption =
			currentSelectedOptions && currentSelectedOptions?.length === 1;

		return (
			<div
				className={`${
					hasOneSelectedOption
						? classes.dropdownLabel
						: classes.dropdownLabelSpecial
				} ${styles.ellipsis}`}
			>
				{hasOneSelectedOption
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const { data: holidays, loading: isHolidaysLoading } =
		useGetHolidaysForSettingsQuery({
			variables: {
				filter:
					noOfLocations && noOfLocations > 0
						? {
								date: {
									_gte: organizationHolidayDates?.startDate,
									_lte: organizationHolidayDates?.endDate,
								},
								_and: [getLocationFilter()],
						  }
						: {
								date: {
									_gte: organizationHolidayDates?.startDate,
									_lte: organizationHolidayDates?.endDate,
								},
						  },
			},
			fetchPolicy: 'network-only',
		});

	const { data: orgLocationList } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: holiday } = useGetOrganiztionHolidayYearQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgLocationList) {
			return;
		}

		const options =
			orgLocationList &&
			orgLocationList?.org_location &&
			orgLocationList?.org_location?.map((value) => {
				return {
					id: value?.id || '--',
					name: value?.name || '--',
				};
			});

		const defaultLocationIdList = options?.map(
			(locationOption: OptionProps) => locationOption?.id
		);
		setSelectedLocationIdList(defaultLocationIdList || []);
		const sortedLocationOption = orderBy(options, ['name'], ['asc']);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{
				id: 'Unassigned',
				name: 'Unassigned',
			},
		]);

		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(options || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocationList]);

	useEffect(() => {
		if (!year) {
			return;
		}
		let startDate = dayjs(year).startOf('month').format('MM-DD');
		let endDate = dayjs().endOf('year').format('MM-DD');

		setOrganizationHolidayDates({
			startDate: `${year}-${startDate}`,
			endDate: `${year}-${endDate}`,
		});
	}, [year]);

	useEffect(() => {
		if (
			!holiday ||
			!holiday?.org_holidays_aggregate ||
			!holiday?.org_holidays_aggregate?.aggregate
		) {
			return;
		}
		const aggregate = holiday?.org_holidays_aggregate?.aggregate;
		let maxDate = dayjs(aggregate?.max?.date).year() + 1;
		let minDate = dayjs(aggregate?.min?.date).year();

		const yearOption = range(minDate, maxDate)
			.map((value: number) => {
				return {
					id: value,
					name: value,
				};
			})
			.reverse();

		setYearDropDownOption(yearOption);
	}, [holiday]);

	return (
		<Box>
			<Card>
				<CardHeader
					title='All Holidays In Organization'
					titleTypographyProps={{ variant: 'h6' }}
				/>
				<Box className={classes.toolBarContainer}>
					<Typography className={classes.financialYear}>
						<Form onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box display='flex'>
											<Box>
												<Box className={formClasses.label}>Choose Year</Box>
												<SelectInput
													source='name'
													label=''
													choices={yearDropDownOption || []}
													defaultValue={year}
													onChange={(event: any) => {
														if (event) {
															let chosenYear = Number(event?.target?.value);
															setYear(chosenYear);
														}
													}}
												/>
											</Box>
											{noOfLocations && noOfLocations > 0 && (
												<Box marginLeft='5px' width={'200px'}>
													<Box className={formClasses.label}>
														Choose Location
													</Box>

													<Autocomplete
														multiple
														limitTags={0}
														value={
															selectedLocationOptionValue &&
															selectedLocationOptionValue?.length > 0
																? selectedLocationOptionValue
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter((status) => status.id !== 'All')
																.map((status) => status?.id);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																setSelectedLocationOptionValue([
																	{ id: 'All', name: 'All' },
																	...locationDropDown,
																]);
																const allStatusIds = locationDropDown?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(allStatusIds);

																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																locationDropDown?.length !== selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(status) => status?.id !== 'All'
																);
																setSelectedLocationOptionValue(currentOptions);
																const currentIds = currentOptions?.map(
																	(resource) => resource?.id
																);
																setSelectedLocationIdList(currentIds);

																return;
															}
															if (
																selectedIds?.length ===
																	locationDropDown?.length &&
																reason === 'select-option'
															) {
																setSelectedLocationOptionValue([
																	{ id: 'All', name: 'All' },
																	...locationDropDown,
																]);
																const responseIds = locationDropDown?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(responseIds);

																return;
															}
															if (
																selectedLocationOptionValue.find(
																	(option) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																setSelectedLocationIdList([]);
																setSelectedLocationOptionValue([]);

																return;
															}
															const selectedId = value?.map(
																(status) => status?.id
															);
															setSelectedLocationIdList(selectedId);
															setSelectedLocationOptionValue(value);
														}}
														options={
															locationDropDown && locationDropDown?.length > 0
																? [
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																  ]
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getLocationFilterLimits}
														ChipProps={{ style: { display: 'none' } }}
														renderInput={(params: any) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<Field {...params} label='' placeholder={''} />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => {
															return (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected || false}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${styles.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															);
														}}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) => {
															return option.id === value.id;
														}}
													/>
												</Box>
											)}
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Typography>
				</Box>
				{isHolidaysLoading ? (
					<Loading />
				) : (
					<CardContent style={{ overflowY: 'auto', paddingTop: '0px' }}>
						<ResponsiveContainer height={360}>
							<List
								resource='org_holidays'
								basePath='/'
								bulkActionButtons={false}
								pagination={<HolidayPagination />}
								actions={false}
								sort={{ field: 'date', order: 'ASC' }}
								filter={{
									id:
										noOfLocations &&
										noOfLocations > 0 &&
										isEmpty(selectedLocationIdList)
											? []
											: (holidays &&
													holidays?.org_holidays?.map(
														(orgHoliday) => orgHoliday.id
													)) || [{}],
								}}
								title={' '}
								perPage={4}
							>
								<Datagrid rowClick={''}>
									<FunctionField
										label='Name'
										render={(record: any) => {
											return (
												<Tooltip
													title={`${record?.name || ''}`}
													placement='right'
												>
													<Typography
														className={`${classes.additionalInfoLabelLeave} ${classes.longLabel} ${classes.rowPadding} ${styles.ellipsis}`}
													>
														{`${record?.name}`}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Date'
										sortable={true}
										render={(record: any) => {
											return (
												<Tooltip
													title={`${
														dayjs(record?.date).format(dateFormat) || ''
													}`}
													placement='right'
												>
													<Typography
														className={`${classes.additionalInfoLabelLeave} ${classes.shortLabel} ${styles.ellipsis}`}
													>
														{`${dayjs(record?.date).format(dateFormat)}`}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Day'
										render={(record: any) => {
											return (
												<Tooltip
													title={`${dayjs(record?.date).format('ddd') || ''}`}
													placement='right'
												>
													<Typography
														className={`${classes.additionalInfoLabelLeave} ${classes.shortLabel} ${styles.ellipsis}`}
													>
														{`${dayjs(record?.date).format('ddd')}`}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									<FunctionField
										label='Optional Holiday'
										render={(record: any) => {
											return (
												<Tooltip
													title={record?.is_restricted ? 'Yes' : 'No'}
													placement='right'
												>
													<Typography
														className={`${classes.additionalInfoLabelLeave} ${classes.shortLabel} ${styles.ellipsis}`}
													>
														{record?.is_restricted ? 'Yes' : 'No'}
													</Typography>
												</Tooltip>
											);
										}}
									/>
									{noOfLocations && noOfLocations > 0 && (
										<FunctionField
											label='Location'
											render={(holiday: any) => {
												return holiday?.location_id ? (
													<ReferenceField
														source='location_id'
														label='Location'
														reference='org_location'
														link={false}
													>
														<FunctionField
															render={(location: any) => {
																return (
																	<Tooltip
																		title={location.name || ''}
																		placement='right'
																	>
																		<Typography
																			className={`${classes.additionalInfoLabelLeave} ${classes.longLabel} ${styles.ellipsis}`}
																		>
																			{location?.name ? location?.name : '--'}
																		</Typography>
																	</Tooltip>
																);
															}}
														/>
													</ReferenceField>
												) : (
													'--'
												);
											}}
										></FunctionField>
									)}
									<FunctionField
										label='Description'
										render={(record: any) => {
											return (
												<Tooltip
													title={`${record?.description || ''}`}
													placement='right'
												>
													<Typography
														className={`${classes.additionalInfoLabelLeave} ${classes.longLabel} ${styles.ellipsis}`}
													>
														{`${record?.description || '--'}`}
													</Typography>
												</Tooltip>
											);
										}}
									/>
								</Datagrid>
							</List>
						</ResponsiveContainer>
					</CardContent>
				)}
			</Card>
		</Box>
	);
};

export default Holidays;
