import React, { useState, useContext } from 'react';
import { TextInput, useNotify, required } from 'react-admin';
import { Button, makeStyles, Box, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { modalFormTheme } from '../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';
import { modalFormStyle } from '../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { useMutation as useApolloMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { Attendance } from './Attendance.model';
import { UPSERT_ATTENDANCE } from './Attendance.gql';
import { UserProfileContext } from '../../App';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '442px',
		minHeight: '250px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	attendance: Attendance;
	refetch: () => void;
	userId?: string;
}

const AttendanceEditForm = (props: Props) => {
	const { open, onClose, attendance, refetch, userId } = props;
	const { permissions: permissionList, id: loggedInUserId } =
		useContext<any>(UserProfileContext);
	const [formError, setFormError] = useState('');
	const classes = modalFormStyle();
	const style = useStyles();
	const notify = useNotify();
	const [upsertAttendance] = useApolloMutation(UPSERT_ATTENDANCE);

	const onSave = (value: Attendance) => {
		const time = dayjs.duration(
			dayjs(`${attendance?.date} ${value?.out_time}`).diff(
				dayjs(`${attendance?.date} ${value?.in_time}`)
			)
		);
		const hour = time.format('H');
		const minute = time.format('m');
		if (parseInt(hour) < 0 || parseInt(minute) < 0) {
			setFormError('Out time should be greater than In time');
			return;
		}
		if (permissionList?.appPermissions?.ui?.editOthersAttendance) {
			upsertAttendance({
				variables: {
					attendance: {
						...value,
						in_time: dayjs(`${attendance.date} ${value?.in_time}`).format(
							'HH:mm:ssZ'
						),
						out_time: value?.out_time
							? dayjs(`${attendance.date} 
					${value?.out_time}`).format('HH:mm:ssZ')
							: null,
						user_id: userId,
					},
				},
			})
				.then((data) => {
					setFormError('');
					notify(
						value?.id
							? 'Attendance Updated Successfully'
							: 'Attendance Created Successfully'
					);
					refetch();
					onClose();
				})
				.catch((error) => {
					if (error) {
						notify(
							value?.id
								? 'Attendance updation failed'
								: 'Attendance creation failed',
							'warning'
						);
					}
				});
		} else {
			upsertAttendance({
				variables: {
					attendance: {
						...value,
						in_time: dayjs(`${attendance.date} ${value?.in_time}`).format(
							'HH:mm:ssZ'
						),
						out_time: value?.out_time
							? dayjs(`${attendance.date} 
					${value?.out_time}`).format('HH:mm:ssZ')
							: null,
						user_id: loggedInUserId,
					},
				},
			})
				.then((data) => {
					setFormError('');
					notify(
						value?.id
							? 'Attendance Updated Successfully'
							: 'Attendance Created Successfully'
					);
					refetch();
					onClose();
				})
				.catch((error) => {
					if (error) {
						notify(
							value?.id
								? 'Attendance updation failed'
								: 'Attendance creation failed',
							'warning'
						);
					}
				});
		}
	};
	const handleClose = () => {
		setFormError('');
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={style.container}>
					<Box className={classes.headerContainer}>
						<Typography className={classes.heading}>Edit Attendance</Typography>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</Box>
					<Form initialValues={attendance} onSubmit={onSave}>
						{({ handleSubmit, invalid }) => (
							<form onSubmit={handleSubmit}>
								<Typography className={classes.label}>In Time</Typography>
								<TextInput
									source='in_time'
									label=''
									type='time'
									validate={required()}
								/>
								<Typography className={classes.label}>Out Time</Typography>
								<TextInput source='out_time' label='' type='time' />
								<Box className={classes.buttonContainer}>
									<Button
										onClick={handleClose}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										disabled={invalid}
										className={invalid ? classes.disabledButton :classes.saveButton}
										type='submit'
									>
										Save
									</Button>
								</Box>
								<Box color='red'>{formError}</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default AttendanceEditForm;
