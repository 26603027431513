import React, { useState, useEffect, useContext } from 'react';
import { uploadFile } from '../../../../Api/restApi.config';
import { supportedFileFormats } from '../../../../Utils';
import { UserProfileContext } from '../../../../App';
import DocumentUpload from '../../../../SharedComponents/DocumentUpload/DocumentUpload.component';
import { Box } from '@material-ui/core';
import {
	useAddTaskDocMutation,
	useDeleteTaskDocMutation,
	useGetProjectTaskDocsQuery,
	useUpdateTaskFileNameMutation,
} from '../../../../generated/graphql';

interface Document {
	id?: any;
	url?: string;
	file_name?: string | null | undefined;
	__typename?: string;
}
interface propsType {
	uploaded: string;
	taskId: string;
}

const TaskDocumentTab = (props: propsType) => {
	const { permissions, orgId } = useContext<any>(UserProfileContext);
	const { uploaded, taskId } = props;
	const [documentList, setDocumentList] = useState<Document[]>();
	const [loading, setLoading] = useState(false);
	const [fileUploadError, setFileUploadError] = useState('');
	const [duplicationError, setDuplicationError] = useState(false);
	const [addOpportunityDoc, { error: uploadError }] = useAddTaskDocMutation();
	const [deleteOpportunityDoc] = useDeleteTaskDocMutation();
	const [updateOpportunityDoc] = useUpdateTaskFileNameMutation();

	const { data: taskDocumentList, refetch: refetchDocs } =
		useGetProjectTaskDocsQuery({
			variables: { task_id: taskId },
		});
	useEffect(() => {
		const selectedTaskDocumentList = taskDocumentList?.project_task_docs?.map(
			(document: Document) => {
				const docInfo = {
					id: document.id,
					docUrl: document.url,
					file_name: document.file_name,
				};
				return docInfo;
			}
		);

		setDocumentList(selectedTaskDocumentList);
	}, [taskDocumentList]);

	useEffect(() => {
		if (uploaded === 'success') {
			refetchDocs();
		}
	}, [uploaded, refetchDocs]);

	const FileUploadHandler = (uploadedFile: string) => {
		let filename: any = uploadedFile[1];
		if (supportedFileFormats.includes(filename.split('.').pop())) {
			setFileUploadError('');
			setLoading(true);
			let data = new FormData();
			data.append('uploadType', 'ProjectTaskDocs');
			data.append('file', uploadedFile[0]);
			data.append('id', orgId);
			uploadFile(data)
				.then((res) => {
					addOpportunityDoc({
						variables: {
							data: {
								type: 'ProjectTaskDocs',
								url: res.data.url,
								task_id: taskId,
								file_name: uploadedFile[1],
							},
						},
					})
						.then(() => {
							setLoading(false);
							setFileUploadError('File uploaded successfully');
							refetchDocs();
						})
						.catch(() => {
							setLoading(false);
							setFileUploadError('File already exist in the documents');
							if (uploadError) return `Submission error! ${uploadError}`;
						});
				})
				.catch((err) => {
					setFileUploadError(
						err.response?.data?.message || 'Document Upload failed'
					);
					setLoading(false);
					refetchDocs();
				});
		} else {
			setFileUploadError(
				` '${filename
					.split('.')
					.pop()}'  file format is not supported in documents. `
			);
			setLoading(false);
		}
	};

	const onDocDelete = (id: string) => {
		setFileUploadError('');
		setLoading(true);
		deleteOpportunityDoc({
			variables: {
				id,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError('File deleted successfully');
			})
			.catch(() => {
				setLoading(false);
				setFileUploadError('File deletion failed');
			});
	};

	const onFileRename = (newFieldName: string, fileId: string) => {
		setLoading(true);
		setFileUploadError('');
		setDuplicationError(false);
		updateOpportunityDoc({
			variables: {
				id: fileId,
				file_name: newFieldName,
			},
		})
			.then(() => {
				setLoading(false);
				refetchDocs();
				setFileUploadError(`File name updated successfully`);
			})
			.catch(() => {
				setLoading(false);
				setDuplicationError(true);
				setFileUploadError(
					`The file name ${newFieldName} already exist in document`
				);
			});
	};

	return (
		<Box>
			<DocumentUpload
				docs={documentList}
				FileUploadHandler={FileUploadHandler}
				onDocDelete={onDocDelete}
				loading={loading}
				refetchDocs={() => refetchDocs()}
				errorMessage={fileUploadError}
				onFileRename={onFileRename}
				onRenameError={duplicationError}
				pageHeight='510px'
				permission={
					permissions?.project_task_docs?.insert_permissions ? true : false
				}
			/>
		</Box>
	);
};

export default TaskDocumentTab;
