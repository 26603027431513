import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	createTheme,
	makeStyles,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import {
	BooleanInput,
	TextInput,
	number,
	useNotify,
	useRefresh,
} from 'react-admin';
import { settingsStyle } from '../../settingsStyle';
import { UserProfileContext } from '../../../../App';

import WarningConfirmationModal from '../../../../SharedComponents/WarningConfirmationModal.component';
import {
	ENABLE_UPDATE_EMPLOYEE_ID_LENGTH,
	GET_EMPLOYEE_ID_LENGTH,
	UPDATE_EMPLOYEE_ID_LENGTH,
} from '../../Settings.gql';
import { useQuery as useApolloQuery, useMutation } from '@apollo/client';
import { modalFormStyle } from '../../../../Layout/styles';
import { isEmpty } from 'lodash';
import ButtonWithLoader from '../../../../SharedComponents/Btn/ButtonWithLoader';
import { modalFormTheme } from '../../../../Layout/Theme.component';
import {
	ENABLE_EMPID_LENGTH_SETTING_CONFIRMATION,
	ENABLE_EMPID_SUCCESS_TEXT,
} from '../../constant';

interface EmployeeIdLength {
	employeeIdLength: string;
}

const employeeIdLengthSettingsStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

const EmployeeIdLengthSettings = () => {
	const {
		orgId: organizationId,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);
	const employeeIdLengthSettingsStyles = employeeIdLengthSettingsStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [employeeIdLength, setEmployeeIdLength] = useState('');
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isEmployeeIdLengthEnabledState, setIsEmployeeIdLengthEnabled] =
		useState<boolean>(false);

	const [
		enableUpdateEmployeeIdLength,
		{ loading: enableEmployeeIdLengthLoading },
	] = useMutation(ENABLE_UPDATE_EMPLOYEE_ID_LENGTH);

	const {
		data: organisationEmployeeIdLength,
		refetch: reftechOrganizationEmployeeIdLength,
	} = useApolloQuery(GET_EMPLOYEE_ID_LENGTH, {
		variables: {
			organizationId,
		},
	});

	useEffect(() => {
		if (isEmpty(organisationEmployeeIdLength?.organization_by_pk)) {
			return;
		}
		if (!isEmpty(organisationEmployeeIdLength?.organization_by_pk)) {
			setEmployeeIdLength(empIdLength);
		}
		setIsEmployeeIdLengthEnabled(isFixedEmpIdLengthEnabled || false);
	}, [organisationEmployeeIdLength, isFixedEmpIdLengthEnabled, empIdLength]);

	const handleSave = () => {
		enableUpdateEmployeeIdLength({
			variables: {
				id: organizationId,
				enable_fixed_employee_id_length: isEmployeeIdLengthEnabledState,
			},
		})
			.then((res) => {
				if (!res.errors) {
					refresh();
					notify(ENABLE_EMPID_SUCCESS_TEXT);
					setIsWarningModalShown(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [updateEmployeeIdLength, { loading: isUpdateEmployeeIdLengthLoading }] =
		useMutation(UPDATE_EMPLOYEE_ID_LENGTH);
	const onHandleSubmit = (value: EmployeeIdLength) => {
		const { employeeIdLength } = value;

		const trimmedValue = employeeIdLength.trim();

		const numericValue = Number(value.employeeIdLength);

		if (trimmedValue === '' || trimmedValue === '0') {
			notify('Employee ID Length cannot be empty or zero.');
			return;
		}
		if (
			!Number.isInteger(numericValue) ||
			numericValue < 1 ||
			numericValue > 6
		) {
			notify(
				'Employee ID length must be a number only integer between 1 and 6.'
			);
			return;
		}

		updateEmployeeIdLength({
			variables: {
				id: organizationId,
				employee_id_length: parseInt(value?.employeeIdLength),
			},
		}).then((data) => {
			notify('Employee ID Length updated successfully');
			reftechOrganizationEmployeeIdLength();
		});
	};

	const handleChange = (value: boolean) => {
		setIsEmployeeIdLengthEnabled(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsWarningModalShown((prevIsWarningModalShown) => {
			if (prevIsWarningModalShown) {
				setIsEmployeeIdLengthEnabled(!isEmployeeIdLengthEnabledState);
			}
			return false;
		});
	};

	return (
		<>
			<Form
				onSubmit={handleSave}
				initialValues={{
					EmployeeIdLengthEnabled: isEmployeeIdLengthEnabledState,
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div className={employeeIdLengthSettingsStyles.container}>
								<Box
									className={settingsStyles.inputContainer}
									display='flex'
									flexDirection='column'
								>
									<>
										<div className={settingsStyles.inputContainer}>
											<BooleanInput
												label='Enable Employee ID Length'
												source='EmployeeIdLengthEnabled'
												onChange={(value) => handleChange(value)}
											/>
										</div>
										<Typography
											className={employeeIdLengthSettingsStyles.description}
										>
											Enabling this will allow Update Employee ID Length.
										</Typography>
									</>
								</Box>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>

			{isEmployeeIdLengthEnabledState && (
				<ThemeProvider theme={modalFormTheme}>
					<Box className={styles.formContainer}>
						<Form
							initialValues={{ employeeIdLength }}
							onSubmit={onHandleSubmit}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={styles.inputContainer}>
										<Box className={styles.inputLabel}>
											<Typography className={classes.label}>
												Employee ID Length *
											</Typography>
										</Box>
										<Box className={styles.input}>
											<TextInput
												source='employeeIdLength'
												fullWidth={true}
												label={''}
												validate={[
													number(),
													(value) => {
														if (!value || value === 0 || value === null) {
															return 'Employee ID must be a non-zero value.';
														} else if (
															value.toString().includes('.') ||
															value < 1 ||
															value > 6
														) {
															return 'length must be a number only integer between 1 and 6';
														}
													},
												]}
											/>
										</Box>
									</Box>
									<Box
										className={classes.buttonContainer}
										style={{ padding: 0 }}
									>
										<ButtonWithLoader
											isLoading={isUpdateEmployeeIdLengthLoading}
											title='Save'
											isDisabled={
												invalid || pristine || isUpdateEmployeeIdLengthLoading
											}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</ThemeProvider>
			)}
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				warningMessage={ENABLE_EMPID_LENGTH_SETTING_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
				isLoading={enableEmployeeIdLengthLoading}
			/>
		</>
	);
};

export default EmployeeIdLengthSettings;
