import React from 'react';
import { TimesheetEmptyScreen } from './TimesheetEmptyScreen.component';
import { TimesheetStyles } from './TimesheetStyles';
import { head, some } from 'lodash';
import {
	Box,
	Typography,
	Tooltip,
	makeStyles,
	Button,
	IconButton,
	withStyles,
} from '@material-ui/core';
import {
	WeeklyTimesheetEntries,
	TimesheetEntry,
	UpdateTimesheetEntryHourPayload,
	DeletedTaskTimesheetEntries,
	HolidayProps,
} from './Timesheet.model';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../Layout/styles';
import dayjs from 'dayjs';
import TimeInput from '../../SharedComponents/TimeInput.component';
import { ExcludedLeaveProps as LeaveProp } from '../../SharedComponents/model';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import holiday from '../../assets/holiday.png';

const TimesheetWeekViewStyle = makeStyles({
	timesheetContent: {
		width: '30%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'left',
		padding: '0px 0px 0px 30px',
	},
	projectName: {
		fontFamily: 'Manrope-semibold',
		fontSize: '12px',
		color: '#343434',
		width: '210px',
	},
	companyName: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#4E4E4E',
		width: '210px',
		marginBottom: '10px',
	},
	task: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#4E4E4E',
		width: '210px',
	},
	notes: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px',
		color: '#5A5A5A',
		width: '210px',
	},
	hoursContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '78%',
		height: '100%',
	},
	timesheetHoursContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '82%',
	},
	totalHoursContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '17%',
		height: '100%',
		justifyContent: 'center',
	},
	timesheetTaskTotalHoursContainer: {
		width: '17%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	errorDayNavigationButtonLabel: {
		fontFamily: 'Manrope-medium',
		color: '#EA4335',
		fontSize: '12px !important',
	},
	errorIcon: {
		width: '10px',
		height: '10px',
		marginLeft: '4px',
	},
});

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

interface TimeSheetWeeklyViewProps {
	timesheet: WeeklyTimesheetEntries;
	weeklyTimsheetHours: {
		totalHours: string;
		dailyHours: {
			date: string;
			totalHours: string;
			isMaximumLimitExceed: boolean;
		}[];
		taskTotalHours: {
			taskId: any;
			totalWorkingHours: string;
			projectId: string;
		}[];
		timesheetEntriesCount: number;
	};
	onHourUpdate: (data: UpdateTimesheetEntryHourPayload) => void;
	onDelete: (data: DeletedTaskTimesheetEntries) => void;
	onSave: () => void;
	onSubmitTimesheet: () => void;
	onAddEntry: () => void;
	onCopyFromPreviousWeek: () => void;
	orgHoliday: HolidayProps[];
	leaves: any;
	disable: boolean;
}

const TimeSheetWeekView = (props: TimeSheetWeeklyViewProps) => {
	const {
		timesheet,
		weeklyTimsheetHours,
		onHourUpdate,
		onDelete,
		onSave,
		onSubmitTimesheet,
		onAddEntry,
		onCopyFromPreviousWeek,
		orgHoliday,
		leaves,
		disable,
	} = props;
	const timesheetStyles = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const timesheetWeekViewStyles = TimesheetWeekViewStyle();
	const modalFormStyles = modalFormStyle();
	const datagridStyles = datagridStyle();
	const getTaskTotalHours = (taskId: string, projectId: string) => {
		if (!taskId) {
			return;
		}
		const task =
			weeklyTimsheetHours.taskTotalHours.length > 0 &&
			weeklyTimsheetHours.taskTotalHours.find(
				(taskTotalHour) =>
					taskTotalHour.taskId === taskId &&
					taskTotalHour.projectId === projectId
			);
		return task ? task.totalWorkingHours : '00:00';
	};

	const timesheetTasksRow = (timesheetEntry: TimesheetEntry, index: number) => (
		<Box key={index}>
			{!timesheetEntry.isDeleted && (
				<Box className={timesheetStyles.timesheetContentContainer}>
					<Box className={timesheetWeekViewStyles.timesheetContent}>
						<Tooltip
							title={`${timesheetEntry.projectName || '- -'}`}
							placement='right'
						>
							<Typography
								className={`${timesheetWeekViewStyles.projectName} ${ellipsisStyles.ellipsis}`}
							>
								{timesheetEntry.projectName}
							</Typography>
						</Tooltip>
						<Tooltip
							title={`${timesheetEntry?.companyName || '- -'}`}
							placement='right'
						>
							<Typography
								className={`${timesheetWeekViewStyles.companyName} ${ellipsisStyles.ellipsis}`}
							>
								{timesheetEntry?.companyName || '- -'}
							</Typography>
						</Tooltip>
						<Tooltip title={`${timesheetEntry.taskName}`} placement='right'>
							<Typography
								className={`${timesheetWeekViewStyles.task} ${ellipsisStyles.ellipsis}`}
							>
								{timesheetEntry.taskName}
							</Typography>
						</Tooltip>
						{/* TODO Fix notes in timesheet */}
						<Tooltip
							title={`${timesheetEntry?.notes || '- -'}`}
							placement='right'
						>
							<Typography
								className={`${timesheetWeekViewStyles.notes} ${ellipsisStyles.ellipsis}`}
							>
								{`${timesheetEntry?.notes || '- -'}`}
							</Typography>
						</Tooltip>
					</Box>
					<Box className={timesheetWeekViewStyles.timesheetHoursContainer}>
						<Box className={timesheetWeekViewStyles.hoursContainer}>
							{timesheetEntry.workingHours.map((workingHour, index) => {
								return (
									<div key={index}>
										<TimeInput
											key={index}
											size={'small'}
											isDisabled={timesheet.isDisabled}
											iconVisiblity={false}
											value={workingHour.hoursAndMinutes || ''}
											isError={workingHour.isError}
											onChange={async (e) => {
												const task = await timesheetEntry.workingHours.find(
													(workingHour) => workingHour.id
												);
												onHourUpdate({
													index: index,
													updatedHour: e,
													taskId: timesheetEntry.taskId,
													projectId: timesheetEntry.projectId,
													isBillable: task?.isBillable,
												});
											}}
										/>
									</div>
								);
							})}
						</Box>
						<Box
							className={
								timesheetWeekViewStyles.timesheetTaskTotalHoursContainer
							}
						>
							<Typography className={timesheetStyles.totalHours}>
								{getTaskTotalHours(
									timesheetEntry.taskId,
									timesheetEntry.projectId
								)}
							</Typography>

							<Box
								display='flex'
								justifyContent='flex-end'
								alignItems='center'
								height='100%'
								width='24px'
							>
								{!timesheet.isDisabled && (
									<IconButton
										className={`${datagridStyles.listEditIconButton} ${timesheetWeekViewStyles.deleteIconButton}`}
									>
										<DeleteIcon
											id='timesheet_week_view_delete_icon'
											className={datagridStyles.editIcon}
											onClick={() => {
												onDelete({
													taskId: timesheetEntry.taskId,
													projectId: timesheetEntry.projectId,
												});
											}}
										/>
									</IconButton>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
	const checkHoliday = (date: string) => {
		if (!date && !orgHoliday) {
			return;
		}
		const holidayList = orgHoliday?.filter((holiday) => holiday?.date === date);
		const holidayTitle =
			holidayList &&
			holidayList?.length > 1 &&
			holidayList?.map((item) => `${item?.name ? item?.name : ''}`);
		return holidayList && holidayList?.length > 0 ? (
			<Tooltip
				title={
					holidayTitle
						? holidayTitle.join(',')
						: head(holidayList)?.date
						? `${head(holidayList)?.name}`
						: ''
				}
				placement='right'
			>
				<img src={holiday} alt='Holiday' width='24px' height='16px' />
			</Tooltip>
		) : (
			<></>
		);
	};
	return (
		<>
			<Box className={timesheetStyles.dayNavigationContainer}>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='flex-end'
					width='85%'
				>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='flex-end'
						width='83%'
					>
						<Box className={timesheetStyles.hoursContainer}>
							{weeklyTimsheetHours.dailyHours.map((day, index) => (
								<Box
									key={index}
									className={timesheetStyles.navigationButtonLabelContainer}
								>
									<Typography
										className={
											day.isMaximumLimitExceed
												? timesheetWeekViewStyles.errorDayNavigationButtonLabel
												: timesheetStyles.dayNavigationButtonLabel
										}
									>
										{day.isMaximumLimitExceed ? (
											<Box display='flex' alignItems='center'>
												{dayjs(day.date).format('ddd').toUpperCase()}
												<ErrorTooltip
													title='We know you are working hard but more than 24 hours is not permitted!'
													placement='top'
													arrow={true}
												>
													<InfoIcon
														className={timesheetWeekViewStyles.errorIcon}
													/>
												</ErrorTooltip>
											</Box>
										) : (
											<Box display='flex'>
												<Box>{dayjs(day.date).format('ddd').toUpperCase()}</Box>
												<Box width='1px' position='relative' top='-4px'>
													{leaves &&
														leaves?.length > 0 &&
														leaves?.map((leave: LeaveProp) => {
															if (day?.date === leave?.leaveDate) {
																return (
																	<Box>
																		<Tooltip
																			title={leave?.name}
																			placement='right'
																		>
																			<div
																				className={timesheetStyles.leaveCircle}
																			>
																				L
																			</div>
																		</Tooltip>
																	</Box>
																);
															}
														})}
												</Box>
												<Box
													width='1px'
													position='relative'
													top='-8px'
													right='-12px'
												>
													{checkHoliday(day?.date)}
												</Box>
											</Box>
										)}
									</Typography>
									<Typography
										className={
											day.isMaximumLimitExceed
												? timesheetWeekViewStyles.errorDayNavigationButtonLabel
												: timesheetStyles.dayNavigationButtonLabel
										}
									>
										{day.totalHours}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				</Box>
				<Box className={timesheetWeekViewStyles.totalHoursContainer}>
					<Typography className={timesheetStyles.totalHours}>
						{weeklyTimsheetHours.totalHours}
					</Typography>
					<Typography className={timesheetStyles.totalHoursLabel}>
						TOTAL HOURS
					</Typography>
				</Box>
			</Box>
			<Box className={timesheetStyles.content}>
				{(timesheet && weeklyTimsheetHours.timesheetEntriesCount > 0) ||
				(timesheet.timesheetEntries.length === 0 && !timesheet.isSaved) ||
				!timesheet.isSaved ? (
					<>
						{timesheet.timesheetEntries.map((timesheetEntry, index) =>
							timesheetTasksRow(timesheetEntry, index)
						)}
						<Box
							width='100%'
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							mt={2}
						>
							<Box className={timesheetStyles.submitTimesheetActionContainer}>
								<Button
									className={modalFormStyles.saveButton}
									variant='contained'
									startIcon={<AddIcon />}
									onClick={onAddEntry}
									disabled={disable || timesheet.isDisabled}
								>
									Add New Entry
								</Button>
								<Button
									className={timesheetStyles.submitTimesheetButton}
									variant='contained'
									onClick={() => {
										onSubmitTimesheet();
									}}
									disabled={
										timesheet.isSubmissionDisabled || !timesheet.isSaved
									}
								>
									Submit week for approval
								</Button>
							</Box>
							<Box>
								<Button
									className={modalFormStyles.saveButton}
									variant='contained'
									onClick={() => {
										onSave();
									}}
									disabled={
										disable ||
										timesheet.isDisabled ||
										timesheet.isSaved ||
										some(weeklyTimsheetHours.dailyHours, {
											isMaximumLimitExceed: true,
										}) ||
										some(timesheet.timesheetEntries, {
											isError: true,
										})
									}
								>
									Save
								</Button>
							</Box>
						</Box>
					</>
				) : (
					<TimesheetEmptyScreen
						text='No entries are available in this week! You can add new entry.'
						copyText='Copy all data from the most recent timesheets'
						onAddEntry={onAddEntry}
						onCopyFromPreviousEntry={onCopyFromPreviousWeek}
						disabled={disable}
					/>
				)}
			</Box>
		</>
	);
};

export default TimeSheetWeekView;
