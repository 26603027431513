import React, { useState, useContext } from 'react';

import {
	List,
	Datagrid,
	TextField,
	FunctionField,
	ReferenceField,
	Loading,
	useRefresh,
	useNotify,
} from 'react-admin';

import head from 'lodash/head';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	Box,
	IconButton,
	Button,
	Typography,
	Tooltip,
	makeStyles,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import { settingsStyle } from '../settingsStyle';
import EditIcon from '@material-ui/icons/Edit';
import OrganisationShiftForm from './OrganisationShiftForm.component';
import { GET_ORGANISATION, GET_ORGANISATION_SHIFT } from '../Settings.gql';
import { useQuery as useApolloQuery } from '@apollo/client';
import { OrganisationResponse } from './Organisation.model';
import { UserProfileContext } from '../../../App';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	useGetShiftEmployeesCountQuery,
	useGetShiftEmployeesQuery,
	useGetOrgShiftMappingsQuery,
	useDeleteOrgShiftMutation,
} from '../../../generated/graphql';
import OrgShiftEmployeeListModal from './OrgShiftEmployeeListModal.component';
import OrgShiftEmployeeMappingForm from './OrgShiftEmployeeMappingForm.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import { DELETE_ORG_SHIFT_MESSAGE } from './constant';
import StarIcon from '@material-ui/icons/Star';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';

const organisationShiftStyle = makeStyles({
	name: {
		width: '100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	rowContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#292929',
		width: '180px',
	},
	employeesCount: {
		width: 'fit-content',
	},
	actionContainer: {
		width: '130px',
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
	},
	IconButton: {
		marginRight: '10px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	nonDefaultColor: {
		color: '#C7C7C7',
		width: '20px',
		height: '20px',
	},
	defaultColor: {
		color: '#EA9926',
		width: '20px',
		height: '20px',
	},
});

interface OrganisationShift {
	id: string;
	name: string;
	start_time: string;
	end_time: string;
	currency_id?: string;
	allowance: number;
}

interface OrgShiftDeptMappings {
	orgShiftMappings?: {
		orgShiftMappingId: any;
		department_id: any;
	}[];
	department_id?: any[];
}

const OrganisationShift = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const [isOrganisationShiftFormShown, setIsOrganisationShiftFormShown] =
		useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isEditMutation, setIsEditMutation] = useState(false);
	const [isEmployeeMappingFormShown, setIsEmployeeMappingFormShown] =
		useState(false);
	const [selectedOrganisationShift, setSelectedOrganisationShift] =
		useState<any>({});
	const [searchedEmployee, setSearchedEmployee] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const datagridStyles = datagridStyle();
	const tooltipStyle = ellipsisStyle();
	const organisationShiftStyles = organisationShiftStyle();
	const [selectedOrgShiftMappings, setSelectedOrgShiftMappings] =
		useState<OrgShiftDeptMappings>();
	const [isEmployeesListModalShown, setIsEmployeesListModalShown] =
		useState(false);
	const [selectedOrgShiftId, setSelectedOrgShiftId] = useState(null);
	const [searchShift, setSearchShift] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();

	const {
		data: organisationShifts,
		loading,
		refetch: refetchOrganisationShifts,
	} = useApolloQuery(GET_ORGANISATION_SHIFT);
	const { data: organisation } = useApolloQuery<OrganisationResponse>(
		GET_ORGANISATION,
		{
			variables: {
				organisationId: organisationId,
			},
		}
	);

	const { data: orgShiftDeptMappings, refetch: refetchOrgShiftDeptMappings } =
		useGetOrgShiftMappingsQuery({
			fetchPolicy: 'network-only',
		});

	const { data: orgShiftEmployees } = useGetShiftEmployeesQuery({
		variables: {
			orgShiftId: selectedOrgShiftId,
			searchedName: `%${searchedEmployee}%`,
		},
		fetchPolicy: 'network-only',
		skip: !selectedOrgShiftId,
	});

	const [deleteOrgShift] = useDeleteOrgShiftMutation();

	const {
		data: orgShiftEmployeesCount,
		refetch: refetchOrgShiftEmployeesCount,
	} = useGetShiftEmployeesCountQuery({
		fetchPolicy: 'network-only',
	});

	if (loading) {
		return <Loading />;
	}

	const ToolBar = () => (
		<Button
			variant='contained'
			className={modalFormStyles.saveButton}
			onClick={() => {
				setIsOrganisationShiftFormShown(true);
			}}
		>
			Add Shift
		</Button>
	);

	const onHandleEditOrganisationShift = (OrganisationShiftId: string) => {
		if (!organisationShifts) {
			return;
		}
		const organisationShift = organisationShifts.org_shift.find(
			({ id }: { id: string }) => id === OrganisationShiftId
		);
		if (!organisationShift) {
			return;
		}
		const { __typename, ...organisationShiftPayload } = organisationShift;
		setSelectedOrganisationShift({
			...organisationShiftPayload,
		});
		setIsEditMutation(true);
		setIsOrganisationShiftFormShown(true);
	};

	const getEmployeesCount = (orgShiftId: string) => {
		if (!orgShiftId || !orgShiftEmployeesCount) {
			return;
		}
		const orgShift = orgShiftEmployeesCount.org_shift.find(
			(orgShift) => orgShift.id === orgShiftId
		);

		const employeesCount = orgShift?.employees_aggregate.aggregate?.count || 0;
		const contractorsCount =
			orgShift?.contractors_aggregate.aggregate?.count || 0;

		return employeesCount + contractorsCount;
	};

	const handleDeleteOrgShift = (orgShiftId: any) => {
		if (!orgShiftId) {
			return;
		}
		deleteOrgShift({
			variables: {
				orgShiftId: orgShiftId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					refresh();
					notify(DELETE_ORG_SHIFT_MESSAGE);
					setSelectedOrgShiftId(null);
					setIsDeleteModalShown(false);
					setIsWarningModalShown(false);
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px' marginTop={'-1px'}>
							<TextComponent
								placeholder='Search Shift'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => {
									setSearchShift(e?.target?.value);
								}}
								value={searchShift}
							/>
						</Box>
					</form>
				</ThemeProvider>
				<ToolBar />
			</Box>
			<Box className={settingsStyles.content} sx={{ whiteSpace: 'nowrap' }} >
				<List
					empty={false}
					resource='org_shift'
					basePath='/org_shift'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: searchShift }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							source='is_default'
							label=''
							render={(shift: any) => (
								<StarIcon
									className={
										shift.is_default
											? organisationShiftStyles.defaultColor
											: organisationShiftStyles.nonDefaultColor
									}
								/>
							)}
						/>
						<FunctionField
							label='NAME'
							render={(shift: any) => {
								return (
									<Tooltip title={`${shift.name}`} placement='right'>
										<Typography
											className={[
												organisationShiftStyles.name,
												tooltipStyle.ellipsis,
											].join(' ')}
										>
											{shift.name}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<TextField source='start_time' label='START TIME' />
						<TextField source='end_time' label='END TIME' />
						<FunctionField
							source='is_active'
							label='IS ACTIVE'
							render={(shift: any) => (shift.is_active ? `Yes` : `No`)}
						/>
						<TextField source='allowance' label='ALLOWANCE' />
						<FunctionField
							source='currency_id'
							label='CURRENCY'
							render={(shift: any) =>
								shift.currency_id ? (
									<ReferenceField
										source='currency_id'
										reference='master_currencies'
									>
										<TextField source='currency_type' />
									</ReferenceField>
								) : (
									<Typography>- -</Typography>
								)
							}
						/>
						<FunctionField
							render={(orgShift: any) => (
								<Box>
									<div
										className={organisationShiftStyles.employeesContainer}
										onClick={() => {
											setSelectedOrgShiftId(orgShift.id);
											setIsEmployeesListModalShown(true);
										}}
									>
										<PeopleOutlineIcon
											className={organisationShiftStyles.peoplesIcon}
										/>
										<Typography
											className={`${organisationShiftStyles.rowContent} ${organisationShiftStyles.employeesCount}`}
										>
											{getEmployeesCount(orgShift.id)}
										</Typography>
									</div>
								</Box>
							)}
						/>
						<FunctionField
							render={(shift: any) => (
								<Box className={organisationShiftStyles.actionContainer}>
									<Box className={organisationShiftStyles.actions}>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${organisationShiftStyles.IconButton}`}
											onClick={() => {
												const orgShift = orgShiftDeptMappings?.org_shift.find(
													(orgShiftDeptMapping) =>
														orgShiftDeptMapping.id === shift.id
												);
												if (orgShift && orgShift?.departments.length > 0) {
													setSelectedOrgShiftMappings({
														orgShiftMappings: orgShift.departments.map(
															(department) => ({
																orgShiftMappingId: department.id,
																department_id: department.department.id,
															})
														),
														department_id: orgShift.departments.map(
															(department) => department.department.id
														),
													});
													setSelectedOrgShiftId(shift.id);
													setIsEmployeeMappingFormShown(true);
													return;
												}
												setSelectedOrgShiftId(shift.id);
												setIsEmployeeMappingFormShown(true);
											}}
										>
											<PersonAddIcon className={datagridStyles.editIcon} />
										</IconButton>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${organisationShiftStyles.IconButton}`}
											onClick={() => {
												onHandleEditOrganisationShift(shift?.id);
											}}
										>
											<EditIcon className={datagridStyles.editIcon} />
										</IconButton>

										<IconButton
											className={`${datagridStyles.listEditIconButton} ${organisationShiftStyles.IconButton} ${organisationShiftStyles.deleteIconButton}`}
										>
											<DeleteIcon
												onClick={() => {
													const employeesCount = getEmployeesCount(shift.id);
													if (employeesCount && employeesCount > 0) {
														setIsWarningModalShown(true);
														return;
													}
													setSelectedOrgShiftId(shift.id);
													setIsDeleteModalShown(true);
												}}
												className={datagridStyles.editIcon}
											/>
										</IconButton>
									</Box>
								</Box>
							)}
						/>
					</Datagrid>
				</List>
			</Box>
			<OrganisationShiftForm
				open={isOrganisationShiftFormShown}
				onClose={() => {
					setIsOrganisationShiftFormShown(false);
					setSelectedOrganisationShift({});
					setIsEditMutation(false);
				}}
				isEditMutation={isEditMutation}
				initialValues={selectedOrganisationShift}
				onSuccess={() => {
					refetchOrganisationShifts();
				}}
				baseCurrency={head(organisation?.organization)?.currency_id}
			/>
			<OrgShiftEmployeeListModal
				open={isEmployeesListModalShown}
				onClose={() => {
					setSelectedOrgShiftId(null);
					setSearchedEmployee('');
					setIsEmployeesListModalShown(false);
				}}
				employees={
					orgShiftEmployees
						? orgShiftEmployees.user.map((user) => ({
								name: user.full_name,
								email: `${user?.email || ''}`,
						  }))
						: []
				}
				onSearchChange={(searchText: string) => {
					setSearchedEmployee(searchText);
				}}
			/>
			<OrgShiftEmployeeMappingForm
				open={isEmployeeMappingFormShown}
				onClose={() => {
					setSelectedOrgShiftId(null);
					setSelectedOrgShiftMappings({});
					setIsEmployeeMappingFormShown(false);
				}}
				orgShiftId={selectedOrgShiftId}
				orgShiftMappings={selectedOrgShiftMappings}
				refetch={() => {
					refetchOrgShiftDeptMappings();
					refetchOrgShiftEmployeesCount();
				}}
			/>
			<DeleteModal
				open={isDeleteModalShown}
				onClose={() => {
					setSelectedOrgShiftId(null);
					setIsDeleteModalShown(false);
					setIsWarningModalShown(false);
				}}
				confirmationMessage={
					'Are you sure want to delete this Shift?. This action cannot be undone.'
				}
				onDelete={() => {
					handleDeleteOrgShift(selectedOrgShiftId);
				}}
			/>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
				}}
				warningMessage={'Unable to delete as there as associated data'}
			/>
		</>
	);
};

export default OrganisationShift;
