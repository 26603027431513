import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { useQuery, SelectInput, Loading, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
	modalFormTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import AddIcon from '@material-ui/icons/Add';
import {
	Box,
	makeStyles,
	Button,
	TextField,
	Tooltip,
	Typography,
	LinearProgress,
} from '@material-ui/core';
import { useQuery as ApolloUseQuery } from '@apollo/client';
import { exportToCsv, getCurrencyFormat } from '../../../Utils/string.util';
import { SearchIcon } from '../../../assets/SvgIcons';
import { ThemeProvider } from '@material-ui/core/styles';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import ProjectMilestoneForm from './ProjectMilestoneForm.component';
import {
	PROJECT_MILESTONE_BY_PK,
	GET_PROJECT,
	GET_MILESTONE_LIST,
	GET_MILESTONE_PROGRESS,
	UPDATE_MILESTONE_ORDER,
} from '../gqlQueries';
import ProjectMilestonePopOver from './ProjectMilestonePopOver.component';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import { ProjectMilestone, ProjectMilestonesProgress } from '../modal';
import { Form } from 'react-final-form';
import { ALL_OPTION } from './constant';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { tableListStyle } from '../../Settings/settingsStyle';
import { useMutation as useApolloMutation } from '@apollo/client';
import { MILESTONE_EXPORT_COLUMNS } from '../constant';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	addMilestoneButton: {
		textTransform: 'none',
		width: '130px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box !important',
		boxShadow: '0px 4px 12px #4285F43B !important',
		borderRadius: '4px',
		fontSize: '10px',
		marginLeft: '10px',
		fontFamily: 'Manrope-bold',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	title: {
		width: '80px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	tableList: {
		height: '385px',
		overflowY: 'auto',
		overflowX: 'hidden',
		marginTop: '30px',
	},
	percentage: {
		marginLeft: '10px',
	},
	progressBarRoot: {
		height: 7,
		borderRadius: 5,
		width: '50px',
	},
	progressBarColorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	progressBar: {
		borderRadius: 5,
		backgroundColor: '#3ABC9E',
	},
	progressBarContainer: {
		display: 'flex',
		cursor: 'pointer',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	dropdownfilterContainer: {
		height: '28px',
		marginLeft: '16px',
	},
	milestonePeriodEnded: {
		color: '#EA4335',
	},
	dragIndicator: {
		width: '18px',
		height: '18px',
	},
	noData: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	dataContainer: {
		width: '100px',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	subContainer: {
		display: 'flex',
		marginRight: '10px',
	},
	cursor: {
		cursor: 'pointer',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
}));

interface MilestoneAmount {
	id: string;
	amount: number;
}
interface Choice {
	id: string;
	name: string;
	label: string;
	value: string;
}

const ProjectMilestoneList = () => {
	const { permissions, dateFormat } = useContext<any>(UserProfileContext);
	const { id }: { id: string } = useParams();
	const classes = useStyles();
	const styles = ellipsisStyle();
	const notify = useNotify();
	const [PaymentStatusId, setPaymentStatusId] = useState('');
	const [paymentStatusList, setPaymentStatusList] = useState([]);
	const [isMilestoneModalShown, setIsMilestoneModalShown] = useState(false);
	const [searchMilestone, setSearchMilestone] = useState('');
	const [milestonestatusList, setMilestoneStatusList] = useState([]);
	const [milestoneStatusId, setMilestoneStatusId] = useState('');
	const [milestoneId, setMilestoneId] = useState(null);
	const [milestoneAmount, setMilestoneAmount] = useState(0);
	const [milestoneForm, setMilestoneForm] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [milestoneStatusFilter, setMilestoneStatusFilter] =
		useState(ALL_OPTION);
	const [milestonePaymentStatusFilter, setMilestonePaymentStatusFilter] =
		useState(ALL_OPTION);

	const { data: projectMilestone } = ApolloUseQuery(PROJECT_MILESTONE_BY_PK, {
		variables: {
			projectMilestoneId: milestoneId || null,
		},
	});
	const dragAndDropRowStyle = tableListStyle();
	const modalFormStyles = modalFormStyle();
	const [columns, setColumns] = useState<ProjectMilestone[]>();
	const [isColumnReOrdered, setIsColumnReOrdered] = useState(false);
	const [updateMileStoneOrder] = useApolloMutation(UPDATE_MILESTONE_ORDER);
	const {
		data: projectMilestoneList,
		loading: isMilestoneLoading,
		refetch: refetchMileStoneTable,
	} = ApolloUseQuery<ProjectMilestonesProgress>(GET_MILESTONE_PROGRESS, {
		variables: {
			projectMilestoneFilter: {
				project_id: {
					_eq: id,
				},
				name: {
					_ilike: `%${searchMilestone}%`,
				},
				project_milestone_status: {
					id:
						milestoneStatusFilter === ALL_OPTION
							? {}
							: {
									_eq: milestoneStatusFilter,
							  },
				},
				payment_status_id:
					milestonePaymentStatusFilter === ALL_OPTION
						? {}
						: {
								_eq: milestonePaymentStatusFilter,
						  },
			},
		},
		fetchPolicy: 'network-only',
	});

	const { data: project } = ApolloUseQuery(GET_PROJECT, {
		variables: {
			projectId: id ? id : null,
		},
	});

	const { data: milestoneList, refetch: refetchMilestoneList } = ApolloUseQuery(
		GET_MILESTONE_LIST,
		{
			variables: {
				project_id: id ? id : null,
			},
			fetchPolicy: 'network-only',
		}
	);

	const { data: milestonePaymentStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'milestone_payment_status',
		payload: {
			sort: { field: 'label', order: 'ASC' },
		},
	});

	const { data: milestoneStatus } = useQuery({
		type: 'GET_LIST',
		resource: 'project_milestone_status',
		payload: { sort: { field: 'label', order: 'ASC' } },
	});
	useEffect(() => {
		if (!milestoneList || !(milestoneList?.project_milestone?.length > 0)) {
			return;
		}
		const milestoneSum = milestoneList?.project_milestone?.reduce(
			(sum: number, number: MilestoneAmount) => sum + number['amount'],
			0
		);
		setMilestoneAmount(milestoneSum);
	}, [milestoneList]);

	useEffect(() => {
		if (!milestoneStatus || !(milestoneStatus.length > 0)) {
			return;
		}
		const status = milestoneStatus.map((value: Choice) => {
			if (value?.value === 'pending') {
				setMilestoneStatusId(value?.id);
			}
			return {
				id: value?.id,
				name: value?.label,
			};
		});
		setMilestoneStatusList(status);
	}, [milestoneStatus]);

	useEffect(() => {
		if (!milestonePaymentStatus || !(milestonePaymentStatus.length > 0)) {
			return;
		}
		const payment = milestonePaymentStatus.map((value: Choice) => {
			if (value?.value === 'pending') {
				setPaymentStatusId(value?.id);
			}
			return {
				id: value?.id,
				name: value?.label,
			};
		});
		setPaymentStatusList(payment);
	}, [milestonePaymentStatus]);

	useEffect(() => {
		if (isEdit) {
			return;
		}
		setMilestoneForm({
			currency_id: project?.project_by_pk?.currency_id,
			link_to_invoice: true,
			payment_status_id: PaymentStatusId,
			status: milestoneStatusId,
		});
	}, [project, isEdit, PaymentStatusId, milestoneStatusId]);

	useEffect(() => {
		if (!(projectMilestone || projectMilestone?.project_milestone_by_pk)) {
			return;
		}
		setMilestoneForm({
			name: projectMilestone.project_milestone_by_pk?.name,
			end_date: projectMilestone.project_milestone_by_pk?.end_date,
			link_to_invoice:
				projectMilestone.project_milestone_by_pk?.link_to_invoice,
			start_date: projectMilestone.project_milestone_by_pk?.start_date,
			id: projectMilestone.project_milestone_by_pk?.id,
			amount: projectMilestone.project_milestone_by_pk?.amount,
			payment_status_id:
				projectMilestone.project_milestone_by_pk?.payment_status_id,
			status: projectMilestone.project_milestone_by_pk?.status,
			currency_id: project?.project_by_pk?.currency_id,
		});
	}, [projectMilestone, isEdit, project]);

	const getMileStonePercentage = (milestoneId: string) => {
		if (!projectMilestoneList) {
			return {
				completedTask: 0,
				totalTasks: 0,
				completionPercentage: 0,
			};
		}

		const totalTasks = projectMilestoneList.project_milestone.find(
			(milestone: any) => milestone.id === milestoneId
		)?.totalTasks.aggregate.count;
		const completedTasks = projectMilestoneList.project_milestone.find(
			(milestone: any) => milestone.id === milestoneId
		)?.compeletedTasks.aggregate.count;

		return completedTasks === 0 && totalTasks === 0
			? {
					completedTask: 0,
					totalTasks: 0,
					completionPercentage: 0,
			  }
			: {
					completedTask: completedTasks,
					totalTasks: totalTasks,
					completionPercentage:
						completedTasks === undefined || totalTasks === undefined
							? 0
							: (completedTasks / totalTasks) * 100,
			  };
	};

	const getMilestoneStatusOptions = () => {
		if (!milestoneStatus) {
			return [];
		}
		const milestoneStatusOptions = milestoneStatus.map(
			(status: { id: string; label: string; value: string }) => ({
				id: status.id,
				name: status.label,
			})
		);
		return [
			{
				id: ALL_OPTION,
				name: ALL_OPTION,
			},
			...milestoneStatusOptions,
		];
	};

	const getMilestonePaymentStatusOptions = () => {
		if (!milestonePaymentStatus) {
			return [];
		}

		const milestonePaymentStatusOptions = milestonePaymentStatus.map(
			(status: { id: string; label: string; value: string }) => ({
				id: status.id,
				name: status.label,
			})
		);
		return [
			{
				id: ALL_OPTION,
				name: ALL_OPTION,
			},
			...milestonePaymentStatusOptions,
		];
	};

	const getMilestoneEndDateTooltipValue = (date: string): string => {
		if (!date) {
			return '- -';
		}
		const diffDays = dayjs(date)
			.startOf('day')
			.diff(dayjs().startOf('day'), 'days');
		if (diffDays < 0) {
			return `Overdue by ${dayjs().diff(dayjs(date), 'days')}`;
		}
		return dayjs(date).format(dateFormat);
	};

	const getMilestoneEndDate = (date: string): ReactNode => {
		if (!date) {
			return <Typography className={classes.title}>- -</Typography>;
		}
		const diffDays = dayjs(date)
			.startOf('day')
			.diff(dayjs().startOf('day'), 'days');
		const milestoneAvailableDays = `${diffDays} Days`;
		if (diffDays === 0) {
			return (
				<Typography className={`${classes.title} ${styles.ellipsis}`}>
					Due in 0 day
				</Typography>
			);
		}
		if (diffDays < 0) {
			return (
				<Typography
					className={`${classes.title} ${styles.ellipsis} ${classes.milestonePeriodEnded}`}
				>
					{dayjs(date).format(dateFormat)}
				</Typography>
			);
		}

		if (diffDays >= 30) {
			return (
				<Typography className={`${classes.title} ${styles.ellipsis}`}>
					{dayjs(date).format(dateFormat)}
				</Typography>
			);
		}

		return (
			<Typography className={`${classes.title} ${styles.ellipsis}`}>
				{`${milestoneAvailableDays} left`}
			</Typography>
		);
	};

	useEffect(() => {
		if (!projectMilestoneList) {
			return;
		}
		const mileStoneTableList = projectMilestoneList?.project_milestone;
		setColumns(mileStoneTableList);
	}, [projectMilestoneList]);
	const reorder = (
		list: ProjectMilestone[],
		startIndex: number,
		endIndex: number
	) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}
		if (
			searchMilestone ||
			(milestoneStatusFilter && milestoneStatusFilter !== 'All') ||
			(milestonePaymentStatusFilter && milestonePaymentStatusFilter !== 'All')
		) {
			notify('To enable reordering kindly remove all applied filters');
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const reOrderedColumns =
			columns &&
			reorder(columns, result.source.index, result.destination.index);
		if (reOrderedColumns) {
			setColumns(reOrderedColumns);
			setIsColumnReOrdered(true);
		}
	};

	const onSaveMilestoneOrder = () => {
		const mileStoneData = columns?.map((column, index) => {
			return {
				where: { id: { _eq: column?.id } },
				_set: { ordinal: index + 1 },
			};
		});
		updateMileStoneOrder({
			variables: {
				mileStoneData: mileStoneData,
			},
		})
			.then((response) => {
				if (response) {
					notify('Milestone order updated successfully');
					setIsColumnReOrdered(false);
					refetchMileStoneTable();
				}
			})
			.catch((error) => {
				console.log(error);
				return;
			});
	};

	const getMileStoneEndDateIndicator = (
		statusValue: string,
		endDate: string
	) => {
		if (!statusValue && !endDate) {
			return '';
		}
		return statusValue === 'complete'
			? dayjs(endDate).format(dateFormat)
			: getMilestoneEndDateTooltipValue(endDate);
	};

	const exportMileStones = () => {
		if (!projectMilestoneList) {
			return;
		}
		const mileStoneExportList = projectMilestoneList?.project_milestone?.map(
			(mileStone) => {
				return [
					mileStone?.name ? String(mileStone?.name).trim() : '--',
					mileStone?.amount
						? `${project?.project_by_pk?.currency?.symbol || ''}${
								mileStone?.amount
						  }`
						: '--',
					mileStone?.start_date
						? dayjs(mileStone?.start_date).format(dateFormat)
						: '--',
					mileStone?.end_date
						? dayjs(mileStone?.end_date).format(dateFormat)
						: '--',
					mileStone?.totalTasks?.aggregate?.count || '--',
					mileStone?.compeletedTasks?.aggregate?.count || '--',
					mileStone?.project_milestone_status?.label || '--',
					mileStone?.milestone_payment_status?.label || '--',
					mileStone?.link_to_invoice ? 'Yes' : 'No',
				];
			}
		);
		const mileStoneExportData = [
			MILESTONE_EXPORT_COLUMNS,
			...mileStoneExportList,
		];
		exportToCsv('Project_MileStone_Export.csv', mileStoneExportData);
	};
	return (
		<>
			<Box className={classes.toolbar}>
				<Box display='flex' alignItems='center'>
					<ThemeProvider theme={searchBarTheme}>
						<form onSubmit={(event) => event.preventDefault()}>
							<Box width='250px'>
								<TextField
									placeholder='Search Milestone'
									label={false}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(e: any) => setSearchMilestone(e?.target?.value)}
								/>
							</Box>
						</form>

						<Form
							onSubmit={() => {}}
							initialValues={{
								milestone_status: ALL_OPTION,
								milestone_payment_status: ALL_OPTION,
							}}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box display='flex' alignItems='center'>
											<Box
												className={classes.dropdownfilterContainer}
												paddingBottom={'5px'}
												marginTop={'-20px'}
											>
												<Box
													className={classes.dropDownFilterLabel}
													marginLeft={'3px'}
												>
													Status
												</Box>
												<SelectInput
													label={''}
													source='milestone_status'
													onChange={(event) => {
														if (event.target.value === ALL_OPTION) {
															setMilestoneStatusFilter(ALL_OPTION);
															return;
														}
														setMilestoneStatusFilter(event.target.value);
													}}
													choices={getMilestoneStatusOptions()}
												/>
											</Box>
											<Box
												className={classes.dropdownfilterContainer}
												paddingBottom={'5px'}
												marginTop={'-20px'}
											>
												<Box
													className={classes.dropDownFilterLabel}
													marginLeft={'3px'}
												>
													Payment status
												</Box>
												<SelectInput
													label={''}
													source='milestone_payment_status'
													onChange={(event) => {
														if (event.target.value === ALL_OPTION) {
															setMilestonePaymentStatusFilter(ALL_OPTION);
															return;
														}
														setMilestonePaymentStatusFilter(event.target.value);
													}}
													choices={getMilestonePaymentStatusOptions()}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</ThemeProvider>
				</Box>
				<Box>
					<Box display='flex'>
						<Box>
							<Tooltip title='Export' placement='top'>
								<ExportButtonWithLoader generateCSV={ () => { exportMileStones()}} isDisabled={isEmpty(projectMilestoneList?.project_milestone)}/>
															</Tooltip>
						</Box>
						{permissions?.project_milestone?.insert_permissions && (
							<Button
								variant='contained'
								color='primary'
								startIcon={<AddIcon />}
								className={classes.addMilestoneButton}
								onClick={() => {
									setMilestoneId(null);
									setIsEdit(false);
									setIsMilestoneModalShown(true);
								}}
							>
								Add Milestone
							</Button>
						)}
					</Box>
				</Box>
			</Box>
			<Box>
				{isMilestoneLoading ? (
					<Loading />
				) : (
					<>
						<Box className={classes.tableList}>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId='list'>
									{(provided) => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											{columns && columns?.length > 0 ? (
												columns?.map((milestone, index: number) => (
													<Draggable
														draggableId={milestone.id}
														index={index}
														key={milestone.id}
														isDragDisabled={
															!permissions?.project_milestone
																?.update_permissions
														}
													>
														{(provided) => (
															<div
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
															>
																<div
																	className={dragAndDropRowStyle.Container}
																	key={milestone.id}
																>
																	<div className={classes.container}>
																		<div className={classes.subContainer}>
																			<DragIndicatorIcon
																				className={classes.dragIndicator}
																			/>
																		</div>
																		<Tooltip title={milestone?.name}>
																			<div
																				className={`${dragAndDropRowStyle.name} ${styles.ellipsis} ${classes.cursor}`}
																			>
																				{milestone?.name}
																			</div>
																		</Tooltip>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			Amount
																		</div>
																		<Tooltip
																			title={`${milestone?.amount || '--'}`}
																		>
																			<div
																				className={`${dragAndDropRowStyle.rowContent} ${styles.ellipsis} ${classes.cursor}`}
																			>
																				{milestone?.amount
																					? getCurrencyFormat(
																							milestone?.amount,
																							project?.project_by_pk?.currency
																								?.currency_type,
																							true
																					  )
																					: '--'}
																			</div>
																		</Tooltip>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			Start Date
																		</div>
																		<Tooltip
																			title={
																				milestone?.start_date
																					? dayjs(milestone?.start_date).format(
																							dateFormat
																					  )
																					: '--'
																			}
																		>
																			<div
																				className={`${dragAndDropRowStyle.rowContent} ${classes.cursor}`}
																			>
																				{milestone?.start_date
																					? dayjs(milestone?.start_date).format(
																							dateFormat
																					  )
																					: '--'}
																			</div>
																		</Tooltip>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			End Date
																		</div>
																		<Tooltip
																			title={
																				milestone?.end_date
																					? getMileStoneEndDateIndicator(
																							milestone
																								?.project_milestone_status
																								?.value,
																							milestone?.end_date
																					  )
																					: '--'
																			}
																		>
																			<div
																				className={`${dragAndDropRowStyle.rowContent} ${classes.cursor}`}
																			>
																				{milestone?.end_date
																					? milestone?.project_milestone_status
																							?.value === 'complete'
																						? dayjs(milestone?.end_date).format(
																								dateFormat
																						  )
																						: getMilestoneEndDate(
																								milestone?.end_date
																						  )
																					: '--'}
																			</div>
																		</Tooltip>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			Progress
																		</div>
																		<div
																			className={`${dragAndDropRowStyle.rowContent} ${classes.progressBarContainer}`}
																		>
																			<div style={{ marginTop: '7px' }}>
																				<LinearProgress
																					variant='determinate'
																					value={Number(
																						getMileStonePercentage(
																							milestone?.id
																						)?.completionPercentage?.toFixed()
																					)}
																					classes={{
																						root: classes.progressBarRoot,
																						colorPrimary:
																							classes.progressBarColorPrimary,
																						bar: classes.progressBar,
																					}}
																				/>
																			</div>

																			<Typography
																				className={classes.percentage}
																				variant='caption'
																			>
																				{getMileStonePercentage(
																					milestone?.id
																				)?.completionPercentage?.toFixed()}
																				%
																			</Typography>
																		</div>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			Status
																		</div>
																		<div
																			className={`${dragAndDropRowStyle.rowContent} ${styles.ellipsis} ${classes.cursor}`}
																		>
																			{milestone?.project_milestone_status
																				?.label || '--'}
																		</div>
																	</div>
																	<div className={classes.dataContainer}>
																		<div className={dragAndDropRowStyle.label}>
																			Payment
																		</div>
																		<div
																			className={`${dragAndDropRowStyle.rowContent} ${styles.ellipsis} ${classes.cursor}`}
																		>
																			{milestone?.milestone_payment_status
																				?.label || '--'}
																		</div>
																	</div>
																	{permissions?.project_milestone
																		?.update_permissions && (
																		<ProjectMilestonePopOver
																			milestoneId={milestone?.id}
																			milestoneAmount={milestoneAmount}
																			paymentStatusList={paymentStatusList}
																			milestoneStatusList={milestonestatusList}
																			currencyId={
																				project?.project_by_pk?.currency_id
																			}
																			validation={{
																				projectStartdate:
																					project?.project_by_pk?.start_date,
																				projectEnddate:
																					project?.project_by_pk?.end_date,
																				revenue:
																					project?.project_by_pk?.revenue,
																			}}
																			refetch={() => {
																				refetchMilestoneList();
																				refetchMileStoneTable();
																			}}
																		/>
																	)}
																</div>
															</div>
														)}
													</Draggable>
												))
											) : (
												<>
													<Typography className={classes.noData}>
														No data found
													</Typography>
												</>
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</Box>
						{columns && columns?.length > 0 && (
							<Box display='flex' justifyContent='flex-end' mt={2}>
								<Button
									disabled={!isColumnReOrdered}
									className={
										isColumnReOrdered
											? modalFormStyles.saveButton
											: modalFormStyles.disabledButton
									}
									variant='contained'
									onClick={() => onSaveMilestoneOrder()}
								>
									Save
								</Button>
							</Box>
						)}
					</>
				)}
			</Box>
			<ProjectMilestoneForm
				open={isMilestoneModalShown}
				onClose={() => setIsMilestoneModalShown(false)}
				milestone={milestoneForm}
				paymentStatusList={paymentStatusList}
				milestoneStatusList={milestonestatusList}
				validation={{
					projectStartdate: project?.project_by_pk?.start_date,
					projectEnddate: project?.project_by_pk?.end_date,
					revenue: project?.project_by_pk?.revenue,
				}}
				milestoneAmount={milestoneAmount}
				refetch={() => {
					refetchMilestoneList();
					refetchMileStoneTable();
				}}
			/>
		</>
	);
};

export default ProjectMilestoneList;
