import React, { useState } from 'react';
import {
	Datagrid,
	FunctionField,
	List,
	useNotify,
	useRefresh,
} from 'react-admin';
import { datagridStyle, ellipsisStyle } from '../../../../Layout/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { settingsStyle } from '../../settingsStyle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { inlineStyle } from '../style';
import {
	Box,
	Tooltip,
	Typography,
	TextField as TextComponent,
	IconButton,
	Button,
} from '@material-ui/core';
import { searchBarTheme } from '../../../../Layout/Theme.component';
import { SearchIcon } from '../../../../assets/SvgIcons';
import {
	useDeleteProjectCategoryMutation,
	useProjectCategoryCountQuery,
} from '../../../../generated/graphql';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../../SharedComponents/WarningModal.component';
import {
	DELETE_CATEGORY_WARNING_MESSAGE,
	PROJECT_CATEGORY_DELETE_SUCCESS_MESSAGE,
	WARNING_CATEGORY_MODAL_MESSAGE,
} from './../constant';
import ProjectCategoryForm from './ProjectCategoryForm.component';

const ToolBar = (props: any) => {
	const { searchText, onSearch, setIsProjectCategoryFormShown } = props;
	const inlineStyles = inlineStyle();
	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			marginBottom={'12px'}
		>
			<Box>
				<ThemeProvider theme={searchBarTheme}>
					<Box width='250px' marginTop={'-1px'}>
						<TextComponent
							placeholder='Search Project Category'
							label={false}
							fullWidth
							InputLabelProps={{ style: { fontSize: 0 } }}
							InputProps={{
								startAdornment: <SearchIcon />,
							}}
							onChange={(e) => {
								onSearch(e?.target?.value);
							}}
							value={searchText || []}
						/>
					</Box>
				</ThemeProvider>
			</Box>
			<Box>
				<Button
					variant='contained'
					className={inlineStyles.saveButton}
					onClick={() => {
						setIsProjectCategoryFormShown(true);
					}}
				>
					Add Project Category
				</Button>
			</Box>
		</Box>
	);
};

const ProjectCategory = () => {
	const notify = useNotify();
	const userefresh = useRefresh();
	const classes = inlineStyle();
	const tooltipStyle = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const settingsStyles = settingsStyle();

	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [projectCategoryData, setProjectCategoryData] = useState({});
	const [isProjectCategoryFormShown, setIsProjectCategoryFormShown] =
		useState(false);
	const [searchedCategory, setSearchedCategory] = useState('');
	const [deleteId, setDeleteId] = useState<string | null>();

	const [deleteProjectCategory] = useDeleteProjectCategoryMutation();
	useProjectCategoryCountQuery({
		variables: {
			id: deleteId,
		},
		fetchPolicy: 'network-only',
		skip: !deleteId,
		onCompleted: (data) =>
			handleWarningModal(
				data.project_category_by_pk?.projects_aggregate.aggregate?.count || 0,
				data.project_category_by_pk?.project_tasks_aggregate?.aggregate
					?.count || 0
			),
	});
	const handleWarningModal = (
		projectCount: number,
		projectTaskCount: number
	) => {
		if (projectCount > 0 || projectTaskCount > 0) {
			setIsWarningModalShown(true);
			setIsDeleteModalShown(false);
		} else {
			setIsWarningModalShown(false);
			setIsDeleteModalShown(true);
		}
	};
	const handleDelete = () => {
		deleteProjectCategory({
			variables: {
				projectCategoryId: deleteId,
			},
		}).then((data) => {
			notify(PROJECT_CATEGORY_DELETE_SUCCESS_MESSAGE);
			userefresh();
			setIsProjectCategoryFormShown(false);
			setIsDeleteModalShown(false);
		});
	};
	return (
		<>
			<Box className={settingsStyles.content}>
				<ToolBar
					searchText={searchedCategory}
					onSearch={(value: string) => setSearchedCategory(value)}
					setIsProjectCategoryFormShown={(value: boolean) =>
						setIsProjectCategoryFormShown(value)
					}
				/>

				<List
					empty={false}
					resource='project_category'
					basePath='/settings'
					bulkActionButtons={false}
					actions={false}
					title={' '}
					perPage={5}
					sort={{ field: 'name', order: 'ASC' }}
					filter={{ name: `%${searchedCategory}%` }}
				>
					<Datagrid rowClick={''}>
						<FunctionField
							label='NAME'
							render={(project: any) => {
								return (
									<Tooltip title={`${project.name}`} placement='right'>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.name || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label='TYPE'
							render={(project: any) => {
								return (
									<Tooltip title={`${project.type}`} placement='right'>
										<Typography
											className={`${classes.name} ${tooltipStyle.ellipsis}`}
										>
											{project.type || '--'}
										</Typography>
									</Tooltip>
								);
							}}
						/>
						<FunctionField
							label=''
							render={(record: any) => {
								return (
									<Box display='flex' alignItems='center'>
										<Box
											className={datagridStyles.listEditIconContainer}
											mr={1}
										>
											<IconButton
												className={datagridStyles.listEditIconButton}
												onClick={() => {
													setProjectCategoryData(record);
													setIsProjectCategoryFormShown(true);
												}}
											>
												<EditIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
										<Box className={datagridStyles.listEditIconContainer}>
											<IconButton
												className={`${datagridStyles.listEditIconButton} ${datagridStyles.deleteIconButton}`}
												onClick={(e) => {
													setDeleteId(record.id);
												}}
											>
												<DeleteIcon className={datagridStyles.editIcon} />
											</IconButton>
										</Box>
									</Box>
								);
							}}
						/>
					</Datagrid>
				</List>

				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
						setDeleteId(null);
					}}
					confirmationMessage={DELETE_CATEGORY_WARNING_MESSAGE}
					onDelete={() => {
						handleDelete();
					}}
				/>
				<ProjectCategoryForm
					open={isProjectCategoryFormShown}
					onClose={() => {
						setIsProjectCategoryFormShown(false);
						setProjectCategoryData({});
					}}
					projectCategory={projectCategoryData}
				/>
				<WarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
						setDeleteId(null);
					}}
					warningMessage={WARNING_CATEGORY_MODAL_MESSAGE}
				/>
			</Box>
		</>
	);
};
export default ProjectCategory;
