import React, { ReactNode, useState, useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { insightViewStyle } from '../../Layout/styles';
import { PipelineTotalValue } from '../../Reports/talent/Pipeline/PipelineTotalValue.component';
import { PipelineWeightedValue } from '../../Reports/talent/Pipeline/PipelineWeightedValue.component';
import { PipelineTotalCount } from '../../Reports/talent/Pipeline/PipelineTotalCount.component';
import { PipelineSalesRevenue } from '../../Reports/talent/Pipeline/PipelineSalesRevenue.component';
import { PipelineLossRevenue } from '../../Reports/talent/Pipeline/PipelineLossRevenue.component';
import { PipelineSummaryReport } from '../../Reports/talent/Pipeline/PipelineSummaryReport.component';
import { PipelineByOwnerReport } from '../../Reports/talent/Pipeline/PipelineByOwner.component';
import { PipelineLossReport } from '../../Reports/talent/Pipeline/PipelineLossReason.component';
import { PipelineTotalSaleReport } from '../../Reports/talent/Pipeline/PipelineTotalSale.component';
import { PipelineTotalSaleByOwnerReport } from '../../Reports/talent/Pipeline/PipelineTotalSaleByOwner.component';
import { PipelineTotalSaleByGeographyReport } from '../../Reports/talent/Pipeline/PipelineSaleByGeography.component';
import { PipelineSaleForecastReport } from '../../Reports/talent/Pipeline/PipelineSaleForecast.component';
import {
	useGetOrgFinancialQuery,
	useGetPipelineInsightsValuesQuery,
} from '../../generated/graphql';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';
import { UserProfileContext } from '../../App';
import { currentYear } from '../constant';
import { head } from 'lodash';

const CountCard = ({ label, value }: { label: string; value: ReactNode }) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.countContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};

const PipelineInsights = () => {
	const { orgId: organisationId } = useContext<any>(UserProfileContext);
	const classes = insightViewStyle();
	const [startDate, setStartDate] = useState<string | null | undefined>(
		currentYear.startDate
	);
	const [endDate, setEndDate] = useState<string | null | undefined>(
		currentYear.endDate
	);
	const { data: organisation } = useGetOrgFinancialQuery({
		variables: { organisationId },
	});

	const { data: pipelineValues, loading: isPipelineValuesLoading } =
		useGetPipelineInsightsValuesQuery({
			variables: { startDate: startDate, endDate: endDate },
		});

	useEffect(() => {
		if (!organisation) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				organisation?.organization[0]?.financial_year_start_month || '',
			financial_year_start_date:
				organisation?.organization[0]?.financial_year_start_date || '',
			financial_year_end_date:
				organisation?.organization[0]?.financial_year_end_date || '',
			financial_year_end_month:
				organisation?.organization[0]?.financial_year_end_month || '',
		});
		setStartDate(calculatedDate?.startDate);
		setEndDate(calculatedDate?.endDate);
	}, [organisation]);

	return (
		<>
			<Box className={classes.headCountContainer}>
				<CountCard
					label='Pipeline Total Value'
					value={
						<PipelineTotalValue
							value={
								pipelineValues?.pipelineTotalValue.aggregate?.sum?.value || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Pipeline Weighted Value'
					value={
						<PipelineWeightedValue
							value={
								pipelineValues?.pipelineTotalWeightedValue.reduce(
									(previousValue, currentValue) =>
										previousValue + currentValue.weighted_value,
									0
								) || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Pipeline Total Count'
					value={
						<PipelineTotalCount
							value={pipelineValues?.pipelineTotalCount.aggregate?.count || 0}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Total Sales'
					value={
						<PipelineSalesRevenue
							count={pipelineValues?.totalSales.aggregate?.sum?.value || 0}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
				<CountCard
					label='Lost Revenue'
					value={
						<PipelineLossRevenue
							value={
								pipelineValues?.pipelineLossValue.aggregate?.sum?.value || 0
							}
							currencyType={
								head(organisation?.organization)?.currency.currency_type || ''
							}
							isLoading={isPipelineValuesLoading}
						/>
					}
				/>
			</Box>
			<Box className={classes.container}>
				<Box className={classes.chartContainer}>
					<PipelineSummaryReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineByOwnerReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineLossReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineTotalSaleReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineTotalSaleByOwnerReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineTotalSaleByGeographyReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
				<Box className={classes.chartContainer}>
					<PipelineSaleForecastReport
						defaultStartDate={startDate}
						defaultEndDate={endDate}
						currency={head(organisation?.organization)?.currency}
					/>
				</Box>
			</Box>
		</>
	);
};

export default PipelineInsights;
