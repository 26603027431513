import React from "react";
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';
import { Filter, TextInput } from 'react-admin';

const DesignationFilter = props => (
    <Filter {...props}>
        <TextInput
            label="Search by designation"
            source="title"
            alwaysOn
        />
    </Filter>
);

export const DesignationList = props => {  
    return(
    <List sort={{ field: "title", order: "ASC" }} bulkActionButtons={false}  {...props} filters={<DesignationFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="title" />
            <TextField source="description" />
        </Datagrid>
    </List>
    )};