import React, { useEffect, useState, useContext } from 'react';
import { FunctionField } from 'react-admin';
import DetailContainer from '../../../SharedComponents/Employee/DetailContainer.component';
import FieldContainer from '../../../SharedComponents/Employee/FieldContainer.componet';
import { Typography, Box } from '@material-ui/core';
import editIcon from '../../../assets/icons/edit-icon.svg';
import ConsultantBankDetailsEditForm from './EditConsultantBankDetails.component';
import { detailsPageTabDetailsStyle } from '../../../Layout/styles';
import { UserProfileContext } from '../../../App';

interface Record {
	bank_name: string;
	branch_name?: string;
	account_number: string;
	ifsc_code?: string;
	id: string
}
interface Props {
	record: Record;
}
export const ConsultantBankDetails = (props: Props) => {
	const { record: consultant } = props;
	const [openEditModal, setOpenEditModal] = useState(false);
  const [consultantDetails, setConsultantDetails] = useState({});
	const classes = detailsPageTabDetailsStyle();
	const {permissions: permissionList } = useContext<any>(UserProfileContext);

  useEffect(() =>{
    if(consultant){
      setConsultantDetails({
				bank_name: consultant?.bank_name,
				branch_name: consultant?.branch_name,
				account_number: consultant?.account_number,
				ifsc_code: consultant?.ifsc_code,
			});
    }
  },[consultant])
	return (
		<>
			{permissionList?.contractor?.update_permissions ? (
				<Box
					className={classes.editContainer}
					onClick={() => setOpenEditModal(true)}
				>
					<img className={classes.editButton} src={editIcon} alt='edit icon' />
					<Box className={classes.editIcon}>Edit Details</Box>
				</Box>
			) : null}
			<DetailContainer>
				<FieldContainer>
					<Typography className={classes.label}>Bank Name</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => `${consultant?.bank_name || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Branch Name</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => `${consultant?.branch_name || '- -'}`}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>Account Number</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) =>
							`${consultant?.account_number || '- -'}`
						}
					/>
				</FieldContainer>
				<FieldContainer>
					<Typography className={classes.label}>IFSC Code</Typography>
					<FunctionField
						className={classes.value}
						record={consultant}
						render={(consultant: any) => `${consultant?.ifsc_code || '- -'}`}
					/>
				</FieldContainer>
			</DetailContainer>
			<ConsultantBankDetailsEditForm
				open={openEditModal}
				onClose={(value: boolean) => setOpenEditModal(value)}
				record={consultantDetails}
			/>
		</>
	);
};

export default ConsultantBankDetails;
