import React, { useState } from 'react';
import { Box, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles({
	container: {
		display: 'flex',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	fabButton: {
		background: '#4285F4 !important',
		color: '#ffffff',
	},
});
interface Menu {
	name: string;
	value: string
}
interface Props {
	selectedMenu: (menu: string) => void;
	menuList: Menu[]
}

export const FloatingComponent = (props: Props) => {
	const { selectedMenu, menuList } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const showPopOver = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const closePopOver = () => {
		setAnchorEl(null);
	};
	const onMenuChange = (menu: string) => {
		selectedMenu(menu);
		closePopOver();
	};
	return (
		<div className={classes.container}>
			<Fab className={classes.fabButton} aria-label='add' onClick={showPopOver}>
				<AddIcon />
			</Fab>
			<Popover
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closePopOver}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				{menuList?.map((menu: Menu, index: number) => (
					<MenuItem key={index}>
						<Box
							key={index}
							onClick={() => onMenuChange(menu?.value)}
							className={classes.editText}
						>
							{menu?.name}
						</Box>
					</MenuItem>
				))}
			</Popover>
		</div>
	);
};
