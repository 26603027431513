import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Tooltip } from '@material-ui/core/';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_OPPORTUNITY } from './Opportunities.gql';
import OpportunityDetailsContainer from './DetailsPage/OpportunityDetailsContainer.component';
import { ellipsisStyle } from '../../Layout/styles';
import OpportunityDetailsLeftPanel from './DetailsPage/OpportunityDetailsLeftPanel.component';

const useStyles = makeStyles(() => ({
	toolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '60px',
		alignItems: 'center',
	},
	backButton: {
		width: '89px',
		height: '36px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/32px Manrope',
		color: '#4285F4',
		cursor: 'pointer',
		marginRight: '20px',
	},
	breadCrumb: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		color: '#5C5C5C',
	},
	arrowIcon: {
		color: '#5C5C5C',
		width: '21px',
		paddingLeft: '7px',
	},
	opportunityName: {
		color: '#4285F4',
		fontSize: '12px',
		fontFamily: 'Manrope-Bold',
		paddingLeft: '14px',
		width: '230px',
	},
	detailContainer: {
		display: 'flex',
	},
	rightPanel: {
		marginLeft: '16px',
		width: '100%',
	},
}));

export const OpportunityDetails = () => {
	const { id }: { id: string } = useParams();
	const [opportunityForm, setOpportunityForm] = useState({});
	const [uploaded, setUploaded] = useState({});
	const classes = useStyles();
	const style = ellipsisStyle();
	const history = useHistory();
	const { data: opportunity, refetch: refetchOpportunityList } = useApolloQuery(
		GET_OPPORTUNITY,
		{
			variables: {
				id: id ? id : null,
			},
		}
	);
	useEffect(() => {
		if (!opportunity) {
			return;
		}
		setOpportunityForm({
			...opportunity.opportunities_by_pk,
		});
	}, [opportunity]);

	return (
		<>
			<Box className={classes.toolBar}>
				<Box className={classes.breadCrumb}>
					<Typography className={classes.title}>Opportunities</Typography>
					<DoubleArrowIcon className={classes.arrowIcon} />
					<Tooltip
						title={`${opportunity?.opportunities_by_pk?.name}`}
						placement='right'
					>
						<Typography
							className={[classes.opportunityName, style.ellipsis].join(' ')}
						>
							{opportunity?.opportunities_by_pk?.name}
						</Typography>
					</Tooltip>
				</Box>
				<Box>
					<button
						onClick={() => history.goBack()}
						className={classes.backButton}
					>
						Back
					</button>
				</Box>
			</Box>
			<Box className={classes.detailContainer}>
				<Box>
					<OpportunityDetailsLeftPanel
						setUploaded={setUploaded}
						opportunity={opportunityForm}
						refetch={refetchOpportunityList}
					/>
				</Box>
				<Box className={classes.rightPanel}>
					<OpportunityDetailsContainer
						uploaded={uploaded}
						setUploaded={setUploaded}
						boardId={opportunity?.opportunities_by_pk?.
							kanbanCard?.kanban_column?.kanban_board?.id}
					/>
				</Box>
			</Box>
		</>
	);
};

export default OpportunityDetails;
